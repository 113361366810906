import React from 'react'

const Error = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
    <rect width="20" height="20" fill="#D2322D" rx="10" />
    <g clipPath="url(#a)">
      <path
        fill="#fff"
        d="M6.936 6.936a1 1 0 0 0 0 1.414L8.586 10l-1.65 1.65a1 1 0 1 0 1.414 1.414l1.65-1.65 1.65 1.65a1 1 0 0 0 1.414-1.414L11.414 10l1.65-1.65a1 1 0 1 0-1.414-1.414L10 8.586l-1.65-1.65a1 1 0 0 0-1.414 0Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M4 4h12v12H4z" />
      </clipPath>
    </defs>
  </svg>
)

export default Error
