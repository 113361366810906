// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<PasswordField /> when rendered as v3 renders without size 1`] = `
Object {
  "asFragment": [Function],
  "baseElement": <body>
    <div>
      <div
        class="v3"
      >
        <div
          class="product "
        >
          <div
            class="quantity"
          >
            <span
              class="wrapper textRegular16 brandBlue500 scale"
            >
              product_thumb.quantity
            </span>
          </div>
          <img
            alt="test"
            class="image thumb"
            height="173"
            src="http://res.cloudinary.com/ldhg5acsz/image/upload/c_fill,dpr_1.0,f_auto,h_173,q_auto,w_173/test"
            width="173"
          />
        </div>
      </div>
      <div
        class="Toastify"
      />
    </div>
  </body>,
  "container": <div>
    <div
      class="v3"
    >
      <div
        class="product "
      >
        <div
          class="quantity"
        >
          <span
            class="wrapper textRegular16 brandBlue500 scale"
          >
            product_thumb.quantity
          </span>
        </div>
        <img
          alt="test"
          class="image thumb"
          height="173"
          src="http://res.cloudinary.com/ldhg5acsz/image/upload/c_fill,dpr_1.0,f_auto,h_173,q_auto,w_173/test"
          width="173"
        />
      </div>
    </div>
    <div
      class="Toastify"
    />
  </div>,
  "debug": [Function],
  "findAllByAltText": [Function],
  "findAllByDisplayValue": [Function],
  "findAllByLabelText": [Function],
  "findAllByPlaceholderText": [Function],
  "findAllByRole": [Function],
  "findAllByTestId": [Function],
  "findAllByText": [Function],
  "findAllByTitle": [Function],
  "findByAltText": [Function],
  "findByDisplayValue": [Function],
  "findByLabelText": [Function],
  "findByPlaceholderText": [Function],
  "findByRole": [Function],
  "findByTestId": [Function],
  "findByText": [Function],
  "findByTitle": [Function],
  "getAllByAltText": [Function],
  "getAllByDisplayValue": [Function],
  "getAllByLabelText": [Function],
  "getAllByPlaceholderText": [Function],
  "getAllByRole": [Function],
  "getAllByTestId": [Function],
  "getAllByText": [Function],
  "getAllByTitle": [Function],
  "getByAltText": [Function],
  "getByDisplayValue": [Function],
  "getByLabelText": [Function],
  "getByPlaceholderText": [Function],
  "getByRole": [Function],
  "getByTestId": [Function],
  "getByText": [Function],
  "getByTitle": [Function],
  "queryAllByAltText": [Function],
  "queryAllByDisplayValue": [Function],
  "queryAllByLabelText": [Function],
  "queryAllByPlaceholderText": [Function],
  "queryAllByRole": [Function],
  "queryAllByTestId": [Function],
  "queryAllByText": [Function],
  "queryAllByTitle": [Function],
  "queryByAltText": [Function],
  "queryByDisplayValue": [Function],
  "queryByLabelText": [Function],
  "queryByPlaceholderText": [Function],
  "queryByRole": [Function],
  "queryByTestId": [Function],
  "queryByText": [Function],
  "queryByTitle": [Function],
  "rerender": [Function],
  "unmount": [Function],
}
`;

exports[`<PasswordField /> when rendered renders correctly 1`] = `
Object {
  "asFragment": [Function],
  "baseElement": <body>
    <div>
      <div
        class=""
      >
        <div
          class="product "
        >
          <div
            class="quantity"
          >
            <span
              class="wrapper textRegular16 brandBlue500 scale"
            >
              2
            </span>
          </div>
          <div
            class="size"
          >
            <span
              class="wrapper textRegular16 brandBlue500 scale"
            >
              100mls
            </span>
          </div>
          <img
            alt="test"
            class="image thumb"
            height="173"
            src="http://res.cloudinary.com/ldhg5acsz/image/upload/c_fill,dpr_1.0,f_auto,h_173,q_auto,w_173/test"
            width="173"
          />
        </div>
      </div>
      <div
        class="Toastify"
      />
    </div>
  </body>,
  "container": <div>
    <div
      class=""
    >
      <div
        class="product "
      >
        <div
          class="quantity"
        >
          <span
            class="wrapper textRegular16 brandBlue500 scale"
          >
            2
          </span>
        </div>
        <div
          class="size"
        >
          <span
            class="wrapper textRegular16 brandBlue500 scale"
          >
            100mls
          </span>
        </div>
        <img
          alt="test"
          class="image thumb"
          height="173"
          src="http://res.cloudinary.com/ldhg5acsz/image/upload/c_fill,dpr_1.0,f_auto,h_173,q_auto,w_173/test"
          width="173"
        />
      </div>
    </div>
    <div
      class="Toastify"
    />
  </div>,
  "debug": [Function],
  "findAllByAltText": [Function],
  "findAllByDisplayValue": [Function],
  "findAllByLabelText": [Function],
  "findAllByPlaceholderText": [Function],
  "findAllByRole": [Function],
  "findAllByTestId": [Function],
  "findAllByText": [Function],
  "findAllByTitle": [Function],
  "findByAltText": [Function],
  "findByDisplayValue": [Function],
  "findByLabelText": [Function],
  "findByPlaceholderText": [Function],
  "findByRole": [Function],
  "findByTestId": [Function],
  "findByText": [Function],
  "findByTitle": [Function],
  "getAllByAltText": [Function],
  "getAllByDisplayValue": [Function],
  "getAllByLabelText": [Function],
  "getAllByPlaceholderText": [Function],
  "getAllByRole": [Function],
  "getAllByTestId": [Function],
  "getAllByText": [Function],
  "getAllByTitle": [Function],
  "getByAltText": [Function],
  "getByDisplayValue": [Function],
  "getByLabelText": [Function],
  "getByPlaceholderText": [Function],
  "getByRole": [Function],
  "getByTestId": [Function],
  "getByText": [Function],
  "getByTitle": [Function],
  "queryAllByAltText": [Function],
  "queryAllByDisplayValue": [Function],
  "queryAllByLabelText": [Function],
  "queryAllByPlaceholderText": [Function],
  "queryAllByRole": [Function],
  "queryAllByTestId": [Function],
  "queryAllByText": [Function],
  "queryAllByTitle": [Function],
  "queryByAltText": [Function],
  "queryByDisplayValue": [Function],
  "queryByLabelText": [Function],
  "queryByPlaceholderText": [Function],
  "queryByRole": [Function],
  "queryByTestId": [Function],
  "queryByText": [Function],
  "queryByTitle": [Function],
  "rerender": [Function],
  "unmount": [Function],
}
`;

exports[`<PasswordField /> when rendered renders without a quantity count if not supplied 1`] = `
Object {
  "asFragment": [Function],
  "baseElement": <body>
    <div>
      <div
        class=""
      >
        <div
          class="product "
        >
          <div
            class="size"
          >
            <span
              class="wrapper textRegular16 brandBlue500 scale"
            >
              100mls
            </span>
          </div>
          <img
            alt="test"
            class="image thumb"
            height="173"
            src="http://res.cloudinary.com/ldhg5acsz/image/upload/c_fill,dpr_1.0,f_auto,h_173,q_auto,w_173/test"
            width="173"
          />
        </div>
      </div>
      <div
        class="Toastify"
      />
    </div>
  </body>,
  "container": <div>
    <div
      class=""
    >
      <div
        class="product "
      >
        <div
          class="size"
        >
          <span
            class="wrapper textRegular16 brandBlue500 scale"
          >
            100mls
          </span>
        </div>
        <img
          alt="test"
          class="image thumb"
          height="173"
          src="http://res.cloudinary.com/ldhg5acsz/image/upload/c_fill,dpr_1.0,f_auto,h_173,q_auto,w_173/test"
          width="173"
        />
      </div>
    </div>
    <div
      class="Toastify"
    />
  </div>,
  "debug": [Function],
  "findAllByAltText": [Function],
  "findAllByDisplayValue": [Function],
  "findAllByLabelText": [Function],
  "findAllByPlaceholderText": [Function],
  "findAllByRole": [Function],
  "findAllByTestId": [Function],
  "findAllByText": [Function],
  "findAllByTitle": [Function],
  "findByAltText": [Function],
  "findByDisplayValue": [Function],
  "findByLabelText": [Function],
  "findByPlaceholderText": [Function],
  "findByRole": [Function],
  "findByTestId": [Function],
  "findByText": [Function],
  "findByTitle": [Function],
  "getAllByAltText": [Function],
  "getAllByDisplayValue": [Function],
  "getAllByLabelText": [Function],
  "getAllByPlaceholderText": [Function],
  "getAllByRole": [Function],
  "getAllByTestId": [Function],
  "getAllByText": [Function],
  "getAllByTitle": [Function],
  "getByAltText": [Function],
  "getByDisplayValue": [Function],
  "getByLabelText": [Function],
  "getByPlaceholderText": [Function],
  "getByRole": [Function],
  "getByTestId": [Function],
  "getByText": [Function],
  "getByTitle": [Function],
  "queryAllByAltText": [Function],
  "queryAllByDisplayValue": [Function],
  "queryAllByLabelText": [Function],
  "queryAllByPlaceholderText": [Function],
  "queryAllByRole": [Function],
  "queryAllByTestId": [Function],
  "queryAllByText": [Function],
  "queryAllByTitle": [Function],
  "queryByAltText": [Function],
  "queryByDisplayValue": [Function],
  "queryByLabelText": [Function],
  "queryByPlaceholderText": [Function],
  "queryByRole": [Function],
  "queryByTestId": [Function],
  "queryByText": [Function],
  "queryByTitle": [Function],
  "rerender": [Function],
  "unmount": [Function],
}
`;

exports[`<PasswordField /> when rendered renders without a size if not supplied 1`] = `
Object {
  "asFragment": [Function],
  "baseElement": <body>
    <div>
      <div
        class=""
      >
        <div
          class="product "
        >
          <div
            class="quantity"
          >
            <span
              class="wrapper textRegular16 brandBlue500 scale"
            >
              2
            </span>
          </div>
          <img
            alt="test"
            class="image thumb"
            height="173"
            src="http://res.cloudinary.com/ldhg5acsz/image/upload/c_fill,dpr_1.0,f_auto,h_173,q_auto,w_173/test"
            width="173"
          />
        </div>
      </div>
      <div
        class="Toastify"
      />
    </div>
  </body>,
  "container": <div>
    <div
      class=""
    >
      <div
        class="product "
      >
        <div
          class="quantity"
        >
          <span
            class="wrapper textRegular16 brandBlue500 scale"
          >
            2
          </span>
        </div>
        <img
          alt="test"
          class="image thumb"
          height="173"
          src="http://res.cloudinary.com/ldhg5acsz/image/upload/c_fill,dpr_1.0,f_auto,h_173,q_auto,w_173/test"
          width="173"
        />
      </div>
    </div>
    <div
      class="Toastify"
    />
  </div>,
  "debug": [Function],
  "findAllByAltText": [Function],
  "findAllByDisplayValue": [Function],
  "findAllByLabelText": [Function],
  "findAllByPlaceholderText": [Function],
  "findAllByRole": [Function],
  "findAllByTestId": [Function],
  "findAllByText": [Function],
  "findAllByTitle": [Function],
  "findByAltText": [Function],
  "findByDisplayValue": [Function],
  "findByLabelText": [Function],
  "findByPlaceholderText": [Function],
  "findByRole": [Function],
  "findByTestId": [Function],
  "findByText": [Function],
  "findByTitle": [Function],
  "getAllByAltText": [Function],
  "getAllByDisplayValue": [Function],
  "getAllByLabelText": [Function],
  "getAllByPlaceholderText": [Function],
  "getAllByRole": [Function],
  "getAllByTestId": [Function],
  "getAllByText": [Function],
  "getAllByTitle": [Function],
  "getByAltText": [Function],
  "getByDisplayValue": [Function],
  "getByLabelText": [Function],
  "getByPlaceholderText": [Function],
  "getByRole": [Function],
  "getByTestId": [Function],
  "getByText": [Function],
  "getByTitle": [Function],
  "queryAllByAltText": [Function],
  "queryAllByDisplayValue": [Function],
  "queryAllByLabelText": [Function],
  "queryAllByPlaceholderText": [Function],
  "queryAllByRole": [Function],
  "queryAllByTestId": [Function],
  "queryAllByText": [Function],
  "queryAllByTitle": [Function],
  "queryByAltText": [Function],
  "queryByDisplayValue": [Function],
  "queryByLabelText": [Function],
  "queryByPlaceholderText": [Function],
  "queryByRole": [Function],
  "queryByTestId": [Function],
  "queryByText": [Function],
  "queryByTitle": [Function],
  "rerender": [Function],
  "unmount": [Function],
}
`;
