import { gql } from '@apollo/client'

const CONFIRMATION_PAGE_QUERY = gql`
  query ConfirmationPageQuery($orderId: ID!) {
    user {
      shippingCountryCode
      preferredLanguage
      subscription {
        activePaymentMethodType
        paymentMethods {
          ... on CreditCardPaymentMethod {
            __typename
            lastFourDigits
            active
          }
        }
      }
      nonCoreOrder(orderId: $orderId) {
        address {
          recipientName
          address1
          address2
          city
          postcode
          deliveryNotes
          deliveryCarrier
        }
        invoice {
          grossPrice
          discountedPrice
          status
        }
        orderParts {
          consignment {
            status
          }
          deliveryDate
          deliverySurcharge
          smallOrderSurcharge
          orderProducts {
            discountedPrice
            grossPrice
            quantity
            productVariant {
              name
              productCollection {
                id
                name
                thumbnail {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`
export { CONFIRMATION_PAGE_QUERY }
