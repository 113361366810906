// @noflow
import flatten from 'lodash/flatten'
import isNull from 'lodash/isNull'
import uniq from 'lodash/uniq'

import * as Sentry from '@/utils/sentry'

import { Analytics } from '@/components/analytics/Analytics'
import type { InitialState as WizardState } from '@/components/pages/SignupWizardPage/SignupWizardPage'
import {
  encodeAge,
  encodeEaterType
} from '@/components/pages/SignupWizardPage/helpers/submitFormData'
import type {
  Dog,
  WizardAge,
  WizardEaterType
} from '@/components/pages/SignupWizardPage/types/types'
// eslint-disable-next-line no-restricted-imports
import type {
  PendingSubscription,
  PlanRecommendation,
  PlanType
} from '@/components/types'

import { Eater } from '@/types'
import { ensureNever } from '@/typescript/utils'

import type { Route } from '../types/routes'
import type { FlowType, PageLoadProperties } from '../types/types'

enum Action {
  onSwipe = 'Swipe',
  arrowClicked = 'Arrow Clicked'
}

const getFlowInformation = (
  route: Route,
  planState: PendingSubscription,
  wizardState: WizardState,
  planRecommendationItem: PlanRecommendation | string | null,
  deliveryCadence: 'short' | 'long' | 'default',
  flowType: FlowType
): Omit<
  PageLoadProperties,
  | 'numberOfSelectedRecipes'
  | 'numberOfRecommendedRecipes'
  | 'numberOfNonRecommendedRecipes'
  | 'addedExtraProducts'
  | 'idealGramsAmount'
> => {
  const dogs = wizardState.dogs

  const numberOfDogs = dogs.length
  const numberOfPuppies = dogs.filter(
    (dog: Dog) => dog.ageStage === 'puppy'
  ).length
  const ageInMonths = dogs.map((dog: Dog) => encodeAge(dog.age as WizardAge))
  const hasAnAdult = !!dogs.find((dog: Dog) => dog.ageStage === 'adult')
  const hasAPuppy = !!dogs.find((dog: Dog) => dog.ageStage === 'puppy')
  const hasASenior = !!dogs.find((dog: Dog) => dog.ageStage === 'senior')
  const weightInGrams = dogs.map((dog: Dog) => dog.weight)
  const hasAnOverweightDog = !!dogs.find(
    (dog: Dog) => dog.bodyCondition === 'chubby'
  )
  const hasARightSizedDog = !!dogs.find(
    (dog: Dog) => dog.bodyCondition === 'justRight'
  )
  const hasAnUnderweightDog = !!dogs.find(
    (dog: Dog) => dog.bodyCondition === 'skinny'
  )
  const allergies = uniq(flatten(dogs.map((dog: Dog) => dog.allergies))) // TODO: Get the names instead of the IDs? Change in wizardState localStorage
  const hasADogWithAnAllergy =
    dogs.filter(
      (dog: Dog): boolean => !isNull(dog.allergies) && dog.allergies.length > 0
    ).length > 0
  const healthIssues = uniq(
    flatten(
      dogs.map((dog: Dog) =>
        dog.healthIssues && dog.healthIssues.length > 0
          ? dog.healthIssues.map((hi) => hi.name)
          : []
      )
    )
  )
  const hasADogWithHealthIssue =
    dogs.filter(
      (dog: Dog): boolean =>
        !isNull(dog.healthIssues) && dog.healthIssues.length > 0
    ).length > 0
  const foodCategories = uniq(
    flatten(dogs.map((dog: Dog): Array<string> => dog.foodCategoryIds))
  )
  const eaterType = uniq(
    flatten(
      dogs.map(
        (dog: Dog): Eater => encodeEaterType(dog.eaterType as WizardEaterType)
      )
    )
  )
  const hasAFussyEater =
    eaterType.filter(
      (eaterType: Eater): boolean =>
        eaterType === 'fussy_eater' || eaterType === 'picky_eater'
    ).length > 0
  const activityLevel = dogs.map((dog: Dog) => dog.activityLevel)
  const breed = dogs.map((dog: Dog) =>
    !isNull(dog.breed) ? dog.breed.key : null
  )
  const selectedDailyGrams = planState?.plan?.pricingCurve.pricingCohort || null

  return {
    numberOfDogs,
    numberOfPuppies,
    ageInMonths,
    hasAnAdult,
    hasAPuppy,
    hasASenior,
    weightInGrams,
    hasAnOverweightDog,
    hasARightSizedDog,
    hasAnUnderweightDog,
    allergies,
    hasADogWithAnAllergy,
    healthIssues,
    hasADogWithHealthIssue,
    foodCategories,
    eaterType,
    hasAFussyEater,
    activityLevel,
    breed,
    deliveryCadence,
    step: route,
    flowType,
    selectedDailyGrams
  }
}

const trackPageLoad = (properties: PageLoadProperties): void => {
  Analytics.track('Plans Flow Step Loaded', {
    ...properties
  })
}

const trackRecipeSelectionToggled = (
  route: Route,
  planState: PendingSubscription,
  wizardState: WizardState | null,
  planRecommendationItem: string | null,
  deliveryCadence: 'short' | 'long' | 'default',
  selectionStatus: 'selected' | 'deselected',
  flavour: string,
  flowType: FlowType
): void => {
  if (isNull(wizardState)) return

  const flowData = getFlowInformation(
    route,
    planState,
    wizardState,
    planRecommendationItem,
    deliveryCadence,
    flowType
  )
  Analytics.track('Plans Flow Recipe Toggled', {
    ...flowData,
    selectionStatus,
    flavour
  })
}

const trackRecipeMoreInfoClicked = (
  route: Route,
  planState: PendingSubscription,
  wizardState: WizardState | null,
  planRecommendationItem: string | null,
  deliveryCadence: 'short' | 'long' | 'default',
  flavour: string,
  flowType: FlowType
): void => {
  if (isNull(wizardState)) return

  const flowData = getFlowInformation(
    route,
    planState,
    wizardState,
    planRecommendationItem,
    deliveryCadence,
    flowType
  )
  Analytics.track('Plans Flow Recipe More Info Clicked', {
    ...flowData,
    flavour
  })
}

const trackAdditionalProductMoreInfoClicked = (
  route: Route,
  planState: PendingSubscription,
  wizardState: WizardState | null,
  planRecommendationItem: string | null,
  deliveryCadence: 'short' | 'long' | 'default',
  extra: string,
  flowType: FlowType
): void => {
  if (isNull(wizardState)) return

  const flowData = getFlowInformation(
    route,
    planState,
    wizardState,
    planRecommendationItem,
    deliveryCadence,
    flowType
  )
  Analytics.track('Plans Flow Extra More Info Clicked', {
    ...flowData,
    extra
  })
}

const trackFaqClicked = (
  route: Route,
  flowtype: FlowType,
  question: string
): void => {
  Analytics.track('New Plans Flow FAQ Clicked', { route, flowtype, question })
}

const trackExtrasProductAdded = (
  productType: string[],
  quantity: number,
  productCollectionSlugs: string[],
  productVariantIds: string[]
): void => {
  Analytics.track('Plans Flow Extras Product Added', {
    productType,
    quantity,
    productCollectionSlugs,
    productVariantIds
  })
}
const trackExtrasProductRemoved = (
  productType: string[],
  quantity: number,
  productCollectionSlugs: string[],
  productVariantIds: string[]
): void => {
  Analytics.track('Precheckout Plans Flow Extras Removed', {
    productType,
    quantity,
    productCollectionSlugs,
    productVariantIds
  })
}

const trackExtrasSeen = (
  quantity: number,
  productGroup: string[] | null,
  productVariantIds: string[]
): void => {
  Analytics.track('Track Extras Seen Pre Checkout', {
    quantity,
    productGroup,
    productVariantIds
  })
}

const trackExtrasCarouselChange = (currentCard: number): void => {
  Analytics.track('Interacted with Extras Carousel on pre-checkout', {
    currentCard
  })
}

const trackCarouselInteractionAnalytics = (action: Action): void => {
  Analytics.track('Swiped or Clicked Extras Carousel on pre-checkout', {
    action: action
  })
}

const trackManagePlanManuallyClicked = (route: Route): void => {
  Analytics.track('Manage Plan Manually Clicked', { route })
}

const trackReviewNewPlanClicked = (route: Route): void => {
  Analytics.track('Review New Plan Button Clicked', { route })
}

const trackStayOnOldPlanClicked = (route: Route): void => {
  Analytics.track('Stay On Old Plan Button Clicked', { route })
}

const trackUpdatePlanClicked = (route: Route): void => {
  Analytics.track('Update Plan Button Clicked', { route })
}

const trackNextButtonClicked = (
  route: Route,
  planState: PendingSubscription,
  wizardState: WizardState | null,
  planRecommendationItem: string | null,
  deliveryCadence: 'short' | 'long' | 'default',
  flowType: FlowType
): void => {
  if (isNull(wizardState)) return

  const flowData = getFlowInformation(
    route,
    planState,
    wizardState,
    planRecommendationItem,
    deliveryCadence,
    flowType
  )
  Analytics.track('Plans Flow Next Button Clicked', { ...flowData })
}

const trackBackButtonClicked = (
  route: Route,
  planState: PendingSubscription,
  wizardState: WizardState | null,
  planRecommendationItem: string | null,
  deliveryCadence: 'short' | 'long' | 'default',
  flowType: FlowType
): void => {
  if (isNull(wizardState)) return

  const flowData = getFlowInformation(
    route,
    planState,
    wizardState,
    planRecommendationItem,
    deliveryCadence,
    flowType
  )
  Analytics.track('Plans Flow Back Button Clicked', { ...flowData })
}

const trackReviewTabToggled = (
  route: Route,
  planState: PendingSubscription,
  wizardState: WizardState | null,
  planRecommendationItem: string | null,
  deliveryCadence: 'short' | 'long' | 'default',
  tab: 'intro' | 'after',
  flowType: FlowType
): void => {
  if (isNull(wizardState)) return

  const flowData = getFlowInformation(
    route,
    planState,
    wizardState,
    planRecommendationItem,
    deliveryCadence,
    flowType
  )
  Analytics.track('Plans Flow Recipe Selection Tab Toggled', {
    ...flowData,
    tab
  })
}

const trackSharedReviewTabToggled = (tab: string): void => {
  Analytics.track('Plans Flow Plan Recommendation Tab Toggled', {
    location: '/plans/review',
    flowType: 'add',
    tab
  })
}

const trackPlanTypeSelected = (
  route: Route,
  planState: PendingSubscription,
  wizardState: WizardState | null,
  planRecommendationItem: string | null,
  deliveryCadence: 'short' | 'long' | 'default',
  type: PlanType,
  flowType: FlowType
): void => {
  if (isNull(wizardState)) return

  const flowData = getFlowInformation(
    route,
    planState,
    wizardState,
    planRecommendationItem,
    deliveryCadence,
    flowType
  )
  Analytics.track('Plans Flow Plan Type Selected', { ...flowData, type })
}

const trackPlanFrequencySelected = (
  route: Route,
  planState: PendingSubscription,
  wizardState: WizardState | null,
  planRecommendationItem: string | null,
  deliveryCadence: 'short' | 'default' | 'long',
  flowType: FlowType
): void => {
  if (isNull(wizardState)) return

  const flowData = getFlowInformation(
    route,
    planState,
    wizardState,
    planRecommendationItem,
    deliveryCadence,
    flowType
  )
  Analytics.track('Plans Flow Frequency Option Selected', { ...flowData })
}

const triggerAnalytics = (
  route: Route,
  planState: PendingSubscription,
  wizardState: WizardState | null,
  planRecommendationItem: PlanRecommendation | null,
  deliveryCadence: 'short' | 'long' | 'default',
  flowType: FlowType
): void => {
  if (isNull(wizardState)) return

  const flowData = getFlowInformation(
    route,
    planState,
    wizardState,
    planRecommendationItem,
    deliveryCadence,
    flowType
  )
  const numberOfSelectedRecipes =
    planState && planState.flavours && planState.flavours.length
  const numberOfRecommendedRecipes =
    planRecommendationItem &&
    planRecommendationItem.combinedRecommendedFlavours.length
  const numberOfNonRecommendedRecipes =
    planRecommendationItem &&
    planRecommendationItem.combinedNotRecommendedFlavours.length
  const addedExtraProducts =
    planState.productVariants &&
    planState.productVariants.map(
      (productVariant) => productVariant.productCollection.name
    )
  const idealGramsAmount =
    planRecommendationItem && planRecommendationItem.idealGramsAmount

  const pageLoadProperties: PageLoadProperties = {
    ...flowData,
    numberOfSelectedRecipes,
    numberOfRecommendedRecipes,
    numberOfNonRecommendedRecipes,
    addedExtraProducts,
    idealGramsAmount
  }

  switch (route) {
    case '/plans/recipes': {
      trackPageLoad(pageLoadProperties)
      break
    }
    case '/plans/plan': {
      trackPageLoad(pageLoadProperties)
      break
    }
    case '/plans/extras': {
      trackPageLoad(pageLoadProperties)
      break
    }
    case '/plans/review': {
      trackPageLoad(pageLoadProperties)
      break
    }
    default: {
      ensureNever(route)
      Sentry.captureException(
        `Cannot call triggerAnalytics with route of: ${route}`,
        {
          tags: {
            product: Sentry.Product.PlansFlow
          }
        }
      )
    }
  }
}

export {
  trackPageLoad,
  triggerAnalytics,
  trackRecipeSelectionToggled,
  trackRecipeMoreInfoClicked,
  trackFaqClicked,
  trackExtrasProductAdded,
  trackExtrasProductRemoved,
  trackExtrasSeen,
  trackExtrasCarouselChange,
  trackCarouselInteractionAnalytics,
  trackNextButtonClicked,
  trackBackButtonClicked,
  trackReviewTabToggled,
  trackSharedReviewTabToggled,
  trackPlanTypeSelected,
  trackPlanFrequencySelected,
  trackManagePlanManuallyClicked,
  trackReviewNewPlanClicked,
  trackStayOnOldPlanClicked,
  trackUpdatePlanClicked,
  trackAdditionalProductMoreInfoClicked,
  Action
}
