// @flow

import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Assets
import CloseIcon from 'assets/images/icons/crosses/close-icon.svg'
import Chevron from '@/components/shared/images/Chevron'

// Utils
import BRAND_COLOURS from '@/constants/BrandColours'

type SharedProps = {|
  showMyAccountButton: boolean
|}

type BackButtonAction = {|
  backAction: 'back',
  handleOnClickBack?: () => void,
  ...SharedProps
|}

type CloseButtonAction = {|
  backAction: 'close',
  closeButtonLink: string,
  ...SharedProps
|}

type NoneButtonAction = {|
  backAction: 'none',
  ...SharedProps
|}

type PropertyBackButtonAction = $PropertyType<BackButtonAction, 'backAction'>
type PropertyCloseButtonAction = $PropertyType<CloseButtonAction, 'backAction'>
type PropertyNoneButtonAction = $PropertyType<NoneButtonAction, 'backAction'>

type BackActionProps = {|
  backAction: PropertyBackButtonAction | PropertyCloseButtonAction | PropertyNoneButtonAction,
  closeButtonLink: $PropertyType<CloseButtonAction, 'closeButtonLink'>,
  handleOnClickBack?: $PropertyType<BackButtonAction, 'handleOnClickBack'>
|}

type Props =
  | BackButtonAction
  | CloseButtonAction
  | NoneButtonAction

/**
 * A Navigation bar which can have "close", "go back" or go to the "account"
 * actions.
 *
 * Example of usage:
 *
 * Option 1: if  backAction is 'close' then 'closeButtonLink' property is required.
 *
 * <DashboardWizardNav
 *  backAction='close'
 *  closeButtonLink='/dashboard'
 *  showMyAccountButton
 * />
 *
 * Option 2: if  backAction is 'back' then 'closeButtonLink' property if forbidden.
 * Additionally there is a 'handleOnClickBack' property to fire a method on back
 * button click.
 *
 * <DashboardWizardNav
 *  backAction='back'
 *  showMyAccountButton
 * />
 *
 * Option 3: if  backAction is 'none' then action button is hidden. We could have
 * optional 'backAction' instead of 'none', but this option is needed to have better
 * types.
 *
 * <DashboardWizardNav
 *  backAction='none'
 *  showMyAccountButton={false}
 * />
 *
 */
const BackAction = ({
  backAction,
  closeButtonLink,
  handleOnClickBack
}: BackActionProps): React.Element<'button' | 'a' | 'div'> => {
  const navigate = useNavigate()

  const handleGoBackAction = React.useCallback((): void => {
    if (handleOnClickBack) {
      handleOnClickBack()
    }
    if (navigate) {
      navigate(-1)
    }
  }, [handleOnClickBack, navigate])

  const { t } = useTranslation('dashboard')

  switch (backAction) {
    case 'back': {
      return (
        <button
          className='nav__content__action nav__content__action--back'
          onClick={handleGoBackAction}
          type='button'
        >
          <Chevron
            color={BRAND_COLOURS.brandBlue500}
            rotation={180}
          />
          <span>
            { t('navigation.back') }
          </span>
        </button>
      )
    }
    case 'close': {
      return (
        <a
          className='nav__content__action nav__content__action--close'
          href={closeButtonLink}
        >
          <img
            alt={t('navigation.close_img_alt')}
            src={CloseIcon}
          />
        </a>
      )
    }
    case 'none': {
      return <div />
    }
    default: {
      (backAction: empty)
      return <div />
    }
  }
}

const DashboardWizardNav = ({
  backAction,
  closeButtonLink = '/',
  showMyAccountButton,
  handleOnClickBack = (): void => {}
}: Props): React.Element<'nav'> => {
  const { t } = useTranslation('dashboard')
  return (
    <nav className='nav'>
      <div className='nav__content'>
        <BackAction
          backAction={backAction}
          closeButtonLink={closeButtonLink}
          handleOnClickBack={handleOnClickBack}
        />
        {
          showMyAccountButton && (
            <a
              className='btn-updated btn-updated--secondary'
              href='/dashboard'
            >
              { t('navigation.my_account') }
            </a>
          )
        }
      </div>
    </nav>
  )
}

export type { Props }

export default DashboardWizardNav
