// @noflow

/* eslint-disable jsx-a11y/label-has-for */

/* eslint-disable react/jsx-no-useless-fragment */
import { format } from 'date-fns'
import i18next from 'i18next'
import React, {
  ForwardedRef,
  forwardRef,
  useCallback,
  useContext,
  useMemo
} from 'react'

import { localeToDateLocale } from '@/utils/countryCodeHelper'

import useBoolean from '@/hooks/useBoolean'

import segmentTrack from '@/components/analytics/Analytics'
import { Expand } from '@/components/elements/atoms/Animated/Animated'
// Component
import Card from '@/components/elements/atoms/Card/Card'
import CloudinaryImage from '@/components/elements/atoms/CloudinaryImage/CloudinaryImage'
import Icon from '@/components/elements/atoms/Icon/Icon'
import Label from '@/components/elements/atoms/Label/Label'
import Text from '@/components/elements/atoms/Text/Text'
import AddDiscountedExtrasCountdownCard from '@/components/elements/molecules/AddDiscountedExtrasCountdownCard'
import LabelList from '@/components/elements/molecules/LabelList/LabelList'
// Hooks
import { NavigateContext } from '@/components/pages/App'

import STYLES from './OrderCard.module.sass'

import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'
import { Code, Language, NonPaymentReason } from '@/types'

import Tour, { TourContent } from '../Tour/Tour'

type PhysicalOrderProduct = {
  nonPaymentReason: NonPaymentReason | null
  quantity: number
  productVariant: {
    productCollection: {
      thumbnail: {
        src: string
      }
    }
  }
}

type Props = {
  isFreshMeal: boolean
  extrasCount: number
  isReplacementBox?: boolean
  isFree?: boolean
  deliveryDate: Date
  cutOffDate?: Date
  isAmendable: boolean
  discountPercentage?: number
  link: string
  onClickAnalytics: (title: string, link: string) => void
  deliveryCopy: string
  shippingCountryCode: CountryCode
  isBoxDelivered: boolean
  extrasImages: (string | undefined)[]
  isNextEditableBox: boolean
  sampleOrderProducts: Array<PhysicalOrderProduct>
  showExtrasInShippingCountry: boolean
  shouldSeeRecipeSurchargePriceIncreasePl?: boolean
}

const MAX_PRODUCT_THUMBNAILS_TO_SHOW = 4

const OrderCard = forwardRef(
  (
    {
      isFreshMeal,
      extrasCount,
      isReplacementBox,
      deliveryDate,
      cutOffDate,
      isAmendable,
      discountPercentage,
      link,
      onClickAnalytics,
      deliveryCopy,
      isFree,
      shippingCountryCode,
      isBoxDelivered,
      extrasImages,
      isNextEditableBox,
      sampleOrderProducts,
      showExtrasInShippingCountry,
      shouldSeeRecipeSurchargePriceIncreasePl
    }: Props,
    ref: ForwardedRef<HTMLDivElement>
  ): JSX.Element => {
    const navigate = useContext(NavigateContext)
    const copyContext = 'dashboard:upcoming_box_card'
    const dateLocale = localeToDateLocale(
      shippingCountryCode,
      i18next.language as Language
    )

    const {
      value: priceIncreaseAlertExpanded,
      toggle: togglePriceIncreaseAlert
    } = useBoolean(false)

    const handleTogglePriceIncreaseAlertOnKeyDown = useCallback(
      (event) => {
        if (event.key === 'Enter') {
          togglePriceIncreaseAlert()
        }
      },
      [togglePriceIncreaseAlert]
    )

    const onClickCallback = React.useCallback((): void => {
      isFreshMeal
        ? onClickAnalytics('Standard box clicked', link)
        : onClickAnalytics('On Demand Extra box clicked', link)

      // eslint-disable-next-line i18next/no-literal-string
      navigate(link, link)
    }, [isFreshMeal, onClickAnalytics, link, navigate])

    const handlePlusIconClick = React.useCallback(
      (e) => {
        e.preventDefault()
        e.stopPropagation()
        isFreshMeal
          ? segmentTrack('Standard box clicked', {
              trigger: 'Plus Icon clicked'
            })
          : segmentTrack('On Demand Extra box clicked', {
              trigger: 'Plus Icon clicked'
            })
        window.location.href = '/dashboard/extras'
      },
      [isFreshMeal]
    )

    const handleProductThumbnailClick = React.useCallback(
      (e) => {
        e.preventDefault()
        e.stopPropagation()
        isFreshMeal
          ? segmentTrack('Standard box clicked', {
              trigger: 'Product Thumbnail clicked'
            })
          : segmentTrack('On Demand Extra box clicked', {
              trigger: 'Product Thumbnail clicked'
            })
        // eslint-disable-next-line i18next/no-literal-string
        window.location.href = `${link}#extras`
      },
      [isFreshMeal, link]
    )

    const handleSampleOnClick = React.useCallback(
      (e) => {
        e.preventDefault()
        e.stopPropagation()
        // eslint-disable-next-line i18next/no-literal-string
        window.location.href = `${link}#extras`
      },
      [link]
    )

    const showPromptToAddExtras =
      !isBoxDelivered && isNextEditableBox && showExtrasInShippingCountry

    const showPriceIncreaseAlert = useMemo(() => {
      const changeDate = new Date('October 30, 2023 00:00:00')

      return (
        shouldSeeRecipeSurchargePriceIncreasePl &&
        changeDate <= deliveryDate &&
        !isFree &&
        isFreshMeal &&
        shippingCountryCode === 'PL'
      )
    }, [
      deliveryDate,
      isFree,
      isFreshMeal,
      shippingCountryCode,
      shouldSeeRecipeSurchargePriceIncreasePl
    ])

    React.useEffect(() => {
      if (sampleOrderProducts.length > 0 && isNextEditableBox) {
        segmentTrack('Seen free sample in deliveries carousel')
      }
    }, [sampleOrderProducts, isNextEditableBox])

    return (
      <>
        <div
          id={isNextEditableBox ? 'nextEditableBox' : undefined}
          className={STYLES.wrapper}
        >
          {/* NOTE: the following will be replaced shortly */}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <a className={STYLES.card} onClick={onClickCallback}>
            <Card shadow>
              <div className={STYLES.container}>
                <div className={STYLES.title}>
                  <div className={STYLES.date}>
                    <Text
                      variant="display16"
                      colour="brandBlue500"
                      text={format(deliveryDate, 'EEE', { locale: dateLocale })}
                      margin={false}
                      translate={false}
                    />
                    <Text
                      variant="display16"
                      colour="brandBlue500"
                      text={format(deliveryDate, 'd', { locale: dateLocale })}
                      margin={false}
                      translate={false}
                    />
                    <Text
                      variant="display16"
                      colour="brandBlue500"
                      text={format(deliveryDate, 'MMM', { locale: dateLocale })}
                      variables={{ date: deliveryDate }}
                      margin={false}
                      translate={false}
                    />
                  </div>
                  <div className={STYLES.editOrder} ref={ref}>
                    <Text
                      text={
                        isAmendable
                          ? `${copyContext}.box_detail_messaging.edit_order`
                          : `${copyContext}.box_detail_messaging.view_order`
                      }
                      margin={false}
                      variant="textRegular16"
                      shouldScale={false}
                      element="span"
                    />
                    <Icon
                      asset="chevron"
                      size={11}
                      accentColour="brandBlue500"
                    />
                  </div>
                </div>
                <div className={STYLES.deliveryCopy}>
                  {isBoxDelivered && (
                    <Icon
                      accentColour="successGreen300"
                      asset="checkmark"
                      size={16}
                      width={16}
                    />
                  )}
                  <Text
                    variant="textRegular14"
                    colour="brandBlue500"
                    text={deliveryCopy}
                    variables={{ date: cutOffDate }}
                    margin={false}
                  />
                </div>
                <LabelList>
                  {isReplacementBox && (
                    <Label
                      variant="replacement"
                      text={{
                        text: `${copyContext}.box_type.replacement_box`
                      }}
                    />
                  )}
                  {isFreshMeal && (
                    <Label
                      variant="freshMeal"
                      text={{
                        text: `${copyContext}.box_type.standard_box`
                      }}
                    />
                  )}
                  <Label
                    variant="extra"
                    text={{
                      text: `${copyContext}.box_type.extras_${
                        extrasCount === 1 ? 'singular' : 'plural'
                      }`,
                      variables: { number: extrasCount }
                    }}
                  />
                  {discountPercentage ? (
                    <Label
                      variant="discount"
                      text={{
                        text: `${copyContext}.box_detail_messaging.${
                          isFree ? 'free' : 'discount'
                        }`,
                        variables: { discount: discountPercentage }
                      }}
                    />
                  ) : (
                    <React.Fragment />
                  )}
                </LabelList>
                {extrasCount > 0 && (
                  <div className={STYLES.productImages}>
                    {extrasImages.map(
                      (image, index) =>
                        image && (
                          <div
                            key={`${image}-${index + 1}`}
                            className={`${STYLES.productImage} ${
                              extrasCount > MAX_PRODUCT_THUMBNAILS_TO_SHOW
                                ? STYLES.moreItems
                                : ''
                            }`}
                            data-count={`+${
                              extrasCount - MAX_PRODUCT_THUMBNAILS_TO_SHOW
                            }`}
                            onClick={handleProductThumbnailClick}
                            onKeyDown={handleProductThumbnailClick}
                            role="button"
                            tabIndex={0}
                          >
                            <CloudinaryImage
                              alt=""
                              image={{
                                path: image,
                                crop: 'fill',
                                height: 120,
                                width: 120,
                                dpr: window.devicePixelRatio
                              }}
                            />
                          </div>
                        )
                    )}
                    {extrasCount < MAX_PRODUCT_THUMBNAILS_TO_SHOW &&
                      extrasImages.length > 0 &&
                      showPromptToAddExtras && (
                        <div
                          onClick={handlePlusIconClick}
                          onKeyDown={handlePlusIconClick}
                          role="button"
                          tabIndex={0}
                          className={STYLES.emptySlot}
                        >
                          <Icon
                            asset="plus"
                            size={14}
                            accentColour="brandBlue400"
                          />
                        </div>
                      )}
                  </div>
                )}
                {extrasCount === 0 && showPromptToAddExtras && (
                  <AddDiscountedExtrasCountdownCard
                    handlePlusIconClick={handlePlusIconClick}
                  />
                )}
                {sampleOrderProducts.length > 0 &&
                  i18next.language === Language.en &&
                  sampleOrderProducts.map((sample) => {
                    const { productVariant } = sample
                    const { productCollection } = productVariant
                    const { thumbnail } = productCollection

                    return (
                      <div
                        key={thumbnail.src}
                        className={STYLES.sampleCardContainer}
                      >
                        <button type="button" onClick={handleSampleOnClick}>
                          <Card
                            variant="brandYellow100"
                            shadow={false}
                            padding={8}
                            border="solid"
                          >
                            <div className={STYLES.sampleCard}>
                              <CloudinaryImage
                                alt=""
                                image={{
                                  path: thumbnail.src,
                                  crop: 'fill',
                                  height: 60,
                                  width: 60,
                                  dpr: window.devicePixelRatio
                                }}
                              />
                              <Text
                                text={`Your <accent type='bold'>free treat</accent> has been added to this box`}
                                translate={false}
                                margin={false}
                                variant="textRegular16"
                                shouldScale
                                colour="brandBlue500"
                                align="left"
                              />
                            </div>
                          </Card>
                        </button>
                      </div>
                    )
                  })}
              </div>
            </Card>
          </a>
        </div>
        {shippingCountryCode === Code.PL && isNextEditableBox && (
          <Tour
            uid="order-card-tour"
            hideBeacon={false}
            steps={[
              {
                content: (
                  <TourContent
                    title={{
                      text: 'home.tour.box_details.title',
                      namespace: 'account'
                    }}
                    description={{
                      text: 'home.tour.box_details.description',
                      namespace: 'account'
                    }}
                  />
                ),
                target: '#nextEditableBox',
                placement: 'auto',
                placementBeacon: 'top-start'
              }
            ]}
            scrollOffset={300}
          />
        )}
        {showPriceIncreaseAlert && (
          <div
            role="button"
            tabIndex={0}
            onClick={togglePriceIncreaseAlert}
            onKeyDown={handleTogglePriceIncreaseAlertOnKeyDown}
            className={STYLES.priceIncreaseAlert}
          >
            <div className={STYLES.priceIncreaseAlertHeader}>
              <Text
                text={`${copyContext}.price_increase_alert.title`}
                variant={'textRegular14'}
                margin={false}
              />
              <Icon asset="question" size={26} width={26} />
            </div>
            <Expand origin="top" show={priceIncreaseAlertExpanded}>
              <Text
                text={`${copyContext}.price_increase_alert.description`}
                variant={'textRegular14'}
                margin={false}
              />
            </Expand>
          </div>
        )}
      </>
    )
  }
)

export type { Props }
export default OrderCard
export { MAX_PRODUCT_THUMBNAILS_TO_SHOW }
