//  @noflow
import type { Language } from '@/packs/localisation'
import { ACCOUNT_ROUTES } from '@/routes'
import times from 'lodash/times'
import React, { Fragment } from 'react'

import SkeletonTitle from '@/components/elements/atoms/SkeletonTitle/SkeletonTitle'
import Text from '@/components/elements/atoms/Text/Text'
import ProductCard from '@/components/elements/molecules/ProductCard/ProductCard'

import STYLES from './CategoryList.module.sass'

import type { ExtrasListQuery_productGroups } from '../../queries/__generated__/ExtrasListQuery'
import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'

type Props = {
  categoryGroup: ExtrasListQuery_productGroups | null
  shippingCountryCode?: CountryCode
  preferredLanguage?: Language
}

const CategoryList = ({
  categoryGroup,
  shippingCountryCode,
  preferredLanguage
}: Props): JSX.Element => {
  const productCollections =
    categoryGroup?.productCollections || times(8, () => null)
  return (
    <div className={STYLES.container}>
      <div className={STYLES.title}>
        {categoryGroup?.name ? (
          <Text
            text={categoryGroup?.name}
            translate={false}
            margin={false}
            variant="display28"
            colour="brandBlue500"
          />
        ) : (
          <SkeletonTitle
            align="left"
            width={250}
            height={36}
            margin=".5rem 0"
          />
        )}
      </div>
      <div className={STYLES.productsWrapper}>
        {productCollections.map((product, i) => {
          return (
            <Fragment key={product?.name || `skeleton-${i}`}>
              <ProductCard
                product={product}
                shippingCountryCode={shippingCountryCode}
                preferredLanguage={preferredLanguage}
                url={`${ACCOUNT_ROUTES.extras}/${product?.slug}`}
                linkType="link"
              />
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}

export default CategoryList
