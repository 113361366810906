// @noflow
import { addMonths, isBefore } from 'date-fns'
import { isNull } from 'lodash'
import { useCallback, useMemo } from 'react'

import useLocalStorage from '../useLocalStorage'

type TourViewedDetails = TourStorage & {
  hasTourBeenCompleted: boolean
  isTourActive: boolean
  hasTourBeenViewed: boolean
  updateTourViewed: (completed: boolean) => void
}

type TourStorage = {
  tourFirstViewed: string | null
  completed: boolean
}

const useTourViewedDetails = (tourId: string): TourViewedDetails => {
  // eslint-disable-next-line i18next/no-literal-string
  const tourKey = useMemo(() => `${tourId}-tour-viewed-props`, [tourId])

  const [
    tourStorage,
    setTourStorage
    // eslint-disable-next-line i18next/no-literal-string
  ] = useLocalStorage<string, TourStorage>(tourKey, {
    tourFirstViewed: null,
    completed: false
  })

  const hasTourBeenCompleted = useMemo((): boolean => {
    return !!tourStorage.completed
  }, [tourStorage.completed])

  const isTourActive = useMemo((): boolean => {
    return (
      isNull(tourStorage.tourFirstViewed) ||
      isBefore(new Date(), addMonths(new Date(tourStorage.tourFirstViewed), 1))
    )
  }, [tourStorage.tourFirstViewed])

  const hasTourBeenViewed = useMemo((): boolean => {
    return !isNull(tourStorage.tourFirstViewed)
  }, [tourStorage.tourFirstViewed])

  const updateTourViewed = useCallback(
    (completed: boolean) => {
      setTourStorage({
        completed,
        tourFirstViewed: tourStorage.tourFirstViewed ?? new Date().toISOString()
      })
    },
    [setTourStorage, tourStorage.tourFirstViewed]
  )

  return {
    ...tourStorage,
    hasTourBeenCompleted,
    isTourActive,
    hasTourBeenViewed,
    updateTourViewed
  }
}

export default useTourViewedDetails
