// @noflow

/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useEffect, useState } from 'react'

import Interactive from '@/components/elements/atoms/Interactive/Interactive'
// Component
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './RafTabBar.module.sass'

type Tab = {
  label: string
  value: string
  hidden?: boolean
  translate?: boolean
}

type Align = 'left' | 'right' | 'center'

type Props = {
  setTab?: (value: string) => void
  tabSelected?: string
  align?: Align
  tabs: Array<Tab>
  namespace: string
}

const RafTabBar = ({
  setTab,
  tabs,
  tabSelected,
  align = 'center',
  namespace
}: Props): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState('')

  useEffect(() => {
    if (tabSelected) {
      setSelectedTab(tabSelected)
    }
  }, [tabSelected])

  const handleClick = useCallback(
    (value: string) => {
      if (setTab) {
        setTab(value)
      }
      setSelectedTab(value)
    },
    [setTab]
  )
  return (
    <div className={STYLES.container}>
      <div className={`${STYLES.wrapper} ${STYLES[align]}`}>
        {tabs
          .filter((tab) => !tab.hidden)
          .map((tab): JSX.Element => {
            return (
              <Interactive
                key={tab.value}
                onClick={() => handleClick(tab.value)}
                element="button"
                className={`${STYLES.tabWrapper} ${
                  selectedTab === tab.value && STYLES.selected
                }`}
                selected={selectedTab === tab.value}
              >
                <Text
                  namespace={namespace}
                  text={tab.label}
                  variant="textRegular16"
                  colour={
                    selectedTab === tab.value ? 'brandBlue500' : 'brandBlue400'
                  }
                  margin={false}
                  translate={tab.translate}
                />
              </Interactive>
            )
          })}
      </div>
    </div>
  )
}

export type { Props }
export default RafTabBar
