// @noflow
import { useQuery } from '@apollo/client'
import times from 'lodash/times'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useButternutBoost from '@/hooks/useButternutBoost'

import comingSoon from 'assets/images/illustrations/extras/shop-empty-state-illustration.svg'

import { Portal } from '@/components/elements/atoms/Portal'
import Text from '@/components/elements/atoms/Text/Text'
import BoostBanner from '@/components/elements/molecules/BoostBanner/BoostBanner'
import { ErrorState } from '@/components/elements/organisms/ErrorState'
import CategoryList from '@/components/pages/ExtrasPage/screens/ExtrasList/components/CategoryList/CategoryList'
import CategoryNavBar from '@/components/pages/ExtrasPage/screens/ExtrasList/components/CategoryNavBar'
import useQueryParam from '@/components/pages/SelfResolutionPage/helpers/useQueryParam'

import STYLES from './ExtrasList.module.sass'

import { EXTRAS_LIST_QUERY } from './queries/extrasListQuery'

import type { ExtrasListQuery } from './queries/__generated__/ExtrasListQuery'
import { MembershipTypes } from '@/types'

const ComingSoon = (): JSX.Element => {
  const namespace = 'dashboard'
  const copyContext = 'extras.coming_soon'
  const { t } = useTranslation(namespace)

  return (
    <div className={STYLES.comingSoon}>
      <img
        className={STYLES.comingSoonImage}
        src={comingSoon}
        alt={t(`${copyContext}.coming_soon_img_alt`)}
      />
      <div className={STYLES.comingSoonInfo}>
        <Text
          text={`${copyContext}.title`}
          namespace={namespace}
          margin={false}
          variant="display24"
          colour="brandBlue500"
          align="center"
        />
        <Text
          text={`${copyContext}.sub_title`}
          namespace={namespace}
          margin={false}
          variant="textRegular16"
          colour="brandBlue500"
          align="center"
        />
      </div>
    </div>
  )
}

const ExtrasList = (): JSX.Element | null => {
  const { boostedSubscription, boostedType } = useButternutBoost()

  const [selectedCategory, setSelectedCategory] = useState<string>('all')
  const { loading, data, error } = useQuery<ExtrasListQuery>(EXTRAS_LIST_QUERY)
  const slug = useQueryParam('slug') || null

  useEffect(() => {
    if (data && slug) {
      const filteredSlugData = data.productGroups.filter(
        (product) => product.slug === slug
      )
      if (filteredSlugData.length > 0) {
        setSelectedCategory(slug)
      }
    }
  }, [data, slug])

  const productGroupsWithProductCollections = useMemo(() => {
    if (data) {
      return data.productGroups.filter(
        (productGroup) => productGroup.productCollections.length > 0
      )
    }
  }, [data])

  const filteredCategoryGroup = useMemo(() => {
    if (data) {
      if (selectedCategory === 'all') return productGroupsWithProductCollections
      return data.productGroups.filter(
        (product) => product.slug === selectedCategory
      )
    } else {
      return times(3, () => null)
    }
  }, [data, productGroupsWithProductCollections, selectedCategory])

  useEffect(() => {
    window.analytics.track('Shop Entered', {
      properties: {
        entryPoint: document.referrer
      }
    })
  }, [])

  const selectCategory = useCallback(
    (value: string): void => {
      window.analytics.track('Shop: Interacted With Product Group Bar', {
        productGroupSlug: value
      })
      setSelectedCategory(value)
    },
    [setSelectedCategory]
  )

  const hasExtrasToDisplay =
    loading ||
    (productGroupsWithProductCollections &&
      productGroupsWithProductCollections.length > 0)

  if (error) {
    return (
      <ErrorState
        error={{
          name: 'Error retrieving extras data',
          message: error.message,
          apollo: error
        }}
        first_line={{
          namespace: 'shared',
          text: 'error_page.text_1'
        }}
        second_line={{
          namespace: 'shared',
          text: 'error_page.text_2'
        }}
      />
    )
  }

  const {
    user: { shippingCountryCode, preferredLanguage },
    productGroups
  } = data || { user: {}, productGroups: [] }

  return (
    <div className={STYLES.container}>
      <div className={STYLES.heroContainerWrapper}>
        <Portal
          element={
            <CategoryNavBar
              categorySelected={selectedCategory}
              setCategory={selectCategory}
              productGroups={productGroups}
              loading={loading}
            />
          }
          target="sub-header-content"
        />
      </div>

      {boostedSubscription && boostedType === MembershipTypes.boost && (
        <BoostBanner loading={loading} />
      )}

      <div className={STYLES.wrapper}>
        {hasExtrasToDisplay ? (
          filteredCategoryGroup?.map((product, i) => {
            return (
              <CategoryList
                key={product?.name || i}
                categoryGroup={product}
                shippingCountryCode={shippingCountryCode}
                preferredLanguage={preferredLanguage}
              />
            )
          })
        ) : (
          <ComingSoon />
        )}
      </div>
    </div>
  )
}

export { ExtrasList, EXTRAS_LIST_QUERY }

export default ExtrasList
