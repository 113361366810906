import { gql } from '@apollo/client'

const SHIPPING_COUNTRY_FRAGMENT = gql`
  fragment shippingCountryFragment on ShippingCountry {
    active
    availableLanguages
    code
    currency
    defaultLanguage
    domain
    id
    name
    scaEnforced
    showExtras
    trustpilotBusinessUnitId
  }
`
export { SHIPPING_COUNTRY_FRAGMENT }
