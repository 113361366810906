// @noflow
import { makeVar } from '@apollo/client'
import { useCallback, useMemo } from 'react'

type SliderHookData = {
  slide: number | 'first' | 'last' | 'next' | 'prev'
  id: string
} | null

const SliderHookVar = makeVar<SliderHookData>(null)

type SliderHook = {
  slideTo: (index: number) => void
  slideToFirst: () => void
  slideToLast: () => void
  slideNext: () => void
  slidePrev: () => void
}

const useSlider = (id: string): SliderHook => {
  const slideTo = useCallback(
    (index: number) => {
      SliderHookVar({
        id,
        slide: index
      })
    },
    [id]
  )

  const slideToFirst = useCallback(() => {
    SliderHookVar({
      id,
      slide: 'first'
    })
  }, [id])

  const slideToLast = useCallback(() => {
    SliderHookVar({
      id,
      slide: 'last'
    })
  }, [id])

  const slideNext = useCallback(() => {
    SliderHookVar({
      id,
      slide: 'next'
    })
  }, [id])

  const slidePrev = useCallback(() => {
    SliderHookVar({
      id,
      slide: 'prev'
    })
  }, [id])

  return useMemo<SliderHook>(
    () => ({
      slideTo,
      slideToFirst,
      slideToLast,
      slideNext,
      slidePrev
    }),
    [slideTo, slideToFirst, slideToLast, slideNext, slidePrev]
  )
}

export { SliderHookVar }
export default useSlider
