// @noflow
import { gql } from '@apollo/client'

const PET_CARE_SECTION_DOGS_FRAGMENT = gql`
  fragment PetCareSectionDogsFragment on Dog {
    id
    name
    breed {
      id
      name
    }
    ageInMonths
    dogProfile {
      id
      avatarUrl
    }
  }
`

export { PET_CARE_SECTION_DOGS_FRAGMENT }
