// @noflow
import isNull from 'lodash/isNull'

import * as Sentry from '@/utils/sentry'

// eslint-disable-next-line no-restricted-imports
import { PendingSubscription } from '@/components/types'

import { ensureNever } from '@/typescript/utils'

import type { Route } from '../types/routes'

const routeToNextStep = (route: Route): Route => {
  switch (route) {
    case '/plans/recipes': {
      return '/plans/plan'
    }
    case '/plans/plan': {
      return '/plans/extras'
    }
    case '/plans/extras': {
      return '/plans/review'
    }
    case '/plans/review': {
      return '/plans/review'
    }
    default: {
      ensureNever(route)
      Sentry.captureException(
        `Cannot generate routeToNextStep for route of: ${route}`,
        {
          tags: {
            product: Sentry.Product.PlansFlow
          }
        }
      )
      return '/plans/plan'
    }
  }
}

const routeToPrevStep = (route: Route): Route => {
  switch (route) {
    case '/plans/recipes': {
      return '/plans/recipes'
    }
    case '/plans/plan': {
      return '/plans/recipes'
    }
    case '/plans/extras': {
      return '/plans/plan'
    }
    case '/plans/review': {
      return '/plans/extras'
    }
    default: {
      ensureNever(route)
      Sentry.captureException(
        `Cannot generate routeToPrevStep for route of: ${route}`,
        {
          tags: {
            product: Sentry.Product.PlansFlow
          }
        }
      )
      return '/plans/recipes'
    }
  }
}

const isButtonDisabled = (
  route: Route,
  pendingSubscription: PendingSubscription
): boolean => {
  switch (route) {
    case '/plans/recipes':
      return (
        isNull(pendingSubscription.flavours) ||
        (Array.isArray(pendingSubscription.flavours) &&
          pendingSubscription.flavours.length === 0)
      )
    case '/plans/plan':
      return isNull(pendingSubscription.plan)
    case '/plans/extras':
    case '/plans/review': {
      return false
    }
    default: {
      ensureNever(route)
      Sentry.captureException(
        `Cannot generate isButtonDisabled for route of: ${route}`,
        {
          tags: {
            product: Sentry.Product.PlansFlow
          }
        }
      )
      return true
    }
  }
}

const routeToButtonTwoText = (route: Route): string => {
  const copyContext = 'plan_steps.navigation'

  switch (route) {
    case '/plans/recipes': {
      // eslint-disable-next-line i18next/no-literal-string
      return `${copyContext}.recipes`
    }
    case '/plans/plan': {
      // eslint-disable-next-line i18next/no-literal-string
      return `${copyContext}.plan`
    }
    case '/plans/extras': {
      // eslint-disable-next-line i18next/no-literal-string
      return `${copyContext}.extras`
    }
    case '/plans/review': {
      // eslint-disable-next-line i18next/no-literal-string
      return `${copyContext}.review`
    }
    default: {
      ensureNever(route)
      Sentry.captureException(
        `Cannot generate routeToButtonOneText for route of: ${route}`,
        {
          tags: {
            product: Sentry.Product.PlansFlow
          }
        }
      )
      // eslint-disable-next-line i18next/no-literal-string
      return `${copyContext}.recipes`
    }
  }
}

export {
  routeToNextStep,
  routeToPrevStep,
  isButtonDisabled,
  routeToButtonTwoText
}
