// @flow

import * as ACTIONS from '../actions'

import getShippingDetailsQuery from '../rails_queries/getShippingDetails'

// Types
import type { Dispatch, Thunk } from 'redux'
import type { RequestResult } from '../rails_queries/getShippingDetails'
import type { RailsModelID } from '@/shared_types/ids'

const getShippingDetails = (userId: RailsModelID): Thunk => {
  return (dispatch: Dispatch): Promise<void> => {
    dispatch(ACTIONS.startGetShippingDetailsRequest())
    return getShippingDetailsQuery({
      userId
    }).then((requestResult: RequestResult): void => {
      switch (requestResult.type) {
        case 'ServerError': {
          dispatch(ACTIONS.receiveShippingDetailsResponse())
          throw new Error(`Server Error: status=${requestResult.status}, message=${requestResult.message}`)
        }
        case 'UnknownError': {
          dispatch(ACTIONS.receiveShippingDetailsResponse())
          throw new Error(requestResult.error)
        }
        case 'Success': {
          dispatch(ACTIONS.receiveShippingDetailsResponse(requestResult.data))
          break
        }
        default: {
          throw new Error(`getShippingDetails: unhandled type ${requestResult.type}`)
        }
      }
    })
  }
}

export default getShippingDetails
