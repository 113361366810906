// @noflow
import React from 'react'
import { useTranslation } from 'react-i18next'

import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import { SelectableCardGroupV2 } from '@/components/elements/molecules/SelectableCardGroup'

import type { SharedInputProps } from '../../UpdateDogWeightAndAppetiteContent'

type Props = SharedInputProps & {
  exercise: string
}

const Activity = ({
  exercise,
  margin = { top: 8, bottom: 0 },
  onSelect,
  loading
}: Props): JSX.Element => {
  const { t } = useTranslation('account')
  const copyContext = 'update_dog_profile_weight_and_appetite'

  const ACTIVITY_LEVELS = [
    {
      value: 'low',
      icon: {
        slug: 'activity-level-low',
        alt: t(`${copyContext}.activity_level.low.heading`),
        image: {
          width: 75,
          height: 60
        }
      },
      identifier: `${copyContext}.activity_level.low`
    },
    {
      value: 'normal',
      icon: {
        slug: 'activity-level-normal',
        alt: t(`${copyContext}.activity_level.normal.heading`),
        image: {
          width: 80,
          height: 45
        }
      },
      identifier: `${copyContext}.activity_level.normal`
    },
    {
      value: 'hyper',
      icon: {
        slug: 'activity-level-hyper',
        alt: t(`${copyContext}.activity_level.hyper.heading`),
        image: {
          width: 80,
          height: 41
        }
      },
      identifier: `${copyContext}.activity_level.hyper`
    }
  ]

  return (
    <SectionWrapper margin={margin}>
      <SelectableCardGroupV2
        options={ACTIVITY_LEVELS}
        onSelect={onSelect}
        currentValue={exercise}
        cardProps={{
          skeleton: {
            isLoading: loading,
            height: '11rem'
          }
        }}
        descriptionCard={{
          title: {
            namespace: 'account',
            text: `${copyContext}.activity_level.${exercise}.heading`
          },
          description: {
            namespace: 'account',
            text: `${copyContext}.activity_level.${exercise}.text`
          }
        }}
      />
    </SectionWrapper>
  )
}

export { Activity }
