import { gql } from '@apollo/client'

const SHIPPING_COUNTRIES = gql`
  query ShippingCountries {
    settings(names: ["marketing_opt_in_default"]) {
      value
      name
    }
    shippingCountries {
      id
      name
      code
      currency
      active
      scaEnforced
    }
    availableMarketingMethodPurposes {
      id
      marketingPurpose {
        id
        key
        name
        encouraged
      }
    }
  }
`
export { SHIPPING_COUNTRIES }
