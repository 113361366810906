// @noflow

enum Routes {
  PetParent = '/plans/pet-parent',
  Recipes = '/plans/recipes',
  Plan = '/plans/plan',
  Extras = '/plans/extras',
  // Whilst Checkout isn't part of the actual Plans Flow routing, we include it
  // visually in the Step counter in the Hero of the Plans Flow, so it's been
  // defined here for consisntency when used in helpers related to the Plans
  // Flow navigation
  Checkout = '/checkout'
}

export { Routes }
