// @flow

import * as React from 'react'

import DashboardAlert from '../shared/DashboardAlert'
import SummaryTab from './SummaryTab'
import SelectBarComponent from '../shared/SelectBarComponent'

import { TABS } from './tabs'

import type { Influencer } from './message_types'

type State = {|
  alertMessage: ?string,
  alertIsError: boolean
|}

type Props = {|
  influencer: Influencer
|}

class InfluencerDashboardSummary extends React.Component<Props, State> {
  constructor (props: Props): void {
    super(props)
    this.state = {
      alertMessage: null,
      alertIsError: false,
    }
  }

  setAlertMessage = (message: string, alertIsError: boolean = false): void => {
    window.scrollTo(0, 0)
    this.setState({
      alertMessage: message,
      alertIsError: alertIsError,
    })
    setTimeout((): void => {
      this.setState({alertMessage: null})
    }, 3000)
  }

  render = (): React.Node => (
    <div className='butternutbox-affiliate-dashboard'>
      {/* $FlowFixMe */}
      <DashboardAlert
        message={this.state.alertMessage}
        isError={this.state.alertIsError}
        shippingCountryCode={this.props.influencer.shipping_country_code}
        preferredLanguage={this.props.influencer.preferred_language}
      />
      <div className={`affiliate-dashboard__${TABS[0].hash}`}>
        <h1 className={`affiliate-dashboard__${TABS[0].hash}-title single-margin--top`}>
          {TABS[0].title}
        </h1>
        <div className='affiliate-dashboard container'>
          <SelectBarComponent
            tabs={TABS}
            selectedIndex={0}
          />
          <SummaryTab
            influencer={this.props.influencer}
            visible={true}
            setAlertMessage={this.setAlertMessage}
          />
        </div>
      </div>
    </div>
  )
}

export default InfluencerDashboardSummary
