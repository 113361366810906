import { useEffect } from 'react'

type Props = {
  formIsValid: boolean
  onEnterPress: () => void
}

const useImplicitFormSubmission = ({
  formIsValid,
  onEnterPress
}: Props): void => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        if (formIsValid) {
          onEnterPress()
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [formIsValid, onEnterPress])
}

export default useImplicitFormSubmission
