// @noflow
import { addDays } from 'date-fns'
import { Factory } from 'fishery'

import { Props as TopBannerProps } from '@/components/elements/atoms/TopBanner/TopBanner'
import { AdminBanner } from '@/components/elements/molecules/BannerManager/BannerManager'

const TopBannerFactory = Factory.define<TopBannerProps>(({ sequence }) => ({
  id: sequence.toString(),
  variant: 'info',
  text: {
    text: 'Banner text'
  },
  order: sequence
}))

const AdminBannerFactory = Factory.define<AdminBanner>(({ sequence }) => ({
  ...TopBannerFactory.build(),
  liveDate: addDays(Date.now(), -1),
  order: sequence
}))

export { AdminBannerFactory, TopBannerFactory }
