// @noflow
import i18next from 'i18next'

import { possessive, pronounContext } from '@/utils/StringHelper'
import * as Sentry from '@/utils/sentry'

import { Gender } from '@/types'
import { ensureNever } from '@/typescript/utils'

import type { FullWizardRoutes, Route } from '../types/routes'
import WizardRoutes from '../types/routes'
import type { Dog } from '../types/types'

type StepHero = {
  title: string
  subtitle?: string
  variables?: { [key: string]: string | number }
  subtitleVariables?: { [key: string]: string | number }
}

enum StepNumber {
  UserTotalSteps = 12,
  GuestTotalSteps = 15
}

const stepsInFlow = (
  isGuest: boolean,
  shouldSeePetParentOnPlans: boolean
): number => {
  switch (true) {
    case !isGuest:
      return shouldSeePetParentOnPlans
        ? StepNumber.UserTotalSteps - 1
        : StepNumber.UserTotalSteps
    default:
      return shouldSeePetParentOnPlans
        ? StepNumber.GuestTotalSteps - 1
        : StepNumber.GuestTotalSteps
  }
}

const routeToStepHero = (
  route: Route,
  dogs: Array<Dog>,
  isSupportedWizardStateVersion: boolean
): StepHero | undefined => {
  const wizardState = JSON.parse(localStorage.getItem('wizardState') || '{}')
  if (route !== WizardRoutes.Name && dogs.some((dog: Dog) => !dog.name)) {
    if (isSupportedWizardStateVersion) {
      Sentry.captureException('Wizard Dog Name is invalid', {
        extra: {
          wizardState
        },
        tags: {
          product: Sentry.Product.Wizard
        }
      })
    }
    window.location.replace(WizardRoutes.Name)
    return
  }

  if (
    route !== WizardRoutes.Name &&
    route !== WizardRoutes.Gender &&
    dogs.some((dog: Dog) => !dog.gender)
  ) {
    Sentry.captureException('Wizard Dog Gender is invalid', {
      extra: {
        wizardState
      },
      tags: {
        product: Sentry.Product.Wizard
      }
    })
    window.location.replace(WizardRoutes.Gender)
    return
  }

  const dogNames = dogs.map((dog: Dog): string => dog.name)
  const dogGendersArray = dogs.map((dog: Dog): Gender => dog.gender as Gender)

  switch (route) {
    case WizardRoutes.Name: {
      return {
        title: 'name_step.hero'
      }
    }
    case WizardRoutes.Gender: {
      return {
        title: 'gender_step.hero',
        variables: {
          dogName: dogNames[0],
          count: dogs.length,
          context: dogs.length > 1 ? 'plural' : ''
        }
      }
    }
    case WizardRoutes.Age: {
      return {
        title: 'age_step.hero',
        variables: {
          dogName: dogNames[0],
          count: dogs.length,
          context: dogs.length > 1 ? 'plural' : ''
        }
      }
    }
    case WizardRoutes.Breed: {
      return {
        title: 'breed_step.hero',
        variables: {
          dogName: dogNames[0],
          count: dogs.length,
          context: dogs.length > 1 ? 'plural' : ''
        }
      }
    }
    case WizardRoutes.Food: {
      return {
        title: 'food_step.hero',
        variables: {
          dogName: dogNames[0],
          count: dogs.length,
          context: dogs.length > 1 ? 'plural' : ''
        }
      }
    }
    case WizardRoutes.Activity: {
      return {
        title: 'activity_step.title',
        variables: {
          dogName: dogNames[0],
          count: dogs.length,
          context: dogs.length > 1 ? 'plural' : ''
        },
        subtitle: 'activity_step.subtitle'
      }
    }
    case WizardRoutes.Eater: {
      return {
        title: 'eater_step.hero',
        variables: {
          dogName: dogNames[0],
          count: dogs.length,
          context: dogs.length > 1 ? 'plural' : ''
        },
        subtitle: 'eater_step.subtitle',
        subtitleVariables: {
          context: pronounContext(dogGendersArray, i18next.language)
        }
      }
    }
    case WizardRoutes.FussinessHelp: {
      return {
        title: 'fussiness_help.hero'
      }
    }
    case WizardRoutes.Goals: {
      return {
        title: 'goals_step.hero',
        variables: {
          possessiveDogName: possessive(dogNames[0], i18next.language),
          count: dogs.length,
          context: dogs.length > 1 ? 'plural' : ''
        }
      }
    }
    case WizardRoutes.BodyShape: {
      return {
        title: 'body_shape_step.hero',
        variables: {
          possessiveDogName: possessive(dogNames[0], i18next.language),
          dogName: dogNames[0],
          count: dogs.length,
          context: dogs.length > 1 ? 'plural' : ''
        },
        subtitle: 'body_shape_step.subtitle',
        subtitleVariables: {
          context: pronounContext(dogGendersArray, i18next.language)
        }
      }
    }
    case WizardRoutes.Weight: {
      return {
        title: 'weight_step.hero',
        variables: {
          dogName: dogNames[0],
          count: dogs.length,
          context: dogs.length > 1 ? 'plural' : ''
        }
      }
    }
    case WizardRoutes.Allergies: {
      return {
        title: 'allergies_step.hero_sensitivities',
        subtitle: 'allergies_step.subtitle',
        variables: {
          dogName: dogNames[0],
          count: dogs.length,
          context: dogs.length > 1 ? 'plural' : ''
        }
      }
    }
    case WizardRoutes.Health: {
      return {
        title: 'health_step.hero',
        variables: {
          dogName: dogNames[0],
          count: dogs.length,
          context: dogs.length > 1 ? 'plural' : ''
        },
        subtitle: 'health_step.subtitle'
      }
    }
    case WizardRoutes.Snacks: {
      return {
        title: 'snacks_step.hero',
        variables: {
          dogName: dogNames[0],
          count: dogs.length,
          context: dogs.length > 1 ? 'plural' : ''
        }
      }
    }
    case WizardRoutes.PetParent: {
      return {
        title: 'pet_parent_step.hero',
        subtitle: 'pet_parent_step.subtitle'
      }
    }
    default: {
      ensureNever(route)
      Sentry.captureException(`Cannot generate stepHero for: ${route}`, {
        tags: {
          product: Sentry.Product.Wizard
        }
      })

      return {
        title: 'name_step.hero'
      }
    }
  }
}

const routeToPercentFilled = ({
  route,
  isGuest,
  shouldSeePetParentOnPlans
}: {
  route: Route
  isGuest: boolean
  shouldSeePetParentOnPlans: boolean
}): number => {
  const wizardSteps: Record<FullWizardRoutes, number> = {
    [WizardRoutes.Name]: 1,
    [WizardRoutes.Gender]: 2,
    [WizardRoutes.Age]: 3,
    [WizardRoutes.Breed]: 4,
    [WizardRoutes.Food]: 5,
    [WizardRoutes.Eater]: 6,
    [WizardRoutes.FussinessHelp]: 7,
    [WizardRoutes.Goals]: 8,
    [WizardRoutes.BodyShape]: 9,
    [WizardRoutes.Weight]: 10,
    [WizardRoutes.Activity]: 11,
    [WizardRoutes.Allergies]: 12,
    [WizardRoutes.Health]: 13,
    [WizardRoutes.Snacks]: 14,
    [WizardRoutes.PetParent]: 15
  }

  // The total number of steps in the Wizard for a Guest or User
  const steps = stepsInFlow(isGuest, shouldSeePetParentOnPlans)

  if (!(route in wizardSteps)) {
    Sentry.captureException('Invalid route for Wizard', {
      extra: {
        route
      },
      tags: {
        product: Sentry.Product.Wizard
      }
    })
  }

  // Which step number am I currently on
  const stepIndex = wizardSteps[route as FullWizardRoutes]

  // What percentage of the Wizard have I completed given the
  // number of steps and the current stepIndex I am on
  const stepPercentage = (stepIndex / steps) * 100

  return parseFloat(stepPercentage.toFixed(2))
}

export { routeToStepHero, routeToPercentFilled, StepNumber, stepsInFlow }
