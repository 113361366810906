// @noflow

/* eslint-disable i18next/no-literal-string */
import React, { useEffect, useRef } from 'react'

import {
  ComponentAnalyticsDisabled,
  ComponentAnalyticsEnabled
} from '@/services/segment'

import { CardItem } from './components/CardItem'
import Card, {
  Props as CardComponentProps
} from '@/components/elements/atoms/Card/Card'
import type { Props as ImageProps } from '@/components/elements/atoms/Image/Image'
import { TextProps } from '@/components/elements/atoms/Text'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './SelectableCardGroup.module.sass'

type Value = string | number | boolean

type Option = {
  value: Value
  icon: ImageProps
}

type OptionWithAnalytics = Option &
  Pick<ComponentAnalyticsEnabled, 'disableAnalytics' | 'identifier'>
type OptionWithoutAnalytics = Option &
  Pick<ComponentAnalyticsDisabled, 'disableAnalytics' | 'identifier'>

type OptionProps = OptionWithAnalytics | OptionWithoutAnalytics

type CardProps = Omit<
  CardComponentProps,
  'children' | 'identifier' | 'disableAnalytics' | 'screenIdentifier'
> & {
  contentDirection?: 'row' | 'column'
  minHeight?: string
}

type DescriptionCard = {
  title: TextProps
  description?: TextProps
  cardProps?: CardProps
  hideTriangle?: boolean
}

type Props = {
  options: Array<OptionProps>
  currentValue: Value
  layoutCardColumns?: number
  cardProps?: CardProps
  descriptionCard?: DescriptionCard
  onSelect: (value: Value) => void
}

/**
 * SelectableCardGroup component
 *
 * This component renders a group of selectable cards with optional
 * description card.
 * It allows users to select one option from a list of options.
 *
 * @example
 * ```
 * import { SelectableCardGroupV2 } from 'components/molecules/SelectableCardGroup'
 *
 * <SelectableCardGroupV2
 *   options={[
 *     {
 *       value: '1',
 *       icon: {
 *         slug: 'slug-to-image',
 *         alt: 'placeholder',
 *         width: 150,
 *         height: 150
 *       },
 *       identifier: 'option1'
 *     }
 *   ]}
 *   onSelect={(value) => setStateValue(value)}
 *   currentValue={stateValue}
 *   descriptionCard={{
 *     title: {
 *       namespace: 'account',
 *       text: 'translation.path.text',
 *     },
 *     description: {
 *       namespace: 'account',
 *       text: 'translation.path.description',
 *     }
 *   }}
 * />
 * ```
 * @param {Array<OptionProps>} options - Array of options
 * @param {number} layoutCardColumns - Number of columns to display
 * @param {Value} currentValue - Current selected value
 * @param {Function} onSelect - Callback function to handle the selected value
 * @param {CardProps} [cardProps] - Props to be passed to the card component
 * @param {DescriptionCard} [descriptionCard] - Props for the description card
 * @category Components
 * @subcategory Molecules
 * @returns {JSX.Element} - SelectableCardGroupV2 component
 */
const SelectableCardGroupV2 = ({
  options,
  layoutCardColumns,
  descriptionCard,
  ...restProps
}: Props): JSX.Element => {
  const shouldShowDescriptionCard =
    restProps.currentValue &&
    descriptionCard &&
    !restProps.cardProps?.skeleton?.isLoading

  const shouldShowTriangle =
    options.some(({ value }) => value === restProps.currentValue) &&
    !descriptionCard?.hideTriangle

  const cardsWrapperRef = useRef<HTMLDivElement | null>(null)
  const triangleRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (
      shouldShowDescriptionCard &&
      shouldShowTriangle &&
      cardsWrapperRef.current
    ) {
      const selectedCard = cardsWrapperRef.current.querySelector(
        `[aria-pressed="true"]`
      )

      if (selectedCard && triangleRef.current) {
        const cardRect = selectedCard.getBoundingClientRect()
        const containerRect = cardsWrapperRef.current.getBoundingClientRect()

        triangleRef.current.style.left = `${
          cardRect.left - containerRect.left + cardRect.width / 2
        }px`
      }
    }
  }, [restProps.currentValue, shouldShowDescriptionCard, shouldShowTriangle])

  return (
    <div
      className={`${STYLES.container} ${
        shouldShowTriangle ? STYLES.containerShowTriangle : ''
      }`}
    >
      <div
        className={STYLES.cardsWrapper}
        ref={cardsWrapperRef}
        data-testid="cards-wrapper"
        style={
          {
            '--layout-card-columns': layoutCardColumns,
            '--card-min-height': restProps.cardProps?.minHeight
          } as React.CSSProperties
        }
      >
        {options.map((option) => (
          <CardItem key={option.value.toString()} {...option} {...restProps} />
        ))}
      </div>
      {shouldShowDescriptionCard && (
        <div className={STYLES.descriptionCard}>
          {shouldShowTriangle && (
            <div
              ref={triangleRef}
              className={STYLES.triangle}
              role="presentation"
              aria-label="triangle"
            />
          )}
          <Card
            variant="brandYellow200"
            disableAnalytics
            padding={24}
            {...descriptionCard.cardProps}
          >
            <Text
              margin={false}
              variant="display16"
              align="center"
              {...descriptionCard.title}
            />
            {descriptionCard.description && (
              <Text
                margin={false}
                align="center"
                {...descriptionCard.description}
              />
            )}
          </Card>
        </div>
      )}
    </div>
  )
}

export type {
  Props,
  Value,
  CardProps,
  OptionWithAnalytics,
  OptionWithoutAnalytics
}

export { SelectableCardGroupV2 }
