// @noflow
import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Expand } from '@/components/elements/atoms/Animated/Animated'
import Card from '@/components/elements/atoms/Card/Card'
import Image from '@/components/elements/atoms/Image'

type Props = {
  namespace: string
  show: boolean
  bancontact: Bancontact
}

type Bancontact = {
  bancontactSubscriptionCallback: () => void
  disabled: boolean
}

const BancontactOption = ({
  namespace,
  show,
  bancontact
}: Props): JSX.Element => {
  const { t } = useTranslation(namespace)
  const copyContext = 'payment_section.payment_options_view'
  const { bancontactSubscriptionCallback, disabled } = bancontact

  return (
    <Expand show={show} margin={{ top: 1.6 }} maxHeight={600}>
      <Card variant="brandBlue100" squared={{ topLeft: true, topRight: true }}>
        <button
          className={classNames(
            `btn-updated--secondary payment-section__payment-options-btn bancontact-button`,
            {
              'btn-updated--disabled': disabled
            }
          )}
          type="button"
          onClick={bancontactSubscriptionCallback}
          disabled={disabled}
          id="bancontact"
        >
          <Image
            slug="bancontact-logo-no-bg"
            alt={`${t(`${copyContext}.bancontact_alt`)}`}
            image={{
              height: 24,
              width: 39
            }}
          />
        </button>
      </Card>
    </Expand>
  )
}

export default BancontactOption
export type { Bancontact }
