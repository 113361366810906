import { gql } from '@apollo/client'

const PRODUCT_COLLECTION_FRAGMENT = gql`
  fragment productCollectionFragment on ProductCollection {
    id
    slug
    unavailableObject {
      reason
      reasonCopy
    }
    productSticker {
      image {
        src
      }
    }
    name
    discount {
      discountBasis
      value
    }
    productLabel {
      name
    }
    primaryImage {
      src
    }
    productVariants {
      id
      name
      discountedPrice
      grossPrice
      productVariantDeliveryTypes {
        deliveryType
        adjustedGrossPrice
        netPrice
        boostedNetPrice
      }
    }
  }
`

const NO_DOGS_HERO_QUERY = gql`
  query NoDogsHeroQuery {
    user {
      id
      firstName
      subscription {
        id
        status
        pauses {
          id
          subreason
          createdAt
        }
      }
    }
  }
`

const PRODUCT_SECTION_QUERY = gql`
  ${PRODUCT_COLLECTION_FRAGMENT}
  query ProductSectionQuery($limit: Int) {
    user {
      id
      preferredLanguage
      shippingCountryCode
      subscription {
        status
      }
    }
    upsellableProductCollections(limit: $limit) {
      ...productCollectionFragment
    }
  }
`

const NO_DOGS_PAGE_DATA = gql`
  query NoDogsPageData {
    user {
      id
      firstName
      subscription {
        id
        status
        pauses {
          id
          subreason
          createdAt
        }
      }
    }
  }
`

export { NO_DOGS_PAGE_DATA, NO_DOGS_HERO_QUERY, PRODUCT_SECTION_QUERY }
