// @noflow
import React from 'react'
import { useTranslation } from 'react-i18next'

import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import { SelectableCardGroupV2 } from '@/components/elements/molecules/SelectableCardGroup'

import type { SharedInputProps } from '../../UpdateDogWeightAndAppetiteContent'

type Props = SharedInputProps & {
  bodyShape: string
}

const BodyCondition = ({
  bodyShape,
  margin = { top: 8, bottom: 0 },
  onSelect,
  loading
}: Props): JSX.Element => {
  const { t } = useTranslation('account')
  const copyContext = 'update_dog_profile_weight_and_appetite'

  const commonIconStyles = {
    image: {
      width: 80,
      height: 56
    }
  }

  const BODY_CONDITIONS = [
    {
      value: 'skinny',
      icon: {
        slug: 'body-condition-skinny',
        alt: t(`${copyContext}.body_condition.skinny.heading`),
        ...commonIconStyles
      },
      identifier: `${copyContext}.body_condition.skinny`
    },
    {
      value: 'just_right',
      icon: {
        slug: 'body-condition-just-right',
        alt: t(`${copyContext}.body_condition.just_right.heading`),
        ...commonIconStyles
      },
      identifier: `${copyContext}.body_condition.just_right`
    },
    {
      value: 'chubby',
      icon: {
        slug: 'body-condition-chubby',
        alt: t(`${copyContext}.body_condition.chubby.heading`),
        ...commonIconStyles
      },
      identifier: `${copyContext}.body_condition.chubby`
    }
  ]

  return (
    <SectionWrapper margin={margin}>
      <SelectableCardGroupV2
        options={BODY_CONDITIONS}
        onSelect={onSelect}
        currentValue={bodyShape}
        cardProps={{
          skeleton: {
            isLoading: loading,
            height: '11rem'
          }
        }}
        descriptionCard={{
          title: {
            namespace: 'account',
            text: `${copyContext}.body_condition.${bodyShape}.heading`
          },
          description: {
            namespace: 'account',
            text: `${copyContext}.body_condition.${bodyShape}.text`
          }
        }}
      />
    </SectionWrapper>
  )
}

export { BodyCondition }
