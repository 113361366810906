// @noflow
import type { Language } from '@/packs/localisation'
import { useReactiveVar } from '@apollo/client'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

// Utils
import { PronounContext, possessive } from '@/utils/StringHelper'

// Breakpoints
import Breakpoints from '@/constants/Breakpoints'

import useIntersectionObserver from '@/hooks/useIntersectionObserver/useIntersectionObserver'
// Hooks
import useWindowSize from '@/hooks/useWindowSize'

import Card from '@/components/elements/atoms/Card/Card'
// Components
import Icon from '@/components/elements/atoms/Icon/Icon'
import Image from '@/components/elements/atoms/Image/Image'
import SkeletonImage from '@/components/elements/atoms/SkeletonImage/SkeletonImage'
import SkeletonTitle from '@/components/elements/atoms/SkeletonTitle/SkeletonTitle'
import Text from '@/components/elements/atoms/Text'
import {
  PlanState,
  deliveryCadenceState,
  plansPageAnalyticState,
  plansPageState,
  plansPageWizardState
} from '@/components/pages/SimplifiedPlansPage/SimplifiedPlansPage'
import {
  trackPlanTypeSelectionSeen,
  trackStorageSectionSeen,
  trackUserSelectsAPlanType
} from '@/components/pages/SimplifiedPlansPage/helpers/analytics'
import {
  IdealPlanQuery_guest_planRecommendation_idealAllPlan as IdealAllPlan,
  IdealPlanQuery_guest_planRecommendation_idealMixPlan as IdealMixPlan
} from '@/components/pages/SimplifiedPlansPage/queries/__generated__/IdealPlanQuery'
import {
  FlowType,
  PlansAnalyticState
} from '@/components/pages/SimplifiedPlansPage/types/analyticsProperties'

// Styles
import STYLES from './boxplancard.module.sass'

import { BoxKind, TypeOfPlan } from '@/types'

import { Routes as PlansRoutes } from '../../../../types/routes'

type Props = {
  namespace: string
  copyContext: string
  dogNames: Array<string>
  dogPronoun: PronounContext
  loading: boolean
  isDesktop: boolean
  preferredLanguage: Language
  idealPlans: Array<IdealAllPlan | IdealMixPlan | null | undefined>
  updateSelectedPlan: (
    planId: string,
    callback: (planId: string) => void
  ) => void
}

const SkeletonBoxPlanCard = (): JSX.Element => {
  const { windowWidth } = useWindowSize()
  const isDesktop = windowWidth >= Breakpoints.lg
  return (
    <Card padding={isDesktop ? 32 : 16} mask>
      <SkeletonTitle align="left" height={28} width={'50%'} />
      <SkeletonTitle align="left" height={16} width={'100%'} />
      <SkeletonTitle align="left" height={16} />
      <SkeletonImage height={162} />
      <SkeletonImage height={162} />
      <div className={STYLES.ongoingBox}>
        <div>
          <SkeletonTitle
            align="left"
            height={28}
            width={isDesktop ? 200 : 100}
          />
          <SkeletonTitle
            align="left"
            height={16}
            width={isDesktop ? 300 : 150}
          />
          <SkeletonTitle
            align="left"
            height={16}
            width={isDesktop ? 300 : 150}
          />
          <SkeletonTitle
            align="left"
            height={16}
            width={isDesktop ? 300 : 150}
          />
        </div>
        <SkeletonImage height={120} width={120} />
      </div>
      <SkeletonTitle align="center" height={16} width={'100%'} />
    </Card>
  )
}

const OngoingBox = ({
  namespace,
  copyContext,
  dogNames,
  isDesktop,
  preferredLanguage
}: Omit<
  Props,
  'dogPronoun' | 'loading' | 'idealPlans' | 'updateSelectedPlan'
>): JSX.Element => {
  const { t } = useTranslation(namespace)

  return (
    <>
      <div className={STYLES.ongoingBox}>
        <div className={STYLES.ongoingBoxText}>
          <Text
            namespace={namespace}
            variant="display20"
            text={`${copyContext}.box_plan.ongoing_title`}
            margin={false}
          />
          <Text
            namespace={namespace}
            variant="textRegular16"
            text={`${copyContext}.box_plan.ongoing_subtitle`}
            variables={{
              possessiveDogName: possessive(dogNames[0], preferredLanguage),
              count: dogNames.length
            }}
            margin={false}
          />
        </div>
        <div className={STYLES.imageWrapper}>
          <Image
            slug="butternut-pouches-in-fridge-default"
            alt={t(`${copyContext}.box_plan.ongoing_img_alt`)}
            image={{
              width: 183,
              height: 275,
              resizeMode: 'resize_to_fill'
            }}
          />
        </div>
      </div>
      <div className={STYLES.infoContent}>
        <Icon
          asset="alert"
          size={isDesktop ? 16 : 14}
          width={isDesktop ? 16 : 14}
          accentColour="supportBlue300"
          backgroundColour="supportBlue100"
        />
        <Text
          namespace={namespace}
          variant="textRegular16"
          text={`${copyContext}.box_plan.ongoing_info`}
          colour="brandBlue400"
          margin={false}
        />
      </div>
    </>
  )
}

const BoxPlanCard = ({
  namespace,
  copyContext,
  dogNames,
  dogPronoun,
  loading,
  preferredLanguage,
  idealPlans,
  updateSelectedPlan
}: Omit<Props, 'isDesktop'>): JSX.Element | null => {
  const { t } = useTranslation(namespace)

  const planTypeSelectionSeenRef = useRef<HTMLDivElement>(null)
  const storageSectionSeenRef = useRef<HTMLDivElement>(null)

  const plansState: PlanState = useReactiveVar(plansPageState)
  const wizardState = useReactiveVar(plansPageWizardState)
  const deliveryCadence = useReactiveVar(deliveryCadenceState)
  const plansAnalyticState: PlansAnalyticState = useReactiveVar(
    plansPageAnalyticState
  )

  const [selectedPlan, setSelectedPlan] = useState<string | undefined | null>(
    plansState.planId
  )
  const { windowWidth } = useWindowSize()
  const isDesktop = windowWidth >= Breakpoints.lg
  const isTablet = windowWidth > Breakpoints.sm
  const isMobile = windowWidth < Breakpoints.md

  const trackUpdateSelectedPlan = useCallback(
    (planType: TypeOfPlan) => {
      trackUserSelectsAPlanType({
        route: PlansRoutes.Plan,
        plansState,
        wizardState,
        deliveryCadence,
        flowType: FlowType.new,
        plansAnalyticState,
        planType
      })
    },
    [deliveryCadence, plansAnalyticState, plansState, wizardState]
  )

  const planTypeSelectionSeen = useIntersectionObserver(
    planTypeSelectionSeenRef,
    {
      threshold: [0.3]
    }
  )

  const storageSectionSeen = useIntersectionObserver(storageSectionSeenRef, {
    threshold: [0.2]
  })

  const handleOnClick = useCallback(
    (plan) => {
      updateSelectedPlan(plan?.id || '', setSelectedPlan)
      trackUpdateSelectedPlan(plan?.typeOfPlanForCustomer || TypeOfPlan.all)
    },
    [trackUpdateSelectedPlan, updateSelectedPlan]
  )

  useEffect(() => {
    if (planTypeSelectionSeen) {
      trackPlanTypeSelectionSeen()
    }
    if (storageSectionSeen) {
      trackStorageSectionSeen()
    }
  }, [planTypeSelectionSeen, storageSectionSeen])

  if (loading) {
    return <SkeletonBoxPlanCard />
  }

  return (
    <div className={STYLES.boxPlanCard}>
      <Card padding={isDesktop ? 32 : 16} mask>
        <Text
          namespace={namespace}
          variant="display20"
          text={`${copyContext}.box_plan.title`}
          variables={{ dogName: dogNames, count: dogNames.length }}
          align={isDesktop ? 'left' : 'center'}
          margin={false}
        />
        <Text
          namespace={namespace}
          variant="textRegular16"
          colour="brandBlue400"
          text={`${copyContext}.box_plan.${
            idealPlans.length === 1 ? 'one_option' : 'multi_option'
          }_subtitle`}
          variables={{
            dogName: dogNames,
            count: dogNames.length
          }}
          align={isDesktop ? 'left' : 'center'}
          margin={false}
        />
        <div className={STYLES.plans} ref={planTypeSelectionSeenRef}>
          {idealPlans.map((plan) => {
            return (
              <Card
                key={plan?.id}
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => handleOnClick(plan)}
                selected={selectedPlan === plan?.id}
                shadow
                border="solid"
                padding={0}
                mask
                className={`${STYLES.planCard} ${
                  selectedPlan === plan?.id ? STYLES.selected : ''
                }`}
                identifier={'plan_card.plan_id_' + plan?.id}
              >
                <div className={STYLES.planCardContent}>
                  <Image
                    slug={`${plan?.typeOfPlanForCustomer}-butternut-with-pink-background`}
                    alt={t(
                      `${copyContext}.box_plan.selected_plan.img_alt.${
                        plan?.boxKind === BoxKind.all_bb ? 'all' : 'mix'
                      }`
                    )}
                    image={{
                      width: isMobile ? 100 : 148,
                      height: isMobile ? 132 : 162,
                      resizeMode: 'resize_to_fill',
                      gravity: 'center'
                    }}
                    className={STYLES.planCardImage}
                  />
                  <div className={STYLES.planCardText}>
                    <Text
                      text={`${copyContext}.box_plan.selected_plan.${plan?.typeOfPlanForCustomer}_butternut`}
                      variant="display16"
                      margin={false}
                      element="h3"
                      namespace={namespace}
                    />
                    <Text
                      text={`${copyContext}.box_plan.selected_plan.grams_per_day`}
                      variables={{
                        gramsPerDay: plan?.servingInformation.gramsPerDay,
                        context: dogPronoun,
                        count: dogNames.length
                      }}
                      namespace={namespace}
                      variant="textRegular14"
                      margin={false}
                    />
                    <Text
                      text={`${copyContext}.box_plan.selected_plan.plan_${
                        plan?.typeOfPlanForCustomer === TypeOfPlan.all
                          ? 'all'
                          : 'mix'
                      }_description`}
                      variables={{
                        gramsPerDay: plan?.servingInformation.gramsPerDay,
                        context: dogPronoun,
                        count: dogNames.length
                      }}
                      namespace={namespace}
                      variant="textRegular14"
                      colour="brandBlue400"
                      margin={false}
                    />
                  </div>
                </div>
              </Card>
            )
          })}
        </div>
        {isTablet && (
          <div ref={storageSectionSeenRef}>
            <OngoingBox
              namespace={namespace}
              copyContext={copyContext}
              dogNames={dogNames}
              isDesktop={isDesktop}
              preferredLanguage={preferredLanguage}
            />
          </div>
        )}
      </Card>
      {!isTablet && (
        <div ref={storageSectionSeenRef}>
          <OngoingBox
            namespace={namespace}
            copyContext={copyContext}
            dogNames={dogNames}
            isDesktop={isDesktop}
            preferredLanguage={preferredLanguage}
          />
        </div>
      )}
    </div>
  )
}

export default BoxPlanCard
