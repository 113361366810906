import { ACCOUNT_ROUTES } from '@/routes'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

// Components
import { Button } from '@/components/elements/atoms/Button'
import Link from '@/components/elements/atoms/Link/Link'
import Modal from '@/components/elements/atoms/Modal/Modal'
import Text from '@/components/elements/atoms/Text'

// Styles
import STYLES from '../ChangeDatePageV2.module.sass'

type Props = {
  onClosed: () => void
  shouldShowModal: boolean
}

const AddDeliveryNoteModal = ({
  onClosed,
  shouldShowModal
}: Props): JSX.Element | null => {
  const navigate = useNavigate()

  const handleNavigateToDeliveryNoteDetails = useCallback(() => {
    navigate(ACCOUNT_ROUTES.personalDetails)
  }, [navigate])

  return (
    <Modal
      isModalOpen={shouldShowModal}
      onCloseButtonClick={onClosed}
      width={600}
      closeButtonBackground={'brandBlue100'}
    >
      <div className={STYLES.deliveryNoteContainer}>
        <div className={STYLES.deliveryNoteHorizontalWrapper}>
          <Text
            text="change_date.add_delivery_note.title"
            namespace="account"
            colour={'brandBlue500'}
            margin={false}
            variant={'display24'}
          />
        </div>
        <Text
          text="change_date.add_delivery_note.subtitle"
          namespace="account"
          colour={'brandBlue500'}
          variant={'textRegular18'}
        />
        <div className={STYLES.deliveryNoteButtonStyling}>
          <Button
            identifier="change_courier.add_delivery_note"
            onClick={handleNavigateToDeliveryNoteDetails}
            fullWidth
            typography={{
              text: 'change_date.add_delivery_note.add_note',
              namespace: 'account'
            }}
          />
        </div>
        <div className={STYLES.deliveryNoteLink}>
          <Link
            text="change_date.add_delivery_note.no_thanks"
            namespace="account"
            onClick={onClosed}
            colour={'brandBlue500'}
            suffix={null}
            size={18}
            identifier="change_courier.add_delivery_note.no_thanks"
          />
        </div>
      </div>
    </Modal>
  )
}

export default AddDeliveryNoteModal
