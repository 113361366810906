import { Container } from '@material-ui/core'
import upperFirst from 'lodash/upperFirst'
import React, { ReactElement } from 'react'

import { BrandColours } from '@/constants/BrandColours'

import ReferralComponent from '@/components/thank_you/components/ThankYouRaf'

import STYLES from './RAFSection.module.sass'

import { DogInput as Dog, Language } from '@/types'

type Props = {
  dogs: Pick<Dog, 'name'>[]
  userId: string
  referralLink: string
  preferredLanguage: Language
  header: string
  whatsappSingleDog: string
  whatsappMultipleDogs: string
  background?: keyof Pick<BrandColours, 'brandYellow200'>
}

const RAFSection = ({
  dogs,
  userId,
  referralLink,
  preferredLanguage,
  header,
  whatsappSingleDog,
  whatsappMultipleDogs,
  background
}: Props): ReactElement => {
  return (
    <section
      className={`${STYLES.section} ${background ? STYLES.brandYellow200 : ''}`}
    >
      <Container maxWidth="sm">
        <ReferralComponent
          referralLink={referralLink}
          dogNames={dogs.map(({ name }): string => upperFirst(name))}
          preferredLanguage={preferredLanguage}
          headerMessage={header}
          whatsappMessageSingleDog={whatsappSingleDog}
          whatsappMessageMultipleDogs={whatsappMultipleDogs}
          userId={userId}
        />
      </Container>
    </section>
  )
}

export default RAFSection
