// @noflow
import initI18n from '@/packs/localisation'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import type { Dispatch } from 'redux'

import MarketingPreferencesModal from './MarketingPreferencesModal'

import type { State } from '../../../index'
import * as THUNKS from '../../../thunks'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

type PresentationalProps = {
  userId: number,
  isFetching: boolean,
  marketingPreferenceData: any
}

type ActionProps = {
  getMarketingPreferenceData: (userId: number) => void,
  updateMarketingPreferences: (
    userId: number,
    purpose_ids: Array<number>
  ) => void
}

type Props = PresentationalProps & ActionProps

const mapStateToProps = (state: State): PresentationalProps => {
  const { marketingPreferences, selectedUserIds, fetchingStatuses } = state
  const { userId } = selectedUserIds
  const { marketingPreferencesState } = fetchingStatuses

  return {
    userId,
    isFetching: marketingPreferencesState,
    marketingPreferenceData: marketingPreferences
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => {
  const getMarketingPreferenceData = (userId: number): void => {
    dispatch(THUNKS.getMarketingPreferenceData(userId))
  }
  const updateMarketingPreferences = (
    userId: number,
    purpose_ids: Array<number>
  ): void => {
    dispatch(THUNKS.updateMarketingPreferences(userId, purpose_ids))
  }

  return {
    getMarketingPreferenceData,
    updateMarketingPreferences
  }
}
const MarketingPreferencesSOC = ({
  userId,
  isFetching,
  marketingPreferenceData,
  getMarketingPreferenceData,
  updateMarketingPreferences
}: Props): React.Node | null => {
  const [showMarketingPreferencesModal, setShowMarketingPreferencesModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  if (!i18next.isInitialized) initI18n('en')
  const { t } = useTranslation('account')

  const { marketingMethods, marketingPurposes } = useMemo(() => {
    if (!marketingPreferenceData) {
      return { marketingMethods: [], marketingPurposes: [] }
    }
    const existingPreferences =
      marketingPreferenceData.user.selectedMarketingMethodPurposes
    const availablePreferences =
      marketingPreferenceData.user.availableMarketingMethodPurposes
    return availablePreferences.reduce(
      (acc, preference) => {
        if (
          !acc.marketingMethods.find(
            (marketingMethod) =>
              preference.marketingMethod.id === marketingMethod.id
          )
        ) {
          const marketingMethod = {
            id: preference.marketingMethod.id,
            name: preference.marketingMethod.name,
            selected: existingPreferences.some(
              (existingPreference) =>
                existingPreference.supportedMarketingMethodPurpose
                  .marketingMethod.id === preference.marketingMethod.id
            )
          }
          acc.marketingMethods.push(marketingMethod)
        }
        if (
          !acc.marketingPurposes.find(
            (marketingPurpose) =>
              preference.marketingPurpose.id === marketingPurpose.id
          )
        ) {
          const marketingPurpose = {
            id: preference.marketingPurpose.id,
            name: preference.marketingPurpose.name,
            selected: existingPreferences.some(
              (existingPreference) =>
                existingPreference.supportedMarketingMethodPurpose
                  .marketingPurpose.id === preference.marketingPurpose.id
            )
          }
          acc.marketingPurposes.push(marketingPurpose)
        }
        return acc
      },
      { marketingMethods: [], marketingPurposes: [] }
    )
  }, [marketingPreferenceData])

  const setTranslatedErrorMessage = useCallback((message: string) => {
    setErrorMessage(t(message))
    const modal = document.getElementById('marketing-preferences-modal') || null
    modal && modal.scrollIntoView({ behavior: 'smooth' })
  }, [t])

  const toggleMarketingPreferencesModal = useCallback(() => {
    setErrorMessage(null)
    setShowMarketingPreferencesModal((prev) => !prev)
  }, [])

  const closeMarketingPreferencesModal = useCallback(() => {
    setErrorMessage(null)
    setShowMarketingPreferencesModal(false)
  }, [])

  const handleSubmitPreferences = useCallback(
    (preferences: Array<string>): void => {
      setErrorMessage(null)
      updateMarketingPreferences(
        userId,
        preferences.map((id) => parseInt(id, 10))
      )
    },
    [updateMarketingPreferences, userId]
  )

  useEffect(() => {
    initI18n('en')
  }, [])

  useEffect(() => {
    getMarketingPreferenceData(userId,)
  }, [userId, getMarketingPreferenceData])

  useEffect(() => {
    setErrorMessage(marketingPreferenceData.errorMessage)
  }, [marketingPreferenceData.errorMessage])

  return (
    <React.Fragment>
      <div
        className={`marketing-preferences card__container card__${
          isFetching ? 'loading' : 'loaded'
        }`}
      >
        <div className="card__header__container">
          <h3 className="card__header">{`Marketing Preferences`}</h3>
          <button
            className="card__button button--white"
            onClick={toggleMarketingPreferencesModal}
            type="button"
          >
            {'Edit'}
          </button>
        </div>
        <div className="card__body">
          {isFetching ? (
            <div className="loading-ring">
              <span />
              <span />
              <span />
              <span />
            </div>
          ) : null}
          <div className="marketing-preferences">
            {marketingPurposes.length > 0 && (
              <div className={'marketing-preferences__wrapper'}>
                <h2 className={'title'}>{'Purposes'}</h2>
                {marketingPurposes.map((purpose) => (
                  <div
                    className={'preference'}
                    key={'purpose' + purpose.id}
                  >
                    <p className={'preference__name'}>{purpose.name}</p>
                    {purpose.selected ? (
                      <p
                        className={'preference__value green'}
                      >
                        {'Yes'}
                      </p>
                    ) : (
                      <p className={'preference__value red'}>
                        {'No'}
                      </p>
                    )}
                  </div>
                ))}
              </div>
            )}
            {marketingMethods.length > 0 && (
              <div className={'marketing-preferences__wrapper'}>
                <h2 className={'title'}>{'Methods'}</h2>
                {marketingMethods.map((method) => (
                  <div className={'preference'} key={'method' + method.id}>
                    <p className={'preference__name'}>{method.name}</p>
                    {method.selected ? (
                      <p
                        className={'preference__value green'}
                      >
                        {'Yes'}
                      </p>
                    ) : (
                      <p className={'preference__value red'}>
                        {'No'}
                      </p>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      {showMarketingPreferencesModal && (
        <div className="modal-container">
          <div
            role="none"
            className="modal-container__backdrop"
            onClick={closeMarketingPreferencesModal}
            onKeyUp={closeMarketingPreferencesModal}
          />
          <div className="modal-container__modal">
            <div className="marketing-preferences-modal" id='marketing-preferences-modal'>
              {errorMessage && (
                <p className={'marketing-preferences__error-message'}>{errorMessage}</p>
              )}
              <MarketingPreferencesModal
                handleSubmitPreferences={handleSubmitPreferences}
                data={marketingPreferenceData}
                handleDisplayError={setTranslatedErrorMessage}
                closeMarketingPreferencesModal={closeMarketingPreferencesModal}
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketingPreferencesSOC)
