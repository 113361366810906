import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { Button } from '@/components/elements/atoms/Button'
import Icon from '@/components/elements/atoms/Icon/Icon'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from '../ReportIssue.module.sass'

import { SelfResolutionCategories_user_subscription_box_potentialMissingItems as PotentialMissingItem } from '../../../queries/__generated__/SelfResolutionCategories'

import useSelfResolutionTracking from '../../../analytics'
import SelfResolutionRoutes from '../../../types/routes'
import CheckboxField from './CheckboxField'

type ProductCheckboxProps = {
  potentialMissingItem: PotentialMissingItem
  selectedItems: Array<PotentialMissingItem>
  onCheckboxChange: (
    potentialMissingItem: PotentialMissingItem,
    added: boolean
  ) => void
}

const ProductCheckbox = ({
  potentialMissingItem,
  selectedItems,
  onCheckboxChange
}: ProductCheckboxProps) => {
  const isChecked = useMemo(() => {
    return (
      selectedItems.findIndex(
        (selectedItem: PotentialMissingItem) =>
          selectedItem.productId === potentialMissingItem.productId
      ) > -1
    )
  }, [selectedItems, potentialMissingItem.productId])

  const onChange = useCallback(
    (key: string, value: boolean) => {
      onCheckboxChange(potentialMissingItem, value)
    },
    [onCheckboxChange, potentialMissingItem]
  )

  return (
    <div className={STYLES.productCheckbox}>
      <CheckboxField
        id={potentialMissingItem.productId}
        label={potentialMissingItem.name}
        value={isChecked}
        thumbnailSrc={potentialMissingItem.thumbnail}
        setValue={onChange}
      />
    </div>
  )
}

type ProductSectionProps = {
  namespace: string
  title: string
  potentialMissingItems: Array<PotentialMissingItem>
  selectedItems: Array<PotentialMissingItem>
  onCheckboxChange: (
    potentialMissingItem: PotentialMissingItem,
    added: boolean
  ) => void
}

const ProductSection = ({
  namespace,
  title,
  potentialMissingItems,
  selectedItems,
  onCheckboxChange
}: ProductSectionProps) => {
  const [isOpen, setIsOpen] = useState(true)
  const toggleView = useCallback(() => {
    setIsOpen(!isOpen)
  }, [setIsOpen, isOpen])

  const renderProducts = useCallback(() => {
    return potentialMissingItems.map((potentialMissingItem) => {
      return (
        <ProductCheckbox
          key={potentialMissingItem.productId}
          potentialMissingItem={potentialMissingItem}
          selectedItems={selectedItems}
          onCheckboxChange={onCheckboxChange}
        />
      )
    })
  }, [potentialMissingItems, onCheckboxChange, selectedItems])

  const showHideText = useMemo(() => {
    if (isOpen) {
      return 'reportIssue.hide'
    } else {
      return 'reportIssue.show'
    }
  }, [isOpen])

  const displayIcon = useMemo(() => {
    if (isOpen) {
      return <Icon asset="minus" accentColour="brandBlue500" size={3} />
    } else {
      return <Icon asset="plus" accentColour="brandBlue500" size={14} />
    }
  }, [isOpen])

  if (potentialMissingItems.length === 0) {
    return null
  }
  return (
    <div className={STYLES.productsSection}>
      <div className={STYLES.productsSectionHeader}>
        <Text
          namespace={namespace}
          variant={'display20'}
          text={title}
          colour={'brandBlue500'}
        />
        <Button
          typography={{
            namespace,
            text: showHideText
          }}
          variant={'ghost'}
          disabled={false}
          onClick={toggleView}
          icon={{ position: 'left', component: displayIcon }}
          displayText={'never'}
          disableAnalytics
        />
      </div>
      {isOpen && renderProducts()}
    </div>
  )
}

type Props = {
  namespace: string
  potentialMissingItems: Array<PotentialMissingItem>
  question: string
  onSelected: (orderProducts: Array<PotentialMissingItem>) => void
}

const ProductSelector = ({
  namespace,
  potentialMissingItems,
  question = '',
  onSelected
}: Props): JSX.Element => {
  const [selectedItems, setSelectedItems] = useState<
    Array<PotentialMissingItem>
  >([])
  const [meals, setMeals] = useState<Array<PotentialMissingItem>>([])
  const [extras, setExtras] = useState<Array<PotentialMissingItem>>([])
  const selfResolutionTracking = useSelfResolutionTracking()

  useEffect(() => {
    setMeals(potentialMissingItems.filter((item) => item.category === 'meal'))
    setExtras(potentialMissingItems.filter((item) => item.category === 'extra'))
  }, [potentialMissingItems])

  const onCheckboxChange = useCallback(
    (potentialMissingItem: PotentialMissingItem, added: boolean) => {
      if (added) {
        const selectedItemsCopy = selectedItems.splice(0)
        selectedItemsCopy.push(potentialMissingItem)
        setSelectedItems(selectedItemsCopy)
        selfResolutionTracking.trackEvent('item selected', {
          item: potentialMissingItem.name,
          numberOfItems: selectedItemsCopy.length
        })
      } else {
        const selectedItemsCopy = selectedItems.filter(
          (selectedItem) =>
            selectedItem.productId !== potentialMissingItem.productId
        )
        setSelectedItems(selectedItemsCopy)
      }
    },
    [selectedItems, selfResolutionTracking]
  )

  const onSubmit = useCallback(() => {
    const idsInOrder = potentialMissingItems.map((item) => item.productId)
    const sortedSelectedItems = selectedItems.sort((a, b) => {
      return idsInOrder.indexOf(a.productId) - idsInOrder.indexOf(b.productId)
    })
    onSelected(sortedSelectedItems)
  }, [potentialMissingItems, selectedItems, onSelected])

  return (
    <div>
      <div className={STYLES.question}>
        <Text text={question} translate={false} />
      </div>
      {meals.length > 0 && (
        <ProductSection
          namespace={namespace}
          title={'reportIssue.pouches'}
          potentialMissingItems={meals}
          selectedItems={selectedItems}
          onCheckboxChange={onCheckboxChange}
        />
      )}
      {extras.length > 0 && (
        <ProductSection
          namespace={namespace}
          title={'reportIssue.extras'}
          potentialMissingItems={extras}
          selectedItems={selectedItems}
          onCheckboxChange={onCheckboxChange}
        />
      )}
      <Link to={SelfResolutionRoutes.ContactCustomerLove}>
        <Text
          namespace={namespace}
          text={'reportIssue.cantSeeItem'}
          variant="textRegular16"
          colour="brandBlue500"
          align={'center'}
        />
      </Link>
      <div className={STYLES.stickyButton}>
        <Button
          typography={{
            namespace,
            text: 'reportIssue.selectItems'
          }}
          disableAnalytics
          disabled={selectedItems.length === 0}
          onClick={onSubmit}
        />
      </div>
    </div>
  )
}

export { Props }
export default ProductSelector
