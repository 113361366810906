// @noflow

/* eslint-disable i18next/no-literal-string */
import { useNotifications } from '@/context/notifications/notifications'
import { ACCOUNT_ROUTES } from '@/routes'
import { useMutation } from '@apollo/client'
import i18next from 'i18next'
import isEqual from 'lodash/isEqual'
import upperFirst from 'lodash/upperFirst'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { possessive } from '@/utils/StringHelper'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import Alert from '@/components/elements/atoms/Alert/AlertCard'
import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'
import { FixedBase } from '@/components/elements/atoms/FixedBase'
import Image from '@/components/elements/atoms/Image/Image'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import SkeletonButton from '@/components/elements/atoms/SkeletonButton/SkeletonButton'
import PillGroup, {
  Props as PillGroupProps
} from '@/components/elements/molecules/PillGroup/PillGroup'
import RadioGroup from '@/components/elements/molecules/RadioGroup/RadioGroup'

import STYLES from './UpdateDogMedicalInfo.module.sass'

import { UPDATE_MEDICAL_INFO_MUTATION } from '../mutations/updateMedicalInfoMutation'

import type {
  UpdateMedicalInfoMutation,
  UpdateMedicalInfoMutationVariables
} from '../mutations/__generated__/UpdateMedicalInfoMutation'
import type {
  MedicalInfoQuery_user_dogs as Dog,
  MedicalInfoQuery_healthIssues as HealthIssue,
  MedicalInfoQuery
} from '../queries/__generated__/MedicalInfoQuery'

type Props = Partial<MedicalInfoQuery> & {
  selectedDog?: Dog
  loading: boolean
}

enum OptionsTypes {
  allergies = 'allergies',
  healthIssues = 'health_issues'
}

enum Options {
  yes = 'yes',
  no = 'no'
}

const UpdateDogMedicalInfoContent = ({
  selectedDog,
  loading,
  ...restProps
}: Props): JSX.Element => {
  const container = useRef<HTMLDivElement>(null)
  const { setErrorNotification, setSuccessNotification } = useNotifications()
  const navigate = useNavigate()
  const { windowWidth } = useWindowSize()
  const { t } = useTranslation('account')

  const copyContext = 'update_dog_profile_medical'
  const isMobile = windowWidth < BREAKPOINTS.md

  const loadedData = (() => {
    if (loading) {
      return null
    }

    const { user, allergens, healthIssues } = restProps
    const { id: userId, dogs } = user ?? {}

    return {
      userId,
      dogs,
      allergens,
      healthIssues
    }
  })()

  const [shouldSetInitialData, setShouldSetInitialData] = useState(true)
  const [medicalInfo, setMedicalInfo] = useState({
    hasAllergies: Options.no,
    hasHealthIssues: Options.no,
    allergiesIds: [] as Array<string>,
    healthIssuesIds: [] as Array<string>
  })

  const genderContext = selectedDog?.gender
  const possessiveDogName =
    selectedDog && possessive(selectedDog.name, i18next.language)

  const noChangeToMedicalInfo = (() => {
    if (selectedDog) {
      const { healthIssues, allergens } = selectedDog

      const allergensIds = allergens.map((allergen) => allergen.id)
      const healthIssuesIds = healthIssues.map((healthIssue) => healthIssue.id)

      return (
        isEqual(medicalInfo.allergiesIds.sort(), allergensIds.sort()) &&
        isEqual(medicalInfo.healthIssuesIds.sort(), healthIssuesIds.sort())
      )
    }
  })()

  const createStatusOptions = (type: OptionsTypes, status: Options) => {
    const statusKeyMap: Record<OptionsTypes, keyof typeof medicalInfo> = {
      [OptionsTypes.allergies]: 'hasAllergies',
      [OptionsTypes.healthIssues]: 'hasHealthIssues'
    }

    return {
      id: `${type}-${status}`,
      text: {
        text: `${copyContext}.options.status`,
        namespace: 'account',
        variables: {
          context: status
        }
      },
      value: status,
      defaultChecked: status === medicalInfo[statusKeyMap[type]]
    }
  }

  const allergiesStatusOptions = Object.values(Options).map((status: Options) =>
    createStatusOptions(OptionsTypes.allergies, status)
  )

  const healthIssuesStatusOptions = Object.values(Options).map(
    (status: Options) => createStatusOptions(OptionsTypes.healthIssues, status)
  )

  const selectItems = useCallback(
    (
      e: React.FormEvent<HTMLDivElement> | undefined,
      type: 'allergies' | 'healthIssues'
    ): void => {
      if (e) {
        const clickedElement = e.target as HTMLInputElement
        const value = clickedElement.value

        setMedicalInfo((prevState) => {
          const updatedIds = clickedElement.checked
            ? [...prevState[`${type}Ids`], value]
            : prevState[`${type}Ids`].filter((id) => id !== value)

          return {
            ...prevState,
            [`${type}Ids`]: updatedIds
          }
        })
      }
    },
    []
  )

  const selectAllergens = useCallback(
    (e: React.FormEvent<HTMLDivElement> | undefined) => {
      selectItems(e, 'allergies')
    },
    [selectItems]
  )

  const selectHealthIssues = useCallback(
    (e: React.FormEvent<HTMLDivElement> | undefined) => {
      selectItems(e, 'healthIssues')
    },
    [selectItems]
  )

  const createPillOptions = (
    items: { id: string; name: string }[],
    type: OptionsTypes,
    selectFunction: (e: React.FormEvent<HTMLDivElement> | undefined) => void,
    checkedIds: string[]
  ): PillGroupProps['pillOptions'] =>
    items.map(({ id, name }) => ({
      id: `${type}-${id}`,
      value: id,
      name,
      text: name,
      translate: false,
      onChange: selectFunction,
      checked: checkedIds.includes(id),
      namespace: 'account',
      variant: 'checkbox'
    })) ?? []

  const allergiesPillOptions = createPillOptions(
    loadedData?.allergens || [],
    OptionsTypes.allergies,
    selectAllergens,
    medicalInfo.allergiesIds
  )

  const healthIssuesPillOptions = createPillOptions(
    loadedData?.healthIssues || [],
    OptionsTypes.healthIssues,
    selectHealthIssues,
    medicalInfo.healthIssuesIds
  )

  const healthIssuesNotification = React.useMemo(() => {
    const warningHealthIssues: Array<HealthIssue> = []
    const goodHealthIssues: Array<HealthIssue> = []

    loadedData?.healthIssues?.forEach((issue) => {
      if (medicalInfo.healthIssuesIds.includes(issue.id)) {
        issue.warning
          ? warningHealthIssues.push(issue)
          : goodHealthIssues.push(issue)
      }
    })

    const commonAlertMessageProps = {
      namespace: 'account',
      translate: true,
      align: 'left'
    } as const

    if (warningHealthIssues.length > 0) {
      return {
        message: {
          text: `${copyContext}.health_issues.alert.warning`,
          variables: {
            count: warningHealthIssues.length,
            healthIssue: warningHealthIssues[0].name
          },
          ...commonAlertMessageProps
        },
        variant: 'warning'
      } as const
    }

    if (goodHealthIssues.length > 0) {
      return {
        message: {
          text: `${copyContext}.health_issues.alert.good`,
          variables: {
            dogName: upperFirst(selectedDog?.name)
          },
          ...commonAlertMessageProps
        },
        variant: 'success'
      } as const
    }

    return null
  }, [loadedData, medicalInfo.healthIssuesIds, selectedDog])

  const onSelectOption = useCallback(
    (index: number, field: keyof typeof medicalInfo) => {
      const selectedValue = Object.values(Options)[index]

      setMedicalInfo((prevState) => ({
        ...prevState,
        [field]: selectedValue
      }))
    },
    []
  )

  const onAllergiesStatusSelect = useCallback(
    (index: number) => {
      onSelectOption(index, 'hasAllergies')
    },
    [onSelectOption]
  )

  const onHealthIssuesStatusSelect = useCallback(
    (index: number) => onSelectOption(index, 'hasHealthIssues'),
    [onSelectOption]
  )

  const contactUs = useCallback(() => {
    window.location.href = '/contact'
  }, [])

  /**
   * Update dog medical info mutation
   *
   * On error, show error message and log error to Sentry.
   * On success, show success message, refetch user data and navigate
   * to the correct screen.
   */
  const [
    updateMedicalConditionMutation,
    { loading: updateDogMedicalInfoLoading }
  ] = useMutation<
    UpdateMedicalInfoMutation,
    UpdateMedicalInfoMutationVariables
  >(UPDATE_MEDICAL_INFO_MUTATION, {
    onError: () => {
      setErrorNotification({
        text: `${copyContext}.notifications.error`,
        namespace: 'account'
      })
    },
    onCompleted: () => {
      setSuccessNotification({
        text: `${copyContext}.notifications.success`,
        namespace: 'account',
        variables: {
          possessiveDogName
        }
      })

      navigate(`${ACCOUNT_ROUTES.profile}/${selectedDog?.id}`)
    }
  })

  /**
   * Hander to update dog medical info
   */
  const updateMedicalConditionDetails = useCallback(async () => {
    try {
      await updateMedicalConditionMutation({
        variables: {
          userId: loadedData?.userId ?? '',
          attributes: [
            {
              id: selectedDog?.id ?? '',
              healthIssueIds:
                medicalInfo.hasHealthIssues === Options.yes
                  ? medicalInfo.healthIssuesIds
                  : [],
              allergenIds:
                medicalInfo.hasAllergies === Options.yes
                  ? medicalInfo.allergiesIds
                  : []
            }
          ]
        }
      })
    } catch (e) {
      setErrorNotification({
        text: `${copyContext}.notifications.error`,
        namespace: 'account'
      })
    }
  }, [
    selectedDog?.id,
    loadedData,
    setErrorNotification,
    updateMedicalConditionMutation,
    medicalInfo
  ])

  useEffect(() => {
    if (shouldSetInitialData && loadedData && selectedDog) {
      const { healthIssues, allergens } = selectedDog

      setMedicalInfo({
        ...medicalInfo,
        hasAllergies: allergens?.length > 0 ? Options.yes : Options.no,
        hasHealthIssues: healthIssues?.length > 0 ? Options.yes : Options.no,
        allergiesIds: allergens?.map(({ id }) => id) ?? [],
        healthIssuesIds: healthIssues?.map(({ id }) => id) ?? []
      })

      // Prevent the effect from running again
      setShouldSetInitialData(false)
    }
  }, [shouldSetInitialData, loadedData, medicalInfo, selectedDog])

  const getSectionWrapperProps = (type: string) =>
    ({
      headerTypography: {
        text: `${copyContext}.${type}.title`,
        namespace: 'account',
        variant: 'display16',
        shouldScale: false,
        margin: false,
        variables: {
          context: genderContext
        }
      },
      margin: {
        top: 0,
        bottom: 0
      }
    } as const)

  const isUpdateDisabled =
    loading || updateDogMedicalInfoLoading || noChangeToMedicalInfo

  return (
    <div ref={container} className={STYLES.container}>
      <Card
        shadow
        padding={isMobile ? 24 : 32}
        width={520}
        className={`${STYLES.card} ${
          medicalInfo.hasHealthIssues === Options.yes
            ? STYLES.noBottomPadding
            : ''
        }`}
      >
        <div className={STYLES.cardContent}>
          <SectionWrapper {...getSectionWrapperProps('allergies')}>
            <div className={STYLES.sectionContent}>
              {loading ? (
                <div className={STYLES.rowGridWrapper}>
                  <SkeletonButton width="100%" height={60} margin="0" />
                  <SkeletonButton width="100%" height={60} margin="0" />
                </div>
              ) : (
                <>
                  <RadioGroup
                    onChange={onAllergiesStatusSelect}
                    radioOptions={allergiesStatusOptions}
                    variant="horizontal"
                    identifier={`${copyContext}.allergies`}
                    version="v2"
                  />
                  {medicalInfo.hasAllergies === Options.yes && (
                    <div className={STYLES.itemsWrapper}>
                      <SectionWrapper
                        headerTypography={{
                          text: `${copyContext}.allergies.sub_title`,
                          namespace: 'account',
                          variant: 'display16',
                          shouldScale: false,
                          margin: false,
                          variables: {
                            context: genderContext
                          }
                        }}
                        margin={{ top: 0, bottom: 0 }}
                      >
                        <PillGroup
                          pillOptions={allergiesPillOptions}
                          justifyContent="flex-start"
                          version="v2"
                        />
                      </SectionWrapper>
                    </div>
                  )}
                </>
              )}
            </div>
          </SectionWrapper>
          <SectionWrapper {...getSectionWrapperProps('health_issues')}>
            <div className={STYLES.sectionContent}>
              {loading ? (
                <div className={STYLES.rowGridWrapper}>
                  <SkeletonButton width="100%" height={60} margin="0" />
                  <SkeletonButton width="100%" height={60} margin="0" />
                </div>
              ) : (
                <>
                  <RadioGroup
                    onChange={onHealthIssuesStatusSelect}
                    radioOptions={healthIssuesStatusOptions}
                    variant="horizontal"
                    identifier={`${copyContext}.health_issues`}
                    version="v2"
                  />
                  {medicalInfo.hasHealthIssues === Options.yes && (
                    <div
                      className={`${STYLES.itemsWrapper} ${STYLES.itemsWrapperHealthIssues}`}
                    >
                      <SectionWrapper
                        headerTypography={{
                          text: `${copyContext}.health_issues.sub_title`,
                          namespace: 'account',
                          variant: 'display16',
                          shouldScale: false,
                          margin: false,
                          variables: {
                            context: genderContext
                          }
                        }}
                        margin={{ top: 0, bottom: 0 }}
                      >
                        <>
                          <PillGroup
                            pillOptions={healthIssuesPillOptions}
                            justifyContent="flex-start"
                            version="v2"
                          />
                          {healthIssuesNotification && (
                            <SectionWrapper
                              margin={{ top: isMobile ? 16 : 24, bottom: 0 }}
                            >
                              <div className={STYLES.healthIssuesAlert}>
                                <Alert {...healthIssuesNotification} />
                                {healthIssuesNotification.variant ===
                                  'warning' && (
                                  <Button
                                    identifier="dog_medical_info.contact_us"
                                    onClick={contactUs}
                                    typography={{
                                      text: `${copyContext}.health_issues.contact_us`,
                                      namespace: 'account'
                                    }}
                                  />
                                )}
                              </div>
                            </SectionWrapper>
                          )}
                        </>
                      </SectionWrapper>
                    </div>
                  )}
                </>
              )}
            </div>
          </SectionWrapper>
        </div>
      </Card>
      <div className={STYLES.bottomImageWrapper}>
        <Image
          alt={t(`${copyContext}.image_alt`)}
          slug="dog-profile-medical"
          image={{
            width: 240,
            height: 125
          }}
        />
      </div>
      <FixedBase backgroundColor="brandWhite90" container={container}>
        <div className={STYLES.footerContent}>
          <Button
            identifier="dog_medical_info.update"
            onClick={updateMedicalConditionDetails}
            typography={{
              text: `${copyContext}.button`,
              namespace: 'account'
            }}
            skeleton={{
              isLoading: loading
            }}
            disabled={isUpdateDisabled}
          />
        </div>
      </FixedBase>
    </div>
  )
}

export { UpdateDogMedicalInfoContent }
