// @noflow
import { useReactiveVar } from '@apollo/client'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'

import useWebviewHanlder from '@/hooks/useWebviewHandler/useWebviewHandler'

import { Button } from '@/components/elements/atoms/Button'
import Icon from '@/components/elements/atoms/Icon/Icon'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './CustomerIssueManagementHeader.module.sass'

import {
  customerIssueManagementState,
  pageHeaderShowBackButtonState,
  pageTitleState,
  routeState
} from '../../CustomerIssueManagementPage'
import CustomerIssueManagementRoutes from '../../types/routes'

type Props = {
  close: () => void
}

const CustomerIssueManagementHeader = ({ close }: Props): JSX.Element => {
  const customerIssueManagementData = useReactiveVar(
    customerIssueManagementState
  )
  const pageTitleValue = useReactiveVar(pageTitleState)
  const pageHeaderShowBackButtonValue = useReactiveVar(
    pageHeaderShowBackButtonState
  )
  const { t } = useTranslation(customerIssueManagementData.namespace)
  const webviewHandler = useWebviewHanlder()
  const navigate = useNavigate()
  const location = useLocation()
  const [initialised, setInitialised] = useState(false)

  useEffect(() => {
    if (!initialised) {
      document.body.classList.remove('hide-dixa')
      customerIssueManagementState({
        ...customerIssueManagementData,
        initialHistoryLength: window.history.length || 1
      })
      setInitialised(true)
    }
  }, [initialised, customerIssueManagementData])

  const isFirstPage = useCallback(() => {
    return (
      location.pathname === CustomerIssueManagementRoutes.Categories &&
      !location.search.includes('category_id')
    )
  }, [location.pathname, location.search])

  const goBack = useCallback(() => {
    if (webviewHandler.isWebview && isFirstPage()) {
      webviewHandler.postMessage('GO-BACK')
    } else {
      navigate(-1)
      routeState(null)
    }
  }, [navigate, isFirstPage, webviewHandler])

  const title = useMemo((): string => {
    if (pageTitleValue) {
      return t(pageTitleValue)
    } else if (customerIssueManagementData.selectedCategory?.name) {
      return customerIssueManagementData.selectedCategory?.name
    } else {
      return t('default')
    }
  }, [pageTitleValue, customerIssueManagementData.selectedCategory?.name, t])

  return (
    <div className={STYLES.heading}>
      <div className={STYLES.headingButtons}>
        <div className={STYLES.backButton}>
          {pageHeaderShowBackButtonValue && (
            <Button
              typography={{
                namespace: customerIssueManagementData.namespace,
                text: 'header.back'
              }}
              variant={'ghost'}
              disabled={false}
              onClick={goBack}
              icon={{
                position: 'left',
                component: (
                  <Icon
                    size={18}
                    asset={'chevron'}
                    direction={'left'}
                    accentColour={'brandBlue400'}
                  />
                )
              }}
              displayText="always"
              disableAnalytics
            />
          )}
        </div>
        <div className={STYLES.closeButton}>
          <Button
            typography={{
              namespace: customerIssueManagementData.namespace,
              text: 'header.close'
            }}
            variant={'ghost'}
            disabled={false}
            onClick={close}
            icon={{
              position: 'left',
              component: (
                <Icon size={18} asset={'close'} accentColour={'brandBlue400'} />
              )
            }}
            displayText="never"
            disableAnalytics
          />
        </div>
      </div>
      <div className={STYLES.desktopTitle}>
        <Text
          variant={'display36'}
          namespace={customerIssueManagementData.namespace}
          element={'h1'}
          text={title}
          translate={false}
        />
      </div>
      <div className={STYLES.mobileTitle}>
        <Text
          variant={'display28'}
          namespace={customerIssueManagementData.namespace}
          element={'h1'}
          text={title}
          translate={false}
        />
      </div>
    </div>
  )
}

export default CustomerIssueManagementHeader
