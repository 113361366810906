// @flow

import * as React from 'react'
import { format, parseISO } from 'date-fns'
import { formatCurrencyWithDecimal } from '@/utils/currency'
import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { useTranslation } from 'react-i18next'

// Types
import type { Payout, PayoutType } from './../../message_types'
import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'
import type { Language } from '@/packs/localisation'

type Props = {|
  payouts: Array<Payout>,
  shippingCountryCode: CountryCode,
  preferredLanguage: Language
|}

// eslint-disable-next-line flowtype/no-weak-types
const formatPayoutType = (payoutType: PayoutType, namespace: string, t: any): string => {
  switch (payoutType) {
    case 'charity_donation': {
      return t(`${namespace}.charity_donation`)
    }
    case 'monetary': {
      return t(`${namespace}.monetary`)
    }
    case 'off_next_box': {
      return t(`${namespace}.off_next_box`)
    }
    default: {
      (payoutType: empty)
      throw new Error(`${payoutType} is not a handled payout type`)
    }
  }
}

const PayoutHistory = (props: Props): React.Node => {
  const { payouts, shippingCountryCode, preferredLanguage } = props
  const { locale, currency } = countryCodeToLocaleCurrency(shippingCountryCode, preferredLanguage)
  const { t } = useTranslation('ambassadors')
  const namespace = 'dashboard.balance_tab'

  return (
    <div>
      <div className='table-and-heading-container'>
        <h1>
          { t(`${namespace}.payout_history`) }
        </h1>
        <div className='table-and-subheading-container'>
          <div className='table-wrapper'>
            <table>
              <thead>
                <tr>
                  <th>
                    { t(`${namespace}.date`) }
                  </th>
                  <th>
                    { t(`${namespace}.payout_type`) }
                  </th>
                  <th>
                    { t(`${namespace}.amount`) }
                  </th>
                </tr>
              </thead>
              {
                payouts.map(({ date, payout_type, amount }: Payout): React.Node => (
                  <tr
                    key={ format(parseISO(date), 'MMMMdy') }
                  >
                    <td>
                      { format(parseISO(date), 'MMMM d, y') }
                    </td>
                    <td>
                      { formatPayoutType(payout_type, namespace, t) }
                    </td>
                    <td>
                      { formatCurrencyWithDecimal(amount, { locale: locale, currency: currency }) }
                    </td>
                  </tr>
                ))
              }
              <tbody />
            </table>
          </div>
          <div className='total-summary'>
            <p>
              {
                `${t(`${namespace}.total`)}: ${
                  formatCurrencyWithDecimal(
                    payouts.reduce((total: number, { amount }: Payout): number => total + amount, 0)
                  , { locale: locale, currency: currency })
                }`
              }
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PayoutHistory
