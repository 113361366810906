// @noflow
import { useReactiveVar } from '@apollo/client'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import EmailIcon from 'assets/images/icons/envelope--blue.svg'
import PhoneIcon from 'assets/images/icons/phone--white.svg'
import WhatsAppIcon from 'assets/images/icons/social-media/icon-whatsapp--blue.svg'
import SpeechBubbleIcon from 'assets/images/icons/speech-bubble-square.svg'

import { Button } from '@/components/elements/atoms/Button'
import Link from '@/components/elements/atoms/Link/Link'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './ContactCustomerLove.module.sass'

import { selfResolutionPageState } from '../../SelfResolutionPage'
import useSelfResolutionTracking from '../../analytics'

type Props = {
  namespace: string
  pageName: string
}

const ContactCustomerLoveDetails = ({
  namespace,
  pageName
}: Props): JSX.Element => {
  const selfResolutionState = useReactiveVar(selfResolutionPageState)
  const { t } = useTranslation(namespace)
  const whatsappHref = 'tel:' + t('contact_customer_love.whatsapp.number')
  const callHref = 'tel:' + t('contact_customer_love.call.number')
  const emailHref = 'mailto:' + t('contact_customer_love.email.address')
  const selfResolutionTracking = useSelfResolutionTracking()

  useEffect(() => {
    document.body.classList.remove('hide-dixa')
  }, [pageName])

  const trackContactMethod = useCallback(
    (method: string) => {
      selfResolutionTracking.trackEvent('contacted CL', { method })
    },
    [selfResolutionTracking]
  )

  const trackWhatsApp = useCallback(() => {
    trackContactMethod('WhatsApp')
  }, [trackContactMethod])

  const trackCall = useCallback(() => {
    trackContactMethod('Call')
  }, [trackContactMethod])

  const trackEmail = useCallback(() => {
    trackContactMethod('Email')
  }, [trackContactMethod])

  const disableChat = useCallback(() => {
    const dixaWidget = document.getElementsByClassName(
      'dixa-messenger-wrapper'
    )[0] as HTMLElement
    return !window._dixa_ || !dixaWidget
  }, [])

  const openChat = useCallback(() => {
    trackContactMethod('online chat')
    window._dixa_.invoke('setWidgetOpen', true)
  }, [trackContactMethod])

  const chatNowText = useCallback(() => {
    return disableChat()
      ? 'contact_customer_love.liveChat.chatClosed'
      : 'contact_customer_love.liveChat.chatNow'
  }, [disableChat])

  const renderHelpText = useCallback(() => {
    if (selfResolutionState.reportId !== '') {
      return (
        <>
          <Text
            namespace={namespace}
            text={'contact_customer_love.text2WithRef'}
            align="left"
            variant={'textRegular16'}
            colour={'brandBlue500'}
          />
          <div className={STYLES.reference}>
            <Text
              namespace={namespace}
              text={'contact_customer_love.referenceNumberLabel'}
              align="left"
              variant={'textRegular16'}
              colour={'brandBlue500'}
            />
            <Text
              text={`#${selfResolutionState.reportId}`}
              align="left"
              variant={'display16'}
              colour={'brandBlue500'}
              translate={false}
            />
          </div>
        </>
      )
    } else {
      return (
        <Text
          namespace={namespace}
          text={'contact_customer_love.text2'}
          align="left"
          variant={'textRegular16'}
          colour={'brandBlue500'}
        />
      )
    }
  }, [selfResolutionState, namespace])

  return (
    <div className={STYLES.contactCustomerLove}>
      <div className={STYLES.intro}>
        <Text
          namespace={namespace}
          text={'contact_customer_love.header'}
          align="left"
          variant={'display20'}
          element={'h2'}
          colour={'brandBlue500'}
        />
        <Text
          namespace={namespace}
          text={'contact_customer_love.text1'}
          align="left"
          variant={'textRegular16'}
          colour={'brandBlue500'}
        />
        {renderHelpText()}
      </div>
      <Text
        namespace={namespace}
        text={'contact_customer_love.contactOptions'}
        align="left"
        variant={'display20'}
        element={'h3'}
        colour={'brandBlue500'}
      />
      <div className={STYLES.section}>
        <img
          loading="lazy"
          className={`${STYLES.icon} ${STYLES.whatsapp}`}
          src={WhatsAppIcon}
          alt={t(`contact_customer_love.whatsapp.title`)}
        />
        <Text
          namespace={namespace}
          text={'contact_customer_love.whatsapp.title'}
          align="left"
          variant={'display16'}
          element={'h4'}
          colour={'brandBlue500'}
        />
        <p className={STYLES.link}>
          <Link
            namespace={namespace}
            suffix={null}
            href={whatsappHref}
            text={'contact_customer_love.whatsapp.number'}
            onClick={trackWhatsApp}
            identifier="self_resolution_page.contact_customer_love.whatsapp_number"
          />
        </p>
        <Text
          namespace={namespace}
          text={'contact_customer_love.whatsapp.monFri'}
          align="left"
          variant={'textRegular16'}
          colour={'brandBlue500'}
        />
        <Text
          namespace={namespace}
          text={'contact_customer_love.whatsapp.satSun'}
          align="left"
          variant={'textRegular16'}
          colour={'brandBlue500'}
        />
        <Text
          namespace={namespace}
          text={'contact_customer_love.whatsapp.bankHols'}
          align="left"
          variant={'textRegular16'}
          colour={'brandBlue500'}
        />
      </div>
      <div className={STYLES.section}>
        <img
          loading="lazy"
          alt=""
          className={`${STYLES.icon} ${STYLES.liveChat}`}
          src={SpeechBubbleIcon}
        />
        <Text
          namespace={namespace}
          text={'contact_customer_love.liveChat.title'}
          align="left"
          variant={'display16'}
          element={'h4'}
          colour={'brandBlue500'}
        />
        <Text
          namespace={namespace}
          text={'contact_customer_love.liveChat.monFri'}
          align="left"
          variant={'textRegular16'}
          colour={'brandBlue500'}
        />
        <Text
          namespace={namespace}
          text={['contact_customer_love.liveChat.satSun', '']}
          align="left"
          variant={'textRegular16'}
          colour={'brandBlue500'}
        />
        <Text
          namespace={namespace}
          text={['contact_customer_love.liveChat.bankHols', '']}
          align="left"
          variant={'textRegular16'}
          colour={'brandBlue500'}
        />
        <Button
          typography={{
            namespace,
            text: chatNowText()
          }}
          onClick={openChat}
          disabled={disableChat()}
          variant={'secondary'}
          disableAnalytics
        />
      </div>
      <div className={STYLES.section}>
        <img
          loading="lazy"
          alt=""
          className={`${STYLES.icon} ${STYLES.call}`}
          src={PhoneIcon}
        />
        <Text
          namespace={namespace}
          text={'contact_customer_love.call.title'}
          align="left"
          variant={'display16'}
          element={'h4'}
          colour={'brandBlue500'}
        />
        <p className={STYLES.link}>
          <Link
            namespace={namespace}
            suffix={null}
            text={'contact_customer_love.whatsapp.number'}
            href={callHref}
            onClick={trackCall}
            identifier="self_resolution_page.contact_customer_love.call_number"
          />
        </p>
        <Text
          namespace={namespace}
          text={'contact_customer_love.call.monFri'}
          align="left"
          variant={'textRegular16'}
          colour={'brandBlue500'}
        />
        <Text
          namespace={namespace}
          text={'contact_customer_love.call.satSun'}
          align="left"
          variant={'textRegular16'}
          colour={'brandBlue500'}
        />
        <Text
          namespace={namespace}
          text={'contact_customer_love.call.bankHols'}
          align="left"
          variant={'textRegular16'}
          colour={'brandBlue500'}
        />
      </div>
      <div className={STYLES.section}>
        <img
          loading="lazy"
          alt=""
          className={`${STYLES.icon} ${STYLES.email}`}
          src={EmailIcon}
        />
        <Text
          namespace={namespace}
          text={'contact_customer_love.email.title'}
          align="left"
          variant={'display16'}
          element={'h4'}
          colour={'brandBlue500'}
        />
        <p className={STYLES.link}>
          <Link
            namespace={namespace}
            suffix={null}
            href={emailHref}
            text={'contact_customer_love.email.address'}
            onClick={trackEmail}
            identifier="self_resolution_page.contact_customer_love.email_address"
          />
        </p>
      </div>
    </div>
  )
}

export default ContactCustomerLoveDetails
