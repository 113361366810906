// @flow
import * as React from 'react'

type Props = {
  header: string,
  content: any
}

class AffiliatesSummaryGridItemComponent extends React.Component<Props> {
  render () {
    return (
      <div className='affiliate-summary__grid-item-container'>
        <div className='affiliate-summary__grid-item'>
          <h2>
            { this.props.header }
          </h2>
          { this.props.content }
        </div>
      </div>
    )
  }
}

export default AffiliatesSummaryGridItemComponent
