// @noflow
import type { Code as ShippingCountryCode } from '@/shared_types/rails_models/shipping_countries'

import { PaymentMethod } from '../../types'

type PaymentMethodsAvaliable = {
  [key in ShippingCountryCode]: Array<PaymentMethod>
}

const paymentMethodsAvalible: PaymentMethodsAvaliable = {
  IE: ['creditCard', 'applePay', 'googlePay', 'payPal'],
  NI: ['creditCard', 'applePay', 'googlePay', 'payPal'],
  BE: ['creditCard', 'applePay', 'googlePay', 'bancontact'],
  NL: ['creditCard', 'iDeal', 'applePay', 'googlePay'],
  GB: ['creditCard', 'applePay', 'googlePay', 'payPal'],
  PL: ['creditCard', 'applePay', 'googlePay'],
  DE: ['creditCard', 'applePay', 'googlePay', 'payPal']
}

const findPaymentMethodsAvaliable = (
  countryCode: ShippingCountryCode
): Array<PaymentMethod> => {
  return paymentMethodsAvalible[countryCode]
}

export { findPaymentMethodsAvaliable }
