import React from 'react'

import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './PageTitle.module.sass'

const PageTitle = (): JSX.Element => {
  const namespace = 'treatments_paused_page'
  return (
    <div className={STYLES.pageTitleContainer}>
      <div className={STYLES.pageTitleWrapper}>
        <span className={STYLES.pauseIcon} />
        <Text
          namespace={namespace}
          text={`page_title`}
          element="h1"
          variant="display36"
          align="left"
          margin={false}
        />
      </div>
      <div className={STYLES.pageTitleBottom} />
    </div>
  )
}

export default PageTitle
