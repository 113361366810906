// @flow

import * as React from 'react'
import i18next from 'i18next'

import DogOpeningBox from '../../../../../assets/images/illustrations/dogs/schnauzer-opening-box.svg'

type Props = {|
  baseUrl: string
|}

class CompleteTrialAccount extends React.Component<Props> {
  namespace = 'ambassadors:dashboard.balance_tab'

  render (): React.Node {
    return (
      <div className='ambassador-balance__card'>
        <div className='ambassador-balance__card__header'>
          <h2>{ i18next.t(`${this.namespace}.keep_tail_wagging`) }</h2>
          <img
            src={DogOpeningBox}
            alt=''
          />
          <p>{ i18next.t(`${this.namespace}.hope_pup_loved_food`) }</p>
          <a
            className='btn btn-updated--blue'
            href={`${this.props.baseUrl}/dashboard#subscription`}
          >
            { i18next.t(`${this.namespace}.order_my_next_box`) }
          </a>
        </div>
      </div>
    )
  }
}

export default CompleteTrialAccount
