import React from 'react'

import Image from '@/components/elements/atoms/Image/Image'

import STYLES from './AppDisplays.module.sass'

const AppDisplays = (): JSX.Element => (
  <div className={STYLES.displaysWrapper}>
    <Image
      alt=""
      slug="app-deliveries-screen"
      image={{
        width: 524,
        height: 1051
      }}
    />
    <Image
      alt=""
      slug="app-splash-screen"
      image={{
        width: 524,
        height: 1051
      }}
    />
    <Image
      alt=""
      slug="app-plan-screen"
      image={{
        width: 524,
        height: 1051
      }}
    />
  </div>
)

export default AppDisplays
