// @noflow
import { FetchResult } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import i18next from 'i18next'

import { bannerMessageState } from '../components/Banner/Banner'
import { Analytics } from '@/components/analytics/Analytics'
import client from '@/components/apollo/client'

import { DIRECT_SALES_SUBSCRIPTION_CREATION } from '../mutations/mutations'

import { DirectSalesAppSubscriptionCreate } from '../mutations/__generated__/DirectSalesAppSubscriptionCreate'

import * as ANALYTICS from '../Analytics/Analytics'
import { ConstructSubscriptionInput } from '../helpers/buiildSubscriptionData'
import {
  LoginUrlProps,
  setSubmissionState
} from '../screens/PaymentScreen/PaymentScreen'

const submitSubscription = ({
  data,
  paymentMethodId,
  navigateToNextStep
}: {
  data: ConstructSubscriptionInput
  paymentMethodId?: string
  navigateToNextStep: (input: LoginUrlProps) => void
}): FetchResult<DirectSalesAppSubscriptionCreate> | void => {
  client
    .mutate<DirectSalesAppSubscriptionCreate>({
      mutation: DIRECT_SALES_SUBSCRIPTION_CREATION,
      variables: {
        chargedOnBoxOne: data.chargedOnBoxOne,
        discountCodeId: data.discountCodeId,
        email: data.email,
        phoneNumber: data.phoneNumber,
        address: data.address,
        singlePuppyNotBroughtHome: data.singlePuppyNotBroughtHome,
        supportedMarketingMethodPurposeIds:
          data.supportedMarketingMethodPurposeIds,
        subscriptionInput: {
          ...data.subscriptionInput,
          paymentMethodId:
            paymentMethodId || data.subscriptionInput.paymentMethodId
        }
      }
    })
    .then((response) => {
      if (response.errors) {
        bannerMessageState({
          message: response.errors[0].message
        })
      }
      if (response.data?.directSalesSubscriptionCreate) {
        Analytics.track(
          'Direct Sales Subscription Created Via Pay On Your Own Device',
          {
            userId: response.data.directSalesSubscriptionCreate.userId,
            paymentProvider: data.subscriptionInput.paymentProvider,
            direct_sales_representative_tracking_id:
              response.data.directSalesSubscriptionCreate
                .directSalesRepresentative.trackingId,
            team: response.data.directSalesSubscriptionCreate
              .directSalesRepresentative.directSalesTeam.name
          }
        )
        navigateToNextStep({
          signUpUrl: response.data.directSalesSubscriptionCreate.userSignInUrl,
          referralUrl:
            response.data.directSalesSubscriptionCreate.referralLinkUrl
        })
        return response
      }
    })
    .catch((error) => {
      Sentry.captureException('Error creating PayOnOwnDevice Subscription', {
        extra: { message: error.message }
      })

      ANALYTICS.setPaymentError({
        error:
          error.message ||
          'DirectSalesCompletePayment Error in createPaymentMethod',
        paymentMethod: 'creditCard'
      })

      bannerMessageState({
        message:
          error?.message || i18next.t('checkout:errors.delivery_details_fetch')
      })

      setSubmissionState({
        type: 'error',
        error:
          error?.message || i18next.t('checkout:errors.delivery_details_fetch')
      })
    })
}

export { submitSubscription }
