// @noflow
import React from 'react'
import { useTranslation } from 'react-i18next'

import { getLocalisedPathnames } from '@/utils/urls'

import { CheckoutPage } from '../../queries/__generated__/CheckoutPage'

type Props = {
  namespace: string
  shippingCountryCode: CheckoutPage['guest']['assumedShippingCountry']['code']
}

const PlaceMyOrderDisclaimer = ({
  namespace,
  shippingCountryCode
}: Props): React.ReactElement => {
  const { t } = useTranslation(namespace)
  const pathnames = getLocalisedPathnames(shippingCountryCode)

  const copyContext = 'disclaimer'

  return (
    <div className="checkout-disclaimer">
      <p
        className="checkout-disclaimer__terms"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: t(`${copyContext}.terms_html`, {
            termsUrl: `/${pathnames.TERMS_OF_USE}`,
            termsConditionsUrl: `/${pathnames.TERMS_AND_CONDITIONS}`
          })
        }}
      />
    </div>
  )
}

export default PlaceMyOrderDisclaimer
