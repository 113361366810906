// @noflow
import { referralLinkEvent } from '@/helpers/ReferralLinkShareHelper'
import type { PartialLocation } from '@/helpers/ReferralLinkShareHelper'
import React from 'react'
import { useTranslation } from 'react-i18next'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

type Props = {
  namespace: string
  referralCode: string
  copyLinkMessage: string
  partialLocation?: PartialLocation
  onSuccess?: () => void
}

const copyContext = 'refer_a_friend.give_and_get_tab.copy_input_button'

const CopyInputButton = ({
  namespace,
  referralCode,
  copyLinkMessage,
  partialLocation = 'Dashboard',
  onSuccess
}: Props): JSX.Element => {
  const [copySuccess, setCopySuccess] = React.useState(false)
  const { windowWidth } = useWindowSize()
  const { t } = useTranslation(namespace)

  const copyReferralLink = React.useCallback(() => {
    const dummy = document.createElement('textarea')

    document.body.appendChild(dummy)
    // eslint-disable-next-line i18next/no-literal-string
    dummy.value = copyLinkMessage
    dummy.select()

    const success = document.execCommand('copy')
    document.body.removeChild(dummy)

    if (success) {
      setCopySuccess(true)
      if (onSuccess) {
        onSuccess()
      }
      setTimeout((): void => {
        setCopySuccess(false)
      }, 3000)
    }

    referralLinkEvent('Copy Link', partialLocation)
  }, [copyLinkMessage, onSuccess, partialLocation])

  const copyPromptKey = windowWidth < BREAKPOINTS.md ? 'mobile' : 'desktop'
  const copySuccessKey = 'success'

  return (
    <button
      className="copy-input__button"
      onClick={copyReferralLink}
      type="button"
    >
      <p className="text-regular-12">
        {t(`${copyContext}_${copySuccess ? copySuccessKey : copyPromptKey}`)}
      </p>
      <input
        id="copyInputButton"
        className="copy-input__input"
        value={referralCode}
        readOnly
        name="referral-link"
      />
    </button>
  )
}

export { Props }
export default CopyInputButton
