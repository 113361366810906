// @noflow
import { ACCOUNT_ROUTES } from '@/routes'
import { format } from 'date-fns'
import Cookies from 'js-cookie'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { localeToDateLocale } from '@/utils/countryCodeHelper'

import useInAccountRaf from '@/hooks/useInAccountRaf/useInAccountRaf'
import useWindowSize from '@/hooks/useWindowSize'

import { Button } from '@/components/elements/atoms/Button/Button'
import Image from '@/components/elements/atoms/Image/Image'
import Link from '@/components/elements/atoms/Link/Link'
import Modal from '@/components/elements/atoms/Modal/Modal'
import Text from '@/components/elements/atoms/Text/Text'
import { BREAKPOINTS } from '@/components/templates/Base'

import STYLES from './RafCampaignModal.module.sass'

import { Language } from '@/types'

import CopyInputButton from '../CopyInputButton/CopyInputButton'
import Countdown from '../Countdown/Countdown'

type Props = {
  isModalOpen: boolean
  setOpenModal: (isOpen: boolean) => void
  preferredLanguage: Language
}

const RAF_CAMPAIGN_COOKIE_KEY = 'has_closed_raf_campaign_jan_25_modal'

const RafCampaignModal = ({
  isModalOpen,
  setOpenModal,
  preferredLanguage
}: Props): JSX.Element | null => {
  const navigate = useNavigate()
  const { windowWidth } = useWindowSize()

  const isMobile = windowWidth < BREAKPOINTS.md

  const namespace = 'dashboard'
  const copyContext = 'refer_a_friend.raf_campaign_modal'
  const countdownCopyContext = 'refer_a_friend.campaign.countdown'

  const showModal = !Cookies.get(RAF_CAMPAIGN_COOKIE_KEY)

  const {
    customerDiscountCopy,
    referralDiscountCopy,
    copyLinkMessage,
    campaignEndDate,
    discountCode,
    shippingCountryCode
  } = useInAccountRaf()

  const setRafCampaignCookie = useCallback((viewed: string) => {
    Cookies.set(RAF_CAMPAIGN_COOKIE_KEY, viewed, {
      path: '/account'
    })
  }, [])

  const navigateToCampaignPage = useCallback(() => {
    setRafCampaignCookie('true')

    navigate(ACCOUNT_ROUTES.referAFriend)
  }, [navigate, setRafCampaignCookie])

  const toggleModal = useCallback(
    (open: boolean) => {
      if (!open) {
        setRafCampaignCookie('true')
      }
      setOpenModal(open)
    },
    [setOpenModal, setRafCampaignCookie]
  )

  const termsLink = useCallback(() => {
    switch (shippingCountryCode) {
      case 'PL':
        return 'https://psibufet.pl/terms-and-conditions-pl'
      default:
        return 'https://join.butternutbox.com/raf-campaign-terms/'
    }
  }, [shippingCountryCode])

  return (
    <Modal
      setOpenModal={toggleModal}
      isModalOpen={showModal && isModalOpen}
      width={450}
      bottomSticky={isMobile}
      padding={false}
    >
      <div className={STYLES.container}>
        <div className={STYLES.imageWrapper}>
          <Image
            slug="raf-campaign-modal-jan-25"
            image={{
              width: 700,
              height: 394,
              resizeMode: 'resize_to_fill',
              quality: 'auto:best'
            }}
            alt={`${copyContext}.image_alt`}
          />
        </div>
        <div className={STYLES.countdownBanner}>
          <Text
            text={`${copyContext}.countdown_text`}
            namespace={namespace}
            variant="display14"
            margin={false}
          />
          <Countdown
            countdownType="dailyCountdown"
            targetDate={campaignEndDate}
            labels={{
              daysLabel: `${countdownCopyContext}.days`,
              hoursLabel: `${countdownCopyContext}.hours`,
              minutesLabel: `${countdownCopyContext}.minutes`,
              secondsLabel: `${countdownCopyContext}.seconds`
            }}
            namespace={namespace}
            textColour="brandBlue500"
            variant="oneLine"
          />
        </div>
        <div className={STYLES.content}>
          <Text
            namespace={namespace}
            text={`${copyContext}.title`}
            variables={{
              referrerDiscount: customerDiscountCopy?.length
                ? customerDiscountCopy[0]
                : '',
              referralDiscount: referralDiscountCopy?.length
                ? referralDiscountCopy[0]
                : ''
            }}
            variant="display28"
            align="center"
            margin={false}
          />
          <Text
            namespace={namespace}
            text={`${copyContext}.description`}
            variables={{
              referrerDiscount: customerDiscountCopy?.length
                ? customerDiscountCopy[0]
                : '',
              referralDiscount: referralDiscountCopy?.length
                ? referralDiscountCopy[0]
                : '',
              date: format(new Date(campaignEndDate), 'do MMMM', {
                locale: localeToDateLocale(
                  shippingCountryCode,
                  preferredLanguage
                )
              })
            }}
            variant="textRegular14"
            align="center"
          />
          {discountCode && (
            <CopyInputButton
              copyLinkMessage={copyLinkMessage}
              namespace={namespace}
              referralCode={discountCode}
            />
          )}
          <div className={STYLES.buttonWrapper}>
            <Button
              typography={{
                text: `${copyContext}.button`,
                namespace
              }}
              identifier="raf-campaign-modal-button"
              variant="primary"
              screenIdentifier="dashboard"
              onClick={navigateToCampaignPage}
              fullWidth
            />
          </div>
          <Link
            text={`${copyContext}.terms`}
            namespace={namespace}
            href={termsLink()}
            disableAnalytics
            opensInNewTab
            suffix={null}
          />
        </div>
      </div>
    </Modal>
  )
}

export default RafCampaignModal
