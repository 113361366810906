// @noflow
import { makeVar, useReactiveVar } from '@apollo/client'
import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'
import isUndefined from 'lodash/isUndefined'
import React, { useEffect, useState } from 'react'
// Routing
import { BrowserRouter as Router, useLocation } from 'react-router-dom'

import PlansRoutes from '@/components/pages/PlansPage/types/routes'
// eslint-disable-next-line no-restricted-imports
import type { PendingSubscription } from '@/components/types'

import type { GuestQuery_currentUser_User_planRecommendation_recommendedExtraProductVariants as RecommendedExtraProductVariants } from './__generated__/GuestQuery'
import { Language } from '@/types'

import Plans from './Plans'
import type { Route as PlanRoute } from './types/routes'

type PlansFlowType = 'new' | 'add'

type Props = {
  variant: Language
}

const initialState: PendingSubscription = {
  flavours: null,
  products: null,
  productVariants: [],
  plan: null
}

const plansStateVersion = '0.0.1'
const versionStorageKey = 'plansStateVersion'
const versionStorageState = localStorage.getItem(versionStorageKey)
const isSupportedPlansStateVersion = versionStorageState === plansStateVersion

const localStorageKey = 'pendingSubscription'
const storageState: string | null = localStorage.getItem(localStorageKey)
const plansPageState = makeVar(
  !isNil(storageState) ? JSON.parse(storageState) : initialState
)

const wizardLocalStorageKey = 'wizardState'
const wizardState: string | null = localStorage.getItem(wizardLocalStorageKey)
const plansPageWizardState = makeVar(
  !isNil(wizardState) ? JSON.parse(wizardState) : null
)

const recommendationStorageKey = 'planRecommendation'
const recommendationState: string | null = localStorage.getItem(
  recommendationStorageKey
)
const planRecommendationState = makeVar(
  !isNil(recommendationState) ? JSON.parse(recommendationState) : null
)

const cadenceStorageKey = 'deliveryCadence'
const cadenceState: string | null = localStorage.getItem(cadenceStorageKey)
const deliveryCadenceState = makeVar(
  !isNil(cadenceState) ? JSON.parse(cadenceState) : 'default'
)

const flowTypeStorageKey = 'plansFlowType'
const flowTypeState: string | null = localStorage.getItem(flowTypeStorageKey)
const plansFlowTypeState = makeVar<PlansFlowType>(
  !isNil(flowTypeState) ? JSON.parse(flowTypeState) : 'new'
)

const skipLoadingScreenStorageKey = 'skipLoadingScreen'
const skipLoadingScreenState: string | null = localStorage.getItem(
  skipLoadingScreenStorageKey
)
const plansSkipLoadingScreenState = makeVar<boolean>(
  !isNil(skipLoadingScreenState) ? JSON.parse(skipLoadingScreenState) : false
)

const recommendedExtraProductVariantsState = makeVar<
  RecommendedExtraProductVariants[]
>([])

const PlansPage = ({ variant }: Props): JSX.Element => {
  const plansState = useReactiveVar(plansPageState)
  const recommendationState = useReactiveVar(planRecommendationState)
  const deliveryCadence = useReactiveVar(deliveryCadenceState)
  const plansFlowType = useReactiveVar(plansFlowTypeState)
  const plansSkipLoadingScreen = useReactiveVar(plansSkipLoadingScreenState)
  const [route, setRoute] = useState<string | undefined>()
  const location = useLocation().pathname as PlanRoute

  useEffect(() => {
    if (!isSupportedPlansStateVersion) {
      localStorage.setItem('plansStateVersion', plansStateVersion)
    }
    if (!isSupportedPlansStateVersion && storageState) {
      plansPageState(initialState)
      window.location.href = PlansRoutes.Recipes
    }
  }, [])

  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(plansState))
  }, [plansState])

  useEffect(() => {
    localStorage.setItem(
      recommendationStorageKey,
      JSON.stringify(recommendationState)
    )
  }, [recommendationState])

  useEffect(() => {
    localStorage.setItem(cadenceStorageKey, JSON.stringify(deliveryCadence))
  }, [deliveryCadence])

  useEffect(() => {
    localStorage.setItem(flowTypeStorageKey, JSON.stringify(plansFlowType))
  }, [plansFlowType])

  useEffect(() => {
    localStorage.setItem(
      skipLoadingScreenStorageKey,
      JSON.stringify(plansSkipLoadingScreen)
    )
  }, [plansSkipLoadingScreen])
  // Reset the pending subscription when first landing in the plans flow if
  // - there is a previous session saved
  // - the user has not come from a previous step
  // - is starting on the first step
  useEffect(() => {
    if (
      isUndefined(route) &&
      !isEqual(plansState, initialState) &&
      location === PlansRoutes.Recipes
    ) {
      plansPageState(initialState)
    }

    setRoute(location)
  }, [location, plansState, route])

  return <Plans variant={variant} />
}

const PlansPageRouter = ({ variant = Language.en }: Props): JSX.Element => (
  <Router>
    <PlansPage variant={variant} />
  </Router>
)

export {
  plansPageState,
  localStorageKey,
  plansPageWizardState,
  planRecommendationState,
  deliveryCadenceState,
  plansFlowTypeState,
  plansSkipLoadingScreenState,
  initialState,
  recommendedExtraProductVariantsState
}

export default PlansPageRouter
