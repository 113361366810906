// @noflow
import { useEffect, useRef } from 'react'

/**
 * # Interval custom hook
 *
 * The main difference between the setInterval and custom useInterval
 * hook is that its arguments are "dynamic". (for example if we want
 * the interval delay to be adjustable)
 *
 * More information in the Dan Abramov's blog post
 * https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 *
 * @example
  ```tsx
  import { useInterval } from '@/hooks/useInterval'

  // Constant delay
  useInterval(() => {
    setCount(count + 1)
  }, 1000)

  // Adjustable delay
  useInterval(() => {
    setCount(count + 1)
  }, delay)
  ```
 *
 * @param callback custom callback function
 * @param delay delay in ms
 */
const useInterval = (callback: () => void, delay: number | null): void => {
  const savedCallback = useRef(callback)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    if (!delay && delay !== 0) {
      return
    }

    const interval = setInterval(() => savedCallback.current(), delay)

    return () => clearInterval(interval)
  }, [delay])
}

export { useInterval }
