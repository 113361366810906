// @noflow
import classnames from 'classnames'
import React from 'react'

import Icon from '@/components/elements/atoms/Icon/Icon'
import type { BrandAssets } from '@/components/elements/atoms/Icon/brandAssets'
import Text from '@/components/elements/atoms/Text/Text'
import type { Props as TextProps } from '@/components/elements/atoms/Text/Text'

import STYLES from './ListItem.module.sass'

import { ensureNever } from '@/typescript/utils'

type BulletStyle = 'checkBox' | 'squareBullet'

type Props = {
  dataTestId?: string
  text: TextProps
  bulletStyle: BulletStyle
}

const ListItem = ({ dataTestId, text, bulletStyle }: Props): JSX.Element => {
  const bulletStyleToImage = (
    bulletStyle: Props['bulletStyle']
  ): keyof BrandAssets => {
    if (bulletStyle === 'squareBullet')
      throw new Error(`Cannot generate bulletStyleToImage for ${bulletStyle}`)
    switch (bulletStyle) {
      case 'checkBox': {
        return 'decorativeCheckbox'
      }
      default: {
        ensureNever(bulletStyle)
        throw new Error(
          `Cannot generate bulletStyleToImage for bulletStyle of: ${bulletStyle}`
        )
      }
    }
  }

  const className = classnames(STYLES.listItem, {
    [STYLES.squareBullet]: bulletStyle === 'squareBullet'
  })

  return (
    <li data-testid={dataTestId} className={className}>
      {bulletStyle !== 'squareBullet' && (
        <Icon
          accentColour="brandBlue500"
          asset={bulletStyleToImage(bulletStyle)}
          size={24}
        />
      )}
      <Text
        element="span"
        text={text.text}
        namespace={text.namespace}
        variant={text.variant}
        variables={text.variables}
        translate={text.translate}
        margin={text.margin}
        colour={text.colour}
      />
    </li>
  )
}

export type { Props, BulletStyle }
export default ListItem
