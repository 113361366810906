// @noflow
import React from 'react'

import {
  ICON_COLOURS,
  IconColours
} from '@/components/elements/atoms/Icon/Icon'

type Props = {
  size: number
  accentColour: IconColours
  backgroundColour?: IconColours
  width?: number | string
}

const Phone = ({
  size,
  accentColour,
  backgroundColour,
  width
}: Props): JSX.Element => {
  return (
    <svg
      viewBox="0 0 35 32"
      width={width}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.978 18.661c.196.342.493.627 1 1.5.4.687.856 1.283 1.117 1.544.238.238.47.484.717.717.602.569 1.187 1.158 1.76 1.73.57.57 1.192 1.133 1.89 1.544.39.229.652.738 1.173.767.378.021.763 0 1.141 0 .412 0 .856.04 1.235-.068.4-.115.67-.526 1.014-.747.229-.146.356-.404.587-.54.193-.113.287-.422.485-.535.352-.201.633-.603.898-.898.303-.337.743-.34 1.159-.34.573 0 1.048-.049 1.564.18.491.219 1.153.426 1.565.797.305.275.79.36 1.14.554.422.234 1.09.393 1.402.782.092.116.352.16.47.28.136.134.232.256.312.438.332.745.23 1.575-.163 2.281-.288.52-.755.958-1.17 1.373-.338.338-.716.644-1.046.974-.345.345-.892.517-1.369.623-.506.112-.964.454-1.467.597-.241.07-.464.222-.684.344-.175.097-.397.123-.569.228-.416.257-1.009.326-1.484.326-.894 0-2.038-.11-2.836-.554-.75-.416-1.61-.58-2.343-1.046-.38-.243-.792-.557-1.238-.685-.356-.101-.675-.461-1.029-.597a2.206 2.206 0 0 1-.507-.312c-.14-.11-.3-.08-.43-.17-.437-.302-.747-.7-1.25-.887-.427-.158-.923-.521-1.253-.833-.283-.267-.503-.584-.783-.848-.208-.197-.487-.472-.586-.746-.087-.237-.372-.364-.471-.608-.163-.4-.72-.88-1.029-1.188-.316-.316-.416-.779-.731-1.094-.307-.306-.478-.69-.652-1.057-.116-.245-.276-.57-.442-.782-.172-.222-.51-.513-.586-.782-.078-.273-.305-.418-.457-.638-.148-.215-.326-.429-.485-.634-.133-.17-.206-.397-.362-.554l-.008-.008c-.111-.11-.26-.26-.318-.419-.067-.185-.2-.318-.275-.485a7.856 7.856 0 0 0-.332-.633l-.012-.023c-.133-.236-.21-.537-.308-.793-.079-.204-.2-.581-.326-.753-.688-.946-1.069-2.093-1.536-3.143-.147-.332-.028-1.13.037-1.485.089-.489-.052-1.072.083-1.543.126-.442.24-1.181.489-1.572.198-.31.304-.72.529-1 .223-.277.426-.52.637-.789.73-.929 1.515-1.9 2.872-1.69.454.07 1.02.059 1.434.289.344.19.795.403 1.04.717.787.894.84 2 1.287 2.894.292.583.5 1 .9 1.673.063.239.219.467.257.713.037.244.01.54.003.786-.005.17-.177.375-.26.521-.18.316-.408.68-.638.956-.14.168-.238.365-.34.544-.134.233-.34.417-.486.644-.306.482-.883.866-.883 1.474 0 .456.009.848.195 1.268.14.313.582 1.122.752 1.421Z"
        fill={backgroundColour ? ICON_COLOURS[backgroundColour] : '#fff'}
      />
      <path
        d="M8.978 16.661c.196.342.493.627 1 1.5.4.687.856 1.283 1.117 1.544.238.238.47.484.717.717.602.569 1.187 1.158 1.76 1.73.57.57 1.192 1.133 1.89 1.544.39.229.652.738 1.173.767.378.021.763 0 1.141 0 .412 0 .856.04 1.235-.068.4-.115.67-.526 1.014-.747.229-.146.356-.404.587-.54.193-.113.287-.422.485-.535.352-.201.633-.603.898-.898.303-.337.743-.34 1.159-.34.573 0 1.048-.049 1.564.18.491.219 1.153.426 1.565.797.305.275.79.36 1.14.554.422.234 1.09.393 1.402.782.092.116.352.16.47.28.136.134.232.256.312.438.332.745.23 1.575-.163 2.281-.288.52-.755.958-1.17 1.373-.338.338-.716.644-1.046.974-.345.345-.892.517-1.369.623-.506.112-.964.454-1.467.597-.241.07-.464.222-.684.344-.175.097-.397.123-.569.228-.416.257-1.009.326-1.484.326-.894 0-2.038-.11-2.836-.554-.75-.416-1.61-.58-2.343-1.046-.38-.243-.792-.557-1.238-.685-.356-.101-.675-.461-1.029-.597a2.206 2.206 0 0 1-.507-.312c-.14-.11-.3-.08-.43-.17-.437-.302-.747-.7-1.25-.887-.427-.158-.923-.521-1.253-.833-.283-.267-.503-.584-.783-.848-.208-.197-.487-.472-.586-.746-.087-.237-.372-.364-.471-.608-.163-.4-.72-.88-1.029-1.188-.316-.316-.416-.779-.731-1.094-.307-.306-.478-.69-.652-1.057-.116-.245-.276-.57-.442-.782-.172-.222-.51-.513-.586-.782-.078-.273-.305-.418-.457-.638-.148-.215-.326-.429-.485-.634-.133-.17-.206-.397-.362-.554l-.008-.008c-.111-.11-.26-.26-.318-.419-.067-.185-.2-.318-.275-.485a7.856 7.856 0 0 0-.332-.633l-.012-.023c-.133-.236-.21-.537-.308-.793-.079-.204-.2-.581-.326-.753-.688-.946-1.069-2.093-1.536-3.143-.147-.332-.028-1.13.037-1.485.089-.489-.052-1.072.083-1.543.126-.442.24-1.181.489-1.572.198-.31.304-.72.529-1 .223-.277.426-.52.637-.789.73-.929 1.515-1.9 2.872-1.69.454.07 1.02.059 1.434.289.344.19.795.403 1.04.717.787.894.84 2 1.287 2.894.292.583.5 1 .9 1.673.063.239.219.467.257.713.037.244.01.54.003.786-.005.17-.177.375-.26.521-.18.316-.408.68-.638.956-.14.168-.238.365-.34.544-.134.233-.34.417-.486.644-.306.482-.883.866-.883 1.474 0 .456.009.848.195 1.268.14.313.582 1.122.752 1.421Z"
        stroke={ICON_COLOURS[accentColour]}
        strokeWidth="1.3"
        strokeLinecap="round"
      />
      <path
        d="M10.504 4.466c.167-.019.312-.198.446-.285.12-.079.241-.194.358-.285.154-.12.307-.262.429-.414.096-.12.215-.279.339-.37.091-.066.154-.18.219-.276.164-.242.33-.442.465-.698.105-.2.223-.391.329-.59.095-.18.162-.37.263-.548M12.213 6.341c.067 0 .161.017.225-.002.066-.02.13-.098.184-.14.077-.06.163-.109.242-.166.311-.224.63-.43.916-.69.217-.198.476-.362.712-.54.181-.135.322-.299.45-.48M12.165 8.43h.901c.294 0 .57.056.855.13.216.058.415.107.618.203.116.054.322.142.451.142"
        stroke={ICON_COLOURS[accentColour]}
        strokeLinecap="round"
      />
    </svg>
  )
}

export default Phone
