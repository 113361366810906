/* eslint-disable react/jsx-props-no-spreading */

/**
 * ModalLayout Component
 *
 * A modal layout component for displaying content in a modal dialog with animations,
 * adaptable to both mobile and desktop layouts. The modal uses `@react-spring/web`
 * for animated transitions and handles interactions such as closing via escape key
 * or clicking outside the modal.
 *
 * @module ModalLayout
 */
import { ModalContext } from '@/context/modalProvider'
import { animated, useSpring } from '@react-spring/web'
import React, { useCallback, useContext, useEffect, useRef } from 'react'

import { trackEvent } from '@/services/segment'

import { useShowDixa } from '@/hooks/useDixa'
import useWindowSize from '@/hooks/useWindowSize'

import useCloseOnSwipe from '@/components/elements/atoms/Modal/hooks/useCloseOnSwipe'
import Text, { TextProps } from '@/components/elements/atoms/Text'
import IconButton from '@/components/elements/molecules/IconButton/IconButton'
import { BREAKPOINTS } from '@/components/templates/Base'

import STYLES from './index.module.sass'

type Props = {
  /**
   * The title content displayed in the modal header.
   *
   * Accepts props for the `Text` component, allowing customization of text style and content.
   */
  title: TextProps

  /**
   * The children content rendered inside the modal body.
   */
  children: React.ReactNode
}

/**
 * Mobile-specific animation configuration for modal transitions.
 */
const mobileAnimations = {
  from: {
    top: '100%',
    transform: 'translate(-50%, 0%)'
  },
  to: {
    top: '100%',
    transform: 'translate(-50%, -100%)'
  }
}

/**
 * Desktop-specific animation configuration for modal transitions.
 */
const desktopAnimations = {
  from: {
    top: '150%',
    transform: 'translate(-50%, -50%)'
  },
  to: {
    top: '50%',
    transform: 'translate(-50%, -50%)'
  }
}

/**
 * ModalLayout Component
 *
 * This component manages modal rendering and behavior, including animations, close button functionality,
 * and event handling for closing via escape key or clicking outside the modal content.
 *
 * @param {Props} props - The props for the modal component.
 * @param {TextProps} props.title - The title content and style for the modal.
 * @param {React.ReactNode} props.children - The children content rendered in the modal body.
 * @returns {JSX.Element} The rendered modal layout.
 */
const ModalLayout = ({ children, title }: Props): JSX.Element => {
  useShowDixa(false)

  const ref = useRef<HTMLDivElement>(null)

  const { closeModal, id } = useContext(ModalContext)
  const { windowWidth } = useWindowSize()
  const isMobile = windowWidth < BREAKPOINTS.md

  /**
   * Handles modal close action.
   */
  const handleClose = useCallback(() => {
    trackEvent('Modal Closed', {
      component_identifier: id
    })
    closeModal(id)
  }, [id, closeModal])

  const { handleSetScrolledToTop, onTouchStart, onTouchMove, onTouchEnd } =
    useCloseOnSwipe({ handleOnClose: handleClose })

  const contentStyles = useSpring(
    isMobile ? mobileAnimations : desktopAnimations
  )

  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  useEffect(() => {
    /**
     * Handles closing the modal when the Escape key is pressed.
     */
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') closeModal(id)
    }

    /**
     * Handles closing the modal when clicking outside its content area.
     */
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      const isSame = ref.current?.isEqualNode(event.target as Node)

      if (isSame) return

      if (!ref?.current?.contains(event.target as Node)) {
        closeModal(id)
      }
    }

    window.addEventListener('mousedown', handleClickOutside)
    window.addEventListener('touchstart', handleClickOutside)
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('mousedown', handleClickOutside)
      window.removeEventListener('touchstart', handleClickOutside)
      window.removeEventListener('keydown', handleEsc)
    }
  }, [ref, closeModal, id])

  return (
    <animated.div>
      <div className={STYLES.overlay}>
        <animated.div className={STYLES.wrapper} style={contentStyles}>
          <div
            className={STYLES.content}
            ref={ref}
            onScroll={handleSetScrolledToTop}
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
          >
            <div className={STYLES.mobileHeaderFlex}>
              <div className={STYLES.mobileBar} />
            </div>

            <div className={STYLES.closeButton}>
              <IconButton
                variant="brandYellow200"
                iconSize={0.4}
                size={40}
                icon="closeBold"
                shape="circle"
                onClick={handleClose}
              />
            </div>

            <div className={STYLES.title}>
              <Text variant="display28" margin={false} {...title} />
            </div>

            <div className={STYLES.children}>{children}</div>
          </div>
        </animated.div>
      </div>
    </animated.div>
  )
}

export default ModalLayout
