// @noflow
import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import greenCheckmark from 'assets/images/icons/checkmarks/green-checkmark.svg'
import greyCheckmark from 'assets/images/icons/checkmarks/grey-checkmark.svg'
import redCross from 'assets/images/icons/crosses/red-cross.svg'

type CheckmarkProps = {
  color: 'green' | 'grey' | 'red'
}

const Checkmark = ({ color }: CheckmarkProps): ReactElement => {
  const { t } = useTranslation('checkout')
  const copy_context = 'validation'

  const colourToAsset = useCallback(() => {
    switch (color) {
      case 'green':
        return greenCheckmark
      case 'grey':
        return greyCheckmark
      case 'red':
        return redCross
    }
  }, [color])

  return (
    <img
      alt={t(`${copy_context}.image_alt`)}
      className="checkout__checkmark"
      src={colourToAsset()}
      data-testid={`validation-indicator-${color}`}
    />
  )
}

const Error = ({ errorMessage }: { errorMessage: string }): ReactElement => (
  <p className="checkout-input__validation-error">{errorMessage}</p>
)

export { Checkmark, Error }
