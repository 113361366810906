// @noflow
import { useMutation, useReactiveVar } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import isNull from 'lodash/isNull'
import React, { useCallback } from 'react'
import { toast } from 'react-toastify'

import { subscriptionDataVar } from '@/services/apollo'

import { defaultClientInitSubscription } from '@/constants/SubscribtionInitObject'

import NotificationContent from '@/components/elements/molecules/NotificationContent/NotificationContent'

// Mutations
import { SUBSCRIPTION_RESUME_MUTATION } from './mutations/subscriptionResumeMutation'

import type { SubscriptionResumeMutation } from './mutations/__generated__/SubscriptionResumeMutation'

type Props = {
  subscriptionId: string
}

type SubscriptionResume = {
  subscriptionResumeMutationData: SubscriptionResumeMutation | null | undefined
  subscriptionResumeMutationLoading: boolean
  handleResumeSubscription: () => Promise<void>
}

/**
 * A custom hook that is used to resume a subscription.
 */
const useSubscriptionResume = ({
  subscriptionId
}: Props): SubscriptionResume => {
  const subscriptionData = useReactiveVar(subscriptionDataVar)
  /**
   * Resume subscription mutation
   */
  const [
    subscriptionResumeMutation,
    {
      data: subscriptionResumeMutationData,
      loading: subscriptionResumeMutationLoading
    }
  ] = useMutation<SubscriptionResumeMutation>(SUBSCRIPTION_RESUME_MUTATION, {
    variables: {
      numOfBoxes: 3
    },
    update(cache, { data: mutationResponse }) {
      if (mutationResponse && mutationResponse.subscriptionResume) {
        const { subscriptionResume } = mutationResponse
        const { subscription } = subscriptionResume
        const { status } = subscription

        subscriptionDataVar(
          !isNull(subscriptionData)
            ? { ...subscriptionData, status }
            : {
                ...defaultClientInitSubscription,
                ...subscription,
                status
              }
        )

        const subscriptionCacheId = cache.identify({
          __typename: 'Subscription',
          id: subscriptionId
        })

        cache.modify({
          id: subscriptionCacheId,
          fields: {
            status: () => status
          }
        })
      }
    }
  })

  /**
   * Resume subscription mutation implementation
   */
  const handleResumeSubscription = useCallback(async (): Promise<void> => {
    const showErrorMessage = (error: string) => {
      toast.error(
        <NotificationContent
          copy={{
            namespace: 'dashboard',
            text: 'alerts.restart_plan_alert_extras.resume_failed'
          }}
        />,
        {
          toastId: 'product-variant-frequency-resume-subscription-failed'
        }
      )

      Sentry.captureException(
        `ProductVariantFrequency | Subscription can't be resumed: ${error}`
      )
    }

    try {
      const { data, errors } = await subscriptionResumeMutation()

      if (errors) {
        showErrorMessage(errors.join(', '))
      }

      if (data) {
        toast.success(
          <NotificationContent
            copy={{
              namespace: 'dashboard',
              text: 'alerts.restart_plan_alert_extras.resume_success'
            }}
          />,
          {
            toastId:
              'product-variant-frequency-resume-subscription-failed-success'
          }
        )
      }
    } catch (error) {
      showErrorMessage(error as string)
    }
  }, [subscriptionResumeMutation])

  return {
    subscriptionResumeMutationData,
    subscriptionResumeMutationLoading,
    handleResumeSubscription
  }
}

export default useSubscriptionResume
