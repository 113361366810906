// @noflow
import type { Language } from '@/packs/localisation'
import { useQuery, useReactiveVar } from '@apollo/client'
import Cookies from 'js-cookie'
import isEqual from 'lodash/isEqual'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import kebabCase from 'lodash/kebabCase'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
// Routing
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { formatCurrencyWithDecimal } from '@/utils/currency'
import * as Sentry from '@/utils/sentry'

import Hypoallergenic from 'assets/images/icons/product-benefits/hypoallergenic.svg'

import AlertCard from '@/components/elements/atoms/Alert/AlertCard'
import Card from '@/components/elements/atoms/Card/Card'
import Label from '@/components/elements/atoms/Label/Label'
import SpeechBubble from '@/components/elements/atoms/SpeechBubble/SpeechBubble'
import Text from '@/components/elements/atoms/Text/Text'
import ListGroup from '@/components/elements/molecules/ListGroup/ListGroup'
import NotificationContainer from '@/components/elements/molecules/NotificationContainer/NotificationContainer'
import NotificationContent from '@/components/elements/molecules/NotificationContent/NotificationContent'
import ProductBenefit from '@/components/elements/molecules/ProductBenefit/ProductBenefit'
import SelectableCard from '@/components/elements/molecules/SelectableCard/SelectableCard'
import FlavourInformationModal, {
  modalData as flavourModalData
} from '@/components/elements/organisms/FlavourInformationModal/FlavourInformationModal'
import HeroText from '@/components/elements/organisms/HeroText/HeroText'
import StickyNavigation from '@/components/elements/organisms/StickyNavigation/StickyNavigation'
import {
  deliveryCadenceState,
  planRecommendationState,
  plansFlowTypeState,
  plansPageState,
  plansPageWizardState,
  plansSkipLoadingScreenState,
  recommendedExtraProductVariantsState
} from '@/components/pages/PlansPage/PlansPage'
import FAQSection from '@/components/pages/PlansPage/components/FAQSection/FAQSection'
import LoadingScreenContent from '@/components/pages/PlansPage/components/LoadingScreenContent/LoadingScreenContent'
import TopNavigation from '@/components/pages/PlansPage/components/TopNavigation/TopNavigation'
import {
  handleFetchError,
  handleWizardDataError,
  verifyWizardData
} from '@/components/pages/PlansPage/helpers/handleErrors'
import PlansRoutes from '@/components/pages/PlansPage/types/routes'
import type { Dog } from '@/components/pages/SignupWizardPage/types/types'
// eslint-disable-next-line no-restricted-imports
import type { Flavour, PendingSubscription } from '@/components/types'

import STYLES from './Recipes.module.sass'

import type {
  RecommendationQuery_currentUser_User_planRecommendation_individual as Individual,
  RecommendationQuery_currentUser_User_planRecommendation_plans as Plan,
  RecommendationQuery
} from '../../__generated__/RecommendationQuery'
import type { Code } from '@/types'

import {
  trackNextButtonClicked,
  trackRecipeMoreInfoClicked,
  trackRecipeSelectionToggled
} from '../../helpers/analytics'
import { GET_RECOMMENDATIONS_QUERY } from '../../queries'
import type { Route as PlanRoute } from '../../types/routes'
import type { FlowType } from '../../types/types'

type Props = {
  variant?: keyof typeof STYLES
  namespace: string
  maxFlavours: number
}

const isFlavourSelected = (
  flavour: Flavour,
  selectedFlavours: Array<Flavour> | undefined
): boolean => {
  if (selectedFlavours) {
    return !isUndefined(
      selectedFlavours.find(
        (selectedFlavour) => selectedFlavour?.id === flavour?.id
      )
    )
  } else return false
}

const manageSelectedFlavours = (
  current: Array<Flavour>,
  selected: boolean,
  flavour: Flavour
): Array<Flavour> => {
  const cloneArray: Array<Flavour> = [...current]
  const flavourIndex: number = current.findIndex(
    (selectedFlavour: Flavour) => selectedFlavour?.id === flavour?.id
  )
  const existsInArray: boolean = flavourIndex !== -1

  if (selected && !existsInArray) cloneArray.push(flavour)
  else if (!selected && existsInArray) cloneArray.splice(flavourIndex, 1)

  return cloneArray
}

const MaxFlavourSelectionNotification = (
  maxFlavours: number,
  namespace: string
): JSX.Element => (
  <NotificationContent
    copy={{
      text: 'plan_steps.recipes.notification_recipes_limit',
      namespace: namespace,
      variables: {
        max: maxFlavours
      }
    }}
  />
)

const disableNextButton = (selectedFlavours: Flavour[] | null): boolean =>
  isNull(selectedFlavours) || selectedFlavours.length === 0

const isFussyEater = (
  individuals: Array<Individual>,
  inABTest: string | null
): boolean =>
  inABTest === 'true' &&
  individuals.some(
    (individual: Individual) =>
      individual.dog.eaterType === 'fussy_eater' ||
      individual.dog.eaterType === 'picky_eater'
  )

const generateLabels = (
  flavour: NonNullable<Flavour>,
  individuals: Array<Individual>,
  notRecommended: Array<NonNullable<Flavour>>,
  namespace: string
): ReactElement[] => {
  let labels: ReactElement[] = []

  if (notRecommended.includes(flavour)) {
    labels.push(
      // eslint-disable-next-line jsx-a11y/label-has-for
      <Label
        key={`${kebabCase(flavour.name)}-not-recommended-label-key`}
        variant="notRecommended"
        text={{
          text: 'plan_steps.recipes.not_recommended',
          namespace
        }}
      />
    )
  }

  if (Array.isArray(flavour.productLabels)) {
    labels = labels.concat(
      flavour.productLabels.map((label) => (
        // eslint-disable-next-line jsx-a11y/label-has-for
        <Label
          key={`${kebabCase(flavour.name)}-${kebabCase(label.name)}-label-key`}
          text={{
            text: label.name,
            translate: false
          }}
          colour={label.themeColour}
        />
      ))
    )
  }

  return labels
}

const Recipes = ({
  variant,
  maxFlavours,
  namespace
}: Props): JSX.Element | null => {
  const { t } = useTranslation(`${namespace}`)
  const copyContext = 'plan_steps.recipes'

  const { loading, data, error } = useQuery<RecommendationQuery>(
    GET_RECOMMENDATIONS_QUERY
  )

  const plansState: PendingSubscription = useReactiveVar(plansPageState)
  const wizardState = useReactiveVar(plansPageWizardState)
  if (!verifyWizardData(wizardState)) handleWizardDataError(wizardState)
  const recommendationState = useReactiveVar(planRecommendationState)
  const cadenceState = useReactiveVar(deliveryCadenceState)
  const flowTypeState = useReactiveVar(plansFlowTypeState) as FlowType
  const skipLoadingScreenState = useReactiveVar(plansSkipLoadingScreenState)
  const recommendedExtraProductVariants = useReactiveVar(
    recommendedExtraProductVariantsState
  )
  const location = useLocation().pathname as PlanRoute
  const { flavours: selectedFlavours } = plansState
  const [maxFlavoursReached, setMaxFlavoursReached] = useState<boolean>(false)

  const setSelectedFlavours = useCallback(
    (selectedFlavours: Flavour[]) => {
      const updatedPendingSubscription: PendingSubscription = {
        ...plansState,
        flavours: selectedFlavours
      }

      if (!isEqual(updatedPendingSubscription, plansState))
        plansPageState(updatedPendingSubscription)
    },
    [plansState]
  )

  const nextButtonClick = useCallback(() => {
    plansSkipLoadingScreenState(true)
    trackNextButtonClicked(
      location,
      plansState,
      wizardState,
      recommendationState,
      cadenceState,
      flowTypeState
    )
  }, [
    location,
    plansState,
    wizardState,
    recommendationState,
    cadenceState,
    flowTypeState
  ])

  // set initially selected flavours
  useEffect(() => {
    if (
      data &&
      data.currentUser &&
      data.currentUser?.__typename !== 'PreWizardGuest' &&
      data.currentUser.planRecommendation &&
      isNull(selectedFlavours)
    ) {
      // default (up to maxFlavours)
      setSelectedFlavours(
        data.currentUser.planRecommendation.combinedRecommendedFlavours.slice(
          0,
          maxFlavours
        )
      )
    }
  }, [data, selectedFlavours, setSelectedFlavours, maxFlavours])

  // manage if max recipes have been reached
  useEffect(() => {
    if (!isNull(selectedFlavours)) {
      if (selectedFlavours.length === maxFlavours) setMaxFlavoursReached(true)
      else if (selectedFlavours.length < maxFlavours)
        setMaxFlavoursReached(false)
    }
  }, [selectedFlavours, maxFlavours, maxFlavoursReached])

  useEffect(() => {
    if (
      data &&
      (data.currentUser?.__typename === 'Guest' ||
        data.currentUser?.__typename === 'User')
    )
      planRecommendationState(data.currentUser.planRecommendation)
  }, [data])

  const cardOnClick = useCallback(
    (selected: boolean, flavour: NonNullable<Flavour>): void => {
      if (isNull(selectedFlavours)) return
      if (
        !selected &&
        !isFlavourSelected(flavour, selectedFlavours) &&
        maxFlavoursReached
      )
        toast(MaxFlavourSelectionNotification(maxFlavours, namespace))
    },
    [maxFlavours, maxFlavoursReached, namespace, selectedFlavours]
  )

  if (loading || !data) {
    return <div />
  }

  if (error) handleFetchError(error)

  const { currentUser, flavours } = data

  if (!currentUser) {
    throw new Error('Cannot find a currentUser')
  }

  if (currentUser.__typename === 'PreWizardGuest') {
    Sentry.captureException(
      'Plans flow Recipes page - currentUser is PreWizardGuest',
      {
        tags: {
          product: Sentry.Product.PlansFlow
        }
      }
    )
    return null
  }

  const { planRecommendation } = currentUser

  if (isNull(planRecommendation)) {
    Sentry.captureException(
      'Plans flow Recipes page - planRecommendation is null',
      {
        tags: {
          product: Sentry.Product.PlansFlow
        }
      }
    )
    window.location.href = '/wizard/pet-parent'
    return null
  }

  const individual = planRecommendation.individual
  const combinedRecommendedFlavours =
    planRecommendation.combinedRecommendedFlavours
  const combinedOtherFlavours = planRecommendation.combinedOtherFlavours
  const combinedNotRecommendedFlavours =
    planRecommendation.combinedNotRecommendedFlavours
  const recommendationReasons = planRecommendation.recommendationReasons
  const productBenefits = flavours
    .map(({ productBenefits }) => productBenefits)
    .flat()

  const findBenefit = (benefitId: string) =>
    productBenefits.find(({ id }) => id === benefitId)

  const easyToDigestBenefit = findBenefit('10')
  const noNastiesBenefit = findBenefit('2')

  const onFlavourSelect = (
    isSelected: boolean,
    flavour: NonNullable<Flavour>
  ) => {
    !isNull(selectedFlavours) &&
      setSelectedFlavours(
        manageSelectedFlavours(selectedFlavours, isSelected, flavour)
      )
    trackRecipeSelectionToggled(
      location,
      plansState,
      wizardState,
      recommendationState,
      cadenceState,
      isSelected ? 'selected' : 'deselected',
      flavour.slug,
      flowTypeState
    )
  }

  const showFlavourModal = (slug: string): void => {
    flavourModalData({ isOpen: true, slug: slug })
    trackRecipeMoreInfoClicked(
      location,
      plansState,
      wizardState,
      recommendationState,
      cadenceState,
      slug,
      flowTypeState
    )
  }

  const shippingCountryCode = (): Code => {
    switch (currentUser.__typename) {
      case 'Guest': {
        return currentUser.assumedShippingCountry.code
      }
      case 'User': {
        return currentUser.shippingCountry.code
      }
    }
  }

  const foodCategoryId = wizardState.dogs.map(
    (dog: Dog) => dog.foodCategoryIds
  )[0][0]

  const surchargeTooltipCopy = (flavour: Flavour) => {
    if (
      flavour &&
      flavour.recipeSurcharges &&
      flavour.recipeSurcharges.length > 0 &&
      data
    ) {
      if (!planRecommendation.plans)
        throw new Error('Cannot find a plan on planRecommendation')

      const availablePlans: Plan[] = [...planRecommendation.plans]
      const smallestRecommendedPlanSize =
        availablePlans &&
        availablePlans.sort((a, b) => a.pouchSize - b.pouchSize)[0].pouchSize

      const smallestSurcharge = flavour.recipeSurcharges.find(
        ({ planSize }) => planSize === smallestRecommendedPlanSize
      )
      if (!smallestSurcharge) return [undefined, undefined]
      const surchargeAmount = smallestSurcharge.amount

      const preferredLanguage =
        currentUser.__typename === 'Guest' || currentUser.__typename === 'User'
          ? currentUser.preferredLanguage
          : (Cookies.get('user_language') as Language)
      const { locale, currency } = countryCodeToLocaleCurrency(
        shippingCountryCode(),
        preferredLanguage
      )

      return [
        t(`${copyContext}.surcharge_tooltip.text`, {
          formattedSurchargeAmount: formatCurrencyWithDecimal(surchargeAmount, {
            locale: locale,
            currency: currency
          })
        }),
        t(`${copyContext}.surcharge_tooltip.content`)
      ]
    } else {
      return [undefined, undefined]
    }
  }

  return (
    <div className={`${STYLES.container} ${variant ? STYLES[variant] : ''}`}>
      <FlavourInformationModal />
      {flowTypeState === 'new' && !skipLoadingScreenState && (
        <LoadingScreenContent
          namespace={namespace}
          foodCategoryId={foodCategoryId}
          loading={loading}
        />
      )}
      {flowTypeState === 'new' ? (
        <HeroText
          variant="plans"
          namespace={namespace}
          title={`plan_steps.stage`}
          subtitle={'plan_steps.recipes.header.title'}
          subtitleVariables={{
            count: individual.length,
            dogPossessiveName: `${individual[0].dog.name}'s`
          }}
          variables={{
            stepCount: 1,
            totalSteps: recommendedExtraProductVariants.length > 0 ? 4 : 3
          }}
        />
      ) : (
        <React.Fragment>
          <TopNavigation
            // eslint-disable-next-line react/jsx-no-bind
            backUrl={() => {
              window.location.href = '/wizard/snacks'
            }}
          />
          <HeroText
            variant="plans"
            namespace={namespace}
            title={`plan_steps.stage`}
            variables={{ stepCount: 1, totalSteps: 3 }}
            subtitle={'plan_steps.recipes.header.title_add'}
          />
        </React.Fragment>
      )}
      <div className={STYLES.recipeIntroduction}>
        <div className={STYLES.wrapper}>
          <div className={STYLES.calorieBreakdownCard}>
            <Card variant="tapewhite" shadow={false}>
              <div className={STYLES.calorieBreakdown}>
                <Text
                  namespace={namespace}
                  text="plan_steps.recipes.calorie_specification.title"
                  variant="textRegular16"
                  colour={'brandBlue500'}
                />
                {individual.map((recommendation) => (
                  <Text
                    key={`calories-${recommendation.dog.name}`}
                    namespace={namespace}
                    text="plan_steps.recipes.calorie_specification.calories_per_dog"
                    variables={{
                      dogName: recommendation.dog.name,
                      dailyCalories: recommendation.dog.dailyCalories
                    }}
                    variant="display18"
                    colour={'brandBlue500'}
                    bold
                  />
                ))}
              </div>
            </Card>
          </div>
          <Text
            text="plan_steps.recipes.recipe_introduction.title"
            variant="textRegular16"
            colour={'brandBlue500'}
            namespace={namespace}
          />
          <div className={STYLES.benefits}>
            <div className={`${STYLES.benefitItem} ${STYLES.slimIcon}`}>
              <div className={STYLES.customBenefit}>
                <img alt="" src={Hypoallergenic} />
                <Text
                  text="plan_steps.recipes.recipe_introduction.hypoallergenic"
                  variant="textRegular14"
                  element="span"
                  colour="brandBlue400"
                  namespace={namespace}
                />
              </div>
            </div>
            {noNastiesBenefit && noNastiesBenefit.image && (
              <div className={STYLES.benefitItem}>
                <ProductBenefit
                  benefit={{
                    name: noNastiesBenefit.name,
                    url: noNastiesBenefit.image.src
                  }}
                />
              </div>
            )}
            {easyToDigestBenefit && easyToDigestBenefit.image && (
              <div className={STYLES.benefitItem}>
                <ProductBenefit
                  benefit={{
                    name: easyToDigestBenefit.name,
                    url: easyToDigestBenefit.image.src
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={STYLES.recipeSelection}>
        <div className={STYLES.wrapper}>
          <div className={STYLES.title}>
            {flowTypeState === 'new' ? (
              <Text
                namespace={namespace}
                text={'plan_steps.recipes.recipe_cards.title'}
                variables={{
                  count: individual.length,
                  dogName: individual[0].dog.name
                }}
                variant="display24"
                colour={'brandBlue500'}
                element="h3"
              />
            ) : (
              <Text
                namespace={namespace}
                text="plan_steps.recipes.recipe_cards.title_add"
                variant="display24"
                colour={'brandBlue500'}
                element="h3"
              />
            )}
          </div>
          <ListGroup
            listItems={recommendationReasons.map((reason) => {
              return {
                bulletStyle: 'checkBox',
                text: {
                  margin: false,
                  text: reason.text,
                  translate: false
                }
              }
            })}
          />
          <Text
            namespace={namespace}
            text="plan_steps.recipes.recipe_cards.feel_free_to_remove"
            variant="textRegular16"
            colour={'brandBlue500'}
          />
          {flowTypeState === 'add' && (
            <AlertCard
              message={{
                text: `plan_steps.recipes.recipe_cards.adjust_selection`,
                namespace: namespace,
                margin: false,
                align: 'left'
              }}
              variant="info"
            />
          )}
        </div>
        <div className={`${STYLES.cardWrapper}`}>
          {!isNull(selectedFlavours) &&
            combinedRecommendedFlavours.map((flavour) => {
              const [tooltipText, tooltipContent] =
                surchargeTooltipCopy(flavour)

              const tooltip =
                tooltipContent && tooltipText
                  ? {
                      tooltipText,
                      tooltipContent,
                      tooltipIdentifier: 'surcharge_tooltip'
                    }
                  : {
                      tooltipText: undefined,
                      tooltipContent: undefined,
                      tooltipIdentifier: undefined
                    }

              return (
                <SelectableCard
                  dataTestId="recommended-recipe-card"
                  key={`recommended-flavour-${flavour.name}`}
                  title={flavour.name}
                  cloudinaryPath={flavour.primaryImage.src}
                  description={flavour.shortDescription}
                  {...tooltip}
                  readMore
                  // eslint-disable-next-line react/jsx-no-bind
                  onReadMoreClick={(slug: string) => showFlavourModal(slug)}
                  defaultSelected={isFlavourSelected(flavour, selectedFlavours)}
                  slug={flavour.slug}
                  ctaVariant="plus"
                  layoutVariant="vertical"
                  selectable={!maxFlavoursReached}
                  labels={generateLabels(
                    flavour,
                    individual,
                    combinedNotRecommendedFlavours,
                    namespace
                  )}
                  truncate={2}
                  maxWidth={false}
                  // eslint-disable-next-line react/jsx-no-bind
                  onSelectionChange={(selected: boolean) =>
                    onFlavourSelect(selected, flavour)
                  }
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={(selected: boolean) =>
                    cardOnClick(selected, flavour)
                  }
                />
              )
            })}
        </div>
      </div>
      {combinedNotRecommendedFlavours.concat(combinedOtherFlavours).length >
        0 && (
        <div className={STYLES.otherRecipes}>
          <div className={STYLES.titleWrapper}>
            <div className={STYLES.title}>
              <Text
                namespace={namespace}
                text="plan_steps.recipes.other_recipes.title"
                variant="display24"
                colour={'brandBlue500'}
                element="h3"
              />
            </div>
          </div>
          <div className={`${STYLES.cardWrapper}`}>
            {!isNull(selectedFlavours) &&
              combinedOtherFlavours.map((flavour) => {
                const [tooltipText, tooltipContent] =
                  surchargeTooltipCopy(flavour)

                const tooltip =
                  tooltipContent && tooltipText
                    ? {
                        tooltipText,
                        tooltipContent,
                        tooltipIdentifier: 'surcharge_tooltip'
                      }
                    : {
                        tooltipText: undefined,
                        tooltipContent: undefined,
                        tooltipIdentifier: undefined
                      }

                return (
                  <SelectableCard
                    dataTestId="other-recipe-card"
                    key={`recommended-flavour-${flavour.name}`}
                    title={flavour.name}
                    cloudinaryPath={flavour.primaryImage.src}
                    description={flavour.shortDescription}
                    {...tooltip}
                    readMore
                    // eslint-disable-next-line react/jsx-no-bind
                    onReadMoreClick={(slug: string) => showFlavourModal(slug)}
                    defaultSelected={isFlavourSelected(
                      flavour,
                      selectedFlavours
                    )}
                    slug={flavour.slug}
                    ctaVariant="plus"
                    layoutVariant="vertical"
                    maxWidth={false}
                    selectable={!maxFlavoursReached}
                    labels={generateLabels(
                      flavour,
                      individual,
                      combinedNotRecommendedFlavours,
                      namespace
                    )}
                    truncate={2}
                    // eslint-disable-next-line react/jsx-no-bind
                    onSelectionChange={(selected: boolean) =>
                      onFlavourSelect(selected, flavour)
                    }
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={(selected: boolean) =>
                      cardOnClick(selected, flavour)
                    }
                  />
                )
              })}
            {!isNull(selectedFlavours) &&
              combinedNotRecommendedFlavours.map((flavour) => {
                const [tooltipText, tooltipContent] =
                  surchargeTooltipCopy(flavour)

                const tooltip =
                  tooltipContent && tooltipText
                    ? {
                        tooltipText,
                        tooltipContent,
                        tooltipIdentifier: 'surcharge_tooltip'
                      }
                    : {
                        tooltipText: undefined,
                        tooltipContent: undefined,
                        tooltipIdentifier: undefined
                      }

                return (
                  <div
                    className={STYLES.cardWithBubble}
                    key={`recommended-flavour-${flavour.name}`}
                  >
                    <SelectableCard
                      key={`recommended-flavour-${flavour.name}`}
                      title={flavour.name}
                      cloudinaryPath={flavour.primaryImage.src}
                      description={flavour.shortDescription}
                      {...tooltip}
                      readMore
                      // eslint-disable-next-line react/jsx-no-bind
                      onReadMoreClick={(slug: string) => showFlavourModal(slug)}
                      defaultSelected={isFlavourSelected(
                        flavour,
                        selectedFlavours
                      )}
                      slug={flavour.slug}
                      ctaVariant="plus"
                      layoutVariant="vertical"
                      maxWidth={false}
                      selectable={!maxFlavoursReached}
                      labels={generateLabels(
                        flavour,
                        individual,
                        combinedNotRecommendedFlavours,
                        namespace
                      )}
                      truncate={2}
                      // eslint-disable-next-line react/jsx-no-bind
                      onSelectionChange={(selected: boolean) =>
                        onFlavourSelect(selected, flavour)
                      }
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={(selected: boolean) =>
                        cardOnClick(selected, flavour)
                      }
                    />
                    <SpeechBubble
                      variant="square"
                      text={{
                        namespace,
                        variant: 'textRegular16',
                        text: 'plan_steps.recipes.other_recipes.not_recommended_speech_bubble',
                        variables: {
                          count: individual.length,
                          dogName: individual[0].dog.name
                        },
                        element: 'span'
                      }}
                      footPosition="topRight"
                      color="red"
                    />
                  </div>
                )
              })}
          </div>
        </div>
      )}
      <FAQSection
        namespace={namespace}
        maxRecipes={maxFlavours}
        numberOfDogs={individual.length}
        shippingCountryCode={shippingCountryCode()}
      />
      {flowTypeState === 'new' ? (
        <StickyNavigation
          variant="twoButtons"
          disabled={disableNextButton(selectedFlavours)}
          buttonOne={{
            variant: 'secondary',
            text: 'plan_steps.navigation.back',
            iconColour: 'brandRed400',
            // eslint-disable-next-line no-return-assign
            onClick: () => (window.location.href = `/wizard/pet-parent`),
            namespace
          }}
          buttonTwo={{
            dataTestId: 'next-button',
            url: PlansRoutes.Plan,
            variant: 'primary',
            text: 'plan_steps.navigation.continue',
            iconColour: 'brandWhite',
            namespace,
            onClick: () => {
              nextButtonClick()
            }
          }}
        />
      ) : (
        <StickyNavigation
          variant="oneButton"
          disabled={disableNextButton(selectedFlavours)}
          buttonOne={{
            url: PlansRoutes.Plan,
            variant: 'primary',
            text: 'plan_steps.navigation.continue',
            iconColour: 'brandWhite',
            namespace,
            onClick: nextButtonClick
          }}
        />
      )}
      <NotificationContainer />
    </div>
  )
}

export { Props, isFussyEater }
export default Recipes
