import React, { useMemo } from 'react'

import { CustomerIssueReportCreate_response_categoryReasons as CategoryReason } from '../../../../mutations/__generated__/CustomerIssueReportCreate'
import { CustomerIssueManagementInputInput } from '@/types'

import Dropdown, { DropdownOption } from '../display_types/Dropdown'

type ReasonProps = {
  reason: string
  categoryReasons: Array<CategoryReason>
  setReason: (newReason: string) => void
}

const Reason = ({
  reason,
  categoryReasons,
  setReason
}: ReasonProps): JSX.Element | null => {
  const reasons: Array<DropdownOption> = useMemo(() => {
    return categoryReasons
      .sort((a, b) => a.presentationalOrder - b.presentationalOrder)
      .flatMap((cr: CategoryReason): Array<DropdownOption> => {
        return cr.reasons.map((reason) => {
          return {
            key: reason.key,
            value: reason.id,
            text: reason.name
          }
        })
      })
  }, [categoryReasons])

  const reasonInput =
    useMemo<CustomerIssueManagementInputInput>((): CustomerIssueManagementInputInput => {
      return {
        fieldId: 'reason',
        stringValue: reason
      }
    }, [reason])

  if (!categoryReasons.length) return null
  return <Dropdown options={reasons} setValue={setReason} input={reasonInput} />
}

export default Reason
