// @flow

import * as React from 'react'
import GiveGetReferralComponent from '../../ambassador_dashboard/partials/give_get_referral_component'
import ReferallLinkAndShareComponent from '../partials/referral_link_and_share_component'
import { useTranslation } from 'react-i18next'
// eslint-disable-next-line flowtype/no-flow-fix-me-comments
// $FlowFixMe
import BREAKPOINTS from '@/constants/Breakpoints'
import useWindowSize from '@/hooks/useWindowSize'
import CloudinaryImage from '@/components/shared/elements/CloudinaryImage/CloudinaryImage'

// Types
import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'
import type { Language } from '@/packs/localisation'
import type {
  AffiliateType,
  ReferralLink,
} from '../partials/referral_component'

type Props = {|
  affiliateType: AffiliateType,
  fullName: string,
  setAlertMessage: string => void,
  visible: boolean,
  priorityDiscountCodeValues: Array<number>,
  socialDiscountCodeValues: Array<number>,
  priorityReferralLink: ReferralLink,
  socialReferralLink: ReferralLink,
  referralSignupCredit: number,
  shippingCountryCode: CountryCode,
  preferredLanguage: Language
|}

const AmbassadorReferralTab = (props: Props): React.Node => {
  const {
    visible,
    priorityReferralLink,
    priorityDiscountCodeValues,
    socialReferralLink,
    socialDiscountCodeValues,
    referralSignupCredit,
    affiliateType,
    fullName,
    setAlertMessage,
    shippingCountryCode,
    preferredLanguage
  } = props

  const { url: socialUrl, referral_category: socialReferralCategory, referral_type: socialReferralType } = socialReferralLink
  const { url: priorityUrl, referral_category: priorityReferralCategory, referral_type: priorityReferralType } = priorityReferralLink

  const { t } = useTranslation('ambassadors')
  const namespace = 'dashboard.referral_tab'
  const { windowWidth } = useWindowSize()

  return (
    <div className="ambassador-dashboard">
      <div className={`visible-${visible.toString()} tab ambassador-referral`}>
        <div className='ambassador-referral__hero-wrapper'>
          <CloudinaryImage
            image={{
              path: windowWidth > BREAKPOINTS.md ? 'Web/photos/dogs/dogs-on-bench-eating-butternut--desktop-hero.jpg' : 'Web/photos/dogs/dogs-on-bench-eating-butternut.jpg',
              crop: 'crop',
              x: windowWidth > BREAKPOINTS.md ? 200 : 0,
              y: windowWidth > BREAKPOINTS.md ? 200 : 780,
              height: windowWidth > BREAKPOINTS.md ? 600 : 800,
              width: windowWidth > BREAKPOINTS.md ? 2800 : 1400
            }}
            alt='Two dogs stood on a bench eating Butternut.'
            className='hero-section'
          />
          <div className='ambassador-referral__hero-wrapper__header-text'>
            <h1>
              <span>
                { t(`${namespace}.refer_clients`) }
              </span>
              <span>
                { t(`${namespace}.and_followers`) }
              </span>
            </h1>
          </div>
        </div>
        <div className='ambassador-referral__links'>
          <div className='ambassador-give-get-discount'>
            <GiveGetReferralComponent
              referralDiscountCodeValues={priorityDiscountCodeValues}
              referralSignupCredit={referralSignupCredit}
              referralCategory='priority'
              shippingCountryCode={shippingCountryCode}
              preferredLanguage={preferredLanguage}
            />
          </div>
          <div className='small-padding-top container'>
            <div className='ambassador-link-and-share'>
              <ReferallLinkAndShareComponent
                partialLocation={'affiliate'}
                affiliateType={affiliateType}
                referralType={priorityReferralType}
                referralLink={priorityUrl}
                referralCategory={priorityReferralCategory}
                copyReferralLinkCallback={setAlertMessage}
              />
              <div className='referral-link__text'>
                { t(`${namespace}.tell_them_name`) }
              </div>
              <div className='referral-link__referrer-name-container'>
                <h2 className='referral-link__referrer-name'>
                  { fullName }
                </h2>
              </div>
            </div>
          </div>
          <div className='ambassador-referral__social-link-section'>
            <div className='ambassador-give-get-discount'>
              <GiveGetReferralComponent
                referralDiscountCodeValues={socialDiscountCodeValues}
                referralSignupCredit={referralSignupCredit}
                referralCategory='social'
                shippingCountryCode={shippingCountryCode}
                preferredLanguage={preferredLanguage}
              />
            </div>
            <div className='container small-padding-top'>
              <div className='ambassador-link-and-share'>
                <ReferallLinkAndShareComponent
                  partialLocation={'affiliate'}
                  affiliateType={affiliateType}
                  referralType={socialReferralType}
                  referralLink={socialUrl}
                  referralCategory={socialReferralCategory}
                  copyReferralLinkCallback={setAlertMessage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AmbassadorReferralTab
