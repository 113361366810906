// @noflow
import { useReactiveVar } from '@apollo/client'
import isNull from 'lodash/isNull'
import React, { useEffect } from 'react'
import { Route, Routes as RouterRoutes, useLocation } from 'react-router-dom'

import {
  deliveryCadenceState,
  plansPageAnalyticState,
  plansPageState,
  plansPageWizardState
} from '@/components/pages/SimplifiedPlansPage/SimplifiedPlansPage'
import Extras from '@/components/pages/SimplifiedPlansPage/screens/Extras/Extras'
import PetParent from '@/components/pages/SimplifiedPlansPage/screens/PetParent/PetParent'
import Plan from '@/components/pages/SimplifiedPlansPage/screens/Plan/Plan'
import Recipes from '@/components/pages/SimplifiedPlansPage/screens/Recipes/Recipes'

import { triggerPageLoadAnalytics } from './helpers/analytics'
import { FlowType } from './types/analyticsProperties'
import { Routes } from './types/routes'

type Props = {
  namespace: string
  hasRecommendedExtras: boolean
  shouldSeePetParentOnPlans: boolean
}

const SimplifiedPlansPageRouting = ({
  namespace,
  hasRecommendedExtras,
  shouldSeePetParentOnPlans
}: Props): JSX.Element => {
  const route = useLocation().pathname as Routes

  const plansState = useReactiveVar(plansPageState)
  const plansWizardState = useReactiveVar(plansPageWizardState)
  const deliveryCadence = useReactiveVar(deliveryCadenceState)
  const plansAnalyticState = useReactiveVar(plansPageAnalyticState)

  useEffect(() => {
    // Scroll to top of window on each page change
    window.scrollTo(0, 0)

    if (!isNull(plansAnalyticState)) {
      triggerPageLoadAnalytics({
        route,
        plansState,
        wizardState: plansWizardState,
        deliveryCadence,
        flowType: FlowType.new,
        plansAnalyticState
      })
    }
    // We only want to re-trigger the page load analytics when the user navigates
    // back and forth between the pages.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route])

  const hasntSetEmail =
    plansWizardState?.user?.email === '' ||
    plansWizardState?.user?.email.includes('temporary')

  return (
    <RouterRoutes>
      <Route
        path={Routes.PetParent}
        element={
          <PetParent
            namespace={namespace}
            hasRecommendedExtras={hasRecommendedExtras}
            shouldSeePetParentOnPlans={shouldSeePetParentOnPlans}
          />
        }
      />
      <Route
        path={Routes.Recipes}
        element={
          shouldSeePetParentOnPlans && hasntSetEmail ? (
            <PetParent
              namespace={namespace}
              hasRecommendedExtras={hasRecommendedExtras}
              shouldSeePetParentOnPlans={shouldSeePetParentOnPlans}
            />
          ) : (
            <Recipes
              namespace={namespace}
              maxFlavours={7}
              hasRecommendedExtras={hasRecommendedExtras}
              shouldSeePetParentOnPlans={shouldSeePetParentOnPlans}
            />
          )
        }
      />
      <Route
        path={Routes.Plan}
        element={
          <Plan
            namespace={namespace}
            hasRecommendedExtras={hasRecommendedExtras}
            shouldSeePetParentOnPlans={shouldSeePetParentOnPlans}
          />
        }
      />
      <Route
        path={Routes.Extras}
        element={
          <Extras
            namespace={namespace}
            shouldSeePetParentOnPlans={shouldSeePetParentOnPlans}
          />
        }
      />
    </RouterRoutes>
  )
}

export default SimplifiedPlansPageRouting
