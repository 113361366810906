import { gql } from '@apollo/client'

const TOP_NAV_DISCOUNT_QUERY = gql`
  query TopNavDiscountQuery($discountCodeId: ID!) {
    discountCode(discountCodeId: $discountCodeId) {
      id
      discountCodeParts {
        value
        discountBasis
        discountType
        n
      }
    }
  }
`

export { TOP_NAV_DISCOUNT_QUERY }
