import React from 'react'

import Image from '@/components/elements/atoms/Image/Image'

type Props = {
  href: string
  iconSrc: string
  alt: string
  iconWidth?: number
  iconHeight?: number
}

const SocialLink = ({
  href,
  iconSrc,
  alt,
  iconWidth = 45,
  iconHeight = 45
}: Props): JSX.Element => (
  <a href={href} rel="noopener noreferrer" target="_blank">
    <Image
      slug={iconSrc}
      image={{ width: iconWidth, height: iconHeight }}
      alt={alt}
    />
  </a>
)

export { Props }
export default SocialLink
