// @noflow
import React, { useCallback } from 'react'
import { toast } from 'react-toastify'

import { Button } from '@/components/elements/atoms/Button'
import Text from '@/components/elements/atoms/Text/Text'
import NotificationContent from '@/components/elements/molecules/NotificationContent/NotificationContent'

import STYLES from './ProductUnavailable.module.sass'

import { UnavailableObjectReason } from '@/types'

type Props = {
  productCollectionSlug: string
  productSlug: string
  email: string
  reason?: UnavailableObjectReason
}

const ProductUnavailable = ({
  productSlug,
  email,
  productCollectionSlug,
  reason
}: Props): JSX.Element => {
  const namespace = 'dashboard'
  // eslint-disable-next-line i18next/no-literal-string
  const copyContext = `extras.product_collection.details.unavailable.${reason}`
  // eslint-disable-next-line i18next/no-literal-string
  const toastCopy = `extras.product_category_page.waitlist_notification.${reason}`
  const analyticsEventName =
    reason === UnavailableObjectReason.coming_soon
      ? 'Additional Product coming soon Email Added To The Mailing List'
      : 'Additional Product OoS Email Added To The Mailing List'

  const handleAddUserToWaitlist = useCallback((): void => {
    window.analytics.track(analyticsEventName, {
      productCollectionSlug: productCollectionSlug,
      productVariantSlug: productSlug,
      email
    })
    // eslint-disable-next-line i18next/no-literal-string
    window.localStorage.setItem(`${productSlug}-unavailable`, 'true')

    toast.success(
      <NotificationContent
        copy={{
          text: toastCopy,
          namespace,
          variables: {
            email
          }
        }}
      />,
      { toastId: 'banner-success-message', autoClose: 5000 }
    )
  }, [analyticsEventName, productCollectionSlug, productSlug, email, toastCopy])

  const isAddedWaitlist = window.localStorage.getItem(
    // eslint-disable-next-line i18next/no-literal-string
    `${productSlug}-unavailable`
  )

  return (
    <div className={STYLES.container}>
      <Text
        namespace={namespace}
        text={`${copyContext}.title`}
        variant="textRegular14"
        margin={false}
        bold
      />
      <Text
        namespace={namespace}
        text={`${copyContext}.subtitle`}
        variant="textRegular14"
      />
      <Button
        typography={{
          namespace,
          text: `${copyContext}.button`
        }}
        onClick={handleAddUserToWaitlist}
        disabled={isAddedWaitlist === 'true'}
        disableAnalytics
      />
    </div>
  )
}

export default ProductUnavailable
