import { useLanguage } from '@/context/injectedValues/injectedValues'
import { useMutation } from '@apollo/client'
import React, { useCallback, useEffect, useState } from 'react'

import cookies from '@/utils/cookies'
import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { Currency, Locale, formatCurrencyWithDecimal } from '@/utils/currency'
import * as Sentry from '@/utils/sentry'

import useButternutBoost from '@/hooks/useButternutBoost'

import { PAUSE_FLOW_STARTED_EVENT_CREATE } from './mutations/pauseFlowStartedEventCreate'

import { PersonalDetailsQuery_user_subscription } from '../../queries/__generated__/PersonalDetailsQuery'
import { Code, MembershipTypes } from '@/types'

import { BoostModal } from './Boost'
import { DelayBox } from './Delay'
import { DiscountModal } from './Discount'
import { PauseError } from './Error'

type Props = {
  subscription?: PersonalDetailsQuery_user_subscription
  shippingCountryCode?: Code
  showPausePrompt: boolean
  setshowPausePrompt: (val: boolean) => void
}

type BoostedBoxesTotalSavings = {
  boostedSavings: number
  locale: Locale
  currency: Currency
}

const BOOST_TOTAL_WORTH = 5000
const DEFAULT_BOX_ALLOWANCE = 4
const BOOST_WORTH_PER_BOX = BOOST_TOTAL_WORTH / DEFAULT_BOX_ALLOWANCE

const boostedBoxesSavings = ({
  undeliveredMembershipBoxesRemaining
}: {
  undeliveredMembershipBoxesRemaining?: number
}): number =>
  undeliveredMembershipBoxesRemaining && undeliveredMembershipBoxesRemaining > 0
    ? BOOST_WORTH_PER_BOX * undeliveredMembershipBoxesRemaining
    : 0

const boostedBoxesTotalSavings = ({
  boostedSavings,
  locale,
  currency
}: BoostedBoxesTotalSavings): string =>
  formatCurrencyWithDecimal(boostedSavings, {
    locale,
    currency
  })

const PauseModal = ({
  setshowPausePrompt,
  subscription,
  shippingCountryCode = Code.GB
}: Props): JSX.Element | null => {
  const { userLanguage } = useLanguage()
  const [brightbackUrl, setBrightbackUrl] = useState<null | string>(null)

  const { boostedType } = useButternutBoost()
  const [trackPauseFlowStarted] = useMutation(PAUSE_FLOW_STARTED_EVENT_CREATE)

  useEffect(() => {
    if (subscription?.pauseUrl) {
      setBrightbackUrl(subscription?.pauseUrl)
    }
  }, [subscription?.pauseUrl])

  const handleHelpNavigation = useCallback(() => {
    window.location.href = '/contact'
  }, [])

  const handlePauseSubscription = useCallback(async () => {
    window.localStorage.removeItem(cookies.restartBoxSelectedDate)

    if (brightbackUrl) {
      try {
        await trackPauseFlowStarted({
          variables: {
            properties: false
          }
        })
      } catch (error) {
        Sentry.captureException(
          `Error with PAUSE_FLOW_STARTED_EVENT_CREATE mutation`,
          {
            extra: { error },
            tags: {
              product: Sentry.Product.Account,
              team: Sentry.Team.Retention
            }
          }
        )
      } finally {
        window.location.href = brightbackUrl
      }
      return
    }

    handleHelpNavigation()
  }, [brightbackUrl, handleHelpNavigation, trackPauseFlowStarted])

  const handleModalClose = useCallback(
    () => setshowPausePrompt(false),
    [setshowPausePrompt]
  )

  if (!subscription) {
    return <PauseError handleHelpNavigation={handleHelpNavigation} />
  }

  const isAPBoosted = boostedType === MembershipTypes.boost_ap_only

  const { locale, currency } = countryCodeToLocaleCurrency(
    shippingCountryCode,
    userLanguage
  )

  const { activeBoostedMembership, nextEditableBox } = subscription
  const { isBoosted, order, isoDeliveryDate, shippingCountry } =
    nextEditableBox || {}
  const { discountTotal, discountTotalPercentage } = order || {}

  const { undeliveredMembershipBoxesRemaining = 0 } =
    activeBoostedMembership || {}

  const discount = discountTotal || 0
  const discountPercentage = discountTotalPercentage || 0

  const boostedSavings = boostedBoxesSavings({
    undeliveredMembershipBoxesRemaining
  })

  const boostedTotalSavings = boostedBoxesTotalSavings({
    locale,
    currency,
    boostedSavings
  })

  const props = {
    handleHelpNavigation,
    currency,
    locale,
    discount,
    discountPercentage,
    handleModalClose,
    handlePauseSubscription
  }

  if (isBoosted || isAPBoosted) {
    return (
      <BoostModal
        {...props}
        isAPBoosted={isAPBoosted}
        boxesRemaining={undeliveredMembershipBoxesRemaining}
        boostedBoxesTotalSavings={boostedTotalSavings}
        handleModalClose={handleModalClose}
      />
    )
  }

  if (discount > 0) {
    return (
      <DiscountModal
        {...props}
        isoDeliveryDate={isoDeliveryDate}
        shippingCountryCode={shippingCountry?.code || Code.GB}
      />
    )
  }

  return <DelayBox {...props} nextBox={nextEditableBox} />
}
export { PauseModal }
