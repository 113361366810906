// @noflow
import React from 'react'

type Props = {
  size: number
  width?: number | string
}

const Question = ({ size, width }: Props): JSX.Element => {
  return (
    <svg
      fill="none"
      height={size}
      viewBox="0 0 26 26"
      width={width || '100%'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z"
        fill="#BEEBF4"
      />
      <path
        d="M12.9998 22.533C18.2647 22.533 22.5328 18.2649 22.5328 13C22.5328 7.73505 18.2647 3.46698 12.9998 3.46698C7.73487 3.46698 3.4668 7.73505 3.4668 13C3.4668 18.2649 7.73487 22.533 12.9998 22.533Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0001 3.46667C18.2652 3.46667 22.5335 7.73489 22.5335 13C22.5335 18.2651 18.2652 22.5333 13.0001 22.5333C7.73501 22.5333 3.4668 18.2651 3.4668 13C3.4668 7.73489 7.73501 3.46667 13.0001 3.46667ZM13.0001 5.26667C8.72913 5.26667 5.2668 8.72901 5.2668 13C5.2668 17.271 8.72913 20.7333 13.0001 20.7333C17.2711 20.7333 20.7335 17.271 20.7335 13C20.7335 8.72901 17.2711 5.26667 13.0001 5.26667ZM14.0938 15.908C14.272 15.908 14.4178 16.0538 14.4178 16.232V18.176C14.4178 18.3542 14.272 18.5 14.0938 18.5H12.1498C11.9716 18.5 11.8258 18.3542 11.8258 18.176V16.232C11.8258 16.0538 11.9716 15.908 12.1498 15.908H14.0938ZM13.1218 6.98181C15.1954 6.98181 16.54 8.31021 16.54 10.1894C16.54 11.534 15.6814 12.4898 14.9686 13.0082C14.353 13.4618 14.2234 13.7372 14.2234 14.3528V14.6606C14.2234 14.8388 14.0776 14.9846 13.8994 14.9846H12.2794C12.1012 14.9846 11.9554 14.8388 11.9554 14.6606V14.3528C11.9554 13.6562 11.9878 13.0244 12.409 12.4898C12.7654 12.0362 13.3162 11.7608 13.6564 11.4368C13.9966 11.1128 14.272 10.6754 14.272 10.3028C14.272 9.63861 13.7698 9.23361 13.1218 9.23361C12.3928 9.23361 11.9554 9.65481 11.9554 10.3514V10.6268C11.9554 10.805 11.8096 10.9508 11.6314 10.9508H10.0114C9.8332 10.9508 9.6874 10.805 9.6874 10.6268V10.3838C9.6874 8.31021 11.0806 6.98181 13.1218 6.98181Z"
        fill="#0BB4D0"
      />
    </svg>
  )
}

export default Question
