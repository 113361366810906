// @noflow
import { useNotifications } from '@/context/notifications/notifications'
import { ACCOUNT_ROUTES } from '@/routes'
import { useMutation, useQuery } from '@apollo/client'
import isUndefined from 'lodash/isUndefined'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

// Utilities
import * as Sentry from '@/utils/sentry'

// Components
import AlertCard from '@/components/elements/atoms/Alert/AlertCard'
import { Button } from '@/components/elements/atoms/Button'
import MultiLineTextField from '@/components/elements/atoms/MultiLineTextField/MultiLineTextField'
import TextField from '@/components/elements/atoms/TextField/TextField'
import Form from '@/components/elements/molecules/Form/Form'
import { AddressBookQuery_user_address as Address } from '@/components/pages/AddressBookPage/queries/__generated__/AddressBookQuery'

// Styles
import STYLES from './EditDeliveryAddress.module.sass'

import { UPDATE_BOX_DELIVERY_ADDRESS } from './mutations/updateDeliveryAddress'
import { EDIT_BOX_DELIVERY_ADDRESS_QUERY } from './queries/editBoxDeliveryAddressQuery'

// Queries
import { editBoxDeliveryAddressV3Query } from './queries/__generated__/editBoxDeliveryAddressV3Query'

const EditDeliveryAddress = (): JSX.Element | null => {
  const { setSuccessNotification, setErrorNotification } = useNotifications()
  const navigate = useNavigate()

  const { data } = useQuery<editBoxDeliveryAddressV3Query>(
    EDIT_BOX_DELIVERY_ADDRESS_QUERY,
    {
      onError: (error) => {
        Sentry.captureException(
          `Error with EDIT_BOX_DELIVERY_ADDRESS_QUERY query`,
          {
            extra: { error },
            tags: {
              product: Sentry.Product.Account
            }
          }
        )
      }
    }
  )
  const user = data?.user
  const box = user?.subscription?.nextEditableBox
  const address = box?.address
  const shippingCountryCode = user?.shippingCountry?.code

  const [updateAddressMutation, { loading: processing }] = useMutation(
    UPDATE_BOX_DELIVERY_ADDRESS,
    {
      onError: () => {
        setErrorNotification({
          text: 'change_address.error',
          namespace: 'account'
        })
      },
      onCompleted: () => {
        setSuccessNotification({
          text: 'change_address.success',
          namespace: 'account'
        })
        navigate(ACCOUNT_ROUTES.base)
      }
    }
  )

  const updateAddress = useCallback(
    (formData: Partial<Address>) => {
      updateAddressMutation({
        variables: {
          userId: user?.id,
          boxId: box?.id,
          address: {
            recipientName: formData.recipientName || address?.recipientName,
            addressLineOne: formData.address1 || address?.address1,
            addressLineTwo: isUndefined(formData.address2)
              ? address?.address2
              : formData.address2,
            city: formData.city || address?.city,
            postcode: formData.postcode || address?.postcode,
            deliveryNotes: isUndefined(formData.deliveryNotes)
              ? address?.deliveryNotes
              : formData.deliveryNotes
          },
          num: 3
        }
      })
    },
    [updateAddressMutation, address, user?.id, box?.id]
  )

  if (!data) return null

  return (
    <div className={STYLES.container}>
      <Form onSubmit={updateAddress}>
        <TextField
          label="recipientName"
          initialValue={address?.recipientName}
        />
        <TextField label="address1" initialValue={address?.address1} />
        <TextField
          label="address2"
          initialValue={address?.address2 as string}
          optional
        />
        <TextField label="city" initialValue={address?.city} />
        <TextField
          label="postcode"
          type="postcode"
          initialValue={address?.postcode}
          shippingCountryCode={shippingCountryCode}
        />
        <MultiLineTextField
          label="deliveryNotes"
          alwaysShowLabel
          rows={4}
          maxLength={
            user?.currentDeliveryAreaPreference?.preferredCarrierService
              ?.deliveryNotesLimit ?? 250
          }
          initialValue={address?.deliveryNotes}
          optional
        />
        <div className={STYLES.alertCard}>
          <AlertCard
            message={{
              namespace: 'text_field',
              text: 'change_address_form.updated_info',
              margin: false,
              align: 'left'
            }}
            variant="info"
          />
        </div>
        <Button
          identifier="edit_delivery_address.update"
          typography={{
            text: 'change_address_form.button_copy',
            namespace: 'text_field'
          }}
          disabled={processing}
        />
      </Form>
    </div>
  )
}

export default EditDeliveryAddress
