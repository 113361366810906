// @noflow
import { InAppBrowser } from '@/utils/userAgent'

import segmentTrack from '@/components/analytics/Analytics'

import { ensureNever } from '@/typescript/utils'

import type {
  AccountDetailsFormKey,
  ActivePaymentView,
  AddressDetailsFormKey,
  DeliveryDetailsFormKey,
  FormSection,
  PaymentMethod
} from '../types'

type FormSectionKey =
  | AddressDetailsFormKey
  | AccountDetailsFormKey
  | DeliveryDetailsFormKey

const blurredFieldData = (
  fieldName: FormSectionKey,
  input: FormSection,
  obfuscate = false
): {
  fieldName: string
  value: string
  valueLength: number
  validity: boolean
  errorMessage?: string
} => {
  const analyticsEventData = {
    fieldName: fieldName === 'searchTerm' ? 'postcode-search-term' : fieldName,
    value: obfuscate
      ? input.value.replace(/[a-zA-Z]/g, 'X').replace(/\d/g, 'D')
      : input.value,
    valueLength: input.value.length
  }

  if (input.errorMessage !== null) {
    return Object.freeze({
      ...analyticsEventData,
      validity: false,
      errorMessage: input.errorMessage
    })
  }
  return Object.freeze({
    ...analyticsEventData,
    validity: true
  })
}

const blurField = (fieldName: FormSectionKey, input: FormSection): void => {
  const eventTitle = 'Checkout Input Field Blurred'

  switch (fieldName) {
    case 'name':
    case 'city': {
      return segmentTrack(eventTitle, blurredFieldData(fieldName, input))
    }
    case 'email':
    case 'searchTerm':
    case 'addressLine1':
    case 'addressLine2':
    case 'postcode':
    case 'eircode':
    case 'deliveryNotes':
    case 'mobileNumber': {
      return segmentTrack(eventTitle, blurredFieldData(fieldName, input, true))
    }
    case 'password': {
      return segmentTrack(
        eventTitle,
        blurredFieldData(
          fieldName,
          Object.freeze({
            ...input,
            value: '[USER PASSWORD]' // Don't upload user passwords :)
          })
        )
      )
    }
    case 'selectedDeliveryDate':
    case 'visible-password':
      return
    default: {
      ensureNever(fieldName)
    }
  }
}

const showAddressFields = (): void =>
  segmentTrack('Checkout Enter Address Manually Button Clicked')

const deliveryDateModalToggle = (isModalOpen: boolean): void => {
  segmentTrack(
    isModalOpen
      ? 'Checkout Delivery Date Modal Opened'
      : 'Checkout Delivery Date Modal Closed'
  )
}

const deliveryDateUpdated = ({
  previousDate,
  newDate
}: {
  previousDate: string
  newDate: string
}): void =>
  segmentTrack('Checkout First Delivery Date Updated', {
    previousDate,
    newDate
  })

const dismissCountdownBanner = (): void =>
  segmentTrack('Checkout Countdown Banner Dismissed')

const offerNextDayDelivery = (): void =>
  segmentTrack('Next Day Delivery Offered as Default', { location: 'checkout' })

const paymentOptionsToggle = (
  selectedPaymentOption: ActivePaymentView
): void => {
  if (selectedPaymentOption === 'none') return
  segmentTrack('Checkout Payment Method Toggled', { selectedPaymentOption })
}

const payPalButtonClicked = (): void => segmentTrack('PayPal Button Clicked')

const idealButtonClicked = (inAppBrowser: InAppBrowser | null): void =>
  segmentTrack('Ideal Button Clicked', { inAppBrowser })

const bancontactButtonClicked = (inAppBrowser: InAppBrowser | null): void =>
  segmentTrack('Bancontact Button Clicked', { inAppBrowser })

const goCardlessButtonClicked = (inAppBrowser: InAppBrowser | null): void =>
  segmentTrack('GoCardless Button Clicked', { inAppBrowser })

const orderSummaryModalToggle = (isModalOpen: boolean): void => {
  segmentTrack(
    isModalOpen
      ? 'Checkout Review Order Modal Opened'
      : 'Checkout Review Order Modal Closed'
  )
}

const orderSummaryOpened = (): void => {
  segmentTrack('Checkout New Order Summary Opened')
}

const submissionAttempt = (paymentMethod: PaymentMethod): void =>
  segmentTrack('Checkout Submission Attempted', { paymentMethod })

const setPaymentComplete = (): void =>
  segmentTrack('Checkout Credit Card Details Completed', {})

const setStripePaymentComplete = (paymentMethod: PaymentMethod): void =>
  segmentTrack('Checkout Stripe Payment Element Completed', { paymentMethod })

const setPaymentError = ({
  error,
  paymentMethod
}: {
  error: string
  paymentMethod: PaymentMethod
}): void => segmentTrack('Checkout Validation Failed', { error, paymentMethod })

const trackPlanId = (planId: number): void =>
  segmentTrack('Plan ID Submitted at Checkout', { planId })

const expressPayButtonClicked = (): void =>
  segmentTrack('Component Clicked', {
    component_identifier: 'Express Pay',
    screenIdentifier: 'Checkout'
  })

const expressPayCancelled = (): void => segmentTrack('Express Pay Cancelled')

const defaultDeliveryDateSaturday = (date: string): void =>
  segmentTrack('Default delivery date is Saturday', { date })

export {
  blurField,
  showAddressFields,
  deliveryDateModalToggle,
  deliveryDateUpdated,
  dismissCountdownBanner,
  offerNextDayDelivery,
  paymentOptionsToggle,
  payPalButtonClicked,
  bancontactButtonClicked,
  goCardlessButtonClicked,
  idealButtonClicked,
  orderSummaryModalToggle,
  orderSummaryOpened,
  submissionAttempt,
  setPaymentComplete,
  setStripePaymentComplete,
  setPaymentError,
  trackPlanId,
  expressPayButtonClicked,
  expressPayCancelled,
  defaultDeliveryDateSaturday
}
