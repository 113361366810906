// @noflow
import { useMutation } from '@apollo/client'
import {
  IdealBankElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js'
import React from 'react'

import * as Sentry from '@/utils/sentry'

import { INSTANT_CHARGE_SETUP_CREATE } from '../components/pages/MyDetailsPage/screens/PaymentMethods/mutations/InstantChargeSetupCreate'
import { PAY_OVERDUE_INVOICES_AND_UNSUSPEND } from '../components/pages/MyDetailsPage/screens/PaymentMethods/mutations/PayOverdueInvoicesAndUnsuspend'
import type { instantChargeSetupCreate } from '../components/pages/MyDetailsPage/screens/PaymentMethods/mutations/__generated__/instantChargeSetupCreate'
import type { payOverdueInvoicesAndUnsuspend } from '../components/pages/MyDetailsPage/screens/PaymentMethods/mutations/__generated__/payOverdueInvoicesAndUnsuspend'

import { Code, PaymentResourceType } from '@/types'

type UsePayWithSepaMethod = {
  payOutstandingInvoicesWithSepaMethod: () => void
  loading: boolean
}

const usePayWithSepaMethod = ({
  shippingCountryCode
}: {
  shippingCountryCode: Code
}): UsePayWithSepaMethod => {
  const stripe = useStripe()
  const elements = useElements()
  const idealBank = elements?.getElement(IdealBankElement)

  const urlParams = new URLSearchParams(window.location.search)
  // need to split paymentIntentSecret to get the id from the start of the string
  const paymentIntentId = urlParams
    .get('payment_intent_client_secret')
    ?.split('_secret_')[0]
  // eslint-disable-next-line i18next/no-literal-string
  const paymentMethodsPageUrl = '/dashboard/my-details/payment-methods'

  const [payOverdueInvoicesAndUnsuspend, { loading: unsuspendLoading }] =
    useMutation<payOverdueInvoicesAndUnsuspend>(
      PAY_OVERDUE_INVOICES_AND_UNSUSPEND
    )
  const [
    instantChargeSetupCreate,
    { loading: instantChargeSetupCreateLoading }
  ] = useMutation<instantChargeSetupCreate>(INSTANT_CHARGE_SETUP_CREATE, {
    variables: {
      redirectUrl: paymentMethodsPageUrl
    },
    onCompleted: async (data) => {
      if (!data.instantChargeSetupCreate?.user || !stripe) return

      if (shippingCountryCode === Code.NL && idealBank) {
        await stripe.confirmIdealPayment(
          data.instantChargeSetupCreate.instantChargeSetupToken,
          {
            payment_method: {
              ideal: idealBank,
              billing_details: {
                name: `${data.instantChargeSetupCreate.user.firstName} ${data.instantChargeSetupCreate.user.lastName}`,
                email: data.instantChargeSetupCreate.user.email
              }
            },
            return_url: window.location.href
          }
        )
      } else if (shippingCountryCode === Code.BE) {
        await stripe.confirmBancontactPayment(
          data.instantChargeSetupCreate.instantChargeSetupToken,
          {
            payment_method: {
              billing_details: {
                name: `${data.instantChargeSetupCreate.user.firstName} ${data.instantChargeSetupCreate.user.lastName}`,
                email: data.instantChargeSetupCreate.user.email
              }
            },
            return_url: window.location.href
          }
        )
      }
    },
    onError: (error) => {
      Sentry.captureException(
        `Error encountered in BILLING_REQUEST_FLOW_CREATE`,
        { extra: { error } }
      )
    }
  })

  React.useEffect(() => {
    if ([Code.NL, Code.BE].includes(shippingCountryCode) && !stripe)
      throw new Error('Cannot find stripe when usePayWithSepaMethod is called')
  }, [shippingCountryCode, stripe])

  React.useEffect(() => {
    if ([Code.NL, Code.BE].includes(shippingCountryCode) && paymentIntentId) {
      payOverdueInvoicesAndUnsuspend({
        variables: {
          paymentResourceId: paymentIntentId,
          paymentResourceType: PaymentResourceType.payment_intent,
          provider: 'stripe'
        },
        onCompleted: () => {
          window.location.href = paymentMethodsPageUrl
        }
      })
    }
  }, [paymentIntentId, payOverdueInvoicesAndUnsuspend, shippingCountryCode])

  const payOutstandingInvoicesWithSepaMethod = () => {
    instantChargeSetupCreate()
  }

  const loading = unsuspendLoading || instantChargeSetupCreateLoading

  return {
    payOutstandingInvoicesWithSepaMethod,
    loading
  }
}

export default usePayWithSepaMethod
