/* eslint-disable jsx-a11y/label-has-for */
import React from 'react'

import Label from '@/components/elements/atoms/Label/Label'

type Props = {
  subscribeSave?: boolean
  recurring?: boolean
}

const ItemLabels = ({ subscribeSave, recurring }: Props): JSX.Element => {
  if (subscribeSave || recurring) {
    return (
      <Label
        variant="subscribeAndSave"
        text={{
          text: 'box_breakdown.subscribe_and_save',
          namespace: 'shared'
        }}
      />
    )
  }

  return (
    <Label
      variant="oneOff"
      text={{
        text: 'box_breakdown.one_off',
        namespace: 'shared'
      }}
    />
  )
}

export default ItemLabels
