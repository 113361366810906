// @noflow
import { Language } from '@/packs/localisation'
import i18next from 'i18next'
import React, { useMemo } from 'react'
import type { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Markup } from 'react-render-markup'

import { lngStringToLngType } from '@/utils/StringHelper'

import { cloudinaryPath } from '@/components/elements/atoms/CloudinaryImage/CloudinaryImage'
import Text from '@/components/elements/atoms/Text/Text'
import type { Benefit } from '@/components/shared/ProductCard'

import { Language as LanguageEnum } from '@/types'

import SwiperSlider from '../elements/molecules/SwiperSlider/SwiperSlider'
import TransitionalModal from './TransitionalModal'
import CloudinaryImage from './elements/CloudinaryImage/CloudinaryImage'

type Constituent = {
  name: string
  percentage: string
}

type CloudinaryOtherAsset = {
  // Not sure why this is erroring and online discussions suggest there's a
  // chance it's a false positive
  // eslint-disable-next-line react/no-unused-prop-types
  path: string
  // eslint-disable-next-line react/no-unused-prop-types
  alt: string | undefined
}

type PresentationalProps = {
  images: Array<CloudinaryOtherAsset>
  title: string
  description: string
  benefits: Array<Benefit>
  composition?: string
  constituents?: Array<Constituent>
  additives?: string
  storage?: string
  className: string
  ingredients?: {
    composition: string
    analyticalConstituents: Array<string>
  }
  tastingNotes?: string
  signedIn: boolean
  displayCtaButton?: boolean
  shopProductName?: string
  isExtras?: boolean
}

type ModalProps = {
  isOpen: boolean
  closeAndUnmount: () => void
}

type Props = PresentationalProps & ModalProps

const Carousel = ({
  images
}: {
  images: Array<CloudinaryOtherAsset>
}): ReactElement => (
  <div className="carousel-wrapper">
    <SwiperSlider
      variant="brandBlue100"
      arrows
      arrowsInside
      bullets
      slidesPerView={1}
    >
      {images.map(
        ({ path, alt }: CloudinaryOtherAsset): ReactElement => (
          <CloudinaryImage
            key={path}
            alt={alt || ''}
            image={{
              path,
              crop: 'fit',
              width: 560,
              dpr: window.devicePixelRatio,
              quality: 'auto:best'
            }}
          />
        )
      )}
    </SwiperSlider>
  </div>
)

const CTAButton = ({
  signedIn,
  className,
  shopProductName
}: {
  signedIn: boolean
  className: string
  shopProductName?: string
}): ReactElement => {
  const { t } = useTranslation('product_modal')

  const details = useMemo(() => {
    const defaultValue = {
      customerLocation: 'dashboard/subscription',
      guestLocation: '/wizard/new',
      ctaCopy: signedIn ? t('change') : t('build')
    }

    switch (className) {
      case 'recipe': {
        return defaultValue
      }
      case 'extra': {
        return {
          // eslint-disable-next-line i18next/no-literal-string
          customerLocation: `dashboard/extras/${shopProductName}`,
          guestLocation: '/wizard/new',
          ctaCopy: t('discover')
        }
      }
      default: {
        return defaultValue
      }
    }
  }, [className, t, shopProductName, signedIn])

  const { customerLocation, guestLocation, ctaCopy } = details

  const location = signedIn ? customerLocation : guestLocation
  const redirect = React.useCallback((): void => {
    window.location.href = location
  }, [location])

  return (
    <button
      className="btn-updated btn-updated--red"
      type="button"
      onClick={redirect}
    >
      {ctaCopy}
    </button>
  )
}

const Benefits = ({
  benefits,
  className
}: {
  benefits: Array<Benefit>
  className: string
}): ReactElement => (
  <div className={`${className}-modal__body__key-benefits`}>
    <div className={`${className}-modal__body__key-benefits__benefits`}>
      {benefits
        .filter((benefit) => benefit.url !== null)
        .map((benefit) => (
          <div
            key={benefit.name}
            className={`${className}-modal__body__benefit`}
          >
            {benefit.url && (
              <img
                src={cloudinaryPath({
                  path: benefit.url,
                  transformations: { format: 'svg', width: 25 }
                })}
                alt={benefit.name}
              />
            )}
            <p>{benefit.name}</p>
          </div>
        ))}
    </div>
  </div>
)

const Ingredients = ({
  composition,
  className,
  ingredients
}: {
  composition?: string
  className: string
  ingredients?: {
    composition: string
    analyticalConstituents: Array<string>
  }
}): ReactElement => {
  const { t } = useTranslation('product_modal')
  const [isOpen, setIsOpen] = React.useState(false)
  const allowedHtmlElement = ['strong', 'em', 'b', 'a', 'br', 'span']
  const onClick = React.useCallback((): void => {
    setIsOpen(!isOpen)
  }, [isOpen])
  return (
    <div className={`${className}-modal__body__composition section-wrapper`}>
      <button
        className={`section-button ${isOpen ? 'open' : 'closed'}`}
        type="button"
        name="ingredients"
        onClick={onClick}
      >
        <h3>{t('ingredients')}</h3>
      </button>
      <p className={`section-content ${isOpen ? 'open' : 'closed'}`}>
        <Markup
          allowed={allowedHtmlElement}
          markup={ingredients?.composition || composition}
        />
      </p>
    </div>
  )
}

const MoreDetails = ({
  additives,
  className
}: {
  additives?: string
  className: string
}): ReactElement => {
  const { t } = useTranslation('dashboard')
  const [isOpen, setIsOpen] = React.useState(false)

  const onClick = React.useCallback(() => setIsOpen(!isOpen), [isOpen])

  return (
    <div
      className={`${className}-modal__body__nutritional-info section-wrapper`}
    >
      <button
        className={`section-button ${isOpen ? 'open' : 'closed'}`}
        type="button"
        name="nutritional"
        onClick={onClick}
      >
        <h3>{t('extras.product_collection.details.see_details_link')}</h3>
      </button>
      <div
        className={`${className}-modal__body__constituents ${
          isOpen ? 'open' : 'closed'
        }`}
      >
        {additives && (
          <div
            className="section-content"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: additives
            }}
          />
        )}
      </div>
    </div>
  )
}
const NutritionalInfo = ({
  constituents,
  additives,
  analyticalConstituents,
  className
}: {
  constituents?: Array<Constituent>
  additives?: string
  className: string
  analyticalConstituents?: Array<string>
}): ReactElement => {
  const { t } = useTranslation('product_modal')
  const [isOpen, setIsOpen] = React.useState(false)
  const onClick = React.useCallback((): void => {
    setIsOpen(!isOpen)
  }, [isOpen])
  return (
    <div
      className={`${className}-modal__body__nutritional-info section-wrapper`}
    >
      <button
        className={`section-button ${isOpen ? 'open' : 'closed'}`}
        type="button"
        name="nutritional"
        onClick={onClick}
      >
        <h3>{t('nutritional_info')}</h3>
      </button>
      <div
        className={`${className}-modal__body__constituents ${
          isOpen ? 'open' : 'closed'
        }`}
      >
        <h4>{t('analytical_constituents')}</h4>
        {constituents &&
          constituents.map(
            (constituent: Constituent): ReactElement => (
              <p key={constituent.name}>
                {constituent.name}
                <span
                  className={`${className}-modal__body__constituents__percentage`}
                >
                  {constituent.percentage}
                </span>
              </p>
            )
          )}
        {analyticalConstituents &&
          analyticalConstituents.map(
            (constituent: string): ReactElement => (
              <p key={constituent}>{constituent}</p>
            )
          )}
        {additives && (
          <>
            <h4>{t('nutritional_additives')}</h4>
            <p>{additives}</p>
          </>
        )}
      </div>
    </div>
  )
}

const StorageAndUse = ({
  storage,
  className
}: {
  storage: string
  className: string
}): ReactElement => {
  const { t } = useTranslation('product_modal')
  const [isOpen, setIsOpen] = React.useState(false)
  const onClick = React.useCallback((): void => {
    setIsOpen(!isOpen)
  }, [isOpen])
  return (
    <div
      className={`${className}-modal__body__storage-and-use section-wrapper`}
    >
      <button
        type="button"
        name="storage"
        className={`section-button ${isOpen ? 'open' : 'closed'}`}
        onClick={onClick}
      >
        <h3>{t('storage')}</h3>
      </button>
      <p className={`section-content ${isOpen ? 'open' : 'closed'}`}>
        {storage}
      </p>
    </div>
  )
}

const TransitionGuide = ({
  className
}: {
  className: string
}): ReactElement => {
  const { t } = useTranslation('product_modal')
  const [isOpen, setIsOpen] = React.useState(false)
  const onClick = React.useCallback((): void => {
    setIsOpen(!isOpen)
  }, [isOpen])

  const sanitisedLanguageCode = lngStringToLngType(i18next.language)

  const localisedTransitioningPDFPath = (language: Language) => {
    switch (language) {
      case LanguageEnum.nl:
      case LanguageEnum.nl_BE:
        return 'Transitioning_Page_Dutch'
      case LanguageEnum.pl:
      case LanguageEnum.pl_PL:
        return 'Transitioning_Page_Polish'
      case LanguageEnum.de_DE:
        return 'Transitioning_Page_German'
      default:
        return 'Transitioning_Page'
    }
  }

  const transitioningPDFPath = localisedTransitioningPDFPath(
    sanitisedLanguageCode
  )

  // eslint-disable-next-line i18next/no-literal-string
  const guideUrl = `https://assets.butternutbox.com/image/upload/Web/documents/${transitioningPDFPath}.pdf`
  return (
    <div
      className={`${className}-modal__body__storage-and-use section-wrapper`}
    >
      <button
        type="button"
        name="transition"
        className={`section-button ${isOpen ? 'open' : 'closed'}`}
        onClick={onClick}
      >
        <h3>{t('transition')}</h3>
      </button>
      <div className={`section-content ${isOpen ? 'open' : 'closed'}`}>
        <Text
          variant="textRegular16"
          text="transition_content"
          namespace="product_modal"
          variables={{ guideUrl }}
        />
      </div>
    </div>
  )
}

const ProductModalContent = ({
  shopProductName,
  title,
  description,
  images,
  benefits,
  composition,
  constituents,
  additives,
  storage,
  className,
  ingredients,
  tastingNotes,
  signedIn,
  displayCtaButton = true,
  isExtras = false
}: PresentationalProps): ReactElement => {
  return (
    <div className={`${className}-modal`}>
      <Carousel images={images} />
      <div className={`${className}-modal__body`}>
        <h2 className={`${className}-modal__body__recipe-name`}>{title}</h2>
        <Benefits className={className} benefits={benefits} />
        {tastingNotes && (
          <p className={`${className}-modal__body__recipe-description`}>
            {tastingNotes}
          </p>
        )}
        <div
          className={`${className}-modal__body__recipe-description`}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: description
          }}
        />
        {(ingredients || composition) && (
          <Ingredients
            composition={composition}
            ingredients={ingredients}
            className={className}
          />
        )}
        {(ingredients?.analyticalConstituents || constituents) && !isExtras && (
          <NutritionalInfo
            constituents={constituents}
            additives={additives}
            className={className}
            analyticalConstituents={ingredients?.analyticalConstituents}
          />
        )}
        {isExtras && (
          <MoreDetails additives={additives} className={className} />
        )}
        {storage && <StorageAndUse storage={storage} className={className} />}
        {!isExtras && <TransitionGuide className={className} />}
        {displayCtaButton && (
          <div className={`${className}-modal__body__sticky-container`}>
            <CTAButton
              signedIn={signedIn}
              className={className}
              shopProductName={shopProductName}
            />
          </div>
        )}
      </div>
    </div>
  )
}

const ProductModal = ({
  isOpen,
  closeAndUnmount,
  shopProductName,
  title,
  description,
  images,
  benefits,
  composition,
  constituents,
  additives,
  storage,
  className,
  ingredients,
  tastingNotes,
  signedIn,
  displayCtaButton,
  isExtras
}: Props): ReactElement => (
  <TransitionalModal
    modalSize="medium"
    showCloseButton
    useCustomCloseButton
    customCloseButtonContent={<span />}
    modalIsOpen={isOpen}
    closeModal={closeAndUnmount}
    transitionTypes={{ desktop: 'right', mobile: 'bottom' }}
    extraClasses={`${className}-modal`}
  >
    <ProductModalContent
      shopProductName={shopProductName}
      title={title}
      description={description}
      images={images}
      benefits={benefits}
      composition={composition}
      constituents={constituents}
      ingredients={ingredients}
      additives={additives}
      storage={storage}
      className={className}
      tastingNotes={tastingNotes}
      signedIn={signedIn}
      displayCtaButton={displayCtaButton}
      isExtras={isExtras}
    />
  </TransitionalModal>
)

export { CloudinaryOtherAsset }
export default ProductModal
