// @flow

import * as React from 'react'

// Components
import ExpandedDogDetails from './ExpandedDogDetails'
import MarketingPreferencesSOC from './MarketingPreferences/MarketingPreferences'

// Assets
import { dogDetailsIcons } from '../../asset-imports/icons'

// Types
import type { ComponentProps } from './../../containers/user_details/DogsDetailsContainer'
import type { DogDetails } from './../../message_types'

type DogDetailsRowProps = {|
  userId: $PropertyType<ComponentProps, 'userId'>,
  dispatchToggleDogOverview: $PropertyType<ComponentProps, 'dispatchToggleDogOverview'>,
  dispatchToggleDogDetails: $PropertyType<ComponentProps, 'dispatchToggleDogDetails'>,
  dogDetails: DogDetails,
  index: number
|}

const DogDetailsRow = ({
  dogDetails,
  userId,
  dispatchToggleDogOverview,
  dispatchToggleDogDetails,
  index
}: DogDetailsRowProps): React.Element<'div'> => {
  const {
    summary_details: {
      image_src: imageSrc,
      full_image_src: fullImageSrc,
      archived,
      name,
      id,
      gender,
      age_type: ageType,
      breed
    }
  } = dogDetails

  // Variables
  const dogAvatar = React.useMemo((): string => {
    const random = Math.floor(Math.random() * 3 + 1)
    const assets = [
      dogDetailsIcons.dogAvatar1,
      dogDetailsIcons.dogAvatar2,
      dogDetailsIcons.dogAvatar3
    ]

    return assets[random - 1]
  }, [])

  const dogPhoto = imageSrc || dogAvatar
  const fullDogPhoto = fullImageSrc || dogAvatar

  // Handlers
  const handleToggleDetails = React.useCallback((): void => {
    dispatchToggleDogDetails(index)
  }, [dispatchToggleDogDetails, index])

  return (
    <div>
      <div className={`card__row ${archived ? 'card__row--archived' : ''}`}>
        <a
          href={fullDogPhoto}
          target="_blank"
          rel="noreferrer"
        >
          <img
            className='card__row__photo'
            src={dogPhoto}
            alt='Dog details avatar'
          />
        </a>
        <div className='card__row__right-side'>
          <div className='card__row__subrow'>
            <h4 className='card__row__name'>
              { name }
            </h4>
            <div className='card__row__edit-container'>
              <a
                className="card__button button--grey"
                href={`/admin/dogs/${id}/edit`}
                rel="noopener noreferrer"
                target='_blank'
              >
                <img
                  src={dogDetailsIcons.edit}
                  alt='Edit icon'
                  className='icon'
                />
              </a>
              <a
                className="card__button button--grey"
                href={`/admin/users/${userId}`}
                rel="noopener noreferrer"
                target='_blank'
              >
                <img
                  src={dogDetailsIcons.deleteIcon}
                  alt='Delete icon'
                  className='icon'
                />
              </a>
              <button
                className="card__button button--grey"
                onClick={handleToggleDetails}
                type='button'
              >
                <img
                  src={dogDetailsIcons.arrow}
                  alt='Expand icon'
                  className='icon'
                />
              </button>
            </div>
          </div>
          <div className='card__row__main-info'>
            <span>
              <img
                src={dogDetailsIcons.gender}
                alt='Gender icon'
                className='icon'
              />
              <p className='card__row__gender'>
                { gender }
              </p>
            </span>
            <span>
              <img
                src={dogDetailsIcons.dog}
                alt='Age icon'
                className='icon'
              />
              <p className='card__row__age'>
                { ageType }
              </p>
            </span>
            <span>
              <img
                src={dogDetailsIcons.breed}
                alt='Breed icon'
                className='icon'
              />
              <p className='card__row__breed'>
                { breed }
              </p>
            </span>
          </div>
          <div>
            {
              archived && (
                <span className='label label--red'>
                  { 'Archived' }
                </span>
              )
            }
          </div>
        </div>
      </div>
      <ExpandedDogDetails
        dispatchToggleDogOverview={dispatchToggleDogOverview}
        dogIndex={index}
        details={dogDetails}
      />
    </div>
  )
}

const DogDetailsComponent = ({
  userId,
  dogsDetails,
  isFetching,
  dispatchGetDogsDetails,
  dispatchToggleDogOverview,
  dispatchToggleDogDetails
}: ComponentProps): React.Element<'div'> => {
  // Variables
  const transitionState = React.useMemo((): string => isFetching
    ? 'loading'
    : 'loaded',
  [isFetching]
  )

  // Effects
  React.useEffect((): void => {
    dispatchGetDogsDetails(userId)
  }, [dispatchGetDogsDetails, userId])

  return (
    <div>
      <div className={`dog-details card__container card__${transitionState}`}>
        <div className='card__header__container'>
          <h3 className='card__header'>
            { 'Dog details' }
          </h3>
          <div className='card__header__right-side'>
            {
              !isFetching && (
                <p>
                  { dogsDetails.length }
                  { dogsDetails.length === 1 ? ' dog' : ' dogs' }
                </p>
              )
            }
            <a
              className='card__button button--white'
              href={`/admin/dogs/new?user_id=${userId}`}
              rel="noopener noreferrer"
              target='_blank'
            >
              <span>
                { '+' }
              </span>
              { ' Add a Dog' }
            </a>
          </div>
        </div>
        <div className='card__body'>
          {
            isFetching && (
              <div className="loading-ring">
                <span />
                <span />
                <span />
                <span />
              </div>
            )
          }
          {
            dogsDetails.length === 0
              ? (
                <div className='card__row--no-dogs'>
                  <p>
                    { `No dogs` }
                  </p>
                </div>
                )
              : (
                  dogsDetails.map((
                    dogDetails: DogDetails,
                    index: number
                  ): React.Element<typeof DogDetailsRow> => (
                    <DogDetailsRow
                      key={dogDetails.summary_details.id}
                      dogDetails={dogDetails}
                      userId={userId}
                      dispatchToggleDogOverview={dispatchToggleDogOverview}
                      dispatchToggleDogDetails={dispatchToggleDogDetails}
                      index={index}
                    />
                  ))
                )
          }
        </div>
      </div>
      <MarketingPreferencesSOC />
    </div>
  )
}

export default DogDetailsComponent
