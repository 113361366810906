// @noflow
import React, { CSSProperties } from 'react'
import type { ReactElement } from 'react'

import { isDark } from '@/utils/contrast'

import STYLES from './productlabel.module.sass'

type Props = {
  name: string
  themeColour: string
  extraClasses?: string
  styleOverrides?: CSSProperties
}

const ProductLabel = ({
  name,
  themeColour,
  extraClasses = '',
  styleOverrides
}: Props): ReactElement => (
  <p
    className={`${STYLES.productLabel} ${extraClasses} ${
      !isDark(themeColour) ? STYLES.invert : ''
    }`}
    style={{
      backgroundColor: themeColour,
      ...styleOverrides
    }}
  >
    {name}
  </p>
)

export default ProductLabel
