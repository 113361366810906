import React from 'react'

import { CustomerIssueManagementInitialDataQuery_categories as EligibleCategory } from '../../../queries/__generated__/CustomerIssueManagementInitialDataQuery'

import Category from './Category'

type CategoryButtonsProps = {
  categories: Array<EligibleCategory>
  selectCategory: (id: string) => void
}

const CategoryButtons = ({
  categories,
  selectCategory
}: CategoryButtonsProps): JSX.Element => {
  const sortedCategories = categories.sort(
    (a, b) => a.presentationalOrder - b.presentationalOrder
  )
  return (
    <>
      {sortedCategories.map((cat) => {
        return (
          <Category
            key={cat.id}
            category={cat}
            selectCategory={selectCategory}
          />
        )
      })}
    </>
  )
}

export default CategoryButtons
