import { gql } from '@apollo/client'

const DIRECT_SALES_PAYMENT_QUERY = gql`
  query DirectSalesPaymentQuery($pendingSubscriptionId: ID!) {
    directSalesPendingSubscription(
      pendingSubscriptionId: $pendingSubscriptionId
    ) {
      pendingSubscription {
        id
        user {
          id
          email
          preferredLanguage
          firstName
          lastName
          dogs {
            gender
            name
            id
            ageInMonths
            breed {
              id
              key
              name
            }
            dogProfile {
              avatarUrl
              description
            }
            dailyCalories
            dailyGrams
            eaterType
            neutered
            possessivePronoun
            pouchSizeInGrams
            weightInGrams
          }
        }
        plan {
          id
          numberOfPouches
          pouchSize
          pouchesPerDay
          price
          durationInDays
          typeOfPlanForCustomer
          trialLengthInDays
          pricesPerDayPerDogs {
            name
            pricePerDayWithoutDiscount
            pricePerDayWithDiscount
          }
        }
        pendingSubscriptionFlavours {
          id
          flavour {
            name
            slug
            themeColour
            thumbnail {
              src
            }
          }
          trialBoxServings
          sevenDayServings
        }
        productVariants {
          productVariant {
            id
            name
            grossPrice
            discountedPrice
            oneOffDeliveryType {
              netPrice
              adjustedGrossPrice
            }
            productCollection {
              id
              name
            }
          }
          discountedPrice
        }
      }
      address {
        address1
        address2
        city
        postcode
        shippingCountry {
          code
          id
        }
        recipientName
        deliveryNotes
        deliveryCarrier
      }
      discountCode {
        id
        discountBasis
        value
        n
        discountType
        code
        discountCodeParts {
          value
          discountBasis
          discountType
          n
        }
      }
      consentedToMarketing
      marketingPreferences
      phoneNumber
    }
  }
`

const DIRECT_SALES_PRICING_QUERY = gql`
  query DirectSalesPendingSubscriptionOrderPrices(
    $planId: ID!
    $discountCodeId: ID!
    $productVariantIds: [ID!]!
    $flavourQuantities: MealInput!
    $oneOffProductsPresent: Boolean!
  ) {
    pendingSubscriptionOrderPrices(
      planId: $planId
      productVariantIds: $productVariantIds
      discountCodeId: $discountCodeId
      flavourQuantities: $flavourQuantities
      oneOffProductsPresent: $oneOffProductsPresent
    ) {
      grossTotalPrice
      netTotalPrice
      netTotalPricePerDay
      totalDiscountedAmount
      grossCoreFoodPrice
      netCoreFoodPrice
      coreFoodDiscountedAmount
      grossCoreFoodPricePerDay
      netCoreFoodPricePerDay
      grossRecipeSurchargePrice
      netRecipeSurchargePrice
      recipeSurchargeDiscountedAmount
      grossDeliverySurchargePrice
      netDeliverySurchargePrice
      deliverySurchargeDiscountedAmount
      grossOneOffAdditionalProductsPrice
      additionalProductsDiscountedAmount
      netOneOffAdditionalProductsPrice
      grossAdditionalProductsPrice
      netAdditionalProductsPrice
      additionalProductPrices {
        id
        recurringPrice
        oneOffPrice
        discountedRecurringPrice
        discountedOneOffPrice
      }
    }
  }
`

const CHARGE_ID_FROM_PAYMENT_INTENT_ID = gql`
  query DirectSalesChargeIdFromPaymentIntentId(
    $paymentIntentId: String!
    $paymentMethodType: PaymentMethodType!
  ) {
    chargeIdFromPaymentIntentId(
      paymentIntentId: $paymentIntentId
      paymentMethodType: $paymentMethodType
    )
  }
`

const DIRECT_SALES_SEPA_PAYMENT_METHOD_ID_FROM_INTENT_SECRET = gql`
  query DirectSalesSepaPaymentMethodIdFromIntentSecret(
    $intentSecret: String!
    $intentType: IntentType!
  ) {
    sepaPaymentMethodIdFromIntentSecret(
      intentSecret: $intentSecret
      intentType: $intentType
    )
  }
`

const RETRIEVE_RAF_URL = gql`
  query RetrieveRafUrl($id: ID!) {
    user(id: $id) {
      subscription {
        referralLink {
          url
        }
      }
    }
  }
`

export {
  DIRECT_SALES_PAYMENT_QUERY,
  CHARGE_ID_FROM_PAYMENT_INTENT_ID,
  DIRECT_SALES_SEPA_PAYMENT_METHOD_ID_FROM_INTENT_SECRET,
  RETRIEVE_RAF_URL,
  DIRECT_SALES_PRICING_QUERY
}
