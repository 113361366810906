// @noflow
import React from 'react'

import { cloudinaryPath } from '@/components/elements/atoms/CloudinaryImage/CloudinaryImage'
import Text, { Props as TextProps } from '@/components/elements/atoms/Text/Text'

import STYLES from './ProductBenefit.module.sass'

type Benefit = {
  name: string
  url: string | null
}

type Props = {
  benefit: Benefit
  invert?: boolean
  position?: 'top' | 'left'
}

const ProductBenefit = ({
  benefit,
  invert = false,
  position = 'top'
}: Props): JSX.Element => {
  const textProperties: Pick<TextProps, 'element' | 'variant' | 'colour'> = {
    element: 'span',
    variant: 'textRegular14',
    colour: invert ? 'brandWhite' : 'brandBlue400'
  }

  return (
    <div
      className={`${STYLES.productBenefit} ${
        position === 'left' ? STYLES.twoColumns : ''
      }`}
    >
      {benefit.url && (
        <img
          alt={benefit.name}
          src={cloudinaryPath({ path: benefit.url, transformations: {} })}
        />
      )}
      <div className={STYLES.productBenefitText}>
        <Text
          text={benefit.name}
          margin={false}
          element={textProperties.element}
          variant={textProperties.variant}
          colour={textProperties.colour}
          translate={false}
        />
      </div>
    </div>
  )
}

export { Props }
export default ProductBenefit
