// @no-flow
import { checkoutPageState } from '../../../CheckoutPage'
import { handleSubmitSubscription } from './buySubscriptionWithCard'
import { subscriptionData } from './submitSubscription'

const buySubscriptionWithPayPal = ({
  token,
  csrfToken
}: {
  token: string
  csrfToken: string
}): void => {
  const checkoutData = checkoutPageState()
  const data = subscriptionData({ state: checkoutData })

  handleSubmitSubscription({
    data,
    csrfToken,
    paymentMethodId: token,
    paymentMethodType: 'payPal'
  })
}

export default buySubscriptionWithPayPal
