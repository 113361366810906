// @flow

import * as React from 'react'
import { format } from 'date-fns'
import type { ComponentProps } from './../../containers/user_details/ApplicableDiscountsContainer'
import type { ApplicableDiscountPartDetails, ApplicableDiscountPartsDetails } from './../../message_types'

// eslint-disable-next-line react/require-optimization
class applicableDiscountsComponent extends React.Component<ComponentProps> {
  // eslint-disable-next-line react/no-arrow-function-lifecycle
  componentDidMount = (): void => {
    const { subscriptionId, dispatchGetApplicableDiscountsDetails } = this.props

    dispatchGetApplicableDiscountsDetails(subscriptionId)
  }

  generateRow = (applicableDiscounts: ApplicableDiscountPartDetails, i: number): React.Node => {
    return (
      <tr key={i}>
        <td>
          { format(new Date(applicableDiscounts.created_at), 'EEEE, MMM do yyy - HH:mm') }
        </td>
        <td>
          { applicableDiscounts.description }
        </td>
        <td>
          { applicableDiscounts.value }
        </td>
        <td>
          { applicableDiscounts.basis }
        </td>
        <td>
          { applicableDiscounts.type }
        </td>
        <td>
          { applicableDiscounts.code }
        </td>
        <td>
          <a
            href={`${applicableDiscounts.link}`}
            rel="noopener noreferrer"
            target='_blank'
          >
            LINK
          </a>
        </td>
      </tr>
    )
  }

  renderTransition = (): string => this.props.isFetching ? 'loading' : 'loaded'

  renderLoadingIcon = (): ?React.Element<'div'> => {
    if (this.props.isFetching) {
      return (
        <div className="loading-ring"><span></span><span></span><span></span><span></span></div>
      )
    }
  }

  renderDiscounts = (discounts: ApplicableDiscountPartsDetails): ?React.Node => {
    if (!this.props.isFetching) {
      if (discounts.length < 1) {
        return (<p className="no-results"> No discounts </p>)
      }
      return (
        <table>
          <thead>
            <tr>
              <th className='card__row__title'>CREATED_AT</th>
              <th className='card__row__title'>DESCRIPTION</th>
              <th className='card__row__title'>VALUE</th>
              <th className='card__row__title'>BASIS</th>
              <th className='card__row__title'>TYPE</th>
              <th className='card__row__title'>CODE</th>
              <th className='card__row__title'>LINK</th>
            </tr>
          </thead>
          <tbody>
            { discounts.map((applicableDiscounts: ApplicableDiscountPartDetails, i: number): React.Node => this.generateRow(applicableDiscounts, i)) }
          </tbody>
        </table>
      )
    }
  }

  render (): React.Node {
    return (
      <div className='applicable-discounts card__container'>
        <div className='card__header__container'>
          <h3 className='card__header'>
            { 'Applicable Discount Parts' }
          </h3>
        </div>
        <div className={`card__body card__${this.renderTransition()}`}>
          { this.renderLoadingIcon() }
          { this.renderDiscounts(this.props.applicableDiscountsDetails) }
        </div>
      </div>
    )
  }
}

export default applicableDiscountsComponent
