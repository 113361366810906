import { Container, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import * as Sentry from '@/utils/sentry'

import { Fade } from '@/components/elements/atoms/Animated/Animated'
import Icon from '@/components/elements/atoms/Icon/Icon'
import Image from '@/components/elements/atoms/Image'
import Text from '@/components/elements/atoms/Text/Text'
import LoadingScreen from '@/components/elements/organisms/LoadingScreen/LoadingScreen'

import STYLES from './LoadingScreenContent.module.sass'

type Props = {
  namespace: string
  foodCategoryId: string
  loading: boolean
}

const LoadingScreenContent = ({
  namespace,
  foodCategoryId,
  loading
}: Props): React.ReactElement => {
  const { t } = useTranslation(namespace)
  const copyContext = 'loading_screen'

  const [showLoadingScreen, setShowLoadingScreen] = useState(true)
  const [highlightOurRecipes, setHighlightOurRecipes] = useState(false)

  const foodCategoryIdToContent = () => {
    switch (foodCategoryId) {
      case '1': {
        return {
          key: 'kibble',
          img: 'kibble-bowl'
        }
      }
      case '2': {
        return {
          key: 'wet',
          img: 'wet-bowl'
        }
      }
      case '3': {
        return {
          key: 'raw',
          img: 'raw-bowl'
        }
      }
      case '4': {
        return {
          key: 'home',
          img: 'duck-chicken-game-veggie-stacked'
        }
      }
      default: {
        Sentry.captureException(
          `foodCategoryIdToKey is not implemented for foodCategoryId`,
          {
            extra: {
              foodCategoryId
            },
            tags: {
              product: Sentry.Product.PlansFlow
            }
          }
        )

        return {
          key: 'kibble',
          img: 'kibble-bowl'
        }
      }
    }
  }

  const { key, img } = foodCategoryIdToContent()

  useEffect(() => {
    if (!loading) {
      setTimeout((): void => {
        setShowLoadingScreen(false)
      }, 8000)
    }
  }, [loading])

  useEffect(() => {
    setTimeout((): void => {
      setHighlightOurRecipes(true)
    }, 4000)
  }, [])

  return (
    <LoadingScreen isOpen={showLoadingScreen} padding={false}>
      <Container maxWidth="md" disableGutters>
        <div
          className={`${STYLES.title} ${
            highlightOurRecipes ? STYLES.hide : ''
          }`}
        >
          <Text
            namespace={namespace}
            text={`${copyContext}.title`}
            variant="display20"
            colour="brandBlue500"
            margin={false}
            align="center"
          />
          <div className={STYLES.dots} />
        </div>
        <Grid container>
          <Grid item container xs={6}>
            <div className={STYLES.comparisonContent}>
              <Fade show={!highlightOurRecipes} opacity={0.4}>
                <Image
                  alt={t(`${copyContext}.img_alt.${key}`)}
                  className={`${STYLES.comparisonImage} ${
                    key === 'home' ? STYLES.homeCooked : ''
                  }`}
                  slug={img}
                  image={{
                    width: key === 'home' ? 200 : 230,
                    height: key === 'home' ? 262 : 230
                  }}
                />
              </Fade>
              <div
                className={`${STYLES.copyWrapper} ${
                  key === 'home' ? STYLES.homeCooked : ''
                }  ${highlightOurRecipes ? STYLES.hide : ''}`}
              >
                <div className={STYLES.arrowWrapper}>
                  <Icon
                    size={30}
                    asset="curvedDashedArrow"
                    accentColour="brandBlue500"
                    direction="topLeft"
                    width={50}
                  />
                </div>
                <Text
                  namespace={namespace}
                  text={`${copyContext}.comparison_title.${key}`}
                  variant="display16"
                  colour="brandBlue500"
                  margin={false}
                />
                <Text
                  namespace={namespace}
                  text={`${copyContext}.comparison_description.${key}`}
                  variant="textRegular14"
                  colour="brandBlue500"
                  margin={false}
                />
              </div>
            </div>
          </Grid>
          <Grid item container xs={6}>
            <div className={STYLES.ourRecipesContent}>
              <div className={STYLES.foodBowl}>
                <Image
                  alt={t(`${copyContext}.img_alt.our_recipes`)}
                  slug="game-bowl"
                  image={{
                    width: 260,
                    height: 260
                  }}
                />
              </div>
              <div
                className={`${STYLES.copyWrapper} ${
                  !highlightOurRecipes ? STYLES.hide : ''
                }`}
              >
                <div className={STYLES.arrowWrapper}>
                  <Icon
                    size={30}
                    asset="curvedDashedArrow"
                    accentColour="brandBlue500"
                    direction="right"
                  />
                </div>
                <Text
                  namespace={namespace}
                  text={`${copyContext}.our_recipes_title`}
                  variant="display16"
                  colour="brandBlue500"
                  margin={false}
                />
                <Text
                  namespace={namespace}
                  text={`${copyContext}.our_recipes_description.${key}`}
                  variant="textRegular14"
                  colour="brandBlue500"
                  margin={false}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </LoadingScreen>
  )
}

export default LoadingScreenContent
