import { CustomerIssueEntryFieldData } from '@types'
import React, { useCallback } from 'react'

type DropDownFieldProps = {
  id: string
  value: string
  placeholder: string
  options: Array<DropDownOptionProps> | null
  setValue: (
    key: string,
    value: number,
    dataType: keyof typeof CustomerIssueEntryFieldData
  ) => void
}

type DropDownOptionProps = {
  id: string
  name: string | null
}

const DropDownField = ({
  id,
  value,
  placeholder,
  options,
  setValue
}: DropDownFieldProps): JSX.Element => {
  const renderOptions = useCallback(() => {
    if (options) {
      return options.map((option): JSX.Element => {
        return (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        )
      })
    }
  }, [options])

  const onChange = useCallback(
    (e) => {
      setValue(id, e.target.value, 'association')
    },
    [setValue, id]
  )

  return (
    <select value={value} onChange={onChange} required>
      <option value="">{placeholder}</option>
      {renderOptions()}
    </select>
  )
}

export default DropDownField
