import React from 'react'

import { SelfResolutionCategories_categories as CustomerIssueCategory } from '../../../queries/__generated__/SelfResolutionCategories'

import CategoryButton from './CategoryButton'

type CategoryButtonsProps = {
  categories: Array<CustomerIssueCategory>
  selectCategory: (id: string) => void
}

const CategoryButtons = ({
  categories,
  selectCategory
}: CategoryButtonsProps): JSX.Element => {
  return (
    <>
      {categories.map((cat) => {
        return (
          <CategoryButton
            key={cat.id}
            category={cat}
            selectCategory={selectCategory}
          />
        )
      })}
    </>
  )
}

export default CategoryButtons
