import React from 'react'

import Icon from '@/components/elements/atoms/Icon/Icon'
import Text from '@/components/elements/atoms/Text'

import STYLES from './DeliveryDetails.module.sass'

type Props = {
  cadenceInWeeks: number
  numberOfPouches: number
  copyContext: string
  namespace: string
  variant?: 'twoLines' | 'threeLines'
}

const DeliveryDetails = ({
  cadenceInWeeks,
  numberOfPouches,
  copyContext,
  namespace,
  variant = 'twoLines'
}: Props): React.ReactElement => {
  return variant === 'threeLines' ? (
    <div className={`${STYLES.delivery} ${STYLES[variant]}`}>
      <Text
        text={`${copyContext}.delivery_every`}
        namespace={namespace}
        variant="display18"
        margin={false}
        element="h3"
      />
      <div className={STYLES.deliveryContent}>
        <Icon asset="vanFilled" size={15} width={24} />
        <div className={STYLES.deliveryText}>
          <Text
            text={`${copyContext}.weeks`}
            namespace={namespace}
            variables={{ cadenceInWeeks }}
            margin={false}
            variant="textRegular20"
          />
          <Text
            text={`${copyContext}.pouches`}
            namespace={namespace}
            variables={{ count: numberOfPouches }}
            margin={false}
            colour="brandBlue400"
          />
        </div>
      </div>
    </div>
  ) : (
    <div className={`${STYLES.delivery} ${STYLES[variant]}`}>
      <Icon asset="vanFilled" size={15} width={24} />
      <Text
        text={`${copyContext}.delivery_every`}
        namespace={namespace}
        variant="display18"
        margin={false}
      />
      <div className={STYLES.deliveryText}>
        <Text
          text={`${copyContext}.weeks`}
          namespace={namespace}
          variables={{ cadenceInWeeks }}
          margin={false}
          variant="textRegular20"
        />
        <Text
          text={`${copyContext}.pouches_brackets`}
          namespace={namespace}
          variables={{ count: numberOfPouches }}
          margin={false}
          colour="brandBlue400"
        />
      </div>
    </div>
  )
}

export default DeliveryDetails
