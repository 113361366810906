import React, { useMemo } from 'react'

import {
  CustomerIssueReportCreate_response_inputFields_CustomerIssueManagementInputFieldAssociation as AssociationInputField,
  CustomerIssueReportCreate_response_inputFields_CustomerIssueManagementInputFieldMultifield_fields_CustomerIssueManagementInputFieldAssociation as MultifieldAssociationField
} from '../../../../mutations/__generated__/CustomerIssueReportCreate'
import { CustomerIssueManagementInputInput } from '@/types'

import Dropdown from '../display_types/Dropdown'

type AssociationProps = {
  inputField: AssociationInputField | MultifieldAssociationField
  inputValue: CustomerIssueManagementInputInput
  updateInput: (input: CustomerIssueManagementInputInput) => void
}

const Association = ({
  inputField,
  inputValue,
  updateInput
}: AssociationProps): JSX.Element => {
  const setValue = React.useCallback(
    (associationValue: string): void => {
      const newInput = {
        fieldId: inputValue.fieldId,
        associationValue
      }
      updateInput(newInput)
    },
    [inputValue, updateInput]
  )

  const options = useMemo(() => {
    return (
      inputField.allowedAssociationValues?.map((option) => {
        return {
          text: option.id,
          value: option.id,
          key: option.id
        }
      }) || []
    )
  }, [inputField])

  switch (inputField.associationDisplayType) {
    case 'dropdown':
      return (
        <Dropdown options={options} input={inputValue} setValue={setValue} />
      )
      break
    default: {
      throw new Error('Unsupported association display type')
    }
  }
}

export default Association
