import React, { useCallback } from 'react'

import PasswordField from '@/components/elements/molecules/PasswordField/PasswordField'

type Props = {
  password: string
  setPassword: (password: string) => void
  setConfirmPassword?: (password: string) => void
  setPasswordsValid?: (value: boolean) => void
  onPasswordChanged?: (value: string) => void
  onConfirmPasswordChanged?: (value: string) => void
}

const PasswordFields = ({
  password,
  setPassword,
  setConfirmPassword,
  setPasswordsValid,
  onPasswordChanged,
  onConfirmPasswordChanged
}: Props): JSX.Element => {
  const handlePasswordChanged = useCallback(
    (v) => {
      setPassword(v)
      onPasswordChanged && onPasswordChanged(v)
    },
    [onPasswordChanged, setPassword]
  )

  const handleConfirmPasswordChanged = useCallback(
    (value: string) => {
      setConfirmPassword && setConfirmPassword(value)
      onConfirmPasswordChanged && onConfirmPasswordChanged(value)
    },
    [onConfirmPasswordChanged, setConfirmPassword]
  )

  const onValidatePassword = useCallback(
    (_, valid) => {
      setPasswordsValid && setPasswordsValid(valid)
      return valid
    },
    [setPasswordsValid]
  )

  return (
    <>
      <PasswordField
        variant={'password'}
        onValidate={onValidatePassword}
        onChange={handlePasswordChanged}
      />
      <PasswordField
        variant={'confirmPassword'}
        password={password}
        onValidate={onValidatePassword}
        onChange={handleConfirmPasswordChanged}
      />
    </>
  )
}

export default PasswordFields
