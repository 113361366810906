// @noflow
import { makeVar, useQuery, useReactiveVar } from '@apollo/client'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import * as Sentry from '@/utils/sentry'
import Validator from '@/utils/validator'

import segmentTrack from '@/components/analytics/Analytics'
import Text from '@/components/elements/atoms/Text/Text'
import StickyNavigation from '@/components/elements/organisms/StickyNavigation/StickyNavigation'
import MarketingConsent from '@/components/pages/SignupWizardPage/components/MarketingConsent'
import { routeToPrevStep } from '@/components/pages/SignupWizardPage/helpers/wizardRoutes'
import type { Route } from '@/components/pages/SignupWizardPage/types/routes'

import STYLES from './PetParent.module.sass'

import { CURRENT_USER } from '../../queries/userQuery'

import { CurrentUser as UserQuery } from '../../queries/__generated__/CurrentUser'
import { MarketingPreferences } from '@/types'

import { csrfTokenState, wizardPageState } from '../../SignupWizardPage'
import { submitFormData } from '../../helpers/submitFormData'
import useImplicitFormSubmission from '../../helpers/useImplicitFormSubmission'

type Props = {
  variant?: keyof typeof STYLES
  namespace: string
}

const nameInvalidState = makeVar(false)
const emailInvalidState = makeVar(false)

const PetParent = ({ variant, namespace }: Props): JSX.Element => {
  const route = useLocation().pathname as Route
  const wizardState = useReactiveVar(wizardPageState)
  const csrfToken = useReactiveVar(csrfTokenState)

  const [fieldInteracted, setFieldInteracted] = useState([false, false])
  const [nameInvalid, setNameInvalid] = useState<boolean>(true)
  const [emailInvalid, setEmailInvalid] = useState<boolean>(true)
  const [submitting, setSubmitting] = useState(false)

  const { user } = wizardState
  const { countryCode } = user.location

  const copyContext = 'pet_parent_step'
  const { t } = useTranslation('wizard_flow')

  const { data: userData } = useQuery<UserQuery>(CURRENT_USER, {
    onError: (error) => {
      Sentry.captureException(`Error when CURRENT_USER executes`, {
        extra: {
          error: error.message
        },
        tags: {
          product: Sentry.Product.Wizard
        }
      })
    }
  })

  const validateName = useCallback(
    (value): void => {
      setNameInvalid(value === '')
    },
    [setNameInvalid]
  )

  const validateEmail = useCallback(
    (value): void => {
      setEmailInvalid(!Validator.isValidEmail(value))
    },
    [setEmailInvalid]
  )

  const handleChange = useCallback(
    (e): void => {
      if (e.currentTarget.name === 'name') {
        if (fieldInteracted[0]) {
          validateName(e.currentTarget.value)
        }
        user.name = e.currentTarget.value
      } else if (e.currentTarget.name === 'email') {
        if (fieldInteracted[1]) {
          validateEmail(e.currentTarget.value.trim())
        }
        user.email = e.currentTarget.value.trim()
      }
      wizardPageState({
        ...wizardState,
        user
      })
    },
    [wizardState, user, fieldInteracted, validateEmail, validateName]
  )

  const handleBlur = useCallback(
    (e): void => {
      const newArray = [...fieldInteracted]
      if (e.currentTarget.name === 'name') {
        newArray[0] = true
        validateName(e.currentTarget.value)
      } else if (e.currentTarget.name === 'email') {
        const email = e.currentTarget.value

        newArray[1] = true
        validateEmail(email)

        // Report to Segment any users which blur away with an invalid email
        if (!Validator.isValidEmail(email) && email.length > 0) {
          segmentTrack('User blurred with invalid email', {
            // Will anonymise provided emails with letters being replaced with X
            // and digits replaced with D.
            // E.g: example123@butternutbox.com -> XXXXXXXDDD@XXXXXXXXXXXX.XXX
            email: email.replace(/[a-zA-Z]/g, 'X').replace(/\d/g, 'D')
          })
        }
      }
      setFieldInteracted(newArray)
    },
    [fieldInteracted, validateEmail, validateName]
  )

  const handleMarketing = useCallback(
    (e): void => {
      const { id, checked } = e.currentTarget

      switch (id) {
        case 'marketingPreferences': {
          if (checked) {
            user.marketingPreference = MarketingPreferences.full_consent
            user.supportedMarketingMethodPurposeIds = userData
              ? userData.availableMarketingMethodPurposes.map(({ id }) => id)
              : []
          } else {
            user.marketingPreference = MarketingPreferences.no_consent
            user.supportedMarketingMethodPurposeIds = []
          }
          break
        }
        case 'dealsOnlyPreference': {
          if (checked) {
            user.marketingPreference = MarketingPreferences.deals_only
            user.supportedMarketingMethodPurposeIds = userData
              ? userData.availableMarketingMethodPurposes
                  .filter((purpose) => purpose.marketingPurpose.encouraged)
                  .map((purpose) => purpose.id)
              : []
          } else {
            user.marketingPreference = MarketingPreferences.no_consent
            user.supportedMarketingMethodPurposeIds = []
          }
          break
        }
        default: {
          Sentry.captureException(
            `handleMarketing is not implemented for ${id}`,
            {
              tags: {
                product: Sentry.Product.Wizard
              }
            }
          )
          user.marketingPreference = MarketingPreferences.no_consent
          user.supportedMarketingMethodPurposeIds = []
        }
      }

      wizardPageState({
        ...wizardState,
        user
      })
    },
    [wizardState, user, userData]
  )

  const navigateToPlans = useCallback(async () => {
    localStorage.setItem('skipLoadingScreen', JSON.stringify(false))
    setSubmitting(true)
    await submitFormData(csrfToken)
    setSubmitting(false)
  }, [csrfToken])

  const onEnterPress = useCallback(() => {
    navigateToPlans()
  }, [navigateToPlans])

  const formIsValid = !nameInvalid && !emailInvalid && !submitting

  useImplicitFormSubmission({ formIsValid, onEnterPress })

  // initial validation
  useEffect(() => {
    validateName(user.name)
    validateEmail(user.email)
  })

  return (
    <>
      <div className={variant ? STYLES[variant] : ''}>
        <div className={STYLES.question}>
          <div className={STYLES.subQuestion}>
            {user && (
              <>
                <div className={STYLES.labelWrapper}>
                  <label htmlFor={'petParentFullName'}>
                    <Text
                      variant="textRegular16"
                      colour="brandBlue500"
                      namespace={namespace}
                      text={`${copyContext}.name.label`}
                    />
                    <input
                      id={'petParentFullName'}
                      data-testid={'pet-parent-name-input'}
                      type="text"
                      name={'name'}
                      placeholder={t(`${copyContext}.name.placeholder`)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={(user && user.name) || ''}
                      aria-invalid={fieldInteracted[0] && nameInvalid}
                    />
                    {fieldInteracted[0] && nameInvalid && (
                      <div className={STYLES.error}>
                        <Text
                          text={`${copyContext}.name.error`}
                          namespace={namespace}
                        />
                      </div>
                    )}
                  </label>
                </div>
                <div className={STYLES.labelWrapper}>
                  <label htmlFor={'petParentEmail'}>
                    <Text
                      variant="textRegular16"
                      colour="brandBlue500"
                      namespace={namespace}
                      text={`${copyContext}.email.label`}
                    />
                    <input
                      id={'petParentEmail'}
                      data-testid={'pet-parent-email-input'}
                      type="email"
                      name={'email'}
                      placeholder={t(`${copyContext}.email.placeholder`)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={(user && user.email) || ''}
                      aria-invalid={fieldInteracted[1] && emailInvalid}
                    />
                    {fieldInteracted[1] && emailInvalid && (
                      <div className={STYLES.error}>
                        <Text
                          text={`${copyContext}.email.error`}
                          namespace={namespace}
                        />
                      </div>
                    )}
                  </label>
                </div>
                <MarketingConsent
                  namespace={namespace}
                  countryCode={countryCode}
                  marketingPreference={user.marketingPreference}
                  handleMarketing={handleMarketing}
                  availableMarketingMethodPurposes={
                    userData?.availableMarketingMethodPurposes ?? []
                  }
                />
              </>
            )}
          </div>
        </div>
      </div>
      <StickyNavigation
        variant="twoButtons"
        disabled={!formIsValid}
        buttonOne={{
          url: routeToPrevStep({ route }),
          text: 'wizard_flow:sticky_navigation.back',
          variant: 'secondary',
          iconColour: 'brandRed400'
        }}
        buttonTwo={{
          dataTestId: 'next-button',
          text: 'wizard_flow:sticky_navigation.continue',
          variant: 'primary',
          iconColour: 'brandWhite',
          onClick: navigateToPlans
        }}
      />
    </>
  )
}

export { Props, nameInvalidState, emailInvalidState }
export default PetParent
