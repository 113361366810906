// @noflow
import type { Language } from '@/packs/localisation'
import { makeVar, useReactiveVar } from '@apollo/client'
import Cookies from 'js-cookie'
import React from 'react'

import NavLogo from '@/components/elements/atoms/NavLogo/NavLogo'
import TopNavDiscount from '@/components/elements/molecules/TopNavDiscount/TopNavDiscount'

import STYLES from './TopNavBanner.module.sass'

import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'

type Props = {
  shippingCountryCode: CountryCode
  preferredLanguage: Language
  variant?: keyof typeof STYLES
}

const TopNavBannerDiscountCodeId = makeVar(Cookies.get('discount_code_id'))

const TopNavBanner = ({
  shippingCountryCode,
  preferredLanguage,
  variant
}: Props): JSX.Element => {
  const discountCodeId = useReactiveVar(TopNavBannerDiscountCodeId)

  return (
    <nav className={`${STYLES.topNavBanner} ${variant ? STYLES[variant] : ''}`}>
      <a href={'/'}>
        <NavLogo size={'large'} />
      </a>
      {discountCodeId && (
        <TopNavDiscount
          discountCodeId={discountCodeId}
          shippingCountryCode={shippingCountryCode}
          preferredLanguage={preferredLanguage}
        />
      )}
    </nav>
  )
}

export { Props, TopNavBannerDiscountCodeId }

export default TopNavBanner
