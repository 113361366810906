import { useReactiveVar } from '@apollo/client'
import React, { useEffect, useMemo, useState } from 'react'

import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { formatCurrencyWithDecimal } from '@/utils/currency'

import ActionButtons from '../../../components/ActionButtons/ActionButtons'
import Text from '@/components/elements/atoms/Text/Text'

import { CustomerIssueEntryInputsCreate_response_resolutionOffering_details_CustomerIssueManagementResolutionDetailsApplyDiscount as ApplyDiscountDetails } from '../../../mutations/__generated__/CustomerIssueEntryInputsCreate'
import { CustomerIssueManagementInitialDataQuery_user_reportable_potentialMissingItems as PotentialMissingItem } from '../../../queries/__generated__/CustomerIssueManagementInitialDataQuery'
import { CustomerIssueManagementInputInput } from '@/types'

import {
  customerIssueManagementState,
  pageTitleState
} from '../../../CustomerIssueManagementPage'
import useCustomerIssueManagementTracking from '../../../analytics'

type Props = {
  details: ApplyDiscountDetails
  onAccept: () => void
  contactUs: () => void
}

type AffectedItemsProps = {
  affectedItems: Array<PotentialMissingItem>
}

const AffectedItems = ({ affectedItems }: AffectedItemsProps): JSX.Element => {
  const customerIssueManagementData = useReactiveVar(
    customerIssueManagementState
  )
  const [initialised, setInitialised] = useState(false)

  useEffect(() => {
    if (!initialised) {
      pageTitleState('resolutionOffering.header.discount')
      setInitialised(true)
    }
  }, [initialised])

  return (
    <>
      {affectedItems.map((affectedItem) => {
        return (
          <Text
            key={affectedItem.productVariant.id}
            namespace={customerIssueManagementData.namespace}
            text={'resolutionOffering.discount.productAmount'}
            variables={{
              amount: affectedItem.quantity,
              product: affectedItem.name
            }}
            variant={'display16'}
            colour={'brandBlue500'}
          />
        )
      })}
    </>
  )
}

const ApplyDiscount = ({
  details,
  onAccept,
  contactUs
}: Props): JSX.Element => {
  const customerIssueManagementData = useReactiveVar(
    customerIssueManagementState
  )
  const customerIssueManagementTracking = useCustomerIssueManagementTracking()
  const [initialised, setInitialised] = React.useState(false)
  const { namespace } = customerIssueManagementData
  const potentialMissingItems = customerIssueManagementData.reportable
    .reportableFields?.potentialMissingItems as Array<PotentialMissingItem>

  const affectedItems = useMemo(() => {
    return customerIssueManagementData.inputValues.map((multifieldInput) => {
      const inputs =
        multifieldInput?.inputs as Array<CustomerIssueManagementInputInput>
      const productId = inputs[0].associationValue || inputs[1].associationValue
      const quantity =
        inputs[0].integerValue || (inputs[1].integerValue as number)
      const product = {
        ...(potentialMissingItems.find(
          (product) => product.productVariant.id === productId
        ) as PotentialMissingItem)
      }
      product.quantity = quantity
      return product
    })
  }, [customerIssueManagementData.inputValues, potentialMissingItems])

  const discountValue = useMemo(() => {
    const discountAmount = details.discountCode.value
    const { locale, currency } = countryCodeToLocaleCurrency(
      customerIssueManagementData.shippingCountryCode,
      customerIssueManagementData.preferredLanguage
    )
    return formatCurrencyWithDecimal(discountAmount, { locale, currency })
  }, [customerIssueManagementData, details.discountCode.value])

  useEffect(() => {
    if (!initialised) {
      customerIssueManagementTracking.stepLoaded(
        'Discount offering',
        'Resolution offering'
      )
      setInitialised(true)
    }
  }, [
    initialised,
    customerIssueManagementData,
    customerIssueManagementTracking
  ])

  return (
    <>
      <Text
        namespace={namespace}
        text={'resolutionOffering.discount.discountOffered'}
        variables={{ value: discountValue }}
        variant={'textRegular16'}
        colour={'brandBlue500'}
        margin={false}
      />
      <AffectedItems affectedItems={affectedItems} />
      <Text
        namespace={namespace}
        text={'resolutionOffering.discount.disclaimer'}
        variables={{}}
        variant={'textRegular16'}
        colour={'brandBlue500'}
        margin={false}
      />
      <ActionButtons
        acceptButton={{
          action: onAccept,
          text: 'resolutionOffering.discount.accept'
        }}
        rejectButton={{
          action: contactUs,
          text: 'resolutionOffering.contactCustomerLove'
        }}
      />
    </>
  )
}

export { Props }
export default ApplyDiscount
