// @flow

import * as React from 'react'
import i18next from 'i18next'

import DogEatingFood from '../../../../../../assets/images/illustrations/dogs/green-dog-eating.svg'

type Props = {|
  myBoxesUrl: string
|}

class CreditRedemptionComplete extends React.Component<Props> {
  namespace = 'ambassadors:dashboard.balance_tab'

  render (): React.Node {
    return (
      <div className='ambassador-balance__card'>
        <div className='ambassador-balance__card__header'>
          <h2>{ i18next.t(`${this.namespace}.credits_applied`) }</h2>
          <img
            src={DogEatingFood}
            alt='An illustration of a dog eating a bowl of food'
          />
          <p>{ i18next.t(`${this.namespace}.check_out_deliveries`) }</p>

          <a
            className='btn btn-updated--blue'
            href={this.props.myBoxesUrl}
          >
            { i18next.t(`${this.namespace}.see_upcoming_deliveries`) }
          </a>
        </div>
      </div>
    )
  }
}

export default CreditRedemptionComplete
