import { ACCOUNT_ROUTES } from '@/routes'
import {
  ACCOUNT_EXTRAS,
  ACCOUNT_SETTINGS_ROUTES,
  AccountRoute
} from '@/routes/routes'

type RouteAccessRule = {
  group: string
  blocked: string[]
  redirect: string
}

enum Groups {
  noDogs = 'noDogs',
  noPaymentMethod = 'noPaymentMethod',
  noExtras = 'noExtras',
  suspended = 'suspended',
  paused = 'paused',
  active = 'active',
  notAmbassador = 'notAmbassador',
  notInfluencer = 'notInfluencer',
  GB = 'GB',
  PL = 'PL',
  BE = 'BE',
  IE = 'IE',
  NI = 'NI',
  NL = 'NL',
  DE = 'DE',
  notEligibleForSwapAndSave = 'notEligibleForSwapAndSave',
  notEligibleForDownsizeAndSave = 'notEligibleForDownsizeAndSave',
  orderPagesHidden = 'orderPagesHidden',
  influencerTrialAccount = 'influencerTrialAccount',
  ambassadorTrialAccount = 'ambassadorTrialAccount',
  notEligibleForMFITBPlan = 'notEligibleForMFITBPlan'
}

/**
 * Spread route object into array of paths
 * @param routes
 */
const spreadRoutes = (routes: Record<string, string>): string[] =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Object.entries(routes).map(([_key, value]) => value)

/**
 * Produce an array consisting of all routes except those in the allowed array.
 * @param allowed - the routes that the user should be allowed to visit
 * @param all - all possible routes
 */
const blockAllExcept = (allowed: string[], all: string[]): string[] =>
  all.filter((route) => !allowed.includes(route))

/**
 * The rules which govern which routes a user can access
 */
const ACCESS_RULES: readonly RouteAccessRule[] = Object.freeze([
  {
    group: Groups.active,
    blocked: [ACCOUNT_ROUTES.paused],
    redirect: ACCOUNT_ROUTES.base
  },
  /**
   * For paused users we allow access to most pages
   * however they see a different account landing page
   */
  {
    group: Groups.paused,
    blocked: [ACCOUNT_ROUTES.base],
    redirect: ACCOUNT_ROUTES.paused
  },
  /**
   * For suspended users we allow access to most pages
   */
  {
    group: Groups.suspended,
    blocked: [ACCOUNT_ROUTES.paused],
    redirect: ACCOUNT_ROUTES.base
  },
  /**
   * For users without a payment method attached we block most pages
   * except those required to add a payment method
   */
  {
    group: Groups.noPaymentMethod,
    blocked: blockAllExcept(
      [
        ACCOUNT_ROUTES.base,
        ACCOUNT_ROUTES.noDogs,
        ACCOUNT_ROUTES.paused,
        ACCOUNT_ROUTES.paymentMethods,
        ACCOUNT_ROUTES.addPaymentMethod,
        ACCOUNT_ROUTES.swapAndSave,
        ACCOUNT_ROUTES.downsizeAndSave,
        ACCOUNT_ROUTES.editRecipes
      ],
      spreadRoutes(ACCOUNT_ROUTES)
    ),
    redirect: ACCOUNT_ROUTES.base
  },
  /**
   * For influencers with a free box, we enable them to access most pages
   */
  {
    group: Groups.influencerTrialAccount,
    blocked: [ACCOUNT_ROUTES.paused, ACCOUNT_ROUTES.referAFriend],
    redirect: ACCOUNT_ROUTES.base
  },
  /**
   * For Ambassadors with a trial account, we enable them to access
   * most pages except RAF and extras
   */
  {
    group: Groups.ambassadorTrialAccount,
    blocked: [
      ACCOUNT_ROUTES.paused,
      ACCOUNT_ROUTES.referAFriend,
      ...spreadRoutes(ACCOUNT_EXTRAS)
    ],
    redirect: ACCOUNT_ROUTES.base
  },
  /**
   * For users without dogs we block most pages
   * except those required to add a dog
   */
  {
    group: Groups.noDogs,
    blocked: blockAllExcept(
      [
        ACCOUNT_ROUTES.profile,
        ...spreadRoutes(ACCOUNT_SETTINGS_ROUTES as AccountRoute)
      ],
      spreadRoutes(ACCOUNT_ROUTES)
    ),
    redirect: ACCOUNT_ROUTES.profile
  },
  /**
   * For users who can't buy extras block extras related pages
   */
  {
    group: Groups.noExtras,
    blocked: spreadRoutes(ACCOUNT_EXTRAS),
    redirect: ACCOUNT_ROUTES.base
  },
  /**
   * For users who are not ambassadors block ambassador related pages
   */
  {
    group: Groups.notAmbassador,
    blocked: [ACCOUNT_ROUTES.ambassador],
    redirect: ACCOUNT_ROUTES.base
  },
  /**
   * For users who are not influencers block influencer related pages
   */
  {
    group: Groups.notInfluencer,
    blocked: [ACCOUNT_ROUTES.influencer],
    redirect: ACCOUNT_ROUTES.base
  },
  /**
   * For users who are not eligible for SwapAndSave block SwapAndSave
   */
  {
    group: Groups.notEligibleForSwapAndSave,
    blocked: [ACCOUNT_ROUTES.swapAndSave],
    redirect: ACCOUNT_ROUTES.base
  },
  /**
   * For users who are not eligible for DownsizeAndSave block DownsizeAndSave
   */
  {
    group: Groups.notEligibleForDownsizeAndSave,
    blocked: [ACCOUNT_ROUTES.downsizeAndSave],
    redirect: ACCOUNT_ROUTES.base
  },
  /**
   * When new dashboard is enabled we block the order pages
   */
  {
    group: Groups.orderPagesHidden,
    blocked: [
      ACCOUNT_ROUTES.orders,
      ACCOUNT_ROUTES.box,
      ACCOUNT_ROUTES.onDemandExtras
    ],
    redirect: ACCOUNT_ROUTES.base
  },
  {
    group: Groups.notEligibleForMFITBPlan,
    blocked: [ACCOUNT_ROUTES.mfitbPlanOffer],
    redirect: ACCOUNT_ROUTES.base
  }
])

export { spreadRoutes, blockAllExcept, Groups, RouteAccessRule }
export default ACCESS_RULES
