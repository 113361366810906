import { useMutation } from '@apollo/client'
import React, { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/elements/atoms/Button/Button'
import Image from '@/components/elements/atoms/Image/Image'
import Text from '@/components/elements/atoms/Text/Text'
import AppDownloadButtons from '@/components/elements/molecules/AppDownloadButtons/AppDownloadButtons'

import STYLES from './AppSMSComponent.module.sass'

import { SEND_APP_SMS } from '../../../mutations/mutations'

import {
  SendAppSMS,
  SendAppSMSVariables
} from '../../../mutations/__generated__/SendAppSMS'

import { PaymentPageContext } from '../../../PayOnOwnDevicePage'

const AppSMSComponent = ({
  namespace
}: {
  namespace: string
}): JSX.Element | null => {
  const { paymentPageState } = useContext(PaymentPageContext)
  const { data } = paymentPageState || {}
  const { directSalesPendingSubscription } = data || {}
  const { pendingSubscription } = directSalesPendingSubscription || {}
  const copyContext = 'complete_screen.sms_screen'

  const { t } = useTranslation(namespace)

  const [sendSMS] = useMutation<SendAppSMS, SendAppSMSVariables>(SEND_APP_SMS, {
    variables: {
      userId: pendingSubscription?.user.id || ''
    }
  })

  const handleSendSMS = useCallback((): void => {
    sendSMS()
  }, [sendSMS])

  return (
    <div className={STYLES.smsContainer}>
      <Text
        namespace={namespace}
        text={`${copyContext}.title`}
        variant={'display36'}
        align={'center'}
        bold
      />
      <div className={STYLES.buttonContainer}>
        <AppDownloadButtons />
      </div>
      <Button
        typography={{
          variant: 'textMono18',
          text: `${copyContext}.app_sms_button`,
          namespace
        }}
        variant={'primary'}
        onClick={handleSendSMS}
        fullWidth
        size="large"
        disableAnalytics
      />
      <div className={STYLES.iphoneImageContainer}>
        <Image
          slug="iphone_back"
          alt={t(`${copyContext}.iphone_back`)}
          image={{ width: 97, height: 121, resizeMode: 'resize_to_fit' }}
          className={STYLES.iphoneBack}
        />
        <Image
          slug="iphone_front"
          alt={t(`${copyContext}.iphone_front`)}
          image={{ width: 111, height: 134, resizeMode: 'resize_to_fit' }}
        />
      </div>
    </div>
  )
}

export default AppSMSComponent
