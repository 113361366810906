import { gql } from '@apollo/client'

const MFITB_PLAN_OFFER_QUERY = gql`
  query MFITBPlanOfferQuery {
    user {
      id
      shippingCountryCode
      preferredLanguage
      subscription {
        id
        increasedDeliveryCadencePlan {
          id
          durationInWeeks
          pricePerWeek
        }
        plan {
          id
          durationInWeeks
          pricePerWeek
        }
      }
    }
  }
`

export { MFITB_PLAN_OFFER_QUERY }
