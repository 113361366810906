// @noflow
import React from 'react'
import { useTranslation } from 'react-i18next'

import * as DIGITAL_PRODUCT_CONSTANTS from '@/utils/butternutbox/constants/digital_product'

import AdultAvatar from 'assets/images/illustrations/dogs/dog-profile-avatars/single-adult-dog.svg'
import BorderTerrier from 'assets/images/illustrations/dogs/dog-profile-avatars/single-border-terrier.svg'
import Chihuahua from 'assets/images/illustrations/dogs/dog-profile-avatars/single-chihuahua.svg'
import CockerSpaniel from 'assets/images/illustrations/dogs/dog-profile-avatars/single-cocker-spaniel.svg'
import Dalmatian from 'assets/images/illustrations/dogs/dog-profile-avatars/single-dalmatian.svg'
import FrenchBulldog from 'assets/images/illustrations/dogs/dog-profile-avatars/single-french-bulldog.svg'
import GermanShephard from 'assets/images/illustrations/dogs/dog-profile-avatars/single-german-shepherd.svg'
import GreatDane from 'assets/images/illustrations/dogs/dog-profile-avatars/single-great-dane.svg'
import LabradorRetriever from 'assets/images/illustrations/dogs/dog-profile-avatars/single-labrador-retriever.svg'
import Pekingese from 'assets/images/illustrations/dogs/dog-profile-avatars/single-pekingnese.svg'
import Poodle from 'assets/images/illustrations/dogs/dog-profile-avatars/single-poodle.svg'
import PuppyAvatar from 'assets/images/illustrations/dogs/dog-profile-avatars/single-puppy-dog.svg'
import Schnauzer from 'assets/images/illustrations/dogs/dog-profile-avatars/single-schnauzer.svg'
import Staffie from 'assets/images/illustrations/dogs/dog-profile-avatars/single-staffie.svg'

import STYLES from './SelectableDogAvatar.module.sass'

import type { DogProfileQuery_user_dogs as Dog } from '../../queries/__generated__/DogProfileQuery'
import { DogProfileQuery_user_dogs_dogProfile as DogProfile } from '../../queries/__generated__/DogProfileQuery'

type Props = {
  dog: Pick<Dog, 'id' | 'name' | 'breed' | 'ageInMonths'> & {
    dogProfile: null | {
      avatarUrl: DogProfile['avatarUrl']
    }
  }
}

const BREED_IMAGES = {
  Chihuahua,
  Dalmatian,
  Pekingese,
  Poodle,
  Schnauzer,
  'Standard Schnauzer': Schnauzer,
  'Miniature Schnauzer': Schnauzer,
  'Staffordshire Bull Terrier': Staffie,
  'Cocker Spaniel': CockerSpaniel,
  'Border Terrier': BorderTerrier,
  'French Bulldog': FrenchBulldog,
  'Labrador Retriever': LabradorRetriever,
  'Golden Retriever': LabradorRetriever,
  'German Shephard': GermanShephard,
  'Great Dane': GreatDane
}

const DogAvatarImage = ({
  dog: {
    name,
    dogProfile,
    breed: { name: breedName },
    ageInMonths
  }
}: Props): JSX.Element => {
  const { t } = useTranslation('account')

  const breedImage = (() => {
    if (Object.keys(BREED_IMAGES).includes(breedName)) {
      const images: { [key: string]: string } = BREED_IMAGES

      return images[breedName]
    } else if (
      ageInMonths <= DIGITAL_PRODUCT_CONSTANTS.MAX_PUPPY_AGE_IN_MONTHS
    ) {
      return PuppyAvatar
    } else {
      return AdultAvatar
    }
  })()

  return (
    <div className={STYLES.dogAvatarWrapper}>
      <img
        alt={t('dog_profile.avatar.image_alt_text', { dogName: name })}
        className={STYLES.dogAvatarImage}
        src={dogProfile?.avatarUrl || breedImage}
      />
    </div>
  )
}

export { DogAvatarImage }
