import { gql } from '@apollo/client'

import { PAYMENT_METHODS_FRAGMENT } from '../../../fragments/fragments'

const ARCHIVE_PAYMENT_METHOD_MUTATION = gql`
  ${PAYMENT_METHODS_FRAGMENT}
  mutation ArchivePaymentMethod(
    $paymentMethodId: ID!
    $archivedType: ArchivedType!
  ) {
    response: archivePaymentMethod(paymentMethodId: $paymentMethodId) {
      user {
        subscription {
          ...paymentMethodsFragment
        }
      }
    }
  }
`

export { ARCHIVE_PAYMENT_METHOD_MUTATION }
