// @noflow
import BorderTerrier from 'assets/images/illustrations/dogs/dog-profile-avatars/single-border-terrier.svg'
import Chihuahua from 'assets/images/illustrations/dogs/dog-profile-avatars/single-chihuahua.svg'
import CockerSpaniel from 'assets/images/illustrations/dogs/dog-profile-avatars/single-cocker-spaniel.svg'
import Dalmatian from 'assets/images/illustrations/dogs/dog-profile-avatars/single-dalmatian.svg'
import FrenchBulldog from 'assets/images/illustrations/dogs/dog-profile-avatars/single-french-bulldog.svg'
import GermanShephard from 'assets/images/illustrations/dogs/dog-profile-avatars/single-german-shepherd.svg'
import GreatDane from 'assets/images/illustrations/dogs/dog-profile-avatars/single-great-dane.svg'
import LabradorRetriever from 'assets/images/illustrations/dogs/dog-profile-avatars/single-labrador-retriever.svg'
import Pekingese from 'assets/images/illustrations/dogs/dog-profile-avatars/single-pekingnese.svg'
import Poodle from 'assets/images/illustrations/dogs/dog-profile-avatars/single-poodle.svg'
import Schnauzer from 'assets/images/illustrations/dogs/dog-profile-avatars/single-schnauzer.svg'
import Staffie from 'assets/images/illustrations/dogs/dog-profile-avatars/single-staffie.svg'

const BREED_IMAGES = {
  Chihuahua,
  Dalmatian,
  Pekingese,
  Poodle,
  Schnauzer,
  'Standard Schnauzer': Schnauzer,
  'Miniature Schnauzer': Schnauzer,
  'Staffordshire Bull Terrier': Staffie,
  'Cocker Spaniel': CockerSpaniel,
  'Border Terrier': BorderTerrier,
  'French Bulldog': FrenchBulldog,
  'Labrador Retriever': LabradorRetriever,
  'Golden Retriever': LabradorRetriever,
  'German Shephard': GermanShephard,
  'Great Dane': GreatDane
}

const useBreedImage = (breed?: string): string | null => {
  if (breed && Object.keys(BREED_IMAGES).includes(breed)) {
    const images: { [key: string]: string } = BREED_IMAGES

    return images[breed]
  }

  return null
}

export { useBreedImage, BREED_IMAGES }
