// @noflow

/**
 * Use react context to provide localisation functionality to pages and components
 */
import { Code, Language } from '@types'
import type { Locale as DateLocale } from 'date-fns'
import { format as dateFNSFormatDate, parseISO } from 'date-fns'
import { enGB } from 'date-fns/locale'
import isString from 'lodash/isString'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

import {
  countryCodeToLocaleCurrency,
  localeToDateLocale
} from '@/utils/countryCodeHelper'
import {
  Options,
  formatCurrencyWithDecimal,
  formatCurrencyWithoutDecimal
} from '@/utils/currency'

/**
 * EEE dd MMM     - ie. Wed 19 Sep
 * iiii dd MMMM   - ie. Wednesday 19 September
 * do MMMM        - ie. 19 September
 * */
type DateFomats =
  | 'EEE dd MMM'
  | 'iiii dd MMMM'
  | 'do MMMM'
  | 'do MMMM yyyy'
  | 'd LLL HH:mm aaa'

type CurrencyFormatter =
  | typeof formatCurrencyWithDecimal
  | typeof formatCurrencyWithoutDecimal

type FormatCurrency = (amount: number, overrides?: Options) => string

interface LocalisationContextProps {
  dateLocale: DateLocale
  normaliseTimezone: (date: Date) => Date
  format:
    | ((date: Date | number | string, format: DateFomats) => string)
    | undefined
  formatCurrencyWithDecimal: FormatCurrency
  formatCurrencyWithoutDecimal: FormatCurrency
}

/**
 * Used to normalise the date and remove the difference added by a timezone
 * @param date
 */
const normaliseTimezone = (date: Date): Date =>
  new Date(date.getTime() - date.getTimezoneOffset() * 60000)

/**
 * Creates a currency formatting function
 *
 * `currencyFormatter` is a higher-order function that takes a currency formatting
 * function and currency options, and returns a new function. This new function
 * takes an amount and optional currency overrides, then applies the provided formatter
 * with the merged options.
 *
 * @param {CurrencyFormatter} formatter - The currency formatter function.
 * @param {Options} currencyOptions - The currency options to merge with currency overrides.
 * @returns {Function} A function that takes an amount and optional currency overrides,
 * then returns the formatted currency string.
 */
const currencyFormatter =
  (formatter: CurrencyFormatter, currencyOptions: Options): FormatCurrency =>
  (amount: number, overrides?: Options) =>
    formatter(amount, { ...currencyOptions, ...overrides })

const LocalisationContext = React.createContext<LocalisationContextProps>({
  dateLocale: enGB,
  normaliseTimezone,
  format: undefined,
  formatCurrencyWithDecimal,
  formatCurrencyWithoutDecimal
})

interface LocalisationProviderProps {
  shippingCountryCode: Code
  preferredLanguage: Language
  children: React.ReactNode
}

const LocalisationProvider = ({
  shippingCountryCode,
  preferredLanguage,
  children
}: LocalisationProviderProps): JSX.Element => {
  const [dateLocale, setDateLocale] = useState<DateLocale>(enGB)

  const currencyOptions: Options = countryCodeToLocaleCurrency(
    shippingCountryCode,
    preferredLanguage
  )

  const format = useCallback(
    (date: Date | number | string, format: DateFomats) =>
      dateFNSFormatDate(isString(date) ? parseISO(date) : date, format, {
        locale: dateLocale
      }),
    [dateLocale]
  )

  /**
   * Currency formatters with pre-set currency options and optional overrides.
   *
   * These functions should be used in the context of the LocalisationProvider
   * instead of the currency formatting functions in `@/utils/currency`.
   *
   * @example
    ```
    import { useLocalisation } from '@/context/localisation'

    const { formatCurrencyWithDecimal, formatCurrencyWithoutDecimal } = useLocalisation()

    const price = 10
    const formattedPriceWithDecimal = formatCurrencyWithDecimal(price)
    const formattedPriceWithoutDecimal = formatCurrencyWithoutDecimal(price)

    // In order to override the currency options, pass an object as the second argument
    const formattedPriceWithDecimalOverride = formatCurrencyWithDecimal(price, {
      locale: 'en-GB',
      currency: 'GBP'
    })
    ```
   */
  const formatCurrency = useMemo(
    () => ({
      formatCurrencyWithDecimal: currencyFormatter(
        formatCurrencyWithDecimal,
        currencyOptions
      ),
      formatCurrencyWithoutDecimal: currencyFormatter(
        formatCurrencyWithoutDecimal,
        currencyOptions
      )
    }),
    [currencyOptions]
  )

  const localisationProviderValues = useMemo(
    () => ({
      dateLocale,
      format,
      normaliseTimezone,
      ...formatCurrency
    }),
    [dateLocale, format, formatCurrency]
  )

  useEffect(
    () =>
      setDateLocale(localeToDateLocale(shippingCountryCode, preferredLanguage)),
    [shippingCountryCode, preferredLanguage]
  )

  return (
    <LocalisationContext.Provider value={localisationProviderValues}>
      {children}
    </LocalisationContext.Provider>
  )
}

const useLocalisation = (): LocalisationContextProps =>
  useContext(LocalisationContext)

export type { FormatCurrency }

export { LocalisationProvider, useLocalisation }
