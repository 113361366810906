// @noflow
import { countries } from 'countries-list'
import {
  AsYouType,
  isValidPhoneNumber,
  parsePhoneNumber
} from 'libphonenumber-js/mobile'

import * as Sentry from '@/utils/sentry'
import Validator from '@/utils/validator'

import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'

type FullName = {
  firstName: string
  lastName: string
}

/*
  Formats certains inputs:
  - phone numbers ignore anything that's not a digit or a space
    (or a + at the beginning for country calling codes) and separate the digits
    with spaces: +44 xxxx xxxxxx or xxxxx xxxxxx.
  - postcodes ignore invalid characters and limit to 8 characters.
*/
const removeExtraWhiteSpace = (value: string): string => {
  return value.replace(/(^\s*)|(\s*$)/gi, '').replace(/[ ]{2,}/gi, ' ')
}

const formatNameInput = (fullName: string): FullName => {
  // replace leading, trailing, and double white space
  const formattedName = removeExtraWhiteSpace(fullName)
  const firstSpace = formattedName.indexOf(' ')
  const firstName = formattedName.substr(0, firstSpace)
  const lastName = formattedName.substr(firstSpace + 1)

  return {
    firstName,
    lastName
  }
}

const format = (
  value: string,
  fieldName: string,
  shippingCountryCode?: CountryCode
): string => {
  switch (fieldName) {
    case 'mobileNumber': {
      return new AsYouType(shippingCountryCode).input(value)
    }
    case 'postcode': {
      return value.replace(/[^a-zA-Z0-9 ]/g, '').slice(0, 8)
    }
    default: {
      return value
    }
  }
}

const supportedCountryNumbers = Object.entries(countries)
  .filter(([key, { continent }]) => continent === 'EU' || key === 'US')
  .map(([key]) => key)

const validate = (
  value: string,
  fieldName: string,
  shippingCountryCode?: CountryCode
): string => {
  switch (fieldName) {
    case 'name': {
      // This allows for special and accented characters used in the alphabet of
      // languages such as Polish.
      const nameRegex = /[\p{L}\p{N}\p{M}-]+\s+[\p{L}\p{N}\p{M}-]+/u
      if (nameRegex.test(value)) {
        return value
      }
      return ''
    }
    case 'email': {
      if (Validator.isValidEmail(value)) {
        return value
      }
      return ''
    }
    case 'addressLine1': {
      if (value.length > 0) {
        return value
      }
      return ''
    }
    case 'addressLine2': {
      return value
    }
    case 'city': {
      if (value.length > 0) {
        return value
      }
      return ''
    }
    case 'postcode': {
      if (!shippingCountryCode)
        Sentry.captureException(`shippingCountryCode is invalid`, {
          extra: {
            shippingCountryCode
          },
          tags: {
            product: Sentry.Product.Checkout
          }
        })
      const countryCode = shippingCountryCode || 'GB'

      if (!Validator.isValidPostcodeForShippingCountry(value, countryCode)) {
        return ''
      }
      if (Validator.isUndeliverablePostcode(value, countryCode)) {
        return ''
      }
      return value
    }
    case 'mobileNumber': {
      const trimmedNumber = value.trim()
      const sanitisedShippingCountryCode =
        shippingCountryCode === 'NI' ? 'GB' : shippingCountryCode

      // We check first that the number is a valid complete mobile number, because parsePhoneNumber
      // requires a complete phone number otherwise it'll error on attempting to parse
      const isValidMobileNumber = isValidPhoneNumber(
        trimmedNumber,
        sanitisedShippingCountryCode
      )
      if (isValidMobileNumber) {
        // We have a defined list of allowed European + US phone numbers which we want to check against
        const parsedNumberCountryCode = parsePhoneNumber(
          trimmedNumber,
          sanitisedShippingCountryCode
        ).country

        if (!parsedNumberCountryCode) return ''

        const isSupportedCountryNumber = supportedCountryNumbers.includes(
          parsedNumberCountryCode
        )

        if (isSupportedCountryNumber) {
          return value
        } else {
          return ''
        }
      } else {
        return ''
      }
    }
    case 'paymentIntentId':
    case 'paymentMethodId': {
      const stripeRegex = /[^a-zA-Z0-9 _]/
      if (stripeRegex.test(value)) {
        throw new Error(`Invalid Stripe: ${value}`)
      }
      return value
    }
    default: {
      Sentry.captureException(`validate not implemented for fieldName`, {
        extra: {
          fieldName
        },
        tags: {
          product: Sentry.Product.Checkout
        }
      })
      return ''
    }
  }
}

export { format, validate, removeExtraWhiteSpace, formatNameInput }
