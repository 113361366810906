// @noflow
import { referralLinkEvent } from '@/helpers/ReferralLinkShareHelper'
import type { PartialLocation } from '@/helpers/ReferralLinkShareHelper'
import React from 'react'
import { useTranslation } from 'react-i18next'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './CopyInputButton.module.sass'

type Props = {
  namespace: string
  referralCode: string
  copyLinkMessage: string
  partialLocation?: PartialLocation
  onSuccess?: () => void
  railsPage?: boolean
}

const copyContext = 'refer_a_friend.give_and_get_tab.copy_input_button'

const CopyInputButton = ({
  namespace,
  referralCode,
  copyLinkMessage,
  partialLocation = 'Dashboard',
  onSuccess,
  railsPage = false
}: Props): JSX.Element => {
  const { t } = useTranslation('dashboard')

  const [copySuccess, setCopySuccess] = React.useState(false)
  const { windowWidth } = useWindowSize()

  const copyReferralLink = React.useCallback(() => {
    const dummy = document.createElement('textarea')

    document.body.appendChild(dummy)
    // eslint-disable-next-line i18next/no-literal-string
    dummy.value = copyLinkMessage
    dummy.select()

    const success = document.execCommand('copy')
    document.body.removeChild(dummy)

    if (success) {
      setCopySuccess(true)
      if (onSuccess) {
        onSuccess()
      }
      setTimeout((): void => {
        setCopySuccess(false)
      }, 3000)
    }

    referralLinkEvent('Copy Link', partialLocation)
  }, [copyLinkMessage, onSuccess, partialLocation])

  const copyPromptKey = windowWidth < BREAKPOINTS.md ? 'mobile' : 'desktop'
  const copySuccessKey = 'success'

  const key = t(
    `${copyContext}_${copySuccess ? copySuccessKey : copyPromptKey}`
  )

  return (
    <button
      className={STYLES.copyInputButton}
      onClick={copyReferralLink}
      type="button"
      data-testid="share-code-copy-input-button"
    >
      <Text
        namespace={namespace}
        text={
          railsPage
            ? key
            : `${copyContext}_${copySuccess ? copySuccessKey : copyPromptKey}`
        }
        variant="textRegular12"
        colour="brandBlue400"
        align="center"
        margin={false}
        translate={!railsPage}
      />
      <input
        id="copyInputButton"
        className={STYLES.copyInput}
        value={referralCode}
        readOnly
        name="referral-link"
      />
    </button>
  )
}

export { Props }
export default CopyInputButton
