import { ApolloError } from '@apollo/client/errors'
import isNil from 'lodash/isNil'
import React from 'react'
import { toast } from 'react-toastify'

import * as Sentry from '@/utils/sentry'

import NotificationContent from '@/components/elements/molecules/NotificationContent/NotificationContent'
import { InitialState } from '@/components/pages/SignupWizardPage/SignupWizardPage'

const handleFetchError = (error: ApolloError): void => {
  const redirectTo = '/plans/recipes'

  Sentry.captureException('A error occured when fetching the plans data', {
    extra: {
      error: JSON.stringify(error)
    },
    tags: {
      product: Sentry.Product.PlansFlow
    }
  })

  toast.error(
    <NotificationContent
      copy={{
        text: 'plan_data_error',
        namespace: 'plans_flow'
      }}
    />
  )

  if (window.location.pathname !== redirectTo) window.location.href = redirectTo
}

const hasCountryCode = (wizardData: InitialState): boolean =>
  !isNil(wizardData?.user?.location?.countryCode)

const isGBAndMissingPostcode = (wizardData: InitialState): boolean =>
  hasCountryCode(wizardData) &&
  wizardData.user.location.countryCode === 'GB' &&
  isNil(wizardData.user.location.postcode)

const verifyWizardData = (
  wizardData: InitialState | null | undefined
): boolean =>
  !isNil(wizardData) &&
  Array.isArray(wizardData.dogs) &&
  wizardData.dogs.length > 0 &&
  hasCountryCode(wizardData) &&
  !isGBAndMissingPostcode(wizardData)

const isLocationError = (wizardData: InitialState | null): boolean =>
  !isNil(wizardData) &&
  (!hasCountryCode(wizardData) || isGBAndMissingPostcode(wizardData))

const redirectTo = (wizardData: InitialState | null): string => {
  if (isLocationError(wizardData)) {
    return '/wizard/location'
  }
  return '/wizard/new'
}

const handleWizardDataError = (wizardData: InitialState | null): void => {
  if (isLocationError(wizardData)) {
    Sentry.captureException(
      'User entered plans review page with no countryCode or GB and null postcode',
      {
        extra: {
          referrer: document.referrer,
          wizardData
        },
        tags: {
          product: Sentry.Product.PlansFlow
        }
      }
    )
  } else {
    Sentry.captureException(
      'Required wizard information could not be fetched from local storage',
      {
        extra: {
          referrer: document.referrer,
          wizardData
        },
        tags: {
          product: Sentry.Product.PlansFlow
        }
      }
    )
  }
  window.location.href = redirectTo(wizardData)
}

export {
  handleFetchError,
  handleWizardDataError,
  verifyWizardData,
  isGBAndMissingPostcode,
  redirectTo,
  hasCountryCode
}
