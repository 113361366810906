// @noflow
import React from 'react'

import FormRequestMessage from './FormRequestMessage'

const FormRequestMessageScrollable = (): React.ReactElement => {
  /**
   * To ensure that the users sees the Message component once the form has been
   * submitted, we use this Hook to ensure the component is scrolled into view
   * when it mounts and has enough offset for the fixed navbar.
   *
   * TODO: Move this to a middleware that can scroll to an element - caveat that
   * the scroll must wait for the element to appear in the DOM _but also_ not
   * block the Redux action pipeline
   */
  React.useEffect((): void => {
    const message = document.getElementById('message-container')
    const navbarOffset = 70

    if (message) {
      message.scrollIntoView()

      const yScrollPosition = window.scrollY

      window.scroll({ behavior: 'smooth', top: yScrollPosition - navbarOffset })
    }
  }, [])

  return (
    <div id="message-container">
      <FormRequestMessage />
    </div>
  )
}

export default FormRequestMessageScrollable
