import { gql } from '@apollo/client'

// Fragments
import { BOX_PHYSICAL_ORDER_PRODUCTS } from '../fragments/fragments'

const RECOMMENDED_ORDERS_PRODUCT_CREATE = gql`
  # eslint-disable-next-line
  ${BOX_PHYSICAL_ORDER_PRODUCTS}
  mutation RecommendedOrderProductsCreate(
    $userId: ID!
    $productVariantInput: ProductVariantInput!
    $boxId: ID!
  ) {
    orderProductsCreate(
      userId: $userId
      productVariantInput: $productVariantInput
    ) {
      user {
        id
        subscription {
          id
          box(boxId: $boxId) {
            ...BoxPhysicalOrderProducts
          }
        }
      }
    }
  }
`
export { RECOMMENDED_ORDERS_PRODUCT_CREATE }
