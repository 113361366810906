// @noflow
import React from 'react'
import { useTranslation } from 'react-i18next'

import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import { SelectableCardGroupV2 } from '@/components/elements/molecules/SelectableCardGroup'

import { Eater } from '@/types'

import type { SharedInputProps } from '../../UpdateDogWeightAndAppetiteContent'

type Props = SharedInputProps & {
  eaterType: string
  genderContext: string
  isMobile?: boolean
}

const EatingHabits = ({
  eaterType,
  genderContext,
  isMobile = false,
  margin = { top: 8, bottom: 0 },
  onSelect,
  loading
}: Props): JSX.Element => {
  const { t } = useTranslation('account')
  const copyContext = 'update_dog_profile_weight_and_appetite'

  const EATER_TYPES = [
    {
      value: Eater.fussy_eater,
      icon: {
        slug: 'eater-type-fussy-eater',
        alt: t(`${copyContext}.eater_type.fussy_eater.heading`),
        image: {
          width: 77,
          height: 48
        }
      },
      identifier: `${copyContext}.eater_type.fussy_eater`
    },
    {
      value: Eater.picky_eater,
      icon: {
        slug: 'eater-type-picky-eater',
        alt: t(`${copyContext}.eater_type.picky_eater.heading`),
        image: {
          width: 72,
          height: 48
        }
      },
      identifier: `${copyContext}.eater_type.picky_eater`
    },
    {
      value: Eater.good_eater,
      icon: {
        slug: 'eater-type-good-eater',
        alt: t(`${copyContext}.eater_type.good_eater.heading`),
        image: {
          width: 80,
          height: 38
        }
      },
      identifier: `${copyContext}.eater_type.good_eater`
    },
    {
      value: Eater.eats_anything,
      icon: {
        slug: 'eater-type-eats-anything',
        alt: t(`${copyContext}.eater_type.eats_anything.heading`),
        image: {
          width: 63,
          height: 64
        }
      },
      identifier: `${copyContext}.eater_type.eats_anything`
    }
  ]

  return (
    <SectionWrapper margin={margin}>
      <SelectableCardGroupV2
        options={EATER_TYPES}
        onSelect={onSelect}
        currentValue={eaterType}
        {...(isMobile && {
          layoutCardColumns: 2
        })}
        cardProps={{
          contentDirection: 'row',
          skeleton: {
            isLoading: loading,
            height: isMobile ? '8rem' : '11rem'
          },
          padding: isMobile ? 8 : 16,
          minHeight: isMobile ? '8rem' : '11rem'
        }}
        descriptionCard={{
          hideTriangle: isMobile,
          title: {
            namespace: 'account',
            text: `${copyContext}.eater_type.${eaterType}.heading`,
            variables: {
              context: genderContext
            }
          },
          description: {
            namespace: 'account',
            text: `${copyContext}.eater_type.${eaterType}.text`,
            variables: {
              context: genderContext
            }
          }
        }}
      />
    </SectionWrapper>
  )
}

export { EatingHabits }
