// @noflow

type InAppBrowser = 'facebook' | 'instagram'

const isFacebookInAppBrowser = (userAgent: NavigatorID['userAgent']): boolean =>
  /\b(FB_IAB)\b/.test(userAgent)
const isInstagramInAppBrowser = (
  userAgent: NavigatorID['userAgent']
): boolean => /\b(Instagram)\b/.test(userAgent)

/**
 * Tests if the users browser has been launched from inside an app
 */
const isInAppBrowser = (
  userAgent: NavigatorID['userAgent']
): InAppBrowser | null => {
  if (isFacebookInAppBrowser(userAgent)) return 'facebook'
  if (isInstagramInAppBrowser(userAgent)) return 'instagram'
  return null
}

const isSafari = (userAgent: NavigatorID['userAgent']): boolean =>
  userAgent.search('Safari') >= 0 && userAgent.search('Chrome') < 0

export { InAppBrowser, isInAppBrowser, isSafari }
