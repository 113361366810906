// @noflow
import React from 'react'
import { useTranslation } from 'react-i18next'

import MultipleDogs from 'assets/images/illustrations/dogs/dog-profile-avatars/multiple-dogs.svg'

import DogAvatar from '@/components/shared/blocks/DogAvatar'
import type { Dog } from '@/components/shared/blocks/DogAvatar'

type Props<T> = {
  children: React.ReactElement
  dogs: Array<T>
  extraCard?: React.ReactElement
}

const DogAvatarCard = <T extends Dog>({
  children,
  dogs,
  extraCard
}: Props<T>): React.ReactElement => {
  const { t } = useTranslation('thank_you')
  const copy_context = 'dog_profile_entry'

  return (
    <div className="dog-avatar-card">
      <div className="dog-avatar-card__contents">
        <div className="dog-avatar-card__dog-avatar-wrapper">
          {dogs.length <= 3 ? (
            dogs.map(
              (dog: Dog): React.ReactElement => (
                <DogAvatar
                  key={dog.id}
                  dog={dog}
                  sprinkles={dogs.length === 1}
                />
              )
            )
          ) : (
            <div className="dog-avatar">
              <img
                alt={t(`${copy_context}.avatar_multiple_alt`)}
                className="dog-avatar__image"
                src={MultipleDogs}
              />
            </div>
          )}
        </div>
        {children}
      </div>
      {extraCard && (
        <div className="dog-avatar-card__contents--bonus-card">{extraCard}</div>
      )}
    </div>
  )
}

export default DogAvatarCard

export type { Props }
