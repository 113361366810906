import { gql } from '@apollo/client'

import { ORDER_SUMMARY_FLAVOUR_FRAGMENT } from '../components/OrderSummary/fragments/OrderSummaryFragment'

import { DISCOUNT_CODE_FRAGMENT } from './fragments/DiscountCodeFragment'
import { FEATURE_FLAG_FRAGMENT } from './fragments/FeatureFlagFragment'
import { PRODUCT_VARIANT_FRAGMENT } from './fragments/ProductVariantFragment'
import { SHIPPING_COUNTRY_FRAGMENT } from './fragments/ShippingCountryFragment'

const CHECKOUT_PAGE = gql`
  ${ORDER_SUMMARY_FLAVOUR_FRAGMENT}
  ${FEATURE_FLAG_FRAGMENT}
  ${SHIPPING_COUNTRY_FRAGMENT}
  ${DISCOUNT_CODE_FRAGMENT}
  ${PRODUCT_VARIANT_FRAGMENT}
  query CheckoutPage($planId: ID!) {
    ...featureFlagFragment
    shippingCountries {
      ...shippingCountryFragment
    }
    guest {
      id
      firstName
      lastName
      email
      assumedShippingCountry {
        ...shippingCountryFragment
      }
      discountCode {
        ...discountCodeFragment
      }
      dogs {
        name
        dailyCalories
        pouchSizeInGrams
      }
      numberOfAffiliateFreeBoxes
      requiresPaymentDetailsOnCheckout
      referredByAmbassador
      referredByInfluencer

      affiliateType
      shouldSupportSca

      pendingSubscription {
        plan {
          durationInDays
          numberOfPouches
          pouchSize
          id
          trial {
            lengthInDays
          }
          pouchesPerDay
          nextUpcomingPrice {
            effectiveFrom
          }
        }
        productVariants {
          productVariant {
            ...productVariantFragment
          }
          discountedPrice
        }
        pendingSubscriptionFlavours {
          flavour {
            ...orderSummaryFlavourFragment
            standardPricePerDay(planId: $planId)
          }
          id
          servings
          sevenDayServings
          trialBoxServings
        }
      }
    }
  }
`

export { CHECKOUT_PAGE }
