// @noflow
import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import SUPPORT_COLOURS from '@/constants/SupportColours'

import STYLES from './ProductUpsellableCard.module.sass'

import Card from '../../atoms/Card/Card'
import SkeletonButton from '../../atoms/SkeletonButton/SkeletonButton'
import SkeletonParagraph from '../../atoms/SkeletonParagraph/SkeletonParagraph'
import type { Props } from './ProductUpsellableCard'

const ProductUpsellableCardSkeleton = ({
  orientation
}: {
  orientation: Props['orientation']
}): JSX.Element => {
  return (
    <Card shadow={false}>
      <SkeletonTheme
        baseColor={SUPPORT_COLOURS.grey200}
        highlightColor={'white'}
      >
        <div
          className={
            orientation === 'horizontal'
              ? STYLES.wrapper
              : STYLES.wrapperVertical
          }
        >
          <div className={STYLES.image}>
            <Skeleton
              count={1}
              width={'100%'}
              height={150}
              style={{
                minWidth: 150
              }}
            />
          </div>
          <div className={STYLES.content}>
            <SkeletonParagraph count={1} />
            <div className={STYLES.subcontent}>
              <SkeletonButton count={1} width={'100%'} />
            </div>
          </div>
        </div>
      </SkeletonTheme>
    </Card>
  )
}

export default ProductUpsellableCardSkeleton
