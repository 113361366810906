import { gql } from '@apollo/client'

import { CALENDAR_DATES_V2_FRAGMENT } from '@/components/elements/molecules/CalendarV2/fragments/calendarDatesV2Fragment'
import { COURIER_FRAGMENT } from '@/components/elements/molecules/CourierSelector/fragments/CourierFragment'

const DELIVERY_DATE_MODAL_WITH_COURIER_QUERY = gql`
  ${CALENDAR_DATES_V2_FRAGMENT}
  ${COURIER_FRAGMENT}
  query DeliveryDateModalWithCourierQuery(
    $calendarInitDate: ISO8601Date!
    $nDays: Int!
    $city: String!
    $postcode: String!
    $shouldAttemptToOfferNextDayDelivery: Boolean!
  ) {
    deliveryAreaOptions(city: $city, postcode: $postcode) {
      ...CourierFragment
    }
    calendarDates(
      startDate: $calendarInitDate
      nDays: $nDays
      city: $city
      postcode: $postcode
      shouldAttemptToOfferNextDayDelivery: $shouldAttemptToOfferNextDayDelivery
    ) {
      ...CalendarDatesV2Fragment
    }
  }
`

export { DELIVERY_DATE_MODAL_WITH_COURIER_QUERY }
