// @noflow
import startCase from 'lodash/startCase'
import React from 'react'

import OnDemandIcon from 'assets/images/icons/extras--on-demand.svg'
import OneOffIcon from 'assets/images/icons/extras--one-off.svg'
import RecurringIcon from 'assets/images/icons/extras--recurring.svg'

import STYLES from './FrequencyVariantIcon.module.sass'

import { VariantDelivery } from '../../../../../../../../types/index'

type Props = {
  deliveryType: VariantDelivery
}

const FrequencyVariantIcon = ({ deliveryType }: Props): JSX.Element | null => {
  switch (deliveryType) {
    case 'recurring': {
      return (
        <div className={STYLES.container}>
          <img alt={startCase(deliveryType)} src={RecurringIcon} />
        </div>
      )
    }
    case 'one_off': {
      return (
        <div className={STYLES.container}>
          <img alt={startCase(deliveryType)} src={OneOffIcon} />
        </div>
      )
    }
    case 'on_demand': {
      return (
        <div className={STYLES.container}>
          <img alt={startCase(deliveryType)} src={OnDemandIcon} />
        </div>
      )
    }
    default: {
      return null
    }
  }
}

export default FrequencyVariantIcon
