// @noflow
import React from 'react'
import { connect } from 'react-redux'

import cross from 'assets/images/icons/crosses/close-icon.svg'

import type { Dispatch, State } from '../reducer'
import { widgetClosed, widgetOpened } from '../slices/navigationSlice'
import ListItem from './ListItem'
import TabBar from './TabBar'
import TabContent from './TabContent'

type PresentationalProps = {
  isOpen: boolean
}

type ActionProps = {
  widgetClosed: () => void
  widgetOpened: () => void
}

type Props = PresentationalProps & ActionProps

const mapStateToProps = (state: State): PresentationalProps => ({
  isOpen: state.navigation.isOpen
})

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
  widgetClosed: (): void => {
    dispatch(widgetClosed())
  },
  widgetOpened: (): void => {
    dispatch(widgetOpened())
  }
})

const OpenCloseButton = ({
  onClick,
  type
}: {
  onClick: () => void
  type: 'open' | 'close'
}): React.ReactElement => (
  <ListItem
    type={type === 'open' ? 'primary' : 'highlighted'}
    className={type === 'open' ? 'open-button' : 'close-button'}
    onClick={onClick}
  >
    <img
      src={
        type === 'open'
          ? 'https://res.cloudinary.com/ldhg5acsz/image/upload/Web/photos/logos/PAW-Donut'
          : cross
      }
      alt=""
    />
  </ListItem>
)

const BackgroundNode = ({
  onClick
}: {
  onClick: () => void
}): React.ReactElement => (
  <button className="background-node" type="button" onClick={onClick} />
)

const OpenPaw = ({
  widgetClosed
}: {
  widgetClosed: () => void
}): React.ReactElement => (
  <>
    <BackgroundNode onClick={widgetClosed} />
    <div className="paw open">
      <TabContent />
      <div className="tab-bar">
        <OpenCloseButton onClick={widgetClosed} type="close" />
        <TabBar />
      </div>
    </div>
  </>
)

const ClosedPaw = ({
  widgetOpened
}: {
  widgetOpened: () => void
}): React.ReactElement => (
  <div className="paw closed">
    <OpenCloseButton onClick={widgetOpened} type="open" />
  </div>
)

const Paw = ({ isOpen, widgetOpened, widgetClosed }: Props) => (
  <div className="paw-container">
    {isOpen ? (
      <OpenPaw widgetClosed={widgetClosed} />
    ) : (
      <ClosedPaw widgetOpened={widgetOpened} />
    )}
  </div>
)

export default connect(mapStateToProps, mapDispatchToProps)(Paw)
