// @flow

import * as React from 'react'

import { ILLUSTRATIONS } from '../assets/'
import { useTranslation } from 'react-i18next'

type Translate = (
  arg0: string,
  arg1?: {|
    context?: string,
    count?: number,
    dogName?: string,
    returnObjects?: boolean
  |}
  ) => string

const copyContext = 'finishing_up'

const generateDogSpeechbubbleText = (t: Translate): string => {
  const descriptions = t(`${copyContext}.descriptions`, { returnObjects: true })
  const descriptionText = descriptions[Math.floor(Math.random() * descriptions.length)]
  return descriptionText
}

const FinishingUp = (): React.Node => {
  const { t } = useTranslation('post_signup_wizard')
  return (
    <section className='finishing-up animated fade-in'>
      <div className='finishing-up__content'>
        <div className='finishing-up__content__image'>
          <div className='finishing-up__content__image__speech-bubble animated fade-in '>
            <span>
              { generateDogSpeechbubbleText(t) }
            </span>
          </div>
          <img
            alt=''
            loading='lazy'
            className='finishing-up__content__image__dog'
            src={ILLUSTRATIONS.ProgressDog}
          />
        </div>
        <h1 className='finishing-up__content__title'>
          { t(`${copyContext}.title`) }
        </h1>
        <h2 className='finishing-up__content__subtitle'>
          { t(`${copyContext}.subtitle`) }
        </h2>
      </div>
    </section>
  )
}

export default FinishingUp
