// @flow

import * as React from 'react'
import { connect, Dispatch } from 'react-redux'
import { Route, Routes, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { pronounContext } from '@/utils/StringHelper'

import NavigationBar from './base_components/NavigationBar'
import ProgressBar from './base_components/ProgressBar'

import FavouriteGames from './FavouriteGames'
import FavouriteActivities from './FavouriteActivities'
import QuirksAndTraits from './QuirksAndTraits'
import UploadPhoto from './UploadPhoto'
import DogDescription from './DogDescription'
import PostWizard from './PostWizard'

import * as ACTIONS from '../actions'

import PostSignUpRoutes, { routeToWizardStep } from '../postSignUpRoutes'
import { capitaliseFirstLetter } from '../../../utils/StringHelper'

import type { Gender } from '../../../shared_types/rails_models/dogs'
import type { Dog } from '../message_types'
import type { State } from '../reducers'
import type { State as WizardStep } from '../reducers/wizardStepReducer'

type PresentationalProps = {|
  wizardStep: WizardStep,
  dogs: Array<Dog>
|}

type ActionProps = {|
  setWizardStep: (WizardStep) => void
|}

type Props =
  & PresentationalProps
  & ActionProps

type Translate = (
  arg0: string,
  arg1?: {|
    context?: string,
    count?: number,
    dogName?: string
  |}
  ) => string

const mapStateToProps = ({ wizardStep, globalAttributes }: State): PresentationalProps => {
  const { dogs } = globalAttributes

  return {
    wizardStep,
    dogs
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => {
  const setWizardStep = (wizardStep: WizardStep): void => {
    dispatch(ACTIONS.setWizardStep(wizardStep))
  }


  return {
    setWizardStep
  }
}

const wizardStepToDocumentTitle = (wizardStep: WizardStep, dogs: Array<Dog>, t: Translate): string => {
  const copyContext = 'document_title'
  const dogGenders = dogs.map(({ gender }: Dog): Gender => (gender))
  switch (wizardStep) {
    case 'FavouriteGames': {
      return `${t(`${copyContext}.profile`, { context: pronounContext(dogGenders, i18next.language) })} ${t(`${copyContext}.favourite_games`, { context: pronounContext(dogGenders, i18next.language) })}`
    }
    case 'FavouriteActivities': {
      return `${t(`${copyContext}.profile`, { context: pronounContext(dogGenders, i18next.language) })} ${t(`${copyContext}.favourite_activities`)}`
    }
    case 'QuirksAndTraits': {
      return `${t(`${copyContext}.profile`, { context: pronounContext(dogGenders, i18next.language) })} ${t(`${copyContext}.quirks_and_treats`)}`
    }
    case 'UploadPhoto': {
      return `${t(`${copyContext}.profile`, { context: pronounContext(dogGenders, i18next.language) })} ${t(`${copyContext}.upload_photo`)}`
    }
    case 'DogDescription': {
      return `${t(`${copyContext}.profile`, { context: pronounContext(dogGenders, i18next.language) })} ${t(`${copyContext}.tell_us`, { context: pronounContext(dogGenders, i18next.language) })}`
    }
    case 'PostWizard': {
      return t(`${copyContext}.building`, { context: pronounContext(dogGenders, i18next.language) })
    }
    default: {
      (wizardStep: empty) // eslint-disable-line no-unused-expressions
      throw new Error(`Cannot generate wizardStepToDocumentTitle for: ${wizardStep}`)
    }
  }
}

const WizardSteps = ({ wizardStep, dogs, setWizardStep }: Props): React.Node => {
  const { t } = useTranslation('post_signup_wizard')
  const location = useLocation()
  React.useEffect((): void => {
    const documentTitle = wizardStepToDocumentTitle(wizardStep, dogs, t)
    document.title = capitaliseFirstLetter(documentTitle)
  }, [dogs, t, wizardStep])

  React.useEffect((): void => {
    const wizardStep = routeToWizardStep(location.pathname)
    setWizardStep(wizardStep)
  }, [location, setWizardStep]);

  return (
    <React.Fragment>
      {
        wizardStep !== 'PostWizard' && (
          <React.Fragment>
            <NavigationBar />
            <ProgressBar />
          </React.Fragment>
        )
      }
      <Routes>
        <Route
          element={<FavouriteGames />}
          exact
          path={PostSignUpRoutes.Root}
        />
        <Route
          element={<FavouriteActivities />}
          path={PostSignUpRoutes.Activities}
        />
        <Route
          element={<QuirksAndTraits />}
          path={PostSignUpRoutes.Quirks}
        />
        <Route
          element={<UploadPhoto />}
          path={PostSignUpRoutes.UploadPhoto}
        />
        <Route
          element={<DogDescription />}
          path={PostSignUpRoutes.DogDescription}
        />
        <Route
          element={<PostWizard />}
          path={PostSignUpRoutes.PostWizard}
        />
      </Routes>
    </React.Fragment>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WizardSteps)
