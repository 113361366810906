// @noflow
import { useCombobox, useMultipleSelection } from 'downshift'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { transformIndents } from '@/utils/transformIndents'

import Icon from '@/components/elements/atoms/Icon/Icon'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './MultipleComboBox.module.sass'

import type { TextProps } from '../../atoms/Text'

type Item = {
  id: string
  typography: TextProps
}

type BorderRadius = 8 | 16
type IndentValues = 16 | 24
type Indent = {
  top?: IndentValues
  right?: IndentValues
  bottom?: IndentValues
  left?: IndentValues
}

type Props = {
  items: Array<Item>
  initialSelectedItems?: Array<Item>
  placeholder?: TextProps
  disabled?: boolean
  borderRadius?: BorderRadius
  margin?: Indent | IndentValues
  padding?: Indent | IndentValues
  dropdownPosition?: 'top' | 'bottom'
  onStateChange?: (selectedItems: Array<Item>) => void
}

/**
 * MultipleComboBox component
 *
 * Use this component to render a multiple combo box.
 *
 * @example
  ```
  import { MultipleComboBox } from 'components/molecules/MultipleComboBox'

  <MultipleComboBox
    items={[
     { id: '1', typography: { text: 'translation.path.text' }},
    ]}
    initialSelectedItems={[]}
    placeholder={{ text: 'translation.path.text' }}
    onStateChange={(selectedItems) => console.log(selectedItems)}
  />
  ```
 *
 * @category Components
 * @subcategory Molecules
 * @returns {JSX.Element} - MultipleComboBox component
 */
const MultipleComboBox = ({
  items,
  initialSelectedItems = [],
  placeholder,
  disabled = false,
  borderRadius = 16,
  margin,
  padding,
  dropdownPosition = 'bottom',
  onStateChange
}: Props): JSX.Element => {
  const { t } = useTranslation(placeholder?.namespace ?? '')

  const [inputValue, setInputValue] = useState('')
  const [selectedItems, setSelectedItems] =
    useState<Array<Item>>(initialSelectedItems)

  const defaultIndents = {
    padding: {
      top: 16,
      right: 24,
      bottom: 16,
      left: 24
    }
  } as const

  const { marginStyles, paddingStyles } = transformIndents({
    margin,
    padding,
    defaultIndents
  })

  const getTypographyText = useCallback(
    (item: TextProps): string =>
      item.translate
        ? t(item.text, { variables: item.variables })
        : (item.text as string),
    [t]
  )

  // Get filtered items based on the input value and the selected items.
  const getFilteredItems = () => {
    const lowerCasedInputValue = inputValue.toLowerCase()

    return items.filter(
      (item) =>
        !selectedItems.some((selectedItem) => selectedItem.id === item.id) &&
        getTypographyText(item.typography)
          .toLowerCase()
          .includes(lowerCasedInputValue)
    )
  }

  const transformedItems = getFilteredItems()

  const { getSelectedItemProps, getDropdownProps, removeSelectedItem } =
    useMultipleSelection({
      selectedItems,
      onStateChange({ selectedItems: newSelectedItems, type }) {
        const {
          stateChangeTypes: {
            SelectedItemKeyDownBackspace,
            SelectedItemKeyDownDelete,
            DropdownKeyDownBackspace,
            FunctionRemoveSelectedItem
          }
        } = useMultipleSelection

        switch (type) {
          case SelectedItemKeyDownBackspace:
          case SelectedItemKeyDownDelete:
          case DropdownKeyDownBackspace:
          case FunctionRemoveSelectedItem: {
            if (!disabled) {
              setSelectedItems(newSelectedItems ?? [])

              onStateChange && onStateChange(newSelectedItems ?? [])
            }

            break
          }
          default: {
            break
          }
        }
      }
    })

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectedItem
  } = useCombobox({
    items: transformedItems,
    itemToString(item) {
      return item ? getTypographyText(item.typography) : ''
    },
    defaultHighlightedIndex: 0, // after selection, highlight the first item.
    selectedItem: null,
    inputValue,
    stateReducer(_, actionAndChanges) {
      const { changes, type } = actionAndChanges

      const {
        stateChangeTypes: { InputKeyDownEnter, ItemClick }
      } = useCombobox

      switch (type) {
        case InputKeyDownEnter:
        case ItemClick: {
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
            highlightedIndex: 0 // with the first option highlighted.
          }
        }
        default: {
          return changes
        }
      }
    },
    onStateChange({
      inputValue: newInputValue,
      type,
      selectedItem: newSelectedItem
    }) {
      const {
        stateChangeTypes: {
          InputKeyDownEnter,
          ItemClick,
          InputBlur,
          InputChange
        }
      } = useCombobox

      switch (type) {
        case InputKeyDownEnter:
        case ItemClick:
        case InputBlur: {
          if (newSelectedItem && !disabled) {
            setSelectedItems([...selectedItems, newSelectedItem])
            setInputValue('')

            onStateChange && onStateChange([...selectedItems, newSelectedItem])
          }
          break
        }
        case InputChange: {
          if (!disabled) {
            setInputValue(newInputValue ?? '')
          }

          break
        }
        default: {
          break
        }
      }
    }
  })

  return (
    <div className={STYLES.wrapper}>
      <div
        className={`${STYLES.inputWrapper} ${disabled ? STYLES.disabled : ''} ${
          STYLES[`borderRadius${borderRadius}`]
        }`}
        style={{ ...marginStyles, ...paddingStyles }}
      >
        {selectedItems.map((selectedItemForRender, index) => (
          <div
            className={STYLES.selectedItem}
            key={selectedItemForRender.id}
            {...getSelectedItemProps({
              selectedItem: selectedItemForRender,
              index
            })}
          >
            <Text
              element="span"
              colour={disabled ? 'grey400' : 'brandBlue500'}
              {...selectedItemForRender.typography}
            />
            <button
              className={STYLES.removeButton}
              // eslint-disable-next-line react/jsx-no-bind
              onClick={(event) => {
                event.stopPropagation()

                removeSelectedItem(selectedItemForRender)
              }}
              type="button"
              aria-label="remove-button"
            >
              <Icon
                asset="close"
                size={16}
                width={16}
                accentColour={disabled ? 'grey500' : 'brandBlue500'}
              />
            </button>
          </div>
        ))}
        <input
          placeholder={
            placeholder?.translate
              ? t(placeholder.text, {
                  variables: placeholder.variables
                })
              : placeholder?.text.toString() ?? ''
          }
          className={STYLES.input}
          {...getInputProps(getDropdownProps({ preventKeyAction: isOpen }))}
          disabled={disabled}
        />
      </div>
      <div
        className={`${STYLES.listOfItems} ${STYLES[dropdownPosition]} ${
          !(isOpen && transformedItems.length) && STYLES.listOfItemsHidden
        }`}
        {...getMenuProps()}
        data-testid="list-of-items"
      >
        {isOpen &&
          transformedItems.map((item, index) => (
            <div
              className={`${STYLES.listItem} ${
                highlightedIndex === index && STYLES.listItemHighlighted
              } ${selectedItem === item && STYLES.listItemSelected}`}
              key={item.id}
              {...getItemProps({ item, index })}
            >
              <Text element="span" {...item.typography} />
            </div>
          ))}
      </div>
    </div>
  )
}

export type { Props, Item }

export { MultipleComboBox }
