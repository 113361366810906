import { gql } from '@apollo/client'

// Fragments
import { CALORIE_DETAILS_FRAGMENT } from '../../CalorieDetails/fragments/calorieDetailsFragment'

const DAILY_PORTION_SIZE_FRAGMENT = gql`
  ${CALORIE_DETAILS_FRAGMENT}
  fragment DailyPortionSizeFragment on User {
    id
    dogs {
      id
      name
      possessivePronoun
      gender
      planPortionInGrams
      ...CalorieDetailsFragment
    }
  }
`

export { DAILY_PORTION_SIZE_FRAGMENT }
