// @noflow
import { useOccasion } from '@/context/festiveTheme/festiveTheme'
import { ACCOUNT_ROUTES } from '@/routes'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import useWindowSize from '@/hooks/useWindowSize'

import Image from '@/components/elements/atoms/Image/Image'
import Text from '@/components/elements/atoms/Text'
import type { TextProps } from '@/components/elements/atoms/Text'
import { BREAKPOINTS } from '@/components/pages/App/App'

import STYLES from './Header.module.sass'

import AnimatedBoostLogo from '../../atoms/BoostLogo/AnimatedBoostLogo'
import { LeftAdornment } from './LeftAdornment'
import { LeftAdornmentProps } from './LeftAdornment/LeftAdornment'

type Variant = 'primary' | 'secondary' | 'tertiary'

type Props = {
  title: TextProps
  variant?: Variant
  subtitle?: TextProps
  leftAdornment?: LeftAdornmentProps
  rightAdornment?: JSX.Element | null
  backgroundColor?: 'brandYellow100' | 'brandYellow200' | 'brandYellow300'
  forceShowLogo?: boolean
  showBoostLogo?: boolean
}

/**
 * Header component
 *
 * Use this component to render a header with a title, subtitle, left and right adornments
 *
 * @example
  ```
  <Header
    title={{
      text: 'path.to.translation.key',
      ...otherOptionalTextProps
    }}
    variant="primary"
    subtitle={{
      text: 'path.to.translation.key',
      ...otherOptionalTextProps
    }}
    leftAdornment={{
      variant: 'icon',
      icon: CustomIcon,
      onPress: () => { // do something }
    }}
    rightAdornment={<RightAdornment />}
  />
  ```
 * @param {TextProps} title - Title text
 * @param {HeaderVariant} [variant] - Header variant
 * @param {TextProps} [subtitle] - Subtitle text
 * @param {LeftAdornmentIconVariant | LeftAdornmentCustomVariant} [leftAdornment] - Left adornment
 * @param {JSX.Element} [rightAdornment] - Right adornment
 * @param {boolean} [forceShowLogo] - Forces Butternut logo to display on mobile and desktop
 * @param {boolean} [showBoostLogo] - Show Butternut Boost logo
 * @returns {JSX.Element} - Header component
 * @category Components
 * @subcategory Molecules
 * @component Header
 */
const Header = ({
  variant = 'primary',
  title,
  subtitle,
  leftAdornment,
  rightAdornment,
  backgroundColor,
  forceShowLogo,
  showBoostLogo
}: Props): JSX.Element => {
  const { windowWidth } = useWindowSize()
  const { festiveClass } = useOccasion()
  const isMobile = windowWidth < BREAKPOINTS.sm
  const isSmallScreen = windowWidth < BREAKPOINTS.md

  const commonTitleVariantStyles = {
    variant: title && title.variant ? title.variant : 'display36',
    element: 'h1',
    margin: false
  } as const

  const headerVariantDefaultStyles = {
    primary: {
      title: {
        ...commonTitleVariantStyles,
        ...title,
        wonky: true
      },
      subtitle: {
        margin: false,
        ...subtitle
      },
      showLogo: true,
      balanceLayout: false,
      leftAdornment: undefined
    },
    secondary: {
      title: {
        ...commonTitleVariantStyles,
        variant: isMobile
          ? 'display24'
          : isSmallScreen
          ? 'display28'
          : 'display36',
        ...title
      },
      subtitle: {
        margin: false,
        ...subtitle
      },
      showLogo: false,
      balanceLayout: true,
      leftAdornment: {
        variant: 'icon'
      }
    },
    tertiary: {
      title: {
        ...commonTitleVariantStyles,
        ...title
      },
      subtitle: {
        margin: false,
        ...subtitle
      },
      showLogo: false,
      balanceLayout: true,
      leftAdornment: {
        variant: 'icon'
      }
    }
  } as const

  const {
    title: titleStyles,
    subtitle: subtitleStyles,
    showLogo,
    balanceLayout,
    leftAdornment: defaultLeftAdornment
  } = headerVariantDefaultStyles[variant]

  const leftAdornmentProps = leftAdornment ?? defaultLeftAdornment

  const showLogoOrForcedShowLogo = showLogo || forceShowLogo

  const { t } = useTranslation('account')

  const rightAdornmentGrid = rightAdornment
    ? {
        xs: 0,
        md: 2
      }
    : {
        xs: 0,
        md: 0
      }

  const leftAdornmentGrid = leftAdornmentProps
    ? {
        xs: 1,
        md: 1
      }
    : {
        xs: 0,
        md: 0
      }

  const logoGrid = showLogoOrForcedShowLogo
    ? {
        xs: 4,
        md: 2
      }
    : {
        xs: 0,
        md: 0
      }

  const titleGrid = {
    xs: 12 - rightAdornmentGrid.xs - leftAdornmentGrid.xs,
    md: 12 - logoGrid.md - rightAdornmentGrid.md - leftAdornmentGrid.md * 2 // calculated twice to keep it centered
  }

  const shouldRenderLogo = (!isSmallScreen && showLogo) || forceShowLogo

  return (
    <header
      className={`${STYLES.wrapper} ${STYLES[variant]} ${
        backgroundColor ? STYLES[backgroundColor] : ''
      } ${festiveClass ? STYLES[festiveClass] : ''}`}
    >
      <Container maxWidth="lg">
        <Grid
          container
          className={`${STYLES.container} ${
            balanceLayout ? STYLES.balanceLayout : ''
          }`}
          alignItems={isSmallScreen ? 'center' : 'flex-end'}
          wrap="nowrap"
        >
          {shouldRenderLogo && (
            <Grid
              item
              xs={logoGrid.xs}
              md={logoGrid.md}
              className={STYLES.logoWrapper}
            >
              <Link to={ACCOUNT_ROUTES.base} className={STYLES.logoLink}>
                <Image
                  className={STYLES.logo}
                  alt={t('navigation_logo_alt')}
                  slug="account-logo"
                  image={{
                    height: 157,
                    width: 194
                  }}
                />
              </Link>
            </Grid>
          )}

          {leftAdornmentProps && (
            <Grid
              item
              xs={leftAdornmentGrid.xs}
              md={leftAdornmentGrid.md}
              className={STYLES.leftAdornmentWrapper}
            >
              <LeftAdornment {...leftAdornmentProps} />
            </Grid>
          )}
          <Grid
            item
            xs={titleGrid.xs}
            md={titleGrid.md}
            className={STYLES.content}
          >
            <div className={STYLES.title}>
              {title && (
                <Text
                  {...titleStyles}
                  namespace={titleStyles.namespace ?? 'account'}
                  text={title.text}
                  align={isSmallScreen ? 'left' : 'center'}
                />
              )}
              {subtitle && !isSmallScreen && (
                <Text
                  {...subtitleStyles}
                  text={subtitle.text}
                  data-testid="sub-title"
                />
              )}
            </div>

            {showBoostLogo && (
              <div className={STYLES.boostLogo}>
                <AnimatedBoostLogo showSign maxWidth={150} />
              </div>
            )}
          </Grid>
          {rightAdornment && (
            <Grid
              item
              xs={rightAdornmentGrid.xs}
              md={rightAdornmentGrid.md}
              className={STYLES.rightAdornment}
              data-testid="right-adornment"
            >
              {rightAdornment}
            </Grid>
          )}
        </Grid>

        {subtitle && isSmallScreen && (
          <div className={STYLES.subtitle}>
            <Text
              {...subtitleStyles}
              text={subtitle.text}
              data-testid="sub-title"
            />
          </div>
        )}
      </Container>
    </header>
  )
}

export type { Props }

export { Header }
