// @flow

import ProgressDog from '../../../../assets/images/illustrations/dogs/progress-bar-dog.svg'
import SpeechBubble from '../../../../assets/images/illustrations/speech-bubbles/small-speech-bubble.svg'
import LeftArrow from '../../../../assets/images/icons/chevrons/blue-chevron.svg'
import AdultAvatar from '../../../../assets/images/illustrations/dogs/adult-dog-avatar.svg'
import PuppyAvatar from '../../../../assets/images/illustrations/dogs/puppy-dog-avatar.svg'
import PlusIconWhite from '../../../../assets/images/icons/plus-icon-white.svg'
import Arrow from '../../../../assets/images/icons/arrows/arrow--blue.svg'
import SpinningDog from '../../../../assets/images/illustrations/dogs/loading-dog.svg'
import Recycle from '../../../../assets/images/icons/icon-recycle.svg'
import StarStarburstFlashGroup from '../../../../assets/images/icons/ornaments/groups/star-starburst-flash-group.svg'

const ILLUSTRATIONS = {
  AdultAvatar,
  ProgressDog,
  PuppyAvatar,
  SpeechBubble,
  SpinningDog
}

const ICONS = {
  Arrow,
  LeftArrow,
  PlusIconWhite,
  Recycle
}

const ORNAMENTS = {
  StarStarburstFlashGroup,
}

export {
  ICONS,
  ILLUSTRATIONS,
  ORNAMENTS
}
