// @noflow
import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import BRAND_COLOURS from '@/constants/BrandColours'
import BREAKPOINTS from '@/constants/Breakpoints'
import SUPPORT_COLOURS from '@/constants/SupportColours'

import useWindowSize from '@/hooks/useWindowSize'

type Props = {
  baseColor?: string
  highlightColor?: string
  width?: string | number
  height?: string | number
  count?: number
}

const CameraActionSkeleton = ({
  baseColor = SUPPORT_COLOURS.grey200,
  highlightColor = BRAND_COLOURS.brandWhite,
  width,
  height,
  count = 1
}: Props): JSX.Element => {
  const { windowWidth } = useWindowSize()
  const skeletonSize = windowWidth < BREAKPOINTS.md ? 48 : 60

  return (
    <SkeletonTheme baseColor={baseColor} highlightColor={highlightColor}>
      <Skeleton
        count={count}
        width={width ?? skeletonSize}
        height={height ?? skeletonSize}
        style={{
          borderRadius: '10px'
        }}
      />
    </SkeletonTheme>
  )
}

export type { Props }

export { CameraActionSkeleton }
