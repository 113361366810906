import { gql } from '@apollo/client'

const PRE_WIZARD_GUEST_ITERABLE_UPSERT_MUTATION = gql`
  mutation PreWizardGuestIterableUpsertMutation($email: String!) {
    preWizardGuestIterableUpsert(email: $email) {
      trackingId
    }
  }
`

export { PRE_WIZARD_GUEST_ITERABLE_UPSERT_MUTATION }
