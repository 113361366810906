// @noflow
import React from 'react'

import STYLES from './StoryProgress.module.sass'

import type { Story } from '../../../Stories'
import ProgressBar from '../../atoms/ProgressBar'

type Props = {
  stories: Array<Story>
  currentIndex: number
  isStoryPaused: boolean
  storyDuration: number
}

const StoryProgress = ({
  stories,
  currentIndex,
  isStoryPaused,
  storyDuration
}: Props): JSX.Element => (
  <div className={STYLES.wrapper}>
    {stories.map(({ id }, index) => (
      <ProgressBar
        key={id}
        index={index}
        currentIndex={currentIndex}
        isStoryPaused={isStoryPaused}
        storyDuration={storyDuration}
      />
    ))}
  </div>
)

export default StoryProgress
