// @noflow
import classnames from 'classnames'
import type { i18n } from 'i18next'
import React, { useCallback, useState } from 'react'

import segmentTrack from '@/components/analytics/Analytics'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './FeedbackTab.module.sass'

type Props = {
  onClick: () => void
  i18n: i18n
}

/**
 * Feedback tab - creates a fixed position tab that opens a feedback form on click
 * Note: i18n is passed manually to FeedbackTab so it can be used in bundles outside of our main bundle.
 *       Referencing i18n means our translation files will be included in the final bundle bloating its size.
 */
const FeedbackTab = ({ onClick, i18n }: Props): JSX.Element => {
  const [feedbackTabClicked, setFeedbackTabClicked] = useState<boolean>(false)

  const onTabClick = useCallback(() => {
    if (!feedbackTabClicked) {
      onClick()
      segmentTrack('Feedback tab clicked')
      setFeedbackTabClicked(true)
    }
  }, [onClick, feedbackTabClicked])

  const className = classnames(STYLES.tab, {
    [STYLES.hide]: feedbackTabClicked
  })

  return (
    <button className={className} type="button" onClick={onTabClick}>
      <div className={STYLES.label}>
        <Text
          variant="textRegular12"
          colour="brandWhite"
          text={i18n.t('atoms:feedback_tab.label')}
          margin={false}
          translate={false}
          element="div"
        />
      </div>
    </button>
  )
}

export default FeedbackTab
