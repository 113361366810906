// @noflow
import React, { useCallback, useEffect, useState } from 'react'

import Card from '@/components/elements/atoms/Card/Card'
import Text from '@/components/elements/atoms/Text/Text'
import StarRating from '@/components/elements/molecules/StarRating/StarRating'
import StickyNavigation from '@/components/elements/organisms/StickyNavigation/StickyNavigation'
import CloudinaryImage from '@/components/shared/elements/CloudinaryImage/CloudinaryImage'
// eslint-disable-next-line no-restricted-imports
import { Answer, Option, Question } from '@/components/types'

import STYLES from './RatingQuestion.module.sass'

type Props = {
  question: Question
  onAnswer: (answers: Array<Answer>) => void
}

type OptionProps = {
  index: number
  onRating: (index: number, optionId: number, rating: number) => void
  option: Option
}

const OptionRow = ({ index, onRating, option }: OptionProps) => {
  const handleSelect = useCallback(
    (rating) => {
      onRating(index, option.id, rating)
    },
    [onRating, option, index]
  )

  return (
    <Card>
      <div className={STYLES.option}>
        {option.image && (
          <CloudinaryImage
            alt={option.text}
            image={{
              path: option.image.src,
              crop: 'fill',
              dpr: window.devicePixelRatio
            }}
          />
        )}
        <div>
          <Text
            margin={false}
            text={option.text}
            variant="display20"
            colour="brandBlue500"
            translate={false}
          />
          <StarRating selectable onSelect={handleSelect} />
        </div>
      </div>
    </Card>
  )
}

const RatingQuestion = ({ question, onAnswer }: Props): JSX.Element => {
  const [answers, setAnswers] = useState<Array<Answer>>([])
  const [finished, setFinished] = useState<boolean>(false)
  const [submitted, setSubmitted] = useState<boolean>(false)

  const handleRating = useCallback(
    (index: number, optionId: number, rating: number) => {
      answers[index] = {
        optionId,
        questionId: question.id,
        value: rating
      }
      setAnswers([...answers])
      setSubmitted(true)
    },
    [answers, setAnswers, question]
  )

  const handleComplete = useCallback(
    (e) => {
      // we don't want the link to change the url
      e.preventDefault()
      onAnswer(answers)
    },
    [onAnswer, answers]
  )

  const options = question.options.map((option: Option, index: number) => {
    return (
      <OptionRow
        index={index}
        key={option.id}
        option={option}
        onRating={handleRating}
      />
    )
  })

  useEffect(() => {
    // This component is supposed to be driven by CMS'd data, and not specific
    // to individual use cases, however, it is used very specifically for the
    // onboarding quiz. This is a temporary fix to make sure that in the
    // onboarding quiz, only one recipe rating is required, and in other usages,
    // all ratings are required.
    if (
      question.questionType === 'dynamic_recipe_rating' &&
      Object.keys(answers).length >= 1 &&
      !finished
    ) {
      setFinished(true)
    } else if (Object.keys(answers).length >= options.length && !finished) {
      setFinished(true)
    }
  }, [setFinished, answers, question, options, finished])

  return (
    <div>
      <div className={STYLES.options}>{options}</div>
      <StickyNavigation
        disabled={!finished || !submitted}
        buttonOne={{
          url: '/',
          text: 'confirm',
          namespace: 'surveys',
          variant: 'primary',
          iconColour: 'brandWhite',
          onClick: handleComplete
        }}
      />
    </div>
  )
}

export { Props }
export default RatingQuestion
