// @noflow
import type { Language } from '@/packs/localisation'
import initI18n from '@/packs/localisation'
import { makeVar, useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { Elements } from '@stripe/react-stripe-js'
import { Stripe, loadStripe } from '@stripe/stripe-js'
import CryptoJS from 'crypto-js'
import { addMinutes, formatISO, isSaturday, parseISO } from 'date-fns'
import Cookies from 'js-cookie'
import React, { useCallback, useEffect, useState } from 'react'

// Utils
import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { localeToStripeLocale } from '@/utils/localeToStripeLocale'
import * as Sentry from '@/utils/sentry'

import { invalidatePasswordField } from './components/InputField/PasswordInputField'
// Components
import LoadingCheckoutPage from './components/LoadingCheckoutPage/LoadingCheckoutPage'
import NextDayDeliveryCountdown from './components/NextDayDeliveryCountdown/NextDayDeliveryCountdown'
import PlaceMyOrderDisclaimer from './components/PaymentSection/PlaceMyOrderDisclaimer'
import withApollo from '@/components/apollo/withApollo'
import Text from '@/components/elements/atoms/Text/Text'
import TopNavBanner from '@/components/elements/molecules/TopNavBanner/TopNavBanner'
import WizardNavigation from '@/components/elements/organisms/WizardNavigation/WizardNavigation'
import AccountSection from '@/components/pages/CheckoutPage/components/AccountSection/AccountSection'
import DeliveryAddressSection from '@/components/pages/CheckoutPage/components/DeliveryAddressSection/DeliveryAddressSection'
import DeliveryDateSection from '@/components/pages/CheckoutPage/components/DeliveryDateSection/DeliveryDateSection'
import OrderSummary from '@/components/pages/CheckoutPage/components/OrderSummary/OrderSummary'
import PaymentSection from '@/components/pages/CheckoutPage/components/PaymentSection/PaymentSection'
import StripePaymentElement from '@/components/pages/CheckoutPage/components/PaymentSection/StripePaymentElement'
import Trustpilot from '@/components/pages/CheckoutPage/components/Trustpilot/Trustpilot'
import {
  currentRouteToCurrentStep,
  currentRouteToPercentFilled,
  currentRouteToTotalStep
} from '@/components/pages/SimplifiedPlansPage/helpers/plansNavigation'
import { Routes as PlansRoute } from '@/components/pages/SimplifiedPlansPage/types/routes'

// Queries
import { CHECKOUT_PAGE } from './queries/checkoutPageQuery'
import { LOGIN_USER } from './queries/loginUser'
import { USER_TYPE_QUERY } from './queries/userTypeQuery'

import type { CheckoutPage as CheckoutPageQuery } from './queries/__generated__/CheckoutPage'
import type {
  LoginUser,
  LoginUserVariables
} from './queries/__generated__/LoginUser'
import type { UserTypeQuery } from './queries/__generated__/UserTypeQuery'
import {
  Affiliate,
  Code as CountryCode,
  DiscountCodePartDiscount,
  DiscountCodePartDiscountBasis
} from '@/types'

import * as ANALYTICS from './Analytics/CheckoutAnalytics'
import {
  dummyCityForShippingCountry,
  dummyPostcodeForShippingCountry
} from './helpers/graphql'
import { useCheckoutPricing } from './hooks/useCheckoutPricing'
import useCountdownBanner from './hooks/useCountdownBanner'
import useDeliveryDatesQuery from './hooks/useDeliveryDatesQuery'
import type { CheckoutState, PaymentMethod } from './types'

type Props = {
  stripeKey: string
  csrfToken: string
  language: Language
  paypalClientId: string
  hasRecommendedExtras: boolean
  shouldSeeCourierSelection: boolean
  shouldSeeDeliveryNotes: boolean
  planId: number
  shouldSeePetParentOnPlans: boolean
}

const locationFromWizardLocalStorageKey = 'wizardState'
const wizardState = window.localStorage.getItem(
  locationFromWizardLocalStorageKey
)

const initialState: CheckoutState = {
  guestId: null,
  user: {
    preferredLanguage: 'en',
    dogs: [],
    numberOfFreeTrialBoxes: 0,
    requiresPaymentDetailsOnCheckout: true,
    affiliateType: Affiliate.not_affiliate,
    deliveryFee: 0,
    discount: null,
    shippingCountryCode: CountryCode.GB,
    firstDeliverableDate: {
      date: formatISO(new Date()),
      endOfLeadTime: formatISO(new Date())
    },
    shippingCountryCodeId: 0,
    shouldSupportSca: true
  },
  plan: {
    planId: 0,
    extraProducts: [],
    trialLengthInDays: 0,
    durationInDays: 0,
    pouchesPerDay: 0,
    flavours: []
  },
  sections: {
    accountDetails: {
      visible: true,
      valid: false,
      form: {
        name: {
          errorMessage: null,
          value: '',
          inputInteractionState: 'NotInteractingNotInteracted'
        },
        email: {
          errorMessage: null,
          value: '',
          inputInteractionState: 'NotInteractingNotInteracted'
        },
        password: {
          errorMessage: null,
          value: '',
          inputInteractionState: 'NotInteractingNotInteracted'
        }
      }
    },
    addressDetails: {
      visible: false,
      valid: false,
      form: {
        searchTerm: {
          errorMessage: null,
          value: '',
          inputInteractionState: 'NotInteractingNotInteracted'
        },
        addressLine1: {
          errorMessage: null,
          value: '',
          inputInteractionState: 'NotInteractingNotInteracted'
        },
        addressLine2: {
          errorMessage: null,
          value: '',
          inputInteractionState: 'NotInteractingNotInteracted'
        },
        city: {
          errorMessage: null,
          value: '',
          inputInteractionState: 'NotInteractingNotInteracted'
        },
        postcode: {
          errorMessage: null,
          value: '',
          inputInteractionState: 'NotInteractingNotInteracted'
        }
      }
    },
    deliveryDetails: {
      visible: false,
      valid: false,
      form: {
        selectedDeliveryDate: {
          errorMessage: null,
          value: formatISO(new Date()),
          endOfLeadTime: formatISO(new Date()),
          selectedDeliveryArea: null,
          inputInteractionState: 'NotInteractingNotInteracted'
        },
        deliveryNotes: {
          errorMessage: null,
          value: '',
          inputInteractionState: 'NotInteractingNotInteracted'
        },
        mobileNumber: {
          errorMessage: null,
          value: '',
          inputInteractionState: 'NotInteractingNotInteracted'
        }
      }
    },
    paymentDetails: {
      visible: false,
      valid: false,
      form: {
        selectedPaymentMethod: {
          complete: false,
          type: 'creditCard',
          preselected: true
        }
      }
    }
  }
}

const getGuestId = (): string => {
  const guestIdKey = 'guest_id'
  return Cookies.get(guestIdKey) || ''
}

// Retrieve any saved State and State version from localStorage
const latestCheckoutVersion = '1.8.1'

const checkoutStorageState = localStorage.getItem('checkout_state')
const checkoutStorageStateVersion = localStorage.getItem('checkout_version')
const isSupportedCheckoutVersion =
  checkoutStorageStateVersion === latestCheckoutVersion

// If the checkoutState item exists in LocalStorage, parse it as JSON
// Otherwise, return the default initalState object
const shouldParseLocalStorage =
  checkoutStorageState && isSupportedCheckoutVersion
const parsedState: CheckoutState = shouldParseLocalStorage
  ? JSON.parse(checkoutStorageState)
  : initialState
const guestId = getGuestId()

const validateGuestId = (): void => {
  if (!guestId) {
    Sentry.captureException(`Cannot get user's guest id`, {
      tags: {
        product: Sentry.Product.Checkout
      }
    })
    window.location.href = '/wizard/new'
    return
  }
  if (guestId === parsedState.guestId) {
    const parsedPassword = CryptoJS.AES.decrypt(
      parsedState.sections.accountDetails.form.password.value,
      guestId
    )
    if (!parsedPassword) {
      invalidatePasswordField(parsedState.sections.accountDetails.form.password)
    }
    const decryptedPassword = parsedPassword.toString(CryptoJS.enc.Utf8)

    parsedState.sections.accountDetails.form.password.value = decryptedPassword
  } else {
    invalidatePasswordField(parsedState.sections.accountDetails.form.password)
  }
}

const userState = makeVar(parsedState.user)
const firstDeliverableDateState = makeVar(parsedState.user.firstDeliverableDate)
const planState = makeVar(parsedState.plan)
const sectionsState = makeVar(parsedState.sections)
const checkoutPageState = makeVar(parsedState)

const getPersistedPostcode = (): {
  country: string
  postcode: string
} | null => {
  if (wizardState) {
    const { user } = JSON.parse(wizardState)
    const { location } = user

    return location
  } else return null
}

const CheckoutPageView = ({
  namespace,
  data,
  csrfToken,
  stripePromise,
  language,
  paypalClientId,
  hasRecommendedExtras,
  shouldSeeCourierSelection,
  stripeKey,
  shouldSeePetParentOnPlans,
  shouldSeeDeliveryNotes
}: {
  namespace: string
  data: CheckoutPageQuery
  csrfToken: Props['csrfToken']
  stripePromise: Promise<Stripe | null>
  language: Language
  paypalClientId: Props['paypalClientId']
  hasRecommendedExtras: boolean
  shouldSeeCourierSelection: boolean
  shouldSeeDeliveryNotes: boolean
  stripeKey: Props['stripeKey']
  shouldSeePetParentOnPlans: boolean
}) => {
  // If the user is flagged from self-referral, we delete their checkout_state
  // so they restart checkout on a fresh state and set the flag back to false.
  if (sessionStorage.getItem('self-referral-flagged') === 'true') {
    localStorage.removeItem('checkout_state')
    sessionStorage.setItem('self-referral-flagged', 'false')
  }

  useEffect(() => {
    if (shouldParseLocalStorage) {
      validateGuestId()
    }

    if (!isSupportedCheckoutVersion) {
      localStorage.setItem('checkout_version', latestCheckoutVersion)
      // If the user has visited checkout before, we want to clear what they had and set them to the initialState of the new version
      checkoutPageState({ ...initialState })
    }
  }, [])

  const {
    guest,
    shippingCountries,
    shouldSeeApplePay,
    shouldSeeGooglePay,
    shouldSeeFetchifyOnCheckout,
    shouldSeeAltCountdown,
    shouldSeeTabbedStripeCheckout,
    shouldSeeUpdatedCheckoutDesign,
    shouldSeeExpressPayOnCheckout,
    shouldSeePayPal
  } = data

  const {
    assumedShippingCountry,
    numberOfAffiliateFreeBoxes,
    requiresPaymentDetailsOnCheckout,
    pendingSubscription,
    affiliateType
  } = guest

  const { code: assumedShippingCountryCode } = assumedShippingCountry

  const checkoutState = useReactiveVar(checkoutPageState)

  const { user, plan, sections } = checkoutState
  const { accountDetails, paymentDetails } = sections

  const { showCountdownBanner } = useCountdownBanner()

  const { fetchDeliveryDates } = useDeliveryDatesQuery()

  /**
   * The following lines deal with the scenario whereby a Dutch customer, paying
   * with IDEAL. In these scenarios, customers get brought to a new tab/browser
   * instance and confirm the payment there. Sometimes, their bank does not
   * redirect them back to the our page/the customer closes the payment
   * confirmation page before the redirect and upon returning to our site the
   * customer is left in a state where they are at checkout and do not proceed
   * to the Thank You page.
   *
   * The solution here is to start querying the currentUser#__typename field
   * as when this returns User, as opposed to the other options, we know that
   * the subscription has been created. We do this by polling when the card
   * payment details are visible and valid:
   *
   * Start polling every 3 seconds
   * After 3 minutes, start polling every 1.5 second
   * After 5 more minutes, stop polling
   *
   * Note, we do not explicitly handle errors here. Error handling on checkout
   * remains unchanged and all we are doing here is polling the server in a
   * very specific scenario
   */

  const {
    loading: pollingLoad,
    data: pollingData,
    stopPolling,
    startPolling
  } = useQuery<UserTypeQuery>(USER_TYPE_QUERY)

  const { locale } = countryCodeToLocaleCurrency(
    assumedShippingCountry.code,
    language
  )

  const [loginUser] = useMutation<LoginUser, LoginUserVariables>(LOGIN_USER, {
    variables: {
      email: accountDetails.form.email.value,
      password: accountDetails.form.password.value
    },
    // eslint-disable-next-line no-return-assign
    onCompleted: (data) =>
      data.signInAuthorisedSubscribedCheckoutUser?.token
        ? (window.location.href =
            data.signInAuthorisedSubscribedCheckoutUser?.token)
        : null,
    onError: (error) => {
      Sentry.captureException(`Error with LOGIN_USER mutation`, {
        extra: { error },
        tags: {
          product: Sentry.Product.Checkout
        }
      })
    }
  })

  useEffect(() => {
    const paymentMethodsThatRequireWebhook: Array<PaymentMethod> = [
      'iDeal',
      'bancontact'
    ]

    const shouldStartPolling =
      paymentDetails.visible &&
      paymentDetails.valid &&
      paymentMethodsThatRequireWebhook.some(
        (pm) => pm === paymentDetails.form.selectedPaymentMethod.type
      )

    if (shouldStartPolling) {
      const timeout = setTimeout(() => {
        stopPolling()
        startPolling(1.5 * 1000) // 1.5 seconds
        setTimeout(() => {
          stopPolling()
        }, 5 * 1000 * 60) // 5 minutes
      }, 3 * 1000 * 60) // 3 minutes

      startPolling(3 * 1000) // 3 seconds

      if (!pollingLoad && pollingData?.currentUser?.__typename !== 'Guest') {
        stopPolling()
        clearTimeout(timeout)
        loginUser()
      }
    }
  }, [
    pollingLoad,
    pollingData?.currentUser?.__typename,
    paymentDetails.visible,
    paymentDetails.form.selectedPaymentMethod.type,
    paymentDetails.valid,
    loginUser,
    startPolling,
    stopPolling
  ])

  useEffect(() => {
    fetchDeliveryDates({
      variables: {
        postcode:
          getPersistedPostcode()?.postcode ||
          dummyPostcodeForShippingCountry(assumedShippingCountryCode),
        city: dummyCityForShippingCountry(assumedShippingCountryCode)
      }
    })

    // We're seeing an issue where some PsiBufet users are getting a default
    // delivery date of Saturday, which shouldn't be the case so we're adding
    // an analytics event to track this and monitor further
    const parsedDate = parseISO(
      sections.deliveryDetails.form.selectedDeliveryDate.value
    )
    const isSaturdayDate = isSaturday(parsedDate)

    if (assumedShippingCountryCode === 'PL' && isSaturdayDate) {
      ANALYTICS.defaultDeliveryDateSaturday(
        sections.deliveryDetails.form.selectedDeliveryDate.value
      )
    }
  }, [
    assumedShippingCountryCode,
    fetchDeliveryDates,
    sections.deliveryDetails.form.selectedDeliveryDate
  ])

  useEffect(() => {
    // Set our initial values from GraphQL response
    user.preferredLanguage = language
    user.dogs = guest.dogs
    user.discount =
      numberOfAffiliateFreeBoxes === 0
        ? guest.discountCode
        : {
            __typename: 'DiscountCode',
            id: '1',
            code: 'Discount',
            discountBasis: 'percentage' as DiscountCodePartDiscountBasis,
            value: 100,
            discountCodeParts: [
              {
                __typename: 'DiscountCodePart',
                discountType: 'single_use' as DiscountCodePartDiscount,
                discountBasis: 'percentage' as DiscountCodePartDiscountBasis,
                value: 100,
                n: 1
              }
            ]
          }
    user.numberOfFreeTrialBoxes = numberOfAffiliateFreeBoxes
    user.requiresPaymentDetailsOnCheckout = requiresPaymentDetailsOnCheckout
    user.shippingCountryCode = assumedShippingCountry.code
    user.shippingCountryCodeId = parseInt(assumedShippingCountry.id)
    user.shouldSupportSca = assumedShippingCountry.scaEnforced
    plan.extraProducts = pendingSubscription.productVariants
    plan.planId = parseInt(pendingSubscription.plan.id)
    plan.trialLengthInDays = pendingSubscription.plan.trial.lengthInDays
    plan.pouchesPerDay = pendingSubscription.plan.pouchesPerDay
    plan.durationInDays = pendingSubscription.plan.durationInDays
    plan.flavours = pendingSubscription.pendingSubscriptionFlavours

    const userEmail = accountDetails.form.email.value
    if (!userEmail && guest.email) {
      accountDetails.form.email.value = guest.email
    }
    const userName = accountDetails.form.name.value
    if (!shouldSeePetParentOnPlans && !userName && guest.firstName) {
      accountDetails.form.name.value = `${guest.firstName} ${
        guest.lastName || ''
      }`
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Ensure we update localStorage every time our Apollo state changes
  useEffect(() => {
    const encryptedPasswordState = Object.freeze({
      ...checkoutState,
      guestId: getGuestId(),
      sections: {
        ...checkoutState.sections,
        accountDetails: {
          ...checkoutState.sections.accountDetails,
          form: {
            ...checkoutState.sections.accountDetails.form,
            password: {
              ...checkoutState.sections.accountDetails.form.password,
              value:
                checkoutState.sections.accountDetails.form.password.value
                  .length > 0
                  ? CryptoJS.AES.encrypt(
                      checkoutState.sections.accountDetails.form.password.value,
                      guestId
                    ).toString()
                  : ''
            }
          }
        }
      }
    })
    localStorage.setItem(
      'checkout_state',
      JSON.stringify(encryptedPasswordState)
    )
  }, [checkoutState])

  const handleBackButton = useCallback(() => {
    window.location.href = hasRecommendedExtras
      ? PlansRoute.Extras
      : PlansRoute.Plan
  }, [hasRecommendedExtras])

  return (
    <React.Fragment>
      <TopNavBanner
        shippingCountryCode={user.shippingCountryCode}
        preferredLanguage={user.preferredLanguage}
      />
      <div className="checkout-wrapper">
        <div className="checkout__progress-bar-wrapper">
          <WizardNavigation
            variant="simplified"
            percentFilled={currentRouteToPercentFilled({
              route: PlansRoute.Checkout,
              hasRecommendedExtras,
              shouldSeePetParentOnPlans
            })}
            currentStep={currentRouteToCurrentStep({
              route: PlansRoute.Checkout,
              hasRecommendedExtras,
              shouldSeePetParentOnPlans
            })}
            totalSteps={currentRouteToTotalStep(
              hasRecommendedExtras,
              shouldSeePetParentOnPlans
            )}
            backOnClick={handleBackButton}
          />
        </div>
        <Text
          namespace={namespace}
          text="title"
          element="h1"
          variant="display28"
          margin={false}
        />
        {shouldSeeAltCountdown === 'true' ? (
          <NextDayDeliveryCountdown
            namespace={namespace}
            endOfLeadTime={
              showCountdownBanner
                ? user.firstDeliverableDate.endOfLeadTime
                : formatISO(addMinutes(new Date(), 30)) // 30 minutes from now
            }
            showAltCountdown={!showCountdownBanner}
          />
        ) : showCountdownBanner ? (
          <NextDayDeliveryCountdown
            namespace={namespace}
            endOfLeadTime={user.firstDeliverableDate.endOfLeadTime}
          />
        ) : null}
        <div className="content-wrapper">
          <div className="order-summary-wrapper">
            <OrderSummary
              namespace={namespace}
              shouldDisplayPrice={numberOfAffiliateFreeBoxes < 1}
              showUpdatedCheckoutDesign={
                shouldSeeUpdatedCheckoutDesign === 'variant'
              }
            />
          </div>

          <form className="form-wrapper">
            <AccountSection
              namespace={namespace}
              shippingCountryCode={assumedShippingCountry.code}
              shouldSeeExpressPayOnCheckout={
                shouldSeeExpressPayOnCheckout === 'variant'
              }
              stripePromise={stripePromise}
              stripeKey={stripeKey}
              language={language}
              currency={assumedShippingCountry.currency}
              csrfToken={csrfToken}
            />
            <DeliveryAddressSection
              namespace={namespace}
              activeShippingCountries={shippingCountries}
              shippingCountryCode={assumedShippingCountryCode}
              useFetchify={shouldSeeFetchifyOnCheckout === 'true'}
            />
            <DeliveryDateSection
              namespace={namespace}
              preferredLanguage={language}
              shippingCountryCode={assumedShippingCountry.code}
              shouldSeeCourierSelection={shouldSeeCourierSelection}
              shouldSeeDeliveryNotes={shouldSeeDeliveryNotes}
            />
            {shouldSeeTabbedStripeCheckout === 'variant' ? (
              <StripePaymentElement
                stripePromise={stripePromise}
                stripeKey={stripeKey}
                language={language}
                namespace={namespace}
                affiliateType={affiliateType}
                shippingCountryCode={assumedShippingCountry.code}
                csrfToken={csrfToken}
                shouldSeeApplePay={shouldSeeApplePay === 'true'}
                shouldSeeGooglePay={shouldSeeGooglePay === 'true'}
                shouldSeePayPal={shouldSeePayPal === 'variant'}
                paypalClientId={paypalClientId}
                currency={assumedShippingCountry.currency}
                locale={locale}
              />
            ) : (
              <Elements
                stripe={stripePromise}
                key={stripeKey}
                options={{
                  locale: localeToStripeLocale(language)
                }}
              >
                <PaymentSection
                  namespace={namespace}
                  shippingCountryCode={assumedShippingCountry.code}
                  affiliateType={affiliateType}
                  csrfToken={csrfToken}
                  shouldSeeApplePay={shouldSeeApplePay === 'true'}
                  shouldSeeGooglePay={shouldSeeGooglePay === 'true'}
                  shouldSeePayPal={shouldSeePayPal === 'variant'}
                  paypalClientId={paypalClientId}
                  currency={assumedShippingCountry.currency}
                  locale={locale}
                />
              </Elements>
            )}
          </form>
          <div className="trust-wrapper">
            {!!assumedShippingCountry.trustpilotBusinessUnitId && (
              <Trustpilot
                businessUnitId={assumedShippingCountry.trustpilotBusinessUnitId}
              />
            )}
          </div>
        </div>
        <footer>
          {shouldSeeExpressPayOnCheckout === 'variant' && (
            <PlaceMyOrderDisclaimer
              namespace={namespace}
              shippingCountryCode={assumedShippingCountry.code}
            />
          )}
          <Text
            namespace={namespace}
            text="copyright"
            variant="textRegular18"
            variables={{
              fullDate: new Date().getFullYear()
            }}
          />
        </footer>
      </div>
    </React.Fragment>
  )
}

const CheckoutPage = ({
  stripeKey,
  csrfToken,
  language,
  paypalClientId,
  hasRecommendedExtras,
  shouldSeeCourierSelection,
  planId,
  shouldSeePetParentOnPlans,
  shouldSeeDeliveryNotes
}: Props): JSX.Element => {
  const [stripePromise] = useState(
    loadStripe(stripeKey, { locale: localeToStripeLocale(language) })
  )

  // Query the Checkout pricing data and set the values
  // in an Apollo Reactive Variable
  const { checkoutPricingLoading } = useCheckoutPricing({ planId })

  const { loading, data, error } = useQuery<CheckoutPageQuery>(CHECKOUT_PAGE, {
    variables: { planId }
  })

  if (error)
    Sentry.captureException(`Error occurred in CHECKOUT_PAGE query`, {
      extra: { error },
      tags: {
        product: Sentry.Product.Checkout
      }
    })

  const namespace = 'checkout'

  const checkoutState = useReactiveVar(checkoutPageState)

  const { user } = checkoutState

  initI18n(language)

  if (loading || !data || checkoutPricingLoading) {
    return (
      <>
        <TopNavBanner
          shippingCountryCode={user.shippingCountryCode}
          preferredLanguage={user.preferredLanguage}
        />
        <LoadingCheckoutPage
          hasRecommendedExtras={hasRecommendedExtras}
          shouldSeeCourierSelection={shouldSeeCourierSelection}
          shouldSeePetParentOnPlans={shouldSeePetParentOnPlans}
        />
      </>
    )
  }

  return (
    <CheckoutPageView
      namespace={namespace}
      data={data}
      csrfToken={csrfToken}
      stripePromise={stripePromise}
      language={language}
      paypalClientId={paypalClientId}
      hasRecommendedExtras={hasRecommendedExtras}
      shouldSeeCourierSelection={shouldSeeCourierSelection}
      shouldSeeDeliveryNotes={shouldSeeDeliveryNotes}
      stripeKey={stripeKey}
      shouldSeePetParentOnPlans={shouldSeePetParentOnPlans}
    />
  )
}

export {
  checkoutPageState,
  userState,
  firstDeliverableDateState,
  planState,
  sectionsState,
  initialState
}

export type { Props }
export { CheckoutPage }

export default withApollo(CheckoutPage)
