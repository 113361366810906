// @no-flow
import { PaymentMethodable, Provider } from '@/types'

import { State } from '../../PayOnOwnDevicePage'
import formatSubscriptionData from '../../helpers/buiildSubscriptionData'
import { LoginUrlProps } from '../../screens/PaymentScreen/PaymentScreen'
import { submitSubscription } from '../submitSubscription'

const buySubscriptionWithPayPal = ({
  token,
  data,
  navigateToNextStep
}: {
  token: string
  data: State
  navigateToNextStep: (input: LoginUrlProps) => void
}): void => {
  const formattedSubData = formatSubscriptionData({
    targetFirstDeliveryDate: data.targetFirstDeliveryDate,
    subscriptionData: data.data,
    provider: Provider.paypal,
    paymentMethodType: PaymentMethodable.BillingAgreement,
    paymentIntentId: '',
    supportedMarketingMethodPurposes: data.marketingPreferences
  })

  submitSubscription({
    data: formattedSubData,
    paymentMethodId: token,
    navigateToNextStep
  })
}

export default buySubscriptionWithPayPal
