import React from 'react'
import { useTranslation } from 'react-i18next'

// Components
import Image from '@/components/elements/atoms/Image/Image'

import STYLES from './BoostLogo.module.sass'

enum Size {
  small = 'small',
  medium = 'medium',
  large = 'large',
  xlarge = 'xlarge',
  xxlarge = 'xxlarge'
}

type Props = {
  size?: Size
}

const BoostLogo = ({ size = Size.small }: Props): JSX.Element => {
  const { t } = useTranslation('atoms')
  const generateLogoSize = () => {
    switch (size) {
      case Size.small: {
        return {
          width: 33,
          height: 14
        }
      }
      case Size.medium: {
        return {
          width: 48,
          height: 20
        }
      }
      case Size.large: {
        return {
          width: 74,
          height: 30
        }
      }
      case Size.xlarge: {
        return {
          width: 97,
          height: 38
        }
      }
      case Size.xxlarge: {
        return {
          width: 108,
          height: 42
        }
      }
      default: {
        return {
          width: 33,
          height: 14
        }
      }
    }
  }

  const logoSize = generateLogoSize()
  return (
    <div className={STYLES.wrapper}>
      <Image
        alt={t('boost_logo.alt')}
        slug="boost-logo"
        // eslint-disable-next-line prettier/prettier
        image={{ ...logoSize }}
      />
    </div>
  )
}

export { Size as BoostLogoSizes }
export default BoostLogo
