import { gql } from '@apollo/client'

const DOG_PERSONALITY_QUERY = gql`
  query DogPersonalityQuery {
    user {
      id
      dogs {
        id
        name
        gender
        dogProfile {
          id
          dogActivities {
            activity
          }
          dogPersonalityTraits {
            trait
          }
          favouriteGame
        }
      }
    }
  }
`

export { DOG_PERSONALITY_QUERY }
