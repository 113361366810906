// @noflow
import type { DirectSalesPendingSubscriptionOrderPrices_pendingSubscriptionOrderPrices as FirstOrderPricing } from '../../../queries/__generated__/DirectSalesPendingSubscriptionOrderPrices'

type Translate = (arg0: string) => string

const paymentRequestDisplayItems = ({
  firstOrderPricing,
  t
}: {
  firstOrderPricing: FirstOrderPricing
  t: Translate
}): Array<{ amount: number; label: string; pending: boolean }> => {
  const copyContext =
    'plan_steps.payment.payment_options.payment_request_display_items'
  const displayItems = [
    {
      amount: firstOrderPricing.grossTotalPrice,
      label: t(`${copyContext}.box_price`),
      pending: false
    },
    {
      amount: firstOrderPricing.netDeliverySurchargePrice,
      label: t(`${copyContext}.delivery_fee`),
      pending: false
    }
  ]
  if (firstOrderPricing.totalDiscountedAmount > 0) {
    const discount = {
      amount: -firstOrderPricing.totalDiscountedAmount,
      label: t(`${copyContext}.discount`),
      pending: false
    }
    displayItems.push(discount)
  }
  return displayItems
}

export default paymentRequestDisplayItems
