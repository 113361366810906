// @noflow
import React, { ReactNode } from 'react'

import CloudinaryImage from '@/components/elements/atoms/CloudinaryImage/CloudinaryImage'
import type { Props as CloudinaryProps } from '@/components/elements/atoms/CloudinaryImage/CloudinaryImage'
import type { Props as ImageProps } from '@/components/elements/atoms/Image/Image'
import Image from '@/components/elements/atoms/Image/Image'
import Text from '@/components/elements/atoms/Text'
import { Props as TextProps } from '@/components/elements/atoms/Text/Text'
import ActionCard, {
  ActionCardProps,
  ClickableActionCardProps,
  NonClickableActionCardProps
} from '@/components/elements/molecules/ActionCard/ActionCard'

// Styles
import STYLES from './ImageCard.module.sass'

type CardProps = Omit<ActionCardProps, 'heading'> & {
  position: 'top' | 'left'
  heading?: TextProps
  padding: 0 | 16
  children: ReactNode
  disablePaddedContent?: boolean
}

type ClickableCloudinaryCardProps = CardProps &
  CloudinaryProps &
  ClickableActionCardProps
type NonClickableCloudinaryCardProps = CardProps &
  CloudinaryProps &
  NonClickableActionCardProps

type CloudinaryCardProps =
  | ClickableCloudinaryCardProps
  | NonClickableCloudinaryCardProps

type ClickableImageCardProps = CardProps &
  Omit<ImageProps, 'className' | 'padding'> &
  ClickableActionCardProps
type NonClickableImageCardProps = CardProps &
  Omit<ImageProps, 'className' | 'padding'> &
  NonClickableActionCardProps

type Props = ClickableImageCardProps | NonClickableImageCardProps

/**
 *
 * @param slug - the image slug
 * @param alt - a description of the image
 * @param image - the image parameters - see the image component for more details
 * @param action - an optional action
 * @param indent - the indentation of the action, if set the action will use position absolute
 * @param position - the position of the image
 * @param heading
 * @param children
 * @param card - properties of the card component
 * @param disablePaddedContent - if true, the content will not be padded
 * @constructor
 */

const CloudinaryImageCard = ({
  alt,
  image,
  action,
  indent,
  position = 'top',
  heading,
  children,
  disablePaddedContent = false,
  ...card
}: CloudinaryCardProps): JSX.Element => {
  return (
    <ActionCard
      {...card}
      action={action}
      indent={position === 'top' ? (card.padding === 0 ? 16 : 24) : indent}
    >
      <div
        className={`
          ${STYLES.container}
          ${STYLES[position]}
        `}
      >
        <CloudinaryImage
          alt={alt}
          image={image}
          className={`
            ${STYLES.image}
          `}
        />
        <div
          className={`
            ${STYLES.content}
            ${card.padding === 0 && !disablePaddedContent ? STYLES.padded : ''}
          `}
        >
          {heading && (
            <div className={STYLES.heading}>
              <Text
                element="div"
                {...heading}
                variant={heading.variant ?? 'display20'}
              />
            </div>
          )}
          {children}
        </div>
      </div>
    </ActionCard>
  )
}
const ImageCard = ({
  slug,
  alt,
  image,
  action,
  indent,
  position = 'top',
  heading,
  children,
  disablePaddedContent,
  ...card
}: Props): JSX.Element => {
  return (
    <ActionCard
      {...card}
      action={action}
      indent={position === 'top' ? (card.padding === 0 ? 16 : 24) : indent}
    >
      <div
        className={`
          ${STYLES.container}
          ${STYLES[position]}
        `}
      >
        <Image
          slug={slug}
          alt={alt}
          image={image}
          className={`
            ${STYLES.image}
          `}
        />
        <div
          className={`
            ${STYLES.content}
            ${card.padding === 0 && !disablePaddedContent ? STYLES.padded : ''}
          `}
        >
          {heading && (
            <div className={STYLES.heading}>
              <Text
                element="div"
                {...heading}
                variant={heading.variant ?? 'display20'}
              />
            </div>
          )}
          {children}
        </div>
      </div>
    </ActionCard>
  )
}

export { Props, CloudinaryCardProps, CloudinaryImageCard }
export default ImageCard
