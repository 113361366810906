const RESUME_SUBSCRIPTION = 'resume_subscription'
const REMOVED_AP = 'customer_removed_extra'
const UPDATED_DELIVERY_DETAILS = 'customer_updated_delivery_details'
const UPDATED_RECIPE_MIX = 'customer_updated_recipe_mix'
const UPDATED_PORTION_SIZE = 'customer_updated_portion_size'
const ADDED_AP_TO_PLAN = 'customer_added_extra_to_plan'
const UPDATED_PLAN_DURATION = 'customer_updated_duration'
const THANK_YOU_PAGE = 'thank_you_page'

export {
  RESUME_SUBSCRIPTION,
  REMOVED_AP,
  UPDATED_DELIVERY_DETAILS,
  UPDATED_RECIPE_MIX,
  UPDATED_PORTION_SIZE,
  ADDED_AP_TO_PLAN,
  UPDATED_PLAN_DURATION,
  THANK_YOU_PAGE
}
