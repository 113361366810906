import React, { ReactElement } from 'react'

import SkeletonButton from '../SkeletonButton/SkeletonButton'
import SkeletonParagraph from '../SkeletonParagraph/SkeletonParagraph'
import SkeletonTitle from '../SkeletonTitle/SkeletonTitle'

const ModalSkeletonTemplate = (): ReactElement => {
  return (
    <>
      <SkeletonTitle />
      <SkeletonParagraph />
      <SkeletonParagraph />
      <SkeletonButton />
    </>
  )
}

export default ModalSkeletonTemplate
