// @noflow
import { useOccasion } from '@/context/festiveTheme/festiveTheme'
import { useMutation, useQuery } from '@apollo/client'
import Container from '@material-ui/core/Container'
import { addDays, subDays } from 'date-fns'
import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import {
  capitaliseFirstLetter,
  pronounContext,
  toLocalisedSentence
} from '@/utils/StringHelper'
import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { formatCurrencyWithDecimal } from '@/utils/currency'
import * as Sentry from '@/utils/sentry'

import CareModal from './components/CareModal/CareModal'
import TreatmentSection from './components/TreatmentSection/TreatmentSection'
import segmentTrack from '@/components/analytics/Analytics'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import NotificationContainer from '@/components/elements/molecules/NotificationContainer/NotificationContainer'
import NotificationContent from '@/components/elements/molecules/NotificationContent/NotificationContent'
import { ErrorState } from '@/components/elements/organisms/ErrorState'
import LoadingScreen from '@/components/elements/organisms/LoadingScreen/LoadingScreen'
import UpcomingBoxes from '@/components/pages/Dashboard/components/upcomingBoxes/UpcomingBoxes'
import Base from '@/components/templates/Base'

import {
  SUBSCRIPTION_RESUME_MUTATION,
  TREATMENTS_PAUSED_PAGE_QUERY
} from './queries/queries'

import type { TreatmentsPausedPageQuery_user_dogs as Dog } from './queries/__generated__/TreatmentsPausedPageQuery'

import generateTreatments from './helpers/helpers'
import type { Treatment, TreatmentID } from './helpers/helpers'

const NOW = new Date()

const TreatmentsPausedPage = (): JSX.Element | null => {
  const namespace = 'treatments_paused_page'

  const [careModalIsOpen, setCareModalIsOpen] = useState(false)
  const [resumeLoading, setResumeLoading] = useState(false)
  const { data, error, loading } = useQuery(TREATMENTS_PAUSED_PAGE_QUERY, {
    variables: {
      from: subDays(NOW, 3),
      to: addDays(NOW, 60)
    },
    onError: (error) =>
      Sentry.captureException(
        'Error occurred in TREATMENTS_PAUSED_PAGE_QUERY',
        {
          extra: { error },
          tags: {
            product: Sentry.Product.Account,
            team: Sentry.Team.Retention
          }
        }
      )
  })

  const [subscriptionResume] = useMutation(SUBSCRIPTION_RESUME_MUTATION, {
    variables: {
      numOfBoxes: 3
    }
  })

  const triggerConfirmation = useCallback(() => {
    setTimeout((): void => {
      window.location.href = '/account/restart-plan-success'
    }, 500)
  }, [])

  const triggerError = useCallback((error) => {
    Sentry.captureException(
      'Could not resume subscription from SUBSCRIPTION_RESUME_MUTATION',
      {
        extra: { error },
        tags: {
          product: Sentry.Product.Account,
          team: Sentry.Team.Retention
        }
      }
    )
    toast.error(
      <NotificationContent
        copy={{
          text: 'notification.resume_failed',
          namespace: 'limited_offer_paused_page'
        }}
      />,
      { toastId: 'resume-error-message' }
    )
  }, [])

  useEffect((): void => {
    if (!data) return

    const { treatments = [] } = data?.user?.subscription?.currentPause || {}

    if (treatments) {
      const generatedTreatments = generateTreatments(treatments)

      const keys = generatedTreatments.map(
        ({ key }: Treatment): TreatmentID => key
      )
      if (data && keys) {
        segmentTrack('Treatments Paused Dashboard - Page Loaded', {
          available_treatments: keys
        })
      }
    }
  }, [data])

  const resumePlanAndBookClicked = useCallback(async (): Promise<void> => {
    segmentTrack(
      'Treatments Paused Dashboard - Care Modal Resume Plan and Book CTA clicked'
    )
    setResumeLoading(true)
    try {
      setTimeout(function () {
        window.open(
          'https://calendly.com/butternut-vet-nurses/free-health-mot',
          '_blank'
        )
      }, 2000)
      await subscriptionResume()
      triggerConfirmation()
    } catch (error) {
      triggerError(error)
    } finally {
      setResumeLoading(false)
    }
  }, [subscriptionResume, triggerConfirmation, triggerError])

  const toggleCareModal = useCallback((): void => {
    setCareModalIsOpen(!careModalIsOpen)
  }, [careModalIsOpen, setCareModalIsOpen])

  const { xmas } = useOccasion()

  if (error) {
    return (
      <ErrorState
        error={{
          name: 'Error returning paused dashboard',
          message: error.message,
          apollo: error
        }}
      />
    )
  }

  if (loading || !data) {
    return (
      <LoadingScreen
        isOpen
        title={{
          text: 'loading_screen.title',
          namespace: 'shared'
        }}
      />
    )
  }

  const {
    user: {
      ordersByDate,
      preferredLanguage,
      shippingCountryCode,
      dogs,
      subscription: {
        previewUncreatedFutureBox,
        plan: { pouchSize, swappablePlan, downsizeablePlan }
      }
    }
  } = data

  const { order } = previewUncreatedFutureBox || {}
  const { totalPrice, surchargeTotal, planTotal, deliveryFee, subtotal } =
    order || {}

  const { locale, currency } = countryCodeToLocaleCurrency(
    shippingCountryCode,
    preferredLanguage
  )

  const dogNames = toLocalisedSentence({
    arr: dogs.map((dog: Dog) => capitaliseFirstLetter(dog.name)),
    lng: preferredLanguage
  })
  const dogGenders = dogs.map((dog: Dog) => dog.gender)
  const dogPronounContext = pronounContext(dogGenders, preferredLanguage)
  const savingsAmount = swappablePlan ? planTotal - swappablePlan.price : 0
  const savingsAmountFormatted = formatCurrencyWithDecimal(savingsAmount, {
    locale: locale,
    currency: currency
  })
  const downsizeSavingsAmount = downsizeablePlan
    ? planTotal - downsizeablePlan.price
    : 0
  const downsizeSavingsAmountFormatted = formatCurrencyWithDecimal(
    downsizeSavingsAmount,
    {
      locale: locale,
      currency: currency
    }
  )
  const surchargeTotalFormatted = formatCurrencyWithDecimal(surchargeTotal, {
    locale: locale,
    currency: currency
  })
  const boxPriceFormatted = formatCurrencyWithDecimal(subtotal + deliveryFee, {
    locale: locale,
    currency: currency
  })
  const discountedBoxPriceFormatted = formatCurrencyWithDecimal(totalPrice, {
    locale: locale,
    currency: currency
  })

  const { treatments = [] } = data?.user?.subscription?.currentPause || {}
  const generatedTreatments = generateTreatments(treatments)

  return (
    <>
      <Base background={xmas ? 'transparent' : 'brandYellow100'}>
        <Container maxWidth={false} disableGutters>
          {generatedTreatments.length > 0 && (
            <TreatmentSection
              treatments={generatedTreatments}
              pouchSize={pouchSize}
              swappablePlanPouchSize={
                swappablePlan ? swappablePlan.pouchSize : 0
              }
              surchargeTotal={surchargeTotalFormatted}
              savingsAmount={savingsAmountFormatted}
              boxPrice={boxPriceFormatted}
              discountedBoxPrice={discountedBoxPriceFormatted}
              toggleCareModal={toggleCareModal}
              namespace={namespace}
              dogNames={dogNames}
              context={dogPronounContext}
              downsizeSavingsAmount={downsizeSavingsAmountFormatted}
            />
          )}
          {ordersByDate.length > 0 && (
            <SectionWrapper
              margin={0}
              headerTypography={{
                text: 'upcoming_boxes.title',
                namespace
              }}
            >
              <UpcomingBoxes filter={['past']} />
            </SectionWrapper>
          )}
        </Container>
      </Base>
      <NotificationContainer autoClose={5000} />
      <CareModal
        careModalIsOpen={careModalIsOpen}
        toggleCareModal={toggleCareModal}
        dogNames={dogNames}
        resumePlanClicked={resumePlanAndBookClicked}
        resumeLoading={resumeLoading}
        namespace={namespace}
      />
    </>
  )
}

export default TreatmentsPausedPage
