import React from 'react'

import {
  CustomerIssueReportCreate_response_inputFields_CustomerIssueManagementInputFieldBoolean as BooleanField,
  CustomerIssueReportCreate_response_inputFields_CustomerIssueManagementInputFieldMultifield_fields_CustomerIssueManagementInputFieldBoolean as MultifieldBooleanField
} from '../../../../mutations/__generated__/CustomerIssueReportCreate'
import { CustomerIssueManagementInputInput } from '@/types'

import ConfirmationBox from '../display_types/ConfirmationBox'

type BooleanProps = {
  inputField: BooleanField | MultifieldBooleanField
  inputValue: CustomerIssueManagementInputInput
  updateInput: (input: CustomerIssueManagementInputInput) => void
}

const Boolean = ({
  inputField,
  inputValue,
  updateInput
}: BooleanProps): JSX.Element => {
  const setValue = React.useCallback(
    (booleanValue: boolean): void => {
      const newInput = {
        fieldId: inputValue.fieldId,
        booleanValue
      }
      updateInput(newInput)
    },
    [inputValue, updateInput]
  )

  switch (inputField.booleanDisplayType) {
    case 'confirmation_box':
      return (
        <ConfirmationBox
          label={inputField.name}
          checked={inputValue.booleanValue || false}
          setValue={setValue}
        />
      )
    default: {
      throw new Error('Unsupported boolean display type')
    }
  }
}

export default Boolean
