// @noflow

/* eslint-disable i18next/no-literal-string */
import { useNotifications } from '@/context/notifications/notifications'
import { ACCOUNT_ROUTES } from '@/routes'
import { useMutation } from '@apollo/client'
import i18next from 'i18next'
import isEqual from 'lodash/isEqual'
import isUndefined from 'lodash/isUndefined'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { possessive } from '@/utils/StringHelper'
import { planDiscrepancy } from '@/utils/planDiscrepancy'

import BREAKPOINTS from '@/constants/Breakpoints'

import useBoolean from '@/hooks/useBoolean'
import useWindowSize from '@/hooks/useWindowSize'

import CheckmarkArea from 'assets/images/icons/checkmarks/checkmark--green-area.svg'
import CrossArea from 'assets/images/icons/crosses/cross--red-area.svg'
import DogInCone from 'assets/images/illustrations/dogs/dog-in-cone.svg'
import DogJumping from 'assets/images/illustrations/dogs/dog-jumping.svg'
import DogsMedicalInfo from 'assets/images/illustrations/dogs/dogs-medical-info.svg'
import Plant from 'assets/images/illustrations/plant.svg'

import Alert from '@/components/elements/atoms/Alert/AlertCard'
import { Button } from '@/components/elements/atoms/Button'
import { FixedBase } from '@/components/elements/atoms/FixedBase'
import Modal from '@/components/elements/atoms/Modal/Modal'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import Text from '@/components/elements/atoms/Text/Text'
import {
  Item,
  MultipleComboBox
} from '@/components/elements/molecules/MultipleComboBox'
import PillGroup, {
  Props as PillGroupProps
} from '@/components/elements/molecules/PillGroup/PillGroup'
import {
  SelectableCardGroup,
  Value
} from '@/components/elements/molecules/SelectableCardGroup'

import STYLES from './UpdateDogMedicalInfo.module.sass'

import { UPDATE_MEDICAL_INFO_MUTATION } from './mutations/updateMedicalInfoMutation'

import type {
  UpdateMedicalInfoMutation,
  UpdateMedicalInfoMutationVariables
} from './mutations/__generated__/UpdateMedicalInfoMutation'
import type {
  MedicalInfoQuery_user_dogs as Dog,
  MedicalInfoQuery
} from './queries/__generated__/MedicalInfoQuery'
import { Gender } from '@/types'

type Props = Partial<MedicalInfoQuery> & {
  selectedDog?: Dog
  loading: boolean
}

const commonIconStyles = {
  width: '100%',
  height: 100
}

type HealthIssuesInputProps = {
  genderContext: Gender
  hasHealthIssues: boolean
  handleOnHealthIssuesToggle: (value: Value) => void
  healthIssuesIds: string[] // dogs current health issue ids
  handleSelectHealthIssues: ((selectedItems: Item[]) => void) | undefined
  initialSelectedHealthIssues: Item[] | undefined
  healthIssues: {
    id: string
    name: string
    warning: boolean
    information: string
  }[]
}

const HealthIssuesInput = ({
  handleSelectHealthIssues,
  initialSelectedHealthIssues,
  handleOnHealthIssuesToggle,
  healthIssues,
  healthIssuesIds,
  genderContext,
  hasHealthIssues
}: HealthIssuesInputProps): JSX.Element => {
  const HEALTH_ISSUES = [
    {
      value: true,
      icon: {
        src: DogInCone,
        alt: 'update_dog_profile_medical_info.health_issues.options.true',
        ...commonIconStyles
      },
      typography: {
        text: 'update_dog_profile_medical_info.health_issues.options.true',
        variables: {
          context: genderContext
        }
      },
      identifier: 'update_dog_profile_medical_info.health_issues_true'
    },
    {
      value: false,
      icon: {
        src: DogJumping,
        alt: 'update_dog_profile_medical_info.health_issues.options.false',
        ...commonIconStyles
      },
      typography: {
        text: 'update_dog_profile_medical_info.health_issues.options.false',
        variables: {
          context: genderContext
        }
      },
      identifier: 'update_dog_profile_medical_info.health_issues_false'
    }
  ]

  const healthIssuesOptions =
    healthIssues.map(({ id, name }) => ({
      id,
      typography: {
        text: name,
        translate: false
      }
    })) ?? []

  return (
    <>
      <SelectableCardGroup
        options={HEALTH_ISSUES}
        onSelect={handleOnHealthIssuesToggle}
        currentValue={hasHealthIssues}
      />
      {hasHealthIssues && (
        <MultipleComboBox
          items={healthIssuesOptions}
          initialSelectedItems={initialSelectedHealthIssues}
          placeholder={{
            text: 'update_dog_profile_medical_info.health_issues.input_placeholder',
            translate: true,
            namespace: 'account'
          }}
          onStateChange={handleSelectHealthIssues}
          dropdownPosition="top"
        />
      )}
      {hasHealthIssues &&
        healthIssuesIds.length > 0 &&
        healthIssuesIds.map((item) => {
          const healthIssue = healthIssues?.find(({ id }) => id === item)

          if (!healthIssue) {
            return null
          }

          const { information, warning } = healthIssue

          return (
            <Alert
              key={information}
              variant={warning ? 'warning' : 'success'}
              message={{
                text: information,
                translate: false,
                align: 'left'
              }}
            />
          )
        })}
    </>
  )
}

type AllergiesInputProps = {
  hasAllergies: boolean
  handleOnAllergiesToggle: (value: Value) => void
  allergiesIds: string[] // dogs current health issue ids
  handleSelectAllergens: (
    e: React.FormEvent<HTMLDivElement> | undefined
  ) => void
  allergies: {
    id: string
    name: string
  }[]
}

const AllergiesInput = ({
  handleSelectAllergens,
  handleOnAllergiesToggle,
  allergies,
  allergiesIds,
  hasAllergies
}: AllergiesInputProps): JSX.Element => {
  const ALLERGIES = [
    {
      value: true,
      icon: {
        src: CheckmarkArea,
        alt: 'update_dog_profile_medical_info.allergies.options.true',
        ...commonIconStyles
      },
      typography: {
        text: 'update_dog_profile_medical_info.allergies.options.true'
      },
      identifier: 'update_dog_profile_medical_info.allergies_true'
    },
    {
      value: false,
      icon: {
        src: CrossArea,
        alt: 'update_dog_profile_medical_info.allergies.options.false',
        ...commonIconStyles
      },
      typography: {
        text: 'update_dog_profile_medical_info.allergies.options.false'
      },
      identifier: 'update_dog_profile_medical_info.allergies_false'
    }
  ]

  const allergiesPillOptions: PillGroupProps['pillOptions'] =
    allergies.map(({ id, name }) => ({
      id,
      value: id,
      name,
      text: name,
      translate: false,
      colour: 'yellow300',
      onChange: handleSelectAllergens,
      checked: allergiesIds.includes(id),
      namespace: 'account',
      variant: 'checkbox'
    })) ?? []

  return (
    <>
      <SelectableCardGroup
        options={ALLERGIES}
        onSelect={handleOnAllergiesToggle}
        currentValue={hasAllergies}
      />
      {hasAllergies && <PillGroup pillOptions={allergiesPillOptions} />}
    </>
  )
}

const UpdateDogMedicalInfoContent = ({
  selectedDog,
  loading,
  ...restProps
}: Props): JSX.Element => {
  const container = useRef<HTMLDivElement>(null)
  const { t } = useTranslation('account')
  const { setErrorNotification } = useNotifications()
  const navigate = useNavigate()
  const { windowWidth } = useWindowSize()

  const isMobile = windowWidth < BREAKPOINTS.md

  const loadedData = (() => {
    if (loading) {
      return null
    }

    const { user, allergens, healthIssues } = restProps
    const { id: userId, dogs } = user ?? {}

    return {
      userId,
      dogs,
      allergens,
      healthIssues
    }
  })()

  const [shouldSetInitialData, setShouldSetInitialData] = useState(true)
  const [medicalInfo, setMedicalInfo] = useState({
    hasAllergies: false,
    hasHealthIssues: false,
    allergiesIds: [] as Array<string>,
    healthIssuesIds: [] as Array<string>
  })

  const {
    value: isConfirmationModalOpen,
    setTrue: openConfirmationModal,
    setFalse: closeConfirmationModal,
    toggle: toggleConfirmationModal
  } = useBoolean(false)

  const genderContext = selectedDog?.gender
  const possessiveDogName =
    selectedDog && possessive(selectedDog.name, i18next.language)

  const noChangeToMedicalInfo = (() => {
    if (selectedDog) {
      const { healthIssues, allergens } = selectedDog

      const allergensIds = allergens.map((allergen) => allergen.id)
      const healthIssuesIds = healthIssues.map((healthIssue) => healthIssue.id)

      return (
        isEqual(medicalInfo.allergiesIds.sort(), allergensIds.sort()) &&
        isEqual(medicalInfo.healthIssuesIds.sort(), healthIssuesIds.sort())
      )
    }
  })()

  const handleOnSelect = useCallback(
    (value, type) => {
      setMedicalInfo({
        ...medicalInfo,
        [type]: value
      })
    },
    [medicalInfo]
  )

  const handleOnAllergiesToggle = useCallback(
    (value) => {
      handleOnSelect(value, 'hasAllergies')
    },
    [handleOnSelect]
  )

  const handleOnHealthIssuesToggle = useCallback(
    (value) => handleOnSelect(value, 'hasHealthIssues'),
    [handleOnSelect]
  )

  /**
   * Update dog medical info mutation
   *
   * On error, show error message and log error to Sentry.
   * On success, show success message, refetch user data and navigate
   * to the correct screen.
   */
  const [
    updateMedicalConditionMutation,
    { loading: updateDogMedicalInfoLoading }
  ] = useMutation<
    UpdateMedicalInfoMutation,
    UpdateMedicalInfoMutationVariables
  >(UPDATE_MEDICAL_INFO_MUTATION, {
    onError: () => {
      setErrorNotification({
        text: 'update_dog_profile_basic_info.notifications.error',
        namespace: 'account'
      })
    },
    onCompleted: (data) => {
      closeConfirmationModal()

      if (data?.dogsUpdate && planDiscrepancy(data.dogsUpdate.subscription)) {
        // TODO: Navigate to the recommended plan page
        navigate(`${ACCOUNT_ROUTES.profile}/${selectedDog?.id}`)
      } else {
        navigate(`${ACCOUNT_ROUTES.profile}/${selectedDog?.id}`)
      }
    }
  })

  /**
   * Hander to update dog medical info
   */
  const updateMedicalConditionDetails = useCallback(async () => {
    try {
      await updateMedicalConditionMutation({
        variables: {
          userId: loadedData?.userId ?? '',
          attributes: [
            {
              id: selectedDog?.id ?? '',
              healthIssueIds: medicalInfo.hasHealthIssues
                ? medicalInfo.healthIssuesIds
                : [],
              allergenIds: medicalInfo.hasAllergies
                ? medicalInfo.allergiesIds
                : []
            }
          ]
        }
      })
    } catch (e) {
      setErrorNotification({
        text: 'update_dog_profile_medical_info.notifications.error',
        namespace: 'account'
      })
    }
  }, [
    selectedDog?.id,
    loadedData,
    setErrorNotification,
    updateMedicalConditionMutation,
    medicalInfo
  ])

  useEffect(() => {
    if (shouldSetInitialData && loadedData && selectedDog) {
      const { healthIssues, allergens } = selectedDog

      setMedicalInfo({
        ...medicalInfo,
        hasAllergies: !!allergens?.length,
        hasHealthIssues: !!healthIssues?.length,
        allergiesIds: allergens?.map(({ id }) => id) ?? [],
        healthIssuesIds: healthIssues?.map(({ id }) => id) ?? []
      })

      // Prevent the effect from running again
      setShouldSetInitialData(false)
    }
  }, [shouldSetInitialData, loadedData, medicalInfo, selectedDog])

  useEffect(() => {
    if (updateDogMedicalInfoLoading) {
      openConfirmationModal()
    }
  }, [updateDogMedicalInfoLoading, openConfirmationModal])

  const handleSelectAllergens = useCallback(
    (e: React.FormEvent<HTMLDivElement> | undefined): void => {
      if (e) {
        const clickedElement = e.target as HTMLInputElement
        const value = clickedElement.value

        if (clickedElement.checked) {
          setMedicalInfo({
            ...medicalInfo,
            allergiesIds: [...medicalInfo.allergiesIds, value]
          })
        } else {
          setMedicalInfo({
            ...medicalInfo,
            allergiesIds: medicalInfo.allergiesIds.filter(
              (allergy) => allergy !== value
            )
          })
        }
      }
    },
    [medicalInfo]
  )

  const handleSelectHealthIssues = useCallback(
    (selectedHealthIssues: Array<Item>) => {
      setMedicalInfo({
        ...medicalInfo,
        healthIssuesIds: selectedHealthIssues.map(({ id }) => id)
      })
    },
    [medicalInfo]
  )

  const initialSelectedHealthIssues =
    selectedDog?.healthIssues?.map(({ id, name }) => ({
      id,
      typography: {
        text: name,
        translate: false
      }
    })) ?? []

  const getSectionWrapperProps = (type: string) =>
    ({
      headerTypography: {
        text: `update_dog_profile_medical_info.${type}.title`,
        namespace: 'account',
        align: 'center',
        variables: {
          context: genderContext
        }
      },
      bgColour: 'brandWhite90',
      borderRadius: 16,
      padding: { top: 16, right: 16, bottom: 16, left: 16 }
    } as const)

  return (
    <div ref={container}>
      <SectionWrapper
        {...getSectionWrapperProps('allergies')}
        margin={{ top: 0 }}
      >
        <div className={STYLES.sectionContent}>
          <AllergiesInput
            allergies={loadedData?.allergens || []}
            allergiesIds={medicalInfo.allergiesIds}
            handleOnAllergiesToggle={handleOnAllergiesToggle}
            handleSelectAllergens={handleSelectAllergens}
            hasAllergies={medicalInfo.hasAllergies}
          />
        </div>
      </SectionWrapper>
      <SectionWrapper {...getSectionWrapperProps('health_issues')}>
        <div className={STYLES.sectionContent}>
          {!isUndefined(genderContext) && (
            <HealthIssuesInput
              initialSelectedHealthIssues={initialSelectedHealthIssues}
              genderContext={genderContext}
              handleOnHealthIssuesToggle={handleOnHealthIssuesToggle}
              handleSelectHealthIssues={handleSelectHealthIssues}
              hasHealthIssues={medicalInfo.hasHealthIssues}
              healthIssues={loadedData?.healthIssues || []}
              healthIssuesIds={medicalInfo.healthIssuesIds}
            />
          )}
        </div>
      </SectionWrapper>
      <FixedBase backgroundColor="brandWhite90" container={container}>
        <div className={STYLES.fixedBaseContent}>
          <Button
            identifier="dog_medical_info.update"
            onClick={updateMedicalConditionDetails}
            typography={{
              text: 'update_dog_profile_medical_info.button',
              namespace: 'account'
            }}
            skeleton={{
              isLoading: loading
            }}
            disabled={
              loading || updateDogMedicalInfoLoading || noChangeToMedicalInfo
            }
          />
        </div>
      </FixedBase>
      <Modal
        isModalOpen={isConfirmationModalOpen}
        setOpenModal={toggleConfirmationModal}
        width={600}
        textAlign="center"
        fullHeight={isMobile}
        showCloseButton={false}
        backgroundColour="brandYellow100"
      >
        <div className={STYLES.confirmationModalContent}>
          <img
            src={CheckmarkArea}
            alt={t('update_dog_profile_medical_info.notifications.confirm.alt')}
          />
          <div>
            <img
              src={DogsMedicalInfo}
              alt={t(
                'update_dog_profile_medical_info.notifications.confirm.dog_info_alt'
              )}
              className={STYLES.confirmationModalDogInfoImg}
            />
            <img
              src={Plant}
              alt={t(
                'update_dog_profile_medical_info.notifications.confirm.plant_alt'
              )}
              className={STYLES.confirmationModalPlantImg}
            />
          </div>
          <Text
            namespace="account"
            text="update_dog_profile_medical_info.notifications.confirm.text"
            variables={{
              possessiveDogName
            }}
            align="center"
            variant="display28"
          />
        </div>
      </Modal>
    </div>
  )
}

export { UpdateDogMedicalInfoContent, HealthIssuesInput, AllergiesInput }
