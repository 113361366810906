// @noflow
import { useQuery } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  countrySpecificFacebookLink,
  countrySpecificInstagramLink
} from '@/utils/countryCodeHelper'

import Image from '@/components/elements/atoms/Image/Image'
import SocialLink from '@/components/elements/atoms/SocialLink/SocialLink'
import Text from '@/components/elements/atoms/Text/Text'
import { DASHBOARD_FOOTER_QUERY } from '@/components/elements/organisms/DashboardFooter/queries/dashboardFooterQuery'

import STYLES from './DashboardFooter.module.sass'

import type { DashboardFooterQuery } from './queries/__generated__/DashboardFooterQuery'

type Variant = 'default' | 'cardboard'

type Props = {
  variant?: Variant
}

const DefaultFooter = () => {
  const { t } = useTranslation('navigation')

  const { loading, data, error } = useQuery<DashboardFooterQuery>(
    DASHBOARD_FOOTER_QUERY
  )
  if (!data || loading) {
    return null
  }
  if (error) {
    Sentry.captureException(
      `An error occured when loading DashboardFooterQuery`,
      { extra: { error } }
    )
  }
  const { shippingCountryCode } = data.user

  return (
    <footer className={STYLES.footer} id="dashboard-footer">
      <div className={STYLES.content}>
        <Text namespace="footer" text={'follow_us_on'} variant="textMono18" />
        <div className={STYLES.links}>
          <SocialLink
            alt=""
            href={countrySpecificInstagramLink(shippingCountryCode)}
            iconSrc="instagram-icon"
          />
          <SocialLink
            alt=""
            href={countrySpecificFacebookLink(shippingCountryCode)}
            iconSrc="facebook-icon"
          />
          {shippingCountryCode !== 'PL' && (
            <>
              <SocialLink
                alt=""
                href="https://twitter.com/ButternutBox"
                iconSrc="twitter-icon"
              />
              <SocialLink
                alt=""
                href="https://www.youtube.com/c/butternutbox"
                iconSrc="youtube-icon"
              />
            </>
          )}
        </div>
      </div>
      <Image
        className={STYLES.logo}
        alt={t('logo_alt')}
        slug="butternut-box-footer-logo"
        image={{
          width: 150,
          height: 118,
          resizeMode: 'resize_to_fill',
          retina: true
        }}
      />
    </footer>
  )
}

const CardBoardFooter = () => {
  const { t } = useTranslation('navigation')

  return (
    <footer className={STYLES.cardBoardFooter}>
      <Image
        alt={t('logo_alt')}
        slug="butternut-box-footer-logo"
        image={{
          width: 110,
          height: 86,
          resizeMode: 'resize_to_fill',
          retina: true
        }}
      />
    </footer>
  )
}

const DashboardFooter = ({ variant = 'default' }: Props): JSX.Element => {
  switch (variant) {
    case 'default': {
      return <DefaultFooter />
    }
    case 'cardboard': {
      return <CardBoardFooter />
    }
  }
}

export default DashboardFooter
