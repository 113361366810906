// @flow

import * as React from 'react'
import CloudinaryImage from '@/components/shared/elements/CloudinaryImage/CloudinaryImage'
import { useTranslation } from 'react-i18next'

type Expert =
  | 'Oli Juste'
  | 'Sarah Barber'
  | 'Dr Ciara Clarke'
  | 'Keisha and Gizmo'
  | 'Karim and Gizmo'

type ExpertObj = {|
  name: string,
  alt: string,
  src: string,
  profession: string,
  experience: string
|}

type BiographyType =
  | 'profession'
  | 'experience'

type Theme =
  | 'blue'
  | 'yellow'
  | 'white'

type Props = {|
  name: Expert,
  bioType?: BiographyType,
  theme?: Theme
|}

type Translate = (arg0: string) => string

const expertObj = (name: Expert, t: Translate): ExpertObj => {
  switch (name) {
    case 'Oli Juste': {
      return {
        name,
        alt: name,
        src: 'Web/photos/people/oli-juste.jpg',
        profession: t('oli_juste.profession'),
        experience: t('oli_juste.experience')
      }
    }
    case 'Sarah Barber': {
      return {
        name,
        alt: name,
        src: 'Web/photos/people/sarah-connell-sait.png',
        profession: t('sarah_connell_sait.profession'),
        experience: ''
      }
    }
    case 'Dr Ciara Clarke': {
      return {
        name: t('ciara_clarke.name'),
        alt: t('ciara_clarke.name'),
        src: 'Web/photos/people/ciara-clarke.jpg',
        profession: t('ciara_clarke.profession'),
        experience: t('ciara_clarke.experience')
      }
    }
    case 'Keisha and Gizmo': {
      return {
        name: t('keisha_gizmo.name'),
        alt: t('keisha_gizmo.name'),
        src: 'Web/photos/people/keisha--square.jpg',
        profession: t('keisha_gizmo.profession'),
        experience: ''
      }
    }
    case 'Karim and Gizmo': {
      return {
        name: t('karim_gizmo.name'),
        alt: t('karim_gizmo.name'),
        src: 'Web/photos/people/karim--square.jpg',
        profession: t('karim_gizmo.profession'),
        experience: ''
      }
    }
    default: {
      (name: empty) // eslint-disable-line no-unused-expressions
      throw new Error(`expert: No case for ${name}`)
    }
  }
}

/**
 * Example usage:
 *
 * import Expert from '/path/to/Expert'
 *
 * <Expert
 *   name='Oli Juste'
 * />
 */
const ExpertComponent = ({
  name,
  bioType = 'profession',
  theme = 'blue'
}: Props): React.Element<'div'> => {
  const { t } = useTranslation('expert')
  const expert = expertObj(name, t)
  return (
    <div className='expert'>
      <div>
        <CloudinaryImage
          alt={expert.alt}
          className={`expert__avatar expert__avatar--${theme}`}
          image={{
            path: expert.src
          }}
        />
      </div>
      <div>
        <p className='expert__name'>
          { expert.name }
        </p>
        <p className='expert__profression'>
          { bioType === 'profession' ? expert.profession : expert.experience }
        </p>
      </div>
    </div>
  )
}

export type { Expert, Theme }

export default ExpertComponent
