import { ACCOUNT_ROUTES } from '@/routes'
import snakeCase from 'lodash/snakeCase'
import React, { useCallback } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import { getPersonalityTraitsIcons } from '@/utils/getPersonalityTraitsIcons'

import Card from '@/components/elements/atoms/Card/Card'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './PersonalityTrait.module.sass'

import { Activity, FavouriteGame, Trait } from '@/types'

type Props = {
  type: string
  value?: Trait | FavouriteGame | Activity | null
  dogId?: string
}

const PersonalityTrait = ({
  type,
  value = null,
  dogId
}: Props): JSX.Element => {
  const navigate = useNavigate()

  const unknownFavouriteGame =
    type === 'favouriteGame' && value === 'unknown_favourite_game'
  const isUnknownTrait = !value || unknownFavouriteGame

  const PersonalityTraitIcon = getPersonalityTraitsIcons(value ?? '')

  const handleOnClick = useCallback(() => {
    if (dogId) {
      navigate(generatePath(ACCOUNT_ROUTES.dogPersonality, { dogId: dogId }))
    }
  }, [dogId, navigate])

  return (
    <Card
      className={STYLES.wrapper}
      variant="brandBlue100"
      fill={false}
      onClick={handleOnClick}
      identifier="dog_profile.personality_details.personality_trait"
    >
      {isUnknownTrait ? (
        <div className={STYLES.emptyIcon} />
      ) : (
        <img src={PersonalityTraitIcon} alt="Personality trait" />
      )}
      <Text
        text={`dog_profile.personality_details.personality_trait.${snakeCase(
          type
        )}`}
        namespace="account"
        variables={{ context: isUnknownTrait ? 'empty' : value ?? '' }}
        align="center"
        colour="brandBlue400"
        margin={false}
      />
    </Card>
  )
}

export { PersonalityTrait }
