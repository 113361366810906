import React from 'react'

import { Button } from '@/components/elements/atoms/Button'

type Props = {
  handleHelpNavigation: () => void
}

const PauseError = ({ handleHelpNavigation }: Props): JSX.Element => (
  <Button
    variant="secondary"
    identifier="stop_deliveries_button.help"
    typography={{
      text: 'subscription_settings.pause_subscription_cta',
      namespace: 'account'
    }}
    onClick={handleHelpNavigation}
    fullWidth
  />
)

export { PauseError }
