// @noflow
import ACCESS_RULES, {
  Groups,
  RouteAccessRule,
  spreadRoutes
} from '@/context/accessManagement/rules'
import * as Sentry from '@sentry/browser'
import { isNull, isUndefined, uniq } from 'lodash'
import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { matchPath } from 'react-router-dom'

import { Code, SubscriptionStatus } from '@/types'

interface AccessManagementContextProps {
  canUserAccessRoute: (route: string) => boolean
  getRedirectPath: (route: string) => string | false | undefined
}

interface AccessManagementProviderProps {
  status: SubscriptionStatus | undefined
  hasPaymentMethod: boolean
  disableExtras: boolean
  dogs: number | undefined
  shippingCountry: Code
  children: ReactNode
  isAmbassador: boolean
  isInfluencer: boolean
  isEligibleForSwapAndSave: boolean
  isEligibleForDownsizeAndSave: boolean
  hideOrderPages: boolean
  isEligibleForMFITBPlan: boolean
}

const userHasActivePaymentMethod = (
  paymentMethods: { active: boolean }[] | undefined
): boolean =>
  !isUndefined(paymentMethods)
    ? paymentMethods.some(({ active }) => active)
    : false

/**
 * Determine what groups the user is part of.
 * Each group has its own array of blocked routes which cannot be accessed by members of that group
 * @param subscriptionStatus
 * @param shippingCountry
 * @param hasPaymentMethod
 * @param disableExtras
 * @param dogs
 * @param isEligibleForSwapAndSave
 * @param isEligibleForDownsizeAndSave
 * @return an array of type group which is used to determine what routes the user can access.
 */
const getUserGroups = (
  subscriptionStatus: SubscriptionStatus | undefined,
  shippingCountry: Code,
  hasPaymentMethod: boolean,
  disableExtras: boolean,
  dogs: number | undefined,
  isAmbassador: boolean,
  isInfluencer: boolean,
  isEligibleForSwapAndSave: boolean,
  isEligibleForDownsizeAndSave: boolean,
  hideOrderPages: boolean,
  isEligibleForMFITBPlan: boolean
): Groups[] => {
  const modifiers = []

  const isAmbassadorOrInfluencer = isAmbassador || isInfluencer

  if (!hasPaymentMethod && !isAmbassadorOrInfluencer)
    modifiers.push(Groups.noPaymentMethod)
  if (isUndefined(dogs) || dogs === 0) modifiers.push(Groups.noDogs)
  // Add access modifier for users subscription status
  if (!isUndefined(subscriptionStatus))
    modifiers.push(Groups[subscriptionStatus])

  if (isInfluencer && !hasPaymentMethod)
    modifiers.push(Groups.influencerTrialAccount)
  if (isAmbassador && !hasPaymentMethod)
    modifiers.push(Groups.ambassadorTrialAccount)
  if (disableExtras) modifiers.push(Groups.noExtras)
  if (!isAmbassador) modifiers.push(Groups.notAmbassador)
  if (!isInfluencer) modifiers.push(Groups.notInfluencer)
  if (!isEligibleForSwapAndSave)
    modifiers.push(Groups.notEligibleForSwapAndSave)
  if (!isEligibleForDownsizeAndSave)
    modifiers.push(Groups.notEligibleForDownsizeAndSave)
  if (hideOrderPages) modifiers.push(Groups.orderPagesHidden)
  if (!isEligibleForMFITBPlan) modifiers.push(Groups.notEligibleForMFITBPlan)

  // Add access modifier for users shipping country
  modifiers.push(Groups[shippingCountry])

  return modifiers
}

/**
 * Generate an array of all routes blocked for this user
 * @param rules
 */
const generateBlockList = (rules: readonly RouteAccessRule[]): string[] => {
  const list = []
  for (const rule of rules) list.push(...rule.blocked)
  return uniq(list)
}

/**
 * Test that the block list does not contain all the redirect routes.
 * If so a redirect loop will be caused when the app tries to redirect to a
 * blocked route and then tries to redirect again.
 * @param rules
 */
const hasRedirectLoop = (rules: readonly RouteAccessRule[]): boolean => {
  const blocklist = generateBlockList(rules)

  return (
    Array.isArray(rules) &&
    rules.length > 0 &&
    rules.filter((rule) => blocklist.includes(rule.redirect)).length ===
      rules.length
  )
}

/**
 * Check if a user should be able to access a route
 * Tests the route against the array of blocked routes in each group they are part of
 * @param groups
 * @param path
 * @param rules
 */
const redirectTo = (
  groups: string[],
  path: string,
  rules: readonly RouteAccessRule[]
): false | string => {
  const userRules = rules.filter((rule: RouteAccessRule) =>
    groups.includes(rule.group)
  )
  const blocklist: string[] = generateBlockList(userRules)

  // filter access rules to users current groups
  for (const rule of userRules) {
    // Test all applicable rules
    for (const blocked of blocklist) {
      // If the route is blocked return the route the user should be redirected to
      // Do not allow redirect if the redirect route is also on the block list
      // If all the redirects are on the block list then the function will return false
      // and allow access to the route. This is to stop a redirect loop occuring.
      if (
        matchPath({ path: blocked }, path) &&
        !blocklist.includes(rule.redirect)
      )
        return rule.redirect
    }
  }

  return false
}

const AccessManagementContext =
  React.createContext<AccessManagementContextProps | null>(null)

/**
 * Provides logic as to whether the user can access a route.
 * Rules are defined in the rules.ts file
 * @param status - subscription status
 * @param hasPaymentMethod - does the user have an active payment method
 * @param disableExtras - can the user access the extras section
 * @param shippingCountry
 * @param dogs - how many active dogs does the user have
 * @param children
 * @param hideOrderPages - hides the order 'show' pages
 * @constructor
 */
const AccessManagementProvider = ({
  status,
  hasPaymentMethod,
  disableExtras,
  shippingCountry,
  dogs,
  children,
  isAmbassador,
  isInfluencer,
  isEligibleForSwapAndSave,
  isEligibleForDownsizeAndSave,
  hideOrderPages,
  isEligibleForMFITBPlan
}: AccessManagementProviderProps): JSX.Element => {
  const [groups, setGroups] = useState<Groups[] | null>(null)
  const [rules, setRules] = useState<RouteAccessRule[] | null>(null)
  const [blocklist, setBlocklist] = useState<string[]>([])

  const accessManagementProviderValues = useMemo(() => {
    if (isNull(rules) || isNull(groups)) {
      return null
    }
    return {
      canUserAccessRoute: (path: string) => !blocklist.includes(path),
      getRedirectPath: (path: string) => {
        /**
         * If this user is part of multiple groups which have conflicting
         * rules then flag for investigation.
         * If a redirect loop is detected the user will hit an escape hatch
         * and be allowed to access the route they are trying to access
         * vs hitting a recursive loop.
         */
        if (hasRedirectLoop(rules)) {
          Sentry.captureException(
            'Redirect loop detected - blocking redirect.' +
              'Flag this combination of rules for investigation.' +
              'The user has accessed a page they shouldnt be able to.',
            {
              extra: {
                redirect: path,
                rules: rules,
                blocklist: blocklist
              }
            }
          )
          return false
        }

        return redirectTo(groups, path, rules)
      }
    }
  }, [blocklist, groups, rules])

  useEffect(() => {
    setGroups(
      getUserGroups(
        status,
        shippingCountry,
        hasPaymentMethod,
        disableExtras,
        dogs,
        isAmbassador,
        isInfluencer,
        isEligibleForSwapAndSave,
        isEligibleForDownsizeAndSave,
        hideOrderPages,
        isEligibleForMFITBPlan
      )
    )
  }, [
    dogs,
    shippingCountry,
    hasPaymentMethod,
    status,
    disableExtras,
    isAmbassador,
    isInfluencer,
    isEligibleForSwapAndSave,
    isEligibleForDownsizeAndSave,
    hideOrderPages,
    isEligibleForMFITBPlan
  ])

  useEffect(() => {
    if (isNull(groups)) return
    setRules(
      ACCESS_RULES.filter((rule: RouteAccessRule) =>
        groups.includes(rule.group as Groups)
      )
    )
  }, [groups])

  useEffect(() => {
    if (isNull(rules)) return
    setBlocklist(generateBlockList(rules))
  }, [rules])

  return (
    <AccessManagementContext.Provider value={accessManagementProviderValues}>
      {children}
    </AccessManagementContext.Provider>
  )
}

const useAccessManagement = (): AccessManagementContextProps | null =>
  useContext(AccessManagementContext)

export {
  AccessManagementProvider,
  useAccessManagement,
  userHasActivePaymentMethod,
  redirectTo,
  hasRedirectLoop,
  spreadRoutes
}
