import { gql } from '@apollo/client'

const AUTHENTICATION_SETUP_CREATE_MUTATION = gql`
  mutation AuthenticationSetupCreateMutation(
    $email: String!
    $redirectUrl: String = ""
    $authenticationType: String!
    $provider: String!
  ) {
    authenticationSetupCreate(
      email: $email
      redirectUrl: $redirectUrl
      authenticationType: $authenticationType
      provider: $provider
    ) {
      authenticationSetup {
        id
        redirectUrl
      }
    }
  }
`

export { AUTHENTICATION_SETUP_CREATE_MUTATION }
