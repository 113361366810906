// @noflow
// Screens
import { ACCOUNT_ROUTES } from '@/routes'
import { gql, useMutation, useQuery } from '@apollo/client'
import React, { useCallback, useContext, useEffect, useState } from 'react'
// Routing
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom'

import BRAND_COLOURS from '@/constants/BrandColours'

import Arrow from 'assets/images/icons/arrows/arrow--blue.svg'
import Close from 'assets/images/icons/crosses/close-icon.svg'
import DogWaggingTailSprite from 'assets/images/illustrations/dogs/dog-wagging-tail--sprite.svg'

import withApollo from '@/components/apollo/withApollo'
import ProgressBar from '@/components/elements/atoms/ProgressBar/ProgressBar'
import DogAvatarGroup from '@/components/elements/molecules/DogAvatarGroup/DogAvatarGroup'
import LoadingScreen from '@/components/elements/organisms/LoadingScreen/LoadingScreen'
// eslint-disable-next-line no-restricted-imports
import { Answer, Question } from '@/components/types'

import STYLES from './SurveyPage.module.sass'

import { NavigateContext } from '../App'
import ChoiceQuestion from './screens/ChoiceQuestion/ChoiceQuestion'
import MultipleChoiceQuestion from './screens/MultipleChoiceQuestion/MultipleChoiceQuestion'
import RatingQuestion from './screens/RatingQuestion/RatingQuestion'
// Temporary Utils
import { onboardingQuizHelper } from './utils'

type QuestionTypes =
  | typeof ChoiceQuestion
  | typeof RatingQuestion
  | typeof MultipleChoiceQuestion
type SurveyPageProps = {
  slug?: string
}

// all user data queried for here will be available in the question text
const SURVEY_PAGE_QUERY = gql`
  query SurveyPageQuery($slug: String!) {
    user {
      id
      dogs {
        id
        name
        possessivePronoun
        breed {
          id
          key
          name
        }
        dogProfile {
          id
          avatarUrl
        }
      }
      shippingCountryCode
    }
    survey(slug: $slug) {
      id
      name
      finishedUrl
      finishedMessage
      questions {
        id
        text
        questionType
        options {
          id
          text
          image {
            src
          }
          goodOption
        }
      }
    }
  }
`

const SUBMIT_ANSWERS_MUTATION = gql`
  mutation SubmitSurveyAnswers($answers: [AnswerInput!]!) {
    submitSurveyAnswers(answers: $answers) {
      answers {
        id
        option {
          text
        }
        question {
          id
          text
        }
        value
      }
    }
  }
`

const SurveyPageComponent = withApollo(
  ({ slug }: SurveyPageProps): JSX.Element => {
    const navigate = useNavigate()
    const navigateContext = useContext(NavigateContext)
    slug ||= window.location.pathname.split('/')[2]
    const defaultData = { user: { dogs: [] }, survey: { questions: [] } }
    const { loading, data = defaultData } = useQuery(SURVEY_PAGE_QUERY, {
      variables: { slug }
    })
    const [submitSurveyAnswers, { data: submissionData }] = useMutation(
      SUBMIT_ANSWERS_MUTATION
    )
    const [percentageComplete, setPercentageComplete] = useState(0)
    const [answers, setAnswers] = useState<Array<Array<Answer>>>([])
    const [currentQuestion, setCurrentQuestion] = useState(0)
    const nextQuestionDelay = 500
    const {
      user,
      survey: { finishedUrl, finishedMessage, questions }
    } = data

    // if the test is finished submit the answers and trigger analytics
    useEffect(() => {
      if (percentageComplete === 100) {
        submitSurveyAnswers({
          variables: { answers: answers.flat() }
        })
      }
    }, [percentageComplete, submitSurveyAnswers, answers])

    // if the answers have been successfully saved redirect to the redirect url
    useEffect(() => {
      if (submissionData) {
        if (slug === 'onboarding_quiz') {
          // This is a temporary bodge to make the new survey system work with the
          // existing onboarding quiz results
          const recommendations = onboardingQuizHelper(answers, questions)

          window.analytics.track('Onboarding Quiz Actions Shown', {
            actionsGiven: recommendations,
            appWebview: !!window.ReactNativeWebView
          })
          window.analytics.track('Onboarding Quiz Results Given', {
            answers,
            appWebview: !!window.ReactNativeWebView
          })
        }

        navigateContext(finishedUrl, finishedUrl)
      }
    }, [submissionData, finishedUrl, answers, questions, slug, navigateContext])

    // move through the questions when currentQuestion is updated
    useEffect(() => {
      if (slug) {
        // eslint-disable-next-line i18next/no-literal-string
        navigate(`/surveys/${slug}/${currentQuestion}`)
        setPercentageComplete((currentQuestion / questions.length) * 100)
        window.analytics.track('Onboarding Quiz Step Load', {
          currentQuestion,
          appWebview: !!window.ReactNativeWebView
        })
      }
    }, [currentQuestion, setPercentageComplete, questions, slug, navigate])

    // if there are no answers trigger Quiz Start analytics
    useEffect(() => {
      if (answers.length === 0) {
        window.analytics.track('Onboarding Quiz Start', {
          appWebview: !!window.ReactNativeWebView
        })
      }
    }, [answers])

    useEffect(() => {
      if (slug) {
        // eslint-disable-next-line i18next/no-literal-string
        navigate(`/surveys/${slug}/${currentQuestion}`)
        setPercentageComplete((currentQuestion / questions.length) * 100)
      }
    }, [currentQuestion, setPercentageComplete, questions, slug, navigate])

    // store responses coming back from the questions
    const handleAnswer = useCallback(
      (answer) => {
        answers[currentQuestion] = answer
        setAnswers([...answers])
        if (currentQuestion < questions.length) {
          setTimeout(() => {
            setCurrentQuestion(currentQuestion + 1)
          }, nextQuestionDelay)
        }
      },
      [answers, currentQuestion, questions, setCurrentQuestion]
    )

    const goBack = useCallback((): void => {
      if (currentQuestion === 0) {
        navigateContext('/account', '/dashboard')
      } else {
        setCurrentQuestion(currentQuestion - 1)
      }
    }, [currentQuestion, navigateContext])

    const typeToQuestionComponent = (type: string): QuestionTypes => {
      switch (type) {
        case 'single_choice':
          return ChoiceQuestion
        case 'rating':
          return RatingQuestion
        case 'dynamic_recipe_rating':
          return RatingQuestion
        case 'multiple_choice':
          return MultipleChoiceQuestion
        default:
          return ChoiceQuestion
      }
    }

    if (loading) {
      return (
        <LoadingScreen
          isOpen
          title={{
            text: 'loading_screen.initial',
            namespace: 'onboarding_quiz'
          }}
        />
      )
    }

    if (percentageComplete === 100) {
      return (
        <LoadingScreen
          isOpen
          title={{
            text: finishedMessage,
            namespace: 'dynamic',
            variables: { user },
            translate: false
          }}
          variant="animated"
          sprite={DogWaggingTailSprite}
        />
      )
    }

    const routes = questions
      .map((question: Question, index: number) => {
        const path =
          index === 0 ? `/surveys/${slug}` : `/surveys/${slug}/${index}`
        const QuestionComponent = typeToQuestionComponent(question.questionType)
        return (
          <Route
            key={`question-route-${question.id}`}
            path={path}
            element={
              <QuestionComponent
                question={question}
                onAnswer={handleAnswer}
                user={user}
              />
            }
          />
        )
      })
      .reverse() // must be reversed so the route element is last

    return (
      <div className={STYLES.container}>
        <nav className={STYLES.nav}>
          <button type="button" onClick={goBack}>
            <img alt="" src={Arrow} />
          </button>
          <ProgressBar
            fillColour={BRAND_COLOURS.brandBlue500}
            percentFilled={percentageComplete}
          />
          <a href={ACCOUNT_ROUTES.base}>
            <img alt="" src={Close} />
          </a>
        </nav>
        <div className={STYLES.profile}>
          <DogAvatarGroup dogs={user.dogs} />
        </div>
        <Routes>{routes}</Routes>
      </div>
    )
  }
)

const SurveyPage = (): JSX.Element => (
  <BrowserRouter>
    <SurveyPageComponent />
  </BrowserRouter>
)
export { SURVEY_PAGE_QUERY, SurveyPage }
export default SurveyPage
