// @noflow
import { Language } from '@/packs/localisation'
import { differenceInSeconds, format, parseISO } from 'date-fns'
import React, { useEffect, useState } from 'react'

import { localeToDateLocale } from '@/utils/countryCodeHelper'

import BRAND_COLOURS from '@/constants/BrandColours'

import Icon from '@/components/elements/atoms/Icon/Icon'
import type { Props as TextProps } from '@/components/elements/atoms/Text/Text'
import Countdown from '@/components/elements/molecules/Countdown/Countdown'

import STYLES from './CountdownBanner.module.sass'

import { Code as CountryCode } from '@/types'

import Text, { AllowedColours } from '../../atoms/Text/Text'

type Props = {
  namespace: string
  bannerText: TextProps
  endOfLeadTime: string
  handleCloseCountdownBanner?: () => void
  enableCloseCountdownBanner?: boolean
  countdownType?: 'dailyCountdown' | 'weeklyCountdown'
  countdownTextColour?: AllowedColours
  countdownBackgroundColour?: keyof typeof BRAND_COLOURS
  showEndDate?: boolean
  shippingCountryCode: CountryCode
  preferredLanguage: Language
}

const CountdownBanner = ({
  namespace,
  endOfLeadTime,
  bannerText,
  handleCloseCountdownBanner,
  enableCloseCountdownBanner = false,
  countdownType = 'dailyCountdown',
  countdownTextColour,
  countdownBackgroundColour,
  showEndDate = false,
  shippingCountryCode,
  preferredLanguage
}: Props): JSX.Element | null => {
  const [currentDate, setCurrentDate] = useState(new Date())
  const colours = { ...BRAND_COLOURS }

  const copyContext = 'refer_a_friend.campaign.countdown'

  useEffect(() => {
    const tick = setInterval(() => {
      setCurrentDate(new Date())
    }, 1000)

    return () => clearInterval(tick)
  })

  return differenceInSeconds(parseISO(endOfLeadTime), currentDate) > 0 ? (
    <div
      style={{
        backgroundColor: colours[countdownBackgroundColour || 'brandBlue100']
      }}
      className={STYLES.countdownBanner}
    >
      <div className={STYLES.bannerText}>
        <Text
          text={bannerText.text}
          variables={bannerText.variables}
          namespace={bannerText.namespace}
          margin={bannerText.margin}
          variant={bannerText.variant}
          shouldScale={bannerText.shouldScale}
          colour={bannerText.colour}
          translate={false}
        />
        {showEndDate && (
          <Text
            text={`${copyContext}.end`}
            variables={{
              date: format(new Date(endOfLeadTime), 'do MMMM', {
                locale: localeToDateLocale(
                  shippingCountryCode,
                  preferredLanguage
                )
              })
            }}
            colour={bannerText.colour}
            namespace={namespace}
            variant="textRegular14"
            margin={false}
          />
        )}
      </div>
      <Countdown
        countdownType={countdownType}
        targetDate={endOfLeadTime}
        labels={{
          daysLabel: `${copyContext}.days`,
          hoursLabel: `${copyContext}.hours`,
          minutesLabel: `${copyContext}.minutes`,
          secondsLabel: `${copyContext}.seconds`
        }}
        namespace={namespace}
        labelVariant="display16"
        numberVariant="display16"
        variant="small"
        textColour={countdownTextColour}
      />
      {enableCloseCountdownBanner && handleCloseCountdownBanner && (
        <button
          type="button"
          className={STYLES.closeButton}
          onClick={handleCloseCountdownBanner}
        >
          <Icon asset="close" size={12} width={12} accentColour="brandWhite" />
        </button>
      )}
    </div>
  ) : null
}

export { Props }
export default CountdownBanner
