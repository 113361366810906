// @noflow
import { makeVar, useReactiveVar } from '@apollo/client'
import { captureException } from '@sentry/browser'
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React, { useCallback, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { localeToStripeLocale } from '@/utils/localeToStripeLocale'
import * as Sentry from '@/utils/sentry'

import CircleSparksRed from 'assets/images/icons/ornaments/sparks/circle-sparks--red.svg'

import Banner, { bannerMessageState } from '../../components/Banner/Banner'
import { totalAmount } from '../../components/DsAppOrderSummary/DsAppOrderSummary'
import PaymmentOptions from '../../components/PaymentOptions/PaymentOptions'
import Text from '@/components/elements/atoms/Text'
import HeroText from '@/components/elements/organisms/HeroText/HeroText'
import StickyNavigation from '@/components/elements/organisms/StickyNavigation/StickyNavigation'

import STYLES from './PaymentScreen.module.sass'

import { PaymentPageContext } from '../../PayOnOwnDevicePage'
import buySubscriptionWithCard from '../../hooks/stripe/card/buySubscriptionWithCard'
import { PayOnOwnDevicePageRoutes } from '../../routes/RouterWrapper'

type ActivePaymentViews =
  | 'none'
  | 'creditCard'
  | 'iDeal'
  | 'bancontact'
  | 'paypal'

type SubmissionState =
  | { type: 'not-requested' }
  | { type: 'loading' }
  | { type: 'error'; error: string }

type LoginUrlProps = {
  signUpUrl: string
  referralUrl: string
}

const TitleAdornment = ({ namespace }: { namespace: string }): JSX.Element => {
  return (
    <>
      <Text
        translate
        namespace={namespace}
        text={'step_two'}
        variant="textMono16"
      />
      <img
        className={STYLES.titleAdornmentImage}
        src={CircleSparksRed}
        alt=""
      />
    </>
  )
}

const paymentSubmissionState = makeVar<SubmissionState>({
  type: 'not-requested'
})

const userLoginUrl = makeVar<LoginUrlProps>({
  signUpUrl: 'https://butternutbox.com/users/sign_in',
  referralUrl: ''
})

const setSubmissionState = (state: SubmissionState): void => {
  paymentSubmissionState({
    ...state
  })
}

const PaymentScreenComponent = ({
  namespace
}: {
  namespace: string
}): JSX.Element | null => {
  const stripe = useStripe()
  const elements = useElements()
  const navigate = useNavigate()
  const [activePaymentView, setActivePaymentView] =
    useState<ActivePaymentViews>('none')

  const bannerMessage = useReactiveVar(bannerMessageState)
  const submissionState = useReactiveVar(paymentSubmissionState)

  const { paymentPageState } = useContext(PaymentPageContext)
  const { data } = paymentPageState || {}
  const { directSalesPendingSubscription } = data || {}
  const { pendingSubscription } = directSalesPendingSubscription || {}

  const { amount } = useReactiveVar(totalAmount)

  const updateLoginUrl = (newProps: Partial<LoginUrlProps>) => {
    const currentProps = userLoginUrl()
    const updatedProps = { ...currentProps, ...newProps }
    userLoginUrl(updatedProps)
  }

  const navigateToNextStep = useCallback(
    ({ signUpUrl, referralUrl }: LoginUrlProps) => {
      updateLoginUrl({
        signUpUrl: signUpUrl,
        referralUrl: referralUrl
      })
      navigate(PayOnOwnDevicePageRoutes.completeScreen)
    },
    [navigate]
  )

  const buySubscriptionCallback = useCallback(() => {
    if (!stripe || !elements) {
      return captureException(
        `Buy subscription button is enabled without Stripe or Stripe Elements initialised)`
      )
    }

    if (!paymentPageState || !pendingSubscription) {
      return captureException(`Subscriptiption data has not been initialised`)
    }

    switch (activePaymentView) {
      case 'creditCard': {
        buySubscriptionWithCard({
          stripe,
          elements,
          paymentPageState: paymentPageState,
          amount: amount,
          navigateToNextStep
        })
        break
      }
      case 'none': {
        break
      }
      default: {
        Sentry.captureException(
          `activePaymentView is invalid in buySubscriptionCallback on PayOnOwnDevicePage, activePaymentView is ${activePaymentView}`
        )
      }
    }
  }, [
    activePaymentView,
    amount,
    elements,
    navigateToNextStep,
    paymentPageState,
    pendingSubscription,
    stripe
  ])

  if (!paymentPageState || amount === 0) {
    return null
  }

  return (
    <div className={STYLES.titleAdornment}>
      <HeroText
        namespace={namespace}
        subtitle={`plan_steps.payment.header.title`}
        variant="plans"
        topExtras={<TitleAdornment namespace={namespace} />}
      />
      <PaymmentOptions
        activePaymentView={activePaymentView}
        setActivePaymentView={setActivePaymentView}
        submissionState={submissionState}
        navigateToNextStep={navigateToNextStep}
        namespace={namespace}
      />
      {bannerMessage.message && (
        <Banner namespace={namespace} message={bannerMessage.message} />
      )}
      <StickyNavigation
        variant="oneButton"
        disabled={
          !paymentPageState ||
          submissionState.type === 'loading' ||
          activePaymentView !== 'creditCard'
        }
        buttonOne={{
          variant: 'primary',
          text: 'plan_steps.navigation.continue',
          iconColour: 'brandWhite',
          namespace,
          onClick: buySubscriptionCallback
        }}
      />
    </div>
  )
}

const PaymentScreen = ({
  namespace
}: {
  namespace: string
}): JSX.Element | null => {
  const { paymentPageState } = useContext(PaymentPageContext)
  if (!paymentPageState) {
    return null
  }

  const { language, stripeKey } = paymentPageState

  const stripePromise = loadStripe(stripeKey)

  return (
    <Elements
      stripe={stripePromise}
      options={{
        locale: localeToStripeLocale(language)
      }}
    >
      <PaymentScreenComponent namespace={namespace} />
    </Elements>
  )
}

export {
  paymentSubmissionState,
  setSubmissionState,
  ActivePaymentViews,
  LoginUrlProps,
  userLoginUrl
}

export default PaymentScreen
