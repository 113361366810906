// @flow

import { connect } from 'react-redux'
import * as React from 'react'
import DatePickerComponent from '../../../../../shared/DatePicker'
import { deliveriesIcons } from '../../../asset-imports/icons'
import InfoIcon from 'assets/images/icons/info/info--support-blue-300.svg'

import type { State } from '../../../index'
import type { CalendarDate, DeliveryWindow } from '../../../../../shared/DatePicker'
import type { Dispatch } from 'redux'
import { fetchCalendar, changeBoxDeliveryDate } from '../../../thunks'
import { handleDateChange, closeDeliveryDateCalendar, toggleAdjustFutureBoxes } from '../../../actions'

type PresentationalProps = {|
  isOpen: boolean,
  boxId: number,
  deliveryWindow: DeliveryWindow,
  selectedDate: ?CalendarDate,
  previousDeliveryDate: string,
  targetNumberOfCells: number,
  currentDeliveryDate: string,
  adjustFutureBoxes: boolean,
  disableAdjustFutureBoxes: boolean,
  boxDeliveryDates: Array<string>
|}

type ActionProps = {|
  dispatchHandleDateChange: (CalendarDate) => void,
  dispatchFetchCalendar: (string, 'month_for' | 'month_after' | 'month_before', number) => void,
  dispatchCloseDeliveryDateCalendar: () => void,
  dispatchChangeBoxDeliveryDate: (number, string, boolean) => void,
  dispatchToggleAdjustFutureBoxes: () => void
|}

type Props =
  & PresentationalProps
  & ActionProps

const mapStateToProps = (state: State): PresentationalProps => {
  const { deliveryDateCalendar } = state
  const {
    isOpen,
    boxId,
    deliveryWindow,
    selectedDate,
    previousDeliveryDate,
    targetNumberOfCells,
    currentDeliveryDate,
    adjustFutureBoxes,
    disableAdjustFutureBoxes
  } = deliveryDateCalendar

  const { deliveriesDetails } = state
  const boxDeliveryDates = deliveriesDetails.deliveries.map((delivery: any): string => delivery.date_details.delivery_date)

  return {
    isOpen,
    boxId,
    deliveryWindow,
    selectedDate,
    previousDeliveryDate,
    targetNumberOfCells,
    currentDeliveryDate,
    adjustFutureBoxes,
    disableAdjustFutureBoxes,
    boxDeliveryDates
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => {
  const dispatchHandleDateChange = (calendarDate: CalendarDate): void => {
    dispatch(handleDateChange(calendarDate))
  }
  const dispatchFetchCalendar = (date: string, direction: 'month_for' | 'month_after' | 'month_before', boxId: number): void => {
    dispatch(fetchCalendar(date, direction, boxId))
  }
  const dispatchCloseDeliveryDateCalendar = (): void => {
    dispatch(closeDeliveryDateCalendar())
  }
  const dispatchChangeBoxDeliveryDate = (boxId: number, newDate: string, adjustFutureBoxes: boolean): void => {
    dispatch(changeBoxDeliveryDate(boxId, newDate, adjustFutureBoxes))
  }
  const dispatchToggleAdjustFutureBoxes = (): void => {
    dispatch(toggleAdjustFutureBoxes())
  }
  return {
    dispatchHandleDateChange,
    dispatchFetchCalendar,
    dispatchCloseDeliveryDateCalendar,
    dispatchChangeBoxDeliveryDate,
    dispatchToggleAdjustFutureBoxes
  }
}

const renderHeader = (
  dispatchFetchCalendar: ((string, 'month_for' | 'month_after' | 'month_before', number) => void),
  deliveryWindow: DeliveryWindow,
  boxId: number
): React.Node => {
  return (
    <div className='delivery-calendar__header'>
      <button
        // eslint-disable-next-line react/jsx-no-bind
        onClick={(): void => {
          dispatchFetchCalendar(
            // eslint-disable-next-line flowtype/no-flow-fix-me-comments
            // $FlowFixMe
            deliveryWindow.dates[0].full_date,
            'month_before',
            boxId
          )
        }}
        type='button'
      >
        <img
          src={deliveriesIcons.arrow}
          alt='Arrow icon'
          className='icon'
        />
      </button>
      <p>
        { deliveryWindow.month_and_year }
      </p>
      <button
        // eslint-disable-next-line flowtype/no-flow-fix-me-comments
        // $FlowFixMe
        onClick={(): void => dispatchFetchCalendar(deliveryWindow.dates[0].full_date, 'month_after', boxId)} // eslint-disable-line react/jsx-no-bind
        type='button'
      >
        <img
          src={deliveriesIcons.arrow}
          alt='Arrow icon'
          className='icon'
        />
      </button>
    </div>
  )
}

const renderFooter = (
  adjustFutureBoxes: boolean,
  dispatchToggleAdjustFutureBoxes: () => void,
  dispatchCloseDeliveryDateCalendar: () => void,
  dispatchChangeBoxDeliveryDate: (number, string, boolean) => void,
  boxId: number,
  selectedDate: ?CalendarDate,
  disableAdjustFutureBoxes: boolean,
  showWarning: boolean
): React.Node => {
  if (disableAdjustFutureBoxes && adjustFutureBoxes) {
    dispatchToggleAdjustFutureBoxes()
  }
  return (
    <div className='delivery-calendar__footer'>
      <input
        type="checkbox"
        defaultChecked={disableAdjustFutureBoxes ? false : adjustFutureBoxes}
        disabled={disableAdjustFutureBoxes}
        onChange={(): void => dispatchToggleAdjustFutureBoxes()} // eslint-disable-line react/jsx-no-bind
      />
      { 'Adjust Future Boxes' }
      { showWarning &&
        <div className='delivery-calendar__alert'>
          <img
            alt={'An information icon'}
            src={InfoIcon}
          />
          <p>
            { 'This customer already has a box scheduled for this day.' }
          </p>
        </div> }
      <div className='delivery-calendar__footer__buttons'>
        <button
          type='button'
          className='card__button button--dark-grey'
          onClick={(): void => dispatchCloseDeliveryDateCalendar()} // eslint-disable-line react/jsx-no-bind
        >
          { 'CANCEL' }
        </button>
        <button
          type='button'
          className='card__button button--green'
          // eslint-disable-next-line react/jsx-no-bind
          onClick={(): void => {
            // eslint-disable-next-line flowtype/no-flow-fix-me-comments
            // $FlowFixMe
            dispatchChangeBoxDeliveryDate(boxId, selectedDate.full_date, adjustFutureBoxes)
            dispatchCloseDeliveryDateCalendar()
          }}
        >
          { 'SAVE' }
        </button>
      </div>
    </div>
  )
}

const DeliveryDateCalendarModalComponent = ({
  isOpen,
  deliveryWindow,
  selectedDate,
  previousDeliveryDate,
  targetNumberOfCells,
  currentDeliveryDate,
  dispatchHandleDateChange,
  boxId,
  adjustFutureBoxes,
  dispatchToggleAdjustFutureBoxes,
  dispatchCloseDeliveryDateCalendar,
  dispatchChangeBoxDeliveryDate,
  dispatchFetchCalendar,
  disableAdjustFutureBoxes,
  boxDeliveryDates
}: Props): React.Node => {
  const [showWarning, setShowWarning] = React.useState(false)
  if (!isOpen) { return null }
  return (
    <div className='delivery-calendar modal-container'>
      <div className='modal-container__backdrop' />
      <div className='modal-container__modal'>
        { renderHeader(
          dispatchFetchCalendar,
          deliveryWindow,
          boxId
        ) }
        <DatePickerComponent
          deliveryWindow={deliveryWindow}
          // eslint-disable-next-line flowtype/no-flow-fix-me-comments
          // $FlowFixMe
          selectedDate={selectedDate}
          previousDeliveryDate={previousDeliveryDate}
          handleDateChange={dispatchHandleDateChange}
          targetNumberOfCells={targetNumberOfCells}
          currentDeliveryDate={currentDeliveryDate}
          boxDeliveryDates={boxDeliveryDates}
          setShowWarning={setShowWarning}
        />
        { renderFooter(
          adjustFutureBoxes,
          dispatchToggleAdjustFutureBoxes,
          dispatchCloseDeliveryDateCalendar,
          dispatchChangeBoxDeliveryDate,
          boxId,
          selectedDate,
          disableAdjustFutureBoxes,
          showWarning
        ) }
      </div>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeliveryDateCalendarModalComponent)
