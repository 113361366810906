// @noflow
import { useQuery } from '@apollo/client'
import i18next from 'i18next'
import isNull from 'lodash/isNull'
import React from 'react'

import Bowl from 'assets/images/illustrations/bowls/bowl-with-food.svg'

import Text from '@/components/elements/atoms/Text/Text'
import { BoxSummary as IBoxSummary } from '@/components/elements/molecules/BoxSummary/__generated__/BoxSummary'
import { BOX_SUMMARY_QUERY } from '@/components/elements/molecules/BoxSummary/queries'
// Component
import ExpandCard from '@/components/elements/molecules/ExpandCard/ExpandCard'

import { TypeOfPlan } from '../../../../types'

type Props = {
  dogs: number
  planType: TypeOfPlan
}

const howToFeedCopy = (dogs: number, planType: TypeOfPlan): string => {
  const namespace = 'organisms:how_to_feed.'

  if (planType === TypeOfPlan.all) {
    return i18next.t(`${namespace}all.instructions`, { count: dogs })
  } else {
    return i18next.t(`${namespace}mix.instructions`)
  }
}

/**
 * An expandable card with our feeding guidelines
 */
const HowToFeedCard = ({ dogs, planType }: Props): JSX.Element => (
  <ExpandCard
    text={{
      text: 'how_to_feed.label',
      namespace: 'organisms'
    }}
    icon={Bowl}
    identifier="how_to_feed"
  >
    <Text
      text={howToFeedCopy(dogs, planType)}
      translate={false}
      element="div"
    />
  </ExpandCard>
)

/**
 * Populates the the how to feed guide with information of dogs currently on
 * the customers plan
 */
const HowToFeedCardWithQuery = (): JSX.Element | null => {
  const { data } = useQuery<IBoxSummary>(BOX_SUMMARY_QUERY)

  if (!data) return null

  const { subscription, dogs } = data.user

  if (isNull(dogs) || isNull(subscription)) return null

  const { typeOfPlanForCustomer } = subscription.plan

  return <HowToFeedCard dogs={dogs.length} planType={typeOfPlanForCustomer} />
}

export { HowToFeedCardWithQuery, howToFeedCopy, Props }
export default HowToFeedCard
