// @noflow
import { userHasActivePaymentMethod } from '@/context/accessManagement/accessManagement'
import { useOccasion } from '@/context/festiveTheme/festiveTheme'
import { ACCOUNT_ROUTES } from '@/routes'
import { useQuery, useReactiveVar } from '@apollo/client'
import { Grid } from '@mui/material'
import { addDays, isAfter, isBefore, isToday, subDays } from 'date-fns'
import isUndefined from 'lodash/isUndefined'
import uniqBy from 'lodash/uniqBy'
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { SwiperClass } from 'swiper/react'

import { subscriptionDataVar } from '@/services/apollo'

import DachshundInBox from 'assets/images/illustrations/dogs/dachshund-in-box-blue-300.svg'

import { CardSkeleton } from '@/components/elements/atoms/Card/CardSkeleton'
import FlatButton from '@/components/elements/atoms/FlatButton/FlatButton'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import Text from '@/components/elements/atoms/Text/Text'
import { UndeliverableDateAdjustment } from '@/components/elements/molecules/UndeliverableDateAdjustment'
import { DeliveryTracking } from '@/components/elements/organisms/DeliveryTracking'
import { useTransformedDeliveryStatus } from '@/components/elements/organisms/DeliveryTracking/hooks'
import { OrderCarouselV3 } from '@/components/elements/organisms/OrderCarousel'
import { OrderSummaryModalFragment as OrderSummary } from '@/components/modals/OrderSummaryModal/fragments/__generated__/OrderSummaryModalFragment'
import BoxDetailsV3 from '@/components/pages/DashboardV3/components/BoxDetailsV3/BoxDetailsV3'
import {
  upcomingBoxQueryV3,
  upcomingBoxQueryV3Variables,
  upcomingBoxQueryV3_user_ordersByDate,
  upcomingBoxQueryV3_user_ordersByDate_Box,
  upcomingBoxQueryV3_user_ordersByDate_NonCoreOrder
} from '@/components/pages/DashboardV3/components/UpcomingBoxesV3/queries/__generated__/upcomingBoxQueryV3'
import {
  Order,
  unifyOrder
} from '@/components/pages/DashboardV3/components/UpcomingBoxesV3/utilities'

import STYLES from './UpcomingBoxesV3.module.sass'

import { UPCOMING_BOX_QUERY_V3 } from './queries/queries'

import { Code as CountryCode, Status, SubscriptionStatus } from '@/types'

import { DASHBOARD_PAGE_SECTIONS, upcomingBoxesLoaded } from '../../DashboardV3'

/**
 * Find which order should be selected first. If there is no upcoming order select the last delivered
 * @param ordersByDate
 */
const getInitialOrder = (
  ordersByDate: upcomingBoxQueryV3_user_ordersByDate[]
): string | undefined => {
  if (ordersByDate.length === 0) return undefined

  const nextOrder = ordersByDate.find((order) => {
    if ('isoDeliveryDate' in order)
      return isAfter(new Date(order.isoDeliveryDate), new Date())

    if (
      'orderParts' in order &&
      'deliveryDate' in order.orderParts &&
      order.orderParts.length > 0
    )
      return isAfter(new Date(order.orderParts[0].deliveryDate), new Date())

    return false
  })

  if (!isUndefined(nextOrder) && 'id' in nextOrder) return nextOrder.id

  const lastOrder = ordersByDate[ordersByDate.length - 1]

  if ('id' in lastOrder) return lastOrder.id

  return undefined
}

const NoOrders = () => {
  const subscription = useReactiveVar(subscriptionDataVar)

  return (
    <div className={STYLES.noOrders}>
      <img
        alt=""
        src={DachshundInBox}
        className={STYLES.noOrdersIllustration}
      />
      <Text
        text="home.upcoming_boxes.no_orders.title"
        namespace="account"
        variant="display18"
      />
      {!userHasActivePaymentMethod(subscription?.paymentMethods) && (
        <Text text="home.upcoming_boxes.no_orders.body" namespace="account" />
      )}
    </div>
  )
}

const INITIAL_SLIDE_FETCH_COUNT = 6
const SLIDE_FETCH_COUNT = 3

type Props = {
  filter?: (Order['type'] | 'past')[]
}

const UPCOMING_BOX_QUERY_VARIABLES = {
  offset: 0,
  limit: INITIAL_SLIDE_FETCH_COUNT,
  order: 'desc'
}

/**
 * A section consisting of an orders carousel and order details component
 * @constructor
 */
const UpcomingBoxesV3 = ({ filter }: Props): JSX.Element | null => {
  const navigate = useNavigate()
  const [swiper, setSwiper] = useState<SwiperClass>()
  const [boxId, setBoxId] = useState<string | undefined>()
  const [order, setOrder] = useState<Order | undefined>()
  const [orderSummary, setOrderSummary] = useState<OrderSummary | undefined>()
  const [firstBoxId, setFirstBoxId] = useState<string | undefined>()
  const { state } = useLocation()
  const { incompleteBeforeDelivery } = useTransformedDeliveryStatus(
    (order?.deliveryStatus as Status) ?? Status.unknown
  )
  const subscriptionData = useReactiveVar(subscriptionDataVar)
  const { xmas } = useOccasion()

  const { data, loading, fetchMore } = useQuery<
    upcomingBoxQueryV3,
    upcomingBoxQueryV3Variables
  >(UPCOMING_BOX_QUERY_V3, {
    notifyOnNetworkStatusChange: true,
    variables: UPCOMING_BOX_QUERY_VARIABLES
  })

  // set initial
  useEffect(() => {
    if (!isUndefined(data)) {
      setFirstBoxId(data.user.subscription?.firstBox.id)
      if (!isUndefined(swiper)) {
        if (isUndefined(order) && data.user.ordersByDate.length > 0) {
          const ordersByDate = [...data.user.ordersByDate].reverse()

          // if have returned from order details page
          const previouslyViewedBoxId = state?.previous?.params?.boxId

          const id = !isUndefined(previouslyViewedBoxId)
            ? previouslyViewedBoxId
            : getInitialOrder(ordersByDate)

          setBoxId(id)
        }
      }
    }
  }, [data, order, state?.previous?.params?.boxId, swiper])

  // update the displayed order when a boxId is set
  useEffect(() => {
    if (!isUndefined(boxId) && !isUndefined(data)) {
      const order:
        | upcomingBoxQueryV3_user_ordersByDate_Box
        | upcomingBoxQueryV3_user_ordersByDate_NonCoreOrder
        | undefined = data.user.ordersByDate.find(
        (order) => 'id' in order && order.id === boxId
      )

      if (!isUndefined(order)) {
        setOrder(unifyOrder(order))
        setOrderSummary(order)
      }
    }
  }, [boxId, data])

  useEffect(() => {
    if (!loading && data) {
      upcomingBoxesLoaded(true)
    }
  }, [loading, data])

  const { user } = data || {}
  const {
    ordersByDate,
    subscription,
    shippingCountryCode,
    shippingCountry,
    currentDeliveryAreaPreference
  } = user || {}

  const {
    id,
    type,
    deliveryDate,
    cutOffDate,
    shippingDate,
    isFull,
    meals,
    extras,
    amendable,
    deliveryStatus,
    isTrialBox,
    isBoosted,
    descriptor,
    numberOfPouches
  } = order || {}

  const orderDetailsReady =
    !isUndefined(id) &&
    !isUndefined(type) &&
    !isUndefined(amendable) &&
    !isUndefined(deliveryDate) &&
    !isUndefined(cutOffDate) &&
    !isUndefined(shippingDate)

  const keepFetching = ordersByDate
    ? ordersByDate.findIndex((order) => order.id === firstBoxId) === -1
    : true

  const carouselOrders = useMemo(() => {
    return ordersByDate
      ? uniqBy(
          ordersByDate
            .map((order) => unifyOrder(order))
            .filter((order) => {
              if (!Array.isArray(filter)) return true
              if (filter.includes(order.type)) return false
              if (
                filter.includes('past') &&
                isAfter(subDays(new Date(), 3), new Date(order.deliveryDate))
              )
                return false
              return true
            })
            .reverse(),
          (order) => order.id
        )
      : []
  }, [filter, ordersByDate])

  const initialSlide = useMemo(() => {
    return carouselOrders.findIndex((order) =>
      isAfter(new Date(order.deliveryDate), new Date())
    )
  }, [carouselOrders])

  const navigateToChangeDate = useCallback(() => {
    navigate(`${ACCOUNT_ROUTES.changeDate}/${boxId}`)
  }, [boxId, navigate])

  // if the slider reaches the furthest most order then fetch the next set of orders if possible
  const increaseOrderHistory = useCallback(() => {
    if (!loading) {
      fetchMore({
        variables: {
          offset: carouselOrders.length,
          limit: SLIDE_FETCH_COUNT
        }
      })
    }
  }, [loading, fetchMore, carouselOrders.length])

  const noOrders = !isUndefined(data) && carouselOrders.length === 0
  const showDeliveryTrackingFlow = (() => {
    const currentDate = new Date()
    const formattedDeliveryDate = order?.deliveryDate ?? currentDate
    const status = order?.deliveryStatus
    const deliveryState = localStorage.getItem(
      // eslint-disable-next-line i18next/no-literal-string
      `deliveryState-${order?.type}-${order?.id}`
    )

    // If delivery is successful, return false
    if (deliveryState && JSON.parse(deliveryState) === 'delivered_success')
      return false

    if (!status) return false

    // If 3 days have passed from delivery date, return false
    if (isAfter(currentDate, addDays(formattedDeliveryDate, 3))) {
      return false
    }

    // Check if before delivery and incomplete
    if (
      isBefore(currentDate, formattedDeliveryDate) &&
      incompleteBeforeDelivery
    )
      return false

    // Otherwise, use the data value
    return data?.shouldSeeDeliveryTracking === 'true'
  })()

  return (
    <Grid container rowSpacing={3}>
      <SectionWrapper
        id={DASHBOARD_PAGE_SECTIONS.upcomingBoxes}
        headerTypography={{
          text: 'home.upcoming_boxes.v3.title',
          namespace: 'account',
          margin: subscriptionData?.status !== SubscriptionStatus.active
        }}
        margin={{
          bottom: 0
        }}
        headerRightAdornment={
          order?.amendable
            ? {
                variant: 'custom',
                content: (
                  <FlatButton
                    variant={xmas ? 'blue200' : 'yellow200'}
                    text={{
                      text: 'home.upcoming_boxes.v3.edit_delivery_cta',
                      namespace: 'account',
                      translate: true
                    }}
                    onClick={navigateToChangeDate}
                    identifier="box_details.edit_meals"
                    data-testid="edit-delivery-date-button"
                  />
                )
              }
            : undefined
        }
      >
        <Fragment>
          {descriptor === 'past' || isTrialBox ? (
            <SectionWrapper margin={{ top: 0, bottom: 16 }}>
              <Text
                text="home.upcoming_boxes.v3.subtitle.past"
                namespace="account"
                variant="textRegular18"
                variables={{
                  numberOfPouches
                }}
                margin={false}
              />
            </SectionWrapper>
          ) : (
            subscriptionData?.status === SubscriptionStatus.active &&
            subscription?.plan && (
              <SectionWrapper margin={{ top: 0, bottom: 16 }}>
                <Text
                  text="home.upcoming_boxes.v3.subtitle.active"
                  namespace="account"
                  variant="textRegular18"
                  variables={{
                    numberOfPouches: subscription?.plan.numberOfPouches,
                    durationInWeeks: subscription?.plan.durationInWeeks,
                    count: subscription?.plan.durationInWeeks
                  }}
                  margin={false}
                />
              </SectionWrapper>
            )
          )}
          <Grid item className={STYLES.slider}>
            {noOrders && <NoOrders />}
            <OrderCarouselV3
              selected={boxId}
              orders={carouselOrders}
              onLazyLoadPrev={keepFetching ? increaseOrderHistory : undefined}
              slider={setSwiper}
              onSlideClick={setBoxId}
              initialSlide={initialSlide}
            />
          </Grid>
        </Fragment>
      </SectionWrapper>
      {showDeliveryTrackingFlow && order ? (
        <Grid item width="100%">
          <DeliveryTracking
            order={order}
            deliveredToday={isToday(order.deliveryDate)}
          />
        </Grid>
      ) : (
        <UndeliverableDateAdjustment selectedOrder={order} />
      )}
      <Grid item width="100%">
        {order && orderDetailsReady ? (
          <BoxDetailsV3
            id={id}
            type={type}
            address={order.address}
            amendable={amendable}
            descriptor={order.descriptor}
            deliveryDate={deliveryDate}
            cutOffDate={cutOffDate}
            meals={meals}
            extras={extras}
            isFull={isFull}
            deliveryStatus={deliveryStatus}
            isBoostedBox={isBoosted}
            isTrialBox={isTrialBox}
            subscriptionStatus={subscriptionData?.status}
            invoiceIssued={order.invoiceIssued}
            shippingCountryCode={shippingCountryCode || CountryCode.GB}
            invoiceId={order.invoiceId}
            showInvoiceDownloadDirections={
              shippingCountry?.showInvoiceDownloadDirections
            }
            preferredLanguage={user?.preferredLanguage}
            invoices={order.invoices}
            carrierName={
              currentDeliveryAreaPreference?.preferredCarrierService?.carrier
                ?.name
            }
            orderSummary={orderSummary}
          />
        ) : (
          isUndefined(data) && <CardSkeleton height="40rem" />
        )}
      </Grid>
    </Grid>
  )
}

export default UpcomingBoxesV3
export { UPCOMING_BOX_QUERY_VARIABLES }
