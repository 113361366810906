import { gql } from '@apollo/client'

const SURVEY_CARD_QUERY = gql`
  query SurveyCardQuery($startingUrl: String) {
    user {
      id
      preferredLanguage
    }
    survey(startingUrl: $startingUrl) {
      id
      name
      slug
      startingUrl
      finishedMessage
      questions {
        id
        text
        ctaText
        ctaDestination
        questionType
        order
        options {
          id
          text
        }
      }
    }
  }
`

export { SURVEY_CARD_QUERY }
