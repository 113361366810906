// @noflow
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import times from 'lodash/times'
import React from 'react'

import BCorpLogo from '@/components/elements/atoms/BCorpLogo/BCorpLogo'

// Styles
import STYLES from './Footer.module.sass'

import SkeletonParagraph from '../../atoms/SkeletonParagraph/SkeletonParagraph'
import SkeletonTitle from '../../atoms/SkeletonTitle/SkeletonTitle'

const FooterSkeleton = (): JSX.Element => (
  <footer className={STYLES.wrapper}>
    <div className={STYLES.footerWrapper}>
      <Container maxWidth="lg">
        <Grid container className={STYLES.footerGridContainer}>
          <Grid item md={2} />
          {times(3).map((i) => (
            <Grid
              key={`column${i}`}
              item
              xs={6}
              md={2}
              className={STYLES.footerLinksContent}
            >
              <SkeletonTitle align="left" />
              <SkeletonParagraph width={'90%'} count={4} shortLine={false} />
            </Grid>
          ))}
          <Grid item xs={6} md={2} container className={STYLES.footerLogo}>
            <BCorpLogo />
          </Grid>
          <Grid item md={2} />
        </Grid>
      </Container>
    </div>
  </footer>
)

export { FooterSkeleton }
