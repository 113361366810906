// @noflow
import type { Language } from '@/packs/localisation'
import { makeVar, useReactiveVar } from '@apollo/client'
import React, { useCallback, useState } from 'react'

import DogStanding from 'assets/images/illustrations/dogs/dog-standing.svg'

import NotificationContainer from '@/components/elements/molecules/NotificationContainer/NotificationContainer'
import TopNavBanner from '@/components/elements/molecules/TopNavBanner/TopNavBanner'
import LoadingScreen from '@/components/elements/organisms/LoadingScreen/LoadingScreen'
import WizardFooter from '@/components/elements/organisms/WizardFooter/WizardFooter'
import type { Props as FooterProps } from '@/components/elements/organisms/WizardFooter/WizardFooter'
import WizardNavigation from '@/components/elements/organisms/WizardNavigation/WizardNavigation'
import CancelPlanUpdateWarningModal from '@/components/pages/PlansPage/components/CancelPlanUpdateWarningModal/CancelPlanUpdateWarningModal'

import STYLES from './WizardNavigationLayout.module.sass'

import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'

type Props = {
  children: JSX.Element | Array<JSX.Element>
  percentFilled: number
  namespace?: string
  isGuest: boolean
  preferredLanguage: Language
  shippingCountryCode: CountryCode
  hero?: {
    title: string
    subtitle?: string
    variables?: { [key: string]: string | number }
    subtitleVariables?: { [key: string]: string | number }
  }
  footer: FooterProps
}

const loadingState = makeVar(false)

const WizardNavigationLayout = ({
  children,
  isGuest,
  percentFilled,
  namespace = '',
  hero,
  footer,
  preferredLanguage,
  shippingCountryCode
}: Props): JSX.Element => {
  const isLoading = useReactiveVar(loadingState)
  const [navigatingAway, setNavigatingAway] = useState<boolean>(false)
  const onClose = useCallback(() => setNavigatingAway(true), [])

  return (
    <div className={STYLES.container}>
      <NotificationContainer autoClose={3000} />
      <LoadingScreen
        isOpen={isLoading}
        title={{ namespace: namespace, text: 'loading_screen.text' }}
        variant={'static'}
        image={DogStanding}
      />
      {isGuest && (
        <TopNavBanner
          shippingCountryCode={shippingCountryCode}
          preferredLanguage={preferredLanguage}
        />
      )}
      {hero ? (
        <WizardNavigation
          percentFilled={percentFilled}
          title={hero.title}
          subtitle={hero.subtitle}
          namespace={namespace}
          variables={hero.variables}
          subtitleVariables={hero.subtitleVariables}
          variant={!isGuest ? 'dashboard' : undefined}
          close={!isGuest ? onClose : undefined}
        />
      ) : (
        ''
      )}
      <div className={STYLES.stepContainer}>
        <div className={STYLES.stepContainerWrapper}>{children}</div>
      </div>
      {footer.variant === 'freshFacts' && (
        <WizardFooter
          variant={footer.variant}
          title={footer.title}
          subtitle={footer.subtitle}
          illustration={footer.illustration}
        />
      )}
      {footer.variant === 'customerLove' && (
        <WizardFooter
          namespace={footer.namespace}
          variant={footer.variant}
          title={footer.title}
          subtitle={footer.subtitle}
          iconAlt={footer.iconAlt}
          illustration={footer.illustration}
        />
      )}
      <CancelPlanUpdateWarningModal
        open={navigatingAway}
        toggle={setNavigatingAway}
      />
    </div>
  )
}

export type { Props }
export { WizardNavigationLayout, loadingState }
export default WizardNavigationLayout
