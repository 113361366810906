/* eslint-disable i18next/no-literal-string */
const imgPathPrefix = 'Web/photos/ugc'
const NLReviews = [
  {
    path: `${imgPathPrefix}/jonahenhazel`,
    alt: 'instagram_carousel.nl.slide_1.image_alt',
    instagram_handle: 'jonahenhazel',
    text: 'Thanks for the treato’s and fetchball! 😍 @butternutboxnl @butternutbox they are pawlicious 👌'
  },
  {
    path: `${imgPathPrefix}/nusa_doodle`,
    alt: 'instagram_carousel.nl.slide_2.image_alt',
    instagram_handle: 'nusa_doodle',
    text: 'Vandaag mijn eerste box ontvangen van @butternutbox.nl 🐶'
  },
  {
    path: `${imgPathPrefix}/beagletoby_`,
    alt: 'instagram_carousel.nl.slide_3.image_alt',
    instagram_handle: 'beagletoby_',
    text: 'I love this food! 😋🥕 We zijn blij dat we echt vers voedsel van @butternutbox.nl kunnen proberen! ☘️'
  },
  {
    path: `${imgPathPrefix}/jj.and.pina`,
    alt: 'instagram_carousel.nl.slide_4.image_alt',
    instagram_handle: 'jj.and.pina',
    text: 'Pina loves her @butternutbox.nl and we never had problems switching over. Just the opposite!'
  },
  {
    path: `${imgPathPrefix}/poppyanddotty`,
    alt: 'instagram_carousel.nl.slide_5.image_alt',
    instagram_handle: 'poppyanddotty',
    text: 'Dit is de mooiste dag van ons leven. Eindelijk heeft ons baasje Butternut Box besteld... Nog even glimlachen voor foto.'
  },
  {
    path: `${imgPathPrefix}/nugget_chi`,
    alt: 'instagram_carousel.nl.slide_6.image_alt',
    instagram_handle: 'nugget_chi',
    text: 'Spoiled by @butternutbox.nl'
  },
  {
    path: `${imgPathPrefix}/marley.shepsky`,
    alt: 'instagram_carousel.nl.slide_7.image_alt',
    instagram_handle: 'marley.shepsky',
    text: 'When I get goodies with my food, I wear them like a boss 🐶 #marleyshepsky #puppyface #puplife🐶 #butternutter #TeamRed'
  }
]

export { NLReviews }
