import { gql } from '@apollo/client'

import { MARKETING_PREFERENCE_FRAGMENT } from '@/components/elements/organisms/MarketingPreferences/fragments/marketingPreferenceFragment'

const MARKETING_PREFERENCE = gql`
  ${MARKETING_PREFERENCE_FRAGMENT}
  query MarketingPreferenceQuery {
    user {
      ...marketingPreferenceFragment
    }
  }
`

export { MARKETING_PREFERENCE }
