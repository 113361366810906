// @flow

import { connect } from 'react-redux'

import { getApplicableDiscountsDetails } from '../../thunks'

import type { ApplicableDiscountPartsDetails } from '../../message_types'
import type { State } from '../../index'
import type { Dispatch } from 'redux'

import ApplicableDiscountsComponent from '../../components/user_details/ApplicableDiscountsComponent'

type PresentationalProps = $ReadOnly<{|
  applicableDiscountsDetails: ApplicableDiscountPartsDetails,
  subscriptionId: number,
  isFetching: boolean
|}>

type ActionProps = $ReadOnly<{|
  dispatchGetApplicableDiscountsDetails: ((number) => void)
|}>

export type ComponentProps =
  & PresentationalProps
  & ActionProps

function mapStateToProps (state: State): PresentationalProps {
  const { applicableDiscountsDetails, selectedUserIds, fetchingStatuses } = state
  const subscriptionId = selectedUserIds.subscriptionId
  const isFetching = fetchingStatuses.appliedDiscountsDetails
  return { applicableDiscountsDetails, subscriptionId, isFetching }
}

function mapDispatchToProps (dispatch: Dispatch): ActionProps {
  const dispatchGetApplicableDiscountsDetails = (subscriptionId: number): void => {
    dispatch(getApplicableDiscountsDetails(subscriptionId))
  }
  return { dispatchGetApplicableDiscountsDetails }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicableDiscountsComponent)
