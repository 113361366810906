// @flow

import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import clone from 'lodash/clone'

import PostSignUpRoutes from '../postSignUpRoutes'
import Header from './../../shared/wizards/Header'
import * as ACTIONS from '../actions'
import * as THUNKS from '../thunks'
import { capitaliseFirstLetter } from '../../../utils/StringHelper'
import { ILLUSTRATIONS, ICONS } from '../assets'
import Chevron from '../../shared/images/Chevron'
import BRAND_COLOURS from '../../../constants/BrandColours'
import { pronounContext } from '@/utils/StringHelper'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

import type { Dispatch } from 'redux'
import type { State } from '../reducers'
import type { State as RequestReducerState } from '../reducers/requestsReducer'
import type { State as DogProfileReducerState } from '../reducers/dogProfilesReducer'
import type { State as GlobalAttributesState } from '../reducers/globalAttributesReducer'
import type { Dog } from '../message_types'
import type { RailsModelID as ID } from '../../../shared_types/ids'
import type { Gender } from '@/shared_types/rails_models/dogs'

type PresentationalProps = {|
  dogs: Array<Dog>,
  request: $PropertyType<RequestReducerState, 'photoUpdate'>,
  dogProfiles: DogProfileReducerState,
  deliveriesReceived: $PropertyType<GlobalAttributesState, 'deliveriesReceived'>
|}

type ActionProps = {|
  progressWizardStep: () => void,
  setSelectedImage: (ID, $PropertyType<GlobalAttributesState, 'deliveriesReceived'>) => void
|}

type Props =
  & PresentationalProps
  & ActionProps

const mapStateToProps = ({
  globalAttributes,
  requests,
  dogProfiles
}: State): PresentationalProps => {
  const { dogs, deliveriesReceived } = globalAttributes
  const { photoUpdate } = requests

  return {
    dogs,
    request: photoUpdate,
    dogProfiles,
    deliveriesReceived
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => {
  const progressWizardStep = (): void => dispatch(ACTIONS.nextWizardStep())

  const setSelectedImage = (dogID: ID, deliveriesReceived: $PropertyType<GlobalAttributesState, 'deliveriesReceived'>): void => {
    const el = document.getElementById(`image-selector-${dogID}`)

    if (!el) throw new Error('Could not find image-selector element')

    const image: ?File = ((el: any): HTMLInputElement).files[0] // eslint-disable-line flowtype/no-weak-types

    // if no image selected, return
    if (!image) return

    const dogAvatar = ((document.getElementById(`upload-photo__card__avatar__image-${dogID}`): any): HTMLImageElement) // eslint-disable-line flowtype/no-weak-types
    const originalSrc = dogAvatar.src
    dispatch(ACTIONS.setAvatarSrc(dogID, ILLUSTRATIONS.SpinningDog))

    const params = {
      image,
      dogId: dogID,
      onSuccess: (avatarUrl: string): void => {
        window.analytics.track(
          'Dog Profile Photo Uploaded',
          {
            dog_id: dogID,
            context: 'Dog Details Wizard',
            deliveries_recieved: deliveriesReceived
          }
        )

        dispatch(ACTIONS.setAvatarSrc(dogID, avatarUrl))
      },
      onError: (): void => {
        dispatch(ACTIONS.setAvatarSrc(dogID, originalSrc))
      }
    }

    dispatch(THUNKS.imageUpload(params))
  }

  return { progressWizardStep, setSelectedImage }
}

const openFileInput = (id: ID): void => {
  const el = document.getElementById(`image-selector-${id}`)

  if (!el) throw new Error(`Could not find image-selector-${id} element`)

  el.click()
}

const placeholderAvatars = [
  ILLUSTRATIONS.PuppyAvatar,
  ILLUSTRATIONS.AdultAvatar,
  ILLUSTRATIONS.SpinningDog
]

const UploadPhoto = ({
  progressWizardStep,
  dogs,
  dogProfiles,
  request,
  setSelectedImage,
  deliveriesReceived
}: Props): React.Node => {
  const { t } = useTranslation('post_signup_wizard')
  const copyContext = 'upload_photo'
  const dogGenders = dogs.map(({ gender }: Dog): Gender => (gender))
  const setSelectedImageForDog = dogs.reduce((acc: {| [ID]: () => void |}, { id }: Dog): {| [ID]: () => void |} => {
    acc[id] = (): void => setSelectedImage(id, deliveriesReceived)

    return acc
  }, clone({}))

  const openFileInputForDog = dogs.reduce((acc: {| [ID]: () => void |}, { id }: Dog): {| [ID]: () => void |} => {
    acc[id] = (): void => openFileInput(id)

    return acc
  }, clone({}))

  return (
    <React.Fragment>
      <Header headerSubtitle={ t(`${copyContext}.subtitle`, { count: dogs.length }) }>
        <span
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: t(`${copyContext}.title`, { context: pronounContext(dogGenders, i18next.language) })
          }}
        />
      </Header>
      <div className='step-body'>
        <div className='step-body__content'>
          {
            dogs.map(({ id, name }: Dog): React.Node => (
              <div
                className='upload-photo'
                key={name}
              >
                <div className='upload-photo__labels'>
                  <p>
                    { capitaliseFirstLetter(name) }
                  </p>

                  {
                    !placeholderAvatars.includes(dogProfiles[id].avatarSrc) && (
                      <div className='upload-photo__labels__compliment'>
                        <span>
                          { dogProfiles[id].compliment }
                        </span>
                      </div>
                    )
                  }
                </div>
                <div className='upload-photo__card'>
                  <div className='upload-photo__card__avatar'>
                    <button
                      onClick={openFileInputForDog[id]}
                      type='button'
                    >
                      <img
                        alt=''
                        className={`upload-photo__card__avatar__image ${request.status === 'In Flight' && request.dogId === id ? 'animated spin animated--infinite animated--long' : ''}`}
                        id={`upload-photo__card__avatar__image-${id}`}
                        src={dogProfiles[id].avatarSrc}
                      />
                    </button>
                    <button
                      className='upload-photo__card__avatar__button'
                      disabled={request.status === 'In Flight'}
                      onClick={openFileInputForDog[id]}
                      type='button'
                    >
                      <img
                        alt=''
                        src={placeholderAvatars.includes(dogProfiles[id].avatarSrc) ? ICONS.PlusIconWhite : ICONS.Recycle }
                      />
                    </button>
                    <input
                      accept='image/*'
                      id={`image-selector-${id}`}
                      onChange={setSelectedImageForDog[id]}
                      type='file'
                    />
                  </div>
                  <button
                    className='upload-photo__card__description'
                    onClick={openFileInputForDog[id]}
                    type='button'
                  >
                    {
                      placeholderAvatars.includes(dogProfiles[id].avatarSrc)
                        ? (
                          <React.Fragment>
                            <p className='upload-photo__card__description__text'>
                              { t(`${copyContext}.add_photo`) }
                            </p>
                            <p className='upload-photo__card__description__subtext'>
                              { t(`${copyContext}.optional`) }
                            </p>
                          </React.Fragment>
                        )
                        : t(`${copyContext}.change_photo`)
                    }
                  </button>
                </div>
              </div>
            ))
          }
          <Link
            className='next-button'
            onClick={progressWizardStep}
            to={PostSignUpRoutes.DogDescription}
          >
            { t(`${copyContext}.button_text_next`) }
            <Chevron
              color={BRAND_COLOURS.brandWhite}
              rotation={0}
            />
          </Link>
          <small className='upload-photo__disclaimer'>
            { t(`${copyContext}.footnote`) }
          </small>
        </div>
      </div>
    </React.Fragment>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadPhoto)
