// @flow

import * as React from 'react'
import i18next from 'i18next'
import SausageInHeart from '../../../../../../assets/images/illustrations/dogs/dachshund-in-heart.svg'
import { countryCodeToDefaultCurrencySymbol } from '@/utils/countryCodeHelper'
import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'

type Props = {|
  donate: number => void,
  shippingCountryCode: CountryCode
|}

type State = {|
  donationAmount: string,
  donationRequestInFlight: boolean
|}

class AwaitingDonation extends React.Component<Props, State> {
  constructor (props: Props): void {
    super(props)
    this.state = {
      donationAmount: '',
      donationRequestInFlight: false
    }
  }

  namespace = 'ambassadors:dashboard.balance_tab'

  onDonationAmountChange = (event: SyntheticEvent<HTMLInputElement>): void => {
    const donationAmount = event.currentTarget.value
    this.setState({ donationAmount })
  }

  donate = (): void => {
    this.setState(
      {
        donationRequestInFlight: true
      }
    )
    const { donationAmount } = this.state
    const donation = parseFloat(donationAmount)
    if (!donation || donation <= 0) return

    const donationInHundreths = Math.round(donation * 100)
    this.props.donate(donationInHundreths)
  }

  render (): React.Node {
    return (
      <div className='ambassador-balance__card'>
        <div className='ambassador-balance__card__header'>
          <h2>
            { i18next.t(`${this.namespace}.partner_charities`) }
          </h2>
          <img
            src={SausageInHeart}
            alt='An illustration of a sausage dog in a love heart'
          />
          <p>
            { i18next.t(`${this.namespace}.we_work_with`) }
          </p>

          <div className='card__item ambassador-balance__card__input'>
            <span className=''>
              { countryCodeToDefaultCurrencySymbol(this.props.shippingCountryCode) }
              <input
                type='number'
                placeholder='00.00'
                step={0.01}
                min={0}
                value={this.state.donationAmount}
                onChange={this.onDonationAmountChange}
              />
            </span>
            <button
              className='btn btn-updated--blue'
              onClick={this.donate}
              disabled={this.state.donationRequestInFlight}
            >
              { i18next.t(`${this.namespace}.donate`) }
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default AwaitingDonation
