// @noflow
import { useOccasion } from '@/context/festiveTheme/festiveTheme'
import { ACCOUNT_ROUTES } from '@/routes'
import { useReactiveVar } from '@apollo/client'
import i18next from 'i18next'
import { upperFirst } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { dogsDataVar } from '@/services/apollo'

import { possessive } from '@/utils/StringHelper'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import Image from '@/components/elements/atoms/Image/Image'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import Text from '@/components/elements/atoms/Text'
import ActionCard from '@/components/elements/molecules/ActionCard/ActionCard'
import { Order } from '@/components/pages/Dashboard/components/upcomingBoxes/utilities'

import STYLES from './UndeliverableDateAdjustment.module.sass'

import { Descriptor } from '@/types'

type Props = {
  selectedOrder: Order | null | undefined
}

const UndeliverableDateAdjustment = ({
  selectedOrder
}: Props): JSX.Element | null => {
  const { xmas } = useOccasion()
  const { t } = useTranslation('molecules')
  const navigate = useNavigate()
  const { dogs = [] } = useReactiveVar(dogsDataVar) ?? {}
  const { windowWidth } = useWindowSize()
  const isMobile = windowWidth < BREAKPOINTS.md

  if (!selectedOrder?.deliveryDateAdjustedBySystem) {
    return null
  }

  const { id: boxId, descriptor, isBoxDelivered, amendable } = selectedOrder

  const possessiveDogName = possessive(
    upperFirst(dogs[0].name),
    i18next.language
  )

  const handleManageDeliveryDate = (): void => {
    navigate(`${ACCOUNT_ROUTES.changeDate}/${boxId}`)
  }

  const isNextBox = descriptor === Descriptor.next && amendable
  const isPastBox = descriptor === Descriptor.past

  if (isBoxDelivered || isPastBox) {
    return null
  }

  return (
    <SectionWrapper margin={{ top: 24, bottom: 0 }}>
      <ActionCard
        dataTestId="undeliverable-date-adjustment"
        variant={xmas ? undefined : 'brandYellow200'}
        button={
          isNextBox
            ? {
                text: {
                  text: 'undeliverable_date_adjustment.next.cta',
                  namespace: 'molecules'
                },
                onClick: handleManageDeliveryDate,
                identifier: 'undeliverable_date_adjustment.next_box.cta',
                variant: xmas ? 'blue' : 'yellow100'
              }
            : undefined
        }
        padding={isMobile ? 16 : 32}
        fill={false}
      >
        <SectionWrapper
          headerTypography={{
            text: `undeliverable_date_adjustment.${descriptor}.title`,
            namespace: 'molecules',
            variant: 'display20',
            variables: {
              possessiveDogName,
              count: dogs.length
            },
            margin: false
          }}
          margin={{
            top: isMobile ? 8 : 0,
            bottom: isMobile ? 8 : 0
          }}
          headerClassName={`${STYLES.contentHeader} ${
            isNextBox ? STYLES.contentHeaderNextBox : ''
          }`}
        >
          <div
            className={`${STYLES.content} ${
              isNextBox ? STYLES.contentNextBox : ''
            }`}
          >
            <Text
              text={`undeliverable_date_adjustment.${descriptor}.text`}
              namespace="molecules"
              variant="textRegular16"
              shouldScale={false}
              margin={false}
            />
            <Image
              alt={t('undeliverable_date_adjustment.image_alt')}
              slug="info-box"
              image={{
                width: isMobile ? 90 : 118,
                height: isMobile ? 64 : 96
              }}
            />
          </div>
        </SectionWrapper>
      </ActionCard>
    </SectionWrapper>
  )
}

export { UndeliverableDateAdjustment }
