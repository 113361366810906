// @noflow
import Cookies from 'js-cookie'
import React from 'react'

const setPseudolocalisationCookie = () => {
  Cookies.set('pseudolocalise', 'true')
  window.location.reload()
}

const removePseudolocalisationCookie = () => {
  Cookies.set('pseudolocalise', 'false')
  window.location.reload()
}

const setInContextEditorCookie = () => {
  Cookies.set('phraseapp_enabled', 'true')
  window.location.reload()
}

const removeInContextEditorCookie = () => {
  Cookies.remove('phraseapp_enabled')
  window.location.reload()
}

const LocalisationTab = (): React.ReactElement => (
  <div className="localisation-tab">
    <div className="tab-title">
      <h2>
        {/* eslint-disable-next-line i18next/no-literal-string */}
        {'Localisation'}
      </h2>
    </div>
    <div className="localisation-tab__content">
      <button
        type="button"
        className="button"
        onClick={setPseudolocalisationCookie}
      >
        {/* eslint-disable-next-line i18next/no-literal-string */}
        {'Pseudolocalise'}
      </button>
      <button
        type="button"
        className="button"
        onClick={removePseudolocalisationCookie}
      >
        {/* eslint-disable-next-line i18next/no-literal-string */}
        {'Undo pseudolocalisation'}
      </button>
      <p>
        {/* eslint-disable-next-line i18next/no-literal-string */}
        {`Important: you will need a Phrase login for the in-context editor. Only one person should be editing at a time. Your edits will feed into our production copy. `}
      </p>
      <button
        type="button"
        className="button"
        onClick={setInContextEditorCookie}
      >
        {/* eslint-disable-next-line i18next/no-literal-string */}
        {'Enable Phrase in-context editor'}
      </button>
      <button
        type="button"
        className="button"
        onClick={removeInContextEditorCookie}
      >
        {/* eslint-disable-next-line i18next/no-literal-string */}
        {'Disable Phrase in-context editor'}
      </button>
    </div>
  </div>
)

export default LocalisationTab
