import { Locale, format } from 'date-fns'
import React from 'react'

import useWindowSize from '@/hooks/useWindowSize'

import AlertCard from '@/components/elements/atoms/Alert/AlertCard'
import { Button } from '@/components/elements/atoms/Button'
import Modal from '@/components/elements/atoms/Modal/Modal'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import Text from '@/components/elements/atoms/Text'
import { BREAKPOINTS } from '@/components/templates/Base'

import STYLES from './SkipBoxModal.module.sass'

import { CourierDeliveryDatesQuery_user_subscription_nextNBoxes as Box } from '../../queries/__generated__/CourierDeliveryDatesQuery'

type Props = {
  box: Box
  showConfirmSkipModal: boolean
  toggleConfirmSkip: () => void
  locale: Locale
  handleSkipBox: () => void
  loading: boolean
  currentDeliveryDate: Date
  nextBoxOutForDelivery: false | Box
}

const textContext = 'change_date.skip_box'

const SkipBoxModal = ({
  box,
  currentDeliveryDate,
  showConfirmSkipModal,
  toggleConfirmSkip,
  locale,
  handleSkipBox,
  loading,
  nextBoxOutForDelivery
}: Props): JSX.Element => {
  const { windowWidth } = useWindowSize()

  const formatDate = (date?: Date) =>
    date
      ? format(new Date(date), 'cccc do MMMM', {
          locale
        })
      : ''
  const currentBoxFormattedDate = formatDate(currentDeliveryDate)

  const skippedBoxFormattedDate = formatDate(box?.isoDeliveryDate)

  const unskippableFormattedDate = formatDate(
    nextBoxOutForDelivery && nextBoxOutForDelivery?.isoDeliveryDate
  )

  return (
    <Modal
      {...(windowWidth > BREAKPOINTS.md
        ? { position: 'top' }
        : { bottomSticky: true })}
      isModalOpen={showConfirmSkipModal}
      width={600}
      showCloseButton
      onCloseButtonClick={toggleConfirmSkip}
    >
      <div className={STYLES.modalInner}>
        <Text
          namespace="account"
          text={`${textContext}.title`}
          variant="display24"
          margin={false}
        />
        <Text
          namespace="account"
          text={`${textContext}.subtitle`}
          variables={{
            date: currentBoxFormattedDate
          }}
          variant="textRegular14"
          margin={false}
        />

        <div className={STYLES.highlightText}>
          <Text
            namespace="account"
            variant="textRegular14"
            text={`${textContext}.move_box_to`}
          />
          <Text
            translate={false}
            variant="display16"
            text={skippedBoxFormattedDate}
          />
        </div>

        {nextBoxOutForDelivery && (
          <SectionWrapper margin={{ bottom: 24 }}>
            <AlertCard
              variant="info"
              message={{
                namespace: 'account',
                text: `${textContext}.unskippable_info`,
                variables: { date: unskippableFormattedDate }
              }}
            />
          </SectionWrapper>
        )}

        <Button
          dataTestId="change-date-skip-box-button-confirm"
          disabled={loading}
          typography={{
            text: `${textContext}.confirm`,
            namespace: 'account'
          }}
          onClick={handleSkipBox}
          identifier="skip_box_confirm"
          fullWidth
        />

        <Button
          disabled={loading}
          typography={{
            text: `${textContext}.cancel`,
            namespace: 'account'
          }}
          onClick={toggleConfirmSkip}
          variant="secondary"
          identifier="skip_box_cancel"
          fullWidth
        />
      </div>
    </Modal>
  )
}

export default SkipBoxModal
