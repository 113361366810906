import React from 'react'

import STYLES from './IconButton.module.sass'

import Icon, { IconColours, Props as IconProps } from '../../atoms/Icon/Icon'

type Size = 24 | 32 | 40 | 48 | 50

type Shape = 'circle' | 'square'

type Props = {
  size: Size
  icon: IconProps['asset']
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  shape?: Shape
  iconSize?: number
  iconColor?: IconColours
  variant?: keyof typeof STYLES
  disabled?: boolean
  iconDirection?: IconProps['direction']
}

/**
 * A round button with an icon inside
 * @param icon
 * @param size
 * @param onClick
 * @param shape
 * @param variant
 * @param iconSize - icon size as percentage of button size
 * @param iconColor
 * @param disabled
 * @param iconDirection
 * @constructor
 */
const IconButton = ({
  icon,
  size,
  onClick,
  shape = 'square',
  variant = 'default',
  iconSize = 0.6,
  iconColor = 'brandBlue500',
  disabled,
  iconDirection
}: Props): JSX.Element => {
  return (
    <button
      className={`
        ${STYLES.button}
        ${disabled ? STYLES.disabled : ''}
        ${STYLES[`size${size}`]}
        ${STYLES[shape]}
        ${STYLES[variant]}
      `}
      type="button"
      onClick={onClick}
    >
      <Icon
        size={Math.round(size * iconSize)}
        asset={icon}
        accentColour={iconColor}
        direction={iconDirection}
      />
    </button>
  )
}

export { Props }
export default IconButton
