// @flow

import * as React from 'react'
import BankAccountForm from '../partials/balance_tab/BankAccountForm'
import CharityDonation from '../partials/balance_tab/CharityDonation'
import CompleteTrialAccount from '../partials/balance_tab/CompleteTrialAccount'
import InactiveAccount from '../partials/balance_tab/InactiveAccount'
import NoSubscriptionAccount from '../partials/balance_tab/NoSubscriptionAccount'
import PaidAccount from '../partials/balance_tab/PaidAccount'
import PayoutHistory from '../partials/balance_tab/PayoutHistory'

import type { Ambassador } from '../message_types'
import type { PayoutType } from './../balance'
import type {
  BankAccount
  // $FlowFixMe as we are moving away from Alt.js
} from './index'

type Props = {|
  ambassador: Ambassador,
  createPayout: (type: PayoutType, amount: number, successHandler: string => void) => void,
  persistBankAccount: (bankAccount: BankAccount) => void,
  deleteBankAccount: (id: number) => void,
  visible: boolean
|}

class BalanceTab extends React.Component<Props> {

  ambassadorDomainUrl = (): string => {
    return `${window.location.protocol}//${window.location.host}`
  }

  mainDomainUrl = (): string => {
    return `${window.location.protocol}//${window.location.host.split('ambassadors.')[1]}`
  }

  accountSpecificBoxCard = (): React.Node => {
    const {user} = this.props.ambassador
    if (!user) {
      return <NoSubscriptionAccount />
    }

    const {subscription} = user
    if (!subscription) {
      return <NoSubscriptionAccount />
    }

    if (subscription.status !== 'active') {
      return (
        <InactiveAccount
          status={subscription.status}
          baseUrl={this.mainDomainUrl()}
        />
      )
    } else if (subscription.account_type === 'paid') {
      return (
        <PaidAccount
          shippingCountryCode={this.props.ambassador.shipping_country_code}
          myBoxesUrl={`${this.ambassadorDomainUrl()}/ambassadors/sign_in_as_user?redirect_url=${encodeURI(`${this.mainDomainUrl()}/dashboard/boxes`)}`}
          redeemCredits={(amount: number, onSuccess: () => void): void => {
            this.props.createPayout('off_next_box', amount, onSuccess)
          }}
        />
      )
    } else if (subscription.trial_status === 'complete') {
      return <CompleteTrialAccount baseUrl={this.mainDomainUrl()} />
    } else {
      return null
    }
  }

  allThreeCards = (): React.Node => (
    <div className='grid--no-gutter'>
      <div className='col-12 col-lg-4'>
        <BankAccountForm
          shippingCountryCode={this.props.ambassador.shipping_country_code}
          balance={this.props.ambassador.balance.formatted_amount}
          bankAccount={this.props.ambassador.bank_account}
          persistBankAccount={this.props.persistBankAccount}
          deleteBankAccount={this.props.deleteBankAccount}
        />
      </div>
      <div className='col-12 col-md-6 col-lg-4'>
        <CharityDonation
          shippingCountryCode={this.props.ambassador.shipping_country_code}
          preferredLanguage={this.props.ambassador.preferred_language}
          donate={(amount: number, onSuccess: () => void): void => {
            this.props.createPayout('charity_donation', amount, onSuccess)
          }}
          remainingBalance={this.props.ambassador.balance.formatted_amount}
        />
      </div>
      <div className='col-12 col-md-6 col-lg-4'>
        { this.accountSpecificBoxCard() }
      </div>
    </div>
  )

  onlyCharityAndBoxCard = (): React.Node => (
    <div className='grid--no-gutter'>
      <div className='col-12 col-md-6'>
        <CharityDonation
          shippingCountryCode={this.props.ambassador.shipping_country_code}
          preferredLanguage={this.props.ambassador.preferred_language}
          donate={(amount: number, onSuccess: () => void): void => {
            this.props.createPayout('charity_donation', amount, onSuccess)
          }}
          remainingBalance={this.props.ambassador.balance.formatted_amount}
        />
      </div>
      <div className='col-12 col-md-6'>
        { this.accountSpecificBoxCard() }
      </div>
    </div>
  )

  render (): React.Node {
    const { ambassador } = this.props
    const { payouts } = ambassador
    return (
      <div className={`visible-${this.props.visible.toString()} tab ambassador-balance`}>
        <div className='ambassador-balance__title'>
          <h1>Your balance is <span>{ this.props.ambassador.balance.formatted_amount }</span></h1>
          <p className='white-font'>How do you want to spend your { this.props.ambassador.balance.formatted_amount }?</p>
        </div>
        {
          this.props.ambassador.eligible_for_cash_payouts ? (
            this.allThreeCards()
          ) : (
            this.onlyCharityAndBoxCard()
          )
        }
        {
          payouts.length > 0 && (
            <PayoutHistory
              shippingCountryCode={this.props.ambassador.shipping_country_code}
              payouts={payouts}
              preferredLanguage={this.props.ambassador.preferred_language}
            />
          )
        }
      </div>
    )
  }
}

export default BalanceTab
