// @flow

import * as MESSAGES from '../actions/messages'

import type { RequestReducerAction } from '../actions'
import type { RailsModelID as ID } from '../../../shared_types/ids'

type RequestStatus =
  | 'In Flight'
  | 'Complete'

type State = {|
  profileBuilding: {
    status: RequestStatus
  },
  traitsUpdate: {
    status: RequestStatus
  },
  activitiesUpdate: {
    status: RequestStatus
  },
  favouriteGamesUpdate: {
    status: RequestStatus
  },
  descriptionsUpdate: {
    status: RequestStatus
  },
  photoUpdate: {
    status: RequestStatus,
    dogId: ?ID
  }
|}

// All requests should be successful to begin with
const initialState: State = {
  profileBuilding: {
    status: 'In Flight'
  },
  traitsUpdate: {
    status: 'Complete'
  },
  activitiesUpdate: {
    status: 'Complete'
  },
  favouriteGamesUpdate: {
    status: 'Complete'
  },
  descriptionsUpdate: {
    status: 'Complete'
  },
  photoUpdate: {
    status: 'Complete',
    dogId: null
  }
}

const reducer = (state: State = initialState, action: RequestReducerAction): State => {
  switch (action.type) {
    case MESSAGES.START_REQUEST: {
      return Object.freeze({
        ...state,
        [action.name]: {
          ...state[action.name],
          dogId: action.dogId,
          status: 'In Flight'
        }
      })
    }
    case MESSAGES.COMPLETE_REQUEST: {
      if (action.error) {
        throw new Error(action.error)
      }
      return Object.freeze({
        ...state,
        [action.name]: {
          ...state[action.name],
          dogId: null,
          status: 'Complete'
        }
      })
    }
    default: {
      (action.type: empty) // eslint-disable-line
      return state
    }
  }
}

export type { State, RequestStatus }
export { initialState }

export default reducer
