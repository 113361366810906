import { ACCOUNT_ROUTES } from '@/routes'
import isEmpty from 'lodash/isEmpty'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

// Components
import FlatButton from '@/components/elements/atoms/FlatButton/FlatButton'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import Text from '@/components/elements/atoms/Text'
// Queries
import { upcomingBoxQueryV3_user_ordersByDate_Box_address as Address } from '@/components/pages/DashboardV3/components/UpcomingBoxesV3/queries/__generated__/upcomingBoxQueryV3'

type Props = {
  address: Address
  amendable: boolean
  namespace: string
  copyContext: string
}

const DeliveryAddress = ({
  address,
  amendable,
  namespace,
  copyContext
}: Props): JSX.Element => {
  const navigate = useNavigate()

  const openEditAddress = useCallback(() => {
    navigate(ACCOUNT_ROUTES.editDeliveryAddress)
  }, [navigate])

  return (
    <SectionWrapper
      margin={0}
      headerTypography={{
        variant: 'display16',
        namespace,
        margin: false,
        text: `${copyContext}.delivery_to`,
        translate: true
      }}
      headerAlignment="end"
      headerRightAdornment={
        amendable
          ? {
              variant: 'custom',
              content: (
                <FlatButton
                  text={{
                    text: `${copyContext}.edit`,
                    variant: 'textRegular14',
                    namespace: 'account',
                    translate: true
                  }}
                  onClick={openEditAddress}
                  identifier="box_details.edit_address"
                />
              )
            }
          : undefined
      }
    >
      <Text
        variant="textRegular18"
        colour="brandBlue500"
        text={
          isEmpty(address.address2)
            ? `${address.address1}, ${address.postcode}`
            : `${address.address1}, ${address.address2}, ${address.postcode}`
        }
        margin={false}
        translate={false}
      />
    </SectionWrapper>
  )
}

export default DeliveryAddress
