// @noflow
import { useReactiveVar } from '@apollo/client'
import { TOptions } from 'i18next'
import React, { useCallback, useEffect, useState } from 'react'

import TwoDogsTalk from 'assets/images/illustrations/dogs/two-dogs-talk.svg'

import LoadingScreen from '@/components/elements/organisms/LoadingScreen/LoadingScreen'

import {
  customerIssueManagementState,
  pageLoadingState,
  pageLoadingVariantState
} from '../../CustomerIssueManagementPage'

type Props = {
  children: JSX.Element
}

const CustomerIssueManagementLoading = ({ children }: Props): JSX.Element => {
  const customerIssueManagementData = useReactiveVar(
    customerIssueManagementState
  )
  const pageLoadingValue = useReactiveVar(pageLoadingState)
  const pageLoadingVariantValue = useReactiveVar(pageLoadingVariantState)
  const [title, setTitle] = useState({
    namespace: customerIssueManagementData.namespace,
    text: 'loading_screen.default',
    variables: {}
  })

  const setLoadingScreenAttributes = useCallback(
    (text: string, variables: TOptions = {}) => {
      setTitle((prev) => ({
        ...prev,
        text,
        variables
      }))
    },
    []
  )

  useEffect(() => {
    switch (pageLoadingVariantValue) {
      case 'wereStillHere':
        setLoadingScreenAttributes('loading_screen.were_still_here')
        break
      case 'submittingData':
        setLoadingScreenAttributes('loading_screen.submitting')
        break
      default:
        setLoadingScreenAttributes('loading_screen.default')
    }
  }, [pageLoadingVariantValue, setLoadingScreenAttributes])

  if (pageLoadingValue) {
    return (
      <LoadingScreen
        isOpen={pageLoadingValue}
        title={title}
        image={TwoDogsTalk}
        variant={'static'}
      />
    )
  } else {
    return children
  }
}

export default CustomerIssueManagementLoading
