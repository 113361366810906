import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import React, { useCallback, useEffect, useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import * as Sentry from '@/utils/sentry'
import Validator from '@/utils/validator'

import ErrorIcon from 'assets/images/icons/error--red-circle-bg.svg'

// Components
import segmentTrack from '@/components/analytics/Analytics'
import { Button } from '@/components/elements/atoms/Button'
import Image from '@/components/elements/atoms/Image/Image'
import Separator from '@/components/elements/atoms/Separator/Separator'
import Text from '@/components/elements/atoms/Text'
import NotificationContainer from '@/components/elements/molecules/NotificationContainer/NotificationContainer'
import NotificationContent from '@/components/elements/molecules/NotificationContent/NotificationContent'
import WizardNavigation from '@/components/elements/organisms/WizardNavigation/WizardNavigation'
import MarketingConsent from '@/components/pages/SignupWizardPage/components/MarketingConsent'
import LoadingScreenContent from '@/components/pages/SimplifiedPlansPage/components/LoadingScreenContent/LoadingScreenContent'

import STYLES from './PetParent.module.sass'

import { CURRENT_USER } from '../../../SignupWizardPage/queries/userQuery'
import { GUEST_UPDATE_DETAILS } from '../../mutations/GuestUpdateDetails'
import { GUEST_UPDATE_ITERABLE_UPSERT } from '../../mutations/GuestUpdateIterableUpsert'
import { GUEST_UPDATE_MARKETING_PREFERENCES } from '../../mutations/GuestUpdateMarketingPreferences'
// Queries
import GUEST_QUERY from '../../queries/GuestQuery'

import { CurrentUser as UserQuery } from '../../../SignupWizardPage/queries/__generated__/CurrentUser'
import {
  GuestMarketingMethodPurposeUpdate,
  GuestMarketingMethodPurposeUpdateVariables
} from '../../mutations/__generated__/GuestMarketingMethodPurposeUpdate'
import {
  GuestUpdateIterableUpsert,
  GuestUpdateIterableUpsertVariables
} from '../../mutations/__generated__/GuestUpdateIterableUpsert'
import {
  GuestUserUpdate,
  GuestUserUpdateVariables
} from '../../mutations/__generated__/GuestUserUpdate'
import { SimplifiedGuestQuery } from '../../queries/__generated__/SimplifiedGuestQuery'
import { AuthenticationStrategy, MarketingPreferences } from '@/types'

import { wizardPageState } from '../../../SignupWizardPage/SignupWizardPage'
import {
  currentRouteToCurrentStep,
  currentRouteToPercentFilled,
  currentRouteToTotalStep
} from '../../helpers/plansNavigation'
import { Routes as PlansRoutes } from '../../types/routes'

type Props = {
  namespace: string
  hasRecommendedExtras: boolean
  shouldSeePetParentOnPlans: boolean
}

const showErrorNotification = (errorMessage: string, t: TFunction) => {
  toast.error(
    <NotificationContent
      copy={{
        text: errorMessage,
        namespace: 'wizard_flow'
      }}
    />,
    {
      icon: (
        <img
          src={ErrorIcon}
          alt={t('wizard_flow:error_message.error_icon_alt')}
        />
      ),
      toastId: 'error-notification'
    }
  )
}

const PetParent: React.FC<Props> = ({
  namespace,
  hasRecommendedExtras,
  shouldSeePetParentOnPlans
}) => {
  const copyContext = 'pet_parent'
  const { t } = useTranslation()
  const wizardState = useReactiveVar(wizardPageState)

  const [emailInvalid, setEmailInvalid] = useState<boolean>(false)

  const skipRecipesLoadingScreenStorageKey = 'skipLoadingScreen'
  const skipRecipesLoadingScreenState = localStorage.getItem(
    skipRecipesLoadingScreenStorageKey
  )
  const showLoadingScreen = !JSON.parse(
    skipRecipesLoadingScreenState || 'false'
  )

  const { user } = wizardState
  const { countryCode } = user.location
  const {
    data: guestData,
    error: guestError,
    loading: guestLoading
  } = useQuery<SimplifiedGuestQuery>(GUEST_QUERY)

  const [
    updateMarketingPreferences,
    { loading: updateMarketingPreferencesLoading }
  ] = useMutation<
    GuestMarketingMethodPurposeUpdate,
    GuestMarketingMethodPurposeUpdateVariables
  >(GUEST_UPDATE_MARKETING_PREFERENCES, {
    onError: (error) => {
      Sentry.captureException(
        'Error occurred in GUEST_UPDATE_MARKETING_PREFERENCES mutation',
        {
          extra: {
            error
          },
          tags: {
            product: Sentry.Product.PlansFlow
          }
        }
      )

      showErrorNotification('error_message.generic', t)
    },
    onCompleted: () => {
      window.location.href = '/plans/recipes'
    }
  })

  const [updateIterableUpsert, { loading: updateIterableUpsertLoading }] =
    useMutation<GuestUpdateIterableUpsert, GuestUpdateIterableUpsertVariables>(
      GUEST_UPDATE_ITERABLE_UPSERT,
      {
        onError: (error) => {
          Sentry.captureException(
            'Error occurred in GUEST_UPDATE_ITERABLE_UPSERT mutation',
            {
              extra: {
                error
              },
              tags: {
                product: Sentry.Product.PlansFlow
              }
            }
          )
          showErrorNotification('error_message.generic', t)
        },
        onCompleted: () => {
          if (guestData?.currentUser?.__typename === 'Guest') {
            updateMarketingPreferences({
              variables: {
                authenticationStrategy: AuthenticationStrategy.user,
                purposeIds: user.supportedMarketingMethodPurposeIds,
                userId: guestData.currentUser.id
              }
            })
          }
        }
      }
    )
  const [updateGuestDetails, { loading: updateGuestDetailsLoading }] =
    useMutation<GuestUserUpdate, GuestUserUpdateVariables>(
      GUEST_UPDATE_DETAILS,
      {
        onError: (error) => {
          if (
            error.message.includes(
              'duplicate key value violates unique constraint "index_users_on_lower_email"'
            ) ||
            error.message.includes(
              'duplicate key value violates unique constraint "index_users_on_email"'
            )
          ) {
            showErrorNotification('error_message.email_exists', t)
          } else {
            Sentry.captureException(
              'Error occurred in GUEST_UPDATE_DETAILS mutation',
              {
                extra: {
                  error
                },
                tags: {
                  product: Sentry.Product.PlansFlow
                }
              }
            )
          }
          showErrorNotification('error_message.generic', t)
        },
        onCompleted: () => {
          if (guestData?.currentUser?.__typename === 'Guest') {
            updateIterableUpsert({
              variables: {
                userId: guestData.currentUser.id
              }
            })
          }
        }
      }
    )

  const { data: userData } = useQuery<UserQuery>(CURRENT_USER, {
    onError: (error) => {
      Sentry.captureException(`Error when CURRENT_USER executes`, {
        extra: {
          error: error.message
        },
        tags: {
          product: Sentry.Product.PlansFlow
        }
      })
    }
  })

  const validateEmail = useCallback(
    (value): void => {
      setEmailInvalid(!Validator.isValidEmail(value))
    },
    [setEmailInvalid]
  )

  const handleChange = useCallback(
    (e): void => {
      user.email = e.currentTarget.value.trim()
      wizardPageState({
        ...wizardState,
        user
      })
    },
    [user, wizardState]
  )

  const handleBlur = useCallback(
    (e): void => {
      const email = e.currentTarget.value

      validateEmail(email)

      // Report to Segment any users which blur away with an invalid email
      if (!Validator.isValidEmail(email) && email.length > 0) {
        segmentTrack('User blurred with invalid email', {
          // Will anonymise provided emails with letters being replaced with X
          // and digits replaced with D.
          // E.g: example123@butternutbox.com -> XXXXXXXDDD@XXXXXXXXXXXX.XXX
          email: email.replace(/[a-zA-Z]/g, 'X').replace(/\d/g, 'D')
        })
      }
    },
    [validateEmail]
  )

  const handleBackButton = useCallback(() => {
    window.location.href = '/wizard/snacks'
  }, [])

  useEffect(() => {
    if (!shouldSeePetParentOnPlans) {
      window.location.href = '/wizard/pet-parent'
    }
  }, [shouldSeePetParentOnPlans])

  useEffect(() => {
    localStorage.setItem('wizardState', JSON.stringify(wizardState))
  }, [wizardState])

  const handleMarketing = useCallback(
    (e): void => {
      const { id, checked } = e.currentTarget

      switch (id) {
        case 'marketingPreferences': {
          if (checked) {
            user.marketingPreference = MarketingPreferences.full_consent
            user.supportedMarketingMethodPurposeIds = userData
              ? userData.availableMarketingMethodPurposes.map(({ id }) => id)
              : []
          } else {
            user.marketingPreference = MarketingPreferences.no_consent
            user.supportedMarketingMethodPurposeIds = []
          }
          break
        }
        case 'dealsOnlyPreference': {
          if (checked) {
            user.marketingPreference = MarketingPreferences.deals_only
            user.supportedMarketingMethodPurposeIds = userData
              ? userData.availableMarketingMethodPurposes
                  .filter((purpose) => purpose.marketingPurpose.encouraged)
                  .map((purpose) => purpose.id)
              : []
          } else {
            user.marketingPreference = MarketingPreferences.no_consent
            user.supportedMarketingMethodPurposeIds = []
          }
          break
        }
        default: {
          Sentry.captureException('Missing case for handleMarketing', {
            tags: {
              product: Sentry.Product.PlansFlow
            },
            extra: {
              id
            }
          })
          user.marketingPreference = MarketingPreferences.no_consent
          user.supportedMarketingMethodPurposeIds = []
        }
      }

      wizardPageState({
        ...wizardState,
        user
      })
    },
    [wizardState, user, userData]
  )

  const navigateToPlans = useCallback(async () => {
    localStorage.setItem('skipLoadingScreen', JSON.stringify(true))
    updateGuestDetails({
      variables: {
        userId:
          guestData?.currentUser?.__typename === 'Guest'
            ? guestData.currentUser.id
            : '',
        guestUserDetails: {
          email: user.email
        }
      }
    })
  }, [guestData, updateGuestDetails, user.email])

  if (guestError) {
    Sentry.captureException(`Error in Plans Flow - Pet Parent step`, {
      extra: {
        guestError
      },
      tags: {
        product: Sentry.Product.PlansFlow
      }
    })
  }
  if (guestData?.currentUser?.__typename !== 'Guest') return null

  const { dogs } = guestData?.currentUser
  const foodCategory = dogs.map((dog) =>
    dog.previousFoodTypes.map(({ foodItem }) => foodItem)
  )[0][0]

  const benefitsIcons = ['dog_collar', 'gift_box', 'heart_plus']

  const displayEmail =
    user.email && !user.email.includes('temporary-') ? user.email : ''

  const formIsValid =
    !emailInvalid &&
    displayEmail.length > 0 &&
    !updateMarketingPreferencesLoading &&
    !updateIterableUpsertLoading &&
    !updateGuestDetailsLoading

  return (
    <div className={STYLES.container}>
      <NotificationContainer autoClose={3000} />
      {showLoadingScreen && (
        <LoadingScreenContent
          namespace={namespace}
          foodCategory={foodCategory}
          loading={guestLoading}
          variant={PlansRoutes.PetParent}
          shouldSeePetParentOnPlans={shouldSeePetParentOnPlans}
        />
      )}
      <WizardNavigation
        variant="simplified"
        percentFilled={currentRouteToPercentFilled({
          route: PlansRoutes.PetParent,
          hasRecommendedExtras,
          shouldSeePetParentOnPlans
        })}
        currentStep={currentRouteToCurrentStep({
          route: PlansRoutes.PetParent,
          hasRecommendedExtras,
          shouldSeePetParentOnPlans
        })}
        totalSteps={currentRouteToTotalStep(
          hasRecommendedExtras,
          shouldSeePetParentOnPlans
        )}
        backOnClick={handleBackButton}
      />
      <div className={STYLES.mainSection}>
        <Text
          dataTestId="hero-text-title"
          namespace={namespace}
          text={`${copyContext}.title`}
          variant="display24"
          element="h1"
          align="center"
        />
        <div className={STYLES.labelWrapper}>
          <label htmlFor={'petParentEmail'}>
            <input
              id={'petParentEmail'}
              data-testid={'pet-parent-email-input'}
              type="email"
              name={'email'}
              placeholder={t(`wizard_flow:pet_parent_step.email.placeholder`)}
              onChange={handleChange}
              onBlur={handleBlur}
              value={displayEmail}
              aria-invalid={emailInvalid}
            />
            {emailInvalid && (
              <div className={STYLES.error}>
                <Text
                  text={`pet_parent_step.email.error`}
                  namespace="wizard_flow"
                />
              </div>
            )}
          </label>
          <Button
            typography={{
              text: `${copyContext}.buton`,
              namespace: namespace
            }}
            disabled={!formIsValid}
            variant="primary"
            fullWidth
            onClick={navigateToPlans}
            disableAnalytics={false}
          />
          <div className={STYLES.benefitsWrapper}>
            <Text
              text={`${copyContext}.benefits_list.title`}
              namespace={namespace}
              variant="display16"
              element="h2"
              margin={false}
            />
            <div>
              {benefitsIcons.map((icon, index) => (
                <div className={STYLES.benefit} key={icon}>
                  <Image
                    alt={t(
                      `${namespace}:${copyContext}.benefits_list.item${
                        index + 1
                      }.alt`
                    )}
                    slug={icon}
                    image={{
                      width: 24,
                      height: 24
                    }}
                  />
                  <Text
                    text={`${copyContext}.benefits_list.item${index + 1}.title`}
                    namespace={namespace}
                    margin={false}
                  />
                </div>
              ))}
            </div>
          </div>
          <Separator handdrawn margin={{ top: 24, bottom: 24 }} />
          <div className={STYLES.marketingConsentWrapper}>
            <MarketingConsent
              namespace="wizard_flow"
              countryCode={countryCode}
              marketingPreference={user.marketingPreference}
              handleMarketing={handleMarketing}
              availableMarketingMethodPurposes={
                userData?.availableMarketingMethodPurposes ?? []
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PetParent
