// @noflow

/* eslint-disable i18next/no-literal-string */
import { Code, Language } from '@types'
import isBefore from 'date-fns/isBefore'
import isUndefined from 'lodash/isUndefined'
import React, { useCallback } from 'react'

import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { Options, formatCurrencyWithDecimal } from '@/utils/currency'

import { Props as TextProps } from '@/components/elements/atoms/Text/Text'
import PriceBreakdown, {
  Props as PriceBreakdownProps
} from '@/components/elements/molecules/PriceBreakdown/PriceBreakdown'
import {
  MealBreakdownRow,
  RowId,
  SummaryLabels,
  freeDelivery,
  generateDeliveryRow,
  generateDiscountRow,
  generateRows,
  generateSurchargeRow,
  generateTotalRow,
  showDiscount,
  showExtras,
  showSurcharge
} from '@/components/elements/organisms/BoxPriceBreakdown/helpers'

type Props = {
  context?: string
  total?: number
  meals: MealBreakdownRow[]
  extras?: number
  discount?: number
  boost?: boolean
  discountTotal?: number
  deliveryFee: number
  surchargeTotal?: number
  shippingCountryCode: Code
  preferredLanguage: Language
  isoDeliveryDate?: string
  csrfToken?: string
  isFreeBox?: boolean
  isReplacementBox?: boolean
  invoiceId?: string
  invoiceIssued?: boolean
  showInvoiceDownloadDirections?: boolean
  hideTitle?: boolean
  toggleInvoicesModalOpen?: () => void
}

const generateMealRow = (
  meal: MealBreakdownRow,
  currencyOptions: Options,
  context: string
): TextProps => ({
  text: `${context}.plan_price`,
  variables: {
    daily: formatCurrencyWithDecimal(meal.perDay, currencyOptions),
    box: formatCurrencyWithDecimal(meal.total, currencyOptions)
  }
})

const showDogNames = (meals: Props['meals']) => meals.length > 1

const generateBreakdownRows = (
  meals: Props['meals'],
  surchargeTotal: Props['surchargeTotal'],
  extras: Props['extras'],
  discount: Props['discount'],
  boost: Props['boost'],
  deliveryFee: Props['deliveryFee'],
  currencyOptions: Options,
  context: string
): PriceBreakdownProps['rows'] => {
  // TODO: remove logic to split plan price per dogs as it's causing pricing inconsistencies
  return generateRows(
    // meals
    meals.map((meal) => ({
      label: showDogNames(meals) ? (meal.dogName as string) : `${context}.box`,
      value: generateMealRow(meal, currencyOptions, context),
      id: RowId.meals,
      translate: { label: !showDogNames(meals) }
    })),
    // delivery
    {
      label: `${context}.delivery`,
      value: generateDeliveryRow(
        deliveryFee,
        undefined,
        currencyOptions,
        context
      ),
      translate: { value: freeDelivery(deliveryFee) },
      id: RowId.delivery,
      boost
    },
    // discount
    showDiscount(discount)
      ? {
          ...generateDiscountRow(
            discount as number,
            currencyOptions,
            context,
            false
          ),
          boost
        }
      : undefined,
    // surcharge
    showSurcharge(surchargeTotal)
      ? generateSurchargeRow(surchargeTotal as number, currencyOptions, context)
      : undefined,
    // extras
    showExtras(extras)
      ? [
          {
            label: `${context}.extras`,
            value: formatCurrencyWithDecimal(extras as number, currencyOptions),
            translate: { value: false },
            id: RowId.extras
          }
        ]
      : undefined
  )
}

/**
 * Show a breakdown of the contents and price of a box
 * If showing a box breakdown in the plans flow use IntroBoxPriceBreakdown
 * @param props
 */
const BoxPriceBreakdown = ({
  context = 'box_breakdown',
  total,
  meals,
  extras,
  surchargeTotal,
  boost,
  discount,
  discountTotal = 0,
  deliveryFee,
  shippingCountryCode,
  preferredLanguage,
  invoiceId,
  isFreeBox = false,
  isReplacementBox = false,
  isoDeliveryDate,
  csrfToken,
  invoiceIssued,
  showInvoiceDownloadDirections,
  toggleInvoicesModalOpen,
  hideTitle = false
}: Props): JSX.Element | null => {
  const namespace = 'dashboard'
  const currencyOptions: Options = countryCodeToLocaleCurrency(
    shippingCountryCode,
    preferredLanguage
  )

  const isFree = total === 0 || isFreeBox

  const showDownloadInvoiceButton = useCallback(() => {
    if (invoiceIssued) {
      return true
    } else if (isoDeliveryDate) {
      const today = new Date()
      const boxDeliveredDate = new Date(isoDeliveryDate)
      return isBefore(boxDeliveredDate, today)
    } else {
      return false
    }
  }, [isoDeliveryDate, invoiceIssued])

  return (
    <PriceBreakdown
      title={hideTitle ? undefined : `${context}.title`}
      labels={SummaryLabels(
        discountTotal,
        isReplacementBox,
        context,
        namespace,
        currencyOptions
      )}
      rows={generateBreakdownRows(
        meals,
        surchargeTotal,
        extras,
        discount,
        boost,
        deliveryFee,
        currencyOptions,
        context
      )}
      total={
        !isUndefined(total)
          ? generateTotalRow(total, isFree, currencyOptions, context)
          : undefined
      }
      namespace={namespace}
      invoiceId={invoiceId}
      csrfToken={csrfToken}
      showDownloadInvoiceButton={showDownloadInvoiceButton()}
      shippingCountryCode={shippingCountryCode}
      invoiceIssued={invoiceIssued}
      showInvoiceDownloadDirections={showInvoiceDownloadDirections}
      isFree={isFree}
      toggleInvoicesModalOpen={toggleInvoicesModalOpen}
    />
  )
}

export { Props, BoxPriceBreakdown, generateBreakdownRows }
