// @noflow
import type { Language } from '@/packs/localisation'
import { useQuery } from '@apollo/client'
import Container from '@material-ui/core/Container'
import * as Sentry from '@sentry/browser'
import isNull from 'lodash/isNull'
import React, { useEffect } from 'react'

import { THANK_YOU_PAGE } from '@/constants/RibbonEvents'

import DogWaggingTailSprite from 'assets/images/illustrations/dogs/dog-wagging-tail--sprite.svg'

import DogProfileSection from './components/DogProfileSection/DogProfileSection'
import withApollo from '@/components/apollo/withApollo'
import DashboardFooter from '@/components/elements/organisms/DashboardFooter/DashboardFooter'
import DownloadAppSection from '@/components/elements/organisms/DownloadAppSection/DownloadAppSection'
import HeroText from '@/components/elements/organisms/HeroText/HeroText'
import LoadingScreen from '@/components/elements/organisms/LoadingScreen/LoadingScreen'
import { thankYouQuery } from '@/components/pages/ThankYouPage/__generated__/thankYouQuery'
import RAFSection from '@/components/pages/ThankYouPage/components/RAFSection/RAFSection'
import GoToAccount from '@/components/thank_you/components/blocks/GoToAccount/GoToAccount'
import NextSteps from '@/components/thank_you/components/blocks/NextSteps/NextSteps'

import STYLES from './ThankYouPage.module.sass'

import Base from '../../templates/Base'
import { THANK_YOU_QUERY } from './queries'

enum PageSections {
  nextSteps,
  referAFriend,
  dogProfile,
  goToAccount,
  appNudge
}

const defaultOrder: PageSections[] = [
  PageSections.dogProfile,
  PageSections.referAFriend,
  PageSections.appNudge,
  PageSections.nextSteps,
  PageSections.goToAccount
]

type Props = {
  variant: Language
}

const GeneratePageSections = (order: PageSections[], data: thankYouQuery) => {
  const { systemShouldShowAppContent } = data || {}

  const sections: JSX.Element[] = []

  const { user, whatsAppSingleDog, whatsAppMultipleDogs, header } = data

  const { shippingCountryCode, preferredLanguage, subscription, dogs, id } =
    user

  if (isNull(dogs)) {
    Sentry.captureException(
      `Thank you page cannot be rendered as no dogs were passed`
    )
    return null
  }

  const { nextNBoxes, referralLink } = subscription

  if (nextNBoxes.length === 0) {
    Sentry.captureException(
      `Thank you page cannot be rendered as no box was passed`
    )
    return null
  }

  const { cutOffDate, isoDeliveryDate } = nextNBoxes[0]

  // eslint-disable-next-line react/destructuring-assignment
  order.forEach((section) => {
    switch (section) {
      case PageSections.nextSteps:
        sections.push(
          <React.Fragment key="next-steps-section">
            <NextSteps
              dogs={dogs}
              editable
              cutOffDate={new Date(cutOffDate)}
              deliveryDate={new Date(isoDeliveryDate)}
              shippingCountryCode={shippingCountryCode}
            />
          </React.Fragment>
        )
        break

      case PageSections.referAFriend:
        sections.push(
          <React.Fragment key="RAF-section">
            <RAFSection
              dogs={dogs}
              userId={id}
              referralLink={referralLink.url}
              preferredLanguage={preferredLanguage}
              header={header.text}
              whatsappSingleDog={whatsAppSingleDog.text}
              whatsappMultipleDogs={whatsAppMultipleDogs.text}
              background={
                // eslint-disable-next-line react/destructuring-assignment
                order.length > 0 && order[0] === PageSections.appNudge
                  ? 'brandYellow200'
                  : undefined
              }
            />
          </React.Fragment>
        )
        break

      case PageSections.goToAccount:
        sections.push(
          <React.Fragment key="go-to-account-section">
            <GoToAccount
              dogs={dogs}
              hasAPuppy={
                !!dogs.find(({ ageInMonths }): boolean => ageInMonths < 12)
              }
            />
          </React.Fragment>
        )
        break

      case PageSections.dogProfile:
        sections.push(
          <React.Fragment key="dog-profile-section">
            <DogProfileSection
              dogs={dogs}
              background={sections.length === 0 ? 'brandYellow200' : undefined}
            />
          </React.Fragment>
        )
        break

      case PageSections.appNudge:
        // don't show the app nudge to users in countries where it hasn't launched
        if (systemShouldShowAppContent !== 'true') return

        sections.push(
          <div
            className={`${STYLES.appNudgeWrapper} ${
              sections.length === 0 ? STYLES.brandYellow100 : ''
            }`}
            key="app-nudge-section"
          >
            <Container maxWidth="xl">
              <DownloadAppSection
                pageContext="thank_you_page"
                shippingCode={shippingCountryCode}
              />
            </Container>
          </div>
        )
        break
    }
  })

  return sections
}

const ThankYouPage = ({ variant }: Props): JSX.Element | null => {
  const namespace = 'thank_you'

  const { loading, data, error } = useQuery<thankYouQuery>(THANK_YOU_QUERY, {
    variables: {
      headerSlug: 'header',
      whatsappSingleDogSlug: 'whatsapp_single_dog',
      whatsappMultipleDogsSlug: 'whatsapp_multiple_dogs'
    }
  })

  // Now the customer's subscription has been created we can remove the
  // localStorage and sessionStorage items that were used to store the
  // subscription data
  useEffect(() => {
    const localStorageKeys = [
      'wizardState',
      'checkout_state',
      'simplifiedPendingSubscription',
      'simplified_selected_plan_details'
    ]

    const sessionStorageKeys = ['gclid']

    localStorageKeys.forEach((key) => {
      window.localStorage.removeItem(key)
    })

    sessionStorageKeys.forEach((key) => {
      window.sessionStorage.removeItem(key)
    })

    if (window.ribbon) window.ribbon('trigger', THANK_YOU_PAGE)
  }, [])

  if (error) {
    Sentry.captureException(error)
  }

  if (loading || !data) {
    return (
      <LoadingScreen
        language={variant}
        isOpen
        variant="animated"
        sprite={DogWaggingTailSprite}
        title={{
          text: 'loading_screen.title',
          namespace: 'thank_you'
        }}
      />
    )
  }

  return (
    <>
      <Base background="brandYellow100">
        <HeroText title="hero.title_html" namespace={namespace} />
        <div className={STYLES.sections}>
          {GeneratePageSections(defaultOrder, data)}
        </div>
      </Base>
      <DashboardFooter />
    </>
  )
}

export default withApollo(ThankYouPage)
