// @noflow
import React from 'react'

import type { Locale } from '@/utils/countryCodeHelper'

import { ReferAFriendPageQuery } from '@/components/pages/ReferAFriendPage/queries/__generated__/ReferAFriendPageQuery'
import Base from '@/components/templates/Base'

import STYLES from './GiveAndGetTab.module.sass'

import type { Currency } from '@/shared_types/rails_models/shipping_countries'

import CardsCarousel from './CardsCarousel/CardsCarousel'
import DiscountSection from './DiscountSection/DiscountSection'
import GiveAndGetCard from './GiveAndGetCard/GiveAndGetCard'

type Props = {
  namespace: string
  locale: Locale
  referralLink: ReferAFriendPageQuery['user']['subscription']['referralLink']
  copyLinkMessage: string
  currency: Currency
}

const GiveAndGetTab = ({
  namespace,
  referralLink,
  locale,
  copyLinkMessage,
  currency
}: Props): JSX.Element => {
  return (
    <Base background={undefined}>
      <div className={STYLES.container}>
        <div className={STYLES.discountCodeSection}>
          <GiveAndGetCard
            copyLinkMessage={copyLinkMessage}
            currency={currency}
            locale={locale}
            namespace={namespace}
            referralLink={referralLink}
          />
          <DiscountSection
            namespace={namespace}
            referralLink={referralLink}
            locale={locale}
            currency={currency}
          />
        </div>
        <div className={STYLES.cardsCarousel}>
          <CardsCarousel
            namespace={namespace}
            referralLink={referralLink}
            locale={locale}
            currency={currency}
          />
        </div>
      </div>
    </Base>
  )
}

export default GiveAndGetTab
