import { useReactiveVar } from '@apollo/client'
import React, { useCallback, useState } from 'react'

import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { formatCurrencyWithDecimal } from '@/utils/currency'

import ContactCustomerLoveButton from '../../../components/ContactCustomerLove/ContactCustomerLoveButton'
import { Button } from '@/components/elements/atoms/Button'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from '../ResolutionOffering.module.sass'

import { CustomerIssueReportEntriesSubmission_response_resolutions_details_ApplyDiscounts as DiscountDetails } from '../../../mutations/__generated__/CustomerIssueReportEntriesSubmission'
import { SelfResolutionCategories_user_subscription_box_potentialMissingItems as PotentialMissingItem } from '../../../queries/__generated__/SelfResolutionCategories'

import { selfResolutionPageState } from '../../../SelfResolutionPage'
import useSelfResolutionTracking from '../../../analytics'

type Props = {
  namespace: string
  discountDetails: DiscountDetails
  affectedItems: Array<PotentialMissingItem>
  applyDiscount: () => void
}

type AffectedItemsProps = {
  namespace: string
  affectedItems: Array<PotentialMissingItem>
}

const AffectedItems = ({
  namespace,
  affectedItems
}: AffectedItemsProps): JSX.Element => {
  return (
    <div className={STYLES.affectedItems}>
      {affectedItems.map((affectedItem) => {
        return (
          <Text
            key={affectedItem.productId}
            namespace={namespace}
            text={'resolutionOffering.discount.productAmount'}
            variables={{
              amount: affectedItem.quantity,
              product: affectedItem.name
            }}
            variant={'display16'}
            colour={'brandBlue500'}
          />
        )
      })}
    </div>
  )
}

const Discount = ({
  namespace,
  discountDetails,
  affectedItems,
  applyDiscount
}: Props): JSX.Element => {
  const selfResolutionTracking = useSelfResolutionTracking()
  const selfResolutionState = useReactiveVar(selfResolutionPageState)
  const [discountValue, setDiscountValue] = useState('')
  const accept = useCallback(() => {
    selfResolutionTracking.trackEvent('Resolution accepted', {
      resolution: 'Discount',
      amount: discountDetails.discountCode.discountCodeParts[0].value
    })
    applyDiscount()
  }, [applyDiscount, selfResolutionTracking, discountDetails])

  React.useEffect(() => {
    const pageTitle = 'resolutionOffering.header.discount'
    selfResolutionPageState({
      ...selfResolutionState,
      showBackButton: false,
      pageTitle
    })
    selfResolutionTracking.stepLoaded(
      'Discount offering',
      'Resolution offering'
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    const discountAmount =
      discountDetails.discountCode.discountCodeParts[0].value
    const { locale, currency } = countryCodeToLocaleCurrency(
      selfResolutionState.shippingCountryCode,
      selfResolutionState.preferredLanguage
    )
    const price = formatCurrencyWithDecimal(discountAmount, {
      locale,
      currency
    })
    setDiscountValue(price)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selfResolutionState])

  return (
    <div className={STYLES.container}>
      <Text
        namespace={namespace}
        text={'resolutionOffering.discount.discountOffered'}
        variables={{ value: discountValue }}
        variant={'textRegular16'}
        colour={'brandBlue500'}
      />
      <AffectedItems namespace={namespace} affectedItems={affectedItems} />
      <Text
        namespace={namespace}
        text={'resolutionOffering.discount.disclaimer'}
        variables={{}}
        variant={'textRegular16'}
        colour={'brandBlue500'}
      />
      <div className={STYLES.discountButtons}>
        <Button
          typography={{
            namespace,
            text: 'resolutionOffering.discount.accept'
          }}
          variant={'primary'}
          onClick={accept}
          disableAnalytics
        />
        <ContactCustomerLoveButton
          namespace={namespace}
          tracking={'Discount Offering'}
        />
      </div>
    </div>
  )
}

export { Props }
export default Discount
