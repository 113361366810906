import { Container, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import * as Sentry from '@/utils/sentry'

import { Fade } from '@/components/elements/atoms/Animated/Animated'
import CloudinaryImage from '@/components/elements/atoms/CloudinaryImage/CloudinaryImage'
import Icon from '@/components/elements/atoms/Icon/Icon'
import Text from '@/components/elements/atoms/Text/Text'
import LoadingScreen from '@/components/elements/organisms/LoadingScreen/LoadingScreen'

import STYLES from './LoadingScreenContent.module.sass'

import { Food as FoodCategory } from '@/types'

import { Routes as PlansRoutes } from '../../types/routes'

type Props = {
  namespace: string
  loading: boolean
  variant: PlansRoutes
  foodCategory?: FoodCategory
  shouldSeePetParentOnPlans?: boolean
}

type LoadingScreenContent = {
  title: string
  sprite: string
  imageAlt: string
}

const RecipesLoadingScreenContent = ({
  namespace,
  loading,
  foodCategory,
  shouldSeePetParentOnPlans = false
}: Omit<Props, 'variant' | 'preferredLanguage'>): JSX.Element => {
  const { t } = useTranslation(namespace)
  const copyContext = 'loading_screen.recipes'

  const [highlightOurRecipes, setHighlightOurRecipes] = useState(false)

  const foodCategoryToContent = () => {
    switch (foodCategory) {
      case FoodCategory.dry_food: {
        return {
          key: 'kibble',
          img: 'Web/photos/meals/bowls/kibble-bowl'
        }
      }
      case FoodCategory.wet_food: {
        return {
          key: 'wet',
          img: 'Web/photos/meals/bowls/wet-bowl'
        }
      }
      case FoodCategory.raw_food: {
        return {
          key: 'raw',
          img: 'Web/photos/meals/bowls/raw-bowl'
        }
      }
      case FoodCategory.home_food: {
        return {
          key: 'home',
          img: 'Web/photos/meals/pouches/duck-chicken-game-veggie-stacked'
        }
      }
      default: {
        Sentry.captureException(
          `foodCategoryToContent is not implemented for this foodCategory`,
          {
            extra: {
              foodCategory
            },
            tags: {
              product: Sentry.Product.PlansFlow
            }
          }
        )
        return {
          key: 'kibble',
          img: 'Web/photos/meals/bowls/kibble-bowl'
        }
      }
    }
  }

  const { key, img } = foodCategoryToContent()

  useEffect(() => {
    setTimeout((): void => {
      setHighlightOurRecipes(true)
    }, 4000)
  }, [])

  const title = shouldSeePetParentOnPlans ? 'alternative_title' : 'title'

  return (
    <LoadingScreen isOpen={loading} padding={false}>
      <Container maxWidth="md" disableGutters>
        <div
          className={`${STYLES.title} ${
            highlightOurRecipes ? STYLES.hide : ''
          }`}
        >
          <Text
            namespace={namespace}
            text={`${copyContext}.${title}`}
            variant="display20"
            colour="brandBlue500"
            margin={false}
            align="center"
          />
          <div className={STYLES.dots} />
        </div>
        <Grid container>
          <Grid item container xs={6}>
            <div className={STYLES.comparisonContent}>
              <Fade show={!highlightOurRecipes} opacity={0.4}>
                <CloudinaryImage
                  alt={t(`${copyContext}.img_alt.${key}`)}
                  className={`${STYLES.comparisonImage} ${
                    key === 'home' ? STYLES.homeCooked : ''
                  }`}
                  image={{
                    path: `${img}`,
                    width: key === 'home' ? 200 : 230,
                    dpr: window.devicePixelRatio
                  }}
                />
              </Fade>
              <div
                className={`${STYLES.copyWrapper} ${
                  key === 'home' ? STYLES.homeCooked : ''
                }  ${highlightOurRecipes ? STYLES.hide : ''}`}
              >
                <div className={STYLES.arrowWrapper}>
                  <Icon
                    size={30}
                    asset="curvedDashedArrow"
                    accentColour="brandBlue500"
                    direction="topLeft"
                    width={50}
                  />
                </div>
                <Text
                  namespace={namespace}
                  text={`${copyContext}.comparison_title.${key}`}
                  variant="display16"
                  colour="brandBlue500"
                  margin={false}
                />
                <Text
                  namespace={namespace}
                  text={`${copyContext}.comparison_description.${key}`}
                  variant="textRegular14"
                  colour="brandBlue500"
                  margin={false}
                />
              </div>
            </div>
          </Grid>
          <Grid item container xs={6}>
            <div className={STYLES.ourRecipesContent}>
              <div className={STYLES.foodBowl}>
                <CloudinaryImage
                  alt={t(`${copyContext}.img_alt.our_recipes`)}
                  image={{
                    path: 'Web/photos/meals/bowls/game-bowl',
                    width: 260,
                    dpr: window.devicePixelRatio
                  }}
                />
              </div>
              <div
                className={`${STYLES.copyWrapper} ${
                  !highlightOurRecipes ? STYLES.hide : ''
                }`}
              >
                <div className={STYLES.arrowWrapper}>
                  <Icon
                    size={30}
                    asset="curvedDashedArrow"
                    accentColour="brandBlue500"
                    direction="right"
                  />
                </div>
                <Text
                  namespace={namespace}
                  text={`${copyContext}.our_recipes_title`}
                  variant="display16"
                  colour="brandBlue500"
                  margin={false}
                />
                <Text
                  namespace={namespace}
                  text={`${copyContext}.our_recipes_description.${key}`}
                  variant="textRegular14"
                  colour="brandBlue500"
                  margin={false}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </LoadingScreen>
  )
}

const LoadingScreenContent = ({
  namespace,
  loading,
  variant,
  foodCategory,
  shouldSeePetParentOnPlans
}: Props): JSX.Element => {
  const [showLoadingScreen, setShowLoadingScreen] = useState(true)

  const timeOutDuration =
    variant === (PlansRoutes.Recipes || PlansRoutes.PetParent) ? 8000 : 3000

  useEffect(() => {
    if (!loading) {
      setTimeout((): void => {
        setShowLoadingScreen(false)
      }, timeOutDuration)
    }
  }, [loading, timeOutDuration])

  return (
    <RecipesLoadingScreenContent
      namespace={namespace}
      loading={showLoadingScreen}
      foodCategory={foodCategory}
      shouldSeePetParentOnPlans={shouldSeePetParentOnPlans}
    />
  )
}

export default LoadingScreenContent
