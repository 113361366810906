// @noflow
// Routes
import { useAccessManagement } from '@/context/accessManagement/accessManagement'
import { useOccasion } from '@/context/festiveTheme/festiveTheme'
import { ACCOUNT_ROUTES } from '@/routes'
import { useReactiveVar } from '@apollo/client'
import isUndefined from 'lodash/isUndefined'
import React, { useEffect, useState } from 'react'

import { dogsDataVar } from '@/services/apollo'

// Assets
import {
  CandyCane,
  DogProfileCollar,
  ExtrasShop,
  Home,
  Rewards
} from '@/components/elements/atoms/Icon/Icons'

// Styles
import STYLES from './NavigationTabBar.module.sass'

// Components
import { Tab } from './Tab'

const handleScroll = (setIsFixed: (isFixed: boolean) => void) => {
  const el = document.getElementById('navigation-tab-bar-scroll-ref')
  if (el && window.scrollY >= el.offsetTop - 32) {
    setIsFixed(true)
  } else {
    setIsFixed(false)
  }
}

/**
 * NavigationTabBar component
 *
 * Use this component to render a navigation tab bar for desktop and mobile.
 * The component will render a list of tabs as a sidebar on a desktop and
 * fixed tab bar at the bottom of the screen on mobile.
 *
 * @example
  ```
  <NavigationTabBar />
  ```
 * @returns {JSX.Element} - NavigationTabBar component
 * @category Components
 * @subcategory Molecules
 * @component NavigationTabBar
 */
const NavigationTabBar = (): JSX.Element => {
  const accessManagement = useAccessManagement()
  const { dogs } = useReactiveVar(dogsDataVar) || {}
  const firstDogId = dogs?.[0]?.id
  const [isFixed, setIsFixed] = useState(false)
  const { xmas } = useOccasion()

  useEffect(() => {
    const handleScrollEvent = () => handleScroll(setIsFixed)
    window.addEventListener('scroll', handleScrollEvent)
    return () => {
      window.removeEventListener('scroll', handleScrollEvent)
    }
  }, [])

  const TABS = [
    {
      icon: Home,
      pathname: ACCOUNT_ROUTES.base,
      title: xmas ? 'navigation_tab_bar.home_xmas' : 'navigation_tab_bar.home',
      hidden: !accessManagement?.canUserAccessRoute(ACCOUNT_ROUTES.base)
    },
    {
      icon: Home,
      pathname: ACCOUNT_ROUTES.paused,
      title: 'navigation_tab_bar.home',
      hidden: !accessManagement?.canUserAccessRoute(ACCOUNT_ROUTES.paused)
    },
    {
      icon: DogProfileCollar,
      pathname: `${ACCOUNT_ROUTES.profile}/${firstDogId}`,
      title: 'navigation_tab_bar.dog_profile',
      variables: {
        count: dogs?.length ?? 0
      },
      hidden:
        isUndefined(firstDogId) ||
        !accessManagement?.canUserAccessRoute(ACCOUNT_ROUTES.profile)
    },
    {
      icon: DogProfileCollar,
      pathname: ACCOUNT_ROUTES.noDogs,
      title: 'navigation_tab_bar.dog_profile',
      variables: {
        count: dogs?.length ?? 0
      },
      hidden:
        !isUndefined(firstDogId) ||
        !accessManagement?.canUserAccessRoute(ACCOUNT_ROUTES.noDogs)
    },
    {
      icon: xmas ? CandyCane : ExtrasShop,
      pathname: ACCOUNT_ROUTES.extras,
      pathMatch: ACCOUNT_ROUTES.extras,
      title: 'navigation_tab_bar.extras_shop',
      hidden: !accessManagement?.canUserAccessRoute(ACCOUNT_ROUTES.extras)
    },
    {
      icon: Rewards,
      pathname: ACCOUNT_ROUTES.referAFriend,
      pathMatch: ACCOUNT_ROUTES.referAFriend,
      title: 'navigation_tab_bar.free_food',
      hidden: !accessManagement?.canUserAccessRoute(ACCOUNT_ROUTES.referAFriend)
    }
  ]

  return (
    <div id="navigation-tab-bar-scroll-ref">
      <section
        className={`${STYLES.wrapper} ${isFixed ? STYLES.fixed : ''}`}
        data-testid="navigation-tab-bar"
      >
        {TABS.filter((tab) => !tab.hidden).map((tab) => (
          <Tab key={tab.pathname} {...tab} />
        ))}
      </section>
    </div>
  )
}

export { NavigationTabBar }
