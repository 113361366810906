import { gql } from '@apollo/client'

const RECIPE_CARD_FLAVOUR_FRAGMENT = gql`
  fragment recipeCardFlavourFragment on NewFlavour {
    id
    name
    slug
    shortDescription
    recipeSurcharge(planId: $planId)
    standardPricePerDay(planId: $planId)
    primaryImage {
      src
    }
  }
`

const RECIPE_CARD_PLAN_FRAGMENT = gql`
  fragment recipeCardPlanFragment on Plan {
    id
    pricePerDay
    trialDiscountedPricePerDay
  }
`

export { RECIPE_CARD_FLAVOUR_FRAGMENT, RECIPE_CARD_PLAN_FRAGMENT }
