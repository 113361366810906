// @noflow
import React, {
  isValidElement,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'

import { AnalyticsProps } from '@/services/segment'

import { Expand } from '@/components/elements/atoms/Animated/Animated'
import Card, { Props as CardProps } from '@/components/elements/atoms/Card/Card'
import Icon from '@/components/elements/atoms/Icon/Icon'
import Text, { Props as TextProps } from '@/components/elements/atoms/Text/Text'

import STYLES from './ExpandCard.module.sass'

type Props = {
  text: TextProps | JSX.Element
  icon?: string
  children: React.ReactNode
  closeOnOutsideClick?: boolean
  divideContent?: boolean
  isOpen?: boolean
  size?: 'small' | 'medium' | 'large'
} & Omit<CardProps, 'size'> &
  AnalyticsProps

const isCustomTitle = (text: Props['text']): text is JSX.Element =>
  isValidElement(text)
const isTextTitle = (text: Props['text']): text is TextProps =>
  text && typeof text === 'object' && 'text' in text

/**
 * A card with an toggle to expand or contract the card showing/hiding its content
 */
const ExpandCard = ({
  text,
  icon,
  children,
  closeOnOutsideClick = false,
  divideContent = false,
  size,
  isOpen,
  ...restProps
}: Props): JSX.Element => {
  const [open, setOpen] = useState<boolean>(isOpen ?? false)

  const onToggle = useCallback(
    (e) => {
      setOpen(!open)
      e.stopPropagation()
    },
    [open]
  )

  const ref = useRef<HTMLDivElement | null>(null)

  const handleClickOutside = useCallback(
    ({ target }) => {
      if (
        open &&
        closeOnOutsideClick &&
        ref.current &&
        !ref.current.contains(target)
      ) {
        setOpen(false)
      }
    },
    [open, closeOnOutsideClick]
  )

  useEffect(() => {
    if (closeOnOutsideClick) {
      document.addEventListener('click', handleClickOutside, true)

      return () => {
        document.removeEventListener('click', handleClickOutside, true)
      }
    }
  }, [closeOnOutsideClick, handleClickOutside])

  return (
    <Card onClick={onToggle} {...restProps} ref={ref}>
      <div className={`${STYLES.toggle} ${size ? STYLES[size] : ''}`}>
        {icon && (
          <div className={STYLES.icon}>
            <img src={icon} alt="" />
          </div>
        )}
        <div className={STYLES.label}>
          {isCustomTitle(text) ? (
            text
          ) : isTextTitle(text) ? (
            <Text
              namespace={text.namespace}
              text={text.text}
              variables={text.variables}
              translate={text.translate}
              variant={text.variant ?? 'display16'}
              margin={false}
              align="left"
              bold={text.bold ?? true}
              element={text.element ?? 'p'}
            />
          ) : null}
        </div>
        <div className={`${STYLES.arrow} ${open ? STYLES.arrowOpen : ''}`}>
          <Icon asset="chevron" size={12} />
        </div>
      </div>
      <Expand show={open} fullWidth origin="top">
        <div
          className={`${STYLES.content} ${
            divideContent ? STYLES.divideContent : ''
          }`}
        >
          {children}
        </div>
      </Expand>
    </Card>
  )
}

export { Props }
export default ExpandCard
