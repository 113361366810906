// @noflow
import React, { useCallback, useEffect, useState } from 'react'

import BlueCheckmark from 'assets/images/icons/checkmarks/blue-checkmark-transparent-bg.svg'
import WhiteCheckmark from 'assets/images/icons/checkmarks/white-checkmark-blue-bg.svg'

import Card from '@/components/elements/atoms/Card/Card'

import STYLES from './SelectableCardTemplate.module.sass'

type Variant = 'horizontal' | 'vertical'

type RadioProps = {
  type: 'radio'
  children: JSX.Element
  selectedCondition: boolean
  variant?: Variant
  isSelectedInitialValue?: boolean
  isDeselectable?: boolean
  handleOnSelectCustomEvent?: () => void
  handleOnUnselectCustomEvent?: () => void
}

type CheckboxProps = {
  type: 'checkbox'
  children: JSX.Element
  variant?: Variant
  isSelectedInitialValue?: boolean
  selectedCondition?: never
  isDeselectable?: boolean
  handleOnSelectCustomEvent?: () => void
  handleOnUnselectCustomEvent?: () => void
}

type Props = RadioProps | CheckboxProps

const SelectableCardTemplate = ({
  variant = 'horizontal',
  type = 'checkbox',
  children,
  selectedCondition,
  isSelectedInitialValue = false,
  isDeselectable = true,
  handleOnSelectCustomEvent,
  handleOnUnselectCustomEvent
}: Props): JSX.Element => {
  const [isSelected, setIsSelected] = useState(isSelectedInitialValue)

  // Handlers
  const handleToggleIsSelected = useCallback(() => {
    switch (type) {
      case 'radio': {
        if (selectedCondition) {
          if (isDeselectable) {
            setIsSelected(false)

            // Custom event on unselect
            if (handleOnUnselectCustomEvent) {
              handleOnUnselectCustomEvent()
            }
          }
        } else {
          setIsSelected(true)

          // Custom event on select
          if (handleOnSelectCustomEvent) {
            handleOnSelectCustomEvent()
          }
        }

        break
      }
      case 'checkbox': {
        if (isSelected) {
          setIsSelected(false)

          if (handleOnUnselectCustomEvent) {
            handleOnUnselectCustomEvent()
          }
        } else {
          setIsSelected(true)

          if (handleOnSelectCustomEvent) {
            handleOnSelectCustomEvent()
          }
        }

        break
      }
      default: {
        setIsSelected(!isSelected)
      }
    }
  }, [
    type,
    selectedCondition,
    isDeselectable,
    handleOnUnselectCustomEvent,
    handleOnSelectCustomEvent,
    isSelected
  ])

  // Effects
  useEffect(() => {
    if (type === 'radio') {
      if (!selectedCondition) {
        setIsSelected(false)
      }
    }
  }, [type, isSelected, selectedCondition])

  useEffect(() => {
    setIsSelected(isSelectedInitialValue)
  }, [isSelectedInitialValue])

  return (
    <div className={`${STYLES.container} ${variant ? STYLES[variant] : ''}`}>
      <Card background={isSelected ? 'blue500' : 'shadow'}>
        {children}
        <button
          type="button"
          className={STYLES.addButton}
          onClick={handleToggleIsSelected}
        >
          <img
            src={isSelected ? WhiteCheckmark : BlueCheckmark}
            className={STYLES.checkmark}
            alt=""
          />
        </button>
      </Card>
    </div>
  )
}

export type { Props }

export default SelectableCardTemplate
