// @noflow
import classnames from 'classnames'
import React from 'react'

import Text from '@/components/elements/atoms/Text/Text'
import type { Props as TextProps } from '@/components/elements/atoms/Text/Text'

import STYLES from './Ribbon.module.sass'

type Props = {
  direction?: 'left' | 'right'
  message: TextProps
  textAlign?: 'center' | 'left'
  size?: 'regular' | 'small'
}

const Ribbon = ({
  message,
  direction = 'left',
  textAlign = 'center',
  size = 'regular'
}: Props): JSX.Element => {
  const containerClassName = classnames(STYLES.container, {
    [STYLES.smallRibbonContainer]: size === 'small'
  })

  const ribbonClassName = classnames(STYLES.ribbon, {
    [STYLES.rightDirection]: direction === 'right',
    [STYLES.smallRibbon]: size === 'small'
  })

  const textClassName = classnames(STYLES.textWrapper, {
    [STYLES.textLeft]: textAlign === 'left',
    [STYLES.smallTextRibbon]: size === 'small'
  })

  return (
    <div className={containerClassName}>
      <div className={ribbonClassName} />
      <div className={textClassName}>
        <Text
          variant={message.variant || 'display20'}
          shouldScale
          namespace={message.namespace}
          text={message.text}
          variables={message.variables}
          colour={message.colour || 'brandBlue500'}
        />
      </div>
    </div>
  )
}

export { Props }
export default Ribbon
