// @noflow
import type { ActionCreatorPayload } from 'ts-redux-utility-types'

import type {
  Form,
  State as FormState,
  Input,
  LoadedAppData
} from '../reducers/formDataReducer'
import type { GlobalData } from '../reducers/globalAttributesReducer'
import type { State as RequestsReducerState } from '../reducers/requestsReducer'
import * as MESSAGES from './messages'
import * as TYPES from './types'

type FieldName =
  | 'newDogName'
  | 'queryType'
  | 'name'
  | 'email'
  | 'dogNames'
  | 'postcode'
  | 'message'
  | 'recaptchaString'
  | 'dogGender'
  | 'isNeuteredOrSpayed'
  | 'dogAgeInput'
  | 'dogBreedInput'
  | 'dogFoodsInput'
  | 'dogEatingHabit'
  | 'dogBodyShape'
  | 'dogWeight'
  | 'dogActivityLevel'
  | 'dogAllergensInput'
  | 'dogHealthIssuesInput'
  | 'dogSnackingHabit'

type StartRequest = {
  type: TYPES.START_REQUEST
  name: keyof RequestsReducerState
}

type CompleteRequestWithSuccess = {
  type: TYPES.COMPLETE_REQUEST_WITH_SUCCESS
  name: keyof RequestsReducerState
}

type CompleteRequestWithError = {
  type: TYPES.COMPLETE_REQUEST_WITH_ERROR
  name: keyof RequestsReducerState
}

type RequestReducerAction =
  | StartRequest
  | CompleteRequestWithSuccess
  | CompleteRequestWithError

type AppDataReceived = {
  type: TYPES.APP_DATA_RECEIVED
  data: LoadedAppData
}

type GlobalDataReceived = {
  type: TYPES.GLOBAL_DATA_RECEIVED
  data: GlobalData
}

type UpdateField = {
  type: TYPES.UPDATE_FIELD
  field: FieldName
  input: Input
}

type SetFormViewingStep = {
  type: TYPES.SET_FORM_VIEWING_STEP
  viewingStep: FormState['viewingStep']
}

type SetRecaptchaLoaded = {
  type: TYPES.SET_RECAPTCHA_LOADED
}

type SetFormType = {
  type: TYPES.SET_FORM_TYPE
  formType: Form['type']
}

type ProbeRequiredFields = {
  type: TYPES.PROBE_REQUIRED_FIELDS
}

type FormDataAction =
  | AppDataReceived
  | UpdateField
  | SetFormViewingStep
  | SetRecaptchaLoaded
  | SetFormType
  | ProbeRequiredFields

type GlobalDataAction = GlobalDataReceived

type TrackableAction = UpdateField

const startRequest = (name: keyof RequestsReducerState): StartRequest => {
  const type: TYPES.START_REQUEST = MESSAGES.START_REQUEST
  return { type, name }
}

const completeRequestWithSuccess = (
  name: keyof RequestsReducerState
): CompleteRequestWithSuccess => {
  const type: TYPES.COMPLETE_REQUEST_WITH_SUCCESS =
    MESSAGES.COMPLETE_REQUEST_WITH_SUCCESS
  return { type, name }
}

const completeRequestWithError = (
  name: keyof RequestsReducerState
): CompleteRequestWithError => {
  const type: TYPES.COMPLETE_REQUEST_WITH_ERROR =
    MESSAGES.COMPLETE_REQUEST_WITH_ERROR
  return { type, name }
}

const appDataReceieved = (data: LoadedAppData): AppDataReceived => {
  const type: TYPES.APP_DATA_RECEIVED = MESSAGES.APP_DATA_RECEIVED
  return { type, data }
}

const globalDataReceieved = (data: GlobalData): GlobalDataReceived => {
  const type: TYPES.GLOBAL_DATA_RECEIVED = MESSAGES.GLOBAL_DATA_RECEIVED
  return { type, data }
}

const updateField = (field: FieldName, input: Input): UpdateField => {
  const type: TYPES.UPDATE_FIELD = MESSAGES.UPDATE_FIELD
  return { type, field, input }
}

const setFormViewingStep = (
  viewingStep: FormState['viewingStep']
): SetFormViewingStep => {
  const type: TYPES.SET_FORM_VIEWING_STEP = MESSAGES.SET_FORM_VIEWING_STEP
  return { type, viewingStep }
}

const setRecaptchaLoaded = (): SetRecaptchaLoaded => {
  const type: TYPES.SET_RECAPTCHA_LOADED = MESSAGES.SET_RECAPTCHA_LOADED
  return { type }
}

const setFormType = (formType: Form['type']): SetFormType => {
  const type: TYPES.SET_FORM_TYPE = MESSAGES.SET_FORM_TYPE
  return { type, formType }
}

const probeRequiredFields = (
  params: ActionCreatorPayload<ProbeRequiredFields> = {}
): ProbeRequiredFields => {
  const type: TYPES.PROBE_REQUIRED_FIELDS = MESSAGES.PROBE_REQUIRED_FIELDS
  return { type, ...params }
}

export type {
  TrackableAction,
  RequestReducerAction,
  FormDataAction,
  GlobalDataAction
}

export {
  startRequest,
  completeRequestWithSuccess,
  completeRequestWithError,
  appDataReceieved,
  globalDataReceieved,
  updateField,
  setFormViewingStep,
  setRecaptchaLoaded,
  setFormType,
  probeRequiredFields
}
