// @noflow
import type { Language } from '@/packs/localisation'
import { ACCOUNT_ROUTES } from '@/routes'
import { useMutation, useQuery } from '@apollo/client'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { possessivePronoun } from '@/utils/StringHelper'
import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { formatCurrencyWithDecimal } from '@/utils/currency'
import * as Sentry from '@/utils/sentry'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import segmentTrack from '@/components/analytics/Analytics'
import Card from '@/components/elements/atoms/Card/Card'
import SkeletonParagraph from '@/components/elements/atoms/SkeletonParagraph/SkeletonParagraph'
import Tabs, { Tab } from '@/components/elements/atoms/Tabs/Tabs'
import Text from '@/components/elements/atoms/Text/Text'
import Tooltip from '@/components/elements/atoms/Tooltip/Tooltip'
import BoxBreakdown from '@/components/elements/molecules/BoxBreakdown/BoxBreakdown'
import DailyFeedingGuideline from '@/components/elements/molecules/DailyFeedingGuideline/DailyFeedingGuideline'
import NotificationContainer from '@/components/elements/molecules/NotificationContainer/NotificationContainer'
import NotificationContent from '@/components/elements/molecules/NotificationContent/NotificationContent'
import TextSeparator from '@/components/elements/molecules/TextSeparator/TextSeparator'
import { BoxPriceBreakdown } from '@/components/elements/organisms/BoxPriceBreakdown/BoxPriceBreakdown'
import ConfirmationModal, {
  modalData
} from '@/components/elements/organisms/ConfirmationModal/ConfirmationModal'
import StickyNavigation from '@/components/elements/organisms/StickyNavigation/StickyNavigation'
import { NavigateContext } from '@/components/pages/App'
import { SUBSCRIPTION_PLAN_UPDATE_MUTATION } from '@/components/pages/SwapAndSavePage/queries/SubscriptionPlanUpdate'
import {
  DOG_PLAN_PORTION_QUERY,
  SWAP_AND_SAVE_PAGE_QUERY
} from '@/components/pages/SwapAndSavePage/queries/SwapAndSavePageQuery'
import { UPDATE_PLAN_AND_RESUME_MUTATION } from '@/components/pages/SwapAndSavePage/queries/UpdatePlanAndResumeMutation'
import { DogPlanPortionQuery_user_dogs as PortionDog } from '@/components/pages/SwapAndSavePage/queries/__generated__/DogPlanPortionQuery'
import {
  SwapAndSavePageQuery_user_dogs as Dog,
  SwapAndSavePageQuery
} from '@/components/pages/SwapAndSavePage/queries/__generated__/SwapAndSavePageQuery'
import { SUBSCRIPTION_RESUME_MUTATION } from '@/components/pages/TreatmentsPausedPage/queries/queries'
import Base from '@/components/templates/Base'

import STYLES from './SwapAndSavePage.module.sass'

import { SubscriptionStatus, TypeOfPlan } from '@/types'

type Props = {
  variant: Language
}

type FeedingGuidelineProps = {
  planId: string
  planType: TypeOfPlan
}

const FeedingGuidelineSection = ({
  planId,
  planType
}: FeedingGuidelineProps): JSX.Element | null => {
  const { loading, data, error } = useQuery(DOG_PLAN_PORTION_QUERY, {
    variables: {
      planId: planId
    }
  })

  if (error)
    Sentry.captureException(
      `Error occured in DOG_PLAN_PORTION_QUERY: ${error.message}`,
      {
        extra: { error },
        tags: {
          product: Sentry.Product.Account,
          team: Sentry.Team.Retention
        }
      }
    )

  if (loading) {
    return (
      <div className={STYLES.dogs}>
        <SkeletonParagraph count={1} />
      </div>
    )
  }

  const {
    user: { dogs }
  } = data

  return (
    <section className={STYLES.dogs}>
      <DailyFeedingGuideline
        namespace="molecules"
        dogs={dogs.map((dog: PortionDog) => ({
          name: dog.name,
          planPortionInGrams: dog.planPortionInGramsForPlan
        }))}
        multipleDogs={dogs.length > 1}
        planType={planType}
      />
    </section>
  )
}

const SwapAndSavePage = (): JSX.Element | null => {
  const navigate = useContext(NavigateContext)
  const namespace = 'swap_and_save'
  const [selectedTab, setSelectedTab] = useState<string>('savingsPlan')
  const { loading, data, error } = useQuery<SwapAndSavePageQuery>(
    SWAP_AND_SAVE_PAGE_QUERY
  )
  const [
    subscriptionResume,
    {
      data: subscriptionResumeMutationData,
      loading: subscriptionResumeMutationLoading,
      error: subscriptionResumeMutationError
    }
  ] = useMutation(SUBSCRIPTION_RESUME_MUTATION, {
    variables: {
      numOfBoxes: 3
    }
  })
  const [
    updatePlanAndResume,
    { data: dataUpdatePlanAndResume, loading: loadingUpdatePlanAndResume }
  ] = useMutation(UPDATE_PLAN_AND_RESUME_MUTATION)
  const [subscriptionPlanUpdate, { loading: loadingSubscriptionPlanUpdate }] =
    useMutation(SUBSCRIPTION_PLAN_UPDATE_MUTATION)
  const { windowWidth } = useWindowSize()

  if (error)
    Sentry.captureException(
      `Error occured in SWAP_AND_SAVE_PAGE_QUERY: ${error.message}`,
      {
        extra: { error },
        tags: {
          product: Sentry.Product.Account,
          team: Sentry.Team.Retention
        }
      }
    )

  const confirmAndResumeClicked = () =>
    updatePlanAndResume({
      variables: {
        userId: data?.user?.id,
        planId: data?.user?.subscription?.plan?.swappablePlan?.id
      }
    })
      .then((data) => {
        segmentTrack('Swap and Save Page - Confirm and Resume Clicked')
        if (data) {
          window.location.pathname = ACCOUNT_ROUTES.base
        }
      })
      .catch((error) => {
        Sentry.captureException(
          `Error occured in UPDATE_PLAN_AND_RESUME_MUTATION: ${error.message}`,
          {
            extra: { error },
            tags: {
              product: Sentry.Product.Account,
              team: Sentry.Team.Retention
            }
          }
        )
        toast.error(
          <NotificationContent
            copy={{
              text: 'notification_resume_error',
              namespace
            }}
          />,
          { toastId: 'confirm-and-resume-error-message' }
        )
      })

  const resumeClicked = useCallback(() => {
    segmentTrack('Swap and Save Page - Resume Only Clicked')
    subscriptionResume()
  }, [subscriptionResume])

  const backToBrightback = useCallback(() => {
    segmentTrack('Swap and Save Page - Exited flow')
    window.history.back()
  }, [])

  const confirmAndSaveClicked = () =>
    subscriptionPlanUpdate({
      variables: {
        userId: data?.user?.id,
        planId: data?.user?.subscription?.plan?.swappablePlan?.id
      }
    })
      .then((data) => {
        segmentTrack('Swap and Save Page - Confirm & Save Clicked')
        if (data) {
          navigate(
            ACCOUNT_ROUTES.base,
            '/dashboard/subscription?scrollDestination=plan-management'
          )
        }
      })
      .catch((error) => {
        Sentry.captureException(
          `Error occured in SUBSCRIPTION_PLAN_UPDATE_MUTATION: ${error.message}`,
          {
            extra: { error },
            tags: {
              product: Sentry.Product.Account,
              team: Sentry.Team.Retention
            }
          }
        )
        toast.error(
          <NotificationContent
            copy={{
              text: 'notification_update_error',
              namespace
            }}
          />,
          { toastId: 'confirm-and-save-error-message' }
        )
      })

  const keepCurrentPlanClicked = useCallback(() => {
    segmentTrack('Swap and Save Page - Save current plan Clicked')
    navigate(ACCOUNT_ROUTES.base, '/dashboard/subscription')
  }, [navigate])

  const setTabValue = useCallback(
    (id: string) => {
      setSelectedTab(id)
    },
    [setSelectedTab]
  )

  useEffect((): void => {
    if (subscriptionResumeMutationLoading || loadingUpdatePlanAndResume) {
      modalData({
        isOpen: true,
        text: 'confirmation_modal',
        namespace,
        delay: 3000
      })
    } else if (subscriptionResumeMutationData || dataUpdatePlanAndResume) {
      window.location.pathname = ACCOUNT_ROUTES.base
    } else if (subscriptionResumeMutationError) {
      Sentry.captureException(
        `Could not resume subscription: ${subscriptionResumeMutationError}`,
        {
          extra: { subscriptionResumeMutationError },
          tags: {
            product: Sentry.Product.Account,
            team: Sentry.Team.Retention
          }
        }
      )
    }
  }, [
    subscriptionResumeMutationLoading,
    subscriptionResumeMutationData,
    subscriptionResumeMutationError,
    loadingUpdatePlanAndResume,
    dataUpdatePlanAndResume
  ])

  if (loading || !data || !data.user.dogs || !data.user.subscription.plan) {
    return null
  }

  const {
    user: {
      shippingCountryCode,
      preferredLanguage,
      dogs,
      subscription: { status, recurringOrder, plan }
    }
  } = data

  const { locale, currency } = countryCodeToLocaleCurrency(
    shippingCountryCode,
    preferredLanguage
  )
  const dogGenders: Dog['gender'][] = dogs.map((dog: Dog) => dog.gender)
  const dogPossessivePronoun = possessivePronoun(dogGenders, preferredLanguage)
  const swappablePlan = plan.swappablePlan
  const savingsAmount = swappablePlan
    ? recurringOrder.planTotal - swappablePlan.price
    : 0

  return (
    <>
      <Base background="brandYellow100">
        <Container maxWidth="lg">
          <div className={STYLES.wrapper}>
            <Grid container justifyContent="center">
              <Grid item sm={12} md={10}>
                {swappablePlan && (
                  <Tabs
                    initial="savingsPlan"
                    onChange={setTabValue}
                    variant={
                      windowWidth < BREAKPOINTS.md ? 'secondary' : undefined
                    }
                  >
                    <Tab
                      label={{
                        text: 'tabs.previous_plan.title',
                        namespace,
                        variables: {
                          pouchSize: plan.pouchSize
                        }
                      }}
                      id="oldPlan"
                    >
                      <div className={STYLES.boxBreakdown}>
                        <BoxBreakdown
                          pouchSize={plan.pouchSize}
                          pouches={plan.numberOfPouches}
                          cadence={plan.durationInDays / 7}
                          highlightedChanges
                        />
                      </div>
                      <Card shadow={false} squared>
                        <div className={STYLES.cardContent}>
                          <Text
                            text={'tabs.previous_plan.heading'}
                            variant="display20"
                            margin={false}
                            namespace={namespace}
                          />
                          <Text
                            text={'tabs.previous_plan.body'}
                            margin={false}
                            namespace={namespace}
                            variables={{
                              currentPouchSize: plan.pouchSize
                            }}
                          />
                          <FeedingGuidelineSection
                            planId={plan.id}
                            planType={plan.typeOfPlanForCustomer}
                          />
                          <div className={STYLES.textSeparator}>
                            <TextSeparator
                              text={'tabs.alert'}
                              variables={{ context: dogPossessivePronoun }}
                              namespace={namespace}
                              info
                            />
                          </div>
                          <BoxPriceBreakdown
                            deliveryFee={recurringOrder.deliveryFee}
                            meals={[
                              {
                                perDay: recurringOrder.planTotalPerDay,
                                total: recurringOrder.planTotal
                              }
                            ]}
                            preferredLanguage={preferredLanguage}
                            shippingCountryCode={shippingCountryCode}
                            total={
                              recurringOrder.planTotal +
                              recurringOrder.deliveryFee
                            } // NOTE: not using `recurringOrder.totalPrice` here since that includes surcharges and we currently have no way of getting totalSurcharges from the swappablePlan to make a fair price comparison
                          />
                          <Text
                            text={'tabs.price_footnote'}
                            variant="textRegular14"
                            colour="brandBlue400"
                            namespace={namespace}
                          />
                        </div>
                      </Card>
                    </Tab>
                    <Tab
                      label={{
                        text: 'tabs.savings_plan.title',
                        namespace,
                        variables: {
                          pouchSize: swappablePlan.pouchSize
                        }
                      }}
                      id="savingsPlan"
                    >
                      <div className={STYLES.boxBreakdown}>
                        <BoxBreakdown
                          pouchSize={swappablePlan.pouchSize}
                          pouches={swappablePlan.numberOfPouches}
                          cadence={swappablePlan.durationInDays / 7}
                          highlightedChanges
                        />
                      </div>
                      <Card shadow={false} squared>
                        <div className={STYLES.cardContent}>
                          <Text
                            text={'tabs.savings_plan.heading'}
                            variant="display20"
                            margin={false}
                            namespace={namespace}
                          />
                          <Text
                            text={'tabs.savings_plan.body'}
                            margin={false}
                            namespace={namespace}
                            variables={{
                              totalSavings: formatCurrencyWithDecimal(
                                savingsAmount,
                                { locale: locale, currency: currency }
                              ),
                              currentPouchSize: plan.pouchSize,
                              swapAndSavePouchSize: swappablePlan.pouchSize,
                              swappablePlanCadence:
                                swappablePlan.durationInDays / 7,
                              count: swappablePlan.durationInDays / 7
                            }}
                          />
                          <div className={STYLES.tooltip}>
                            <Tooltip
                              label={{
                                namespace,
                                text: 'tabs.savings_plan.storage_tooltip.trigger'
                              }}
                              flush="left"
                              identifier="swap_and_save.savings_plan.storage_tooltip"
                            >
                              <Text
                                namespace={namespace}
                                text="tabs.savings_plan.storage_tooltip.content"
                                variant="textRegular16"
                              />
                            </Tooltip>
                          </div>
                          <FeedingGuidelineSection
                            planId={swappablePlan.id}
                            planType={swappablePlan.typeOfPlanForCustomer}
                          />
                          <div className={STYLES.textSeparator}>
                            <TextSeparator
                              text={'tabs.alert'}
                              variables={{ context: dogPossessivePronoun }}
                              namespace={namespace}
                              info
                            />
                          </div>
                          <BoxPriceBreakdown
                            deliveryFee={recurringOrder.deliveryFee}
                            meals={[
                              {
                                perDay: swappablePlan.pricePerDay,
                                total: swappablePlan.price
                              }
                            ]}
                            preferredLanguage={preferredLanguage}
                            shippingCountryCode={shippingCountryCode}
                            total={
                              swappablePlan.price + recurringOrder.deliveryFee
                            }
                          />
                          <Text
                            text={'tabs.price_footnote'}
                            variant="textRegular14"
                            colour="brandBlue400"
                            namespace={namespace}
                          />
                        </div>
                      </Card>
                    </Tab>
                  </Tabs>
                )}
              </Grid>
            </Grid>
          </div>
        </Container>
      </Base>
      <React.Fragment>
        {status === SubscriptionStatus.paused && (
          <StickyNavigation
            variant={'oneButton'}
            disabled={
              loadingUpdatePlanAndResume || subscriptionResumeMutationLoading
            }
            buttonOne={{
              variant: 'primary',
              text:
                selectedTab === 'savingsPlan'
                  ? 'navigation.swap_and_start_new_plan'
                  : 'navigation.resume_previous_plan',
              iconColour: 'brandWhite',
              namespace,
              onClick:
                selectedTab === 'savingsPlan'
                  ? confirmAndResumeClicked
                  : resumeClicked
            }}
          />
        )}
        {status === SubscriptionStatus.active && (
          <StickyNavigation
            variant={'twoButtons'}
            disabled={loadingSubscriptionPlanUpdate}
            buttonOne={{
              variant: 'secondary',
              text: 'navigation.back',
              iconColour: 'brandRed400',
              namespace,
              onClick: backToBrightback
            }}
            buttonTwo={{
              variant: 'primary',
              text:
                selectedTab === 'savingsPlan'
                  ? 'navigation.confirm_and_save'
                  : 'navigation.keep_current_plan',
              iconColour: 'brandWhite',
              namespace,
              onClick:
                selectedTab === 'savingsPlan'
                  ? confirmAndSaveClicked
                  : keepCurrentPlanClicked
            }}
          />
        )}
      </React.Fragment>
      <NotificationContainer autoClose={5000} />
      <ConfirmationModal />
    </>
  )
}

export { Props }

export default SwapAndSavePage
