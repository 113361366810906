// @noflow
import type {
  Recommendation,
  RecommendationId,
  Recommendations
} from './generateRecommendations'

const setRecommendationCompletion = (
  recommendationId: RecommendationId
): void => {
  const localStorageItem = window.localStorage.getItem(
    'onboardingRecommendations'
  )
  if (!localStorageItem) return

  const onboardingRecommendations: Recommendations =
    JSON.parse(localStorageItem)

  const updatedOnboardingRecommendations = onboardingRecommendations.map(
    (recommendation: Recommendation) => {
      return recommendation.id === recommendationId
        ? { ...recommendation, completed: true }
        : recommendation
    }
  )
  window.localStorage.setItem(
    'onboardingRecommendations',
    JSON.stringify(updatedOnboardingRecommendations)
  )
}

export default setRecommendationCompletion
