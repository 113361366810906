// @noflow
import type { Language } from '@/packs/localisation'
import { StripeElementLocale } from '@stripe/stripe-js'

import { lngStringToLngType } from '@/utils/StringHelper'

import { ensureNever } from '@/typescript/utils'

const localeToStripeLocale = (language: Language): StripeElementLocale => {
  if (!language) return 'auto'

  const sanitisedLanguageCode = lngStringToLngType(language)

  switch (sanitisedLanguageCode) {
    case 'en':
      return 'en-GB'
    case 'nl_BE':
      return 'nl'
    case 'pl_PL':
      return 'pl'
    case 'de_DE':
      return 'de'
    case 'cs_CZ':
      return 'cs'
    case 'fr':
    case 'nl':
    case 'pl':
      return sanitisedLanguageCode
    default:
      ensureNever(sanitisedLanguageCode)
      return sanitisedLanguageCode
  }
}

export { localeToStripeLocale }
