import { gql } from '@apollo/client'

const SAVE_FRESH_MEALS_MUTATION = gql`
  mutation SaveFreshMealsMutation($userId: ID!, $meal: MealInput!) {
    mealUpdate(userId: $userId, meal: $meal) {
      id
      quantity
      flavour {
        id
        name
        recipeSurcharge
      }
    }
  }
`

export { SAVE_FRESH_MEALS_MUTATION }
