// @noflow
import { useReactiveVar } from '@apollo/client'
import { useStripe } from '@stripe/react-stripe-js'
import { PaymentRequest, PaymentRequestWallet } from '@stripe/stripe-js'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'

import {
  countryCodeToDefaultCurrency,
  localeToDateLocale
} from '@/utils/countryCodeHelper'

import { totalAmount } from '../../../components/DsAppOrderSummary/DsAppOrderSummary'

import { Code } from '@/types'

import { State } from '../../../PayOnOwnDevicePage'
import { LoginUrlProps } from '../../../screens/PaymentScreen/PaymentScreen'
import { PaymentMethod, SubmissionState } from '../../../types'
import stripeMutationMethods from '../sharedStripeMutationMethods'
import { buySubscriptionWithPaymentRequest } from './buySubscriptionWithPaymentRequest'
import { paymentMethodFromWalletName } from './paymentMethodFromWalletName'
import paymentRequestDisplayItems from './paymentRequestDisplayItems'

type PaymentRequestInProgress = {
  paymentMethod: PaymentMethod | null
  inProgress: boolean
}

type UseBuySubscriptionWithPaymentRequest = {
  paymentRequest: PaymentRequest | null
  paymentRequestInProgress: PaymentRequestInProgress
  availablePaymentRequestMethod: PaymentMethod | null
}

type Translate = (arg0: string) => string

type Props = {
  paymentPageState: State | null
  t: Translate
  shouldSeeApplePay: boolean
  shouldSeeGooglePay: boolean
  submissionState: SubmissionState
  navigateToNextStep: (input: LoginUrlProps) => void
  setGooglePayOrApplePay: (value: boolean) => void
}

const useBuySubscriptionWithPaymentRequest = ({
  paymentPageState,
  t,
  shouldSeeApplePay,
  shouldSeeGooglePay,
  submissionState,
  navigateToNextStep,
  setGooglePayOrApplePay
}: Props): UseBuySubscriptionWithPaymentRequest => {
  const copyContext =
    'plan_steps.payment.payment_options.payment_request_display_items'
  const stripe = useStripe()
  const { raiseBannerError } = stripeMutationMethods()
  const [paymentRequestInProgress, setPaymentRequestInProgress] =
    useState<PaymentRequestInProgress>({
      paymentMethod: null,
      inProgress: false
    })
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(
    null
  )
  const [availablePaymentRequestMethod, setAvailablePaymentRequestMethod] =
    useState<PaymentMethod | null>(null)

  const [disabledWallets] = useState<PaymentRequestWallet[]>(() => {
    const wallets: PaymentRequestWallet[] = []
    if (!shouldSeeApplePay) {
      wallets.push('applePay')
    }
    if (!shouldSeeGooglePay) {
      wallets.push('googlePay')
    }
    return wallets
  })

  if (!paymentPageState) {
    throw new Error('Pending subscription data not found')
  }
  // OrderSummary Data
  const { address, discountCode } =
    paymentPageState.data.directSalesPendingSubscription

  const { shippingCountry } = address

  const dateLocale = localeToDateLocale(
    shippingCountry.code,
    paymentPageState.language
  )

  const formattedDate = new Date(paymentPageState.targetFirstDeliveryDate)

  const deliveryDate = format(formattedDate, 'EEEE, d MMMM', {
    locale: dateLocale
  })

  const { amount, firstOrderPricing } = useReactiveVar(totalAmount)

  useEffect((): void => {
    if (stripe && firstOrderPricing && amount) {
      const pr = stripe.paymentRequest({
        country:
          shippingCountry.code === Code.NI ? Code.GB : shippingCountry.code,
        currency: countryCodeToDefaultCurrency(
          shippingCountry.code
        ).toLowerCase(),
        requestShipping: false,
        displayItems: paymentRequestDisplayItems({
          firstOrderPricing,
          t
        }),
        total: {
          label: t(`${copyContext}.total`),
          amount: amount,
          pending: false
        },
        disableWallets: disabledWallets
      })

      pr.on('paymentmethod', (event): void => {
        if (submissionState.type === 'loading') {
          return
        }
        const paymentMethod = paymentMethodFromWalletName(
          event.walletName as PaymentRequestWallet
        )

        if (!paymentMethod) {
          throw new Error('Payment method not found')
          return
        }

        setPaymentRequestInProgress({ paymentMethod, inProgress: true })

        buySubscriptionWithPaymentRequest({
          event,
          stripe,
          paymentPageState,
          paymentMethodType: paymentMethod,
          amount: firstOrderPricing.netTotalPrice,
          navigateToNextStep
        })
      })

      pr.on('cancel', () =>
        setPaymentRequestInProgress({ paymentMethod: null, inProgress: false })
      )

      pr.canMakePayment().then((result): void => {
        if (result) {
          if (result.applePay) {
            setGooglePayOrApplePay(true)
            setAvailablePaymentRequestMethod('applePay')
            pr.update({
              shippingOptions: [
                {
                  id: 'standard',
                  label: t(`${copyContext}.shipping_options_label`),
                  detail: t(`${copyContext}.shipping_options_label`),
                  amount: firstOrderPricing.netDeliverySurchargePrice
                }
              ]
            })
          }
          if (result.googlePay) {
            setAvailablePaymentRequestMethod('googlePay')
            setGooglePayOrApplePay(true)
          }

          setPaymentRequest(pr)
        }
      })
    }
  }, [
    deliveryDate,
    discountCode,
    shippingCountry,
    stripe,
    t,
    disabledWallets,
    firstOrderPricing,
    paymentPageState,
    submissionState,
    raiseBannerError,
    navigateToNextStep,
    setGooglePayOrApplePay,
    amount
  ])

  return {
    paymentRequest,
    paymentRequestInProgress,
    availablePaymentRequestMethod
  }
}

export type { UseBuySubscriptionWithPaymentRequest }

export { useBuySubscriptionWithPaymentRequest }
