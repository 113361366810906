import React, { useCallback } from 'react'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import { Analytics } from '@/components/analytics/Analytics'
import { Button } from '@/components/elements/atoms/Button'
import Text from '@/components/elements/atoms/Text/Text'
import AppDisplays from '@/components/elements/molecules/AppDisplays/AppDisplays'
import AppDownloadButtons from '@/components/elements/molecules/AppDownloadButtons/AppDownloadButtons'

import STYLES from './DownloadAppSection.module.sass'

import { Code } from '@/types'

type ViewProps = {
  namespace: string
  handleOnClick?: () => void
  pageContext: 'login_page' | 'thank_you_page'
  shippingCode: Code
}

type Props = {
  handleOnClick?: () => void
  pageContext: 'login_page' | 'thank_you_page'
  shippingCode: Code
}

const MobileView = ({
  namespace,
  handleOnClick,
  pageContext,
  shippingCode
}: ViewProps): JSX.Element => {
  const path = window.location.pathname
  const downloadAppClick = useCallback(() => {
    Analytics.track('Download Butternut App Primary CTA Clicked', { path })
    window.open('/app/download', '_blank')
  }, [path])
  return (
    <div
      className={`${STYLES.mobileWrapper} ${
        pageContext === 'login_page' ? STYLES.fullHeight : ''
      }`}
    >
      <Text
        colour="brandBlue500"
        namespace={namespace}
        text={`title`}
        variant="display28"
        element="h1"
        align="center"
      />
      <div className={STYLES.displays}>
        <AppDisplays />
        <AppDownloadButtons shippingCode={shippingCode} />
      </div>
      <Text
        colour="brandBlue500"
        namespace={namespace}
        text={`subtitle_mobile`}
        variant="textRegular16"
        align="center"
      />
      <div className={STYLES.CTAwrapper}>
        <Button
          typography={{
            text: 'download_app',
            namespace: 'shared'
          }}
          onClick={downloadAppClick}
          disableAnalytics
        />
        {handleOnClick && (
          <Button
            typography={{
              namespace: 'shared',
              text: 'continue_to_dashboard'
            }}
            onClick={handleOnClick}
            variant="ghost"
            disableAnalytics
          />
        )}
      </div>
    </div>
  )
}

const DesktopView = ({
  namespace,
  handleOnClick,
  pageContext,
  shippingCode
}: ViewProps): JSX.Element => {
  return (
    <div
      className={`${STYLES.desktopWrapper} ${
        pageContext === 'login_page' ? STYLES.fullHeight : ''
      }`}
    >
      <div>
        <Text
          colour="brandBlue500"
          namespace={namespace}
          text={`title`}
          variant="display36"
          element="h1"
          align="left"
        />
        <Text
          colour="brandBlue500"
          namespace={namespace}
          text={`subtitle_desktop`}
          variant="textRegular18"
        />
        <AppDownloadButtons shippingCode={shippingCode} />
        {handleOnClick && (
          <Button
            typography={{
              namespace: 'shared',
              text: 'continue_to_dashboard'
            }}
            onClick={handleOnClick}
            disableAnalytics
          />
        )}
      </div>
      <div className={STYLES.displays}>
        <AppDisplays />
      </div>
    </div>
  )
}

const DownloadAppSection = ({
  handleOnClick,
  pageContext,
  shippingCode
}: Props): JSX.Element => {
  const { windowWidth } = useWindowSize()
  const namespace = 'butternut_app'
  if (windowWidth >= BREAKPOINTS.lg) {
    return (
      <DesktopView
        namespace={namespace}
        pageContext={pageContext}
        handleOnClick={handleOnClick}
        shippingCode={shippingCode}
      />
    )
  }
  return (
    <MobileView
      namespace={namespace}
      pageContext={pageContext}
      handleOnClick={handleOnClick}
      shippingCode={shippingCode}
    />
  )
}

export { Props }
export default DownloadAppSection
