// @flow

import * as React from 'react'

export default class BaseModalComponent<Props, State> extends React.Component<Props, State> {
  //  dashToCamelCase method turns 'recipes-modal' into 'recipesModal'
  dashToCamelCase = (modalName: string): string => {
    return modalName.replace(/-([a-z])/g, (g: string): string => {
      return g[1].toUpperCase()
    })
  }

  openModal = (modalName: string): void => {
    const modal = this.dashToCamelCase(modalName)
    this.setState({
      [`${modal}IsOpen`]: true,
      [`${modal}IsMounted`]: true
    })
  }

  closeModal = (modalName: string): void => {
    const modal = this.dashToCamelCase(modalName)
    this.setState(
      {[`${modal}IsOpen`]: false},
      (): void => {
        setTimeout((): void => {
          this.setState({[`${modal}IsMounted`]: false})
        }, 500)
      }
    )
  }
}
