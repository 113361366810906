import { gql } from '@apollo/client'

const ACCOUNT_NAVIGATION_QUERY = gql`
  query accountNavigationQuery {
    user {
      id
      firstName
    }
  }
`

export { ACCOUNT_NAVIGATION_QUERY }
