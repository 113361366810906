// @noflow
import React, { useEffect } from 'react'

import LoadingScreen from '@/components/elements/organisms/LoadingScreen/LoadingScreen'

const LoadingPaused = (): JSX.Element => {
  useEffect(() => {
    setTimeout(function () {
      window.location.href = '/dashboard/paused'
    }, 2000)
  }, [])

  return (
    <LoadingScreen
      isOpen
      title={{
        text: 'loading_screen.title',
        namespace: 'shared'
      }}
    />
  )
}

export default LoadingPaused
