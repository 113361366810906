// @noflow
import React from 'react'

import {
  ICON_COLOURS,
  IconColours
} from '@/components/elements/atoms/Icon/Icon'

type Props = {
  size: number
  accentColour: IconColours
  width?: number | string
}

const RadioSelected = ({ size, accentColour, width }: Props): JSX.Element => {
  return (
    <svg
      width={width}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12.5"
        cy="12.5"
        r="12"
        fill="#fff"
        stroke={ICON_COLOURS[accentColour]}
      />
      <circle cx="12.5" cy="12.5" r="9.5" fill={ICON_COLOURS[accentColour]} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m11.398 15.853-3.261-3.041a.432.432 0 0 1-.137-.33c0-.135.046-.245.137-.33l.722-.66a.448.448 0 0 1 .342-.147c.137 0 .257.05.361.147l2.188 2.052 4.688-4.397a.512.512 0 0 1 .36-.147c.138 0 .252.049.343.147l.722.66a.432.432 0 0 1 .137.33.432.432 0 0 1-.137.329l-5.761 5.387a.46.46 0 0 1-.352.147.46.46 0 0 1-.352-.147Z"
        fill="#fff"
      />
    </svg>
  )
}

export default RadioSelected
