import React, { useCallback } from 'react'

import { CustomerIssueManagementInitialDataQuery_user_reportable_potentialMissingItems as PotentialMissingItem } from '@/components/pages/CustomerIssueManagementPage/queries/__generated__/CustomerIssueManagementInitialDataQuery'

import { CustomerIssueReportCreate_response_inputFields_CustomerIssueManagementInputFieldMultifield_fields_CustomerIssueManagementInputFieldInteger as MultifieldIntegerField } from '../../../../mutations/__generated__/CustomerIssueReportCreate'
import { CustomerIssueManagementInputInput } from '@/types'

import Stepper from './Stepper'

type ProductStepperProps = {
  field: MultifieldIntegerField
  multifieldInput: CustomerIssueManagementInputInput
  potentialMissingItem: PotentialMissingItem
  setValue: (newInput: CustomerIssueManagementInputInput) => void
}

const ProductStepper = ({
  field,
  multifieldInput,
  potentialMissingItem,
  setValue
}: ProductStepperProps): JSX.Element | null => {
  const multifieldInputInputs =
    multifieldInput.inputs as Array<CustomerIssueManagementInputInput>
  const integerInput = multifieldInputInputs.find(
    (input) => input.fieldId === field.id
  )

  const setStepperValue = useCallback(
    (integerValue: number) => {
      const newMultifieldInput: CustomerIssueManagementInputInput = {
        ...multifieldInput
      }
      const newInputs = multifieldInputInputs.filter(
        (input) => input.fieldId !== field.id
      )
      newInputs.push({ fieldId: field.id, integerValue })
      newMultifieldInput.inputs = newInputs
      setValue(newMultifieldInput)
    },
    [field.id, multifieldInput, multifieldInputInputs, setValue]
  )

  if (!integerInput) {
    setStepperValue(1)
    return null
  }

  return (
    <Stepper
      maxValue={potentialMissingItem.quantity}
      minValue={1}
      input={integerInput}
      text={potentialMissingItem.name}
      subtitle={field.name}
      setValue={setStepperValue}
    />
  )
}

export default ProductStepper
