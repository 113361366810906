// @flow

import * as React from 'react'
import { connect } from 'react-redux'

import * as THUNKS from '../../thunks'
import * as ACTIONS from '../../actions'

import type { Dispatch } from 'redux'
import type { State } from '../../index'
import type { SCAResponse } from '@/components/pages/CheckoutPage/helpers/submission'
import type { CSRFToken } from '@/shared_types/csrfToken'

import { CardElement, useStripe, Elements, useElements } from '@stripe/react-stripe-js'
import { Stripe, StripeCardElementChangeEvent, loadStripe } from '@stripe/stripe-js'
import { localeToStripeLocale } from '@/utils/localeToStripeLocale'
import i18next from 'i18next'

import BRAND_COLOURS from '@/constants/BrandColours'
import SUPPORT_COLOURS from '@/constants/SupportColours'

type PresentationalProps = {|
  stripe_publishable_key: string,
  sca_enforced: boolean,
  email: string,
  isModalOpen: boolean,
  csrfToken: CSRFToken,
  addCreditCardToSubscriptionErrorMessage: ?string,
  addCreditCardToSubscriptionInFlight: boolean
|}

type ActionProps = {|
  addCreditCardToSubscription: (string) => void,
  closeAddCreditCardToSubscriptionModal: () => void
|}

type Props =
  & PresentationalProps
  & ActionProps

type ConfirmCardSetupResponse = {|
  paymentMethod: {|
    billing_details: {|
      address: {||},
      email: ?string,
        name: ?string,
        phone: ?string
      |},
    card: CardElement,
    created: number,
    customer: null,
    id: string,
    livemode: boolean,
    object: string,
    type: string
  |},
  error: {|
    message: string
  |}
|}

const mapStateToProps = (state: State): PresentationalProps => {
  const { csrfToken, subscriptionDetails } = state

  const {
    stripe_publishable_key,
    sca_enforced,
    email,
    addCreditCardToSubscriptionDetails
  } = subscriptionDetails
  const {
    isModalOpen,
    addCreditCardToSubscriptionErrorMessage,
    addCreditCardToSubscriptionInFlight
  } = addCreditCardToSubscriptionDetails

  return {
    stripe_publishable_key,
    sca_enforced,
    email,
    csrfToken,
    isModalOpen,
    addCreditCardToSubscriptionErrorMessage,
    addCreditCardToSubscriptionInFlight
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => {
  const closeAddCreditCardToSubscriptionModal = (): void => dispatch(ACTIONS.toggleAddCreditCardToSubscriptionModal())

  const addCreditCardToSubscription = (paymentMethodId: string): void => {
    dispatch(THUNKS.addCreditCardToSubscription(paymentMethodId))
  }

  return {
    closeAddCreditCardToSubscriptionModal,
    addCreditCardToSubscription
  }
}

const requestFromSCAController = (email: string, csrfToken: CSRFToken): Promise<SCAResponse> => {
  const endpoint = '/api/payment_methods/credit_cards/strong_customer_authentication/create'
  // eslint-disable-next-line i18next/no-literal-string
  const queryParams = `email=${email}&source=admin`
  const csrf = ((csrfToken: any): string)
  return fetch(`${endpoint}?${queryParams}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': csrf
    },
    credentials: 'same-origin'
  }).then((response: Response): Promise<SCAResponse> => {
    return response.json()
  })
}

const confirmWithoutSCA = (card: CardElement, stripe: Stripe, addCreditCardToSubscription: (paymentMethodId: string) => void, setErrorMessage: (message: string) => void): void => {
  stripe.createPaymentMethod({
    type: 'card',
    card
  })
    .then((result: ConfirmCardSetupResponse): Promise<Response> | typeof undefined => {
      if (result.error) {
        return setErrorMessage(result.error.message)
      } else {
        return addCreditCardToSubscription(result.paymentMethod.id)
      }
    })
}

const confirmWithSCA = (card: CardElement, stripe: Stripe, email: string, csrfToken: CSRFToken, addCreditCardToSubscription: (paymentMethodId: string) => void, setErrorMessage: (message: string) => void): void => {
  requestFromSCAController(encodeURIComponent(email), csrfToken)
    .then((res: SCAResponse): void => {
      stripe.confirmCardSetup(
        res.card_authentication_setup.client_secret,
        {
          payment_method: { card: card }
        }
      ).then((result: ConfirmCardSetupResponse): ?Promise<Response> => {
        if (result.error) {
          return setErrorMessage(result.error.message)
        }
        if (result.setupIntent && result.setupIntent.payment_method) {
          return addCreditCardToSubscription(result.setupIntent.payment_method)
        }
      })
    })
}

const ButtonWrapper = ({
  email,
  sca_enforced,
  csrfToken,
  addCreditCardToSubscriptionInFlight,
  closeAddCreditCardToSubscriptionModal,
  addCreditCardToSubscription,
  setErrorMessage
}: {|
  email: string,
  sca_enforced: boolean,
  csrfToken: CSRFToken,
  addCreditCardToSubscriptionInFlight: boolean,
  closeAddCreditCardToSubscriptionModal: () => void,
  addCreditCardToSubscription: (paymentMethodId: string) => void,
  setErrorMessage: (string) => void
|}): React.Node => {
  const elements = useElements()
  const stripe = useStripe()

  const handleOnClick = React.useCallback(
    (): void => {
      const card = elements.getElement('card')
      if (sca_enforced) {
        return confirmWithSCA(card, stripe, email, csrfToken, addCreditCardToSubscription, setErrorMessage)
      } else {
        return confirmWithoutSCA(card, stripe, addCreditCardToSubscription, setErrorMessage)
      }
    },
    [sca_enforced, elements, stripe, email, addCreditCardToSubscription, setErrorMessage, csrfToken],
  )
  return (
    <div className='restart-subscription-modal__footer'>
      <button
        className='card__button button--dark-grey'
        onClick={closeAddCreditCardToSubscriptionModal}
        disabled={addCreditCardToSubscriptionInFlight}
        type='button'
      >
        { 'CANCEL' }
      </button>
      <button
        className={`card__button button--green`}
        onClick={handleOnClick}
        disabled={addCreditCardToSubscriptionInFlight}
        type='button'
      >
        { addCreditCardToSubscriptionInFlight ? 'SUBMITTING' : 'SUBMIT' }
      </button>
    </div>
  )
}

const AddCreditCardToSubscriptionModal = ({
  isModalOpen,
  stripe_publishable_key,
  sca_enforced,
  email,
  csrfToken,
  addCreditCardToSubscriptionErrorMessage,
  closeAddCreditCardToSubscriptionModal,
  addCreditCardToSubscription,
  addCreditCardToSubscriptionInFlight,
}: Props): React.Node => {
  if (!stripe_publishable_key) return null
  const stripePromise = loadStripe(stripe_publishable_key)
  const [errorMessage, setErrorMessage] = React.useState(null)

  const onCardElementChange = React.useCallback((event: StripeCardElementChangeEvent): void => {
    if (event.error !== undefined) {
      setErrorMessage(event.error.message)
    } else setErrorMessage(null)
  }, [])

  if (!isModalOpen) return null
  return (
    <div className='modal-container'>
      <div className='modal-container__backdrop' />
      <div className='delivery-address-modal__form-wrapper'>
        <div
          className='modal-container__modal restart-subscription-modal'
          style={{ width: '600px' }}
        >
          <div className='card-details-modal__header'>
            <h3>
              { 'Please enter their card details:' }
            </h3>
          </div>
          <Elements
            options={{ locale: localeToStripeLocale(i18next.language) }}
            stripe={stripePromise}
          >
            <CardElement
              onChange={onCardElementChange}
              // eslint-disable-next-line react/forbid-component-props
              options={{
                hidePostalCode: true,
                style: {

                  base: {
                    fontSize: '18px',
                    fontFamily: 'gt-pressura-regular, helvetica, arial, sans-serif',
                    color: BRAND_COLOURS.brandBlue400,

                    '::placeholder': {
                      color: errorMessage === null ? BRAND_COLOURS.brandBlue400 : SUPPORT_COLOURS.dangerRed300
                    },
                  },
                  invalid: {
                    color: SUPPORT_COLOURS.dangerRed300
                  }
                }
              }}
            />
            { errorMessage !== null && (
              <p>
                { errorMessage }
              </p>
            ) }
            { addCreditCardToSubscriptionErrorMessage !== null && (
              <p>
                { addCreditCardToSubscriptionErrorMessage }
              </p>
            ) }
            <ButtonWrapper
              email={email}
              sca_enforced={sca_enforced}
              csrfToken={csrfToken}
              addCreditCardToSubscriptionInFlight={addCreditCardToSubscriptionInFlight}
              closeAddCreditCardToSubscriptionModal={closeAddCreditCardToSubscriptionModal}
              addCreditCardToSubscription={addCreditCardToSubscription}
              setErrorMessage={setErrorMessage}
            />
          </Elements>
        </div>
      </div>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddCreditCardToSubscriptionModal)
