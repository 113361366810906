// @noflow
import { useOccasion } from '@/context/festiveTheme/festiveTheme'
import type { AccountRouteObject } from '@/routes/account'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import classnames from 'classnames'
import i18n from 'i18next'
import { merge } from 'lodash'
import compact from 'lodash/compact'
import isFunction from 'lodash/isFunction'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, Params, RouteObject, useMatches } from 'react-router-dom'

import { useShowDixa } from '@/hooks/useDixa'

import BannerManagerWithQuery from '@/components/elements/molecules/BannerManager/BannerManager'
import { Footer } from '@/components/elements/molecules/Footer'
import { Header, HeaderProps } from '@/components/elements/molecules/Header'
import { NavigationTabBar } from '@/components/elements/molecules/NavigationTabBar'
import { SubHeader } from '@/components/elements/molecules/SubHeader'
import RouteTransition from '@/components/templates/RouteTransition/RouteTransition'

import STYLES from './Screen.module.sass'

enum ScreenBackgroundColor {
  brandYellow100 = 'brandYellow100',
  brandYellow200 = 'brandYellow200',
  brandYellow300 = 'brandYellow300',
  brandBlue100 = 'brandBlue100',
  brandPink100 = 'brandPink100'
}

type HighlightedSection = {
  children: JSX.Element | JSX.Element[]
  backgroundColor?: ScreenBackgroundColor
}

type Props = {
  children: JSX.Element | JSX.Element[]
  highlightedSection?: HighlightedSection
  showBannerManager?: boolean
  header?: HeaderProps
  showSubHeader?: boolean
  hideNavigationTabBar?: boolean
  hideFooter?: boolean
  hidePadding?: boolean
  backgroundColor?: ScreenBackgroundColor
  documentTitleKey: string
  childrenMaxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  disableGutters?: boolean
  fillScreen?: boolean
  showDixa?: boolean
}

/**
 * Screen component
 *
 * Use this component to render a screen with a header, footer and navigation tab bar.
 *
 * @example
  ```
  <Screen
    header={{
      title: {
        text: 'path.to.translation.key',
        ...otherOptionalTextProps
      },
      ...otherOptionalHeaderProps
    }}
    footer={{
      shippingCountryCode: 'GB'
    }}
  >
    // children JSX as a screen content
  </Screen>
  ```
 * @param {JSX.Element | JSX.Element[]} children - JSX as a screen content
 * @param {HeaderProps} header - Header props
 * @param {FooterProps} footer - Footer props
 * @param {boolean} showBannerManager - Show banner manager
 * @param {boolean} showSubHeader - Show sub header
 * @param {boolean} hideNavigationTabBar - Hide navigation tab bar
 * @param {boolean} hideFooter - Hide footer
 * @param {boolean} disableGutters - If true, the left and right padding is removed.
 * @returns {JSX.Element} - Screen component
 * @category Components
 * @subcategory Templates
 * @component Screen
 * @abstract
 */
const Screen = ({
  children,
  highlightedSection,
  showBannerManager = false,
  header,
  showSubHeader = false,
  hideNavigationTabBar = false,
  hideFooter = false,
  hidePadding = false,
  backgroundColor = ScreenBackgroundColor.brandYellow100,
  documentTitleKey = 'default',
  childrenMaxWidth,
  disableGutters = false,
  fillScreen = false,
  showDixa = false
}: Props): JSX.Element => {
  const { festiveClass } = useOccasion()
  const subHeaderContentRef = useRef<null | HTMLDivElement>(null)
  const { t } = useTranslation('account')

  useEffect(() => {
    const prevTitle = document.title
    document.title = t(`document_titles.${documentTitleKey}`)
    return () => {
      document.title = prevTitle
    }
  }, [documentTitleKey, t])

  useShowDixa(showDixa)

  const contentClassName = classnames(STYLES.content, STYLES.contentWrapper, {
    [STYLES.contentFullWidth]: hideNavigationTabBar,
    [STYLES.contentFillScreen]: fillScreen
  })

  const containerWrapperClassName = classnames(
    STYLES.containerWrapper,
    STYLES[backgroundColor],
    header?.variant && STYLES[header.variant],
    festiveClass && STYLES[festiveClass],
    {
      [STYLES.showNavigationTabBar]: !hideNavigationTabBar,
      [STYLES.noPadding]: hidePadding,
      [STYLES.noFooter]: hideFooter,
      [STYLES.containerFillScreen]: fillScreen
    }
  )

  const childrenWrapperClassName = classnames(
    STYLES.childrenWrapper,
    childrenMaxWidth && STYLES[childrenMaxWidth],
    {
      [STYLES.childrenFillScreen]: fillScreen
    }
  )

  const screenWrapperClassName = classnames(
    STYLES.screenWrapper,
    festiveClass && STYLES[festiveClass],
    {
      [STYLES.screenWrapperFullWidth]: hideNavigationTabBar
    }
  )

  const highlightedSectionClassName = classnames(STYLES.highlightedSection, {
    [STYLES[highlightedSection?.backgroundColor || 'brandYellow200']]:
      highlightedSection?.backgroundColor || 'brandYellow200'
  })

  return (
    <div className={screenWrapperClassName} data-testid="screen">
      {showBannerManager && <BannerManagerWithQuery i18n={i18n} />}
      {header && <Header {...header} />}
      <div className={containerWrapperClassName}>
        <Container
          maxWidth={fillScreen ? false : 'lg'}
          disableGutters={disableGutters || fillScreen}
          className={STYLES.gridContainer}
        >
          <Grid container className={STYLES.container} wrap="nowrap">
            {!hideNavigationTabBar && (
              <Grid item md={2} className={STYLES.navigationTabBar}>
                <NavigationTabBar />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              md={hideNavigationTabBar ? 12 : 10}
              className={contentClassName}
              id="screen-content"
            >
              <RouteTransition>
                <>
                  <SubHeader
                    showSubHeader={showSubHeader}
                    content={
                      <div id="sub-header-content" ref={subHeaderContentRef} />
                    }
                  />
                  {highlightedSection && (
                    <div className={highlightedSectionClassName}>
                      {highlightedSection.children}
                    </div>
                  )}
                  <div className={childrenWrapperClassName}>{children}</div>
                </>
              </RouteTransition>
            </Grid>
          </Grid>
        </Container>
      </div>
      {!hideFooter && <Footer />}
    </div>
  )
}

/**
 * Flatten the current route child route objects to generate the Screen templates props.
 * Props as merged from the parent route downwards.
 * @param data - an array of Route objects based on the current route hierarchy
 */
const generateScreenTemplateProps = (
  data: RouteObject &
    {
      params: Params<string>
      handle: AccountRouteObject['handle']
    }[]
): Props => {
  const allProps: AccountRouteObject['handle'][] = compact(
    data.map((x) => {
      if (isFunction(x.handle)) return x.handle(x.params)
      return x.handle
    })
  )

  const mergedProps: AccountRouteObject['handle'] = {}

  allProps.forEach((props) => merge(mergedProps, props))

  return mergedProps
}

/**
 * Connects the screen template to react router so it's values are defined by the Route handle object.
 * This wrapper should be used with instead of implementing <Screen /> directly
 */
const ScreenWithDataProvider = (): JSX.Element => {
  const matches = useMatches() as (AccountRouteObject & {
    params: Params<string>
  })[]
  const templateData: Props = generateScreenTemplateProps(matches) || {}

  return (
    <Screen {...templateData}>
      <Outlet />
    </Screen>
  )
}

export type { Props }

export { Screen, ScreenWithDataProvider, ScreenBackgroundColor }
