/* eslint-disable i18next/no-literal-string */
// @noflow
import {
  VirtualElement,
  arrow,
  autoUpdate,
  flip,
  size as floatingUIsize,
  offset,
  shift,
  useFloating
} from '@floating-ui/react-dom'
import classnames from 'classnames'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { animated, useSpring } from 'react-spring'

import Icon from '@/components/elements/atoms/Icon/Icon'
import Link, { Props as LinkProps } from '@/components/elements/atoms/Link/Link'
import type { Props as TextProps } from '@/components/elements/atoms/Text/Text'

import STYLES from './Tooltip.module.sass'

type Props = Pick<LinkProps, 'size' | 'variant' | 'flush'> & {
  label?: Pick<
    TextProps,
    | 'text'
    | 'namespace'
    | 'variables'
    | 'translate'
    | 'colour'
    | 'shouldScale'
    | 'element'
    | 'underline'
  >
  children: JSX.Element
  onOpen?: () => void
  displayIcon?: boolean
  iconSize?: number
  tooltipMaxWidth?: number
  identifier: string
}

const CARET_HEIGHT = 15

const Tooltip = ({
  label,
  children,
  size = 14,
  flush,
  variant,
  onOpen,
  displayIcon = true,
  iconSize = 26,
  tooltipMaxWidth,
  identifier
}: Props): JSX.Element | null => {
  const elementRef = useRef<HTMLSpanElement>(null)
  const buttonRef = useRef<HTMLAnchorElement | HTMLButtonElement>(null)
  const caretRef = useRef(null)

  const {
    x,
    y,
    update,
    refs: { setReference, setFloating },
    strategy,
    placement,
    middlewareData: { arrow: { x: arrowX } = {} }
  } = useFloating<VirtualElement>({
    placement: 'top',
    strategy: 'fixed',
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(CARET_HEIGHT),
      shift(),
      flip(),
      arrow({ element: caretRef }),
      floatingUIsize({
        apply({ availableWidth, elements }) {
          elements.floating.style.width =
            tooltipMaxWidth && availableWidth > tooltipMaxWidth
              ? `${tooltipMaxWidth}px`
              : `${availableWidth}px`
        }
      })
    ]
  })

  const [open, setOpen] = useState(false)

  // recalculate the tooltip position on open
  // this mitigates issues where the tooltip parents may have moved ie. in a carousel
  useEffect(() => {
    if (open) {
      update()

      onOpen && onOpen()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const toggle = useCallback(
    (e): void => {
      e.stopPropagation()
      setOpen(!open)
    },
    [setOpen, open]
  )

  const closeOnClickOutside = useCallback(() => setOpen(false), [])
  const closeOnBlur = useCallback(() => setOpen(false), [])
  const closeOnEsc = useCallback((event) => {
    if (event.key === 'Escape') setOpen(false)
  }, [])

  const cleanUpListeners = useCallback(() => {
    document.removeEventListener('click', closeOnClickOutside)
    document.removeEventListener('keydown', closeOnEsc)
    if (!isNull(elementRef.current))
      elementRef.current.removeEventListener('blur', closeOnBlur)
  }, [closeOnBlur, closeOnClickOutside, closeOnEsc])

  // Clean up listeners on unmount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => cleanUpListeners(), [])

  useEffect(() => {
    if (open) {
      document.addEventListener('click', closeOnClickOutside)
      document.addEventListener('keydown', closeOnEsc)
      if (!isNull(elementRef.current))
        elementRef.current.addEventListener('blur', closeOnBlur)
    } else {
      document.removeEventListener('click', closeOnClickOutside)
      document.removeEventListener('keydown', closeOnEsc)
      if (!isNull(buttonRef.current))
        buttonRef.current.removeEventListener('blur', closeOnBlur)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const animate = useSpring({
    config: { mass: 1, tension: 500, friction: 18 },
    opacity: open ? 1 : 0,
    // eslint-disable-next-line i18next/no-literal-string
    transform: `scale(${open ? 1 : 0.85}) translate(0, ${
      open ? 0 : 20 * (placement === 'top' ? 1 : -1)
    }px)`
  })

  const TooltipIcon = (() => {
    return (
      <div className={STYLES.icon} ref={setReference}>
        <Icon asset="question" size={iconSize} width={iconSize} />
      </div>
    )
  })()

  const tooltipClassName = classnames(STYLES.tooltip, {
    [STYLES.tooltipShow]: open,
    [STYLES.tooltipHide]: !open
  })

  const caretClassName = classnames(STYLES.caret, {
    [STYLES.caretBottom]: placement === 'bottom'
  })

  return (
    <span ref={displayIcon ? elementRef : setReference}>
      {!isUndefined(label) ? (
        <Link
          reactRef={buttonRef as RefObject<HTMLAnchorElement>}
          onClick={toggle}
          size={size}
          flush={flush}
          variant={variant}
          suffix={displayIcon ? TooltipIcon : null}
          text={label.text}
          variables={label.variables}
          namespace={label.namespace}
          translate={label.translate}
          colour={label.colour}
          shouldScale={label.shouldScale}
          nativeUnderline={label.underline}
          identifier={identifier}
        />
      ) : (
        <button
          className={STYLES.button}
          type="button"
          ref={buttonRef as RefObject<HTMLButtonElement>}
          onClick={toggle}
        >
          {TooltipIcon}
        </button>
      )}
      <animated.div
        ref={setFloating}
        style={{
          ...animate,
          position: strategy,
          top: y ?? 0,
          left: x ?? 0
        }}
        role="tooltip"
        aria-hidden={!open}
        className={tooltipClassName}
      >
        {children}
        <svg
          height={CARET_HEIGHT}
          width="20"
          ref={caretRef}
          className={caretClassName}
          style={{
            position: 'absolute',
            top: placement === 'top' ? `calc(100% - 1px)` : 'auto',
            transform: placement === 'bottom' ? 'rotate(180deg)' : undefined,
            // 4px == border height set in CSS (TODO - make dynamic)
            bottom: placement === 'bottom' ? 'calc(100% + 4px)' : 'auto',
            left: arrowX
          }}
        >
          <polygon points={`0, 0 20, 0 10, ${CARET_HEIGHT}`} />
        </svg>
      </animated.div>
    </span>
  )
}

export { Props }
export default Tooltip
