import {
  useApiToken,
  useCsrfToken
} from '@/context/injectedValues/injectedValues'

type UploadPhoto = {
  file: Blob
  dogid: string
}

type UseDogProfilePictureUpload = {
  uploadPhoto: ({
    file,
    dogid
  }: UploadPhoto) => Promise<Response | Record<string, never>>
}

const useDogProfilePictureUploadAPI = (): UseDogProfilePictureUpload => {
  const { token } = useApiToken()
  const { csrfToken } = useCsrfToken()

  const uploadPhoto = async ({
    file,
    dogid
  }: UploadPhoto): Promise<Response | Record<string, never>> => {
    const profilePictureForm = new FormData()

    profilePictureForm.append('avatar', file)

    try {
      const response = await fetch(
        // eslint-disable-next-line i18next/no-literal-string
        `/api/clients/v1/my-dog/${dogid}/avatar?token=${token}`,
        {
          method: 'POST',
          credentials: 'same-origin',
          headers: {
            'X-CSRF-Token': csrfToken ?? ''
          },
          body: profilePictureForm
        }
      )

      return response
    } catch (error) {
      return {}
    }
  }

  return { uploadPhoto }
}

export { useDogProfilePictureUploadAPI }
