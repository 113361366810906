import { gql } from '@apollo/client'

const DOG_WEIGHT_AND_APPETITE_QUERY = gql`
  query DogWeightAndAppetiteQuery {
    user {
      id
      subscription {
        id
        idealPlan {
          pricingCurve {
            pricingCohort
          }
        }
        plan {
          pricingCurve {
            pricingCohort
          }
        }
      }
      dogs {
        id
        name
        gender
        weightInGrams
        bodyShape
        exercise
        neutered
        eaterType
        snackingHabits
      }
    }
  }
`

export { DOG_WEIGHT_AND_APPETITE_QUERY }
