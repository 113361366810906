// @noflow
import {
  addMonths,
  differenceInDays,
  isAfter,
  isSameDay,
  startOfMonth
} from 'date-fns'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import type { ReactElement } from 'react'

import DaysOfTheWeekV2 from '../CalendarV2/components/DaysOfTheWeekV2'
import HeaderMonthV2 from '../CalendarV2/components/HeaderMonthV2'
import CalendarDatesV2 from './components/CalendarDatesV2'

import STYLES from './CalendarV2.module.sass'

import type { PausedCalendarDatesData_calendarDates as CalendarDateV1 } from '../Calendar/queries/__generated__/PausedCalendarDatesData'
import type { CalendarDatesV2Fragment as CalendarDate } from './fragments/__generated__/calendarDatesV2Fragment'
import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'

type Props = {
  selectedDate?: Date | null
  shippingCountryCode: CountryCode
  calendarDates: Array<CalendarDate | CalendarDateV1>
  currentDeliveryDate: Date
  currentDeliveryDates: Array<Date>
  setSelectedDate: (selectedDate: Date | null) => void
  scrollToSelectedDate: boolean
  maxMonthsShown: number
  noCalendarBorder?: boolean
}

const CalendarV2 = ({
  selectedDate,
  currentDeliveryDate,
  currentDeliveryDates,
  shippingCountryCode,
  calendarDates,
  setSelectedDate,
  scrollToSelectedDate,
  maxMonthsShown,
  noCalendarBorder = false
}: Props): ReactElement => {
  const today = new Date()
  const initialMonthInView = startOfMonth(today)
  const months = useMemo(() => {
    const monthsArray = []
    for (let i = 0; i < maxMonthsShown; i++) {
      const month = addMonths(initialMonthInView, i)
      const monthDates = calendarDates.filter(
        (date) => new Date(date.date).getMonth() === month.getMonth()
      )
      monthsArray.push(monthDates)
    }
    return monthsArray
  }, [calendarDates, initialMonthInView, maxMonthsShown])

  const [forecastedDeliveryDates, setForecastedDeliveryDates] = useState<
    Array<Date>
  >([])

  const updateForecastedDates = useCallback(
    (newSelectedDate: Date) => {
      const durationOffset = differenceInDays(
        newSelectedDate.setHours(0),
        currentDeliveryDate.setHours(0)
      )

      const updatedDeliveryDates = currentDeliveryDates
        .filter((date) => isAfter(date, currentDeliveryDate))
        .map((date) => {
          const newDate = new Date(date)
          newDate.setDate(newDate.getDate() + durationOffset)
          const calendarDate = calendarDates.find((cd) =>
            isSameDay(newDate, new Date(cd.date))
          )
          if (calendarDate && !calendarDate.deliverable) {
            const replacementDate = new Date(calendarDate.replacementDate)
            newDate.setFullYear(replacementDate.getFullYear())
            newDate.setMonth(replacementDate.getMonth())
            newDate.setDate(replacementDate.getDate())
          }
          return newDate
        })

      setForecastedDeliveryDates(updatedDeliveryDates)
    },
    [calendarDates, currentDeliveryDate, currentDeliveryDates]
  )

  useEffect(() => {
    if (!selectedDate) {
      setForecastedDeliveryDates([])
    } else {
      updateForecastedDates(selectedDate)
    }
  }, [selectedDate, updateForecastedDates])

  const handleSetSelectedDate = useCallback(
    (newSelectedDate: Date) => {
      if (isSameDay(newSelectedDate, currentDeliveryDate)) {
        setSelectedDate(null)
      } else {
        setSelectedDate(newSelectedDate)
      }
    },
    [currentDeliveryDate, setSelectedDate]
  )

  return (
    <div
      className={`${STYLES.calendar} ${
        noCalendarBorder ? STYLES.noCalendarBorder : ''
      }`}
      data-testid="calendar-v2"
    >
      <DaysOfTheWeekV2 />
      {months.map((month, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index}>
          <HeaderMonthV2
            currentMonthInView={addMonths(initialMonthInView, index)}
            shippingCountryCode={shippingCountryCode}
          />
          <div className={`${STYLES.days}`}>
            <CalendarDatesV2
              calendarDates={month}
              forecastedDeliveryDates={forecastedDeliveryDates}
              selectedDate={selectedDate}
              setSelectedDate={handleSetSelectedDate}
              currentDeliveryDate={currentDeliveryDate}
              currentDeliveryDates={currentDeliveryDates}
              scrollToSelectedDate={scrollToSelectedDate}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

export type { Props }
export { CalendarV2 }
export default CalendarV2
