//  @noflow

/* eslint-disable i18next/no-literal-string */
import { ACCOUNT_ROUTES } from '@/routes'
import React, { useCallback, useContext } from 'react'

import WhiteCheckmarkBlueBg from 'assets/images/icons/checkmarks/white-checkmark-blue-bg.svg'

import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'
import Text from '@/components/elements/atoms/Text/Text'
import { NavigateContext } from '@/components/pages/App'

import STYLES from './BasketCard.module.sass'

import type { VariantDelivery } from '../../../../../types/index'

type Props = {
  selectedProductVariantFrequency?: VariantDelivery
  selectedProductQuantity?: number
  polygonPosition?: 'top' | 'bottom'
  nextEditableBoxId?: string
}

const BasketCard = ({
  selectedProductVariantFrequency,
  selectedProductQuantity,
  polygonPosition,
  nextEditableBoxId = ''
}: Props): JSX.Element => {
  const navigate = useContext(NavigateContext)

  const handleOnClick = useCallback(() => {
    switch (selectedProductVariantFrequency) {
      case 'on_demand': {
        window.analytics.track('CTA clicked', {
          properties: {
            location: window.location.pathname,
            title: 'add to basket'
          }
        })

        navigate(ACCOUNT_ROUTES.basket, ACCOUNT_ROUTES.basket)

        break
      }
      case 'one_off': {
        window.analytics.track('CTA clicked', {
          properties: {
            location: window.location.pathname,
            title: 'add to box'
          }
        })

        const route = `${ACCOUNT_ROUTES.orders}/${nextEditableBoxId}#alert`
        const legacy = `/dashboard/orders/${nextEditableBoxId}#alert`
        navigate(route, legacy)

        break
      }
      case 'recurring': {
        window.analytics.track('CTA clicked', {
          properties: {
            location: window.location.pathname,
            title: 'add to plan'
          }
        })

        const route = ACCOUNT_ROUTES.base
        const legacy = '/dashboard/subscription#alert'
        navigate(route, legacy)

        break
      }
    }
  }, [navigate, nextEditableBoxId, selectedProductVariantFrequency])

  return (
    <>
      {polygonPosition && polygonPosition === 'top' && (
        <div className={`${STYLES.polygon} ${STYLES.top}`} />
      )}
      <Card variant="brandYellow200">
        <div className={STYLES.card}>
          <div className={STYLES.cardContent}>
            <img alt="checkmark" src={WhiteCheckmarkBlueBg} />
            <Text
              text="extras.basket.basket_card.title"
              namespace="dashboard"
              variant="textRegular16"
              colour="brandBlue500"
              variables={{
                count: selectedProductQuantity,
                context: selectedProductVariantFrequency
              }}
              margin={false}
            />
          </div>
          <Button
            typography={{
              text: 'extras.basket.basket_card.button',
              namespace: 'dashboard',
              variables: { context: selectedProductVariantFrequency }
            }}
            variant="secondary"
            onClick={handleOnClick}
            disableAnalytics
          />
        </div>
      </Card>
      {polygonPosition && polygonPosition === 'bottom' && (
        <div className={`${STYLES.polygon} ${STYLES.bottom}`} />
      )}
    </>
  )
}

export default BasketCard
