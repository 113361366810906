// @flow

import * as React from 'react'
import { Image } from 'cloudinary-react'
import { Cloudinary } from 'cloudinary-core'

type CropType =
  | 'scale'
  | 'fill'
  | 'fit'
  | 'limit'
  | 'pad'
  | 'lpad'
  | 'crop'

type GravityType =
  | 'auto'
  | 'north_west'
  | 'north'
  | 'north_east'
  | 'west'
  | 'center'
  | 'east'
  | 'south_west'
  | 'south'
  | 'south_east'
  | 'custom'

type CloudinaryParams = {|
  path: string,
  width?: number,
  height?: number,
  mode?: string,
  quality?: string,
  format?: string,
  crop?: CropType,
  gravity?: GravityType,
  aspectRatio?: string,
  dpr?: number | 'auto',
  x?: number,
  y?: number
|}

type Props = {|
  image: CloudinaryParams,
  alt: string,
  className?: string
|}

type Ref = {|
  current: ?HTMLImageElement
|}

const CloudName = 'ldhg5acsz'
const CloudPath = `https://res.cloudinary.com/${CloudName}/image/upload/`
const cloudinary = Cloudinary.new({ cloud_name: 'ldhg5acsz' })

const sharedTransformationAttributes = {
  quality: 'auto',
  fetch_format: 'auto',
}
/**
 * An image element that uses the cloudinary API and React package to dynamically
 * transform our uploaded images
 * Cloudinary allows us to set many transformations including height, width,
 * mode (scale, crop, etc), and quality (by default we set quality to auto).
 * All cloudinary props other than path are optional.
 *
 * Example usage
 * <CloudinaryImage
 *   alt='An image of butternut box'
 *   image={{path: 'Web/photos/icons/butternut-box'}}
 * />
 */

const CloudinaryImage = React.forwardRef<Props, HTMLImageElement>(({
  alt,
  className,
  image: {
    path,
    width,
    height,
    mode,
    quality = 'auto',
    format = 'auto',
    crop,
    gravity,
    aspectRatio,
    dpr,
    x,
    y
  }
}: Props, ref: React.Ref<'img'>): React.Element<'img'> => {
  const isLazyLoaded = className && className.includes('lazy')
  const dataSrcUrl = cloudinary.url(path, {
    secure: true,
    ...sharedTransformationAttributes,
    width,
    height,
    mode,
    crop,
    gravity,
    aspectRatio,
    dpr,
    x,
    y
  })
  return (
  /**
   * If we set an image height or width to anything less than 0 the
   * Cloudinary default width or height will be set instead.
   */
    <Image
      className={className}
      cloudName={CloudName}
      publicId={!isLazyLoaded ? path : undefined}
      width={width}
      height={height}
      mode={mode}
      quality={quality}
      fetchFormat={format}
      crop={crop}
      gravity={gravity}
      innerRef={ref}
      alt={alt}
      aspectRatio={aspectRatio}
      dpr={dpr}
      x={x}
      y={y}
      dataSrc={isLazyLoaded ? dataSrcUrl : undefined}
    />
  )
}
)

export type {
  Ref,
  CloudinaryParams
}

export {
  CloudPath,
  CloudName
}

export default CloudinaryImage
