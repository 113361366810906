// @flow

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import locale from 'date-fns/esm/locale/en-GB'
import i18next from 'i18next'

import {
  daysOfTheWeek,
  dayToLetterDayOfWeek
} from '@/shared_types/date_time/week'
import { localeToDateLocale } from '@/utils/countryCodeHelper'

import Chevron from '../shared/images/Chevron'
import BRAND_COLOURS from '../../constants/BrandColours'

import LoadingDog from '../../../assets/images/illustrations/dogs/loading-dog.svg'

import type {
  CalendarType,
  CalendarDate
} from './../../shared_types/graphql/types/models/calendarType.js'
import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'
import type { DayOfWeek } from '@/shared_types/date_time/week'

type Props = {|
  calendarDates: CalendarType,
  clickDate: (e: SyntheticEvent<HTMLButtonElement>) => void,
  fetchingDates: boolean,
  monthInView: Date,
  progressCurrentMonth?: () => void,
  regressCurrentMonth?: () => void,
  currentlySelectedDate: Date | string,
  shippingCountryCode: CountryCode,
  nextBoxDates?: Array<Date>
|}

const generateDateClasses = (
  date: Date,
  deliverable: boolean,
  currentlySelectedDate: Date | string,
  nextBoxDates?: Array<Date>
): string => {
  const today = new Date().toLocaleDateString()

  const isSameDateAsFutureBoxes = (date: Date): boolean | void => {
    if (!nextBoxDates) return

    return !!nextBoxDates.find((nextBoxDate: Date): boolean => nextBoxDate.toLocaleDateString() === new Date(date).toLocaleDateString())
  }

  if (today === new Date(date).toLocaleDateString()) {
    return ('calendar__days__day--today')
  } else if (!deliverable) {
    return 'calendar__days__day--not-deliverable'
  } else if (new Date(currentlySelectedDate).toLocaleDateString() === new Date(date).toLocaleDateString()) {
    return 'calendar__days__day--selected'
  } else if (isSameDateAsFutureBoxes(date)) {
    return 'calendar__days__day--future-date'
  } else {
    return ''
  }
}

const dayHeaders = (day: DayOfWeek): string => {
  if (locale && locale.localize) {
    switch (day) {
      case 'Monday':
        return locale.localize.day(1, { width: 'narrow' })
      case 'Tuesday':
        return locale.localize.day(2, { width: 'narrow' })
      case 'Wednesday':
        return locale.localize.day(3, { width: 'narrow' })
      case 'Thursday':
        return locale.localize.day(4, { width: 'narrow' })
      case 'Friday':
        return locale.localize.day(5, { width: 'narrow' })
      case 'Saturday':
        return locale.localize.day(6, { width: 'narrow' })
      case 'Sunday':
        return locale.localize.day(0, { width: 'narrow' })
      default:
        throw new Error(`${day} is not a valid day of the week`)
    }
  } else {
    // fallback if locale is undefined
    return dayToLetterDayOfWeek(day)
  }
}

/**
 * Example usage:
 *
 * <Calendar
 *    calendarDates={dates}
 *    monthInView={new Date()}
 *    progressCurrentMonth={foo()}
 *    regressCurrentMonth={bar()}
 *    requestInFlight={false}
 *    selectedDeliveryDate={new Date()}
 *    shippingCountryCode={shippingCountryCode}
 *    nextBoxDates={nextBoxDates}
 * />
 */
const Calendar = ({
  calendarDates,
  fetchingDates,
  monthInView,
  progressCurrentMonth,
  regressCurrentMonth,
  clickDate,
  currentlySelectedDate,
  shippingCountryCode,
  nextBoxDates
}: Props): React.Element<'div'> => {
  const { t } = useTranslation('dashboard')
  const dateLocale = localeToDateLocale(shippingCountryCode, i18next.language)
  return (
    <div className='calendar'>
      <div className='calendar__month'>
        {
          regressCurrentMonth && (
            <button
              className='calendar__month__selector'
              disabled={monthInView.getMonth() === new Date().getMonth() && monthInView.getFullYear() === new Date().getFullYear()}
              onClick={regressCurrentMonth}
              type='button'
            >
              <Chevron
                color={BRAND_COLOURS.brandBlue500}
                rotation={0}
              />
            </button>
          )
        }
        <p className='calendar__month__name'>
          { `${format(monthInView, 'MMMM', { locale: dateLocale })} ${monthInView.getFullYear()}` }
        </p>
        {
          progressCurrentMonth && (
            <button
              className='calendar__month__selector'
              onClick={progressCurrentMonth}
              type='button'
            >
              <Chevron
                color={BRAND_COLOURS.brandBlue500}
                rotation={0}
              />
            </button>
          )
        }
      </div>
      <div className='calendar__day-names'>
        {
          daysOfTheWeek.map((name: DayOfWeek): React.Element<'span'> => (
            <span key={name}>
              { dayHeaders(name) }
            </span>
          ))
        }
      </div>
      <div className={`calendar__days ${fetchingDates ? 'calendar__days--loading' : ''}`}>
        {
          fetchingDates
            ? (
              <img
                alt={t('calendar.fetching_dates_img_alt')}
                src={LoadingDog}
              />
              )
            : (
                calendarDates.map((calendarDate: CalendarDate): React.Element<'button'> => {
                  const { date } = calendarDate
                  const dayNumber = new Date(date).getDay()

                  return (
                    <button
                      className={`calendar__days__day ${generateDateClasses(calendarDate.date, calendarDate.deliverable, currentlySelectedDate, nextBoxDates)}`}
                      type='button'
                      disabled={!calendarDate.deliverable}
                      key={date.toString()}
                      style={{ gridColumnStart: dayNumber === '0' ? 7 : dayNumber }}
                      onClick={clickDate}
                      value={date}
                    >
                      { new Date(date).getDate() }
                    </button>
                  )
                })
              )
        }
      </div>
    </div>
  )
}

export default Calendar
