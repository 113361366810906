// @noflow
import classname from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Image from '@/components/elements/atoms/Image'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './BreedCard.module.sass'

import Card from '../Card/Card'

type PopularBreed = {
  key: string
  number: number
  slug: string
  isPopular: true
}

type NonPopularBreed = {
  key: string
  isPopular: false
}

type Props = {
  selectedBreed: NonPopularBreed | PopularBreed
  namespace: string
  isPreWizardGuest: boolean
  variant: 'v1' | 'v2'
}

const BreedCard = ({
  selectedBreed,
  namespace,
  isPreWizardGuest,
  variant
}: Props): JSX.Element => {
  const { t } = useTranslation(namespace)

  const cn = classname(STYLES.animationWrapper, 'breedCardWrapper', {
    [STYLES.v2]: variant === 'v2'
  })

  return (
    <div id="matchedBreedCard" className={cn}>
      <Card>
        {selectedBreed.isPopular ? (
          <div className={STYLES.matchedBreed}>
            <div className={STYLES.matchedBreedImage}>
              <Image
                alt=""
                slug={selectedBreed.slug}
                image={{
                  width: variant === 'v2' ? 90 : 120,
                  height: variant === 'v2' ? 90 : 120,
                  resizeMode: 'resize_to_fill'
                }}
              />
            </div>
            <div>
              <div className={STYLES.banner}>
                <Text
                  text={`breed_autocomplete.feedback.popular_breed_html${
                    isPreWizardGuest ? '_pre_wizard_guest' : ''
                  }`}
                  namespace={namespace}
                  variables={{
                    number: selectedBreed.number,
                    sanitisedNamePlural: t(
                      `models:breeds.${selectedBreed.key}.sanitised_name_plural`
                    )
                  }}
                  margin={false}
                />
              </div>
            </div>
          </div>
        ) : // Not most popular breed
        selectedBreed.key !== 'dalmatian' ? (
          <div className={STYLES.banner}>
            <Text
              text={
                selectedBreed.key === 'other'
                  ? `breed_autocomplete.feedback.other_breed${
                      isPreWizardGuest ? '_pre_wizard_guest' : ''
                    }`
                  : `breed_autocomplete.feedback.generic_breed${
                      isPreWizardGuest ? '_pre_wizard_guest' : ''
                    }`
              }
              namespace={namespace}
              margin={false}
              variant={variant === 'v2' ? 'textRegular14' : 'textRegular16'}
            />
          </div>
        ) : (
          <Text
            text={`breed_autocomplete.feedback.dalmatian_atomic`}
            namespace={namespace}
            margin={false}
            variables={{
              href: 'https://help.butternutbox.com/en/articles/119-dalmatians-and-butternut%20target=  '
            }}
          />
        )}
      </Card>
    </div>
  )
}

export { Props, NonPopularBreed, PopularBreed }
export default BreedCard
