// @noflow
import { Factory } from 'fishery'

import type { RecommendationQuery_currentUser_User_planRecommendation_plans as Plan } from '@/components/pages/PlansPage/__generated__/RecommendationQuery'

import type { TypeOfPlan } from '@/types/index'

export default Factory.define<Plan>(() => ({
  __typename: 'Plan',
  durationInDays: 7,
  id: '1',
  numberOfPouches: 14,
  pouchSize: 600,
  pricePerDay: 1000,
  pricingCurve: {
    __typename: 'PricingCurve',
    pricingCohort: '1200g'
  },
  trialLengthInDays: 7,
  trialDiscountedPricePerDay: 100,
  typeOfPlanForCustomer: 'all' as TypeOfPlan,
  gramsPerDay: 1200
}))
