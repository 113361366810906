// @noflow
import classnames from 'classnames'
import React from 'react'

import Text from '@/components/elements/atoms/Text/Text'
import type { Props as TextProps } from '@/components/elements/atoms/Text/Text'

import STYLES from './SpeechBubble.module.sass'

import SpeechBubbleImageDesktop from '../../../../../assets/images/illustrations/speech-bubbles/medium-speech-bubble--foot-left--desktop.svg'
import SpeechBubbleImageMobile from '../../../../../assets/images/illustrations/speech-bubbles/medium-speech-bubble--foot-left.svg'
import SpeechBubbleImageDesktopSquare from '../../../../../assets/images/illustrations/speech-bubbles/square-speech-bubble--foot-left--desktop.svg'
import SpeechBubbleImageDesktopSquareRed from '../../../../../assets/images/illustrations/speech-bubbles/square-speech-bubble--foot-left--red--desktop.svg'
import SpeechBubbleImageDesktopSquareNoFoot from '../../../../../assets/images/illustrations/speech-bubbles/square-speech-bubble--no-foot--desktop.svg'

type FootPosition =
  | 'bottomLeft'
  | 'bottomRight'
  | 'topRight'
  | 'topLeft'
  | 'none'

type BubblePosition = 'left' | 'center' | 'right'

type BubbleGraphic = 'default' | 'square'

type BubbleColor = 'yellow' | 'red'

type Props = {
  text: TextProps
  footPosition: FootPosition
  bubblePosition?: BubblePosition
  variant?: BubbleGraphic
  color?: BubbleColor
}

const SpeechBubble = ({
  text,
  footPosition,
  bubblePosition,
  variant,
  color = 'yellow'
}: Props): JSX.Element => {
  const bubbleGraphicVariant: { desktop: string; mobile: string } = ((
    variant?: BubbleGraphic
  ) => {
    const paths: { desktop: string; mobile: string } = {
      desktop: SpeechBubbleImageDesktop,
      mobile: SpeechBubbleImageMobile
    }

    switch (variant) {
      case 'square':
        if (footPosition === 'none') {
          paths.desktop = SpeechBubbleImageDesktopSquareNoFoot
          paths.mobile = SpeechBubbleImageDesktopSquareNoFoot
        } else {
          if (color === 'red') {
            paths.desktop = SpeechBubbleImageDesktopSquareRed
            paths.mobile = SpeechBubbleImageDesktopSquareRed
          } else {
            paths.desktop = SpeechBubbleImageDesktopSquare
            paths.mobile = SpeechBubbleImageDesktopSquare
          }
        }
        return paths
      default:
        return paths
    }
  })(variant)

  const className = classnames(STYLES.speechBubble, {
    ...(bubblePosition && { [STYLES[bubblePosition]]: bubblePosition })
  })

  const quoteClassName = classnames(
    STYLES.quote,
    variant && [STYLES[variant]],
    {
      [STYLES[footPosition]]: footPosition
    }
  )

  return (
    <div className={className}>
      <div className={quoteClassName}>
        <img
          loading="lazy"
          className={STYLES.desktop}
          src={bubbleGraphicVariant.desktop}
          alt=""
        />
        <img
          className={STYLES.mobile}
          src={bubbleGraphicVariant.mobile}
          alt=""
        />
        <Text
          text={text.text}
          variables={text.variables}
          namespace={text.namespace}
          margin={text.margin}
          variant={text.variant}
          shouldScale={text.shouldScale}
          colour={color === 'red' ? 'brandWhite' : 'brandBlue500'}
          element={text.element}
          translate={text.translate}
        />
      </div>
    </div>
  )
}

export { Props }
export default SpeechBubble
