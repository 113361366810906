/* eslint-disable i18next/no-literal-string */
// @noflow
import React from 'react'

const IdeasTab = (): React.ReactElement => (
  <div className="ideas-tab">
    <div className="tab-title">
      <h2>{`Ideas`}</h2>
    </div>
    <div className="ideas-tab__content">
      <p className="ideas-tab__content__prompt">
        {`Got an idea for something to add to PAW?`}
      </p>
      <a
        href="https://www.notion.so/butternutbox/PAW-Product-Automation-Widget-f7e369fc61414c508269e5309cae592c#fdbd3e9636d34e9ea719187582d56de0"
        className="ideas-tab__content__link button"
      >
        {/* eslint-disable-next-line i18next/no-literal-string */}
        {`Let the tech team know`}
      </a>
      <p className="ideas-tab__content__signature">{`xoxo`}</p>
    </div>
  </div>
)

export default IdeasTab
