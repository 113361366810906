// @flow

type WizardSex =
  | 'Male'
  | 'Female'

type WizardActivityLevel =
  | 'Lazy'
  | 'Leisurely'
  | 'FairlyActive'
  | 'BundleOfEnergy'
  | 'HyperActive'

type WizardCurrentlyEating =
  | 'Dry'
  | 'Raw'
  | 'Wet'
  | 'HomeCooked'

type WizardBodyCondition =
  | 'Skinny'
  | 'JustRight'
  | 'Chubby'

type WizardFussiness =
  | 'VeryFussy'
  | 'CanBeFussy'
  | 'GoodEater'
  | 'EatsAnything'

type WizardSnackingHabits =
  | 'NoSnacks'
  | 'SomeSnacks'
  | 'LotsOfSnacks'

type Gender =
  | 'male'
  | 'female'

type Exercise =
  | 'low'
  | 'normal'
  | 'hyper'

type CurrentFood =
  | 'dry_food'
  | 'wet_food'
  | 'raw_food'
  | 'home_food'

type BodyShape =
  | 'skinny'
  | 'just_right'
  | 'chubby'

type EaterType =
  | 'fussy_eater'
  | 'picky_eater'
  | 'good_eater'
  | 'eats_anything'

type SnackingHabits =
  | 'unknown_snacking_habits'
  | 'eats_no_snacks'
  | 'eats_some_snacks'
  | 'eats_lots_of_snacks'

type AgeClassificationByUserWhenCreated =
  | 'unclassified_when_created'
  | 'classified_as_adult_by_user_when_created'
  | 'classified_as_puppy_by_user_when_created'
  | 'classified_as_senior_by_user_when_created'

type Dog = {|
  activityLevel: WizardActivityLevel,
  age: {|
    months: number,
    weeks?: number,
    years?: number
  |},
  allergies: Array<string>,
  bodyCondition: WizardBodyCondition,
  breed: {|
    id: number,
    name: string
  |},
  broughtHome: boolean,
  currentlyEating: Array<WizardCurrentlyEating>,
  deSexed: boolean,
  fussiness: WizardFussiness,
  healthIssues: {|
    contents: Array<string>,
    tag: string
  |},
  isRescue: boolean,
  isWorkingDog: boolean,
  mass: number,
  name: string,
  sex: WizardSex,
  snackingHabits: WizardSnackingHabits
|}

// Below are functions which map backend ruby strings to strings used in the wizard
const genderToWizardSex = (gender: Gender): WizardSex => {
  switch (gender) {
    case 'male': {
      return 'Male'
    }
    case 'female': {
      return 'Female'
    }
    default: {
      (gender: empty) // eslint-disable-line no-unused-expressions
      throw new Error(`genderToWizardSex failed for ${gender}`)
    }
  }
}

// There is a mismatch between Activity and WizardActivity level here.
// Below are the agreed appropriate mappings
const exerciseToWizardActivityLevel = (exercise: Exercise): WizardActivityLevel => {
  switch (exercise) {
    case 'low': {
      return 'Lazy'
    }
    case 'normal': {
      return 'FairlyActive'
    }
    case 'hyper': {
      return 'BundleOfEnergy'
    }
    default: {
      (exercise: empty) // eslint-disable-line no-unused-expressions
      throw new Error(`exerciseToWizardActivityLevel failed for ${exercise}`)
    }
  }
}

const currentFoodToWizardCurrentlyEating = (currentFood: CurrentFood): WizardCurrentlyEating => {
  switch (currentFood) {
    case 'dry_food': {
      return 'Dry'
    }
    case 'wet_food': {
      return 'Wet'
    }
    case 'raw_food': {
      return 'Raw'
    }
    case 'home_food': {
      return 'HomeCooked'
    }
    default: {
      (currentFood: empty) // eslint-disable-line no-unused-expressions
      throw new Error(`currentFoodToWizardCurrentlyEating failed for ${currentFood}`)
    }
  }
}

const bodyShapeToWizardBodyCondition = (bodyShape: BodyShape): WizardBodyCondition => {
  switch (bodyShape) {
    case 'skinny': {
      return 'Skinny'
    }
    case 'just_right': {
      return 'JustRight'
    }
    case 'chubby': {
      return 'Chubby'
    }
    default: {
      (bodyShape: empty) // eslint-disable-line no-unused-expressions
      throw new Error(`bodyShapeToWizardBodyCondition failed for ${bodyShape}`)
    }
  }
}

const eaterTypeToWizardFussiness = (eaterType: EaterType): WizardFussiness => {
  switch (eaterType) {
    case 'fussy_eater': {
      return 'VeryFussy'
    }
    case 'picky_eater': {
      return 'CanBeFussy'
    }
    case 'good_eater': {
      return 'GoodEater'
    }
    case 'eats_anything': {
      return 'EatsAnything'
    }
    default: {
      (eaterType: empty) // eslint-disable-line no-unused-expressions
      throw new Error(`eaterTypeToWizardFussiness failed for ${eaterType}`)
    }
  }
}

const snackingHabitsToWizardSnackingHabits = (snackingHabits: SnackingHabits): WizardSnackingHabits => {
  switch (snackingHabits) {
    // unknown_snacking_habits has no Wizard counterpart, just assign default
    case 'unknown_snacking_habits': {
      return 'SomeSnacks'
    }
    case 'eats_no_snacks': {
      return 'NoSnacks'
    }
    case 'eats_some_snacks': {
      return 'SomeSnacks'
    }
    case 'eats_lots_of_snacks': {
      return 'LotsOfSnacks'
    }
    default: {
      (snackingHabits: empty) // eslint-disable-line no-unused-expressions
      throw new Error(`snackingHabitsToWizardSnackingHabits failed for ${snackingHabits}`)
    }
  }
}

export type {
  Gender,
  Exercise,
  CurrentFood,
  BodyShape,
  EaterType,
  SnackingHabits,
  AgeClassificationByUserWhenCreated,
  Dog
}

export {
  genderToWizardSex,
  exerciseToWizardActivityLevel,
  currentFoodToWizardCurrentlyEating,
  bodyShapeToWizardBodyCondition,
  eaterTypeToWizardFussiness,
  snackingHabitsToWizardSnackingHabits
}
