// @noflow
import i18next from 'i18next'

import setRecommendationCompletion from '@/utils/onboardingQuiz/setRecommendationCompletion'

import segmentTrack from '@/components/analytics/Analytics'

type PartialLocation =
  | 'Dashboard'
  | 'Post Signup Wizard'
  | 'Thank You'
  | 'Raf Campaign'

type ReferralShareMethod =
  | 'WhatsApp'
  | 'Email'
  | 'Facebook'
  | 'Email'
  | 'Copy Link'

const referralLinkEvent = (
  method: ReferralShareMethod,
  location: PartialLocation
): void => {
  const event = 'Referral Link Copy Share Event'
  const properties = { method, location }

  segmentTrack(event, properties)
}

const copyReferralLinkButtonWasClicked = ({
  e,
  referralLink,
  partialLocation,
  copyReferralLinkCallback
}: {
  e:
    | React.MouseEvent<HTMLButtonElement>
    | React.KeyboardEvent<HTMLButtonElement>
  referralLink: string
  partialLocation: PartialLocation
  copyReferralLinkCallback: (alertMessage: string) => void
}): void => {
  e.currentTarget.blur()
  const elem = document.getElementsByClassName(
    `input-${referralLink}`
  )[0] as HTMLInputElement | null

  if (!elem) {
    // eslint-disable-next-line i18next/no-literal-string
    copyReferralLinkCallback(`Oops! Couldn't copy`)
    return
  }

  elem.select()
  const range = document.createRange()
  range.selectNodeContents(elem)
  const selection = window.getSelection()

  if (selection) {
    selection.removeAllRanges()
    selection.addRange(range)
  }

  elem.setSelectionRange(0, 999999)

  const success = document.execCommand('copy')

  if (success) {
    copyReferralLinkCallback(
      i18next.t('dashboard:referrals.link_and_share.copied_tooltip')
    )
    setRecommendationCompletion('Raf')
  } else {
    // eslint-disable-next-line i18next/no-literal-string
    copyReferralLinkCallback(`Oops! Couldn't copy`)
  }

  referralLinkEvent('Copy Link', partialLocation)
}

const shareOnFacebookButtonWasClicked = (
  partialLocation: PartialLocation
): void => {
  referralLinkEvent('Facebook', partialLocation)
}

const shareOnWhatsAppButtonWasClicked = (
  partialLocation: PartialLocation
): void => {
  referralLinkEvent('WhatsApp', partialLocation)
}

const shareByMailButtonWasClicked = (
  partialLocation: PartialLocation
): void => {
  referralLinkEvent('Email', partialLocation)
}

const sendEmailButtonWasClicked = (partialLocation: PartialLocation): void => {
  referralLinkEvent('Email', partialLocation)
}

export type { PartialLocation }

export {
  copyReferralLinkButtonWasClicked,
  shareOnFacebookButtonWasClicked,
  shareOnWhatsAppButtonWasClicked,
  shareByMailButtonWasClicked,
  sendEmailButtonWasClicked,
  referralLinkEvent
}
