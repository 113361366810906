// @noflow
import lowerCase from 'lodash/lowerCase'
import startCase from 'lodash/startCase'

import { ensureNever } from '@/typescript/utils'

import type { TrackableAction } from '../actions'
import * as MESSAGES from '../actions/messages'
import type { State } from '../reducers'

/**
 * Ideally, this would be an Array<$PropertyType<TrackableAction, 'type'>>
 * However, the redux-action-analytics-middleware generics do not extend to the
 * trackableActions. This is documented in the package and may be dealt with
 * in a future update:
 *
 * github.com/TomasBarry/redux-action-analytics-middleware/blob/master/src/index.js#L15
 * flow.org/en/docs/types/utilities/#toc-propertytype
 */
const trackableActions = [MESSAGES.UPDATE_FIELD]

const trackCallback = (
  action: TrackableAction,
  preActionState: State,
  postActionState: State
): void => {
  /**
   * Handle the scenario where window.analytics.track is not present (in the
   * case of a customer having an ad-blocker etc)
   */
  if (!window.analytics || !window.analytics.track) {
    return
  }

  switch (action.field) {
    case 'newDogName':
    case 'name':
    case 'email':
    case 'postcode':
    case 'dogNames':
    case 'message':
    case 'dogGender':
    case 'isNeuteredOrSpayed':
    case 'dogAgeInput':
    case 'dogBreedInput':
    case 'dogFoodsInput':
    case 'dogEatingHabit':
    case 'dogBodyShape':
    case 'dogWeight':
    case 'dogActivityLevel':
    case 'dogAllergensInput':
    case 'dogHealthIssuesInput':
    case 'dogSnackingHabit':
    case 'recaptchaString': {
      // eslint-disable-next-line i18next/no-literal-string
      const eventName = `Contact Us Page ${startCase(
        lowerCase(action.field)
      )} Updated`
      const interaction = action.input.interaction

      switch (interaction) {
        case 'InteractedWithError': {
          window.analytics.track(eventName, { status: 'error' })
          break
        }
        case 'InteractedWithSuccess': {
          window.analytics.track(eventName, { status: 'success' })
          break
        }
      }
      break
    }
    case 'queryType': {
      const eventName = 'Contact Us Page Query Type Updated'

      const from = preActionState.formData.queryType.value
      const to = postActionState.formData.queryType.value

      // Ignore form resets and selecting the same value
      if (to === 'How can we help?' || to === from) {
        return
      }

      const properties = { from, to }
      window.analytics.track(eventName, properties)
      break
    }
    default: {
      ensureNever(action.field)
      throw new Error(`${action.field} is not a valid field name.`)
    }
  }
}

export { trackCallback, trackableActions }
