import { gql } from '@apollo/client'

const MARKETING_PREFERENCE_FRAGMENT = gql`
  fragment marketingPreferenceFragment on User {
    id
    selectedMarketingMethodPurposes {
      id
      supportedMarketingMethodPurpose {
        marketingMethod {
          id
          name
        }
        marketingPurpose {
          id
          name
          description
        }
      }
    }
    availableMarketingMethodPurposes {
      id
      preselect
      shippingCountry {
        id
      }
      marketingMethod {
        id
        name
      }
      marketingPurpose {
        id
        name
        description
      }
    }
  }
`

export { MARKETING_PREFERENCE_FRAGMENT }
