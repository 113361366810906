// @noflow
import { stubCSRFToken } from '@/shared_types/csrfToken'
import type { CSRFToken } from '@/shared_types/csrfToken'
import type { RailsModelID } from '@/shared_types/ids'
import type { Token } from '@/shared_types/tokens'
import { ensureNever } from '@/typescript/utils'

import type { GlobalDataAction } from '../actions'
import * as MESSAGES from '../actions/messages'

type Allergen = {
  name: string
}

type GlobalData = {
  allergens: Array<Allergen>
}

type State = {
  token: Token | null // if the user is not a client
  userId: RailsModelID | null // if the user is of the type Users::PreWizardGuest
  csrfToken: CSRFToken
  recaptchaSiteKey: string
} & GlobalData

const initialState: State = {
  token: null,
  userId: null,
  csrfToken: stubCSRFToken,
  recaptchaSiteKey: '',
  allergens: []
}

const reducer = (
  state: State = initialState,
  action: GlobalDataAction
): State => {
  switch (action.type) {
    case MESSAGES.GLOBAL_DATA_RECEIVED: {
      return {
        ...state,
        allergens: [...state.allergens, ...action.data.allergens]
      }
    }
    default: {
      ensureNever(action.type)
      return state
    }
  }
}

export type { State, Allergen, GlobalData }

export { initialState }

export default reducer
