import { gql } from '@apollo/client'

import { PAYMENT_METHODS_FRAGMENT } from '../fragments/fragments'

const SET_PAYMENT_METHOD_TO_ACTIVE = gql`
  ${PAYMENT_METHODS_FRAGMENT}
  mutation PaymentMethodSetActive(
    $paymentMethodId: ID!
    $archivedType: ArchivedType!
  ) {
    response: paymentMethodSetActive(paymentMethodId: $paymentMethodId) {
      subscription {
        ...paymentMethodsFragment
      }
    }
  }
`

export { SET_PAYMENT_METHOD_TO_ACTIVE }
