import { gql } from '@apollo/client'

const ALLERGENS_QUERY = gql`
  query Allergens {
    allergens {
      id
      name
    }
  }
`

export { ALLERGENS_QUERY }
