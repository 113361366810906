// @noflow
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import bancontactLogo from 'assets/images/logos/bancontact-logo.svg'

import STYLES from './PaymentComponents.module.sass'

import { State } from '../../PayOnOwnDevicePage'
import useBuySubscriptionWithBancontact from '../../hooks/stripe/sepa_direct_debit/useBuySubscriptionWithBancontact'
import {
  ActivePaymentViews,
  LoginUrlProps,
  setSubmissionState
} from '../../screens/PaymentScreen/PaymentScreen'

type Props = {
  disableBancontactButton: boolean
  paymentPageState: State
  setActivePaymentView: (view: ActivePaymentViews) => void
  navigateToNextStep: (input: LoginUrlProps) => void
  namespace: string
}

const Bancontact = ({
  disableBancontactButton,
  paymentPageState,
  setActivePaymentView,
  navigateToNextStep,
  namespace
}: Props): JSX.Element => {
  const { t } = useTranslation(namespace)
  const copyContext = 'plan_steps.payment.payment_options'

  const { bancontactSubscriptionCallback, bancontactAuthRecieved } =
    useBuySubscriptionWithBancontact({
      paymentPageState,
      setActivePaymentView,
      navigateToNextStep
    })

  useEffect(() => {
    if (bancontactAuthRecieved) {
      setSubmissionState({ type: 'loading' })
    }
  }, [bancontactAuthRecieved])

  const handleBancontactOnClick = useCallback(() => {
    setActivePaymentView('bancontact')
    bancontactSubscriptionCallback()
  }, [setActivePaymentView, bancontactSubscriptionCallback])

  return (
    <button
      className={`${STYLES.button} ${STYLES.bancontactButton}`}
      type="button"
      onClick={handleBancontactOnClick}
      disabled={disableBancontactButton}
    >
      <img
        style={{ height: '3rem' }}
        src={bancontactLogo}
        alt={t(`${copyContext}.bancontact_alt`)}
      />
    </button>
  )
}

export default Bancontact
