// @flow

import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import * as ACTIONS from '../actions'
import * as THUNKS from '../thunks'
import PostSignUpRoutes from '../postSignUpRoutes'
import Header from './../../shared/wizards/Header'
import DogNameDivider from './base_components/DogNameDivider'
import SelectablePillButton from '@/components/shared/elements/PillButton/SelectablePillButton'
import { activities } from '../../../shared_types/rails_models/dog_activity'
import { activitiesSelected } from '../reducers/dogProfilesReducer'
import Chevron from '../../shared/images/Chevron'
import BRAND_COLOURS from '../../../constants/BrandColours'
import { useTranslation } from 'react-i18next'

import type { Activity } from '../../../shared_types/rails_models/dog_activity'
import type { RailsModelID as ID } from '../../../shared_types/ids'
import type { Dispatch } from 'redux'
import type { State } from '../reducers'
import type { State as DogProfileState } from '../reducers/dogProfilesReducer'
import type { Dog } from '../message_types'

type PresentationalProps = {|
  dogs: Array<Dog>,
  dogProfiles: DogProfileState,
  areActivitiesSelected: boolean
|}

type ActionProps = {|
  activitiesUpdate: () => void,
  toggleActivity: (ID, Activity) => void
|}

type Props =
  & PresentationalProps
  & ActionProps

const mapStateToProps = ({
  globalAttributes,
  dogProfiles
}: State): PresentationalProps => {
  const { dogs } = globalAttributes
  const areActivitiesSelected = activitiesSelected(dogProfiles)

  return {
    dogs,
    dogProfiles,
    areActivitiesSelected
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => {
  const toggleActivity = (id: ID, activity: Activity): void => {
    dispatch(ACTIONS.toggleActivity(id, activity))
  }
  const activitiesUpdate = (): void => {
    dispatch(ACTIONS.nextWizardStep())
    dispatch(THUNKS.activitiesUpdate())
  }

  return { activitiesUpdate, toggleActivity }
}

const FavouriteActivities = ({
  activitiesUpdate,
  dogs,
  toggleActivity,
  dogProfiles,
  areActivitiesSelected
}: Props): React.Node => {
  const { t } = useTranslation('post_signup_wizard')
  const copyContext = 'favourite_activities'
  const toggleActivityMethods = dogs.reduce((acc: ({ [ID]: { [Activity]: () => void} }), dog: Dog): ({ [ID]: { [Activity]: () => void} }) => {
    acc[dog.id] = activities.reduce((activitiesAcc: ({ [Activity]: () => void }), activity: Activity): ({ [Activity]: () => void }) => {
      activitiesAcc[activity] = (): void => toggleActivity(dog.id, activity)

      return activitiesAcc
    }, {})
    return acc
  }, {})
  return (
    <React.Fragment>
      <Header headerSubtitle={ t(`${copyContext}.subtitle`) }>
        <span
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: t(`${copyContext}.title`)
          }}
        />
      </Header>
      <div className='step-body'>
        <div className='step-body__content'>
          {
            dogs.map(({ name, id }: Dog): React.Node => (
              <React.Fragment key={name}>
                <DogNameDivider dividerText={ t(`${copyContext}.separator_text`, { dogName: name }) } />
                <div className='selectable-pill-wrapper'>
                  {
                    activities.map((activity: Activity): React.Node => (
                      <SelectablePillButton
                        action={toggleActivityMethods[id][activity]}
                        buttonText={ t(`${copyContext}.activities.${activity}`) }
                        key={activity}
                        selected={dogProfiles[id].activities[activity]}
                      />
                    ))
                  }
                </div>
              </React.Fragment>
            ))
          }
          <Link
            className={`next-button next-button${areActivitiesSelected ? '' : '--disabled'}`}
            onClick={activitiesUpdate}
            to={PostSignUpRoutes.Quirks}
          >
            { t(`${copyContext}.button_text_next`) }
            <Chevron
              color={BRAND_COLOURS.brandWhite}
              rotation={0}
            />
          </Link>
        </div>
      </div>
    </React.Fragment>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FavouriteActivities)
