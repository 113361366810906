/* eslint-disable i18next/no-literal-string */
const imgPathPrefix = 'Web/photos/ugc'
const GBReviews = [
  {
    path: `${imgPathPrefix}/maggie.and.hank`,
    alt: 'instagram_carousel.gb.slide_1.image_alt',
    instagram_handle: 'maggie.and.hank',
    text: 'starting off my birthday celebrations by trying out the new Yonks Bar from @butternutbox 🤤'
  },
  {
    path: `${imgPathPrefix}/hazelandpoppy90`,
    alt: 'instagram_carousel.gb.slide_2.image_alt',
    instagram_handle: 'hazelandpoppy90',
    text: 'Happy National Pet Day & happy @butternutbox delivery day! It seems fitting that the delivery day is on National Pet Day, and it certainly has improved Poppy’s lifestyle in the last 16 months'
  },
  {
    path: `${imgPathPrefix}/autumnthesprocker`,
    alt: 'instagram_carousel.gb.slide_3.image_alt',
    instagram_handle: 'autumnthesprocker',
    text: 'Happy #tongueouttuesday 👅! Nothing gets me licking my lips like another delivery of my favourite @butternutbox'
  },
  {
    path: `${imgPathPrefix}/cavapoppy_`,
    alt: 'instagram_carousel.gb.slide_4.image_alt',
    instagram_handle: 'cavapoppy_',
    text: 'My face when mummy whips out @butternutbox 🤭🥩🥦🍗🥕💛 I have officially been weened off raw food & began my Butternut Journey this week & I am very happy about it, considering I never leave a crumb'
  },
  {
    path: `${imgPathPrefix}/ralphs_sprocker_adventures`,
    alt: 'instagram_carousel.gb.slide_5.image_alt',
    instagram_handle: 'ralphs_sprocker_adventures',
    text: 'We have three fans of @butternutbox in the house and are all #teamred. They all took to the change from kibble so well and absolutely love meal times now!'
  },
  {
    path: `${imgPathPrefix}/shep_thesheepdog`,
    alt: 'instagram_carousel.gb.slide_6.image_alt',
    instagram_handle: 'shep_thesheepdog',
    text: 'The Butternutters watching @crufts @butternutbox 🏅🐶📺'
  },
  {
    path: `${imgPathPrefix}/what_freddie_saw`,
    alt: 'instagram_carousel.gb.slide_7.image_alt',
    instagram_handle: 'what_freddie_saw',
    text: 'Really enjoying my new Yonks bar from @butternutbox 😋'
  },
  {
    path: `${imgPathPrefix}/meeko_tales`,
    alt: 'instagram_carousel.gb.slide_8.image_alt',
    instagram_handle: 'meeko_tales',
    text: 'Hey pups, dr. Meeko is here<br />I’ve just discovered a magic powder from @butternutbox that helps with upset tummy. It contains pre, pro and postbiotics that help support good bacteria in the gut and mineral rich clay that flushes out toxins.'
  }
]

export { GBReviews }
