import { useReactiveVar } from '@apollo/client'
import React, { useEffect, useMemo, useState } from 'react'
import { Navigate } from 'react-router'

import DogCelebrating from 'assets/images/illustrations/dogs/dog-celebrating.svg'

import Text from '@/components/elements/atoms/Text/Text'

import STYLES from '../ResolutionConfirmation.module.sass'

import { customerIssueManagementState } from '../../../CustomerIssueManagementPage'
import useCustomerIssueManagementTracking from '../../../analytics'
import CustomerIssueManagementRoutes from '../../../types/routes'
import ResolutionConfirmationBox from './ResolutionConfirmationBox'

type Props = {
  isoDeliveryDate: string
  dogNames: string
}

const Replacement = ({ isoDeliveryDate, dogNames }: Props): JSX.Element => {
  const customerIssueManagementData = useReactiveVar(
    customerIssueManagementState
  )
  const customerIssueManagementTracking = useCustomerIssueManagementTracking()
  const [initialised, setInitialised] = useState(false)

  useEffect(() => {
    if (!initialised) {
      customerIssueManagementState({
        ...customerIssueManagementData,
        faqType: 'replaceBox'
      })
      customerIssueManagementTracking.stepLoaded(
        'Replacement box confirmation',
        'Resolution confirmation'
      )
      setInitialised(true)
    }
  }, [
    initialised,
    customerIssueManagementData,
    customerIssueManagementTracking
  ])

  const deliveryDate = useMemo(() => {
    return new Date(isoDeliveryDate)
  }, [isoDeliveryDate])

  if (!isoDeliveryDate) {
    return <Navigate to={CustomerIssueManagementRoutes.ContactCustomerLove} />
  }

  return (
    <>
      <div className={STYLES.imgContainer}>
        <img alt="" src={DogCelebrating} />
      </div>
      <Text
        namespace={customerIssueManagementData.namespace}
        text={'resolutionConfirmation.replacementBox.next_box_text'}
        variant={'textRegular16'}
        colour={'brandBlue500'}
        variables={{
          dogNames
        }}
        margin={false}
      />
      {!!deliveryDate && (
        <ResolutionConfirmationBox
          asset={'van'}
          assetSize={20}
          textProps={{
            namespace: customerIssueManagementData.namespace,
            text: 'resolutionConfirmation.replacementBox.deliveryDate',
            variables: { date: deliveryDate },
            colour: 'brandBlue500',
            translate: true,
            margin: false
          }}
        />
      )}
      <Text
        namespace={customerIssueManagementData.namespace}
        text={'resolutionConfirmation.replacementBox.text1'}
        variant={'textRegular16'}
        colour={'brandBlue500'}
        margin={false}
      />
      <Text
        namespace={customerIssueManagementData.namespace}
        text={'resolutionConfirmation.replacementBox.text2'}
        variant={'textRegular16'}
        colour={'brandBlue500'}
        variables={{
          dogNames
        }}
        margin={false}
      />
    </>
  )
}

export { Props }
export default Replacement
