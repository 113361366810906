import { gql } from '@apollo/client'

import { MARKETING_PREFERENCE_FRAGMENT } from '@/components/elements/organisms/MarketingPreferences/fragments/marketingPreferenceFragment'

const UPDATE_MARKETING_PREFERENCES = gql`
  ${MARKETING_PREFERENCE_FRAGMENT}
  mutation MarketingMethodPurposeUpdate(
    $userId: ID
    $authenticationStrategy: AuthenticationStrategy!
    $trackingId: String
    $token: String
    $attribute: String
    $shaType: String
    $encryptionKey: String
    $purposeIds: [ID!]!
  ) {
    marketingMethodPurposeUpdate(
      userId: $userId
      authenticationStrategy: $authenticationStrategy
      trackingId: $trackingId
      token: $token
      attribute: $attribute
      shaType: $shaType
      encryptionKey: $encryptionKey
      purposeIds: $purposeIds
    ) {
      ...marketingPreferenceFragment
    }
  }
`

export { UPDATE_MARKETING_PREFERENCES }
