// @flow

import * as React from 'react'
import { connect } from 'react-redux'
import * as ACTIONS from '../../actions'

import DashboardWizardNav from '@/components/shared/blocks/DashboardWizardNav'

import type { Dispatch } from 'redux'
import type { State } from '../../reducers'
import type { State as WizardStep } from '../../reducers/wizardStepReducer'

type PresentationalProps = {|
  currentStep: WizardStep
|}

type ActionProps = {|
  previousWizardStep: () => void
|}

type Props =
  & PresentationalProps
  & ActionProps

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => {
  const previousWizardStep = (): void => {
    dispatch(ACTIONS.previousWizardStep())
  }

  return { previousWizardStep }
}

const mapStateToProps = ({ wizardStep }: State): PresentationalProps => {
  return {
    currentStep: wizardStep
  }
}

const NavigationBar = ({
  previousWizardStep,
  currentStep
}: Props): React.Node => (
  currentStep !== 'FavouriteGames' && (
    <DashboardWizardNav
      backAction='back'
      handleOnClickBack={previousWizardStep}
      showMyAccountButton
    />
  )
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigationBar)
