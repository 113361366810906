// @noflow
import React from 'react'

import { isBoxDelivered } from '@/utils/orderHelper'

import type {
  boxQuery_user_subscription_box as Box,
  boxQuery_user as User
} from '../../__generated__/boxQuery'

import { Recipes } from '../../OrderPage'

type Props = {
  box: Box
  boxId: number
  shippingCountryCode: User['shippingCountryCode']
  preferredLanguage: User['preferredLanguage']
  isNextEditableBox: boolean
}

const RecipeSelection = ({
  box,
  boxId,
  shippingCountryCode,
  preferredLanguage,
  isNextEditableBox
}: Props): JSX.Element => {
  const { totalGrams } = box

  // Show list of recipes
  return (
    <Recipes
      pouchSize={totalGrams}
      shippingCountryCode={shippingCountryCode}
      preferredLanguage={preferredLanguage}
      isNextEditableBox={isNextEditableBox}
      isBoxDelivered={isBoxDelivered(box as Box)}
      namespace="order"
      boxId={boxId}
    />
  )
}

export default RecipeSelection
