// @noflow
import { useReactiveVar } from '@apollo/client'
import React, { useCallback, useEffect, useState } from 'react'
import {
  Navigate,
  Route,
  Routes as RouterRoutes,
  useLocation,
  useNavigate
} from 'react-router-dom'

import useWebviewHanlder from '@/hooks/useWebviewHandler/useWebviewHandler'

import SelfResolutionHeader from './components/SelfResolutionHeader/SelfResolutionHeader'

import STYLES from './SelfResolutionPage.module.sass'

import { CustomerIssueReportEntriesSubmissionVariables } from './mutations/__generated__/CustomerIssueReportEntriesSubmission'
import { CustomerIssueResolutionAcceptanceReplacementBoxSubmissionVariables } from './mutations/__generated__/CustomerIssueResolutionAcceptanceReplacementBoxSubmission'
import { SelfResolutionCategories_user_subscription_box_potentialMissingItems as PotentialMissingItems } from './queries/__generated__/SelfResolutionCategories'

import { selfResolutionPageState } from './SelfResolutionPage'
import useSelfResolutionTracking from './analytics'
import getRouteWithBoxId from './helpers/getRouteWithBoxId'
// Screens
import Categories from './screens/Categories/Categories'
import ContactCustomerLove from './screens/ContactCustomerLove/ContactCustomerLove'
import Error from './screens/Error/Error'
import QuickHelpResolution from './screens/QuickHelpResolution/QuickHelpResolution'
import ReportIssue from './screens/ReportIssue/ReportIssue'
import ResolutionConfirmation from './screens/ResolutionConfirmation/ResolutionConfirmation'
import ResolutionOffering from './screens/ResolutionOffering/ResolutionOffering'
import Routes from './types/routes'
import type { Route as SelfResolutionRoute } from './types/routes'

type Props = {
  namespace: string
  route: SelfResolutionRoute | null
  boxId: string | null
  initialHistoryLength: number
  setRoute: (route: null) => void
  categorySubmission: (categoryId: string, categoryName: string) => void
  reportIssue: (
    variables: CustomerIssueReportEntriesSubmissionVariables,
    affectedItems?: Array<PotentialMissingItems>
  ) => void
  orderReplacementBox: (
    variables: CustomerIssueResolutionAcceptanceReplacementBoxSubmissionVariables
  ) => void
  applyDiscount: () => void
}

const SelfResolution = ({
  namespace,
  route,
  boxId,
  initialHistoryLength,
  setRoute,
  categorySubmission,
  reportIssue,
  orderReplacementBox,
  applyDiscount
}: Props): JSX.Element => {
  const selfResolutionState = useReactiveVar(selfResolutionPageState)
  const location = useLocation().pathname as SelfResolutionRoute
  const navigate = useNavigate()
  const [returnPath, setReturnPath] = useState('../dashboard')
  const selfResolutionTracking = useSelfResolutionTracking()
  const webviewHandler = useWebviewHanlder()

  useEffect(() => {
    window.scrollTo(0, 0)
    if (!document.body.classList.contains('hide-dixa')) {
      document.body.classList.add('hide-dixa')
    }
  }, [location])

  useEffect(() => {
    if (route) {
      navigate(getRouteWithBoxId(route))
    }
  }, [route, navigate])

  const hostName = window.location.origin

  useEffect(() => {
    if (
      document.referrer.indexOf(hostName) === 0 &&
      document.referrer.indexOf('/self-resolution/') === -1
    ) {
      const url = new URL(document.referrer)
      setReturnPath(url.pathname)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goBack = useCallback(() => {
    if (
      webviewHandler.isWebview &&
      window.history.length === initialHistoryLength
    ) {
      webviewHandler.postMessage('GO-BACK')
    } else {
      navigate(-1)
      setRoute(null)
    }
  }, [navigate, setRoute, webviewHandler, initialHistoryLength])

  const close = useCallback(() => {
    selfResolutionTracking.dropOff(location, 'Close')
    if (webviewHandler.isWebview) {
      webviewHandler.postMessage('GO-BACK')
    } else {
      window.location.href = returnPath
    }
  }, [returnPath, selfResolutionTracking, location, webviewHandler])

  if (!boxId) {
    return <>{close()}</>
  }

  return (
    <>
      <SelfResolutionHeader
        namespace={namespace}
        goBack={goBack}
        close={close}
      />
      <RouterRoutes>
        <Route
          path={Routes.Default}
          element={<Navigate to={getRouteWithBoxId(Routes.Categories)} />}
        />
        <Route
          path={Routes.Categories}
          element={
            <div className={STYLES.cardContent}>
              <Categories
                boxId={boxId}
                categorySubmission={categorySubmission}
                returnPath={returnPath}
              />
            </div>
          }
        />
        <Route
          path={Routes.QuickHelpResolution}
          element={
            <div className={STYLES.cardContent}>
              <QuickHelpResolution
                namespace={namespace}
                returnPath={returnPath}
              />
            </div>
          }
        />
        <Route
          path={Routes.ReportIssue}
          element={
            <div className={STYLES.cardContent}>
              <ReportIssue
                namespace={namespace}
                reportIssue={reportIssue}
                returnPath={returnPath}
              />
            </div>
          }
        />
        <Route
          path={Routes.ResolutionOffering}
          element={
            <div className={STYLES.cardContent}>
              <ResolutionOffering
                namespace={namespace}
                orderReplacementBox={orderReplacementBox}
                applyDiscount={applyDiscount}
              />
            </div>
          }
        />
        <Route
          path={Routes.ResolutionConfirmation}
          element={
            <div>
              <ResolutionConfirmation namespace={namespace} />
            </div>
          }
        />
        <Route
          path={Routes.ContactCustomerLove}
          element={
            <div className={STYLES.cardContent}>
              <ContactCustomerLove namespace={namespace} />
            </div>
          }
        />
        <Route
          path={Routes.Error}
          element={
            <Error
              namespace={namespace}
              errorType={selfResolutionState.errorType}
            />
          }
        />
      </RouterRoutes>
    </>
  )
}

export { Props }
export default SelfResolution
