// @noflow

/* eslint-disable react/jsx-no-bind */
import { useOccasion } from '@/context/festiveTheme/festiveTheme'
import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import useWindowSize from '@/hooks/useWindowSize'

import { cloudinaryPath } from '@/components/elements/atoms/CloudinaryImage/CloudinaryImage'
// Component
import Text, { Props as TextProps } from '@/components/elements/atoms/Text/Text'
import { BREAKPOINTS } from '@/components/pages/App/App'

import STYLES from './CategoryBar.module.sass'

import SkeletonImage from '../../atoms/SkeletonImage/SkeletonImage'

type CategoryText = TextProps & {
  text: string
}

type Category = {
  icon: string
  text: CategoryText
  value: string
}

type Align = 'left' | 'right' | 'center'

type Loading = {
  isLoading: boolean
  skeletonCount: number
}

type Props = {
  setCategory?: (value: string) => void
  categorySelected?: string
  align?: Align
  categories: Array<Category>
  loading?: Loading
}

const CategoryBar = ({
  setCategory,
  categories,
  categorySelected,
  align = 'center',
  loading = { isLoading: false, skeletonCount: 0 }
}: Props): JSX.Element => {
  const { windowWidth } = useWindowSize()
  const [selectedCategory, setSelectedCategory] = useState('')
  const isDesktop = useMemo(() => windowWidth >= BREAKPOINTS.md, [windowWidth])
  const { xmas } = useOccasion()

  useEffect(() => {
    if (categorySelected) {
      setSelectedCategory(categorySelected)
    }
  }, [categorySelected])

  const handleClick = useCallback(
    (value: string) => {
      if (setCategory) {
        setCategory(value)
      }
      setSelectedCategory(value)
    },
    [setCategory]
  )
  return (
    <div className={STYLES.container}>
      <div className={`${STYLES.wrapper} ${STYLES[align]}`}>
        {loading.isLoading ? (
          <>
            {Array.from(Array(loading.skeletonCount).keys()).map(
              (i: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <SkeletonImage
                  key={i}
                  width={isDesktop ? 100 : 70}
                  height={isDesktop ? 44 : 70}
                />
              )
            )}
          </>
        ) : (
          categories.map((category: Category): JSX.Element => {
            const className = classNames(STYLES.categoryWrapper, {
              [STYLES.selected]: selectedCategory === category.value,
              [STYLES.xmas]: xmas
            })

            return (
              <div
                key={category.text.text}
                className={className}
                role="button"
                tabIndex={0}
                onClick={() => handleClick(category.value)}
                onKeyDown={() => handleClick(category.value)}
              >
                {category.icon.includes('star') ? (
                  <img alt="" src={category.icon} />
                ) : (
                  <img
                    alt=""
                    src={cloudinaryPath({
                      path: category.icon,
                      transformations: { format: 'svg' }
                    })}
                  />
                )}
                <Text
                  namespace={category.text?.namespace}
                  translate={!!category.text?.namespace}
                  text={category.text.text}
                  variant={category.text?.variant || 'textRegular14'}
                  colour={category.text?.colour || 'brandBlue500'}
                  margin={false}
                />
              </div>
            )
          })
        )}
      </div>
    </div>
  )
}

export type { Props }
export default CategoryBar
