// @flow

import type { RailsModelID } from '@/shared_types/ids'
import type { CSRFToken } from '@/shared_types/csrfToken'
import type { ServerError, UnknownError } from '@/redux/graphql_queries/errorTypes'
import type { DeliveryDetails, SubscriptionDetails } from '../message_types'

type DecodedRailsResponse = {|
  deliveries_details: Array<DeliveryDetails>,
  number_of_box_issues: number,
  number_of_boxes_delivered: number,
  subscription_details: SubscriptionDetails
|}

type Success = {|
  type: 'Success',
  data: DecodedRailsResponse
|}

type RequestResult =
  | Success
  | ServerError
  | UnknownError

const { fetch } = global

const changePlan = ({
  subscriptionId,
  csrfToken,
  planId
}: {
  subscriptionId: RailsModelID,
  csrfToken: CSRFToken,
  planId: RailsModelID
}): Promise<RequestResult> => {
  const endpoint = '/admin/single_customer_view/change_plan'
  const queryParams = `subscription_id=${subscriptionId}&plan_id=${planId}`
  const method = 'POST'
  const credentials = 'same-origin'
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  }
  return fetch(`${endpoint}?${queryParams}`, { headers, method, credentials })
    .then((res: Response): Promise<RequestResult> => {
      if (!res.ok) {
        return res.text()
          .then((message: string): RequestResult => {
            return {
              type: 'ServerError',
              status: res.status,
              message
            }
          })
      }
      return res.json()
        .then(({ deliveries_details, number_of_box_issues, number_of_boxes_delivered, subscription_details }: DecodedRailsResponse): RequestResult => {
          if (deliveries_details && number_of_box_issues && number_of_boxes_delivered && subscription_details) {
            return {
              type: 'Success',
              data: {
                deliveries_details,
                number_of_box_issues,
                number_of_boxes_delivered,
                subscription_details
              }
            }
          } else {
            return {
              type: 'UnknownError',
              error: 'Unknown Error'
            }
          }
        })
    })
}

export type {
  RequestResult
}

export default changePlan
