import { makeVar, useReactiveVar } from '@apollo/client'
import React, { useEffect } from 'react'

import WhiteCheckmarkBlueBg from 'assets/images/icons/checkmarks/white-checkmark-blue-bg.svg'

import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './ConfirmationModal.module.sass'

const modalData = makeVar({
  isOpen: false,
  namespace: '',
  text: '',
  delay: 3000
})

const ConfirmationModal = (): JSX.Element | null => {
  const modalState = useReactiveVar(modalData)

  useEffect(() => {
    if (modalState.isOpen) {
      setTimeout((): void => {
        modalData({
          ...modalState,
          isOpen: false
        })
      }, modalState.delay)
    }
  }, [modalState])

  if (!modalState.isOpen) return null
  return (
    <div className={STYLES.container}>
      <div className={STYLES.paper}>
        <div className={STYLES.content}>
          <img src={WhiteCheckmarkBlueBg} alt="" />
          <Text
            text={modalState.text}
            namespace={modalState.namespace}
            variant="display20"
            shouldScale={false}
            margin={false}
          />
        </div>
      </div>
    </div>
  )
}

export { modalData }
export default ConfirmationModal
