// @noflow
import { Fragment, createElement } from 'react'

type Props = {
  condition: boolean
  wrapper: (typeof createElement.arguments)[0]
  defaultWrapper?: (typeof createElement.arguments)[0]
  children: JSX.Element | Array<JSX.Element>
  wrapperProps?: (typeof createElement.arguments)[1]
  defaultWrapperProps?: (typeof createElement.arguments)[1]
}

/**
 * ConditionalWrapper
 *
 * Use this component to conditionally render a wrapper for a content.
 *
 * @example
  ```
  <ConditionalWrapper
    condition={condition}
    wrapper={TestWrapper}
    wrapperProps={{ testProp: 'test' }}
    defaultWrapper={DefaultWrapper}
    defaultWrapperProps={{ defaultProp: 'default' }}
  >
    <Text text="Content" translate={false} />
  </ConditionalWrapper>
  ```
 * @param {boolean} condition - Condition to render wrapper
 * @param {Function} wrapper - Wrapper component
 * @param {Object} wrapperProps - Wrapper component props
 * @param {Function} wrapper - Default wrapper component if condition is false (defaults to Fragment)
 * @param {Object} wrapperProps - Default wrapper component props
 * @param {JSX.Element} children - Content to be wrapped
 * @returns {JSX.Element} - Rendered component
 * @category Components
 * @subcategory Atoms
 * @component ConditionalWrapper
 */
const ConditionalWrapper = ({
  condition,
  wrapper,
  wrapperProps,
  defaultWrapper = Fragment,
  defaultWrapperProps,
  children
}: Props): JSX.Element =>
  condition
    ? createElement(wrapper, wrapperProps, [children])
    : createElement(defaultWrapper, defaultWrapperProps, [children])

export { ConditionalWrapper }
