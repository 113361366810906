// @noflow
import initI18n, { Language } from '@/packs/localisation'
import { ACCOUNT_ROUTES } from '@/routes'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import ButternutBoxSprite from 'assets/images/illustrations/loading-screens/butternut-box-and-yellow-heart-illustration--sprite.svg'

import SuccessOverlay from '@/components/elements/organisms/SuccessOverlay/SuccessOverlay'
import { NavigateContext } from '@/components/pages/App'

type Props = {
  language: Language
}

const OrderConfirmed = ({ language }: Props): JSX.Element | null => {
  initI18n(language)

  const namespace = 'account'

  const navigate = useContext(NavigateContext)
  const { t } = useTranslation('simplified_plans_flow')

  useEffect(() => {
    setTimeout(() => {
      navigate(ACCOUNT_ROUTES.base, '/dashboard')
    }, 3600)
  }, [navigate])

  return (
    <SuccessOverlay
      sprite={ButternutBoxSprite}
      imageAlt={t('loading_screen.butternut_box_img_alt')}
      typography={{
        text: 'shared.order_confirmed',
        margin: false,
        namespace
      }}
    />
  )
}

export default OrderConfirmed
