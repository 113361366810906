// @noflow
import initI18n from '@/packs/localisation'
import { useQuery } from '@apollo/client'
import Cookies from 'js-cookie'
import React, { createContext, useEffect, useState } from 'react'

import useLocalStorage from '@/hooks/useLocalStorage'

import withApollo from '@/components/apollo/withApollo'
import TopNavBanner from '@/components/elements/molecules/TopNavBanner/TopNavBanner'
import LoadingScreen from '@/components/elements/organisms/LoadingScreen/LoadingScreen'

import STYLES from './PayOnOwnDevicePage.module.sass'

import { DIRECT_SALES_PAYMENT_QUERY } from './queries/directSalesPaymentQuery'

import { DirectSalesPaymentQuery } from './queries/__generated__/DirectSalesPaymentQuery'
import { Language as Languages } from '@/types'

import RouterWrapper from './routes/RouterWrapper'

type PaymentPageProps = {
  pendingSubscriptionId: string
  stripeKey: string
  csrfToken: string
  language: Languages
  targetFirstDeliveryDate: Date
  marketingPreferences: string[]
  paypalClientId: string
}

type State = {
  stripeKey: string
  csrfToken: string
  language: Languages
  data: DirectSalesPaymentQuery
  targetFirstDeliveryDate: Date
  marketingPreferences: string[]
  paypalClientId: string
}

type PaymentPageContextType = {
  paymentPageState: State | null
}

const PaymentPageContext = createContext<PaymentPageContextType>({
  paymentPageState: null
})

const PaymentPage = ({
  pendingSubscriptionId,
  stripeKey,
  csrfToken,
  language,
  targetFirstDeliveryDate,
  marketingPreferences,
  paypalClientId
}: PaymentPageProps): JSX.Element | null => {
  const [storageState, setStorageState] = useLocalStorage<string, State | null>(
    'directSalesPaymentState',
    null
  )
  const pageLanguage: Languages = language
  const [initialised, setInitialised] = useState<boolean>(false)

  initI18n(pageLanguage)

  const [directSalesPaymentState, setDirectSalesPaymentState] =
    useState<State | null>(storageState)
  const [contextValue, setContextValue] = useState<PaymentPageContextType>({
    paymentPageState: directSalesPaymentState || null
  })

  const { data, loading } = useQuery<DirectSalesPaymentQuery>(
    DIRECT_SALES_PAYMENT_QUERY,
    {
      variables: {
        pendingSubscriptionId: pendingSubscriptionId
      }
    }
  )

  const namespace = 'ds_app_pay_on_signup_flow'

  useEffect(() => {
    if (data?.directSalesPendingSubscription) {
      const dataObject = {
        stripeKey,
        csrfToken,
        language,
        data,
        targetFirstDeliveryDate,
        marketingPreferences,
        paypalClientId
      }
      setStorageState(dataObject)
      if (storageState === null) {
        setDirectSalesPaymentState(dataObject)
        setContextValue({
          paymentPageState: dataObject
        })
      } else {
        setDirectSalesPaymentState(storageState)
        setContextValue({
          paymentPageState: storageState
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, stripeKey, csrfToken, language])

  useEffect(() => {
    if (data) {
      Cookies.set(
        'discount_code_id',
        data?.directSalesPendingSubscription?.discountCode?.id || '',
        { expires: 30 }
      )
    }
  }, [data])

  useEffect(() => {
    if (data && !loading && contextValue.paymentPageState !== null) {
      setInitialised(true)
    }
  }, [data, loading, contextValue.paymentPageState])

  return (
    <>
      {initialised && data && (
        <PaymentPageContext.Provider value={contextValue}>
          <div className={STYLES.backgroundColour}>
            <TopNavBanner
              shippingCountryCode={
                data.directSalesPendingSubscription.address.shippingCountry.code
              }
              preferredLanguage={language}
            />
            <RouterWrapper namespace={namespace} />
          </div>
        </PaymentPageContext.Provider>
      )}
      <LoadingScreen
        isOpen={!initialised}
        title={{
          text: 'loading_screen.title',
          namespace: 'shared'
        }}
      />
    </>
  )
}

export type { State }
export { PaymentPage, PaymentPageContext }
export default withApollo(PaymentPage)
