// @noflow
import 'intersection-observer'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import 'scroll-behavior-polyfill'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import YellowBadge from 'assets/images/icons/badges/badge--yellow.svg'

import { Button } from '@/components/elements/atoms/Button'
import Image from '@/components/elements/atoms/Image/Image'
import TabbedPanel from '@/components/shared/TabbedPanel'

import { ensureNever } from '@/typescript/utils'

type Category = 'recipe' | 'treat'

const TabText = ({
  namespace,
  category
}: {
  category: Category
  namespace: string
}): React.ReactElement<'p'> => {
  const recipeNumber = React.useMemo((): string => {
    const tabbedComponentElement = document.querySelector(
      '.our-products__tabbed-component'
    )
    if (!tabbedComponentElement)
      throw new Error('Could not find tabbedComponentElement')
    const recipeNumber =
      tabbedComponentElement.getAttribute('data-recipe-number')
    if (!recipeNumber) throw new Error('Could not find recipeNumber')
    return recipeNumber
  }, [])

  const { t } = useTranslation(namespace)

  switch (category) {
    case 'recipe': {
      return (
        <p
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: t('tabbed_content.recipes.text_html', { recipeNumber })
          }}
        />
      )
    }
    case 'treat': {
      return (
        <p
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: t('tabbed_content.tasty_treats.text_html', { recipeNumber })
          }}
        />
      )
    }
    default: {
      ensureNever(category)
      throw new Error(`TabText is not implemented for ${category}`)
    }
  }
}

const baseClass = 'tabbed-component'

const TabImage = ({
  namespace,
  category,
  windowWidth
}: {
  namespace: string
  category: Category
  windowWidth: number
}) => {
  const { t } = useTranslation(namespace)
  // eslint-disable-next-line i18next/no-literal-string
  const imgClass = `${baseClass}__tab-image`

  switch (category) {
    case 'recipe': {
      return (
        <Image
          alt={t('tabbed_content.recipes.image_alt')}
          className={imgClass}
          slug={
            windowWidth > BREAKPOINTS.md
              ? '/german-shepherd-with-butternut-box-desktop'
              : '/german-shepherd-with-butternut-box-mobile'
          }
          image={{
            width: windowWidth > BREAKPOINTS.md ? 525 : 360,
            height: windowWidth > BREAKPOINTS.md ? 327 : 252
          }}
        />
      )
    }
    case 'treat': {
      return (
        <Image
          alt={t('tabbed_content.tasty_treats.image_alt')}
          className={imgClass}
          slug={
            windowWidth > BREAKPOINTS.md
              ? '/treats-with-ingredients-desktop'
              : '/treats-with-ingredients-mobile'
          }
          image={{
            width: windowWidth > BREAKPOINTS.md ? 525 : 360,
            height: windowWidth > BREAKPOINTS.md ? 327 : 252
          }}
        />
      )
    }
    default: {
      ensureNever(category)
      throw new Error(`TabImage is not implemented for ${category}`)
    }
  }
}

const Panel = ({
  namespace,
  selectedTab,
  windowWidth,
  category
}: {
  namespace: string
  selectedTab: string
  windowWidth: number
  category: Category
}): React.ReactElement<'div'> => {
  const { t } = useTranslation(namespace)
  const getTabBadgeText = (category: Category): string => {
    switch (category) {
      case 'recipe': {
        return t('tabbed_content.recipes.badge-text')
      }
      case 'treat': {
        return t('tabbed_content.tasty_treats.badge-text')
      }
      default: {
        ensureNever(category)
        throw new Error(`getTabImage is not implemented for ${category}`)
      }
    }
  }

  return (
    <div className={`${baseClass}__panel-wrapper`}>
      <div className={`${baseClass}__panel`}>
        <TabImage
          namespace={namespace}
          category={category}
          windowWidth={windowWidth}
        />
        <div
          className={`${baseClass}__tab-badge ${
            selectedTab === 'tasty_treats'
              ? `${baseClass}__tab-badge--right`
              : ''
          }`}
        >
          <img src={YellowBadge} alt="" height="100" width="100" />
          <span className={`${baseClass}__tab-badge-text`}>
            {getTabBadgeText(category)}
          </span>
        </div>
        <div className={`${baseClass}__tab-text`}>
          <TabText namespace={namespace} category={category} />
        </div>
      </div>
    </div>
  )
}

const TabbedComponent = ({
  namespace,
  showExtras
}: {
  namespace: string
  showExtras: boolean
}): React.ReactElement<'div'> => {
  const [selectedTab, selectTab] = React.useState('recipes-tab')
  const { windowWidth } = useWindowSize()
  const { t } = useTranslation(namespace)

  const recipeTab = [
    {
      title: t('tabbed_content.recipes.button_text'),
      id: 'recipes-tab',
      button_text: t('tabbed_content.recipes.button_text'),
      panel: (
        <Panel
          namespace={namespace}
          selectedTab="recipes"
          category="recipe"
          windowWidth={windowWidth}
        />
      ),
      selectTab: (): void => selectTab('recipes-tab')
    }
  ]

  const extrasTabs = [
    {
      title: t('tabbed_content.tasty_treats.button_text'),
      id: 'treats-tab',
      button_text: t('tabbed_content.tasty_treats.button_text'),
      panel: (
        <Panel
          namespace={namespace}
          selectedTab="tasty_treats"
          category="treat"
          windowWidth={windowWidth}
        />
      ),
      selectTab: (): void => selectTab('treats-tab')
    }
  ]

  const tabs = showExtras ? recipeTab.concat(extrasTabs) : recipeTab

  return (
    <div>
      <div className={baseClass}>
        <TabbedPanel selectedTabId={selectedTab} tabs={tabs} />
      </div>
      <div className={`${baseClass}__button-wrapper`}>
        <a href="/our_products">
          <Button
            typography={{
              namespace,
              text: 'our_products.button_text'
            }}
            disableAnalytics
            onClick={React.useCallback(() => null, [])}
            variant="secondary"
          />
        </a>
      </div>
    </div>
  )
}

export default TabbedComponent
