// @noflow
import { IdealBankElement } from '@stripe/react-stripe-js'
import { StripeIdealBankElementChangeEvent } from '@stripe/stripe-js'
import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'

import BRAND_COLOURS from '@/constants/BrandColours'

import { Expand } from '@/components/elements/atoms/Animated/Animated'
import Card from '@/components/elements/atoms/Card/Card'
import Image from '@/components/elements/atoms/Image'
import Text from '@/components/elements/atoms/Text'

import { CheckoutState } from '../../../types'

type Props = {
  namespace: string
  show: boolean
  ideal: Ideal
}

type Ideal = {
  handleIdealOnClick: () => void
  handleIdealSelect: (e: StripeIdealBankElementChangeEvent) => CheckoutState
  disabled: boolean
}

const IdealOption = ({ namespace, show, ideal }: Props): JSX.Element => {
  const { t } = useTranslation(namespace)
  const copyContext = 'payment_section.payment_options_view'
  const { handleIdealOnClick, handleIdealSelect, disabled } = ideal

  return (
    <Expand show={show} margin={{ top: 1.6 }} maxHeight={600}>
      <Card variant="brandBlue100" squared={{ topLeft: true, topRight: true }}>
        <Text
          text={`${copyContext}.ideal_choose_bank`}
          namespace={namespace}
          align="left"
        />
        <div className="ideal-section">
          <IdealBankElement
            onChange={handleIdealSelect}
            options={{
              style: {
                base: {
                  padding: '10px 16px',
                  color: BRAND_COLOURS.brandBlue500,
                  fontFamily:
                    'gt-pressura-regular, helvetica, arial, sans-serif',
                  fontSize: '16px',
                  '::placeholder': {
                    color: BRAND_COLOURS.brandBlue300
                  }
                }
              }
            }}
          />
        </div>
        <button
          className={classNames(
            'btn-updated--secondary payment-section__payment-options-btn ideal-button',
            {
              'btn-updated--disabled': disabled
            }
          )}
          type="button"
          disabled={disabled}
          onClick={handleIdealOnClick}
        >
          <p>{t(`${copyContext}.ideal`)}</p>
          <Image
            slug="ideal-logo-no-bg"
            alt={`${t(`${copyContext}.ideal_alt`)}`}
            image={{
              height: 30,
              width: 30
            }}
          />
        </button>
      </Card>
    </Expand>
  )
}

export default IdealOption
export type { Ideal }
