type AccountRoutes =
  | 'base'
  | 'paused'
  | 'noDogs'
  | 'orders'
  | 'box'
  | 'extrasOnly'
  | 'onDemandExtras'
  | 'oneOffBox'
  | 'oneOffBoxConfirmation'
  | 'changeDate'
  | 'profile'
  | 'dogBasicInfo'
  | 'dogWeightAndAppetite'
  | 'dogMedicalInfo'
  | 'dogEatingHabits'
  | 'dogAboutInfo'
  | 'dogPersonality'
  | 'referAFriend'
  | 'editRecipes'
  | 'planManagement'
  | 'rewards'
  | 'servingSize'
  | 'updatePlan'
  | 'reviewDailyPortion'
  | 'reviewFrequency'
  | 'deliveryFrequency'
  | 'editDogDetails'
  | 'editDeliveryAddress'
  | 'planConfirmation'
  | 'planConfirmationPuppy'
  | 'editExtras'
  | 'myAccount'
  | 'personalDetails'
  | 'paymentMethods'
  | 'addPaymentMethod'
  | 'contactPreferences'
  | 'extras'
  | 'basket'
  | 'swapAndSave'
  | 'switchAndSave'
  | 'downsizeAndSave'
  | 'boostEnroll'
  | 'ambassador'
  | 'influencer'
  | 'mealtimeChecklist'
  | 'mfitbPlanOffer'
  | 'restartPlanSuccess'

type AccountRoute = { [key in AccountRoutes]: string }

const ACCOUNT_SETTINGS_ROUTES = {
  myAccount: '/account/my-account',
  personalDetails: '/account/personal-details',
  paymentMethods: '/account/payment-methods',
  addPaymentMethod: '/account/payment-methods/add',
  contactPreferences: '/account/contact-preferences'
}

const ACCOUNT_EXTRAS = {
  extras: '/account/extras',
  basket: '/account/extras/basket'
}

const ACCOUNT_ROUTES: AccountRoute = {
  base: '/account',
  paused: '/account/paused',
  noDogs: '/account/no-dogs',
  orders: '/account/orders',
  box: '/account/orders/:boxId',
  extrasOnly: '/account/orders/extras-only',
  onDemandExtras: '/account/orders/extras-only/:id',
  oneOffBox: '/account/one-off-box',
  oneOffBoxConfirmation: '/account/one-off-box/order-confirmation',
  changeDate: '/account/orders/change-date',
  profile: '/account/my-dogs',
  dogBasicInfo: `/account/my-dogs/:dogId/basic-info`,
  dogWeightAndAppetite: `/account/my-dogs/:dogId/weight-and-appetite`,
  dogMedicalInfo: `/account/my-dogs/:dogId/medical-info`,
  dogEatingHabits: `/account/my-dogs/:dogId/eating-habits`,
  dogAboutInfo: `/account/my-dogs/:dogId/about`,
  dogPersonality: `/account/my-dogs/:dogId/personality`,
  referAFriend: '/account/refer-a-friend',
  editRecipes: '/account/edit-recipes',
  planManagement: '/account/plan-management',
  rewards: '/account/rewards',
  servingSize: '/account/serving-size',
  updatePlan: '/account/update-plan',
  reviewDailyPortion: '/account/update-plan/daily-portion',
  reviewFrequency: '/account/update-plan/delivery-frequency',
  deliveryFrequency: '/account/delivery-frequency',
  editDogDetails: '/account/edit-dog-details',
  editDeliveryAddress: '/account/edit-delivery-address',
  planConfirmation: '/account/plan-confirmation',
  planConfirmationPuppy: '/account/plan-confirmation/puppy',
  swapAndSave: '/account/swap-and-save',
  switchAndSave: '/account/switch-and-save',
  downsizeAndSave: '/account/downsize-and-save',
  editExtras: '/account/edit-extras',
  boostEnroll: '/account/boost',
  ambassador: '/users/sign_in_as_ambassador',
  influencer: '/users/sign_in_as_influencer',
  mealtimeChecklist: '/account/mealtime-checklist',
  mfitbPlanOffer: '/account/mfitb-plan-offer',
  restartPlanSuccess: '/account/restart-plan-success',
  ...ACCOUNT_EXTRAS,
  ...ACCOUNT_SETTINGS_ROUTES
}

export {
  AccountRoutes,
  AccountRoute,
  ACCOUNT_SETTINGS_ROUTES,
  ACCOUNT_EXTRAS,
  ACCOUNT_ROUTES
}
