// @flow

import * as MESSAGES from '../actions/messages'

import {
  selectIsAnyDogAPuppy
} from '../reducers/globalAttributesReducer'

import type { State } from '../reducers'
import type { TrackableAction } from '../actions'

type BaseTraits = {|
  hasAPuppy: boolean,
  numberOfDogs: number
|}

/**
 * Ideally, this would be an Array<$PropertyType<TrackableAction, 'type'>>
 * However, the redux-action-analytics-middleware generics do not extend to the
 * trackableActions. This is documented in the package and may be dealt with
 * in a future update:
 *
 * github.com/TomasBarry/redux-action-analytics-middleware/blob/master/src/index.js#L15
 * flow.org/en/docs/types/utilities/#toc-propertytype
 */
const trackableActions = [
  MESSAGES.NEXT_WIZARD_STEP,
  MESSAGES.PREVIOUS_WIZARD_STEP,
  MESSAGES.START_REQUEST,
  MESSAGES.COMPLETE_REQUEST,
  MESSAGES.SELECT_FAVOURITE_GAME,
  MESSAGES.TOGGLE_ACTIVITY,
  MESSAGES.TOGGLE_TRAIT,
  MESSAGES.UPDATE_DOG_DESCRIPTION,
  MESSAGES.SET_AVATAR_SRC
]

const TABLE_NAME = 'POST_SIGN_UP_WIZARD_INTERACTIONS'

const selectBaseTraits = ({
  preActionState, // eslint-disable-line no-unused-vars
  postActionState
}: {
  preActionState: State,
  postActionState: State
}): BaseTraits => {
  const { globalAttributes } = postActionState

  const hasAPuppy = selectIsAnyDogAPuppy(globalAttributes)
  const numberOfDogs = globalAttributes.dogs.length

  return {
    hasAPuppy,
    numberOfDogs
  }
}

const trackCallback = (
  action: TrackableAction,
  preActionState: State,
  postActionState: State
): void => {
  /**
   * Handle the scenario where window.analytics.track is not present (in the
   * case of a customer having an ad-blocker etc)
   */
  if (!window.analytics || !window.analytics.track) { return }

  const baseTraits: BaseTraits = selectBaseTraits({
    preActionState,
    postActionState
  })

  switch (action.type) {
    case MESSAGES.NEXT_WIZARD_STEP: {
      const eventName = MESSAGES.NEXT_WIZARD_STEP

      const { wizardStep } = preActionState

      const properties = {
        eventName,
        preActionWizardStep: wizardStep
      }

      window.analytics.track(
        TABLE_NAME,
        {
          ...properties,
          ...baseTraits
        }
      )
      break
    }
    case MESSAGES.PREVIOUS_WIZARD_STEP: {
      const eventName = MESSAGES.PREVIOUS_WIZARD_STEP

      const { wizardStep } = preActionState

      const properties = {
        eventName,
        preActionWizardStep: wizardStep
      }

      window.analytics.track(
        TABLE_NAME,
        {
          ...properties,
          ...baseTraits
        }
      )
      break
    }
    case MESSAGES.SELECT_FAVOURITE_GAME: {
      const eventName = MESSAGES.SELECT_FAVOURITE_GAME

      const { id, favouriteGame } = action

      const properties = {
        eventName,
        id,
        favouriteGame
      }

      window.analytics.track(
        TABLE_NAME,
        {
          ...properties,
          ...baseTraits
        }
      )
      break
    }
    case MESSAGES.TOGGLE_ACTIVITY: {
      const eventName = MESSAGES.TOGGLE_ACTIVITY

      const { dogProfiles } = postActionState
      const selected = dogProfiles[action.id].activities[action.activity]

      const { id, activity } = action

      const properties = {
        eventName,
        id,
        activity,
        selected
      }

      window.analytics.track(
        TABLE_NAME,
        {
          ...properties,
          ...baseTraits
        }
      )
      break
    }
    case MESSAGES.TOGGLE_TRAIT: {
      const eventName = MESSAGES.TOGGLE_TRAIT

      const { dogProfiles } = postActionState
      const selected = dogProfiles[action.id].traits[action.trait]

      const { id, trait } = action

      const properties = {
        eventName,
        id,
        trait,
        selected
      }

      window.analytics.track(
        TABLE_NAME,
        {
          ...properties,
          ...baseTraits
        }
      )
      break
    }
    case MESSAGES.UPDATE_DOG_DESCRIPTION: {
      const eventName = MESSAGES.UPDATE_DOG_DESCRIPTION

      const { id, description } = action

      const properties = {
        eventName,
        id,
        description
      }

      window.analytics.track(
        TABLE_NAME,
        {
          ...properties,
          ...baseTraits
        }
      )
      break
    }
    case MESSAGES.SET_AVATAR_SRC: {
      const eventName = MESSAGES.SET_AVATAR_SRC

      const { id } = action

      const properties = {
        eventName,
        id
      }

      window.analytics.track(
        TABLE_NAME,
        {
          ...properties,
          ...baseTraits
        }
      )
      break
    }
    case MESSAGES.START_REQUEST: {
      const eventName = MESSAGES.START_REQUEST

      const { name } = action

      const properties = {
        eventName,
        request: name
      }

      window.analytics.track(
        TABLE_NAME,
        {
          ...properties,
          ...baseTraits
        }
      )
      break
    }
    case MESSAGES.COMPLETE_REQUEST: {
      const eventName = MESSAGES.COMPLETE_REQUEST

      const { name, error } = action

      // can't use shorthand on error as we want the error key present even
      // when the value of error is not specified (undefined)
      const properties = {
        eventName,
        request: name,
        error: error
      }

      window.analytics.track(
        TABLE_NAME,
        {
          ...properties,
          ...baseTraits
        }
      )
      break
    }
    default: {
      (action.type: empty) // eslint-disable-line
      throw new Error(`No tracking defined for action type ${action.type}`)
    }
  }
}

export {
  trackCallback,
  trackableActions,
  selectBaseTraits
}
