/* eslint-disable i18next/no-literal-string */
// @noflow
import React, { useCallback } from 'react'

import Info from 'assets/images/icons/info/blue-info-blue-ring.svg'

import { Button } from '@/components/elements/atoms/Button'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './ErrorCard.module.sass'

import ExpandCard from '../../molecules/ExpandCard/ExpandCard'

type Props = {
  message: string
  location?: string
}

const ErrorCard = ({ message, location }: Props): JSX.Element => {
  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(`Error details:
      Message: ${message}
      ${location ? `Location: ${location}` : ''}`)
  }, [location, message])

  return (
    <ExpandCard
      text={{
        text: 'error_state.error_other.error_details_label',
        namespace: 'account'
      }}
      icon={Info}
      identifier="error_state.error_other.error_details"
    >
      <div className={STYLES.content}>
        <Text
          namespace="account"
          text={'error_state.error_other.error_details_message'}
          variant="textRegular16"
          margin={false}
          align="left"
        />
        <div className={STYLES.error}>
          <div className={STYLES.errorLabel}>
            <Text
              namespace="account"
              text={'error_state.error_other.error_message_label'}
              variant="textRegular12"
              margin={false}
              align="left"
            />
          </div>
          <Text
            text={message}
            variant="textMono14"
            translate={false}
            align="left"
            margin={false}
          />
          {location && (
            <>
              <div className={STYLES.errorLabel}>
                <Text
                  namespace="account"
                  text={'error_state.error_other.error_page_label'}
                  variant="textRegular12"
                  margin={false}
                  align="left"
                />
              </div>
              <Text
                text={location}
                variant="textMono14"
                translate={false}
                align="left"
                margin={false}
              />
            </>
          )}
        </div>
        <div className={STYLES.copyButton}>
          <Button
            typography={{
              namespace: 'account',
              text: 'error_state.error_other.copy_to_clipboard'
            }}
            onClick={copyToClipboard}
            variant="primary"
            identifier="error-state-copy-to-clipboard"
          />
        </div>
      </div>
    </ExpandCard>
  )
}

export { ErrorCard }
