import { StripeIdealBankElementChangeEvent } from '@stripe/stripe-js'
import React, { useCallback, useState } from 'react'

import idealLogo from 'assets/images/logos/ideal-logo.svg'

import DetailsCard from '@/components/elements/molecules/DetailsCard/DetailsCard'

import { InputPaymentMethod } from '@/types'

import PaymentMethodModal from '../PaymentMethodModal/PaymentMethodModal'

type Props = {
  inlineErrorMessage: string | null
  disableButton: boolean
  setDisableButton: (disable: boolean) => void
  setIdealBank: (bank: string) => void
  onSubmit: () => void
}

const IdealCard = ({
  inlineErrorMessage,
  disableButton,
  setDisableButton,
  setIdealBank,
  onSubmit
}: Props): JSX.Element => {
  const [openNewPaymentMethod, setOpenNewPaymentMethod] = useState(false)

  const onIdealSelectChange = React.useCallback(
    (e: StripeIdealBankElementChangeEvent) => {
      if (e.complete) {
        setIdealBank(e.value)
        setDisableButton(false)
      } else {
        setDisableButton(true)
      }
    },
    [setDisableButton, setIdealBank]
  )

  const onSubmitWrapper = useCallback(() => {
    setDisableButton(true)
    onSubmit()
  }, [onSubmit, setDisableButton])

  const toggleAddPaymentMethodModal = useCallback(() => {
    setOpenNewPaymentMethod(!openNewPaymentMethod)
  }, [openNewPaymentMethod])

  return (
    <>
      <DetailsCard
        text={{ text: 'my_details.payment_methods.method_names.ideal' }}
        onClick={toggleAddPaymentMethodModal}
        leftImage={<img height="30px" src={idealLogo} alt={'ideal logo'} />}
        namespace={'dashboard'}
        identifier="payment_methods.ideal"
      />
      <PaymentMethodModal
        isOpen={openNewPaymentMethod}
        toggle={toggleAddPaymentMethodModal}
        inlineErrorMessage={inlineErrorMessage}
        onSubmit={onSubmitWrapper}
        onIdealSelectChange={onIdealSelectChange}
        paymentMethodType={InputPaymentMethod.sepa_direct_debit}
        disableButton={disableButton}
      />
    </>
  )
}

export { Props }
export default IdealCard
