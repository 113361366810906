// @noflow
import { makeVar, useReactiveVar } from '@apollo/client'
import React from 'react'
import { useTranslation } from 'react-i18next'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import MealBowl from 'assets/images/illustrations/bowls/bowl-with-hearts--yellow.svg'

import { Button } from '@/components/elements/atoms/Button'
import Modal from '@/components/elements/atoms/Modal/Modal'
import Text from '@/components/elements/atoms/Text/Text'
import { ReferAFriendPageQuery } from '@/components/pages/ReferAFriendPage/queries/__generated__/ReferAFriendPageQuery'

import STYLES from './DonationsModal.module.sass'

type Props = {
  namespace: string
  referralLink: ReferAFriendPageQuery['user']['subscription']['referralLink']
  showRafCharityButton: boolean
}

const donationsModalState = makeVar(false)

const DonationsModal = ({
  namespace,
  referralLink,
  showRafCharityButton
}: Props): JSX.Element => {
  const { t } = useTranslation('dashboard')
  const copyContext = 'refer_a_friend.give_and_get_tab.donations_modal'

  const isDonationsModalOpen = useReactiveVar(donationsModalState)

  const { windowWidth } = useWindowSize()

  const { referrals } = referralLink

  const showDonations = referrals.length > 0

  const handleOnClick = React.useCallback(() => {
    window.open(
      'https://join.butternutbox.com/charity-page/',
      '_blank',
      'noopener,noreferrer'
    )
  }, [])

  return (
    <Modal
      isModalOpen={isDonationsModalOpen}
      setOpenModal={donationsModalState}
      width={600}
      showCloseButton
      bodyScroll
      bottomSticky={windowWidth < BREAKPOINTS.md}
    >
      <>
        <div className={STYLES.title}>
          <Text
            namespace={namespace}
            text={`${copyContext}.${
              showDonations ? 'donations' : 'rescue'
            }_title`}
            variant="display20"
            align="center"
            margin={false}
          />
        </div>
        <img
          src={MealBowl}
          alt={t(`${copyContext}.img_alt`)}
          className={STYLES.imageWrapper}
        />
        {showDonations && (
          <Text
            namespace={namespace}
            text={`${copyContext}.subtitle`}
            variables={{
              count: referrals.length
            }}
            variant="display16"
            align="center"
            margin={false}
          />
        )}
        <Text
          namespace={namespace}
          text={`${copyContext}.${
            showDonations ? 'donations' : 'rescue'
          }_description`}
          variant="textRegular14"
          align="center"
        />
        {showRafCharityButton && (
          <div className={STYLES.buttonWrapper}>
            <Button
              typography={{
                namespace,
                text: `${copyContext}.learn_more_button`
              }}
              onClick={handleOnClick}
              disableAnalytics
            />
          </div>
        )}
      </>
    </Modal>
  )
}

export { donationsModalState }
export default DonationsModal
