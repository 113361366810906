import classNames from 'classnames'
import React from 'react'

import Text from '@/components/shared/elements/Text/Text'

import STYLES from './InPageNotification.module.sass'

import { TextProps } from '../Text'
import { Error, Info, Success, Warn } from './toastify'

type Props = {
  text: TextProps
  variant?: 'success' | 'info' | 'warn' | 'error'
  className?: string
  children?: React.ReactNode
}

const icons = { success: Success, info: Info, warn: Warn, error: Error }

const Notification = ({
  text,
  variant = 'info',
  className,
  children
}: Props): JSX.Element => {
  const classname = classNames(STYLES.container, STYLES[variant], className)

  const Icon = icons[variant]

  return (
    <div className={classname}>
      <div>
        <Icon />
      </div>
      <div>
        <Text {...text} />

        {children}
      </div>
    </div>
  )
}

export default Notification
