// @flow

import * as React from 'react'

type Size =
  | 'xlarge'
  | 'large'
  | 'medium'
  | 'small'
  | 'tiny'

type Props = {|
  children: React.Node,
  type: Size,
  className?: string
|}

/**
 * Title component generates one of the h1, h2, h3, h4, h5
 * elements depending on the provided type
 *
 * 'xlarge' type - 6.4rem
 * 'large' type - 4.5rem
 * 'medium' type - 3.6rem
 * 'small' type - 2.8rem
 * 'tiny' type - 2rem
 *
 * By default type is set to 'small'
 *
 * Example usage
 * <Title
 *   type='medium'
 *   className='custom-title'
 * >
 *   { `My great title` }
 * </Title>
 */

const Title = ({
  className = '',
  type = 'small',
  children
}: Props): React.Node => {
  const classNames = `title title--${type} ${className}`

  switch (type) {
    case 'xlarge': {
      return (
        <h1 className={classNames}>
          { children }
        </h1>
      )
    }
    case 'large': {
      return (
        <h2 className={classNames}>
          { children }
        </h2>
      )
    }
    case 'medium': {
      return (
        <h3 className={classNames}>
          { children }
        </h3>
      )
    }
    case 'small': {
      return (
        <h4 className={classNames}>
          { children }
        </h4>
      )
    }
    case 'tiny': {
      return (
        <h5 className={classNames}>
          { children }
        </h5>
      )
    }
    default: {
      (type: empty) // eslint-disable-line no-unused-expressions
      throw new Error(`Cannot render Title for type: ${type}`)
    }
  }
}

export default Title

export type {
  Size
}
