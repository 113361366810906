// @noflow

import React from 'react'
import { BrowserRouter as Router, Route, Routes as RoutesRouter } from 'react-router-dom'

// Routes
import Routes from './routes'

// Components
import ExtrasList from './screens/ExtrasList/ExtrasList'
import ProductCollection from './screens/ProductCollection'
import BasketPage from './screens/BasketPage/BasketPage'
import ConfirmationPage from './screens/ConfirmationPage/ConfirmationPage'

// Types
import type { Language } from '@/packs/localisation'

type Props = {
  variant: Language
}

const ExtrasPageRoutesWrapper = ({ variant }: Props) => {
  return (
    <Router>
      <RoutesRouter>
        <Route
          path={Routes.ExtrasList}
          element={<ExtrasList variant={variant} />}
        />
        <Route
          path={Routes.Basket}
          element={<BasketPage variant={variant} />}
        />
        <Route
          path={Routes.Confirmation}
          element={<ConfirmationPage variant={variant} />}
        />
        <Route
          path={Routes.ProductCollectionPath}
          element={<ProductCollection variant={variant} />}
        />
      </RoutesRouter>
    </Router>
  )
}

export default ExtrasPageRoutesWrapper
