import isUndefined from 'lodash/isUndefined'

// eslint-disable-next-line no-restricted-imports
import { PlanType } from '@/components/types'

const isAllOrMix = (planType: PlanType): 'all' | 'mix' | undefined => {
  if (isUndefined(planType)) return undefined
  return planType === 'all' ? 'all' : 'mix'
}

export default isAllOrMix
