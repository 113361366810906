// @noflow
import { Options, formatCurrencyWithDecimal } from '@/utils/currency'

import type {
  EditableShopItemProps,
  Props as ShopItemProps
} from '@/components/elements/molecules/BoxItem/ShopItem/ShopItem'
import type { SizeOption } from '@/components/elements/molecules/ExtrasModal/ExtrasContent'
import { getProductSizeOptions } from '@/components/elements/molecules/ExtrasModal/helpers'
import type { oneOffBoxQuery_user_subscription_previewUncreatedFutureBox_order_physicalOrderProducts as UncreatedFutureBoxPhysicalProducts } from '@/components/pages/OneOffBoxPage/queries/__generated__/oneOffBoxQuery'

import {
  EditExtrasQuery_user_subscription_box_physicalOrderProducts as PhysicalProduct,
  EditExtrasQuery_user_subscription_box_physicalOrderProducts_productVariant_productCollection_productVariants as ProductVariant
} from '../queries/__generated__/EditExtrasQuery'
import { Frequency } from '@/types'

const transformExtra = (
  extra: PhysicalProduct | UncreatedFutureBoxPhysicalProducts,
  currencyOptions: Options
): ShopItemProps => {
  const { frequency, productVariant } = extra
  const { grossPrice, subscribeAndSaveable } = productVariant

  const recurring = frequency === Frequency.recurring
  const priceFormatter = (price: number) =>
    formatCurrencyWithDecimal(price, currencyOptions)

  // Prices
  const price = priceFormatter(extra.price)
  const grossPriceToShow = priceFormatter(grossPrice)

  return Object.freeze({
    cloudinaryPath: extra.productVariant.productCollection.thumbnail.src,
    productName: extra.productVariant.productCollection.name,
    price,
    discountedPrice: priceFormatter(extra.discountedPrice),
    sizeDescription: extra.productVariant.name,
    quantity: extra.quantity,
    subscribeSave: subscribeAndSaveable && recurring,
    recurring: recurring,
    free: !extra.requiresPayment,
    productVariant: extra.productVariant,
    nonPaymentReason: extra.nonPaymentReason,
    grossPrice: grossPriceToShow
  })
}

const generateSizeOptions = (variants: ProductVariant[]): SizeOption[] => {
  const options: SizeOption[] = []

  for (let i = 0; i < variants.length; i++) {
    const variant = variants[i]
    options.push({
      label: variant.name,
      value: variant.id
    })
  }
  return options
}

// transform the query object to a structure consumable by our components
const transformEditableExtras = (
  products: PhysicalProduct[],
  currencyOptions: Options
): EditableShopItemProps[] =>
  products.map((extra) => {
    const selectedProductVariant = extra.productVariant

    return Object.freeze({
      ...transformExtra(extra, currencyOptions),
      id: selectedProductVariant.id,
      size: selectedProductVariant.id,
      sizes: getProductSizeOptions(
        generateSizeOptions(
          selectedProductVariant.productCollection.productVariants
        )
      ),
      requiresPayment: extra.requiresPayment
    })
  }) as EditableShopItemProps[]

export { transformExtra, transformEditableExtras }
