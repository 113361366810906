// @noflow
import { Language } from '@/packs/localisation'
import { makeVar, useMutation, useQuery, useReactiveVar } from '@apollo/client'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import useWebviewHandler from '@/hooks/useWebviewHandler'

import SelfResolutionLoading from './components/SelfResolutionLoading/SelfResolutionLoading'

import STYLES from './SelfResolutionPage.module.sass'

// Mutations
import { SUBMIT_CATEGORY } from './mutations/CustomerIssueCategorySubmission'
import { REPORT_ISSUE } from './mutations/ReportIssueSubmission'
import { APPLY_DISCOUNT } from './mutations/ResolutionAcceptanceApplyDiscountSubmission'
import { ORDER_REPLACEMENT_BOX } from './mutations/ResolutionAcceptanceReplacementBoxSubmission'
import { CATEGORIES } from './queries/categoriesQuery'

import {
  CustomerIssueCategorySubmission,
  CustomerIssueCategorySubmission_response_fields as EntryField,
  CustomerIssueCategorySubmission_response_resolutions as QuickHelpResolution,
  CustomerIssueCategorySubmission_response_reasons as Reason
} from './mutations/__generated__/CustomerIssueCategorySubmission'
import {
  CustomerIssueReportEntriesSubmission,
  CustomerIssueReportEntriesSubmissionVariables,
  CustomerIssueReportEntriesSubmission_response_resolutions_details_ApplyDiscounts as DiscountDetails,
  CustomerIssueReportEntriesSubmission_response_resolutions as ResolutionOffering
} from './mutations/__generated__/CustomerIssueReportEntriesSubmission'
import { CustomerIssueResolutionAcceptanceApplyDiscount } from './mutations/__generated__/CustomerIssueResolutionAcceptanceApplyDiscount'
import {
  CustomerIssueResolutionAcceptanceReplacementBoxSubmission,
  CustomerIssueResolutionAcceptanceReplacementBoxSubmissionVariables
} from './mutations/__generated__/CustomerIssueResolutionAcceptanceReplacementBoxSubmission'
import {
  SelfResolutionCategories_categories as CustomerIssueCategory,
  SelfResolutionCategories_user_subscription_box_potentialMissingItems as PotentialMissingItems,
  SelfResolutionCategories,
  SelfResolutionCategories_user_subscription_box
} from './queries/__generated__/SelfResolutionCategories'
import type { Code } from '@/shared_types/rails_models/shipping_countries'

import SelfResolution from './SelfResolution'
import useSelfResolutionTracking from './analytics'
import SelfResolutionRoutes, {
  Route as SelfResolutionRoute
} from './types/routes'
import {
  EntryFieldsState,
  LoadingScreenType,
  ResolutionConfirmation,
  ResolutionOfferings
} from './types/types'

type Props = {
  variant: Language
}

type SelfResolutionState = {
  userId: string
  shippingCountryCode: Code
  preferredLanguage: Language
  reportId: string
  pageTitle: string | null
  error: boolean
  errorType: string
  selectedCategory: string
  box: SelfResolutionCategories_user_subscription_box
  showBackButton: boolean
  hasReceivedReplacementBox: boolean
}

const initialState: SelfResolutionState = {
  userId: '',
  shippingCountryCode: 'GB',
  preferredLanguage: 'en',
  reportId: '',
  pageTitle: 'default',
  error: false,
  errorType: 'default',
  selectedCategory: '',
  box: {
    __typename: 'Box',
    hasPreviousOrderIssue: false,
    potentialMissingItems: [],
    isoDeliveryDate: null,
    price: -1
  },
  showBackButton: true,
  hasReceivedReplacementBox: false
}

const selfResolutionPageState = makeVar(initialState)
const categoriesState = makeVar<Array<CustomerIssueCategory>>([])
const resolutionsState = makeVar<Array<QuickHelpResolution> | null>([])
const entryFieldsState = makeVar<EntryFieldsState>({
  entryFields: [],
  reasons: []
})
const resolutionOfferingState = makeVar<ResolutionOfferings>({
  resolutions: []
})
const resolutionConfirmationState = makeVar<ResolutionConfirmation>({
  type: null,
  details: null
})

const SelfResolutionPage = ({ variant }: Props): JSX.Element => {
  const boxId = Object.fromEntries(
    new URLSearchParams(window.location.search)
  ).box_id
  const webViewHandler = useWebviewHandler()
  const selfResolutionState = useReactiveVar(selfResolutionPageState)
  const resolutionOfferingData = useReactiveVar(resolutionOfferingState)
  const resolutionConfirmationData = useReactiveVar(resolutionConfirmationState)
  const namespace = 'self_resolution_flow'
  const [loading, setLoading] = useState(false)
  const [confirmed, setConfirmed] = useState(false)
  const [offered, setOffered] = useState(false)
  const [loadingScreen, setLoadingScreen] =
    useState<LoadingScreenType>('default')
  const [route, setRoute] = useState<SelfResolutionRoute | null>(null)
  const [initialHistoryLength, setInitialHistoryLength] = useState(1)
  const selfResolutionTracking = useSelfResolutionTracking()
  const {
    loading: categoriesLoading,
    data: categoriesData,
    error: categoriesError
  } = useQuery<SelfResolutionCategories>(CATEGORIES, {
    variables: { boxId: boxId }
  })
  const [
    submitCategoryMutation,
    {
      loading: categoriesSubmissionLoading,
      data: categoriesSubmissionData,
      error: categoriesSubmissionError
    }
  ] = useMutation<CustomerIssueCategorySubmission>(SUBMIT_CATEGORY)
  const [
    reportIssueMutation,
    {
      loading: reportIssueLoading,
      data: reportIssueData,
      error: reportIssueError
    }
  ] = useMutation<CustomerIssueReportEntriesSubmission>(REPORT_ISSUE)
  const [
    orderReplacementBoxMutation,
    {
      loading: resolutionOfferingReplacementBoxLoading,
      data: resolutionOfferingReplacementBoxData,
      error: resolutionOfferingReplacementBoxError
    }
  ] = useMutation<CustomerIssueResolutionAcceptanceReplacementBoxSubmission>(
    ORDER_REPLACEMENT_BOX
  )

  const [
    applyDiscountMutation,
    {
      loading: resolutionOfferingApplyDiscountLoading,
      data: resolutionOfferingApplyDiscountData,
      error: resolutionOfferingApplyDiscountError
    }
  ] =
    useMutation<CustomerIssueResolutionAcceptanceApplyDiscount>(APPLY_DISCOUNT)

  const setResolutionOfferingState = useCallback(
    (
      resolutions?: Array<ResolutionOffering>,
      affectedItems?: Array<PotentialMissingItems>
    ) => {
      const newState = { ...resolutionOfferingData }

      if (resolutions && newState.resolutions !== resolutions) {
        newState.resolutions = resolutions
      }
      if (affectedItems && newState.affectedItems !== affectedItems) {
        newState.affectedItems = affectedItems
      }
      if (
        newState.resolutions !== resolutionOfferingData.resolutions ||
        newState.affectedItems !== resolutionOfferingData.affectedItems
      ) {
        resolutionOfferingState(newState)
      }
    },
    [resolutionOfferingData]
  )

  const categorySubmission = useCallback(
    (categoryId: string, categoryName: string) => {
      const args = {
        variables: {
          userId: selfResolutionState.userId,
          boxId: boxId,
          categoryId: categoryId
        }
      }
      selfResolutionPageState({
        ...selfResolutionState,
        selectedCategory: categoryName
      })
      submitCategoryMutation(args)
    },
    [submitCategoryMutation, selfResolutionState, boxId]
  )

  const reportIssue = useCallback(
    (
      variables: CustomerIssueReportEntriesSubmissionVariables,
      affectedItems?: Array<PotentialMissingItems>
    ) => {
      const args = { variables }
      if (affectedItems) {
        setResolutionOfferingState(undefined, affectedItems)
      }
      reportIssueMutation(args)
    },
    [reportIssueMutation, setResolutionOfferingState]
  )

  const orderReplacementBox = useCallback(
    (
      variables: CustomerIssueResolutionAcceptanceReplacementBoxSubmissionVariables
    ) => {
      const args = { variables }
      resolutionConfirmationState({
        type: null,
        details: variables.replacementBoxDetails
      })
      orderReplacementBoxMutation(args)
    },
    [orderReplacementBoxMutation]
  )

  const applyDiscount = useCallback(() => {
    applyDiscountMutation({
      variables: {
        userId: selfResolutionState.userId,
        reportId: selfResolutionState.reportId
      }
    })
  }, [applyDiscountMutation, selfResolutionState])

  useEffect(() => {
    if (document.referrer.indexOf('/self-resolution/') === -1) {
      const hostName = window.location.origin
      const referrer =
        document.referrer.indexOf(hostName) === 0
          ? new URL(document.referrer).pathname
          : document.referrer
      selfResolutionTracking.trackEvent('new session started', {
        entry: referrer
      })
    }
    setInitialHistoryLength(window.history.length)
    webViewHandler.pageLoaded()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLoading = useCallback(() => {
    setLoading(
      categoriesLoading ||
        categoriesSubmissionLoading ||
        reportIssueLoading ||
        resolutionOfferingReplacementBoxLoading ||
        resolutionOfferingApplyDiscountLoading
    )
    if (categoriesLoading) {
      setLoadingScreen('default')
    } else if (
      categoriesSubmissionLoading ||
      resolutionOfferingReplacementBoxLoading ||
      resolutionOfferingApplyDiscountLoading
    ) {
      setLoadingScreen('wereStillHere')
    } else if (reportIssueLoading) {
      setLoadingScreen('submittingReport')
    } else {
      setLoadingScreen('default')
    }
  }, [
    categoriesLoading,
    categoriesSubmissionLoading,
    reportIssueLoading,
    resolutionOfferingReplacementBoxLoading,
    resolutionOfferingApplyDiscountLoading
  ])

  const hasFailed = useCallback((): boolean => {
    return (
      !!categoriesError ||
      !!categoriesSubmissionError ||
      !!reportIssueError ||
      !!resolutionOfferingReplacementBoxError ||
      !!resolutionOfferingApplyDiscountError
    )
  }, [
    categoriesError,
    categoriesSubmissionError,
    reportIssueError,
    resolutionOfferingReplacementBoxError,
    resolutionOfferingApplyDiscountError
  ])

  const errorType = useCallback(() => {
    if (categoriesError || categoriesSubmissionError) {
      return 'couldntFetchInfo'
    } else if (reportIssueError) {
      return 'couldntSendReport'
    } else {
      return 'default'
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    categoriesError,
    categoriesSubmissionError,
    reportIssueError,
    resolutionOfferingReplacementBoxError,
    resolutionOfferingApplyDiscountError
  ])

  const handleCategoriesSubmissionResponse = useCallback(
    (
      resolutions: Array<QuickHelpResolution> | null,
      entryFields: Array<EntryField>,
      reasons: Array<Reason> | null,
      reportId: string
    ) => {
      if (selfResolutionState.reportId !== reportId) {
        selfResolutionPageState({
          ...selfResolutionState,
          reportId
        })
      }
      entryFieldsState({ entryFields, reasons })
      resolutionsState(resolutions)

      if (resolutions && resolutions.length > 0) {
        setRoute(SelfResolutionRoutes.QuickHelpResolution)
      } else if (entryFields.length > 0) {
        setRoute(SelfResolutionRoutes.ReportIssue)
      } else {
        setRoute(SelfResolutionRoutes.ResolutionOffering)
      }
    },
    [selfResolutionState]
  )

  useEffect(() => {
    const pageState = {
      ...selfResolutionState,
      error: hasFailed(),
      errorType: errorType()
    }

    handleLoading()

    if (categoriesData) {
      pageState.userId = categoriesData.user.id
      pageState.box = categoriesData.user.subscription
        .box as SelfResolutionCategories_user_subscription_box
      const today = new Date()
      const deliveryDate = new Date(pageState.box.isoDeliveryDate)
      const deliveryDateFourDaysLater = new Date(pageState.box.isoDeliveryDate)
      deliveryDateFourDaysLater.setDate(deliveryDateFourDaysLater.getDate() + 4)
      if (today < deliveryDate || today > deliveryDateFourDaysLater) {
        const categoriesWithoutDeliveryIssues =
          categoriesData.categories.filter((cat) => {
            return cat.id !== '148' && cat.parentId !== '148'
          })
        categoriesState(categoriesWithoutDeliveryIssues)
      } else {
        categoriesState(categoriesData.categories)
      }
    }

    selfResolutionPageState(pageState)

    if (hasFailed()) {
      setRoute(SelfResolutionRoutes.Error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    categoriesLoading,
    categoriesData,
    categoriesError,
    categoriesSubmissionError,
    categoriesSubmissionLoading,
    reportIssueLoading,
    reportIssueError,
    resolutionOfferingReplacementBoxLoading,
    resolutionOfferingReplacementBoxError,
    resolutionOfferingApplyDiscountLoading,
    resolutionOfferingApplyDiscountError
  ])

  useEffect(() => {
    if (!!categoriesSubmissionData && !!categoriesSubmissionData.response) {
      handleCategoriesSubmissionResponse(
        categoriesSubmissionData.response.resolutions,
        categoriesSubmissionData.response.fields,
        categoriesSubmissionData.response.reasons,
        categoriesSubmissionData.response.reportId
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriesSubmissionData])

  useEffect(() => {
    if (reportIssueData) {
      const resolutions = reportIssueData.response
        ? reportIssueData.response.resolutions
        : []
      setResolutionOfferingState(resolutions)
      if (resolutions[0] && resolutions[0].key) {
        resolutionConfirmationState({
          type: null,
          details: resolutions[0].details as DiscountDetails
        })
        setOffered(true)
      }
      setRoute(SelfResolutionRoutes.ResolutionOffering)
    }
  }, [reportIssueData, setResolutionOfferingState])

  useEffect(() => {
    if (
      !!resolutionOfferingReplacementBoxData &&
      !!resolutionOfferingReplacementBoxData.response
    ) {
      const resolutionApplicationConfirmation =
        resolutionOfferingReplacementBoxData.response
          .resolutionApplicationConfirmation
      resolutionConfirmationState({
        ...resolutionConfirmationData,
        type: resolutionApplicationConfirmation.type
      })
      setConfirmed(true)
      setRoute(SelfResolutionRoutes.ResolutionConfirmation)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resolutionOfferingReplacementBoxData])

  useEffect(() => {
    if (
      !!resolutionOfferingApplyDiscountData &&
      !!resolutionOfferingApplyDiscountData.response
    ) {
      const resolutionApplicationConfirmation =
        resolutionOfferingApplyDiscountData.response
          .resolutionApplicationConfirmation
      resolutionConfirmationState({
        ...resolutionConfirmationData,
        type: resolutionApplicationConfirmation.type
      })
      setConfirmed(true)
      setRoute(SelfResolutionRoutes.ResolutionConfirmation)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resolutionOfferingApplyDiscountData])

  const containerClassNames = useMemo(() => {
    return `${STYLES.container} ${confirmed ? STYLES.confirmed : ''}  ${
      offered ? STYLES.offered : ''
    }`
  }, [confirmed, offered])
  return (
    <div className={containerClassNames}>
      <Router>
        <SelfResolutionLoading
          namespace={namespace}
          loading={loading}
          loadingScreen={loadingScreen}
        >
          <SelfResolution
            namespace={namespace}
            boxId={boxId}
            route={route}
            initialHistoryLength={initialHistoryLength}
            setRoute={setRoute}
            categorySubmission={categorySubmission}
            reportIssue={reportIssue}
            orderReplacementBox={orderReplacementBox}
            applyDiscount={applyDiscount}
          />
        </SelfResolutionLoading>
      </Router>
    </div>
  )
}

export {
  SelfResolutionPage,
  SelfResolutionState,
  selfResolutionPageState,
  categoriesState,
  resolutionsState,
  entryFieldsState,
  resolutionOfferingState,
  resolutionConfirmationState,
  Props
}
export default SelfResolutionPage
