/* eslint-disable i18next/no-literal-string */
// @noflow
import { useLanguage } from '@/context/injectedValues/injectedValues'
import { useLocalisation } from '@/context/localisation/localisation'
import { useNotifications } from '@/context/notifications/notifications'
import { ProductUpsellContext } from '@/context/productUpsellModal/productUpsellModal'
import { ACCOUNT_ROUTES } from '@/routes'
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client'
import { Grid } from '@mui/material'
import { addMonths, differenceInDays, format, startOfMonth } from 'date-fns'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { settingsVar, userDataVar } from '@/services/apollo'

import { localeToDateLocale } from '@/utils/countryCodeHelper'
import * as Sentry from '@/utils/sentry'

import { useComponentDidMount } from '@/hooks/useComponentDidMount'
import useSkipPushFlag from '@/hooks/useSkipPushFlag'

import AddDeliveryNoteModal from './components/AddDeliveryNoteModal'
import ChangeCourierFooter from './components/ChangeCourierFooter'
import { DateConfirmationFooter } from './components/DateConfirmationFooter'
import { DefaultFooter } from './components/DefaultFooter'
import PushBoxModal from './components/PushBoxModal'
import SkipBoxModal from './components/SkipBoxModal'
import { CardSkeleton } from '@/components/elements/atoms/Card/CardSkeleton'
import Text from '@/components/elements/atoms/Text'
import CalendarV2 from '@/components/elements/molecules/CalendarV2'
import { CalendarDatesV2Fragment as CalendarDates } from '@/components/elements/molecules/CalendarV2/fragments/__generated__/calendarDatesV2Fragment'
import CourierSelector from '@/components/elements/molecules/CourierSelector/CourierSelector'
import { ProductUpsellAnalyticTypes } from '@/components/elements/organisms/ProductsUpsellModal/ProductsUpsellModal'
import { DELIVERY_DATE_V2_UPDATE } from '@/components/pages/ChangeDatePageV2/mutations/DeliveryDateV2Update'
import { DELIVERY_DATE_V2_QUERY } from '@/components/pages/ChangeDatePageV2/queries/DeliveryDateV2Query'

import STYLES from './ChangeDatePageV2.module.sass'

import { COURIER_DELIVERY_DATES_QUERY } from './queries/CourierDeliveryDatesQuery'

import { DeliveryDateV2Update } from './mutations/__generated__/DeliveryDateV2Update'
import {
  CourierDeliveryDatesQuery_user_subscription_nextNBoxes as Box,
  CourierDeliveryDatesQuery,
  CourierDeliveryDatesQueryVariables,
  CourierDeliveryDatesQuery_user_deliveryAreaOptions as DeliveryAreaOption
} from './queries/__generated__/CourierDeliveryDatesQuery'
import {
  DeliveryDateV2Query,
  DeliveryDateV2QueryVariables
} from './queries/__generated__/DeliveryDateV2Query'
import { Code } from '@/types'

/**
 * This page features a calendarV2 component which allows users to change the date of a delivery
 * @constructor
 */

const ChangeDatePageV2 = (): JSX.Element | null => {
  const { carrier_preferences_enabled } = useReactiveVar(settingsVar)
  const userData = useReactiveVar(userDataVar)
  const { boxId } = useParams()
  const navigate = useNavigate()
  const { setSuccessNotification, setErrorNotification } = useNotifications()
  const { normaliseTimezone } = useLocalisation()
  const { userLanguage } = useLanguage()
  const [initialised, setInitialised] = useState(false)
  const [searchParams] = useSearchParams()
  const { openProductsUpsellModal } = useContext(ProductUpsellContext)

  const [currentDeliveryDate, setCurrentDeliveryDate] = useState<Date>(
    new Date()
  )
  const [currentDeliveryDates, setCurrentDeliveryDates] = useState<Array<Date>>(
    []
  )
  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState<Date | null>(
    null
  )

  const [currentNextNBoxes, setCurrentNextNBoxes] = useState<Box[]>([])

  const [calendarDates, setCalendarDates] = useState<CalendarDates[]>([])

  const [showConfirmSkipModal, setConfirmSkipModal] = useState(false)
  const [showConfirmPushModal, setConfirmPushModal] = useState(false)

  const [currentPreferredCarrier, setCurrentPreferredCarrier] = useState<
    string | null
  >()
  const [deliveryAreaOptions, setDeliveryAreaOptions] = useState<
    DeliveryAreaOption[]
  >([])
  const [selectedDeliveryArea, setSelectedDeliveryArea] =
    useState<DeliveryAreaOption | null>(null)
  const [isAddDeliveryNoteModalOpen, setIsAddDeliveryNoteModalOpen] =
    useState(false)

  const { shouldSeeSkipEitherVersion: shouldSeeSkipBox, shouldSeePush } =
    useSkipPushFlag()

  const courierOnlyChange = useMemo(
    () =>
      !selectedDeliveryDate &&
      currentPreferredCarrier !== selectedDeliveryArea?.id,
    [currentPreferredCarrier, selectedDeliveryArea?.id, selectedDeliveryDate]
  )

  const today = new Date()
  const initialMonthInView = startOfMonth(today)
  const finalMonthInView = useMemo(() => {
    const finalMonth = addMonths(initialMonthInView, 4)
    finalMonth.setDate(1)
    return finalMonth
  }, [initialMonthInView])

  const nDays = useMemo(() => {
    return differenceInDays(finalMonthInView, initialMonthInView)
  }, [initialMonthInView, finalMonthInView])

  const [
    deliveryDatesQuery,
    {
      loading: deliveryDatesLoading,
      data: deliveryDatesData,
      error: deliveryDatesError
    }
  ] = useLazyQuery<DeliveryDateV2Query, DeliveryDateV2QueryVariables>(
    DELIVERY_DATE_V2_QUERY,
    {
      variables: {
        boxId: boxId || '',
        nextNBoxes: 3,
        calendarInitDate: initialMonthInView,
        nDays: nDays,
        userId: userData?.id,
        shouldAttemptToOfferNextDayDelivery: false
      },
      onError: () => {
        setErrorNotification({
          text: 'change_date.error.update',
          namespace: 'account'
        })
        navigate(ACCOUNT_ROUTES.base)
      }
    }
  )

  const [
    courierDeliveryDatesQuery,
    {
      loading: courierDeliveryDatesLoading,
      data: courierDeliveryDatesData,
      error: courierDeliveryDatesError
    }
  ] = useLazyQuery<
    CourierDeliveryDatesQuery,
    CourierDeliveryDatesQueryVariables
  >(COURIER_DELIVERY_DATES_QUERY, {
    variables: {
      userId: userData?.id,
      boxId: boxId || '',
      nextNBoxes: 3,
      calendarInitDate: initialMonthInView,
      nDays: nDays,
      shouldAttemptToOfferNextDayDelivery: false
    },
    onError: () => {
      setErrorNotification({
        text: 'change_date.error.update',
        namespace: 'account'
      })
      navigate(ACCOUNT_ROUTES.base)
    }
  })

  const data = deliveryDatesData || courierDeliveryDatesData
  const loading = deliveryDatesLoading || courierDeliveryDatesLoading
  const error = deliveryDatesError || courierDeliveryDatesError

  const updateDeliveryArea = useCallback(
    (id: string) => {
      const deliveryArea = deliveryAreaOptions?.find((dao) => dao.id === id)

      if (deliveryArea) {
        setSelectedDeliveryArea(deliveryArea)
        setCalendarDates(deliveryArea.calendarDates)
      }
    },
    [deliveryAreaOptions]
  )

  const handleCloseFooter = useCallback(() => {
    setSelectedDeliveryDate(null)
    if (currentPreferredCarrier) updateDeliveryArea(currentPreferredCarrier)
  }, [currentPreferredCarrier, updateDeliveryArea])

  const handleCloseDeliveryNoteModal = useCallback(async (): Promise<void> => {
    setIsAddDeliveryNoteModalOpen(false)
    if (currentPreferredCarrier) updateDeliveryArea(currentPreferredCarrier)
  }, [currentPreferredCarrier, updateDeliveryArea])

  const handleCloseCourierFooter = useCallback(() => {
    if (currentPreferredCarrier) updateDeliveryArea(currentPreferredCarrier)
    setIsAddDeliveryNoteModalOpen(true)
  }, [currentPreferredCarrier, updateDeliveryArea])

  const updateState = useCallback(
    (
      deliveryDate: Date,
      nextNBoxes: Array<Box>,
      calendarDates?: Array<CalendarDates>,
      deliveryAreas?: Array<DeliveryAreaOption>,
      currentDeliveryAreaPreferenceId?: string
    ) => {
      const deliveryDates = nextNBoxes.map(
        (box) => new Date(box.isoDeliveryDate)
      )

      setCurrentDeliveryDate(deliveryDate)
      setCurrentDeliveryDates(deliveryDates)
      setCurrentNextNBoxes(nextNBoxes)

      if (deliveryAreas && deliveryAreas.length > 1) {
        const currentDeliveryAreaPreference =
          deliveryAreas.find((deliveryAreaOption) => {
            return deliveryAreaOption.id === currentDeliveryAreaPreferenceId
          }) ?? deliveryAreas[0]
        setCurrentPreferredCarrier(currentDeliveryAreaPreferenceId)
        setSelectedDeliveryArea(currentDeliveryAreaPreference)
        setCalendarDates(currentDeliveryAreaPreference.calendarDates)
      } else if (calendarDates) {
        setCalendarDates(calendarDates)
      }
    },
    []
  )

  const [deliveryDateUpdate, { loading: processing }] = useMutation(
    DELIVERY_DATE_V2_UPDATE,
    {
      onError: () => {
        if (courierOnlyChange) {
          setErrorNotification({
            text: 'change_date.change_courier.error.update',
            namespace: 'account'
          })
        } else {
          setErrorNotification({
            text: 'change_date.error.update',
            namespace: 'account'
          })
        }
      },
      onCompleted: (deliveryDateUpdateData: DeliveryDateV2Update) => {
        const { boxDeliveryDateUpdate } = deliveryDateUpdateData
        if (!boxDeliveryDateUpdate) return
        const { subscription, currentDeliveryAreaPreference } =
          boxDeliveryDateUpdate
        const { box, nextNBoxes } = subscription

        setSelectedDeliveryDate(null)
        if (courierOnlyChange) {
          setSuccessNotification({
            text: 'change_date.change_courier.success',
            namespace: 'account'
          })
          handleCloseCourierFooter()
        } else {
          setSuccessNotification({
            text: 'change_date.success',
            namespace: 'account'
          })
        }

        if (
          currentDeliveryAreaPreference &&
          currentDeliveryAreaPreference.id !== currentPreferredCarrier
        ) {
          setIsAddDeliveryNoteModalOpen(true)
        } else {
          setTimeout((): void => {
            const showUpsellModal =
              !!data?.user.shippingCountry.showUpcomingOrderSeeMoreExtras &&
              !selectedDeliveryArea

            if (showUpsellModal) {
              const copyContext = 'products_upsell_modal'
              const shippingCountryCode =
                userData?.shippingCountry.code || Code.GB
              const locale = localeToDateLocale(
                shippingCountryCode,
                userLanguage || 'en'
              )

              const deliveryDate = box?.isoDeliveryDate

              openProductsUpsellModal({
                headerText: [
                  {
                    text: `${copyContext}.delivery_date_changed_modal.title`
                  },
                  {
                    text: `${copyContext}.delivery_date_changed_modal.title_information`,
                    variant: 'textRegular18',
                    colour: 'brandBlue400',
                    variables: {
                      deliveryDate: format(
                        new Date(deliveryDate),
                        'EEEE do MMMM',
                        {
                          locale
                        }
                      )
                    }
                  }
                ],
                analyticsType: ProductUpsellAnalyticTypes.deliveryDateChange
              })
            }
            navigate(ACCOUNT_ROUTES.base)
          }, 2000)
        }

        updateState(
          new Date(box?.isoDeliveryDate),
          nextNBoxes,
          undefined,
          deliveryAreaOptions,
          currentDeliveryAreaPreference?.id
        )
      }
    }
  )

  useComponentDidMount(() => {
    const shouldOpenModal = searchParams.get('openSkipModal')
    const shouldOpenPushModal = searchParams.get('openPushModal')

    if (shouldOpenModal) {
      setConfirmSkipModal(true)
    }

    if (shouldOpenPushModal) {
      setConfirmPushModal(true)
    }
  })

  useEffect(() => {
    if (carrier_preferences_enabled) {
      courierDeliveryDatesQuery()
    } else {
      deliveryDatesQuery()
    }
  }, [
    carrier_preferences_enabled,
    courierDeliveryDatesQuery,
    deliveryDatesQuery
  ])

  useEffect(() => {
    if (!initialised && courierDeliveryDatesData) {
      const { user } = courierDeliveryDatesData
      const {
        deliveryAreaOptions,
        subscription,
        currentDeliveryAreaPreference
      } = user
      const { box, nextNBoxes } = subscription

      const deliveryDate = new Date(box?.isoDeliveryDate)

      updateState(
        deliveryDate,
        nextNBoxes,
        courierDeliveryDatesData.calendarDates,
        deliveryAreaOptions,
        currentDeliveryAreaPreference?.id
      )

      setDeliveryAreaOptions(deliveryAreaOptions)

      setInitialised(true)
    } else if (!initialised && deliveryDatesData) {
      const { user } = deliveryDatesData
      const { subscription } = user
      const { box, nextNBoxes } = subscription

      const deliveryDate = new Date(box?.isoDeliveryDate)

      updateState(deliveryDate, nextNBoxes, deliveryDatesData.calendarDates)

      setInitialised(true)
    }
  }, [
    courierDeliveryDatesData,
    deliveryAreaOptions,
    deliveryDatesData,
    initialised,
    updateState
  ])
  /**
   * If user navigates to this page past cutoff then redirect them back
   */
  useEffect(() => {
    if (!loading && data && !data.user.subscription.box?.editable) {
      setErrorNotification({
        text: 'change_date.past_cutoff',
        namespace: 'account'
      })
      navigate(ACCOUNT_ROUTES.base)
    }
  }, [data, loading, navigate, setErrorNotification])

  /**
   * Handle if an invalid box id is passed
   */
  useEffect(() => {
    if (!isUndefined(error)) {
      setErrorNotification({
        text: 'change_date.error.get',
        namespace: 'account'
      })
      navigate(ACCOUNT_ROUTES.base)
    }
  }, [error, navigate, setErrorNotification])

  const updateDeliveryDate = useCallback(() => {
    if (
      !isUndefined(data) &&
      (!isNull(selectedDeliveryDate) || !isNull(selectedDeliveryArea)) &&
      !isNull(data.user.subscription.box)
    ) {
      const { user } = data
      const { id: userId, subscription } = user
      const { box } = subscription

      if (!isNull(box)) {
        const { id: boxId } = box
        if (selectedDeliveryDate || selectedDeliveryArea)
          deliveryDateUpdate({
            variables: {
              userId,
              boxId,
              selectedDeliveryDate: selectedDeliveryDate
                ? new Date(
                    normaliseTimezone(selectedDeliveryDate)
                  ).toISOString()
                : new Date(
                    normaliseTimezone(currentDeliveryDate)
                  ).toISOString(),
              deliveryAreaId: selectedDeliveryArea?.id,
              adjustFutureBoxDates: true,
              triggerEvents: true,
              nextNBoxes: 3
            }
          })
      }
    }
  }, [
    data,
    selectedDeliveryDate,
    selectedDeliveryArea,
    currentDeliveryDate,
    deliveryDateUpdate,
    normaliseTimezone
  ])

  const toggleConfirmSkip = useCallback(
    () => setConfirmSkipModal((prev) => !prev),
    []
  )

  const toggleConfirmPush = useCallback(
    () => setConfirmPushModal((prev) => !prev),
    []
  )

  const handleFooterButtonClick = useCallback(() => {
    if (shouldSeeSkipBox) {
      toggleConfirmSkip()
      return
    }

    if (shouldSeePush) {
      toggleConfirmPush()
    }
  }, [shouldSeePush, shouldSeeSkipBox, toggleConfirmPush, toggleConfirmSkip])

  const [firstUpcomginBox, secondUpcomingBox, thirdUpcomingBox] =
    currentNextNBoxes

  const skipToBox = firstUpcomginBox?.editable
    ? secondUpcomingBox
    : thirdUpcomingBox

  const pushToBox = firstUpcomginBox?.editable
    ? firstUpcomginBox
    : secondUpcomingBox

  const handleUpdateBoxDate = useCallback(
    async ({ newDate }) => {
      if (isUndefined(data)) return

      const { user } = data
      const { id: userId } = user

      await deliveryDateUpdate({
        variables: {
          userId,
          boxId,
          selectedDeliveryDate: newDate,
          deliveryAreaId: selectedDeliveryArea?.id,
          adjustFutureBoxDates: true,
          triggerEvents: true,
          nextNBoxes: 3
        }
      })
    },
    [boxId, data, deliveryDateUpdate, selectedDeliveryArea?.id]
  )

  const handleSkipBox = useCallback(async () => {
    if (!skipToBox) return

    try {
      await handleUpdateBoxDate({ newDate: skipToBox?.isoDeliveryDate })

      toggleConfirmSkip()
    } catch (error) {
      Sentry.captureException(`Error skipping box in calendar`, {
        extra: { error },
        tags: {
          product: Sentry.Product.Account,
          team: Sentry.Team.Retention
        }
      })
    }
  }, [handleUpdateBoxDate, skipToBox, toggleConfirmSkip])

  const handlePushClick = useCallback(
    async (newDate: Date) => {
      try {
        await handleUpdateBoxDate({ newDate })

        toggleConfirmPush()
      } catch (error) {
        Sentry.captureException(`Error pushing box in calendar`, {
          extra: { error },
          tags: {
            product: Sentry.Product.Account,
            team: Sentry.Team.Retention
          }
        })
      }
    },
    [handleUpdateBoxDate, toggleConfirmPush]
  )

  if (loading || !data) {
    return (
      <div className={STYLES.container}>
        <CardSkeleton height="43rem" />
      </div>
    )
  }

  const { user } = data
  const { shippingCountryCode, subscription } = user
  const { box } = subscription

  const locale = localeToDateLocale(shippingCountryCode, userLanguage || 'en')

  if (!box) return null

  const typeOfMargin = selectedDeliveryDate
    ? STYLES.confirmationFooterMargin
    : STYLES.footerMargin

  const nextBoxOutForDelivery = !firstUpcomginBox?.editable && firstUpcomginBox

  return (
    <div className={`${STYLES.container} ${typeOfMargin}`}>
      {/* grid needs to be width 100% */}
      <Grid container rowSpacing={3} direction="column">
        <Grid
          item
          alignSelf="center"
          className={STYLES.subtitle}
          width={'100%'}
        >
          <Text
            text="change_date.subtitle"
            namespace="account"
            align="center"
            variant="textRegular16"
            colour="brandBlue500"
            margin={false}
          />
          {carrier_preferences_enabled && selectedDeliveryArea && (
            <CourierSelector
              deliveryAreaOptions={deliveryAreaOptions}
              selectedCarrier={selectedDeliveryArea}
              selectedDate={selectedDeliveryDate ?? currentDeliveryDate}
              setSelectedCarrier={setSelectedDeliveryArea}
              setSelectedDate={setSelectedDeliveryDate}
              setCalendarDates={setCalendarDates}
            />
          )}
        </Grid>
        <Grid item>
          <CalendarV2
            selectedDate={selectedDeliveryDate}
            calendarDates={calendarDates}
            currentDeliveryDate={currentDeliveryDate}
            currentDeliveryDates={currentDeliveryDates}
            shippingCountryCode={shippingCountryCode}
            setSelectedDate={setSelectedDeliveryDate}
            maxMonthsShown={4}
            scrollToSelectedDate
          />
        </Grid>
        {selectedDeliveryDate && (
          <DateConfirmationFooter
            selectedDeliveryDate={selectedDeliveryDate}
            locale={locale}
            processing={processing}
            handleCloseFooter={handleCloseFooter}
            updateDeliveryDate={updateDeliveryDate}
            dropShadow
          />
        )}
        {courierOnlyChange && (
          <ChangeCourierFooter
            selectedCourier={
              selectedDeliveryArea?.preferredCarrierService.carrier.name ?? ''
            }
            onClosed={handleCloseCourierFooter}
            onPress={updateDeliveryDate}
            updating={processing}
          />
        )}
        {isAddDeliveryNoteModalOpen && (
          <AddDeliveryNoteModal
            onClosed={handleCloseDeliveryNoteModal}
            shouldShowModal={isAddDeliveryNoteModalOpen}
          />
        )}
        {!selectedDeliveryDate && !courierOnlyChange && (
          <DefaultFooter
            currentDeliveryDate={currentDeliveryDate}
            locale={locale}
            courierName={
              selectedDeliveryArea?.preferredCarrierService.carrier.name
            }
            onButtonClick={handleFooterButtonClick}
            showButton={shouldSeePush || shouldSeeSkipBox}
            buttonIdentifier={
              shouldSeePush
                ? 'calendar_push_box_modal_toggle'
                : 'calendar_skip_box_modal_toggle'
            }
            buttonText={
              shouldSeePush
                ? 'change_date.push_box.title'
                : 'change_date.skip_box.button'
            }
          />
        )}
      </Grid>

      {showConfirmSkipModal && (
        <SkipBoxModal
          box={skipToBox}
          nextBoxOutForDelivery={nextBoxOutForDelivery}
          currentDeliveryDate={currentDeliveryDate}
          showConfirmSkipModal={showConfirmSkipModal}
          toggleConfirmSkip={toggleConfirmSkip}
          locale={locale}
          handleSkipBox={handleSkipBox}
          loading={processing}
        />
      )}

      {showConfirmPushModal && (
        <PushBoxModal
          calendarDates={calendarDates}
          nextBoxOutForDelivery={nextBoxOutForDelivery}
          nextBoxDate={pushToBox?.isoDeliveryDate}
          showConfirmPushModal={showConfirmPushModal}
          toggleConfirmPush={toggleConfirmPush}
          locale={locale}
          onPushClick={handlePushClick}
          loading={processing}
        />
      )}
    </div>
  )
}

export default ChangeDatePageV2
