import { gql } from '@apollo/client'

const PENDING_SUBSCRIPTION_ORDER_PRICING_QUERY = gql`
  query PendingSubscriptionOrderPricingQuery(
    $planId: ID!
    $productVariantIds: [ID!]!
    $flavourQuantities: MealInput!
    $discountCodeId: ID
  ) {
    pendingSubscriptionOrderPrices(
      planId: $planId
      productVariantIds: $productVariantIds
      flavourQuantities: $flavourQuantities
      discountCodeId: $discountCodeId
    ) {
      standardOrderCoreFoodGrossPricePerDay
    }
  }
`

export default PENDING_SUBSCRIPTION_ORDER_PRICING_QUERY
