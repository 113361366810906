import { gql } from '@apollo/client'

const CREATE_DOG_PROFILE_MUTATION = gql`
  mutation CreateDogProfileMutation($userId: ID!, $dogIdsInput: [ID!]!) {
    dogProfilesCreate(userId: $userId, dogIdsInput: $dogIdsInput) {
      id
    }
  }
`

export { CREATE_DOG_PROFILE_MUTATION }
