// @noflow
import React from 'react'

import {
  ICON_COLOURS,
  IconColours
} from '@/components/elements/atoms/Icon/Icon'

type Props = {
  size: number
  accentColour: IconColours
  width?: number | string
}

const Bin = ({ size, accentColour, width }: Props): JSX.Element => {
  return (
    <svg
      fill="none"
      height={size}
      viewBox="0 0 24 27"
      width={width || '100%'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m8.72728 21.2727c0 .3069-.23864.5455-.54546.5455h-1.0909c-.30682 0-.54547-.2386-.54547-.5455v-11.99997c0-.30682.23865-.54545.54547-.54545h1.0909c.30682 0 .54546.23863.54546.54545zm4.36362 0c0 .3069-.2386.5455-.5454.5455h-1.091c-.3068 0-.5454-.2386-.5454-.5455v-11.99997c0-.30682.2386-.54545.5454-.54545h1.091c.3068 0 .5454.23863.5454.54545zm4.3637 0c0 .3069-.2387.5455-.5455.5455h-1.0909c-.3068 0-.5455-.2386-.5455-.5455v-11.99997c0-.30682.2387-.54545.5455-.54545h1.0909c.3068 0 .5455.23863.5455.54545zm-9.27278-16.90905.83523-1.99432c.05113-.06819.1875-.17046.28977-.1875h5.40338c.0853.01704.2387.11931.2898.1875l.8182 1.99432zm15.81818.54545c0-.30682-.2386-.54545-.5455-.54545h-5.267l-1.1932-2.8466c-.3409-.83523-1.3636-1.51705-2.267-1.51705h-5.45457c-.90341 0-1.92613.68182-2.26704 1.51705l-1.19319 2.8466h-5.267049c-.306818 0-.545451.23863-.545451.54545v1.09091c0 .30682.238633.54545.545451.54545h1.636369v16.22724c0 1.875 1.22728 3.4091 2.72728 3.4091h14.1818c1.5 0 2.7273-1.6022 2.7273-3.4772v-16.15914h1.6363c.3069 0 .5455-.23863.5455-.54545z"
        fill={ICON_COLOURS[accentColour]}
      />
    </svg>
  )
}

export default Bin
