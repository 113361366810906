// @noflow
import { intervalToDuration, isFuture } from 'date-fns'
import React, { Fragment, useEffect, useState } from 'react'

import * as Sentry from '@/utils/sentry'

import Text, { AllowedColours } from '@/components/elements/atoms/Text/Text'

import STYLES from './CountdownOneLine.module.sass'

type Props = {
  targetDate: string | Date | undefined
  labels: {
    daysLabel: string
    hoursLabel: string
    minutesLabel: string
    secondsLabel: string
  }
  countdownType?: 'dailyCountdown' | 'weeklyCountdown'
  shouldScale?: boolean
  textColour?: AllowedColours
  namespace: string
}

// Converts single digit numbers into to digits with a leading 0 ('01', '05', '10')
// And then turns each digit into an array ['0', '1']
const intervalNumberToArray = (num: number | undefined): Array<string> => {
  if (num !== undefined) {
    return num.toString().padStart(2, '0').split('')
  } else {
    Sentry.captureException('num given to intervalNumberToArray is undefined')
    return ['0', '0']
  }
}

const CountdownOneLine = ({
  targetDate,
  labels,
  countdownType = 'dailyCountdown',
  shouldScale,
  textColour,
  namespace
}: Props): JSX.Element | null => {
  const [currentDate, setCurrentDate] = useState(new Date())

  const [countdownDate, setCountdownDate] = useState(new Date())

  useEffect(() => {
    if (isFuture(countdownDate)) {
      const tick = setInterval(() => {
        setCurrentDate(new Date())
      }, 1000)

      return () => clearInterval(tick)
    }
  })

  useEffect(() => {
    if (!targetDate) return
    const date = new Date(targetDate)
    setCountdownDate(date)
  }, [targetDate])

  if (!targetDate) return null

  const interval = intervalToDuration({
    start: currentDate,
    end: countdownDate
  })

  const zeroDays = interval.days === 0

  const allCountdownItems = [
    {
      label: labels.daysLabel,
      value: intervalNumberToArray(interval.days),
      plural: interval.days
    },
    {
      label: labels.hoursLabel,
      value: intervalNumberToArray(interval.hours),
      plural: interval.hours
    },
    {
      label: labels.minutesLabel,
      value: intervalNumberToArray(interval.minutes),
      plural: interval.minutes
    },
    {
      label: labels.secondsLabel,
      value: intervalNumberToArray(interval.seconds),
      plural: interval.seconds
    }
  ]
  const countdownItems = zeroDays
    ? allCountdownItems.slice(1, 4)
    : allCountdownItems.slice(0, 3)

  const isWeeklyCountdown = countdownType === 'weeklyCountdown'

  return (
    <div
      className={`
      ${STYLES.countdown}
      ${countdownType ? STYLES[countdownType] : ''}
      ${STYLES.small}`}
    >
      {countdownItems.map((item, index) => (
        <Fragment key={item.label}>
          <div
            className={`
            ${STYLES.countdownItem}`}
          >
            <div className={STYLES.countdownItemDigits}>
              {item.value.map((value, index) => (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${value}-${index}`}
                  className={STYLES.countdownItemDigit}
                >
                  <Text
                    text={value}
                    margin={false}
                    variant="textRegular14"
                    translate={false}
                    colour="brandBlue500"
                    bold
                    shouldScale={shouldScale}
                  />
                </div>
              ))}
            </div>
            <div className={STYLES.countdownItemLabel}>
              <Text
                text={item.label}
                margin={false}
                variant="textRegular12"
                colour="brandBlue400"
                shouldScale={shouldScale ?? isWeeklyCountdown}
                namespace={namespace}
                variables={{ count: item.plural === 0 ? 1 : item.plural }}
              />
            </div>
          </div>
          {index !== countdownItems.length - 1 && !isWeeklyCountdown && (
            <div className={STYLES.countdownItem}>
              <Text
                text=":"
                margin={false}
                variant="textRegular14"
                translate={false}
                colour={textColour || 'brandWhite'}
                bold
                shouldScale={shouldScale}
              />
            </div>
          )}
        </Fragment>
      ))}
    </div>
  )
}

export type { Props }
export default CountdownOneLine
export { intervalNumberToArray }
