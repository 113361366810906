// @noflow
import { IdealBankElement } from '@stripe/react-stripe-js'
import React, { useEffect } from 'react'

import BRAND_COLOURS from '@/constants/BrandColours'

import dogsMeeting from 'assets/images/illustrations/dogs/dogs-meeting.svg'

import LoadingScreen from '@/components/elements/organisms/LoadingScreen/LoadingScreen'
import { setSubmissionState } from '@/components/pages/CheckoutPage/components/PaymentSection/PaymentSection'

import STYLES from '../PaymentOptions/PaymentOptions.module.sass'

import { Code } from '@/types'

import { State } from '../../PayOnOwnDevicePage'
import useBuySubscriptionWithIdeal from '../../hooks/stripe/sepa_direct_debit/useBuySubscriptionWithIdeal'
import {
  ActivePaymentViews,
  LoginUrlProps
} from '../../screens/PaymentScreen/PaymentScreen'
import { SubmissionState } from '../../types'
import PlaceMyOrderDisclaimer from '../Disclaimer/PlaceMyOrderDisclaimer'

const IdealView = ({
  paymentPageState,
  submissionState,
  setActivePaymentView,
  navigateToNextStep,
  namespace
}: {
  paymentPageState: State
  submissionState: SubmissionState
  setActivePaymentView: (paymentType: ActivePaymentViews) => void
  navigateToNextStep: (input: LoginUrlProps) => void
  namespace: string
}): JSX.Element => {
  const { iDealSubscriptionCallback, idealAuthReceived } =
    useBuySubscriptionWithIdeal({
      paymentPageState,
      setActivePaymentView,
      navigateToNextStep
    })

  useEffect(() => {
    if (idealAuthReceived) {
      setSubmissionState({ type: 'loading' })
    }
  }, [idealAuthReceived])

  return (
    <div className={STYLES.container}>
      {submissionState.type === 'loading' && (
        <div>
          <LoadingScreen
            isOpen={submissionState.type === 'loading'}
            title={{
              namespace: namespace,
              text: 'loading_screen.text',
              variant: 'display20'
            }}
            variant={'static'}
            image={dogsMeeting}
          />
        </div>
      )}
      <div className={STYLES.contentWrapper}>
        <div className={`${STYLES.buttonWrapper} ${STYLES.idealViewPadding}`}>
          <IdealBankElement
            onChange={iDealSubscriptionCallback}
            options={{
              style: {
                base: {
                  padding: '5px 12px',
                  color: BRAND_COLOURS.brandBlue500,
                  fontFamily:
                    'gt-pressura-regular, helvetica, arial, sans-serif',
                  fontSize: '18px',
                  '::placeholder': {
                    color: BRAND_COLOURS.brandBlue500
                  }
                }
              }
            }}
          />
        </div>
        <PlaceMyOrderDisclaimer
          namespace={namespace}
          shippingCountryCode={
            paymentPageState?.data.directSalesPendingSubscription.address
              ?.shippingCountry.code || Code.GB
          }
        />
      </div>
    </div>
  )
}

export default IdealView
