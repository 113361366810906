// @noflow
import React, { useCallback } from 'react'

import { UPDATED_DELIVERY_DETAILS } from '@/constants/RibbonEvents'

import AlertCard from '@/components/elements/atoms/Alert/AlertCard'
import { Button } from '@/components/elements/atoms/Button'
import MultiLineTextField from '@/components/elements/atoms/MultiLineTextField/MultiLineTextField'
import Text from '@/components/elements/atoms/Text/Text'
import TextField from '@/components/elements/atoms/TextField/TextField'
import Form from '@/components/elements/molecules/Form/Form'

import STYLES from './ChangeAddress.module.sass'

import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'

type Address = {
  recipientName: string
  address1: string
  address2: string | null
  city: string
  postcode: string
  deliveryNotes: string | null
  deliveryCarrier: string | null
}

type Props = {
  address: Address
  onChange: (data: Address) => void
  toggleModal: () => void
  shippingCountryCode: CountryCode
  showExtraFeeCopy?: boolean
}

const ChangeAddress = ({
  address,
  onChange,
  toggleModal,
  shippingCountryCode,
  showExtraFeeCopy = true
}: Props): JSX.Element | null => {
  const nameSpace = 'text_field'
  const updateAddress = useCallback(
    (data): void => {
      onChange(data)
      toggleModal()
      window.ribbon && window.ribbon('trigger', UPDATED_DELIVERY_DETAILS)
    },
    [onChange, toggleModal]
  )

  const chooseCarrierDeliveryNoteLimit = React.useCallback(() => {
    switch (address.deliveryCarrier) {
      case 'Goodspeed':
        return 80
      case 'Poland':
        return 30
      default:
        return 250
    }
  }, [address.deliveryCarrier])

  return (
    <div className={STYLES.container}>
      <Form onSubmit={updateAddress}>
        <div className={STYLES.title}>
          <Text
            variant="display24"
            namespace={nameSpace}
            text="change_address_form.title"
            margin={false}
          />
        </div>
        <TextField label="recipientName" initialValue={address.recipientName} />
        <TextField label="address1" initialValue={address.address1} />
        <TextField
          label="address2"
          initialValue={address.address2 as string}
          optional
        />
        <TextField label="city" initialValue={address.city} />
        <TextField
          label="postcode"
          type="postcode"
          initialValue={address.postcode}
          shippingCountryCode={shippingCountryCode}
        />
        <MultiLineTextField
          label="deliveryNotes"
          alwaysShowLabel
          rows={4}
          maxLength={chooseCarrierDeliveryNoteLimit()}
          initialValue={address?.deliveryNotes}
          optional
        />
        {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
        <>
          {showExtraFeeCopy && (
            <div className={STYLES.alertCard}>
              <AlertCard
                message={{
                  namespace: nameSpace,
                  text: 'change_address_form.info',
                  margin: false
                }}
                variant="info"
              />
            </div>
          )}
        </>
        <Button
          typography={{
            namespace: nameSpace,
            text: 'change_address_form.button_copy'
          }}
          disableAnalytics
          size="large"
          variant="secondary"
        />
      </Form>
    </div>
  )
}

export type { Props, Address }

export default ChangeAddress
