/* eslint-disable i18next/no-literal-string */
// @noflow
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import FlatButton from '@/components/elements/atoms/FlatButton/FlatButton'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './Actions.module.sass'

import { Status } from '@/types'

import { useTransformedDeliveryStatus } from '../../hooks/useTransformedDeliveryStatus'

type Props = {
  status: Status
  dogsDetails: {
    joinedDogsNames: string
    joinedPossessiveDogsNames: string
    pronounContext: string
  } | null
  boxId: string
  deliveryState: string
  setDeliveryState: (state: string) => void
}

const Actions = ({
  status,
  dogsDetails,
  deliveryState,
  boxId,
  setDeliveryState
}: Props): JSX.Element | null => {
  const { t } = useTranslation('organisms')
  const copyContext = 'delivery_tracking.actions'
  const { completedWithSuccess, deliveryProblem, preparing } =
    useTransformedDeliveryStatus(status ?? Status.unknown)

  const [buttonsVisibility, setButtonsVisibility] = useState({
    buttonOne: false,
    buttonTwo: false
  })

  const buttonOneClick = useCallback(() => {
    switch (deliveryState) {
      case 'delivery_feedback': {
        setDeliveryState('delivered_problem')
        setButtonsVisibility({ buttonOne: true, buttonTwo: false })
        break
      }
      case 'delivered_problem': {
        window.location.href = `/self-resolution?box_id=${boxId}`
        break
      }
      case 'contact_cl': {
        window.location.href = '/contact'
        break
      }
      default: {
        return null
      }
    }
  }, [deliveryState, setDeliveryState, boxId])

  const buttonTwoClick = useCallback(() => {
    if (deliveryState === 'delivery_feedback') {
      setDeliveryState('delivered_success')
      setButtonsVisibility({ buttonOne: false, buttonTwo: false })
    }
  }, [deliveryState, setDeliveryState])

  useEffect(() => {
    // Set the buttons visibility
    if (deliveryState === 'delivered_success') {
      setButtonsVisibility({ buttonOne: false, buttonTwo: false })
    } else if (deliveryState === 'delivered_problem' || deliveryProblem) {
      setButtonsVisibility({ buttonOne: true, buttonTwo: false })
    } else if (completedWithSuccess) {
      setButtonsVisibility({ buttonOne: true, buttonTwo: true })
    }

    // Set the delivery state for actions
    if (!['delivered_problem', 'delivered_success'].includes(deliveryState)) {
      if (deliveryProblem) {
        setDeliveryState('contact_cl')
      } else if (completedWithSuccess) {
        setDeliveryState('delivery_feedback')
      }
    }
  }, [
    deliveryState,
    setDeliveryState,
    status,
    preparing,
    completedWithSuccess,
    deliveryProblem
  ])

  const getIdentifier = useCallback(
    (type): string => {
      if (deliveryState) {
        const identifierContext = 'delivery_tracking.footer'

        const mappings = {
          delivery_feedback: `delivery_feedback.${type}`,
          delivered_problem: 'delivered_problem.self_serve_opened',
          contact_cl: 'contacted_cl'
        }

        const mappedValue =
          mappings[deliveryState as keyof typeof mappings] ?? deliveryState

        return `${identifierContext}.${mappedValue}`
      } else {
        return `delivery_tracking.footer.${type}`
      }
    },
    [deliveryState]
  )

  const buttonOneIdentifier = getIdentifier('negative')
  const buttonTwoIdentifier = getIdentifier('positive')

  return (
    <div className={STYLES.wrapper}>
      {!!deliveryState && (
        <Text
          text={`${copyContext}.${deliveryState}.prompt`}
          namespace="organisms"
          variables={{
            context: dogsDetails?.pronounContext,
            dogName: dogsDetails?.joinedDogsNames
          }}
          align="center"
          margin={false}
        />
      )}
      <div className={STYLES.buttons}>
        {buttonsVisibility.buttonOne && (
          <FlatButton
            onClick={buttonOneClick}
            variant="yellow200"
            identifier={buttonOneIdentifier}
            {...(deliveryState === 'delivery_feedback'
              ? {
                  icon: {
                    slug: 'thumbs-down',
                    image: {
                      height: 24,
                      width: 24
                    },
                    alt: t(`${copyContext}.thumbs_down_alt`)
                  }
                }
              : {
                  text: {
                    text: `${copyContext}.${deliveryState}.button1`,
                    namespace: 'organisms',
                    translate: true
                  }
                })}
          />
        )}
        {buttonsVisibility.buttonTwo && (
          <FlatButton
            onClick={buttonTwoClick}
            variant="yellow200"
            identifier={buttonTwoIdentifier}
            {...(deliveryState === 'delivery_feedback'
              ? {
                  icon: {
                    slug: 'thumbs-up',
                    image: {
                      height: 24,
                      width: 24
                    },
                    alt: t(`${copyContext}.thumbs_up_alt`)
                  }
                }
              : {
                  text: {
                    text: `${copyContext}.${deliveryState}.button2`,
                    namespace: 'organisms',
                    translate: true
                  }
                })}
          />
        )}
      </div>
    </div>
  )
}

export { Actions }
