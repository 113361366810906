// @noflow

/* eslint-disable i18next/no-literal-string */
import { Factory } from 'fishery'
import random from 'lodash/random'

export type FetchifyAddress = {
  result: {
    line_1: string
    line_2: string
    province_name: string
    locality: string
    postal_code: string
  }
  address: string
  postcode: string
}
const londonPostcode = `W${random(1, 12)} 7FP`

const FetchifyAddressFactory = Factory.define<FetchifyAddress>(() => ({
  result: {
    line_1: 'Address Line 1',
    line_2: 'Address Line 2',
    locality: 'Locality',
    province_name: 'Province',
    postal_code: londonPostcode
  },
  address: 'London address',
  postcode: londonPostcode
}))

export default FetchifyAddressFactory
