import React from 'react'

import Image from '@/components/elements/atoms/Image'

import { PaymentMethod } from '../../../types'

const paymentMethodToIcons = (
  method: PaymentMethod,
  t: (key: string) => string
): JSX.Element => {
  const copyContext = 'payment_section.payment_options_view'

  switch (method) {
    case 'creditCard':
      return (
        <div className="card-logos">
          <Image
            slug={'visa-logo'}
            image={{
              height: 24,
              width: 32
            }}
            alt={t(`${copyContext}.visa_alt`)}
          />
          <Image
            slug={'amex-logo'}
            image={{
              height: 24,
              width: 32
            }}
            alt={t(`${copyContext}.amex_alt`)}
          />
          <Image
            slug={'mastercard-logo'}
            image={{
              height: 24,
              width: 32
            }}
            alt={t(`${copyContext}.mastercard_alt`)}
          />
        </div>
      )
    case 'applePay':
      return (
        <Image
          slug={'apple-pay-logo'}
          image={{
            height: 24,
            width: 32
          }}
          alt={t(`${copyContext}.apple_pay_alt`)}
        />
      )
    case 'googlePay':
      return (
        <Image
          slug={'google-pay-logo'}
          image={{
            height: 24,
            width: 32
          }}
          alt={t(`${copyContext}.google_pay_alt`)}
        />
      )

    case 'payPal':
      return (
        <Image
          slug={'paypal-yellow-logo'}
          image={{
            height: 24,
            width: 32
          }}
          alt={t(`${copyContext}.paypal_alt`)}
        />
      )
    case 'bancontact':
      return (
        <Image
          slug={'bancontact-logo'}
          image={{
            height: 24,
            width: 32
          }}
          alt={t(`${copyContext}.bancontact_alt`)}
        />
      )
    case 'iDeal':
      return (
        <Image
          slug={'ideal-logo'}
          image={{
            height: 24,
            width: 32
          }}
          alt={t(`${copyContext}.ideal_alt`)}
        />
      )
    default:
      return <div />
  }
}

export default paymentMethodToIcons
