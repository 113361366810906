// @noflow
import type { GraphQLErrorInstance } from '@/redux/graphql_queries/errorTypes'
import { captureException } from '@sentry/browser'
import type { StripeCardElement, StripeElements } from '@stripe/stripe-js'
import { Stripe } from '@stripe/stripe-js'

import { trackEvent } from '@/services/segment'

import segmentTrack from '@/components/analytics/Analytics'

import { CREATE_PAYMENT_METHOD_MUTATION_STRING } from '../mutations/CreatePaymentMethod'

type PaymentMethod = { lastFourDigits: string }

type CreditCardsCreateResponse = {
  data: {
    creditCardsCreate: {
      subscription: {
        paymentMethods: Array<PaymentMethod>
        activePaymentMethodType:
          | 'CreditCard'
          | 'BillingAgreement'
          | 'SepaDirectDebit'
      }
    }
  }
  errors?: Array<GraphQLErrorInstance>
}

type CardAuthenticationStatus =
  | 'requires_payment_method'
  | 'requires_confirmation'
  | 'requires_action'
  | 'processing'
  | 'canceled'
  | 'succeeded'

type SCAResponse = {
  card_authentication_setup: {
    client_secret: string
    customer_id: string
    id: string
    status: CardAuthenticationStatus
  }
}

const requestFromSCAController = (
  email: string,
  csrfToken: string,
  authenticationType: 'card' | 'ideal' | 'bancontact',
  token: string
): Promise<SCAResponse> => {
  const endpoint =
    '/api/payment_methods/credit_cards/strong_customer_authentication/create'

  return fetch(`${endpoint}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': csrfToken
    },
    credentials: 'same-origin',
    body: JSON.stringify({
      email,
      csrf_token: csrfToken,
      authentication_type: authenticationType,
      token
    })
  })
    .then((response: Response): Promise<SCAResponse> => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error(
          `Error requesting from SCA controller: ${response.statusText}`
        )
      }
    })
    .catch((err: Error) => {
      throw new Error(`Error requesting from SCA controller: ${err}`)
    })
}

const confirmCardSetupWithoutSCA = async (
  stripe: Stripe,
  card: StripeCardElement,
  isActive: boolean,
  userID: number,
  endpoint: string,
  headers: {
    Accept: string
    'Content-Type': string
    'X-CSRF-Token': string
  },
  method: string,
  graphQLQuery: string,
  credentials: 'same-origin' | 'omit',
  successHandler: () => void,
  errorHandler: (arg0: string) => void
) => {
  const { error, paymentMethod } = await stripe.createPaymentMethod({
    type: 'card',
    card: card
  })
  if (error?.message) {
    errorHandler(error.message)
  } else {
    if (paymentMethod) {
      const body = JSON.stringify({
        query: graphQLQuery,
        variables: {
          userId: userID,
          paymentMethods: [
            {
              paymentMethodId: paymentMethod.id,
              active: isActive,
              paymentMethodType: 'credit_card',
              paymentProvider: 'stripe'
            }
          ]
        }
      })
      fetch(endpoint, { headers, method, credentials, body }).then(
        (res: Response): void => {
          if (res && res.ok) {
            res.json().then(({ errors }: CreditCardsCreateResponse): void => {
              if (!errors) {
                successHandler()
              } else {
                errorHandler(errors[0].message)
              }
            })
          } else {
            res.json().then(({ errors }: CreditCardsCreateResponse): void => {
              if (errors) {
                errorHandler(errors[0].message)
              }
            })
          }
        }
      )
    } else
      throw new Error('Payment method could not be set through no payment page')
  }
}

const confirmCardSetupWithSCA = (
  stripe: Stripe,
  card: StripeCardElement,
  isActive: boolean,
  userID: number,
  endpoint: string,
  headers: {
    Accept: string
    'Content-Type': string
    'X-CSRF-Token': string
  },
  method: string,
  graphQLQuery: string,
  credentials: 'same-origin' | 'omit',
  successHandler: () => void,
  errorHandler: (arg: string) => void,
  email: string,
  csrfToken: string,
  authenticationType: 'card',
  userToken: string
): void => {
  requestFromSCAController(
    encodeURIComponent(email),
    csrfToken,
    authenticationType,
    userToken
  ).then((res): void => {
    stripe
      .confirmCardSetup(res.card_authentication_setup.client_secret, {
        payment_method: { card: card }
      })
      .then((result) => {
        if (result.error) {
          errorHandler(
            result.error.code ||
              'Card setup with SCA: Error retrieving client setup intent'
          )
        } else if (result.setupIntent?.payment_method) {
          const body = JSON.stringify({
            query: graphQLQuery,
            variables: {
              userId: userID,
              paymentMethods: [
                {
                  paymentMethodId: result.setupIntent.payment_method,
                  active: isActive,
                  paymentMethodType: 'credit_card',
                  paymentProvider: 'stripe'
                }
              ]
            }
          })
          return fetch(endpoint, { headers, method, credentials, body })
        }
      })
      .then((res) => {
        if (res && res.ok) {
          res.json().then(({ errors }: CreditCardsCreateResponse): void => {
            if (!errors) {
              successHandler()
            } else {
              errorHandler(errors[0].message)
            }
          })
        } else {
          res?.json().then(({ errors }: CreditCardsCreateResponse): void => {
            if (errors) {
              errorHandler(errors[0].message)
            }
          })
        }
      })
  })
}

const addBancontactPaymentMethod = (
  email: string,
  csrfToken: string,
  token: string,
  stripe: Stripe,
  name: string,
  redirectUrl: string,
  successHandler: () => void,
  errorHandler: (arg: string) => void
): Promise<Response> => {
  return new Promise((): void => {
    trackEvent('Creating new Payment method', {
      component_identifier:
        'AddPaymentMethodPage_CreatePaymentMethod_Bancontact_create'
    })
    requestFromSCAController(
      encodeURIComponent(email),
      csrfToken,
      'bancontact',
      token
    ).then((res) => {
      stripe
        .confirmBancontactSetup(res.card_authentication_setup.client_secret, {
          payment_method: {
            billing_details: {
              name,
              email
            }
          },
          return_url: redirectUrl
        })
        .then((result) => {
          segmentTrack('Bancontact redirection initialised')
          if (result.error) {
            captureException(
              `Error confirming Bancontact Setup in My Details: ${result.error}`
            )
            errorHandler(result.error.message || '')
          } else {
            successHandler()
          }
        })
    })
  })
}

const addIdealPaymentMethod = (
  email: string,
  csrfToken: string,
  token: string,
  stripe: Stripe,
  idealBank: string,
  name: string,
  redirectUrl: string,
  successHandler: () => void,
  errorHandler: (arg: string) => void
): Promise<Response> => {
  return new Promise((): void => {
    trackEvent('Creating new Payment method', {
      component_identifier:
        'AddPaymentMethodPage_CreatePaymentMethod_Ideal_create'
    })
    requestFromSCAController(
      encodeURIComponent(email),
      csrfToken,
      'ideal',
      token
    ).then((res) => {
      stripe
        .confirmIdealSetup(res.card_authentication_setup.client_secret, {
          payment_method: {
            ideal: {
              bank: idealBank
            },
            billing_details: {
              name,
              email
            }
          },
          return_url: redirectUrl
        })
        .then((result) => {
          segmentTrack('iDeal redirection initialised')
          if (result.error) {
            captureException(
              `Error confirming Ideal Setup in My Details: ${result.error}`
            )
            errorHandler(result.error.message || '')
          } else {
            successHandler()
          }
        })
    })
  })
}

const updateCreditCard = (
  stripe: Stripe,
  elements: StripeElements,
  isActive: boolean,
  successHandler: () => void,
  errorHandler: (arg: string) => void,
  userID: number,
  csrfToken: string,
  email: string,
  authenticationType: 'card',
  userToken: string
): Promise<Response> => {
  // Ensure that the userID is a number
  if (typeof userID !== 'number') {
    throw new Error(`User ID (${userID}) should be of type number`)
  }
  const endpoint = '/graphql'

  const graphQLQuery = CREATE_PAYMENT_METHOD_MUTATION_STRING
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  }
  const method = 'POST'
  const credentials = 'same-origin'

  const card = elements && elements.getElement('card')
  if (!card)
    throw new Error('could not find card element at No Payment Method Page')
  return new Promise((): void => {
    if (email) {
      trackEvent('Creating new Payment method', {
        component_identifier:
          'AddPaymentMethodPage_CreatePaymentMethod_CreditCardWithSCA_create'
      })
      confirmCardSetupWithSCA(
        stripe,
        card,
        isActive,
        userID,
        endpoint,
        headers,
        method,
        graphQLQuery,
        credentials,
        successHandler,
        errorHandler,
        email,
        csrfToken,
        authenticationType,
        userToken
      )
    } else {
      trackEvent('Creating new Payment method', {
        component_identifier:
          'AddPaymentMethodPage_CreatePaymentMethod_CreditCardWithoutSCA_create'
      })
      confirmCardSetupWithoutSCA(
        stripe,
        card,
        isActive,
        userID,
        endpoint,
        headers,
        method,
        graphQLQuery,
        credentials,
        successHandler,
        errorHandler
      )
    }
  })
}

export { updateCreditCard, addIdealPaymentMethod, addBancontactPaymentMethod }
