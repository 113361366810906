import { useReactiveVar } from '@apollo/client'
import React, { useEffect, useMemo, useState } from 'react'

import DogCelebrating from 'assets/images/illustrations/dogs/dog-celebrating.svg'

import Text from '@/components/elements/atoms/Text/Text'

import STYLES from '../ResolutionConfirmation.module.sass'

import { customerIssueManagementState } from '../../../CustomerIssueManagementPage'
import useCustomerIssueManagementTracking from '../../../analytics'

type Props = {
  resolutionType: string
  dogNames: string
}

const Default = ({ resolutionType, dogNames }: Props): JSX.Element => {
  const customerIssueManagementData = useReactiveVar(
    customerIssueManagementState
  )
  const customerIssueManagementTracking = useCustomerIssueManagementTracking()
  const [initialised, setInitialised] = useState(false)

  const defaultText = useMemo(() => {
    return [
      // eslint-disable-next-line i18next/no-literal-string
      `resolutionConfirmation.${resolutionType}.${customerIssueManagementData.selectedCategory?.key}.text`,
      // eslint-disable-next-line i18next/no-literal-string
      `resolutionConfirmation.${resolutionType}.text`,
      'resolutionConfirmation.default.text'
    ]
  }, [resolutionType, customerIssueManagementData.selectedCategory?.key])

  useEffect(() => {
    if (!initialised) {
      customerIssueManagementTracking.stepLoaded(
        'Default confirmation',
        'Resolution confirmation'
      )
      setInitialised(true)
    }
  }, [
    initialised,
    customerIssueManagementData,
    customerIssueManagementTracking
  ])

  return (
    <>
      <div className={STYLES.imgContainer}>
        <img alt="" src={DogCelebrating} />
      </div>
      <Text
        namespace={customerIssueManagementData.namespace}
        text={defaultText}
        variant={'textRegular16'}
        colour={'brandBlue500'}
        variables={{
          dogNames
        }}
        margin={false}
      />
    </>
  )
}

export { Props }
export default Default
