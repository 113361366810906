// @noflow
import i18next from 'i18next'

import { Product, captureException } from '@/utils/sentry'

import { PayPalOption, PaypalResponse } from '../../../types'
import { bannerMessageState } from '../Banner'

// @noflow
const getPaypalPaymentAuthorisation = ({
  redirectUrl,
  payPalOption,
  csrfToken,
  setPayPalAuthorisationResponse
}: {
  redirectUrl: string
  payPalOption: PayPalOption
  csrfToken: string
  setPayPalAuthorisationResponse: (arg: PaypalResponse) => void
}): void => {
  if (payPalOption !== 'with_paypal') {
    return
  }

  const endpoint = '/api/guests/paypal_authorisation'
  // eslint-disable-next-line i18next/no-literal-string
  const queryParams = `redirect_url=${redirectUrl}`
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-CSRF-Token': csrfToken
  }
  const method = 'POST'
  const credentials = 'same-origin'

  fetch(`${endpoint}?${queryParams}`, { headers, method, credentials }).then(
    (res: Response): Promise<void> | void => {
      if (!res.ok) {
        captureException(`Received error from getPaypalPaymentAuthorisation`, {
          extra: {
            response: res
          },
          tags: {
            product: Product.Checkout
          }
        })
        bannerMessageState({
          message: i18next.t('checkout:errors.delivery_details_fetch'),
          type: 'error'
        })
      } else {
        try {
          return res.json().then((res: PaypalResponse): void => {
            setPayPalAuthorisationResponse({
              redirect_url: res.redirect_url,
              token: res.token
            })
          })
        } catch (error) {
          captureException(
            `Encountered error parsing response.json from /api/guests/paypal_authorisation`,
            {
              extra: {
                error
              },
              tags: {
                product: Product.Checkout
              }
            }
          )
        }
      }
    }
  )
}

export default getPaypalPaymentAuthorisation
