// @flow

import * as React from 'react'

type Props = {|
  dividerText: string
|}

const DogNameDivider = ({ dividerText }: Props): React.Element<'div'> => (
  <div className='dog-name-divider'>
    <strong>
      { dividerText }
    </strong>
  </div>
)

export default DogNameDivider
