import { gql } from '@apollo/client'

const PENDING_SUBSCRIPTION_ORDER_PRICING_FRAGMENT = gql`
  fragment pendingSubscriptionOrderPricingFragment on PendingSubscriptionOrderPricing {
    grossTotalPrice
    netTotalPrice
    netTotalPricePerDay
    totalDiscountedAmount
    grossCoreFoodPrice
    grossCoreFoodPricePerDay
    netCoreFoodPrice
    coreFoodDiscountedAmount
    grossRecipeSurchargePrice
    netRecipeSurchargePrice
    recipeSurchargeDiscountedAmount
    grossDeliverySurchargePrice
    netDeliverySurchargePrice
    deliverySurchargeDiscountedAmount
    grossAdditionalProductsPrice
    netAdditionalProductsPrice
    additionalProductsDiscountedAmount
    totalSavedAmount
    standardOrderCoreFoodGrossPrice
    standardOrderCoreFoodGrossPricePerDay
    flavourPricings {
      flavourName
      servings
      netStandardOrderPricePerServing
      grossStandardOrderPricePerServing
      netFirstOrderPricePerServing
    }
  }
`
export { PENDING_SUBSCRIPTION_ORDER_PRICING_FRAGMENT }
