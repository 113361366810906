import { gql } from '@apollo/client'

export const PAUSE_FLOW_STARTED_EVENT_CREATE = gql`
  mutation PauseFlowStartedEventCreate($properties: Boolean!) {
    pauseFlowStartedEventCreate(properties: $properties) {
      id
      status
    }
  }
`
