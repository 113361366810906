// @noflow
import { Factory } from 'fishery'

import { BoxFactory } from '../factories'
import { Subscription } from '../types'

export default Factory.define<Subscription>(({ sequence }) => ({
  id: sequence,
  nextEditableBox: BoxFactory.build(),
  firstBox: BoxFactory.build(),
  plan: {
    durationInDays: 14
  }
}))
