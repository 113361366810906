// @noflow
import type { StripeIdealBankElementChangeEvent } from '@stripe/stripe-js'
import React, { useCallback, useState } from 'react'

import AlertCard from '@/components/elements/atoms/Alert/AlertCard'
import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'
import Icon from '@/components/elements/atoms/Icon/Icon'
import Link from '@/components/elements/atoms/Link/Link'
import Separator from '@/components/elements/atoms/Separator/Separator'
import Text from '@/components/elements/atoms/Text/Text'
import Tooltip from '@/components/elements/atoms/Tooltip/Tooltip'

import STYLES from './PaymentMethodCard.module.sass'

import { PaymentMethodsQuery_user_subscription_paymentMethods as PaymentMethod } from '../../queries/__generated__/PaymentMethodsQuery'
// Enums
import { Code, InputPaymentMethod } from '@/types'

import usePayWithGoCardless from '../../../../../../../hooks/usePayWithGoCardless'
import usePayWithSepaMethod from '../../../../../../../hooks/usePayWithSepaMethod'
import PaymentMethodModal from '../PaymentMethodModal/PaymentMethodModal'
import PaymentMethodSkeleton from './PaymentMethodSkeleton'

type Props = {
  paymentMethod: PaymentMethod
  selected: boolean
  shouldOfferAttemptPaymentAgain: boolean
  disableRetryPaymentButton: boolean
  onSelect: (paymentMethod: PaymentMethod) => void
  resumeSubscription: () => void
  onArchivePaymentMethodClick: (paymentMethod: PaymentMethod) => void
  shippingCountryCode: Code
}

const PaymentMethodCard = ({
  paymentMethod,
  selected,
  shouldOfferAttemptPaymentAgain,
  disableRetryPaymentButton,
  onSelect,
  resumeSubscription,
  onArchivePaymentMethodClick,
  shippingCountryCode
}: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const toggle = useCallback(() => {
    setIsDisabled(true)
    setIsOpen(!isOpen)
  }, [isOpen, setIsOpen])

  const idealBankChangedEvent = useCallback(
    (e: StripeIdealBankElementChangeEvent) => {
      setIsDisabled(!e.complete)
    },
    [setIsDisabled]
  )

  const {
    payOutstandingInvoicesWithGoCardless,
    loading: goCardlessPaymentLoading
  } = usePayWithGoCardless()
  const { payOutstandingInvoicesWithSepaMethod } = usePayWithSepaMethod({
    shippingCountryCode
  })
  const loading = goCardlessPaymentLoading

  const handleClickAttemptPayment = useCallback(() => {
    switch (paymentMethod.type) {
      case 'DirectDebit':
        payOutstandingInvoicesWithGoCardless()
        break
      case 'SepaDirectDebit': {
        if (shippingCountryCode === Code.NL) {
          toggle()
        } else if (shippingCountryCode === Code.BE) {
          payOutstandingInvoicesWithSepaMethod()
        }
        break
      }
      default:
        resumeSubscription()
    }
  }, [
    paymentMethod.type,
    payOutstandingInvoicesWithGoCardless,
    resumeSubscription,
    toggle,
    payOutstandingInvoicesWithSepaMethod,
    shippingCountryCode
  ])

  const handleOnClick = useCallback(() => {
    onSelect(paymentMethod)
  }, [onSelect, paymentMethod])

  const handleArchiveClick = useCallback(() => {
    onArchivePaymentMethodClick(paymentMethod)
  }, [onArchivePaymentMethodClick, paymentMethod])

  const renderArchivedLink = useCallback(() => {
    if (paymentMethod.active) {
      return (
        <Tooltip
          displayIcon={false}
          label={{
            text: 'my_details.payment_methods.remove',
            namespace: 'dashboard'
          }}
          identifier="my_details.payment_methods.remove_tooltip"
        >
          <Text
            namespace="dashboard"
            text={'my_details.payment_methods.unable_to_remove'}
          />
        </Tooltip>
      )
    } else {
      return (
        <Link
          namespace={'dashboard'}
          text="my_details.payment_methods.remove"
          onClick={handleArchiveClick}
          suffix={null}
          size={14}
          identifier="my_details.payment_methods.remove"
        />
      )
    }
  }, [handleArchiveClick, paymentMethod.active])

  if (loading) {
    return <PaymentMethodSkeleton />
  }

  return (
    <Card shadow padding={0}>
      <div className={STYLES.inner}>
        <div
          className={STYLES.icon}
          onClick={handleOnClick}
          role={'button'}
          tabIndex={0}
          onKeyUp={handleOnClick}
        >
          <Icon
            asset={selected ? 'radioSelected' : 'radioUnselected'}
            size={30}
          />
        </div>
        <div className={`${STYLES.details}`}>
          {paymentMethod.__typename === 'CreditCardPaymentMethod' && (
            <>
              <div className={STYLES.cardTitle}>
                <Text
                  namespace={'dashboard'}
                  text="my_details.payment_methods.method_names.card"
                  variant="textRegular14"
                  colour="brandBlue400"
                  margin={false}
                />
                {renderArchivedLink()}
              </div>
              <Text
                namespace={'dashboard'}
                text="my_details.payment_methods.obscured_card_number"
                variables={{ lastFourDigits: paymentMethod.lastFourDigits }}
                variant="display16"
                margin={false}
              />
            </>
          )}
          {paymentMethod.__typename === 'BillingAgreement' && (
            <>
              <div className={STYLES.cardTitle}>
                <Text
                  namespace={'dashboard'}
                  text="my_details.payment_methods.method_names.paypal.heading"
                  variant="textRegular14"
                  colour="brandBlue400"
                  margin={false}
                />
                {renderArchivedLink()}
              </div>
              <Text
                text={paymentMethod.paypalEmail || ''}
                translate={false}
                variant="display16"
                margin={false}
              />
            </>
          )}
          {paymentMethod.__typename === 'SepaDirectDebit' && (
            <>
              <div className={STYLES.cardTitle}>
                <Text
                  namespace={'dashboard'}
                  text="my_details.payment_methods.method_names.direct_debit.heading"
                  variant="textRegular14"
                  colour="brandBlue400"
                  margin={false}
                />
                {renderArchivedLink()}
              </div>
              <Text
                namespace={'dashboard'}
                text="my_details.payment_methods.method_names.direct_debit.last_four_precursor"
                variables={{ accountLastFour: paymentMethod.accountLastFour }}
                variant="display16"
                margin={false}
              />
            </>
          )}
          {paymentMethod.__typename === 'MerchantInitiatedPayment' && (
            <>
              <div className={STYLES.cardTitle}>
                <Text
                  namespace={'dashboard'}
                  text="my_details.payment_methods.method_names.merchant_initiated_payment.heading"
                  variant="textRegular14"
                  colour="brandBlue400"
                  margin={false}
                />
                {renderArchivedLink()}
              </div>
              <Text
                namespace={'dashboard'}
                text="my_details.payment_methods.method_names.merchant_initiated_payment.body"
                variant="display16"
                margin={false}
              />
            </>
          )}
        </div>
      </div>
      {shouldOfferAttemptPaymentAgain && (
        <div className={STYLES.bottomExtra}>
          <Separator handdrawn />
          <div className={STYLES.bottomExtraContainer}>
            <AlertCard
              message={{
                namespace: 'dashboard',
                text: 'my_details.payment_methods.no_payment_info.suspended',
                margin: false,
                align: 'left'
              }}
              variant="error"
            />
            <div className={STYLES.buttonContainer}>
              <Button
                typography={{
                  text: 'my_details.payment_methods.attempt_payment_again',
                  namespace: 'dashboard'
                }}
                onClick={handleClickAttemptPayment}
                disabled={disableRetryPaymentButton}
                disableAnalytics
              />
            </div>
          </div>
        </div>
      )}
      {paymentMethod.__typename === 'SepaDirectDebit' &&
        shippingCountryCode === 'NL' && (
          <PaymentMethodModal
            isOpen={isOpen}
            toggle={toggle}
            inlineErrorMessage={null}
            onSubmit={payOutstandingInvoicesWithSepaMethod}
            onIdealSelectChange={idealBankChangedEvent}
            paymentMethodType={InputPaymentMethod.sepa_direct_debit}
            disableButton={isDisabled}
          />
        )}
    </Card>
  )
}

export { Props }
export default PaymentMethodCard
