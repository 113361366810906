/* eslint-disable i18next/no-literal-string */
const imgPathPrefix = 'Web/photos/ugc'
const IEReviews = [
  {
    path: `${imgPathPrefix}/muirigh_madra`,
    alt: 'instagram_carousel.ie.slide_1.image_alt',
    instagram_handle: 'muirigh_madra',
    text: 'Bad Penny, good boy!'
  },
  {
    path: `${imgPathPrefix}/north.coast.canines`,
    alt: 'instagram_carousel.ie.slide_2.image_alt',
    instagram_handle: 'north.coast.canines',
    text: 'Puppy dog eyes always pulled out for snacks'
  },
  {
    path: `${imgPathPrefix}/jessieotrot`,
    alt: 'instagram_carousel.ie.slide_3.image_alt',
    instagram_handle: 'jessieotrot',
    text: '“Who’s a good boy?” 🐾'
  },
  {
    path: `${imgPathPrefix}/heysimonhere`,
    alt: 'instagram_carousel.ie.slide_4.image_alt',
    instagram_handle: 'heysimonhere',
    text: 'There is nothing better than my @butternut food. High quality meals eveytime. Excellent team. Fantastic deliveries. Everything is awsome.'
  },
  {
    path: `${imgPathPrefix}/meeko_tales`,
    alt: 'instagram_carousel.ie.slide_5.image_alt',
    instagram_handle: 'meeko_tales',
    text: 'Hey pups, dr. Meeko is here<br />I’ve just discovered a magic powder from @butternutbox that helps with upset tummy. It contains pre, pro and postbiotics that help support good bacteria in the gut and mineral rich clay that flushes out toxins.'
  },
  {
    path: `${imgPathPrefix}/dunmanwaydairygirl`,
    alt: 'instagram_carousel.ie.slide_6.image_alt',
    instagram_handle: 'dunmanwaydairygirl',
    text: 'I have had so much trouble finding decent dog food since moving to Ireland but happily now there’s a @butternutbox.ie My girls are over the moon with their new dinners and bandanas! 😎'
  },
  {
    path: `${imgPathPrefix}/chloeefarrell22`,
    alt: 'instagram_carousel.ie.slide_7.image_alt',
    instagram_handle: 'chloeefarrell22',
    text: 'Riley’s an excited pupper with her new food 🍽🐾🤤'
  }
]

export { IEReviews }
