// @noflow
import { ensureNever } from '@/typescript/utils'

import * as ACTIONS from '../actions'
import submitContactFormQuery from '../fetch_requests/submitContactForm'
import type { RequestResult } from '../fetch_requests/submitContactForm'
import type { Dispatch, State, Thunk } from '../reducers'

const submitContactForm = (): Thunk<Promise<void>> => {
  return (dispatch: Dispatch, getState: () => State): Promise<void> => {
    dispatch(ACTIONS.startRequest('submitContactForm'))

    const { globalAttributes, formData } = getState()
    const { form, queryType } = formData
    const { token, csrfToken } = globalAttributes

    return submitContactFormQuery({
      token,
      csrfToken,
      queryType,
      form
    }).then((requestResult: RequestResult): void => {
      dispatch(
        ACTIONS.updateField('recaptchaString', {
          value: '',
          interaction: 'NotInteracted'
        })
      )
      switch (requestResult.type) {
        case 'ServerError': {
          dispatch(ACTIONS.completeRequestWithError('submitContactForm'))
          throw new Error(
            `Server Error: status=${requestResult.status}, message=${requestResult.message}`
          )
        }
        case 'GraphQLError': {
          throw new Error(
            'Cannot throw a GraphQLError for a fetch controller request'
          )
        }
        case 'UnknownError': {
          dispatch(ACTIONS.completeRequestWithError('submitContactForm'))
          throw new Error(requestResult.error)
        }
        case 'Success': {
          dispatch(ACTIONS.completeRequestWithSuccess('submitContactForm'))
          dispatch(
            ACTIONS.updateField('message', {
              value: '',
              interaction: 'NotInteracted'
            })
          )
          dispatch(
            ACTIONS.updateField('queryType', {
              value: 'How can we help?',
              interaction: 'NotInteracted'
            })
          )
          dispatch(ACTIONS.setFormViewingStep('Query Selection'))

          break
        }
        default: {
          ensureNever(requestResult)
        }
      }
    })
  }
}

export default submitContactForm
