// @noflow
import type { Language } from '@/packs/localisation'
import { useReactiveVar } from '@apollo/client'
import { Stripe } from '@stripe/stripe-js'
import React, { ReactElement, useCallback, useEffect } from 'react'

import Validator from '@/utils/validator'

import dogsMeeting from 'assets/images/illustrations/dogs/dogs-meeting.svg'

import segmentTrack from '@/components/analytics/Analytics'
import { Expand } from '@/components/elements/atoms/Animated/Animated'
import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'
import FlatButton from '@/components/elements/atoms/FlatButton/FlatButton'
import Text from '@/components/elements/atoms/Text/Text'
import LoadingScreen from '@/components/elements/organisms/LoadingScreen/LoadingScreen'
import { checkoutPageState } from '@/components/pages/CheckoutPage/CheckoutPage'
import { StandardInputField } from '@/components/pages/CheckoutPage/components/InputField/InputField'
import * as inputHelpers from '@/components/pages/CheckoutPage/helpers/inputs'
import * as sectionsHelpers from '@/components/pages/CheckoutPage/helpers/sections'
import type { AccountDetailsFormKey } from '@/components/pages/CheckoutPage/types'

import { CheckoutPage } from '../../queries/__generated__/CheckoutPage'
import { ensureNever } from '@/typescript/utils'

import * as ANALYTICS from '../../Analytics/CheckoutAnalytics'
import PasswordInputField from '../InputField/PasswordInputField'
import { paymentSubmissionState } from '../PaymentSection/PaymentSectionWithStripe'
import ExpressCheckoutElements from './ExpressCheckoutElements'

type Props = {
  namespace: string
  shippingCountryCode: CheckoutPage['guest']['assumedShippingCountry']['code']
  shouldSeeExpressPayOnCheckout: boolean
  stripePromise: Promise<Stripe | null>
  stripeKey: string
  language: Language
  currency: CheckoutPage['guest']['assumedShippingCountry']['currency']
  csrfToken: string
}

const AccountSection = ({
  namespace,
  shippingCountryCode,
  shouldSeeExpressPayOnCheckout,
  stripePromise,
  stripeKey,
  language,
  currency,
  csrfToken
}: Props): ReactElement => {
  const { sections } = checkoutPageState()
  const { accountDetails } = sections
  const { visible, valid, form } = accountDetails
  const { name, email, password } = form

  const formHasInputtedData = Object.values(form).every(
    ({ value }) => value.length > 0
  )

  const validateInputField = useCallback(
    (fieldName: AccountDetailsFormKey, value: string) => {
      switch (fieldName) {
        case 'name':
        case 'email':
        case 'password': {
          // Set the inputInteractionState directly
          sections.accountDetails.form[fieldName].inputInteractionState =
            'NotInteractingInteracted'

          const errorMessage = inputHelpers.validate(value, fieldName)

          // Set the error message
          sections.accountDetails.form[fieldName].errorMessage = errorMessage

          // Report to Segment any users which blur away with an invalid email
          if (
            fieldName === 'email' &&
            !Validator.isValidEmail(value) &&
            value.length > 0
          ) {
            segmentTrack('User blurred with invalid email', {
              // Will anonymise provided emails with letters being replaced with X
              // and digits replaced with D.
              // E.g: example123@butternutbox.com -> XXXXXXXDDD@XXXXXXXXXXXX.XXX
              email: value.replace(/[a-zA-Z]/g, 'X').replace(/\d/g, 'D')
            })
          }

          return checkoutPageState({
            ...checkoutPageState(),
            sections
          })
        }
        case 'visible-password':
          return
        default:
          ensureNever(fieldName)
      }
    },
    [sections]
  )

  const updateInputField = (
    fieldName: AccountDetailsFormKey,
    value: string
  ) => {
    switch (fieldName) {
      case 'name':
      case 'email':
      case 'password': {
        // Given the input is being typed into, update its inputInteractionState
        const newInteractionState = inputHelpers.interactingState(
          sections.accountDetails.form[fieldName].inputInteractionState
        )
        sections.accountDetails.form[fieldName].inputInteractionState =
          newInteractionState

        sections.accountDetails.form[fieldName].value = value
        const errorMessage = inputHelpers.validate(value, fieldName)
        // Set the error message
        sections.accountDetails.form[fieldName].errorMessage = errorMessage

        return checkoutPageState({
          ...checkoutPageState(),
          sections
        })
      }
      case 'visible-password':
        return
      default:
        ensureNever(fieldName)
    }
  }

  const handleOnBlur = (fieldName: AccountDetailsFormKey, value: string) => {
    validateInputField(fieldName, value)
    ANALYTICS.blurField(fieldName, sections.accountDetails.form[fieldName])
  }

  const isSectionValid = useCallback(() => {
    const isAccountSectionValid =
      name.errorMessage === null &&
      email.errorMessage === null &&
      password.errorMessage === null

    const sectionIsValid = isAccountSectionValid && formHasInputtedData

    sections.accountDetails.valid = sectionIsValid
    // If the section is not valid, we want to show the section
    sections.accountDetails.visible =
      sections.accountDetails.visible || !sectionIsValid

    checkoutPageState({
      ...checkoutPageState(),
      sections
    })
    return sectionIsValid
  }, [
    name.errorMessage,
    email.errorMessage,
    password.errorMessage,
    sections,
    formHasInputtedData
  ])

  const continueButtonClick = useCallback(() => {
    validateInputField('name', name.value)
    validateInputField('email', email.value)
    validateInputField('password', password.value)

    if (isSectionValid()) {
      sections.accountDetails.visible = false
      sections.addressDetails.visible = true

      checkoutPageState({
        ...checkoutPageState(),
        sections
      })
    }
  }, [
    validateInputField,
    name.value,
    email.value,
    password.value,
    isSectionValid,
    sections
  ])

  const editSection = useCallback(
    (event) => {
      event.preventDefault()

      sections.accountDetails.visible = true

      checkoutPageState({
        ...checkoutPageState(),
        sections
      })
    },
    [sections]
  )

  useEffect(() => {
    isSectionValid()
  }, [
    name.value,
    name.errorMessage,
    email.value,
    email.errorMessage,
    password.value,
    password.errorMessage,
    sections,
    formHasInputtedData,
    isSectionValid
  ])

  const submissionState = useReactiveVar(paymentSubmissionState)
  const showLoadingScreen = submissionState.type === 'loading'

  return (
    <section
      className={`${sectionsHelpers.sectionClassName('account')} section ${
        visible ? 'section--visible' : 'section--hidden'
      }`}
    >
      <Card shadow>
        <div className="card-content">
          <div className="card-title">
            <div>
              <Text
                namespace={namespace}
                text="account_section.title"
                element="h2"
                variant="display20"
                align="left"
                margin={false}
              />
              {visible && (
                <Text
                  namespace={namespace}
                  text="account_section.subtitle"
                  colour="brandBlue400"
                  align="left"
                  margin={false}
                />
              )}
              {!visible && valid && (
                <Text
                  colour="brandBlue400"
                  translate={false}
                  align="left"
                  text={email.value}
                />
              )}
            </div>
            {!visible && (
              <FlatButton
                variant="yellow200"
                onClick={editSection}
                identifier="Account Details Edit"
                screenIdentifier="checkout_page"
                text={{
                  namespace: namespace,
                  text: 'edit_button'
                }}
              />
            )}
          </div>
          {showLoadingScreen && (
            <LoadingScreen
              isOpen={showLoadingScreen}
              title={{
                namespace,
                text: 'loading_screen.text',
                variant: 'display20'
              }}
              variant={'static'}
              image={dogsMeeting}
            />
          )}
          <Expand
            show={visible}
            margin={{ top: 1.6, right: 0, bottom: 0, left: 0 }}
            maxHeight={shouldSeeExpressPayOnCheckout ? 1600 : 600}
            origin="top"
          >
            {/* eslint-disable react/jsx-no-bind */}
            <StandardInputField
              fieldName="name"
              dataTestId="checkout-name-input"
              input={name}
              onBlur={(e) => handleOnBlur('name', e.currentTarget.value)}
              onChange={(e) => updateInputField('name', e.currentTarget.value)}
            />
            <StandardInputField
              fieldName="email"
              dataTestId="checkout-email-input"
              input={email}
              onBlur={(e) =>
                handleOnBlur('email', e.currentTarget.value.trim())
              }
              onChange={(e) =>
                updateInputField('email', e.currentTarget.value.trim())
              }
            />
            <PasswordInputField
              value={password}
              dataTestId="checkout-password-input"
              onBlur={(e) => handleOnBlur('password', e.currentTarget.value)}
              onChange={(e) =>
                updateInputField('password', e.currentTarget.value)
              }
            />
            {/* eslint-enable react/jsx-no-bind */}
            {visible && (
              <>
                <div className="checkout__continue-button">
                  <Button
                    dataTestId="checkout-continue-to-add-button"
                    typography={{
                      namespace,
                      text: 'continue_button.address'
                    }}
                    disabled={!valid}
                    fullWidth
                    onClick={continueButtonClick}
                    identifier="continue_to_address"
                    screenIdentifier="checkout_page"
                  />
                </div>
                {shouldSeeExpressPayOnCheckout && (
                  <ExpressCheckoutElements
                    stripePromise={stripePromise}
                    stripeKey={stripeKey}
                    language={language}
                    namespace={namespace}
                    shippingCountryCode={shippingCountryCode}
                    currency={currency}
                    csrfToken={csrfToken}
                    isSectionValid={isSectionValid}
                    validateInputField={validateInputField}
                  />
                )}
              </>
            )}
          </Expand>
        </div>
      </Card>
    </section>
  )
}

export default AccountSection
