import { gql } from '@apollo/client'

import { CALENDAR_DATES_V2_FRAGMENT } from '@/components/elements/molecules/CalendarV2/fragments/calendarDatesV2Fragment'
import { COURIER_FRAGMENT } from '@/components/elements/molecules/CourierSelector/fragments/CourierFragment'

import { DELIVERY_DATE_USER_FRAGMENT } from '../fragments/DeliveryDateUserFragment'

// eslint-disable-next-line i18next/no-literal-string
const COURIER_DELIVERY_DATES_QUERY = gql`
  ${CALENDAR_DATES_V2_FRAGMENT}
  ${DELIVERY_DATE_USER_FRAGMENT}
  ${COURIER_FRAGMENT}
  query CourierDeliveryDatesQuery(
    $boxId: ID!
    $nextNBoxes: Int!
    $calendarInitDate: ISO8601Date!
    $nDays: Int!
    $shouldAttemptToOfferNextDayDelivery: Boolean!
    $userId: ID
  ) {
    user {
      id
      currentDeliveryAreaPreference {
        id
      }
      deliveryAreaOptions {
        ...CourierFragment
      }
      ...DeliveryDateUserFragment
    }
    calendarDates(
      startDate: $calendarInitDate
      nDays: $nDays
      shouldAttemptToOfferNextDayDelivery: $shouldAttemptToOfferNextDayDelivery
      userId: $userId
    ) {
      ...CalendarDatesV2Fragment
    }
  }
`

export { COURIER_DELIVERY_DATES_QUERY }
