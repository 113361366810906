// @noflow
import { useQuery, useReactiveVar } from '@apollo/client'
import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'
import { useCallback, useEffect, useState } from 'react'

import * as Sentry from '@/utils/sentry'

import IDEAL_PLAN_QUERY from '../queries/IdealPlanQuery'

import {
  IdealPlanQuery_guest_planRecommendation_idealAllPlan as IdealAllPlan,
  IdealPlanQuery_guest_planRecommendation_idealMixPlan as IdealMixPlan,
  IdealPlanQuery
} from '../queries/__generated__/IdealPlanQuery'
import { BoxKind } from '@/types'

import { PlanState, plansPageState } from '../SimplifiedPlansPage'

type SelectedPlan = IdealAllPlan | IdealMixPlan | null

type UseIdealPlan = {
  selectedPlan?: SelectedPlan
  discountedPricePerDay: number
  idealMixPlan?: IdealMixPlan | null
  idealAllPlan?: IdealAllPlan | null
  updateSelectedPlan: (
    planId: string,
    callback: (planId: string) => void
  ) => void
}

const useIdealPlan = (): UseIdealPlan => {
  const [discountedPricePerDay, setDiscountedPricePerDay] = useState(0)
  const plansState: PlanState = useReactiveVar(plansPageState)
  const [idealAllPlan, setIdealAllPlan] = useState<
    UseIdealPlan['idealAllPlan'] | null
  >()
  const [idealMixPlan, setIdealMixPlan] = useState<
    UseIdealPlan['idealMixPlan'] | null
  >()
  const [selectedPlan, setSelectedPlan] = useState<
    UseIdealPlan['idealAllPlan'] | UseIdealPlan['idealMixPlan']
  >()

  const { data, error } = useQuery<IdealPlanQuery>(IDEAL_PLAN_QUERY, {
    onCompleted: (data) => {
      setIdealAllPlan(data?.guest.planRecommendation?.idealAllPlan)
      setIdealMixPlan(data?.guest.planRecommendation?.idealMixPlan)
    }
  })

  if (error) {
    Sentry.captureException(`Error in useIdealPlan hook - IdealPlanQuery`, {
      extra: {
        error
      },
      tags: {
        product: Sentry.Product.PlansFlow
      }
    })
  }

  useEffect(() => {
    if (plansState.planId) {
      // if selected plan exists and is the same as an ideal plan, return that plan
      const _selectedPlan = [idealAllPlan, idealMixPlan].filter(
        (plan) => plan?.id === plansState.planId
      )
      if (_selectedPlan.length > 0) {
        setSelectedPlan(_selectedPlan[0])
      } else {
        setSelectedPlan(idealAllPlan ?? idealMixPlan)
      }
    } else {
      // Otherwise return all plan if it exists or return mix plan if not
      setSelectedPlan(idealAllPlan ?? idealMixPlan)
    }
    const recommendedPlans = [
      data?.guest.planRecommendation?.idealAllPlan,
      data?.guest.planRecommendation?.idealMixPlan
    ].filter((plan) => !isNil(plan))

    // We want to filter out duplicate plans as it's possible for the backend to
    // return an idealAllPlan and an idealMixPlan that are identical except for
    // the boxKind and planId. Therefore we filter out plans that have the same
    // typeOfPlanForCustomer and trialLengthInDays to avoid showing this to users
    const filterDuplicatePlans = (
      recommendedPlans: Array<SelectedPlan | undefined>
    ) => {
      if (!isNil(recommendedPlans)) {
        return recommendedPlans.filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              (plan) =>
                plan?.typeOfPlanForCustomer === value?.typeOfPlanForCustomer &&
                plan?.trial.lengthInDays === value?.trial.lengthInDays
            )
        )
      } else {
        return null
      }
    }

    const filteredPlans = filterDuplicatePlans(recommendedPlans)
    if (
      filteredPlans &&
      filteredPlans.length === 1 &&
      !isNil(filteredPlans[0])
    ) {
      if (filteredPlans[0].boxKind === BoxKind.all_bb) {
        setIdealAllPlan(filteredPlans[0])
        setIdealMixPlan(null)
      } else {
        setIdealAllPlan(null)
        setIdealMixPlan(filteredPlans && filteredPlans[0])
      }
    }

    setDiscountedPricePerDay(selectedPlan?.trialDiscountedPricePerDay ?? 0)
  }, [
    selectedPlan?.trialDiscountedPricePerDay,
    plansState.planId,
    selectedPlan,
    idealAllPlan,
    idealMixPlan,
    data?.guest.planRecommendation?.idealAllPlan,
    data?.guest.planRecommendation?.idealMixPlan
  ])

  const updateSelectedPlan = useCallback(
    (planId: string, callback: (planId: string) => void) => {
      const updatedPendingSubscription: PlanState = {
        ...plansState,
        planId
      }

      if (!isEqual(updatedPendingSubscription, plansState)) {
        plansPageState(updatedPendingSubscription)
        callback(planId)
      }
    },
    [plansState]
  )

  return {
    selectedPlan,
    idealAllPlan,
    idealMixPlan,
    discountedPricePerDay,
    updateSelectedPlan
  }
}

export default useIdealPlan

export type { SelectedPlan }
