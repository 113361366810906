import { gql } from '@apollo/client'

const DOG_ABOUT_INFO_QUERY = gql`
  query DogAboutInfoQuery {
    user {
      id
      dogs {
        id
        name
        ageInMonths
        breed {
          key
          name
        }
        gender
        neutered
      }
    }
    breeds {
      key
      name
    }
  }
`

export { DOG_ABOUT_INFO_QUERY }
