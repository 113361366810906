// @noflow
// Context
import { useQuery } from '@apollo/client'
import type { ApolloError } from '@apollo/client'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { differenceInDays } from 'date-fns'
import React, { useContext } from 'react'
import type { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import * as Sentry from '@/utils/sentry'

import LoadingScreen from '@/components/elements/organisms/LoadingScreen/LoadingScreen'
import AddDogSection from '@/components/pages/NoDogsPage/components/AddDogSection/AddDogSection'
import STYLES from '@/components/pages/NoDogsPage/components/NoDogsHero/NoDogsHero.module.sass'
import ProductSection from '@/components/pages/NoDogsPage/components/ProductSection/ProductSection'
import Base from '@/components/templates/Base'

import { NO_DOGS_PAGE_DATA } from './queries/noDogsPageQuery'

import type { NoDogsPageData } from './queries/__generated__/NoDogsPageData'

import { NoDogsPageContext } from '../../index'
import type { NoDogsPageContextType } from '../../index'
import { PAUSE_SUBREASON } from '../../types/pauses'

// NoDogsStateContent hook types
type NoDogsStateContent = {
  data: NoDogsPageData | undefined
  loading: boolean
  error: ApolloError | undefined
  handlers: {
    setShowErrorState: NoDogsPageContextType['setError']
  }
}

const useNoDogsStateContent = () => {
  const { setError } = useContext(NoDogsPageContext)

  const { loading, error, data } = useQuery<NoDogsPageData>(NO_DOGS_PAGE_DATA)

  return {
    data,
    loading,
    error,
    handlers: { setError }
  }
}

const NoDogsStateContent = (): ReactElement | null => {
  const { t } = useTranslation('dashboard')
  const {
    data,
    loading,
    error,
    handlers: { setError }
  } = useNoDogsStateContent()

  if (loading) {
    return (
      <LoadingScreen
        isOpen
        title={{
          text: t('no_dogs.loading_text')
        }}
      />
    )
  }

  if (error) {
    Sentry.captureException('Error occured in NO_DOGS_PAGE_DATA query', {
      extra: { error },
      tags: {
        product: Sentry.Product.Account,
        team: Sentry.Team.Retention
      }
    })

    // Redirect to the 'dashboard/no_dogs/error' page
    setError(error)

    return null
  }

  if (!data) {
    Sentry.captureException('NoDogsStateContent | Data is not available', {
      tags: {
        product: Sentry.Product.Account,
        team: Sentry.Team.Retention
      }
    })

    // Redirect to the 'dashboard/no_dogs/error' page
    setError(error)

    return null
  }

  const {
    user: { firstName }
  } = data

  const today = new Date()
  const lastPause = data?.user.subscription.pauses?.[0]
  const lastPauseDate = new Date(lastPause?.createdAt)
  const diffDays = differenceInDays(today, lastPauseDate)
  const pausedLongEnoughAgo = diffDays < 30

  const dogPassedReason =
    lastPause?.subreason === PAUSE_SUBREASON.dog_passed && pausedLongEnoughAgo

  return (
    <Base background="brandYellow100">
      <Container maxWidth={false} disableGutters>
        {dogPassedReason ? (
          <Grid container justifyContent="center">
            <div className={STYLES.heroContainerWrapper}>
              <AddDogSection
                firstName={firstName}
                dogPassedReason={dogPassedReason}
              />
            </div>
          </Grid>
        ) : (
          <ProductSection />
        )}
      </Container>
    </Base>
  )
}

export default NoDogsStateContent
