import { TOptions } from 'i18next'
import React from 'react'

import Text from '@/components/elements/atoms/Text/Text'
import TEXTSTYLES from '@/components/elements/atoms/Text/Text.module.sass'

import STYLES from './HeroText.module.sass'

type Props = {
  variant?: keyof typeof STYLES
  title?: string | Array<string>
  subtitle?: string
  extras?: JSX.Element | null
  topExtras?: JSX.Element | null
  titleAdornment?: JSX.Element
  namespace?: string
  variables?: TOptions
  subtitleVariables?: { [key: string]: string | number }
  translate?: boolean
  whiteBackground?: boolean
}

const titleTextVariant = (
  heroVariant: keyof typeof STYLES | undefined
): keyof typeof TEXTSTYLES => {
  switch (heroVariant) {
    case 'wizard': {
      return 'display28'
    }
    case 'slim': {
      return 'display20'
    }
    case 'plans': {
      return 'display16'
    }
    default: {
      return 'display28'
    }
  }
}

const subtitleTextVariant = (
  heroVariant: keyof typeof STYLES | undefined
): keyof typeof TEXTSTYLES => {
  switch (heroVariant) {
    case 'wizard': {
      return 'textRegular18'
    }
    case 'slim': {
      return 'display16'
    }
    case 'large': {
      return 'textMono18'
    }
    case 'plans': {
      return 'display28'
    }
    case 'care': {
      return 'display24'
    }
    default: {
      return 'display16'
    }
  }
}

const HeroText = ({
  variant,
  title,
  subtitle,
  extras = null,
  namespace = '',
  variables = {},
  subtitleVariables = {},
  translate,
  whiteBackground = false,
  titleAdornment,
  topExtras
}: Props): JSX.Element | null => {
  return (
    <div
      className={`${STYLES.container} ${variant && STYLES[variant]} ${
        !title && STYLES.noPadding
      }`}
    >
      <div className={`${STYLES.contents}`}>
        {topExtras}
        <div className={STYLES.title}>
          {title && (
            <div
              data-testid="hero-text-title"
              className={whiteBackground ? `${STYLES.whiteBackground}` : ''}
            >
              <Text
                text={title}
                namespace={namespace}
                variant={titleTextVariant(variant)}
                element="h1"
                margin={false}
                variables={variables}
                translate={translate}
              />
            </div>
          )}
          {titleAdornment}
        </div>
        {subtitle && (
          <Text
            dataTestId="hero-text-subtitle"
            text={subtitle}
            namespace={namespace}
            variant={subtitleTextVariant(variant)}
            element="h2"
            variables={subtitleVariables}
            translate={translate}
          />
        )}
        {extras}
      </div>
    </div>
  )
}

export { Props }
export default HeroText
