// @noflow
import * as Sentry from '@sentry/browser'
import classnames from 'classnames'
import isUndefined from 'lodash/isUndefined'
import React from 'react'

import { isDark } from '@/utils/contrast'

import Van from 'assets/images/icons/vans/van-icon.svg'

import Text, { AllowedColours } from '@/components/elements/atoms/Text/Text'
// Props
import type { Props as TextProps } from '@/components/elements/atoms/Text/Text'

import STYLES from './Label.module.sass'

import { ensureNever } from '@/typescript/utils'

type SupportedColours =
  | 'brandYellow200'
  | 'brandYellow300'
  | 'brandRed100'
  | 'brandRed300'
  | 'brandRed400'
  | 'brandPink100'
  | 'brandBlue200'
  | 'brandBlue500'
  | 'brandBlue600'
  | 'brandBlue100'
  | 'brandWhite'
  | 'dangerRed400'
  | 'dangerRed300'
  | 'grey500'
  | 'successGreen100'

type LabelSizes = 'regular' | 'small'

type Variants =
  | 'secondBoxDiscount'
  | 'oneOff'
  | 'bestValue'
  | 'discount'
  | 'recommended'
  | 'recommendedExtra'
  | 'recommendedServingSize'
  | 'notRecommended'
  | 'fussy'
  | 'extra'
  | 'freshMeal'
  | 'replacement'
  | 'shopItem'
  | 'product'
  | 'subscribeAndSave'
  | 'recurring'
  | 'earliestDate'
  | 'save'
  | 'freeSample'

type Props = {
  colour?: SupportedColours | string
  text: TextProps
  size?: LabelSizes
  variant?: Variants
  delivery?: boolean
  id?: string
  icon?: JSX.Element
  rowDirection?: boolean
}

type LabelVariant = {
  backgroundColor: SupportedColours | string | undefined
  textColor: AllowedColours | undefined
}

const colorIsHex = (color: string): boolean => color[0] === '#'

const generateVariant = (
  variant: Props['variant'],
  colour: Props['colour'],
  text: Props['text']
): LabelVariant | undefined => {
  const colors: LabelVariant | undefined = {
    backgroundColor: undefined,
    textColor: undefined
  }

  if (variant) {
    switch (variant) {
      case 'discount': {
        colors.backgroundColor = 'brandRed400'
        colors.textColor = 'brandWhite'
        break
      }
      case 'bestValue':
      case 'freshMeal':
      case 'recommendedServingSize': {
        colors.backgroundColor = 'brandBlue500'
        colors.textColor = 'brandWhite'
        break
      }
      case 'replacement': {
        colors.backgroundColor = 'grey500'
        colors.textColor = 'brandWhite'
        break
      }
      case 'recommended':
      case 'extra':
      case 'fussy':
      case 'shopItem': {
        colors.backgroundColor = 'brandYellow300'
        colors.textColor = 'brandBlue500'
        break
      }
      case 'notRecommended': {
        colors.backgroundColor = 'dangerRed300'
        colors.textColor = 'brandWhite'
        break
      }
      case 'secondBoxDiscount': {
        colors.backgroundColor = 'brandPink100'
        colors.textColor = 'brandBlue500'
        break
      }
      case 'oneOff':
      case 'subscribeAndSave': {
        colors.backgroundColor = 'brandYellow200'
        colors.textColor = 'brandBlue500'
        break
      }
      case 'recommendedExtra':
      case 'product': {
        colors.backgroundColor = 'brandBlue200'
        break
      }
      case 'recurring':
      case 'earliestDate':
      case 'save': {
        colors.backgroundColor = 'brandBlue100'
        colors.textColor = 'brandBlue500'
        break
      }
      case 'freeSample': {
        colors.backgroundColor = 'brandRed100'
        colors.textColor = 'brandBlue500'
        break
      }
      default: {
        ensureNever(variant)
      }
    }
    /* if is a hex color passed from admin */
  } else if (!isUndefined(colour) && colorIsHex(colour)) {
    colors.backgroundColor = colour
    colors.textColor = isDark(colour) ? 'brandWhite' : 'brandBlue500'
    /* if label color is defined by text object */
  } else if (!isUndefined(text.colour)) {
    colors.backgroundColor = colour
    colors.textColor = text.colour
  } else {
    Sentry.captureException('Label could not be generated from passed values', {
      extra: { variant, colour, text }
    })
  }

  return colors
}

const Label = ({
  text,
  size,
  variant,
  colour,
  delivery,
  icon,
  id,
  rowDirection
}: Props): JSX.Element | null => {
  const labelVariant = generateVariant(variant, colour, text)

  if (isUndefined(labelVariant) || isUndefined(labelVariant.backgroundColor))
    return null

  const backgroundIsHex: boolean = colorIsHex(labelVariant.backgroundColor)

  const className = classnames(STYLES.container, {
    [STYLES[labelVariant.backgroundColor as keyof typeof STYLES]]:
      !backgroundIsHex,
    [STYLES[size as keyof typeof STYLES]]: !isUndefined(size),
    [STYLES.rowDirection]: rowDirection
  })

  return (
    <div
      className={className}
      style={{
        backgroundColor: backgroundIsHex
          ? labelVariant.backgroundColor
          : undefined
      }}
      data-testid={id}
    >
      {delivery && <img alt="" src={Van} />}
      {icon}
      <Text
        namespace={text.namespace}
        text={text.text}
        margin={text.margin}
        translate={text.translate}
        variables={text.variables}
        variant={size === 'small' ? 'textRegular12' : 'textRegular14'}
        colour={labelVariant.textColor}
        element={text.element || 'span'}
        shouldScale={text.shouldScale}
      />
    </div>
  )
}

export type { Props, Variants, SupportedColours, LabelSizes }

export { generateVariant }
export default Label
