// @noflow
import { userHasActivePaymentMethod } from '@/context/accessManagement/accessManagement'
import { useQuery, useReactiveVar } from '@apollo/client'
import { Grid } from '@mui/material'
import { addDays, isAfter, isBefore, isToday, subDays } from 'date-fns'
import isUndefined from 'lodash/isUndefined'
import uniqBy from 'lodash/uniqBy'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { SwiperClass } from 'swiper/react'

import {
  featureFlagsDataVar,
  settingsVar,
  subscriptionDataVar
} from '@/services/apollo'

import DachshundInBox from 'assets/images/illustrations/dogs/dachshund-in-box-blue-300.svg'

import { CardSkeleton } from '@/components/elements/atoms/Card/CardSkeleton'
import Text from '@/components/elements/atoms/Text/Text'
import { UndeliverableDateAdjustment } from '@/components/elements/molecules/UndeliverableDateAdjustment'
import { DeliveryTracking } from '@/components/elements/organisms/DeliveryTracking'
import { useTransformedDeliveryStatus } from '@/components/elements/organisms/DeliveryTracking/hooks'
import OrderCarousel from '@/components/elements/organisms/OrderCarousel/OrderCarousel'
import BoxDetails from '@/components/pages/Dashboard/components/boxDetails/BoxDetails'
import {
  upcomingBoxQuery,
  upcomingBoxQueryVariables,
  upcomingBoxQuery_user_ordersByDate,
  upcomingBoxQuery_user_ordersByDate_Box,
  upcomingBoxQuery_user_ordersByDate_NonCoreOrder
} from '@/components/pages/Dashboard/components/upcomingBoxes/queries/__generated__/upcomingBoxQuery'
import {
  Order,
  unifyOrder
} from '@/components/pages/Dashboard/components/upcomingBoxes/utilities'

import STYLES from './UpcomingBoxes.module.sass'

import { UPCOMING_BOX_QUERY } from './queries/queries'

import { Status } from '@/types'

import { upcomingBoxesLoaded } from '../../Dashboard'

/**
 * Find which order should be selected first. If there is no upcoming order select the last delivered
 * @param ordersByDate
 */
const getInitialOrder = (
  ordersByDate: upcomingBoxQuery_user_ordersByDate[]
): string | undefined => {
  if (ordersByDate.length === 0) return undefined

  const nextOrder = ordersByDate.find((order) => {
    if ('isoDeliveryDate' in order)
      return isAfter(new Date(order.isoDeliveryDate), new Date())

    if (
      'orderParts' in order &&
      'deliveryDate' in order.orderParts &&
      order.orderParts.length > 0
    )
      return isAfter(new Date(order.orderParts[0].deliveryDate), new Date())

    return false
  })

  if (!isUndefined(nextOrder) && 'id' in nextOrder) return nextOrder.id

  const lastOrder = ordersByDate[ordersByDate.length - 1]

  if ('id' in lastOrder) return lastOrder.id

  return undefined
}

const NoOrders = () => {
  const subscription = useReactiveVar(subscriptionDataVar)

  return (
    <div className={STYLES.noOrders}>
      <img
        alt=""
        src={DachshundInBox}
        className={STYLES.noOrdersIllustration}
      />
      <Text
        text="home.upcoming_boxes.no_orders.title"
        namespace="account"
        variant="display18"
      />
      {!userHasActivePaymentMethod(subscription?.paymentMethods) && (
        <Text text="home.upcoming_boxes.no_orders.body" namespace="account" />
      )}
    </div>
  )
}

const INITIAL_SLIDE_FETCH_COUNT = 6
const SLIDE_FETCH_COUNT = 3

type Props = {
  filter?: (Order['type'] | 'past')[]
}

const UPCOMING_BOX_QUERY_VARIABLES = {
  offset: 0,
  limit: INITIAL_SLIDE_FETCH_COUNT,
  order: 'desc'
}

/**
 * A section consisting of an orders carousel and order details component
 * @constructor
 */
const UpcomingBoxes = ({ filter }: Props): JSX.Element | null => {
  const [swiper, setSwiper] = useState<SwiperClass>()
  const [boxId, setBoxId] = useState<string | undefined>()
  const [order, setOrder] = useState<Order | undefined>()
  const [firstBoxId, setFirstBoxId] = useState<string | undefined>()
  const { state } = useLocation()
  const { incompleteBeforeDelivery } = useTransformedDeliveryStatus(
    (order?.deliveryStatus as Status) ?? Status.unknown
  )
  const subscriptionData = useReactiveVar(subscriptionDataVar)
  const featureFlagsData = useReactiveVar(featureFlagsDataVar)
  const settingsData = useReactiveVar(settingsVar)

  const shouldSeeSelfResolutions =
    featureFlagsData?.shouldSeeSelfResolutions === 'true'

  const { data, loading, fetchMore } = useQuery<
    upcomingBoxQuery,
    upcomingBoxQueryVariables
  >(UPCOMING_BOX_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: UPCOMING_BOX_QUERY_VARIABLES
  })

  // set initial
  useEffect(() => {
    if (!isUndefined(data)) {
      setFirstBoxId(data.user.subscription?.firstBox.id)
      if (!isUndefined(swiper)) {
        if (isUndefined(order) && data.user.ordersByDate.length > 0) {
          const ordersByDate = [...data.user.ordersByDate].reverse()

          // if have returned from order details page
          const previouslyViewedBoxId = state?.previous?.params?.boxId

          const id = !isUndefined(previouslyViewedBoxId)
            ? previouslyViewedBoxId
            : getInitialOrder(ordersByDate)

          setBoxId(id)
        }
      }
    }
  }, [data, order, state?.previous?.params?.boxId, swiper])

  // update the displayed order when a boxId is set
  useEffect(() => {
    if (!isUndefined(boxId) && !isUndefined(data)) {
      const order:
        | upcomingBoxQuery_user_ordersByDate_Box
        | upcomingBoxQuery_user_ordersByDate_NonCoreOrder
        | undefined = data.user.ordersByDate.find(
        (order) => 'id' in order && order.id === boxId
      )

      if (!isUndefined(order)) setOrder(unifyOrder(order))
    }
  }, [boxId, data])

  useEffect(() => {
    if (!loading && data) {
      upcomingBoxesLoaded(true)
    }
  }, [loading, data])

  const { user } = data || {}
  const { ordersByDate, subscription, currentDeliveryAreaPreference } =
    user || {}
  const preferredCarrierName = settingsData.carrier_preferences_enabled
    ? currentDeliveryAreaPreference?.preferredCarrierService?.carrier?.name
    : null

  const {
    id,
    type,
    deliveryDate,
    cutOffDate,
    shippingDate,
    isFull,
    meals,
    extras,
    amendable,
    shouldOfferSelfService,
    deliveryStatus,
    numberOfPouches,
    pouchSize,
    durationInDays,
    isTrialBox,
    isBoosted,
    isFreeBox,
    isReplacementBox,
    pricing
  } = order || {}

  const orderDetailsReady =
    !isUndefined(id) &&
    !isUndefined(type) &&
    !isUndefined(amendable) &&
    !isUndefined(deliveryDate) &&
    !isUndefined(cutOffDate) &&
    !isUndefined(shippingDate) &&
    !isUndefined(shouldOfferSelfService)

  const keepFetching = ordersByDate
    ? ordersByDate.findIndex((order) => order.id === firstBoxId) === -1
    : true

  const carouselOrders = useMemo(() => {
    return ordersByDate
      ? uniqBy(
          ordersByDate
            .map((order) => unifyOrder(order))
            .filter((order) => {
              if (!Array.isArray(filter)) return true
              if (filter.includes(order.type)) return false
              if (
                filter.includes('past') &&
                isAfter(subDays(new Date(), 3), new Date(order.deliveryDate))
              )
                return false
              return true
            })
            .reverse(),
          (order) => order.id
        )
      : []
  }, [filter, ordersByDate])

  const initialSlide = useMemo(() => {
    return carouselOrders.findIndex((order) =>
      isAfter(new Date(order.deliveryDate), new Date())
    )
  }, [carouselOrders])

  // if the slider reaches the furthest most order then fetch the next set of orders if possible
  const increaseOrderHistory = useCallback(() => {
    if (!loading) {
      fetchMore({
        variables: {
          offset: carouselOrders.length,
          limit: SLIDE_FETCH_COUNT
        }
      })
    }
  }, [loading, fetchMore, carouselOrders.length])

  const noOrders = !isUndefined(data) && carouselOrders.length === 0
  const showDeliveryTrackingFlow = (() => {
    const currentDate = new Date()
    const formattedDeliveryDate = order?.deliveryDate ?? currentDate
    const status = order?.deliveryStatus
    const deliveryState = localStorage.getItem(
      // eslint-disable-next-line i18next/no-literal-string
      `deliveryState-${order?.type}-${order?.id}`
    )

    // If delivery is successful, return false
    if (deliveryState && JSON.parse(deliveryState) === 'delivered_success')
      return false

    if (!status) return false

    // If 3 days have passed from delivery date, return false
    if (isAfter(currentDate, addDays(formattedDeliveryDate, 3))) {
      return false
    }

    // Check if before delivery and incomplete
    if (
      isBefore(currentDate, formattedDeliveryDate) &&
      incompleteBeforeDelivery
    )
      return false

    // Otherwise, use the data value
    return data?.shouldSeeDeliveryTracking === 'true'
  })()

  return (
    <Grid container rowSpacing={3}>
      <Grid item className={STYLES.slider}>
        {noOrders && <NoOrders />}
        <OrderCarousel
          selected={boxId}
          orders={carouselOrders}
          onLazyLoadPrev={keepFetching ? increaseOrderHistory : undefined}
          slider={setSwiper}
          onSlideClick={setBoxId}
          initialSlide={initialSlide}
        />
      </Grid>
      {showDeliveryTrackingFlow && order ? (
        <Grid item width="100%">
          <DeliveryTracking
            order={order}
            deliveredToday={isToday(order.deliveryDate)}
          />
        </Grid>
      ) : (
        <UndeliverableDateAdjustment selectedOrder={order} />
      )}
      <Grid item width="100%">
        {order && orderDetailsReady ? (
          <BoxDetails
            id={id}
            type={type}
            amendable={amendable}
            descriptor={order.descriptor}
            deliveryDate={deliveryDate}
            cutOffDate={cutOffDate}
            shippingDate={shippingDate}
            meals={meals}
            extras={extras}
            isFull={isFull}
            numberOfPouches={numberOfPouches}
            pouchSize={pouchSize}
            durationInDays={durationInDays}
            shouldOfferSelfService={
              shouldOfferSelfService && shouldSeeSelfResolutions
            }
            deliveryStatus={deliveryStatus}
            isBoostedBox={isBoosted}
            isTrialBox={isTrialBox}
            isFreeBox={isFreeBox}
            isReplacementBox={isReplacementBox}
            pricing={pricing}
            nextUpcomingPrice={subscription?.plan?.nextUpcomingPrice}
            planPricePerDay={subscription?.plan?.pricePerDay}
            subscriptionStatus={subscriptionData?.status}
            preferredCarrierName={preferredCarrierName}
          />
        ) : (
          isUndefined(data) && <CardSkeleton height="40rem" />
        )}
      </Grid>
    </Grid>
  )
}

export default UpcomingBoxes
export { UPCOMING_BOX_QUERY_VARIABLES }
