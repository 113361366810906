// @noflow
import i18next from 'i18next'
import 'whatwg-fetch'

import client from '@/components/apollo/client'

import { CHARGE_ID_FROM_PAYMENT_INTENT_ID } from '../queries/chargeIdFromPaymentIntentId'
import { SEPA_PAYMENT_METHOD_ID_FROM_INTENT_SECRET } from '../queries/sepaPaymentMethodIdFromIntentSecret'

import { ChargeIdFromPaymentIntentId } from '../queries/__generated__/ChargeIdFromPaymentIntentId'
import { SepaPaymentMethodIdFromIntentSecret } from '../queries/__generated__/SepaPaymentMethodIdFromIntentSecret'
import type { IntentType } from '@/types'

import * as ANALYTICS from '../../../Analytics/CheckoutAnalytics'
import { checkoutPageState } from '../../../CheckoutPage'
import type { PaymentMethod } from '../../../types'
import { bannerMessageState } from '../Banner'
import { handleSubmitSubscription } from './buySubscriptionWithCard'
import { subscriptionData } from './submitSubscription'

const buySubscriptionWithSepaDirectDebit = ({
  intentSecret,
  csrfToken,
  paymentMethod,
  intentType
}: {
  intentSecret: string
  csrfToken: string
  paymentMethod: PaymentMethod
  intentType: IntentType
}): void => {
  client
    .query<SepaPaymentMethodIdFromIntentSecret>({
      query: SEPA_PAYMENT_METHOD_ID_FROM_INTENT_SECRET,
      variables: {
        intentSecret: intentSecret,
        intentType
      }
    })
    .then((result) => {
      if (result.error) {
        bannerMessageState({
          message:
            result.error.message ||
            i18next.t('checkout:errors.delivery_details_fetch'),
          type: 'error'
        })
        ANALYTICS.setPaymentError({
          error:
            result.error.message ||
            'Checkout Error in buySubscriptionWithSepaDirectDebit',
          paymentMethod: paymentMethod
        })
      } else {
        const checkoutData = checkoutPageState()
        const data = subscriptionData({ state: checkoutData })
        const paymentMethodId = result.data.sepaPaymentMethodIdFromIntentSecret

        // If we've taken payment for the first box we need to fetch the charge ID and pass that into subscription creation.
        if (intentType === 'payment_intent') {
          client
            .query<ChargeIdFromPaymentIntentId>({
              query: CHARGE_ID_FROM_PAYMENT_INTENT_ID,
              variables: {
                paymentIntentId: intentSecret.split('_secret_')[0],
                paymentMethodType: 'sepa_direct_debit'
              }
            })
            .then((result) => {
              handleSubmitSubscription({
                data,
                csrfToken,
                paymentMethodId: paymentMethodId,
                paymentMethodType: paymentMethod,
                boxOneChargeId: result.data.chargeIdFromPaymentIntentId
              })
            })
        } else {
          handleSubmitSubscription({
            data,
            csrfToken,
            paymentMethodId: paymentMethodId,
            paymentMethodType: paymentMethod
          })
        }
      }
    })
}

export default buySubscriptionWithSepaDirectDebit
