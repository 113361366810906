import { gql } from '@apollo/client'

const SWAP_AND_SAVE_PAGE_QUERY = gql`
  query SwapAndSavePageQuery {
    user {
      id
      shippingCountryCode
      preferredLanguage
      dogs {
        id
        gender
      }
      subscription {
        id
        status
        recurringOrder {
          deliveryFee
          planTotal
          planTotalPerDay
        }
        plan {
          id
          pouchSize
          numberOfPouches
          durationInDays
          typeOfPlanForCustomer
          servingSize
          swappablePlan {
            id
            price
            pricePerDay
            pouchSize
            numberOfPouches
            durationInDays
            typeOfPlanForCustomer
            servingSize
          }
        }
      }
    }
  }
`
const DOG_PLAN_PORTION_QUERY = gql`
  query DogPlanPortionQuery($planId: ID!) {
    user {
      id
      dogs {
        id
        name
        planPortionInGrams
        planPortionInGramsForPlan(planId: $planId)
        gender
      }
    }
  }
`

export { SWAP_AND_SAVE_PAGE_QUERY, DOG_PLAN_PORTION_QUERY }
