// @noflow
import { useMutation } from '@apollo/client'
import {
  BillingRequest,
  GoCardlessDropinOptions,
  useGoCardlessDropin
} from '@gocardless/react-dropin'
import i18next from 'i18next'
import React from 'react'

import * as Sentry from '@/utils/sentry'
import { isInAppBrowser } from '@/utils/userAgent'

import * as ANALYTICS from '../components/pages/CheckoutPage/Analytics/CheckoutAnalytics'
import { checkoutPageState } from '../components/pages/CheckoutPage/CheckoutPage'
import { bannerMessageState } from '../components/pages/CheckoutPage/components/PaymentSection/Banner'
import { setSubmissionState } from '../components/pages/CheckoutPage/components/PaymentSection/PaymentSection'
import { handleSubmitSubscription } from '../components/pages/CheckoutPage/components/PaymentSection/helpers/buySubscriptionWithCard'
import { subscriptionData } from '../components/pages/CheckoutPage/components/PaymentSection/helpers/submitSubscription'
import type { SubscriptionData } from '../components/pages/CheckoutPage/components/PaymentSection/helpers/submitSubscription'
import type { billingRequestFlowCreate } from '../components/pages/CheckoutPage/queries/__generated__/billingRequestFlowCreate'
// GraphQL
import { BILLING_REQUEST_FLOW_CREATE } from '../components/pages/CheckoutPage/queries/billingRequestFlowCreateMutation'
import { GOCARDLESS_USER_PARAM_CREATE } from '../components/pages/CheckoutPage/queries/gocardlessUserParamCreateMutation'
import { INSTANT_CHARGE_SETUP_CREATE } from '../components/pages/MyDetailsPage/screens/PaymentMethods/mutations/InstantChargeSetupCreate'
import { PAY_OVERDUE_INVOICES_AND_UNSUSPEND } from '../components/pages/MyDetailsPage/screens/PaymentMethods/mutations/PayOverdueInvoicesAndUnsuspend'
import type { instantChargeSetupCreate } from '../components/pages/MyDetailsPage/screens/PaymentMethods/mutations/__generated__/instantChargeSetupCreate'
import type { payOverdueInvoicesAndUnsuspend } from '../components/pages/MyDetailsPage/screens/PaymentMethods/mutations/__generated__/payOverdueInvoicesAndUnsuspend'
import client from '@/components/apollo/client'

type UsePayWithGoCardless = {
  handleBuySubscriptionWithGoCardless: () => void
  payOutstandingInvoicesWithGoCardless: () => void
  loading: boolean
}

enum TransactionType {
  MandateSetup = 'mandate_setup',
  InstantPay = 'instant_pay'
}

const usePayWithGoCardless = ({
  csrfToken
}: {
  csrfToken?: string
} = {}): UsePayWithGoCardless => {
  const [billingRequestFlowId, setBillingRequestFlowId] = React.useState('')
  const [goCardlessModalOpen, setGoCardlessModalOpen] = React.useState(false)
  const transactionType = React.useRef<TransactionType>()
  const { sections } = checkoutPageState()
  const copyContext = 'checkout:payment_section.go_cardless_error'
  // eslint-disable-next-line i18next/no-literal-string
  const paymentMethodsPageUrl = '/dashboard/my-details/payment-methods'

  const [billingRequestFlowCreate, { loading: loadingMandateModal }] =
    useMutation<billingRequestFlowCreate>(BILLING_REQUEST_FLOW_CREATE, {
      variables: {
        email: sections.accountDetails.form.email.value,
        address: {
          postcode: sections.addressDetails.form.postcode.value,
          addressLineOne: sections.addressDetails.form.addressLine1.value,
          addressLineTwo: sections.addressDetails.form.addressLine2.value,
          recipientName: sections.accountDetails.form.name.value,
          city: sections.addressDetails.form.city.value,
          shippingCountryId: 1
        }
      },
      onCompleted: (data) => {
        if (data.billingRequestFlowCreate) {
          setBillingRequestFlowId(
            data.billingRequestFlowCreate.billingRequestFlowId
          )
          setGoCardlessModalOpen(true)
        }
      },
      onError: (error) => {
        bannerMessageState({
          message: i18next.t(copyContext),
          type: 'error'
        })
        Sentry.captureException(
          `Error encountered in BILLING_REQUEST_FLOW_CREATE`,
          { extra: { error } }
        )
      }
    })

  const [instantChargeSetupCreate, { loading: loadingBankPayModal }] =
    useMutation<instantChargeSetupCreate>(INSTANT_CHARGE_SETUP_CREATE, {
      variables: {
        redirectUrl: paymentMethodsPageUrl
      },
      onCompleted: (data) => {
        if (data.instantChargeSetupCreate) {
          setBillingRequestFlowId(
            data.instantChargeSetupCreate?.instantChargeSetupToken
          )
          setGoCardlessModalOpen(true)
        }
      },
      onError: (error) => {
        bannerMessageState({
          message: i18next.t(copyContext),
          type: 'error'
        })
        Sentry.captureException(
          `Error encountered in BILLING_REQUEST_FLOW_CREATE`,
          { extra: { error } }
        )
      }
    })

  const [payOverdueInvoicesAndUnsuspend, { loading: unsuspendLoading }] =
    useMutation<payOverdueInvoicesAndUnsuspend>(
      PAY_OVERDUE_INVOICES_AND_UNSUSPEND,
      {
        onCompleted: () => {
          window.location.href = paymentMethodsPageUrl
        }
      }
    )

  const storeGoCardlessUserParam = (data: SubscriptionData) => {
    client.mutate({
      mutation: GOCARDLESS_USER_PARAM_CREATE,
      variables: {
        email: data.user.email,
        planId: data.plan_id,
        userParams: JSON.stringify(data)
      }
    })
  }

  const buySubscription = (billingRequest: BillingRequest) => {
    sections.paymentDetails.form.selectedPaymentMethod.type = 'goCardless'
    const checkoutData = checkoutPageState({
      ...checkoutPageState(),
      sections
    })

    ANALYTICS.submissionAttempt('goCardless')
    const data = subscriptionData({ state: checkoutData })

    setSubmissionState({ type: 'loading' })
    if (csrfToken) {
      handleSubmitSubscription({
        data,
        csrfToken,
        paymentMethodType: 'goCardless',
        paymentMethodId: billingRequest.links.mandate_request_mandate,
        boxOneChargeId: billingRequest.links.payment_request_payment
      })
    } else {
      Sentry.captureException(
        'No CSRF token provided to useBuySubscriptionWithGoCardless hook'
      )
    }
  }

  const config: GoCardlessDropinOptions = {
    billingRequestFlowID: billingRequestFlowId,
    environment: process.env.NODE_ENV === 'production' ? 'live' : 'sandbox',
    onSuccess: (billingRequest) => {
      if (transactionType.current === TransactionType.MandateSetup) {
        buySubscription(billingRequest)
      }
      if (transactionType.current === TransactionType.InstantPay) {
        payOverdueInvoicesAndUnsuspend({
          variables: {
            paymentId: billingRequest.links.payment_request_payment,
            provider: 'gocardless'
          }
        })
      }
    },
    onExit: (error, metadata) => {
      if (error) {
        bannerMessageState({
          message: i18next.t(copyContext),
          type: 'error'
        })
        Sentry.captureException(`Billing request flow exited with error`, {
          extra: { error, metadata }
        })
      }
    }
  }

  const { open } = useGoCardlessDropin(config)

  React.useEffect(() => {
    if (goCardlessModalOpen) {
      open()
    }
  }, [goCardlessModalOpen, open])

  const handleBuySubscriptionWithGoCardless = React.useCallback((): void => {
    transactionType.current = TransactionType.MandateSetup
    ANALYTICS.goCardlessButtonClicked(isInAppBrowser(navigator.userAgent))

    sections.paymentDetails.form.selectedPaymentMethod.type = 'goCardless'
    checkoutPageState({
      ...checkoutPageState(),
      sections
    })
    const checkoutData = checkoutPageState()
    const data = subscriptionData({ state: checkoutData })
    storeGoCardlessUserParam(data)
    billingRequestFlowCreate()
  }, [billingRequestFlowCreate, sections])

  const payOutstandingInvoicesWithGoCardless = () => {
    transactionType.current = TransactionType.InstantPay
    instantChargeSetupCreate()
  }

  const loading = unsuspendLoading || loadingBankPayModal || loadingMandateModal

  return {
    handleBuySubscriptionWithGoCardless,
    payOutstandingInvoicesWithGoCardless,
    loading
  }
}

export default usePayWithGoCardless
