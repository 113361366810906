// @noflow
import { ACCOUNT_ROUTES } from '@/routes'
import React, { useCallback, useContext } from 'react'

import { Button } from '@/components/elements/atoms/Button'
import Icon from '@/components/elements/atoms/Icon/Icon'
import Text from '@/components/elements/atoms/Text/Text'
import { NavigateContext } from '@/components/pages/App'
import ExtrasPageRoutes from '@/components/pages/ExtrasPage/routes'

// import { Link } from 'react-router-dom'
// import ExtrasPageRoutes from '../../../../routes'
import STYLES from './EmptyBasketState.module.sass'

const EmptyBasketPage = (): JSX.Element => {
  const navigate = useContext(NavigateContext)
  const goToShop = useCallback(() => {
    navigate(ACCOUNT_ROUTES.extras, ExtrasPageRoutes.ExtrasList)
  }, [navigate])
  return (
    <div className={STYLES.container}>
      <div className={STYLES.wrapper}>
        <div className={STYLES.icon}>
          <Icon size={90} asset="shoppingDog" />
        </div>
        <Text
          text="extras.basket.empty_basket.title"
          namespace="dashboard"
          variant="textRegular18"
          colour="brandBlue500"
          bold
        />
        <Text
          text="extras.basket.empty_basket.subtitle"
          namespace="dashboard"
          variant="textRegular18"
          colour="brandBlue400"
          margin={false}
        />
        <Button
          typography={{
            namespace: 'dashboard',
            text: 'extras.basket.empty_basket.button'
          }}
          variant="primary"
          identifier="empty-basket-button"
          onClick={goToShop}
        />
      </div>
    </div>
  )
}

export default EmptyBasketPage
