import { gql } from '@apollo/client'

import { USER_FRAGMENT } from '../fragments/fragments'

const INITIAL_DATA = gql`
  query MyDetailsInitialDataQuery {
    user {
      id
      firstName
      lastName
      shouldSupportSca
      email
      shippingCountryCode
      affiliateType
      preferredLanguage
      token
      subscription {
        id
        accountType
        status
      }
    }
  }
`

const PERSONAL_DETAILS = gql`
  ${USER_FRAGMENT}
  query MyDetailsPersonalDetailsQuery {
    user {
      ...userFragment
    }
  }
`

export { INITIAL_DATA, PERSONAL_DETAILS }
