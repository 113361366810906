// @flow

import type { TabValues } from '../shared/SelectBarComponent'

const TABS: Array<TabValues> = [
  {
    title: 'Your Summary',
    tabTitle: 'Summary',
    hash: 'summary',
    pathname: 'summary',
  },
  {
    title: 'Influencer Code',
    tabTitle: 'Refer',
    hash: 'refer',
    pathname: 'refer',
  },
]

export {
  TABS,
}
