// @noflow
import { TOptions } from 'i18next'
import React, { useCallback, useEffect, useState } from 'react'

import TwoDogsTalk from 'assets/images/illustrations/dogs/two-dogs-talk.svg'

import LoadingScreen from '@/components/elements/organisms/LoadingScreen/LoadingScreen'

import type { LoadingScreenType } from '../../types/types'

type Props = {
  children: JSX.Element
  namespace: string
  loading: boolean
  loadingScreen: LoadingScreenType
}

const SelfResolutionLoading = ({
  children,
  namespace,
  loading,
  loadingScreen
}: Props): JSX.Element => {
  const [title, setTitle] = useState({
    namespace: namespace,
    text: 'loading_screen.default',
    variables: {}
  })

  const setLoadingScreenAttributes = useCallback(
    (text: string, variables: TOptions = {}) => {
      setTitle({
        ...title,
        text,
        variables
      })
    },
    [title]
  )

  useEffect(() => {
    switch (loadingScreen) {
      case 'wereStillHere':
        setLoadingScreenAttributes('loading_screen.were_still_here')
        break
      case 'submittingReport':
        setLoadingScreenAttributes('loading_screen.submitting_report')
        break
      default:
        setLoadingScreenAttributes('loading_screen.default')
    }
  }, [loadingScreen, setLoadingScreenAttributes])

  if (loading && title) {
    return (
      <LoadingScreen
        isOpen={loading}
        title={title}
        image={TwoDogsTalk}
        variant={'static'}
      />
    )
  } else {
    return children
  }
}

export default SelfResolutionLoading
