import { gql } from '@apollo/client'

const SHARE_PROFILE_FRAGMENT = gql`
  fragment ShareProfileFragment on User {
    id
    subscription {
      id
      referralLink {
        url
      }
    }
    dogs {
      id
      name
      possessivePronoun
      dogProfile {
        id
        avatarUrl
        dogActivities {
          activity
        }
        dogPersonalityTraits {
          trait
        }
        favouriteGame
      }
    }
  }
`

export { SHARE_PROFILE_FRAGMENT }
