import React from 'react'

import MenuIcon from 'assets/images/icons/navigation/icon-squiggly-burger.svg'

import STYLES from './NavMenuIcon.module.sass'

type Props = {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const NavMenuIcon = ({ onClick }: Props): JSX.Element => (
  <button className={STYLES.container} onClick={onClick} type="button">
    <img loading="lazy" src={MenuIcon} alt="menu icon" />
  </button>
)

export { Props }
export default NavMenuIcon
