// @noflow
import type {
  PaymentRequestPaymentMethodEvent,
  PaymentRequestWallet,
  Stripe
} from '@stripe/stripe-js'
import i18next from 'i18next'
import 'whatwg-fetch'

import { Product, captureException } from '@/utils/sentry'

import * as ANALYTICS from '../../../Analytics/CheckoutAnalytics'
import { checkoutPageState } from '../../../CheckoutPage'
import { PaymentMethod } from '../../../types'
import { bannerMessageState } from '../Banner'
import { chargePaymentRequestBoxOne } from './chargeBoxOne'
import { paymentMethodFromWalletName } from './paymentMethodFromWalletName'
import { submitSubscription, subscriptionData } from './submitSubscription'

type Props = {
  event: PaymentRequestPaymentMethodEvent
  csrfToken: string
  paymentMethod: PaymentMethod
  chargeId?: string
}

const handleSubmitSubscription = ({
  event,
  csrfToken,
  chargeId,
  paymentMethod
}: Props) => {
  const checkoutData = checkoutPageState()
  const data = subscriptionData({
    state: checkoutData
  })

  ANALYTICS.submissionAttempt(paymentMethod)

  return submitSubscription({
    data,
    csrfToken,
    paymentMethodId: event.paymentMethod.id,
    boxOneChargeId: chargeId
  })
}

const buySubscriptionWithPaymentRequest = async ({
  event,
  csrfToken,
  stripe
}: {
  stripe: Stripe
  event: PaymentRequestPaymentMethodEvent
  csrfToken: string
}): Promise<void> => {
  let redirectUrl

  const paymentMethodId = event.paymentMethod.id
  const checkoutData = checkoutPageState()
  const data = subscriptionData({
    state: checkoutData
  })

  const paymentMethod = paymentMethodFromWalletName(
    event.walletName as PaymentRequestWallet
  )

  try {
    const chargeId = await chargePaymentRequestBoxOne({
      data,
      paymentMethodId,
      stripe
    })

    const response = await handleSubmitSubscription({
      event,
      csrfToken,
      chargeId,
      paymentMethod
    })

    if (response.error) throw new Error(response.error)

    event.complete('success')
    redirectUrl = response.redirect_to
  } catch (error) {
    event.complete('fail')
    bannerMessageState({
      message: i18next.t('checkout:errors.delivery_details_fetch'),
      type: 'error'
    })

    if (error instanceof Error) {
      captureException(
        'Received error in response in buySubscriptionWithPaymentRequest',
        {
          extra: {
            error
          },
          tags: {
            product: Product.Checkout
          }
        }
      )
      ANALYTICS.setPaymentError({
        error: error.message,
        paymentMethod: paymentMethod
      })
    }
  } finally {
    if (redirectUrl) window.location.href = redirectUrl
  }
}

export { buySubscriptionWithPaymentRequest }
