import { gql } from '@apollo/client'

const STRIPE_USER_PARAMS_SETUP_INTENT_CREATE_MUTATION = gql`
  mutation StripeUserParamsSetupIntentCreate(
    $subscriptionData: SubscriptionDataInput!
    $authenticationType: String!
    $paymentIntentId: String!
  ) {
    stripeUserParamsSetupIntentCreate(
      subscriptionData: $subscriptionData
      authenticationType: $authenticationType
      paymentIntentId: $paymentIntentId
    ) {
      paymentIntentId
    }
  }
`

export { STRIPE_USER_PARAMS_SETUP_INTENT_CREATE_MUTATION }
