import { useLanguage } from '@/context/injectedValues/injectedValues'
import { useQuery } from '@apollo/client'
import { isEqual } from 'date-fns'
import React, { Fragment, useCallback, useState } from 'react'

import { localeToDateLocale } from '@/utils/countryCodeHelper'
import * as Sentry from '@/utils/sentry'

import { CardSkeleton } from '@/components/elements/atoms/Card/CardSkeleton'
import CalendarV2 from '@/components/elements/molecules/CalendarV2'
import { DateConfirmationFooter } from '@/components/pages/ChangeDatePageV2/components/DateConfirmationFooter'

import { PAUSED_CALENDAR_DATES_DATA } from '../queries/pausedCalendarDatesQuery'

import {
  PausedCalendarDatesData,
  PausedCalendarDatesDataVariables
} from '../queries/__generated__/PausedCalendarDatesData'

const today = new Date()

const initialMonthInView = new Date(
  today.getFullYear(),
  today.getMonth(),
  1,
  10
)

type Props = {
  userId?: string
  setSelectedDate: (date: Date | null) => void
  pausedDeliveryDate: Date
  handleSubmit: (date: Date | null) => void
  shipmentType?: string
  shouldAttemptToOfferNextDayDelivery?: boolean
}

const Calendar = ({
  userId,
  setSelectedDate,
  pausedDeliveryDate,
  handleSubmit,
  shipmentType = 'box',
  shouldAttemptToOfferNextDayDelivery = false
}: Props): JSX.Element => {
  const { userLanguage } = useLanguage()

  const [newDate, setNewDate] = useState<null | Date>(null)

  const [footerOpen, setFooterOpen] = useState(false)

  const toggleFooter = useCallback(() => setFooterOpen((prev) => !prev), [])

  const handleConfirmDateChange = useCallback(() => {
    setSelectedDate(newDate)
    handleSubmit(newDate)
    toggleFooter()
  }, [handleSubmit, newDate, setSelectedDate, toggleFooter])

  const handleDateChange = useCallback(
    (date: Date | null) => {
      if (date && !isEqual(new Date(date), pausedDeliveryDate)) {
        setFooterOpen(true)
      }

      setNewDate(date)
    },
    [pausedDeliveryDate]
  )

  const { loading, error, data } = useQuery<
    PausedCalendarDatesData,
    PausedCalendarDatesDataVariables
  >(PAUSED_CALENDAR_DATES_DATA, {
    variables: {
      calendarInitDate: initialMonthInView,
      nDays: 120,
      shipmentType,
      userId,
      shouldAttemptToOfferNextDayDelivery
    }
  })

  if (loading || error || !data) {
    if (error) {
      Sentry.captureException(
        `Error encountered with PAUSED_CALENDAR_DATES_DATA query`,
        {
          extra: {
            error
          },
          tags: {
            product: Sentry.Product.Account,
            team: Sentry.Team.Retention
          }
        }
      )
    }
    return <CardSkeleton height="43rem" />
  }

  const { user, calendarDates } = data
  const { shippingCountryCode, subscription } = user
  const { nextNBoxes } = subscription

  const currentDeliveryDates = nextNBoxes.map((box) => box.isoDeliveryDate)

  const locale = localeToDateLocale(shippingCountryCode, userLanguage || 'en')

  return (
    <Fragment>
      <CalendarV2
        selectedDate={newDate}
        calendarDates={calendarDates}
        currentDeliveryDate={pausedDeliveryDate}
        currentDeliveryDates={currentDeliveryDates}
        shippingCountryCode={shippingCountryCode}
        setSelectedDate={handleDateChange}
        maxMonthsShown={3}
        scrollToSelectedDate={false}
        noCalendarBorder
      />

      {footerOpen && newDate && (
        <DateConfirmationFooter
          selectedDeliveryDate={newDate}
          locale={locale}
          processing={false}
          handleCloseFooter={toggleFooter}
          updateDeliveryDate={handleConfirmDateChange}
          radiusBottomLeft
          radiusBottomRight
          dropShadow
          hideCloseButton
        />
      )}
    </Fragment>
  )
}

export default Calendar
