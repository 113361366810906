import { useReactiveVar } from '@apollo/client'
import React, { useCallback } from 'react'

import Faq from '@/components/elements/molecules/Faq/Faq'

import { customerIssueManagementState } from '../../CustomerIssueManagementPage'
import useCustomerIssueManagementTracking from '../../analytics'

enum FaqType {
  replaceBox = 'replaceBox'
}

type Props = {
  faqType: FaqType
}

const ReplaceBoxFaq = (): JSX.Element => {
  const customerIssueManagementData = useReactiveVar(
    customerIssueManagementState
  )
  const customerIssueManagementTracking = useCustomerIssueManagementTracking()

  const onReplacementBoxFAQToggled = useCallback(() => {
    customerIssueManagementTracking.trackEvent('FAQ clicked', {
      name: 'Replacement Box'
    })
  }, [customerIssueManagementTracking])

  const onPaymentFAQToggled = useCallback(() => {
    customerIssueManagementTracking.trackEvent('FAQ clicked', {
      name: 'Payment'
    })
  }, [customerIssueManagementTracking])

  return (
    <>
      <Faq
        question={{
          namespace: customerIssueManagementData.namespace,
          text: 'resolutionConfirmation.replacementBox.question1',
          variant: 'textRegular16',
          colour: 'brandBlue500'
        }}
        answer={{
          text: 'resolutionConfirmation.replacementBox.answer1',
          namespace: customerIssueManagementData.namespace
        }}
        onClick={onReplacementBoxFAQToggled}
      />
      <Faq
        question={{
          namespace: customerIssueManagementData.namespace,
          text: 'resolutionConfirmation.replacementBox.question2',
          variant: 'textRegular16',
          colour: 'brandBlue500'
        }}
        answer={{
          text: 'resolutionConfirmation.replacementBox.answer2',
          namespace: customerIssueManagementData.namespace
        }}
        onClick={onPaymentFAQToggled}
      />
    </>
  )
}

export { Props }
export default ReplaceBoxFaq
