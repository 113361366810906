/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Affiliate {
  ambassador = 'ambassador',
  influencer = 'influencer',
  not_affiliate = 'not_affiliate'
}

export enum ApplicableDiscountPartDiscount {
  every_nth_box = 'every_nth_box',
  n_boxes = 'n_boxes',
  nth_box = 'nth_box',
  rollover = 'rollover',
  single_use = 'single_use'
}

export enum ApplicableDiscountPartDiscountBasis {
  percentage = 'percentage',
  total = 'total'
}

export enum AppointmentEnum {
  ten_minute = 'ten_minute',
  thirty_minute = 'thirty_minute'
}

export enum Code {
  GB = 'GB',
  IE = 'IE',
  NI = 'NI',
  NL = 'NL'
}

export enum Currency {
  EUR = 'EUR',
  GBP = 'GBP'
}

export enum CustomerIssueCategoryClassification {
  issue = 'issue',
  reason = 'reason'
}

export enum CustomerIssueEntryFieldData {
  association = 'association',
  boolean = 'boolean',
  integer = 'integer',
  multifield = 'multifield',
  string = 'string'
}

export enum CustomerIssueEntryFieldSelectionFormat {
  multiple = 'multiple',
  not_applicable = 'not_applicable',
  single = 'single'
}

export enum CustomerIssueEntryFieldTypesAssociationAssociationType {
  OrderIssueCategory = 'OrderIssueCategory',
  Product = 'Product'
}

export enum CustomerIssueEntryFieldTypesAssociationDisplayType {
  dropdown = 'dropdown',
  multiselect = 'multiselect'
}

export enum CustomerIssueEntryFieldTypesBooleanDisplayType {
  confirmation_box = 'confirmation_box'
}

export enum CustomerIssueEntryFieldTypesIntegerDisplayType {
  stepper = 'stepper'
}

export enum CustomerIssueEntryFieldTypesMultifieldDisplayType {
  product_count = 'product_count'
}

export enum CustomerIssueEntryFieldTypesStringDisplayType {
  freetext = 'freetext'
}

export enum CustomerIssueResolutionDetailsTypeField {
  apply_discount = 'apply_discount',
  order_tracking_number = 'order_tracking_number',
  replace_box = 'replace_box',
  replacement_box_details = 'replacement_box_details',
  track_order = 'track_order'
}

export enum CustomerIssueResolutionKey {
  apply_discount = 'apply_discount',
  replace_box = 'replace_box',
  replacement_box = 'replacement_box',
  track_order = 'track_order'
}

export enum CustomerIssueResolutionResolution {
  invariable = 'invariable',
  variable = 'variable'
}

export enum DiscountCodePartDiscount {
  every_nth_box = 'every_nth_box',
  n_boxes = 'n_boxes',
  nth_box = 'nth_box',
  single_use = 'single_use'
}

export enum DiscountCodePartDiscountBasis {
  percentage = 'percentage',
  total = 'total'
}

export enum Eater {
  eats_anything = 'eats_anything',
  fussy_eater = 'fussy_eater',
  good_eater = 'good_eater',
  picky_eater = 'picky_eater'
}

export enum Frequency {
  one_off = 'one_off',
  recurring = 'recurring'
}

export enum Gender {
  female = 'female',
  male = 'male'
}

export enum Language {
  en = 'en',
  nl = 'nl'
}

export enum Name {
  cancer = 'cancer',
  diabetes = 'diabetes',
  epilepsy = 'epilepsy',
  heart_condition = 'heart_condition',
  ibd = 'ibd',
  joint_problems = 'joint_problems',
  kidney_disease = 'kidney_disease',
  liver_disease = 'liver_disease',
  obesity = 'obesity',
  pancreatitis = 'pancreatitis',
  sensitive_stomach = 'sensitive_stomach',
  skin_or_coat_issues = 'skin_or_coat_issues',
  struvite_stones = 'struvite_stones'
}

export enum NonCoreInvoiceStatus {
  failed = 'failed',
  forgiven = 'forgiven',
  free = 'free',
  paid = 'paid',
  pending = 'pending',
  unpaid = 'unpaid'
}

export enum PaymentMethodable {
  BillingAgreement = 'BillingAgreement',
  CreditCard = 'CreditCard',
  SepaDirectDebit = 'SepaDirectDebit'
}

export enum Profession {
  vet_nurse = 'vet_nurse'
}

export enum SubscriptionStatus {
  active = 'active',
  paused = 'paused',
  suspended = 'suspended'
}

export enum Unit {
  advent_calendar = 'advent_calendar',
  festive_bundle = 'festive_bundle',
  ball = 'ball',
  bandanas = 'bandanas',
  bowls = 'bowls',
  bundle = 'bundle',
  grams = 'grams',
  milliliters = 'milliliters',
  rolls = 'rolls',
  tote_bag = 'tote_bag'
}

export interface AddressInput {
  postcode: string
  addressLineOne: string
  recipientName: string
  addressLineTwo?: string | null
  city: string
  shippingCountryId?: number | null
  deliveryNotes?: string | null
}

export interface CustomerIssueReportEntryInputInput {
  fieldId: string
  booleanValue?: boolean | null
  integerValue?: number | null
  stringValue?: string | null
  associationValue?: string | null
  entryFieldInputs?: CustomerIssueReportEntryInputInput[] | null
}

export interface CustomerIssueResolutionAcceptanceReplacementBoxDetailsInput {
  deliveryDate: any
  deliveryAddress: AddressInput
}

export interface PendingSubscriptionInput {
  flavourIds: string[]
  planId: string
  productIds?: string[] | null
}

export interface ProductInput {
  productId: string
  quantity?: number | null
}

//==============================================================
// END Enums and Input Objects
//==============================================================
