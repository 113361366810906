// @noflow
import { ACCOUNT_ROUTES } from '@/routes'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import cookies from '@/utils/cookies'

import ButternutBoxSprite from 'assets/images/illustrations/loading-screens/meal-pouch-with-bowl-and-yellow-hearts-illustration--sprite.svg'

import SuccessOverlay from '@/components/elements/organisms/SuccessOverlay/SuccessOverlay'
import { NavigateContext } from '@/components/pages/App'

const RestartPlanSuccess = (): JSX.Element | null => {
  const navigate = useContext(NavigateContext)
  const { t } = useTranslation('simplified_plans_flow')

  useEffect(() => {
    setTimeout(() => {
      window.localStorage.removeItem(cookies.restartBoxSelectedDate)
      navigate(
        ACCOUNT_ROUTES.base + '?show_reactivation_confirmation=true',
        '/dashboard?show_reactivation_confirmation=true'
      )
    }, 3600)
  }, [navigate])

  return (
    <SuccessOverlay
      sprite={ButternutBoxSprite}
      imageAlt={t('loading_screen.meal_bowl_img_alt')}
      typography={{
        text: 'success',
        margin: false,
        namespace: 'treatments_paused_page'
      }}
    />
  )
}

export default RestartPlanSuccess
