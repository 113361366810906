// @noflow

/**
 * Manage festive styling across the site
 */
import { ACCOUNT_ROUTES } from '@/routes'
import { useReactiveVar } from '@apollo/client'
import { isUndefined } from 'lodash'
import isNull from 'lodash/isNull'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import { featureFlagsDataVar } from '@/services/apollo'

import Snowfall from '@/utils/snow'

import useLocalStorage from '@/hooks/useLocalStorage'

enum Occasions {
  xmas = 'xmas'
}

const defaultContext = {
  occasion: undefined,
  optedIn: false,
  festiveClass: undefined,
  setOptedIn: undefined,
  xmas: false
}

const FestiveThemeContext =
  React.createContext<FestiveThemeContextProps>(defaultContext)

interface FestiveThemeContextProps {
  occasion: Occasions | undefined
  festiveClass: 'xmas' | undefined // a class name specific to the current occasion
  optedIn: boolean
  setOptedIn: ((optIn: boolean) => void) | undefined
  xmas: boolean
}

interface LocalisationProviderProps {
  children: React.ReactNode
}

/**
 * Check if current date falls between the start and end date of an occasion
 * @param featureFlags - list of current feature flags
 */
const getCurrentOccasion = (
  featureFlags: Record<string, string | null>
): Occasions | undefined => {
  if (
    Object.keys(featureFlags).includes('shouldSeeXmasTheme') &&
    featureFlags.shouldSeeXmasTheme === 'true'
  )
    return Occasions.xmas
}

const generateOccasionLocalStorageKey = (
  occasion: string | undefined
): string => {
  // eslint-disable-next-line i18next/no-literal-string
  if (!isUndefined(occasion)) return `${occasion}-theme-opt-in`
  return ''
}

const Snow = () => (
  <Snowfall
    color="#F5F5F5"
    snowflakeCount={60}
    wind={[0.0, 2.0]}
    speed={[0.0, 1.0]}
    radius={[1.0, 2.0]}
    style={{
      position: 'fixed',
      width: '100vw',
      height: '100vh',
      zIndex: 4
    }}
  />
)

/**
 * Show snowfall on certain pages if xmas
 */
const showSnow = (xmas: boolean, pathname: string) =>
  xmas &&
  !isUndefined(
    [
      ACCOUNT_ROUTES.base,
      `${ACCOUNT_ROUTES.profile}/:dogId`,
      ACCOUNT_ROUTES.extras,
      ACCOUNT_ROUTES.referAFriend
    ].find((path: string) =>
      matchPath(
        {
          path
        },
        pathname
      )
    )
  )

const FestiveThemeProvider = ({
  children
}: LocalisationProviderProps): JSX.Element => {
  const featureFlags = useReactiveVar(featureFlagsDataVar)
  const { pathname } = useLocation()
  const [occasion, setOccasion] = useState<Occasions | undefined>()
  const [optedIn, setOptedIn] = useLocalStorage<string, boolean>(
    generateOccasionLocalStorageKey(occasion),
    false
  )

  const festiveThemeProviderValues = useMemo(
    () => ({
      occasion,
      optedIn,
      setOptedIn,
      festiveClass: optedIn ? occasion : undefined,
      xmas: occasion === Occasions.xmas && optedIn
    }),
    [occasion, optedIn, setOptedIn]
  )

  // parse the current feature flags and set the occasion if applicable
  useEffect(() => {
    if (!isNull(featureFlags)) setOccasion(getCurrentOccasion(featureFlags))
  }, [featureFlags])

  // check if the user has opted in previously
  useEffect(() => {
    if (!isUndefined(occasion)) {
      setOptedIn(
        localStorage.getItem(generateOccasionLocalStorageKey(occasion)) ===
          'true'
      )
    }
  }, [occasion, setOptedIn])

  return (
    <FestiveThemeContext.Provider value={festiveThemeProviderValues}>
      {showSnow(festiveThemeProviderValues.xmas, pathname) && <Snow />}
      {children}
    </FestiveThemeContext.Provider>
  )
}

const useOccasion = (): FestiveThemeContextProps =>
  useContext(FestiveThemeContext)

export {
  FestiveThemeProvider,
  useOccasion,
  Occasions,
  generateOccasionLocalStorageKey
}
