// @noflow
import React from 'react'

import { DynamicImage } from './components/DynamicImage'
import { StaticImage } from './components/StaticImage'
import { Props as ImageProps } from '@/components/elements/atoms/Image/Image'

type Common = {
  avatarUrl: string | null | undefined
  ageInMonths: number
}

type Static = {
  variant?: 'static'
  sprinkles?: boolean
  breed?: string
  border?: boolean
  image?: never
} & Common

type Dynamic = {
  variant?: 'dynamic'
  image: ImageProps['image']
  sprinkles?: never
  breed?: never
  border?: never
} & Common

type Props = Static | Dynamic

const DogAvatar = ({
  variant = 'static',
  ...props
}: Props): JSX.Element | null => {
  switch (variant) {
    case 'static': {
      return <StaticImage {...(props as Static)} />
    }
    case 'dynamic': {
      return <DynamicImage {...(props as Dynamic)} />
    }
    default: {
      return null
    }
  }
}

export type { Props, Static, Dynamic }

export default DogAvatar
