type Indent = {
  top?: number
  right?: number
  bottom?: number
  left?: number
}

type Args = {
  margin?: Indent | number
  padding?: Indent | number
  defaultIndents?: {
    margin?: Indent
    padding?: Indent
  }
}

type TransformIndents = {
  marginStyles: {
    marginTop?: number
    marginRight?: number
    marginBottom?: number
    marginLeft?: number
  }
  paddingStyles: {
    paddingTop?: number
    paddingRight?: number
    paddingBottom?: number
    paddingLeft?: number
  }
}

const transformIndents = ({
  margin,
  padding,
  defaultIndents
}: Args): TransformIndents => {
  const paddingObject =
    typeof padding === 'number'
      ? {
          top: padding,
          right: padding,
          bottom: padding,
          left: padding
        }
      : padding

  const marginObject =
    typeof margin === 'number'
      ? {
          top: margin,
          right: margin,
          bottom: margin,
          left: margin
        }
      : margin

  const extendedIndents = {
    margin: {
      ...defaultIndents?.margin,
      ...marginObject
    },
    padding: {
      ...defaultIndents?.padding,
      ...paddingObject
    }
  }

  const marginStyles = {
    marginTop: extendedIndents.margin?.top,
    marginRight: extendedIndents.margin?.right,
    marginBottom: extendedIndents.margin?.bottom,
    marginLeft: extendedIndents.margin?.left
  }

  const paddingStyles = {
    paddingTop: extendedIndents.padding?.top,
    paddingRight: extendedIndents.padding?.right,
    paddingBottom: extendedIndents.padding?.bottom,
    paddingLeft: extendedIndents.padding?.left
  }

  return { marginStyles, paddingStyles }
}

export { transformIndents }
