// @noflow
import { captureException } from '@sentry/browser'

import {
  PayPalOption,
  PaypalResponse
} from '@/components/pages/CheckoutPage/types'

// @noflow
const getPaypalPaymentAuthorisation = ({
  redirectUrl,
  payPalOption,
  csrfToken,
  userToken,
  setPayPalAuthorisationResponse,
  isLoading
}: {
  redirectUrl: string
  payPalOption: PayPalOption
  csrfToken: string
  userToken: string
  setPayPalAuthorisationResponse: (arg: PaypalResponse) => void
  isLoading: (loading: boolean) => void
}): void => {
  if (payPalOption !== 'with_paypal') {
    return
  }

  const endpoint = '/api/guests/paypal_authorisation'
  // eslint-disable-next-line i18next/no-literal-string
  const queryParams = `redirect_url=${redirectUrl}&token=${userToken}`
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-CSRF-Token': csrfToken
  }
  const method = 'POST'
  const credentials = 'same-origin'
  isLoading(true)
  fetch(`${endpoint}?${queryParams}`, { headers, method, credentials }).then(
    (res: Response): Promise<void> | void => {
      if (!res.ok) {
        captureException(
          `Recieved error from getPaypalPaymentAuthorisation: ${res.status} ${res.statusText}`
        )
      } else {
        try {
          return res.json().then((res: PaypalResponse): void => {
            setPayPalAuthorisationResponse({
              redirect_url: res.redirect_url,
              token: res.token
            })
            isLoading(false)
          })
        } catch (error) {
          captureException(
            `Encountered error parsing response.json from /api/guests/paypal_authorisation: ${error}`
          )
          isLoading(false)
        }
      }
    }
  )
}

export default getPaypalPaymentAuthorisation
