// @flow

import * as React from 'react'
import { connect } from 'react-redux'

import * as THUNKS from '../../thunks'
import * as ACTIONS from '../../actions'

import type { Dispatch } from 'redux'
import type { State } from '../../index'

type PresentationalProps = {|
  dateIsValid: boolean,
  day: number,
  isOpen: boolean,
  month: number,
  year: number,
  displayInvalidRestartDateMessage: boolean
|}

type ActionProps = {|
  closeDeliveryDateCalendar: () => void,
  restartSubscriptionWithDate: () => void,
  updateDay: (SyntheticEvent<HTMLInputElement>) => void,
  updateMonth: (SyntheticEvent<HTMLInputElement>) => void,
  updateYear: (SyntheticEvent<HTMLInputElement>) => void,
|}

type Props =
  & PresentationalProps
  & ActionProps

const mapStateToProps = (state: State): PresentationalProps => {
  const { subscriptionDetails } = state
  const { restartSubscriptionDetails } = subscriptionDetails
  const { isModalOpen, selectedDate, displayInvalidRestartDateMessage } = restartSubscriptionDetails
  const { day, month, year } = selectedDate

  const date = new Date()
  // month - 1 since the month index is 0-based (0 = January)
  date.setFullYear(year, month - 1, day)

  // Ensure that the date entered is a valid calendar date, and it is in the
  // future (provided that it is not a Saturday)
  // It is up to the server to determine whether or not the date is deliverable
  const dateIsValid = (
    date.getFullYear() === year &&
    date.getMonth() === month - 1 &&
    date.getDate() === day &&
    date > new Date() &&
    date.getDay() !== 6 // 6 is Saturday
  )

  return {
    isOpen: isModalOpen,
    day,
    month,
    year,
    dateIsValid,
    displayInvalidRestartDateMessage
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => {
  const closeDeliveryDateCalendar = (): void => dispatch(ACTIONS.toggleRestartModal())
  const restartSubscriptionWithDate = (): void => {
    // We only need access to getState() thus we can ignore the 'no-unused-vars'
    // ESlint warning for the first parameter to the anonymous function
    dispatch((_: void, getState: () => State): void => {
      const { subscriptionDetails, selectedUserIds } = getState()
      const { subscriptionId } = selectedUserIds
      const { restartSubscriptionDetails } = subscriptionDetails
      const { selectedDate } = restartSubscriptionDetails
      const { day, month, year } = selectedDate

      const newDate = { day, month, year }
      dispatch(THUNKS.restartSubscriptionWithDate(newDate, subscriptionId))
    })
  }
  const updateDay = (e: SyntheticEvent<HTMLInputElement>): void => {
    const day = parseInt(e.currentTarget.value)
    if (isNaN(day)) { return }

    // $FlowFixMe
    dispatch(ACTIONS.updateDay(day))
  }
  const updateMonth = (e: SyntheticEvent<HTMLInputElement>): void => {
    const month = parseInt(e.currentTarget.value)
    if (isNaN(month)) { return }

    // $FlowFixMe
    dispatch(ACTIONS.updateMonth(month))
  }
  const updateYear = (e: SyntheticEvent<HTMLInputElement>): void => {
    const year = parseInt(e.currentTarget.value)
    if (isNaN(year)) { return }

    dispatch(ACTIONS.updateYear(year))
  }

  return {
    closeDeliveryDateCalendar,
    restartSubscriptionWithDate,
    updateDay,
    updateMonth,
    updateYear,
  }
}

const RestartSubscriptionModal = ({
  isOpen,
  closeDeliveryDateCalendar,
  restartSubscriptionWithDate,
  day,
  month,
  year,
  updateDay,
  updateMonth,
  updateYear,
  dateIsValid,
  displayInvalidRestartDateMessage,
}: Props): React.Node => {
  if (!isOpen) return null
  return (
    <div className='modal-container'>
      <div className='modal-container__backdrop' />
      <div className='modal-container__modal restart-subscription-modal'>
        <h3>
          { 'Select the date that you would like the next box to be delivered on' }
        </h3>
        <div className='restart-subscription-modal__date-picker'>
          <div className='restart-subscription-modal__date-picker__date'>
            <p>
              { 'Day:' }
            </p>
            <input
              id='day'
              name='day'
              onChange={updateDay}
              placeholder={new Date().getDate()}
              type='number'
              value={day}
            />
          </div>
          <div className='restart-subscription-modal__date-picker__date'>
            <p>
              { 'Month:' }
            </p>
            <input
              id='month'
              name='month'
              onChange={updateMonth}
              placeholder={new Date().getMonth() + 1}
              type='number'
              value={month}
            />
          </div>
          <div className='restart-subscription-modal__date-picker__date'>
            <p>
              { 'Year:' }
            </p>
            <input
              id='year'
              name='year'
              onChange={updateYear}
              placeholder={new Date().getFullYear()}
              type='number'
              value={year}
            />
          </div>
        </div>
        {
          displayInvalidRestartDateMessage && (
            <p>
              { 'Delivery not available on this date.' }
            </p>
          )
        }
        <div className='restart-subscription-modal__footer'>
          <button
            className='card__button button--dark-grey'
            onClick={closeDeliveryDateCalendar}
            type='button'
          >
            { 'CANCEL' }
          </button>
          <button
            className={`card__button button--green ${dateIsValid ? '' : 'button--disabled'}`}
            disabled={!dateIsValid}
            onClick={dateIsValid ? restartSubscriptionWithDate : null}
            type='button'
          >
            { 'SAVE' }
          </button>
        </div>
      </div>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RestartSubscriptionModal)
