// @flow

import * as ACTIONS from '../actions'

import switchSubscriptionToStripeQuery from '../rails_queries/switchSubscriptionToStripe'

// Types
import type { Dispatch, Thunk } from 'redux'
import type { RequestResult } from '../rails_queries/switchSubscriptionToStripe'
import type { State } from '../index'

const switchSubscriptionToStripe = (): Thunk => {
  return (dispatch: Dispatch, getState: () => State): Promise<void> => {
    dispatch(ACTIONS.startSwitchToStripeRequest())
    const { subscriptionDetails, csrfToken } = getState()
    const { id, switchToStripeDetails } = subscriptionDetails
    const { stripeId } = switchToStripeDetails
    const subscriptionId = id
    return switchSubscriptionToStripeQuery({
      subscriptionId, stripeId, csrfToken
    }).then((requestResult: RequestResult): void => {
      switch (requestResult.type) {
        case 'ServerError': {
          dispatch(ACTIONS.switchToStripeFailure(requestResult.message))
          throw new Error(`Server Error: status=${requestResult.status}, message=${requestResult.message}`)
        }
        case 'UnknownError': {
          dispatch(ACTIONS.switchToStripeFailure(requestResult.error))
          throw new Error(requestResult.error)
        }
        case 'Success': {
          dispatch(ACTIONS.toggleSwitchToStripeModal())
          dispatch(ACTIONS.switchToStripeSuccess(requestResult.message))
          break
        }
        default: {
          throw new Error(`switchSubscriptionToStripe: unhandled type ${requestResult.type}`)
        }
      }
    })
  }
}

export default switchSubscriptionToStripe
