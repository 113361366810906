// @flow

import * as React from 'react'
import i18next from 'i18next'

import DogEating from '../../../../../assets/images/illustrations/dogs/dog-eating-from-bowl--yellow.svg'

type InactiveSubscriptionStatus =
  | 'paused'
  | 'suspended'

type Props = {|
  baseUrl: string,
  status: InactiveSubscriptionStatus
|}

class InactiveAccount extends React.Component<Props> {
  namespace = 'ambassadors:dashboard.balance_tab'

  pausedMessage = (): string => {
    if (this.props.status === 'paused') {
      return i18next.t(`${this.namespace}.resume_subscription`)
    } else {
      return i18next.t(`${this.namespace}.couldnt_collect_payment`)
    }
  }

  buttonMessage = (): string => {
    if (this.props.status === 'paused') {
      return i18next.t(`${this.namespace}.resume_my_subscription`)
    } else {
      return i18next.t(`${this.namespace}.update_details`)
    }
  }

  redirectEndpoint = (): string => {
    if (this.props.status === 'paused') {
      return '/dashboard#subscription'
    } else {
      return '/dashboard#account'
    }
  }

  render (): React.Node {
    return (
      <div className='ambassador-balance__card'>
        <div className='ambassador-balance__card__header'>
          <h2>Your subscription is paused</h2>
          <img
            src={DogEating}
            alt='An illustration of a dog eating from a bowl'
          />
          <p>{ this.pausedMessage() }</p>
          <a
            className='btn btn-updated--blue'
            href={`${this.props.baseUrl}${this.redirectEndpoint()}`}
          >
            { this.buttonMessage() }
          </a>
        </div>
      </div>
    )
  }
}

export default InactiveAccount
