// @noflow
import React, { useMemo } from 'react'

import AlertCard from '@/components/elements/atoms/Alert/AlertCard'
import { Button } from '@/components/elements/atoms/Button'
import type { Props as TextProps } from '@/components/elements/atoms/Text/Text'
import ProductUnavailable from '@/components/pages/ExtrasPage/screens/ProductCollection/components/organisms/ProductUnavailable'

import STYLES from './FixedBase.module.sass'

import type {
  SubscriptionStatus,
  UnavailableObjectReason,
  VariantDelivery
} from '../../../../../../../../types/index'

type Props = {
  selectedProductVariantFrequency: VariantDelivery
  isUpdateProductDisabled: boolean
  isUpdateProductInProgress: boolean
  onUpdateProduct: () => void
  error: TextProps | null
  unavailableReason?: UnavailableObjectReason
  productSlug: string
  email: string
  productCollectionSlug: string
  status: SubscriptionStatus
}

const FixedBase = ({
  selectedProductVariantFrequency,
  isUpdateProductDisabled,
  isUpdateProductInProgress,
  onUpdateProduct,
  error,
  unavailableReason,
  productSlug,
  email,
  productCollectionSlug,
  status
}: Props): JSX.Element => {
  // TODO: dynamic check if box is full
  const boxIsFull = false

  const buttonContext = useMemo(() => {
    if (!boxIsFull) {
      return selectedProductVariantFrequency
    } else {
      return 'box_is_full'
    }
  }, [boxIsFull, selectedProductVariantFrequency])

  return (
    <div className={STYLES.container}>
      {!unavailableReason ? (
        <>
          <Button
            typography={{
              namespace: 'dashboard',
              text: 'extras.product_collection.details.quantity_selection.button',
              variables: { context: buttonContext }
            }}
            disableAnalytics
            onClick={onUpdateProduct}
            disabled={
              isUpdateProductInProgress ||
              isUpdateProductDisabled ||
              status === 'suspended'
            }
          />
          {error && (
            <div className={STYLES.alertCard}>
              <AlertCard message={error} variant="error" />
            </div>
          )}
        </>
      ) : (
        <ProductUnavailable
          productSlug={productSlug}
          email={email}
          productCollectionSlug={productCollectionSlug}
          reason={unavailableReason}
        />
      )}
    </div>
  )
}

export default FixedBase
