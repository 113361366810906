import { gql } from '@apollo/client'

const ADDITIONAL_PRODUCT_QUERY = gql`
  query AdditionalProductQuery($slug: String!) {
    productCollection(slug: $slug) {
      id
      name
      images {
        src
      }
      productBenefits {
        id
        name
        image {
          src
        }
      }
      about
      description
    }
  }
`

export { ADDITIONAL_PRODUCT_QUERY }
