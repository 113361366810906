// @noflow
import React, { Fragment } from 'react'

import { Locale } from '@/utils/countryCodeHelper'

import CopyInputButton from '@/components/elements/molecules/CopyInputButton/CopyInputButton'
import ShareModalButton from '@/components/elements/molecules/ShareModalButton/ShareModalButton'
import useDiscountCopy from '@/components/pages/ReferAFriendPage/hooks/useDiscountCopy'
import { ReferAFriendPageQuery_user_subscription_referralLink } from '@/components/pages/ReferAFriendPage/queries/__generated__/ReferAFriendPageQuery'

import type { Currency } from '@/shared_types/rails_models/shipping_countries'

type Props = {
  copyLinkMessage: string
  referralLink: ReferAFriendPageQuery_user_subscription_referralLink
  locale: Locale
  currency: Currency
}

const Referal = ({
  copyLinkMessage,
  referralLink,
  locale,
  currency
}: Props): JSX.Element => {
  const { discountCode } = useDiscountCopy(referralLink, locale, currency)

  return (
    <Fragment>
      <CopyInputButton
        copyLinkMessage={copyLinkMessage}
        namespace="dashbaord"
        referralCode={discountCode}
        railsPage
      />

      <ShareModalButton
        namespace="dashboard"
        buttonText="refer_a_friend.give_and_get_tab.give_and_get_card.cta"
      />
    </Fragment>
  )
}

export default Referal
