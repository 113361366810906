// @noflow
import { useQuery } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import isNull from 'lodash/isNull'
import React from 'react'

import Card from '@/components/elements/atoms/Card/Card'
import Separator from '@/components/elements/atoms/Separator/Separator'
import Spinner from '@/components/elements/atoms/Spinner/Spinner'
import BoxBreakdown, {
  Props as BoxBreakdownProps
} from '@/components/elements/molecules/BoxBreakdown/BoxBreakdown'
import { BOX_SUMMARY_QUERY } from '@/components/elements/molecules/BoxSummary/queries'
import DailyFeedingGuideline from '@/components/elements/molecules/DailyFeedingGuideline/DailyFeedingGuideline'
import {
  BoxPriceBreakdown,
  Props as BoxPriceBreakdownProps
} from '@/components/elements/organisms/BoxPriceBreakdown/BoxPriceBreakdown'

import STYLES from './BoxSummary.module.sass'

import { BoxSummary as IBoxSummary } from './__generated__/BoxSummary'

import { TypeOfPlan } from '../../../../types'

type Props = BoxBreakdownProps &
  BoxPriceBreakdownProps & {
    dogs: Array<{
      name: string
      planPortionInGrams: number
    }>
    planType: TypeOfPlan
  }

/**
 * A breakdown of a users box including grams per day to be fed to each dog, cadence and a price breakdown
 */
const BoxSummary = ({
  dogs,
  planType,
  pouchSize,
  pouches,
  cadence,
  total,
  meals,
  extras,
  surchargeTotal,
  deliveryFee,
  shippingCountryCode,
  preferredLanguage
}: Props): JSX.Element => {
  const namespace = 'molecules'
  const multipleDogs: boolean = dogs.length > 1

  return (
    <Card>
      <div className={STYLES.inner}>
        <DailyFeedingGuideline
          namespace={namespace}
          dogs={dogs}
          multipleDogs={multipleDogs}
          planType={planType}
        />
        <Separator handdrawn />
        <div className={STYLES.boxBreakdown}>
          <BoxBreakdown
            pouchSize={pouchSize}
            pouches={pouches}
            cadence={cadence}
          />
        </div>
        <Separator handdrawn />
        <div className={STYLES.priceBreakdown}>
          <BoxPriceBreakdown
            context="plan_breakdown"
            total={total}
            meals={meals}
            extras={extras}
            surchargeTotal={surchargeTotal}
            deliveryFee={deliveryFee}
            shippingCountryCode={shippingCountryCode}
            preferredLanguage={preferredLanguage}
          />
        </div>
      </div>
    </Card>
  )
}

/**
 * Generates a box summary of a customers ongoing boxes
 * Does not show discounts, or non-recurring extras, only items that will be in every box
 */
const BoxSummaryWithQuery = (): JSX.Element | null => {
  const { data, error } = useQuery<IBoxSummary>(BOX_SUMMARY_QUERY)

  if (error) {
    Sentry.captureException(
      `An error occured when loading BoxSummaryWithQuery`,
      { extra: { error } }
    )
    return null
  }

  if (!data) {
    return (
      <Card>
        <div className={STYLES.placeholder}>
          <Spinner />
        </div>
      </Card>
    )
  }

  const { shippingCountryCode, preferredLanguage, subscription, dogs } =
    data.user

  if (isNull(dogs) || dogs.length === 0) return null

  const { numberOfPouches, pouchSize, durationInDays, typeOfPlanForCustomer } =
    subscription.plan

  const {
    deliveryFee,
    physicalProductsTotal,
    surchargeTotal,
    totalPrice,
    planTotal,
    planTotalPerDay
  } = subscription.recurringOrder

  return (
    <BoxSummary
      planType={typeOfPlanForCustomer}
      meals={[
        {
          total: planTotal,
          perDay: planTotalPerDay
        }
      ]}
      extras={physicalProductsTotal}
      surchargeTotal={surchargeTotal}
      deliveryFee={deliveryFee}
      total={totalPrice}
      dogs={dogs.map((dog) => ({
        name: dog.name,
        planPortionInGrams: dog.planPortionInGrams || 0
      }))}
      pouchSize={pouchSize}
      pouches={numberOfPouches}
      cadence={durationInDays / 7}
      shippingCountryCode={shippingCountryCode}
      preferredLanguage={preferredLanguage}
    />
  )
}

export { BoxSummaryWithQuery, Props }
export default BoxSummary
