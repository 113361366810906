import { ModalContext, ModalManagerContext } from '@/context/modalProvider'
import { useContext } from 'react'

type UseModal = {
  openModal: () => void
  closeModal: () => void
}

export const useModalManager = (): ModalContext | null => {
  const context = useContext(ModalManagerContext)

  return context
}

export const useModal = (
  id: string,
  props?: Record<string, unknown | undefined>
): UseModal => {
  const modalManager = useModalManager()
  if (!modalManager) {
    throw new Error('useModal must be used within a ModalProvider')
  }

  const { openModal, closeModal } = modalManager

  return {
    openModal: () => openModal(id, props),
    closeModal: () => closeModal(id)
  }
}
