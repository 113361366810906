import { PaymentRequestWallet } from '@stripe/stripe-js'

import { PaymentMethod } from '../../../types'

const paymentMethodFromWalletName = (
  walletName: PaymentRequestWallet
): PaymentMethod => {
  switch (walletName) {
    case 'googlePay':
      return 'googlePay'
    case 'applePay':
      return 'applePay'
    default:
      return 'creditCard'
  }
}

export { paymentMethodFromWalletName }
