// @noflow
import { InAppBrowser } from '@/utils/userAgent'

import segmentTrack from '@/components/analytics/Analytics'

import { PaymentMethod } from '../types'

const payPalButtonClicked = (): void => segmentTrack('PayPal Button Clicked')

const idealButtonClicked = (inAppBrowser: InAppBrowser | null): void =>
  segmentTrack('Ideal Button Clicked', { inAppBrowser })

const bancontactButtonClicked = (inAppBrowser: InAppBrowser | null): void =>
  segmentTrack('Bancontact Button Clicked', { inAppBrowser })

const submissionAttempt = (paymentMethod: PaymentMethod): void =>
  segmentTrack('Checkout Submission Attempted', { paymentMethod })

const setPaymentComplete = (): void =>
  segmentTrack('Checkout Credit Card Details Completed', {})

const setPaymentError = ({
  error,
  paymentMethod
}: {
  error: string
  paymentMethod: PaymentMethod
}): void => segmentTrack('Checkout Validation Failed', { error, paymentMethod })

export {
  payPalButtonClicked,
  bancontactButtonClicked,
  idealButtonClicked,
  submissionAttempt,
  setPaymentComplete,
  setPaymentError
}
