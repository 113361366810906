// @flow

import * as React from 'react'
import ErrorIcon from 'assets/images/icons/crosses/red-cross.svg'

/**
 * Docs for the HTMLTextAreaElement:
 * github.com/facebook/flow/blob/422f13bee662472f9d44742edf1cee68b7caee15/lib/dom.js#L3328
 *
 * Docs for the HTMLLabelElement:
 * github.com/facebook/flow/blob/422f13bee662472f9d44742edf1cee68b7caee15/lib/dom.js#L3439
 */

type Props = {|
  className: $PropertyType<HTMLLabelElement, 'className'>,
  disabled: $PropertyType<HTMLTextAreaElement, 'disabled'>,
  label: string,
  invalid: boolean,
  invalidityMessage: string,
  maxLength: $PropertyType<HTMLTextAreaElement, 'maxLength'>,
  name: $PropertyType<HTMLTextAreaElement, 'name'>,
  onBlur?: (SyntheticEvent<HTMLTextAreaElement>) => void,
  onChange: (SyntheticEvent<HTMLTextAreaElement>) => void,
  required: $PropertyType<HTMLTextAreaElement, 'required'>,
  rows?: $PropertyType<HTMLTextAreaElement, 'rows'>,
  textAreaId: $PropertyType<HTMLTextAreaElement, 'id'>,
  value: $PropertyType<HTMLTextAreaElement, 'value'>,
  placeholder: $PropertyType<HTMLTextAreaElement, 'placeholder'>,
  showCharactersCounter?: boolean,
  onMaxLengthLimitHit?: () => void
|}

const TextArea = ({
  className,
  disabled,
  invalid,
  invalidityMessage,
  label,
  maxLength,
  name,
  onBlur,
  onChange,
  required,
  rows,
  textAreaId,
  value,
  placeholder,
  showCharactersCounter = false,
  onMaxLengthLimitHit
}: Props): React.Node => {
  const [charactersCounter, setCharactersCounter] = React.useState<number>(0)

  const handleOnChange = React.useCallback((e: SyntheticEvent<HTMLTextAreaElement>): void => {
    onChange(e)
    setCharactersCounter(e.currentTarget.value.length)
  }, [onChange])

  React.useEffect((): void => {
    if ((charactersCounter === maxLength) && onMaxLengthLimitHit) {
      onMaxLengthLimitHit()
    }
  }, [charactersCounter, maxLength, onMaxLengthLimitHit])

  return (
    <React.Fragment>
      <div className={`text-area-wrapper ${invalid ? 'text-area-wrapper__invalid' : ''}`}>
        <textarea
          aria-label={label}
          className={`${className} ${disabled ? 'disabled' : ''} ${invalid ? 'invalid' : ''}`}
          disabled={disabled}
          id={textAreaId}
          maxLength={maxLength}
          name={name}
          onBlur={disabled ? null : onBlur}
          onChange={disabled ? null : handleOnChange}
          required={required}
          rows={rows}
          value={value}
          placeholder={placeholder}
        />
        {
          showCharactersCounter && (
            <span className={`text-area-wrapper__characters-counter ${invalid ? 'text-area-wrapper__characters-counter--invalid' : ''} ${charactersCounter === maxLength ? 'text-area-wrapper__characters-counter--max-characters' : ''}`}>
              { charactersCounter }
              { `/` }
              { maxLength }
            </span>
          )
        }
      </div>
      {
        invalid && (
          <p>
            <img
              alt=''
              src={ErrorIcon}
            />
            { invalidityMessage }
          </p>
        )
      }
    </React.Fragment>
  )
}

export default TextArea
