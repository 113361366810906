import React, { useCallback } from 'react'

import Text, { Props as TextProps } from '@/components/elements/atoms/Text/Text'

import STYLES from './DownloadInvoiceButton.module.sass'

import { Button } from '../../atoms/Button'

type Props = {
  csrfToken: string
  shippingCountryCode: string
  invoiceId: string
  invoiceIssued?: boolean
  showInvoiceDownloadDirections?: boolean
  toggleInvoicesModalOpen?: (
    e:
      | React.MouseEvent<HTMLButtonElement>
      | React.KeyboardEvent<HTMLButtonElement>
  ) => void
  onError?: (message: string) => void
  buttonText?: TextProps
  fullWidth?: boolean
}

const DownloadInvoiceButton = ({
  csrfToken,
  shippingCountryCode,
  invoiceId,
  invoiceIssued = false,
  showInvoiceDownloadDirections = false,
  toggleInvoicesModalOpen,
  onError,
  buttonText,
  fullWidth = false
}: Props): JSX.Element | null => {
  const handleContactRedirect = useCallback((e): void => {
    e.preventDefault()
    e.stopPropagation()
    window.location.href = '/contact'
  }, [])
  const handleDownloadInvoiceClick = useCallback((): void => {
    const headers = {
      'Content-Type': 'application/pdf',
      'X-CSRF-Token': csrfToken
    }
    const method = 'GET'
    const credentials = 'same-origin'
    const path = window.location.pathname.split('/')[1]

    fetch(`/${path}/invoices/${invoiceId}/download`, {
      headers,
      method,
      credentials
    })
      .then((res: Response): void => {
        if (res.ok) {
          res.blob().then((blob: Blob): void => {
            const file = window.URL.createObjectURL(blob)
            window.open(file, '_blank')
          })
        } else {
          onError && onError('Invoice download unsuccessful')
        }
      })
      .catch((error) => {
        onError && onError(error)
      })
  }, [csrfToken, invoiceId, onError])

  if (invoiceIssued) {
    return (
      <Button
        typography={{
          namespace: 'order',
          text: 'invoices'
        }}
        disableAnalytics
        onClick={toggleInvoicesModalOpen}
        variant="secondary"
        fullWidth={fullWidth}
      />
    )
  } else if (shippingCountryCode !== 'GB') {
    if (showInvoiceDownloadDirections) {
      return (
        <div>
          <div className={STYLES.wrapper}>
            <Text
              text="box_breakdown.missing_invoice_title"
              margin={false}
              element="h3"
              variant="textRegular16"
              bold
              namespace="dashboard"
            />
            <Text
              text="box_breakdown.missing_invoice_text"
              margin={false}
              element="p"
              variant="textRegular16"
              bold={false}
              namespace="dashboard"
            />
            <Button
              typography={{
                namespace: 'dashboard',
                text: 'box_breakdown.contact_us'
              }}
              onClick={handleContactRedirect}
              variant="secondary"
              fullWidth
              disableAnalytics
            />
          </div>
        </div>
      )
    } else {
      return null
    }
  } else {
    return (
      <Button
        typography={
          buttonText ?? {
            namespace: 'dashboard',
            text: 'box_breakdown.download_invoice'
          }
        }
        onClick={handleDownloadInvoiceClick}
        variant="secondary"
        disableAnalytics
        fullWidth={fullWidth}
      />
    )
  }
}

export { Props }
export default DownloadInvoiceButton
