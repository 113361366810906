// @noflow
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import type { Tab } from '../models/tab'

type State = {
  selectedTab: Tab
  isOpen: boolean
  csrfToken: string
}

const initialState: State = {
  selectedTab: 'funnel',
  isOpen: false,
  csrfToken: ''
}

const { reducer, actions } = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    tabSelectedFromBar(state: State, { payload }: PayloadAction<Tab>) {
      state.selectedTab = payload
    },
    widgetOpened(state: State) {
      state.isOpen = true
    },
    widgetClosed(state: State) {
      state.isOpen = false
    }
  }
})

export const { tabSelectedFromBar, widgetOpened, widgetClosed } = actions

export default reducer
