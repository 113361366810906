// @noflow
import * as Sentry from '@/utils/sentry'

import type {
  RecommendationQuery_currentUser_Guest as RecommendationQueryGuestUser,
  RecommendationQuery_currentUser_User as RecommendationQueryUser
} from '../__generated__/RecommendationQuery'
import type {
  ReviewQuery_currentUser_Guest as GuestUser,
  ReviewQuery_currentUser_User as User
} from '../__generated__/ReviewQuery'
import type { Code as CountryCode, Language } from '@/types/index'

type CurrentUserLocaleData = {
  countryCode: CountryCode
  preferredLanguage: Language
}

type CurrentUser =
  | RecommendationQueryGuestUser
  | RecommendationQueryUser
  | GuestUser
  | User
// This allows us to strictly type Current User against Apollo's generated types
// whilst handling the different ways we need to extract countryCode and
// prefeferred language dependent on if the currentUser is a Guest or User. If
// for any reason a PreWizardGuest ends up on the Plans flow, we'd look to
// capture the error and handle with default values.

const currentUserToLocaleData = (
  currentUser: CurrentUser
): CurrentUserLocaleData => {
  switch (currentUser.__typename) {
    case 'Guest': {
      return {
        countryCode: currentUser.assumedShippingCountry.code,
        preferredLanguage: currentUser.preferredLanguage
      }
    }
    case 'User': {
      return {
        countryCode: currentUser.shippingCountry.code,
        preferredLanguage: currentUser.preferredLanguage
      }
    }
    default: {
      Sentry.captureException(`Invalid currentUser type on Plans flow`, {
        extra: {
          currentUser
        },
        tags: {
          product: Sentry.Product.PlansFlow
        }
      })
      return {
        countryCode: 'GB' as CountryCode,
        preferredLanguage: 'en' as Language
      }
    }
  }
}

export { currentUserToLocaleData }
