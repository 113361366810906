/* eslint-disable i18next/no-literal-string */
// @noflow
import { useNotifications } from '@/context/notifications/notifications'
import { ACCOUNT_ROUTES } from '@/routes'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import isUndefined from 'lodash/isUndefined'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { subscriptionDataVar } from '@/services/apollo'

// Utils
import BREAKPOINTS from '@/constants/Breakpoints'

// Hooks
import useWindowSize from '@/hooks/useWindowSize'

// Assets
import WarningIcon from 'assets/images/icons/warning-icon-triangle.svg'

// Components
import AlertCard from '@/components/elements/atoms/Alert/AlertCard'
import { Button } from '@/components/elements/atoms/Button'
import SkeletonButton from '@/components/elements/atoms/SkeletonButton/SkeletonButton'
import SkeletonParagraph from '@/components/elements/atoms/SkeletonParagraph/SkeletonParagraph'
import SkeletonTitle from '@/components/elements/atoms/SkeletonTitle/SkeletonTitle'
import Text from '@/components/elements/atoms/Text/Text'
import { SuspendedSectionQuery } from '@/components/pages/Dashboard/components/SuspendedSection/__generated__/SuspendedSectionQuery'
// Mutations
import { SUBSCRIPTION_UNSUSPENSION_ATTEMPT_MUTATION } from '@/components/pages/MyDetailsPage/screens/PaymentMethods/mutations/SubscriptionUnsuspensionAttemptMutation'
import { SubscriptionUnsuspensionAttemptMutation } from '@/components/pages/MyDetailsPage/screens/PaymentMethods/mutations/__generated__/SubscriptionUnsuspensionAttemptMutation'

import STYLES from './SuspendedSection.module.sass'

// Apollo
import { SUSPENDED_SECTION_QUERY } from './queries'

const SuspendedSectionLoading = (): JSX.Element => {
  const { windowWidth } = useWindowSize()
  const isMobile = windowWidth < BREAKPOINTS.md
  return (
    <div className={STYLES.suspendedSection}>
      <SkeletonTitle
        align={isMobile ? 'center' : 'left'}
        height={isMobile ? 24 : 28}
        width="100%"
        margin=".5rem 0"
      />
      <SkeletonParagraph
        align={isMobile ? 'center' : 'left'}
        margin=".5rem 0"
        width="100%"
      />
      <SkeletonButton justify={isMobile ? 'center' : 'start'} width="100%" />
    </div>
  )
}

const SuspendedSection = (): JSX.Element | null => {
  const namespace = 'account'
  const copyContext = 'home.hero_section.suspended'
  const { t } = useTranslation('account')
  const navigate = useNavigate()
  const { windowWidth } = useWindowSize()
  const isMobile = windowWidth < BREAKPOINTS.md
  const subscriptionData = useReactiveVar(subscriptionDataVar)
  const [unsuspensionError, setUnsuspensionError] = useState(false)
  const { setSuccessNotification } = useNotifications()
  const [
    subscriptionUnsuspensionAttemptMutation,
    {
      loading: subscriptionUnsuspensionAttemptMutationLoading,
      error: subscriptionUnsuspensionAttemptMutationError
    }
  ] = useMutation<SubscriptionUnsuspensionAttemptMutation>(
    SUBSCRIPTION_UNSUSPENSION_ATTEMPT_MUTATION,
    {
      onCompleted: (data) => {
        setSuccessNotification({
          text: 'Payment successful',
          namespace: 'molecules',
          translate: false
        })
        setUnsuspensionError(false)

        const newStatus =
          data.subscriptionUnsuspensionAttempt?.subscription?.status
        if (
          newStatus &&
          subscriptionData &&
          newStatus !== subscriptionData?.status
        )
          subscriptionDataVar({
            ...subscriptionData,
            status: newStatus
          })
      },
      onError: (error) => {
        Sentry.captureException(
          `Error when SUBSCRIPTION_UNSUSPENSION_ATTEMPT_MUTATION executes on SuspendedSection`,
          {
            extra: {
              error
            }
          }
        )
        setUnsuspensionError(true)
      },
      variables: {
        offset: 0,
        limit: 6,
        nextNBoxes: 1
      }
    }
  )

  const onClickAddPaymentMethod = useCallback(() => {
    navigate(ACCOUNT_ROUTES.paymentMethods)
  }, [navigate])

  const onClickRetryPayment = useCallback(() => {
    subscriptionUnsuspensionAttemptMutation()
  }, [subscriptionUnsuspensionAttemptMutation])

  const { loading, data, error } = useQuery<SuspendedSectionQuery>(
    SUSPENDED_SECTION_QUERY
  )

  if (loading) {
    return <SuspendedSectionLoading />
  }

  if (error) {
    Sentry.captureException(`Error in SUSPENDED_SECTION_QUERY`, {
      extra: {
        error
      }
    })
  }

  if (isUndefined(data)) return null

  const {
    user: {
      subscription: { suspendedForInsufficientFunds, activePaymentMethodType }
    }
  } = data

  return (
    <div className={STYLES.suspendedSection}>
      <img
        src={WarningIcon}
        width={38}
        height={36}
        alt={t(`${copyContext}.warning_alt`)}
        className={STYLES.warningIcon}
      />
      <Text
        text={
          suspendedForInsufficientFunds
            ? `${copyContext}.title_insufficient_funds`
            : `${copyContext}.title`
        }
        namespace={namespace}
        margin={false}
        variant="display20"
        align={isMobile ? 'center' : 'left'}
        element="h2"
      />
      <Text
        text={
          suspendedForInsufficientFunds
            ? `${copyContext}.payment_insufficient_funds_update`
            : `${copyContext}.payment_default_update`
        }
        namespace={namespace}
        align={isMobile ? 'center' : 'left'}
        variant="textRegular14"
        margin={false}
      />
      {suspendedForInsufficientFunds &&
      activePaymentMethodType === 'CreditCard' ? (
        <Button
          identifier="suspended_section_update_payment_method.button"
          onClick={onClickRetryPayment}
          typography={{
            namespace,
            text: `${copyContext}.cta_insufficient_funds`
          }}
          disabled={subscriptionUnsuspensionAttemptMutationLoading}
        />
      ) : (
        <Button
          identifier="suspended_section_update_payment_method.button"
          onClick={onClickAddPaymentMethod}
          typography={{
            namespace,
            text: `${copyContext}.cta`
          }}
        />
      )}
      {(subscriptionUnsuspensionAttemptMutationError || unsuspensionError) && (
        <AlertCard
          message={{
            text: 'my_details.payment_methods.unsuspension_error',
            namespace: 'dashboard'
          }}
          variant="error"
        />
      )}
    </div>
  )
}

export default SuspendedSection
