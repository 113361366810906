import { gql } from '@apollo/client'

const PRODUCT_VARIANT_FRAGMENT = gql`
  fragment productVariantFragment on ProductVariant {
    id
    name
    grossPrice
    recurringDeliveryType {
      netPrice
    }
    productVariantDeliveryTypes {
      deliveryType
      netPrice
    }
    productCollection {
      name
      merchandisingImage {
        src
      }
    }
  }
`
export { PRODUCT_VARIANT_FRAGMENT }
