// @noflow
import { eachDayOfInterval, endOfWeek, format, startOfWeek } from 'date-fns'
import type { Locale } from 'date-fns'
import { de, enGB, nl, nlBE, pl } from 'date-fns/locale'
import i18next from 'i18next'
import React, { useMemo } from 'react'
import type { ReactElement } from 'react'

import Text from '@/components/elements/atoms/Text/Text'

import STYLES from '../Calendar.module.sass'

const DaysOfTheWeek = (): ReactElement => {
  // Set current locale depending on the language
  const currentLocale = useMemo((): Locale => {
    switch (i18next.language) {
      case 'en': {
        return enGB
      }
      case 'nl': {
        return nl
      }
      case 'nl_BE': {
        return nlBE
      }
      case 'pl':
      case 'pl_PL': {
        return pl
      }
      case 'de_DE': {
        return de
      }
      default: {
        return enGB
      }
    }
  }, [])

  // Define that week starts from Monday
  const weekInterval = useMemo(
    () =>
      eachDayOfInterval({
        start: startOfWeek(new Date(), { weekStartsOn: 1 }),
        end: endOfWeek(new Date(), { weekStartsOn: 1 })
      }),
    []
  )

  /**
   * Get week days in the format of the array.
   *
   * EN: [M, T, W, T, F, S, S]
   * NL: [M, D, W, D, V, Z, Z]
   */
  const daysOfTheWeek = useMemo(
    () =>
      weekInterval.map((day) =>
        format(day, 'EEEEE', {
          locale: currentLocale
        })
      ),
    [weekInterval, currentLocale]
  )

  return (
    <div className={STYLES.dayNames}>
      {daysOfTheWeek.map((day, index) => (
        <Text
          key={`${weekInterval[index]}`}
          translate={false}
          text={day}
          element="span"
          colour="brandBlue500"
          variant="textRegular16"
          shouldScale={false}
        />
      ))}
    </div>
  )
}

export default DaysOfTheWeek
