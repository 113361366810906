import classNames from 'classnames'
import { times } from 'lodash'
import React from 'react'

import STYLES from './SteppedProgressBar.module.sass'

type Props = {
  steps: number
  current: number
}

const SteppedProgressBar = ({ steps, current }: Props): JSX.Element => {
  return (
    <div className={STYLES.progressBarWrapper}>
      {times(steps).map((step) => {
        const innerBarClasses = classNames(STYLES.progressBarInner, {
          [STYLES.progressBarInnerFilled]: step < current
        })

        return (
          <div key={step} className={STYLES.progressBar}>
            <div className={innerBarClasses} />
          </div>
        )
      })}
    </div>
  )
}

export { SteppedProgressBar }
