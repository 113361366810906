// @noflow
import { MutableRefObject, SyntheticEvent, useRef } from 'react'

type Args = {
  onFileSelect?: (file: File) => void
}

type FileUpload = {
  fileInputRef: MutableRefObject<HTMLInputElement | null>
  handleElementClick: () => void
  handleFileSelect: (event: SyntheticEvent<HTMLInputElement>) => void
}

const useFileUpload = ({ onFileSelect }: Args): FileUpload => {
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const handleElementClick = () => {
    fileInputRef?.current?.click()
  }

  const handleFileSelect = (event: SyntheticEvent<HTMLInputElement>) => {
    const [file] = event.currentTarget.files ?? []

    if (file) {
      onFileSelect && onFileSelect(file)
    }
  }

  return {
    fileInputRef,
    handleElementClick,
    handleFileSelect
  }
}

export type { FileUpload }
export { useFileUpload }
