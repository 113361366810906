// @noflow
import Validator from '@/utils/validator'

import GenericForm from '@/components/contact_us/components/contact_form/GenericForm'

import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'
import { Language } from '@/types'
import { ensureNever } from '@/typescript/utils'

import type { FormDataAction } from '../actions'
import * as MESSAGES from '../actions/messages'

type Interaction =
  | 'NotInteracted' // the default
  | 'Interacting' // when typing
  | 'InteractedWithError' // when blurred and there's a validation error
  | 'InteractedWithSuccess' // blurred with no violations

type Input = {
  value: string
  interaction: Interaction
}

type FormInput = Input | Set<Input>

type FormType = 'generic-form'

type ContactInformation = {
  contactNumber: string | null
  displayContactNumber: string | null
  whatsappNumber: string | null
  displayWhatsappNumber: string | null
  contactHours: string | null
  whatsappContactHours: string | null
}

type GenericForm = {
  type: 'generic-form'
  name: Input
  email: Input
  dogNames: Input
  message: Input
  recaptchaString: Input
  shippingCountryCode: CountryCode
  preferredLanguage: Language
  contactInformation: ContactInformation
}

type Form = GenericForm

type CachedForm = {
  genericForm: Partial<Omit<GenericForm, 'type'>>
}

type ViewingStep = 'Get In Touch' | 'Query Selection' | 'Fill Out Form'

/**
 * The State of the formDataReducer is a Form (as defined above) where each
 * value can be `null` (before the app data loads). This allows us to load the
 * form and allow it to be edited while the initial data is being requested
 * and the initial data can be used or discarded if the user has already started
 * interacting with the form
 */
type State = {
  form: Form
  cachedFormData: CachedForm
  viewingStep: ViewingStep
  recaptchaLoaded: boolean
  queryType: Input
}

/**
 * The LoadedAppData type below is a type of the Form, where all Input
 * interaction values have the initial value of 'NotInteracted' and we exclude
 * the queryType and recaptchaString as these do not come back from the server:
 *
 * const LoadedAppData: LoadedAppData = {
 *   name: {
 *     value: '',
 *     interaction: 'NotInteracted'
 *   },
 *   ...
 * }
 */
type LoadedAppData =
  | Record<
      keyof Omit<GenericForm, 'recaptchaString' | 'type' | 'preferredLanguage'>,
      {
        value: string
        interaction: 'NotInteracted'
      }
    >
  | {
      shippingCountryCode: CountryCode
      preferredLanguage: Language
      contactInformation: ContactInformation
    }

const genericFormInitialFieldsState: Omit<GenericForm, 'type'> = {
  name: {
    value: '',
    interaction: 'NotInteracted'
  },
  email: {
    value: '',
    interaction: 'NotInteracted'
  },
  dogNames: {
    value: '',
    interaction: 'NotInteracted'
  },
  message: {
    value: '',
    interaction: 'NotInteracted'
  },
  recaptchaString: {
    value: '',
    interaction: 'NotInteracted'
  },
  shippingCountryCode: 'GB',
  preferredLanguage: Language.en,
  contactInformation: {
    contactHours: null,
    contactNumber: null,
    displayContactNumber: null,
    displayWhatsappNumber: null,
    whatsappContactHours: null,
    whatsappNumber: null
  }
}

const initialState: State = Object.freeze({
  form: {
    type: 'generic-form',
    ...genericFormInitialFieldsState
  },
  cachedFormData: {
    genericForm: {
      ...genericFormInitialFieldsState
    }
  },
  viewingStep: 'Get In Touch',
  recaptchaLoaded: false,
  queryType: {
    value: 'How can we help?',
    interaction: 'NotInteracted'
  }
})

const initState = (type: FormType): State => {
  switch (type) {
    case 'generic-form': {
      return initialState
    }
    default: {
      ensureNever(type)
      return initialState
    }
  }
}

const selectRequiredFormInputs = ({ form }: State): Array<FormInput> => {
  switch (form.type) {
    case 'generic-form': {
      const { name, email, dogNames, message, recaptchaString } = form

      return [name, email, dogNames, message, recaptchaString]
    }
  }
}

const isValidDogNames = (value: Input['value']): boolean => {
  return value !== '' && value.length > 0
}

const isValidEmail = (value: Input['value']): boolean => {
  return Validator.isValidEmail(value)
}

const isValidMessage = (value: Input['value']): boolean => {
  return value !== '' && value.length > 0
}

const isValidName = (value: Input['value']): boolean => {
  return value !== '' && value.length > 0
}

const selectIsFormValid = (state: State): boolean => {
  const { form, viewingStep, recaptchaLoaded, queryType } = state

  switch (form.type) {
    case 'generic-form': {
      const { dogNames, email, message, name, recaptchaString } = form

      return (
        isValidDogNames(dogNames.value) &&
        isValidEmail(email.value) &&
        isValidMessage(message.value) &&
        isValidName(name.value) &&
        recaptchaString.interaction === 'InteractedWithSuccess' &&
        queryType.interaction === 'InteractedWithSuccess' &&
        viewingStep === 'Fill Out Form' &&
        recaptchaLoaded === true
      )
    }
  }
}

const reducer = (
  state: State = initialState,
  action: FormDataAction
): State => {
  switch (action.type) {
    case MESSAGES.APP_DATA_RECEIVED: {
      const { form, cachedFormData } = state
      const { data } = action

      const values: Array<Input> = ((): Array<Input> => {
        switch (form.type) {
          case 'generic-form': {
            return [form.name, form.email, form.dogNames, form.message]
          }
        }
      })()

      // If the form has been interacted with then don't populate it
      // Query type has been excluded as it may come preset with query params
      if (
        values.some(
          ({ interaction }: Input): boolean => interaction !== 'NotInteracted'
        )
      ) {
        return state
      }
      switch (form.type) {
        case 'generic-form': {
          return {
            ...state,
            form: {
              ...form,
              ...data
            } as GenericForm,
            cachedFormData: {
              ...cachedFormData,
              genericForm: {
                ...cachedFormData.genericForm,
                ...data
              } as GenericForm
            }
          }
        }
      }
      break
    }
    case MESSAGES.PROBE_REQUIRED_FIELDS: {
      const { form, cachedFormData } = state
      switch (form.type as FormType) {
        case 'generic-form': {
          const { dogNames, email, message, name } = form as GenericForm

          const { genericForm } = cachedFormData

          return {
            ...state,
            form: {
              ...form,
              dogNames: {
                ...dogNames,
                interaction: isValidDogNames(dogNames.value)
                  ? 'InteractedWithSuccess'
                  : 'InteractedWithError'
              },
              email: {
                ...email,
                interaction: isValidEmail(email.value)
                  ? 'InteractedWithSuccess'
                  : 'InteractedWithError'
              },
              message: {
                ...message,
                interaction: isValidMessage(message.value)
                  ? 'InteractedWithSuccess'
                  : 'InteractedWithError'
              },
              name: {
                ...name,
                interaction: isValidName(name.value)
                  ? 'InteractedWithSuccess'
                  : 'InteractedWithError'
              }
            } as GenericForm,
            cachedFormData: {
              ...cachedFormData,
              genericForm: {
                ...genericForm,
                dogNames: {
                  ...dogNames,
                  interaction:
                    dogNames.value.length > 0
                      ? 'InteractedWithSuccess'
                      : 'InteractedWithError'
                },
                email: {
                  ...email,
                  interaction:
                    email.value.length > 0
                      ? 'InteractedWithSuccess'
                      : 'InteractedWithError'
                },
                message: {
                  ...message,
                  interaction:
                    message.value.length > 0
                      ? 'InteractedWithSuccess'
                      : 'InteractedWithError'
                },
                name: {
                  ...name,
                  interaction:
                    name.value.length > 0
                      ? 'InteractedWithSuccess'
                      : 'InteractedWithError'
                }
              }
            }
          }
        }
      }
      break
    }
    case MESSAGES.UPDATE_FIELD: {
      switch (action.field) {
        case 'queryType': {
          return Object.freeze({
            ...state,
            queryType: action.input
          })
        }
        case 'email': {
          const { form, cachedFormData } = state
          const { genericForm } = cachedFormData
          const { input: email } = action
          const { value, interaction } = email

          const updateCachedEmailField = (presetInteraction: Interaction) => {
            switch (form.type) {
              case 'generic-form': {
                return {
                  genericForm: {
                    ...genericForm,
                    email: {
                      ...email,
                      interaction: isValidEmail(value)
                        ? interaction
                        : presetInteraction
                    }
                  }
                }
              }
              default: {
                return {}
              }
            }
          }

          /*
           * If the input field has previously been interacted with and had been
           * erroneous then we want to leave the interaction state as it was so long
           * as the input remains invalid however if the interaction, having been
           * invalid, is now a valid value then we want to set the interaction to
           * the value indicated by the action
           */
          if (form.email.interaction === 'InteractedWithError') {
            return {
              ...state,
              form: {
                ...form,
                email: {
                  ...email,
                  interaction: isValidEmail(value)
                    ? interaction
                    : form.email.interaction
                }
              } as Form,
              cachedFormData: {
                ...cachedFormData,
                ...updateCachedEmailField(form.email.interaction)
              } as CachedForm
            }
          }
          return {
            ...state,
            form: {
              ...form,
              email
            } as Form,
            cachedFormData: {
              ...cachedFormData,
              ...updateCachedEmailField(email.interaction)
            } as CachedForm
          }
        }
        case 'name':
        case 'message':
        case 'dogNames':
        case 'recaptchaString': {
          const { form, cachedFormData } = state
          const { genericForm } = cachedFormData
          const { input, field } = action

          return Object.freeze({
            ...state,
            form: {
              ...form,
              [field]: input
            },
            cachedFormData: {
              ...cachedFormData,
              ...(() => {
                switch (form.type) {
                  case 'generic-form': {
                    return {
                      genericForm: {
                        ...genericForm,
                        [field]: input
                      }
                    }
                  }
                  default: {
                    return {}
                  }
                }
              })()
            }
          })
        }
        default: {
          return state
        }
      }
    }
    case MESSAGES.SET_FORM_VIEWING_STEP: {
      return {
        ...state,
        viewingStep: action.viewingStep
      }
    }
    case MESSAGES.SET_RECAPTCHA_LOADED: {
      return {
        ...state,
        recaptchaLoaded: true
      }
    }
    case MESSAGES.SET_FORM_TYPE: {
      let formData
      switch (action.formType) {
        case 'generic-form': {
          formData = {
            type: 'generic-form' as const,
            ...state.cachedFormData.genericForm
          }
          break
        }
        default: {
          formData = {
            ...initialState.form
          }
        }
      }

      return {
        ...state,
        form: {
          ...formData
        } as Form
      }
    }
    default: {
      ensureNever(action)
      return state
    }
  }
}

export type {
  State,
  Form,
  GenericForm,
  Input,
  LoadedAppData,
  FormType,
  Interaction,
  FormInput
}

export {
  initialState,
  initState,
  selectRequiredFormInputs,
  isValidDogNames,
  isValidEmail,
  isValidMessage,
  isValidName,
  selectIsFormValid
}

export default reducer
