// @noflow

/* eslint-disable i18next/no-literal-string */
import Container from '@material-ui/core/Container'
import classnames from 'classnames'
import { addDays, isBefore } from 'date-fns'
import isNil from 'lodash/isNil'
import isUndefined from 'lodash/isUndefined'
import kebabCase from 'lodash/kebabCase'
import startCase from 'lodash/startCase'
import React, { useCallback } from 'react'

import { isDevelopment } from '@/utils/isDevelopment'

import useLocalStorage from '@/hooks/useLocalStorage'

import segmentTrack from '@/components/analytics/Analytics'
import Text from '@/components/elements/atoms/Text/Text'
import type { Props as TextProps } from '@/components/elements/atoms/Text/Text'
import IconButton from '@/components/elements/molecules/IconButton/IconButton'

import STYLES from './TopBanner.module.sass'

type Variants = 'error' | 'success' | 'info' | 'mourning'

type Props = {
  id: string
  variant?: Variants
  text: TextProps
  link?: string
  showEveryXDays?: number
  dismissible?: boolean
}

const generateBannerLocalStorageKey = (id: string): string =>
  `${kebabCase(id)}-banner-dismissed`

const showBanner = (
  bannerLastDismissed: number | undefined | null,
  showEveryXDays?: number
): boolean =>
  isNil(bannerLastDismissed) ||
  (!isUndefined(showEveryXDays) &&
    isBefore(addDays(bannerLastDismissed, showEveryXDays), Date.now()))

const getDismissedTime = (): number => new Date().getTime()

const isInternalRoute = (link: string): boolean =>
  link.includes('www.butternutbox') || (link.length > 0 && link[0] === '/')

const stripDomainIfDevelopment = (link: string) =>
  isDevelopment() ? link.replace(/^.*\/\/[^/]+/, '') : link

const TopBanner = ({
  id,
  variant = 'info',
  text,
  link,
  showEveryXDays,
  dismissible = true
}: Props): JSX.Element | null => {
  const [dismissed, setDismissed] = useLocalStorage<string, number | undefined>(
    generateBannerLocalStorageKey(id),
    undefined
  )

  const onCloseBanner = useCallback(
    (event): void => {
      event.stopPropagation()
      segmentTrack(`${startCase(id)} banner dismissed`)
      setDismissed(getDismissedTime())
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setDismissed]
  )

  const onClickLink = useCallback((): void => {
    if (!isUndefined(link)) {
      segmentTrack(`${startCase(id)} banner clicked`)
      if (dismissible) setDismissed(getDismissedTime())

      // if is internal route
      if (isInternalRoute(link))
        window.location.href = stripDomainIfDevelopment(link)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDismissed])

  const BannerElement = !isUndefined(link)
    ? 'a'
    : ('div' as keyof JSX.IntrinsicElements)
  const isExternalLink: boolean = !isUndefined(link) && !isInternalRoute(link)

  const className = classnames(STYLES.container, STYLES[variant], {
    [STYLES.link]: link
  })

  return showBanner(dismissed, showEveryXDays) ? (
    <BannerElement
      className={className}
      onClick={onClickLink}
      data-testid={`${kebabCase(id)}-banner`}
      href={isExternalLink ? link : undefined}
      target={isExternalLink ? '_blank' : undefined}
    >
      <Container maxWidth="lg">
        <div className={STYLES.inner}>
          <div className={STYLES.copy}>
            <Text
              text={text.text}
              margin={false}
              variant="textRegular16"
              namespace={text.namespace}
              translate={text.translate || false}
              variables={text.variables}
              element="div"
            />
          </div>
          {dismissible && (
            /* eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */
            <label className={STYLES.close}>
              <IconButton size={24} icon="close" onClick={onCloseBanner} />
            </label>
          )}
        </div>
      </Container>
    </BannerElement>
  ) : null
}

export { Props, Variants, generateBannerLocalStorageKey, showBanner }
export default TopBanner
