import { useQuery } from '@apollo/client'
import Grid from '@material-ui/core/Grid'
import { differenceInDays } from 'date-fns'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import * as Sentry from '@/utils/sentry'

import Image from '@/components/elements/atoms/Image/Image'
import SkeletonParagraph from '@/components/elements/atoms/SkeletonParagraph/SkeletonParagraph'
import SkeletonTitle from '@/components/elements/atoms/SkeletonTitle/SkeletonTitle'
import Text from '@/components/elements/atoms/Text/Text'
import AddDogSection from '@/components/pages/NoDogsPage/components/AddDogSection/AddDogSection'

import STYLES from './NoDogsHero.module.sass'

import { NO_DOGS_HERO_QUERY } from '../NoDogsStateContent/queries/noDogsPageQuery'

import { NoDogsHeroQuery } from '../NoDogsStateContent/queries/__generated__/NoDogsHeroQuery'

import { PAUSE_SUBREASON } from '../../types/pauses'

const SkeletonContent = () => {
  return (
    <Grid container justifyContent="center">
      <div className={STYLES.heroContainerWrapper}>
        <Grid item sm={12} md={7}>
          <SkeletonTitle align={'left'} height={'2.4rem'} margin=".5rem 0" />
          <SkeletonParagraph
            align={'left'}
            count={3}
            shortLine
            margin=".5rem 0"
          />
        </Grid>
      </div>
    </Grid>
  )
}

const NoDogsHero = (): JSX.Element | null => {
  const namespace = 'dashboard'
  const { t } = useTranslation('dashboard')
  const { loading, error, data } = useQuery<NoDogsHeroQuery>(NO_DOGS_HERO_QUERY)

  if (loading) return <SkeletonContent />

  if (error) {
    // eslint-disable-next-line i18next/no-literal-string
    Sentry.captureException(`Error in NO_DOGS_HERO_QUERY`, {
      extra: {
        error
      },
      tags: {
        product: Sentry.Product.Account,
        team: Sentry.Team.Retention
      }
    })
    return null
  }

  const today = new Date()
  const lastPause = data?.user.subscription.pauses?.[0]
  const lastPauseDate = new Date(lastPause?.createdAt)
  const diffDays = differenceInDays(today, lastPauseDate)
  const pausedLongEnoughAgo = diffDays < 30

  const dogPassedReason =
    lastPause?.subreason === PAUSE_SUBREASON.dog_passed && pausedLongEnoughAgo

  return (
    <>
      <Grid container justifyContent="center">
        <div className={STYLES.heroContainerWrapper}>
          {dogPassedReason ? (
            <Grid item sm={12} md={7} className={STYLES.heroContent}>
              <Text
                namespace={namespace}
                text={`no_dogs.title`}
                variant="display24"
                margin={false}
                variables={{
                  firstName: data?.user.firstName
                }}
              />
              <Text
                namespace={namespace}
                text={`no_dogs.body_passed_away`}
                margin={false}
              />
              <div className={STYLES.expertWrapper}>
                <Image
                  alt={t('no_dogs.expert_alt')}
                  slug={'matt_may'}
                  image={{
                    width: 100,
                    height: 100
                  }}
                  className={STYLES.expertImg}
                />
                <div>
                  <Text
                    text={'Matt May'}
                    margin={false}
                    translate={false}
                    bold
                  />
                  <Text
                    namespace={namespace}
                    text={'no_dogs.expert'}
                    margin={false}
                    variant="textRegular16"
                  />
                </div>
              </div>
            </Grid>
          ) : (
            <AddDogSection
              firstName={data?.user.firstName || ''}
              dogPassedReason={dogPassedReason}
            />
          )}
        </div>
      </Grid>
      <div className={STYLES.heroBottom} />
    </>
  )
}

export default NoDogsHero
