import React from 'react'

import { Button } from '@/components/elements/atoms/Button'
import Image from '@/components/elements/atoms/Image/Image'
import Modal from '@/components/elements/atoms/Modal/Modal'
import Text from '@/components/elements/atoms/Text/Text'
import { BREAKPOINTS } from '@/components/templates/Base'

import STYLES from './CareModal.module.sass'

type Props = {
  careModalIsOpen: boolean
  toggleCareModal: () => void
  dogNames: string
  resumePlanClicked: () => void
  resumeLoading: boolean
  namespace: string
}

const CareModal = ({
  careModalIsOpen,
  toggleCareModal,
  dogNames,
  resumePlanClicked,
  resumeLoading,
  namespace
}: Props): JSX.Element | null => {
  if (!careModalIsOpen) return null
  return (
    <Modal
      setOpenModal={toggleCareModal}
      isModalOpen={careModalIsOpen}
      width={800}
      bottomSticky={window.innerWidth < BREAKPOINTS.md}
    >
      <Text
        namespace={namespace}
        text={'treatments_section.treatment_cards.care.modal.title'}
        variant="display24"
        margin={false}
        align="center"
      />
      <div className={STYLES.modalContent}>
        <Image
          className={STYLES.modalImage}
          alt={'treatment_cards.care.modal.image_alt'}
          slug="merchandising-care-treatment-card"
          image={{
            height: 504,
            width: 664
          }}
        />
        <div className={STYLES.modalCopy}>
          <Text
            namespace={namespace}
            text={'treatments_section.treatment_cards.care.modal.body'}
            variables={{
              dogName: dogNames
            }}
            margin={false}
          />
          <Button
            typography={{
              namespace,
              text: resumeLoading
                ? `treatments_section.treatment_cards.care.modal.cta_loading`
                : `treatments_section.treatment_cards.care.modal.cta`
            }}
            onClick={resumePlanClicked}
            fullWidth
            disabled={resumeLoading}
            disableAnalytics
          />
        </div>
      </div>
    </Modal>
  )
}

export default CareModal
