import { captureException } from '@sentry/browser'
import { useStripe } from '@stripe/react-stripe-js'
import React, { useEffect } from 'react'

import { addBancontactPaymentMethod } from '../screens/PaymentMethods/helpers/addPaymentMethod'

type useAddBancontact = {
  addBancontactCallback: () => void
  bancontactAuthRecieved: boolean
}

const useAddBancontact = ({
  userEmail,
  userToken,
  fullName,
  redirectUrl,
  csrfToken,
  onSuccess,
  onError,
  onCreate,
  setLoading
}: {
  userEmail: string
  userToken: string
  fullName: string
  redirectUrl: string
  csrfToken: string
  onSuccess: () => void
  onError: (message: string) => void
  onCreate: (setupIntentId: string) => void
  setLoading: (loading: boolean) => void
}): useAddBancontact => {
  const stripe = useStripe()

  const [bancontactAuthRecieved, setBancontactAuthRecieved] =
    React.useState(false)

  const addBancontactCallback = React.useCallback(() => {
    if (!stripe)
      throw new Error(
        'Cannot find stripe when buySubscritionWithSepaDirectDebit is called'
      )

    setLoading(true)

    return addBancontactPaymentMethod(
      userEmail,
      csrfToken,
      userToken,
      stripe,
      fullName,
      redirectUrl,
      onSuccess,
      onError
    )
  }, [
    csrfToken,
    fullName,
    onError,
    onSuccess,
    redirectUrl,
    setLoading,
    stripe,
    userEmail,
    userToken
  ])

  // Upon being redirected back to page after an authorisation attempt to
  // create the subscription or alert the customer that authentication has failed
  useEffect(() => {
    if (window.location.search.includes('redirect_status') && stripe) {
      if (
        window.location.search.includes('redirect_status=succeeded') &&
        window.location.search.includes('method_type=bancontact')
      ) {
        const urlParams = new URLSearchParams(window.location.search)
        const intentSecret = urlParams.get('setup_intent_client_secret')
        if (intentSecret) {
          setBancontactAuthRecieved(true)
          setLoading(true)
          stripe.retrieveSetupIntent(intentSecret).then((result) => {
            if (result.error) {
              setLoading(false)
              onError(result.error.message || '')
            } else {
              onCreate(result.setupIntent.id)
            }
          })
        } else {
          setLoading(false)
          onError('Bancontact payment failed, intentSecret is undefined')
        }
      } else {
        const urlParams = new URLSearchParams(window.location.search)
        const status = urlParams.get('redirect_status')
        setLoading(false)
        setBancontactAuthRecieved(false)
        captureException(`Bancontact Auth Redirect Status = ${status}`)
      }
    }
  }, [onCreate, onError, setLoading, stripe])

  return {
    addBancontactCallback,
    bancontactAuthRecieved
  }
}

export default useAddBancontact
