// @noflow
import { useLanguage } from '@/context/injectedValues/injectedValues'
import { useLocalisation } from '@/context/localisation/localisation'
import { useNotifications } from '@/context/notifications/notifications'
import { ProductUpsellContext } from '@/context/productUpsellModal/productUpsellModal'
import { ACCOUNT_ROUTES } from '@/routes'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { Grid } from '@mui/material'
import { addDays, format, formatISO } from 'date-fns'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { featureFlagsDataVar } from '@/services/apollo'

import { localeToDateLocale } from '@/utils/countryCodeHelper'

import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'
import { CardSkeleton } from '@/components/elements/atoms/Card/CardSkeleton'
import CheckBox from '@/components/elements/atoms/CheckBox/CheckBox'
import Text from '@/components/elements/atoms/Text'
import Calendar from '@/components/elements/molecules/Calendar'
import { ProductUpsellAnalyticTypes } from '@/components/elements/organisms/ProductsUpsellModal/ProductsUpsellModal'
import { DeliveryDateQuery } from '@/components/pages/ChangeDatePage/__generated__/DeliveryDateQuery'
import {
  DELIVERY_DATE_QUERY,
  DELIVERY_DATE_UPDATE
} from '@/components/pages/ChangeDatePage/queries'
import { DASHBOARD_PAGE_SECTIONS } from '@/components/pages/Dashboard/Dashboard'

import STYLES from './ChangeDatePage.module.sass'

import { Code } from '@/types'

/**
 * This page features a calendar component which allows users to change the date of a delivery
 * @constructor
 */

const ChangeDatePage = (): JSX.Element | null => {
  const { boxId } = useParams()
  const navigate = useNavigate()
  const { setInfoNotification, setErrorNotification } = useNotifications()
  const { normaliseTimezone } = useLocalisation()
  const { userLanguage } = useLanguage()
  const { openProductsUpsellModal } = useContext(ProductUpsellContext)
  const [deliveryDate, setDeliveryDate] = useState<Date>()
  const [onlyMoveThisBox, setOnlyMoveThisBox] = useState<boolean>(false)
  const handleCheckboxChange = useCallback((id: number, val: boolean) => {
    setOnlyMoveThisBox(val)
  }, [])

  const shouldSeeOnlyMoveThisBoxCheckbox =
    useReactiveVar(featureFlagsDataVar)?.shouldSeeOnlyMoveThisBoxCheckbox ===
    'true'

  const { loading, data, error } = useQuery<DeliveryDateQuery>(
    DELIVERY_DATE_QUERY,
    {
      variables: {
        boxId: boxId || '',
        from: formatISO(new Date().setHours(1, 0, 0, 0)),
        to: addDays(new Date().setHours(1, 0, 0, 0), 90),
        nextNBoxes: 3
      },
      onError: () => {
        setErrorNotification({
          text: 'change_date.error.update',
          namespace: 'account'
        })
        navigate(ACCOUNT_ROUTES.base)
      }
    }
  )

  const [deliveryDateUpdate, { loading: processing }] = useMutation(
    DELIVERY_DATE_UPDATE,
    {
      onError: () => {
        setErrorNotification({
          text: 'change_date.error.update',
          namespace: 'account'
        })
        setDeliveryDate(data?.user.subscription.box?.isoDeliveryDate)
      },
      onCompleted: () => {
        setInfoNotification({
          text: 'change_date.success',
          namespace: 'account'
        })
        setTimeout((): void => {
          const showUpsellModal =
            (data?.user.shippingCountry.showUpcomingOrderSeeMoreExtras &&
              data?.user.subscription.nextNBoxes[0]?.isNextEditableBox) ||
            false
          if (showUpsellModal) {
            const shippingCountryCode =
              data?.user.shippingCountryCode || Code.GB
            const dateLocale = localeToDateLocale(
              shippingCountryCode,
              userLanguage || 'en'
            )

            const deliveryDate =
              data?.user.subscription.nextNBoxes[0]?.isoDeliveryDate
            openProductsUpsellModal({
              analyticsType: ProductUpsellAnalyticTypes.deliveryDateChange,
              headerText: [
                {
                  text: 'products_upsell_modal.delivery_date_changed_modal.title'
                },
                {
                  text: 'products_upsell_modal.delivery_date_changed_modal.title_information',
                  variant: 'textRegular18',
                  colour: 'brandBlue400',
                  variables: {
                    deliveryDate: format(
                      new Date(deliveryDate),
                      'EEEE do MMMM',
                      {
                        locale: dateLocale
                      }
                    )
                  }
                }
              ]
            })
          }
          navigate(
            ACCOUNT_ROUTES.base + '#' + DASHBOARD_PAGE_SECTIONS.upcomingBoxes
          )
        }, 2000)
      }
    }
  )

  /**
   * If user navigates to this page past cutoff then redirect them back
   */
  useEffect(() => {
    if (!loading && data && !data.user.subscription.box?.editable) {
      setErrorNotification({
        text: 'change_date.past_cutoff',
        namespace: 'account'
      })
      navigate(ACCOUNT_ROUTES.base)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.user.subscription.box?.isoDeliveryDate])

  /**
   * Handle if an invalid box id is passed
   */
  useEffect(() => {
    if (!isUndefined(error)) {
      setErrorNotification({
        text: 'change_date.error.get',
        namespace: 'account'
      })
      navigate(ACCOUNT_ROUTES.base)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  /**
   * Set initial delivery date on load
   */
  useEffect(() => {
    if (
      isUndefined(deliveryDate) &&
      !isUndefined(data) &&
      !isNull(data?.user.subscription.box)
    ) {
      setDeliveryDate(data?.user.subscription.box?.isoDeliveryDate)
    }
  }, [data, data?.user.subscription.box?.isoDeliveryDate, deliveryDate])

  const updateDeliveryDate = useCallback(() => {
    if (
      !isUndefined(data) &&
      !isUndefined(deliveryDate) &&
      !isNull(data.user.subscription.box)
    ) {
      const { user } = data
      const { id: userId, subscription } = user
      const { box } = subscription

      if (!isNull(box)) {
        const { id: boxId } = box
        if (deliveryDate)
          deliveryDateUpdate({
            variables: {
              userId,
              boxId,
              selectedDeliveryDate: new Date(
                normaliseTimezone(deliveryDate)
              ).toISOString(),
              adjustFutureBoxDates: !onlyMoveThisBox,
              triggerEvents: true
            }
          })
      }
    }
  }, [
    data,
    deliveryDate,
    deliveryDateUpdate,
    normaliseTimezone,
    onlyMoveThisBox
  ])

  if (loading || !data) {
    return (
      <div className={STYLES.container}>
        <CardSkeleton height="43rem" />
      </div>
    )
  }

  const { user } = data
  const { shippingCountryCode, subscription } = user
  const { box, nextNBoxes } = subscription

  if (!box) return null

  const { isoDeliveryDate } = box

  if (!deliveryDate) return null

  return (
    <div className={STYLES.container}>
      <Card>
        <Grid container rowSpacing={3} direction="column">
          <Grid item>
            <Calendar
              upcomingBoxes={nextNBoxes}
              userId={user.id}
              selectedDate={deliveryDate}
              deliveryDate={new Date(isoDeliveryDate)}
              shippingCountryCode={shippingCountryCode}
              setSelectedDate={setDeliveryDate}
              shouldAttemptToOfferNextDayDelivery={false}
              box={box}
              onlyMoveThisBox={onlyMoveThisBox}
            />
          </Grid>
          {shouldSeeOnlyMoveThisBoxCheckbox && (
            <Grid
              item
              alignSelf="center"
              justifyContent="center"
              alignItems={'baseline'}
              flexDirection="row"
              display={'flex'}
            >
              <CheckBox
                defaultValue={onlyMoveThisBox}
                id={0}
                tabIndex={0}
                marginRight={16}
                onChange={handleCheckboxChange}
              />
              <Text
                text="change_date.only_move_this_box"
                namespace="account"
                colour={'brandBlue500'}
                margin={false}
              />
            </Grid>
          )}
          <Grid item alignSelf="center">
            <Button
              identifier="change_date.update_date"
              disabled={processing || !deliveryDate}
              onClick={updateDeliveryDate}
              typography={{
                text: 'change_date.submit',
                namespace: 'account'
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </div>
  )
}

export default ChangeDatePage
