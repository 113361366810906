import { gql } from '@apollo/client'

const PLAN_CONFIRMATION_QUERY = gql`
  query PlanConfirmationQuery($numOfBoxes: Int!) {
    user {
      id
      subscription {
        id
        status
        nextNBoxes(num: $numOfBoxes) {
          id
          isoDeliveryDate
          editable
        }
        nextEditableBox {
          isoDeliveryDate
        }
      }
    }
  }
`
export { PLAN_CONFIRMATION_QUERY }
