// @noflow
import 'scroll-behavior-polyfill'
import 'whatwg-fetch'

import { PaymentMethodable, Provider } from '@/types'

import { State } from '../../../PayOnOwnDevicePage'
import formatSubscriptionData from '../../../helpers/buiildSubscriptionData'
import { validate } from '../../../helpers/inputs'
import { LoginUrlProps } from '../../../screens/PaymentScreen/PaymentScreen'
import { submitSubscription } from '../../submitSubscription'
import stripeMutationMethods from '../sharedStripeMutationMethods'

const buySubscriptionWithSepaDirectDebit = async ({
  intentSecret,
  paymentPageState,
  navigateToNextStep
}: {
  intentSecret: string
  paymentPageState: State
  navigateToNextStep: (input: LoginUrlProps) => void
}): Promise<void> => {
  const { sepaPaymentMethodIdFromIntentSecret, raiseBannerError } =
    stripeMutationMethods()
  return await sepaPaymentMethodIdFromIntentSecret({
    intentSecret,
    intentType: 'payment_intent'
  })
    .then((result) => {
      if (result) {
        const paymentMethodId = result.data.sepaPaymentMethodIdFromIntentSecret
        const paymentIntentId = intentSecret.split('_secret_')[0]

        const formattedSubData = formatSubscriptionData({
          targetFirstDeliveryDate: paymentPageState.targetFirstDeliveryDate,
          subscriptionData: paymentPageState.data,
          provider: Provider.stripe,
          paymentMethodType: PaymentMethodable.SepaDirectDebit,
          paymentIntentId: paymentIntentId,
          supportedMarketingMethodPurposes:
            paymentPageState.marketingPreferences
        })

        if (!formattedSubData) {
          throw new Error('Error fetching subscription data')
        }
        const response = submitSubscription({
          data: formattedSubData,
          paymentMethodId: validate(
            paymentMethodId,
            'paymentMethodId',
            paymentPageState.data.directSalesPendingSubscription.address
              .shippingCountry.code
          ),
          navigateToNextStep
        })
        if (response && response.errors) {
          throw new Error(response.errors[0].message)
        }
      }
    })
    .catch((error) => {
      window.localStorage.setItem('paymentMethod', 'none')
      raiseBannerError(error)
    })
}

export default buySubscriptionWithSepaDirectDebit
