/* eslint-disable react/require-optimization */
/* eslint-disable react/no-arrow-function-lifecycle */
/* eslint-disable react/destructuring-assignment */
// @flow

import * as React from 'react'

import { tabs, shopTab } from './tabs'

import DashboardAlert from '../shared/DashboardAlert'
import SelectBar from './partials/SelectBar'
import SummaryTab from './tabs/SummaryTab'

import type { Ambassador } from './message_types'

type Props = {|
  ambassador: Ambassador,
  ambassadorShopPath: string
|}

type State = {|
  alertMessage: ?string,
  alertIsError: boolean
|}

class AmbassadorDashboardSummary extends React.Component<Props, State> {
  constructor (props: Props): void {
    super(props)
    this.state = {
      alertMessage: null,
      alertIsError: false,
    }
  }

  setAlertMessage = (alertMessage: string, alertIsError: boolean = false): void => {
    this.setState({alertMessage, alertIsError}, (): void => {
      window.scrollTo(0, 0)
      setTimeout((): void => {
        this.setState({alertMessage: null})
      }, 3000)
    })
  }

  render = (): React.Element<'div'> => (
    <div className='butternutbox-ambassador-dashboard'>
      {/* $FlowFixMe */}
      <DashboardAlert
        message={this.state.alertMessage}
        isError={this.state.alertIsError}
        shippingCountryCode={this.props.ambassador.shipping_country_code}
        preferredLanguage={this.props.ambassador.preferred_language}
      />
      <div className={`affiliate-dashboard__${tabs[0].hash}`}>
        <div className='affiliate-dashboard container'>
          <SelectBar
            tabs={tabs}
            shopTab={ this.props.ambassadorShopPath ? shopTab : []}
            ambassadorShopPath={this.props.ambassadorShopPath}
            ambassador={this.props.ambassador}
            selectedIndex={0}
          />
          <SummaryTab
            ambassador={this.props.ambassador}
            ambassadorShopPath={this.props.ambassadorShopPath}
            // eslint-disable-next-line react/jsx-boolean-value
            visible={true}
            setAlertMessage={this.setAlertMessage}
          />
        </div>
      </div>
    </div>
  )
}

export default AmbassadorDashboardSummary
