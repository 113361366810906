import { CustomerIssueEntryFieldData } from '@types'
import React, { KeyboardEvent, useCallback } from 'react'

import CheckBox from '@/components/elements/atoms/CheckBox/CheckBox'
import Text from '@/components/elements/atoms/Text/Text'
import FlavourImage from '@/components/shared/elements/FlavourImage'

import STYLES from '../ReportIssue.module.sass'

type CheckboxFieldProps = {
  id: string
  label: string
  value: boolean
  thumbnailSrc?: string
  thumbnailQuantity?: number
  setValue: (
    key: string,
    value: boolean,
    dataType: keyof typeof CustomerIssueEntryFieldData
  ) => void
}

const CheckboxField = ({
  id,
  label,
  value,
  thumbnailSrc,
  thumbnailQuantity,
  setValue
}: CheckboxFieldProps): JSX.Element => {
  const onChange = useCallback(
    (checkBoxId: number, newValue: boolean) => {
      setValue(id, newValue, 'boolean')
    },
    [setValue, id]
  )

  const onLabelClick = useCallback(() => {
    setValue(id, !value, 'boolean')
  }, [value, setValue, id])

  const onLabelKeyUp = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === '32' || e.key === '13') {
        setValue(id, !value, 'boolean')
      }
    },
    [value, setValue, id]
  )

  return (
    <div
      role="presentation"
      className={STYLES.checkbox}
      onClick={onLabelClick}
      onKeyUp={onLabelKeyUp}
    >
      <div className={STYLES.wrapper}>
        <CheckBox
          defaultValue={value}
          id={0}
          tabIndex={0}
          onChange={onChange}
        />
        <Text text={label} colour={'brandBlue500'} translate={false} />
      </div>
      {thumbnailSrc && (
        <FlavourImage
          flavour={{
            name: label,
            themeColour: 'white',
            image: {
              src: thumbnailSrc
            }
          }}
          quantity={thumbnailQuantity}
        />
      )}
    </div>
  )
}

export default CheckboxField
