import React from 'react'

import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './DailyFeedingGuideline.module.sass'

import { TypeOfPlan } from '../../../../types'

type Props = {
  multipleDogs: boolean
  dogs: Array<{
    name: string
    planPortionInGrams: number
  }>
  planType: TypeOfPlan
  namespace: string
}

const DailyFeedingGuideline = ({
  multipleDogs,
  dogs,
  planType,
  namespace
}: Props): JSX.Element => {
  return (
    <React.Fragment>
      <div className={STYLES.recommendation}>
        <div className={STYLES.container}>
          <div
            className={`${STYLES.label} ${
              !multipleDogs ? STYLES.labelSingle : ''
            }`}
          >
            <Text
              variant="display16"
              element="div"
              text="box_summary.recommendation_label"
              namespace={namespace}
            />
          </div>
          {multipleDogs &&
            dogs.map((dog) => (
              <div key={`${dog.name}-key`} className={STYLES.dog}>
                <Text
                  variant="textRegular16"
                  element="div"
                  text={dog.name}
                  translate={false}
                />
                <Text
                  variant="display16"
                  element="div"
                  text="box_summary.grams_per_day"
                  variables={{
                    grams: dog.planPortionInGrams
                  }}
                  namespace={namespace}
                />
              </div>
            ))}
        </div>
        {!multipleDogs && (
          <div className={STYLES.size}>
            <Text
              align="right"
              variant="display20"
              element="div"
              text="box_summary.grams"
              variables={{
                grams: dogs[0].planPortionInGrams
              }}
              namespace={namespace}
            />
          </div>
        )}
      </div>
      <div className={STYLES.planType}>
        <Text
          variant="textRegular16"
          element="div"
          text={`box_summary.plan_type.${
            planType === TypeOfPlan.all ? 'all' : 'mix'
          }`}
          colour="brandBlue400"
          namespace={namespace}
        />
      </div>
    </React.Fragment>
  )
}

export { Props }
export default DailyFeedingGuideline
