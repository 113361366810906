import { Status } from '@/types'

type TransformedDeliveryStatus = {
  completed: boolean
  completedWithSuccess: boolean
  completedWithIssue: boolean
  incompleteBeforeDelivery: boolean
  inProgress: boolean
  preparing: boolean
  deliveryProblem: boolean
  manifested: boolean
  inTransit: boolean
  outForDelivery: boolean
  showActions: boolean
  noProgressBar: boolean
  showDescriptionText: boolean
}

const STATUS_GROUPS = {
  completed: [Status.delivered, Status.exception],
  completedWithSuccess: [Status.delivered],
  completedWithIssue: [Status.exception],
  incompleteBeforeDelivery: [
    Status.unallocated,
    Status.allocated,
    Status.unknown,
    Status.cancelled
  ],
  inProgress: [Status.printed, Status.manifested, Status.in, Status.out],
  manifested: [Status.manifested],
  inTransit: [Status.in],
  outForDelivery: [Status.out, Status.attempted_delivery],
  preparing: [Status.printed, Status.manifested, Status.in],
  deliveryProblem: [
    Status.unknown,
    Status.unallocated,
    Status.allocated,
    Status.cancelled,
    Status.exception
  ],
  showActions: [
    Status.exception,
    Status.delivered,
    Status.unknown,
    Status.unallocated,
    Status.allocated,
    Status.cancelled
  ],
  noProgressBar: [Status.printed],
  showDescriptionText: [
    Status.in,
    Status.out,
    Status.attempted_delivery,
    Status.exception,
    Status.allocated,
    Status.unallocated,
    Status.unknown,
    Status.cancelled
  ]
}

const useTransformedDeliveryStatus = (
  status: Status
): TransformedDeliveryStatus =>
  Object.fromEntries(
    Object.entries(STATUS_GROUPS).map(([key, statuses]) => [
      key,
      statuses.includes(status)
    ])
  ) as TransformedDeliveryStatus

export { useTransformedDeliveryStatus }
