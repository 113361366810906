// @noflow
import React from 'react'
import { useTranslation } from 'react-i18next'

import segmentTrack from '@/components/analytics/Analytics'
import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'
import Image from '@/components/elements/atoms/Image'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from '../../CardsCarousel.module.sass'

import { ruffGuideModalState } from '../../../RuffGuideModal/RuffGuideModal'

type Props = {
  namespace: string
}

const RuffGuideCard = ({ namespace }: Props): JSX.Element => {
  const copyContext = 'refer_a_friend.give_and_get_tab.cards.ruff_guide'

  const handleReferralSavingsOnClick = React.useCallback(() => {
    ruffGuideModalState(true)
    segmentTrack('Ruff-guide modal opened')
  }, [])

  const { t } = useTranslation(namespace)

  return (
    <Card padding={16} fill={false}>
      <div className={STYLES.cardContentWrapper}>
        <Image
          slug="two-dogs-bowl-pink-bg-illustration"
          image={{ width: 228, height: 104 }}
          alt={t(`${copyContext}.alt`)}
        />
        <Text
          variant="display20"
          text={`${copyContext}.title`}
          namespace={namespace}
          margin={false}
          align="left"
        />
        <Text
          variant="textRegular16"
          text={`${copyContext}.description`}
          colour="brandBlue400"
          namespace={namespace}
          margin={false}
          align="left"
        />
        <div className={STYLES.buttonWrapper}>
          <Button
            typography={{ text: `${copyContext}.cta`, namespace }}
            variant="secondary"
            onClick={handleReferralSavingsOnClick}
            disableAnalytics
            fullWidth
          />
        </div>
      </div>
    </Card>
  )
}

export default RuffGuideCard
