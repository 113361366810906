import React from 'react'

const Info = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
    <rect width="20" height="20" fill="#078AA2" rx="10" />
    <g fill="#fff" clipPath="url(#a)">
      <path d="M9 6.667a1 1 0 1 1 2 0 1 1 0 0 1-2 0ZM9 9.5a1 1 0 0 1 2 0v3.833a1 1 0 1 1-2 0V9.5Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M4 4h12v12H4z" />
      </clipPath>
    </defs>
  </svg>
)
export default Info
