import React from 'react'

// Constants
import SUPPORT_COLOURS from '@/constants/SupportColours'

// Components
import AnimatedBoostLogo from '@/components/elements/atoms/BoostLogo/AnimatedBoostLogo'
import Icon from '@/components/elements/atoms/Icon/Icon'
import SkeletonImage from '@/components/elements/atoms/SkeletonImage/SkeletonImage'
import Text from '@/components/elements/atoms/Text/Text'

// Styles
import STYLES from './BoostBanner.module.sass'

type Props = {
  loading: boolean
}

const BoostBanner = ({ loading }: Props): JSX.Element => {
  if (loading) {
    return (
      <div className={STYLES.container}>
        <SkeletonImage height={130} />
      </div>
    )
  }

  return (
    <div className={STYLES.container}>
      <svg
        width="1308"
        height="785"
        viewBox="0 0 1308 785"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid slice"
        className={STYLES.blobOverlay}
        filter="blur(50px)"
      >
        <path
          d="M452.602 464.523C376.923 391.074 363.863 323.73 330.313 223.747C304.874 147.935 351.495 70.9068 287.015 23.6092C210.118 -32.797 116.943 20.8072 52.2634 90.8888C-23.6626 173.156 -0.132036 267.781 26.8719 376.373L27.0133 376.941C69.2883 546.945 186.395 615.574 341.699 696.624C562.116 811.657 747.372 808.314 978.069 715.604C1082.04 673.821 1150.53 649.751 1220.63 562.329C1269.75 501.06 1302.49 459.27 1307.32 380.889C1318.97 192.209 1068.42 188.531 884.345 145.496C800.006 125.779 744.725 91.8478 663.747 122.579C485.228 190.326 1022.18 408.271 896.884 552.355C780.83 685.813 579.516 587.7 452.602 464.523Z"
          className={STYLES.blob}
          fill={SUPPORT_COLOURS.shadowYellow}
        />
      </svg>
      <div className={STYLES.contentWrapper}>
        <AnimatedBoostLogo />
        <div className={STYLES.discount}>
          <Icon
            accentColour="boostDark"
            asset="checkmark"
            size={13}
            width={13}
          />
          <Text
            variant="textRegular14"
            colour="boostDark"
            text="boost_banner.copy"
            namespace="molecules"
            margin={false}
            align="center"
          />
        </div>
      </div>
    </div>
  )
}

export { Props }
export default BoostBanner
