// @noflow
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

// Hooks
import useButternutBoost from '@/hooks/useButternutBoost'

// Component
import segmentTrack from '@/components/analytics/Analytics'
import BoostExtras50DiscountBadge from '@/components/elements/atoms/BoostExtrasDiscountBadge/BoostExtras50DiscountBadge'
import BoostExtrasDiscountBadge from '@/components/elements/atoms/BoostExtrasDiscountBadge/BoostExtrasDiscountBadge'
import Card, { generateVariant } from '@/components/elements/atoms/Card/Card'
import Image from '@/components/elements/atoms/Image/Image'
import Text from '@/components/elements/atoms/Text/Text'
import { NavigateContext } from '@/components/pages/App'

import STYLES from './AddMore.module.sass'

import { MembershipTypes } from '@/types'

type Props = {
  link: string
  hasExtras?: boolean
  isBoostedBox?: boolean | null
}

const AddMore = ({
  link,
  hasExtras = false,
  isBoostedBox = false
}: Props): JSX.Element | null => {
  const navigate = useContext(NavigateContext)
  const { t } = useTranslation('shared')
  const { boostedType } = useButternutBoost()

  const spaceForMoreClicked = React.useCallback((): void => {
    segmentTrack('Order Details Page - Space for more items area clicked', {
      link
    })
    navigate(link, link)
  }, [link, navigate])

  const boostAddMoreButtonClicked = React.useCallback((): void => {
    segmentTrack('Order Details Page - Boost - Add extras button clicked', {
      link
    })

    navigate(link, link)
  }, [navigate, link])

  if (hasExtras) return null

  if (isBoostedBox) {
    return (
      <Card
        background={
          boostedType === MembershipTypes.boost ? 'boosted' : 'boosted_ap_only'
        }
        onClick={boostAddMoreButtonClicked}
        identifier="boost_add_more"
        id="edit-extras-tour-target"
      >
        <div>
          {boostedType === MembershipTypes.boost ? (
            <BoostExtrasDiscountBadge />
          ) : (
            <BoostExtras50DiscountBadge />
          )}
        </div>
        <div>
          <Text
            variant="display16"
            colour={
              boostedType === MembershipTypes.boost
                ? 'brandWhite'
                : 'brandBlue500'
            }
            namespace="shared"
            text={
              boostedType === MembershipTypes.boost
                ? 'box_breakdown.boost_upsell_title'
                : 'box_breakdown.boost_upsell_title_ap_only'
            }
          />
          {boostedType === MembershipTypes.boost && (
            <Text
              variant="textRegular14"
              colour={
                boostedType === MembershipTypes.boost
                  ? 'brandWhite'
                  : 'brandBlue500'
              }
              namespace="shared"
              text="box_breakdown.boost_upsell"
            />
          )}
        </div>
      </Card>
    )
  }

  return (
    <Card
      border="dashed"
      variant={generateVariant('transparent', 'brandBlue500')}
      onClick={spaceForMoreClicked}
      identifier="box_breakdown.see_extras"
      id="edit-extras-tour-target"
    >
      <div className={STYLES.content}>
        <div>
          <Image
            slug="extras-recipe-retriever"
            image={{ width: 117, height: 117 }}
            alt={t('box_breakdown.boost_upsell_image_alt')}
          />
        </div>

        <div>
          <Text
            variant="display16"
            colour="brandBlue500"
            namespace="shared"
            text="box_breakdown.see_extras"
          />
          <Text
            variant="textRegular16"
            colour="brandBlue400"
            namespace="shared"
            text="box_breakdown.space_for_more"
          />
        </div>
      </div>
    </Card>
  )
}

export { Props }
export default AddMore
