// @noflow
const getURLParam = (
  index: number,
  overrideURL?: string
): string | undefined => {
  const url: URL = new URL(
    overrideURL !== undefined ? overrideURL : window.location.href
  )
  const parts: string[] = url.pathname.split('/')
  parts.splice(0, 1)

  if (parts.length - 1 > index) return undefined

  return parts[index]
}

const getURLNumberParam = (
  index: number,
  overrideURL?: string
): number | undefined => {
  const param = getURLParam(index, overrideURL)

  if (Number.isNaN(Number(param))) return undefined

  return Number(param)
}

export { getURLParam, getURLNumberParam }
