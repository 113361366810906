/* eslint-disable i18next/no-literal-string */
// @noflow
import { useAccessManagement } from '@/context/accessManagement/accessManagement'
import { ACCOUNT_ROUTES } from '@/routes'
import { useReactiveVar } from '@apollo/client'
import { Grid } from '@mui/material'
import isUndefined from 'lodash/isUndefined'
import React, { Fragment, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { dogsDataVar, settingsVar } from '@/services/apollo'

import scrollToElement from '@/utils/scrollToElement'

import useButternutBoost from '@/hooks/useButternutBoost'
import useWindowSize from '@/hooks/useWindowSize'

import BoxIcon from 'assets/images/icons/box.svg'

import segmentTrack from '@/components/analytics/Analytics'
import BoostLogo, {
  BoostLogoSizes
} from '@/components/elements/atoms/BoostLogo/BoostLogo'
import { BoxDeliveryStatusText } from '@/components/elements/atoms/BoxDeliveryStatusText/BoxDeliveryStatusText'
import Card, { generateVariant } from '@/components/elements/atoms/Card/Card'
import FlatButton from '@/components/elements/atoms/FlatButton/FlatButton'
import Plus from '@/components/elements/atoms/Icon/Icons/Plus'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import Text from '@/components/elements/atoms/Text/Text'
import ProductThumb, {
  Props as ProductThumbProps
} from '@/components/elements/molecules/ProductThumb/ProductThumb'
import SwiperSlider from '@/components/elements/molecules/SwiperSlider/SwiperSlider'
import { OrderSummaryModalFragment as OrderSummary } from '@/components/modals/OrderSummaryModal/fragments/__generated__/OrderSummaryModalFragment'
import ModalTrigger from '@/components/modals/trigger'
import { BREAKPOINTS } from '@/components/pages/App/App'
import DeliveryAddress from '@/components/pages/DashboardV3/components/DeliveryAddress/DeliveryAddress'
import {
  upcomingBoxQueryV3_user_ordersByDate_Box_address as Address,
  upcomingBoxQueryV3_user_ordersByDate_Box as Box
} from '@/components/pages/DashboardV3/components/UpcomingBoxesV3/queries/__generated__/upcomingBoxQueryV3'
import { Order } from '@/components/pages/DashboardV3/components/UpcomingBoxesV3/utilities'

import STYLES from './BoxDetailsV3.module.sass'

import {
  Code as CountryCode,
  Descriptor,
  Language,
  MembershipTypes,
  SubscriptionStatus
} from '@/types'

import AddMore from './AddMore/AddMore'
import BoxActionButtons from './BoxActionButtons/BoxActionButtons'

type Props = {
  id: string
  type: Order['type']
  descriptor: Box['descriptor']
  address: Address
  amendable: boolean
  deliveryDate: Date
  cutOffDate: Date
  meals?: ProductThumbProps[]
  isFull?: boolean
  extras?: ProductThumbProps[]
  deliveryStatus?: string | null
  isTrialBox?: boolean | null
  isBoostedBox?: boolean | null
  subscriptionStatus?: string | null
  invoiceIssued?: boolean
  shippingCountryCode: CountryCode
  invoiceId?: string
  showInvoiceDownloadDirections?: boolean
  preferredLanguage?: Language
  invoices?: Box['invoices']
  carrierName?: string | null
  orderSummary?: OrderSummary
}

const title = (
  type: Order['type'],
  context: string,
  descriptor: Box['descriptor'],
  isTrialBox?: boolean | null,
  isBoostedBox?: boolean | null,
  courierEnabled?: boolean
): string | Array<string> => {
  const ending =
    courierEnabled && descriptor !== Descriptor.past
      ? 'and_courier_title'
      : 'title'
  if (isTrialBox) return `${context}.starter_core_order_${ending}`
  if (isBoostedBox) return `${context}.boosted_core_order_${ending}`
  switch (type) {
    case 'nonCoreOrder': {
      return `${context}.non_core_order_${ending}`
    }
    case 'oneOffBox': {
      return `${context}.one_off_${ending}`
    }
    default: {
      return [
        `${context}.${descriptor}_core_order_${ending}`,
        `${context}.core_order_${ending}`
      ]
    }
  }
}

/**
 * Displays the details of a subscription order or non-core order
 *
 * @param {string} id - The id of the order
 * @param {string} type - The type of order
 * @param {Descriptor} descriptor - Whether the order is past, next or future in timeline
 * @param {boolean} amendable - Whether the order is amendable
 * @param {Address} address - The address of the order
 * @param {number} numberOfPouches - The number of pouches in the order
 * @param {number} pouchSize - The size of the pouches in the order
 * @param {number} durationInDays - The duration of the order in days
 * @param {Date} deliveryDate - The delivery date of the order
 * @param {Date} cutOffDate - The cut off date of the order
 * @param {Date} shippingDate - The shipping date of the order
 * @param {ProductThumbProps[]} meals - The meals in the order
 * @param {boolean} isFull - Whether the order is full
 * @param {ProductThumbProps[]} extras - The extras in the order
 * @param {string} deliveryStatus - The delivery status of the order
 * @param {boolean} isTrialBox - Whether the order is a trial/starter box
 * @param {boolean} isBoostedBox - Whether the order is a boosted box
 * @param {boolean} isFreeBox - Whether the order is a free box
 * @param {boolean} isReplacementBox - Whether the order is a replacement box
 * @param {BoxOrder | NonCoreOrderPricing} pricing - The pricing of the order
 * @param {NextUpcomingPrice} nextUpcomingPrice - The next upcoming price of the plan
 * @param {Plan['pricePerDay']} planPricePerDay - The price per day of the plan
 * @param {string} subscriptionStatus
 * @param {boolean} invoiceIssued - Whether the invoice has been issued
 * @param {CountryCode} shippingCountryCode - The shipping country code
 * @param {string} invoiceId - The invoice id
 * @param {boolean} showInvoiceDownloadDirections - Whether to show invoice download directions
 * @param {Language} preferredLanguage - The user's preferred language
 * @param {Array<Invoice>} invoices - The invoices for the order
 * @returns {JSX.Element} - The component
 */
const BoxDetailsV3 = ({
  id,
  type,
  amendable,
  address,
  descriptor,
  deliveryDate,
  cutOffDate,
  meals,
  extras,
  isFull,
  deliveryStatus,
  isTrialBox,
  isBoostedBox,
  subscriptionStatus,
  invoiceIssued,
  shippingCountryCode,
  invoiceId,
  showInvoiceDownloadDirections,
  preferredLanguage,
  invoices,
  carrierName,
  orderSummary
}: Props): JSX.Element => {
  const { windowWidth } = useWindowSize()
  const namespace = 'account'
  const navigate = useNavigate()
  const accessManagement = useAccessManagement()
  const { boostedType } = useButternutBoost()
  const { carrier_preferences_enabled } = useReactiveVar(settingsVar)
  const dogsData = useReactiveVar(dogsDataVar)
  const isMobile = windowWidth < BREAKPOINTS.sm
  const isDesktop = windowWidth >= BREAKPOINTS.md
  const copyContext = 'home.box_details'
  const cardPadding = isMobile ? 16 : 32

  const editRecipes = useCallback(
    () => navigate(ACCOUNT_ROUTES.editRecipes),
    [navigate]
  )

  const editExtras = useCallback(
    // eslint-disable-next-line i18next/no-literal-string
    () => navigate(`${ACCOUNT_ROUTES.editExtras}/${id}`),
    [id, navigate]
  )

  const canUserAccessExtras = accessManagement?.canUserAccessRoute(
    ACCOUNT_ROUTES.extras
  )
  const hasExtras = !isUndefined(extras) && extras.length > 0
  const showAddExtrasPrompt =
    !hasExtras && amendable && canUserAccessExtras && type !== 'oneOffBox'
  const showOrderButtons = descriptor === Descriptor.past

  const handleAddMoreExtrasClick = React.useCallback((): void => {
    const link = ACCOUNT_ROUTES.extras
    segmentTrack('Order Details Page - Add more extras button clicked', {
      link
    })
    navigate(link)
  }, [navigate])

  const suspendedSubscriptionStatus =
    subscriptionStatus === SubscriptionStatus.suspended

  const renderExtrasSlides = useCallback(
    (hasExtras: boolean, amendable: boolean): JSX.Element[] => {
      if (!hasExtras || !extras) return []
      const slides = extras?.map((extra: ProductThumbProps): JSX.Element => {
        return (
          <ProductThumb
            image={extra.image}
            alt={extra.alt}
            quantity={extra.quantity}
            key={extra.image}
            size={extra.size}
            v3
            hideBackground
          />
        )
      })
      if (amendable && !isFull && canUserAccessExtras) {
        slides.push(
          <div key="add-more" className={STYLES.addMoreExtrasCard}>
            <Card
              border="dashed"
              onClick={handleAddMoreExtrasClick}
              padding={0}
              variant={generateVariant(
                'brandYellow200',
                'brandYellow600',
                'brandYellow300'
              )}
              identifier="box_details.add_more_extras"
            >
              <div className={STYLES.addMoreExtras}>
                <Plus size={isDesktop ? 24 : 14} accentColour="brandBlue500" />
                <Text
                  margin={false}
                  namespace="shared"
                  text="box_breakdown.see_more_extras"
                  colour="brandBlue500"
                  variant="display16"
                  align="center"
                />
              </div>
            </Card>
          </div>
        )
      }
      return slides
    },
    [extras, isFull, canUserAccessExtras, handleAddMoreExtrasClick, isDesktop]
  )

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const focusParam = urlParams.get('focus')
    if (focusParam === 'extrasSection') {
      const el = document.getElementById('extrasSection')
      if (el) scrollToElement(el as HTMLElement)
    }
  }, [])

  return (
    <Card mask padding={0} shadow fadedOut={suspendedSubscriptionStatus}>
      <SectionWrapper
        bgColour="cardboardBrown"
        padding={{
          top: cardPadding,
          left: cardPadding,
          right: cardPadding,
          bottom: 24
        }}
        margin={0}
      >
        <div className={STYLES.boxDetailsHeader}>
          <div className={STYLES.boxIcon}>
            <img alt={''} src={BoxIcon} />
          </div>
          <div>
            <Text
              variant="display20"
              text={title(
                type,
                copyContext,
                descriptor,
                isTrialBox,
                isBoostedBox && boostedType === MembershipTypes.boost,
                carrier_preferences_enabled
              )}
              margin={false}
              colour="brandWhite"
              namespace={namespace}
              variables={{
                carrierName
              }}
            />
            {!isTrialBox && (
              <BoxDeliveryStatusText
                amendable={amendable}
                status={deliveryStatus}
                cutOffDate={cutOffDate}
                deliveryDate={deliveryDate}
                type={type}
                descriptor={descriptor}
                colour="brandWhite"
              />
            )}
          </div>
        </div>
      </SectionWrapper>
      <SectionWrapper padding={cardPadding} margin={0}>
        <Grid container rowSpacing={2} flexDirection="row-reverse">
          {showOrderButtons && (
            <Grid item xs={12} md={6}>
              <BoxActionButtons
                namespace={namespace}
                copyContext={copyContext}
                showDownloadReceiptButton={showOrderButtons}
                showHelpWithOrderButton={showOrderButtons}
                invoiceIssued={invoiceIssued}
                shippingCountryCode={shippingCountryCode}
                invoiceId={invoiceId}
                showInvoiceDownloadDirections={showInvoiceDownloadDirections}
                preferredLanguage={preferredLanguage}
                invoices={invoices}
              />
            </Grid>
          )}
          <Grid item xs={12} md={showOrderButtons ? 6 : 12}>
            <DeliveryAddress
              address={address}
              amendable={amendable}
              namespace={namespace}
              copyContext={copyContext}
            />
          </Grid>
        </Grid>
        <Fragment>
          {!isUndefined(meals) && (
            <Grid
              item
              container
              rowSpacing={0}
              flexDirection="column"
              paddingTop={0}
            >
              <Grid item className={STYLES.slider}>
                <SectionWrapper
                  margin={{
                    top: 24,
                    bottom: isDesktop ? 24 : 16
                  }}
                  headerTypography={{
                    variant: 'display16',
                    namespace,
                    text: `${copyContext}.fresh_meals_v3`,
                    translate: true
                  }}
                  headerRightAdornment={
                    amendable
                      ? {
                          variant: 'custom',
                          content: (
                            <FlatButton
                              text={{
                                text: `${copyContext}.edit`,
                                namespace,
                                translate: true
                              }}
                              onClick={editRecipes}
                              identifier="box_details.edit_meals"
                            />
                          )
                        }
                      : undefined
                  }
                >
                  <SwiperSlider
                    variant="brandBlue100"
                    paddingOverride={isDesktop ? 32 : 16}
                  >
                    {meals.map((meal) => (
                      <ProductThumb
                        image={meal.image}
                        alt={meal.alt}
                        key={meal.image}
                        v3
                        hideBackground
                      />
                    ))}
                  </SwiperSlider>
                </SectionWrapper>
              </Grid>
            </Grid>
          )}
          {(hasExtras || showAddExtrasPrompt) && (
            <Grid item container flexDirection="column" id="extrasSection">
              <Grid item className={STYLES.slider}>
                <SectionWrapper
                  margin={{
                    top: 0,
                    bottom: isDesktop ? 24 : 16
                  }}
                  headerTypography={{
                    variant: 'display16',
                    namespace,
                    text: `${copyContext}.extras`,
                    translate: true
                  }}
                  headerLeftAdornment={
                    isBoostedBox && boostedType === MembershipTypes.boost
                      ? {
                          content: (
                            <div>
                              <BoostLogo
                                size={
                                  isMobile
                                    ? BoostLogoSizes.medium
                                    : BoostLogoSizes.large
                                }
                              />
                            </div>
                          ),
                          variant: 'custom'
                        }
                      : undefined
                  }
                  headerRightAdornment={
                    amendable && hasExtras
                      ? {
                          variant: 'custom',
                          content: (
                            <FlatButton
                              text={{
                                text: `${copyContext}.edit`,
                                namespace,
                                translate: true
                              }}
                              onClick={editExtras}
                              identifier="box_details.edit_extras"
                            />
                          )
                        }
                      : undefined
                  }
                >
                  <>
                    {hasExtras && (
                      <SwiperSlider
                        variant="brandBlue100"
                        paddingOverride={isDesktop ? 32 : 16}
                      >
                        {renderExtrasSlides(hasExtras, amendable)}
                      </SwiperSlider>
                    )}
                    {showAddExtrasPrompt && (
                      <Grid container item alignItems="stretch">
                        <Grid item xs={12} lg={5}>
                          <AddMore
                            link={ACCOUNT_ROUTES.extras}
                            dogName={dogsData?.dogs[0]?.name ?? ''}
                            hasExtras={hasExtras}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </>
                </SectionWrapper>
              </Grid>
            </Grid>
          )}
          {orderSummary && (
            <ModalTrigger
              text={{
                namespace,
                text: `${copyContext}.view_order_summary`,
                margin: false
              }}
              modalId="order-summary-modal"
              modalOptions={{
                data: {
                  order: orderSummary
                }
              }}
              backgroundColor="brandYellow100"
            />
          )}
        </Fragment>
      </SectionWrapper>
    </Card>
  )
}
export { Props }
export default BoxDetailsV3
