// @noflow
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react'

import type { CheckoutPage } from '../../../queries/__generated__/CheckoutPage'

import { checkoutPageState } from '../../../CheckoutPage'
import type { ActivePaymentView, PayPalOption } from '../../../types'
import { findPaymentMethodsAvaliable } from '../helpers/paymentMethodsAvaliable'
import { UseBuySubscriptionWithPaymentRequest } from './useBuySubscriptionWithPaymentRequest'

type AffiliateType = CheckoutPage['guest']['affiliateType']

type RequiresPaymentDetailsOnCheckout =
  CheckoutPage['guest']['requiresPaymentDetailsOnCheckout']

type ShippingCountryCode =
  CheckoutPage['guest']['assumedShippingCountry']['code']

type UsePaymentView = {
  activePaymentView: ActivePaymentView
  setActivePaymentView: Dispatch<SetStateAction<ActivePaymentView>>
  currentPayPalOption: PayPalOption
  togglePaymentOptionsVisibility: (newPaymentType: ActivePaymentView) => void
}

const payPalOption = (
  affiliateType: AffiliateType,
  shippingCountryCode: ShippingCountryCode
): PayPalOption => {
  const avaliablePaymentMethods =
    findPaymentMethodsAvaliable(shippingCountryCode)

  if (affiliateType === 'not_affiliate') {
    return avaliablePaymentMethods.includes('payPal')
      ? 'with_paypal'
      : 'without_paypal'
  } else {
    return 'without_paypal'
  }
}

const usePaymentView = ({
  affiliateType,
  shippingCountryCode,
  requiresPayment,
  paymentRequest
}: {
  affiliateType: AffiliateType
  shippingCountryCode: ShippingCountryCode
  requiresPayment: RequiresPaymentDetailsOnCheckout
  paymentRequest: UseBuySubscriptionWithPaymentRequest['paymentRequest']
}): UsePaymentView => {
  const currentPayPalOption = payPalOption(affiliateType, shippingCountryCode)

  const [activePaymentView, setActivePaymentView] =
    useState<ActivePaymentView>('none')

  useEffect(() => {
    if (requiresPayment) {
      const availablePaymentMethods =
        findPaymentMethodsAvaliable(shippingCountryCode)
      setActivePaymentView(availablePaymentMethods[0])
    } else {
      setActivePaymentView('none')
    }
  }, [
    requiresPayment,
    shippingCountryCode,
    currentPayPalOption,
    paymentRequest
  ])

  const togglePaymentOptionsVisibility = useCallback(
    (newPaymentType: ActivePaymentView): void => {
      const { sections } = checkoutPageState()
      sections.paymentDetails.valid = false

      setActivePaymentView(newPaymentType)
      checkoutPageState({
        ...checkoutPageState(),
        sections
      })
    },
    []
  )

  return {
    activePaymentView,
    setActivePaymentView,
    currentPayPalOption,
    togglePaymentOptionsVisibility
  }
}

export default usePaymentView
