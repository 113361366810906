// @noflow
import { Language } from '@/packs/localisation'
import React, { Fragment } from 'react'

import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { formatCurrencyWithDecimal } from '@/utils/currency'

import Text from '@/components/elements/atoms/Text/Text'
import Tooltip from '@/components/elements/atoms/Tooltip/Tooltip'

import { Code } from '@/types'

type Props = {
  name: string
  slug: string
  shippingCountryCode: Code
  language: Language
  recipeSurcharge: number
  shouldSeeRecipeSurchargeTooltips: boolean
  shouldSeeRecipeSurchargePriceIncreasePl: boolean
}

export const SurchargeTooltip = ({
  name,
  slug,
  shippingCountryCode,
  language,
  recipeSurcharge,
  shouldSeeRecipeSurchargeTooltips,
  shouldSeeRecipeSurchargePriceIncreasePl
}: Props): JSX.Element => {
  const currencyOptions = countryCodeToLocaleCurrency(
    shippingCountryCode,
    language
  )

  const hasSurcharge = recipeSurcharge > 0

  /**
   * Show the surcharge price without a tooltip if recipe has surcharge
   * and `shouldSeeRecipeSurchargeTooltips` feature flag is `false`.
   */
  const shouldShowSurchargePriceText = ((): boolean =>
    hasSurcharge && !shouldSeeRecipeSurchargeTooltips)()

  const shouldSeePriceIncreasePl = ((): boolean =>
    shippingCountryCode === Code.PL &&
    (slug === 'pl_lamb' ||
      slug === 'pl_beef' ||
      slug === 'pl_turkey' ||
      slug === 'pl_game' ||
      slug === 'pl_duck_and_chicken'))()

  /**
   * Show the surcharge price tooltip
   *
   * Show surcharge tooltip for Poland if the feature flag is `true` and the recipe is lamb, beef or turkey
   *
   * For other regions always `false` if surcharge text shown instead
   *
   * Show surcharge tooltip if recipe surcharge available and `shouldSeeRecipeSurchargeTooltips`
   * feature flag is `true`
   */
  const shouldShowRecipeSurchargeTooltip = ((): boolean => {
    if (shouldSeePriceIncreasePl) {
      return shouldSeeRecipeSurchargePriceIncreasePl
    }

    if (hasSurcharge) {
      return shouldSeeRecipeSurchargeTooltips
    } else {
      return false
    }
  })()

  const copyContext = ((): string => {
    switch (shippingCountryCode) {
      case Code.NL:
      case Code.BE: {
        if (
          slug === 'lamb' ||
          slug === 'beef' ||
          slug === 'turkey' ||
          slug === 'game'
        ) {
          return slug
        } else {
          return 'generic'
        }
      }
      case Code.GB:
      case Code.NI:
      case Code.IE: {
        return 'generic'
      }
      case Code.PL: {
        if (
          shouldSeePriceIncreasePl &&
          shouldSeeRecipeSurchargePriceIncreasePl
        ) {
          return 'price_increase'
        }

        return 'generic'
      }
      case Code.DE:
      default: {
        return 'generic'
      }
    }
  })()

  const formattedRecipeSurcharge = hasSurcharge
    ? formatCurrencyWithDecimal(recipeSurcharge, currencyOptions)
    : null

  return (
    <Fragment>
      {shouldShowSurchargePriceText && (
        <Text
          text={`+${formattedRecipeSurcharge}`}
          namespace="shared"
          variant="textRegular14"
          colour="brandBlue400"
          margin={false}
          translate={false}
        />
      )}
      {shouldShowRecipeSurchargeTooltip && (
        <Tooltip
          {...(formattedRecipeSurcharge
            ? {
                label: {
                  text: `+${formattedRecipeSurcharge}`,
                  namespace: 'shared',
                  translate: false
                }
              }
            : {})}
          identifier="recipe_card.surcharge_tooltip"
        >
          <Text
            namespace="shared"
            text="box_breakdown.surcharge_tooltip"
            variables={{ context: copyContext, flavourName: name }}
            variant="textRegular14"
            colour="brandBlue400"
            margin={false}
          />
        </Tooltip>
      )}
    </Fragment>
  )
}

export default SurchargeTooltip
