// @noflow
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'
import React, {
  Children,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState
} from 'react'

import useWindowSize from '@/hooks/useWindowSize'

import Spinner from '@/components/elements/atoms/Spinner/Spinner'
import Text, { Props as TextProps } from '@/components/elements/atoms/Text/Text'
import { BREAKPOINTS } from '@/components/pages/App/App'

import STYLES from './Tabs.module.sass'

type TabHeaderAllowedColours = 'brandWhite'

type Props = {
  initial?: string
  variant?: 'secondary'
  header?: TabHeaderAllowedColours
  loading?: boolean
  children: React.ReactElement[]
  onChange?: (id: string) => void
  noMarginBottom?: boolean
}

type TabProps = {
  id: string
  // eslint-disable-next-line react/no-unused-prop-types
  label: TextProps
  selected?: boolean
  children: ReactNode
  hidden?: boolean
}

const Tab = ({
  id,
  selected,
  children,
  hidden = false
}: TabProps): ReactElement | null => {
  if (hidden) return null

  const className = classNames(STYLES.tab, {
    [STYLES.selected]: selected
  })

  return (
    <div className={className} data-tab={`${id}`} aria-hidden={!selected}>
      {children}
    </div>
  )
}

const TabNav = (
  tabs: ReactElement[],
  selected: string,
  onSelect: (id: string) => void,
  variant?: Props['variant'],
  header?: Props['header']
): ReactElement => {
  const { windowWidth } = useWindowSize()
  const tabVariant = useCallback(
    (variant?: Props['variant']): TextProps['variant'] => {
      if (windowWidth < BREAKPOINTS.sm) return 'textRegular14'
      switch (variant) {
        case 'secondary':
          return 'textRegular14'
        default:
          return 'display20'
      }
    },
    [windowWidth]
  )
  return (
    <nav
      className={`${STYLES.nav} ${
        !isUndefined(header) ? (STYLES[`${header}Header`] as string) : ''
      }`}
    >
      {Children.map(tabs, (child) => {
        if (isEmpty(child.props)) return
        if (child.props.hidden) return

        const { id, label } = child.props
        const { namespace, text, translate, variables } = label
        const isSelected: boolean = selected === id

        return (
          <button
            type="button"
            key={`tab-${id}-key`}
            className={`${STYLES.button} ${isSelected ? STYLES.selected : ''}`}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => onSelect(id)}
          >
            <Text
              element="span"
              text={text}
              variant={tabVariant(variant)}
              shouldScale
              colour={isSelected ? 'brandBlue500' : 'brandBlue400'}
              namespace={namespace}
              translate={translate}
              variables={variables}
            />
          </button>
        )
      })}
    </nav>
  )
}

const Tabs = ({
  initial,
  variant,
  loading,
  onChange,
  children,
  header,
  noMarginBottom = false
}: Props): JSX.Element => {
  const [selected, setSelected] = useState<string>(
    initial || children[0].props.id
  )

  useEffect(() => {
    if (!isUndefined(onChange)) onChange(selected)
  }, [selected, onChange])

  const classname = classNames(STYLES.tabs, {
    [variant ? STYLES[variant] : '']: variant,
    [STYLES.noMarginBottom]: noMarginBottom
  })

  return (
    <div className={classname}>
      {TabNav(children, selected, setSelected, variant, header)}
      <div className={STYLES.container}>
        {loading ? (
          <div className={STYLES.loading}>
            <Spinner />
          </div>
        ) : (
          Children.map(children, (child) =>
            React.cloneElement(child, { selected: child.props.id === selected })
          )
        )}
      </div>
    </div>
  )
}

export { Props, Tab }
export default Tabs
