import { PaymentRequestButtonElement } from '@stripe/react-stripe-js'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import Card from '@/components/elements/atoms/Card/Card'

import STYLES from './PaymentComponents.module.sass'

import { PaymentPageContext } from '../../PayOnOwnDevicePage'
import { useBuySubscriptionWithPaymentRequest } from '../../hooks/stripe/apple_and_google_pay/useBuySubscriptionWithPaymentRequest'
import { LoginUrlProps } from '../../screens/PaymentScreen/PaymentScreen'
import { SubmissionState } from '../../types'

const PaymentRequestOption = ({
  submissionState,
  navigateToNextStep,
  setGooglePayOrApplePay,
  namespace
}: {
  submissionState: SubmissionState
  navigateToNextStep: (input: LoginUrlProps) => void
  setGooglePayOrApplePay: (value: boolean) => void
  namespace: string
}): JSX.Element | null => {
  const { paymentPageState } = useContext(PaymentPageContext)
  if (!paymentPageState) {
    throw new Error('Subscription data not found')
  }
  const { t } = useTranslation(namespace)

  const {
    paymentRequest,
    availablePaymentRequestMethod,
    paymentRequestInProgress
  } = useBuySubscriptionWithPaymentRequest({
    paymentPageState,
    t,
    shouldSeeApplePay: true,
    shouldSeeGooglePay: true,
    submissionState,
    navigateToNextStep,
    setGooglePayOrApplePay
  })

  if (availablePaymentRequestMethod === 'none' || paymentRequest === null) {
    return null
  }

  return (
    <div className={STYLES.applePayButton}>
      <Card
        disabled={paymentRequestInProgress.inProgress}
        squared={{ topLeft: true, topRight: true }}
      >
        {paymentRequest !== null && (
          <PaymentRequestButtonElement
            className="PaymentRequestButton"
            options={{
              paymentRequest: paymentRequest
            }}
          />
        )}
      </Card>
    </div>
  )
}

export default PaymentRequestOption
