// @noflow
import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import SUPPORT_COLOURS from '@/constants/SupportColours'

import STYLES from './SkeletonParagraph.module.sass'

type Props = {
  baseColor?: string
  highlightColor?: string
  width?: string | number
  height?: string | number
  count?: number
  shortLine?: boolean
  margin?: string | number
  align?: 'left' | 'center' | 'right'
}

const SkeletonParagraph = ({
  baseColor = SUPPORT_COLOURS.grey200,
  highlightColor = 'white',
  width = '100%',
  height = '1.6rem',
  count = 2,
  shortLine = true,
  align = 'left',
  margin = '.5rem'
}: Props): JSX.Element => {
  return (
    <div className={`${STYLES.container} ${STYLES[align]}`}>
      <SkeletonTheme baseColor={baseColor} highlightColor={highlightColor}>
        <Skeleton
          count={count}
          width={width}
          height={height}
          style={{
            margin,
            borderRadius: '.5rem'
          }}
        />
        {shortLine && (
          <Skeleton
            width={'50%'}
            height={height}
            style={{
              margin,
              borderRadius: '.5rem'
            }}
          />
        )}
      </SkeletonTheme>
    </div>
  )
}

export { Props }
export default SkeletonParagraph
