// @noflow
import classnames from 'classnames'
import isUndefined from 'lodash/isUndefined'
import React, { useCallback, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

import Icon from '@/components/elements/atoms/Icon/Icon'
import LoadingOverlay from '@/components/elements/atoms/LoadingOverlay/LoadingOverlay'

import STYLES from './Modal.module.sass'

import useCloseOnSwipe from './hooks/useCloseOnSwipe'

type ModalWidth = 350 | 450 | 600 | 800 | 'full'

type Alignment = 'center' | 'left' | 'right'

type Variant = 'survey' | 'default'

type AllowedCloseButtonColours =
  | 'brandBlue200'
  | 'brandBlue600'
  | 'brandYellow300'
  | 'brandBlue100'

type CloseButtonIcon = 'close' | 'surveyClose'

type Props = {
  isModalOpen: boolean
  setOpenModal?: (arg: boolean) => void
  children: JSX.Element | Array<JSX.Element>
  width: ModalWidth
  textAlign?: Alignment
  padding?: boolean
  showCloseButton?: boolean
  bottomSticky?: boolean
  position?: 'top'
  fullHeight?: boolean
  fadeAnimation?: boolean
  variant?: Variant
  flex?: boolean
  onCloseButtonClick?: () => void
  loading?: boolean
  bodyScroll?: boolean
  overflowVisible?: boolean
  closeButtonBackground?: AllowedCloseButtonColours
  backgroundColour?: 'brandWhite' | 'brandYellow100'
  closeButtonIcon?: CloseButtonIcon
  closeButtonIconSize?: number
}

const manageModalBodyClass = (open: boolean) => {
  if (open) document.body.classList.add('modal--open')
  else document.body.classList.remove('modal--open')
}

/**
 * Our Modal atom takes several props that depending on
 * how they are combined support the different variants
 * that we use across the website. The default Modal is
 * a centered modal on Desktop, with a set width of 450,
 * its content is scrollable and on mobile devices
 * it's a full screen modal with scrollable content as well.
 *
 *
 * ==== EXAMPLE USAGE ====
 *
 * ==>   Default variant   <==
 * <Modal
 *   isModalOpen={isOpen}
 *   setOpenModal={toggle}
 * >
 * { children }
 * </Modal>
 *
 *
 * ==>   Full width variant   <==
 * <Modal
 *   isModalOpen={isOpen}
 *   setOpenModal={toggle}
 *   width='full'
 * >
 * { children }
 * </Modal>
 *
 *
 * ==>   Full height variant   <==
 * <Modal
 *   isModalOpen={isOpen}
 *   setOpenModal={toggle}
 *   fullHeight
 * >
 * { children }
 * </Modal>
 *
 *
 * ==>   Full screen variant   <==
 * <Modal
 *   isModalOpen={isOpen}
 *   setOpenModal={toggle}
 *   fullHeight
 *   width='full'
 * >
 * { children }
 * </Modal>
 *
 *
 * ==>   Bottom sticky variant   <==
 * ACROSS ALL DEVICES: This variant is a bottom sticky modal
 * that slides in from the bottom of the screen
 *
 * NOTE: it looks like a banner on desktop and it's not
 * extensively used (used in the DownloadAppModal and the survey cards)
 *
 * <Modal
 *   isModalOpen={isOpen}
 *   setOpenModal={toggle}
 *   bottomSticky
 * >
 * { children }
 * </Modal>
 *
 *
 * ==>   Mobile drawer variant   <==
 * DRAWER ONLY ON MOBILE: This variant is a bottom sticky modal
 * that slides in from the bottom of the screen on mobile devices.
 * On Desktop it's our default centered modal with the specified
 * dimanesions as props.
 *
 *
 * NOTE: note that this toggling the bottomSticky prop
 * from `true` to `false` based on the windowWidth in order
 * to achieve the desired effect.
 *
 *
 * <Modal
 *   isModalOpen={isOpen}
 *   setOpenModal={toggle}
 *   bottomSticky={window.innerWidth < BREAKPOINTS.md}
 * >
 * { children }
 * </Modal>
 *
 */

const Modal = ({
  isModalOpen,
  setOpenModal,
  children,
  width = 450,
  textAlign,
  padding = true,
  showCloseButton = true,
  bottomSticky,
  position,
  fullHeight = false,
  fadeAnimation = false,
  variant = 'default',
  flex = false,
  loading = false,
  onCloseButtonClick,
  bodyScroll = true,
  overflowVisible = false,
  closeButtonBackground = 'brandBlue200',
  backgroundColour = 'brandWhite',
  closeButtonIcon = 'close',
  closeButtonIconSize = 22
}: Props): JSX.Element | null => {
  const [open, setOpen] = useState(false)
  const [closed, setClosed] = useState(false)

  const handleOnClick = useCallback((): void => {
    if (!isUndefined(onCloseButtonClick)) onCloseButtonClick()
    setClosed(true)
    setTimeout((): void => {
      setOpen(false)
      setOpenModal && setOpenModal(false)
      setClosed(false)
    }, 250)
  }, [setOpen, setOpenModal, onCloseButtonClick])

  const { handleSetScrolledToTop, onTouchStart, onTouchMove, onTouchEnd } =
    useCloseOnSwipe({ handleOnClose: handleOnClick })

  const stopPropagation = useCallback((e): void => {
    e.stopPropagation()
  }, [])

  const handleEscapeClick = useCallback(
    (e): void => {
      if (e.key === 'Escape') handleOnClick()
    },
    [handleOnClick]
  )

  // Find our modal contents
  const targetElement = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    setOpen(isModalOpen)

    if (open) {
      // Focus on modal when it initially opens
      if (targetElement && targetElement.current) {
        targetElement.current.focus()
      }
      // Listen for if the user presses their Escape key
      window.addEventListener('keydown', handleEscapeClick)

      // Then remove the event listener
      return () => window.removeEventListener('keydown', handleEscapeClick)
    }
  }, [isModalOpen, open, handleOnClick, handleEscapeClick])

  useEffect(() => {
    if (!bodyScroll) manageModalBodyClass(open)
  }, [open, bodyScroll])

  if (!open) return null

  const className = classnames(
    STYLES.contentWrapper,
    STYLES[`contentWrapper--${width}` as keyof typeof STYLES],
    {
      [STYLES.fadeAnimation]: fadeAnimation,
      [STYLES.closed]: closed,
      [STYLES.bottomSticky]: bottomSticky,
      [STYLES.positionTop]: position === 'top',
      [STYLES.fullHeight]: fullHeight
    }
  )

  const contentClassName = classnames(STYLES.content, {
    [STYLES[`content--text-${textAlign}` as keyof typeof STYLES]]: textAlign,
    [STYLES.nopadding]: !padding,
    [STYLES.fullHeight]: fullHeight,
    [STYLES.flex]: flex,
    [STYLES.overflowVisible]: overflowVisible,
    [STYLES[`${backgroundColour}Content`]]: backgroundColour
  })

  const buttonClassName = classnames(STYLES.closeButton, {
    [STYLES[`${closeButtonBackground}` as keyof typeof STYLES]]:
      closeButtonBackground
  })

  return createPortal(
    <div
      className={`${STYLES.background} ${STYLES[`${variant}`]}`}
      onClick={handleOnClick}
      onKeyDown={handleEscapeClick}
      tabIndex={-1}
      role="button"
    >
      <div
        className={className}
        onClick={stopPropagation}
        onKeyDown={handleEscapeClick}
        tabIndex={0}
        role="button"
        ref={targetElement}
        onScroll={handleSetScrolledToTop}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        <div className={contentClassName}>{children}</div>
        {showCloseButton && (
          <button
            type="button"
            className={buttonClassName}
            onClick={handleOnClick}
          >
            <Icon asset={closeButtonIcon} size={closeButtonIconSize} />
          </button>
        )}
        <LoadingOverlay show={loading} />
      </div>
    </div>,
    document.body
  )
}

export { Props }
export default Modal
