// @flow

import * as DIGITAL_PRODUCT_CONSTANTS from './../../../utils/butternutbox/constants/digital_product'

import type { RailsModelID } from '../../../shared_types/ids'
import type { CSRFToken, Token } from '../../../shared_types/tokens'
import type { Code } from '../../../shared_types/rails_models/shipping_countries'
import type { Dog, ApplicableDiscountPart } from '../message_types'
import type { Language } from '@/packs/localisation'

type State = {|
  firstName: string,
  lastName: string,
  userID: RailsModelID,
  token: Token,
  csrfToken: CSRFToken,
  shippingCountryCode: Code,
  preferredLanguage: Language,
  dogs: Array<Dog>,
  referralUrl: string,
  referrerDiscount: {|
    applicableDiscountParts: Array<ApplicableDiscountPart>
  |},
  referralDiscount: {|
    discountCodeParts: Array<ApplicableDiscountPart>
  |},
  deliveriesReceived: number,
  postSignupReferrerDiscount: string,
  postSignupReferralDiscount: string,
  whatsappSingleDog: string,
  whatsappMultipleDogs: string
|}

const initialState: State = {
  firstName: '',
  lastName: '',
  token: '',
  userID: 0,
  csrfToken: '',
  shippingCountryCode: 'GB',
  preferredLanguage: 'en',
  dogs: [],
  referralUrl: '',
  referrerDiscount: {
    applicableDiscountParts: []
  },
  referralDiscount: {
    discountCodeParts: []
  },
  deliveriesReceived: 0,
  postSignupReferrerDiscount: '',
  postSignupReferralDiscount: '',
  whatsappSingleDog: '',
  whatsappMultipleDogs: ''
}

const selectFullName = ({ firstName, lastName }: State): string => {
  return `${firstName} ${lastName}`
}

const selectAreAllDogsPuppies = ({ dogs }: State): boolean => (
  dogs.every((dog: Dog): boolean =>
    dog.ageInMonths <= DIGITAL_PRODUCT_CONSTANTS.MAX_PUPPY_AGE_IN_MONTHS
  )
)

const selectIsAnyDogAPuppy = ({ dogs }: State): boolean => {
  return dogs.some((dog: Dog): boolean =>
    dog.ageInMonths <= DIGITAL_PRODUCT_CONSTANTS.MAX_PUPPY_AGE_IN_MONTHS
  )
}

const reducer = (state: State = initialState): State => state

export type { State }

export {
  initialState,
  selectFullName,
  selectAreAllDogsPuppies,
  selectIsAnyDogAPuppy
}

export default reducer
