// @noflow
import type { Language } from '@/packs/localisation'
import initI18n from '@/packs/localisation'
import { makeVar, useQuery, useReactiveVar } from '@apollo/client'
import i18next from 'i18next'
import Cookies from 'js-cookie'
import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { toast } from 'react-toastify'

import * as Sentry from '@/utils/sentry'

import ErrorIcon from 'assets/images/icons/error--red-circle-bg.svg'

import withApollo from '@/components/apollo/withApollo'
import NotificationContent from '@/components/elements/molecules/NotificationContent/NotificationContent'
import { HEALTH_ISSUES_QUERY } from '@/components/pages/SignupWizardPage/screens/Health/queries/healthIssuesQuery'
import { loadingState } from '@/components/templates/WizardNavigationLayout/WizardNavigationLayout'

import { FOOD_CATEGORIES_QUERY } from './queries/foodCategoriesQuery'
import { SHIPPING_COUNTRIES } from './queries/shippingCountriesQuery'
import { CURRENT_USER } from './queries/userQuery'

import type { Allergens as AllergensQuery } from './queries/__generated__/Allergens'
import type { CurrentUser as UserQuery } from './queries/__generated__/CurrentUser'
import type { ShippingCountries as ShippingCountriesQuery } from './queries/__generated__/ShippingCountries'
// Enums
import { Code as CountryCode, MarketingPreferences } from '@/types'

import SignupWizard from './SignupWizard'
import { ALLERGENS_QUERY } from './screens/Allergies/Allergies'
import {
  Dog,
  User,
  WizardActivityLevel,
  WizardAgeStage,
  WizardBodyCondition,
  WizardSnackingHabits
} from './types/types'

type Props = {
  preferredLanguage: Language
  csrfToken: string
  shouldSeePetParentOnPlans: boolean
}

type InitialState = {
  user: User
  dogs: Array<Dog>
}

const wizardStateVersion = '0.1.0'
const countryCode = Cookies.get('user_country_code') as CountryCode | undefined

const roughCostCalculatorLocalStorage = localStorage.getItem(
  'rough_cost_calculator'
)

const parsedRoughCostState = roughCostCalculatorLocalStorage
  ? JSON.parse(roughCostCalculatorLocalStorage)
  : null

// On the Rough Cost Calculator, 0 represents '0-11 months' and 12 represents
// '12+ years' in the dropdown options which means we're not able to determine
// the specific dog age from those, so we don't want to persist those values
const roughCostCalculatorAgeIsSpecificYears =
  parsedRoughCostState?.age > 0 && parsedRoughCostState?.age < 12

// We also want to make sure the localStorage item exists and that the user has
// specifically interacted with the ageInput on the RoughCostCalculator, as we
// don't want to use the defaulted value of 2 years old from the placeholder
const shouldUseRoughCostCalculatorAge =
  parsedRoughCostState?.interactedWithAge &&
  roughCostCalculatorAgeIsSpecificYears

const ageLocalStorage = shouldUseRoughCostCalculatorAge
  ? parsedRoughCostState.age
  : null

const dogAgeLocalStorage = {
  weeks: null,
  months: null,
  years: ageLocalStorage
}

const breedSelectorBreedId = Cookies.get('breedSelectorBreedId')

const initialState: InitialState = {
  user: {
    location: {
      countryCode: countryCode || CountryCode.GB,
      postcode: ''
    },
    name: '',
    email: '',
    marketingPreference: MarketingPreferences.no_consent,
    supportedMarketingMethodPurposeIds: [],
    goalSegment: null,
    trackingId: null
  },
  dogs: [
    {
      name: '',
      gender: null,
      neutered: null,
      ageStage: ageLocalStorage ? WizardAgeStage.Adult : null,
      age: dogAgeLocalStorage,
      isRescue: null,
      broughtHome: null,
      breed: breedSelectorBreedId ? JSON.parse(breedSelectorBreedId).key : null,
      foodCategoryIds: [],
      eaterType: null,
      weight: null,
      bodyCondition: WizardBodyCondition.JustRight,
      isWorkingDog: null,
      activityLevel: WizardActivityLevel.FairlyActive,
      allergies: [],
      healthIssues: [],
      snackingHabits: WizardSnackingHabits.SomeSnacks
    }
  ]
}

// Retrieve any saved State and State version from LocalStorage
const storageState = localStorage.getItem('wizardState')
const storageStateVersion = localStorage.getItem('wizardStateVersion')
const isSupportedWizardStateVersion = storageStateVersion === wizardStateVersion

// If the wizardState item exists in local storage, parse it as JSON.
// Otherwise, return the default initalState object
const shouldParseLocalStorage = storageState && isSupportedWizardStateVersion
const parsedState = shouldParseLocalStorage
  ? JSON.parse(storageState)
  : initialState

// If a user has selected a Breed from the BreedSelector component, retrieve
// the cookie and update the `breed` object of the first dog in the state with it's value.
const parsedStateWithPreSelectedBreed = {
  ...parsedState,
  dogs: parsedState.dogs.map((dog: Dog, index: number) => {
    return {
      ...dog,
      breed:
        breedSelectorBreedId && index === 0
          ? JSON.parse(breedSelectorBreedId)
          : dog.breed
    }
  })
}

const wizardPageState = makeVar<InitialState>(parsedStateWithPreSelectedBreed)
const errorState = makeVar({ showError: false, errorMessage: '' })
const locationState = makeVar<ShippingCountriesQuery | null>(null)
const csrfTokenState = makeVar<string | null>(null)

const namespace = 'wizard_flow'

const showErrorNotification = (errorMessage: string) => {
  toast.error(
    <NotificationContent
      copy={{
        text: errorMessage,
        namespace: namespace
      }}
    />,
    {
      icon: <img src={ErrorIcon} alt="" />,
      toastId: 'error-notification'
    }
  )
}

const SignupWizardPage = ({
  csrfToken,
  preferredLanguage,
  shouldSeePetParentOnPlans
}: Props): JSX.Element => {
  if (!i18next.isInitialized) {
    initI18n(preferredLanguage)
  }

  const wizardState = useReactiveVar(wizardPageState)
  const showErrorState = useReactiveVar(errorState)

  // PRELOAD SOME DATA
  useQuery(HEALTH_ISSUES_QUERY)
  useQuery(FOOD_CATEGORIES_QUERY)

  // query for first page
  const {
    loading: allergensLoading,
    data: allergensData,
    error: allergensError
  } = useQuery<AllergensQuery>(ALLERGENS_QUERY)

  const {
    loading: locationLoading,
    data: locationData,
    error: locationError
  } = useQuery<ShippingCountriesQuery>(SHIPPING_COUNTRIES, {
    onCompleted({ settings, availableMarketingMethodPurposes }) {
      const marketingOptInDefault = settings.find(
        (setting) => setting.name === 'marketing_opt_in_default'
      )?.value
      wizardPageState({
        ...wizardState,
        user: {
          ...wizardState.user,
          marketingPreference:
            marketingOptInDefault === false
              ? MarketingPreferences.no_consent
              : MarketingPreferences.full_consent,
          supportedMarketingMethodPurposeIds:
            marketingOptInDefault === false
              ? []
              : availableMarketingMethodPurposes.map(({ id }) => id)
        }
      })
    }
  })

  const { loading: userLoading, error: userError } =
    useQuery<UserQuery>(CURRENT_USER)

  // use this to rectify breaking changes to state ( migration )
  React.useEffect(() => {
    if (!isSupportedWizardStateVersion) {
      localStorage.setItem('wizardStateVersion', wizardStateVersion)
    }
    if (!isSupportedWizardStateVersion && storageState) {
      // changes to move from previous version
      const dogs = wizardState.dogs.map((dog: Dog) => ({
        ...dog,
        foodCategoryIds: [],
        currentlyEating: []
      }))

      wizardPageState({
        ...wizardState,
        dogs
      })
      window.location.href = '/wizard/new'
    }
    // We need to disable exhaustive deps here as we only want to run this once
    // otherwise this will cause multiple re-renders and crash the wizard
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    loadingState(locationLoading && allergensLoading && userLoading)
    if (showErrorState.showError) {
      showErrorNotification(showErrorState.errorMessage)
    }
    if (locationError) {
      showErrorNotification('error_message.generic')
      Sentry.captureException(
        `Error encountered from SHIPPING_COUNTRIES query`,
        {
          extra: {
            locationError
          },
          tags: {
            product: Sentry.Product.Wizard
          }
        }
      )
    }
    if (allergensError) {
      showErrorNotification('error_message.generic')
      Sentry.captureException(`Error encountered from ALLERGENS_QUERY query`, {
        extra: {
          allergensError
        },
        tags: {
          product: Sentry.Product.Wizard
        }
      })
    }
    if (userError) {
      showErrorNotification('error_message.generic')
      Sentry.captureException(`Error encountered from CURRENT_USER query`, {
        extra: {
          userError
        },
        tags: {
          product: Sentry.Product.Wizard
        }
      })
    }
    if (!locationData || !allergensData) {
      return
    }
    if (locationData) {
      locationState(locationData)
    }
  }, [
    locationLoading,
    locationData,
    locationError,
    showErrorState,
    allergensLoading,
    allergensError,
    allergensData,
    userLoading,
    userError
  ])

  // Set the CSRF Apollo reactive state with the value from the back end
  React.useEffect(() => {
    csrfTokenState(csrfToken)
  }, [csrfToken])

  React.useEffect(() => {
    localStorage.setItem('wizardState', JSON.stringify(wizardState))
  }, [wizardState])

  return (
    <Router>
      <SignupWizard
        preferredLanguage={preferredLanguage}
        shippingCountryCode={wizardState.user.location.countryCode}
        isSupportedWizardStateVersion={isSupportedWizardStateVersion}
        shouldSeePetParentOnPlans={shouldSeePetParentOnPlans}
      />
    </Router>
  )
}

export {
  SignupWizardPage,
  wizardPageState,
  locationState,
  errorState,
  csrfTokenState,
  Props,
  InitialState
}

export default withApollo(SignupWizardPage)
