// @noflow
import { useLocalisation } from '@/context/localisation/localisation'
import { ACCOUNT_ROUTES } from '@/routes'
import { useQuery, useReactiveVar } from '@apollo/client'
import i18next from 'i18next'
import capitalize from 'lodash/capitalize'
import isNil from 'lodash/isNil'
import isUndefined from 'lodash/isUndefined'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { dogsDataVar } from '@/services/apollo'

import { toLocalisedSentence } from '@/utils/StringHelper'

import {
  UsePlanQuery_user_subscription_planOptions,
  UsePlanQuery_user_subscription_planOptions_gramsPerDayPerDogs
} from '@/hooks/usePlan/__generated__/UsePlanQuery'
import usePlan from '@/hooks/usePlan/usePlan'

import AlertCard from '@/components/elements/atoms/Alert/AlertCard'
import { Button } from '@/components/elements/atoms/Button'
import { CardSkeleton } from '@/components/elements/atoms/Card/CardSkeleton'
import { FixedBase } from '@/components/elements/atoms/FixedBase'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import Separator from '@/components/elements/atoms/Separator/Separator'
import Text from '@/components/elements/atoms/Text/Text'
import TalkingHead from '@/components/elements/molecules/TalkingHead/TalkingHead'
import {
  PlanConfirmationQuery,
  PlanConfirmationQuery_user_subscription_nextNBoxes
} from '@/components/pages/ConfirmPlanPage/__generated__/PlanConfirmationQuery'
import { PLAN_CONFIRMATION_QUERY } from '@/components/pages/ConfirmPlanPage/queries'

import STYLES from './ConfirmPlanPage.module.sass'

import { SubscriptionStatus } from '@/types'

const namespace = 'account'

const isNextBoxPastCutoffForChange = (
  nextBox: PlanConfirmationQuery_user_subscription_nextNBoxes
) => nextBox.editable

type NoChangeProps = {
  gramsPerDay: number
  pronoun: string
}

/**
 * Content to be shown when the users plan has not changed
 * @param gramsPerDay
 * @constructor
 */
const NoChange = ({ gramsPerDay, pronoun }: NoChangeProps) => {
  const navigate = useNavigate()

  const goToMyDogPage = useCallback(() => {
    navigate(ACCOUNT_ROUTES.profile)
  }, [navigate])

  return (
    <>
      <div className={STYLES.title}>
        <Text
          text="plan_confirmation.no_change.title"
          variant="display24"
          namespace={namespace}
          margin={false}
          element="h2"
        />
      </div>
      <div className={STYLES.separator}>
        <Separator handdrawn />
      </div>
      <div className={STYLES.info}>
        <Text
          text="plan_confirmation.no_change.overview"
          variables={{
            grams: gramsPerDay,
            context: pronoun
          }}
          variant="textRegular18"
          namespace={namespace}
          element="div"
        />
        <button type="button" onClick={goToMyDogPage} className={STYLES.link}>
          <AlertCard
            message={{
              text: 'plan_confirmation.no_change.changes',
              namespace,
              align: 'left'
            }}
          />
        </button>
      </div>
    </>
  )
}

type ChangeProps = {
  pronoun: string
  gramsPerDay: number
  isoDeliveryDate: string
  previousPlan?: UsePlanQuery_user_subscription_planOptions
  nextBox: PlanConfirmationQuery_user_subscription_nextNBoxes | undefined
  status: SubscriptionStatus
}

/**
 * Content to be shown when the users plan has changed
 * @param gramsPerDay
 * @param isoDeliveryDate
 * @param previousPlan
 * @param nextBox
 * @param status
 * @constructor
 */
const Change = ({
  gramsPerDay,
  pronoun,
  isoDeliveryDate,
  previousPlan,
  nextBox,
  status
}: ChangeProps) => {
  const navigate = useNavigate()
  const { format } = useLocalisation()

  const goToDashboard = useCallback(() => {
    navigate(ACCOUNT_ROUTES.base)
  }, [navigate])

  if (isUndefined(previousPlan) || isUndefined(format)) return null

  return (
    <>
      <div className={STYLES.title}>
        <Text
          text="plan_confirmation.new.title"
          variant="display24"
          namespace={namespace}
          margin={false}
          element="h2"
          variables={{
            grams: gramsPerDay
          }}
        />
      </div>
      <div className={STYLES.separator}>
        <Separator handdrawn />
      </div>
      {status === SubscriptionStatus.active ? (
        <div className={STYLES.delivery}>
          <Text
            text="plan_confirmation.new.begins"
            variant="textRegular18"
            namespace={namespace}
            element="div"
          />
          {
            <Text
              text={format(isoDeliveryDate, 'iiii dd MMMM')}
              variant="display24"
              element="div"
              translate={false}
            />
          }
        </div>
      ) : (
        <div className={STYLES.paused}>
          <Text
            text="plan_confirmation.paused.overview"
            variant="textRegular18"
            namespace={namespace}
            align="left"
            margin={false}
            variables={{
              context: pronoun
            }}
          />
          <Button
            onClick={goToDashboard}
            typography={{
              text: 'plan_confirmation.paused.button',
              namespace
            }}
            identifier="plan_confirmation.go_to_dashboard"
          />
        </div>
      )}
      {!isUndefined(nextBox) && !isNextBoxPastCutoffForChange(nextBox) && (
        <div className={STYLES.warning}>
          <AlertCard
            message={{
              text: 'plan_confirmation.new.past_cutoff',
              align: 'left',
              variables: {
                date: format(nextBox.isoDeliveryDate, 'iiii dd MMMM'),
                grams: previousPlan.pouchSize.toString()
              },
              namespace
            }}
          />
        </div>
      )}
    </>
  )
}

/**
 * Generates a sentence of each dog and their daily grams
 * @example "Dog_one 160g and Dog_two 340g"
 * @param dogs
 * @param t
 */
const gramSplitSentence = (
  dogs: UsePlanQuery_user_subscription_planOptions_gramsPerDayPerDogs[],
  t: TFunction<'translation'>
) =>
  dogs.map(({ name, gramsPerDay }): string =>
    t(`plan_confirmation.gram_split_sentence`, {
      dogName: capitalize(name),
      grams: gramsPerDay
    })
  )

/**
 * Page shown to users if their plans either changed or not changed
 * If the plan has changed shows the new values and start date
 * If the plan has not changed reassures the user that their plan will remain the sames
 * @constructor
 */
const ConfirmPlanPage = (): JSX.Element | null => {
  const container = useRef<HTMLDivElement>(null)
  const { t } = useTranslation(namespace)
  const navigate = useNavigate()
  const { dogs, has_puppy, pronoun } = useReactiveVar(dogsDataVar) || {}
  const { previousPlanId } = useParams()
  const [previousPlan, setPreviousPlan] = useState<
    UsePlanQuery_user_subscription_planOptions | undefined
  >()

  const { data } = useQuery<PlanConfirmationQuery>(PLAN_CONFIRMATION_QUERY, {
    variables: {
      numOfBoxes: 1
    }
  })
  const { user } = data || {}
  const { subscription } = user || {}
  const { nextNBoxes, nextEditableBox, status } = subscription || {}

  const { getPlan, currentPlan } = usePlan()

  const goToPlan = useCallback(() => {
    navigate(ACCOUNT_ROUTES.base)
  }, [navigate])

  /**
   * Get the new plan from the planId URL parameter. If none is provided default to the ideal plan
   */
  useEffect(() => {
    if (
      isUndefined(previousPlan) &&
      !isUndefined(getPlan) &&
      !isUndefined(previousPlanId)
    ) {
      setPreviousPlan(getPlan(previousPlanId))
    }
  }, [getPlan, previousPlan, previousPlanId])

  /**
   * Show skeleton while fetching data
   */
  if (
    isNil(dogs) ||
    isUndefined(currentPlan) ||
    isUndefined(pronoun) ||
    isNil(nextEditableBox) ||
    isUndefined(status) ||
    isUndefined(nextNBoxes) ||
    (!isUndefined(previousPlanId) && isUndefined(previousPlan))
  )
    return (
      <div className={STYLES.container}>
        <CardSkeleton height={600} />
      </div>
    )

  const nextBox = nextNBoxes[0]
  const gramsPerDay = currentPlan.gramsPerDay
  const deliveryDate = nextEditableBox.isoDeliveryDate

  return (
    <div className={STYLES.container} ref={container}>
      <SectionWrapper
        padding={32}
        bgColour="brandWhite90"
        borderRadius={16}
        margin={{ top: 0 }}
      >
        {!isUndefined(previousPlanId) && previousPlanId !== currentPlan?.id ? (
          <Change
            pronoun={pronoun}
            gramsPerDay={gramsPerDay}
            isoDeliveryDate={deliveryDate}
            previousPlan={previousPlan}
            nextBox={nextBox}
            status={status}
          />
        ) : (
          <NoChange gramsPerDay={gramsPerDay} pronoun={pronoun} />
        )}
        <div className={STYLES.separator}>
          <Separator handdrawn />
        </div>
        <div className={STYLES.title}>
          <Text
            text="plan_confirmation.expert.title"
            namespace={namespace}
            variant="display24"
            element="h3"
          />
        </div>
        <TalkingHead
          expert="sarah"
          text={
            has_puppy
              ? {
                  text: 'plan_confirmation.expert.advice_puppy',
                  variables: {
                    context: pronoun
                  },
                  namespace
                }
              : {
                  text: 'plan_confirmation.expert.advice',
                  namespace,
                  variables: {
                    context: pronoun,
                    gramsPerDog: toLocalisedSentence({
                      arr: gramSplitSentence(currentPlan.gramsPerDayPerDogs, t),
                      lng: i18next.language
                    })
                  }
                }
          }
        />
        <FixedBase backgroundColor="brandWhite90" container={container}>
          <div className={STYLES.buttons}>
            <Button
              onClick={goToPlan}
              typography={{
                text: 'plan_confirmation.button',
                variables: {
                  context: pronoun
                },
                namespace
              }}
              identifier="plan_confirmation.go_to_plan"
            />
          </div>
        </FixedBase>
      </SectionWrapper>
    </div>
  )
}

export default ConfirmPlanPage
