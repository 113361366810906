import { gql } from '@apollo/client'

const ADD_PAYMENT_METHOD_QUERY = gql`
  query AddPaymentMethodQuery {
    user {
      id
      firstName
      lastName
      email
      token
      preferredLanguage
      shippingCountryCode
      affiliateType
    }
  }
`
export default ADD_PAYMENT_METHOD_QUERY
