// @noflow
import { makeVar, useQuery, useReactiveVar } from '@apollo/client'
import React, { useCallback, useRef, useState } from 'react'

import * as Sentry from '@/utils/sentry'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import CloudinaryImage from '@/components/elements/atoms/CloudinaryImage/CloudinaryImage'
import Modal from '@/components/elements/atoms/Modal/Modal'
import Text from '@/components/elements/atoms/Text/Text'
import ProductBenefit from '@/components/elements/molecules/ProductBenefit/ProductBenefit'

import STYLES from './AdditionalProductModal.module.sass'

import { ADDITIONAL_PRODUCT_QUERY } from './queries/additionalProductQuery'

import type {
  AdditionalProductQuery,
  AdditionalProductQueryVariables
} from './queries/__generated__/AdditionalProductQuery'

import ModalSkeletonTemplate from '../../atoms/ModalSkeletonTermplate/ModalSkeletonTemplate'
import Separator from '../../atoms/Separator/Separator'
import SwiperSlider from '../../molecules/SwiperSlider/SwiperSlider'

type ModalData = {
  isOpen: boolean
  slug: string
}

const modalData = makeVar({
  isOpen: false
} as ModalData)

const AdditionalProductInformationModal = (): JSX.Element | null => {
  const modalState = useReactiveVar(modalData)
  const { data, loading } = useQuery<
    AdditionalProductQuery,
    AdditionalProductQueryVariables
  >(ADDITIONAL_PRODUCT_QUERY, {
    variables: { slug: modalState.slug },
    skip: !modalState.slug,
    onError: (error) => {
      Sentry.captureException(
        `GraphQL error occurred when executing ADDITIONAL_PRODUCT_QUERY`,
        {
          extra: {
            error,
            slug: modalState.slug
          }
        }
      )
    }
  })

  const { windowWidth } = useWindowSize()

  const [isSeeDetailsOpen, setIsSeeDetailsOpen] = useState<boolean>(false)
  const [detailsHeight, setDetailsHeight] = useState<number>(0)
  const content = useRef<HTMLDivElement>(null)

  const triggerToggle = useCallback((): void => {
    setIsSeeDetailsOpen(!isSeeDetailsOpen)

    if (!isSeeDetailsOpen && content.current) {
      setDetailsHeight(content.current.scrollHeight)
    } else {
      setDetailsHeight(0)
    }
  }, [isSeeDetailsOpen, content])

  const toggleModal = useCallback((): void => {
    setTimeout((): void => {
      modalData({ ...modalState, isOpen: !modalState.isOpen })
    }, 250)
  }, [modalState])

  if (loading || !data) {
    return (
      <Modal
        isModalOpen={modalState.isOpen}
        setOpenModal={toggleModal}
        width={600}
      >
        <ModalSkeletonTemplate />
      </Modal>
    )
  }

  const {
    productCollection: { name, about, description, images, productBenefits }
  } = data

  return (
    <Modal
      isModalOpen={modalState.isOpen}
      setOpenModal={toggleModal}
      onCloseButtonClick={toggleModal}
      width={600}
      padding={false}
      fullHeight={windowWidth < BREAKPOINTS.md}
    >
      <SwiperSlider
        variant="brandBlue100"
        slidesPerView={1}
        arrows
        arrowsInside
        bullets
      >
        {images.map(
          (image): JSX.Element => (
            <div key={image.src} className={STYLES.carouselImage}>
              <CloudinaryImage
                alt={name}
                image={{
                  path: image.src,
                  width: 600,
                  height: 400,
                  crop: 'fill'
                }}
              />
            </div>
          )
        )}
      </SwiperSlider>
      <div className={STYLES.information}>
        <Text
          text={name}
          variant="display24"
          colour="brandBlue500"
          translate={false}
        />
        <div
          className={STYLES.description}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: description
          }}
        />
        <Text
          namespace="shared"
          text={'additional_product_modal.key_benefits'}
          variant="display20"
          colour="brandBlue500"
        />
        <div className={STYLES.benefitList}>
          {productBenefits
            .filter((benefit) => benefit.image !== null)
            .map((benefit) => (
              <ProductBenefit
                key={benefit.name}
                benefit={{
                  name: benefit.name,
                  url: benefit.image?.src || null
                }}
              />
            ))}
        </div>
        <button
          type="button"
          className={STYLES.seeDetailsButton}
          onClick={triggerToggle}
        >
          <Text
            text="additional_product_modal.see_details"
            variant="display20"
            colour="brandBlue500"
            namespace="shared"
          />
          <span
            className={`${STYLES.seeDetailsIcon} ${
              isSeeDetailsOpen ? STYLES.seeDetailsIconOpen : ''
            }`}
          />
        </button>
        <div
          ref={content}
          className={`${STYLES.details} ${
            isSeeDetailsOpen ? STYLES.detailsOpen : ''
          }`}
          style={{ maxHeight: `${detailsHeight}px` }}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: about
          }}
        />
        <Separator />
      </div>
    </Modal>
  )
}

export { ADDITIONAL_PRODUCT_QUERY, modalData }

export default AdditionalProductInformationModal
