// @noflow
import { ApolloQueryResult, makeVar, useQuery } from '@apollo/client'
import { isAfter, isBefore, isEqual, parseISO } from 'date-fns'
import { isNull } from 'lodash'

import * as Sentry from '@/utils/sentry'

import { CHECKOUT_PENDING_SUBSCRIPTION_ORDER_PRICING } from '../queries/checkoutPendingSubscriptionPricingQuery'

import {
  CheckoutPendingSubscriptionOrderPricingQuery,
  CheckoutPendingSubscriptionOrderPricingQueryVariables
} from '../queries/__generated__/CheckoutPendingSubscriptionOrderPricingQuery'

type Props = {
  planId: number
  endOfLeadTime?: string
  skip?: boolean
  handlePricingUpdate?: () => void
}

type OrderPrice = {
  grossTotalPrice: number
  netTotalPrice: number
  netTotalPricePerDay: number
  totalDiscountedAmount: number
  standardOrderCoreFoodGrossPrice: number
  grossCoreFoodPrice: number
  grossCoreFoodPricePerDay: number
  netCoreFoodPrice: number
  coreFoodDiscountedAmount: number
  grossRecipeSurchargePrice: number
  netRecipeSurchargePrice: number
  recipeSurchargeDiscountedAmount: number
  grossDeliverySurchargePrice: number
  netDeliverySurchargePrice: number
  deliverySurchargeDiscountedAmount: number
  grossAdditionalProductsPrice: number
  netAdditionalProductsPrice: number
  additionalProductsDiscountedAmount: number
  totalSavedAmount: number
  flavourPricings: Array<{
    flavourName: string
    servings: number
    netStandardOrderPricePerServing: number
    grossStandardOrderPricePerServing: number
    netFirstOrderPricePerServing: number
  }>
}

type PriceIncrease = {
  showPriceIncreaseAlert: boolean
  effectiveFrom: null | Date
}

type PendingSubscriptionOrderFlavours = Array<{
  flavour: {
    standardPricePerDay: number
  }
}>

type InitialState = {
  standardOrderPricing: OrderPrice
  firstOrderPricing: OrderPrice
  secondOrderPricing: OrderPrice
  pendingSubscriptionFlavours: PendingSubscriptionOrderFlavours
  productVariants: CheckoutPendingSubscriptionOrderPricingQuery['guest']['pendingSubscription']['productVariants']
  priceIncrease: PriceIncrease
}

type CheckoutPricing = {
  refetchCheckoutPricing: (
    variables?:
      | Partial<CheckoutPendingSubscriptionOrderPricingQueryVariables>
      | undefined
  ) => Promise<ApolloQueryResult<CheckoutPendingSubscriptionOrderPricingQuery>>
  checkoutPricingLoading: boolean
}

const initialOrderPricing: OrderPrice = {
  grossTotalPrice: 0,
  netTotalPrice: 0,
  netTotalPricePerDay: 0,
  totalDiscountedAmount: 0,
  standardOrderCoreFoodGrossPrice: 0,
  grossCoreFoodPrice: 0,
  grossCoreFoodPricePerDay: 0,
  netCoreFoodPrice: 0,
  coreFoodDiscountedAmount: 0,
  grossRecipeSurchargePrice: 0,
  netRecipeSurchargePrice: 0,
  recipeSurchargeDiscountedAmount: 0,
  grossDeliverySurchargePrice: 0,
  netDeliverySurchargePrice: 0,
  deliverySurchargeDiscountedAmount: 0,
  grossAdditionalProductsPrice: 0,
  netAdditionalProductsPrice: 0,
  additionalProductsDiscountedAmount: 0,
  totalSavedAmount: 0,
  flavourPricings: []
}

const initialState: InitialState = {
  standardOrderPricing: initialOrderPricing,
  firstOrderPricing: initialOrderPricing,
  secondOrderPricing: initialOrderPricing,
  pendingSubscriptionFlavours: [],
  productVariants: [],
  priceIncrease: {
    showPriceIncreaseAlert: false,
    effectiveFrom: null
  }
}

const checkoutPricingState = makeVar<InitialState>(initialState)

const useCheckoutPricing = ({
  endOfLeadTime,
  planId,
  skip = false,
  handlePricingUpdate
}: Props): CheckoutPricing => {
  const { refetch: refetchCheckoutPricing, loading: checkoutPricingLoading } =
    useQuery<
      CheckoutPendingSubscriptionOrderPricingQuery,
      CheckoutPendingSubscriptionOrderPricingQueryVariables
    >(CHECKOUT_PENDING_SUBSCRIPTION_ORDER_PRICING, {
      variables: {
        expectedFirstShippingDate: endOfLeadTime,
        planId: String(planId)
      },
      skip: skip,
      onCompleted: ({ guest, shouldSeeUpcomingPriceRiseInfo }) => {
        const { pendingSubscription } = guest
        const {
          standardOrderPricing,
          firstOrderPricing,
          secondOrderPricing,
          pendingSubscriptionFlavours,
          plan,
          productVariants
        } = pendingSubscription

        const showPriceIncreaseAlert = () => {
          if (isNull(plan.nextUpcomingPrice)) return false
          if (!endOfLeadTime) return false
          if (shouldSeeUpcomingPriceRiseInfo === 'false') return false

          const effectiveFrom = parseISO(plan.nextUpcomingPrice.effectiveFrom)
          const endOfLeadTimeDate = parseISO(endOfLeadTime)
          const today = new Date()

          return (
            (isAfter(effectiveFrom, today) &&
              isEqual(effectiveFrom, endOfLeadTimeDate)) ||
            isBefore(effectiveFrom, endOfLeadTimeDate)
          )
        }

        checkoutPricingState({
          standardOrderPricing: {
            grossTotalPrice: standardOrderPricing.grossTotalPrice,
            netTotalPrice: standardOrderPricing.netTotalPrice,
            netTotalPricePerDay: standardOrderPricing.netTotalPricePerDay,
            totalDiscountedAmount: standardOrderPricing.totalDiscountedAmount,
            standardOrderCoreFoodGrossPrice:
              standardOrderPricing.standardOrderCoreFoodGrossPrice,
            grossCoreFoodPrice: standardOrderPricing.grossCoreFoodPrice,
            grossCoreFoodPricePerDay:
              standardOrderPricing.grossCoreFoodPricePerDay,
            netCoreFoodPrice: standardOrderPricing.netCoreFoodPrice,
            coreFoodDiscountedAmount:
              standardOrderPricing.coreFoodDiscountedAmount,
            grossRecipeSurchargePrice:
              standardOrderPricing.grossRecipeSurchargePrice,
            netRecipeSurchargePrice:
              standardOrderPricing.netRecipeSurchargePrice,
            recipeSurchargeDiscountedAmount:
              standardOrderPricing.recipeSurchargeDiscountedAmount,
            grossDeliverySurchargePrice:
              standardOrderPricing.grossDeliverySurchargePrice,
            netDeliverySurchargePrice:
              standardOrderPricing.netDeliverySurchargePrice,
            deliverySurchargeDiscountedAmount:
              standardOrderPricing.deliverySurchargeDiscountedAmount,
            grossAdditionalProductsPrice:
              standardOrderPricing.grossAdditionalProductsPrice,
            netAdditionalProductsPrice:
              standardOrderPricing.netAdditionalProductsPrice,
            additionalProductsDiscountedAmount:
              standardOrderPricing.additionalProductsDiscountedAmount,
            totalSavedAmount: standardOrderPricing.totalSavedAmount,
            flavourPricings: standardOrderPricing.flavourPricings
          },
          firstOrderPricing: {
            grossTotalPrice: firstOrderPricing.grossTotalPrice,
            netTotalPrice: firstOrderPricing.netTotalPrice,
            netTotalPricePerDay: firstOrderPricing.netTotalPricePerDay,
            totalDiscountedAmount: firstOrderPricing.totalDiscountedAmount,
            standardOrderCoreFoodGrossPrice:
              firstOrderPricing.standardOrderCoreFoodGrossPrice,
            grossCoreFoodPrice: firstOrderPricing.grossCoreFoodPrice,
            grossCoreFoodPricePerDay:
              firstOrderPricing.grossCoreFoodPricePerDay,
            netCoreFoodPrice: firstOrderPricing.netCoreFoodPrice,
            coreFoodDiscountedAmount:
              firstOrderPricing.coreFoodDiscountedAmount,
            grossRecipeSurchargePrice:
              firstOrderPricing.grossRecipeSurchargePrice,
            netRecipeSurchargePrice: firstOrderPricing.netRecipeSurchargePrice,
            recipeSurchargeDiscountedAmount:
              firstOrderPricing.recipeSurchargeDiscountedAmount,
            grossDeliverySurchargePrice:
              firstOrderPricing.grossDeliverySurchargePrice,
            netDeliverySurchargePrice:
              firstOrderPricing.netDeliverySurchargePrice,
            deliverySurchargeDiscountedAmount:
              firstOrderPricing.deliverySurchargeDiscountedAmount,
            grossAdditionalProductsPrice:
              firstOrderPricing.grossAdditionalProductsPrice,
            netAdditionalProductsPrice:
              firstOrderPricing.netAdditionalProductsPrice,
            additionalProductsDiscountedAmount:
              firstOrderPricing.additionalProductsDiscountedAmount,
            totalSavedAmount: firstOrderPricing.totalSavedAmount,
            flavourPricings: firstOrderPricing.flavourPricings
          },
          secondOrderPricing: {
            grossTotalPrice: secondOrderPricing.grossTotalPrice,
            netTotalPrice: secondOrderPricing.netTotalPrice,
            netTotalPricePerDay: secondOrderPricing.netTotalPricePerDay,
            totalDiscountedAmount: secondOrderPricing.totalDiscountedAmount,
            standardOrderCoreFoodGrossPrice:
              secondOrderPricing.standardOrderCoreFoodGrossPrice,
            grossCoreFoodPrice: secondOrderPricing.grossCoreFoodPrice,
            grossCoreFoodPricePerDay:
              secondOrderPricing.grossCoreFoodPricePerDay,
            netCoreFoodPrice: secondOrderPricing.netCoreFoodPrice,
            coreFoodDiscountedAmount:
              secondOrderPricing.coreFoodDiscountedAmount,
            grossRecipeSurchargePrice:
              secondOrderPricing.grossRecipeSurchargePrice,
            netRecipeSurchargePrice: secondOrderPricing.netRecipeSurchargePrice,
            recipeSurchargeDiscountedAmount:
              secondOrderPricing.recipeSurchargeDiscountedAmount,
            grossDeliverySurchargePrice:
              secondOrderPricing.grossDeliverySurchargePrice,
            netDeliverySurchargePrice:
              secondOrderPricing.netDeliverySurchargePrice,
            deliverySurchargeDiscountedAmount:
              secondOrderPricing.deliverySurchargeDiscountedAmount,
            grossAdditionalProductsPrice:
              secondOrderPricing.grossAdditionalProductsPrice,
            netAdditionalProductsPrice:
              secondOrderPricing.netAdditionalProductsPrice,
            additionalProductsDiscountedAmount:
              secondOrderPricing.additionalProductsDiscountedAmount,
            totalSavedAmount: secondOrderPricing.totalSavedAmount,
            flavourPricings: secondOrderPricing.flavourPricings
          },
          pendingSubscriptionFlavours: pendingSubscriptionFlavours,
          productVariants: productVariants,
          priceIncrease: {
            showPriceIncreaseAlert: showPriceIncreaseAlert(),
            effectiveFrom: pendingSubscription.plan.nextUpcomingPrice
              ? parseISO(
                  pendingSubscription.plan.nextUpcomingPrice.effectiveFrom
                )
              : null
          }
        })
        if (handlePricingUpdate) {
          handlePricingUpdate()
        }
      },
      onError: (error) => {
        Sentry.captureException(
          `Error occured in CHECKOUT_PENDING_SUBSCRIPTION_ORDER_PRICING query`,
          {
            extra: {
              error
            },
            tags: {
              product: Sentry.Product.Checkout
            }
          }
        )
      }
    })

  return { refetchCheckoutPricing, checkoutPricingLoading }
}

export { useCheckoutPricing, checkoutPricingState }
export type { InitialState as CheckoutPricingState }
