// @noflow
import { ACCOUNT_ROUTES } from '@/routes'
import { useQuery } from '@apollo/client'
import isNil from 'lodash/isNil'
import isUndefined from 'lodash/isUndefined'
import snakeCase from 'lodash/snakeCase'
import times from 'lodash/times'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import type { Locale } from '@/utils/countryCodeHelper'
import { formatCurrencyWithoutDecimal } from '@/utils/currency'
import { percentageValue } from '@/utils/percentage'

import segmentTrack from '@/components/analytics/Analytics'
import { CardSkeleton } from '@/components/elements/atoms/Card/CardSkeleton'
import MerchandisingCard from '@/components/elements/atoms/MerchandisingCard/MerchandisingCard'
import SkeletonParagraph from '@/components/elements/atoms/SkeletonParagraph/SkeletonParagraph'
import SwiperSlider from '@/components/elements/molecules/SwiperSlider/SwiperSlider'

import {
  NotificationsQuery_notifications as Notification,
  NotificationsQuery,
  NotificationsQuery_user_subscription_activeRafCampaignVariant,
  NotificationsQuery_user_subscription_referralLink_referrerDiscount
} from './__generated__/NotificationsQuery'
import type { Currency } from '@/shared_types/rails_models/shipping_countries'

import { MERCH_ITEM_QUERY } from './queries'

const MERCHANDISING_CAROUSEL_ID = 'merchandising-carousel'

const analyticsOnClick = (title: string, link: string) => {
  segmentTrack('Merchandising Card Clicked', {
    title,
    link
  })
}

const MerchandisingCarousel = (): JSX.Element | null => {
  const { t } = useTranslation()

  const { loading, data, error } =
    useQuery<NotificationsQuery>(MERCH_ITEM_QUERY)

  const renderSlides = useCallback(
    (
      displayRAFInCarousel:
        | false
        | NotificationsQuery_user_subscription_referralLink_referrerDiscount
        | null,
      referrerDiscount: NotificationsQuery_user_subscription_referralLink_referrerDiscount | null,
      activeRafCampaignVariant: NotificationsQuery_user_subscription_activeRafCampaignVariant | null,
      locale: Locale,
      currency: Currency
    ): JSX.Element[] => {
      if (isUndefined(data) || data.notifications.length === 0) return []
      const slides = data.notifications.map(
        (notification: Notification): JSX.Element => {
          const { text, link, image, altText } = notification
          const snakeCaseText = snakeCase(text)
          return (
            <MerchandisingCard
              key={`merch-card-${snakeCaseText}-key`}
              title={{
                text: text
              }}
              to={link}
              alt={altText}
              image={!isNil(image) ? image.src : undefined}
              analyticsOnClick={analyticsOnClick}
              identifier={'merchandising_card.' + snakeCaseText}
            />
          )
        }
      )
      if (displayRAFInCarousel && referrerDiscount) {
        const hasPercentageDiscount =
          referrerDiscount.applicableDiscountParts[0].discountBasis ===
          'percentage'

        const hasAltruisticDiscount =
          referrerDiscount.applicableDiscountParts[0].value <= 0

        const discountText = () =>
          hasAltruisticDiscount
            ? 'merchandising_card.raf.body_altruistic'
            : 'merchandising_card.raf.body'

        const discountValue = () =>
          hasPercentageDiscount
            ? percentageValue(referrerDiscount.applicableDiscountParts[0].value)
            : formatCurrencyWithoutDecimal(
                referrerDiscount.applicableDiscountParts[0].value,
                {
                  locale,
                  currency
                }
              )

        const discountBadge = () =>
          hasAltruisticDiscount
            ? undefined
            : {
                text: 'merchandising_card.raf.discountCopy',
                variables: { discount: discountValue() },
                namespace: 'atoms'
              }

        const discountTitle = () => ({
          text: discountText(),
          variables: { discount: discountValue() },
          namespace: 'atoms'
        })

        const merchandisingCardPath = () => ACCOUNT_ROUTES.referAFriend

        slides.unshift(
          <MerchandisingCard
            key="merch-card-raf-key"
            alt={t('atoms:merchandising_card.raf.alt')}
            image="/Web/photos/dogs/three-dogs-wearing-bandanas"
            discount={discountBadge()}
            title={discountTitle()}
            to={merchandisingCardPath()}
            analyticsOnClick={analyticsOnClick}
            identifier="merchandising_card.raf"
          />
        )
      }
      return slides
    },
    [data, t]
  )

  if (loading && !error) {
    return (
      <SwiperSlider paddingOverride={24}>
        {times(data?.notifications.length ?? 3).map(
          (index: number): JSX.Element => (
            <div key={index}>
              <CardSkeleton height="20rem" width="32rem" />
              <SkeletonParagraph
                align={'left'}
                count={1}
                shortLine={false}
                margin={'1.6rem 0'}
              />
            </div>
          )
        )}
      </SwiperSlider>
    )
  }
  // eslint-disable-next-line i18next/no-literal-string
  if (error)
    throw new Error(`Error when MERCH_ITEM_QUERY executes: ${error.message}`)

  if (isUndefined(data) || data.notifications.length === 0) return null

  const { user } = data
  const { shippingCountryCode, preferredLanguage, subscription } = user
  const { referralLink, activeRafCampaignVariant } = subscription
  const { referrerDiscount } = referralLink

  const { locale, currency } = countryCodeToLocaleCurrency(
    shippingCountryCode,
    preferredLanguage
  )

  const displayRAFInCarousel = referrerDiscount

  return (
    <section>
      <SwiperSlider
        id={MERCHANDISING_CAROUSEL_ID}
        arrowPosition={-4}
        paddingOverride={24}
      >
        {renderSlides(
          displayRAFInCarousel,
          referrerDiscount,
          activeRafCampaignVariant,
          locale,
          currency
        )}
      </SwiperSlider>
    </section>
  )
}

export { MERCH_ITEM_QUERY, MERCHANDISING_CAROUSEL_ID }
export default MerchandisingCarousel
