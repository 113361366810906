// @noflow
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'

import DogWithHearts from 'assets/images/illustrations/dogs/dog-with-hearts.svg'


type Props = {
  data?: any,
  handleSubmitPreferences: (preferenceIds: string[]) => void,
  handleDisplayError: (errorText: string) => void,
  closeMarketingPreferencesModal: () => void
}

const MarketingPreferencesModal = ({
  handleSubmitPreferences,
  data,
  handleDisplayError,
  closeMarketingPreferencesModal
}: Props): React.Node | null => {
  const { t } = useTranslation('account')
  const [initialised, setInitialised] = useState(false)
  const [marketingPreferences, setMarketingPreferences] =
    useState({
      isUnsubscribeChecked: false,
      isInteracted: false,
      selectedMarketingMethodIds: [],
      selectedMarketingPurposeIds: []
    })

  const {
    availableMethods,
    availablePurposes,
    existingPreferences,
    preselectedPurposeIds,
    preselectedMethodIds
  } = useMemo(() => {
    if (!data) {
      return {
        availableMethods: [],
        availablePurposes: [],
        existingPreferences: [],
        preselectedPurposeIds: [],
        preselectedMethodIds: []
      }
    }

    const {
      availableMarketingMethodPurposes: availablePreferences,
      selectedMarketingMethodPurposes: existingPreferences
    } = data.user

    const availableMethods = availablePreferences.reduce(
      (acc, { marketingMethod }) => {
        if (!acc.find((method) => method.id === marketingMethod.id)) {
          acc.push(marketingMethod)
        }
        return acc
      },
      []
    )

    const availablePurposes = availablePreferences.reduce(
      (acc, { marketingPurpose }) => {
        if (!acc.find((purpose) => purpose.id === marketingPurpose.id)) {
          acc.push(marketingPurpose)
        }
        return acc
      },
      []
    )

    const preselectedPreferences = availablePreferences.filter(
      ({ preselect }) => preselect
    )

    const preselectedPurposeIds = preselectedPreferences.map(
      ({ marketingPurpose: { id } }) => id
    )

    const preselectedMethodIds = preselectedPreferences.map(
      ({ marketingMethod: { id } }) => id
    )

    return {
      availablePreferences,
      availableMethods,
      availablePurposes,
      existingPreferences,
      preselectedPurposeIds,
      preselectedMethodIds
    }
  }, [data])


  const hasMethodSelected = useMemo(
    () => marketingPreferences.selectedMarketingMethodIds.length > 0,
    [marketingPreferences.selectedMarketingMethodIds]
  )
  const hasPurposeSelected = useMemo(
    () => marketingPreferences.selectedMarketingPurposeIds.length > 0,
    [marketingPreferences.selectedMarketingPurposeIds]
  )

  const handleMarketingPurposeChange = useCallback(
    (e) => {
      const checked = e.currentTarget.checked
      const idAsString = e.currentTarget.id.split('-')[1]
      setMarketingPreferences((prevState) => ({
        ...prevState,
        isInteracted: true,
        isUnsubscribeChecked: false,
        selectedMarketingPurposeIds: checked
          ? [...prevState.selectedMarketingPurposeIds, idAsString]
          : prevState.selectedMarketingPurposeIds.filter(
              (item) => item !== idAsString
            )
      }))
    },
    []
  )

  const handleMarketingMethodChange = useCallback(
    (e) => {
      const checked = e.currentTarget.checked
      const idAsString = e.currentTarget.id.split('-')[1]
      setMarketingPreferences((prevState) => ({
        ...prevState,
        isInteracted: true,
        isUnsubscribeChecked: false,
        selectedMarketingMethodIds: checked
          ? [...prevState.selectedMarketingMethodIds, idAsString]
          : prevState.selectedMarketingMethodIds.filter(
              (item) => item !== idAsString
            )
      }))
    },
    []
  )

  const handleClearPreferences = useCallback(
    (e) => {
      const checked = e.currentTarget.checked
      if (e.currentTarget.id !== '-1') return
      if (checked) {
        setMarketingPreferences((prevState) => ({
          ...prevState,
          selectedMarketingMethodIds: [],
          selectedMarketingPurposeIds: [],
          isInteracted: true,
          isUnsubscribeChecked: checked
        }))
      } else {
        setMarketingPreferences((prevState) => ({
          ...prevState,
          selectedMarketingMethodIds: preselectedMethodIds,
          selectedMarketingPurposeIds: preselectedPurposeIds,
          isInteracted: true,
          isUnsubscribeChecked: checked
        }))
      }
    },
    [preselectedMethodIds, preselectedPurposeIds]
  )

  const handleSubmit = useCallback(() => {
    const availableMarketingMethodPurposes = [
      ...(data?.user.availableMarketingMethodPurposes ?? [])
    ]

    const selectedPreferences = availableMarketingMethodPurposes.filter(
      (preference) =>
        marketingPreferences.selectedMarketingMethodIds.includes(
          preference.marketingMethod.id
        ) &&
        marketingPreferences.selectedMarketingPurposeIds.includes(
          preference.marketingPurpose.id
        )
    )

    const selectedPreferenceIds =
      selectedPreferences && selectedPreferences.map((preference) => preference.id) || []

    if (!hasMethodSelected && hasPurposeSelected) {
      handleDisplayError('contact_preferences.update_error_missing_method')
      return
    } else if (hasMethodSelected && !hasPurposeSelected) {
      handleDisplayError('contact_preferences.update_error_missing_purpose')
      return
    }
    handleSubmitPreferences(selectedPreferenceIds)
    closeMarketingPreferencesModal()
  }, [data?.user.availableMarketingMethodPurposes, hasMethodSelected, hasPurposeSelected, handleSubmitPreferences, closeMarketingPreferencesModal, marketingPreferences.selectedMarketingMethodIds, marketingPreferences.selectedMarketingPurposeIds, handleDisplayError])

  useEffect(() => {
    if (!data || initialised) return
    if (!existingPreferences.length) {
      setMarketingPreferences((prevState) => ({
        ...prevState,
        isUnsubscribeChecked: true
      }))
      setInitialised(true)
      return
    }

    setMarketingPreferences((prevState) => ({
      ...prevState,
      selectedMarketingPurposeIds: existingPreferences.map(
        ({
          supportedMarketingMethodPurpose: {
            marketingPurpose: { id }
          }
        }) => id
      ),
      selectedMarketingMethodIds: existingPreferences.map(
        ({
          supportedMarketingMethodPurpose: {
            marketingMethod: { id }
          }
        }) => id
      )
    }))
    setInitialised(true)
  }, [data, existingPreferences, initialised])

  useEffect(() => {
    if (data && initialised && !hasMethodSelected && !hasPurposeSelected) {
      setMarketingPreferences((prevState) => {
        return {
          ...prevState,
          isUnsubscribeChecked: true,
          selectedMarketingMethodIds: [],
          selectedMarketingPurposeIds: []
        }
      })
    }
  }, [data, hasMethodSelected, hasPurposeSelected, initialised])

  return(
    <div className={'card-wrapper'}>
      <Grid item sm={12} md={6} className={'form-wrapper'}>
        {(() => {
          return (
            <>
              <div className={'section-wrapper'}>
                <p>{t('contact_preferences.content_types')}</p>
                <div className='checkbox-list'>
                  {availablePurposes.map((purpose) => {
                    return (
                      <>
                        <div className={'checkbox-wrapper'}>
                          <input
                            type='checkbox'
                            id={`purpose-${purpose.id}`}
                            checked={marketingPreferences.selectedMarketingPurposeIds.includes(
                              `${purpose.id}`
                            )}
                            onChange={handleMarketingPurposeChange}
                          />
                          <label htmlFor={`purpose-${purpose.id}`}>
                            {purpose.name}
                          </label>
                        </div>
                        <div className={'explainer-text'}>
                          <p>{purpose.description}</p>
                        </div>
                      </>
                    )
                  })}
                </div>
              </div>
              <div className={'section-wrapper'}>
                <p>{t('contact_preferences.contact_methods.title')}</p>
                <div className='checkbox-list__horizontal'>
                  {availableMethods.map((method) => {
                    return (
                      <div className={'checkbox-wrapper'} key={`method-${method.id}`}>
                        <input
                          type='checkbox'
                          id={`method-${method.id}`}
                          checked={marketingPreferences.selectedMarketingMethodIds.includes(
                            `${method.id}`
                          )}
                          onChange={handleMarketingMethodChange}
                        />
                        <label htmlFor={`method-${method.id}`}>
                          {method.name}
                        </label>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className={'section-wrapper'}>
                <div className={'checkbox-wrapper'}>
                  <input
                    type='checkbox'
                    checked={marketingPreferences.isUnsubscribeChecked}
                    id={-1}
                    onChange={handleClearPreferences}
                  />
                  <label htmlFor={-1}>
                    {t('contact_preferences.unsubscribe')}
                  </label>
                </div>
              </div>
              <div className={'footer-section'}>
                <button onClick={handleSubmit} type={'button'}>
                  {t('contact_preferences.save')}
                </button>
                {/* eslint-disable-next-line react/no-danger */}
                <p dangerouslySetInnerHTML={{__html: t('contact_preferences.save_note')}} />
              </div>
            </>
          )
        })()}
      </Grid>
      <div className={'image-wrapper'}>
        <img alt="A illustrated dog with two hearts." src={DogWithHearts} />
      </div>
    </div>
  )
}

export default MarketingPreferencesModal