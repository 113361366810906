// @flow

import 'scroll-behavior-polyfill'

import type { Dispatch, Action, Store } from 'redux'

/**
 * ScrollProperties represents the parameters passed to `window.scroll` that
 * dictates how it behaves. All keys to an object of type ScrollProperties
 * are optional
 *
 * ScrollProperties - npmjs.com/package/scroll-behavior-polyfill
 */
type ScrollProperties = {|
  behavior?: 'smooth',
  top?: number
|}

/**
 * HistoryProperties represents the properties of `window.history` that will be
 * overwritten. All keys to an object of type HistoryProperties are optional
 *
 * HistoryProperties - developer.mozilla.org/en-US/docs/Web/API/History
 */
type HistoryProperties = {|
  scrollRestoration?: 'manual'
|}

/**
 * The shouldScroll callback is provided with the full context of the state
 * before and after the action is run in order to allow the call-site
 * flexibility regarding how it would like to determine whether a scroll action
 * should occur. The ShouldScrollCallbackParameters does not assume any shape
 * of the Redux app state therefore it makes available the full state before
 * and after the action occurs as well as the action itself
 */
type ShouldScrollCallbackParameters<State> = {|
  action: Action,
  preActionState: State,
  postActionState: State
|}

type ScrollPageMiddlewareParameters<State> = {|
  scrollProperties: ScrollProperties,
  historyProperties?: HistoryProperties,
  shouldScroll: (ShouldScrollCallbackParameters<State>) => boolean
|}

/**
 * scrollPage
 *
 * Accepts an object as an argument of the form ScrollPageMiddlewareParameters
 * that contains the callback that will return a boolean indicating whether or
 * not `window.scroll` should occur. Additionally, the object contains the set
 * of properties to be passed to `window.scroll` so that the middleware is
 * entirely customizable in each use case
 *
 * Example usage:
 *
 * Assume the state of the Redux app is of the form:
 *
 * View =
 *   | 'A'
 *   | 'B'
 *
 * State = {
 *   currentView: View
 * }
 *
 * and there is an action of the form:
 *
 * ChangeView = {
 *   type: 'CHANGE_VIEW',
 *   newView: View
 * }
 *
 * Then configuration of the `scrollPage` middleware could be of the form:
 *
 * const scrollProperties = { behaviour: 'smooth', top: 0 }
 * const historyProperties = { scrollRestoration: 'manual' }
 * const shouldScroll = ({
 *   action,
 *   preActionState,
 *   postActionState
 * }: ShouldScrollCallbackParameters<State>) => {
 *   if (action.type !== 'CHANGE_VIEW') return false
 *   if (preActionState.currentView !== postActionState.currentView) return true
 * }
 *
 * const scrollPageMiddleWare = scrollPage<State>({
 *   scrollProperties,
 *   historyProperties,
 *   shouldScroll
 * })
 */
const scrollPage: Store = <State>({
  scrollProperties,
  historyProperties = {},
  shouldScroll
}: ScrollPageMiddlewareParameters<State>): ((Store) => Action) => {
  for (const [key, value] of Object.entries(historyProperties)) {
    window.history[key] = value
  }

  return ({ getState }: Store): (Store => Action) => {
    return (next: Dispatch): Dispatch => {
      return (action: Action): Action => {
        const preActionState = getState()
        const result = next(action)
        const postActionState = getState()

        const callbackParameters = {
          action,
          preActionState,
          postActionState
        }

        if (shouldScroll(callbackParameters)) window.scroll(scrollProperties)

        return result
      }
    }
  }
}

export type {
  ShouldScrollCallbackParameters,
  ScrollProperties,
  HistoryProperties
}

export default scrollPage
