// @noflow
import { gql } from '@apollo/client'

import { PAYMENT_METHODS_FRAGMENT } from '@/components/pages/MyDetailsPage/fragments/fragments'

import { REACTIVATION_CAMPAIGN_FRAGMENT } from '../fragments/limitedOfferFragment'

const CLIENT_INIT_QUERY = gql`
  ${PAYMENT_METHODS_FRAGMENT}
  ${REACTIVATION_CAMPAIGN_FRAGMENT}
  query ClientInitQuery($nextNBoxes: Int!, $archivedType: ArchivedType!) {
    systemShouldShowAppContent: featureFlag(
      flag: "system_should_show_app_content"
      fallback: "not_in_cohort"
    )
    shouldSeeXmasTheme: featureFlag(
      flag: "should_see_xmas_theme"
      fallback: "false"
    )
    shouldSeeOnlyMoveThisBoxCheckbox: featureFlag(
      flag: "should-see-move-only-this-box-checkbox"
      fallback: "false"
    )
    shouldSeePlanManagementPhase3: featureFlag(
      flag: "should_see_plan_management_phase_3"
      fallback: "not_in_cohort"
    )
    shouldSeeDogProfileV2: featureFlag(
      flag: "should_see_dog_profile_v2"
      fallback: "false"
    )
    shouldSeeUpdatedDeliveryCalendarUI: featureFlag(
      flag: "should_see_updated_delivery_calendar_ui"
      fallback: "false"
    )
    shouldTrackErrorsOnErrorState: featureFlag(
      flag: "should_track_errors_on_error_state"
      fallback: "false"
    )
    shouldSeeBoostOnboarding: featureFlag(
      flag: "should_see_boost_onboarding"
      fallback: "not_in_cohort"
    )
    shouldSeeBoostOnboardingV3: featureFlag(
      flag: "should-see-boost-onboarding-v3"
      fallback: "not_in_cohort"
    )
    shouldSeeUpcomingPriceRiseInfo: featureFlag(
      flag: "system-should-see-upcoming-price-rise-info"
      fallback: "false"
    )
    shouldSeeSelfResolutions: featureFlag(
      flag: "should_see_self_resolutions"
      fallback: "false"
    )
    shouldSeeStap: featureFlag(flag: "should_see_stap", fallback: "false")
    allowEditAndReviewPlanBeforeReactivation: featureFlag(
      flag: "allow-edit-and-review-plan-before-reactivation"
      fallback: "not_in_cohort"
    )
    shouldSeeSkipBoxFeature: featureFlag(
      flag: "should_see_skip_box_feature"
      fallback: "not_in_cohort"
    )
    skipVersusPushBoxExpirience: featureFlag(
      flag: "skip-versus-push-box-experience"
      fallback: "not_in_cohort"
    )
    shouldSeeSwapToMFITBPlanOfferPage: featureFlag(
      flag: "should_see_swap_to_mfitb_plan_offer_page"
      fallback: "not_in_cohort"
    )
    shouldSeeHomeOnboardingTourV2: featureFlag(
      flag: "should_see_home_onboarding_tour_v2"
      fallback: "not_in_cohort"
    )
    settings(names: ["carrier_preferences_enabled"]) {
      name
      value
    }
    notifications {
      id
    }
    currentAdminUser {
      id
    }
    user {
      id
      acquisitionChannel
      affiliateType
      address {
        id
        deliverable
        postcode
      }
      dogs {
        id
        name
        gender
        planPortionInGrams
        ageInMonths
        eaterType
        weightInGrams
        dogProfile {
          avatarUrl
        }
        breed {
          id
          key
          name
        }
      }
      subscription {
        id
        status
        deliveriesReceived
        activeBoostedMembership {
          id
          isActive
          membershipType
        }
        nextNBoxes(num: $nextNBoxes) {
          id
          editable
          descriptor
          cutOffDate
          isoDeliveryDate
          isTrialBox
        }
        increasedDeliveryCadencePlan {
          id
        }
        plan {
          swappablePlan {
            id
          }
          downsizeablePlan {
            id
          }
        }
        alreadyRedeemedReactivationCampaign {
          ...reactivationCampaignFragment
        }
        defaultReactivationCampaign {
          ...reactivationCampaignFragment
        }
        ...paymentMethodsFragment
      }
      shippingCountry {
        id
        code
        showExtras
        showCare
        defaultLanguage
      }
      preferredLanguage
    }
  }
`

export { CLIENT_INIT_QUERY }
