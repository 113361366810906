/* eslint-disable react-hooks/rules-of-hooks */
// @noflow
import { useNotifications } from '@/context/notifications/notifications'
import { ACCOUNT_ROUTES } from '@/routes'
import { useMutation, useQuery } from '@apollo/client'
import { Grid } from '@mui/material'
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef
} from 'react'
import { useNavigate, useParams as useParamsSpa } from 'react-router-dom'

import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import type { Locale } from '@/utils/countryCodeHelper'
import * as Sentry from '@/utils/sentry'

import BREAKPOINTS from '@/constants/Breakpoints'
import { ADDED_AP_TO_PLAN } from '@/constants/RibbonEvents'

import useBoolean from '@/hooks/useBoolean'
import useLocalStorage from '@/hooks/useLocalStorage'
import useWindowSize from '@/hooks/useWindowSize'

import FixedBase from './components/molecules/FixedBase'
import About from './components/organisms/About'
import CustomerReviews from './components/organisms/CustomerReviews'
import Description from './components/organisms/Description'
import Gallery from './components/organisms/Gallery'
import KeyBenefits from './components/organisms/KeyBenefits'
import ProductCollectionTitle from './components/organisms/ProductCollectionTitle'
import ProductUnavailable from './components/organisms/ProductUnavailable'
import ProductVariantFrequencies from './components/organisms/ProductVariantFrequencies'
import ProductVariants from './components/organisms/ProductVariants'
import QuantitySelection from './components/organisms/QuantitySelection'
import AlertCard from '@/components/elements/atoms/Alert/AlertCard'
import { Button } from '@/components/elements/atoms/Button'
import LoadingScreen from '@/components/elements/organisms/LoadingScreen/LoadingScreen'
import { UPCOMING_BOX_QUERY_VARIABLES } from '@/components/pages/Dashboard/components/upcomingBoxes/UpcomingBoxes'

import STYLES from './ProductCollection.module.sass'

// Mutations
import { ORDERS_PRODUCT_CREATE } from './mutations/ordersProductCreate'
import { PRODUCT_COLLECTION_QUERY } from './queries/productCollectionQuery'

import type {
  orderProductsCreate,
  orderProductsCreateVariables
} from './mutations/__generated__/orderProductsCreate'
import type {
  ProductCollectionQuery,
  ProductCollectionQueryVariables
} from './queries/__generated__/ProductCollectionQuery'
import type { Currency } from '@/shared_types/rails_models/shipping_countries'

import type { SubscriptionStatus } from '../../../../../types/index'
import ExtrasPageRoutes from '../../routes'
import { errorCopy } from './helpers/copies'
import useSelectedProduct from './hooks/useSelectedProduct/useSelectedProduct'

type ProductCollectionContentProps = {
  data: ProductCollectionQuery
  slug: string
}

type ProductCollectionContext = {
  locale: Locale
  currency: Currency
  isUpdateProductInProgress: boolean
  status: SubscriptionStatus
  subscriptionId: ProductCollectionQuery['user']['subscription']['id']
}

const ProductCollectionContext = createContext<ProductCollectionContext>({
  locale: 'en-GB',
  currency: 'GBP',
  isUpdateProductInProgress: false,
  status: 'active' as SubscriptionStatus,
  subscriptionId: ''
})

const ProductCollectionContent = ({
  data,
  slug
}: ProductCollectionContentProps): JSX.Element => {
  const { setSuccessNotification } = useNotifications()
  const {
    user: {
      id: userId,
      shippingCountryCode,
      preferredLanguage,
      email,
      subscription: { id: subscriptionId, status, nextEditableBox }
    },
    nonCoreOrderDeliveryFee,
    productCollection: {
      name,
      images,
      averageCustomerRating,
      countOfCustomerRatings,
      publicCustomerReviews,
      productBenefits,
      productVariants,
      about,
      description,
      productSticker,
      sizeGuide
    }
  } = data

  const { windowWidth } = useWindowSize()

  useEffect(() => {
    window.analytics.track('Product Collection Viewed', {
      productCollectionSlug: slug
    })
  }, [slug])

  const [
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    recurringProductQuantity,
    setRecurringProductQuantity
  ] = useLocalStorage<string, number>('recurringProductQuantity', 0)

  const [
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    oneOffProductQuantity,
    setOneOffProductQuantity
  ] = useLocalStorage<string, number>('oneOffProductQuantity', 0)

  const {
    // Seleted product variant
    selectedProductVariantId,
    selectedProductVariant,
    setSelectedProductVariantId,
    // Seleted product variant frequecny
    selectedProductVariantFrequency,
    selectedProductVariantDeliveryType,
    setSelectedProductVariantFrequency,
    // Seleted product quantity
    selectedProductQuantity,
    setSelectedProductQuantity,
    selectedProductVariantSlug,
    setSelectedProductVariantSlug
  } = useSelectedProduct({ productVariants, subscriptionStatus: status })

  const { grossPrice } = selectedProductVariant || {}

  const { locale, currency } = countryCodeToLocaleCurrency(
    shippingCountryCode,
    preferredLanguage
  )

  /**
   * When a product is added we show the confirmation card and set
   * `isProductAdded` variable to `true` for a specific time. At the
   * moment of writing the timeout is equal to 4s.
   *
   * This variable can be used to disable interactive elements on the page
   * for a period of time when we show the card.
   */
  const {
    value: isProductAdded,
    setTrue: setIsProductAddedTrue,
    setFalse: setIsProductAddedFalse
  } = useBoolean(false)

  const {
    value: shouldDisplayErrorIfAvalable,
    setTrue: setShouldDisplayErrorIfAvalableTrue,
    setFalse: setShouldDisplayErrorIfAvalableFalse
  } = useBoolean(true)

  const setSelectedProductFrequencyType = useCallback(
    (variantDelivery) => {
      setShouldDisplayErrorIfAvalableFalse()
      setSelectedProductVariantFrequency(variantDelivery)
    },
    [setSelectedProductVariantFrequency, setShouldDisplayErrorIfAvalableFalse]
  )

  const productVariantInput = {
    productVariantId: selectedProductVariantId,
    quantity: selectedProductQuantity,
    deliveryType: selectedProductVariantFrequency
  }

  // Refs
  const customerReviewsSectionRef = useRef(null)
  const descriptionRef = useRef(null)
  const aboutRef = useRef(null)

  const [
    createOrderProduct,
    { loading: mutationLoading, error: mutationError }
  ] = useMutation<orderProductsCreate, orderProductsCreateVariables>(
    ORDERS_PRODUCT_CREATE,
    {
      variables: {
        ...UPCOMING_BOX_QUERY_VARIABLES,
        userId: userId,
        productVariantInput: productVariantInput
      },
      onCompleted: () => {
        setIsProductAddedTrue()
      },
      update(cache, { data }) {
        if (data && data.orderProductsCreate) {
          const {
            orderProductsCreate: {
              user: { basket: updatedBasket }
            }
          } = data

          const updatedBasketProducts = updatedBasket?.basketProducts || []

          const basketRef = cache.identify({
            __typename: 'Basket',
            id: updatedBasket?.id
          })

          cache.modify({
            id: basketRef,
            fields: {
              basketProducts: () => updatedBasketProducts
            }
          })
        }
      }
    }
  )

  const isUpdateProductDisabled =
    isProductAdded ||
    (status === 'paused' && selectedProductVariantFrequency !== 'on_demand')

  const isUpdateProductInProgress = useMemo(() => {
    return isProductAdded || mutationLoading
  }, [isProductAdded, mutationLoading])

  // Handlers
  const onUpdateProduct = useCallback(() => {
    const selectedProductVariant = productVariants.find(
      ({ id }) => selectedProductVariantId === id
    )
    window.analytics.track('Shop: Additional Product Added', {
      productCollectionSlug: slug,
      purchaseType: selectedProductVariantFrequency,
      quantity: selectedProductQuantity,
      sizeSlug: selectedProductVariant?.slug
    })
    setShouldDisplayErrorIfAvalableTrue()
    createOrderProduct()
    /**
     * Update the quantity of the recurring product in local storage.
     * This is needed to show the alert card on the "Plan" page.
     */
    if (selectedProductVariantFrequency === 'recurring') {
      window.ribbon && window.ribbon('trigger', ADDED_AP_TO_PLAN)
      setRecurringProductQuantity(selectedProductQuantity)
    }
    /**
     * Update the quantity of the one off product in local storage.
     * This is needed to show the alert card on the "Box" page.
     */
    if (selectedProductVariantFrequency === 'one_off') {
      setOneOffProductQuantity(selectedProductQuantity)
    }
  }, [
    productVariants,
    slug,
    selectedProductVariantFrequency,
    selectedProductQuantity,
    setRecurringProductQuantity,
    setOneOffProductQuantity,
    setShouldDisplayErrorIfAvalableTrue,
    createOrderProduct,
    selectedProductVariantId
  ])

  const productCollectionContextValue = useMemo(
    () => ({
      locale,
      currency,
      isUpdateProductInProgress,
      status,
      subscriptionId
    }),
    [locale, currency, isUpdateProductInProgress, status, subscriptionId]
  )

  const error =
    mutationError?.message && shouldDisplayErrorIfAvalable
      ? errorCopy({
          mutationError: mutationError?.message,
          recuringType: selectedProductVariantFrequency,
          quantity: selectedProductQuantity,
          objectId: nextEditableBox?.id
        })
      : null

  // Effects
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>
    if (isProductAdded) {
      timeout = setTimeout(() => setIsProductAddedFalse(), 5000)
    }
    return () => clearTimeout(timeout)
  }, [
    isProductAdded,
    setIsProductAddedFalse,
    selectedProductVariantFrequency,
    windowWidth
  ])

  useEffect(() => {
    let href
    switch (selectedProductVariantFrequency) {
      case 'on_demand': {
        href = ACCOUNT_ROUTES.basket
        break
      }
      case 'one_off':
      case 'recurring': {
        // eslint-disable-next-line i18next/no-literal-string
        href = `${ACCOUNT_ROUTES.base}?focus=extrasSection`
        break
      }
    }

    isProductAdded &&
      setSuccessNotification({
        text: 'extras.basket.notification.added_product',
        variables: {
          count: selectedProductQuantity,
          context: selectedProductVariantFrequency,
          href
        },
        namespace: 'dashboard'
      })
  }, [
    isProductAdded,
    selectedProductQuantity,
    selectedProductVariantFrequency,
    setSuccessNotification
  ])

  return (
    <ProductCollectionContext.Provider value={productCollectionContextValue}>
      <div className={STYLES.container}>
        <div className={STYLES.content}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Gallery
                name={name}
                productSticker={productSticker}
                images={images}
                productCollectionSlug={slug}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={STYLES.details}>
                <ProductCollectionTitle
                  name={name}
                  slug={slug}
                  grossPrice={grossPrice}
                  averageCustomerRating={averageCustomerRating}
                  countOfCustomerRatings={countOfCustomerRatings}
                  publicCustomerReviews={publicCustomerReviews}
                  locale={locale}
                  currency={currency}
                  selectedProductVariantDeliveryType={
                    selectedProductVariantDeliveryType
                  }
                  customerReviewsSectionRef={customerReviewsSectionRef}
                />
                <Description
                  description={description}
                  descriptionRef={descriptionRef}
                  productCollectionSlug={slug}
                />
                <KeyBenefits
                  productBenefits={productBenefits}
                  slug={slug}
                  aboutRef={aboutRef}
                />
                <ProductVariants
                  name={name}
                  productVariants={productVariants}
                  sizeGuide={sizeGuide}
                  selectedProductVariantId={selectedProductVariantId}
                  setSelectedProductVariantId={setSelectedProductVariantId}
                  selectedProductVariantSlug={selectedProductVariantSlug}
                  setSelectedProductVariantSlug={setSelectedProductVariantSlug}
                  selectedProductVariantFrequency={
                    selectedProductVariantFrequency
                  }
                  setSelectedProductVariantFrequency={
                    setSelectedProductFrequencyType
                  }
                />
                {!selectedProductVariant?.unavailableObject?.reasonCopy ? (
                  <QuantitySelection
                    status={status}
                    isUpdateProductInProgress={isUpdateProductInProgress}
                    selectedProductVariantFrequency={
                      selectedProductVariantFrequency
                    }
                    isUpdateProductDisabled={isUpdateProductDisabled}
                    setSelectedProductQuantity={setSelectedProductQuantity}
                  />
                ) : (
                  windowWidth > BREAKPOINTS.md && (
                    <ProductUnavailable
                      productSlug={selectedProductVariant?.slug || ''}
                      email={email}
                      productCollectionSlug={slug}
                      reason={selectedProductVariant?.unavailableObject?.reason}
                    />
                  )
                )}
                {!selectedProductVariant?.unavailableObject?.reasonCopy && (
                  <React.Fragment>
                    <ProductVariantFrequencies
                      status={status}
                      nonCoreOrderDeliveryFee={nonCoreOrderDeliveryFee}
                      name={name}
                      selectedProductVariantFrequency={
                        selectedProductVariantFrequency
                      }
                      setSelectedProductVariantFrequency={
                        setSelectedProductFrequencyType
                      }
                      selectedProductVariant={selectedProductVariant}
                      productCollectionSlug={slug}
                    />
                    {windowWidth >= BREAKPOINTS.md && (
                      <div className={STYLES.ctaWrapper}>
                        <Button
                          typography={{
                            namespace: 'dashboard',
                            text: 'extras.product_collection.details.quantity_selection.button',
                            variables: {
                              context: selectedProductVariantFrequency
                            }
                          }}
                          onClick={onUpdateProduct}
                          disabled={
                            isUpdateProductInProgress ||
                            isUpdateProductDisabled ||
                            status === 'suspended'
                          }
                          disableAnalytics
                        />
                        {error && (
                          <div className={STYLES.alertCard}>
                            <AlertCard message={error} variant="error" />
                          </div>
                        )}
                      </div>
                    )}
                  </React.Fragment>
                )}
                <About about={about} aboutRef={aboutRef} />
              </div>
            </Grid>
          </Grid>
        </div>
        <div ref={customerReviewsSectionRef}>
          <CustomerReviews
            publicCustomerReviews={publicCustomerReviews}
            averageCustomerRating={averageCustomerRating}
            countOfCustomerRatings={countOfCustomerRatings}
          />
        </div>
        {windowWidth < BREAKPOINTS.md && (
          <FixedBase
            status={status}
            selectedProductVariantFrequency={selectedProductVariantFrequency}
            isUpdateProductInProgress={isUpdateProductInProgress}
            isUpdateProductDisabled={isUpdateProductDisabled}
            onUpdateProduct={onUpdateProduct}
            error={error}
            unavailableReason={
              selectedProductVariant?.unavailableObject?.reason
            }
            productSlug={selectedProductVariant?.slug || ''}
            email={email}
            productCollectionSlug={slug}
          />
        )}
      </div>
    </ProductCollectionContext.Provider>
  )
}

const ProductCollection = (): JSX.Element | null => {
  const { product_collection: productCollectionSlug } = useParamsSpa<{
    product_collection: string
  }>()
  const navigate = useNavigate()

  const { loading, data, error } = useQuery<
    ProductCollectionQuery,
    ProductCollectionQueryVariables
  >(PRODUCT_COLLECTION_QUERY, {
    variables: { slug: productCollectionSlug || '' }
  })

  if (loading) {
    return (
      <LoadingScreen
        isOpen
        title={{
          text: 'loading_screen.title',
          namespace: 'shared'
        }}
      />
    )
  }

  if (error) {
    Sentry.captureException(`Error in PRODUCT_COLLECTION_QUERY `, {
      extra: { error },
      tags: {
        product: Sentry.Product.Account,
        team: Sentry.Team.Retention
      }
    })

    navigate(ACCOUNT_ROUTES.extras)

    return null
  }

  if (!data) {
    navigate(ExtrasPageRoutes.ExtrasList)
    return null
  }

  return (
    <ProductCollectionContent data={data} slug={productCollectionSlug || ''} />
  )
}

export { ProductCollectionContext }

export default ProductCollection
