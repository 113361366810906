import { gql } from '@apollo/client'

const ONE_OFF_BOX_QUERY = gql`
  query oneOffBoxQuery {
    user {
      __typename
      id
      shippingCountryCode
      preferredLanguage
      dogs {
        name
        gender
      }
      address {
        address1
        address2
        city
        postcode
        recipientName
        deliveryNotes
        deliveryCarrier
      }
      subscription {
        plan {
          pouchSize
          numberOfPouches
          durationInDays
          pouchesPerDay
        }
        meal {
          quantity
          flavour {
            slug
            thumbnail {
              src
            }
            themeColour
            name
            recipeSurcharge
            productLabels {
              name
              themeColour
            }
          }
        }
        previewUncreatedFutureBox(withDiscounts: false) {
          isoDeliveryDate
          editable
          cutOffDate
          order {
            deliveryFee
            physicalProductsTotal
            planTotal
            planTotalPerDay
            subtotal
            totalPrice
            surchargeTotal
            physicalOrderProducts {
              quantity
              price
              frequency
              requiresPayment
              nonPaymentReason
              discountedPrice
              productVariant {
                subscribeAndSaveable
                name
                grossPrice
                oneOffDeliveryType {
                  netPrice
                }
                recurringDeliveryType {
                  netPrice
                }
                productCollection {
                  name
                  thumbnail {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
    shouldSeeRecipeSurchargeTooltips: featureFlag(
      flag: "should_see_recipe_surcharge_tooltips"
      fallback: "false"
    )
    shouldSeeRecipeSurchargePriceIncreasePl: featureFlag(
      flag: "should_see_recipe_surcharge_price_increase_pl"
      fallback: "false"
    )
  }
`

const CALENDAR_DATES_QUERY = gql`
  query calendarDatesQuery($userId: ID!, $nDays: Int) {
    calendarDates(nDays: $nDays, userId: $userId) {
      date
      deliverable
    }
  }
`

const ONE_OFF_BOX_CREATE = gql`
  mutation OneOffBoxesCreate(
    $userId: ID!
    $deliveryDate: ISO8601Date!
    $address: AddressInput!
  ) {
    oneOffBoxCreate(
      userId: $userId
      deliveryDate: $deliveryDate
      address: $address
    ) {
      id
    }
  }
`

export { ONE_OFF_BOX_QUERY, CALENDAR_DATES_QUERY, ONE_OFF_BOX_CREATE }
