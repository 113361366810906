// @noflow
import React from 'react'

import {
  ICON_COLOURS,
  IconColours
} from '@/components/elements/atoms/Icon/Icon'

type Props = {
  size: number
  accentColour: IconColours
  width?: number | string
}

const Facebook = ({ size, accentColour, width }: Props): JSX.Element => {
  return (
    <svg
      viewBox="0 0 72 72"
      height={size}
      width={width || '100%'}
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M68 0H4a4 4 0 00-4 4v64a4 4 0 004 4h34.46V44.16h-9.35v-10.9h9.35v-8c0-9.3 5.68-14.36 14-14.36a77.46 77.46 0 018.38.43V21h-5.76c-4.5 0-5.37 2.14-5.37 5.29v6.94H60.5l-1.4 10.9h-9.39V72H68a4 4 0 004-4V4a4 4 0 00-4-4z"
        fill={ICON_COLOURS[accentColour]}
      />
    </svg>
  )
}

export default Facebook
