// @noflow
import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import type { Dispatch } from 'redux'

import CurlyArrow from 'assets/images/icons/arrows/dashed-squiggle-arrow--blue-500.svg'

import Image from '@/components/elements/atoms/Image/Image'
import Select from '@/components/shared/elements/Select'

import * as ACTIONS from '../../actions'
import type { State } from '../../reducers'
import type { State as FormState } from '../../reducers/formDataReducer'
import type { State as RequestsState } from '../../reducers/requestsReducer'
import Queries, { options, queryCodes } from './query_type/queries'

type PresentationalProps = {
  queryType: FormState['queryType']
  submitContactForm: RequestsState['submitContactForm']
}

type ActionProps = {
  updateQueryType: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

type Props = PresentationalProps & ActionProps

const mapStateToProps = ({
  formData,
  requests
}: State): PresentationalProps => {
  const { queryType } = formData
  const { submitContactForm } = requests

  return {
    queryType,
    submitContactForm
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => {
  const updateQueryType = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const newQueryType = e.currentTarget.value
    // Prevent updating the queryType to the unselectable placeholder
    if (newQueryType !== 'dropdown_default_query') {
      dispatch(
        ACTIONS.updateField('queryType', {
          value: e.currentTarget.value,
          interaction: 'InteractedWithSuccess'
        })
      )
      switch (newQueryType) {
        default: {
          dispatch(ACTIONS.setFormType('generic-form'))
        }
      }

      dispatch(ACTIONS.setFormViewingStep('Fill Out Form'))
    }
  }

  return {
    updateQueryType
  }
}

const SubmitButton = ({
  queryType,
  submitContactForm,
  updateQueryType
}: Props): React.ReactElement => {
  const copyContext = 'query_type'
  const { t } = useTranslation('contacts')
  const queryTypeElement = (
    queryType: FormState['queryType']
  ): React.ReactElement<'div'> => {
    if (
      queryType.interaction === 'NotInteracted' ||
      queryType.value === t(`${copyContext}.default_option_query`)
    ) {
      return (
        <div className="select-helper-text">
          <p>{t(`${copyContext}.help_query`)}</p>
          <img alt="" src={CurlyArrow} />
        </div>
      )
    } else if (
      queryType.value !== 'other_queries' &&
      queryType.value !== 'delivery_help'
    ) {
      return (
        <div className="contact-us__form__answer">
          <div className="contact-us__form__answer__content">
            {/*  eslint-disable-next-line array-callback-return */}
            {queryCodes.map((queryCode) => {
              if (queryCode === queryType.value) {
                return <Queries code={queryCode} key={queryCode} />
              }
            })}
          </div>
          {/**
           * TODO:
           * Replace this <div> with the <Expert /> component that we have
           * use in other products
           */}
          <div className="contact-us__form__answer__author">
            <Image
              alt=""
              slug="keisha-square"
              image={{ width: 66, height: 66 }}
            />
            <div>
              <p>{t(`${copyContext}.customer_love_team`)}</p>
              <small>{t(`${copyContext}.customer_love_team_caption`)}</small>
            </div>
          </div>
        </div>
      )
    }
    return <div />
  }
  return (
    <React.Fragment>
      <Select
        className=""
        defaultValue={t(`${copyContext}.default_option_query`)}
        disabled={submitContactForm.status === 'In Flight'}
        onBlur={updateQueryType}
        options={options(t)}
        value={queryType.value}
      />
      {queryTypeElement(queryType)}
    </React.Fragment>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SubmitButton)
