import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { CustomerIssueCategorySubmission_response_reasons as Reason } from '../../../mutations/__generated__/CustomerIssueCategorySubmission'

import useSelfResolutionTracking from '../../../analytics'
import DropDownField from './DropdownField'

type ReasonFieldProps = {
  namespace: string
  reason: string
  reasons: Array<Reason> | null
  setReason: (reason: string) => void
}

const ReasonField = ({
  namespace,
  reason,
  reasons,
  setReason
}: ReasonFieldProps): JSX.Element | null => {
  const sortedReasons = useMemo(() => {
    return (
      reasons?.sort((a, b) => a.presentationalOrder - b.presentationalOrder) ||
      []
    )
  }, [reasons])
  const selfResolutionTracking = useSelfResolutionTracking()
  const { t } = useTranslation(namespace)

  const trackChange = useCallback(
    (id: string) => {
      const reasonName = reasons?.find((reason) => reason.id === id)?.name || ''
      selfResolutionTracking.trackEvent('reason changed', {
        reason: reasonName
      })
    },
    [reasons, selfResolutionTracking]
  )

  const onChange = useCallback(
    (key: string, reason: number) => {
      const reasonId = reason + ''
      trackChange(reasonId)
      setReason(reasonId)
    },
    [setReason, trackChange]
  )

  if (sortedReasons.length === 0) {
    return null
  }
  return (
    <DropDownField
      id={'reasons'}
      value={reason}
      placeholder={t('reportIssue.chooseTopic')}
      options={sortedReasons}
      setValue={onChange}
    />
  )
}

export default ReasonField
