import React from 'react'

import STYLES from './LabelList.module.sass'

type Alignment = 'left' | 'center' | 'right'

type Props = {
  children: JSX.Element | Array<JSX.Element | false | undefined>
  alignment?: Alignment
}

const LabelList = ({ children, alignment }: Props): JSX.Element => {
  return (
    <div
      className={`${STYLES.container} ${
        STYLES[alignment as keyof typeof STYLES]
      }`}
    >
      {children}
    </div>
  )
}

export { Props }
export default LabelList
