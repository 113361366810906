// @noflow
import { Code } from '@/types'

const CALENDLY_BASE_URL = 'https://calendly.com'

const SOCIAL_MEDIA_BASE_URLS = {
  TIK_TOK: 'https://www.tiktok.com',
  TWITTER: 'https://twitter.com',
  INSTAGRAM: 'https://www.instagram.com',
  FACEBOOK: 'https://www.facebook.com',
  YOUTUBE: 'https://www.youtube.com'
}

const DEFAULT_PATHNAMES = {
  PRIVACY_POLICY: 'privacy-policy',
  OUR_STORY: 'our_story',
  BLOG: 'blog',
  REVIEWS: 'reviews',
  CONTACT: 'contact',
  CHARITY: 'charity-page',
  MAKING_OUR_MEALS: 'making_our_meals',
  TERMS_OF_USE: 'terms-of-use',
  TERMS_AND_CONDITIONS: 'terms-and-conditions',
  HELP_CENTER: 'en',
  TIK_TOK: '@butternutbox',
  TWITTER: 'ButternutBox',
  INSTAGRAM: 'butternutbox',
  FACEBOOK: 'ButternutBox',
  YOUTUBE: 'c/butternutbox',
  WIZARD: 'wizard/new'
}

const PATHNAMES = Object.values(Code).reduce((acc, code) => {
  acc[code] = {
    ...DEFAULT_PATHNAMES
  }
  return acc
}, {} as Record<Code, typeof DEFAULT_PATHNAMES>)

// Country-specific overrides
PATHNAMES.NL.PRIVACY_POLICY = 'privacy-policy-nl'
PATHNAMES.BE.PRIVACY_POLICY = 'privacy-policy-be'
PATHNAMES.PL.PRIVACY_POLICY = 'privacy-policy-pl'

PATHNAMES.PL.TERMS_OF_USE = 'terms-of-use-pl'

PATHNAMES.PL.TERMS_AND_CONDITIONS = 'terms-and-conditions-pl'

PATHNAMES.PL.TWITTER = ''
PATHNAMES.PL.TIK_TOK = '@psibufet'
PATHNAMES.PL.YOUTUBE = ''

PATHNAMES.NL.HELP_CENTER = 'nl'
PATHNAMES.BE.HELP_CENTER = 'nl'
PATHNAMES.PL.HELP_CENTER = 'pl'

PATHNAMES.NL.INSTAGRAM = 'butternutbox.nl'
PATHNAMES.BE.INSTAGRAM = 'butternutbox.be'
PATHNAMES.DE.INSTAGRAM = 'butternutbox.de'
PATHNAMES.PL.INSTAGRAM = 'psibufet'

PATHNAMES.IE.FACEBOOK = 'ButternutBox.ie'
PATHNAMES.NL.FACEBOOK = 'ButternutBox.nl'
PATHNAMES.BE.FACEBOOK = 'ButternutBox.be'
PATHNAMES.DE.FACEBOOK = 'profile.php?id=61556277572237'
PATHNAMES.PL.FACEBOOK = 'psibufet'

export { CALENDLY_BASE_URL, PATHNAMES, SOCIAL_MEDIA_BASE_URLS }
