import { ACCOUNT_ROUTES } from '@/routes'
import { useNavigate } from 'react-router-dom'

const useGoBack = (): ((defaultRoute?: string) => void) => {
  const navigate = useNavigate()

  return (defaultRoute: string = ACCOUNT_ROUTES.base) => {
    if (
      document.referrer === '' ||
      document.referrer === document.URL ||
      !document.referrer.includes(window.location.host)
    ) {
      navigate(defaultRoute, { replace: true })
    } else {
      navigate(-1)
    }
  }
}

export { useGoBack }
