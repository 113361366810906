// @flow

import * as React from 'react'
import MessageBannerContainer from '../containers/user_details/MessageBannerContainer'
import SearchBar from './SearchBar'
import SearchResultsTable from './SearchResultsTable'
import UserDetailsContainer from '../containers/UserDetailsContainer'

import type { ComponentProps } from '../containers/SingleCustomerViewIndexContainer'
import type { State } from '../index'

class SingleCustomerViewIndex extends React.Component<ComponentProps> {
  componentDidMount (): void {
    const {
      searchResults,
      searchParams,
      dispatchMakeNewSearch,
      dispatchRespondToBrowserNavigation,
      dispatchClearSearchResults
    } = this.props
    // The below Flow error is a known issue:
    // - https://github.com/facebook/flow/issues/2221
    //
    // The `Object#entries` method returns values of `Array<mixed` regardless of
    // what the actual types are of the elements of the array. Even though a
    // `SearchParams` has values which are all of the type `string`, Flow
    // assumes that they are of a mixed type.
    //
    // It is in the pipeline that the `Object#entries` type signature will be
    // updated to actually look at the types of the object that is being acted
    // upon in a later release of Flow but for now we must ignore this error
    // $FlowFixMe
    const performSearch = Object.entries(searchParams).some(([k,v]: [string, mixed]): boolean => {
      switch (k) {
        case ('accountStatus'): { return v !== 'not-selected' }
        case ('shippingCountryCode'): { return v !== 'not-selected' }
      default: { return v !== '' }
      }
    })

    if (searchResults.length === 0 && performSearch) {
      dispatchMakeNewSearch(searchParams)
    }

    window.onpopstate = (e: {| state: State |}): void => {
      const { state } = e
      const { isSearching, subscriptionDetails, searchParams } = state
      const { id } = subscriptionDetails
      dispatchRespondToBrowserNavigation(state)
      if (isSearching) {
        dispatchMakeNewSearch(searchParams)
      } else if (id > -1) {
        dispatchClearSearchResults()
      }
    }
  }

  render = (): React.Node => (
    <div className='single-customer-view'>
      <MessageBannerContainer />
      <div className='single-customer-view__main-container'>
        <SearchBar />
        <SearchResultsTable />
        <UserDetailsContainer />
      </div>
    </div>
  )
}

export default SingleCustomerViewIndex
