import React from 'react'

import QuantitySelector from '@/components/elements/atoms/QuantitySelector/QuantitySelector'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from '../../InputsScreen.module.sass'

import { CustomerIssueManagementInputInput } from '@/types'

type StepperProps = {
  text: string
  subtitle?: string
  maxValue?: number
  minValue?: number
  input: CustomerIssueManagementInputInput
  setValue: (quantity: number) => void
}

const Stepper = ({
  text,
  subtitle,
  maxValue,
  minValue = 0,
  input,
  setValue
}: StepperProps): JSX.Element => {
  const onQuantityChange = React.useCallback(
    (quantity: number) => {
      setValue(quantity)
    },
    [setValue]
  )

  return (
    <div className={STYLES.quantitySelector}>
      <Text
        variant={'display16'}
        text={text}
        translate={false}
        margin={false}
      />
      {subtitle && <Text text={subtitle} translate={false} margin={false} />}
      <QuantitySelector
        quantity={(input?.integerValue as number) || 0}
        minValue={minValue}
        maxValue={maxValue}
        onChange={onQuantityChange}
        identifier="stepper"
      />
    </div>
  )
}

export default Stepper
