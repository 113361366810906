// @flow

type Activity =
  | 'walking'
  | 'cuddling'
  | 'making_friends'
  | 'doing_tricks'

const activities: Array<Activity> = [
  'walking',
  'cuddling',
  'making_friends',
  'doing_tricks'
]

export type { Activity }

export { activities }
