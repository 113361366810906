// @noflow
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  SectionWrapper,
  SectionWrapperProps
} from '@/components/elements/atoms/SectionWrapper'
import SkeletonButton from '@/components/elements/atoms/SkeletonButton/SkeletonButton'
import { TextInput } from '@/components/elements/atoms/TextInput'

type Props = {
  weightInKg: number
  onWeightChange: (value: string) => void
  loading?: boolean
  margin?: SectionWrapperProps['margin']
}

const Weight = ({
  weightInKg,
  margin = { top: 8, bottom: 0 },
  onWeightChange,
  loading = false
}: Props): JSX.Element => {
  const { t } = useTranslation('account')

  if (loading) {
    return (
      <SectionWrapper margin={margin}>
        <SkeletonButton width="100%" height={60} margin="0" />
      </SectionWrapper>
    )
  }

  return (
    <SectionWrapper margin={margin}>
      <TextInput
        initialValue={weightInKg.toString()}
        type="number"
        onChange={onWeightChange}
        loading={loading}
        placeholder="0"
        validationRules={[
          {
            validate: (value) => value.length > 0 && parseInt(value) > 0,
            errorMessage: t(
              'update_dog_profile_weight_and_appetite.weight.input_error_message'
            )
          }
        ]}
      />
    </SectionWrapper>
  )
}

export { Weight }
