// @noflow
// Lib
import React from 'react'

import BRAND_COLOURS from '@/constants/BrandColours'
import SUPPORT_COLOURS from '@/constants/SupportColours'

import type { StarTypes } from '@/components/elements/atoms/Icon/Icons/Star'

import { BRAND_ASSETS } from './brandAssets'

type IconColours =
  | keyof typeof BRAND_COLOURS
  | keyof typeof SUPPORT_COLOURS
  | 'transparent'

type Direction =
  | 'left'
  | 'right'
  | 'down'
  | 'up'
  | 'topRight'
  | 'topLeft'
  | 'bottomRight'

type Props = {
  size: number
  accentColour?: IconColours
  asset: keyof typeof BRAND_ASSETS
  backgroundColour?: IconColours | 'transparent' | 'transparent'
  starType?: StarTypes
  direction?: Direction
  responsive?: boolean
  /**
   * Issue: 100% width on iOS devices adds unnecessary paddings on the image sides.
   * In case when we embed image to the button it stretches the button.
   *
   * Fix: add fixed width value for the icon.
   */
  width?: number | string
}

const ICON_COLOURS = {
  ...BRAND_COLOURS,
  ...SUPPORT_COLOURS,
  transparent: 'transparent'
}

const Icon = ({
  size,
  accentColour = 'brandBlue500',
  asset,
  width,
  backgroundColour,
  starType,
  direction,
  responsive
}: Props): JSX.Element => {
  const DynamicAsset = BRAND_ASSETS[asset]
  return (
    <DynamicAsset
      size={size}
      width={width}
      accentColour={accentColour}
      backgroundColour={backgroundColour}
      starType={starType}
      direction={direction}
      responsive={responsive}
    />
  )
}

export { Props, IconColours, Direction, ICON_COLOURS }
export default Icon
