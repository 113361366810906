import { useMutation, useReactiveVar } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import { useCallback, useEffect, useState } from 'react'

// Mutations
import { CUSTOMER_ISSUE_ENTRY_INPUTS_CREATE } from '../mutations/CustomerIssueEntryInputsCreate'

import {
  CustomerIssueEntryInputsCreate,
  CustomerIssueEntryInputsCreate_response as Response
} from '../mutations/__generated__/CustomerIssueEntryInputsCreate'
import {
  CustomerIssueManagementInputInput,
  CustomerIssueManagementReporterable
} from '@/types'

import {
  customerIssueEntryInputsState,
  customerIssueManagementState,
  customerIssueReportState,
  errorState,
  pageLoadingState,
  routeState
} from '../CustomerIssueManagementPage'
import CustomerIssueManagementRoutes from '../types/routes'

type CustomerIssueEntryInputsCreateMutationHook = {
  submit: (
    inputs: Array<CustomerIssueManagementInputInput>,
    reasonId?: string
  ) => void
}

const useCustomerIssueEntryInputsCreateMutation =
  (): CustomerIssueEntryInputsCreateMutationHook => {
    const customerIssueManagementData = useReactiveVar(
      customerIssueManagementState
    )
    const customerIssueReportData = useReactiveVar(customerIssueReportState)
    const customerIssueEntryInputsData = useReactiveVar(
      customerIssueEntryInputsState
    )
    const [dataRetrieved, setDataRetrieved] = useState<Response | null>(null)

    const [
      createEntryInputs,
      {
        loading: customerIssueEntryInputsCreateLoading,
        data: customerIssueEntryInputsCreateData,
        error: customerIssueEntryInputsCreateError
      }
    ] = useMutation<CustomerIssueEntryInputsCreate>(
      CUSTOMER_ISSUE_ENTRY_INPUTS_CREATE
    )

    const submit = useCallback(
      (inputs: Array<CustomerIssueManagementInputInput>, reasonId?: string) => {
        const variables = {
          reporterableType: customerIssueManagementData.reporterable
            .type as CustomerIssueManagementReporterable,
          reporterableId: customerIssueManagementData.reporterable.id,
          entryId: customerIssueReportData.entryId,
          reasonId: reasonId || null,
          inputs
        }
        customerIssueManagementState({
          ...customerIssueManagementData,
          inputValues: inputs
        })
        createEntryInputs({ variables })
      },
      [
        customerIssueManagementData,
        customerIssueReportData.entryId,
        createEntryInputs
      ]
    )

    useEffect(() => {
      pageLoadingState(customerIssueEntryInputsCreateLoading)
    }, [customerIssueEntryInputsCreateLoading])

    useEffect(() => {
      if (customerIssueEntryInputsCreateError) {
        Sentry.captureException(
          `Error submitting customerIssueEntryInputsCreate`
        )
        errorState(!!customerIssueEntryInputsCreateError)
        routeState(CustomerIssueManagementRoutes.Error)
      }
    }, [customerIssueEntryInputsCreateError])

    useEffect(() => {
      if (
        customerIssueEntryInputsCreateData?.response &&
        dataRetrieved !== customerIssueEntryInputsCreateData.response
      ) {
        setDataRetrieved(customerIssueEntryInputsCreateData.response)
        customerIssueEntryInputsState({
          ...customerIssueEntryInputsData,
          ...customerIssueEntryInputsCreateData.response
        })
        if (customerIssueEntryInputsCreateData?.response?.resolutionOffering) {
          routeState(CustomerIssueManagementRoutes.ResolutionOffering)
        } else {
          routeState(CustomerIssueManagementRoutes.ContactCustomerLove)
        }
      }
    }, [
      customerIssueEntryInputsData,
      customerIssueEntryInputsCreateData,
      dataRetrieved
    ])

    return {
      submit
    }
  }

export default useCustomerIssueEntryInputsCreateMutation
