import { StripeCardElementChangeEvent } from '@stripe/stripe-js'
import React, { useCallback, useState } from 'react'

import DetailsCard from '@/components/elements/molecules/DetailsCard/DetailsCard'
import { abstractStripeError } from '@/components/pages/CheckoutPage/helpers/errors'

import { InputPaymentMethod } from '@/types'

import PaymentMethodModal from '../PaymentMethodModal/PaymentMethodModal'

type Props = {
  inlineErrorMessage: string | null
  disableButton: boolean
  setActivePayment: (active: boolean) => void
  setInlineErrorMessage: (message: string | null) => void
  setDisableButton: (disable: boolean) => void
  onSubmit: () => void
}

const CreditCardCard = ({
  inlineErrorMessage,
  disableButton,
  setActivePayment,
  setInlineErrorMessage,
  setDisableButton,
  onSubmit
}: Props): JSX.Element => {
  const [openNewPaymentMethod, setOpenNewPaymentMethod] = useState(false)
  const toggleAddPaymentMethodModal = useCallback(() => {
    setOpenNewPaymentMethod(!openNewPaymentMethod)
  }, [openNewPaymentMethod])

  const handleSetActivePayment = useCallback(
    (id: number, checked: boolean) => {
      setActivePayment(checked)
    },
    [setActivePayment]
  )

  const onCardElementChange = React.useCallback(
    (event: StripeCardElementChangeEvent) => {
      if (event.error !== undefined) {
        const errorMessage = abstractStripeError(event.error)

        setInlineErrorMessage(errorMessage)
        setDisableButton(true)
      }

      if (event.complete) {
        setDisableButton(false)
        setInlineErrorMessage(null)
      }
    },
    [setDisableButton, setInlineErrorMessage]
  )

  return (
    <>
      <DetailsCard
        text={{ text: 'my_details.payment_methods.method_names.card' }}
        onClick={toggleAddPaymentMethodModal}
        icon={'paymentMethods'}
        namespace={'dashboard'}
        identifier="payment_methods.card"
      />
      <PaymentMethodModal
        isOpen={openNewPaymentMethod}
        toggle={toggleAddPaymentMethodModal}
        inlineErrorMessage={inlineErrorMessage}
        onIsActiveChange={handleSetActivePayment}
        onSubmit={onSubmit}
        onCardElementChange={onCardElementChange}
        paymentMethodType={InputPaymentMethod.credit_card}
        disableButton={disableButton}
      />
    </>
  )
}

export { Props }
export default CreditCardCard
