import {
  useCsrfToken,
  useStripe
} from '@/context/injectedValues/injectedValues'
import { useQuery } from '@apollo/client'
import { captureException } from '@sentry/browser'
import { Elements as StripeElements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React from 'react'

import { localeToStripeLocale } from '@/utils/localeToStripeLocale'

import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import ErrorPage from '@/components/pages/ErrorPage/ErrorPage'

import ADD_PAYMENT_METHOD_QUERY from './queries/AddPaymentMethodQuery'

import { AddPaymentMethodQuery } from './queries/__generated__/AddPaymentMethodQuery'

import AddPaymentMethod from './AddPaymentMethod'
import AddPaymentMethodSkeleton from './AddPaymentMethodSkeleton'

const AddPaymentMethodPage = (): JSX.Element | null => {
  const { csrfToken } = useCsrfToken()
  const { key } = useStripe()

  const { data, loading, error } = useQuery<AddPaymentMethodQuery>(
    ADD_PAYMENT_METHOD_QUERY,
    {
      onError: (error) => {
        captureException('AddPaymentMethodQuery failed', {
          extra: {
            error
          },
          tags: {
            component: 'AddPaymentMethodPage'
          }
        })
      }
    }
  )

  // TODO: Set available payment methods from ShippingCountryCode
  if (loading) return <AddPaymentMethodSkeleton amountOfSkeletons={2} />
  if (error || !data || !csrfToken || !key) {
    captureException('AddPaymentMethodPage did not load', {
      tags: {
        component: 'AddPaymentMethodPage'
      }
    })
    switch (true) {
      case !csrfToken:
        return <ErrorPage error="Add payment method: CSRF token not found" />
      case !key:
        return <ErrorPage error="Add payment method: Stripe key not found" />
      case !data:
        return <ErrorPage error="Add payment method: Data not found" />
      default:
        return <ErrorPage error={error} />
    }
  }

  const stripePromise = loadStripe(key) // stripePublishableKey
  const { user } = data
  const { affiliateType, shippingCountryCode } = user

  return (
    <SectionWrapper
      headerTypography={{
        text: 'titles.add_payment_method',
        namespace: 'account'
      }}
      margin={{ top: 0, bottom: 0 }}
    >
      <StripeElements
        stripe={stripePromise}
        options={{
          locale: localeToStripeLocale(user.preferredLanguage)
        }}
      >
        <AddPaymentMethod
          user={user}
          affiliateType={affiliateType}
          shippingCountryCode={shippingCountryCode}
          csrfToken={csrfToken}
        />
      </StripeElements>
    </SectionWrapper>
  )
}

export default AddPaymentMethodPage
