// @noflow
import { useReactiveVar } from '@apollo/client'
import i18next from 'i18next'
import React, { MouseEvent, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { pronounContext } from '@/utils/StringHelper'

import cavapooBundleofenergy from 'assets/images/illustrations/dogs/activity-level/cavapoo-bundle-of-energy.svg'
import cavapooFairlyactive from 'assets/images/illustrations/dogs/activity-level/cavapoo-fairly-active.svg'
import cavapooHyperactive from 'assets/images/illustrations/dogs/activity-level/cavapoo-hyperactive.svg'
import cavapooLazyBones from 'assets/images/illustrations/dogs/activity-level/cavapoo-lazy-bones.svg'
import cavapooLeisurely from 'assets/images/illustrations/dogs/activity-level/cavapoo-leisurely.svg'
import normalBundleofenergy from 'assets/images/illustrations/dogs/activity-level/normal-bundle-of-energy.svg'
import normalFairlyactive from 'assets/images/illustrations/dogs/activity-level/normal-fairly-active.svg'
import normalHyperactive from 'assets/images/illustrations/dogs/activity-level/normal-hyperactive.svg'
import normalLazyBones from 'assets/images/illustrations/dogs/activity-level/normal-lazy-bones.svg'
import normalLeisurely from 'assets/images/illustrations/dogs/activity-level/normal-leisurely.svg'
import puppyBundleOfEnergy from 'assets/images/illustrations/dogs/activity-level/puppy-bundle-of-energy.svg'
import puppyFairlyactive from 'assets/images/illustrations/dogs/activity-level/puppy-fairly-active.svg'
import puppyLeisurely from 'assets/images/illustrations/dogs/activity-level/puppy-leisurely.svg'

import Text from '@/components/elements/atoms/Text/Text'
import TextSeparator from '@/components/elements/molecules/TextSeparator/TextSeparator'
import StickyNavigation from '@/components/elements/organisms/StickyNavigation/StickyNavigation'
import {
  routeToNextStep,
  routeToPrevStep
} from '@/components/pages/SignupWizardPage/helpers/wizardRoutes'
import type { Route } from '@/components/pages/SignupWizardPage/types/routes'

import STYLES from './Activity.module.sass'

import { Gender } from '@/types'

import { wizardPageState } from '../../SignupWizardPage'
import useImplicitFormSubmission from '../../helpers/useImplicitFormSubmission'
import { Dog, WizardActivityLevel } from '../../types/types'

type Props = {
  variant?: keyof typeof STYLES
  namespace: string
}

type ActivityRowProps = {
  dog: Dog
  namespace: string
  index: number
  handleClick: (event: MouseEvent | KeyboardEvent, index: number) => void
  areDogsCavapoos: boolean
}

type ActivityDescriptionProps = {
  dog: Dog
  namespace: string
}

const copyContext = 'activity_step'

const ActivityRow = ({
  dog,
  handleClick,
  namespace,
  areDogsCavapoos,
  index
}: ActivityRowProps): JSX.Element => {
  const { t } = useTranslation(namespace)
  const { activityLevel, ageStage } = dog
  const dogAgeStage = ageStage === 'puppy' ? 'puppy' : 'adult'

  return (
    <div className={STYLES.activityLevelRow}>
      {dogAgeStage === 'puppy' && (
        <>
          <button
            className={
              activityLevel === 'leisurely'
                ? `${STYLES.activityLevelItem} ${STYLES.selected}`
                : `${STYLES.activityLevelItem}`
            }
            type="button"
            id={'leisurely'}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={(e) => handleClick(e, index)}
          >
            <img
              className={STYLES.defaultImage}
              alt={t(`${copyContext}.${dogAgeStage}.leisurely.img_alt`)}
              src={puppyLeisurely}
            />
            <img
              className={STYLES.selectedImage}
              alt={t(`${copyContext}.${dogAgeStage}.leisurely.img_alt`)}
              src={puppyLeisurely}
            />
          </button>
          <button
            className={
              activityLevel === 'fairlyActive'
                ? `${STYLES.activityLevelItem} ${STYLES.selected}`
                : `${STYLES.activityLevelItem}`
            }
            type="button"
            id={'fairlyActive'}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={(e) => handleClick(e, index)}
          >
            <img
              className={STYLES.defaultImage}
              alt={t(`${copyContext}.${dogAgeStage}.fairlyactive.img_alt`)}
              src={puppyFairlyactive}
            />
            <img
              className={STYLES.selectedImage}
              alt={t(`${copyContext}.${dogAgeStage}.fairlyactive.img_alt`)}
              src={puppyFairlyactive}
            />
          </button>
          <button
            className={
              activityLevel === 'bundleOfEnergy'
                ? `${STYLES.activityLevelItem} ${STYLES.selected}`
                : `${STYLES.activityLevelItem}`
            }
            type="button"
            id={'bundleOfEnergy'}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={(e) => handleClick(e, index)}
          >
            <img
              className={STYLES.defaultImage}
              alt={t(`${copyContext}.${dogAgeStage}.bundleofenergy.img_alt`)}
              src={puppyBundleOfEnergy}
            />
            <img
              className={STYLES.selectedImage}
              alt={t(`${copyContext}.${dogAgeStage}.bundleofenergy.img_alt`)}
              src={puppyBundleOfEnergy}
            />
          </button>
        </>
      )}
      {dogAgeStage !== 'puppy' && (
        <>
          <button
            className={
              activityLevel === 'lazy'
                ? `${STYLES.activityLevelItem} ${STYLES.selected}`
                : `${STYLES.activityLevelItem}`
            }
            type="button"
            id={'lazy'}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={(e) => handleClick(e, index)}
          >
            <img
              className={STYLES.defaultImage}
              alt={t(`${copyContext}.${dogAgeStage}.lazy.img_alt`)}
              src={areDogsCavapoos ? cavapooLazyBones : normalLazyBones}
            />
            <img
              className={STYLES.selectedImage}
              alt={t(`${copyContext}.${dogAgeStage}.lazy.img_alt`)}
              src={areDogsCavapoos ? cavapooLazyBones : normalLazyBones}
            />
          </button>
          <button
            className={
              activityLevel === 'leisurely'
                ? `${STYLES.activityLevelItem} ${STYLES.selected}`
                : `${STYLES.activityLevelItem}`
            }
            type="button"
            id={'leisurely'}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={(e) => handleClick(e, index)}
          >
            <img
              className={STYLES.defaultImage}
              alt={t(`${copyContext}.${dogAgeStage}.leisurely.img_alt`)}
              src={areDogsCavapoos ? cavapooLeisurely : normalLeisurely}
            />
            <img
              className={STYLES.selectedImage}
              alt={t(`${copyContext}.${dogAgeStage}.leisurely.img_alt`)}
              src={areDogsCavapoos ? cavapooLeisurely : normalLeisurely}
            />
          </button>
          <button
            className={
              activityLevel === 'fairlyActive'
                ? `${STYLES.activityLevelItem} ${STYLES.selected}`
                : `${STYLES.activityLevelItem}`
            }
            type="button"
            id={'fairlyActive'}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={(e) => handleClick(e, index)}
          >
            <img
              className={STYLES.defaultImage}
              alt={t(`${copyContext}.${dogAgeStage}.fairlyactive.img_alt`)}
              src={areDogsCavapoos ? cavapooFairlyactive : normalFairlyactive}
            />
            <img
              className={STYLES.selectedImage}
              alt={t(`${copyContext}.${dogAgeStage}.fairlyactive.img_alt`)}
              src={areDogsCavapoos ? cavapooFairlyactive : normalFairlyactive}
            />
          </button>
          <button
            className={
              activityLevel === 'bundleOfEnergy'
                ? `${STYLES.activityLevelItem} ${STYLES.selected}`
                : `${STYLES.activityLevelItem}`
            }
            type="button"
            id={'bundleOfEnergy'}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={(e) => handleClick(e, index)}
          >
            <img
              className={STYLES.defaultImage}
              alt={t(`${copyContext}.${dogAgeStage}.bundleofenergy.img_alt`)}
              src={
                areDogsCavapoos ? cavapooBundleofenergy : normalBundleofenergy
              }
            />
            <img
              className={STYLES.selectedImage}
              alt={t(`${copyContext}.${dogAgeStage}.bundleofenergy.img_alt`)}
              src={
                areDogsCavapoos ? cavapooBundleofenergy : normalBundleofenergy
              }
            />
          </button>
          <button
            className={
              activityLevel === 'hyperActive'
                ? `${STYLES.activityLevelItem} ${STYLES.selected}`
                : `${STYLES.activityLevelItem}`
            }
            type="button"
            id={'hyperActive'}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={(e) => handleClick(e, index)}
          >
            <img
              className={STYLES.defaultImage}
              alt={t(`${copyContext}.${dogAgeStage}.hyperactive.img_alt`)}
              src={areDogsCavapoos ? cavapooHyperactive : normalHyperactive}
            />
            <img
              className={STYLES.selectedImage}
              alt={t(`${copyContext}.${dogAgeStage}.hyperactive.img_alt`)}
              src={areDogsCavapoos ? cavapooHyperactive : normalHyperactive}
            />
          </button>
        </>
      )}
    </div>
  )
}

const ActivityDescription = ({
  dog,
  namespace
}: ActivityDescriptionProps): JSX.Element => {
  const { activityLevel, ageStage, gender } = dog
  const dogAgeStage = ageStage === 'puppy' ? 'puppy' : 'adult'
  switch (activityLevel) {
    case 'lazy':
      return (
        <div
          className={`${dogAgeStage === 'puppy' ? STYLES.puppy : STYLES.adult}`}
        >
          <div className={`${STYLES.activityLevelDescription} ${STYLES.lazy}`}>
            <Text
              variant={'textRegular16'}
              colour={'brandBlue500'}
              namespace={namespace}
              bold
              text={`${copyContext}.adult.lazy.title`}
            />
            <Text
              variant={'textRegular16'}
              colour={'brandBlue500'}
              namespace={namespace}
              text={`${copyContext}.adult.lazy.subtitle`}
            />
          </div>
        </div>
      )
    case 'leisurely':
      return (
        <div
          className={`${dogAgeStage === 'puppy' ? STYLES.puppy : STYLES.adult}`}
        >
          <div
            className={`${STYLES.activityLevelDescription} ${STYLES.leisurely}`}
          >
            <Text
              variant={'textRegular16'}
              colour={'brandBlue500'}
              namespace={namespace}
              bold
              text={`${copyContext}.${dogAgeStage}.leisurely.title`}
              variables={{
                context: pronounContext([gender as Gender], i18next.language)
              }}
            />
            <Text
              variant={'textRegular16'}
              colour={'brandBlue500'}
              namespace={namespace}
              text={`${copyContext}.${dogAgeStage}.leisurely.subtitle`}
            />
          </div>
        </div>
      )
    case 'fairlyActive':
      return (
        <div
          className={`${dogAgeStage === 'puppy' ? STYLES.puppy : STYLES.adult}`}
        >
          <div
            className={`${STYLES.activityLevelDescription} ${STYLES.fairlyactive}`}
          >
            <Text
              variant={'textRegular16'}
              colour={'brandBlue500'}
              namespace={namespace}
              bold
              text={`${copyContext}.${dogAgeStage}.fairlyactive.title`}
            />
            <Text
              variant={'textRegular16'}
              colour={'brandBlue500'}
              namespace={namespace}
              text={`${copyContext}.${dogAgeStage}.fairlyactive.subtitle`}
            />
          </div>
        </div>
      )
    case 'bundleOfEnergy':
      return (
        <div
          className={`${dogAgeStage === 'puppy' ? STYLES.puppy : STYLES.adult}`}
        >
          <div
            className={`${STYLES.activityLevelDescription} ${STYLES.bundleofenergy}`}
          >
            <Text
              variant={'textRegular16'}
              colour={'brandBlue500'}
              namespace={namespace}
              bold
              text={`${copyContext}.${dogAgeStage}.bundleofenergy.title`}
            />
            <Text
              variant={'textRegular16'}
              colour={'brandBlue500'}
              namespace={namespace}
              text={`${copyContext}.${dogAgeStage}.bundleofenergy.subtitle`}
            />
          </div>
        </div>
      )
    case 'hyperActive':
      return (
        <div
          className={`${dogAgeStage === 'puppy' ? STYLES.puppy : STYLES.adult}`}
        >
          <div
            className={`${STYLES.activityLevelDescription} ${STYLES.hyperactive}`}
          >
            <Text
              variant={'textRegular16'}
              colour={'brandBlue500'}
              namespace={namespace}
              bold
              text={`${copyContext}.adult.hyperactive.title`}
            />
            <Text
              variant={'textRegular16'}
              colour={'brandBlue500'}
              namespace={namespace}
              text={`${copyContext}.adult.hyperactive.subtitle`}
              variables={{
                context: pronounContext([gender as Gender], i18next.language)
              }}
            />
          </div>
        </div>
      )
    default: {
      throw new Error(`${activityLevel} is an invalid activityLevel`)
    }
  }
}

const Activity = ({ variant, namespace }: Props): JSX.Element => {
  const wizardState = useReactiveVar(wizardPageState)
  const { dogs } = wizardState
  const route = useLocation().pathname as Route

  const formIsValid = dogs.every(({ activityLevel }) => activityLevel !== null)
  const navigate = useNavigate()

  useEffect(() => {
    localStorage.setItem('wizardState', JSON.stringify(wizardState))
  }, [wizardState])

  const onEnterPress = useCallback(() => {
    navigate(routeToNextStep({ route }))
  }, [navigate, route])

  useImplicitFormSubmission({ formIsValid, onEnterPress })

  const handleClick = useCallback(
    (e, index): void => {
      dogs[index].activityLevel = e.currentTarget.id
      wizardPageState({
        ...wizardState,
        dogs
      })
    },
    [dogs, wizardState]
  )

  const handleWorkingDog = useCallback(
    (e, index): void => {
      if (e.currentTarget.checked) {
        dogs[index].activityLevel = WizardActivityLevel.HyperActive
        dogs[index].isWorkingDog = true
      } else {
        dogs[index].activityLevel = WizardActivityLevel.FairlyActive
        dogs[index].isWorkingDog = false
      }
      wizardPageState({
        ...wizardState,
        dogs
      })
    },
    [wizardState, dogs]
  )

  return (
    <>
      <div className={`${variant ? STYLES[variant] : ''}`}>
        <div className={STYLES.question}>
          {dogs.map((dog: Dog, index: number) => (
            <div className={STYLES.subQuestion} key={index.toString()}>
              <TextSeparator
                text={`${copyContext}.text_separator`}
                namespace={namespace}
                variables={{ dogName: dog.name }}
              />
              <div className={STYLES.checkboxWrapper} id="checkboxWrapper">
                <div className={STYLES.checkboxHover}>
                  <form>
                    <input
                      className={STYLES.checkbox}
                      type="checkbox"
                      name="workingdog"
                      id={`workingdog-input-${index}`}
                      data-testid="is-working-dog"
                      value="workingdog"
                      checked={dogs[index].isWorkingDog === true || false}
                      // eslint-disable-next-line react/jsx-no-bind
                      onChange={(e) => handleWorkingDog(e, index)}
                    />
                  </form>
                </div>
                <label
                  className={STYLES.checkboxLabel}
                  htmlFor={`workingdog-input-${index}`}
                >
                  <Text
                    variant="textRegular18"
                    shouldScale={false}
                    colour="brandBlue500"
                    element="p"
                    namespace={namespace}
                    text={`${copyContext}.working_dog`}
                  />
                </label>
              </div>

              {!dog.isWorkingDog && (
                <>
                  <ActivityRow
                    namespace={namespace}
                    dog={dog}
                    handleClick={handleClick}
                    index={index}
                    areDogsCavapoos
                  />
                  <ActivityDescription namespace={namespace} dog={dog} />
                </>
              )}
            </div>
          ))}
        </div>
      </div>
      <StickyNavigation
        variant="twoButtons"
        buttonOne={{
          url: routeToPrevStep({ route }),
          text: 'wizard_flow:sticky_navigation.back',
          variant: 'secondary',
          iconColour: 'brandRed400'
        }}
        buttonTwo={{
          dataTestId: 'next-button',
          url: routeToNextStep({ route }),
          text: 'wizard_flow:sticky_navigation.continue',
          variant: 'primary',
          iconColour: 'brandWhite'
        }}
        disabled={!formIsValid}
      />
    </>
  )
}

export { Props }
export default Activity
export { ActivityRow }
