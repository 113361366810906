// @flow

/* eslint-disable react/destructuring-assignment */

import * as React from 'react'

import CompleteBankAccount from './bank_account_form/CompleteBankAccount'
import EmptyBankAccount from './bank_account_form/EmptyBankAccount'
import EditingBankAccount from './bank_account_form/EditingBankAccount'
import PiggyBank from '../../../../../assets/images/illustrations/piggy-bank.svg'
import i18next from 'i18next'

import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'
import type { BankAccount } from '../../message_types'

type EmptyBankAccountView = {|
  name: 'EMPTY_BANK_ACCOUNT'
|}

type CompleteBankAccountView = {|
  name: 'COMPLETE_BANK_ACCOUNT',
  bankAccount: BankAccount
|}

type EditingBankAccountView = {|
  name: 'EDITING_BANK_ACCOUNT',
  bankAccount: BankAccount
|}

type BankAccountView =
  | EmptyBankAccountView
  | CompleteBankAccountView
  | EditingBankAccountView

type Props = {|
  balance: string,
  shippingCountryCode: CountryCode,
  bankAccount: ?BankAccount,
  persistBankAccount: (bankAccount: BankAccount) => void,
  deleteBankAccount: (id: number) => void,
|}

type State = {|
  currentView: BankAccountView,
  deleteAlertIsVisible: boolean
|}

class BankAccountForm extends React.Component<Props, State> {
  constructor (props: Props): void {
    super(props)
    const currentView = props.bankAccount ? (
      { name: 'COMPLETE_BANK_ACCOUNT', bankAccount: props.bankAccount }
    ) : (
      { name: 'EMPTY_BANK_ACCOUNT' }
    )
    this.state = {currentView, deleteAlertIsVisible: false}
  }

  namespace =  'ambassadors:dashboard.balance_tab'

  initialBankAccount = (): BankAccount => {
    if (this.props.shippingCountryCode === 'GB' || this.props.shippingCountryCode === 'NI') {
      return {
        id: null,
        account_holder_name: '',
        bank_account_type: 'uk',
        account_number: '',
        sort_code: ''
      }
    } else {
      return {
        id: null,
        account_holder_name: '',
        bank_account_type: 'international',
        iban: '',
        swift_code: ''
      }
    }
  }

  startEditing = (): void => {
    const bankAccount = this.state.currentView.bankAccount || this.initialBankAccount()
    const currentView = {
      name: 'EDITING_BANK_ACCOUNT',
      bankAccount
    }
    this.setState({...this.state, currentView})
  }

  cancelEdit = (): void => {
    const {bankAccount} = this.props
    if (!bankAccount) {
      const currentView = { name: 'EMPTY_BANK_ACCOUNT' }
      this.setState({...this.state, currentView})
    } else {
      const currentView = { name: 'COMPLETE_BANK_ACCOUNT', bankAccount }
      this.setState({...this.state, currentView})
    }
  }

  deleteBankAccount = (bankAccount: BankAccount): void => {
    if (bankAccount.id !== null && bankAccount.id !== undefined) {
      const currentView = { name: 'EMPTY_BANK_ACCOUNT' }
      this.props.deleteBankAccount(bankAccount.id)
      this.setState({deleteAlertIsVisible: true, currentView})
    }
  }

  persistBankAccount = (bankAccount: BankAccount): void => {
    const currentView = {
      name: 'COMPLETE_BANK_ACCOUNT',
      bankAccount
    }
    this.props.persistBankAccount(bankAccount)
    this.setState({...this.state, currentView})
  }

  cardContents = (): React.Node => {
    const {currentView} = this.state
    switch (currentView.name) {
      case 'EMPTY_BANK_ACCOUNT':
        return (
          <EmptyBankAccount
            shippingCountryCode={this.props.shippingCountryCode}
            startEditing={this.startEditing}
            deleteAlertIsVisible={this.state.deleteAlertIsVisible}
            onAlertDismiss={ (): void => { this.setState({...this.state, deleteAlertIsVisible: false}) }}
          />
        )
      case 'COMPLETE_BANK_ACCOUNT':
        return (
          <CompleteBankAccount
            bankAccount={currentView.bankAccount}
            startEditing={this.startEditing}
          />
        )

      case 'EDITING_BANK_ACCOUNT':
        return (
          <EditingBankAccount
            shippingCountryCode={this.props.shippingCountryCode}
            bankAccount={currentView.bankAccount}
            cancelEdit={this.cancelEdit}
            deleteBankAccount={this.deleteBankAccount}
            persistBankAccount={this.persistBankAccount}
          />
        )

      default:
        (currentView: empty) // eslint-disable-line no-unused-expressions
        return null
    }
  }

  render (): React.Node {
    return (
      <div className='ambassador-balance__bank-details'>
        <div className='ambassador-balance__bank-details__header'>
          <h2>{ i18next.t(`${this.namespace}.your_bank_account`) }</h2>

          <img src={PiggyBank} alt='Piggy Bank' />
          <p>{ i18next.t(`${this.namespace}.get_money_transferred`) }</p>

          <div className='ambassador-balance__bank-details__header__balance-notice'>
            <p className='no-margin--bottom'>
              <strong>{ this.props.balance } </strong>
              { i18next.t(`${this.namespace}.will_be_paid`) }<strong>{ i18next.t(`${this.namespace}.first_friday`) }</strong>
            </p>
          </div>
        </div>

        <div className='ambassador-balance__bank-details__content full--width'>
          { this.cardContents() }
        </div>
      </div>
    )
  }
}

export default BankAccountForm
