import { RefObject, useState } from 'react'

import useEventListener from '@/hooks/useEventListener/useEventListener'

/**
 * Track if an html element is focused
 * @example
 * import { useRef } from 'react'
 *
 * const Component = () => {
 *   const focusRef = useRef(null)
 *   const isFocus = useHover(focusRef)
 *
 *   return (
 *     <div ref={focusRef}>
 *       {`The current div is ${isFocus ? `focuses` : `blurred`}`}
 *     </div>
 *   )
 * }
 */
export function useFocus<T extends HTMLElement = HTMLElement>(
  elementRef: RefObject<T>
): boolean {
  const [value, setValue] = useState<boolean>(false)

  const handleFocus = () => setValue(true)
  const handleBlur = () => setValue(false)

  useEventListener('focus', handleFocus, elementRef)
  useEventListener('blur', handleBlur, elementRef)

  return value
}

export default useFocus
