// @flow

import * as TYPES from './types'
import * as MESSAGES from './messages'

import type { State as WizardStep } from '../reducers/wizardStepReducer'
import type { State as RequestReducerState } from '../reducers/requestsReducer'
import type { FavouriteGame } from '../../../shared_types/rails_models/dog_profile'
import type { Activity } from '../../../shared_types/rails_models/dog_activity'
import type { Trait } from '../../../shared_types/rails_models/dog_personality_trait'
import type { RailsModelID as ID } from '../../../shared_types/ids'

type SetWizardStep = {|
  wizardStep: WizardStep,
  type: TYPES.SET_WIZARD_STEP
|}

type NextWizardStep = {|
  type: TYPES.NEXT_WIZARD_STEP
|}

type PreviousWizardStep = {|
  type: TYPES.PREVIOUS_WIZARD_STEP
|}

/**
 * Request Reducer Action Types
 * The following actions are those actions that are to be handled by the Request
 * reducer
 */
type StartRequest = {|
  type: TYPES.START_REQUEST,
  name: $Keys<RequestReducerState>,
  dogId: ?ID
|}

type CompleteRequest = {|
  type: TYPES.COMPLETE_REQUEST,
  name: $Keys<RequestReducerState>,
  error?: string,
|}

type WizardStepReducerAction =
  | SetWizardStep
  | NextWizardStep
  | PreviousWizardStep

type RequestReducerAction =
  | StartRequest
  | CompleteRequest

type SelectFavouriteGame = {|
  type: TYPES.SELECT_FAVOURITE_GAME,
  id: ID,
  favouriteGame: FavouriteGame
|}

type ToggleActivity = {|
  type: TYPES.TOGGLE_ACTIVITY,
  id: ID,
  activity: Activity
|}

type ToggleTrait = {|
  type: TYPES.TOGGLE_TRAIT,
  id: ID,
  trait: Trait
|}

type UpdateDogDescription = {|
  type: TYPES.UPDATE_DOG_DESCRIPTION,
  id: ID,
  description: string
|}

type SetAvatarSrc = {|
  type: TYPES.SET_AVATAR_SRC,
  id: ID,
  src: string
|}

type DogProfilesReducerAction =
  | SelectFavouriteGame
  | ToggleActivity
  | ToggleTrait
  | UpdateDogDescription
  | SetAvatarSrc

type TrackableAction =
  | NextWizardStep
  | PreviousWizardStep
  | StartRequest
  | CompleteRequest
  | SelectFavouriteGame
  | ToggleActivity
  | ToggleTrait
  | UpdateDogDescription
  | SetAvatarSrc

const setWizardStep = (wizardStep: WizardStep): SetWizardStep => {
  const type: TYPES.SET_WIZARD_STEP = MESSAGES.SET_WIZARD_STEP
  return { type, wizardStep }
}

const nextWizardStep = (): NextWizardStep => {
  const type: TYPES.NEXT_WIZARD_STEP = MESSAGES.NEXT_WIZARD_STEP
  return { type }
}

const previousWizardStep = (): PreviousWizardStep => {
  const type: TYPES.PREVIOUS_WIZARD_STEP = MESSAGES.PREVIOUS_WIZARD_STEP
  return { type }
}

/**
 * Request Reducer Action Creators
 * The following action creators are those that are to be handled by the Request
 * reducer
 */
const startRequest = (name: $Keys<RequestReducerState>, dogId: ?ID): StartRequest => {
  const type: TYPES.START_REQUEST = MESSAGES.START_REQUEST
  return { type, name, dogId }
}

const completeRequest = (
  name: $Keys<RequestReducerState>,
  { error }: { error?: string }
): CompleteRequest => {
  const type: TYPES.COMPLETE_REQUEST = MESSAGES.COMPLETE_REQUEST
  return { type, name, error }
}

const selectFavouriteGame = (id: ID, favouriteGame: FavouriteGame): SelectFavouriteGame => {
  const type: TYPES.SELECT_FAVOURITE_GAME = MESSAGES.SELECT_FAVOURITE_GAME
  return { type, id, favouriteGame }
}

const toggleActivity = (id: ID, activity: Activity): ToggleActivity => {
  const type: TYPES.TOGGLE_ACTIVITY = MESSAGES.TOGGLE_ACTIVITY
  return { type, id, activity }
}

const toggleTrait = (id: ID, trait: Trait): ToggleTrait => {
  const type: TYPES.TOGGLE_TRAIT = MESSAGES.TOGGLE_TRAIT
  return { type, id, trait }
}

const updateDogDescription = (id: ID, description: string): UpdateDogDescription => {
  const type: TYPES.UPDATE_DOG_DESCRIPTION = MESSAGES.UPDATE_DOG_DESCRIPTION
  return { type, id, description }
}

const setAvatarSrc = (id: ID, src: string): SetAvatarSrc => {
  const type: TYPES.SET_AVATAR_SRC = MESSAGES.SET_AVATAR_SRC
  return { type, id, src }
}

export type {
  WizardStepReducerAction,
  RequestReducerAction,
  DogProfilesReducerAction,
  TrackableAction
}

export {
  setWizardStep,
  nextWizardStep,
  previousWizardStep,
  startRequest,
  completeRequest,
  selectFavouriteGame,
  toggleActivity,
  toggleTrait,
  updateDogDescription,
  setAvatarSrc
}
