// @noflow
import React, { useCallback } from 'react'

import { Button } from '@/components/elements/atoms/Button'
import Modal from '@/components/elements/atoms/Modal/Modal'
import Text, { Props as TextProps } from '@/components/elements/atoms/Text/Text'

import STYLES from './NavigateAwayModal.module.sass'

type Props = {
  open: boolean
  toggle: (open: boolean) => void
  namespace: string
  title: string
  description: TextProps
  cancelLabel: string
  confirmLabel: string
  onCancel: () => void
  onConfirm: () => void
}

const NavigateAwayModal = ({
  open,
  toggle,
  namespace,
  title,
  description,
  cancelLabel,
  confirmLabel,
  onCancel,
  onConfirm
}: Props): JSX.Element => {
  const onConfirmClick = useCallback(() => {
    toggle(false)
    onConfirm()
  }, [onConfirm, toggle])

  const onCancelClick = useCallback(() => {
    onCancel()
  }, [onCancel])

  return (
    <Modal
      isModalOpen={open}
      setOpenModal={toggle}
      width={800}
      bottomSticky
      textAlign="center"
    >
      <Text
        namespace={namespace}
        text={title}
        variant="display20"
        colour="brandBlue500"
        margin={false}
      />
      <div className={STYLES.description}>
        <Text
          namespace={namespace}
          text={description.text}
          variant="textRegular16"
          colour="brandBlue500"
          variables={description.variables}
        />
      </div>
      <div className={STYLES.navigateAwayButtons}>
        <Button
          typography={{
            namespace,
            text: confirmLabel
          }}
          onClick={onConfirmClick}
          disableAnalytics
        />
        <Button
          typography={{
            namespace,
            text: cancelLabel
          }}
          variant="secondary"
          onClick={onCancelClick}
          disableAnalytics
        />
      </div>
    </Modal>
  )
}

export default NavigateAwayModal
