import { gql } from '@apollo/client'

const BILLING_REQUEST_FLOW_CREATE = gql`
  mutation billingRequestFlowCreate(
    $redirectUrl: String
    $exitUrl: String
    $existingCustomerId: String
    $address: AddressInput!
    $email: String!
  ) {
    billingRequestFlowCreate(
      email: $email
      redirectUrl: $redirectUrl
      exitUrl: $exitUrl
      existingCustomerId: $existingCustomerId
      address: $address
    ) {
      billingRequestId
      billingRequestFlowId
    }
  }
`

export { BILLING_REQUEST_FLOW_CREATE }
