// @flow

import * as React from 'react'

import PillButton from './PillButton'
import type { FavouriteGame } from '../../../../shared_types/rails_models/dog_profile'

type Props = {|
  t: (arg0: string) => string,
  copyContext: string,
  options: Array<FavouriteGame>,
  selectedOptionCallback: (string) => void,
  selectedOption?: string
|}

const SelectableItemList = ({
  t,
  copyContext,
  options,
  selectedOptionCallback,
  selectedOption
}: Props): React.Element<'div'> => (
  <div className='pill-wrapper'>
    {
      // Due to the how the SelectableItemList has been put together, we must
      // deal with the fact that we require an arrow function here. The selected
      // option must bubble up to the parent component rather than be connected
      // to the Redux store where the selected option can be set as part of the
      // global application state
      /* eslint-disable react/jsx-no-bind */
      options.map((optionText: string): React.Node => (
        <PillButton
          displayText={t(`${copyContext}.${optionText}`)}
          key={optionText}
          selectItemCallback={(): void => selectedOptionCallback(optionText)}
          selected={optionText === selectedOption}
        />
      ))
      /* eslint-disable react/jsx-no-bind */
    }
  </div>
)

export default SelectableItemList
