import { gql } from '@apollo/client'

const UPDATE_MEDICAL_INFO_MUTATION = gql`
  mutation UpdateMedicalInfoMutation(
    $userId: ID!
    $attributes: [DogUpdateInput!]!
  ) {
    dogsUpdate(userId: $userId, attributes: $attributes) {
      id
      dogs {
        id
        name
        gender
        possessivePronoun
        healthIssues {
          id
          name
          warning
          information
        }
        allergens {
          id
          name
        }
      }
      subscription {
        id
        idealPlan {
          pricingCurve {
            pricingCohort
          }
        }
        plan {
          pricingCurve {
            pricingCohort
          }
        }
      }
    }
  }
`

export { UPDATE_MEDICAL_INFO_MUTATION }
