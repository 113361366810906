// @noflow
import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import type { Dispatch } from 'redux'

import Input from '@/components/shared/elements/Input'

import { ensureNever } from '@/typescript/utils'

import * as ACTIONS from '../../actions'
import type { State } from '../../reducers'
import { isValidDogNames } from '../../reducers/formDataReducer'
import type { Form, GenericForm } from '../../reducers/formDataReducer'
import type { State as RequestsState } from '../../reducers/requestsReducer'

type PresentationalProps = {
  dogNames: GenericForm['dogNames']
  submitContactForm: RequestsState['submitContactForm']
  type: Form['type']
}

type ActionProps = {
  updateDogNames: (e: React.ChangeEvent<HTMLInputElement>) => void
  validateDogNames: (e: React.ChangeEvent<HTMLInputElement>) => void
}

type Props = PresentationalProps & ActionProps

type Translate = (arg0: string) => string

const mapStateToProps = ({
  formData: { form },
  requests
}: State): PresentationalProps => {
  const { dogNames } = form as GenericForm
  const { submitContactForm } = requests
  const { type } = form as GenericForm

  return {
    dogNames,
    submitContactForm,
    type
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => {
  const updateDogNames = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.currentTarget.value
    dispatch(
      ACTIONS.updateField('dogNames', {
        value,
        interaction: 'Interacting'
      })
    )
  }

  const validateDogNames = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.currentTarget.value
    dispatch(
      ACTIONS.updateField('dogNames', {
        value,
        interaction: isValidDogNames(value)
          ? 'InteractedWithSuccess'
          : 'InteractedWithError'
      })
    )
  }

  return {
    updateDogNames,
    validateDogNames
  }
}

const placeholderText = (formType: Form['type'], t: Translate): string => {
  switch (formType) {
    case 'generic-form': {
      return t('dog_names_input.placeholder_text.gen_form')
    }
    default: {
      ensureNever(formType)
      throw new Error(`placeholderText unhandled form type: ${formType}`)
    }
  }
}

const invalidityMessageText = (
  formType: Form['type'],
  t: Translate
): string => {
  switch (formType) {
    case 'generic-form': {
      return t('dog_names_input.invalidity_message_text.gen_form')
    }
    default: {
      ensureNever(formType)
      throw new Error(`invalidityMessageText unhandled form type: ${formType}`)
    }
  }
}

const DogNamesInput = ({
  dogNames,
  submitContactForm,
  updateDogNames,
  validateDogNames,
  type
}: Props): React.ReactElement => {
  const { t } = useTranslation('contacts')
  return (
    <Input
      autoComplete="on"
      className="dog-names"
      disabled={submitContactForm.status === 'In Flight'}
      inputId="dogNames"
      invalid={dogNames.interaction === 'InteractedWithError'}
      invalidityMessage={invalidityMessageText(type, t)}
      label={t('dog_names_input.label')}
      name="dogNames"
      onBlur={validateDogNames}
      onChange={updateDogNames}
      required
      type="text"
      value={dogNames.value}
      placeholder={placeholderText(type, t)}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DogNamesInput)
