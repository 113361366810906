import { MealInput, PaymentMethodType, Provider } from '@/types'

import { DeliveryCadence, PaymentIdentifier, PaymentType } from '../types'
import { ConstructSubscriptionInput } from './buiildSubscriptionData'

type FormattedSubscriptionData = {
  planId: string
  paymentProvider: Provider
  paymentMethodIdentifierType: PaymentIdentifier
  additionalProductIds: string[]
  paymentMethodType: PaymentType
  targetFirstDeliveryDate: string
  mealBreakdown: MealInput
  deliveryCadence: DeliveryCadence | null
  user: {
    addressAttributes: {
      addressLineOne: string
      addressLineTwo?: string | null
      city: string
      shippingCountryId?: number | null
      recipientName: string
      postcode: string
    }
    shouldSupportSca: boolean
    email: string
    firstName: string
    lastName: string
    phone: string | null
  }
}

export const buildSubscriptionInputDataForStripeSetupIntent = ({
  subscriptionData,
  paymentMethodType,
  firstName,
  lastName
}: {
  subscriptionData: ConstructSubscriptionInput
  paymentMethodType: PaymentMethodType
  firstName: string
  lastName: string
}): FormattedSubscriptionData => {
  return {
    planId: subscriptionData.subscriptionInput.planId,
    paymentProvider: Provider.stripe,
    paymentMethodIdentifierType: 'id',
    additionalProductIds:
      subscriptionData?.subscriptionInput?.productVariantInputs?.map(
        (productVariantInput) => productVariantInput.productVariantId
      ) || [],
    paymentMethodType: paymentMethodType,
    targetFirstDeliveryDate:
      subscriptionData.subscriptionInput.targetFirstDeliveryDate,
    mealBreakdown: subscriptionData.subscriptionInput.mealBreakdown,
    deliveryCadence: 'default',
    user: {
      addressAttributes: {
        addressLineOne: subscriptionData.address.addressLineOne,
        addressLineTwo: subscriptionData.address.addressLineTwo,
        city: subscriptionData.address.city,
        shippingCountryId: subscriptionData.address.shippingCountryId,
        recipientName: subscriptionData.address.recipientName,
        postcode: subscriptionData.address.postcode
      },
      shouldSupportSca: true,
      email: subscriptionData.email,
      firstName: firstName,
      lastName: lastName,
      phone: subscriptionData.phoneNumber
    }
  }
}
