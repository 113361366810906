// @noflow
import i18next from 'i18next'
import upperFirst from 'lodash/upperFirst'
import React, { Fragment, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { animated, useSpring } from 'react-spring'

import { possessive } from '@/utils/StringHelper'

import BRAND_COLOURS from '@/constants/BrandColours'
import SUPPORT_COLOURS from '@/constants/SupportColours'

import Labrador from 'assets/images/illustrations/dogs/labrador.svg'

import AlertCard from '@/components/elements/atoms/Alert/AlertCard'
import Text from '@/components/elements/atoms/Text/Text'
import Tooltip from '@/components/elements/atoms/Tooltip/Tooltip'

import STYLES from './CalorieDetails.module.sass'

import type { CalorieDetailsFragment as Dog } from './fragments/__generated__/CalorieDetailsFragment'
import { SnackingHabit } from '@/types'

type Props = {
  dog: Dog
  expanded: boolean
}

const CalorieDetails = ({ dog, expanded }: Props): JSX.Element => {
  const { t } = useTranslation('account')

  const possessiveDogName = upperFirst(possessive(dog.name, i18next.language))
  const possessivePronoun = dog.possessivePronoun

  const planPercentageIsAbove90 =
    dog.roundedUncappedPlanPortionInPercentage >= 90

  const planCaloriesIsAboveTheIdealAmmount =
    dog.roundedUncappedPlanPortionInPercentage > 100

  const planCaloriesIsBelowTheIdealAmmount =
    dog.roundedUncappedPlanPortionInPercentage < 100

  const showTreatMessage =
    (dog?.snackingHabits === SnackingHabit.eats_some_snacks ||
      dog?.snackingHabits === SnackingHabit.eats_lots_of_snacks) &&
    planPercentageIsAbove90 &&
    planCaloriesIsBelowTheIdealAmmount

  // Animation variables
  const radius = 75
  const circumference = 2 * Math.PI * radius
  const circumferenceWidth = 16
  const delay = 1000

  // Calculated progress values
  const cappedPercentage = dog.roundedPlanPortionInPercentage
  const exceededPercentage =
    dog.roundedDailyCaloriesExceededByPlanPortionInPercentage
  const progressValue = (100 - cappedPercentage) / 100
  const exceededProgressValue = (100 - exceededPercentage) / 100

  const defaultGraphInfoAnimationStyle = useMemo(
    () => ({
      transform: 'scale(0)'
    }),
    []
  )

  // Animation for the graph information inside the progress bar
  const [graphInfoAnimationStyle, graphInfoAnimationApi] = useSpring(() => ({
    from: defaultGraphInfoAnimationStyle,
    transform: 'scale(1)',
    config: {
      tension: 400,
      mass: 2,
      velocity: 5
    }
  }))

  const defaultGraphAnimationStyle = useMemo(
    () => ({
      strokeDashoffset: circumference
    }),
    [circumference]
  )

  // Animation for the progress bar that represents the exceeded calories
  const [exceededProgressAnimationStyle, exceededProgressApi] = useSpring(
    () => ({
      ...defaultGraphAnimationStyle,
      config: { duration: 700, delay }
    })
  )

  // Animation for the progress bar
  const [progressAnimationStyle, progressApi] = useSpring(() => ({
    ...defaultGraphAnimationStyle,
    config: { duration: 700, delay },
    onRest: () => {
      exceededProgressApi.start({
        strokeDashoffset: circumference * exceededProgressValue
      })
    }
  }))

  const startProgressAnimation = useCallback(() => {
    progressApi.start({
      strokeDashoffset: circumference * progressValue
    })
  }, [progressApi, circumference, progressValue])

  const resetProgressAnimation = useCallback(() => {
    progressApi.set(defaultGraphAnimationStyle)
    exceededProgressApi.set(defaultGraphAnimationStyle)
    graphInfoAnimationApi.set(defaultGraphInfoAnimationStyle)
  }, [
    progressApi,
    defaultGraphAnimationStyle,
    graphInfoAnimationApi,
    exceededProgressApi,
    defaultGraphInfoAnimationStyle
  ])

  const showGraphInfoAnimation = useCallback(() => {
    graphInfoAnimationApi.start({
      transform: 'scale(1)'
    })
  }, [graphInfoAnimationApi])

  useEffect(() => {
    if (expanded) {
      startProgressAnimation()
      showGraphInfoAnimation()
    } else {
      resetProgressAnimation()
    }
  }, [
    expanded,
    startProgressAnimation,
    showGraphInfoAnimation,
    resetProgressAnimation
  ])

  return (
    <div>
      <div className={STYLES.calorieDetails}>
        <div className={STYLES.graphWrapper}>
          <svg width="166" height="166" className={STYLES.graph}>
            <circle
              cx="83"
              cy="83"
              r="75"
              stroke={BRAND_COLOURS.brandYellow200}
              strokeWidth={`${circumferenceWidth}px`}
            />
            <animated.circle
              cx="83"
              cy="83"
              r="75"
              stroke={BRAND_COLOURS.brandYellow400}
              strokeWidth={`${circumferenceWidth}px`}
              strokeDasharray={circumference}
              strokeLinecap="round"
              style={progressAnimationStyle}
            />
            <defs>
              <linearGradient id="gradient" x1="0%" y1="0%" x2="0" y2="1">
                <stop
                  offset="49%"
                  style={{
                    stopColor: BRAND_COLOURS.brandYellow400,
                    stopOpacity: 1
                  }}
                />
                <stop
                  offset="60%"
                  style={{
                    stopColor: SUPPORT_COLOURS.warningOrange300,
                    stopOpacity: 1
                  }}
                />
              </linearGradient>
            </defs>
            <animated.circle
              cx="83"
              cy="83"
              r="75"
              stroke={'url(#gradient)'}
              strokeWidth={`${circumferenceWidth}px`}
              strokeDasharray={circumference}
              strokeLinecap="square"
              style={exceededProgressAnimationStyle}
            />
          </svg>
          <animated.div
            className={STYLES.graphInformation}
            style={graphInfoAnimationStyle}
          >
            <Text
              variant="display20"
              text="dog_profile.calorie_details.graph_information.plan_portion_in_percentage"
              namespace="account"
              variables={{
                planPortionInPercentage:
                  dog.roundedUncappedPlanPortionInPercentage ?? 0
              }}
              margin={false}
            />
            <Tooltip
              label={{
                namespace: 'account',
                text: 'dog_profile.calorie_details.graph_information.tooltip_text',
                colour: 'brandBlue400',
                shouldScale: false
              }}
              flush="left"
              identifier="dog_profile.calorie_details.graph_information.tooltip_text"
            >
              <Fragment>
                <Text
                  namespace="account"
                  text="dog_profile.calorie_details.graph_information.tooltip_message"
                  variables={{
                    possessiveDogName,
                    possessivePronoun
                  }}
                  variant="textRegular14"
                  colour="brandBlue400"
                />
                <Text
                  namespace="account"
                  text="dog_profile.calorie_details.graph_information.tooltip_calories"
                  variables={{
                    lowerDailyCalories:
                      dog.roundedDailyCaloriesLowerBoundary ?? 0,
                    upperDailyCalories:
                      dog.roundedDailyCaloriesUpperBoundary ?? 0
                  }}
                  variant="display16"
                  margin={false}
                />
              </Fragment>
            </Tooltip>
            <img
              src={Labrador}
              alt={t('dog_profile.calorie_details.graph_information.img_alt')}
            />
          </animated.div>
        </div>
        <div className={STYLES.keys}>
          <div className={`${STYLES.key} ${STYLES.keyYellow400}`}>
            <div>
              <Text
                namespace="account"
                text="dog_profile.calorie_details.keys.plan_portion_in_grams"
                variables={{
                  planPortionInGrams: dog.planPortionInGrams
                }}
                margin={false}
                colour="brandBlue400"
              />
              <Text
                namespace="account"
                text="dog_profile.calorie_details.keys.plan_portion_in_calories"
                variables={{
                  planPortionInCalories: dog.roundedPlanPortionInCalories
                }}
                variant="display16"
                margin={false}
              />
            </div>
          </div>
          {planCaloriesIsBelowTheIdealAmmount && (
            <div className={`${STYLES.key} ${STYLES.keyYellow200}`}>
              <div>
                <Text
                  namespace="account"
                  text={
                    showTreatMessage
                      ? 'dog_profile.calorie_details.keys.room_for_treats'
                      : 'dog_profile.calorie_details.keys.add_to_diet'
                  }
                  variables={{
                    planPortionInGrams: dog.planPortionInGrams,
                    possessivePronoun
                  }}
                  colour="brandBlue400"
                  margin={false}
                />
                {!showTreatMessage && (
                  <Text
                    namespace="account"
                    text="dog_profile.calorie_details.keys.daily_calories_unfulfilled_by_plan_portion"
                    variables={{
                      lowerDailyCaloriesUnfulfilledByPlanPortion:
                        dog.roundedDailyCaloriesUnfulfilledByPlanPortionLowerBoundary,
                      upperDailyCaloriesUnfulfilledByPlanPortion:
                        dog.roundedDailyCaloriesUnfulfilledByPlanPortionUpperBoundary
                    }}
                    variant="display16"
                    margin={false}
                  />
                )}
              </div>
            </div>
          )}
          {planCaloriesIsAboveTheIdealAmmount && (
            <div className={`${STYLES.key} ${STYLES.keyOrange300}`}>
              <Text
                namespace="account"
                text="dog_profile.calorie_details.keys.more_than_recommended"
                variables={{
                  caloriesAboveRecomendation:
                    dog.roundedDailyCaloriesExceededByPlanPortion ?? 0
                }}
                colour="brandBlue400"
                margin={false}
              />
            </div>
          )}
        </div>
      </div>
      <AlertCard
        variant={planCaloriesIsAboveTheIdealAmmount ? 'warning' : 'info'}
        message={{
          text: planCaloriesIsAboveTheIdealAmmount
            ? 'dog_profile.calorie_details.warning.more_than_recommended_alert'
            : 'dog_profile.calorie_details.warning.edit_portion_size',
          namespace: 'account',
          variables: {
            context: dog.gender
          },
          margin: false,
          align: 'left'
        }}
      />
    </div>
  )
}

export { CalorieDetails }
