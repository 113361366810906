// @noflow
type GraphQLDog = {
  name: string
  dailyCalories: number
  pouchSizeInGrams: number
}

type DogSummary = {
  pricePerDayWithDiscount: number | null
  name: string
  pricePerDayWithoutDiscount: number
}

const buildDogPricing = (
  dogs: Array<GraphQLDog>,
  pricePerDayWithoutDiscount: number,
  pricePerDayWithDiscount: number | null
): Array<DogSummary> => {
  const totalCalories = dogs.reduce(
    (sum: number, dog: GraphQLDog): number => sum + dog.dailyCalories,
    0
  )
  return dogs.map((dog: GraphQLDog): DogSummary => {
    const shareOfCalories = dog.dailyCalories / totalCalories
    const withoutDiscount = pricePerDayWithoutDiscount * shareOfCalories
    const withDiscount =
      typeof pricePerDayWithDiscount === 'number'
        ? pricePerDayWithDiscount * shareOfCalories
        : null
    return {
      name: dog.name,
      pricePerDayWithoutDiscount: withoutDiscount,
      pricePerDayWithDiscount: withDiscount
    }
  })
}

export default buildDogPricing
