// @flow

import { connect } from 'react-redux'

import type { State } from '../../index'
import type { CustomerHistory } from '../../message_types'
import type { Dispatch } from 'redux'
import { getCustomerHistoryDetails } from '../../thunks'

import CustomerHistoryComponent from '../../components/user_details/CustomerHistory'

type PresentationalProps = $ReadOnly<{|
  userId: number,
  isFetching: boolean,
  customerHistory: CustomerHistory
|}>

type ActionProps = $ReadOnly<{|
  dispatchGetCustomerHistoryDetails: ((number) => void)
|}>

export type ComponentProps =
  & PresentationalProps
  & ActionProps

function mapStateToProps (state: State): PresentationalProps {
  const { selectedUserIds, fetchingStatuses, customerHistory } = state
  const isFetching = fetchingStatuses.customerHistoryEvents
  const { userId } = selectedUserIds
  return { userId, isFetching, customerHistory }
}

function mapDispatchToProps (dispatch: Dispatch): ActionProps {
  const dispatchGetCustomerHistoryDetails = (userId: number): void => {
    dispatch(getCustomerHistoryDetails(userId))
  }
  return { dispatchGetCustomerHistoryDetails }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerHistoryComponent)
