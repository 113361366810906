import React from 'react'

import paypalLogo from 'assets/images/logos/paypal-small-logo.svg'

import SkeletonLoading from '@/components/elements/atoms/SkeletonLoading/SkeletonLoading'
import DetailsCard from '@/components/elements/molecules/DetailsCard/DetailsCard'
import DetailsCardSkeleton from '@/components/pages/MyDetailsPage/components/DetailsCardSkeleton/DetailsCardSkeleton'

type Props = {
  paypalAuthorisationLoading: boolean
  onSubmit: () => void
}

const PaypalCard = ({
  paypalAuthorisationLoading,
  onSubmit
}: Props): JSX.Element => {
  return (
    <SkeletonLoading
      contentReady={!paypalAuthorisationLoading}
      template={<DetailsCardSkeleton shortLine />}
    >
      <DetailsCard
        text={{
          text: 'my_details.payment_methods.method_names.paypal.heading'
        }}
        onClick={onSubmit}
        namespace={'dashboard'}
        leftImage={
          <img
            alt={'paypal logo'}
            height="20px"
            className="payment-section__card-icon"
            src={paypalLogo}
          />
        }
        identifier="payment_methods.paypal"
      />
    </SkeletonLoading>
  )
}

export { Props }
export default PaypalCard
