// @noflow
import { useReactiveVar } from '@apollo/client'
import i18next from 'i18next'
import Cookies from 'js-cookie'
import React, { FormEvent, useCallback, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { possessive, pronounContext } from '@/utils/StringHelper'

import segmentTrack from '@/components/analytics/Analytics'
import Text from '@/components/elements/atoms/Text/Text'
import BreedAutocomplete from '@/components/elements/molecules/BreedAutocomplete/BreedAutocomplete'
import type { BreedSelectorQuery_breeds as BreedResult } from '@/components/elements/molecules/BreedAutocomplete/queries/__generated__/BreedSelectorQuery'
import ExpertCard from '@/components/elements/molecules/ExpertCard/ExpertCard'
import TextSeparator from '@/components/elements/molecules/TextSeparator/TextSeparator'
import StickyNavigation from '@/components/elements/organisms/StickyNavigation/StickyNavigation'
import {
  routeToNextStep,
  routeToPrevStep
} from '@/components/pages/SignupWizardPage/helpers/wizardRoutes'
import type { Route } from '@/components/pages/SignupWizardPage/types/routes'

import STYLES from './Breed.module.sass'

import { Gender } from '@/types'

import { wizardPageState } from '../../SignupWizardPage'
import useImplicitFormSubmission from '../../helpers/useImplicitFormSubmission'
import type { Dog } from '../../types/types'

type Props = {
  variant?: keyof typeof STYLES
  namespace: string
}

const copyContext = 'breed_step'

const Breed = ({ variant, namespace }: Props): JSX.Element => {
  const route = useLocation().pathname as Route
  const wizardState = useReactiveVar(wizardPageState)
  const [selectedBreed, setSelectedBreed] = useState<string | null>()
  const { dogs } = wizardState

  const formIsValid = dogs.every(
    (dog: Dog) => dog.breed && dog.breed.key && dog.breed.id
  )

  const navigate = useNavigate()

  const onContinueClicked = useCallback(() => {
    const eventName = 'Breed selector breed entered'
    const properties = {
      path: window.location.pathname,
      breed: selectedBreed
    }

    segmentTrack(eventName, properties)
    navigate(routeToNextStep({ route }))
  }, [navigate, route, selectedBreed])

  const onEnterPress = useCallback(() => {
    onContinueClicked()
  }, [onContinueClicked])

  useImplicitFormSubmission({ formIsValid, onEnterPress })

  const updateDogBreed = useCallback(
    (
      index: number,
      breed: { id: number; key: string; name: string } | null
    ) => {
      dogs[index].breed = breed
      setSelectedBreed(breed?.key || null)

      wizardPageState({
        ...wizardState,
        dogs
      })
    },
    [dogs, wizardState]
  )

  const unknownBreedChange = (
    e: FormEvent<HTMLInputElement>,
    index: number
  ) => {
    const unknownBreed = e.currentTarget
    updateDogBreed(
      index,
      unknownBreed.checked
        ? {
            id: 260,
            key: 'unknown',
            name: i18next.t('models:breeds.other.name')
          }
        : null
    )
  }

  const onBreedSelection = useCallback(
    (breed: BreedResult, dogIndex: number) => {
      // Remove any pre-selected Breed from the BreedSelector cookie
      Cookies.remove('breedSelectorBreedId')

      if (breed.key === 'mixed_breed') {
        updateDogBreed(dogIndex, { id: 260, key: breed.key, name: breed.name })
      } else {
        updateDogBreed(dogIndex, {
          id: parseInt(breed.id),
          key: breed.key,
          name: breed.name
        })
      }
    },
    [updateDogBreed]
  )

  const onInputEmpty = useCallback(
    (index: number) => {
      updateDogBreed(index, null)
    },
    [updateDogBreed]
  )
  return (
    <>
      <div className={variant ? STYLES[variant] : ''}>
        <div className={STYLES.question}>
          {dogs.map((dog: Dog, index: number) => {
            const intialBreedValue: BreedResult | undefined =
              dog.breed && dog.breed.key !== 'unknown'
                ? {
                    __typename: 'Breed',
                    id: dog.breed.id.toString(),
                    key: dog.breed.key,
                    name: dog.breed.name,
                    popular: false
                  }
                : undefined

            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index}>
                <div className={STYLES.subQuestion}>
                  <TextSeparator
                    text={`${copyContext}.text_separator`}
                    namespace={namespace}
                    variables={{ dogName: dog.name }}
                  />
                  <div className={STYLES.typeAheadCombobox}>
                    {(!dog.breed || dog.breed.key !== 'unknown') && (
                      <BreedAutocomplete
                        variant="v2"
                        initialValue={intialBreedValue}
                        loadingPlaceholder={i18next.t(
                          `paid_traffic:breed_hero_section.field_placeholder_loading`
                        )}
                        placeholder={i18next.t(
                          `${namespace}:${copyContext}.placeholder`,
                          {
                            possessiveDogName: possessive(
                              dog.name,
                              i18next.language
                            )
                          }
                        )}
                        events={{
                          onBreedSelection: (breed) =>
                            onBreedSelection(breed, index),
                          onInputEmpty: () => onInputEmpty(index),
                          onListOpen: () => null,
                          onFocus: () => null
                        }}
                      />
                    )}
                  </div>
                  <div className={STYLES.checkboxWrapper}>
                    <input
                      type="checkbox"
                      aria-labelledby={`unknownBreedInput-${index}`}
                      className={STYLES.checkboxInput}
                      id={`unknownBreedInput-${index}`}
                      // eslint-disable-next-line react/jsx-no-bind
                      onChange={(e) => unknownBreedChange(e, index)}
                      defaultChecked={
                        !dog.breed ? undefined : dog.breed.key === 'unknown'
                      }
                    />
                    <label
                      htmlFor={`unknownBreedInput-${index}`}
                      className={STYLES.checkboxLabel}
                    >
                      <Text
                        namespace={namespace}
                        text={`${copyContext}.unknown_breed`}
                        variables={{
                          context: pronounContext(
                            [dog.gender as Gender],
                            i18next.language
                          )
                        }}
                        variant="textRegular16"
                        colour="brandBlue500"
                      />
                    </label>
                  </div>
                </div>
                {dog.breed && dog.breed.key === 'dalmatian' && (
                  <div className={STYLES.speechBubble}>
                    <div className={STYLES.quote}>
                      <Text
                        namespace={namespace}
                        text={`${copyContext}.dalmatian_quote_html`}
                        variables={{ class: STYLES.btnText }}
                        variant="textRegular16"
                        colour="brandBlue500"
                      />
                    </div>
                    <ExpertCard
                      namespace={'expert'}
                      expert={{
                        name: 'Sarah Barber',
                        description: 'sarah_connell_sait.profession',
                        imgSrc: 'Web/photos/people/sarah-connell-sait.png',
                        imgAlt: 'sarah_connell_sait.image_alt'
                      }}
                    />
                  </div>
                )}
                {dog.breed && dog.breed.key === 'hungarian_vizsla' && (
                  <div className={STYLES.speechBubble}>
                    <div className={STYLES.quote}>
                      <Text
                        namespace={namespace}
                        text={`${copyContext}.hungarian_vizsla_quote`}
                        variables={{
                          possessiveDogName: possessive(
                            dog.name,
                            i18next.language
                          ),
                          context: pronounContext(
                            [dog.gender as Gender],
                            i18next.language
                          )
                        }}
                        variant="textRegular16"
                        colour="brandBlue500"
                      />
                    </div>
                    <ExpertCard
                      namespace={'expert'}
                      expert={{
                        name: 'Sarah Barber',
                        description: 'sarah_connell_sait.profession',
                        imgSrc: 'Web/photos/people/sarah-connell-sait.png',
                        imgAlt: 'sarah_connell_sait.image_alt'
                      }}
                    />
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
      <StickyNavigation
        variant="twoButtons"
        buttonOne={{
          url: routeToPrevStep({ route }),
          text: 'wizard_flow:sticky_navigation.back',
          variant: 'secondary',
          iconColour: 'brandRed400'
        }}
        buttonTwo={{
          dataTestId: 'next-button',
          text: 'wizard_flow:sticky_navigation.continue',
          variant: 'primary',
          iconColour: 'brandWhite',
          onClick: onContinueClicked
        }}
        disabled={!formIsValid}
      />
    </>
  )
}

export { Props }
export default Breed
