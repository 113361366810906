// @noflow
import React from 'react'

import ListItem from '@/components/elements/atoms/ListItem/ListItem'
import type { Props as ListItemProps } from '@/components/elements/atoms/ListItem/ListItem'

import STYLES from './ListGroup.module.sass'

type GroupSpacing = 'small' | 'regular'

type Props = {
  listItems: Array<ListItemProps>
  spacing?: GroupSpacing
}

const ListGroup = ({ listItems, spacing = 'regular' }: Props): JSX.Element => (
  <ul className={`${STYLES.listGroup} ${STYLES[spacing]}`}>
    {listItems.map((listItem: ListItemProps) => (
      <ListItem
        dataTestId={listItem.dataTestId}
        key={
          listItem.text.text instanceof Array
            ? listItem.text.text[0]
            : listItem.text.text
        }
        text={listItem.text}
        bulletStyle={listItem.bulletStyle}
      />
    ))}
  </ul>
)

export { Props }
export default ListGroup
