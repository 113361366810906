// @noflow
import React, { useCallback, useState } from 'react'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import AccountDetailsCard from '../../MyDetailsPage/components/AccountDetailsCard/AccountDetailsCard'
import Modal from '@/components/elements/atoms/Modal/Modal'
import Text from '@/components/elements/atoms/Text/Text'
import PasswordForm, {
  Passwords
} from '@/components/elements/organisms/PasswordForm/PasswordForm'

import STYLES from './../PersonalDetails.module.sass'

type Props = {
  loading: boolean
  updateYourPassword: (password: string) => void
}

const YourPassword = ({ loading, updateYourPassword }: Props): JSX.Element => {
  const { windowWidth } = useWindowSize()
  const [yourPasswordModalIsOpen, setYourPasswordModalIsOpen] = useState(false)

  const toggleYourPasswordModal = useCallback(() => {
    setYourPasswordModalIsOpen((prev) => !prev)
  }, [])

  const onSubmit = useCallback(
    (data: Passwords) => {
      updateYourPassword(data.password)
      toggleYourPasswordModal()
    },
    [toggleYourPasswordModal, updateYourPassword]
  )

  return (
    <>
      <AccountDetailsCard
        loading={loading}
        lines={1}
        shortLine={false}
        label={{ text: 'my_details.personal_details.your_password.label' }}
        text={{
          text: '&#10033;&#10033;&#10033;&#10033;&#10033;&#10033;',
          translate: false
        }}
        onClick={toggleYourPasswordModal}
        prompt={'my_details.personal_details.your_password.prompt'}
        icon={'padlock'}
        namespace={'dashboard'}
        linkPosition={'top'}
        identifier="personal_details.change_password"
      />
      <Modal
        isModalOpen={yourPasswordModalIsOpen}
        setOpenModal={toggleYourPasswordModal}
        width={600}
        bottomSticky={windowWidth < BREAKPOINTS.md}
      >
        <div className={STYLES.titleWrapper}>
          <Text
            namespace={'dashboard'}
            text={
              'my_details.personal_details.your_password.change_your_password'
            }
            variant="textRegular20"
            margin={false}
            bold
            align="center"
          />
          <Text
            namespace={'dashboard'}
            text={'my_details.personal_details.your_password.help_text'}
            variant="textRegular16"
            align="center"
            margin={false}
          />
        </div>
        <PasswordForm onSubmit={onSubmit} />
      </Modal>
    </>
  )
}

export default YourPassword
