import { useReactiveVar } from '@apollo/client'
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'

import Categories from './components/Categories'

import STYLES from './categories.module.sass'

import { CustomerIssueManagementInitialDataQuery_categories as EligibleCategory } from '../../queries/__generated__/CustomerIssueManagementInitialDataQuery'

import {
  customerIssueManagementState,
  pageTitleState
} from '../../CustomerIssueManagementPage'
import useCustomerIssueManagementTracking from '../../analytics'
import useQueryParam from '../../hooks/useQueryParam'
import SelfResolutionRoutes from '../../types/routes'

type Props = {
  submitCustomerIssueReportCreate: (category: EligibleCategory) => void
}

const CategoriesScreen = ({
  submitCustomerIssueReportCreate
}: Props): JSX.Element => {
  const customerIssueManagementData = useReactiveVar(
    customerIssueManagementState
  )
  const { search } = useLocation()
  const categoryIdParam = useQueryParam('category_id', search) || null
  const navigate = useNavigate()
  const [initialised, setInitialised] = useState(false)
  const [categoryId, setCategoryId] = useState<string | null>(categoryIdParam)
  const [categories, setCategories] = useState<Array<EligibleCategory>>([])
  const customerIssueManagementTracking = useCustomerIssueManagementTracking()

  useEffect(() => {
    if (!initialised) {
      pageTitleState('categories.title')
      customerIssueManagementTracking.stepLoaded('Categories', 'Categories')
      setInitialised(true)
    }
  }, [initialised, customerIssueManagementTracking])

  useEffect(() => {
    setCategoryId(categoryIdParam)
  }, [categoryIdParam])

  const getCategory = useCallback(
    (id: string | null): EligibleCategory | null => {
      return (
        customerIssueManagementData.eligibleCategories.find(
          (cat) => cat.id === id
        ) || null
      )
    },
    [customerIssueManagementData.eligibleCategories]
  )

  const getDescendantCategories = useCallback(
    (id: string | null): Array<EligibleCategory> => {
      return customerIssueManagementData.eligibleCategories.filter(
        (cat) => cat.parentId === id
      )
    },
    [customerIssueManagementData.eligibleCategories]
  )

  const trackCategory = useCallback(
    (category: EligibleCategory): void => {
      const parentCategory = getCategory(category.parentId)
      customerIssueManagementTracking.categorySelected(
        category?.name,
        parentCategory?.name
      )
    },
    [getCategory, customerIssueManagementTracking]
  )

  const nextCategoryScreen = useCallback(
    (id: string): void => {
      const path =
        SelfResolutionRoutes.Categories +
        customerIssueManagementData.queryParams +
        '&category_id=' +
        id
      navigate(path)
    },
    [customerIssueManagementData.queryParams, navigate]
  )

  useEffect(() => {
    const descendantCategories = getDescendantCategories(categoryId)
    if (descendantCategories.length > 0) {
      setCategories(descendantCategories)
    } else {
      setCategoryId(null)
    }
  }, [categoryId, getDescendantCategories])

  const selectCategory = useCallback(
    (id: string) => {
      const category = getCategory(id)
      if (category) {
        trackCategory(category)
        if (getDescendantCategories(id).length > 0) {
          nextCategoryScreen(id)
        } else {
          submitCustomerIssueReportCreate(category)
        }
      }
    },
    [
      getCategory,
      trackCategory,
      getDescendantCategories,
      nextCategoryScreen,
      submitCustomerIssueReportCreate
    ]
  )

  return (
    <div className={`${STYLES.container}`}>
      <Categories categories={categories} selectCategory={selectCategory} />
    </div>
  )
}

export { Props }
export default CategoriesScreen
