// @noflow
import { CardElement } from '@stripe/react-stripe-js'
import type { StripeCardElementChangeEvent } from '@stripe/stripe-js'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import BRAND_COLOURS from '@/constants/BrandColours'
import SUPPORT_COLOURS from '@/constants/SupportColours'

import { Button } from '@/components/elements/atoms/Button'
import Image from '@/components/elements/atoms/Image'

import { Error } from '../Validation/Validation'

type CreditCard = {
  errorMessage: string | null
  onCardElementChange: (event: StripeCardElementChangeEvent) => void
  creditCardLoading?: boolean
  disableCreditCardButton?: boolean
  buySubscriptionCallback?: () => void
}
type Props = {
  namespace: string
} & CreditCard

const CreditCardView = ({
  errorMessage,
  onCardElementChange,
  namespace,
  creditCardLoading,
  disableCreditCardButton,
  buySubscriptionCallback
}: Props): JSX.Element => {
  // Force the Element frame to paint once it is visible on the page
  const stripeElement: HTMLElement | null =
    document.querySelector('.StripeElement')

  const forceReflow = useCallback((stripeElement: HTMLElement | null): void => {
    if (stripeElement) {
      const initialDisplay = stripeElement.style.display

      stripeElement.style.display = 'none'
      stripeElement.style.display = initialDisplay
    }
  }, [])

  useEffect((): void => {
    forceReflow(stripeElement)
  })

  const { t } = useTranslation(namespace)

  return (
    <div
      className={`checkout-input-wrapper${
        errorMessage === null ? '' : ' checkout-input-wrapper--invalid'
      }`}
      data-testid="checkout-credit-card-input"
    >
      <div id="paypal-button-container" />
      <CardElement
        id="test-id-credit-card"
        onChange={onCardElementChange}
        // eslint-disable-next-line react/forbid-component-props
        options={{
          hidePostalCode: true,
          style: {
            base: {
              fontSize: '18px',
              fontFamily: 'gt-pressura-regular, helvetica, arial, sans-serif',
              color: BRAND_COLOURS.brandBlue500,

              '::placeholder': {
                color:
                  errorMessage === null
                    ? BRAND_COLOURS.brandBlue400
                    : SUPPORT_COLOURS.dangerRed300
              }
            },
            invalid: {
              color: '#7a0a06'
            }
          }
        }}
      />
      {errorMessage && <Error errorMessage={errorMessage} />}
      <div className="stripe-logo">
        <Image
          slug="stripe-logo"
          image={{
            height: 21,
            width: 96
          }}
          alt={t('payment_section.payment_options_view.stripe_alt')}
        />
      </div>
      <div className="checkout__continue-button">
        <Button
          typography={{
            namespace,
            text: creditCardLoading
              ? 'continue_button.loading'
              : 'continue_button.card'
          }}
          dataTestId="checkout-continue-to-pay-button"
          disabled={disableCreditCardButton}
          onClick={buySubscriptionCallback}
          fullWidth
          disableAnalytics
        />
      </div>
    </div>
  )
}

export default CreditCardView

export { CreditCard }
