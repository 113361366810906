// @noflow
import type { Language } from '@/packs/localisation'
import Cookies from 'js-cookie'

import * as Sentry from '@/utils/sentry'

import { dummyPostcodeForShippingCountry } from '@/components/pages/CheckoutPage/helpers/graphql'
import { errorState } from '@/components/pages/SignupWizardPage/SignupWizardPage'

import { BodyShape, SnackingHabits } from '@/shared_types/rails_models/dogs'
import {
  AgeClassificationByUserWhenCreated,
  BodyShape as BodyShapeEnum,
  Code as CountryCode,
  DogInput,
  Eater,
  Exercise,
  FeedingGuidelinesUsed,
  Gender,
  MarketingPreferences,
  SnackingHabit
} from '@/types'

import type {
  Dog,
  GoalSegment,
  WizardActivityLevel,
  WizardAge,
  WizardAgeStage,
  WizardBodyCondition,
  WizardEaterType,
  WizardSnackingHabits
} from '../types/types'
import { trackEmailAlreadyExists } from './analytics'

type FormattedDog = {
  name: string
  gender: string
  breed_id: number
  body_shape: BodyShape
  exercise: number
  eater_type: Eater
  age_in_months: number
  age_classification_by_user_when_created: number
  brought_home: boolean
  weight_in_grams: number
  neutered: boolean
  snacking_habits: SnackingHabits
  allergies: Array<string> | null
  health_issues: Array<string> | null
  food_category_ids: Array<string>
  is_rescue: boolean | null
}

type FormattedUser = {
  user: {
    email: string
    full_name: string
    dogs: Array<FormattedDog>
    marketing_consent?: MarketingPreferences
    supported_marketing_method_purpose_ids?: Array<string>
    country: CountryCode
    postcode: string | null
    preferred_language: Language
    goal_segment: GoalSegment | null
  }
}

type TriggerError = 'emailExists' | 'generic'

const languageCookiesKey = 'user_language'

const getLanguage = (): Language => {
  try {
    const language = Cookies.get(languageCookiesKey) as Language | null
    if (language) {
      return language
    } else return 'en'
  } catch (_) {
    return 'en'
  }
}

const encodeBodyShape = (arg: WizardBodyCondition): BodyShape => {
  return arg.replace(
    /[A-Z]/g,
    (letter) => `_${letter.toLowerCase()}`
  ) as BodyShape
}

const encodeExercise = (arg: WizardActivityLevel): number => {
  switch (arg) {
    case 'lazy':
      return 0
    case 'leisurely':
    case 'fairlyActive':
    case 'bundleOfEnergy':
      return 2
    case 'hyperActive':
      return 3
    default: {
      Sentry.captureException(
        'Unhandled exercise type given to encodeExercice',
        {
          extra: {
            exercise: arg
          },
          tags: {
            product: Sentry.Product.Wizard
          }
        }
      )
      return 2
    }
  }
}

const encodeExerciseEnum = (arg: WizardActivityLevel): Exercise => {
  switch (arg) {
    case 'lazy':
      return Exercise.low
    case 'leisurely':
    case 'fairlyActive':
    case 'bundleOfEnergy':
      return Exercise.normal
    case 'hyperActive':
      return Exercise.hyper
    default: {
      Sentry.captureException(
        'Unhandled exercise type given to encodeExerciseEnum',
        {
          extra: {
            exercise: arg
          },
          tags: {
            product: Sentry.Product.Wizard
          }
        }
      )
      return Exercise.normal
    }
  }
}

const encodeEaterType = (arg: WizardEaterType | null): Eater => {
  switch (arg) {
    case 'veryFussy': {
      return Eater.fussy_eater
    }
    case 'canBeFussy': {
      return Eater.picky_eater
    }
    case 'goodEater': {
      return Eater.good_eater
    }
    case 'eatsAnything': {
      return Eater.eats_anything
    }
    default: {
      Sentry.captureException(
        'Unhandled exercise type given to encodeEaterType',
        {
          extra: {
            eaterType: arg
          },
          tags: {
            product: Sentry.Product.Wizard
          }
        }
      )
      return Eater.good_eater
    }
  }
}

const encodeAge = (arg: WizardAge): number => {
  let total = 0
  if (arg.weeks) {
    total = total + (arg.weeks * 12) / 52
  }
  if (arg.months) {
    total = total + arg.months
  }
  if (arg.years) {
    total = total + arg.years * 12
  }
  if (total === 0) {
    Sentry.captureException('Was unable to calculate dog age in encodeAge', {
      extra: {
        age: arg
      },
      tags: {
        product: Sentry.Product.Wizard
      }
    })
    return Math.round(total)
  }

  return Math.round(total)
}
const encodeAgeClassification = (arg: WizardAgeStage | null) => {
  switch (arg) {
    case 'adult':
      return 1
    case 'puppy':
      return 2
    case 'senior':
      return 3
    default: {
      Sentry.captureException(
        'Unhandled age classification given to encodeAgeClassification',
        {
          extra: {
            age: arg
          },
          tags: {
            product: Sentry.Product.Wizard
          }
        }
      )

      return arg
    }
  }
}

const encodeAgeClassificationEnum = (arg: WizardAgeStage | null) => {
  switch (arg) {
    case 'adult':
      return AgeClassificationByUserWhenCreated.classified_as_adult_by_user_when_created
    case 'puppy':
      return AgeClassificationByUserWhenCreated.classified_as_puppy_by_user_when_created
    case 'senior':
      return AgeClassificationByUserWhenCreated.classified_as_senior_by_user_when_created
    default:
      return AgeClassificationByUserWhenCreated.classified_as_adult_by_user_when_created
  }
}

const encodeSnackingHabits = (arg: WizardSnackingHabits): SnackingHabit => {
  switch (arg) {
    case 'noSnacks':
      return SnackingHabit.eats_no_snacks
    case 'someSnacks':
      return SnackingHabit.eats_some_snacks
    case 'lotsOfSnacks':
      return SnackingHabit.eats_lots_of_snacks
    default: {
      Sentry.captureException(
        'Unhandled snacking habit given to encodeSnackingHabits',
        {
          extra: {
            snackingHabit: arg
          },
          tags: {
            product: Sentry.Product.Wizard
          }
        }
      )

      return SnackingHabit.eats_some_snacks
    }
  }
}

const encodeDogs = (dogs: Array<Dog>) => {
  const formattedDogs = [] as Array<FormattedDog>
  dogs.forEach((dog: Dog) => {
    formattedDogs.push({
      name: dog.name as string,
      gender: dog.gender as string,
      breed_id: dog.breed?.id as number,
      body_shape: encodeBodyShape(dog.bodyCondition) as BodyShape,
      exercise: encodeExercise(dog.activityLevel),
      eater_type: encodeEaterType(dog.eaterType as WizardEaterType),
      age_in_months: encodeAge(dog.age as WizardAge),
      age_classification_by_user_when_created: encodeAgeClassification(
        dog.ageStage
      ) as number,
      brought_home: dog.broughtHome === null ? true : dog.broughtHome,
      weight_in_grams: dog.weight as number,
      neutered: dog.neutered as boolean,
      snacking_habits: encodeSnackingHabits(dog.snackingHabits),
      allergies: dog.allergies,
      health_issues: dog.healthIssues?.map((hi) => hi.id) || null,
      food_category_ids: dog.foodCategoryIds,
      is_rescue: dog.isRescue
    })
  })
  return formattedDogs
}

const triggerError = (errorType: TriggerError) => {
  switch (errorType) {
    case 'emailExists':
      trackEmailAlreadyExists()

      errorState({
        showError: true,
        errorMessage: 'error_message.email_exists'
      })
      break
    case 'generic':
      errorState({
        showError: true,
        errorMessage: 'error_message.generic'
      })
  }

  setTimeout((): void => {
    errorState({ showError: false, errorMessage: '' })
  }, 3000)
}

const submitFormData = async (
  csrfToken: string | null,
  shouldSeePetParentOnPlans?: boolean
): Promise<void> => {
  const storageKey = 'wizardState'
  const wizardState = JSON.parse(localStorage.getItem(storageKey) as string)
  const { user, dogs } = wizardState

  const email = shouldSeePetParentOnPlans
    ? // eslint-disable-next-line i18next/no-literal-string
      `temporary-${user.trackingId}@butternutbox.com`
    : user.email
  const fullName = shouldSeePetParentOnPlans ? 'Pet Parent' : user.name

  const data: FormattedUser = {
    user: {
      email: email,
      full_name: fullName,
      dogs: encodeDogs(dogs),
      marketing_consent: user.marketingPreference,
      country: user.location.countryCode,
      postcode: dummyPostcodeForShippingCountry(user.location.countryCode),
      preferred_language: getLanguage(),
      goal_segment: user.goalSegment,
      supported_marketing_method_purpose_ids:
        user.supportedMarketingMethodPurposeIds
    }
  }

  if (!csrfToken) {
    throw new Error(`CSRF token not found in submitFormData`)
  }
  return fetch('/api/wizard/v1/complete', {
    method: 'POST',
    body: JSON.stringify({
      user: {
        ...data.user
      }
    }),
    headers: {
      'X-CSRF-Token': csrfToken,
      'Content-Type': 'application/json'
    }
  }).then((res: Response): Promise<void> => {
    if (!res.ok) {
      return res.text().then((message: string): void => {
        try {
          const errorMessage = JSON.parse(message)
          if (errorMessage.error === 'email_exists') {
            triggerError('emailExists')
          } else {
            Sentry.captureException(
              `Server Error occurred whilst navigating from Wizard Pet Parent to Plans`,
              {
                extra: {
                  error: errorMessage.error
                },
                tags: {
                  product: Sentry.Product.Wizard
                }
              }
            )
            triggerError('generic')
          }
        } catch (error) {
          Sentry.captureException(
            `Server Error occurred whilst navigating from Wizard Pet Parent to Plans`,
            {
              extra: {
                error
              },
              tags: {
                product: Sentry.Product.Wizard
              }
            }
          )
          triggerError('generic')
        }
      })
    }
    return res.text().then((): void => {
      shouldSeePetParentOnPlans
        ? (window.location.href = '/plans/pet-parent')
        : (window.location.href = '/plans/recipes')
    })
  })
}

// TODO: This adapter should not be necessary. Each screen should only add values to the dog object that matches
// the Dog type
const adaptDogsForMutation = (dogs: Array<Dog>): Array<DogInput> => {
  return dogs.map((dog: Dog): DogInput => {
    return {
      name: dog.name,
      breedId: dog.breed ? dog.breed.id.toString() : '',
      gender: dog.gender ? Gender[dog.gender] : Gender.male,
      exercise: encodeExerciseEnum(dog.activityLevel),
      ageInMonthsWhenLastUpdated: encodeAge(dog.age as WizardAge),
      weightInGrams: dog.weight || 0,
      bodyShape: BodyShapeEnum[encodeBodyShape(dog.bodyCondition)],
      neutered: dog.neutered || false,
      ageClassificationByUserWhenCreated: encodeAgeClassificationEnum(
        dog.ageStage
      ),
      feedingGuidelinesUsed:
        FeedingGuidelinesUsed.october2020_feeding_guidelines,
      eaterType: encodeEaterType(dog.eaterType),
      snackingHabits: encodeSnackingHabits(dog.snackingHabits),
      allergenIds: dog.allergies,
      healthIssueIds: dog.healthIssues?.map(({ id }) => id),
      foodCategoryIds: dog.foodCategoryIds
    }
  })
}

export { submitFormData, encodeEaterType, encodeAge, adaptDogsForMutation }

export type { FormattedDog, FormattedUser }
