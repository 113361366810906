import { gql } from '@apollo/client'

const INITIAL_DATA_QUERY = gql`
  query ContactFormInitialData {
    shippingCountries {
      id
      code
      contactInformation {
        id
        contactHours
        whatsappContactHours
        contactNumber
        displayContactNumber
        whatsappNumber
        displayWhatsappNumber
      }
    }
    user {
      id
      firstName
      lastName
      preferredLanguage
      email
      shippingCountry {
        id
        code
      }
      dogs {
        id
        name
      }
    }
    allergens {
      id
      name
    }
  }
`

const INITIAL_DATA_PRE_WIZARD_GUEST_QUERY = gql`
  query ContactFormInitialDataPreWizardGuest {
    shippingCountries {
      id
      code
      contactInformation {
        id
        contactHours
        whatsappContactHours
        contactNumber
        displayContactNumber
        whatsappNumber
        displayWhatsappNumber
      }
    }
    allergens {
      id
      name
    }
  }
`

export { INITIAL_DATA_QUERY, INITIAL_DATA_PRE_WIZARD_GUEST_QUERY }
