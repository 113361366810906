// @noflow
import { useQuery } from '@apollo/client'
import {
  addDays,
  format,
  getDaysInMonth,
  isBefore,
  isDate,
  isSameDay,
  parseISO
} from 'date-fns'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import type { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import LoadingDog from 'assets/images/illustrations/dogs/loading-dog.svg'

import CalendarDates from './components/CalendarDates'
import DaysOfTheWeek from './components/DaysOfTheWeek'
import HeaderMonth from './components/HeaderMonth'
import {
  DeliveryDateQuery_user_subscription_box as Box,
  DeliveryDateQuery_user_subscription_nextNBoxes as UpcomingBoxes
} from '@/components/pages/ChangeDatePage/__generated__/DeliveryDateQuery'

import STYLES from './Calendar.module.sass'

import { CALENDAR_DATES_DATA } from './queries/calendarDatesQuery'

import type {
  CalendarDatesData_calendarDates as CalendarDate,
  CalendarDatesData,
  CalendarDatesDataVariables
} from './queries/__generated__/CalendarDatesData'
import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'

/**
 * This is necessary to maintain both a new and a legacy version of the `calendarDates` field.
 *
 * The new version uses the `userId` when querying delivery dates. This is because we need to
 * identify a specific user, rather than just their geographic area.
 *
 * The legacy version uses the `postcode` field to query delivery dates. As of the time of
 * writing this comment, the legacy version is still in use for the Checkout page.
 */
type UserIdVariant = {
  userId: string
  postcode?: never
  city?: never
}

type PostcodeVariant = {
  postcode: string
  city: CalendarDatesDataVariables['city']
  userId?: never
}

export type ForecastedDate = {
  forecastedDeliveryDate: string
  editable: boolean
  durationInDays: number
}

type Props = {
  shipmentType?: CalendarDatesDataVariables['shipmentType']
  selectedDate: Date | null
  deliveryDate: Date | null
  shippingCountryCode: CountryCode
  disableRegressCurrentMonth?: boolean
  disableProgressCurrentMonth?: boolean
  availableDeliveryDates?: Array<CalendarDate>
  shouldAttemptToOfferNextDayDelivery?: boolean
  setSelectedDate: (selectedDate: Date) => void
  handleCustomOnDateChange?: (newSelectedDate?: Date) => void
  upcomingBoxes?: Array<UpcomingBoxes>
  box?: Box
  onlyMoveThisBox?: boolean
} & (UserIdVariant | PostcodeVariant)

/**
 * # Calendar
 *
 * Note: You need to create `selectedDate` variable outside of the `Calendar`
 * component to use it outside of the component.
 *
 * @example
  ```ts
  const [selectedDate, setSelectedDate] = useState<Date>(deliveryDate)

  <Calendar
    userId={userId}
    deliveryDate={deliveryDate}
    selectedDate={selectedDate}
    setSelectedDate={setSelectedDate}
    shippingCountryCode={shippingCountryCode}
  />
  ```
 *
 * @param {string} city - city name
 * @param {Date} selectedDate - date value
 * @param {Function} setSelectedDate - set selected date handler
 * @param {Date} deliveryDate - initial delivery date to define initial month in view
 * @param {"GB" | "NI" | "IE" | "NL" | "BE" | "PL" | "DE"} shippingCountryCode - country code
 * @param {Array<Date>} [futureBoxesDates] - dates of the future boxes
 * @param {boolean} [disableRegressCurrentMonth=false] - disabled regress functionality
 * @param {boolean} [disableProgressCurrentMonth=false] - disabled progress functionality
 * @param {boolean} [shouldAttemptToOfferNextDayDelivery=false] - should attempt to offer next day delivery
 * @param {string} [postcode] - postcode is used to query calendar dates
 * @param {string} [userId] - user id
 * @param {Function} [handleCustomOnDateChange] - custom event on date change
 *
 */
const Calendar = ({
  city,
  selectedDate,
  deliveryDate,
  shippingCountryCode,
  shipmentType = 'box',
  disableRegressCurrentMonth = false,
  disableProgressCurrentMonth = false,
  availableDeliveryDates,
  setSelectedDate,
  handleCustomOnDateChange,
  shouldAttemptToOfferNextDayDelivery,
  postcode,
  userId,
  upcomingBoxes,
  box,
  onlyMoveThisBox = false
}: Props): ReactElement => {
  const { t } = useTranslation('dashboard')

  /**
   * As an `initialMonthInView` we use the first day of the month, we want to make
   * sure that we'll get the first day of the month no matter what timezone is
   * used. To do that we set hours manually to 10h to avoid the 00:00:00 time.
   */

  const today = new Date()
  const initialMonthInView = deliveryDate
    ? new Date(deliveryDate.getFullYear(), deliveryDate.getMonth(), 1, 10)
    : new Date(today.getFullYear(), today.getMonth(), 1, 10)

  const [currentMonthInView, setCurrentMonthInView] =
    useState(initialMonthInView)
  const [dates, setDates] = useState<Array<CalendarDate>>([])

  const numberOfDaysInCurrentMonth = useMemo(
    () => getDaysInMonth(currentMonthInView) - 1,
    [currentMonthInView]
  )

  const parsedDate = useMemo(
    () => parseISO(box?.isoDeliveryDate),
    [box?.isoDeliveryDate]
  )

  const defaultForecastedDeliveryDates = useMemo(
    () =>
      upcomingBoxes &&
      upcomingBoxes.reduce(
        (
          result: Array<ForecastedDate>,
          { isoDeliveryDate: forecastedDeliveryDate, durationInDays }
        ) => {
          const parsedForecastedDeliveryDate = parseISO(forecastedDeliveryDate)

          if (!isSameDay(parsedDate, parsedForecastedDeliveryDate)) {
            const forecastedDateObj = {
              forecastedDeliveryDate,
              editable: isBefore(parsedDate, parsedForecastedDeliveryDate),
              durationInDays
            }

            result.push(forecastedDateObj)
          }

          return result
        },
        []
      ),
    [parsedDate, upcomingBoxes]
  )

  const [forecastedDeliveryDates, setForecastedDeliveryDates] = useState(
    defaultForecastedDeliveryDates
  )

  const adjustForecastedDates = useCallback(
    (date: Date) => {
      let durationOffset = 0
      const updatedForecastedDeliveryDates =
        forecastedDeliveryDates &&
        forecastedDeliveryDates.map(
          ({ forecastedDeliveryDate, editable, durationInDays }) => {
            if (editable) {
              durationOffset += durationInDays
              const adjustedForecastedDeliveryDate = format(
                addDays(date, durationOffset),
                'yyyy-MM-dd'
              )
              return {
                forecastedDeliveryDate: adjustedForecastedDeliveryDate,
                editable,
                durationInDays
              }
            } else {
              return {
                forecastedDeliveryDate,
                editable,
                durationInDays
              }
            }
          }
        )

      setForecastedDeliveryDates(updatedForecastedDeliveryDates)
    },
    [forecastedDeliveryDates]
  )

  const { loading, error, data } = useQuery<
    CalendarDatesData,
    CalendarDatesDataVariables
  >(CALENDAR_DATES_DATA, {
    variables: {
      calendarInitDate: currentMonthInView,
      nDays: numberOfDaysInCurrentMonth,
      shipmentType,
      ...(userId ? { userId } : { postcode, city }),
      shouldAttemptToOfferNextDayDelivery
    }
  })

  useEffect(() => {
    if (data) {
      if (availableDeliveryDates && availableDeliveryDates.length > 0) {
        const amendedDates = data.calendarDates.map((cd): CalendarDate => {
          const matchingDate = availableDeliveryDates.find(
            (ad) => ad.date === cd.date
          )
          return {
            __typename: cd.__typename,
            date: cd.date,
            deliverable: matchingDate ? matchingDate.deliverable : false
          }
        })
        setDates(amendedDates)
      } else {
        setDates(data.calendarDates)
      }
    }
  }, [availableDeliveryDates, data])

  useEffect(() => {
    if (!selectedDate && data) {
      const item = data.calendarDates.find(
        (calendarDate: CalendarDate) => calendarDate.deliverable
      )
      if (item?.date) {
        // Date needs to be formatted to be render properly
        // new Date('2022-01-27').toLocaleString()
        // '26/01/2022, 21:00:00'
        // new Date('2022/01/27').toLocaleString()
        // '27/01/2022, 00:00:00'
        setSelectedDate(new Date(item.date.replace(/-/g, '/')))
      }
    }
  }, [data, selectedDate, setSelectedDate])

  useEffect(() => {
    if (onlyMoveThisBox) {
      setForecastedDeliveryDates(defaultForecastedDeliveryDates)
    } else if (selectedDate && isDate(selectedDate) && !onlyMoveThisBox) {
      adjustForecastedDates(selectedDate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onlyMoveThisBox, selectedDate])

  return (
    <div className={STYLES.calendar}>
      <HeaderMonth
        currentMonthInView={currentMonthInView}
        setCurrentMonthInView={setCurrentMonthInView}
        disableRegressCurrentMonth={disableRegressCurrentMonth}
        disableProgressCurrentMonth={disableProgressCurrentMonth}
        shippingCountryCode={shippingCountryCode}
      />
      <DaysOfTheWeek />
      <div className={`${STYLES.days} ${loading ? STYLES.daysLoading : ''}`}>
        {loading ? (
          <img alt={t('calendar.fetching_dates_img_alt')} src={LoadingDog} />
        ) : error || !data ? null : (
          <CalendarDates
            calendarDates={dates}
            forecastedDeliveryDates={forecastedDeliveryDates}
            adjustForecastedDates={adjustForecastedDates}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            handleCustomOnDateChange={handleCustomOnDateChange}
            onlyMoveThisBox={onlyMoveThisBox}
          />
        )}
      </div>
    </div>
  )
}

export type { Props }
export { Calendar }
export default Calendar
