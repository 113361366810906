// @noflow
import { Factory } from 'fishery'

// eslint-disable-next-line no-restricted-imports
import type { Allergen } from '@/components/types'

export default Factory.define<Allergen>(({ sequence }) => ({
  id: sequence,
  // eslint-disable-next-line i18next/no-literal-string
  name: `Allergen ${sequence}`
}))
