import React, { useCallback, useMemo } from 'react'

import Icon from '@/components/elements/atoms/Icon/Icon'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from '../categories.module.sass'

import { CustomerIssueManagementInitialDataQuery_categories as EligibleCategory } from '../../../queries/__generated__/CustomerIssueManagementInitialDataQuery'

type CategoryProps = {
  category: EligibleCategory
  selectCategory: (id: string) => void
}

const Category = ({ category, selectCategory }: CategoryProps): JSX.Element => {
  const onClick = useCallback(
    () => selectCategory(category.id),
    [category, selectCategory]
  )

  const variant = useMemo(() => {
    return category.description &&
      category.description !== 'Description not found'
      ? 'display16'
      : 'textRegular16'
  }, [category])

  return (
    <button type={'button'} className={STYLES.categoryButton} onClick={onClick}>
      <div className={STYLES.wrapper}>
        <div>
          <Text
            align={'left'}
            text={category.name}
            colour={'brandBlue500'}
            variant={variant}
            translate={false}
          />
          {category.description &&
            category.description !== 'Description not found' && (
              <Text
                align={'left'}
                text={category.description}
                translate={false}
                colour={'brandBlue400'}
                variant={'textRegular16'}
              />
            )}
        </div>
      </div>
      <div className={STYLES.icon}>
        <Icon asset={'chevron'} size={16} />
      </div>
    </button>
  )
}

export default Category
