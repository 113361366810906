// @flow

export const MAKE_SEARCH_REQUEST = 'MAKE_SEARCH_REQUEST'
export const RECEIVED_SEARCH_RESPONSE = 'RECEIVED_SEARCH_RESPONSE'
export const MAKE_ACCOUNT_DETAILS_REQUEST = 'MAKE_ACCOUNT_DETAILS_REQUEST'
export const RECEIVED_ACCOUNT_DETAILS_RESPONSE = 'RECEIVED_ACCOUNT_DETAILS_RESPONSE'
export const UPDATE_SEARCH_STRING = 'UPDATE_SEARCH_STRING'
export const UPDATE_SELECTED_USER_ID = 'UPDATE_SELECTED_USER_ID'
export const MAKE_SUBSCRIPTION_DETAILS_REQUEST = 'MAKE_SUBSCRIPTION_DETAILS_REQUEST'
export const RECEIVE_SUBSCRIPTION_DETAILS_RESPONSE = 'RECEIVE_SUBSCRIPTION_DETAILS_RESPONSE'
export const MAKE_DELIVERIES_DETAILS_REQUEST = 'MAKE_DELIVERIES_DETAILS_REQUEST'
export const RECEIVE_DELIVERIES_DETAILS_RESPONSE = 'RECEIVE_DELIVERIES_DETAILS_RESPONSE'
export const UPDATE_TOP_DELIVERY_INDEX = 'UPDATE_TOP_DELIVERY_INDEX'
export const MAKE_APPLICABLE_DISCOUNTS_DETAILS_REQUEST = 'MAKE_APPLICABLE_DISCOUNTS_DETAILS_REQUEST'
export const RECEIVE_APPLICABLE_DISCOUNTS_DETAILS_RESPONSE = 'RECEIVE_APPLICABLE_DISCOUNTS_DETAILS_RESPONSE'
export const MAKE_DOGS_DETAILS_REQUEST = 'MAKE_DOGS_DETAILS_REQUEST'
export const RECEIVE_DOGS_DETAILS_RESPONSE = 'RECEIVE_DOGS_DETAILS_RESPONSE'
export const TOGGLE_DOG_DETAILS = 'TOGGLE_DOG_DETAILS'
export const TOGGLE_DOG_OVERVIEW = 'TOGGLE_DOG_OVERVIEW'
export const MAKE_CUSTOMER_HISTORY_REQUEST = 'MAKE_CUSTOMER_HISTORY_REQUEST'
export const RECEIVE_CUSTOMER_HISTORY_RESPONSE = 'RECEIVE_CUSTOMER_HISTORY_RESPONSE'
export const RECEIEVE_EMPTY_ACCOUNT_DETAILS_RESPONSE = 'RECEIEVE_EMPTY_ACCOUNT_DETAILS_RESPONSE'
export const RECEIEVE_EMPTY_SUBSCRIPTION_DETAILS_RESPONSE = 'RECEIEVE_EMPTY_SUBSCRIPTION_DETAILS_RESPONSE'
export const RECEIEVE_EMPTY_APPLICABLE_DISCOUNTS_DETAILS_RESPONSE = 'RECEIEVE_EMPTY_APPLICABLE_DISCOUNTS_DETAILS_RESPONSE'
export const RECEIEVE_EMPTY_DELIVERIES_DETAILS_RESPONSE = 'RECEIEVE_EMPTY_DELIVERIES_DETAILS_RESPONSE'
export const RECEIEVE_EMPTY_DOGS_DETAILS_RESPONSE = 'RECEIEVE_EMPTY_DOGS_DETAILS_RESPONSE'
export const RECEIEVE_EMPTY_CUSTOMER_HISTORY_RESPONSE = 'RECEIEVE_EMPTY_CUSTOMER_HISTORY_RESPONSE'
export const RECEIEVE_EMPTY_CONTACT_PREFERENCES_RESPONSE = 'RECEIEVE_EMPTY_CONTACT_PREFERENCES_RESPONSE'
export const PUSH_BOX_N_WEEKS = 'PUSH_BOX_N_WEEKS'
export const RESPOND_TO_BROWSER_NAVIGATION = 'RESPOND_TO_BROWSER_NAVIGATION'
export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS'
export const CLEAR_SEARCH_PARAMS = 'CLEAR_SEARCH_PARAMS'
export const CHANGE_BOX_DELIVERY_DATE = 'CHANGE_BOX_DELIVERY_DATE'
export const OPEN_DELIVERY_DATE_CALENDAR = 'OPEN_DELIVERY_DATE_CALENDAR'
export const DISPLAY_BANNER_MESSAGE = 'DISPLAY_BANNER_MESSAGE'
export const HIDE_BANNER_MESSAGE = 'HIDE_BANNER_MESSAGE'
export const HANDLE_DATE_CHANGE = 'HANDLE_DATE_CHANGE'
export const MAKE_CALENDAR_REQUEST = 'MAKE_CALENDAR_REQUEST'
export const RECEIVE_CALENDAR_RESPONSE = 'RECEIVE_CALENDAR_RESPONSE'
export const CLOSE_DELIVERY_DATE_CALENDAR = 'CLOSE_DELIVERY_DATE_CALENDAR'
export const TOGGLE_ADJUST_FUTURE_BOXES = 'TOGGLE_ADJUST_FUTURE_BOXES'
export const RESTART_SUBSCRIPTION = 'RESTART_SUBSCRIPTION'
export const TOGGLE_RESTART_MODAL = 'TOGGLE_RESTART_MODAL'
export const RECEIVE_DELIVERIES_HISTORY = 'RECEIVE_DELIVERIES_HISTORY'
export const MAKE_DELIVERIES_HISTORY_REQUEST = 'MAKE_DELIVERIES_HISTORY_REQUEST'
export const MAKE_CONTACT_PREFERENCES_REQUEST = 'MAKE_CONTACT_PREFERENCES_REQUEST'
export const RECEIVE_CONTACT_PREFERENCES_RESPONSE = 'RECEIVE_CONTACT_PREFERENCES_RESPONSE'
export const TOGGLE_CONTACT_PREFERENCES_MODAL = 'TOGGLE_CONTACT_PREFERENCES_MODAL'
export const UPDATE_DAY = 'UPDATE_DAY'
export const UPDATE_MONTH = 'UPDATE_MONTH'
export const UPDATE_YEAR = 'UPDATE_YEAR'
export const TOGGLE_CURRENT_TREATS_EMAIL_PREFERENCE = 'TOGGLE_CURRENT_TREATS_EMAIL_PREFERENCE'
export const TOGGLE_CURRENT_WOOLCOOL_RETURN_SCHEME_PREFERENCE = 'TOGGLE_CURRENT_WOOLCOOL_RETURN_SCHEME_PREFERENCE'
export const TOGGLE_CURRENT_WANTS_TO_RECEIVE_ICE_PREFERENCE = 'TOGGLE_CURRENT_WANTS_TO_RECEIVE_ICE_PREFERENCE'
export const TOGGLE_WANTS_TO_RECEIVE_ICE_REQUEST = 'TOGGLE_WANTS_TO_RECEIVE_ICE_REQUEST'
export const TOGGLE_WANTS_TO_RECEIVE_ICE_RESPONSE = 'TOGGLE_WANTS_TO_RECEIVE_ICE_RESPONSE'
export const MAKE_MARKETING_PREFERENCES_REQUEST = 'MAKE_MARKETING_PREFERENCES_REQUEST'
export const RECEIEVE_EMPTY_MARKETING_PREFERENCES_RESPONSE = 'RECEIEVE_EMPTY_MARKETING_PREFERENCES_RESPONSE'
export const RECEIVE_MARKETING_PREFERENCES_RESPONSE = 'RECEIVE_MARKETING_PREFERENCES_RESPONSE'
export const ERROR_UPDATING_MARKETING_PREFERENCES = 'ERROR_UPDATING_MARKETING_PREFERENCES'
const UPDATE_PRICING_COHORT_SELECTION = 'UPDATE_PRICING_COHORT_SELECTION'
const UPDATE_DURATION_IN_DAYS_SELECTION = 'UPDATE_DURATION_IN_DAYS_SELECTION'
const UPDATE_NUMBER_OF_POUCHES_SELECTION = 'UPDATE_NUMBER_OF_POUCHES_SELECTION'
const TOGGLE_PRICING_CURVE_TYPE_SELECTION = 'TOGGLE_PRICING_CURVE_TYPE_SELECTION'
const START_CHANGE_PLAN_REQUEST = 'START_CHANGE_PLAN_REQUEST'
const COMPLETE_CHANGE_PLAN_REQUEST = 'COMPLETE_CHANGE_PLAN_REQUEST'
const START_UPDATE_PROPOSED_PLAN_REQUEST = 'START_UPDATE_PROPOSED_PLAN_REQUEST'
const COMPLETE_UPDATE_PROPOSED_PLAN_REQUEST = 'COMPLETE_UPDATE_PROPOSED_PLAN_REQUEST'
const UPDATE_CHANGE_PLAN_MODAL_PROPOSED_PLAN = 'UPDATE_CHANGE_PLAN_MODAL_PROPOSED_PLAN'
const TOGGLE_CHANGE_PLAN_MODAL = 'TOGGLE_CHANGE_PLAN_MODAL'
const DISPLAY_INVALID_RESTART_DATE_MESSAGE = 'DISPLAY_INVALID_RESTART_DATE_MESSAGE'
const TOGGLE_SWITCH_TO_STRIPE_MODAL = 'TOGGLE_SWITCH_TO_STRIPE_MODAL'
const START_SWITCH_TO_STRIPE_REQUEST = 'START_SWITCH_TO_STRIPE_REQUEST'
const UPDATE_STRIPE_ID = 'UPDATE_STRIPE_ID'
const SWITCH_TO_STRIPE_SUCCESS = 'SWITCH_TO_STRIPE_SUCCESS'
const SWITCH_TO_STRIPE_FAILURE = 'SWITCH_TO_STRIPE_FAILURE'
const START_GET_SHIPPING_DETAILS_RESPONSE = 'START_GET_SHIPPING_DETAILS_RESPONSE'
const RECEIVE_SHIPPING_DETAILS_RESPONSE = 'RECEIVE_SHIPPING_DETAILS_RESPONSE'
const TOGGLE_ADD_CREDIT_CARD_TO_SUBSCRIPTION_MODAL = 'TOGGLE_ADD_CREDIT_CARD_TO_SUBSCRIPTION_MODAL'
const START_ADD_CREDIT_CARD_TO_SUBSCRIPTION_REQUEST = 'START_ADD_CREDIT_CARD_TO_SUBSCRIPTION_REQUEST'
const ADD_CREDIT_CARD_TO_SUBSCRIPTION_SUCCESS = 'ADD_CREDIT_CARD_TO_SUBSCRIPTION_SUCCESS'
const ADD_CREDIT_CARD_TO_SUBSCRIPTION_FAILURE = 'ADD_CREDIT_CARD_TO_SUBSCRIPTION_FAILURE'
const START_SEND_RAF_DETAILS_EMAIL_REQUEST = 'START_SEND_RAF_DETAILS_EMAIL_REQUEST'
const SEND_RAF_DETAILS_EMAIL_SUCCESS = 'SEND_RAF_DETAILS_EMAIL_SUCCESS'
const SEND_RAF_DETAILS_EMAIL_FAILURE = 'SEND_RAF_DETAILS_EMAIL_FAILURE'
const START_SEND_RAF_DETAILS_SMS_REQUEST = 'START_SEND_RAF_DETAILS_SMS_REQUEST'
const SEND_RAF_DETAILS_SMS_SUCCESS = 'SEND_RAF_DETAILS_SMS_SUCCESS'
const SEND_RAF_DETAILS_SMS_FAILURE = 'SEND_RAF_DETAILS_SMS_FAILURE'

export {
  UPDATE_PRICING_COHORT_SELECTION,
  UPDATE_DURATION_IN_DAYS_SELECTION,
  UPDATE_NUMBER_OF_POUCHES_SELECTION,
  TOGGLE_PRICING_CURVE_TYPE_SELECTION,
  START_CHANGE_PLAN_REQUEST,
  COMPLETE_CHANGE_PLAN_REQUEST,
  START_UPDATE_PROPOSED_PLAN_REQUEST,
  COMPLETE_UPDATE_PROPOSED_PLAN_REQUEST,
  UPDATE_CHANGE_PLAN_MODAL_PROPOSED_PLAN,
  TOGGLE_CHANGE_PLAN_MODAL,
  DISPLAY_INVALID_RESTART_DATE_MESSAGE,
  TOGGLE_SWITCH_TO_STRIPE_MODAL,
  START_SWITCH_TO_STRIPE_REQUEST,
  UPDATE_STRIPE_ID,
  SWITCH_TO_STRIPE_SUCCESS,
  SWITCH_TO_STRIPE_FAILURE,
  START_GET_SHIPPING_DETAILS_RESPONSE,
  RECEIVE_SHIPPING_DETAILS_RESPONSE,
  TOGGLE_ADD_CREDIT_CARD_TO_SUBSCRIPTION_MODAL,
  START_ADD_CREDIT_CARD_TO_SUBSCRIPTION_REQUEST,
  ADD_CREDIT_CARD_TO_SUBSCRIPTION_FAILURE,
  ADD_CREDIT_CARD_TO_SUBSCRIPTION_SUCCESS,
  START_SEND_RAF_DETAILS_EMAIL_REQUEST,
  SEND_RAF_DETAILS_EMAIL_SUCCESS,
  SEND_RAF_DETAILS_EMAIL_FAILURE,
  START_SEND_RAF_DETAILS_SMS_REQUEST,
  SEND_RAF_DETAILS_SMS_SUCCESS,
  SEND_RAF_DETAILS_SMS_FAILURE
}
