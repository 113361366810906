// @noflow
// Context
import React, { useContext } from 'react'
import type { ReactElement } from 'react'
import { Route, BrowserRouter as Router } from 'react-router-dom'

import { ErrorState } from '@/components/elements/organisms/ErrorState'

import { NoDogsPageContext } from '../index'

type Props = {
  children: ReactElement
}

type RoutePath = '/dashboard/no_dogs' | '/dashboard/no_dogs/error'

const NoDogsMainPath: RoutePath = '/dashboard/no_dogs'
const NoDogsErrorPath: RoutePath = '/dashboard/no_dogs/error'

const RouterWrapper = ({ children }: Props): ReactElement => {
  const { error } = useContext(NoDogsPageContext)

  return (
    <Router>
      {error ? (
        <Route path={NoDogsErrorPath} element={<ErrorState error={error} />} />
      ) : (
        <Route path={NoDogsMainPath} element={children} />
      )}
    </Router>
  )
}

export default RouterWrapper
