import { gql } from '@apollo/client'

import { DISCOUNT_CODE_FRAGMENT } from '../../../../../queries/fragments/DiscountCodeFragment'

const ADD_DISCOUNT_TO_GUEST = gql`
  ${DISCOUNT_CODE_FRAGMENT}
  mutation CheckoutAddDiscountToGuest($discountCode: String!) {
    addDiscountToGuest(code: $discountCode) {
      guest {
        discountCode {
          ...discountCodeFragment
        }
      }
    }
  }
`
export { ADD_DISCOUNT_TO_GUEST }
