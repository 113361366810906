import React, { createContext, useMemo, useState } from 'react'

import { MembershipTypes } from '@/types'

type ProviderProps = {
  children: JSX.Element | JSX.Element[]
  initialBoosted: boolean
  initialBoostedType: null | MembershipTypes
}

type Context = {
  boostedSubscription: boolean
  setBoostedSubscription: (val: boolean) => void
  boostedType: MembershipTypes | null
  setBoostedType: (data: MembershipTypes) => void
}
const ButternutBoostContext = createContext<Context>({
  boostedSubscription: false,
  setBoostedSubscription: () => undefined,
  boostedType: null,
  setBoostedType: () => null
})

const ButternutBoostProvider = ({
  children,
  initialBoosted = false,
  initialBoostedType = null
}: ProviderProps): JSX.Element => {
  const [boosted, setBoosted] = useState(initialBoosted)
  const [boostType, setBoostType] = useState(initialBoostedType)

  const context = useMemo(
    () => ({
      boostedSubscription: boosted,
      setBoostedSubscription: setBoosted,
      boostedType: boostType,
      setBoostedType: setBoostType
    }),
    [boosted, boostType]
  )

  return (
    <ButternutBoostContext.Provider value={context}>
      {children}
    </ButternutBoostContext.Provider>
  )
}

export { ButternutBoostContext, ButternutBoostProvider }
