import { gql } from '@apollo/client'

const PAUSED_CALENDAR_DATES_DATA = gql`
  query PausedCalendarDatesData(
    $calendarInitDate: ISO8601Date!
    $nDays: Int
    $city: String
    $shipmentType: String!
    $shouldAttemptToOfferNextDayDelivery: Boolean
    $postcode: String
    $userId: ID
  ) {
    user {
      id
      shippingCountryCode
      subscription {
        id
        nextNBoxes(num: 3) {
          id
          isoDeliveryDate
          editable
        }
      }
    }
    calendarDates(
      startDate: $calendarInitDate
      nDays: $nDays
      city: $city
      shipmentType: $shipmentType
      shouldAttemptToOfferNextDayDelivery: $shouldAttemptToOfferNextDayDelivery
      postcode: $postcode
      userId: $userId
    ) {
      date
      deliverable
      replacementDate
    }
  }
`

export { PAUSED_CALENDAR_DATES_DATA }
