// @noflow
import { Stripe, StripeElements, StripeError } from '@stripe/stripe-js'
import i18next from 'i18next'

import * as Sentry from '@/utils/sentry'

import client from '@/components/apollo/client'
import type {
  AddDiscountToGuest,
  AddDiscountToGuestVariables
} from '@/components/pages/PlansPage/components/RedeemDiscount/queries/__generated__/AddDiscountToGuest'
// GraphQL
import { ADD_DISCOUNT_TO_GUEST } from '@/components/pages/PlansPage/components/RedeemDiscount/queries/redeemDiscount'

import { STRIPE_CHARGE_BOX_ONE_MUTATION } from '../mutations/stripeChargeBoxOneAndSetupPaymentMethod'
import { STRIPE_USER_PARAMS_SETUP_INTENT_CREATE_MUTATION } from '../mutations/stripeUserParamsSetupIntentCreate'
import { CHARGE_ID_FROM_PAYMENT_INTENT_ID } from '../queries/chargeIdFromPaymentIntentId'

import { StripeChargeBoxOneAndSetupPaymentMethod } from '../mutations/__generated__/StripeChargeBoxOneAndSetupPaymentMethod'
import { StripeUserParamsSetupIntentCreate } from '../mutations/__generated__/StripeUserParamsSetupIntentCreate'
import { ChargeIdFromPaymentIntentId } from '../queries/__generated__/ChargeIdFromPaymentIntentId'

import type { AuthenticationType, PaymentMethod } from '../../../types'
import { bannerMessageState } from '../Banner'
import { setSubmissionState } from '../PaymentSectionWithStripe'
import { handleSubmitSubscription } from './buySubscriptionWithStripe'
import {
  paymentMethodToAuthenticationType,
  paymentMethodToPaymentMethodType
} from './formatPaymentMethod'
import { formatSubscriptionDataAddressForGraphql } from './formatSubscriptionDataAddressForGraphql'
import { SubscriptionData } from './submitSubscription'

const setErrorState = (error: string) => {
  bannerMessageState({
    message: error,
    type: 'error'
  })
  setSubmissionState({
    type: 'error',
    error: error
  })
}

const updateSessionDiscountCode = async () => {
  const sessionDiscountCode = window.sessionStorage.getItem('discount_code')

  if (!sessionDiscountCode) return

  try {
    const result = await client.mutate<
      AddDiscountToGuest,
      AddDiscountToGuestVariables
    >({
      mutation: ADD_DISCOUNT_TO_GUEST,
      variables: { discountCode: sessionDiscountCode }
    })

    if (result.errors) {
      setErrorState(result.errors[0].message)
      Sentry.captureException('Error in updateSessionDiscountCode', {
        extra: { message: result.errors[0].message },
        tags: { product: Sentry.Product.Checkout }
      })
    }
  } catch (error) {
    setErrorState(i18next.t('checkout:errors.delivery_details_fetch'))
    Sentry.captureException('Mutation error in updateSessionDiscountCode', {
      extra: { message: error },
      tags: { product: Sentry.Product.Checkout }
    })
  }
}

const attemptStripeSubmission = async ({
  elements
}: {
  elements: StripeElements
}): Promise<StripeError | void> => {
  try {
    const { error: submitError } = await elements.submit()
    if (submitError?.message) {
      setErrorState(submitError?.message)
      return submitError
    }
  } catch (error) {
    setErrorState(i18next.t('checkout:errors.delivery_details_fetch'))
  }
}

const stripeChargeBoxOneAndSetupPaymentMethod = async () => {
  try {
    const result = await client.mutate<StripeChargeBoxOneAndSetupPaymentMethod>(
      {
        mutation: STRIPE_CHARGE_BOX_ONE_MUTATION,
        variables: { paymentMethodType: 'auto' }
      }
    )

    if (result.errors) {
      setErrorState(result.errors[0].message)
      Sentry.captureException(
        'Error in stripeChargeBoxOneAndSetupPaymentMethod',
        {
          extra: { message: result.errors[0].message },
          tags: { product: Sentry.Product.Checkout }
        }
      )
    }
    return result.data?.stripeChargeBoxOneAndSetupPaymentMethod
  } catch (error) {
    setErrorState(i18next.t('checkout:errors.delivery_details_fetch'))
    Sentry.captureException(
      'Error in stripeChargeBoxOneAndSetupPaymentMethod',
      {
        extra: { message: error },
        tags: { product: Sentry.Product.Checkout }
      }
    )
  }
}

const confirmStripePayment = async ({
  stripe,
  elements,
  clientSecret
}: {
  stripe: Stripe
  elements: StripeElements
  clientSecret: string
}) => {
  try {
    const result = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: { return_url: window.location.href },
      redirect: 'if_required'
    })

    if (result.error) {
      setErrorState(
        result.error.message ||
          i18next.t('checkout:errors.delivery_details_fetch')
      )
      Sentry.captureException('Error in confirmStripePayment', {
        extra: { message: result.error.message },
        tags: { product: Sentry.Product.Checkout }
      })
    }
    return result
  } catch (error) {
    setErrorState(i18next.t('checkout:errors.delivery_details_fetch'))
    Sentry.captureException('Error in confirmStripePayment', {
      extra: { message: error },
      tags: { product: Sentry.Product.Checkout }
    })
  }
}

const retrieveChargeId = async ({
  paymentIntentId,
  paymentMethod
}: {
  paymentIntentId: string
  paymentMethod: PaymentMethod
}) => {
  try {
    const result = await client.query<ChargeIdFromPaymentIntentId>({
      query: CHARGE_ID_FROM_PAYMENT_INTENT_ID,
      variables: {
        paymentIntentId,
        paymentMethodType: paymentMethodToPaymentMethodType(paymentMethod)
      }
    })

    if (result.error) {
      Sentry.captureException('Error fetching charge ID', {
        extra: { message: result.error.message },
        tags: { product: Sentry.Product.Checkout }
      })
      setErrorState(result.error.message)
    }
    return result.data.chargeIdFromPaymentIntentId
  } catch (error) {
    Sentry.captureException('Query error in retrieveChargeId', {
      extra: { error: error },
      tags: { product: Sentry.Product.Checkout }
    })
    setErrorState(i18next.t('checkout:errors.delivery_details_fetch'))
  }
}

const createUserParams = async ({
  data,
  paymentIntentId,
  authenticationType
}: {
  data: SubscriptionData
  paymentIntentId: string | null
  authenticationType: AuthenticationType
}) => {
  try {
    const subscriptionData = formatSubscriptionDataAddressForGraphql(data)

    const result = await client.mutate<StripeUserParamsSetupIntentCreate>({
      mutation: STRIPE_USER_PARAMS_SETUP_INTENT_CREATE_MUTATION,
      variables: {
        authenticationType,
        subscriptionData,
        paymentIntentId
      }
    })

    if (result.errors) {
      setErrorState(result.errors[0].message)
      Sentry.captureException('Error in createUserParams', {
        extra: { message: result.errors[0].message },
        tags: { product: Sentry.Product.Checkout }
      })
    }

    return result.data?.stripeUserParamsSetupIntentCreate?.paymentIntentId
  } catch (error) {
    setErrorState(i18next.t('checkout:errors.delivery_details_fetch'))
    Sentry.captureException('Error in createUserParams', {
      extra: { message: error },
      tags: { product: Sentry.Product.Checkout }
    })
  }
}

const chargeStripeBoxOne = async ({
  stripe,
  elements,
  data,
  csrfToken,
  paymentMethod
}: {
  stripe: Stripe
  elements: StripeElements
  data: SubscriptionData
  csrfToken: string
  paymentMethod: PaymentMethod
}): Promise<void> => {
  try {
    await updateSessionDiscountCode()
    const stripeSubmissionAttemptError = await attemptStripeSubmission({
      elements
    })

    if (stripeSubmissionAttemptError) return

    const setupResult = await stripeChargeBoxOneAndSetupPaymentMethod()

    if (!setupResult?.stripeSecretToken) return

    const userParams = await createUserParams({
      data,
      paymentIntentId: setupResult.paymentIntentId,
      authenticationType: paymentMethodToAuthenticationType(paymentMethod)
    })

    if (!userParams) return

    const confirmResult = await confirmStripePayment({
      stripe,
      elements,
      clientSecret: setupResult.stripeSecretToken
    })

    if (!confirmResult?.paymentIntent?.payment_method) return

    const paymentIntentId = confirmResult.paymentIntent.id
    const paymentMethodId = confirmResult.paymentIntent.payment_method

    const chargeId = await retrieveChargeId({ paymentIntentId, paymentMethod })

    await handleSubmitSubscription({
      data,
      csrfToken,
      paymentMethodId,
      paymentMethodType: paymentMethod,
      boxOneChargeId: chargeId
    })
  } catch (error) {
    setErrorState(i18next.t('checkout:errors.delivery_details_fetch'))
  }
}

export default chargeStripeBoxOne
