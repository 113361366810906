// @noflow
import { Container } from '@material-ui/core'
import i18next from 'i18next'
import React, { RefObject, useCallback, useRef } from 'react'
import type { ReactElement } from 'react'

import {
  capitaliseFirstLetter,
  possessive,
  pronounContext,
  toLocalisedSentence
} from '@/utils/StringHelper'

import BRAND_COLOURS, { BrandColours } from '@/constants/BrandColours'

import useIntersectionObserver from '@/hooks/useIntersectionObserver'

import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'
import ProgressBar from '@/components/elements/atoms/ProgressBar/ProgressBar'
import SpeechBubble from '@/components/elements/atoms/SpeechBubble/SpeechBubble'
import Text from '@/components/elements/atoms/Text/Text'
import DogAvatarGroup from '@/components/elements/molecules/DogAvatarGroup/DogAvatarGroup'

import STYLES from './DogProfileSection.module.sass'

// eslint-disable-next-line no-restricted-imports
import type { thankYouQuery_user_dogs as Dog } from '../../__generated__/thankYouQuery'
import type { Gender } from '@/shared_types/rails_models/dogs'

type Props = {
  dogs: Dog[]
  background?: keyof Pick<BrandColours, 'brandYellow200'>
}

const DogProfileEntry = ({ dogs, background }: Props): ReactElement => {
  const namespace = 'thank_you'
  const copyContext = 'dog_profile_entry'

  const cardRef: RefObject<HTMLDivElement> = useRef(null)

  const dogGenders = dogs.map(({ gender }): Gender => gender)
  const dogNames = toLocalisedSentence({
    arr: dogs.map((dog) => capitaliseFirstLetter(dog.name)),
    lng: i18next.language
  })
  const possessiveDogNames = possessive(dogNames, i18next.language)

  const goToPostSignupWizard = useCallback(() => {
    window.location.href = '/post_sign_up_wizard'
  }, [])

  const showProgress = useIntersectionObserver(cardRef, { threshold: [0.2] })

  return (
    <section
      className={`${STYLES.section} ${background ? STYLES[background] : ''}`}
    >
      <Text
        text={`${copyContext}.title`}
        namespace={namespace}
        variant="display28"
        element="h2"
        variables={{
          possessiveDogNames,
          context: pronounContext(dogGenders, i18next.language)
        }}
        align="center"
      />
      <Container maxWidth="sm">
        <Card padding={0}>
          <div className={STYLES.cardInner} ref={cardRef}>
            <div className={STYLES.dogAvatar}>
              <DogAvatarGroup dogs={dogs} />
            </div>
            <div className={STYLES.speechBubble}>
              <SpeechBubble
                text={{
                  text: `${copyContext}.almost_done`,
                  variant: 'textRegular14',
                  element: 'div',
                  namespace
                }}
                footPosition="bottomRight"
                bubblePosition="center"
                variant="square"
              />
            </div>
            <ProgressBar
              percentFilled={showProgress ? 90 : 0}
              fillColour={BRAND_COLOURS.brandBlue500}
            />
            <Text
              namespace={namespace}
              text={`${copyContext}.description_text_html`}
              variables={{
                context: pronounContext(dogGenders, i18next.language)
              }}
              element="div"
            />
            <Button
              typography={{
                namespace,
                text: `${copyContext}.button_text_${pronounContext(
                  dogGenders,
                  i18next.language
                )}`
              }}
              disableAnalytics
              onClick={goToPostSignupWizard}
            />
          </div>
        </Card>
      </Container>
    </section>
  )
}

export default DogProfileEntry
