/* eslint-disable i18next/no-literal-string */
// @noflow
// Factories
import { Factory } from 'fishery'

import { InitialState } from '@/components/pages/SignupWizardPage/SignupWizardPage'
import type { Dog, User } from '@/components/pages/SignupWizardPage/types/types'
import {
  GoalSegment,
  WizardActivityLevel,
  WizardAgeStage,
  WizardBodyCondition,
  WizardEaterType,
  WizardSnackingHabits
} from '@/components/pages/SignupWizardPage/types/types'

import { Code, Gender, MarketingPreferences } from '@/types'

type WizardUserCheckoutFactoryType = {
  location: {
    countryCode: Code
    city: string
    postcode: string
    adressLine1: string
    phone: string
  }
  password: string
}

// Dog as per SignupWizard journey
const WizardDogFactory = Factory.define<Dog>(({ sequence }) => ({
  name: 'Dog name',
  gender: Gender.male,
  neutered: true,
  ageStage: WizardAgeStage.Adult,
  age: {
    weeks: null,
    months: 3,
    years: 2
  },
  isRescue: false,
  broughtHome: true,
  breed: {
    id: sequence,
    key: sequence.toString(),
    name: 'Breed 1'
  },
  foodCategoryIds: ['1'],
  eaterType: WizardEaterType.CanBeFussy,
  bodyCondition: WizardBodyCondition.JustRight,
  weight: 20,
  isWorkingDog: true,
  activityLevel: WizardActivityLevel.HyperActive,
  allergies: [],
  healthIssues: [],
  snackingHabits: WizardSnackingHabits.SomeSnacks
}))

const WizardUserFactory = Factory.define<User>(() => ({
  location: {
    countryCode: Code.GB,
    postcode: 'NW23BX'
  },
  name: `User 1`,
  email: `user+2@butternutbox.com`,
  marketingPreference: MarketingPreferences.full_consent,
  supportedMarketingMethodPurposeIds: [],
  goalSegment: GoalSegment.Convenience,
  trackingId: '1234'
}))
const WizardUserCheckoutFactory = Factory.define<WizardUserCheckoutFactoryType>(
  () => ({
    location: {
      countryCode: Code.GB,
      city: 'London',
      postcode: 'NW23BX',
      adressLine1: 'Flat 1 Address Line 1',
      phone: '07777777777'
    },
    password: 'Password1'
  })
)

const WizardFactory = Factory.define<InitialState>(() => ({
  user: WizardUserFactory.build(),
  dogs: WizardDogFactory.buildList(1)
}))

export { WizardDogFactory, WizardUserFactory, WizardUserCheckoutFactory }
export type { WizardUserCheckoutFactoryType }
export default WizardFactory
