// @noflow
import React from 'react'

import { AnalyticsProps } from '@/services/segment'

import Text from '@/components/elements/atoms/Text/Text'
import RadioGroup, {
  RadioOption
} from '@/components/elements/molecules/RadioGroup/RadioGroup'

import STYLES from '../SurveyCard.module.sass'

import type { SurveyCardQuery_survey_questions_options as SurveyQuestionOption } from '../queries/__generated__/SurveyCardQuery'

import { sendAnswerAnalyticsEvent } from '../SurveyCard'
import type { AnswerableCardProps, BaseCardProps } from '../SurveyCard'

type MultipleChoiceQuestionProps = BaseCardProps &
  AnswerableCardProps &
  AnalyticsProps

const MultipleChoiceQuestion = ({
  currentQuestion,
  setCurrentQuestionIndex,
  userId,
  surveyTitle,
  setAnswers,
  answers,
  ...analyticProps
}: MultipleChoiceQuestionProps): JSX.Element => {
  const handleClick = React.useCallback(
    (key: number) => {
      setCurrentQuestionIndex(currentQuestion.order + 1)
      answers[currentQuestion.order] = {
        optionId: currentQuestion.options[key].id,
        questionId: currentQuestion.id,
        value: key
      }
      setAnswers([...answers])
      sendAnswerAnalyticsEvent({
        userId,
        surveyTitle,
        question: currentQuestion.text,
        answer: key
      })
    },
    [
      currentQuestion,
      setCurrentQuestionIndex,
      userId,
      surveyTitle,
      setAnswers,
      answers
    ]
  )

  const options = currentQuestion.options.map(
    (option: SurveyQuestionOption, index: number): RadioOption => {
      return {
        id: option.id.toString(),
        text: {
          text: option.text,
          namespace: '',
          translate: false
        },
        value: index,
        defaultChecked: false
      }
    }
  )

  return (
    <div className={STYLES.surveyCard}>
      <Text
        text={currentQuestion.text}
        element={'h2'}
        variant="display20"
        colour={'brandBlue500'}
        margin
        align={'left'}
        translate={false}
      />
      <div className={STYLES.contentContainer}>
        <div className={STYLES.radioGroup}>
          <RadioGroup
            onChange={handleClick}
            variant="fullWidth"
            radioOptions={options}
            {...analyticProps}
          />
        </div>
      </div>
    </div>
  )
}

export default MultipleChoiceQuestion
