import { useNotifications } from '@/context/notifications/notifications'
import React, { useCallback, useState } from 'react'

import STYLES from './PasswordForm.module.sass'

import AlertCard from '../../atoms/Alert/AlertCard'
import { Button } from '../../atoms/Button'
import Form from '../../molecules/Form/Form'
import PasswordFields from '../../molecules/PasswordFields/PasswordFields'

type Passwords = {
  password: string
  confirmPassword: string
}

type Props = {
  onSubmit: (value: Passwords) => void
  onPasswordChanged?: (value: string) => void
  onConfirmPasswordChanged?: (value: string) => void
}

const PasswordForm = ({
  onSubmit,
  onPasswordChanged,
  onConfirmPasswordChanged
}: Props): JSX.Element => {
  const { setInfoNotification } = useNotifications()
  const [passwordsValid, setPasswordsValid] = useState(false)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const onFormSubmit = useCallback(() => {
    if (passwordsValid) {
      onSubmit({ password, confirmPassword })
    } else {
      setInfoNotification({
        text: 'my_details.personal_details.notification_no_change',
        namespace: 'dashboard'
      })
    }
  }, [confirmPassword, onSubmit, password, passwordsValid, setInfoNotification])

  return (
    <Form onSubmit={onFormSubmit}>
      <PasswordFields
        password={password}
        setPassword={setPassword}
        setConfirmPassword={setConfirmPassword}
        setPasswordsValid={setPasswordsValid}
        onPasswordChanged={onPasswordChanged}
        onConfirmPasswordChanged={onConfirmPasswordChanged}
      />
      <div className={STYLES.alertWrapper}>
        <AlertCard
          message={{
            text: 'my_details.personal_details.your_password.log_out_warning',
            namespace: 'dashboard'
          }}
          variant="info"
        />
      </div>
      <Button
        typography={{
          namespace: 'dashboard',
          text: 'my_details.personal_details.your_password.save'
        }}
        disableAnalytics
        disabled={!passwordsValid}
      />
    </Form>
  )
}

export { Props, Passwords }
export default PasswordForm
