import { useReactiveVar } from '@apollo/client'
import { format } from 'date-fns'
import React, { useCallback, useState } from 'react'

import { featureFlagsDataVar } from '@/services/apollo'

import cookies from '@/utils/cookies'
import { localeToDateLocale } from '@/utils/countryCodeHelper'

import useLocalStorage from '@/hooks/useLocalStorage'
import useWindowSize from '@/hooks/useWindowSize'

import PausedCalendar from './components/Calendar'
import FlatButton from '@/components/elements/atoms/FlatButton/FlatButton'
import Modal from '@/components/elements/atoms/Modal/Modal'
import Text from '@/components/elements/atoms/Text'
import IconCard from '@/components/elements/molecules/IconCard/IconCard'
import { BREAKPOINTS } from '@/components/templates/Base'

import STYLES from './PausedDatePicker.module.sass'

import { TreatmentsPausedPageQuery_user } from '../../queries/__generated__/TreatmentsPausedPageQuery'

type Props = {
  user: TreatmentsPausedPageQuery_user
  pausedDeliveryDate: Date
  setPausedDeliveryDate: (selectedDate: Date) => void
}

const PausedDatePicker = ({
  user,
  pausedDeliveryDate,
  setPausedDeliveryDate
}: Props): JSX.Element | null => {
  const [modalOpen, setmodalOpen] = useState(false)
  const [restartBoxDate, setRestartBoxDate] = useLocalStorage<string, Date>(
    cookies.restartBoxSelectedDate,
    pausedDeliveryDate
  )

  const { windowWidth } = useWindowSize()

  const featureFlagsData = useReactiveVar(featureFlagsDataVar)

  const allowEditAndReviewPlanBeforeReactivation =
    featureFlagsData?.allowEditAndReviewPlanBeforeReactivation === 'variant'

  const toggleModal = useCallback(() => setmodalOpen((prev) => !prev), [])

  const date = new Date(restartBoxDate)

  const handleDateChange = useCallback(
    (newDate) => {
      setRestartBoxDate(newDate)
    },
    [setRestartBoxDate]
  )
  const dateLocale = localeToDateLocale(
    user.shippingCountryCode,
    user.preferredLanguage
  )

  const handleSubmitDateChange = useCallback(
    (date) => {
      setPausedDeliveryDate(date)
      setRestartBoxDate(date)
      toggleModal()
    },
    [setPausedDeliveryDate, setRestartBoxDate, toggleModal]
  )

  return (
    <>
      <div className={STYLES.dateWrapper}>
        {allowEditAndReviewPlanBeforeReactivation ? (
          <div className={STYLES.dateEdit}>
            <div>
              <Text
                text={'delivery_details.delivery_date'}
                namespace="shared"
                margin={false}
                variant="display16"
              />
              <Text
                text={format(date, 'EEEE do MMM', { locale: dateLocale })}
                translate={false}
                margin={false}
              />
            </div>
            <FlatButton
              text={{
                text: 'delivery_details.edit',
                namespace: 'shared',
                translate: true
              }}
              onClick={toggleModal}
              identifier="restart_plan_card.edit_date"
            />
          </div>
        ) : (
          <IconCard
            padding={16}
            variant={{
              border: 'transparent',
              background: 'brandBlue100',
              selected: 'transparent'
            }}
            heading={{
              text: format(date, 'EEE dd MMM', { locale: dateLocale }),
              translate: false
            }}
            icon={{ asset: 'van' }}
            onClick={toggleModal}
            border={'solid'}
            identifier="paused_date_picker"
          />
        )}
      </div>

      <Modal
        isModalOpen={modalOpen}
        setOpenModal={toggleModal}
        width={800}
        bottomSticky={windowWidth < BREAKPOINTS.md}
        textAlign="center"
        backgroundColour="brandYellow100"
      >
        <Text
          text="home.upcoming_boxes.edit_delivery_date_cta"
          namespace="account"
          variant="display24"
          align="center"
        />

        <PausedCalendar
          userId={user.id}
          setSelectedDate={handleDateChange}
          pausedDeliveryDate={new Date(pausedDeliveryDate)}
          handleSubmit={handleSubmitDateChange}
        />
      </Modal>
    </>
  )
}

export { PausedDatePicker }
