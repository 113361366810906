// @noflow
import React from 'react'

import {
  Direction,
  ICON_COLOURS,
  IconColours
} from '@/components/elements/atoms/Icon/Icon'

import STYLES from './sprinkles.module.sass'

type Props = {
  size: number
  accentColour: IconColours
  width?: number | string
  direction?: Direction
}

const Sprinkles = ({
  size,
  accentColour,
  width,
  direction = 'left'
}: Props): JSX.Element => {
  return (
    <svg
      fill="none"
      height={size}
      viewBox="0 0 53 81"
      width={width || '100%'}
      className={`${direction ? STYLES[direction] : ''}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.4 12c0 .2 0 .4-.3.4a.4.4 0 0 1-.3-.3L.6 1C.6.8.6.6.9.6c.1 0 .3.1.3.3L2.4 12zm8.3.8c-.2.2-.4.3-.6.1a.4.4 0 0 1 0-.5l7.2-10.2c.2-.2.4-.3.6-.1v.5l-7.2 10.2zm7.8 8.2c-.2 0-.4 0-.5-.2 0-.1 0-.4.2-.4l9.3-5.4c.2 0 .4 0 .5.2 0 .1 0 .4-.2.4L18.5 21zm2 10c-.3 0-.5-.2-.5-.5s.2-.5.4-.5h9.2c.2 0 .4.2.4.5s-.2.5-.4.5h-9.2z"
        fill={ICON_COLOURS[accentColour]}
        stroke={ICON_COLOURS[accentColour]}
        strokeWidth="#000"
      />
    </svg>
  )
}

export default Sprinkles
