// @noflow
import { ACCOUNT_ROUTES } from '@/routes'
import { useReactiveVar } from '@apollo/client'
import i18next from 'i18next'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { SwiperClass } from 'swiper/react'

import { dogsDataVar } from '@/services/apollo'

import usePlan from '@/hooks/usePlan/usePlan'
import type { FrequencyOption, Plan } from '@/hooks/usePlan/usePlan'

import { Expand } from '@/components/elements/atoms/Animated/Animated'
import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'
import { FixedBase } from '@/components/elements/atoms/FixedBase'
import Pouch from '@/components/elements/atoms/Pouch/Pouch'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import SkeletonTitle from '@/components/elements/atoms/SkeletonTitle/SkeletonTitle'
import Text, { Props as TextProps } from '@/components/elements/atoms/Text/Text'
import SwiperSlider from '@/components/elements/molecules/SwiperSlider/SwiperSlider'

import STYLES from './DeliveryFrequencyPageV3.module.sass'

type DateCardType = {
  text: TextProps[]
  loading?: boolean
}

const DateCard = ({ text, loading }: DateCardType) => {
  if (loading) {
    return (
      <Card padding={0} shadow fill={false}>
        <div className={STYLES.date}>
          <SkeletonTitle height={28} width={30} />
          <SkeletonTitle height={20} width={70} />
        </div>
      </Card>
    )
  }
  return (
    <Card padding={0} shadow fill={false}>
      <div className={STYLES.date}>
        {text.map((text, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Text key={i} {...text} margin={false} />
        ))}
      </div>
    </Card>
  )
}

const DeliveryFrequencyPageV3 = (): JSX.Element | null => {
  const namespace = 'account'
  const copyContext = 'edit_delivery_frequency'
  const { dogs } = useReactiveVar(dogsDataVar) || {}
  const { pronoun } = useReactiveVar(dogsDataVar) || {}
  const navigate = useNavigate()
  const [durationSwiper, setDurationSwiper] = useState<SwiperClass>()
  const [servingsSwiper, setServingsSwiper] = useState<SwiperClass>()
  const [loading, setLoading] = useState<boolean>(true)
  const [initialised, setInitialised] = useState<boolean>(false)
  const [durationIndex, setDurationIndex] = useState<number>()
  const [servingsIndex, setServingsIndex] = useState<number>()
  const [selectedPlan, setSelectedPlan] = useState<Plan>()
  const [displayingCurrentPlan, setDisplayingCurrentPlan] = useState<
    boolean | undefined
  >()
  const { getPlan } = usePlan()
  const { currentPlan } = usePlan()
  const { planId } = useParams()
  const isPolish = ['pl', 'pl-PL'].includes(i18next.language)
  const container = useRef<HTMLDivElement>(null)
  const { updating } = usePlan()

  const onSelect = useCallback(() => {
    if (isUndefined(selectedPlan) || isUndefined(displayingCurrentPlan)) return
    if (displayingCurrentPlan) navigate(ACCOUNT_ROUTES.base)
    else navigate(`${ACCOUNT_ROUTES.reviewFrequency}/${selectedPlan.id}`)
  }, [displayingCurrentPlan, navigate, selectedPlan])

  useEffect(() => {
    if (!isUndefined(currentPlan) && !isUndefined(selectedPlan)) {
      setDisplayingCurrentPlan(currentPlan?.id === selectedPlan?.id)
    }
  }, [currentPlan, selectedPlan])

  useEffect(() => {
    if (!isUndefined(planId) && !isUndefined(getPlan)) {
      setSelectedPlan(getPlan(planId))
    }
  }, [getPlan, planId])

  useEffect(() => {
    if (!isUndefined(servingsSwiper)) {
      servingsSwiper.on('slideChange', (swiper) => {
        setServingsIndex(swiper.activeIndex)
      })
    }
  }, [servingsSwiper])

  useEffect(() => {
    if (!isUndefined(durationSwiper)) {
      durationSwiper.on('slideChange', (swiper) => {
        setDurationIndex(swiper.activeIndex)
      })
    }
  }, [durationSwiper])

  const { frequencyOptionsByNumberOfPouches } = usePlan()

  /**
   * Get the currently selected plan based off the currently
   * selected servings and duration.
   */
  useEffect(() => {
    if (
      !isUndefined(servingsIndex) &&
      !isUndefined(durationIndex) &&
      !isUndefined(frequencyOptionsByNumberOfPouches)
    ) {
      setSelectedPlan(
        frequencyOptionsByNumberOfPouches[servingsIndex].options[durationIndex]
      )
    }
  }, [servingsIndex, durationIndex, frequencyOptionsByNumberOfPouches])

  /**
   * Set initial plan on load
   */
  useEffect(() => {
    if (
      !initialised &&
      !isUndefined(servingsIndex) &&
      !isUndefined(durationIndex) &&
      !isUndefined(servingsSwiper) &&
      !isUndefined(durationSwiper)
    ) {
      servingsSwiper.slideTo(servingsIndex, 0, false)
      durationSwiper.slideTo(durationIndex, 0, false)

      setInitialised(true)
    }
  }, [
    durationIndex,
    durationSwiper,
    initialised,
    servingsIndex,
    servingsSwiper
  ])

  useEffect(() => {
    if (
      !initialised &&
      (!isUndefined(currentPlan) || !isUndefined(selectedPlan)) &&
      !isUndefined(frequencyOptionsByNumberOfPouches)
    ) {
      const id = selectedPlan?.id ?? currentPlan?.id

      const initialServingsIndex = frequencyOptionsByNumberOfPouches.findIndex(
        (frequencyOption: FrequencyOption) => {
          return (
            frequencyOption.options.findIndex((plan) => plan.id === id) > -1
          )
        }
      )

      if (initialServingsIndex !== -1) {
        setServingsIndex(initialServingsIndex)
        const initialDurationIndex = frequencyOptionsByNumberOfPouches[
          initialServingsIndex
        ].options.findIndex((plan) => plan.id === id)
        setDurationIndex(initialDurationIndex)
      } else {
        setServingsIndex(0)
        setDurationIndex(0)
      }
    }
  }, [
    currentPlan,
    frequencyOptionsByNumberOfPouches,
    initialised,
    selectedPlan
  ])

  /**
   * Handle loading state
   */
  useEffect(() => {
    setLoading(
      isUndefined(currentPlan) ||
        isUndefined(frequencyOptionsByNumberOfPouches) ||
        isNull(dogs)
    )
  }, [currentPlan, dogs, frequencyOptionsByNumberOfPouches])

  if (loading || isUndefined(currentPlan))
    return (
      <div className={STYLES.container}>
        <SectionWrapper
          bgColour={'brandYellow200'}
          borderRadius={16}
          padding={32}
        >
          <div className={STYLES.currentPlan}>
            <SkeletonTitle width="80%" />
            <SkeletonTitle width="50%" />
          </div>
          <div className={STYLES.slider}>
            <Pouch>
              <SkeletonTitle width={35} height={28} />
              <SkeletonTitle width={70} height={20} />
            </Pouch>
          </div>
          <div className={STYLES.durationTitle}>
            <SkeletonTitle width={60} />
          </div>
          <div className={STYLES.slider}>
            <div className={STYLES.dateContainer}>
              <DateCard text={[]} loading />
            </div>
          </div>
        </SectionWrapper>
      </div>
    )

  return (
    <div className={STYLES.container}>
      <SectionWrapper
        bgColour={'brandYellow200'}
        borderRadius={16}
        padding={32}
      >
        <div className={STYLES.currentPlan}>
          <Text
            namespace={namespace}
            variant="display20"
            text={`${copyContext}.current`}
            variables={{
              pouches: currentPlan.numberOfPouches,
              count: currentPlan.durationInDays / 7
            }}
            align="center"
            margin={false}
          />
        </div>
        <div className={STYLES.slider}>
          <SwiperSlider
            slider={setServingsSwiper}
            variant="brandYellow300"
            slidesPerView={1}
            arrows
          >
            {!isUndefined(servingsIndex) &&
            !isUndefined(frequencyOptionsByNumberOfPouches) ? (
              frequencyOptionsByNumberOfPouches.map((frequencyOption) => (
                <Pouch key={frequencyOption.numberOfPouches}>
                  <Text
                    text={frequencyOption.numberOfPouches.toString()}
                    translate={false}
                    variant="display28"
                    margin={false}
                  />
                  <Text
                    text={`${copyContext}.pouch.size`}
                    variables={{
                      grams: frequencyOption.pouchSize
                    }}
                    namespace={namespace}
                    variant="textRegular14"
                    margin={false}
                  />
                </Pouch>
              ))
            ) : (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <></>
            )}
          </SwiperSlider>
        </div>
        <div className={STYLES.durationTitle}>
          <Text
            text={`${copyContext}.duration_in_days_title`}
            namespace={namespace}
            variant="textRegular20"
            margin={false}
          />
        </div>
        <div className={STYLES.slider}>
          <SwiperSlider
            slider={setDurationSwiper}
            variant="brandYellow300"
            slidesPerView={1}
            arrows
          >
            {!isUndefined(servingsIndex) &&
            !isUndefined(frequencyOptionsByNumberOfPouches)
              ? frequencyOptionsByNumberOfPouches[servingsIndex].options.map(
                  (plan: Plan): JSX.Element => (
                    <div className={STYLES.dateContainer} key={plan.id}>
                      <DateCard
                        text={[
                          {
                            text: (plan.durationInDays / 7).toString(),
                            variant: 'display24',
                            translate: false
                          },
                          {
                            text:
                              isPolish && plan.durationInDays / 7 === 8
                                ? `${copyContext}.delivery_frequency_selection.week_1`
                                : `${copyContext}.delivery_frequency_selection.week`,
                            variant: 'textRegular16',
                            variables: {
                              count: !isPolish
                                ? plan.durationInDays / 7
                                : undefined
                            },
                            namespace: namespace
                          }
                        ]}
                      />
                    </div>
                  )
                )
              : null}
          </SwiperSlider>
        </div>
        <FixedBase backgroundColor="brandWhite90" container={container}>
          <div className={STYLES.button}>
            <Button
              disabled={updating || isUndefined(selectedPlan)}
              onClick={onSelect}
              typography={{
                text: `${copyContext}.button.${
                  displayingCurrentPlan ? 'current' : 'update'
                }`,
                namespace,
                variables: {
                  context: pronoun
                }
              }}
              identifier="change_serving_size.update"
            />
            <Expand
              show={
                !isUndefined(displayingCurrentPlan) && displayingCurrentPlan
              }
            >
              <div className={STYLES.noChange}>
                <Text
                  text={`${copyContext}.subtext.current`}
                  variables={{
                    context: pronoun
                  }}
                  namespace={namespace}
                  margin={false}
                />
              </div>
            </Expand>
          </div>
        </FixedBase>
      </SectionWrapper>
    </div>
  )
}

export default DeliveryFrequencyPageV3
