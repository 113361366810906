/* eslint-disable react/jsx-no-useless-fragment */
// @noflow
import type { Language } from '@/packs/localisation'
import { ACCOUNT_ROUTES } from '@/routes'
import { useMutation, useQuery } from '@apollo/client'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { Code as CountryCode } from '@types'
import { format as formatDate } from 'date-fns'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import kebabCase from 'lodash/kebabCase'
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { toast } from 'react-toastify'

import {
  countryCodeToLocaleCurrency,
  localeToDateLocale
} from '@/utils/countryCodeHelper'
import { Options } from '@/utils/currency'
import { getTimezoneOffset } from '@/utils/getTimezoneOffset'
import * as Sentry from '@/utils/sentry'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import PuppyFetchingBox from 'assets/images/illustrations/dogs/puppy-fetching-box.svg'

import segmentTrack from '@/components/analytics/Analytics'
import AlertCard from '@/components/elements/atoms/Alert/AlertCard'
import { Button } from '@/components/elements/atoms/Button'
import Modal from '@/components/elements/atoms/Modal/Modal'
import SkeletonLoading from '@/components/elements/atoms/SkeletonLoading/SkeletonLoading'
import BoxBreakdown from '@/components/elements/molecules/BoxBreakdown/BoxBreakdown'
import { SkeletonFreshMealItem } from '@/components/elements/molecules/BoxItem/FreshMealItem/FreshMealItem'
import ShopItem, {
  Props as ShopItemProps
} from '@/components/elements/molecules/BoxItem/ShopItem/ShopItem'
import Calendar from '@/components/elements/molecules/Calendar/Calendar'
import ChangeAddress from '@/components/elements/molecules/ChangeAddress/ChangeAddress'
import DetailsCard from '@/components/elements/molecules/DetailsCard/DetailsCard'
import { transformExtra } from '@/components/elements/molecules/ExtrasModal/utils/transformExtras'
import NotificationContainer from '@/components/elements/molecules/NotificationContainer/NotificationContainer'
import NotificationContent from '@/components/elements/molecules/NotificationContent/NotificationContent'
import TitleAndMore from '@/components/elements/molecules/TitleAndMore/TitleAndMore'
import { BoxPriceBreakdown } from '@/components/elements/organisms/BoxPriceBreakdown/BoxPriceBreakdown'
import ConfirmationModal, {
  modalData
} from '@/components/elements/organisms/ConfirmationModal/ConfirmationModal'
import FreshMealList, {
  Recipe
} from '@/components/elements/organisms/FreshMealList/FreshMealList'
import LoadingScreen from '@/components/elements/organisms/LoadingScreen/LoadingScreen'
import { NavigateContext } from '@/components/pages/App'
import {
  calendarDatesQuery_calendarDates as CalendarDate,
  calendarDatesQuery
} from '@/components/pages/OneOffBoxPage/queries/__generated__/calendarDatesQuery'
import {
  oneOffBoxQuery_user_address as Address,
  oneOffBoxQuery_user_subscription_meal as Meal,
  oneOffBoxQuery_user_subscription_previewUncreatedFutureBox_order_physicalOrderProducts as PhysicalOrderProducts,
  oneOffBoxQuery
} from '@/components/pages/OneOffBoxPage/queries/__generated__/oneOffBoxQuery'
import {
  CALENDAR_DATES_QUERY,
  ONE_OFF_BOX_CREATE,
  ONE_OFF_BOX_QUERY
} from '@/components/pages/OneOffBoxPage/queries/queries'
import {
  MealsHeader,
  constructUpdatedAddressObject
} from '@/components/pages/OrderPage/OrderPage'
import Base from '@/components/templates/Base'

import STYLES from './OneOffBox.module.sass'

type Props = {
  variant?: Language
}

type RecipeProps = {
  pouchSize: number
  shippingCountryCode: CountryCode
  preferredLanguage: Language
  meals: Meal[] | null
  numberOfPouches: number
  durationInDays: number
  shouldSeeRecipeSurchargeTooltips: boolean
  shouldSeeRecipeSurchargePriceIncreasePl: boolean
}

type ShopSectionProps = {
  products: PhysicalOrderProducts[]
  currencyOptions: Options
}

type UpdatedAddressFields = Omit<Partial<Address>, '__typename'>

type DeliveryDetailsProps = {
  userId: string
  shippingCountryCode: CountryCode
  preferredLanguage: Language
  address: Address
  isoDeliveryDate: Date
  handleDeliveryDate: (date: string) => void
  handleAddress: (address: Address) => void
}

type CompleteOrderProps = {
  userId: string
  deliveryDate: string | Date
  address: Address
  namespace: string
  shippingCountryCode: CountryCode
  preferredLanguage: Language
  setShowCreatingBoxScreen: (show: boolean) => void
}

const Recipes = ({
  pouchSize,
  shippingCountryCode,
  preferredLanguage,
  meals,
  numberOfPouches,
  durationInDays,
  shouldSeeRecipeSurchargeTooltips,
  shouldSeeRecipeSurchargePriceIncreasePl
}: RecipeProps): JSX.Element | null => {
  const recipesInTheBox = meals?.filter(({ quantity }: Meal) => quantity > 0)

  const analyticsOnEditClick = useCallback((): void => {
    segmentTrack('One-Off Flow edit meals clicked')
  }, [])

  return (
    <section className={STYLES.section}>
      <div className={STYLES.boxBreakdown}>
        <BoxBreakdown
          pouchSize={pouchSize}
          pouches={numberOfPouches}
          cadence={durationInDays / 7}
        />
      </div>
      <MealsHeader
        amendable
        analyticsOnEditClick={analyticsOnEditClick}
        onEditModalCloseGoTo="/dashboard/one-off-boxes"
      />
      <SkeletonLoading
        contentReady={!isNull(meals)}
        template={
          <>
            <SkeletonFreshMealItem />
            <SkeletonFreshMealItem />
            <SkeletonFreshMealItem />
          </>
        }
      >
        <FreshMealList
          pouchSize={pouchSize}
          recipes={
            recipesInTheBox?.map((recipe): Recipe => {
              return {
                quantity: recipe.quantity,
                flavour: {
                  ...recipe.flavour,
                  thumbnail: recipe.flavour.thumbnail.src,
                  productLabels: recipe.flavour.productLabels || []
                }
              }
            }) || []
          }
          shippingCountryCode={shippingCountryCode}
          language={preferredLanguage}
          shouldSeeRecipeSurchargeTooltips={shouldSeeRecipeSurchargeTooltips}
          shouldSeeRecipeSurchargePriceIncreasePl={
            shouldSeeRecipeSurchargePriceIncreasePl
          }
        />
      </SkeletonLoading>
    </section>
  )
}

const transformExtras = (
  extras: PhysicalOrderProducts[],
  currencyOptions: Options
): ShopItemProps[] =>
  extras.map((extra: PhysicalOrderProducts) =>
    transformExtra(extra, currencyOptions)
  )

const Shop = ({ products, currencyOptions }: ShopSectionProps) => {
  const navigate = useContext(NavigateContext)
  const subscriptionLinkParams = useMemo(
    () =>
      new URLSearchParams({
        openModal: 'extras',
        onModalClose: '/dashboard/one-off-boxes',
        'analytics[link_clicked]': 'One-Off-Flow-My-Plan-Link-Cicked'
      }),
    []
  )

  const onClick = useCallback(() => {
    navigate(
      ACCOUNT_ROUTES.editExtras,
      // eslint-disable-next-line i18next/no-literal-string
      `/dashboard/subscription?${subscriptionLinkParams.toString()}`
    )
  }, [navigate, subscriptionLinkParams])

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {products.length > 0 && (
        <section className={STYLES.section} id="extras">
          <div className={STYLES.sectionTitle}>
            <TitleAndMore
              title="shop.title"
              more="shop.more"
              namespace="order"
              href="#"
              onClick={onClick}
              identifier="one_off_box.shop.more"
            />
          </div>
          {transformExtras(products, currencyOptions).map((item) => (
            <ShopItem key={kebabCase(item.productName)} {...item} />
          ))}
        </section>
      )}
    </>
  )
}

const DeliveryDetailsSection = ({
  userId,
  shippingCountryCode,
  preferredLanguage,
  address,
  isoDeliveryDate,
  handleAddress,
  handleDeliveryDate
}: DeliveryDetailsProps): JSX.Element => {
  const dateLocale = localeToDateLocale(shippingCountryCode, preferredLanguage)
  const { windowWidth } = useWindowSize()

  const [addressModalIsOpen, setAddressModalIsOpen] = useState(false)
  const [deliveryDateModalIsOpen, setDeliveryDateModalIsOpen] = useState(false)

  const updateAddress = useCallback(
    (updatedFields: UpdatedAddressFields): void => {
      handleAddress(constructUpdatedAddressObject(address, updatedFields))
      setAddressModalIsOpen(false)
      modalData({
        isOpen: true,
        namespace: 'shared',
        text: 'delivery_details.address_updated',
        delay: 3000
      })
      const eventName = 'One-Off Flow Address Updated'
      segmentTrack(eventName)
    },
    [address, handleAddress]
  )

  const updateDeliveryDate = useCallback(
    (date: Date): void => {
      handleDeliveryDate(getTimezoneOffset(date))
      setDeliveryDateModalIsOpen(false)
      modalData({
        isOpen: true,
        namespace: 'shared',
        text: 'delivery_details.date_updated',
        delay: 3000
      })
      const eventName = 'One-Off Flow Delivery Date Updated'
      segmentTrack(eventName)
    },
    [handleDeliveryDate]
  )

  const toggleAddressModal = useCallback((): void => {
    setAddressModalIsOpen(!addressModalIsOpen)
    const eventName = 'One-Off Flow Toggled Address Modal'
    segmentTrack(eventName)
  }, [addressModalIsOpen, setAddressModalIsOpen])

  const toggleDeliveryDatesModal = useCallback((): void => {
    setDeliveryDateModalIsOpen(!deliveryDateModalIsOpen)
    const eventName = 'One-Off Flow Toggled Date Modal'
    segmentTrack(eventName)
  }, [deliveryDateModalIsOpen])

  const recipientName = Object.keys(address)
    .filter((key: string) => key === 'recipientName')
    .map((key: string): string | null => {
      if (address[key as keyof Address] === '') return null
      return `${address[key as keyof Address]}`
    })
  const addressLines = Object.keys(address)
    .filter((key: string) => key === 'address1' || key === 'address2')
    .map((key: string): string | null => {
      if (address[key as keyof Address] === '') return null
      return `${address[key as keyof Address]}`
    })
    .join(', ')
  const cityAndPostcode = Object.keys(address)
    .filter((key: string) => key === 'city' || key === 'postcode')
    .map((key: string): string | null => {
      if (address[key as keyof Address] === '') return null
      return `${address[key as keyof Address]}`
    })
    .join(', ')
  // eslint-disable-next-line i18next/no-literal-string
  const addressCopy = `${recipientName}<br>${addressLines}<br>${cityAndPostcode}`

  return (
    <Grid container spacing={3}>
      <Grid item sm={12}>
        <DetailsCard
          icon="van"
          iconSize={19}
          label={{
            text: 'delivery_details.delivery_date'
          }}
          onClick={toggleDeliveryDatesModal}
          prompt={'shared:delivery_details.edit'}
          text={{
            text: formatDate(new Date(isoDeliveryDate), 'iiii dd MMMM', {
              locale: dateLocale
            }),
            translate: false
          }}
          namespace="shared"
          identifier="delivery_details.change_date"
        />
      </Grid>
      <Grid item sm={12}>
        <DetailsCard
          icon="house"
          iconSize={32}
          label={{
            text: 'delivery_details.address'
          }}
          onClick={toggleAddressModal}
          prompt={'shared:delivery_details.edit'}
          text={{
            text: `${addressCopy}`,
            translate: false
          }}
          namespace="shared"
          identifier="delivery_details.change_address"
        />
      </Grid>
      {addressModalIsOpen && (
        <Modal
          setOpenModal={toggleAddressModal}
          isModalOpen={addressModalIsOpen}
          width={600}
          fullHeight={windowWidth < BREAKPOINTS.md}
        >
          <ChangeAddress
            address={address}
            onChange={updateAddress}
            toggleModal={toggleAddressModal}
            shippingCountryCode={shippingCountryCode}
          />
        </Modal>
      )}
      {deliveryDateModalIsOpen && (
        <Modal
          setOpenModal={toggleDeliveryDatesModal}
          isModalOpen={deliveryDateModalIsOpen}
          width={600}
          bottomSticky={windowWidth < BREAKPOINTS.md}
        >
          <Calendar
            selectedDate={isoDeliveryDate}
            deliveryDate={isoDeliveryDate}
            shippingCountryCode={shippingCountryCode}
            setSelectedDate={updateDeliveryDate}
            userId={userId}
            shouldAttemptToOfferNextDayDelivery={false}
          />
          <div className={STYLES.alertCard}>
            <AlertCard
              message={{
                namespace: 'unavailable_dates_card',
                text: 'content',
                margin: false
              }}
              variant="info"
            />
          </div>
        </Modal>
      )}
    </Grid>
  )
}

const CompleteOrder = ({
  userId,
  deliveryDate,
  address,
  namespace,
  shippingCountryCode,
  preferredLanguage,
  setShowCreatingBoxScreen
}: CompleteOrderProps): JSX.Element | null => {
  const navigate = useContext(NavigateContext)
  const dateLocale = localeToDateLocale(shippingCountryCode, preferredLanguage)
  const [
    oneOffBoxCreate,
    { loading: oneOffBoxCreateLoading, error: oneOffBoxCreateError }
  ] = useMutation(ONE_OFF_BOX_CREATE)
  const completeOrder = useCallback(() => {
    oneOffBoxCreate({
      variables: {
        userId: userId,
        deliveryDate: deliveryDate,
        address: {
          recipientName: address.recipientName,
          addressLineOne: address.address1,
          addressLineTwo: address.address2,
          city: address.city,
          postcode: address.postcode,
          deliveryNotes: address.deliveryNotes
        }
      }
    })
      .then((res) => {
        if (res.errors) {
          Sentry.captureException(
            `OOBPage | Error occured in ONE_OFF_BOX_CREATE mutation:`,
            {
              extra: { res },
              tags: {
                product: Sentry.Product.Account,
                team: Sentry.Team.Retention
              }
            }
          )
          const eventName = 'One-Off Flow Order Failed'
          segmentTrack(eventName)
          toast.error(
            <NotificationContent
              copy={{
                text: 'order_failed',
                namespace: 'one_off_box_page'
              }}
            />,
            { toastId: 'one-off-box-complete-order-error' }
          )
        } else {
          const eventName = 'One-Off Flow Box Ordered'
          segmentTrack(eventName)
          const formattedDate = formatDate(
            new Date(deliveryDate),
            'iiii, dd MMMM',
            {
              locale: dateLocale
            }
          )
          localStorage.setItem('delivery_date_oob', formattedDate)
          navigate(
            ACCOUNT_ROUTES.oneOffBoxConfirmation,
            `/dashboard/one-off-boxes/order-confirmation`
          )
        }
        setShowCreatingBoxScreen(false)
      })
      .catch((error) => {
        Sentry.captureException(error, {
          extra: { error },
          tags: {
            product: Sentry.Product.Account,
            team: Sentry.Team.Retention
          }
        })
        toast.error(
          <NotificationContent
            copy={{
              text: 'order_failed',
              namespace: 'one_off_box_page'
            }}
          />,
          { toastId: 'one-off-box-complete-order-error-message' }
        )
        setShowCreatingBoxScreen(false)
      })
  }, [
    oneOffBoxCreate,
    userId,
    deliveryDate,
    address.recipientName,
    address.address1,
    address.address2,
    address.city,
    address.postcode,
    address.deliveryNotes,
    setShowCreatingBoxScreen,
    dateLocale,
    navigate
  ])

  useEffect(() => {
    setShowCreatingBoxScreen(oneOffBoxCreateLoading && !oneOffBoxCreateError)
  }, [oneOffBoxCreateError, oneOffBoxCreateLoading, setShowCreatingBoxScreen])

  return (
    <div className={`${STYLES.btnWrapper}`}>
      <Button
        disabled={oneOffBoxCreateLoading}
        onClick={completeOrder}
        typography={{
          text: 'cta',
          namespace
        }}
        variant="primary"
        identifier="one_off_box.complete_order_button"
      />
    </div>
  )
}

const OneOffBox = ({ variant }: Props): JSX.Element => {
  const namespace = 'one_off_box_page'

  const [deliveryDate, setDeliveryDate] = useState<Date>()
  const [boxAddress, setAddress] = useState<Address>()
  const [showCreatingBoxScreen, setShowCreatingBoxScreen] = useState(false)
  const { loading, data, error } = useQuery<oneOffBoxQuery>(ONE_OFF_BOX_QUERY)

  const {
    loading: calendarLoading,
    data: calendarData,
    error: calendarError
  } = useQuery<calendarDatesQuery>(CALENDAR_DATES_QUERY, {
    variables: {
      nDays: 14,
      userId: data?.user?.id
    }
  })

  useEffect(() => {
    if (data?.user && calendarData?.calendarDates) {
      const { address } = data?.user
      const initialDate = new Date(
        calendarData?.calendarDates.find(
          (d: CalendarDate): boolean => d.deliverable
        )?.date
      )
      setDeliveryDate((initialDate && initialDate) || new Date())
      setAddress(address)
    }
  }, [data?.user, calendarData?.calendarDates])

  const handleDeliveryDate = useCallback((date: string) => {
    setDeliveryDate(new Date(date))
  }, [])

  const handleAddress = useCallback((address: Address) => {
    setAddress(address)
  }, [])

  if (error) {
    Sentry.captureException('OOBPage | Error occured in ONE_OFF_BOX_QUERY', {
      extra: { error },
      tags: {
        product: Sentry.Product.Account,
        team: Sentry.Team.Retention
      }
    })
    const eventName = 'One-Off Flow Request Failed'
    segmentTrack(eventName)
    toast.error(
      <NotificationContent
        copy={{
          text: 'request_failed',
          namespace: 'one_off_box_page'
        }}
      />,
      { toastId: 'one-off-box-complete-order-error' }
    )
  }
  if (calendarError) {
    Sentry.captureException('OOBPage | Error occured in CALENDAR_DATES_QUERY', {
      extra: { calendarError },
      tags: {
        product: Sentry.Product.Account,
        team: Sentry.Team.Retention
      }
    })
  }

  if (showCreatingBoxScreen) {
    return (
      <LoadingScreen
        language={variant}
        isOpen
        title={{
          text: 'loading_screen.creating_box.title',
          namespace: namespace
        }}
        variant="static"
        image={PuppyFetchingBox}
      />
    )
  }
  if (
    loading ||
    !data ||
    !data.user.address ||
    isNull(data.user.subscription.previewUncreatedFutureBox) ||
    calendarLoading ||
    !calendarData ||
    isUndefined(deliveryDate) ||
    isUndefined(boxAddress) ||
    !data.user.dogs
  ) {
    return (
      <LoadingScreen
        language={variant}
        isOpen
        title={{
          text: 'loading_screen.fetching.title',
          namespace: namespace
        }}
        variant="static"
        image={PuppyFetchingBox}
      />
    )
  }

  const {
    user: {
      id,
      shippingCountryCode,
      preferredLanguage,
      subscription: { meal, plan, previewUncreatedFutureBox }
    }
  } = data

  const currencyOptions: Options = countryCodeToLocaleCurrency(
    shippingCountryCode,
    preferredLanguage
  )

  return (
    <>
      <Base background="brandYellow100">
        <Container maxWidth="lg">
          <div className={STYLES.container}>
            <Grid container spacing={3}>
              <Grid item sm={12} md={6}>
                <Grid container spacing={3}>
                  <Grid item sm={12}>
                    <DeliveryDetailsSection
                      shippingCountryCode={shippingCountryCode}
                      preferredLanguage={preferredLanguage}
                      address={boxAddress}
                      isoDeliveryDate={deliveryDate}
                      handleDeliveryDate={handleDeliveryDate}
                      handleAddress={handleAddress}
                      userId={id}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <Recipes
                      pouchSize={plan.pouchSize}
                      shippingCountryCode={shippingCountryCode}
                      preferredLanguage={preferredLanguage}
                      meals={meal}
                      numberOfPouches={plan.numberOfPouches}
                      durationInDays={plan.durationInDays}
                      shouldSeeRecipeSurchargeTooltips={
                        data?.shouldSeeRecipeSurchargeTooltips
                          ? JSON.parse(data.shouldSeeRecipeSurchargeTooltips)
                          : false
                      }
                      shouldSeeRecipeSurchargePriceIncreasePl={
                        data?.shouldSeeRecipeSurchargePriceIncreasePl
                          ? JSON.parse(
                              data.shouldSeeRecipeSurchargePriceIncreasePl
                            )
                          : false
                      }
                    />
                  </Grid>
                  {previewUncreatedFutureBox.order.physicalOrderProducts
                    .length > 0 && (
                    <Grid item sm={12}>
                      <Shop
                        products={
                          previewUncreatedFutureBox.order.physicalOrderProducts
                        }
                        currencyOptions={currencyOptions}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item sm={12} md={6}>
                <section className={`${STYLES.section}`}>
                  <BoxPriceBreakdown
                    deliveryFee={
                      previewUncreatedFutureBox.order.deliveryFee || 0
                    }
                    extras={
                      previewUncreatedFutureBox.order.physicalProductsTotal ||
                      undefined
                    }
                    meals={[
                      {
                        perDay: previewUncreatedFutureBox.order.planTotalPerDay,
                        total: previewUncreatedFutureBox.order.planTotal
                      }
                    ]}
                    preferredLanguage={preferredLanguage}
                    shippingCountryCode={shippingCountryCode}
                    surchargeTotal={
                      previewUncreatedFutureBox.order.surchargeTotal
                    }
                    total={previewUncreatedFutureBox.order.totalPrice}
                  />
                  <div className={`${STYLES.alertWrapper}`}>
                    <AlertCard
                      message={{
                        text: 'delivery_notice',
                        namespace: namespace,
                        align: 'left'
                      }}
                      variant="info"
                    />
                  </div>
                  <CompleteOrder
                    namespace={namespace}
                    userId={id}
                    deliveryDate={deliveryDate}
                    address={boxAddress}
                    shippingCountryCode={shippingCountryCode}
                    preferredLanguage={preferredLanguage}
                    setShowCreatingBoxScreen={setShowCreatingBoxScreen}
                  />
                </section>
              </Grid>
            </Grid>
          </div>
        </Container>
      </Base>
      <ConfirmationModal />
      <NotificationContainer autoClose={5000} />
    </>
  )
}

export { Props }

export default OneOffBox
