import { gql } from '@apollo/client'

const MERCH_ITEM_QUERY = gql`
  query NotificationsQuery {
    notifications {
      id
      image {
        src
      }
      link
      altText
      order
      text
    }
    user {
      id
      shippingCountryCode
      preferredLanguage
      subscription {
        activeRafCampaignVariant {
          rafCampaign {
            name
          }
        }
        id
        referralLink {
          referrerDiscount {
            applicableDiscountParts {
              discountBasis
              value
            }
          }
        }
      }
    }
  }
`

export { MERCH_ITEM_QUERY }
