// @noflow
import classnames from 'classnames'
import isFunction from 'lodash/isFunction'
import isString from 'lodash/isString'
import isUndefined from 'lodash/isUndefined'
import React from 'react'
import { Link } from 'react-router-dom'

import Icon from '@/components/elements/atoms/Icon/Icon'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './simplenav.module.sass'

type SupportedColours = 'brandBlue400'

type Props = {
  color?: SupportedColours
  back?: string | (() => void)
  close?: string | (() => void)
}

const SimpleNav = ({
  color = 'brandBlue400',
  back,
  close
}: Props): JSX.Element | null => {
  const className = classnames(STYLES.container, {
    [STYLES.noBack]: isUndefined(back)
  })

  return (
    <nav className={className}>
      {back && (
        <div className={STYLES.backContainer}>
          <Link className={STYLES.link} to={isString(back) ? back : '#'}>
            <button
              type="button"
              className={STYLES.back}
              // eslint-disable-next-line react/jsx-no-bind
              onClick={isFunction(back) ? () => back() : undefined}
            >
              <Icon accentColour={color} asset="chevron" size={18} />
              <Text
                namespace="shared"
                text="navigation.back"
                margin={false}
                variant="textMono16"
                colour={color}
                shouldScale={false}
                element="span"
              />
            </button>
          </Link>
        </div>
      )}
      {close && (
        <div className={STYLES.closeContainer}>
          <Link className={STYLES.link} to={isString(close) ? close : '#'}>
            <button
              type="button"
              className={STYLES.close}
              // eslint-disable-next-line react/jsx-no-bind
              onClick={isFunction(close) ? () => close() : undefined}
            >
              <Icon accentColour={color} asset="close" size={18} />
            </button>
          </Link>
        </div>
      )}
    </nav>
  )
}

/***
 * Used alongside the HeroText component to add extra navigation items
 * ie. <SimpleNavHero />
 *     <HeroText />
 */
const SimpleNavHero = (props: Props): JSX.Element | null => {
  return (
    <div className={STYLES.hero}>
      <div className={STYLES.inner}>
        <SimpleNav {...props} />
      </div>
    </div>
  )
}

export { Props, SimpleNavHero }
export default SimpleNav
