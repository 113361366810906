import { useEffect } from 'react'

const useShowDixa = (visible = false): void => {
  useEffect(() => {
    const [element] = document.getElementsByClassName(
      'dixa-messenger-wrapper'
    ) as HTMLCollectionOf<HTMLElement>

    if (!element) return

    element.style.display = visible ? 'block' : 'none'

    return () => {
      if (element) {
        element.style.display = 'block'
      }
    }
  }, [visible])
}

export { useShowDixa }
