import classnames from 'classnames'
import { TOptions } from 'i18next'
import React from 'react'

import BrandedChevron from 'assets/images/icons/chevrons/chevron--blue-500.svg'

import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './Toggle.module.sass'

type Props = {
  toggleOpen: boolean
  namespace?: string
  text: string
  onClick: (param: boolean) => void
  variables?: TOptions
}

const Toggle = ({
  text,
  toggleOpen,
  namespace,
  onClick,
  variables
}: Props): JSX.Element => {
  const [isToggleOpen, setIsToggleOpen] = React.useState(toggleOpen)

  const onClickCallback = React.useCallback((): void => {
    setIsToggleOpen(!isToggleOpen)
    onClick(!isToggleOpen)
  }, [isToggleOpen, onClick])

  const className = classnames({ [STYLES.toggled]: isToggleOpen })

  return (
    <button
      className={STYLES.container}
      onClick={onClickCallback}
      type="button"
    >
      <Text
        namespace={namespace}
        text={text}
        margin={false}
        variant="textRegular16"
        element="span"
        variables={variables}
      />
      <img alt="" className={className} src={BrandedChevron} />
    </button>
  )
}

export { Props }
export default Toggle
