// @flow

import * as ACTIONS from '../actions'

import sendRafDetailsSmsQuery from '../graphql_queries/sendRafDetailsSms'

// Types
import type { Dispatch, Thunk } from 'redux'
import type { RequestResult } from '../graphql_queries/sendRafDetailsSms'
import type { State } from '../index'

const sendRafDetailsSms = (): Thunk => {
  return (dispatch: Dispatch, getState: () => State): Promise<void> => {
    dispatch(ACTIONS.startSendRafDetailsSmsRequest())
    const { selectedUserIds, csrfToken } = getState()
    const { userId } = selectedUserIds
    return sendRafDetailsSmsQuery({
      userId, csrfToken
    }).then((requestResult: RequestResult): void => {
      switch (requestResult.type) {
        case 'ServerError': {
          dispatch(ACTIONS.sendRafDetailsSmsFailure(requestResult.message))
          throw new Error(`Server Error: status=${requestResult.status}, message=${requestResult.message}`)
        }
        case 'UnknownError': {
          dispatch(ACTIONS.sendRafDetailsSmsFailure(requestResult.error))
          throw new Error(requestResult.error)
        }
        case 'Success': {
          return requestResult.success
            ? dispatch(ACTIONS.sendRafDetailsSmsSuccess())
            : dispatch(ACTIONS.sendRafDetailsSmsFailure(requestResult.errorMessage))
        }
        default: {
          throw new Error(`sendRafDetailsSms: unhandled type ${requestResult.type}`)
        }
      }
    })
  }
}

export default sendRafDetailsSms
