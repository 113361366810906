// @noflow
import { useCallback, useMemo, useState } from 'react'

type WebviewHanlder = {
  isWebview: boolean
  webviewMessage: WebviewMessage | null
  postMessage: (message: string, payload?: unknown) => void
  pageLoaded: () => void
}

type WebviewMessage = {
  message: string
  payload?: unknown
}

declare global {
  interface Window {
    ReactNativeWebView: {
      postMessage: (msg: string) => void
    }
  }
}

const useWebviewHanlder = (): WebviewHanlder => {
  const prefix = 'BUTTERNUTBOX-WEBVIEW-'
  const [webviewMessage, setWebviewMessage] = useState<WebviewMessage | null>(
    null
  )

  const isWebview = useMemo((): boolean => {
    return !!window.ReactNativeWebView
  }, [])

  const postMessage = useCallback((message: string, payload?: unknown) => {
    const webviewMessage: WebviewMessage = {
      message: prefix + message,
      payload
    }
    window.ReactNativeWebView.postMessage(JSON.stringify(webviewMessage))
  }, [])

  const pageLoaded = useCallback(() => {
    if (isWebview) {
      postMessage('PAGE-LOADED')
    }
  }, [isWebview, postMessage])

  window.addEventListener(
    'message',
    useCallback(
      (ev: MessageEvent) => {
        if (typeof ev.data !== 'string' || ev.data.indexOf(prefix) === -1) {
          return
        }
        const { message, payload }: WebviewMessage = JSON.parse(ev.data)
        if (message && message.indexOf(prefix) === 0) {
          setWebviewMessage({
            message: message.replace(prefix, ''),
            payload
          })
        }
      },
      [setWebviewMessage]
    )
  )

  return {
    isWebview,
    webviewMessage,
    postMessage,
    pageLoaded
  }
}

export default useWebviewHanlder
