// @noflow
import React, { useCallback } from 'react'

import {
  ComponentAnalyticsDisabled,
  ComponentAnalyticsEnabled
} from '@/services/segment'

import Card, {
  Props as CardComponentProps
} from '@/components/elements/atoms/Card/Card'
import { TextProps } from '@/components/elements/atoms/Text'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './SelectableCardGroup.module.sass'

type Value = string | number | boolean

type Icon = {
  src: string
  alt: string
  width?: number | string
  height?: number | string
}

type Option = {
  value: Value
  icon: Icon
  typography?: TextProps
  description?: TextProps
}

type OptionWithAnalytics = Option &
  Pick<ComponentAnalyticsEnabled, 'disableAnalytics' | 'identifier'>
type OptionWithoutAnalytics = Option &
  Pick<ComponentAnalyticsDisabled, 'disableAnalytics' | 'identifier'>

type OptionProps = OptionWithAnalytics | OptionWithoutAnalytics

type CardProps = Omit<
  CardComponentProps,
  'children' | 'identifier' | 'disableAnalytics' | 'screenIdentifier'
> & {
  contentDirection?: 'row' | 'column'
}

type Props = {
  options: Array<OptionProps>
  currentValue: Value
  onSelect: (value: Value) => void
  className?: string
  cardProps?: CardProps
}

type CardItemWithAnalytics = OptionWithAnalytics &
  Pick<Props, 'onSelect' | 'currentValue' | 'cardProps'>
type CardItemWithoutAnalytics = OptionWithoutAnalytics &
  Pick<Props, 'cardProps' | 'onSelect' | 'currentValue'>

type CardItemProps = CardItemWithAnalytics | CardItemWithoutAnalytics

const CardItem = ({
  value,
  typography,
  description,
  icon: { src, alt, height: iconHeight, width: iconWidth },
  currentValue,
  onSelect,
  cardProps,
  ...analyticProps
}: CardItemProps) => {
  const handleOnSelect = useCallback(() => {
    onSelect(value)
  }, [onSelect, value])

  const isSelected = currentValue === value

  const {
    className = '',
    contentDirection = 'column',
    ...restCardProps
  } = cardProps || {}

  return (
    <Card
      className={`${STYLES.card} ${className} ${STYLES[contentDirection]}`}
      onClick={handleOnSelect}
      selected={isSelected}
      shadow
      border="solid"
      {...analyticProps}
      {...restCardProps}
    >
      <div>
        <img src={src} alt={alt} width={iconWidth} height={iconHeight} />
        {typography && (
          <Text
            namespace="account"
            margin={false}
            variant="display16"
            align="center"
            {...typography}
          />
        )}
      </div>
      {description && isSelected && (
        <Text namespace="account" margin={false} {...description} />
      )}
    </Card>
  )
}

/**
 * SelectableCardGroup component
 *
 * Use this component to render a selectable card group.
 *
 * @example
  ```
  import { SelectableCardGroup } from 'components/molecules/SelectableCardGroup'

  <SelectableCardGroup
    options={[
      {
        value: '1',
        icon: {
          src: 'https://via.placeholder.com/150',
          alt: 'placeholder',
        },
        typography: {
          text: 'translation.path.text',
        }
      }
    ]}
    onSelect={(value) => setStateValue(value))}
    currentValue={stateValue}
  />
  ```
 * @param {Array<Option>} options - Array of options
 * @param {Value} currentValue - Current selected value
 * @param {Function} onSelect - Callback function to handle the selected value
 * @param {string} [className] - Class name to be added to the component
 * @param {CardProps} [cardProps] - Props to be passed to the card component
 * @category Components
 * @subcategory Molecules
 * @returns {JSX.Element} - SelectableCardGroup component
 *
 * This component is deprecated and will be removed in the future.
 * @deprecated in favour of `components/molecules/SelectableCardGroupV2`
 */
const SelectableCardGroup = ({
  options,
  className = '',
  ...restProps
}: Props): JSX.Element => {
  return (
    <div
      className={`${STYLES.wrapper} ${className} ${
        restProps.cardProps?.skeleton?.isLoading ? STYLES.loading : ''
      }`}
    >
      {options.map((option: OptionProps) => (
        <CardItem key={option.value.toString()} {...option} {...restProps} />
      ))}
    </div>
  )
}

export type { Props, Value }

export { SelectableCardGroup }
