// @noflow
import { ACCOUNT_ROUTES } from '@/routes'
import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { useGoBack } from '@/hooks/useGoBack/useGoBack'

import ExtrasContent from '@/components/elements/molecules/ExtrasModal/ExtrasContent'

const EditExtrasPage = (): JSX.Element => {
  const goBack = useGoBack()
  const { boxId } = useParams()

  const onDone = useCallback(
    (redirect = `${ACCOUNT_ROUTES.orders}/${boxId}`) => goBack(redirect),
    [boxId, goBack]
  )

  return <ExtrasContent boxId={boxId} onDone={onDone} />
}

export { EditExtrasPage }
