// @flow

import * as ACTIONS from '../actions'

import changePlanQuery from '../rails_queries/changePlan'

// Types
import type { Dispatch, Thunk } from 'redux'
import type { RequestResult } from '../rails_queries/changePlan'
import type { State } from '../index'

const changePlan = (): Thunk => {
  return (dispatch: Dispatch, getState: () => State): Promise<void> => {
    dispatch(ACTIONS.startChangePlanRequest())

    const { selectedUserIds, subscriptionDetails, csrfToken } = getState()
    const { subscriptionId } = selectedUserIds
    const { changePlanDetails } = subscriptionDetails
    const { proposedPlan } = changePlanDetails
    const { plan: { id } } = proposedPlan
    const planId = id
    return changePlanQuery({
      subscriptionId, csrfToken, planId
    }).then((requestResult: RequestResult): void => {
      switch (requestResult.type) {
        case 'ServerError': {
          dispatch(ACTIONS.receiveEmptyDeliveriesDetailsResponse())
          dispatch(ACTIONS.receiveEmptySubscriptionDetailsResponse())
          throw new Error(`Server Error: status=${requestResult.status}, message=${requestResult.message}`)
        }
        case 'UnknownError': {
          dispatch(ACTIONS.receiveEmptyDeliveriesDetailsResponse())
          dispatch(ACTIONS.receiveEmptySubscriptionDetailsResponse())
          throw new Error(requestResult.error)
        }
        case 'Success': {
          const { data } = requestResult
          const { deliveries_details, number_of_box_issues, number_of_boxes_delivered, subscription_details } = data
          dispatch(ACTIONS.receiveDeliveriesDetailsResponse(
            deliveries_details,
            number_of_box_issues,
            number_of_boxes_delivered
          ))
          dispatch(ACTIONS.receiveSubscriptionDetailsResponse(
            subscription_details
          ))
          dispatch(ACTIONS.completeChangePlanRequest())
          break
        }
        default: {
          (requestResult.type: empty) // eslint-disable-line no-unused-expressions
          throw new Error(`changePlan: unhandled type ${requestResult.type}`)
        }
      }
    })
  }
}

export default changePlan
