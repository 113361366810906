// @noflow
import React from 'react'

import DogAvatar from '@/components/elements/molecules/DogAvatar/DogAvatar'

import STYLES from './DogAvatarImage.module.sass'

import type { PetCareSectionDogsFragment as Dog } from '../../fragments/__generated__/PetCareSectionDogsFragment'

type Props = {
  dog: Dog
}

const DogAvatarImage = ({
  dog: { name, dogProfile, breed, ageInMonths }
}: Props): JSX.Element => (
  <div>
    {dogProfile?.avatarUrl ? (
      <div className={STYLES.wrapper}>
        <img alt={name} src={dogProfile?.avatarUrl} />
      </div>
    ) : (
      <div className={STYLES.wrapper}>
        <DogAvatar
          avatarUrl={dogProfile?.avatarUrl}
          sprinkles={false}
          breed={breed.name}
          ageInMonths={ageInMonths}
          border={false}
        />
      </div>
    )}
  </div>
)

export default DogAvatarImage
