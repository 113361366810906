// @noflow
import { isSameDay, parseISO } from 'date-fns'
import React, { Fragment, useCallback, useMemo } from 'react'
import type { ComponentProps, ReactElement } from 'react'

import scrollToElement from '@/utils/scrollToElement'

import STYLES from '../CalendarV2.module.sass'

import type { PausedCalendarDatesData_calendarDates as CalendarDateV1 } from '../../Calendar/queries/__generated__/PausedCalendarDatesData'
import { CalendarDatesV2Fragment as CalendarDate } from '../fragments/__generated__/calendarDatesV2Fragment'

import CalendarV2 from '../CalendarV2'

type CalendarProps = ComponentProps<typeof CalendarV2>

type Props = {
  calendarDates: Array<CalendarDate | CalendarDateV1>
  selectedDate: CalendarProps['selectedDate']
  currentDeliveryDate: CalendarProps['currentDeliveryDate']
  currentDeliveryDates: Array<Date>
  setSelectedDate: (newSelectedDate: Date) => void
  forecastedDeliveryDates?: Array<Date>
  scrollToSelectedDate: boolean
}

type CalendarDateCellProps = {
  date: Date
  deliverable: boolean
} & Pick<
  Props,
  | 'selectedDate'
  | 'currentDeliveryDate'
  | 'currentDeliveryDates'
  | 'setSelectedDate'
  | 'forecastedDeliveryDates'
  | 'scrollToSelectedDate'
>

const isInDateArray = (date: Date, dateArray?: Array<Date>): boolean | void => {
  if (!dateArray) return

  return !!dateArray.find((nextBoxDate: Date): boolean => {
    return isSameDay(nextBoxDate, date)
  })
}

const CalendarDateCell = ({
  date,
  deliverable,
  selectedDate,
  currentDeliveryDate,
  currentDeliveryDates,
  setSelectedDate,
  forecastedDeliveryDates,
  scrollToSelectedDate = true
}: CalendarDateCellProps): ReactElement => {
  const isSelectedDate = useMemo(
    () => selectedDate && isSameDay(selectedDate, new Date(date)),
    [date, selectedDate]
  )
  const isForecastedDate = useMemo(
    () => isInDateArray(date, forecastedDeliveryDates),
    [date, forecastedDeliveryDates]
  )
  const classNames = useMemo((): string => {
    const today = new Date()

    const isToday = isSameDay(today, date)
    const isNotDeliverable = !deliverable
    const isCurrentDeliveryDate =
      currentDeliveryDate && isSameDay(currentDeliveryDate, new Date(date))
    const isCurrentFutureDate = isInDateArray(date, currentDeliveryDates)
    const noDateSelected = !selectedDate

    const styles = [STYLES.day]

    if (isToday) styles.push(STYLES.today)
    if (isNotDeliverable) styles.push(STYLES.dayNotDeliverable)
    if (isSelectedDate) styles.push(STYLES.newSelectedDate)
    if (isCurrentDeliveryDate) styles.push(STYLES.currentDeliveryDate)
    else if (isCurrentFutureDate) styles.push(STYLES.currentFutureDate)
    if (isForecastedDate) styles.push(STYLES.forecastedDate)
    if (noDateSelected) styles.push(STYLES.active)

    return styles.join(' ')
  }, [
    currentDeliveryDate,
    currentDeliveryDates,
    date,
    deliverable,
    isForecastedDate,
    isSelectedDate,
    selectedDate
  ])

  const dayNumber = useMemo(() => new Date(date).getDay(), [date])
  const dateCopy = useMemo(() => new Date(date).getDate(), [date])

  const handleOnClick = useCallback(
    (e) => {
      if (scrollToSelectedDate) {
        scrollToElement(e.target)
      }
      setSelectedDate(new Date(date))
    },
    [date, setSelectedDate, scrollToSelectedDate]
  )

  return (
    <button
      className={classNames}
      type="button"
      disabled={!deliverable}
      style={{
        gridColumnStart: dayNumber === 0 ? 7 : dayNumber
      }}
      value={date.toString()}
      onClick={handleOnClick}
    >
      {dateCopy}
    </button>
  )
}

const CalendarDatesV2 = ({
  calendarDates,
  selectedDate,
  setSelectedDate,
  forecastedDeliveryDates,
  currentDeliveryDate,
  currentDeliveryDates,
  scrollToSelectedDate
}: Props): ReactElement => (
  <Fragment>
    {calendarDates.map(
      ({ date, deliverable }: CalendarDate | CalendarDateV1): ReactElement => (
        <CalendarDateCell
          key={date}
          date={parseISO(date)}
          deliverable={deliverable}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          forecastedDeliveryDates={forecastedDeliveryDates}
          currentDeliveryDates={currentDeliveryDates}
          currentDeliveryDate={currentDeliveryDate}
          scrollToSelectedDate={scrollToSelectedDate}
        />
      )
    )}
  </Fragment>
)

export default CalendarDatesV2
