// This just needs a field to add the stripe customer id
// and a submit button (and a message to remind them to check
// the default payment method on Stripe)

// @flow

import * as React from 'react'
import { connect } from 'react-redux'

import * as THUNKS from '../../thunks'
import * as ACTIONS from '../../actions'

import type { Dispatch } from 'redux'
import type { State } from '../../index'

type PresentationalProps = {|
  stripeId: string,
  isModalOpen: boolean,
  switchToStripeErrorMessage: ?string,
  switchToStripeInFlight: boolean
|}

type ActionProps = {|
  switchSubscriptionToStripe: () => void,
  closeSwitchToStripeModal: () => void,
  handleOnInputChange: (e: SyntheticEvent<HTMLInputElement>) => void,
|}

type Props =
  & PresentationalProps
  & ActionProps

const mapStateToProps = (state: State): PresentationalProps => {
  const { subscriptionDetails } = state
  const { switchToStripeDetails } = subscriptionDetails
  const { stripeId, isModalOpen, switchToStripeErrorMessage, switchToStripeInFlight } = switchToStripeDetails
  return {
    isModalOpen,
    stripeId,
    switchToStripeErrorMessage,
    switchToStripeInFlight
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => {
  const closeSwitchToStripeModal = (): void => dispatch(ACTIONS.toggleSwitchToStripeModal())

  const switchSubscriptionToStripe = (): void => {
    dispatch(THUNKS.switchSubscriptionToStripe())
  }

  const handleOnInputChange = (e: SyntheticEvent<HTMLInputElement>): void => {
    dispatch(ACTIONS.updateStripeId(e.currentTarget.value))
  }

  return {
    closeSwitchToStripeModal,
    switchSubscriptionToStripe,
    handleOnInputChange
  }
}

const SwitchToStripeModal = ({
  isModalOpen,
  stripeId,
  switchToStripeErrorMessage,
  closeSwitchToStripeModal,
  switchSubscriptionToStripe,
  switchToStripeInFlight,
  handleOnInputChange
}: Props): React.Node => {
  if (!isModalOpen) return null
  return (
    <div className='modal-container'>
      <div className='modal-container__backdrop' />
      <div className='delivery-address-modal__form-wrapper'>
        <div className='modal-container__modal restart-subscription-modal'>
          <h3>
            { 'Please enter the Stripe customer number:' }
          </h3>
          <input
            value={stripeId}
            onChange={handleOnInputChange}
            type='text'
          />
          { switchToStripeErrorMessage !== null && (
            <div className='card__row'>
              <p className='card__row__title'>
                { switchToStripeErrorMessage }
              </p>
            </div>
          ) }
          <div className='restart-subscription-modal__footer'>
            <button
              className='card__button button--dark-grey'
              onClick={closeSwitchToStripeModal}
              disabled={switchToStripeInFlight}
              type='button'
            >
              { 'CANCEL' }
            </button>
            <button
              className={`card__button button--green`}
              onClick={switchSubscriptionToStripe}
              disabled={switchToStripeInFlight || stripeId === ''}
              type='button'
            >
              { 'Submit' }
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SwitchToStripeModal)
