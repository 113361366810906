import React from 'react'

import MultiLineTextField from '@/components/elements/atoms/MultiLineTextField/MultiLineTextField'

import { CustomerIssueManagementInputInput } from '@/types'

type FreeFormProps = {
  label: string
  setValue: (stringValue: string) => void
  input: CustomerIssueManagementInputInput | null
}

const FreeForm = ({ label, setValue, input }: FreeFormProps): JSX.Element => {
  const onTextChange = React.useCallback(
    (e) => {
      setValue(e)
    },
    [setValue]
  )

  return (
    <MultiLineTextField
      label={label}
      onChange={onTextChange}
      initialValue={input?.stringValue}
      translate={false}
    />
  )
}

export default FreeForm
