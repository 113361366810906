// @flow

import * as ACTIONS from '../actions'
import * as MESSAGE_TYPES from '../message_types'

import type { Dispatch, Thunk } from 'redux'
import type { State } from '../index'

type ContactPreferencesFromServer = {|
  consented_to_marketing: boolean,
  wants_to_receive_treats_emails: boolean,
  opted_out_of_woolcool_return_scheme: boolean,
  wants_to_receive_ice: boolean
|}

const { fetch } = global

const getContactPreferences = (userId: number): Thunk => {
  return function (dispatch: Dispatch): void {
    dispatch(ACTIONS.makeContactPreferencesRequest())
    const endpoint = '/admin/single_customer_view/contact_preferences'
    const queryParams = `user_id=${userId}`
    fetch(`${endpoint}?${queryParams}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then((res: Response): void => {
        if (!res.ok) {
          res.json().then((): void => {
            dispatch(ACTIONS.receiveEmptyContactPreferencesResponse())
          })
        } else {
          res.json()
            .then((res: ContactPreferencesFromServer): void => {
              const action = ACTIONS.receiveContactPreferencesResponse(
                res.consented_to_marketing,
                res.wants_to_receive_treats_emails,
                res.opted_out_of_woolcool_return_scheme,
                res.wants_to_receive_ice
              )
              dispatch(action)
            })
        }
      })
  }
}

const updateContactPreferences = (userId: number, contactPreferences: MESSAGE_TYPES.ContactPreferences): Thunk => {
  return function (dispatch: Dispatch, getState: () => State): void {
    dispatch(ACTIONS.makeContactPreferencesRequest())
    const { csrfToken } = getState()
    const endpoint = '/admin/single_customer_view/contact_preferences'
    const queryParams = `user_id=${userId}`
    fetch(`${endpoint}?${queryParams}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify(contactPreferences)
    })
      .then((res: Response): void => {
        if (!res.ok) {
          res.json().then((): void => {
            dispatch(ACTIONS.receiveEmptyContactPreferencesResponse())
          })
        } else {
          res.json()
            .then((res: ContactPreferencesFromServer): void => {
              const action = ACTIONS.receiveContactPreferencesResponse(
                res.consented_to_marketing,
                res.wants_to_receive_treats_emails,
                res.opted_out_of_woolcool_return_scheme,
                res.wants_to_receive_ice
              )
              dispatch(action)
            })
        }
      })
  }
}

export { getContactPreferences, updateContactPreferences }
