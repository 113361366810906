// @flow

import * as React from 'react'
import { format } from 'date-fns'
import { connect } from 'react-redux'
import capitalize from 'lodash/capitalize'

import type { BoxStatus } from '@/shared_types/graphql/types/enums/box/boxStatus'

import type { State } from '../../index'

import * as THUNKS from '../../thunks'

import type { DeliveryHistory } from '../../message_types'
import type { Dispatch } from 'redux'

type PresentationalDelivery = {|
  boxId: number,
  deliveryNumber: number,
  boxStatus: BoxStatus,
  amountOfFood: string,
  numberOfDiscounts: number,
  priceSummary: {|
    grossPrice: string,
    discountValue: string,
    netPrice: string
  |},
  chargedStatus: {
    invoiceStatus: 'unknown' | 'free' | 'paid' | 'unpaid' | 'forgiven' | 'disputed',
    chargedAtDate: ?string,
    dueDate: ?string
  },
  deliveryDetails: {|
    numberOfOrderIssues: number,
    isAdHoc: boolean,
    shippingDate: string,
    deliveryDate: string,
    reportIds: Array<number>
  |}
|}

type PresentationalProps = {|
  isFetching: boolean,
  userId: number,
  deliveries: Array<PresentationalDelivery>
|}

type ActionProps = {|
  getDeliveriesHistory: ((number) => void)
|}

type Props =
  & PresentationalProps
  & ActionProps

const mapStateToProps = (state: State): PresentationalProps => {
  const { deliveries_history, fetchingStatuses, selectedUserIds } = state
  const { userId } = selectedUserIds
  const { deliveriesHistory } = fetchingStatuses
  const deliveries = deliveries_history.map((delivery: DeliveryHistory): PresentationalDelivery => {
    const {
      box_attributes,
      box_contents_summary,
      number_of_discounts,
      prices_summary,
      invoice_summary,
      delivery_summary
    } = delivery
    const { box_id, delivery_number, box_status } = box_attributes
    const { number_of_pouches, pouch_size } = box_contents_summary
    const { gross_price, value_of_discounts, net_price } = prices_summary
    const { status, charged_at, due_date } = invoice_summary
    const { is_ad_hoc, shipping_date, delivery_date, number_of_order_issues, report_ids } = delivery_summary

    return {
      boxId: box_id,
      deliveryNumber: delivery_number,
      boxStatus: box_status,
      amountOfFood: `${number_of_pouches} x ${pouch_size}g`,
      numberOfDiscounts: number_of_discounts,
      priceSummary: {
        grossPrice: gross_price,
        discountValue: value_of_discounts,
        netPrice: net_price
      },
      chargedStatus: {
        invoiceStatus: status,
        chargedAtDate: charged_at,
        dueDate: due_date
      },
      deliveryDetails: {
        numberOfOrderIssues: number_of_order_issues,
        reportIds: report_ids,
        isAdHoc: is_ad_hoc,
        shippingDate: shipping_date,
        deliveryDate: delivery_date
      }
    }
  })

  return { isFetching: deliveriesHistory, deliveries, userId }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => {
  const getDeliveriesHistory = (userId: number): void => {
    dispatch(THUNKS.getDeliveriesHistory(userId))
  }
  return { getDeliveriesHistory }
}

const renderTransition = ({ isFetching }: PresentationalProps): string =>
  isFetching ? 'loading' : 'loaded'

const renderLoadingIcon = ({ isFetching }: PresentationalProps): ?React.Element<'div'> => {
  if (isFetching) {
    return (
      <div className="loading-ring">
        <span></span><span></span><span></span><span></span>
      </div>
    )
  }
}

const generateBoxChargedLabels = (delivery: PresentationalDelivery): ?React.Node => {
  switch (delivery.chargedStatus.invoiceStatus) {
    case 'unknown': {
      return (
        <div className="label-container__label label-container__label--orange">
          Unknown
        </div>
      )
    }
    case 'free': {
      return (
        <div className="label-container__label label-container__label--green">
          Free
        </div>
      )
    }
    case 'paid': {
      return (
        <React.Fragment>
          <div className="label-container__label label-container__label--green">
            Yes
          </div>
          <div>
            { `Charged at:` }
            <br />
            {
              delivery.chargedStatus.chargedAtDate &&
              format(new Date(delivery.chargedStatus.chargedAtDate), 'EEEE, MMM do yyy - HH:mm')
            }
          </div>
        </React.Fragment>
      )
    }
    case 'unpaid': {
      return (
        <React.Fragment>
          <div className="label-container__label label-container__label--red">
            { `No` }
          </div>
          {
            delivery.boxStatus === 'active' && (
              <div>
                { `Charge due:` }
                <br />
                {
                  delivery.chargedStatus.dueDate &&
                  format(new Date(delivery.chargedStatus.dueDate), 'EEEE, MMM do yyy - HH:mm')
                }
              </div>
            )
          }
        </React.Fragment>
      )
    }
    case 'forgiven': {
      return (
        <div className="label-container__label label-container__label--orange">
          Forgiven
        </div>
      )
    }
    case 'disputed': {
      return (
        <div className="label-container__label label-container__label--orange">
          Disputed
        </div>
      )
    }
  }
}

const renderAdHocLabel = (delivery: PresentationalDelivery): ?React.Element<'div'> => {
  if (delivery.deliveryDetails.isAdHoc) {
    return (
      <div className='label-container'>
        <div className='label-container__label label-container__label--blue'>
          AdHoc
        </div>
      </div>
    )
  }
}

const renderDiscountRow = (deliveries: PresentationalDelivery, i: number): React.Node => (
  <tr key={i}>
    <td>
      {deliveries.deliveryNumber}
      <a
        href={'/admin/boxes/' + deliveries.boxId}
        target="_blank"
        rel="noopener noreferrer"
      >
        View box
      </a>
    </td>
    <td>
      { capitalize(deliveries.boxStatus) }
    </td>
    <td>{deliveries.amountOfFood}</td>
    <td>{deliveries.numberOfDiscounts}</td>
    <td>
      <div className='price-breakdown'>
        <div className='price-breakdown__row'>
          <span>Without discounts</span>
          <p>{deliveries.priceSummary.grossPrice}</p>
        </div>
        <div className='price-breakdown__row'>
          <span>With discounts</span>
          <p>{deliveries.priceSummary.discountValue}</p>
        </div>
        <div className='price-breakdown__row'>
          <span>Net price</span>
          <p>{deliveries.priceSummary.netPrice}</p>
        </div>
      </div>
    </td>
    <td>
      <div className='label-container'>
        { generateBoxChargedLabels(deliveries) }
      </div>
    </td>
    <td>
      { format(new Date(deliveries.deliveryDetails.shippingDate), 'EEEE, MMM do yyy') }
    </td>
    <td>
      { format(new Date(deliveries.deliveryDetails.deliveryDate), 'EEEE, MMM do yyy') }
      { renderAdHocLabel(deliveries) }
      <div className='label-container'>
        {
          deliveries.deliveryDetails.reportIds.map((report_id: number): React.Node => (
            <a
              className='label-container__label label-container__label--red'
              href={`/admin/order-issues/reports/${report_id}`}
              key={report_id}
              target={'_blank'}
              rel={'noreferrer'}
            >
              {
                `Issue ${report_id} >`
              }
            </a>
          ))
        }
      </div>
    </td>
  </tr>
)

const renderDeliveries = (deliveries: Array<PresentationalDelivery>, { isFetching }: PresentationalProps): ?React.Node => {
  if (!isFetching) {
    if (deliveries.length < 1) {
      return (
        <p className="no-results">
          { 'No deliveries' }
        </p>
      )
    }
    return (
      <table>
        <thead>
          <tr>
            <th className='card__row__title'>BOX #</th>
            <th className='card__row__title'>
              { 'BOX STATUS' }
            </th>
            <th className='card__row__title'>AMOUNT OF FOOD</th>
            <th className='card__row__title'># OF DISCOUNTS</th>
            <th className='card__row__title'>BOX PRICE</th>
            <th className='card__row__title'>CHARGED</th>
            <th className='card__row__title'>
              { 'SHIPPING DATE' }
            </th>
            <th className='card__row__title'>DELIVERY DATE</th>
          </tr>
        </thead>
        <tbody>
          {
            deliveries.sort((deliveryA: PresentationalDelivery, deliveryB: PresentationalDelivery): number => new Date(deliveryB.deliveryDetails.deliveryDate) - new Date(deliveryA.deliveryDetails.deliveryDate))
              .map((deliveries: PresentationalDelivery, i: number): React.Node => renderDiscountRow(deliveries, i))
          }
        </tbody>
      </table>
    )
  }
}

class DeliveriesHistoryComponent extends React.Component<Props> {
  componentDidMount = (): void => {
    this.props.getDeliveriesHistory(this.props.userId)
  }

  render = (): React.Node => (
    <div className={`deliveries-history card__container card__${renderTransition(this.props)}`}>
      <div className='card__header__container'>
        <h3 className='card__header'>Box history</h3>
      </div>
      <div className='card__body'>
        { renderLoadingIcon(this.props) }
        { renderDeliveries(this.props.deliveries, this.props) }
      </div>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeliveriesHistoryComponent)
