import { gql } from '@apollo/client'

const REPORT_ISSUE = gql`
  mutation CustomerIssueReportEntriesSubmission(
    $userId: ID!
    $reportId: ID!
    $entryFieldInputs: [CustomerIssueReportEntryInputInput!]!
    $reasonId: ID!
  ) {
    response: customerIssueReportEntriesSubmission(
      userId: $userId
      reportId: $reportId
      entryFieldInputs: $entryFieldInputs
      reasonId: $reasonId
    ) {
      reportId
      resolutions {
        key
        details {
          __typename
          ... on ReplacementBoxDetails {
            availableDeliveryDates {
              date
              deliverable
            }
            defaultAddress {
              address1
              address2
              city
              country
              deliveryNotes
              deliveryCarrier
              postcode
              recipientName
              shippingCountry {
                active
                code
                name
                id
              }
            }
            type
          }
          ... on ApplyDiscounts {
            discountCode {
              discountCodeParts {
                value
              }
            }
          }
        }
      }
    }
  }
`

export { REPORT_ISSUE }
