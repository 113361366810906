// @flow

type FavouriteGame =
  | 'unknown_favourite_game'
  | 'just_hanging_out'
  | 'ball_chaser'
  | 'stick_fetcher'
  | 'toy_squeaker'
  | 'tug_of_war_master'

const favouriteGames: Array<FavouriteGame> = [
  'unknown_favourite_game',
  'just_hanging_out',
  'ball_chaser',
  'stick_fetcher',
  'toy_squeaker',
  'tug_of_war_master'
]

export type { FavouriteGame }
export { favouriteGames }
