// @noflow
import classnames from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Validator from '@/utils/validator'

import Icon from '@/components/elements/atoms/Icon/Icon'

import STYLES from './text_field.module.sass'

import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'

type Props = {
  variant?: keyof Omit<typeof STYLES, 'container'>
  namespace?: string
  errorMessage?: string
  label: string
  onChange?: (value: string) => void
  onValidate?: (label: string, valid: boolean, value: string) => void
  initialValue?: string
  isValid?: boolean
  optional?: boolean
  type?: 'text' | 'postcode' | 'password'
  helperText?: string
  shippingCountryCode?: CountryCode
  additionalValidation?: (value: string) => boolean
  successState?: boolean
  disabled?: boolean
}

/**
 * An input element to be used primarily by the Form molecule.
 *
 * By default the successState prop is set to true. This prop renders a small green
 * checkmark within the input field when valid. We set it to false for password types
 * since those have extra functionality on hiding/showing the value and have an
 * eye icon button to toggle visibility in place of the geeen checkmark.
 *
 * Optional fields don't have a successState, since they can be left blank.
 *
 */

const TextField = ({
  variant,
  namespace = 'text_field',
  errorMessage,
  label,
  onChange,
  onValidate,
  initialValue = '',
  isValid = true,
  optional = false,
  type = 'text',
  helperText,
  shippingCountryCode = 'GB',
  additionalValidation = () => true,
  successState = true,
  disabled = false
}: Props): JSX.Element | null => {
  const [value, setValue] = useState(initialValue)
  const [valid, setValid] = useState<boolean>(false)
  const [visited, setVisited] = useState<boolean>(false)
  const { t } = useTranslation(namespace)
  const defaultTranslation = useTranslation('text_field').t

  useEffect(() => {
    if (!optional) {
      setValid(false)
    }
  }, [optional])

  const validate = useCallback(() => {
    let newValid
    if (optional) {
      newValid = true
    } else {
      switch (type) {
        case 'postcode':
          newValid =
            Validator.isValidPostcode(value) &&
            Validator.isValidPostcodeForShippingCountry(
              value,
              shippingCountryCode
            )
          break
        default:
          newValid = value.length > 0
      }
    }
    if (newValid) {
      newValid = additionalValidation(value)
    }

    setVisited(true)
    onValidate && onValidate(label, newValid, value)
    setValid(newValid)
  }, [
    optional,
    onValidate,
    label,
    value,
    type,
    shippingCountryCode,
    additionalValidation
  ])

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      setValue(e.currentTarget.value)
      onChange && onChange(e.currentTarget.value)
    },
    [setValue, onChange]
  )

  useEffect(() => {
    if (!isValid && visited) {
      validate()
    }
  }, [isValid, validate, visited])

  const translatedLabel = optional ? `${t(label)} - ${t('optional')}` : t(label)
  const errored = !valid && visited

  const errorMessageTranslated = errorMessage
    ? t(errorMessage)
    : type === 'postcode'
    ? defaultTranslation('postcode_error_message', { shippingCountryCode })
    : defaultTranslation('error_message', {
        label: translatedLabel.toLowerCase()
      })

  const className = classnames(STYLES.container, variant && [STYLES[variant]], {
    [STYLES.error]: errored
  })

  const labelClassName = classnames({
    [STYLES.label]: !errored,
    [STYLES.labelError]: errored,
    [STYLES.labelDisabled]: disabled
  })

  return (
    <div className={STYLES.wrapper}>
      <div className={className}>
        <input
          type={type}
          onChange={handleChange}
          value={value}
          onBlur={validate}
          className={value && value.length > 0 ? STYLES.filled : ''}
          disabled={disabled}
        />
        <div className={STYLES.labelContainer}>
          <span className={labelClassName}>{translatedLabel}</span>
        </div>
        {((isValid && !errored) || valid) && !optional && successState && (
          <div className={STYLES.successIcon}>
            <Icon asset="checkmark" size={15} accentColour="successGreen300" />
          </div>
        )}
      </div>
      {helperText && !errored && (
        <span className={STYLES.helperText}>{helperText}</span>
      )}
      {errored && (
        <div className={STYLES.helperText}>
          <div className={STYLES.errorIcon}>
            <Icon
              asset="errorCircle"
              size={12}
              accentColour="dangerRed300"
              width={12}
            />
          </div>
          <span className={STYLES.helperTextError}>
            {errorMessageTranslated}
          </span>
        </div>
      )}
    </div>
  )
}

export { Props }
export default TextField
