import { useReactiveVar } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import {
  IdealBankElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js'
import i18next from 'i18next'
import React from 'react'

import { bannerMessageState } from '../../../components/Banner/Banner'
import { totalAmount } from '../../../components/DsAppOrderSummary/DsAppOrderSummary'

import { State } from '../../../PayOnOwnDevicePage'
import {
  ActivePaymentViews,
  LoginUrlProps,
  setSubmissionState
} from '../../../screens/PaymentScreen/PaymentScreen'
import buySubscriptionWithSepaDirectDebit from './buySubscriptionWithSepaDirectDebit'
import chargeBoxOneIdeal from './chargeBoxOneIdeal'

type UseBuySubscriptionWithIdeal = {
  iDealSubscriptionCallback: () => void
  idealAuthReceived: boolean
}

const useBuySubscriptionWithIdeal = ({
  paymentPageState,
  setActivePaymentView,
  navigateToNextStep
}: {
  paymentPageState: State
  setActivePaymentView: (view: ActivePaymentViews) => void
  navigateToNextStep: (input: LoginUrlProps) => void
}): UseBuySubscriptionWithIdeal => {
  const elements = useElements()
  const stripe = useStripe()

  const { pendingSubscription } =
    paymentPageState.data.directSalesPendingSubscription

  const { amount } = useReactiveVar(totalAmount)
  const copyContext =
    'plan_steps.payment.payment_options.payment_request_display_items'

  const [idealAuthReceived, setIdealAuthReceived] = React.useState(false)
  const idealSelected = window.localStorage.getItem('paymentMethod') === 'iDeal'

  const iDealSubscriptionCallback = React.useCallback(async () => {
    const idealBank = elements?.getElement(IdealBankElement)
    if (!stripe)
      throw new Error(
        'Cannot find stripe when buySubscriptionWithSepaDirectDebit is called'
      )
    if (!idealBank) {
      Sentry.captureException('Could not find ideal card element at checkout')
      return
    }

    setSubmissionState({ type: 'loading' })

    // If a mobile iDEAL user, show an alert asking them to return to the site after authorisation
    if (!paymentPageState) {
      throw new Error('Cannnot retrieve checkout data')
    }
    await chargeBoxOneIdeal({
      paymentPageState,
      stripe,
      fullName: `${pendingSubscription?.user?.firstName} ${pendingSubscription?.user?.lastName}`,
      idealBank,
      email: pendingSubscription?.user?.email || '',
      amount: amount,
      setActivePaymentView
    }).then(() => {
      window.localStorage.setItem('paymentMethod', 'iDeal')
    })
  }, [
    elements,
    amount,
    paymentPageState,
    pendingSubscription,
    stripe,
    setActivePaymentView
  ])

  React.useEffect(() => {
    if (
      window.location.search.includes('redirect_status') &&
      stripe &&
      idealSelected &&
      paymentPageState
    ) {
      if (window.location.search.includes('redirect_status=succeeded')) {
        const urlParams = new URLSearchParams(window.location.search)
        const paymentIntentSecret = urlParams.get(
          'payment_intent_client_secret'
        )
        const setupIntentSecret = urlParams.get('setup_intent_client_secret')

        const intentSecret = paymentIntentSecret || setupIntentSecret

        if (intentSecret) {
          setIdealAuthReceived(true)
          setSubmissionState({ type: 'loading' })
          buySubscriptionWithSepaDirectDebit({
            intentSecret: intentSecret,
            paymentPageState,
            navigateToNextStep
          })
        } else {
          Sentry.captureException(
            'Ideal payment failed, intentSecret is undefined'
          )
          setSubmissionState({
            type: 'error',
            error: i18next.t(copyContext)
          })
          window.history.replaceState(
            {},
            document.title,
            location.href.split('?')[0]
          )
        }
      } else {
        const urlParams = new URLSearchParams(window.location.search)
        const status = urlParams.get('redirect_status')
        Sentry.captureException(`Ideal Auth Redirect Status = ${status}`)
        setIdealAuthReceived(false)

        // Remove URL params after failed authentication
        window.history.replaceState(
          {},
          document.title,
          location.href.split('?')[0]
        )
        window.localStorage.setItem('paymentMethod', 'none')
        setSubmissionState({
          type: 'error',
          error: i18next.t(copyContext)
        })
        bannerMessageState({
          message: i18next.t(copyContext)
        })
      }
    }
  }, [idealSelected, navigateToNextStep, paymentPageState, stripe])

  return {
    iDealSubscriptionCallback,
    idealAuthReceived
  }
}

export default useBuySubscriptionWithIdeal
