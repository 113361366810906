// @noflow
import BREAKPOINTS from '@/constants/Breakpoints'

/**
 * By passing an HTML element (either using React Ref's or by the more
 * traditional, document.getElementByID), when this function is called the
 * browser scrolls to the top of the passed element and accounts for the sticky
 * navigation bar on Desktop and Mobile
 */
const scrollToElement = (
  element: HTMLElement,
  offsetFromTop?: number
): void | null => {
  const currentScroll = window.scrollY
  const currentElementTop = element.getBoundingClientRect().top
  const finalElementTop =
    offsetFromTop ?? window.innerWidth <= BREAKPOINTS.md ? 50 : 100

  window.scrollTo({
    behavior: 'smooth',
    top: currentScroll + currentElementTop - finalElementTop
  })
}

export default scrollToElement
