import React from 'react'

import Separator from '@/components/elements/atoms/Separator/Separator'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './TextSeparator.module.sass'

type Props = {
  namespace?: string
  text: string
  variables?: { [key: string]: string | number }
  info?: boolean
}

const TextSeparator = ({
  namespace = '',
  text,
  variables,
  info = false
}: Props): JSX.Element => (
  <div className={STYLES.container}>
    <Separator />
    <div className={`${STYLES.textContainer} ${info ? STYLES.info : ''}`}>
      <Text
        namespace={namespace}
        text={text}
        margin={false}
        variant={info ? 'textRegular14' : 'display16'}
        colour="brandBlue500"
        bold={!info}
        variables={variables}
      />
    </div>
    <Separator />
  </div>
)

export { Props }
export default TextSeparator
