// @noflow
import React from 'react'

import { Button } from '@/components/elements/atoms/Button'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from '../SurveyCard.module.sass'

import type { BaseCardProps } from '../SurveyCard'

type ExternalLinkProps = BaseCardProps

const ExternalLinkCard = ({
  currentQuestion,
  setCurrentQuestionIndex
}: ExternalLinkProps): JSX.Element | null => {
  const handleClick = React.useCallback(() => {
    setCurrentQuestionIndex(currentQuestion.order + 1)
    if (currentQuestion.ctaDestination) {
      window.open(currentQuestion.ctaDestination, '_blank')
    }
  }, [currentQuestion, setCurrentQuestionIndex])

  if (!currentQuestion.ctaText || !currentQuestion.ctaDestination) return null
  return (
    <div className={`${STYLES.externalLinkQuestion} ${STYLES.surveyCard}`}>
      <Text
        text={currentQuestion.text}
        element={'h2'}
        variant="display20"
        colour={'brandBlue500'}
        margin
        align={'left'}
        translate={false}
      />
      <div className={STYLES.contentContainer}>
        <Button
          typography={{
            text: currentQuestion.ctaText,
            translate: false
          }}
          variant="secondary"
          onClick={handleClick}
          disableAnalytics
        />
      </div>
    </div>
  )
}

export default ExternalLinkCard
