// @noflow
import React from 'react'
import { useTranslation } from 'react-i18next'

import IdealLogo from 'assets/images/logos/ideal-logo.svg'

import STYLES from './PaymentComponents.module.sass'

type Props = {
  onClick: () => void
  disableIdealButton: boolean
  namespace: string
}

const Ideal = ({
  onClick,
  disableIdealButton,
  namespace
}: Props): JSX.Element => {
  const { t } = useTranslation(namespace)
  const copyContext = 'plan_steps.payment.payment_options'

  return (
    <button
      className={`${STYLES.button} ${STYLES.idealButton}`}
      type="button"
      disabled={disableIdealButton}
      onClick={onClick}
    >
      <img
        alt={t(`${copyContext}.ideal_alt`)}
        height="20px"
        className="payment-section__card-icon"
        src={IdealLogo}
      />
      {t(`${copyContext}.ideal`)}
    </button>
  )
}

export default Ideal
