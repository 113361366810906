// Stripe
import { IdealBankElement } from '@stripe/react-stripe-js'
import {
  StripeCardElementChangeEvent,
  StripeIdealBankElementChangeEvent
} from '@stripe/stripe-js'
import React from 'react'

import BRAND_COLOURS from '@/constants/BrandColours'
import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import { Button } from '@/components/elements/atoms/Button'
import CheckBox from '@/components/elements/atoms/CheckBox/CheckBox'
import Modal from '@/components/elements/atoms/Modal/Modal'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from '../../PaymentMethods.module.sass'

import { InputPaymentMethod } from '@/types'

import CreditCardView from './CreditCardView'

type Props = {
  isOpen: boolean
  disableButton: boolean
  inlineErrorMessage: string | null
  paymentMethodType?: InputPaymentMethod
  onIsActiveChange?: (id: number, newValue: boolean) => void
  toggle: () => void
  onSubmit?: () => void
  onCardElementChange?: (event: StripeCardElementChangeEvent) => void
  onIdealSelectChange?: (event: StripeIdealBankElementChangeEvent) => void
}

const PaymentMethodModal = ({
  isOpen,
  disableButton,
  inlineErrorMessage,
  paymentMethodType,
  onIsActiveChange,
  toggle,
  onSubmit,
  onCardElementChange,
  onIdealSelectChange
}: Props): JSX.Element => {
  const { windowWidth } = useWindowSize()
  return (
    <Modal
      isModalOpen={isOpen}
      setOpenModal={toggle}
      width={800}
      textAlign="center"
      overflowVisible
      bottomSticky={windowWidth < BREAKPOINTS.md}
    >
      <div>
        {paymentMethodType === InputPaymentMethod.credit_card &&
          onCardElementChange && (
            <>
              <Text
                namespace="dashboard"
                text="my_details.payment_methods.new_payment_method.heading"
                variant="display20"
                colour="brandBlue500"
                margin={false}
              />
              <div className={STYLES.wrapper}>
                <CreditCardView
                  errorMessage={inlineErrorMessage}
                  onCardElementChange={onCardElementChange}
                />
              </div>
              {onIsActiveChange && (
                <div className={STYLES.checkboxWrapper}>
                  <CheckBox
                    defaultValue
                    id={0}
                    tabIndex={0}
                    onChange={onIsActiveChange}
                  />
                  <Text
                    namespace="dashboard"
                    text="my_details.payment_methods.new_payment_method.default_payment"
                    colour={'brandBlue500'}
                    margin={false}
                  />
                </div>
              )}
            </>
          )}
        {paymentMethodType === InputPaymentMethod.sepa_direct_debit && (
          <div className="ideal-section">
            <IdealBankElement
              onChange={onIdealSelectChange}
              options={{
                style: {
                  base: {
                    padding: '8px 6px',
                    color: BRAND_COLOURS.brandBlue500,
                    fontFamily:
                      'gt-pressura-regular, helvetica, arial, sans-serif',
                    fontSize: '18px',
                    '::placeholder': {
                      color: BRAND_COLOURS.brandBlue500
                    }
                  }
                }
              }}
            />
          </div>
        )}
      </div>
      <div className={STYLES.buttonContainer}>
        <Button
          typography={{
            text: 'my_details.payment_methods.new_payment_method.confirm',
            namespace: 'dashboard'
          }}
          disabled={disableButton}
          onClick={onSubmit}
          disableAnalytics
        />
      </div>
    </Modal>
  )
}

export { Props }
export default PaymentMethodModal
