import { Option } from '@/components/elements/atoms/HybridSelect/HybridSelect'
import { SizeOption } from '@/components/elements/molecules/ExtrasModal/ExtrasContent'

const isEnoughSpaceRemaining = (
  spacesRemaining: number,
  currentlySelectedNumberOfItems: number,
  currentItemVolume: number,
  potentialItemVolume: number
): boolean => {
  const currentSpaceTaken = currentlySelectedNumberOfItems * currentItemVolume
  const potentialSpaceTaken =
    currentlySelectedNumberOfItems * potentialItemVolume

  return potentialSpaceTaken - currentSpaceTaken > spacesRemaining
}

const getProductSizeOptions = (sizes: SizeOption[]): Option[] => {
  const options: Option[] = []

  for (let i = 0; i < sizes.length; i++) {
    const variant = sizes[i]

    options.push({
      text: variant.label as string,
      value: variant.value,
      translate: false
    })
  }
  return options
}

export { isEnoughSpaceRemaining, getProductSizeOptions }
