// @noflow
import { useReactiveVar } from '@apollo/client'
import i18next from 'i18next'
import React, { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { pronounContext } from '@/utils/StringHelper'

import RadioGroup, {
  RadioOption
} from '@/components/elements/molecules/RadioGroup/RadioGroup'
import TextSeparator from '@/components/elements/molecules/TextSeparator/TextSeparator'
import StickyNavigation from '@/components/elements/organisms/StickyNavigation/StickyNavigation'
import {
  routeToNextStep,
  routeToPrevStep
} from '@/components/pages/SignupWizardPage/helpers/wizardRoutes'
import type { Route } from '@/components/pages/SignupWizardPage/types/routes'

import STYLES from './Eater.module.sass'

import { Gender } from '@/types'

import { wizardPageState } from '../../SignupWizardPage'
import useImplicitFormSubmission from '../../helpers/useImplicitFormSubmission'
import { Dog, WizardEaterType } from '../../types/types'

type Props = {
  variant?: keyof typeof STYLES
  namespace: string
  isGuest: boolean
}

const copyContext = 'eater_step'

type EaterOptions = Omit<RadioOption, 'value'> & { value: WizardEaterType }

const EaterOptions = ({
  dogIndex,
  dog,
  namespace
}: {
  dogIndex: number
  dog: Dog
  namespace: Props['namespace']
}) => {
  const wizardState = useReactiveVar(wizardPageState)
  const { dogs } = wizardState

  /* eslint-disable i18next/no-literal-string */
  const EaterOptions = useMemo<EaterOptions[]>(
    () => [
      {
        id: `${dog.name}-${dogIndex.toString()}-foodie`,
        dataTestId: 'eater-eatsAnything',
        text: {
          text: `${copyContext}.foodie`,
          namespace
        },
        subText: {
          text: `${copyContext}.foodie_sub_text`,
          namespace,
          variables: {
            context: pronounContext([dog.gender as Gender], i18next.language)
          }
        },
        value: WizardEaterType.EatsAnything,
        defaultChecked: dog.eaterType === WizardEaterType.EatsAnything
      },
      {
        id: `${dog.name}-${dogIndex.toString()}-not_fussy`,
        dataTestId: 'eater-goodEater',
        text: {
          text: `${copyContext}.not_fussy`,
          namespace,
          variables: {
            context: pronounContext([dog.gender as Gender], i18next.language)
          }
        },
        subText: {
          text: `${copyContext}.not_fussy_sub_text`,
          namespace,
          variables: {
            context: pronounContext([dog.gender as Gender], i18next.language)
          }
        },
        value: WizardEaterType.GoodEater,
        defaultChecked: dog.eaterType === WizardEaterType.GoodEater
      },
      {
        id: `${dog.name}-${dogIndex.toString()}-fussy`,
        dataTestId: 'eater-canBeFussy',
        text: {
          text: `${copyContext}.fussy`,
          namespace
        },
        subText: {
          text: `${copyContext}.fussy_sub_text`,
          namespace,
          variables: {
            context: pronounContext([dog.gender as Gender], i18next.language)
          }
        },
        value: WizardEaterType.CanBeFussy,
        defaultChecked: dog.eaterType === WizardEaterType.CanBeFussy
      },
      {
        id: `${dog.name}-${dogIndex.toString()}-very_fussy`,
        dataTestId: 'eater-veryFussy',
        text: {
          text: `${copyContext}.very_fussy`,
          namespace
        },
        subText: {
          text: `${copyContext}.very_fussy_sub_text`,
          namespace,
          variables: {
            context: pronounContext([dog.gender as Gender], i18next.language)
          }
        },
        value: WizardEaterType.VeryFussy,
        defaultChecked: dog.eaterType === WizardEaterType.VeryFussy
      }
    ],
    [dog.gender, dog.name, dogIndex, namespace, dog.eaterType]
  )
  /* eslint-enable i18next/no-literal-string */

  const handleChange = useCallback(
    (index: number) => {
      const RootOption = EaterOptions[index]

      dogs[dogIndex].eaterType = RootOption.value

      wizardPageState({
        ...wizardState,
        dogs
      })
    },
    [EaterOptions, dogs, wizardState, dogIndex]
  )
  return (
    <RadioGroup
      onChange={handleChange}
      radioOptions={EaterOptions}
      variant="fullWidth"
      identifier="sign_up.eater"
    />
  )
}

const Eater = ({ variant, namespace, isGuest }: Props): JSX.Element => {
  const route = useLocation().pathname as Route
  const wizardState = useReactiveVar(wizardPageState)
  const { dogs } = wizardState

  const formIsValid = dogs.every(({ eaterType }) => eaterType !== null)

  const fussyDogs = dogs.filter(
    (dog: Dog) =>
      dog.eaterType &&
      [WizardEaterType.VeryFussy, WizardEaterType.CanBeFussy].includes(
        dog.eaterType
      )
  )
  const fussyDogsPresent = fussyDogs.length > 0

  const navigate = useNavigate()

  const onEnterPress = useCallback(() => {
    navigate(
      routeToNextStep({
        route,
        fussyDogsPresent,
        isGuest
      })
    )
  }, [fussyDogsPresent, navigate, isGuest, route])

  useImplicitFormSubmission({ formIsValid, onEnterPress })

  return (
    <>
      <div className={variant ? STYLES[variant] : ''}>
        <div id={'whatKindOfEaterStep'} className={STYLES.question}>
          {dogs.map((dog: Dog, index: number) => (
            <div key={index.toString()}>
              <div className={STYLES.subQuestion}>
                <TextSeparator
                  text={`${copyContext}.text_separator`}
                  namespace={namespace}
                  variables={{ dogName: dog.name }}
                />
                <EaterOptions
                  dogIndex={index}
                  dog={dog}
                  namespace={namespace}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <StickyNavigation
        variant="twoButtons"
        buttonOne={{
          url: routeToPrevStep({ route }),
          text: 'wizard_flow:sticky_navigation.back',
          variant: 'secondary',
          iconColour: 'brandRed400'
        }}
        buttonTwo={{
          dataTestId: 'next-button',
          url: routeToNextStep({
            route,
            fussyDogsPresent,
            isGuest
          }),
          text: 'wizard_flow:sticky_navigation.continue',
          variant: 'primary',
          iconColour: 'brandWhite'
        }}
        disabled={!formIsValid}
      />
    </>
  )
}

export { Props }
export default Eater
