// @noflow
import { gql } from '@apollo/client'

const BASKET_PRODUCTS_UPDATE_MUTATION = gql`
  mutation BasketProductsUpdate(
    $userId: ID!
    $basketProductVariants: [ProductVariantInput!]!
  ) {
    basketProductsUpdate(
      userId: $userId
      basketProductVariants: $basketProductVariants
    ) {
      user {
        id
        basket {
          basketProducts {
            id
          }
        }
      }
    }
  }
`

export { BASKET_PRODUCTS_UPDATE_MUTATION }
