// @noflow
import React, { useCallback, useEffect, useState } from 'react'

import STYLES from './FileDecoder.module.sass'

type ImagesFile = {
  base64: Array<string | null>
  fileList: React.ChangeEvent<HTMLInputElement>
}

type Props = {
  disabled?: boolean
  multipleFiles?: boolean
  handleFiles?: (event: ImagesFile | Array<null>) => void
  setAlert?: (value: boolean) => void
  initialFiles?: Array<string | null>
  children: JSX.Element
  maxLength?: number
}

const FileDecoder = ({
  disabled,
  multipleFiles,
  handleFiles,
  setAlert,
  initialFiles,
  maxLength = 1,
  children
}: Props): JSX.Element => {
  const [uploadFiles, setUploadFiles] = useState<Array<string | null>>([])

  useEffect(() => {
    if (initialFiles && initialFiles.length > 0) {
      setUploadFiles(initialFiles)
    }
  }, [initialFiles])

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      if (e.target.files) {
        if (!multipleFiles) {
          const file = e.target.files[0]
          const imagesFile = { base64: [], fileList: e }
          const reader = new FileReader()
          reader.onloadend = () =>
            (imagesFile.base64 as Array<ArrayBuffer | null>).push(
              reader.result as ArrayBuffer
            )
          reader.readAsDataURL(file)
          setUploadFiles(imagesFile.base64)

          if (handleFiles) {
            handleFiles(imagesFile)
          }
        }
        const files = [...e.target.files]
        let formattedFiles
        const imagesFile = { base64: [], fileList: e }

        if (files.length > maxLength) {
          setUploadFiles([])
          handleFiles && handleFiles([])
          setAlert && setAlert(true)
          formattedFiles = files.slice(0, maxLength)
        }
        if (files.length <= maxLength) {
          formattedFiles = files
          setAlert && setAlert(false)
        }
        formattedFiles &&
          formattedFiles.forEach((file) => {
            const reader = new FileReader()
            reader.onloadend = () =>
              (imagesFile.base64 as Array<ArrayBuffer | null>).push(
                reader.result as ArrayBuffer
              )
            reader.readAsDataURL(file)
            setUploadFiles(imagesFile.base64)
            handleFiles && handleFiles(imagesFile)
          })
      }
    },
    [handleFiles, multipleFiles, maxLength, setAlert]
  )

  return (
    <>
      <div className={STYLES.container}>
        <input
          type="file"
          className={STYLES.input}
          onChange={handleChange}
          multiple={multipleFiles}
          disabled={disabled}
          accept="image/jpeg,image/png"
        />

        <div>{children}</div>
      </div>
      <div className={STYLES.files}>
        {uploadFiles &&
          uploadFiles.map((file: string | null): JSX.Element | null => {
            if (!file) return null
            return (
              <div key={file} className={STYLES.file}>
                <img src={file} alt="" />
              </div>
            )
          })}
      </div>
    </>
  )
}

export type { Props, ImagesFile }
export default FileDecoder
