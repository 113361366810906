// @noflow
import { useQuery, useReactiveVar } from '@apollo/client'
import i18next from 'i18next'
import isNil from 'lodash/isNil'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import React, { useCallback } from 'react'
// Routing
import { useLocation } from 'react-router-dom'

import { pronounContext } from '@/utils/StringHelper'
import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { Options, formatCurrencyWithDecimal } from '@/utils/currency'
import { percentageValue } from '@/utils/percentage'

import Card from '@/components/elements/atoms/Card/Card'
import Icon from '@/components/elements/atoms/Icon/Icon'
import Label from '@/components/elements/atoms/Label/Label'
import Text from '@/components/elements/atoms/Text/Text'
import BoxBreakdown from '@/components/elements/molecules/BoxBreakdown/BoxBreakdown'
import LabelList from '@/components/elements/molecules/LabelList/LabelList'
import TextSeparator from '@/components/elements/molecules/TextSeparator/TextSeparator'
import ConfirmationModal, {
  modalData
} from '@/components/elements/organisms/ConfirmationModal/ConfirmationModal'
import HeroText from '@/components/elements/organisms/HeroText/HeroText'
import RecipeSelectionToggle from '@/components/elements/organisms/RecipeSelectionToggle/RecipeSelectionToggle'
import StickyNavigation from '@/components/elements/organisms/StickyNavigation/StickyNavigation'
// Data
import {
  deliveryCadenceState,
  planRecommendationState,
  plansFlowTypeState,
  plansPageState,
  plansPageWizardState,
  recommendedExtraProductVariantsState
} from '@/components/pages/PlansPage/PlansPage'
import type { RecommendationQuery_currentUser_Guest_discountCode_discountCodeParts as Discount } from '@/components/pages/PlansPage/__generated__/RecommendationQuery'
import {
  ReviewQuery_pendingSubscription_productVariants as ExtraProducts,
  ReviewQuery_pendingSubscription_pendingSubscriptionFlavours as PendingSubscriptionFlavours,
  ReviewQuery
} from '@/components/pages/PlansPage/__generated__/ReviewQuery'
import FAQSection from '@/components/pages/PlansPage/components/FAQSection/FAQSection'
import RedeemDiscount from '@/components/pages/PlansPage/components/RedeemDiscount/RedeemDiscount'
import { currentUserToLocaleData } from '@/components/pages/PlansPage/helpers/currentUserToLocaleData'
import {
  handleFetchError,
  handleWizardDataError,
  verifyWizardData
} from '@/components/pages/PlansPage/helpers/handleErrors'
import PlansRoutes from '@/components/pages/PlansPage/types/routes'
import type { InitialState as WizardState } from '@/components/pages/SignupWizardPage/SignupWizardPage'
import { User } from '@/components/pages/SignupWizardPage/types/types'
import type { Dog as WizardDog } from '@/components/pages/SignupWizardPage/types/types'
// eslint-disable-next-line no-restricted-imports
import { PendingSubscription } from '@/components/types'

import STYLES from './Review.module.sass'

import {
  trackBackButtonClicked,
  trackNextButtonClicked,
  trackReviewTabToggled
} from '../../helpers/analytics'
import { GET_REVIEW_QUERY } from '../../queries'
import type { Route as PlanRoute } from '../../types/routes'

type Props = {
  variant?: keyof typeof STYLES
  maxFlavours: number
  namespace: string
}

type ReviewPageViewProps = Props & {
  wizardState: WizardState
}

type BoxInView = 'introBox' | 'afterBox'

type Recipe = {
  themeColour: string
  name: string
  numberOfPouches: number
  pouchSize: number
  thumbnail?: string
}

type ExtraCardProps = {
  extraProduct: ExtraProducts
  namespace: string
  copyContext: string
  hasDiscount: boolean
  currencyOptions: Options
}

const getRecipesFromPayload = (
  recipes: ReviewQuery['pendingSubscription']['pendingSubscriptionFlavours'],
  pouchSize: number
): Array<Recipe> =>
  recipes.map((recipe) => ({
    name: recipe.flavour.name,
    themeColour: recipe.flavour.themeColour,
    numberOfPouches: recipe.trialBoxServings,
    thumbnail: recipe.flavour.thumbnail.src,
    pouchSize
  }))

const ExtraCard = ({
  extraProduct: { productVariant, discountedPrice },
  namespace,
  currencyOptions,
  hasDiscount,
  copyContext
}: ExtraCardProps): JSX.Element => {
  const recurringPrice = productVariant.recurringDeliveryType
    ? productVariant.recurringDeliveryType.netPrice
    : productVariant.grossPrice
  const extraName = productVariant.productCollection.name
  return (
    <div
      className={STYLES.orderSummaryItem}
      key={`dog-price-summary-${productVariant.productCollection.name}-key`}
    >
      <Text
        text={extraName}
        translate={false}
        namespace={namespace}
        variant="textRegular16"
        colour="brandBlue500"
        margin={false}
      />
      <Text
        text={
          !hasDiscount
            ? formatCurrencyWithDecimal(recurringPrice, currencyOptions)
            : `${copyContext}.order_summary.extras.value_discounted`
        }
        namespace={namespace}
        variant="textRegular16"
        colour="brandBlue500"
        margin={false}
        variables={{
          price: formatCurrencyWithDecimal(recurringPrice, currencyOptions),
          discountedPrice:
            hasDiscount && discountedPrice
              ? formatCurrencyWithDecimal(discountedPrice, currencyOptions)
              : undefined
        }}
        translate={hasDiscount}
      />
    </div>
  )
}

const ReviewPageView = ({
  variant,
  maxFlavours,
  namespace,
  wizardState
}: ReviewPageViewProps): JSX.Element | null => {
  const location = useLocation().pathname as PlanRoute

  const plansState: PendingSubscription = useReactiveVar(plansPageState)
  const recommendationState = useReactiveVar(planRecommendationState)
  const cadenceState = useReactiveVar(deliveryCadenceState)
  const flowTypeState = useReactiveVar(plansFlowTypeState)
  const recommendedExtraProductVariants = useReactiveVar(
    recommendedExtraProductVariantsState
  )

  const { plan } = plansState

  const copyContext = 'plan_steps.review'

  const [boxInView, setBoxInView] = React.useState<BoxInView>('introBox')

  const introBoxClick = React.useCallback(() => {
    setBoxInView('introBox')
    trackReviewTabToggled(
      location,
      plansState,
      wizardState,
      recommendationState,
      cadenceState,
      'intro',
      flowTypeState
    )
  }, [
    cadenceState,
    location,
    plansState,
    recommendationState,
    wizardState,
    flowTypeState
  ])

  const afterBoxClick = React.useCallback(() => {
    setBoxInView('afterBox')
    trackReviewTabToggled(
      location,
      plansState,
      wizardState,
      recommendationState,
      cadenceState,
      'after',
      flowTypeState
    )
  }, [
    cadenceState,
    location,
    plansState,
    recommendationState,
    wizardState,
    flowTypeState
  ])

  const backButtonClick = React.useCallback(
    () =>
      trackBackButtonClicked(
        location,
        plansState,
        wizardState,
        recommendationState,
        cadenceState,
        flowTypeState
      ),
    [
      cadenceState,
      location,
      plansState,
      recommendationState,
      wizardState,
      flowTypeState
    ]
  )

  const redeemDiscountCallback = React.useCallback(() => {
    modalData({
      isOpen: true,
      // eslint-disable-next-line i18next/no-literal-string
      text: `${copyContext}.discount_entry.success_modal_message`,
      namespace,
      delay: 3000
    })
  }, [namespace])

  const { loading, data, error } = useQuery<ReviewQuery>(GET_REVIEW_QUERY(), {
    fetchPolicy: 'network-only'
  })

  // this code integrate the news plans flow with the old checkout. Eventually this will be removed in favour
  // of using the pending subscription all the way through the process. But for now
  // this code takes data from the pending subscription and drops it in to the required format
  // for use on the checkout page and beyond.
  const proceedToCheckout = useCallback(() => {
    trackNextButtonClicked(
      location,
      plansState,
      wizardState,
      recommendationState,
      cadenceState,
      flowTypeState
    )
    if (data) {
      const mealBreakdown =
        data.pendingSubscription.pendingSubscriptionFlavours.reduce(
          (
            breakdown: { [key: string]: number },
            sf: PendingSubscriptionFlavours
          ) => {
            breakdown[sf.flavour.slug] = sf.sevenDayServings
            return breakdown
          },
          {}
        )
      const selectedPlanId = data.pendingSubscription.plan.id
      const selectedExtraProducts = data.pendingSubscription.productVariants
      window.localStorage.setItem(
        'selected_plan_details',
        JSON.stringify({
          mealBreakdown,
          selectedPlanId,
          selectedExtraProducts
        })
      )

      // eslint-disable-next-line i18next/no-literal-string
      window.location.href = `/subscription/checkout`
    }
  }, [
    cadenceState,
    data,
    flowTypeState,
    location,
    plansState,
    recommendationState,
    wizardState
  ])

  if (
    loading ||
    !data ||
    !data.pendingSubscription ||
    !data.pendingSubscription.pendingSubscriptionFlavours ||
    !data.pendingSubscription.standardOrderPricing ||
    !data.pendingSubscription.firstOrderPricing ||
    !data.pendingSubscription.secondOrderPricing ||
    !data.currentUser ||
    data.currentUser.__typename === 'PreWizardGuest' ||
    !data.currentUser.planRecommendation ||
    !data.currentUser.planRecommendation.individual ||
    !(data.currentUser.planRecommendation.individual.length > 0)
  )
    return null

  if (error) handleFetchError(error)

  const {
    pendingSubscriptionFlavours,
    productVariants: extraProducts,
    plan: subscriptionPlan,
    standardOrderPricing,
    firstOrderPricing,
    secondOrderPricing
  } = data.pendingSubscription
  const { individual } = data.currentUser.planRecommendation
  const { pricesPerDayPerDogs, trialLengthInDays, pouchesPerDay } =
    subscriptionPlan
  const { currentUser } = data
  const { countryCode, preferredLanguage } =
    currentUserToLocaleData(currentUser)

  const dogGenders = individual.map(({ dog }) => dog.gender)
  const dogPronoun = pronounContext(dogGenders, i18next.language)
  const currencyOptions: Options = countryCodeToLocaleCurrency(
    countryCode,
    preferredLanguage
  )

  const discountCode =
    currentUser.__typename === 'Guest' ? currentUser.discountCode : null
  let discounts: undefined | Discount[]
  if (!isNull(discountCode)) discounts = discountCode.discountCodeParts

  if (isNil(plan)) return null

  const cadenceInWeeks =
    boxInView === 'introBox'
      ? plan.trialLengthInDays / 7
      : plan.durationInDays / 7
  const singleWeek = cadenceInWeeks === 1
  const dailyServing = plan.pouchSize * pouchesPerDay
  const pouchesPerBox =
    boxInView === 'introBox'
      ? trialLengthInDays * pouchesPerDay
      : plan.numberOfPouches

  const weekPluralityString = singleWeek ? '' : '_plural'
  const deliveryString =
    standardOrderPricing.netDeliverySurchargePrice === 0 ? '_free_delivery' : ''
  const recipeSurchargeString =
    standardOrderPricing.netRecipeSurchargePrice === 0 ? '' : '_has_surcharges'

  const hasDiscount = !isNull(discountCode)

  return (
    <div className={`${STYLES.container} ${variant ? STYLES[variant] : ''}`}>
      <HeroText
        variant="plans"
        namespace={namespace}
        title={`plan_steps.stage`}
        subtitle={`plan_steps.review.header.title`}
        subtitleVariables={{ context: dogPronoun }}
        variables={{
          stepCount: recommendedExtraProductVariants.length > 0 ? 4 : 3,
          totalSteps: recommendedExtraProductVariants.length > 0 ? 4 : 3
        }}
      />
      <div className={`${STYLES.wrapper} ${STYLES.planCardWrapper}`}>
        {/* Box Toggle */}
        <div className={STYLES.boxToggle}>
          <button
            type="button"
            onClick={introBoxClick}
            className={boxInView === 'introBox' ? STYLES.selected : ''}
          >
            <Text
              text={`${copyContext}.box_toggle.intro_box`}
              namespace={namespace}
              variant="display20"
              shouldScale
              margin={false}
              colour={
                boxInView === 'introBox' ? 'brandBlue500' : 'brandBlue400'
              }
            />
          </button>
          <button
            type="button"
            onClick={afterBoxClick}
            className={boxInView === 'afterBox' ? STYLES.selected : ''}
          >
            <Text
              text={`${copyContext}.box_toggle.after_box`}
              namespace={namespace}
              variant="display20"
              shouldScale
              margin={false}
              colour={
                boxInView === 'afterBox' ? 'brandBlue500' : 'brandBlue400'
              }
            />
          </button>
        </div>

        <div className={STYLES.cardOuter}>
          <Card squared>
            <div className={STYLES.orderDetails}>
              <Text
                text={
                  boxInView === 'introBox'
                    ? singleWeek
                      ? `${copyContext}.box_card.title.intro_box`
                      : `${copyContext}.box_card.title.intro_box_plural`
                    : singleWeek
                    ? `${copyContext}.box_card.title.after_box`
                    : `${copyContext}.box_card.title.after_box_plural`
                }
                variables={{
                  cadenceInWeeks: cadenceInWeeks
                }}
                namespace={namespace}
                variant="display16"
                colour="brandBlue500"
                shouldScale
              />

              <Text
                text={
                  boxInView === 'introBox'
                    ? singleWeek
                      ? `${copyContext}.box_card.subtitle.intro_box`
                      : `${copyContext}.box_card.subtitle.intro_box_plural`
                    : singleWeek
                    ? `${copyContext}.box_card.subtitle.after_box`
                    : `${copyContext}.box_card.subtitle.after_box_plural`
                }
                variables={{
                  cadenceInWeeks: cadenceInWeeks,
                  context: dogPronoun
                }}
                namespace={namespace}
                variant="textRegular16"
                colour="brandBlue500"
                shouldScale
              />

              <BoxBreakdown
                pouchSize={plan.pouchSize}
                pouches={pouchesPerBox}
                cadence={cadenceInWeeks}
              />

              {boxInView === 'afterBox' && (
                <Text
                  text={`${copyContext}.box_card.box_after_information`}
                  variables={{ context: dogPronoun }}
                  namespace={namespace}
                  variant="textRegular16"
                  colour="brandBlue500"
                  shouldScale
                />
              )}

              {boxInView === 'introBox' && (
                <RecipeSelectionToggle
                  recipes={getRecipesFromPayload(
                    pendingSubscriptionFlavours,
                    dailyServing
                  )}
                  dogPronoun={dogPronoun}
                  namespace={namespace}
                />
              )}
            </div>

            <TextSeparator
              text={`${copyContext}.box_card.divider`}
              variables={{ context: dogPronoun }}
              namespace={namespace}
              info
            />

            {boxInView === 'afterBox' && (
              <div className={STYLES.afterDetails}>
                <Text
                  text={`${copyContext}.box_card.box_after_delivery_information${recipeSurchargeString}${deliveryString}${weekPluralityString}`}
                  variables={{
                    cadenceInWeeks: cadenceInWeeks,
                    dailyPrice: formatCurrencyWithDecimal(
                      standardOrderPricing.grossCoreFoodPricePerDay,
                      currencyOptions
                    ),
                    deliveryPrice: formatCurrencyWithDecimal(
                      standardOrderPricing.netDeliverySurchargePrice,
                      currencyOptions
                    )
                  }}
                  namespace={namespace}
                  variant="textRegular16"
                  colour="brandBlue500"
                  shouldScale
                />
                {secondOrderPricing.totalDiscountedAmount > 0 && (
                  // eslint-disable-next-line jsx-a11y/label-has-for
                  <Label
                    variant="secondBoxDiscount"
                    text={{
                      namespace,
                      text: `${copyContext}.order_summary.second_discount.label`,
                      variables: {
                        value: formatCurrencyWithDecimal(
                          secondOrderPricing.totalDiscountedAmount,
                          currencyOptions
                        )
                      }
                    }}
                  />
                )}
              </div>
            )}

            {boxInView === 'introBox' && (
              <div className={STYLES.discountCodeWrapper}>
                <div className={STYLES.discountCodeTitleWrapper}>
                  <Text
                    text={`${copyContext}.discount_entry.title`}
                    namespace={namespace}
                    variant="display16"
                    colour="brandBlue500"
                    margin={false}
                    shouldScale
                  />
                  <Icon
                    asset="curvedDashedArrow"
                    size={45}
                    width={45}
                    accentColour="successGreen200"
                  />
                </div>
                <RedeemDiscount onSuccess={redeemDiscountCallback} />
              </div>
            )}

            {/* Order Summary */}
            {boxInView === 'introBox' &&
              !isUndefined(firstOrderPricing.netTotalPricePerDay) && (
                <div className={STYLES.orderSummary}>
                  <div className={STYLES.orderSummaryItem}>
                    <Text
                      text={`${copyContext}.order_summary.title`}
                      namespace={namespace}
                      variant="display16"
                      colour="brandBlue500"
                      shouldScale
                    />
                    {/* Handle displaying of discount */}
                    {!isUndefined(discounts) && (
                      <LabelList alignment="right">
                        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                        <Label
                          variant="discount"
                          text={{
                            text:
                              discounts[0].discountBasis === 'percentage'
                                ? `${copyContext}.order_summary.discount_label.percentage`
                                : `${copyContext}.order_summary.discount_label.value`,
                            variables: {
                              value:
                                discounts[0].discountBasis === 'percentage'
                                  ? discounts[0].value
                                  : formatCurrencyWithDecimal(
                                      discounts[0].value,
                                      currencyOptions
                                    )
                            },
                            namespace
                          }}
                        />
                      </LabelList>
                    )}
                  </div>
                  <div className={STYLES.orderSummaryItems}>
                    {pricesPerDayPerDogs.map((dog) => (
                      <div
                        className={STYLES.orderSummaryItem}
                        key={`dog-price-summary-${dog.name}-key`}
                      >
                        <Text
                          text={
                            individual.length > 1
                              ? dog.name
                              : `${copyContext}.order_summary.intro_price.label`
                          }
                          namespace={namespace}
                          variant="textRegular16"
                          colour="brandBlue500"
                          margin={false}
                          shouldScale
                          translate={individual.length === 1}
                        />
                        <Text
                          text={
                            firstOrderPricing.totalDiscountedAmount > 0
                              ? `${copyContext}.order_summary.intro_price.value_discounted`
                              : `${copyContext}.order_summary.intro_price.value`
                          }
                          variables={{
                            pricePerDay: formatCurrencyWithDecimal(
                              dog.pricePerDayWithoutDiscount,
                              currencyOptions
                            ),
                            discountedPricePerDay: !isUndefined(
                              dog.pricePerDayWithDiscount
                            )
                              ? formatCurrencyWithDecimal(
                                  dog.pricePerDayWithDiscount,
                                  currencyOptions
                                )
                              : undefined
                          }}
                          namespace={namespace}
                          variant="textRegular16"
                          colour="brandBlue500"
                          margin={false}
                          shouldScale
                        />
                      </div>
                    ))}
                    {standardOrderPricing.grossRecipeSurchargePrice > 0 && (
                      <div className={STYLES.orderSummaryItem}>
                        <Text
                          text={`${copyContext}.order_summary.surcharge_amount.label`}
                          namespace={namespace}
                          variant="textRegular16"
                          colour="brandBlue500"
                          margin={false}
                          shouldScale
                        />
                        <Text
                          text={
                            firstOrderPricing.netRecipeSurchargePrice > 0
                              ? `${copyContext}.order_summary.surcharge_amount.price`
                              : `${copyContext}.order_summary.surcharge_amount.free`
                          }
                          variables={{
                            standardPrice: formatCurrencyWithDecimal(
                              standardOrderPricing.grossRecipeSurchargePrice,
                              currencyOptions
                            )
                          }}
                          namespace={namespace}
                          variant="textRegular16"
                          colour="brandBlue500"
                          margin={false}
                          shouldScale
                        />
                      </div>
                    )}
                    {extraProducts.map((extraProduct) => {
                      return (
                        <ExtraCard
                          key={extraProduct.productVariant.id}
                          extraProduct={extraProduct}
                          namespace={namespace}
                          copyContext={copyContext}
                          hasDiscount={hasDiscount}
                          currencyOptions={currencyOptions}
                        />
                      )
                    })}
                    <div className={STYLES.orderSummaryItem}>
                      <Text
                        text={`${copyContext}.order_summary.delivery.label_offered`}
                        namespace={namespace}
                        variant="textRegular16"
                        colour="brandBlue500"
                        margin={false}
                        shouldScale
                      />
                      <Text
                        text={
                          firstOrderPricing.netDeliverySurchargePrice === 0
                            ? `${copyContext}.order_summary.delivery.value_free`
                            : `${copyContext}.order_summary.delivery.value_offered`
                        }
                        variables={{
                          deliveryFee: formatCurrencyWithDecimal(
                            firstOrderPricing.netDeliverySurchargePrice,
                            currencyOptions
                          )
                        }}
                        namespace={namespace}
                        variant="textRegular16"
                        colour="brandBlue500"
                        margin={false}
                        shouldScale
                      />
                    </div>
                    {firstOrderPricing.totalDiscountedAmount > 0 && (
                      <div
                        className={`${STYLES.orderSummaryItem} ${STYLES.discount}`}
                      >
                        <Text
                          text={`${copyContext}.order_summary.discount.label`}
                          namespace={namespace}
                          variant="textRegular16"
                          colour="brandRed500"
                          margin={false}
                          shouldScale
                        />
                        <Text
                          text={`${copyContext}.order_summary.discount.value`}
                          variables={{
                            discountValue: formatCurrencyWithDecimal(
                              firstOrderPricing.totalDiscountedAmount,
                              currencyOptions
                            )
                          }}
                          namespace={namespace}
                          variant="textRegular16"
                          colour="brandRed500"
                          margin={false}
                          shouldScale
                        />
                      </div>
                    )}
                    <div className={STYLES.orderSummaryItem}>
                      <Text
                        text={`${copyContext}.order_summary.box_total.label`}
                        namespace={namespace}
                        variant="display14"
                        colour="brandBlue500"
                        margin={false}
                        shouldScale
                      />
                      <Text
                        text={`${copyContext}.order_summary.box_total.value`}
                        variables={{
                          totalPrice: formatCurrencyWithDecimal(
                            firstOrderPricing.netTotalPrice,
                            currencyOptions
                          )
                        }}
                        namespace={namespace}
                        variant="display14"
                        colour="brandBlue500"
                        margin={false}
                        shouldScale
                      />
                    </div>
                    {!isUndefined(discounts) && discounts.length >= 2 && (
                      // eslint-disable-next-line jsx-a11y/label-has-for
                      <Label
                        variant="secondBoxDiscount"
                        text={{
                          namespace,
                          text: `${copyContext}.order_summary.second_discount.label`,
                          variables: {
                            value:
                              discounts[1].discountBasis === 'percentage'
                                ? percentageValue(discounts[1].value)
                                : formatCurrencyWithDecimal(
                                    discounts[1].value,
                                    currencyOptions
                                  )
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
          </Card>
        </div>
      </div>
      <FAQSection
        namespace={namespace}
        maxRecipes={maxFlavours}
        numberOfDogs={individual.length}
        shippingCountryCode={countryCode}
      />
      <StickyNavigation
        variant="twoButtons"
        buttonOne={{
          url:
            recommendedExtraProductVariants.length > 0
              ? PlansRoutes.Extras
              : PlansRoutes.Plan,
          variant: 'secondary',
          text: 'plan_steps.navigation.back',
          iconColour: 'brandRed400',
          namespace,
          onClick: backButtonClick
        }}
        buttonTwo={{
          dataTestId: 'next-button',
          variant: 'primary',
          text: 'plan_steps.navigation.continue',
          iconColour: 'brandWhite',
          onClick: proceedToCheckout,
          namespace
        }}
      />
      <ConfirmationModal />
    </div>
  )
}

const Review = ({
  variant,
  maxFlavours,
  namespace
}: Props): JSX.Element | null => {
  const wizardState: {
    user: User
    dogs: WizardDog[]
  } | null = useReactiveVar(plansPageWizardState)

  if (!verifyWizardData(wizardState) || !wizardState) {
    handleWizardDataError(wizardState)
    return null
  }

  return (
    <ReviewPageView
      variant={variant}
      maxFlavours={maxFlavours}
      namespace={namespace}
      wizardState={wizardState}
    />
  )
}

export { Props }
export default Review
