import isUndefined from 'lodash/isUndefined'
import React, { ReactElement } from 'react'

import STYLES from './Truncate.module.sass'

type Props = {
  lines?: 1 | 2 | 3 | 4 | 5
  children: string | ReactElement
}

const Truncate = ({ lines, children }: Props): JSX.Element | null => {
  return (
    <div
      className={`${
        !isUndefined(lines)
          ? STYLES[('lines' + lines) as keyof typeof STYLES]
          : ''
      }`}
    >
      {children}
    </div>
  )
}

export { Props }
export default Truncate
