import { useReactiveVar } from '@apollo/client'
import React, { useEffect } from 'react'

import BasicInfoDog from 'assets/images/illustrations/dogs/wizard-footer/basic-info-dog.svg'

import ContactCustomerLoveButton from '../../components/ContactCustomerLove/ContactCustomerLoveButton'
import Text from '@/components/elements/atoms/Text/Text'
import WizardFooter from '@/components/elements/organisms/WizardFooter/WizardFooter'

import STYLES from './Error.module.sass'

import { selfResolutionPageState } from '../../SelfResolutionPage'
import useSelfResolutionTracking from '../../analytics'

type Props = {
  namespace: string
  errorType?: string
}

const Error = ({ namespace, errorType = 'default' }: Props): JSX.Element => {
  const selfResolutionState = useReactiveVar(selfResolutionPageState)
  const pageTitle = 'error.titles.' + errorType
  const selfResolutionTracking = useSelfResolutionTracking()

  useEffect(() => {
    selfResolutionPageState({
      ...selfResolutionState,
      showBackButton: false,
      pageTitle
    })
    selfResolutionTracking.trackEvent('error page loaded')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={STYLES.container}>
      <Text namespace={namespace} text={`error.text_1`} />
      <Text namespace={namespace} text={`error.text_2`} />
      <div className={STYLES.actions}>
        <ContactCustomerLoveButton namespace={namespace} tracking={'Error'} />
      </div>
      <WizardFooter
        variant="customerLove"
        namespace={'wizard_flow'}
        title={'footer.title'}
        subtitle={'footer.subtitle'}
        iconAlt={'footer.guarantee_badge_icon_alt'}
        illustration={{
          img: BasicInfoDog,
          imgAlt: 'footer.basic_info_dog_alt',
          imgPosition: 'right'
        }}
      />
    </div>
  )
}

export { Props }
export default Error
