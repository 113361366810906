// @noflow
import React from 'react'

import {
  ICON_COLOURS,
  IconColours
} from '@/components/elements/atoms/Icon/Icon'

type Props = {
  size: number
  width?: number | string
  accentColour: IconColours
}

const DogBowl = ({
  size,
  width,
  accentColour = 'brandPink400'
}: Props): JSX.Element => {
  return (
    <svg
      width={width || '100%'}
      height={size}
      viewBox="0 0 80 63"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.595 33.527c-.324-2.98 0-8.98 3.887-9.135 3.888-.156 3.952-.584 3.499-.778-.26-2.462-.078-7.386 2.721-7.386 1.81 0 2.525.054 2.735.11.145-.873.594-2.574 1.347-3.025.972-.583 1.943-1.36 3.693-1.36.971-2.139 1.555-5.054 5.247-5.054.584-1.361 1.361-2.722 3.888-1.944.777-3.304 1.75-5.442 3.887-4.86 2.138.584 3.11.778 5.831 2.333 2.721 1.555 4.47.972 4.665 1.555.194.583 1.555 1.75 2.721 1.75 1.166 0 2.916-.584 3.304.777.39 1.36.583 2.332 1.555 3.304s2.916 2.527 2.138 3.499c-.622.777-1.814 2.008-2.332 2.526 1.684.648 5.17 2.8 5.636 6.22 4.277 2.138 5.248 2.721 5.248 4.86 0 2.137-.971 6.219-1.555 6.996-.583.778-54.228 4.471-58.115-.388Z"
        fill="#C18666"
      />
      <path
        d="M43.054 20.893c-.466-1.089-2.915-.583-4.082-.195-1.36 1.555 1.36 1.555 1.944 1.555.583 0 1.36 0 2.332 1.555.778-.971.39-1.555-.194-2.915ZM54.12 27.381c-.466-1.088-2.915-.583-4.081-.194-1.36 1.554 1.36 1.555 1.944 1.555.583 0 1.36 0 2.332 1.555.778-.972.389-1.556-.194-2.916ZM49.146 10.645c-.704-.953-2.972.102-4.018.748-.967 1.825 1.682 1.2 2.25 1.067.567-.134 1.323-.313 2.626.978.534-1.125.022-1.603-.858-2.793ZM28.75 23.734c-1.151-.276-2.209 1.99-2.593 3.158.435 2.02 2.06-.162 2.408-.63.348-.468.812-1.091 2.64-.942-.315-1.204-1.015-1.24-2.455-1.586ZM37.983 12.258c-1.007-.623-2.721 1.198-3.452 2.186-.22 2.054 2.007.49 2.484.156.477-.335 1.114-.782 2.802-.067.078-1.242-.575-1.496-1.834-2.275ZM60.936 24.197c-3.11-1.244-4.406.26-4.665 1.166 3.304-.583 3.693.583 5.053 2.138 1.167-3.304 5.054-3.304 6.609-2.72 1.542.577 1.364 3.066 1.171 5.76l-.005.07c.972 0 .972-2.915.972-3.887s-1.36-2.916-2.915-3.693c-1.555-.778-3.5-.583-6.22 1.166ZM26.922 17.829c3.11-1.244 4.405.259 4.664 1.166-3.304-.583-3.692.583-5.053 2.138-1.166-3.304-5.054-3.304-6.609-2.721-1.541.578-1.363 3.067-1.17 5.761l.004.07c-.972 0-.972-2.915-.972-3.887s1.361-2.916 2.916-3.693c1.555-.778 3.498-.583 6.22 1.166ZM56.077 17.394c.466-.777 3.045-1.101 4.276-1.166.583.194 2.138 1.166 2.526 1.944.312.622.648 2.073.778 2.72-.26-.388-.933-1.36-1.555-2.137-.777-.972-3.304-.972-4.47 0-1.167.972-2.333.583-2.527.194-.194-.389.389-.583.972-1.555Z"
        fill="#8A634F"
      />
      <path
        d="M5.736 32.166c.622-.933 4.535-.389 6.414 0 3.822.324 12.79.933 18.076.778 5.287-.156 21.64-.584 29.155-.778h15.355c1.244 0 3.11 10.107 3.887 15.16.518 1.815 1.516 6.298 1.36 9.719-.194 4.276-4.47 4.47-6.802 4.47H54.52c-5.895.26-18.425.622-21.38 0-3.693-.777-22.74.778-29.154.778-6.415 0-3.305-6.414-2.722-9.524.467-2.488 2.527-10.366 3.499-13.994.065-1.814.35-5.676.972-6.609Z"
        fill={ICON_COLOURS[accentColour]}
      />
      <path
        d="M51.828 21.335c0 .226-.07.573.093.757.138.156.438.25.64.17.188-.072.28-.169.276-.372-.003-.226-.173-.44-.413-.44M35.065 26.669c0 .226-.07.573.093.756.138.156.438.25.641.171.188-.073.279-.17.275-.372-.003-.226-.173-.44-.413-.44M30.493 8.382c0 .226-.07.573.093.757.139.155.438.25.641.17.188-.073.279-.17.276-.372-.004-.226-.174-.44-.413-.44"
        stroke="#5E862C"
      />
      <path
        d="m42.248 6.792 1.143-2.286c.48-.96 0-.762 1.524-.381 1.524.38 1.143 1.524.762 2.286-.38.762-.38 1.523-.762 2.285-.38.762-.38.762-1.905 0-1.523-.762-1.142-1.143-.761-1.904ZM53.023 15.922l-1.02-1.935c-.428-.813-.534-.35.508-1.357 1.041-1.006 1.648-.186 1.988.46.34.645.874.995 1.214 1.64.34.645.34.645-.968 1.477-1.308.831-1.381.36-1.721-.285ZM20.5 27.782l.616-2.1c.26-.881-.139-.622 1.31-.619 1.447.003 1.312 1.015 1.106 1.715-.205.7-.066 1.322-.271 2.022-.206.7-.206.7-1.723.385-1.518-.314-1.243-.703-1.038-1.403Z"
        fill="#C76642"
      />
    </svg>
  )
}

export default DogBowl
