import Cockapoo from 'assets/images/illustrations/dogs/dog-weight/cockapoo-white.svg'
import Dachshund from 'assets/images/illustrations/dogs/dog-weight/dachshund-white.svg'
import Dalmatian from 'assets/images/illustrations/dogs/dog-weight/dalmatian-white.svg'
import GermanShepherd from 'assets/images/illustrations/dogs/dog-weight/german-shepherd-white.svg'
import GreatDane from 'assets/images/illustrations/dogs/dog-weight/great-dane-white.svg'
import SpringerSpaniel from 'assets/images/illustrations/dogs/dog-weight/springer-spaniel-white.svg'
import Staffie from 'assets/images/illustrations/dogs/dog-weight/staffie-white.svg'

type Waypoint = {
  dog: string
  img: string
  alt: string
  weightMid: number
  weightMin: number
  weightMax: number
  width?: number
  height?: number
}

type WeightEstimate = {
  breed: string
  nameKey: string
  ageInWeeks: 8 | 9 | 10 | 11 | 12
  weightEstimate: number
}

const waypoints: Waypoint[] = [
  {
    dog: 'dachshund',
    img: Dachshund,
    alt: 'weight_step.dachshund_image_alt',
    weightMid: 4000,
    weightMin: 1000,
    weightMax: 8000
  },
  {
    dog: 'cockapoo',
    img: Cockapoo,
    alt: 'weight_step.cockapoo_image_alt',
    weightMid: 11000,
    weightMin: 8000,
    weightMax: 15000
  },
  {
    dog: 'springerSpaniel',
    img: SpringerSpaniel,
    alt: 'weight_step.springer_spaniel_image_alt',
    weightMid: 18000,
    weightMin: 15000,
    weightMax: 22000
  },
  {
    dog: 'dalmatian',
    img: Dalmatian,
    alt: 'weight_step.dalmatian_image_alt',
    weightMid: 25000,
    weightMin: 22000,
    weightMax: 29000
  },
  {
    dog: 'staffie',
    img: Staffie,
    alt: 'weight_step.staffie_image_alt',
    weightMid: 32000,
    weightMin: 29000,
    weightMax: 36000
  },
  {
    dog: 'germanShepherd',
    img: GermanShepherd,
    alt: 'weight_step.german_shepherd_image_alt',
    weightMid: 39000,
    weightMin: 36000,
    weightMax: 43000
  },
  {
    dog: 'greatDane',
    img: GreatDane,
    alt: 'weight_step.great_dane_image_alt',
    weightMid: 46000,
    weightMin: 43000,
    weightMax: 53000
  }
]

// We only have data for puppies aged between 8 - 12 weeks
// Data collated from this Metabase question: https://metabase.dev.butternutbox.com/question/2742?age_in_week=10
// median_weight values are used below rounded to the nearest .5kg
const dogWeightEstimates: WeightEstimate[] = [
  {
    breed: 'french_bulldog',
    nameKey: 'french_bulldog.name',
    ageInWeeks: 8,
    weightEstimate: 3500
  },
  {
    breed: 'french_bulldog',
    nameKey: 'french_bulldog.name',
    ageInWeeks: 9,
    weightEstimate: 3500
  },
  {
    breed: 'french_bulldog',
    nameKey: 'french_bulldog.name',
    ageInWeeks: 10,
    weightEstimate: 3500
  },
  {
    breed: 'french_bulldog',
    nameKey: 'french_bulldog.name',
    ageInWeeks: 11,
    weightEstimate: 3500
  },
  {
    breed: 'french_bulldog',
    nameKey: 'french_bulldog.name',
    ageInWeeks: 12,
    weightEstimate: 4000
  },
  {
    breed: 'cocker_spaniel',
    nameKey: 'cocker_spaniel.name',
    ageInWeeks: 8,
    weightEstimate: 4000
  },
  {
    breed: 'cocker_spaniel',
    nameKey: 'cocker_spaniel.name',
    ageInWeeks: 9,
    weightEstimate: 4000
  },
  {
    breed: 'cocker_spaniel',
    nameKey: 'cocker_spaniel.name',
    ageInWeeks: 10,
    weightEstimate: 4000
  },
  {
    breed: 'cocker_spaniel',
    nameKey: 'cocker_spaniel.name',
    ageInWeeks: 11,
    weightEstimate: 4000
  },
  {
    breed: 'cocker_spaniel',
    nameKey: 'cocker_spaniel.name',
    ageInWeeks: 12,
    weightEstimate: 4000
  },
  {
    breed: 'labrador_retriever',
    nameKey: 'labrador_retriever.name',
    ageInWeeks: 8,
    weightEstimate: 7500
  },
  {
    breed: 'labrador_retriever',
    nameKey: 'labrador_retriever.name',
    ageInWeeks: 9,
    weightEstimate: 6000
  },
  {
    breed: 'labrador_retriever',
    nameKey: 'labrador_retriever.name',
    ageInWeeks: 10,
    weightEstimate: 7000
  },
  {
    breed: 'labrador_retriever',
    nameKey: 'labrador_retriever.name',
    ageInWeeks: 11,
    weightEstimate: 7500
  },
  {
    breed: 'labrador_retriever',
    nameKey: 'labrador_retriever.name',
    ageInWeeks: 12,
    weightEstimate: 7000
  },
  {
    breed: 'chihuahua',
    nameKey: 'chihuahua.name',
    ageInWeeks: 8,
    weightEstimate: 1500
  },
  {
    breed: 'chihuahua',
    nameKey: 'chihuahua.name',
    ageInWeeks: 9,
    weightEstimate: 1000
  },
  {
    breed: 'chihuahua',
    nameKey: 'chihuahua.name',
    ageInWeeks: 10,
    weightEstimate: 1500
  },
  {
    breed: 'chihuahua',
    nameKey: 'chihuahua.name',
    ageInWeeks: 11,
    weightEstimate: 1500
  },
  {
    breed: 'chihuahua',
    nameKey: 'chihuahua.name',
    ageInWeeks: 12,
    weightEstimate: 1000
  },
  {
    breed: 'pug',
    nameKey: 'pug.name',
    ageInWeeks: 8,
    weightEstimate: 2500
  },
  {
    breed: 'pug',
    nameKey: 'pug.name',
    ageInWeeks: 9,
    weightEstimate: 2500
  },
  {
    breed: 'pug',
    nameKey: 'pug.name',
    ageInWeeks: 10,
    weightEstimate: 2500
  },
  {
    breed: 'pug',
    nameKey: 'pug.name',
    ageInWeeks: 11,
    weightEstimate: 2500
  },
  {
    breed: 'pug',
    nameKey: 'pug.name',
    ageInWeeks: 12,
    weightEstimate: 2500
  },
  {
    breed: 'golden_retriever',
    nameKey: 'golden_retriever.name',
    ageInWeeks: 8,
    weightEstimate: 7000
  },
  {
    breed: 'golden_retriever',
    nameKey: 'golden_retriever.name',
    ageInWeeks: 9,
    weightEstimate: 7000
  },
  {
    breed: 'golden_retriever',
    nameKey: 'golden_retriever.name',
    ageInWeeks: 10,
    weightEstimate: 7000
  },
  {
    breed: 'golden_retriever',
    nameKey: 'golden_retriever.name',
    ageInWeeks: 11,
    weightEstimate: 7000
  },
  {
    breed: 'golden_retriever',
    nameKey: 'golden_retriever.name',
    ageInWeeks: 12,
    weightEstimate: 7000
  },
  {
    breed: 'staffordshire_bull_terrier',
    nameKey: 'staffordshire_bull_terrier.name',
    ageInWeeks: 8,
    weightEstimate: 5000
  },
  {
    breed: 'staffordshire_bull_terrier',
    nameKey: 'staffordshire_bull_terrier.name',
    ageInWeeks: 9,
    weightEstimate: 4500
  },
  {
    breed: 'staffordshire_bull_terrier',
    nameKey: 'staffordshire_bull_terrier.name',
    ageInWeeks: 10,
    weightEstimate: 5000
  },
  {
    breed: 'staffordshire_bull_terrier',
    nameKey: 'staffordshire_bull_terrier.name',
    ageInWeeks: 11,
    weightEstimate: 4500
  },
  {
    breed: 'staffordshire_bull_terrier',
    nameKey: 'staffordshire_bull_terrier.name',
    ageInWeeks: 12,
    weightEstimate: 5000
  },
  {
    breed: 'cockapoo_cocker_spaniel_poodle_mix',
    nameKey: 'cockapoo_cocker_spaniel_poodle_mix.sanitised_name',
    ageInWeeks: 8,
    weightEstimate: 3500
  },
  {
    breed: 'cockapoo_cocker_spaniel_poodle_mix',
    nameKey: 'cockapoo_cocker_spaniel_poodle_mix.sanitised_name',
    ageInWeeks: 9,
    weightEstimate: 3500
  },
  {
    breed: 'cockapoo_cocker_spaniel_poodle_mix',
    nameKey: 'cockapoo_cocker_spaniel_poodle_mix.sanitised_name',
    ageInWeeks: 10,
    weightEstimate: 3000
  },
  {
    breed: 'cockapoo_cocker_spaniel_poodle_mix',
    nameKey: 'cockapoo_cocker_spaniel_poodle_mix.sanitised_name',
    ageInWeeks: 11,
    weightEstimate: 3500
  },
  {
    breed: 'cockapoo_cocker_spaniel_poodle_mix',
    nameKey: 'cockapoo_cocker_spaniel_poodle_mix.sanitised_name',
    ageInWeeks: 12,
    weightEstimate: 3500
  },
  {
    breed: 'cavapoo_cavalier_king_charles_spaniel_poodle_mix',
    nameKey: 'cavapoo_cavalier_king_charles_spaniel_poodle_mix.sanitised_name',
    ageInWeeks: 8,
    weightEstimate: 3000
  },
  {
    breed: 'cavapoo_cavalier_king_charles_spaniel_poodle_mix',
    nameKey: 'cavapoo_cavalier_king_charles_spaniel_poodle_mix.sanitised_name',
    ageInWeeks: 9,
    weightEstimate: 3000
  },
  {
    breed: 'cavapoo_cavalier_king_charles_spaniel_poodle_mix',
    nameKey: 'cavapoo_cavalier_king_charles_spaniel_poodle_mix.sanitised_name',
    ageInWeeks: 10,
    weightEstimate: 3000
  },
  {
    breed: 'cavapoo_cavalier_king_charles_spaniel_poodle_mix',
    nameKey: 'cavapoo_cavalier_king_charles_spaniel_poodle_mix.sanitised_name',
    ageInWeeks: 11,
    weightEstimate: 3000
  },
  {
    breed: 'cavapoo_cavalier_king_charles_spaniel_poodle_mix',
    nameKey: 'cavapoo_cavalier_king_charles_spaniel_poodle_mix.sanitised_name',
    ageInWeeks: 12,
    weightEstimate: 3000
  },
  {
    breed: 'miniature_dachshund',
    nameKey: 'miniature_dachshund.name',
    ageInWeeks: 8,
    weightEstimate: 2500
  },
  {
    breed: 'miniature_dachshund',
    nameKey: 'miniature_dachshund.name',
    ageInWeeks: 9,
    weightEstimate: 2000
  },
  {
    breed: 'miniature_dachshund',
    nameKey: 'miniature_dachshund.name',
    ageInWeeks: 10,
    weightEstimate: 2000
  },
  {
    breed: 'miniature_dachshund',
    nameKey: 'miniature_dachshund.name',
    ageInWeeks: 11,
    weightEstimate: 2000
  },
  {
    breed: 'miniature_dachshund',
    nameKey: 'miniature_dachshund.name',
    ageInWeeks: 12,
    weightEstimate: 2500
  },
  {
    breed: 'jack_russell_terrier',
    nameKey: 'jack_russell_terrier.name',
    ageInWeeks: 8,
    weightEstimate: 3000
  },
  {
    breed: 'jack_russell_terrier',
    nameKey: 'jack_russell_terrier.name',
    ageInWeeks: 9,
    weightEstimate: 2500
  },
  {
    breed: 'jack_russell_terrier',
    nameKey: 'jack_russell_terrier.name',
    ageInWeeks: 10,
    weightEstimate: 2500
  },
  {
    breed: 'jack_russell_terrier',
    nameKey: 'jack_russell_terrier.name',
    ageInWeeks: 11,
    weightEstimate: 3000
  },
  {
    breed: 'jack_russell_terrier',
    nameKey: 'jack_russell_terrier.name',
    ageInWeeks: 12,
    weightEstimate: 2500
  },
  {
    breed: 'dachshund',
    nameKey: 'dachshund.name',
    ageInWeeks: 8,
    weightEstimate: 2000
  },
  {
    breed: 'dachshund',
    nameKey: 'dachshund.name',
    ageInWeeks: 9,
    weightEstimate: 3000
  },
  {
    breed: 'dachshund',
    nameKey: 'dachshund.name',
    ageInWeeks: 10,
    weightEstimate: 2500
  },
  {
    breed: 'dachshund',
    nameKey: 'dachshund.name',
    ageInWeeks: 11,
    weightEstimate: 2500
  },
  {
    breed: 'dachshund',
    nameKey: 'dachshund.name',
    ageInWeeks: 12,
    weightEstimate: 3000
  },
  {
    breed: 'whippet',
    nameKey: 'whippet.name',
    ageInWeeks: 8,
    weightEstimate: 5000
  },
  {
    breed: 'whippet',
    nameKey: 'whippet.name',
    ageInWeeks: 9,
    weightEstimate: 5000
  },
  {
    breed: 'whippet',
    nameKey: 'whippet.name',
    ageInWeeks: 10,
    weightEstimate: 4000
  },
  {
    breed: 'whippet',
    nameKey: 'whippet.name',
    ageInWeeks: 11,
    weightEstimate: 5000
  },
  {
    breed: 'whippet',
    nameKey: 'whippet.name',
    ageInWeeks: 12,
    weightEstimate: 5000
  },
  {
    breed: 'labradoodle_labrador_retriever_poodle_mix',
    nameKey: 'labradoodle_labrador_retriever_poodle_mix.sanitised_name',
    ageInWeeks: 8,
    weightEstimate: 5500
  },
  {
    breed: 'labradoodle_labrador_retriever_poodle_mix',
    nameKey: 'labradoodle_labrador_retriever_poodle_mix.sanitised_name',
    ageInWeeks: 9,
    weightEstimate: 5000
  },
  {
    breed: 'labradoodle_labrador_retriever_poodle_mix',
    nameKey: 'labradoodle_labrador_retriever_poodle_mix.sanitised_name',
    ageInWeeks: 10,
    weightEstimate: 5000
  },
  {
    breed: 'labradoodle_labrador_retriever_poodle_mix',
    nameKey: 'labradoodle_labrador_retriever_poodle_mix.sanitised_name',
    ageInWeeks: 11,
    weightEstimate: 6000
  },
  {
    breed: 'labradoodle_labrador_retriever_poodle_mix',
    nameKey: 'labradoodle_labrador_retriever_poodle_mix.sanitised_name',
    ageInWeeks: 12,
    weightEstimate: 5000
  },
  {
    breed: 'shih_tzu',
    nameKey: 'shih_tzu.name',
    ageInWeeks: 8,
    weightEstimate: 3000
  },
  {
    breed: 'shih_tzu',
    nameKey: 'shih_tzu.name',
    ageInWeeks: 9,
    weightEstimate: 2000
  },
  {
    breed: 'shih_tzu',
    nameKey: 'shih_tzu.name',
    ageInWeeks: 10,
    weightEstimate: 2000
  },
  {
    breed: 'shih_tzu',
    nameKey: 'shih_tzu.name',
    ageInWeeks: 11,
    weightEstimate: 2000
  },
  {
    breed: 'shih_tzu',
    nameKey: 'shih_tzu.name',
    ageInWeeks: 12,
    weightEstimate: 3000
  },
  {
    breed: 'cavapoochon_cavalier_king_charles_bichon_frise_poodle_mix',
    nameKey:
      'cavapoochon_cavalier_king_charles_bichon_frise_poodle_mix.sanitised_name',
    ageInWeeks: 8,
    weightEstimate: 3000
  },
  {
    breed: 'cavapoochon_cavalier_king_charles_bichon_frise_poodle_mix',
    nameKey:
      'cavapoochon_cavalier_king_charles_bichon_frise_poodle_mix.sanitised_name',
    ageInWeeks: 9,
    weightEstimate: 3000
  },
  {
    breed: 'cavapoochon_cavalier_king_charles_bichon_frise_poodle_mix',
    nameKey:
      'cavapoochon_cavalier_king_charles_bichon_frise_poodle_mix.sanitised_name',
    ageInWeeks: 10,
    weightEstimate: 3000
  },
  {
    breed: 'cavapoochon_cavalier_king_charles_bichon_frise_poodle_mix',
    nameKey:
      'cavapoochon_cavalier_king_charles_bichon_frise_poodle_mix.sanitised_name',
    ageInWeeks: 11,
    weightEstimate: 3000
  },
  {
    breed: 'cavapoochon_cavalier_king_charles_bichon_frise_poodle_mix',
    nameKey:
      'cavapoochon_cavalier_king_charles_bichon_frise_poodle_mix.sanitised_name',
    ageInWeeks: 12,
    weightEstimate: 3000
  },
  {
    breed: 'cavalier_king_charles_spaniel',
    nameKey: 'cavalier_king_charles_spaniel.name',
    ageInWeeks: 8,
    weightEstimate: 3000
  },
  {
    breed: 'cavalier_king_charles_spaniel',
    nameKey: 'cavalier_king_charles_spaniel.name',
    ageInWeeks: 9,
    weightEstimate: 3000
  },
  {
    breed: 'cavalier_king_charles_spaniel',
    nameKey: 'cavalier_king_charles_spaniel.name',
    ageInWeeks: 10,
    weightEstimate: 3000
  },
  {
    breed: 'cavalier_king_charles_spaniel',
    nameKey: 'cavalier_king_charles_spaniel.name',
    ageInWeeks: 11,
    weightEstimate: 3000
  },
  {
    breed: 'cavalier_king_charles_spaniel',
    nameKey: 'cavalier_king_charles_spaniel.name',
    ageInWeeks: 12,
    weightEstimate: 3000
  },
  {
    breed: 'maltipoo_maltese_poodle_mix',
    nameKey: 'maltipoo_maltese_poodle_mix.sanitised_name',
    ageInWeeks: 8,
    weightEstimate: 2000
  },
  {
    breed: 'maltipoo_maltese_poodle_mix',
    nameKey: 'maltipoo_maltese_poodle_mix.sanitised_name',
    ageInWeeks: 9,
    weightEstimate: 2000
  },
  {
    breed: 'maltipoo_maltese_poodle_mix',
    nameKey: 'maltipoo_maltese_poodle_mix.sanitised_name',
    ageInWeeks: 10,
    weightEstimate: 1500
  },
  {
    breed: 'maltipoo_maltese_poodle_mix',
    nameKey: 'maltipoo_maltese_poodle_mix.sanitised_name',
    ageInWeeks: 11,
    weightEstimate: 2000
  },
  {
    breed: 'maltipoo_maltese_poodle_mix',
    nameKey: 'maltipoo_maltese_poodle_mix.sanitised_name',
    ageInWeeks: 12,
    weightEstimate: 2500
  },
  {
    breed: 'border_collie',
    nameKey: 'border_collie.name',
    ageInWeeks: 8,
    weightEstimate: 6500
  },
  {
    breed: 'border_collie',
    nameKey: 'border_collie.name',
    ageInWeeks: 9,
    weightEstimate: 5000
  },
  {
    breed: 'border_collie',
    nameKey: 'border_collie.name',
    ageInWeeks: 10,
    weightEstimate: 5000
  },
  {
    breed: 'border_collie',
    nameKey: 'border_collie.name',
    ageInWeeks: 11,
    weightEstimate: 5000
  },
  {
    breed: 'border_collie',
    nameKey: 'border_collie.name',
    ageInWeeks: 12,
    weightEstimate: 6000
  }
]

export { waypoints, dogWeightEstimates }
