import classNames from 'classnames'
import React from 'react'

import { possessive } from '@/utils/StringHelper'

import PolaroidFrame from 'assets/images/backgrounds/dog-profile-polaroid-frame.svg'

import SuccessOverlay from '@/components/elements/organisms/SuccessOverlay/SuccessOverlay'

import STYLES from '../DogProfile/DogProfile.module.sass'

import { thankYouQueryV2_user_dogs as Dog } from '../../__generated__/thankYouQueryV2'

type Props = {
  confirmationDog: Dog
  handleSuccessClose: () => void
  confirmationPolaroid: string
}

const DogProfileConfirmation = ({
  confirmationDog,
  handleSuccessClose,
  confirmationPolaroid
}: Props): JSX.Element => {
  const cn = classNames(STYLES.polaroidPicture, STYLES.rotate)

  return (
    <SuccessOverlay
      handleClose={handleSuccessClose}
      typography={{
        namespace: 'account',
        text: 'thank_you_page_v2.dog_profile.confirmation_title',
        margin: false,
        variables: { dogName: possessive(confirmationDog.name) }
      }}
      footnote={{
        namespace: 'account',
        text: 'thank_you_page_v2.dog_profile.confirmation_sub_title',
        margin: false
      }}
    >
      <div className={STYLES.polaroid}>
        <img
          src={PolaroidFrame}
          alt={confirmationDog.name}
          className={STYLES.polaroidFrame}
        />

        <img
          src={confirmationPolaroid}
          className={cn}
          alt={confirmationDog.name}
        />
      </div>
    </SuccessOverlay>
  )
}

export { DogProfileConfirmation }
