// @noflow
import { PaymentMethod, Stripe } from '@stripe/stripe-js'

import { PaymentMethodType, PaymentMethodable, Provider } from '@/types'

import { State } from '../../../PayOnOwnDevicePage'
import formatSubscriptionData from '../../../helpers/buiildSubscriptionData'
import stripeMutationMethods from '../sharedStripeMutationMethods'

const confirmPaymentRequestPayment = async ({
  stripe,
  clientSecret,
  paymentMethodId
}: {
  stripe: Stripe
  clientSecret: string
  paymentMethodId: string
}) => {
  // Confirm the PaymentIntent without handling potential next actions (yet).
  return await stripe
    .confirmCardPayment(
      clientSecret,
      {
        payment_method: paymentMethodId
      },
      { handleActions: false }
    )
    .then((response) => {
      const { paymentIntent, error: confirmError } = response
      // Check if the PaymentIntent requires any actions and,
      // if so, let Stripe.js handle the flow
      if (paymentIntent && paymentIntent.status === 'requires_action') {
        return stripe.confirmCardPayment(clientSecret).then((response) => {
          const { paymentIntent: handledPaymentIntent, error } = response
          if (error) throw new Error(error.message)
          if (!handledPaymentIntent)
            throw new Error('Error confirming the payment')

          return handledPaymentIntent.id
        })
      }

      if (paymentIntent) return paymentIntent.id

      if (confirmError) throw new Error(confirmError.message)

      return undefined
    })
}

export const chargePaymentRequestBoxOne = async ({
  paymentPageState,
  paymentMethod,
  stripe,
  requestType,
  email,
  amount
}: {
  paymentPageState: State
  paymentMethod: PaymentMethod
  stripe: Stripe
  requestType: string
  email: string
  amount: number
}): Promise<string | void> => {
  const { stripeCustomerCreate, stripeSetupIntentCreate, raiseBannerError } =
    stripeMutationMethods()
  return await stripeCustomerCreate({
    paymentMethodType: 'card',
    email: email,
    amount: amount
  })
    .then((res) => {
      if (res?.data?.directSalesStripeCustomerCreate) {
        const clientSecret =
          res?.data.directSalesStripeCustomerCreate?.paymentIntentId
        const paymentIntentId = clientSecret.split('_secret')[0]
        if (clientSecret) {
          const user =
            paymentPageState.data.directSalesPendingSubscription
              .pendingSubscription.user

          const formattedSubData = formatSubscriptionData({
            targetFirstDeliveryDate: paymentPageState.targetFirstDeliveryDate,
            subscriptionData: paymentPageState.data,
            provider: Provider.stripe,
            paymentMethodType: PaymentMethodable.CreditCard,
            paymentMethod: paymentMethod,
            paymentIntentId: paymentIntentId,
            supportedMarketingMethodPurposes:
              paymentPageState.marketingPreferences
          })
          if (formattedSubData) {
            stripeSetupIntentCreate({
              authenticationType: requestType,
              formattedSubscriptionData: formattedSubData,
              paymentIntentId: paymentIntentId,
              firstName: user.firstName,
              lastName: user.lastName || '',
              paymentMethodType: PaymentMethodType.credit_card
            })
            const response = confirmPaymentRequestPayment({
              stripe,
              clientSecret,
              paymentMethodId: paymentMethod.id
            })
            return response
          }
        }
      }
    })
    .catch((error) => {
      raiseBannerError(error)
    })
}
