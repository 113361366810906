// @noflow
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  countrySpecificFacebookLink,
  countrySpecificInstagramLink,
  countrySpecificTikTokLink
} from '@/utils/countryCodeHelper'

import Facebook from 'assets/images/logos/facebook-logo-white.svg'
import Instagram from 'assets/images/logos/instagram-logo-white.svg'
import TikTok from 'assets/images/logos/tiktok-logo-white.svg'
import YouTube from 'assets/images/logos/youtube-logo-white.svg'

import Text from '@/components/elements/atoms/Text/Text'
import InstagramCarousel from '@/components/elements/organisms/InstagramCarousel/InstagramCarousel'

import STYLES from './SocialReviewsSection.module.sass'

import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'

type Props = {
  countryCode: CountryCode
}

const SocialReviewsSection = ({ countryCode }: Props): JSX.Element => {
  const copyContext = 'social_reviews'
  const namespace = 'home_page'
  const { t } = useTranslation(namespace)

  const socialMediaLinks = [
    {
      key: 'instagram',
      img: Instagram,
      href: countrySpecificInstagramLink(countryCode)
    },
    {
      key: 'facebook',
      img: Facebook,
      href: countrySpecificFacebookLink(countryCode)
    },
    {
      key: 'youtube',
      img: YouTube,
      href: 'https://www.youtube.com/c/butternutbox'
    },
    {
      key: 'tiktok',
      img: TikTok,
      href: countrySpecificTikTokLink(countryCode)
    }
  ]

  return (
    <section className={STYLES.socialSection}>
      <Text
        align="center"
        element="h2"
        variant="display28"
        text={`${copyContext}.title`}
        namespace={namespace}
        colour="brandWhite"
      />
      <Text
        align="center"
        variant="textRegular18"
        text={`${copyContext}.text`}
        namespace={namespace}
        colour="brandWhite"
      />
      <InstagramCarousel countryCode={countryCode} />
      <Text
        align="center"
        variant="textRegular18"
        text={`${copyContext}.prompt`}
        namespace={namespace}
        colour="brandWhite"
      />
      <div className={STYLES.socialIcons}>
        {socialMediaLinks
          .filter((link) => {
            return countryCode === 'PL' && link.key !== 'youtube'
          })
          .map(({ key, img, href }) => (
            <a key={key} href={href} rel="noopener noreferrer" target="_blank">
              <img src={img} alt={t(`${copyContext}.${key}`)} loading="lazy" />
            </a>
          ))}
      </div>
    </section>
  )
}

export { Props }
export default SocialReviewsSection
