// @noflow
import React from 'react'

import Text from '@/components/elements/atoms/Text/Text'
import StarRating from '@/components/elements/molecules/StarRating/StarRating'

import STYLES from './CustomerReviews.module.sass'

import type {
  ProductCollectionQuery_productCollection_publicCustomerReviews as CustomerReview,
  ProductCollectionQuery_productCollection as ProductCollection
} from '../../../queries/__generated__/ProductCollectionQuery'

import { getRoundedAverageRating } from '../../../utils/customerReviewsRating'
import ReviewCard from '../../molecules/ReviewCard'

type Props = {
  publicCustomerReviews: Array<CustomerReview>
  averageCustomerRating: ProductCollection['averageCustomerRating']
  countOfCustomerRatings: ProductCollection['countOfCustomerRatings']
}

const CustomerReviews = ({
  publicCustomerReviews,
  averageCustomerRating,
  countOfCustomerRatings
}: Props): JSX.Element | null => {
  const roundedAverageRating = getRoundedAverageRating(averageCustomerRating)

  if (countOfCustomerRatings <= 0 || publicCustomerReviews.length <= 0) {
    return null
  }

  return (
    <div id="customer-reviews" className={STYLES.container}>
      <div className={STYLES.content}>
        <Text
          element="h3"
          namespace="dashboard"
          text="extras.product_collection.customer_reviews.title"
          variant="display28"
          align="center"
          margin={false}
        />
        <div className={STYLES.rating}>
          <StarRating
            rating={roundedAverageRating}
            accentColour="brandBlue500"
            responsive={false}
          />
        </div>
        <Text
          element="p"
          namespace="dashboard"
          text="extras.product_collection.customer_reviews.description"
          variables={{
            averageRating: averageCustomerRating,
            customerReviewsAmount: countOfCustomerRatings,
            count: countOfCustomerRatings
          }}
          variant="textRegular16"
          align="center"
        />
        <div className={STYLES.reviews}>
          {publicCustomerReviews.map((publicCustomerReview) => (
            <ReviewCard
              key={publicCustomerReview.id}
              publicCustomerReview={publicCustomerReview}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default CustomerReviews
