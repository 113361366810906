// @noflow
import { useOccasion } from '@/context/festiveTheme/festiveTheme'
import { isNull } from 'lodash'
import React, { useMemo } from 'react'

import Breakpoints from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import Text from '@/components/elements/atoms/Text'

type Props = {
  isNextBox?: boolean
  isTrialBox?: boolean
  isNonCoreOrder?: boolean
  isOneOffBox?: boolean
}

const OrderLabel = ({
  isNextBox,
  isTrialBox,
  isNonCoreOrder,
  isOneOffBox
}: Props): JSX.Element | null => {
  const { xmas } = useOccasion()
  const { windowWidth } = useWindowSize()

  const labelText = useMemo(() => {
    switch (true) {
      case !isNextBox:
        return null
      case isTrialBox:
        return 'order_carousel.order_date.label.first'
      case isNonCoreOrder:
        return 'order_carousel.order_date.label.extras'
      case isOneOffBox:
        return 'order_carousel.order_date.label.oneOff'
      default:
        return 'order_carousel.order_date.label.next'
    }
  }, [isNextBox, isNonCoreOrder, isOneOffBox, isTrialBox])
  const isMobile = windowWidth < Breakpoints.md

  if (isNull(labelText)) {
    return null
  }
  return (
    <SectionWrapper
      margin={0}
      padding={isMobile ? 4 : 8}
      bgColour={xmas ? 'brandBlue400' : 'brandYellow300'}
    >
      <Text
        text={labelText}
        namespace="organisms"
        element="div"
        variant="textRegular14"
        shouldScale
        colour={xmas ? 'brandWhite' : 'brandYellow700'}
      />
    </SectionWrapper>
  )
}

export { OrderLabel }
