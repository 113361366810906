import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router'

import {
  CustomerIssueEntryInputsCreate_response_resolutionOffering_details_CustomerIssueManagementResolutionDetailsApplyDiscount as ApplyDiscountDetails,
  CustomerIssueEntryInputsCreate_response_resolutionOffering_details_CustomerIssueManagementResolutionDetailsReplaceBox as ReplaceBoxDetails,
  CustomerIssueEntryInputsCreate_response_resolutionOffering as ResolutionOffering
} from '../../../mutations/__generated__/CustomerIssueEntryInputsCreate'
import {
  AddressInput,
  CustomerIssueManagementResolutionResolutionType
} from '@/types'

import CustomerIssueManagementRoutes from '../../../types/routes'
import ApplyDiscount from './ApplyDiscount'
import Replacement from './Replacement'

type Props = {
  resolutionOffering: ResolutionOffering
  onAccept: (deliveryDate?: string, deliveryAddress?: AddressInput) => void
  contactUs: () => void
}

const ResolutionSwitch = ({
  resolutionOffering,
  onAccept,
  contactUs
}: Props): JSX.Element => {
  const [initialised, setInitialised] = useState(false)

  useEffect(() => {
    if (!initialised) {
      setInitialised(true)
    }
  }, [initialised])

  switch (resolutionOffering.resolutionType) {
    case CustomerIssueManagementResolutionResolutionType.replace_box:
      return (
        <Replacement
          details={resolutionOffering.details as ReplaceBoxDetails}
          onAccept={onAccept}
          contactUs={contactUs}
        />
      )
    case CustomerIssueManagementResolutionResolutionType.apply_discount:
      return (
        <ApplyDiscount
          details={resolutionOffering.details as ApplyDiscountDetails}
          onAccept={onAccept}
          contactUs={contactUs}
        />
      )
    default:
      return <Navigate to={CustomerIssueManagementRoutes.ContactCustomerLove} />
  }
}

export { Props }
export default ResolutionSwitch
