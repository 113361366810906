// @flow

import * as React from 'react'

type Props = {|
  action: () => void,
  buttonText: string,
  selected: boolean
|}

const SelectablePillButton = ({ action, buttonText, selected }: Props): React.Element<'button'> => (
  <button
    className={`pill-btn--selectable${selected ? ' pill-btn--selectable--selected' : ''}`}
    onClick={action}
    type='button'
  >
    <span>
      { buttonText }
    </span>
  </button>
)

export default SelectablePillButton
