import { gql } from '@apollo/client'

import { RECIPE_CARD_FLAVOUR_FRAGMENT } from '@/components/elements/molecules/RecipeCard/fragment/recipeCardFragment'

import { RECOMMENDED_EXTRAS_FRAGMENT } from './fragment/RecommendedExtrasFragment'

const RECIPES_SCREEN_QUERY = gql`
  ${RECIPE_CARD_FLAVOUR_FRAGMENT}
  ${RECOMMENDED_EXTRAS_FRAGMENT}
  query RecipesScreenQuery($planId: ID!) {
    guest {
      planRecommendation {
        idealGramsAmount
        individual {
          dog {
            id
            allergens {
              id
              name
              flavours {
                id
                name
              }
            }
            healthIssues {
              id
              flavours {
                id
                name
              }
              name
            }
          }
        }
        recommendedExtraProductVariants {
          ...recommendedExtrasFragment
        }
        combinedRecommendedFlavours {
          ...recipeCardFlavourFragment
        }
        combinedNotRecommendedFlavours {
          ...recipeCardFlavourFragment
        }
        combinedOtherFlavours {
          ...recipeCardFlavourFragment
        }
      }
    }
  }
`

export default RECIPES_SCREEN_QUERY
