// @noflow
import { useNotifications } from '@/context/notifications/notifications'
import { ProductUpsellContext } from '@/context/productUpsellModal/productUpsellModal'
import { ACCOUNT_ROUTES } from '@/routes'
import { useMutation, useQuery } from '@apollo/client'
import { Grid } from '@mui/material'
import * as Sentry from '@sentry/browser'
import camelCase from 'lodash/camelCase'
import times from 'lodash/times'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

import BRAND_COLOURS from '@/constants/BrandColours'
// Constants
import BREAKPOINTS from '@/constants/Breakpoints'
import { UPDATED_RECIPE_MIX } from '@/constants/RibbonEvents'
import SUPPORT_COLOURS from '@/constants/SupportColours'

// Hooks
import { useGoBack } from '@/hooks/useGoBack/useGoBack'
import useWindowSize from '@/hooks/useWindowSize'

// Components
import AlertCard from '@/components/elements/atoms/Alert/AlertCard'
import { Button } from '@/components/elements/atoms/Button'
import { CardSkeleton } from '@/components/elements/atoms/Card/CardSkeleton'
import Icon from '@/components/elements/atoms/Icon/Icon'
import ProgressBar from '@/components/elements/atoms/ProgressBar/ProgressBar'
import Text from '@/components/elements/atoms/Text/Text'
import ConfirmationModal, {
  modalData
} from '@/components/elements/organisms/ConfirmationModal/ConfirmationModal'
import { ProductUpsellAnalyticTypes } from '@/components/elements/organisms/ProductsUpsellModal/ProductsUpsellModal'
import { RecipeCard } from '@/components/pages/EditRecipes/components/RecipeCard/RecipeCard'

import STYLES from './EditRecipes.module.sass'

import { SAVE_FRESH_MEALS_MUTATION } from './mutations/saveFreshMealsMutation'
import { MEAL_FLAVOURS_QUERY } from './queries/mealFlavoursQuery'
import { SAVE_FRESH_MEALS_UPDATE_QUERY } from './queries/saveFreshMealsUpdateQuery'

import type {
  SaveFreshMealsMutation,
  SaveFreshMealsMutationVariables
} from './mutations/__generated__/SaveFreshMealsMutation'
import type {
  MealFlavoursQuery_user_subscription_meal as Meal,
  MealFlavoursQuery
} from './queries/__generated__/MealFlavoursQuery'
import { MealInput, RecipeSurchargeCalculation } from '@/types'

type MealInputKey = keyof MealInput

type EditFreshMealsContext = {
  mealQuantities: MealInput
  percentFilled: number
  increaseMealQuantity: (mealInputKey: MealInputKey, amount: number) => void
  decreaseMealQuantity: (mealInputKey: MealInputKey, amount: number) => void
  pouchSize: number
  pouchesPerServing: number
}

const EditFreshMealsContext = createContext<EditFreshMealsContext>(
  {} as EditFreshMealsContext
)

const EditRecipes = (): JSX.Element => {
  const namespace = 'account'
  const goBack = useGoBack()
  const { windowWidth } = useWindowSize()
  const isSmallScreen = windowWidth < BREAKPOINTS.sm
  const { setErrorNotification } = useNotifications()
  const [noChanges, setNoChanges] = useState(true)
  const { loading, data, error } = useQuery<MealFlavoursQuery>(
    MEAL_FLAVOURS_QUERY,
    {
      variables: {
        num: 1,
        calculationType: RecipeSurchargeCalculation.per_pouch
      }
    }
  )
  const userId = data?.user.id || ''
  const { openProductsUpsellModal } = useContext(ProductUpsellContext)

  const [saveFreshMeals, { loading: saveLoading, error: saveError }] =
    useMutation<SaveFreshMealsMutation, SaveFreshMealsMutationVariables>(
      SAVE_FRESH_MEALS_MUTATION,
      {
        onCompleted: () => {
          modalData({
            isOpen: true,
            text: 'edit_recipes.confirmation_modal',
            namespace,
            delay: 1000
          })
          setTimeout((): void => {
            const showUpsellModal =
              (data?.user.shippingCountry.showUpcomingOrderSeeMoreExtras &&
                data?.user.subscription.nextNBoxes[0]?.isNextEditableBox) ||
              false

            if (showUpsellModal) {
              openProductsUpsellModal({
                headerText: {
                  text: 'products_upsell_modal.recipes_changed_modal.title'
                },
                analyticsType: ProductUpsellAnalyticTypes.recipeChange
              })
            }
            goBack(ACCOUNT_ROUTES.base)
          }, 2000)
        },
        onError: () => {
          setErrorNotification({
            text: 'edit_recipes.notification.save_meals_failed',
            namespace
          })
          if (saveError) {
            Sentry.captureException(
              `Could not update meals from SAVE_FRESH_MEALS_MUTATION`,
              { extra: { error: saveError.message } }
            )
          }
        },
        refetchQueries: [
          {
            query: SAVE_FRESH_MEALS_UPDATE_QUERY
          }
        ]
      }
    )

  const [mealQuantities, setMealQuantities] = useState<MealInput>({})
  const [initialised, setInitialised] = useState(false)

  const pouchSize = data?.user.subscription.plan?.pouchSize ?? 0

  const numberOfPouches = data?.user.subscription.plan?.numberOfPouches ?? 0

  const pouchesPerServing =
    data?.user.subscription.plan?.servingInformation?.pouchesPerServing ?? 0

  const freshMeals = useMemo(() => {
    return data?.user.subscription.meal
  }, [data])

  const totalPouchesAdded = useMemo(() => {
    return Object.values(mealQuantities).reduce(
      (acc, quantity) => (acc += quantity),
      0
    )
  }, [mealQuantities])

  const percentFilled = useMemo(() => {
    return (totalPouchesAdded / numberOfPouches) * 100
  }, [totalPouchesAdded, numberOfPouches])

  const increaseMealQuantity = useCallback(
    (mealInputKey: MealInputKey, amount = 1): void => {
      if (totalPouchesAdded >= numberOfPouches) return

      if (noChanges) {
        setNoChanges(false)
      }

      setMealQuantities((prevMealQuantities) => ({
        ...prevMealQuantities,
        [mealInputKey]: (prevMealQuantities[mealInputKey] || 0) + amount
      }))
    },
    [noChanges, totalPouchesAdded, numberOfPouches]
  )

  const decreaseMealQuantity = useCallback(
    (mealInputKey: MealInputKey, amount = 1): void => {
      if (noChanges) {
        setNoChanges(false)
      }

      setMealQuantities((prevMealQuantities) => ({
        ...prevMealQuantities,
        [mealInputKey]: Math.max(
          0,
          (prevMealQuantities[mealInputKey] || 0) - amount
        )
      }))
    },
    [noChanges]
  )

  const extraOrMissingPouches = useMemo(() => {
    return totalPouchesAdded - numberOfPouches
  }, [totalPouchesAdded, numberOfPouches])

  const handleSave = useCallback(async (): Promise<void> => {
    window.ribbon && window.ribbon('trigger', UPDATED_RECIPE_MIX)
    if (extraOrMissingPouches === 0) {
      await saveFreshMeals({
        variables: {
          userId,
          meal: mealQuantities
        }
      })
    }
  }, [extraOrMissingPouches, mealQuantities, saveFreshMeals, userId])

  const value = useMemo(() => {
    const valueObj = {
      mealQuantities,
      percentFilled,
      increaseMealQuantity,
      decreaseMealQuantity,
      pouchSize,
      pouchesPerServing
    }
    return valueObj
  }, [
    mealQuantities,
    percentFilled,
    increaseMealQuantity,
    decreaseMealQuantity,
    pouchSize,
    pouchesPerServing
  ])

  useEffect(() => {
    if (
      !initialised &&
      data?.user.subscription.meal &&
      data.user.subscription.meal.length > 0 &&
      data?.user.subscription.plan
    ) {
      const mealQuantitiesFromData = data.user.subscription.meal.reduce(
        (acc, meal) => {
          const mealInputKey = camelCase(meal.slug) as MealInputKey
          acc[mealInputKey as MealInputKey] = meal.quantity
          return acc
        },
        {} as MealInput
      )
      setMealQuantities(mealQuantitiesFromData)
      setInitialised(true)
    }
  }, [data, initialised])

  if (loading) {
    return (
      <Grid
        container
        columnSpacing={isSmallScreen ? 0 : 4}
        className={STYLES.contentWrapper}
      >
        <Grid
          container
          item
          columnSpacing={{ xs: 0, sm: 3 }}
          rowSpacing={{ xs: 3, sm: 6 }}
        >
          {times(12).map(
            (index: number): JSX.Element => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <CardSkeleton height={isSmallScreen ? '29rem' : '50rem'} />
              </Grid>
            )
          )}
        </Grid>
      </Grid>
    )
  }
  if (error || !data || !freshMeals) {
    if (error) {
      Sentry.captureException(`Error in MEAL_FLAVOURS_QUERY`, {
        extra: { error: error.message }
      })
    }
    if (!data || !freshMeals) {
      Sentry.captureException(
        'Tried to render EditRecipes without data or freshMeals'
      )
    }
    // TODO: check if there is a holistic SPA approach for error state UI
    return (
      <div className={STYLES.alertContainer}>
        <AlertCard
          message={{
            text: 'edit_recipes.error_message',
            namespace
          }}
          variant="error"
        />
      </div>
    )
  }
  const boxIsFull = extraOrMissingPouches === 0
  const boxIsOver = extraOrMissingPouches > 0

  return (
    <EditFreshMealsContext.Provider value={value}>
      <Grid
        container
        columnSpacing={isSmallScreen ? 0 : 4}
        className={STYLES.contentWrapper}
      >
        <div className={STYLES.recipesWrapper}>
          <Grid
            container
            item
            columnSpacing={{ xs: 0, sm: 3 }}
            rowSpacing={{ xs: 3, sm: 6 }}
          >
            {freshMeals?.map((freshMeal: Meal) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={freshMeal.slug}>
                <RecipeCard freshMeal={freshMeal} />
              </Grid>
            ))}
          </Grid>
        </div>
        <div className={STYLES.stickyFooter}>
          <div className={STYLES.stickyFooterContent}>
            <Text
              text="edit_recipes.total_pouches"
              namespace={namespace}
              bold
              margin={false}
            />
            <div className={STYLES.progress}>
              <ProgressBar
                fillColour={
                  percentFilled === 100
                    ? SUPPORT_COLOURS.successGreen400
                    : BRAND_COLOURS.brandRed400
                }
                percentFilled={percentFilled}
              />
              <div className={STYLES.counter}>
                {boxIsFull && (
                  <Icon
                    accentColour="successGreen400"
                    asset="checkmark"
                    size={20}
                  />
                )}
                {boxIsOver && (
                  <Icon
                    accentColour="dangerRed300"
                    asset="errorCircle"
                    size={20}
                  />
                )}
                <Text
                  text={`${totalPouchesAdded}/${numberOfPouches}`}
                  translate={false}
                  margin={false}
                />
              </div>
            </div>
            <div className={STYLES.ctaWrapper}>
              <Button
                identifier="recipes_footer.save.button"
                onClick={handleSave}
                typography={{
                  namespace,
                  text: saveLoading
                    ? 'edit_recipes.saving'
                    : 'edit_recipes.save'
                }}
                variant="primary"
                disabled={noChanges || percentFilled !== 100 || saveLoading}
                fullWidth
              />
            </div>
          </div>
        </div>
      </Grid>
      <ConfirmationModal />
    </EditFreshMealsContext.Provider>
  )
}

export type { MealInputKey }
export { EditRecipes, EditFreshMealsContext }
