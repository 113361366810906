import React, { useCallback, useMemo } from 'react'

import { Button } from '@/components/elements/atoms/Button'
import Icon from '@/components/elements/atoms/Icon/Icon'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './Accordion.module.sass'

type AccordionProps = {
  children: JSX.Element | Array<JSX.Element> | null
  namespace?: string
  title: string
  onToggle?: (isOpen: boolean) => void
}

const Accordion = ({
  children,
  namespace,
  title,
  onToggle
}: AccordionProps): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(true)
  const toggleView = useCallback(() => {
    setIsOpen(!isOpen)
    onToggle && onToggle(!isOpen)
  }, [setIsOpen, onToggle, isOpen])

  const showHideText = useMemo(() => {
    if (isOpen) {
      return 'inputs.hide'
    } else {
      return 'inputs.show'
    }
  }, [isOpen])

  const displayIcon = useMemo(() => {
    if (isOpen) {
      return <Icon asset="minus" accentColour="brandBlue500" size={3} />
    } else {
      return <Icon asset="plus" accentColour="brandBlue500" size={14} />
    }
  }, [isOpen])

  return (
    <div className={STYLES.accordion}>
      <div className={STYLES.accordionHeader}>
        <Text
          namespace={namespace}
          variant={'display20'}
          text={title}
          colour={'brandBlue500'}
        />
        <Button
          typography={{
            namespace,
            text: showHideText
          }}
          icon={{
            component: displayIcon,
            position: 'left'
          }}
          variant={'ghost'}
          disabled={false}
          onClick={toggleView}
          displayText={'never'}
          disableAnalytics
        />
      </div>
      {isOpen && children}
    </div>
  )
}

export default Accordion
