// @noflow
import { useQuery } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import { isAfter, isBefore } from 'date-fns'
import type { i18n } from 'i18next'
import isNil from 'lodash/isNil'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import React, { useMemo } from 'react'

import withApollo from '@/components/apollo/withApollo'
import TopBanner, {
  Props as BannerProps,
  Variants,
  generateBannerLocalStorageKey,
  showBanner
} from '@/components/elements/atoms/TopBanner/TopBanner'
import {
  Banners,
  Banners_banners
} from '@/components/elements/molecules/BannerManager/__generated__/Banners'
import { BANNER_QUERY } from '@/components/elements/molecules/BannerManager/queries'

import STYLES from './BannerManager.module.sass'

type ManagedBanner = BannerProps & { order: number }
type AdminBanner = ManagedBanner & {
  order: number
  liveDate: Date
  endDate?: Date
}
type Props = {
  banners?: ManagedBanner[]
}
type BannerManagerWithQueryProps = Props & {
  i18n: i18n
}

const MAX_BANNERS = 1

const getDismissedBanners = (
  banners: ManagedBanner[]
): { [key: string]: number } => {
  const dismissedBanners: { [key: string]: number } = {}

  banners.forEach((banner: ManagedBanner) => {
    const bannerLastDismissed: string | null = localStorage.getItem(
      generateBannerLocalStorageKey(banner.id)
    )
    if (!isNull(bannerLastDismissed))
      dismissedBanners[banner.id] = parseInt(bannerLastDismissed)
  })

  return dismissedBanners
}

const filterDismissedBanners = (
  banners: ManagedBanner[],
  dismissedBanners: { [key: string]: number }
): ManagedBanner[] =>
  banners.filter((banner: ManagedBanner) => {
    const bannerLastDismissed: number | null =
      banner.id in dismissedBanners ? dismissedBanners[banner.id] : null
    if (isNull(bannerLastDismissed)) return true
    return showBanner(bannerLastDismissed, banner.showEveryXDays)
  })

const filterBannersOutsideOfDateRange = (
  adminBanners: AdminBanner[],
  today: Date
): AdminBanner[] =>
  adminBanners.filter(
    (banner: AdminBanner) =>
      isBefore(new Date(banner.liveDate), today) &&
      (isNil(banner.endDate) || isAfter(new Date(banner.endDate), today))
  )

const limitNumberOfBanners = (
  banners: ManagedBanner[],
  max: number
): ManagedBanner[] => banners.slice(0, max)

const orderBanners = (banners: ManagedBanner[]): ManagedBanner[] =>
  banners.sort(
    (bannerA: ManagedBanner, bannerB: ManagedBanner) =>
      bannerA.order - bannerB.order
  )

/**
 * Managers when and how TopBanner components are displayed at the top of the page
 * @param banners an array of banner props
 */
const BannerManager = ({ banners = [] }: Props): JSX.Element => {
  return (
    <section className={STYLES.container}>
      {limitNumberOfBanners(
        orderBanners(
          filterDismissedBanners(banners, getDismissedBanners(banners))
        ),
        MAX_BANNERS
      ).map((banner: BannerProps) => (
        <TopBanner
          id={banner.id}
          key={`${banner.id}-banner-key`}
          text={banner.text}
          variant={banner.variant}
          link={banner.link}
          showEveryXDays={banner.showEveryXDays}
          dismissible={banner.dismissible}
        />
      ))}
    </section>
  )
}

/**
 * converts the interface of banners received from admin to be consumed by the banner manager
 */
const transformAdminBanners = (
  adminBanners: Banners_banners[]
): AdminBanner[] =>
  adminBanners.map((adminBanner: Banners_banners) => {
    const {
      link,
      text,
      slug,
      template,
      order,
      liveDate,
      endDate,
      showAgainAfterXDays
    } = adminBanner

    return {
      id: slug,
      link: link || undefined,
      variant: template as Variants,
      text: {
        text: text,
        translate: false
      },
      order,
      template,
      liveDate,
      endDate,
      showAgainAfterXDays
    }
  })

/**
 * Fetches banners from the backend and merges them with any banners generated on the frontend
 * @param banners an array of frontend banner props
 * Note: i18n is passed manually to BannerManager so it can be used in bundles outside of our main bundle.
 *       Referencing i18n means our translation files will be included in the final bundle bloating its size.
 */
const BannerManagerWithQuery = ({
  banners = [],
  i18n
}: BannerManagerWithQueryProps): JSX.Element | null => {
  const { data, loading, error } = useQuery<Banners>(BANNER_QUERY)

  const bannersToDisplay = useMemo(() => {
    return banners.concat(
      filterBannersOutsideOfDateRange(
        transformAdminBanners(data?.banners ?? []),
        new Date()
      )
    )
  }, [banners, data?.banners])

  if (error) {
    Sentry.captureException(`Error when BANNER_QUERY executes`, {
      extra: {
        error
      }
    })
  }

  if (loading || error || isUndefined(data) || isUndefined(i18n)) return null

  return <BannerManager banners={bannersToDisplay} />
}

export {
  AdminBanner,
  ManagedBanner,
  filterDismissedBanners,
  limitNumberOfBanners,
  orderBanners,
  filterBannersOutsideOfDateRange
}
export default withApollo(BannerManagerWithQuery)
