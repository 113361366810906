// @noflow
import React from 'react'

import InfoIcon from 'assets/images/icons/info/info-notification--icon.svg'

import Text from '@/components/elements/atoms/Text'

import STYLES from './Badge.module.sass'

type Props = {
  variant: 'info' | 'discount' | null
}

const Badge = ({ variant }: Props): JSX.Element | null => {
  switch (variant) {
    case 'info': {
      return (
        <div className={`${STYLES.container} ${STYLES.info}`}>
          <img src={InfoIcon} alt="" role="presentation" />
        </div>
      )
    }
    case 'discount': {
      return (
        <div className={`${STYLES.container} ${STYLES.discount}`}>
          <Text
            text="%"
            translate={false}
            margin={false}
            colour="brandWhite"
            variant="display12"
            element="span"
            shouldScale={false}
          />
        </div>
      )
    }
    default: {
      return null
    }
  }
}

export { Badge }
