import Cookie from 'assets/images/icons/dog-personality-traits/cookie.svg'
import Glasses from 'assets/images/icons/dog-personality-traits/glasses.svg'
import Heart from 'assets/images/icons/dog-personality-traits/heart.svg'
import Leash from 'assets/images/icons/dog-personality-traits/leash.svg'
import Paws from 'assets/images/icons/dog-personality-traits/paws.svg'
import Rope from 'assets/images/icons/dog-personality-traits/rope.svg'
import Stick from 'assets/images/icons/dog-personality-traits/stick.svg'
import ToyBall from 'assets/images/icons/dog-personality-traits/toy-ball.svg'
import ToyBear from 'assets/images/icons/dog-personality-traits/toy-bear.svg'
import ToySqueaker from 'assets/images/icons/dog-personality-traits/toy-squeaker.svg'

const getPersonalityTraitsIcons = (trait: string): string => {
  switch (trait) {
    case 'goofball':
    case 'shy':
    case 'clever_clogs':
    case 'sweet': {
      return Heart
    }
    case 'just_hanging_out': {
      return Glasses
    }
    case 'ball_chaser': {
      return ToyBall
    }
    case 'stick_fetcher': {
      return Stick
    }
    case 'toy_squeaker': {
      return ToySqueaker
    }
    case 'tug_of_war_master': {
      return Rope
    }
    case 'walking': {
      return Leash
    }
    case 'cuddling': {
      return ToyBear
    }
    case 'making_friends': {
      return Paws
    }
    case 'doing_tricks': {
      return Cookie
    }
    default: {
      return Heart
    }
  }
}

export { getPersonalityTraitsIcons }
