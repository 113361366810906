import { NotificationContextProps } from '@/context/notifications/notifications'
import { SubscriptionStatus } from '@types'
import Cookies from 'js-cookie'
import isUndefined from 'lodash/isUndefined'

/**
 * The notification cookies to watch for
 */
enum NotificationCookies {
  reactivationDiscountRedemptionAttempted = 'reactivation_discount_redemption_attempted',
  couponRedemptionAttempted = 'coupon_redemption_attempted'
}

type NotificationCookie = Record<string, unknown>

type ReactivationDiscountNotification = {
  invalidity_reasons: {
    data: {
      subscription_status: SubscriptionStatus
    }
  }[]
  success: boolean
}

type CouponRedemptionNotification = {
  invalidity_reason: string | null
  success: boolean
}

const isReactivationDiscountNotification = (
  value: ReactivationDiscountNotification
) => Array.isArray(value.invalidity_reasons) && !isUndefined(value.success)

/**
 * Generate a notification around reactivation discounts
 * @param reactivationDiscountStatus
 * @param notificationHandlers
 */
const generateReactivationDiscountNotification = (
  reactivationDiscountStatus: ReactivationDiscountNotification,
  notificationHandlers: NotificationContextProps
) => {
  const { setErrorNotification, setSuccessNotification } = notificationHandlers
  const { invalidity_reasons: invalidityReasons, success } =
    reactivationDiscountStatus

  if (!success) {
    const [reason] = invalidityReasons

    if (!reason) return

    const {
      data: { subscription_status: subscriptionStatus }
    } = reason

    setErrorNotification({
      text:
        subscriptionStatus === 'paused'
          ? 'reactivation.reactivation_campaign_error.inactive'
          : 'reactivation.reactivation_campaign_error.active',
      namespace: 'dashboard'
    })

    Cookies.remove(NotificationCookies.reactivationDiscountRedemptionAttempted)

    return
  }

  setSuccessNotification({
    text: 'reactivation.reactivation_campaign_success',
    namespace: 'dashboard'
  })

  Cookies.remove(NotificationCookies.reactivationDiscountRedemptionAttempted)
}

const isCouponRedemptionNotification = (value: CouponRedemptionNotification) =>
  !isUndefined(value.success)

/**
 * Generate a notification around reactivation discounts
 * @param couponRedemptionStatus
 * @param notificationHandlers
 */
const generateCouponRedemptionNotification = (
  couponRedemptionStatus: CouponRedemptionNotification,
  notificationHandlers: NotificationContextProps
) => {
  const { setErrorNotification, setSuccessNotification } = notificationHandlers
  const { invalidity_reason: invalidityReason, success } =
    couponRedemptionStatus

  if (!success) {
    if (!invalidityReason) return

    setErrorNotification({
      // eslint-disable-next-line i18next/no-literal-string
      text: `coupons.coupon_redemption_error.${invalidityReason}`,
      namespace: 'dashboard'
    })

    Cookies.remove(NotificationCookies.couponRedemptionAttempted)

    return
  }

  setSuccessNotification({
    text: 'coupons.coupon_redemption_success',
    namespace: 'dashboard'
  })

  Cookies.remove(NotificationCookies.couponRedemptionAttempted)
}

/**
 * Fetch any cookies passed from the server used to trigger notifications
 * @param cookies
 */
const getNotificationCookies = (cookies: string[]): NotificationCookie => {
  const notificationCookies: NotificationCookie = {}

  for (const key of cookies) {
    const cookie = Cookies.get(key)

    if (!isUndefined(cookie))
      notificationCookies[key] = JSON.parse(decodeURIComponent(cookie))
  }

  return notificationCookies
}

const showNotificationFromServerCookie = (
  cookies: NotificationCookie,
  notificationHandlers: NotificationContextProps
) => {
  for (const key of Object.keys(cookies)) {
    switch (key) {
      case NotificationCookies.reactivationDiscountRedemptionAttempted:
        if (
          !isReactivationDiscountNotification(
            cookies[key] as ReactivationDiscountNotification
          )
        )
          break

        generateReactivationDiscountNotification(
          cookies[key] as ReactivationDiscountNotification,
          notificationHandlers
        )
        break

      case NotificationCookies.couponRedemptionAttempted:
        if (
          !isCouponRedemptionNotification(
            cookies[key] as CouponRedemptionNotification
          )
        )
          break

        generateCouponRedemptionNotification(
          cookies[key] as CouponRedemptionNotification,
          notificationHandlers
        )
        break
    }
  }
}

/**
 * Watch for certain cookies from the server and fire a toast notification if they exist.
 * Do not use this for client side notifications - use the useNotifications() hook directly.
 * @param notificationHandlers
 */
const serverCookieNotificationHandler = (
  notificationHandlers: NotificationContextProps
): void => {
  showNotificationFromServerCookie(
    getNotificationCookies(Object.values(NotificationCookies)),
    notificationHandlers
  )
}

export default serverCookieNotificationHandler
