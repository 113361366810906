// @noflow
import React from 'react'

import {
  Direction,
  ICON_COLOURS,
  IconColours
} from '@/components/elements/atoms/Icon/Icon'

import STYLES from './Chevron.module.sass'

type Props = {
  size: number
  accentColour: IconColours
  width?: number | string
  direction?: Direction
}

const Chevron = ({
  size,
  accentColour,
  width,
  direction = 'right'
}: Props): JSX.Element => {
  return (
    <svg
      height={size}
      viewBox="0 0 25 30"
      width={width || '100%'}
      className={`${direction ? STYLES[direction] : ''}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="m1.58201 29.625h-.620324c-.682355-1.6129-.341177-2.8845 1.147594-3.784 4.83852-2.9465 9.95622-5.4898 13.89522-9.739.8374-.8995.9925-1.5509.031-2.2642-2.2642-1.6749-4.5593-3.3188-6.91657-4.86956-1.86097-1.20963-3.81499-2.26417-5.769-3.31872-1.39573-.74439-1.73691-1.95402-1.67487-3.38076.09305-1.923001.93048-2.388244 2.54332-1.364712 2.48129 1.581822 5.02461 3.070592 7.44382 4.714452 3.9081 2.66739 7.9092 5.2417 11.6311 8.1572 2.2021 1.7369 1.5508 3.722-1.0546 4.7455-1.7989.7134-3.6599 1.4888-5.2417 2.6054-4.8075 3.4428-10.01819 6.1412-15.41499 8.4984z"
        fill={ICON_COLOURS[accentColour]}
        fillRule="evenodd"
      />
    </svg>
  )
}

export type { Direction }
export default Chevron
