// @flow

// eslint-disable-next-line
// $FlowFixMe
class Formatter {
  static capitalizeFirstLetter (word: string): string {
    return word[0].toUpperCase() + word.slice(1)
  }

  static possessiveName (name: string): string {
    // eslint-disable-next-line quotes
    const suffix = name.endsWith('s') ? '\'' : '\'s'
    return `${name}${suffix}`
  }

  static removeWhiteSpace(str: string): string {
    // eslint-disable-next-line
    return str.replace(RegExp(' ', 'g'), '')
  }
}

export default Formatter
