// @noflow
import type { Stripe } from '@stripe/stripe-js'
import i18next from 'i18next'

import { bannerMessageState } from '../../../components/Banner/Banner'
import segmentTrack from '@/components/analytics/Analytics'

import { PaymentMethodType, PaymentMethodable, Provider } from '@/types'

import { State } from '../../../PayOnOwnDevicePage'
import formatSubscriptionData from '../../../helpers/buiildSubscriptionData'
import { ActivePaymentViews } from '../../../screens/PaymentScreen/PaymentScreen'
import stripeMutationMethods from '../sharedStripeMutationMethods'

const chargeBoxOneBancontact = async ({
  paymentPageState,
  stripe,
  fullName,
  email,
  amount,
  setActivePaymentView
}: {
  paymentPageState: State
  stripe: Stripe
  fullName: string
  email: string
  amount: number
  setActivePaymentView: (view: ActivePaymentViews) => void
}): Promise<void> => {
  const { stripeCustomerCreate, stripeSetupIntentCreate, raiseBannerError } =
    stripeMutationMethods()
  try {
    return await stripeCustomerCreate({
      email: email,
      amount: amount,
      paymentMethodType: 'bancontact'
    })
      .then((res) => {
        if (res?.data?.directSalesStripeCustomerCreate) {
          const clientSecret =
            res?.data.directSalesStripeCustomerCreate?.paymentIntentId
          const paymentIntentId = clientSecret.split('_secret')[0]

          if (clientSecret && paymentIntentId) {
            // If first box has a value we can continue with payment intent
            const user =
              paymentPageState.data.directSalesPendingSubscription
                .pendingSubscription.user

            const formattedSubData = formatSubscriptionData({
              targetFirstDeliveryDate: paymentPageState.targetFirstDeliveryDate,
              subscriptionData: paymentPageState.data,
              provider: Provider.stripe,
              paymentMethodType: PaymentMethodable.SepaDirectDebit,
              paymentIntentId: paymentIntentId,
              supportedMarketingMethodPurposes:
                paymentPageState.marketingPreferences
            })

            stripeSetupIntentCreate({
              authenticationType: 'bancontact',
              formattedSubscriptionData: formattedSubData,
              paymentIntentId: paymentIntentId,
              firstName: user.firstName,
              lastName: user?.lastName || '',
              paymentMethodType: PaymentMethodType.sepa_direct_debit
            })

            stripe.confirmBancontactPayment(clientSecret, {
              payment_method: {
                billing_details: {
                  name: fullName,
                  email: email
                }
              },
              return_url: window.location.href
            })

            segmentTrack('bancontact redirection initialised')
            setActivePaymentView('bancontact')
          }
        }
      })
      .catch((error) => {
        window.localStorage.setItem('paymentMethod', 'none')
        raiseBannerError(error)
      })
  } catch {
    window.localStorage.setItem('paymentMethod', 'none')
    bannerMessageState({
      message: i18next.t('checkout:errors.delivery_details_fetch')
    })
  }
}

export default chargeBoxOneBancontact
