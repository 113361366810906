import { gql } from '@apollo/client'

const SHOP_ITEM_FRAGMENT = gql`
  fragment ShopItemFragment on ProductVariant {
    id
    name
    productCollection {
      id
      name
      slug
    }
    recurringDeliveryType {
      netPrice
    }
    oneOffDeliveryType {
      netPrice
    }
  }
`

export { SHOP_ITEM_FRAGMENT }
