// @noflow
import { useReactiveVar } from '@apollo/client'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useNavigationType } from 'react-router'

import useWebviewHandler from '@/hooks/useWebviewHandler'

import EmailIcon from 'assets/images/icons/envelope--blue.svg'
import PhoneIcon from 'assets/images/icons/phone--white.svg'
import SpeechBubbleIcon from 'assets/images/icons/speech-bubble-circle.svg'

import { Button } from '@/components/elements/atoms/Button'
import Link from '@/components/elements/atoms/Link/Link'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './ContactCustomerLove.module.sass'

import { customerIssueManagementState } from '../../CustomerIssueManagementPage'
// Tracking
import useCustomerIssueManagementTracking from '../../analytics'

const ContactCustomerLoveDetails = (): JSX.Element => {
  const customerIssueManagementData = useReactiveVar(
    customerIssueManagementState
  )
  const { t } = useTranslation(customerIssueManagementData.namespace)
  const callHref = 'tel:' + t('contact_customer_love.call.number')
  const emailHref = 'mailto:' + t('contact_customer_love.email.address')
  const customerIssueManagementTracking = useCustomerIssueManagementTracking()
  const webviewHandler = useWebviewHandler()
  const navigate = useNavigate()
  const navigationType = useNavigationType()
  const [initialised, setInitialised] = useState(false)

  const handleReturnToStart = useCallback(() => {
    if (webviewHandler.isWebview) {
      webviewHandler.postMessage('GO-BACK')
    } else {
      navigate(
        customerIssueManagementData.initialHistoryLength - window.history.length
      )
    }
  }, [
    customerIssueManagementData.initialHistoryLength,
    navigate,
    webviewHandler
  ])

  useEffect(() => {
    if (!initialised) {
      document.body.classList.remove('hide-dixa')
      customerIssueManagementState({
        ...customerIssueManagementData,
        clDetailsLoaded: true
      })
      if (navigationType === 'POP') {
        handleReturnToStart()
      }
      setInitialised(true)
    }
  }, [
    initialised,
    customerIssueManagementData,
    navigationType,
    handleReturnToStart
  ])

  const trackContactMethod = useCallback(
    (method: string) => {
      customerIssueManagementTracking.trackEvent('contacted CL', { method })
    },
    [customerIssueManagementTracking]
  )

  const trackCall = useCallback(() => {
    trackContactMethod('Call')
  }, [trackContactMethod])

  const trackEmail = useCallback(() => {
    trackContactMethod('Email')
  }, [trackContactMethod])

  const disableChat = useCallback(() => {
    const dixaWidget = document.getElementsByClassName(
      'dixa-messenger-wrapper'
    )[0] as HTMLElement
    return !window._dixa_ || !dixaWidget
  }, [])

  const openChat = useCallback(() => {
    trackContactMethod('online chat')
    window._dixa_.invoke('setWidgetOpen', true)
  }, [trackContactMethod])

  const chatNowText = useCallback(() => {
    return disableChat()
      ? 'contact_customer_love.liveChat.chatClosed'
      : 'contact_customer_love.liveChat.chatNow'
  }, [disableChat])

  const renderHelpText = useCallback(() => {
    if (customerIssueManagementData.reportId !== '') {
      return (
        <>
          <Text
            namespace={customerIssueManagementData.namespace}
            text={'contact_customer_love.text1'}
            align="left"
            variant={'textRegular16'}
            colour={'brandBlue500'}
          />
          <Text
            namespace={customerIssueManagementData.namespace}
            text={'contact_customer_love.text2WithRef'}
            align="left"
            variant={'textRegular16'}
            colour={'brandBlue500'}
          />
          <div className={STYLES.reference}>
            <Text
              namespace={customerIssueManagementData.namespace}
              text={'contact_customer_love.referenceNumberLabel'}
              align="left"
              variant={'textRegular16'}
              colour={'brandBlue500'}
            />
            <Text
              text={`#${customerIssueManagementData.reportId}`}
              align="left"
              variant={'display16'}
              colour={'brandBlue500'}
              translate={false}
            />
          </div>
        </>
      )
    } else {
      return (
        <>
          <Text
            namespace={customerIssueManagementData.namespace}
            text={'contact_customer_love.text1'}
            align="left"
            variant={'textRegular16'}
            colour={'brandBlue500'}
          />
          <Text
            namespace={customerIssueManagementData.namespace}
            text={'contact_customer_love.text2'}
            align="left"
            variant={'textRegular16'}
            colour={'brandBlue500'}
          />
        </>
      )
    }
  }, [customerIssueManagementData])

  return (
    <div className={STYLES.contactCustomerLove}>
      <div className={STYLES.intro}>{renderHelpText()}</div>
      <div className={STYLES.section}>
        <div className={STYLES.title}>
          <img
            alt=""
            loading="lazy"
            className={`${STYLES.icon} ${STYLES.liveChat}`}
            src={SpeechBubbleIcon}
          />
          <Text
            namespace={customerIssueManagementData.namespace}
            text={'contact_customer_love.liveChat.title'}
            align="left"
            variant={'display16'}
            element={'h4'}
            colour={'brandBlue500'}
          />
        </div>
        <Text
          namespace={customerIssueManagementData.namespace}
          text={'contact_customer_love.liveChat.monFri'}
          align="left"
          variant={'textRegular16'}
          colour={'brandBlue500'}
        />
        <Text
          namespace={customerIssueManagementData.namespace}
          text={['contact_customer_love.liveChat.satSun', '']}
          align="left"
          variant={'textRegular16'}
          colour={'brandBlue500'}
        />
        <Button
          typography={{
            namespace: customerIssueManagementData.namespace,
            text: chatNowText()
          }}
          disableAnalytics
          onClick={openChat}
          disabled={disableChat()}
          variant={'secondary'}
        />
      </div>
      <div className={STYLES.section}>
        <div className={STYLES.title}>
          <img
            alt=""
            loading="lazy"
            className={`${STYLES.icon} ${STYLES.call}`}
            src={PhoneIcon}
          />
          <Text
            namespace={customerIssueManagementData.namespace}
            text={'contact_customer_love.call.title'}
            align="left"
            variant={'display16'}
            element={'h4'}
            colour={'brandBlue500'}
          />
        </div>
        <p className={STYLES.link}>
          <Link
            namespace={customerIssueManagementData.namespace}
            suffix={null}
            text={'contact_customer_love.call.number'}
            href={callHref}
            onClick={trackCall}
            identifier="contact_customer_love.call_number"
          />
        </p>
        <Text
          namespace={customerIssueManagementData.namespace}
          text={'contact_customer_love.call.monFri'}
          align="left"
          variant={'textRegular16'}
          colour={'brandBlue500'}
        />
        <Text
          namespace={customerIssueManagementData.namespace}
          text={'contact_customer_love.call.satSun'}
          align="left"
          variant={'textRegular16'}
          colour={'brandBlue500'}
        />
        <Text
          namespace={customerIssueManagementData.namespace}
          text={'contact_customer_love.call.bankHols'}
          align="left"
          variant={'textRegular16'}
          colour={'brandBlue500'}
        />
      </div>
      <div className={STYLES.section}>
        <div className={STYLES.title}>
          <img
            alt=""
            loading="lazy"
            className={`${STYLES.icon} ${STYLES.email}`}
            src={EmailIcon}
          />
          <Text
            namespace={customerIssueManagementData.namespace}
            text={'contact_customer_love.email.title'}
            align="left"
            variant={'display16'}
            element={'h4'}
            colour={'brandBlue500'}
          />
        </div>
        <p className={STYLES.link}>
          <Link
            namespace={customerIssueManagementData.namespace}
            suffix={null}
            href={emailHref}
            text={'contact_customer_love.email.address'}
            onClick={trackEmail}
            identifier="contact_customer_love.email_address"
          />
        </p>
      </div>
    </div>
  )
}

export default ContactCustomerLoveDetails
