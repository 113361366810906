import React, { useCallback } from 'react'

import DownloadAppSection from '@/components/elements/organisms/DownloadAppSection/DownloadAppSection'

import STYLES from './AppOverlay.module.sass'

import { Code } from '@/types'

type Props = {
  shippingCode: Code
  closeAppOverlay: () => void
}

const AppOverlay = ({ shippingCode, closeAppOverlay }: Props): JSX.Element => {
  const handleAccountClick = useCallback(() => {
    closeAppOverlay()
    window.location.href = '/account'
  }, [closeAppOverlay])

  return (
    <div className={STYLES.appOverlayWrapper} data-testid="app-overlay">
      <DownloadAppSection
        shippingCode={shippingCode}
        handleOnClick={handleAccountClick}
        pageContext="thank_you_page"
      />
    </div>
  )
}

export { Props }
export default AppOverlay
