// @noflow
import { useReactiveVar } from '@apollo/client'
import React, { FormEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'
import Icon from '@/components/elements/atoms/Icon/Icon'
import Text from '@/components/elements/atoms/Text/Text'
import StickyNavigation from '@/components/elements/organisms/StickyNavigation/StickyNavigation'
import { routeToNextStep } from '@/components/pages/SignupWizardPage/helpers/wizardRoutes'
import type { Route } from '@/components/pages/SignupWizardPage/types/routes'

import STYLES from './Name.module.sass'

import WarningIcon from '../../../../../../assets/images/icons/warning-icon.svg'
import { wizardPageState } from '../../SignupWizardPage'
import useImplicitFormSubmission from '../../helpers/useImplicitFormSubmission'
import {
  Dog,
  WizardActivityLevel,
  WizardBodyCondition,
  WizardSnackingHabits
} from '../../types/types'

type Props = {
  variant?: keyof typeof STYLES
  namespace: string
}

type NameFieldProps = {
  namespace: string
  dog: Dog
  canDelete: boolean
  index: number
  onDelete: () => void
  onChange: (e: FormEvent<HTMLInputElement>) => void
}

type ModalProps = {
  namespace: string
  dogName: string
  onRemoveClick: () => void
  onCancelClick: () => void
}

const Modal = ({
  namespace,
  dogName,
  onRemoveClick,
  onCancelClick
}: ModalProps): JSX.Element => {
  return (
    <div className={STYLES.modalWrapper}>
      <div className={STYLES.modalContent}>
        <button
          type="button"
          className={STYLES.buttonClose}
          onClick={onCancelClick}
        >
          <Icon asset="close" size={12} />
        </button>

        <div className={STYLES.upper}>
          <Text
            element="p"
            variant="display28"
            namespace={namespace}
            text="name_step.remove_modal.title"
            variables={{ dogName }}
          />
          <div className={STYLES.uppermid}>
            <Text
              element="p"
              variant="textRegular16"
              namespace={namespace}
              text="name_step.remove_modal.text"
            />
            <img src={WarningIcon} alt="" />
          </div>
        </div>
        <div className={STYLES.lower}>
          <Button
            typography={{
              namespace,
              text: 'name_step.remove_modal.button_text_remove'
            }}
            onClick={onRemoveClick}
            disableAnalytics
          />
          <Button
            typography={{
              namespace,
              text: 'name_step.remove_modal.button_text_cancel'
            }}
            variant="ghost"
            onClick={onCancelClick}
            disableAnalytics
          />
        </div>
      </div>
    </div>
  )
}

const NameField = ({
  namespace,
  dog,
  canDelete,
  index,
  onDelete,
  onChange
}: NameFieldProps): JSX.Element => {
  const { t } = useTranslation(namespace)
  const copy_context = 'name_step'

  return (
    <div className={STYLES.question}>
      <Card>
        <div className={STYLES.inputLabelContainer}>
          <Text
            variant="textRegular16"
            element="p"
            namespace={namespace}
            text="name_step.field_label"
            colour="brandBlue500"
          />
          {canDelete && (
            <button
              type="button"
              className={STYLES.closeIcon}
              onClick={onDelete}
            >
              <Icon asset="close" size={12} />
            </button>
          )}
        </div>
        <input
          id={`dogNameInput-${index}`}
          data-testid="name-input"
          type="text"
          placeholder={t(`${copy_context}.your_dogs_name`)}
          onChange={onChange}
          value={dog.name}
        />
      </Card>
    </div>
  )
}

const Name = ({ variant, namespace }: Props): JSX.Element => {
  const route = useLocation().pathname as Route

  const [modalOpen, setModalOpen] = useState(false)
  const [currentDog, setCurrentDog] = useState(0)
  const wizardState = useReactiveVar(wizardPageState)
  const { t } = useTranslation(namespace)
  const copy_context = 'name_step'
  const { dogs } = wizardState

  const formIsValid = dogs.every((dog: Dog) => dog.name.length > 0)

  useEffect(() => {
    localStorage.setItem('wizardState', JSON.stringify(wizardState))
  }, [wizardState])

  const navigate = useNavigate()

  const onEnterPress = useCallback(() => {
    navigate(routeToNextStep({ route }))
  }, [navigate, route])

  useImplicitFormSubmission({ formIsValid, onEnterPress })

  const addDog = useCallback(() => {
    const newDog: Dog = {
      name: '',
      gender: null,
      neutered: null,
      ageStage: null,
      age: null,
      isRescue: null,
      broughtHome: null,
      breed: null,
      foodCategoryIds: [],
      eaterType: null,
      weight: null,
      isWorkingDog: null,
      activityLevel: WizardActivityLevel.FairlyActive,
      bodyCondition: WizardBodyCondition.JustRight,
      allergies: [],
      healthIssues: [],
      snackingHabits: WizardSnackingHabits.SomeSnacks
    }

    dogs.push(newDog)

    wizardPageState({
      ...wizardState,
      dogs
    })
  }, [wizardState, dogs])

  const removeDog = useCallback(
    (index: number) => {
      setModalOpen(false)
      setCurrentDog(0)
      dogs.splice(index, 1)
      wizardPageState({
        ...wizardState,
        dogs
      })
    },
    [wizardState, dogs]
  )

  const checkRemoveDog = useCallback(
    (index: number) => {
      const justAdded = Object.keys(dogs[index]).length <= 1
      if (justAdded) {
        removeDog(index)
      } else {
        setCurrentDog(index)
        setModalOpen(true)
      }
    },
    [dogs, removeDog]
  )

  const updateDog = useCallback(
    (index: number, name: string) => {
      dogs[index].name = name
      wizardPageState({
        ...wizardState,
        dogs
      })
    },
    [wizardState, dogs]
  )

  const closeModal = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  return (
    <>
      <div className={variant ? STYLES[variant] : ''}>
        {modalOpen && (
          <Modal
            namespace={namespace}
            dogName={dogs[currentDog].name}
            // eslint-disable-next-line react/jsx-no-bind
            onRemoveClick={() => {
              removeDog(currentDog)
            }}
            onCancelClick={closeModal}
          />
        )}
        <div className={STYLES.inner}>
          {wizardState.dogs &&
            wizardState.dogs.map((dog: Dog, index: number) => (
              <NameField
                key={index.toString()}
                namespace={namespace}
                dog={dog}
                canDelete={dogs.length > 1}
                index={index}
                // eslint-disable-next-line react/jsx-no-bind
                onDelete={() => {
                  checkRemoveDog(index)
                }}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={(e: FormEvent<HTMLInputElement>) => {
                  updateDog(index, e.currentTarget.value)
                }}
              />
            ))}
          <button type="button" className={STYLES.addButton} onClick={addDog}>
            {t(`${copy_context}.another_dog`)}
          </button>
        </div>
      </div>
      <StickyNavigation
        buttonOne={{
          dataTestId: 'next-button',
          url: routeToNextStep({ route }),
          text: 'wizard_flow:sticky_navigation.start',
          variant: 'primary',
          iconColour: 'brandWhite'
        }}
        disabled={!formIsValid}
      />
    </>
  )
}

export { Props }
export default Name
export { NameField, Modal }
