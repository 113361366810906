// @noflow
import Cookies from 'js-cookie'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import BlueArrow from 'assets/images/icons/arrows/arrow-blue.svg'

import segmentTrack from '@/components/analytics/Analytics'
import { Routes } from '@/components/pages/SimplifiedPlansPage/types/routes'

import type {
  Code as CountryCode,
  ShippingCountry
} from '@/shared_types/rails_models/shipping_countries'

import {
  getCountryCode,
  getCountryFlag,
  getCountryName
} from './localisationHelpers'

const baseClass = 'geo-ip-widget'
const countryCodeCookiesKey = 'user_country_code'

const triggerGeoIpWidgetAnalytics = ({
  originalLocation,
  newLocation,
  widgetLocation
}: {
  originalLocation: CountryCode
  newLocation: CountryCode
  widgetLocation: string
}): void => {
  const eventName = 'Location Widget Toggled'
  const properties = {
    original_location: originalLocation,
    new_location: newLocation,
    widget_location: widgetLocation
  }
  segmentTrack(eventName, properties)
}

const setCountryCode = ({
  newCountryCode,
  currentCountryCode,
  widgetLocation,
  onCountryChange
}: {
  newCountryCode: CountryCode
  currentCountryCode: CountryCode
  widgetLocation: string
  onCountryChange?: () => void
}) => {
  if (newCountryCode !== currentCountryCode) {
    triggerGeoIpWidgetAnalytics({
      originalLocation: currentCountryCode,
      newLocation: newCountryCode,
      widgetLocation
    })
    const cookieOptions =
      window.location.hostname === 'localhost'
        ? undefined
        : { domain: window.location.hostname }
    Cookies.set(countryCodeCookiesKey, newCountryCode, cookieOptions)
    window.location.reload()
    if (onCountryChange) {
      onCountryChange()
    }
    // We found that on Checkout if the user changes country from Germany to
    // another Butternut country or vice versa, they can't proceed as the meal
    // selections are different for Germany (PsiBufet users are unaffected as
    // the above logic already means they are redirected to the correct domain).
    // This change will redirect the user from Checkout back to Plans Flow if
    // this happens.
    if (
      (widgetLocation === 'checkout' &&
        currentCountryCode !== 'DE' &&
        newCountryCode === 'DE') ||
      (currentCountryCode === 'DE' && newCountryCode !== 'DE')
    ) {
      location.replace(Routes.Recipes)
    }
  } else return null
}

const CountryOption = ({
  countryCode,
  currentCountryCode,
  countryOptionsAreVisible,
  setCountryOptionsAreVisible,
  activeShippingCountries,
  widgetLocation,
  onCountryChange
}: {
  countryCode: CountryCode
  currentCountryCode: CountryCode
  countryOptionsAreVisible: boolean
  setCountryOptionsAreVisible: React.Dispatch<React.SetStateAction<boolean>>
  activeShippingCountries: Array<ShippingCountry>
  widgetLocation: string
  onCountryChange?: () => void
}): React.ReactElement<'div'> => {
  const { t } = useTranslation('shipping_countries')
  const setCountryLocation = React.useCallback(() => {
    setCountryCode({
      newCountryCode: countryCode,
      currentCountryCode,
      widgetLocation,
      onCountryChange
    })
    setCountryOptionsAreVisible(!countryOptionsAreVisible)
  }, [
    countryCode,
    currentCountryCode,
    countryOptionsAreVisible,
    setCountryOptionsAreVisible,
    widgetLocation,
    onCountryChange
  ])

  const handleKeyDown = React.useCallback(
    (e) => {
      if (e.key !== 'Tab') {
        setCountryLocation()
      }
    },
    [setCountryLocation]
  )

  return (
    <div
      id={countryCode}
      className={`${baseClass}__country-option`}
      onClick={setCountryLocation}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
    >
      <img
        src={getCountryFlag(countryCode) || ''}
        className={`${baseClass}__country-flag`}
        alt={`The flag of ${getCountryName({
          activeShippingCountries,
          countryCode,
          t
        })}`}
        height="25"
        width="43"
      />
      <span className={`${baseClass}__country-name text-regular-18`}>
        {getCountryName({ activeShippingCountries, countryCode, t }) || ''}
      </span>
    </div>
  )
}

const GeoIpWidget = ({
  widgetLocation,
  activeShippingCountries,
  onCountryChange
}: {
  widgetLocation: string
  activeShippingCountries: Array<ShippingCountry>
  onCountryChange?: () => void
}): React.ReactElement<'div'> => {
  const { t } = useTranslation('shipping_countries')
  const [countryOptionsAreVisible, setCountryOptionsAreVisible] =
    React.useState(false)

  const currentCountryCode = getCountryCode()

  const singleActiveShippingCountry = activeShippingCountries.length === 1

  const toggleCountryOptionsAreVisible = React.useCallback(() => {
    if (singleActiveShippingCountry) {
      return null
    } else {
      setCountryOptionsAreVisible(!countryOptionsAreVisible)
    }
  }, [countryOptionsAreVisible, singleActiveShippingCountry])

  const countryOptions = activeShippingCountries.filter(
    (country: ShippingCountry) =>
      country.code !== currentCountryCode && country.active
  )

  // This enables us to detect when a user has clicked on an area aside from
  // the Geo Ip Widget and closes the widget if it's open
  React.useEffect(() => {
    const bodyElement = document.getElementsByTagName('body')[0] as HTMLElement
    bodyElement.addEventListener('click', (e) => {
      const targetElement = e.target as Element
      if (
        countryOptionsAreVisible &&
        targetElement.className !== 'geo-ip-widget__selected-country' &&
        targetElement.className !== 'geo-ip-widget__country-option'
      ) {
        setCountryOptionsAreVisible(false)
      }
    })
  }, [countryOptionsAreVisible])

  return (
    <div className={baseClass}>
      <div
        className={`${baseClass}__selected-country${
          singleActiveShippingCountry
            ? ` ${baseClass}__selected-country--single-country`
            : ''
        }`}
        onClick={toggleCountryOptionsAreVisible}
        onKeyDown={toggleCountryOptionsAreVisible}
        role="button"
        tabIndex={0}
      >
        <img
          src={getCountryFlag(currentCountryCode) || ''}
          className={`${baseClass}__country-flag`}
          alt={`The flag of ${getCountryName({
            activeShippingCountries,
            countryCode: currentCountryCode,
            t
          })}`}
          height="25"
          width="43"
        />
        <span
          className={`${baseClass}__selected-country__name text-regular-18`}
        >
          {getCountryName({
            activeShippingCountries,
            countryCode: currentCountryCode,
            t
          }) || ''}
        </span>
        <div
          className={`${baseClass}__selected-country__arrow-wrapper ${
            singleActiveShippingCountry
              ? `${baseClass}__selected-country__arrow-wrapper--single-country`
              : ''
          }`}
        >
          <img
            src={BlueArrow}
            className={`${baseClass}__selected-country__arrow${
              countryOptionsAreVisible ? '--up' : '--down'
            }`}
            alt={`A blue arrow pointing ${
              countryOptionsAreVisible ? 'up' : 'down'
            }`}
            height="16"
            width="19"
          />
        </div>
      </div>
      {countryOptionsAreVisible && (
        <div className={`${baseClass}__country-options`}>
          {countryOptions.map(
            ({ code }: ShippingCountry): React.ReactElement => (
              <CountryOption
                key={code}
                countryCode={code}
                currentCountryCode={currentCountryCode}
                countryOptionsAreVisible={countryOptionsAreVisible}
                setCountryOptionsAreVisible={setCountryOptionsAreVisible}
                activeShippingCountries={activeShippingCountries}
                widgetLocation={widgetLocation}
                onCountryChange={onCountryChange}
              />
            )
          )}
        </div>
      )}
    </div>
  )
}

export default GeoIpWidget
