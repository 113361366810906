// @noflow
import Cookies from 'js-cookie'
import * as React from 'react'

import STYLES from './Image.module.sass'

type GravityType =
  | 'auto'
  | 'north_west'
  | 'north'
  | 'north_east'
  | 'west'
  | 'center'
  | 'east'
  | 'south_west'
  | 'south'
  | 'south_east'
  | 'custom'
  | 'xy_center'

type ResizeModeType =
  | 'resize_to_fill'
  | 'resize_to_fit'
  | 'resize_to_limit'
  | 'resize_and_pad'
  | 'crop'

type ImageParams = {
  width: number
  height: number
  resizeMode?: ResizeModeType
  quality?: string
  format?: string
  gravity?: GravityType
  aspectRatio?: string
  x?: number
  y?: number
  rotate?: number
  monochrome?: boolean
  backgroundColour?: string
  retina?: boolean
}

type Props = {
  slug: string
  image: ImageParams
  alt: string
  className?: string
  lazyLoad?: boolean
}

const assetSource = (slug: string): string => {
  const locale = Cookies.get('user_language') || 'en'
  const src = `/asset_management/assets/${slug}/${locale}` // eslint-disable-line i18next/no-literal-string

  return src
}

const urlBuilder = (imageParams: ImageParams, slug: string): string => {
  let src = assetSource(slug) + '?'

  // multiply width and height by 2 for retina screens
  const width = imageParams.retina ? imageParams.width * 2 : imageParams.width
  const height = imageParams.retina
    ? imageParams.height * 2
    : imageParams.height

  if (imageParams.resizeMode === 'crop') {
    src += `&${imageParams.resizeMode}=${imageParams.x},${imageParams.y},${width},${height}`
  } else if (imageParams.resizeMode) {
    src += `&${imageParams.resizeMode}=${width},${height}`
  }

  Object.keys(imageParams).forEach((key: string) => {
    if (!['resizeMode', 'width', 'height', 'x', 'y', 'crop'].includes(key)) {
      const formattedKey = key === 'format' ? 'convert' : key
      // eslint-disable-next-line i18next/no-literal-string
      src += `&${formattedKey}=${imageParams[key as keyof ImageParams]}`
    }
  })

  return src
}

/**
 * An image element that uses the butternut asset management system and React package to dynamically
 * transform our uploaded images
 * The system allows us to set many transformations including height, width,
 * mode (scale, crop, etc), and quality (by default we set quality to auto).
 * All props other than slug are optional.
 *
 * Example usage
 * <Image
 *   alt='An image of butternut box'
 *   slug='top-logo'
 *   image={{ width: 200, height: 200, resizeMode: 'resize_to_fit'}}
 * />
 */

const Image = ({
  slug,
  alt,
  image,
  className,
  lazyLoad = false
}: Props): JSX.Element => {
  const src = urlBuilder(image, slug)

  const { width, height } = image

  return (
    <img
      alt={alt}
      className={`${STYLES.image} ${lazyLoad ? 'lazy' : ''} ${className || ''}`}
      width={width}
      height={height}
      src={src}
      data-src={lazyLoad ? src : undefined}
    />
  )
}

export type { GravityType, Props }

export default Image

export { assetSource }
