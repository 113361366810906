// @noflow
import React from 'react'

import {
  ICON_COLOURS,
  IconColours
} from '@/components/elements/atoms/Icon/Icon'

type Props = {
  size: number
  accentColour: IconColours
  width?: number | string
}

const DecorativeCheckbox = ({
  size,
  accentColour,
  width
}: Props): JSX.Element => {
  return (
    <svg
      fill="none"
      height={size}
      viewBox="0 0 26 25"
      width={width || '100%'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke={ICON_COLOURS[accentColour]}>
        <path
          d="m1 3h21v21h-21z"
          fill="#ffe180"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="m7 13.4656c.63186.4007.93722.5605 1.5 1.0344.35651.3002 1.13849 1.1839 1.5 1.4797.4613.3774.7373.5961 1.2615.8737.1858.0984.1302.1048.3073-.0169.1812-.1247.3745-.2984.5298-.4538.1463-.1462.3218-.2074.4284-.403.0798-.1462.199-.3553.3101-.4848.3786-.4418.6653-.9687 1.1133-1.367.3704-.3293.6237-.7554.8878-1.1725.6511-1.0281 1.633-1.8447 2.2971-2.8776.13-.20233.3533-.38666.5157-.56937.3119-.3509.5937-.73618.8963-1.09639.6198-.73782 1.143-1.45554 1.6432-2.27733.34-.55864.7216-1.07295 1.1048-1.59808.3622-.49629.8829-.79432 1.3304-1.21758.488-.46162 1.0262-.73355 1.319-1.31905"
          strokeLinecap="round"
          strokeWidth="2.4"
        />
      </g>
    </svg>
  )
}

export default DecorativeCheckbox
