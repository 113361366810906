// @noflow
import type { Stripe, StripeIdealBankElement } from '@stripe/stripe-js'
import i18next from 'i18next'

import segmentTrack from '@/components/analytics/Analytics'
import client from '@/components/apollo/client'

import { STRIPE_CHARGE_BOX_ONE_MUTATION } from '../mutations/stripeChargeBoxOneAndSetupPaymentMethod'
import { STRIPE_USER_PARAMS_SETUP_INTENT_CREATE_MUTATION } from '../mutations/stripeUserParamsSetupIntentCreate'

import type { StripeChargeBoxOneAndSetupPaymentMethod } from '../mutations/__generated__/StripeChargeBoxOneAndSetupPaymentMethod'
import type { StripeUserParamsSetupIntentCreate } from '../mutations/__generated__/StripeUserParamsSetupIntentCreate'

import { checkoutPageState } from '../../../CheckoutPage'
import { bannerMessageState } from '../Banner'
import { handleSubmitSubscription } from './buySubscriptionWithCard'
import { formatSubscriptionDataAddressForGraphql } from './formatSubscriptionDataAddressForGraphql'
import requestFromSCAController from './requestFromSCAController'
import { subscriptionData } from './submitSubscription'
import type { SubscriptionData } from './submitSubscription'

const getSCAAuthorisation = ({
  data,
  csrfToken,
  stripe,
  name,
  idealBank
}: {
  data: SubscriptionData
  csrfToken: string
  stripe: Stripe
  name: string
  idealBank: StripeIdealBankElement
}) => {
  requestFromSCAController({
    email: encodeURIComponent(data.user.email),
    csrfToken,
    data
  }).then((res) => {
    stripe
      .confirmIdealSetup(res.card_authentication_setup.client_secret, {
        payment_method: {
          ideal: idealBank,
          billing_details: {
            name: name,
            email: data.user.email
          }
        },
        return_url: window.location.href
      })
      .then((result) => {
        segmentTrack('iDeal redirection initialised')

        if (result.error?.message) {
          bannerMessageState({
            message:
              result.error.message ||
              i18next.t('checkout:errors.delivery_details_fetch'),
            type: 'error'
          })
        }
      })
  })
}

const chargeBoxOneIdeal = async ({
  data,
  stripe,
  name,
  csrfToken,
  idealBank
}: {
  data: SubscriptionData
  stripe: Stripe
  name: string
  csrfToken: string
  idealBank: StripeIdealBankElement
}) => {
  try {
    const boxOneMutationRes =
      await client.mutate<StripeChargeBoxOneAndSetupPaymentMethod>({
        mutation: STRIPE_CHARGE_BOX_ONE_MUTATION,
        variables: {
          paymentMethodType: 'ideal'
        }
      })

    if (boxOneMutationRes?.data?.stripeChargeBoxOneAndSetupPaymentMethod) {
      const {
        stripeSecretToken: clientSecret,
        paymentIntentId,
        boxCanBeCharged
      } = boxOneMutationRes.data.stripeChargeBoxOneAndSetupPaymentMethod

      if (boxCanBeCharged && clientSecret && paymentIntentId) {
        // If first box has a value we can continue with payment intent
        const formattedSubData = formatSubscriptionDataAddressForGraphql(data)

        await client.mutate<StripeUserParamsSetupIntentCreate>({
          mutation: STRIPE_USER_PARAMS_SETUP_INTENT_CREATE_MUTATION,
          variables: {
            authenticationType: 'iDeal',
            subscriptionData: formattedSubData,
            paymentIntentId: paymentIntentId
          }
        })

        const result = await stripe.confirmIdealPayment(clientSecret, {
          payment_method: {
            ideal: idealBank,
            billing_details: {
              name: name,
              email: data.user.email
            }
          },
          return_url: window.location.href
        })
        segmentTrack('iDeal redirection initialised')

        if (result.error?.message) {
          bannerMessageState({
            message:
              result.error.message ||
              i18next.t('checkout:errors.delivery_details_fetch'),
            type: 'error'
          })
        }
      } else {
        // If the first boxes value is 0 we cannot charge and so must create via a setup intent as before.
        getSCAAuthorisation({ data, csrfToken, stripe, name, idealBank })
      }
    } else {
      bannerMessageState({
        message: i18next.t('checkout:errors.delivery_details_fetch'),
        type: 'error'
      })
    }
  } catch {
    bannerMessageState({
      message: i18next.t('checkout:errors.delivery_details_fetch'),
      type: 'error'
    })
  }
}

const getIdealPaymentAuthorisation = ({
  stripe,
  name,
  idealBank,
  csrfToken
}: {
  stripe: Stripe
  name: string
  idealBank: StripeIdealBankElement
  csrfToken: string
}): void => {
  const checkoutData = checkoutPageState()
  const requiresPayment = checkoutData.user.requiresPaymentDetailsOnCheckout
  const data = subscriptionData({ state: checkoutData })

  if (!requiresPayment) {
    handleSubmitSubscription({
      data,
      csrfToken,
      paymentMethodId: '',
      paymentMethodType: 'iDeal'
    })
  } else {
    chargeBoxOneIdeal({ data, csrfToken, stripe, name, idealBank })
  }
}

export default getIdealPaymentAuthorisation
