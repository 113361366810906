// @noflow
import initI18n, { Language } from '@/packs/localisation'
import i18next from 'i18next'
import React from 'react'

import NavLogo from '@/components/elements/atoms/NavLogo/NavLogo'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './ConfirmedPaused.module.sass'

type Props = {
  variant: Language
}

const ConfirmedPaused = ({ variant }: Props): JSX.Element => {
  if (!i18next.isInitialized) {
    initI18n(variant)
  }

  return (
    <div className={STYLES.container}>
      <div className={STYLES.logo}>
        <NavLogo size="large" />
      </div>
      <Text
        namespace="dashboard"
        text={'confirmed_paused.title'}
        margin={false}
        variant="display16"
        align="center"
      />
    </div>
  )
}
export default ConfirmedPaused
