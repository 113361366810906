import { gql } from '@apollo/client'

const BOX_SUMMARY_QUERY = gql`
  query BoxSummary {
    user {
      id
      shippingCountryCode
      preferredLanguage
      dogs {
        id
        name
        planPortionInGrams
        gender
      }
      subscription {
        id
        recurringOrder {
          deliveryFee
          surchargeTotal
          physicalProductsTotal
          totalPrice
          planTotal
          planTotalPerDay
        }
        plan {
          numberOfPouches
          pouchSize
          pouchesPerDay
          price
          durationInDays
          typeOfPlanForCustomer
          pricesPerDayPerDogs {
            name
            pricePerDayWithoutDiscount
          }
        }
      }
    }
  }
`

export { BOX_SUMMARY_QUERY }
