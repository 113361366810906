import { useReactiveVar } from '@apollo/client'
import React, { useCallback, useMemo, useState } from 'react'

import { Button } from '@/components/elements/atoms/Button'
import Label from '@/components/elements/atoms/Label/Label'
import Modal from '@/components/elements/atoms/Modal/Modal'
import Text from '@/components/elements/atoms/Text/Text'
import Calendar from '@/components/elements/molecules/Calendar'

import STYLES from '../Resolution.module.sass'

import { CustomerIssueEntryInputsCreate_response_resolutionOffering_details_CustomerIssueManagementResolutionDetailsReplaceBox_availableDeliveryDates as AvailableDeliveryDate } from '../../../mutations/__generated__/CustomerIssueEntryInputsCreate'

import { customerIssueManagementState } from '../../../CustomerIssueManagementPage'

type Props = {
  firstDeliverableDate: Date
  availableDeliveryDates: Array<AvailableDeliveryDate>
  selectedDate: Date
  setSelectedDate: (newDate: Date) => void
}

const ChangeDateButton = ({
  firstDeliverableDate,
  availableDeliveryDates,
  selectedDate,
  setSelectedDate
}: Props): JSX.Element => {
  const customerIssueManagementData = useReactiveVar(
    customerIssueManagementState
  )
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const [temporaryDate, setTemporaryDate] = useState(selectedDate)

  const openCalendar = useCallback(() => {
    setTemporaryDate(selectedDate)
    setIsCalendarOpen(true)
  }, [setIsCalendarOpen, selectedDate])

  const saveDate = useCallback(() => {
    if (selectedDate !== temporaryDate) {
      setSelectedDate(temporaryDate)
    }
    setIsCalendarOpen(false)
  }, [setIsCalendarOpen, setSelectedDate, selectedDate, temporaryDate])

  const isEarliestDate = useMemo(() => {
    return selectedDate.toDateString() === firstDeliverableDate.toDateString()
  }, [selectedDate, firstDeliverableDate])

  return (
    <>
      <div className={STYLES.changeBox}>
        <div className={'details'}>
          <div className={STYLES.labelHeader}>
            <Text
              variant={'textRegular14'}
              colour={'brandBlue400'}
              namespace={customerIssueManagementData.namespace}
              text={'resolutionOffering.replacementBox.getNewBox'}
              margin={false}
            />
            {isEarliestDate && (
              // eslint-disable-next-line jsx-a11y/label-has-for
              <Label
                variant={'earliestDate'}
                delivery
                text={{
                  variant: 'textRegular14',
                  namespace: customerIssueManagementData.namespace,
                  text: 'resolutionOffering.replacementBox.earliest_delivery',
                  margin: false
                }}
              />
            )}
          </div>
          <Text
            variant={'textRegular16'}
            colour={'brandBlue500'}
            namespace={customerIssueManagementData.namespace}
            text={'resolutionOffering.replacementBox.deliveryDate'}
            variables={{ date: selectedDate }}
            margin={false}
          />
        </div>
        <Button
          variant={'secondary'}
          onClick={openCalendar}
          typography={{
            namespace: customerIssueManagementData.namespace,
            text: 'resolutionOffering.replacementBox.change'
          }}
          disableAnalytics
        />
      </div>
      <Modal
        isModalOpen={isCalendarOpen}
        setOpenModal={setIsCalendarOpen}
        width={800}
      >
        <Calendar
          userId={customerIssueManagementData.reporterable.id}
          deliveryDate={selectedDate}
          selectedDate={temporaryDate}
          shippingCountryCode={customerIssueManagementData.shippingCountryCode}
          setSelectedDate={setTemporaryDate}
          availableDeliveryDates={availableDeliveryDates}
        />
        <Button
          typography={{
            namespace: customerIssueManagementData.namespace,
            text: 'resolutionOffering.replacementBox.save'
          }}
          onClick={saveDate}
          disableAnalytics
        />
      </Modal>
    </>
  )
}

export { Props }
export default ChangeDateButton
