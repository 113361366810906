import { useMutation, useReactiveVar } from '@apollo/client'
import { useCallback, useEffect, useState } from 'react'

// Mutations
import { CUSTOMER_ISSUE_RESOLUTION_OFFERING_ACCEPT } from '../mutations/CustomerIssueResolutionOfferingAccept'
import { CUSTOMER_ISSUE_RESOLUTION_OFFERING_REJECT } from '../mutations/CustomerIssueResolutionOfferingReject'

import {
  CustomerIssueResolutionOfferingAccept,
  CustomerIssueResolutionOfferingAcceptVariables,
  CustomerIssueResolutionOfferingAccept_response_applicationDetails,
  CustomerIssueResolutionOfferingAccept_response as Response
} from '../mutations/__generated__/CustomerIssueResolutionOfferingAccept'
import { CustomerIssueResolutionOfferingReject } from '../mutations/__generated__/CustomerIssueResolutionOfferingReject'
import {
  AddressInput,
  CustomerIssueManagementResolutionOfferingAcceptanceDetailsInput,
  CustomerIssueManagementResolutionResolutionType
} from '@/types'

import {
  customerIssueResolutionOfferingState,
  errorState,
  pageLoadingState,
  routeState
} from '../CustomerIssueManagementPage'
import CustomerIssueManagementRoutes from '../types/routes'

type CustomerIssueResolutionOfferingMutationsHook = {
  accept: (
    resolutionOfferingId: string,
    deliveryDate?: string,
    deliveryAddress?: AddressInput
  ) => void
  acceptNoResponse: (
    resolutionOfferingId: string,
    resolutionType: CustomerIssueManagementResolutionResolutionType
  ) => void
  reject: (resolutionOfferingId: string) => void
}

const useCustomerIssueResolutionOfferingMutations =
  (): CustomerIssueResolutionOfferingMutationsHook => {
    const [dataRetrieved, setDataRetrieved] = useState<Response | null>(null)
    const customerIssueResolutionOfferingData = useReactiveVar(
      customerIssueResolutionOfferingState
    )
    const [
      customerIssueResolutionOfferingAccept,
      {
        loading: customerIssueResolutionOfferingAcceptLoading,
        data: customerIssueResolutionOfferingAcceptData,
        error: customerIssueResolutionOfferingAcceptError
      }
    ] = useMutation<CustomerIssueResolutionOfferingAccept>(
      CUSTOMER_ISSUE_RESOLUTION_OFFERING_ACCEPT
    )
    const accept = useCallback(
      (
        resolutionOfferingId: string,
        deliveryDate?: string,
        deliveryAddress?: AddressInput
      ) => {
        if (resolutionOfferingId) {
          const acceptanceDetails: CustomerIssueManagementResolutionOfferingAcceptanceDetailsInput =
            {
              id: resolutionOfferingId
            }
          if (deliveryDate && deliveryAddress) {
            acceptanceDetails.replaceBox = {
              deliveryDate,
              deliveryAddress
            }
          }
          const variables: CustomerIssueResolutionOfferingAcceptVariables = {
            acceptanceDetails
          }
          customerIssueResolutionOfferingAccept({ variables })
        } else {
          routeState(CustomerIssueManagementRoutes.ContactCustomerLove)
        }
      },
      [customerIssueResolutionOfferingAccept]
    )

    const [customerIssueResolutionOfferingReject] =
      useMutation<CustomerIssueResolutionOfferingReject>(
        CUSTOMER_ISSUE_RESOLUTION_OFFERING_REJECT
      )
    const reject = useCallback(
      (resolutionOfferingId: string) => {
        customerIssueResolutionOfferingReject({
          variables: { rejectionDetails: { id: resolutionOfferingId } }
        })
      },
      [customerIssueResolutionOfferingReject]
    )

    const [acceptNoResponseMutation] =
      useMutation<CustomerIssueResolutionOfferingAccept>(
        CUSTOMER_ISSUE_RESOLUTION_OFFERING_ACCEPT
      )
    const acceptNoResponse = useCallback(
      (
        resolutionOfferingId: string,
        resolutionType: CustomerIssueManagementResolutionResolutionType
      ) => {
        acceptNoResponseMutation({
          variables: {
            acceptanceDetails: {
              id: resolutionOfferingId
            }
          }
        })
        customerIssueResolutionOfferingState({
          ...customerIssueResolutionOfferingData,
          applicationDetails: {
            ...(customerIssueResolutionOfferingData.applicationDetails as CustomerIssueResolutionOfferingAccept_response_applicationDetails),
            type: resolutionType
          }
        })
        routeState(CustomerIssueManagementRoutes.ResolutionConfirmation)
      },
      [acceptNoResponseMutation, customerIssueResolutionOfferingData]
    )

    useEffect(() => {
      pageLoadingState(customerIssueResolutionOfferingAcceptLoading)
    }, [customerIssueResolutionOfferingAcceptLoading])

    useEffect(() => {
      if (customerIssueResolutionOfferingAcceptError) {
        errorState(!!customerIssueResolutionOfferingAcceptError)
        routeState(CustomerIssueManagementRoutes.Error)
      }
    }, [customerIssueResolutionOfferingAcceptError])

    useEffect(() => {
      if (
        customerIssueResolutionOfferingAcceptData &&
        dataRetrieved !== customerIssueResolutionOfferingAcceptData.response
      ) {
        setDataRetrieved(customerIssueResolutionOfferingAcceptData.response)
        customerIssueResolutionOfferingState({
          ...customerIssueResolutionOfferingData,
          ...customerIssueResolutionOfferingAcceptData.response
        })
        routeState(CustomerIssueManagementRoutes.ResolutionConfirmation)
      }
    }, [
      customerIssueResolutionOfferingAcceptData,
      dataRetrieved,
      customerIssueResolutionOfferingData
    ])

    return {
      accept,
      acceptNoResponse,
      reject
    }
  }
export { CustomerIssueResolutionOfferingMutationsHook }
export default useCustomerIssueResolutionOfferingMutations
