import React, { useCallback, useState } from 'react'

import { Expand } from '@/components/elements/atoms/Animated/Animated'
import FlatButton from '@/components/elements/atoms/FlatButton/FlatButton'
import Separator from '@/components/elements/atoms/Separator/Separator'
import Text from '@/components/elements/atoms/Text'
import DogAvatar from '@/components/elements/molecules/DogAvatar/DogAvatar'

import STYLES from './DogGramsPerDay.module.sass'

import {
  SubscriptionDetailsCardQuery_user_dogs as Dog,
  SubscriptionDetailsCardQuery_user_subscription_plan_gramsPerDayPerDogs as GramsPerDayPerDog
} from '../../queries/__generated__/SubscriptionDetailsCardQuery'

type Variant = 'smallAvatar' | 'largeAvatar'

type DogGramsPerDayProps = {
  dogs: Array<Dog>
  gramsPerDayPerDogs: Array<GramsPerDayPerDog>
  showSeparator?: boolean
  namespace: string
  copyContext: string
  variant: Variant
  screenIdentifier?: string
  expand?: boolean
}

type DogGramsPerDayListItem = {
  dogId: string
  dogName: string
  breed: string
  avatarUrl: string
  ageInMonths: number
  gramsPerDay: number
  showSeparator?: boolean
  variant: Variant
  copyContext: string
  namespace: string
}

const DogGramsPerDayListItem = ({
  dogId,
  dogName,
  breed,
  avatarUrl,
  ageInMonths,
  gramsPerDay,
  showSeparator = false,
  variant,
  copyContext,
  namespace
}: DogGramsPerDayListItem): React.ReactElement => {
  return (
    <div className={STYLES.dog}>
      {variant === 'largeAvatar' && (
        <div className={STYLES.avatar}>
          <DogAvatar
            key={dogId}
            avatarUrl={avatarUrl}
            breed={breed}
            ageInMonths={ageInMonths}
            sprinkles={false}
          />
        </div>
      )}
      <Text
        text={`${copyContext}.dog_gets`}
        namespace={namespace}
        variables={{ dogName }}
        variant="display18"
        margin={false}
      />
      <div className={STYLES.gramsPerDay}>
        {variant === 'smallAvatar' && (
          <div className={STYLES.avatar}>
            <DogAvatar
              key={dogId}
              avatarUrl={avatarUrl}
              breed={breed}
              ageInMonths={ageInMonths}
              sprinkles={false}
            />
          </div>
        )}
        <div className={STYLES.gramsPerDayText}>
          <Text
            text={`${copyContext}.amount`}
            namespace={namespace}
            variant="textRegular20"
            margin={false}
            variables={{
              gramsPerDay
            }}
          />
          <Text
            text={`${copyContext}.per_day`}
            namespace={namespace}
            margin={false}
          />
        </div>
      </div>
      {showSeparator && (
        <div className={STYLES.separator}>
          <Separator handdrawn />
        </div>
      )}
    </div>
  )
}

const DogGramsPerDayList = ({
  dogs,
  gramsPerDayPerDogs,
  showSeparator = false,
  namespace,
  copyContext,
  variant
}: Omit<
  DogGramsPerDayProps,
  'screenIdentifier' | 'expand'
>): React.ReactElement => {
  // Combine the dog data with the grams per day data
  const combinedDogs = gramsPerDayPerDogs.map((gpd) => {
    const dog = dogs?.find((d) => d.id === gpd.dogId)
    return {
      ...gpd,
      ...dog
    }
  })

  return (
    <div className={`${STYLES.amountBreakdown} ${STYLES[variant]}`}>
      {combinedDogs.map((dog) => (
        <DogGramsPerDayListItem
          key={dog.dogId}
          dogId={dog.dogId}
          dogName={dog.name}
          breed={dog.breed?.name || ''}
          avatarUrl={dog.dogProfile?.avatarUrl || ''}
          ageInMonths={dog.ageInMonths || 0}
          gramsPerDay={dog.gramsPerDay}
          showSeparator={showSeparator && combinedDogs.length > 1}
          variant={variant}
          copyContext={copyContext}
          namespace={namespace}
        />
      ))}
    </div>
  )
}

const DogGramsPerDayExpand = ({
  dogs,
  gramsPerDayPerDogs,
  namespace,
  copyContext,
  variant,
  screenIdentifier
}: Omit<
  DogGramsPerDayProps,
  'showSeparator' | 'expand'
>): React.ReactElement => {
  const [expanded, setExpanded] = useState(false)
  // Combine the dog data with the grams per day data
  const combinedDogs = gramsPerDayPerDogs.map((gpd) => {
    const dog = dogs?.find((d) => d.id === gpd.dogId)
    return {
      ...gpd,
      ...dog
    }
  })

  const firstDog = combinedDogs[0]
  const remainingDogs = combinedDogs.slice(1)

  const handleExpand = useCallback(() => {
    setExpanded(!expanded)
  }, [expanded])

  return (
    <div className={STYLES.expandList}>
      <div className={`${STYLES.amountBreakdown} ${STYLES[variant]}`}>
        <DogGramsPerDayListItem
          key={firstDog.dogId}
          dogId={firstDog.dogId}
          dogName={firstDog.name}
          breed={firstDog.breed?.name || ''}
          avatarUrl={firstDog.dogProfile?.avatarUrl || ''}
          ageInMonths={firstDog.ageInMonths || 0}
          gramsPerDay={firstDog.gramsPerDay}
          showSeparator={false}
          variant={variant}
          copyContext={copyContext}
          namespace={namespace}
        />
        <Expand show={expanded}>
          {remainingDogs.map((dog) => (
            <DogGramsPerDayListItem
              key={dog.dogId}
              dogId={dog.dogId}
              dogName={dog.name}
              breed={dog.breed?.name || ''}
              avatarUrl={dog.dogProfile?.avatarUrl || ''}
              ageInMonths={dog.ageInMonths || 0}
              gramsPerDay={dog.gramsPerDay}
              showSeparator={false}
              variant={variant}
              copyContext={copyContext}
              namespace={namespace}
            />
          ))}
        </Expand>
      </div>
      <div className={STYLES.expandButton}>
        <FlatButton
          text={{
            text: expanded
              ? `${copyContext}.show_less`
              : `${copyContext}.show_more`,
            namespace: 'account'
          }}
          variant="yellow200"
          onClick={handleExpand}
          identifier="dog_grams_per_day_expand"
          screenIdentifier={screenIdentifier}
        />
      </div>
    </div>
  )
}

const DogGramsPerDay = ({
  dogs,
  gramsPerDayPerDogs,
  showSeparator = false,
  namespace,
  copyContext,
  variant,
  expand = false
}: Omit<DogGramsPerDayProps, 'screenIdentifier'>): React.ReactElement => {
  return (
    <div className={`${STYLES.amountBreakdown} ${STYLES[variant]}`}>
      {expand && dogs.length > 1 ? (
        <DogGramsPerDayExpand
          copyContext={copyContext}
          namespace={namespace}
          dogs={dogs}
          gramsPerDayPerDogs={gramsPerDayPerDogs}
          variant={variant}
          screenIdentifier="dog_grams_per_day_expand"
        />
      ) : (
        <DogGramsPerDayList
          copyContext={copyContext}
          namespace={namespace}
          dogs={dogs}
          gramsPerDayPerDogs={gramsPerDayPerDogs}
          variant={variant}
          showSeparator={showSeparator}
        />
      )}
    </div>
  )
}

export default DogGramsPerDay
