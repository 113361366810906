// @noflow
import React from 'react'

import SkeletonButton from '@/components/elements/atoms/SkeletonButton/SkeletonButton'

const PaymentMethodSkeleton = (): JSX.Element => {
  return <SkeletonButton width={'100%'} height={'9.2rem'} />
}

export default PaymentMethodSkeleton
