// @flow

import * as React from 'react'

type HeaderImage =
  | React.Element<'picture'>
  | React.Element<'img'>

type Props = {|
  headerSubtitle?: string | Array<string> | React.Node,
  children: ?React.Node,
  extraPaddingBottom?: number,
  headerImage?: HeaderImage,
  isLeftAligned?: boolean
|}

const Header = ({
  headerSubtitle,
  children,
  extraPaddingBottom,
  headerImage,
  isLeftAligned = false
}: Props): React.Element<'header'> => (
  <header className={`step-header ${headerImage ? 'step-header--reduced-margin' : ''}`}>
    <div
      className={`step-header__content ${isLeftAligned ? 'step-header__content--left-aligned' : ''}`}
      style={{
        paddingBottom: extraPaddingBottom ? `${extraPaddingBottom}rem` : null
      }}
    >
      <h1 className='step-header__content__title'>
        { children }
      </h1>
      <div className='step-header__content__subtitle'>
        { headerSubtitle }
      </div>
    </div>
    { headerImage }
  </header>
)

export type { Props }

export default Header
