// @flow

import * as React from 'react'

/**
 * Docs for the HTMLSelectElement:
 * github.com/facebook/flow/blob/422f13bee662472f9d44742edf1cee68b7caee15/lib/dom.js#L3362
 */
type Option = {|
  value: string,
  text: string
|}

type Props = {|
  className: $PropertyType<HTMLSelectElement, 'className'>,
  disabled: $PropertyType<HTMLSelectElement, 'disabled'>,
  onBlur: (SyntheticEvent<HTMLSelectElement>) => void,
  options: Array<Option>,
  defaultValue: $PropertyType<HTMLSelectElement, 'value'>,
  value: $PropertyType<HTMLSelectElement, 'value'>
|}

const Select = ({
  className,
  disabled,
  onBlur,
  options,
  defaultValue,
  value
}: Props): React.Element<'select'> => (
  <select
    className={`${className} ${disabled ? 'disabled' : ''}`}
    disabled={disabled}
    onBlur={onBlur}
    onChange={onBlur}
    readOnly
    value={value}
  >
    <option
      disabled
      key={defaultValue}
      value={defaultValue}
    >
      { defaultValue }
    </option>
    {
      options.map((opt: Option): React.Element<'option'> => (
        <option
          key={opt.value}
          value={opt.value}
        >
          { opt.text }
        </option>
      ))
    }
  </select>
)

export default Select
