// @noflow

/* eslint-disable jsx-a11y/label-has-for */
import type { Language } from '@/packs/localisation'
import { useQuery } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import React from 'react'
import { toast } from 'react-toastify'

import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { formatCurrencyWithDecimal } from '@/utils/currency'

import Icon from '@/components/elements/atoms/Icon/Icon'
import Label from '@/components/elements/atoms/Label/Label'
import Text from '@/components/elements/atoms/Text/Text'
import LabelList from '@/components/elements/molecules/LabelList/LabelList'
import NotificationContent from '@/components/elements/molecules/NotificationContent/NotificationContent'
import { TOP_NAV_DISCOUNT_QUERY } from '@/components/elements/molecules/TopNavDiscount/queries/topNavDiscountQuery'

import STYLES from './TopNavDiscount.module.sass'

import type { TopNavDiscountQuery } from './queries/__generated__/TopNavDiscountQuery'
import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'

type Props = {
  discountCodeId: string
  shippingCountryCode: CountryCode
  preferredLanguage: Language
}

const TopNavDiscount = ({
  discountCodeId,
  shippingCountryCode,
  preferredLanguage
}: Props): JSX.Element | null => {
  const namespace = 'shared'

  const { loading, data, error } = useQuery<TopNavDiscountQuery>(
    TOP_NAV_DISCOUNT_QUERY,
    { variables: { discountCodeId } }
  )

  const { locale, currency } = countryCodeToLocaleCurrency(
    shippingCountryCode,
    preferredLanguage
  )

  React.useEffect(() => {
    if (error) {
      toast.error(
        <NotificationContent
          copy={{
            text: 'top_nav_banner.error_message',
            namespace: namespace
          }}
        />,
        {
          icon: (
            <Icon size={20} asset="errorCircle" accentColour="dangerRed300" />
          )
        }
      )
      Sentry.captureException(error.message)
    }
  }, [loading, error, namespace])

  if (!data || loading) {
    return null
  }

  const { discountCodeParts } = data.discountCode

  const isDoubleDiscount =
    discountCodeParts[0].discountType === 'n_boxes' &&
    discountCodeParts[0].n === 2

  return (
    <div className={STYLES.topNavDiscount}>
      <LabelList alignment="right">
        <Label
          variant="discount"
          size="small"
          text={{
            namespace: namespace,
            text: `top_nav_banner.discount_${discountCodeParts[0].discountBasis}`,
            variables: {
              discountValue:
                discountCodeParts[0].discountBasis === 'percentage'
                  ? discountCodeParts[0].value
                  : formatCurrencyWithDecimal(discountCodeParts[0].value, {
                      locale,
                      currency
                    })
            }
          }}
        />
        <>
          {discountCodeParts[1] && (
            <Label
              variant="secondBoxDiscount"
              size="small"
              text={{
                namespace: namespace,
                text: `top_nav_banner.discount_${discountCodeParts[1].discountBasis}`,
                variables: {
                  discountValue:
                    discountCodeParts[1].discountBasis === 'percentage'
                      ? discountCodeParts[1].value
                      : formatCurrencyWithDecimal(discountCodeParts[1].value, {
                          locale,
                          currency
                        })
                }
              }}
            />
          )}
          {discountCodeParts[2] && (
            <Label
              variant="secondBoxDiscount"
              size="small"
              text={{
                namespace: namespace,
                text: `top_nav_banner.discount_${discountCodeParts[2].discountBasis}`,
                variables: {
                  discountValue:
                    discountCodeParts[1].discountBasis === 'percentage'
                      ? discountCodeParts[1].value
                      : formatCurrencyWithDecimal(discountCodeParts[1].value, {
                          locale,
                          currency
                        })
                }
              }}
            />
          )}
          {isDoubleDiscount && (
            <Label
              variant="secondBoxDiscount"
              size="small"
              text={{
                namespace: namespace,
                text: `top_nav_banner.discount_${discountCodeParts[0].discountBasis}`,
                variables: {
                  discountValue:
                    discountCodeParts[0].discountBasis === 'percentage'
                      ? discountCodeParts[0].value
                      : formatCurrencyWithDecimal(discountCodeParts[0].value, {
                          locale,
                          currency
                        })
                }
              }}
            />
          )}
        </>
      </LabelList>
      <Text
        namespace={namespace}
        text="top_nav_banner.discount_message"
        margin={false}
        variant="textRegular14"
        colour="brandBlue500"
        element="p"
        shouldScale={false}
        align="right"
      />
    </div>
  )
}

export { Props }
export default TopNavDiscount
