import { gql } from '@apollo/client'

const UPDATE_BOX_DELIVERY_ADDRESS = gql`
  mutation UpdateBoxDeliveryAddressV3(
    $userId: ID!
    $boxId: ID!
    $address: AddressInput!
    $num: Int!
  ) {
    boxAddressUpdate(userId: $userId, boxId: $boxId, address: $address) {
      id
      subscription {
        id
        box(boxId: $boxId) {
          boostedBox {
            id
          }
          address {
            recipientName
            address1
            address2
            city
            postcode
            deliveryNotes
            deliveryCarrier
          }
          isoDeliveryDate
        }
        nextNBoxes(num: $num) {
          id
          isoDeliveryDate
        }
      }
    }
  }
`

export { UPDATE_BOX_DELIVERY_ADDRESS }
