// @noflow
import { countries } from 'countries-list'
import i18next from 'i18next'
import {
  AsYouType,
  isValidPhoneNumber,
  parsePhoneNumber
} from 'libphonenumber-js/mobile'

import * as Sentry from '@/utils/sentry'
import Validator from '@/utils/validator'

import {
  maximumLength,
  oneLowercaseLetter,
  oneSpecialCharacterOrNumber,
  oneUpperCaseLetter
} from '../components/InputField/PasswordInputField'

import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'
import { Code } from '@/types'

import type { FormSectionKey } from '../types'

// TODO: Placeholder for now
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AppState = any

type InputInteractionState =
  | 'InteractingInteracted'
  | 'InteractingNotInteracted'
  | 'NotInteractingInteracted'
  | 'NotInteractingNotInteracted'

type InputWithString = {
  errorMessage: string | null
  inputInteractionState: InputInteractionState
  value: string
}

type InputType = 'text' | 'email' | 'password' | 'tel'

type Autocomplete =
  | 'name'
  | 'email'
  | 'new-password'
  | 'tel'
  | 'postal-code'
  | 'address-line1'
  | 'address-line2'
  | 'address-level2'

type ValidationIndicator = 'none' | 'error' | 'checkmark'

type ModalName = 'delivery-date-modal' | 'order-summary-modal'

type FullName = {
  firstName: string
  lastName: string
}

type Translate = (arg0: string) => string

const mobileNumberRequiredRegions: Array<CountryCode> = [
  'IE',
  'NL',
  'BE',
  'PL',
  'DE'
]

/*
  Formats certains inputs:
  - phone numbers ignore anything that's not a digit or a space
    (or a + at the beginning for country calling codes) and separate the digits
    with spaces: +44 xxxx xxxxxx or xxxxx xxxxxx.
  - postcodes ignore invalid characters and limit to 8 characters.
*/
const removeExtraWhiteSpace = (value: string): string => {
  return value.replace(/(^\s*)|(\s*$)/gi, '').replace(/[ ]{2,}/gi, ' ')
}

const formatNameInput = (fullName: string): FullName => {
  // replace leading, trailing, and double white space
  const formattedName = removeExtraWhiteSpace(fullName)
  const firstSpace = formattedName.indexOf(' ')
  const firstName = formattedName.substr(0, firstSpace)
  const lastName = formattedName.substr(firstSpace + 1)

  return {
    firstName,
    lastName
  }
}

const format = (
  value: string,
  fieldName: FormSectionKey,
  shippingCountryCode?: CountryCode
): string => {
  switch (fieldName) {
    case 'mobileNumber': {
      return new AsYouType(shippingCountryCode).input(value)
    }
    case 'postcode': {
      return value.replace(/[^a-zA-Z0-9 ]/g, '').slice(0, 8)
    }
    default: {
      return value
    }
  }
}

const interactingState = (
  currentInteractionState: InputInteractionState
): InputInteractionState => {
  if (
    currentInteractionState === 'NotInteractingInteracted' ||
    currentInteractionState === 'InteractingInteracted'
  ) {
    return 'InteractingInteracted'
  } else {
    return 'InteractingNotInteracted'
  }
}

const addressLineLimit = (shippingCountryCode: CountryCode): number | null => {
  switch (shippingCountryCode) {
    case Code.DE: {
      return 35
    }
    case Code.BE:
    case Code.NL: {
      return 95
    }
    default: {
      return null
    }
  }
}

const supportedCountryNumbers = Object.entries(countries)
  .filter(([key, { continent }]) => continent === 'EU' || key === 'US')
  .map(([key]) => key)

const validate = (
  value: string,
  fieldName: FormSectionKey,
  shippingCountryCode?: CountryCode,
  deliveryNotesLimit?: number
): string | null => {
  const copy_context = 'checkout:errors'

  switch (fieldName) {
    case 'name': {
      // The bellow regex allows for accented characters such as the ones found in PL or GE names,
      // Comma, dash and space, excluding special characters
      const nameRegex =
        /^[A-Za-z0-9À-ÖØ-Ýà-öø-ÿĄ-ŹąćęłńóśżźÄ-ÖÜä-öüÿ,'-]+(?:\s+[A-Za-z0-9À-ÖØ-Ýà-öø-ÿĄ-ŹąćęłńóśżźÄ-ÖÜä-öüÿ,'-]+)+\s*$/

      if (!nameRegex.test(value)) {
        return i18next.t(`${copy_context}.name`)
      }
      return null
    }
    case 'email': {
      if (Validator.isValidEmail(value)) {
        return null
      }
      return i18next.t(`${copy_context}.email`)
    }
    case 'password':
    case 'visible-password': {
      if (!oneUpperCaseLetter.test(value)) {
        return i18next.t(`${copy_context}.password.one_uppercase_letter`)
      }
      if (!oneLowercaseLetter.test(value)) {
        return i18next.t(`${copy_context}.password.one_lowercase_letter`)
      }
      if (!maximumLength.test(value)) {
        return i18next.t(`${copy_context}.password.minimum_length`)
      }
      if (!oneSpecialCharacterOrNumber.test(value)) {
        return i18next.t(
          `${copy_context}.password.one_special_character_or_number`
        )
      }
      return null
    }
    case 'addressLine1': {
      const limitationValue =
        (shippingCountryCode && addressLineLimit(shippingCountryCode)) ?? 0

      if (
        value.length <= 0 ||
        (limitationValue !== 0 && value.length > limitationValue)
      ) {
        return i18next.t(`${copy_context}.address_line_1`)
      }
      return null
    }
    case 'addressLine2': {
      return null
    }
    case 'city': {
      if (value.length > 0) {
        return null
      }
      return i18next.t(`${copy_context}.address_city`)
    }
    case 'searchTerm': {
      if (Validator.isBlank(value)) {
        return i18next.t(`${copy_context}.address_invalid`)
      }
      return null
    }
    case 'postcode': {
      // This field has custom validation in the PostcodeInputField component
      return null
    }
    case 'deliveryNotes': {
      if (deliveryNotesLimit && value.length > deliveryNotesLimit) {
        return i18next.t(`${copy_context}.delivery_notes`, {
          deliveryNotesLimit
        })
      }
      return null
    }
    case 'mobileNumber': {
      if (!value) {
        const shouldProvideMobileNumber = mobileNumberRequiredRegions.some(
          (code) => code === shippingCountryCode
        )
        if (shouldProvideMobileNumber) {
          return i18next.t(`${copy_context}.mobile`)
        } else return null
      }

      const trimmedNumber = value.trim()
      const sanitisedShippingCountryCode =
        shippingCountryCode === 'NI' ? 'GB' : shippingCountryCode

      // We check first that the number is a valid complete mobile number, because parsePhoneNumber
      // requires a complete phone number otherwise it'll error on attempting to parse
      const isValidMobileNumber = isValidPhoneNumber(
        trimmedNumber,
        sanitisedShippingCountryCode
      )
      if (isValidMobileNumber) {
        // We have a defined list of allowed European + US phone numbers which we want to check against
        const parsedNumberCountryCode = parsePhoneNumber(
          trimmedNumber,
          sanitisedShippingCountryCode
        ).country

        if (!parsedNumberCountryCode) return null

        const isSupportedCountryNumber = supportedCountryNumbers.includes(
          parsedNumberCountryCode
        )

        if (isSupportedCountryNumber) {
          return null
        } else {
          return i18next.t(`${copy_context}.mobile`)
        }
      } else {
        return i18next.t(`${copy_context}.mobile`)
      }
    }
    default: {
      Sentry.captureException(`validate not implemented for fieldName`, {
        extra: {
          fieldName
        },
        tags: {
          product: Sentry.Product.Checkout
        }
      })
      return null
    }
  }
}

const validationIndicator = (
  inputField: InputWithString
): ValidationIndicator => {
  if (inputField.errorMessage === null) {
    if (inputField.inputInteractionState === 'NotInteractingNotInteracted') {
      return 'none'
    }
    return 'checkmark'
  }
  switch (inputField.inputInteractionState) {
    case 'InteractingNotInteracted': {
      return 'none'
    }
    case 'NotInteractingNotInteracted': {
      return 'none'
    }
    case 'InteractingInteracted': {
      return 'error'
    }
    case 'NotInteractingInteracted': {
      return 'error'
    }
  }
}

const isRequired = (
  fieldName: FormSectionKey,
  shippingCountryCode?: CountryCode
): boolean => {
  switch (fieldName) {
    case 'searchTerm': {
      return false
    }
    case 'mobileNumber': {
      const shouldProvideMobileNumber = mobileNumberRequiredRegions.some(
        (code) => code === shippingCountryCode
      )
      if (shouldProvideMobileNumber) {
        return true
      } else return false
    }
    case 'addressLine2': {
      return false
    }
    default: {
      return true
    }
  }
}

const mapFieldNameToType = (fieldName: FormSectionKey): InputType => {
  switch (fieldName) {
    case 'email': {
      return 'email'
    }
    case 'password': {
      return 'password'
    }
    case 'mobileNumber': {
      return 'tel'
    }
    default: {
      return 'text'
    }
  }
}

const mapFieldNameToPlaceholder = (
  t: Translate,
  fieldName: FormSectionKey,
  shippingCountryCode?: CountryCode
): string => {
  const copy_context = 'input_placeholders'
  const isIreland = shippingCountryCode === 'IE'
  switch (fieldName) {
    case 'name': {
      return t(`${copy_context}.name`)
    }
    case 'email': {
      return t(`${copy_context}.email`)
    }
    case 'password': {
      return t(`${copy_context}.password`)
    }
    case 'visible-password': {
      return t(`${copy_context}.visible_password`)
    }
    case 'mobileNumber': {
      return t(`${copy_context}.mobile_number`)
    }
    case 'searchTerm': {
      return isIreland
        ? t(`${copy_context}.search_term_ie`)
        : t(`${copy_context}.search_term`)
    }
    case 'addressLine1': {
      return t(`${copy_context}.address_line1`)
    }
    case 'addressLine2': {
      return t(`${copy_context}.address_line2`)
    }
    case 'city': {
      return t(`${copy_context}.city`)
    }
    case 'postcode': {
      return t(`${copy_context}.postcode`)
    }
    case 'eircode': {
      return t(`${copy_context}.eircode`)
    }
    default: {
      Sentry.captureException(`Cannot mapFieldNameToPlaceholder to fieldName`, {
        extra: {
          fieldName
        },
        tags: {
          product: Sentry.Product.Checkout
        }
      })
      return ''
    }
  }
}

const mapFieldNameToAutocomplete = (
  fieldName: FormSectionKey
): Autocomplete | undefined => {
  switch (fieldName) {
    case 'name': {
      return 'name'
    }
    case 'email': {
      return 'email'
    }
    case 'password': {
      return 'new-password'
    }
    case 'visible-password': {
      return 'new-password'
    }
    case 'mobileNumber': {
      return 'tel'
    }
    case 'searchTerm': {
      return undefined
    }
    case 'addressLine1': {
      return 'address-line1'
    }
    case 'addressLine2': {
      return 'address-line2'
    }
    case 'city': {
      return 'address-level2'
    }
    case 'postcode': {
      return 'postal-code'
    }
  }
}

const fieldsWithErrors = (
  state: AppState
): Array<FormSectionKey | 'payment-details'> => {
  const fieldNames: Array<FormSectionKey | 'payment-details'> = []
  if (validationIndicator(state.accountSectionReducer.form.name) === 'error') {
    fieldNames.push('name')
  }
  if (validationIndicator(state.accountSectionReducer.form.email) === 'error') {
    fieldNames.push('email')
  }
  if (
    validationIndicator(state.accountSectionReducer.form.password) === 'error'
  ) {
    fieldNames.push('password')
  }
  if (
    validationIndicator(state.deliveryDateSectionReducer.form.mobileNumber) ===
    'error'
  ) {
    fieldNames.push('mobileNumber')
  }
  if (
    validationIndicator(state.deliveryAddressSectionReducer.form.searchTerm) ===
    'error'
  ) {
    fieldNames.push('searchTerm')
  }
  if (
    validationIndicator(
      state.deliveryAddressSectionReducer.form.addressLine1
    ) === 'error'
  ) {
    fieldNames.push('addressLine1')
  }
  if (
    validationIndicator(
      state.deliveryAddressSectionReducer.form.addressLine2
    ) === 'error'
  ) {
    fieldNames.push('addressLine2')
  }
  if (
    validationIndicator(state.deliveryAddressSectionReducer.form.city) ===
    'error'
  ) {
    fieldNames.push('city')
  }
  if (
    validationIndicator(state.deliveryAddressSectionReducer.form.postcode) ===
    'error'
  ) {
    fieldNames.push('postcode')
  }
  if (state.paymentSectionReducer.form.card.errorMessage !== null) {
    fieldNames.push('payment-details')
  }
  return fieldNames
}

export {
  mapFieldNameToType,
  mapFieldNameToAutocomplete,
  mapFieldNameToPlaceholder,
  fieldsWithErrors,
  isRequired,
  format,
  validate,
  validationIndicator,
  interactingState,
  removeExtraWhiteSpace,
  formatNameInput,
  addressLineLimit
}

export type { ModalName }
