import { ApolloQueryResult, FetchResult } from '@apollo/client'
import { StripeError } from '@stripe/stripe-js'
import { GraphQLError } from 'graphql'
import i18next from 'i18next'

import { bannerMessageState } from '../../components/Banner/Banner'
import client from '@/components/apollo/client'

// GraphQL
import {
  DIRECT_SALES_STRIPE_CUSTOMER_CREATION,
  STRIPE_USER_PARAMS_SETUP_INTENT_CREATE_MUTATION
} from '../../mutations/mutations'
import { DIRECT_SALES_SEPA_PAYMENT_METHOD_ID_FROM_INTENT_SECRET } from '../../queries/directSalesPaymentQuery'

import { DirectSalesStripeCustomerCreate } from '../../mutations/__generated__/DirectSalesStripeCustomerCreate'
import { DirectSalesStripeUserParamsSetupIntentCreate } from '../../mutations/__generated__/directSalesStripeUserParamsSetupIntentCreate'
import { DirectSalesSepaPaymentMethodIdFromIntentSecret } from '../../queries/__generated__/DirectSalesSepaPaymentMethodIdFromIntentSecret'
import { PaymentMethodType } from '@/types'

import { ConstructSubscriptionInput } from '../../helpers/buiildSubscriptionData'
import { buildSubscriptionInputDataForStripeSetupIntent } from '../../helpers/buiildSubscriptionInputDataForStripeSetupIntent'
import { abstractStripeError } from '../../helpers/errors'
import { setSubmissionState } from '../../screens/PaymentScreen/PaymentScreen'

type SetupIntentProps = {
  authenticationType: string
  formattedSubscriptionData: ConstructSubscriptionInput
  paymentIntentId: string
  firstName: string
  lastName: string
  paymentMethodType: PaymentMethodType
}

type StripeCustomerCreateProps = {
  paymentMethodType: string
  email: string
  amount: number
}

type PaymentMethodFromIntentProps = {
  intentSecret: string
  intentType: string
}

type Props = {
  stripeCustomerCreate: (
    input: StripeCustomerCreateProps
  ) => Promise<FetchResult<DirectSalesStripeCustomerCreate> | void>
  stripeSetupIntentCreate: (
    input: SetupIntentProps
  ) => Promise<
    FetchResult<DirectSalesStripeUserParamsSetupIntentCreate> | undefined
  >
  raiseBannerError: (error: Error | GraphQLError | StripeError) => void
  sepaPaymentMethodIdFromIntentSecret: (
    input: PaymentMethodFromIntentProps
  ) => Promise<ApolloQueryResult<DirectSalesSepaPaymentMethodIdFromIntentSecret> | void>
}

const stripeMutationMethods = (): Props => {
  const raiseBannerError = (error: Error | StripeError | GraphQLError) => {
    let message = error?.message

    if (!(error instanceof Error)) {
      message = abstractStripeError(error)
    }
    bannerMessageState({
      message:
        error?.message || i18next.t('checkout:errors.delivery_details_fetch')
    })

    setSubmissionState({
      type: 'error',
      error: message || i18next.t('checkout:errors.delivery_details_fetch')
    })
  }

  const sepaPaymentMethodIdFromIntentSecret = async ({
    intentSecret,
    intentType
  }: PaymentMethodFromIntentProps): Promise<ApolloQueryResult<DirectSalesSepaPaymentMethodIdFromIntentSecret> | void> => {
    return await client
      .query<DirectSalesSepaPaymentMethodIdFromIntentSecret>({
        query: DIRECT_SALES_SEPA_PAYMENT_METHOD_ID_FROM_INTENT_SECRET,
        variables: {
          intentSecret: intentSecret,
          intentType: intentType
        }
      })
      .then((result) => {
        return result
      })
      .catch((error) => {
        raiseBannerError(error)
      })
  }

  const stripeCustomerCreate = async ({
    paymentMethodType,
    email,
    amount
  }: StripeCustomerCreateProps): Promise<FetchResult<DirectSalesStripeCustomerCreate> | void> => {
    return await client
      .mutate<DirectSalesStripeCustomerCreate>({
        mutation: DIRECT_SALES_STRIPE_CUSTOMER_CREATION,
        variables: {
          paymentMethodType: paymentMethodType,
          email: email,
          amount: amount
        }
      })
      .then((res) => {
        return res as Promise<FetchResult<DirectSalesStripeCustomerCreate>>
      })
      .catch((error) => {
        raiseBannerError(error)
      })
  }

  const stripeSetupIntentCreate = async ({
    authenticationType,
    formattedSubscriptionData,
    paymentIntentId,
    firstName,
    lastName,
    paymentMethodType
  }: SetupIntentProps): Promise<
    FetchResult<DirectSalesStripeUserParamsSetupIntentCreate> | undefined
  > => {
    try {
      const formattedSetupIntentData =
        buildSubscriptionInputDataForStripeSetupIntent({
          subscriptionData: formattedSubscriptionData,
          paymentMethodType: paymentMethodType,
          firstName: firstName,
          lastName: lastName
        })

      return await client
        .mutate<DirectSalesStripeUserParamsSetupIntentCreate>({
          mutation: STRIPE_USER_PARAMS_SETUP_INTENT_CREATE_MUTATION,
          variables: {
            authenticationType: authenticationType,
            subscriptionData: formattedSetupIntentData,
            paymentIntentId: paymentIntentId
          }
        })
        .then((res) => {
          return res
        })
    } catch (error) {
      raiseBannerError(error as Error | StripeError | GraphQLError)
    }
  }

  return {
    stripeCustomerCreate,
    stripeSetupIntentCreate,
    raiseBannerError,
    sepaPaymentMethodIdFromIntentSecret
  }
}

export default stripeMutationMethods
