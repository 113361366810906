// @noflow
import { useReactiveVar } from '@apollo/client'
import times from 'lodash/times'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
// Routing
import { useLocation } from 'react-router-dom'

import Text from '@/components/elements/atoms/Text/Text'
import Faq from '@/components/elements/molecules/Faq/Faq'
import { plansFlowTypeState } from '@/components/pages/PlansPage/PlansPage'

import STYLES from './FAQSection.module.sass'

import { Code } from '@/types'
import { ensureNever } from '@/typescript/utils'

import { trackFaqClicked } from '../../helpers/analytics'
import type { Route as PlanRoute } from '../../types/routes'
import type { FlowType } from '../../types/types'

type Props = Pick<
  FAQProps,
  'maxRecipes' | 'numberOfDogs' | 'namespace' | 'shippingCountryCode'
>

type FAQProps = {
  flowTypeState: FlowType
  location: PlanRoute
  namespace: string
  maxRecipes: number
  numberOfDogs: number
  shippingCountryCode: Code
}

const FAQs = ({
  flowTypeState,
  location,
  namespace,
  maxRecipes,
  numberOfDogs,
  shippingCountryCode
}: FAQProps): JSX.Element => {
  const { t } = useTranslation(namespace)
  switch (flowTypeState) {
    case 'new':
      return (
        <>
          {times(shippingCountryCode === Code.PL ? 6 : 5).map(
            (index: number): JSX.Element => (
              <Faq
                key={index}
                question={{
                  text: `plan_steps.frequently_asked_questions.question_${
                    index + 1
                  }.question`,
                  namespace: namespace,
                  variant: 'textRegular16',
                  colour: 'brandBlue500'
                }}
                answer={{
                  text: `plan_steps.frequently_asked_questions.question_${
                    index + 1
                  }.answer`,
                  namespace: namespace,
                  margin: false,
                  variant: 'textRegular14',
                  colour: 'brandBlue400',
                  variables: {
                    count: numberOfDogs,
                    maxRecipes
                  }
                }}
                // eslint-disable-next-line react-hooks/rules-of-hooks
                onClick={useCallback(
                  (): void =>
                    trackFaqClicked(
                      location,
                      flowTypeState,
                      t(
                        `plan_steps.frequently_asked_questions.question_${
                          index + 1
                        }.question`
                      )
                    ),
                  [index]
                )}
              />
            )
          )}
        </>
      )
    case 'add':
      switch (location) {
        case '/plans/recipes':
          return (
            <Faq
              question={{
                text: `plan_steps.frequently_asked_questions.add_dog.recipes.question`,
                namespace: namespace,
                variant: 'textRegular16',
                colour: 'brandBlue500'
              }}
              answer={{
                text: `plan_steps.frequently_asked_questions.add_dog.recipes.answer`,
                namespace: namespace,
                margin: false,
                variant: 'textRegular14',
                colour: 'brandBlue400'
              }}
              // eslint-disable-next-line react-hooks/rules-of-hooks
              onClick={useCallback(
                (): void =>
                  trackFaqClicked(
                    location,
                    flowTypeState,
                    t(
                      `plan_steps.frequently_asked_questions.add_dog.recipes.question`
                    )
                  ),
                [location, t, flowTypeState]
              )}
            />
          )
        case '/plans/plan':
          return (
            <Faq
              question={{
                text: `plan_steps.frequently_asked_questions.add_dog.plan.question`,
                namespace: namespace,
                variant: 'textRegular16',
                colour: 'brandBlue500'
              }}
              answer={{
                text: `plan_steps.frequently_asked_questions.add_dog.plan.answer`,
                namespace: namespace,
                margin: false,
                variant: 'textRegular14',
                colour: 'brandBlue400'
              }}
              // eslint-disable-next-line react-hooks/rules-of-hooks
              onClick={useCallback(
                (): void =>
                  trackFaqClicked(
                    location,
                    flowTypeState,
                    t(
                      `plan_steps.frequently_asked_questions.add_dog.plan.question`
                    )
                  ),
                [location, t, flowTypeState]
              )}
            />
          )
        case '/plans/review':
          return (
            <>
              {times(2).map(
                (index: number): JSX.Element => (
                  <Faq
                    key={index}
                    question={{
                      text: `plan_steps.frequently_asked_questions.add_dog.review.question_${
                        index + 1
                      }.question`,
                      namespace: namespace,
                      variant: 'textRegular16',
                      colour: 'brandBlue500'
                    }}
                    answer={{
                      text: `plan_steps.frequently_asked_questions.add_dog.review.question_${
                        index + 1
                      }.answer`,
                      namespace: namespace,
                      margin: false,
                      variant: 'textRegular14',
                      colour: 'brandBlue400'
                    }}
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    onClick={useCallback(
                      (): void =>
                        trackFaqClicked(
                          location,
                          flowTypeState,
                          t(
                            `plan_steps.frequently_asked_questions.add_dog.review.question_${
                              index + 1
                            }.question`
                          )
                        ),
                      [index]
                    )}
                  />
                )
              )}
            </>
          )
        default: {
          throw new Error(`Cannot generate FAQs for location of: ${location}`)
        }
      }
    default: {
      ensureNever(flowTypeState)
      throw new Error(
        `Cannot generate FAQs for flowTypeState of: ${flowTypeState}`
      )
    }
  }
}

const FAQSection = ({
  namespace,
  maxRecipes,
  numberOfDogs,
  shippingCountryCode
}: Props): JSX.Element => {
  const location = useLocation().pathname as PlanRoute
  const flowTypeState = useReactiveVar(plansFlowTypeState) as FlowType

  return (
    <div className={STYLES.faqSection}>
      <div className={STYLES.wrapper}>
        <Text
          namespace={namespace}
          text={'plan_steps.frequently_asked_questions.title'}
          variant="display24"
          colour="brandBlue500"
          margin={false}
        />
        <FAQs
          flowTypeState={flowTypeState}
          location={location}
          namespace={namespace}
          maxRecipes={maxRecipes}
          numberOfDogs={numberOfDogs}
          shippingCountryCode={shippingCountryCode}
        />
      </div>
    </div>
  )
}

export default FAQSection
