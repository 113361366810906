// @flow

import * as React from 'react'
import i18next from 'i18next'

import DogInBox from '../../../../../../assets/images/illustrations/dogs/dachshund-in-box.svg'
import { countryCodeToDefaultCurrencySymbol } from '@/utils/countryCodeHelper'

import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'

type Props = {|
  redeemCredits: number => void,
  shippingCountryCode: CountryCode
|}

type State = {|
  amountOfCreditsToRedeem: string,
  payoutRequestInFlight: boolean
|}

class AwaitingCreditRedemption extends React.Component<Props, State> {
  constructor (props: Props): void {
    super(props)
    this.state = {
      amountOfCreditsToRedeem: '',
      payoutRequestInFlight: false
    }
  }

  namespace = 'ambassadors:dashboard.balance_tab'

  onAmountOfCreditsToRedeemChange = (event: SyntheticEvent<HTMLInputElement>): void => {
    const amountOfCreditsToRedeem = event.currentTarget.value
    this.setState({ amountOfCreditsToRedeem })
  }

  redeemCredits = (): void => {
    this.setState(
      {
        payoutRequestInFlight: true
      }
    )
    const {amountOfCreditsToRedeem} = this.state
    const credits = parseFloat(amountOfCreditsToRedeem)
    if (!credits || credits <= 0) return

    const creditsInHundredths = Math.round(credits * 100)
    this.props.redeemCredits(creditsInHundredths)
  }

  render (): React.Node {
    return (
      <div className='ambassador-balance__card'>
        <div className='ambassador-balance__card__header'>
          <h2>{ i18next.t(`${this.namespace}.my_next_box`) }</h2>
          <img
            src={DogInBox}
            alt='An illustration of a dog sniffing inside a box'
          />
          <p>{ i18next.t(`${this.namespace}.apply_credit`) }</p>
          <div className='card__item ambassador-balance__card__input'>
            <span className=''>
              { countryCodeToDefaultCurrencySymbol(this.props.shippingCountryCode) }
              <input
                type='number'
                placeholder='00.00'
                step={0.01}
                min={0}
                value={this.state.amountOfCreditsToRedeem}
                onChange={this.onAmountOfCreditsToRedeemChange}
              />
            </span>
            <button
              className='btn btn-updated--blue'
              onClick={this.redeemCredits}
              disabled={this.state.payoutRequestInFlight}
            >
              { i18next.t(`${this.namespace}.confirm`) }
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default AwaitingCreditRedemption
