import React from 'react'

// Components
import { Button } from '@/components/elements/atoms/Button'
import { FixedBase } from '@/components/elements/atoms/FixedBase'
import Icon from '@/components/elements/atoms/Icon/Icon'
import Text from '@/components/elements/atoms/Text'

// Styles
import STYLES from './ChangeCourierFooter.module.sass'

type Props = {
  selectedCourier: string
  updating: boolean
  onPress: () => void
  onClosed: () => void
}

const ChangeCourierFooter = ({
  selectedCourier,
  onClosed,
  onPress,
  updating
}: Props): JSX.Element | null => {
  return (
    <FixedBase>
      <div className={STYLES.container}>
        <div className={STYLES.horizontalWrapper}>
          <Text
            text="change_date.change_courier.title"
            namespace="account"
            variables={{
              newCourier: selectedCourier
            }}
            colour={'brandBlue500'}
            margin={false}
            variant={'textRegular18'}
          />
          <div className={STYLES.closeWrapper}>
            <button
              type="button"
              className={STYLES.closeButton}
              onClick={onClosed}
            >
              {/* We want to use FlatButton here ideally however it is not currently */}
              {/* set up to render images without using asset management */}
              {/* which we should not use for icons */}
              {/* TODO: update when we update <FlatButton component */}
              <Icon asset={'close'} size={16} />
            </button>
          </div>
        </div>
        <Button
          identifier="change_courier.update_courier"
          disabled={updating}
          onClick={onPress}
          fullWidth
          typography={{
            text: 'change_date.change_courier.confirm',
            namespace: 'account'
          }}
        />
      </div>
    </FixedBase>
  )
}

export default ChangeCourierFooter
