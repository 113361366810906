// @noflow
import React from 'react'

import FestiveThemeCard from '@/components/pages/Dashboard/components/FestiveThemeCard/FestiveThemeCard'

import { PrimaryActions } from './PrimaryActions'

const HeroSection = (): JSX.Element | null => {
  return (
    <>
      <FestiveThemeCard />
      <PrimaryActions />
    </>
  )
}

export default HeroSection
