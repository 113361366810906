import { gql } from '@apollo/client'

const BOX_ORDER_SUMMARY_MODAL_FRAGMENT = gql`
  fragment BoxOrderSummaryModalFragment on Box {
    id
    netPrice
    price
    order {
      id
      deliveryFee
      discountTotal
      physicalProductsTotal
      planTotal
      planTotalPerDay
      subtotal
      surchargeTotal
      totalPrice
    }
  }
`

const NON_CORE_ORDER_ORDER_SUMMARY_MODAL_FRAGMENT = gql`
  fragment NonCoreOrderOrderSummaryModalFragment on NonCoreOrder {
    id
    invoice {
      id
      discountTotal
      discountedPrice
      grossPrice
    }
    orderParts {
      deliverySurcharge
      orderProductsTotal
      smallOrderSurcharge
    }
  }
`

const ORDER_SUMMARY_MODAL_FRAGMENT = gql`
  ${BOX_ORDER_SUMMARY_MODAL_FRAGMENT}
  ${NON_CORE_ORDER_ORDER_SUMMARY_MODAL_FRAGMENT}
  fragment OrderSummaryModalFragment on OrderUnion {
    ... on Box {
      ...BoxOrderSummaryModalFragment
    }
    ... on NonCoreOrder {
      ...NonCoreOrderOrderSummaryModalFragment
    }
  }
`

export {
  BOX_ORDER_SUMMARY_MODAL_FRAGMENT,
  NON_CORE_ORDER_ORDER_SUMMARY_MODAL_FRAGMENT,
  ORDER_SUMMARY_MODAL_FRAGMENT
}
