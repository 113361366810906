// @noflow

type Route =
  | '/self-resolution'
  | '/self-resolution/categories'
  | '/self-resolution/contact-customer-love'
  | '/self-resolution/quick-help'
  | '/self-resolution/report-issue'
  | '/self-resolution/resolution-offering'
  | '/self-resolution/resolution-confirmation'
  | '/self-resolution/oops'

const Default: Route = '/self-resolution'
const Categories: Route = '/self-resolution/categories'
const ContactCustomerLove: Route = '/self-resolution/contact-customer-love'
const QuickHelp: Route = '/self-resolution/quick-help'
const Inputs: Route = '/self-resolution/report-issue'
const ResolutionOffering: Route = '/self-resolution/resolution-offering'
const ResolutionConfirmation: Route = '/self-resolution/resolution-confirmation'
const Error: Route = '/self-resolution/oops'

const CustomerIssueManagementRoutes = {
  Default,
  Categories,
  ContactCustomerLove,
  QuickHelp,
  Inputs,
  ResolutionOffering,
  ResolutionConfirmation,
  Error
}

export type { Route }

export default CustomerIssueManagementRoutes
