// @noflow
import React from 'react'

import Text from '@/components/elements/atoms/Text/Text'
import type { Props as TextProps } from '@/components/elements/atoms/Text/Text'

import STYLES from './RadioButton.module.sass'

type Variant = 'fullWidth' | 'white' | 'withChildren' | 'horizontal'
type ColorVariant = 'brandBlue100' | 'brandYellow200'
type Size = 'small'
type IconPosition = 'left' | 'right'

type SubOptions = {
  title: TextProps
  options: Array<Omit<Props, 'subOptions' | 'onChange' | 'optionIndex'>>
}

type Props = {
  id: string
  dataTestId?: string
  variant?: Variant
  colorVariant?: ColorVariant
  text: TextProps
  value: string | number
  subText?: TextProps
  subOptions?: SubOptions
  onChange: (index: number, subOptionIndex: number) => void
  defaultChecked: boolean
  optionIndex: number
  icons?: React.ReactNode
  children?: React.ReactNode
  border?: boolean
  size?: Size
  iconPosition?: IconPosition
}

const SubOptions = ({
  subOptions,
  onChange,
  checkedIndex
}: {
  subOptions: SubOptions
  onChange: (index: number) => void
  checkedIndex: number
}) => {
  const { title, options } = subOptions

  return (
    <div className={STYLES.subOptionsContainer}>
      <div className={STYLES.subOptionsTitle}>
        <Text
          namespace={title.namespace}
          variables={title.variables}
          text={title.text}
          variant="display16"
          margin={false}
          colour="brandBlue500"
          translate={title.translate}
        />
      </div>
      {options.map((option, index) => (
        <React.Fragment key={option.value}>
          <input
            id={option.id}
            type="radio"
            value={option.value}
            // eslint-disable-next-line react/jsx-no-bind
            onChange={() => onChange(index)}
            checked={checkedIndex === index || option.defaultChecked}
          />
          <label htmlFor={option.id}>
            <span className={STYLES.label}>
              <Text
                element="span"
                namespace={option.text.namespace}
                text={option.text.text}
                variables={option.text.variables}
                margin={false}
                translate={option.text.translate}
              />
              {option.subText && (
                <Text
                  element="span"
                  namespace={option.subText.namespace}
                  text={option.subText.text}
                  variables={option.subText.variables}
                  margin={false}
                  translate={option.subText.translate}
                />
              )}
            </span>
          </label>
        </React.Fragment>
      ))}
    </div>
  )
}

const RadioButton = ({
  id,
  dataTestId,
  variant = 'fullWidth',
  colorVariant = 'brandBlue100',
  text,
  value,
  subText,
  subOptions,
  onChange,
  defaultChecked,
  optionIndex,
  icons,
  children,
  border = true,
  size,
  iconPosition = 'right'
}: Props): JSX.Element => {
  const [checked, setChecked] = React.useState(defaultChecked)
  const [subOptionIndex, setSubOptionIndex] = React.useState(-1)

  const handleOptionChange = React.useCallback(() => {
    setChecked(!checked)

    onChange(optionIndex, subOptionIndex)
  }, [onChange, checked, optionIndex, subOptionIndex])

  const handleSubOptionChange = React.useCallback(
    (subOptionIndex: number) => {
      setSubOptionIndex(subOptionIndex)

      onChange(optionIndex, subOptionIndex)
    },
    [onChange, optionIndex]
  )

  React.useEffect(() => {
    setChecked(defaultChecked)
  }, [defaultChecked])

  return (
    <div
      className={`${STYLES.container} ${variant && STYLES[variant]} ${
        colorVariant && STYLES[colorVariant]
      } ${subOptions && defaultChecked ? STYLES.subOptionsSelected : ''} ${
        !border ? STYLES.noBorder : ''
      } ${size && STYLES[size]}`}
    >
      <input
        type="radio"
        value={value}
        onChange={handleOptionChange}
        checked={checked}
        id={id}
        data-testid={dataTestId}
        tabIndex={0}
      />
      <label htmlFor={id}>
        {icons && iconPosition === 'left' && (
          <div className={STYLES.icons}>{icons}</div>
        )}
        <span className={STYLES.label}>
          <Text
            element="span"
            namespace={text.namespace}
            text={text.text}
            variables={text.variables}
            margin={false}
            translate={text.translate}
          />
          {subText && (
            <Text
              element="span"
              namespace={subText.namespace}
              text={subText.text}
              variables={subText.variables}
              margin={false}
              colour={subText.colour ?? 'brandBlue400'}
              translate={subText.translate}
            />
          )}
        </span>
        {icons && iconPosition !== 'left' && (
          <div className={STYLES.icons}>{icons}</div>
        )}
      </label>
      {subOptions && checked && (
        <SubOptions
          subOptions={subOptions}
          onChange={handleSubOptionChange}
          checkedIndex={subOptionIndex}
        />
      )}
      {children && <div>{children}</div>}
    </div>
  )
}

export { Props }
export type { Size, IconPosition }
export default RadioButton
