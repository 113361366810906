// @noflow
import { makeVar, useReactiveVar } from '@apollo/client'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import type {
  StripeCardElementChangeEvent,
  StripeIdealBankElementChangeEvent
} from '@stripe/stripe-js'
import i18next from 'i18next'
import snakeCase from 'lodash/snakeCase'
import React, { createRef, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Locale } from '@/utils/currency'
import { Product, captureException } from '@/utils/sentry'
import { getLocalisedPathnames } from '@/utils/urls'

import Lock from 'assets/images/icons/locks/lock.svg'
import dogStanding from 'assets/images/illustrations/dogs/dog-standing.svg'
import dogsMeeting from 'assets/images/illustrations/dogs/dogs-meeting.svg'

import { Expand } from '@/components/elements/atoms/Animated/Animated'
import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'
import Text from '@/components/elements/atoms/Text/Text'
import RadioGroup from '@/components/elements/molecules/RadioGroup/RadioGroup'
import LoadingScreen from '@/components/elements/organisms/LoadingScreen/LoadingScreen'

import type { CheckoutPage } from '../../queries/__generated__/CheckoutPage'

import * as ANALYTICS from '../../Analytics/CheckoutAnalytics'
import { checkoutPageState } from '../../CheckoutPage'
import { abstractStripeError } from '../../helpers/errors'
import { useCheckoutPricing } from '../../hooks/useCheckoutPricing'
import {
  SubmissionState,
  convertActivePaymentViewToPaymentMethod
} from '../../types'
import Banner, { bannerMessageState } from './Banner'
import PaymentOption from './PaymentOptions/PaymentOption'
import buySubscriptionWithCard from './helpers/buySubscriptionWithCard'
import paymentMethodToIcons from './helpers/paymentMethodIcons'
import { findPaymentMethodsAvaliable } from './helpers/paymentMethodsAvaliable'
import useBuySubscriptionWithBancontact from './hooks/useBuySubscriptionWithBancontact'
import useBuySubscriptionWithIdeal from './hooks/useBuySubscriptionWithIdeal'
import useBuySubscriptionWithPayPal from './hooks/useBuySubscriptionWithPayPal'
import { useBuySubscriptionWithPaymentRequest } from './hooks/useBuySubscriptionWithPaymentRequest'
import usePaymentView from './hooks/usePaymentView'

type Props = {
  namespace: string
  affiliateType: CheckoutPage['guest']['affiliateType']
  shippingCountryCode: CheckoutPage['guest']['assumedShippingCountry']['code']
  csrfToken: string
  shouldSeeApplePay: boolean
  shouldSeeGooglePay: boolean
  paypalClientId: string
  currency: CheckoutPage['guest']['assumedShippingCountry']['currency']
  locale: Locale
  shouldSeePayPal: boolean
}

const paymentSubmissionState = makeVar<SubmissionState>({
  type: 'not-requested'
})

const setSubmissionState = (state: SubmissionState): void => {
  paymentSubmissionState({
    ...state
  })
}

const PaymentSection = ({
  namespace,
  affiliateType,
  shippingCountryCode,
  csrfToken,
  shouldSeeApplePay,
  shouldSeeGooglePay,
  paypalClientId,
  currency,
  locale,
  shouldSeePayPal
}: Props): JSX.Element => {
  // Refs
  const section = createRef<HTMLElement>()

  // Stripe
  const stripe = useStripe()
  const elements = useElements()

  // Localisation
  const { t } = useTranslation(namespace)
  const copyContext = 'payment_section'

  const bannerMessage = useReactiveVar(bannerMessageState)
  const submissionState = useReactiveVar(paymentSubmissionState)
  const { sections, user, plan } = checkoutPageState()
  const { paymentDetails } = sections
  const { visible, form } = paymentDetails
  const { selectedPaymentMethod } = form
  const requiresPayment = user.requiresPaymentDetailsOnCheckout

  const [inlineErrorMessage, setinlineErrorMessage] = useState<string | null>(
    null
  )

  const {
    paymentRequest,
    paymentRequestInProgress,
    availablePaymentRequestMethod,
    updatePaymentRequestPrices
  } = useBuySubscriptionWithPaymentRequest({
    shippingCountryCode,
    csrfToken,
    t,
    shouldSeeApplePay,
    shouldSeeGooglePay
  })

  const { activePaymentView, setActivePaymentView, currentPayPalOption } =
    usePaymentView({
      affiliateType,
      shippingCountryCode,
      requiresPayment,
      paymentRequest
    })

  const {
    payPalAuthorisationResponse,
    payPalBillingAgreementTokenReceived,
    payPalButtonClicked,
    payPalPaymentSuccess,
    handlePayPalInContextSubmission,
    fetchPaypalToken
  } = useBuySubscriptionWithPayPal({
    currentPayPalOption,
    csrfToken,
    activePaymentView
  })

  useCheckoutPricing({
    planId: plan.planId,
    handlePricingUpdate: fetchPaypalToken
  })

  const { bancontactSubscriptionCallback, bancontactAuthRecieved } =
    useBuySubscriptionWithBancontact({ csrfToken })

  const { iDealSubscriptionCallback, idealAuthReceived, idealButtonClicked } =
    useBuySubscriptionWithIdeal({ csrfToken, setActivePaymentView })

  const handleIdealSelect = useCallback(
    (e: StripeIdealBankElementChangeEvent) => {
      sections.paymentDetails.valid = e.complete
      return checkoutPageState({
        ...checkoutPageState(),
        sections
      })
    },
    [sections]
  )
  const onCardElementChange = useCallback(
    (event: StripeCardElementChangeEvent) => {
      if (event.error !== undefined) {
        const errorMessage = abstractStripeError(event.error)
        sections.paymentDetails.valid = false

        setinlineErrorMessage(errorMessage)

        return checkoutPageState({
          ...checkoutPageState(),
          sections
        })
      }

      if (event.complete) {
        sections.paymentDetails.valid = event.complete
        setinlineErrorMessage(null)

        return checkoutPageState({
          ...checkoutPageState(),
          sections
        })
      }
    },
    [sections]
  )

  const buySubscriptionCallback = useCallback(() => {
    if (!stripe || !elements) {
      bannerMessageState({
        message: i18next.t('checkout:errors.delivery_details_fetch'),
        type: 'error'
      })
      return captureException(
        `Buy subscription button is enabled without Stripe or Stripe Elements initialised`,
        {
          tags: {
            product: Product.Checkout
          }
        }
      )
    }

    switch (activePaymentView) {
      case 'credit-card':
      case 'creditCard': {
        buySubscriptionWithCard({ stripe, elements, csrfToken })
        ANALYTICS.submissionAttempt('creditCard')
        break
      }
      case 'iDeal': {
        iDealSubscriptionCallback()
        ANALYTICS.submissionAttempt('iDeal')
        break
      }
      case 'payment-options': {
        break
      }
      case 'none': {
        buySubscriptionWithCard({ stripe, elements, csrfToken })
        ANALYTICS.submissionAttempt('none')
        break
      }
      default: {
        captureException(
          `activePaymentView is invalid in buySubscriptionCallback on Checkout`,
          {
            extra: {
              activePaymentView
            },
            tags: {
              product: Product.Checkout
            }
          }
        )
      }
    }
  }, [
    activePaymentView,
    csrfToken,
    elements,
    iDealSubscriptionCallback,
    stripe
  ])

  const areAllSectionsValid = () =>
    Object.values(sections).every(({ valid }) => valid)

  const areAllSectionsValidExceptPaymentDetails = () =>
    Object.entries(sections)
      .filter(([key]) => key !== 'paymentDetails')
      .every(([, { valid }]) => valid)

  const disablePayPalButton =
    (submissionState.type === 'loading' &&
      payPalBillingAgreementTokenReceived) ||
    !areAllSectionsValidExceptPaymentDetails()

  const bancontactDisabled =
    bancontactAuthRecieved ||
    submissionState.type === 'loading' ||
    !areAllSectionsValidExceptPaymentDetails()

  const idealDisabled =
    idealAuthReceived ||
    submissionState.type === 'loading' ||
    (!areAllSectionsValid() && activePaymentView !== 'none')

  const showLoadingScreen =
    payPalPaymentSuccess ||
    bancontactAuthRecieved ||
    idealAuthReceived ||
    (paymentRequestInProgress.paymentMethod === 'googlePay' &&
      paymentRequestInProgress.inProgress)

  const availablePaymentMethods =
    findPaymentMethodsAvaliable(shippingCountryCode)

  const filteredPaymentMethods = availablePaymentMethods.filter((method) => {
    if (
      (method === 'applePay' && availablePaymentRequestMethod === 'applePay') ||
      (method === 'googlePay' && availablePaymentRequestMethod === 'googlePay')
    ) {
      return true // Include applePay or googlePay only if the user meets the device requirements
    }
    if (method === 'payPal') {
      return shouldSeePayPal // Exclude payPal if shouldSeePayPal is false
    }
    return !['applePay', 'googlePay'].includes(method) // Otherwise exclude applePay and googlePay
  })

  useEffect(() => {
    if (visible) {
      selectedPaymentMethod.type =
        convertActivePaymentViewToPaymentMethod(activePaymentView)
      checkoutPageState({
        ...checkoutPageState()
      })
    }
  }, [activePaymentView, selectedPaymentMethod, visible])

  const availablePaymentOptions = filteredPaymentMethods.map((method) => {
    return {
      id: method,
      text: {
        namespace,
        // eslint-disable-next-line i18next/no-literal-string
        text: `${copyContext}.payment_options_view.${
          method === 'iDeal' ? 'ideal' : snakeCase(method)
        }`
      },
      value: method,
      defaultChecked: activePaymentView === method,
      icons: paymentMethodToIcons(method, t),
      children: (
        <PaymentOption
          activePaymentView={activePaymentView}
          namespace={namespace}
          paymentMethod={method}
          paymentSectionVisible={visible}
          creditCard={{
            onCardElementChange,
            errorMessage: inlineErrorMessage,
            creditCardLoading: submissionState.type === 'loading',
            disableCreditCardButton:
              submissionState.type === 'loading' ||
              (!areAllSectionsValid() && activePaymentView !== 'none'),
            buySubscriptionCallback: buySubscriptionCallback
          }}
          paymentRequest={paymentRequest}
          updatePaymentRequestPrices={updatePaymentRequestPrices}
          payPal={{
            payPalButtonClicked,
            paypalClientId,
            currency,
            payPalAuthorisationResponse,
            handlePayPalInContextSubmission,
            disablePayPalButton,
            locale
          }}
          bancontact={{
            bancontactSubscriptionCallback,
            disabled: bancontactDisabled
          }}
          ideal={{
            handleIdealOnClick: buySubscriptionCallback,
            handleIdealSelect,
            disabled: idealDisabled
          }}
        />
      )
    }
  })

  const handleOnChange = useCallback(
    (index: number) => {
      const selectedPaymentOption = availablePaymentOptions[index]

      selectedPaymentMethod.type = selectedPaymentOption.value
      selectedPaymentMethod.preselected = false

      checkoutPageState({
        ...checkoutPageState()
      })

      if (selectedPaymentOption.value === 'iDeal') {
        idealButtonClicked()
      } else {
        setActivePaymentView(selectedPaymentOption.value)
      }
      ANALYTICS.paymentOptionsToggle(selectedPaymentOption.value)
    },
    [
      availablePaymentOptions,
      idealButtonClicked,
      selectedPaymentMethod,
      setActivePaymentView
    ]
  )

  const pathnames = getLocalisedPathnames(shippingCountryCode)

  return (
    <section
      ref={section}
      className={`payment-section section ${
        visible ? 'section--visible' : 'section--hidden'
      }`}
    >
      <Card shadow>
        <div className="card-content">
          <div className="card-title">
            <div className="card-title-wrapper">
              <Text
                namespace={namespace}
                text={`${copyContext}.title`}
                element="h2"
                variant="display20"
                align="left"
                margin={false}
              />
            </div>
            <img src={Lock} alt="" />
          </div>
          {showLoadingScreen && (
            <LoadingScreen
              isOpen={showLoadingScreen}
              title={{
                namespace,
                text: 'loading_screen.text',
                variant: payPalPaymentSuccess ? 'display28' : 'display20'
              }}
              variant={'static'}
              image={payPalPaymentSuccess ? dogStanding : dogsMeeting}
            />
          )}
          <Expand show={visible} margin={{ top: 0.8 }}>
            {requiresPayment && activePaymentView !== 'none' && (
              <RadioGroup
                variant="withChildren"
                radioOptions={availablePaymentOptions}
                onChange={handleOnChange}
                identifier="checkout_page.payment_options"
              />
            )}
            {/* Admin payment button view */}
            {!requiresPayment && (
              <div className="checkout__continue-button checkout__continue-button--purchase">
                <Button
                  typography={{
                    namespace,
                    text:
                      submissionState.type === 'loading'
                        ? 'continue_button.loading'
                        : 'continue_button.order'
                  }}
                  disabled={
                    submissionState.type === 'loading' ||
                    (!areAllSectionsValid() && activePaymentView !== 'none')
                  }
                  onClick={buySubscriptionCallback}
                  fullWidth
                  disableAnalytics
                />
              </div>
            )}
            <div className="payment-disclaimer">
              <Text
                namespace={namespace}
                text="disclaimer.title"
                colour="brandBlue400"
                variant="display16"
                margin={false}
                align="left"
                shouldScale={false}
              />
              <Text
                namespace={namespace}
                text="disclaimer.terms_html"
                variables={{
                  termsUrl: `/${pathnames.TERMS_OF_USE}`,
                  termsConditionsUrl: `/${pathnames.TERMS_AND_CONDITIONS}`
                }}
                colour="brandBlue400"
                variant="textRegular16"
                margin={false}
                align="left"
                shouldScale={false}
              />
            </div>
          </Expand>
        </div>
      </Card>
      {bannerMessage.message && (
        <Banner type={bannerMessage.type} message={bannerMessage.message} />
      )}
    </section>
  )
}

export default PaymentSection

export { paymentSubmissionState, setSubmissionState }
