// @noflow
import * as Sentry from '@sentry/browser'
import * as Cookies from 'js-cookie'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Code as CountryCode } from '@/types'
import { ensureNever } from '@/typescript/utils'

type QueriesType = Record<string, React.ReactElement>

type ValidURLParamValue = 'HELP_WITH_MY_DELIVERY'

type QueryTypeCode =
  | 'delivery_changes'
  | 'stop_subscription'
  | 'change_dog_plan'
  | 'update_dog_weight'
  | 'locate_delivery'
  | 'defrosted_pouches'
  | 'recycled_packaging'
  | 'butternut_cost'
  | 'health_suitability'
  | 'delivery_help'
  | 'pets_at_home'
  | 'other_queries'

type Option = {
  value: string
  text: string
}

type Translate = (arg0: string) => string

const candidateParam = new URLSearchParams(window.location.search).get(
  'contact_type'
)
const referrerPage =
  document.referrer !== '' ? new URL(document.referrer).pathname : ''
const referrerUrl = '/dashboard/box_issues' || '/self-resolution'

const toValidURLParamValue = (
  candidate: string | null
): ValidURLParamValue | null => {
  switch (candidate) {
    case 'HELP_WITH_MY_DELIVERY': {
      return referrerPage === referrerUrl ? candidate : null
    }
    default: {
      return null
    }
  }
}

const urlParamToQuery = (
  urlParamValue: ValidURLParamValue
): keyof QueriesType => {
  switch (urlParamValue) {
    case 'HELP_WITH_MY_DELIVERY': {
      return 'delivery_help'
    }
    default: {
      ensureNever(urlParamValue)
      Sentry.captureException(
        `urlParamValue: ${urlParamValue} is not a valid param`
      )
      return 'other_queries'
    }
  }
}

const shippingCountryCode = Cookies.get('user_country_code')

const applicableToUserCountry = (code: QueryTypeCode): boolean => {
  return !(
    code === 'pets_at_home' &&
    shippingCountryCode !== CountryCode.GB &&
    shippingCountryCode !== CountryCode.NI
  )
}

const queryCodeToValue = (code: QueryTypeCode, t: Translate): string => {
  return t(`query_keys.${code}`)
}

const queryCodes: Array<QueryTypeCode> = [
  'delivery_changes',
  'stop_subscription',
  'change_dog_plan',
  'update_dog_weight',
  'locate_delivery',
  'defrosted_pouches',
  'recycled_packaging',
  'butternut_cost',
  'health_suitability',
  'pets_at_home',
  'other_queries'
]

if (
  candidateParam === 'HELP_WITH_MY_DELIVERY' &&
  referrerPage === referrerUrl
) {
  queryCodes.push('delivery_help')
}

const options = (t: Translate): Array<Option> => {
  const queries = queryCodes
    .filter((code) => applicableToUserCountry(code))
    .map((code: QueryTypeCode): Option => {
      return {
        value: code,
        text: queryCodeToValue(code, t)
      }
    })
  return queries
}

const Queries = ({
  code
}: {
  code: QueryTypeCode
}): React.ReactElement | null => {
  const { t } = useTranslation('contacts')
  const copyContext = 'queries'
  switch (code) {
    case 'delivery_changes':
      return (
        <React.Fragment>
          <p
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t(`${copyContext}.delivery_changes.top_text`)
            }}
          />
          <p
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t(`${copyContext}.delivery_changes.bottom_text`)
            }}
          />
        </React.Fragment>
      )
    case 'stop_subscription':
      return (
        <p
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: t(`${copyContext}.stop_subscription.text`)
          }}
        />
      )
    case 'change_dog_plan':
      return (
        <React.Fragment>
          <p
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t(`${copyContext}.change_dog_plan.top_text`)
            }}
          />
          <ul
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t(`${copyContext}.change_dog_plan.bottom_text`)
            }}
          />
        </React.Fragment>
      )
    case 'update_dog_weight':
      return (
        <React.Fragment>
          <p
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t(`${copyContext}.update_dog_weight.top_text`)
            }}
          />
          <p>{t(`${copyContext}.update_dog_weight.bottom_text`)}</p>
        </React.Fragment>
      )
    case 'locate_delivery':
      return (
        <p
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: t(`${copyContext}.locate_delivery.text`)
          }}
        />
      )
    case 'defrosted_pouches':
      return (
        <React.Fragment>
          <p
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t(`${copyContext}.defrosted_pouches.top_text`)
            }}
          />
          <p
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t(`${copyContext}.defrosted_pouches.bottom_text`)
            }}
          />
        </React.Fragment>
      )
    // eslint-disable-next-line quotes
    case 'recycled_packaging':
      return (
        <p
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: t(`${copyContext}.recycled_packaging.text`)
          }}
        />
      )
    case 'butternut_cost':
      return (
        <React.Fragment>
          <p
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t(`${copyContext}.butternut_cost.top_text`)
            }}
          />
          <p>{t(`${copyContext}.butternut_cost.bottom_text`)}</p>
        </React.Fragment>
      )
    case 'health_suitability':
      return (
        <p
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: t(`${copyContext}.health_suitability.text`)
          }}
        />
      )
    case 'delivery_help':
      if (
        candidateParam === 'HELP_WITH_MY_DELIVERY' &&
        referrerPage === referrerUrl
      ) {
        return <p>{t(`${copyContext}.delivery_help.text`)}</p>
      } else {
        return null
      }
    case 'pets_at_home':
      return (
        <p
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: t(`${copyContext}.pets_at_home.text`)
          }}
        />
      )
    case 'other_queries':
      return <p>{t(`${copyContext}.other.text`)}</p>
  }
}

export type { ValidURLParamValue }
export { toValidURLParamValue, urlParamToQuery, queryCodes, options }
export default Queries
