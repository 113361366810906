// @flow

/* eslint-disable react/require-optimization */
/* eslint-disable react/jsx-no-literals */
/* eslint-disable i18next/no-literal-string */
/* eslint-disable react/no-arrow-function-lifecycle */
/* eslint-disable react/destructuring-assignment */

import * as React from 'react'
import BoxSummaryComponent from '../../affiliates/partials/box_summary_component'
import SummaryGridItemComponent from '../../shared/AffiliatesSummaryGridItemComponent'
import ReferralComponent from '../../affiliates/partials/referral_component'
import ShopifyCard from '../partials/ShopifyCard'
import DogInHearts from 'assets/images/illustrations/dogs/dog-in-hearts.svg'
import Box from 'assets/images/illustrations/boxes/exploding-box.svg'
import BarkingDog from 'assets/images/illustrations/dogs/barking-dalmatian.svg'
import PiggyBank from 'assets/images/illustrations/piggy-bank.svg'
import DogPushingShoppingCart from 'assets/images/illustrations/dogs/dog-pushing-shopping-cart.svg'
import i18next from 'i18next'

import type {
  Ambassador,
  Breed
} from '../message_types'

type Props = {|
  ambassador: Ambassador,
  ambassadorShopPath: string,
  setAlertMessage: string => void,
  visible: boolean
|}

class SummaryTab extends React.Component<Props> {
  clientsReferredContent = (ambassador: Ambassador): React.Node => {
    const {
      number_of_clients_referred: numberOfClientsReferred
    } = ambassador.referral_history
    const text = numberOfClientsReferred > 0 ? numberOfClientsReferred.toString() : i18next.t(`${this.namespace}.no_clients_referred`)

    return (
      <div>
        <div className='content-padding__top-bottom-mini'>
          <img alt='' src={Box} />
        </div>
        <p>{ text }</p>
      </div>
    )
  }

  charityContributionsContent = (ambassador: Ambassador): React.Node => {
    const {
      total_amount_donated_to_charity: totalAmountDonatedToCharity,
      formatted_total_amount_donated_to_charity: formattedTotalAmountDonatedToCharity
    } = ambassador.payout_history

    const text = totalAmountDonatedToCharity > 0 ? formattedTotalAmountDonatedToCharity : i18next.t(`${this.namespace}.no_donations`)

    return (
      <div>
        <div className='content-padding__top-bottom-mini'>
          <img alt='A illustrated dog surrounded in a heart.' src={DogInHearts} />
        </div>
        <p>{ text }</p>
      </div>
    )
  }

  moneyEarnedContent = (ambassador: Ambassador): React.Node => {
    const {
      total_amount_earned: totalAmountEarned,
      formatted_total_amount_earned: formattedTotalAmountEarned
    } = ambassador.payout_history

    const text = totalAmountEarned > 0 ? formattedTotalAmountEarned : i18next.t(`${this.namespace}.no_clients_referred`)

    return (
      <div>
        <div className='content-padding__top-bottom-mini'>
          <img alt='' src={PiggyBank} />
        </div>
        <p>{ text }</p>
      </div>
    )
  }

  currentCreditContent = (ambassador: Ambassador): React.Node => {
    const {
      amount,
      formatted_amount: formattedAmount
    } = ambassador.balance

    const text = amount > 0 ? formattedAmount : i18next.t(`${this.namespace}.no_credit`)

    return (
      <React.Fragment>
        <div className='content-padding__top-bottom-mini'>
          <img
            alt=''
            src={DogPushingShoppingCart}
          />
        </div>
        <p>
          { text }
        </p>
      </React.Fragment>
    )
  }

  topBreedsReferredContent = (ambassador: Ambassador): React.Node => {
    const {
      most_popular_breeds: mostPopularBreeds
    } = ambassador.referral_history

    if (mostPopularBreeds.length > 0) {
      return (
        <div className='content-padding__top-bottom-mini'>
          {
            mostPopularBreeds.map((breed: Breed, index: number): React.Node => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <p className='list-item' key={index}>
                  { `${index + 1}: ${breed}` }
                </p>
              )
            })
          }
          <p>{ i18next.t(`${this.namespace}.let_dogs_in`) }</p>
        </div>
      )
    } else {
      return (
        <div>
          <div className='content-padding__top-bottom-mini'>
            <img alt='' src={BarkingDog} />
          </div>
          <p>{ i18next.t(`${this.namespace}.no_clients_referred`)}</p>
        </div>
      )
    }
  }

  namespace = 'ambassadors:dashboard.summary_tab'

  render (): React.Node {
    const affiliate = {
      dog_names: this.props.ambassador.dog_names,
      is_paid_subscription: this.props.ambassador.is_paid_subscription,
      number_of_trial_boxes: this.props.ambassador.number_of_trial_boxes,
      trial_subscription: this.props.ambassador.trial_subscription,
      subscription_status: this.props.ambassador.subscription_status
    }
    return (
      <div className={`visible-${this.props.visible.toString()} tab affiliate-summary`}>
        <div className='section affiliate-overview'>
          { (this.props.ambassadorShopPath) &&
            <ShopifyCard ambassadorShopPath={this.props.ambassadorShopPath} />
          }
          <div className='affiliate-summary__grid content-padding small-padding-top'>
            <SummaryGridItemComponent
              header={ i18next.t(`${this.namespace}.clients`) }
              content={this.clientsReferredContent(this.props.ambassador)}
            />
            <SummaryGridItemComponent
              header={ i18next.t(`${this.namespace}.charity`) }
              content={this.charityContributionsContent(this.props.ambassador)}
            />
            <SummaryGridItemComponent
              header={ i18next.t(`${this.namespace}.credit`) }
              content={this.currentCreditContent(this.props.ambassador)}
            />
            <SummaryGridItemComponent
              header={ i18next.t(`${this.namespace}.earnings`) }
              content={this.moneyEarnedContent(this.props.ambassador)}
            />
            <SummaryGridItemComponent
              header={ i18next.t(`${this.namespace}.top_breeds`) }
              content={this.topBreedsReferredContent(this.props.ambassador)}
            />
            <BoxSummaryComponent
              affiliate={affiliate}
              affiliateType='ambassador'
            />
          </div>
        </div>
        <div className='section affiliate-referral'>
          <ReferralComponent
            affiliateType='ambassador'
            fullName={this.props.ambassador.full_name}
            referralLink={this.props.ambassador.priority_referral_link}
            setAlertMessage={this.props.setAlertMessage}
            // eslint-disable-next-line react/jsx-boolean-value
            includeTitle={true}
            referralSignupCredit={this.props.ambassador.credit_received_on_referral_signup}
          />
        </div>
        <div className='section ambassador-tell-us-views content-padding'>
          <p className='white-text'>
            { i18next.t(`${this.namespace}.your_view`) }
          </p>
          <p className='red-text bold-font'>
            { i18next.t(`${this.namespace}.testimony`) }
          </p>
          <p className='red-text no-margin--bottom'>
            { i18next.t(`${this.namespace}.behaviourist`) }
          </p>
          <p className='red-text'>
            { i18next.t(`${this.namespace}.current_chair`) }
          </p>
          <div className='btn-with-shadow-container'>
            <a
              className='btn btn-red'
              rel="noreferrer"
              target='_blank'
              href='https://uk.trustpilot.com/review/butternutbox.com'
            >
              { i18next.t(`${this.namespace}.throw_words`) }
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default SummaryTab
