import { gql } from '@apollo/client'

const IN_ACCOUNT_RAF_QUERY = gql`
  query InAccountRafQuery {
    user {
      id
      firstName
      shippingCountryCode
      dogs {
        id
        name
        dogProfile {
          id
          avatarUrl
        }
      }
      subscription {
        id
        referralLink {
          referralLinkTypeName
          referralDiscountsTotalValue
          url
          referralDiscount {
            id
            code
            discountCodeParts {
              value
              discountType
              discountBasis
              n
            }
          }
          referrerDiscount {
            applicableDiscountParts {
              value
              discountBasis
              n
              discountType
            }
          }
          referrals {
            refereeFirstName
            refereeDogNames
            refereeSignUpDate
          }
        }
        activeRafCampaignRedeemed
        activeRafCampaignVariant {
          name
          rafCampaign {
            name
            endDate
          }
          view {
            data
          }
        }
      }
    }
    shippingCountries {
      code
      showRafCharityButton
    }
    whatsAppSingleDog: copyEntry(slug: "whatsapp_single_dog") {
      text
    }
    whatsAppMultipleDogs: copyEntry(slug: "whatsapp_multiple_dogs") {
      text
    }
  }
`

export { IN_ACCOUNT_RAF_QUERY }
