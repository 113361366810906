// @noflow
import React from 'react'

import { Button } from '@/components/elements/atoms/Button'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from '../SurveyCard.module.sass'

import type { BaseCardProps } from '../SurveyCard'

type IntroCardProps = BaseCardProps & {
  setModalDismissal: () => void
}

const IntroCard = ({
  currentQuestion,
  setModalDismissal,
  setCurrentQuestionIndex
}: IntroCardProps): JSX.Element => {
  const handleClick = React.useCallback(() => {
    setCurrentQuestionIndex(currentQuestion.order + 1)
  }, [setCurrentQuestionIndex, currentQuestion])

  return (
    <div className={STYLES.surveyCard}>
      <Text
        text={currentQuestion.text}
        element={'h2'}
        variant="display20"
        colour={'brandBlue500'}
        margin
        align={'left'}
        translate={false}
      />
      <div className={STYLES.contentContainer}>
        <div className={STYLES.buttonContainer}>
          <Button
            variant="ghostWhiteText"
            typography={{
              text: 'intro_no',
              namespace: 'surveys'
            }}
            onClick={setModalDismissal}
            disableAnalytics
          />
          <Button
            typography={{
              text: 'intro_yes',
              namespace: 'surveys'
            }}
            variant="secondary"
            onClick={handleClick}
            disableAnalytics
          />
        </div>
      </div>
    </div>
  )
}

export default IntroCard
