// @noflow

/* eslint-disable i18next/no-literal-string */
import React, { useCallback } from 'react'

// Hooks
// Components
import { UpsellEntryPoint } from './components/UpsellEntryPoint'

import { DogProfileQuery_user_dogs as Dog } from '../../queries/__generated__/DogProfileQuery'

type Props = {
  dog?: Dog
  loading?: boolean
  calendlyUrl?: string
}

const HealthSection = ({
  dog,
  loading = false,
  calendlyUrl
}: Props): JSX.Element => {
  const handleNavigateToPetCarePage = useCallback(() => {
    if (calendlyUrl) {
      window.open(calendlyUrl, '_blank', 'noopener,noreferrer')
    }
  }, [calendlyUrl])

  return (
    <UpsellEntryPoint
      handleNavigateToPetCarePage={handleNavigateToPetCarePage}
      loading={loading}
      dog={dog}
    />
  )
}

export { HealthSection }
