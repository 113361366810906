// @noflow
import React, { useCallback } from 'react'

import { AnalyticsProps, trackEvent } from '@/services/segment'

import RadioButton from '@/components/elements/atoms/RadioButton/RadioButton'
import type { Props as RadioButtonProps } from '@/components/elements/atoms/RadioButton/RadioButton'

import STYLES from './RadioGroup.module.sass'

type RadioOption = Omit<RadioButtonProps, 'onChange' | 'optionIndex'>
type ColorVariant = 'brandBlue100' | 'brandYellow200'
type Versions = 'v2'

type Props = {
  variant?: 'fullWidth' | 'white' | 'withChildren' | 'horizontal'
  colorVariant?: ColorVariant
  radioOptions: Array<RadioOption>
  onChange: (index: number, subOptionIndex: number) => void
  className?: string
  version?: Versions
} & AnalyticsProps

const RadioGroup = ({
  variant = 'fullWidth',
  colorVariant = 'brandBlue100',
  radioOptions,
  disableAnalytics = false,
  identifier,
  screenIdentifier = null,
  onChange,
  className = '',
  version
}: Props): JSX.Element => {
  // Handlers
  const handleTrackEvent = useCallback(
    (componentIdentifier: string): void => {
      trackEvent('Component Clicked', {
        component_identifier: componentIdentifier,
        ...(screenIdentifier ? { screen_identifier: screenIdentifier } : {})
      })
    },
    [screenIdentifier]
  )

  const handleChange = React.useCallback(
    (index: number, subOptionIndex: number) => {
      onChange(index, subOptionIndex)
      if (!disableAnalytics && identifier) {
        handleTrackEvent(identifier)
      }
    },
    [disableAnalytics, handleTrackEvent, identifier, onChange]
  )

  return (
    <div
      className={`${STYLES.container} ${version && STYLES[version]} ${
        variant && STYLES[variant]
      } ${colorVariant && STYLES[colorVariant]} ${className}`}
    >
      {radioOptions.map(
        (
          radioButton: Omit<RadioButtonProps, 'onChange' | 'optionIndex'>,
          index: number
        ) => (
          <RadioButton
            id={radioButton.id}
            dataTestId={radioButton.dataTestId}
            key={radioButton.value}
            variant={variant}
            colorVariant={colorVariant}
            text={radioButton.text}
            value={radioButton.value}
            subText={radioButton.subText}
            subOptions={radioButton.subOptions}
            onChange={handleChange}
            defaultChecked={radioButton.defaultChecked}
            optionIndex={index}
            icons={radioButton.icons}
            border={radioButton.border}
            size={radioButton.size}
            iconPosition={radioButton.iconPosition}
          >
            {radioButton.children && radioButton.children}
          </RadioButton>
        )
      )}
    </div>
  )
}

export type { Props, RadioOption }
export default RadioGroup
