// @noflow
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import lotsOfTreats from 'assets/images/illustrations/dogs/snacks/lots-of-treats.svg'
import noTreats from 'assets/images/illustrations/dogs/snacks/no-treats.svg'
import someTreats from 'assets/images/illustrations/dogs/snacks/some-treats.svg'

import Text from '@/components/elements/atoms/Text/Text'
import TextSeparator from '@/components/elements/molecules/TextSeparator/TextSeparator'
import StickyNavigation from '@/components/elements/organisms/StickyNavigation/StickyNavigation'
import {
  routeToNextStep,
  routeToPrevStep
} from '@/components/pages/SignupWizardPage/helpers/wizardRoutes'
import { loadingState } from '@/components/templates/WizardNavigationLayout/WizardNavigationLayout'

import STYLES from './Snacks.module.sass'

// Mutations
import { ADD_DOGS_MUTATION } from '../../queries/addDogsMutation'

import { csrfTokenState, wizardPageState } from '../../SignupWizardPage'
import { trackUserAddedDogAndClickedSeePlan } from '../../helpers/analytics'
import {
  adaptDogsForMutation,
  submitFormData
} from '../../helpers/submitFormData'
import useImplicitFormSubmission from '../../helpers/useImplicitFormSubmission'
import type { Route } from '../../types/routes'
import type { Dog, WizardSnackingHabits } from '../../types/types'

type Props = {
  variant?: keyof typeof STYLES
  namespace: string
  shouldSeePetParentOnPlans: boolean
  isGuest: boolean
}

type SnackingHabitsDescriptionProps = {
  snackingHabit: WizardSnackingHabits
  namespace: string
}

const SnackingHabitDescription = ({
  snackingHabit,
  namespace
}: SnackingHabitsDescriptionProps): JSX.Element => {
  switch (snackingHabit) {
    case 'noSnacks':
      return (
        <div
          className={`${STYLES.snackingHabitDescription} ${STYLES.noSnacks}`}
        >
          <Text
            namespace={namespace}
            text={'snacks_step.no_snacks.title'}
            variant={'textRegular16'}
            colour={'brandBlue500'}
          />
        </div>
      )
    case 'someSnacks':
      return (
        <div
          className={`${STYLES.snackingHabitDescription} ${STYLES.someSnacks}`}
        >
          <Text
            namespace={namespace}
            text={'snacks_step.some_snacks.title'}
            variant={'textRegular16'}
            colour={'brandBlue500'}
          />
          <Text
            namespace={namespace}
            text={'snacks_step.some_snacks.description'}
            variant={'textRegular16'}
            colour={'brandBlue500'}
          />
        </div>
      )
    case 'lotsOfSnacks':
      return (
        <div
          className={`${STYLES.snackingHabitDescription} ${STYLES.lotsOfSnacks}`}
        >
          <Text
            namespace={namespace}
            text={'snacks_step.lots_of_snacks.title'}
            variant={'textRegular16'}
            colour={'brandBlue500'}
          />
          <Text
            namespace={namespace}
            text={'snacks_step.lots_of_snacks.description'}
            variant={'textRegular16'}
            colour={'brandBlue500'}
          />
        </div>
      )
    default: {
      throw new Error(`${snackingHabit} is an invalid snackingHabit`)
    }
  }
}

const Snacks = ({
  variant,
  namespace,
  shouldSeePetParentOnPlans,
  isGuest
}: Props): JSX.Element => {
  const wizardState = useReactiveVar(wizardPageState)
  const { dogs } = wizardState
  const route = useLocation().pathname as Route

  const copyContext = 'snacks_step'
  const { t } = useTranslation(namespace)

  const [addDogs] = useMutation(ADD_DOGS_MUTATION, {
    onCompleted: (data) => {
      window.localStorage.setItem(
        'mealSelectionTypeSwitched',
        JSON.stringify(data.addDogs.mealSelectionTypeSwitched)
      )
      window.location.href = '/plans/recipes'
    }
  })

  const formIsValid = dogs.every((dog: Dog) => dog.snackingHabits !== null)

  const csrfToken = useReactiveVar(csrfTokenState)
  const navigate = useNavigate()

  const nextStep = React.useCallback(() => {
    trackUserAddedDogAndClickedSeePlan()
    loadingState(true)
    addDogs({ variables: { dogs: adaptDogsForMutation(dogs) } })
  }, [addDogs, dogs])

  const navigateToPlans = useCallback(async () => {
    await submitFormData(csrfToken, shouldSeePetParentOnPlans)
  }, [csrfToken, shouldSeePetParentOnPlans])

  const onEnterPress = useCallback(() => {
    if (isGuest) {
      shouldSeePetParentOnPlans
        ? navigateToPlans()
        : navigate(routeToNextStep({ route }))
    } else nextStep()
  }, [
    isGuest,
    navigate,
    navigateToPlans,
    nextStep,
    route,
    shouldSeePetParentOnPlans
  ])

  useImplicitFormSubmission({ formIsValid, onEnterPress })

  const handleClick = useCallback(
    (e, index): void => {
      dogs[index].snackingHabits = e.currentTarget.id
      wizardPageState({
        ...wizardState,
        dogs
      })
    },
    [dogs, wizardState]
  )

  const isGuestContinueButton = shouldSeePetParentOnPlans
    ? 'wizard_flow:sticky_navigation.create_box'
    : 'wizard_flow:sticky_navigation.continue'

  const buttonText = isGuest
    ? isGuestContinueButton
    : 'wizard_flow:sticky_navigation.see_plan'

  return (
    <>
      <div className={variant ? STYLES[variant] : ''}>
        <div className={STYLES.question}>
          {dogs.map(
            (dog: Dog, index: number): JSX.Element => (
              <div className={STYLES.subQuestion} key={index.toString()}>
                <TextSeparator
                  text={`${copyContext}.text_separator`}
                  namespace={namespace}
                  variables={{ dogName: dog.name }}
                />
                <div className={STYLES.snackingHabitsRow}>
                  <button
                    className={
                      dog.snackingHabits === 'noSnacks'
                        ? `${STYLES.snackingHabit} ${STYLES.selected}`
                        : `${STYLES.snackingHabit}`
                    }
                    type="button"
                    id={'noSnacks'}
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={(e) => handleClick(e, index)}
                  >
                    <img
                      className={STYLES.defaultImage}
                      alt={t(`${copyContext}.no_snacks.img_alt`)}
                      src={noTreats}
                    />
                    <img
                      className={STYLES.selectedImage}
                      alt={t(`${copyContext}.no_snacks.img_alt`)}
                      src={noTreats}
                    />
                  </button>
                  <button
                    type={'button'}
                    className={
                      dog.snackingHabits === 'someSnacks'
                        ? `${STYLES.snackingHabit} ${STYLES.selected}`
                        : `${STYLES.snackingHabit}`
                    }
                    id={'someSnacks'}
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={(e) => handleClick(e, index)}
                  >
                    <img
                      className={STYLES.defaultImage}
                      alt={t(`${copyContext}.some_snacks.img_alt`)}
                      src={someTreats}
                    />
                    <img
                      className={STYLES.selectedImage}
                      alt={t(`${copyContext}.some_snacks.img_alt`)}
                      src={someTreats}
                    />
                  </button>
                  <button
                    type="button"
                    className={
                      dog.snackingHabits === 'lotsOfSnacks'
                        ? `${STYLES.snackingHabit} ${STYLES.selected}`
                        : `${STYLES.snackingHabit}`
                    }
                    id={'lotsOfSnacks'}
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={(e) => handleClick(e, index)}
                  >
                    <img
                      className={STYLES.defaultImage}
                      alt={t(`${copyContext}.lots_of_snacks.img_alt`)}
                      src={lotsOfTreats}
                    />
                    <img
                      className={STYLES.selectedImage}
                      alt={t(`${copyContext}.lots_of_snacks.img_alt`)}
                      src={lotsOfTreats}
                    />
                  </button>
                </div>
                {dog.snackingHabits && (
                  <SnackingHabitDescription
                    namespace={namespace}
                    snackingHabit={dog.snackingHabits}
                  />
                )}
              </div>
            )
          )}
        </div>
      </div>
      <StickyNavigation
        variant="twoButtons"
        buttonOne={{
          url: routeToPrevStep({ route }),
          text: 'wizard_flow:sticky_navigation.back',
          variant: 'secondary',
          iconColour: 'brandRed400'
        }}
        buttonTwo={{
          dataTestId: 'next-button',
          text: buttonText,
          variant: 'primary',
          iconColour: 'brandWhite',
          onClick: () => onEnterPress()
        }}
        disabled={!formIsValid}
      />
    </>
  )
}

export { Props, SnackingHabitDescription }
export default Snacks
