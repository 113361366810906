// @noflow
import React from 'react'

import {
  ICON_COLOURS,
  IconColours
} from '@/components/elements/atoms/Icon/Icon'

type Props = {
  size: number
  accentColour: IconColours
  width?: number | string
}

const Pause = ({ size, accentColour, width }: Props): JSX.Element => {
  return (
    <svg
      height={size}
      width={width || '100%'}
      viewBox="0 0 11 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.3 3c.067.868.043 1.744-.014 2.611-.05.768-.169 1.54-.169 2.31 0 .094.036.188.05.279.065.39.099.786.139 1.179.06.583.049 1.17.09 1.755.006.096.04.193.032.29-.009.11-.044.223-.066.33-.089.43-.186.858-.258 1.29-.086.513-.097 1.027-.097 1.545 0 .058-.032.193.037.216M9 3c-.025.886-.25 1.79-.371 2.673-.106.775-.156 1.567-.355 2.334-.031.12-.052.25-.109.365-.029.058-.127.077-.142.13-.066.235.028.54.055.772.064.55.144 1.099.207 1.648.105.912.23 1.853.172 2.77-.027.434-.145.875-.145 1.308"
        stroke="#432918"
        strokeOpacity=".3"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M2.3 2c.067.868.043 1.744-.014 2.611-.05.768-.169 1.54-.169 2.31 0 .094.036.188.05.279.065.39.099.786.139 1.179.06.583.049 1.17.09 1.755.006.096.04.193.032.29-.009.11-.044.223-.066.33-.089.43-.186.858-.258 1.29-.086.513-.097 1.027-.097 1.545 0 .058-.032.193.037.216M8 2c-.025.886-.25 1.79-.371 2.673-.106.775-.156 1.567-.355 2.334-.031.12-.052.25-.109.365-.029.058-.127.077-.142.13-.066.235.028.54.055.772.064.55.144 1.098.207 1.648.105.912.23 1.853.172 2.77-.027.434-.145.875-.145 1.308"
        stroke={ICON_COLOURS[accentColour]}
        strokeOpacity=".5"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default Pause
