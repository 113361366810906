// @noflow
import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useContext, useMemo } from 'react'

import { formatCurrencyWithDecimal } from '@/utils/currency'

import useButternutBoost from '@/hooks/useButternutBoost'

import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './ProductVariant.module.sass'

import type { ProductCollectionQuery_productCollection_productVariants as ProductVariantType } from '../../../queries/__generated__/ProductCollectionQuery'

import type { VariantDelivery } from '../../../../../../../../types/index'
import { ProductCollectionContext } from '../../../ProductCollection'

type Props = {
  productVariant: ProductVariantType
  selectedProductVariantId: ProductVariantType['id']
  selectedProductVariantFrequency: VariantDelivery
  setSelectedProductVariantId: (id: ProductVariantType['id']) => void
  setSelectedProductVariantSlug: (slug: ProductVariantType['slug']) => void
  setSelectedProductVariantFrequency: (variantDelivery: VariantDelivery) => void
  unavailable?: boolean
}

const ProductVariant = ({
  productVariant: { id, name, productVariantDeliveryTypes, bundleItems, slug },
  setSelectedProductVariantId,
  selectedProductVariantId,
  selectedProductVariantFrequency,
  setSelectedProductVariantFrequency,
  unavailable = false,
  setSelectedProductVariantSlug
}: Props): JSX.Element => {
  const { locale, currency, isUpdateProductInProgress } = useContext(
    ProductCollectionContext
  )

  const { boostedSubscription } = useButternutBoost()

  const productVariantDeliveryType = productVariantDeliveryTypes.find(
    ({ deliveryType }) => deliveryType === selectedProductVariantFrequency
  )

  const { netPrice, boostedNetPrice } = useMemo(() => {
    if (!productVariantDeliveryType) {
      return {
        netPrice: null
      }
    }

    const { netPrice, boostedNetPrice } = productVariantDeliveryType

    return {
      netPrice,
      boostedNetPrice
    }
  }, [productVariantDeliveryType])

  /**
   * Formatted net price with a discount if applicable.
   * Price adjustment is taken into the account.
   */
  const formattedNetPrice = formatCurrencyWithDecimal(netPrice || 0, {
    locale,
    currency
  })

  const formattedNetBoostPrice = formatCurrencyWithDecimal(
    boostedNetPrice || 0,
    {
      locale,
      currency
    }
  )

  const isProductVariantSelected = useMemo(
    () => selectedProductVariantId === id,
    [selectedProductVariantId, id]
  )

  // Handlers
  const handleSetSelectedProductVariantId = useCallback((): void => {
    setSelectedProductVariantId(id)
    setSelectedProductVariantSlug(slug)

    /**
     * If the product variant is not available for the selected
     * delivery type, then select the first available delivery type.
     */
    if (!productVariantDeliveryType) {
      const [firstAvailableFrequency] = productVariantDeliveryTypes

      setSelectedProductVariantFrequency(firstAvailableFrequency.deliveryType)
    }
  }, [
    setSelectedProductVariantId,
    id,
    productVariantDeliveryTypes,
    productVariantDeliveryType,
    setSelectedProductVariantFrequency,
    setSelectedProductVariantSlug,
    slug
  ])

  const discountedPriceColour = useMemo(() => {
    if (unavailable) return 'grey400'
    return 'brandBlue500'
  }, [unavailable])

  const formattedTotalBundleSavings = useMemo(() => {
    if (!bundleItems || isEmpty(bundleItems)) return null

    const bundleItemsGrossPrice = bundleItems.reduce((acc, item) => {
      return acc + item.constituent.grossPrice * item.quantity
    }, 0)

    const totalSavings = bundleItemsGrossPrice - (netPrice || 0)

    return formatCurrencyWithDecimal(totalSavings, {
      locale,
      currency
    })
  }, [bundleItems, netPrice, locale, currency])

  return (
    <div
      className={`${STYLES.wrapper} ${
        isProductVariantSelected ? STYLES.selected : ''
      } ${isUpdateProductInProgress ? STYLES.disabled : ''}
        ${unavailable ? STYLES.unavailable : ''}`}
    >
      <button
        type="button"
        className={STYLES.container}
        onClick={handleSetSelectedProductVariantId}
        disabled={isUpdateProductInProgress}
      >
        <div className={STYLES.info}>
          <Text
            element="p"
            translate={false}
            text={name}
            variant="textRegular16"
            shouldScale={false}
            margin={false}
            colour={unavailable ? 'grey400' : 'brandBlue500'}
            bold
          />
          <Text
            element="p"
            translate={false}
            variant="textRegular16"
            text={
              boostedSubscription ? formattedNetBoostPrice : formattedNetPrice
            }
            colour={discountedPriceColour}
            shouldScale={false}
            margin={false}
          />
        </div>
        {formattedTotalBundleSavings && !boostedSubscription && (
          <div className={STYLES.saving}>
            <Text
              element="p"
              namespace="dashboard"
              text={'extras.product_collection.details.product_variant.saving'}
              variables={{ formattedSaveAmount: formattedTotalBundleSavings }}
              variant="textRegular14"
              colour={unavailable ? 'grey400' : 'brandBlue500'}
              margin={false}
              shouldScale={false}
            />
          </div>
        )}
      </button>
    </div>
  )
}

export default ProductVariant
