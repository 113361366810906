// @noflow
import { ACCOUNT_ROUTES } from '@/routes'
import { useQuery } from '@apollo/client'
import { Grid } from '@mui/material'
import i18next from 'i18next'
import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { pronounContext } from '@/utils/StringHelper'
import * as Sentry from '@/utils/sentry'

import UsePlan from '@/hooks/usePlan/usePlan'
import useWindowSize from '@/hooks/useWindowSize'

import { CardSkeleton } from '@/components/elements/atoms/Card/CardSkeleton'
import Image from '@/components/elements/atoms/Image/Image'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import { Indent } from '@/components/elements/atoms/SectionWrapper/SectionWrapper'
import Separator from '@/components/elements/atoms/Separator/Separator'
import SkeletonTitle from '@/components/elements/atoms/SkeletonTitle/SkeletonTitle'
import Text from '@/components/elements/atoms/Text'
import IconCard from '@/components/elements/molecules/IconCard/IconCard'
import SubscriptionDetailsCard from '@/components/elements/molecules/SubscriptionDetailsCard/SubscriptionDetailsCard'

import STYLES from './PlanManagementPageV3.module.sass'

import { PLAN_MANAGEMENT_PAGE_V3_QUERY } from './queries/PlanManagementPageV3Query'

import { PlanManagementPageV3Query } from './queries/__generated__/PlanManagementPageV3Query'

import { BREAKPOINTS } from '../App/App'

type PlanManagementSectionProps = {
  id: string
  path: string
  imageSlug: string
  imageHeight: number
  imageWidth: number
  namespace: string
  dogs: PlanManagementPageV3Query['user']['dogs']
}

const PlanManagementSection = ({
  id,
  path,
  namespace,
  dogs,
  imageSlug,
  imageHeight,
  imageWidth
}: PlanManagementSectionProps) => {
  const navigate = useNavigate()

  // eslint-disable-next-line i18next/no-literal-string
  const copyContext = `plan_management.sections_v2.${id}`
  // eslint-disable-next-line i18next/no-literal-string
  const imageAlt = `${copyContext}.imageAlt`

  const dogGenders = dogs?.map((dog) => dog.gender)
  const dogPronoun = pronounContext(dogGenders || [], i18next.language)

  const onClick = useCallback(() => {
    navigate(path)
  }, [navigate, path])

  return (
    <div>
      <Text
        text={`${copyContext}.title`}
        variant="display18"
        namespace={namespace}
        variables={{
          dogName: dogs && dogs?.length > 0 ? dogs[0].name : '',
          count: (dogs && dogs.length) || 0
        }}
        margin={false}
      />
      <SectionWrapper margin={0} padding={{ top: 8, bottom: 32 }}>
        <IconCard
          onClick={onClick}
          padding={16}
          fill={false}
          identifier={copyContext}
        >
          <div className={STYLES.sectionContent}>
            <Image
              alt={imageAlt}
              slug={imageSlug}
              image={{
                width: imageWidth,
                height: imageHeight,
                resizeMode: 'resize_to_fill',
                retina: true
              }}
            />
            <div className={STYLES.sectionText}>
              <Text
                text={`${copyContext}.subtitle`}
                margin={false}
                namespace={namespace}
                variables={{
                  context: dogPronoun
                }}
              />
              <Text
                text={`${copyContext}.description`}
                namespace={namespace}
                margin={false}
                colour="brandBlue400"
                variant="textRegular14"
              />
            </div>
          </div>
        </IconCard>
      </SectionWrapper>
    </div>
  )
}

const sections: Omit<PlanManagementSectionProps, 'namespace' | 'dogs'>[] = [
  {
    id: 'size',
    path: ACCOUNT_ROUTES.servingSize,
    imageSlug: 'duck-and-chicken-bowl',
    imageHeight: 48,
    imageWidth: 48
  },
  {
    id: 'frequency',
    path: ACCOUNT_ROUTES.deliveryFrequency,
    imageSlug: 'open-box-of-butternut-meals-illustration',
    imageHeight: 40,
    imageWidth: 35
  }
]

const PlanManagementPageV3 = (): JSX.Element => {
  const { windowWidth } = useWindowSize()
  const isMobile = windowWidth < BREAKPOINTS.md

  const { canChangeDeliveryFrequency, loading: planLoading } = UsePlan()
  const namespace = 'account'

  const actionCardPadding = useMemo((): Indent => {
    switch (true) {
      case windowWidth < BREAKPOINTS.sm:
        return {
          top: 32,
          left: 16,
          right: 16,
          bottom: 16
        }
      case windowWidth < BREAKPOINTS.md:
        return {
          top: 32,
          left: 24,
          right: 24,
          bottom: 16
        }
      default:
        return {
          top: 32,
          left: 32,
          right: 32,
          bottom: 0
        }
    }
  }, [windowWidth])

  const { data } = useQuery<PlanManagementPageV3Query>(
    PLAN_MANAGEMENT_PAGE_V3_QUERY,
    {
      onError: (error) => {
        Sentry.captureException(
          `Error with PLAN_MANAGEMENT_PAGE_V3_QUERY query`,
          {
            extra: { error },
            tags: {
              product: Sentry.Product.Account
            }
          }
        )
      }
    }
  )

  return (
    <div className={STYLES.pageWrapper}>
      {isMobile && <Separator solid bold={false} />}
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12} md={4}>
          <SectionWrapper margin={{ top: isMobile ? 24 : 0, bottom: 0 }}>
            <SubscriptionDetailsCard namespace="account" variant="noCard" />
          </SectionWrapper>
        </Grid>
        <Grid item xs={12} md={8} rowSpacing={0}>
          <div className={STYLES.actionCard}>
            <SectionWrapper
              bgColour="brandYellow200"
              padding={actionCardPadding}
              borderRadius={isMobile ? 0 : 16}
              margin={{ top: isMobile ? 8 : 0, bottom: 0 }}
            >
              {planLoading ? (
                <div className={STYLES.sectionsWrapper}>
                  <div>
                    <SkeletonTitle height={28} width={350} align="left" />
                    <SectionWrapper margin={0} padding={{ top: 8, bottom: 32 }}>
                      <CardSkeleton height={80} />
                    </SectionWrapper>
                  </div>
                  <div>
                    <SkeletonTitle height={28} width={350} align="left" />
                    <SectionWrapper margin={0} padding={{ top: 8, bottom: 32 }}>
                      <CardSkeleton height={isMobile ? 98 : 80} />
                    </SectionWrapper>
                  </div>
                </div>
              ) : (
                <div className={STYLES.sectionsWrapper}>
                  {sections.map(
                    (
                      props: Omit<
                        PlanManagementSectionProps,
                        'dogs' | 'namespace'
                      >
                    ) => {
                      if (
                        !canChangeDeliveryFrequency &&
                        props.id === 'frequency'
                      )
                        return null

                      return (
                        <PlanManagementSection
                          key={props.id}
                          {...props}
                          namespace={namespace}
                          dogs={data?.user.dogs || []}
                        />
                      )
                    }
                  )}
                </div>
              )}
            </SectionWrapper>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default PlanManagementPageV3
