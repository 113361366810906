// @flow

import * as React from 'react'
import FacebookIcon from '../../../../assets/images/icons/social-media/icon-facebook.svg'
import EmailIcon from '../../../../assets/images/icons/social-media/icon-email.svg'
import WhatsAppIcon from '../../../../assets/images/icons/social-media/icon-whatsapp.svg'
import CopyLinkIcon from '../../../../assets/images/icons/copy-link.svg'
import { Analytics } from '../../analytics/Analytics'
import { useTranslation } from 'react-i18next'

// types
import type { ReferralCategory } from '../../ambassador_dashboard/partials/give_get_referral_component'

type Props = {|
  referralType: string,
  referralLink: string,
  referralCategory: ?ReferralCategory,
  affiliateType: string,
  copyReferralLinkCallback: (string) => void,
  partialLocation: string,
  activeElement?: () => void
|}

const AffiliatesReferralLinkAndShareComponent = (props: Props): React.Node => {
  const { referralType, referralLink, referralCategory, affiliateType } = props
  const { t } = useTranslation('ambassadors')
  const namespace = 'dashboard.referral_component'

  const referralLinkEvent = (context: string, object: string, action: string): void => {
    const event: string = 'Referral Link Copy Share Event'
    const properties = { context, object, action }
    Analytics.track(event, properties)
  }

  const getDiscountType = React.useCallback((): string => {
    if (affiliateType === 'influencer') {
      return '50%'
    } else {
      switch (referralType) { //  when affiliate is ambassador check referralType
        case 'one_hundred_off_by_default':
          return t(`${namespace}.free_box`)
        case 'fifty_percent_off_first_two':
          return t(`${namespace}.fifty_off_first_two`)
        case 'twenty_five_percent_off_one_twenty_five_percent_off_two':
          return t(`${namespace}.twenty_five_off_first_two`)
        case 'seventy_five_percent_off_one_twenty_five_percent_off_two':
          return  t(`${namespace}.seventy_five_twenty_five_off`)
        default:
          return '75%'
      }
    }
  }, [referralType, affiliateType, t])

  const getDiscountMessage = React.useCallback((): string => {
    if (affiliateType === 'influencer') {
      return t(`${namespace}.fifty_off_discount`)
    } else {
      switch (referralType) { //  when affiliate is ambassador check referralType
        case 'one_hundred_off_by_default':
          return t(`${namespace}.free_box_code`)
        case 'fifty_percent_off_first_two':
          return t(`${namespace}.code_for_fifty_off_two`)
        case 'twenty_five_percent_off_one_twenty_five_percent_off_two':
          return t(`${namespace}.code_for_twenty_five_off_two`)
        case 'seventy_five_percent_off_one_twenty_five_percent_off_two':
          return t(`${namespace}.code_for_seventy_five_twenty_five_off`)
        default:
          return t(`${namespace}.code_for_seventy_five_off`)
      }
    }
  }, [referralType, affiliateType, t])

  const copyReferralLinkButtonWasClicked = React.useCallback((e: SyntheticEvent<HTMLButtonElement>): void => {
    const { referralLink, activeElement, partialLocation, copyReferralLinkCallback } = props
    e.currentTarget.blur()
    let elem = document.getElementsByClassName(`input-${referralLink}`)[0]
    if (partialLocation === 'thank-you' && activeElement) {
      elem = activeElement()
    }
    if (!elem) {
      copyReferralLinkCallback(t(`${namespace}.couldnt_copy`))
      return
    }
    // eslint-disable-next-line flowtype/no-flow-fix-me-comments
    // $FlowFixMe
    elem.select()
    const range = document.createRange()
    range.selectNodeContents(elem)
    const selection = window.getSelection()
    selection.removeAllRanges()
    selection.addRange(range)
    // eslint-disable-next-line flowtype/no-flow-fix-me-comments
    // $FlowFixMe
    elem.setSelectionRange(0, 999999)

    const success = document.execCommand('copy')

    if (success) {
      copyReferralLinkCallback(t(`${namespace}.copied`))
    } else {
      copyReferralLinkCallback(t(`${namespace}.couldnt_copy`))
    }

    referralLinkEvent(
      `${partialLocation} - referralLink`,
      'copyLinkButton',
      'Clicked'
    )
  }, [props, t])

  const getEmailMessage = React.useCallback((): string => {
    const subject = encodeURI(t(`${namespace}.email_subject`))
    const bodyFirstP = encodeURI(t(`${namespace}.email_body_first`))
    const bodySecondP = encodeURI(t(`${namespace}.email_body_second`, { discountMessage: getDiscountMessage(), referralLink }))
    return `mailto:?subject=${subject}&body=${bodyFirstP}%0D%0A%0D%0A${bodySecondP}`
  }, [getDiscountMessage, referralLink, t])

  const shareByMailClicked = React.useCallback((): void => {
    const { partialLocation } = props
    const url = getEmailMessage()
    const w = window.open(url, '_blank')
    w.focus()
    referralLinkEvent(
      `${partialLocation} - email`,
      'shareButton',
      'Clicked'
    )
  }, [getEmailMessage, props])

  const shareOnWhatsAppClicked = React.useCallback((): void => {
    const { partialLocation } = props
    const discountType = getDiscountType()
    const whatsAppMessage = encodeURI(t(`${namespace}.whatsapp_message`, { discountType, referralLink }))
    const url = `https://api.whatsapp.com/send?phone=&text=${whatsAppMessage}&source=&data=`
    const w = window.open(url, '_blank')
    w.focus()
    referralLinkEvent(
      `${partialLocation} - whatsApp`,
      'shareButton',
      'Clicked'
    )
  }, [props, referralLink, getDiscountType, t])

  const shareOnFacebookClicked = React.useCallback((): void => {
    const { partialLocation, referralLink } = props
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(referralLink)}`
    const w = window.open(url, '_blank')
    w.focus()
    referralLinkEvent(
      `${partialLocation} - facebook`,
      'shareButton',
      'Clicked'
    )
  }, [props])

  const getButtonText = (): string => {
    if (affiliateType === 'ambassador') {
      if (referralCategory === 'priority') {
        return t(`${namespace}.copy_client_link`)
      }
      if (referralCategory === 'social') {
        return t(`${namespace}.copy_follower_link`)
      }
      return t(`${namespace}.copy_link`)
    }
    return t(`${namespace}.copy_link`)
  }

  return (
    <div className='affiliate-referral-link-and-buttons'>
      <input
        className={`input-${referralLink} referral-link__textarea`}
        value={referralLink}
        readOnly
      />
      { affiliateType === 'ambassador'
        ? (
          <button
            className='referral-link__button referral-link__button--email referral-link__client-button btn btn-updated btn-updated--red'
            type='button'
            onClick={copyReferralLinkButtonWasClicked}
          >
            <img
              className='referral-link__copy-icon'
              src={CopyLinkIcon}
              alt={'Copy Link Icon'}
            />
            { getButtonText() }
          </button>
          )
        : (
          <button
            className='referral-link__copy-button btn-updated--blue btn-updated'
            type='button'
            onClick={copyReferralLinkButtonWasClicked}
          >
            { t(`${namespace}.copy`) }
          </button>
          ) }
      { affiliateType === 'ambassador'
        ? (
          <div className='referral-link__text'>
            { t(`${namespace}.share_link_via`) }
          </div>
          )
        : (
          <h3 className='referral-link__section-title'>
            { t(`${namespace}.share_link_on`) }
          </h3>
          ) }
      <div className='referral-buttons-container'>
        {
          (referralCategory === 'priority' || affiliateType === 'influencer') && (
            <React.Fragment>
              <button
                className='referral-link__button referral-link__button--email btn btn-updated btn-updated--red priority-button'
                onClick={shareByMailClicked}
                target='_blank'
                rel='noopener noreferrer'
                name='email-button'
                type='button'
              >
                <img
                  src={EmailIcon}
                  alt={'Email icon'}
                />
                { t(`${namespace}.email`) }
              </button>
              <button
                target='_blank'
                name='WhatsApp-button'
                rel='noopener noreferrer'
                className='referral-link__button referral-link__button--whatsapp btn btn-updated btn-updated--red priority-button'
                type='button'
                onClick={shareOnWhatsAppClicked}
              >
                <img
                  src={WhatsAppIcon}
                  alt={'whatsApp icon'}
                />
                { `WhatsApp` }
              </button>
            </React.Fragment>
          )
        }
        {
          (referralCategory === 'social' || affiliateType === 'influencer') && (
            <button
              className='referral-link__button referral-link__button--facebook btn btn-updated btn-updated--red'
              name='Facebook-button'
              type='button'
              onClick={shareOnFacebookClicked}
            >
              <img
                src={FacebookIcon}
                alt={'facebook icon'}
              />
              <span>
                { `Facebook` }
              </span>
            </button>
          )
        }
      </div>
    </div>
  )
}

export default AffiliatesReferralLinkAndShareComponent
