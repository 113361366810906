// @noflow
import React from 'react'
import { ToastOptions } from 'react-toastify'

import Close from 'assets/images/icons/crosses/close-bold.svg'

import Text from '@/components/elements/atoms/Text/Text'
import type { Props as TextProps } from '@/components/elements/atoms/Text/Text'
import CloudinaryImage from '@/components/shared/elements/CloudinaryImage/CloudinaryImage'

import STYLES from './NotificationContent.module.sass'

import {
  Error,
  Info,
  Success,
  Warn
} from '../../atoms/InPageNotification/toastify'

type Type = 'success' | 'error' | 'warn' | 'info'

type ExtendedToastOptions = { showClose?: boolean } & ToastOptions
/**
 * A React component for a customizable close button, typically used in toast notifications.
 *
 * @param closeToast - A callback function to handle closing the toast notification.
 * @returns A JSX.Element representing the close button.
 */
const CloseButton = ({
  closeToast
}: {
  closeToast: () => void
}): JSX.Element => (
  <button className={STYLES.close} type="button" onClick={closeToast}>
    <img src={Close} alt="" />
  </button>
)

/**
 * Generates toast configuration options based on provided parameters.
 *
 * @param options - An object containing extended toast options.
 * @param options.autoClose - True or Duration in milliseconds for auto-closing the toast. Defaults to `5000` if not specified.
 * @param options.showClose - A boolean indicating whether the close button should be displayed.
 * @returns An object containing toast options, such as `closeButton`, `autoClose`, and `className`.
 */
const options = (options: ExtendedToastOptions = {}) => {
  const { autoClose, showClose } = options

  return {
    closeButton: showClose ? CloseButton : false,
    autoClose: showClose ? false : autoClose ?? 5000,
    className: STYLES.v2
  }
}

/**
 * A collection of variant configurations for toast notifications based on the type.
 *
 * Each variant includes specific options such as icons and applies the base options.
 *
 * @typeParam Type - The type of the toast notification (e.g., `success`, `error`, `warn`, `info`).
 * @typeParam ExtendedToastOptions - An interface that extends the base toast options with additional properties.
 *
 * @example
 * // Example usage:
 * V2Variant.success({ autoClose: 3000, showClose: true });
 */
const V2Variant: Record<Type, (props?: ExtendedToastOptions) => ToastOptions> =
  {
    success: (props) => ({
      ...options(props),
      icon: Success,
      ...props
    }),
    error: (props) => ({
      ...options(props),
      icon: Error,
      ...props
    }),
    warn: (props) => ({
      ...options(props),
      icon: Warn,
      ...props
    }),
    info: (props) => ({
      ...options(props),
      icon: Info,
      ...props
    })
  }

type Props = {
  copy: TextProps
  imagePath?: string
}

const NotificationContent = ({ copy, imagePath }: Props): JSX.Element => (
  <React.Fragment>
    {imagePath && (
      <CloudinaryImage
        alt=""
        className={STYLES.image}
        image={{
          path: imagePath,
          crop: 'fill',
          width: 50,
          height: 50,
          dpr: 2
        }}
      />
    )}
    <Text
      text={copy.text}
      variant="textRegular14"
      translate={copy.translate}
      namespace={copy.namespace}
      margin={false}
      variables={copy.variables}
    />
  </React.Fragment>
)

export { Props, V2Variant }
export default NotificationContent
