import { gql } from '@apollo/client'

import {
  ADDRESS_FRAGMENT,
  USER_ADDRESS_FRAGMENT
} from '../fragments/addressFragment'

const UPDATE_DEFAULT_ADDRESS = gql`
  ${ADDRESS_FRAGMENT}
  ${USER_ADDRESS_FRAGMENT}
  mutation UpdateDefaultAddress(
    $userId: ID!
    $address: AddressInput!
    $num: Int!
  ) {
    response: addressUpdate(userId: $userId, address: $address) {
      ...UserAddressFragment
      subscription {
        nextNBoxes(num: $num) {
          id
          address {
            ...AddressFragment
          }
          isoDeliveryDate
        }
      }
    }
  }
`

export { UPDATE_DEFAULT_ADDRESS }
