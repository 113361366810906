import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import DogEating from 'assets/images/illustrations/dogs/ds-app-dog-eating.svg'

import AccountManagement from '../../components/CompletionComponents/AccountManagement/AccountManagement'
import AppSMSComponent from '../../components/CompletionComponents/AppSMSComponent/AppSMSComponent'
import RAFSMSComponent from '../../components/CompletionComponents/RafSMSComponent/RAFSMSComponent'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './CompleteScreen.module.sass'

import { PaymentPageContext } from '../../PayOnOwnDevicePage'

const CompleteScreen = ({
  namespace
}: {
  namespace: string
}): JSX.Element | null => {
  const { paymentPageState } = useContext(PaymentPageContext)
  const { data } = paymentPageState || {}
  const { directSalesPendingSubscription } = data || {}
  const { pendingSubscription } = directSalesPendingSubscription || {}

  const { t } = useTranslation(namespace)
  const orderNumber = 1
  return (
    <div className={STYLES.scrollContainer}>
      <div className={STYLES.confirmationContainer}>
        <Text
          namespace={namespace}
          text={'complete_screen.title'}
          variant={'display28'}
          variables={{ name: pendingSubscription?.user.firstName }}
          align={'left'}
          bold
        />
        <Text
          namespace={namespace}
          text={'complete_screen.order_number'}
          variant={'display16'}
          align={'left'}
          variables={{ orderNumber: orderNumber }}
          colour={'brandBlue400'}
        />
        <div className={STYLES.emailConfirmation}>
          <Text
            namespace={namespace}
            text={'complete_screen.email_confirmation'}
            variant={'textRegular16'}
            align={'left'}
            variables={{
              email: pendingSubscription?.user.email
            }}
          />
        </div>
        <div className={STYLES.imageContainer}>
          <img src={DogEating} alt={t('complete_screen.dog_eating')} />
        </div>
      </div>
      <AppSMSComponent namespace={namespace} />
      <RAFSMSComponent namespace={namespace} />
      <AccountManagement namespace={namespace} />
    </div>
  )
}

export default CompleteScreen
