// @flow

import * as ACTIONS from '../actions'

import type { Dispatch, Thunk } from 'redux'
import type { RailsModelID as ID } from '../../../shared_types/ids'
import type { State } from '../reducers'

type DogIdDescription = {|
  dogId: ID,
  description: string
|}

type ResponseBody = {|
  data?: {
    id: ID
  },
  errors?: [string]
|}

const { fetch } = global

/**
 * Creates a dog profile if they do not have one
 * Updates the description on a users dog profiles
 *
 * Body: {
 *   token: Matches User#token in the Postgres schema
 *   query: GraphQL mutation to add treats to a subscription
 * }
 *
 * Response: {
 *    data: { Present if the request succeeded
 *      DescriptionsUpdate: {
 *        subscription: The User Subscription Type
 *      }
 *    }
 *    error: Present if the request failed
 * }
 */
const descriptionsUpdate = (): Thunk => {
  return (dispatch: Dispatch, getState: () => State): Promise<void> => {
    dispatch(ACTIONS.startRequest('descriptionsUpdate'))
    const { globalAttributes, dogProfiles } = getState()
    const { token, csrfToken, userID } = globalAttributes

    // Ensure that the userID is a number
    if (typeof userID !== 'number') {
      throw new Error(`User ID (${userID}) should be of type number`)
    }

    const descriptionsInput: Array<DogIdDescription> = Object.keys(dogProfiles)
      // $FlowFixMe
      .map((dogId: ID): DogIdDescription => {
        return {
          dogId: dogId,
          description: dogProfiles[dogId].description
        }
      })

    const endpoint = '/graphql'

    const query = `
      mutation DescriptionsUpdate(
        $userId: ID!,
        $descriptionsInput: [DescriptionInput!]!
      ) {
        descriptionsUpdate(
          userId: $userId,
          descriptionsInput: $descriptionsInput
        ) {
          id
        }
      }
    `
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    }
    const method = 'POST'
    const credentials = 'same-origin'
    const body = JSON.stringify({
      token,
      query,
      variables: {
        userId: userID,
        descriptionsInput: descriptionsInput
      }
    })

    return new Promise((resolve: () => void): void => {
      fetch(endpoint, { headers, method, credentials, body })
        .then((res: Response): void => {
          res.json().then(({ data, errors }: ResponseBody): void => {
            if (!errors) {
              if (!data) {
                throw new Error(`There was no error but data is empty`)
              }
              dispatch(ACTIONS.completeRequest(
                'descriptionsUpdate',
                {}
              ))
              resolve()
            } else {
              dispatch(ACTIONS.completeRequest(
                'descriptionsUpdate',
                {
                  error: `Error for user with id ${userID}: ${errors.join()}`
                }
              ))
              resolve()
            }
          })
        })
    })
  }
}

export default descriptionsUpdate
