// @noflow
import { isUndefined } from 'lodash'
import React, { useMemo } from 'react'

import { AnalyticsProps } from '@/services/segment'

import SkeletonLoading from '@/components/elements/atoms/SkeletonLoading/SkeletonLoading'
import DetailsCard, {
  HrefDetailCardProps,
  NonClickableDetailCardProps,
  OnClickDetailCardProps
} from '@/components/elements/molecules/DetailsCard/DetailsCard'

import STYLES from './AccountDetailsCard.module.sass'

import DetailsCardSkeleton, {
  Props as DetailsCardSkeletonProps
} from '../DetailsCardSkeleton/DetailsCardSkeleton'

type HrefAccountDetailsCard = HrefDetailCardProps &
  DetailsCardSkeletonProps & {
    loading?: boolean
  } & AnalyticsProps
type OnClickAccountDetailsCard = OnClickDetailCardProps &
  DetailsCardSkeletonProps & {
    loading?: boolean
  } & AnalyticsProps
type NonClickableAccountDetailsCard = NonClickableDetailCardProps &
  DetailsCardSkeletonProps & {
    loading?: boolean
  }
type Props =
  | HrefAccountDetailsCard
  | OnClickAccountDetailsCard
  | NonClickableAccountDetailsCard

const AccountDetailsCard = ({
  loading = false,
  label,
  text,
  prompt,
  href,
  onClick,
  icon,
  iconSize,
  invert,
  namespace,
  bottomExtra,
  linkPosition,
  lines,
  shortLine,
  identifier,
  disableAnalytics = false
}: Props): JSX.Element => {
  const defaultInteractiveProps = useMemo(() => {
    return {
      onClick: undefined,
      href: undefined,
      identifier: undefined,
      disableAnalytics: undefined
    }
  }, [])
  const interactiveProps = useMemo(() => {
    if (isUndefined(identifier) && isUndefined(disableAnalytics)) {
      return defaultInteractiveProps
    }

    const trackingProps = identifier
      ? { identifier, disableAnalytics: undefined }
      : { disableAnalytics, identifier: undefined }

    switch (true) {
      case !isUndefined(href):
        return { href, ...trackingProps }
      case !isUndefined(onClick):
        return { onClick, ...trackingProps }
      default:
        return defaultInteractiveProps
    }
  }, [identifier, disableAnalytics, defaultInteractiveProps, href, onClick])
  return (
    <div className={STYLES.container}>
      <SkeletonLoading
        contentReady={!loading}
        template={
          <DetailsCardSkeleton
            linkPosition={linkPosition}
            lines={lines}
            shortLine={shortLine}
          />
        }
      >
        <DetailsCard
          label={label}
          text={text}
          prompt={prompt}
          icon={icon}
          iconSize={iconSize}
          invert={invert}
          namespace={namespace}
          bottomExtra={bottomExtra}
          {...interactiveProps}
        />
      </SkeletonLoading>
    </div>
  )
}

export default AccountDetailsCard
