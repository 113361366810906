// @noflow

type RoutePath =
  | '/dashboard/extras'
  | '/dashboard/extras/:product_collection'
  | '/dashboard/extras/basket'
  | '/dashboard/extras/confirmation'

// Advent Calendar issues routes
const ExtrasList: RoutePath = '/dashboard/extras'
const ProductCollectionPath: RoutePath = '/dashboard/extras/:product_collection'
const Basket: RoutePath = '/dashboard/extras/basket'
const Confirmation: RoutePath = '/dashboard/extras/confirmation'

const ExtrasPageRoutes = {
  ExtrasList,
  ProductCollectionPath,
  Basket,
  Confirmation
}

export type { RoutePath }

export default ExtrasPageRoutes
