// @noflow
import React, { useMemo } from 'react'

import ShootingStar from 'assets/images/icons/ornaments/stars/blue-shooting-star.svg'

import CategoryBar from '@/components/elements/molecules/CategoryBar/CategoryBar'

import type { ExtrasListQuery_productGroups as Product } from '../queries/__generated__/ExtrasListQuery'

type Props = {
  categorySelected: string
  setCategory: (value: string) => void
  productGroups: Array<Product>
  loading: boolean
}

const CategoryNavBar = ({
  setCategory,
  categorySelected,
  productGroups,
  loading
}: Props): JSX.Element => {
  const productCategories = useMemo(() => {
    // filtering the product groups to only return the ones that have product
    // collections inside to prevent users from seeing empty categories
    const filteredProductGroups = productGroups.filter(
      (product) => product.productCollections.length > 0
    )
    return filteredProductGroups.map((product) => {
      return {
        icon: product.thumbnail.src,
        text: {
          text: product.name
        },
        value: product.slug
      }
    })
  }, [productGroups])

  const categories = [
    {
      icon: ShootingStar,
      text: {
        text: 'extras.view_all',
        namespace: 'dashboard'
      },
      value: 'all'
    },
    ...productCategories
  ]

  return (
    <CategoryBar
      categories={categories}
      setCategory={setCategory}
      categorySelected={categorySelected}
      loading={{ isLoading: loading, skeletonCount: 6 }}
    />
  )
}

export default CategoryNavBar
