// @noflow
import React, { useCallback, useState } from 'react'

import Card from '@/components/elements/atoms/Card/Card'
import Text from '@/components/elements/atoms/Text/Text'
import HeroText from '@/components/elements/organisms/HeroText/HeroText'
import CloudinaryImage from '@/components/shared/elements/CloudinaryImage/CloudinaryImage'
// eslint-disable-next-line no-restricted-imports
import type { Answer, Option, Question, User } from '@/components/types'

import STYLES from './ChoiceQuestion.module.sass'

type Props = {
  question: Question
  onAnswer: (answer: Array<Answer>) => void
  user: User
}

type OptionProps = {
  selected: boolean
  onSelect: (id: number) => void
  option: Option
}

const OptionRow = ({ onSelect, selected, option }: OptionProps) => {
  const [hover, setHover] = useState<boolean>(false)

  const handleClick = useCallback(() => {
    onSelect(option.id)
  }, [onSelect, option])

  const hoverOver = useCallback(() => {
    setHover(true)
  }, [setHover])

  const hoverOut = useCallback(() => {
    setHover(false)
  }, [setHover])

  let backgroundColor: 'blue100' | 'blue500' | undefined

  if (hover) {
    backgroundColor = 'blue100'
  }
  if (selected) {
    backgroundColor = 'blue500'
  }

  return (
    <button
      onClick={handleClick}
      onMouseOver={hoverOver}
      onMouseOut={hoverOut}
      onFocus={hoverOver}
      onBlur={hoverOut}
      className={`${STYLES.button} ${selected ? STYLES.selected : ''}`}
      type="button"
    >
      <Card background={backgroundColor}>
        <div className={STYLES.option}>
          <div
            className={`${STYLES.image} ${
              option.goodOption ? STYLES.animated : ''
            }`}
          >
            {option.image && (
              <CloudinaryImage
                alt={option.text}
                image={{
                  path: option.image.src,
                  crop: 'fill',
                  dpr: window.devicePixelRatio
                }}
              />
            )}
          </div>
          <Text
            text={option.text}
            variant="display20"
            colour={selected ? 'brandWhite' : 'brandBlue500'}
            margin={false}
            translate={false}
          />
        </div>
      </Card>
    </button>
  )
}

const ChoiceQuestion = ({ question, onAnswer, user }: Props): JSX.Element => {
  const [selected, setSelected] = useState<number | null>()

  const onSelect = useCallback(
    (optionId: number) => {
      setSelected(optionId)
      onAnswer([
        {
          optionId,
          questionId: question.id,
          value: 1
        }
      ])
    },
    [onAnswer, question.id]
  )

  const options = question.options.map((option) => {
    return (
      <OptionRow
        selected={selected === option.id}
        key={option.id}
        option={option}
        onSelect={onSelect}
      />
    )
  })

  return (
    <>
      <HeroText
        title={question.text}
        variables={{ user }}
        namespace="dynamic"
        translate={false}
      />
      <div
        className={`${STYLES.options} ${
          options.length === 2 ? STYLES.double : ''
        }`}
      >
        {options}
      </div>
    </>
  )
}

export { Props }
export default ChoiceQuestion
