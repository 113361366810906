import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'

import ActionButtons from '../../../components/ActionButtons/ActionButtons'

import {
  CustomerIssueReportCreate_response_categoryReasons as CategoryReasons,
  CustomerIssueReportCreate_response_inputFields as InputFields,
  CustomerIssueReportCreate_response_inputFields_CustomerIssueManagementInputFieldMultifield_fields as MultifieldFields,
  CustomerIssueReportCreate_response_inputFields_CustomerIssueManagementInputFieldMultifield as MultifieldInputField
} from '../../../mutations/__generated__/CustomerIssueReportCreate'
import { CustomerIssueManagementInputInput } from '@/types'

import useCustomerIssueManagementTracking from '../../../analytics'
import useQueryParam from '../../../hooks/useQueryParam'
import CustomerIssueManagementRoutes from '../../../types/routes'
import InputSection from './InputSection'
import Multifield from './input_types/Multifield'

type InputSwitchProps = {
  inputFields: Array<InputFields>
  categoryReasons: Array<CategoryReasons> | null
  inputs: Array<CustomerIssueManagementInputInput>
  reason: string
  updateInputs: (
    fieldId: string,
    newInputs: Array<CustomerIssueManagementInputInput>
  ) => void
  setReason: (newReason: string) => void
  submit: () => void
}

type SectionDisplayType = 'form' | 'multifield'

type Section = {
  displayType: SectionDisplayType
  fields: Array<InputFields | MultifieldFields>
  id: string
}

const InputSwitch = ({
  inputFields,
  categoryReasons,
  inputs,
  reason,
  updateInputs,
  setReason,
  submit
}: InputSwitchProps): JSX.Element | null => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const customerIssueManagementTracking = useCustomerIssueManagementTracking()
  const stepParam = useQueryParam('step', search)
  const [sortedInputFields] = useState(
    inputFields.sort((a, b) => a.presentationalOrder - b.presentationalOrder)
  )
  const [initialised, setInitialised] = useState<boolean>(false)
  const [sectionIds, setSectionIds] = useState<Array<string>>([])
  const [sections, setSections] = useState<Array<Section>>([])
  const [sectionId, setSectionId] = useState<string>('')
  const [section, setSection] = useState<Section>()

  const buttonText = useMemo(() => {
    const index = sectionIds.findIndex((id) => id === sectionId)
    if (index === sectionIds.length - 1) {
      return 'inputs.submit'
    } else {
      return 'inputs.continue'
    }
  }, [sectionId, sectionIds])

  const inputHasValue = useCallback(
    (inputFieldId: string): boolean => {
      const matchingInput = inputs.find(
        (sectionInput) => sectionInput.fieldId === inputFieldId
      )
      return (
        !matchingInput ||
        (!matchingInput.stringValue &&
          !matchingInput.integerValue &&
          !matchingInput.booleanValue &&
          !matchingInput.associationValue &&
          !matchingInput.inputs) ||
        (!!matchingInput.stringValue &&
          matchingInput.stringValue.length === 0) ||
        (!!matchingInput.associationValue &&
          matchingInput.associationValue.length === 0) ||
        (!!matchingInput.inputs && matchingInput.inputs.length === 0) ||
        (!!matchingInput.integerValue && matchingInput.integerValue === 0)
      )
    },
    [inputs]
  )

  const submissionDisabled = useMemo(() => {
    let shouldBeDisabled = false
    if (categoryReasons && categoryReasons.length > 0 && !reason) {
      return true
    }
    for (const inputField of inputFields) {
      if (inputHasValue(inputField.id)) {
        shouldBeDisabled = true
        break
      }
    }
    return shouldBeDisabled
  }, [categoryReasons, reason, inputFields, inputHasValue])

  const createMultifieldSection = useCallback(
    (id: string, multifield: MultifieldInputField): Section => {
      return {
        displayType: 'multifield',
        id,
        fields: [multifield]
      }
    },
    []
  )

  const createMultifieldSectionIds = useCallback(
    (id: string, multifield: MultifieldInputField) => {
      switch (multifield.multifieldDisplayType) {
        case 'product_count':
          return [id + '_selecting', id + '_amounts']
        default:
          return [id]
      }
    },
    []
  )

  const createSection = useCallback(
    (id: string, fields: Array<InputFields>): Section => {
      return {
        displayType: 'form',
        id,
        fields
      }
    },
    []
  )

  const createSectionId = useCallback(() => {
    return 'section' + sections.length
  }, [sections])

  const createSections = useCallback(() => {
    let fields = sortedInputFields.slice(0)
    const createdSections: Array<Section> = []
    const createdSectionIds: Array<string> = []
    while (fields.length > 0) {
      const end = fields.findIndex(
        (inputField) =>
          inputField.__typename ===
          'CustomerIssueManagementInputFieldMultifield'
      )
      if (end === -1) {
        const id = createSectionId()
        createdSectionIds.push(id)
        createdSections.push(createSection(id, fields))
        fields = []
      } else if (end === 0) {
        const multifieldField = fields[0] as MultifieldInputField
        const id = createSectionId()
        const ids = createMultifieldSectionIds(id, multifieldField)
        createdSectionIds.push(...ids)
        createdSections.push(createMultifieldSection(id, multifieldField))
        fields = fields.slice(1)
      } else {
        const id = createSectionId()
        createdSectionIds.push(id)
        createdSections.push({
          displayType: 'form',
          id: id,
          fields: fields.slice(0, end - 1)
        })
        fields = fields.slice(end)
      }
    }
    setSections(createdSections)
    setSectionIds(createdSectionIds)
    setSection(createdSections[0])
    setSectionId(createdSectionIds[0])
  }, [
    sortedInputFields,
    createMultifieldSection,
    createMultifieldSectionIds,
    createSection,
    createSectionId
  ])

  const updateUrl = useCallback(
    (step: number) => {
      const path = CustomerIssueManagementRoutes.Inputs + '?step=' + step
      navigate(path)
    },
    [navigate]
  )

  const getNewSectionId = useCallback(
    (sectionIndex: number): string | null => {
      return sectionIds[sectionIndex]
    },
    [sectionIds]
  )

  const getNewSection = useCallback(
    (newSectionId: string | null): Section | null => {
      return newSectionId
        ? sections.find(
            (sect) => sect.id.indexOf(newSectionId.split('_')[0]) === 0
          ) || null
        : null
    },
    [sections]
  )

  const setNewSection = useCallback(
    (newSectionId: string | null, newSection: Section | null) => {
      if (newSectionId) {
        setSectionId(newSectionId)
      }
      if (newSection && newSection?.id !== section?.id) {
        setSection(newSection)
      }
    },
    [section]
  )

  const updateSection = useCallback(
    (sectionIndex: number) => {
      const newSectionId = getNewSectionId(sectionIndex)
      const newSection = getNewSection(newSectionId)
      setNewSection(newSectionId, newSection)
    },
    [getNewSectionId, getNewSection, setNewSection]
  )

  const onButtonPress = useCallback(() => {
    const currentIndex = sectionIds.findIndex((id) => id === sectionId)
    const newSectionId = getNewSectionId(currentIndex + 1)
    const newSection = getNewSection(newSectionId)
    if (!newSection) {
      submit()
      return
    }
    updateUrl(currentIndex + 1)
  }, [sectionId, sectionIds, submit, updateUrl, getNewSectionId, getNewSection])

  useEffect(() => {
    if (!initialised) {
      createSections()
      setInitialised(true)
    }
  }, [createSections, sections, initialised])

  useEffect(() => {
    const stepIndex = stepParam ? parseInt(stepParam, 10) : 0
    updateSection(stepIndex)
  }, [stepParam, updateSection])

  switch (section?.displayType) {
    case 'multifield':
      customerIssueManagementTracking.stepLoaded('Select items', 'Report Issue')
      return (
        <>
          <Multifield
            inputField={section.fields[0] as MultifieldInputField}
            sectionId={sectionId}
            updateInputs={updateInputs}
          />
          <ActionButtons
            acceptButton={{
              text: buttonText,
              action: onButtonPress,
              disabled: submissionDisabled
            }}
          />
        </>
      )
    case 'form':
      customerIssueManagementTracking.stepLoaded('Report Issue', 'Report Issue')
      return (
        <>
          <InputSection
            inputFields={section.fields as Array<InputFields>}
            categoryReasons={categoryReasons}
            reason={reason}
            updateInputs={updateInputs}
            setReason={setReason}
          />
          <ActionButtons
            acceptButton={{
              text: buttonText,
              action: onButtonPress,
              disabled: submissionDisabled
            }}
          />
        </>
      )
    default:
      return null
  }
}

export default InputSwitch
