// @noflow
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import type { Locale } from '@/utils/countryCodeHelper'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import IllustratedBowl from 'assets/images/illustrations/bowls/illustrated-bowl.svg'
import PawsBottomLeft from 'assets/images/illustrations/paws/paws-bottom-left.svg'
import PawsBottomRight from 'assets/images/illustrations/paws/paws-bottom-right.svg'
import PawsTopRight from 'assets/images/illustrations/paws/paws-top-right.svg'

import Card from '@/components/elements/atoms/Card/Card'
import Icon from '@/components/elements/atoms/Icon/Icon'
import Text from '@/components/elements/atoms/Text/Text'
import { ReferAFriendPageQuery } from '@/components/pages/ReferAFriendPage/queries/__generated__/ReferAFriendPageQuery'

import STYLES from './DiscountSection.module.sass'

import type { Currency } from '@/shared_types/rails_models/shipping_countries'

import useDiscountCopy from '../../../hooks/useDiscountCopy'

type Props = {
  namespace: string
  locale: Locale
  referralLink: ReferAFriendPageQuery['user']['subscription']['referralLink']
  currency: Currency
  squared?: boolean
}

const copyContext = 'refer_a_friend.give_and_get_tab.discount_section'

type DiscountPartProps = {
  discountValue: string
  namespace: string
  windowWidth: number
  variant: 'white' | 'yellow'
  direction: 'row' | 'column'
  discountText: string
  isAltruistic?: boolean
  boxNumber?: number
}

const DiscountPart = ({
  windowWidth,
  namespace,
  discountValue,
  discountText,
  variant,
  direction,
  isAltruistic = false,
  boxNumber
}: DiscountPartProps): JSX.Element => {
  const { t } = useTranslation(namespace)

  const calculateDimension = (direction: string, windowWidth: number) => {
    const isRow = direction === 'row'
    const isMobile = windowWidth < BREAKPOINTS.lg

    return isRow ? (isMobile ? 64 : 80) : isMobile ? 120 : 160
  }

  const getBadgeVariant = useCallback((): JSX.Element => {
    const height = calculateDimension(direction, windowWidth)
    const width = calculateDimension(direction, windowWidth)

    return (
      <Icon
        asset="badge"
        size={height}
        width={width}
        accentColour={variant === 'white' ? 'brandYellow100' : 'brandYellow400'}
      />
    )
  }, [direction, variant, windowWidth])

  return (
    <div className={`${STYLES.discountContainer} ${STYLES[direction]}`}>
      <div className={`${STYLES.discount}`}>
        <div className={STYLES.badge}>{getBadgeVariant()}</div>
        {isAltruistic ? (
          <img
            className={STYLES.bowl}
            alt={t(`${copyContext}.bowl_alt`)}
            src={IllustratedBowl}
            width={windowWidth < BREAKPOINTS.lg ? 51 : 71}
            height={windowWidth < BREAKPOINTS.lg ? 40 : 56}
          />
        ) : (
          <Text
            namespace={namespace}
            text={`${copyContext}.discount`}
            variables={{ value: discountValue }}
            variant={direction === 'row' ? 'display18' : 'display28'}
            margin={false}
            element="p"
          />
        )}
      </div>
      <div className={STYLES.rewardCopy}>
        {isAltruistic ? (
          <Text
            namespace={namespace}
            text={`${copyContext}.altruistic_copy`}
            variant="textRegular16"
            margin={false}
            element="p"
          />
        ) : (
          <Text
            namespace={namespace}
            text={discountText}
            variables={{ boxNumber }}
            variant="textRegular16"
            margin={false}
            element="p"
          />
        )}
      </div>
    </div>
  )
}

const DiscountSection = ({
  namespace,
  locale,
  currency,
  referralLink,
  squared
}: Props): JSX.Element => {
  const { windowWidth } = useWindowSize()

  const { isAltruistic, isMultiDiscount, referralDiscount, referrerDiscount } =
    useDiscountCopy(referralLink, locale, currency)

  return (
    <Card variant="brandYellow200" mask padding={0} squared={squared}>
      <div className={STYLES.container}>
        <div className={STYLES.referralSection}>
          <img
            src={PawsBottomLeft}
            className={STYLES.pawsBottom}
            alt={`${copyContext}.paws_alt`}
          />
          <img
            src={PawsTopRight}
            className={STYLES.pawsTop}
            alt={`${copyContext}.paws_alt`}
          />
          {isMultiDiscount ? (
            <div className={STYLES.multiReward}>
              <Text
                namespace={namespace}
                text={`${copyContext}.your_friend_gets`}
                variant="display16"
                element="h2"
                margin={false}
              />
              <DiscountPart
                discountValue={referralDiscount[0].copy}
                namespace={namespace}
                windowWidth={windowWidth}
                variant="white"
                direction="row"
                discountText={`${copyContext}.referral_multi_discount`}
                boxNumber={referralDiscount[0].boxNumber}
              />
              <div className={STYLES.plusWrapper}>
                <Text
                  namespace={namespace}
                  text="+"
                  variant="display18"
                  margin={false}
                  element="p"
                  translate={false}
                />
              </div>
              {referralDiscount[1] && (
                <DiscountPart
                  discountValue={referralDiscount[1].copy}
                  namespace={namespace}
                  windowWidth={windowWidth}
                  variant="white"
                  direction="row"
                  discountText={`${copyContext}.referral_multi_discount`}
                  boxNumber={referralDiscount[1].boxNumber}
                />
              )}
            </div>
          ) : (
            <div className={STYLES.singleReward}>
              <Text
                namespace={namespace}
                text={`${copyContext}.your_friend_gets`}
                variant="display16"
                element="h2"
                margin={false}
              />
              <DiscountPart
                discountValue={referralDiscount[0].copy}
                namespace={namespace}
                windowWidth={windowWidth}
                variant="white"
                direction="column"
                discountText={`${copyContext}.referral_single_discount`}
                boxNumber={1}
              />
            </div>
          )}
        </div>
        <div className={STYLES.referrerSection}>
          <img
            src={PawsBottomRight}
            className={STYLES.pawsBottom}
            alt={`${copyContext}.paws_alt`}
          />
          <div className={STYLES.singleReward}>
            {isAltruistic ? (
              <Text
                namespace={namespace}
                text={`${copyContext}.you_donate`}
                variant="display16"
                element="h2"
                margin={false}
              />
            ) : (
              <Text
                namespace={namespace}
                text={`${copyContext}.you_get`}
                variant="display16"
                element="h2"
                margin={false}
              />
            )}
            <DiscountPart
              discountValue={referrerDiscount.copy}
              namespace={namespace}
              windowWidth={windowWidth}
              variant="yellow"
              direction="column"
              discountText={`${copyContext}.referrer_single_discount`}
              isAltruistic={isAltruistic}
            />
          </div>
        </div>
      </div>
    </Card>
  )
}

export default DiscountSection
