// @noflow

type Route =
  | '/wizard/new'
  | '/wizard/gender'
  | '/wizard/age'
  | '/wizard/breed'
  | '/wizard/food'
  | '/wizard/eater'
  | '/wizard/fussiness-help'
  | '/wizard/goals'
  | '/wizard/body'
  | '/wizard/weight'
  | '/wizard/activity'
  | '/wizard/allergies'
  | '/wizard/health'
  | '/wizard/snacks'
  | '/wizard/pet-parent'

const Name: Route = '/wizard/new'
const Gender: Route = '/wizard/gender'
const Age: Route = '/wizard/age'
const Breed: Route = '/wizard/breed'
const Food: Route = '/wizard/food'
const Eater: Route = '/wizard/eater'
const FussinessHelp: Route = '/wizard/fussiness-help'
const BodyShape: Route = '/wizard/body'
const Weight: Route = '/wizard/weight'
const Activity: Route = '/wizard/activity'
const Allergies: Route = '/wizard/allergies'
const Health: Route = '/wizard/health'
const Snacks: Route = '/wizard/snacks'
const Goals: Route = '/wizard/goals'
const PetParent: Route = '/wizard/pet-parent'

const WizardRoutes = {
  Name,
  Gender,
  Age,
  Breed,
  Food,
  Eater,
  FussinessHelp,
  Goals,
  BodyShape,
  Weight,
  Activity,
  Allergies,
  Health,
  Snacks,
  PetParent
}

type FullWizardRoutes = (typeof WizardRoutes)[keyof typeof WizardRoutes]

export type { Route, FullWizardRoutes }

export default WizardRoutes
