import React from 'react'
import { useTranslation } from 'react-i18next'

import CloudinaryImage from '@/components/elements/atoms/CloudinaryImage'
import SpeechBubble from '@/components/elements/atoms/SpeechBubble/SpeechBubble'

// Styles
import STYLES from './TalkingHead.module.sass'

import Text, { Props as TextProps } from '../../atoms/Text/Text'

type Expert = 'oli' | 'sarah' | 'ciara' | 'keisha' | 'karim'

const namespace = 'molecules'
const context = 'talking_head'

type Props = {
  expert: Expert
  text: TextProps
}

const TalkingHead = ({ expert, text }: Props): JSX.Element => {
  const { t } = useTranslation(namespace)

  return (
    <div className={STYLES.container}>
      <div className={STYLES.bubble}>
        <SpeechBubble text={text} footPosition="bottomLeft" />
      </div>
      <div className={STYLES.details}>
        <CloudinaryImage
          alt={t(`${context}.${expert}.alt`)}
          className={STYLES.avatar}
          image={{
            path: `Web/photos/people/${expert}.jpg`
          }}
        />
        <div>
          <Text
            variant="textRegular18"
            text={`${context}.${expert}.name`}
            namespace={namespace}
            bold
            element="div"
          />
          <Text
            variant="textRegular18"
            text={`${context}.${expert}.credentials`}
            namespace={namespace}
            element="div"
          />
        </div>
      </div>
    </div>
  )
}

export { Props }
export default TalkingHead
