// @noflow
import {
  useLanguage,
  useShippingCountry
} from '@/context/injectedValues/injectedValues'
import { useNotifications } from '@/context/notifications/notifications'
import { ACCOUNT_ROUTES } from '@/routes'
import { useReactiveVar } from '@apollo/client'
import Cookies from 'js-cookie'
import isBoolean from 'lodash/isBoolean'
import isNil from 'lodash/isNil'
import isUndefined from 'lodash/isUndefined'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { dogsDataVar } from '@/services/apollo'

import cookies from '@/utils/cookies'
import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { formatCurrencyWithDecimal } from '@/utils/currency'

import { UsePlanQuery_user_subscription_planOptions } from '@/hooks/usePlan/__generated__/UsePlanQuery'
import usePlanOptions from '@/hooks/usePlan/usePlan'
import type { Plan } from '@/hooks/usePlan/usePlan'

import AlertCard from '@/components/elements/atoms/Alert/AlertCard'
import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'
import { CardSkeleton } from '@/components/elements/atoms/Card/CardSkeleton'
import { FixedBase } from '@/components/elements/atoms/FixedBase'
import Icon from '@/components/elements/atoms/Icon/Icon'
import Image from '@/components/elements/atoms/Image/Image'
import Separator from '@/components/elements/atoms/Separator/Separator'
import Text from '@/components/elements/atoms/Text/Text'
import { ClientInitQuery_user_dogs as Dog } from '@/components/pages/App/queries/__generated__/ClientInitQuery'

import STYLES from './UpdatePlanPageV3.module.sass'

enum ReviewType {
  dailyPortion = 'daily-portion',
  deliveryFrequency = 'delivery-frequency'
}

type UpdatedGramsPerDayProps = {
  dogs: Dog[]
  newPlan: Plan
  currentPlan: Plan
  namespace: string
  copyContext: string
}

type UpdatedPouchSizeProps = {
  newPlan: Plan
  currentPlan: Plan
  namespace: string
  copyContext: string
}

type UpdatedNumberOfPouchesProps = {
  newPlan: Plan
  currentPlan: Plan
  namespace: string
  copyContext: string
}

type UpdatedPricingProps = {
  newPlan: Plan
  currentPlan: Plan
  namespace: string
  copyContext: string
  savings?: number
}

type UpdatedFrequencyProps = {
  dogs: Dog[]
  newPlan: Plan
  currentPlan: Plan
  namespace: string
  copyContext: string
  showWarning?: boolean
}

const getMoreLessSameContext = (
  currentPlanValue: number,
  newPlanValue: number
) => {
  switch (true) {
    case currentPlanValue < newPlanValue:
      return 'more'
    case currentPlanValue > newPlanValue:
      return 'less'
    default:
      return 'same'
  }
}

const SkeletonLoading = () => (
  <div className={STYLES.skeleton}>
    <CardSkeleton height={500} />
  </div>
)

const UpdatedGramsPerDay = ({
  dogs,
  newPlan,
  currentPlan,
  namespace,
  copyContext
}: UpdatedGramsPerDayProps): JSX.Element => {
  const { gramsPerDay } = newPlan
  const context = getMoreLessSameContext(
    currentPlan.gramsPerDay,
    newPlan.gramsPerDay
  )
  return (
    <div className={STYLES.gramsPerDay}>
      <Text
        align="left"
        margin={false}
        text={`${copyContext}.amount.${context}`}
        namespace={namespace}
        variables={{
          count: dogs.length,
          dogName: dogs[0].name
        }}
        element="h3"
      />
      <div className={STYLES.amountText}>
        <Text
          align="left"
          text={`${copyContext}.amount.amount_grams`}
          namespace={namespace}
          variant="display24"
          margin={false}
          bold
          variables={{
            amountPerDayInGrams: gramsPerDay
          }}
        />
        <Text
          align="left"
          text={`${copyContext}.per_day`}
          namespace={namespace}
          variant="display20"
          margin={false}
          bold
        />
      </div>
      <Image
        alt={`${copyContext}.bowl_alt`}
        slug="duck-and-chicken-bowl"
        image={{
          width: 40,
          height: 40,
          resizeMode: 'resize_to_fill',
          retina: true
        }}
      />
    </div>
  )
}

const UpdatedPouchSize = ({
  newPlan,
  currentPlan,
  namespace,
  copyContext
}: UpdatedPouchSizeProps): JSX.Element => {
  const { pouchesPerDay, pouchSize, numberOfPouches } = newPlan
  const context = getMoreLessSameContext(
    currentPlan.servingSize,
    newPlan.servingSize
  )
  return (
    <div className={STYLES.updatedPouchSize}>
      <Text
        text={`${copyContext}.pouch_size.${context}`}
        namespace={namespace}
        element="h3"
        margin={false}
        align="left"
      />
      <div className={STYLES.updatedPouchSizeText}>
        <Text
          text={`${copyContext}.pouches_per_day`}
          namespace={namespace}
          margin={false}
          bold
          variables={{
            numberOfPouchesPerDay: pouchesPerDay,
            pouchSize,
            count: pouchesPerDay
          }}
          align="left"
        />
        <Text
          text={`${copyContext}.pouches_per_box`}
          namespace={namespace}
          margin={false}
          colour="brandBlue400"
          variables={{
            pouchesPerBox: numberOfPouches
          }}
          align="left"
        />
      </div>
      <Image
        alt={`${copyContext}.pouches_alt`}
        slug={pouchesPerDay > 1 ? 'two-pouches' : 'one-pouch'}
        image={{
          width: 40,
          height: 40,
          resizeMode: 'resize_to_fill',
          retina: true
        }}
      />
    </div>
  )
}

const UpdatedNumberOfPouches = ({
  newPlan,
  currentPlan,
  namespace,
  copyContext
}: UpdatedNumberOfPouchesProps): JSX.Element => {
  const { pouchesPerDay, pouchSize, numberOfPouches } = newPlan
  const context = getMoreLessSameContext(
    currentPlan.numberOfPouches,
    newPlan.numberOfPouches
  )
  return (
    <div className={STYLES.updatedNumberOfPouches}>
      <Text
        text={`${copyContext}.number_of_pouches.${context}`}
        namespace={namespace}
        element="h3"
        margin={false}
        align="left"
      />
      <div className={STYLES.updatedNumberOfPouchesText}>
        <Text
          text={`${copyContext}.pouches_per_day`}
          namespace={namespace}
          margin={false}
          bold
          variables={{
            numberOfPouchesPerDay: pouchesPerDay,
            pouchSize,
            count: pouchesPerDay
          }}
          align="left"
        />
        <Text
          text={`${copyContext}.pouches_per_box`}
          namespace={namespace}
          margin={false}
          colour="brandBlue400"
          variables={{
            pouchesPerBox: numberOfPouches
          }}
          align="left"
        />
      </div>
      <Image
        alt={`${copyContext}.pouches_alt`}
        slug={pouchesPerDay > 1 ? 'two-pouches' : 'one-pouch'}
        image={{
          width: 40,
          height: 40,
          resizeMode: 'resize_to_fill',
          retina: true
        }}
      />
    </div>
  )
}

const UpdatedPricing = ({
  newPlan,
  currentPlan,
  namespace,
  copyContext,
  savings
}: UpdatedPricingProps): JSX.Element => {
  const { shippingCountry } = useShippingCountry()
  const { userLanguage } = useLanguage()
  const { locale, currency } = countryCodeToLocaleCurrency(
    shippingCountry,
    userLanguage
  )
  // New price
  const { pricePerWeek } = newPlan
  const newPricePerWeek = formatCurrencyWithDecimal(pricePerWeek, {
    locale,
    currency
  })

  // Current price
  const { pricePerWeek: currentPricePerWeek } = currentPlan
  const currentPricePerWeekFormatted = formatCurrencyWithDecimal(
    currentPricePerWeek,
    {
      locale,
      currency
    }
  )

  // New price is greater than current price
  const planPriceIncreased = pricePerWeek > currentPricePerWeek

  // Savings formatted
  const savingsFormatted = formatCurrencyWithDecimal(savings || 0, {
    locale,
    currency
  })

  return (
    <div className={STYLES.updatedPricing}>
      <Text
        text={`${copyContext}.pricing.your_new_price_per_week_text`}
        namespace={namespace}
        margin={false}
        align="left"
      />
      <div className={STYLES.amountText}>
        <Text
          text={`${newPricePerWeek}`}
          namespace={namespace}
          margin={false}
          translate={false}
          variant="display16"
          bold
          align="left"
        />
        <Text
          text={`${copyContext}.per_week`}
          namespace={namespace}
          margin={false}
          variant="display16"
          bold
          align="left"
        />
      </div>
      {planPriceIncreased && (
        <Text
          text={`${copyContext}.pricing.current_price_per_week_increase`}
          namespace={namespace}
          margin={false}
          variables={{ currentPricePerWeek: currentPricePerWeekFormatted }}
          colour="brandBlue400"
          align="left"
        />
      )}
      {savings && savings > 0 && (
        <div className={STYLES.savings}>
          <Icon
            asset="checkmark"
            size={16}
            width={16}
            accentColour="successGreen400"
          />
          <Text
            text={`${copyContext}.pricing.current_price_per_week_saved`}
            namespace={namespace}
            margin={false}
            variables={{ amountSaved: savingsFormatted }}
            colour="supportGreen400"
            align="left"
          />
        </div>
      )}
    </div>
  )
}

const UpdatedFrequency = ({
  dogs,
  newPlan,
  currentPlan,
  namespace,
  copyContext,
  showWarning
}: UpdatedFrequencyProps): JSX.Element => {
  const { durationInDays } = newPlan
  const cadenceInWeeks = durationInDays / 7
  const context = getMoreLessSameContext(
    currentPlan.durationInDays,
    newPlan.durationInDays
  )

  return (
    <>
      <div className={STYLES.gramsPerDay}>
        <Text
          align="left"
          margin={false}
          text={`${copyContext}.delivery_frequency.${context}`}
          namespace={namespace}
          variables={{
            count: dogs.length,
            dogName: dogs[0].name
          }}
          element="h3"
        />
        <Text
          align="left"
          text={`${copyContext}.delivery_frequency.cadence_in_weeks`}
          namespace={namespace}
          variant="display24"
          margin={false}
          bold
          variables={{
            count: cadenceInWeeks
          }}
        />
        <Image
          alt={`${copyContext}.box_alt`}
          slug="open-box-of-butternut-meals-illustration"
          image={{
            width: 40,
            height: 40,
            resizeMode: 'resize_to_fill',
            retina: true
          }}
        />
      </div>
      {showWarning && (
        <div className={STYLES.info}>
          <AlertCard
            message={{
              text: `${copyContext}.delivery_frequency.maximum_pouches_info`,
              namespace,
              align: 'left'
            }}
          />
        </div>
      )}
    </>
  )
}

type SeparatedContentProps = {
  children: Array<boolean | JSX.Element>
}

const SeparatedContent = ({ children }: SeparatedContentProps): JSX.Element => {
  const componentsOnly = children.filter((child) => !isBoolean(child))
  return (
    <div>
      {componentsOnly.map((child, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index}>
            {child}
            {index < componentsOnly.length - 1 && (
              <Separator handdrawn margin={{ top: 24, bottom: 24 }} />
            )}
          </div>
        )
      })}
    </div>
  )
}

const UpdatePlanPageV3 = (): JSX.Element | null => {
  const namespace = 'account'
  const copyContext = 'plan_review_v2'
  const container = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const { dogs, pronoun } = useReactiveVar(dogsDataVar) || {}
  const { reviewType, planId } = useParams()
  const { setErrorNotification, setSuccessNotification } = useNotifications()
  const [newPlan, setNewPlan] = useState<
    UsePlanQuery_user_subscription_planOptions | undefined
  >()

  const { setPlan, getPlan, currentPlan, recurringOrder, updating } =
    usePlanOptions()

  const isDailyPortionReview = reviewType === ReviewType.dailyPortion
  const isDeliveryFrequencyReview = reviewType === ReviewType.deliveryFrequency

  const gramsPerDayUpdated =
    isDailyPortionReview || currentPlan?.gramsPerDay !== newPlan?.gramsPerDay

  const pouchSizeUpdated =
    isDailyPortionReview || currentPlan?.servingSize !== newPlan?.servingSize

  const durationInDaysUpdated =
    currentPlan?.durationInDays !== newPlan?.durationInDays

  const frequencyUpdated =
    (isDailyPortionReview && durationInDaysUpdated) ||
    isDeliveryFrequencyReview ||
    currentPlan?.durationInDays !== newPlan?.durationInDays

  const numberOfPouchesUpdated =
    isDeliveryFrequencyReview ||
    currentPlan?.numberOfPouches !== newPlan?.numberOfPouches

  const updatePlan = useCallback(() => {
    if (Cookies.get(cookies.reviewingMFITBPlanOffer) === 'true') {
      Cookies.set(cookies.swapToMFITBPlanOfferSeen, 'true')
      Cookies.set(cookies.reviewingMFITBPlanOffer, 'false')
    }
    if (
      !isUndefined(newPlan) &&
      !isUndefined(setPlan) &&
      !isUndefined(currentPlan)
    ) {
      const redirectURL = ACCOUNT_ROUTES.base

      setPlan(newPlan.id)
        .then(() => {
          setSuccessNotification({
            text: 'serving_size.notifications.success',
            namespace
          })
          navigate(redirectURL)
        })
        .catch(() => {
          setErrorNotification({
            text: 'serving_size.notifications.error',
            namespace
          })
        })
    }
  }, [
    newPlan,
    setPlan,
    currentPlan,
    setSuccessNotification,
    navigate,
    setErrorNotification
  ])

  const cancel = useCallback(() => {
    if (Cookies.get(cookies.reviewingMFITBPlanOffer) === 'true') {
      Cookies.set(cookies.swapToMFITBPlanOfferSeen, 'true')
      Cookies.set(cookies.reviewingMFITBPlanOffer, 'false')
    }
    navigate(ACCOUNT_ROUTES.base)
  }, [navigate])

  /**
   * Get the new plan from the planId URL parameter. If none is provided show the ideal plan
   */
  useEffect(() => {
    if (
      isUndefined(planId) ||
      (!isUndefined(currentPlan) && planId === currentPlan.id)
    )
      navigate(ACCOUNT_ROUTES.planManagement)
    else if (isUndefined(newPlan) && !isUndefined(getPlan)) {
      setNewPlan(getPlan(planId))
    }
  }, [currentPlan, getPlan, navigate, newPlan, planId])

  if (
    isNil(dogs) ||
    isNil(pronoun) ||
    isUndefined(currentPlan) ||
    isUndefined(newPlan) ||
    isUndefined(recurringOrder)
  )
    return <div className={STYLES.container}>{SkeletonLoading()}</div>

  const savings = currentPlan.pricePerWeek - newPlan.pricePerWeek

  return (
    <div className={STYLES.container} ref={container}>
      <Card mask padding={0}>
        <div className={STYLES.cardContent}>
          <SeparatedContent>
            {gramsPerDayUpdated && (
              <UpdatedGramsPerDay
                dogs={dogs}
                newPlan={newPlan}
                currentPlan={currentPlan}
                namespace={namespace}
                copyContext={copyContext}
              />
            )}
            {pouchSizeUpdated && (
              <UpdatedPouchSize
                newPlan={newPlan}
                currentPlan={currentPlan}
                namespace={namespace}
                copyContext={copyContext}
              />
            )}
            {(frequencyUpdated || numberOfPouchesUpdated) && (
              <UpdatedFrequency
                dogs={dogs}
                newPlan={newPlan}
                currentPlan={currentPlan}
                namespace={namespace}
                copyContext={copyContext}
              />
            )}
            {numberOfPouchesUpdated && (
              <UpdatedNumberOfPouches
                newPlan={newPlan}
                currentPlan={currentPlan}
                namespace={namespace}
                copyContext={copyContext}
              />
            )}
          </SeparatedContent>
        </div>
        <div className={STYLES.pricingInfo}>
          <UpdatedPricing
            newPlan={newPlan}
            currentPlan={currentPlan}
            namespace={namespace}
            copyContext={copyContext}
            savings={savings}
          />
        </div>
      </Card>
      <FixedBase backgroundColor="brandWhite90" container={container}>
        <div className={STYLES.buttons}>
          <Button
            onClick={updatePlan}
            typography={{
              text: `${copyContext}.cta.confirm`,
              namespace
            }}
            identifier="update_plan.update"
            disabled={updating}
          />
          <Button
            variant="secondary"
            onClick={cancel}
            typography={{
              text: `${copyContext}.cta.cancel`,
              namespace
            }}
            identifier="update_plan.dont_update"
            disabled={updating}
          />
        </div>
      </FixedBase>
    </div>
  )
}

export default UpdatePlanPageV3
