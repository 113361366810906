import { gql } from '@apollo/client'

const STRIPE_USER_PARAMS_SETUP_INTENT_CREATE_MUTATION = gql`
  mutation DirectSalesStripeUserParamsSetupIntentCreate(
    $subscriptionData: SubscriptionDataInput!
    $authenticationType: String!
    $paymentIntentId: String!
  ) {
    stripeUserParamsSetupIntentCreate(
      subscriptionData: $subscriptionData
      authenticationType: $authenticationType
      paymentIntentId: $paymentIntentId
    ) {
      paymentIntentId
    }
  }
`

const DIRECT_SALES_STRIPE_CUSTOMER_CREATION = gql`
  mutation DirectSalesStripeCustomerCreate(
    $email: String!
    $amount: Int!
    $paymentMethodType: String
  ) {
    directSalesStripeCustomerCreate(
      email: $email
      amount: $amount
      paymentMethodType: $paymentMethodType
    ) {
      paymentIntentId
    }
  }
`

const DIRECT_SALES_SUBSCRIPTION_CREATION = gql`
  mutation DirectSalesAppSubscriptionCreate(
    $email: String!
    $discountCodeId: ID!
    $phoneNumber: String
    $password: String
    $address: AddressInput!
    $subscriptionInput: SubscriptionInput!
    $chargedOnBoxOne: Boolean
    $supportedMarketingMethodPurposeIds: [ID!]
  ) {
    directSalesSubscriptionCreate(
      discountCodeId: $discountCodeId
      email: $email
      phoneNumber: $phoneNumber
      password: $password
      address: $address
      subscriptionInput: $subscriptionInput
      chargedOnBoxOne: $chargedOnBoxOne
      supportedMarketingMethodPurposeIds: $supportedMarketingMethodPurposeIds
    ) {
      firstDeliveryDate
      userSignInUrl
      userId
      referralLinkUrl
      firstInvoiceStatus
      directSalesRepresentative {
        trackingId
        directSalesTeam {
          name
        }
      }
    }
  }
`

const SEND_APP_SMS = gql`
  mutation SendAppSMS($userId: ID!) {
    sendAppSms(userId: $userId) {
      success
      errorMessage
    }
  }
`

const SEND_RAF_SMS = gql`
  mutation SendRafSMS($userId: ID!) {
    sendRafSms(userId: $userId) {
      success
      errorMessage
    }
  }
`

export {
  STRIPE_USER_PARAMS_SETUP_INTENT_CREATE_MUTATION,
  DIRECT_SALES_STRIPE_CUSTOMER_CREATION,
  DIRECT_SALES_SUBSCRIPTION_CREATION,
  SEND_APP_SMS,
  SEND_RAF_SMS
}
