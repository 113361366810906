import { useReactiveVar } from '@apollo/client'
import React, { useCallback, useState } from 'react'

import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { formatCurrencyWithDecimal } from '@/utils/currency'

import DogCelebrating from 'assets/images/illustrations/dogs/dog-celebrating.svg'

import { Button } from '@/components/elements/atoms/Button'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from '../ResolutionConfirmation.module.sass'

import { CustomerIssueReportEntriesSubmission_response_resolutions_details_ApplyDiscounts as DiscountDetails } from '../../../mutations/__generated__/CustomerIssueReportEntriesSubmission'

import { selfResolutionPageState } from '../../../SelfResolutionPage'
import useSelfResolutionTracking from '../../../analytics'
import ResolutionConfirmationBox from './ResolutionConfirmationBox'

type Props = {
  namespace: string
  details: DiscountDetails
}

const DiscountConfirmation = ({ namespace, details }: Props): JSX.Element => {
  const selfResolutionTracking = useSelfResolutionTracking()
  const selfResolutionState = useReactiveVar(selfResolutionPageState)
  const [discountValue, setDiscountValue] = useState('')

  React.useEffect(() => {
    const pageTitle = 'resolutionConfirmation.discount.header'
    selfResolutionPageState({
      ...selfResolutionState,
      showBackButton: false,
      pageTitle
    })
    selfResolutionTracking.stepLoaded(
      'Discount confirmation',
      'Resolution confirmation'
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    const discountAmount = details.discountCode.discountCodeParts[0].value
    const { locale, currency } = countryCodeToLocaleCurrency(
      selfResolutionState.shippingCountryCode,
      selfResolutionState.preferredLanguage
    )
    const price = formatCurrencyWithDecimal(discountAmount, {
      locale,
      currency
    })
    setDiscountValue(price)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selfResolutionState])

  const goToMyAccount = useCallback(() => {
    selfResolutionTracking.dropOff('Discount Confirmation', 'Dashboard')
    window.location.href = '../dashboard'
  }, [selfResolutionTracking])

  const goToDeliveries = useCallback(() => {
    selfResolutionTracking.dropOff('Discount Confirmation', 'Update deliveries')
    window.location.href = '../dashboard/boxes'
  }, [selfResolutionTracking])

  return (
    <>
      <div className={STYLES.imgContainer}>
        <img alt="" src={DogCelebrating} />
      </div>
      <div className={STYLES.discount}>
        <Text
          namespace={namespace}
          text={'resolutionConfirmation.discount.text1'}
          variant={'textRegular16'}
          colour={'brandBlue500'}
        />
        <ResolutionConfirmationBox
          asset={'boxClosed'}
          assetSize={20}
          textProps={{
            namespace: namespace,
            text: discountValue,
            colour: 'brandBlue500',
            translate: false,
            variant: 'textRegular18'
          }}
        />
        <Text
          namespace={namespace}
          text={'resolutionConfirmation.discount.text2'}
          variant={'textRegular16'}
          colour={'brandBlue500'}
        />
        <div className={STYLES.buttonWrapper}>
          <Button
            onClick={goToMyAccount}
            typography={{
              namespace,
              text: 'resolutionConfirmation.discount.account_button'
            }}
            disableAnalytics
          />
          <Button
            typography={{
              namespace,
              text: 'resolutionConfirmation.discount.deliveries_button'
            }}
            onClick={goToDeliveries}
            variant={'secondary'}
            disableAnalytics
          />
        </div>
      </div>
    </>
  )
}

export { Props }
export default DiscountConfirmation
