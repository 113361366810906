// @noflow

const initGTM = (): void => {
  const gtmData = document.getElementById('gtm-data')
  if (!gtmData) {
    throw new Error('Could not load gtmData')
  }
  const dataset = gtmData.dataset
  const { gtmContainerId } = dataset

  const gtmScript = document.createElement('script')
  // eslint-disable-next-line i18next/no-literal-string
  gtmScript.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer', '${gtmContainerId}');`
  gtmScript.type = 'text/javascript'
  gtmScript.async = true

  if (!document.body) {
    throw new Error('body not loaded yet')
  }
  document.body.appendChild(gtmScript)

  const gtmNoScript = document.createElement('noscript')
  const gtmNoScriptIframe = document.createElement('iframe')
  // eslint-disable-next-line i18next/no-literal-string
  gtmNoScriptIframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmContainerId}`
  gtmNoScriptIframe.style.display = 'none'
  gtmNoScriptIframe.style.visibility = 'hidden'
  gtmNoScriptIframe.style.height = '0'
  gtmNoScriptIframe.style.width = '0'

  gtmNoScript.appendChild(gtmNoScriptIframe)
  if (!document.head) {
    throw new Error('head not loaded yet')
  }

  document.head.appendChild(gtmNoScript)
}

export default initGTM
