/* eslint-disable react/no-unused-prop-types */
// @noflow
import React from 'react'
import { useTranslation } from 'react-i18next'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import Image from '../elements/atoms/Image'
import SwiperSlider from '../elements/molecules/SwiperSlider/SwiperSlider'

type ImageCrop = {
  x: number
  y: number
  width: number
  height: number
}

type CarouselItem = {
  imageSlug: string
  desktopCrop: ImageCrop
  mobileCrop: ImageCrop
}

const carouselItems: Array<CarouselItem> = [
  {
    imageSlug: 'mayhew-2',
    mobileCrop: { x: 0, y: 1151, width: 2988, height: 2200 },
    desktopCrop: { x: 0, y: 1249, width: 2988, height: 1608 }
  },
  {
    imageSlug: 'hammersmith-and-fulham-foodbank',
    mobileCrop: { x: 115, y: 175, width: 616, height: 409 },
    desktopCrop: { x: 68, y: 181, width: 730, height: 394 }
  },
  {
    imageSlug: 'foal-farm',
    mobileCrop: { x: 0, y: 852, width: 3024, height: 1989 },
    desktopCrop: { x: 0, y: 904, width: 3024, height: 1639 }
  },
  {
    imageSlug: 'wild-at-heart-foundation-2',
    mobileCrop: { x: 425, y: 1564, width: 2965, height: 2000 },
    desktopCrop: { x: 433, y: 1671, width: 2867, height: 1546 }
  }
]

const CharityCarousel = (): React.ReactElement => {
  const { windowWidth } = useWindowSize()
  const { t } = useTranslation('our_story')

  const namespace = 'that_was_then_this_is_now.step_2.charity_carousel'

  return (
    <SwiperSlider
      slidesPerView={1}
      arrows={windowWidth > BREAKPOINTS.md}
      arrowsInside
      bullets
    >
      {carouselItems.map(
        (
          { imageSlug, mobileCrop, desktopCrop }: CarouselItem,
          index
        ): React.ReactElement => (
          <div key={imageSlug} className="today__carousel__card">
            <Image
              alt={t(`${namespace}.slide_${index + 1}.alt`)}
              slug={imageSlug}
              image={{
                width:
                  windowWidth > BREAKPOINTS.sm
                    ? desktopCrop.width
                    : mobileCrop.width,
                height:
                  windowWidth > BREAKPOINTS.sm
                    ? desktopCrop.height
                    : mobileCrop.height,
                resizeMode: 'crop',
                x: windowWidth > BREAKPOINTS.sm ? desktopCrop.x : mobileCrop.x,
                y: windowWidth > BREAKPOINTS.sm ? desktopCrop.y : mobileCrop.y
              }}
            />
            <p>
              <span>{t(`${namespace}.slide_${index + 1}.title`)}</span>
            </p>
          </div>
        )
      )}
    </SwiperSlider>
  )
}

export default CharityCarousel
