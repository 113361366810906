// @noflow
import React from 'react'

import RedStarburst from 'assets/images/icons/ornaments/starbursts/red-starburst.svg'
import BlueHeart from 'assets/images/illustrations/hearts/blue-heart.svg'
import WhiteHeart from 'assets/images/illustrations/hearts/white-heart.svg'

import STYLES from './Sprinkles.module.sass'

const SPRINKLES = [
  RedStarburst,
  RedStarburst,
  BlueHeart,
  BlueHeart,
  WhiteHeart,
  WhiteHeart
]

type Props = {
  sprinkles: boolean
}

const Sprinkles = ({ sprinkles }: Props): JSX.Element | null => {
  if (!sprinkles) {
    return null
  }

  return (
    <>
      {SPRINKLES.map(
        (sprinkle: string, index: number): JSX.Element => (
          <img
            role="presentation"
            alt=""
            key={`${sprinkle}--${index + 1}`}
            src={sprinkle}
            className={`${STYLES.sprinkle} ${
              STYLES[`sprinkle--${index + 1}` as keyof typeof STYLES]
            }`}
            data-testid="dog-avatar-sprinkle"
          />
        )
      )}
    </>
  )
}

export { Sprinkles }
