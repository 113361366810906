/* globals fetch */
/* eslint-disable react/require-optimization */
/* eslint-disable react/no-arrow-function-lifecycle */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable flowtype/require-exact-type */
// @flow

import * as React from 'react'

import { tabs, shopTab } from './tabs'

import DashboardAlert from '../shared/DashboardAlert'
import SelectBar from './partials/SelectBar'
import BalanceTab from './tabs/BalanceTab'

import type { Ambassador } from './message_types'

import type {
  BankAccount
  // $FlowFixMe as we are moving away from Alt.js
} from './index'

type Props = {|
  ambassador: Ambassador,
  csrf_token: string,
  ambassadorShopPath: string
|}

type State = {|
  ambassador: Ambassador,
  alertMessage: ?string,
  alertIsError: boolean
|}

export type PayoutType =
  | 'charity_donation'
  | 'off_next_box'

type HttpMethod =
  | 'POST'
  | 'PUT'
  | 'DELETE'

class AmbassadorDashboardBalance extends React.Component<Props, State> {
  constructor (props: Props): void {
    super(props)
    this.state = {
      ambassador: props.ambassador,
      alertMessage: null,
      alertIsError: false,
    }
  }

  setAlertMessage = (alertMessage: string, alertIsError: boolean = false): void => {
    this.setState({alertMessage, alertIsError}, (): void => {
      window.scrollTo(0, 0)
      setTimeout((): void => {
        this.setState({alertMessage: null})
      }, 3000)
    })
  }

  sendRequest = (endpoint: string, method: HttpMethod, body: {}): Promise<Response> => {
    return fetch(endpoint, {
      method,
      body: JSON.stringify(body),
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.props.csrf_token
      },
      mode: 'cors'
    })
  }

  createPayout = (type: PayoutType, amount: number, successHandler: string => void): void => {
    const endpoint = '/api/ambassadors/payouts'
    const method = 'POST'
    const body = {
      token: this.state.ambassador.token,
      payout: { type, amount }
    }

    this.sendRequest(endpoint, method, body).then((res: Response): Promise<{ambassador: Ambassador, message: string, error?: string}> => {
      return res.json()
    }).then(({ambassador, message, error}: {ambassador: Ambassador, message: string, error?: string}): void => {
      if (error) {
        this.setAlertMessage(error, true)
      } else {
        this.setState({ambassador: ambassador})
        successHandler(message)
      }
    })
  }

  persistBankAccount = (bankAccount: BankAccount): void => {
    const updating = bankAccount.id !== null && bankAccount.id !== undefined
    // eslint-disable-next-line i18next/no-literal-string
    const endpoint = updating ? `/api/ambassadors/bank_accounts/${bankAccount.id}` : '/api/ambassadors/bank_accounts'
    const method = updating ? 'PUT' : 'POST'
    const body = {
      token: this.state.ambassador.token,
      bank_account: {
        account_holder_name: bankAccount.account_holder_name,
        account_number: bankAccount.account_number,
        sort_code: bankAccount.sort_code,
        iban: bankAccount.iban,
        swift_code: bankAccount.swift_code
      }
    }

    this.sendRequest(endpoint, method, body)
      .then((response: Response): void => {
        if (response.ok) {
          response.json()
            .then((ambassador: Ambassador): void => {
              this.setState({ambassador: ambassador})
            })
        }
      })
  }

  deleteBankAccount = (id: number): void => {
    // eslint-disable-next-line i18next/no-literal-string
    const endpoint = `/api/ambassadors/bank_accounts/${id}`
    const body = {
      token: this.state.ambassador.token
    }

    this.sendRequest(endpoint, 'DELETE', body)
      .then((response: Response): void => {
        if (response.ok) {
          response.json()
            .then((ambassador: Ambassador): void => {
              this.setState({ambassador: ambassador})
            })
        }
      })
  }

  render = (): React.Element<'div'> => (
    <div className='butternutbox-ambassador-dashboard'>
      {/* $FlowFixMe */}
      <DashboardAlert
        message={this.state.alertMessage}
        isError={this.state.alertIsError}
        shippingCountryCode={this.props.ambassador.shipping_country_code}
        preferredLanguage={this.props.ambassador.preferred_language}
      />
      <div className={`affiliate-dashboard__${tabs[3].hash}`}>
        <div className='affiliate-dashboard container'>
          <SelectBar
            tabs={tabs}
            ambassador={this.state.ambassador}
            shopTab={ this.props.ambassadorShopPath ? shopTab : []}
            selectedIndex={3}
            ambassadorShopPath={this.props.ambassadorShopPath}
          />
          <BalanceTab
            ambassador={this.state.ambassador}
            persistBankAccount={this.persistBankAccount}
            deleteBankAccount={this.deleteBankAccount}
            createPayout={this.createPayout}
            // eslint-disable-next-line react/jsx-boolean-value
            visible={true}
          />
        </div>
      </div>
    </div>
  )
}

export default AmbassadorDashboardBalance
