// @noflow
import camelCase from 'lodash/camelCase'

import { MealInput } from '@/types'

import type { SubscriptionData } from './submitSubscription'

type FormattedSubscriptionData = {
  planId: SubscriptionData['plan_id']
  paymentProvider: SubscriptionData['payment_provider']
  paymentMethodIdentifierType: SubscriptionData['payment_method_identifier_type']
  additionalProductIds: SubscriptionData['additional_product_ids']
  paymentMethodType: SubscriptionData['payment_method_type']
  targetFirstDeliveryDate: SubscriptionData['target_first_delivery_date']
  mealBreakdown: MealInput
  deliveryCadence: SubscriptionData['delivery_cadence']
  gaClientId: SubscriptionData['ga_client_id']
  user: {
    addressAttributes: {
      addressLineOne: SubscriptionData['user']['address_attributes']['address_1']
      addressLineTwo: SubscriptionData['user']['address_attributes']['address_2']
      city: SubscriptionData['user']['address_attributes']['city']
      shippingCountryId: SubscriptionData['user']['address_attributes']['shipping_country_id']
      recipientName: SubscriptionData['user']['address_attributes']['recipient_name']
      postcode: SubscriptionData['user']['address_attributes']['postcode']
    }
    shouldSupportSca: SubscriptionData['user']['should_support_sca']
    email: SubscriptionData['user']['email']
    firstName: SubscriptionData['user']['first_name']
    lastName: SubscriptionData['user']['last_name']
    password: SubscriptionData['user']['password']
    passwordConfirmation: SubscriptionData['user']['password_confirmation']
    phone: SubscriptionData['user']['phone']
  }
}

const cameliseMeals = (
  mealBreakdown: SubscriptionData['meal_breakdown']
): MealInput => {
  return Object.keys(mealBreakdown).reduce((acc: MealInput, key) => {
    acc[camelCase(key) as keyof MealInput] = mealBreakdown[key]

    return acc
  }, {})
}

export const formatSubscriptionDataAddressForGraphql = (
  subscriptionData: SubscriptionData
): FormattedSubscriptionData => {
  return {
    planId: subscriptionData.plan_id,
    paymentProvider: subscriptionData.payment_provider,
    paymentMethodIdentifierType:
      subscriptionData.payment_method_identifier_type,
    additionalProductIds: subscriptionData.additional_product_ids,
    paymentMethodType: subscriptionData.payment_method_type,
    targetFirstDeliveryDate: subscriptionData.target_first_delivery_date,
    mealBreakdown: cameliseMeals(subscriptionData.meal_breakdown),
    deliveryCadence: subscriptionData.delivery_cadence,
    gaClientId: subscriptionData.ga_client_id,
    user: {
      addressAttributes: {
        addressLineOne: subscriptionData.user.address_attributes.address_1,
        addressLineTwo: subscriptionData.user.address_attributes.address_2,
        city: subscriptionData.user.address_attributes.city,
        shippingCountryId:
          subscriptionData.user.address_attributes.shipping_country_id,
        recipientName: subscriptionData.user.address_attributes.recipient_name,
        postcode: subscriptionData.user.address_attributes.postcode
      },
      shouldSupportSca: subscriptionData.user.should_support_sca,
      email: subscriptionData.user.email,
      firstName: subscriptionData.user.first_name,
      lastName: subscriptionData.user.last_name,
      password: subscriptionData.user.password,
      passwordConfirmation: subscriptionData.user.password_confirmation,
      phone: subscriptionData.user.phone
    }
  }
}
