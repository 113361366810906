import { gql } from '@apollo/client'

const CHARGE_ID_FROM_PAYMENT_INTENT_ID = gql`
  query ChargeIdFromPaymentIntentId(
    $paymentIntentId: String!
    $paymentMethodType: PaymentMethodType!
  ) {
    chargeIdFromPaymentIntentId(
      paymentIntentId: $paymentIntentId
      paymentMethodType: $paymentMethodType
    )
  }
`

export { CHARGE_ID_FROM_PAYMENT_INTENT_ID }
