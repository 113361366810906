import React, { ReactElement, useCallback } from 'react'

import STYLES from './SkeletonLoading.module.sass'

import SkeletonParagraph from '../SkeletonParagraph/SkeletonParagraph'
import SkeletonTitle from '../SkeletonTitle/SkeletonTitle'

type Props = {
  children: ReactElement
  contentReady: boolean
  template?: ReactElement
}

const SkeletonLoading = ({
  children,
  contentReady,
  template
}: Props): ReactElement => {
  const renderContentWhenLoaded = useCallback((): ReactElement => {
    if (contentReady) {
      return children
    } else {
      return (
        template || (
          <div className={`${STYLES.container}`}>
            <SkeletonTitle />
            <SkeletonParagraph />
            <SkeletonParagraph />
          </div>
        )
      )
    }
  }, [contentReady, children, template])

  return <>{renderContentWhenLoaded()}</>
}

export { Props }
export default SkeletonLoading
