// @flow

import * as React from 'react'
import i18next from 'i18next'

import PencilIcon from '../../../../../../assets/images/icons/pencils/pencil-blue-filled.svg'

import type { BankAccount } from '../../../message_types'

type Props = {|
  bankAccount: BankAccount,
  startEditing: () => void
|}

class CompleteBankAccount extends React.Component<Props> {
  namespace = 'ambassadors:dashboard.balance_tab'

  render (): React.Node {
    return (
      <div>
        <div className='card__header full--width'>
          <p className='card__header__title'>{ i18next.t(`${this.namespace}.account_details`) }</p>
          <button
            className='card__header__button btn-updated btn-updated--grey'
            onClick={this.props.startEditing}
          >
            <img src={PencilIcon} alt='A pencil icon indicating edit functionality' />
            { i18next.t(`${this.namespace}.edit`) }
          </button>
        </div>

        <div className='card__item'>
          <p className='card__item__heading'>{ i18next.t(`${this.namespace}.account_holder`) }</p>
          <p className='capitalized'>{ this.props.bankAccount.account_holder_name }</p>
        </div>

        { this.props.bankAccount.bank_account_type === 'uk' && (
          <React.Fragment>
            <div className='card__item'>
              <p className='card__item__heading'>Account number</p>
              <p className='capitalized'>{ this.props.bankAccount.account_number }</p>
            </div>
            <div className='card__item'>
              <p className='card__item__heading'>Bank sort code</p>
              <p className='capitalized'>{ this.props.bankAccount.sort_code }</p>
            </div>
          </React.Fragment>
        ) }

        { this.props.bankAccount.bank_account_type === 'international' && (
          <React.Fragment>
            <div className='card__item'>
              <p className='card__item__heading'>IBAN</p>
              <p className='capitalized'>{ this.props.bankAccount.iban }</p>
            </div>

            <div className='card__item'>
              <p className='card__item__heading'>Swift code</p>
              <p className='capitalized'>{ this.props.bankAccount.swift_code }</p>
            </div>
          </React.Fragment>
        ) }
      </div>
    )
  }
}

export default CompleteBankAccount
