// @noflow
import { makeVar, useReactiveVar } from '@apollo/client'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripePaymentElementChangeEvent } from '@stripe/stripe-js'
import i18next from 'i18next'
import React, { createRef, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Locale } from '@/utils/currency'
import { Product, captureException } from '@/utils/sentry'
import { getLocalisedPathnames } from '@/utils/urls'

import Lock from 'assets/images/icons/locks/lock.svg'
import dogStanding from 'assets/images/illustrations/dogs/dog-standing.svg'
import dogsMeeting from 'assets/images/illustrations/dogs/dogs-meeting.svg'

import { Expand } from '@/components/elements/atoms/Animated/Animated'
import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'
import Text from '@/components/elements/atoms/Text/Text'
import TextSeparator from '@/components/elements/molecules/TextSeparator/TextSeparator'
import LoadingScreen from '@/components/elements/organisms/LoadingScreen/LoadingScreen'

import type { CheckoutPage } from '../../queries/__generated__/CheckoutPage'

import * as ANALYTICS from '../../Analytics/CheckoutAnalytics'
import { checkoutPageState } from '../../CheckoutPage'
import {
  checkoutPricingState,
  useCheckoutPricing
} from '../../hooks/useCheckoutPricing'
import {
  SubmissionState,
  convertActivePaymentViewToPaymentMethod
} from '../../types'
import Banner, { bannerMessageState } from './Banner'
import PayPalButton from './PayPalButton'
import buySubscriptionWithCard from './helpers/buySubscriptionWithCard'
import buySubscriptionWithStripe from './helpers/buySubscriptionWithStripe'
import { stripePaymentMethodToPaymentMethod } from './helpers/formatPaymentMethod'
import { findPaymentMethodsAvaliable } from './helpers/paymentMethodsAvaliable'
import useBuySubscriptionWithBancontact from './hooks/useBuySubscriptionWithBancontact'
import useBuySubscriptionWithIdeal from './hooks/useBuySubscriptionWithIdeal'
import useBuySubscriptionWithPayPal from './hooks/useBuySubscriptionWithPayPal'
import { useBuySubscriptionWithPaymentRequest } from './hooks/useBuySubscriptionWithPaymentRequest'
import usePaymentView from './hooks/usePaymentView'

type Props = {
  namespace: string
  affiliateType: CheckoutPage['guest']['affiliateType']
  shippingCountryCode: CheckoutPage['guest']['assumedShippingCountry']['code']
  csrfToken: string
  shouldSeeApplePay: boolean
  shouldSeeGooglePay: boolean
  paypalClientId: string
  currency: CheckoutPage['guest']['assumedShippingCountry']['currency']
  locale: Locale
  shouldSeePayPal: boolean
}

const paymentSubmissionState = makeVar<SubmissionState>({
  type: 'not-requested'
})

const setSubmissionState = (state: SubmissionState): void => {
  paymentSubmissionState({
    ...state
  })
}

const PaymentSectionWithStripe = ({
  namespace,
  affiliateType,
  shippingCountryCode,
  csrfToken,
  shouldSeeApplePay,
  shouldSeeGooglePay,
  paypalClientId,
  currency,
  locale,
  shouldSeePayPal
}: Props): JSX.Element => {
  const section = createRef<HTMLElement>()

  const stripe = useStripe()
  const elements = useElements()

  const { t } = useTranslation(namespace)
  const copyContext = 'payment_section'

  const bannerMessage = useReactiveVar(bannerMessageState)
  const submissionState = useReactiveVar(paymentSubmissionState)
  const { firstOrderPricing } = useReactiveVar(checkoutPricingState)

  const { sections, user, plan } = checkoutPageState()
  const { paymentDetails } = sections
  const { visible, form } = paymentDetails
  const { selectedPaymentMethod } = form
  const requiresPayment = user.requiresPaymentDetailsOnCheckout

  const [unmountPaymentElement, setUnmountPaymentElement] = useState(false)

  const togglePaymentElement = () => {
    setUnmountPaymentElement(true)
    setUnmountPaymentElement(false)
  }

  const { paymentRequest, paymentRequestInProgress } =
    useBuySubscriptionWithPaymentRequest({
      shippingCountryCode,
      csrfToken,
      t,
      shouldSeeApplePay,
      shouldSeeGooglePay
    })

  const { activePaymentView, setActivePaymentView, currentPayPalOption } =
    usePaymentView({
      affiliateType,
      shippingCountryCode,
      requiresPayment,
      paymentRequest
    })

  const {
    payPalAuthorisationResponse,
    payPalBillingAgreementTokenReceived,
    payPalButtonClicked,
    payPalPaymentSuccess,
    handlePayPalInContextSubmission,
    fetchPaypalToken
  } = useBuySubscriptionWithPayPal({
    currentPayPalOption,
    csrfToken,
    activePaymentView,
    togglePaymentElement
  })

  useCheckoutPricing({
    planId: plan.planId,
    handlePricingUpdate: fetchPaypalToken
  })

  const { bancontactAuthRecieved } = useBuySubscriptionWithBancontact({
    csrfToken
  })

  const { idealAuthReceived } = useBuySubscriptionWithIdeal({
    csrfToken,
    setActivePaymentView
  })

  const buySubscriptionCallback = useCallback(() => {
    if (!stripe || !elements) {
      bannerMessageState({
        message: i18next.t('checkout:errors.delivery_details_fetch'),
        type: 'error'
      })
      return captureException(
        `Buy subscription button is enabled without Stripe or Stripe Elements initialised`,
        {
          tags: {
            product: Product.Checkout
          }
        }
      )
    }

    const hasDiscount = user?.discount?.code

    switch (activePaymentView) {
      case 'credit-card':
      case 'creditCard':
      case 'googlePay':
      case 'applePay': {
        if (hasDiscount) {
          elements.update({ amount: firstOrderPricing.netTotalPrice })
        }

        buySubscriptionWithStripe({ stripe, elements, csrfToken })

        if (
          activePaymentView === 'googlePay' ||
          activePaymentView === 'applePay'
        ) {
          ANALYTICS.submissionAttempt(activePaymentView)
        } else {
          ANALYTICS.submissionAttempt('creditCard')
        }

        break
      }
      case 'iDeal':
      case 'bancontact': {
        if (hasDiscount) {
          elements.update({ amount: firstOrderPricing.netTotalPrice })
        }
        buySubscriptionWithStripe({ stripe, elements, csrfToken })
        ANALYTICS.submissionAttempt(activePaymentView)
        break
      }
      case 'none': {
        // This would be the case for Admin users. Unfortunately, we cannot use
        // buySubscriptionWithStripe as confirmPayment requires a mounted
        // Stripe Element which is not available in the Admin flow
        buySubscriptionWithCard({ stripe, elements, csrfToken })
        ANALYTICS.submissionAttempt('none')
        break
      }
      default: {
        captureException(
          `activePaymentView is invalid in buySubscriptionCallback on Checkout`,
          {
            extra: {
              activePaymentView
            },
            tags: {
              product: Product.Checkout
            }
          }
        )
      }
    }
  }, [
    activePaymentView,
    csrfToken,
    elements,
    firstOrderPricing.netTotalPrice,
    stripe,
    user?.discount?.code
  ])

  const areAllSectionsValid = () =>
    Object.values(sections).every(({ valid }) => valid)

  const areAllSectionsValidExceptPaymentDetails = () =>
    Object.entries(sections)
      .filter(([key]) => key !== 'paymentDetails')
      .every(([, { valid }]) => valid)

  const disablePayPalButton =
    (submissionState.type === 'loading' &&
      payPalBillingAgreementTokenReceived) ||
    !areAllSectionsValidExceptPaymentDetails()

  const showLoadingScreen =
    payPalPaymentSuccess ||
    bancontactAuthRecieved ||
    idealAuthReceived ||
    (paymentRequestInProgress.paymentMethod === 'googlePay' &&
      paymentRequestInProgress.inProgress)

  const availablePaymentMethods =
    findPaymentMethodsAvaliable(shippingCountryCode)

  const payPalIsAvailable = availablePaymentMethods.includes('payPal')

  const showPayPal =
    requiresPayment &&
    payPalIsAvailable &&
    payPalAuthorisationResponse &&
    payPalAuthorisationResponse?.redirect_url !== null &&
    activePaymentView !== 'none' &&
    shouldSeePayPal

  const showStripePaymentElement =
    requiresPayment && activePaymentView !== 'none' && !unmountPaymentElement

  useEffect(() => {
    if (visible && selectedPaymentMethod.preselected === true) {
      selectedPaymentMethod.type =
        convertActivePaymentViewToPaymentMethod(activePaymentView)
      checkoutPageState({
        ...checkoutPageState()
      })
    }
  }, [activePaymentView, selectedPaymentMethod, visible])

  // This fires when the Stripe PaymentElement first renders on the page. To
  // prevent this from overriding the sepa_direct_debit (iDeal and Bancontact)
  // payments that are in flight, we want to only call this if the redirect
  // success status is not present in the URL
  const handlePaymentElementChange = useCallback(
    (event: StripePaymentElementChangeEvent) => {
      if (!window.location.search.includes('redirect_status=succeeded')) {
        const paymentMethod = stripePaymentMethodToPaymentMethod(
          event.value.type
        )
        selectedPaymentMethod.type = paymentMethod
        selectedPaymentMethod.preselected = false

        checkoutPageState({
          ...checkoutPageState()
        })
        setActivePaymentView(paymentMethod)
        ANALYTICS.paymentOptionsToggle(paymentMethod)
      }
    },
    [selectedPaymentMethod, setActivePaymentView]
  )

  // The handlePaymentElementChange above doesn't ever get fired by Stripe
  // if there is only one payment option present, e.g. only credit card.
  // This causes an issue if the user switches between the credit card and
  // PayPal payment options and we could end up wrongly trying to charge using
  // our PayPal hooks when there is no authorisation for PayPal. The
  // handlePaymentElementFocus callback is used to switch the user back to
  // credit card if they click back on the Stripe Payment Element and only if
  // they selected PayPal previously. Without the PayPal check, this would also
  // wrongly change the user always to card even if they're trying to pay with
  // iDeal on submission
  const handlePaymentElementFocus = useCallback(() => {
    if (selectedPaymentMethod.type === 'payPal') {
      selectedPaymentMethod.type = 'creditCard'
      selectedPaymentMethod.preselected = false

      checkoutPageState({
        ...checkoutPageState()
      })
      setActivePaymentView('creditCard')
      ANALYTICS.paymentOptionsToggle('creditCard')
    }
  }, [selectedPaymentMethod, setActivePaymentView])

  const pathnames = getLocalisedPathnames(shippingCountryCode)

  return (
    <section
      ref={section}
      className={`payment-section section ${
        visible ? 'section--visible' : 'section--hidden'
      }`}
    >
      <Card shadow>
        <div className="card-content">
          <div className="card-title">
            <div className="card-title-wrapper">
              <Text
                namespace={namespace}
                text={`${copyContext}.title`}
                element="h2"
                variant="display20"
                align="left"
                margin={false}
              />
            </div>
            <img src={Lock} alt="" />
          </div>
          {showLoadingScreen && (
            <LoadingScreen
              isOpen={showLoadingScreen}
              title={{
                namespace,
                text: 'loading_screen.text',
                variant: payPalPaymentSuccess ? 'display28' : 'display20'
              }}
              variant={'static'}
              image={payPalPaymentSuccess ? dogStanding : dogsMeeting}
            />
          )}
          <Expand show={visible} margin={{ top: 0.8 }}>
            {showStripePaymentElement && (
              <>
                <PaymentElement
                  options={{
                    layout: 'tabs',
                    terms: {
                      card: 'never',
                      ideal: 'never',
                      bancontact: 'never',
                      applePay: 'never',
                      googlePay: 'never'
                    },
                    // We want to show iDeal and Bancontact first for NL and BE.
                    // Any listed payment methods that aren't available in a
                    // region will be ignored and the remaining payment methods
                    // will be shown, e.g. GB users will see Card first as iDeal
                    // and Bancontact are not available in GB
                    paymentMethodOrder: ['ideal', 'bancontact']
                  }}
                  onFocus={handlePaymentElementFocus}
                  onChange={handlePaymentElementChange}
                />
                <div className="checkout__continue-button">
                  <Button
                    typography={{
                      namespace,
                      text:
                        submissionState.type === 'loading'
                          ? 'continue_button.loading'
                          : 'continue_button.complete'
                    }}
                    dataTestId="checkout-continue-to-pay-button"
                    onClick={buySubscriptionCallback}
                    disabled={submissionState.type === 'loading'}
                    fullWidth
                    disableAnalytics
                  />
                </div>
              </>
            )}
            {showPayPal && (
              <>
                <TextSeparator
                  namespace={namespace}
                  text={`${copyContext}.payment_options_view.or`}
                />
                <PayPalButton
                  paypalClientId={paypalClientId}
                  currency={currency}
                  payPalButtonClicked={payPalButtonClicked}
                  disablePayPalButton={disablePayPalButton}
                  payPalAuthorisationResponse={payPalAuthorisationResponse}
                  handlePayPalInContextSubmission={
                    handlePayPalInContextSubmission
                  }
                  locale={locale}
                />
              </>
            )}
            {/* Admin payment button view */}
            {!requiresPayment && (
              <div className="checkout__continue-button checkout__continue-button--purchase">
                <Button
                  typography={{
                    namespace,
                    text:
                      submissionState.type === 'loading'
                        ? 'continue_button.loading'
                        : 'continue_button.order'
                  }}
                  disabled={
                    submissionState.type === 'loading' ||
                    (!areAllSectionsValid() && activePaymentView !== 'none')
                  }
                  onClick={buySubscriptionCallback}
                  fullWidth
                  disableAnalytics
                />
              </div>
            )}
            <div className="payment-disclaimer">
              <Text
                namespace={namespace}
                text="disclaimer.title"
                colour="brandBlue400"
                variant="display16"
                margin={false}
                align="left"
                shouldScale={false}
              />
              <Text
                namespace={namespace}
                text="disclaimer.terms_html"
                variables={{
                  termsUrl: `/${pathnames.TERMS_OF_USE}`,
                  termsConditionsUrl: `/${pathnames.TERMS_AND_CONDITIONS}`
                }}
                colour="brandBlue400"
                variant="textRegular16"
                margin={false}
                align="left"
                shouldScale={false}
              />
            </div>
          </Expand>
        </div>
      </Card>
      {bannerMessage.message && (
        <Banner type={bannerMessage.type} message={bannerMessage.message} />
      )}
    </section>
  )
}

export default PaymentSectionWithStripe

export { paymentSubmissionState, setSubmissionState }
