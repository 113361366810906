// @noflow
import type { AddressDetailsFormKey } from '@/components/pages/CheckoutPage/types'

import type {
  Code as CountryCode,
  ShippingCountry
} from '@/shared_types/rails_models/shipping_countries'

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectAddress: (result: any, shippingCountryCode: CountryCode) => void
  showAddressFields: () => void
  shippingCountryCode: CountryCode
  activeShippingCountries: Array<ShippingCountry>
}

type FetchifyInput = {
  accessToken: string
  dom: {
    search: AddressDetailsFormKey
    town: AddressDetailsFormKey
    postcode: AddressDetailsFormKey
    line_1: AddressDetailsFormKey
    line_2: AddressDetailsFormKey
  }
  domMode: string
  defaultCountry: CountryCode
  countrySelector: boolean
  getIpLocation: boolean
  placeholders: boolean
  enabledCountries: Array<CountryCode>
  countryMatchWith: string
  // The object, dom and result objects come from Fetchify and contain a long
  // list of properties which, for the purpose of this usage here, didn't seem
  // necessary to apply explicit typing
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onResultSelected: (object: any, dom: any, result: any) => void
}

const FetchifyParams = ({
  selectAddress,
  showAddressFields,
  shippingCountryCode,
  activeShippingCountries
}: Props): FetchifyInput => {
  // We filter out 'NI' here as Fetchify (and actually officially everywhere
  // else) identify this as Nicaragua and not Northern Ireland in two digit ISO
  const shippingCountryCodes = activeShippingCountries
    .filter((shippingCountry) => !['NI'].includes(shippingCountry.code))
    .map(
      (shippingCountry: ShippingCountry): CountryCode => shippingCountry.code
    )
  return {
    accessToken: 'd4c88-e73a8-4e36e-067da',
    dom: {
      search: 'searchTerm',
      town: 'city',
      postcode: 'postcode',
      line_1: 'addressLine1',
      line_2: 'addressLine2'
    },
    domMode: 'id',
    defaultCountry: shippingCountryCode,
    countrySelector: false,
    getIpLocation: false,
    placeholders: false,
    enabledCountries: shippingCountryCodes,
    countryMatchWith: 'iso_2',
    onResultSelected: function (object, dom, result) {
      showAddressFields()
      selectAddress(result, shippingCountryCode)
    }
  }
}

export default FetchifyParams
