// @noflow
import { useLanguage } from '@/context/injectedValues/injectedValues'
import type { Language } from '@/packs/localisation'
import upperFirst from 'lodash/upperFirst'
import React, { useState } from 'react'

import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { getURLParam } from '@/utils/getURLParam'

import { InAccountRafQuery } from '@/hooks/useInAccountRaf/queries/__generated__/InAccountRafQuery'
import useInAccountRaf from '@/hooks/useInAccountRaf/useInAccountRaf'

import DogStanding from 'assets/images/illustrations/dogs/dog-standing.svg'

// Components
import RafTabBar from './components/GiveAndGetTab/RafTabBar/RafTabBar'
import ReferralSavingsModal from './components/GiveAndGetTab/ReferralSavingsModal/ReferralSavingsModal'
import RuffGuideModal from './components/GiveAndGetTab/RuffGuideModal/RuffGuideModal'
import { Portal } from '@/components/elements/atoms/Portal'
import LoadingScreen from '@/components/elements/organisms/LoadingScreen/LoadingScreen'
import DonationsModal from '@/components/pages/ReferAFriendPage/components/GiveAndGetTab/DonationsModal/DonationsModal'
import GiveAndGetTab from '@/components/pages/ReferAFriendPage/components/GiveAndGetTab/GiveAndGetTab'
import RAFPassModal from '@/components/pages/ReferAFriendPage/components/GiveAndGetTab/RAFPassModal/RAFPassModal'
import MyPackTab from '@/components/pages/ReferAFriendPage/components/MyPackTab/MyPackTab'
import Base from '@/components/templates/Base'
import RafCampaign from '@/components/templates/RafCampaign/RafCampaign'

// Styles
import STYLES from './ReferAFriendPage.module.sass'

type ReferAFriendPageViewProps = {
  preferredLanguage: Language
  namespace: string
  copyContext: string
  data: InAccountRafQuery
}

enum RAFTabNames {
  GiveAndGet = 'giveAndGet',
  MyPack = 'myPack',
  Campaign = 'campaign'
}

const ReferAFriendPageView = ({
  preferredLanguage,
  namespace,
  copyContext,
  data
}: ReferAFriendPageViewProps): JSX.Element => {
  const {
    copyLinkMessage,
    customerDiscountCopy,
    discountCode,
    referralDiscountCopy,
    shippingCountryCode,
    campaignEndDate
  } = useInAccountRaf()
  const { user } = data
  const { firstName, dogs, subscription } = user
  const { activeRafCampaignVariant, referralLink } = subscription
  const rafCampaignTemplateData = activeRafCampaignVariant?.view?.data

  const showRafCampaign =
    !!activeRafCampaignVariant?.rafCampaign.name &&
    !!activeRafCampaignVariant?.view

  const tabs = [
    {
      /* eslint-disable i18next/no-literal-string */
      label: `${copyContext}.give_and_get_tab.tab_title`,
      value: RAFTabNames.GiveAndGet,
      hidden: showRafCampaign
    },
    {
      label: rafCampaignTemplateData?.name?.tab_name || '',
      value: RAFTabNames.Campaign,
      hidden: !showRafCampaign,
      translate: false
    },
    {
      label: `${copyContext}.my_pack_tab.tab_title`,
      value: RAFTabNames.MyPack
    }
    /* eslint-enable i18next/no-literal-string */
  ]

  const DEFAULT_ACTIVE_TAB = showRafCampaign
    ? RAFTabNames.Campaign
    : RAFTabNames.GiveAndGet

  const openTab = getURLParam(2)
  const initialTab = openTab || DEFAULT_ACTIVE_TAB
  const [selectedTab, setSelectedTab] = useState(initialTab)

  const showRafCharityButton =
    data?.shippingCountries.find(({ code }) => code === shippingCountryCode)
      ?.showRafCharityButton || false

  const { locale, currency } = countryCodeToLocaleCurrency(
    shippingCountryCode,
    preferredLanguage
  )

  return (
    <Base background={undefined}>
      <div className={STYLES.pageWrapper}>
        <div className={STYLES.container}>
          <Portal
            target="sub-header-content"
            element={
              <RafTabBar
                tabSelected={selectedTab}
                setTab={setSelectedTab}
                namespace={namespace}
                tabs={tabs}
                align="left"
              />
            }
          />
          <div
            className={`${STYLES.tab} ${
              selectedTab === RAFTabNames.GiveAndGet && STYLES.show
            }`}
          >
            <GiveAndGetTab
              namespace={namespace}
              locale={locale}
              referralLink={referralLink}
              copyLinkMessage={copyLinkMessage}
              currency={currency}
            />
          </div>
          <div
            className={`${STYLES.tab} ${
              selectedTab === RAFTabNames.Campaign && STYLES.show
            } ${STYLES.fullWidth}`}
          >
            <RafCampaign
              copyLinkMessage={copyLinkMessage}
              ctaText={rafCampaignTemplateData?.cta.text || ''}
              customerDiscountCopy={customerDiscountCopy}
              referralDiscountCopy={referralDiscountCopy || []}
              description={rafCampaignTemplateData?.description.text || ''}
              heading={rafCampaignTemplateData?.heading.text || ''}
              heroImageSlug={rafCampaignTemplateData?.hero_image.slug || ''}
              heroImageAlt={
                rafCampaignTemplateData?.hero_image.hero_alt_text || ''
              }
              infoText={rafCampaignTemplateData?.terms.terms || ''}
              namespace={namespace}
              backgroundColour={
                rafCampaignTemplateData?.background.colour || ''
              }
              referralCode={discountCode || ''}
              userId={data?.user.id || ''}
              bannerColour={
                rafCampaignTemplateData?.hero_banner.banner_colour || ''
              }
              countdownBackgroundColour={
                rafCampaignTemplateData?.countdown_banner.banner_colour || ''
              }
              countdownTextColour={
                rafCampaignTemplateData?.countdown_banner.text_colour || ''
              }
              enableConfetti
              expiryDate={campaignEndDate}
              countdownText={
                rafCampaignTemplateData?.countdown_banner.banner_copy || ''
              }
              sticker={rafCampaignTemplateData?.sticker.slug || ''}
              stickerAlt={
                rafCampaignTemplateData?.sticker.sticker_alt_text || ''
              }
              ctaVariant={rafCampaignTemplateData?.cta.variant || ''}
              textColour={rafCampaignTemplateData?.text.colour || ''}
              addEmailInputColour={rafCampaignTemplateData?.text.colour || ''}
              preferredLanguage={preferredLanguage}
              shippingCountryCode={shippingCountryCode}
              maxEmailInputs={3}
              termsAndConditionsUrl={rafCampaignTemplateData?.terms.URL || ''}
            />
          </div>
          <div
            className={`${STYLES.tab} ${
              selectedTab === RAFTabNames.MyPack && STYLES.show
            }`}
          >
            <MyPackTab namespace={namespace} referralLink={referralLink} />
          </div>
        </div>
        <RAFPassModal
          namespace={namespace}
          referralLink={referralLink}
          dogNames={dogs?.map(({ name }) => upperFirst(name)) || []}
          firstName={firstName}
          avatarUrl={
            dogs && dogs[0].dogProfile?.avatarUrl
              ? dogs[0].dogProfile?.avatarUrl
              : ''
          }
        />
        <DonationsModal
          namespace={namespace}
          referralLink={referralLink}
          showRafCharityButton={showRafCharityButton}
        />
        <ReferralSavingsModal
          namespace={namespace}
          referralLink={referralLink}
          shippingCountryCode={shippingCountryCode}
          preferredLanguage={preferredLanguage}
        />
        <RuffGuideModal namespace={namespace} />
      </div>
    </Base>
  )
}

const ReferAFriendPage = (): JSX.Element | null => {
  const namespace = 'dashboard'
  const copyContext = 'refer_a_friend'

  const { userLanguage } = useLanguage()

  // Pre-load data
  const { loading, data } = useInAccountRaf()

  return loading || !data ? (
    <LoadingScreen
      isOpen={loading}
      title={{ namespace, text: `${copyContext}.loading_screen.text` }}
      variant={'static'}
      image={DogStanding}
    />
  ) : (
    <ReferAFriendPageView
      preferredLanguage={userLanguage}
      namespace={namespace}
      copyContext={copyContext}
      data={data}
    />
  )
}

export { ReferAFriendPage }

export default ReferAFriendPage
