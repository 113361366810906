import { gql } from '@apollo/client'

const BREED_SELECTOR_QUERY = gql`
  query BreedSelectorQuery {
    breeds {
      id
      name
      key
      popular
    }
  }
`

export { BREED_SELECTOR_QUERY }
