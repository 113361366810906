import { gql } from '@apollo/client'

const CREATE_PAYMENT_METHOD_MUTATION_STRING = `
mutation CreatePaymentMethodMutation(
  $userId: ID!,
  $paymentMethods: [PaymentMethodInput!]!
) {
  paymentMethodsCreate(
    userId: $userId,
    paymentMethods: $paymentMethods
  ) {
      id
    }
  }
`

const CREATE_PAYMENT_METHOD_MUTATION = gql`
  mutation CreatePaymentMethodMutation(
    $userId: ID!
    $paymentMethods: [PaymentMethodInput!]!
  ) {
    paymentMethodsCreate(userId: $userId, paymentMethods: $paymentMethods) {
      id
    }
  }
`

export { CREATE_PAYMENT_METHOD_MUTATION, CREATE_PAYMENT_METHOD_MUTATION_STRING }
