// @noflow
import type { Language } from '@/packs/localisation'
import initI18n from '@/packs/localisation'
import { useQuery, useReactiveVar } from '@apollo/client'
import i18next from 'i18next'
import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'
import React from 'react'
// Routing
import { Route, Routes as RouterRoutes, useLocation } from 'react-router-dom'

import withApollo from '@/components/apollo/withApollo'
import TopNavBanner from '@/components/elements/molecules/TopNavBanner/TopNavBanner'
import {
  deliveryCadenceState,
  initialState,
  planRecommendationState,
  plansFlowTypeState,
  plansPageState,
  plansPageWizardState,
  recommendedExtraProductVariantsState
} from '@/components/pages/PlansPage/PlansPage'
import type { GuestQuery } from '@/components/pages/PlansPage/__generated__/GuestQuery'
import { GUEST_QUERY } from '@/components/pages/PlansPage/queries'
import NoNavigationFullWidth from '@/components/templates/NoNavigationFullWidth/NoNavigationFullWidth'

import { triggerAnalytics } from './helpers/analytics'
import Extras from './screens/Extras/Extras'
import Plan from './screens/Plan/Plan'
// Screens
import Recipes from './screens/Recipes/Recipes'
import Review from './screens/Review/Review'
import ReviewSharedPlan from './screens/ReviewSharedPlan/ReviewSharedPlan'
import Routes from './types/routes'
import type { Route as PlanRoute } from './types/routes'

type Props = {
  variant: Language
}

const Plans = ({ variant }: Props): JSX.Element => {
  const { loading, data } = useQuery<GuestQuery>(GUEST_QUERY)

  const location = useLocation().pathname as PlanRoute

  const planState = useReactiveVar(plansPageState)
  const wizardState = useReactiveVar(plansPageWizardState)
  const recommendationState = useReactiveVar(planRecommendationState)
  const cadenceState = useReactiveVar(deliveryCadenceState)
  const recommendedExtraProductVariants = useReactiveVar(
    recommendedExtraProductVariantsState
  )
  const flowTypeState = useReactiveVar(plansFlowTypeState)

  const namespace = 'plans_flow'
  const maxFlavours = 7

  if (!i18next.isInitialized) initI18n(variant)

  // Scroll to top of window on each page change
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  React.useEffect(() => {
    if (!isNil(recommendationState) && !isEqual(planState, initialState))
      triggerAnalytics(
        location,
        planState,
        wizardState,
        recommendationState,
        cadenceState,
        flowTypeState
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, recommendationState])

  React.useEffect(() => {
    if (data) {
      plansFlowTypeState(
        data?.currentUser?.__typename === 'Guest' ? 'new' : 'add'
      )

      // Store the amount of recommended extras in Apollo state so we can control
      // whether a customer should see the Extras step if no products can be
      // recommended to them.
      if (
        data.currentUser?.__typename === 'Guest' ||
        data.currentUser?.__typename === 'User'
      ) {
        recommendedExtraProductVariantsState(
          data.currentUser.planRecommendation
            ?.recommendedExtraProductVariants || []
        )
      }
    }
  }, [data])

  if (loading || !data) {
    return <div />
  }

  return (
    <NoNavigationFullWidth variant={variant}>
      {flowTypeState === 'new' && data?.currentUser?.__typename === 'Guest' && (
        <TopNavBanner
          shippingCountryCode={data.currentUser.assumedShippingCountry.code}
          preferredLanguage={data.currentUser.preferredLanguage}
        />
      )}
      <RouterRoutes>
        <Route
          path={Routes.Recipes}
          element={<Recipes namespace={namespace} maxFlavours={maxFlavours} />}
        />
        <Route
          path={Routes.Plan}
          element={<Plan namespace={namespace} maxFlavours={maxFlavours} />}
        />

        <Route
          path={Routes.Extras}
          element={
            <Extras
              namespace={namespace}
              recommendedExtraProductVariants={recommendedExtraProductVariants}
            />
          }
        />

        <Route
          path={Routes.Review}
          element={
            flowTypeState === 'add' &&
            data?.currentUser?.__typename === 'User' ? (
              <ReviewSharedPlan
                namespace={namespace}
                maxFlavours={maxFlavours}
              />
            ) : (
              <Review namespace={namespace} maxFlavours={maxFlavours} />
            )
          }
        />
      </RouterRoutes>
    </NoNavigationFullWidth>
  )
}

export { Plans, GUEST_QUERY }
export default withApollo(Plans)
