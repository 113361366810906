// @noflow
import React from 'react'

import NavLogo from '@/components/elements/atoms/NavLogo/NavLogo'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './KeptSubscription.module.sass'

const KeptSubscription = (): JSX.Element => (
  <div className={STYLES.container}>
    <div className={STYLES.logo}>
      <NavLogo size="large" />
    </div>
    <Text
      namespace="dashboard"
      text={'kept_subscription.title'}
      margin={false}
      variant="display16"
      align="center"
    />
  </div>
)

export default KeptSubscription
