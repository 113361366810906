// @noflow
import React from 'react'
import type { ReactElement } from 'react'

import DogNamesInput from '../DogNamesInput'
import EmailInput from '../EmailInput'
import MessageTextArea from '../MessageTextArea'
import NameInput from '../NameInput'
import Recaptcha from '../Recaptcha'
import SubmitButton from '../SubmitButton'

const GenericForm = (): ReactElement => (
  <React.Fragment>
    <MessageTextArea />
    <NameInput />
    <DogNamesInput />
    <EmailInput />
    <Recaptcha />
    <SubmitButton />
  </React.Fragment>
)

export default GenericForm
