// @noflow

type Route =
  | '/self-resolution'
  | '/self-resolution/categories'
  | '/self-resolution/contact-customer-love'
  | '/self-resolution/quick-help'
  | '/self-resolution/report-issue'
  | '/self-resolution/resolution-offering'
  | '/self-resolution/resolution-confirmation'
  | '/self-resolution/oops'

const Default: Route = '/self-resolution'
const Categories: Route = '/self-resolution/categories'
const ContactCustomerLove: Route = '/self-resolution/contact-customer-love'
const QuickHelpResolution: Route = '/self-resolution/quick-help'
const ReportIssue: Route = '/self-resolution/report-issue'
const ResolutionOffering: Route = '/self-resolution/resolution-offering'
const ResolutionConfirmation: Route = '/self-resolution/resolution-confirmation'
const Error: Route = '/self-resolution/oops'

const SelfResolutionRoutes = {
  Default,
  Categories,
  ContactCustomerLove,
  QuickHelpResolution,
  ReportIssue,
  ResolutionOffering,
  ResolutionConfirmation,
  Error
}

export type { Route }

export default SelfResolutionRoutes
