// @noflow
import React, { useCallback, useContext, useEffect, useMemo } from 'react'

import { formatCurrencyWithDecimal } from '@/utils/currency'

import useBoolean from '@/hooks/useBoolean'

import Icon from '@/components/elements/atoms/Icon/Icon'
import Text from '@/components/elements/atoms/Text/Text'
import NotificationContainer from '@/components/elements/molecules/NotificationContainer/NotificationContainer'

import STYLES from './ProductVariantFrequencyDropDown.module.sass'

import type {
  ProductCollectionQuery_productCollection as ProductCollection,
  ProductCollectionQuery
} from '../../../queries/__generated__/ProductCollectionQuery'

import type { VariantDelivery } from '../../../../../../../../types/index'
import { ProductCollectionContext } from '../../../ProductCollection'
import FrequencyVariantIcon from '../../atoms/FrequencyVariantIcon'

type Props = {
  nonCoreOrderDeliveryFee: ProductCollectionQuery['nonCoreOrderDeliveryFee']
  name: ProductCollection['name']
  productCollectionSlug: ProductCollection['slug']
  selectedProductVariantFrequency: VariantDelivery
  setSelectedProductVariantFrequency: (variantDelivery: VariantDelivery) => void
}

const ProductVariantFrequencyDropDown = ({
  nonCoreOrderDeliveryFee,
  name,
  productCollectionSlug,
  selectedProductVariantFrequency,
  setSelectedProductVariantFrequency
}: Props): JSX.Element | null => {
  const copyContext =
    'extras.product_collection.details.product_variant_frequencies.option'

  const { locale, currency, isUpdateProductInProgress } = useContext(
    ProductCollectionContext
  )

  const {
    value: isOneOffOpen,
    toggle: toggleIsOneOffOpen,
    setFalse: setOneOffClosed
  } = useBoolean(false)

  const formattedDeliveryFee = useMemo(
    () =>
      formatCurrencyWithDecimal(nonCoreOrderDeliveryFee, {
        locale: locale,
        currency: currency
      }),
    [nonCoreOrderDeliveryFee, locale, currency]
  )

  const isOneOffFrequencySelected = useMemo(
    () => selectedProductVariantFrequency === 'one_off',
    [selectedProductVariantFrequency]
  )

  const isOnDemandFrequencySelected = useMemo(
    () => selectedProductVariantFrequency === 'on_demand',
    [selectedProductVariantFrequency]
  )

  useEffect(() => {
    if (selectedProductVariantFrequency === 'recurring') {
      setOneOffClosed()
    }
  }, [selectedProductVariantFrequency, setOneOffClosed])

  // Handlers
  const handleSetSelectedOneOffFrequency = useCallback((): void => {
    setSelectedProductVariantFrequency('one_off' as VariantDelivery)
  }, [setSelectedProductVariantFrequency])

  const handleSetSelectedOnDemandFrequency = useCallback((): void => {
    setSelectedProductVariantFrequency('on_demand' as VariantDelivery)
  }, [setSelectedProductVariantFrequency])

  const handleToggleIsOneOffOpen = useCallback((): void => {
    if (!isOneOffOpen) {
      window.analytics.track(
        'Interacted With One-off Dropdown On Product Details Page',
        {
          productCollectionSlug
        }
      )
      handleSetSelectedOneOffFrequency()
    }
    toggleIsOneOffOpen()
  }, [
    handleSetSelectedOneOffFrequency,
    isOneOffOpen,
    productCollectionSlug,
    toggleIsOneOffOpen
  ])

  return (
    <div>
      <div
        className={`${STYLES.wrapper} ${
          isOneOffFrequencySelected || isOnDemandFrequencySelected
            ? STYLES.selected
            : ''
        }`}
      >
        <button
          type="button"
          className={`${STYLES.container} ${
            isUpdateProductInProgress ? STYLES.disabled : ''
          }`}
          onClick={handleToggleIsOneOffOpen}
          disabled={isUpdateProductInProgress}
        >
          <div className={STYLES.leftContent}>
            <div className={STYLES.textContent}>
              <Text
                namespace="dashboard"
                text={`${copyContext}.title_add_one_off`}
                variant="textRegular16"
                shouldScale={false}
                margin={false}
                align="left"
                bold
              />
              <Text
                namespace="dashboard"
                text={`${copyContext}.description_add_one_off`}
                variant="textRegular14"
                shouldScale={false}
                margin={false}
                align="left"
              />
            </div>
          </div>
          <div className={STYLES.chevronWrapper}>
            <Icon
              asset="chevron"
              size={19}
              direction={isOneOffOpen ? 'up' : 'down'}
            />
          </div>
        </button>
        {isOneOffOpen && (
          <>
            <button
              type="button"
              className={`${STYLES.container} ${
                isUpdateProductInProgress ? STYLES.disabled : ''
              }`}
              onClick={handleSetSelectedOneOffFrequency}
              disabled={isUpdateProductInProgress}
            >
              <div className={STYLES.leftContent}>
                <div className={STYLES.iconWrapper}>
                  {isOneOffFrequencySelected && (
                    <Icon asset="checkmark" size={19} />
                  )}
                </div>
                <div className={STYLES.textContent}>
                  <Text
                    element="p"
                    namespace="dashboard"
                    text={`${copyContext}.title`}
                    variables={{ context: 'one_off' }}
                    variant="textRegular16"
                    shouldScale={false}
                    bold={isOneOffFrequencySelected}
                    margin={false}
                  />
                  <Text
                    element="p"
                    namespace="dashboard"
                    text={`${copyContext}.description`}
                    variables={{ context: 'one_off', productName: name }}
                    variant="textRegular14"
                    shouldScale={false}
                    margin={false}
                  />
                </div>
              </div>
              <FrequencyVariantIcon
                deliveryType={'one_off' as VariantDelivery}
              />
            </button>

            <button
              type="button"
              className={`${STYLES.container} ${
                isUpdateProductInProgress ? STYLES.disabled : ''
              }`}
              onClick={handleSetSelectedOnDemandFrequency}
              disabled={isUpdateProductInProgress}
            >
              <div className={STYLES.leftContent}>
                <div className={STYLES.iconWrapper}>
                  {isOnDemandFrequencySelected && (
                    <Icon asset="checkmark" size={19} />
                  )}
                </div>
                <div className={STYLES.textContent}>
                  <Text
                    element="p"
                    namespace="dashboard"
                    text={`${copyContext}.title`}
                    variables={{ context: 'on_demand' }}
                    variant="textRegular16"
                    shouldScale={false}
                    bold={isOnDemandFrequencySelected}
                    margin={false}
                  />
                  <Text
                    element="p"
                    namespace="dashboard"
                    text={`${copyContext}.description`}
                    variables={{ context: 'on_demand', productName: name }}
                    variant="textRegular14"
                    shouldScale={false}
                    margin={false}
                  />
                  {nonCoreOrderDeliveryFee && (
                    <div className={STYLES.deliveryFee}>
                      <Icon size={14} asset="vanFilled" />
                      <Text
                        text="extras.separate_delivery.get_it_sooner.delivery_fee"
                        namespace="dashboard"
                        variables={{
                          deliveryFee: formattedDeliveryFee
                        }}
                        colour="brandBlue500"
                        variant="textRegular14"
                        margin={false}
                        shouldScale={false}
                      />
                    </div>
                  )}
                </div>
              </div>
              <FrequencyVariantIcon
                deliveryType={'on_demand' as VariantDelivery}
              />
            </button>
          </>
        )}
      </div>
      <NotificationContainer autoClose={5000} />
    </div>
  )
}

export default ProductVariantFrequencyDropDown
