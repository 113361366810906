// @noflow
import React from 'react'
import { useTranslation } from 'react-i18next'

// Utils
import { PronounContext } from '@/utils/StringHelper'

// Breakpoints
import Breakpoints from '@/constants/Breakpoints'

// Hooks
import useWindowSize from '@/hooks/useWindowSize'

import Card from '@/components/elements/atoms/Card/Card'
// Components
import Icon from '@/components/elements/atoms/Icon/Icon'
import Image from '@/components/elements/atoms/Image/Image'
import SkeletonImage from '@/components/elements/atoms/SkeletonImage/SkeletonImage'
import SkeletonTitle from '@/components/elements/atoms/SkeletonTitle/SkeletonTitle'
import Text from '@/components/elements/atoms/Text'
import Tooltip from '@/components/elements/atoms/Tooltip/Tooltip'

// Styles
import STYLES from './starterboxcard.module.sass'

import { SelectedPlan } from '../../../../hooks/useIdealPlan'

type Props = {
  namespace: string
  copyContext: string
  selectedPlan?: SelectedPlan
  idealPlans: Array<SelectedPlan | undefined>
  dogNames: Array<string>
  dogPronoun: PronounContext
  loading: boolean
  handleTooltipOpened: () => void
}

const SkeletonStarterBoxCard = (): JSX.Element => {
  const { windowWidth } = useWindowSize()
  const isDesktop = windowWidth >= Breakpoints.lg
  return (
    <Card padding={isDesktop ? 32 : 16} mask>
      <SkeletonTitle align="left" height={28} width={'50%'} />
      <SkeletonTitle align="left" height={16} width={'100%'} />
      <SkeletonTitle align="left" height={16} />
      <div className={STYLES.boxCardWrapper}>
        <SkeletonImage height={178} />
      </div>
      <div className={STYLES.pouchesCopy}>
        <SkeletonTitle align="center" height={24} />
        <SkeletonTitle align="center" height={20} />
        <SkeletonTitle align="center" height={16} />
      </div>
    </Card>
  )
}

const StarterBoxCard = ({
  namespace,
  copyContext,
  selectedPlan,
  idealPlans,
  dogNames,
  dogPronoun,
  loading,
  handleTooltipOpened
}: Props): JSX.Element | null => {
  const { t } = useTranslation(namespace)
  const { windowWidth } = useWindowSize()
  const isDesktop = windowWidth >= Breakpoints.lg

  if (loading || !selectedPlan) {
    return <SkeletonStarterBoxCard />
  }

  const {
    trial,
    pouchesPerDay,
    pouchSize,
    gramsPerDayPerDogs,
    typeOfPlanForCustomer
  } = selectedPlan
  const { lengthInDays: trialLengthInDays } = trial
  const totalPouches = trialLengthInDays * pouchesPerDay

  const showLargestPlanWarning =
    idealPlans.length === 1 && typeOfPlanForCustomer !== 'all'

  return (
    <div className={STYLES.starterBoxCard}>
      <Card padding={isDesktop ? 32 : 16} mask>
        <Text
          namespace={namespace}
          variant="display20"
          text={`${copyContext}.box_breakdown.title`}
          align={isDesktop ? 'left' : 'center'}
          margin={false}
        />
        <Text
          namespace={namespace}
          variant="textRegular16"
          colour="brandBlue400"
          text={`${copyContext}.box_breakdown.subtitle`}
          variables={{
            dogName: dogNames,
            context: dogPronoun,
            count: dogNames.length,
            cadenceInWeeks: trialLengthInDays / 7
          }}
          align={isDesktop ? 'left' : 'center'}
          margin={false}
        />
        <div className={STYLES.boxCardWrapper}>
          <Card variant="brandYellow100" padding={0}>
            <div className={STYLES.pouchesCard}>
              <div className={STYLES.contentWrapper}>
                <Image
                  className={STYLES.image}
                  slug="spaniel-watching-butternut-box-with-meals-and-meal-bowl-on-counter"
                  alt={t(`${copyContext}.box_breakdown.img_alt`)}
                  image={{
                    width: 414,
                    height: 178
                  }}
                />
                <div className={STYLES.pouchesCopy}>
                  <Text
                    namespace={namespace}
                    variant="display20"
                    text={`${copyContext}.box_breakdown.total_pouches`}
                    variables={{
                      totalPouches,
                      pouchSize
                    }}
                    margin={false}
                    align="center"
                    element="h2"
                  />
                  {gramsPerDayPerDogs.length > 1 ? (
                    gramsPerDayPerDogs.map((dog) => (
                      <div className={STYLES.amountPerDog} key={dog.name}>
                        <Text
                          namespace={namespace}
                          variant="textRegular16"
                          text={`${copyContext}.box_breakdown.total_days_dog`}
                          variables={{
                            totalDays: trialLengthInDays,
                            dogName: dog.name
                          }}
                          margin={false}
                          align="center"
                        />
                        <Text
                          namespace={namespace}
                          variant="textRegular16"
                          text={`${copyContext}.box_breakdown.per_day`}
                          variables={{ amount: dog.gramsPerDay }}
                          margin={false}
                          align="center"
                        />
                      </div>
                    ))
                  ) : (
                    <Text
                      namespace={namespace}
                      variant="textRegular16"
                      text={`${copyContext}.box_breakdown.total_days`}
                      variables={{ totalDays: trialLengthInDays }}
                      margin={false}
                      align="center"
                    />
                  )}
                  {showLargestPlanWarning ? (
                    <div className={STYLES.largestPlanWarningCard}>
                      <Card variant="brandYellow200">
                        <div className={STYLES.largestPlanWarning}>
                          <div className={STYLES.iconWrapper}>
                            <Icon
                              asset="alert"
                              accentColour="supportBlue400"
                              size={14}
                              width={14}
                            />
                          </div>
                          <Text
                            namespace={namespace}
                            variant="textRegular14"
                            text={`${copyContext}.largest_plan_warning`}
                            variables={{
                              dogName: dogNames,
                              context: dogPronoun,
                              count: dogNames.length
                            }}
                            align="left"
                            margin={false}
                          />
                        </div>
                      </Card>
                    </div>
                  ) : (
                    <div className={STYLES.tooltipWrapper}>
                      <Tooltip
                        label={
                          windowWidth > Breakpoints.sm
                            ? {
                                namespace,
                                text: `${copyContext}.box_breakdown.tooltip_trigger`,
                                colour: 'brandBlue400'
                              }
                            : undefined
                        }
                        size={16}
                        onOpen={handleTooltipOpened}
                        identifier="box_breakdown.tooltip_trigger"
                      >
                        <Text
                          namespace={namespace}
                          variant="textRegular16"
                          text={`${copyContext}.box_breakdown.tooltip_info`}
                          variables={{
                            dogName: dogNames,
                            context: dogPronoun,
                            count: dogNames.length
                          }}
                          align="center"
                        />
                      </Tooltip>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Card>
        </div>
      </Card>
    </div>
  )
}

export default StarterBoxCard
