import upperFirst from 'lodash/upperFirst'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import ShareProfileImage from 'assets/images/illustrations/share-profile.svg'

import { Analytics } from '@/components/analytics/Analytics'
import Card from '@/components/elements/atoms/Card/Card'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './ShareProfile.module.sass'

import { ShareProfileFragment_dogs } from './fragment/__generated__/ShareProfileFragment'

type Props = {
  dog: ShareProfileFragment_dogs
  shareLink: string
}

const ShareProfile = ({ dog, shareLink }: Props): JSX.Element => {
  const { t } = useTranslation('account')

  const handleShareDogProfile = useCallback(() => {
    const dogProfile = dog.dogProfile

    if (!dogProfile) {
      return null
    }

    const shareableProfile = {
      quirk: dogProfile.dogPersonalityTraits[0].trait,
      favouriteGame: dogProfile.favouriteGame,
      url: dogProfile.avatarUrl
    }
    const encodedShareableProfile = btoa(JSON.stringify(shareableProfile))
    // eslint-disable-next-line i18next/no-literal-string
    const facebookShareUrl = 'https://www.facebook.com/sharer/sharer.php?u='
    // eslint-disable-next-line i18next/no-literal-string
    const shareUrl = `${shareLink}?dog_profile_image=true&version=${encodedShareableProfile}`
    const encodedShareUrl = encodeURIComponent(shareUrl)
    window.open(`${facebookShareUrl}${encodedShareUrl}`, '_blank')
    Analytics.track('Shared Dog Profile', {})
  }, [dog.dogProfile, shareLink])

  return (
    <Card
      identifier="dog_profile.share_profile"
      className={STYLES.wrapper}
      variant="brandYellow200"
      fill={false}
      onClick={handleShareDogProfile}
    >
      <div>
        <Text
          text="dog_profile.share_profile.title"
          namespace="account"
          variables={{ dogName: upperFirst(dog.name) }}
          align="left"
          colour="brandBlue500"
          variant="display20"
          margin={false}
        />
        <Text
          text="dog_profile.share_profile.description"
          namespace="account"
          variables={{ possessivePronoun: dog.possessivePronoun }}
          align="left"
          colour="brandBlue400"
          variant="textRegular18"
          margin={false}
        />
      </div>
      <img
        src={ShareProfileImage}
        alt={t('dog_profile.share_profile.title', {
          dogName: upperFirst(dog.name)
        })}
      />
    </Card>
  )
}

export { ShareProfile }
