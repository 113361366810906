import { gql } from '@apollo/client'

const CUSTOMER_ISSUE_REPORT_CREATE = gql`
  fragment trackOrderResolutionDetailsFields on CustomerIssueManagementResolutionDetailsTrackOrder {
    boxId
    trackingId
    trackingUrl
    courierName
    address1
    address2
  }

  fragment helpCentreArticleResolutionDetailsFields on CustomerIssueManagementResolutionDetailsHelpCentreArticle {
    articleId
  }

  mutation CustomerIssueReportCreate(
    $reportableType: CustomerIssueManagementReportable!
    $reportableId: ID!
    $reporterableType: CustomerIssueManagementReporterable!
    $reporterableId: ID!
    $categoryId: ID!
  ) {
    response: customerIssueReportCreate(
      reportableType: $reportableType
      reportableId: $reportableId
      reporterableType: $reporterableType
      reporterableId: $reporterableId
      categoryId: $categoryId
    ) {
      categoryReasons {
        id
        presentationalOrder
        reasons {
          description
          id
          key
          name
          parentId
          presentationalOrder
        }
      }
      entryId
      inputFields {
        dataType
        description
        key
        name
        presentationalOrder
        selectionFormat
        __typename
        ... on CustomerIssueManagementInputFieldAssociation {
          allowedAssociationValues {
            __typename
            ... on Product {
              id
            }
          }
          associationType
          associationDisplayType
          id
        }
        ... on CustomerIssueManagementInputFieldString {
          allowedStringValues
          stringDisplayType
          id
        }
        ... on CustomerIssueManagementInputFieldInteger {
          allowedIntegerValues
          integerDisplayType
          id
        }
        ... on CustomerIssueManagementInputFieldBoolean {
          booleanDisplayType
          id
        }
        ... on CustomerIssueManagementInputFieldMultifield {
          multifieldDisplayType
          id
          fields {
            name
            dataType
            presentationalOrder
            selectionFormat
            __typename
            ... on CustomerIssueManagementInputFieldAssociation {
              allowedAssociationValues {
                __typename
                ... on Product {
                  id
                }
              }
              associationType
              associationDisplayType
              id
            }
            ... on CustomerIssueManagementInputFieldString {
              allowedStringValues
              stringDisplayType
              id
            }
            ... on CustomerIssueManagementInputFieldInteger {
              allowedIntegerValues
              integerDisplayType
              id
            }
            ... on CustomerIssueManagementInputFieldBoolean {
              booleanDisplayType
              id
            }
          }
        }
      }
      reportId
      resolutionOffering {
        id
        details {
          type
          ... on CustomerIssueManagementResolutionDetailsTrackOrder {
            ...trackOrderResolutionDetailsFields
          }
          ... on CustomerIssueManagementResolutionDetailsHelpCentreArticle {
            ...helpCentreArticleResolutionDetailsFields
          }
        }
        resolutionType
      }
    }
  }
`

export { CUSTOMER_ISSUE_REPORT_CREATE }
