// @noflow
import { useReactiveVar } from '@apollo/client'
import React, { useEffect } from 'react'
import {
  Navigate,
  Route,
  Routes as RouterRoutes,
  useNavigate
} from 'react-router-dom'

import STYLES from './CustomerIssueManagementPage.module.sass'

import { CustomerIssueManagementInitialDataQuery_categories as EligibleCategory } from './queries/__generated__/CustomerIssueManagementInitialDataQuery'
import { CustomerIssueManagementInputInput } from '@/types'

import {
  customerIssueManagementState,
  routeState
} from './CustomerIssueManagementPage'
import { CustomerIssueResolutionOfferingMutationsHook } from './hooks/useCustomerIssueResolutionOfferingMutations'
// Screens
import CategoriesScreen from './screens/Categories/CategoriesScreen'
import ContactCustomerLove from './screens/ContactCustomerLove/ContactCustomerLove'
import Error from './screens/Error/Error'
import InputsScreen from './screens/Inputs/InputsScreen'
import QuickHelp from './screens/QuickHelp/QuickHelp'
import Resolution from './screens/Resolution/Resolution'
import ResolutionConfirmation from './screens/ResolutionConfirmation/ResolutionConfirmation'
import Routes from './types/routes'

type Props = {
  customerIssueResolutionOfferingMutations: CustomerIssueResolutionOfferingMutationsHook
  submitCustomerIssueReportCreate: (category: EligibleCategory) => void
  submitCustomerIssueEntryInputsCreate: (
    inputs: Array<CustomerIssueManagementInputInput>,
    reasonId?: string
  ) => void
}

const CustomerIssueManagement = ({
  customerIssueResolutionOfferingMutations,
  submitCustomerIssueReportCreate,
  submitCustomerIssueEntryInputsCreate
}: Props): JSX.Element => {
  const customerIssueManagementData = useReactiveVar(
    customerIssueManagementState
  )
  const routeValue = useReactiveVar(routeState)
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
    if (!document.body.classList.contains('hide-dixa')) {
      document.body.classList.add('hide-dixa')
    }
  }, [])

  useEffect(() => {
    if (routeValue) {
      navigate(routeValue + customerIssueManagementData.queryParams)
      routeState(null)
    }
  }, [routeValue, navigate, customerIssueManagementData.queryParams])

  return (
    <RouterRoutes>
      <Route
        path={Routes.Default}
        element={
          <Navigate
            to={Routes.Categories + customerIssueManagementData.queryParams}
          />
        }
      />
      <Route
        path={Routes.Categories}
        element={
          <div className={STYLES.cardContent}>
            <CategoriesScreen
              submitCustomerIssueReportCreate={submitCustomerIssueReportCreate}
            />
          </div>
        }
      />
      <Route
        path={Routes.QuickHelp}
        element={
          <QuickHelp
            customerIssueResolutionOfferingMutations={
              customerIssueResolutionOfferingMutations
            }
          />
        }
      />
      <Route
        path={Routes.Inputs}
        element={
          <div className={STYLES.cardContent}>
            <InputsScreen
              submitCustomerIssueEntryInputsCreate={
                submitCustomerIssueEntryInputsCreate
              }
            />
          </div>
        }
      />
      <Route
        path={Routes.ResolutionOffering}
        element={
          <div className={STYLES.cardContent}>
            <Resolution
              customerIssueResolutionOfferingMutations={
                customerIssueResolutionOfferingMutations
              }
            />
          </div>
        }
      />
      <Route
        path={Routes.ResolutionConfirmation}
        element={
          <div className={STYLES.cardContent}>
            <ResolutionConfirmation />
          </div>
        }
      />
      <Route
        path={Routes.ContactCustomerLove}
        element={
          <div className={STYLES.cardContent}>
            <ContactCustomerLove />
          </div>
        }
      />
      <Route path={Routes.Error} element={<Error />} />
    </RouterRoutes>
  )
}

export { Props }
export default CustomerIssueManagement
