// @flow

import * as React from 'react'
import SuccessIcon from 'assets/images/icons/checkmarks/green-checkmark.svg'
import ErrorIcon from 'assets/images/icons/crosses/red-cross.svg'

type MessageLevel =
  | 'info'
  | 'success'
  | 'error'

type Props = {|
  level: MessageLevel,
  className?: string,
  content: string
|}

const Icon = ({ level }: { level: MessageLevel }): React.Element<'img'> => {
  switch (level) {
    case 'info': {
      throw new Error(`iconFromMessageLevel not implemented for ${level}`)
    }
    case 'error': {
      return (
        <img
          alt=''
          src={ErrorIcon}
        />
      )
    }
    case 'success': {
      return (
        <img
          alt=''
          src={SuccessIcon}
        />
      )
    }
    default: {
      (level: empty) // eslint-disable-line no-unused-expressions
      throw new Error(`iconFromMessageLevel not implemented for ${level}`)
    }
  }
}

const Message = ({
  level,
  className,
  content
}: Props): React.Element<'div'> => (
  <div className={`message message--${level} ${className || ''}`}>
    <Icon level={level} />
    <p>
      { content }
    </p>
  </div>
)

export type { MessageLevel }

export default Message
