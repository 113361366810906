// @no-flow
import i18next from 'i18next'
import { useCallback, useEffect, useState } from 'react'

import * as ANALYTICS from '../../../Analytics/CheckoutAnalytics'
import { checkoutPageState } from '../../../CheckoutPage'
import { ActivePaymentView, PayPalOption, PaypalResponse } from '../../../types'
import { bannerMessageState } from '../Banner'
import { paymentSubmissionState, setSubmissionState } from '../PaymentSection'
import buySubscriptionWithPayPal from '../helpers/buySubscriptionWithPayPal'
import getPaypalPaymentAuthorisation from '../helpers/getPaypalPaymentAuthorisation'

enum PayPalInContextAction {
  Approve = 'approve',
  Cancel = 'cancel',
  Error = 'error'
}

type UseBuySubscriptionWithPayPal = {
  payPalButtonClicked: (redirectUrl?: string) => void
  payPalBillingAgreementTokenReceived: boolean
  payPalAuthorisationResponse: PaypalResponse | null
  payPalPaymentSuccess: boolean
  handlePayPalInContextSubmission: ({
    action,
    token
  }: {
    action: PayPalInContextAction
    token: string
  }) => void
  fetchPaypalToken: () => void
}

const useBuySubscriptionWithPayPal = ({
  currentPayPalOption,
  csrfToken,
  activePaymentView,
  togglePaymentElement
}: {
  currentPayPalOption: PayPalOption
  csrfToken: string
  activePaymentView: ActivePaymentView
  togglePaymentElement?: () => void
}): UseBuySubscriptionWithPayPal => {
  const { sections } = checkoutPageState()

  const [payPalAuthorisationResponse, setPayPalAuthorisationResponse] =
    useState<PaypalResponse | null>(null)
  const [
    payPalBillingAgreementTokenReceived,
    setPayPalBillingAgreementTokenReceived
  ] = useState(false)

  const submissionState = paymentSubmissionState()

  const payPalPaymentSuccess =
    submissionState.type === 'loading' && payPalBillingAgreementTokenReceived

  const payPalButtonClicked = useCallback(() => {
    ANALYTICS.payPalButtonClicked()
    ANALYTICS.submissionAttempt('payPal')

    // Reset the error banner if it's showing
    bannerMessageState({ message: null, type: 'error' })

    sections.paymentDetails.form.selectedPaymentMethod.type = 'payPal'

    checkoutPageState({
      ...checkoutPageState(),
      sections
    })
  }, [sections])

  const fetchPaypalToken = useCallback(() => {
    getPaypalPaymentAuthorisation({
      redirectUrl: window.location.href,
      payPalOption: currentPayPalOption,
      csrfToken,
      setPayPalAuthorisationResponse
    })
  }, [currentPayPalOption, csrfToken, setPayPalAuthorisationResponse])

  useEffect((): void => {
    const updateAfterPayPalBillingTokenReceived = (
      activePaymentView: ActivePaymentView
    ) => {
      if (
        window.location.search.includes('token') &&
        activePaymentView === 'payment-options'
      ) {
        // checkout with PayPal if PayPal token present in the url
        const urlParams = new URLSearchParams(window.location.search)
        const token = urlParams.get('token')

        if (token) {
          setSubmissionState({ type: 'loading' })
          setPayPalBillingAgreementTokenReceived(true)
          buySubscriptionWithPayPal({ token, csrfToken })
        }
      } else {
        setPayPalBillingAgreementTokenReceived(false)
        setSubmissionState({
          type: 'error',
          error: i18next.t('checkout:errors.delivery_details_fetch')
        })
      }
    }

    updateAfterPayPalBillingTokenReceived(activePaymentView)
  }, [activePaymentView, csrfToken])

  const handlePayPalInContextSubmission = useCallback(
    ({ action, token }: { action: PayPalInContextAction; token: string }) => {
      // Reset the error banner if it's showing
      bannerMessageState({ message: null, type: 'error' })

      if (action === PayPalInContextAction.Approve) {
        setSubmissionState({ type: 'loading' })
        setPayPalBillingAgreementTokenReceived(true)
        buySubscriptionWithPayPal({ token, csrfToken })
      }
      if (action === PayPalInContextAction.Cancel && togglePaymentElement) {
        // We need unmount and remount StripePaymentElement to ensure that users
        // who toggle between StripePaymentElement and PayPal and back again will
        // have the correct activePaymentView and paymentMethodType state, as the
        // remount will ensure handlePaymentElementChange event is fired which
        // handles the payment view state accordingly
        togglePaymentElement()
      }
    },
    [csrfToken, togglePaymentElement]
  )

  return {
    payPalButtonClicked,
    payPalBillingAgreementTokenReceived,
    payPalAuthorisationResponse,
    payPalPaymentSuccess,
    handlePayPalInContextSubmission,
    fetchPaypalToken
  }
}

export default useBuySubscriptionWithPayPal

export { PayPalInContextAction, UseBuySubscriptionWithPayPal }
