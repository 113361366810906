// @flow

import * as React from 'react'

import ReferallLinkAndShareComponent from './referral_link_and_share_component'
import RightBarkingDog from '../../../../assets/images/illustrations/dogs/barking-labrador.svg'
import LeftBarkingDog from '../../../../assets/images/illustrations/dogs/barking-dalmatian.svg'
import { formatCurrencyWithoutDecimal } from '@/utils/currency'
import i18next from 'i18next'

// types
import type { ReferralCategory } from '../../ambassador_dashboard/partials/give_get_referral_component'

export type AffiliateType =
  | 'influencer'
  | 'ambassador'

export type ReferralLink = {|
  url: string,
  referral_type: string,
  referral_category: ?ReferralCategory
|}

type Props = {|
  affiliateType: AffiliateType,
  includeTitle: boolean,
  fullName: string,
  referralLink: ReferralLink,
  setAlertMessage: string => void,
  referralSignupCredit: number
|}

class ReferralComponent extends React.Component<Props> {
  titleFor = (affiliateType: AffiliateType): string => {
    switch (affiliateType) {
      case 'influencer':
        return i18next.t(`${this.namespace}.bark_about_us`)
      case 'ambassador':
        return i18next.t(`${this.namespace}.refer_client`)

      default:
        (affiliateType: empty)
        throw new Error(`titleFor(_type_) not implemented for type: ${affiliateType}`)
    }
  }

  descriptionFor = (affiliateType: AffiliateType): string => {
    const { referralSignupCredit } = this.props
    switch (affiliateType) {
      case 'influencer':
        return i18next.t(`${this.namespace}.give_fifty`)
      case 'ambassador':
        return i18next.t(`${this.namespace}.refer_with_discount`, { referralSignupCredit: formatCurrencyWithoutDecimal(referralSignupCredit) })
      default:
        (affiliateType: empty)
        throw new Error(`descriptionFor(_type_) not implemented for type: ${affiliateType}`)
    }
  }

  namespace = 'ambassadors:dashboard.referral_component'

  render (): React.Node {
    const { referralLink, affiliateType, setAlertMessage, includeTitle } = this.props
    const { referral_category, referral_type, url } = referralLink

    return (
      <div className='content-padding__extreme small-padding-top'>
        {
          includeTitle
            ? (
              <h2>
                { this.titleFor(affiliateType) }
              </h2>
              )
            : null
        }
        <p>
          { this.descriptionFor(affiliateType) }
        </p>
        <div className='container'>
          <img
            src={LeftBarkingDog}
            alt=''
          />
          <div className='affiliate-referral__block'>
            <ReferallLinkAndShareComponent
              partialLocation={'affiliate'}
              affiliateType={affiliateType}
              referralType={referral_type}
              referralLink={url}
              referralCategory={referral_category}
              copyReferralLinkCallback={setAlertMessage}
            />
          </div>
          <img
            src={RightBarkingDog}
            alt=''
          />
        </div>
        <p className='no-margin--bottom single-margin--top'>
          { i18next.t(`${this.namespace}.barking`) }
        </p>
      </div>
    )
  }
}

export default ReferralComponent
