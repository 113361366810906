import { useQuery, useReactiveVar } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import { useEffect, useState } from 'react'

import { INITIAL_DATA } from '../queries/initialDataQuery'

import {
  CustomerIssueManagementInitialDataQuery,
  CustomerIssueManagementInitialDataQuery_user_dogs as Dog,
  CustomerIssueManagementInitialDataQuery_categories as EligibleCategory
} from '../queries/__generated__/CustomerIssueManagementInitialDataQuery'
import {
  CustomerIssueManagementReportable,
  CustomerIssueManagementReporterable
} from '@/types'

import {
  customerIssueManagementState,
  errorState,
  pageLoadingState,
  routeState
} from '../CustomerIssueManagementPage'
import CustomerIssueManagementRoutes from '../types/routes'
import { Reportable } from '../types/types'

const useCustomerIssueInitialQuery = (reportable: Reportable): void => {
  const [dataRetrieved, setDataRetrieved] = useState(false)
  const customerIssueManagementData = useReactiveVar(
    customerIssueManagementState
  )
  const queryParams = Object.fromEntries(
    new URLSearchParams(window.location.search)
  )

  const {
    loading: initialDataLoading,
    data: initialDataData,
    error: initialDataError
  } = useQuery<CustomerIssueManagementInitialDataQuery>(INITIAL_DATA, {
    variables: {
      reportableId: reportable.id,
      reportableType: reportable.type
    }
  })

  useEffect(() => {
    pageLoadingState(initialDataLoading)
  }, [initialDataLoading])

  useEffect(() => {
    if (initialDataError) {
      Sentry.captureException(
        `Error on initialDataQuery for ${reportable.type} ${reportable.id}`
      )
      errorState(!!initialDataError)
      routeState(CustomerIssueManagementRoutes.Error)
    }
  }, [initialDataError, reportable.id, reportable.type])

  useEffect(() => {
    if (initialDataData && !dataRetrieved) {
      customerIssueManagementState({
        ...customerIssueManagementData,
        shouldSeeSelfResolutions:
          initialDataData.shouldSeeSelfResolutions === 'true',
        reportable: {
          ...customerIssueManagementData.reportable,
          ...initialDataData.user.reportable,
          id: queryParams.box_id || queryParams.reportable_id,
          type: queryParams.box_id
            ? CustomerIssueManagementReportable.Box
            : (queryParams.reportable_type as CustomerIssueManagementReportable),
          reportableFields: initialDataData.user.reportable
        },
        reporterable: {
          id: initialDataData?.user.id,
          type: CustomerIssueManagementReporterable.User
        },
        eligibleCategories:
          initialDataData.categories as Array<EligibleCategory>,
        dogs: initialDataData?.user.dogs as Array<Dog>
      })
      setDataRetrieved(true)
    }
  }, [customerIssueManagementData, initialDataData, dataRetrieved, queryParams])
}

export default useCustomerIssueInitialQuery
