// @noflow
import React from 'react'

type Props = {
  size: number
  width?: number | string
}

const Close = ({ size, width }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '100%'}
    height={size}
    viewBox="0 0 25 25"
    fill="none"
  >
    <path
      d="M13.4679 12.4002L19.3773 6.49077C19.6725 6.19553 19.6725 5.71686 19.3773 5.42162C19.082 5.12639 18.6034 5.12639 18.3081 5.42162L12.3987 11.331L6.4893 5.42162C6.19407 5.12639 5.7154 5.12639 5.42016 5.42162C5.12492 5.71686 5.12492 6.19553 5.42016 6.49077L11.3296 12.4002L5.42013 18.3096C5.12489 18.6049 5.12489 19.0835 5.42013 19.3788C5.71536 19.674 6.19403 19.674 6.48927 19.3788L12.3987 13.4693L18.3082 19.3788C18.6034 19.674 19.0821 19.674 19.3773 19.3788C19.6725 19.0835 19.6725 18.6049 19.3773 18.3096L13.4679 12.4002Z"
      fill="white"
    />
  </svg>
)

export default Close
