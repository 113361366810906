/* eslint-disable i18next/no-literal-string */
// @noflow
import { useAccessManagement } from '@/context/accessManagement/accessManagement'
import { useLocalisation } from '@/context/localisation/localisation'
import { ACCOUNT_ROUTES } from '@/routes'
import { Grid } from '@mui/material'
import isUndefined from 'lodash/isUndefined'
import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import useButternutBoost from '@/hooks/useButternutBoost'
import useWindowSize from '@/hooks/useWindowSize'

import WhiteTick from 'assets/images/icons/checkmarks/white-tick-roundal.svg'

import segmentTrack from '@/components/analytics/Analytics'
import BoostLogo, {
  BoostLogoSizes
} from '@/components/elements/atoms/BoostLogo/BoostLogo'
import { BoxDeliveryStatusText } from '@/components/elements/atoms/BoxDeliveryStatusText/BoxDeliveryStatusText'
import { Button } from '@/components/elements/atoms/Button'
import Card, { generateVariant } from '@/components/elements/atoms/Card/Card'
import FlatButton from '@/components/elements/atoms/FlatButton/FlatButton'
import Plus from '@/components/elements/atoms/Icon/Icons/Plus'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import Text from '@/components/elements/atoms/Text/Text'
import ActionCard from '@/components/elements/molecules/ActionCard/ActionCard'
import IconCard from '@/components/elements/molecules/IconCard/IconCard'
import ProductThumb, {
  Props as ProductThumbProps
} from '@/components/elements/molecules/ProductThumb/ProductThumb'
import SwiperSlider from '@/components/elements/molecules/SwiperSlider/SwiperSlider'
import BoostCard from '@/components/elements/organisms/BoostCard/BoostCard'
import { BREAKPOINTS } from '@/components/pages/App/App'
import {
  upcomingBoxQuery_user_ordersByDate_Box as Box,
  upcomingBoxQuery_user_currentDeliveryAreaPreference_preferredCarrierService_carrier as Carrier,
  upcomingBoxQuery_user_subscription_plan_nextUpcomingPrice as NextUpcomingPrice,
  upcomingBoxQuery_user_ordersByDate_NonCoreOrder as NonCoreOrder,
  upcomingBoxQuery_user_ordersByDate_NonCoreOrder_orderParts as NonCoreOrderOrderPart,
  upcomingBoxQuery_user_subscription_plan as Plan
} from '@/components/pages/Dashboard/components/upcomingBoxes/queries/__generated__/upcomingBoxQuery'
import { Order } from '@/components/pages/Dashboard/components/upcomingBoxes/utilities'
import AddMore from '@/components/pages/OrderPage/components/AddMore/AddMore'

import STYLES from './BoxDetails.module.sass'

import { Descriptor, MembershipTypes, SubscriptionStatus } from '@/types'

import { BoxDetailsPriceBreakdown } from '../BoxDetailsPriceBreakdown'

type BoxOrderPricing = {
  planPricePerDay: Box['planPricePerDay']
} & Box['order']

type NonCoreOrderPricing = {
  invoice: NonCoreOrder['invoice']
  deliverySurcharge: NonCoreOrderOrderPart['deliverySurcharge']
  smallOrderSurcharge: NonCoreOrderOrderPart['smallOrderSurcharge']
  orderProductsTotal: NonCoreOrderOrderPart['orderProductsTotal']
}

type Props = {
  id: string
  type: Order['type']
  descriptor: Box['descriptor']
  amendable: boolean
  numberOfPouches?: number
  pouchSize?: number
  durationInDays?: number
  deliveryDate: Date
  cutOffDate: Date
  shippingDate: Date
  meals?: ProductThumbProps[]
  isFull?: boolean
  extras?: ProductThumbProps[]
  shouldOfferSelfService?: boolean
  deliveryStatus?: string | null
  isTrialBox?: boolean | null
  isBoostedBox?: boolean | null
  isFreeBox?: boolean
  isReplacementBox?: boolean
  pricing?: BoxOrderPricing | NonCoreOrderPricing
  nextUpcomingPrice: NextUpcomingPrice | undefined | null
  planPricePerDay: Plan['pricePerDay'] | undefined
  subscriptionStatus?: string | null
  preferredCarrierName?: Carrier['name'] | null
}

const title = (
  type: Order['type'],
  context: string,
  descriptor: Box['descriptor'],
  isTrialBox?: boolean | null,
  isBoostedBox?: boolean | null
): string | Array<string> => {
  if (isTrialBox) return `${context}.starter_core_order_title`
  if (isBoostedBox) return `${context}.boosted_core_order_title`
  switch (type) {
    case 'nonCoreOrder': {
      return `${context}.non_core_order_title`
    }
    case 'oneOffBox': {
      return `${context}.one_off_title`
    }
    default: {
      return [
        `${context}.${descriptor}_core_order_title`,
        `${context}.core_order_title`
      ]
    }
  }
}

/**
 * Displays the details of a subscription order or non-core order
 *
 * @param {string} id - The id of the order
 * @param {string} type - The type of order
 * @param {Descriptor} descriptor - Whether the order is past, next or future in timeline
 * @param {boolean} amendable - Whether the order is amendable
 * @param {number} numberOfPouches - The number of pouches in the order
 * @param {number} pouchSize - The size of the pouches in the order
 * @param {number} durationInDays - The duration of the order in days
 * @param {Date} deliveryDate - The delivery date of the order
 * @param {Date} cutOffDate - The cut off date of the order
 * @param {Date} shippingDate - The shipping date of the order
 * @param {ProductThumbProps[]} meals - The meals in the order
 * @param {boolean} isFull - Whether the order is full
 * @param {ProductThumbProps[]} extras - The extras in the order
 * @param {boolean} shouldOfferSelfService - Whether the order should offer self service
 * @param {string} deliveryStatus - The delivery status of the order
 * @param {boolean} isTrialBox - Whether the order is a trial/starter box
 * @param {boolean} isBoostedBox - Whether the order is a boosted box
 * @param {boolean} isFreeBox - Whether the order is a free box
 * @param {boolean} isReplacementBox - Whether the order is a replacement box
 * @param {BoxOrder | NonCoreOrderPricing} pricing - The pricing of the order
 * @param {NextUpcomingPrice} nextUpcomingPrice - The next upcoming price of the plan
 * @param {Plan['pricePerDay']} planPricePerDay - The price per day of the plan
 * @param {string} subscriptionStatus
 * @param {Carrier['name']} preferredCarrierName - The preferred carrier service name
 * @returns {JSX.Element} - The component
 */
const BoxDetails = ({
  id,
  type,
  amendable,
  descriptor,
  deliveryDate,
  cutOffDate,
  shippingDate,
  meals,
  extras,
  isFull,
  numberOfPouches,
  pouchSize,
  durationInDays,
  shouldOfferSelfService,
  deliveryStatus,
  isTrialBox,
  isBoostedBox,
  isFreeBox,
  isReplacementBox,
  pricing,
  nextUpcomingPrice,
  planPricePerDay,
  subscriptionStatus,
  preferredCarrierName
}: Props): JSX.Element => {
  const { windowWidth } = useWindowSize()
  const namespace = 'account'
  const navigate = useNavigate()
  const { format } = useLocalisation()
  const accessManagement = useAccessManagement()
  const { boostedType } = useButternutBoost()
  const isMobile = windowWidth < BREAKPOINTS.sm
  const align = isMobile ? 'center' : 'left'
  const copyContext = 'home.box_details'

  const navigateToOrder = useCallback(() => {
    switch (type) {
      case 'box':
      case 'oneOffBox': {
        navigate(`${ACCOUNT_ROUTES.orders}/${id}`)
        break
      }
      case 'nonCoreOrder': {
        navigate(`${ACCOUNT_ROUTES.onDemandExtras}/${id}`)
        break
      }
    }
  }, [id, navigate, type])

  const changeDate = useCallback(
    () => navigate(`${ACCOUNT_ROUTES.changeDate}/${id}`),
    [id, navigate]
  )

  const changePlan = useCallback(
    () => navigate(`${ACCOUNT_ROUTES.planManagement}`),
    [navigate]
  )

  const editRecipes = useCallback(
    () => navigate(ACCOUNT_ROUTES.editRecipes),
    [navigate]
  )

  const editExtras = useCallback(
    // eslint-disable-next-line i18next/no-literal-string
    () => navigate(`${ACCOUNT_ROUTES.editExtras}/${id}`),
    [id, navigate]
  )

  const helpWithBoxClicked = useCallback(
    // eslint-disable-next-line i18next/no-literal-string
    () => (window.location.href = `/self-resolution?box_id=${id}`),
    [id]
  )

  const canUserAccessExtras = accessManagement?.canUserAccessRoute(
    ACCOUNT_ROUTES.extras
  )
  const hasExtras = !isUndefined(extras) && extras.length > 0
  const showAddExtrasPrompt =
    !hasExtras && amendable && canUserAccessExtras && type !== 'oneOffBox'
  const showOrderHelpButton =
    !isUndefined(shouldOfferSelfService) && shouldOfferSelfService

  const handleAddMoreExtrasClick = React.useCallback((): void => {
    const link = ACCOUNT_ROUTES.extras
    segmentTrack('Order Details Page - Add more extras button clicked', {
      link
    })
    navigate(link)
  }, [navigate])

  const DeliveryCard =
    isBoostedBox && boostedType === MembershipTypes.boost ? BoostCard : IconCard

  const suspendedSubscriptionStatus =
    subscriptionStatus === SubscriptionStatus.suspended

  const renderExtrasSlides = useCallback(
    (hasExtras: boolean, amendable: boolean): JSX.Element[] => {
      if (!hasExtras || !extras) return []
      const slides = extras?.map((extra: ProductThumbProps): JSX.Element => {
        return (
          <ProductThumb
            image={extra.image}
            alt={extra.alt}
            quantity={extra.quantity}
            key={extra.image}
            size={extra.size}
          />
        )
      })
      if (amendable && !isFull && canUserAccessExtras) {
        slides.push(
          <div className={STYLES.addMoreExtrasCard}>
            <Card
              border="dashed"
              onClick={handleAddMoreExtrasClick}
              key="add-more"
              padding={0}
              variant={generateVariant(
                'brandBlue100',
                'brandBlue400',
                'transparent'
              )}
              identifier="box_details.add_more_extras"
            >
              <div className={STYLES.addMoreExtras}>
                <Plus
                  size={windowWidth > BREAKPOINTS.md ? 40 : 14}
                  accentColour="brandBlue400"
                />
                <Text
                  margin={false}
                  namespace="shared"
                  text="box_breakdown.see_more_extras"
                  colour="brandBlue400"
                  variant="display16"
                  align="center"
                />
              </div>
            </Card>
          </div>
        )
      }
      return slides
    },
    [extras, handleAddMoreExtrasClick, isFull, windowWidth, canUserAccessExtras]
  )

  const changePlanProps = useCallback(
    (cardName: string) => {
      return amendable
        ? {
            onClick: changePlan,
            identifier: `box_details.plan_details.${cardName}`
          }
        : {}
    },
    [amendable, changePlan]
  )

  const changeDateProps = useMemo(() => {
    return amendable
      ? {
          onClick: changeDate,
          identifier: 'box_details.change_date'
        }
      : {}
  }, [amendable, changeDate])

  return (
    <ActionCard
      shadow
      padding={windowWidth > BREAKPOINTS.md ? 32 : 16}
      heading={{
        text: title(
          type,
          copyContext,
          descriptor,
          isTrialBox,
          isBoostedBox && boostedType === MembershipTypes.boost
        ),
        namespace
      }}
      button={{
        text: {
          namespace,
          text: `${copyContext}.view`,
          translate: true
        },
        onClick: navigateToOrder
      }}
      fadedOut={suspendedSubscriptionStatus}
    >
      <Grid container rowSpacing={4}>
        <Grid container item alignItems="stretch">
          <Grid item xs={12}>
            <BoxDeliveryStatusText
              amendable={amendable}
              status={deliveryStatus}
              cutOffDate={cutOffDate}
              deliveryDate={deliveryDate}
              type={type}
              descriptor={descriptor}
            />
          </Grid>
          {/* Delivery date */}
          <Grid
            container
            item
            alignItems="stretch"
            id="box-details"
            data-wrapper="deliveryDetails"
            className={STYLES.deliveryDetails}
            rowGap={{ xs: 1, md: 2 }}
          >
            <Grid item xs={12} lg={5}>
              <DeliveryCard
                id="delivery-date-card-tour-target"
                padding={windowWidth > BREAKPOINTS.xl ? 32 : 16}
                variant={
                  amendable
                    ? 'brandBlue100'
                    : {
                        border: 'brandBlue400',
                        background: 'transparent',
                        selected: 'transparent'
                      }
                }
                heading={{
                  text: !isUndefined(format)
                    ? format(deliveryDate, 'EEE dd MMM')
                    : '',
                  translate: false
                }}
                icon={{ asset: 'van' }}
                subtext={[
                  {
                    text:
                      descriptor !== Descriptor.past && preferredCarrierName
                        ? preferredCarrierName
                        : '',
                    variant: 'textRegular14',
                    translate: false
                  },
                  {
                    namespace: 'shared',
                    text:
                      isBoostedBox && boostedType === MembershipTypes.boost
                        ? 'delivery_details.boost'
                        : '',
                    variant: 'display14',
                    translate: !!isBoostedBox,
                    icon:
                      isBoostedBox && boostedType === MembershipTypes.boost
                        ? WhiteTick
                        : ''
                  }
                ]}
                border={amendable ? 'solid' : 'dashed'}
                showArrow={
                  !isBoostedBox || boostedType === MembershipTypes.boost_ap_only
                }
                rightImage={
                  isBoostedBox && boostedType === MembershipTypes.boost ? (
                    <BoostLogo size={BoostLogoSizes.large} />
                  ) : null
                }
                {...changeDateProps}
              />
            </Grid>
            {/* Pouch size / Pouch count / Cadence */}
            {!isUndefined(meals) && (
              <Grid
                container
                item
                alignItems="stretch"
                columnGap={{ xs: 1, md: 2 }}
                rowGap={{ xs: 1, md: 2 }}
                xs
                id="plan-details-tour-target"
              >
                {/* Pouch size */}
                {!isUndefined(pouchSize) && (
                  <Grid item xs alignItems="stretch" key="pouch-size">
                    <IconCard
                      padding={16}
                      heading={{
                        text: `${copyContext}.box_breakdown.pouch_size.value`,
                        namespace,
                        element: 'p',
                        translate: true,
                        tight: true,
                        align,
                        variables: {
                          volume: pouchSize
                        },
                        margin: false
                      }}
                      subtext={{
                        text: `${copyContext}.box_breakdown.pouch_size.label`,
                        align,
                        element: 'p',
                        namespace,
                        translate: true,
                        tight: true,
                        margin: false
                      }}
                      icon={isMobile ? undefined : { asset: 'pouch' }}
                      variant={
                        amendable
                          ? 'brandBlue100'
                          : {
                              border: 'brandBlue400',
                              background: 'transparent',
                              selected: 'transparent'
                            }
                      }
                      border={amendable ? 'solid' : 'dashed'}
                      showArrow={false}
                      {...changePlanProps('pouch_size')}
                    />
                  </Grid>
                )}
                {/* Pouch count */}
                {!isUndefined(numberOfPouches) && (
                  <Grid item xs alignItems="stretch" key="pouch-count">
                    <IconCard
                      padding={16}
                      heading={{
                        text: numberOfPouches.toString(),
                        align,
                        element: 'p',
                        translate: false,
                        tight: true,
                        margin: false
                      }}
                      subtext={{
                        text: `${copyContext}.box_breakdown.pouch_count.label`,
                        align,
                        element: 'p',
                        namespace,
                        translate: true,
                        tight: true,
                        margin: false
                      }}
                      icon={
                        isMobile
                          ? undefined
                          : { asset: 'boxOpen', backgroundColour: 'brandWhite' }
                      }
                      variant={
                        amendable
                          ? 'brandBlue100'
                          : {
                              border: 'brandBlue400',
                              background: 'transparent',
                              selected: 'transparent'
                            }
                      }
                      border={amendable ? 'solid' : 'dashed'}
                      showArrow={false}
                      {...changePlanProps('pouch_count')}
                    />
                  </Grid>
                )}
                {/* Cadence */}
                {!isUndefined(durationInDays) && (
                  <Grid item xs alignItems="stretch" key="cadence">
                    <IconCard
                      padding={16}
                      heading={{
                        text: `${copyContext}.box_breakdown.cadence.value`,
                        element: 'p',
                        align,
                        translate: true,
                        tight: true,
                        namespace,
                        variables: {
                          days: durationInDays
                        },
                        margin: false
                      }}
                      subtext={{
                        text: `${copyContext}.box_breakdown.cadence.label`,
                        element: 'p',
                        align,
                        namespace,
                        translate: true,
                        tight: true,
                        margin: false
                      }}
                      icon={isMobile ? undefined : { asset: 'calendar' }}
                      variant={
                        amendable
                          ? 'brandBlue100'
                          : {
                              border: 'brandBlue400',
                              background: 'transparent',
                              selected: 'transparent'
                            }
                      }
                      border={amendable ? 'solid' : 'dashed'}
                      showArrow={false}
                      {...changePlanProps('cadence')}
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
        {showOrderHelpButton && (
          <Grid
            item
            container
            rowSpacing={2}
            flexDirection="row"
            justifyContent="center"
            className={STYLES.helpButton}
          >
            <Button
              fullWidth
              identifier="dashboard.help_with_box"
              typography={{
                text: `${copyContext}.get_help_cta`,
                namespace
              }}
              onClick={helpWithBoxClicked}
            />
          </Grid>
        )}
        {!isUndefined(meals) && (
          <Grid item container rowSpacing={2} flexDirection="column">
            <Grid item className={STYLES.slider}>
              <SectionWrapper
                margin={{
                  top: 0,
                  bottom: 0
                }}
                headerTypography={{
                  variant: 'display16',
                  element: 'div',
                  namespace,
                  text: `${copyContext}.fresh_meals`,
                  translate: true
                }}
                headerRightAdornment={
                  amendable
                    ? {
                        variant: 'custom',
                        content: (
                          <FlatButton
                            text={{
                              text: `${copyContext}.edit`,
                              namespace,
                              translate: true
                            }}
                            onClick={editRecipes}
                            identifier="box_details.edit_meals"
                            id="edit-meals-tour-target"
                          />
                        )
                      }
                    : undefined
                }
              >
                <SwiperSlider
                  variant="brandBlue100"
                  paddingOverride={windowWidth > BREAKPOINTS.md ? 32 : 16}
                >
                  {meals.map((meal) => (
                    <ProductThumb
                      image={meal.image}
                      alt={meal.alt}
                      quantity={meal.quantity}
                      key={meal.image}
                    />
                  ))}
                </SwiperSlider>
              </SectionWrapper>
            </Grid>
          </Grid>
        )}
        {(hasExtras || showAddExtrasPrompt) && (
          <Grid item container rowSpacing={2} flexDirection="column">
            <Grid item className={STYLES.slider}>
              <SectionWrapper
                margin={{
                  top: 0,
                  bottom: 0
                }}
                headerTypography={{
                  variant: 'display16',
                  element: 'div',
                  namespace,
                  text: `${copyContext}.extras`,
                  translate: true
                }}
                headerLeftAdornment={
                  isBoostedBox && boostedType === MembershipTypes.boost
                    ? {
                        content: (
                          <div>
                            <BoostLogo
                              size={
                                isMobile
                                  ? BoostLogoSizes.medium
                                  : BoostLogoSizes.large
                              }
                            />
                          </div>
                        ),
                        variant: 'custom'
                      }
                    : undefined
                }
                headerRightAdornment={
                  amendable && hasExtras
                    ? {
                        variant: 'custom',
                        content: (
                          <FlatButton
                            text={{
                              text: `${copyContext}.edit`,
                              namespace,
                              translate: true
                            }}
                            onClick={editExtras}
                            identifier="box_details.edit_extras"
                            id="edit-extras-tour-target"
                          />
                        )
                      }
                    : undefined
                }
              >
                <>
                  {hasExtras && (
                    <SwiperSlider
                      variant="brandBlue100"
                      paddingOverride={windowWidth > BREAKPOINTS.md ? 32 : 16}
                    >
                      {renderExtrasSlides(hasExtras, amendable)}
                    </SwiperSlider>
                  )}
                  {showAddExtrasPrompt && (
                    <Grid container item alignItems="stretch">
                      <Grid item xs={12} lg={5}>
                        <AddMore
                          isBoostedBox={isBoostedBox}
                          link={ACCOUNT_ROUTES.extras}
                          hasExtras={hasExtras}
                        />
                      </Grid>
                    </Grid>
                  )}
                </>
              </SectionWrapper>
            </Grid>
          </Grid>
        )}
        <BoxDetailsPriceBreakdown
          type={type}
          pricing={pricing}
          isBoostedBox={isBoostedBox}
          isFreeBox={isFreeBox}
          isReplacementBox={isReplacementBox}
          nextUpcomingPrice={nextUpcomingPrice}
          shippingDate={shippingDate}
          planPricePerDay={planPricePerDay}
        />
      </Grid>
    </ActionCard>
  )
}
export { Props }
export default BoxDetails
