import { useReactiveVar } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router'

import ResolutionConfirmationSwitch from './components/ResolutionConfirmationSwitch'

import STYLES from './ResolutionConfirmation.module.sass'

import { CustomerIssueResolutionOfferingAccept_response_user } from '../../mutations/__generated__/CustomerIssueResolutionOfferingAccept'

import {
  customerIssueResolutionOfferingState,
  pageHeaderShowBackButtonState,
  pageTitleState
} from '../../CustomerIssueManagementPage'
import CustomerIssueManagementRoutes from '../../types/routes'

const ResolutionConfirmation = (): JSX.Element => {
  const customerIssueResolutionOfferingData = useReactiveVar(
    customerIssueResolutionOfferingState
  )
  const [initialised, setInitialised] = useState(false)

  useEffect(() => {
    if (!initialised) {
      pageTitleState('resolutionConfirmation.default.header')
      pageHeaderShowBackButtonState(false)
      setInitialised(true)
    }
  }, [initialised])

  if (!customerIssueResolutionOfferingData.applicationDetails) {
    return <Navigate to={CustomerIssueManagementRoutes.ContactCustomerLove} />
  }

  return (
    <div className={STYLES.container}>
      <ResolutionConfirmationSwitch
        applicationDetails={
          customerIssueResolutionOfferingData.applicationDetails
        }
        user={
          customerIssueResolutionOfferingData.user as CustomerIssueResolutionOfferingAccept_response_user
        }
      />
    </div>
  )
}

export default ResolutionConfirmation
