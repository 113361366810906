import { gql } from '@apollo/client'

const CREATE_PENDING_SUBSCRIPTION = gql`
  mutation SimplifiedCreatePendingSubscription(
    $pendingSubscription: PendingSubscriptionInput!
  ) {
    createPendingSubscription(pendingSubscription: $pendingSubscription) {
      user {
        id
      }
      pendingSubscription {
        id
        firstOrderPricing {
          netTotalPrice
          standardOrderCoreFoodGrossPrice
          grossAdditionalProductsPrice
        }
        pendingSubscriptionFlavours {
          flavour {
            name
            slug
            themeColour
            thumbnail {
              src
            }
          }
          sevenDayServings
        }
      }
    }
  }
`

export { CREATE_PENDING_SUBSCRIPTION }
