// @noflow
import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import type { Dispatch } from 'redux'

import Input from '@/components/shared/elements/Input'

import * as ACTIONS from '../../actions'
import type { State } from '../../reducers'
import { isValidName } from '../../reducers/formDataReducer'
import type { Form } from '../../reducers/formDataReducer'
import type { State as RequestsState } from '../../reducers/requestsReducer'

type PresentationalProps = {
  name: Form['name']
  submitContactForm: RequestsState['submitContactForm']
}

type ActionProps = {
  updateName: (e: React.ChangeEvent<HTMLInputElement>) => void
  validateName: (e: React.FocusEvent<HTMLInputElement>) => void
}

type Props = PresentationalProps & ActionProps

const mapStateToProps = ({
  formData: { form },
  requests
}: State): PresentationalProps => {
  const { name } = form as Form
  const { submitContactForm } = requests

  return {
    name,
    submitContactForm
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => {
  const updateName = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.currentTarget.value
    dispatch(
      ACTIONS.updateField('name', {
        value,
        interaction: 'Interacting'
      })
    )
  }

  const validateName = (e: React.FocusEvent<HTMLInputElement>): void => {
    const value = e.currentTarget.value
    dispatch(
      ACTIONS.updateField('name', {
        value,
        interaction: isValidName(value)
          ? 'InteractedWithSuccess'
          : 'InteractedWithError'
      })
    )
  }

  return {
    updateName,
    validateName
  }
}

const NameInput = ({
  name,
  submitContactForm,
  updateName,
  validateName
}: Props): React.ReactElement => {
  const copyContext = 'name_input'
  const { t } = useTranslation('contacts')
  return (
    <Input
      autoComplete="on"
      className="name"
      disabled={submitContactForm.status === 'In Flight'}
      invalid={name.interaction === 'InteractedWithError'}
      invalidityMessage={t(`${copyContext}.invalidity_message`)}
      inputId="name"
      label={t(`${copyContext}.label`)}
      name="name"
      onChange={updateName}
      onBlur={validateName}
      required
      type="text"
      value={name.value}
      placeholder={t(`${copyContext}.placeholder`)}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(NameInput)
