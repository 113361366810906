// @noflow
import { makeVar } from '@apollo/client'
import React from 'react'

import Text from '@/components/elements/atoms/Text'

import STYLES from './Banner.module.sass'

type BannerMessage = {
  message: string | null
}

const bannerMessageState = makeVar<BannerMessage>({
  message: null
})

const Banner = ({
  message,
  namespace
}: {
  message: string
  namespace: string
}): JSX.Element => {
  return (
    <div className={STYLES.bannerContainer}>
      <Text translate namespace={namespace} text={message || ''} />
    </div>
  )
}

export default Banner
export { bannerMessageState }

export type { BannerMessage }
