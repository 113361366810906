// @noflow
import { Container, Grid } from '@material-ui/core'
import i18next from 'i18next'
import React, { useCallback } from 'react'
import type { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { pronounContext } from '@/utils/StringHelper'

import FullBowl from 'assets/images/illustrations/bowls/full-bowl--white-fill.svg'
import Pouch from 'assets/images/illustrations/pouches/pouch--white-fill.svg'
import Treats from 'assets/images/illustrations/treats--white-fill.svg'
import Van from 'assets/images/illustrations/vans/van--white-fill.svg'

import { Button } from '@/components/elements/atoms/Button'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from '../../../../../../assets/stylesheets/thank_you/go-to-account.module.sass'

import type { Gender } from '@/shared_types/rails_models/dogs'
import { DogInput as Dog } from '@/types'

type Props = {
  hasAPuppy: boolean
  dogs: Pick<Dog, 'gender'>[]
}

const GoToAccount = ({ hasAPuppy, dogs }: Props): ReactElement => {
  const namespace = 'thank_you'
  const { t } = useTranslation(namespace)
  const copyContext = 'go_to_account'
  const dogGenders = dogs.map(({ gender }): Gender => gender)

  const goToDashboard = useCallback(() => {
    window.location.href = '/dashboard'
  }, [])

  return (
    <section className={STYLES.goToAccountSection}>
      <Text
        text={`${copyContext}.title`}
        namespace={namespace}
        variant="display28"
        element="h2"
        align="center"
      />
      <Container maxWidth="md">
        <Grid container spacing={3} alignItems="stretch">
          <Grid container item sm={6}>
            <div className={STYLES.goToAccountSectionItem}>
              <img
                alt={t(`${copyContext}.image_1_alt`)}
                className="tape__icon"
                src={FullBowl}
              />
              <div>
                <Text
                  text={`${copyContext}.change_recipe`}
                  namespace={namespace}
                  variables={{
                    context: pronounContext(dogGenders, i18next.language)
                  }}
                  bold
                  element="div"
                />
                <Text
                  text={`${copyContext}.description_text`}
                  namespace={namespace}
                  variables={{
                    context: pronounContext(dogGenders, i18next.language)
                  }}
                  element="div"
                />
              </div>
            </div>
          </Grid>
          <Grid container item sm={6}>
            <div className={STYLES.goToAccountSectionItem}>
              <img
                alt={t(`${copyContext}.image_2_alt`)}
                className="tape__icon"
                src={Pouch}
              />
              {hasAPuppy ? (
                <div>
                  <Text
                    text={`${copyContext}.update_weight_subtitle`}
                    namespace={namespace}
                    variables={{
                      context: pronounContext(dogGenders, i18next.language)
                    }}
                    bold
                    element="div"
                  />
                  <Text
                    text={`${copyContext}.update_weight_text`}
                    namespace={namespace}
                    variables={{
                      context: pronounContext(dogGenders, i18next.language)
                    }}
                    element="div"
                  />
                </div>
              ) : (
                <div>
                  <Text
                    text={`${copyContext}.change_food_subtitle`}
                    namespace={namespace}
                    variables={{
                      context: pronounContext(dogGenders, i18next.language)
                    }}
                    bold
                    element="div"
                  />
                  <Text
                    text={`${copyContext}.change_food_text`}
                    namespace={namespace}
                    variables={{
                      context: pronounContext(dogGenders, i18next.language)
                    }}
                    element="div"
                  />
                </div>
              )}
            </div>
          </Grid>
          <Grid container item sm={6}>
            <div className={STYLES.goToAccountSectionItem}>
              <img
                alt={t(`${copyContext}.image_3_alt`)}
                className="tape__icon"
                src={Van}
              />
              <div>
                <Text
                  text={`${copyContext}.change_delivery_subtitle`}
                  namespace={namespace}
                  variables={{
                    context: pronounContext(dogGenders, i18next.language)
                  }}
                  bold
                  element="div"
                />
                <Text
                  text={`${copyContext}.change_delivery_text`}
                  namespace={namespace}
                  variables={{
                    context: pronounContext(dogGenders, i18next.language)
                  }}
                  element="div"
                />
              </div>
            </div>
          </Grid>
          <Grid container item sm={6}>
            <div className={STYLES.goToAccountSectionItem}>
              <img
                alt={t(`${copyContext}.image_4_alt`)}
                className="tape__icon"
                src={Treats}
              />
              <div>
                <Text
                  text={`${copyContext}.extras_subtitle`}
                  namespace={namespace}
                  variables={{
                    context: pronounContext(dogGenders, i18next.language)
                  }}
                  bold
                  element="div"
                />
                <Text
                  text={`${copyContext}.extras_text`}
                  namespace={namespace}
                  variables={{
                    context: pronounContext(dogGenders, i18next.language)
                  }}
                  element="div"
                />
              </div>
            </div>
          </Grid>
          <Grid item container justifyContent="center">
            <Button
              typography={{
                namespace,
                text: `${copyContext}.button_text`,
                variables: {
                  context: pronounContext(dogGenders, i18next.language)
                }
              }}
              variant="secondary"
              onClick={goToDashboard}
              disableAnalytics
            />
          </Grid>
        </Grid>
      </Container>
    </section>
  )
}
export default GoToAccount
