// Utlis
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'
import React, { Fragment, useEffect, useRef } from 'react'

import AmbassadorIcon from 'assets/images/icons/navigation/icon-ambassador.svg'
import StoryIcon from 'assets/images/icons/navigation/icon-book.svg'
import BoxIcon from 'assets/images/icons/navigation/icon-box.svg'
import AccountIcon from 'assets/images/icons/navigation/icon-customer-profile.svg'
import DogIcon from 'assets/images/icons/navigation/icon-dog-profile.svg'
import ExtrasIcon from 'assets/images/icons/navigation/icon-extras.svg'
import FreeFoodIcon from 'assets/images/icons/navigation/icon-heart.svg'
import HomeIcon from 'assets/images/icons/navigation/icon-house.svg'
import LogoutIcon from 'assets/images/icons/navigation/icon-log-out.svg'
import ProductsIcon from 'assets/images/icons/navigation/icon-pouch.svg'
import HelpIcon from 'assets/images/icons/navigation/icon-question-mark.svg'
import ReviewsIcon from 'assets/images/icons/speech-bubble-square.svg'

import NavLink from '@/components/elements/atoms/NavLink/NavLink'
import Separator from '@/components/elements/atoms/Separator/Separator'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './DashboardMobileNavMenu.module.sass'

type Props = {
  backgroundClicked?: () => void
  open: boolean
  isAmbassador: boolean
  isInfluencer: boolean
  numberOfDogs: number
  referralsSinceLastLoginCount: number
  showSecondaryNav: boolean
}

const DashboardMobileNavMenu = ({
  backgroundClicked,
  open,
  isAmbassador,
  isInfluencer,
  numberOfDogs,
  referralsSinceLastLoginCount,
  showSecondaryNav
}: Props): JSX.Element => {
  /*
   * Get the element we want to lock scrolling around
   */
  const targetElement = useRef<HTMLDivElement>(null)
  /*
   * We want to lock the document.body scrolling when the slide out navigation
   * is open
   */
  useEffect((): void => {
    if (targetElement?.current)
      open
        ? disableBodyScroll(targetElement.current)
        : clearAllBodyScrollLocks()
  }, [targetElement, open])

  return (
    <Fragment>
      <div
        className={`${STYLES.background} ${open ? STYLES.open : ''}`}
        onClick={backgroundClicked}
        onKeyDown={backgroundClicked}
        tabIndex={0}
        role="button"
      />
      <div
        className={`${STYLES.container} ${open ? STYLES.open : ''}`}
        ref={targetElement}
      >
        {showSecondaryNav && (
          <Fragment>
            <div className={STYLES.section}>
              <div className={STYLES.row}>
                <NavLink
                  variant="mobile"
                  text={{
                    text: 'dashboard',
                    namespace: 'navigation'
                  }}
                  href="/dashboard"
                  iconSrc={HomeIcon}
                />
              </div>
              <div className={STYLES.row}>
                <NavLink
                  variant="mobile"
                  text={{
                    text: 'my_plan',
                    namespace: 'navigation'
                  }}
                  href="/dashboard/subscription"
                  iconSrc={BoxIcon}
                />
              </div>
              <div className={STYLES.row}>
                <NavLink
                  variant="mobile"
                  text={{
                    text: 'my_details',
                    namespace: 'navigation'
                  }}
                  href="/dashboard/my-details"
                  iconSrc={AccountIcon}
                />
              </div>
              <div className={STYLES.row}>
                <NavLink
                  variant="mobile"
                  text={{
                    text: `extras`,
                    namespace: 'navigation'
                  }}
                  href="/dashboard/extras"
                  iconSrc={ExtrasIcon}
                />
              </div>
              <div className={STYLES.row}>
                <NavLink
                  variant="mobile"
                  text={{
                    text: numberOfDogs > 1 ? `my_dogs` : `my_dog`,
                    namespace: 'navigation'
                  }}
                  href="/dashboard/my-dog"
                  iconSrc={DogIcon}
                />
              </div>
              {!isAmbassador && !isInfluencer && (
                <div className={STYLES.row}>
                  {referralsSinceLastLoginCount > 0 && (
                    <div className={STYLES.numberNotification}>
                      <Text
                        text={`${referralsSinceLastLoginCount}`}
                        translate={false}
                        variant="textRegular16"
                        colour="brandWhite"
                        element="span"
                        shouldScale={false}
                      />
                    </div>
                  )}
                  <NavLink
                    variant="mobile"
                    text={{
                      text: 'free_food',
                      namespace: 'navigation'
                    }}
                    href="/dashboard/refer-a-friend"
                    iconSrc={FreeFoodIcon}
                  />
                </div>
              )}
            </div>
            <Separator bold={false} />
          </Fragment>
        )}
        <div className={STYLES.section}>
          <div className={STYLES.row}>
            <NavLink
              variant="mobile"
              text={{
                text: 'our_products',
                namespace: 'navigation'
              }}
              href="/our_products"
              iconSrc={ProductsIcon}
            />
          </div>
          <div className={STYLES.row}>
            <NavLink
              variant="mobile"
              text={{
                text: 'our_story',
                namespace: 'navigation'
              }}
              href="/our_story"
              iconSrc={StoryIcon}
            />
          </div>
          <div className={STYLES.row}>
            <NavLink
              variant="mobile"
              text={{
                text: 'reviews',
                namespace: 'navigation'
              }}
              href="/reviews"
              iconSrc={ReviewsIcon}
            />
          </div>
          <div className={STYLES.row}>
            <NavLink
              variant="mobile"
              text={{
                text: 'help',
                namespace: 'navigation'
              }}
              href="/contact"
              iconSrc={HelpIcon}
            />
          </div>
          {isAmbassador && (
            <div className={STYLES.row}>
              <NavLink
                variant="mobile"
                text={{
                  text: 'ambassador_account',
                  namespace: 'navigation'
                }}
                href="/users/sign_in_as_ambassador"
                iconSrc={AmbassadorIcon}
              />
            </div>
          )}
          <div className={STYLES.row}>
            <NavLink
              variant="mobile"
              text={{
                text: 'log_out',
                namespace: 'navigation'
              }}
              href="/users/sign_out"
              iconSrc={LogoutIcon}
            />
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export { Props }
export default DashboardMobileNavMenu
