// @noflow
import * as React from 'react'
import {
  ChangeEvent,
  Fragment,
  ReactElement,
  useCallback,
  useEffect
} from 'react'
import { useTranslation } from 'react-i18next'

import * as Validation from '@/components/pages/CheckoutPage/components/Validation/Validation'
import * as inputs from '@/components/pages/CheckoutPage/helpers/inputs'

import { Code as CountryCode } from '@/types'

import { FormSectionKey } from '../../types'
import { BaseInputField, InputWithString } from './InputField'

type Props = {
  value: InputWithString
  shippingCountryCode: CountryCode
  fieldName: FormSectionKey
  isHidden?: boolean
  dataTestId?: string
  onBlur: (event: ChangeEvent<HTMLInputElement>) => void
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  errorMessage: string | null
  loading: boolean
  validatePostcode: (value: string) => void
  postcodeIsValid: boolean
}

const PostcodeInputField = ({
  value,
  shippingCountryCode,
  fieldName,
  dataTestId,
  onBlur,
  onChange,
  isHidden = false,
  errorMessage,
  loading,
  validatePostcode,
  postcodeIsValid
}: Props): ReactElement => {
  const copyContext = 'checkout:errors.postcode'
  const { t } = useTranslation('checkout')

  const validateAndSetErrorMessage = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      validatePostcode(event.currentTarget.value)
      value.errorMessage = errorMessage
    },
    [errorMessage, validatePostcode, value]
  )

  const onChangeCallback = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      validateAndSetErrorMessage(event)
      onChange(event)
    },
    [onChange, validateAndSetErrorMessage]
  )

  const onBlurCallback = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      validateAndSetErrorMessage(event)
      onBlur(event)
    },
    [onBlur, validateAndSetErrorMessage]
  )

  const validationIndicator = (): string | undefined => {
    if (loading) {
      return 'error'
    } else if (errorMessage === null && postcodeIsValid) {
      if (value.inputInteractionState === 'NotInteractingNotInteracted') {
        return 'none'
      }
      return 'checkmark'
    }
    switch (value.inputInteractionState) {
      case 'InteractingNotInteracted':
      case 'NotInteractingNotInteracted': {
        return 'none'
      }
      case 'InteractingInteracted':
      case 'NotInteractingInteracted': {
        return loading ? 'none' : 'error'
      }
    }
  }

  // If a value is already present, validate it on
  // component load
  useEffect(() => {
    if (value.value.length > 0) validatePostcode(value.value)
  }, [validatePostcode, value.value])

  return (
    <Fragment>
      <BaseInputField
        fieldName={fieldName}
        isValid={validationIndicator() !== 'error'}
        dataTestId={dataTestId}
        onBlur={onBlurCallback}
        onChange={onChangeCallback}
        value={value.value}
        isHidden={isHidden}
        shippingCountryCode={shippingCountryCode}
      >
        <p
          className={`checkout-placeholder ${fieldName}-placeholder`}
          id={`${fieldName}-placeholder`}
        >
          {inputs.isRequired(fieldName, shippingCountryCode) ? null : (
            <span className="optional-label">
              {`- ${t(`${copyContext}.optional`)}`}
            </span>
          )}
        </p>
        {!loading && validationIndicator() === 'checkmark' ? (
          <Validation.Checkmark color="green" />
        ) : null}
      </BaseInputField>
      {errorMessage && (
        <div className="checkout__password-helper-container">
          <p className="checkout__password-helper checkout__password-helper--red">
            <Validation.Checkmark color="red" />
            {errorMessage}
          </p>
        </div>
      )}
    </Fragment>
  )
}

export default PostcodeInputField
export { Props }
