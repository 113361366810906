// @noflow
import type { Language } from '@/packs/localisation'
import { useMutation } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import i18next from 'i18next'
import React, { useCallback } from 'react'
import { toast } from 'react-toastify'

import {
  capitaliseFirstLetter,
  pronounContext,
  toLocalisedSentence
} from '@/utils/StringHelper'

import useLocalStorage from '@/hooks/useLocalStorage'

// Component
import segmentTrack from '@/components/analytics/Analytics'
import Card from '@/components/elements/atoms/Card/Card'
import Link from '@/components/elements/atoms/Link/Link'
import Text from '@/components/elements/atoms/Text/Text'
import NotificationContent from '@/components/elements/molecules/NotificationContent/NotificationContent'
import ProductUpsellableCard from '@/components/elements/molecules/ProductUpsellableCard'
import { UPCOMING_BOX_QUERY_VARIABLES } from '@/components/pages/Dashboard/components/upcomingBoxes/UpcomingBoxes'
import { getProductPricesFromCollection } from '@/components/pages/ExtrasPage/screens/ProductCollection/components/molecules/ProductPrice/ProductPrice'
import { errorCopy } from '@/components/pages/ExtrasPage/screens/ProductCollection/helpers/copies'
import {
  orderProductsCreate,
  orderProductsCreateVariables
} from '@/components/pages/ExtrasPage/screens/ProductCollection/mutations/__generated__/orderProductsCreate'
import { ORDERS_PRODUCT_CREATE } from '@/components/pages/ExtrasPage/screens/ProductCollection/mutations/ordersProductCreate'
import type {
  boxQuery_user_dogs as Dog,
  boxQuery_user_subscription_upsellableSampleProduct as Product
} from '@/components/pages/OrderPage/__generated__/boxQuery'
import STYLES from '@/components/pages/OrderPage/components/AdditionalProductUpsellable/AdditionalProductUpsellable.module.sass'

import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'
import type { VariantDelivery } from '@/types'

type Props = {
  product: Product
  shippingCountryCode: CountryCode
  preferredLanguage: Language
  dogs: Array<Dog>
  userId: string
  refetchQuery: () => void
  deliveryType: VariantDelivery
  boosted?: boolean
}

const AdditionalProductUpsellable = ({
  product,
  boosted,
  shippingCountryCode,
  preferredLanguage,
  dogs,
  userId,
  refetchQuery,
  deliveryType
}: Props): JSX.Element | null => {
  const [isDismissed, setIsDismissed] = useLocalStorage(
    'dismissedUpsellable',
    false
  )

  const {
    id,
    name,
    productCollection: {
      name: productName,
      slug,
      thumbnail: { src }
    }
  } = product

  const productCollection = product.productCollection

  const joinedDogsNames = toLocalisedSentence({
    arr: dogs.map(({ name }: Dog): string => capitaliseFirstLetter(name)),
    lng: 'en'
  })

  const dogGenders = dogs.map(({ gender }) => gender)
  const dogPronoun = pronounContext(dogGenders, i18next.language)

  const namespace = 'shared'

  const [createOrderProduct, { loading }] = useMutation<
    orderProductsCreate,
    orderProductsCreateVariables
  >(ORDERS_PRODUCT_CREATE, {
    variables: {
      ...UPCOMING_BOX_QUERY_VARIABLES,
      userId,
      productVariantInput: {
        productVariantId: id,
        quantity: 1,
        deliveryType: deliveryType
      }
    },
    onCompleted: () => {
      toast.success(
        <NotificationContent
          copy={{
            namespace: namespace,
            text: 'box_breakdown.additional_product_upsell.success_modal'
          }}
        />,
        { toastId: 'alert-success-sample-added-to-plan' }
      )

      segmentTrack('Shop: Additional Product Added', {
        type: 'Sample Upsell Card',
        productCollectionSlug: slug,
        purchaseType: deliveryType,
        quantity: 1,
        sizeSlug: name
      })

      refetchQuery()
    },
    onError: (error) => {
      Sentry.captureException(
        `AdditionalProductUpsellable is unable to add a product`,
        {
          extra: {
            deliveryType: deliveryType,
            productName: productName,
            error: error
          }
        }
      )

      toast.error(
        <NotificationContent
          copy={errorCopy({
            mutationError: error?.message,
            recuringType: deliveryType,
            quantity: 1,
            objectId: id
          })}
        />,
        { toastId: 'alert-error-sample-added-to-plan' }
      )
    }
  })

  const addAPToPlanOrNextBox = useCallback(() => {
    createOrderProduct()
  }, [createOrderProduct])

  const handleDismiss = useCallback(() => {
    segmentTrack('Closed AP Upsell Card', { type: 'Sample Upsell Card' })
    setIsDismissed(true)
  }, [setIsDismissed])

  React.useEffect(() => {
    if (!isDismissed) {
      segmentTrack('Seen AP Upsell Card ', {
        type: 'Sample Upsell Card',
        productCollectionName: productName
      })
    }
  }, [isDismissed, productName])

  if (isDismissed) return null

  return (
    <div className={STYLES.upsellableWrapper}>
      <Card variant="brandYellow100" border="solid">
        <div className={STYLES.content}>
          <div className={STYLES.title}>
            <Text
              variant="display20"
              namespace={namespace}
              text="box_breakdown.additional_product_upsell.title"
              margin={false}
              shouldScale={false}
            />
            <Link
              namespace={namespace}
              onClick={handleDismiss}
              text="box_breakdown.additional_product_upsell.close"
              suffix={null}
              identifier="box_breakdown.additional_product_upsell.close"
            />
          </div>
          <Text
            variant="textRegular16"
            namespace={namespace}
            text="box_breakdown.additional_product_upsell.subtitle"
            variables={{
              dogsName: joinedDogsNames,
              context: dogPronoun,
              count: dogs.length
            }}
            margin={false}
            shouldScale={false}
          />
          <div className={STYLES.productCard}>
            <ProductUpsellableCard
              variant="oneClick"
              orientation="horizontal"
              name={name}
              productName={productName}
              src={src}
              onClick={addAPToPlanOrNextBox}
              disableButton={loading}
              deliveryType={deliveryType}
              shippingCountryCode={shippingCountryCode}
              preferredLanguage={preferredLanguage}
              boostedSubscription={boosted}
              {...getProductPricesFromCollection(
                id,
                deliveryType,
                productCollection
              )}
            />
          </div>
        </div>
      </Card>
    </div>
  )
}

export type { Props }
export default AdditionalProductUpsellable
