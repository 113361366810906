// @noflow
import type { Language } from '@/packs/localisation'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import Arrow from 'assets/images/icons/arrows/arrow--blue.svg'

import CloudinaryImage from '@/components/elements/atoms/CloudinaryImage/CloudinaryImage'
import type {
  CloudinaryParams,
  CropType,
  GravityType
} from '@/components/elements/atoms/CloudinaryImage/CloudinaryImage'
import Text from '@/components/elements/atoms/Text/Text'
import RafLinkAndShare from '@/components/elements/organisms/RafLinkAndShare/RafLinkAndShare'
import type {
  ApplicableDiscountPart,
  Dog
} from '@/components/post_sign_up_wizard/message_types'

type Props = {
  dogs: Array<Dog>
  referralLink: string
  referrerDiscount: {
    discountCodeParts: Array<ApplicableDiscountPart>
  }
  preferredLanguage: Language
  postSignupReferrerDiscount: string
  postSignupReferralDiscount: string
  whatsappSingleDog: string
  whatsappMultipleDogs: string
  userID: string
}

const PostWizardReferral = ({
  dogs,
  referralLink,
  referrerDiscount,
  preferredLanguage,
  postSignupReferrerDiscount,
  postSignupReferralDiscount,
  whatsappSingleDog,
  whatsappMultipleDogs,
  userID
}: Props): JSX.Element => {
  const namespace = 'post_signup_wizard'
  const copyContext = 'post_wizard_referral'
  const { t } = useTranslation(['post_signup_wizard', 'dashboard'])
  const [alertMessage, setAlertMessage] = React.useState('')
  const tooltipClass =
    alertMessage !== t(`dashboard:referrals.link_and_share.copied_tooltip`)
      ? 'clipboard-tooltip--danger'
      : 'clipboard-tooltip--success'

  const { windowWidth } = useWindowSize()

  // We only want to hide the wizardFooter on the RAF step, and not the rest of the wizard
  const wizardFooter = document.getElementsByClassName(
    'wizard-footer'
  )[0] as HTMLElement
  if (wizardFooter) {
    wizardFooter.style.display = 'none'
  }

  const cloudinaryImageDesktop: CloudinaryParams = {
    path: 'Web/photos/dogs/loki-freyja-eating-butternut.jpg',
    width: 800,
    height: 1500,
    dpr: window.devicePixelRatio,
    crop: 'lfill' as CropType,
    gravity: 'west' as GravityType
  }

  const cloudinaryImageMobileTablet: CloudinaryParams = {
    path: 'Web/photos/dogs/loki-freyja-eating-butternut.jpg',
    width: 800,
    height: 300,
    dpr: window.devicePixelRatio,
    crop: 'lfill',
    gravity: 'xy_center',
    y: 850
  }

  React.useEffect((): void => {
    setTimeout((): void => {
      setAlertMessage('')
    }, 3000)
  }, [alertMessage])

  return (
    <React.Fragment>
      <div className="raf-step__hero">
        <CloudinaryImage
          alt={t(`${copyContext}.img_alt`)}
          image={
            windowWidth < BREAKPOINTS.lg
              ? cloudinaryImageMobileTablet
              : cloudinaryImageDesktop
          }
        />
      </div>
      <div className="raf-step__card-wrapper">
        <div className="raf-step__card">
          <Text
            namespace={namespace}
            text={`${copyContext}.title`}
            variant="textRegular18"
            colour="brandBlue500"
            bold
          />
          <div className="raf-step__card__offer">
            {referrerDiscount && (
              <React.Fragment>
                <div className="raf-step__card__offer__amount">
                  <Text
                    translate={false}
                    text={postSignupReferrerDiscount}
                    variant="textRegular18"
                    colour="brandBlue500"
                  />
                </div>
                <div className="raf-step__card__offer__arrows">
                  <img alt="" src={Arrow} />
                  <img alt="" src={Arrow} />
                </div>
              </React.Fragment>
            )}
            <div className="raf-step__card__offer__amount">
              <Text
                translate={false}
                text={postSignupReferralDiscount}
                variant="textRegular18"
                colour="brandBlue500"
              />
            </div>
          </div>
          <div className="referrals">
            {alertMessage && (
              <div className={`clipboard-tooltip tooltip ${tooltipClass}`}>
                <span>{alertMessage}</span>
              </div>
            )}
            <RafLinkAndShare
              referralLink={referralLink}
              copyReferralLinkCallback={setAlertMessage}
              partialLocation="Post Signup Wizard"
              dogNames={dogs.map((d: Dog): string => d.name)}
              preferredLanguage={preferredLanguage}
              whatsappMessageSingleDog={whatsappSingleDog}
              whatsappMessageMultipleDogs={whatsappMultipleDogs}
              userID={userID}
            />
            <div className="referrals__footnote">
              <Text
                namespace={namespace}
                text={`${copyContext}.footnote`}
                variant="textRegular14"
                colour="brandBlue500"
                align="center"
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PostWizardReferral
