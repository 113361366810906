// @flow

import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import * as ACTIONS from '../actions'
import * as THUNKS from '../thunks'
import PostSignUpRoutes from '../postSignUpRoutes'
import Header from './../../shared/wizards/Header'
import DogNameDivider from './base_components/DogNameDivider'
import SelectableItemList from '@/components/shared/elements/PillButton/SelectableItemList'
import { favouriteGames } from '../../../shared_types/rails_models/dog_profile'
import { pronounContext } from '../../../utils/StringHelper'
import { favouriteGamesSelected } from '../reducers/dogProfilesReducer'
import Chevron from '../../shared/images/Chevron'
import BRAND_COLOURS from '../../../constants/BrandColours'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

import type { Dispatch } from 'redux'
import type { State } from '../reducers'
import type { FavouriteGame } from '../../../shared_types/rails_models/dog_profile'
import type { RailsModelID as ID } from '../../../shared_types/ids'
import type { Gender } from '../../../shared_types/rails_models/dogs'
import type { State as DogProfileState } from '../reducers/dogProfilesReducer'
import type { Dog } from '../message_types'

type PresentationalProps = {|
  dogs: Array<Dog>,
  dogProfiles: DogProfileState,
  areFavouriteGamesSelected: boolean
|}

type ActionProps = {|
  favouriteGamesUpdate: () => void,
  selectFavouriteGame: (ID, FavouriteGame) => void
|}

type Props =
  & PresentationalProps
  & ActionProps

const mapStateToProps = ({
  globalAttributes,
  dogProfiles
}: State): PresentationalProps => {
  const { dogs } = globalAttributes
  const areFavouriteGamesSelected = favouriteGamesSelected(dogProfiles)

  return {
    dogs,
    dogProfiles,
    areFavouriteGamesSelected
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => {
  const selectFavouriteGame = (id: ID, favouriteGameLabel: FavouriteGame): void => {
    dispatch(ACTIONS.selectFavouriteGame(id, favouriteGameLabel))
  }

  const favouriteGamesUpdate = (): void => {
    dispatch(ACTIONS.nextWizardStep())
    dispatch(THUNKS.favouriteGamesUpdate())
  }

  return {
    favouriteGamesUpdate,
    selectFavouriteGame
  }
}

const FavouriteGames = ({
  favouriteGamesUpdate,
  selectFavouriteGame,
  dogs,
  dogProfiles,
  areFavouriteGamesSelected
}: Props): React.Node => {
  const { t } = useTranslation('post_signup_wizard')
  const copyContext = 'favourite_games'
  const dogGenders = dogs.map(({ gender }: Dog): Gender => (gender))
  const formattedFavouriteGames = favouriteGames.filter((favouriteGame: FavouriteGame): boolean => favouriteGame !== 'unknown_favourite_game')
  const selectFavouriteGameMethods = dogs.reduce((acc: ({ [ID]: (FavouriteGame) => void }), dog: Dog): ({ [ID]: (FavouriteGame) => void }) => {
    acc[dog.id] = (favouriteGameLabel: FavouriteGame): void => selectFavouriteGame(dog.id, favouriteGameLabel)
    return acc
  }, {})
  return (
    <React.Fragment>
      <Header headerSubtitle={ t(`${copyContext}.subtitle`, { count: dogs.length }) }>
        <span
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: t(`${copyContext}.title`, { context: pronounContext(dogGenders, i18next.language) })
          }}
        />
      </Header>
      <div className='step-body'>
        <div className='step-body__content'>
          {
            dogs.map(({ name, id }: Dog): React.Node => {
              return (
                <React.Fragment key={name}>
                  <DogNameDivider dividerText={ t(`${copyContext}.separator_text`, { dogName: name }) } />
                  <SelectableItemList
                    t={t}
                    copyContext={'favourite_games.games'}
                    options={formattedFavouriteGames}
                    selectedOption={dogProfiles[id].favouriteGame}
                    /**
                     * SelectableItemList is a shared component where the
                     * selectedOptionCallback expects a string as a parameter
                     * instead of taking a generic parameter that can define
                     * what it should take (in this case, a union type)
                     * $FlowFixMe
                     */
                    selectedOptionCallback={selectFavouriteGameMethods[id]}
                  />
                </React.Fragment>
              )
            })
          }
          <Link
            className={`next-button next-button${areFavouriteGamesSelected ? '' : '--disabled'}`}
            onClick={favouriteGamesUpdate}
            to={PostSignUpRoutes.Activities}
          >
            { t(`${copyContext}.button_text_next`) }
            <Chevron
              color={BRAND_COLOURS.brandWhite}
              rotation={0}
            />
          </Link>
        </div>
      </div>
    </React.Fragment>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FavouriteGames)
