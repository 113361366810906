// @noflow
import lazyLoad from '@/modules/lazyLoad'
import * as React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import InstaIcons from 'assets/images/icons/social-media/insta-icons.svg'

import CloudinaryImage from '@/components/shared/elements/CloudinaryImage/CloudinaryImage'

import STYLES from './InstagramCarousel.module.sass'

import Text from '../../atoms/Text/Text'
import SwiperSlider from '../../molecules/SwiperSlider/SwiperSlider'
import { GBReviews } from './staticData/gbData'
import { IEReviews } from './staticData/ieData'
import { NLReviews } from './staticData/nlData'
import { PLReviews } from './staticData/plData'

type Review = {
  path: string
  text: string
  instagram_handle: string
  alt: string
}

type Props = {
  countryCode: string
}

const InstagramCarousel = ({ countryCode }: Props): React.ReactElement => {
  const { windowWidth } = useWindowSize()
  const namespace = 'organisms'
  const { t } = useTranslation(namespace)

  const getReviewsByCountry = React.useCallback((): Review[] => {
    switch (countryCode) {
      case 'NI':
      case 'IE':
        return IEReviews
      case 'BE':
      case 'NL':
        return NLReviews
      case 'PL':
        return PLReviews
      case 'GB':
      case 'DE':
      default:
        return GBReviews
    }
  }, [countryCode])

  useEffect((): void => {
    lazyLoad.update()
  }, [])

  return (
    <div className="carousel-wrapper">
      <SwiperSlider
        className={STYLES.instagramCarousel}
        arrows={windowWidth > BREAKPOINTS.lg}
        arrowsInside={windowWidth > BREAKPOINTS.lg}
        bullets={false}
        centeredSlides={windowWidth > BREAKPOINTS.md}
      >
        {getReviewsByCountry().map((review): React.ReactElement => {
          const { path, instagram_handle, text, alt } = review

          return (
            <div key={instagram_handle} className={STYLES.card}>
              <div className={STYLES.imageFrame}>
                <CloudinaryImage
                  alt={t(alt)}
                  className="lazy"
                  image={{
                    path: path,
                    width: 300,
                    height: 300,
                    dpr: 2,
                    quality: 'auto:best'
                  }}
                />
              </div>
              <div className={STYLES.textContent}>
                <img
                  className={STYLES.instaIcons}
                  src={InstaIcons}
                  alt={t('instagram_carousel.social_icons_alt')}
                  width="278"
                  height="21"
                />
                <Text
                  element="span"
                  align="left"
                  variant="textRegular14"
                  text={`${instagram_handle} `}
                  translate={false}
                  bold
                />
                <Text
                  element="span"
                  align="left"
                  variant="textRegular14"
                  text={text}
                  translate={false}
                />
              </div>
            </div>
          )
        })}
      </SwiperSlider>
    </div>
  )
}

export type { Review, Props }
export default InstagramCarousel
