// @noflow
import type { NonCoreInvoiceStatus } from '@types'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import React, { useCallback } from 'react'

import { MINIMUM_AMOUNT_ORDER_WITHOUT_EXTRA_FEE } from '@/utils/butternutbox/constants/digital_product'
import type { Locale } from '@/utils/countryCodeHelper'
import {
  formatCurrencyWithDecimal,
  formatCurrencyWithoutDecimal
} from '@/utils/currency'

import { Button } from '@/components/elements/atoms/Button'
import Label from '@/components/elements/atoms/Label/Label'
import Text from '@/components/elements/atoms/Text/Text'
import Tooltip from '@/components/elements/atoms/Tooltip/Tooltip'

import STYLES from './OrderSummary.module.sass'

import type {
  Code as CountryCode,
  Currency
} from '@/shared_types/rails_models/shipping_countries'

type Props = {
  status?: NonCoreInvoiceStatus
  grossPrice: number
  price: number
  smallPadding?: boolean
  displayCLLink?: boolean
  deliveryPrice?: number
  smallOrderFee?: number | null
  locale: Locale
  currency: Currency
  orderSubmited?: boolean
  shippingCountryCode?: CountryCode
}

const ContactCustomerLove = (): JSX.Element => {
  const handleClick = useCallback((): void => {
    if (window && window._dixa_) {
      window._dixa_.invoke('setWidgetOpen', true)
    } else {
      window.location.href = '/contact'
    }
  }, [])

  return (
    <div className={`${STYLES.line} ${STYLES.centered}`}>
      <Text
        namespace="one_time_purchase_footer"
        text="need_help"
        variant="textRegular16"
        colour="brandBlue500"
        margin={false}
      />
      <button className={`${STYLES.link}`} onClick={handleClick} type="button">
        <Text
          namespace="one_time_purchase_footer"
          text="contact_customer_love"
          variant="textRegular16"
          colour="brandBlue500"
          margin={false}
        />
      </button>
    </div>
  )
}

const OrderSummary = ({
  status,
  price,
  grossPrice,
  smallPadding = false,
  displayCLLink = false,
  deliveryPrice,
  smallOrderFee,
  locale,
  currency,
  orderSubmited,
  shippingCountryCode = 'GB'
}: Props): JSX.Element => {
  const totalPriceOfProducts =
    price - (deliveryPrice || 0) - (smallOrderFee || 0)
  const amountDiscounted = grossPrice - price
  const namespace = 'order_summary'
  const minimumOrderFee =
    MINIMUM_AMOUNT_ORDER_WITHOUT_EXTRA_FEE(shippingCountryCode)

  const tooltipPriceVariable =
    shippingCountryCode === 'NL' ||
    shippingCountryCode === 'BE' ||
    shippingCountryCode === 'PL'
      ? formatCurrencyWithDecimal(minimumOrderFee, { locale, currency })
      : formatCurrencyWithoutDecimal(minimumOrderFee, { locale, currency })

  // Handlers
  const handleReplaceOrder = useCallback(() => {
    window.analytics.track('Cta clicked', {
      properties: {
        location: window.location.pathname,
        title: 'Replace Non Core Order'
      }
    })
    window.location.href = '/dashboard/extras'
  }, [])

  return (
    <div
      className={`${STYLES.container} ${smallPadding && STYLES.smallPadding}`}
    >
      <div className={STYLES.line}>
        <Text
          namespace={namespace}
          text="order_summary"
          variant="display20"
          element="h3"
          colour="brandBlue500"
          margin={false}
        />
        {status === 'failed' && (
          // TODO: improve Label
          // eslint-disable-next-line jsx-a11y/label-has-for
          <Label
            text={{
              text: 'payment_failed',
              namespace: 'order_summary',
              colour: 'brandWhite',
              margin: false
            }}
            colour="dangerRed400"
          />
        )}
      </div>
      <div className={STYLES.line}>
        <Text
          namespace={namespace}
          text="price"
          variant="textRegular16"
          colour="brandBlue500"
          margin={false}
        />
        {status === 'free' ? (
          <div className={STYLES.freeOrderPriceWrapper}>
            <Text
              namespace={namespace}
              text="price_value"
              variables={{
                price: formatCurrencyWithDecimal(totalPriceOfProducts, {
                  locale,
                  currency
                }),
                context: status
              }}
              variant="textRegular14"
              colour="brandBlue400"
              margin={false}
            />
            <Text
              namespace={namespace}
              text="price_value"
              variables={{
                price: formatCurrencyWithDecimal(0, {
                  locale,
                  currency
                })
              }}
              variant="textRegular16"
              colour="brandBlue500"
              margin={false}
            />
          </div>
        ) : (
          <Text
            namespace={namespace}
            text="price_value"
            variables={{
              price: formatCurrencyWithDecimal(totalPriceOfProducts, {
                locale,
                currency
              })
            }}
            variant="textRegular16"
            colour="brandBlue500"
            margin={false}
          />
        )}
      </div>
      {amountDiscounted !== 0 && (
        <div className={STYLES.line}>
          <Text
            namespace={namespace}
            text="discount"
            variant="textRegular16"
            colour="brandRed600"
            margin={false}
          />
          <Text
            translate={false}
            text={formatCurrencyWithDecimal(amountDiscounted, {
              locale,
              currency
            })}
            variant="textRegular16"
            colour="brandRed600"
            margin={false}
          />
        </div>
      )}
      {deliveryPrice && status !== 'free' && (
        <div className={STYLES.line}>
          <Text
            namespace={namespace}
            text="delivery"
            variant="textRegular16"
            colour="brandBlue500"
            margin={false}
          />
          <Text
            namespace={namespace}
            text={formatCurrencyWithDecimal(deliveryPrice, {
              locale,
              currency
            })}
            variant="textRegular16"
            colour="brandBlue500"
            margin={false}
            translate={false}
          />
        </div>
      )}
      {!isUndefined(smallOrderFee) &&
        !isNull(smallOrderFee) &&
        smallOrderFee > 0 &&
        status !== 'free' && (
          <div className={STYLES.line}>
            <Tooltip
              label={{
                namespace: namespace,
                text: 'small_order_fee'
              }}
              size={16}
              identifier="order_summary.small_order_fee_tooltip"
            >
              <div className={STYLES.tooltip}>
                <Text
                  namespace={namespace}
                  text={
                    orderSubmited
                      ? 'past_small_order_fee_tooltip'
                      : 'small_order_fee_tooltip'
                  }
                  variables={{
                    price: tooltipPriceVariable
                  }}
                  variant="textRegular16"
                  colour="brandBlue500"
                />
              </div>
            </Tooltip>
            <Text
              namespace={namespace}
              text={formatCurrencyWithDecimal(smallOrderFee, {
                locale,
                currency
              })}
              variant="textRegular16"
              colour="brandBlue500"
              margin={false}
              translate={false}
            />
          </div>
        )}
      <div className={STYLES.line}>
        <Text
          namespace={namespace}
          text="total"
          variant="display16"
          colour="brandBlue500"
          margin={false}
        />
        <Text
          namespace={namespace}
          text="total_price"
          variables={{
            price: formatCurrencyWithDecimal(grossPrice, {
              locale,
              currency
            }),
            context: status
          }}
          variant="display16"
          colour="brandBlue500"
          margin={false}
        />
      </div>
      {status === 'failed' && (
        <div className={STYLES.button}>
          <Button
            typography={{
              namespace,
              text: 'replace_order'
            }}
            onClick={handleReplaceOrder}
            disableAnalytics
          />
        </div>
      )}
      {displayCLLink && <ContactCustomerLove />}
    </div>
  )
}

export type { Props }

export default OrderSummary
