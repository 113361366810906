import { gql } from '@apollo/client'

const APPOINTMENT_QUERY = gql`
  query AppointmentQuery($appointmentId: ID!) {
    user {
      id
      appointment(appointmentId: $appointmentId) {
        id
        dog {
          id
          name
        }
        time
        status
        appointmentProduct {
          id
          appointmentType
        }
        petCareProfessional {
          id
          name
          profession
          profilePicture
        }
      }
    }
  }
`

export { APPOINTMENT_QUERY }
