// @noflow
import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import scrollToElement from '@/utils/scrollToElement'

import * as ACTIONS from '../actions'
import type { Dispatch, State } from '../reducers'
import type { State as FormState } from '../reducers/formDataReducer'
import FrequentlyAskedQuestions from './FrequentlyAskedQuestions'
import FillOutForm from './contact_form/FillOutForm'
import GetInTouch from './contact_form/GetInTouch'

type PresentationalProps = {
  viewingStep: FormState['viewingStep']
}

type ActionProps = {
  setFormViewingStep: (viewingStep: FormState['viewingStep']) => void
}

type Props = PresentationalProps & ActionProps

const mapStateToProps = ({ formData }: State): PresentationalProps => {
  const { viewingStep } = formData

  return {
    viewingStep
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => {
  const setFormViewingStep = (viewingStep: FormState['viewingStep']): void => {
    dispatch(ACTIONS.setFormViewingStep(viewingStep))
  }

  return {
    setFormViewingStep
  }
}

const ContactForm = ({
  viewingStep,
  setFormViewingStep
}: Props): React.ReactElement => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const viewingStepParam = urlParams.get('viewingStep')
    if (viewingStepParam === 'querySelection') {
      setFormViewingStep('Query Selection')
      const el = document.getElementById('contact-us-title')
      if (el) scrollToElement(el as HTMLElement)
    }
  }, [setFormViewingStep])

  switch (viewingStep) {
    case 'Get In Touch': {
      return (
        <React.Fragment>
          <GetInTouch />
          <FrequentlyAskedQuestions />
        </React.Fragment>
      )
    }
    case 'Query Selection':
    case 'Fill Out Form': {
      return (
        <React.Fragment>
          <FillOutForm />
          <FrequentlyAskedQuestions />
        </React.Fragment>
      )
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm)
