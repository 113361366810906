import { Grid } from '@mui/material'
import React from 'react'

import { CardSkeleton } from '@/components/elements/atoms/Card/CardSkeleton'
import SkeletonButton from '@/components/elements/atoms/SkeletonButton/SkeletonButton'
import SkeletonParagraph from '@/components/elements/atoms/SkeletonParagraph/SkeletonParagraph'
import SkeletonTitle from '@/components/elements/atoms/SkeletonTitle/SkeletonTitle'

const OrderPageSkeleton = (): JSX.Element => {
  return (
    <div>
      <SkeletonTitle width={200} height={22} margin={4} align="left" />
      <SkeletonParagraph
        width={180}
        count={1}
        shortLine={false}
        height={20}
        margin={4}
      />
      <SkeletonButton
        height={26}
        width={80}
        margin=".8rem 0 1.6rem"
        justify="start"
      />
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <CardSkeleton height={'8.8rem'} />
        </Grid>
        <Grid item sm={12}>
          <CardSkeleton height={'14.8rem'} />
        </Grid>
        <Grid item sm={12}>
          <CardSkeleton height={'54rem'} />
        </Grid>
        <Grid item sm={12}>
          <CardSkeleton height={'32rem'} />
        </Grid>
      </Grid>
    </div>
  )
}

export default OrderPageSkeleton
