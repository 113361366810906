import { gql } from '@apollo/client'

const DOWNSIZE_AND_SAVE_PAGE_QUERY = gql`
  query DownsizeAndSavePageQuery {
    user {
      id
      shippingCountryCode
      preferredLanguage
      dogs {
        id
        gender
      }
      subscription {
        id
        status
        recurringOrder {
          deliveryFee
          planTotal
          planTotalPerDay
        }
        plan {
          id
          pouchSize
          numberOfPouches
          durationInDays
          typeOfPlanForCustomer
          servingSize
          downsizeablePlan {
            id
            price
            pricePerDay
            pouchSize
            numberOfPouches
            durationInDays
            typeOfPlanForCustomer
            servingSize
          }
        }
      }
    }
  }
`

export { DOWNSIZE_AND_SAVE_PAGE_QUERY }
