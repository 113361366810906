import {
  CsrfTokenContext,
  TokenContext
} from '@/context/injectedValues/injectedValues'
import thankYouConfetti from '@/lottie/thank-you-page-v2.json'
import initI18n, { Language } from '@/packs/localisation'
import { useQuery } from '@apollo/client'
import classNames from 'classnames'
import Handlebars from 'handlebars'
import Lottie from 'lottie-react'
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'

import { possessive, toLocalisedSentence } from '@/utils/StringHelper'
import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import * as Sentry from '@/utils/sentry'

import { THANK_YOU_PAGE } from '@/constants/RibbonEvents'

import AppOverlay from './components/AppOverlay/AppOverlay'
import { DogProfileConfirmation } from './components/DogProfileConfirmation'
import { SubscriptionConfirmation } from './components/SupscriptionConfirmation'
import withApollo from '@/components/apollo/withApollo'
import { Button } from '@/components/elements/atoms/Button'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import Text from '@/components/elements/atoms/Text'

import STYLES from './ThankYouPageV2.module.sass'

import { thankYouQueryV2_user_dogs as Dog } from './__generated__/thankYouQueryV2'

import { AccountOverview, Discounts, DogProfile } from './components'
import { THANK_YOU_QUERY_V2 } from './queries'

type DogProfilePictureState = { id: string; path: string }

type Props = {
  variant: Language
  csrfToken: string
  token: string
}

const ThankYouPageV2 = ({
  variant,
  csrfToken,
  token
}: Props): JSX.Element | null => {
  initI18n(variant)

  const [confirmationPolaroid, setConfirmationPolaroid] = useState<
    false | string
  >(false)
  const [confirmationDog, setConfirmationDog] = useState<Dog | false>(false)
  const [dogProfilePictures, setDogProfilePictures] = useState<
    Array<DogProfilePictureState>
  >([])
  const [showAppOverlay, setShowAppOverlay] = useState(false)
  const [showSubscriptionConfirmation, setShowSubscriptionConfirmation] =
    useState(true)
  const [userCsrftoken, setUserCsrftoken] = useState<string | undefined>(
    undefined
  )
  const [userToken, setUserToken] = useState<string | undefined>(undefined)

  const { loading, data, error } = useQuery(THANK_YOU_QUERY_V2, {
    variables: {
      headerSlug: 'header',
      whatsappSingleDogSlug: 'whatsapp_single_dog',
      whatsappMultipleDogsSlug: 'whatsapp_multiple_dogs'
    }
  })

  const handleDogProfileUploaded = useCallback((path, dog) => {
    if (path) {
      const updatedPicures = (prev: Array<DogProfilePictureState>) => {
        const updated = prev.map((item) => {
          if (item.id === dog.id) {
            return { ...item, path }
          }

          return item
        })

        return updated
      }

      setDogProfilePictures(updatedPicures)
      setConfirmationPolaroid(path)
      setConfirmationDog(dog)
    }
  }, [])

  const [isScrolling, setIsScrolling] = useState(false)

  const handleCloseAppOverlay = useCallback(() => setShowAppOverlay(false), [])

  const handleScroll = () => {
    const bottom =
      window.innerHeight + window.scrollY >=
      document.documentElement.scrollHeight

    const top = window.scrollY === 0

    if (bottom || top) {
      setIsScrolling(false)
    } else {
      setIsScrolling(true)
    }
  }

  const csrfTokenProviderValues = useMemo(
    () => ({ csrfToken: userCsrftoken, setCsrfToken: setUserCsrftoken }),
    [userCsrftoken]
  )

  const tokenProviderValues = useMemo(
    () => ({ token: userToken, setToken: setUserToken }),
    [userToken]
  )

  useEffect(() => setUserCsrftoken(csrfToken), [csrfToken])
  useEffect(() => setUserToken(token), [token])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {
      passive: true
    })

    const localStorageKeys = [
      'wizardState',
      'checkout_state',
      'simplifiedPendingSubscription',
      'simplified_selected_plan_details'
    ]

    const sessionStorageKeys = ['gclid']

    localStorageKeys.forEach((key) => {
      window.localStorage.removeItem(key)
    })

    sessionStorageKeys.forEach((key) => {
      window.sessionStorage.removeItem(key)
    })

    if (window.ribbon) window.ribbon('trigger', THANK_YOU_PAGE)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleCloseSubscriptionConfirm = useCallback(() => {
    setShowSubscriptionConfirmation(false)
  }, [])

  useEffect(() => {
    if (!data) return

    const pictures = data.user?.dogs.map((dog: Dog) => ({
      id: dog.id,
      path: dog.dogProfile?.avatarUrl
    }))

    setDogProfilePictures(pictures)
  }, [data])

  const handleGoToAccount = useCallback(() => {
    if (data && data.systemShouldShowAppContent === 'true') {
      setShowAppOverlay(true)
    } else {
      window.location.href = '/account'
    }
  }, [data])

  const handleSuccessClose = useCallback(() => {
    setTimeout(() => {
      setConfirmationPolaroid(false)

      const allHavePicture = dogProfilePictures.every((dog) => !!dog.path)

      if (allHavePicture) handleGoToAccount()
    }, 500)
  }, [dogProfilePictures, handleGoToAccount])

  if (error) {
    Sentry.captureException('Error in ThankYouPageV2', {
      extra: error,
      tags: {
        product: Sentry.Product.Checkout,
        team: Sentry.Team.Retention
      }
    })
  }

  if (loading || !data || error) return null

  const { whatsAppSingleDog, whatsAppMultipleDogs, user } = data || {}
  const { preferredLanguage, shippingCountryCode, subscription } = user || {}
  const { referralLink = {} } = subscription || {}

  const { locale, currency } = countryCodeToLocaleCurrency(
    shippingCountryCode || 'GB',
    preferredLanguage || 'en'
  )

  const dogNames = user?.dogs?.map((dog: Dog) => dog.name) || []
  const possesiveDogNames =
    user?.dogs?.map((dog: Dog) => possessive(dog.name)) || []

  const joinedDogNames = toLocalisedSentence({
    arr: dogNames,
    lng: preferredLanguage,
    oxfordComma: true
  })

  const possesiveJoinedDogNames = toLocalisedSentence({
    arr: possesiveDogNames,
    lng: preferredLanguage,
    oxfordComma: true
  })

  const copyLinkMessage = Handlebars.compile(
    dogNames.length === 1 ? whatsAppSingleDog.text : whatsAppMultipleDogs.text,
    { noEscape: true }
  )({
    dogNames: joinedDogNames,
    // eslint-disable-next-line i18next/no-literal-string
    referralLink: `${referralLink.url}?share_source=copylink`
  })

  const footerClass = classNames(STYLES.footer, {
    [STYLES.hidden]: !isScrolling
  })

  const names = user.dogs.map((dog: Dog) => dog.name)

  if (showSubscriptionConfirmation) {
    return (
      <SubscriptionConfirmation
        handleSuccessClose={handleCloseSubscriptionConfirm}
      />
    )
  }

  if (showAppOverlay) {
    return (
      <AppOverlay
        shippingCode={shippingCountryCode}
        closeAppOverlay={handleCloseAppOverlay}
      />
    )
  }

  if (confirmationPolaroid && confirmationDog) {
    return (
      <DogProfileConfirmation
        confirmationDog={confirmationDog}
        handleSuccessClose={handleSuccessClose}
        confirmationPolaroid={confirmationPolaroid}
      />
    )
  }

  return (
    <CsrfTokenContext.Provider value={csrfTokenProviderValues}>
      <TokenContext.Provider value={tokenProviderValues}>
        <Fragment>
          <div className={STYLES.confetti}>
            <Lottie animationData={thankYouConfetti} loop={false} />
          </div>

          <SectionWrapper
            margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
            bgColour="brandYellow100"
          >
            <SectionWrapper
              bgColour="brandYellow200"
              className={STYLES.header}
              margin={{ top: 0 }}
            >
              <Text
                margin={false}
                dataTestId="thank-you-title"
                namespace="account"
                text="thank_you_page_v2.welcome_title"
                variant="display28"
                align="center"
              />
            </SectionWrapper>

            <div className={STYLES.page}>
              <Text
                text="thank_you_page_v2.welcome_subtitle"
                namespace="account"
                dataTestId="thank-you-subtitle"
                variables={{
                  dogNames: toLocalisedSentence({
                    arr: names,
                    lng: preferredLanguage
                  })
                }}
              />

              <Discounts
                referralLink={referralLink}
                locale={locale}
                currency={currency}
                copyLinkMessage={copyLinkMessage}
                possesiveJoinedDogNames={possesiveJoinedDogNames}
              />

              <DogProfile
                dogs={user?.dogs}
                handleUpload={handleDogProfileUploaded}
              />

              <AccountOverview
                onGoToAccountClick={handleGoToAccount}
                dogs={user?.dogs}
              />

              <footer className={footerClass}>
                <div className={STYLES.buttonWrapper}>
                  <Button
                    dataTestId="account-button"
                    onClick={handleGoToAccount}
                    identifier="thank_you.sticky.go_to_account"
                    variant="secondary"
                    typography={{
                      text: 'thank_you_page_v2.go_to_account',
                      namespace: 'account'
                    }}
                  />
                </div>
              </footer>
            </div>
          </SectionWrapper>
        </Fragment>
      </TokenContext.Provider>
    </CsrfTokenContext.Provider>
  )
}

export default withApollo(ThankYouPageV2)
