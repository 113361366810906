import { CustomerIssueEntryFieldData } from '@types'
import React, { useCallback } from 'react'

import STYLES from '../ReportIssue.module.sass'

type TextAreaFieldProps = {
  id: string
  placeholder: string
  value: string
  setValue: (
    key: string,
    value: string,
    dataType: keyof typeof CustomerIssueEntryFieldData
  ) => void
}

const TextAreaField = ({
  id,
  placeholder,
  value,
  setValue
}: TextAreaFieldProps): JSX.Element => {
  const maxLength = 1000
  const onTextChange = useCallback(
    (e) => {
      setValue(id, e.target.value, 'string')
    },
    [setValue, id]
  )
  const issueClass = useCallback((): string => {
    return value.length >= maxLength ? STYLES.issue : ''
  }, [value])

  return (
    <>
      <textarea
        rows={8}
        className={issueClass()}
        maxLength={maxLength}
        value={value}
        onChange={onTextChange}
        placeholder={placeholder}
      />
      <p className={STYLES.maxLengthIndicator}>
        {value.length + '/' + maxLength}
      </p>
    </>
  )
}

export default TextAreaField
