import React from 'react'

const Success = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
    <rect width="20" height="20" fill="#238A0F" rx="10" />
    <g clipPath="url(#a)">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="m8.505 13.353-3.044-3.041a.448.448 0 0 1-.128-.33c0-.135.043-.245.128-.33l.674-.66a.406.406 0 0 1 .32-.146c.127 0 .24.048.337.146l2.041 2.052 4.375-4.397a.462.462 0 0 1 .338-.147c.127 0 .234.049.319.147l.674.66a.448.448 0 0 1 .128.33.448.448 0 0 1-.128.329l-5.378 5.387a.415.415 0 0 1-.328.147.415.415 0 0 1-.328-.147Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M4 4h12v12H4z" />
      </clipPath>
    </defs>
  </svg>
)

export default Success
