import { gql } from '@apollo/client'

const FEEDING_TIPS_QUERY = gql`
  query FeedingTipsQuery {
    user {
      id
      shippingCountryCode
      dogs {
        id
        eaterType
      }
    }
  }
`

export { FEEDING_TIPS_QUERY }
