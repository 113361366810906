// @noflow
import React, { useCallback, useEffect, useState } from 'react'

import useBoolean from '@/hooks/useBoolean'

import Icon from '@/components/elements/atoms/Icon/Icon'
import Text, { Props as TextProps } from '@/components/elements/atoms/Text/Text'

import STYLES from './Select.module.sass'

type Option = {
  value: string | number
  label: TextProps
  id: string
}

type OptionProps = {
  option: Option
  value?: Option | null
  onChange?: (value: Option) => void
  setValue: (value: Option) => void
  setIsOpen: (value: boolean) => void
}

type Props = {
  initialValue?: Option | null
  label: TextProps
  options: Array<Option>
  disabled?: boolean
  size?: 'slim' | 'regular' | 'large'
  onChange?: (value: Option) => void
}

const OptionComponent = ({
  option,
  value,
  setValue,
  onChange,
  setIsOpen
}: OptionProps): JSX.Element => {
  const handleChange = useCallback((): void => {
    setValue(option)
    onChange && onChange(option)
    setIsOpen(false)
  }, [setValue, onChange, option, setIsOpen])

  return (
    <div
      onClick={handleChange}
      onKeyUp={handleChange}
      className={`
      ${STYLES.listItem}
      ${value?.value === option.value && STYLES.selected}
      `}
      role="button"
      tabIndex={0}
    >
      <Text
        text={option.label.text}
        {...(!option.label.translate
          ? { namespace: option.label.namespace }
          : {})}
        variant="textRegular18"
        colour="brandBlue500"
        variables={option.label.variables}
        translate={option.label.translate}
        margin={false}
      />
    </div>
  )
}

const Select = ({
  initialValue,
  label,
  options,
  disabled = false,
  size = 'regular',
  onChange
}: Props): JSX.Element => {
  const [value, setValue] = useState<Option | null>(null)
  const {
    value: isOpen,
    toggle: toggling,
    setValue: setIsOpen
  } = useBoolean(false)

  const handleToggle = useCallback(() => {
    if (!disabled) {
      toggling()
    }
  }, [disabled, toggling])

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue)
    }
  }, [initialValue])
  const headerText = value?.label || label

  return (
    <div className={STYLES.container}>
      <div
        className={`${STYLES.header} ${disabled ? STYLES.disabled : ''} ${
          size && STYLES[size]
        }`}
        onClick={handleToggle}
        onKeyUp={handleToggle}
        role="button"
        tabIndex={0}
      >
        <Text
          text={headerText.text}
          namespace={headerText.namespace}
          translate={headerText.translate}
          variant="textRegular18"
          colour={disabled ? 'grey400' : 'brandBlue500'}
          variables={headerText.variables}
          margin={false}
        />
        <div className={STYLES.icon}>
          <Icon
            size={18}
            asset="chevron"
            accentColour={disabled ? 'grey400' : 'brandBlue500'}
          />
        </div>
      </div>
      {isOpen && (
        <div className={STYLES.listContainer}>
          <ul className={STYLES.list}>
            {options.map((option) => (
              <OptionComponent
                key={option.value}
                option={option}
                value={value}
                setValue={setValue}
                onChange={onChange}
                setIsOpen={setIsOpen}
              />
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

export type { Props, Option }
export default Select
