// @noflow
import React from 'react'
import { useTranslation } from 'react-i18next'

import Text from '@/components/elements/atoms/Text/Text'
import * as Validation from '@/components/pages/CheckoutPage/components/Validation/Validation'
import * as inputs from '@/components/pages/CheckoutPage/helpers/inputs'

import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'

import type {
  AccountDetailsFormKey,
  AddressDetailsFormKey,
  DeliveryDetailsFormKey
} from '../../types'

type FormSectionKey =
  | AddressDetailsFormKey
  | AccountDetailsFormKey
  | DeliveryDetailsFormKey

type InputInteractionState =
  | 'InteractingInteracted'
  | 'InteractingNotInteracted'
  | 'NotInteractingInteracted'
  | 'NotInteractingNotInteracted'

type InputWithString = {
  errorMessage: string | null
  inputInteractionState: InputInteractionState
  value: string
}

type Props = {
  children?: React.ReactNode
  fieldName: FormSectionKey
  isValid: boolean
  onBlur: (event: React.ChangeEvent<HTMLInputElement>) => void
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  value: string
  dataTestId?: string
  isHidden?: boolean
  shippingCountryCode?: CountryCode
}

type Translate = (arg0: string) => string

const placeholder = (
  t: Translate,
  fieldName: FormSectionKey,
  shippingCountryCode?: CountryCode
): React.ReactElement => {
  const copy_context = 'input_placeholders'
  const placeholderFieldName =
    fieldName === 'postcode' && shippingCountryCode === 'IE'
      ? 'eircode'
      : fieldName
  // For GB, we want to make the field optional but not display the copy indicating it is 'optional'
  const isGBMobile =
    fieldName === 'mobileNumber' && shippingCountryCode === 'GB'
  return (
    <p
      className={`checkout-placeholder ${fieldName}-placeholder`}
      id={`${fieldName}-placeholder`}
    >
      {inputs.mapFieldNameToPlaceholder(
        t,
        placeholderFieldName,
        shippingCountryCode
      )}
      {isGBMobile ||
      fieldName === 'searchTerm' ||
      inputs.isRequired(fieldName, shippingCountryCode) ? null : (
        <span className="optional-label">
          {`- ${t(`${copy_context}.optional`)}`}
        </span>
      )}
    </p>
  )
}

const BaseInputField = ({
  onChange,
  onBlur,
  children,
  fieldName,
  value,
  isValid,
  dataTestId,
  isHidden = false,
  shippingCountryCode
}: Props): React.ReactElement => {
  const { t } = useTranslation('checkout')
  return (
    <div
      className={`checkout-input-wrapper${
        isValid ? '' : ' checkout-input-wrapper--invalid'
      } checkout-input-wrapper--${fieldName}${
        isHidden ? ' checkout-input-wrapper--hidden' : ''
      }`}
    >
      <input
        id={fieldName}
        data-testid={dataTestId}
        aria-labelledby={`${fieldName}-placeholder`}
        autoComplete={inputs.mapFieldNameToAutocomplete(fieldName)}
        className={`checkout-input${
          value === '' ? '' : ' checkout-input--interacted'
        }`}
        name={fieldName}
        onBlur={onBlur}
        onChange={onChange}
        type={inputs.mapFieldNameToType(fieldName)}
        value={value}
      />
      {placeholder(t, fieldName, shippingCountryCode)}
      {children}
    </div>
  )
}

const StandardInputField = ({
  input,
  fieldName,
  onChange,
  dataTestId,
  onBlur,
  isHidden,
  shippingCountryCode,
  maxCharacterLimit
}: {
  fieldName: FormSectionKey
  input: InputWithString
  onBlur: (event: React.ChangeEvent<HTMLInputElement>) => void
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  dataTestId?: string
  isHidden?: boolean
  shippingCountryCode?: CountryCode
  maxCharacterLimit?: number | null
}): React.ReactElement => {
  const indicator = inputs.validationIndicator(input)

  return (
    <React.Fragment>
      <BaseInputField
        fieldName={fieldName}
        isValid={indicator !== 'error'}
        onBlur={onBlur}
        onChange={onChange}
        dataTestId={dataTestId}
        value={input.value}
        isHidden={isHidden}
        shippingCountryCode={shippingCountryCode}
      >
        {indicator === 'checkmark' ? (
          <Validation.Checkmark color="green" />
        ) : null}
      </BaseInputField>
      {!isHidden && maxCharacterLimit && fieldName === 'addressLine1' && (
        <div
          className={`checkout-counter ${
            indicator === 'error' && 'checkout-counter--invalid'
          }`}
        >
          <div>
            {indicator === 'error' && (
              <Validation.Error errorMessage={input.errorMessage || ''} />
            )}
          </div>
          <Text
            text={`${input.value.length}/${maxCharacterLimit}`}
            align="right"
            variant="textRegular12"
            translate={false}
            colour={indicator === 'error' ? 'dangerRed300' : 'brandBlue500'}
          />
        </div>
      )}
      {fieldName !== 'addressLine1' && indicator === 'error' ? (
        <Validation.Error errorMessage={input.errorMessage || ''} />
      ) : null}
    </React.Fragment>
  )
}

const AddressSearchInputField = ({
  input,
  fieldName,
  onChange,
  dataTestId,
  onBlur,
  shippingCountryCode
}: {
  fieldName: FormSectionKey
  input: InputWithString
  onBlur: (event: React.ChangeEvent<HTMLInputElement>) => void
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  dataTestId?: string
  shippingCountryCode: CountryCode
}): React.ReactElement => {
  const indicator = inputs.validationIndicator(input)
  return (
    <React.Fragment>
      <div className="search-term-button-wrapper">
        <BaseInputField
          fieldName={fieldName}
          isValid={indicator !== 'error'}
          onBlur={onBlur}
          onChange={onChange}
          dataTestId={dataTestId}
          value={input.value}
          shippingCountryCode={shippingCountryCode}
        />
      </div>
      {indicator === 'error' ? (
        <Validation.Error errorMessage={input.errorMessage || ''} />
      ) : null}
    </React.Fragment>
  )
}

export {
  StandardInputField,
  AddressSearchInputField,
  BaseInputField,
  InputWithString,
  InputInteractionState
}
