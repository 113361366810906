// @noflow
import type { Language } from '@/packs/localisation'
import React from 'react'

import FreshMealItem, {
  Config,
  ProductLabel
} from '@/components/elements/molecules/BoxItem/FreshMealItem/FreshMealItem'

import { Code } from '@/types'

type Recipe = {
  quantity: number
  price?: number
  discountedPrice?: number
  flavour: {
    thumbnail: string
    name: string
    slug: string
    recipeSurcharge?: number | null
    productLabels: Array<ProductLabel>
  }
}

type Props = {
  pouchSize?: number
  recipes: Array<Recipe>
  shippingCountryCode: Code
  language: Language
  shouldSeeRecipeSurchargeTooltips: boolean
  shouldSeeRecipeSurchargePriceIncreasePl: boolean
  config?: Config
  perDogLabel?: boolean
}

const FreshMealList = ({
  pouchSize,
  recipes,
  shippingCountryCode,
  language,
  shouldSeeRecipeSurchargeTooltips,
  shouldSeeRecipeSurchargePriceIncreasePl,
  config,
  perDogLabel = false
}: Props): JSX.Element => {
  return (
    <div>
      {recipes.map(({ flavour, quantity, price, discountedPrice }) => {
        return (
          <FreshMealItem
            key={flavour.slug}
            name={flavour.name}
            pouchSize={pouchSize}
            numberOfPouches={quantity}
            thumbnail={flavour.thumbnail}
            productLabels={flavour.productLabels}
            recipeSurcharge={flavour.recipeSurcharge}
            slug={flavour.slug}
            shippingCountryCode={shippingCountryCode}
            language={language}
            shouldSeeRecipeSurchargeTooltips={shouldSeeRecipeSurchargeTooltips}
            shouldSeeRecipeSurchargePriceIncreasePl={
              shouldSeeRecipeSurchargePriceIncreasePl
            }
            price={price}
            discountedPrice={discountedPrice}
            config={config}
            perDogLabel={perDogLabel}
          />
        )
      })}
    </div>
  )
}

export { Props, Recipe }
export default FreshMealList
