// @flow

/*
This is the JS code that goes along with the shared _tooltip.erb.html view
and _tooltip.sass.
For when you want to use a tooltip but an entire Elm app is overkill.
Not to mention a React app which would be even bigger.

Call initializeTooltip after the DOM has loaded and pass it the div.tooltip
DOM node. See packs/how_it_works.js for a usage example.

Essentially,
```
import initializeTooltip from '../shared/vanillaTooltip'

document.addEventListener('DOMContentLoaded', (): void => {
  document.querySelectorAll('.tooltip').forEach(initializeTooltip)
})
```
*/
const initializeTooltip = (tooltip: HTMLElement): void => {
  const trigger = tooltip.querySelector('.tooltip__trigger-text')
  const icon = tooltip.querySelector('.tooltip__icon')
  const content = tooltip.querySelector('.tooltip__content')

  if (!trigger || !content) {
    throw new Error(`Unknown structure for the tooltip DOM node`)
  }
  const toggle = (open: boolean): void => {
    if (open) {
      tooltip.classList.remove('tooltip--closed')
      tooltip.classList.add('tooltip--open')
    } else {
      tooltip.classList.remove('tooltip--open')
      tooltip.classList.add('tooltip--closed')
    }
  }
  const isOpen = (): boolean => tooltip.classList.contains('tooltip--open')

  tooltip.onblur = (): void => toggle(false)
  tooltip.onfocus = (e: Event): void => {
    setTimeout((): void => {
      if (!isOpen()) {
        e.preventDefault()
        toggle(true)
      }
    }, 250)
  }
  const onClick = (e: Event): void => {
    e.preventDefault()
    e.stopPropagation()
    toggle(!isOpen())
  }

  trigger.onclick = onClick
  if (icon) icon.onclick = onClick
}

export default initializeTooltip
