// @noflow
import React, { useCallback } from 'react'

import Text from '@/components/elements/atoms/Text'

import STYLES from './PaymentComponents.module.sass'

type CardProps = {
  onClick: () => void
  disableCreditCardButton: boolean
  namespace: string
}

const Card = ({
  onClick,
  disableCreditCardButton,
  namespace
}: CardProps): JSX.Element => {
  const copyContext = 'plan_steps.payment.payment_options'

  const handleOnClick = useCallback(() => {
    onClick()
  }, [onClick])

  return (
    <button
      className={STYLES.button}
      id="credit-card-button"
      type="button"
      disabled={disableCreditCardButton}
      onClick={handleOnClick}
    >
      <Text
        translate
        namespace={namespace}
        text={`${copyContext}.credit_card`}
      />
    </button>
  )
}

export default Card
