// @noflow
import { format } from 'date-fns'
import React from 'react'

import SingleAdultDog from 'assets/images/illustrations/dogs/dog-profile-avatars/single-adult-dog.svg'

import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './ReviewCard.module.sass'

import type { ProductCollectionQuery_productCollection_publicCustomerReviews as CustomerReview } from '../../../queries/__generated__/ProductCollectionQuery'

type Props = {
  publicCustomerReview: CustomerReview
}

const ReviewCard = ({
  publicCustomerReview: { author, createdAt, description }
}: Props): JSX.Element => {
  return (
    <div className={STYLES.container}>
      {description && (
        <Text
          element="p"
          translate={false}
          text={description}
          variant="textRegular16"
          margin={false}
        />
      )}
      <div className={STYLES.authorWrapper}>
        <img alt="" className={STYLES.avatar} src={SingleAdultDog} />
        <div className={STYLES.information}>
          <Text
            element="p"
            translate={false}
            text={author}
            variant="textRegular14"
            margin={false}
          />
          <Text
            element="p"
            translate={false}
            text={format(new Date(createdAt), 'EEEE, MMM do yyy')}
            variant="textRegular14"
            margin={false}
          />
        </div>
      </div>
    </div>
  )
}

export default ReviewCard
