// @noflow
import React from 'react'

import {
  ICON_COLOURS,
  IconColours
} from '@/components/elements/atoms/Icon/Icon'

type Props = {
  size: number
  accentColour: IconColours
  width?: number | string
}

const SquiggleDashedArrow = ({
  size,
  accentColour,
  width
}: Props): JSX.Element => {
  return (
    <svg
      fill="none"
      height={size}
      viewBox="0 0 97 106"
      width={width || '100%'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.843 100.907c-32.234-30.6-7.975-62.154 8.543-63.52 16.515-1.366 25.697 12.141 5.32 19.389-20.376 7.248-39.69-21.179-12.723-43.394 6.995-6.677 22.628-9.888 32.479 1.187"
        stroke={ICON_COLOURS[accentColour]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeDasharray="3 3"
      />
      <path
        d="m35.484 90.712 1.125 10.159-10.136 1.103"
        stroke={ICON_COLOURS[accentColour]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SquiggleDashedArrow
