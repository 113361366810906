// @noflow
import isUndefined from 'lodash/isUndefined'
import React, { KeyboardEvent, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
// components
import Joyride, {
  CallBackProps,
  Props as JoyrideProps,
  Step,
  TooltipRenderProps
} from 'react-joyride'

import BRAND_COLOURS from '@/constants/BrandColours'

import CloseIcon from 'assets/images/icons/crosses/close--blue.svg'

import FlatButton from '@/components/elements/atoms/FlatButton/FlatButton'
import Image, {
  Props as ImageProps
} from '@/components/elements/atoms/Image/Image'
import Text, { Props as TextProps } from '@/components/elements/atoms/Text/Text'

// styles
import STYLES from './TourV2.module.sass'

type CustomStep = Step & {
  data?: { id?: string }
}

type Props = Pick<JoyrideProps, 'run'> & {
  steps: CustomStep[]
  callback?: (props: CallBackProps) => void
}

type TooltipProps = TooltipRenderProps & {
  step: CustomStep
}

type TourViewedProps = {
  tourFirstViewed: string | null
  lastViewedOn?: string
  completed: boolean
}

type TourTextProps = Pick<TextProps, 'text' | 'variables' | 'namespace'>

type TourContentProps = {
  title?: TourTextProps
  description?: TourTextProps
  image?: Omit<ImageProps, 'image'> & { image?: Partial<ImageProps['image']> }
}

const TourTitle = ({
  text,
  variables,
  namespace
}: TourTextProps): JSX.Element => (
  <div className={STYLES.title}>
    <Text
      namespace={namespace}
      text={text}
      variables={variables}
      variant="display16"
      bold
      align="left"
      translate
      element="div"
      colour="brandBlue500"
    />
  </div>
)

const TourDescription = ({
  text,
  variables,
  namespace
}: TourTextProps): JSX.Element => (
  <Text
    namespace={namespace}
    text={text}
    variables={variables}
    variant="textRegular16"
    align="left"
    translate
    element="div"
    colour="brandBlue500"
  />
)

const TourContent = (props: TourContentProps): JSX.Element => {
  const { title, description, image } = props

  return (
    <div>
      {!isUndefined(image) &&
        !isUndefined(image.alt) &&
        !isUndefined(image.slug) && (
          <Image
            className={STYLES.image}
            slug={image.slug}
            image={{
              ...image.image,
              resizeMode: 'resize_to_fill',
              height: 140,
              width: 364
            }}
            alt={image.alt}
          />
        )}
      {(!isUndefined(title) || !isUndefined(description)) && (
        <div className={STYLES.content}>
          {!isUndefined(title) && (
            <TourTitle
              text={title.text}
              variables={title.variables}
              namespace={title.namespace}
            />
          )}
          {!isUndefined(description) && (
            <TourDescription
              text={description.text}
              variables={description.variables}
              namespace={description.namespace}
            />
          )}
        </div>
      )}
    </div>
  )
}

const TourTooltip = ({
  index,
  step,
  backProps,
  closeProps,
  skipProps,
  primaryProps,
  tooltipProps,
  isLastStep
}: TooltipProps): JSX.Element | null => {
  const namespace = 'molecules'
  const context = 'tour'
  const { t } = useTranslation(namespace)
  const uid = step.data?.id ?? 'tour'

  return (
    <div
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...tooltipProps}
      className={STYLES.tooltip}
    >
      <button
        type="button"
        className={`${STYLES.button} ${STYLES.closeButton}`}
        onClick={isLastStep ? closeProps.onClick : skipProps.onClick}
      >
        <img
          className={STYLES.closeIcon}
          alt={t(`${context}.close`)}
          src={CloseIcon}
        />
      </button>
      <div className={STYLES.card}>
        {step.content}
        <div className={STYLES.buttons}>
          {index > 0 && !isLastStep && (
            <FlatButton
              text={{
                text: `${context}.back`,
                namespace: 'molecules',
                variant: 'textRegular16'
              }}
              variant="transparentHoverYellow400"
              onClick={
                backProps.onClick as (
                  e:
                    | MouseEvent<HTMLDivElement | MouseEvent | HTMLElement>
                    | KeyboardEvent<
                        HTMLDivElement | KeyboardEvent | HTMLElement
                      >
                    | undefined
                ) => void
              }
              identifier={`${uid}-back-button-step-${index + 1}`}
              focusHighlight={false}
            />
          )}
          <FlatButton
            text={{
              text: isLastStep ? `${context}.finish` : `${context}.next`,
              namespace: 'molecules',
              variant: 'textRegular16'
            }}
            className={isLastStep ? STYLES.finish : ''}
            variant="yellow200HoverYellow400"
            onClick={
              isLastStep
                ? (closeProps.onClick as (
                    e:
                      | MouseEvent<HTMLDivElement | MouseEvent | HTMLElement>
                      | KeyboardEvent<
                          HTMLDivElement | KeyboardEvent | HTMLElement
                        >
                      | undefined
                  ) => void)
                : (primaryProps.onClick as (
                    e:
                      | MouseEvent<HTMLDivElement | MouseEvent | HTMLElement>
                      | KeyboardEvent<
                          HTMLDivElement | KeyboardEvent | HTMLElement
                        >
                      | undefined
                  ) => void)
            }
            identifier={
              isLastStep
                ? `${uid}-finish-button`
                : `${uid}-next-button-step-${index + 1}`
            }
            focusHighlight={false}
          />
        </div>
      </div>
    </div>
  )
}

const TourV2 = ({ steps, run, callback }: Props): JSX.Element | null => {
  return (
    <Joyride
      callback={callback}
      run={run}
      continuous
      scrollToFirstStep={false}
      steps={steps}
      spotlightPadding={0}
      styles={{
        options: {
          zIndex: 2000000,
          arrowColor: BRAND_COLOURS.brandYellow300,
          overlayColor: 'rgba(0, 0, 0, 0.5)',
          primaryColor: BRAND_COLOURS.brandYellow300
        },
        spotlight: {
          borderRadius: 16
        }
      }}
      tooltipComponent={TourTooltip}
      disableOverlayClose
    />
  )
}

export { Props, CustomStep, TourViewedProps, TourContent }
export default TourV2
