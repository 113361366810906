// @noflow
import type { Language } from '@/packs/localisation'
import { ACCOUNT_ROUTES } from '@/routes'
import { useQuery } from '@apollo/client'
import type { PaymentMethodable } from '@types'
import { format } from 'date-fns'
import i18next from 'i18next'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  countryCodeToLocaleCurrency,
  localeToDateLocale
} from '@/utils/countryCodeHelper'
import { formatCurrencyWithDecimal } from '@/utils/currency'
import { hasBeenDelivered } from '@/utils/orderHelper'

// Contants
import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import DogWithBagSprite from 'assets/images/illustrations/dogs/dog-with-bag--sprite.svg'

import AlertCard from '@/components/elements/atoms/Alert/AlertCard'
import Icon from '@/components/elements/atoms/Icon/Icon'
import Separator from '@/components/elements/atoms/Separator/Separator'
import Text from '@/components/elements/atoms/Text/Text'
import { PaymentMethodSubtitle } from '@/components/elements/molecules/OneTimePurchaseFooter/OneTimePurchaseFooter'
import OrderSummary from '@/components/elements/molecules/OrderSummary/OrderSummary'
import ProductQuantityLarge from '@/components/elements/molecules/ProductQuantityLarge/ProductQuantityLarge'
import ConfirmationModal from '@/components/elements/organisms/ConfirmationModal/ConfirmationModal'
import DeliveryDetails from '@/components/elements/organisms/DeliveryDetails/DeliveryDetails'
import LoadingScreen from '@/components/elements/organisms/LoadingScreen/LoadingScreen'
import { NavigateContext } from '@/components/pages/App'
// eslint-disable-next-line no-restricted-imports
import { DeliveryStatus } from '@/components/types/DeliveryStatus'

import STYLES from './ConfirmationPage.module.sass'

import { CONFIRMATION_PAGE_QUERY } from './queries/confirmationPageQuery'

import type {
  ConfirmationPageQuery,
  ConfirmationPageQueryVariables,
  ConfirmationPageQuery_user_subscription_paymentMethods_CreditCardPaymentMethod as CreditCardPaymentMethod,
  ConfirmationPageQuery_user_nonCoreOrder_orderParts_orderProducts as ProductProps
} from './queries/__generated__/ConfirmationPageQuery'

type AddressValue = {
  address1: string
  address2: string
  city: string
  postcode: string
  recipientName: string
  deliveryNotes: string
  deliveryCarrier: string
}

const ConfirmationPage = (): JSX.Element | null => {
  const [paymentDueDate, setPaymentDueDate] = useState<Date | null>()
  const navigate = useContext(NavigateContext)
  const confirmation = false
  const id = useParams().id ?? ''

  const { windowWidth } = useWindowSize()

  const { loading, data, error } = useQuery<
    ConfirmationPageQuery,
    ConfirmationPageQueryVariables
  >(CONFIRMATION_PAGE_QUERY, {
    variables: {
      orderId: id || ''
    }
  })
  useEffect(() => {
    if (data?.user.nonCoreOrder.invoice.status === 'failed' && !confirmation) {
      window.analytics.track('ODE Purchase Failed Page Viewed')
    }
  }, [confirmation, data?.user.nonCoreOrder.invoice.status])

  if (!id) {
    // TODO: use internal routes when new Extras page is ready
    // history.push(ExtrasPageRoutes.ExtrasList)
    navigate(ACCOUNT_ROUTES.extras, '/dashboard/extras')
    return null
  }

  // TODO: Remove Throw error from the DashboardNavigation component
  // because it doesn't allow to do proper error checking
  if (loading) {
    return (
      <LoadingScreen
        isOpen
        title={{
          text: 'extras.basket.loading_screen.title',
          namespace: 'dashboard'
        }}
        variant="animated"
        sprite={DogWithBagSprite}
      />
    )
  }

  if (error) {
    navigate(ACCOUNT_ROUTES.extras, '/dashboard/extras')
    return null
  }

  if (!data) {
    navigate(ACCOUNT_ROUTES.extras, '/dashboard/extras')
    return null
  }

  const {
    user: {
      shippingCountryCode,
      preferredLanguage,
      subscription,
      nonCoreOrder: {
        address,
        invoice: { grossPrice, discountedPrice, status },
        orderParts
      }
    }
  } = data

  const { locale, currency } = countryCodeToLocaleCurrency(
    shippingCountryCode,
    preferredLanguage
  )
  const dateLocale = localeToDateLocale(
    shippingCountryCode,
    i18next.language as Language
  )
  const dueDate = format(
    Date.parse(orderParts[0].deliveryDate),
    'EEEE, dd MMMM',
    { locale: dateLocale }
  )
  const boxIsDelivered = hasBeenDelivered(
    orderParts[0].deliveryDate,
    orderParts[0].consignment?.status as DeliveryStatus
  )
  const deliveryCopy = boxIsDelivered ? 'delivered' : 'due_on'

  const lastFourDigits = () => {
    const activeCreditCardPaymentMethod = subscription.paymentMethods.find(
      (pm) => pm.__typename === 'CreditCardPaymentMethod' && pm.active
    ) as CreditCardPaymentMethod
    return activeCreditCardPaymentMethod?.lastFourDigits
  }

  return (
    <>
      <ConfirmationModal />
      <div className={STYLES.container}>
        <div className={STYLES.main}>
          <div className={STYLES.wrapper}>
            {confirmation && (
              <div className={STYLES.alertCard}>
                <AlertCard
                  message={{
                    text: 'extras.confirmation.all_done',
                    namespace: 'dashboard',
                    margin: false
                  }}
                  variant="success"
                />
              </div>
            )}
            {status === 'failed' && !confirmation && (
              <div className={STYLES.alertCard}>
                <AlertCard
                  message={{
                    text: 'extras.confirmation.payment_failed_alert',
                    namespace: 'dashboard',
                    margin: false,
                    variables: { url: '/contact' },
                    align: 'left'
                  }}
                  variant="error"
                />
              </div>
            )}
            <Text
              text="extras.confirmation.your_order_details"
              namespace="dashboard"
              variant="display20"
              variables={{ details: `- # ${id}` }}
              colour="brandBlue500"
            />
            <div className={STYLES.content}>
              <div className={STYLES.dueDate}>
                <Icon size={14} asset="vanFilled" />
                {dueDate === format(new Date(), 'dd MMM') ? (
                  <Text
                    variant="textRegular14"
                    colour="brandBlue400"
                    text="extras.confirmation.dueToday"
                    namespace="dashboard"
                  />
                ) : (
                  <Text
                    variant="textRegular14"
                    colour="brandBlue400"
                    text={`extras.confirmation.${deliveryCopy}`}
                    variables={{ dueDate }}
                    namespace="dashboard"
                  />
                )}
              </div>
              <div className={STYLES.productCards}>
                {orderParts[0].orderProducts.map(
                  (orderProduct: ProductProps): JSX.Element | null => {
                    const {
                      name: sizeName,
                      productCollection: {
                        name: productName,
                        thumbnail: { src }
                      }
                    } = orderProduct.productVariant
                    const amountDiscounted =
                      orderProduct.grossPrice - orderProduct.discountedPrice

                    return (
                      <ProductQuantityLarge
                        key={productName}
                        image={src}
                        alt={productName}
                        quantity={orderProduct.quantity}
                        price={formatCurrencyWithDecimal(
                          orderProduct.discountedPrice,
                          { locale: locale, currency: currency }
                        )}
                        grossPrice={formatCurrencyWithDecimal(
                          orderProduct.grossPrice,
                          { locale: locale, currency: currency }
                        )}
                        discount={
                          amountDiscounted > 0
                            ? formatCurrencyWithDecimal(amountDiscounted, {
                                locale,
                                currency
                              })
                            : null
                        }
                        title={{
                          text: productName,
                          translate: false
                        }}
                        size={{
                          text: sizeName
                        }}
                      />
                    )
                  }
                )}
              </div>
              <DeliveryDetails
                address={address as AddressValue}
                deliveryDate={new Date(orderParts[0].deliveryDate)}
                paymentDueDate={paymentDueDate}
                status={orderParts[0].consignment?.status as DeliveryStatus}
                shippingCountryCode={shippingCountryCode}
                setPaymentDueDate={setPaymentDueDate}
                shipmentType="non_core"
              />
            </div>
          </div>
          {windowWidth < BREAKPOINTS.md && <Separator handdrawn />}
          <div
            className={`${STYLES.footer} ${
              ((status === 'failed' && !confirmation) || confirmation) &&
              STYLES.lowerFooter
            }`}
          >
            <OrderSummary
              status={status}
              grossPrice={discountedPrice}
              price={grossPrice}
              deliveryPrice={orderParts[0]?.deliverySurcharge}
              smallOrderFee={orderParts[0]?.smallOrderSurcharge}
              locale={locale}
              currency={currency}
              shippingCountryCode={shippingCountryCode}
              orderSubmited
            />
            {paymentDueDate && status !== 'failed' && status !== 'free' && (
              <PaymentMethodSubtitle
                paymentDueDate={paymentDueDate}
                activePaymentMethodType={
                  subscription.activePaymentMethodType as PaymentMethodable
                }
                lastFourDigits={lastFourDigits()}
                shippingCountryCode={shippingCountryCode}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export { ConfirmationPage, CONFIRMATION_PAGE_QUERY }

export default ConfirmationPage
