// @noflow
import React from 'react'
import { useTranslation } from 'react-i18next'

import DogWithTreats from 'assets/images/illustrations/dogs/dog-with-box-of-treats.svg'

import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'
import Icon from '@/components/elements/atoms/Icon/Icon'
import Text from '@/components/elements/atoms/Text/Text'
import type { Props as TextProps } from '@/components/elements/atoms/Text/Text'
import CloudinaryImage from '@/components/shared/elements/CloudinaryImage/CloudinaryImage'

import STYLES from './ProductQuantityLarge.module.sass'

type Props = {
  image: string
  alt: string
  title: TextProps
  size: TextProps
  grossPrice?: string
  discount?: string | null
  price: string
  quantity: number
  shadow?: boolean
  onProductRemoveDisabled?: boolean
  onChange?: () => void
  onProductRemove?: () => void
}

const ProductQuantityLarge = ({
  image,
  quantity = 0,
  price,
  title,
  alt,
  size,
  discount,
  grossPrice,
  shadow = true,
  onProductRemoveDisabled = false,
  onChange,
  onProductRemove
}: Props): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div className={STYLES.container}>
      <Card padding={8} shadow={shadow}>
        <div className={STYLES.cardBody}>
          <div className={STYLES.summary}>
            {image ? (
              <CloudinaryImage
                alt={alt}
                image={{
                  path: image,
                  width: 90,
                  height: 90,
                  crop: 'fill',
                  dpr: window.devicePixelRatio
                }}
              />
            ) : (
              <img
                alt={t('dashboard:extras.past_orders.alt')}
                src={DogWithTreats}
              />
            )}
            <div className={STYLES.wrapper}>
              <div className={STYLES.content}>
                <Text
                  text={title.text}
                  variables={title.variables}
                  namespace={title.namespace}
                  translate={title.translate}
                  variant="textRegular18"
                  colour="brandBlue500"
                  margin={false}
                  bold
                />
                <div className={STYLES.quantity}>
                  <Text
                    translate={false}
                    text={`${quantity}`}
                    variant="textRegular16"
                    colour="brandBlue500"
                    margin={false}
                  />
                </div>
              </div>
              <div className={STYLES.textContent}>
                {size.text !== '' && (
                  <Text
                    text={`${size.text},`}
                    colour="brandBlue500"
                    margin={false}
                    translate={false}
                  />
                )}
                <Text
                  text={price}
                  namespace="dashboard"
                  colour={discount ? 'brandRed500' : 'brandBlue500'}
                  margin={false}
                  bold={!!discount}
                  translate={false}
                />
                {discount && (
                  <Text
                    translate={false}
                    text={`<accent type="strikeTrough">${grossPrice}</accent>`}
                    colour="brandBlue500"
                    variant="textRegular14"
                    margin={false}
                  />
                )}
              </div>
              <div className={STYLES.footer}>
                <div className={STYLES.changeButton}>
                  {onChange && (
                    <Button
                      typography={{
                        text: 'delivery_details.change',
                        namespace: 'shared'
                      }}
                      variant="linkWhiteBG"
                      disableAnalytics
                      onClick={onChange}
                      icon={{
                        position: 'right',
                        component: <Icon asset="chevron" size={14} width={12} />
                      }}
                    />
                  )}
                  {onProductRemove && (
                    <Button
                      typography={{
                        namespace: 'shared',
                        text: 'delivery_details.remove'
                      }}
                      disableAnalytics
                      variant="linkWhiteBG"
                      disabled={onProductRemoveDisabled}
                      onClick={onProductRemove}
                      icon={{
                        position: 'right',
                        component: <Icon asset="bin" size={14} width={12} />
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}

export { Props }
export default ProductQuantityLarge
