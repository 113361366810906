import { CustomerIssueResolutionAcceptanceReplacementBoxDetailsInput as ReplacementBoxDetails } from '@types'
import React, { useCallback } from 'react'

import DogCelebrating from 'assets/images/illustrations/dogs/dog-celebrating.svg'

import { Button } from '@/components/elements/atoms/Button'
import Text from '@/components/elements/atoms/Text/Text'
import Faq from '@/components/elements/molecules/Faq/Faq'

import STYLES from '../ResolutionConfirmation.module.sass'

import useSelfResolutionTracking from '../../../analytics'
import ResolutionConfirmationBox from './ResolutionConfirmationBox'

type Props = {
  namespace: string
  details: ReplacementBoxDetails
}

const ReplacementBoxConfirmation = ({
  namespace,
  details
}: Props): JSX.Element => {
  const getDeliveryDate = useCallback(() => {
    return new Date(details.deliveryDate)
  }, [details])
  const selfResolutionTracking = useSelfResolutionTracking()

  const goToMyAccount = useCallback(() => {
    selfResolutionTracking.dropOff('Replacement Box Confirmation', 'My Account')
    window.location.href = '../dashboard'
  }, [selfResolutionTracking])

  const onReplacementBoxFAQToggled = useCallback(() => {
    selfResolutionTracking.trackEvent('FAQ clicked', {
      name: 'Replacement Box'
    })
  }, [selfResolutionTracking])

  const onPaymentFAQToggled = useCallback(() => {
    selfResolutionTracking.trackEvent('FAQ clicked', { name: 'Payment' })
  }, [selfResolutionTracking])

  React.useEffect(() => {
    selfResolutionTracking.stepLoaded(
      'Replacement box confirmation',
      'Resolution confirmation'
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className={STYLES.imgContainer}>
        <img alt="" src={DogCelebrating} />
      </div>
      <Text
        namespace={namespace}
        text={'resolutionConfirmation.replacementBox.next_box_text'}
        variant={'textRegular16'}
        colour={'brandBlue500'}
      />
      {
        // eslint-disable-next-line jsx-a11y/label-has-for
        <ResolutionConfirmationBox
          asset={'van'}
          assetSize={20}
          textProps={{
            namespace: namespace,
            text: 'resolutionConfirmation.replacementBox.deliveryDate',
            variables: { date: getDeliveryDate() },
            colour: 'brandBlue500',
            translate: true
          }}
        />
      }
      <Text
        namespace={namespace}
        text={'resolutionConfirmation.replacementBox.text1'}
        variant={'textRegular16'}
        colour={'brandBlue500'}
      />
      <Text
        namespace={namespace}
        text={'resolutionConfirmation.replacementBox.text2'}
        variant={'textRegular16'}
        colour={'brandBlue500'}
      />
      <Text
        namespace={namespace}
        text={'resolutionConfirmation.replacementBox.text3_html'}
        variant={'textRegular16'}
        colour={'brandBlue500'}
      />
      <div className={STYLES.buttonWrapper}>
        <Button
          typography={{
            namespace,
            text: 'resolutionConfirmation.replacementBox.account_button'
          }}
          disableAnalytics
          onClick={goToMyAccount}
        />
      </div>
      <Text
        variant={'display24'}
        namespace={namespace}
        text={'resolutionConfirmation.replacementBox.faq_header'}
        colour={'brandBlue500'}
      />
      <Faq
        question={{
          namespace: namespace,
          text: 'resolutionConfirmation.replacementBox.question1',
          variant: 'textRegular16',
          colour: 'brandBlue500'
        }}
        answer={{
          text: 'resolutionConfirmation.replacementBox.answer1',
          namespace: namespace
        }}
        onClick={onReplacementBoxFAQToggled}
      />
      <Faq
        question={{
          namespace: namespace,
          text: 'resolutionConfirmation.replacementBox.question2',
          variant: 'textRegular16',
          colour: 'brandBlue500'
        }}
        answer={{
          text: 'resolutionConfirmation.replacementBox.answer2',
          namespace: namespace
        }}
        onClick={onPaymentFAQToggled}
      />
    </>
  )
}

export { Props }
export default ReplacementBoxConfirmation
