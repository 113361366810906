// @noflow
import classnames from 'classnames'
import React, { useEffect, useState } from 'react'

import NewBadge from 'assets/images/icons/badges/new-badge.svg'

import Text from '@/components/elements/atoms/Text/Text'
import type { Props as TextProps } from '@/components/elements/atoms/Text/Text'

import STYLES from './NavLink.module.sass'

type Props = {
  variant?: keyof typeof STYLES
  text: TextProps
  href: string
  iconSrc?: string
  newBadge?: boolean
  onClick?: () => void
  hoverColour?: 'brandYellow400' | 'brandYellow500' | 'brandBlue200'
}

const NavLink = ({
  variant,
  text,
  href,
  iconSrc,
  newBadge = false,
  onClick,
  hoverColour = 'brandYellow400'
}: Props): JSX.Element => {
  const [active, setActive] = useState(false)
  useEffect((): void => {
    const currentLocation = window.location.pathname
    if (href === currentLocation) setActive(true)
    if (href === '/dashboard' && currentLocation === '/dashboard/paused')
      setActive(true) // Mark Dashboard link as active, when the url is /dashboard/paused
  }, [href])

  const className = classnames(
    STYLES.container,
    STYLES[hoverColour],
    variant && [STYLES[variant]],
    {
      [STYLES.active]: active,
      [STYLES.newNavItem]: newBadge
    }
  )

  if (onClick) {
    return (
      <button className={className} type="button" onClick={onClick}>
        <Text {...text} text={text.text} element="span" />
      </button>
    )
  }

  return (
    <a
      href={href}
      className={`
        ${STYLES.container}
        ${variant ? STYLES[variant] : ''} ${active ? STYLES.active : ''}
        ${newBadge ? STYLES.newNavItem : ''}
        ${STYLES[hoverColour]}`}
    >
      {iconSrc && <img src={iconSrc} alt="" />}
      <Text
        text={text.text}
        element="span"
        namespace={text.namespace}
        translate={text.translate}
      />
      {newBadge && <img className={STYLES.badge} alt="" src={NewBadge} />}
    </a>
  )
}

export { Props }
export default NavLink
