// @flow

import * as React from 'react'
import ReferralComponent from '../partials/referral_component'

import type {
  AffiliateType,
  ReferralLink,
} from '../partials/referral_component'

type Props = {|
  affiliateType: AffiliateType,
  fullName: string,
  referralLink: ReferralLink,
  setAlertMessage: string => void,
  visible: boolean
|}

const InfluencerReferralTab = (props: Props): React.Node => {
  const {
    affiliateType,
    fullName,
    referralLink,
    setAlertMessage,
    visible
  } = props

  return (
    <div className={`visible-${visible.toString()} tab affiliate-referral`}>
      <div className='section affiliate-referral'>
        <ReferralComponent
          affiliateType={affiliateType}
          setAlertMessage={setAlertMessage}
          fullName={fullName}
          referralLink={referralLink}
          referralSignupCredit={0}
          includeTitle={false}
        />
      </div>
    </div>
  )
}

export default InfluencerReferralTab
