// @noflow
import times from 'lodash/times'
import React from 'react'

import SkeletonButton from '@/components/elements/atoms/SkeletonButton/SkeletonButton'

import STYLES from './AddPaymentMethod.module.sass'

type Props = {
  amountOfSkeletons: number
}

const AddPaymentMethodSkeleton = ({
  amountOfSkeletons
}: Props): JSX.Element => {
  // TODO: Get available payment methods from ShippingCountryCode
  return (
    <>
      {times(amountOfSkeletons).map((paymentMethod) => (
        <div key={paymentMethod} className={STYLES.skeletonButton}>
          <SkeletonButton width={'100%'} height={'4rem'} />
        </div>
      ))}
    </>
  )
}

export default AddPaymentMethodSkeleton
