// @noflow
import * as TYPES from './types'

// Requests
const START_REQUEST: TYPES.START_REQUEST = 'START_REQUEST'
const COMPLETE_REQUEST_WITH_SUCCESS: TYPES.COMPLETE_REQUEST_WITH_SUCCESS =
  'COMPLETE_REQUEST_WITH_SUCCESS'
const COMPLETE_REQUEST_WITH_ERROR: TYPES.COMPLETE_REQUEST_WITH_ERROR =
  'COMPLETE_REQUEST_WITH_ERROR'

// Form data
const APP_DATA_RECEIVED: TYPES.APP_DATA_RECEIVED = 'APP_DATA_RECEIVED'
const GLOBAL_DATA_RECEIVED: TYPES.GLOBAL_DATA_RECEIVED = 'GLOBAL_DATA_RECEIVED'
const UPDATE_FIELD: TYPES.UPDATE_FIELD = 'UPDATE_FIELD'
const SET_FORM_VIEWING_STEP: TYPES.SET_FORM_VIEWING_STEP =
  'SET_FORM_VIEWING_STEP'
const SET_RECAPTCHA_LOADED: TYPES.SET_RECAPTCHA_LOADED = 'SET_RECAPTCHA_LOADED'
const SET_FORM_TYPE: TYPES.SET_FORM_TYPE = 'SET_FORM_TYPE'
const PROBE_REQUIRED_FIELDS: TYPES.PROBE_REQUIRED_FIELDS =
  'PROBE_REQUIRED_FIELDS'

export {
  START_REQUEST,
  COMPLETE_REQUEST_WITH_SUCCESS,
  COMPLETE_REQUEST_WITH_ERROR,
  APP_DATA_RECEIVED,
  GLOBAL_DATA_RECEIVED,
  UPDATE_FIELD,
  SET_FORM_VIEWING_STEP,
  SET_RECAPTCHA_LOADED,
  SET_FORM_TYPE,
  PROBE_REQUIRED_FIELDS
}
