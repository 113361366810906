import { MyDetailsPersonalDetailsQuery_user_address as Address } from '@/components/pages/MyDetailsPage/queries/__generated__/MyDetailsPersonalDetailsQuery'

const chooseCarrierDeliveryNoteLimit = (address?: Address | null): number => {
  if (!address) return 250

  switch (address.deliveryCarrier) {
    case 'Goodspeed':
      return 80
    case 'Poland':
      return 30
    default:
      return 250
  }
}

export { chooseCarrierDeliveryNoteLimit }
