// @noflow
import { useReactiveVar } from '@apollo/client'
import { useStripe } from '@stripe/react-stripe-js'
import { PaymentRequest, PaymentRequestWallet } from '@stripe/stripe-js'
import { format, parseISO } from 'date-fns'
import { useCallback, useEffect, useRef, useState } from 'react'

import {
  countryCodeToDefaultCurrency,
  localeToDateLocale
} from '@/utils/countryCodeHelper'

import type { CheckoutPage } from '../../../queries/__generated__/CheckoutPage'
import { Code } from '@/types'

import { checkoutPageState } from '../../../CheckoutPage'
import { checkoutPricingState } from '../../../hooks/useCheckoutPricing'
import type { PaymentMethod } from '../../../types'
import { bannerMessageState } from '../Banner'
import { buySubscriptionWithPaymentRequest } from '../helpers/buySubscriptionWithPaymentRequest'
import { paymentMethodFromWalletName } from '../helpers/paymentMethodFromWalletName'
import paymentRequestDisplayItems from '../helpers/paymentRequestDisplayItems'

type ShippingCountryCode =
  CheckoutPage['guest']['assumedShippingCountry']['code']

type PaymentRequestInProgress = {
  paymentMethod: PaymentMethod | null
  inProgress: boolean
}

type UseBuySubscriptionWithPaymentRequest = {
  paymentRequest: PaymentRequest | null
  paymentRequestInProgress: PaymentRequestInProgress
  availablePaymentRequestMethod: PaymentMethod | null
  updatePaymentRequestPrices: () => void
}

type Translate = (arg0: string) => string

type Props = {
  shippingCountryCode: ShippingCountryCode
  csrfToken: string
  t: Translate
  shouldSeeApplePay: boolean
  shouldSeeGooglePay: boolean
}

const useBuySubscriptionWithPaymentRequest = ({
  shippingCountryCode,
  csrfToken,
  t,
  shouldSeeApplePay,
  shouldSeeGooglePay
}: Props): UseBuySubscriptionWithPaymentRequest => {
  const copyContext = 'payment_section.payment_request_display_items'
  const stripe = useStripe()
  const { user, sections } = checkoutPageState()
  const { discount } = user
  const { deliveryDetails, paymentDetails } = sections

  const { firstOrderPricing } = useReactiveVar(checkoutPricingState)

  const [paymentRequestInProgress, setPaymentRequestInProgress] =
    useState<PaymentRequestInProgress>({
      paymentMethod: null,
      inProgress: false
    })
  const paymentRequestRef = useRef<PaymentRequest | null>(null)
  const [availablePaymentRequestMethod, setAvailablePaymentRequestMethod] =
    useState<PaymentMethod | null>(null)

  const [disabledWallets] = useState<PaymentRequestWallet[]>(() => {
    const wallets: PaymentRequestWallet[] = []
    if (!shouldSeeApplePay) {
      wallets.push('applePay')
    }
    if (!shouldSeeGooglePay) {
      wallets.push('googlePay')
    }
    return wallets
  })

  const dateLocale = localeToDateLocale(
    shippingCountryCode,
    user.preferredLanguage
  )
  const deliveryDate = format(
    parseISO(deliveryDetails.form.selectedDeliveryDate.value),
    'EEEE, d MMMM',
    { locale: dateLocale }
  )

  const updatePaymentRequestPrices = useCallback(() => {
    if (paymentRequestRef.current) {
      paymentRequestRef.current.update({
        total: {
          label: t(`${copyContext}.total`),
          amount: firstOrderPricing.netTotalPrice,
          pending: false
        },
        displayItems: paymentRequestDisplayItems({
          firstOrderPricing,
          t
        })
      })
    }
  }, [firstOrderPricing, t, copyContext])

  useEffect((): void => {
    if (stripe && !paymentRequestRef.current) {
      const pr = stripe.paymentRequest({
        country:
          shippingCountryCode === Code.NI ? Code.GB : shippingCountryCode,
        currency:
          countryCodeToDefaultCurrency(shippingCountryCode).toLowerCase(),
        requestShipping: false,
        displayItems: paymentRequestDisplayItems({
          firstOrderPricing,
          t
        }),
        total: {
          label: t(`${copyContext}.total`),
          amount: firstOrderPricing.netTotalPrice,
          pending: false
        },
        disableWallets: disabledWallets
      })

      pr.on('paymentmethod', (event): void => {
        const paymentMethod = paymentMethodFromWalletName(
          event.walletName as PaymentRequestWallet
        )

        setPaymentRequestInProgress({ paymentMethod, inProgress: true })

        paymentDetails.form.selectedPaymentMethod.type = paymentMethod

        // Reset the error banner if it's showing
        bannerMessageState({ message: null, type: 'error' })

        buySubscriptionWithPaymentRequest({
          event,
          csrfToken,
          stripe
        })
      })

      pr.on('cancel', () =>
        setPaymentRequestInProgress({ paymentMethod: null, inProgress: false })
      )

      pr.canMakePayment().then((result): void => {
        if (result) {
          paymentRequestRef.current = pr
          if (result.applePay) {
            setAvailablePaymentRequestMethod('applePay')
            paymentRequestRef.current.update({
              shippingOptions: [
                {
                  id: 'standard',
                  label: t(`${copyContext}.shipping_options_label`),
                  detail: t(`${copyContext}.shipping_options_label`),
                  amount: firstOrderPricing.netDeliverySurchargePrice
                }
              ]
            })
          }
          if (result.googlePay) {
            setAvailablePaymentRequestMethod('googlePay')
          }
        }
      })
    }
  }, [
    csrfToken,
    deliveryDate,
    discount,
    paymentDetails.form.selectedPaymentMethod,
    shippingCountryCode,
    stripe,
    t,
    disabledWallets,
    firstOrderPricing
  ])

  useEffect(() => {
    updatePaymentRequestPrices()
  }, [firstOrderPricing, t, copyContext, updatePaymentRequestPrices])

  return {
    paymentRequest: paymentRequestRef.current,
    paymentRequestInProgress,
    availablePaymentRequestMethod,
    updatePaymentRequestPrices
  }
}

export type { UseBuySubscriptionWithPaymentRequest }

export { useBuySubscriptionWithPaymentRequest }
