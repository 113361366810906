// @noflow
import { PaymentMethod } from '@stripe/stripe-js'
import camelCase from 'lodash/camelCase'

import type {
  DirectSalesPaymentQuery_directSalesPendingSubscription_pendingSubscription_pendingSubscriptionFlavours as Flavour,
  DirectSalesPaymentQuery_directSalesPendingSubscription_pendingSubscription_productVariants as ProductVariant
} from '@/components/pages/DirectSalesPayOnOwnDevicePage/queries/__generated__/DirectSalesPaymentQuery'
import { DirectSalesPaymentQuery } from '@/components/pages/DirectSalesPayOnOwnDevicePage/queries/__generated__/DirectSalesPaymentQuery'

import {
  AddressInput,
  MealInput,
  PaymentMethodable,
  ProductVariantInput,
  Provider,
  SubscriptionInput,
  VariantDelivery
} from '@/types'

import { format, formatNameInput, validate } from './inputs'

type ConstructSubscriptionInput = {
  discountCodeId: string
  email: string
  phoneNumber: string
  address: AddressInput
  chargedOnBoxOne: boolean
  subscriptionInput: SubscriptionInput
  singlePuppyNotBroughtHome: boolean
  supportedMarketingMethodPurposeIds: string[]
}

type MealBreakdown = {
  [key: string]: number
}

const constructMealBreakdown = (flavourInputs: Flavour[]): MealBreakdown => {
  const breakdown: MealBreakdown = {}

  flavourInputs.forEach((flavourInput) => {
    breakdown[camelCase(flavourInput.flavour.slug) as keyof MealInput] =
      flavourInput.trialBoxServings
  })

  return breakdown
}

const constructProductVariantInputs = (
  productVariantInputs: ProductVariant[]
): ProductVariantInput[] => {
  return productVariantInputs.map((productVariantInput) => {
    return {
      productVariantId: productVariantInput.productVariant.id,
      quantity: 1,
      deliveryType: VariantDelivery.one_off
    }
  })
}

const validateId = (value: string): string => {
  const idRegex = /^\d+$/
  if (idRegex.test(value)) {
    return value
  }
  return '-1'
}

const formatSubscriptionData = ({
  targetFirstDeliveryDate,
  subscriptionData,
  paymentMethod,
  provider,
  paymentMethodType,
  paymentIntentId,
  supportedMarketingMethodPurposes
}: {
  targetFirstDeliveryDate: Date
  subscriptionData: DirectSalesPaymentQuery
  paymentMethod?: PaymentMethod
  provider: Provider
  paymentMethodType: PaymentMethodable
  paymentIntentId: string
  supportedMarketingMethodPurposes: string[]
}): ConstructSubscriptionInput => {
  const { directSalesPendingSubscription } = subscriptionData

  const { pendingSubscription, address, phoneNumber, discountCode } =
    directSalesPendingSubscription

  const shippingCountryCode = address.shippingCountry.code
  const postcode = format(address.postcode, 'postcode', shippingCountryCode)

  const mobileNumber = format(phoneNumber, 'mobileNumber', shippingCountryCode)

  const { firstName, lastName } = formatNameInput(address.recipientName)

  return {
    chargedOnBoxOne: true,
    discountCodeId: discountCode?.id ? validateId(discountCode?.id) : '',
    email: pendingSubscription.user.email
      ? validate(pendingSubscription.user.email, 'email', shippingCountryCode)
      : '',
    phoneNumber: mobileNumber,
    address: {
      addressLineOne: validate(
        address.address1,
        'addressLine1',
        shippingCountryCode
      ),
      addressLineTwo: address.address2
        ? validate(address.address2, 'addressLine2', shippingCountryCode)
        : '',
      city: validate(address.city, 'city', shippingCountryCode),
      shippingCountryId: parseInt(validateId(address.shippingCountry.id), 10),
      recipientName: validate(
        `${firstName} ${lastName}`,
        'name',
        shippingCountryCode
      ),
      postcode: validate(postcode, 'postcode', shippingCountryCode)
    },
    singlePuppyNotBroughtHome: true, // TODO: this is hardcoded in DS app currently and should be removed when marketing is updated
    supportedMarketingMethodPurposeIds: supportedMarketingMethodPurposes || [],
    subscriptionInput: {
      planId: validateId(pendingSubscription.plan.id),
      targetFirstDeliveryDate: targetFirstDeliveryDate,
      paymentMethodId: paymentMethod?.id
        ? validate(paymentMethod?.id, 'paymentMethodId', shippingCountryCode)
        : '',
      paymentMethodType: paymentMethodType,
      paymentProvider: provider,
      paymentIntentId: paymentIntentId
        ? validate(paymentIntentId, 'paymentIntentId', shippingCountryCode)
        : '',
      mealBreakdown: constructMealBreakdown(
        pendingSubscription.pendingSubscriptionFlavours
      ),
      productVariantInputs: constructProductVariantInputs(
        pendingSubscription.productVariants
      )
    }
  }
}

export type { ConstructSubscriptionInput }

export default formatSubscriptionData
