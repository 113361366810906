import { gql } from '@apollo/client'

const CALENDAR_DATES_DATA = gql`
  query CalendarDatesData(
    $calendarInitDate: ISO8601Date!
    $nDays: Int
    $city: String
    $shipmentType: String!
    $shouldAttemptToOfferNextDayDelivery: Boolean
    $postcode: String
    $userId: ID
  ) {
    calendarDates(
      startDate: $calendarInitDate
      nDays: $nDays
      city: $city
      shipmentType: $shipmentType
      shouldAttemptToOfferNextDayDelivery: $shouldAttemptToOfferNextDayDelivery
      postcode: $postcode
      userId: $userId
    ) {
      date
      deliverable
    }
  }
`

export { CALENDAR_DATES_DATA }
