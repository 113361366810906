// @noflow
import { useMutation, useQuery } from '@apollo/client'
import Cookies from 'js-cookie'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Product, captureException } from '@/utils/sentry'

import { Button } from '@/components/elements/atoms/Button'
import Icon from '@/components/elements/atoms/Icon/Icon'
import Text from '@/components/elements/atoms/Text/Text'
import { TopNavBannerDiscountCodeId } from '@/components/elements/molecules/TopNavBanner/TopNavBanner'
import { ReviewQuery } from '@/components/pages/PlansPage/__generated__/ReviewQuery'
import { GET_REVIEW_QUERY } from '@/components/pages/PlansPage/queries'

import STYLES from './RedeemDiscount.module.sass'

import { CURRENT_DISCOUNT_CODE } from './queries/currentDiscountCode'
import { ADD_DISCOUNT_TO_GUEST } from './queries/redeemDiscount'

import type {
  AddDiscountToGuest,
  AddDiscountToGuestVariables
} from './queries/__generated__/AddDiscountToGuest'
import type {
  CurrentDiscountCode,
  CurrentDiscountCodeVariables,
  CurrentDiscountCode_discountCode
} from './queries/__generated__/CurrentDiscountCode'

type DiscountCode = CurrentDiscountCode_discountCode['code']
type ResponseState = {
  success: boolean
  responseMessage: string
}

type Props = {
  onSuccess?: () => void
}

// These error messages are returned by the add_discount_to_guest
// GraphQL mutation and all currently raised errors can be found here:
//
// ButternutBox/app/graphql/mutations/add_discount_to_guest.rb
const handledErrorMessages = [
  'discount_not_found',
  'discount_code_expired',
  'reactivation_campaign_code_attempted',
  'referral_link_type_invalid'
]

const RedeemDiscount = ({ onSuccess }: Props): JSX.Element => {
  const discountCodeCookiesKey = 'discount_code_id'
  const discountCodeId = Cookies.get(discountCodeCookiesKey)

  const namespace = 'plans_flow'
  const copyContext = 'plan_steps.review.discount_entry'
  const { t } = useTranslation(namespace)

  const [discountCode, setDiscountCode] = React.useState<DiscountCode | null>(
    null
  )
  const [responseState, setResponseState] =
    React.useState<ResponseState | null>(null)

  const { refetch: refetchReviewQuery } = useQuery<ReviewQuery>(
    GET_REVIEW_QUERY()
  )

  // Fetch the current discount code
  const { loading, error } = useQuery<
    CurrentDiscountCode,
    CurrentDiscountCodeVariables
  >(CURRENT_DISCOUNT_CODE, {
    skip: !discountCodeId,
    variables: {
      discountCodeId: discountCodeId || ''
    },
    onCompleted: (data) => {
      setDiscountCode(data.discountCode.code)
      setResponseState({
        success: true,
        responseMessage: t(`${copyContext}.success_message`)
      })
    }
  })

  // Redeem a Discount
  const [addDiscountToGuest, { loading: redeemDiscountLoading }] = useMutation<
    AddDiscountToGuest,
    AddDiscountToGuestVariables
  >(ADD_DISCOUNT_TO_GUEST, {
    variables: {
      discountCode: discountCode || '0'
    },
    onError: (error) => {
      // If Apollo returns an error we haven't accounted for, send it to Sentry
      if (!handledErrorMessages.includes(error.message)) {
        captureException(`Error received in REDEEM_DISCOUNT mutation`, {
          extra: {
            error: error.message
          },
          tags: {
            product: Product.PlansFlow
          }
        })
      }

      setResponseState({
        success: false,
        responseMessage: t(`${copyContext}.error_message`)
      })
    },
    onCompleted: (data) => {
      if (onSuccess) onSuccess()

      // Refetch the information in the Review step to reflect
      // the new applied discount
      refetchReviewQuery()

      // Set the discount code id value for the
      // Top Nav Banner so it can re-query for the new
      // discount information
      TopNavBannerDiscountCodeId(
        data.addDiscountToGuest?.guest.discountCode?.id
      )

      setResponseState({
        success: true,
        responseMessage: t(`${copyContext}.success_message`)
      })
    }
  })

  const discountCodeChangeCallback = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setResponseState(null)
      if (e.currentTarget.value.length > 0) {
        setDiscountCode(e.currentTarget.value)
      } else {
        setDiscountCode(null)
      }
    },
    []
  )

  const addDiscountToGuestCallback = React.useCallback(() => {
    if (!discountCode) {
      captureException(
        `Discount code is null and the discount apply button is enabled`,
        {
          tags: {
            product: Product.PlansFlow
          }
        }
      )
      return
    }

    // Remove all white space from the discount code
    const trimmedDiscountCode = discountCode.trim()

    addDiscountToGuest({
      variables: {
        discountCode: trimmedDiscountCode
      }
    })
  }, [discountCode, addDiscountToGuest])

  if (error) {
    captureException(`Error received in CURRENT_DISCOUNT_CODE query`, {
      extra: {
        error
      },
      tags: {
        product: Product.PlansFlow
      }
    })
  }

  return (
    <React.Fragment>
      <div className={STYLES.inputWrapper}>
        <input
          defaultValue={discountCode || ''}
          onChange={discountCodeChangeCallback}
          placeholder={t(`${copyContext}.placeholder`)}
          disabled={loading}
        />
        <Button
          variant="secondary"
          typography={{
            namespace,
            text: redeemDiscountLoading
              ? `${copyContext}.cta_loading`
              : `${copyContext}.cta`
          }}
          disableAnalytics
          disabled={redeemDiscountLoading || discountCode === null}
          onClick={addDiscountToGuestCallback}
        />
      </div>
      {responseState && (
        <div className={STYLES.responseWrapper}>
          {responseState.success ? (
            <>
              <Icon
                asset="checkmark"
                size={15}
                accentColour="successGreen300"
              />
              <Text
                text={responseState.responseMessage}
                variant="textRegular14"
                colour="supportGreen300"
                margin={false}
                translate={false}
              />
            </>
          ) : (
            <>
              <Icon asset="errorCircle" size={15} accentColour="dangerRed300" />
              <Text
                text={responseState.responseMessage}
                variant="textRegular14"
                colour="supportRed300"
                margin={false}
                translate={false}
              />
            </>
          )}
        </div>
      )}
    </React.Fragment>
  )
}

export default RedeemDiscount
export type { Props }
