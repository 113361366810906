// @noflow
import React from 'react'

import {
  ICON_COLOURS,
  IconColours
} from '@/components/elements/atoms/Icon/Icon'

type Props = {
  size: number
  accentColour: IconColours
  width?: number | string
}

const Close = ({
  size,
  accentColour = 'brandBlue500',
  width
}: Props): JSX.Element => {
  return (
    <svg
      width={width || '100%'}
      height={size}
      viewBox="0 0 14 14"
      fill={ICON_COLOURS[accentColour]}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.636176 0.635919C0.134073 1.13802 0.134073 1.95209 0.636175 2.45419L5.18165 6.99967L0.635848 11.5455C0.133746 12.0476 0.133746 12.8617 0.635849 13.3638C1.13795 13.8659 1.95202 13.8659 2.45412 13.3638L6.99993 8.81795L11.5458 13.3638C12.0479 13.8659 12.862 13.8659 13.3641 13.3638C13.8662 12.8617 13.8662 12.0477 13.3641 11.5456L8.8182 6.99967L13.3638 2.45411C13.8659 1.952 13.8659 1.13793 13.3638 0.635832C12.8617 0.13373 12.0476 0.13373 11.5455 0.635831L6.99993 5.1814L2.45445 0.635919C1.95235 0.133817 1.13828 0.133817 0.636176 0.635919Z"
        fill={accentColour}
      />
    </svg>
  )
}

export default Close
