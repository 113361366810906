import { gql } from '@apollo/client'

const DOG_EATING_HABITS_QUERY = gql`
  query DogEatingHabitsQuery {
    user {
      id
      dogs {
        id
        name
        gender
        eaterType
        snackingHabits
      }
    }
  }
`

export { DOG_EATING_HABITS_QUERY }
