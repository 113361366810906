// @noflow
import React from 'react'

import PaymentMethodSkeleton from './components/PaymentMethodCard/PaymentMethodSkeleton'
import SkeletonButton from '@/components/elements/atoms/SkeletonButton/SkeletonButton'

import STYLES from './PaymentMethods.module.sass'

const PaymentMethodsSkeleton = (): JSX.Element => {
  return (
    <div className={STYLES.skeletonWrapper}>
      <PaymentMethodSkeleton />
      <div className={STYLES.skeletonButton}>
        <SkeletonButton width={'100%'} height={'4rem'} />
      </div>
    </div>
  )
}

export default PaymentMethodsSkeleton
