/* eslint-disable i18next/no-literal-string */
// @noflow
import { useQuery, useReactiveVar } from '@apollo/client'
import React, { FormEvent, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import PillGroup, {
  Props as PillGroupProps
} from '@/components/elements/molecules/PillGroup/PillGroup'
import TextSeparator from '@/components/elements/molecules/TextSeparator/TextSeparator'
import StickyNavigation from '@/components/elements/organisms/StickyNavigation/StickyNavigation'
import {
  routeToNextStep,
  routeToPrevStep
} from '@/components/pages/SignupWizardPage/helpers/wizardRoutes'
import type { Route } from '@/components/pages/SignupWizardPage/types/routes'

import STYLES from './Food.module.sass'

import { FOOD_CATEGORIES_QUERY } from '../../queries/foodCategoriesQuery'

import type { FoodCategories } from '../../queries/__generated__/FoodCategories'

import { wizardPageState } from '../../SignupWizardPage'
import useImplicitFormSubmission from '../../helpers/useImplicitFormSubmission'
import type { Dog } from '../../types/types'

type Props = {
  variant?: keyof typeof STYLES
  namespace: string
}

const Food = ({ variant, namespace = 'wizard_flow' }: Props): JSX.Element => {
  const route = useLocation().pathname as Route
  const wizardState = useReactiveVar(wizardPageState)
  const { dogs } = wizardState

  const copyContext = 'food_step'

  const formIsValid = dogs.every(
    (dog: Dog) => dog.foodCategoryIds && dog.foodCategoryIds.length > 0
  )

  const { data } = useQuery<FoodCategories>(FOOD_CATEGORIES_QUERY)

  const navigate = useNavigate()

  const onEnterPress = useCallback(() => {
    navigate(routeToNextStep({ route }))
  }, [navigate, route])

  useImplicitFormSubmission({ formIsValid, onEnterPress })

  const handleFoodChange = useCallback(
    (e: FormEvent<HTMLDivElement>, index: number): void => {
      const food = e.target as HTMLTextAreaElement
      if (dogs[index].foodCategoryIds.includes(food.value)) {
        const selectedCurrentlyEating = dogs[index].foodCategoryIds.filter(
          (foodType) => foodType !== food.value
        )
        dogs[index].foodCategoryIds = selectedCurrentlyEating.slice(0)
      } else {
        dogs[index].foodCategoryIds.push(food.value)
      }
      wizardPageState({
        ...wizardState,
        dogs
      })
    },
    [dogs, wizardState]
  )

  const pillOptions = (
    dog: Dog,
    index: number
  ): PillGroupProps['pillOptions'] => {
    if (data) {
      return data.foodCategories.map((foodCategory) => ({
        id: `${dog.name}-${index.toString()}-${foodCategory.id}`,
        dataTestId: `food-category-pill-${foodCategory.id}`,
        name: `${dog.name}-${index.toString()}-food`,
        text: foodCategory.name,
        value: foodCategory.id,
        translate: false,
        defaultChecked:
          dog.foodCategoryIds && dog.foodCategoryIds.includes(foodCategory.id),
        namespace,
        variant: 'checkbox'
      }))
    } else {
      return []
    }
  }

  return (
    <>
      <div className={variant ? STYLES[variant] : ''}>
        <div id={'whatCurrentFoodStep'} className={STYLES.question}>
          {dogs.map((dog: Dog, index: number) => (
            <div key={index.toString()}>
              <div
                className={STYLES.subQuestion}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={(e) => handleFoodChange(e, index)}
              >
                <TextSeparator
                  text={`${copyContext}.text_separator`}
                  namespace={namespace}
                  variables={{ dogName: dog.name }}
                />
                <PillGroup pillOptions={pillOptions(dog, index)} />
              </div>
            </div>
          ))}
        </div>
      </div>
      <StickyNavigation
        variant="twoButtons"
        buttonOne={{
          url: routeToPrevStep({ route }),
          text: 'wizard_flow:sticky_navigation.back',
          variant: 'secondary',
          iconColour: 'brandRed400'
        }}
        buttonTwo={{
          dataTestId: 'next-button',
          url: routeToNextStep({ route }),
          text: 'wizard_flow:sticky_navigation.continue',
          variant: 'primary',
          iconColour: 'brandWhite'
        }}
        disabled={!formIsValid}
      />
    </>
  )
}

export { Props }
export default Food
