// @noflow
import lazyLoad from '@/modules/lazyLoad'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import 'scroll-behavior-polyfill'
import Swiper from 'swiper'

import Arrow from 'assets/images/icons/arrows/arrow-blue.svg'

import Image from '@/components/elements/atoms/Image/Image'

import SwiperSlider from '../elements/molecules/SwiperSlider/SwiperSlider'

type CustomerStory = {
  path: string
  alt: string
  header: string
  text: Array<string>
  fun_fact?: string
  subscript?: string
  top_left_tape_text?: string
  top_right_tape_text?: string
  bottom_tape_text?: string
}

type Props = {
  customerStories: Array<CustomerStory>
  namespace: string
}

const CustomerStoriesCarousel = ({
  customerStories,
  namespace
}: Props): React.ReactElement => {
  const { t } = useTranslation('shared')
  const { t: customT } = useTranslation(namespace)
  const carouselRef: React.RefObject<HTMLDivElement> = React.useRef(null)
  const [cardExpanded, toggleCardExpansion] = React.useState(false)
  const [swiper, setSwiper] = React.useState<null | Swiper>(null)

  const baseClass = 'ugc-stories__carousel'
  const copyContext = 'ugc_reviews_carousel'

  const toggleCard = React.useCallback((): void => {
    if (carouselRef && carouselRef.current && cardExpanded) {
      const currentElement: HTMLElement = carouselRef.current
      const yOffset = -80
      const y =
        currentElement.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset
      window.scrollTo({ top: y, behavior: 'smooth' })
    }

    toggleCardExpansion(!cardExpanded)

    if (swiper) {
      setTimeout(() => {
        swiper.updateAutoHeight(100)
      }, 100)
    }
  }, [cardExpanded, swiper])

  const setSwiperCallback = useCallback(
    (swiper: Swiper): void => setSwiper(swiper),
    []
  )

  React.useEffect((): void => {
    lazyLoad.update()
  }, [carouselRef])

  return (
    <div ref={carouselRef}>
      <SwiperSlider
        bullets={false}
        arrows
        arrowsInside
        autoHeight
        slider={setSwiperCallback}
        loop
        centeredSlides
      >
        {customerStories.map(
          (story): React.ReactElement => (
            <div
              key={story.path}
              className={`${baseClass}__card ${cardExpanded ? 'expanded' : ''}`}
            >
              <div className="image-container">
                <Image
                  alt={customT(story.alt)}
                  slug={story.path}
                  className="lazy image-container__image"
                  lazyLoad
                  image={{
                    width: 260,
                    height: 260,
                    resizeMode: 'resize_to_fill'
                  }}
                />
                {story.top_left_tape_text && (
                  <span className="tape-text tape-text--top-left">
                    {customT(story.top_left_tape_text)}
                  </span>
                )}
                {story.top_right_tape_text && (
                  <span className="tape-text tape-text--top-right">
                    {customT(story.top_right_tape_text)}
                  </span>
                )}
                {story.bottom_tape_text && (
                  <span className="tape-text tape-text--bottom">
                    {customT(story.bottom_tape_text)}
                  </span>
                )}
              </div>
              <h3>{customT(story.header)}</h3>
              <div className={`${baseClass}__card__content`}>
                {story.text.map(
                  (p: string, index: number): React.ReactElement => {
                    return (
                      <React.Fragment
                        key={`story-text-${index}`} // eslint-disable-line react/no-array-index-key
                      >
                        <p>{customT(p)}</p>
                        <br />
                      </React.Fragment>
                    )
                  }
                )}
                {story.fun_fact && (
                  <p>
                    <span className="fun-fact">
                      {t(`${copyContext}.fun_fact`)}
                    </span>
                    {customT(story.fun_fact)}
                  </p>
                )}
                {story.subscript && (
                  <React.Fragment>
                    <br />
                    <small>{customT(story.subscript)}</small>
                  </React.Fragment>
                )}
              </div>
              <label
                htmlFor="card-expansion-toggle"
                aria-owns="card-expansion-toggle-content"
                className="toggle-text"
              >
                <input
                  type="checkbox"
                  id="card-expansion-toggle"
                  role="button"
                  aria-label={t(`${copyContext}.read_more_aria_label`)}
                  onChange={toggleCard}
                  className="toggle-checkbox"
                />
                <span>
                  {cardExpanded
                    ? t(`${copyContext}.read_less_trigger_label`)
                    : t(`${copyContext}.read_more_trigger_label`)}
                  <img
                    src={Arrow}
                    alt=""
                    className={`toggle-arrow ${
                      cardExpanded ? 'toggle-arrow--expanded' : ''
                    }`}
                  />
                </span>
              </label>
            </div>
          )
        )}
      </SwiperSlider>
    </div>
  )
}

export type { CustomerStory }
export default CustomerStoriesCarousel
