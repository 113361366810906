// @flow

import * as React from 'react'
import { format } from 'date-fns'
import type { ComponentProps } from './../../containers/user_details/CustomerHistoryContainer'
import type { CustomerHistoryEntry } from './../../message_types'

// eslint-disable-next-line react/require-optimization
class CustomerHistoryComponent extends React.Component<ComponentProps> {
  // eslint-disable-next-line react/no-arrow-function-lifecycle
  componentDidMount = (): void => {
    const { userId, dispatchGetCustomerHistoryDetails } = this.props

    dispatchGetCustomerHistoryDetails(userId)
  }

  generateRow = (history: CustomerHistoryEntry, i: number): React.Node => {
    return (
      <div className='card__entry' key={i}>
        <div className='card__row'>
          {
            history.actor_email &&
            <p>
              { history.actor_email }
            </p>
          }
          {
            history.action &&
            <p>
              { history.action }
            </p>
          }
          {
            history.attribute &&
            <p>
              { history.attribute }
            </p>
          }
          {
            history.from && history.to &&
            <p>
              { `from ${history.from} to ${history.to}` }
            </p>
          }
        </div>
        <div className='card__row'>
          <div>
            {
              history.timestamp &&
              <span>
                { format(new Date(history.timestamp), 'EEEE, MMM do yyy - HH:mm') }
              </span>
            }
            {
              history.browser &&
              <span>
                { history.browser }
              </span>
            }
            {
              history.operating_system &&
              <span>
                { history.operating_system }
              </span>
            }
            {
              history.device &&
              <span>
                { history.device }
              </span>
            }
          </div>
        </div>
      </div>
    )
  }

  renderTransition = (): string => this.props.isFetching ? 'loading' : 'loaded'

  renderLoadingIcon = (): ?React.Element<'div'> => {
    if (this.props.isFetching) {
      return (
        <div className="loading-ring"><span></span><span></span><span></span><span></span></div>
      )
    }
  }

  render (): React.Node {
    const { customerHistory } = this.props
    const { gocardless_link, stripe_link, amplitude_link, entries, sentry_link, iterable_link, revolut_link } = customerHistory
    return (
      <div className={`customer-history card__container card__${this.renderTransition()}`}>
        <div className='card__header__container'>
          <h3 className='card__header'>
            { 'Customer history' }
          </h3>
          <div className='card__button-container'>
            {
              gocardless_link &&
              <a
                className='card__button button--no-shadow'
                href={gocardless_link}
                rel="noopener noreferrer"
                target='_blank'
              >
                { 'GoCardless >' }
              </a>
            }
            {
              revolut_link &&
              <a
                className='card__button button--no-shadow'
                href={revolut_link}
                rel="noopener noreferrer"
                target='_blank'
              >
                { 'Revolut >' }
              </a>
            }
            {
              stripe_link &&
              <a
                className='card__button button--no-shadow'
                href={stripe_link}
                rel="noopener noreferrer"
                target='_blank'
              >
                { 'Stripe >' }
              </a>
            }
            {
              iterable_link &&
              <a
                className='card__button button--no-shadow'
                href={iterable_link}
                rel="noopener noreferrer"
                target='_blank'
              >
                { 'Iterable >' }
              </a>
            }
            <a
              className='card__button button--no-shadow'
              href={amplitude_link}
              rel="noopener noreferrer"
              target='_blank'
            >
              { 'Amplitude >' }
            </a>
            <a
              className='card__button button--no-shadow'
              href={sentry_link}
              rel="noopener noreferrer"
              target='_blank'
            >
              { 'Sentry >' }
            </a>
          </div>
        </div>
        <div className='card__body'>
          { this.renderLoadingIcon() }
          {
            entries.sort((a: CustomerHistoryEntry, b: CustomerHistoryEntry): number => {
              return Date.parse(b.created_at) - Date.parse(a.created_at)
            }).map(this.generateRow)
          }
        </div>
      </div>
    )
  }
}

export default CustomerHistoryComponent
