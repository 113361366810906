// @noflow
import type { Error } from '@/redux/graphql_queries/errorTypes'

import type { CSRFToken, Token } from '@/shared_types/tokens'
import { ensureNever } from '@/typescript/utils'

import type { Form, GenericForm, Input } from '../reducers/formDataReducer'

type DecodedResponse = {
  message?: string
}

type Success = {
  type: 'Success'
}

type RequestResult = Success | Error

const { fetch } = global

type SubmitContactForm = {
  token: Token | null
  csrfToken: CSRFToken
  queryType: Input
  form: Form
}

const submitContactForm = ({
  token,
  csrfToken,
  queryType,
  form
}: SubmitContactForm): Promise<RequestResult> => {
  const endpoint = '/contact'
  const method = 'POST'
  const credentials = 'same-origin'
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  }

  let body
  const { type } = form
  switch (type) {
    case 'generic-form': {
      const { recaptchaString, name, email, message, dogNames, type } =
        form as GenericForm

      body = JSON.stringify({
        token,
        // See developers.google.com/recaptcha/docs/verify
        'g-recaptcha-response': recaptchaString.value,
        contact: {
          name: name.value,
          email: email.value,
          message: message.value,
          query_type: queryType.value,
          dogs_names: dogNames.value,
          form_type: type
        }
      })
      break
    }
    default: {
      ensureNever(type)
      throw new Error(`${type} is not a valid form type.`)
    }
  }

  return fetch(endpoint, { headers, method, credentials, body }).then(
    (res: Response): Promise<RequestResult> => {
      if (!res.ok) {
        return res
          .json()
          .then(({ message }: DecodedResponse): RequestResult => {
            if (message) {
              return {
                type: 'ServerError',
                status: res.status,
                message
              }
            } else {
              return {
                type: 'UnknownError',
                error: 'UnknownError'
              }
            }
          })
      }
      return res.json().then(({ message }: DecodedResponse): RequestResult => {
        if (message) {
          return {
            type: 'ServerError',
            status: res.status,
            message
          }
        } else {
          return {
            type: 'Success'
          }
        }
      })
    }
  )
}

export type { RequestResult, DecodedResponse }

export default submitContactForm
