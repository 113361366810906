import React from 'react'

import STYLES from './Spinner.module.sass'

type SupportedColours = 'brandYellow500' | 'brandBlue500'

type Props = {
  variant?: SupportedColours
}

const Spinner = ({ variant = 'brandBlue500' }: Props): JSX.Element => {
  return <div className={`${STYLES.spinner} ${STYLES[variant]}`} />
}

export default Spinner
