// Tracking
import {
  CustomerIssueEntryFieldData,
  CustomerIssueReportEntryInputInput as ReportIssueInput
} from '@types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Button } from '@/components/elements/atoms/Button'

import STYLES from '../ReportIssue.module.sass'

import {
  CustomerIssueCategorySubmission_response_fields_CustomerIssueEntryFieldAssociation_allowedAssociationValues_CustomerIssueCategory as CustomerIssueCategory,
  CustomerIssueCategorySubmission_response_fields_CustomerIssueEntryFieldAssociation as CustomerIssueEntryFieldAssociation,
  CustomerIssueCategorySubmission_response_fields as EntryField,
  CustomerIssueCategorySubmission_response_reasons as Reason
} from '../../../mutations/__generated__/CustomerIssueCategorySubmission'
import { CustomerIssueReportEntriesSubmissionVariables } from '../../../mutations/__generated__/CustomerIssueReportEntriesSubmission'
import { SelfResolutionCategories_user_subscription_box_potentialMissingItems as PotentialMissingItems } from '../../../queries/__generated__/SelfResolutionCategories'

import useSelfResolutionTracking from '../../../analytics'
import CheckboxField from './CheckboxField'
import DropDownField from './DropdownField'
import ReasonField from './ReasonField'
import TextAreaField from './TextAreaField'

type IssueFormProps = {
  entryFields: Array<EntryField>
  userId: string
  reportId: string
  namespace: string
  reasons: Array<Reason> | null
  reportIssue: (
    variables: CustomerIssueReportEntriesSubmissionVariables,
    affectedItems?: Array<PotentialMissingItems>
  ) => void
}

const IssueForm = ({
  entryFields,
  userId,
  reportId,
  namespace,
  reasons,
  reportIssue
}: IssueFormProps): JSX.Element => {
  const [reason, setReason] = useState<string>('')
  const [values, setValues] = useState<Array<ReportIssueInput>>([])
  const selfResolutionTracking = useSelfResolutionTracking()

  useEffect(() => {
    selfResolutionTracking.stepLoaded('Report Issue', 'Report Issue')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const initialValues: Array<ReportIssueInput> = []
    for (const entryField of entryFields) {
      switch (entryField.dataType) {
        case 'association':
          initialValues.push({ fieldId: entryField.id, associationValue: '' })
          break
        case 'string':
          initialValues.push({ fieldId: entryField.id, stringValue: '' })
          break
        case 'boolean':
          initialValues.push({ fieldId: entryField.id, booleanValue: false })
          break
        case 'integer':
          initialValues.push({ fieldId: entryField.id, integerValue: -1 })
          break
      }
    }
    setValues(initialValues)
  }, [entryFields, setValues])

  const setValue = useCallback(
    (
      key: string,
      value: string | boolean | number,
      dataType: keyof typeof CustomerIssueEntryFieldData
    ) => {
      const newValues = [...values]
      const valueToChange = newValues.find((x) => x.fieldId === key)
      if (valueToChange) {
        switch (dataType) {
          case 'association':
            valueToChange.associationValue = value as string
            break
          case 'string':
            valueToChange.stringValue = value as string
            break
          case 'boolean':
            valueToChange.booleanValue = value as boolean
            break
          case 'integer':
            valueToChange.integerValue = value as number
            break
        }
        setValues(newValues)
      }
    },
    [values, setValues]
  )

  const notAllFieldsAnswered = useMemo(() => {
    return !values.every((field) => {
      return (
        (field.associationValue && field.associationValue.length > 0) ||
        (field.stringValue && field.stringValue.length > 0) ||
        (field.integerValue && field.integerValue > -1) ||
        field.booleanValue ||
        (reasons && reasons.length > 0 && reason !== '')
      )
    })
  }, [values, reason, reasons])

  const getAssociationValue = useCallback(
    (
      entryFieldAssociation: CustomerIssueEntryFieldAssociation,
      associationValue: string
    ): string => {
      const categories =
        entryFieldAssociation.allowedAssociationValues as Array<CustomerIssueCategory>
      const value = categories.find((x) => x.id === associationValue)?.name
      return value || 'n/a'
    },
    []
  )

  const getReadableSubmittedValues = useCallback(() => {
    return entryFields.map(
      (
        entryField
      ): { name: string; value: string | boolean | number } | null => {
        const field = values.find(
          (x) => x.fieldId === entryField.id
        ) as ReportIssueInput

        switch (entryField.dataType) {
          case 'association':
            return {
              name: entryField.name,
              value: getAssociationValue(
                entryField as CustomerIssueEntryFieldAssociation,
                field?.associationValue as string
              )
            }
          case 'string':
            return {
              name: entryField.name,
              value: field.stringValue as string
            }
          case 'boolean':
            return {
              name: entryField.name,
              value: field.booleanValue as boolean
            }
          case 'integer':
            return {
              name: entryField.name,
              value: field.integerValue as number
            }
          default:
            return null
        }
      }
    )
  }, [entryFields, values, getAssociationValue])

  const submit = useCallback(() => {
    const variables: CustomerIssueReportEntriesSubmissionVariables = {
      userId: userId,
      reportId: reportId,
      entryFieldInputs: values,
      reasonId: reason
    }
    const trackingValues = getReadableSubmittedValues()
    selfResolutionTracking.trackEvent('submit issue', {
      values: trackingValues
    })
    reportIssue(variables)
  }, [
    values,
    selfResolutionTracking,
    reportIssue,
    reportId,
    userId,
    reason,
    getReadableSubmittedValues
  ])

  const renderEntryFields = useCallback(() => {
    return entryFields.map(
      (
        entryField: EntryField | CustomerIssueEntryFieldAssociation
      ): JSX.Element | null => {
        const field = values.find(
          (x) => x.fieldId === entryField.id
        ) as ReportIssueInput
        const entryFieldAssociation =
          entryField as CustomerIssueEntryFieldAssociation
        const categories =
          entryFieldAssociation.allowedAssociationValues as Array<CustomerIssueCategory>
        if (!field) {
          return null
        }
        switch (entryField.dataType) {
          case 'association':
            return (
              <DropDownField
                key={entryField.id}
                id={entryField.id}
                placeholder={entryField.name}
                value={`${field?.associationValue}`}
                options={categories}
                setValue={setValue}
              />
            )
          case 'string':
            return (
              <TextAreaField
                key={entryField.id}
                id={entryField.id}
                placeholder={entryField.name}
                value={field.stringValue as string}
                setValue={setValue}
              />
            )
          case 'boolean':
            return (
              <CheckboxField
                key={entryField.id}
                id={entryField.id}
                label={entryField.name}
                value={field.booleanValue as boolean}
                setValue={setValue}
              />
            )
          case 'integer':
            return null // Integer Field
          default:
            return null
        }
      }
    )
  }, [entryFields, values, setValue])

  return (
    <div className={STYLES.container}>
      <ReasonField
        namespace={namespace}
        reason={reason}
        reasons={reasons}
        setReason={setReason}
      />
      {renderEntryFields()}
      <Button
        typography={{
          namespace,
          text: 'reportIssue.submit'
        }}
        disableAnalytics
        onClick={submit}
        disabled={notAllFieldsAnswered}
      />
    </div>
  )
}

export default IssueForm
