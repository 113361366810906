// @noflow
import { isNil } from 'lodash'
import isUndefined from 'lodash/isUndefined'
import React from 'react'

import type { Locale } from '@/utils/countryCodeHelper'
import { Currency, formatCurrencyWithDecimal } from '@/utils/currency'

import BoostLogo, {
  BoostLogoSizes
} from '@/components/elements/atoms/BoostLogo/BoostLogo'
import Text from '@/components/elements/atoms/Text/Text'
import { ProductCollectionQuery_productCollection_productVariants as ProductVariant } from '@/components/pages/ExtrasPage/screens/ProductCollection/queries/__generated__/ProductCollectionQuery'

import STYLES from './ProductPrice.module.sass'

import { MembershipTypes, VariantDelivery } from '@/types'

type Props = {
  grossPrice: number
  discountedPrice?: number
  boostedPrice?: number
  boostedSubscription?: boolean
  boostedType?: null | MembershipTypes
  locale: Locale
  currency: Currency
}

type ProductCollection = {
  productVariants: {
    id: ProductVariant['id']
    grossPrice: ProductVariant['grossPrice']
    productVariantDeliveryTypes: ProductVariant['productVariantDeliveryTypes']
  }[]
}

/**
 * Simplify all the pricing values from a product collection query into gross, discounted and boosted prices
 * @param id - product variant ID
 * @param deliveryType - the product delivery type
 * @param productCollection - collection of product variants
 */
const getProductPricesFromCollection = (
  id: string,
  deliveryType: VariantDelivery,
  productCollection: ProductCollection
): Pick<Props, 'grossPrice' | 'boostedPrice' | 'discountedPrice'> => {
  const variants = productCollection.productVariants.filter(
    ({ id: productVariantId }) => productVariantId === id
  )

  const variant = variants[0]
  const deliveryVariant = variant.productVariantDeliveryTypes.find(
    (productVariantDeliveryTypes) =>
      productVariantDeliveryTypes.deliveryType === deliveryType
  )

  const grossPrice = deliveryVariant
    ? deliveryVariant.adjustedGrossPrice
    : variant.grossPrice

  const discountedPrice = deliveryVariant ? deliveryVariant.netPrice : undefined

  return {
    grossPrice,
    discountedPrice:
      !isNil(discountedPrice) && discountedPrice < grossPrice
        ? discountedPrice
        : undefined,
    boostedPrice: deliveryVariant ? deliveryVariant.boostedNetPrice : undefined
  }
}

/**
 * Format a product
 * @param grossPrice - The total product price without any discounts
 * @param discountedPrice - The total product price with discounts applied (not including boost)
 * @param boostedPrice - The total product price with the boost discount applied
 * @param boosted - should the boosted discount be shown instead of the standard discount?
 * @param locale
 * @param currency
 * @constructor
 */
const ProductPrice = ({
  grossPrice,
  discountedPrice,
  boostedPrice,
  locale,
  currency,
  boostedSubscription,
  boostedType
}: Props): JSX.Element => {
  const prettyPrice = (price: number): string =>
    formatCurrencyWithDecimal(price, {
      locale,
      currency
    })

  const isBoosted = boostedSubscription && !isUndefined(boostedPrice)

  /**
   * Check if a product is discounted or has price adjustments
   */
  const isDiscounted =
    isBoosted || (!isUndefined(discountedPrice) && discountedPrice < grossPrice)

  /**
   * Formatted gross price
   */
  const formattedGrossPrice = prettyPrice(grossPrice)

  return (
    <>
      {isBoosted && boostedType === MembershipTypes.boost && (
        <div>
          <BoostLogo size={BoostLogoSizes.medium} />
        </div>
      )}
      <div className={STYLES.prices}>
        {isDiscounted && (
          <Text
            element="p"
            translate={false}
            text={
              isBoosted
                ? prettyPrice(boostedPrice)
                : discountedPrice
                ? prettyPrice(discountedPrice)
                : ''
            }
            variant={
              isBoosted && boostedType === MembershipTypes.boost
                ? 'display24'
                : 'display18'
            }
            colour={
              isBoosted && boostedType === MembershipTypes.boost
                ? 'boostLight'
                : 'brandRed500'
            }
            margin={false}
            shouldScale={false}
          />
        )}
        <Text
          element="p"
          translate={false}
          text={
            isDiscounted
              ? `<accent type='strikeTrough'>${formattedGrossPrice}</accent>`
              : formattedGrossPrice
          }
          variant={isDiscounted ? 'textRegular18' : 'display18'}
          colour={'brandBlue500'}
          margin={false}
          shouldScale={false}
        />
      </div>
    </>
  )
}

export { Props, getProductPricesFromCollection }
export default ProductPrice
