import { gql } from '@apollo/client'

const FEATURE_FLAG_FRAGMENT = gql`
  fragment featureFlagFragment on Query {
    shouldSeeApplePay: featureFlag(
      flag: "system-should-see-apple-pay"
      fallback: "false"
    )
    shouldSeeGooglePay: featureFlag(
      flag: "system-should-see-google-pay"
      fallback: "false"
    )
    shouldSeeFetchifyOnCheckout: featureFlag(
      flag: "system_see_fetchify_on_checkout"
      fallback: "false"
    )
    shouldSeeAltCountdown: featureFlag(
      flag: "should_see_alt_countdown"
      fallback: "false"
    )
    shouldSeeTabbedStripeCheckout: featureFlag(
      flag: "should_see_tabbed_stripe_checkout"
      fallback: "not_in_cohort"
    )
    shouldSeeUpdatedCheckoutDesign: featureFlag(
      flag: "should_see_updated_checkout_design"
      fallback: "not_in_cohort"
    )
    shouldSeeExpressPayOnCheckout: featureFlag(
      flag: "should_see_express_pay_on_checkout"
      fallback: "not_in_cohort"
    )
    shouldSeePayPal: featureFlag(
      flag: "system-should-see-paypal"
      fallback: "control"
    )
  }
`
export { FEATURE_FLAG_FRAGMENT }
