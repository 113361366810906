// @noflow
import { useReactiveVar } from '@apollo/client'
import i18next from 'i18next'
import React, { ChangeEvent, FormEvent, Fragment, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { pronounContext } from '@/utils/StringHelper'
import { Product, captureException } from '@/utils/sentry'

import Text from '@/components/elements/atoms/Text/Text'
import ExpertCard from '@/components/elements/molecules/ExpertCard/ExpertCard'
import PillGroup from '@/components/elements/molecules/PillGroup/PillGroup'
import TextSeparator from '@/components/elements/molecules/TextSeparator/TextSeparator'
import StickyNavigation from '@/components/elements/organisms/StickyNavigation/StickyNavigation'
import {
  routeToNextStep,
  routeToPrevStep
} from '@/components/pages/SignupWizardPage/helpers/wizardRoutes'
import type { Route } from '@/components/pages/SignupWizardPage/types/routes'

import STYLES from './Age.module.sass'

import { Gender } from '@/types'

import { wizardPageState } from '../../SignupWizardPage'
import useImplicitFormSubmission from '../../helpers/useImplicitFormSubmission'
import { Dog, WizardAgeStage } from '../../types/types'

type Props = {
  variant?: keyof typeof STYLES
  namespace: string
}

type PuppyBroughtHomeProps = {
  namespace: string
  dog: Dog
  index: number
  onChange: (e: ChangeEvent<HTMLInputElement>, index: number) => void
}

type RescueProps = {
  namespace: string
  dog: Dog
  index: number
  onChange: (e: FormEvent<HTMLInputElement>, index: number) => void
}

type DetailsProps = {
  namespace: string
  dog: Dog
  index: number
  onChange: (e: FormEvent<HTMLInputElement>, index: number) => void
}

const copyContext = 'age_step'

const Speechbubble = ({ namespace }: { namespace: string }): JSX.Element => (
  <div className={STYLES.speechBubble}>
    <div className={STYLES.quote}>
      <div>
        <Text
          namespace={namespace}
          variant="textRegular16"
          colour="brandBlue500"
          text={`${copyContext}.speech_bubble`}
        />
      </div>
    </div>
    <ExpertCard
      namespace={'expert'}
      expert={{
        name: 'Dr Ciara Clarke',
        description: 'ciara_clarke.profession',
        imgSrc: 'Web/photos/people/ciara-clarke.jpg',
        imgAlt: 'ciara_clarke.image_alt'
      }}
    />
  </div>
)

const DogIsRescue = ({
  namespace,
  dog,
  index,
  onChange
}: RescueProps): JSX.Element => {
  const { name } = dog
  return (
    <div className={STYLES.dogIsRescueWrapper}>
      <input
        type={'checkbox'}
        id={`dogIsRescueInput-${index}`}
        data-testid={'dog-is-rescue'}
        role={'button'}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={(e) => onChange(e, index)}
        defaultChecked={dog.isRescue === null ? undefined : dog.isRescue}
        tabIndex={0}
      />
      <label
        htmlFor={`dogIsRescueInput-${index}`}
        className={STYLES.checkboxLabel}
      >
        <Text
          namespace={namespace}
          text={`${copyContext}.is_dog_rescue`}
          variables={{ dogName: name }}
          variant="textRegular16"
          colour="brandBlue500"
        />
      </label>
    </div>
  )
}

const PuppyBroughtHome = ({
  namespace,
  dog,
  index,
  onChange
}: PuppyBroughtHomeProps): JSX.Element => {
  const { gender } = dog
  return (
    <div className={STYLES.puppyBroughtHomeWrapper}>
      <input
        type={'checkbox'}
        id={`puppyBroughtHomeInput-${index}`}
        role={'button'}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={(e) => onChange(e, index)}
        defaultChecked={dog.broughtHome === false}
      />
      <label
        htmlFor={`puppyBroughtHomeInput-${index}`}
        className={STYLES.checkboxLabel}
      >
        <Text
          namespace={namespace}
          text={`${copyContext}.brought_home`}
          variables={{
            context: pronounContext([gender as Gender], i18next.language)
          }}
          variant="textRegular16"
          colour="brandBlue500"
        />
      </label>
    </div>
  )
}

const PuppyDetails = ({
  namespace,
  dog,
  index,
  onChange
}: DetailsProps): JSX.Element => {
  const { age } = dog

  return (
    <div className={STYLES.ageInputs}>
      <input
        type={'number'}
        className={'ageInput'}
        name={'months'}
        id={'months'}
        placeholder={'0'}
        value={age && age.months ? age.months : ''}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={(e) => onChange(e, index)}
      />
      <label htmlFor="months">
        <Text
          namespace={namespace}
          text={`${copyContext}.month`}
          variables={{ count: age && age.months ? age.months : 0 }}
          variant="textRegular16"
          colour="brandBlue500"
        />
      </label>
      <input
        type={'number'}
        className={'ageInput'}
        id={'weeks'}
        name={'weeks'}
        placeholder={'0'}
        value={age && age.weeks ? age.weeks : ''}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={(e) => onChange(e, index)}
      />
      <label htmlFor="weeks">
        <Text
          namespace={namespace}
          text={`${copyContext}.week`}
          variables={{ count: age && age.weeks ? age.weeks : 0 }}
          variant="textRegular16"
          colour="brandBlue500"
        />
      </label>
    </div>
  )
}

const AgeDetails = ({
  namespace,
  dog,
  onChange,
  index
}: DetailsProps): JSX.Element => {
  const { age } = dog

  return (
    <div className={STYLES.ageInputs}>
      <input
        type={'number'}
        className={'ageInput'}
        id={'years'}
        data-testid={'age-input-years'}
        name={'years'}
        placeholder={'0'}
        value={age && age.years ? age.years : ''}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={(e) => onChange(e, index)}
      />
      <label htmlFor="years">
        <Text
          namespace={namespace}
          text={`${copyContext}.year`}
          variables={{ count: age && age.years ? age.years : 0 }}
          variant="textRegular16"
          colour="brandBlue500"
        />
      </label>
      <input
        type={'number'}
        className={'ageInput'}
        id={'months'}
        data-testid={'age-input-months'}
        name={'months'}
        placeholder={'0'}
        value={age && age.months ? age.months : ''}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={(e) => onChange(e, index)}
      />
      <label htmlFor="months">
        <Text
          namespace={namespace}
          text={`${copyContext}.month`}
          variables={{ count: age && age.months ? age.months : 0 }}
          variant="textRegular16"
          colour="brandBlue500"
        />
      </label>
    </div>
  )
}

const Age = ({ variant, namespace }: Props): JSX.Element => {
  const route = useLocation().pathname as Route
  const wizardState = useReactiveVar(wizardPageState)
  const { dogs } = wizardState

  const formIsValid = dogs.every(
    (dog: Dog) =>
      dog.ageStage !== null &&
      dog.age &&
      ((dog.age.weeks && dog.age.weeks > 0) ||
        (dog.age.months && dog.age.months > 0) ||
        (dog.age.years && dog.age.years > 0))
  )

  const navigate = useNavigate()

  const onEnterPress = useCallback(() => {
    navigate(routeToNextStep({ route }))
  }, [navigate, route])

  useImplicitFormSubmission({ formIsValid, onEnterPress })

  const resetAge = useCallback(
    (dog) => {
      dog.age = { weeks: null, months: null, years: null }
      wizardPageState({
        ...wizardState,
        dogs
      })
    },
    [dogs, wizardState]
  )

  const handleAgeStageChange = useCallback(
    (e: FormEvent<HTMLDivElement>, index: number) => {
      resetAge(dogs[index])
      const ageStage = e.target as HTMLInputElement
      const ageStageValue = ageStage.value
      dogs[index].ageStage = ageStageValue as WizardAgeStage
      wizardPageState({
        ...wizardState,
        dogs
      })
    },
    [dogs, wizardState, resetAge]
  )

  const handleBroughtHomeChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, index: number) => {
      const broughtHome = e.target
      dogs[index].broughtHome = !broughtHome.checked
      wizardPageState({
        ...wizardState,
        dogs
      })
    },
    [dogs, wizardState]
  )

  const handleDogIsRescueChange = useCallback(
    (e: FormEvent<HTMLInputElement>, index: number) => {
      const dogIsRescue = e.currentTarget
      dogs[index].isRescue = dogIsRescue.checked
      wizardPageState({
        ...wizardState,
        dogs
      })
    },
    [dogs, wizardState]
  )

  const handleAgeChange = useCallback(
    (e: FormEvent<HTMLInputElement>, index: number) => {
      const ageInput = e.currentTarget
      const age = ageInput.value === '' ? null : Number(ageInput.value)
      const dogAge = dogs[index].age

      if (!dogAge) {
        captureException(`Could not find dogAge object`, {
          extra: {
            dogIndex: index,
            dog: dogs
          },
          tags: {
            product: Product.Wizard
          }
        })

        return
      }

      switch (ageInput.name) {
        case 'weeks':
          dogAge.weeks = age
          break
        case 'months':
          dogAge.months = age
          break
        case 'years':
          dogAge.years = age
          break
        default:
          captureException(`Invalid ageInput.name given to handleAgeChange`, {
            extra: {
              ageInputName: ageInput.name,
              dogs
            },
            tags: {
              product: Product.Wizard
            }
          })
      }

      wizardPageState({
        ...wizardState,
        dogs
      })
    },
    [dogs, wizardState]
  )

  return (
    <>
      <div className={`${STYLES.container} ${variant ? STYLES[variant] : ''}`}>
        <div className={STYLES.question} id={'howOldStep'}>
          {dogs.map((dog: Dog, index: number) => {
            return (
              <div
                key={`${dog.name}-${index.toString()}`}
                className={STYLES.subQuestion}
              >
                <TextSeparator
                  namespace={namespace}
                  text={`${copyContext}.text_separator`}
                  variables={{ dogName: dog.name }}
                />
                <div className={STYLES.ageInputContainer}>
                  {/* eslint-disable-next-line react/jsx-no-bind */}
                  <div onChange={(e) => handleAgeStageChange(e, index)}>
                    <PillGroup
                      pillOptions={[
                        {
                          id: `${dog.name}-${index.toString()}-puppy`,
                          dataTestId: 'age-stage-puppy',
                          name: `${dog.name}-${index.toString()}-ageStage`,
                          text: `${copyContext}.puppy`,
                          value: WizardAgeStage.Puppy,
                          defaultChecked: dog.ageStage === WizardAgeStage.Puppy,
                          namespace
                        },
                        {
                          id: `${dog.name}-${index.toString()}-adult`,
                          dataTestId: 'age-stage-adult',
                          name: `${dog.name}-${index.toString()}-ageStage`,
                          text: `${copyContext}.adult`,
                          value: WizardAgeStage.Adult,
                          defaultChecked: dog.ageStage === WizardAgeStage.Adult,
                          namespace
                        },
                        {
                          id: `${dog.name}-${index.toString()}-senior`,
                          dataTestId: 'age-stage-senior',
                          name: `${dog.name}-${index.toString()}-ageStage`,
                          text: `${copyContext}.senior`,
                          value: WizardAgeStage.Senior,
                          defaultChecked:
                            dog.ageStage === WizardAgeStage.Senior,
                          namespace
                        }
                      ]}
                    />
                  </div>
                  {[...Array(30)].map((e, i) => (
                    <span
                      className={`${
                        dog.ageStage === WizardAgeStage.Puppy
                          ? STYLES.confetto
                          : ''
                      }`}
                      // eslint-disable-next-line react/no-array-index-key
                      key={i}
                    />
                  ))}
                  {dog.ageStage ? (
                    dog.ageStage === WizardAgeStage.Puppy ? (
                      <PuppyDetails
                        namespace={namespace}
                        index={index}
                        onChange={handleAgeChange}
                        dog={dog}
                      />
                    ) : (
                      <AgeDetails
                        namespace={namespace}
                        index={index}
                        onChange={handleAgeChange}
                        dog={dog}
                      />
                    )
                  ) : (
                    ''
                  )}
                </div>
                {dog.ageStage && dog.ageStage === WizardAgeStage.Puppy ? (
                  <Fragment>
                    {((dog.age && dog.age.months) ||
                      (dog.age && dog.age.weeks)) && (
                      <DogIsRescue
                        namespace={namespace}
                        dog={dog}
                        index={index}
                        onChange={handleDogIsRescueChange}
                      />
                    )}
                    {dogs.length === 1 && (
                      <PuppyBroughtHome
                        namespace={namespace}
                        dog={dog}
                        index={index}
                        onChange={handleBroughtHomeChange}
                      />
                    )}
                    <Speechbubble namespace={namespace} />
                  </Fragment>
                ) : (
                  ((dog.age && dog.age.months) ||
                    (dog.age && dog.age.years)) && (
                    <DogIsRescue
                      namespace={namespace}
                      dog={dog}
                      index={index}
                      onChange={handleDogIsRescueChange}
                    />
                  )
                )}
              </div>
            )
          })}
        </div>
      </div>
      <StickyNavigation
        variant="twoButtons"
        buttonOne={{
          url: routeToPrevStep({ route }),
          text: 'wizard_flow:sticky_navigation.back',
          variant: 'secondary',
          iconColour: 'brandRed400'
        }}
        buttonTwo={{
          dataTestId: 'next-button',
          url: routeToNextStep({ route }),
          text: 'wizard_flow:sticky_navigation.continue',
          variant: 'primary',
          iconColour: 'brandWhite'
        }}
        disabled={!formIsValid}
      />
    </>
  )
}

export { Props }
export { PuppyDetails, AgeDetails }
export default Age
