// @noflow
import React, { useCallback, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { pronounContext } from '@/utils/StringHelper'

import CircleSparksRed from 'assets/images/icons/ornaments/sparks/circle-sparks--red.svg'

import DsAppOrderSummary from '../../components/DsAppOrderSummary/DsAppOrderSummary'
import LoadingOverlay from '@/components/elements/atoms/LoadingOverlay/LoadingOverlay'
import Text from '@/components/elements/atoms/Text'
import HeroText from '@/components/elements/organisms/HeroText/HeroText'
import StickyNavigation from '@/components/elements/organisms/StickyNavigation/StickyNavigation'

import STYLES from './SummaryScreen.module.sass'

import { PaymentPageContext } from '../../PayOnOwnDevicePage'
import { PayOnOwnDevicePageRoutes } from '../../routes/RouterWrapper'

const TitleAdornment = ({ namespace }: { namespace: string }): JSX.Element => {
  return (
    <>
      <Text
        translate
        namespace={namespace}
        text={'step_one'}
        variant="textMono16"
      />
      <img
        className={STYLES.titleAdornmentImage}
        src={CircleSparksRed}
        alt=""
      />
    </>
  )
}

const SummaryScreen = ({
  namespace
}: {
  namespace: string
}): JSX.Element | null => {
  const { paymentPageState } = useContext(PaymentPageContext)
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState<boolean>(true)

  const navigateToNextStep = useCallback(
    (e) => {
      e.preventDefault()
      navigate(PayOnOwnDevicePageRoutes.paymentScreen)
    },
    [navigate]
  )

  useEffect(() => {
    if (paymentPageState?.data) {
      setLoading(false)
    }
  }, [paymentPageState?.data])

  if (loading || paymentPageState === null) {
    return <LoadingOverlay show={loading} />
  }

  const dogGenders =
    paymentPageState.data.directSalesPendingSubscription.pendingSubscription.user.dogs.map(
      (dog) => dog.gender
    )

  const dogPronoun = pronounContext(dogGenders, paymentPageState?.language)

  return (
    <div className={STYLES.titleAdornment}>
      <HeroText
        namespace={namespace}
        subtitle={`plan_steps.review.header.title`}
        subtitleVariables={{ context: dogPronoun }}
        variant="plans"
        topExtras={<TitleAdornment namespace={namespace} />}
      />
      <DsAppOrderSummary
        namespace={namespace}
        paymentPageState={paymentPageState}
      />
      <StickyNavigation
        variant="oneButton"
        disabled={!paymentPageState}
        buttonOne={{
          variant: 'primary',
          text: 'plan_steps.navigation.continue',
          iconColour: 'brandWhite',
          namespace,
          onClick: navigateToNextStep
        }}
      />
    </div>
  )
}

export default SummaryScreen
