import { isNull } from 'lodash'
import React from 'react'

import Text from '@/components/elements/atoms/Text'

import STYLES from '../AddressBookPage.module.sass'

type Props = {
  deliveryNotes: string | null
}

const DeliveryNotes = ({ deliveryNotes }: Props): JSX.Element | null => {
  return (
    <div className={STYLES.deliveryNotesWrapper}>
      {!isNull(deliveryNotes) && (
        <div className={STYLES.deliveryNotesLabel}>
          <Text
            namespace="account"
            text={'my_details.address_book.delivery_notes_title'}
            variant="textRegular14"
            colour="brandBlue400"
            margin={false}
          />
        </div>
      )}
      <Text
        text={deliveryNotes ?? 'my_details.address_book.empty_delivery_notes'}
        namespace="account"
        variant="textRegular16"
        translate={!deliveryNotes}
        colour="brandBlue500"
        margin={false}
        hideOverflow
      />
    </div>
  )
}

export default DeliveryNotes
