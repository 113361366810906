// @noflow
import React from 'react'
import { useTranslation } from 'react-i18next'
import Recaptcha from 'react-recaptcha'
import { connect } from 'react-redux'
import type { Dispatch } from 'redux'

import * as ACTIONS from '../../actions'
import type { State } from '../../reducers'
import type { State as FormState } from '../../reducers/formDataReducer'
import type { State as GlobalAttributesState } from '../../reducers/globalAttributesReducer'

type VerifiedUserString = string

type PresentationalProps = {
  recaptchaSiteKey: GlobalAttributesState['recaptchaSiteKey']
  recaptchaLoading: FormState['recaptchaLoaded']
}

type ActionProps = {
  verifyRecaptcha: (verifiedUserString: string) => void
  expireRecaptcha: () => void
  setRecaptchaLoaded: () => void
}

type Props = PresentationalProps & ActionProps

const mapStateToProps = ({
  formData,
  globalAttributes
}: State): PresentationalProps => {
  const { recaptchaLoaded } = formData
  const { recaptchaSiteKey } = globalAttributes

  return {
    recaptchaSiteKey,
    recaptchaLoading: !recaptchaLoaded
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => {
  const setRecaptchaLoaded = (): void => {
    dispatch(ACTIONS.setRecaptchaLoaded())
  }

  const verifyRecaptcha = (verifiedUserString: VerifiedUserString): void => {
    dispatch(
      ACTIONS.updateField('recaptchaString', {
        value: verifiedUserString,
        interaction: 'InteractedWithSuccess'
      })
    )
  }

  const expireRecaptcha = (): void => {
    dispatch(
      ACTIONS.updateField('recaptchaString', {
        value: '',
        interaction: 'NotInteracted'
      })
    )
  }

  return {
    verifyRecaptcha,
    expireRecaptcha,
    setRecaptchaLoaded
  }
}

const RecaptchaWrapper = ({
  recaptchaSiteKey,
  recaptchaLoading,
  verifyRecaptcha,
  expireRecaptcha,
  setRecaptchaLoaded
}: Props): React.ReactElement => {
  const { t } = useTranslation('contacts')
  const copyContext = 'recaptcha'
  return (
    <div className="contact-us__form__recaptcha">
      {recaptchaLoading && <p>{t(`${copyContext}.recaptcha_loading`)}</p>}
      <Recaptcha
        sitekey={recaptchaSiteKey}
        render="explicit"
        verifyCallback={verifyRecaptcha}
        expiredCallback={expireRecaptcha}
        onloadCallback={setRecaptchaLoaded}
      />
      <div className="contact-us__form__recaptcha__text">
        {t(`${copyContext}.recaptcha_text`)}
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RecaptchaWrapper)
