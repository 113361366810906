// @noflow
import { Factory } from 'fishery'

import { Box } from '../types'

export default Factory.define<Box>(({ sequence }) => ({
  id: sequence,
  isoDeliveryDate: new Date().toDateString(),
  full: false
}))
