// @noflow
import { ACCOUNT_ROUTES } from '@/routes'
import { useReactiveVar } from '@apollo/client'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { SwiperClass } from 'swiper/react'

import { dogsDataVar } from '@/services/apollo'

import usePlan, { Plan } from '@/hooks/usePlan/usePlan'

import AlertCard from '@/components/elements/atoms/Alert/AlertCard'
import { Bounce, Expand } from '@/components/elements/atoms/Animated/Animated'
import { Button } from '@/components/elements/atoms/Button'
import { CardSkeleton } from '@/components/elements/atoms/Card/CardSkeleton'
import { FixedBase } from '@/components/elements/atoms/FixedBase'
import Image from '@/components/elements/atoms/Image'
import Label from '@/components/elements/atoms/Label/Label'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import SpeechBubble from '@/components/elements/atoms/SpeechBubble/SpeechBubble'
import Text, {
  AllowedAlignment,
  Props as TextProps
} from '@/components/elements/atoms/Text/Text'
import SwiperSlider from '@/components/elements/molecules/SwiperSlider/SwiperSlider'

import STYLES from './ServingSizePage.module.sass'

import { TypeOfPlan } from '@/types'

type ServingSizeCardProps = {
  size?: 'small' | 'regular'
  children: ReactNode
}

type PouchProps = ServingSizeCardProps

const namespace = 'account'

const Pouch = ({ size = 'regular', children }: PouchProps): JSX.Element => {
  const { t } = useTranslation(namespace)

  const isSmall = size === 'small'
  const slug = isSmall ? 'small-generic-pouch' : 'generic-pouch'

  return (
    <div className={`${STYLES.slide} ${isSmall ? STYLES.small : ''}`}>
      <div className={STYLES.pouch}>
        <Image
          slug={slug}
          image={{ width: isSmall ? 300 : 200, height: isSmall ? 224 : 320 }}
          alt={t(`serving_size.pouch_alt`, { pouchSize: size })}
        />
        <div className={STYLES.label}>
          <div className={STYLES.logo}>
            <Image
              slug="account-logo"
              alt={t('serving_size.pouch.logo_alt')}
              image={{ width: 60, height: 48 }}
            />
          </div>
          <div className={STYLES.details}>{children}</div>
        </div>
      </div>
    </div>
  )
}

type PlanTypeInfoProps = {
  pronoun: string
  customerPlanType: string
}

const showBoxSizeWarning = (currentPlan: Plan, selectedPlan: Plan) =>
  selectedPlan.numberOfPouches !== currentPlan.numberOfPouches ||
  selectedPlan.durationInDays !== currentPlan.durationInDays

const PlanTypeInfo = ({
  pronoun,
  customerPlanType
}: PlanTypeInfoProps): JSX.Element => {
  const { t } = useTranslation(namespace)

  return (
    <>
      {['some', 'half', 'most', 'all'].map((planType) => (
        <div
          key={planType}
          className={`${
            customerPlanType !== planType ? STYLES.hiddenPlanType : ''
          }`}
        >
          <Bounce show={customerPlanType === planType}>
            <SpeechBubble
              footPosition="topLeft"
              bubblePosition="center"
              text={{
                text: `<strong>${t(`shared.serving_type.${planType}.title`, {
                  context: pronoun
                })}</strong><br />${t(
                  `shared.serving_type.${planType}.description`,
                  {
                    context: pronoun
                  }
                )}`,
                translate: false
              }}
            />
          </Bounce>
        </div>
      ))}
    </>
  )
}

type PlanEditPageProps = {
  title?: TextProps
  selectedPlan: Plan | undefined
  loading: boolean
  children: ReactNode
}

const getSectionWrapperProps = (title?: TextProps) =>
  ({
    headerTypography: !isUndefined(title)
      ? {
          ...title,
          namespace: 'account',
          align: 'center' as AllowedAlignment
        }
      : undefined,
    bgColour: 'brandWhite90',
    borderRadius: 16,
    padding: 32
  } as const)

const PlanEditPage = ({
  title,
  selectedPlan,
  loading,
  children
}: PlanEditPageProps): JSX.Element | null => {
  const container = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const { currentPlan } = usePlan()
  const { pronoun } = useReactiveVar(dogsDataVar) || {}
  const [displayingCurrentPlan, setDisplayingCurrentPlan] = useState<
    boolean | undefined
  >()

  const { updating } = usePlan()

  const onSelect = useCallback(() => {
    if (isUndefined(selectedPlan) || isUndefined(displayingCurrentPlan)) return
    if (displayingCurrentPlan) navigate(ACCOUNT_ROUTES.planConfirmation)
    else navigate(`${ACCOUNT_ROUTES.updatePlan}/${selectedPlan.id}`)
  }, [displayingCurrentPlan, navigate, selectedPlan])

  useEffect(() => {
    if (!isUndefined(currentPlan) && !isUndefined(selectedPlan)) {
      setDisplayingCurrentPlan(currentPlan?.id === selectedPlan?.id)
    }
  }, [currentPlan, selectedPlan])

  if (loading || isUndefined(currentPlan) || isUndefined(pronoun)) return null

  return (
    <div ref={container}>
      <SectionWrapper {...getSectionWrapperProps(title)}>
        <>
          {children}
          {!isUndefined(selectedPlan) && (
            <div className={STYLES.coverage}>
              <PlanTypeInfo
                pronoun={pronoun}
                customerPlanType={selectedPlan.typeOfPlanForCustomer}
              />
            </div>
          )}
        </>
      </SectionWrapper>
      <FixedBase backgroundColor="brandWhite90" container={container}>
        <div className={STYLES.button}>
          <Button
            disabled={updating || isUndefined(selectedPlan)}
            onClick={onSelect}
            typography={{
              text: `serving_size.button.${
                displayingCurrentPlan ? 'current' : 'update'
              }`,
              namespace,
              variables: {
                context: pronoun
              }
            }}
            identifier="change_serving_size.update"
          />
          <Expand
            show={!isUndefined(displayingCurrentPlan) && displayingCurrentPlan}
          >
            <div className={STYLES.noChange}>
              <Text
                text="serving_size.subtext.current"
                variables={{
                  context: pronoun
                }}
                namespace={namespace}
                element="div"
              />
            </div>
          </Expand>
        </div>
      </FixedBase>
    </div>
  )
}

const ServingSizePage = (): JSX.Element | null => {
  const { dogs } = useReactiveVar(dogsDataVar) || {}
  const { planId } = useParams()
  const { currentPlan, getPlan } = usePlan()
  const { state } = useLocation()
  const [swiper, setSwiper] = useState<SwiperClass>()
  const [loading, setLoading] = useState<boolean>(true)
  const [swiperIndex, setSwiperIndex] = useState<number>()
  const [selectedPlan, setSelectedPlan] = useState<Plan>()

  useEffect(() => {
    if (!isUndefined(planId) && !isUndefined(getPlan)) {
      setSelectedPlan(getPlan(planId))
    }
  }, [getPlan, planId])

  useEffect(() => {
    if (!isUndefined(swiper)) {
      swiper.on('slideChange', (swiper) => {
        setSwiperIndex(swiper.activeIndex)
      })
    }
  }, [swiper])

  const { dailyServingSizeOptions, dailyServingSizeIndex } = usePlan()

  useEffect(() => {
    !isUndefined(swiperIndex) &&
      !isUndefined(dailyServingSizeOptions) &&
      setSelectedPlan(dailyServingSizeOptions[swiperIndex])
  }, [dailyServingSizeOptions, swiperIndex])

  // on load sync slider with the current plan
  useEffect(() => {
    if (
      !isUndefined(swiper) &&
      !isUndefined(dailyServingSizeIndex) &&
      !isUndefined(dailyServingSizeOptions) &&
      isUndefined(swiperIndex)
    ) {
      let initial = dailyServingSizeIndex

      // if returning from the review page then display the previously changed plan
      if (!isUndefined(state?.previous?.params?.planId)) {
        initial = dailyServingSizeOptions.findIndex(
          (plan) => plan.id === state?.previous?.params?.planId
        )
      }

      setSwiperIndex(initial)
      swiper.slideTo(initial, 0)
    }
  }, [
    dailyServingSizeIndex,
    dailyServingSizeOptions,
    state?.previous?.params?.planId,
    swiper,
    swiperIndex
  ])

  // manage loading state
  useEffect(() => {
    setLoading(
      isUndefined(dailyServingSizeIndex) ||
        isUndefined(dailyServingSizeOptions) ||
        isNull(dogs)
    )
  }, [dailyServingSizeIndex, dailyServingSizeOptions, dogs])

  if (loading || isUndefined(dailyServingSizeOptions))
    return (
      <div className={STYLES.container}>
        <CardSkeleton width={800} height={580} />
      </div>
    )

  const allPlansFeedAllNeeds =
    dailyServingSizeOptions?.length ===
    dailyServingSizeOptions?.filter(
      (option) => option.typeOfPlanForCustomer === TypeOfPlan.all
    ).length

  return (
    <div className={STYLES.container}>
      <PlanEditPage selectedPlan={selectedPlan} loading={loading}>
        <Expand
          show={
            !isUndefined(currentPlan) &&
            !isUndefined(selectedPlan) &&
            showBoxSizeWarning(currentPlan, selectedPlan)
          }
        >
          <AlertCard
            message={{
              text: 'serving_size.box_warning',
              namespace,
              align: 'left',
              variables: {
                numberOfPouches:
                  !isUndefined(selectedPlan) && selectedPlan.numberOfPouches,
                pouchSize: !isUndefined(selectedPlan) && selectedPlan.pouchSize,
                weeks:
                  !isUndefined(selectedPlan) && selectedPlan.durationInDays / 7
              }
            }}
          />
        </Expand>
        <div className={STYLES.slider}>
          <SwiperSlider
            slider={setSwiper}
            variant="brandYellow300"
            slidesPerView={1}
            initialSlide={dailyServingSizeIndex}
            arrows
          >
            {dailyServingSizeOptions?.map((option) => {
              // NOTE: there is a pouchesPerDay value available from graphQL however it always returns 0
              const pouchesPerDay = option.servingSize / option.pouchSize

              return (
                <Pouch
                  key={option.gramsPerDay}
                  size={option.pouchSize < 400 ? 'small' : 'regular'}
                >
                  <Text
                    text="serving_size.pouch.daily_grams"
                    variables={{ grams: option.servingSize }}
                    namespace={namespace}
                    variant="display24"
                    element="div"
                  />
                  {pouchesPerDay > 1 && (
                    <Text
                      text="serving_size.pouch.multiple_pouches"
                      variables={{
                        grams: option.pouchSize,
                        count: pouchesPerDay
                      }}
                      namespace={namespace}
                      variant="textRegular14"
                      element="div"
                    />
                  )}
                  {allPlansFeedAllNeeds &&
                    option.typeOfPlanForCustomer === TypeOfPlan.all && (
                      <div className={STYLES.recommended}>
                        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                        <Label
                          variant="recommendedServingSize"
                          text={{
                            text: 'serving_size.pouch.recommended',
                            namespace
                          }}
                          size="small"
                        />
                      </div>
                    )}
                </Pouch>
              )
            })}
          </SwiperSlider>
        </div>
      </PlanEditPage>
    </div>
  )
}

export { Pouch, PlanTypeInfo, PlanEditPage }
export default ServingSizePage
