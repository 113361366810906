// @noflow

/* eslint-disable i18next/no-literal-string */
import isNil from 'lodash/isNil'
import isUndefined from 'lodash/isUndefined'
import React, { ReactElement } from 'react'

import { Options, formatCurrencyWithDecimal } from '@/utils/currency'

import Label from '@/components/elements/atoms/Label/Label'
import { Props as TextProps } from '@/components/elements/atoms/Text/Text'
import {
  Props as PriceBreakdownProps,
  Row as PriceBreakdownRowProps,
  TotalRow
} from '@/components/elements/molecules/PriceBreakdown/PriceBreakdown'
import { Props } from '@/components/elements/organisms/BoxPriceBreakdown/BoxPriceBreakdown'

enum RowId {
  meals = 'meals',
  delivery = 'delivery',
  discount = 'discount',
  extras = 'extras',
  surcharge = 'surcharge'
}

type TotalBreakdownRow = {
  total: number
  totalBeforeDiscount?: number
}

type PerDayBreakdownRow = {
  perDay: number
  perDayBeforeDiscount?: number
}

type DogPerDayBreakdownRow = PerDayBreakdownRow & {
  dogName?: string
}

type MealBreakdownRow = {
  dogName?: string
  total: number
  perDay: number
}

const SummaryLabels = (
  discountTotal: number,
  isReplacementBox: boolean,
  context: string,
  namespace: string,
  currencyOptions: Options
): ReactElement[] | undefined => {
  const labels: ReactElement[] = []

  if (isReplacementBox) {
    labels.push(
      // eslint-disable-next-line jsx-a11y/label-has-for
      <Label
        id="replacement-box-label"
        key="replacement-box-label"
        variant="replacement"
        text={{
          text: `${context}.replacement`,
          translate: true,
          namespace
        }}
      />
    )
  }

  if (discountTotal > 0) {
    labels.push(
      // eslint-disable-next-line jsx-a11y/label-has-for
      <Label
        id="discount-flat-label"
        key="discount-flat-label"
        variant="discount"
        text={{
          text: `${context}.discount`,
          variables: {
            discount: formatCurrencyWithDecimal(discountTotal, currencyOptions)
          },
          translate: true,
          namespace: namespace
        }}
      />
    )
  }

  if (labels.length > 0) return labels
  return undefined
}

const freeDelivery = (deliveryFee: Props['deliveryFee']): boolean =>
  deliveryFee === 0 || isUndefined(deliveryFee)

const generateDeliveryRow = (
  total: number,
  totalBeforeDiscount: number | undefined,
  currencyOptions: Options,
  context: string
): TextProps =>
  !isUndefined(totalBeforeDiscount)
    ? {
        text:
          total === 0
            ? `${context}.discounted_free_delivery`
            : `${context}.discounted_total`,
        variables: {
          total: formatCurrencyWithDecimal(total, currencyOptions),
          totalBeforeDiscount: formatCurrencyWithDecimal(
            totalBeforeDiscount,
            currencyOptions
          )
        }
      }
    : {
        text:
          total === 0
            ? `${context}.delivery_free`
            : formatCurrencyWithDecimal(total, currencyOptions)
      }

const generateDiscountRow = (
  discount: number,
  currencyOptions: Options,
  context: string,
  intro: boolean,
  boost?: boolean
): PriceBreakdownRowProps => ({
  label: intro
    ? `${context}.intro_box_discount_label`
    : `${context}.discount_label`,
  value: {
    text: `-${formatCurrencyWithDecimal(discount, currencyOptions)}`,
    translate: false,
    bold: true
  },
  discount: true,
  translate: { value: false },
  labelOverrides: {
    bold: true
  },
  id: RowId.discount,
  boost
})

const generateSurchargeRow = (
  surcharge: number,
  currencyOptions: Options,
  context: string
): PriceBreakdownRowProps => ({
  label: `${context}.surcharge`,
  value: formatCurrencyWithDecimal(surcharge, currencyOptions),
  translate: { value: false },
  id: RowId.surcharge
})

const generateTotalRow = (
  total: number,
  isFreeBox: boolean,
  currencyOptions: Options,
  context: string
): TotalRow => ({
  label: `${context}.total`,
  value: isFreeBox
    ? `${context}.total_free`
    : formatCurrencyWithDecimal(total, currencyOptions),
  translate: isFreeBox
})

const showDiscount = (discount: Props['discount']): boolean =>
  !isNil(discount) && discount > 0
const showSurcharge = (surcharge: Props['surchargeTotal']): boolean =>
  !isNil(surcharge) && surcharge > 0
const showExtras = (extras: Props['extras']): boolean =>
  !isNil(extras) && extras > 0

// Enforce the order of the breakdown rows
const generateRows = (
  meals: PriceBreakdownRowProps[],
  delivery: PriceBreakdownRowProps,
  discount?: PriceBreakdownRowProps,
  surcharge?: PriceBreakdownRowProps,
  extras?: PriceBreakdownRowProps[]
): PriceBreakdownProps['rows'] => {
  const rows: PriceBreakdownProps['rows'] = []

  rows.push(...meals)
  if (!isUndefined(surcharge)) rows.push(surcharge)
  if (!isUndefined(extras)) rows.push(...extras)
  rows.push(delivery)
  if (!isUndefined(discount)) rows.push(discount)

  return rows
}

export {
  RowId,
  MealBreakdownRow,
  DogPerDayBreakdownRow,
  PerDayBreakdownRow,
  TotalBreakdownRow,
  freeDelivery,
  SummaryLabels,
  generateRows,
  showDiscount,
  showSurcharge,
  showExtras,
  generateSurchargeRow,
  generateDiscountRow,
  generateTotalRow,
  generateDeliveryRow
}
