import { gql } from '@apollo/client'

import {
  EDIT_EXTRAS_BOX_FRAGMENT,
  EDIT_EXTRAS_USER_FRAGMENT
} from '../fragments/fragments'

const EXTRAS_MODAL_QUERY = gql`
  query ExtrasModalQuery {
    user {
      id
      dogs {
        gender
        name
      }
    }
  }
`
const EDIT_EXTRAS_QUERY = gql`
  ${EDIT_EXTRAS_USER_FRAGMENT}
  ${EDIT_EXTRAS_BOX_FRAGMENT}
  query EditExtrasQuery($boxId: ID!) {
    user {
      ...EditExtrasUserFragment
      subscription {
        id
        box(boxId: $boxId) {
          ...EditExtrasBoxFragment
        }
      }
    }
  }
`

const EDIT_EXTRAS_NO_BOX_ID_QUERY = gql`
  ${EDIT_EXTRAS_USER_FRAGMENT}
  ${EDIT_EXTRAS_BOX_FRAGMENT}
  query EditExtrasNoBoxIdQuery {
    user {
      ...EditExtrasUserFragment
      subscription {
        id
        nextEditableBox {
          ...EditExtrasBoxFragment
        }
      }
    }
  }
`

export { EXTRAS_MODAL_QUERY, EDIT_EXTRAS_QUERY, EDIT_EXTRAS_NO_BOX_ID_QUERY }
