// @noflow
import React from 'react'

import Icon from '@/components/elements/atoms/Icon/Icon'
import Text from '@/components/elements/atoms/Text/Text'

type Props = {
  deliveryFee: number
  relevantPostcode: string
}

const DeliveryFeeInfo = ({
  deliveryFee,
  relevantPostcode
}: Props): JSX.Element | null => {
  const namespace = 'checkout'
  const copyKey = deliveryFee === 0 ? 'fee_free' : 'fee_postcode_offered'

  return (
    <div className="delivery-fee-message">
      <Icon asset="checkmark" size={20} accentColour="successGreen300" />
      <Text
        namespace={namespace}
        text={`delivery_date_section.${copyKey}`}
        variables={{ relevantPostcode }}
        colour="brandBlue400"
        align="left"
      />
    </div>
  )
}

export default DeliveryFeeInfo
