// @noflow
import { ACCOUNT_ROUTES } from '@/routes'
import React, { SVGProps, cloneElement, useCallback, useMemo } from 'react'

import BREAKPOINTS from '@/constants/Breakpoints'

import { useGoBack } from '@/hooks/useGoBack/useGoBack'
import useWindowSize from '@/hooks/useWindowSize'

// Assets
import Chevron from '@/components/elements/atoms/Icon/Icons/Chevron'

// Styles
import STYLES from '../Header.module.sass'

type LeftAdornmentIconVariant = {
  variant?: 'icon'
  icon?: JSX.Element
  props?: SVGProps<SVGAElement>
  defaultBackRoute?: string
  onPress?: () => void
  component?: never
}

type LeftAdornmentCustomVariant = {
  variant: 'custom'
  component: JSX.Element
  icon?: never
  props?: never
  defaultBackRoute?: never
  onPress?: never
}

type Props = LeftAdornmentIconVariant | LeftAdornmentCustomVariant

const LeftAdornment = ({
  variant = 'icon',
  defaultBackRoute = ACCOUNT_ROUTES.base,
  ...props
}: Props): JSX.Element | null => {
  const goBack = useGoBack()
  const { windowWidth } = useWindowSize()

  const chevronSize = useMemo(
    () => (windowWidth < BREAKPOINTS.md ? 20 : 23),
    [windowWidth]
  )

  const defaultAction = useCallback(() => {
    if (variant === 'icon') {
      goBack(defaultBackRoute)
    }
  }, [goBack, variant, defaultBackRoute])

  switch (variant) {
    case 'icon': {
      const { icon, props: svgProps } = props

      const onPressAction = props.onPress || defaultAction

      return (
        <button
          onClick={onPressAction}
          type="button"
          className={STYLES.leftAdornment}
        >
          {icon ? (
            cloneElement(icon, svgProps)
          ) : (
            <Chevron
              direction="left"
              accentColour="brandBlue500"
              size={chevronSize}
            />
          )}
        </button>
      )
    }
    case 'custom': {
      const { component } = props as LeftAdornmentCustomVariant

      return component
    }
    default: {
      return null
    }
  }
}

export type { Props as LeftAdornmentProps }
export { LeftAdornment }
