// @noflow
import Calendar from '@/components/elements/atoms/Icon/Icons/Calendar'

import Alert from './Icons/Alert'
import Arrow from './Icons/Arrow'
import Badge from './Icons/Badge'
import Bin from './Icons/Bin'
import BoxClosed from './Icons/BoxClosed'
import BoxOpen from './Icons/BoxOpen'
import Bubble from './Icons/Bubble'
import ButternutLogo from './Icons/ButternutLogo'
import Checkmark from './Icons/Checkmark'
import Chevron from './Icons/Chevron'
import Close from './Icons/Close'
import CloseBold from './Icons/CloseBold'
import CurvedArrow from './Icons/CurvedArrow'
import CurvedDashedArrow from './Icons/CurvedDashedArrow'
import Customer from './Icons/Customer'
import DecorativeCheckbox from './Icons/DecorativeCheckbox'
import DogBowl from './Icons/DogBowl'
import Email from './Icons/Email'
import ErrorCircle from './Icons/ErrorCircle'
import Eye from './Icons/Eye'
import Facebook from './Icons/Facebook'
import FacebookLetter from './Icons/FacebookLetter'
import Food from './Icons/Food'
import HeartWithPlus from './Icons/HeartWithPlus'
import House from './Icons/House'
import Lock from './Icons/Lock'
import Minus from './Icons/Minus'
import Padlock from './Icons/Padlock'
import Pause from './Icons/Pause'
import Paw from './Icons/Paw'
import PaymentMethod from './Icons/PaymentMethod'
import Phone from './Icons/Phone'
import Play from './Icons/Play'
import Plus from './Icons/Plus'
import Pouch from './Icons/Pouch'
import Question from './Icons/Question'
import RadioSelected from './Icons/RadioSelected'
import RadioUnselected from './Icons/RadioUnselected'
import Receipt from './Icons/Receipt'
import ShoppingDog from './Icons/ShoppingDog'
import Sprinkles from './Icons/Sprinkles'
import SquiggleDashedArrow from './Icons/SquiggleDashedArrow'
import Star from './Icons/Star'
import Stethoscope from './Icons/Stethoscope'
import StopVan from './Icons/StopVan'
import SurveyClose from './Icons/SurveyClose'
import Van from './Icons/Van'
import VanFilled from './Icons/VanFilled'
import WhatsApp from './Icons/WhatsApp'

type BrandAssets = {
  checkmark: typeof Checkmark
  badge: typeof Badge
  boxClosed: typeof BoxClosed
  boxOpen: typeof BoxOpen
  chevron: typeof Chevron
  close: typeof Close
  closeBold: typeof CloseBold
  van: typeof Van
  vanFilled: typeof VanFilled
  calendar: typeof Calendar
  pouch: typeof Pouch
  arrow: typeof Arrow
  bin: typeof Bin
  plus: typeof Plus
  decorativeCheckbox: typeof DecorativeCheckbox
  minus: typeof Minus
  bubble: typeof Bubble
  shoppingDog: typeof ShoppingDog
  whatsApp: typeof WhatsApp
  facebook: typeof Facebook
  facebookLetter: typeof FacebookLetter
  email: typeof Email
  errorCircle: typeof ErrorCircle
  curvedDashedArrow: typeof CurvedDashedArrow
  alert: typeof Alert
  question: typeof Question
  star: typeof Star
  house: typeof House
  paw: typeof Paw
  pause: typeof Pause
  play: typeof Play
  curvedArrow: typeof CurvedArrow
  customer: typeof Customer
  paymentMethods: typeof PaymentMethod
  receipt: typeof Receipt
  phone: typeof Phone
  stopVan: typeof StopVan
  butternutLogo: typeof ButternutLogo
  eye: typeof Eye
  padlock: typeof Padlock
  radioUnselected: typeof RadioUnselected
  radioSelected: typeof RadioSelected
  squiggleDashedArrow: typeof SquiggleDashedArrow
  lock: typeof Lock
  sprinkles: typeof Sprinkles
  surveyClose: typeof SurveyClose
  dogBowl: typeof DogBowl
  food: typeof Food
  stethoscope: typeof Stethoscope
  heartWithPlus: typeof HeartWithPlus
}

const BRAND_ASSETS: BrandAssets = {
  checkmark: Checkmark,
  badge: Badge,
  boxClosed: BoxClosed,
  boxOpen: BoxOpen,
  chevron: Chevron,
  close: Close,
  closeBold: CloseBold,
  van: Van,
  vanFilled: VanFilled,
  calendar: Calendar,
  pouch: Pouch,
  arrow: Arrow,
  bin: Bin,
  plus: Plus,
  decorativeCheckbox: DecorativeCheckbox,
  minus: Minus,
  bubble: Bubble,
  shoppingDog: ShoppingDog,
  whatsApp: WhatsApp,
  facebook: Facebook,
  facebookLetter: FacebookLetter,
  email: Email,
  errorCircle: ErrorCircle,
  curvedDashedArrow: CurvedDashedArrow,
  alert: Alert,
  question: Question,
  star: Star,
  house: House,
  paw: Paw,
  pause: Pause,
  play: Play,
  curvedArrow: CurvedArrow,
  customer: Customer,
  paymentMethods: PaymentMethod,
  receipt: Receipt,
  phone: Phone,
  stopVan: StopVan,
  butternutLogo: ButternutLogo,
  eye: Eye,
  padlock: Padlock,
  radioUnselected: RadioUnselected,
  radioSelected: RadioSelected,
  squiggleDashedArrow: SquiggleDashedArrow,
  lock: Lock,
  sprinkles: Sprinkles,
  surveyClose: SurveyClose,
  dogBowl: DogBowl,
  food: Food,
  stethoscope: Stethoscope,
  heartWithPlus: HeartWithPlus
}

export { BRAND_ASSETS }

export type { BrandAssets }
