// @noflow
// Enum
import { useMemo, useState } from 'react'

import * as Sentry from '@/utils/sentry'

import type {
  ProductCollectionQuery_productCollection_productVariants_productVariantDeliveryTypes as ProductVariantDeliveryType,
  ProductCollectionQuery_productCollection_productVariants as ProductVariantType
} from '../../queries/__generated__/ProductCollectionQuery'

import { SubscriptionStatus } from '../../../../../../../types/index'
import type { VariantDelivery } from '../../../../../../../types/index'

type SelectedProductArgs = {
  productVariants: Array<ProductVariantType>
  subscriptionStatus: SubscriptionStatus
}

type SelectedProduct = {
  selectedProductVariantId: ProductVariantType['id']
  selectedProductVariantSlug: ProductVariantType['slug']
  selectedProductVariant: ProductVariantType | null
  selectedProductVariantFrequency: VariantDelivery
  selectedProductVariantDeliveryType: ProductVariantDeliveryType | null
  selectedProductQuantity: number
  setSelectedProductVariantId: (arg: ProductVariantType['id']) => void
  setSelectedProductVariantSlug: (arg: ProductVariantType['slug']) => void
  setSelectedProductVariantFrequency: (arg: VariantDelivery) => void
  setSelectedProductQuantity: (arg: number) => void
}

const useSelectedProduct = ({
  productVariants,
  subscriptionStatus
}: SelectedProductArgs): SelectedProduct => {
  /**
   * Get the first available product variant
   *
   * We use that to get all available delivery types for the
   * first available product variant and set initial value for the
   * `selectedProductVariantId` state.
   */
  const [firstAvailableProductVariant] = productVariants

  /**
   * Get available delivery types for the first product variant
   *
   * We use that to check if the `recurring` delivery type available
   * and to get the first available delivery type for the initial
   * product variant.
   */
  const { productVariantDeliveryTypes: firstProductVariantDeliveryTypes } =
    firstAvailableProductVariant

  /**
   * Check if the recurring delivery type available
   *
   * We use that to set initial value for the `selectedProductVariantFrequency`
   * state.
   */
  const isOnDemandFrequencyAvailable = firstProductVariantDeliveryTypes.some(
    ({ deliveryType }) => deliveryType === 'on_demand'
  )

  const isRecurringFrequencyAvailable = firstProductVariantDeliveryTypes.some(
    ({ deliveryType }) => deliveryType === 'recurring'
  )

  const [firstAvailableFrequency] = firstProductVariantDeliveryTypes

  /**
   * Selected product variant
   *
   * Setting the default value to the first product variant in the array.
   * Array should be sorted on the server.
   */
  const [selectedProductVariantId, setSelectedProductVariantId] = useState(
    firstAvailableProductVariant.id
  )

  /**
   * Selected product variant
   *
   * Setting the default value to the first product variant in the array.
   * Array should be sorted on the server.
   */
  const [selectedProductVariantSlug, setSelectedProductVariantSlug] = useState(
    firstAvailableProductVariant.slug
  )

  const selectedProductVariant = useMemo(() => {
    const selectedProductCollectionVariant = productVariants.find(
      ({ id }) => id === selectedProductVariantId
    )

    if (!selectedProductCollectionVariant) {
      Sentry.captureException(
        `Product variant is not valid for: ${selectedProductVariantId}`,
        {
          tags: {
            product: Sentry.Product.Account,
            team: Sentry.Team.Retention
          }
        }
      )

      return null
    }

    return selectedProductCollectionVariant
  }, [productVariants, selectedProductVariantId])

  /**
   * Selected product variant frequency
   *
   * Setting the default value to the 'recurring' option.
   *
   * Available frequencies:
   * - one_off
   * - recurring
   * - on_demand
   */
  const [selectedProductVariantFrequency, setSelectedProductVariantFrequency] =
    useState<VariantDelivery>(
      subscriptionStatus === SubscriptionStatus.paused &&
        isOnDemandFrequencyAvailable
        ? ('on_demand' as VariantDelivery)
        : isRecurringFrequencyAvailable
        ? ('recurring' as VariantDelivery)
        : firstAvailableFrequency.deliveryType
    )

  const selectedProductVariantDeliveryType = useMemo(() => {
    if (selectedProductVariant) {
      const { productVariantDeliveryTypes } = selectedProductVariant

      const selectedProductCollectionVariantDeliveryType =
        productVariantDeliveryTypes.find(
          ({ deliveryType }) => deliveryType === selectedProductVariantFrequency
        )

      if (!selectedProductCollectionVariantDeliveryType) {
        Sentry.captureException(
          `Product variant delivery type is not valid for: ${selectedProductVariantFrequency}`,
          {
            tags: {
              product: Sentry.Product.Account,
              team: Sentry.Team.Retention
            }
          }
        )

        return null
      }

      return selectedProductCollectionVariantDeliveryType
    } else {
      return null
    }
  }, [selectedProductVariant, selectedProductVariantFrequency])

  /**
   * Selected product quantity
   *
   * TODO: set correct initial selected product quantity
   */
  const [selectedProductQuantity, setSelectedProductQuantity] = useState(1)

  return {
    // Product variant
    selectedProductVariantId,
    selectedProductVariant,
    setSelectedProductVariantId,
    // Product variant frequecny
    selectedProductVariantFrequency,
    selectedProductVariantDeliveryType,
    setSelectedProductVariantFrequency,
    // Product quantity
    selectedProductQuantity,
    setSelectedProductQuantity,
    // Product slug
    selectedProductVariantSlug,
    setSelectedProductVariantSlug
  }
}

export default useSelectedProduct
