import { gql } from '@apollo/client'

import { RECOMMENDED_EXTRAS_FRAGMENT } from './fragment/RecommendedExtrasFragment'

const EXTRAS_SCREEN_QUERY = gql`
  ${RECOMMENDED_EXTRAS_FRAGMENT}
  query ExtrasScreenQuery {
    guest {
      planRecommendation {
        recommendedExtraProductVariants {
          ...recommendedExtrasFragment
        }
      }
    }
  }
`

export default EXTRAS_SCREEN_QUERY
