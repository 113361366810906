// @noflow
import { useNotifications } from '@/context/notifications/notifications'
import { FetchResult, useMutation, useQuery } from '@apollo/client'
import isUndefined from 'lodash/isUndefined'
import sortBy from 'lodash/sortBy'
import sortedUniqBy from 'lodash/sortedUniqBy'
import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import * as Sentry from '@/utils/sentry'

import { UsePlanQuery } from '@/hooks/usePlan/__generated__/UsePlanQuery'
import type {
  UsePlanQuery_user_dogs as Dog,
  UsePlanQuery_user_subscription_plan_gramsPerDayPerDogs as DogPlan,
  UsePlanQuery_user_subscription_plan as Plan,
  UsePlanQuery_user_subscription_recurringOrder as RecurringOrder
} from '@/hooks/usePlan/__generated__/UsePlanQuery'
import { UPDATE_PLAN_MUTATION, USE_PLAN_QUERY } from '@/hooks/usePlan/queries'

import { Serving } from '@/shared_types/graphql/types/enums/plans/serving'

type FrequencyOption = Pick<Plan, 'numberOfPouches' | 'pouchSize'> & {
  options: Plan[]
}

type PlanProps = {
  dailyServingSizeOptions: Plan[] | undefined
  dailyServingSizeIndex: number | undefined
  currentPlan: Plan | undefined
  idealPlan: Plan | undefined
  recurringOrder: RecurringOrder | undefined
  frequencyOptionsByNumberOfPouches: FrequencyOption[] | undefined
  setPlan: ((planId: string) => Promise<FetchResult>) | undefined
  getPlan: ((planId: string) => Plan | undefined) | undefined
  canChangeDeliveryFrequency: boolean | undefined
  updating: boolean
  loading: boolean
  dogPlans: Array<(Dog & Pick<DogPlan, 'gramsPerDay'>) | undefined> | undefined
}

/**
 * Hook which gives easy access to plan related information like the users current plan,
 * plans they can change to and functions to get plans by Id and set a new plan
 */
const usePlan = (): PlanProps => {
  const { planId: selectedPlanId } = useParams()
  const { data, loading } = useQuery<UsePlanQuery>(USE_PLAN_QUERY, {
    variables: {
      caloriesMultipleOf: 10,
      lowerPercentage: 90,
      upperPercentage: 110
    },
    onError: (error) => {
      Sentry.captureException(`Error with USE_PLAN_QUERY query`, {
        extra: { error },
        tags: {
          product: Sentry.Product.Account
        }
      })
    }
  })
  const { user } = data || {}
  const [updatePlanOption, { loading: updating }] = useMutation(
    UPDATE_PLAN_MUTATION,
    {
      update(cache, { data }) {
        const { subscriptionPlanUpdate } = data

        if (subscriptionPlanUpdate) {
          const { plan } = subscriptionPlanUpdate.subscription

          const cacheid = cache.identify({
            __typename: 'Plan',
            id: plan.id
          })
          cache.modify({
            id: cacheid,
            fields: {
              gramsPerDay: () => plan.gramsPerDay,
              durationInDays: () => plan.durationInDays,
              pouchSize: () => plan.pouchSize
            }
          })
        }
      },
      onError: (error) => {
        Sentry.captureException(`Error with UPDATE_PLAN_MUTATION mutation`, {
          extra: { error },
          tags: {
            product: Sentry.Product.Account
          }
        })
      }
    }
  )

  const { subscription, dogs } = user || {}
  const {
    planOptions: plans,
    plan,
    idealPlan,
    recurringOrder: recurring
  } = subscription || {}
  const { setErrorNotification } = useNotifications()
  const namespace = 'account'

  const plansGroupedByPricingCohort = useMemo(() => {
    if (isUndefined(plans)) return undefined

    return plans.reduce((acc, plan: Plan) => {
      acc[plan.servingSize] = [...(acc[plan.servingSize] || []), plan]
      return acc
    }, {} as Record<string, Array<Plan>>)
  }, [plans])

  /**
   * Get a plan by id
   */
  const getPlan = useCallback(
    (planId: string) => plans?.find((plan: Plan) => plan.id === planId),
    [plans]
  )

  /**
   * Get the users currently selected plan
   */
  const currentPlan: Plan | undefined = useMemo(
    () =>
      isUndefined(plans) || isUndefined(plan) ? undefined : getPlan(plan.id),
    [getPlan, plan, plans]
  )

  const selectedPlan = useMemo(() => {
    if (isUndefined(plans)) return undefined
    if (isUndefined(selectedPlanId)) return currentPlan
    return getPlan(selectedPlanId) ?? currentPlan
  }, [currentPlan, getPlan, plans, selectedPlanId])

  /**
   * Get the users current recurring order items
   */
  const recurringOrder: RecurringOrder | undefined = useMemo(
    () => (isUndefined(subscription) ? undefined : recurring),
    [recurring, subscription]
  )

  const plansInCurrentPricingCohort = useMemo(() => {
    if (isUndefined(plansGroupedByPricingCohort) || isUndefined(selectedPlan))
      return undefined

    return plansGroupedByPricingCohort[selectedPlan.servingSize]
  }, [selectedPlan, plansGroupedByPricingCohort])

  /**
   * Get a list of plans available to this user based on a daily serving size and filtered
   * by their current plan duration and servings
   * Brought over from the app - src/screens/PupspaceScreen/Plan/machines/planManagementWizard/machine/actions.ts
   */
  const getPlanByDailyServingSize: (
    dailyServingSize: string
  ) => Plan | undefined = useCallback(
    (dailyServingSize: string) => {
      if (isUndefined(selectedPlan) || isUndefined(plansGroupedByPricingCohort))
        return

      // Attempt to get the plan that matches the current duration in days/servings
      return plansGroupedByPricingCohort[dailyServingSize].find(
        (plan: Plan) => {
          return (
            (selectedPlan.servingType === plan.servingType &&
              plan.durationInDays === selectedPlan.durationInDays &&
              plan.numberOfServings === selectedPlan.numberOfServings) ||
            (selectedPlan.servingType === Serving.standard_serving &&
              plan.servingType === Serving.single_serve &&
              plan.durationInDays * 2 === selectedPlan.durationInDays &&
              plan.numberOfServings * 2 === selectedPlan.numberOfServings) ||
            (selectedPlan.servingType === Serving.single_serve &&
              plan.servingType === Serving.standard_serving &&
              plan.durationInDays / 2 === selectedPlan.durationInDays &&
              plan.numberOfServings / 2 === selectedPlan.numberOfServings)
          )
        }
      )
    },
    [selectedPlan, plansGroupedByPricingCohort]
  )

  /**
   * Returns an array of valid plans for the user based on the duration/servings
   * in their currently selected plan.
   */
  const dailyServingSizeOptions: Plan[] | undefined = useMemo(() => {
    if (isUndefined(plansGroupedByPricingCohort)) return
    return Array.from(new Set(Object.keys(plansGroupedByPricingCohort)))
      .sort((a, b) => Number(a) - Number(b))
      .map((size) => getPlanByDailyServingSize(size))
      .filter((item) => item) as Plan[] // filter out undefined
  }, [getPlanByDailyServingSize, plansGroupedByPricingCohort])

  /**
   * Get the index of the currently selected plan from the daily serving size options
   */
  const dailyServingSizeIndex = useMemo(
    () =>
      isUndefined(selectedPlan) || isUndefined(dailyServingSizeOptions)
        ? undefined
        : dailyServingSizeOptions.findIndex(
            (option) => option.servingSize === selectedPlan.servingSize
          ),
    [selectedPlan, dailyServingSizeOptions]
  )

  /**
   * Get a list of serving and frequency options filtered by the currently selected plan
   * (Brought across from old logic)
   */
  const frequencyOptions = useMemo(() => {
    if (isUndefined(plansInCurrentPricingCohort) || isUndefined(selectedPlan))
      return

    const sortedSelectedPlanOptions = sortBy(plansInCurrentPricingCohort, [
      'numberOfPouches',
      'durationInDays'
    ])

    return sortedSelectedPlanOptions.filter(
      ({ durationInDays, numberOfPouches, id }: Plan): boolean => {
        // For customers who are on Admin plans (7 pouches) we need to ensure that
        // they are able to see their current plan in the range of options.
        // For those customers, we don't filter the plans that have 7 pouches and for
        // customers who are not on a 7 pouch plan, filter as normal.
        // NOTE: This old logic was copied over
        return (
          id === selectedPlan.id ||
          (durationInDays !== 7 && numberOfPouches !== 7)
        )
      }
    )
  }, [selectedPlan, plansInCurrentPricingCohort])

  /**
   * Get the serving and frequency filtered by the currently selected plan
   */
  const frequencyOptionsByNumberOfPouches: Array<FrequencyOption> | undefined =
    useMemo(() => {
      if (isUndefined(frequencyOptions)) return

      return sortedUniqBy(
        sortBy(frequencyOptions, ['numberOfPouches']),
        'numberOfPouches'
      ).map((plan) => ({
        numberOfPouches: plan.numberOfPouches,
        pouchSize: plan.pouchSize,
        options: frequencyOptions.filter(
          (option) => plan.numberOfPouches === option.numberOfPouches
        )
      }))
    }, [frequencyOptions])

  const canChangeDeliveryFrequency: boolean | undefined = useMemo(() => {
    if (isUndefined(user)) return

    return user.subscription.deliveriesReceived > 0
  }, [user])

  /**
   * Update the customers plan
   */
  const setPlan: (planId: string) => Promise<FetchResult> = useCallback(
    (planId: string) =>
      updatePlanOption({
        variables: {
          userId: user?.id,
          planId,
          numOfBoxes: 3
        },
        onError: () => {
          setErrorNotification({
            text: 'serving_size.notifications.error',
            namespace
          })
        }
      }),
    [setErrorNotification, updatePlanOption, user?.id]
  )

  const dogPlans = useMemo(() => {
    if (!dogs) return

    return dogs.map((dog) => {
      const plan = selectedPlan?.gramsPerDayPerDogs?.find(
        (p) => p.dogId === dog.id
      )
      return {
        ...dog,
        gramsPerDay: plan?.gramsPerDay ?? -1
      }
    })
  }, [dogs, selectedPlan?.gramsPerDayPerDogs])

  return {
    dailyServingSizeOptions,
    dailyServingSizeIndex,
    frequencyOptionsByNumberOfPouches,
    currentPlan,
    idealPlan,
    recurringOrder,
    getPlan: !isUndefined(plans) ? getPlan : undefined,
    setPlan: !isUndefined(user) ? setPlan : undefined,
    canChangeDeliveryFrequency,
    updating,
    loading,
    dogPlans
  }
}

export { Plan, RecurringOrder, FrequencyOption }
export default usePlan
