// @noflow
import React from 'react'

import {
  ICON_COLOURS,
  IconColours
} from '@/components/elements/atoms/Icon/Icon'

type Props = {
  size: number
  accentColour: IconColours
  width?: number | string
}

const ErrorCircle = ({ size, accentColour, width }: Props): JSX.Element => {
  return (
    <svg
      fill={ICON_COLOURS[accentColour]}
      viewBox="0 0 12 12"
      height={size}
      width={width || '100%'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 0C2.68548 0 0 2.68548 0 6C0 9.31452 2.68548 12 6 12C9.31452 12 12 9.31452 12 6C12 2.68548 9.31452 0 6 0ZM5.99999 10.8387C3.32661 10.8387
          1.16129 8.67338 1.16129 5.99999C1.16129 3.32661 3.32661 1.16129 5.99999 1.16129C8.67338 1.16129 10.8387 3.32661 10.8387 5.99999C10.8387 8.67338
          8.67338 10.8387 5.99999 10.8387ZM6.95806 6L8.4629 4.49516C8.57661 4.38145 8.57661 4.19758 8.4629 4.08387L7.91613 3.5371C7.80242 3.42339 7.61855
          3.42339 7.50484 3.5371L6 5.04193L4.49516 3.5371C4.38145 3.42339 4.19758 3.42339 4.08387 3.5371L3.5371 4.08387C3.42339 4.19758 3.42339 4.38145
          3.5371 4.49516L5.04193 6L3.5371 7.50484C3.42339 7.61855 3.42339 7.80242 3.5371 7.91613L4.08387 8.4629C4.19758 8.57661 4.38145 8.57661 4.49516
          8.4629L6 6.95806L7.50484 8.4629C7.61855 8.57661 7.80242 8.57661 7.91613 8.4629L8.4629 7.91613C8.57661 7.80242 8.57661 7.61855 8.4629 7.50484L6.95806 6Z"
        fill={ICON_COLOURS[accentColour]}
      />
    </svg>
  )
}

export default ErrorCircle
