import React, { useCallback, useMemo } from 'react'

import CheckBox from '@/components/elements/atoms/CheckBox/CheckBox'
import Text from '@/components/elements/atoms/Text/Text'
import FlavourImage from '@/components/shared/elements/FlavourImage'

import STYLES from '../../InputsScreen.module.sass'

type ConfirmationBoxProps = {
  label: string
  checked: boolean
  padding?: boolean
  setValue: (booleanValue: boolean) => void
  thumbnailSrc?: string
}

const ConfirmationBox = ({
  label,
  checked,
  padding,
  setValue,
  thumbnailSrc
}: ConfirmationBoxProps): JSX.Element => {
  const onChange = useCallback(
    (checkBoxId: number, newValue: boolean) => {
      setValue(newValue)
    },
    [setValue]
  )

  const onLabelClick = useCallback(() => {
    setValue(!checked)
  }, [setValue, checked])

  const onLabelKeyUp = useCallback(
    (e) => {
      if (e.key === '32' || e.key === '13') {
        setValue(!checked)
      }
    },
    [setValue, checked]
  )

  const classnames = useMemo(() => {
    return padding
      ? STYLES.confirmationBox + ' ' + STYLES.padding
      : STYLES.confirmationBox
  }, [padding])

  return (
    <div
      role="presentation"
      className={classnames}
      onClick={onLabelClick}
      onKeyUp={onLabelKeyUp}
    >
      <div className={STYLES.wrapper}>
        <CheckBox
          defaultValue={checked}
          id={0}
          tabIndex={0}
          onChange={onChange}
        />
        <Text
          text={label}
          colour={'brandBlue500'}
          translate={false}
          margin={false}
        />
      </div>
      {thumbnailSrc && (
        <FlavourImage
          flavour={{
            name: label,
            themeColour: 'white',
            image: {
              src: thumbnailSrc
            }
          }}
          extraClasses={STYLES.image}
        />
      )}
    </div>
  )
}

export default ConfirmationBox
