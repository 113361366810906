/* eslint-disable i18next/no-literal-string */
// @noflow
import { Factory } from 'fishery'

// eslint-disable-next-line no-restricted-imports
import type { Answer, Option, Question, Survey } from '@/components/types'

const AnswerFactory = Factory.define<Answer>(({ sequence }) => ({
  optionId: sequence,
  questionId: sequence,
  value: sequence
}))

const OptionFactory = Factory.define<Option>(({ sequence }) => ({
  id: sequence,
  text: `Option ${sequence}`,
  image: {
    src: 'https://d1qzwxap7t1x49.cloudfront.net/packs/media/bowls/sad-bowl-7d194e24.svg',
    key: `${sequence}`
  },
  goodOption: false
}))

const QuestionFactory = Factory.define<Question>(({ sequence }) => ({
  id: sequence,
  text: `Question {{user.dogs.0.name}} ${sequence}`,
  questionType: 'multiple_choice',
  options: OptionFactory.buildList(3),
  ctaText: '',
  ctaDestination: '',
  order: sequence
}))

export default Factory.define<Survey>(({ sequence }) => ({
  id: sequence,
  slug: 'survey-one',
  finishedUrl: '/dashboard',
  startingUrl: '/dashboard',
  finishedMessage: 'Loading Results...',
  name: `Survey ${sequence}`,
  questions: QuestionFactory.buildList(1).concat(
    QuestionFactory.buildList(1, { questionType: 'rating' }),
    QuestionFactory.buildList(1, { questionType: 'multiple_choice' })
  )
}))

export { AnswerFactory, QuestionFactory }
