// @noflow
import classnames from 'classnames'
import isNil from 'lodash/isNil'
import React, { RefObject, useEffect } from 'react'

import useElementSize from '@/hooks/useElementSize'

// Styles
import STYLES from './FixedBase.module.sass'

type AllowedBgColors =
  | 'brandWhite'
  | 'brandWhite90'
  | 'brandYellow200'
  | 'cardboardBrown'

type Props = {
  container?: RefObject<HTMLDivElement>
  children: JSX.Element | JSX.Element[]
  backgroundColor?: AllowedBgColors
  radiusBottomLeft?: boolean
  radiusBottomRight?: boolean
  radiusTopLeft?: boolean
  radiusTopRight?: boolean
  dropShadow?: boolean
}

/**
 * A fixed container aligned to the bottom of the viewport
 * @param container - optional, if a ref is passed the component will reserve space
 *                    for itself by adding padding equal to its height to the specified component
 * @param children
 * @param backgroundColor
 * @constructor
 */
const FixedBase = ({
  container,
  children,
  backgroundColor = 'brandWhite',
  radiusBottomLeft = false,
  radiusBottomRight = false,
  radiusTopLeft = false,
  radiusTopRight = false,
  dropShadow = false
}: Props): JSX.Element => {
  const {
    setRef,
    size: { height }
  } = useElementSize()

  const cn = classnames(STYLES.wrapper, STYLES[backgroundColor], {
    [STYLES.radiusBottomLeft]: radiusBottomLeft,
    [STYLES.radiusBottomRight]: radiusBottomRight,
    [STYLES.radiusTopLeft]: radiusTopLeft,
    [STYLES.radiusTopRight]: radiusTopRight,
    [STYLES.dropShadow]: dropShadow
  })

  useEffect(() => {
    if (!isNil(container) && !isNil(container.current)) {
      // eslint-disable-next-line i18next/no-literal-string
      container.current.style.paddingBottom = `${height}px`
    }
  }, [height, container])

  return (
    <div className={cn} ref={setRef}>
      {children}
    </div>
  )
}

export type { Props }

export { FixedBase }
