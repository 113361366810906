import React, { ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import STYLES from './RouteTransition.module.sass'

type Props = {
  children: ReactNode
}

/**
 * Provide a transition in when mounting the children of a new route
 * @param children
 * @constructor
 */
const RouteTransition = ({ children }: Props): JSX.Element => {
  const location = useLocation()

  return (
    <TransitionGroup
      component={null}
      className={STYLES.routetransitioncontainer}
    >
      <CSSTransition
        key={location.key}
        classNames="routetransition"
        timeout={{
          enter: 300,
          exit: 0
        }}
      >
        {children}
      </CSSTransition>
    </TransitionGroup>
  )
}

export default RouteTransition
