/* eslint-disable react/jsx-no-bind */
// @noflow

/* eslint-disable i18next/no-literal-string */
import { useLocalisation } from '@/context/localisation'
import i18next from 'i18next'
import upperFirst from 'lodash/upperFirst'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { possessive, toLocalisedSentence } from '@/utils/StringHelper'

import BREAKPOINTS from '@/constants/Breakpoints'

import useLocalStorage from '@/hooks/useLocalStorage'
import { useQueryWithPolling } from '@/hooks/useQueryWithPolling'
import useWindowSize from '@/hooks/useWindowSize'

import { Actions } from './components/Actions/Actions'
import { ProgressBar } from './components/ProgressBar'
import Card from '@/components/elements/atoms/Card/Card'
import FlatButton from '@/components/elements/atoms/FlatButton/FlatButton'
import Image from '@/components/elements/atoms/Image/Image'
import Text from '@/components/elements/atoms/Text/Text'
import { Order } from '@/components/pages/Dashboard/components/upcomingBoxes/utilities'

import STYLES from './DeliveryTracking.module.sass'

import { BOX_TRACKING_QUERY } from './queries/boxDeliveryTrackingQuery'
import { NCO_TRACKING_QUERY } from './queries/ncoDeliveryTrackingQuery'

import type {
  BoxTrackingQuery,
  BoxTrackingQueryVariables
} from './queries/__generated__/BoxTrackingQuery'
import type {
  NcoTrackingQuery,
  NcoTrackingQueryVariables
} from './queries/__generated__/NcoTrackingQuery'
import { Status } from '@/types'

import { useTransformedDeliveryStatus } from './hooks/useTransformedDeliveryStatus'

type OrderQuery = BoxTrackingQuery | NcoTrackingQuery
type OrderQueryVariables = BoxTrackingQueryVariables | NcoTrackingQueryVariables

type Props = {
  order: Order
  deliveredToday: boolean
}

const DeliveryTracking = ({
  order,
  deliveredToday
}: Props): JSX.Element | null => {
  const { type: orderType, id } = order
  const { windowWidth } = useWindowSize()
  const { t } = useTranslation('organisms')
  const { format } = useLocalisation()

  const isMobile = windowWidth < BREAKPOINTS.md

  const [deliveryState, setDeliveryState] = useLocalStorage(
    `deliveryState-${orderType}-${id}`,
    ''
  )

  const ORDER_QUERY =
    orderType === 'nonCoreOrder' ? NCO_TRACKING_QUERY : BOX_TRACKING_QUERY

  const { loading, data, error } = useQueryWithPolling<
    OrderQuery,
    OrderQueryVariables
  >(
    ORDER_QUERY,
    {
      variables: { boxId: id }
    },
    { disablePolling: !deliveredToday, stopPollingOnUnmount: true }
  )

  const consignment = (() => {
    if (!data) {
      return null
    }

    const { user } = data

    if ('subscription' in user && orderType === 'box') {
      return user.subscription.box?.consignment
    } else if ('nonCoreOrder' in user && orderType === 'nonCoreOrder') {
      return user.nonCoreOrder.orderParts[0].consignment
    } else {
      return null
    }
  })()

  const status = consignment?.status ?? Status.printed

  const {
    inProgress,
    completedWithSuccess,
    deliveryProblem,
    noProgressBar,
    showDescriptionText,
    showActions
  } = useTransformedDeliveryStatus(status)

  const formattedDate =
    format &&
    format(new Date(consignment?.updatedAt ?? new Date()), 'd LLL HH:mm aaa')

  const dogsDetails = (() => {
    if (!data?.user.dogs) {
      return null
    }

    const {
      user: { dogs }
    } = data

    const joinedDogsNames = toLocalisedSentence({
      arr: dogs.map(({ name }): string => upperFirst(name)) ?? [],
      lng: i18next.language
    })
    const joinedPossessiveDogsNames = possessive(
      joinedDogsNames,
      i18next.language
    )

    const pronounContext = dogs.length > 1 ? 'plural' : dogs[0].gender

    return {
      joinedDogsNames,
      joinedPossessiveDogsNames,
      pronounContext
    }
  })()

  const deliveryStateImage = (() => {
    const defaultImage = {
      slug: 'open-box-of-butternut-meals-illustration',
      alt: t('delivery_tracking.open_box_of_butternut_meals_illustration.alt')
    }

    switch (true) {
      case inProgress: {
        return defaultImage
      }
      case completedWithSuccess: {
        return {
          slug: 'successful-delivery-box',
          alt: t('delivery_tracking.successful_delivery_box.alt')
        }
      }
      case deliveryProblem: {
        return {
          slug: 'danger-box',
          alt: t('delivery_tracking.danger_box.alt')
        }
      }
      default: {
        return defaultImage
      }
    }
  })()

  const handleTrackOrder = () => {
    if (consignment?.trackingUrl) {
      window.open(consignment.trackingUrl, '_blank')
    }
  }

  if ((!data && !loading) || error) {
    return null
  }

  return (
    <Card
      variant="brandYellow200"
      skeleton={{ isLoading: loading }}
      className={STYLES.wrapper}
      padding={isMobile ? 16 : 32}
      mask
    >
      <div
        className={`${STYLES.content} ${
          !showActions ? STYLES.contentNoActions : ''
        }`}
      >
        {consignment?.trackingUrl && (
          <div className={STYLES.trackButton}>
            <FlatButton
              onClick={handleTrackOrder}
              variant="yellow300"
              identifier="delivery_tracking.track_box"
              text={{
                text: `delivery_tracking.track_order`,
                namespace: 'organisms',
                translate: true
              }}
            />
          </div>
        )}
        <div className={STYLES.headerWrapper}>
          <Text
            text={formattedDate || ''}
            translate={false}
            colour="brandBlue400"
            margin={false}
          />
          <div className={STYLES.headerTextWrapper}>
            <Text
              text={`delivery_tracking.${status}.header`}
              namespace="organisms"
              variables={{
                possessiveDogName: dogsDetails?.joinedPossessiveDogsNames ?? '',
                carrierName: consignment?.carrierName ?? 'loading',
                context: data?.user?.dogs?.length === 1 ? '' : 'plural'
              }}
              variant="display20"
              margin={false}
            />
            {(showActions || isMobile) && (
              <Image
                alt={deliveryStateImage.alt}
                slug={deliveryStateImage.slug}
                image={{
                  width: 100,
                  height: 100
                }}
              />
            )}
          </div>
        </div>
        <div>
          {showDescriptionText && (
            <Text
              text={`delivery_tracking.${status}.text`}
              namespace="organisms"
              variables={{ context: deliveredToday ? 'today' : 'tomorrow' }}
            />
          )}
          {!noProgressBar && <ProgressBar status={status} />}
        </div>
      </div>
      {!showActions && !isMobile && (
        <Image
          alt={deliveryStateImage.alt}
          slug={deliveryStateImage.slug}
          image={{
            width: 150,
            height: 150
          }}
          className={STYLES.noActionsImage}
        />
      )}
      {showActions && (
        <Actions
          status={status}
          dogsDetails={dogsDetails}
          boxId={id}
          deliveryState={deliveryState}
          setDeliveryState={setDeliveryState}
        />
      )}
    </Card>
  )
}

export type { Props, OrderQuery }

export { DeliveryTracking }
