// @flow

import * as React from 'react'
import { format } from 'date-fns'
import { connect } from 'react-redux'
import type { Dispatch } from 'redux'
import capitalize from 'lodash/capitalize'

import type { BoxStatus } from '@/shared_types/graphql/types/enums/box/boxStatus'
import * as THUNKS from '../../thunks'
import * as ACTIONS from '../../actions'
import type { State } from '../../index'
import type {
  DeliveriesDetails,
  DeliveryDetails,
  DogDetails,
  RecurringExtraProduct
} from './../../message_types'
import { deliveriesIcons } from '../../asset-imports/icons'

type PresentationalProps = {|
  deliveriesDetails: DeliveriesDetails,
  isFetching: boolean,
  userId: number,
  dogsDetails: Array<DogDetails>,
  recurringExtraProducts: Array<RecurringExtraProduct>
|}

type ActionProps = {|
  fetchCalendar: ((string, 'month_for' | 'month_after' | 'month_before', number) => void),
  getDeliveriesDetails: ((number) => void),
  openDeliveryDateCalendar: ((DeliveryDetails) => void),
  pushBoxAWeek: ((number) => void),
  updateTopIndex: ((number) => void)
|}

type Props =
  & PresentationalProps
  & ActionProps

const mapStateToProps = (state: State): PresentationalProps => {
  const { deliveriesDetails, subscriptionDetails, selectedUserIds, fetchingStatuses, dogsDetails } = state
  const { userId } = selectedUserIds
  const isFetching = fetchingStatuses.deliveriesDetails
  const { recurring_extra_products } = subscriptionDetails

  return {
    deliveriesDetails,
    userId,
    isFetching,
    dogsDetails,
    recurringExtraProducts: recurring_extra_products
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => {
  const updateTopIndex = (newIndex: number): void => {
    dispatch(ACTIONS.updateTopIndex(newIndex))
  }
  const getDeliveriesDetails = (userId: number): void => {
    dispatch(THUNKS.getDeliveriesDetails(userId))
  }
  const pushBoxAWeek = (boxId: number): void => {
    dispatch(THUNKS.pushBoxNWeeks(boxId, 1))
  }
  const openDeliveryDateCalendar = (delivery: DeliveryDetails): void => {
    dispatch(ACTIONS.openDeliveryDateCalendar(delivery))
  }
  const fetchCalendar = (date: string, direction: 'month_for' | 'month_after' | 'month_before', boxId: number): void => {
    dispatch(THUNKS.fetchCalendar(date, direction, boxId))
  }
  return {
    updateTopIndex,
    getDeliveriesDetails,
    openDeliveryDateCalendar,
    pushBoxAWeek,
    fetchCalendar
  }
}

const renderTransition = (isFetching: boolean): string => isFetching ? 'loading' : 'loaded'

const renderLoadingIcon = (isFetching: boolean): ?React.Element<'div'> => {
  if (isFetching) {
    return (
      <div className="loading-ring">
        <span />
        <span />
        <span />
        <span />
      </div>
    )
  }
}

const renderOrderIssueButton = (box_id: number, openOrderIssue: (box_id: number) => void): React.Node => {
  const handleOrderClick = (e: SyntheticEvent<HTMLInputElement>): void => {
    e.preventDefault()
    e.stopPropagation()

    openOrderIssue(box_id)
  }
  return (
    <a
      className='card__button button--blue'
      href={`/admin/order-issues/reports/box/for/${box_id}`}
      // eslint-disable-next-line react/jsx-no-bind
      onClick={handleOrderClick}
    >
      { 'Order Issues' }
    </a>
  )
}

const renderButtons = (delivery: DeliveryDetails, pushBoxAWeek: (boxId: number) => void, openOrderIssue: (box_id: number) => void): ?React.Node => (
  <span>
    { renderOrderIssueButton(delivery.box_id, openOrderIssue) }
    {
      !delivery.date_details.delivered && !delivery.date_details.past_cut_off_date && delivery.date_details.pushable && (
        <button
          className='card__button button--blue'
          // eslint-disable-next-line react/jsx-no-bind
          onClick={(): void => pushBoxAWeek(delivery.box_id)}
          type='button'
        >
          { 'Push 1 week' }
        </button>
      )
    }
  </span>
)

const statusColor = (
  status: BoxStatus
): string => {
  const colors = {
    active: 'green',
    skipped: 'yellow',
    void: 'red'
  }

  return colors[status] || 'grey'
}

const renderLabels = (
  delivery: DeliveryDetails,
  isDogBirthdayBox: boolean,
  recurringExtraProducts: Array<RecurringExtraProduct>
): ?Array<React.Node> => {
  const refunds = delivery.refunds
  const discounts = delivery.discounts

  const labels = []

  labels.push(
    <span
      className={`label label--${statusColor(delivery.box_status)}`}
      key='box_status'
    >
      { capitalize(delivery.box_status) }
    </span>
  )

  if (delivery.is_next_box) {
    labels.push(
      <span
        className='label label--green'
        key='next_box'
      >
        { 'Next Box' }
      </span>
    )
  }
  if (delivery.is_one_off) {
    labels.push(
      <span
        className='label label--orange'
        key='one_off_box'
      >
        { 'One-off Box' }
      </span>
    )
  }
  if (refunds.length > 0) {
    const allrefunds = refunds.map((refund: $ReadOnly<{| value: string, basis: 'percentage' | 'total' |}>, i: number): React.Node => {
      const refundString = refund.basis === 'percentage' ? `${refund.value}%` : `${refund.value}`
      const key = i
      return (
        <span
          className='label label--purple'
          key={key}
        >
          { `${refundString} refunded` }
        </span>)
    })
    labels.push(allrefunds)
  }
  if (discounts.length > 0) {
    const alldiscounts = discounts.map((discount: $ReadOnly<{| value: string, basis: 'percentage' | 'total' |}>, i: number): React.Node => {
      const discountString = discount.basis === 'percentage' ? `${discount.value}%` : `${discount.value}`
      const key = i
      return (
        <span
          className='label label--purple'
          key={key}
        >
          { `${discountString} discount` }
        </span>
      )
    })
    labels.push(alldiscounts)
  }
  if (delivery.box_type !== 'normal') {
    labels.push(
      <span
        className='label label--dark-blue'
        key='box_type'
      >
        { delivery.box_type }
      </span>)
  }
  if (delivery.invoice_status && delivery.date_details.delivered) {
    labels.push(
      <span
        className='label label--dark-blue'
        key='box_type'
      >
        { delivery.invoice_status }
      </span>)
  }
  if (delivery.number_of_issues_recorded > 0) {
    labels.push(
      <span
        className='label label--red'
        key='issues'
      >
        { `${delivery.number_of_issues_recorded} ${(delivery.number_of_issues_recorded === 1 ? ' issue' : ' issues')}` }
      </span>
    )
  }
  if (delivery.editable) {
    if (delivery.has_extra_products && recurringExtraProducts.length > 0) {
      labels.push(
        <span
          className='label label--purple'
          key={delivery.box_id}
        >
          { 'Subscription extras' }
        </span>
      )
    }
    if (delivery.has_one_off_extra_products) {
      labels.push(
        <span
          className='label label--purple'
          key={delivery.box_id}
        >
          { 'One-off extras' }
        </span>
      )
    }
  } else {
    if (delivery.has_extra_products) {
      labels.push(
        <span
          className='label label--purple'
          key={delivery.box_id}
        >
          { 'Has extras' }
        </span>
      )
    }
  }
  if (isDogBirthdayBox) {
    labels.push(
      <span
        className='label label--birthday'
        key={delivery.box_id}
      >
        { '🎂 Birthday Box' }
      </span>
    )
  }
  if (delivery.pricing_curve_type === 'convenience_pricing') {
    labels.push(
      <span
        className='label label--yellow'
        key={delivery.box_id}
      >
        { 'Convenience Pricing' }
      </span>
    )
  }
  if (delivery.is_boosted) {
    labels.push(
      <span
        className='label label--green'
        key={delivery.box_id}
      >
        { delivery.boosted_type }
      </span>
    )
  }

  return labels
}

const renderFlag = (
  delivery: DeliveryDetails,
  fetchCalendar: $PropertyType<ActionProps, 'fetchCalendar'>,
  openDeliveryDateCalendar: (delivery: DeliveryDetails) => void,
  openCalendars: (
    delivery: DeliveryDetails,
    fetchCalendar: $PropertyType<ActionProps, 'fetchCalendar'>,
    openDeliveryDateCalendar: (delivery: DeliveryDetails) => void) => void
): React.Node => {
  const handleCalendarClick = (): void => {
    openCalendars(delivery, fetchCalendar, openDeliveryDateCalendar)
  }

  let flag = ''
  if (delivery.date_details.delivered) {
    flag = (
      <span className='flag'>
        <img
          alt='Checkmark icon'
          className='icon'
          src={deliveriesIcons.checkCircle}
        />
        { 'DELIVERED' }
      </span>
    )
  }
  if (delivery.date_details.past_cut_off_date) {
    flag = (
      <span className='flag'>
        <img
          alt="Calendar icon"
          className='icon'
          src={deliveriesIcons.cutOffDate}
        />
        { 'CUT-OFF REACHED' }
      </span>)
  }
  if (!delivery.fulfilment_triggered) {
    flag = (
      <button
        className='card__button button--grey button--no-shadow'
        // eslint-disable-next-line react/jsx-no-bind
        onClick={handleCalendarClick}
        type='button'
      >
        <img
          alt='Pencil icon'
          className='icon'
          src={deliveriesIcons.edit}
        />
      </button>
    )
  }
  return flag
}

const renderDelivery = (
  delivery: DeliveryDetails,
  pushBoxAWeek: (boxId: number) => void,
  fetchCalendar: $PropertyType<ActionProps, 'fetchCalendar'>,
  openDeliveryDateCalendar: (delivery: DeliveryDetails) => void,
  isDogBirthdayBox: boolean,
  recurringExtraProducts: Array<RecurringExtraProduct>,
  openOrderIssue: (box_id: number) => void,
  openCalendars: (
    delivery: DeliveryDetails,
    fetchCalendar: $PropertyType<ActionProps, 'fetchCalendar'>,
    openDeliveryDateCalendar: (delivery: DeliveryDetails) => void) => void
): React.Element<'div'> => (
  <div
    className="card__box"
    key={delivery.box_number}
  >
    <div className='card__box__row top-row'>
      <div className='top-row__title'>
        <p>
          { `BOX ${delivery.box_number}` }
        </p>
        {
          delivery.editable && (
            <a
              className='card__button button--grey button--no-shadow'
              href={`/admin/ap_orders/${delivery.order_id}/edit`}
              rel="noopener noreferrer"
              target='_blank'
            >
              <img
                alt='Pencil icon'
                className='icon'
                src={deliveriesIcons.edit}
              />
            </a>
          )
        }
      </div>
      <a
        className='card__button button--white'
        href={`/admin/boxes/${delivery.box_id}`}
        rel="noopener noreferrer"
        target='_blank'
      >
        <img
          alt="Eye icon"
          className='icon'
          src={deliveriesIcons.eye}
        />
        { 'View box details' }
      </a>
    </div>
    <div className='card__box__row'>
      <div className='card__row__labels'>
        {
          renderLabels(
            delivery,
            isDogBirthdayBox,
            recurringExtraProducts
          )
        }
      </div>
    </div>
    <div className='card__box__row'>
      <p className='card__row__title'>
        { `Shipping date` }
      </p>
      <p className='flag-container'>
        { delivery.date_details.shipping_date ? format(Date.parse(delivery.date_details.shipping_date), 'EEEE, MMMM do yyyy') : 'No shipping date' }
      </p>
    </div>
    <div className='card__box__row'>
      <p className='card__row__title'>
        { `Delivery date` }
      </p>
      <p className='flag-container'>
        { delivery.date_details.delivery_date ? format(Date.parse(delivery.date_details.delivery_date), 'EEEE, MMMM do yyyy') : 'No delivery date' }
        { renderFlag(delivery, fetchCalendar, openDeliveryDateCalendar, openCalendars) }
      </p>
    </div>
    <div className='card__box__row'>
      <p className='card__row__title'>
        { 'Price' }
      </p>
      <p>
        { delivery.prices.charged_price }
      </p>
    </div>
    <div className='card__box__row'>
      <p className='card__row__title'>
        { 'Postcode' }
      </p>
      <p>
        { delivery.address.postcode }
      </p>
    </div>
    {
      delivery.carrier.name &&
        <div className='card__box__row'>
          <p className='card__row__title'>
            { 'Allocated Carrier - Service' }
          </p>
          <p>
            { `${delivery.carrier.name} - ${delivery.carrier.service}` }
          </p>
        </div>
    }
    {
      delivery.tracking_event.description &&
        <div className='card__box__row'>
          <p className='card__row__title'>
            { 'Last tracking event - timestamp' }
          </p>
          <p>
            { `${delivery.tracking_event.description} - ${format(Date.parse(delivery.tracking_event.timestamp), 'EEEE, MMMM do yyyy @ HH:mm')}` }
          </p>
        </div>
    }
    { renderButtons(delivery, pushBoxAWeek, openOrderIssue) }
  </div>
)

const showPreviousDeliveries = (deliveriesDetails: DeliveriesDetails, updateTopIndex: (number) => void): void => {
  const currentIndex = deliveriesDetails.deliveryInTopOfViewIndex
  const newIndex = currentIndex <= 3 ? 0 : currentIndex - 3
  updateTopIndex(newIndex)
}

const showNextDeliveries = (deliveriesDetails: DeliveriesDetails, updateTopIndex: (number) => void): void => {
  const currentIndex = deliveriesDetails.deliveryInTopOfViewIndex
  const deliveriesLength = deliveriesDetails.deliveries.length
  const newIndex = (deliveriesLength - 1) - (currentIndex + 2) <= 2 ? deliveriesLength - 3 : currentIndex + 3
  updateTopIndex(newIndex)
}

const renderDeliveries = (
  deliveriesDetails: DeliveriesDetails,
  startIndex: number,
  pushBoxAWeek: (boxId: number) => void,
  fetchCalendar: $PropertyType<ActionProps, 'fetchCalendar'>,
  openDeliveryDateCalendar: (delivery: DeliveryDetails) => void,
  dogsDetails: Array<DogDetails>,
  recurringExtraProducts: Array<RecurringExtraProduct>,
  openOrderIssue: (box_id: number) => void,
  openCalendars: (
    delivery: DeliveryDetails,
    fetchCalendar: $PropertyType<ActionProps, 'fetchCalendar'>,
    openDeliveryDateCalendar: (delivery: DeliveryDetails) => void) => void
): React.Node => {
  if (deliveriesDetails.deliveries.length > 0) {
    const deliveriesToRender = deliveriesDetails.deliveries.slice(startIndex, startIndex + 3)
    let addBirthdayLabel = false
    let previousMonth = -1
    return deliveriesToRender.map((delivery: DeliveryDetails): React.Node => {
      const deliveryMonth = format(Date.parse(delivery.date_details.delivery_date), 'M')
      if (deliveryMonth !== previousMonth) {
        previousMonth = deliveryMonth
        addBirthdayLabel = false
      }
      const isDogBirthdayBox = dogsDetails.some(({ summary_details }: DogDetails): boolean => {
        return deliveryMonth === format(Date.parse(summary_details.date_of_birth), 'M') && !addBirthdayLabel
      })
      if (isDogBirthdayBox) {
        addBirthdayLabel = true
      }
      return renderDelivery(
        delivery,
        pushBoxAWeek,
        fetchCalendar,
        openDeliveryDateCalendar,
        isDogBirthdayBox,
        recurringExtraProducts,
        openOrderIssue,
        openCalendars
      )
    })
  } else {
    return (
      <p className="no-results">
        { `No Deliveries` }
      </p>
    )
  }
}

const DeliveriesDetailsComponent = ({
  deliveriesDetails,
  isFetching,
  userId,
  getDeliveriesDetails,
  fetchCalendar,
  openDeliveryDateCalendar,
  updateTopIndex,
  pushBoxAWeek,
  dogsDetails,
  recurringExtraProducts
}: Props): React.Node => {
  const { numberOfBoxesDelivered } = deliveriesDetails

  // Compare number of delivered boxes to the actual box number
  // and take the box as last delivered
  const lastDeliveredBoxIndex = React.useMemo((): number => (
    deliveriesDetails.deliveries.findIndex((
      delivery: DeliveryDetails
    ): boolean => delivery.box_number === numberOfBoxesDelivered)
  ), [numberOfBoxesDelivered, deliveriesDetails.deliveries])

  React.useEffect((): void => {
    getDeliveriesDetails(userId)
  }, [getDeliveriesDetails, userId])

  React.useEffect((): void => {
    // If numberOfBoxesDelivered equals to 0 we will have
    // lastDeliveredBoxIndex = -1. In this case we keep
    // deliveryInTopOfViewIndex = 0
    if (lastDeliveredBoxIndex > 0) {
      updateTopIndex(lastDeliveredBoxIndex)
    }
  }, [lastDeliveredBoxIndex, updateTopIndex])

  const openOrderIssue = React.useCallback((box_id: number): void => {
    // In order to be able to `window.close()` the 49ers tab, we need to
    // open it this way.
    window.open(`/admin/order-issues/reports/box/for/${box_id}`, '_blank')
  }, [])

  const openCalendars = React.useCallback((
    delivery: DeliveryDetails,
    fetchCalendar: $PropertyType<ActionProps, 'fetchCalendar'>,
    openDeliveryDateCalendar: (delivery: DeliveryDetails) => void
  ): void => {
    fetchCalendar(delivery.date_details.fetchable_delivery_date, 'month_for', delivery.box_id)
    openDeliveryDateCalendar(delivery)
  }, [])

  return (
    <div className={`deliveries-detail card__container card__${renderTransition(isFetching)}`}>
      <div className='card__header__container'>
        <h3 className='card__header'>
          { 'Deliveries' }
        </h3>
        { !isFetching &&
          <div className='card__header__tally'>
            <p>
              { `${deliveriesDetails.numberOfBoxesDelivered} received` }
            </p>
            <p>
              { `${deliveriesDetails.numberOfBoxIssues} issues` }
            </p>
          </div> }
      </div>
      <div className='card__body'>
        { renderLoadingIcon(isFetching) }
        {
          renderDeliveries(
            deliveriesDetails,
            deliveriesDetails.deliveryInTopOfViewIndex,
            pushBoxAWeek,
            fetchCalendar,
            openDeliveryDateCalendar,
            dogsDetails,
            recurringExtraProducts,
            openOrderIssue,
            openCalendars
          )
        }
        { deliveriesDetails.deliveries.length > 3 &&
          <div className='card__box__controls'>
            <button
              className='card__button button--grey'
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ (): void => { showNextDeliveries(deliveriesDetails, updateTopIndex) } }
              type='button'
            >
              <img
                alt="Arrow icon"
                className='icon'
                src={deliveriesIcons.arrow}
              />
            </button>
            <button
              className='card__button button--grey'
              // eslint-disable-next-line react/jsx-no-bind
              onClick={(): void => { showPreviousDeliveries(deliveriesDetails, updateTopIndex) }}
              type='button'
            >
              <img
                alt="Arrow icon"
                className='icon'
                src={deliveriesIcons.arrow}
              />
            </button>
          </div> }
      </div>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeliveriesDetailsComponent)
