// @noflow
import { parseISO } from 'date-fns'
import React, { Fragment, useCallback, useMemo } from 'react'
import type { ComponentProps, ReactElement, SyntheticEvent } from 'react'

import STYLES from '../Calendar.module.sass'

import type { CalendarDatesData_calendarDates as CalendarDate } from '../queries/__generated__/CalendarDatesData'

import Calendar, { ForecastedDate } from '../Calendar'

type CalendarProps = ComponentProps<typeof Calendar>

type Props = {
  calendarDates: Array<CalendarDate>
  selectedDate: CalendarProps['selectedDate']
  setSelectedDate: CalendarProps['setSelectedDate']
  handleCustomOnDateChange: CalendarProps['handleCustomOnDateChange']
  forecastedDeliveryDates?: ForecastedDate[]
  adjustForecastedDates: (date: Date) => void
  onlyMoveThisBox?: boolean
}

type CalendarDateCellProps = {
  date: CalendarDate['date']
  deliverable: CalendarDate['deliverable']
} & Pick<
  Props,
  | 'selectedDate'
  | 'setSelectedDate'
  | 'handleCustomOnDateChange'
  | 'forecastedDeliveryDates'
  | 'adjustForecastedDates'
  | 'onlyMoveThisBox'
>

const CalendarDateCell = ({
  date,
  deliverable,
  selectedDate,
  setSelectedDate,
  handleCustomOnDateChange,
  forecastedDeliveryDates,
  adjustForecastedDates,
  onlyMoveThisBox
}: CalendarDateCellProps): ReactElement => {
  const dateVariantClass = useMemo((): string => {
    const today = new Date().toLocaleDateString()

    const isSameDateAsFutureBoxes = (date: Date): boolean | void => {
      if (!forecastedDeliveryDates) return

      return !!forecastedDeliveryDates
        .map((forecastedDate) =>
          parseISO(forecastedDate.forecastedDeliveryDate)
        )
        .find(
          (nextBoxDate: Date): boolean =>
            nextBoxDate.toLocaleDateString() ===
            new Date(date).toLocaleDateString()
        )
    }

    if (today === new Date(date).toLocaleDateString()) {
      return STYLES.dayToday
    } else if (!deliverable) {
      return STYLES.dayNotDeliverable
    } else if (
      selectedDate &&
      new Date(selectedDate).toLocaleDateString() ===
        new Date(date).toLocaleDateString()
    ) {
      return STYLES.daySelected
    } else if (isSameDateAsFutureBoxes(date)) {
      return STYLES.dayFutureDate
    } else {
      return ''
    }
  }, [date, deliverable, forecastedDeliveryDates, selectedDate])

  const dayNumber = useMemo(() => new Date(date).getDay(), [date])
  const dateCopy = useMemo(() => new Date(date).getDate(), [date])

  // Handlers
  const handleSetSelectedDate = useCallback(
    (e: SyntheticEvent<HTMLButtonElement>): void => {
      const newSelectedDate = new Date(e.currentTarget.value)

      setSelectedDate(newSelectedDate)
      !onlyMoveThisBox && adjustForecastedDates(newSelectedDate)

      if (handleCustomOnDateChange) {
        handleCustomOnDateChange(newSelectedDate)
      }
    },
    [
      setSelectedDate,
      onlyMoveThisBox,
      adjustForecastedDates,
      handleCustomOnDateChange
    ]
  )

  return (
    <button
      className={`${STYLES.day} ${dateVariantClass}`}
      type="button"
      disabled={!deliverable}
      style={{ gridColumnStart: dayNumber === 0 ? 7 : dayNumber }}
      value={date.toString()}
      onClick={handleSetSelectedDate}
    >
      {dateCopy}
    </button>
  )
}

const CalendarDates = ({
  calendarDates,
  selectedDate,
  setSelectedDate,
  handleCustomOnDateChange,
  forecastedDeliveryDates,
  adjustForecastedDates,
  onlyMoveThisBox
}: Props): ReactElement => (
  <Fragment>
    {calendarDates.map(
      ({ date, deliverable }: CalendarDate): ReactElement => (
        <CalendarDateCell
          key={date.toString()}
          // Date needs to be formatted to be render properly
          // new Date('2022-01-27').toLocaleString()
          // '26/01/2022, 21:00:00'
          // new Date('2022/01/27').toLocaleString()
          // '27/01/2022, 00:00:00'
          date={date.replace(/-/g, '/')}
          deliverable={deliverable}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          handleCustomOnDateChange={handleCustomOnDateChange}
          forecastedDeliveryDates={forecastedDeliveryDates}
          adjustForecastedDates={adjustForecastedDates}
          onlyMoveThisBox={onlyMoveThisBox}
        />
      )
    )}
  </Fragment>
)

export default CalendarDates
