// @noflow
import { useQuery } from '@apollo/client/react/hooks/useQuery'
import times from 'lodash/times'
import { useState } from 'react'

import {
  Currency,
  Locale,
  formatCurrencyWithoutDecimal
} from '@/utils/currency'
import { percentageValue } from '@/utils/percentage'
import * as Sentry from '@/utils/sentry'

import { DISCOUNT_COPY_QUERY } from '../queries/discountCopyQuery'

import type {
  DiscountCopyQuery_guest_discountCode_discountCodeParts as DiscountCodeParts,
  DiscountCopyQuery
} from '../queries/__generated__/DiscountCopyQuery'

import { DiscountCodePartDiscountBasis } from '../../SignupWizardPage/types/globalTypes'

type DiscountCopyPart = {
  copy: string
  value: DiscountCodeParts['value']
  basis: DiscountCodeParts['discountBasis']
  n: DiscountCodeParts['n']
}

type UseDiscountCopy = {
  fetchDiscountCopy: () => void
  discountCopyParts: Array<DiscountCopyPart>
  loading: boolean
}

const useDiscountCopy = (
  locale: Locale,
  currency: Currency
): UseDiscountCopy => {
  const [discountCopyParts, setDiscountCopyParts] = useState<
    Array<DiscountCopyPart>
  >([])
  const formatDiscount = (
    basis: DiscountCodePartDiscountBasis,
    value: number
  ): string => {
    if (basis === 'percentage') {
      return percentageValue(value)
    } else {
      return formatCurrencyWithoutDecimal(value, {
        locale,
        currency
      })
    }
  }

  const { refetch, loading } = useQuery<DiscountCopyQuery>(
    DISCOUNT_COPY_QUERY,
    {
      onCompleted: ({ guest: { discountCode } }) => {
        if (discountCode) {
          const { discountCodeParts } = discountCode
          // Check for if discount code is n_boxes:2
          const isDoubleDiscount =
            discountCodeParts[0].discountType === 'n_boxes' &&
            discountCodeParts[0].n &&
            discountCodeParts[0].n >= 2

          const formatedDiscountCodeParts: Array<DiscountCopyPart> = []

          if (isDoubleDiscount && discountCodeParts[0].n) {
            const discountPart: DiscountCopyPart = {
              copy: formatDiscount(
                discountCodeParts[0].discountBasis,
                discountCodeParts[0].value
              ),
              value: discountCodeParts[0].value,
              basis: discountCodeParts[0].discountBasis,
              n: discountCodeParts[0].n
            }
            formatedDiscountCodeParts.push(
              ...times(discountCodeParts[0].n).map(() => discountPart)
            )
          } else {
            formatedDiscountCodeParts.push(
              ...discountCode?.discountCodeParts.map(
                ({ value, discountBasis, n }) =>
                  ({
                    copy: formatDiscount(discountBasis, value),
                    value,
                    basis: discountBasis,
                    n
                  } as DiscountCopyPart)
              )
            )
          }

          setDiscountCopyParts(formatedDiscountCodeParts)
        }
      },
      onError: (error) => {
        Sentry.captureException(
          `Error with DISCOUNT_COPY_QUERY on CheckoutPage`,
          {
            extra: {
              error
            },
            tags: {
              product: Sentry.Product.Checkout
            }
          }
        )
      }
    }
  )

  return {
    fetchDiscountCopy: refetch,
    // Sort by n to ensure that the first box discount is always first
    discountCopyParts: discountCopyParts.sort((a, b) => {
      if (a.n === null) return 1 // Treat null as the largest value
      if (b.n === null) return -1 // Treat null as the largest value
      return a.n - b.n
    }),
    loading
  }
}

export default useDiscountCopy
