// @noflow
import React from 'react'

import STYLES from './ProductVariantFrequencies.module.sass'

import type {
  ProductCollectionQuery_productCollection as ProductCollection,
  ProductCollectionQuery,
  ProductCollectionQuery_productCollection_productVariants as ProductVariantType
} from '../../../queries/__generated__/ProductCollectionQuery'

import type {
  SubscriptionStatus,
  VariantDelivery
} from '../../../../../../../../types/index'
import SectionWrapper from '../../SectionWrapper'
import ProductVariantFrequency from '../../molecules/ProductVariantFrequency'
import ProductVariantFrequencyDropDown from '../../molecules/ProductVariantFrequencyDropDown'

type Props = {
  status: SubscriptionStatus
  nonCoreOrderDeliveryFee: ProductCollectionQuery['nonCoreOrderDeliveryFee']
  name: ProductCollection['name']
  productCollectionSlug: ProductCollection['slug']
  selectedProductVariantFrequency: VariantDelivery
  selectedProductVariant: ProductVariantType | null
  setSelectedProductVariantFrequency: (variantDelivery: VariantDelivery) => void
}

const ProductVariantFrequencies = ({
  status,
  nonCoreOrderDeliveryFee,
  name,
  productCollectionSlug,
  selectedProductVariantFrequency,
  selectedProductVariant,
  setSelectedProductVariantFrequency
}: Props): JSX.Element | null => {
  if (!selectedProductVariant) {
    return null
  }

  const { productVariantDeliveryTypes, subscribeAndSaveable } =
    selectedProductVariant

  const shouldSee2Variants =
    productVariantDeliveryTypes.length === 3 && status === 'active'

  return (
    <SectionWrapper
      title={{
        text: 'extras.product_collection.details.product_variant_frequencies.title',
        namespace: 'dashboard'
      }}
      marginBottom
    >
      <div className={STYLES.container}>
        {shouldSee2Variants ? (
          <React.Fragment>
            <ProductVariantFrequency
              key="recurring"
              nonCoreOrderDeliveryFee={nonCoreOrderDeliveryFee}
              name={name}
              deliveryType={'recurring' as VariantDelivery}
              selectedProductVariantFrequency={selectedProductVariantFrequency}
              setSelectedProductVariantFrequency={
                setSelectedProductVariantFrequency
              }
              subscribeAndSaveable={subscribeAndSaveable}
              shouldSee2Variants={shouldSee2Variants}
            />
            <ProductVariantFrequencyDropDown
              key="ode_one-off"
              nonCoreOrderDeliveryFee={nonCoreOrderDeliveryFee}
              name={name}
              selectedProductVariantFrequency={selectedProductVariantFrequency}
              setSelectedProductVariantFrequency={
                setSelectedProductVariantFrequency
              }
              productCollectionSlug={productCollectionSlug}
            />
          </React.Fragment>
        ) : (
          productVariantDeliveryTypes.map(({ deliveryType }) => (
            <ProductVariantFrequency
              key={deliveryType}
              nonCoreOrderDeliveryFee={nonCoreOrderDeliveryFee}
              name={name}
              deliveryType={deliveryType}
              selectedProductVariantFrequency={selectedProductVariantFrequency}
              setSelectedProductVariantFrequency={
                setSelectedProductVariantFrequency
              }
              subscribeAndSaveable={subscribeAndSaveable}
            />
          ))
        )}
      </div>
    </SectionWrapper>
  )
}

export default ProductVariantFrequencies
