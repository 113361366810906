// @noflow
import { Occasions, useOccasion } from '@/context/festiveTheme/festiveTheme'
import { isUndefined } from 'lodash'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { trackEvent } from '@/services/segment'

import XmasDogWithHat from 'assets/images/illustrations/dogs/xmas-dog-with-hat.svg'

import { Wiggle } from '@/components/elements/atoms/Animated/Animated'
import Card, { CardVariantProp } from '@/components/elements/atoms/Card/Card'
import { PillToggle } from '@/components/elements/atoms/PillToggle/PillToggle'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import Text, { TextProps } from '@/components/elements/atoms/Text'

import STYLES from './FestiveThemeCard.module.sass'

type OccasionThemeCardConfig = {
  occasion: Occasions
  cardVariant?: CardVariantProp
  copy: TextProps
  image: string
  imageAlt: string
}

const xmasVariant: OccasionThemeCardConfig = {
  occasion: Occasions.xmas,
  copy: { text: 'festive.xmas.prompt', namespace: 'account' },
  image: XmasDogWithHat,
  imageAlt: 'account:festive.xmas.prompt_image_alt'
}

const themes: OccasionThemeCardConfig[] = [xmasVariant]

const getTheme = (
  occasion: Occasions | undefined
): OccasionThemeCardConfig | undefined =>
  themes.find((theme) => theme.occasion === occasion)

/**
 * Show a toggle to turn on the current occasions unique branding
 * @constructor
 */
const FestiveThemeCard = (): JSX.Element | null => {
  const { occasion, setOptedIn, optedIn } = useOccasion()
  const { t } = useTranslation()

  const toggle = useCallback(() => {
    if (!isUndefined(setOptedIn)) {
      setOptedIn(!optedIn)
      trackEvent('Xmas theme toggled | ' + optedIn ? 'On' : 'Off', {
        component_identifier: 'Festive theme card',
        screen_identifier: 'Home'
      })
    }
  }, [optedIn, setOptedIn])

  if (isUndefined(setOptedIn)) return null

  const theme = getTheme(occasion)

  if (isUndefined(theme)) return null

  const { cardVariant, copy, image, imageAlt } = theme

  return (
    <SectionWrapper
      margin={{
        top: 0
      }}
    >
      <Wiggle repeat={1}>
        <Card variant={cardVariant} shadow>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */}
          <label className={STYLES.toggle}>
            <img alt={t(imageAlt)} src={image} />
            <div className={STYLES.label}>
              <Text
                text={`${copy.text}_${optedIn ? 'leave' : 'enter'}`}
                namespace={copy.namespace}
                bold
                margin={false}
              />
            </div>

            <PillToggle toggled={optedIn} onChange={toggle} />
          </label>
        </Card>
      </Wiggle>
    </SectionWrapper>
  )
}

export default FestiveThemeCard
