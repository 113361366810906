import { gql } from '@apollo/client'

import { PERSONAL_DETAILS_FRAGMENT } from '../fragments/personalDetailsFragment'

const UPDATE_PERSONAL_DETAILS = gql`
  ${PERSONAL_DETAILS_FRAGMENT}
  mutation UpdatePersonalDetails(
    $userId: ID!
    $userDetails: UserDetailsInput!
  ) {
    response: userDetailsUpdate(userId: $userId, userDetails: $userDetails) {
      ...personalDetailsFragment
    }
  }
`

export { UPDATE_PERSONAL_DETAILS }
