import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import SignupScreen from '@/components/pages/Influencers/components/SignupScreen'

const Influencers = (): JSX.Element => (
  <BrowserRouter>
    <Routes>
      <Route path="/influencers/sign_up" element={<SignupScreen />} />
    </Routes>
  </BrowserRouter>
)

export default Influencers
