// @noflow
import React from 'react'
import { useTranslation } from 'react-i18next'

import Text from '@/components/elements/atoms/Text/Text'
import type { Expert as ExpertName } from '@/components/shared/Expert'
import CloudinaryImage, {
  CloudinaryParams
} from '@/components/shared/elements/CloudinaryImage/CloudinaryImage'

import STYLES from './ExpertCard.module.sass'

type Expert = {
  name: ExpertName
  description: string
  imgSrc: string
  imgAlt: string
}
type Props = {
  namespace?: string
  expert: Expert
  imageProps?: CloudinaryParams
}

const ExpertCard = ({ namespace, expert, imageProps }: Props): JSX.Element => {
  const { t } = useTranslation(namespace)
  return (
    <div className={STYLES.container}>
      <CloudinaryImage
        alt={t(expert.imgAlt)}
        image={{
          ...imageProps,
          path: imageProps?.path || expert.imgSrc
        }}
        className={STYLES.image}
      />
      <div className={STYLES.textContainer}>
        <Text
          namespace={namespace}
          text={expert.name}
          margin={false}
          variant="textRegular16"
          colour="brandBlue500"
          element="p"
          bold
          translate={false}
        />
        <br />
        <Text
          namespace={namespace}
          text={expert.description}
          margin={false}
          variant="textRegular16"
          colour="brandBlue500"
          element="p"
        />
      </div>
    </div>
  )
}

export type { Props, Expert }
export default ExpertCard
