// @noflow
import lazyLoad from '@/modules/lazyLoad'
import React from 'react'
import { useTranslation } from 'react-i18next'

import useWindowSize from '@/hooks/useWindowSize'

import RibbonDatesBadge from 'assets/images/icons/badges/oli-juste-dates.svg'

import Image from '@/components/elements/atoms/Image/Image'
import type { Expert as ExpertName } from '@/components/shared/Expert'

import SwiperSlider from '../elements/molecules/SwiperSlider/SwiperSlider'
import { BREAKPOINTS } from '../templates/Base'

type CarouselItem = {
  imagePath: string
  alt: string
  expertName: ExpertName
  expertRole: string
  expertTitle?: string
  expertDescription: string
  cardColor: string
  ribbon?: string
  ribbonAlt?: string
}

const ExpertsCarousel = (): React.ReactElement => {
  const baseClass = 'our-experts__carousel'

  const { t } = useTranslation('reviews')
  const { windowWidth } = useWindowSize()

  const carouselItems: Array<CarouselItem> = [
    {
      imagePath: '/expert-ciara-clarke',
      alt: t('our_experts.carousel.slide1.image_alt'),
      expertName: t('our_experts.carousel.slide1.name'),
      expertRole: t('our_experts.carousel.slide1.expert_role'),
      expertTitle: t('our_experts.carousel.slide1.expert_title'),
      expertDescription: t('our_experts.carousel.slide1.expert_description'),
      cardColor: 'yellow'
    },
    {
      imagePath: '/expert-sarah-barber',
      alt: t('our_experts.carousel.slide2.image_alt'),
      expertName: 'Sarah Barber',
      expertRole: t('our_experts.carousel.slide2.expert_role'),
      expertDescription: t('our_experts.carousel.slide2.expert_description'),
      cardColor: 'pink'
    },
    {
      imagePath: '/expert-oli-juste',
      alt: t('our_experts.carousel.slide3.image_alt'),
      expertName: 'Oli Juste',
      expertRole: t('our_experts.carousel.slide3.expert_role'),
      expertDescription: t('our_experts.carousel.slide3.expert_description'),
      cardColor: 'blue',
      ribbon: RibbonDatesBadge,
      ribbonAlt: t('our_experts.carousel.slide3.ribbon_alt')
    }
  ]

  React.useEffect((): void => {
    lazyLoad.update()
  }, [])

  return (
    <SwiperSlider
      variant="brandBlue100"
      arrows
      arrowsInside
      bullets
      slidesPerView={1}
      arrowPosition={windowWidth > BREAKPOINTS.md ? 0 : -4.5}
    >
      {carouselItems.map(
        (carouselItem: CarouselItem): React.ReactElement => (
          <div
            key={carouselItem.alt}
            className={`${baseClass}__wrapper`}
            aria-label={carouselItem.alt}
          >
            <div
              className={`${baseClass}__card ${baseClass}__card--${carouselItem.cardColor}`}
            >
              <div>
                <Image
                  alt={carouselItem.alt}
                  className={`${baseClass}__card-image lazy`}
                  slug={carouselItem.imagePath}
                  image={{
                    width: 800,
                    height: 524
                  }}
                  lazyLoad
                />
                {carouselItem.ribbon && (
                  <div className={`${baseClass}__card-ribbon`}>
                    <img
                      className={`${baseClass}__card-ribbon--img`}
                      src={carouselItem.ribbon}
                      alt={carouselItem.ribbonAlt}
                    />
                  </div>
                )}
              </div>
              <div className={`${baseClass}__card-text`}>
                <span className="expert-name">{carouselItem.expertName}</span>
                <p>{carouselItem.expertRole}</p>
                {carouselItem.expertTitle && (
                  <p className={`${baseClass}__expert-title`}>
                    {carouselItem.expertTitle}
                  </p>
                )}
                <p className={`${baseClass}__expert-description`}>
                  {carouselItem.expertDescription}
                </p>
              </div>
            </div>
          </div>
        )
      )}
    </SwiperSlider>
  )
}

export default ExpertsCarousel
