// @noflow
import classNames from 'classnames'
import capitalize from 'lodash/capitalize'
import isArray from 'lodash/isArray'
import isNil from 'lodash/isNil'
import React, { KeyboardEvent, MouseEvent, useCallback } from 'react'

import { AnalyticsProps, trackEvent } from '@/services/segment'

import Image, {
  Props as ImageProps
} from '@/components/elements/atoms/Image/Image'
import Interactive from '@/components/elements/atoms/Interactive/Interactive'
import type { Props as TextProps } from '@/components/elements/atoms/Text/Text'
import Text from '@/components/elements/atoms/Text/Text'

// Styles
import STYLES from './FlatButton.module.sass'

type IconVariant = {
  icon: ImageProps
  text?: never
}

type TypographyVariant = {
  text: TextProps
  icon?: never
}

type FlatButtonProps = {
  onClick: (
    e:
      | MouseEvent<HTMLDivElement | MouseEvent | HTMLElement>
      | KeyboardEvent<HTMLDivElement | KeyboardEvent | HTMLElement>
      | undefined
  ) => void
  variant?:
    | 'blue'
    | 'blue200'
    | 'yellow100'
    | 'yellow200'
    | 'yellow300'
    | 'white'
    | 'blue200'
    | 'yellow200HoverYellow400'
    | 'transparentHoverYellow400'
  ['data-testid']?: string
  id?: string
  className?: string
  focusHighlight?: boolean
}

type FlatButtonTypographyVariantProps = FlatButtonProps &
  AnalyticsProps &
  TypographyVariant

type FlatButtonIconVariantProps = FlatButtonProps & AnalyticsProps & IconVariant

type Props = FlatButtonTypographyVariantProps | FlatButtonIconVariantProps

/**
 * A button used for secondary actions not important enough to warrant the use of a standard button
 * @param onClick
 * @param variant - the colour of the button
 * @param text - the button copy
 * @param {string} identifier - identifier of the button using the `app_place.component_identifier` format
 * @param {boolean} [trackDisabledPresses=false] - boolean that appends `.disabled` to the `identifier`
 * @param {string} [screenIdentifier=null] - identifier of the screen where the button is located
 * @constructor
 */
const FlatButton = ({
  onClick,
  variant = 'blue',
  text,
  icon,
  screenIdentifier = null,
  disableAnalytics = false,
  identifier = '',
  id = '',
  className = '',
  focusHighlight = true,
  ...props
}: Props): JSX.Element => {
  // If translate is not defined, we default to true otherwise we use the value passed in
  const shouldTranslate = (() => {
    if (!text) {
      return false
    }

    const { translate } = text

    return isNil(translate) ? true : translate
  })()

  const handleTrackEvent = useCallback(
    (componentIdentifier: string): void => {
      trackEvent('Component Clicked', {
        component_identifier: componentIdentifier,
        ...(screenIdentifier ? { screen_identifier: screenIdentifier } : {})
      })
    },
    [screenIdentifier]
  )

  const onButtonClick = useCallback(
    (event) => {
      onClick(event)
      // If the button and analytics are enabled, we track the event.
      if (!disableAnalytics && identifier) {
        handleTrackEvent(identifier)
      }
    },
    [disableAnalytics, handleTrackEvent, identifier, onClick]
  )

  const classes = classNames(className, STYLES.button, STYLES[variant])

  return (
    <Interactive
      element="button"
      onClick={onButtonClick}
      onKeyDown={onButtonClick}
      className={classes}
      id={id}
      focusHighlight={focusHighlight}
      {...props}
    >
      {icon && <Image {...icon} />}
      {text && (
        <Text
          variant={text.variant ?? 'textRegular14'}
          text={
            !isArray(text.text) && !shouldTranslate
              ? capitalize(text.text)
              : text.text
          }
          element="span"
          translate={shouldTranslate}
          namespace={text.namespace}
          variables={text.variables}
        />
      )}
    </Interactive>
  )
}

export { Props, FlatButtonTypographyVariantProps }
export default FlatButton
