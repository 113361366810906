// @noflow
import isNull from 'lodash/isNull'

import type { DogProfileQuery_user_dogs_dogProfile as Profile } from './../queries/__generated__/DogProfileQuery'

type ProfileStatus =
  | 'pawfectButternutter'
  | 'butternutterFour'
  | 'butternutterThree'
  | 'buddingButternutter'

const profileCompletionStatus = (profile: Profile | null): ProfileStatus => {
  const { avatarUrl, dogPersonalityTraits, dogActivities, favouriteGame } =
    profile || {}

  const hasAvatar = !!avatarUrl
  const hasQuirks = dogPersonalityTraits && dogPersonalityTraits.length > 0
  const hasFavouriteActivities = dogActivities && dogActivities.length > 0
  const hasFavouriteGame = favouriteGame !== 'unknown_favourite_game'

  const sectionsCompleted = [
    hasAvatar,
    hasQuirks,
    hasFavouriteActivities,
    hasFavouriteGame
  ]
  const numberOfSectionsCompleted = sectionsCompleted.filter(Boolean).length
  if (sectionsCompleted.every(Boolean)) {
    return 'pawfectButternutter'
  } else if (numberOfSectionsCompleted >= 2) {
    return 'butternutterFour'
  } else if (numberOfSectionsCompleted >= 1) {
    return 'butternutterThree'
  } else if (isNull(profile)) {
    return 'buddingButternutter'
  } else return 'buddingButternutter'
}

export { ProfileStatus, profileCompletionStatus }
