// @noflow
import React from 'react'

import BRAND_COLOURS from '@/constants/BrandColours'

import useElementSize from '@/hooks/useElementSize'

import Icon from '@/components/elements/atoms/Icon/Icon'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './StoryContent.module.sass'

import type { Story } from '../../../Stories'

type Props = {
  currentStory: Story
}

const StoryContent = ({
  currentStory: {
    layout,
    bottomBgColour = 'brandBlue100',
    title,
    subTitle,
    description,
    content: CustomContent
  }
}: Props): JSX.Element | null => {
  const {
    setRef: bottomTextWrapperRef,
    size: { height: bottomTextWrapperHeight }
  } = useElementSize()

  switch (layout) {
    case 'defined': {
      return (
        <div className={STYLES.bottomWrapper}>
          {title?.text && (
            <Text
              // Allow pass props that doesn't require default values
              {...title}
              text={title?.text || ''}
              element={title?.element || 'h2'}
              variant={title?.variant || 'display36'}
              colour={title?.colour || 'brandBlue500'}
              margin={false}
              align="left"
            />
          )}
          <div className={STYLES.bottomTextWrapper} ref={bottomTextWrapperRef}>
            {subTitle?.text && (
              <Text
                // Allow pass props that doesn't require default values

                {...subTitle}
                text={subTitle?.text || ''}
                element={subTitle?.element || 'p'}
                variant={subTitle?.variant || 'textRegular18'}
                colour={subTitle?.colour || 'brandBlue500'}
                margin={false}
              />
            )}
            {description?.texts.map((text) => (
              <div className={STYLES.list} key={text}>
                <div className={STYLES.icon}>
                  <Icon asset={'paw'} size={22} accentColour="brandBlue700" />
                </div>
                <Text
                  // Allow pass props that doesn't require default values

                  {...description.textProps}
                  text={text}
                  element={description.textProps.element || 'p'}
                  variant={description.textProps.variant || 'textRegular18'}
                  colour={description.textProps.colour || 'brandBlue500'}
                  margin={false}
                />
              </div>
            ))}
          </div>
          <div
            className={STYLES.bottomBackground}
            style={{
              backgroundColor: BRAND_COLOURS[bottomBgColour],
              height: bottomTextWrapperHeight
            }}
          />
        </div>
      )
    }
    case 'custom': {
      return CustomContent ? <CustomContent /> : null
    }
    default: {
      return null
    }
  }
}

export default StoryContent
