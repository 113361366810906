// @noflow
import React from 'react'

// Styles
import STYLES from './DogProfileCollar.module.sass'

// Types
import { ICON_COLOURS, IconProps } from '../index'

type Props = {
  showShadow?: boolean
} & IconProps

const DogProfileCollar = ({
  height,
  accentColour,
  width,
  showShadow
}: Props): JSX.Element => {
  return (
    <div className={STYLES.wrapper}>
      <svg
        width={width ?? 28}
        height={height ?? 27}
        viewBox="0 0 28 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={STYLES.icon}
      >
        <path
          d="M1.072 5.046c.32-.32.554-.8.94-1.048.248-.16.4-.428.665-.597 1.189-.76 2.683-1.306 4.045-1.694 2.704-.768 5.51-.706 8.297-.706.563 0 1.119.052 1.67.072.261.01.487-.006.742.073.265.081.646.196.918.216.177.013.408.11.578.16.24.072.51.076.742.165.54.208 1.121.298 1.662.506.515.198 1.055.372 1.552.614.32.155.592.37.903.537.167.09.402.15.542.29.27.27.55.367.83.585.148.115.727.376.755.542.024.147-.033.318.008.461.045.16.169.357.228.53.508 1.467.851 3.191.851 4.734 0 .752-.5 1.297-1.536 1.724-.305.17-6.479 3.3-8.454 2.818-1.402.392-1.963.51-3.427.562-1.245.045-2.513 0-3.74 0-.315 0-.708-.118-1.019-.192-2.15-.514-4.72-1.044-6.315-2.689-.52-.536-.611-1.413-1.132-1.83-.23-.183-.088-.98-.088-1.243 0-.422.02-.832-.068-1.232a7.144 7.144 0 0 1-.149-1.577c0-.182.104-1.043-.072-1.131"
          stroke={ICON_COLOURS[accentColour]}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M1.506 5.118c.577 0 1.123.78 1.553 1.115.548.426 1.152.758 1.733 1.16.504.348 1.133.589 1.705.794 1.014.364 1.998.765 2.981 1.208 1.173.529 2.197.923 3.487.923h2.444c.978 0 1.863-.182 2.784-.47.531-.166 1.084-.262 1.601-.47.485-.193.934-.449 1.412-.65.706-.294 1.323-.84 2.023-1.098.615-.229.93-.957 1.46-1.268.276-.162.722-.922.722-1.244"
          stroke={ICON_COLOURS[accentColour]}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M5.84 7.285c.721 0 1.493-.438 2.182-.634.538-.154 1.123-.148 1.669-.305 1.897-.547 3.796-.68 5.77-.719.638-.012 1.266.069 1.898.069.178 0 .358.125.541.144.427.045.84.287 1.268.365.455.083.873.188 1.316.341.189.066 1.685.875 1.605.955M8.511 9.451h-.83c-.213 0-.398.225-.578.325-.322.18-.88.655-.903 1.047-.04.677-.144 1.33-.144 2.019 0 .224-.09.7.072.87.43.455.997.781 1.444 1.228M13.795 11.315c.082-.266.53-1.98.53-2.26 0-.162.097-.263.234-.341.514-.294 1.336-.062 1.762.351.46.447.258 1.56.245 2.133-.006.29-.05 4.372-.068 4.66 0 .24-.813.328-.813.579 0 .283-.836-.459-.986-.578-.272-.218-.437-.708-.594-1.021-.246-.492-.393-.93-.393-1.497 0-.344-.012-.692 0-1.036.01-.267.004-.73.083-.99Z"
          stroke={ICON_COLOURS[accentColour]}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M14.786 15.985c-.515 0-1.154.6-1.125 1.125.018.333-.033.976-.033 1.3 0 .262.897 1.012 1.127 1.127.307.153 1.36 0 1.691 0 .29 0 .743-.564 1.041-.564.419 0-.048-.03.134-.512.133-.351.103-1.187-.133-1.484-.351-.441-.919-.534-1.351-.842"
          stroke={ICON_COLOURS[accentColour]}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M10.844 20.427c.194.076 3.109.078 3.315.102 1.976.234 3.854-.34 5.83-.537.336-.033.614-.105.915-.22.354-.136.957.053 1.322.097.303.035.861.35 1.027.621.159.262-.114.84-.278 1.04-.216.263-.555.476-.596.82-.066.56.489.329.696.659.106.17.138.135.107.397-.03.261-.133.46-.22.705-.13.363-.258.689-.718.685-1.32-.01-2.554-.456-3.874-.613l-1.221-.145c-.405-.047-.814.058-1.222.01-.79-.094-1.584-.034-2.367-.127-.39-.046-1.072.007-1.442.12-.378.116-.864.125-1.263.175-.462.059-.924.173-1.376.218-.57.057-1.244.55-1.798.484-.39-.046-.56-.116-.855-.332-.29-.213-.206-.515-.169-.828.045-.377.141-.64.433-.894.22-.192.422-.3.503-.616.164-.643-1.152-.557-1.137-1.293.014-.696.428-1.903 1.288-1.801.324.038.69.126.973.286.405.229.986.494 1.435.619.255.07.453.274.692.368ZM10.191 12.34h.2M22.185 11.145h.08M19.12 12.09c-.008-.067-.125-.1-.175-.05-.023.023.078.027.112-.077"
          stroke={ICON_COLOURS[accentColour]}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
      {showShadow && (
        <svg
          width="36"
          height="30"
          viewBox="0 0 36 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={STYLES.shadow}
        >
          <path
            d="M4.094 3.29c.702-.351 5.826-1.217 5.869-1.565 1.474 0 8.928-1.565 10.37-1.565 3.933 0 6.867 2.367 9.586 5.086 2.05 2.05 3.332 4.41 4.87 6.826.312.492.37 1.63.13 2.174-.967 2.175-3.19 3.264-5 4.608-2.505 1.861-4.275 1.85-6.152 4.696-3.216 4.875-6.109 8.499-10.978 3.13-2.289-2.523-4.38-5.024-7-7.217-1.998-1.673-3.906-3.692-4.695-6.239-1.38-4.452-1.067-7.9 3-9.934Z"
            fill="#FFE180"
          />
        </svg>
      )}
    </div>
  )
}

export type { Props }

export { DogProfileCollar }
