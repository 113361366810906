import { gql } from '@apollo/client'

const SWITCH_AND_SAVE_PAGE_QUERY = gql`
  query SwitchAndSavePageQuery {
    user {
      id
      shippingCountryCode
      preferredLanguage
      subscription {
        id
        status
        recurringOrder {
          deliveryFee
          planTotal
          planTotalPerDay
          surchargeTotal
        }
        meal {
          quantity
          flavour {
            slug
            thumbnail {
              src
            }
            name
            recipeSurcharge
          }
        }
      }
    }
  }
`

export { SWITCH_AND_SAVE_PAGE_QUERY }
