import initI18n from '@/packs/localisation'
import { useMutation } from '@apollo/client'
import { Language } from '@types'
import i18next from 'i18next'
import Cookies from 'js-cookie'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import Validator from '@/utils/validator'

import withApollo from '@/components/apollo/withApollo'
import { Button } from '@/components/elements/atoms/Button'
import Interactive from '@/components/elements/atoms/Interactive/Interactive'
import LoadingOverlay from '@/components/elements/atoms/LoadingOverlay/LoadingOverlay'
import Modal from '@/components/elements/atoms/Modal/Modal'
import Text from '@/components/elements/atoms/Text'
import TextField from '@/components/elements/atoms/TextField/TextField'
import Form from '@/components/elements/molecules/Form/Form'
import PasswordFields from '@/components/elements/molecules/PasswordFields/PasswordFields'

import STYLES from '../Influencers.module.sass'

import { SIGNUP_INFLUENCER_MUTATION } from '../mutations/signupInfluencer'

const SignupScreen = (): JSX.Element => {
  const [dogCount, setDogCount] = useState(1)
  const [dogNames, setDogNames] = useState<string[]>([])
  const [password, setPassword] = useState('')

  const language = (Cookies.get('user_language') || 'en') as Language
  if (!i18next.isInitialized) initI18n(language)

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const [signupMutation, { data, loading, error, reset }] = useMutation(
    SIGNUP_INFLUENCER_MUTATION
  )

  const handleAddDog = useCallback(
    () => setDogCount(dogCount + 1),
    [dogCount, setDogCount]
  )

  const handleDogNameEntered = useCallback(
    (index: number, name: string) => {
      const updatedDogNames = [...dogNames]
      updatedDogNames[index] = name
      setDogNames(updatedDogNames)
    },
    [setDogNames, dogNames]
  )

  const getAddDogHandler = useCallback(
    (index: number) => {
      return (name: string) => handleDogNameEntered(index, name)
    },
    [handleDogNameEntered]
  )

  const onSubmit = useCallback(
    async (formData) => {
      await signupMutation({
        variables: {
          influencer: {
            firstName: formData.first_name,
            lastName: formData.last_name,
            email: formData.email,
            password,
            phone: formData.phone,
            dogs: dogNames,
            token: searchParams.get('token')
          }
        }
      })
    },
    [dogNames, password, searchParams, signupMutation]
  )

  useEffect(() => {
    if (data) {
      window.location.href = data.signupInfluencer.influencer.token
    }
  }, [data, navigate])

  return (
    <div className={STYLES.container}>
      <Text
        variant="display36"
        namespace="ambassadors"
        text="sign_up.join_vip"
      />
      <Text
        variant="bold"
        namespace="ambassadors"
        text="sign_up.enter_details_vip"
      />
      <div className={STYLES.formContainer}>
        <Form onSubmit={onSubmit}>
          <TextField
            label="first_name"
            additionalValidation={Validator.isNotBlank}
          />
          <TextField
            label="last_name"
            additionalValidation={Validator.isNotBlank}
          />
          <TextField
            label="email"
            additionalValidation={Validator.isValidEmail}
          />
          <TextField
            label="phone"
            additionalValidation={Validator.isValidPhoneNumber}
          />
          <PasswordFields password={password} setPassword={setPassword} />
          <>
            {[...Array(dogCount).keys()].map((dogNumber) => (
              <TextField
                key={dogNumber}
                label={`dog_name`}
                additionalValidation={Validator.isNotBlank}
                onChange={getAddDogHandler(dogNumber)}
              />
            ))}
          </>
          <Interactive onClick={handleAddDog}>
            <Text text="sign_up.add_a_dog" namespace="ambassadors" />
          </Interactive>
          <Button
            typography={{
              namespace: 'ambassadors',
              text: 'sign_up.sign_up'
            }}
            disableAnalytics
            size="large"
            variant="secondary"
          />
        </Form>
      </div>
      <LoadingOverlay show={loading} />
      <Modal isModalOpen={!!error} width={450} onCloseButtonClick={reset}>
        <Text namespace="gql_errors" text="oops" />
      </Modal>
    </div>
  )
}

export default withApollo(SignupScreen)
