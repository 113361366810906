// @flow

import * as React from 'react'

type Tab = {|
  title: React.Node,
  panel: React.Node,
  id: string,
  selectTab: () => void
|}

type Props = {|
  tabs: Array<Tab>,
  selectedTabId: React.Node
|}

const selectedPanel = (
  tabs: $PropertyType<Props, 'tabs'>,
  selectedTabId: $PropertyType<Props, 'selectedTabId'>
): React.Node => {
  const tab = tabs.find((tab: Tab): React.Node => tab.id === selectedTabId)
  if (!tab) {
    throw new Error('No tab matching selectedTab')
  }
  // If the tab does not exist an error will be thrown above therefore the below
  // flow error can be ignored
  return (tab.panel)
}

const TabbedPanel = ({
  tabs,
  selectedTabId
}: Props): React.Element<'div'> => (
  <div className="tabbed-panel">
    <div className="tabbed-panel__tabs">
      {
        tabs.length === 0
          ? null
          : tabs.map((tab: Tab): React.Element<'button'> => (
            <button
              className={`tabbed-panel__tabs__tab ${tab.id === selectedTabId && tabs.length > 1 ? 'tabbed-panel__tabs__tab--selected' : ''} ${tabs.length === 1 ? 'tabbed-panel__tabs__tab--one-available-selected' : ''}`}
              key={tab.id}
              onClick={tab.selectTab}
              type='button'
            >
              <span>
                { tab.title }
              </span>
            </button>
          ))
      }
    </div>
    {
      selectedPanel(tabs, selectedTabId)
    }
  </div>
)

export type { Tab }

export default TabbedPanel
