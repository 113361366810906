// @noflow
import { Factory } from 'fishery'

// eslint-disable-next-line no-restricted-imports
import type { DogProfile } from '@/components/types'

export default Factory.define<DogProfile>(({ sequence }) => ({
  id: sequence,
  avatarUrl:
    'https://s3-eu-west-1.amazonaws.com/butternutbox-dog-profile-pictures/dog_profiles/avatars/000/060/845/thumbnail/6915AD6F-3B95-409B-8CCD-41706AB81407.jpeg?1625470947',
  description: 'A good boy'
}))
