import React, { useCallback } from 'react'

import AlertCard from '@/components/elements/atoms/Alert/AlertCard'
import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'

import STYLES from '../../PaymentMethods.module.sass'

type Props = {
  freeBoxNotDelivered: boolean
  dogNames: string[]
  onClickAddPaymentMethod: () => void
}

const NoPaymentMethod = ({
  freeBoxNotDelivered,
  dogNames,
  onClickAddPaymentMethod
}: Props): JSX.Element => {
  const handleClick = useCallback(() => null, [])
  return (
    <div>
      {freeBoxNotDelivered && (
        <Card>
          <AlertCard
            message={{
              namespace: 'dashboard',
              text: 'my_details.payment_methods.no_payment_info.free_box',
              variables: { dogName: dogNames[0] },
              margin: false,
              align: 'left'
            }}
            variant="info"
          />
          <div className={STYLES.buttonContainer}>
            <Button
              typography={{
                text: 'my_details.payment_methods.add_payment_method',
                namespace: 'dashboard'
              }}
              disableAnalytics
              onClick={handleClick}
              disabled
            />
          </div>
        </Card>
      )}
      {!freeBoxNotDelivered && (
        <Card>
          <AlertCard
            message={{
              namespace: 'dashboard',
              text: 'my_details.payment_methods.no_payment_info.next_box',
              variables: { dogName: dogNames[0] },
              margin: false,
              align: 'left'
            }}
            variant="info"
          />
          <div className={STYLES.buttonContainer}>
            <Button
              typography={{
                text: 'my_details.payment_methods.add_payment_method',
                namespace: 'dashboard'
              }}
              disableAnalytics
              // eslint-disable-next-line react/jsx-no-bind
              onClick={onClickAddPaymentMethod}
            />
          </div>
        </Card>
      )}
    </div>
  )
}

export { Props }
export default NoPaymentMethod
