import classNames from 'classnames'
import { times } from 'lodash'
import React from 'react'

import STYLES from '../DeliveryTracking.module.sass'

import { Status } from '@/types'

import { useTransformedDeliveryStatus } from '../hooks/useTransformedDeliveryStatus'

type Props = {
  status: Status
}

const ProgressBar = ({ status }: Props): JSX.Element => {
  const { completed, deliveryProblem, manifested, inTransit, outForDelivery } =
    useTransformedDeliveryStatus(status ?? Status.unknown)

  const amountToFill = ((): number => {
    if (manifested) return 1
    if (inTransit) return 2
    if (outForDelivery) return 3
    if (completed) return 4
    return 1
  })()

  return (
    <div className={STYLES.progressBarWrapper}>
      {times(4).map((step) => {
        const innerBarClasses = classNames(STYLES.progressBarInner, {
          [STYLES.progressBarInnerFilled]: amountToFill > step,
          [STYLES.progressBarInnerException]: step === 4 && deliveryProblem
        })

        return (
          <div key={step} className={STYLES.progressBar}>
            <div className={innerBarClasses} />
          </div>
        )
      })}
    </div>
  )
}

export { ProgressBar }
