// @noflow
// Language support
import initI18n from '@/packs/localisation'
import type { Language } from '@/packs/localisation'
import i18next from 'i18next'
import React from 'react'

import STYLES from './NoNavigationFullWidth.module.sass'

type Props = {
  children: React.ReactNode
  variant?: Language
}

const NoNavigationFullWidth = ({
  children,
  variant = 'en'
}: Props): JSX.Element => {
  if (!i18next.isInitialized) initI18n(variant)

  return (
    <div className={variant}>
      <div className={STYLES.container}>{children}</div>
    </div>
  )
}

export { Props }
export default NoNavigationFullWidth
