// @noflow
import { captureException } from '@sentry/browser'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeCardElementChangeEvent } from '@stripe/stripe-js'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/elements/atoms/Button'

import { NoPaymentMethodPageData_user } from '../queries/__generated__/NoPaymentMethodPageData'

import { abstractStripeError } from '../../CheckoutPage/helpers/errors'
import { useSuccessfulPaymentMethodSubmissionTracking } from '../analytics/NoPaymentMethodAnalytics'
import { updateCreditCard } from '../helpers/buySubscription'
import CreditCardView from './CreditCardView'

type Props = {
  namespace: string
  user: NoPaymentMethodPageData_user
  csrfToken: string
}

const PaymentDetailsSection = ({
  namespace,
  user,
  csrfToken
}: Props): JSX.Element => {
  const { t } = useTranslation('dashboard')
  const [inlineErrorMessage, setInlineErrorMessage] = React.useState<
    string | null
  >(null)
  const [disableButton, setDisableButton] = React.useState<boolean>(true)
  const elements = useElements()
  const stripe = useStripe()
  const trackPaymentMethodPageSubmission =
    useSuccessfulPaymentMethodSubmissionTracking()

  const onCardElementChange = React.useCallback(
    (event: StripeCardElementChangeEvent) => {
      if (event.error !== undefined) {
        const errorMessage = abstractStripeError(event.error)

        setInlineErrorMessage(errorMessage)
        setDisableButton(true)
      }

      if (event.complete) {
        setDisableButton(false)
        setInlineErrorMessage(null)
      }
    },
    []
  )

  const addPaymentMethodCallback = React.useCallback(() => {
    setDisableButton(true)
    if (!stripe || !elements) {
      return captureException(
        `Buy subscription button is enabled without Stripe or Stripe Elements initialised`
      )
    }

    const onSuccess = () => {
      trackPaymentMethodPageSubmission()
      location.reload()
    }

    const onError = (errorMessage: string): void => {
      if (errorMessage === 'Client attempted to refer themselves') {
        setInlineErrorMessage(
          t('no_payment_method.self_referral_error_message')
        )
      } else {
        setInlineErrorMessage(t('no_payment_method.error_message'))
      }
      setDisableButton(true)
      captureException(errorMessage)
    }

    updateCreditCard(
      stripe,
      elements,
      onSuccess,
      onError,
      parseInt(user.id),
      csrfToken,
      user.shouldSupportSca,
      user.email,
      'card',
      user.token
    )
  }, [csrfToken, user, elements, stripe, trackPaymentMethodPageSubmission, t])

  return (
    <React.Fragment>
      <CreditCardView
        errorMessage={inlineErrorMessage}
        onCardElementChange={onCardElementChange}
      />
      <Button
        typography={{
          text: 'no_payment_method.btn_text',
          namespace
        }}
        onClick={addPaymentMethodCallback}
        disabled={disableButton}
        disableAnalytics
      />
    </React.Fragment>
  )
}

export default PaymentDetailsSection
