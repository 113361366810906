/**
 * Use react context to provide notification functionality to pages and components
 */
import React, { useContext, useMemo } from 'react'
import { toast } from 'react-toastify'

import { Props as TextProps } from '@/components/elements/atoms/Text/Text'
// components
import NotificationContainer from '@/components/elements/molecules/NotificationContainer/NotificationContainer'
import NotificationContent from '@/components/elements/molecules/NotificationContent/NotificationContent'

interface NotificationContextProps {
  setInfoNotification: (
    text: TextProps,
    autoClose?: boolean,
    icon?: React.ReactNode
  ) => React.ReactText
  setWarningNotification: (
    text: TextProps,
    autoClose?: boolean,
    icon?: React.ReactNode
  ) => React.ReactText
  setErrorNotification: (
    text: TextProps,
    autoClose?: boolean,
    icon?: React.ReactNode
  ) => React.ReactText
  setSuccessNotification: (
    text: TextProps,
    autoClose?: boolean,
    icon?: React.ReactNode
  ) => React.ReactText
}

const setInfoNotification = (
  text: TextProps,
  autoClose = true,
  icon?: React.ReactNode
) =>
  toast.info(<NotificationContent copy={text} />, {
    autoClose: autoClose ? 5000 : false,
    icon
  })
const setWarningNotification = (
  text: TextProps,
  autoClose = true,
  icon?: React.ReactNode
) =>
  toast.warning(<NotificationContent copy={text} />, {
    autoClose: autoClose ? 5000 : false,
    icon
  })
const setErrorNotification = (
  text: TextProps,
  autoClose = true,
  icon?: React.ReactNode
) =>
  toast.error(<NotificationContent copy={text} />, {
    autoClose: autoClose ? 5000 : false,
    icon
  })
const setSuccessNotification = (
  text: TextProps,
  autoClose = true,
  icon?: React.ReactNode
) =>
  toast.success(<NotificationContent copy={text} />, {
    autoClose: autoClose ? 5000 : false,
    icon
  })

const NotificationContext = React.createContext<NotificationContextProps>({
  setInfoNotification,
  setWarningNotification,
  setErrorNotification,
  setSuccessNotification
})

interface NotificationProviderProps {
  children: React.ReactNode
}

const NotificationProvider = ({
  children
}: NotificationProviderProps): JSX.Element => {
  const notificationProviderValues = useMemo(
    () => ({
      setInfoNotification,
      setWarningNotification,
      setErrorNotification,
      setSuccessNotification
    }),
    []
  )

  return (
    <>
      <NotificationContext.Provider value={notificationProviderValues}>
        {children}
      </NotificationContext.Provider>
      <NotificationContainer />
    </>
  )
}

const useNotifications = (): NotificationContextProps =>
  useContext(NotificationContext)

export { NotificationProvider, useNotifications, NotificationContextProps }
