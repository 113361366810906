// @noflow
import React from 'react'
import { Provider } from 'react-redux'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import type { Action } from 'redux'
import AnalyticsMiddleware from 'redux-action-analytics-middleware'
import thunk from 'redux-thunk'
import type { ThunkMiddleware } from 'redux-thunk'

import ContactForm from './components/ContactForm'
import {
  toValidURLParamValue,
  urlParamToQuery
} from './components/contact_form/query_type/queries'
import type { ValidURLParamValue } from './components/contact_form/query_type/queries'

import type { TrackableAction } from './actions'
import { trackCallback, trackableActions } from './analytics'
import type { State } from './reducers'
import formData, {
  initState as initFormDataState,
  initialState as initialFormData
} from './reducers/formDataReducer'
import globalAttributes from './reducers/globalAttributesReducer'
import type { State as GlobalAttributes } from './reducers/globalAttributesReducer'
import requests from './reducers/requestsReducer'
import * as THUNKS from './thunks'

type Props = Omit<GlobalAttributes, 'allergens'>

const App = ({
  csrfToken,
  token,
  userId,
  recaptchaSiteKey
}: Props): React.ReactElement => {
  // To use Redux extension in browser
  // https://github.com/zalmoxisus/redux-devtools-extension#usage
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const masterReducer = combineReducers({
    globalAttributes,
    requests,
    formData
  })

  const analyticsMiddleware = AnalyticsMiddleware<State, TrackableAction>({
    trackCallback,
    trackableActions
  })

  const searchParams = new URLSearchParams(window.location.search)
  const candidateParam = searchParams.get('contact_type')

  const validURLParamValue: ValidURLParamValue | null =
    toValidURLParamValue(candidateParam)

  const formDataState = validURLParamValue
    ? {
        ...initFormDataState('generic-form'),
        queryType: {
          value: urlParamToQuery(validURLParamValue),
          interaction: 'InteractedWithSuccess' as const
        },
        viewingStep: 'Fill Out Form' as const
      }
    : initialFormData

  const store = createStore(
    masterReducer,
    {
      formData: formDataState,
      globalAttributes: {
        csrfToken,
        token,
        userId,
        recaptchaSiteKey,
        allergens: [{ name: 'None' }]
      }
    },
    composeEnhancers(
      applyMiddleware(
        thunk as ThunkMiddleware<State, Action<string>>,
        analyticsMiddleware
      )
    )
  )

  /**
   * Having set up the Redux application make a fetch for the app data to
   * improve loading experience
   */
  store.dispatch(THUNKS.fetchInitialData())

  React.useEffect((): void => {
    const searchParams = new URLSearchParams(window.location.search)
    const candidateParam = searchParams.get('contact_type')
    const element = document.getElementById('contact-us-form')
    if (element && toValidURLParamValue(candidateParam)) {
      return window.scrollTo({
        behavior: 'smooth',
        // By using '+80' the form comes into view nicely
        top: element.offsetTop + 80
      })
    }
  }, [])

  return (
    <Provider store={store}>
      <ContactForm />
    </Provider>
  )
}

export default App
