import { gql } from '@apollo/client'

const DISCOUNT_CODE_FRAGMENT = gql`
  fragment discountCodeFragment on DiscountCode {
    id
    code
    discountBasis
    value
    discountCodeParts {
      discountBasis
      value
      discountType
      n
    }
  }
`
export { DISCOUNT_CODE_FRAGMENT }
