// @flow

import type { Action } from '../actions'
import type { State } from '../index'

export type Dispatch = Action => Action
export type MiddlewareAPI = {| dispatch: Dispatch, getState: () => State |}
export type Middleware = (api: MiddlewareAPI) => (next: Dispatch) => Dispatch

export { stepPersistance } from './stepPersistance'
