import React from 'react'
import { Slide, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import CrossIcon from 'assets/images/icons/crosses/close--blue.svg'

import STYLES from './NotificationContainer.module.sass'

type Position =
  | 'top-right'
  | 'top-left'
  | 'top-center'
  | 'bottom-right'
  | 'bottom-left'
  | 'bottom-center'

type Props = {
  position?: Position
  autoClose?: false | number
}

const CloseButton = ({
  closeToast
}: {
  closeToast: () => void
}): JSX.Element => (
  <button type="button" className={STYLES.closeBtn} onClick={closeToast}>
    <img src={CrossIcon} alt="" />
  </button>
)

/**
 * Example of usage:
 *
 * 1. import NotificationContainer from '@/components/elements/molecules/NotificationContainer/NotificationContainer'
 * 2. use the toast emitter to trigger your notification:
 *    import { toast } from 'react-toastify'
 *
 *    API reference for toast
 *    https://fkhadra.github.io/react-toastify/api/toast
 *
 *    Note that you can display different types of Notifications:
 *    default, success, info, warning, error
 *
 * // Default
 * toast(<YourComponent />)
 *
 * // Success with delay
 * toast.success(<YourComponent />, { delay: 500 })
 * OR (same as)
 * toast(<YourComponent />, { delay: 500, type: 'success' })
 *
 * // Info
 * toast.info(<YourComponent />)
 *
 * // Warning
 * toast.warn(<YourComponent />)
 *
 * // Error
 * toast.error(<YourComponent />)
 *
 *
 * YourComponent should render the
 * <NotificationContent /> (Text and optional CloudinaryImage).
 * This will ensure the Notifications are consistent and we don't pass
 * untranslated strings in our toast().
 */

const NotificationContainer = ({
  position = 'top-right',
  autoClose = false
}: Props): JSX.Element => {
  const { windowWidth } = useWindowSize()

  return (
    <ToastContainer
      toastClassName={STYLES.notification}
      bodyClassName={STYLES.content}
      position={windowWidth < BREAKPOINTS.md ? 'top-center' : position}
      autoClose={autoClose}
      newestOnTop
      draggable={false}
      pauseOnHover={false}
      pauseOnFocusLoss={false}
      hideProgressBar
      closeOnClick={false}
      closeButton={CloseButton}
      transition={Slide}
    />
  )
}

export { Props }
export default NotificationContainer
