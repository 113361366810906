// @noflow
import { useReactiveVar } from '@apollo/client'
import React, { Fragment, useCallback, useEffect, useState } from 'react'

import { dogsDataVar } from '@/services/apollo'

import AlertCard from '@/components/elements/atoms/Alert/AlertCard'
import { Expand } from '@/components/elements/atoms/Animated/Animated'
import { Button } from '@/components/elements/atoms/Button'
import SizeGuide from '@/components/elements/organisms/SizeGuide/SizeGuide'
import { ClientInitQuery_user_dogs as Dog } from '@/components/pages/App/queries/__generated__/ClientInitQuery'

import STYLES from './ProductVariants.module.sass'

import type {
  ProductCollectionQuery_productCollection as ProductCollection,
  ProductCollectionQuery_productCollection_productVariants as ProductVariantType
} from '../../../queries/__generated__/ProductCollectionQuery'

import type { VariantDelivery } from '../../../../../../../../types/index'
import SectionWrapper from '../../SectionWrapper'
import ProductVariant from '../../molecules/ProductVariant'

type Props = {
  name: ProductCollection['name']
  productVariants: Array<ProductVariantType>
  sizeGuide: ProductCollection['sizeGuide']
  selectedProductVariantId: ProductVariantType['id']
  selectedProductVariantFrequency: VariantDelivery
  setSelectedProductVariantId: (id: ProductVariantType['id']) => void
  setSelectedProductVariantFrequency: (variantDelivery: VariantDelivery) => void
  selectedProductVariantSlug: ProductVariantType['slug']
  setSelectedProductVariantSlug: (slug: ProductVariantType['slug']) => void
}

const ProductVariants = ({
  name,
  productVariants,
  sizeGuide,
  selectedProductVariantId,
  setSelectedProductVariantId,
  selectedProductVariantFrequency,
  setSelectedProductVariantFrequency,
  selectedProductVariantSlug,
  setSelectedProductVariantSlug
}: Props): JSX.Element => {
  const { dogs } = useReactiveVar(dogsDataVar) || {}
  const [isSizeGuideModalOpen, setIsSizeGuideModalOpen] = useState(false)
  const [showSizeWarning, setShowSizeWarning] = useState<boolean>(false)

  const handleSeeSizeGuide = useCallback((): void => {
    window.analytics.track('Size Guide Clicked', {
      properties: {
        productGroup: name
      }
    })
    setIsSizeGuideModalOpen(true)
  }, [name])

  // Show a warning alert if any dog is over 9kg and has selected the small yonks bar (single or bundle)
  // or any dog is over 19kg and has selected either the small or medium yonks bar (single or bundle)
  // since these bars are not suitable for all dogs sizes as per our size guide recommendationss
  useEffect(() => {
    const dogOver9kg = dogs?.some((dog: Dog) => dog.weightInGrams > 9000)
    const dogOver19kg = dogs?.some((dog: Dog) => dog.weightInGrams > 19000)
    const smallYonksBarsSelected =
      selectedProductVariantSlug === '3_x_small_bar' ||
      selectedProductVariantSlug === 'small_yonks_bar'
    const mediumYonksBarsSelected =
      selectedProductVariantSlug === '3_x_medium_bar' ||
      selectedProductVariantSlug === 'medium_yonks_bar'

    if (smallYonksBarsSelected && dogOver9kg) {
      setShowSizeWarning(true)
    } else if (
      (smallYonksBarsSelected || mediumYonksBarsSelected) &&
      dogOver19kg
    ) {
      setShowSizeWarning(true)
    } else {
      setShowSizeWarning(false)
    }
  }, [selectedProductVariantSlug, dogs])

  return (
    <Fragment>
      <SectionWrapper
        title={{
          text: 'extras.product_collection.details.product_variant.title',
          namespace: 'dashboard'
        }}
        marginBottom
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...(sizeGuide
          ? {
              titleHelper: (
                <Button
                  variant="linkWhiteBG"
                  typography={{
                    namespace: 'dashboard',
                    text: 'extras.product_collection.details.product_variant.size_guide.see_size_guide'
                  }}
                  disableAnalytics
                  size="slim"
                  onClick={handleSeeSizeGuide}
                />
              )
            }
          : {})}
      >
        <Fragment>
          <div className={STYLES.container}>
            {productVariants.map((productVariant) => (
              <ProductVariant
                key={productVariant.id}
                productVariant={productVariant}
                selectedProductVariantId={selectedProductVariantId}
                setSelectedProductVariantId={setSelectedProductVariantId}
                selectedProductVariantFrequency={
                  selectedProductVariantFrequency
                }
                setSelectedProductVariantFrequency={
                  setSelectedProductVariantFrequency
                }
                unavailable={!!productVariant?.unavailableObject?.reason}
                setSelectedProductVariantSlug={setSelectedProductVariantSlug}
              />
            ))}
          </div>
          {showSizeWarning && (
            <Expand show={showSizeWarning}>
              <div className={STYLES.warningAlert}>
                <AlertCard
                  message={{
                    namespace: 'dashboard',
                    text: 'extras.product_collection.details.sizing_warning',
                    align: 'left'
                  }}
                  variant="warning"
                />
              </div>
            </Expand>
          )}
        </Fragment>
      </SectionWrapper>
      <SizeGuide
        type="cms"
        isModalOpen={isSizeGuideModalOpen}
        setOpenModal={setIsSizeGuideModalOpen}
        sizeGuide={sizeGuide}
        productCollectionName={name}
      />
    </Fragment>
  )
}

export default ProductVariants
