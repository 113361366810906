import React from 'react'

import bancontactOriginalLogo from 'assets/images/logos/bancontact-original-logo.svg'

import DetailsCard from '@/components/elements/molecules/DetailsCard/DetailsCard'

type Props = {
  onSubmit: () => void
}

const BancontactCard = ({ onSubmit }: Props): JSX.Element => {
  return (
    <DetailsCard
      text={{ text: 'my_details.payment_methods.method_names.bancontact' }}
      onClick={onSubmit}
      leftImage={
        <img
          height="30px"
          src={bancontactOriginalLogo}
          alt={'bancontact logo'}
        />
      }
      namespace={'dashboard'}
      identifier="payment_methods.bancontact"
    />
  )
}

export { Props }
export default BancontactCard
