import { PaymentRequestButtonElement } from '@stripe/react-stripe-js'
import React, { useCallback } from 'react'

import { Expand } from '@/components/elements/atoms/Animated/Animated'
import Card from '@/components/elements/atoms/Card/Card'

import { UseBuySubscriptionWithPaymentRequest } from '../../PaymentSection/hooks/useBuySubscriptionWithPaymentRequest'

type Props = {
  show: boolean
  paymentRequest: UseBuySubscriptionWithPaymentRequest['paymentRequest']
  updatePaymentRequestPrices: UseBuySubscriptionWithPaymentRequest['updatePaymentRequestPrices']
}
const PaymentRequestOption = ({
  show,
  paymentRequest,
  updatePaymentRequestPrices
}: Props): JSX.Element => {
  const handleOnClick = useCallback(() => {
    if (!paymentRequest) {
      return
    }

    updatePaymentRequestPrices()
  }, [paymentRequest, updatePaymentRequestPrices])

  return (
    <Expand show={show} margin={{ top: 1.6 }}>
      <Card variant="brandBlue100" squared={{ topLeft: true, topRight: true }}>
        {paymentRequest !== null && (
          <PaymentRequestButtonElement
            onClick={handleOnClick}
            className="PaymentRequestButton"
            options={{
              paymentRequest: paymentRequest
            }}
          />
        )}
      </Card>
    </Expand>
  )
}

export default PaymentRequestOption
