// @noflow
import type { Props as StarRatingProps } from '@/components/elements/molecules/StarRating/StarRating'

import type { ProductCollectionQuery_productCollection as ProductCollection } from '../queries/__generated__/ProductCollectionQuery'

/**
 * Get the rounded average rating of a product's customer reviews.
 *
 * It takes an `averageCustomerRating` integer and returns a rounded average rating
 *
 * Example output:
 * 4.3 -> 4.5
 * 4.2 -> 4
 *
 * @param averageCustomerRating - ProductCollection['averageCustomerRating']
 * @returns A function that takes in an averageCustomerRating and returns a rounded
 * average rating.
 */
const getRoundedAverageRating = (
  averageCustomerRating: ProductCollection['averageCustomerRating']
): StarRatingProps['rating'] => {
  if (!averageCustomerRating) {
    return 0
  } else {
    return parseFloat(
      (Math.round(averageCustomerRating * 2) / 2).toFixed(1)
    ) as StarRatingProps['rating']
  }
}

export { getRoundedAverageRating }
