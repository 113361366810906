import { useMutation, useReactiveVar } from '@apollo/client'
import React, { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/elements/atoms/Button/Button'
import Card, { generateVariant } from '@/components/elements/atoms/Card/Card'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './RAFSMSComponent.module.sass'

import { SEND_RAF_SMS } from '../../../mutations/mutations'

import {
  SendRafSMS,
  SendRafSMSVariables
} from '../../../mutations/__generated__/SendRafSMS'

import { PaymentPageContext } from '../../../PayOnOwnDevicePage'
import { userLoginUrl } from '../../../screens/PaymentScreen/PaymentScreen'

const RAFSMSComponent = ({
  namespace
}: {
  namespace: string
}): JSX.Element | null => {
  const { paymentPageState } = useContext(PaymentPageContext)
  const { data } = paymentPageState || {}
  const { directSalesPendingSubscription } = data || {}
  const { pendingSubscription } = directSalesPendingSubscription || {}
  const copyContext = 'complete_screen.raf_sms_screen'

  const { t } = useTranslation(namespace)

  const referralUrl = useReactiveVar(userLoginUrl).referralUrl

  const [sendRafSMS] = useMutation<SendRafSMS, SendRafSMSVariables>(
    SEND_RAF_SMS,
    {
      variables: {
        userId: pendingSubscription?.user.id || ''
      }
    }
  )

  const handleRafButtonClick = useCallback(() => {
    sendRafSMS()
  }, [sendRafSMS])

  if (!pendingSubscription) {
    return null
  }

  return (
    <div className={STYLES.smsContainer}>
      <Text
        namespace={namespace}
        text={`${copyContext}.title`}
        variant={'display36'}
        align={'center'}
        bold
      />
      <div className={STYLES.textBottomMargin}>
        <Text
          namespace={namespace}
          text={
            pendingSubscription?.user.dogs.length > 1
              ? `${copyContext}.subtitle_plural`
              : `${copyContext}.subtitle`
          }
          variant={'textRegular16'}
          variables={{ dogName: pendingSubscription?.user.dogs[0].name }}
          colour={'brandBlue400'}
          align={'center'}
        />
      </div>
      <Card
        border="dashed"
        variant={generateVariant('brandBlue100', 'brandBlue400', 'transparent')}
        fill={false}
      >
        <Text
          namespace={namespace}
          text={`${copyContext}.referral_link`}
          variant={'textMono16'}
          variables={{ dogName: pendingSubscription?.user.dogs[0].name }}
          colour={'brandBlue300'}
          align={'center'}
        />
        <div className={STYLES.referralLink}>
          <Text
            namespace={namespace}
            text={referralUrl ?? 'test'}
            variant={'display20'}
            colour={'brandRed400'}
            align={'center'}
            translate={false}
          />
        </div>
      </Card>
      <div className={STYLES.buttonContainer}>
        <Button
          typography={{
            variant: 'textMono18',
            text: t(`${copyContext}.raf_cta`)
          }}
          variant={'primary'}
          onClick={handleRafButtonClick}
          fullWidth
          size="large"
          disableAnalytics
        />
      </div>
    </div>
  )
}

export default RAFSMSComponent
