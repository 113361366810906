// @noflow
import { CardElement } from '@stripe/react-stripe-js'
import type { StripeCardElementChangeEvent } from '@stripe/stripe-js'
import React from 'react'

import BRAND_COLOURS from '@/constants/BrandColours'
import SUPPORT_COLOURS from '@/constants/SupportColours'

import dogsMeeting from 'assets/images/illustrations/dogs/dogs-meeting.svg'

import LoadingScreen from '@/components/elements/organisms/LoadingScreen/LoadingScreen'

import STYLES from './PaymentComponents.module.sass'

import { abstractStripeError } from '../../helpers/errors'
import { SubmissionState } from '../../types'

const Error = ({
  errorMessage
}: {
  errorMessage: string
}): React.ReactElement => (
  <p className="checkout-input__validation-error">{errorMessage}</p>
)

const forceReflow = (stripeElement: HTMLElement | null): void => {
  if (stripeElement) {
    const initialDisplay = stripeElement.style.display

    stripeElement.style.display = 'none'
    stripeElement.style.display = initialDisplay
  }
}

const CardWrapper = ({
  errorMessage,
  namespace,
  submissionState
}: {
  errorMessage: string | null
  namespace: string
  submissionState: SubmissionState
}): React.ReactElement => {
  const [inlineErrorMessage, setinlineErrorMessage] = React.useState<
    string | null
  >(null)

  const onCardElementChange = React.useCallback(
    (event: StripeCardElementChangeEvent) => {
      if (event.error !== undefined) {
        const errorMessage = abstractStripeError(event.error)

        setinlineErrorMessage(errorMessage)
      }
    },
    []
  )

  return (
    <div className={`checkout-input-wrapper ${STYLES.checkoutInputWrapper}`}>
      {submissionState.type === 'loading' && (
        <div>
          <LoadingScreen
            isOpen={submissionState.type === 'loading'}
            title={{
              namespace,
              text: 'loading_screen.text',
              variant: 'display20'
            }}
            variant={'static'}
            image={dogsMeeting}
          />
        </div>
      )}
      <div className={STYLES.cardButton}>
        <CardElement
          onChange={onCardElementChange}
          // eslint-disable-next-line react/forbid-component-props
          options={{
            hidePostalCode: true,
            style: {
              base: {
                fontSize: '18px',
                fontFamily: 'gt-pressura-regular, helvetica, arial, sans-serif',
                color: BRAND_COLOURS.brandBlue500,

                '::placeholder': {
                  color:
                    errorMessage === null
                      ? BRAND_COLOURS.brandBlue400
                      : SUPPORT_COLOURS.dangerRed300
                }
              },
              invalid: {
                color: '#7a0a06'
              }
            }
          }}
        />
      </div>
      {inlineErrorMessage && <Error errorMessage={inlineErrorMessage} />}
    </div>
  )
}

const CreditCardView = ({
  submissionState,
  namespace
}: {
  submissionState: SubmissionState
  namespace: string
}): JSX.Element => {
  // Force the Element frame to paint once it is visible on the page
  const stripeElement: HTMLElement | null =
    document.querySelector('.StripeElement')

  const errorMessage = null
  React.useEffect((): void => {
    forceReflow(stripeElement)
  })

  return (
    <CardWrapper
      errorMessage={errorMessage}
      namespace={namespace}
      submissionState={submissionState}
    />
  )
}

export default CreditCardView
