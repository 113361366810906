// @noflow
import { useEffect, useRef } from 'react'

const usePreviousValue = <Value>(value: Value): Value => {
  const ref = useRef<Value>(value)

  useEffect((): void => {
    ref.current = value
  }, [value])

  return ref.current
}

export default usePreviousValue
