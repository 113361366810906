// @noflow
import React from 'react'

import {
  ICON_COLOURS,
  IconColours
} from '@/components/elements/atoms/Icon/Icon'

type Props = {
  size: number
  accentColour: IconColours
  width?: number | string
}

const Bubble = ({ size, accentColour, width }: Props): JSX.Element => {
  return (
    <svg
      fill="none"
      height={size}
      viewBox="0 0 98 30"
      width={width || '100%'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.0328 1.54163C80.2244 1.54139 78.5694 1.93738 78.0418 2.05576C77.4817 2.13988 31.8789 1.22795 17.4129 0.891193C2.94703 0.554435 1.02722 8.83783 1.02722 8.83783C0.999119 10.4477 0.915358 11.0278 0.923418 14.7846C0.448406 14.8374 1.11516 19.7018 3.23974 25.3659C4.35081 28.328 11.6509 28.6665 50.7069 29.0254C86.3333 29.3528 95.9419 29.8551 96.461 25.5796L96.6061 24.3697C97.2993 18.5182 98.1983 17.6107 97.7253 15.4161L96.8689 7.80667C96.8514 7.73818 96.5906 6.93339 96.5912 6.89128C96.4568 6.54045 96.3115 6.19155 96.1509 5.84742L95.898 5.33516C95.8059 5.15941 95.1029 4.218 95.508 4.70408L95.4529 4.61052C95.179 4.10538 90.8609 1.93109 90.8609 1.93109C90.2569 1.78412 89.632 1.63224 88.9646 1.57016L88.6677 1.54247C88.0104 1.47525 83.8412 1.54186 82.0328 1.54163Z"
        fill={ICON_COLOURS[accentColour]}
      />
    </svg>
  )
}

export default Bubble
