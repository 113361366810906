// @noflow
import classNames from 'classnames'
import React from 'react'

import type { Locale } from '@/utils/countryCodeHelper'
import { Currency } from '@/utils/currency'

import Card from '@/components/elements/atoms/Card/Card'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import Text from '@/components/elements/atoms/Text'
import DiscountSection from '@/components/pages/ReferAFriendPage/components/GiveAndGetTab/DiscountSection/DiscountSection'
import { ReferAFriendPageQuery } from '@/components/pages/ReferAFriendPage/queries/__generated__/ReferAFriendPageQuery'

import STYLES from './Discounts.module.sass'

import Referal from './Referal'

type Props = {
  locale: Locale
  referralLink: ReferAFriendPageQuery['user']['subscription']['referralLink']
  currency: Currency
  copyLinkMessage: string
  possesiveJoinedDogNames: string
}

const Discounts = ({
  referralLink,
  locale,
  currency,
  copyLinkMessage,
  possesiveJoinedDogNames
}: Props): JSX.Element => {
  const cn = classNames(STYLES.title, STYLES.padder)

  return (
    <Card className={STYLES.card} padding={0} dataTestId="share-section">
      <div className={cn}>
        <Text
          namespace="account"
          variant="display20"
          text="thank_you_page_v2.discounts.invite_friends"
          variables={{ dogNames: possesiveJoinedDogNames }}
          margin={false}
        />
      </div>

      <DiscountSection
        namespace={'dashboard'}
        referralLink={referralLink}
        locale={locale}
        currency={currency}
        squared
      />

      <div className={STYLES.padder}>
        <SectionWrapper margin={{ top: 24 }}>
          <Referal
            copyLinkMessage={copyLinkMessage}
            referralLink={referralLink}
            locale={locale}
            currency={currency}
          />
        </SectionWrapper>
      </div>
    </Card>
  )
}

export default Discounts
