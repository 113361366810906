import { useLanguage } from '@/context/injectedValues/injectedValues'
import { useQuery } from '@apollo/client'
import Handlebars from 'handlebars'
import { upperFirst } from 'lodash'
import { useMemo } from 'react'

import { toLocalisedSentence } from '@/utils/StringHelper'
import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { formatCurrencyWithoutDecimal } from '@/utils/currency'
import { percentageValue } from '@/utils/percentage'
import * as Sentry from '@/utils/sentry'

import { IN_ACCOUNT_RAF_QUERY } from './queries/InAccountRafQuery'

import { InAccountRafQuery } from './queries/__generated__/InAccountRafQuery'
import {
  ApplicableDiscountPartDiscountBasis,
  Code as CountryCode,
  DiscountCodePartDiscountBasis
} from '@/types'

type InAccountRafOutputProps = {
  campaignName?: string
  eligibleForRafCampaign: boolean
  dogNamesAsSentence: string
  loading: boolean
  data: InAccountRafQuery | undefined
  referralDiscountCopy: string[] | undefined
  customerDiscountCopy: string[] | undefined
  copyLinkMessage: string
  campaignEndDate: string
  discountCode: string | undefined
  shippingCountryCode: CountryCode
}

const useInAccountRaf = (
  checkCampaignName?: string
): InAccountRafOutputProps => {
  const { userLanguage } = useLanguage()
  const { loading, data } = useQuery<InAccountRafQuery>(IN_ACCOUNT_RAF_QUERY, {
    onError: (error) => {
      Sentry.captureException(
        `Error occured in IN_ACCOUNT_RAF_QUERY: ${error.message}`,
        {
          extra: {
            error
          },
          tags: {
            product: Sentry.Product.Account
          }
        }
      )
    }
  })
  const dogs = data?.user.dogs

  const joinedDogNames = useMemo(() => {
    if (!dogs) return

    const dogNames = dogs?.map(({ name }) => upperFirst(name)) || []

    return toLocalisedSentence({
      arr: dogNames,
      lng: userLanguage
    })
  }, [dogs, userLanguage])

  // eslint-disable-next-line i18next/no-literal-string
  const copyLinkMessage = useMemo(() => {
    if (!dogs) return
    return Handlebars.compile(
      dogs?.length === 1
        ? data?.whatsAppSingleDog.text
        : data?.whatsAppMultipleDogs.text,
      { noEscape: true }
    )({
      dogNames: joinedDogNames,
      // eslint-disable-next-line i18next/no-literal-string
      referralLink: `${data?.user.subscription.referralLink.url}?share_source=copylink`
    })
  }, [
    data?.user.subscription.referralLink.url,
    data?.whatsAppMultipleDogs.text,
    data?.whatsAppSingleDog.text,
    dogs,
    joinedDogNames
  ])

  const { locale, currency } = countryCodeToLocaleCurrency(
    data?.user.shippingCountryCode || 'GB',
    userLanguage
  )

  const today = new Date()

  const eligibleForRafCampaign =
    !!checkCampaignName?.length &&
    data?.user.subscription.activeRafCampaignVariant?.rafCampaign.name ===
      checkCampaignName &&
    !!(
      today.toISOString() <
      data?.user.subscription.activeRafCampaignVariant?.rafCampaign.endDate
    )

  const formatDiscount = (
    basis: ApplicableDiscountPartDiscountBasis | DiscountCodePartDiscountBasis,
    value: number
  ): string => {
    if (basis === 'percentage') {
      return percentageValue(value)
    } else {
      return formatCurrencyWithoutDecimal(value, {
        locale,
        currency
      })
    }
  }

  // Basic discount copy
  const customerDiscountCopy =
    data?.user.subscription.referralLink.referrerDiscount?.applicableDiscountParts.map(
      ({ value, discountBasis }) => formatDiscount(discountBasis, value)
    )
  const referralDiscountCopy =
    data?.user.subscription.referralLink.referralDiscount.discountCodeParts.map(
      ({ value, discountBasis }) => formatDiscount(discountBasis, value)
    )

  return {
    campaignName:
      data?.user.subscription.activeRafCampaignVariant?.rafCampaign.name,
    eligibleForRafCampaign,
    dogNamesAsSentence: joinedDogNames || '',
    loading,
    data,
    referralDiscountCopy,
    customerDiscountCopy,
    copyLinkMessage: copyLinkMessage || '',
    campaignEndDate:
      data?.user.subscription.activeRafCampaignVariant?.rafCampaign.endDate,
    discountCode: data?.user.subscription.referralLink.referralDiscount.code,
    shippingCountryCode: data?.user.shippingCountryCode || CountryCode.GB
  }
}

export default useInAccountRaf
