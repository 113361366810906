// @noflow
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  SectionWrapper,
  SectionWrapperProps
} from '@/components/elements/atoms/SectionWrapper'
import Text, { TextProps } from '@/components/elements/atoms/Text'

type Props = {
  title: TextProps
  content: TextProps
  margin?: SectionWrapperProps['margin']
}

const DetailsRowSection = ({
  title: { text, ...titleRest },
  content,
  margin
}: Props): JSX.Element => {
  const { t } = useTranslation('account')

  const formattedTitle = `${t(text)}:`

  return (
    <SectionWrapper
      headerTypography={{
        variant: 'display16',
        namespace: 'account',
        margin: false,
        translate: false,
        shouldScale: false,
        text: formattedTitle,
        ...titleRest
      }}
      margin={margin}
    >
      <Text margin={false} namespace="account" {...content} />
    </SectionWrapper>
  )
}

export { DetailsRowSection }
