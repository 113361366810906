import { gql } from '@apollo/client'

const CUSTOMER_ISSUE_ENTRY_INPUTS_CREATE = gql`
  fragment applyDiscount on CustomerIssueManagementResolutionDetailsApplyDiscount {
    discountCode {
      id
      code
      value
    }
  }

  fragment replaceBox on CustomerIssueManagementResolutionDetailsReplaceBox {
    availableDeliveryDates {
      date
      deliverable
      endOfLeadTime
    }
    defaultAddress {
      address1
      address2
      city
      shippingCountry {
        id
        code
      }
      postcode
      recipientName
      deliveryNotes
      deliveryCarrier
    }
  }

  mutation CustomerIssueEntryInputsCreate(
    $reporterableType: CustomerIssueManagementReporterable!
    $reporterableId: ID!
    $entryId: ID!
    $reasonId: ID
    $inputs: [CustomerIssueManagementInputInput!]!
  ) {
    response: customerIssueEntryInputsCreate(
      reporterableType: $reporterableType
      reporterableId: $reporterableId
      entryId: $entryId
      reasonId: $reasonId
      inputs: $inputs
    ) {
      entryId
      reportId
      resolutionOffering {
        details {
          ... on CustomerIssueManagementResolutionDetailsApplyDiscount {
            ...applyDiscount
          }
          ... on CustomerIssueManagementResolutionDetailsReplaceBox {
            ...replaceBox
          }
        }
        id
        resolutionType
      }
    }
  }
`

export { CUSTOMER_ISSUE_ENTRY_INPUTS_CREATE }
