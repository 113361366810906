// @noflow
import type { Language } from '@/packs/localisation'
import { useReactiveVar } from '@apollo/client'
import { Elements } from '@stripe/react-stripe-js'
import { Stripe } from '@stripe/stripe-js'
import lowerCase from 'lodash/lowerCase'
import React from 'react'

import { Locale } from '@/utils/currency'
import { localeToStripeLocale } from '@/utils/localeToStripeLocale'

import BRAND_COLOURS from '@/constants/BrandColours'

import PaymentSectionWithStripe from '@/components/pages/CheckoutPage/components/PaymentSection/PaymentSectionWithStripe'

import type { CheckoutPage } from '../../queries/__generated__/CheckoutPage'
import { Code as CountryCode } from '@/types'

import { checkoutPricingState } from '../../hooks/useCheckoutPricing'

type Props = {
  stripePromise: Promise<Stripe | null>
  stripeKey: string
  language: Language
  namespace: string
  affiliateType: CheckoutPage['guest']['affiliateType']
  shippingCountryCode: CountryCode
  csrfToken: string
  shouldSeeApplePay: boolean
  shouldSeeGooglePay: boolean
  paypalClientId: string
  currency: CheckoutPage['guest']['assumedShippingCountry']['currency']
  locale: Locale
  shouldSeePayPal: boolean
}

const StripePaymentElement = ({
  stripePromise,
  stripeKey,
  language,
  namespace,
  affiliateType,
  shippingCountryCode,
  csrfToken,
  shouldSeeApplePay,
  shouldSeeGooglePay,
  paypalClientId,
  currency,
  locale,
  shouldSeePayPal
}: Props): JSX.Element | null => {
  const { firstOrderPricing } = useReactiveVar(checkoutPricingState)

  const checkoutPriceLoaded = firstOrderPricing.netTotalPrice > 0

  return checkoutPriceLoaded ? (
    <div className="stripe-payment-element">
      <Elements
        stripe={stripePromise}
        key={stripeKey}
        options={{
          locale: localeToStripeLocale(language),
          mode: 'payment',
          amount: firstOrderPricing.netTotalPrice,
          currency: lowerCase(currency),
          setupFutureUsage: 'off_session',
          appearance: {
            theme: 'stripe',
            variables: {
              colorPrimary: BRAND_COLOURS.brandBlue500,
              colorText: BRAND_COLOURS.brandBlue500,
              fontFamily: 'gt-pressura-regular, helvetica, arial, sans-serif',
              fontWeightMedium: '400',
              fontSizeBase: '18px'
            },
            rules: {
              '.AccordionItem': {
                borderColor: BRAND_COLOURS.brandBlue500,
                boxShadow: 'none',
                borderWidth: '0 0 0.1rem',
                borderRadius: '0'
              },
              '.RadioIconOuter': {
                stroke: BRAND_COLOURS.brandBlue500
              },
              '.Label': {
                color: BRAND_COLOURS.brandBlue500
              },
              '.Input': {
                borderColor: BRAND_COLOURS.brandBlue500
              }
            }
          },
          fonts: [
            {
              family: 'gt-pressura-regular',
              src: 'url(https://cdn.butternutbox.com/assets/GT-Pressura-Regular-939a820c2e8d519892d754e9b7e4b3a6ee751b6c1620f5794f89a27152f1cace.woff2)',
              weight: '400'
            }
          ]
        }}
      >
        <PaymentSectionWithStripe
          namespace={namespace}
          shippingCountryCode={shippingCountryCode}
          affiliateType={affiliateType}
          csrfToken={csrfToken}
          shouldSeeApplePay={shouldSeeApplePay}
          shouldSeeGooglePay={shouldSeeGooglePay}
          shouldSeePayPal={shouldSeePayPal}
          paypalClientId={paypalClientId}
          currency={currency}
          locale={locale}
        />
      </Elements>
    </div>
  ) : null
}

export default StripePaymentElement
