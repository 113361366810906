import { gql } from '@apollo/client'

const BOX_ORDER_FRAGMENT = gql`
  fragment BoxOrderFragment on Box {
    order {
      id
      orderProducts {
        id
      }
      planTotalPerDay
      planTotal
      surchargeTotal
      physicalProductsTotal
      deliveryFee
      discountTotal
      discountTotalPercentage
      totalPrice
    }
    descriptor
    amendable
  }
`

export { BOX_ORDER_FRAGMENT }
