import { gql } from '@apollo/client'

import { RECIPE_CARD_PLAN_FRAGMENT } from '@/components/elements/molecules/RecipeCard/fragment/recipeCardFragment'

const IDEAL_PLAN_FRAGMENT = gql`
  fragment idealPlanFragment on Plan {
    ...recipeCardPlanFragment
    boxKind
    typeOfPlanForCustomer
    gramsPerDayPerDogs {
      dogId
      gramsPerDay
      name
    }
    pouchSize
    pouchesPerDay
    servingInformation {
      gramsPerDay
    }
    trial {
      lengthInDays
    }
  }
`

const IDEAL_SCREEN_QUERY = gql`
  ${RECIPE_CARD_PLAN_FRAGMENT}
  ${IDEAL_PLAN_FRAGMENT}
  query IdealPlanQuery {
    guest {
      planRecommendation {
        idealMixPlan {
          ...idealPlanFragment
        }
        idealAllPlan {
          ...idealPlanFragment
        }
      }
    }
  }
`

export default IDEAL_SCREEN_QUERY
