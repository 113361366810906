// @noflow
import classnames from 'classnames'
import { Cloudinary } from 'cloudinary-core'
import { Image } from 'cloudinary-react'
import * as React from 'react'

import STYLES from './CloudinaryImage.module.sass'

type CropType =
  | 'scale'
  | 'fill'
  | 'fit'
  | 'limit'
  | 'pad'
  | 'lpad'
  | 'crop'
  | 'lfill'

type GravityType =
  | 'auto'
  | 'north_west'
  | 'north'
  | 'north_east'
  | 'west'
  | 'center'
  | 'east'
  | 'south_west'
  | 'south'
  | 'south_east'
  | 'custom'
  | 'xy_center'

type BaseParams = {
  width?: number
  height?: number
  mode?: string
  quality?: string
  format?: string
  crop?: CropType
  gravity?: GravityType
  aspectRatio?: string
  dpr?: number | 'auto'
  x?: number
  y?: number
}

type CloudinaryParams = BaseParams & { path: string }

type Props = {
  image: CloudinaryParams
  alt: string
  lazyLoad?: boolean
  className?: string
}

const CloudName = 'ldhg5acsz'
// eslint-disable-next-line i18next/no-literal-string
const CloudPath = `https://res.cloudinary.com/${CloudName}/image/upload/`
const cloudinary = Cloudinary.new({ cloud_name: 'ldhg5acsz' })

const sharedTransformationAttributes = {
  quality: 'auto',
  fetch_format: 'auto'
}
/**
 * An image element that uses the cloudinary API and React package to dynamically
 * transform our uploaded images
 * Cloudinary allows us to set many transformations including height, width,
 * mode (scale, crop, etc), and quality (by default we set quality to auto).
 * All cloudinary props other than path are optional.
 *
 * Example usage
 * <CloudinaryImage
 *   alt='An image of butternut box'
 *   image={{path: 'Web/photos/icons/butternut-box'}}
 * />
 */

const cloudinaryPath = ({
  path,
  transformations
}: {
  path: string
  transformations: BaseParams
}): string => {
  return cloudinary.url(path, {
    secure: true,
    ...transformations
  })
}

const CloudinaryImage = ({
  alt,
  lazyLoad = false,
  image: {
    path,
    width,
    height,
    mode,
    quality = 'auto',
    format = 'auto',
    crop,
    gravity,
    aspectRatio,
    dpr,
    x,
    y
  },
  className
}: Props): JSX.Element => {
  const dataSrcUrl = cloudinary.url(path, {
    secure: true,
    ...sharedTransformationAttributes,
    width,
    height,
    mode,
    crop,
    gravity,
    aspectRatio,
    dpr,
    x,
    y
  })

  const cn = classnames(STYLES.image, className, { lazy: lazyLoad })

  return (
    /**
     * If we set an image height or width to anything less than 0 the
     * Cloudinary default width or height will be set instead.
     */
    <Image
      className={cn}
      cloudName={CloudName}
      publicId={!lazyLoad ? path : undefined}
      width={width}
      height={height}
      mode={mode}
      quality={quality}
      fetchFormat={format}
      crop={crop}
      gravity={gravity}
      alt={alt}
      aspectRatio={aspectRatio}
      dpr={dpr}
      x={x}
      y={y}
      dataSrc={lazyLoad ? dataSrcUrl : undefined}
    />
  )
}

export type { CloudinaryParams, CropType, GravityType, Props }

export { CloudPath, CloudName, cloudinaryPath }

export default CloudinaryImage
