import { useReactiveVar } from '@apollo/client'
import React, { useCallback } from 'react'
import { Navigate } from 'react-router'
import { useNavigate } from 'react-router-dom'

import useWebviewHandler from '@/hooks/useWebviewHandler'

import SuccessIcon from 'assets/images/icons/checkmarks/green-checkmark.svg'

import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './QuickHelpResolution.module.sass'

import {
  CustomerIssueCategorySubmission_response_resolutions_details_OrderTrackingNumber as OrderTrackingNumber,
  CustomerIssueCategorySubmission_response_resolutions as Resolution
} from '../../mutations/__generated__/CustomerIssueCategorySubmission'

import {
  resolutionsState,
  selfResolutionPageState
} from '../../SelfResolutionPage'
import useSelfResolutionTracking from '../../analytics'
import getRouteWithBoxId from '../../helpers/getRouteWithBoxId'
import SelfResolutionRoutes from '../../types/routes'
import ContactCustomerLoveDetails from '../ContactCustomerLove/ContactCustomerLoveDetails'

type Props = {
  namespace: string
  returnPath: string
}

const QuickHelpResolution = ({ namespace, returnPath }: Props): JSX.Element => {
  const [isCopied, setIsCopied] = React.useState(false)
  const resolutions = useReactiveVar(resolutionsState) as Array<Resolution>
  const selfResolutionState = useReactiveVar(selfResolutionPageState)
  const details = resolutions[0]?.details as OrderTrackingNumber
  const pageTitle = 'quick_help.header'
  const navigate = useNavigate()
  const webviewHandler = useWebviewHandler()
  const selfResolutionTracking = useSelfResolutionTracking()

  const copyTrackingNumber = useCallback(() => {
    if (!details.trackingId) return
    selfResolutionTracking.trackEvent('copied tracking code')
    if (webviewHandler.isWebview) {
      webviewHandler.postMessage('COPY', details.trackingId)
    } else {
      navigator.clipboard.writeText(details.trackingId)
    }
    setIsCopied(true)
  }, [details, selfResolutionTracking, setIsCopied, webviewHandler])

  const handleTrackOrder = useCallback(() => {
    selfResolutionTracking.trackEvent('Track Order clicked')
    if (webviewHandler.isWebview) {
      webviewHandler.postMessage(
        'TRACK-ORDER',
        'https://' + details.trackingUrl
      )
    } else {
      window.open('https://' + details.trackingUrl, '_blank')
    }
  }, [details, selfResolutionTracking, webviewHandler])

  const handleReportIssueLink = React.useCallback(() => {
    navigate(getRouteWithBoxId(SelfResolutionRoutes.ReportIssue))
  }, [navigate])

  React.useEffect(() => {
    selfResolutionPageState({
      ...selfResolutionState,
      pageTitle
    })
    selfResolutionTracking.stepLoaded('Track order', 'Quick Resolution')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (resolutions.length === 0) {
    return <Navigate to={getRouteWithBoxId(SelfResolutionRoutes.Categories)} />
  }

  if (!selfResolutionState.showBackButton) {
    navigate(returnPath, { replace: true })
  }

  if (!details.trackingId || !details.trackingUrl) {
    return (
      <ContactCustomerLoveDetails
        namespace={namespace}
        pageName={'Track Order'}
      />
    )
  }

  return (
    <div className={STYLES.container}>
      <Text
        namespace={namespace}
        variant={'display20'}
        colour={'brandBlue500'}
        text={'quick_help.address_header'}
      />
      <Text
        translate={false}
        variant={'textRegular16'}
        colour={'brandBlue500'}
        text={details.address1}
      />
      <Text
        translate={false}
        variant={'textRegular16'}
        colour={'brandBlue500'}
        text={details.address2}
      />
      {details.trackingId && (
        <>
          <Text
            namespace={namespace}
            variant="display20"
            colour={'brandBlue500'}
            text={'quick_help.tracking_header'}
          />
          <Card border="dashed" squared>
            <div className={STYLES.orderTrackingNumber}>
              <Text
                variant={'textRegular16'}
                colour={'brandBlue400'}
                translate={false}
                text={details.trackingId}
              />
              <Button
                variant={'secondary'}
                onClick={copyTrackingNumber}
                typography={{
                  namespace,
                  text: 'quick_help.copy_button'
                }}
                disableAnalytics
              />
            </div>
          </Card>
          {isCopied && (
            <div className={STYLES.copySuccess}>
              <img alt="" src={SuccessIcon} />
              <Text
                namespace={namespace}
                text={'quick_help.copied'}
                variant={'textRegular14'}
                colour={'supportGreen300'}
              />
            </div>
          )}
          <Text
            namespace={namespace}
            variant={'textRegular18'}
            colour={'brandBlue500'}
            text={'quick_help.text'}
            variables={{ courier: details.courierName }}
          />
        </>
      )}
      <div className={STYLES.buttonsContainer}>
        <Button
          variant={'ghost'}
          typography={{
            text: 'quick_help.missing_box',
            namespace
          }}
          onClick={handleReportIssueLink}
          disableAnalytics
        />
        <Button
          typography={{
            namespace,
            text: 'quick_help.track_order'
          }}
          onClick={handleTrackOrder}
          disableAnalytics
        />
      </div>
    </div>
  )
}

export { Props }
export default QuickHelpResolution
