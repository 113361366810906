import { gql } from '@apollo/client'

const BASKET_PAGE_QUERY = gql`
  query BasketPageQuery {
    user {
      id
      preferredLanguage
      shippingCountryCode
      subscription {
        status
        deliveriesReceived
        activePaymentMethodType
        paymentMethods {
          ... on CreditCardPaymentMethod {
            __typename
            lastFourDigits
            active
          }
        }
      }
      address {
        recipientName
        address1
        address2
        city
        postcode
      }
      nonCoreOrders {
        id
      }
      basket {
        discountedPrice
        grossPrice
        smallOrderFee
        basketProducts {
          id
          grossPrice
          discountedPrice
          quantity
          productVariant {
            id
            name
            productCollection {
              id
              name
              thumbnail {
                src
              }
            }
          }
        }
      }
    }
    nonCoreOrderDeliveryFee
  }
`
export { BASKET_PAGE_QUERY }
