import { gql } from '@apollo/client'

const DELIVERY_DATE_MODAL_QUERY = gql`
  query DeliveryDateModalQuery(
    $calendarInitDate: ISO8601Date!
    $nDays: Int
    $city: String!
    $postcode: String!
    $shouldAttemptToOfferNextDayDelivery: Boolean
  ) {
    calendarDates(
      startDate: $calendarInitDate
      nDays: $nDays
      city: $city
      postcode: $postcode
      shouldAttemptToOfferNextDayDelivery: $shouldAttemptToOfferNextDayDelivery
    ) {
      date
      deliverable
      endOfLeadTime
    }
  }
`

export { DELIVERY_DATE_MODAL_QUERY }
