// @noflow
import { useReactiveVar } from '@apollo/client'
import i18next from 'i18next'
import React, { MouseEvent, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { pronounContext } from '@/utils/StringHelper'

import chubbyCavapoo from 'assets/images/illustrations/dogs/body-condition/cavapoo-chubby.svg'
import justRightCavapoo from 'assets/images/illustrations/dogs/body-condition/cavapoo-just-right.svg'
import skinnyCavapoo from 'assets/images/illustrations/dogs/body-condition/cavapoo-skinny.svg'
import chubby from 'assets/images/illustrations/dogs/body-condition/normal-chubby.svg'
import justRight from 'assets/images/illustrations/dogs/body-condition/normal-just-right.svg'
import skinny from 'assets/images/illustrations/dogs/body-condition/normal-skinny.svg'
import chubbyPuppy from 'assets/images/illustrations/dogs/body-condition/puppy-chubby.svg'
import justRightPuppy from 'assets/images/illustrations/dogs/body-condition/puppy-just-right.svg'
import skinnyPuppy from 'assets/images/illustrations/dogs/body-condition/puppy-skinny.svg'

import Text from '@/components/elements/atoms/Text/Text'
import TextSeparator from '@/components/elements/molecules/TextSeparator/TextSeparator'
import StickyNavigation from '@/components/elements/organisms/StickyNavigation/StickyNavigation'
import {
  routeToNextStep,
  routeToPrevStep
} from '@/components/pages/SignupWizardPage/helpers/wizardRoutes'
import type { Route } from '@/components/pages/SignupWizardPage/types/routes'

import STYLES from './BodyShape.module.sass'

import { Gender } from '@/types'

import { wizardPageState } from '../../SignupWizardPage'
import useImplicitFormSubmission from '../../helpers/useImplicitFormSubmission'
import type { Dog } from '../../types/types'

type Props = {
  variant?: keyof typeof STYLES
  namespace: string
  isGuest: boolean
}

type BodyTypeDescriptionProps = {
  dog: Dog
  namespace: string
}

type BodyTypeRowProps = {
  dog: Dog
  index: number
  handleClick: (event: MouseEvent, index: number) => void
  areDogsCavapoos: boolean
  namespace: string
}

const copyContext = 'body_shape_step'

const BodyTypeDescription = ({
  dog,
  namespace
}: BodyTypeDescriptionProps): JSX.Element => {
  const { bodyCondition, ageStage, gender } = dog
  switch (bodyCondition) {
    case 'skinny':
      return (
        <div className={`${STYLES.bodyTypeDescription} ${STYLES.skinny}`}>
          <Text
            namespace={namespace}
            text={`${copyContext}.skinny.title`}
            variant="textRegular16"
            colour="brandBlue500"
          />
          <Text
            namespace={namespace}
            text={
              ageStage === 'puppy'
                ? `${copyContext}.skinny.description_puppy`
                : `${copyContext}.skinny.description`
            }
            variables={{
              context: pronounContext([gender as Gender], i18next.language)
            }}
            variant="textRegular16"
            colour="brandBlue500"
          />
        </div>
      )
    case 'justRight':
      return (
        <div className={`${STYLES.bodyTypeDescription} ${STYLES.justRight}`}>
          <Text
            namespace={namespace}
            text={`${copyContext}.just_right.title`}
            variant="textRegular16"
            colour="brandBlue500"
          />
          <Text
            namespace={namespace}
            text={
              ageStage === 'puppy'
                ? `${copyContext}.just_right.description_puppy`
                : `${copyContext}.just_right.description`
            }
            variables={{
              context: pronounContext([gender as Gender], i18next.language)
            }}
            variant="textRegular16"
            colour="brandBlue500"
          />
        </div>
      )
    case 'chubby':
      return (
        <div className={`${STYLES.bodyTypeDescription} ${STYLES.chubby}`}>
          <Text
            namespace={namespace}
            text={`${copyContext}.chubby.title`}
            variant="textRegular16"
            colour="brandBlue500"
          />
          <Text
            namespace={namespace}
            text={
              ageStage === 'puppy'
                ? `${copyContext}.chubby.description_puppy`
                : `${copyContext}.chubby.description`
            }
            variables={{
              context: pronounContext([gender as Gender], i18next.language)
            }}
            variant="textRegular16"
            colour="brandBlue500"
          />
        </div>
      )
    default: {
      throw new Error(`${bodyCondition} is an invalid bodyType`)
    }
  }
}

const BodyTypeRow = ({
  namespace,
  dog,
  areDogsCavapoos,
  handleClick,
  index
}: BodyTypeRowProps): JSX.Element => {
  const { t } = useTranslation(namespace)
  const { bodyCondition, ageStage } = dog

  if (ageStage === 'puppy') {
    return (
      <div className={STYLES.bodyTypeRowPuppy}>
        <button
          className={
            bodyCondition === 'skinny'
              ? `${STYLES.bodyTypeItem} ${STYLES.selected}`
              : `${STYLES.bodyTypeItem}`
          }
          type="button"
          id={'skinny'}
          // eslint-disable-next-line react/jsx-no-bind
          onClick={(e) => handleClick(e, index)}
        >
          <img
            className={STYLES.defaultImage}
            alt={t(`${copyContext}.skinny.img_alt_puppy`)}
            src={skinnyPuppy}
          />
          <img
            className={STYLES.selectedImage}
            alt={t(`${copyContext}.skinny.img_alt_puppy`)}
            src={skinnyPuppy}
          />
        </button>
        <button
          type={'button'}
          className={
            bodyCondition === 'justRight'
              ? `${STYLES.bodyTypeItem} ${STYLES.selected}`
              : `${STYLES.bodyTypeItem}`
          }
          id={'justRight'}
          // eslint-disable-next-line react/jsx-no-bind
          onClick={(e) => handleClick(e, index)}
        >
          <img
            className={STYLES.defaultImage}
            alt={t(`${copyContext}.just_right.img_alt_puppy`)}
            src={justRightPuppy}
          />
          <img
            className={STYLES.selectedImage}
            alt={t(`${copyContext}.just_right.img_alt_puppy`)}
            src={justRightPuppy}
          />
        </button>
        <button
          type="button"
          className={
            bodyCondition === 'chubby'
              ? `${STYLES.bodyTypeItem} ${STYLES.selected}`
              : `${STYLES.bodyTypeItem}`
          }
          id={'chubby'}
          // eslint-disable-next-line react/jsx-no-bind
          onClick={(e) => handleClick(e, index)}
        >
          <img
            className={STYLES.defaultImage}
            alt={t(`${copyContext}.chubby.img_alt_puppy`)}
            src={chubbyPuppy}
          />
          <img
            className={STYLES.selectedImage}
            alt={t(`${copyContext}.chubby.img_alt_puppy`)}
            src={chubbyPuppy}
          />
        </button>
      </div>
    )
  } else {
    return (
      <div
        className={
          areDogsCavapoos ? STYLES.bodyTypeRowCavapoo : STYLES.bodyTypeRow
        }
      >
        <button
          className={
            bodyCondition === 'skinny'
              ? `${STYLES.bodyTypeItem} btn btn--radio btn--radio-selected ${STYLES.selected}`
              : `btn btn--radio ${STYLES.bodyTypeItem}`
          }
          type="button"
          id={'skinny'}
          // eslint-disable-next-line react/jsx-no-bind
          onClick={(e) => handleClick(e, index)}
        >
          <img
            className={STYLES.defaultImage}
            alt={t(`${copyContext}.skinny.img_alt`)}
            src={areDogsCavapoos ? skinnyCavapoo : skinny}
          />
          <img
            className={STYLES.selectedImage}
            alt={t(`${copyContext}.skinny.img_alt`)}
            src={areDogsCavapoos ? skinnyCavapoo : skinny}
          />
        </button>
        <button
          type="button"
          className={
            bodyCondition === 'justRight'
              ? `${STYLES.bodyTypeItem} ${STYLES.selected}`
              : `${STYLES.bodyTypeItem}`
          }
          id={'justRight'}
          // eslint-disable-next-line react/jsx-no-bind
          onClick={(e) => handleClick(e, index)}
        >
          <img
            className={STYLES.defaultImage}
            alt={t(`${copyContext}.just_right.img_alt`)}
            src={areDogsCavapoos ? justRightCavapoo : justRight}
          />
          <img
            className={STYLES.selectedImage}
            alt={t(`${copyContext}.just_right.img_alt`)}
            src={areDogsCavapoos ? justRightCavapoo : justRight}
          />
        </button>
        <button
          type="button"
          className={
            bodyCondition === 'chubby'
              ? `${STYLES.bodyTypeItem} ${STYLES.selected}`
              : `${STYLES.bodyTypeItem}`
          }
          id={'chubby'}
          // eslint-disable-next-line react/jsx-no-bind
          onClick={(e) => handleClick(e, index)}
        >
          <img
            className={STYLES.defaultImage}
            alt={t(`${copyContext}.chubby.img_alt`)}
            src={areDogsCavapoos ? chubbyCavapoo : chubby}
          />
          <img
            className={STYLES.selectedImage}
            alt={t(`${copyContext}.chubby.img_alt`)}
            src={areDogsCavapoos ? chubbyCavapoo : chubby}
          />
        </button>
      </div>
    )
  }
}

const BodyShape = ({ variant, namespace, isGuest }: Props): JSX.Element => {
  const wizardState = useReactiveVar(wizardPageState)
  const { dogs } = wizardState
  const route = useLocation().pathname as Route

  const formIsValid = dogs.every(({ bodyCondition }) => bodyCondition !== null)

  const navigate = useNavigate()

  const onEnterPress = useCallback(() => {
    navigate(routeToNextStep({ route }))
  }, [navigate, route])

  useImplicitFormSubmission({ formIsValid, onEnterPress })

  const handleClick = useCallback(
    (e, index): void => {
      dogs[index].bodyCondition = e.currentTarget.id
      wizardPageState({
        ...wizardState,
        dogs
      })
    },
    [dogs, wizardState]
  )

  return (
    <>
      <div className={variant ? STYLES[variant] : ''}>
        <div className={STYLES.question}>
          {dogs.map((dog: Dog, index: number) => (
            <div
              key={`${dog.name}-${index.toString()}`}
              className={STYLES.subQuestion}
            >
              <TextSeparator
                text={`${copyContext}.text_separator`}
                namespace={namespace}
                variables={{ dogName: dog.name }}
              />
              <div className={STYLES.bodyType}>
                <BodyTypeRow
                  namespace={namespace}
                  dog={dog}
                  areDogsCavapoos
                  handleClick={handleClick}
                  index={index}
                />
              </div>
              {dog.ageStage && dog.bodyCondition && (
                <BodyTypeDescription namespace={namespace} dog={dog} />
              )}
            </div>
          ))}
        </div>
      </div>
      <StickyNavigation
        variant="twoButtons"
        buttonOne={{
          url: routeToPrevStep({ route, isGuest }),
          text: 'wizard_flow:sticky_navigation.back',
          variant: 'secondary',
          iconColour: 'brandRed400'
        }}
        buttonTwo={{
          dataTestId: 'next-button',
          url: routeToNextStep({ route }),
          text: 'wizard_flow:sticky_navigation.continue',
          variant: 'primary',
          iconColour: 'brandWhite'
        }}
        disabled={!formIsValid}
      />
    </>
  )
}

export { Props, BodyTypeDescription }
export default BodyShape
