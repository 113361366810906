// @noflow
import type { Language } from '@/packs/localisation'
import { useReactiveVar } from '@apollo/client'
import { Elements } from '@stripe/react-stripe-js'
import { Stripe } from '@stripe/stripe-js'
import lowerCase from 'lodash/lowerCase'
import React from 'react'

import { localeToStripeLocale } from '@/utils/localeToStripeLocale'

import type { AccountDetailsFormKey } from '@/components/pages/CheckoutPage/types'

import type { CheckoutPage } from '../../queries/__generated__/CheckoutPage'

import { checkoutPricingState } from '../../hooks/useCheckoutPricing'
import ExpressCheckout from './ExpressCheckout'

type Props = {
  stripePromise: Promise<Stripe | null>
  stripeKey: string
  language: Language
  namespace: string
  shippingCountryCode: CheckoutPage['guest']['assumedShippingCountry']['code']
  currency: CheckoutPage['guest']['assumedShippingCountry']['currency']
  csrfToken: string
  isSectionValid: () => boolean
  validateInputField: (fieldName: AccountDetailsFormKey, value: string) => void
}

const ExpressCheckoutElements = ({
  stripePromise,
  stripeKey,
  language,
  namespace,
  shippingCountryCode,
  currency,
  csrfToken,
  isSectionValid,
  validateInputField
}: Props): JSX.Element | null => {
  const { firstOrderPricing } = useReactiveVar(checkoutPricingState)

  const checkoutPriceLoaded = firstOrderPricing.netTotalPrice > 0

  return checkoutPriceLoaded ? (
    <Elements
      stripe={stripePromise}
      key={stripeKey}
      options={{
        locale: localeToStripeLocale(language),
        mode: 'payment',
        amount: firstOrderPricing.netTotalPrice,
        currency: lowerCase(currency),
        setupFutureUsage: 'off_session'
      }}
    >
      <ExpressCheckout
        namespace={namespace}
        shippingCountryCode={shippingCountryCode}
        csrfToken={csrfToken}
        isSectionValid={isSectionValid}
        validateInputField={validateInputField}
      />
    </Elements>
  ) : null
}

export default ExpressCheckoutElements
