// @noflow

/* eslint-disable react/jsx-props-no-spreading, i18next/no-literal-string, @typescript-eslint/no-unused-vars */
import { useLanguage } from '@/context/injectedValues/injectedValues'
import { useNotifications } from '@/context/notifications/notifications'
import { ACCOUNT_ROUTES } from '@/routes'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import classNames from 'classnames'
import produce, { Draft } from 'immer'
import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'
import isUndefined from 'lodash/isUndefined'
import remove from 'lodash/remove'
import set from 'lodash/set'
import times from 'lodash/times'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { SwiperClass } from 'swiper/react'

import { dogsDataVar } from '@/services/apollo'

import { pronounContext } from '@/utils/StringHelper'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import AlertCard from '@/components/elements/atoms/Alert/AlertCard'
import { Expand } from '@/components/elements/atoms/Animated/Animated'
import { Button } from '@/components/elements/atoms/Button'
import { CardSkeleton } from '@/components/elements/atoms/Card/CardSkeleton'
import { FixedBase } from '@/components/elements/atoms/FixedBase'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import { Item } from '@/components/elements/molecules/MultipleComboBox'
import SwiperSlider from '@/components/elements/molecules/SwiperSlider/SwiperSlider'
import TalkingHead from '@/components/elements/molecules/TalkingHead/TalkingHead'
import { SelectableDogAvatar } from '@/components/pages/DogProfile/components/SelectableDogAvatar'
import {
  DogDetailsQuery_user_dogs as Dog,
  DogDetailsQuery
} from '@/components/pages/EditDogDetailsPage/__generated__/DogDetailsQuery'
import {
  DOG_DETAILS_QUERY,
  UPDATE_DOG_DETAILS_MUTATION
} from '@/components/pages/EditDogDetailsPage/queries'
import {
  BodyShapeInput,
  DogActivityInput,
  DogWeightInput,
  NeuteringStatusInput
} from '@/components/pages/UpdateDogBasicInfo/UpdateDogBasicInfoContent'
import {
  AllergiesInput,
  HealthIssuesInput
} from '@/components/pages/UpdateDogMedicalInfo/UpdateDogMedicalInfoContent'

import STYLES from './EditDogDetailsPage.module.sass'

import { DogUpdateInput, Gender } from '@/types'

const namespace = 'account'

const formatForMutation = (dogDetails: DogDetails[]): DogUpdateInput[] =>
  dogDetails.map(
    ({
      __typename,
      dogProfile,
      breed,
      ageInMonths,
      allergens,
      healthIssues,
      hasHealthIssues,
      hasAllergens,
      ...rest
    }) => ({
      ...rest,
      healthIssueIds: hasHealthIssues ? rest.healthIssueIds : [],
      allergenIds: hasAllergens ? rest.allergenIds : []
    })
  )

type DogDetails = Dog & {
  hasHealthIssues: boolean
  healthIssueIds: string[]
  hasAllergens: boolean
  allergenIds: string[]
}

const convertDogToDogDetails = (dog: Dog): DogDetails => ({
  hasHealthIssues: dog.healthIssues.length > 0,
  healthIssueIds: dog.healthIssues.map((healthIssue) => healthIssue.id),
  hasAllergens: dog.allergens.length > 0,
  allergenIds: dog.allergens.map((allergen) => allergen.id),
  ...dog
})

type Sections =
  | 'weight'
  | 'body_shape'
  | 'activity_level'
  | 'neutering_status'
  | 'allergies'
  | 'health_issues'

const getSectionWrapperProps = (type: Sections, genderContext: Gender) =>
  ({
    headerTypography: {
      text: `${
        type === 'allergies' || type === 'health_issues'
          ? 'update_dog_profile_medical_info'
          : 'update_dog_profile_basic_info'
      }.${type}.title`,
      namespace,
      align: 'center',
      variables: {
        context: genderContext
      }
    },
    bgColour: 'brandWhite90',
    borderRadius: 16,
    padding: { top: 16, right: 16, bottom: 16, left: 16 }
  } as const)

const SkeletonLoading = () => (
  <div className={STYLES.skeleton}>
    {times(6, (i) => (
      <CardSkeleton key={i} height={200} />
    ))}
  </div>
)

const EditDogDetailsPage = (): JSX.Element | null => {
  const container = useRef<HTMLDivElement>(null)
  const { dogId } = useParams()
  const navigate = useNavigate()
  const { possessive } = useReactiveVar(dogsDataVar) || {}
  const { data } = useQuery<DogDetailsQuery>(DOG_DETAILS_QUERY)
  const [swiper, setSwiper] = useState<SwiperClass>()
  const [selectedDogId, setSelectedDogId] = useState<string>()
  const [loading, setLoading] = useState<boolean>(true)
  const { setInfoNotification, setErrorNotification } = useNotifications()
  const { userLanguage } = useLanguage()
  const { windowWidth } = useWindowSize()
  const isMobile = windowWidth < BREAKPOINTS.md

  const [updatedDogDetails, setUpdatedDogDetails] = useState<DogDetails[]>()

  const { user, healthIssues, allergens } = data || {}
  const { dogs, id } = user || {}

  /**
   * Handle loading state
   */
  useEffect(() => {
    setLoading(isUndefined(data))
  }, [data])

  /**
   * Set initial dog details
   */
  useEffect(() => {
    if (isUndefined(updatedDogDetails) && !isNil(dogs)) {
      setUpdatedDogDetails(dogs.map((dog) => convertDogToDogDetails(dog)))
    }
  }, [updatedDogDetails, dogs])

  /**
   * Set initially selected dog
   */
  useEffect(() => {
    if (isUndefined(selectedDogId) && !isUndefined(swiper) && !isNil(dogs)) {
      setSelectedDogId(dogId || dogs[0].id)
    }
  }, [dogId, dogs, selectedDogId, swiper])

  /**
   * Update selected dog on slide change
   */
  useEffect(() => {
    if (!isUndefined(swiper) && !isNil(dogs)) {
      swiper.on('slideChange', () => {
        setSelectedDogId(dogs[swiper.activeIndex].id)
      })
    }
  }, [dogs, swiper])

  /**
   * On clicking dog avatars update the selected dog
   */
  const selectDog = useCallback(
    (dogId: string) => {
      if (!isNil(dogs)) {
        setSelectedDogId(dogId)
      }
    },
    [dogs]
  )

  /**
   * Transition to the relevant dog details when the selected dog id changes
   */
  useEffect(() => {
    if (!isNil(dogs) && !isUndefined(swiper)) {
      swiper.slideTo(dogs.findIndex((dog) => dog.id === selectedDogId))
    }
  }, [dogs, selectedDogId, swiper])

  const [updateDogDetailsMutation] = useMutation(UPDATE_DOG_DETAILS_MUTATION, {
    update: (cache, { data }) => {
      if (data.dogsUpdate) {
        const { dogs } = data.dogsUpdate

        dogs.forEach((dog: Dog) => {
          const cacheid = cache.identify({ __typename: 'Dog', id: dog.id })

          cache.modify({
            id: cacheid,
            fields: {
              weightInGrams: () => dog.weightInGrams,
              bodyShape: () => dog.bodyShape,
              exercise: () => dog.exercise,
              healthIssues: () => dog.healthIssues,
              neutered: () => dog.neutered,
              allergens: () => dog.allergens
            }
          })
        })
      }
    },
    onError: () => {
      setErrorNotification({
        text: 'update_dog_profile_basic_info.notifications.error',
        namespace: 'account'
      })
    },
    onCompleted: () => {
      setInfoNotification({
        text: `update_dog_profile_basic_info.notifications.confirm.${
          dogs?.length === 1 ? 'text' : 'alt'
        }`,
        namespace: 'account',
        variables: {
          possessiveDogName:
            !isUndefined(possessive) && !isNil(dogs)
              ? possessive[dogs[0].id]
              : ''
        }
      })
      navigate(ACCOUNT_ROUTES.updatePlan)
    }
  })

  /**
   * Update dog details event
   */
  const onSubmit = useCallback(() => {
    if (isUndefined(updatedDogDetails) || isUndefined(id)) return

    updateDogDetailsMutation({
      variables: {
        userId: id,
        attributes: formatForMutation(updatedDogDetails)
      }
    })
  }, [updateDogDetailsMutation, updatedDogDetails, id])

  /**
   * Update a dog detail for the currently selected dog
   */
  const updateField = useCallback(
    (key: string, value: number | string | string[]) => {
      if (isUndefined(updatedDogDetails)) return

      const updatedDetails = produce<DogDetails[]>(
        updatedDogDetails,
        (draft) => {
          const details: Draft<DogDetails> | undefined = draft.find(
            (dog) => dog.id === selectedDogId
          )

          if (!isUndefined(details)) set(details, key, value)
        }
      )

      setUpdatedDogDetails(updatedDetails)
    },
    [selectedDogId, updatedDogDetails]
  )

  const updateWeight = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      updateField('weightInGrams', parseInt(event.currentTarget.value) * 1000)
    },
    [updateField]
  )

  const handleOnBodyShapeSelect = useCallback(
    (value) => updateField('bodyShape', value),
    [updateField]
  )

  const handleActivityLevelSelect = useCallback(
    (value) => updateField('exercise', value),
    [updateField]
  )

  const handleOnAllergensToggle = useCallback(
    (value) => updateField('hasAllergens', value),
    [updateField]
  )

  const handleSelectAllergens = useCallback(
    (e: React.FormEvent<HTMLDivElement> | undefined) => {
      if (e) {
        const clickedElement = e.target as HTMLInputElement
        const value = clickedElement.value

        const updatedAllergens = produce(updatedDogDetails, (draft) => {
          if (isUndefined(draft)) return

          const details: DogDetails | undefined = draft.find(
            (dog) => dog.id === selectedDogId
          )

          if (!isUndefined(details)) {
            if (clickedElement.checked) {
              details.allergenIds.push(value)
            } else {
              remove(details.allergenIds, (allergen) => allergen === value)
            }
          }
        })

        setUpdatedDogDetails(updatedAllergens)
      }
    },
    [selectedDogId, updatedDogDetails]
  )

  const handleNeuteringStatusSelect = useCallback(
    (value) => updateField('neutered', value),
    [updateField]
  )

  const handleOnHealthIssuesToggle = useCallback(
    (value) => updateField('hasHealthIssues', value),
    [updateField]
  )

  const handleSelectHealthIssues = useCallback(
    (selectedHealthIssues: Array<Item>) => {
      updateField(
        'healthIssueIds',
        selectedHealthIssues.map(({ id }) => id)
      )
    },
    [updateField]
  )

  const dogsChanged = useMemo(() => {
    if (isNil(dogs) || isUndefined(updatedDogDetails)) return undefined

    return updatedDogDetails.filter(
      (updatedDetails, i) =>
        !isEqual(updatedDetails, convertDogToDogDetails(dogs[i]))
    )?.length
  }, [dogs, updatedDogDetails])

  if (
    loading ||
    isUndefined(updatedDogDetails) ||
    isNil(dogs) ||
    isUndefined(healthIssues) ||
    isUndefined(allergens)
  )
    return <div className={STYLES.container}>{SkeletonLoading()}</div>

  const submitButtonClass = classNames({
    [STYLES.submit]: true,
    [STYLES.hideButton]: dogsChanged === 0 || isUndefined(dogsChanged)
  })

  return (
    <div className={STYLES.container} ref={container}>
      <Expand show={!isUndefined(dogsChanged) && dogsChanged > 0}>
        <div className={STYLES.alert}>
          <AlertCard
            variant="info"
            message={{
              text: 'change_dog_details.alert',
              namespace
            }}
          />
        </div>
      </Expand>
      <div className={STYLES.avatars}>
        {updatedDogDetails.map((dog: Dog) => (
          <SelectableDogAvatar
            key={dog.id}
            dog={dog}
            selectedDogId={selectedDogId}
            link={null}
            onSelectAction={selectDog}
          />
        ))}
      </div>
      <SwiperSlider
        slider={setSwiper}
        arrows={false}
        variant="brandYellow300"
        slidesPerView={1}
      >
        {updatedDogDetails.map((dog: DogDetails, i) => (
          <div key={dog.id}>
            <SectionWrapper {...getSectionWrapperProps('weight', dog.gender)}>
              <div className={STYLES.sectionContent}>
                <div className={STYLES.weight}>
                  <DogWeightInput
                    weightInKg={dog.weightInGrams / 1000}
                    onWeightChange={updateWeight}
                  />
                </div>
              </div>
            </SectionWrapper>
            <SectionWrapper
              {...getSectionWrapperProps('body_shape', dog.gender)}
            >
              <div className={STYLES.sectionContent}>
                <BodyShapeInput
                  onSelect={handleOnBodyShapeSelect}
                  bodyShape={dog.bodyShape}
                  isMobile={isMobile}
                  loading={loading}
                />
              </div>
            </SectionWrapper>
            <SectionWrapper
              {...getSectionWrapperProps('activity_level', dog.gender)}
            >
              <div className={STYLES.sectionContent}>
                <DogActivityInput
                  onSelect={handleActivityLevelSelect}
                  exercise={dog.exercise}
                  isMobile={isMobile}
                  loading={loading}
                />
              </div>
            </SectionWrapper>
            <SectionWrapper
              {...getSectionWrapperProps('health_issues', dog.gender)}
            >
              <div className={STYLES.sectionContent}>
                <HealthIssuesInput
                  genderContext={dog.gender}
                  handleSelectHealthIssues={handleSelectHealthIssues}
                  hasHealthIssues={dog.hasHealthIssues}
                  healthIssues={healthIssues}
                  initialSelectedHealthIssues={
                    dogs[i].healthIssues.map(({ id, name }) => ({
                      id,
                      typography: {
                        text: name,
                        translate: false
                      }
                    })) ?? []
                  }
                  healthIssuesIds={dog.healthIssueIds}
                  handleOnHealthIssuesToggle={handleOnHealthIssuesToggle}
                />
              </div>
            </SectionWrapper>
            <SectionWrapper
              {...getSectionWrapperProps('allergies', dog.gender)}
            >
              <div className={STYLES.sectionContent}>
                <AllergiesInput
                  allergies={allergens}
                  allergiesIds={dog.allergenIds}
                  handleSelectAllergens={handleSelectAllergens}
                  handleOnAllergiesToggle={handleOnAllergensToggle}
                  hasAllergies={dog.hasAllergens}
                />
              </div>
            </SectionWrapper>
            <SectionWrapper
              {...getSectionWrapperProps('neutering_status', dog.gender)}
            >
              <div className={STYLES.sectionContent}>
                <NeuteringStatusInput
                  genderContext={dog.gender}
                  onSelect={handleNeuteringStatusSelect}
                  neutered={dog.neutered}
                  isMobile={isMobile}
                  loading={loading}
                />
              </div>
              <div className={STYLES.bubble}>
                <TalkingHead
                  expert="sarah"
                  text={{
                    text: 'update_dog_profile_basic_info.neutering_status.expert_card.neutered_description',
                    variables: {
                      context: pronounContext(
                        [dog.gender],
                        userLanguage || 'en'
                      ),
                      possessiveDogName:
                        !isUndefined(possessive) && !isNil(dogs)
                          ? possessive[dogs[0].id]
                          : ''
                    },
                    namespace
                  }}
                />
              </div>
            </SectionWrapper>
          </div>
        ))}
      </SwiperSlider>
      {!isUndefined(dogsChanged) && dogsChanged > 0 && (
        <FixedBase backgroundColor="brandWhite90" container={container}>
          <div className={STYLES.buttons}>
            <div className={submitButtonClass}>
              <Button
                disabled={dogsChanged === 0}
                onClick={onSubmit}
                typography={{
                  text: 'change_dog_details.buttons.submit',
                  namespace,
                  variables: {
                    count: dogsChanged,
                    name: dogs[dogs.length - 1].name
                  }
                }}
                identifier=""
              />
            </div>
          </div>
        </FixedBase>
      )}
    </div>
  )
}

export default EditDogDetailsPage
