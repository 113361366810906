import React, { useEffect } from 'react'

import { PayPalOption, PaypalResponse } from '../../CheckoutPage/types'
import getPaypalPaymentAuthorisation from '../screens/PaymentMethods/helpers/getPaypalAuthorisation'

type useAddPaypal = {
  payPalButtonClicked: () => void
  payPalBillingAgreementTokenReceived: boolean
  payPalAuthorisationResponse: PaypalResponse | null
  payPalPaymentSuccess: boolean
}

const useAddPaypal = ({
  currentPayPalOption,
  csrfToken,
  userToken,
  responseLoading,
  redirectUrl,
  onCreate,
  onError,
  setResponseLoading,
  setAuthorisationLoading
}: {
  currentPayPalOption: PayPalOption
  csrfToken: string
  userToken: string
  responseLoading: boolean
  redirectUrl: string
  onCreate: (token: string) => void
  onError: (message: string) => void
  setResponseLoading: (loading: boolean) => void
  setAuthorisationLoading: (loading: boolean) => void
}): useAddPaypal => {
  const [payPalAuthorisationResponse, setPayPalAuthorisationResponse] =
    React.useState<PaypalResponse | null>(null)
  const [
    payPalBillingAgreementTokenReceived,
    setPayPalBillingAgreementTokenReceived
  ] = React.useState(false)

  const payPalPaymentSuccess =
    responseLoading && payPalBillingAgreementTokenReceived

  const payPalButtonClicked = React.useCallback(() => {
    if (payPalAuthorisationResponse?.redirect_url) {
      window.location.href = payPalAuthorisationResponse?.redirect_url
    }
  }, [payPalAuthorisationResponse?.redirect_url])

  useEffect((): void => {
    if (!payPalAuthorisationResponse?.redirect_url) {
      getPaypalPaymentAuthorisation({
        redirectUrl,
        payPalOption: currentPayPalOption,
        csrfToken,
        userToken,
        setPayPalAuthorisationResponse,
        isLoading: setAuthorisationLoading
      })
    }
  }, [
    payPalAuthorisationResponse,
    csrfToken,
    currentPayPalOption,
    userToken,
    setAuthorisationLoading,
    redirectUrl
  ])

  useEffect(() => {
    if (window.location.search.includes('token')) {
      setResponseLoading(true)
      const urlParams = new URLSearchParams(window.location.search)
      const token = urlParams.get('token')
      if (token) {
        setPayPalBillingAgreementTokenReceived(true)
        onCreate(token)
      } else {
        setPayPalBillingAgreementTokenReceived(false)
        setResponseLoading(false)
        onError('Add Paypal payment method failed, token is undefined')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    payPalButtonClicked,
    payPalBillingAgreementTokenReceived,
    payPalAuthorisationResponse,
    payPalPaymentSuccess
  }
}

export default useAddPaypal
