import { useReactiveVar } from '@apollo/client'
import { CustomerIssueResolutionAcceptanceReplacementBoxDetailsInput as ReplacementBoxDetails } from '@types'
import React, { useCallback, useState } from 'react'

import STYLES from './ResolutionConfirmation.module.sass'

import { CustomerIssueReportEntriesSubmission_response_resolutions_details_ApplyDiscounts as DiscountDetails } from '../../mutations/__generated__/CustomerIssueReportEntriesSubmission'

import {
  resolutionConfirmationState,
  selfResolutionPageState
} from '../../SelfResolutionPage'
import ContactCustomerLove from '../ContactCustomerLove/ContactCustomerLoveDetails'
import DiscountConfirmation from './ResolutionConfirmationComponents/DiscountConfirmation'
import ReplacementBoxConfirmation from './ResolutionConfirmationComponents/ReplacementBoxConfirmation'

type Props = {
  namespace: string
}

const ResolutionConfirmation = ({ namespace }: Props): JSX.Element => {
  const selfResolutionState = useReactiveVar(selfResolutionPageState)
  const resolutionConfirmationData = useReactiveVar(resolutionConfirmationState)
  const [contactCL, setContactCL] = useState(false)
  const pageTitle = 'resolutionConfirmation.header'
  React.useEffect(() => {
    selfResolutionPageState({
      ...selfResolutionState,
      showBackButton: false,
      pageTitle,
      hasReceivedReplacementBox:
        resolutionConfirmationData.type ===
        ('replacement_box_details' || 'replace_box')
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderConfirmation = useCallback(() => {
    switch (resolutionConfirmationData.type) {
      case 'replacement_box_details':
      case 'replace_box': {
        return (
          <ReplacementBoxConfirmation
            namespace={namespace}
            details={
              resolutionConfirmationData.details as ReplacementBoxDetails
            }
          />
        )
      }
      case 'apply_discount': {
        return (
          <DiscountConfirmation
            namespace={namespace}
            details={resolutionConfirmationData.details as DiscountDetails}
          />
        )
      }
      default:
        if (!contactCL) {
          setContactCL(true)
        }
        return (
          <ContactCustomerLove
            pageName={'Resolution confirmation'}
            namespace={namespace}
          />
        )
    }
  }, [resolutionConfirmationData, contactCL, namespace])

  return (
    <div className={`${STYLES.container} ${contactCL ? STYLES.contactCL : ''}`}>
      {renderConfirmation()}
    </div>
  )
}

export { Props }
export default ResolutionConfirmation
