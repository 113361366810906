// @noflow
import type { Code, Gender, MarketingPreferences } from '@types'

import type { HealthIssues_healthIssues as HealthIssue } from '@/components/pages/SignupWizardPage/screens/Health/queries/__generated__/HealthIssues'

import type { Route } from '../types/routes'

enum WizardAgeStage {
  Puppy = 'puppy',
  Adult = 'adult',
  Senior = 'senior'
}

type WizardAge = {
  weeks: number | null
  months: number | null
  years: number | null
}

enum WizardActivityLevel {
  Lazy = 'lazy',
  Leisurely = 'leisurely',
  FairlyActive = 'fairlyActive',
  BundleOfEnergy = 'bundleOfEnergy',
  HyperActive = 'hyperActive'
}

enum WizardCurrentlyEating {
  Dry = 'dry',
  Raw = 'raw',
  Wet = 'wet',
  HomeCooked = 'homeCooked'
}

enum WizardBodyCondition {
  Skinny = 'skinny',
  JustRight = 'justRight',
  Chubby = 'chubby'
}

enum WizardEaterType {
  VeryFussy = 'veryFussy',
  CanBeFussy = 'canBeFussy',
  GoodEater = 'goodEater',
  EatsAnything = 'eatsAnything'
}

enum WizardSnackingHabits {
  NoSnacks = 'noSnacks',
  SomeSnacks = 'someSnacks',
  LotsOfSnacks = 'lotsOfSnacks'
}

enum WizardHealthIssuesTag {
  Some = 'some',
  None = 'none'
}

type WizardHealthIssues = {
  contents: Array<string> | null
  tag: WizardHealthIssuesTag | null
}

enum GoalSegment {
  HealthConscious = 'health_conscious',
  PremiumTaste = 'premium_taste',
  PremiumIngredients = 'premium_ingredients',
  Convenience = 'convenience'
}

type User = {
  location: {
    countryCode: Code
    postcode: string | null
  }
  name: string
  email: string
  marketingConsent?: MarketingPreferences
  marketingPreference: MarketingPreferences
  supportedMarketingMethodPurposeIds: Array<string>
  goalSegment: GoalSegment | null
  trackingId: string | null
}

type PuppyNotBroughtHomeProperties = {
  activityLevel: WizardActivityLevel
  age: {
    months: number | null
    weeks: number | null
  }
  allergies: Array<string> | null
  bodyCondition: WizardBodyCondition
  breed: {
    id: number | null
    name: string | null
  }
  broughtHome: boolean | null
  foodCategoryIds: Array<string> | string[]
  deSexed: boolean | null
  eaterType: WizardEaterType | null
  healthIssues: WizardHealthIssues | null
  isRescue: boolean | null
  isWorkingDog: boolean | null
  mass: number | null
  name: string
  sex: Gender | null
  snackingHabits: WizardSnackingHabits
}

type PageLoadProperties = {
  dogCount: number
  location: Route
  hasAdult: boolean
  hasPuppy: boolean
  hasSenior: boolean
  isEligibleForStrippedBackWizard: boolean
}

type Dog = {
  name: string
  gender: Gender | null
  neutered: boolean | null
  ageStage: WizardAgeStage | null
  age: WizardAge | null
  isRescue: boolean | null
  broughtHome: boolean | null
  breed: {
    id: number
    key: string
    name: string
  } | null
  foodCategoryIds: Array<string>
  eaterType: WizardEaterType | null
  bodyCondition: WizardBodyCondition
  weight: number | null
  isWorkingDog: boolean | null
  activityLevel: WizardActivityLevel
  allergies: Array<string> | null
  healthIssues: Array<HealthIssue> | null
  snackingHabits: WizardSnackingHabits
}

export {
  WizardAgeStage,
  WizardAge,
  WizardActivityLevel,
  WizardCurrentlyEating,
  WizardBodyCondition,
  WizardEaterType,
  WizardSnackingHabits,
  WizardHealthIssues,
  WizardHealthIssuesTag,
  PuppyNotBroughtHomeProperties,
  PageLoadProperties,
  Dog,
  User,
  GoalSegment
}
