// @noflow
import React, { useCallback } from 'react'

import { Locale } from '@/utils/countryCodeHelper'
import { Currency } from '@/utils/currency'

import useWindowSize from '@/hooks/useWindowSize'

import SwiperSlider from '@/components/elements/molecules/SwiperSlider/SwiperSlider'
import { BREAKPOINTS } from '@/components/templates/Base'

import STYLES from './CardsCarousel.module.sass'

import { ReferAFriendPageQuery } from '../../../queries/__generated__/ReferAFriendPageQuery'

import DonationsCard from './Cards/Donations/Donations'
import RafPassCard from './Cards/RafPass/RafPass'
import ReferralSavingsCard from './Cards/ReferralSavings/ReferralSavings'
import RescuePromiseCard from './Cards/RescuePromise/RescuePromise'
import RuffGuideCard from './Cards/RuffGuide/RuffGuide'
import YourPackCard from './Cards/YourPack/YourPack'

export type Props = {
  namespace: string
  referralLink: ReferAFriendPageQuery['user']['subscription']['referralLink']
  locale: Locale
  currency: Currency
}

const CardsCarousel = ({
  namespace,
  referralLink,
  locale,
  currency
}: Props): JSX.Element => {
  const { windowWidth } = useWindowSize()
  const isDesktop = windowWidth >= BREAKPOINTS.lg

  const { referrals, referralDiscountsTotalValue } = referralLink

  const getCards = useCallback(() => {
    const cards: Array<JSX.Element> = []
    if (windowWidth < BREAKPOINTS.lg)
      cards.push(<RafPassCard namespace={namespace} key="raf-pass" />)
    if (referrals.length > 0) {
      cards.push(
        <YourPackCard
          namespace={namespace}
          referrals={referrals}
          key="your-pack"
        />
      )
      cards.push(
        <DonationsCard
          namespace={namespace}
          referrals={referrals}
          key="donations"
        />
      )
      if (referralDiscountsTotalValue > 0) {
        cards.push(
          <ReferralSavingsCard
            namespace={namespace}
            referralLink={referralLink}
            locale={locale}
            currency={currency}
            key="referral-savings"
          />
        )
      }
    } else {
      cards.push(<RuffGuideCard namespace={namespace} key="ruff-guide" />)
      cards.push(
        <RescuePromiseCard namespace={namespace} key="rescue-promise" />
      )
    }

    return cards
  }, [
    currency,
    locale,
    namespace,
    referralDiscountsTotalValue,
    referralLink,
    referrals,
    windowWidth
  ])

  return (
    <div className={STYLES.container}>
      {!isDesktop && (
        <SwiperSlider arrows={false} slidesPerView="auto">
          {getCards()}
        </SwiperSlider>
      )}

      {isDesktop && <div className={STYLES.imageWrapper}>{getCards()}</div>}
    </div>
  )
}

export default CardsCarousel
