// @noflow
import type { Language } from '@/packs/localisation'
import { useQuery, useReactiveVar } from '@apollo/client'
import isUndefined from 'lodash/isUndefined'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Routes as RouterRoutes, useLocation } from 'react-router-dom'

import { initCookieConsent } from '@/components/cookie_consent'
import WizardNavigationLayout from '@/components/templates/WizardNavigationLayout/WizardNavigationLayout'

import { CURRENT_USER } from './queries/userQuery'

import type { CurrentUser as CurrentUserQuery } from './queries/__generated__/CurrentUser'
import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'

import { wizardPageState } from './SignupWizardPage'
import { triggerAnalytics } from './helpers/analytics'
import routeToFreshFactFooter from './helpers/routeToFreshFactFooter'
import { routeToPercentFilled, routeToStepHero } from './helpers/stepHero'
// Screens
import Activity from './screens/Activity/Activity'
import Age from './screens/Age/Age'
import Allergies from './screens/Allergies/Allergies'
import BodyShape from './screens/BodyShape/BodyShape'
import Breed from './screens/Breed/Breed'
import Eater from './screens/Eater/Eater'
import Food from './screens/Food/Food'
import FussinessHelp from './screens/FussinessHelp/FussinessHelp'
import Gender from './screens/Gender/Gender'
import Goals from './screens/Goals/Goals'
import Health from './screens/Health/Health'
import Name from './screens/Name/Name'
import PetParent from './screens/PetParent/PetParent'
import Snacks from './screens/Snacks/Snacks'
import Weight from './screens/Weight/Weight'
import Routes from './types/routes'
import type { Route as WizardRoute } from './types/routes'

type Props = {
  preferredLanguage: Language
  shippingCountryCode: CountryCode
  isSupportedWizardStateVersion: boolean
  shouldSeePetParentOnPlans: boolean
}

const SignupWizard = ({
  preferredLanguage,
  shippingCountryCode,
  isSupportedWizardStateVersion,
  shouldSeePetParentOnPlans
}: Props): JSX.Element | null => {
  const namespace = 'wizard_flow'
  const location = useLocation().pathname as WizardRoute
  const wizardState = useReactiveVar(wizardPageState)
  const { t } = useTranslation(namespace)
  const [isGuest, setIsGuest] = useState<boolean | undefined>()
  const { dogs, user } = wizardState
  const { data: userData } = useQuery<CurrentUserQuery>(CURRENT_USER, {
    onCompleted: (data) => {
      if (
        data.currentUser?.__typename === 'PreWizardGuest' &&
        !wizardState.user.trackingId
      ) {
        wizardState.user.trackingId = data.currentUser.trackingId
      }
    }
  })

  useEffect(() => {
    if (isUndefined(isGuest) && !isUndefined(userData))
      setIsGuest(userData.currentUser?.__typename !== 'User')
  }, [userData, isGuest])

  const hero = routeToStepHero(location, dogs, isSupportedWizardStateVersion)

  const freshFactFooter = routeToFreshFactFooter({
    route: location,
    t,
    wizardState,
    shippingCountryCode
  })

  useEffect(() => {
    triggerAnalytics(location, dogs, user)
    window.scrollTo(0, 0)
    // We only want to trigger this once on navigating to another Wizard step and
    // not each time whilst the user is editing the dogs data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    localStorage.setItem('wizardState', JSON.stringify(wizardState))
  }, [wizardState])

  const localStorageCookieConsent = localStorage.getItem('cookieConsent')
  if (!localStorageCookieConsent) {
    initCookieConsent()
  }

  if (isUndefined(isGuest)) return null

  return (
    <WizardNavigationLayout
      namespace={namespace}
      hero={hero}
      isGuest={isGuest}
      preferredLanguage={preferredLanguage}
      shippingCountryCode={shippingCountryCode}
      footer={freshFactFooter}
      percentFilled={routeToPercentFilled({
        route: location,
        isGuest,
        shouldSeePetParentOnPlans
      })}
    >
      <RouterRoutes>
        <Route path={Routes.Name} element={<Name namespace={namespace} />} />

        <Route
          path={Routes.Gender}
          element={<Gender namespace={namespace} />}
        />

        <Route path={Routes.Age} element={<Age namespace={namespace} />} />

        <Route path={Routes.Breed} element={<Breed namespace={namespace} />} />

        <Route path={Routes.Food} element={<Food namespace={namespace} />} />

        <Route
          path={Routes.Eater}
          element={<Eater isGuest={isGuest} namespace={namespace} />}
        />
        <Route path={Routes.Goals} element={<Goals namespace={namespace} />} />
        <Route
          path={Routes.BodyShape}
          element={<BodyShape isGuest={isGuest} namespace={namespace} />}
        />

        <Route
          path={Routes.Weight}
          element={<Weight namespace={namespace} />}
        />

        <Route
          path={Routes.Activity}
          element={<Activity namespace={namespace} />}
        />

        <Route
          path={Routes.Allergies}
          element={<Allergies namespace={namespace} />}
        />

        <Route
          path={Routes.Health}
          element={<Health namespace={namespace} />}
        />

        <Route
          path={Routes.Snacks}
          element={
            <Snacks
              namespace={namespace}
              shouldSeePetParentOnPlans={shouldSeePetParentOnPlans}
              isGuest={isGuest}
            />
          }
        />
        <Route
          path={Routes.PetParent}
          element={<PetParent namespace={namespace} />}
        />
        <Route
          path={Routes.FussinessHelp}
          element={<FussinessHelp isGuest={isGuest} namespace={namespace} />}
        />
      </RouterRoutes>
    </WizardNavigationLayout>
  )
}

export default SignupWizard
