// @noflow
import React from 'react'

import Modal from '@/components/elements/atoms/Modal/Modal'
import SpeechBubble from '@/components/elements/atoms/SpeechBubble/SpeechBubble'
import Text from '@/components/elements/atoms/Text/Text'
import type { Props as TextProps } from '@/components/elements/atoms/Text/Text'
import ExpertCard from '@/components/elements/molecules/ExpertCard/ExpertCard'
import type { Expert as ExpertProps } from '@/components/elements/molecules/ExpertCard/ExpertCard'
import CloudinaryImage from '@/components/shared/elements/CloudinaryImage/CloudinaryImage'

import STYLES from './SizeGuide.module.sass'

type Copies = {
  title: TextProps
  header: TextProps
  size: TextProps
  bubble: TextProps
}

type Image = {
  alt: string
  image: string
}

type SharedProps = {
  isModalOpen: boolean
  expert?: ExpertProps
  setOpenModal: (isModalOpen: Props['isModalOpen']) => void
}

type ManualSizeGuide = {
  type: 'manual'
  sizes: Array<string>
  dimensions: Array<string>
  copies: Copies
  image: Image
  rowNumber: number
  productCollectionName?: never
  sizeGuide?: never
} & SharedProps

type CmsSizeGuide = {
  type: 'cms'
  productCollectionName: string
  sizeGuide: string | null
  sizes?: never
  dimensions?: never
  copies?: never
  image?: never
  rowNumber?: never
} & SharedProps

type Props = ManualSizeGuide | CmsSizeGuide

const SizeGuide = ({
  type = 'manual',
  isModalOpen,
  setOpenModal,
  sizes,
  dimensions,
  copies,
  image,
  expert = {
    name: 'Sarah Barber',
    description: 'sarah_connell_sait.profession',
    imgSrc: 'Web/photos/people/sarah-connell-sait.png',
    imgAlt: 'sarah_connell_sait.image_alt'
  },
  rowNumber,
  productCollectionName,
  sizeGuide
}: Props): JSX.Element | null => {
  switch (type) {
    case 'manual': {
      if (!copies) {
        return null
      }

      return (
        <Modal
          isModalOpen={isModalOpen}
          setOpenModal={setOpenModal}
          width={600}
          padding={false}
        >
          <div className={STYLES.container}>
            <div className={STYLES.header}>
              <Text
                text={copies.title.text}
                namespace={copies.title.namespace}
                variant="display28"
                colour="brandBlue500"
                align="center"
                margin={false}
              />
            </div>
            <CloudinaryImage
              alt={image?.alt || ''}
              image={{
                path: image?.image || ''
              }}
            />
            <div className={STYLES.content}>
              <div className={STYLES.table}>
                <table>
                  <thead>
                    <tr>
                      {[...Array(rowNumber).keys()].map((e) => (
                        <th key={e} className={STYLES.tableHeader}>
                          <Text
                            text={`${copies.header.text}${e + 1}`}
                            namespace={copies.header.namespace}
                            variant="display16"
                            colour="brandBlue500"
                            margin={false}
                          />
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {[...Array(rowNumber).keys()].map((e) => (
                      <tr key={e}>
                        <th className={STYLES.tableRow}>
                          <Text
                            text={(sizes && sizes[e]) || ''}
                            variant="textRegular16"
                            colour="brandBlue500"
                            translate={false}
                            margin={false}
                          />
                        </th>
                        <th className={STYLES.tableRow}>
                          <Text
                            text={`${copies.size.text}${e + 1}`}
                            namespace={copies.size.namespace}
                            variant="textRegular16"
                            colour="brandBlue500"
                            margin={false}
                          />
                        </th>
                        <th className={STYLES.tableRow}>
                          <Text
                            text={(dimensions && dimensions[e]) || ''}
                            variant="textRegular16"
                            colour="brandBlue500"
                            translate={false}
                            margin={false}
                          />
                        </th>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <SpeechBubble
                footPosition="bottomLeft"
                text={{
                  namespace: copies.bubble.namespace,
                  text: copies.bubble.text,
                  variant: 'textRegular16',
                  element: 'span'
                }}
              />
              <div>
                <ExpertCard namespace="expert" expert={expert} />
              </div>
            </div>
          </div>
        </Modal>
      )
    }
    case 'cms': {
      return (
        <Modal
          isModalOpen={isModalOpen}
          setOpenModal={setOpenModal}
          width={600}
          padding={false}
        >
          <div className={STYLES.container}>
            <div className={STYLES.header}>
              <Text
                text="extras.product_collection.details.product_variant.size_guide.title"
                namespace="dashboard"
                variables={{ productCollectionName }}
                variant="display28"
                colour="brandBlue500"
                align="center"
                margin={false}
              />
            </div>
            <div className={STYLES.content}>
              <div
                className={STYLES.cmsTable}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: sizeGuide || '' }}
              />
              <SpeechBubble
                footPosition="bottomLeft"
                text={{
                  namespace: 'dashboard',
                  text: 'extras.product_collection.details.product_variant.size_guide.bubble',
                  variant: 'textRegular16',
                  element: 'span'
                }}
              />
              <div>
                <ExpertCard namespace="expert" expert={expert} />
              </div>
            </div>
          </div>
        </Modal>
      )
    }
    default: {
      return null
    }
  }
}

export type { Props }

export default SizeGuide
