import { useReactiveVar } from '@apollo/client'
import { client as elevioClient } from 'elevio'
import React, { useEffect, useState } from 'react'

import STYLES from '../QuickHelp.module.sass'

import { CustomerIssueReportCreate_response_resolutionOffering_details_CustomerIssueManagementResolutionDetailsHelpCentreArticle as HelpCentreArticleDetails } from '../../../mutations/__generated__/CustomerIssueReportCreate'

import { customerIssueManagementState } from '../../../CustomerIssueManagementPage'
import useCustomerIssueManagementTracking from '../../../analytics'
import ContactCustomerLoveDetails from '../../ContactCustomerLove/ContactCustomerLoveDetails'

type Props = {
  details: HelpCentreArticleDetails
}

const HelpCentreArticle = ({ details }: Props): JSX.Element => {
  const customerIssueManagementData = useReactiveVar(
    customerIssueManagementState
  )
  const customerIssueManagementTracking = useCustomerIssueManagementTracking()
  const [initialised, setInitialised] = useState(false)
  const elevioAccountId = '5f80749823581'
  const elevioHelpCentreArticleStylesheetUrl =
    'https://assets.butternutbox.com/raw/upload/v1660212964/Web/stylesheets/help-centre-article.css'

  useEffect(() => {
    if (!initialised) {
      customerIssueManagementTracking.stepLoaded(
        'Help Centre Article',
        'Quick Resolution'
      )
      setInitialised(true)
    }
  }, [
    initialised,
    customerIssueManagementData,
    customerIssueManagementTracking
  ])

  const { articleId } = details

  if (!articleId) {
    return <ContactCustomerLoveDetails />
  }

  elevioClient.load(elevioAccountId).then(() => {
    elevioClient.setSettings({
      embeddable_css_file: elevioHelpCentreArticleStylesheetUrl
    })
  })

  return (
    <div className={STYLES.container}>
      <elevio-element data-type="article" data-id={articleId} />
    </div>
  )
}

export { Props }
export default HelpCentreArticle
