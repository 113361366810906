import classNames from 'classnames'
import isNumber from 'lodash/isNumber'
import React from 'react'

import DiscountBadgeBG from 'assets/images/icons/badges/discount-badge.svg'
import SavingsDiscountBadgeBG from 'assets/images/icons/badges/savings-discount-badge.svg'

// Component
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './DiscountBadge.module.sass'

type Props = {
  discount: number | string
  symbolOnly?: boolean
  size?: 'regular' | 'small'
  variant?: 'discount' | 'savings'
}

const DiscountBadge = ({
  discount,
  symbolOnly = false,
  size = 'regular',
  variant = 'discount'
}: Props): JSX.Element => {
  const namespace = 'atoms'

  const badgeClasses = classNames(STYLES.container, STYLES[size])

  return (
    <div className={badgeClasses}>
      <div className={STYLES.background}>
        {variant === 'discount' && <img alt="" src={DiscountBadgeBG} />}
        {variant === 'savings' && <img alt="" src={SavingsDiscountBadgeBG} />}
      </div>
      <div className={STYLES.copy}>
        <Text
          text={isNumber(discount) ? 'discount_badge.percentage' : discount}
          margin={false}
          variables={{ discount }}
          namespace={namespace}
          translate={isNumber(discount)}
          variant={
            symbolOnly
              ? size === 'small'
                ? 'display20'
                : 'display24'
              : 'display16'
          }
          element="div"
          colour="brandWhite"
          align="center"
          shouldScale={false}
        />
        {!symbolOnly && (
          <Text
            text="discount_badge.off_label"
            margin={false}
            namespace={namespace}
            variant="textRegular14"
            element="div"
            colour="brandWhite"
            align="center"
            bold
            shouldScale={false}
          />
        )}
      </div>
    </div>
  )
}

export { Props }
export default DiscountBadge
