// @noflow

/* eslint-disable i18next/no-literal-string */
import { useNotifications } from '@/context/notifications/notifications'
import { ACCOUNT_ROUTES } from '@/routes'
import { useMutation } from '@apollo/client'
import i18next from 'i18next'
import isEqual from 'lodash/isEqual'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { possessive } from '@/utils/StringHelper'
import { getPersonalityTraitsIcons } from '@/utils/getPersonalityTraitsIcons'

import BREAKPOINTS from '@/constants/Breakpoints'

import useBoolean from '@/hooks/useBoolean'
import useWindowSize from '@/hooks/useWindowSize'

import CheckmarkArea from 'assets/images/icons/checkmarks/checkmark--green-area.svg'

import { Button } from '@/components/elements/atoms/Button'
import { FixedBase } from '@/components/elements/atoms/FixedBase'
import Image from '@/components/elements/atoms/Image/Image'
import Modal from '@/components/elements/atoms/Modal/Modal'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import Text from '@/components/elements/atoms/Text/Text'
import PillGroup, {
  Props as PillGroupProps
} from '@/components/elements/molecules/PillGroup/PillGroup'
import { DOG_PROFILE_QUERY } from '@/components/pages/DogProfile/queries/dogProfileQuery'

import STYLES from './UpdateDogPersonality.module.sass'

import {
  CREATE_DOG_PROFILES_MUTATION,
  UPDATE_DOG_PERSONALITY_MUTATION
} from './mutations/updateDogPersonalityMutation'

import type {
  CreateDogProfilesMutation,
  CreateDogProfilesMutationVariables
} from './mutations/__generated__/CreateDogProfilesMutation'
import type {
  UpdateDogPersonalityMutation,
  UpdateDogPersonalityMutationVariables
} from './mutations/__generated__/UpdateDogPersonalityMutation'
import type {
  DogPersonalityQuery_user_dogs as Dog,
  DogPersonalityQuery
} from './queries/__generated__/DogPersonalityQuery'
import { Activity, FavouriteGame, Trait } from '@/types'

type Props = Partial<DogPersonalityQuery> & {
  selectedDog?: Dog
  loading: boolean
}

type EventType = React.FormEvent<HTMLDivElement> | undefined

const UpdateDogPersonalityContent = ({
  selectedDog,
  loading,
  ...restProps
}: Props): JSX.Element => {
  const container = useRef<HTMLDivElement>(null)
  const { t } = useTranslation('account')
  const { setErrorNotification } = useNotifications()
  const navigate = useNavigate()
  const { windowWidth } = useWindowSize()

  const isMobile = windowWidth < BREAKPOINTS.md

  const loadedData = (() => {
    if (loading) {
      return null
    }

    const { user } = restProps
    const { id: userId, dogs } = user ?? {}

    return {
      userId,
      dogs
    }
  })()

  const [shouldSetInitialData, setShouldSetInitialData] = useState(true)
  const [personality, setPersonality] = useState<{
    traits: Array<Trait>
    activities: Array<Activity>
    favouriteGame: FavouriteGame
  }>({
    traits: [],
    activities: [],
    favouriteGame: 'unknown_favourite_game' as FavouriteGame
  })

  const {
    value: isConfirmationModalOpen,
    setTrue: openConfirmationModal,
    setFalse: closeConfirmationModal,
    toggle: toggleConfirmationModal
  } = useBoolean(false)

  const genderContext = selectedDog?.gender ?? ''
  const possessiveDogName =
    selectedDog && possessive(selectedDog.name, i18next.language)

  const noChangeToPersonality = (() => {
    if (selectedDog?.dogProfile) {
      const {
        dogProfile: { dogPersonalityTraits, dogActivities, favouriteGame }
      } = selectedDog

      const traits = dogPersonalityTraits.map(({ trait }) => trait)
      const activities = dogActivities.map(({ activity }) => activity)

      return (
        isEqual(personality.traits.sort(), traits.sort()) &&
        isEqual(personality.activities.sort(), activities.sort()) &&
        favouriteGame === personality.favouriteGame
      )
    } else {
      return (
        personality.traits.length === 0 &&
        personality.activities.length === 0 &&
        personality.favouriteGame === 'unknown_favourite_game'
      )
    }
  })()

  /**
   * Create dog profiles mutation
   */
  const [createDogProfilesMutation] = useMutation<
    CreateDogProfilesMutation,
    CreateDogProfilesMutationVariables
  >(CREATE_DOG_PROFILES_MUTATION, {
    onError: () => {
      setErrorNotification({
        text: 'update_dog_profile_personality.notifications.error',
        namespace: 'account'
      })
    }
  })

  /**
   * Update dog personality mutation
   *
   * On error, show error message.
   * On success, show success message and navigate back to the previous screen.
   */
  const [
    updateDogPersonalityMutation,
    { loading: updateDogPersonalityLoading }
  ] = useMutation<
    UpdateDogPersonalityMutation,
    UpdateDogPersonalityMutationVariables
  >(UPDATE_DOG_PERSONALITY_MUTATION, {
    onError: () => {
      setErrorNotification({
        text: 'update_dog_profile_personality.notifications.error',
        namespace: 'account'
      })
    },
    onCompleted: () => {
      closeConfirmationModal()

      navigate(`${ACCOUNT_ROUTES.profile}/${selectedDog?.id}`)
    },
    refetchQueries: [
      {
        query: DOG_PROFILE_QUERY,
        variables: {
          caloriesMultipleOf: 10,
          percentageMultipleOf: 5,
          lowerPercentage: 90,
          upperPercentage: 110,
          limit: 5,
          status: ['confirmed', 'pending', 'pending_cancellation', 'cancelled']
        }
      }
    ]
  })

  /**
   * Handler to create dog profile if not available
   */
  const createProfileIfNotAvailable =
    useCallback(async (): Promise<boolean> => {
      if (selectedDog?.dogProfile) {
        return true
      } else {
        try {
          await createDogProfilesMutation({
            variables: {
              userId: loadedData?.userId ?? '',
              dogIdsInput: [selectedDog?.id ?? '']
            }
          })
        } catch (e) {
          setErrorNotification({
            text: 'update_dog_profile_personality.notifications.error',
            namespace: 'account'
          })
        }
      }

      return false
    }, [
      createDogProfilesMutation,
      setErrorNotification,
      selectedDog,
      loadedData
    ])

  /**
   * Hander to update dog personality
   */
  const updateDogPersonalityDetails = useCallback(async () => {
    try {
      const dogProfile = await createProfileIfNotAvailable()

      if (dogProfile) {
        const { traits, activities, favouriteGame } = personality

        await updateDogPersonalityMutation({
          variables: {
            userId: loadedData?.userId ?? '',
            traitsInput: [
              {
                dogId: selectedDog?.id ?? '',
                traits: traits as Trait[]
              }
            ],
            activitiesInput: [
              {
                dogId: selectedDog?.id ?? '',
                activities: activities as Activity[]
              }
            ],
            favouriteGamesInput: [
              {
                dogId: selectedDog?.id ?? '',
                favouriteGame: favouriteGame as FavouriteGame
              }
            ]
          }
        })
      }
    } catch (e) {
      setErrorNotification({
        text: 'update_dog_profile_personality.notifications.error',
        namespace: 'account'
      })
    }
  }, [
    createProfileIfNotAvailable,
    selectedDog?.id,
    loadedData?.userId,
    updateDogPersonalityMutation,
    personality,
    setErrorNotification
  ])

  useEffect(() => {
    if (shouldSetInitialData && loadedData && selectedDog?.dogProfile) {
      const {
        dogProfile: { dogPersonalityTraits, dogActivities, favouriteGame }
      } = selectedDog

      const traits = dogPersonalityTraits.map(({ trait }) => trait)
      const activities = dogActivities.map(({ activity }) => activity)

      setPersonality({
        ...personality,
        traits,
        activities,
        favouriteGame:
          favouriteGame ?? ('unknown_favourite_game' as FavouriteGame)
      })

      // Prevent the effect from running again
      setShouldSetInitialData(false)
    }
  }, [shouldSetInitialData, loadedData, personality, selectedDog])

  const handleSelectPersonalityTrait = useCallback(
    (e: EventType, type: 'traits' | 'activities' | 'favouriteGame'): void => {
      if (e) {
        const clickedElement = e.target as HTMLInputElement
        const value = clickedElement.value

        if (type === 'favouriteGame') {
          setPersonality({
            ...personality,
            [type]: value as FavouriteGame
          })
        } else {
          if (clickedElement.checked) {
            setPersonality({
              ...personality,
              [type]: [...personality[type], value]
            })
          } else {
            setPersonality({
              ...personality,
              [type]: (personality[type] as Array<Trait | Activity>).filter(
                (item) => item !== value
              )
            })
          }
        }
      }
    },
    [personality]
  )

  const traitsPillOptions: PillGroupProps['pillOptions'] = Object.values(
    Trait
  ).map((trait) => ({
    id: trait,
    value: trait,
    name: trait,
    text: `update_dog_profile_personality.traits.options.${trait}`,
    icon: {
      src: getPersonalityTraitsIcons(trait)
    },
    colour: 'yellow300',
    onChange: (e: EventType) => handleSelectPersonalityTrait(e, 'traits'),
    checked: personality.traits.includes(trait),
    namespace: 'account',
    variant: 'checkbox'
  }))

  const activitiesPillOptions: PillGroupProps['pillOptions'] = Object.values(
    Activity
  ).map((activity) => ({
    id: activity,
    value: activity,
    name: activity,
    text: `update_dog_profile_personality.activities.options.${activity}`,
    icon: {
      src: getPersonalityTraitsIcons(activity)
    },
    colour: 'yellow300',
    onChange: (e: EventType) => handleSelectPersonalityTrait(e, 'activities'),
    checked: personality.activities.includes(activity),
    namespace: 'account',
    variant: 'checkbox'
  }))

  const favouriteGamePillOptions: PillGroupProps['pillOptions'] = Object.values(
    FavouriteGame
  ).map((game) => ({
    id: game,
    value: game,
    name: game,
    text: `update_dog_profile_personality.favourite_game.options.${game}`,
    icon: {
      src: getPersonalityTraitsIcons(game)
    },
    colour: 'yellow300',
    onChange: (e: EventType) =>
      handleSelectPersonalityTrait(e, 'favouriteGame'),
    checked: personality.favouriteGame === game,
    namespace: 'account'
  }))

  useEffect(() => {
    if (updateDogPersonalityLoading) {
      openConfirmationModal()
    }
  }, [updateDogPersonalityLoading, openConfirmationModal])

  const getSectionWrapperProps = (type: string) =>
    ({
      headerTypography: {
        text: `update_dog_profile_personality.${type}.title`,
        namespace: 'account',
        align: 'center',
        variables: {
          context: genderContext
        }
      },
      bgColour: 'brandWhite90',
      borderRadius: 16,
      padding: { top: 16, right: 16, bottom: 16, left: 16 }
    } as const)

  return (
    <div ref={container}>
      <SectionWrapper {...getSectionWrapperProps('traits')} margin={{ top: 0 }}>
        <div className={STYLES.sectionContent}>
          <Image
            alt={t('update_dog_profile_personality.traits.image_alt')}
            slug="dogs-yoga"
            image={{
              height: 220,
              width: 300
            }}
            className={STYLES.stretchImg}
          />
          <PillGroup
            pillOptions={traitsPillOptions}
            skeleton={{
              isLoading: loading,
              count: 4
            }}
          />
        </div>
      </SectionWrapper>
      <SectionWrapper {...getSectionWrapperProps('activities')}>
        <div className={STYLES.sectionContent}>
          <Image
            alt={t('update_dog_profile_personality.activities.image_alt')}
            slug="dog-with-toy"
            image={{
              height: 101,
              width: 200
            }}
            className={STYLES.stretchImg}
          />
          <PillGroup
            pillOptions={activitiesPillOptions}
            skeleton={{
              isLoading: loading,
              count: 3
            }}
          />
        </div>
      </SectionWrapper>
      <SectionWrapper {...getSectionWrapperProps('favourite_game')}>
        <div className={STYLES.sectionContent}>
          <Image
            alt={t('update_dog_profile_personality.favourite_game.image_alt')}
            slug="dogs-on-skate"
            image={{
              height: 165,
              width: 200
            }}
            className={STYLES.stretchImg}
          />
          <PillGroup
            pillOptions={favouriteGamePillOptions}
            skeleton={{
              isLoading: loading,
              count: 5
            }}
          />
        </div>
      </SectionWrapper>
      <FixedBase backgroundColor="brandWhite90" container={container}>
        <div className={STYLES.fixedBaseContent}>
          <Button
            identifier="dog_personality.update"
            onClick={updateDogPersonalityDetails}
            typography={{
              text: 'update_dog_profile_personality.button',
              namespace: 'account'
            }}
            skeleton={{
              isLoading: loading
            }}
            disabled={
              loading || updateDogPersonalityLoading || noChangeToPersonality
            }
          />
        </div>
      </FixedBase>
      <Modal
        isModalOpen={isConfirmationModalOpen}
        setOpenModal={toggleConfirmationModal}
        width={600}
        textAlign="center"
        fullHeight={isMobile}
        showCloseButton={false}
        backgroundColour="brandYellow100"
      >
        <div className={STYLES.confirmationModalContent}>
          <img
            src={CheckmarkArea}
            alt={t('update_dog_profile_personality.notifications.confirm.alt')}
          />
          <Text
            namespace="account"
            text="update_dog_profile_personality.notifications.confirm.text"
            variables={{
              possessiveDogName
            }}
            align="center"
            variant="display28"
          />
        </div>
      </Modal>
    </div>
  )
}

export { UpdateDogPersonalityContent }
