// @noflow
import { ACCOUNT_EXTRAS } from '@/routes/routes'
import { ApolloError, useMutation, useQuery } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import { format } from 'date-fns'
import { isEqual } from 'lodash'
import isUndefined from 'lodash/isUndefined'
import kebabCase from 'lodash/kebabCase'
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { toast } from 'react-toastify'

import {
  countryCodeToLocaleCurrency,
  localeToDateLocale
} from '@/utils/countryCodeHelper'

import EditableShopItem from './components/LineItem'
import Text from '@/components/elements/atoms/Text/Text'
import type {
  EditableShopItemProps,
  EditedProductDetails
} from '@/components/elements/molecules/BoxItem/ShopItem/ShopItem'
import type { boxQuery_user_subscription_box_physicalOrderProducts as PhysicalOrderProduct } from '@/components/pages/OrderPage/__generated__/boxQuery'

import STYLES from './ExtrasModal.module.sass'

import {
  UPDATE_ORDER_PRODUCTS,
  UPDATE_RECURRING_ORDER_PRODUCTS
} from './mutations/updateOrderProducts'
import {
  EDIT_EXTRAS_NO_BOX_ID_QUERY,
  EDIT_EXTRAS_QUERY
} from './queries/queries'

import type {
  UpdateOrderProducts,
  UpdateOrderProductsVariables
} from './mutations/__generated__/UpdateOrderProducts'
import type {
  UpdateRecurringOrderProducts,
  UpdateRecurringOrderProductsVariables
} from './mutations/__generated__/UpdateRecurringOrderProducts'
import {
  EditExtrasNoBoxIdQuery,
  EditExtrasNoBoxIdQuery_user_subscription as NoBoxIdSubscription
} from './queries/__generated__/EditExtrasNoBoxIdQuery'
import {
  EditExtrasQuery,
  EditExtrasQueryVariables,
  EditExtrasQuery_user_subscription as EditExtrasSubscription
} from './queries/__generated__/EditExtrasQuery'

import { Code, Language } from '../../../../types'
import { Button } from '../../atoms/Button'
import { CardSkeleton } from '../../atoms/Card/CardSkeleton'
import SkeletonButton from '../../atoms/SkeletonButton/SkeletonButton'
import SkeletonParagraph from '../../atoms/SkeletonParagraph/SkeletonParagraph'
import { ErrorState } from '../../organisms/ErrorState'
import NotificationContent from '../NotificationContent/NotificationContent'
import { transformEditableExtras } from './utils/transformExtras'

type Props = {
  boxId?: string
  hasRemovedExtra?: boolean
  setHasRemovedExtra?: (hasRemovedExtra: boolean) => void
  setUpdating?: (updating: boolean) => void
  onSaveSuccess?: () => void
  onSaveError?: () => void
  onDone?: (redirect?: string) => void
}

type SizeOption = {
  value: string
  label: string
}

const namespace = 'account'

const formatOrderProducts = (products: EditableShopItemProps[]) =>
  products.map((product) => {
    return {
      productId: product.id,
      productVariantId: product.productVariant.id,
      quantity: product.quantity
    }
  })

const ExtrasContent = ({
  boxId,
  hasRemovedExtra,
  setHasRemovedExtra,
  setUpdating,
  onSaveSuccess,
  onSaveError,
  onDone
}: Props): JSX.Element | null => {
  const [extras, setExtras] = useState<EditableShopItemProps[] | []>([])

  const [productIdToUpdate, setProductIdToUpdate] = useState<string | null>(
    null
  )

  const [editedProductDetails, setEditedProductDetails] =
    useState<EditedProductDetails | null>(null)

  const handleDone = useCallback(
    (redirect?: string) => {
      if (typeof onDone === 'function') onDone(redirect)
    },
    [onDone]
  )

  const {
    loading: editExtrasLoading,
    data: editExtrasData,
    error: editExtrasError,
    refetch: editExtrasRefetch
  } = useQuery<EditExtrasQuery, EditExtrasQueryVariables>(EDIT_EXTRAS_QUERY, {
    skip: !boxId,
    variables: {
      boxId: boxId || ''
    }
  })

  const {
    loading: noBoxIdLoading,
    data: noBoxIdData,
    error: noBoxIdError,
    refetch: noBoxIdRefetch
  } = useQuery<EditExtrasNoBoxIdQuery>(EDIT_EXTRAS_NO_BOX_ID_QUERY, {
    skip: !!boxId
  })

  const loading = editExtrasLoading || noBoxIdLoading
  const error = editExtrasError || noBoxIdError
  const data = editExtrasData || noBoxIdData
  const refetch = editExtrasRefetch || noBoxIdRefetch

  const {
    id: userId,
    preferredLanguage,
    shippingCountryCode
  } = data?.user || {
    id: '',
    shippingCountryCode: Code.GB,
    preferredLanguage: Language.en
  }

  const editExtrasSubscription = data?.user
    ?.subscription as EditExtrasSubscription

  const noBoxIdSubscription = data?.user?.subscription as NoBoxIdSubscription

  const box =
    editExtrasSubscription?.box || noBoxIdSubscription?.nextEditableBox

  const { isoDeliveryDate, physicalOrderProducts, isBoosted } = box || {}

  const handleOnCompleted = () => {
    onSaveSuccess && onSaveSuccess()

    // Reset product id to update after mutation is finished
    setProductIdToUpdate(null)

    toast.success(
      <NotificationContent
        copy={{
          text: 'edit_extras.add_success',
          namespace,
          translate: true
        }}
      />,
      { toastId: 'alert-success-message' }
    )
  }

  const handleOnError = (error: ApolloError) => {
    onSaveError && onSaveError()
    refetch()

    const maxQuantityError = error.message.includes(
      'User_exceeded_max_quantity'
    )

    if (!maxQuantityError) {
      Sentry.captureException(
        `Error editing extras in UPDATE_ORDER_PRODUCTS or UPDATE_RECURRING_ORDER_PRODUCTS mutation`,
        {
          extra: {
            error
          }
        }
      )
    }

    const errorMessage = maxQuantityError
      ? 'edit_extras.error_message.full_box_error'
      : 'edit_extras.error_message.generic'

    toast.error(
      <NotificationContent
        copy={{
          text: errorMessage,
          namespace,
          translate: true
        }}
      />,
      { toastId: 'alert-error-message' }
    )

    // reset the has removed extra boolean if mutation failed
    if (hasRemovedExtra && setHasRemovedExtra) {
      setHasRemovedExtra(false)
    }

    // Reset the churn product details if mutation failed
    if (editedProductDetails) {
      setEditedProductDetails(null)
    }

    // Reset product id to update if mutation failed
    if (productIdToUpdate) {
      setProductIdToUpdate(null)
    }
  }

  const [updateOrderProducts, { loading: updateOrderProductsLoading }] =
    useMutation<UpdateOrderProducts, UpdateOrderProductsVariables>(
      UPDATE_ORDER_PRODUCTS,
      {
        onCompleted: handleOnCompleted,
        onError: handleOnError,
        update(cache, { data: mutationResponse }) {
          if (mutationResponse?.orderProductsUpdate) {
            const {
              orderProductsUpdate: { box }
            } = mutationResponse

            if (box) {
              const {
                physicalOrderProducts: updatedPhysicalOrderProducts,
                order: updatedOrder
              } = box

              const boxCacheId = cache.identify({
                __typename: 'Box',
                id: boxId
              })

              cache.modify({
                id: boxCacheId,
                fields: {
                  physicalOrderProducts: () => updatedPhysicalOrderProducts,
                  order: () => updatedOrder
                }
              })
            }
          }
        }
      }
    )

  const [
    updateRecurringOrderProducts,
    { loading: updateRecurringOrderProductsLoading }
  ] = useMutation<
    UpdateRecurringOrderProducts,
    UpdateRecurringOrderProductsVariables
  >(UPDATE_RECURRING_ORDER_PRODUCTS, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
    update(cache, { data: mutationResponse }) {
      if (mutationResponse?.recurringOrderProductsUpdate) {
        const {
          recurringOrderProductsUpdate: { box }
        } = mutationResponse

        if (box) {
          const {
            physicalOrderProducts: updatedPhysicalOrderProducts,
            order: updatedOrder
          } = box

          const boxCacheId = cache.identify({
            __typename: 'Box',
            id: boxId
          })

          cache.modify({
            id: boxCacheId,
            fields: {
              physicalOrderProducts: () => updatedPhysicalOrderProducts,
              order: () => updatedOrder
            }
          })
        }
      }
    }
  })

  const handleUpdateOneOffProducts = useCallback(
    async (products) => {
      const orderProducts = formatOrderProducts(products)

      await updateOrderProducts({
        variables: {
          userId,
          orderProducts,
          boxId: boxId || ''
        }
      })
    },
    [boxId, updateOrderProducts, userId]
  )

  const handleUpdateRepeatingProducts = useCallback(
    async (products) => {
      const orderProducts = formatOrderProducts(products)

      await updateRecurringOrderProducts({
        variables: {
          userId,
          recurringOrderProducts: orderProducts,
          boxId: boxId || ''
        }
      })
    },
    [boxId, updateRecurringOrderProducts, userId]
  )

  useEffect(() => {
    if (setUpdating)
      if (updateOrderProductsLoading || updateRecurringOrderProductsLoading) {
        setUpdating(true)
      } else {
        setUpdating(false)
      }
  }, [
    updateOrderProductsLoading,
    updateRecurringOrderProductsLoading,
    setUpdating
  ])

  const initialExtras = useMemo(() => {
    return physicalOrderProducts
      ? transformEditableExtras(
          physicalOrderProducts,
          countryCodeToLocaleCurrency(shippingCountryCode, preferredLanguage)
        )
      : []
  }, [physicalOrderProducts, preferredLanguage, shippingCountryCode])

  // Ensure that as the user physicalOrderProducts change
  // the extras presented in the modal continue to be updated
  useEffect(() => {
    if (physicalOrderProducts) setExtras(initialExtras)
  }, [
    initialExtras,
    physicalOrderProducts,
    preferredLanguage,
    shippingCountryCode
  ])

  const onQuantityChange = useCallback((id: string, quantity: number) => {
    const updated = (prev: EditableShopItemProps[]) =>
      prev.map((extra) => (extra.id === id ? { ...extra, quantity } : extra))

    setExtras(updated)
  }, [])

  const productsChanged = !isEqual(extras, initialExtras)

  const handleSaveExtras = useCallback(
    async (callback?: () => void) => {
      if (!productsChanged) return

      const oneOffProducts = extras.filter((extra) => !extra.recurring)
      const repeatingProducts = extras.filter((extra) => extra.recurring)

      if (oneOffProducts.length > 0) {
        await handleUpdateOneOffProducts(oneOffProducts)
      }

      if (repeatingProducts.length > 0) {
        await handleUpdateRepeatingProducts(repeatingProducts)
      }

      callback && callback()
    },
    [
      extras,
      handleUpdateOneOffProducts,
      handleUpdateRepeatingProducts,
      productsChanged
    ]
  )

  const handleSaveAndShop = useCallback(async () => {
    const done = () => handleDone(ACCOUNT_EXTRAS.extras)
    await handleSaveExtras(done)
  }, [handleDone, handleSaveExtras])

  const handleSaveAndClose = useCallback(async () => {
    await handleSaveExtras(handleDone)
  }, [handleDone, handleSaveExtras])

  if (loading)
    return (
      <div className={STYLES.list}>
        <SkeletonParagraph
          width={'35em'}
          align="center"
          count={1}
          shortLine={false}
        />
        <CardSkeleton height={'12rem'} />
        <CardSkeleton height={'12rem'} />
        <div className={STYLES.updateButton}>
          <SkeletonButton width={'100%'} />
        </div>
      </div>
    )

  if (isUndefined(physicalOrderProducts) || error)
    return (
      <ErrorState
        error={{
          name: 'Error',
          message: error?.message ?? 'No physical order products found',
          apollo: error
        }}
      />
    )

  return (
    <section className={STYLES.items}>
      <div className={STYLES.deliveryDate}>
        <Text
          text={'edit_extras.delivery_date'}
          variables={{
            date: format(new Date(isoDeliveryDate), 'eeee d LLLL', {
              locale: localeToDateLocale(shippingCountryCode, preferredLanguage)
            })
          }}
          variant="textRegular16"
          element="span"
          colour="brandBlue500"
          margin={false}
          align="center"
          namespace={namespace}
        />
      </div>

      <div className={STYLES.list}>
        {extras.map((extra) => (
          <div
            key={`${kebabCase(extra.id)}-${extra.size}`}
            className={STYLES.card}
          >
            <EditableShopItem
              {...extra}
              isBoostedBox={isBoosted}
              onQuantityChange={onQuantityChange}
              processing={
                loading ||
                updateOrderProductsLoading ||
                updateRecurringOrderProductsLoading
              }
            />
          </div>
        ))}
      </div>

      {onDone && (
        <Fragment>
          <div className={STYLES.updateButton}>
            <Button
              typography={{
                text:
                  updateOrderProductsLoading ||
                  updateRecurringOrderProductsLoading
                    ? 'edit_extras.updating'
                    : 'edit_extras.save_and_close',
                namespace
              }}
              onClick={handleSaveAndClose}
              disabled={
                loading ||
                updateOrderProductsLoading ||
                updateRecurringOrderProductsLoading ||
                !productsChanged
              }
              fullWidth
              identifier="edit_extras.done_button"
            />
          </div>

          <div className={STYLES.updateButton}>
            <Button
              typography={{
                text:
                  updateOrderProductsLoading ||
                  updateRecurringOrderProductsLoading
                    ? 'edit_extras.updating'
                    : 'edit_extras.save_and_shop',
                namespace
              }}
              variant="secondary"
              onClick={handleSaveAndShop}
              disabled={
                loading ||
                updateOrderProductsLoading ||
                updateRecurringOrderProductsLoading ||
                !productsChanged
              }
              fullWidth
              identifier="edit_extras.done_button"
            />
          </div>
        </Fragment>
      )}
    </section>
  )
}

export { Props, SizeOption }
export default ExtrasContent
