// @noflow
import React, { ReactElement } from 'react'
import { TFunction } from 'react-i18next'

import * as Sentry from '@/utils/sentry'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import CloudinaryImage from '@/components/elements/atoms/CloudinaryImage'
import Text from '@/components/elements/atoms/Text/Text'
import SelectableCard from '@/components/elements/molecules/SelectableCard/SelectableCard'
import { deliveryCadenceState } from '@/components/pages/PlansPage/PlansPage'
import { trackPlanFrequencySelected } from '@/components/pages/PlansPage/helpers/analytics'
import type { InitialState as WizardState } from '@/components/pages/SignupWizardPage/SignupWizardPage'
// eslint-disable-next-line no-restricted-imports
import type { PendingSubscription } from '@/components/types'

import STYLES from './OngoingPlanCards.module.sass'

import type { RecommendationQuery_currentUser_User_planRecommendation_plans as MealPlan } from '../../../../__generated__/RecommendationQuery'

import type { Route as PlanRoute } from '../../../../types/routes'
import type { FlowType } from '../../../../types/types'
import {
  PlanCadences,
  RecommendedLabel,
  getNumberOfWeeksWorthOfFood
} from '../../Plan'

type OngoingPlanCardsProps = {
  cadences: MealPlan[]
  namespace: string
  copyContext: string
  selectedCadence: MealPlan
  recommendedCadence: MealPlan
  setSelectedCadence: (cadence: MealPlan) => void
  t: TFunction<string>
  location: PlanRoute
  plansState: PendingSubscription
  wizardState: WizardState
  recommendationState: string
  flowTypeState: FlowType
}

const OngoingPlanCards = ({
  cadences,
  namespace,
  copyContext,
  selectedCadence,
  recommendedCadence,
  setSelectedCadence,
  t,
  location,
  plansState,
  wizardState,
  recommendationState,
  flowTypeState
}: OngoingPlanCardsProps): JSX.Element => {
  const cadenceDurations: PlanCadences[] = ['long', 'default', 'short']
  const { windowWidth } = useWindowSize()

  if (cadences.length > cadenceDurations.length) {
    Sentry.captureException(`Unexpected cadence length`, {
      extra: {
        expected: cadenceDurations.length,
        got: cadences.length
      },
      tags: {
        product: Sentry.Product.PlansFlow
      }
    })
  }

  return (
    <>
      {cadences.map((cadence: MealPlan, i: number) => {
        const cadenceDescriptor: PlanCadences = cadenceDurations[i]
        const description = t(
          `${copyContext}.cadence_selection.ongoing_plan.amount_of_food`,
          { count: getNumberOfWeeksWorthOfFood(cadence.durationInDays) }
        )

        const recommendedLabel: ReactElement[] | undefined =
          recommendedCadence.id === cadence.id
            ? [RecommendedLabel(cadence.id, copyContext, t)]
            : undefined

        return (
          <SelectableCard
            key={`cadence-card-${cadence.id}`}
            layoutVariant={
              windowWidth <= BREAKPOINTS.sm ? 'horizontal' : 'vertical'
            }
            title={t(
              `${copyContext}.cadence_selection.ongoing_plan.options.${cadenceDescriptor}.title`
            )}
            cloudinaryPath={`Web/photos/meals/pouches/butternut-pouches-in-fridge--${cadenceDescriptor}`}
            description={description}
            defaultSelected={selectedCadence.id === cadence.id}
            deselectable={false}
            // eslint-disable-next-line react/jsx-no-bind
            onSelectionChange={(selected: boolean) => {
              if (selected && cadence.id !== selectedCadence.id) {
                setSelectedCadence(cadence)
                trackPlanFrequencySelected(
                  location,
                  plansState,
                  wizardState,
                  recommendationState,
                  cadenceDescriptor,
                  flowTypeState
                )
                deliveryCadenceState(cadenceDescriptor)
              }
            }}
            labels={recommendedLabel}
            tooltipText={t(
              `${copyContext}.cadence_selection.ongoing_plan.options.tooltip_title`
            )}
            tooltipContent={
              <div className={STYLES.cadenceTooltipContent}>
                <div>
                  <CloudinaryImage
                    alt={t(
                      `${copyContext}.cadence_selection.ongoing_plan.image_alt`
                    )}
                    className={STYLES.cadenceTooltipImage}
                    image={{
                      path: `Web/photos/illustrations/freezer-space/freezer-space-${cadenceDescriptor}.svg`
                    }}
                  />
                  <Text
                    namespace={namespace}
                    text={`${copyContext}.cadence_selection.ongoing_plan.options.number_of_pouches`}
                    variables={{ pouchCount: cadence.numberOfPouches }}
                    variant="textRegular14"
                    colour="brandBlue500"
                    bold
                  />
                  <Text
                    namespace={namespace}
                    text={`${copyContext}.cadence_selection.ongoing_plan.options.${cadenceDescriptor}.tooltip`}
                    variant="textRegular14"
                    colour="brandBlue500"
                  />
                </div>
                <Text
                  namespace={namespace}
                  text={`${copyContext}.cadence_selection.ongoing_plan.options.tip`}
                  variant="textRegular14"
                  colour="brandBlue500"
                />
              </div>
            }
            tooltipIdentifier="cadence_selection.ongoing_plan.options.tooltip"
          />
        )
      })}
    </>
  )
}

export default OngoingPlanCards
