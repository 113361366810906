import { gql } from '@apollo/client'

const PRODUCT_COLLECTION_QUERY = gql`
  query ProductCollectionQuery($slug: String!) {
    user {
      id
      shippingCountryCode
      preferredLanguage
      email
      subscription {
        id
        status
        nextEditableBox {
          id
        }
      }
      basket {
        id
        basketProducts {
          quantity
        }
      }
    }
    nonCoreOrderDeliveryFee
    productCollection(slug: $slug) {
      name
      slug
      sizeGuide
      images {
        src
      }
      productSticker {
        slug
        image {
          src
        }
      }
      productBenefits {
        id
        name
        image {
          src
        }
      }
      averageCustomerRating
      countOfCustomerRatings
      publicCustomerReviews {
        id
        author
        rating
        createdAt
        description
      }
      productVariants {
        id
        name
        slug
        grossPrice
        discountedPrice
        subscribeAndSaveable
        bundleItems {
          quantity
          constituent {
            id
            grossPrice
          }
        }
        productVariantDeliveryTypes {
          deliveryType
          adjustedGrossPrice
          netPrice
          boostedNetPrice
        }
        unavailableObject {
          reason
          reasonCopy
        }
      }
      about
      description
    }
  }
`
export { PRODUCT_COLLECTION_QUERY }
