// @noflow
import { ShopItemFragment } from '../fragments/__generated__/ShopItemFragment'
import { NonPaymentReason } from '@/types'

type Product = {
  quantity: number
  productVariant: ShopItemFragment
  nonPaymentReason: NonPaymentReason | null
  requiresPayment?: boolean
  recurring?: boolean
}

type Args = {
  product: Product
  finalQuantity: number
  finalSizeSlug: string | null
  binSelected: boolean
}

const editShopItemAnalytics = ({
  product,
  finalQuantity,
  finalSizeSlug,
  binSelected
}: Args): void => {
  const {
    recurring,
    quantity: initialQuantity,
    nonPaymentReason,
    requiresPayment,
    productVariant: {
      id: productVariantId,
      name,
      recurringDeliveryType,
      oneOffDeliveryType,
      productCollection: { slug: productCollectionSlug }
    }
  } = product

  const productNetPrice = recurring
    ? recurringDeliveryType?.netPrice || 0
    : oneOffDeliveryType?.netPrice || 0

  if (finalQuantity - initialQuantity !== 0 || name !== finalSizeSlug) {
    /**
     * Calculate the total spend of the initial and edited product.
     */
    const initialSpend = initialQuantity * productNetPrice
    const finalSpend = finalQuantity * productNetPrice

    /**
     * Check if the product is a one-off, recurring, or free sample product.
     */
    const purchaseType = () => {
      if (recurring) return 'recurring'
      if (nonPaymentReason === NonPaymentReason.free_sample)
        return NonPaymentReason.free_sample

      return 'one_off'
    }

    /**
     * Calculate the difference between the initial and edited product quantity.
     */
    const delta = finalQuantity - initialQuantity

    const event = 'Shop: Edited extras'
    const properties = {
      productVariantId,
      productCollectionSlug,
      sizeSlug: name,
      finalSizeSlug: finalSizeSlug || name,
      purchaseType: purchaseType(),
      requiresPayment,
      initialQuantity,
      finalQuantity,
      initialSpend,
      finalSpend,
      binSelected,
      delta
    }
    window.analytics.track(event, properties)
  }
}

export { editShopItemAnalytics }
