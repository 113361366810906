import i18next from 'i18next'

import * as Sentry from '@/utils/sentry'

import { checkoutPageState } from '../../../CheckoutPage'
import { bannerMessageState } from '../Banner'
import { SubscriptionData } from './submitSubscription'

type CardAuthenticationStatus =
  | 'requires_payment_method'
  | 'requires_confirmation'
  | 'requires_action'
  | 'processing'
  | 'canceled'
  | 'succeeded'

type SCAResponse = {
  card_authentication_setup: {
    client_secret: string
    customer_id: string
    id: string
    status: CardAuthenticationStatus
  }
}

const requestFromSCAController = ({
  email,
  csrfToken,
  data
}: {
  email: string
  csrfToken: string
  data: SubscriptionData
}): Promise<SCAResponse> => {
  const { user } = checkoutPageState()
  const { shippingCountryCode } = user

  const endpoint =
    '/api/payment_methods/credit_cards/strong_customer_authentication/create'

  const generateAuthenticationType = (
    paymentMethodType: SubscriptionData['payment_method_type'] | undefined
  ) => {
    switch (paymentMethodType) {
      case 'credit_card':
      case 'billing_agreement': {
        return 'card'
      }
      case 'sepa_direct_debit': {
        if (shippingCountryCode === 'BE') {
          return 'bancontact'
        } else {
          return 'ideal'
        }
      }
      default: {
        Sentry.captureException(
          `Unhandled paymentMethodType in reqestFromSCAController`,
          {
            extra: {
              paymentMethodType
            },
            tags: {
              product: Sentry.Product.Checkout
            }
          }
        )

        return 'card'
      }
    }
  }

  return fetch(`${endpoint}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': csrfToken
    },
    credentials: 'same-origin',
    body: JSON.stringify({
      email,
      csrf_token: csrfToken,
      authentication_type: generateAuthenticationType(
        data?.payment_method_type
      ),
      data
    })
  }).then((response) => {
    if (response.ok) {
      try {
        return response.json()
      } catch (error) {
        Sentry.captureException(
          `Encountered error in response from requestFromSCAController`,
          {
            extra: {
              endpoint,
              error
            },
            tags: {
              product: Sentry.Product.Checkout
            }
          }
        )

        bannerMessageState({
          message: i18next.t('checkout:errors.delivery_details_fetch'),
          type: 'error'
        })
      }
    } else {
      bannerMessageState({
        message: i18next.t('checkout:errors.delivery_details_fetch'),
        type: 'error'
      })
      Sentry.captureException(
        `Recieved error in response from requestFromSCAController`,
        {
          extra: {
            response
          },
          tags: {
            product: Sentry.Product.Checkout
          }
        }
      )
    }
  })
}

export default requestFromSCAController
