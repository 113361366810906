import { gql } from '@apollo/client'
import { addDays, formatISO } from 'date-fns'

const BOX_ORDER_FRAGMENT = gql`
  fragment boxOrderFragment on Box {
    id
    isoDeliveryDate
    cutOffDate
    consignment {
      status
    }
    order {
      id
      discountTotalPercentage
      subtotal
      totalPrice
      orderProducts {
        id
        nonPaymentReason
      }
    }
    isReplacementBox
    isFreeBox
    amendable
    isNextEditableBox
    physicalOrderProducts {
      id
      nonPaymentReason
      quantity
      productVariant {
        id
        name
        productCollection {
          id
          name
          thumbnail {
            src
          }
        }
      }
    }
    meals {
      id
      flavour {
        id
        slug
      }
      quantity
    }
    plan {
      id
      pouchSize
    }
  }
`

const NON_CORE_ORDER_FRAGMENT = gql`
  fragment nonCoreOrderFragment on NonCoreOrder {
    id
    orderParts {
      deliveryDate
      orderProducts {
        id
        quantity
        productVariant {
          id
          productCollection {
            id
            thumbnail {
              src
            }
          }
        }
      }
    }
  }
`

const PAST_ORDERS = gql`
  ${BOX_ORDER_FRAGMENT}
  ${NON_CORE_ORDER_FRAGMENT}
  query pastOrderQuery(
    $to: ISO8601Date!
    $offset: Int!
    $limit: Int!
    $order: String
  ) {
    user {
      id
      shippingCountryCode
      ordersByDate(to: $to, offset: $offset, limit: $limit, order: $order) {
        __typename
        ... on Box {
          id
          ...boxOrderFragment
        }
        ... on NonCoreOrder {
          id
          ...nonCoreOrderFragment
        }
      }
    }
  }
`

const UPCOMING_ORDERS = gql`${BOX_ORDER_FRAGMENT}${NON_CORE_ORDER_FRAGMENT}
  query upcomingOrderQuery($offset: Int!, $limit: Int!) {
    user {
      id
      shippingCountryCode
      ordersByDate(from: "${formatISO(
        addDays(new Date(), 1)
      )}", offset: $offset, limit: $limit) {
        __typename
        ... on Box {
          ...boxOrderFragment
        }
        ... on NonCoreOrder {
          ...nonCoreOrderFragment
        }
      }
    }
    shippingCountries {
      id
      code
      showUpcomingOrderSeeMoreExtras
    }
  }
`

export { PAST_ORDERS, UPCOMING_ORDERS }
