// @noflow
// we import the 'store' from the wizard page
import { useReactiveVar } from '@apollo/client'
import i18next from 'i18next'
import React, { FormEvent, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { pronounContext } from '@/utils/StringHelper'

import Text from '@/components/elements/atoms/Text/Text'
import PillGroup from '@/components/elements/molecules/PillGroup/PillGroup'
import TextSeparator from '@/components/elements/molecules/TextSeparator/TextSeparator'
import StickyNavigation from '@/components/elements/organisms/StickyNavigation/StickyNavigation'
import {
  routeToNextStep,
  routeToPrevStep
} from '@/components/pages/SignupWizardPage/helpers/wizardRoutes'
import type { Route } from '@/components/pages/SignupWizardPage/types/routes'

import STYLES from './Gender.module.sass'

import { Gender as GenderType } from '@/types'

import { InitialState, wizardPageState } from '../../SignupWizardPage'
import useImplicitFormSubmission from '../../helpers/useImplicitFormSubmission'

type Props = {
  variant?: keyof typeof STYLES
  namespace: string
}

type DesexedProps = {
  namespace: string
  dog: InitialState['dogs'][0]
  onChange: (e: FormEvent<HTMLDivElement>, index: number) => void
  index: number
}

const copyContext = 'gender_step'

const Desexed = ({
  namespace,
  dog,
  onChange,
  index
}: DesexedProps): JSX.Element => {
  const { name, gender } = dog

  return (
    <div
      className={STYLES.subQuestion}
      // eslint-disable-next-line react/jsx-no-bind
      onChange={(e) => onChange(e, index)}
    >
      <Text
        namespace={namespace}
        variant="textRegular16"
        colour="brandBlue500"
        text={`${copyContext}.desexed`}
        variables={{
          context: pronounContext([gender || GenderType.male], i18next.language)
        }}
      />
      <PillGroup
        pillOptions={[
          {
            id: `${name}-${index.toString()}-yes`,
            dataTestId: 'desexed-true',
            name: `${name}-${index.toString()}-desexed`,
            text: `${copyContext}.yes_label`,
            value: 'yes',
            defaultChecked: dog.neutered === true,
            namespace
          },
          {
            id: `${name}-${index.toString()}-no`,
            dataTestId: 'desexed-false',
            name: `${name}-${index.toString()}-desexed`,
            text: `${copyContext}.no_label`,
            value: 'no',
            defaultChecked: dog.neutered === false,
            namespace
          }
        ]}
      />
    </div>
  )
}

const Gender = ({ variant, namespace }: Props): JSX.Element => {
  const route = useLocation().pathname as Route
  const wizardState = useReactiveVar(wizardPageState)
  const { dogs } = wizardState

  const formIsValid = dogs.every(
    (dog) => dog.gender !== null && dog.neutered !== null
  )

  const navigate = useNavigate()

  const onEnterPress = useCallback(() => {
    navigate(routeToNextStep({ route }))
  }, [navigate, route])

  useImplicitFormSubmission({ formIsValid, onEnterPress })

  const handleGenderChange = useCallback(
    (e: FormEvent<HTMLDivElement>, index: number): void => {
      const gender = e.target as HTMLTextAreaElement
      dogs[index].gender = gender.value as GenderType
      wizardPageState({
        ...wizardState,
        dogs
      })
    },
    [dogs, wizardState]
  )

  const handleNeuteredChange = useCallback(
    (e: FormEvent<HTMLDivElement>, index: number): void => {
      const neutered = e.target as HTMLTextAreaElement
      dogs[index].neutered = neutered.value === 'yes'
      wizardPageState({
        ...wizardState,
        dogs
      })
    },
    [dogs, wizardState]
  )

  return (
    <>
      <div className={variant ? STYLES[variant] : ''}>
        <div className={STYLES.question} id={'boysOrGirlsStep'}>
          {dogs.map((dog, index) => (
            <div key={index.toString()}>
              <div
                className={STYLES.subQuestion}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={(e) => handleGenderChange(e, index)}
              >
                <TextSeparator
                  text={`${copyContext}.text_separator`}
                  namespace={namespace}
                  variables={{ dogName: dog.name }}
                />
                <PillGroup
                  pillOptions={[
                    {
                      id: `${dog.name}-${index.toString()}-female`,
                      dataTestId: 'gender-female',
                      name: `${dog.name}-${index.toString()}-gender`,
                      text: `${copyContext}.girl`,
                      value: GenderType.female,
                      defaultChecked: dog.gender === GenderType.female,
                      namespace
                    },
                    {
                      id: `${dog.name}-${index.toString()}-male`,
                      dataTestId: 'gender-male',
                      name: `${dog.name}-${index.toString()}-gender`,
                      text: `${copyContext}.boy`,
                      value: GenderType.male,
                      defaultChecked: dog.gender === GenderType.male,
                      namespace
                    }
                  ]}
                />
              </div>
              {dog.gender && (
                <Desexed
                  namespace={namespace}
                  dog={dog}
                  onChange={handleNeuteredChange}
                  index={index}
                />
              )}
            </div>
          ))}
        </div>
      </div>
      <StickyNavigation
        variant="twoButtons"
        buttonOne={{
          url: routeToPrevStep({ route }),
          text: 'wizard_flow:sticky_navigation.back',
          variant: 'secondary',
          iconColour: 'brandRed400'
        }}
        buttonTwo={{
          dataTestId: 'next-button',
          url: routeToNextStep({ route }),
          text: 'wizard_flow:sticky_navigation.continue',
          variant: 'primary',
          iconColour: 'brandWhite'
        }}
        disabled={!formIsValid}
      />
    </>
  )
}
export { Props }
export default Gender
