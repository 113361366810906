// @no-flow
import { useReactiveVar } from '@apollo/client'
import i18next from 'i18next'
import { useCallback, useEffect, useState } from 'react'

import { bannerMessageState } from '../../components/Banner/Banner'
import { totalAmount } from '../../components/DsAppOrderSummary/DsAppOrderSummary'

import * as ANALYTICS from '../../Analytics/Analytics'
import { State } from '../../PayOnOwnDevicePage'
import {
  ActivePaymentViews,
  LoginUrlProps,
  setSubmissionState
} from '../../screens/PaymentScreen/PaymentScreen'
import { PaypalResponse } from '../../types'
import buySubscriptionWithPayPal from './buySubscriptionWithPayPal'
import getPaypalPaymentAuthorisation from './getPaypalPaymentAuthorisation'

enum PayPalInContextAction {
  Approve = 'approve',
  Cancel = 'cancel',
  Error = 'error'
}

type UseBuySubscriptionWithPayPal = {
  payPalButtonClicked: (redirectUrl?: string) => void
  payPalBillingAgreementTokenReceived: boolean
  payPalAuthorisationResponse: PaypalResponse | null
  payPalPaymentSuccess: boolean
  handlePayPalInContextSubmission: ({
    action,
    token
  }: {
    action: PayPalInContextAction
    token: string
  }) => void
}

const useBuySubscriptionWithPayPal = ({
  csrfToken,
  activePaymentView,
  setActivePaymentView,
  navigateToNextStep,
  paymentPageState
}: {
  csrfToken: string
  activePaymentView: ActivePaymentViews
  setActivePaymentView: (view: ActivePaymentViews) => void
  navigateToNextStep: (input: LoginUrlProps) => void
  paymentPageState: State
}): UseBuySubscriptionWithPayPal => {
  const { amount } = useReactiveVar(totalAmount)

  const [payPalAuthorisationResponse, setPayPalAuthorisationResponse] =
    useState<PaypalResponse | null>(null)
  const [
    payPalBillingAgreementTokenReceived,
    setPayPalBillingAgreementTokenReceived
  ] = useState(false)

  const payPalPaymentSuccess = payPalBillingAgreementTokenReceived

  const payPalButtonClicked = useCallback(() => {
    ANALYTICS.payPalButtonClicked()
    ANALYTICS.submissionAttempt('payPal')
    setActivePaymentView('paypal')
    // Reset the error banner if it's showing
    bannerMessageState({ message: '' })
  }, [setActivePaymentView])

  useEffect((): void => {
    if (!payPalAuthorisationResponse?.redirect_url) {
      getPaypalPaymentAuthorisation({
        redirectUrl: window.location.href,
        csrfToken,
        setPayPalAuthorisationResponse,
        totalAmount: amount,
        countryCode:
          paymentPageState.data.directSalesPendingSubscription.address
            .shippingCountry.code
      })
    }
  }, [
    payPalAuthorisationResponse,
    csrfToken,
    amount,
    paymentPageState.data.directSalesPendingSubscription.address.shippingCountry
      .code
  ])

  useEffect((): void => {
    const updateAfterPayPalBillingTokenReceived = (
      activePaymentView: ActivePaymentViews
    ) => {
      if (
        window.location.search.includes('token') &&
        activePaymentView === 'paypal'
      ) {
        // checkout with PayPal if PayPal token present in the url
        const urlParams = new URLSearchParams(window.location.search)
        const token = urlParams.get('token')

        if (token) {
          setSubmissionState({ type: 'loading' })
          setPayPalBillingAgreementTokenReceived(true)
          buySubscriptionWithPayPal({
            token,
            data: paymentPageState,
            navigateToNextStep
          })
        }
      } else {
        setPayPalBillingAgreementTokenReceived(false)
        setSubmissionState({
          type: 'error',
          error: i18next.t('checkout:errors.delivery_details_fetch')
        })
      }
    }

    updateAfterPayPalBillingTokenReceived(activePaymentView)
  }, [activePaymentView, navigateToNextStep, paymentPageState])

  const handlePayPalInContextSubmission = useCallback(
    ({ action, token }: { action: PayPalInContextAction; token: string }) => {
      // Reset the error banner if it's showing
      bannerMessageState({ message: '' })

      if (action === PayPalInContextAction.Approve) {
        setSubmissionState({ type: 'loading' })
        setActivePaymentView('paypal')
        setPayPalBillingAgreementTokenReceived(true)
        buySubscriptionWithPayPal({
          token,
          data: paymentPageState,
          navigateToNextStep
        })
      }
    },
    [navigateToNextStep, paymentPageState, setActivePaymentView]
  )

  return {
    payPalButtonClicked,
    payPalBillingAgreementTokenReceived,
    payPalAuthorisationResponse,
    payPalPaymentSuccess,
    handlePayPalInContextSubmission
  }
}

export default useBuySubscriptionWithPayPal

export { PayPalInContextAction, UseBuySubscriptionWithPayPal }
