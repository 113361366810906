// @flow

opaque type CSRFToken = string
type CSRFTokenCandidate = string

const stubCSRFToken: CSRFToken = 'stub'

const toValidCSRFToken = (candidate: CSRFTokenCandidate): ?CSRFToken => {
  if (candidate.length > 0) {
    return candidate
  }
}

export type {
  CSRFToken
}

export {
  toValidCSRFToken,
  stubCSRFToken
}
