// @noflow
import type { Language } from '@/packs/localisation'
import { useQuery } from '@apollo/client'
import {
  addMonths,
  format,
  formatISO,
  getDaysInMonth,
  parseISO,
  startOfMonth
} from 'date-fns'
import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { localeToDateLocale } from '@/utils/countryCodeHelper'
import * as Sentry from '@/utils/sentry'

import rightArrow from 'assets/images/icons/arrows/arrow-blue.svg'
import calendarIcon from 'assets/images/icons/calendar.svg'

import { Expand } from '@/components/elements/atoms/Animated/Animated'
import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'
import FlatButton from '@/components/elements/atoms/FlatButton/FlatButton'
import MultiLineTextField from '@/components/elements/atoms/MultiLineTextField/MultiLineTextField'
import Text from '@/components/elements/atoms/Text/Text'
import * as inputHelpers from '@/components/pages/CheckoutPage/helpers/inputs'

import { DELIVERY_DATE_MODAL_QUERY } from './queries/deliveryDateModalQuery'
import { DELIVERY_DATE_MODAL_WITH_COURIER_QUERY } from './queries/deliveryDateModalWithCourierQuery'

import type { CheckoutPage } from '../../queries/__generated__/CheckoutPage'
import type {
  DeliveryDateModalQuery,
  DeliveryDateModalQueryVariables
} from './queries/__generated__/DeliveryDateModalQuery'
import type {
  DeliveryDateModalWithCourierQuery_calendarDates as CalendarDates,
  DeliveryDateModalWithCourierQuery,
  DeliveryDateModalWithCourierQueryVariables
} from './queries/__generated__/DeliveryDateModalWithCourierQuery'
import { ensureNever } from '@/typescript/utils'

import * as ANALYTICS from '../../Analytics/CheckoutAnalytics'
import { checkoutPageState } from '../../CheckoutPage'
import type { DeliveryDetailsFormKey, FormSectionKey } from '../../types'
import { StandardInputField } from '../InputField/InputField'
import DeliveryDateModal from './DeliveryDateModal'
import DeliveryDateModalWithCourier from './DeliveryDateModalWithCourier/DeliveryDateModalWithCourier'
import DeliveryFeeInfo from './DeliveryFeeInfo'

type Props = {
  namespace: string
  preferredLanguage: Language
  shippingCountryCode: CheckoutPage['guest']['assumedShippingCountry']['code']
  shouldSeeCourierSelection: boolean
  shouldSeeDeliveryNotes: boolean
}

const DeliveryDateSection = ({
  namespace,
  preferredLanguage,
  shippingCountryCode,
  shouldSeeCourierSelection,
  shouldSeeDeliveryNotes
}: Props): JSX.Element | null => {
  const { t } = useTranslation(namespace)
  const copyContext = 'delivery_date_section'

  const { sections, user } = checkoutPageState()
  const { addressDetails, deliveryDetails } = sections
  const { form: addressDetailsForm, valid: addressDetailsValid } =
    addressDetails
  const { postcode, city } = addressDetailsForm
  const { visible, form, valid } = deliveryDetails
  const { deliveryFee, requiresPaymentDetailsOnCheckout } = user
  const { selectedDeliveryDate, mobileNumber, deliveryNotes } = form

  const dateLocale = localeToDateLocale(shippingCountryCode, preferredLanguage)

  const [showDeliveryDateModal, setShowDeliveryDateModal] = useState(false)
  const [calendarDates, setCalendarDates] = useState<CalendarDates[]>([])

  const today = new Date()
  const initialMonthInView = startOfMonth(today)
  const secondMonthInview = addMonths(initialMonthInView, 1)
  const nDays =
    getDaysInMonth(initialMonthInView) + getDaysInMonth(secondMonthInview)

  const { loading: deliveryDateLoading, data: deliveryDateData } = useQuery<
    DeliveryDateModalQuery,
    DeliveryDateModalQueryVariables
  >(DELIVERY_DATE_MODAL_QUERY, {
    variables: {
      calendarInitDate: formatISO(new Date(), { representation: 'date' }),
      nDays: 28,
      city: city.value,
      postcode: postcode.value,
      shouldAttemptToOfferNextDayDelivery: true
    },
    skip:
      shouldSeeCourierSelection || !!postcode.errorMessage || !postcode.value,
    onError: (error) => {
      Sentry.captureException(`Error occurred in DELIVERY_DATE_MODAL_QUERY`, {
        extra: {
          error
        },
        tags: {
          product: Sentry.Product.Checkout
        }
      })
    }
  })

  const {
    loading: deliveryDateWithCourierLoading,
    data: deliveryDateWithCourierData
  } = useQuery<
    DeliveryDateModalWithCourierQuery,
    DeliveryDateModalWithCourierQueryVariables
  >(DELIVERY_DATE_MODAL_WITH_COURIER_QUERY, {
    variables: {
      calendarInitDate: initialMonthInView,
      nDays: nDays,
      city: city.value,
      postcode: postcode.value,
      shouldAttemptToOfferNextDayDelivery: true
    },
    skip:
      !shouldSeeCourierSelection || !!postcode.errorMessage || !postcode.value,
    onCompleted: ({ deliveryAreaOptions, calendarDates }) => {
      if (!isEmpty(deliveryAreaOptions)) {
        selectedDeliveryDate.selectedDeliveryArea = deliveryAreaOptions?.[0]
        setCalendarDates(deliveryAreaOptions?.[0]?.calendarDates)
      } else {
        selectedDeliveryDate.selectedDeliveryArea = null
        setCalendarDates(calendarDates)
      }

      checkoutPageState({
        ...checkoutPageState(),
        sections
      })
    },
    onError: (error) => {
      Sentry.captureException(
        `Error occurred in DELIVERY_DATE_MODAL_WITH_COURIER_QUERY`,
        {
          extra: {
            error
          },
          tags: {
            product: Sentry.Product.Checkout
          }
        }
      )
    }
  })

  const toggleDeliveryDateModal = useCallback(() => {
    ANALYTICS.deliveryDateModalToggle(!showDeliveryDateModal)
    setShowDeliveryDateModal(!showDeliveryDateModal)
  }, [showDeliveryDateModal])

  const updateDeliveryDetails = (
    fieldName: DeliveryDetailsFormKey,
    value: string
  ) => {
    switch (fieldName) {
      case 'selectedDeliveryDate':
        return
      case 'deliveryNotes':
      case 'mobileNumber': {
        sections.deliveryDetails.form[fieldName].value = value

        // Given the input is being typed into, update its inputInteractionState
        const newInteractionState = inputHelpers.interactingState(
          sections.deliveryDetails.form[fieldName].inputInteractionState
        )
        sections.deliveryDetails.form[fieldName].inputInteractionState =
          newInteractionState

        // Is the current value of the input valid?
        const errorMessage = inputHelpers.validate(
          value,
          fieldName,
          shippingCountryCode
        )
        // If the current value is valid, clear any existing error messages
        if (!errorMessage)
          sections.deliveryDetails.form[fieldName].errorMessage = null

        return checkoutPageState({
          ...checkoutPageState(),
          sections
        })
      }

      default:
        ensureNever(fieldName)
    }
  }

  const validateInputField = useCallback(
    (fieldName: DeliveryDetailsFormKey, value: string) => {
      switch (fieldName) {
        case 'selectedDeliveryDate':
          return
        case 'deliveryNotes':
        case 'mobileNumber': {
          // Is the current value of the input valid?
          const errorMessage = inputHelpers.validate(
            value,
            fieldName,
            shippingCountryCode,
            selectedDeliveryDate.selectedDeliveryArea?.preferredCarrierService
              .deliveryNotesLimit
          )

          // If not, append the relevant errorMessage to the state
          sections.deliveryDetails.form[fieldName].errorMessage = errorMessage

          // Set the inputInteractionState directly
          sections.deliveryDetails.form[fieldName].inputInteractionState =
            'NotInteractingInteracted'

          ANALYTICS.blurField(
            fieldName,
            sections.deliveryDetails.form[fieldName]
          )

          return checkoutPageState({
            ...checkoutPageState(),
            sections
          })
        }
        default:
          ensureNever(fieldName)
      }
    },
    [
      sections,
      selectedDeliveryDate.selectedDeliveryArea?.preferredCarrierService
        .deliveryNotesLimit,
      shippingCountryCode
    ]
  )

  const validateAllSectionInputs = useCallback(() => {
    let isFormValid = true

    Object.values(sections).forEach((section) => {
      const { form } = section

      Object.entries(form).forEach((form) => {
        const [key, value] = form

        // We don't need to evaluate selectedDeliveryDate or selectedPaymentMethod
        // as they are not free text inputFields. We also don't need to validate
        // searchTerm as it's optional if user selects to input their address
        // manually without the AddressFinder. DeliveryNotes is also optional
        const excludedFormSections = [
          'searchTerm',
          'selectedDeliveryDate',
          'selectedPaymentMethod'
        ]

        if (!excludedFormSections.includes(key)) {
          value.errorMessage = inputHelpers.validate(
            value.value,
            key as FormSectionKey,
            user.shippingCountryCode,
            selectedDeliveryDate.selectedDeliveryArea?.preferredCarrierService
              .deliveryNotesLimit
          )
          if (value.errorMessage) {
            checkoutPageState({
              ...checkoutPageState(),
              sections
            })
            isFormValid = false
          }
        }
      })
    })
    return isFormValid
  }, [sections, user.shippingCountryCode, selectedDeliveryDate])

  const continueButtonClick = useCallback(() => {
    const isFormValid = validateAllSectionInputs()

    if (isFormValid) {
      sections.deliveryDetails.visible = false
      sections.paymentDetails.visible = true

      checkoutPageState({
        ...checkoutPageState(),
        sections
      })
    }
  }, [validateAllSectionInputs, sections])

  const editSection = useCallback(
    (event) => {
      event.preventDefault()

      sections.deliveryDetails.visible = true

      checkoutPageState({
        ...checkoutPageState(),
        sections
      })
    },
    [sections]
  )
  useEffect(() => {
    const isMobileNumberValidToRegion = (): boolean => {
      const mobileNumberOptionalRegions =
        shippingCountryCode === 'GB' || shippingCountryCode === 'NI'
      if (mobileNumberOptionalRegions) {
        return true
      } else {
        if (mobileNumber.value !== '') {
          return true
        } else return false
      }
    }

    const isDeliveryDateSectionValid =
      selectedDeliveryDate.errorMessage === null &&
      mobileNumber.errorMessage === null &&
      deliveryNotes.errorMessage === null &&
      isMobileNumberValidToRegion()

    sections.deliveryDetails.valid = isDeliveryDateSectionValid

    checkoutPageState({
      ...checkoutPageState(),
      sections
    })
  }, [
    mobileNumber.errorMessage,
    sections,
    selectedDeliveryDate.errorMessage,
    mobileNumber.value,
    shippingCountryCode,
    deliveryNotes.errorMessage
  ])

  const titleText = shouldSeeCourierSelection ? 'title_alternative' : 'title'

  const deliveryDatesLoading = shouldSeeCourierSelection
    ? deliveryDateWithCourierLoading
    : deliveryDateLoading

  const courierInfoCopy = (): string => {
    const courierName =
      sections.deliveryDetails.form.selectedDeliveryDate.selectedDeliveryArea
        ?.preferredCarrierService.carrier.name

    if (shouldSeeCourierSelection) {
      switch (courierName) {
        case 'Goodspeed':
          return t(`${copyContext}.goodspeed_courier_selection`)
        case 'DPD Poland':
        default:
          return t(`${copyContext}.dpd_poland_courier_selection`)
      }
    } else {
      switch (shippingCountryCode) {
        case 'GB':
        case 'NI':
          return t(`${copyContext}.text_optional`)
        default:
          return t(`${copyContext}.text`)
      }
    }
  }

  return (
    <section
      className={`delivery-date-section section ${
        visible ? 'section--visible' : 'section--hidden'
      }`}
    >
      <Card shadow>
        <div className="card-content">
          <div className="card-title">
            <Text
              namespace={namespace}
              text={`${copyContext}.${titleText}`}
              element="h2"
              variant="display20"
              align="left"
              margin={false}
            />
            {!visible && valid && addressDetailsValid && (
              <FlatButton
                variant="yellow200"
                onClick={editSection}
                identifier="Delivery Date Edit"
                screenIdentifier="checkout_page"
                text={{
                  namespace: namespace,
                  text: 'edit_button'
                }}
              />
            )}
          </div>
          {!visible && valid && (
            <Text
              colour="brandBlue400"
              translate={false}
              align="left"
              text={format(
                parseISO(selectedDeliveryDate.value),
                'EEEE MMMM do yyy',
                {
                  locale: dateLocale
                }
              )}
            />
          )}
          <Expand show={visible} margin={{ top: 1.6 }} maxHeight={483}>
            <DeliveryFeeInfo
              deliveryFee={deliveryFee}
              relevantPostcode={postcode.value}
            />
            <div>
              <button
                className="delivery-day-details"
                onClick={toggleDeliveryDateModal}
                type="button"
                disabled={deliveryDatesLoading}
              >
                <div className="delivery-day-details__selected-date">
                  {shouldSeeCourierSelection ? (
                    <p className="delivery-day-details__selected-date__formatted-date">
                      {format(
                        parseISO(selectedDeliveryDate.value),
                        'dd/MM/yy',
                        {
                          locale: dateLocale
                        }
                      )}
                      {!isEmpty(selectedDeliveryDate.selectedDeliveryArea) &&
                        ` (${selectedDeliveryDate.selectedDeliveryArea?.preferredCarrierService.carrier.name})`}
                    </p>
                  ) : (
                    <p className="delivery-day-details__selected-date__formatted-date">
                      {format(
                        parseISO(selectedDeliveryDate.value),
                        'E d LLL yy',
                        {
                          locale: dateLocale
                        }
                      )}
                    </p>
                  )}

                  {selectedDeliveryDate.value ===
                    user.firstDeliverableDate.date && (
                    <span className="delivery-day-details__earliest-delivery-indicator">
                      {t(`delivery_date_button.earliest_delivery`)}
                    </span>
                  )}
                </div>
                <div
                  className={`delivery-day-details__change-date-button ${
                    deliveryDatesLoading
                      ? 'delivery-day-details__change-date-button--disabled'
                      : ''
                  }`}
                >
                  <div className="delivery-day-details__change-date-button__calendar">
                    <span className="delivery-day-details__change-date-button__calendar__date">
                      {format(parseISO(selectedDeliveryDate.value), 'd', {
                        locale: dateLocale
                      })}
                    </span>
                    <img
                      alt={t(`delivery_date_button.icon_alt`)}
                      className="delivery-day-details__selected-date__calendar__icon"
                      src={calendarIcon}
                    />
                  </div>
                  <div className="delivery-day-details__change-date-button__change">
                    <p>{t(`delivery_date_button.button_text`)}</p>
                    <img alt="" src={rightArrow} />
                  </div>
                </div>
              </button>
              {shouldSeeCourierSelection && (
                <DeliveryDateModalWithCourier
                  namespace={namespace}
                  toggleDeliveryDateModal={toggleDeliveryDateModal}
                  isDeliveryDateModalOpen={showDeliveryDateModal}
                  shippingCountryCode={shippingCountryCode}
                  preferredLanguage={preferredLanguage}
                  calendarDates={calendarDates}
                  setCalendarDates={setCalendarDates}
                  deliveryDateWithCourierLoading={
                    deliveryDateWithCourierLoading
                  }
                  deliveryDateWithCourierData={deliveryDateWithCourierData}
                />
              )}
              {!shouldSeeCourierSelection && deliveryDateData && (
                <DeliveryDateModal
                  namespace={namespace}
                  openDeliveryDateModal={toggleDeliveryDateModal}
                  isDeliveryDateModalOpen={showDeliveryDateModal}
                  shippingCountryCode={shippingCountryCode}
                  availableDeliveryDates={deliveryDateData.calendarDates}
                  preferredLanguage={preferredLanguage}
                />
              )}
              {shouldSeeDeliveryNotes && (
                <MultiLineTextField
                  label="deliveryNotes"
                  alwaysShowLabel
                  rows={4}
                  maxLength={
                    selectedDeliveryDate.selectedDeliveryArea
                      ?.preferredCarrierService.deliveryNotesLimit ?? 30
                  }
                  optional
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={(e) => updateDeliveryDetails('deliveryNotes', e)}
                  // eslint-disable-next-line react/jsx-no-bind
                  onValidate={() =>
                    validateInputField('deliveryNotes', deliveryNotes.value)
                  }
                  errorMessageCopy={deliveryNotes.errorMessage}
                />
              )}
              <StandardInputField
                dataTestId="checkout-mobile-input"
                fieldName="mobileNumber"
                input={mobileNumber}
                // eslint-disable-next-line react/jsx-no-bind
                onBlur={() =>
                  validateInputField('mobileNumber', mobileNumber.value)
                }
                // eslint-disable-next-line react/jsx-no-bind
                onChange={(e) =>
                  updateDeliveryDetails('mobileNumber', e.currentTarget.value)
                }
                shippingCountryCode={shippingCountryCode}
              />
            </div>
            <p className="courier-info">{courierInfoCopy()}</p>
            {visible && (
              <div className="checkout__continue-button">
                <Button
                  typography={{
                    namespace,
                    text: requiresPaymentDetailsOnCheckout
                      ? 'continue_button.payment'
                      : 'continue_button.place_order'
                  }}
                  dataTestId="checkout-continue-to-payment-button"
                  disabled={!valid}
                  onClick={continueButtonClick}
                  fullWidth
                  identifier="continue_to_payment"
                  screenIdentifier="checkout_page"
                />
              </div>
            )}
          </Expand>
        </div>
      </Card>
    </section>
  )
}

export default DeliveryDateSection
