import React from 'react'

import {
  CustomerIssueReportCreate_response_inputFields_CustomerIssueManagementInputFieldMultifield_fields_CustomerIssueManagementInputFieldString as MultifieldStringField,
  CustomerIssueReportCreate_response_inputFields_CustomerIssueManagementInputFieldString as StringInputField
} from '../../../../mutations/__generated__/CustomerIssueReportCreate'
import { CustomerIssueManagementInputInput } from '@/types'

import FreeForm from '../display_types/FreeForm'

type StringProps = {
  inputField: StringInputField | MultifieldStringField
  inputValue: CustomerIssueManagementInputInput
  updateInput: (input: CustomerIssueManagementInputInput) => void
}

const String = ({
  inputField,
  inputValue,
  updateInput
}: StringProps): JSX.Element => {
  const setValue = React.useCallback(
    (stringValue: string): void => {
      const newInput = {
        fieldId: inputValue.fieldId,
        stringValue
      }
      updateInput(newInput)
    },
    [inputValue, updateInput]
  )

  switch (inputField.stringDisplayType) {
    case 'freetext':
      return (
        <FreeForm
          label={inputField.name}
          input={inputValue}
          setValue={setValue}
        />
      )
    default: {
      throw new Error('Unsupported string display type')
    }
  }
}

export default String
