// @noflow
import React from 'react'

import { countryCodeToPrivacyUrl } from '@/utils/countryCodeHelper'

import Card from '@/components/elements/atoms/Card/Card'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './MarketingConsent.module.sass'

import { CurrentUser_availableMarketingMethodPurposes as AvailableMarketingMethodPurposes } from '../queries/__generated__/CurrentUser'
import { Code as CountryCode, MarketingPreferences } from '@/types'

type Props = {
  namespace: string
  countryCode: CountryCode
  marketingPreference: MarketingPreferences
  handleMarketing: (e: React.ChangeEvent<HTMLInputElement>) => void
  availableMarketingMethodPurposes: Array<AvailableMarketingMethodPurposes>
}

const MarketingConsent = ({
  namespace,
  countryCode,
  marketingPreference,
  handleMarketing,
  availableMarketingMethodPurposes
}: Props): React.ReactElement => {
  const copyContext = 'pet_parent_step'

  const encouragedMarketingPurpose = availableMarketingMethodPurposes.find(
    ({ marketingPurpose }) => marketingPurpose.encouraged
  )

  return (
    <>
      <label htmlFor={`marketingPreferences`}>
        <div className={STYLES.checkboxWrapper} id="checkboxWrapper">
          <div className={STYLES.checkboxHover}>
            <input
              className={STYLES.checkbox}
              type="checkbox"
              name="marketingPreferences"
              id={`marketingPreferences`}
              data-testid="marketing-preferences-checkbox"
              value="marketingPreferences"
              checked={
                marketingPreference === MarketingPreferences.full_consent
              }
              onChange={handleMarketing}
            />
          </div>
          <div className={STYLES.optInWrapper}>
            <Text
              variant="textRegular16"
              colour="brandBlue500"
              namespace={namespace}
              margin={false}
              text={`${copyContext}.opt_in_text_html`}
              variables={{ privacyUrl: countryCodeToPrivacyUrl(countryCode) }}
            />
            <Text
              variant="textRegular16"
              colour="brandBlue400"
              namespace={namespace}
              text={`${copyContext}.privacy_policy`}
              variables={{ privacyUrl: countryCodeToPrivacyUrl(countryCode) }}
            />
          </div>
        </div>
      </label>
      {marketingPreference !== MarketingPreferences.full_consent && (
        <label htmlFor={`dealsOnlyPreference`}>
          <div className={STYLES.dealsOnlyContainer}>
            <Card variant="tapeblue400">
              <div className={STYLES.dealsOnlyContent}>
                <div className={STYLES.dealsOnlyCopyWrapper}>
                  <Text
                    variant="display14"
                    colour="brandWhite"
                    namespace={namespace}
                    text={`${copyContext}.deals_only_preference.title`}
                  />
                  <Text
                    variant="textRegular14"
                    colour="brandWhite"
                    namespace={namespace}
                    text={`${copyContext}.deals_only_preference.description`}
                  />
                </div>
                <div
                  className={`${STYLES.checkboxWrapper} ${STYLES.dealsOnlyWrapper}`}
                  id="checkboxWrapper"
                >
                  <div
                    className={`${STYLES.checkboxHover} ${STYLES.dealsOnlyCheckboxHover}`}
                  >
                    <input
                      className={`${STYLES.checkbox} ${STYLES.dealsOnlyCheckbox}`}
                      type="checkbox"
                      name="dealsOnlyPreference"
                      id={`dealsOnlyPreference`}
                      value="dealsOnlyPreference"
                      checked={
                        marketingPreference === MarketingPreferences.deals_only
                      }
                      onChange={handleMarketing}
                    />
                  </div>
                  <div className={STYLES.dealsOnlyOptInWrapper}>
                    <Text
                      variant="display20"
                      colour="brandWhite"
                      margin={false}
                      namespace={namespace}
                      text={
                        encouragedMarketingPurpose
                          ? encouragedMarketingPurpose.marketingPurpose.name
                          : `${copyContext}.deals_only_preference.opt_in`
                      }
                      translate={!encouragedMarketingPurpose}
                    />
                  </div>
                </div>
                <div className={STYLES.dealsOnlyCopyWrapper}>
                  <Text
                    variant="textRegular14"
                    colour="brandWhite"
                    namespace={namespace}
                    text={`${copyContext}.deals_only_preference.reassurance_message`}
                  />
                </div>
              </div>
            </Card>
          </div>
        </label>
      )}
    </>
  )
}

export default MarketingConsent
