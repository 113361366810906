import { gql } from '@apollo/client'

const BOTTOM_NAV_QUERY = gql`
  query BottomNavQuery {
    user {
      id
      affiliateType
      referralsSinceLastLoginCount
      dogs {
        id
      }
    }
  }
`

export { BOTTOM_NAV_QUERY }
