// @flow

import * as React from 'react'
import { useTranslation } from 'react-i18next'

// eslint-disable-next-line flowtype/no-flow-fix-me-comments
// $FlowFixMe
import Text from '@/components/elements/atoms/Text/Text'
// eslint-disable-next-line flowtype/no-flow-fix-me-comments
// $FlowFixMe
import { Button } from '@/components/elements/atoms/Button'
import { Analytics } from '@/components/analytics/Analytics'

type Props = {|
  ambassadorShopPath: string
|}

const ShopifyCard = ({ ambassadorShopPath }: Props): React.Element<'div'> => {
  const { t } = useTranslation('ambassadors')
  const namespace = 'dashboard.shopify'

  const linkToShopify = React.useCallback((): void => {
    // eslint-disable-next-line react/destructuring-assignment
    Analytics.track('Ambassador Dashboard Shopify Shop Card Clicked')
    window.location.href = ambassadorShopPath
  }, [ambassadorShopPath])

  return(
    <div className='shopify-card__wrapper'>
      <div className='shopify-card'>
        <div className='shopify-text__wrapper'>
          <Text
            text={t(`${namespace}.pop_to_the_shops`)}
            element='h1'
            variant='display20'
            align='left'
            translate={false}
          />
          <Text
            text={t(`${namespace}.browse_shelves`)}
            translate={false}
            align='left'
          />
          <div className='button-wrapper'>
            <Button
              typography={{
                translate: false,
                text: t(`${namespace}.visit_shop`)
              }}
              onClick={linkToShopify}
              size={'slim'}
              fullWidth
              disableAnalytics
            />
          </div>
        </div>
        <img
          alt='A dog with collection of extras'
          slug='ambassador-shop-entry-card'
          src='https://butternutbox.com/asset_management/assets/ambassador-shop-entry-card/en?&resize_to_fill=207,155'
        />
      </div>
    </div>
  )
}

export default ShopifyCard
