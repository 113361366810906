// @noflow
import type { Props as TextProps } from '@/components/elements/atoms/Text/Text'

type Props = {
  mutationError: string
  recuringType: string
  quantity: number
  objectId?: string
}

const errorCopy = ({
  mutationError,
  recuringType,
  quantity,
  objectId
}: Props): TextProps => {
  if (mutationError.includes('User_exceeded_max_quantity')) {
    window.analytics.track('Shop: Reached limit', {
      properties: {
        quantity: quantity,
        frequencyType: recuringType
      }
    })
    return {
      namespace: 'dashboard',
      text: 'extras.product_collection.errors.exceed_limit',
      variables: { context: recuringType, objectId: objectId },
      margin: false
    }
  }
  if (mutationError.includes('Product has already been taken')) {
    window.analytics.track('Shop: Attempt to add product again')
    return {
      namespace: 'dashboard',
      text: 'extras.product_collection.errors.already_taken',
      variables: { context: recuringType },
      margin: false
    }
  } else {
    return {
      namespace: 'dashboard',
      text: 'extras.product_collection.errors.generic',
      margin: false
    }
  }
}

export { errorCopy }
