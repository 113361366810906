// @noflow
import { useQuery, useReactiveVar } from '@apollo/client'
import React, { FormEvent, useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Product, captureException } from '@/utils/sentry'

import Pill from '@/components/elements/atoms/Pill/Pill'
import Text from '@/components/elements/atoms/Text/Text'
import ExpertCard from '@/components/elements/molecules/ExpertCard/ExpertCard'
import TextSeparator from '@/components/elements/molecules/TextSeparator/TextSeparator'
import StickyNavigation from '@/components/elements/organisms/StickyNavigation/StickyNavigation'
import {
  routeToNextStep,
  routeToPrevStep
} from '@/components/pages/SignupWizardPage/helpers/wizardRoutes'
import type { Route } from '@/components/pages/SignupWizardPage/types/routes'

import STYLES from './Allergies.module.sass'

import { ALLERGENS_QUERY } from '../../queries/allergiesQuery'

import type {
  Allergens_allergens as Allergen,
  Allergens as AllergensQuery
} from '../../queries/__generated__/Allergens'

import { wizardPageState } from '../../SignupWizardPage'
import useImplicitFormSubmission from '../../helpers/useImplicitFormSubmission'
import type { Dog } from '../../types/types'

type Props = {
  variant?: keyof typeof STYLES
  namespace: string
}

const copyContext = 'allergies_step'

const Speechbubble = ({ namespace }: { namespace: string }): JSX.Element => (
  <div className={STYLES.speechBubble}>
    <div className={STYLES.quote}>
      <div>
        <Text
          namespace={namespace}
          text={`${copyContext}.unknown_allergies.speech_bubble`}
          variant={'textRegular16'}
          colour={'brandBlue500'}
        />
      </div>
    </div>
    <ExpertCard
      namespace={'expert'}
      expert={{
        name: 'Sarah Barber',
        description: 'sarah_connell_sait.profession',
        imgSrc: 'Web/photos/people/sarah-connell-sait.png',
        imgAlt: 'sarah_connell_sait.image_alt'
      }}
    />
  </div>
)

const Allergies = ({ variant, namespace }: Props): JSX.Element => {
  const wizardState = useReactiveVar(wizardPageState)
  const route = useLocation().pathname as Route

  useEffect(() => {
    localStorage.setItem('wizardState', JSON.stringify(wizardState))
  }, [wizardState])
  const { dogs } = wizardState
  const { data = { allergens: [] } } = useQuery<AllergensQuery>(ALLERGENS_QUERY)

  const { allergens } = data

  const [unknownAllergies, setUnknownAllergies] = useState<Array<number>>([])
  const showMultiDogAlert =
    dogs.length > 1 &&
    dogs.filter(
      (dog: Dog) => dog.allergies && dog.allergies?.length === allergens.length
    ).length > 0

  const formIsValid = dogs.every(
    (dog: Dog) => dog.allergies && dog.allergies?.length < allergens.length
  )

  const navigate = useNavigate()

  const onEnterPress = useCallback(() => {
    navigate(routeToNextStep({ route }))
  }, [navigate, route])

  useImplicitFormSubmission({ formIsValid, onEnterPress })

  const handleAllergies = useCallback(
    (dogIndex: number, clickedElement: HTMLInputElement) => {
      if (clickedElement.checked) {
        if (!dogs[dogIndex].allergies) {
          dogs[dogIndex].allergies = []
        }
        dogs[dogIndex].allergies?.push(clickedElement.value)
      } else {
        const allergy = dogs[dogIndex].allergies?.indexOf(clickedElement.value)

        if (allergy === undefined || allergy === -1) {
          captureException(`Could not find allergy in handleAllergies array`, {
            extra: {
              dogIndex,
              dogs,
              clickedElement: clickedElement.value
            },
            tags: {
              product: Product.Wizard
            }
          })
          return
        }

        dogs[dogIndex].allergies?.splice(allergy, 1)
      }
    },
    [dogs]
  )

  const handleNoAllergies = useCallback(
    (dogIndex: number) => {
      dogs[dogIndex].allergies = []
    },
    [dogs]
  )

  const handleUnknownAllergies = useCallback(
    (dogIndex: number, clickedElement: HTMLInputElement) => {
      if (clickedElement.checked) {
        dogs[dogIndex].allergies = []
        const newArray = [...unknownAllergies]
        newArray.push(dogIndex)
        setUnknownAllergies(newArray)
      } else {
        dogs[dogIndex].allergies = null
        const newArray = [...unknownAllergies]
        newArray.splice(newArray.indexOf(dogIndex), 1)
        setUnknownAllergies(newArray)
      }
    },
    [dogs, unknownAllergies]
  )

  const handleChange = useCallback(
    (e: FormEvent<HTMLDivElement> | undefined, dogIndex: number): void => {
      if (e) {
        const clickedElement = e.target as HTMLInputElement
        const value = clickedElement.value
        if (value === 'Nothing') {
          handleNoAllergies(dogIndex)
        } else if (value === 'unknown_allergies') {
          handleUnknownAllergies(dogIndex, clickedElement)
        } else {
          handleAllergies(dogIndex, clickedElement)
        }
      }
      wizardPageState({
        ...wizardState,
        dogs
      })
    },
    [
      dogs,
      wizardState,
      handleNoAllergies,
      handleUnknownAllergies,
      handleAllergies
    ]
  )

  return (
    <>
      <div className={variant ? STYLES[variant] : ''}>
        <div className={STYLES.question} id={'anyAllergiesStep'}>
          {dogs.map((dog: Dog, index: number) => (
            <div className={STYLES.subQuestion} key={index.toString()}>
              <TextSeparator
                text={`${copyContext}.text_separator_sensitivities`}
                namespace={namespace}
                variables={{ dogName: dog.name }}
              />
              {!unknownAllergies.includes(index) && (
                <div className={STYLES.pillGroup}>
                  <Pill
                    namespace={namespace}
                    variant="checkbox"
                    id={`${dog.name}-${index.toString()}-nothing`}
                    dataTestId="no-allergies"
                    name={`${dog.name}-${index.toString()}-no-allergies`}
                    text={`${copyContext}.allergies.nothing.name`}
                    value={'Nothing'}
                    checked={
                      (dog.allergies && dog.allergies.length === 0) || false
                    }
                    // eslint-disable-next-line react/jsx-no-bind
                    onChange={(e) => handleChange(e, index)}
                  />

                  {allergens.map((allergen: Allergen, i) => (
                    <div key={i.toString()}>
                      <Pill
                        namespace={namespace}
                        variant="checkbox"
                        id={`${dog.name}-${index.toString()}-${allergen.name}`}
                        dataTestId={`allergy-${allergen.id}`}
                        name={`${dog.name}-${index.toString()}-${
                          allergen.name
                        }`}
                        text={allergen.name}
                        value={allergen.id}
                        checked={
                          (dog.allergies &&
                            dog.allergies.includes(allergen.id)) ||
                          false
                        }
                        // eslint-disable-next-line react/jsx-no-bind
                        onChange={(e) => handleChange(e, index)}
                        translate={false}
                      />
                    </div>
                  ))}
                </div>
              )}
              {dogs[index].ageStage && dogs[index].ageStage === 'puppy' && (
                <div className={STYLES.checkboxWrapper} id="checkboxWrapper">
                  <div className={STYLES.checkboxHover}>
                    <input
                      className={STYLES.checkbox}
                      type="checkbox"
                      name="unknown_allergies"
                      id={`unknown-allergies-input-${index}`}
                      value="unknown_allergies"
                      // eslint-disable-next-line react/jsx-no-bind
                      onChange={(e) => handleChange(e, index)}
                    />
                  </div>
                  <label
                    className={STYLES.checkboxLabel}
                    htmlFor={`unknown-allergies-input-${index}`}
                  >
                    <Text
                      variant={'textRegular16'}
                      colour={'brandBlue500'}
                      element="p"
                      bold={false}
                      namespace={namespace}
                      text={`${copyContext}.unknown_allergies.checkbox`}
                    />
                  </label>
                </div>
              )}
              {unknownAllergies.includes(index) && (
                <Speechbubble namespace={namespace} />
              )}
              {dogs[index].allergies &&
                dogs[index].allergies?.length === allergens.length && (
                  <div id={'alert'} className={STYLES.alert}>
                    <Text
                      variant={'textRegular16'}
                      colour={'brandBlue500'}
                      bold
                      namespace={namespace}
                      text={`${copyContext}.allergic_to_all.title`}
                    />
                    <Text
                      variant={'textRegular16'}
                      colour={'brandBlue500'}
                      element="p"
                      bold={false}
                      namespace={namespace}
                      text={`${copyContext}.allergic_to_all.text`}
                      variables={{ dogName: dog.name }}
                    />
                  </div>
                )}
            </div>
          ))}
          {showMultiDogAlert && (
            <div id={'alert'} className={STYLES.alert}>
              <Text
                variant={'textRegular16'}
                colour={'brandBlue500'}
                element="p"
                bold
                namespace={namespace}
                text={`${copyContext}.allergic_to_all.title`}
              />
              <Text
                variant={'textRegular16'}
                colour={'brandBlue500'}
                element="p"
                bold={false}
                namespace={namespace}
                text={`${copyContext}.allergic_to_all.text`}
                variables={{ count: dogs.length }}
              />
            </div>
          )}
        </div>
      </div>
      <StickyNavigation
        variant="twoButtons"
        buttonOne={{
          url: routeToPrevStep({ route }),
          text: 'wizard_flow:sticky_navigation.back',
          variant: 'secondary',
          iconColour: 'brandRed400'
        }}
        buttonTwo={{
          dataTestId: 'next-button',
          url: routeToNextStep({ route }),
          text: 'wizard_flow:sticky_navigation.continue',
          variant: 'primary',
          iconColour: 'brandWhite'
        }}
        disabled={!formIsValid}
      />
    </>
  )
}

export { Props, Allergies, ALLERGENS_QUERY }
export default Allergies
