// @noflow
import { configureStore } from '@reduxjs/toolkit'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import Paw from './components/Paw'

import type { Tab } from './models/tab'
import reducer from './reducer'

type InitialArguments = {
  csrfToken: string
  selectedTab: Tab
}

const renderPaw = (
  { csrfToken, selectedTab }: InitialArguments,
  node: HTMLElement
): void => {
  const store = configureStore({
    reducer,
    preloadedState: {
      navigation: {
        selectedTab: selectedTab,
        csrfToken,
        isOpen: false
      }
    }
  })

  ReactDOM.render(
    <Provider store={store}>
      <Paw />
    </Provider>,
    node
  )
}

const initPaw = (): void => {
  const node = document.getElementById('bb-paw') as HTMLElement | null

  if (!node) {
    throw new Error('Missing node for PAW')
  }

  const csrfToken = node.dataset.csrfToken

  if (!csrfToken) {
    throw new Error('Data for PAW is poorly formatted')
  }

  const selectedTab =
    (window.localStorage.getItem('paw-tab') as Tab) ?? 'funnel'

  renderPaw({ csrfToken, selectedTab }, node)
}

export default initPaw
