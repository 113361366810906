import classnames from 'classnames'
import React, { useEffect, useState } from 'react'

import BRAND_COLOURS, { BrandColours } from '@/constants/BrandColours'
import { SupportColours } from '@/constants/SupportColours'

import STYLES from './ProgressBar.module.sass'

type AllowedBGColors =
  | 'grey100'
  | 'grey200'
  | 'brandWhite'
  | 'brandYellow200'
  | 'brandYellow600'

type FillColour =
  | BrandColours[keyof BrandColours]
  | SupportColours[keyof SupportColours]

type Props = {
  percentFilled: number
  fillColour?: FillColour
  showOverflow?: boolean
  background?: AllowedBGColors
  slim?: boolean
}

const ProgressBar = ({
  percentFilled,
  fillColour = BRAND_COLOURS.brandYellow400,
  showOverflow = false,
  background = 'grey200',
  slim = false
}: Props): JSX.Element => {
  const [amountFilled, setAmountFilled] = useState(percentFilled ?? 5)
  const overflown = showOverflow && percentFilled > 100

  useEffect((): void => {
    if (percentFilled > 100) {
      const percentFilledWithOverflow = 200 - percentFilled
      setAmountFilled(percentFilledWithOverflow)
    } else {
      setAmountFilled(percentFilled)
    }
  }, [percentFilled])

  const className = classnames(STYLES.progressBar, {
    [STYLES.brandRed400]: overflown,
    [STYLES[background]]: !overflown,
    [STYLES.slim]: slim
  })

  const classNameInner = classnames(STYLES.progressBarInner, {
    [STYLES.slim]: slim
  })

  return (
    <div className={className}>
      <div
        className={classNameInner}
        style={{
          backgroundColor: fillColour,
          width: amountFilled >= 100 ? '100%' : `${amountFilled}%`
        }}
      />
    </div>
  )
}

export { Props }
export default ProgressBar
