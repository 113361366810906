// @noflow
import type { DogBasicInfoQuery_user_subscription } from '@/components/pages/UpdateDogBasicInfo/queries/__generated__/DogBasicInfoQuery'
import type { MedicalInfoQuery_user_subscription } from '@/components/pages/UpdateDogMedicalInfo/queries/__generated__/MedicalInfoQuery'

type Subscription =
  | DogBasicInfoQuery_user_subscription
  | MedicalInfoQuery_user_subscription

const planDiscrepancy = (subscription: Subscription): boolean => {
  const {
    idealPlan: {
      pricingCurve: { pricingCohort: pricingCohortIdeal }
    }
  } = subscription
  const {
    plan: {
      pricingCurve: { pricingCohort: pricingCohortCurrent }
    }
  } = subscription

  return pricingCohortCurrent !== pricingCohortIdeal
}

export { planDiscrepancy }
