/* eslint-disable i18next/no-literal-string */
// @noflow
import React from 'react'
import { connect } from 'react-redux'

import type { User } from '../models/user'
import type { Dispatch, State } from '../reducer'
import {
  clearLocalStorageButtonClicked,
  randomNameClicked
} from '../slices/funnelSlice'
import FunnelCTA from './FunnelCTA'
import ListItem from './ListItem'
import PresetList from './PresetList'

type PresentationalProps = {
  user: User
}

type ActionProps = {
  clearLocalStorageButtonClicked: () => void
  randomNameClicked: () => void
}

type Props = PresentationalProps & ActionProps

const mapStateToProps = (state: State): PresentationalProps => ({
  user: state.funnel.user
})

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
  clearLocalStorageButtonClicked: (): void => {
    dispatch(clearLocalStorageButtonClicked())
  },
  randomNameClicked: (): void => {
    dispatch(randomNameClicked())
  }
})

const FunnelExplanation = ({
  user,
  randomNameClicked
}: {
  user: User
  randomNameClicked: () => void
}): React.ReactElement => (
  <p>
    {`Your name is `}
    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
    <span onClick={randomNameClicked}>{user.name}</span>
    {`, and your dog is:`}
  </p>
)

const FunnelTab = ({
  user,
  clearLocalStorageButtonClicked,
  randomNameClicked
}: Props): React.ReactElement => (
  <div className="funnel-tab">
    <div className="tab-title">
      <h2>{`Funnel`}</h2>
      <ListItem type="highlighted" onClick={clearLocalStorageButtonClicked}>
        {`Clear Cookies`}
      </ListItem>
    </div>
    <div className="funnel-tab__content">
      <FunnelExplanation user={user} randomNameClicked={randomNameClicked} />
      <PresetList />
      <FunnelCTA />
    </div>
  </div>
)

export default connect(mapStateToProps, mapDispatchToProps)(FunnelTab)
