// @noflow
import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import SUPPORT_COLOURS from '@/constants/SupportColours'

import STYLES from './SkeletonTitle.module.sass'

type Props = {
  baseColor?: string
  highlightColor?: string
  width?: string | number
  height?: string | number
  align?: 'left' | 'center' | 'right'
  margin?: string | number
}

const SkeletonTitle = ({
  baseColor = SUPPORT_COLOURS.grey200,
  highlightColor = 'white',
  width = '80%',
  height = 35,
  align = 'center',
  margin = '.5rem'
}: Props): JSX.Element => {
  return (
    <div className={`${STYLES.container} ${STYLES[align]}`}>
      <SkeletonTheme baseColor={baseColor} highlightColor={highlightColor}>
        <Skeleton
          width={width}
          height={height}
          style={{
            margin,
            borderRadius: '.5rem'
          }}
        />
      </SkeletonTheme>
    </div>
  )
}

export { Props }
export default SkeletonTitle
