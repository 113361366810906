// @noflow
import { useReactiveVar } from '@apollo/client'
import React, { useCallback, useState } from 'react'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import ContactCustomerLoveButton from '../../../components/ContactCustomerLove/ContactCustomerLoveButton'
import { Button } from '@/components/elements/atoms/Button'
import Modal from '@/components/elements/atoms/Modal/Modal'
import Text from '@/components/elements/atoms/Text/Text'
import Calendar from '@/components/elements/molecules/Calendar'
import type { Address } from '@/components/elements/molecules/ChangeAddress/ChangeAddress'
import ChangeAddress from '@/components/elements/molecules/ChangeAddress/ChangeAddress'

// STYLES
import STYLES from '../ResolutionOffering.module.sass'

import {
  CustomerIssueReportEntriesSubmission_response_resolutions_details_ReplacementBoxDetails_availableDeliveryDates as CalendarDate,
  CustomerIssueReportEntriesSubmission_response_resolutions_details_ReplacementBoxDetails as ReplacementBoxDetails
} from '../../../mutations/__generated__/CustomerIssueReportEntriesSubmission'
import { CustomerIssueResolutionAcceptanceReplacementBoxSubmissionVariables } from '../../../mutations/__generated__/CustomerIssueResolutionAcceptanceReplacementBoxSubmission'

import { selfResolutionPageState } from '../../../SelfResolutionPage'
import useSelfResolutionTracking from '../../../analytics'

type Props = {
  namespace: string
  replacementBox: ReplacementBoxDetails
  orderReplacementBox: (
    variables: CustomerIssueResolutionAcceptanceReplacementBoxSubmissionVariables
  ) => void
}

const ReplacementBox = ({
  namespace,
  replacementBox,
  orderReplacementBox
}: Props): JSX.Element => {
  const selfResolutionState = useReactiveVar(selfResolutionPageState)
  const { hasReceivedReplacementBox } = selfResolutionState
  const initialDate = replacementBox.availableDeliveryDates.find(
    (d) => d.deliverable === true
  )
  const [selectedDate, setSelectedDate] = useState<Date>(
    new Date(initialDate?.date)
  )
  const [updatedDate, setUpdatedDate] = useState<CalendarDate>(
    initialDate as CalendarDate
  )
  const [address, setAddress] = useState<Address>({
    recipientName: replacementBox.defaultAddress.recipientName as string,
    address1: replacementBox.defaultAddress.address1,
    address2: replacementBox.defaultAddress.address2 as string,
    city: replacementBox.defaultAddress.city,
    postcode: replacementBox.defaultAddress.postcode,
    deliveryNotes: replacementBox.defaultAddress.deliveryNotes,
    deliveryCarrier: replacementBox.defaultAddress.deliveryCarrier as string
  })
  const [updatedAddress, setUpdatedAddress] = useState(
    replacementBox.defaultAddress
  )
  const [isChangeAddressOpen, setIsChangeAddressOpen] = useState(false)
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const selfResolutionTracking = useSelfResolutionTracking()

  React.useEffect(() => {
    const pageTitle = 'resolutionOffering.header.replacementBox'
    selfResolutionPageState({
      ...selfResolutionState,
      showBackButton: false,
      pageTitle
    })
    selfResolutionTracking.stepLoaded(
      'Discount offering',
      'Resolution offering'
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { windowWidth } = useWindowSize()

  const openChangeAddress = useCallback(() => {
    setIsChangeAddressOpen(true)
  }, [setIsChangeAddressOpen])

  const closeChangeAddress = useCallback(() => {
    setIsChangeAddressOpen(false)
  }, [setIsChangeAddressOpen])

  const niceAddress = useCallback(() => {
    return `${address.address1}, ${address.city}`
  }, [address])

  const updateAddress = useCallback(
    (newAddress: Address) => {
      selfResolutionTracking.trackEvent('change delivery address')
      setAddress({
        ...address,
        ...newAddress
      })
      setUpdatedAddress({
        ...updatedAddress,
        ...newAddress
      })
    },
    [
      address,
      updatedAddress,
      setAddress,
      setUpdatedAddress,
      selfResolutionTracking
    ]
  )

  const openCalendar = useCallback(() => {
    setIsCalendarOpen(true)
  }, [setIsCalendarOpen])

  const closeCalendar = useCallback(() => {
    setIsCalendarOpen(false)
  }, [setIsCalendarOpen])

  const changeDate = useCallback(
    (newDate: Date) => {
      selfResolutionTracking.trackEvent('change delivery date')
      setSelectedDate(newDate)
      const isoDate = newDate.toISOString().split('T')[0]
      const calendarDate = replacementBox.availableDeliveryDates.find(
        (d) => d.date === isoDate
      )
      setUpdatedDate(calendarDate as CalendarDate)
    },
    [
      setSelectedDate,
      setUpdatedDate,
      replacementBox.availableDeliveryDates,
      selfResolutionTracking
    ]
  )

  const orderReplacementBoxSubmit = useCallback(() => {
    const { postcode, recipientName, city, deliveryNotes } = updatedAddress
    selfResolutionTracking.trackEvent('Resolution accepted', {
      resolution: 'Replacement box'
    })
    orderReplacementBox({
      userId: selfResolutionState.userId,
      reportId: selfResolutionState.reportId,
      replacementBoxDetails: {
        deliveryDate: updatedDate.date,
        deliveryAddress: {
          postcode,
          recipientName: recipientName as string,
          city,
          deliveryNotes,
          addressLineOne: updatedAddress.address1,
          addressLineTwo: updatedAddress.address2,
          shippingCountryId: parseInt(updatedAddress.shippingCountry.id, 10)
        }
      }
    })
  }, [
    updatedAddress,
    updatedDate,
    selfResolutionState,
    selfResolutionTracking,
    orderReplacementBox
  ])

  return (
    <>
      <Text
        namespace={namespace}
        text={'resolutionOffering.replacementBox.text1'}
      />
      <Text
        namespace={namespace}
        text={'resolutionOffering.replacementBox.text2'}
      />
      <div className={STYLES.boxDetails}>
        <div className={STYLES.boxDetailsHeader}>
          <Text
            namespace={namespace}
            text={'resolutionOffering.replacementBox.getNewBox'}
          />
          <Button
            typography={{
              namespace,
              text: 'resolutionOffering.replacementBox.change'
            }}
            variant={'secondary'}
            onClick={openCalendar}
            disableAnalytics
          />
        </div>
        <Text
          namespace={namespace}
          text={'resolutionOffering.replacementBox.deliveryDate'}
          variables={{ date: selectedDate }}
        />
      </div>
      <div className={STYLES.boxDetails}>
        <div className={STYLES.boxDetailsHeader}>
          <Text
            namespace={namespace}
            text={'resolutionOffering.replacementBox.sendHere'}
          />
          <Button
            typography={{
              namespace,
              text: 'resolutionOffering.replacementBox.change'
            }}
            variant={'secondary'}
            onClick={openChangeAddress}
            disableAnalytics
          />
        </div>
        <Text namespace={namespace} text={niceAddress()} translate={false} />
      </div>
      <Button
        typography={{
          namespace,
          text: 'resolutionOffering.replacementBox.send'
        }}
        disableAnalytics
        disabled={hasReceivedReplacementBox}
        onClick={orderReplacementBoxSubmit}
      />
      <ContactCustomerLoveButton
        namespace={namespace}
        tracking={'Replacement Box Offering'}
      />
      <Modal
        isModalOpen={isCalendarOpen}
        setOpenModal={setIsCalendarOpen}
        width={800}
        bottomSticky={windowWidth < BREAKPOINTS.md}
      >
        <Calendar
          userId={selfResolutionState.userId}
          deliveryDate={selectedDate}
          selectedDate={selectedDate}
          shippingCountryCode={
            replacementBox.defaultAddress.shippingCountry.code
          }
          setSelectedDate={changeDate}
          handleCustomOnDateChange={closeCalendar}
          availableDeliveryDates={replacementBox.availableDeliveryDates}
        />
      </Modal>
      <Modal
        isModalOpen={isChangeAddressOpen}
        setOpenModal={setIsChangeAddressOpen}
        width={600}
      >
        <ChangeAddress
          address={address}
          onChange={updateAddress}
          toggleModal={closeChangeAddress}
          shippingCountryCode={
            replacementBox.defaultAddress.shippingCountry.code
          }
        />
      </Modal>
    </>
  )
}

export { Props }
export default ReplacementBox
