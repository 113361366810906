// Development Env Delivery Address Finder
// We don't want to call Fetchify's API in test environments, so instead we'll
// just render the below fake address dropdown with a preset list of addresses.
import random from 'lodash/random'
import React from 'react'

import { FetchifyAddress, FetchifyAddressFactory } from '@/components/factories'

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectAddress: (result: any) => void
}

const irishPostcodes = [
  'W23 N6F7',
  'X91 NX59',
  'A96 X9T2',
  'D07 HH34',
  'D02 F635',
  'A92 FN25',
  'D15 TR22'
]
const dutchPostcodes = [
  '5437 BV',
  '4941 LD',
  '6215 XZ',
  '5705 AD',
  '5502 TP',
  '1079 RB',
  '1424 CH'
]
const belgiumPostcodes = [
  '1000',
  '1080',
  '2000',
  '2020',
  '3500',
  '8000',
  '8880'
]
const polishPostcodes = ['62-262', '87-100', '40-001', '49-353']
const germanPostcodes = [
  '10245',
  '10115',
  '20095',
  '80331',
  '04109',
  '50667',
  '70173'
]

const generatePostcode = (postcodes: string[]) =>
  postcodes[random(0, postcodes.length - 1)]

const addressOptions: Array<FetchifyAddress> = [
  FetchifyAddressFactory.build(),
  FetchifyAddressFactory.build({
    result: {
      locality: 'Irish Address',
      province_name: 'Irish Address',
      postal_code: generatePostcode(irishPostcodes)
    },
    address: 'Irish Address',
    postcode: generatePostcode(irishPostcodes)
  }),
  FetchifyAddressFactory.build({
    result: {
      locality: 'Dutch Address',
      province_name: 'Dutch Address',
      postal_code: generatePostcode(dutchPostcodes)
    },
    address: 'Dutch Address',
    postcode: generatePostcode(dutchPostcodes)
  }),
  FetchifyAddressFactory.build({
    result: {
      locality: 'Belgium Address',
      province_name: 'Belgium Address',
      postal_code: generatePostcode(belgiumPostcodes)
    },
    address: 'Belgium Address',
    postcode: generatePostcode(belgiumPostcodes)
  }),
  FetchifyAddressFactory.build({
    result: {
      locality: 'Polish Address',
      province_name: 'Polish Address',
      postal_code: generatePostcode(polishPostcodes)
    },
    address: 'Polish Address',
    postcode: generatePostcode(polishPostcodes)
  }),
  FetchifyAddressFactory.build({
    result: {
      locality: 'German Address',
      province_name: 'German Address',
      postal_code: generatePostcode(germanPostcodes)
    },
    address: 'German Address',
    postcode: generatePostcode(germanPostcodes)
  })
]

const DeliveryAddressFinder = ({
  selectAddress
}: Props): React.ReactElement => (
  <div className="c2a_mode1 c2a_light c2a_accent_default c2a_dev" id="cc_c2a">
    <ul className="c2a_results">
      {addressOptions.map(({ address, result, postcode }) => (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <li
          key={address}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => {
            selectAddress(result)
          }}
          // eslint-disable-next-line react/jsx-no-bind
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              return selectAddress(result)
            }
          }}
        >
          <div className="c2a_results__text">
            <span>{postcode}</span>
            <span className="light">{address}</span>
          </div>
        </li>
      ))}
    </ul>
  </div>
)

export default DeliveryAddressFinder
