// @flow

import * as ACTIONS from '../actions'

import updateProposedPlanQuery from '../rails_queries/updateProposedPlan'

// Types
import type { Dispatch, Thunk } from 'redux'
import type { RequestResult } from '../rails_queries/updateProposedPlan'
import type { State } from '../index'

const updateProposedPlan = (): Thunk => {
  return (dispatch: Dispatch, getState: () => State): Promise<void> => {
    dispatch(ACTIONS.startUpdateProposedPlanRequest())

    const { subscriptionDetails, csrfToken, selectedUserIds } = getState()
    const { userId } = selectedUserIds
    const { changePlanDetails, plan_details } = subscriptionDetails
    const { planAttributesSelection } = changePlanDetails
    const { durationInDays, numberOfPouches, pricingCurve, deliveryFee } = planAttributesSelection
    const { pricingCurveType, pricingCohort } = pricingCurve
    const shippingCountryCode = plan_details.shipping_country_code
    return updateProposedPlanQuery({
      csrfToken,
      durationInDays,
      numberOfPouches,
      pricingCurveType,
      pricingCohort,
      deliveryFee,
      shippingCountryCode,
      userId
    }).then((requestResult: RequestResult): void => {
      switch (requestResult.type) {
        case 'ServerError': {
          dispatch(ACTIONS.completeUpdateProposedPlanRequest())
          throw new Error(`Server Error: status=${requestResult.status}, message=${requestResult.message}`)
        }
        case 'UnknownError': {
          dispatch(ACTIONS.completeUpdateProposedPlanRequest())
          throw new Error(requestResult.error)
        }
        case 'Success': {
          const { data } = requestResult

          dispatch(ACTIONS.updateChangePlanModalProposedPlan(
            data
          ))
          dispatch(ACTIONS.completeUpdateProposedPlanRequest())
          break
        }
        default: {
          (requestResult.type: empty) // eslint-disable-line no-unused-expressions
          throw new Error(`updateProposedPlan: unhandled type ${requestResult.type}`)
        }
      }
    })
  }
}

export default updateProposedPlan
