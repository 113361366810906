// @noflow
import React, { useCallback, useContext, useEffect } from 'react'

import dogsMeeting from 'assets/images/illustrations/dogs/dogs-meeting.svg'

import TextSeparator from '@/components/elements/molecules/TextSeparator/TextSeparator'
import LoadingScreen from '@/components/elements/organisms/LoadingScreen/LoadingScreen'

import STYLES from './PaymentOptions.module.sass'

import { Code } from '@/types'

import { PaymentPageContext } from '../../PayOnOwnDevicePage'
import { LoginUrlProps } from '../../screens/PaymentScreen/PaymentScreen'
import PlaceMyOrderDisclaimer from '../Disclaimer/PlaceMyOrderDisclaimer'
import Bancontact from '../PaymentComponents/Bancontact'
import Card from '../PaymentComponents/Card'
import CreditCardView from '../PaymentComponents/CreditCardView'
import Ideal from '../PaymentComponents/Ideal'
import IdealView from '../PaymentComponents/IdealView'
import PayPal from '../PaymentComponents/PayPal'
import PaymentRequestOption from '../PaymentComponents/PaymentRequestOption'
import { findPaymentMethodsAvaliable } from './paymentMethodsAvaliable'

type ActivePaymentViews =
  | 'none'
  | 'creditCard'
  | 'iDeal'
  | 'bancontact'
  | 'paypal'

type SubmissionState =
  | { type: 'not-requested' }
  | { type: 'loading' }
  | { type: 'error'; error: string }

const PaymentOptions = ({
  activePaymentView,
  setActivePaymentView,
  submissionState,
  navigateToNextStep,
  namespace
}: {
  activePaymentView: ActivePaymentViews
  setActivePaymentView: (paymentType: ActivePaymentViews) => void
  submissionState: SubmissionState
  navigateToNextStep: (input: LoginUrlProps) => void
  namespace: string
}): JSX.Element | null => {
  const copyContext = 'plan_steps.payment.payment_options'
  const { paymentPageState } = useContext(PaymentPageContext)
  const [googlePayOrApplePay, setGooglePayOrApplePay] = React.useState(false)

  const activePayment = window.localStorage.getItem('paymentMethod')

  useEffect(() => {
    if (submissionState.type === 'error') {
      window.localStorage.setItem('paymentMethod', 'none')
      setActivePaymentView('none')
    }
  }, [submissionState, setActivePaymentView])

  const handleCardButtonclick = useCallback(() => {
    setActivePaymentView('creditCard')
  }, [setActivePaymentView])

  const handleIdealButtonclick = useCallback(() => {
    setActivePaymentView('iDeal')
  }, [setActivePaymentView])

  if (!paymentPageState) {
    return null
  }

  const { data } = paymentPageState
  const { directSalesPendingSubscription } = data
  const { address } = directSalesPendingSubscription

  const avaliablePaymentMethods = findPaymentMethodsAvaliable(
    address.shippingCountry.code
  )

  if (
    avaliablePaymentMethods.includes('creditCard') &&
    activePaymentView === 'creditCard'
  ) {
    return (
      <div className={STYLES.container}>
        <div className={STYLES.contentWrapper}>
          <div className={STYLES.buttonWrapper}>
            <CreditCardView
              namespace={namespace}
              submissionState={submissionState}
            />
          </div>
          <PlaceMyOrderDisclaimer
            namespace={namespace}
            shippingCountryCode={address?.shippingCountry.code || Code.GB}
          />
        </div>
      </div>
    )
  }

  if (
    (avaliablePaymentMethods.includes('iDeal') &&
      activePaymentView === 'iDeal') ||
    activePayment === 'iDeal'
  ) {
    return (
      <IdealView
        namespace={namespace}
        paymentPageState={paymentPageState}
        submissionState={submissionState}
        setActivePaymentView={setActivePaymentView}
        navigateToNextStep={navigateToNextStep}
      />
    )
  }

  return (
    <div className={STYLES.container}>
      {submissionState.type === 'loading' && (
        <div>
          <LoadingScreen
            isOpen={submissionState.type === 'loading'}
            title={{
              namespace,
              text: 'loading_screen.text',
              variant: 'display20'
            }}
            variant={'static'}
            image={dogsMeeting}
          />
        </div>
      )}
      <div className={STYLES.contentWrapper}>
        <div className={STYLES.buttonWrapper}>
          <Card
            namespace={namespace}
            onClick={handleCardButtonclick}
            disableCreditCardButton={submissionState.type === 'loading'}
          />
        </div>
        {(avaliablePaymentMethods.length > 3 || googlePayOrApplePay) && (
          <TextSeparator namespace={namespace} text={`${copyContext}.or`} />
        )}
        <PaymentRequestOption
          namespace={namespace}
          submissionState={submissionState}
          navigateToNextStep={navigateToNextStep}
          setGooglePayOrApplePay={setGooglePayOrApplePay}
        />
        {avaliablePaymentMethods.includes('iDeal') && (
          <div className={STYLES.buttonWrapper}>
            <Ideal
              namespace={namespace}
              onClick={handleIdealButtonclick}
              disableIdealButton={
                (activePayment && !['iDeal', 'none'].includes(activePayment)) ||
                submissionState.type === 'loading'
              }
            />
          </div>
        )}
        {avaliablePaymentMethods.includes('bancontact') && (
          <div className={STYLES.buttonWrapper}>
            <Bancontact
              namespace={namespace}
              disableBancontactButton={
                (activePayment &&
                  !['bancontact', 'none'].includes(activePayment)) ||
                submissionState.type === 'loading'
              }
              paymentPageState={paymentPageState}
              setActivePaymentView={setActivePaymentView}
              navigateToNextStep={navigateToNextStep}
            />
          </div>
        )}
        {avaliablePaymentMethods.includes('payPal') && (
          <div className={STYLES.buttonWrapper}>
            <PayPal
              paymentPageState={paymentPageState}
              submissionState={submissionState}
              setActivePaymentView={setActivePaymentView}
              navigateToNextStep={navigateToNextStep}
              paypalClientId={paymentPageState.paypalClientId}
              csrfToken={paymentPageState.csrfToken}
              activePaymentView={activePaymentView}
            />
          </div>
        )}
        <PlaceMyOrderDisclaimer
          namespace={namespace}
          shippingCountryCode={address?.shippingCountry.code || Code.GB}
        />
      </div>
    </div>
  )
}

export default PaymentOptions
