// @flow

import * as React from 'react'
import { connect } from 'react-redux'

import * as ACTIONS from '../actions'

import Analytics from '../../../analytics/admin/single_customer_view/SingleCustomerViewAnalytics'

import { searchResultsIcons } from '../asset-imports/icons'

import type { State } from '../index'
import type {
  SearchResults,
  SearchResult,
  AccountStatus,
  DogSearchResults
} from '../message_types'
import type { SearchParams, SelectedUserIds } from '../actions'
import type { Dispatch } from 'redux'

type PresentationalProps = {|
  searchResults: SearchResults,
  searchParams: SearchParams,
  selectedUserIds: SelectedUserIds,
  viewingSearchResults: boolean,
  isSearching: boolean
|}

type ActionProps = {|
  setSelectedUserIds: ((SelectedUserIds, number) => void)
|}

type Props =
  & PresentationalProps
  & ActionProps

const mapStateToProps = (state: State): PresentationalProps => {
  const {
    searchResults,
    selectedUserIds,
    viewingSearchResults,
    searchParams,
    isSearching
  } = state

  return {
    searchResults,
    selectedUserIds,
    viewingSearchResults,
    searchParams,
    isSearching
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => {
  const setSelectedUserIds = (selectedUserIds: SelectedUserIds, index: number): void =>
    dispatch((_: void, getState: () => State): void => {
      const state = getState()
      const { searchParams } = state
      const {
        firstName,
        lastName,
        email,
        phone,
        postcode,
        dogsName,
        dogsBreed,
        rafCode,
        accountStatus
      } = searchParams
      const { userId } = selectedUserIds

      // Set the selected user IDs and trigger an analytics event
      dispatch(ACTIONS.updateSelectedUserIds(selectedUserIds))
      const searchDetails = {
        firstName,
        lastName,
        email,
        phone,
        postcode,
        dogsName,
        dogsBreed,
        rafCode,
        accountStatus,
        selectedIndex: index,
        selectedUserId: userId
      }

      Analytics.selectSearchResult(searchDetails)
    })

  return { setSelectedUserIds }
}

const selectUser = ({ setSelectedUserIds }: Props, { ids }: SearchResult, index: number): void => {
  const { user_id, subscription_id } = ids
  setSelectedUserIds({ userId: user_id, subscriptionId: subscription_id }, index)
  window.scrollTo(0, 0)
}

const renderAccountStatus = (accountStatus: AccountStatus): React.Node => {
  switch (accountStatus) {
    case 'active': {
      return (
        <div className='label-container'>
          <img
            className='icon'
            src={searchResultsIcons.checkmark}
            alt='A checkmark for an active subscription'
          />
          Active
        </div>
      )
    }
    case 'paused': {
      return (
        <div className='label-container'>
          <img
            className='icon'
            src={searchResultsIcons.stop}
            alt='An pair of vertical bars to indicate a paused subscription'
          />
          Paused
        </div>
      )
    }
    case 'suspended': {
      return (
        <div className='label-container'>
          <img
            className='icon'
            src={searchResultsIcons.suspended}
            alt='An stop sign to indicate a suspended subscription'
          />
          Suspended
        </div>
      )
    }
    default: {
      return (
        <div className='label-container'>
          Guest
        </div>
      )
    }
  }
}

const renderDogDetails = (name: string, breed: string): React.Node => (
  <div
    className='search-view__table__subrow--bottom__dog-details__dog'
    key={`${name}__${breed}`}
  >
    <span>
      <img
        src={searchResultsIcons.paw}
        className='icon'
        alt='Dog paw icon'
      />
      <p>
        { name }
      </p>
    </span>
    <span>
      <img
        src={searchResultsIcons.breed}
        className='icon'
        alt='Dog breed icon'
      />
      <p>
        { breed }
      </p>
    </span>
  </div>
)

const searchResultTableRow = (
  onClick: () => void,
  searchResult: SearchResult
): React.Node => (
  <div
    className='search-view__table__row'
    key={searchResult.ids.user_id}
    tabIndex='0'
    role='button'
    onClick={onClick}
    onKeyUp={(e: KeyboardEvent): void => {
      // If the key press is not `Enter` then return
      if (e.keyCode !== 13) { return }
      onClick()
    }}
  >
    <div className='search-view__table__subrow--top'>
      <p className='search-view__table__customer-name'>
        { searchResult.full_name }
      </p>
      <div className='search-view__table__status-container'>
        <div className='search-view__table__account-status'>
          { renderAccountStatus(searchResult.subscription.status) }
        </div>
      </div>
    </div>
    <div className='search-view__table__subrow--bottom'>
      <div className='search-view__table__subrow--bottom__dog-details'>
        { searchResult.dogs.map((dog: DogSearchResults): React.Node => renderDogDetails(dog.name, dog.breed)) }
      </div>
      <div className='search-view__table__subrow--bottom__user-details'>
        <span>
          <img
            src={searchResultsIcons.email}
            className='icon'
            alt='Email icon'
          />
          <p>
            { searchResult.email }
          </p>
        </span>
        { searchResultsIcons.address &&
        <span>
          <img
            src={searchResultsIcons.address}
            className='icon'
            alt='Address icon'
          />
          <p>
            { searchResult.address.postcode }
          </p>
        </span> }
        <span>
          <img
            src={searchResultsIcons.box}
            className='icon'
            alt='Deliveries Received icon'
          />
          { /* We check whether deliveries_received is present as otherwise we would get an error  */ }
          <p>
            { `${searchResult.subscription.deliveries_received || '0'} ${searchResult.subscription.deliveries_received === 1 ? 'box' : 'boxes'} delivered` }
          </p>
        </span>
      </div>
    </div>
  </div>
)

const renderNoResults = (): React.Node => (
  <div className="search-view__no-results">
    <img
      src={searchResultsIcons.noResultsEmail}
      alt="No results dog"
    />
    <p>
      { `Oops, it looks like we don't have anyone that matches your search criteria` }
    </p>
  </div>
)

const SearchResultsTable = (props: Props): React.Node => {
  const {
    viewingSearchResults,
    selectedUserIds,
    isSearching,
    searchResults
  } = props
  const { userId } = selectedUserIds

  if (!viewingSearchResults && userId < 1 && !isSearching) {
    return (
      <div className="search-view__no-results">
        <img
          src={searchResultsIcons.startSearch}
          alt="No search dog"
        />
      </div>
    )
  } else if (viewingSearchResults && searchResults.length < 1) {
    return renderNoResults()
  } else if (userId > 0) {
    return null
  }
  return (
    <div className='search-view__table'>
      <div>
        {
          searchResults.map((searchResult: SearchResult, i: number): React.Node =>
            searchResultTableRow(
              (): void => selectUser(props, searchResult, i),
              searchResult
            ))
        }
      </div>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchResultsTable)
