// @noflow
import React from 'react'

import {
  ICON_COLOURS,
  IconColours
} from '@/components/elements/atoms/Icon/Icon'

type Props = {
  size: number
  width?: number | string
  accentColour: IconColours
  backgroundColour?: IconColours
}

const Alert = ({
  size,
  width,
  accentColour,
  backgroundColour = 'brandWhite'
}: Props): JSX.Element => {
  return (
    <svg
      fill="none"
      height={size}
      viewBox="0 0 22 22"
      width={width || '100%'}
      style={{
        transform: accentColour === 'warningOrange300' ? 'rotate(180deg)' : ''
      }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.044 11c0 5.547-4.497 10.044-10.044 10.044C5.453 21.044.957 16.547.957 11 .957 5.453 5.453.957 11 .957 16.547.957 21.044 5.453 21.044 11Z"
        fill={ICON_COLOURS[backgroundColour]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 1.626a9.374 9.374 0 1 0 0 18.748 9.374 9.374 0 0 0 0-18.748ZM.287 11C.287 5.083 5.083.287 11 .287 16.917.287 21.713 5.083 21.713 11c0 5.917-4.796 10.713-10.713 10.713C5.083 21.713.287 16.917.287 11Z"
        fill={ICON_COLOURS[accentColour]}
      />
      <path
        d="M9.89 8.322h1.55c.13 0 .23-.1.23-.23v-1.55c0-.114-.1-.229-.23-.229H9.89c-.129 0-.23.115-.23.23v1.55c0 .128.101.229.23.229Zm1.708 7.963V9.57c0-.115-.1-.23-.23-.23H9.962c-.129 0-.23.115-.23.23v6.715c0 .115.101.23.23.23h1.406c.13 0 .23-.115.23-.23Z"
        fill={ICON_COLOURS[accentColour]}
      />
    </svg>
  )
}

export default Alert
