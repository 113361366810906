// @flow

import * as React from 'react'
import i18next from 'i18next'

import DogOpeningBox from '../../../../../assets/images/illustrations/dogs/schnauzer-opening-box.svg'

type Props = {||}

class NoSubscriptionAccount extends React.Component<Props> {
  namespace = 'ambassadors:dashboard.balance_tab'

  render (): React.Node {
    return (
      <div className='ambassador-balance__card'>
        <div className='ambassador-balance__card__header'>
          <h2>{ i18next.t(`${this.namespace}.make_tail_wag`) }</h2>
          <img
            alt=''
            src={DogOpeningBox}
          />
          <p>{ i18next.t(`${this.namespace}.become_butternutter`) }</p>
          <a
            className='btn btn-updated--blue'
            href={'/ambassadors/sign_in_as_guest'}
          >
            { i18next.t(`${this.namespace}.sign_me_up`) }
          </a>
        </div>
      </div>
    )
  }
}

export default NoSubscriptionAccount
