// @flow

import * as ACTIONS from '../actions'

import type { Date, SubscriptionDetails, DeliveryDetails } from '../message_types'
import type { Dispatch, Thunk } from 'redux'
import type { State } from '../index'

const { fetch } = global

const restartSubscriptionWithDate = (newDate: Date, subscriptionId: number): Thunk => {
  return function (dispatch: Dispatch, getState: () => State): void {
    dispatch(ACTIONS.signalRestartSubscription(subscriptionId, newDate))

    const { csrfToken } = getState()

    const endpoint = '/admin/single_customer_view/restart_subscription_with_target_next_box_date'
    const method = 'POST'
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-CSRF-Token': csrfToken
    }
    const body = JSON.stringify({
      new_date: newDate,
      subscription_id: subscriptionId,
    })

    fetch(endpoint, { method, headers, body })
      .then((res: Response): void => {
        if (!res.ok) {
          res.json().then((res: $ReadOnly<{| error: string |}>): void => {
            switch (res.error) {
              case 'Invalid date selected': {
                dispatch(ACTIONS.displayInvalidRestartDateMessage())
                break
              }
              default: {
                throw new Error(`Error restarting subscription: ${subscriptionId}`)
              }
            }
          })
        } else {
          res.json()
            .then((res: {| subscription_details: SubscriptionDetails, deliveries_details: Array<DeliveryDetails>, number_of_box_issues: number, number_of_boxes_delivered: number |}): void => {
              dispatch(ACTIONS.toggleRestartModal())
              dispatch(ACTIONS.receiveDeliveriesDetailsResponse(
                res.deliveries_details,
                res.number_of_box_issues,
                res.number_of_boxes_delivered
              ))
              dispatch(ACTIONS.receiveSubscriptionDetailsResponse(res.subscription_details))
              dispatch(ACTIONS.displayBannerMessage('Hooray! Subscription has been restarted.'))
              setTimeout((): void => dispatch(ACTIONS.hideBannerMessage()), 3000)
            })
        }
      })
  }
}

export { restartSubscriptionWithDate }
