// @flow

import { connect } from 'react-redux'

import UserDetails from '../components/UserDetails'

import type { State } from '../index'

type PresentationalProps = $ReadOnly<{|
  showUserContainers: boolean,
  fullName: string
|}>

export type ComponentProps =
  & PresentationalProps

function mapStateToProps (state: State): PresentationalProps {
  const { selectedUserIds, accountDetails } = state
  const showUserContainers = selectedUserIds.userId > 0
  const fullName = accountDetails.full_name
  return { showUserContainers, fullName }
}

export default connect(
  mapStateToProps
)(UserDetails)
