// @noflow

/* eslint-disable i18next/no-literal-string */
import { useNotifications } from '@/context/notifications/notifications'
import { ACCOUNT_ROUTES } from '@/routes'
import { useMutation } from '@apollo/client'
import i18next from 'i18next'
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { possessive } from '@/utils/StringHelper'
import { planDiscrepancy } from '@/utils/planDiscrepancy'

import BREAKPOINTS from '@/constants/Breakpoints'

import useBoolean from '@/hooks/useBoolean'
import useWindowSize from '@/hooks/useWindowSize'

import CheckmarkArea from 'assets/images/icons/checkmarks/checkmark--green-area.svg'
import CrossArea from 'assets/images/icons/crosses/cross--red-area.svg'
import Active from 'assets/images/illustrations/dogs/activity-level/active.svg'
import Hyper from 'assets/images/illustrations/dogs/activity-level/hyper.svg'
import Low from 'assets/images/illustrations/dogs/activity-level/low.svg'
import Chubby from 'assets/images/illustrations/dogs/body-condition/chubby.svg'
import Average from 'assets/images/illustrations/dogs/body-condition/regular.svg'
import Skinny from 'assets/images/illustrations/dogs/body-condition/skinny.svg'
import DogWeightConfirm from 'assets/images/illustrations/dogs/dog-weight--green.svg'
import DogWeight from 'assets/images/illustrations/dogs/dog-weight.svg'
import Plant from 'assets/images/illustrations/plant.svg'

import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'
import { FixedBase } from '@/components/elements/atoms/FixedBase'
import Modal from '@/components/elements/atoms/Modal/Modal'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import Text from '@/components/elements/atoms/Text/Text'
import { SelectableCardGroup } from '@/components/elements/molecules/SelectableCardGroup'
import { Value } from '@/components/elements/molecules/SelectableCardGroup/SelectableCardGroup'

import STYLES from './UpdateDogBasicInfo.module.sass'

import { UPDATE_DOG_BASIC_INFO_MUTATION } from './mutations/updateDogBasicInfoMutation'

import type {
  UpdateDogBasicInfoMutation,
  UpdateDogBasicInfoMutationVariables
} from './mutations/__generated__/UpdateDogBasicInfoMutation'
import type {
  DogBasicInfoQuery_user_dogs as Dog,
  DogBasicInfoQuery
} from './queries/__generated__/DogBasicInfoQuery'
import type { BodyShape, Exercise } from '@/types'
import { Gender } from '@/types'

type Props = Partial<DogBasicInfoQuery> & {
  selectedDog?: Dog
  loading: boolean
}

const commonIconStyles = {
  width: '100%',
  height: 100
}

const BODY_SHAPE = [
  {
    value: 'skinny',
    icon: {
      src: Skinny,
      alt: 'update_dog_profile_basic_info.body_shape.skinny.heading',
      ...commonIconStyles
    },
    identifier: 'update_dog_profile_basic_info.body_shape.skinny'
  },
  {
    value: 'just_right',
    icon: {
      src: Average,
      alt: 'update_dog_profile_basic_info.body_shape.just_right.heading',
      ...commonIconStyles
    },
    identifier: 'update_dog_profile_basic_info.body_shape.just_right'
  },
  {
    value: 'chubby',
    icon: {
      src: Chubby,
      alt: 'update_dog_profile_basic_info.body_shape.chubby.heading',
      ...commonIconStyles
    },
    identifier: 'update_dog_profile_basic_info.body_shape.chubby'
  }
]

const ACTIVITY_LEVELS = [
  {
    value: 'low',
    icon: {
      src: Low,
      alt: 'update_dog_profile_basic_info.activity.low.heading',
      ...commonIconStyles
    },
    identifier: 'update_dog_profile_basic_info.activity.low'
  },
  {
    value: 'normal',
    icon: {
      src: Active,
      alt: 'update_dog_profile_basic_info.body_shape.normal.heading',
      ...commonIconStyles
    },
    identifier: 'update_dog_profile_basic_info.activity.normal'
  },
  {
    value: 'hyper',
    icon: {
      src: Hyper,
      alt: 'update_dog_profile_basic_info.body_shape.hyper.heading',
      ...commonIconStyles
    },
    identifier: 'update_dog_profile_basic_info.activity.hyper'
  }
]

type DogWeightInputProps = {
  weightInKg: number
  onWeightChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const DogWeightInput = ({
  weightInKg,
  onWeightChange
}: DogWeightInputProps): JSX.Element => {
  const { t } = useTranslation('account')
  return (
    <div className={STYLES.weightContent}>
      <div className={STYLES.inputWrapper}>
        <input
          className={STYLES.input}
          value={weightInKg < 1 ? '' : weightInKg}
          type="number"
          placeholder="0.0"
          onChange={onWeightChange}
        />
        <div className={STYLES.unit}>
          <Text
            namespace="account"
            text="update_dog_profile_basic_info.weight.kg"
            margin={false}
            colour="brandBlue400"
          />
        </div>
      </div>
      <img
        src={DogWeight}
        alt={t('update_dog_profile_basic_info.weight.image_alt')}
        className={STYLES.weightImage}
      />
    </div>
  )
}

type SharedInputProps = {
  onSelect: (value: Value) => void
  loading: boolean
  isMobile: boolean
}

type BodyShapeInputProps = SharedInputProps & {
  bodyShape: string
}

const BodyShapeInput = ({
  bodyShape,
  onSelect,
  loading,
  isMobile
}: BodyShapeInputProps): JSX.Element => (
  <>
    <SelectableCardGroup
      options={BODY_SHAPE}
      onSelect={onSelect}
      currentValue={bodyShape}
      cardProps={{
        skeleton: {
          isLoading: loading,
          height: isMobile ? '8rem' : '15rem'
        }
      }}
    />
    {bodyShape && (
      <Card
        variant="brandYellow200"
        skeleton={{
          isLoading: loading,
          height: '9rem'
        }}
      >
        <Text
          namespace="account"
          text={`update_dog_profile_basic_info.body_shape.${bodyShape}.heading`}
          margin={false}
          variant="display16"
          align="center"
        />
        <Text
          namespace="account"
          text={`update_dog_profile_basic_info.body_shape.${bodyShape}.text`}
          margin={false}
          align="center"
        />
      </Card>
    )}
  </>
)

type DogActivityInputProps = SharedInputProps & {
  exercise: string
}

const DogActivityInput = ({
  exercise,
  onSelect,
  loading,
  isMobile
}: DogActivityInputProps): JSX.Element => (
  <>
    <SelectableCardGroup
      options={ACTIVITY_LEVELS}
      onSelect={onSelect}
      currentValue={exercise}
      cardProps={{
        skeleton: {
          isLoading: loading,
          height: isMobile ? '8rem' : '15rem'
        }
      }}
    />
    {exercise && (
      <Card
        variant="brandYellow200"
        skeleton={{
          isLoading: loading,
          height: '9rem'
        }}
      >
        <Text
          namespace="account"
          text={`update_dog_profile_basic_info.activity_level.${exercise}.heading`}
          margin={false}
          variant="display16"
          align="center"
        />
        <Text
          namespace="account"
          text={`update_dog_profile_basic_info.activity_level.${exercise}.text`}
          margin={false}
          align="center"
        />
      </Card>
    )}
  </>
)

type NeuteringStatusInputProps = SharedInputProps & {
  neutered: boolean
  genderContext: '' | Gender
}

const NeuteringStatusInput = ({
  onSelect,
  neutered,
  genderContext,
  loading,
  isMobile
}: NeuteringStatusInputProps): JSX.Element => {
  const NEUTERING_STATUSES = [
    {
      value: true,
      icon: {
        src: CheckmarkArea,
        alt: 'update_dog_profile_basic_info.neutering_status.options.true',
        ...commonIconStyles
      },
      typography: {
        text: 'update_dog_profile_basic_info.neutering_status.options.true',
        variables: {
          context: genderContext
        }
      },
      identifier: 'update_dog_profile_basic_info.neutering_status.true'
    },
    {
      value: false,
      icon: {
        src: CrossArea,
        alt: 'update_dog_profile_basic_info.neutering_status.options.false',
        ...commonIconStyles
      },
      typography: {
        text: 'update_dog_profile_basic_info.neutering_status.options.false',
        variables: {
          context: genderContext
        }
      },
      identifier: 'update_dog_profile_basic_info.neutering_status.false'
    }
  ]

  return (
    <SelectableCardGroup
      options={NEUTERING_STATUSES}
      onSelect={onSelect}
      currentValue={neutered}
      cardProps={{
        skeleton: {
          isLoading: loading,
          height: isMobile ? '8rem' : '15rem'
        }
      }}
    />
  )
}

const UpdateDogBasicInfoContent = ({
  selectedDog,
  loading,
  ...restProps
}: Props): JSX.Element => {
  const container = useRef<HTMLDivElement>(null)
  const { t } = useTranslation('account')
  const { setErrorNotification } = useNotifications()
  const navigate = useNavigate()
  const { windowWidth } = useWindowSize()

  const isMobile = windowWidth < BREAKPOINTS.md

  const loadedData = (() => {
    if (loading) {
      return null
    }

    const { user } = restProps
    const { id: userId, dogs } = user ?? {}

    return {
      userId,
      dogs
    }
  })()

  const [shouldSetInitialData, setShouldSetInitialData] = useState(true)
  const [basicInfo, setBasicInfo] = useState({
    weightInKg: 0,
    bodyShape: '',
    exercise: '',
    neutered: false
  })

  const {
    value: isConfirmationModalOpen,
    setTrue: openConfirmationModal,
    setFalse: closeConfirmationModal,
    toggle: toggleConfirmationModal
  } = useBoolean(false)

  const genderContext = selectedDog?.gender ?? ''
  const possessiveDogName =
    selectedDog && possessive(selectedDog.name, i18next.language)

  const noChangeToBasicInfo = (() => {
    if (selectedDog) {
      const { weightInGrams, bodyShape, exercise, neutered } = selectedDog

      return (
        weightInGrams === basicInfo.weightInKg * 1000 &&
        bodyShape === basicInfo.bodyShape &&
        exercise === basicInfo.exercise &&
        neutered === basicInfo.neutered
      )
    }
  })()

  const handleOnSelect = useCallback(
    (value, type) => {
      setBasicInfo({
        ...basicInfo,
        [type]: value
      })
    },
    [basicInfo]
  )

  const handleOnBodyShapeSelect = useCallback(
    (value) => handleOnSelect(value, 'bodyShape'),
    [handleOnSelect]
  )

  const handleActivityLevelSelect = useCallback(
    (value) => handleOnSelect(value, 'exercise'),
    [handleOnSelect]
  )

  const handleNeuteringStatusSelect = useCallback(
    (value) => handleOnSelect(value, 'neutered'),
    [handleOnSelect]
  )

  const onWeightChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target

      if (isNaN(parseFloat(value))) {
        setBasicInfo({
          ...basicInfo,
          weightInKg: 0
        })
      }

      setBasicInfo({
        ...basicInfo,
        weightInKg: parseFloat(value)
      })
    },
    [basicInfo]
  )

  /**
   * Update dog basic info mutation
   *
   * On error, show error message and log error to Sentry.
   * On success, show success message, refetch user data and navigate
   * back to the previous screen.
   */
  const [updateDogBasicInfoMutation, { loading: updateDogBasicInfoLoading }] =
    useMutation<
      UpdateDogBasicInfoMutation,
      UpdateDogBasicInfoMutationVariables
    >(UPDATE_DOG_BASIC_INFO_MUTATION, {
      onError: () => {
        setErrorNotification({
          text: 'update_dog_profile_basic_info.notifications.error',
          namespace: 'account'
        })
      },
      onCompleted: (data) => {
        closeConfirmationModal()

        if (data?.dogsUpdate && planDiscrepancy(data.dogsUpdate.subscription)) {
          // TODO: Navigate to the recommended plan page
          navigate(`${ACCOUNT_ROUTES.profile}/${selectedDog?.id}`)
        } else {
          navigate(`${ACCOUNT_ROUTES.profile}/${selectedDog?.id}`)
        }
      }
    })

  /**
   * Hander to update dog basic info
   */
  const updateDogBasicInfoDetails = useCallback(async () => {
    try {
      const { weightInKg, exercise, bodyShape, neutered } = basicInfo
      const weightInGrams = weightInKg * 1000

      await updateDogBasicInfoMutation({
        variables: {
          userId: loadedData?.userId ?? '',
          attributes: [
            {
              id: selectedDog?.id ?? '',
              weightInGrams,
              exercise: exercise as Exercise,
              bodyShape: bodyShape as BodyShape,
              neutered
            }
          ],
          caloriesMultipleOf: 10,
          percentageMultipleOf: 5,
          lowerPercentage: 90,
          upperPercentage: 110
        }
      })
    } catch (e) {
      setErrorNotification({
        text: 'update_dog_profile_basic_info.notifications.error',
        namespace: 'account'
      })
    }
  }, [
    selectedDog?.id,
    loadedData?.userId,
    updateDogBasicInfoMutation,
    basicInfo,
    setErrorNotification
  ])

  useEffect(() => {
    if (shouldSetInitialData && loadedData && selectedDog) {
      const { weightInGrams } = selectedDog

      setBasicInfo({
        ...basicInfo,
        weightInKg: weightInGrams / 1000,
        bodyShape: selectedDog.bodyShape,
        exercise: selectedDog.exercise,
        neutered: selectedDog.neutered
      })

      // Prevent the effect from running again
      setShouldSetInitialData(false)
    }
  }, [shouldSetInitialData, loadedData, basicInfo, selectedDog])

  useEffect(() => {
    if (updateDogBasicInfoLoading) {
      openConfirmationModal()
    }
  }, [updateDogBasicInfoLoading, openConfirmationModal])

  const getSectionWrapperProps = (type: string) =>
    ({
      headerTypography: {
        text: `update_dog_profile_basic_info.${type}.title`,
        namespace: 'account',
        align: 'center',
        variables: {
          context: genderContext
        }
      },
      bgColour: 'brandWhite90',
      borderRadius: 16,
      padding: { top: 16, right: 16, bottom: 16, left: 16 }
    } as const)

  return (
    <Fragment>
      <div className={STYLES.wrapper} ref={container}>
        <SectionWrapper
          {...getSectionWrapperProps('weight')}
          margin={{ top: 0 }}
        >
          <DogWeightInput
            weightInKg={basicInfo.weightInKg}
            onWeightChange={onWeightChange}
          />
        </SectionWrapper>
        <SectionWrapper {...getSectionWrapperProps('body_shape')}>
          <div className={STYLES.sectionContent}>
            <BodyShapeInput
              loading={loading}
              isMobile={isMobile}
              bodyShape={basicInfo.bodyShape}
              onSelect={handleOnBodyShapeSelect}
            />
          </div>
        </SectionWrapper>
        <SectionWrapper {...getSectionWrapperProps('activity_level')}>
          <div className={STYLES.sectionContent}>
            <DogActivityInput
              loading={loading}
              isMobile={isMobile}
              onSelect={handleActivityLevelSelect}
              exercise={basicInfo.exercise}
            />
          </div>
        </SectionWrapper>
        <SectionWrapper {...getSectionWrapperProps('neutering_status')}>
          <div className={STYLES.sectionContent}>
            <NeuteringStatusInput
              loading={loading}
              isMobile={isMobile}
              genderContext={genderContext}
              onSelect={handleNeuteringStatusSelect}
              neutered={basicInfo.neutered}
            />
          </div>
        </SectionWrapper>
      </div>
      <FixedBase backgroundColor="brandWhite90" container={container}>
        <div className={STYLES.fixedBaseContent}>
          <Button
            identifier="dog_basic_info.update"
            onClick={updateDogBasicInfoDetails}
            typography={{
              text: 'update_dog_profile_basic_info.button',
              namespace: 'account'
            }}
            skeleton={{
              isLoading: loading
            }}
            disabled={
              loading || updateDogBasicInfoLoading || noChangeToBasicInfo
            }
          />
        </div>
      </FixedBase>
      <Modal
        isModalOpen={isConfirmationModalOpen}
        setOpenModal={toggleConfirmationModal}
        width={600}
        textAlign="center"
        fullHeight={isMobile}
        showCloseButton={false}
        backgroundColour="brandYellow100"
      >
        <div className={STYLES.confirmationModalContent}>
          <img
            src={CheckmarkArea}
            alt={t('update_dog_profile_basic_info.notifications.confirm.alt')}
          />
          <div>
            <img
              src={Plant}
              alt={t(
                'update_dog_profile_basic_info.notifications.confirm.plant_alt'
              )}
            />
            <img
              src={DogWeightConfirm}
              alt={t(
                'update_dog_profile_basic_info.notifications.confirm.dog_info_alt'
              )}
            />
          </div>
          <Text
            namespace="account"
            text="update_dog_profile_basic_info.notifications.confirm.text"
            variables={{
              possessiveDogName
            }}
            align="center"
            variant="display28"
          />
        </div>
      </Modal>
    </Fragment>
  )
}

export {
  UpdateDogBasicInfoContent,
  DogWeightInput,
  BodyShapeInput,
  DogActivityInput,
  NeuteringStatusInput,
  BODY_SHAPE
}
