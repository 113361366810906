// @noflow
import Cookies from 'js-cookie'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import PinkLetter from 'assets/images/dashboard/my-account/letter--pink.svg'
import PinkPhone from 'assets/images/dashboard/my-account/phone--pink.svg'
import YellowPaw from 'assets/images/icons/paws/paw-yellow.svg'

import type { State } from '../../reducers'
import { State as FormState, FormType } from '../../reducers/formDataReducer'
import type { State as RequestsState } from '../../reducers/requestsReducer'
import Form from './Form'
import FormRequestMessageScrollable from './FormRequestMessageScrollable'
import QueryType from './QueryType'

type PresentationalProps = {
  submitContactForm: RequestsState['submitContactForm']
  viewingStep: FormState['viewingStep']
  formType: FormType
  preferredLanguage: FormState['form']['preferredLanguage']
  queryType: FormState['queryType']
  contactInformation: FormState['form']['contactInformation']
}

type Props = PresentationalProps

const mapStateToProps = ({
  requests,
  formData
}: State): PresentationalProps => {
  const { viewingStep, queryType, form } = formData
  const { submitContactForm } = requests
  const { type: formType, preferredLanguage, contactInformation } = form

  return {
    submitContactForm,
    viewingStep,
    formType,
    preferredLanguage,
    queryType,
    contactInformation
  }
}

const PawElements = (): Array<React.ReactElement> => {
  return [YellowPaw, YellowPaw, YellowPaw, YellowPaw].map(
    (assetPath: string, index: number): React.ReactElement => (
      <img
        alt=""
        className="tablet-and-desktop-view"
        key={index} // eslint-disable-line react/no-array-index-key
        src={assetPath}
      />
    )
  )
}

const ContactForm = ({
  submitContactForm,
  viewingStep,
  formType,
  preferredLanguage,
  queryType,
  contactInformation
}: Props): React.ReactElement => {
  const copyContext = 'fill_out_form'
  const { t } = useTranslation('contacts')
  let hostDefaults
  if (location.hostname === 'psibufet.pl')
    hostDefaults = {
      preferredLanguage: 'pl_PL',
      shippingCountryCode: 'PL'
    }
  else {
    hostDefaults = {
      preferredLanguage: 'en',
      shippingCountryCode: 'GB'
    }
  }
  const userLanguage =
    Cookies.get('user_language') ||
    preferredLanguage ||
    hostDefaults.preferredLanguage

  return (
    <div className="contact-us-further-information-card" id="contact-us-form">
      <div className="contact-us-further-information-card__inner-card">
        {userLanguage !== 'fr' && (
          <div className="contact-us-further-information-card__contact-method">
            {contactInformation.displayContactNumber &&
              contactInformation.contactNumber && (
                <>
                  <h4>
                    <img
                      src={PinkPhone}
                      alt={t(`${copyContext}.phone_img_alt`)}
                    />
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: t(`${copyContext}.call_text`, {
                          number: contactInformation.contactNumber,
                          formattedNumber:
                            contactInformation.displayContactNumber
                        })
                      }}
                    />
                  </h4>
                  {contactInformation.contactHours && (
                    <p
                      className="contact-us-further-information-card__call-opening-times"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: contactInformation.contactHours
                      }}
                    />
                  )}
                </>
              )}
            {contactInformation.whatsappNumber &&
              contactInformation.displayWhatsappNumber && (
                <>
                  <h4>
                    <div
                      className="contact-us-further-information-card__call-contact-number"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: t(`${copyContext}.whatsapp_contact_number`, {
                          number: contactInformation.whatsappNumber,
                          formattedNumber:
                            contactInformation.displayWhatsappNumber
                        })
                      }}
                    />
                  </h4>
                  {contactInformation.whatsappContactHours && (
                    <p
                      className="contact-us-further-information-card__call-opening-times"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: contactInformation.whatsappContactHours
                      }}
                    />
                  )}
                </>
              )}
          </div>
        )}
        <div className="contact-us-further-information-card__contact-method">
          <h4>
            <img src={PinkLetter} alt="" />
            {t(`${copyContext}.send_message_text`)}
          </h4>
          <form
            className={`contact-us__form contact-us__form__${formType}`}
            autoComplete="on"
          >
            {(submitContactForm.status === 'Complete With Success' ||
              submitContactForm.status === 'Complete With Error') && (
              <FormRequestMessageScrollable />
            )}
            <QueryType />
            {viewingStep === 'Fill Out Form' &&
              !['stop_subscription', 'pets_at_home'].includes(
                queryType.value
              ) && <Form />}
          </form>
        </div>
      </div>
      <div className="contact-us-further-information-card__paws">
        {PawElements()}
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(ContactForm)
