// @noflow
import { useReactiveVar } from '@apollo/client'
import React from 'react'

import { Button } from '@/components/elements/atoms/Button'
import Icon from '@/components/elements/atoms/Icon/Icon'

import STYLES from './SelfResolutionHeader.module.sass'

import { selfResolutionPageState } from './../../SelfResolutionPage'

type Props = {
  namespace: string
  goBack: () => void
  close: () => void
}

const SelfResolutionHeader = ({
  namespace,
  goBack,
  close
}: Props): JSX.Element => {
  const selfResolutionState = useReactiveVar(selfResolutionPageState)

  return (
    <div className={STYLES.heading}>
      <div className={STYLES.headingButtons}>
        <div className={STYLES.backButton}>
          {selfResolutionState.showBackButton && (
            <Button
              typography={{
                namespace,
                text: 'header.back'
              }}
              variant={'ghost'}
              disabled={false}
              onClick={goBack}
              icon={{
                position: 'left',
                component: <Icon asset="chevron" size={18} />
              }}
              displayText={'desktopOnly'}
              disableAnalytics
            />
          )}
        </div>
        <div className={STYLES.closeButton}>
          <Button
            typography={{
              namespace,
              text: 'header.close'
            }}
            variant={'ghost'}
            disabled={false}
            onClick={close}
            icon={{
              position: 'left',
              component: <Icon asset="close" size={18} />
            }}
            displayText={'never'}
            disableAnalytics
          />
        </div>
      </div>
    </div>
  )
}

export default SelfResolutionHeader
