// @noflow
import type { TOptions } from 'i18next'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import Text, { AllowedColours } from '@/components/elements/atoms/Text/Text'

import STYLES from './ExpandableIconButton.module.sass'

type Props = {
  namespace?: string
  text: string | Array<string>
  variables?: TOptions
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  disabledOnClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  variant?: keyof typeof STYLES
  icon: JSX.Element
  translate?: boolean
  toggleIconButton: boolean
  onFocus?: () => void
}

const ExpandableIconButton = ({
  namespace,
  text,
  variables,
  disabled,
  onClick,
  disabledOnClick,
  variant = 'default',
  icon,
  translate = true,
  toggleIconButton = false,
  onFocus
}: Props): JSX.Element => {
  const { t } = useTranslation(namespace)

  const handleOnClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>): void => {
      if (disabled) {
        if (disabledOnClick) disabledOnClick(e)
      } else {
        onClick && onClick(e)
      }
    },
    [disabled, disabledOnClick, onClick]
  )

  const generateTextColour = (): AllowedColours => {
    switch (variant) {
      case 'default':
      case 'primary': {
        return 'brandWhite'
      }
      case 'linkWhiteBg':
      case 'link': {
        return disabled ? 'brandBlue400' : 'brandBlue500'
      }
      case 'secondary':
      case 'ghost':
      case 'ghostWhite': {
        return disabled ? 'brandRed300' : 'brandRed400'
      }
      default: {
        return 'brandWhite'
      }
    }
  }
  const textColour = generateTextColour()

  return (
    <button
      className={`${STYLES.button} ${variant && STYLES[variant]} ${
        toggleIconButton ? STYLES.short : STYLES.long
      }`}
      disabled={disabled}
      onClick={handleOnClick}
      type="button"
      aria-label={translate ? t(text) : (text as string)}
      onFocus={onFocus}
    >
      <div className={STYLES.buttonIcon}>{icon}</div>
      <div className={`${STYLES.buttonText}`}>
        <Text
          namespace={namespace}
          text={text}
          margin={false}
          variant="textMono18"
          shouldScale={false}
          element="span"
          variables={variables}
          translate={translate}
          colour={textColour}
        />
      </div>
    </button>
  )
}

export { Props }
export default ExpandableIconButton
