// @flow

type Trait =
  | 'goofball'
  | 'shy'
  | 'clever_clogs'
  | 'sweet'

type TraitLabel =
  | 'A bit of a goofball'
  | 'A little bit shy'
  | 'A real clever clogs'
  | 'A big softy'

const traits: Array<Trait> = [
  'goofball',
  'shy',
  'clever_clogs',
  'sweet'
]

export type { Trait, TraitLabel }
export { traits }
