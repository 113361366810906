// @noflow
import React from 'react'

import STYLES from './SubHeader.module.sass'

type Props = {
  content: JSX.Element | React.ReactNode
  showSubHeader?: boolean
}

const SubHeader = ({ content, showSubHeader = true }: Props): JSX.Element => {
  return (
    <div className={`${STYLES.wrapper} ${!showSubHeader ? STYLES.hidden : ''}`}>
      {content}
    </div>
  )
}

export type { Props }

export { SubHeader }
