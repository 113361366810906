// @noflow
import { useReactiveVar } from '@apollo/client'
import i18next from 'i18next'
import isNull from 'lodash/isNull'
import React, {
  FormEvent,
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { possessive, pronounContext } from '@/utils/StringHelper'

import DragArrow from 'assets/images/icons/arrows/drag-arrow.svg'

import { Button } from '@/components/elements/atoms/Button'
import Text from '@/components/elements/atoms/Text/Text'
import Tooltip from '@/components/elements/atoms/Tooltip/Tooltip'
import ExpertCard from '@/components/elements/molecules/ExpertCard/ExpertCard'
import TextSeparator from '@/components/elements/molecules/TextSeparator/TextSeparator'
import StickyNavigation from '@/components/elements/organisms/StickyNavigation/StickyNavigation'
import {
  routeToNextStep,
  routeToPrevStep
} from '@/components/pages/SignupWizardPage/helpers/wizardRoutes'
import type { Route } from '@/components/pages/SignupWizardPage/types/routes'

import STYLES from './Weight.module.sass'

import { Gender } from '@/types'

import { wizardPageState } from '../../SignupWizardPage'
import {
  trackWeightEstimationShown,
  trackWeightEstimationUsed
} from '../../helpers/analytics'
import { ageToNearestWeeks, isPuppyFlow } from '../../helpers/dogAge'
import useImplicitFormSubmission from '../../helpers/useImplicitFormSubmission'
import { Dog, WizardEaterType } from '../../types/types'
import { dogWeightEstimates, waypoints } from './staticData'

type Props = {
  variant?: keyof typeof STYLES
  namespace: string
}

type WeightInputProps = {
  namespace: string
  dog: Dog
  index: number
  handleDogImageWeightChange: (weight: number | null) => void
  handleWeightChange: (dogWeight: number | null, index: number) => void
}

type WeightSliderProps = {
  namespace: string
  dog: Dog
  selectedWaypointIndex: number | null
  index: number
  handleDogImageWeightChange: (weight: number | null) => void
  handleWeightChange: (dogWeight: number | null, index: number) => void
  activeSlider: number
}

type WeightEstimateProps = {
  namespace: string
  dog: Dog
  dogIndex: number
  ageToNearestWeeks: number
  handleWeightChange: (dogWeight: number | null, index: number) => void
}

type SpeechBubbleProps = {
  namespace: string
  dogs: Array<Dog>
  dog?: Dog
}

const copyContext = 'weight_step'

const WeightInput = ({
  namespace,
  dog,
  index,
  handleWeightChange,
  handleDogImageWeightChange
}: WeightInputProps): JSX.Element => {
  const { weight } = dog
  const weightInKilograms = weight || weight === 0 ? weight / 1000 : ''

  const handleWeightInputChange = useCallback(
    (e: FormEvent<HTMLInputElement>): void => {
      const weight =
        e.currentTarget.value === '' ? null : parseFloat(e.currentTarget.value)
      const weightInGrams = weight || weight === 0 ? weight * 1000 : null
      handleWeightChange(weightInGrams, index)
      handleDogImageWeightChange(weightInGrams)
    },
    [handleWeightChange, handleDogImageWeightChange, index]
  )
  return (
    <div className={STYLES.weightInputContainer}>
      <div className={STYLES.weightInput}>
        <input
          type={'number'}
          id={'weight'}
          data-testid={'weight-input'}
          name={'weight'}
          placeholder={'00.00'}
          value={weightInKilograms}
          // eslint-disable-next-line react/jsx-no-bind
          onChange={handleWeightInputChange}
        />
        <label htmlFor="weight">
          <Text
            namespace={namespace}
            text={`${copyContext}.kg`}
            variant="textRegular16"
            colour="brandBlue500"
          />
        </label>
      </div>
    </div>
  )
}

const WeightSlider = ({
  namespace,
  dog,
  selectedWaypointIndex,
  index,
  handleWeightChange,
  handleDogImageWeightChange,
  activeSlider
}: WeightSliderProps): JSX.Element => {
  const { t } = useTranslation(namespace)
  const { weight } = dog
  const weightInGrams = weight || 0

  const weightToNearestKg = Math.round(weightInGrams / 1000) * 1000
  const activeClass = activeSlider === index ? STYLES.active : ''

  const handleWeightImageClick = useCallback(
    (index: number, waypointWeight: number): void => {
      handleWeightChange(waypointWeight, index)
      handleDogImageWeightChange(waypointWeight)
    },
    [handleWeightChange, handleDogImageWeightChange]
  )

  const handleWeightSliderChange = useCallback(
    (e: FormEvent<HTMLInputElement>): void => {
      const weight =
        e.currentTarget.value === '' ? null : parseFloat(e.currentTarget.value)
      handleWeightChange(weight, index)
      handleDogImageWeightChange(weight)
    },
    [handleWeightChange, handleDogImageWeightChange, index]
  )
  return (
    <Fragment>
      <div
        className={`${STYLES.weightSliderDogs} ${activeClass}`}
        id={'weightSlider'}
      >
        {waypoints.map(
          (waypoint, waypointIndex: number): ReactElement => (
            <button
              id={`dog-weight-image-${index}-${waypoint.dog}`}
              key={waypoint.dog}
              type="button"
              className={`${STYLES.weightSliderDog} ${
                waypointIndex === selectedWaypointIndex
                  ? `${STYLES.sizeSelected} ${
                      STYLES[
                        `grams${weightToNearestKg.toString()}` as keyof typeof STYLES
                      ]
                    }`
                  : ''
              }`}
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => handleWeightImageClick(index, waypoint.weightMid)}
            >
              <img
                src={waypoint.img}
                alt={t(waypoint.alt)}
                width={waypoint.width}
                height={waypoint.height}
              />
            </button>
          )
        )}
      </div>
      <div className={STYLES.weightSlider}>
        <div
          className={`${STYLES.dragCta} ${
            weightInGrams > 0 ? STYLES.isHidden : ''
          }`}
        >
          <Text
            variant={'textRegular14'}
            colour={'brandBlue500'}
            element={'span'}
            namespace={namespace}
            text={`${copyContext}.weight_slider_drag`}
          />
          <img
            src={DragArrow}
            alt={t(`${copyContext}.weight_slider_arrow_image_alt`)}
          />
        </div>
        <label htmlFor={`weight-slider-input-${index}`}>
          <Text
            variant={'display16'}
            colour={'brandBlue500'}
            namespace={namespace}
            text={`${copyContext}.weight_slider_kg_start`}
          />
        </label>
        <input
          id={`weight-slider-input-${index}`}
          type="range"
          step="1000"
          min="1000"
          max="50000"
          onChange={handleWeightSliderChange}
          value={weightInGrams}
        />
        <label htmlFor={`weight-slider-input-${index}`}>
          <Text
            variant={'display16'}
            colour={'brandBlue500'}
            namespace={namespace}
            text={`${copyContext}.weight_slider_kg_end`}
          />
        </label>
      </div>
    </Fragment>
  )
}

const WeightEstimate = ({
  namespace,
  dog,
  dogIndex,
  ageToNearestWeeks,
  handleWeightChange
}: WeightEstimateProps): JSX.Element => {
  const { breed } = dog

  useEffect(() => {
    trackWeightEstimationShown()
  }, [])

  const ageToNearestEstimate = ageToNearestWeeks < 8 ? 8 : ageToNearestWeeks
  const ageToNearestDogEstimate =
    breed &&
    dogWeightEstimates.find(
      (estimate) =>
        estimate.breed === breed.key &&
        estimate.ageInWeeks === ageToNearestEstimate
    )

  const breedName = ageToNearestDogEstimate && dog.breed?.name

  const weightEstimate = ageToNearestDogEstimate
    ? ageToNearestDogEstimate.weightEstimate
    : 0

  const weightEstimateInKg = weightEstimate / 1000

  const handleWeightEstimateClick = useCallback((): void => {
    handleWeightChange(weightEstimate, dogIndex)
    trackWeightEstimationUsed()
  }, [handleWeightChange, dogIndex, weightEstimate])

  return (
    <div id={'weightEstimate'} className={STYLES.weightEstimate}>
      <Text
        variant={'textRegular16'}
        colour={'brandBlue500'}
        bold
        namespace={namespace}
        text={`${copyContext}.estimate.title`}
      />
      <Text
        variant={'textRegular16'}
        colour={'brandBlue500'}
        element="p"
        bold={false}
        namespace={namespace}
        text={`${copyContext}.estimate.text`}
        variables={{
          dogAge: ageToNearestEstimate,
          dogBreed: breedName || '',
          weightEstimate: weightEstimateInKg
        }}
      />
      <div className={STYLES.weightEstimateButtonWrapper}>
        <Button
          typography={{
            namespace,
            text: `${copyContext}.estimate.button`
          }}
          disableAnalytics
          variant={'secondary'}
          // eslint-disable-next-line react/jsx-no-bind
          onClick={handleWeightEstimateClick}
        />
      </div>
    </div>
  )
}

const SpeechBubble = ({
  namespace,
  dogs,
  dog
}: SpeechBubbleProps): JSX.Element => {
  const dogNames = dogs.map((dog: Dog): string => dog.name)
  const dogGender = dogs.map((dog: Dog): Gender => dog.gender as Gender)
  const textVariables =
    dog && dog.gender
      ? {
          context: pronounContext([dog.gender], i18next.language),
          count: dogs.length,
          possessiveDogName: possessive(dog.name, i18next.language)
        }
      : {
          context: pronounContext(dogGender, i18next.language),
          count: dogs.length,
          possessiveDogName: possessive(dogNames[0], i18next.language)
        }
  return (
    <div className={STYLES.speechBubble} id={'speechBubble'}>
      <div className={STYLES.quote}>
        <div>
          <Text
            namespace={namespace}
            text={`${copyContext}.speech_bubble`}
            variables={textVariables}
            variant={'textRegular16'}
            colour={'brandBlue500'}
          />
        </div>
      </div>
      <ExpertCard
        namespace={'expert'}
        expert={{
          name: 'Sarah Barber',
          description: 'sarah_connell_sait.profession',
          imgSrc: 'Web/photos/people/sarah-connell-sait.png',
          imgAlt: 'sarah_connell_sait.image_alt'
        }}
      />
    </div>
  )
}

const Weight = ({ variant, namespace }: Props): JSX.Element => {
  const wizardState = useReactiveVar(wizardPageState)
  const { dogs } = wizardState
  const route = useLocation().pathname as Route

  const [selectedWaypointIndex, setSelectedWaypointIndex] = useState(-1)
  const [activeSlider, setActiveSlider] = useState(-1)
  const hasMultiplePuppies =
    dogs.filter((dog: Dog) => dog.ageStage === 'puppy').length > 1
  // The !! ensures a boolean is returned on finding a dog in the array which
  // fulfills the criteria, i.e. a popular breed dog

  const formIsValid = dogs.every(
    (dog: Dog) => dog.weight && dog.weight > 0 && dog.weight <= 100000
  )

  const navigate = useNavigate()

  const onEnterPress = useCallback(() => {
    navigate(routeToNextStep({ route }))
  }, [navigate, route])

  useImplicitFormSubmission({ formIsValid, onEnterPress })

  const isPopularBreed =
    !isNull(dogs) && !isNull(dogs[0].breed)
      ? !!dogWeightEstimates.find(({ breed }) => breed === dogs[0].breed?.key)
      : false

  useEffect(() => {
    if (isPuppyFlow(dogs)) {
      dogs.map(
        (dog: Dog): string => (dog.eaterType = WizardEaterType.GoodEater)
      )
    }
    wizardPageState({
      ...wizardState,
      dogs
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDogImageWeightChange = useCallback((weight: number | null) => {
    const dogWeight = weight || 0
    waypoints.forEach((waypoint, i: number) => {
      if (dogWeight >= waypoint.weightMin && dogWeight < waypoint.weightMax) {
        setSelectedWaypointIndex(i)
      } else if (dogWeight === 0) {
        setSelectedWaypointIndex(-1)
      }
    })
  }, [])

  const handleWeightChange = useCallback(
    (dogWeight: number | null, index: number): void => {
      dogs[index].weight = dogWeight
      setActiveSlider(index)
      wizardPageState({
        ...wizardState,
        dogs
      })
    },
    [wizardState, dogs, setActiveSlider]
  )

  const tooltipList = [
    // eslint-disable-next-line i18next/no-literal-string
    `${copyContext}.tooltip.list.step_1`,
    // eslint-disable-next-line i18next/no-literal-string
    `${copyContext}.tooltip.list.step_2`,
    // eslint-disable-next-line i18next/no-literal-string
    `${copyContext}.tooltip.list.step_3`
  ]
  return (
    <>
      <div className={variant ? STYLES[variant] : ''}>
        <div id={'whatMassStep'} className={STYLES.question}>
          {dogs.map((dog: Dog, index: number) => (
            <div key={index.toString()}>
              <div className={STYLES.subQuestion}>
                <TextSeparator
                  text={`${copyContext}.text_separator`}
                  namespace={namespace}
                  variables={{ dogName: dog.name }}
                />
                <WeightInput
                  namespace={namespace}
                  index={index}
                  dog={dog}
                  handleWeightChange={handleWeightChange}
                  handleDogImageWeightChange={handleDogImageWeightChange}
                />
                {isPuppyFlow(dogs) &&
                dog.broughtHome === false &&
                isPopularBreed &&
                dog.age ? (
                  <WeightEstimate
                    namespace={namespace}
                    dog={dog}
                    dogIndex={index}
                    ageToNearestWeeks={ageToNearestWeeks({
                      weeks: dog.age.weeks,
                      months: dog.age.months
                    })}
                    handleWeightChange={handleWeightChange}
                  />
                ) : (
                  <Fragment>
                    <WeightSlider
                      namespace={namespace}
                      dog={dog}
                      index={index}
                      selectedWaypointIndex={selectedWaypointIndex}
                      handleWeightChange={handleWeightChange}
                      handleDogImageWeightChange={handleDogImageWeightChange}
                      activeSlider={activeSlider}
                    />
                    <Text
                      namespace={namespace}
                      text={'weight_step.weight_slider_prompt'}
                      variables={{
                        context: pronounContext(
                          [dog.gender as Gender],
                          i18next.language
                        )
                      }}
                      variant={'textRegular16'}
                      colour={'brandBlue500'}
                    />
                  </Fragment>
                )}
                {dog.ageStage && dog.ageStage === 'puppy' && (
                  <div className={STYLES.tooltipContainer}>
                    <Tooltip
                      label={{
                        namespace: namespace,
                        text: 'weight_step.tooltip.label',
                        variables: { dogName: dog.name }
                      }}
                      identifier="signup_wizard.weight_step.tooltip"
                    >
                      <ul>
                        {tooltipList.map((listItem: string, index: number) => (
                          <li key={index.toString()}>
                            <Text
                              namespace={namespace}
                              text={listItem}
                              shouldScale={false}
                              variables={{
                                context: pronounContext(
                                  [dog.gender as Gender],
                                  i18next.language
                                ),
                                dogName: dog.name,
                                possessiveDogName: possessive(
                                  dog.name,
                                  i18next.language
                                )
                              }}
                            />
                          </li>
                        ))}
                      </ul>
                    </Tooltip>
                  </div>
                )}
                {dog.weight && dog.weight > 100000 && (
                  <div id={'alert'} className={STYLES.alert}>
                    <Text
                      variant={'textRegular16'}
                      colour={'brandBlue500'}
                      element="p"
                      bold
                      namespace={namespace}
                      text={`weight_step.big_dog.title`}
                    />
                    <Text
                      variant={'textRegular16'}
                      colour={'brandBlue500'}
                      element="p"
                      bold={false}
                      namespace={namespace}
                      text={`weight_step.big_dog.text`}
                      variables={{ dogName: dog.name }}
                    />
                  </div>
                )}
                {!hasMultiplePuppies &&
                  dog.ageStage &&
                  dog.ageStage === 'puppy' && (
                    <SpeechBubble namespace={namespace} dogs={dogs} dog={dog} />
                  )}
              </div>
            </div>
          ))}
          {hasMultiplePuppies && (
            <SpeechBubble namespace={namespace} dogs={dogs} />
          )}
        </div>
      </div>
      <StickyNavigation
        variant="twoButtons"
        buttonOne={{
          url: routeToPrevStep({ route }),
          text: 'wizard_flow:sticky_navigation.back',
          variant: 'secondary',
          iconColour: 'brandRed400'
        }}
        buttonTwo={{
          dataTestId: 'next-button',
          url: routeToNextStep({ route }),
          text: 'wizard_flow:sticky_navigation.continue',
          variant: 'primary',
          iconColour: 'brandWhite'
        }}
        disabled={!formIsValid}
      />
    </>
  )
}

export { Props }
export default Weight
