import { useReactiveVar } from '@apollo/client'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import Text from '@/components/elements/atoms/Text/Text'
import { customerIssueManagementState } from '@/components/pages/CustomerIssueManagementPage/CustomerIssueManagementPage'
import Accordion from '@/components/pages/CustomerIssueManagementPage/components/Accordion/Accordion'
import CustomerIssueManagementRoutes from '@/components/pages/CustomerIssueManagementPage/types/routes'
import { MultiSelectItem } from '@/components/pages/CustomerIssueManagementPage/types/types'

import STYLES from '../../InputsScreen.module.sass'

import ConfirmationBox from './ConfirmationBox'

type AccordionItemsProps = {
  value: unknown
  label: string
  thumbnailSrc: string
  checkedValues: Array<unknown>
  addCheckedValue: (newValue: unknown) => void
  removeCheckedValue: (valueToRemove: unknown) => void
}

const AccordionItem = ({
  value,
  label,
  thumbnailSrc,
  checkedValues,
  addCheckedValue,
  removeCheckedValue
}: AccordionItemsProps) => {
  const onChange = React.useCallback(
    (isChecked: boolean) => {
      if (isChecked) {
        addCheckedValue(value)
      } else {
        removeCheckedValue(value)
      }
    },
    [value, addCheckedValue, removeCheckedValue]
  )

  const checked = useMemo(() => {
    return checkedValues.includes(value)
  }, [checkedValues, value])

  return (
    <ConfirmationBox
      checked={checked}
      label={label}
      padding
      thumbnailSrc={thumbnailSrc}
      setValue={onChange}
    />
  )
}

type MultiSelectAccordionsProps = {
  items: Array<Array<MultiSelectItem>>
  titles: Array<string>
  checkedValues: Array<unknown>
  addCheckedValue: (newValue: unknown) => void
  removeCheckedValue: (valueToRemove: unknown) => void
}

const MultiSelectAccordions = ({
  items,
  titles,
  checkedValues,
  addCheckedValue,
  removeCheckedValue
}: MultiSelectAccordionsProps) => {
  const customerIssueManagementData = useReactiveVar(
    customerIssueManagementState
  )
  return (
    <>
      {items.map((itemGroup, index) => {
        return (
          itemGroup.length > 0 && (
            <Accordion
              namespace={customerIssueManagementData.namespace}
              key={titles[index]}
              title={titles[index]}
            >
              {itemGroup.map((item) => {
                return (
                  <AccordionItem
                    key={item.label.split(' ').join('')}
                    value={item.value}
                    label={item.label}
                    thumbnailSrc={item.thumbnailSrc}
                    checkedValues={checkedValues}
                    addCheckedValue={addCheckedValue}
                    removeCheckedValue={removeCheckedValue}
                  />
                )
              })}
            </Accordion>
          )
        )
      })}
    </>
  )
}

type MultiselectProps = {
  items: Array<Array<MultiSelectItem>>
  selectedItems: Array<unknown>
  titles: Array<string>
  onSelected: (values: Array<unknown>) => void
}

const Multiselect = ({
  items,
  titles,
  selectedItems,
  onSelected
}: MultiselectProps): JSX.Element => {
  const [checkedValues, setCheckedValues] = useState<Array<unknown>>([])
  const customerIssueManagementData = useReactiveVar(
    customerIssueManagementState
  )

  const addCheckedValue = useCallback(
    (newValue: unknown) => {
      if (!checkedValues.find((value) => value === newValue)) {
        const updatedCheckkedValues = [...checkedValues, newValue]
        onSelected(updatedCheckkedValues)
      }
    },
    [checkedValues, onSelected]
  )

  const removeCheckedValue = useCallback(
    (newValue: unknown) => {
      if (checkedValues.find((value) => value === newValue)) {
        const updatedCheckkedValues = checkedValues.filter(
          (value) => value !== newValue
        )
        onSelected(updatedCheckkedValues)
      }
    },
    [checkedValues, onSelected]
  )

  useEffect(() => {
    if (selectedItems) {
      setCheckedValues(selectedItems)
    }
  }, [selectedItems])

  return (
    <div className={STYLES.multiselect}>
      <MultiSelectAccordions
        items={items}
        titles={titles}
        checkedValues={checkedValues}
        addCheckedValue={addCheckedValue}
        removeCheckedValue={removeCheckedValue}
      />
      <Link to={CustomerIssueManagementRoutes.ContactCustomerLove}>
        <Text
          namespace={customerIssueManagementData.namespace}
          text={'inputs.cantSeeItem'}
          variant="textRegular16"
          colour="brandBlue500"
          align={'center'}
        />
      </Link>
    </div>
  )
}

export default Multiselect
