import React, { useCallback } from 'react'

import AppStoreBadge from 'assets/images/buttons/app-store-badge.svg'
import GooglePlayBadge from 'assets/images/buttons/google-play-badge.png'

import { Analytics } from '@/components/analytics/Analytics'

import STYLES from './AppDownloadButtons.module.sass'

import { Code } from '@/types'

type Props = {
  shippingCode?: Code
}

const AppDownloadButtons = ({ shippingCode }: Props): JSX.Element => {
  const psibufet = shippingCode === Code.PL
  const path = window.location.pathname
  const trackClickOnAppStoreDownload = useCallback(() => {
    Analytics.track('Download Butternut App on AppStore Clicked', { path })
  }, [path])

  const trackClickOnGooglePlayDownload = useCallback(() => {
    Analytics.track('Download Butternut App on Google Play Clicked', { path })
  }, [path])

  return (
    <div className={STYLES.buttonWrapper}>
      <a
        href={
          psibufet
            ? 'https://apps.apple.com/pl/app/psibufet/id6476685062'
            : 'https://apps.apple.com/app/butternut-box/id1575215029'
        }
        className={STYLES.downloadCTA}
        target="_blank"
        rel="noopener noreferrer"
        onClick={trackClickOnAppStoreDownload}
      >
        <img
          src={AppStoreBadge}
          alt="Download the Butternut app on the Apple App Store"
        />
      </a>
      <a
        href={
          psibufet
            ? 'https://play.google.com/store/apps/details?id=com.psibufet.baxter'
            : 'https://play.google.com/store/apps/details?id=com.butternutbox.baxter'
        }
        className={STYLES.downloadCTA}
        target="_blank"
        rel="noopener noreferrer"
        onClick={trackClickOnGooglePlayDownload}
      >
        <img
          src={GooglePlayBadge}
          alt="Download the Butternut app on the Google Play Store"
        />
      </a>
    </div>
  )
}

export default AppDownloadButtons
