import { gql } from '@apollo/client'

const ADD_DOGS_MUTATION = gql`
  mutation addDogs($dogs: [DogInput!]!) {
    addDogs(dogs: $dogs) {
      dogs {
        id
      }
      mealSelectionTypeSwitched
    }
  }
`

export { ADD_DOGS_MUTATION }
