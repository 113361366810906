// @noflow
import type { Language } from '@/packs/localisation'
import React, { useCallback, useEffect } from 'react'

// Utils
import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { formatCurrencyWithDecimal } from '@/utils/currency'

// Assets
import WhiteTick from 'assets/images/icons/checkmarks/white-tick.svg'

// Components
import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'
import CloudinaryImage from '@/components/elements/atoms/CloudinaryImage'
import Link from '@/components/elements/atoms/Link/Link'
import SkeletonButton from '@/components/elements/atoms/SkeletonButton/SkeletonButton'
import SkeletonImage from '@/components/elements/atoms/SkeletonImage/SkeletonImage'
import SkeletonParagraph from '@/components/elements/atoms/SkeletonParagraph/SkeletonParagraph'
import Text from '@/components/elements/atoms/Text/Text'
import Tooltip from '@/components/elements/atoms/Tooltip/Tooltip'

// Styles
import STYLES from './RecipeCard.module.sass'

import { recipeCardFlavourFragment } from './fragment/__generated__/recipeCardFlavourFragment'
// Types
import { Code } from '@/types'

type Recipe = {
  id: string
  thumbnail: string
  name: string
  description: string
  price: number
  discountedPrice: number
  selected: boolean
  hasAllergies: boolean
  hasHealthIssues: boolean
  slug: recipeCardFlavourFragment['slug']
  recipeSurcharge: number
}

type Props = {
  dataTestId?: string
  recipe: Recipe
  onSelect: (selected: boolean, recipe: Recipe) => void
  namespace: string
  shippingCountryCode: Code
  preferredLanguage: Language
  dogNames: Array<string>
  loading: boolean
  disableSelection?: boolean
  tooltipOnOpen?: () => void
  onReadMoreClick?: (slug: string) => void
}

const SkeletonRecipeCard = (): JSX.Element => {
  return (
    <Card padding={0}>
      <div className={STYLES.container}>
        <SkeletonImage
          borderRadius="1.6rem 1.6rem 0 0"
          height={200}
          width={270}
        />
        <div className={STYLES.content}>
          <SkeletonParagraph shortLine={false} count={1} height="1.8rem" />
          <SkeletonParagraph
            shortLine={false}
            count={1}
            height="1.4rem"
            width="10rem"
          />
          <SkeletonParagraph count={1} height="1.2rem" />
        </div>
        <div className={STYLES.cta}>
          <SkeletonButton
            margin="0"
            width="100%"
            height="4rem"
            borderRadius="1rem"
          />
        </div>
      </div>
    </Card>
  )
}

const RecipeCard = ({
  dataTestId,
  recipe,
  onSelect,
  namespace,
  shippingCountryCode,
  preferredLanguage,
  dogNames,
  loading,
  disableSelection = false,
  tooltipOnOpen,
  onReadMoreClick
}: Props): JSX.Element => {
  const {
    name,
    description,
    hasAllergies,
    hasHealthIssues,
    selected,
    thumbnail,
    recipeSurcharge,
    slug
  } = recipe

  const handleOnClick = useCallback(() => {
    onSelect(!selected, recipe)
  }, [onSelect, recipe, selected])

  const handleReadMore = useCallback(() => {
    if (!slug || !onReadMoreClick) return
    onReadMoreClick(slug)
  }, [slug, onReadMoreClick])

  useEffect(() => {
    if (hasAllergies || hasHealthIssues) {
      onSelect(false, recipe)
    }
    // This causes a continuous re-render otherwise and we only want this to
    // happen once on page load to avoid overriding the user's choice to select
    // a recipe even if their dog has allergies/health issues to a flavour
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) return <SkeletonRecipeCard />

  const copyContext = 'recipes.recipe_card'
  const { locale, currency } = countryCodeToLocaleCurrency(
    shippingCountryCode,
    preferredLanguage
  )

  return (
    <Card padding={0}>
      <div data-testid={dataTestId} className={STYLES.container}>
        {hasAllergies && !hasHealthIssues && (
          <div className={STYLES.allergyBanner}>
            <Text
              shouldScale={false}
              namespace={namespace}
              text={`${copyContext}.allergies`}
              variables={{
                count: dogNames.length,
                dogName: dogNames
              }}
              margin={false}
              variant="display14"
              colour="brandWhite"
              align="center"
            />
          </div>
        )}
        {hasHealthIssues && (
          <div className={STYLES.healthIssueBanner}>
            <Text
              shouldScale={false}
              namespace={namespace}
              text={`${copyContext}.health_issues`}
              variables={{
                count: dogNames.length,
                dogName: dogNames
              }}
              margin={false}
              variant="display14"
              align="center"
            />
          </div>
        )}
        <CloudinaryImage
          alt={name}
          className={STYLES.mealImage}
          image={{
            path: thumbnail,
            height: 200,
            dpr: window.devicePixelRatio,
            width: 270,
            crop: 'fill'
          }}
        />
        {selected && (
          <div
            data-testid="recipe-card-selected-flavour"
            className={STYLES.selectedBanner}
          >
            <img
              src={WhiteTick}
              className={STYLES.checkmark}
              alt={`${copyContext}.tick`}
            />
            <Text
              shouldScale={false}
              namespace={namespace}
              text={`${copyContext}.selected`}
              margin={false}
              variant="display14"
              colour="brandWhite"
              align="center"
            />
          </div>
        )}
        <div className={STYLES.content}>
          <div className={STYLES.nameAndToolTipContainer}>
            <Text
              shouldScale={false}
              text={name}
              translate={false}
              margin={false}
              variant="display16"
            />
            {recipeSurcharge > 0 && (
              <Tooltip
                size={14}
                onOpen={tooltipOnOpen}
                tooltipMaxWidth={200}
                label={{
                  colour: 'brandBlue600',
                  namespace,
                  text: `${copyContext}.surcharge_tooltip.text`,
                  variables: {
                    formattedSurchargeAmount: formatCurrencyWithDecimal(
                      recipeSurcharge,
                      {
                        locale: locale,
                        currency: currency
                      }
                    )
                  }
                }}
                identifier={`recipe_card.${slug}.surcharge_tooltip`}
              >
                <Text
                  namespace={namespace}
                  text={`${copyContext}.surcharge_tooltip.content`}
                  variant="textRegular14"
                  colour="brandBlue500"
                />
              </Tooltip>
            )}
          </div>
          <Text
            shouldScale={false}
            text={description}
            translate={false}
            margin={false}
            variant="textRegular16"
            colour="brandBlue400"
          />
          {onReadMoreClick && (
            <div className={STYLES.readMore}>
              <Link
                text={`read_more`}
                onClick={handleReadMore}
                namespace={'shared'}
                suffix={null}
                shouldScale={false}
                identifier={`recipe_card.${slug}.read_more`}
              />
            </div>
          )}
        </div>
        <div className={STYLES.cta}>
          <Button
            variant={selected ? 'secondary' : 'primary'}
            onClick={handleOnClick}
            typography={{
              text: selected
                ? `${copyContext}.cta_remove`
                : `${copyContext}.cta_add`,
              namespace
            }}
            disabled={disableSelection && !selected}
            fullWidth
            disableAnalytics
          />
        </div>
      </div>
    </Card>
  )
}

export { Props, Recipe, SkeletonRecipeCard }
export default RecipeCard
