import { gql } from '@apollo/client'

const CREATE_DOG_PROFILES_MUTATION = gql`
  mutation CreateDogProfilesMutation($userId: ID!, $dogIdsInput: [ID!]!) {
    dogProfilesCreate(userId: $userId, dogIdsInput: $dogIdsInput) {
      id
    }
  }
`

const UPDATE_DOG_PERSONALITY_MUTATION = gql`
  mutation UpdateDogPersonalityMutation(
    $userId: ID!
    $traitsInput: [TraitsInput!]!
    $activitiesInput: [ActivitiesInput!]!
    $favouriteGamesInput: [FavouriteGameInput!]!
  ) {
    traitsUpdate(userId: $userId, traitsInput: $traitsInput) {
      id
    }
    activitiesUpdate(userId: $userId, activitiesInput: $activitiesInput) {
      id
    }
    favouriteGamesUpdate(
      userId: $userId
      favouriteGamesInput: $favouriteGamesInput
    ) {
      id
    }
  }
`

export { CREATE_DOG_PROFILES_MUTATION, UPDATE_DOG_PERSONALITY_MUTATION }
