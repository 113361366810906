import { useReactiveVar } from '@apollo/client'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import CategoryButtons from './components/CategoryButtons'

import STYLES from './Categories.module.sass'

import { SelfResolutionCategories_categories as CustomerIssueCategory } from '../../queries/__generated__/SelfResolutionCategories'

import {
  categoriesState,
  selfResolutionPageState
} from '../../SelfResolutionPage'
import useSelfResolutionTracking from '../../analytics'
import useQueryParam from '../../helpers/useQueryParam'
import SelfResolutionRoutes from '../../types/routes'

type Props = {
  returnPath: string
  boxId: string | null
  categorySubmission: (categoryId: string, categoryName: string) => void
}

const Categories = ({
  returnPath,
  boxId,
  categorySubmission
}: Props): JSX.Element => {
  const selfResolutionState = useReactiveVar(selfResolutionPageState)
  const categoriesData = useReactiveVar(categoriesState)
  const pageTitle = 'categories.title'
  const categoryIdParam = useQueryParam('category_id')
  const navigate = useNavigate()
  const [categoryId, setCategoryId] = useState<string | null>(categoryIdParam)
  const [categories, setCategories] = useState<Array<CustomerIssueCategory>>([])
  const selfResolutionTracking = useSelfResolutionTracking()

  useEffect(() => {
    selfResolutionPageState({
      ...selfResolutionState,
      pageTitle
    })
    selfResolutionTracking.stepLoaded('Categories', 'Categories')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setCategoryId(categoryIdParam)
  }, [categoryIdParam])

  useEffect(() => {
    const descendantCategories = categoriesData.filter(
      (cat) => cat.parentId === categoryId
    )
    if (descendantCategories.length > 0) {
      setCategories(descendantCategories)
    } else {
      setCategoryId(null)
    }
  }, [categoryId, categoriesData])

  const selectCategory = useCallback(
    (id: string) => {
      const category = categoriesData.find((cat) => cat.id === id)
      if (category) {
        const parentCategory = categoriesData.find(
          (cat) => cat.id === category.parentId
        )
        selfResolutionTracking.categorySelected(
          category?.name,
          parentCategory?.name
        )
        const hasDescendantCategories = !!categoriesData.find(
          (cat) => cat.parentId === id
        )
        if (hasDescendantCategories) {
          const path =
            SelfResolutionRoutes.Categories +
            '?box_id=' +
            boxId +
            '&category_id=' +
            id
          navigate(path)
        } else {
          categorySubmission(id, category.name)
        }
      }
    },
    [
      boxId,
      categoriesData,
      selfResolutionTracking,
      categorySubmission,
      navigate
    ]
  )

  if (!selfResolutionState.showBackButton) {
    navigate(returnPath, { replace: true })
  }
  return (
    <div className={`${STYLES.container}`}>
      <CategoryButtons
        categories={categories}
        selectCategory={selectCategory}
      />
    </div>
  )
}

export { Props }
export default Categories
