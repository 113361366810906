// @noflow
import i18next from 'i18next'
import upperFirst from 'lodash/upperFirst'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { possessive } from '@/utils/StringHelper'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'
import Image from '@/components/elements/atoms/Image'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from '../HealthSection.module.sass'

import { DogProfileQuery_user_dogs as Dog } from '../../../queries/__generated__/DogProfileQuery'

type Props = {
  dog?: Dog
  loading?: boolean
  handleNavigateToPetCarePage: () => void
}

const UpsellEntryPoint = ({
  dog,
  loading = false,
  handleNavigateToPetCarePage
}: Props): JSX.Element => {
  const { windowWidth } = useWindowSize()

  const isMobile = windowWidth < BREAKPOINTS.md

  const careType = dog?.recommendedCareDetails.careType

  const { t } = useTranslation('account')

  const backgroundImage = useMemo(() => {
    return careType === 'puppy' ? 'puppy_care' : 'new_care_image'
  }, [careType])

  return (
    <SectionWrapper
      headerTypography={{
        text: 'dog_profile.health_section.title',
        namespace: 'account',
        translate: true,
        variables: {
          possessivePronoun: upperFirst(dog?.possessivePronoun ?? '')
        }
      }}
    >
      <div className={STYLES.content}>
        <Card
          background={false}
          className={STYLES.card}
          padding={0}
          onClick={handleNavigateToPetCarePage}
          identifier="dog_profile.health_section.card"
        >
          <div className={STYLES.image}>
            <div className={STYLES.imageOverlay} />
            <Image
              slug={backgroundImage}
              image={{
                resizeMode: 'resize_to_fill',
                width: 483,
                height: 289
              }}
              alt={t('dog_profile.health_section.alt')}
            />
          </div>
        </Card>

        <Card
          shadow={!isMobile}
          padding={isMobile ? 0 : 32}
          background={!isMobile}
          skeleton={{ isLoading: loading, height: '100%' }}
        >
          <div className={STYLES.infoWrapper}>
            <div className={STYLES.info}>
              <div className={STYLES.infoHeader}>
                <Text
                  text="dog_profile.health_section.info_header"
                  variant="display20"
                  variables={{ dogName: upperFirst(dog?.name) }}
                  namespace="account"
                  margin={false}
                />
              </div>
              <Text
                text={`dog_profile.health_section.${careType}`}
                variables={{
                  dogName: upperFirst(dog?.name),
                  possessiveName: possessive(
                    dog?.name ?? 'loading',
                    i18next.language
                  ),
                  possessivePronoun: dog?.possessivePronoun
                }}
                variant="textRegular16"
                namespace="account"
                margin={false}
              />
            </div>
            <Button
              fullWidth
              identifier="dog_profile.health_section.info_card_book_appointment"
              typography={{
                text: 'dog_profile.health_section.info_card_book_appointment',
                namespace: 'account'
              }}
              onClick={handleNavigateToPetCarePage}
            />
          </div>
        </Card>
      </div>
    </SectionWrapper>
  )
}

export { UpsellEntryPoint }
