// @flow

import * as React from 'react'
import i18next from 'i18next'
import initI18n from '@/packs/localisation'
import DashboardAlert from '../shared/DashboardAlert'
import InfluencerReferralTab from '../affiliates/tabs/influencer_referral_tab'
import SelectBarComponent from '../shared/SelectBarComponent'

import { TABS } from './tabs'

import type { Influencer } from './message_types'

type State = {|
  alertMessage: ?string,
  alertIsError: boolean
|}

type Props = {|
  influencer: Influencer
|}

class InfluencerDashboardSummary extends React.Component<Props, State> {
  constructor (props: Props): void {
    super(props)
    if (!i18next.isInitialized) initI18n(this.props.influencer.preferred_language)
    this.state = {
      alertMessage: null,
      alertIsError: false,
    }
  }

  setAlertMessage = (message: string, alertIsError: boolean = false): void => {
    window.scrollTo(0, 0)
    this.setState({
      alertMessage: message,
      alertIsError: alertIsError,
    })
    setTimeout((): void => {
      this.setState({alertMessage: null})
    }, 3000)
  }

  render = (): React.Node => (
    <div className='butternutbox-affiliate-dashboard'>
      {/* $FlowFixMe */}
      <DashboardAlert
        message={this.state.alertMessage}
        isError={this.state.alertIsError}
        shippingCountryCode={this.props.influencer.shipping_country_code}
        preferredLanguage={this.props.influencer.preferred_language}
      />
      <div className={`affiliate-dashboard__${TABS[1].hash}`}>
        <h1 className={`affiliate-dashboard__${TABS[1].hash}-title single-margin--top`}>
          {TABS[1].title}
        </h1>
        <div className='affiliate-dashboard container'>
          <SelectBarComponent
            tabs={TABS}
            selectedIndex={1}
          />
          <InfluencerReferralTab
            affiliateType='influencer'
            fullName={this.props.influencer.full_name}
            referralLink={this.props.influencer.referral_link}
            visible={true}
            setAlertMessage={this.setAlertMessage}
          />
        </div>
      </div>
    </div>
  )
}

export default InfluencerDashboardSummary
