// @noflow
import React, { useCallback } from 'react'

import useWebviewHandler from '@/hooks/useWebviewHandler'

import STYLES from './dixamessengerwebview.module.sass'

const DixaMessengerWebviewPage = (): JSX.Element => {
  const webviewHandler = useWebviewHandler()

  const onWidgetOpenChanged = useCallback(
    (open: boolean) => {
      if (!open) {
        webviewHandler.postMessage('ON-CLOSE')
      }
    },
    [webviewHandler]
  )

  React.useEffect((): void => {
    webviewHandler.pageLoaded()
    if (webviewHandler.isWebview) {
      const interval = setInterval((): void => {
        const dixaWidget = document.getElementsByClassName(
          'dixa-messenger-wrapper'
        )[0] as HTMLElement
        const dixaWidget2 = document.getElementsByClassName(
          'dixa-messenger-wrapper'
        )[1] as HTMLElement
        if (window._dixa_ && dixaWidget) {
          dixaWidget2.style.height = '92%'
          window._dixa_.invoke('setWidgetOpen', true)
          window._dixa_.addListener(
            'on-widget-open-changed',
            onWidgetOpenChanged
          )
          clearInterval(interval)
        }
      }, 2000)
    } else {
      window.location.href = '/contact'
    }
  }, [onWidgetOpenChanged, webviewHandler, webviewHandler.isWebview])
  return <div className={STYLES.container} />
}

export default DixaMessengerWebviewPage
