// @noflow

type Tab = 'funnel' | 'ideas' | 'localisation'

const allTabs: Array<Tab> = ['funnel', 'ideas', 'localisation']

const title = (tab: Tab): string => {
  switch (tab) {
    case 'funnel':
      return 'Funnel'
    case 'ideas':
      return 'Ideas'
    case 'localisation':
      return 'Localisation'
  }
}

export type { Tab }

export { allTabs, title }
