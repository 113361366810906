import { gql } from '@apollo/client'

const FOOTER_QUERY = gql`
  query FooterQuery {
    user {
      id
      subscription {
        id
        status
      }
      shippingCountry {
        id
        code
        footerLinks {
          footerColumn
          title
          url
          availableForStatuses
        }
      }
    }
  }
`

export { FOOTER_QUERY }
