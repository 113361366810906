// @noflow
import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import BRAND_COLOURS from '@/constants/BrandColours'
import SUPPORT_COLOURS from '@/constants/SupportColours'

// Styles
import STYLES from './Card.module.sass'

type Props = {
  baseColor?: string
  highlightColor?: string
  width?: string | number
  height?: string | number
}

const CardSkeleton = ({
  baseColor = SUPPORT_COLOURS.grey200,
  highlightColor = BRAND_COLOURS.brandWhite,
  width = '100%',
  height = 40
}: Props): JSX.Element => {
  return (
    <SkeletonTheme baseColor={baseColor} highlightColor={highlightColor}>
      <Skeleton
        className={`${STYLES.skeleton} ${STYLES.borderRadius}`}
        width={width}
        height={height}
      />
    </SkeletonTheme>
  )
}

export type { Props }

export { CardSkeleton }
