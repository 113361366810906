import { useReactiveVar } from '@apollo/client'
import i18next from 'i18next'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import upperFirst from 'lodash/upperFirst'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CallBackProps, Placement, STATUS } from 'react-joyride'

import { dogsDataVar } from '@/services/apollo'

import { possessive, toLocalisedSentence } from '@/utils/StringHelper'

import Card from '@/components/elements/atoms/Card/Card'
import { CardSkeleton } from '@/components/elements/atoms/Card/CardSkeleton'
import FlatButton from '@/components/elements/atoms/FlatButton/FlatButton'
import Image from '@/components/elements/atoms/Image/Image'
import Text from '@/components/elements/atoms/Text/Text'
import TourV2, {
  CustomStep,
  TourContent
} from '@/components/elements/molecules/TourV2/TourV2'

import STYLES from './HomeTourV2.module.sass'

import { upcomingBoxesLoaded } from '../../Dashboard'

const HOME_PAGE_TOUR_ID = 'home-screen-tour-v2'

interface State {
  complete: boolean
  run: boolean
}

const HomeTourV2 = (): JSX.Element | null => {
  const upcomingBoxesLoadedData = useReactiveVar(upcomingBoxesLoaded)
  const namespace = 'account'
  const { t } = useTranslation(namespace)
  const { dogs, pronoun } = useReactiveVar(dogsDataVar) || {}
  const joinedDogsNames =
    dogs && dogs.length > 0
      ? toLocalisedSentence({
          arr: dogs.map(({ name }): string => upperFirst(name)) ?? [],
          lng: i18next.language
        })
      : ''
  const joinedPossessiveDogsNames = possessive(
    joinedDogsNames,
    i18next.language
  )
  const [tourState, setTourState] = useState<State>({
    complete: false,
    run: false
  })
  const body = useRef(document.querySelector('body'))
  const [firstStepTargetReady, setFirstStepTargetReady] =
    useState<boolean>(false)

  const steps = useMemo((): CustomStep[] => {
    return [
      {
        content: (
          <TourContent
            title={{
              text: 'tours.homeV2.edit_delivery_date.title',
              namespace
            }}
            description={{
              text: 'tours.homeV2.edit_delivery_date.description',
              namespace
            }}
          />
        ),
        target: '#delivery-date-card-tour-target',
        placement: 'top' as Placement,
        placementBeacon: 'top-start' as Placement,
        disableBeacon: true,
        data: {
          id: HOME_PAGE_TOUR_ID
        }
      },
      {
        content: (
          <TourContent
            title={{
              text: 'tours.homeV2.edit_recipes.title',
              namespace,
              variables: { possessiveDogNames: joinedPossessiveDogsNames }
            }}
            description={{
              text: 'tours.homeV2.edit_recipes.description',
              namespace
            }}
          />
        ),
        target: '#edit-meals-tour-target',
        placement: 'top' as Placement,
        placementBeacon: 'top-start' as Placement,
        data: {
          id: HOME_PAGE_TOUR_ID
        }
      },
      {
        content: (
          <TourContent
            title={{
              text: 'tours.homeV2.edit_extras.title',
              namespace,
              variables: { possessiveDogNames: joinedPossessiveDogsNames }
            }}
            description={{
              text: 'tours.homeV2.edit_extras.description',
              namespace
            }}
          />
        ),
        target: '#edit-extras-tour-target',
        placement: 'top' as Placement,
        placementBeacon: 'top-end' as Placement,
        data: {
          id: HOME_PAGE_TOUR_ID
        }
      },
      {
        content: (
          <TourContent
            title={{
              text: 'tours.homeV2.edit_plan.title',
              namespace
            }}
            description={{
              text: 'tours.homeV2.edit_plan.description',
              namespace,
              variables: {
                possessiveDogNames: joinedPossessiveDogsNames,
                context: pronoun
              }
            }}
          />
        ),
        target: '#plan-details-tour-target',
        placement: 'bottom' as Placement,
        placementBeacon: 'top-start' as Placement,
        spotlightPadding: 8,
        styles: {
          spotlight: {
            borderRadius: 16
          }
        },
        data: {
          id: HOME_PAGE_TOUR_ID
        }
      }
    ]
  }, [joinedPossessiveDogsNames, pronoun])

  const handleClickRestart = useCallback(() => {
    setTourState({ ...tourState, run: true })
  }, [tourState])

  const handleJoyrideCallback = useCallback(
    (data: CallBackProps) => {
      const { status } = data
      if (status === STATUS.SKIPPED) {
        setTourState({ complete: tourState.complete ?? false, run: false })
      } else if (status === STATUS.FINISHED) {
        setTourState({ complete: true, run: false })
      }
    },
    [setTourState, tourState.complete]
  )

  useEffect(() => {
    if (isUndefined(steps) || steps.length === 0) return

    // Skip this if the target is an HTMLElement and not a selector.
    // In this case if it is not null it is renders
    if (steps[0].target instanceof HTMLElement) {
      setFirstStepTargetReady(true)
      return
    }

    // Before initialising the observer check that the selector is not already rendered
    if (!isNull(body.current) && body.current.querySelector(steps[0].target)) {
      setFirstStepTargetReady(true)
      return
    }

    if (body.current) {
      const observer = new MutationObserver((mutationList) => {
        for (const mutation of mutationList) {
          const element: HTMLElement = mutation.target as HTMLElement

          if (steps[0].target instanceof HTMLElement) {
            observer.disconnect()
            return
          }

          if (element.querySelector(steps[0].target)) {
            observer.disconnect()

            setFirstStepTargetReady(true)
          }
        }
      })

      observer.observe(document.body, { childList: true, subtree: true })
    }
  }, [body, steps])

  if (!upcomingBoxesLoadedData || !firstStepTargetReady)
    return (
      <div className={STYLES.tourCardWrapper}>
        <CardSkeleton height="15rem" />
      </div>
    )

  return (
    <>
      <div className={STYLES.tourCardWrapper}>
        <Card shadow className={STYLES.tourCard} padding={0}>
          <div className={STYLES.tourCardImage}>
            <Image
              alt={t('update_dog_profile_personality.favourite_game.image_alt')}
              slug="dogs-on-skate"
              image={{
                height: 73,
                width: 118
              }}
            />
          </div>
          <div className={STYLES.tourCardCopy}>
            <Text
              variant="display20"
              text={'tours.homeV2.tour_trigger.title'}
              namespace={namespace}
              margin={false}
            />
            <FlatButton
              text={{
                text: tourState.complete
                  ? 'tours.homeV2.tour_trigger.cta_retake'
                  : 'tours.homeV2.tour_trigger.cta',
                namespace,
                variant: 'textRegular16'
              }}
              onClick={handleClickRestart}
              identifier="home-screen-tour-v2-trigger"
            />
          </div>
        </Card>
      </div>
      <TourV2
        callback={handleJoyrideCallback}
        run={tourState.run}
        steps={steps}
      />
    </>
  )
}

export default HomeTourV2
