import { Language } from '@/packs/localisation'
import times from 'lodash/times'
import React, { useCallback, useEffect, useRef } from 'react'

// Hooks
import useIntersectionObserver from '@/hooks/useIntersectionObserver'
import useWindowSize from '@/hooks/useWindowSize'

// Components
import Text from '@/components/elements/atoms/Text/Text'
import RecipeCard, {
  Recipe,
  SkeletonRecipeCard
} from '@/components/elements/molecules/RecipeCard/RecipeCard'
import SwiperSlider from '@/components/elements/molecules/SwiperSlider/SwiperSlider'
import { BREAKPOINTS } from '@/components/templates/Base'

// Styles
import STYLES from './SecondaryRecipesSection.module.sass'

// Types
import { Code } from '@/types'

import {
  trackCarouselInteraction,
  trackSecondaryRecipesShown
} from '../../../../helpers/analytics'
import { Action, TooltipLocation } from '../../../../types/analyticsProperties'

type Props = {
  dogNames: Array<string>
  namespace: string
  copyContext: string
  loading: boolean
  shippingCountryCode: Code
  preferredLanguage: Language
  recipes: Array<Recipe>
  updateRecipes: (selected: boolean, recipe: Recipe) => void
  maxFlavoursReached: boolean
  handleTooltipOpened: (location: TooltipLocation) => void
  onReadMoreClick?: (slug: string) => void
}

const SecondaryRecipesSection = ({
  dogNames,
  namespace,
  copyContext,
  loading,
  preferredLanguage,
  shippingCountryCode,
  updateRecipes,
  recipes,
  maxFlavoursReached,
  handleTooltipOpened,
  onReadMoreClick
}: Props): JSX.Element | null => {
  const { windowWidth } = useWindowSize()

  const secondaryRecipesShownRef = useRef<HTMLDivElement>(null)

  const secondaryRecipesShown = useIntersectionObserver(
    secondaryRecipesShownRef,
    {
      threshold: [0.2]
    }
  )

  useEffect(() => {
    if (secondaryRecipesShown) {
      trackSecondaryRecipesShown()
    }
  }, [secondaryRecipesShown])

  const handleSliderFirstMove = useCallback(() => {
    trackCarouselInteraction({
      action: Action.onSwipe,
      section: 'secondaryRecipes'
    })
  }, [])

  const handleArrowClick = useCallback(() => {
    trackCarouselInteraction({
      action: Action.arrowClicked,
      section: 'secondaryRecipes'
    })
  }, [])

  const handleTooltipOnOpen = useCallback(() => {
    handleTooltipOpened(TooltipLocation.otherRecipes)
  }, [handleTooltipOpened])

  return (
    <div ref={secondaryRecipesShownRef}>
      <div className={STYLES.title}>
        <Text
          namespace={namespace}
          text={`${copyContext}.secondary_recipes.other`}
          variant="display20"
          margin={false}
          align="left"
          bold
        />
      </div>
      <div className={STYLES.carouselWrapper}>
        <SwiperSlider
          arrows={windowWidth > BREAKPOINTS.md}
          onSliderFirstMove={handleSliderFirstMove}
          onArrowClick={handleArrowClick}
        >
          {loading
            ? times(5).map((n) => <SkeletonRecipeCard key={n} />)
            : recipes.map((recipe) => {
                return (
                  <RecipeCard
                    dataTestId="secondary-recipes-recipe-card"
                    key={recipe.name}
                    dogNames={dogNames}
                    loading={loading}
                    namespace={namespace}
                    preferredLanguage={preferredLanguage}
                    recipe={recipe}
                    shippingCountryCode={shippingCountryCode}
                    onSelect={updateRecipes}
                    disableSelection={maxFlavoursReached}
                    tooltipOnOpen={handleTooltipOnOpen}
                    onReadMoreClick={onReadMoreClick}
                  />
                )
              })}
        </SwiperSlider>
      </div>
    </div>
  )
}

export default SecondaryRecipesSection
