// @flow

import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import * as ACTIONS from '../actions'
import * as THUNKS from '../thunks'
import Header from './../../shared/wizards/Header'
import Chevron from '../../shared/images/Chevron'
import BRAND_COLOURS from '../../../constants/BrandColours'
import PostSignUpRoutes from '../postSignUpRoutes'
import { pronounContext } from '@/utils/StringHelper'
import { capitaliseFirstLetter } from '../../../utils/StringHelper'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

import type { Gender } from '../../../shared_types/rails_models/dogs'
import type { State } from '../reducers'
import type { Dog } from '../message_types'
import type { Dispatch } from 'redux'
import type { RailsModelID as ID } from '../../../shared_types/ids'

type PresentationalProps = {|
  dogs: Array<Dog>
|}

type ActionProps = {|
  dogDescriptionUpdate: () => void,
  updateDogDescription: (ID, SyntheticEvent<HTMLTextAreaElement>) => void
|}

type Props =
  & PresentationalProps
  & ActionProps

type Translate = (
  arg0: string,
  arg1?: {|
    context?: string,
    count?: number,
    dogName?: string
  |}
  ) => string

const mapStateToProps = ({ globalAttributes }: State): PresentationalProps => {
  const { dogs } = globalAttributes

  return {
    dogs
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => {
  const dogDescriptionUpdate = (): void => {
    dispatch(ACTIONS.nextWizardStep())
    dispatch(THUNKS.descriptionsUpdate())
  }

  const updateDogDescription = (id: ID, event: SyntheticEvent<HTMLTextAreaElement>): void => {
    dispatch(ACTIONS.updateDogDescription(id, event.currentTarget.value))
  }

  return { dogDescriptionUpdate, updateDogDescription }
}

const generateRandomPlaceholder = ({
  name,
  gender,
  t
}: {|
  name: string,
  gender: Gender,
  t: Translate
|}): string => {
  const copyContext = 'dog_description.placeholders'
  const placeholders = [
    t(`${copyContext}.a`, { dogName: name }),
    t(`${copyContext}.b`, { dogName: name }),
    t(`${copyContext}.c`, { dogName: name, context: gender.toLowerCase() }),
    t(`${copyContext}.d`, { context: gender.toLowerCase() }),
    t(`${copyContext}.e`, { context: gender.toLowerCase() }),
    t(`${copyContext}.f`, { context: gender.toLowerCase() }),
    t(`${copyContext}.g`, { context: gender.toLowerCase() }),
  ]
  const placeholderText = placeholders[Math.floor(Math.random() * placeholders.length)]
  return placeholderText
}

const DogDescription = ({
  dogs,
  dogDescriptionUpdate,
  updateDogDescription
}: Props): React.Node => {
  const dogGenders = dogs.map(({ gender }: Dog): Gender => (gender))
  const { t } = useTranslation('post_signup_wizard')
  const copyContext = 'dog_description'

  const updateDogDescriptionMethods = dogs.reduce((acc: ({ [ID]: (SyntheticEvent<HTMLTextAreaElement>) => void }), dog: Dog): ({ [ID]: (SyntheticEvent<HTMLTextAreaElement>) => void }) => {
    acc[dog.id] = (event: SyntheticEvent<HTMLTextAreaElement>): void => updateDogDescription(dog.id, event)
    return acc
  }, {})

  return (
    <React.Fragment>
      <Header>
        <span
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: t(`${copyContext}.title`, { context: pronounContext(dogGenders, i18next.language) })
          }}
        />
      </Header>
      <div className='step-body'>
        <div className='step-body__content'>
          {
            dogs.map(({ name, id, gender }: Dog): React.Node => (
              <div
                className='dog-description'
                key={name}
              >
                <p className='dog-description__header'>
                  { t(`${copyContext}.separator_text`, { dogName: capitaliseFirstLetter(name) }) }
                </p>
                <textarea
                  className='dog-description__text-area'
                  onBlur={updateDogDescriptionMethods[id]}
                  placeholder={capitaliseFirstLetter(
                    generateRandomPlaceholder(
                      {
                        name,
                        gender,
                        t
                      }
                    )
                  )}
                  rows="7"
                />
              </div>
            ))
          }
          <Link
            className='next-button'
            onClick={dogDescriptionUpdate}
            to={PostSignUpRoutes.PostWizard}
          >
            { t(`${copyContext}.button_text_next`, { context: pronounContext(dogGenders, i18next.language) }) }
            <Chevron
              color={BRAND_COLOURS.brandWhite}
              rotation={0}
            />
          </Link>
        </div>
      </div>
    </React.Fragment>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DogDescription)
