// @noflow
import React from 'react'

import {
  ICON_COLOURS,
  IconColours
} from '@/components/elements/atoms/Icon/Icon'

type Props = {
  size: number
  accentColour: IconColours
  width?: number | string
}

const Email = ({ size, accentColour, width }: Props): JSX.Element => {
  return (
    <svg
      viewBox="0 0 72 72"
      height={size}
      width={width || '100%'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M69.8 10.8c-1.3-1.3-2.9-2-4.8-2H6.8c-1.9 0-3.5.7-4.8 2-1.3 1.3-2 2.9-2 4.8v40.3c0 1.9.7 3.5 2 4.8 1.3 1.3 2.9 2 4.8 2H65c1.9 0 3.5-.7 4.8-2 1.3-1.3 2-2.9 2-4.8V15.6c0-1.9-.7-3.5-2-4.8zM24.3 43.9c1.8 1.4 3.2 2.4 4.3 3.1 2.6 1.5 5 2.2 7.3 2.2s4.6-.7 7.1-2.2c1.2-.7 2.6-1.7 4.2-3L61 55.9H10.5l13.8-12zm28.5-4.4L65 29.9v20.3L52.8 39.5zm-46-23.9H65v5.7C61.8 24 55.5 29 46.1 36.2l-1.3 1.1c-1.9 1.6-3.4 2.7-4.5 3.4-1.8 1.2-3.3 1.8-4.5 1.8-1.2 0-2.7-.6-4.5-1.8-1.1-.7-2.6-1.8-4.5-3.4l-1.3-1.1C16.3 28.9 10 23.9 6.8 21.3v-5.7zm12 23.9l-12 10.4v-20l12 9.6z"
        fill={ICON_COLOURS[accentColour]}
      />
    </svg>
  )
}

export default Email
