import React from 'react'

import { Button } from '@/components/elements/atoms/Button'

import styles from './PauseModal.module.sass'

type Props = {
  handlePauseSubscription: () => void
  handleModalClose: () => void
  keepSubscriptionText?: string
  keepSubscriptionIdentifier?: string
}

type PauseButtonProps = {
  handlePauseSubscription: Props['handlePauseSubscription']
}

const PauseButton = ({
  handlePauseSubscription
}: PauseButtonProps): JSX.Element => (
  <Button
    identifier="stop_deliveries_button.stop"
    variant="secondary"
    typography={{
      namespace: 'account',
      text: 'subscription_settings.pause_subscription_cta_alternative'
    }}
    fullWidth
    onClick={handlePauseSubscription}
    dataTestId="pause-subscription-button-confirm"
  />
)

const Buttons = ({
  handlePauseSubscription,
  handleModalClose,
  keepSubscriptionText = 'subscription_settings.keep_discount_cta',
  keepSubscriptionIdentifier = 'keep_discount.button'
}: Props): JSX.Element => (
  <div className={styles.buttonGroup}>
    <PauseButton handlePauseSubscription={handlePauseSubscription} />

    <Button
      identifier={keepSubscriptionIdentifier}
      typography={{ namespace: 'account', text: keepSubscriptionText }}
      fullWidth
      onClick={handleModalClose}
    />
  </div>
)

export { Buttons, PauseButton }
