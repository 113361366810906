// @flow

import type { RailsModelID } from '@/shared_types/ids'
import type { CSRFToken } from '@/shared_types/csrfToken'
import type { ServerError, UnknownError } from '@/redux/graphql_queries/errorTypes'
import type { PricingCurveType, PricingCohort } from '@/shared_types/rails_models/pricingCurve'
import type { ProposedPlan, MealDetails, RecipeSurcharge } from '../message_types'
import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'

type DecodedRailsResponse = {|
  plan: {|
    id: RailsModelID,
    duration_in_days: number,
    number_of_pouches: number,
    price_per_pouch: number,
    pouch_size_in_grams: number,
    delivery_fee: number,
    pricing_curve: {|
      pricing_curve_type: PricingCurveType,
      pricing_cohort: PricingCohort
    |},
  |},
  proposed_recipe_surcharges: Array<RecipeSurcharge>,
  shipping_country_code: CountryCode,
  meal: MealDetails
|}

type Success = {|
  type: 'Success',
  data: ProposedPlan
|}

type RequestResult =
  | Success
  | ServerError
  | UnknownError

const { fetch } = global

const updateProposedPlan = ({
  csrfToken,
  durationInDays,
  numberOfPouches,
  pricingCurveType,
  pricingCohort,
  shippingCountryCode,
  userId,
  deliveryFee
}: {|
  csrfToken: CSRFToken,
  durationInDays: number,
  numberOfPouches: number,
  pricingCurveType: PricingCurveType,
  pricingCohort: PricingCohort,
  shippingCountryCode: CountryCode,
  userId: RailsModelID,
  deliveryFee: number
|}): Promise<RequestResult> => {
  const endpoint = '/admin/single_customer_view/update_proposed_plan'

  const method = 'POST'
  const credentials = 'same-origin'
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  }
  const body = JSON.stringify({
    duration_in_days: durationInDays,
    number_of_pouches: numberOfPouches,
    pricing_curve_type: pricingCurveType,
    pricing_cohort: pricingCohort,
    shipping_country_code: shippingCountryCode,
    user_id: userId,
    delivery_fee: deliveryFee
  })
  return fetch(endpoint, { headers, method, credentials, body })
    .then((res: Response): Promise<RequestResult> => {
      if (!res.ok) {
        return res.text()
          .then((message: string): RequestResult => {
            return {
              type: 'ServerError',
              status: res.status,
              message
            }
          })
      }
      return res.json()
        .then(({
          plan: {
            id,
            duration_in_days,
            number_of_pouches,
            price_per_pouch,
            pricing_curve,
            pouch_size_in_grams,
            delivery_fee
          },
          proposed_recipe_surcharges,
          shipping_country_code,
          meal
        }: DecodedRailsResponse): RequestResult => {
          if (id && duration_in_days && number_of_pouches && price_per_pouch && pricing_curve && pouch_size_in_grams && shipping_country_code && (delivery_fee || delivery_fee === 0)) {
            const { pricing_curve_type, pricing_cohort } = pricing_curve
            return {
              type: 'Success',
              data: {
                plan: {
                  id: id,
                  durationInDays: duration_in_days,
                  numberOfPouches: number_of_pouches,
                  pricePerPouch: price_per_pouch,
                  pouchSizeInGrams: pouch_size_in_grams,
                  deliveryFee: delivery_fee,
                  pricingCurve: {
                    pricingCurveType: pricing_curve_type,
                    pricingCohort: pricing_cohort
                  },
                },
                proposedRecipeSurcharges: proposed_recipe_surcharges,
                shippingCountryCode: shipping_country_code,
                meal: meal
              }
            }
          } else {
            return {
              type: 'UnknownError',
              error: 'Unknown Error'
            }
          }
        })
    })
}

export type {
  RequestResult
}

export default updateProposedPlan
