// @noflow
import i18next from 'i18next'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import {
  capitaliseFirstLetter,
  toLocalisedSentence
} from '@/utils/StringHelper'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import UpdatedNewBadge from 'assets/images/icons/badges/updated-new-badge.svg'

import { Button } from '@/components/elements/atoms/Button'
import Image from '@/components/elements/atoms/Image/Image'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './PetCareSection.module.sass'

import type { PetCareSectionDogsFragment as Dog } from './fragments/__generated__/PetCareSectionDogsFragment'

type Props = {
  dogs: Array<Dog> | null
  calendlyUrl?: string
}

const PetCareSection = ({ dogs, calendlyUrl }: Props): JSX.Element | null => {
  const { t } = useTranslation('dashboard')
  const { windowWidth } = useWindowSize()
  const imageAlt = t('pet_care_section.image_alt')

  const handleNavigateToPetCarePage = useCallback(() => {
    if (calendlyUrl) {
      window.open(calendlyUrl, '_blank', 'noopener,noreferrer')
    }
  }, [calendlyUrl])

  if (!dogs) {
    return null
  }
  const joinedDogsNames = toLocalisedSentence({
    arr: dogs.map(({ name }: Dog): string => capitaliseFirstLetter(name)),
    lng: i18next.language
  })

  return (
    <section className={STYLES.wrapper}>
      <img
        className={`${STYLES.newTag} ${
          windowWidth < BREAKPOINTS.md && STYLES.mobile
        }`}
        src={UpdatedNewBadge}
        alt={`a sticker that says "new"`}
      />
      <div className={STYLES.content}>
        <div className={STYLES.texts}>
          <div className={STYLES.title}>
            <Text
              namespace="dashboard"
              text="pet_care_section.title"
              variant="display28"
              colour="brandBlue500"
              align="left"
              element="h4"
              margin={false}
            />
          </div>
          <Text
            namespace="dashboard"
            text="pet_care_section.subtitle"
            variant="textRegular16"
            colour="brandBlue500"
            align="left"
            element="h5"
            variables={{ dogsNames: joinedDogsNames }}
          />
        </div>
        <Button
          onClick={handleNavigateToPetCarePage}
          typography={{
            namespace: 'dashboard',
            text: 'pet_care_section.button'
          }}
          identifier="Care paged clicked from dashboard entry point"
          size={windowWidth >= BREAKPOINTS.md ? 'regular' : 'slim'}
        />
      </div>
      <div className={STYLES.image}>
        <Image
          alt={imageAlt}
          slug="merchandising-care-treatment-card"
          image={{
            width: windowWidth >= BREAKPOINTS.md ? 380 : 180,
            height: 200
          }}
        />
      </div>
    </section>
  )
}

export default PetCareSection
