// @noflow
import React from 'react'

import {
  Direction,
  ICON_COLOURS,
  IconColours
} from '@/components/elements/atoms/Icon/Icon'

import STYLES from './curveddashedarrow.module.sass'

type Props = {
  size: number
  accentColour: IconColours
  width?: number | string
  direction?: Direction
}

const CurvedDashedArrow = ({
  size,
  accentColour,
  width,
  direction = 'down'
}: Props): JSX.Element => {
  return (
    <svg
      fill="none"
      height={size}
      viewBox="0 0 31 26"
      width={width || '100%'}
      className={`${direction ? STYLES[direction] : ''}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1.109C10.935.257 29.714 4.042 25.354 26"
        stroke={ICON_COLOURS[accentColour]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeDasharray="3 3"
      />
      <path
        d="M22.115 22l2.5 4.5 4.5-2.5"
        stroke={ICON_COLOURS[accentColour]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CurvedDashedArrow
