import { gql } from '@apollo/client'

import { PRICE_FRAGMENT } from '@/components/pages/ExtrasPage/screens/ProductCollection/components/molecules/ProductPrice/fragments'

const GET_UPSELLABLE_PRODUCTS = gql`
  ${PRICE_FRAGMENT}
  query GetUpsellableProducts($deliveryType: VariantDelivery, $num: Int!) {
    user {
      id
      firstName
      preferredLanguage
      shippingCountryCode
      dogs {
        id
        name
      }
      subscription {
        activeBoostedMembership {
          id
          isActive
        }
        nextNBoxes(num: $num) {
          id
          isoDeliveryDate
          physicalOrderProducts {
            frequency
            productVariant {
              id
              recurringDeliveryType {
                boostedNetPrice
              }
            }
            quantity
          }
        }
      }
    }
    extrasUpsellRecommendations(
      type: "in_account_upsell"
      deliveryType: $deliveryType
    ) {
      id
      name
      grossPrice
      recurringDeliveryType {
        adjustedGrossPrice
      }
      oneOffDeliveryType {
        adjustedGrossPrice
      }
      productCollection {
        slug
        name
        thumbnail {
          src
        }
        productVariants {
          id
          name
          ...PriceFragment
        }
      }
    }
  }
`
export { GET_UPSELLABLE_PRODUCTS }
