import { gql } from '@apollo/client'

const FOOD_CATEGORIES_QUERY = gql`
  query FoodCategories {
    foodCategories {
      id
      name
    }
  }
`

export { FOOD_CATEGORIES_QUERY }
