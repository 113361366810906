import { useReactiveVar } from '@apollo/client'
import React, { useCallback, useMemo, useState } from 'react'

import { Button } from '@/components/elements/atoms/Button'
import Modal from '@/components/elements/atoms/Modal/Modal'
import Text from '@/components/elements/atoms/Text/Text'
import ChangeAddress, {
  Address
} from '@/components/elements/molecules/ChangeAddress/ChangeAddress'

import STYLES from '../Resolution.module.sass'

import { CustomerIssueEntryInputsCreate_response_resolutionOffering_details_CustomerIssueManagementResolutionDetailsReplaceBox_defaultAddress as DeliveryAddress } from '../../../mutations/__generated__/CustomerIssueEntryInputsCreate'

import { customerIssueManagementState } from '../../../CustomerIssueManagementPage'

type Props = {
  selectedAddress: DeliveryAddress
  setSelectedAddress: (newAddress: DeliveryAddress) => void
}

const ChangeAddressButton = ({
  selectedAddress,
  setSelectedAddress
}: Props): JSX.Element => {
  const customerIssueManagementData = useReactiveVar(
    customerIssueManagementState
  )
  const [isChangeAddressOpen, setIsChangeAddressOpen] = useState(false)

  const openChangeAddress = useCallback(() => {
    setIsChangeAddressOpen(true)
  }, [setIsChangeAddressOpen])

  const closeChangeAddress = useCallback(() => {
    setIsChangeAddressOpen(false)
  }, [setIsChangeAddressOpen])

  const updateAddress = useCallback(
    (newAddress: Address) => {
      setSelectedAddress({
        ...selectedAddress,
        ...newAddress
      })
    },
    [selectedAddress, setSelectedAddress]
  )

  const niceAddress = useMemo(() => {
    return `${selectedAddress.address1}, ${selectedAddress.city}`
  }, [selectedAddress])

  return (
    <>
      <div className={STYLES.changeBox}>
        <div className={'details'}>
          <Text
            variant={'textRegular14'}
            colour={'brandBlue400'}
            namespace={customerIssueManagementData.namespace}
            text={'resolutionOffering.replacementBox.sendHere'}
            margin={false}
          />
          <Text
            variant={'textRegular16'}
            colour={'brandBlue500'}
            namespace={customerIssueManagementData.namespace}
            text={niceAddress}
            variables={{ Address: selectedAddress }}
            translate={false}
            margin={false}
          />
        </div>
        <Button
          typography={{
            text: 'resolutionOffering.replacementBox.change',
            namespace: customerIssueManagementData.namespace
          }}
          variant={'secondary'}
          onClick={openChangeAddress}
          disableAnalytics
        />
      </div>
      <Modal
        isModalOpen={isChangeAddressOpen}
        setOpenModal={setIsChangeAddressOpen}
        width={800}
      >
        <ChangeAddress
          address={{
            address1: selectedAddress.address1,
            address2: selectedAddress.address2 || '',
            city: selectedAddress.city,
            postcode: selectedAddress.postcode,
            recipientName: selectedAddress.recipientName,
            deliveryNotes: selectedAddress.deliveryNotes || '',
            deliveryCarrier: selectedAddress.deliveryCarrier || ''
          }}
          onChange={updateAddress}
          toggleModal={closeChangeAddress}
          shippingCountryCode={selectedAddress.shippingCountry.code}
        />
      </Modal>
    </>
  )
}

export { Props }
export default ChangeAddressButton
