import React from 'react'

import useWindowSize from '@/hooks/useWindowSize'

import { BREAKPOINTS } from '@/components/pages/App/App'

import STYLES from './ProductCard.module.sass'

import SkeletonButton from '../../atoms/SkeletonButton/SkeletonButton'
import SkeletonImage from '../../atoms/SkeletonImage/SkeletonImage'
import SkeletonParagraph from '../../atoms/SkeletonParagraph/SkeletonParagraph'
import SkeletonTitle from '../../atoms/SkeletonTitle/SkeletonTitle'

const ProductCardSkeleton = (): JSX.Element => {
  const { windowWidth } = useWindowSize()
  return (
    <div className={STYLES.skeleton}>
      <SkeletonImage
        height={windowWidth > BREAKPOINTS.md ? 240 : 140}
        width={'100%'}
        margin={'0 0 .8rem'}
        borderRadius="unset"
      />
      <SkeletonTitle height={18} width={180} align="left" margin=".5rem 0" />
      <SkeletonParagraph
        width={75}
        height={18}
        shortLine={false}
        count={1}
        margin=".5rem 0"
      />
      <SkeletonParagraph
        width={90}
        height={18}
        shortLine={false}
        count={1}
        margin=".5rem 0"
      />
      <SkeletonButton width={150} justify="start" margin="1rem 0" />
    </div>
  )
}

export default ProductCardSkeleton
