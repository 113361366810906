import React from 'react'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import { Button } from '@/components/elements/atoms/Button'
import Modal from '@/components/elements/atoms/Modal/Modal'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from '../../PaymentMethods.module.sass'

type Props = {
  isOpen: boolean
  suspended: boolean
  toggle: () => void
  onSubmit: () => void
}

const UpdateDefaultPaymentMethodModal = ({
  isOpen,
  suspended,
  toggle,
  onSubmit
}: Props): JSX.Element | null => {
  const { windowWidth } = useWindowSize()
  return (
    <Modal
      isModalOpen={isOpen}
      setOpenModal={toggle}
      width={800}
      textAlign="center"
      bottomSticky={windowWidth < BREAKPOINTS.md}
    >
      <Text
        namespace={'dashboard'}
        text="my_details.payment_methods.change_payment_method.heading"
        variant="display20"
        colour="brandBlue500"
        margin={false}
      />
      <div>
        {suspended && (
          <Text
            namespace={'dashboard'}
            text="my_details.payment_methods.change_payment_method.suspended_subheading"
            variant="textRegular16"
            colour="brandBlue500"
            margin={false}
          />
        )}
      </div>
      <div className={STYLES.buttonContainer}>
        <Button
          variant="secondary"
          typography={{
            text: 'my_details.payment_methods.change_payment_method.decline',
            namespace: 'dashboard'
          }}
          disableAnalytics
          onClick={toggle}
        />
        <Button
          typography={{
            text: 'my_details.payment_methods.change_payment_method.approve',
            namespace: 'dashboard'
          }}
          disableAnalytics
          onClick={onSubmit}
        />
      </div>
    </Modal>
  )
}

export { Props }
export default UpdateDefaultPaymentMethodModal
