// @noflow
import type { Language } from '@/packs/localisation'
import { format } from 'date-fns'
import i18next from 'i18next'
import React, { useCallback, useMemo } from 'react'
import type { ComponentProps, ReactElement } from 'react'

import { localeToDateLocale } from '@/utils/countryCodeHelper'

import Icon from '@/components/elements/atoms/Icon/Icon'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from '../Calendar.module.sass'

import Calendar from '../Calendar'

type CalendarProps = ComponentProps<typeof Calendar>

type Props = {
  currentMonthInView: Date
  disableRegressCurrentMonth: CalendarProps['disableRegressCurrentMonth']
  disableProgressCurrentMonth: CalendarProps['disableProgressCurrentMonth']
  shippingCountryCode: CalendarProps['shippingCountryCode']
  setCurrentMonthInView: (monthInView: Date) => void
}

const HeaderMonth = ({
  currentMonthInView,
  disableRegressCurrentMonth,
  disableProgressCurrentMonth,
  shippingCountryCode,
  setCurrentMonthInView
}: Props): ReactElement => {
  // Variables
  const dateLocale = localeToDateLocale(
    shippingCountryCode,
    i18next.language as Language
  )

  const currentMonthCopy = useMemo(
    () =>
      `${format(currentMonthInView, 'LLLL', {
        locale: dateLocale
      })} ${currentMonthInView.getFullYear()}`,
    [currentMonthInView, dateLocale]
  )

  const isRegressCurrentMonthDisabled = useMemo(
    () =>
      currentMonthInView.getMonth() === new Date().getMonth() &&
      currentMonthInView.getFullYear() === new Date().getFullYear(),
    [currentMonthInView]
  )

  // Handlers
  const handleRegressCurrentMonth = useCallback((): void => {
    const previousMonth = new Date(
      currentMonthInView.getFullYear(),
      currentMonthInView.getMonth() - 1,
      1,
      10
    )

    setCurrentMonthInView(previousMonth)
  }, [currentMonthInView, setCurrentMonthInView])

  const handleProgressCurrentMonth = useCallback((): void => {
    const nextMonth = new Date(
      currentMonthInView.getFullYear(),
      currentMonthInView.getMonth() + 1,
      1,
      10
    )

    setCurrentMonthInView(nextMonth)
  }, [currentMonthInView, setCurrentMonthInView])

  return (
    <div className={STYLES.month}>
      {!disableRegressCurrentMonth && (
        <button
          className={STYLES.monthSelector}
          type="button"
          disabled={isRegressCurrentMonthDisabled}
          onClick={handleRegressCurrentMonth}
        >
          <Icon asset="chevron" accentColour="brandBlue500" size={18} />
        </button>
      )}
      <div className={STYLES.monthName}>
        <Text
          translate={false}
          text={currentMonthCopy}
          element="p"
          colour="brandBlue500"
          variant="textRegular18"
          margin={false}
        />
      </div>
      {!disableProgressCurrentMonth && (
        <button
          className={STYLES.monthSelector}
          type="button"
          onClick={handleProgressCurrentMonth}
        >
          <Icon asset="chevron" accentColour="brandBlue500" size={18} />
        </button>
      )}
    </div>
  )
}

export default HeaderMonth
