// @noflow
import { useQuery } from '@apollo/client'
import { differenceInDays } from 'date-fns'
import React, { SyntheticEvent, useCallback } from 'react'

import { percentageValue } from '@/utils/percentage'

import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'
import Icon from '@/components/elements/atoms/Icon/Icon'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './AddDiscountedExtrasCountdownCard.module.sass'

import { ADD_DISCOUNTED_EXTRAS_COUNTDOWN_CARD_QUERY } from './queries/AddDiscountedExtrasCountdownCardQuery'

import { addDiscountedExtrasCountdownCardQuery } from './queries/__generated__/addDiscountedExtrasCountdownCardQuery'

type Props = {
  handlePlusIconClick: (e: SyntheticEvent) => void
}

const AddExtras = ({ handlePlusIconClick }: Props) => (
  <div
    onClick={handlePlusIconClick}
    onKeyDown={handlePlusIconClick}
    role="button"
    tabIndex={0}
    className={STYLES.container}
  >
    <Card border="dashed" squared>
      <div className={STYLES.addExtrasBox}>
        <Icon asset="plus" size={14} accentColour="brandBlue400" />
        <Text
          text="upcoming_box_card.add_extras"
          namespace="dashboard"
          margin={false}
          variant="textRegular16"
          shouldScale={false}
          element="span"
          colour="brandBlue400"
        />
      </div>
    </Card>
  </div>
)

const AddDiscountedExtrasCountdownCard = ({
  handlePlusIconClick
}: Props): JSX.Element => {
  const handleOnClick = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    window.analytics.track('Cta clicked', {
      location: 'explore extras',
      title: 'dashboard'
    })
    window.location.href = '/dashboard/extras'
  }, [])

  const { data, error } = useQuery<addDiscountedExtrasCountdownCardQuery>(
    ADD_DISCOUNTED_EXTRAS_COUNTDOWN_CARD_QUERY
  )
  if (!data || error)
    return <AddExtras handlePlusIconClick={handlePlusIconClick} />

  const {
    user: {
      subscription: { nextEditableBox }
    }
  } = data

  if (!nextEditableBox)
    return <AddExtras handlePlusIconClick={handlePlusIconClick} />

  const { isoDeliveryDate, hoursUntilCutOff, order } = nextEditableBox
  const value = order.discountTotalPercentage

  const today = new Date()
  const deliveryDate = new Date(isoDeliveryDate)

  const daysLeft = differenceInDays(deliveryDate, today)
  const count = daysLeft <= 0 ? hoursUntilCutOff : daysLeft
  const context = daysLeft <= 0 ? 'hour' : 'day'

  if (!value || count <= 0)
    return <AddExtras handlePlusIconClick={handlePlusIconClick} />

  return (
    <div className={STYLES.container}>
      <Card border="dashed" squared>
        <div className={STYLES.firstExtrasBox}>
          <Text
            text="upcoming_box_card.first_box.copy"
            namespace="dashboard"
            variables={{ count, context, discount: percentageValue(value) }}
            margin={false}
            variant="textRegular16"
            shouldScale={false}
            element="span"
            colour="brandBlue400"
          />
          <Button
            typography={{
              namespace: 'dashboard',
              text: 'upcoming_box_card.first_box.button'
            }}
            variant="secondary"
            onClick={handleOnClick}
            disableAnalytics
          />
        </div>
      </Card>
    </div>
  )
}

export type { Props }
export { AddDiscountedExtrasCountdownCard }
export default AddDiscountedExtrasCountdownCard
