// @noflow
import { EmailValidator } from 'commons-validator-js'
import { isValidPhoneNumber as isValidNumber } from 'libphonenumber-js/mobile'

import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'

import formatter from './formatter'

const GBRegex =
  /^([A-PR-UWYZ]([0-9]{1,2}|([A-HK-Y][0-9]|[A-HK-Y][0-9]([0-9]|[ABEHMNPRV-Y]))|[0-9][A-HJKS-UW])\s?[0-9][ABD-HJLNP-UW-Z]{2}|(GIR 0AA)|(SAN TA1)|(BFPO (C\/O )?[0-9]{1,4})|((ASCN|BBND|[BFS]IQQ|PCRN|STHL|TDCU|TKCA) 1ZZ))$$/
const GBUndeliverableRegex =
  /^(BT\d{1,2}|JE\d{1,2}|ZE\d{1,2}|EI\d{1,2}|GY[1-9]|GY10|HS[1-9]|KW1[5-7]|PA4[2-9]|PA34|PA6[0-9]|PA7[0-8]|PA80|TR2[1-5])\s*([a-zA-Z0-9]{3})$/

const NIRegex = /^BT[0-9]{1,2}[\s]*([\d][A-Za-z]{2})$/

const IERegex = /(?:^[AC-FHKNPRTV-Y][0-9]{2}|D6W)[ -]?[0-9AC-FHKNPRTV-Y]{4}$/

const NLRegex = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[A-Za-z]{2}$/

const BRUSSELS = '10[02-9]0|108[1-3]|11[2-9]0|12[0-1]0'
const VLAAMS_BRABANT_1000 =
  '150[0-2]|154[017]|15[67]0|160[0-2]|16[2-4]0|165[0-4]|167[0134]|170[0-3]|173[01]|174[0-25]|175[05]|176[01]|17[79]0|178[05]|18[0248]0|18[36][01]|185[0-3]|19[157]0|193[023]|198[0-2]'
const VLAAMS_BRABANT_3000 =
  '300[01]|301[028]|30[2489]0|305[0-4]|306[0-1]|307[018]|311[018]|312[08]|31[345]0|319[01]|32[017][0-2]|322[01]|329[034]|33[05-7]0|332[01]|338[014]|339[01]|340[014]|3440|345[04]|346[0,1]|347[0-3]'
const ANTWERPEN =
  '20[02-7]0|2018|21[014-8]0|22[0569]0|222[0-3]|22[37][05]|224[023]|228[08]|23[013-79]0|232[01-38]|238[0127]|24[04-8]0|24[39][01]|25[025-9]0|253[01]|254[07]|26[013-6]0|262[07]|28[2357-9]0|28[06][01]|281[12]|284[05]|29[0-9]0'
const LIMBURG =
  '35[235679]0|350[01]|351[0-2]|354[05]|358[0-3]|36[0457-9]0|36[23][01]|366[058]|37[07]0|3717|372[0-4]|373[02]|374[026]|379[0-38]|380[036]|383[0-2]|38[457]0|389[01]|39[0-35689]0|394[015]|397[01]'
const WEST_VLAANDEREN =
  '80[02]0|8200|821[01]|830[01]|83[148]0|837[07]|84[05-9]0|842[01]|843[0-4]|85[013][01]|85[246]0|855[0-4]|857[023]|858[0-37]|86[0-35-8]0|864[07]|869[01]|87[0-46-8]0|875[05]|879[0-3]|88[0-46-9]0|885[01]|890[02468]|89[2348]0|895[0-46-8]|897[028]'
const OOST_VLAANDEREN =
  '90[06-9]0|90[3-5][0-2]|91[02-79]0|911[12]|(918|925)[05]|92[02-46-9]0|930[08]|93[124]0|940[0-46]|9420|(945|952|983|988|992|997)[01]|947[023]|950[06]|(993|955|957|999)[012]|96[029]0|963[06]|966[017]|(968|996)[018]|97[059]0|977[012]|98[0-24-79]0|99[0145]0|998[0-28]'
const WALLONIA =
  '1035|(130|146|41[478]|47[013679]|48[26]|508|510|56[25]|60[06]|611|621|644|694|704|71[489]|730|795)[01]|(133|40[34]|41[02]|443|467|48[05]|503|603|614|67[89]|697|701|732|752|781|791)[0-2]|(405|416|463|618|681|695|706|764|79[47])[0-3]|(46[58]|500|53[56]|55[0246]|604|622|646|667|703|733|76[28]|778|780)[0-4]|104[3457]|13[12][05]|134[0-28]|13[56][07]|13[789]0|140[0-24]|1410|142[018]|14[39][05]|14[48]0|145[07]|147[0-46]|40[02]0|41[39]0|421[0789]|425[02347]|426[013]|428[07]|4300|4317|(434|738)[027]|435[017]|436[07]|44[02678]0|445[0-38]|45[02469]0|45[357][07]|460[012678]|46[19]0|462[0134]|472[018]|4750|478[02-4]|483[0147]|484[015]|487[07]|48[89]0|49[0125679]0|498[037]|502[0124]|50[67]0|51[4579]0|53[0148]0|533[02346]|537[02467]|553[07]|555[05]|557[0-6]|55[89]0|56[03678]0|564[0146]|60[12]0|61[25]0|62[0458]0|623[08]|6470|6500|6511|653[0-46]|654[023]|656[07]|659[0-46]|66[04]0|663[07]|6642|666[0-36]|668[016-8]|669[028]|670[046]|6717|672[0134]|67[35]0|674[01237]|676[01279]|68[04679]0|682[0134]|683[0-468]|685[0-36]|688[07]|69[06]0|692[012479]|698[023467]|699[07]|70[057-9]0|702[0124]|71[0-2,67]0|713[013467]|73[4579]0|750[0-46]|753[0-468]|754[0238]|760[0-48]|761[018]|77[0356]0|771[12]|774[023]|782[23]|78[35789]0|786[0-46]|790[01346]'

const BERegex = new RegExp(
  `^(${BRUSSELS}|${VLAAMS_BRABANT_1000}|${VLAAMS_BRABANT_3000}|${ANTWERPEN}|${LIMBURG}|${WEST_VLAANDEREN}|${OOST_VLAANDEREN}|${WALLONIA})$`
)

const PLRegex = /^\d{2}-\d{3}$/

const DERegex = /^\d{5}$/

export default class Validator {
  static isValidPhoneNumber(phoneNumber: string): boolean {
    const supportedPhoneNumberRegions: Array<CountryCode> = [
      'GB',
      'IE',
      'NL',
      'BE',
      'PL',
      'DE'
    ]

    if (phoneNumber) {
      const trimmedNumber = phoneNumber.trim()
      const validPhoneNumber = supportedPhoneNumberRegions.some(
        (code: CountryCode): boolean => isValidNumber(trimmedNumber, code)
      )
      if (validPhoneNumber) {
        return true
      }
      return false
    }
    return false
  }

  static isValidEmail(email: string): boolean {
    if (email) {
      const validator = new EmailValidator()
      return validator.isValid(email)
    }
    return false
  }

  static isValidName(name: string): boolean {
    if (name) {
      if (name.match(/\w+ \w+/)) {
        return true
      } else {
        return false
      }
    }
    return false
  }

  static isValidPostcode(postcode: string): boolean {
    if (postcode) {
      const formattedPostcode = formatter
        .removeWhiteSpace(postcode)
        .toUpperCase()

      if (
        formattedPostcode.match(GBRegex) ||
        formattedPostcode.match(NIRegex) ||
        formattedPostcode.match(IERegex) ||
        formattedPostcode.match(NLRegex) ||
        formattedPostcode.match(BERegex) ||
        formattedPostcode.match(PLRegex) ||
        formattedPostcode.match(DERegex)
      ) {
        return true
      } else {
        return false
      }
    }
    return false
  }

  static isValidPostcodeForShippingCountry(
    postcode: string,
    shippingCountryCode: CountryCode
  ): boolean {
    if (postcode) {
      const formattedPostcode = formatter
        .removeWhiteSpace(postcode)
        .toUpperCase()

      if (
        (shippingCountryCode === 'GB' && formattedPostcode.match(GBRegex)) ||
        (shippingCountryCode === 'NI' && formattedPostcode.match(NIRegex)) ||
        (shippingCountryCode === 'IE' && formattedPostcode.match(IERegex)) ||
        (shippingCountryCode === 'NL' && formattedPostcode.match(NLRegex)) ||
        (shippingCountryCode === 'BE' && formattedPostcode.match(BERegex)) ||
        (shippingCountryCode === 'PL' && formattedPostcode.match(PLRegex)) ||
        (shippingCountryCode === 'DE' && formattedPostcode.match(DERegex))
      ) {
        return true
      } else {
        return false
      }
    }
    return false
  }

  static isBlank(text: string): boolean {
    return !/\.*[a-zA-z0-9]+.*/.test(text)
  }

  static isNotBlank(text: string): boolean {
    return /\.*[a-zA-z0-9]+.*/.test(text)
  }

  /**
   * Two day Postcode Validation
   * ===========================
   *
   * Note, UK Postcodes are formed from two parts:
   *   1. Outward Code (We call these prefixes below)
   *   2. Inward Code (Inward codes are always 3 characters long)

   * For example, for the the postcode W2 4BA:
   *   1. The outward code is W2
   *   2. The inward code is 4BA

   * When comparing PH15 2AA and PH1 5DQ, which both start with P15 (A two day
   * delivery postcode), we need to ensure that the inward code is 3 characters
   * long after taking away the PH15 outward code to ensure it is actually in the
   * PH15 postcode area, rather than being in the PH1 area with 5 as the first
   * character of the inward code. As a result below, we ensure that there is
   * 3 characters after the outward code when checking if a postcode is in a two
   * day delivery area.
   */

  static isUndeliverablePostcode(
    postcode: string,
    shippingCountryCode: CountryCode
  ): boolean {
    if (postcode && shippingCountryCode === 'GB') {
      const formattedPostcode = formatter
        .removeWhiteSpace(postcode)
        .toUpperCase()
      return !!formattedPostcode.match(GBUndeliverableRegex)
    }
    return false
  }

  static isTwoDayDeliveryPostcode(postcode: string): boolean {
    if (postcode) {
      const formattedPostcode = formatter
        .removeWhiteSpace(postcode)
        .toUpperCase()

      const twoDayPostcodePrefix = this.twoDayPostcodePrefixes().some(
        (prefix: string): boolean => {
          return formattedPostcode.startsWith(prefix)
        }
      )

      const twoDayPostcodeOutwardCode = this.twoDayPostcodeOutwardCodes().some(
        (prefix: string): boolean => {
          return (
            formattedPostcode.startsWith(prefix) &&
            formattedPostcode.length - prefix.length === 3
          )
        }
      )

      return twoDayPostcodePrefix || twoDayPostcodeOutwardCode
    }
    return false
  }

  static twoDayPostcodePrefixes(): Array<string> {
    return ['IM', 'IV']
  }

  static twoDayPostcodeOutwardCodes(): Array<string> {
    return [
      'AB30',
      'AB31',
      'AB33',
      'AB34',
      'AB35',
      'AB36',
      'AB37',
      'AB38',
      'AB41',
      'AB42',
      'AB43',
      'AB44',
      'AB45',
      'AB51',
      'AB52',
      'AB53',
      'AB54',
      'AB55',
      'AB56',
      'FK18',
      'FK19',
      'FK20',
      'FK21',
      'KA27',
      'KA28',
      'KW1',
      'KW2',
      'KW3',
      'KW4',
      'KW5',
      'KW6',
      'KW7',
      'KW8',
      'KW9',
      'KW10',
      'KW11',
      'KW12',
      'KW13',
      'KW14',
      'PA20',
      'PA21',
      'PA22',
      'PA23',
      'PA24',
      'PA25',
      'PA26',
      'PA27',
      'PA28',
      'PA29',
      'PA30',
      'PA31',
      'PA32',
      'PA33',
      'PA35',
      'PA36',
      'PA37',
      'PA38',
      'PA41',
      'PH15',
      'PH16',
      'PH17',
      'PH18',
      'PH19',
      'PH20',
      'PH21',
      'PH22',
      'PH23',
      'PH24',
      'PH25',
      'PH26',
      'PH27',
      'PH28',
      'PH29',
      'PH30',
      'PH31',
      'PH32',
      'PH33',
      'PH34',
      'PH35',
      'PH36',
      'PH37',
      'PH38',
      'PH39',
      'PH40',
      'PH41',
      'PH42',
      'PH43',
      'PH44',
      'PH49',
      'PH50',
      'PO30',
      'PO31',
      'PO32',
      'PO33',
      'PO34',
      'PO35',
      'PO36',
      'PO37',
      'PO38',
      'PO39',
      'PO40',
      'PO41'
    ]
  }
}
