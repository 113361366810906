import { gql } from '@apollo/client'

const ORDER_PRODUCTS_UPDATE = gql`
  mutation OrderProductsUpdate(
    $userId: ID!
    $boxId: ID!
    $orderProducts: [ProductInput!]!
  ) {
    orderProductsUpdate(
      userId: $userId
      boxId: $boxId
      orderProducts: $orderProducts
    ) {
      id
      box(boxId: $boxId) {
        id
        physicalOrderProducts {
          id
          nonPaymentReason
          quantity
          productVariant {
            id
            name
            productCollection {
              id
              name
              thumbnail {
                src
              }
            }
          }
        }
      }
    }
  }
`
export { ORDER_PRODUCTS_UPDATE }
