// @noflow
import { useQuery } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import i18n from 'i18next'
import React, { useCallback, useState } from 'react'

import AmbassadorIcon from 'assets/images/icons/navigation/icon-ambassador-account.svg'
import ArrowLeftIcon from 'assets/images/icons/navigation/icon-arrow-left.svg'
import UserIcon from 'assets/images/icons/navigation/icon-customer-account.svg'

import NavLink from '@/components/elements/atoms/NavLink/NavLink'
import NavLogo from '@/components/elements/atoms/NavLogo/NavLogo'
import Text from '@/components/elements/atoms/Text/Text'
import BannerManagerWithQuery from '@/components/elements/molecules/BannerManager/BannerManager'
import DashboardMobileNavMenu from '@/components/elements/organisms/DashboardMobileNavMenu/DashboardMobileNavMenu'

import STYLES from './DashboardNavigation.module.sass'

import { TOP_NAV_QUERY } from './queries/topNavQuery'

import type { TopNavQuery } from './queries/__generated__/TopNavQuery'

type Props = {
  showSecondaryNav?: boolean
}

const DashboardNavigation = ({
  showSecondaryNav = true
}: Props): JSX.Element | null => {
  const [menuOpen, setMenuOpen] = useState(false)

  const menuItemClicked = useCallback(() => {
    setMenuOpen(!menuOpen)
  }, [menuOpen, setMenuOpen])

  const { loading, data, error } = useQuery<TopNavQuery>(TOP_NAV_QUERY)

  if (loading || !data) return null
  if (error) Sentry.captureException(error)

  const {
    user: { firstName, affiliateType, referralsSinceLastLoginCount, dogs }
  } = data

  const isAmbassador = affiliateType === 'ambassador'
  const isInfluencer = affiliateType === 'influencer'
  const numberOfDogs = dogs?.length
  const referralsCount = referralsSinceLastLoginCount || 0

  return (
    <React.Fragment>
      <header className={STYLES.container}>
        <div className={`${STYLES.navWrapper} ${STYLES.navWrapperPrimary}`}>
          <nav>
            <div className={`${STYLES.column} ${STYLES.left}`}>
              <a href="/">
                <NavLogo />
              </a>
              <NavLink
                text={{
                  text: 'our_story',
                  namespace: 'navigation'
                }}
                href="/our_story"
              />
              <NavLink
                text={{
                  text: 'our_products',
                  namespace: 'navigation'
                }}
                href="/our_products"
              />
              <NavLink
                text={{
                  text: 'help',
                  namespace: 'navigation'
                }}
                href="/contact"
              />
            </div>
            <div className={`${STYLES.column} ${STYLES.right}`}>
              {isAmbassador && (
                <div className={STYLES.navProfile}>
                  <img alt="" src={AmbassadorIcon} />
                  <NavLink
                    text={{
                      text: 'ambassador',
                      namespace: 'navigation'
                    }}
                    href="/users/sign_in_as_ambassador"
                  />
                </div>
              )}
              <div className={STYLES.navProfile}>
                <img alt="" src={UserIcon} />
                <NavLink
                  text={{
                    text: firstName,
                    translate: false
                  }}
                  href="/dashboard"
                />
              </div>
              <NavLink
                text={{
                  text: 'log_out',
                  namespace: 'navigation'
                }}
                href="/users/sign_out"
              />
            </div>
            <div className={`${STYLES.column} ${STYLES.mobile}`}>
              <a href="/">
                <NavLogo />
              </a>
              <button
                className={`${STYLES.navProfile} ${STYLES.navTrigger}`}
                type="button"
                onClick={menuItemClicked}
              >
                <img alt="" src={UserIcon} />
                <Text text={firstName} translate={false} element="span" />
                <img alt="" src={ArrowLeftIcon} />
              </button>
            </div>
          </nav>
        </div>

        {showSecondaryNav && (
          <div className={`${STYLES.navWrapper} ${STYLES.navWrapperSecondary}`}>
            <nav>
              <div className={`${STYLES.column} ${STYLES.left}`}>
                <NavLink
                  text={{
                    text: 'dashboard',
                    namespace: 'navigation'
                  }}
                  href="/dashboard"
                />
                <NavLink
                  text={{
                    text: 'my_plan',
                    namespace: 'navigation'
                  }}
                  href="/dashboard/subscription"
                />
                <NavLink
                  text={{
                    text: 'my_details',
                    namespace: 'navigation'
                  }}
                  href="/dashboard/my-details"
                />
                <NavLink
                  text={{
                    text:
                      numberOfDogs && numberOfDogs > 1 ? `my_dogs` : `my_dog`,
                    namespace: 'navigation'
                  }}
                  href="/dashboard/my-dog"
                />
                <NavLink
                  text={{
                    text: 'extras',
                    namespace: 'navigation'
                  }}
                  href="/dashboard/extras"
                />
                {!isAmbassador && !isInfluencer && (
                  <div className={STYLES.navItemWithNotification}>
                    <NavLink
                      text={{
                        text: 'free_food_caps',
                        namespace: 'navigation'
                      }}
                      href="/dashboard/refer-a-friend"
                    />
                    {referralsCount > 0 ? (
                      <div className={STYLES.numberNotification}>
                        <Text
                          text={`${referralsSinceLastLoginCount}`}
                          translate={false}
                          variant="textRegular16"
                          colour="brandWhite"
                          element="span"
                          shouldScale={false}
                        />
                      </div>
                    ) : (
                      <div className={STYLES.genericNotification} />
                    )}
                  </div>
                )}
              </div>
            </nav>
          </div>
        )}
      </header>
      <DashboardMobileNavMenu
        open={menuOpen}
        backgroundClicked={menuItemClicked}
        isAmbassador={isAmbassador}
        isInfluencer={isInfluencer}
        numberOfDogs={numberOfDogs || 1}
        referralsSinceLastLoginCount={referralsCount}
        showSecondaryNav={showSecondaryNav}
      />
      <BannerManagerWithQuery i18n={i18n} />
    </React.Fragment>
  )
}

export { TOP_NAV_QUERY, DashboardNavigation }
export default DashboardNavigation
