// @noflow
import React from 'react'

import {
  ICON_COLOURS,
  IconColours
} from '@/components/elements/atoms/Icon/Icon'

type Props = {
  size: number
  accentColour: IconColours
  width?: number | string
}

const Play = ({ size, accentColour, width }: Props): JSX.Element => {
  return (
    <svg
      height={size}
      width={width || '100%'}
      viewBox="0 0 13 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m4.5 11.976-.5-6.5 5 3-4.5 3.5Z"
        stroke="#432918"
        strokeOpacity=".1"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M10.508 7.884c1.5.58-1.5.98-7.5 6.58L3.5 8.557s-.5-5-.5-5.5 3 1.92 3 1.92 3.007 2.328 4.508 2.908Z"
        fill={ICON_COLOURS[accentColour]}
      />
      <path
        d="M3.008 14.465c6-5.6 9-6 7.5-6.58C9.008 7.303 6 4.974 6 4.974s-3-2.42-3-1.92.5 5.5.5 5.5l-.5 6"
        stroke="#432918"
        strokeOpacity=".1"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="m3.5 10.988-.5-6.5 5 3-4.5 3.5Z"
        stroke={ICON_COLOURS[accentColour]}
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M9.508 6.896c1.5.58-1.5.98-7.5 6.58L2.5 7.569s-.5-5-.5-5.5 3 1.92 3 1.92 3.007 2.328 4.508 2.908Z"
        fill={ICON_COLOURS[accentColour]}
      />
      <path
        d="M2.008 13.477c6-5.6 9-6 7.5-6.58C8.008 6.316 5 3.986 5 3.986S2 1.569 2 2.069s.5 5.5.5 5.5l-.5 6"
        stroke={ICON_COLOURS[accentColour]}
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default Play
