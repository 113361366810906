import { gql } from '@apollo/client'

const PAY_OVERDUE_INVOICES_AND_UNSUSPEND = gql`
  mutation payOverdueInvoicesAndUnsuspend(
    $paymentId: String
    $paymentResourceId: String
    $paymentResourceType: PaymentResourceType
    $provider: String!
  ) {
    payOverdueInvoicesAndUnsuspend(
      paymentId: $paymentId
      paymentResourceId: $paymentResourceId
      paymentResourceType: $paymentResourceType
      provider: $provider
    ) {
      user {
        id
      }
    }
  }
`

export { PAY_OVERDUE_INVOICES_AND_UNSUSPEND }
