import { gql } from '@apollo/client'

import { DISCOUNT_CODE_FRAGMENT } from '../../../../../queries/fragments/DiscountCodeFragment'

const CURRENT_DISCOUNT_CODE = gql`
  ${DISCOUNT_CODE_FRAGMENT}
  query CheckoutCurrentDiscountCode($discountCodeId: ID!) {
    discountCode(discountCodeId: $discountCodeId) {
      ...discountCodeFragment
    }
  }
`
export { CURRENT_DISCOUNT_CODE }
