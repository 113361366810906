// @noflow
import { isSameDay } from 'date-fns'
import React, { useCallback, useMemo } from 'react'

import segmentTrack from '@/components/analytics/Analytics'
import type { Props as TextProps } from '@/components/elements/atoms/Text/Text'
import {
  CourierFragment_calendarDates as CalendarDate,
  CourierFragment as DeliveryArea
} from '@/components/elements/molecules/CourierSelector/fragments/__generated__/CourierFragment'
import RadioGroup, {
  RadioOption
} from '@/components/elements/molecules/RadioGroup/RadioGroup'

import STYLES from './CourierSelector.module.sass'

type Props = {
  deliveryAreaOptions: DeliveryArea[]
  selectedCarrier: DeliveryArea
  selectedDate: Date
  setSelectedCarrier: (carrier: DeliveryArea) => void
  setSelectedDate: (date: Date) => void
  setCalendarDates: (dates: CalendarDate[]) => void
  subText?: TextProps
}

const CourierSelector = ({
  deliveryAreaOptions,
  selectedCarrier,
  selectedDate,
  setSelectedCarrier,
  setSelectedDate,
  setCalendarDates,
  subText
}: Props): JSX.Element | null => {
  const carriers = useMemo(() => {
    if (deliveryAreaOptions) {
      return deliveryAreaOptions
        .map((dao) => ({
          name: dao.preferredCarrierService.carrier.name,
          id: dao.id
        }))
        .slice(0, 2)
    }
  }, [deliveryAreaOptions])

  const availableCarriers = useMemo(() => {
    return carriers?.map(
      (carrier): RadioOption => ({
        id: carrier.id,
        text: {
          text: carrier.name ?? '',
          translate: false
        },
        subText: subText ?? { text: '' },
        value: carrier.name ?? '',
        defaultChecked: carrier.id === selectedCarrier?.id
      })
    )
  }, [carriers, selectedCarrier?.id, subText])

  const getDeliverableDate = useCallback(
    (deliveryArea: DeliveryArea): Date => {
      let i = 0
      const selectedCalendarDate = deliveryArea.calendarDates.find(
        (date, index) => {
          const sameDay = isSameDay(new Date(date.date), selectedDate.getTime())
          if (sameDay) i = index
          return sameDay
        }
      )

      switch (true) {
        case selectedCalendarDate?.deliverable:
          return selectedDate
        case !!selectedCalendarDate?.replacementDate:
          // excuse the redundant ternary, but tsc is stupid and
          // doesn't understand that selectedCalendarDate must exist
          return selectedCalendarDate
            ? new Date(selectedCalendarDate.replacementDate)
            : selectedDate
        case !deliveryArea.regularDeliveryDaysOfWeek.includes(
          selectedDate.getDay()
        ): {
          const nextDeliverableDate = deliveryArea.calendarDates
            .slice(i)
            .find((date) => date.deliverable)
          return nextDeliverableDate
            ? new Date(nextDeliverableDate.date)
            : selectedDate
        }
        default:
          return selectedDate
      }
    },
    [selectedDate]
  )

  const updateDeliveryArea = useCallback(
    (id: string) => {
      const deliveryArea = deliveryAreaOptions?.find((dao) => dao.id === id)

      if (deliveryArea) {
        const date = getDeliverableDate(deliveryArea)
        setSelectedCarrier(deliveryArea)
        setCalendarDates(deliveryArea.calendarDates)
        if (!isSameDay(date, selectedDate)) setSelectedDate(date)
      }
    },
    [
      deliveryAreaOptions,
      getDeliverableDate,
      selectedDate,
      setCalendarDates,
      setSelectedCarrier,
      setSelectedDate
    ]
  )

  const handleCarrierSelection = useCallback(
    (index: number): void => {
      if (carriers) {
        updateDeliveryArea(carriers[index].id)
        segmentTrack('Delivery Carrier selection changed', {
          carrier: carriers[index].name
        })
      }
    },
    [carriers, updateDeliveryArea]
  )

  if (!availableCarriers || availableCarriers.length < 2) return null

  return (
    <div className={STYLES.radioWrapper}>
      <div className={STYLES.checkboxWrapper}>
        <RadioGroup
          onChange={handleCarrierSelection}
          radioOptions={availableCarriers}
          colorVariant={'brandYellow200'}
          variant={'horizontal'}
          identifier="courier_selector"
        />
      </div>
    </div>
  )
}

export default CourierSelector
