import { gql } from '@apollo/client'

const NCO_TRACKING_QUERY = gql`
  query NcoTrackingQuery($boxId: ID!) {
    user {
      id
      dogs {
        id
        name
        gender
      }
      nonCoreOrder(orderId: $boxId) {
        id
        orderParts {
          deliveryDate
          consignment {
            id
            updatedAt
            status
            carrierName
            trackingUrl
          }
        }
      }
    }
  }
`

export { NCO_TRACKING_QUERY }
