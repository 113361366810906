// @noflow
// Mutations
import { useMutation } from '@apollo/client'
import React, { useCallback, useMemo } from 'react'
import type { ReactElement } from 'react'
import { toast } from 'react-toastify'

import type { Locale } from '@/utils/countryCodeHelper'

import NotificationContainer from '@/components/elements/molecules/NotificationContainer/NotificationContainer'
import NotificationContent from '@/components/elements/molecules/NotificationContent/NotificationContent'

import STYLES from '../../BasketPage.module.sass'

import type {
  BasketProductsUpdate,
  BasketProductsUpdateVariables
} from '../../mutations/__generated__/BasketProductsUpdate'
import type {
  BasketPageQuery_user_basket_basketProducts as BasketProduct,
  BasketPageQuery_user as User
} from '../../queries/__generated__/BasketPageQuery'
import type { Currency } from '@/shared_types/rails_models/shipping_countries'
import { ProductVariantInput, VariantDelivery } from '@/types/'

import { BASKET_PRODUCTS_UPDATE_MUTATION } from '../../mutations'
import BasketProductCard from './BasketProductCard'

type Props = {
  userId: User['id']
  basketProducts: Array<BasketProduct>
  locale: Locale
  currency: Currency
}

// Basket products list hook types
type BasketProductsListProps = Omit<Props, 'locale' | 'currency'>
type BasketProductsList = {
  basketProductsUpdateLoading: boolean
  handleRemoveBasketProduct: (
    productId: BasketProduct['productVariant']['id']
  ) => Promise<void>
}

const useBasketProductsList = ({
  userId,
  basketProducts
}: BasketProductsListProps): BasketProductsList => {
  /**
   * Create basket products input including products with quantity equals to 0
   */
  const basketProductsInput = useMemo(
    () =>
      basketProducts.map(
        (basketProduct: BasketProduct): ProductVariantInput => {
          return {
            productVariantId: basketProduct.productVariant.id,
            quantity: basketProduct.quantity || 0,
            deliveryType: VariantDelivery.on_demand
          }
        }
      ),
    [basketProducts]
  )

  // Mutations
  const [basketProductsUpdate, { loading: basketProductsUpdateLoading }] =
    useMutation<BasketProductsUpdate, BasketProductsUpdateVariables>(
      BASKET_PRODUCTS_UPDATE_MUTATION,
      {
        update(cache, { data: mutationResponse }) {
          if (mutationResponse?.basketProductsUpdate) {
            const {
              basketProductsUpdate: {
                user: { basket: updatedBasket }
              }
            } = mutationResponse

            // Update basket data in the cache
            cache.modify({
              fields: {
                user(userRef) {
                  cache.modify({
                    id: userRef.__ref,
                    fields: {
                      basket: () => updatedBasket
                    }
                  })
                }
              }
            })
          }
        }
      }
    )

  // Handlers
  const handleRemoveBasketProduct = useCallback(
    async (productVariantId: BasketProduct['productVariant']['id']) => {
      const errorNotification = () => {
        toast.error(
          <NotificationContent
            copy={{
              namespace: 'dashboard',
              text: 'extras.basket.notification.remove_product_error'
            }}
          />,
          {
            toastId: 'basket-product-remove-error-notification'
          }
        )
      }

      try {
        // Create updated products input for mutation
        const updatedBasketProductsInput = basketProductsInput.map(
          (basketProductInput: ProductVariantInput) => {
            if (basketProductInput.productVariantId === productVariantId) {
              // Set quantity of selected product equals to 0
              return {
                productVariantId,
                quantity: 0,
                deliveryType: VariantDelivery.on_demand
              }
            } else {
              // Keep other products as they are
              return basketProductInput
            }
          }
        )

        // Fire basket update mutation with updated basket products input
        const { data, errors } = await basketProductsUpdate({
          variables: {
            userId,
            basketProductVariants: updatedBasketProductsInput
          }
        })

        // Fire error notification if there are any errors or data is not available
        if (errors || !data) {
          errorNotification()
        }

        // Fire success notification if returned data is present
        if (data?.basketProductsUpdate) {
          window.analytics.track('Additional Product Churned', {
            properties: {
              purchaseType: 'ode',
              productVariantId: productVariantId
            }
          })
          const {
            basketProductsUpdate: {
              user: { basket }
            }
          } = data

          /**
           * Remove product success notification.
           * In case if there are no products in the basket we navigate customers to the
           * empty state page and don't show success notification.
           */
          if (basket && basket.basketProducts.length > 0) {
            toast.success(
              <NotificationContent
                copy={{
                  namespace: 'dashboard',
                  text: 'extras.basket.notification.remove_product_success'
                }}
              />,
              {
                toastId: 'basket-product-remove-success-notification'
              }
            )
          }
        }
      } catch (error) {
        errorNotification()
      }
    },
    [basketProductsInput, basketProductsUpdate, userId]
  )

  return {
    handleRemoveBasketProduct,
    basketProductsUpdateLoading
  }
}

const BasketProductsList = ({
  userId,
  basketProducts,
  locale,
  currency
}: Props): ReactElement => {
  const { handleRemoveBasketProduct, basketProductsUpdateLoading } =
    useBasketProductsList({
      userId,
      basketProducts
    })

  return (
    <div className={STYLES.productCards}>
      {basketProducts.map((basketProduct: BasketProduct) => (
        <BasketProductCard
          key={basketProduct.id}
          basketProduct={basketProduct}
          locale={locale}
          currency={currency}
          handleRemoveBasketProduct={handleRemoveBasketProduct}
          basketProductsUpdateLoading={basketProductsUpdateLoading}
        />
      ))}
      <NotificationContainer autoClose={5000} />
    </div>
  )
}

export default BasketProductsList
