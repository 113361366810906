import { gql } from '@apollo/client'

const ADDRESS_FRAGMENT = gql`
  fragment AddressFragment on Address {
    id
    address1
    address2
    city
    postcode
    shippingCountry {
      id
      code
    }
    recipientName
    deliveryNotes
    deliveryCarrier
  }
`

const USER_ADDRESS_FRAGMENT = gql`
  ${ADDRESS_FRAGMENT}
  fragment UserAddressFragment on User {
    id
    address {
      ...AddressFragment
    }
  }
`

export { ADDRESS_FRAGMENT, USER_ADDRESS_FRAGMENT }
