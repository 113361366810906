// @noflow
import { useLanguage } from '@/context/injectedValues/injectedValues'
import { useLocalisation } from '@/context/localisation'
import { formatDuration, intervalToDuration, isBefore } from 'date-fns'
import { isNil } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { toLocalisedSentence } from '@/utils/StringHelper'

import { TextProps } from '../Text'
import Text, { AllowedColours } from '../Text/Text'

enum DeliveryStatus {
  unknown = 'unknown',
  unallocated = 'unallocated',
  allocated = 'allocated',
  printed = 'printed',
  cancelled = 'cancelled',
  manifested = 'manifested',
  in = 'in',
  out = 'out',
  attempted_delivery = 'attempted_delivery',
  delivered = 'delivered',
  exception = 'exception'
}

type Props = {
  amendable?: boolean
  status?: string | null
  cutOffDate?: Date | null
  deliveryDate?: Date | null
  type: 'box' | 'nonCoreOrder' | 'oneOffBox'
  descriptor: string
  colour?: AllowedColours
}

const BoxDeliveryStatusText = ({
  amendable,
  status,
  cutOffDate,
  deliveryDate,
  type,
  descriptor,
  colour = 'brandBlue400'
}: Props): JSX.Element | null => {
  const { dateLocale } = useLocalisation()
  const { userLanguage } = useLanguage()
  const [timeUntilCutOff, setTimeUntilCutOff] = useState<string | null>(null)
  const [timeUntilDelivery, setTimeUntilDelivery] = useState<string | null>(
    null
  )
  const copyContext = 'box_delivery_status_text'

  const cutOffDateAndTime = useMemo(() => {
    if (isNil(cutOffDate)) {
      return undefined
    }

    const date = new Date(cutOffDate)
    date.setHours(23)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)

    return date
  }, [cutOffDate])

  const shouldSeeEditableLaterText = useMemo(() => {
    return !amendable && descriptor === 'future' && type === 'box'
  }, [amendable, descriptor, type])

  const shouldSeeEditableCutOffText = useMemo(() => {
    return (
      amendable && cutOffDateAndTime && isBefore(new Date(), cutOffDateAndTime)
    )
  }, [amendable, cutOffDateAndTime])

  const shouldSeeTimeUntilDeliveryText = useMemo(() => {
    return !amendable && deliveryDate && isBefore(new Date(), deliveryDate)
  }, [amendable, deliveryDate])

  const textProps = {
    variant: 'textRegular16',
    element: 'div',
    namespace: 'atoms',
    translate: true
  } as TextProps

  const calculateTimeUntilDate = useCallback(
    (until: Date, setter: (timeUntil: string) => void) => {
      const now = new Date()

      const duration = intervalToDuration({
        start: now,
        end: until
      })

      const lessThanOneHourRemaining =
        duration.months === 0 &&
        duration.days === 0 &&
        duration.hours === 0 &&
        duration.minutes &&
        duration.minutes > 0

      // Format the duration based on its length
      let formattedDuration
      if (lessThanOneHourRemaining) {
        formattedDuration = formattedDuration = formatDuration(
          { hours: 1 },
          {
            format: ['hours'],
            delimiter: '||',
            locale: dateLocale
          }
        )
      } else if (duration.months && duration.months > 0) {
        formattedDuration = formatDuration(duration, {
          format: ['months', 'days'],
          delimiter: '||',
          locale: dateLocale
        })
      } else {
        formattedDuration = formatDuration(duration, {
          format: ['days', 'hours'],
          delimiter: '||',
          locale: dateLocale
        })
      }

      // Convert the formatted duration to a localised sentence
      const localisedTime = toLocalisedSentence({
        arr: formattedDuration.split('||'),
        lng: userLanguage
      })

      // Update the state
      setter(localisedTime)
    },
    [dateLocale, userLanguage]
  )

  // Handle the cutOffDate and deliveryDate change
  useEffect(() => {
    if (shouldSeeEditableCutOffText && cutOffDateAndTime) {
      calculateTimeUntilDate(cutOffDateAndTime, setTimeUntilCutOff)
      setTimeUntilDelivery(null)
    } else if (shouldSeeTimeUntilDeliveryText && deliveryDate) {
      calculateTimeUntilDate(deliveryDate, setTimeUntilDelivery)
      setTimeUntilCutOff(null)
    } else {
      setTimeUntilCutOff(null)
      setTimeUntilDelivery(null)
    }
  }, [
    calculateTimeUntilDate,
    shouldSeeTimeUntilDeliveryText,
    shouldSeeEditableCutOffText,
    cutOffDateAndTime,
    deliveryDate,
    amendable
  ])

  if (shouldSeeEditableLaterText) {
    return <Text colour={colour} {...textProps} text="not_yet_editable" />
  } else if (shouldSeeEditableCutOffText) {
    return (
      <Text
        colour={colour}
        {...textProps}
        text={`${copyContext}.timeUntilCutOff`}
        variables={{ timeUntilCutOff }}
      />
    )
  } else if (shouldSeeTimeUntilDeliveryText) {
    return (
      <Text
        colour={colour}
        {...textProps}
        text={`${copyContext}.timeUntilDelivery`}
        variables={{ timeUntilDelivery }}
      />
    )
  }

  switch (status) {
    case DeliveryStatus.unallocated:
    case DeliveryStatus.allocated:
    case DeliveryStatus.in:
    case DeliveryStatus.manifested:
    case DeliveryStatus.printed: {
      return (
        <Text
          colour={colour}
          {...textProps}
          text={`${copyContext}.beingPrepared`}
        />
      )
    }
    case DeliveryStatus.out:
    case DeliveryStatus.delivered:
    case DeliveryStatus.cancelled: {
      return (
        <Text
          colour={colour}
          {...textProps}
          text={`${copyContext}.${status}`}
        />
      )
    }
    case DeliveryStatus.unknown:
    case DeliveryStatus.attempted_delivery:
    case DeliveryStatus.exception: {
      return (
        <a href="/contact">
          <Text
            colour={colour}
            {...textProps}
            text={`${copyContext}.help`}
            underline
          />
        </a>
      )
    }
    default: {
      return (
        <a href="/contact">
          <Text
            colour={colour}
            {...textProps}
            text={`${copyContext}.help`}
            underline
          />
        </a>
      )
    }
  }
}

export { BoxDeliveryStatusText }
