import { gql } from '@apollo/client'

const CREATE_ORDER_MUTATION = gql`
  mutation nonCoreOrderCreate(
    $userId: ID!
    $deliveryAddress: AddressInput!
    $deliveryDate: ISO8601Date!
  ) {
    nonCoreOrderCreate(
      userId: $userId
      deliveryAddress: $deliveryAddress
      deliveryDate: $deliveryDate
    ) {
      order {
        id
      }
    }
  }
`
export { CREATE_ORDER_MUTATION }
