import { useReactiveVar } from '@apollo/client'
import React, { useCallback } from 'react'

import { Button } from '@/components/elements/atoms/Button/Button'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './AccountManagement.module.sass'

import { userLoginUrl } from '../../../screens/PaymentScreen/PaymentScreen'

const AccountManagement = ({
  namespace
}: {
  namespace: string
}): JSX.Element | null => {
  const loginUrl = useReactiveVar(userLoginUrl).signUpUrl

  const copyContext = 'complete_screen.account_management'

  const handleButtonclick = useCallback(() => {
    window.location.href = loginUrl
  }, [loginUrl])

  return (
    <div className={STYLES.accountContainer}>
      <Text
        namespace={namespace}
        text={`${copyContext}.title`}
        variant={'display36'}
        align={'center'}
        bold
      />
      <div className={STYLES.descriptionText}>
        <Text
          namespace={namespace}
          text={`${copyContext}.subtitle`}
          variant={'textRegular16'}
          colour={'brandBlue400'}
          align={'center'}
        />
      </div>
      <Button
        typography={{
          variant: 'textMono18',
          text: `${copyContext}.account_management_cta`,
          namespace
        }}
        disableAnalytics
        variant={'primary'}
        onClick={handleButtonclick}
        fullWidth
        size="large"
      />
    </div>
  )
}

export default AccountManagement
