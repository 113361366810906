// @noflow
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Locale } from '@/utils/countryCodeHelper'
import { Currency, formatCurrencyWithDecimal } from '@/utils/currency'

import segmentTrack from '@/components/analytics/Analytics'
import Card from '@/components/elements/atoms/Card/Card'
import Image from '@/components/elements/atoms/Image/Image'
import Text from '@/components/elements/atoms/Text/Text'
import { ReferAFriendPageQuery } from '@/components/pages/ReferAFriendPage/queries/__generated__/ReferAFriendPageQuery'

import STYLES from '../../CardsCarousel.module.sass'

import { referralSavingsModalState } from '../../../ReferralSavingsModal/ReferralSavingsModal'

type Props = {
  namespace: string
  referralLink: ReferAFriendPageQuery['user']['subscription']['referralLink']
  locale: Locale
  currency: Currency
}

const ReferralSavingsCard = ({
  namespace,
  referralLink,
  locale,
  currency
}: Props): JSX.Element => {
  const { referralDiscountsTotalValue } = referralLink

  const copyContext = 'refer_a_friend.give_and_get_tab.cards.referral_savings'

  const { t } = useTranslation(namespace)

  const handleReferralSavingsOnClick = React.useCallback(() => {
    referralSavingsModalState(true)
    segmentTrack('Referral Savings Modal opened')
  }, [])

  return (
    <Card
      padding={16}
      onClick={handleReferralSavingsOnClick}
      fill={false}
      identifier="refer_a_friend.give_and_get.referral_savings_card"
    >
      <div className={STYLES.cardContentWrapper}>
        <Image
          slug="bb-box-yellow-bg-illustration"
          image={{ width: 228, height: 104 }}
          alt={t(`${copyContext}.alt`)}
        />
        <Text
          variant="display28"
          text={formatCurrencyWithDecimal(referralDiscountsTotalValue, {
            locale,
            currency
          })}
          translate={false}
          margin={false}
          align="center"
        />
        <Text
          variant="textRegular16"
          text={`${copyContext}.description`}
          colour="brandBlue400"
          namespace={namespace}
          margin={false}
          align="center"
        />
      </div>
    </Card>
  )
}

export default ReferralSavingsCard
