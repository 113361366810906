// @noflow
import Grid from '@material-ui/core/Grid'
import { compareDesc } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'

import useWindowSize from '@/hooks/useWindowSize'

import Card from '@/components/elements/atoms/Card/Card'
import Icon from '@/components/elements/atoms/Icon/Icon'
import Image from '@/components/elements/atoms/Image'
import Text from '@/components/elements/atoms/Text/Text'
import ShareModalButton from '@/components/elements/molecules/ShareModalButton/ShareModalButton'
import { ReferAFriendPageQuery } from '@/components/pages/ReferAFriendPage/queries/__generated__/ReferAFriendPageQuery'
import Base, { BREAKPOINTS } from '@/components/templates/Base'

import STYLES from './MyPackTab.module.sass'

type Props = {
  namespace: string
  referralLink: ReferAFriendPageQuery['user']['subscription']['referralLink']
}

const MyPackTab = ({ namespace, referralLink }: Props): JSX.Element => {
  const copyContext = 'refer_a_friend.my_pack_tab'
  const { referrals } = referralLink

  const { t } = useTranslation(namespace)

  const { windowWidth } = useWindowSize()

  const isMobile = windowWidth < BREAKPOINTS.md

  return (
    <Base background={undefined}>
      <div className={STYLES.container}>
        <Card shadow={false} padding={24}>
          <Image
            className={STYLES.titleIllustration}
            slug="my-pack-tab-illustration"
            image={{ width: 330, height: 115 }}
            alt={t(`${copyContext}.title_illustration_alt`)}
          />
          <Text
            namespace={namespace}
            text={`${copyContext}.title`}
            variant="display24"
            margin={false}
          />
          <Text
            namespace={namespace}
            text={`${copyContext}.referral_count`}
            variables={{
              count: referrals.length
            }}
            margin={false}
          />
          <div className={STYLES.packList}>
            {referrals.length > 0 ? (
              [...referrals]
                .sort((a, b) =>
                  compareDesc(
                    new Date(a.refereeSignUpDate),
                    new Date(b.refereeSignUpDate)
                  )
                )
                .map(
                  ({
                    refereeFirstName,
                    refereeDogNames,
                    refereeSignUpDate
                  }) => (
                    <Grid
                      key={refereeFirstName}
                      container
                      justifyContent="space-between"
                      className={STYLES.refereeItem}
                    >
                      <div className={STYLES.refereeDetails}>
                        <Icon asset="paw" size={19} width={19} direction="up" />
                        <Text
                          namespace={namespace}
                          text={`${copyContext}.referee_details`}
                          variables={{
                            firstName: refereeFirstName,
                            count: refereeDogNames.length,
                            dogName: refereeDogNames[0]
                          }}
                          variant="textRegular18"
                          margin={false}
                        />
                      </div>
                      <Text
                        namespace={namespace}
                        text={`${copyContext}.referee_date`}
                        variables={{
                          date: new Date(refereeSignUpDate)
                        }}
                        variant="textRegular14"
                        margin={false}
                      />
                    </Grid>
                  )
                )
            ) : (
              <Text
                namespace={namespace}
                text={`${copyContext}.subtitle`}
                colour="brandBlue400"
              />
            )}
          </div>
          <div className={STYLES.buttonWrapper}>
            <ShareModalButton
              namespace={namespace}
              buttonText={`${copyContext}.cta_button`}
              variant="secondary"
            />
          </div>
        </Card>
        {!isMobile && (
          <Card
            shadow={false}
            mask
            padding={0}
            border="none"
            fill={false}
            className={STYLES.imageCard}
          >
            <Image
              slug="three-dogs-on-bed"
              image={{ width: 566, height: 322 }}
              alt={t(`${copyContext}.hero_alt`)}
            />
          </Card>
        )}
      </div>
    </Base>
  )
}

export default MyPackTab
