// @noflow
import i18next from 'i18next'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
// components
import Joyride, {
  ACTIONS,
  BeaconRenderProps,
  CallBackProps,
  EVENTS,
  Props as JoyrideProps,
  STATUS,
  Step,
  StoreHelpers,
  TooltipRenderProps
} from 'react-joyride'

import BRAND_COLOURS from '@/constants/BrandColours'

import useTourViewedDetails from '@/hooks/useTourViewedDetails'

import Arrow from 'assets/images/icons/arrows/arrow--blue.svg'
import CloseIcon from 'assets/images/icons/crosses/close--blue.svg'

import segmentTrack from '@/components/analytics/Analytics'
import Image, {
  Props as ImageProps
} from '@/components/elements/atoms/Image/Image'
import Text, { Props as TextProps } from '@/components/elements/atoms/Text/Text'

// styles
import STYLES from './Tour.module.sass'

type CustomStep = Step & {
  beaconOffset?: { x?: number; y?: number }
  hideArrow?: boolean
}

type Props = Pick<JoyrideProps, 'scrollOffset' | 'getHelpers'> & {
  steps: CustomStep[]
  uid: string
  hideBeacon?: boolean
  callback?: (props: CallBackProps) => void
}

type TooltipProps = TooltipRenderProps & {
  step: CustomStep
}

type TourViewedProps = {
  tourFirstViewed: string | null
  lastViewedOn?: string
  completed: boolean
}

type TourTextProps = Pick<TextProps, 'text' | 'variables' | 'namespace'>

type TourContentProps = {
  title?: TourTextProps
  description?: TourTextProps
  image?: Omit<ImageProps, 'image'> & { image?: Partial<ImageProps['image']> }
}

type TourBeaconProps = BeaconRenderProps & {
  uid: string
  hidden?: boolean
  offset?: CustomStep['beaconOffset']
}

const TourTitle = ({
  text,
  variables,
  namespace
}: TourTextProps): JSX.Element => (
  <div className={STYLES.title}>
    <Text
      namespace={namespace}
      text={text}
      variables={variables}
      variant="display16"
      bold
      align="left"
      translate
      element="div"
      colour="brandWhite"
    />
  </div>
)

const TourDescription = ({
  text,
  variables,
  namespace
}: TourTextProps): JSX.Element => (
  <Text
    namespace={namespace}
    text={text}
    variables={variables}
    variant="textRegular16"
    align="left"
    translate
    element="div"
    colour="brandWhite"
  />
)

const TourContent = (props: TourContentProps): JSX.Element => {
  const { title, description, image } = props

  return (
    <div>
      {!isUndefined(image) &&
        !isUndefined(image.alt) &&
        !isUndefined(image.slug) && (
          <Image
            className={STYLES.image}
            slug={image.slug}
            image={{
              ...image.image,
              resizeMode: 'resize_to_fill',
              height: 140,
              width: 364
            }}
            alt={image.alt}
          />
        )}
      {(!isUndefined(title) || !isUndefined(description)) && (
        <div className={STYLES.content}>
          {!isUndefined(title) && (
            <TourTitle
              text={title.text}
              variables={title.variables}
              namespace={title.namespace}
            />
          )}
          {!isUndefined(description) && (
            <TourDescription
              text={description.text}
              variables={description.variables}
              namespace={description.namespace}
            />
          )}
        </div>
      )}
    </div>
  )
}

const TourTooltip = ({
  index,
  step,
  size,
  backProps,
  closeProps,
  skipProps,
  primaryProps,
  tooltipProps
}: TooltipProps): JSX.Element | null => {
  const namespace = 'molecules'
  const context = 'tour'
  const { t } = useTranslation(namespace)
  const nextText =
    (step.locale?.next as string) ??
    (index === size - 1 ? t(`${context}.done`) : t(`${context}.next`))
  const isFirstStep = index === 0
  const isLastStep = index === size - 1

  return (
    <div
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...tooltipProps}
      // NOTE: if we don't set the tooltip content to position: none when hidden then fixed position elements
      // on the dashboard will jump around on scroll
      className={`${STYLES.tooltip} ${step.hideArrow ? STYLES.hideArrow : ''}`}
    >
      <button
        type="button"
        className={`${STYLES.button} ${STYLES.closeButton}`}
        onClick={isLastStep ? closeProps.onClick : skipProps.onClick}
      >
        <img
          className={STYLES.closeIcon}
          alt={`${context}.close`}
          src={CloseIcon}
        />
      </button>
      <div className={STYLES.card}>
        {step.content}
        <div className={STYLES.buttons}>
          {index > 0 && (
            <button
              type="button"
              className={`${STYLES.button} ${STYLES.back}`}
              onClick={backProps.onClick}
            >
              <img alt={`${context}.back`} src={Arrow} />
            </button>
          )}
          <button
            type="button"
            className={`${STYLES.button} ${STYLES.next} ${
              isFirstStep ? STYLES.center : ''
            }`}
            onClick={isLastStep ? closeProps.onClick : primaryProps.onClick}
          >
            <Text
              namespace={namespace}
              text={nextText}
              variant="textRegular16"
              align="center"
              translate={false}
              colour="brandBlue500"
              margin={false}
            />
          </button>
        </div>
      </div>
    </div>
  )
}

/**
 * The clickable element that appears after a tour has been dismissed allowing the user to restart it
 */
const TourBeacon = ({ uid, hidden, offset, ...rest }: TourBeaconProps) => {
  const beaconRef = useRef<HTMLButtonElement>(null)
  const [trackingAttached, setTrackingAttached] = useState<boolean>(false)

  const trackBeaconClick = useCallback(() => {
    // eslint-disable-next-line i18next/no-literal-string
    segmentTrack(`${uid} beacon clicked`)
  }, [uid])

  useEffect(() => {
    if (!trackingAttached && !isNull(beaconRef) && !isNull(beaconRef.current)) {
      beaconRef.current.addEventListener('click', trackBeaconClick)
      setTrackingAttached(true)
    }
  }, [trackBeaconClick, trackingAttached])

  return (
    <button
      ref={beaconRef}
      type="button"
      className={`${STYLES.beacon} ${hidden ? STYLES.beaconHide : ''}`}
      onClick={trackBeaconClick}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...rest}
      title={i18next.t(`molecules:tour.open_alt`)}
      aria-label={i18next.t(`molecules:tour.open_alt`)}
      style={
        !isUndefined(offset)
          ? {
              top: offset.y,
              left: offset.x
            }
          : undefined
      }
    >
      <div className={STYLES.paw} />
      <div className={STYLES.ring} />
    </button>
  )
}

const TOUR_OPEN_CLASS = 'tour--open'

const Tour = ({
  uid,
  steps,
  callback,
  scrollOffset,
  getHelpers,
  hideBeacon
}: Props): JSX.Element | null => {
  const body = useRef(document.querySelector('body'))
  const [visible, setVisible] = useState<boolean>(false)
  const [helpers, setHelpers] = useState<StoreHelpers>()
  const [firstStepTargetReady, setFirstStepTargetReady] =
    useState<boolean>(false)
  const { completed, tourFirstViewed, isTourActive, updateTourViewed } =
    useTourViewedDetails(uid)
  const [hasViewedFullTour, setHasViewedFullTour] = useState<boolean>(completed)

  /**
   * Wait for the first steps target element to be rendered to the page.
   * If the tour initialises before this it will fail silently as it has no
   * element to position against.
   */
  useEffect(() => {
    if (isUndefined(steps) || steps.length === 0) return

    // Skip this if the target is an HTMLElement and not a selector.
    // In this case if it is not null it is renders
    if (steps[0].target instanceof HTMLElement) {
      setFirstStepTargetReady(true)
      return
    }

    // Before initialising the observer check that the selector is not already rendered
    if (!isNull(body.current) && body.current.querySelector(steps[0].target)) {
      setFirstStepTargetReady(true)
      return
    }

    if (body.current) {
      const observer = new MutationObserver((mutationList) => {
        for (const mutation of mutationList) {
          const element: HTMLElement = mutation.target as HTMLElement

          if (steps[0].target instanceof HTMLElement) {
            observer.disconnect()
            return
          }

          if (element.querySelector(steps[0].target)) {
            observer.disconnect()

            setFirstStepTargetReady(true)
          }
        }
      })

      observer.observe(document.body, { childList: true, subtree: true })
    }
  }, [body, steps])

  const manageBeacon = useCallback(
    (props: BeaconRenderProps): ReactElement<BeaconRenderProps> | null => (
      <TourBeacon
        {...props}
        uid={uid}
        hidden={hideBeacon}
        offset={
          !isUndefined(helpers)
            ? steps[helpers.info().index].beaconOffset
            : undefined
        }
      />
    ),
    [hideBeacon, uid, steps, helpers]
  )

  const manageHelpers = useCallback(
    (helpers: StoreHelpers) => {
      setHelpers(helpers)
      if (!isUndefined(getHelpers)) getHelpers(helpers)
    },
    [getHelpers]
  )

  const onShow = useCallback(() => {
    setVisible(true)
    if (!document.body.classList.contains(TOUR_OPEN_CLASS))
      document.body.classList.add(TOUR_OPEN_CLASS)

    // eslint-disable-next-line i18next/no-literal-string
    segmentTrack(`${uid} tour opened`)
  }, [uid])

  const onHide = useCallback(() => {
    setVisible(false)
    if (document.body.classList.contains(TOUR_OPEN_CLASS))
      document.body.classList.remove(TOUR_OPEN_CLASS)

    // eslint-disable-next-line i18next/no-literal-string
    segmentTrack(`${uid} tour closed`)
  }, [uid])

  const joyrideCallback = useCallback(
    (props: CallBackProps) => {
      // on tour open
      if (
        props.type === EVENTS.TOOLTIP &&
        props.status === STATUS.RUNNING &&
        props.action === ACTIONS.UPDATE
      ) {
        if (!visible && props.action === ACTIONS.UPDATE) onShow()
      }

      if (
        !completed &&
        props.action === ACTIONS.NEXT &&
        props.index === props.size - 1
      ) {
        setHasViewedFullTour(true)
      }

      // on tour close
      if (props.action === ACTIONS.CLOSE || props.action === ACTIONS.SKIP) {
        if (completed) {
          // eslint-disable-next-line i18next/no-literal-string
          segmentTrack(`${uid} tour completed`)
        } else {
          // eslint-disable-next-line i18next/no-literal-string
          segmentTrack(`${uid} tour skipped`)
        }

        onHide()

        updateTourViewed(hasViewedFullTour)
      }

      if (!isUndefined(callback)) callback(props)
    },
    [
      completed,
      callback,
      visible,
      onShow,
      onHide,
      updateTourViewed,
      hasViewedFullTour,
      uid
    ]
  )

  // We don't show tour if:
  // - the first step target is not ready
  // - the tour has been completed
  // - the tour was first viewed more than 3 days ago
  // - the tour has been viewed today
  if (!firstStepTargetReady || completed || !isTourActive) return null

  return (
    <Joyride
      // NOTE: We want the tour to display automatically if the user has not dismissed it before.
      //       After dismissing the tour show the paw beacon so the user can relaunch it if they want.
      //       This is achieved by toggling disableBeacon as required. If disabled the tour will play automatically.
      steps={steps.map((step, i) =>
        i === 0 ? { ...step, disableBeacon: isNull(tourFirstViewed) } : step
      )}
      continuous
      disableCloseOnEsc={false}
      disableScrollParentFix
      scrollOffset={scrollOffset}
      scrollDuration={1000}
      showProgress
      floaterProps={{
        styles: {
          floater: {
            // NOTE: Fixes an issue where elements positioned with fixed/sticky (for example the
            //       header / footer on the mobile dashboard) jump around on scroll as the hidden
            //       floater repositions. This takes the floater out of the page flow until needed.
            display: !visible ? 'none' : undefined
          }
        },
        disableAnimation: true,
        disableFlip: true
      }}
      styles={{
        options: {
          arrowColor: BRAND_COLOURS.brandBlue500
        }
      }}
      getHelpers={manageHelpers}
      tooltipComponent={TourTooltip}
      beaconComponent={manageBeacon}
      callback={joyrideCallback}
    />
  )
}

export { Props, CustomStep, TourViewedProps, TourContent }
export default Tour
