// @noflow
import { useLanguage } from '@/context/injectedValues/injectedValues'
import { useReactiveVar } from '@apollo/client'
import isNull from 'lodash/isNull'
import upperFirst from 'lodash/upperFirst'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { featureFlagsDataVar } from '@/services/apollo'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import { ArchiveDogProfileAction } from './components/ArchiveDogProfileAction'
import { DailyPortionSize } from './components/DailyPortionSize'
import { DetailsCarousel } from './components/DetailsCarousel'
import { DogProfilePicture } from './components/DogProfilePicture'
import { DogSelection } from './components/DogSelection'
import { HealthSection } from './components/HealthSection'
import { PersonalityDetails } from './components/PersonalityDetails'
import { ShareProfile } from './components/ShareProfile'
import { ShareRafPass } from './components/ShareRafPass'
import { Portal } from '@/components/elements/atoms/Portal'
import RAFPassModal from '@/components/pages/ReferAFriendPage/components/GiveAndGetTab/RAFPassModal/RAFPassModal'

import STYLES from './DogProfileContent.module.sass'

import type {
  DogProfileQuery,
  DogProfileQuery_user as User
} from './queries/__generated__/DogProfileQuery'

import { ProfileStatus, profileCompletionStatus } from './helpers/helpers'

type Props = {
  loading: boolean
  refetch: () => void
} & Partial<DogProfileQuery>

const DogProfileContent = ({
  loading,
  refetch,
  ...restProps
}: Props): JSX.Element => {
  const { dogId: selectedDogId } = useParams()
  const { userLanguage } = useLanguage()
  const { windowWidth } = useWindowSize()
  const [profileStatus, setProfileStatus] = useState<ProfileStatus | null>(null)
  const [hasFirstTimeProfilePic, sethasFirstTimeProfilePic] = useState(false)
  const shouldSeeStap =
    useReactiveVar(featureFlagsDataVar)?.shouldSeeStap === 'true'

  const loadedData = (() => {
    if (loading) {
      return null
    }

    const { user, archiveReasons } = restProps
    const {
      id: userId,
      dogs,
      subscription,
      shippingCountry,
      shippingCountryCode,
      firstName
    } = user ?? {}

    const dogsAmount = dogs?.length ?? 0
    const [firstDog] = dogs ?? []

    const referralLink = subscription?.referralLink
    const shareLink = subscription?.referralLink.url ?? ''

    return {
      userId,
      dogs,
      firstDog,
      dogsAmount,
      archiveReasons,
      referralLink,
      shareLink,
      showCare: shippingCountry?.showCare ?? false,
      shippingCountryCode,
      firstName,
      calendlyUrl: shippingCountry?.vetNurseCalendlyUrl ?? ''
    }
  })()

  const [shouldSetInitialData, setShouldSetInitialData] = useState(true)
  const [dogsBeforeArchive, setDogsBeforeArchive] = useState<User['dogs']>([])

  // Find the selected dog
  const selectedDog = loadedData?.dogs?.find(({ id }) => id === selectedDogId)

  // Find the selected dog's index
  const selectedDogIndex = loadedData?.dogs?.findIndex(
    ({ id }) => id === selectedDogId
  )

  /**
   * If the selected dog is archived, we need to keep the selected
   * dog in the state, so that we can show the archived dog profile
   * during the exiting state of the ArchiveDogPrfile drawer.
   */
  const selectedDogBeforeArchive = dogsBeforeArchive?.find(
    ({ id }) => id === selectedDogId
  )

  /**
   * If the selected dog is archived, we need to show the archived
   * dog profile. Otherwise, we show the selected dog profile.
   */
  const dog = selectedDog ?? selectedDogBeforeArchive

  useEffect(() => {
    if (shouldSetInitialData && loadedData) {
      setDogsBeforeArchive(loadedData.dogs ?? [])

      const hasPreviousProfilePictures =
        loadedData.dogs?.some((dog) => dog?.dogProfile?.avatarUrl) || false

      sethasFirstTimeProfilePic(!hasPreviousProfilePictures)

      // Prevent the effect from running again
      setShouldSetInitialData(false)

      // Set profile status
      if (dog) setProfileStatus(profileCompletionStatus(dog.dogProfile))
    }
  }, [shouldSetInitialData, loadedData, dog])

  const showFacebookShare =
    selectedDog?.dogProfile &&
    loadedData?.dogs &&
    loadedData?.dogs.length === 1 &&
    selectedDog?.name.length <= 11 &&
    !isNull(profileStatus) &&
    profileStatus === 'pawfectButternutter' &&
    userLanguage &&
    userLanguage === 'en' &&
    windowWidth >= BREAKPOINTS.lg

  const showRAFPass =
    selectedDog?.dogProfile &&
    selectedDog?.dogProfile?.avatarUrl &&
    windowWidth < BREAKPOINTS.lg

  const showHealthSection =
    shouldSeeStap && loadedData?.showCare && loadedData?.calendlyUrl

  return (
    <div>
      {loadedData?.dogs && loadedData.dogs.length > 1 && (
        <Portal
          element={
            <DogSelection
              dogs={loadedData.dogs}
              selectedDogId={selectedDogId}
            />
          }
          target="sub-header-content"
        />
      )}
      <div
        className={`${STYLES.dogProfileDetailsWrapper} ${
          loadedData?.dogsAmount === 1
            ? STYLES.dogProfileDetailsWrapperSingle
            : ''
        }`}
      >
        <DogProfilePicture
          userId={loadedData?.userId}
          dog={dog}
          loading={loading}
          refetch={refetch}
          sethasFirstTimeProfilePic={sethasFirstTimeProfilePic}
        />
        <PersonalityDetails dog={dog} loading={loading} />
      </div>
      {showFacebookShare && (
        <ShareProfile
          dog={selectedDog}
          shareLink={loadedData?.shareLink || ''}
        />
      )}
      {showRAFPass &&
        loadedData?.dogs &&
        loadedData?.referralLink &&
        loadedData?.shippingCountryCode && (
          <>
            <ShareRafPass
              referralLink={loadedData.referralLink}
              shippingCountryCode={loadedData.shippingCountryCode}
              preferredLanguage={userLanguage}
              hasFirstTimeProfilePic={hasFirstTimeProfilePic}
            />
            <RAFPassModal
              namespace="dashboard"
              referralLink={loadedData.referralLink}
              dogNames={
                loadedData.dogs.map(({ name }) => upperFirst(name)) || []
              }
              firstName={loadedData?.firstName || ''}
              avatarUrl={
                loadedData.dogs[0].dogProfile?.avatarUrl
                  ? loadedData.dogs[0].dogProfile?.avatarUrl
                  : ''
              }
            />
          </>
        )}
      <DetailsCarousel dog={dog} loading={loading} />
      <DailyPortionSize
        loading={loading}
        dog={dog}
        dogIndex={selectedDogIndex}
      />
      {showHealthSection && (
        <HealthSection
          dog={dog}
          loading={loading}
          calendlyUrl={loadedData?.calendlyUrl}
        />
      )}
      <ArchiveDogProfileAction
        dog={dog}
        dogs={loadedData?.dogs}
        archiveReasons={loadedData?.archiveReasons}
        loading={loading}
      />
    </div>
  )
}

export { DogProfileContent }
