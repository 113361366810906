import React, { useCallback, useState } from 'react'

import {
  CustomerIssueReportCreate_response_inputFields_CustomerIssueManagementInputFieldAssociation as AssociationInputField,
  CustomerIssueReportCreate_response_inputFields_CustomerIssueManagementInputFieldBoolean as BooleanField,
  CustomerIssueReportCreate_response_categoryReasons as CategoryReason,
  CustomerIssueReportCreate_response_inputFields_CustomerIssueManagementInputFieldInteger as IntegerField,
  CustomerIssueReportCreate_response_inputFields_CustomerIssueManagementInputFieldMultifield_fields_CustomerIssueManagementInputFieldAssociation as MultifieldAssociationField,
  CustomerIssueReportCreate_response_inputFields_CustomerIssueManagementInputFieldMultifield_fields_CustomerIssueManagementInputFieldBoolean as MultifieldBooleanField,
  CustomerIssueReportCreate_response_inputFields_CustomerIssueManagementInputFieldMultifield_fields_CustomerIssueManagementInputFieldInteger as MultifieldIntegerField,
  CustomerIssueReportCreate_response_inputFields_CustomerIssueManagementInputFieldMultifield_fields_CustomerIssueManagementInputFieldString as MultifieldStringField,
  CustomerIssueReportCreate_response_inputFields_CustomerIssueManagementInputFieldString as StringInputField
} from '../../../mutations/__generated__/CustomerIssueReportCreate'
import { CustomerIssueManagementInputInput } from '@/types'

import { InputField } from '../../../types/types'
import Association from './input_types/Association'
import Boolean from './input_types/Boolean'
import Integer from './input_types/Integer'
import Reason from './input_types/Reason'
import String from './input_types/String'

type InputProps = {
  inputField?: InputField
  categoryReasons?: Array<CategoryReason> | null
  reason: string
  updateInputs: (
    fieldId: string,
    newInputs: Array<CustomerIssueManagementInputInput>
  ) => void
  setReason: (newReason: string) => void
}

const Input = ({
  inputField,
  categoryReasons,
  reason,
  updateInputs,
  setReason
}: InputProps): JSX.Element | null => {
  const [inputValue, setInputValue] =
    useState<CustomerIssueManagementInputInput>({
      fieldId: inputField?.id || 'reasonField'
    })
  const updateInput = useCallback(
    (newInput: CustomerIssueManagementInputInput) => {
      setInputValue(newInput)
      updateInputs(newInput.fieldId, [newInput])
    },
    [updateInputs]
  )

  if (categoryReasons && updateInputs) {
    return (
      <Reason
        reason={reason}
        categoryReasons={categoryReasons}
        setReason={setReason}
      />
    )
  } else if (inputField && updateInputs) {
    switch (inputField.dataType) {
      case 'association':
        return (
          <Association
            inputField={
              inputField as AssociationInputField | MultifieldAssociationField
            }
            inputValue={inputValue}
            updateInput={updateInput}
          />
        )
      case 'boolean':
        return (
          <Boolean
            inputField={inputField as BooleanField | MultifieldBooleanField}
            inputValue={inputValue}
            updateInput={updateInput}
          />
        )
      case 'integer':
        return (
          <Integer
            inputField={inputField as IntegerField | MultifieldIntegerField}
            inputValue={inputValue}
            updateInput={updateInput}
          />
        )
      case 'string':
        return (
          <String
            inputField={inputField as StringInputField | MultifieldStringField}
            inputValue={inputValue}
            updateInput={updateInput}
          />
        )
      default: {
        throw new Error(`${inputField} - invalid inputField data type`)
      }
    }
  } else {
    throw new Error('No fields for input')
  }
}

export default Input
