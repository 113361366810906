import { useReactiveVar } from '@apollo/client'
import React, { useEffect } from 'react'

import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './CustomerIssuesFAQ.module.sass'

import {
  customerIssueManagementState,
  pageClassesState
} from '../../CustomerIssueManagementPage'
import CustomerIssuesFAQSwitch from './CustomerIssuesFAQSwitch'

const CustomerIssuesFAQ = (): JSX.Element | null => {
  const customerIssueManagementData = useReactiveVar(
    customerIssueManagementState
  )
  const pageClassesData = useReactiveVar(pageClassesState)

  useEffect(() => {
    const faqClassIndex = pageClassesData.indexOf('faq')
    const pageClasses = pageClassesData.slice()
    if (customerIssueManagementData.faqType && faqClassIndex === -1) {
      pageClasses.push('faq')
      pageClassesState(pageClasses)
    } else if (!customerIssueManagementData.faqType && faqClassIndex > -1) {
      pageClasses.splice(faqClassIndex, 1)
      pageClassesState(pageClasses)
    }
  }, [customerIssueManagementData.faqType, pageClassesData])

  if (!customerIssueManagementData.faqType) {
    return null
  }
  return (
    <div className={STYLES.faqWrapper}>
      <div className={STYLES.faqContainer}>
        <Text
          variant={'display24'}
          namespace={customerIssueManagementData.namespace}
          text={'resolutionConfirmation.replacementBox.faq_header'}
          colour={'brandBlue500'}
        />
        <CustomerIssuesFAQSwitch
          faqType={customerIssueManagementData.faqType}
        />
      </div>
    </div>
  )
}

export default CustomerIssuesFAQ
