// @noflow
import i18next from 'i18next'
import React from 'react'

import { possessive, pronounContext } from '@/utils/StringHelper'

import AlertCard from '@/components/elements/atoms/Alert/AlertCard'
import type { HealthIssues_healthIssues as HealthIssue } from '@/components/pages/SignupWizardPage/screens/Health/queries/__generated__/HealthIssues'
import { Dog } from '@/components/pages/SignupWizardPage/types/types'

import { Gender } from '@/types'

type HealthIssueAlertCardProps = {
  namespace: string
  dog: Dog
  healthIssue: HealthIssue
}

const HealthIssueAlertCard = ({
  namespace,
  dog,
  healthIssue
}: HealthIssueAlertCardProps): JSX.Element => {
  return (
    <AlertCard
      message={{
        namespace,
        variant: 'textRegular16',
        text: healthIssue.information,
        variables: {
          dogName: dog.name,
          possessiveDogName: possessive(dog.name, i18next.language),
          context: pronounContext([dog.gender as Gender], i18next.language)
        },
        translate: false
      }}
      variant={healthIssue.warning ? 'warning' : 'success'}
    />
  )
}

export default HealthIssueAlertCard
