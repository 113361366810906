import React, { useEffect, useState } from 'react'

import { Button } from '@/components/elements/atoms/Button'
import MultiLineTextField from '@/components/elements/atoms/MultiLineTextField/MultiLineTextField'
import TextField from '@/components/elements/atoms/TextField/TextField'

import STYLES from './Form.module.sass'

import PasswordField from '../PasswordField/PasswordField'

type Props = {
  variant?: keyof typeof STYLES
  children: Array<JSX.Element | false>
  onSubmit: (data: { [key: string]: string }) => void
}

const Form = ({ variant, children, onSubmit }: Props): JSX.Element => {
  const [validFields, setValidFields] = useState({})
  const [formData, setFormData] = useState({})
  const [valid, setValid] = useState<boolean>(true)

  const onValidate = (type: string, valid: boolean, value: string) => {
    setValidFields({
      ...validFields,
      [type]: valid
    })

    setFormData({
      ...formData,
      [type]: value
    })
  }

  useEffect(() => {
    if (Object.values(validFields).some((valid: boolean | unknown) => !valid)) {
      setValid(false)
    } else {
      setValid(true)
    }
  }, [validFields])

  const onClick = () => {
    if (Object.values(validFields).some((valid: boolean | unknown) => !valid)) {
      setValid(false)
    } else {
      onSubmit(formData)
    }
  }

  const formFields = children.map(
    (field: JSX.Element | false, index: number): JSX.Element | null => {
      if (!field) {
        return null
      }

      // eslint-disable-next-line i18next/no-literal-string
      const key = `form-field-${index}`
      if ([TextField, PasswordField, MultiLineTextField].includes(field.type)) {
        return React.cloneElement(field, { onValidate, key: key })
      } else if (field.type === Button) {
        return React.cloneElement(field, {
          onClick,
          disabled: !valid,
          key: 'button'
        })
      } else {
        return field
      }
    }
  )

  return (
    <form className={`${STYLES.container} ${variant ? STYLES[variant] : ''}`}>
      {formFields}
    </form>
  )
}

export { Props }
export default Form
