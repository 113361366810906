// @noflow
import { makeVar } from '@apollo/client'
import React from 'react'

type BannerMessage = {
  type: 'error' | 'info'
  message: string | null
}

const bannerMessageState = makeVar<BannerMessage>({
  message: null,
  type: 'error'
})

const Banner = ({ type, message }: BannerMessage): JSX.Element => {
  return (
    <div className={`banner banner--${type}`}>
      <p>{message}</p>
    </div>
  )
}

export default Banner
export { bannerMessageState }

export type { BannerMessage }
