// @noflow
import React from 'react'
import { connect } from 'react-redux'

import { allTabs, title } from '../models/tab'
import type { Tab } from '../models/tab'
import type { Dispatch, State } from '../reducer'
import { tabSelectedFromBar } from '../slices/navigationSlice'
import ListItem from './ListItem'

type PresentationalProps = {
  selectedTab: Tab
}

type ActionProps = {
  tabSelectedFromBar: (tab: Tab) => void
}

type Props = PresentationalProps & ActionProps

type BarItemProps = {
  tabSelectedFromBar: (tab: Tab) => void
  selectedTab: Tab
  tab: Tab
}

const mapStateToProps = (state: State): PresentationalProps => ({
  selectedTab: state.navigation.selectedTab
})

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
  tabSelectedFromBar: (tab: Tab): void => {
    dispatch(tabSelectedFromBar(tab))
  }
})

const BarItem = ({
  tabSelectedFromBar,
  selectedTab,
  tab
}: BarItemProps): React.ReactElement => {
  const onClick = React.useCallback(
    () => tabSelectedFromBar(tab),
    [tabSelectedFromBar, tab]
  )
  return (
    <ListItem
      type={tab === selectedTab ? 'primary' : 'regular'}
      onClick={onClick}
    >
      {title(tab)}
    </ListItem>
  )
}

const TabBar = ({
  tabSelectedFromBar,
  selectedTab
}: Props): React.ReactElement => (
  <div className="tab-bar__tabs">
    {allTabs.map(
      (tab: Tab): React.ReactElement => (
        <BarItem
          tabSelectedFromBar={tabSelectedFromBar}
          selectedTab={selectedTab}
          tab={tab}
          key={tab}
        />
      )
    )}
  </div>
)

export default connect(mapStateToProps, mapDispatchToProps)(TabBar)
