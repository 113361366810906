// @flow

import { connect } from 'react-redux'

import type { State } from '../../index'
import type { AccountDetails, DogsDetails } from '../../message_types'
import type { Dispatch } from 'redux'
import { getAccountDetails, getDogsDetails, sendRafDetailsEmail, sendRafDetailsSms } from '../../thunks'

import AccountDetailsComponent from '../../components/user_details/AccountDetailsComponent'

type PresentationalProps = {|
    isFetching: boolean,
    accountDetails: AccountDetails,
    dogsDetails: DogsDetails,
    userId: number,
    subscriptionId: number
|}

type ActionProps = $ReadOnly<{|
  dispatchGetAccountDetails: ((number) => void),
  dispatchGetDogsDetails: ((number) => void),
  dispatchSendRafDetailsEmail: (() => void),
  dispatchSendRafDetailsSms: (() => void),
|}>

export type ComponentProps =
  & PresentationalProps
  & ActionProps

function mapStateToProps (state: State): PresentationalProps {
  const { accountDetails, dogsDetails, fetchingStatuses, selectedUserIds } = state
  const isFetching = fetchingStatuses.accountDetails
  const { userId, subscriptionId } = selectedUserIds
  return { accountDetails, dogsDetails, isFetching, userId, subscriptionId }
}

function mapDispatchToProps (dispatch: Dispatch): ActionProps {
  const dispatchGetAccountDetails = (userId: number): void => {
    dispatch(getAccountDetails(userId))
  }
  const dispatchGetDogsDetails = (userId: number): void => {
    dispatch(getDogsDetails(userId))
  }
  const dispatchSendRafDetailsEmail = (): void => {
    dispatch(sendRafDetailsEmail())
  }
  const dispatchSendRafDetailsSms = (): void => {
    dispatch(sendRafDetailsSms())
  }
  return { dispatchGetAccountDetails, dispatchGetDogsDetails, dispatchSendRafDetailsEmail, dispatchSendRafDetailsSms }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountDetailsComponent)
