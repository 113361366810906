// @noflow
import { sendEmailButtonWasClicked } from '@/helpers/ReferralLinkShareHelper'
import type { PartialLocation } from '@/helpers/ReferralLinkShareHelper'
import { useMutation } from '@apollo/client'
import i18next from 'i18next'
import React, { useCallback } from 'react'

import { toLocalisedSentence } from '@/utils/StringHelper'
import Validator from '@/utils/validator'

import GreenCheckmark from 'assets/images/icons/checkmarks/green-checkmark.svg'

import withApollo from '@/components/apollo/withApollo'
import { Button } from '@/components/elements/atoms/Button'
import Icon from '@/components/elements/atoms/Icon/Icon'
import Text, { AllowedColours } from '@/components/elements/atoms/Text/Text'
import ConfirmationModal, {
  modalData
} from '@/components/elements/organisms/ConfirmationModal/ConfirmationModal'
import { SEND_EMAIL_MUTATION } from '@/components/elements/organisms/EmailShare/queries/sendEmailMutation'

import STYLES from './EmailShare.module.sass'

import type {
  ReferAFriendUpsert_referAFriendUpsert as SendEmailMutation,
  ReferAFriendUpsertVariables as SendEmailVariables
} from './queries/__generated__/ReferAFriendUpsert'

type EmailShareProps = {
  userID: string
  partialLocation: PartialLocation
  onSuccess?: () => void
  ctaText?: string
  translate?: boolean
  variant?: 'primary' | 'secondary'
  fullWidthCta?: boolean
  maxEmailInputs?: number
  ctaNamespace?: string
  textColour?: AllowedColours
  addEmailInputColour?: 'brandBlue500' | 'brandWhite'
}

type EmailInput = {
  index: number
  active: boolean
  valid: boolean | undefined
  value: string
}

type GraphQLErrorResponse = {
  message: string
  unsuccessful_emails?: Array<string>
  successful_emails?: Array<string>
}

const copyContext = 'dashboard:referrals.link_and_share'

const parseErrorMessage = (error: GraphQLErrorResponse): string => {
  if (!error.unsuccessful_emails || !error.successful_emails)
    return error.message
  const reassuranceMessage =
    error.successful_emails.length > 0
      ? i18next.t(`${copyContext}.reassurance_message`)
      : ''
  const emails = toLocalisedSentence({
    arr: error.unsuccessful_emails,
    lng: i18next.language
  })
  return i18next.t(`${copyContext}.error_message`, {
    count: error.unsuccessful_emails.length,
    emails,
    reassuranceMessage
  })
}

const EmailShare = ({
  userID,
  partialLocation,
  onSuccess,
  ctaText,
  variant = 'primary',
  fullWidthCta = false,
  maxEmailInputs = 5,
  translate = true,
  ctaNamespace,
  textColour = 'brandBlue500',
  addEmailInputColour = 'brandBlue500'
}: EmailShareProps): React.ReactElement => {
  const [activeEmailInputs, updateActiveEmailInputs] = React.useState<
    Array<EmailInput>
  >([{ index: 0, active: true, valid: undefined, value: '' }])
  const [responseMessage, setResponseMessage] = React.useState('')
  const addEmailInput = React.useCallback(() => {
    updateActiveEmailInputs([
      ...activeEmailInputs,
      {
        index: activeEmailInputs[activeEmailInputs.length - 1].index + 1,
        active: true,
        valid: undefined,
        value: ''
      }
    ])
  }, [activeEmailInputs])

  const emails = activeEmailInputs.map((email: EmailInput): string =>
    email.value !== '' ? email.value : ''
  )
  const allEmailsAreValid = activeEmailInputs.every(
    (email: EmailInput): boolean => email.valid !== undefined && email.valid
  )

  const [sendEmail, { loading, data, error }] = useMutation<
    SendEmailMutation,
    SendEmailVariables
  >(SEND_EMAIL_MUTATION, {
    variables: { userId: userID, emails }
  })

  const sendEmails = React.useCallback(() => {
    sendEmail()
    sendEmailButtonWasClicked(partialLocation)
  }, [partialLocation, sendEmail])

  React.useEffect(() => {
    if (data) {
      if (onSuccess) {
        onSuccess()
      } else {
        modalData({
          isOpen: true,
          namespace: 'dashboard',
          // eslint-disable-next-line i18next/no-literal-string
          text: `${copyContext}.email_sent`,
          delay: 3000
        })
      }
    }
    if (error) {
      setResponseMessage(parseErrorMessage(error.graphQLErrors[0]))
    }
  }, [data, error, onSuccess])

  return (
    <div className="referral-link-share">
      <Text
        namespace="dashboard"
        text={`${copyContext}.send_email`}
        variant="textRegular16"
        colour={textColour}
        margin={false}
        align="left"
        bold
      />
      {activeEmailInputs.map(
        ({ index, active }): React.ReactElement | false =>
          active && (
            <EmailInput
              index={index}
              key={`email-input-${index}`}
              activeEmailInputs={activeEmailInputs}
              updateActiveEmailInputs={updateActiveEmailInputs}
              addEmailInputColour={addEmailInputColour}
            />
          )
      )}
      {activeEmailInputs.length < maxEmailInputs && (
        <button
          type="button"
          onClick={addEmailInput}
          className={`${STYLES.emailInputCTA} ${STYLES[addEmailInputColour]}`}
        >
          {`+ ${i18next.t(`${copyContext}.add_friend_btn`)}`}
        </button>
      )}
      <div className={STYLES.sendButtonWrapper}>
        <Button
          disableAnalytics
          typography={{
            translate,
            namespace: ctaNamespace ?? 'dashboard',
            text:
              ctaText ??
              `${copyContext}.send_email_btn${loading ? '_loading' : ''}`
          }}
          onClick={sendEmails}
          disabled={loading || !allEmailsAreValid}
          size="regular"
          variant={variant}
          fullWidth={fullWidthCta}
        />
      </div>
      {error && (
        <div className={STYLES.errorBanner}>
          <Text
            translate={false}
            text={responseMessage}
            variant="textRegular18"
            align="center"
            colour="supportRed500"
            margin={false}
          />
        </div>
      )}
      <ConfirmationModal />
    </div>
  )
}

const EmailInput = ({
  index,
  activeEmailInputs,
  updateActiveEmailInputs,
  addEmailInputColour = 'brandBlue500'
}: {
  index: number
  activeEmailInputs: Array<EmailInput>
  updateActiveEmailInputs: React.Dispatch<React.SetStateAction<EmailInput[]>>
  addEmailInputColour?: 'brandBlue500' | 'brandWhite'
}): React.ReactElement => {
  const removeActiveInput = React.useCallback(
    (e: React.SyntheticEvent<HTMLElement>) => {
      const target = e.target as HTMLElement
      const targetIndex = target.dataset.index
      updateActiveEmailInputs(
        activeEmailInputs.filter(
          (email: EmailInput): boolean => email.index.toString() !== targetIndex
        )
      )
    },
    [updateActiveEmailInputs, activeEmailInputs]
  )

  const [inputIsValid, setValidation] = React.useState(
    undefined as boolean | undefined
  )
  const [interacted, setInteracted] = React.useState(false)

  const validateEmail = React.useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement
      const targetIndex = target.dataset.index
      const value = target.value
      const validity = Validator.isValidEmail(value)
      setValidation(validity)

      const updatedList = activeEmailInputs.map((item) => {
        if (item.index.toString() === targetIndex) {
          if (value === '') {
            return { ...item, valid: undefined, value: value }
          } else {
            return { ...item, valid: validity, value: value }
          }
        }
        return item
      })
      updateActiveEmailInputs(() => [...updatedList])
    },
    [setValidation, activeEmailInputs, updateActiveEmailInputs]
  )

  const stopCursorAnimation = (
    e: React.SyntheticEvent<HTMLInputElement>
  ): void => {
    const target = e.target as HTMLInputElement
    const parent = target.parentElement
    if (parent && !parent.classList.contains(STYLES.stopAnimation)) {
      parent.classList.add(STYLES.stopAnimation)
    }
  }

  const handleBlur = React.useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>): void => {
      stopCursorAnimation(e)
      setInteracted(true)
      validateEmail(e)
    },
    [validateEmail, setInteracted]
  )

  const handleFocus = React.useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>): void => {
      stopCursorAnimation(e)
    },
    []
  )

  const handleOnChange = React.useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>): void => {
      const target = e.target as HTMLInputElement
      if (interacted) {
        validateEmail(e)
      } else if (Validator.isValidEmail(target.value)) {
        setInteracted(true)
        validateEmail(e)
      }
      stopCursorAnimation(e)
    },
    [interacted, validateEmail]
  )

  const emailPlaceholderTextColour = useCallback((): AllowedColours => {
    if (inputIsValid === false) {
      return 'supportRed300'
    } else {
      return 'brandBlue400'
    }
  }, [inputIsValid])

  return (
    <>
      {activeEmailInputs.length > 1 && (
        <button
          type="button"
          className={`${STYLES.emailInputCTA} ${STYLES[addEmailInputColour]}}`}
          data-index={index}
          onClick={removeActiveInput}
        >
          {i18next.t(`${copyContext}.remove_btn`)}
        </button>
      )}
      <div
        className={`
        ${STYLES.emailInputWrapper}
        ${inputIsValid === false ? `${STYLES.invalid}` : ''}
        ${index === 0 ? `${STYLES.animate}` : ''}`}
      >
        <input
          autoComplete="on"
          className={`
            ${STYLES.emailInput}
            ${inputIsValid === undefined ? '' : `${STYLES.inputInteracted}`}
          `}
          id="email"
          name="email"
          data-index={index}
          onChange={handleOnChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          required
          type="text"
        />
        <div className={STYLES.emailPlaceholder}>
          <Text
            namespace="dashboard"
            text={`${copyContext}.email_placeholder`}
            colour={emailPlaceholderTextColour()}
            variant="textRegular16"
            margin={false}
          />
        </div>
        {inputIsValid && (
          <img
            alt=""
            src={GreenCheckmark}
            className={STYLES.validationCheckmark}
          />
        )}
      </div>
      {inputIsValid === false && (
        <div className={STYLES.validationErrorMessage}>
          <div className={STYLES.errorCircleIcon}>
            <Icon
              size={20}
              width={20}
              asset="errorCircle"
              accentColour="dangerRed300"
            />
          </div>
          <Text
            namespace="dashboard"
            text={`${copyContext}.email_validation_error`}
            variant="textRegular16"
            colour="supportRed300"
            margin={false}
          />
        </div>
      )}
    </>
  )
}

export type { EmailShareProps }

export default withApollo(EmailShare)
