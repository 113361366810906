// @flow

import * as React from 'react'
import { formatCurrencyWithoutDecimal } from '@/utils/currency'
import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { useTranslation } from 'react-i18next'

import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'
import type { Language } from '@/packs/localisation'

export type ReferralCategory =
  | 'social'
  | 'priority'

type Props = {|
  referralSignupCredit: number,
  referralDiscountCodeValues: Array<number>,
  referralCategory: ReferralCategory,
  shippingCountryCode: CountryCode,
  preferredLanguage: Language
|}

const baseClass = 'ambassador-give-get-discount'

const GiveGetReferralComponent = ({
  referralSignupCredit,
  referralDiscountCodeValues,
  referralCategory,
  shippingCountryCode,
  preferredLanguage
}: Props): React.Node => {
  const { t } = useTranslation('ambassadors')
  const namespace = 'dashboard.give_get_referral_component'
  const { locale, currency } = countryCodeToLocaleCurrency(shippingCountryCode, preferredLanguage)

  const headerFor = (referralCategory: ReferralCategory): string => {
    switch (referralCategory) {
      case 'priority':
        return t(`${namespace}.your_referral_link`)
      case 'social':
        return t(`${namespace}.your_social_media_link`)

      default:
        throw new Error('Unsupported link category')
    }
  }

  const headerNumberFor = (referralCategory: ReferralCategory): string => {
    switch (referralCategory) {
      case 'priority':
        return '01'
      case 'social':
        return '02'
      default:
        throw new Error('Unsupported link category')
    }
  }

  const subHeaderFor = (referralCategory: ReferralCategory): React.Element<'p'> => {
    switch (referralCategory) {
      case 'priority':
        return (
          <p>
            { t(`${namespace}.use_high_discount`) }
          </p>
        )
      case 'social':
        return (
          <p>
            { t(`${namespace}.shared_online`) }
          </p>
        )
      default:
        throw new Error('Unsupported link category')
    }
  }

  const descriptionFor = (referralCategory: ReferralCategory): string => {
    switch (referralCategory) {
      case 'priority':
        return t(`${namespace}.clients_get`)
      case 'social':
        return t(`${namespace}.followers_get`)

      default:
        return t(`${namespace}.clients_get`)
    }
  }

  return (
    <div className={`${baseClass}__container`}>
      <div className={`${baseClass}__header-container`}>
        <div className={`${baseClass}__header-main`}>
          <h2 className={`${baseClass}__header-main-number`}>
            { headerNumberFor(referralCategory) }
          </h2>
          <h2 className={`${baseClass}__header-main-text`}>
            { headerFor(referralCategory) }
          </h2>
        </div>
        <div className={`${baseClass}__header-subheader`}>
          { subHeaderFor(referralCategory) }
        </div>
      </div>
      <div className={`${baseClass}__discount-wrapper`}>
        <div className={`${baseClass}__referral`}>
          <p className={`${baseClass}__text ${baseClass}__text--title`}>
            { descriptionFor(referralCategory) }
          </p>
          {
            referralDiscountCodeValues.length === 1
              ? (
                <React.Fragment>
                  <div className={`${baseClass}__referral__container`}>
                    <span className={`${baseClass}__referral__container--single-discount`}>
                      { `${referralDiscountCodeValues[0]}%` }
                    </span>
                    <span className={`${baseClass}__referral__container--single-discount-off`}>
                      { ` ${t(`${namespace}.off`)}` }
                    </span>
                  </div>
                  <p className={`${baseClass}__text`}>
                    { t(`${namespace}.their_first_box`) }
                  </p>
                </React.Fragment>
                )
              : (
                <div className={`${baseClass}__referral__tiered-wrapper`}>
                  <div className={`${baseClass}__referral__container`}>
                    <span className={`${baseClass}__referral__container--tiered-discount`}>
                      { `${referralDiscountCodeValues[0]}%` }
                    </span>
                    <span className={`${baseClass}__referral__container--tiered-discount-off`}>
                      { ` ${t(`${namespace}.off`)}` }
                    </span>
                  </div>
                  <div className={`${baseClass}__referral__container`}>
                    <span className={`${baseClass}__referral__container--tiered-discount`}>
                      { `${referralDiscountCodeValues[1]}%` }
                    </span>
                    <span className={`${baseClass}__referral__container--tiered-discount-off`}>
                      { ` ${t(`${namespace}.off`)}`}
                    </span>
                  </div>
                  <p className={`${baseClass}__text`}>
                    { t(`${namespace}.their_first_box`) }
                  </p>
                  <p className={`${baseClass}__text`}>
                    { t(`${namespace}.their_second_box`) }
                  </p>
                </div>
                )
          }
        </div>
        <div className={`${baseClass}__referrer`}>
          <p className={`${baseClass}__text ${baseClass}__text--title`}>
            { t(`${namespace}.you_get`) }
          </p>
          <div className={`${baseClass}__referrer__container`}>
            <span>
              { `${formatCurrencyWithoutDecimal(referralSignupCredit, { locale: locale, currency: currency })}` }
            </span>
          </div>
          <p className={`${baseClass}__text ${baseClass}__text`}>
            { t(`${namespace}.account_credit`) }
          </p>
        </div>
      </div>
    </div>
  )
}

export default GiveGetReferralComponent
