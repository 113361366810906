// @flow

import * as React from 'react'

import DancingDog from 'assets/images/gifs/happy_dancing_dog.gif'
import ButternutVanGif from 'assets/images/gifs/lorry.gif'
import { useTranslation } from 'react-i18next'

type Box = {|
  next_delivery_date: string,
  postcode: string
|}

type Subscription = {|
  formatted_signup_discount_code_description: string,
  number_of_trial_boxes_delivered: number,
  next_box: ?Box,
  last_box: ?Box
|}

type Affiliate = {|
  dog_names: Array<string>,
  is_paid_subscription: boolean,
  number_of_trial_boxes: number,
  trial_subscription: ?Subscription,
  subscription_status: string,
|}

type AffiliateType =
  | 'influencer'
  | 'ambassador'

type Props = {|
  affiliate: Affiliate,
  affiliateType: AffiliateType
|}

type GridItem = {|
  top: string,
  middle: React.Node,
  bottom: React.Node
|}

const formatDogNames = (names: Array<string>): string => names.join(' and')

const signInAsGuestPath = (affiliateType: AffiliateType): string => {
  switch (affiliateType) {
    case 'influencer':
      return '/influencers/sign_in_as_guest'
    case 'ambassador':
      return '/ambassadors/sign_in_as_guest'
    default:
      (affiliateType: empty) // eslint-disable-line no-unused-expressions
      throw new Error(`signInAsGuestPath(_type_) not implemented for type: ${affiliateType}`)
  }
}

const signInAsUserPath = (redirectUrl: string, affiliateType: AffiliateType): string => {
  switch (affiliateType) {
    case 'influencer':
      return `/influencers/sign_in_as_user?redirect_url=${redirectUrl}`
    case 'ambassador':
      return `/ambassadors/sign_in_as_user?redirect_url=${redirectUrl}`
    default:
      (affiliateType: empty) // eslint-disable-line no-unused-expressions
      throw new Error(`signInAsUserPath(_type_) not implemented for type: ${affiliateType}`)
  }
}

const BoxSummaryComponent = ({ affiliate, affiliateType }: Props): React.Node => {
  const { t } = useTranslation('ambassadors')
  const namespace = 'dashboard.box_summary'

  const dancingDogContainer = (text: string): React.Node => (
    <div className='grid--flex grid--flex-direction--row container grid--flex-center'>
      <img
        alt='An animation of a dancing dog.'
        className='grid-column--flex-item-single mirrored desktop-and-tablet-display'
        src={DancingDog}
      />
      <div className='grid-column--flex-item-triple'>
        <p>
          { text }
        </p>
      </div>
      <img
        alt='An animation of a dancing dog.'
        className='grid-column--flex-item-single desktop-and-tablet-display'
        src={DancingDog}
      />
    </div>
  )

  const gridItemsForSubWIthOutstandingDeliveries =
    (affiliate: Affiliate, subscription: {| number_of_trial_boxes_delivered: number, next_box: Box |}): GridItem => {
    const { next_box: nextBox, number_of_trial_boxes_delivered } = subscription

    const totalBoxes = affiliate.number_of_trial_boxes

    const top = totalBoxes === 1
      ? t(`${namespace}.first_box_on_us`)
      : t(`${namespace}.your_free_box`, { boxOrBoxes: t(`${namespace}.boxes`) })

    const middleTextStart = totalBoxes === 1
      ? t(`${namespace}.free_box_built`)
      : number_of_trial_boxes_delivered === 0
        ? t(`${namespace}.first_free_box_built`)
        : `${t(`${namespace}.hope_you_loved`,
          { dogNames: formatDogNames(affiliate.dog_names), boxOrBoxes: t(`${namespace}.box`) })} ${t(`${namespace}.your_next_free_box`)}`

    const middleText = t(`${namespace}.will_be_delivered`, { middleTextStart, delivery_date: nextBox.next_delivery_date, postcode: nextBox.postcode })


    const middle = (
      <div>
        <p>
          { middleText }
        </p>
        <div className='content-padding__top-bottom-mini'>
          <img
            alt='The Butternut Box van.'
            src={ButternutVanGif}
          />
        </div>
      </div>
    )

    const bottom = (
      <a href={signInAsUserPath('/dashboard/boxes', affiliateType)}>
        { t(`${namespace}.change_delivery_day`) }
      </a>
    )

    return { top, middle, bottom }
  }

  const gridItemsForAffiliateWithoutSubscription = (affiliate: Affiliate): GridItem => {
    const totalBoxes = affiliate.number_of_trial_boxes

    const top = t(`${namespace}.your_free_box`,
      { boxOrBoxes: totalBoxes === 1 ? t(`${namespace}.box`) : t(`${namespace}.boxes`) }
    )


    const middleText = totalBoxes === 1
      ? t(`${namespace}.first_box_on_us`)
      : t(`${namespace}.first_boxes_on_us`, { totalBoxes } )

    const bottom = (
      <div className='btn-with-shadow-container'>
        <a
          className='btn btn-yellow-blue-hover'
          href={signInAsGuestPath(affiliateType)}
        >
          { t(`${namespace}.build_first_box`) }
        </a>
      </div>
    )

    return { top, middle: dancingDogContainer(middleText), bottom }
  }

  const gridItemsForAffiliateWithPaidSub = (affiliate: Affiliate): GridItem => {
    const subscriptionCopy = (): string => {
      switch(affiliate.subscription_status) {
        case 'active':
        return t(
          `${namespace}.active_subscription`,
          { dogNames: formatDogNames(affiliate.dog_names) }
        )
        case 'suspended': return t(`${namespace}.suspended_subscription`)
        case 'paused': return t(`${namespace}.paused_subscription`)

        default: return ''
      }
    }


    const top = t(`${namespace}.your_subscription`)

    const bottom = (
      <div className='btn-with-shadow-container'>
        <a
          className='btn btn-yellow-blue-hover'
          href={signInAsUserPath('/dashboard', affiliateType)}
        >
          { t(`${namespace}.go_to_your_dashboard`) }
        </a>
      </div>
    )

    return (
      {
        top,
        middle: dancingDogContainer(subscriptionCopy()),
        bottom
      }
    )
  }

  const gridItemsForSubWithAllBoxesDelivered = (affiliate: Affiliate): GridItem => {
    const { number_of_trial_boxes: totalBoxes } = affiliate

    const top = t(`${namespace}.your_boxes`)

    const boxOrBoxes = totalBoxes === 1 ? t(`${namespace}.box`) : t(`${namespace}.boxes`)
    const middleTextTop = t(`${namespace}.hope_you_loved`,
      { dogNames: formatDogNames(affiliate.dog_names), boxOrBoxes })


    const middle = (
      <div>
        <p>
          { middleTextTop }
        </p>
        <p>
          { t(`${namespace}.continue_boxes`) }
        </p>
      </div>
    )

    const bottom = (
      <div className='btn-with-shadow-container'>
        <a
          className='btn btn-yellow-blue-hover'
          href={signInAsUserPath('/account/payment-methods', affiliateType)}
        >
          { t(`${namespace}.complete_your_profile`) }
        </a>
      </div>
    )

    return { top, middle, bottom }
  }

  const generateGridItemsFor = (affiliate: Affiliate): ?GridItem => {
    if (!affiliate.is_paid_subscription && affiliate.number_of_trial_boxes < 1) return null

    const { trial_subscription: subscription } = affiliate

    if(affiliate.is_paid_subscription) {
      return gridItemsForAffiliateWithPaidSub(affiliate)
    } else if (!subscription) {
      return gridItemsForAffiliateWithoutSubscription(affiliate)
    } else {
      const {
        next_box: nextBox,
        last_box: lastBox,
        number_of_trial_boxes_delivered
      } = subscription

      // eslint-disable-next-line flowtype/no-flow-fix-me-comments
      // $FlowFixMe
      if (nextBox && !nextBox.delivered) {
        return gridItemsForSubWIthOutstandingDeliveries(affiliate, { number_of_trial_boxes_delivered, next_box: nextBox })
      } else if (lastBox && !nextBox) {
        return gridItemsForSubWithAllBoxesDelivered(affiliate)
      }
    }
  }

  const [gridItems] = React.useState<?GridItem>(generateGridItemsFor(affiliate))

  if (gridItems === null || gridItems === undefined) {
    return null
  }

  return (
    <div className='affiliate-summary__grid-item-container affiliate-summary__grid-full-item'>
      <div className='affiliate-summary__grid-item'>
        <h2>
          { gridItems.top }
        </h2>
        { gridItems.middle }
        <div>
          { gridItems.bottom }
        </div>
      </div>
    </div>
  )
}

export type {
  Affiliate
}

export default BoxSummaryComponent