// @noflow

/* eslint-disable i18next/no-literal-string */
import { useNotifications } from '@/context/notifications/notifications'
import { ACCOUNT_ROUTES } from '@/routes'
import { useMutation } from '@apollo/client'
import i18next from 'i18next'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { possessive } from '@/utils/StringHelper'
import { planDiscrepancy } from '@/utils/planDiscrepancy'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import { Activity } from './components/Activity'
import { BodyCondition } from './components/BodyCondition'
import { EatingHabits } from './components/EatingHabits'
import { SnackingHabits } from './components/SnackingHabits'
import { Weight } from './components/Weight'
import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'
import { FixedBase } from '@/components/elements/atoms/FixedBase'
import {
  SectionWrapper,
  SectionWrapperProps
} from '@/components/elements/atoms/SectionWrapper'
import { Value } from '@/components/elements/molecules/SelectableCardGroup/SelectableCardGroup'

import STYLES from './UpdateDogWeightAndAppetite.module.sass'

import { UPDATE_DOG_WEIGHT_AND_APPETITE_MUTATION } from './mutations/updateDogWeightAndAppetiteMutation'

import type {
  UpdateDogWeightAndAppetiteMutation,
  UpdateDogWeightAndAppetiteMutationVariables
} from './mutations/__generated__/UpdateDogWeightAndAppetiteMutation'
import type {
  DogWeightAndAppetiteQuery_user_dogs as Dog,
  DogWeightAndAppetiteQuery
} from './queries/__generated__/DogWeightAndAppetiteQuery'
import { Eater, SnackingHabit } from '@/types'
import type { BodyShape, Exercise } from '@/types'

type Props = Partial<DogWeightAndAppetiteQuery> & {
  selectedDog?: Dog
  loading: boolean
}

const copyContext = 'update_dog_profile_weight_and_appetite'

type SharedInputProps = {
  onSelect: (value: Value) => void
  loading: boolean
  margin?: SectionWrapperProps['margin']
}

const UpdateDogWeightAndAppetiteContent = ({
  selectedDog,
  loading,
  ...restProps
}: Props): JSX.Element => {
  const container = useRef<HTMLDivElement>(null)
  const { setErrorNotification, setSuccessNotification } = useNotifications()
  const navigate = useNavigate()
  const { windowWidth } = useWindowSize()

  const isMobile = windowWidth < BREAKPOINTS.md

  const loadedData = (() => {
    if (loading) {
      return null
    }

    const { user } = restProps
    const { id: userId, dogs } = user ?? {}

    return {
      userId,
      dogs
    }
  })()

  const [shouldSetInitialData, setShouldSetInitialData] = useState(true)
  const [weightAndAppetite, setWeightAndAppetite] = useState({
    weightInKg: 0,
    bodyShape: '',
    exercise: '',
    eaterType: Eater.eats_anything,
    snackingHabits: SnackingHabit.unknown_snacking_habits
  })

  const genderContext = selectedDog?.gender ?? ''
  const possessiveDogName =
    selectedDog && possessive(selectedDog.name, i18next.language)

  const onWeightChange = useCallback((value: string) => {
    setWeightAndAppetite((prevState) => ({
      ...prevState,
      weightInKg: value === '' ? 0 : +parseFloat(value).toFixed(2)
    }))
  }, [])

  const handleOnSelect = useCallback(
    (value, type) => {
      setWeightAndAppetite({
        ...weightAndAppetite,
        [type]: value
      })
    },
    [weightAndAppetite]
  )

  const handleOnBodyShapeSelect = useCallback(
    (value) => handleOnSelect(value, 'bodyShape'),
    [handleOnSelect]
  )

  const handleActivityLevelSelect = useCallback(
    (value) => handleOnSelect(value, 'exercise'),
    [handleOnSelect]
  )

  const handleOnEaterTypeSelect = useCallback(
    (value) => {
      handleOnSelect(value, 'eaterType')
    },
    [handleOnSelect]
  )

  const handleOnSnackingHabitsSelect = useCallback(
    (value) => handleOnSelect(value, 'snackingHabits'),
    [handleOnSelect]
  )

  /**
   * Update dog weight and appetite mutation
   *
   * On error, show error message and log error to Sentry.
   * On success, show success message, refetch user data and navigate
   * back to the previous screen.
   */
  const [
    updateDogWeightAndAppetiteMutation,
    { loading: updateDogWeightAndAppetiteLoading }
  ] = useMutation<
    UpdateDogWeightAndAppetiteMutation,
    UpdateDogWeightAndAppetiteMutationVariables
  >(UPDATE_DOG_WEIGHT_AND_APPETITE_MUTATION, {
    onError: () => {
      setErrorNotification({
        text: `${copyContext}.notifications.error`,
        namespace: 'account'
      })
    },
    onCompleted: (data) => {
      setSuccessNotification({
        text: `${copyContext}.notifications.success`,
        namespace: 'account',
        variables: {
          possessiveDogName
        }
      })

      // TODO: Double check if we need that logic
      if (data?.dogsUpdate && planDiscrepancy(data.dogsUpdate.subscription)) {
        // TODO: Navigate to the recommended plan page
        navigate(`${ACCOUNT_ROUTES.profile}/${selectedDog?.id}`)
      } else {
        navigate(`${ACCOUNT_ROUTES.profile}/${selectedDog?.id}`)
      }
    }
  })

  /**
   * Hander to update dog weight and appetite
   */
  const updateDogWeightAndAppetiteDetails = useCallback(async () => {
    try {
      const { weightInKg, exercise, bodyShape, snackingHabits, eaterType } =
        weightAndAppetite
      const weightInGrams = weightInKg * 1000

      await updateDogWeightAndAppetiteMutation({
        variables: {
          userId: loadedData?.userId ?? '',
          attributes: [
            {
              id: selectedDog?.id ?? '',
              weightInGrams,
              exercise: exercise as Exercise,
              bodyShape: bodyShape as BodyShape,
              snackingHabits: snackingHabits as SnackingHabit,
              eaterType: eaterType as Eater
            }
          ],
          caloriesMultipleOf: 10,
          percentageMultipleOf: 5,
          lowerPercentage: 90,
          upperPercentage: 110
        }
      })
    } catch (e) {
      setErrorNotification({
        text: `${copyContext}.notifications.error`,
        namespace: 'account'
      })
    }
  }, [
    selectedDog?.id,
    loadedData?.userId,
    updateDogWeightAndAppetiteMutation,
    weightAndAppetite,
    setErrorNotification
  ])

  useEffect(() => {
    if (shouldSetInitialData && loadedData && selectedDog) {
      const { weightInGrams, bodyShape, exercise, snackingHabits, eaterType } =
        selectedDog

      setWeightAndAppetite({
        ...weightAndAppetite,
        weightInKg: weightInGrams / 1000,
        bodyShape,
        exercise,
        snackingHabits,
        eaterType
      })

      // Prevent the effect from running again
      setShouldSetInitialData(false)
    }
  }, [shouldSetInitialData, loadedData, weightAndAppetite, selectedDog])

  const getSectionWrapperProps = (type: string) =>
    ({
      headerTypography: {
        text: `${copyContext}.${type}.title`,
        namespace: 'account',
        variant: 'display16',
        shouldScale: false,
        margin: false,
        variables: {
          context: genderContext
        }
      },
      margin: {
        top: 0,
        bottom: 0
      }
    } as const)

  const formErrorsDisabled = weightAndAppetite.weightInKg === 0
  const noChangeToWeightAndAppetite = useMemo(() => {
    if (!selectedDog) return true

    const { weightInGrams, bodyShape, exercise, snackingHabits, eaterType } =
      selectedDog

    return (
      weightInGrams === weightAndAppetite.weightInKg * 1000 &&
      bodyShape === weightAndAppetite.bodyShape &&
      exercise === weightAndAppetite.exercise &&
      snackingHabits === weightAndAppetite.snackingHabits &&
      eaterType === weightAndAppetite.eaterType
    )
  }, [weightAndAppetite, selectedDog])

  const isUpdateDisabled =
    loading ||
    updateDogWeightAndAppetiteLoading ||
    noChangeToWeightAndAppetite ||
    formErrorsDisabled

  return (
    <div ref={container} className={STYLES.container}>
      <Card shadow padding={isMobile ? 24 : 32} width={520}>
        <div className={STYLES.cardContent}>
          <SectionWrapper {...getSectionWrapperProps('weight')}>
            <Weight
              weightInKg={weightAndAppetite.weightInKg}
              onWeightChange={onWeightChange}
              loading={loading}
            />
          </SectionWrapper>
          <SectionWrapper {...getSectionWrapperProps('body_condition')}>
            <BodyCondition
              loading={loading}
              bodyShape={weightAndAppetite.bodyShape}
              onSelect={handleOnBodyShapeSelect}
            />
          </SectionWrapper>
          <SectionWrapper {...getSectionWrapperProps('activity_level')}>
            <Activity
              loading={loading}
              onSelect={handleActivityLevelSelect}
              exercise={weightAndAppetite.exercise}
            />
          </SectionWrapper>
          <SectionWrapper {...getSectionWrapperProps('eater_type')}>
            <EatingHabits
              loading={loading}
              genderContext={genderContext}
              isMobile={isMobile}
              onSelect={handleOnEaterTypeSelect}
              eaterType={weightAndAppetite.eaterType}
            />
          </SectionWrapper>
          <SectionWrapper {...getSectionWrapperProps('snacking_habits')}>
            <SnackingHabits
              loading={loading}
              genderContext={genderContext}
              onSelect={handleOnSnackingHabitsSelect}
              snackingHabits={weightAndAppetite.snackingHabits}
            />
          </SectionWrapper>
        </div>
      </Card>
      <FixedBase backgroundColor="brandWhite90" container={container}>
        <div className={STYLES.footerContent}>
          <Button
            identifier="dog_weight_and_appetite.update"
            onClick={updateDogWeightAndAppetiteDetails}
            typography={{
              text: `${copyContext}.button`,
              namespace: 'account'
            }}
            skeleton={{
              isLoading: loading
            }}
            disabled={isUpdateDisabled}
          />
        </div>
      </FixedBase>
    </div>
  )
}

export type { SharedInputProps }

export { UpdateDogWeightAndAppetiteContent }
