// @noflow

import React from 'react'

declare type Transition = 'left' | 'bottom' | 'scale' | 'right' | 'bottom-with-image'

declare type Props = {
  modalIsOpen: boolean,
  showCloseButton: boolean,
  useCustomCloseButton?: boolean,
  customCloseButtonContent?: React.ReactElement,
  modalSize: 'small' | 'medium' | 'large',
  transitionTypes: { desktop: Transition, mobile: Transition },
  extraClasses: string,
  closeModal: (className: string) => void,
  children: React.ReactNode,
  withBackdrop?: boolean
}

declare class TransitionalModal extends React.Component<Props> {}

export default TransitionalModal
