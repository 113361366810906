import { useCallback, useEffect, useState } from 'react'

type Props = {
  countdownDuration: number
  stopCountdown?: boolean
}

type UserInactivity = {
  hasAbandoned: boolean
  resetCountDown: () => void
}

const useUserInactivity = ({
  countdownDuration,
  stopCountdown
}: Props): UserInactivity => {
  const [timeLeft, setTimeLeft] = useState(countdownDuration)
  const [paused, setPaused] = useState(false)

  const resetCountDown = useCallback(
    () => setTimeLeft(countdownDuration),
    [countdownDuration]
  )

  // Listen for when the user changes tab
  const handleTabChange = useCallback(() => {
    if (document.visibilityState !== 'visible') {
      setTimeLeft(0)
    }
    setPaused(true)
  }, [])

  useEffect(() => {
    if (paused || stopCountdown) return

    // When mouse movement is detected, reset the timer
    document.addEventListener('mousemove', resetCountDown)

    // When the active tab changes, set the timeLeft to 0
    document.addEventListener('visibilitychange', handleTabChange)

    // Clean up the event listeners
    return () => {
      document.removeEventListener('mousemove', resetCountDown)
      document.removeEventListener('visibilitychange', handleTabChange)
    }
  }, [handleTabChange, paused, resetCountDown, stopCountdown])

  // Countdown the timer from the passed in countdownDuration to 0
  useEffect(() => {
    if (paused || stopCountdown) return

    const countdown = setTimeout(() => setTimeLeft(timeLeft - 1), 1000)

    // Once the timer reaches 0, we can pause it so it
    // does not count down any further
    if (timeLeft < 1) setPaused(true)

    return () => {
      clearTimeout(countdown)
    }
  }, [timeLeft, paused, stopCountdown])

  return {
    hasAbandoned: timeLeft < 1,
    resetCountDown
  }
}

export default useUserInactivity
