// @noflow
import React, { Fragment } from 'react'

import Text from '@/components/elements/atoms/Text'
import type { TextProps } from '@/components/elements/atoms/Text'

type TextVariant = {
  variant: 'text'
  typography: TextProps
}

type CustomVariant = {
  variant: 'custom'
  content: JSX.Element | null
}

type TextVariantType = TextVariant['variant']
type CustomVariantType = CustomVariant['variant']

type Variant = {
  variant: TextVariantType | CustomVariantType
} & Omit<TextVariant, 'type'> &
  Omit<CustomVariant, 'type'>

type Props = Variant | TextVariant | CustomVariant

const HeaderRightAdornment = ({
  variant,
  ...restProps
}: Props): JSX.Element | null => {
  switch (variant) {
    case 'text': {
      const { typography } = restProps as TextVariant

      return <Text {...typography} />
    }
    case 'custom': {
      const { content } = restProps as CustomVariant

      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <Fragment>{content}</Fragment>
    }
    default: {
      return null
    }
  }
}

export type { Props }

export { HeaderRightAdornment }
