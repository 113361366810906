import React, { ReactElement } from 'react'

import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './BoxBreakdown.module.sass'

import Box from '../../../../../assets/images/illustrations/boxes/exploding-box--yellow.svg'
import Calendar from '../../../../../assets/images/illustrations/calendar--simple.svg'
import Pouch from '../../../../../assets/images/illustrations/pouches/pouch_with_sparks--yellow.svg'

type AllowedAlignment = 'left' | 'right' | 'center'

type Props = {
  pouchSize: number
  pouches: number
  cadence: number
  alignment?: AllowedAlignment
  highlightedChanges?: boolean // Used on the SwapAndPage
}

const BoxBreakdown = ({
  pouchSize,
  pouches,
  cadence,
  alignment = 'center',
  highlightedChanges = false
}: Props): ReactElement => {
  const namespace = 'shared'

  const items: {
    key: 'dailyServing' | 'pouches' | 'cadence'
    image: string
    alt: string
    title: string | number
    variables?: { [key: string]: number }
    label: string
  }[] = [
    {
      key: 'dailyServing',
      image: Pouch,
      alt: 'box_breakdown.daily_serving_alt',
      title: 'box_breakdown.daily_serving',
      variables: {
        dailyServing: pouchSize
      },
      label: 'box_breakdown.daily_serving_label'
    },
    {
      key: 'pouches',
      image: Box,
      alt: 'box_breakdown.pouches_alt',
      title: pouches,
      label: 'box_breakdown.pouches_label'
    },
    {
      key: 'cadence',
      image: Calendar,
      alt: 'box_breakdown.cadence_alt',
      title: 'box_breakdown.cadence',
      variables: {
        cadence: cadence * 7
      },
      label: 'box_breakdown.cadence_label'
    }
  ]

  return (
    <div
      className={`${STYLES.boxBreakdown} ${
        alignment ? STYLES[`${alignment}Align`] : ''
      } ${highlightedChanges ? STYLES.highlightedChanges : ''} `}
    >
      {items.map((item, i) => (
        <div
          className={STYLES.boxBreakdownDetail}
          key={`box-breakdown-${item.key}-key`}
        >
          <div className={STYLES.boxBreakdownImage}>
            <img
              alt={item.alt}
              src={item.image}
              className={STYLES[`${item.key}BoxBreakdownImage`]}
            />
          </div>
          <Text
            text={
              typeof item.title === 'string'
                ? item.title
                : item.title.toString()
            }
            variant="display16"
            colour="brandBlue500"
            margin={false}
            translate={typeof item.title === 'string'}
            variables={item.variables}
            namespace={namespace}
          />
          <div className={STYLES.boxBreakdownLabel}>
            <Text
              text={item.label}
              namespace={namespace}
              variant="textRegular14"
              colour="brandBlue400"
              margin={false}
            />
          </div>
          {i < items.length - 1 &&
            alignment === 'center' &&
            !highlightedChanges && (
              <div className={STYLES.boxBreakdownSeparator} />
            )}
        </div>
      ))}
    </div>
  )
}

export { Props }
export default BoxBreakdown
