// @noflow
import { ACCOUNT_ROUTES } from '@/routes'
import { useReactiveVar } from '@apollo/client'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { SwiperClass } from 'swiper/react'

import { dogsDataVar } from '@/services/apollo'

import usePlan, { Plan } from '@/hooks/usePlan/usePlan'

import { Expand } from '@/components/elements/atoms/Animated/Animated'
import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'
import { FixedBase } from '@/components/elements/atoms/FixedBase'
import Pouch from '@/components/elements/atoms/Pouch/Pouch'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import SkeletonParagraph from '@/components/elements/atoms/SkeletonParagraph/SkeletonParagraph'
import SkeletonTitle from '@/components/elements/atoms/SkeletonTitle/SkeletonTitle'
import Text from '@/components/elements/atoms/Text/Text'
import Tooltip from '@/components/elements/atoms/Tooltip/Tooltip'
import CalorieBar from '@/components/elements/molecules/CalorieBar/CalorieBar'
import ExpandCard from '@/components/elements/molecules/ExpandCard/ExpandCard'
import SwiperSlider from '@/components/elements/molecules/SwiperSlider/SwiperSlider'

import STYLES from './ServingSizePageV3.module.sass'

import { Serving } from '@/types'

const namespace = 'account'

const ServingSizePageV3 = (): JSX.Element | null => {
  const copyContext = 'serving_size_v2'
  const { dogs } = useReactiveVar(dogsDataVar) || {}
  const { planId } = useParams()
  const { currentPlan, getPlan, dogPlans, updating } = usePlan()
  const { state } = useLocation()
  const [swiper, setSwiper] = useState<SwiperClass>()
  const [loading, setLoading] = useState<boolean>(true)
  const [swiperIndex, setSwiperIndex] = useState<number>()
  const [selectedPlan, setSelectedPlan] = useState<Plan>()
  const navigate = useNavigate()
  const { pronoun } = useReactiveVar(dogsDataVar) || {}
  const [displayingCurrentPlan, setDisplayingCurrentPlan] = useState<
    boolean | undefined
  >()
  const isSingleDog = dogPlans && dogPlans.length === 1

  const lowerCalorieBound =
    dogPlans && dogPlans[0]?.roundedDailyCaloriesLowerBoundary
  const upperCalorieBound =
    dogPlans && dogPlans[0]?.roundedDailyCaloriesUpperBoundary

  const halfCaloriesOfFirstDog =
    (dogPlans && dogPlans[0] && !isUndefined(dogPlans[0].roundedDailyCalories)
      ? dogPlans[0]?.roundedDailyCalories
      : 1000) / 2

  const onSelect = useCallback(() => {
    if (isUndefined(selectedPlan) || isUndefined(displayingCurrentPlan)) return
    if (displayingCurrentPlan) navigate(ACCOUNT_ROUTES.base)
    else navigate(`${ACCOUNT_ROUTES.reviewDailyPortion}/${selectedPlan.id}`)
  }, [displayingCurrentPlan, navigate, selectedPlan])

  useEffect(() => {
    if (!isUndefined(currentPlan) && !isUndefined(selectedPlan)) {
      setDisplayingCurrentPlan(currentPlan?.id === selectedPlan?.id)
    }
  }, [currentPlan, selectedPlan])

  useEffect(() => {
    if (!isUndefined(planId) && !isUndefined(getPlan)) {
      setSelectedPlan(getPlan(planId))
    }
  }, [getPlan, planId])

  useEffect(() => {
    if (!isUndefined(swiper)) {
      swiper.on('slideChange', (swiper) => {
        setSwiperIndex(swiper.activeIndex)
      })
    }
  }, [swiper])

  const { dailyServingSizeOptions, dailyServingSizeIndex } = usePlan()

  useEffect(() => {
    !isUndefined(swiperIndex) &&
      !isUndefined(dailyServingSizeOptions) &&
      setSelectedPlan(dailyServingSizeOptions[swiperIndex])
  }, [dailyServingSizeOptions, swiperIndex])

  // on load sync slider with the current plan
  useEffect(() => {
    if (
      !isUndefined(swiper) &&
      !isUndefined(dailyServingSizeIndex) &&
      !isUndefined(dailyServingSizeOptions) &&
      isUndefined(swiperIndex)
    ) {
      let initial = dailyServingSizeIndex

      // if returning from the review page then display the previously changed plan
      if (!isUndefined(state?.previous?.params?.planId)) {
        initial = dailyServingSizeOptions.findIndex(
          (plan) => plan.id === state?.previous?.params?.planId
        )
      }

      setSwiperIndex(initial)
      swiper.slideTo(initial, 0)
    }
  }, [
    dailyServingSizeIndex,
    dailyServingSizeOptions,
    state?.previous?.params?.planId,
    swiper,
    swiperIndex
  ])

  // manage loading state
  useEffect(() => {
    setLoading(
      isUndefined(dailyServingSizeIndex) ||
        isUndefined(dailyServingSizeOptions) ||
        isNull(dogs)
    )
  }, [dailyServingSizeIndex, dailyServingSizeOptions, dogs])

  if (loading || isUndefined(dailyServingSizeOptions))
    return (
      <div className={STYLES.container}>
        <SectionWrapper
          margin={{
            top: 32,
            bottom: 16
          }}
        >
          <Card
            variant={{
              border: 'transparent',
              background: 'brandYellow200',
              selected: 'transparent'
            }}
            padding={24}
          >
            <Pouch>
              <SkeletonTitle width={70} height={24} />
            </Pouch>
            {dogs &&
              dogs.length > 1 &&
              dogs.map((dog) => (
                <SectionWrapper
                  key={dog.id}
                  margin={{
                    top: 16,
                    bottom: 0
                  }}
                >
                  <SkeletonTitle width={220} height={16} align="left" />
                </SectionWrapper>
              ))}
            {dogs && dogs.length === 1 && (
              <SectionWrapper margin={{ top: 16, bottom: 0 }}>
                <SkeletonTitle width={120} height={20} align="left" />
                <SkeletonParagraph height={16} count={1} shortLine />
                <SectionWrapper margin={{ top: 16, bottom: 16 }}>
                  <CalorieBar />
                </SectionWrapper>
              </SectionWrapper>
            )}
          </Card>
        </SectionWrapper>
      </div>
    )

  return (
    <div className={STYLES.container}>
      <SectionWrapper
        margin={{
          top: 32,
          bottom: 16
        }}
      >
        <Card
          variant={{
            border: 'transparent',
            background: 'brandYellow200',
            selected: 'transparent'
          }}
          padding={24}
        >
          <div className={STYLES.slider}>
            <SwiperSlider
              slider={setSwiper}
              variant="brandYellow300"
              slidesPerView={1}
              initialSlide={dailyServingSizeIndex}
              arrows
            >
              {dailyServingSizeOptions?.map((option) => {
                const isStandardServing =
                  option.servingType === Serving.standard_serving

                return (
                  <Pouch key={option.gramsPerDay} double={!isStandardServing}>
                    <Text
                      text="serving_size.pouch.daily_grams"
                      variables={{ grams: option.servingSize }}
                      namespace={namespace}
                      variant="display24"
                      element="div"
                    />
                    {!isStandardServing && (
                      <Text
                        text="serving_size.pouch.multiple_pouches"
                        variables={{
                          grams: option.pouchSize,
                          count: 2
                        }}
                        namespace={namespace}
                        variant="textRegular14"
                        element="div"
                      />
                    )}
                  </Pouch>
                )
              })}
            </SwiperSlider>
          </div>
          {isSingleDog && (
            <SectionWrapper margin={{ top: 16, bottom: 0 }}>
              <Text
                variant="display16"
                namespace={namespace}
                text={`${copyContext}.single_dog.calorie_guidance.title`}
                margin={false}
              />
              <div>
                <Text
                  element="span"
                  namespace={namespace}
                  text={`${copyContext}.single_dog.calorie_guidance.text1`}
                  margin={false}
                  variables={{
                    dogName: dogPlans[0]?.name
                  }}
                />
                <Tooltip
                  label={{
                    element: 'span',
                    text: `${copyContext}.single_dog.calorie_guidance.text2`,
                    namespace
                  }}
                  size={16}
                  displayIcon={false}
                  identifier="calorie_guidance.tooltip"
                >
                  <Text
                    element="span"
                    namespace={namespace}
                    text={`${copyContext}.single_dog.calorie_guidance.tooltip`}
                    margin={false}
                  />
                </Tooltip>
                <Text
                  element="span"
                  namespace={namespace}
                  text={`${copyContext}.single_dog.calorie_guidance.text3`}
                  margin={false}
                  variables={{
                    min: lowerCalorieBound,
                    max: dogPlans[0]?.roundedDailyCaloriesUpperBoundary
                  }}
                />
              </div>
              {!isUndefined(lowerCalorieBound) &&
              !isUndefined(upperCalorieBound) ? (
                <SectionWrapper margin={{ top: 16, bottom: 16 }}>
                  <CalorieBar
                    lowerBound={lowerCalorieBound ?? 0}
                    upperBound={upperCalorieBound ?? 2000}
                    value={selectedPlan?.roundedCaloriesPerDay ?? 0}
                  />
                </SectionWrapper>
              ) : (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <></>
              )}
            </SectionWrapper>
          )}
          {selectedPlan &&
            selectedPlan.gramsPerDayPerDogs.length > 1 &&
            selectedPlan.gramsPerDayPerDogs.map((gramsPerDayPerDog) => {
              return (
                <SectionWrapper
                  key={gramsPerDayPerDog.dogId}
                  margin={{
                    top: 16,
                    bottom: 0
                  }}
                >
                  <Text
                    text={`${copyContext}.multiple_dogs.amount`}
                    namespace={namespace}
                    variables={{
                      dogName: gramsPerDayPerDog.name,
                      grams: gramsPerDayPerDog.gramsPerDay
                    }}
                    margin={false}
                  />
                </SectionWrapper>
              )
            })}
        </Card>
      </SectionWrapper>
      <SectionWrapper
        margin={{
          top: 0,
          bottom: 24
        }}
      >
        <ExpandCard
          text={{
            namespace,
            text: `${copyContext}.mix_feeding_card.label`,
            variant: 'textRegular18',
            bold: false
          }}
          shadow
          identifier="plan_management.serving_size.mix_feeding_card"
        >
          <Text
            namespace={namespace}
            text={`${copyContext}.mix_feeding_card.content`}
            variables={{
              context: pronoun,
              halfCal: halfCaloriesOfFirstDog,
              dogName: dogPlans && dogPlans[0]?.name
            }}
            margin={false}
          />
        </ExpandCard>
      </SectionWrapper>
      <FixedBase backgroundColor="brandWhite90">
        <div className={STYLES.button}>
          <Button
            disabled={updating || isUndefined(selectedPlan)}
            onClick={onSelect}
            typography={{
              text: `serving_size.button.${
                displayingCurrentPlan ? 'current' : 'update'
              }`,
              namespace,
              variables: {
                context: pronoun
              }
            }}
            identifier="change_serving_size.update"
          />
          <Expand
            show={!isUndefined(displayingCurrentPlan) && displayingCurrentPlan}
          >
            <div className={STYLES.noChange}>
              <Text
                text="serving_size.subtext.current"
                variables={{
                  context: pronoun
                }}
                namespace={namespace}
                element="div"
                align="center"
              />
            </div>
          </Expand>
        </div>
      </FixedBase>
    </div>
  )
}

export default ServingSizePageV3
