// @flow

import * as React from 'react'
import i18next from 'i18next'

import AlertCheckmark from '../../../../../../assets/images/icons/checkmarks/transparent-checkmark-green-bg.svg'

type Props = {|
  isVisible: boolean,
  onDismiss: () => void
|}

class Alert extends React.Component<Props> {
  namespace = 'ambassadors:dashboard.balance_tab'

  render (): React.Node {
    return (
      <div
        className={'ambassador-balance__bank-details__delete-alert ' + (this.props.isVisible ? 'ambassador-balance__bank-details__delete-alert--visible' : '')}>
        <img
          src={AlertCheckmark}
          alt="green checkmark" />

        <p>{ i18next.t(`${this.namespace}.bank_account_deleted`) }</p>
        <button
          className="btn btn-updated--blue"
          onClick={this.props.onDismiss}>

          { i18next.t(`${this.namespace}.great`) }
        </button>
      </div>
    )
  }
}

export default Alert
