// @noflow
import { gql } from '@apollo/client'

import { SUBSCRIPTION_OVERVIEW_FRAGMENT } from '@/components/elements/molecules/SubscriptionOverview/fragments/subscriptionOverviewFragment'

const DASHBOARD_QUERY = gql`
  ${SUBSCRIPTION_OVERVIEW_FRAGMENT}
  query DashboardV3Query {
    user {
      id
      ...SubscriptionOverviewFragment
    }
  }
`

export { DASHBOARD_QUERY }
