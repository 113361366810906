// @noflow
// Language support
import type { Language } from '@/packs/localisation'
import initI18n from '@/packs/localisation'
import i18next from 'i18next'
import React from 'react'

import Text from '@/components/elements/atoms/Text/Text'
import type { Props as TextProps } from '@/components/elements/atoms/Text/Text'

import STYLES from './LoadingScreen.module.sass'

type LoadingScreenTextProps = Omit<TextProps, 'variant'> & {
  variant?: 'display28' | 'display20'
}

type Props = {
  variant?: keyof typeof STYLES
  isOpen: boolean
  title?: LoadingScreenTextProps
  image?: string
  imageAlt?: string
  sprite?: string
  language?: Language
  children?: JSX.Element | Array<JSX.Element>
  padding?: boolean
}

const LoadingScreen = ({
  variant,
  isOpen = true,
  title,
  image,
  imageAlt = '',
  sprite,
  language = 'en',
  children,
  padding = true
}: Props): JSX.Element => {
  if (!i18next.isInitialized) initI18n(language)

  return (
    <div
      aria-label="Loading Screen"
      className={`${STYLES.container} ${isOpen ? '' : STYLES.hidden} ${
        variant ? STYLES[variant] : ''
      } ${padding ? STYLES.padding : ''}`}
    >
      {children ? (
        <div>{children}</div>
      ) : (
        <>
          {variant === 'static' && image && <img alt={imageAlt} src={image} />}
          {variant === 'animated' && sprite && (
            <div className={STYLES.spriteContainer}>
              <img alt={imageAlt} src={sprite} />
            </div>
          )}
          {title && (
            <Text
              variant={title.variant || 'display24'}
              namespace={title.namespace}
              text={title.text}
              variables={title.variables}
              translate={title.namespace !== undefined}
              align={title.align}
            />
          )}
        </>
      )}
    </div>
  )
}

export default LoadingScreen
export { Props }
