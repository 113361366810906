import { gql } from '@apollo/client'

const DELIVERY_DATES_QUERY = gql`
  query DeliveryDates($postcode: String!, $city: String!) {
    defaultSelectedDate(
      postcode: $postcode
      city: $city
      shouldOfferSundayAsDefault: true
    ) {
      date
      deliverable
      endOfLeadTime
    }
    standardDeliveryFee
  }
`

export { DELIVERY_DATES_QUERY }
