// @noflow
import React, { useCallback, useState } from 'react'

import { SimpleNavHero } from '@/components/elements/atoms/SimpleNav/SimpleNav'
import CancelPlanUpdateWarningModal from '@/components/pages/PlansPage/components/CancelPlanUpdateWarningModal/CancelPlanUpdateWarningModal'

type Props = {
  backUrl: string | (() => void)
}

const TopNavigation = ({ backUrl }: Props): JSX.Element => {
  const [navigatingAway, setNavigatingAway] = useState<boolean>(false)
  const onClose = useCallback(() => setNavigatingAway(true), [])

  return (
    <>
      <CancelPlanUpdateWarningModal
        open={navigatingAway}
        toggle={setNavigatingAway}
      />
      <SimpleNavHero back={backUrl} close={onClose} />
    </>
  )
}

export default TopNavigation
