// @flow

import * as React from 'react'
import i18next from 'i18next'

import Alert from './Alert'

import PencilIcon from '../../../../../../assets/images/icons/pencils/pencil-blue-filled.svg'
import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'

type Props = {|
  shippingCountryCode: CountryCode,
  onAlertDismiss: () => void,
  deleteAlertIsVisible: boolean,
  startEditing: () => void
|}

class EmptyBankAccount extends React.Component<Props> {
  namespace = 'ambassadors:dashboard.balance_tab'

  render (): React.Node {
    return (
      <div>
        <div className='card__header full--width'>
          <p className='card__header__title'>{ i18next.t(`${this.namespace}.account_details`) }</p>
          <button
            className='card__header__button btn-updated btn-updated--grey'
            onClick={this.props.startEditing}
          >
            <img src={PencilIcon} alt='A pencil icon indicating edit functionality' />
            { i18next.t(`${this.namespace}.edit`) }
          </button>
        </div>

        <div className='card__item'>
          <p className='card__item__heading'>{ i18next.t(`${this.namespace}.account_holder`) }</p>
          <div className='card__item ambassador-balance__bank-details__content__input'>
            <input
              onClick={this.props.startEditing}
              placeholder='e.g. Jane Smith'
              readOnly
              type='text'
            />
          </div>
        </div>
        { (this.props.shippingCountryCode === 'GB' || this.props.shippingCountryCode === 'NI') && (
          <React.Fragment>
            <div className='card__item'>
              <p className='card__item__heading'>Account number</p>
              <div className='card__item ambassador-balance__bank-details__content__input'>
                <input
                  onClick={this.props.startEditing}
                  placeholder='8 digit account number'
                  readOnly
                  type='text'
                />
              </div>
            </div>

            <div className='card__item'>
              <p className='card__item__heading'>Bank sort code</p>
              <div className='card__item ambassador-balance__bank-details__content__input'>
                <input
                  onClick={this.props.startEditing}
                  placeholder='6 digit number e.g 12-34-56'
                  readOnly
                  type='text'
                />
              </div>
            </div>
          </React.Fragment>
        ) }

        { (this.props.shippingCountryCode !== 'GB' && this.props.shippingCountryCode !== 'NI') && (
          <React.Fragment>
            <div className='card__item'>
              <p className='card__item__heading'>IBAN</p>
              <div className='card__item ambassador-balance__bank-details__content__input'>
                <input
                  onClick={this.props.startEditing}
                  placeholder='IBAN'
                  readOnly
                  type='text'
                />
              </div>
            </div>

            <div className='card__item'>
              <p className='card__item__heading'>Swift code</p>
              <div className='card__item ambassador-balance__bank-details__content__input'>
                <input
                  onClick={this.props.startEditing}
                  placeholder='Swift code'
                  readOnly
                  type='text'
                />
              </div>
            </div>
          </React.Fragment>
        ) }
        <Alert
          isVisible={this.props.deleteAlertIsVisible}
          onDismiss={this.props.onAlertDismiss}
        />
      </div>
    )
  }
}

export default EmptyBankAccount
