// @noflow
// Utilities
import { PATHNAMES } from '@/constants/urls'

// Types
import type { Code as CountryCode } from '@/types'

const getLocalisedPathnames = (
  countryCode?: CountryCode
): Record<string, string> => PATHNAMES[countryCode ?? 'GB']

export { getLocalisedPathnames }
