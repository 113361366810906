// @noflow
import React from 'react'

import {
  ICON_COLOURS,
  IconColours
} from '@/components/elements/atoms/Icon/Icon'

type Props = {
  size: number
  accentColour: IconColours
  backgroundColour?: IconColours
  width?: number | string
}

const Receipt = ({
  size,
  accentColour,
  backgroundColour,
  width
}: Props): JSX.Element => {
  return (
    <svg
      width={width}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.344 2.454c-.002.013-.046.024-.129.032-.427.167-.776.87-.923 1.202-.344 1.204-1.006 4.857-1.006 6.11 0 5.095-.083 10.21-.083 15.292l-1.79 1.95a.5.5 0 0 1-.721.015l-1.778-1.777a.5.5 0 0 0-.707 0l-1.293 1.293a.5.5 0 0 1-.707 0l-1.33-1.33a.5.5 0 0 0-.666-.037l-1.838 1.47a.5.5 0 0 1-.625 0l-1.938-1.55a.5.5 0 0 0-.535-.056l-1.99.995a.508.508 0 0 1-.734-.446c-.04-2.21-.201-6.897-.3-8.224C3.177 16.377 3 15.42 3 14.39V8.087c0-.756.165-1.503.165-2.259 0-.454.164-.913.164-1.358 0-.523.12-1.311.476-1.705.21-.235.158-.508.47-.682.287-.159.863-.041 1.194-.041.885 0 15.234.602 16.746.444a.52.52 0 0 1 .129-.032Z"
        fill={backgroundColour ? ICON_COLOURS[backgroundColour] : '#fff'}
      />
      <path
        d="M20.344 1.454C19 1.424 3.5.5 3 1c-1 .5-1.67 1.947-1.67 2.47 0 .445-.165.904-.165 1.358 0 .756-.165 1.503-.165 2.26v6.301c0 1.03.176 1.987.252 3.005.098 1.326.259 6.013.3 8.223.006.371.4.612.732.446l1.99-.995a.5.5 0 0 1 .536.056l1.938 1.55a.5.5 0 0 0 .625 0l1.838-1.47a.5.5 0 0 1 .666.037l1.33 1.33a.5.5 0 0 0 .707 0l1.293-1.293a.5.5 0 0 1 .707 0l1.778 1.777a.5.5 0 0 0 .722-.015l1.79-1.95c0-5.082.082-10.197.082-15.292 0-1.253.662-4.906 1.006-6.11.162-.364.566-1.177 1.052-1.234Zm0 0c.849-.1 1.5-.184 1.893.7.336.755.658 1.45.658 2.304v2.14c0 .481-1.465.288-1.852.288h-2.181M3.595 6.618h1.782M4.35 10.832h1.643M8.56 10.924h7.001M8.56 6.924h4"
        stroke={ICON_COLOURS[accentColour]}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.525 14.836h.82c.072 0 .231-.03.263.035M8.56 14.924h6M4.386 18.924h1.432M8.56 18.924h7.001"
        stroke={ICON_COLOURS[accentColour]}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default Receipt
