// Componenets
import React from 'react'

import STYLES from '../InputsScreen.module.sass'

import {
  CustomerIssueReportCreate_response_categoryReasons as CategoryReason,
  CustomerIssueReportCreate_response_inputFields as InputField
} from '../../../mutations/__generated__/CustomerIssueReportCreate'
import { CustomerIssueManagementInputInput } from '@/types'

import Input from './Input'

type InputSectionProps = {
  inputFields: Array<InputField>
  categoryReasons?: Array<CategoryReason> | null
  reason: string
  updateInputs: (
    fieldId: string,
    newInputs: Array<CustomerIssueManagementInputInput>
  ) => void
  setReason: (newReason: string) => void
}

const InputSection = ({
  inputFields,
  categoryReasons,
  reason,
  updateInputs,
  setReason
}: InputSectionProps): JSX.Element => {
  return (
    <div className={STYLES.inputSection}>
      {categoryReasons && (
        <Input
          categoryReasons={categoryReasons}
          reason={reason}
          setReason={setReason}
          updateInputs={updateInputs}
        />
      )}
      {inputFields &&
        inputFields.map((inputField: InputField): JSX.Element => {
          return (
            <Input
              key={inputField.id}
              inputField={inputField}
              reason={reason}
              setReason={setReason}
              updateInputs={updateInputs}
            />
          )
        })}
    </div>
  )
}

export default InputSection
