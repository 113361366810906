// @noflow
import React from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'

import CompleteScreen from '../screens/CompleteScreen/CompleteScreen'
import PaymentScreen from '../screens/PaymentScreen/PaymentScreen'
import SummaryScreen from '../screens/Summary/SummaryScreen'

type RoutePath =
  | '/subscription/complete_direct_sales_payment/summary'
  | '/subscription/complete_direct_sales_payment/payment'
  | '/subscription/complete_direct_sales_payment/complete'

const SummaryScreenRoute: RoutePath =
  '/subscription/complete_direct_sales_payment/summary'
const PaymentScreenRoute: RoutePath =
  '/subscription/complete_direct_sales_payment/payment'
const CompleteScreenRoute: RoutePath =
  '/subscription/complete_direct_sales_payment/complete'

const PayOnOwnDevicePageRoutes = {
  summaryScreen: SummaryScreenRoute,
  paymentScreen: PaymentScreenRoute,
  completeScreen: CompleteScreenRoute
}

const PayOnOwnDevicePageRoutesWrapper = ({
  namespace
}: {
  namespace: string
}): JSX.Element | null => {
  return (
    <Routes>
      <Route
        path={SummaryScreenRoute}
        element={<SummaryScreen namespace={namespace} />}
      />
      <Route
        path={PaymentScreenRoute}
        element={<PaymentScreen namespace={namespace} />}
      />
      <Route
        path={CompleteScreenRoute}
        element={<CompleteScreen namespace={namespace} />}
      />
    </Routes>
  )
}

const RouterWrapper = ({ namespace }: { namespace: string }): JSX.Element => (
  <Router>
    <PayOnOwnDevicePageRoutesWrapper namespace={namespace} />
  </Router>
)

export type { RoutePath }

export { PayOnOwnDevicePageRoutes }

export default RouterWrapper
