// @noflow
import isUndefined from 'lodash/isUndefined'
import React, { useCallback, useRef, useState } from 'react'

import { PronounContext } from '@/utils/StringHelper'

import ProductThumb from '@/components/elements/atoms/ProductThumb/ProductThumb'
import Text from '@/components/elements/atoms/Text/Text'
import Toggle from '@/components/elements/atoms/Toggle/Toggle'

import STYLES from './RecipeSelectionToggle.module.sass'

type Recipe = {
  themeColour: string
  name: string
  numberOfPouches: number
  pouchSize: number
  thumbnail?: string
}

type Props = {
  recipes: Array<Recipe>
  dogPronoun: PronounContext
  namespace: string
}

const RecipeSelectionToggle = ({
  recipes,
  dogPronoun,
  namespace
}: Props): JSX.Element => {
  const copyContext = 'plan_steps.review'

  const [toggleOpen, setToggleOpen] = useState(false)
  const [recipeSelectionHeight, setRecipeSelectionHeight] = useState(0)
  const content = useRef<HTMLDivElement>(null)

  const toggleToggle = useCallback(() => {
    setToggleOpen(!toggleOpen)

    if (!toggleOpen && content.current) {
      setRecipeSelectionHeight(content.current.scrollHeight)
    } else {
      setRecipeSelectionHeight(0)
    }
  }, [toggleOpen])

  const numberOfServings = recipes.reduce(
    (total: number, { numberOfPouches }: Recipe) => total + numberOfPouches,
    0
  )

  return (
    <React.Fragment>
      <div
        ref={content}
        className={STYLES.recipeSelection}
        style={{ maxHeight: `${recipeSelectionHeight}px` }}
      >
        <div className={STYLES.recipeSelectionTitle}>
          <Text
            text={`${copyContext}.box_card.recipe_selection.title`}
            namespace={namespace}
            variant="display16"
            colour="brandBlue500"
            element="h4"
            shouldScale
          />
          <Text
            text={`${copyContext}.box_card.recipe_selection.number_of_servings`}
            variables={{ numberOfServings }}
            namespace={namespace}
            variant="textRegular16"
            colour="brandBlue500"
            element="span"
            shouldScale
          />
        </div>
        <div>
          {recipes.map(({ name, pouchSize, numberOfPouches, thumbnail }) => {
            return (
              <div className={STYLES.recipeSelectionRecipe} key={name}>
                {!isUndefined(thumbnail) && (
                  <ProductThumb image={thumbnail} alt={''} />
                )}
                <Text
                  text={name}
                  variant="textRegular16"
                  colour="brandBlue500"
                  margin={false}
                  translate={false}
                />
                <div>
                  <Text
                    text={`${copyContext}.box_card.recipe_selection.recipe_servings`}
                    variables={{
                      numberOfServings: numberOfPouches,
                      count: numberOfPouches
                    }}
                    namespace={namespace}
                    variant="textRegular14"
                    colour="brandBlue400"
                    margin={false}
                    shouldScale
                  />
                  <Text
                    text={`${copyContext}.box_card.recipe_selection.pouch_size`}
                    variables={{ pouchSize }}
                    namespace={namespace}
                    variant="textRegular14"
                    colour="brandBlue400"
                    margin={false}
                    shouldScale
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className={STYLES.recipeSelectionToggle}>
        <Toggle
          onClick={toggleToggle}
          variables={{ context: dogPronoun }}
          text={
            toggleOpen
              ? `${copyContext}.box_card.recipe_selection.toggle_open`
              : `${copyContext}.box_card.recipe_selection.toggle_closed`
          }
          namespace={namespace}
          toggleOpen={toggleOpen}
        />
      </div>
    </React.Fragment>
  )
}

export { Props }
export default RecipeSelectionToggle
