// @noflow
import type { ApolloError } from '@apollo/client'
import React, { Fragment, createContext, useMemo, useState } from 'react'
import type { Dispatch, ReactElement, SetStateAction } from 'react'

import NoDogsStateContent from './components/NoDogsStateContent'
import DashboardFooter from '@/components/elements/organisms/DashboardFooter/DashboardFooter'
import DashboardNavigation from '@/components/elements/organisms/DashboardNavigation/DashboardNavigation'

import RouterWrapper from './routes/RouterWrapper'

type NoDogsPageContextType = {
  error: ApolloError | undefined
  setError: Dispatch<SetStateAction<ApolloError | undefined>>
}

const NoDogsPageContext = createContext<NoDogsPageContextType>({
  error: undefined,
  setError: (x) => x
})

const NoDogsPage = (): ReactElement => {
  const [error, setError] = useState<ApolloError | undefined>(undefined)
  const contextValue = useMemo(
    () => ({
      error,
      setError
    }),
    [error, setError]
  )

  return (
    <NoDogsPageContext.Provider value={contextValue}>
      <RouterWrapper>
        <Fragment>
          <DashboardNavigation showSecondaryNav={false} />
          <NoDogsStateContent />
          <DashboardFooter />
        </Fragment>
      </RouterWrapper>
    </NoDogsPageContext.Provider>
  )
}

export type { NoDogsPageContextType }

export { NoDogsPageContext }

export default NoDogsPage
