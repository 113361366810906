// @flow

import type { RailsModelID } from '@/shared_types/ids'
import type { CSRFToken } from '@/shared_types/csrfToken'
import type { ServerError, UnknownError } from '@/redux/graphql_queries/errorTypes'

type Success = {|
    type: 'Success',
    errorMessage: string,
    success: boolean
|}

type RequestResult =
    | Success
    | ServerError
    | UnknownError

const { fetch } = global

const sendRafDetailsSms = async ({
  userId,
  csrfToken
}: {|
    userId: RailsModelID,
    csrfToken: CSRFToken
|}): Promise<RequestResult> => {
  const endpoint = '/graphql'
  const method = 'POST'
  const credentials = 'same-origin'
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  }

  const graphQLQuery = `
    mutation($userId: ID!) {
      sendRafSmsFromSoc(userId: $userId) {
        success
        errorMessage      
      }
    }
  `

  const body = JSON.stringify({
    query: graphQLQuery,
    variables: {
      userId
    }
  })

  const res = await fetch(endpoint, { headers, method, credentials, body })

  if (!res.ok) {
    const message = await res.text()

    return {
      type: 'ServerError',
      status: res.status,
      message
    }
  }

  const { data, errors } = await res.json()

  if (data) {
    const { sendRafSmsFromSoc } = data
    const { errorMessage, success } = sendRafSmsFromSoc

    return {
      type: 'Success',
      errorMessage,
      success
    }
  } else if (errors) {
    return {
      type: 'ServerError',
      status: res.status,
      message: res.text()
    }
  } else {
    return {
      type: 'UnknownError',
      error: 'Unknown Error'
    }
  }
}

export type {
  RequestResult
}

export default sendRafDetailsSms
