import { CustomerIssueReportEntryInputInput as ReportIssueInput } from '@types'
import React, { useCallback, useEffect, useState } from 'react'

import STYLES from '../ReportIssue.module.sass'

import {
  CustomerIssueCategorySubmission_response_fields_CustomerIssueEntryFieldMultifield_fields_CustomerIssueEntryFieldAssociation as MultifieldAssociation,
  CustomerIssueCategorySubmission_response_fields_CustomerIssueEntryFieldMultifield_fields as MultifieldEntryField,
  CustomerIssueCategorySubmission_response_fields_CustomerIssueEntryFieldMultifield_fields_CustomerIssueEntryFieldInteger as MultifieldInteger
} from '../../../mutations/__generated__/CustomerIssueCategorySubmission'
import { CustomerIssueReportEntriesSubmissionVariables } from '../../../mutations/__generated__/CustomerIssueReportEntriesSubmission'
import { SelfResolutionCategories_user_subscription_box_potentialMissingItems as PotentialMissingItem } from '../../../queries/__generated__/SelfResolutionCategories'

import useSelfResolutionTracking from '../../../analytics'
import ProductAmounts from './ProductAmounts'
import ProductSelector from './ProductSelector'

type Props = {
  userId: string
  reportId: string
  fieldId: string
  namespace: string
  potentialMissingItems: Array<PotentialMissingItem>
  entryFields: Array<MultifieldEntryField>
  reportIssue: (
    variables: CustomerIssueReportEntriesSubmissionVariables,
    affectedItems?: Array<PotentialMissingItem>
  ) => void
}

const ItemSelector = ({
  userId,
  reportId,
  fieldId,
  namespace,
  potentialMissingItems,
  entryFields,
  reportIssue
}: Props): JSX.Element => {
  const [selectedItems, setSelectedItems] = useState<
    Array<PotentialMissingItem>
  >([])
  const [pageState, setPageState] = useState<'selecting' | 'amounts'>(
    'selecting'
  )
  const [selectorQuestion, setSelectorQuestion] =
    useState<MultifieldAssociation | null>(null)
  const [amountsQuestion, setAmountsQuestion] =
    useState<MultifieldInteger | null>(null)
  const selfResolutionTracking = useSelfResolutionTracking()

  useEffect(() => {
    const selectorEntryField =
      entryFields.find((field) => field.dataType === 'association') || null
    setSelectorQuestion(selectorEntryField as MultifieldAssociation)
    const amountsEntryField =
      entryFields.find((field) => field.dataType === 'integer') || null
    setAmountsQuestion(amountsEntryField as MultifieldInteger)
  }, [entryFields])

  const onSelected = useCallback(
    (potentialMissingItems: Array<PotentialMissingItem>) => {
      setSelectedItems(potentialMissingItems)
      setPageState('amounts')
    },
    []
  )

  const onSubmit = useCallback(
    (values: Array<Array<ReportIssueInput>>) => {
      const variables: CustomerIssueReportEntriesSubmissionVariables = {
        userId: userId,
        reportId: reportId,
        entryFieldInputs: values.map((value): ReportIssueInput => {
          return {
            fieldId,
            entryFieldInputs: value
          }
        }),
        reasonId: ''
      }
      const affectedItems = selectedItems
        .map((item): PotentialMissingItem => {
          const field = values.find(
            (value) => value[0].associationValue === item.productId
          ) as Array<ReportIssueInput>
          const quantity = field[1].integerValue
          const newItem = {
            ...item,
            quantity: quantity as number
          }
          return newItem
        })
        .filter((item) => item.quantity > 0)
      const simplifiedItemsForTracking = affectedItems.map((item) => {
        return { product: item.name, quantity: item.quantity }
      })
      selfResolutionTracking.trackEvent('reported products', {
        selectedItems: simplifiedItemsForTracking
      })
      reportIssue(variables, affectedItems)
    },
    [
      selfResolutionTracking,
      reportIssue,
      selectedItems,
      reportId,
      userId,
      fieldId
    ]
  )

  useEffect(() => {
    selfResolutionTracking.stepLoaded('Select items', 'Report Issue')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={`${STYLES.container}`}>
      {pageState === 'selecting' && (
        <ProductSelector
          namespace={namespace}
          potentialMissingItems={potentialMissingItems}
          onSelected={onSelected}
          question={selectorQuestion?.name as string}
        />
      )}
      {pageState === 'amounts' && (
        <ProductAmounts
          namespace={namespace}
          selectedItems={selectedItems}
          question={amountsQuestion?.name as string}
          onSubmit={onSubmit}
          associationId={selectorQuestion?.id as string}
          integerId={amountsQuestion?.id as string}
        />
      )}
    </div>
  )
}

export { Props }
export default ItemSelector
