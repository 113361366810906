// @noflow
import { useCallback } from 'react'

import type { Locale } from '@/utils/countryCodeHelper'
import { formatCurrencyWithoutDecimal } from '@/utils/currency'
import { percentageValue } from '@/utils/percentage'

import { ReferAFriendPageQuery_user_subscription_referralLink as ReferralLink } from '../queries/__generated__/ReferAFriendPageQuery'
import type { Currency } from '@/shared_types/rails_models/shipping_countries'
import {
  ApplicableDiscountPartDiscountBasis,
  DiscountCodePartDiscountBasis
} from '@/types'

type DiscountCopy = {
  copy: string
  value: number
  basis: ApplicableDiscountPartDiscountBasis | DiscountCodePartDiscountBasis
  boxNumber?: number
}

type UseDiscountCopy = {
  isAltruistic: boolean
  referrerDiscount: DiscountCopy
  referralDiscount: Array<DiscountCopy>
  isMultiDiscount: boolean
  discountCode: string
}

const useDiscountCopy = (
  referralLink: ReferralLink,
  locale: Locale,
  currency: Currency
): UseDiscountCopy => {
  const { referralDiscount, referrerDiscount } = referralLink
  const { discountCodeParts, code } = referralDiscount
  const { applicableDiscountParts } = referrerDiscount || {}

  // Referral Discount Parts
  const {
    value: firstPartDiscountValue,
    discountBasis: firstPartDiscountBasis
  } = discountCodeParts[0]
  const {
    value: secondPartDiscountValue,
    discountBasis: secondPartDiscountBasis
  } = discountCodeParts[1] || []

  // Referrer Discount Parts
  const {
    value: applicableDiscountValue,
    discountBasis: referrerDiscountBasis
  } = (applicableDiscountParts || [])[0]

  const getValueCopy = useCallback(
    (discountBasis: DiscountCopy['basis'], discountValue: number) => {
      return discountBasis === DiscountCodePartDiscountBasis.total
        ? formatCurrencyWithoutDecimal(discountValue, {
            locale,
            currency
          })
        : percentageValue(discountValue)
    },
    [currency, locale]
  )

  return {
    isAltruistic: applicableDiscountValue === 0,
    isMultiDiscount: discountCodeParts.length > 1,
    referrerDiscount: {
      copy: getValueCopy(referrerDiscountBasis, applicableDiscountValue),
      value: applicableDiscountValue,
      basis: referrerDiscountBasis
    },
    referralDiscount: [
      {
        copy: getValueCopy(firstPartDiscountBasis, firstPartDiscountValue),
        value: firstPartDiscountValue,
        basis: firstPartDiscountBasis,
        boxNumber: 1
      },
      {
        copy: getValueCopy(secondPartDiscountBasis, secondPartDiscountValue),
        value: secondPartDiscountValue,
        basis: secondPartDiscountBasis,
        boxNumber: 2
      }
    ],
    discountCode: code
  }
}

export default useDiscountCopy
