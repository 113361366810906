/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames'
import React, { useCallback } from 'react'

import { trackEvent } from '@/services/segment'

import { useModal } from '@/hooks/useModal'

import Info from 'assets/images/icons/info/tooltip-info.svg'

import Interactive from '@/components/elements/atoms/Interactive/Interactive'
import Text, { TextProps } from '@/components/elements/atoms/Text'

import STYLES from './trigger.module.sass'

type Props = {
  align?: false | 'center'
  text: TextProps
  modalOptions?: Record<string, unknown | undefined>
  modalId: string
  rightAdornment?: () => React.ReactNode
  backgroundColor?: 'transparent' | 'brandYellow100'
  className?: string
  screenIdentifier?: string
}

type ContentProps = { classname: string } & Pick<
  Props,
  'rightAdornment' | 'text'
>

/**
 * Renders the inner content of the `ModalTrigger` component, including the text and optional adornment.
 *
 * @param {string} classname - The CSS class applied to the main container element.
 * @param {TextProps} text - Props for configuring the `Text` component rendered inside the content.
 * @param {() => React.ReactNode} [rightAdornment] - Optional function to render an adornment on the right-hand side of the content.
 *
 * @returns {JSX.Element} The rendered content element with text and optional adornments.
 *
 * @example
 * // Content with adornment
 * <Content
 *   classname="flex-container"
 *   text={{ children: "Open Modal" }}
 *   rightAdornment={() => <Icon name="arrow" />}
 * />
 *
 * @example
 * // Content without adornment
 * <Content
 *   classname="flex-container"
 *   text={{ children: "Details" }}
 * />
 */
const Content = ({
  rightAdornment,
  text,
  classname
}: ContentProps): JSX.Element => {
  if (rightAdornment) {
    return (
      <div className={STYLES.adornmentContainer}>
        <div className={classname}>
          <img alt="" src={Info} />
          <div className={STYLES.textWrapper}>
            <Text variant="display16" {...text} />
          </div>
        </div>

        {rightAdornment()}
      </div>
    )
  }

  return (
    <div className={classname}>
      <img alt="" src={Info} />
      <div className={STYLES.textWrapper}>
        <Text variant="display16" {...text} />
      </div>
    </div>
  )
}

/**
 * Renders an interactive button element that opens a modal on click.
 * It supports text, optional adornments, configurable styles, and event tracking.
 *
 * @param {string} modalId - The unique identifier for the modal to open.
 * @param {'transparent' | 'brandYellow100'} [backgroundColor='transparent'] - The background color style for the button.
 * @param {TextProps} text - Props for configuring the `Text` component rendered inside the button.
 * @param {() => React.ReactNode} [rightAdornment] - Optional function to render a right adornment inside the button.
 * @param {Record<string, unknown | undefined>} [modalOptions] - Additional options to configure the modal.
 * @param {false | 'center'} [align = false] - Determines the alignment of the content. Only works with no right adornment. Use `'center'` to center-align.
 * @param {string} [className] - Additional class names for the root element for custom styling.
 * @param {string} [screenIdentifier] - Optional identifier for the screen or context where the component is used, for event tracking.
 *
 * @returns {JSX.Element} The rendered button element that triggers the modal.
 *
 * @example
 * // Basic usage
 * <ModalTrigger
 *   modalId="exampleModal"
 *   text={{ children: "Open Modal" }}
 * />
 *
 * @example
 * // With adornments and screen tracking
 * <ModalTrigger
 *   modalId="exampleModal"
 *   text={{ children: "Details" }}
 *   rightAdornment={() => <Icon name="arrow" />}
 *   backgroundColor="brandYellow100"
 *   screenIdentifier="homeScreen"
 * />
 *
 * @example
 * // Passing custom modal options
 * <ModalTrigger
 *   modalId="exampleModal"
 *   modalOptions={{ data: { user: { id: 1234, name: 'Bob' }} }}
 *   text={{ children: "Show Info" }}
 * />
 */
const ModalTrigger = ({
  modalId,
  backgroundColor = 'transparent',
  text,
  rightAdornment,
  modalOptions,
  align = false,
  className,
  screenIdentifier
}: Props): JSX.Element => {
  const { openModal } = useModal(modalId, modalOptions)

  const interactiveClass = classNames(
    STYLES.interactive,
    className,
    STYLES[backgroundColor]
  )

  const classname = classNames(STYLES.flexContainer, {
    [STYLES.center]: align === 'center'
  })

  const handleClick = useCallback(() => {
    trackEvent('Modal Trigger Clicked', {
      component_identifier: modalId,
      ...(screenIdentifier ? { screen_identifier: screenIdentifier } : {})
    })

    openModal()
  }, [modalId, openModal, screenIdentifier])

  return (
    <Interactive
      className={interactiveClass}
      element="button"
      onClick={handleClick}
    >
      <Content
        classname={classname}
        text={text}
        rightAdornment={rightAdornment}
      />
    </Interactive>
  )
}

export default ModalTrigger
