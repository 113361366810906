// @noflow
import React from 'react'

import Pill from '@/components/elements/atoms/Pill/Pill'
import type { Props as PillProps } from '@/components/elements/atoms/Pill/Pill'
import {
  PillSkeleton,
  Props as PillSkeletonProps
} from '@/components/elements/atoms/Pill/PillSkeleton'

import STYLES from './PillGroup.module.sass'

// Set the PillOption name value to be the same for each pill if you wish to
// group them into one question

type Skeleton = {
  isLoading: boolean
} & PillSkeletonProps

type Props = {
  pillOptions: Array<PillProps>
  skeleton?: Skeleton
  justifyContent?: 'center' | 'flex-start' | 'flex-end'
  version?: PillProps['version']
}

const PillGroup = ({
  pillOptions,
  skeleton,
  justifyContent = 'center',
  version
}: Props): JSX.Element => {
  if (skeleton?.isLoading) {
    return (
      <div className={STYLES.loading}>
        <PillSkeleton count={skeleton.count ?? 1} />
      </div>
    )
  }

  return (
    <div className={`${STYLES.container}`} style={{ justifyContent }}>
      {pillOptions.map((pillOption) => (
        <Pill key={pillOption.id} {...pillOption} version={version} />
      ))}
    </div>
  )
}

export { Props }
export default PillGroup
