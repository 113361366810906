import { gql } from '@apollo/client'

import {
  BOX_ORDER_FRAGMENT,
  NON_CORE_ORDER_FRAGMENT
} from '@/components/pages/Dashboard/components/upcomingBoxes/queries/fragments'

const SUBSCRIPTION_UNSUSPENSION_ATTEMPT_MUTATION = gql`
  ${BOX_ORDER_FRAGMENT}
  ${NON_CORE_ORDER_FRAGMENT}
  mutation SubscriptionUnsuspensionAttemptMutation(
    $offset: Int!
    $limit: Int!
    $nextNBoxes: Int!
  ) {
    subscriptionUnsuspensionAttempt {
      id
      ordersByDate(offset: $offset, limit: $limit) {
        ...upcomingBoxOrderFragment
        ...nonCoreUpcomingOrderFragment
      }
      subscription {
        id
        status
        suspendedForInsufficientFunds
        increasedDeliveryCadencePlan {
          id
        }
        nextNBoxes: nextNBoxes(num: $nextNBoxes) {
          id
          editable
          cutOffDate
        }
      }
    }
  }
`

export { SUBSCRIPTION_UNSUSPENSION_ATTEMPT_MUTATION }
