// @noflow
import { differenceInMinutes, isTomorrow, parseISO } from 'date-fns'
import React from 'react'

import * as ANALYTICS from '@/components/pages/CheckoutPage/Analytics/CheckoutAnalytics'
import { checkoutPageState } from '@/components/pages/CheckoutPage/CheckoutPage'

type UseCountdownBanner = {
  showCountdownBanner: boolean
}

const useCountdownBanner = (): UseCountdownBanner => {
  const { user } = checkoutPageState()
  const { firstDeliverableDate } = user
  const { date, endOfLeadTime } = firstDeliverableDate

  // Determines if the endOfLeadTime qualifies for the Countdown banner to be shown
  const shouldSeeCountdown =
    differenceInMinutes(parseISO(endOfLeadTime), new Date()) >= 5 && // If they have more than 5 minutes left before they reach the next day cut off
    isTomorrow(parseISO(date)) // And is the firstDeliverableDate Tomorrow (i.e Next Day delivery)

  React.useEffect(() => {
    if (isTomorrow(parseISO(date))) {
      ANALYTICS.offerNextDayDelivery()
    }
  }, [date])

  const showCountdownBanner = shouldSeeCountdown

  return {
    showCountdownBanner
  }
}

export default useCountdownBanner
