import { gql } from '@apollo/client'

const PAYMENT_METHODS_QUERY = gql`
  query PaymentMethodsQuery($archivedType: ArchivedType, $num: Int!) {
    user {
      id
      dogs {
        id
        name
      }
      shippingCountryCode
      preferredLanguage
      subscription {
        id
        status
        activePaymentMethodType
        deliveriesReceived
        paymentMethods(archivedType: $archivedType) {
          ... on BillingAgreement {
            __typename
            id
            type
            active
            paypalEmail
          }
          ... on CreditCardPaymentMethod {
            __typename
            id
            type
            active
            lastFourDigits
            expMonth
            expYear
          }
          ... on SepaDirectDebit {
            __typename
            id
            type
            active
            accountLastFour
          }
          ... on MerchantInitiatedPayment {
            __typename
            id
            type
            active
            merchantInitiatedType
          }
        }
        nextNBoxes(num: $num) {
          id
          isNextEditableBox
          isFreeBox
        }
      }
    }
  }
`

export { PAYMENT_METHODS_QUERY }
