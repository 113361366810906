// @noflow
import isArray from 'lodash/isArray'
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'

import BREAKPOINTS from '@/constants/Breakpoints'

import BlueCheckmark from 'assets/images/icons/checkmarks/blue-checkmark-transparent-bg.svg'
import WhiteCheckmark from 'assets/images/icons/checkmarks/white-checkmark-blue-bg.svg'
import PlusIcon from 'assets/images/icons/plus-icon-blue.svg'

import Card, { generateVariant } from '@/components/elements/atoms/Card/Card'
import CloudinaryImage from '@/components/elements/atoms/CloudinaryImage/CloudinaryImage'
import Text from '@/components/elements/atoms/Text/Text'
import Tooltip from '@/components/elements/atoms/Tooltip/Tooltip'
import Truncate, {
  Props as TruncateProps
} from '@/components/elements/atoms/Truncate/Truncate'

import STYLES from './SelectableCard.module.sass'

type Props = {
  defaultSelected: boolean
  title: string
  slug?: string
  subtitle?: string
  cloudinaryPath: string
  readMore?: boolean
  onReadMoreClick?: (slug: string) => void
  description: string
  layoutVariant: 'horizontal' | 'vertical'
  ctaVariant?: 'plus' | 'checkmark' | 'none'
  selectable?: boolean
  deselectable?: boolean
  onSelectionChange: (selected: boolean) => void
  onClick?: (selected: boolean) => void
  labels?: ReactElement[]
  maxWidth?: boolean
  truncate?: TruncateProps['lines']
  dataTestId?: string
} & (TooltipProps | NoTooltipProps)

type TooltipProps = {
  tooltipText: string
  tooltipContent: string | ReactElement
  tooltipIdentifier: string
}

type NoTooltipProps = {
  tooltipText?: never
  tooltipContent?: never
  tooltipIdentifier?: never
}

const SelectableCard = ({
  defaultSelected = false,
  title,
  slug,
  subtitle,
  cloudinaryPath,
  readMore,
  onReadMoreClick,
  description,
  tooltipText,
  tooltipContent,
  tooltipIdentifier,
  layoutVariant = 'vertical',
  ctaVariant = 'checkmark',
  labels,
  selectable = true,
  deselectable = true,
  onSelectionChange,
  onClick,
  maxWidth = true,
  truncate,
  dataTestId
}: Props): JSX.Element => {
  const cardRef = useRef<HTMLDivElement>(null)
  const [selected, setSelected] = useState(defaultSelected)

  useEffect(() => {
    setSelected(defaultSelected)
  }, [defaultSelected])

  const toggleSelection = useCallback(() => {
    if ((!deselectable && selected) || (!selectable && !selected)) {
      if (onClick) onClick(selected)
    } else {
      setSelected(!selected)
      onSelectionChange(!selected)
      if (onClick) onClick(!selected)
    }
  }, [deselectable, selected, selectable, onClick, onSelectionChange])

  const handleReadMore = useCallback(() => {
    if (!slug || !onReadMoreClick) return
    onReadMoreClick(slug)
  }, [slug, onReadMoreClick])

  const generateCTAVariant = (
    variant: Props['ctaVariant'] | undefined,
    isSelected: boolean
  ): ReactElement | null => {
    if (variant === 'none') return null

    let image, classes

    switch (variant) {
      case 'plus':
        image = isSelected ? WhiteCheckmark : PlusIcon
        classes = isSelected ? STYLES.checkmark : STYLES.plus
        break

      default:
        image = isSelected ? WhiteCheckmark : BlueCheckmark
        classes = STYLES.checkmark
    }

    return (
      <button
        type="button"
        className={STYLES.addButton}
        onClick={toggleSelection}
      >
        <img src={image} className={classes} alt="" />
      </button>
    )
  }
  const CTAVariant = generateCTAVariant(ctaVariant, selected)

  return (
    <div
      data-testid={dataTestId}
      ref={cardRef}
      className={`${STYLES.selectableCard} ${STYLES[layoutVariant]} ${
        ctaVariant === 'none' ? STYLES.noCTA : ''
      } ${!maxWidth ? STYLES.noMaxWidth : ''} ${
        selected ? STYLES.selected : ''
      }`}
      data-selected={selected}
    >
      <Card variant={selected ? generateVariant('brandBlue500') : null}>
        <div
          data-testid={`recipe-card-${selected ? 'selected' : 'deSelected'}`}
          className={STYLES.selectableCardContent}
        >
          <div className={STYLES.selectableCardImage}>
            <CloudinaryImage
              alt=""
              image={{
                path: cloudinaryPath,
                crop: 'fill',
                width:
                  cardRef && cardRef.current
                    ? cardRef.current.clientWidth
                    : 500,
                dpr: 2
              }}
            />
          </div>
          <div className={STYLES.selectableCardBody}>
            <Text
              text={title}
              margin={false}
              translate={false}
              variant={
                window.innerWidth < BREAKPOINTS.md
                  ? 'textRegular16'
                  : 'display16'
              }
              bold
              colour={selected ? 'brandWhite' : 'brandBlue500'}
            />
            {subtitle && (
              <Text
                text={subtitle}
                margin={false}
                translate={false}
                variant="textRegular14"
                colour={selected ? 'brandWhite' : 'brandBlue500'}
              />
            )}
            {isArray(labels) && labels.length > 0 && (
              <div className={STYLES.labels}>{labels}</div>
            )}
            <Truncate lines={truncate}>
              <Text
                text={description}
                margin={false}
                translate={false}
                variant="textRegular14"
                colour={selected ? 'brandBlue200' : 'brandBlue400'}
              />
            </Truncate>
            {readMore && (
              <button
                type="button"
                onClick={handleReadMore}
                className={`${STYLES.readMore} ${
                  selected ? STYLES.readMoreSelected : ''
                }`}
              >
                <Text
                  text="read_more"
                  namespace="shared"
                  variant="textRegular16"
                  shouldScale={false}
                  element="span"
                  colour={selected ? 'brandWhite' : 'brandBlue500'}
                />
              </button>
            )}
            {CTAVariant}
            {tooltipText && tooltipContent && tooltipIdentifier && (
              <div className={STYLES.tooltip}>
                <Tooltip
                  size={14}
                  variant={selected ? 'brandBlue600' : undefined}
                  flush="left"
                  label={{
                    text: tooltipText,
                    translate: false
                  }}
                  identifier={tooltipIdentifier}
                >
                  {typeof tooltipContent === 'string' ? (
                    <Text
                      text={tooltipContent}
                      variant="textRegular14"
                      colour="brandBlue500"
                      translate={false}
                    />
                  ) : (
                    tooltipContent
                  )}
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      </Card>
    </div>
  )
}

export { Props }
export default SelectableCard
