// @noflow
import { useQuery } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import i18next from 'i18next'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import React, { useCallback, useState } from 'react'

import { pronounContext } from '@/utils/StringHelper'

import { REMOVED_AP } from '@/constants/RibbonEvents'

import { Button } from '@/components/elements/atoms/Button'
import ExtrasContent, {
  Props as ExtrasContentProps
} from '@/components/elements/molecules/ExtrasModal/ExtrasContent'
import ModalWithHeaderFooter from '@/components/elements/molecules/ModalWithHeaderFooter/ModalWithHeaderFooter'
import type { Props as ModalProps } from '@/components/elements/molecules/ModalWithHeaderFooter/ModalWithHeaderFooter'
import { boxQuery } from '@/components/pages/OrderPage/__generated__/boxQuery'
import { BOX_QUERY } from '@/components/pages/OrderPage/queries'
import { ReviewQuery_currentUser_User_planRecommendation_individual_dog as Dog } from '@/components/pages/PlansPage/__generated__/ReviewQuery'

import STYLES from './ExtrasModal.module.sass'

type Props = Pick<
  ExtrasContentProps,
  'boxId' | 'onSaveSuccess' | 'onSaveError'
> &
  Pick<ModalProps, 'isModalOpen' | 'setOpenModal' | 'onCloseButtonClick'>

const ExtrasModal = ({
  boxId,
  isModalOpen,
  setOpenModal,
  onSaveSuccess,
  onCloseButtonClick
}: Props): JSX.Element | null => {
  const namespace = 'account'
  const [updating, setUpdating] = useState(false)
  const [hasRemovedExtra, setHasRemovedExtra] = useState(false)
  const { loading, data, error } = useQuery<boxQuery>(BOX_QUERY, {
    variables: {
      boxId: boxId || ''
    }
  })

  const onSuccess = useCallback(() => {
    if (!isUndefined(onSaveSuccess)) onSaveSuccess()
  }, [onSaveSuccess])

  const onSaveClick = useCallback(() => {
    setOpenModal && setOpenModal(false)
    if (onCloseButtonClick) onCloseButtonClick()
    if (hasRemovedExtra) {
      window.ribbon && window.ribbon('trigger', REMOVED_AP)
    }
  }, [hasRemovedExtra, onCloseButtonClick, setOpenModal])

  if (error) Sentry.captureException(error)

  if (!data) return null

  const {
    user: { dogs }
  } = data

  if (isNull(dogs)) return null

  const dogGenders: Dog['gender'][] = dogs.map((dog) => dog.gender)
  const dogPronoun = pronounContext(dogGenders, i18next.language)

  return (
    <ModalWithHeaderFooter
      width={450}
      isModalOpen={isModalOpen}
      setOpenModal={setOpenModal}
      onCloseButtonClick={onSaveClick}
      // stop the user changing quantity while a mutation is already underway - will cause a BE error
      loading={loading || updating}
      title={{
        text: 'edit_extras.title',
        variables: {
          context: dogPronoun
        },
        namespace
      }}
      footer={
        <footer className={STYLES.footer}>
          <Button
            typography={{
              namespace,
              text: 'edit_extras.save'
            }}
            variant="primary"
            onClick={onSaveClick}
            disableAnalytics
          />
        </footer>
      }
    >
      <ExtrasContent
        boxId={boxId}
        onSaveSuccess={onSuccess}
        hasRemovedExtra={hasRemovedExtra}
        setHasRemovedExtra={setHasRemovedExtra}
        setUpdating={setUpdating}
      />
    </ModalWithHeaderFooter>
  )
}

export default ExtrasModal
