/* eslint-disable react/require-optimization */
/* eslint-disable react/no-arrow-function-lifecycle */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable flowtype/require-exact-type */
// @flow

import * as React from 'react'

import { tabs, shopTab } from './tabs'

import DashboardAlert from '../shared/DashboardAlert'
import SelectBar from './partials/SelectBar'
import AmbassadorReferralTab from '../affiliates/tabs/ambassador_referral_tab'

import type { Ambassador } from './message_types'

type Props = {|
  ambassador: Ambassador,
  ambassadorShopPath: string
|}

type State = {|
  alertMessage: ?string,
  alertIsError: boolean
|}

class AmbassadorDashboardReferrals extends React.Component<Props, State> {
  constructor (props: Props): void {
    super(props)
    this.state = {
      alertMessage: null,
      alertIsError: false,
    }
  }

  setAlertMessage = (alertMessage: string, alertIsError: boolean = false): void => {
    this.setState({alertMessage, alertIsError}, (): void => {
      window.scrollTo(0, 0)
      setTimeout((): void => {
        this.setState({alertMessage: null})
      }, 3000)
    })
  }

  render = (): React.Element<'div'> => (
    <div className='butternutbox-ambassador-dashboard'>
      {/* $FlowFixMe */}
      <DashboardAlert
        message={this.state.alertMessage}
        isError={this.state.alertIsError}
        shippingCountryCode={this.props.ambassador.shipping_country_code}
        preferredLanguage={this.props.ambassador.preferred_language}
      />
      <div className={`affiliate-dashboard__${tabs[1].hash}`}>
        <div className='affiliate-dashboard container'>
          <SelectBar
            tabs={tabs}
            ambassador={this.props.ambassador}
            shopTab={ this.props.ambassadorShopPath ? shopTab : []}
            selectedIndex={1}
            ambassadorShopPath={this.props.ambassadorShopPath}
          />
          <AmbassadorReferralTab
            shippingCountryCode={this.props.ambassador.shipping_country_code}
            affiliateType={'ambassador'}
            fullName={this.props.ambassador.full_name}
            priorityDiscountCodeValues={this.props.ambassador.priority_discount_code_values}
            socialDiscountCodeValues={this.props.ambassador.social_discount_code_values}
            priorityReferralLink={this.props.ambassador.priority_referral_link}
            socialReferralLink={this.props.ambassador.social_referral_link}
            referralSignupCredit={this.props.ambassador.credit_received_on_referral_signup}
            // eslint-disable-next-line react/jsx-boolean-value
            visible={true}
            setAlertMessage={this.setAlertMessage}
            preferredLanguage={this.props.ambassador.preferred_language}
          />
        </div>
      </div>
    </div>
  )
}

export default AmbassadorDashboardReferrals
