import { gql } from '@apollo/client'

const SUBSCRIPTION_OVERVIEW_FRAGMENT = gql`
  fragment SubscriptionOverviewFragment on User {
    dogs {
      id
      name
      planPortionInGrams
      gender
      ageInMonths
      dogProfile {
        id
        avatarUrl
      }
    }
    subscription {
      id
      status
      plan {
        id
        pouchSize
        pouchesPerDay
      }
    }
  }
`

export { SUBSCRIPTION_OVERVIEW_FRAGMENT }
