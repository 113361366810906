// @noflow
import type { Language } from '@/packs/localisation'
import { ACCOUNT_ROUTES } from '@/routes'
import { useMutation, useQuery } from '@apollo/client'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import isNull from 'lodash/isNull'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { formatCurrencyWithDecimal } from '@/utils/currency'
import * as Sentry from '@/utils/sentry'

import BREAKPOINTS from '@/constants/Breakpoints'

import useSubscriptionResume from '@/hooks/subscriptionResume/useSubscriptionResume'
import useWindowSize from '@/hooks/useWindowSize'

import ButternutBoxSprite from 'assets/images/illustrations/loading-screens/meal-pouch-with-bowl-and-yellow-hearts-illustration--sprite.svg'

import segmentTrack from '@/components/analytics/Analytics'
import Separator from '@/components/elements/atoms/Separator/Separator'
import Text from '@/components/elements/atoms/Text/Text'
import NotificationContainer from '@/components/elements/molecules/NotificationContainer/NotificationContainer'
import NotificationContent from '@/components/elements/molecules/NotificationContent/NotificationContent'
import ProductThumb from '@/components/elements/molecules/ProductThumb/ProductThumb'
import StickyNavigation from '@/components/elements/organisms/StickyNavigation/StickyNavigation'
import SuccessOverlay from '@/components/elements/organisms/SuccessOverlay/SuccessOverlay'
import { REMOVE_SURCHARGED_RECIPES_MUTATION } from '@/components/pages/SwitchAndSavePage/queries/RemoveSurchargedRecipesMutation'
import { SWITCH_AND_SAVE_PAGE_QUERY } from '@/components/pages/SwitchAndSavePage/queries/SwitchAndSavePageQuery'
import type { RemoveSurchargedRecipes } from '@/components/pages/SwitchAndSavePage/queries/__generated__/RemoveSurchargedRecipes'
import {
  SwitchAndSavePageQuery_user_subscription_meal as Meal,
  SwitchAndSavePageQuery_user_subscription as Subscription,
  SwitchAndSavePageQuery
} from '@/components/pages/SwitchAndSavePage/queries/__generated__/SwitchAndSavePageQuery'
import Base from '@/components/templates/Base'

import STYLES from './SwitchAndSavePage.module.sass'

import { SubscriptionStatus } from '@/types'

type Props = {
  variant: Language
}

const SwitchAndSavePage = (): JSX.Element | null => {
  const [currentUserSubscription, setCurrentUserSubscription] =
    useState<null | Subscription>(null)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const { t } = useTranslation('simplified_plans_flow')

  const namespace = 'account'
  const { loading, data, error } = useQuery<SwitchAndSavePageQuery>(
    SWITCH_AND_SAVE_PAGE_QUERY,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'cache-first',
      onCompleted: ({ user }) =>
        user?.__typename === 'User' &&
        setCurrentUserSubscription(user?.subscription)
    }
  )

  const { handleResumeSubscription } = useSubscriptionResume({
    subscriptionId: currentUserSubscription?.id || ''
  })

  const [removeSurchargedRecipes, { loading: loadingRemoveSurchargedRecipes }] =
    useMutation<RemoveSurchargedRecipes>(REMOVE_SURCHARGED_RECIPES_MUTATION, {
      onCompleted: () => {
        setShowSuccess(true)
      }
    })

  const { windowWidth } = useWindowSize()

  if (error)
    Sentry.captureException(
      `Error occured in SWITCH_AND_SAVE_PAGE_QUERY: ${error.message}`,
      {
        extra: { error },
        tags: {
          product: Sentry.Product.Account,
          team: Sentry.Team.Retention
        }
      }
    )

  const pausedUser =
    data?.user.subscription.status === SubscriptionStatus.paused

  const saveAndRestartClicked = useCallback(async () => {
    try {
      await removeSurchargedRecipes().then(async () => {
        segmentTrack('Switch and Save Page - Save and Restart Clicked')
        if (pausedUser) await handleResumeSubscription()

        setTimeout((): void => {
          window.location.href = ACCOUNT_ROUTES.base
        }, 1000)
      })
    } catch (error) {
      Sentry.captureException(
        `Error occured in REMOVE_SURCHARGED_RECIPES_MUTATION on Switch and Save page`,
        {
          extra: { error },
          tags: {
            product: Sentry.Product.Account,
            team: Sentry.Team.Retention
          }
        }
      )
      toast.error(
        <NotificationContent
          copy={{
            text: 'switch_and_save.notification_update_error',
            namespace
          }}
        />,
        { toastId: 'confirm-and-save-error-message' }
      )
    }
  }, [handleResumeSubscription, removeSurchargedRecipes, pausedUser])

  if (loading || !data) {
    return null
  }

  const {
    user: {
      shippingCountryCode,
      preferredLanguage,
      subscription: { recurringOrder, meal }
    }
  } = data

  const { locale, currency } = countryCodeToLocaleCurrency(
    shippingCountryCode,
    preferredLanguage
  )

  const recipesInTheBox = meal?.filter(({ quantity }: Meal) => quantity > 0)

  if (!meal || !recipesInTheBox) {
    return null
  }

  const recipesWithSurcharge = recipesInTheBox.filter(
    ({ flavour }: Meal) =>
      !isNull(flavour.recipeSurcharge) && flavour.recipeSurcharge > 0
  )

  const recipesWithoutSurcharge = recipesInTheBox.filter(
    ({ flavour }: Meal) =>
      !isNull(flavour.recipeSurcharge) && flavour.recipeSurcharge === 0
  )

  const surchargeTotal = formatCurrencyWithDecimal(
    recurringOrder.surchargeTotal,
    {
      locale: locale,
      currency: currency
    }
  )

  if (showSuccess) {
    return (
      <SuccessOverlay
        sprite={ButternutBoxSprite}
        imageAlt={t('loading_screen.meal_bowl_img_alt')}
        typography={{
          text: 'switch_and_save.success_message',
          margin: false,
          namespace
        }}
      />
    )
  } else {
    return (
      <>
        <Base background="brandYellow100">
          <Container maxWidth={false} disableGutters>
            <div className={STYLES.wrapper}>
              <Grid container justifyContent="center">
                <Grid item sm={12} md={8}>
                  <Text
                    text={'switch_and_save.subheading'}
                    variables={{ surchargeTotal }}
                    variant="textRegular16"
                    margin={false}
                    namespace={namespace}
                    align={windowWidth < BREAKPOINTS.md ? 'left' : 'center'}
                  />
                  <div className={STYLES.textSeparator}>
                    <Separator solid bold={false} />
                  </div>
                  <Text
                    text={'switch_and_save.meals_removed'}
                    variant="display20"
                    margin={false}
                    namespace={namespace}
                  />
                  <div className={STYLES.thumbs}>
                    {recipesWithSurcharge?.map(({ flavour }: Meal) => (
                      <ProductThumb
                        image={flavour.thumbnail.src}
                        alt={''}
                        key={flavour.name}
                        quantity={null}
                      />
                    ))}
                  </div>
                  <Text
                    text={'switch_and_save.meals_in_new_plan'}
                    variant="display20"
                    margin={false}
                    namespace={namespace}
                  />
                  <div className={STYLES.thumbs}>
                    {recipesWithoutSurcharge?.map(({ flavour }: Meal) => (
                      <ProductThumb
                        image={flavour.thumbnail.src}
                        alt={''}
                        key={flavour.name}
                        quantity={null}
                      />
                    ))}
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </Base>
        <StickyNavigation
          variant={'oneButton'}
          disabled={loadingRemoveSurchargedRecipes}
          buttonOne={{
            variant: 'primary',
            text: 'switch_and_save.save_and_restart',
            icon: null,
            namespace,
            onClick: saveAndRestartClicked
          }}
        />
        <NotificationContainer autoClose={5000} />
      </>
    )
  }
}

export { Props }

export default SwitchAndSavePage
