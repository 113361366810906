// @noflow
import isNull from 'lodash/isNull'
import { RefObject, useEffect, useRef, useState } from 'react'

const useIntersectionObserver = (
  ref: RefObject<HTMLElement>,
  options: IntersectionObserverInit
): boolean => {
  const observerRef = useRef<IntersectionObserver | null>(null)
  const [isOnScreen, setIsOnScreen] = useState(false)

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      ([entry]) => setIsOnScreen(entry.isIntersecting),
      options
    )
  }, [options])

  useEffect(() => {
    if (!isNull(observerRef.current) && !isNull(ref.current)) {
      observerRef.current.observe(ref.current)
    }
  }, [ref])

  return isOnScreen
}

export default useIntersectionObserver
