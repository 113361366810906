// @noflow
import { useReactiveVar } from '@apollo/client'
import React, { useCallback } from 'react'

import { subscriptionDataVar } from '@/services/apollo'

import useBoolean from '@/hooks/useBoolean'

import { Button } from '@/components/elements/atoms/Button'

import STYLES from './ArchiveDogProfileAction.module.sass'

import type {
  DogProfileQuery_user_dogs as Dog,
  DogProfileQuery
} from '../../queries/__generated__/DogProfileQuery'
import { SubscriptionStatus } from '@/types'

import { ArchiveDogProfileModal } from './ArchiveDogProfileModal'

type Props = {
  dog?: Dog
  dogs?: Array<Dog> | null
  archiveReasons?: DogProfileQuery['archiveReasons']
  loading?: boolean
}

const ArchiveDogProfileAction = ({
  dog,
  dogs,
  archiveReasons,
  loading = false
}: Props): JSX.Element => {
  const {
    value: isModalOpen,
    setTrue: openModal,
    toggle: toggleModal
  } = useBoolean(false)

  const subscriptionData = useReactiveVar(subscriptionDataVar)

  const isDataLoaded = dog && dogs && archiveReasons

  const handleClick = useCallback(() => {
    if (subscriptionData?.status === SubscriptionStatus.suspended) {
      return (window.location.href = '/contact')
    }

    openModal()
  }, [openModal, subscriptionData?.status])

  return (
    <div className={STYLES.wrapper}>
      <Button
        identifier="dog_profile.archive_entry_point"
        variant="secondary"
        onClick={handleClick}
        typography={{
          text: 'dog_profile.archive_dog_profile.entry_point',
          namespace: 'account'
        }}
        skeleton={{
          isLoading: loading,
          width: '100%'
        }}
        fullWidth
      />
      {isModalOpen && isDataLoaded && (
        <ArchiveDogProfileModal
          dog={dog}
          dogs={dogs}
          archiveReasons={archiveReasons}
          isModalOpen={isModalOpen}
          toggleModal={toggleModal}
        />
      )}
    </div>
  )
}

export { ArchiveDogProfileAction }
