// @noflow
import { CardElement } from '@stripe/react-stripe-js'
import type { StripeCardElementChangeEvent } from '@stripe/stripe-js'
import React from 'react'

import BRAND_COLOURS from '@/constants/BrandColours'
import SUPPORT_COLOURS from '@/constants/SupportColours'

import Icon from '@/components/elements/atoms/Icon/Icon'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from '../../PaymentMethods.module.sass'

type Props = {
  errorMessage: string | null
  onCardElementChange: (event: StripeCardElementChangeEvent) => void
}

const forceReflow = (stripeElement: HTMLElement | null): void => {
  if (stripeElement) {
    const initialDisplay = stripeElement.style.display

    stripeElement.style.display = 'none'
    stripeElement.style.display = initialDisplay
  }
}

const Error = ({ errorMessage }: { errorMessage: string }): JSX.Element => (
  <div className={STYLES.helperTextError}>
    <div className={STYLES.errorIcon}>
      <Icon
        asset="errorCircle"
        size={12}
        width={12}
        accentColour="dangerRed300"
      />
    </div>
    <Text
      text={errorMessage}
      translate={false}
      variant={'textRegular12'}
      colour="dangerRed300"
      align={'left'}
    />
  </div>
)

const CardWrapper = ({
  errorMessage,
  onCardElementChange
}: {
  errorMessage: string | null
  onCardElementChange: (event: StripeCardElementChangeEvent) => void
}): React.ReactElement => {
  return (
    <div
      className={`checkout-input-wrapper${
        errorMessage === null ? '' : ' checkout-input-wrapper--invalid'
      }`}
    >
      <CardElement
        onChange={onCardElementChange}
        // eslint-disable-next-line react/forbid-component-props
        options={{
          hidePostalCode: true,
          style: {
            base: {
              fontSize: '18px',
              fontFamily: 'gt-pressura-regular, helvetica, arial, sans-serif',
              color: BRAND_COLOURS.brandBlue500,

              '::placeholder': {
                color:
                  errorMessage === null
                    ? BRAND_COLOURS.brandBlue400
                    : SUPPORT_COLOURS.dangerRed300
              }
            },
            invalid: {
              color: '#7a0a06'
            }
          }
        }}
      />
      {errorMessage && <Error errorMessage={errorMessage} />}
    </div>
  )
}

const CreditCardView = ({
  errorMessage,
  onCardElementChange
}: Props): JSX.Element => {
  // Force the Element frame to paint once it is visible on the page
  const stripeElement: HTMLElement | null =
    document.querySelector('.StripeElement')

  React.useEffect((): void => {
    forceReflow(stripeElement)
  })

  return (
    <CardWrapper
      errorMessage={errorMessage}
      onCardElementChange={onCardElementChange}
    />
  )
}

export default CreditCardView
