// @noflow
import { Occasions, useOccasion } from '@/context/festiveTheme/festiveTheme'
import classNames from 'classnames'
import isUndefined from 'lodash/isUndefined'
import React, { useRef } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

// Utils
import BREAKPOINTS from '@/constants/Breakpoints'

import useFocus from '@/hooks/useFocus/useFocus'
import useHover from '@/hooks/useHover/useHover'
import useWindowSize from '@/hooks/useWindowSize'

// Types
import type {
  DogProfileCollarProps,
  ExtrasShopProps,
  HomeProps,
  RewardsProps
} from '@/components/elements/atoms/Icon/Icons'
import Text, { TextProps } from '@/components/elements/atoms/Text'

import STYLES from './Tab.module.sass'

type IconProps =
  | HomeProps
  | DogProfileCollarProps
  | ExtrasShopProps
  | RewardsProps

type Props = {
  icon: React.ComponentType<IconProps>
  pathname: string
  pathMatch?: string
  title: TextProps['text']
  variables?: TextProps['variables']
  onClick?: () => void
}

/**
 * Tab component
 *
 * Ideally this component should be used as a child of the NavigationTabBar component.
 * Use this component to render a single navigation tab.
 *
 * @example
  ```
  <Tab
    icon={Home}
    pathname='/home'
    title='navigation_tab_bar.home'
  />
  ```
 * @param {React.ComponentType<IconProps>} icon - Icon component
 * @param {string} pathname - Pathname of the tab
 * @param {string} [pathMatch] - Path match of the tab (used for nested routes)
 * @param {string} title - Title of the tab
 * @param {TextProps['variables']} [variables] - Variables for the title
 * @returns {JSX.Element} - Tab component
 * @category Components
 * @subcategory Molecules/NavigationTabBar
 * @component Tab
 */
const Tab = ({
  icon: Icon,
  pathname,
  pathMatch,
  title,
  variables,
  onClick
}: Props): JSX.Element => {
  const location = useLocation()
  const { festiveClass } = useOccasion()
  const { windowWidth } = useWindowSize()
  const ref = useRef(null)
  const isHovered = useHover(ref)
  const isFocused = useFocus(ref)

  const isNestedRoute = !!pathMatch && location.pathname.includes(pathMatch)
  const isTabActive =
    pathname === location.pathname || isNestedRoute || isHovered || isFocused
  const isMobile = windowWidth < BREAKPOINTS.md

  const classes = classNames(STYLES.tab, {
    [STYLES.active]: isTabActive,
    [STYLES[festiveClass as Occasions]]: !isUndefined(festiveClass)
  })

  return (
    <NavLink
      end
      to={pathname}
      className={classes}
      onClick={onClick}
      ref={ref}
      role="menuitem"
    >
      <div className={STYLES.iconWrapper}>
        <Icon
          accentColour={isTabActive ? 'brandBlue500' : 'brandBlue400'}
          showShadow={isTabActive}
          height={32}
          width={30}
        />
      </div>
      <Text
        text={title}
        namespace="molecules"
        margin={false}
        shouldScale={false}
        variant={isMobile ? 'textRegular14' : 'textRegular18'}
        colour={isTabActive ? 'brandBlue500' : 'brandBlue400'}
        variables={variables}
      />
    </NavLink>
  )
}

export type { Props }

export { Tab }
