// @flow

import type { State as WizardStep } from './reducers/wizardStepReducer'

type Route =
  | '/post_sign_up_wizard'
  | '/post_sign_up_wizard/activities'
  | '/post_sign_up_wizard/quirks'
  | '/post_sign_up_wizard/upload-photo'
  | '/post_sign_up_wizard/dog-description'
  | '/post_sign_up_wizard/finishing-up'

const Root: Route = '/post_sign_up_wizard'
const Activities: Route = '/post_sign_up_wizard/activities'
const Quirks: Route = '/post_sign_up_wizard/quirks'
const UploadPhoto: Route = '/post_sign_up_wizard/upload-photo'
const DogDescription: Route = '/post_sign_up_wizard/dog-description'
const PostWizard: Route = '/post_sign_up_wizard/finishing-up'

const PostSignUpRoutes = {
  Root,
  Activities,
  Quirks,
  UploadPhoto,
  DogDescription,
  PostWizard
}

const routeToWizardStep = (route: Route): WizardStep => {
  switch (route) {
    case '/post_sign_up_wizard': {
      return 'FavouriteGames'
    }
    case '/post_sign_up_wizard/activities': {
      return 'FavouriteActivities'
    }
    case '/post_sign_up_wizard/quirks': {
      return 'QuirksAndTraits'
    }
    case '/post_sign_up_wizard/upload-photo': {
      return 'UploadPhoto'
    }
    case '/post_sign_up_wizard/dog-description': {
      return 'DogDescription'
    }
    case '/post_sign_up_wizard/finishing-up': {
      return 'PostWizard'
    }
    default: {
      (route: empty) // eslint-disable-line no-unused-expressions
      throw new Error(`Cannot generate routeToWizardStep for: ${route}`)
    }
  }
}

export type { Route }

export {
  routeToWizardStep
}

export default PostSignUpRoutes
