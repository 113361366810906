// @flow

import type { RailsModelID } from '@/shared_types/ids'
import type { CSRFToken } from '@/shared_types/csrfToken'

type RequestResult = {|
  wants_to_receive_ice: boolean
|}

const { fetch } = global

const toggleWantsToReceiveIceQuery = ({
  csrfToken,
  wants_to_receive_ice,
  subscriptionId,
}: {|
  csrfToken: CSRFToken,
  wants_to_receive_ice: boolean,
  subscriptionId: RailsModelID
|}): Promise<RequestResult> => {
  const endpoint = '/admin/single_customer_view/toggle_wants_to_receive_ice?subscription_id=' + subscriptionId

  const method = 'POST'
  const credentials = 'same-origin'
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  }
  const body = JSON.stringify({
    wants_to_receive_ice: !wants_to_receive_ice
  })
  return fetch(endpoint, { headers, method, credentials, body })
    .then((res: Response): Promise<RequestResult> => {
      if (!res.ok) {
        return res.text().then((): RequestResult => ({
          wants_to_receive_ice: wants_to_receive_ice
        }))
      }
      return res.json()
    })
}

export type {
  RequestResult
}

export default toggleWantsToReceiveIceQuery
