// @noflow
import React from 'react'
import { useTranslation } from 'react-i18next'

import * as DIGITAL_PRODUCT_CONSTANTS from '@/utils/butternutbox/constants/digital_product'

import RedStarburst from 'assets/images/icons/ornaments/starbursts/red-starburst.svg'
import AdultAvatar from 'assets/images/illustrations/dogs/dog-profile-avatars/single-adult-dog.svg'
import BorderTerrier from 'assets/images/illustrations/dogs/dog-profile-avatars/single-border-terrier.svg'
import Chihuahua from 'assets/images/illustrations/dogs/dog-profile-avatars/single-chihuahua.svg'
import CockerSpaniel from 'assets/images/illustrations/dogs/dog-profile-avatars/single-cocker-spaniel.svg'
import Dalmatian from 'assets/images/illustrations/dogs/dog-profile-avatars/single-dalmatian.svg'
import FrenchBulldog from 'assets/images/illustrations/dogs/dog-profile-avatars/single-french-bulldog.svg'
import GermanShephard from 'assets/images/illustrations/dogs/dog-profile-avatars/single-german-shepherd.svg'
import GreatDane from 'assets/images/illustrations/dogs/dog-profile-avatars/single-great-dane.svg'
import LabradorRetriever from 'assets/images/illustrations/dogs/dog-profile-avatars/single-labrador-retriever.svg'
import Pekingese from 'assets/images/illustrations/dogs/dog-profile-avatars/single-pekingnese.svg'
import Poodle from 'assets/images/illustrations/dogs/dog-profile-avatars/single-poodle.svg'
import PuppyAvatar from 'assets/images/illustrations/dogs/dog-profile-avatars/single-puppy-dog.svg'
import Schnauzer from 'assets/images/illustrations/dogs/dog-profile-avatars/single-schnauzer.svg'
import Staffie from 'assets/images/illustrations/dogs/dog-profile-avatars/single-staffie.svg'
import BlueHeart from 'assets/images/illustrations/hearts/blue-heart.svg'
import WhiteHeart from 'assets/images/illustrations/hearts/white-heart.svg'

import { RailsModelID } from '@/shared_types/ids'

type Dog = {
  id: RailsModelID
  avatarUrl: string | null
  breed: string
  ageInMonths: number
}

type Props<T> = {
  dog: T
  sprinkles: boolean
  extraClasses?: string
}

const BaseAvatarImage = ({
  alt,
  image,
  sprinkles,
  extraClasses
}: {
  alt: string
  image: string
  sprinkles: boolean
  extraClasses?: string
}): React.ReactElement => {
  const SPRINKLES = [
    RedStarburst,
    RedStarburst,
    BlueHeart,
    BlueHeart,
    WhiteHeart,
    WhiteHeart
  ]

  return (
    <div className={`dog-avatar ${extraClasses || ''}`}>
      <img alt={alt} className="dog-avatar__image" src={image} />
      {sprinkles
        ? SPRINKLES.map(
            (sprinkle: string, index: number): React.ReactElement => (
              <img
                alt=""
                key={`${sprinkle}--${index + 1}`}
                src={sprinkle}
                className={`dog-avatar__sprinkle dog-avatar__sprinkle--${
                  index + 1
                }`}
              />
            )
          )
        : ''}
    </div>
  )
}

const DogAvatar = <T extends Dog>({
  dog,
  sprinkles,
  extraClasses
}: Props<T>): React.ReactElement => {
  const { avatarUrl, breed, ageInMonths } = dog

  const { t } = useTranslation('thank_you')
  const copy_context = 'dog_profile_entry'

  if (avatarUrl) {
    return (
      <BaseAvatarImage
        alt={t(`${copy_context}.avatar_single_custom_alt`)}
        image={avatarUrl}
        sprinkles={sprinkles}
        extraClasses={extraClasses}
      />
    )
  } else {
    if (breed === 'Chihuahua') {
      return (
        <BaseAvatarImage
          alt={t(`${copy_context}.avatar_single_illustration_alt`)}
          image={Chihuahua}
          sprinkles={sprinkles}
          extraClasses={extraClasses}
        />
      )
    } else if (breed === 'Cocker Spaniel') {
      return (
        <BaseAvatarImage
          alt={t(`${copy_context}.avatar_single_illustration_alt`)}
          image={CockerSpaniel}
          sprinkles={sprinkles}
          extraClasses={extraClasses}
        />
      )
    } else if (breed === 'Dalmatian') {
      return (
        <BaseAvatarImage
          alt={t(`${copy_context}.avatar_single_illustration_alt`)}
          image={Dalmatian}
          sprinkles={sprinkles}
          extraClasses={extraClasses}
        />
      )
    } else if (breed === 'French Bulldog') {
      return (
        <BaseAvatarImage
          alt={t(`${copy_context}.avatar_single_illustration_alt`)}
          image={FrenchBulldog}
          sprinkles={sprinkles}
          extraClasses={extraClasses}
        />
      )
    } else if (breed === 'Labrador Retriever' || breed === 'Golden Retriever') {
      return (
        <BaseAvatarImage
          alt={t(`${copy_context}.avatar_single_illustration_alt`)}
          image={LabradorRetriever}
          sprinkles={sprinkles}
          extraClasses={extraClasses}
        />
      )
    } else if (breed === 'Pekingese') {
      return (
        <BaseAvatarImage
          alt={t(`${copy_context}.avatar_single_illustration_alt`)}
          image={Pekingese}
          sprinkles={sprinkles}
          extraClasses={extraClasses}
        />
      )
    } else if (breed === 'Poodle') {
      return (
        <BaseAvatarImage
          alt={t(`${copy_context}.avatar_single_illustration_alt`)}
          image={Poodle}
          sprinkles={sprinkles}
          extraClasses={extraClasses}
        />
      )
    } else if (breed === 'Border Terrier') {
      return (
        <BaseAvatarImage
          alt={t(`${copy_context}.avatar_single_illustration_alt`)}
          image={BorderTerrier}
          sprinkles={sprinkles}
          extraClasses={extraClasses}
        />
      )
    } else if (breed === 'Great Dane') {
      return (
        <BaseAvatarImage
          alt={t(`${copy_context}.avatar_single_illustration_alt`)}
          image={GreatDane}
          sprinkles={sprinkles}
          extraClasses={extraClasses}
        />
      )
    } else if (breed === 'Staffordshire Bull Terrier') {
      return (
        <BaseAvatarImage
          alt={t(`${copy_context}.avatar_single_illustration_alt`)}
          image={Staffie}
          sprinkles={sprinkles}
          extraClasses={extraClasses}
        />
      )
    } else if (breed === 'German Shepherd') {
      return (
        <BaseAvatarImage
          alt={t(`${copy_context}.avatar_single_illustration_alt`)}
          image={GermanShephard}
          sprinkles={sprinkles}
          extraClasses={extraClasses}
        />
      )
    } else if (
      breed === 'Miniature Schnauzer' ||
      breed === 'Schnauzer' ||
      breed === 'Standard Schnauzer'
    ) {
      return (
        <BaseAvatarImage
          alt={t(`${copy_context}.avatar_single_illustration_alt`)}
          image={Schnauzer}
          sprinkles={sprinkles}
          extraClasses={extraClasses}
        />
      )
    } else if (
      ageInMonths <= DIGITAL_PRODUCT_CONSTANTS.MAX_PUPPY_AGE_IN_MONTHS
    ) {
      return (
        <BaseAvatarImage
          alt={t(`${copy_context}.avatar_single_illustration_alt`)}
          image={PuppyAvatar}
          sprinkles={sprinkles}
          extraClasses={extraClasses}
        />
      )
    } else {
      return (
        <BaseAvatarImage
          alt={t(`${copy_context}.avatar_single_illustration_alt`)}
          image={AdultAvatar}
          sprinkles={sprinkles}
          extraClasses={extraClasses}
        />
      )
    }
  }
}

export default DogAvatar

export type { Props, Dog }
