import { gql } from '@apollo/client'

import {
  BOX_ORDER_FRAGMENT,
  NON_CORE_ORDER_FRAGMENT
} from '@/components/pages/Dashboard/components/upcomingBoxes/queries/fragments'
import { PAYMENT_METHODS_FRAGMENT } from '@/components/pages/MyDetailsPage/fragments/fragments'

const ACTIVATE_BOOST_MEMBERSHIP = gql`
  ${BOX_ORDER_FRAGMENT}
  ${NON_CORE_ORDER_FRAGMENT}
  ${PAYMENT_METHODS_FRAGMENT}

  mutation ActivateMembership(
    $offset: Int!
    $limit: Int!
    $order: String
    $nextNBoxes: Int!
    $archivedType: ArchivedType!
  ) {
    createMembership(membership: { membershipType: boost_ap_only }) {
      user {
        id
        ordersByDate(offset: $offset, limit: $limit, order: $order) {
          ...upcomingBoxOrderFragment
          ...nonCoreUpcomingOrderFragment
        }
        subscription {
          id
          status
          deliveriesReceived
          activeBoostedMembership {
            id
            isActive
          }
          lastBox {
            id
            isoDeliveryDate
          }
          nextNBoxes(num: $nextNBoxes) {
            id
            editable
            cutOffDate
            isoDeliveryDate
          }
          plan {
            swappablePlan {
              id
            }
          }
          ...paymentMethodsFragment
        }
      }

      membership {
        id
        isActive
      }
    }
  }
`

export { ACTIVATE_BOOST_MEMBERSHIP }
