// @noflow
import { differenceInSeconds, parseISO } from 'date-fns'
import React, { useEffect, useState } from 'react'

import segmentTrack from '@/components/analytics/Analytics'
import Text from '@/components/elements/atoms/Text/Text'
import Countdown from '@/components/elements/molecules/Countdown/Countdown'

type Props = {
  namespace: string
  endOfLeadTime: string
  showAltCountdown?: boolean
}

const NextDayDeliveryDailyCountdown = ({
  namespace,
  endOfLeadTime,
  showAltCountdown = false
}: Props): JSX.Element | null => {
  const [currentDate, setCurrentDate] = useState(new Date())
  const copyContext = 'next_day_delivery_countdown'

  useEffect(() => {
    const tick = setInterval(() => {
      setCurrentDate(new Date())
    }, 1000)

    return () => clearInterval(tick)
  })

  useEffect(() => {
    segmentTrack('Checkout Countdown', { showAltCountdown })
  }, [showAltCountdown])

  const baseClass = 'next-day-delivery-countdown'
  const textClass = 'countdown-text'

  return differenceInSeconds(parseISO(endOfLeadTime), currentDate) > 0 ? (
    <div className={baseClass}>
      <div>
        <p className={textClass}>
          <Text
            namespace={namespace}
            text={`${copyContext}.${showAltCountdown ? 'alt_title' : 'title'}`}
            margin={false}
            element="span"
          />
        </p>
        <div>
          <Countdown
            targetDate={endOfLeadTime}
            countdownType="dailyCountdown"
            numberVariant="display16"
            labelVariant="textRegular14"
            textColour="brandBlue500"
            variant="default"
            labels={{
              daysLabel: `${copyContext}.day`,
              hoursLabel: `${copyContext}.hour`,
              minutesLabel: `${copyContext}.minute`,
              secondsLabel: `${copyContext}.second`
            }}
            namespace={namespace}
          />
        </div>
      </div>
    </div>
  ) : null
}

export default NextDayDeliveryDailyCountdown
