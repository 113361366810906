// @noflow
import type { Language } from '@/packs/localisation'
import i18next from 'i18next'
import * as React from 'react'

import { toLocalisedSentence } from '@/utils/StringHelper'
import formatter from '@/utils/formatter'

import Text from '@/components/elements/atoms/Text/Text'
import RafLinkAndShare from '@/components/elements/organisms/RafLinkAndShare/RafLinkAndShare'

type Props = {
  referralLink: string
  dogNames: Array<string>
  preferredLanguage: Language
  headerMessage: string
  whatsappMessageSingleDog: string
  whatsappMessageMultipleDogs: string
  userId: string
}

const ReferralComponent = ({
  referralLink,
  headerMessage,
  whatsappMessageSingleDog,
  whatsappMessageMultipleDogs,
  dogNames,
  preferredLanguage,
  userId
}: Props): JSX.Element => {
  const namespace = 'thank_you'
  const copy_context = 'thank_you:thank_you_raf'

  const [alertMessage, setAlertMessage] = React.useState('')
  const tooltipClass =
    alertMessage !== i18next.t('shared:raf.copied_btn')
      ? 'clipboard-tooltip--danger'
      : 'clipboard-tooltip--success'
  React.useEffect((): void => {
    setTimeout((): void => {
      setAlertMessage('')
    }, 3000)
  }, [alertMessage])

  const joinedDogsNames = toLocalisedSentence({
    arr: dogNames,
    lng: i18next.language
  })
  const formattedDogNames = formatter.possessiveName(joinedDogsNames)

  return (
    <div className="thank-you__main__referral-section-container">
      <div className="thank-you__main__referral-section-header">
        <Text
          namespace={namespace}
          text={`${copy_context}.gift_a_friend_title`}
          variant="display28"
          colour="brandBlue500"
          align="center"
          element="h3"
        />
        <Text
          translate={false}
          text={headerMessage}
          variables={{ dogNames: formattedDogNames }}
          variant="textRegular16"
          colour="brandBlue500"
          align="center"
        />
      </div>
      <div className="thank-you__main__referral-section__share-content">
        {alertMessage && (
          <div className={`clipboard-tooltip tooltip ${tooltipClass}`}>
            <span>{alertMessage}</span>
          </div>
        )}
        <RafLinkAndShare
          referralLink={referralLink}
          copyReferralLinkCallback={setAlertMessage}
          partialLocation="Thank You"
          dogNames={dogNames}
          preferredLanguage={preferredLanguage}
          whatsappMessageSingleDog={whatsappMessageSingleDog}
          whatsappMessageMultipleDogs={whatsappMessageMultipleDogs}
          userID={userId}
        />
      </div>
    </div>
  )
}

export default ReferralComponent
