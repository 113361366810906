// @flow

import type { RailsModelID } from '@/shared_types/ids'
import type { ServerError, UnknownError } from '@/redux/graphql_queries/errorTypes'
import type { ShippingDetails } from '../message_types'
import { initialShippingDetails } from '../reducers'

type Success = {|
  type: 'Success',
  data: ShippingDetails
|}

type DecodedAddressRailsResponse = {|
  shipping_countries: $PropertyType<ShippingDetails, 'shippingCountries'>,
  shipping_country_code: $PropertyType<ShippingDetails, 'shippingCountryCode'>,
  delivery_area_name: $PropertyType<ShippingDetails, 'deliveryAreaName'>,
  regular_lead_time_in_hours: $PropertyType<ShippingDetails, 'regularLeadTimeInHours'>,
  ad_hoc_lead_time_in_hours: $PropertyType<ShippingDetails, 'adHocLeadTimeInHours'>,
  regular_deliverable_dates: $PropertyType<ShippingDetails, 'regularDeliverableDates'>,
  ad_hoc_deliverable_dates: $PropertyType<ShippingDetails, 'adHocDeliverableDates'>,
  is_eligible_for_carrier_preference: $PropertyType<ShippingDetails, 'isEligibleForCarrierPreference'>,
  carrier_preference: $PropertyType<ShippingDetails, 'carrierPreference'>,
  delivery_areas_user_id: $PropertyType<ShippingDetails, 'deliveryAreasUserId'>,
  can_manage_delivery_areas_user: $PropertyType<ShippingDetails, 'canManageDeliveryAreasUser'>
|}

type DecodedNoAddressRailsResponse = {|
  message: string
|}

type DecodedRailsResponse =
  | DecodedAddressRailsResponse
  | DecodedNoAddressRailsResponse

type RequestResult =
  | Success
  | ServerError
  | UnknownError

const { fetch } = global

const getShippingDetails = ({
  userId
}: {|
  userId: RailsModelID
|}): Promise<RequestResult> => {
  const endpoint = '/admin/single_customer_view/shipping_details'
  const queryParams = `user_id=${userId}`
  const method = 'GET'
  const credentials = 'same-origin'
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
  return fetch(`${endpoint}?${queryParams}`, { headers, method, credentials })
    .then((res: Response): Promise<RequestResult> => {
      if (!res.ok) {
        return res.text()
          .then((message: string): RequestResult => {
            return {
              type: 'ServerError',
              status: res.status,
              message
            }
          })
      }
      return res.json()
        .then((response: DecodedRailsResponse): RequestResult => {
          if (
            response.shipping_countries && response.shipping_country_code && response.delivery_area_name && response.regular_lead_time_in_hours && response.ad_hoc_lead_time_in_hours && response.regular_deliverable_dates && response.ad_hoc_deliverable_dates
          ) {
            return {
              type: 'Success',
              data: {
                ...initialShippingDetails,
                shippingCountries: response.shipping_countries,
                shippingCountryCode: response.shipping_country_code,
                deliveryAreaName: response.delivery_area_name,
                regularLeadTimeInHours: response.regular_lead_time_in_hours,
                adHocLeadTimeInHours: response.ad_hoc_lead_time_in_hours,
                regularDeliverableDates: response.regular_deliverable_dates,
                adHocDeliverableDates: response.ad_hoc_deliverable_dates,
                isEligibleForCarrierPreference: response.is_eligible_for_carrier_preference,
                carrierPreference: response.carrier_preference,
                deliveryAreasUserId: response.delivery_areas_user_id,
                canManageDeliveryAreasUser: response.can_manage_delivery_areas_user
              }
            }
          } else if (
            response.message === 'User does not have an address'
          ) {
            return {
              type: 'Success',
              data: {
                ...initialShippingDetails,
                noAddress: true
              }
            }
          } else {
            return {
              type: 'UnknownError',
              error: 'Unknown Error'
            }
          }
        })
    })
}

export type {
  RequestResult
}

export default getShippingDetails
