import isFunction from 'lodash/isFunction'
import isString from 'lodash/isString'
import isUndefined from 'lodash/isUndefined'
import React from 'react'
import { Link } from 'react-router-dom'

import BRAND_COLOURS from '@/constants/BrandColours'

import Icon from '@/components/elements/atoms/Icon/Icon'
import ProgressBar from '@/components/elements/atoms/ProgressBar/ProgressBar'
import Text from '@/components/elements/atoms/Text/Text'
import HeroText from '@/components/elements/organisms/HeroText/HeroText'

import STYLES from './WizardNavigation.module.sass'

type Variants = 'dashboard' | 'simplified'

type Props = {
  percentFilled: number
  title?: string
  subtitle?: string
  namespace?: string
  variant?: Variants
  variables?: { [key: string]: string | number }
  subtitleVariables?: { [key: string]: string | number }
  close?: string | (() => void)
  currentStep?: number
  totalSteps?: number
  backUrl?: string
  backOnClick?: () => void
}

type SimplifiedProps = {
  percentFilled: number
  currentStep: number
  totalSteps: number
  backUrl?: string
  backOnClick?: () => void
}

const SimplifiedNavigation = ({
  percentFilled,
  currentStep,
  totalSteps,
  backUrl,
  backOnClick
}: SimplifiedProps): JSX.Element => {
  return (
    <div className={`${STYLES.container} ${STYLES.simplified}`}>
      <div className={STYLES.progressBarWrapper}>
        <div className={STYLES.backButtonWrapper}>
          {backUrl ? (
            <Link to={backUrl} className={STYLES.backButton}>
              <Icon
                accentColour="brandBlue500"
                asset="chevron"
                size={18}
                direction="left"
              />
            </Link>
          ) : (
            <button
              type="button"
              className={STYLES.backButton}
              onClick={backOnClick}
            >
              <Icon
                accentColour="brandBlue500"
                asset="chevron"
                size={18}
                direction="left"
              />
            </button>
          )}
        </div>
        <ProgressBar
          fillColour={BRAND_COLOURS.brandYellow400}
          background="brandYellow200"
          percentFilled={percentFilled}
        />
      </div>
      <div className={STYLES.stepWrapper}>
        <Text
          variant="display16"
          text="wizard_navigation.steps"
          namespace="organisms"
          variables={{ currentStep, totalSteps }}
          shouldScale={false}
          margin={false}
        />
      </div>
    </div>
  )
}

const NavigationWithHeroText = ({
  percentFilled,
  title,
  subtitle,
  namespace,
  close,
  variables = {},
  subtitleVariables = {},
  variant
}: Omit<Props, 'currentStep' | 'totalSteps'>): JSX.Element => {
  return (
    <div
      className={`${STYLES.container} ${
        !isUndefined(variant) ? STYLES[variant] : ''
      }`}
    >
      <div className={STYLES.progressBarWrapper}>
        <ProgressBar
          fillColour={BRAND_COLOURS.brandBlue500}
          background={variant === 'dashboard' ? 'brandWhite' : 'grey200'}
          percentFilled={percentFilled}
        />
        {!isUndefined(close) && (
          <Link to={isString(close) ? close : '#'}>
            <button
              type="button"
              className={STYLES.close}
              onClick={isFunction(close) ? close : undefined}
            >
              <Icon accentColour="brandBlue500" asset="close" size={18} />
            </button>
          </Link>
        )}
      </div>
      <HeroText
        variant="wizard"
        title={title}
        subtitle={subtitle}
        namespace={namespace}
        variables={variables}
        subtitleVariables={subtitleVariables}
      />
    </div>
  )
}

const WizardNavigation = ({
  percentFilled,
  title,
  subtitle,
  namespace = '',
  close,
  variables = {},
  subtitleVariables = {},
  variant,
  currentStep = 1,
  totalSteps = 1,
  backUrl,
  backOnClick
}: Props): JSX.Element => {
  return variant === 'simplified' ? (
    <SimplifiedNavigation
      percentFilled={percentFilled}
      currentStep={currentStep}
      totalSteps={totalSteps}
      backUrl={backUrl}
      backOnClick={backOnClick}
    />
  ) : (
    <NavigationWithHeroText
      percentFilled={percentFilled}
      title={title}
      subtitle={subtitle}
      namespace={namespace}
      close={close}
      variables={variables}
      subtitleVariables={subtitleVariables}
      variant={variant}
    />
  )
}

export { Props }
export default WizardNavigation
