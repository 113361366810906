import { gql } from '@apollo/client'

const FLAVOUR_QUERY = gql`
  query FlavourQuery($slug: String!) {
    flavour(slug: $slug) {
      images {
        src
      }
      primaryImage {
        src
      }
      name
      longDescription
      tastingNotes
      productBenefits {
        name
        image {
          src
        }
      }
      composition
      flavourConstituents {
        percentage
        constituent {
          name
        }
      }
      additives
      storage
    }
  }
`

export { FLAVOUR_QUERY }
