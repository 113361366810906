import { gql } from '@apollo/client'

const STRIPE_CHARGE_BOX_ONE_MUTATION = gql`
  mutation StripeChargeBoxOneAndSetupPaymentMethod(
    $paymentMethodType: String!
  ) {
    stripeChargeBoxOneAndSetupPaymentMethod(
      paymentMethodType: $paymentMethodType
    ) {
      stripeSecretToken
      paymentIntentId
      boxCanBeCharged
    }
  }
`
export { STRIPE_CHARGE_BOX_ONE_MUTATION }
