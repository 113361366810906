// @noflow
import { Language } from '@/packs/localisation'
import { useReactiveVar } from '@apollo/client'
import { format, formatISO, parseISO } from 'date-fns'
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react'

import { localeToDateLocale } from '@/utils/countryCodeHelper'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import { Button } from '@/components/elements/atoms/Button'
import { ButtonSkeleton } from '@/components/elements/atoms/Button/ButtonSkeleton'
import { CardSkeleton } from '@/components/elements/atoms/Card/CardSkeleton'
import Modal from '@/components/elements/atoms/Modal/Modal'
import SkeletonParagraph from '@/components/elements/atoms/SkeletonParagraph/SkeletonParagraph'
import Text from '@/components/elements/atoms/Text'
import CalendarV2 from '@/components/elements/molecules/CalendarV2'
import { CalendarDatesV2Fragment as CalendarDates } from '@/components/elements/molecules/CalendarV2/fragments/__generated__/calendarDatesV2Fragment'
import CourierSelector from '@/components/elements/molecules/CourierSelector/CourierSelector'

import STYLES from './DeliveryDateModalWithCourier.module.sass'

import type { CheckoutPage } from '../../../queries/__generated__/CheckoutPage'
import type {
  DeliveryDateModalWithCourierQuery_deliveryAreaOptions as DeliveryArea,
  DeliveryDateModalWithCourierQuery
} from '../queries/__generated__/DeliveryDateModalWithCourierQuery'

import * as ANALYTICS from '../../../Analytics/CheckoutAnalytics'
import { checkoutPageState } from '../../../CheckoutPage'
import { generateEndOfLeadTime } from '../../../helpers/generateEndOfLeadTime'
import { checkoutPricingState } from '../../../hooks/useCheckoutPricing'

type Props = {
  namespace: string
  toggleDeliveryDateModal: Dispatch<SetStateAction<boolean>>
  isDeliveryDateModalOpen: boolean
  shippingCountryCode: CheckoutPage['guest']['assumedShippingCountry']['code']
  preferredLanguage: Language
  calendarDates: DeliveryDateModalWithCourierQuery['calendarDates']
  setCalendarDates: Dispatch<SetStateAction<CalendarDates[]>>
  deliveryDateWithCourierLoading: boolean
  deliveryDateWithCourierData?: DeliveryDateModalWithCourierQuery
}

const SkeletonDeliveryDateModal = (): JSX.Element => {
  return (
    <div className={STYLES.courierCalendar}>
      <div className={STYLES.skeletonCourierWrapper}>
        <ButtonSkeleton height={60} />
        <ButtonSkeleton height={60} />
      </div>
      <SkeletonParagraph shortLine={false} count={1} height="2.4rem" />
      <CardSkeleton height="43rem" />
    </div>
  )
}

const DeliveryDateModalWithCourier = ({
  namespace,
  toggleDeliveryDateModal,
  isDeliveryDateModalOpen,
  shippingCountryCode,
  preferredLanguage,
  calendarDates,
  setCalendarDates,
  deliveryDateWithCourierLoading,
  deliveryDateWithCourierData
}: Props): JSX.Element => {
  const copyContext = 'delivery_date_modal'

  const { windowWidth } = useWindowSize()

  const { sections } = checkoutPageState()
  const { deliveryDetails } = sections
  const { form: deliveryDetailsForm } = deliveryDetails
  const { selectedDeliveryDate } = deliveryDetailsForm

  const { firstOrderPricing } = useReactiveVar(checkoutPricingState)
  const { netDeliverySurchargePrice } = firstOrderPricing

  const [selectedDate] = useState<Date | null>(null)
  const [deliveryDetailsUpdated, setDeliveryDetailsUpdated] = useState(false)

  const dateLocale = localeToDateLocale(shippingCountryCode, preferredLanguage)

  const hasFreeDelivery = netDeliverySurchargePrice === 0

  const setSelectedDate = useCallback(
    (date: Date | null) => {
      if (date === null) return

      const matchedAvaliableDeliveryDate = calendarDates.find(
        (add) => add.date === formatISO(date, { representation: 'date' })
      )
      if (!matchedAvaliableDeliveryDate)
        throw new Error(
          `Could not find a matching availableDeliveryDate in DeliveryDateModalWithCourier`
        )

      const endOfLeadTime = generateEndOfLeadTime(matchedAvaliableDeliveryDate)

      sections.deliveryDetails.form.selectedDeliveryDate.value =
        matchedAvaliableDeliveryDate.date
      sections.deliveryDetails.form.selectedDeliveryDate.endOfLeadTime =
        endOfLeadTime
      sections.deliveryDetails.valid = true

      setDeliveryDetailsUpdated(true)

      checkoutPageState({
        ...checkoutPageState(),
        sections
      })

      ANALYTICS.deliveryDateUpdated({
        previousDate: sections.deliveryDetails.form.selectedDeliveryDate.value,
        newDate: formatISO(date, { representation: 'date' })
      })
    },
    [sections, calendarDates]
  )

  const setSelectedCarrier = useCallback(
    (selectedDeliveryArea: DeliveryArea) => {
      setCalendarDates(selectedDeliveryArea.calendarDates)
      sections.deliveryDetails.form.selectedDeliveryDate.selectedDeliveryArea =
        selectedDeliveryArea
      checkoutPageState({
        ...checkoutPageState(),
        sections
      })
    },
    [sections, setCalendarDates]
  )

  const closeModal = useCallback(
    () => toggleDeliveryDateModal(false),
    [toggleDeliveryDateModal]
  )

  return (
    <Modal
      isModalOpen={isDeliveryDateModalOpen}
      setOpenModal={toggleDeliveryDateModal}
      width={600}
      fullHeight={windowWidth < BREAKPOINTS.md}
      backgroundColour="brandYellow100"
    >
      {!deliveryDateWithCourierLoading && deliveryDateWithCourierData ? (
        <>
          <div className={STYLES.courierCalendar}>
            {deliveryDateWithCourierData.deliveryAreaOptions.length > 1 &&
              selectedDeliveryDate.selectedDeliveryArea && (
                <CourierSelector
                  deliveryAreaOptions={
                    deliveryDateWithCourierData.deliveryAreaOptions
                  }
                  selectedCarrier={selectedDeliveryDate.selectedDeliveryArea}
                  selectedDate={parseISO(selectedDeliveryDate.value)}
                  setSelectedCarrier={setSelectedCarrier}
                  setSelectedDate={setSelectedDate}
                  setCalendarDates={setCalendarDates}
                  subText={
                    hasFreeDelivery
                      ? {
                          namespace,
                          text: `${copyContext}.free_delivery`,
                          colour: 'supportGreen500'
                        }
                      : undefined
                  }
                />
              )}
            <CalendarV2
              selectedDate={selectedDate}
              calendarDates={calendarDates}
              currentDeliveryDate={parseISO(selectedDeliveryDate.value)}
              currentDeliveryDates={[parseISO(selectedDeliveryDate.value)]}
              shippingCountryCode={shippingCountryCode}
              setSelectedDate={setSelectedDate}
              scrollToSelectedDate={false}
              maxMonthsShown={2}
              noCalendarBorder
            />
          </div>
          {deliveryDetailsUpdated && (
            <div className={STYLES.updatedInfo}>
              <Text
                namespace={namespace}
                text={`${copyContext}.text_html`}
                variables={{
                  dayName: format(
                    parseISO(selectedDeliveryDate.value),
                    'EEEE',
                    {
                      locale: dateLocale
                    }
                  )
                }}
              />
              <div className={STYLES.cta}>
                <Button
                  typography={{
                    text: `${copyContext}.button_text`,
                    namespace
                  }}
                  onClick={closeModal}
                  fullWidth
                  identifier="update_delivery_date"
                  screenIdentifier="checkout_page"
                />
              </div>
            </div>
          )}
        </>
      ) : (
        <SkeletonDeliveryDateModal />
      )}
    </Modal>
  )
}

export default DeliveryDateModalWithCourier
