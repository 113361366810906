// @noflow
import React from 'react'
import { useTranslation } from 'react-i18next'

import segmentTrack from '@/components/analytics/Analytics'
import Card from '@/components/elements/atoms/Card/Card'
import Image from '@/components/elements/atoms/Image/Image'
import Text from '@/components/elements/atoms/Text/Text'
import { donationsModalState } from '@/components/pages/ReferAFriendPage/components/GiveAndGetTab/DonationsModal/DonationsModal'
import { ReferAFriendPageQuery } from '@/components/pages/ReferAFriendPage/queries/__generated__/ReferAFriendPageQuery'

import STYLES from '../../CardsCarousel.module.sass'

type Props = {
  namespace: string
  referrals: ReferAFriendPageQuery['user']['subscription']['referralLink']['referrals']
}

const DonationsCard = ({ namespace, referrals }: Props): JSX.Element => {
  const { t } = useTranslation('dashboard')
  const copyContext = 'refer_a_friend.give_and_get_tab.cards.donations'

  const handleDonationsClick = React.useCallback(() => {
    donationsModalState(true)
    segmentTrack('RAF Donations Modal opened')
  }, [])

  return (
    <Card
      onClick={handleDonationsClick}
      fill={false}
      identifier="refer_a_friend.give_and_get.donations_card"
    >
      <div className={STYLES.cardContentWrapper}>
        <Image
          slug="bowl-full-of-food-blue-bg"
          image={{ width: 228, height: 104 }}
          alt={t(`${copyContext}.alt`)}
        />
        <Text
          variant="display28"
          text={`${referrals.length}`}
          translate={false}
          margin={false}
          align="center"
        />
        <Text
          variant="textRegular16"
          text={`${copyContext}.description`}
          colour="brandBlue400"
          namespace={namespace}
          margin={false}
          align="center"
        />
      </div>
    </Card>
  )
}

export default DonationsCard
