// @noflow
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router'

import { Button } from '@/components/elements/atoms/Button'

import useSelfResolutionButtonTracking from '../../analytics'
import SelfResolutionRoutes from '../../types/routes'

type Props = {
  namespace: string
  tracking: string
}

const ContactCustomerLoveButton = ({
  namespace,
  tracking
}: Props): JSX.Element => {
  const selfResolutionButtonTracking = useSelfResolutionButtonTracking()
  const navigate = useNavigate()
  const contactUs = useCallback(() => {
    selfResolutionButtonTracking.trackEvent('contact CL clicked', {
      page: tracking
    })
    navigate(SelfResolutionRoutes.ContactCustomerLove)
  }, [selfResolutionButtonTracking, navigate, tracking])

  return (
    <Button
      variant={'ghost'}
      typography={{
        namespace,
        text: 'resolutionOffering.contactCustomerLove'
      }}
      onClick={contactUs}
      disableAnalytics
    />
  )
}

export default ContactCustomerLoveButton
