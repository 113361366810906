// @noflow
import React from 'react'
import { connect } from 'react-redux'

import { presetDescription, presetTitle } from '../models/dog'
import type { Preset } from '../models/dog'
import type { Dispatch, State } from '../reducer'
import { wizardPresetToggled } from '../slices/funnelSlice'

type PresentationalProps = {
  presets: Record<Preset, boolean>
}

type ActionProps = {
  wizardPresetToggled: (payload: Preset) => void
}

type Props = PresentationalProps & ActionProps

const mapStateToProps = (state: State): PresentationalProps => ({
  presets: state.funnel.presets
})

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
  wizardPresetToggled: (preset: Preset): void => {
    dispatch(wizardPresetToggled(preset))
  }
})

const PresetItem = ({
  preset,
  isSelected,
  presetToggled
}: {
  preset: Preset
  isSelected: boolean
  presetToggled: (preset: Preset) => void
}) => {
  const onClick = React.useCallback(() => {
    presetToggled(preset)
  }, [presetToggled, preset])

  return (
    <button
      className={`preset-item ${isSelected ? 'preset-item--selected' : ''}`}
      type="button"
      onClick={onClick}
    >
      <span className="preset-item__name">{presetTitle(preset)}</span>
      <span className="preset-item__description">
        {presetDescription(preset)}
      </span>
    </button>
  )
}

const PresetList = ({
  presets,
  wizardPresetToggled
}: Props): React.ReactElement => (
  <div className="preset-list">
    {(Object.keys(presets) as Array<Preset>).map((preset: Preset) => (
      <PresetItem
        key={preset}
        preset={preset}
        isSelected={presets[preset]}
        presetToggled={wizardPresetToggled}
      />
    ))}
  </div>
)

export default connect(mapStateToProps, mapDispatchToProps)(PresetList)
