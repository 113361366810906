/* eslint-disable react/jsx-no-bind */
/* eslint-disable i18next/no-literal-string */
/* eslint-disable react/require-optimization */
/* eslint-disable react/no-arrow-function-lifecycle */
/* eslint-disable react/destructuring-assignment */
// @flow

import * as React from 'react'
import { Analytics } from '@/components/analytics/Analytics'

export type TabValues = {|
  title: string,
  tabTitle: string,
  hash: string,
  pathname: string
|}

type Props = {|
  tabs: Array<TabValues>,
  selectedIndex: number
|}

class SelectBarComponent extends React.Component<Props> {
  getButtonClass = (): string => {
    switch (this.props.selectedIndex) {
      case 0: // Summary Tab
        return 'yellow-blue'
      case 1: // Refer Tab
        return 'red-pink'
      case 2: // Sales Tab
        return 'blue-white'
      case 3: // Leaderboard
        return 'red-pink'
      default:
        return ''
    }
  }

  handleTabClick = (tab: TabValues): void => {
    // This component is only used in the influencers dashboard
    Analytics.track(`Influencer Dashboard ${tab.pathname} Tab Clicked`)
  }

  renderTab = (tab: TabValues, i: number): React.Node => {
    let buttonClass = null
    if (i === this.props.selectedIndex) {
      buttonClass = `btn-${this.getButtonClass()}-hover`
    } else {
      buttonClass = `btn-${this.getButtonClass()}-color`
    }
    return (
      <div key={i}>
        <div className='mobile-display'>
          <div className='tab-item'>
            <a
              className={`btn ${buttonClass}`}
              href={tab.pathname}
              id={i}
              onClick={(): void => { this.handleTabClick(tab) }}
            >
              { tab.tabTitle }
            </a>
          </div>
        </div>
        <div className='desktop-and-tablet-display'>
          <div className='tab-item'>
            <a
              className={`btn ${buttonClass}`}
              href={tab.pathname}
              id={i}
            >
              { tab.tabTitle }
            </a>
          </div>
        </div>
      </div>
    )
  }

  render = (): React.Node => (
    <div className='selectbar'>
      { this.props.tabs.map(this.renderTab) }
    </div>
  )
}

export default SelectBarComponent
