import { ACCOUNT_ROUTES } from '@/routes'
import { useReactiveVar } from '@apollo/client'
import Cookies from 'js-cookie'

import { featureFlagsDataVar, subscriptionDataVar } from '@/services/apollo'

import cookies from '@/utils/cookies'

const useMTFITB = (): void => {
  const subscription = useReactiveVar(subscriptionDataVar)
  const featureFlagsData = useReactiveVar(featureFlagsDataVar)

  const shouldSeeSwapToMFITBPlanOfferPage =
    featureFlagsData?.shouldSeeSwapToMFITBPlanOfferPage === 'variant'

  const canSwapToMFITBPlan = subscription?.increasedDeliveryCadencePlan?.id

  const shouldRedirectToMFITBPage =
    shouldSeeSwapToMFITBPlanOfferPage &&
    canSwapToMFITBPlan &&
    Cookies.get(cookies.swapToMFITBPlanOfferSeen) !== 'true'

  if (shouldRedirectToMFITBPage) {
    window.location.href = ACCOUNT_ROUTES.mfitbPlanOffer
  }
}

export { useMTFITB }
