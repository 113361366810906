// @noflow
import React from 'react'

import GuaranteeBadge from 'assets/images/icons/badges/guarantee-badge.svg'

import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './WizardFooter.module.sass'

type CustomerLoveProps = {
  variant: 'customerLove'
  namespace?: string
  title: string
  subtitle: string
  iconAlt: string
  illustration: {
    img: string
    imgAlt: string
    imgPosition: 'left' | 'right'
  }
}

type FreshFactProps = {
  variant: 'freshFacts'
  title: string
  subtitle: string
  illustration: {
    img: string
    imgAlt: string
    imgPosition: 'left' | 'right'
  }
}

type Props = CustomerLoveProps | FreshFactProps

const CustomerLoveVariant = ({
  namespace,
  title,
  subtitle,
  iconAlt,
  illustration
}: Omit<CustomerLoveProps, 'variant'>) => (
  <footer className={STYLES.footer}>
    <div className={STYLES.illustrationContainer}>
      <img
        alt={illustration.imgAlt}
        className={`${STYLES.illustration} ${STYLES[illustration.imgPosition]}`}
        src={illustration.img}
      />
      <img
        alt={iconAlt}
        className={STYLES.guaranteeBadge}
        src={GuaranteeBadge}
        height={38}
        width={40}
      />
    </div>
    <div className={STYLES.textContainer}>
      <Text
        namespace={namespace}
        text={title}
        margin={false}
        variant="textRegular16"
        element="p"
        colour="brandBlue500"
        bold
      />
      <Text
        namespace={namespace}
        text={subtitle}
        variant="textRegular16"
        element="p"
        colour="brandBlue500"
      />
    </div>
  </footer>
)

const FreshFactsVariant = ({
  title,
  subtitle,
  illustration
}: Omit<FreshFactProps, 'variant'>) => (
  <footer className={`${STYLES.freshFactFooter} notranslate`}>
    <div className={STYLES.freshFactContainer}>
      <img
        className={STYLES.freshFactIllustration}
        alt={illustration.imgAlt}
        src={illustration.img}
      />
      <Text
        colour="cardboardBrown"
        element="h3"
        variant="display20"
        text={title}
        margin={false}
        translate={false}
      />
      <Text
        colour="cardboardBrown"
        element="p"
        variant="textRegular14"
        text={subtitle}
        margin={false}
        translate={false}
      />
    </div>
  </footer>
)

const WizardFooter = (props: Props): JSX.Element => {
  const { variant } = props

  switch (variant) {
    case 'customerLove': {
      const { namespace, title, iconAlt, subtitle, illustration } = props
      return (
        <CustomerLoveVariant
          namespace={namespace}
          title={title}
          subtitle={subtitle}
          iconAlt={iconAlt}
          illustration={illustration}
        />
      )
    }
    case 'freshFacts': {
      const { title, subtitle, illustration } = props
      return (
        <FreshFactsVariant
          title={title}
          subtitle={subtitle}
          illustration={illustration}
        />
      )
    }
  }
}

export type { Props, CustomerLoveProps, FreshFactProps }
export default WizardFooter
