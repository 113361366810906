// @noflow
import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import Message from '@/components/shared/elements/Message'
import type { MessageLevel } from '@/components/shared/elements/Message'

import type { State } from '../../reducers'
import type { State as FormDataState } from '../../reducers/formDataReducer'
import type {
  RequestStatus,
  State as RequestsState
} from '../../reducers/requestsReducer'

type PresentationalProps = {
  submitContactForm: RequestsState['submitContactForm']
  viewingStep: FormDataState['viewingStep']
  form: FormDataState['form']
}

type Props = PresentationalProps

type Translate = (arg0: string) => string

const mapStateToProps = ({
  requests,
  formData
}: State): PresentationalProps => {
  const { submitContactForm } = requests
  const { viewingStep, form } = formData

  return {
    submitContactForm,
    viewingStep,
    form
  }
}

const levelFromStatus = (status: RequestStatus): MessageLevel => {
  switch (status) {
    case 'Pending': {
      throw new Error(`levelFromStatus not implemented for ${status}`)
    }
    case 'In Flight': {
      throw new Error(`levelFromStatus not implemented for ${status}`)
    }
    case 'Complete With Success': {
      return 'success'
    }
    case 'Complete With Error': {
      return 'error'
    }
  }
}

const contentFromStatus = (
  status: RequestStatus,
  form: FormDataState['form'],
  t: Translate
): string => {
  switch (status) {
    case 'Pending': {
      throw new Error(`contentFromStatus not implemented for ${status}`)
    }
    case 'In Flight': {
      throw new Error(`contentFromStatus not implemented for ${status}`)
    }
    case 'Complete With Success': {
      return t('form_request_message.generic_success')
    }
    // eslint-disable-next-line no-fallthrough
    case 'Complete With Error': {
      // Would love to translate a GraphQL error here
      return t('form_request_message.error')
    }
  }
}

const FormRequestMessage = ({
  submitContactForm,
  viewingStep,
  form
}: Props): React.ReactElement | null => {
  const { t } = useTranslation('contacts')
  return viewingStep === 'Query Selection' ? (
    <Message
      level={levelFromStatus(submitContactForm.status)}
      content={contentFromStatus(submitContactForm.status, form, t)}
    />
  ) : null
}

export default connect(mapStateToProps)(FormRequestMessage)
