// @noflow
import React from 'react'

import {
  ICON_COLOURS,
  IconColours
} from '@/components/elements/atoms/Icon/Icon'

type Props = {
  size: number
  accentColour: IconColours
  width?: number | string
}

const Close = ({ size, accentColour, width }: Props): JSX.Element => {
  return (
    <svg
      fill={ICON_COLOURS[accentColour]}
      height={size}
      viewBox="0 0 30 30"
      width={width || '100%'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m17.2274 15 12.3113-12.31131c.6151-.61508.6151-1.61231 0-2.227384-.6151-.615075-1.6123-.615075-2.2274 0l-12.3113 12.311294-12.31125-12.311294c-.61507-.615075-1.61231-.615075-2.227383 0-.615074.615074-.615074 1.612304 0 2.227384l12.311233 12.31131-12.311302 12.3113c-.615074.6151-.615074 1.6123 0 2.2274.615072.6151 1.612312.6151 2.227382 0l12.31132-12.3114 12.3114 12.3114c.615.6151 1.6123.6151 2.2274 0 .615-.6151.615-1.6123 0-2.2274z" />
    </svg>
  )
}

export default Close
