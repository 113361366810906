// @noflow
import React from 'react'
import { connect } from 'react-redux'

import { Button } from '@/components/shared/elements/Button/Button'

import * as ACTIONS from '../../actions'
import type { Dispatch } from '../../reducers'

type ActionProps = {
  setFormViewingStep: () => void
}

type Props = ActionProps

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => {
  const setFormViewingStep = (): void => {
    dispatch(ACTIONS.setFormViewingStep('Query Selection'))
  }

  return {
    setFormViewingStep
  }
}

const GetInTouch = ({ setFormViewingStep }: Props): React.ReactElement => {
  const namespace = 'contacts'
  return (
    <div className="contact-us__further-information__get-in-touch">
      <Button
        variant="secondary"
        onClick={setFormViewingStep}
        disableAnalytics
        typography={{
          text: 'get_in_touch.button_content',
          namespace
        }}
      />
    </div>
  )
}

export default connect(null, mapDispatchToProps)(GetInTouch)
