// @noflow
import React from 'react'

import {
  ICON_COLOURS,
  IconColours
} from '@/components/elements/atoms/Icon/Icon'

type Props = {
  size: number
  width?: number | string
  accentColour: IconColours
}

const Eye = ({
  size,
  width,
  accentColour = 'brandBlue500'
}: Props): JSX.Element => {
  return (
    <svg
      fill="none"
      height={size}
      viewBox="0 0 23 15"
      width={width || '100%'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.735 6.507C20.483 2.607 16.285 0 11.5 0 6.714 0 2.516 2.608.265 6.507a1.992 1.992 0 000 1.986C2.517 12.393 6.715 15 11.5 15c4.786 0 8.984-2.608 11.235-6.507a1.992 1.992 0 000-1.986zM11.5 13.043c-4.095 0-7.67-2.23-9.583-5.543a11.062 11.062 0 018.631-5.503 2.3 2.3 0 01.633 1.59c0 1.26-1.002 2.283-2.237 2.283S6.708 4.848 6.708 3.587v-.002a5.61 5.61 0 00-.639 2.61c0 3.062 2.432 5.544 5.431 5.544 3 0 5.43-2.482 5.43-5.543a5.6 5.6 0 00-1.113-3.364A11.116 11.116 0 0121.083 7.5c-1.913 3.314-5.488 5.543-9.583 5.543z"
        fill={ICON_COLOURS[accentColour]}
      />
    </svg>
  )
}

export default Eye
