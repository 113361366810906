// @noflow
import { ACCOUNT_ROUTES } from '@/routes'
import lowerCase from 'lodash/lowerCase'
import startCase from 'lodash/startCase'
import React from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import SkeletonParagraph from '@/components/elements/atoms/SkeletonParagraph/SkeletonParagraph'
import type { TextProps } from '@/components/elements/atoms/Text'
import Text from '@/components/elements/atoms/Text/Text'
import SwiperSlider from '@/components/elements/molecules/SwiperSlider/SwiperSlider'
import ImageCard from '@/components/elements/organisms/ImageCard/ImageCard'

import STYLES from './DetailsCarousel.module.sass'

import type { DogProfileQuery_user_dogs as Dog } from '../../queries/__generated__/DogProfileQuery'

type Props = {
  dog?: Dog
  loading?: boolean
}

type Detail = {
  type: string
  slug: string
  title: string
  content: Array<TextProps>
  action: {
    onClick: () => void
  }
}

const DetailsCarousel = ({ dog, loading = false }: Props): JSX.Element => {
  const { windowWidth } = useWindowSize()
  const navigate = useNavigate()

  const dogWeightInKg = (dog?.weightInGrams ?? 0) / 1000
  const allergies = dog?.allergens.map(({ name }) => name).join(', ')
  const issues = dog?.healthIssues.map(({ name }) => name).join(', ')

  const details: Array<Detail> = [
    {
      type: 'basic_info',
      slug: 'dog-profile-details',
      title: 'dog_profile.details.carousel_cards.basic_info.title',
      content: [
        {
          text: 'dog_profile.details.carousel_cards.basic_info.content.weight',
          variables: { weight: dogWeightInKg }
        },
        {
          text: 'dog_profile.details.carousel_cards.basic_info.content.body_shape',
          variables: { context: dog?.bodyShape ?? '' }
        },
        {
          text: 'dog_profile.details.carousel_cards.basic_info.content.exercise',
          variables: { context: dog?.exercise ?? '' }
        },
        {
          // eslint-disable-next-line i18next/no-literal-string
          text: `dog_profile.details.carousel_cards.basic_info.content.neutered_${
            dog?.neutered ? 'yes' : 'no'
          }`,
          variables: { context: dog?.gender ?? '' }
        }
      ],
      action: {
        onClick: () => {
          navigate(
            generatePath(ACCOUNT_ROUTES.dogBasicInfo, { dogId: dog?.id ?? '' })
          )
        }
      }
    },
    {
      type: 'medical_info',
      slug: 'dog-profile-medical-info',
      title: 'dog_profile.details.carousel_cards.medical_info.title',
      content: [
        {
          text: 'dog_profile.details.carousel_cards.medical_info.content.allergens',
          variables: {
            context:
              dog && dog?.allergens.length > 0 ? 'hasAllergens' : 'noAllergens',
            allergies: allergies ?? ''
          }
        },
        {
          text: 'dog_profile.details.carousel_cards.medical_info.content.health_issues',
          variables: {
            context: issues && issues?.length > 0 ? 'hasIssues' : 'noIssues',
            issues: issues ?? ''
          }
        }
      ],
      action: {
        onClick: () => {
          navigate(
            generatePath(ACCOUNT_ROUTES.dogMedicalInfo, {
              dogId: dog?.id ?? ''
            })
          )
        }
      }
    },
    {
      type: 'eating_habits',
      slug: 'dog-profile-eating-habits',
      title: 'dog_profile.details.carousel_cards.eating_habits.title',
      content: [
        {
          text: 'dog_profile.details.carousel_cards.eating_habits.content.eater',
          variables: { context: dog?.eaterType ?? '' }
        },
        {
          text: 'dog_profile.details.carousel_cards.eating_habits.content.snacking_habits',
          variables: { context: dog?.snackingHabits ?? '' }
        }
      ],
      action: {
        onClick: () => {
          navigate(
            generatePath(ACCOUNT_ROUTES.dogEatingHabits, {
              dogId: dog?.id ?? ''
            })
          )
        }
      }
    }
  ]

  const isMobile = windowWidth < BREAKPOINTS.md

  return (
    <SectionWrapper
      headerTypography={{
        text: 'dog_profile.details.title',
        namespace: 'account',
        translate: true,
        variables: { context: loading ? 'loading' : dog?.gender ?? '' }
      }}
    >
      <div
        id="dog-profile-dog-details"
        className={`${STYLES.wrapper} ${
          !isMobile && loading ? STYLES.loading : ''
        }`}
      >
        {isMobile && (
          <SwiperSlider
            className={`${STYLES.slider} ${loading ? STYLES.loading : ''}`}
            arrows={false}
            slidesPerView="auto"
            skeleton={loading ? { width: '100%', height: '20rem' } : undefined}
          >
            {details.map(({ type, slug, title, action, content }) => (
              <div className={STYLES.imageWrapper} key={type}>
                <ImageCard
                  shadow
                  className={STYLES.imageCard}
                  padding={16}
                  image={{
                    width: isMobile ? 100 : 200,
                    height: isMobile ? 146 : 175
                  }}
                  slug={slug}
                  alt={startCase(lowerCase(slug))}
                  heading={{
                    text: title,
                    namespace: 'account'
                  }}
                  position={isMobile ? 'left' : 'top'}
                  button={
                    !isMobile
                      ? {
                          text: {
                            text: 'dog_profile.details.carousel_cards.action',
                            namespace: 'account',
                            translate: true
                          },
                          onClick: action.onClick
                        }
                      : undefined
                  }
                  fill={false}
                  onClick={action.onClick}
                  skeleton={{
                    isLoading: loading,
                    height: '25rem'
                  }}
                  identifier="dog_profile.details.carousel_cards"
                >
                  <div>
                    {loading ? (
                      <SkeletonParagraph
                        count={4}
                        width={'60%'}
                        shortLine={false}
                      />
                    ) : (
                      content.map((item) => (
                        <Text
                          key={item.text as string}
                          namespace="account"
                          margin={false}
                          {...item}
                        />
                      ))
                    )}
                  </div>
                </ImageCard>
              </div>
            ))}
          </SwiperSlider>
        )}

        {!isMobile && (
          <div className={STYLES.imageWrapper}>
            {details.map(({ type, slug, title, action, content }) => (
              <ImageCard
                key={type}
                shadow
                className={STYLES.imageCard}
                padding={16}
                image={{
                  width: isMobile ? 100 : 200,
                  height: isMobile ? 146 : 175
                }}
                slug={slug}
                alt={startCase(lowerCase(slug))}
                heading={{
                  text: title,
                  namespace: 'account'
                }}
                position={isMobile ? 'left' : 'top'}
                button={
                  !isMobile
                    ? {
                        text: {
                          text: 'dog_profile.details.carousel_cards.action',
                          namespace: 'account',
                          translate: true
                        },
                        onClick: action.onClick
                      }
                    : undefined
                }
                fill={false}
                onClick={action.onClick}
                skeleton={{
                  isLoading: loading,
                  height: '25rem'
                }}
                identifier="dog_profile.details.carousel_cards"
              >
                <div>
                  {loading ? (
                    <SkeletonParagraph
                      count={4}
                      width={'60%'}
                      shortLine={false}
                    />
                  ) : (
                    content.map((item) => (
                      <Text
                        key={item.text as string}
                        namespace="account"
                        margin={false}
                        {...item}
                      />
                    ))
                  )}
                </div>
              </ImageCard>
            ))}
          </div>
        )}
      </div>
    </SectionWrapper>
  )
}

export { DetailsCarousel }
