// @flow

import { connect } from 'react-redux'
import type { State } from '../../index'
import MessageBannerComponent from '../../components/user_details/MessageBannerComponent'

type PresentationalProps = $ReadOnly<{|
  message: string,
  showMessage: boolean
|}>

export type ComponentProps =
  & PresentationalProps

function mapStateToProps (state: State): PresentationalProps {
  const { message, showMessage } = state.bannerMessage
  return { message, showMessage }
}

export default connect(
  mapStateToProps
)(MessageBannerComponent)
