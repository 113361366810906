// @flow

import * as React from 'react'
import BaseModalComponent from '../../../../shared/BaseModalComponent'
import i18next from 'i18next'

import TransitionalModal from '../../../../../../javascript/components/shared/TransitionalModal'
import DeleteIcon from '../../../../../../assets/images/icons/icon-delete.svg'

import type { BankAccount } from '../../../message_types'
import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'

type Props = {|
  bankAccount: BankAccount,
  cancelEdit: () => void,
  deleteBankAccount: BankAccount => void,
  persistBankAccount: BankAccount => void,
  shippingCountryCode: CountryCode
|}

type ChangesetValidity = {|
  accountHolderName: boolean,
  accountNumber: boolean,
  sortCode: boolean,
  iban: boolean,
  swiftCode: boolean
|}

type ChangesetFieldsTouched = ChangesetValidity

type State = {|
  changeset: BankAccount,
  changesetValidity: ChangesetValidity,
  changesetFieldsTouched: ChangesetFieldsTouched,
  editBankAccountModalIsMounted: boolean,
  editBankAccountModalIsOpen: boolean
|}

class EditingBankAccount extends BaseModalComponent<Props, State> {
  constructor (props: Props): void {
    super(props)

    const { bankAccount } = props

    const changesetValidity = {
      accountHolderName: this.validateAccountHolderName(bankAccount.account_holder_name),
      accountNumber: bankAccount.bank_account_type === 'uk' ? this.validateAccountNumber(bankAccount.account_number) : true,
      sortCode: bankAccount.bank_account_type === 'uk' ? this.validateSortCode(bankAccount.sort_code) : true,
      iban: bankAccount.bank_account_type === 'international' ? this.validateIBAN(bankAccount.iban) : true,
      swiftCode: bankAccount.bank_account_type === 'international' ? this.validateSwiftCode(bankAccount.swift_code) : true
    }

    const changesetFieldsTouched = {
      accountHolderName: false,
      accountNumber: false,
      sortCode: false,
      iban: false,
      swiftCode: false
    }

    this.state = {
      changeset: props.bankAccount.bank_account_type === 'uk'
        ? { ...props.bankAccount, bank_account_type: 'uk' }
        : { ...props.bankAccount, bank_account_type: 'international' },
      changesetValidity,
      changesetFieldsTouched,
      editBankAccountModalIsMounted: false,
      editBankAccountModalIsOpen: false
    }
  }

  namespace = 'ambassadors:dashboard.balance_tab'

  validateAccountHolderName = (name: string): boolean => {
    return name.length > 0
  }

  validateAccountNumber = (accountNumber: ?string): boolean => {
    // 12345678
    if (accountNumber === null || accountNumber === undefined) {
      return true
    } else {
      return accountNumber.length >= 8
    }
  }

  validateSortCode = (sortCode: ?string): boolean => {
    // XX-XX-XX
    if (sortCode === null || sortCode === undefined) {
      return true
    } else {
      return sortCode.length >= 8
    }
  }

  validateIBAN = (iban: ?string): boolean => {
    if (iban === null || iban === undefined) {
      return true
    } else {
      return iban.length >= 15
    }
  }

  validateSwiftCode = (swiftCode: ?string): boolean => {
    if (swiftCode === null || swiftCode === undefined) {
      return true
    } else {
      return swiftCode.length >= 8
    }
  }

  setFieldTouched = (field: $Keys<ChangesetFieldsTouched>): (() => void) => {
    return (): void => {
      this.setState((state: State): State => {
        const changesetFieldsTouched = Object.freeze({
          ...state.changesetFieldsTouched,
          [field]: true
        })
        return { ...this.state, changesetFieldsTouched }
      })
    }
  }

  onAccountHolderNameChange = (e: SyntheticEvent<HTMLInputElement>): void => {
    const { value: accountHolderName } = e.currentTarget
    const changesetValidity = Object.freeze({
      ...this.state.changesetValidity,
      accountHolderName: this.validateAccountHolderName(accountHolderName)
    })
    this.setState((state: State): State => {
      const changeset = {
        ...state.changeset,
        account_holder_name: accountHolderName
      }
      return {
        ...state,
        /**
        * Flow cannot see that either international or UK will be fine here
        * $FlowFixMe
        **/
        changeset,
        changesetValidity
      }
    })
  }

  onAccountNumberChange = (e: SyntheticEvent<HTMLInputElement>): void => {
    const { value: accountNumber } = e.currentTarget
    this.setState((state: State): State => {
      if (state.changeset.bank_account_type === 'international') return state

      const changeset = Object.freeze({
        ...state.changeset,
        account_number: accountNumber
      })
      const changesetValidity = Object.freeze({
        ...state.changesetValidity,
        accountNumber: this.validateAccountNumber(accountNumber)
      })
      return {
        ...this.state,
        changeset,
        changesetValidity
      }
    })
  }

  onSortCodeChange = (e: SyntheticEvent<HTMLInputElement>): void => {
    const { value: sortCode } = e.currentTarget
    this.setState((state: State): State => {
      if (state.changeset.bank_account_type === 'international') return state

      const changeset = Object.freeze({
        ...state.changeset,
        sort_code: sortCode
      })
      const changesetValidity = Object.freeze({
        ...state.changesetValidity,
        sortCode: this.validateSortCode(sortCode)
      })
      return {
        ...state,
        changeset,
        changesetValidity
      }
    })
  }

  onIBANChange = (e: SyntheticEvent<HTMLInputElement>): void => {
    const { value: iban } = e.currentTarget

    this.setState((state: State): State => {
      if (state.changeset.bank_account_type === 'uk') return state

      const changeset = Object.freeze({
        ...state.changeset,
        iban: iban
      })
      const changesetValidity = Object.freeze({
        ...state.changesetValidity,
        iban: this.validateIBAN(iban)
      })
      return {
        ...state,
        changeset,
        changesetValidity
      }
    })
  }

  onSwiftCodeChange = (e: SyntheticEvent<HTMLInputElement>): void => {
    const { value: swiftCode } = e.currentTarget
    this.setState((state: State): State => {
      if (state.changeset.bank_account_type === 'uk') return state

      const changeset = Object.freeze({
        ...state.changeset,
        swift_code: swiftCode
      })
      const changesetValidity = Object.freeze({
        ...state.changesetValidity,
        swiftCode: this.validateSwiftCode(swiftCode)
      })
      return {
        ...this.state,
        changeset,
        changesetValidity
      }
    })
  }

  shouldShowErrorFor = (field: $Keys<ChangesetValidity>): boolean => {
    return !this.state.changesetValidity[field] && this.state.changesetFieldsTouched[field]
  }

  changesetIsValid = (): boolean => {
    const {
      accountHolderName,
      accountNumber,
      sortCode,
      iban,
      swiftCode
    } = this.state.changesetValidity
    return accountHolderName && (
      (this.props.bankAccount.bank_account_type === 'uk' && accountNumber && sortCode) ||
      (this.props.bankAccount.bank_account_type === 'international' && iban && swiftCode)
    )
  }

  inputClass = 'ambassador-balance__bank-details__content__input'

  inputClassFor = (field: $Keys<ChangesetValidity>): string => {
    if (this.shouldShowErrorFor(field)) {
      return `${this.inputClass} ${this.inputClass}--invalid`
    } else {
      return this.inputClass
    }
  }

  inputInvalidWarning = (): React.Node => {
    return (
      <div className='ambassador-balance__bank-details__content__input__warning'>
        { i18next.t(`${this.namespace}.something_missing`) }
      </div>
    )
  }

  render (): React.Node {
    return (
      <div>
        <div className='card__header full--width'>
          <p className='card__header__title'>{ i18next.t(`${this.namespace}.account_details`) }</p>
          { this.props.bankAccount.id !== null && this.props.bankAccount.id !== undefined &&
            <button
              className='card__header__button btn-updated btn-updated--grey'
              onClick={(): void => this.openModal('edit-bank-account-modal')}
            >
              <img src={DeleteIcon} alt='An illustration of a rubbish bin' />
              { i18next.t(`${this.namespace}.delete`) }
            </button>
          }
        </div>

        <div className='card__item'>
          <p className='card__item__heading'>
            { i18next.t(`${this.namespace}.account_holder`) }
          </p>
          <div className={`card__item ${this.inputClassFor('accountHolderName')}`}>
            <input
              // eslint-disable-next-line jsx-a11y/no-autofocus, react/jsx-boolean-value
              autoFocus={true}
              onBlur={this.setFieldTouched('accountHolderName')}
              onChange={this.onAccountHolderNameChange}
              placeholder='e.g. Jane Smith'
              type='text'
              value={this.state.changeset.account_holder_name}
            />
            {
              this.shouldShowErrorFor('accountHolderName') ? this.inputInvalidWarning() : null
            }
          </div>
        </div>

        { this.state.changeset.bank_account_type === 'uk' && (
          <React.Fragment>
            <div className='card__item'>
              <p className='card__item__heading'>
                { 'Account number' }
              </p>
              <div className={`card__item ${this.inputClassFor('accountNumber')}`}>
                <input
                  onBlur={this.setFieldTouched('accountNumber')}
                  onChange={this.onAccountNumberChange}
                  placeholder='8-digit account number'
                  type='number'
                  value={this.state.changeset.bank_account_type === 'uk' ? this.state.changeset.account_number : ''}
                />
                {
                  this.shouldShowErrorFor('accountNumber') ? this.inputInvalidWarning() : null
                }
              </div>
            </div>

            <div className='card__item'>
              <p className='card__item__heading'>
                { 'Bank sort code' }
              </p>
              <div className={`card__item ${this.inputClassFor('sortCode')}`}>
                <input
                  maxLength='8'
                  onBlur={this.setFieldTouched('sortCode')}
                  onChange={this.onSortCodeChange}
                  placeholder='6-digit number, e.g. 12-34-56'
                  type='text'
                  value={this.state.changeset.bank_account_type === 'uk' ? this.state.changeset.sort_code : ''}
                />
                {
                  this.shouldShowErrorFor('sortCode') ? this.inputInvalidWarning() : null
                }
              </div>
            </div>
          </React.Fragment>
        ) }

        { this.state.changeset.bank_account_type === 'international' && (
          <React.Fragment>
            <div className='card__item'>
              <p className='card__item__heading'>
                { 'IBAN' }
              </p>
              <div className={`card__item ${this.inputClassFor('iban')}`}>
                <input
                  maxLength='32'
                  onBlur={this.setFieldTouched('iban')}
                  onChange={this.onIBANChange}
                  placeholder='IBAN'
                  type='tel'
                  value={this.state.changeset.bank_account_type === 'international' ? this.state.changeset.iban : ''}
                />
                {
                  this.shouldShowErrorFor('iban') ? this.inputInvalidWarning() : null
                }
              </div>
            </div>

            <div className='card__item'>
              <p className='card__item__heading'>
                { 'Swift code' }
              </p>
              <div className={`card__item ${this.inputClassFor('swiftCode')}`}>
                <input
                  maxLength='11'
                  onBlur={this.setFieldTouched('sortCode')}
                  onChange={this.onSwiftCodeChange}
                  placeholder='Swift code'
                  type='tel'
                  value={this.state.changeset.bank_account_type === 'international' ? this.state.changeset.swift_code : ''}
                />
                {
                  this.shouldShowErrorFor('swiftCode') ? this.inputInvalidWarning() : null
                }
              </div>
            </div>
          </React.Fragment>
        ) }

        <div className='card__item'>
          <button
            className={`card__footer__button btn btn-updated--green btn-updated--${this.changesetIsValid() ? '' : 'disabled'}`}
            disabled={!this.changesetIsValid()}
            onClick={(): void => this.props.persistBankAccount(this.state.changeset)}
          >
            { i18next.t(`${this.namespace}.save`) }
          </button>
          <button
            className='card__footer__button btn btn-updated--grey'
            onClick={this.props.cancelEdit}
          >
            { i18next.t(`${this.namespace}.cancel`) }
          </button>
        </div>
        {this.state.editBankAccountModalIsMounted &&
        <TransitionalModal
          modalIsOpen={this.state.editBankAccountModalIsOpen}
          closeModal={(): void => this.closeModal('edit-bank-account-modal')}
          extraClasses='edit-bank-account-modal'
          showCloseButton={true}
          modalSize='medium'
          transitionTypes={{desktop: 'scale', mobile: 'scale'}}
        >
          <div className='ambassador-balance__bank-details__modal__header'>
            <p>{ i18next.t(`${this.namespace}.are_you_sure_delete`) }</p>
          </div>
          <div className='ambassador-balance__bank-details__modal__content'>
            <button
              className='btn btn-updated--blue'
              onClick={(): void => this.props.deleteBankAccount(this.state.changeset)}
            >
              { i18next.t(`${this.namespace}.delete_account_details`) }
            </button>
            <button
              className='btn btn-updated--grey'
              onClick={(): void => this.closeModal('edit-bank-account-modal')}
            >
              { i18next.t(`${this.namespace}.cancel`) }
            </button>
          </div>
        </TransitionalModal>
        }
      </div>
    )
  }
}

export default EditingBankAccount
