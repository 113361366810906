import { gql } from '@apollo/client'

// Fragments
import { CALORIE_DETAILS_FRAGMENT } from '../../DogProfile/components/CalorieDetails/fragments/calorieDetailsFragment'

const UPDATE_DOG_BASIC_INFO_MUTATION = gql`
  ${CALORIE_DETAILS_FRAGMENT}
  mutation UpdateDogBasicInfoMutation(
    $userId: ID!
    $attributes: [DogUpdateInput!]!
    $caloriesMultipleOf: Int!
    $percentageMultipleOf: Int!
    $lowerPercentage: Int!
    $upperPercentage: Int!
  ) {
    dogsUpdate(userId: $userId, attributes: $attributes) {
      id
      dogs {
        id
        name
        gender
        weightInGrams
        bodyShape
        exercise
        neutered
        ...CalorieDetailsFragment
      }
      subscription {
        id
        idealPlan {
          pricingCurve {
            pricingCohort
          }
        }
        plan {
          pricingCurve {
            pricingCohort
          }
        }
      }
    }
  }
`

export { UPDATE_DOG_BASIC_INFO_MUTATION }
