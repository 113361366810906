// @noflow
import { makeVar, useReactiveVar } from '@apollo/client'
import upperFirst from 'lodash/upperFirst'
import { QRCodeSVG } from 'qrcode.react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import BrandColours from '@/constants/BrandColours'
import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import Card from '@/components/elements/atoms/Card/Card'
import Image from '@/components/elements/atoms/Image/Image'
import Modal from '@/components/elements/atoms/Modal/Modal'
import Text from '@/components/elements/atoms/Text/Text'
import { ReferAFriendPageQuery } from '@/components/pages/ReferAFriendPage/queries/__generated__/ReferAFriendPageQuery'

import STYLES from './RAFPassModal.module.sass'

type Props = {
  namespace: string
  referralLink: ReferAFriendPageQuery['user']['subscription']['referralLink']
  firstName: ReferAFriendPageQuery['user']['firstName']
  dogNames: Array<string>
  avatarUrl?: string
}

const rafPassModalState = makeVar(false)

const RAFPassModal = ({
  namespace,
  referralLink,
  firstName,
  dogNames,
  avatarUrl
}: Props): JSX.Element => {
  const { t } = useTranslation('dashboard')
  const copyContext = 'refer_a_friend.give_and_get_tab.raf_pass_modal'

  const isRAFPassModalOpen = useReactiveVar(rafPassModalState)

  const { windowWidth } = useWindowSize()

  const { url } = referralLink

  return (
    <Modal
      isModalOpen={isRAFPassModalOpen}
      setOpenModal={rafPassModalState}
      width={600}
      showCloseButton
      bodyScroll
      bottomSticky={windowWidth < BREAKPOINTS.md}
    >
      <Text
        namespace={namespace}
        text={`${copyContext}.title`}
        variant="display20"
        align="center"
        margin={false}
      />
      <div className={STYLES.container}>
        <Card variant="brandYellow300" padding={0}>
          <div className={STYLES.rafPass}>
            <div className={STYLES.subtitleWrapper}>
              <Text
                namespace={namespace}
                text={`${copyContext}.subtitle`}
                variant="display16"
                colour="cardboardBrown"
                margin={false}
              />
            </div>
            {avatarUrl ? (
              <div className={STYLES.imageWrapper}>
                <img alt="Your dog" src={avatarUrl} />
              </div>
            ) : (
              <Image
                alt={t(`${copyContext}.img_alt`)}
                slug="dogs-scooby-and-drew"
                image={{
                  width: 335,
                  height: 137
                }}
              />
            )}
            <div className={STYLES.referrerDetails}>
              <Text
                namespace={namespace}
                text={`${copyContext}.butternutters`}
                variant="display16"
                colour="brandBlue600"
                margin={false}
              />
              <Text
                namespace={namespace}
                text={`${copyContext}.referrer_details`}
                variables={{
                  firstName: upperFirst(firstName),
                  count: dogNames.length,
                  dogName: dogNames[0]
                }}
                variant="display24"
                margin={false}
              />
            </div>
            <div className={STYLES.qrCode}>
              <QRCodeSVG
                value={`${url}?share_source=qrcode`}
                size={98}
                fgColor={BrandColours.brandBlue600}
              />
            </div>
          </div>
        </Card>
      </div>
    </Modal>
  )
}

export { rafPassModalState }
export default RAFPassModal
