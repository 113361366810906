import React from 'react'

import { useLocalisationHelper } from '@/hooks/useLocalisationHelper/useLocalisationHelper'

import Text from '@/components/elements/atoms/Text'

import STYLES from '../OrderSummaryModal.module.sass'

import { NonCoreOrderOrderSummaryModalFragment as NonCoreOrder } from '../fragments/__generated__/NonCoreOrderOrderSummaryModalFragment'

type Props = {
  order: NonCoreOrder
}

const NonCoreOrderPriceBreakdown = ({ order }: Props): JSX.Element => {
  const { formatPrice } = useLocalisationHelper()
  const namespace = 'modals'
  const context = 'order_summary.content'
  const { orderParts, invoice } = order
  const { deliverySurcharge, orderProductsTotal, smallOrderSurcharge } =
    orderParts[0]
  const { discountTotal, discountedPrice } = invoice
  const isDeliveryFree = deliverySurcharge === 0
  const isBoxFree = discountedPrice === 0
  return (
    <div>
      <div className={STYLES.row}>
        <div>
          <Text
            text={`${context}.labels.price`}
            namespace={namespace}
            margin={false}
          />
        </div>
        <div>
          <Text
            text={`${formatPrice(orderProductsTotal)}`}
            translate={false}
            margin={false}
          />
        </div>
      </div>
      <div className={STYLES.row}>
        <div>
          <Text
            text={`${context}.labels.delivery`}
            namespace={namespace}
            margin={false}
          />
        </div>
        <div>
          <Text
            text={
              isDeliveryFree
                ? `${context}.free`
                : `${formatPrice(deliverySurcharge)}`
            }
            namespace={namespace}
            translate={isDeliveryFree}
            margin={false}
          />
        </div>
      </div>
      <div className={STYLES.row}>
        <div>
          <Text
            text={`${context}.labels.small_order_fee`}
            namespace={namespace}
            margin={false}
          />
        </div>
        <div>
          <Text
            text={`${formatPrice(smallOrderSurcharge)}`}
            translate={false}
            margin={false}
          />
        </div>
      </div>
      {discountTotal > 0 && (
        <div className={STYLES.row}>
          <div>
            <Text
              text={`${context}.labels.discount`}
              namespace={namespace}
              margin={false}
            />
          </div>
          <div>
            <Text
              text={`${formatPrice(discountTotal)}`}
              translate={false}
              margin={false}
            />
          </div>
        </div>
      )}
      <div className={STYLES.row}>
        <div>
          <Text
            text={`${context}.labels.box_total`}
            namespace={namespace}
            variant="display16"
            margin={false}
          />
        </div>
        <div>
          <Text
            text={
              isBoxFree ? `${context}.free` : `${formatPrice(discountedPrice)}`
            }
            translate={isBoxFree}
            margin={false}
          />
        </div>
      </div>
    </div>
  )
}

export { NonCoreOrderPriceBreakdown }
