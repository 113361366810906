import { gql } from '@apollo/client'

// Used to poll the current user type to determine if we should redirect to the
// Thank You page. Ideally we would perhaps use a subscription
const USER_TYPE_QUERY = gql`
  query UserTypeQuery {
    currentUser {
      __typename
    }
  }
`

export { USER_TYPE_QUERY }
