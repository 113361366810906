const CONVERT_TO_PAID_MUTATION = `
  mutation ConvertToPaid(
    $userId: ID!,
    $creditCard: CreditCardInput!
  ) {
    convertToPaid(
      userId: $userId,
      creditCard: $creditCard
    ) {
      subscription {
        creditCard {
          lastFourDigits
        }
      }
    }
  }
`

export { CONVERT_TO_PAID_MUTATION }
