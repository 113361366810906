// @noflow
import type { Action } from 'redux'
import type { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { initialState as initialFormData } from './formDataReducer'
import type { State as FormData } from './formDataReducer'
import { initialState as initialGlobalAttributes } from './globalAttributesReducer'
import type { State as GlobalAttributes } from './globalAttributesReducer'
import { initialState as initialRequests } from './requestsReducer'
import type { State as Requests } from './requestsReducer'

type State = {
  globalAttributes: GlobalAttributes
  requests: Requests
  formData: FormData
}

type Thunk<R> = ThunkAction<R, State, unknown, Action<string>>

type Dispatch = ThunkDispatch<State, unknown, Action<string>>

const initialState: State = {
  globalAttributes: initialGlobalAttributes,
  requests: initialRequests,
  formData: initialFormData
}

export type { State, Thunk, Dispatch }

export { initialState }
