// @noflow
import React from 'react'
import { connect } from 'react-redux'

import { allPresets } from '../models/dog'
import type { Preset } from '../models/dog'
import type { User } from '../models/user'
import type { Dispatch, State } from '../reducer'
import { goToPlansClicked } from '../slices/funnelSlice'
import type { Submission } from '../slices/funnelSlice'

type PresentationalProps = {
  csrfToken: string
  user: User
  selectedPresets: Array<Preset>
  submission: Submission
}

type ActionProps = {
  goToPlansClicked: (payload: {
    user: User
    presets: Array<Preset>
    csrfToken: string
  }) => void
}

type Props = PresentationalProps & ActionProps

const mapStateToProps = (state: State): PresentationalProps => {
  const { user, presets, submission } = state.funnel
  const csrfToken = state.navigation.csrfToken
  const selectedPresets = allPresets.filter(
    (preset: Preset): boolean => presets[preset]
  )
  return {
    user,
    selectedPresets,
    submission,
    csrfToken
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
  goToPlansClicked: (payload: {
    user: User
    presets: Array<Preset>
    csrfToken: string
  }): void => {
    dispatch(goToPlansClicked(payload))
  }
})

const submissionButtonText = (submission: Submission): string => {
  switch (submission.type) {
    case 'notSubmittedYet':
      return 'Go to Plans'
    case 'submitting':
      return 'Going...'
    case 'failed':
      return 'Failed. Try again?'
    case 'submitted':
      return 'Redirecting'
  }
}

const subSubmissionButtonText = (submission: Submission): string | null => {
  switch (submission.type) {
    case 'notSubmittedYet':
      return null
    case 'submitting':
      return null
    case 'failed':
      return 'If this keeps happening, let the tech team know'
    case 'submitted':
      return '(give it a sec)'
  }
}

const FunnelCTA = ({
  submission,
  selectedPresets,
  user,
  csrfToken,
  goToPlansClicked
}: Props): React.ReactElement => {
  const buttonText = submissionButtonText(submission)
  const subButtonText = subSubmissionButtonText(submission)
  const onClick = React.useCallback(() => {
    goToPlansClicked({ user, csrfToken, presets: selectedPresets })
  }, [goToPlansClicked, user, csrfToken, selectedPresets])
  return (
    <div className="funnel-tab__content__cta">
      <button
        type="button"
        className="button"
        disabled={
          selectedPresets.length === 0 || submission.type === 'submitting'
        }
        onClick={onClick}
      >
        {buttonText}
      </button>
      {subButtonText && <p>{subButtonText}</p>}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(FunnelCTA)
