// @flow

import clone from 'lodash/clone'
import i18next from 'i18next'
import * as MESSAGES from '../actions/messages'

import type { DogProfilesReducerAction } from '../actions'
import type { RailsModelID as ID } from '../../../shared_types/ids'
import type { DogProfile, Dog } from '../message_types'
import type { Trait } from '../../../shared_types/rails_models/dog_personality_trait'
import type { Activity } from '../../../shared_types/rails_models/dog_activity'

import { traits } from '../../../shared_types/rails_models/dog_personality_trait'
import { activities } from '../../../shared_types/rails_models/dog_activity'
import { ILLUSTRATIONS } from '../assets'

type State = {|
  [ID]: {
    ...DogProfile,
    avatarSrc: string,
    compliment: string
  }
|}

const copyContext = 'post_signup_wizard:upload_photo'

const initialState: State = Object.freeze({})

const favouriteGamesSelected = (state: State): boolean => {
  return Object.keys(state)
  // $FlowFixMe
    .reduce((acc: boolean, dogId: ID): boolean => {
      return acc && (state[dogId].favouriteGame !== 'unknown_favourite_game')
    }, true)
}

const activitiesSelected = (state: State): boolean => {
  return Object.keys(state)
  // $FlowFixMe
    .reduce((acc: boolean, dogId: ID): boolean => {
      return acc && activities.reduce((accActivities: boolean, activity: Activity): boolean => {
        return accActivities || state[dogId].activities[activity]
      }, false)
    }, true)
}

const traitsSelected = (state: State): boolean => {
  return Object.keys(state)
  // $FlowFixMe
    .reduce((acc: boolean, dogId: ID): boolean => {
      return acc && traits.reduce((accTraits: boolean, trait: Trait): boolean => {
        return accTraits || state[dogId].traits[trait]
      }, false)
    }, true)
}

/*
* This method sets an Object that has key value pairs for each of the possible
* traits. Where the key is the trait and the value is a boolean that states
* whether the trait has been selected or not. The values all initially get set
* to false as this is when a user is creating their dog profiles
*/
const initTraits = (): {| [Trait]: boolean |} => {
  return traits.reduce((acc: {| [Trait]: boolean |}, trait: Trait): {| [Trait]: boolean |} => {
    acc[trait] = false

    return acc
  }, clone({}))
}

/*
* This method sets an Object that has key value pairs for each of the possible
* traits. Where the key is the activity and the value is a boolean that states
* whether the activity has been selected or not. The values all initially get
* set to false as this is when a user is creating their dog profiles.
*/
const initActivities = (): {| [Activity]: boolean |} => {
  return activities.reduce((acc: {| [Activity]: boolean |}, activity: Activity): {| [Activity]: boolean |} => {
    acc[activity] = false

    return acc
  }, clone({}))
}

const init = (dogs: Array<Dog>): State => {
  return dogs.reduce((acc: State, { id, ageInMonths }: Dog): State => {
    const avatarSrc = ageInMonths < 18
      ? ILLUSTRATIONS.PuppyAvatar
      : ILLUSTRATIONS.AdultAvatar
    acc[id] = {
      favouriteGame: 'unknown_favourite_game',
      traits: initTraits(),
      activities: initActivities(),
      description: '',
      avatarSrc,
      compliment: ''
    }

    return acc
  }, clone(initialState))
}

const generateCompliment = (): string => {
  const compliments = i18next.t(`${copyContext}.compliments`, { returnObjects: true })
  const complimentText = compliments[Math.floor(Math.random() * compliments.length)]
  return complimentText
}

const reducer = (state: State = initialState, action: DogProfilesReducerAction): State => {
  switch (action.type) {
    case MESSAGES.SELECT_FAVOURITE_GAME: {
      return Object.freeze({
        ...state,
        [action.id]: {
          ...state[action.id],
          favouriteGame: action.favouriteGame
        }
      })
    }
    case MESSAGES.TOGGLE_ACTIVITY: {
      return Object.freeze({
        ...state,
        [action.id]: {
          ...state[action.id],
          activities: {
            ...state[action.id].activities,
            [action.activity]: !state[action.id].activities[action.activity]
          }
        }
      })
    }
    case MESSAGES.TOGGLE_TRAIT: {
      return Object.freeze({
        ...state,
        [action.id]: {
          ...state[action.id],
          traits: {
            ...state[action.id].traits,
            [action.trait]: !state[action.id].traits[action.trait]
          }
        }
      })
    }
    case MESSAGES.UPDATE_DOG_DESCRIPTION: {
      return Object.freeze({
        ...state,
        [action.id]: {
          ...state[action.id],
          description: action.description
        }
      })
    }
    case MESSAGES.SET_AVATAR_SRC: {
      return Object.freeze({
        ...state,
        [action.id]: {
          ...state[action.id],
          avatarSrc: action.src,
          compliment: generateCompliment()
        }
      })
    }
    default: {
      (action.type: empty) // eslint-disable-line
      return state
    }
  }
}

export type { State }
export {
  init,
  initTraits,
  initActivities,
  favouriteGamesSelected,
  activitiesSelected,
  traitsSelected
}

export default reducer
