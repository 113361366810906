// @noflow
import { useQuery } from '@apollo/client'
import Grid from '@material-ui/core/Grid'
import times from 'lodash/times'
import React, { useCallback } from 'react'

import * as Sentry from '@/utils/sentry'

import { Button } from '@/components/elements/atoms/Button'
import Text from '@/components/elements/atoms/Text/Text'
import ProductCard from '@/components/elements/molecules/ProductCard/ProductCard'
import ProductCardSkeleton from '@/components/elements/molecules/ProductCard/ProductCardSkeleton'

import STYLES from './ProductSection.module.sass'

import { PRODUCT_SECTION_QUERY } from '../NoDogsStateContent/queries/noDogsPageQuery'

import {
  ProductSectionQuery,
  ProductSectionQueryVariables
} from '../NoDogsStateContent/queries/__generated__/ProductSectionQuery'

const ProductSection = (): JSX.Element => {
  const namespace = 'dashboard'

  const { data, error, refetch, loading } = useQuery<
    ProductSectionQuery,
    ProductSectionQueryVariables
  >(PRODUCT_SECTION_QUERY, {
    variables: {
      limit: 3
    }
  })

  const retry = useCallback(() => {
    refetch()
  }, [refetch])

  if (error) {
    Sentry.captureException(`Error in PRODUCT_SECTION_QUERY`, {
      extra: { error },
      tags: {
        product: Sentry.Product.Account,
        team: Sentry.Team.Retention
      }
    })

    return (
      <Grid
        container
        justifyContent="center"
        className={STYLES.productsContainer}
      >
        <Grid item sm={12}>
          <Text
            namespace={namespace}
            text={'no_dogs.product_section.error_text'}
            variant="display24"
            margin={false}
          />
          <Button
            identifier="no_dogs_product_section_error_button"
            typography={{ text: 'no_dogs.product_section.error_button' }}
            variant="primary"
            onClick={retry}
          />
        </Grid>
      </Grid>
    )
  }
  const { shippingCountryCode, preferredLanguage } = data?.user || {}
  const productGroups = data?.upsellableProductCollections || []

  return (
    <Grid
      container
      justifyContent="center"
      className={STYLES.productsContainer}
    >
      <Grid item sm={12}>
        <Text
          namespace={namespace}
          text={'no_dogs.product_section.title'}
          variant="display24"
          margin={false}
        />
        <Text
          namespace={namespace}
          text={'no_dogs.product_section.body'}
          margin={false}
        />
      </Grid>
      {!loading && data ? (
        <div className={STYLES.productWrapper}>
          {productGroups.map((product) => {
            return (
              <ProductCard
                key={product.id}
                product={product}
                shippingCountryCode={shippingCountryCode}
                preferredLanguage={preferredLanguage}
                url={''} // Overriding the default ProductCard behaviour since for customers with no dogs we don't want them to access other dashboard pages (at least not for the time being) - also added pointer-events: none in the styles
                linkType="a"
                treatmentStyling // Hides size and price and removes interactivity
              />
            )
          })}
        </div>
      ) : (
        <div className={STYLES.productWrapper}>
          {times(3).map((i: number) => (
            <ProductCardSkeleton key={`card${i}`} />
          ))}
        </div>
      )}
    </Grid>
  )
}

export default ProductSection
