import { gql } from '@apollo/client'

import { DAILY_PORTION_SIZE_FRAGMENT } from '../components/DailyPortionSize/fragments/dailyPortionSizeFragment'
import { SHARE_PROFILE_FRAGMENT } from '../components/ShareProfile/fragment/shareProfileFragment'
import { RAF_PASS_FRAGMENT } from '../components/ShareRafPass/fragment/shareRafPassFragment'

const DOG_PROFILE_QUERY = gql`
  ${SHARE_PROFILE_FRAGMENT}
  ${RAF_PASS_FRAGMENT}
  ${DAILY_PORTION_SIZE_FRAGMENT}
  query DogProfileQuery(
    $caloriesMultipleOf: Int!
    $percentageMultipleOf: Int!
    $lowerPercentage: Int!
    $upperPercentage: Int!
  ) {
    archiveReasons
    user {
      id
      ...DailyPortionSizeFragment
      firstName
      shippingCountryCode
      preferredLanguage
      shippingCountry {
        id
        showCare
        vetNurseCalendlyUrl
      }
      dogs {
        id
        name
        ageInMonths
        gender
        eaterType
        snackingHabits
        possessivePronoun
        breed {
          key
          name
        }
        weightInGrams
        bodyShape
        exercise
        neutered
        dogProfile {
          id
          avatarUrl
          avatarImageAttached
          description
          dogActivities {
            activity
          }
          dogPersonalityTraits {
            trait
          }
          favouriteGame
        }
        healthIssues {
          id
          name
          warning
          information
        }
        allergens {
          id
          name
        }
        planPortionInGrams
        recommendedCareDetails {
          petCareProfessional {
            id
            name
            profession
            profilePicture
          }
          careType
        }
      }
      ...ShareProfileFragment
      ...ShareRafPassFragment
    }
  }
`

export { DOG_PROFILE_QUERY }
