// @flow

/* eslint-disable react/require-optimization */
/* eslint-disable react/jsx-no-literals */
/* eslint-disable i18next/no-literal-string */
/* eslint-disable react/no-arrow-function-lifecycle */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable flowtype/require-exact-type */

import * as React from 'react'
import i18next from 'i18next'
import type { MerchName } from './../merch'

type MerchOption = {|
  quantity_selected: number,
  max_quantity: number,
  name: MerchName,
  step: number,
  translation_key: string
|}

type Props = {|
  createMerchandiseOrder: ({ payload: { items: { [MerchName]: number } } }) => void,
  setAlertMessage: string => void,
  visible: boolean
|}

type State = {|
  selectedItems: Array<MerchOption>,
  requestInFlight: boolean
|}

const merchOptions: Array<MerchOption> = [
  {
    name: 'Business Cards',
    max_quantity: 100,
    quantity_selected: 0,
    step: 25,
    translation_key: 'business_cards'
  },
  {
    name: 'Discounted Ambassador Leaflets',
    max_quantity: 200,
    quantity_selected: 0,
    step: 50,
    translation_key: 'discounted_ambassador_leaflets'
  },
  {
    name: 'Posters',
    max_quantity: 100,
    quantity_selected: 0,
    step: 5,
    translation_key: 'posters'
  }
]

class MerchTab extends React.Component<Props, State> {
  constructor (props: Props): void {
    super(props)
    this.state = {
      requestInFlight: false,
      selectedItems: merchOptions
    }
  }

  namespace = 'ambassadors:dashboard.merch_tab'

  renderSelect = (merchOption: MerchOption): React.Node => {
    const { selectedItems } = this.state

    const quantityList = Array(merchOption.max_quantity + 1).fill(0).map((_zero: number, index: number): number => index)
      .filter((n: number): boolean => n % merchOption.step === 0)

    const currentItem = selectedItems.find((selectedItem: MerchOption): boolean => selectedItem.name === merchOption.name)

    if (!currentItem) {
      throw new Error(`Merch option is not in selected items array: ${merchOption.name}`)
    }

    const quantitySelected = currentItem.quantity_selected

    return (
      <div
        className='merch-selects'
        key={merchOption.name}
      >
        <label
          className='centered-block tripple-margin--top single-margin--bottom merch-select'
          name={merchOption.name}
          id={merchOption.name}
        >
          { i18next.t(`${this.namespace}.${merchOption.translation_key}`) }
        </label>
        <select
          className='centered-block tripple-margin--top single-margin--bottom merch-select'
          onChange={this.handleMerchQuantitySelection}
          name={merchOption.name}
          id={merchOption.name}
          value={quantitySelected}
        >
          {
            quantityList.map((listValue: number): React.Node => (
              <option
                key={listValue}
                value={listValue}
              >
                { listValue }
              </option>
            ))
          }
        </select>
      </div>
    )
  }

  renderOption = (value: number): React.Node => {
    const { selectedItems } = this.state
    return (
      <option
        key={value}
        value={value}
        selected={value === selectedItems}
      >
        { value }
      </option>
    )
  }

  handleMerchQuantitySelection = (e: SyntheticEvent<HTMLSelectElement>): void => {
    const {
      name: merchItem,
      value: quantity
    } = e.currentTarget

    const selectedItems = this.state.selectedItems.map((merchOption: MerchOption): MerchOption => {
      if (merchOption.name === merchItem) {
        return Object.freeze({...merchOption, quantity_selected: parseInt(quantity)})
      } else {
        return merchOption
      }
    })

    this.setState(
      {
        selectedItems: selectedItems
      }
    )
  }

  submitMerchRequest = (): void => {
    const items: { [MerchName]: number } = this.state.selectedItems.reduce((acc: { [MerchName]: number }, merchOption: MerchOption): {} => {
      if (merchOption.quantity_selected === 0) {
        return acc
      } else {
        return { ...acc, [merchOption.name]: merchOption.quantity_selected }
      }
    }, {})

    this.setState(
      {
        requestInFlight: true
      }
    )

    this.props.createMerchandiseOrder({
      payload: { items }
    })

    this.setState(
      {
        requestInFlight: false,
        selectedItems: merchOptions
      }
    )

    this.props.setAlertMessage(i18next.t(`${this.namespace}.order_submitted`))
  }

  totalQuantity = (): number => {
    const { selectedItems } = this.state
    return selectedItems
      .reduce((acc: number, { quantity_selected }: MerchOption): number => acc + quantity_selected, 0)
  }

  render (): React.Node {
    return (
      <div className={`visible-${this.props.visible.toString()} tab ambassador-merch`}>
        <div className='section'>
          <div className='merch-selection content-padding__extreme small-padding-top'>
            <p>
              { i18next.t(`${this.namespace}.out_of_merch`) }
            </p>
            <div>
              {
                merchOptions.map(this.renderSelect)
              }
            </div>
            <button
              className='btn-updated btn-updated--pink'
              onClick={this.submitMerchRequest}
              disabled={this.state.requestInFlight || this.totalQuantity() === 0}
            >
              { i18next.t(`${this.namespace}.order`) }
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default MerchTab
