// @flow

import { connect } from 'react-redux'
import * as React from 'react'
import type { Dispatch } from 'redux'

import type { State } from '../../../index'
import * as THUNKS from '../../../thunks'
import * as ACTIONS from '../../../actions'

type PresentationalProps = {|
  contact_preferences_modal_is_open: boolean,
  currentWantsToReceiveTreatsEmails: boolean,
  currentOptedOutOfWoolcoolReturnScheme: boolean,
  currentWantsToReceiveIce: boolean
|}

type ActionProps = {|
  toggleContactPreferencesModal: (() => void),
  updateAndCloseContactPreferences: (() => void),
  toggleCurrentTreatsEmailPreference: (() => void),
  toggleCurrentWoolcoolReturnSchemePreference: (() => void),
  toggleCurrentWantsToReceiveIcePreference: (() => void)
|}

type Props =
  & PresentationalProps
  & ActionProps

const mapStateToProps = (state: State): PresentationalProps => {
  const { contactPreferences } = state
  const {
    contact_preferences_modal_is_open,
    current_wants_to_receive_treats_emails,
    current_opted_out_of_woolcool_return_scheme: currentOptedOutOfWoolcoolReturnScheme,
    current_wants_to_receive_ice: currentWantsToReceiveIce
  } = contactPreferences
  const currentWantsToReceiveTreatsEmails = current_wants_to_receive_treats_emails
  return {
    contact_preferences_modal_is_open,
    currentWantsToReceiveTreatsEmails,
    currentOptedOutOfWoolcoolReturnScheme,
    currentWantsToReceiveIce
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => {
  const toggleContactPreferencesModal = (): void => {
    dispatch(ACTIONS.toggleContactPreferencesModal())
  }
  const updateAndCloseContactPreferences = (): void => {
    dispatch((_: void, getState: () => State): void => {
      const state = getState()
      const { contactPreferences, selectedUserIds } = state
      const { userId } = selectedUserIds
      dispatch(ACTIONS.toggleContactPreferencesModal())
      dispatch(THUNKS.updateContactPreferences(userId, contactPreferences))
    })
  }
  const toggleCurrentTreatsEmailPreference = (): void => {
    dispatch(ACTIONS.toggleCurrentTreatsEmailPreference())
  }
  const toggleCurrentWoolcoolReturnSchemePreference = (): void => {
    dispatch(ACTIONS.toggleCurrentWoolcoolReturnSchemePreference())
  }

  const toggleCurrentWantsToReceiveIcePreference = (): void => {
    dispatch(ACTIONS.toggleCurrentWantsToReceiveIcePreference())
  }

  return {
    toggleContactPreferencesModal,
    updateAndCloseContactPreferences,
    toggleCurrentTreatsEmailPreference,
    toggleCurrentWoolcoolReturnSchemePreference,
    toggleCurrentWantsToReceiveIcePreference
  }
}

const ContactPreferencesModal = ({
  contact_preferences_modal_is_open,
  toggleContactPreferencesModal,
  updateAndCloseContactPreferences,
  currentWantsToReceiveTreatsEmails,
  toggleCurrentTreatsEmailPreference,
  currentOptedOutOfWoolcoolReturnScheme,
  toggleCurrentWoolcoolReturnSchemePreference,
  currentWantsToReceiveIce,
  toggleCurrentWantsToReceiveIcePreference
}: Props): React.Node => {
  if (!contact_preferences_modal_is_open) { return null }
  return (
    <div className='modal-container'>
      <div className='modal-container__backdrop' />
      <div className='modal-container__modal'>
        <div className='contact-preferences-modal'>
          <h2>
            { `Change Contact Preferences` }
          </h2>
          <label
            className='preference-toggle'
          >
            <input
              defaultChecked={currentWantsToReceiveTreatsEmails}
              onChange={toggleCurrentTreatsEmailPreference}
              type="checkbox"
            />
            <span />
            { 'Happy to talk about treats' }
          </label>

          <label
            className='preference-toggle'
          >
            <input
              defaultChecked={currentOptedOutOfWoolcoolReturnScheme}
              onChange={toggleCurrentWoolcoolReturnSchemePreference}
              type="checkbox"
            />
            <span />
            { 'Opted out of WoolCool return scheme' }
          </label>

          <label
            className='preference-toggle'
          >
            <input
              defaultChecked={currentWantsToReceiveIce}
              onChange={toggleCurrentWantsToReceiveIcePreference}
              type="checkbox"
            />
            <span />
            { 'Wants to receive ice' }
          </label>

          <div className='contact-preferences-modal__footer'>
            <button
              className='card__button button--dark-grey'
              onClick={toggleContactPreferencesModal}
              type='button'
            >
              { 'Cancel' }
            </button>
            <button
              className='card__button button--green'
              onClick={updateAndCloseContactPreferences}
              type='button'
            >
              { 'Save Preferences' }
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactPreferencesModal)
