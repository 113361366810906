// @noflow
import Cookies from 'js-cookie'
import React, { ReactElement, useEffect, useRef } from 'react'

declare global {
  interface Window {
    Trustpilot: {
      loadFromElement: (element: HTMLElement, arg: boolean) => void
    }
  }
}

type Props = {
  businessUnitId: string
}

const Trustpilot = ({ businessUnitId }: Props): ReactElement => {
  const trustBoxRef = useRef<HTMLDivElement>(null)
  const language = Cookies.get('user_language')
  const shippingCountryCode = Cookies.get('user_country_code')
  const locale =
    language && shippingCountryCode
      ? `${language}-${shippingCountryCode}`
      : 'en-GB'

  useEffect((): void => {
    if (window.Trustpilot && trustBoxRef.current) {
      window.Trustpilot.loadFromElement(trustBoxRef.current, true)
    }
  }, [])
  return (
    <section className="trustpilot-section">
      <div
        ref={trustBoxRef}
        className="trustpilot-widget"
        data-businessunit-id={businessUnitId}
        data-locale={locale}
        data-style-height="50px"
        data-style-width="100%"
        data-template-id="5419b6ffb0d04a076446a9af"
        data-theme="light"
      />
    </section>
  )
}

export default Trustpilot
