// @flow

import * as React from 'react'
import type { ComponentProps } from '../containers/UserDetailsContainer'
import AccountDetailsContainer from '../containers/user_details/AccountDetailsContainer'
import SubscriptionDetails from './user_details/SubscriptionDetails'
import DogsDetailsContainer from '../containers/user_details/DogsDetailsContainer'
import DeliveriesDetailsComponent from '../components/user_details/DeliveriesDetails'
import DeliveriesHistory from './user_details/DeliveriesHistory'
import ApplicableDiscountsContainer from '../containers/user_details/ApplicableDiscountsContainer'
import CustomerHistoryContainer from '../containers/user_details/CustomerHistoryContainer'
import DeliveryDateCalendarModalComponent from './user_details/delivery_date_calendar/DeliveryDateCalendarModalComponent'
import ChangePlanModal from '../components/modals/ChangePlanModal'
import RestartSubscriptionModal from './modals/restart_subscription_modal'
import SwitchToStripeModal from './modals/SwitchToStripeModal'
import AddCreditCardToSubscriptionModal from './modals/AddCreditCardToSubscriptionModal'

class UserDetails extends React.Component<ComponentProps> {
  componentDidUpdate (): void {
    if (this.props.showUserContainers && this.props.fullName) {
      document.title = this.props.fullName + ' | SOC'
    }
  }

  render (): React.Node {
    if (this.props.showUserContainers) {
      return (
        <div className="user-details-view">
          <DeliveryDateCalendarModalComponent />
          <RestartSubscriptionModal />
          <ChangePlanModal />
          <SwitchToStripeModal />
          <AddCreditCardToSubscriptionModal />
          <AccountDetailsContainer />
          <SubscriptionDetails />
          <DogsDetailsContainer />
          <DeliveriesDetailsComponent />
          <DeliveriesHistory />
          <ApplicableDiscountsContainer />
          <CustomerHistoryContainer />
        </div>
      )
    }
    return null
  }
}

export default UserDetails
