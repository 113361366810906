// @noflow

/* eslint-disable react/jsx-no-bind */
import { useOccasion } from '@/context/festiveTheme/festiveTheme'
import { ACCOUNT_ROUTES } from '@/routes'
import isUndefined from 'lodash/isUndefined'
import upperFirst from 'lodash/upperFirst'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import BREAKPOINTS from '@/constants/Breakpoints'

import useBoolean from '@/hooks/useBoolean'
import useWindowSize from '@/hooks/useWindowSize'

import { Expand } from '@/components/elements/atoms/Animated/Animated'
import Card from '@/components/elements/atoms/Card/Card'
import FlatButton from '@/components/elements/atoms/FlatButton/FlatButton'
import Icon from '@/components/elements/atoms/Icon/Icon'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import SkeletonButton from '@/components/elements/atoms/SkeletonButton/SkeletonButton'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './DailyPortionSize.module.sass'

import type { DailyPortionSizeFragment_dogs as Dog } from './fragments/__generated__/DailyPortionSizeFragment'

import { CalorieDetails } from '../CalorieDetails'

type Props = {
  dog?: Dog
  dogIndex?: number
  loading?: boolean
}

const DailyPortionSize = ({
  dog,
  dogIndex,
  loading = false
}: Props): JSX.Element => {
  const { windowWidth } = useWindowSize()
  const navigate = useNavigate()

  const isMobile = windowWidth < BREAKPOINTS.md

  const { value: expanded, toggle: toggleExpanded } = useBoolean(false)

  const theme = (() => {
    const defaultTheme = {
      backgroundColor: 'brandPink400',
      bowlColor: 'brandRed400',
      textColor: 'brandWhite'
    } as const

    if (isUndefined(dogIndex)) {
      return defaultTheme
    }

    switch (dogIndex % 4) {
      case 0: {
        return {
          backgroundColor: 'brandPink200',
          bowlColor: 'brandRed400',
          textColor: 'brandWhite'
        } as const
      }
      case 1: {
        return {
          backgroundColor: 'brandBlue200',
          bowlColor: 'brandPink200',
          textColor: 'brandBlue500'
        } as const
      }
      case 2: {
        return {
          backgroundColor: 'brandBlue500',
          bowlColor: 'brandYellow200',
          textColor: 'brandBlue500'
        } as const
      }
      case 3: {
        return {
          backgroundColor: 'brandYellow300',
          bowlColor: 'brandBlue500',
          textColor: 'brandBlue500'
        } as const
      }
      default: {
        return defaultTheme
      }
    }
  })()

  const openPlanManagement = () => {
    navigate(ACCOUNT_ROUTES.planManagement)
  }

  const { xmas } = useOccasion()

  return (
    <div className={STYLES.wrapper}>
      <SectionWrapper
        headerTypography={{
          text: 'dog_profile.daily_portion_size.title',
          namespace: 'account',
          variables: { context: loading ? 'loading' : dog?.gender ?? '' }
        }}
        headerRightAdornment={{
          variant: 'custom',
          content: loading ? (
            <SkeletonButton width={50} height={32} />
          ) : (
            <FlatButton
              text={{
                text: 'dog_profile.daily_portion_size.action',
                namespace: 'account',
                translate: true
              }}
              onClick={openPlanManagement}
              variant={xmas ? 'blue200' : 'yellow200'}
              identifier="daily_portion_size.action"
            />
          )
        }}
        margin={{
          bottom: isMobile ? 0 : 32
        }}
      >
        <Card
          shadow
          padding={16}
          fill={false}
          skeleton={{
            isLoading: loading,
            height: '13.5rem'
          }}
          onClick={toggleExpanded}
          identifier="dog_profile.daily_portion_size.card"
        >
          <div className={STYLES.cardContent}>
            <div
              className={`${STYLES.bowlIconWrapper} ${
                STYLES[theme.backgroundColor]
              }`}
            >
              <Icon
                asset="dogBowl"
                width={isMobile ? 80 : 120}
                size={isMobile ? 62 : 94}
                accentColour={theme.bowlColor}
              />
              <div className={STYLES.portionAmount}>
                <Text
                  variant="display20"
                  text="dog_profile.daily_portion_size.portion_amount"
                  namespace="account"
                  variables={{
                    portionSize: dog?.planPortionInGrams ?? 0
                  }}
                  colour={theme.textColor}
                  margin={false}
                />
              </div>
            </div>
            <div className={STYLES.textWrapper}>
              <Text
                variant="display20"
                text="dog_profile.daily_portion_size.portion"
                namespace="account"
                variables={{
                  dogName: upperFirst(dog?.name ?? ''),
                  planPortionInGrams: dog?.planPortionInGrams ?? 0
                }}
                margin={false}
              />
              <div className={STYLES.toggle}>
                <div className={STYLES.label}>
                  <Text
                    text={`dog_profile.daily_portion_size.see_${
                      expanded ? 'less' : 'more'
                    }`}
                    namespace="account"
                    colour="brandBlue400"
                    margin={false}
                    align="left"
                  />
                </div>
                <div
                  className={`${STYLES.arrow} ${
                    expanded ? STYLES.arrowExpanded : ''
                  }`}
                >
                  <Icon asset="chevron" size={12} />
                </div>
              </div>
            </div>
          </div>
          {dog && (
            <Expand show={expanded}>
              <div className={STYLES.expandedContent}>
                <CalorieDetails dog={dog} expanded={expanded} />
              </div>
            </Expand>
          )}
        </Card>
      </SectionWrapper>
    </div>
  )
}

export { DailyPortionSize }
