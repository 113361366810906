// @noflow
import {
  ICON_COLOURS,
  IconColours
} from '@/components/elements/atoms/Icon/Icon'

type IconProps = {
  accentColour: IconColours
  height?: number | string
  width?: number | string
}

export type { IconProps }
export type { HomeProps } from './Home'
export type { DogProfileCollarProps } from './DogProfileCollar'
export type { ExtrasShopProps } from './ExtrasShop'
export type { RewardsProps } from './Rewards'

export { Home } from './Home'
export { DogProfileCollar } from './DogProfileCollar'
export { ExtrasShop } from './ExtrasShop'
export { CandyCane } from './CandyCane'
export { Rewards } from './Rewards'
export { ICON_COLOURS }
