import { gql } from '@apollo/client'

const PERSONAL_DETAILS_FRAGMENT = gql`
  fragment personalDetailsFragment on User {
    id
    firstName
    lastName
    email
    phone
  }
`

export { PERSONAL_DETAILS_FRAGMENT }
