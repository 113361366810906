// @noflow
import classnames from 'classnames'
import { TOptions } from 'i18next'
import isNull from 'lodash/isNull'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Icon from '@/components/elements/atoms/Icon/Icon'

import STYLES from './MultiLineTextField.module.sass'

type Props = {
  variant?: keyof Omit<typeof STYLES, 'container'>
  namespace?: string
  label: string
  onChange?: (value: string) => void
  onValidate?: (label: string, valid: boolean, value: string) => void
  initialValue?: string | null
  optional?: boolean
  rows?: number
  maxLength?: number
  variables?: TOptions | null
  translate?: boolean
  alwaysShowLabel?: boolean
  errorMessageCopy?: string | null
}

const MultiLineTextField = React.forwardRef(
  (
    {
      variant,
      namespace = 'text_field',
      label,
      onChange,
      onValidate,
      initialValue = '',
      optional = false,
      rows = 5,
      maxLength = 1000,
      variables,
      translate = true,
      alwaysShowLabel = false,
      errorMessageCopy = ''
    }: Props,
    ref: React.Ref<HTMLTextAreaElement>
  ): JSX.Element | null => {
    const [value, setValue] = useState(initialValue)
    const [valid, setValid] = useState<boolean>(false)
    const [visited, setVisited] = useState<boolean>(false)
    const [focused, setFocused] = useState<boolean>(false)
    const [changed, setChanged] = useState<boolean>(false)
    const { t } = useTranslation(namespace)

    useEffect(() => {
      if (!optional) {
        setValid(false)
      }
    }, [optional])

    const validate = useCallback(() => {
      let newValid
      if (changed && !isNull(value)) {
        if (maxLength) {
          newValid = value.length <= maxLength
        } else if (optional) {
          newValid = true
        } else {
          newValid = value.length > 0
        }
        setVisited(true)
        onValidate && onValidate(label, newValid, value)
        setValid(newValid)
      }
    }, [changed, value, optional, maxLength, onValidate, label])

    const onBlur = useCallback(() => {
      setFocused(false)
      validate()
    }, [setFocused, validate])

    const onFocus = useCallback(() => {
      setFocused(true)
    }, [setFocused])

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setChanged(e.currentTarget.value !== value)
        setValue(e.currentTarget.value)
        onChange && onChange(e.currentTarget.value)
      },
      [value, setValue, onChange]
    )

    const translatedLabel = useMemo(() => {
      if (!translate) {
        return label
      }
      const labelWithVariable = variables ? t(label, variables) : t(label)
      return optional
        ? `${labelWithVariable} - ${t('optional')}`
        : labelWithVariable
    }, [translate, variables, t, label, optional])

    const errored = (!valid && visited) || errorMessageCopy
    const errorMessage =
      errorMessageCopy ||
      t('error_message', {
        label: translatedLabel.toLowerCase()
      })

    const wrapperClassName = classnames(STYLES.wrapper, {
      [STYLES.focused]: focused
    })

    const containerClassName = classnames(
      STYLES.container,
      variant && [STYLES[variant]],
      {
        [STYLES.error]: errored
      }
    )

    return (
      <div className={wrapperClassName}>
        <div className={containerClassName}>
          <textarea
            ref={ref}
            onChange={handleChange}
            onFocus={onFocus}
            value={value || ''}
            onBlur={onBlur}
            rows={rows}
            maxLength={maxLength}
          />
          {(alwaysShowLabel || !value || value.length <= 0) && (
            <div
              className={`${STYLES.labelContainer} ${
                value && value.length > 0 ? STYLES.filled : ''
              }`}
            >
              <span className={`${errored ? STYLES.labelError : STYLES.label}`}>
                {translatedLabel}
              </span>
            </div>
          )}
          <div className={STYLES.maxLengthContainer}>
            <span className={STYLES.maxLengthLabel}>
              {`${value?.length || 0}/${maxLength}`}
            </span>
          </div>
        </div>
        {errored && (
          <div className={STYLES.helperText}>
            <div className={STYLES.errorIcon}>
              <Icon asset="errorCircle" size={12} accentColour="dangerRed300" />
            </div>
            <span className={STYLES.helperTextError}>{errorMessage}</span>
          </div>
        )}
      </div>
    )
  }
)

export type { Props }
export default MultiLineTextField
