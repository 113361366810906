/* globals fetch */
/* eslint-disable react/require-optimization */
/* eslint-disable react/no-arrow-function-lifecycle */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable flowtype/require-exact-type */
// @flow

import * as React from 'react'

import { tabs, shopTab } from './tabs'

import DashboardAlert from '../shared/DashboardAlert'
import SelectBar from './partials/SelectBar'
import MerchTab from './tabs/MerchTab'

import type { Ambassador } from './message_types'

export type MerchName =
  | 'Business Cards'
  | 'Discounted Ambassador Leaflets'
  | 'Posters'

type Props = {|
  ambassador: Ambassador,
  csrf_token: string,
  ambassadorShopPath: string
|}

type State = {|
  alertMessage: ?string,
  alertIsError: boolean
|}

class AmbassadorDashboardMerch extends React.Component<Props, State> {
  constructor (props: Props): void {
    super(props)
    this.state = {
      alertMessage: null,
      alertIsError: false,
    }
  }

  setAlertMessage = (alertMessage: string, alertIsError: boolean = false): void => {
    this.setState({alertMessage, alertIsError}, (): void => {
      window.scrollTo(0, 0)
      setTimeout((): void => {
        this.setState({alertMessage: null})
      }, 3000)
    })
  }

  createMerchandiseOrder = ({payload}: { payload: { items: { [MerchName]: number } } }): void => {
    const endpoint = '/api/ambassadors/merchandise_orders'
    const body = {
      token: this.props.ambassador.token,
      merchandise_order: {
        items: payload.items
      }
    }

    fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify(body),
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.props.csrf_token
      },
      mode: 'cors'
    }).then((res: Response): Promise<{ ambassador: Ambassador, error: string }> => {
      return res.json()
    }).then((data: { ambassador: Ambassador, error: string }): void => {
      if (data.error) {
        this.setAlertMessage(data.error, true)
      }
    })
  }

  render = (): React.Element<'div'> => (
    <div className='butternutbox-ambassador-dashboard'>
      <DashboardAlert
        message={this.state.alertMessage}
        isError={this.state.alertIsError}
        shippingCountryCode={this.props.ambassador.shipping_country_code}
        preferredLanguage={this.props.ambassador.preferred_language}
      />
      <div className={`affiliate-dashboard__${tabs[2].hash}`}>
        <div className='affiliate-dashboard container'>
          <SelectBar
            tabs={tabs}
            shopTab={ this.props.ambassadorShopPath ? shopTab : []}
            ambassador={this.props.ambassador}
            selectedIndex={2}
            ambassadorShopPath={this.props.ambassadorShopPath}
          />
          <MerchTab
            createMerchandiseOrder={this.createMerchandiseOrder}
            // eslint-disable-next-line react/jsx-boolean-value
            visible={true}
            setAlertMessage={this.setAlertMessage}
          />
        </div>
      </div>
    </div>
  )
}

export default AmbassadorDashboardMerch
