// @noflow
import { useQuery } from '@apollo/client'
import React from 'react'
import { useParams } from 'react-router-dom'

import { ErrorState } from '@/components/elements/organisms/ErrorState'

import { MEDICAL_INFO_QUERY } from './queries/medicalInfoQuery'

import type { MedicalInfoQuery } from './queries/__generated__/MedicalInfoQuery'

import { UpdateDogMedicalInfoContent } from './UpdateDogMedicalInfoContent'

const UpdateDogMedicalInfo = (): JSX.Element | null => {
  const { dogId: selectedDogId } = useParams()

  // TODO: Take advantage of the refetch function
  const { loading, data, error } =
    useQuery<MedicalInfoQuery>(MEDICAL_INFO_QUERY)

  const selectedDog = data?.user.dogs?.find(({ id }) => id === selectedDogId)

  if (!loading && error) {
    return (
      <ErrorState
        error={{
          name: 'Error retrieving dogs data',
          message: error.message,
          apollo: error
        }}
      />
    )
  }

  if (!loading && (!data || !selectedDog)) {
    return (
      <ErrorState
        error={{
          name: 'Could not find dog with id',
          message: 'Could not find dog with id ' + selectedDogId,
          extra: { selectedDogId }
        }}
      />
    )
  }

  return (
    <UpdateDogMedicalInfoContent
      {...data}
      selectedDog={selectedDog}
      loading={loading}
    />
  )
}

export { UpdateDogMedicalInfo }
