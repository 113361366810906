// @noflow
import { ApolloError, useMutation } from '@apollo/client'
import React, { ChangeEvent, ReactElement, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import withApollo from '@/components/apollo/withApollo'
import { StandardInputField } from '@/components/pages/CheckoutPage/components/InputField/InputField'
import * as inputHelpers from '@/components/pages/CheckoutPage/helpers/inputs'
import type { FormSection } from '@/components/pages/CheckoutPage/types'
import PlansRoutes from '@/components/pages/PlansPage/types/routes'
import WizardRoutes from '@/components/pages/SignupWizardPage/types/routes'
import { Button } from '@/components/shared/elements/Button/Button'

import { PRE_WIZARD_GUEST_ITERABLE_UPSERT_MUTATION } from './mutations/PreWizardGuestIterableUpsertMutation'

import type {
  PreWizardGuestIterableUpsertMutation,
  PreWizardGuestIterableUpsertMutationVariables
} from './mutations/__generated__/PreWizardGuestIterableUpsertMutation'

import CopyInputButton from './CopyInputButton'

const namespace = 'shared'
const copyContext = 'blog_discount_modal'

type InputProps = {
  shouldShowLeadCaptureForm: boolean
  discountCode: string
  discountPercentage: string
  funnelCompleted: boolean
}

const useStandardInputField = () => {
  const { t } = useTranslation(namespace)

  const [success, setSuccess] = useState(false)
  const [email, setEmail] = useState<FormSection>({
    errorMessage: null,
    value: '',
    inputInteractionState: 'NotInteractingNotInteracted'
  })

  const handleSubmitEmailError = useCallback(
    ({ graphQLErrors }: ApolloError) => {
      const error = graphQLErrors[0]
      const errorClass: string = (error.message || '').split('::').pop() || ''

      const errorMessages: { [key: string]: string } = {
        EmailAlreadySubscribedError: 'already_exists_error',
        EmailInvalidError: 'validaiton_error'
      }
      const errorKey = errorMessages[errorClass] || 'generic_error'

      setEmail({
        ...email,
        inputInteractionState: 'NotInteractingInteracted',
        errorMessage: t(`${copyContext}.email.${errorKey}`)
      })
    },
    [email, t]
  )

  const [submitEmailToIterable, { loading }] = useMutation<
    PreWizardGuestIterableUpsertMutation,
    PreWizardGuestIterableUpsertMutationVariables
  >(PRE_WIZARD_GUEST_ITERABLE_UPSERT_MUTATION, {
    onError: handleSubmitEmailError,
    onCompleted() {
      setSuccess(true)
    }
  })

  const handleOnSubmit = useCallback(
    () => submitEmailToIterable({ variables: { email: email.value } }),
    [email.value, submitEmailToIterable]
  )

  const validateEmail = useCallback((email: FormSection['value']): boolean => {
    // The `validate` function from `inputHelpers` returns
    // its own message error, but since we want to use a
    // custom one, we just check for `null` to provide
    // our own message.

    return !inputHelpers.validate(email, 'email')
  }, [])

  const handleOnChange = useCallback(
    ({ currentTarget: { value } }: ChangeEvent<HTMLInputElement>): void => {
      const inputInteractionState = inputHelpers.interactingState(
        email.inputInteractionState
      )
      const errorMessage = validateEmail(value)
        ? t(`${copyContext}.email.validation_error`)
        : null

      setEmail({ value, inputInteractionState, errorMessage })
    },
    [t, email, validateEmail]
  )

  return {
    email,
    loading,
    success,
    handleOnChange,
    handleOnSubmit
  }
}

const DiscountModalForm = ({
  shouldShowLeadCaptureForm,
  discountCode,
  discountPercentage,
  funnelCompleted
}: InputProps): ReactElement | null => {
  const { t } = useTranslation(namespace)

  const {
    email,
    loading,
    success: shouldHideForm,
    handleOnChange,
    handleOnSubmit
  } = useStandardInputField()

  const incompleteFunnelCtaKey = shouldShowLeadCaptureForm
    ? 'cta_variant'
    : 'cta'
  const ctaKey = funnelCompleted ? 'cta_plan' : incompleteFunnelCtaKey

  const inputLabelVariant = shouldHideForm
    ? 'title_variant_success'
    : 'title_variant'
  const inputLabel = shouldShowLeadCaptureForm ? inputLabelVariant : 'title'

  const handleOnClick = useCallback((): void => {
    if (shouldShowLeadCaptureForm) {
      handleOnSubmit()
    } else {
      window.location.href = funnelCompleted
        ? PlansRoutes.Plan
        : WizardRoutes.Name
    }
  }, [funnelCompleted, shouldShowLeadCaptureForm, handleOnSubmit])

  return (
    <>
      <p className="text-regular-16">
        {t(`${copyContext}.${inputLabel}`, { discountPercentage })}
      </p>
      {!shouldHideForm ? (
        <>
          {shouldShowLeadCaptureForm ? (
            <StandardInputField
              fieldName="email"
              dataTestId="blog-discount-email-input"
              input={email}
              onBlur={handleOnChange}
              onChange={handleOnChange}
            />
          ) : (
            <CopyInputButton
              copyLinkMessage={discountCode}
              namespace="dashboard"
              referralCode={discountCode}
            />
          )}
          <div className="discount-modal__cta-button-wrapper">
            <Button
              onClick={handleOnClick}
              disabled={!email.errorMessage || loading}
              typography={{
                text: `${copyContext}.${ctaKey}`,
                namespace: namespace,
                variables: { discountPercentage }
              }}
              screenIdentifier="Blog"
              identifier="Blog Exit Discount Modal"
            />
          </div>
        </>
      ) : null}
    </>
  )
}

export default withApollo(DiscountModalForm)
