// @noflow
import isUndefined from 'lodash/isUndefined'
import React from 'react'
import { useTranslation } from 'react-i18next'

import GenericAvatar from 'assets/images/illustrations/dogs/dog-profile-avatars/single-adult-dog.svg'

import Card from '@/components/elements/atoms/Card/Card'
import Text from '@/components/elements/atoms/Text/Text'
import Tooltip from '@/components/elements/atoms/Tooltip/Tooltip'

import STYLES from './FeedCard.module.sass'

type Props = {
  avatar?: string
  dogName: string
  dailyGrams: number
  dailyGramsHasChanged: boolean
}

const FeedCard = ({
  avatar,
  dogName,
  dailyGrams,
  dailyGramsHasChanged
}: Props): JSX.Element => {
  const namespace = 'molecules'
  const { t } = useTranslation(namespace)

  return (
    <div className={STYLES.container}>
      <Card shadow={false} squared>
        <div>
          <img
            alt={t('feed_card.avatar_alt', { dogName })}
            className={STYLES.avatar}
            src={!isUndefined(avatar) ? avatar : GenericAvatar}
          />
        </div>
        <div className={STYLES.description}>
          <Text
            text={dogName}
            translate={false}
            variant="display16"
            colour="brandBlue500"
            element="span"
          />
          <Text
            namespace={namespace}
            text="feed_card.daily_grams"
            variables={{ dailyGrams }}
            variant="textRegular14"
            colour="brandBlue500"
            element="span"
          />
          {dailyGramsHasChanged && (
            <Tooltip
              label={{
                namespace,
                text: 'feed_card.tooltip.label',
                variables: { dogName }
              }}
              flush="left"
              identifier="feed_card.tooltip"
            >
              <Text
                namespace={namespace}
                text="feed_card.tooltip.description"
                variables={{ dogName }}
                variant="textRegular14"
                colour="brandBlue500"
              />
            </Tooltip>
          )}
        </div>
      </Card>
    </div>
  )
}

export { Props }
export default FeedCard
