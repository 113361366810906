// @noflow
import { ACCOUNT_ROUTES } from '@/routes'
import { type ApolloError, useReactiveVar } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import type { ReactElement } from 'react'

import { featureFlagsDataVar } from '@/services/apollo'

import DogInABox from 'assets/images/illustrations/dogs/order-failed-dog.svg'

import { Button } from '@/components/elements/atoms/Button'
import SpeechBubble from '@/components/elements/atoms/SpeechBubble/SpeechBubble'
import Text, { Props as TextProps } from '@/components/elements/atoms/Text/Text'
import ExpertCard from '@/components/elements/molecules/ExpertCard/ExpertCard'
import { NavigateContext } from '@/components/pages/App'

import STYLES from './ErrorState.module.sass'

import { ErrorCard } from '../../atoms/ErrorCard'

type ErrorDetails = {
  name: string
  message: string
  apollo?: ApolloError
  extra?: Record<string, unknown>
}

type Props = {
  first_line?: TextProps
  second_line?: TextProps | null
  bubble?: TextProps
  button?: TextProps
  secondaryButton?: TextProps
  error: ErrorDetails
}

const ErrorState = ({
  first_line = {
    namespace: 'account',
    text: 'error_state.error_other.text_1'
  },
  second_line = {
    namespace: 'account',
    text: 'error_state.error_other.text_2'
  },
  bubble = {
    namespace: 'account',
    text: 'error_state.error_other.bubble_text_html',
    variables: { customerLoveLink: '/contact' }
  },
  button = {
    namespace: 'account',
    text: 'error_state.error_other.button'
  },
  secondaryButton = {
    namespace: 'account',
    text: 'error_state.error_other.secondary_button'
  },
  error
}: Props): ReactElement => {
  const featureFlagsData = useReactiveVar(featureFlagsDataVar)
  const shouldTrackErrorsOnErrorState =
    featureFlagsData?.shouldTrackErrorsOnErrorState
  const navigate = useContext(NavigateContext)

  // Handlers
  const tryAgain = useCallback((): void => window.location.reload(), [])
  const backToAccount = useCallback((): void => {
    navigate(ACCOUNT_ROUTES.base, ACCOUNT_ROUTES.base)
  }, [navigate])

  const customerLoveLink = useMemo(() => {
    const link = '/contact'
    const queryParams = new URLSearchParams({
      errorDetails: JSON.stringify({
        message: error.message,
        location: window.location.href
      })
    })
    return `${link}?${queryParams.toString()}`
  }, [error.message])

  useEffect(() => {
    window.scrollTo(0, 0)
    if (shouldTrackErrorsOnErrorState) {
      Sentry.captureException(error?.name ?? 'Error state shown', {
        extra: {
          message: error?.message,
          apollo: error?.apollo,
          errorStateShown: true,
          ...error?.extra
        }
      })
    }
  }, [error, shouldTrackErrorsOnErrorState])

  return (
    <div className={STYLES.container}>
      <div className={STYLES.content}>
        <img alt="Dog in a box" className={STYLES.image} src={DogInABox} />
        <Text
          namespace={first_line.namespace}
          text={first_line.text}
          variant="textMono18"
        />
        {second_line && (
          <Text
            namespace={second_line.namespace}
            text={second_line.text}
            variant="textMono18"
            margin={false}
          />
        )}
        <div className={STYLES.bubble}>
          <SpeechBubble
            footPosition="bottomLeft"
            text={{
              namespace: bubble.namespace,
              text: bubble.text,
              variables: { ...bubble.variables, customerLoveLink }
            }}
          />
          <ExpertCard
            namespace="expert"
            expert={{
              name: 'Keisha and Gizmo',
              description: 'keisha_gizmo.profession',
              imgSrc: 'Web/photos/people/keisha--square.jpg',
              imgAlt: 'keisha_gizmo.name'
            }}
          />
        </div>
        {error?.message && (
          <div className={STYLES.errorCard}>
            <ErrorCard
              message={error.message}
              location={window.location.href}
            />
          </div>
        )}
        <div className={STYLES.button}>
          <Button
            typography={{
              namespace: button.namespace,
              text: button.text
            }}
            onClick={tryAgain}
            variant="primary"
            identifier="error-state-try-again"
          />
          <Button
            typography={{
              namespace: secondaryButton.namespace,
              text: secondaryButton.text
            }}
            onClick={backToAccount}
            variant="secondary"
            identifier="error-state-back-to-account"
          />
        </div>
      </div>
    </div>
  )
}

export { ErrorState }
