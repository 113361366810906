// @noflow
import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import type { Dispatch } from 'redux'

import TextArea from '@/components/shared/elements/TextArea'

import * as ACTIONS from '../../actions'
import type { State } from '../../reducers'
import { isValidMessage } from '../../reducers/formDataReducer'
import type { GenericForm } from '../../reducers/formDataReducer'
import type { State as RequestsState } from '../../reducers/requestsReducer'

type PresentationalProps = {
  message: GenericForm['message']
  submitContactForm: RequestsState['submitContactForm']
}

type ActionProps = {
  updateMessage: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  validateMessage: (e: React.FocusEvent<HTMLTextAreaElement>) => void
}

type Props = PresentationalProps & ActionProps

const mapStateToProps = ({
  formData: { form },
  requests
}: State): PresentationalProps => {
  const { message } = form as GenericForm
  const { submitContactForm } = requests

  return {
    message,
    submitContactForm
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => {
  const updateMessage = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const value = e.currentTarget.value
    dispatch(
      ACTIONS.updateField('message', {
        value,
        interaction: 'Interacting'
      })
    )
  }

  const validateMessage = (e: React.FocusEvent<HTMLTextAreaElement>): void => {
    const value = e.currentTarget.value
    dispatch(
      ACTIONS.updateField('message', {
        value,
        interaction: isValidMessage(value)
          ? 'InteractedWithSuccess'
          : 'InteractedWithError'
      })
    )
  }

  return {
    updateMessage,
    validateMessage
  }
}

const MessageTextArea = ({
  message,
  submitContactForm,
  updateMessage,
  validateMessage
}: Props): React.ReactElement => {
  const copyContext = 'message_text_area'
  const { t } = useTranslation('contacts')
  return (
    <TextArea
      className=""
      disabled={submitContactForm.status === 'In Flight'}
      invalid={message.interaction === 'InteractedWithError'}
      invalidityMessage={t(`${copyContext}.invalidity_message`)}
      label={t(`${copyContext}.label`)}
      maxLength={5000}
      name="message"
      onBlur={validateMessage}
      onChange={updateMessage}
      required
      textAreaId="message"
      value={message.value}
      placeholder={t(`${copyContext}.placeholder`)}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageTextArea)
