import { CustomerIssueReportEntryInputInput as ReportIssueInput } from '@types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Button } from '@/components/elements/atoms/Button'
import QuantitySelector from '@/components/elements/atoms/QuantitySelector/QuantitySelector'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from '../ReportIssue.module.sass'

import { SelfResolutionCategories_user_subscription_box_potentialMissingItems as PotentialMissingItem } from '../../../queries/__generated__/SelfResolutionCategories'

type ProductQuantitySelectorProps = {
  reportIssueInput: Array<ReportIssueInput>
  productName: string
  maxValue: number
  question: string
  onQuantityChange: (quantity: number, id: string) => void
}

const ProductQuantitySelector = ({
  reportIssueInput,
  productName,
  question,
  maxValue,
  onQuantityChange
}: ProductQuantitySelectorProps) => {
  const onChange = useCallback(
    (quantity: number) => {
      onQuantityChange(quantity, reportIssueInput[0].associationValue as string)
    },
    [reportIssueInput, onQuantityChange]
  )

  return (
    <div className={STYLES.quantitySelector}>
      <Text variant="textRegular18" text={productName} translate={false} bold />
      <Text text={question} translate={false} />
      <QuantitySelector
        quantity={reportIssueInput[1].integerValue as number}
        minValue={0}
        maxValue={maxValue}
        onChange={onChange}
        identifier="product-amounts"
      />
    </div>
  )
}

type Props = {
  namespace: string
  selectedItems: Array<PotentialMissingItem>
  question: string
  associationId: string
  integerId: string
  onSubmit: (values: Array<Array<ReportIssueInput>>) => void
}

const ProductAmounts = ({
  namespace,
  selectedItems,
  question,
  associationId,
  integerId,
  onSubmit
}: Props): JSX.Element => {
  const [reportIssueInputs, setReportIssueInputs] = useState<
    Array<Array<ReportIssueInput>>
  >([])

  useEffect(() => {
    const initialReportIssueInputs: Array<Array<ReportIssueInput>> =
      selectedItems.map((selectedItem) => {
        const associationReportIssueInput: ReportIssueInput = {
          fieldId: associationId,
          associationValue: selectedItem.productId
        }
        const integerReportIssueInput: ReportIssueInput = {
          fieldId: integerId,
          integerValue: 0
        }
        return [associationReportIssueInput, integerReportIssueInput]
      })
    setReportIssueInputs(initialReportIssueInputs)
  }, [selectedItems, associationId, integerId])

  const onQuantityChange = useCallback(
    (quantity: number, id: string) => {
      const reportIssueInputsCopy = reportIssueInputs.slice()
      const reportIssueInput = reportIssueInputsCopy.find(
        (rii) => rii[0].associationValue === id
      ) as Array<ReportIssueInput>
      reportIssueInput[1].integerValue = quantity
      setReportIssueInputs(reportIssueInputsCopy)
    },
    [reportIssueInputs]
  )

  const renderProductAmounts = useCallback(() => {
    return reportIssueInputs.map((reportIssueInput) => {
      const productId = reportIssueInput[0].associationValue
      const selectedItem = selectedItems.find(
        (item) => item.productId === productId
      ) as PotentialMissingItem
      return (
        <ProductQuantitySelector
          key={productId}
          question={question}
          productName={selectedItem.name}
          maxValue={selectedItem.quantity}
          reportIssueInput={reportIssueInput}
          onQuantityChange={onQuantityChange}
        />
      )
    })
  }, [selectedItems, reportIssueInputs, onQuantityChange, question])

  const submit = useCallback(() => {
    onSubmit(reportIssueInputs)
  }, [reportIssueInputs, onSubmit])

  const haveNoMissingItems = useMemo((): boolean => {
    const numberThatHaveItemsMissing = reportIssueInputs.filter(
      (input): boolean => {
        return input[1] && (input[1].integerValue as number) > 0
      }
    ).length
    return numberThatHaveItemsMissing === 0
  }, [reportIssueInputs])

  return (
    <>
      {renderProductAmounts()}
      <div className={STYLES.stickyButton}>
        <Button
          typography={{
            namespace,
            text: 'reportIssue.submit'
          }}
          onClick={submit}
          disabled={haveNoMissingItems}
          disableAnalytics
        />
      </div>
    </>
  )
}

export default ProductAmounts
