import { gql } from '@apollo/client'

const ORDER_SUMMARY_FLAVOUR_FRAGMENT = gql`
  fragment orderSummaryFlavourFragment on NewFlavour {
    id
    name
    slug
    shortDescription
    primaryImage {
      src
    }
    recipeSurcharges {
      planSize
      amount
    }
  }
`

export { ORDER_SUMMARY_FLAVOUR_FRAGMENT }
