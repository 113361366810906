// @noflow
import { useCallback, useState } from 'react'
import type { SyntheticEvent } from 'react'

import useBoolean from '@/hooks/useBoolean'

type Args = {
  handleOnClose: () => void
}
type UseCloseOnSwipe = {
  handleSetScrolledToTop: (event: SyntheticEvent) => void
  onTouchStart: (event: SyntheticEvent) => void
  onTouchMove: (event: SyntheticEvent) => void
  onTouchEnd: () => void
}

// Close a modal on swipe to the bottom on touch devices
const useCloseOnSwipe = ({ handleOnClose }: Args): UseCloseOnSwipe => {
  const {
    value: scrolledToTop,
    setFalse: setScrolledToTopFalse,
    setTrue: setScrolledToTopTrue
  } = useBoolean(true)
  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)

  const MIN_SWIPE_DISTANCE = 50

  // Define if the modal is scrolled to the very top
  const handleSetScrolledToTop = useCallback(
    (event) => {
      if (event.target.scrollTop === 0) {
        setScrolledToTopTrue()
      } else {
        setScrolledToTopFalse()
      }
    },
    [setScrolledToTopTrue, setScrolledToTopFalse]
  )

  const onTouchStart = useCallback((event) => {
    // To avoid the swipe execution with usual touch events
    setTouchEnd(null)

    // Set the touch start position
    setTouchStart(event.targetTouches[0].clientY)
  }, [])

  const onTouchMove = useCallback((event) => {
    // Set the touch end position
    setTouchEnd(event.targetTouches[0].clientY)
  }, [])

  const onTouchEnd = useCallback(() => {
    if (!touchStart || !touchEnd) return

    // Measure the distance of the swipe
    const distance = touchStart - touchEnd

    // Check if the swipe is a bottom swipe
    const isBottomSwipe = distance < -MIN_SWIPE_DISTANCE

    /**
     * Close the modal if it's a bottom swipe and the modal is
     * scrolled to the very top.
     */
    if (isBottomSwipe && scrolledToTop) {
      handleOnClose()
    }
  }, [touchStart, touchEnd, scrolledToTop, handleOnClose])

  return {
    handleSetScrolledToTop,
    onTouchStart,
    onTouchMove,
    onTouchEnd
  }
}

export default useCloseOnSwipe
