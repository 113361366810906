import { useReactiveVar } from '@apollo/client'
import { useCallback } from 'react'

import { userDataVar } from '@/services/apollo'

import {
  LocaleCurrency,
  countryCodeToDefaultCurrency,
  countryCodeToDefaultCurrencySymbol,
  countryCodeToLocale,
  countryCodeToLocaleCurrency,
  countryCodeToPrivacyUrl,
  countrySpecificFacebookLink,
  countrySpecificInstagramLink,
  countrySpecificTikTokLink,
  localeToDateLocale
} from '@/utils/countryCodeHelper'
import {
  formatCurrencyWithDecimal,
  formatCurrencyWithoutDecimal
} from '@/utils/currency'

type SocialMedia = 'facebook' | 'instagram' | 'tiktok'

type LocalisationHelper = {
  getLocaleCurrency: () => LocaleCurrency
  getDefaultCurrencySymbol: () => string
  getDefaultCurrency: () => string
  getPrivacyUrl: () => string
  getSocialMediaLink: (socialMedia: SocialMedia) => string
  getLocale: () => string
  getDateLocale: () => Locale
  formatPrice: (price: number) => string
  formatPriceNoDecimal: (price: number) => string
}

const useLocalisationHelper = (): LocalisationHelper => {
  const userData = useReactiveVar(userDataVar)
  const countryCode = userData?.shippingCountry.code || 'GB'
  const language =
    userData?.preferredLanguage ||
    userData?.shippingCountry.defaultLanguage ||
    'en'

  const getLocaleCurrency = useCallback(
    () => countryCodeToLocaleCurrency(countryCode, language),
    [countryCode, language]
  )

  const getDefaultCurrencySymbol = useCallback(
    () => countryCodeToDefaultCurrencySymbol(countryCode),
    [countryCode]
  )

  const getDefaultCurrency = useCallback(
    () => countryCodeToDefaultCurrency(countryCode),
    [countryCode]
  )

  const getDateLocale = useCallback(
    () => localeToDateLocale(countryCode, language),
    [countryCode, language]
  )

  const getPrivacyUrl = useCallback(
    () => countryCodeToPrivacyUrl(countryCode),
    [countryCode]
  )

  const getSocialMediaLink = useCallback(
    (socialMedia: 'facebook' | 'instagram' | 'tiktok') => {
      switch (socialMedia) {
        case 'facebook':
          return countrySpecificFacebookLink(countryCode)
        case 'instagram':
          return countrySpecificInstagramLink(countryCode)
        case 'tiktok':
          return countrySpecificTikTokLink(countryCode)
        default:
          return ''
      }
    },
    [countryCode]
  )

  const getLocale = useCallback(
    () => countryCodeToLocale(countryCode, language),
    [countryCode, language]
  )

  const formatPrice = useCallback(
    (price: number) =>
      formatCurrencyWithDecimal(price, {
        currency: getDefaultCurrency(),
        locale: getLocale()
      }),
    [getDefaultCurrency, getLocale]
  )

  const formatPriceNoDecimal = useCallback(
    (price: number) =>
      formatCurrencyWithoutDecimal(price, {
        currency: getDefaultCurrency(),
        locale: getLocale()
      }),
    [getDefaultCurrency, getLocale]
  )

  return {
    getLocaleCurrency,
    getDefaultCurrencySymbol,
    getDefaultCurrency,
    getPrivacyUrl,
    getSocialMediaLink,
    getLocale,
    getDateLocale,
    formatPrice,
    formatPriceNoDecimal
  }
}

export { useLocalisationHelper, LocalisationHelper }
