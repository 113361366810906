// @noflow
import React from 'react'
import type { ReactElement } from 'react'
import { connect } from 'react-redux'

import { ensureNever } from '@/typescript/utils'

import type { State } from '../../../reducers'
import type { Form as FormData } from '../../../reducers/formDataReducer'
import GenericForm from '../GenericForm'

type PresentationalProps = {
  type: FormData['type']
}

type Props = PresentationalProps

const mapStateToProps = ({
  formData: {
    form: { type }
  }
}: State): PresentationalProps => ({
  type
})

const Form = ({ type }: Props): ReactElement => {
  switch (type) {
    case 'generic-form': {
      return <GenericForm />
    }
    default: {
      ensureNever(type)
      throw new Error(`Form type ${type} is not valid.`)
    }
  }
}

export default connect(mapStateToProps)(Form)
