import { useOccasion } from '@/context/festiveTheme/festiveTheme'
import { ACCOUNT_ROUTES } from '@/routes'
import { useQuery } from '@apollo/client'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import * as Sentry from '@/utils/sentry'

import useWindowSize from '@/hooks/useWindowSize'

import DeliveryDetails from './components/DeliveryDetails/DeliveryDetails'
import DogGramsPerDay from './components/DogGramsPerDay/DogGramsPerDay'
import Card from '@/components/elements/atoms/Card/Card'
import { CardSkeleton } from '@/components/elements/atoms/Card/CardSkeleton'
import FlatButton from '@/components/elements/atoms/FlatButton/FlatButton'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import Separator from '@/components/elements/atoms/Separator/Separator'
import { BREAKPOINTS } from '@/components/templates/Base'

import STYLES from './SubscriptionDetailsCard.module.sass'

import { SUBSCRIPTION_DETAILS_CARD_QUERY } from './queries/queries'

import { SubscriptionDetailsCardQuery } from './queries/__generated__/SubscriptionDetailsCardQuery'
import { SubscriptionStatus } from '@/types'

type Props = {
  namespace: string
  editable?: boolean
  variant?: 'card' | 'noCard'
}

type VariantProps = {
  namespace: string
  copyContext: string
  editable?: boolean
  editSubscription: () => void
  isMultiDog: boolean
  dogs: SubscriptionDetailsCardQuery['user']['dogs']
  gramsPerDayPerDogs: SubscriptionDetailsCardQuery['user']['subscription']['plan']['gramsPerDayPerDogs']
  durationInDays: SubscriptionDetailsCardQuery['user']['subscription']['plan']['durationInDays']
  numberOfPouches: SubscriptionDetailsCardQuery['user']['subscription']['plan']['numberOfPouches']
  loading?: boolean
}

const SubscriptionDetailsCardVariant = ({
  namespace,
  copyContext,
  editable,
  editSubscription,
  isMultiDog,
  dogs,
  gramsPerDayPerDogs,
  durationInDays,
  numberOfPouches,
  loading = false
}: VariantProps): JSX.Element => {
  const { xmas } = useOccasion()

  if (loading) {
    return (
      <div className={STYLES.wrapper}>
        <CardSkeleton height={200} />
      </div>
    )
  }
  return (
    <Card mask shadow padding={0}>
      <SectionWrapper
        margin={{ top: 0, bottom: 0 }}
        padding={{ top: 16, right: 24, left: 24, bottom: 16 }}
        bgColour={xmas ? 'brandBlue200' : 'brandYellow200'}
        headerTypography={{
          text: `${copyContext}.title`,
          namespace,
          margin: false,
          variant: 'display20'
        }}
        headerRightAdornment={
          editable
            ? {
                variant: 'custom',
                content: (
                  <FlatButton
                    text={{
                      text: `${copyContext}.cta`,
                      namespace,
                      translate: true
                    }}
                    variant={xmas ? 'blue' : 'yellow100'}
                    onClick={editSubscription}
                    identifier="subscription_details.edit_subscription"
                  />
                )
              }
            : undefined
        }
      />
      <div
        className={`${STYLES.content} ${
          isMultiDog ? STYLES.multiDog : STYLES.singleDog
        }`}
      >
        <div className={STYLES.dogGramsWrapper}>
          <DogGramsPerDay
            copyContext={copyContext}
            dogs={dogs || []}
            gramsPerDayPerDogs={gramsPerDayPerDogs}
            namespace={namespace}
            variant={isMultiDog ? 'largeAvatar' : 'smallAvatar'}
            showSeparator={isMultiDog}
          />
        </div>
        <div className={STYLES.separator}>
          <Separator handdrawn vertical />
        </div>
        <div className={STYLES.deliveryWrapper}>
          <DeliveryDetails
            cadenceInWeeks={durationInDays / 7}
            numberOfPouches={numberOfPouches}
            copyContext={copyContext}
            namespace={namespace}
            variant={isMultiDog ? 'twoLines' : 'threeLines'}
          />
        </div>
      </div>
    </Card>
  )
}

const SubscriptionDetailsNoCardVariant = ({
  namespace,
  copyContext,
  isMultiDog,
  dogs,
  gramsPerDayPerDogs,
  durationInDays,
  numberOfPouches,
  loading = false
}: Omit<VariantProps, 'editable' | 'editSubscription'>): JSX.Element => {
  const { windowWidth } = useWindowSize()
  const isMobile = windowWidth < BREAKPOINTS.md

  if (loading) {
    return (
      <div className={`${STYLES.contentNoCard} ${STYLES.singleDog}`}>
        <CardSkeleton height={100} />
        <div className={STYLES.separator}>
          <Separator handdrawn vertical={isMultiDog ? false : isMobile} />
        </div>
        <CardSkeleton height={100} />
      </div>
    )
  }
  return (
    <div
      className={`${STYLES.contentNoCard} ${
        isMultiDog ? STYLES.multiDog : STYLES.singleDog
      }`}
    >
      <DogGramsPerDay
        copyContext={copyContext}
        dogs={dogs || []}
        gramsPerDayPerDogs={gramsPerDayPerDogs}
        namespace={namespace}
        variant="smallAvatar"
        showSeparator={false}
        expand
      />
      <div className={STYLES.separator}>
        <Separator handdrawn vertical={isMultiDog ? false : isMobile} />
      </div>
      <DeliveryDetails
        cadenceInWeeks={durationInDays / 7}
        numberOfPouches={numberOfPouches}
        copyContext={copyContext}
        namespace={namespace}
        variant="threeLines"
      />
    </div>
  )
}

const SubscriptionDetails = ({
  namespace,
  editable,
  variant = 'card'
}: Props): JSX.Element | null => {
  const copyContext = 'home.subscription_details'
  const navigate = useNavigate()

  const { data, loading, error } = useQuery<SubscriptionDetailsCardQuery>(
    SUBSCRIPTION_DETAILS_CARD_QUERY,
    {
      onError: (error) => {
        Sentry.captureException(
          `Error with SUBSCRIPTION_DETAILS_CARD_QUERY query`,
          {
            extra: { error },
            tags: {
              product: Sentry.Product.Account
            }
          }
        )
      }
    }
  )

  const editSubscription = useCallback(() => {
    navigate(ACCOUNT_ROUTES.planManagement)
  }, [navigate])

  if (
    (!loading &&
      data?.user.subscription.status !== SubscriptionStatus.active) ||
    error
  ) {
    return null
  }

  const { dogs, subscription } = data?.user || {}
  const { plan } = subscription || {}
  const { durationInDays, gramsPerDayPerDogs, numberOfPouches } = plan || {}

  const isMultiDog = (dogs && dogs?.length > 1) || false

  return variant === 'card' ? (
    <div className={STYLES.wrapper}>
      <SubscriptionDetailsCardVariant
        copyContext={copyContext}
        namespace={namespace}
        dogs={dogs || []}
        gramsPerDayPerDogs={gramsPerDayPerDogs || []}
        durationInDays={durationInDays || 0}
        numberOfPouches={numberOfPouches || 0}
        editable={editable}
        editSubscription={editSubscription}
        isMultiDog={isMultiDog}
        loading={!data || loading}
      />
    </div>
  ) : (
    <SubscriptionDetailsNoCardVariant
      copyContext={copyContext}
      namespace={namespace}
      dogs={dogs || []}
      gramsPerDayPerDogs={gramsPerDayPerDogs || []}
      durationInDays={durationInDays || 0}
      numberOfPouches={numberOfPouches || 0}
      isMultiDog={isMultiDog}
      loading={!data || loading}
    />
  )
}

export default SubscriptionDetails
