import { gql } from '@apollo/client'

const MEAL_FLAVOURS_QUERY = gql`
  query MealFlavoursQuery(
    $num: Int!
    $calculationType: RecipeSurchargeCalculation = per_serving
  ) {
    user {
      id
      shippingCountry {
        id
        showUpcomingOrderSeeMoreExtras
      }
      subscription {
        id
        nextNBoxes(num: $num) {
          id
          isNextEditableBox
          isoDeliveryDate
        }
        plan {
          id
          numberOfPouches
          # eslint-disable @graphql-eslint/no-deprecated
          servingType
          pouchSize
          servingInformation {
            pouchesPerServing
          }
        }
        meal {
          id
          quantity
          slug
          flavour {
            id
            slug
            productBenefits {
              name
              id
              image {
                src
              }
            }
            recipeSurcharge(calculationType: $calculationType)
            name
            thumbnail {
              src
            }
          }
        }
      }
    }
  }
`

export { MEAL_FLAVOURS_QUERY }
