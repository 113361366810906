import { useMutation } from '@apollo/client'
import { useCallback, useState } from 'react'

import { CREATE_DOG_PROFILE_MUTATION_HOOK } from './mutations/createDogProfileMutation'

import {
  CreateDogProfileMutationHook,
  CreateDogProfileMutationHookVariables
} from './mutations/__generated__/CreateDogProfileMutationHook'
import { UpdateDogProfilePictureMutationHook_avatarsUpdate_dogs } from './mutations/__generated__/UpdateDogProfilePictureMutationHook'

import { useFileUpload } from '../useFileUpload'
import { FileUpload } from '../useFileUpload/useFileUpload'
import { useDogProfilePictureUploadAPI } from './useDogProfilePictureUploadAPI'

type Props = {
  handleSuccessMessage?: (file: string) => void
  handleErrorMessage?: (error?: string) => void
  refetch?: () => void
  userId: string
  dog: UpdateDogProfilePictureMutationHook_avatarsUpdate_dogs
}

type UseDogProfilePictureUpload = {
  pictureUploading: boolean
  firstTimeProfilePicture: boolean
  profilePicture: false | string
}

const useDogProfileUpload = ({
  handleSuccessMessage,
  handleErrorMessage,
  refetch,
  userId,
  dog
}: Props): UseDogProfilePictureUpload & FileUpload => {
  const [pictureUploading, setpictureUploading] = useState(false)
  const [firstTimeProfilePicture, sethasFirstTimeProfilePic] = useState(false)
  const [profilePicture, setProfilePicture] = useState<false | string>(false)

  const { uploadPhoto } = useDogProfilePictureUploadAPI()

  const [createDogProfileMutation] = useMutation<
    CreateDogProfileMutationHook,
    CreateDogProfileMutationHookVariables
  >(CREATE_DOG_PROFILE_MUTATION_HOOK, {
    onError() {
      handleErrorMessage && handleErrorMessage()
    }
  })

  const uploadNewPicture = useCallback(
    async (file): Promise<false | string> => {
      if (file) {
        const profilePictureForm = new FormData()
        profilePictureForm.append('avatar', file)

        try {
          const response = await uploadPhoto({ file, dogid: dog?.id || '' })

          if (response.ok) {
            const res = await response.json()

            if (res.avatar_url) {
              handleSuccessMessage && handleSuccessMessage(res.avatar_url)

              if (!dog?.dogProfile?.avatarUrl) {
                sethasFirstTimeProfilePic(true)
              }

              refetch && refetch()

              return res.avatar_url
            } else {
              handleErrorMessage && handleErrorMessage('corrupted')
              refetch && refetch()

              return false
            }
          }

          handleErrorMessage && handleErrorMessage()

          return false
        } catch (error) {
          handleErrorMessage && handleErrorMessage()

          return false
        }
      }

      return false
    },
    [
      uploadPhoto,
      dog?.id,
      dog?.dogProfile?.avatarUrl,
      handleErrorMessage,
      handleSuccessMessage,
      refetch
    ]
  )

  const createProfileIfNotAvailable =
    useCallback(async (): Promise<boolean> => {
      if (dog?.dogProfile) return true

      try {
        if (dog) {
          const { id: dogId } = dog

          const { data } = await createDogProfileMutation({
            variables: {
              userId: userId ?? '',
              dogIdsInput: [dogId]
            }
          })

          if (data) {
            return true
          }
        }
      } catch (e) {
        handleErrorMessage && handleErrorMessage()
        return false
      }

      return false
    }, [dog, createDogProfileMutation, userId, handleErrorMessage])

  const updateDogProfilePicture = useCallback(
    async (file) => {
      setpictureUploading(true)
      try {
        await createProfileIfNotAvailable()

        const picture = await uploadNewPicture(file)

        setProfilePicture(picture)
        return true
      } catch (error) {
        handleErrorMessage && handleErrorMessage()
      } finally {
        setpictureUploading(false)
      }
    },
    [createProfileIfNotAvailable, handleErrorMessage, uploadNewPicture]
  )

  const { fileInputRef, handleElementClick, handleFileSelect } = useFileUpload({
    onFileSelect: updateDogProfilePicture
  })

  return {
    fileInputRef,
    handleElementClick,
    handleFileSelect,
    pictureUploading,
    firstTimeProfilePicture,
    profilePicture
  }
}

export { useDogProfileUpload }
