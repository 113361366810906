import React from 'react'

import { Expand } from '@/components/elements/atoms/Animated/Animated'
import Card from '@/components/elements/atoms/Card/Card'
import Text from '@/components/elements/atoms/Text/Text'

import CreditCardView, { CreditCard } from '../CreditCardView'

type Props = {
  namespace: string
  show: boolean
  creditCard: CreditCard
}
const CreditCardOption = ({
  namespace,
  show,
  creditCard
}: Props): JSX.Element => {
  const {
    errorMessage,
    onCardElementChange,
    creditCardLoading,
    disableCreditCardButton,
    buySubscriptionCallback
  } = creditCard
  return (
    <Expand show={show} margin={{ top: 1.6 }} maxHeight={600}>
      <Card variant="brandBlue100" squared={{ topLeft: true, topRight: true }}>
        <Text
          namespace={namespace}
          text="payment_section.payment_options_view.pay_with_card"
          variant="textRegular16"
          shouldScale={false}
          align="left"
        />
        <CreditCardView
          errorMessage={errorMessage}
          onCardElementChange={onCardElementChange}
          namespace={namespace}
          creditCardLoading={creditCardLoading}
          disableCreditCardButton={disableCreditCardButton}
          buySubscriptionCallback={buySubscriptionCallback}
        />
      </Card>
    </Expand>
  )
}

export default CreditCardOption
