// @flow

import * as React from 'react'
// eslint-disable-next-line flowtype/no-flow-fix-me-comments
// $FlowFixMe
import Image from '@/components/elements/atoms/Image/Image'
import lazyLoad from '@/modules/lazyLoad'
import { useTranslation } from 'react-i18next'

// eslint-disable-next-line flowtype/no-flow-fix-me-comments
// $FlowFixMe
import type { ImageParams } from '@/components/elements/atoms/Image/Image'

type Tile =
  | 'making-our-meals'
  | 'how-it-works'
  | 'our-story'
  | 'our-products'

type Props = {|
  title: string,
  image: string,
  urlPath: string,
  imageAlt: string,
  transformations?: ImageParams
|}

const LearnMoreTile = ({ title, image, urlPath, imageAlt, transformations }: Props): React.Element<'a'> => {
  React.useEffect((): void => {
    lazyLoad.update()
  }, [])
  return (
    <a
      className='learn-more__tile'
      href={urlPath}
    >
      <Image
        alt={imageAlt}
        slug={image}
        image={{ ...transformations }}
        lazyLoad
      />
      <p>
        <span>
          { title }
        </span>
      </p>
    </a>
  )
}

const LearnMoreTiles = (tile: Tile): React.Node => {
  const { t } = useTranslation('learn_more_about_butternut')
  switch (tile) {
    case 'making-our-meals': {
      return (
        <LearnMoreTile
          transformations={{ width: 330, height: 214, resizeMode: 'resize_to_fill' }}
          title={t('making_our_meals_tile.title')}
          image={'MOM-thumb'}
          urlPath='/making_our_meals'
          imageAlt={t('making_our_meals_tile.image_alt')}
          key={tile}
        />
      )
    }
    case 'how-it-works': {
      return (
        <LearnMoreTile
          transformations={{ width: 330, height: 214, resizeMode: 'resize_to_fill' }}
          title={t('how_it_works_tile.title')}
          image='how-it-works-thumb'
          urlPath='/how_it_works'
          imageAlt={t('how_it_works_tile.image_alt')}
          key={tile}
        />
      )
    }
    case 'our-story': {
      return (
        <LearnMoreTile
          transformations={{ width: 330, height: 214, resizeMode: 'resize_to_fill' }}
          title={t('our_story_tile.title')}
          image='our-story-thumb'
          urlPath='/our_story'
          imageAlt={t('our_story_tile.image_alt')}
          key={tile}
        />
      )
    }
    case 'our-products': {
      return (
        <LearnMoreTile
          transformations={{ width: 330, height: 214, resizeMode: 'resize_to_fill', gravity: 'south' }}
          title={t('our_products_tile.title')}
          image='products-thumb'
          urlPath='/our_products'
          imageAlt={t('our_products_tile.image_alt')}
          key={tile}
        />
      )
    }
    default: {
      (tile: empty) // eslint-disable-line no-unused-expressions
      throw new Error(`Cannot generate LearnMoreTiles for: ${tile}`)
    }
  }
}

const LearnMoreSection = ({ tiles }: {| tiles: Array<Tile> |}): React.Element<'div'> => {
  const { t } = useTranslation('learn_more_about_butternut')
  const columnClass = tiles.length % 2 ? 'col-3' : 'col-2'
  return (
    <div className='learn-more__wrapper'>
      <h2
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={ { __html: t('title') } }
      />
      <div className={`learn-more__tiles learn-more__tiles--${columnClass}`}>
        { tiles.map(LearnMoreTiles) }
      </div>
    </div>
  )
}

export default LearnMoreSection
