import { gql } from '@apollo/client'

const REMOVE_SURCHARGED_RECIPES_MUTATION = gql`
  mutation RemoveSurchargedRecipes {
    removeSurchargedRecipes {
      user {
        id
      }
    }
  }
`

export { REMOVE_SURCHARGED_RECIPES_MUTATION }
