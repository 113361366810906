import { useReactiveVar } from '@apollo/client'
import React, { useCallback, useEffect, useState } from 'react'

import { getTimezoneOffset } from '@/utils/getTimezoneOffset'

import ActionButtons from '../../../components/ActionButtons/ActionButtons'
import Text from '@/components/elements/atoms/Text/Text'

import {
  CustomerIssueEntryInputsCreate_response_resolutionOffering_details_CustomerIssueManagementResolutionDetailsReplaceBox_defaultAddress as DeliveryAddress,
  CustomerIssueEntryInputsCreate_response_resolutionOffering_details_CustomerIssueManagementResolutionDetailsReplaceBox as ReplaceBoxDetails
} from '../../../mutations/__generated__/CustomerIssueEntryInputsCreate'
import { AddressInput } from '@/types'

import { customerIssueManagementState } from '../../../CustomerIssueManagementPage'
import useCustomerIssueManagementTracking from '../../../analytics'
import ChangeAddressButton from './ChangeAddressButton'
import ChangeDateButton from './ChangeDateButton'

type Props = {
  details: ReplaceBoxDetails
  onAccept: (deliveryDate?: string, deliveryAddress?: AddressInput) => void
  contactUs: () => void
}

const Replacement = ({ details, onAccept, contactUs }: Props): JSX.Element => {
  const customerIssueManagementData = useReactiveVar(
    customerIssueManagementState
  )
  const firstDeliverableDate = new Date(
    details.availableDeliveryDates.find((d) => d.deliverable)?.date
  )
  const [selectedAddress, setSelectedAddress] = useState<DeliveryAddress>(
    details.defaultAddress
  )
  const [selectedDate, setSelectedDate] = useState<Date>(firstDeliverableDate)
  const customerIssueManagementTracking = useCustomerIssueManagementTracking()
  const [initialised, setInitialised] = useState(false)

  const changeAddress = useCallback(
    (address: DeliveryAddress) => {
      customerIssueManagementTracking.trackEvent('change delivery address')
      setSelectedAddress(address)
    },
    [customerIssueManagementTracking]
  )

  const changeDate = useCallback(
    (date: Date) => {
      customerIssueManagementTracking.trackEvent('change delivery date')
      setSelectedDate(date)
    },
    [customerIssueManagementTracking]
  )

  useEffect(() => {
    if (!initialised) {
      customerIssueManagementTracking.stepLoaded(
        'Replacement box offering',
        'Resolution offering'
      )
      setInitialised(true)
    }
  }, [
    initialised,
    customerIssueManagementData,
    customerIssueManagementTracking
  ])

  const accept = useCallback(() => {
    const address = {
      postcode: selectedAddress.postcode,
      addressLineOne: selectedAddress.address1,
      recipientName: selectedAddress.recipientName,
      addressLineTwo: selectedAddress.address2,
      city: selectedAddress.city,
      shippingCountryId: parseInt(selectedAddress.shippingCountry.id, 10)
    }
    customerIssueManagementTracking.trackEvent('Resolution accepted', {
      resolution: 'Replacement box'
    })
    const dateWithTimezoneOffset = getTimezoneOffset(selectedDate)
    onAccept(dateWithTimezoneOffset, address)
  }, [selectedDate, selectedAddress, onAccept, customerIssueManagementTracking])

  return (
    <>
      <Text
        namespace={customerIssueManagementData.namespace}
        text={'resolutionOffering.replacementBox.text1'}
        margin={false}
      />
      <Text
        namespace={customerIssueManagementData.namespace}
        text={'resolutionOffering.replacementBox.text2'}
      />
      <ChangeAddressButton
        selectedAddress={selectedAddress}
        setSelectedAddress={changeAddress}
      />
      <ChangeDateButton
        firstDeliverableDate={firstDeliverableDate}
        availableDeliveryDates={details.availableDeliveryDates}
        selectedDate={selectedDate}
        setSelectedDate={changeDate}
      />
      <ActionButtons
        acceptButton={{
          action: accept,
          text: 'resolutionOffering.replacementBox.send'
        }}
        rejectButton={{
          action: contactUs,
          text: 'resolutionOffering.contactCustomerLove'
        }}
      />
    </>
  )
}

export { Props }
export default Replacement
