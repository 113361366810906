// @flow

import { connect } from 'react-redux'
import * as React from 'react'

import * as THUNKS from '../../thunks'
import * as ACTIONS from '../../actions'

import { formatCurrencyWithDecimal } from '@/utils/currency'
import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'

// Types
import type { State } from '../../index'

import type {
  PlanDetails,
  PlanAttributesSelection,
  ProposedPlan,
  RecurringExtraProduct,
  RecipeSurcharge
} from '../../message_types'

import type { PricingCohort } from '@/shared_types/rails_models/pricingCurve'

import type { Dispatch } from 'redux'

import type { Language } from '@/packs/localisation'

type PresentationalProps = {|
  isOpen: boolean,
  currentPlanDetails: PlanDetails,
  deliveryFee: number,
  planAttributesSelection: PlanAttributesSelection,
  proposedPlan: ProposedPlan,
  totalPriceOfExtras: number,
  totalPriceOfRecipeSurcharges: number,
  proposedTotalPriceOfRecipeSurcharges: number,
  updateProposedPlanRequestInFlight: boolean,
  changePlanRequestInFlight: boolean,
  preferredLanguage: Language,
|}

type ActionProps = {|
  closeModal: () => void,
  changePlan: () => void,
  updatePricingCohortSelection: (PricingCohort) => void,
  updateDurationInDaysSelection: (number) => void,
  updateNumberOfPouchesSelection: (number) => void,
  updateProposedPlan: () => void
|}

export type Props =
  & PresentationalProps
  & ActionProps

const mapStateToProps = (state: State): PresentationalProps => {
  const { accountDetails, subscriptionDetails } = state
  const { plan_details, changePlanDetails, recurring_extra_products, recipe_surcharges, delivery_fee } = subscriptionDetails
  const {
    isModalOpen,
    planAttributesSelection,
    proposedPlan,
    updateProposedPlanRequestInFlight,
    changePlanRequestInFlight
  } = changePlanDetails
  const { proposedRecipeSurcharges } = proposedPlan
  const currentPlanDetails = plan_details
  const isOpen = isModalOpen
  const totalPriceOfExtras = recurring_extra_products.reduce((total: number, { quantity, price }: RecurringExtraProduct): number => {
    return total + (quantity * price)
  }, 0)

  const totalPriceOfRecipeSurcharges = recipe_surcharges.reduce((total: number, { price }: RecipeSurcharge): number => {
    return total + price
  }, 0)
  const proposedTotalPriceOfRecipeSurcharges = proposedRecipeSurcharges.reduce((total: number, { price }: RecipeSurcharge): number => {
    return total + price
  }, 0)

  const preferredLanguage = accountDetails.preferred_language

  return {
    isOpen,
    currentPlanDetails,
    deliveryFee: delivery_fee,
    planAttributesSelection,
    proposedPlan,
    totalPriceOfExtras,
    totalPriceOfRecipeSurcharges,
    proposedTotalPriceOfRecipeSurcharges,
    updateProposedPlanRequestInFlight,
    changePlanRequestInFlight,
    preferredLanguage
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => {
  const closeModal = (): void => {
    dispatch(ACTIONS.toggleChangePlanModal())
  }

  const changePlan = (): void => {
    dispatch(THUNKS.changePlan())
    dispatch(ACTIONS.toggleChangePlanModal())
  }

  const updateNumberOfPouchesSelection = (numberOfPouches: number): void => {
    dispatch(ACTIONS.updateNumberOfPouchesSelection(numberOfPouches))
  }

  const updatePricingCohortSelection = (pricingCohort: PricingCohort): void => {
    dispatch((_: void, getState: () => State): void => {
      const { subscriptionDetails } = getState()
      const { changePlanDetails } = subscriptionDetails
      const { planAttributesSelection } = changePlanDetails
      const { numberOfPouches } = planAttributesSelection
      updateNumberOfPouchesIfRequired(numberOfPouches, pricingCohort, updateNumberOfPouchesSelection)
      dispatch(ACTIONS.updatePricingCohortSelection(pricingCohort))
    })
  }

  const updateDurationInDaysSelection = (durationInDays: number): void => {
    dispatch(ACTIONS.updateDurationInDaysSelection(durationInDays))
  }

  const updateProposedPlan = (): void => {
    dispatch(THUNKS.updateProposedPlan())
  }

  return {
    closeModal,
    changePlan,
    updatePricingCohortSelection,
    updateDurationInDaysSelection,
    updateNumberOfPouchesSelection,
    updateProposedPlan
  }
}

const pricingCohortOptions: Array<PricingCohort> = [
  '125g',
  '200g',
  '300g',
  '400g',
  '500g',
  '600g',
  '800g',
  '1000g',
  '1200g'
]

const numberOfDaysandPouchesOptions: Array<number> = [
  7,
  14,
  21,
  28,
  35,
  42,
  49,
  56
]

const singleServePricingCohorts = ['800g', '1000g', '1200g']

const updateNumberOfPouchesIfRequired = (numberOfPouches: number, pricingCohort: PricingCohort, updateNumberOfPouchesSelection: (number) => void): void => {
  switch (pricingCohort) {
    case '125g':
    case '200g':
    case '300g': {
      break
    }
    case '400g': {
      if (numberOfPouches > 42) {
        updateNumberOfPouchesSelection(42)
      }
      break
    }
    case '500g':
    case '600g': {
      if (numberOfPouches > 28) {
        updateNumberOfPouchesSelection(28)
      }
      break
    }
    case '800g':
      if (numberOfPouches !== 14 && numberOfPouches !== 28 && numberOfPouches !== 42) {
        updateNumberOfPouchesSelection(42)
      }
      break
    case '1000g':
    case '1200g': {
      if (numberOfPouches !== 14 && numberOfPouches !== 28) {
        updateNumberOfPouchesSelection(28)
      }
      break
    }
    default: {
      throw new Error(`${pricingCohort} is not a valid pricing cohort`)
    }
  }
}

const shouldPricingCohortBeDisabled = (pricingCohort: PricingCohort, numberOfPouches: number): boolean => {
  switch (pricingCohort) {
    case '125g':
    case '200g':
    case '300g': {
      return false
    }
    case '400g': {
      return numberOfPouches > 42
    }
    case '500g':
    case '600g': {
      return numberOfPouches > 28
    }
    case '800g': {
      return numberOfPouches !== 14 && numberOfPouches !== 28 && numberOfPouches !== 42
    }
    case '1000g':
    case '1200g': {
      return numberOfPouches !== 14 && numberOfPouches !== 28
    }
    default: {
      throw new Error(`${pricingCohort} is not a valid pricing cohort`)
    }
  }
}

const shouldNumberOfPouchesBeDisabled = (numberOfPouches: number, pricingCohort: PricingCohort): boolean => {
  switch (pricingCohort) {
    case '125g':
    case '200g':
    case '300g': {
      return false
    }
    case '400g': {
      return numberOfPouches > 42
    }
    case '500g':
    case '600g': {
      return numberOfPouches > 28
    }
    case '800g': {
      return numberOfPouches !== 14 && numberOfPouches !== 28 && numberOfPouches !== 42
    }
    case '1000g':
    case '1200g': {
      return numberOfPouches !== 14 && numberOfPouches !== 28
    }
    default: {
      throw new Error(`${pricingCohort} is not a valid pricing cohort`)
    }
  }
}

const stringifySingleServePlans = (pricingCohort: PricingCohort, numberOfPouches: number): string => {
  if (singleServePricingCohorts.includes(pricingCohort)) {
    return `(${numberOfPouches / 2} x ${pricingCohort})`
  } else {
    return ''
  }
}

const stringForActualSingleServePouches = (pricingCohort: PricingCohort): string => {
  switch (pricingCohort) {
    case '125g':
    case '200g':
    case '300g':
    case '400g':
    case '500g':
    case '600g': {
      return ''
    }
    case '800g': {
      return '2 x 400g'
    }
    case '1000g': {
      return '2 x 500g'
    }
    case '1200g': {
      return '2 x 600g'
    }
    default: {
      throw new Error(`${pricingCohort} is not a valid pricing cohort`)
    }
  }
}

const ChangePlanModal = ({
  isOpen,
  currentPlanDetails,
  deliveryFee,
  closeModal,
  changePlan,
  planAttributesSelection,
  proposedPlan,
  updatePricingCohortSelection,
  updateDurationInDaysSelection,
  updateNumberOfPouchesSelection,
  updateProposedPlan,
  totalPriceOfExtras,
  totalPriceOfRecipeSurcharges,
  proposedTotalPriceOfRecipeSurcharges,
  updateProposedPlanRequestInFlight,
  changePlanRequestInFlight,
  preferredLanguage
}: Props): ?React.Node => {
  if (!isOpen) return null

  const conveniencePricingPresent =
    currentPlanDetails.pricing_curve_type === 'convenience_pricing' ||
    proposedPlan.plan.pricingCurve.pricingCurveType === 'convenience_pricing'

  const { shippingCountryCode } = proposedPlan
  const { locale, currency } = countryCodeToLocaleCurrency(shippingCountryCode, preferredLanguage)

  return (
    <div className='modal-container'>
      <div className='modal-container__backdrop' />
      <div className='modal-container__modal'>
        <div className='change-plan-modal'>
          <h1 className='change-plan-modal__header'>
            { `Edit plan` }
          </h1>

          <div className='change-plan-modal__section'>
            <strong className='change-plan-modal__section__label'>
              { 'Plan size' }
            </strong>
            <div className='plan-size-grid'>
              {
                pricingCohortOptions
                  .map((pricingCohort: PricingCohort): React.Element<'div'> => {
                    const selectedButtonClass =
                      planAttributesSelection.pricingCurve.pricingCohort === pricingCohort
                        ? 'selected'
                        : ''

                    return (
                      <div
                        key={pricingCohort}
                        className='plan-size-grid__item'
                      >
                        <button
                          className={`btn-updated ${selectedButtonClass}`}
                          disabled={shouldPricingCohortBeDisabled(pricingCohort, planAttributesSelection.numberOfPouches)}
                          type='button'
                          onClick={(): void => updatePricingCohortSelection(pricingCohort)} // eslint-disable-line react/jsx-no-bind
                        >
                          { `${pricingCohort}` }
                        </button>
                        {
                          singleServePricingCohorts.includes(pricingCohort) && (
                            <small className='plan-size-grid__item__explainer'>
                              { `${stringForActualSingleServePouches(pricingCohort)}` }
                            </small>
                          )
                        }
                      </div>
                    )
                  })
              }
            </div>
          </div>

          <div className='change-plan-modal__section change-plan-modal__section--row'>
            <div>
              <strong className='change-plan-modal__section__label'>
                { 'Number of pouches' }
              </strong>
              <div className='number-of-pouches-grid'>
                {
                  numberOfDaysandPouchesOptions
                    .map((numberOfPouches: number): React.Element<'button'> => {
                      const selectedButtonClass = planAttributesSelection.numberOfPouches === numberOfPouches
                        ? 'selected'
                        : ''

                      return (
                        <button
                          className={`btn-updated ${selectedButtonClass}`}
                          disabled={shouldNumberOfPouchesBeDisabled(numberOfPouches, planAttributesSelection.pricingCurve.pricingCohort)}
                          type='button'
                          key={numberOfPouches}
                          onClick={(): void => updateNumberOfPouchesSelection(numberOfPouches)} // eslint-disable-line react/jsx-no-bind
                        >
                          { `${numberOfPouches}` }
                        </button>
                      )
                    })
                }
              </div>
            </div>

            <div>
              <p className='change-plan-modal__section__label'>
                <strong>
                  { 'Delivery frequency' }
                </strong>
                { ` (days)` }
              </p>
              <div className='delivery-frequency-grid'>
                {
                  numberOfDaysandPouchesOptions
                    .map((durationInDays: number): React.Element<'button'> => {
                      const selectedButtonClass = planAttributesSelection.durationInDays === durationInDays
                        ? 'selected'
                        : ''

                      return (
                        <button
                          className={`btn-updated ${selectedButtonClass}`}
                          type='button'
                          key={durationInDays}
                          onClick={(): void => updateDurationInDaysSelection(durationInDays)} // eslint-disable-line react/jsx-no-bind
                        >
                          { `${durationInDays}` }
                        </button>
                      )
                    })
                }
              </div>
            </div>
          </div>

          <button
            className={`btn-updated  btn-updated--primary${updateProposedPlanRequestInFlight ? 'btn-updated--disabled' : ''}`}
            disabled={updateProposedPlanRequestInFlight}
            onClick={updateProposedPlan}
            type='button'
          >
            { 'Calculate Plan' }
          </button>

          {
            proposedPlan.plan.id !== -1 && (
              <div
                className='plan-comparison'
                style={{
                  marginTop: conveniencePricingPresent ? '6rem' : '4rem'
                }}
              >
                <div className='plan-comparison__plan'>
                  <p className='plan-comparison__plan__title'>
                    { 'Current' }
                    {
                      currentPlanDetails.pricing_curve_type === 'convenience_pricing' && (
                        <span className='plan-comparison__plan__title__label'>
                          { 'Convenience pricing' }
                        </span>
                      )
                    }
                  </p>
                  <strong>
                    { `${currentPlanDetails.pricing_cohort} per day` }
                  </strong>
                  <p>
                    { `${currentPlanDetails.number_of_pouches} x ${currentPlanDetails.pouch_size_in_grams}g ${stringifySingleServePlans(currentPlanDetails.pricing_cohort, currentPlanDetails.number_of_pouches)} every ${currentPlanDetails.duration_in_days} days` }
                  </p>

                  <div className='plan-comparison__plan__breakdown'>
                    <div className='plan-comparison__plan__breakdown__row'>
                      <p>
                        { 'Daily Price' }
                      </p>
                      <p>
                        { `${formatCurrencyWithDecimal((currentPlanDetails.price_per_pouch * currentPlanDetails.number_of_pouches) / currentPlanDetails.duration_in_days, { locale: locale, currency: currency })}` }
                      </p>
                    </div>
                    <div className='plan-comparison__plan__breakdown__row'>
                      <p>
                        { 'Box price' }
                      </p>
                      <p>
                        { `${formatCurrencyWithDecimal(currentPlanDetails.price_per_pouch * currentPlanDetails.number_of_pouches, { locale: locale, currency: currency })}` }
                      </p>
                    </div>
                    { totalPriceOfRecipeSurcharges > 0 &&
                    <div className='plan-comparison__plan__breakdown__row'>
                      <p>
                        { 'Recipe surcharge' }
                      </p>
                      <p>
                        { `+${formatCurrencyWithDecimal(totalPriceOfRecipeSurcharges, { locale: locale, currency: currency })}` }
                      </p>
                    </div> }
                    <div className='plan-comparison__plan__breakdown__row'>
                      <p>
                        { 'Extras' }
                      </p>
                      <p>
                        { `${formatCurrencyWithDecimal(totalPriceOfExtras, { locale: locale, currency: currency })}` }
                      </p>
                    </div>
                    <div className='plan-comparison__plan__breakdown__row'>
                      <p>
                        { 'Delivery Fee' }
                      </p>
                      <p>
                        { `${formatCurrencyWithDecimal(parseInt(deliveryFee), { locale: locale, currency: currency })}` }
                      </p>
                    </div>
                    <div className='plan-comparison__plan__breakdown__row'>
                      <p>
                        { 'TOTAL' }
                      </p>
                      <p>
                        { `${formatCurrencyWithDecimal(totalPriceOfExtras + totalPriceOfRecipeSurcharges + deliveryFee + (currentPlanDetails.price_per_pouch * currentPlanDetails.number_of_pouches), { locale: locale, currency: currency })}` }
                      </p>
                    </div>
                  </div>
                </div>

                <div className='plan-comparison__plan'>
                  <p className='plan-comparison__plan__title'>
                    { 'Proposed' }
                    {
                      proposedPlan.plan.pricingCurve.pricingCurveType === 'convenience_pricing' && (
                        <span className='plan-comparison__plan__title__label'>
                          { 'Convenience pricing' }
                        </span>
                      )
                    }
                  </p>
                  <strong>
                    { `${proposedPlan.plan.pricingCurve.pricingCohort} per day` }
                  </strong>
                  <p>
                    { `${proposedPlan.plan.numberOfPouches} x ${proposedPlan.plan.pouchSizeInGrams}g ${stringifySingleServePlans(proposedPlan.plan.pricingCurve.pricingCohort, proposedPlan.plan.numberOfPouches)} every ${proposedPlan.plan.durationInDays} days` }
                  </p>

                  <div className='plan-comparison__plan__breakdown'>
                    <div className='plan-comparison__plan__breakdown__row'>
                      <p>
                        { 'Daily Price' }
                      </p>
                      <p>
                        { `${formatCurrencyWithDecimal((proposedPlan.plan.pricePerPouch * proposedPlan.plan.numberOfPouches) / proposedPlan.plan.durationInDays, { locale: locale, currency: currency })}` }
                      </p>
                    </div>
                    <div className='plan-comparison__plan__breakdown__row'>
                      <p>
                        { 'Box price' }
                      </p>
                      <p>
                        { `${formatCurrencyWithDecimal(proposedPlan.plan.pricePerPouch * proposedPlan.plan.numberOfPouches, { locale: locale, currency: currency })}` }
                      </p>
                    </div>
                    { proposedTotalPriceOfRecipeSurcharges > 0 &&
                    <div className='plan-comparison__plan__breakdown__row'>
                      <p>
                        { 'Recipe surcharge' }
                      </p>
                      <p>
                        { `+${formatCurrencyWithDecimal(proposedTotalPriceOfRecipeSurcharges, { locale: locale, currency: currency })}` }
                      </p>
                    </div> }
                    <div className='plan-comparison__plan__breakdown__row'>
                      <p>
                        { 'Extras' }
                      </p>
                      <p>
                        { `${formatCurrencyWithDecimal(totalPriceOfExtras, { locale: locale, currency: currency })}` }
                      </p>
                    </div>
                    <div className='plan-comparison__plan__breakdown__row'>
                      <p>
                        { 'Delivery Fee' }
                      </p>
                      <p>
                        { `${formatCurrencyWithDecimal(proposedPlan.plan.deliveryFee, { locale: locale, currency: currency })}` }
                      </p>
                    </div>
                    <div className='plan-comparison__plan__breakdown__row'>
                      <p>
                        { 'TOTAL' }
                      </p>
                      <p>
                        { `${formatCurrencyWithDecimal(totalPriceOfExtras + proposedTotalPriceOfRecipeSurcharges + proposedPlan.plan.deliveryFee + (proposedPlan.plan.pricePerPouch * proposedPlan.plan.numberOfPouches), { locale: locale, currency: currency })}` }
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )
          }

          <div className='change-plan-modal__footer'>
            <button
              onClick={closeModal}
              type='button'
            >
              { 'Cancel' }
            </button>
            <button
              disabled={proposedPlan.plan.id === -1 || currentPlanDetails.plan_id === proposedPlan.plan.id || changePlanRequestInFlight}
              onClick={changePlan}
              type='button'
            >
              { 'Update Plan' }
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePlanModal)
