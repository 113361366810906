import { useQuery } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import upperFirst from 'lodash/upperFirst'
import React from 'react'

import { SHARE_MODAL_QUERY } from './queries/ShareModalQuery'

import { ShareModalQuery } from './queries/__generated__/ShareModalQuery'

import { Button } from '../../atoms/Button'
import ShareModal, {
  shareModalState
} from '../../organisms/ShareModal/ShareModal'

type Props = {
  namespace: string
  buttonText: string
  variant?: 'primary' | 'secondary'
  onClick?: () => void
}

const ShareModalButton = ({
  namespace,
  buttonText,
  variant = 'primary',
  onClick
}: Props): JSX.Element | null => {
  const { loading, data, error } = useQuery<ShareModalQuery>(SHARE_MODAL_QUERY)

  const ctaOnClick = React.useCallback(() => {
    onClick && onClick()
    shareModalState(true)
  }, [onClick])

  if (error) {
    Sentry.captureException(`An error occured when loading ShareModalButton`, {
      extra: { error }
    })
  }

  if (!data || loading) {
    return null
  }
  const { user, whatsAppSingleDog, whatsAppMultipleDogs } = data
  const {
    shippingCountryCode,
    subscription,
    id: userId,
    dogs,
    preferredLanguage
  } = user
  const { referralLink } = subscription
  return (
    <div id="raf-share">
      <Button
        typography={{
          text: buttonText,
          namespace
        }}
        fullWidth
        variant={variant}
        onClick={ctaOnClick}
        disableAnalytics
      />
      <ShareModal
        namespace={namespace}
        preferredLanguage={preferredLanguage}
        shippingCountryCode={shippingCountryCode}
        referralLink={referralLink}
        userId={userId}
        dogNames={dogs?.map(({ name }) => upperFirst(name)) || []}
        whatsAppSingleDog={whatsAppSingleDog}
        whatsAppMultipleDogs={whatsAppMultipleDogs}
      />
    </div>
  )
}

export default ShareModalButton
