// @noflow
import React from 'react'
import { useTranslation } from 'react-i18next'

import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import { SelectableCardGroupV2 } from '@/components/elements/molecules/SelectableCardGroup'

import { SnackingHabit } from '@/types'

import type { SharedInputProps } from '../../UpdateDogWeightAndAppetiteContent'

type Props = SharedInputProps & {
  snackingHabits: SnackingHabit
  genderContext: string
}

const SnackingHabits = ({
  snackingHabits,
  genderContext,
  onSelect,
  loading,
  margin = { top: 8, bottom: 0 }
}: Props): JSX.Element => {
  const { t } = useTranslation('account')
  const copyContext = 'update_dog_profile_weight_and_appetite'

  const SNACKING_HABITS = [
    {
      value: SnackingHabit.eats_no_snacks,
      icon: {
        slug: 'snacking-habits-no-snacks',
        alt: t(`${copyContext}.snacking_habits.eats_no_snacks.heading`),
        image: {
          width: 52,
          height: 46
        }
      },
      identifier: `${copyContext}.snacking_habits.none`
    },
    {
      value: SnackingHabit.eats_some_snacks,
      icon: {
        slug: 'snacking-habits-some-snacks',
        alt: t(`${copyContext}.snacking_habits.eats_some_snacks.heading`),
        image: {
          width: 66,
          height: 56
        }
      },
      identifier: `${copyContext}.snacking_habits.some`
    },
    {
      value: SnackingHabit.eats_lots_of_snacks,
      icon: {
        slug: 'snacking-habits-lots-snacks',
        alt: t(`${copyContext}.snacking_habits.eats_lots_of_snacks.heading`),
        image: {
          width: 66,
          height: 56
        }
      },
      identifier: `${copyContext}.snacking_habits.lots`
    }
  ]

  return (
    <SectionWrapper margin={margin}>
      <SelectableCardGroupV2
        options={SNACKING_HABITS}
        onSelect={onSelect}
        currentValue={snackingHabits}
        cardProps={{
          skeleton: {
            isLoading: loading,
            height: '11rem'
          }
        }}
        descriptionCard={{
          title: {
            namespace: 'account',
            text: `${copyContext}.snacking_habits.${snackingHabits}.heading`,
            variables: {
              context: genderContext
            }
          },
          ...(snackingHabits !== SnackingHabit.unknown_snacking_habits && {
            description: {
              namespace: 'account',
              text: `${copyContext}.snacking_habits.${snackingHabits}.text`,
              variables: {
                context: genderContext
              }
            }
          })
        }}
      />
    </SectionWrapper>
  )
}

export { SnackingHabits }
