import { useReactiveVar } from '@apollo/client'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'

import ActionButtons from '../../components/ActionButtons/ActionButtons'
import QuickHelpTypeSwitch from './components/QuickHelpTypeSwitch'
import Text from '@/components/elements/atoms/Text/Text'

import PARENTSTYLES from '../../CustomerIssueManagementPage.module.sass'
import STYLES from './QuickHelp.module.sass'

import { CustomerIssueManagementResolutionResolutionType } from '@/types'

import {
  customerIssueManagementState,
  customerIssueReportState,
  pageHeaderShowBackButtonState,
  pageTitleState
} from '../../CustomerIssueManagementPage'
import { CustomerIssueResolutionOfferingMutationsHook } from '../../hooks/useCustomerIssueResolutionOfferingMutations'
import CustomerIssueManagementRoutes from '../../types/routes'
import ContactCustomerLoveDetails from '../ContactCustomerLove/ContactCustomerLoveDetails'

type Props = {
  customerIssueResolutionOfferingMutations: CustomerIssueResolutionOfferingMutationsHook
}

type ButtonVariants = {
  accept: 'primary' | 'secondary'
  reject: 'secondary' | 'ghost'
}

const QuickHelp = ({
  customerIssueResolutionOfferingMutations
}: Props): JSX.Element => {
  const [initialised, setInitialised] = useState(false)
  const navigate = useNavigate()
  const pageHeaderShowBackButtonValue = useReactiveVar(
    pageHeaderShowBackButtonState
  )
  const customerIssueManagementData = useReactiveVar(
    customerIssueManagementState
  )
  const customerIssueReportData = useReactiveVar(customerIssueReportState)

  useEffect(() => {
    if (!initialised) {
      pageTitleState(null)
      setInitialised(true)
    }
  }, [initialised])

  const buttonVariants: ButtonVariants = useMemo(() => {
    if (
      customerIssueReportData.resolutionOffering?.resolutionType ===
      'track_order'
    ) {
      return {
        accept: 'secondary',
        reject: 'ghost'
      }
    }
    return {
      accept: 'primary',
      reject: 'secondary'
    }
  }, [customerIssueReportData.resolutionOffering?.resolutionType])

  const accept = React.useCallback(() => {
    customerIssueResolutionOfferingMutations.acceptNoResponse(
      customerIssueReportData.resolutionOffering?.id as string,
      customerIssueReportData.resolutionOffering
        ?.resolutionType as CustomerIssueManagementResolutionResolutionType
    )
  }, [
    customerIssueReportData.resolutionOffering?.id,
    customerIssueReportData.resolutionOffering?.resolutionType,
    customerIssueResolutionOfferingMutations
  ])

  const reject = React.useCallback(() => {
    customerIssueResolutionOfferingMutations.reject(
      customerIssueReportData.resolutionOffering?.id as string
    )
    navigate(CustomerIssueManagementRoutes.Inputs)
  }, [
    customerIssueReportData.resolutionOffering?.id,
    customerIssueResolutionOfferingMutations,
    navigate
  ])

  const actionsText = useMemo(() => {
    return [
      // eslint-disable-next-line i18next/no-literal-string
      `quick_help.actions_text.${customerIssueReportData.resolutionOffering?.resolutionType}.${customerIssueManagementData.selectedCategory?.key}`,
      // eslint-disable-next-line i18next/no-literal-string
      `quick_help.actions_text.${customerIssueReportData.resolutionOffering?.resolutionType}`,
      'quick_help.actions_text.default'
    ]
  }, [
    customerIssueManagementData.selectedCategory?.key,
    customerIssueReportData.resolutionOffering?.resolutionType
  ])

  const acceptButtonText = useMemo(() => {
    return [
      // eslint-disable-next-line i18next/no-literal-string
      `quick_help.accept_button.${customerIssueReportData.resolutionOffering?.resolutionType}.${customerIssueManagementData.selectedCategory?.key}`,
      // eslint-disable-next-line i18next/no-literal-string
      `quick_help.accept_button.${customerIssueReportData.resolutionOffering?.resolutionType}`,
      'quick_help.accept_button.default'
    ]
  }, [
    customerIssueManagementData.selectedCategory?.key,
    customerIssueReportData.resolutionOffering?.resolutionType
  ])

  const rejectButtonText = useMemo(() => {
    return [
      // eslint-disable-next-line i18next/no-literal-string
      `quick_help.reject_button.${customerIssueReportData.resolutionOffering?.resolutionType}.${customerIssueManagementData.selectedCategory?.key}`,
      // eslint-disable-next-line i18next/no-literal-string
      `quick_help.reject_button.${customerIssueReportData.resolutionOffering?.resolutionType}`,
      'quick_help.reject_button.default'
    ]
  }, [
    customerIssueManagementData.selectedCategory?.key,
    customerIssueReportData.resolutionOffering?.resolutionType
  ])

  if (!pageHeaderShowBackButtonValue) {
    navigate(customerIssueManagementData.returnPath, { replace: true })
  }

  if (customerIssueReportData?.resolutionOffering) {
    return (
      <div className={STYLES.container}>
        <div className={PARENTSTYLES.cardContent}>
          <QuickHelpTypeSwitch
            resolutionOffering={customerIssueReportData.resolutionOffering}
          />
        </div>
        <div className={STYLES.actions}>
          <Text
            text={actionsText}
            variant={'textRegular16'}
            colour={'brandBlue500'}
            margin={false}
            namespace={customerIssueManagementData.namespace}
            align={'center'}
          />
          <ActionButtons
            acceptButton={{
              variant: buttonVariants.accept,
              text: acceptButtonText,
              action: accept
            }}
            rejectButton={{
              variant: buttonVariants.reject,
              text: rejectButtonText,
              action: reject
            }}
            variant={'center'}
          />
        </div>
      </div>
    )
  } else {
    return (
      <div className={PARENTSTYLES.cardContent}>
        <ContactCustomerLoveDetails />
      </div>
    )
  }
}

export default QuickHelp
