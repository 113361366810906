// @flow

import type { RailsModelID } from '@/shared_types/ids'
import type { CSRFToken } from '@/shared_types/csrfToken'
import type { ServerError, UnknownError } from '@/redux/graphql_queries/errorTypes'

type Success = {|
  type: 'Success',
  message: string
|}

type RequestResult =
  | Success
  | ServerError
  | UnknownError

const { fetch } = global

const sendRafDetailsEmail = ({
  subscriptionId,
  csrfToken
}: {|
  subscriptionId: RailsModelID,
  csrfToken: CSRFToken
|}): Promise<RequestResult> => {
  const endpoint = '/admin/single_customer_view/send_raf_details_email'
  const queryParams = `subscription_id=${subscriptionId}`
  const method = 'POST'
  const credentials = 'same-origin'
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  }
  return fetch(`${endpoint}?${queryParams}`, { headers, method, credentials })
    .then((res: Response): Promise<RequestResult> => {
      if (!res.ok) {
        return res.text()
          .then((message: string): RequestResult => {
            return {
              type: 'ServerError',
              status: res.status,
              message
            }
          })
      }
      return res.json()
        .then(({ message }: {| message: string |}): RequestResult => {
          if (message) {
            return {
              type: 'Success',
              message
            }
          } else {
            return {
              type: 'UnknownError',
              error: message
            }
          }
        })
    })
}

export type {
  RequestResult
}

export default sendRafDetailsEmail
