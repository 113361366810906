import React from 'react'

import {
  CustomerIssueReportCreate_response_inputFields_CustomerIssueManagementInputFieldInteger as IntegerField,
  CustomerIssueReportCreate_response_inputFields_CustomerIssueManagementInputFieldMultifield_fields_CustomerIssueManagementInputFieldInteger as MultifieldIntegerField
} from '../../../../mutations/__generated__/CustomerIssueReportCreate'
import { CustomerIssueManagementInputInput } from '@/types'

import Stepper from '../display_types/Stepper'

type IntegerProps = {
  inputField: IntegerField | MultifieldIntegerField
  inputValue: CustomerIssueManagementInputInput
  maxValue?: number
  updateInput: (input: CustomerIssueManagementInputInput) => void
}

const Integer = ({
  inputField,
  inputValue,
  maxValue,
  updateInput
}: IntegerProps): JSX.Element => {
  const setValue = React.useCallback(
    (integerValue: number): void => {
      const newInput: CustomerIssueManagementInputInput = {
        fieldId: inputValue.fieldId,
        integerValue
      }
      updateInput(newInput)
    },
    [inputValue, updateInput]
  )

  switch (inputField.integerDisplayType) {
    case 'stepper':
      return (
        <Stepper
          text={inputField.name}
          maxValue={maxValue}
          input={inputValue}
          setValue={setValue}
        />
      )
    default: {
      throw new Error('Unsupported integer display type')
    }
  }
}

export default Integer
