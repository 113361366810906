// @noflow
import { ACCOUNT_ROUTES } from '@/routes'
import { Grid } from '@mui/material'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import UsePlan from '@/hooks/usePlan/usePlan'

import IconCard from '@/components/elements/molecules/IconCard/IconCard'

type PlanManagementSectionProps = {
  id: string
  path: string
}

const PlanManagementSection = ({ id, path }: PlanManagementSectionProps) => {
  const navigate = useNavigate()

  const onClick = useCallback(() => {
    navigate(path)
  }, [navigate, path])

  return (
    <Grid item xs={12}>
      <IconCard
        onClick={onClick}
        heading={{
          text: `plan_management.sections.${id}.title`,
          namespace: 'account'
        }}
        subtext={{
          text: `plan_management.sections.${id}.subtext`,
          namespace: 'account'
        }}
        identifier={`plan_management.sections.${id}`}
      />
    </Grid>
  )
}

const sections: PlanManagementSectionProps[] = [
  {
    id: 'size',
    path: ACCOUNT_ROUTES.servingSize
  },
  {
    id: 'frequency',
    path: ACCOUNT_ROUTES.deliveryFrequency
  },
  {
    id: 'recommendation',
    path: ACCOUNT_ROUTES.editDogDetails
  }
]

const PlanManagementPage = (): JSX.Element => {
  const { canChangeDeliveryFrequency } = UsePlan()

  return (
    <Grid container rowSpacing={2}>
      {sections.map((props: PlanManagementSectionProps) => {
        if (!canChangeDeliveryFrequency && props.id === 'frequency') return null

        return <PlanManagementSection key={props.id} {...props} />
      })}
    </Grid>
  )
}

export default PlanManagementPage
