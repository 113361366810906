// @noflow

import React from 'react'

type Tab = {
  title: string,
  panel: React.ReactElement,
  id: string,
  selectTab: () => void
}

type Props = {
  tabs: Array<Tab>,
  selectedTabId: string
}

declare class TabbedPanel extends React.Component<Props> {}

export default TabbedPanel
