/* eslint-disable react/jsx-no-bind */
// @noflow
import { useAccessManagement } from '@/context/accessManagement/accessManagement'
import { ACCOUNT_ROUTES } from '@/routes'
import { Grid } from '@material-ui/core'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import BoxOpen from '@/components/elements/atoms/Icon/Icons/BoxOpen/BoxOpen'
import ButternutLogo from '@/components/elements/atoms/Icon/Icons/ButternutLogo'
import { Letter } from '@/components/elements/atoms/Icon/Icons/Letter'
import { Logout } from '@/components/elements/atoms/Icon/Icons/Logout'
import { PaymentMethods } from '@/components/elements/atoms/Icon/Icons/PaymentMethods'
import Person from '@/components/elements/atoms/Icon/Icons/Person/Person'
import Phone from '@/components/elements/atoms/Icon/Icons/Phone/Phone'
import DetailsCard from '@/components/elements/molecules/DetailsCard/DetailsCard'

import STYLES from './MyAccountPage.module.sass'

const MyAccountPage = (): JSX.Element => {
  const navigate = useNavigate()
  const accessManagement = useAccessManagement()

  return (
    <Grid container>
      <Grid item xs={12}>
        <>
          {accessManagement?.canUserAccessRoute(ACCOUNT_ROUTES.ambassador) && (
            <div className={STYLES.container}>
              <DetailsCard
                text={{
                  text: 'account_navigation.ambassador'
                }}
                onClick={() =>
                  (window.location.href = ACCOUNT_ROUTES.ambassador)
                }
                namespace="molecules"
                icon={<ButternutLogo width={30} />}
                identifier="mobile.account_navigation.ambassador"
              />
            </div>
          )}
          {accessManagement?.canUserAccessRoute(ACCOUNT_ROUTES.influencer) && (
            <div className={STYLES.container}>
              <DetailsCard
                text={{
                  text: 'account_navigation.influencer'
                }}
                onClick={() =>
                  (window.location.href = ACCOUNT_ROUTES.influencer)
                }
                identifier="mobile.account_navigation.influencer"
                namespace="molecules"
                icon={<ButternutLogo width={30} />}
              />
            </div>
          )}
          <div className={STYLES.container}>
            <DetailsCard
              text={{
                text: 'account_navigation.account_details'
              }}
              onClick={() => navigate(ACCOUNT_ROUTES.personalDetails)}
              identifier="mobile.account_navigation.account_details"
              namespace="molecules"
              icon={
                <Person
                  showShadow
                  accentColour="brandBlue500"
                  height={32}
                  width={30}
                />
              }
            />
          </div>
          <div className={STYLES.container}>
            <DetailsCard
              text={{
                text: 'account_navigation.payment_methods'
              }}
              onClick={() => navigate(ACCOUNT_ROUTES.paymentMethods)}
              identifier="mobile.account_navigation.payment_methods"
              namespace="molecules"
              icon={
                <PaymentMethods
                  showShadow
                  accentColour="brandBlue500"
                  height={32}
                  width={30}
                />
              }
            />
          </div>
          <div className={STYLES.container}>
            <DetailsCard
              text={{
                text: 'account_navigation.order_history'
              }}
              onClick={() => navigate(ACCOUNT_ROUTES.orders)}
              identifier="mobile.account_navigation.order_history"
              namespace="molecules"
              icon={
                <BoxOpen
                  showShadow
                  accentColour="brandBlue500"
                  height={32}
                  width={30}
                />
              }
            />
          </div>
          <div className={STYLES.container}>
            <DetailsCard
              text={{
                text: 'account_navigation.contact_preferences'
              }}
              onClick={() => navigate(ACCOUNT_ROUTES.contactPreferences)}
              identifier="mobile.account_navigation.contact_preferences"
              namespace="molecules"
              icon={
                <Letter
                  showShadow
                  accentColour="brandBlue500"
                  height={32}
                  width={30}
                />
              }
            />
          </div>
          <div className={STYLES.container}>
            <DetailsCard
              text={{ text: 'account_navigation.help', translate: true }}
              onClick={() => (window.location.href = '/contact')}
              identifier="mobile.account_navigation.help"
              namespace="molecules"
              icon={
                <Phone
                  showShadow
                  accentColour="brandBlue500"
                  height={32}
                  width={30}
                />
              }
            />
          </div>
          <div className={STYLES.container}>
            <DetailsCard
              text={{ text: 'account_navigation.logout', translate: true }}
              onClick={() => (window.location.href = '/users/sign_out')}
              identifier="mobile.account_navigation.logout"
              namespace="molecules"
              icon={
                <Logout
                  showShadow
                  accentColour="brandBlue500"
                  height={32}
                  width={30}
                />
              }
            />
          </div>
        </>
      </Grid>
    </Grid>
  )
}

export default MyAccountPage
