// @noflow
// Factories
import { Factory } from 'fishery'

// eslint-disable-next-line no-restricted-imports
import type { Dog } from '@/components/types'

// Enums
import { Eater, Gender } from '@/types/index'

import DogProfileFactory from './DogProfileFactory'

export default Factory.define<Dog>(({ sequence }) => ({
  ageInMonths: 24,
  dailyCalories: 1000,
  dailyGrams: 500,
  id: sequence.toString(),
  name: 'Dog 1',
  neutered: true,
  possessivePronoun: 'his',
  pouchSizeInGrams: 500,
  weightInGrams: 5000,
  breed: {
    id: '1',
    key: '1',
    name: 'Labrador Retriever'
  },
  gender: Gender.male,
  dogProfile: DogProfileFactory.build(),
  eaterType: Eater.fussy_eater
}))
