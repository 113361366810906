// @noflow
import React from 'react'

// Styles
import STYLES from './Rewards.module.sass'

// Types
import { ICON_COLOURS, IconProps } from '../index'

type Props = {
  showShadow?: boolean
} & IconProps

const Rewards = ({
  height,
  accentColour,
  width,
  showShadow
}: Props): JSX.Element => {
  return (
    <div className={STYLES.wrapper}>
      <svg
        width={width ?? 26}
        height={height ?? 27}
        viewBox="0 0 26 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={STYLES.icon}
      >
        <path
          d="M2.682 12.307c0 .547-.086 9.406-.056 10.022.05.854.235 1.692.235 2.552 0 .188-.007.514.073.656.331.59.898.179 1.655.268.561-.015 16.253-.09 16.835-.09.132 0 .524-.103.613-.191.174-.175.132-.903.132-1.133 0-.392.238-9.49.238-9.882v-2.023M1.073 12c.691 0 21.418.066 22.11.066.157-.015.476-.02.622-.086.087-.04.345.015.39-.063.123-.216-.017-.469.073-.682-.073-1.235-.073-1.503-.073-2.146 0-.307-.043-.592-.06-.894-.017-.318-.914-.178-1.161-.178-1.747 0-3.516-.057-5.261-.12-1.634-.058-7.325-.178-8.97-.178H1.4c-.106 0-.328-.054-.328.09v.416c0 .354-.04.705-.06 1.043-.044.809.035 1.922.035 2.732"
          stroke={ICON_COLOURS[accentColour]}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M10.357 8.313c0 1.325.248 2.617.33 3.933.133.476.507 11.622.507 12.116v1.467M14.048 8.313c0 1.325.248 2.617.33 3.933.132.476.507 11.622.507 12.116v1.467M8.549 7.5c-.687-.076-2.311-1.382-2.752-1.823-.519-.518-.748-1.456-.748-2.177 0-.436 0-1 .334-1.5.423-.27 1.178-.5 1.666-.5.523 0 1.42.046 1.886.305.527.292 1.136.714 1.572 1.134.795.767 1.943 1.768 1.943 2.947 0 .222-.013 1.036 0 1.258M12.548 7.142c0-2.135 1.582-4.69 3.416-5.505 1.09-.484 2.078-.212 3.097.298.336.168.87.541.92.939.074.59.05.931-.27 1.506-.17.309-.614.807-.901.986-.512.32-.815.834-1.34 1.125-.22.123-1.293.79-1.422 1.009"
          stroke={ICON_COLOURS[accentColour]}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
      {showShadow && (
        <svg
          width="27"
          height="28"
          viewBox="0 0 27 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={STYLES.shadow}
        >
          <path
            d="M1.50007 13.5C0.700071 13.5 1.16674 10.1667 1.50007 8.50001C1.57573 8.10002 1.86488 8.00001 2 8C4.16667 8.16667 8.2 8.40001 7 8C5.5 7.49999 5 4.50001 5.5 3.00001C6 1.50001 8.5 2.00001 10 2.00001C11.2 2.00001 12.8333 4.00001 13.5 5.00001C14 4.33335 15.1 2.90001 15.5 2.50001C16 2.00001 17 2.00001 19.5 2.00001C22 2.00001 21.5 4.50001 21.5 5.50001C21.5 6.30001 19.5 7.83335 18.5 8.50001H26C27 8.50001 26.5 12.5 26.5 13.5C26.5 14.3 24.8333 14.1667 24 14V24.5C24 26.9 23 27.5 22.5 27.5H15.5C12 27.6667 4.7 27.9 3.5 27.5C2.3 27.1 3 18 3.5 13.5H1.50007Z"
            fill="#FFE180"
          />
        </svg>
      )}
    </div>
  )
}

export type { Props }

export { Rewards }
