import React from 'react'

import { BoxPriceBreakdown } from './components/BoxPriceBreakdown'
import { NonCoreOrderPriceBreakdown } from './components/NonCoreOrderPriceBreakdown'

import { BoxOrderSummaryModalFragment as Box } from './fragments/__generated__/BoxOrderSummaryModalFragment'
import { NonCoreOrderOrderSummaryModalFragment as NonCoreOrder } from './fragments/__generated__/NonCoreOrderOrderSummaryModalFragment'
import { OrderSummaryModalFragment as Order } from './fragments/__generated__/OrderSummaryModalFragment'

import ModalLayout from '../layout'

type Props = {
  data: {
    order: Order
  }
}

const OrderSummaryModal = (props: Props): JSX.Element => {
  const {
    data: { order }
  } = props
  const box: Box | null = order.__typename === 'Box' ? order : null
  const nonCoreOrder: NonCoreOrder | null =
    order.__typename === 'NonCoreOrder' ? order : null
  return (
    <ModalLayout
      title={{
        text: 'order_summary.title',
        namespace: 'modals'
      }}
    >
      {box && <BoxPriceBreakdown box={box} />}
      {nonCoreOrder && <NonCoreOrderPriceBreakdown order={nonCoreOrder} />}
    </ModalLayout>
  )
}

export { OrderSummaryModal }
