// @flow

import type { Tab } from './partials/SelectBar'

const tabs: Array<Tab> = [
  {
    title: 'Your Summary',
    tabTitle: 'Summary',
    hash: 'summary',
    pathname: '/dashboard/summary',
    translation_key: 'ambassadors:dashboard.tabs.your_summary'
  },
  {
    title: 'Refer a Client',
    tabTitle: 'Refer',
    hash: 'refer',
    pathname: '/dashboard/referrals',
    translation_key: 'ambassadors:dashboard.tabs.refer_a_client'
  },
  {
    title: 'Free Merch',
    tabTitle: 'Merch',
    hash: 'merch',
    pathname: '/dashboard/merch',
    translation_key: 'ambassadors:dashboard.tabs.free_merch'
  },
  {
    title: 'Balance',
    tabTitle: 'Balance',
    hash: 'balance',
    pathname: '/dashboard/balance',
    translation_key: 'ambassadors:dashboard.tabs.balance'
  },
  {
    title: 'Contact',
    tabTitle: 'Contact',
    hash: 'contact',
    pathname: '/dashboard/contact',
    translation_key: 'ambassadors:dashboard.tabs.contact'
  }
]

const shopTab: Array<Tab> = [
  {
    title: 'Visit Shop',
    tabTitle: 'Shop',
    hash: 'shop',
    pathname: '',
    translation_key: 'ambassadors:dashboard.tabs.visit_shop'
  }
]

export {
  tabs,
  shopTab
}
