import { gql } from '@apollo/client'

import { PENDING_SUBSCRIPTION_ORDER_PRICING_FRAGMENT } from './fragments/PendingSubscriptionOrderPricingFragment'

const CHECKOUT_PENDING_SUBSCRIPTION_ORDER_PRICING = gql`
  ${PENDING_SUBSCRIPTION_ORDER_PRICING_FRAGMENT}
  query CheckoutPendingSubscriptionOrderPricingQuery(
    $expectedFirstShippingDate: ISO8601Date
    $planId: ID!
  ) {
    shouldSeeUpcomingPriceRiseInfo: featureFlag(
      flag: "system-should-see-upcoming-price-rise-info"
      fallback: "false"
    )
    guest {
      id
      pendingSubscription {
        id
        standardOrderPricing(
          expectedFirstShippingDate: $expectedFirstShippingDate
        ) {
          ...pendingSubscriptionOrderPricingFragment
        }
        firstOrderPricing(
          expectedFirstShippingDate: $expectedFirstShippingDate
        ) {
          ...pendingSubscriptionOrderPricingFragment
        }
        secondOrderPricing(
          expectedFirstShippingDate: $expectedFirstShippingDate
        ) {
          ...pendingSubscriptionOrderPricingFragment
        }
        plan {
          durationInDays
          numberOfPouches
          pouchSize
          id
          trial {
            lengthInDays
          }
          pouchesPerDay
          nextUpcomingPrice {
            effectiveFrom
          }
        }
        pendingSubscriptionFlavours {
          id
          flavour {
            id
            standardPricePerDay(planId: $planId)
          }
        }
        productVariants {
          productVariant {
            id
            name
            grossPrice
            recurringDeliveryType {
              netPrice
            }
            productVariantDeliveryTypes {
              deliveryType
              netPrice
            }
            productCollection {
              name
              merchandisingImage {
                src
              }
            }
          }
          discountedPrice
        }
      }
    }
  }
`

export { CHECKOUT_PENDING_SUBSCRIPTION_ORDER_PRICING }
