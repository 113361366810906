//  @noflow
import { ACCOUNT_ROUTES } from '@/routes'
import { useQuery } from '@apollo/client'
import type { VariantDelivery } from '@types'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import Basket from 'assets/images/icons/basket.svg'

import SkeletonButton from '@/components/elements/atoms/SkeletonButton/SkeletonButton'
import Text from '@/components/elements/atoms/Text/Text'
import BasketCard from '@/components/pages/ExtrasPage/components/BasketCard/BasketCard'

import STYLES from './BasketButton.module.sass'

import { BASKET_BUTTON_QUERY } from './queries/basketButtonQuery'

import { BasketButtonQuery } from './queries/__generated__/BasketButtonQuery'

type Props = {
  shake?: boolean
  position?: 'relative' | 'absolute'
  selectedProductVariantFrequency?: VariantDelivery
  selectedProductQuantity?: number
  isProductAdded?: boolean
}

const BasketButton = ({
  shake = false,
  position = 'relative',
  selectedProductVariantFrequency,
  selectedProductQuantity,
  isProductAdded = false
}: Props): JSX.Element | null => {
  const { t } = useTranslation('dashboard')
  const { windowWidth } = useWindowSize()

  const handleAnalytics = useCallback(() => {
    window.analytics.track('Shop: Tapped on basket icon')
  }, [])

  const { loading, data, error } =
    useQuery<BasketButtonQuery>(BASKET_BUTTON_QUERY)

  if (loading) {
    return <SkeletonButton height={50} width={116} />
  }

  if (error) {
    toast.error(error.message)
    return null
  }

  const count = data?.user?.basket?.basketProducts.reduce((acc, product) => {
    return acc + product.quantity
  }, 0)

  if (!count) return null

  return (
    <div className={STYLES.wrapper}>
      <Link to={ACCOUNT_ROUTES.basket} onClick={handleAnalytics}>
        <div
          className={`${STYLES.container} ${shake && STYLES.shake} ${
            position === 'absolute' && STYLES.absolute
          }`}
        >
          <div className={STYLES.text}>
            <Text
              text="extras.basket.basket"
              namespace="dashboard"
              variant="textRegular16"
              colour="brandYellow100"
              margin={false}
            />
          </div>
          <div className={STYLES.icon}>
            <img alt={t('extras.basket.basket')} src={Basket} />
            {count && count > 0 && (
              <div className={STYLES.count}>
                <Text
                  text={count.toString()}
                  variant="textRegular12"
                  colour="brandWhite"
                  shouldScale={false}
                  margin={false}
                  translate={false}
                />
              </div>
            )}
          </div>
        </div>
      </Link>
      {isProductAdded && windowWidth > BREAKPOINTS.md && (
        <div className={STYLES.cardWrapper}>
          <BasketCard
            selectedProductVariantFrequency={selectedProductVariantFrequency}
            selectedProductQuantity={selectedProductQuantity}
            polygonPosition="top"
          />
        </div>
      )}
    </div>
  )
}

export default BasketButton
