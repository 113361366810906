import React, { useCallback, useEffect, useState } from 'react'

import {
  CustomerIssueReportCreate_response_inputFields_CustomerIssueManagementInputFieldMultifield_fields_CustomerIssueManagementInputFieldAssociation as MultifieldAssociationField,
  CustomerIssueReportCreate_response_inputFields_CustomerIssueManagementInputFieldMultifield as MultifieldField
} from '../../../../mutations/__generated__/CustomerIssueReportCreate'
import { CustomerIssueManagementInputInput } from '@/types'

import MultiselectAndEnumerate from '../display_types/MultiselectAndEnumerate'

type MultifieldProps = {
  inputField: MultifieldField
  sectionId: string
  updateInputs: (
    fieldId: string,
    newInputs: Array<CustomerIssueManagementInputInput>
  ) => void
}

const Multifield = ({
  inputField,
  sectionId,
  updateInputs
}: MultifieldProps): JSX.Element | null => {
  const [pageState, setPageState] = useState('')
  const [multifieldInputs, setMultifieldInputs] = useState<
    Array<CustomerIssueManagementInputInput>
  >([])
  const associationField =
    (inputField.fields.find(
      (field) => field.dataType === 'association'
    ) as MultifieldAssociationField) || null

  const updateMultifieldInputs = useCallback(
    (newMultifieldInputs: Array<CustomerIssueManagementInputInput>) => {
      const fieldId = inputField.id
      updateInputs(fieldId, newMultifieldInputs)
    },
    [inputField.id, updateInputs]
  )

  const compareAssociationValues = useCallback(
    (
      inputA: CustomerIssueManagementInputInput,
      inputB: CustomerIssueManagementInputInput
    ) => {
      const inputAInputs =
        inputA.inputs as Array<CustomerIssueManagementInputInput>
      const associationInputA = inputAInputs.find(
        (input) => input.fieldId === associationField.id
      )
      const inputBInputs =
        inputB.inputs as Array<CustomerIssueManagementInputInput>
      const associationInputB = inputBInputs.find(
        (input) => input.fieldId === associationField.id
      )
      return (
        associationInputA?.associationValue ===
        associationInputB?.associationValue
      )
    },
    [associationField]
  )

  const addMultifieldInput = useCallback(
    (newMultifieldInput: CustomerIssueManagementInputInput): void => {
      const multifieldInputsWithoutNewMultifieldInput = multifieldInputs.filter(
        (multifieldInput) =>
          !compareAssociationValues(newMultifieldInput, multifieldInput)
      )
      const multifieldInputsWithNewMultifieldInput =
        multifieldInputsWithoutNewMultifieldInput.concat(newMultifieldInput)
      setMultifieldInputs(multifieldInputsWithNewMultifieldInput)
      updateMultifieldInputs(multifieldInputsWithNewMultifieldInput)
    },
    [multifieldInputs, updateMultifieldInputs, compareAssociationValues]
  )

  const removeMultifieldInput = useCallback(
    (multifieldInputsToRemove: CustomerIssueManagementInputInput) => {
      const multifieldInputsWithoutNewMultifieldInput = multifieldInputs.filter(
        (multifieldInput) =>
          !compareAssociationValues(multifieldInputsToRemove, multifieldInput)
      )
      setMultifieldInputs(multifieldInputsWithoutNewMultifieldInput)
      updateMultifieldInputs(multifieldInputsWithoutNewMultifieldInput)
    },
    [multifieldInputs, updateMultifieldInputs, compareAssociationValues]
  )

  useEffect(() => {
    const state = sectionId.split('_')[1]
    if (state) {
      setPageState(state)
    }
  }, [sectionId])
  switch (inputField.multifieldDisplayType) {
    case 'product_count':
      return (
        <MultiselectAndEnumerate
          inputField={inputField}
          addMultifieldInput={addMultifieldInput}
          removeMultifieldInput={removeMultifieldInput}
          multifieldInputs={multifieldInputs}
          pageState={pageState}
        />
      )
    default: {
      throw new Error('Unsupported multifield display type')
    }
  }
}

export default Multifield
