// @noflow
import type { PaymentMethod as StripePaymentMethod } from '@stripe/stripe-js'
import i18next from 'i18next'
import Cookies from 'js-cookie'

import * as Sentry from '@/utils/sentry'

import * as ANALYTICS from '../../../Analytics/CheckoutAnalytics'
import * as inputs from '../../../helpers/inputs'
import type {
  CheckoutState,
  PaymentIdentifier,
  PaymentProvider,
  PaymentType
} from '../../../types'
import { bannerMessageState } from '../Banner'
import { setSubmissionState } from '../PaymentSection'
import {
  paymentMethodIdentifierType,
  paymentMethodToPaymentMethodType,
  paymentMethodToProvider
} from './formatPaymentMethod'

type DeliveryCadence = 'short' | 'default' | 'long'

type MealBreakdown = { [key: string]: number }

type SubscriptionData = {
  plan_id: number
  payment_provider: PaymentProvider
  payment_method_identifier_type: PaymentIdentifier
  additional_product_ids: number[]
  payment_method_type: PaymentType
  target_first_delivery_date: string
  meal_breakdown: MealBreakdown
  delivery_cadence: DeliveryCadence | null
  ga_client_id: string | undefined
  user: {
    address_attributes: {
      address_1: string
      address_2: string
      city: string
      shipping_country_id: number
      recipient_name: string
      postcode: string
      delivery_notes?: string
    }
    should_support_sca: boolean
    email: string
    first_name: string
    last_name: string
    password: string
    password_confirmation: string
    phone: string | null
  }
}

type SubscriptionResponse = {
  redirect_to: string | null
  error: string | null
}

const subscriptionData = ({
  state
}: {
  state: CheckoutState
}): SubscriptionData => {
  const { user, sections, plan } = state
  const { accountDetails, deliveryDetails, paymentDetails, addressDetails } =
    sections

  const paymentMethod = paymentDetails.form.selectedPaymentMethod.type
  const deliveryCadence = window.localStorage.getItem(
    'deliveryCadence'
  ) as DeliveryCadence | null
  if (!deliveryCadence) {
    Sentry.captureException(
      `Cannot find deliveryCadence item in localStorage`,
      {
        tags: {
          product: Sentry.Product.Checkout
        }
      }
    )
    window.location.href = '/plans/recipes'
  }

  const planState = window.localStorage.getItem(
    'simplified_selected_plan_details'
  )
  if (!planState) {
    Sentry.captureException(
      'Cannot find simplified_selected_plan_details in local storage',
      {
        tags: {
          product: Sentry.Product.Checkout
        }
      }
    )
    window.location.href = '/plans/recipes'
  }

  const { firstName, lastName } = inputs.formatNameInput(
    accountDetails.form.name.value
  )
  const gaClientID = Cookies.get('ga_client_id')
  const gclid = window.sessionStorage.getItem('gclid')
  const facebookClickId = Cookies.get('_fbc')
  const facebookBrowserId = Cookies.get('_fbp')
  const postcode = inputs.removeExtraWhiteSpace(
    addressDetails.form.postcode.value
  )
  const deliveryDetailsMobileNumber =
    deliveryDetails.form.mobileNumber.value === ''
      ? null
      : deliveryDetails.form.mobileNumber.value

  const baseSubscriptionData = {
    payment_provider: paymentMethodToProvider(paymentMethod),
    payment_method_identifier_type: paymentMethodIdentifierType(paymentMethod),
    payment_method_type: paymentMethodToPaymentMethodType(paymentMethod),
    additional_product_ids: plan.extraProducts.map((extraProduct) =>
      parseInt(extraProduct.productVariant.id)
    ),
    target_first_delivery_date: deliveryDetails.form.selectedDeliveryDate.value,
    selected_delivery_area_id:
      deliveryDetails.form.selectedDeliveryDate.selectedDeliveryArea?.id,
    plan_id: plan.planId,
    meal_breakdown: planState ? JSON.parse(planState).mealBreakdown : null,
    delivery_cadence: deliveryCadence,
    ga_client_id: gaClientID,
    user: {
      first_name: firstName,
      last_name: lastName,
      should_support_sca: user.shouldSupportSca,
      email: accountDetails.form.email.value,
      phone: deliveryDetailsMobileNumber,
      password: accountDetails.form.password.value,
      password_confirmation: accountDetails.form.password.value,
      google_click_identifier: gclid,
      address_attributes: {
        address_1: addressDetails.form.addressLine1.value,
        address_2: addressDetails.form.addressLine2.value,
        postcode: postcode,
        city: addressDetails.form.city.value,
        shipping_country_id: user.shippingCountryCodeId,
        recipient_name: firstName + ' ' + lastName,
        delivery_notes: deliveryDetails.form.deliveryNotes.value
      },
      facebook_user_tracking_attributes: {
        click_id: facebookClickId,
        browser_id: facebookBrowserId
      }
    }
  }

  ANALYTICS.trackPlanId(plan.planId)

  return baseSubscriptionData
}

const submitSubscription = ({
  data,
  csrfToken,
  paymentMethodId,
  boxOneChargeId
}: {
  data: SubscriptionData
  csrfToken: string
  paymentMethodId: string | StripePaymentMethod
  boxOneChargeId?: string
}): Promise<SubscriptionResponse> => {
  return fetch('/subscriptions', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': csrfToken
    },
    credentials: 'same-origin',
    body: JSON.stringify({
      ...data,
      payment_method_identifier: paymentMethodId,
      box_one_charge_id: boxOneChargeId
    })
  }).then((response) => {
    if (response.ok) {
      try {
        return response.json()
      } catch (error) {
        Sentry.captureException(
          `Encountered error parsing response.json from /subscriptions`,
          {
            extra: {
              error
            },
            tags: {
              product: Sentry.Product.Checkout
            }
          }
        )

        bannerMessageState({
          message: i18next.t('checkout:errors.delivery_details_fetch'),
          type: 'error'
        })
      }
    } else {
      bannerMessageState({
        message: i18next.t('checkout:errors.delivery_details_fetch'),
        type: 'error'
      })
      Sentry.captureException(
        `Received error in response from submitSubscription`,
        {
          extra: {
            response
          },
          tags: {
            product: Sentry.Product.Checkout
          }
        }
      )

      setSubmissionState({
        type: 'error',
        error: i18next.t('checkout:errors.delivery_details_fetch')
      })

      return response.json()
    }
  })
}

export { subscriptionData, submitSubscription }

export type { SubscriptionData }
