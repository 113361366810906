// @flow

import * as React from 'react'
import {
  differenceInYears,
  differenceInMonths,
  differenceInDays
} from 'date-fns'

import type { DogDetails } from '../../message_types'

type Props = $ReadOnly<{|
  dispatchToggleDogOverview: ((dogIndex: number, panelInView: 'overview' | 'description') => void),
  dogIndex: number,
  details: DogDetails
|}>

// eslint-disable-next-line react/require-optimization
class ExpandedDogDetails extends React.Component<Props> {
  renderOverview = (): React.Node => {
    const { details, dogIndex } = this.props

    const today = new Date()
    const dateOfBirth = new Date(details.overview_details.age)
    const ageInYears = differenceInYears(today, dateOfBirth)
    const ageInMonths = differenceInMonths(today, dateOfBirth) % 12
    const ageInDays = differenceInDays(today, dateOfBirth)

    return (
      <div className='expanded-details__body'>
        <div className='card__row__column'>
          <div className='card__row__subrow'>
            <p>
              { `Activity level` }
            </p>
            <p>
              { details.overview_details.activity_level }
            </p>
          </div>
          <div className='card__row__subrow'>
            <p>
              { `Weight` }
            </p>
            <p>
              { `${details.overview_details.weight}kg` }
            </p>
          </div>
          <div className='card__row__subrow'>
            <p>
              { `Snacking` }
            </p>
            <p>
              {
                details.overview_details.snacking_habits
              }
            </p>
          </div>
          <div className='card__row__subrow'>
            <p>
              { `Type of eater` }
            </p>
            <p>
              {
                details.overview_details.type_of_eater &&
                details.overview_details.type_of_eater
              }
            </p>
          </div>
          <div className='card__row__subrow'>
            <p>
              { `Age` }
            </p>
            <p>
              {
                ageInYears === 0 && ageInMonths === 0 && ageInDays !== 0 && (
                  `${ageInDays} ${ageInDays === 1 ? 'Day' : 'Days'}`
                )
              }
              {
                ageInYears !== 0 && (
                  `${ageInYears} ${ageInYears === 1 ? 'Year' : 'Years'}`
                )
              }
              {
                ageInMonths !== 0 && (
                  ` ${ageInMonths} ${ageInMonths === 1 ? 'Month' : 'Months'}`
                )
              }
            </p>
          </div>
        </div>
        <div className='card__row__column'>
          <div className='card__row__subrow'>
            <p>{'Health concerns'}</p>
            {details.overview_details.health_concerns.map((concern: string): React.Node =>
              <p key={`${concern}--${dogIndex}`}>{concern}</p>
            )}
            {details.overview_details.health_concerns.length === 0 && (<p>{'None'}</p>)}
          </div>
          <div className='card__row__subrow'>
            <p>{'Body condition'}</p>
            <p>{details.overview_details.body_condition}</p>
          </div>
          <div className='card__row__subrow'>
            <p>{'Recommended pouch size'}</p>
            <p>{`${details.overview_details.recommended_pouch_size}g (${details.overview_details.recommended_pouches_per_day} pouch/day)`}</p>
          </div>
          <div className='card__row__subrow'>
            <p>{'Recommended gram amount'}</p>
            <p>{`${details.overview_details.recommended_gram_amount}g`}</p>
          </div>
          <div className='card__row__subrow'>
            <p>
              {
                details.summary_details.gender === 'male'
                    ? 'Neutered'
                    : 'Spayed'
              }
            </p>
            <p>
              {details.overview_details.neutered ? `Yes` : `No`}
            </p>
          </div>
          <div className='card__row__subrow'>
            <p>{'Allergies'}</p>
            {details.overview_details.allergies.map((allergy: string): React.Node =>
              <p key={`${allergy}--${dogIndex}`}>{allergy}</p>
            )}
            {details.overview_details.allergies.length === 0 && (<p>{'None'}</p>)}
          </div>
        </div>
      </div>
    )
  }

  renderDescription = (): React.Node => {
    const {details, dogIndex} = this.props
    return (
      <div>
        <div className='card__row__subrow'>
          <p>{'About'}</p>
          <p>{details.description_details.description}</p>
        </div>
        <div className='card__row__subrow'>
          <p>{'Favourite game'}</p>
          <p>{details.description_details.favourite_game}</p>
        </div>
        <div className='card__row__subrow'>
          <p>{'Favourite activities'}</p>
          <p>{details.description_details.favourite_activities}</p>
        </div>
        <div className='card__row__subrow'>
          <p>{'Quirks'}</p>
          {details.description_details.quirks.map((quirk: string): React.Node =>
            <p key={`${quirk}--${dogIndex}`}>{quirk}</p>
          )}
        </div>
      </div>
    )
  }

  handleToggleOverview = (i: number, panelInView: 'overview' | 'description'): void => {
    const { dispatchToggleDogOverview } = this.props

    dispatchToggleDogOverview(i, panelInView)
  }

  render (): React.Node {
    const { details } = this.props
    const { detailsExpanded } = details
    const { dogIndex } = this.props
    const { panelInView } = details

    if (detailsExpanded) {
      return (
        <div className='expanded-details'>
          <div className='expanded-details__header-container'>
            { /* eslint-disable-next-line */}
            <button type="button" onClick={this.handleToggleOverview.bind(this, dogIndex, 'overview')}>
              <h6 className={panelInView === 'overview' ? 'active' : ''}>{'OVERVIEW'}</h6>
            </button>
            { /* eslint-disable-next-line */}
            <button type="button" onClick={this.handleToggleOverview.bind(this, dogIndex, 'description')}>
              <h6 className={panelInView === 'description' ? 'active' : ''}>{'DESCRIPTION'}</h6>
            </button>
          </div>
          <div className='expanded-details__body-container'>
            { panelInView === 'description' ? this.renderDescription() : this.renderOverview() }
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}

export default ExpandedDogDetails
