// @noflow

/* eslint-disable i18next/no-literal-string */
import { useNotifications } from '@/context/notifications/notifications'
import { ACCOUNT_ROUTES } from '@/routes'
import { useMutation } from '@apollo/client'
import i18next from 'i18next'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { possessive } from '@/utils/StringHelper'

import BREAKPOINTS from '@/constants/Breakpoints'

import useBoolean from '@/hooks/useBoolean'
import useWindowSize from '@/hooks/useWindowSize'

import CheckmarkArea from 'assets/images/icons/checkmarks/checkmark--green-area.svg'
import DogsEatingHabits from 'assets/images/illustrations/dogs/dogs-eating-habits.svg'
import EatsAnything from 'assets/images/illustrations/dogs/eater-type/eats-anything.svg'
import FussyEater from 'assets/images/illustrations/dogs/eater-type/fussy-eater.svg'
import GoodEater from 'assets/images/illustrations/dogs/eater-type/good-eater.svg'
import PickyEater from 'assets/images/illustrations/dogs/eater-type/picky-eater.svg'
import EatsLotsOfSnacks from 'assets/images/illustrations/dogs/snacks/eats-lots-of-snacks.svg'
import EatsNoSnacks from 'assets/images/illustrations/dogs/snacks/eats-no-snacks.svg'
import EatsSomeSnacks from 'assets/images/illustrations/dogs/snacks/eats-some-snacks.svg'
import Plant from 'assets/images/illustrations/plant.svg'

import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'
import { FixedBase } from '@/components/elements/atoms/FixedBase'
import Modal from '@/components/elements/atoms/Modal/Modal'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import Text from '@/components/elements/atoms/Text/Text'
import { SelectableCardGroup } from '@/components/elements/molecules/SelectableCardGroup'

import STYLES from './UpdateDogEatingHabits.module.sass'

import { UPDATE_DOG_EATING_HABITS_MUTATION } from './mutations/updateDogEatingHabitsMutation'

import type {
  UpdateDogEatingHabitsMutation,
  UpdateDogEatingHabitsMutationVariables
} from './mutations/__generated__/UpdateDogEatingHabitsMutation'
import type {
  DogEatingHabitsQuery_user_dogs as Dog,
  DogEatingHabitsQuery
} from './queries/__generated__/DogEatingHabitsQuery'
import { Eater, SnackingHabit } from '@/types'

type Props = Partial<DogEatingHabitsQuery> & {
  selectedDog?: Dog
  loading: boolean
}

const UpdateDogEatingHabitsContent = ({
  selectedDog,
  loading,
  ...restProps
}: Props): JSX.Element => {
  const container = useRef<HTMLDivElement>(null)
  const { t } = useTranslation('account')
  const { setErrorNotification } = useNotifications()
  const navigate = useNavigate()
  const { windowWidth } = useWindowSize()

  const isMobile = windowWidth < BREAKPOINTS.md

  const loadedData = (() => {
    if (loading) {
      return null
    }

    const { user } = restProps
    const { id: userId, dogs } = user ?? {}

    return {
      userId,
      dogs
    }
  })()

  const [shouldSetInitialData, setShouldSetInitialData] = useState(true)
  const [eatingHabits, setEatingHabits] = useState({
    eaterType: Eater.eats_anything,
    snackingHabits: SnackingHabit.unknown_snacking_habits
  })

  const {
    value: isConfirmationModalOpen,
    setTrue: openConfirmationModal,
    setFalse: closeConfirmationModal,
    toggle: toggleConfirmationModal
  } = useBoolean(false)

  const genderContext = selectedDog?.gender ?? ''
  const possessiveDogName =
    selectedDog && possessive(selectedDog.name, i18next.language)

  const commonIconStyles = {
    width: '100%',
    height: 70
  }

  const EATER_TYPE = [
    {
      value: Eater.eats_anything,
      icon: {
        src: EatsAnything,
        alt: 'update_dog_eating_habits.eater_type.eats_anything.heading'
      },
      typography: {
        text: 'update_dog_eating_habits.eater_type.eats_anything.heading',
        variables: { context: genderContext }
      },
      description: {
        text: 'update_dog_eating_habits.eater_type.eats_anything.text',
        variables: { context: genderContext }
      },
      identifier: 'update_dog_eating_habits.eater_type.eats_anything'
    },
    {
      value: Eater.good_eater,
      icon: {
        src: GoodEater,
        alt: 'update_dog_eating_habits.eater_type.good_eater.heading'
      },
      typography: {
        text: 'update_dog_eating_habits.eater_type.good_eater.heading',
        variables: { context: genderContext }
      },
      description: {
        text: 'update_dog_eating_habits.eater_type.good_eater.text',
        variables: { context: genderContext }
      },
      identifier: 'update_dog_eating_habits.eater_type.good_eater'
    },
    {
      value: Eater.picky_eater,
      icon: {
        src: PickyEater,
        alt: 'update_dog_eating_habits.eater_type.picky_eater.heading'
      },
      typography: {
        text: 'update_dog_eating_habits.eater_type.picky_eater.heading',
        variables: { context: genderContext }
      },
      description: {
        text: 'update_dog_eating_habits.eater_type.picky_eater.text',
        variables: { context: genderContext }
      },
      identifier: 'update_dog_eating_habits.eater_type.picky_eater'
    },
    {
      value: Eater.fussy_eater,
      icon: {
        src: FussyEater,
        alt: 'update_dog_eating_habits.eater_type.fussy_eater.heading'
      },
      typography: {
        text: 'update_dog_eating_habits.eater_type.fussy_eater.heading',
        variables: { context: genderContext }
      },
      description: {
        text: 'update_dog_eating_habits.eater_type.fussy_eater.text',
        variables: { context: genderContext }
      },
      identifier: 'update_dog_eating_habits.eater_type.fussy_eater'
    }
  ]

  const SNACKING_HABITS = [
    {
      value: SnackingHabit.eats_no_snacks,
      icon: {
        src: EatsNoSnacks,
        alt: 'update_dog_eating_habits.snacking_habits.none.heading',
        ...commonIconStyles
      },
      identifier: 'update_dog_eating_habits.snacking_habits.none'
    },
    {
      value: SnackingHabit.eats_some_snacks,
      icon: {
        src: EatsSomeSnacks,
        alt: 'update_dog_eating_habits.snacking_habits.some.heading',
        ...commonIconStyles
      },
      identifier: 'update_dog_eating_habits.snacking_habits.some'
    },
    {
      value: SnackingHabit.eats_lots_of_snacks,
      icon: {
        src: EatsLotsOfSnacks,
        alt: 'update_dog_eating_habits.snacking_habits.lots.heading',
        ...commonIconStyles
      },
      identifier: 'update_dog_eating_habits.snacking_habits.lots'
    }
  ]

  const noChangeToEatingHabits = (() => {
    if (selectedDog) {
      const { eaterType, snackingHabits } = selectedDog

      return (
        eaterType === eatingHabits.eaterType &&
        snackingHabits === eatingHabits.snackingHabits
      )
    }
  })()

  const handleOnSelect = useCallback(
    (value, type) => {
      setEatingHabits({
        ...eatingHabits,
        [type]: value
      })
    },
    [eatingHabits]
  )

  const handleOnEaterTypeSelect = useCallback(
    (value) => {
      handleOnSelect(value, 'eaterType')
    },
    [handleOnSelect]
  )

  const handleOnSnackingHabitsSelect = useCallback(
    (value) => handleOnSelect(value, 'snackingHabits'),
    [handleOnSelect]
  )

  /**
   * Update dog eating habits mutation
   *
   * On error, show error message and log error to Sentry.
   * On success, show success message, refetch data and go back to the
   * previous screen.
   */
  const [
    updateDogEatingHabitsMutation,
    { loading: updateDogEatingHabitsLoading }
  ] = useMutation<
    UpdateDogEatingHabitsMutation,
    UpdateDogEatingHabitsMutationVariables
  >(UPDATE_DOG_EATING_HABITS_MUTATION, {
    onError: () => {
      setErrorNotification({
        text: 'update_dog_eating_habits.notifications.error',
        namespace: 'account'
      })
    },
    onCompleted: () => {
      closeConfirmationModal()

      navigate(`${ACCOUNT_ROUTES.profile}/${selectedDog?.id}`)
    }
  })

  /**
   * Hander to update dog eating habits
   */
  const updateEatinghabits = useCallback(async () => {
    try {
      await updateDogEatingHabitsMutation({
        variables: {
          userId: loadedData?.userId ?? '',
          attributes: [
            {
              id: selectedDog?.id ?? '',
              snackingHabits: eatingHabits.snackingHabits as SnackingHabit,
              eaterType: eatingHabits.eaterType as Eater
            }
          ]
        }
      })
    } catch (e) {
      setErrorNotification({
        text: 'update_dog_eating_habits.notifications.error',
        namespace: 'account'
      })
    }
  }, [
    updateDogEatingHabitsMutation,
    loadedData,
    selectedDog,
    eatingHabits,
    setErrorNotification
  ])

  useEffect(() => {
    if (shouldSetInitialData && loadedData && selectedDog) {
      const { eaterType, snackingHabits } = selectedDog

      setEatingHabits({
        ...eatingHabits,
        eaterType,
        snackingHabits
      })

      // Prevent the effect from running again
      setShouldSetInitialData(false)
    }
  }, [shouldSetInitialData, loadedData, eatingHabits, selectedDog])

  useEffect(() => {
    if (updateDogEatingHabitsLoading) {
      openConfirmationModal()
    }
  }, [updateDogEatingHabitsLoading, openConfirmationModal])

  const getSectionWrapperProps = (type: string) =>
    ({
      headerTypography: {
        text: `update_dog_eating_habits.${type}.title`,
        namespace: 'account',
        align: 'center',
        variables: {
          context: genderContext
        }
      },
      bgColour: 'brandWhite90',
      borderRadius: 16,
      padding: { top: 16, right: 16, bottom: 16, left: 16 }
    } as const)

  return (
    <div ref={container}>
      <SectionWrapper
        {...getSectionWrapperProps('eater_type')}
        margin={{ top: 0 }}
      >
        <div className={STYLES.sectionContent}>
          <SelectableCardGroup
            options={EATER_TYPE}
            onSelect={handleOnEaterTypeSelect}
            currentValue={eatingHabits.eaterType}
            className={STYLES.eaterTypeCards}
            cardProps={{
              className: STYLES.eaterTypeCard,
              contentDirection: 'row',
              skeleton: {
                isLoading: loading,
                height: isMobile ? '8rem' : '15rem'
              }
            }}
          />
        </div>
      </SectionWrapper>
      <SectionWrapper {...getSectionWrapperProps('snacking_habits')}>
        <div className={STYLES.sectionContent}>
          <SelectableCardGroup
            options={SNACKING_HABITS}
            onSelect={handleOnSnackingHabitsSelect}
            currentValue={eatingHabits.snackingHabits}
            cardProps={{
              skeleton: {
                isLoading: loading,
                height: isMobile ? '8rem' : '15rem'
              }
            }}
          />
          <Card
            variant="brandYellow200"
            skeleton={{
              isLoading: loading,
              height: '9rem'
            }}
          >
            <Text
              namespace="account"
              text={`update_dog_eating_habits.snacking_habits.${eatingHabits.snackingHabits}.heading`}
              variables={{
                context: genderContext
              }}
              margin={false}
              variant="display16"
              align="center"
            />
            {eatingHabits.snackingHabits !==
              SnackingHabit.unknown_snacking_habits && (
              <Text
                namespace="account"
                text={`update_dog_eating_habits.snacking_habits.${eatingHabits.snackingHabits}.text`}
                margin={false}
                align="center"
              />
            )}
          </Card>
        </div>
      </SectionWrapper>
      <FixedBase backgroundColor="brandWhite90" container={container}>
        <div className={STYLES.fixedBaseContent}>
          <Button
            identifier="dog_eating_habits.update"
            onClick={updateEatinghabits}
            typography={{
              text: 'update_dog_eating_habits.button',
              namespace: 'account'
            }}
            skeleton={{
              isLoading: loading
            }}
            disabled={
              loading || updateDogEatingHabitsLoading || noChangeToEatingHabits
            }
          />
        </div>
      </FixedBase>
      <Modal
        isModalOpen={isConfirmationModalOpen}
        setOpenModal={toggleConfirmationModal}
        width={600}
        textAlign="center"
        fullHeight={isMobile}
        showCloseButton={false}
        backgroundColour="brandYellow100"
      >
        <div className={STYLES.confirmationModalContent}>
          <img
            src={CheckmarkArea}
            alt={t('update_dog_eating_habits.notifications.confirm.alt')}
          />
          <div>
            <img
              src={DogsEatingHabits}
              alt={t(
                'update_dog_eating_habits.notifications.confirm.dog_info_alt'
              )}
              className={STYLES.confirmationModalDogInfoImg}
            />
            <img
              src={Plant}
              alt={t(
                'update_dog_eating_habits.notifications.confirm.plant_alt'
              )}
              className={STYLES.confirmationModalPlantImg}
            />
          </div>
          <Text
            namespace="account"
            text="update_dog_eating_habits.notifications.confirm.text"
            variables={{
              possessiveDogName
            }}
            align="center"
            variant="display28"
          />
        </div>
      </Modal>
    </div>
  )
}

export { UpdateDogEatingHabitsContent }
