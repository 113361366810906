// @noflow
import { QueryLazyOptions, useLazyQuery } from '@apollo/client'

import * as Sentry from '@/utils/sentry'

import { checkoutPageState } from '@/components/pages/CheckoutPage/CheckoutPage'

import { DELIVERY_DATES_QUERY } from '../queries/deliveryDatesQuery'

import type {
  DeliveryDates as DeliveryDatesQuery,
  DeliveryDatesVariables
} from '../queries/__generated__/DeliveryDates'

import { generateEndOfLeadTime } from '../helpers/generateEndOfLeadTime'

type UseDeliveryDatesQuery = {
  fetchDeliveryDates: (
    options?: QueryLazyOptions<DeliveryDatesVariables> | undefined
  ) => void
  loading: boolean
}

const useDeliveryDatesQuery = (): UseDeliveryDatesQuery => {
  const { user, sections } = checkoutPageState()
  const comesFromPayPal = window.location.search.includes('token')
  const comesFromIdeal = window.location.search.includes('redirect_status')
  const userIsRedirected = comesFromIdeal || comesFromPayPal
  const [fetchDeliveryDates, { loading }] = useLazyQuery<
    DeliveryDatesQuery,
    DeliveryDatesVariables
  >(DELIVERY_DATES_QUERY, {
    onCompleted: ({ defaultSelectedDate, standardDeliveryFee }) => {
      const { date } = defaultSelectedDate
      const endOfLeadTime = generateEndOfLeadTime(defaultSelectedDate)
      user.deliveryFee = standardDeliveryFee
      user.firstDeliverableDate.date = date
      user.firstDeliverableDate.endOfLeadTime = endOfLeadTime
      if (!userIsRedirected) {
        // if a user is redirected back to checkout so we can proceed to subscription
        // creation, we do not want to reset the value of their selectedDeliveryDate
        sections.deliveryDetails.form.selectedDeliveryDate.value = date
        sections.deliveryDetails.form.selectedDeliveryDate.endOfLeadTime =
          endOfLeadTime
      }

      checkoutPageState({
        ...checkoutPageState(),
        sections,
        user
      })
    },
    onError: (error) => {
      Sentry.captureException(
        `Error with DELIVERY_DATES_QUERY on CheckoutPage`,
        {
          extra: {
            error: error.message
          },
          tags: {
            product: Sentry.Product.Checkout
          }
        }
      )
    }
  })

  return {
    fetchDeliveryDates,
    loading
  }
}

export default useDeliveryDatesQuery
