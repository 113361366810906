// @noflow
import type { Language } from '@/packs/localisation'
import { format } from 'date-fns'
import i18next from 'i18next'
import React, { useMemo } from 'react'
import type { ComponentProps, ReactElement } from 'react'

import { localeToDateLocale } from '@/utils/countryCodeHelper'

import Text from '@/components/elements/atoms/Text/Text'

import STYLES from '../CalendarV2.module.sass'

import CalendarV2 from '../CalendarV2'

type CalendarProps = ComponentProps<typeof CalendarV2>

type Props = {
  currentMonthInView: Date
  shippingCountryCode: CalendarProps['shippingCountryCode']
}

const HeaderMonthV2 = ({
  currentMonthInView,
  shippingCountryCode
}: Props): ReactElement => {
  // Variables
  const dateLocale = localeToDateLocale(
    shippingCountryCode,
    i18next.language as Language
  )

  const currentMonthCopy = useMemo(
    () =>
      `${format(currentMonthInView, 'LLLL', {
        locale: dateLocale
      })} ${currentMonthInView.getFullYear()}`,
    [currentMonthInView, dateLocale]
  )

  return (
    <div className={STYLES.month}>
      <div className={STYLES.monthName}>
        <Text
          translate={false}
          text={currentMonthCopy}
          element="p"
          colour="brandBlue500"
          variant="textRegular18"
          margin={false}
        />
      </div>
    </div>
  )
}

export default HeaderMonthV2
