import { gql } from '@apollo/client'

const GUEST_UPDATE_ITERABLE_UPSERT = gql`
  mutation GuestUpdateIterableUpsert($userId: ID!) {
    guestUpdateIterableUpsert(userId: $userId) {
      id
    }
  }
`

export { GUEST_UPDATE_ITERABLE_UPSERT }
