import isNull from 'lodash/isNull'
import React from 'react'

import Breakpoints from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import CloudinaryImage from '@/components/elements/atoms/CloudinaryImage'
import Text from '@/components/elements/atoms/Text'

// Styles
import STYLES from './ProductThumb.module.sass'

type Props = {
  image?: string
  alt: string
  quantity?: number | null
  size?: string
  v3?: boolean
  hideBackground?: boolean
  imageSize?: { mobile: number; desktop: number }
}

/**
 * A card representing a product with a background image of the product and it's details over the top
 * @param image - the background image
 * @param alt - a description of the product image
 * @param quantity - the quantity of the product
 * @param size - the product size
 * @param {Object} props.imageSize - The size of the image on different devices.
 * @param {number} props.imageSize.mobile - The size of the image on mobile devices. Default is 112.
 * @param {number} props.imageSize.desktop - The size of the image on desktop devices. Default is 173.
 *
 * @constructor
 */
const ProductThumb = ({
  image,
  alt,
  quantity,
  size,
  v3 = false,
  hideBackground = false,
  imageSize = { mobile: 112, desktop: 173 }
}: Props): JSX.Element => {
  const { windowWidth } = useWindowSize()
  const isMobile = windowWidth < Breakpoints.md
  return (
    <div className={v3 ? STYLES.v3 : ''}>
      <div
        className={`${STYLES.product} ${
          hideBackground ? STYLES.hideBackground : ''
        }`}
      >
        {quantity && !isNull(quantity) && (
          <div className={STYLES.quantity}>
            <Text
              text={v3 ? 'product_thumb.quantity' : quantity.toString()}
              namespace="molecules"
              translate={v3}
              element="span"
              variables={{ quantity }}
            />
          </div>
        )}
        {!v3 && size && (
          <div className={STYLES.size}>
            <Text text={size} translate={false} element="span" />
          </div>
        )}
        {image && (
          <CloudinaryImage
            image={{
              path: image,
              crop: 'fill',
              width: isMobile ? imageSize.mobile : imageSize.desktop,
              height: isMobile ? imageSize.mobile : imageSize.desktop,
              dpr: window.devicePixelRatio
            }}
            className={STYLES.thumb}
            alt={alt}
          />
        )}
      </div>
    </div>
  )
}

export { Props }
export default ProductThumb
