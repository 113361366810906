import { ensureNever } from '@/typescript/utils'

import { Routes as PlansRoutes } from '../types/routes'

type Props = {
  route: PlansRoutes
  hasRecommendedExtras: boolean
  shouldSeePetParentOnPlans: boolean
}

const currentRouteToCurrentStep = ({
  route,
  hasRecommendedExtras,
  shouldSeePetParentOnPlans = false
}: Props): number => {
  switch (route) {
    case PlansRoutes.PetParent:
      return shouldSeePetParentOnPlans ? 1 : 0
    case PlansRoutes.Recipes:
      return shouldSeePetParentOnPlans ? 2 : 1
    case PlansRoutes.Plan:
      return shouldSeePetParentOnPlans ? 3 : 2
    case PlansRoutes.Extras:
      return shouldSeePetParentOnPlans ? 4 : 3
    case PlansRoutes.Checkout:
      if (hasRecommendedExtras) {
        return shouldSeePetParentOnPlans ? 5 : 4
      } else return shouldSeePetParentOnPlans ? 4 : 3
    default:
      return 1
  }
}

const currentRouteToTotalStep = (
  hasRecommendedExtras: boolean,
  shouldSeePetParentOnPlans: boolean
): number => {
  if (hasRecommendedExtras) {
    return shouldSeePetParentOnPlans ? 5 : 4
  } else {
    return shouldSeePetParentOnPlans ? 4 : 3
  }
}

const currentRouteToPercentFilled = ({
  route,
  hasRecommendedExtras,
  shouldSeePetParentOnPlans = false
}: Props): number => {
  const totalSteps = currentRouteToTotalStep(
    hasRecommendedExtras,
    shouldSeePetParentOnPlans
  )
  switch (route) {
    case PlansRoutes.PetParent:
      return shouldSeePetParentOnPlans ? 100 / totalSteps : 0
    case PlansRoutes.Recipes:
      return shouldSeePetParentOnPlans
        ? 100 / totalSteps
        : (100 / totalSteps) * 2
    case PlansRoutes.Plan:
      return shouldSeePetParentOnPlans
        ? (100 / totalSteps) * 2
        : (100 / totalSteps) * 3
    case PlansRoutes.Extras:
      return shouldSeePetParentOnPlans
        ? (100 / totalSteps) * 3
        : (100 / totalSteps) * 4
    case PlansRoutes.Checkout:
      return 100
    default:
      ensureNever(route)
      return 100 / totalSteps
  }
}

export {
  currentRouteToCurrentStep,
  currentRouteToTotalStep,
  currentRouteToPercentFilled
}
