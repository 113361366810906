import { useOccasion } from '@/context/festiveTheme/festiveTheme'
import isUndefined from 'lodash/isUndefined'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Image from '@/components/elements/atoms/Image/Image'

import STYLES from './BCorpLogo.module.sass'

type Props = {
  variant?: 'white' | 'cardboard'
}

const BCorpLogo = ({ variant = 'white' }: Props): JSX.Element => {
  const { t } = useTranslation('atoms')
  const { festiveClass } = useOccasion()
  return (
    <a
      className={`${STYLES.logo} ${STYLES[variant]} ${
        !isUndefined(festiveClass) ? STYLES[festiveClass] : ''
      }`}
      href="http://www.bcorporation.net/find-a-b-corp/company/butternut-box"
      target="_blank"
      rel="noreferrer"
    >
      <Image
        className={STYLES.img}
        alt={t('bcorp_logo.alt')}
        slug="bcorp-logo"
        image={{
          width: 73,
          height: 124
        }}
      />
    </a>
  )
}

export { Props }
export default BCorpLogo
