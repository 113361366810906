// @flow

/* globals fetch */

import {
  makeSearchRequest,
  receiveSearchResponse,
  makeAccountDetailsRequest,
  receiveAccountDetailsResponse,
  updateSelectedUserIds,
  makeSubscriptionDetailsRequest,
  receiveSubscriptionDetailsResponse,
  makeApplicableDiscountsDetailsRequest,
  receiveApplicableDiscountsDetailsResponse,
  makeDogsDetailsRequest,
  receiveDogsDetailsResponse,
  makeDeliveriesDetailsRequest,
  receiveDeliveriesDetailsResponse,
  makeCustomerHistoryRequest,
  receiveCustomerHistoryResponse,
  receiveEmptyAccountDetailsResponse,
  receiveEmptySubscriptionDetailsResponse,
  receiveEmptyApplicableDiscountsDetailsResponse,
  receiveEmptyDeliveriesDetailsResponse,
  receiveEmptyDogsDetailsResponse,
  receiveEmptyCustomerHistoryResponse,
  signalPushBoxNWeeks,
  signalChangeBoxDeliveryDate,
  displayBannerMessage,
  hideBannerMessage,
  makeCalendarRequest,
  receivedCalendarResponse,
  receiveDeliveriesHistoryResponse,
  makeDeliveriesHistoryRequest
} from '../actions'

import type { Dispatch, Thunk } from 'redux'
import type {
  SearchParams,
  SearchField
} from '../actions'
import type {
  SearchResults,
  AccountDetails,
  SubscriptionDetails,
  ApplicableDiscountPartsDetails,
  DogsDetails,
  DeliveryDetails,
  CustomerHistoryEntry,
  DeliveriesHistory
} from '../message_types'

import type { DeliveryWindow } from '../../../shared/DatePicker'
import type { State } from '../index'

const getSearchResults = (dispatch: Dispatch, searchParams: SearchParams): void => {
  dispatch(makeSearchRequest(searchParams))

  const param_mapping: { [SearchField]: string } = {
    firstName: 'first_name',
    lastName: 'last_name',
    email: 'email',
    phone: 'phone',
    postcode: 'postcode',
    dogsName: 'dogs_name',
    dogsBreed: 'dogs_breed',
    rafCode: 'raf_code',
    accountStatus: 'account_status',
    shippingCountryCode: 'shipping_country_code'
  }

  // The below Flow error is a known issue:
  // - https://github.com/facebook/flow/issues/2221
  //
  // The `Object#entries` method returns an `Array<mixed` regardless of what
  // the actual types are of the elements of the array. Even though a
  // `SearchParams` has values which are all of the type `string`, Flow
  // assumes that they are of a mixed type.
  //
  // It is in the pipeline that the `Object#entries` type signature will be
  // updated to actually look at the types of the object that is being acted
  // upon in a later release of Flow but for now we must ignore this error
  const params = Object.entries(searchParams)
    // if accountStatus is not selected, ignore that field
    .filter(([k, v]: [string, mixed]): boolean => {
      return !((k === 'accountStatus' || k === 'shippingCountryCode') && v === 'not-selected')
    })
    // $FlowFixMe
    .map(([k, v]: [SearchField, string]): string => {
      return v ? `${param_mapping[k]}=${v}` : ''
    })
    .filter((el: string): string => {
      return el !== '' ? el : ''
    })

  const endpoint = '/admin/single_customer_view/search'
  const queryParams = params.join('&')
  const headers = { Accept: 'application/json' }
  fetch(`${endpoint}?${queryParams}`, { headers })
    .then((res: Response): void => {
      if (!res.ok) { throw new Error('Erroneous response') }
      res.json()
        .then((res: $ReadOnly<{| search_results: SearchResults |}>): void => {
          dispatch(receiveSearchResponse(res.search_results))
        })
    })
}

const makeNewSearch = (searchParams: SearchParams): Thunk => {
  return (dispatch: Dispatch): void => {
    dispatch(updateSelectedUserIds({ userId: -1, subscriptionId: -1 }))
    getSearchResults(dispatch, searchParams)
  }
}

export function getAccountDetails (userId: number): Thunk {
  return function (dispatch: Dispatch): void {
    dispatch(makeAccountDetailsRequest(userId))
    const endpoint = '/admin/single_customer_view/account_details'
    const queryParams = `user_id=${userId}`
    fetch(`${endpoint}?${queryParams}`, {
      headers: {
        Accept: 'application/json'
      }
    })
      .then((res: Response): void => {
        if (!res.ok) {
          res.json().then((res: $ReadOnly<{| error: string |}>): void => {
            console.log(res.error)
            dispatch(receiveEmptyAccountDetailsResponse())
          })
        } else {
          res.json()
            .then((res: $ReadOnly<{| account_details: AccountDetails |}>): void => {
              dispatch(receiveAccountDetailsResponse(res.account_details))
            })
        }
      })
  }
}

export function getSubscriptionDetails (userId: number): Thunk {
  return function (dispatch: Dispatch): void {
    dispatch(makeSubscriptionDetailsRequest())
    const endpoint = '/admin/single_customer_view/subscription_details'
    const queryParams = `user_id=${userId}`
    fetch(`${endpoint}?${queryParams}`, {
      headers: {
        Accept: 'application/json'
      }
    })
      .then((res: Response): void => {
        if (!res.ok) {
          res.json().then((res: $ReadOnly<{| error: string |}>): void => {
            console.log(res.error)
            dispatch(receiveEmptySubscriptionDetailsResponse())
          })
        } else {
          res.json()
            .then((res: $ReadOnly<{| subscription_details: SubscriptionDetails |}>): void => {
              dispatch(receiveSubscriptionDetailsResponse(res.subscription_details))
            })
        }
      })
  }
}

export function getApplicableDiscountsDetails (subscriptionId: number): Thunk {
  return function (dispatch: Dispatch): void {
    dispatch(makeApplicableDiscountsDetailsRequest())
    const endpoint = '/admin/single_customer_view/applicable_discounts_details'
    const queryParams = `subscription_id=${subscriptionId}`
    fetch(`${endpoint}?${queryParams}`, {
      headers: {
        Accept: 'application/json'
      }
    })
      .then((res: Response): void => {
        if (!res.ok) {
          res.json().then((res: $ReadOnly<{| error: string |}>): void => {
            console.log(res.error)
            dispatch(receiveEmptyApplicableDiscountsDetailsResponse())
          })
        } else {
          res.json()
            .then((res: $ReadOnly<{| applicable_discount_parts_details: ApplicableDiscountPartsDetails |}>): void => {
              dispatch(receiveApplicableDiscountsDetailsResponse(res.applicable_discount_parts_details))
            })
        }
      })
  }
}

export function getDeliveriesDetails (userId: number): Thunk {
  return function (dispatch: Dispatch): void {
    // Signal to app that request has started
    dispatch(makeDeliveriesDetailsRequest())
    const endpoint = '/admin/single_customer_view/deliveries_details'
    const queryParams = `user_id=${userId}`
    fetch(`${endpoint}?${queryParams}`, {
      headers: {
        Accept: 'application/json'
      }
    })
      .then((res: Response): void => {
        if (!res.ok) {
          res.json().then((): void => {
            dispatch(receiveEmptyDeliveriesDetailsResponse())
          })
        } else {
          res.json()
            .then((res: $ReadOnly<{| deliveries: Array<DeliveryDetails>, number_of_box_issues: number, number_of_boxes_delivered: number |}>): void => {
              dispatch(receiveDeliveriesDetailsResponse(
                res.deliveries,
                res.number_of_box_issues,
                res.number_of_boxes_delivered
              ))
            })
        }
      })
  }
}

export function getDogsDetails (userId: number): Thunk {
  return function (dispatch: Dispatch): void {
    // Signal to app that request has started
    dispatch(makeDogsDetailsRequest())
    const endpoint = '/admin/single_customer_view/dogs_details'
    const queryParams = `user_id=${userId}`
    fetch(`${endpoint}?${queryParams}`, {
      headers: {
        Accept: 'application/json'
      }
    })
      .then((res: Response): void => {
        if (!res.ok) {
          res.json().then((res: $ReadOnly<{| error: string |}>): void => {
            console.log(res.error)
            dispatch(receiveEmptyDogsDetailsResponse())
          })
        } else {
          res.json()
            .then((res: $ReadOnly<{| dogs_details: DogsDetails |}>): void => {
              const data = res.dogs_details.map((dog_details: $ElementType<DogsDetails, 0>): $ElementType<DogsDetails, 0> => {
                return Object.freeze({
                  ...dog_details,
                  summary_details: {
                    ...dog_details.summary_details,
                    date_of_birth: dog_details.summary_details.date_of_birth
                  }
                })
              })
              dispatch(receiveDogsDetailsResponse(data))
            })
        }
      })
  }
}

export function getCustomerHistoryDetails (userId: number): Thunk {
  return function (dispatch: Dispatch): void {
    dispatch(makeCustomerHistoryRequest())
    const endpoint = '/admin/single_customer_view/customer_history'
    const queryParams = `user_id=${userId}`
    fetch(`${endpoint}?${queryParams}`, {
      headers: {
        Accept: 'application/json'
      }
    })
      .then((res: Response): void => {
        if (!res.ok) {
          res.json().then((res: $ReadOnly<{| error: string |}>): void => {
            console.log(res.error)
            dispatch(receiveEmptyCustomerHistoryResponse())
          })
        } else {
          res.json()
            .then((res: $ReadOnly<{| entries: Array<CustomerHistoryEntry>, amplitude_link: string, stripe_link: string, sentry_link: string, gocardless_link: string, iterable_link: string, revolut_link: string|}>): void => {
              dispatch(receiveCustomerHistoryResponse(res.entries, res.amplitude_link, res.stripe_link, res.sentry_link, res.gocardless_link, res.iterable_link, res.revolut_link))
            })
        }
      })
  }
}

export function pushBoxNWeeks (boxId: number, nWeeks: number): Thunk {
  return function (dispatch: Dispatch, getState: () => State): void {
    dispatch(signalPushBoxNWeeks(boxId, nWeeks))
    const { csrfToken } = getState()
    const endpoint = '/admin/single_customer_view/push_box_n_weeks'
    const queryParams = `box_id=${boxId}&n_weeks=${nWeeks}`
    fetch(`${endpoint}?${queryParams}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'X-CSRF-Token': `${String(csrfToken)}`
      }
    })
      .then((res: Response): void => {
        if (!res.ok) {
          res.json().then((res: $ReadOnly<{| error: string |}>): void => {
            console.log(res.error)
            dispatch(receiveEmptyDeliveriesDetailsResponse())
          })
        } else {
          res.json()
            .then((res: $ReadOnly<{| deliveries: Array<DeliveryDetails>, number_of_box_issues: number, number_of_boxes_delivered: number |}>): void => {
              dispatch(receiveDeliveriesDetailsResponse(
                res.deliveries,
                res.number_of_box_issues,
                res.number_of_boxes_delivered
              ))
              dispatch(displayBannerMessage('Hooray! Your push was successful.'))
              setTimeout((): void => {
                dispatch(hideBannerMessage())
              }, 3000)
            })
        }
      })
  }
}

export function changeBoxDeliveryDate (boxId: number, newDate: string, adjustFutureBoxes: boolean): Thunk {
  return function (dispatch: Dispatch, getState: () => State): void {
    dispatch(signalChangeBoxDeliveryDate(boxId))
    const { csrfToken } = getState()
    const endpoint = '/admin/single_customer_view/change_box_delivery_date'
    const queryParams = `box_id=${boxId}&new_date=${newDate}&adjust_future_boxes=${adjustFutureBoxes.toString()}`
    fetch(`${endpoint}?${queryParams}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'X-CSRF-Token': `${String(csrfToken)}`
      }
    })
      .then((res: Response): void => {
        if (!res.ok) {
          res.json().then((res: $ReadOnly<{| error: string |}>): void => {
            console.log(res.error)
            dispatch(receiveEmptyDeliveriesDetailsResponse())
          })
        } else {
          res.json()
            .then((res: $ReadOnly<{| deliveries: Array<DeliveryDetails>, number_of_box_issues: number, number_of_boxes_delivered: number |}>): void => {
              dispatch(receiveDeliveriesDetailsResponse(
                res.deliveries,
                res.number_of_box_issues,
                res.number_of_boxes_delivered
              ))
            })
        }
      })
  }
}

export function fetchCalendar (date: string, direction: 'month_for' | 'month_after' | 'month_before', boxId: number): Thunk {
  return function (dispatch: Dispatch): void {
    dispatch(makeCalendarRequest())
    const endpoint = `/admin/single_customer_view/delivery_calendar/${direction}`
    const queryParams = `date=${date}&box_id=${boxId}`
    fetch(`${endpoint}?${queryParams}`, {
      headers: {
        Accept: 'application/json'
      }
    })
      .then((res: Response): void => {
        if (!res.ok) {
          res.json().then((res: $ReadOnly<{| error: string |}>): void => {
            console.log(res.error)
          })
        } else {
          res.json()
            .then((res: $ReadOnly<{| delivery_window: DeliveryWindow, previous_delivery_date: string |}>): void => {
              const selectedDate = direction === 'month_for' ? { date: '', formatted_date: '', full_date: date, day_name: '', is_deliverable: true } : { date: '', formatted_date: '', full_date: '', day_name: '', is_deliverable: false }
              dispatch(receivedCalendarResponse(res.delivery_window, res.previous_delivery_date, selectedDate))
            })
        }
      })
  }
}

const getDeliveriesHistory = (userId: number): Thunk => {
  return (dispatch: Dispatch): void => {
    dispatch(makeDeliveriesHistoryRequest())
    const endpoint = '/admin/single_customer_view/deliveries_history'
    const queryParams = `user_id=${userId}`
    const headers = { Accept: 'application/json' }
    fetch(`${endpoint}?${queryParams}`, { headers })
      .then((res: Response): void => {
        if (!res.ok) { throw new Error('Erroneous response') }
        res.json()
          .then((res: $ReadOnly<{| deliveries: DeliveriesHistory |}>): void => {
            dispatch(receiveDeliveriesHistoryResponse(res.deliveries))
          })
      })
  }
}

export {
  makeNewSearch,
  getDeliveriesHistory
}

export type {
  Dispatch,
  Thunk
}

export { default as changePlan } from './changePlan'
export { default as updateProposedPlan } from './updateProposedPlan'
export { default as switchSubscriptionToStripe } from './switchSubscriptionToStripe'
export { default as addCreditCardToSubscription } from './addCreditCardToSubscription'
export { default as sendRafDetailsEmail } from './sendRafDetailsEmail'
export { default as sendRafDetailsSms } from './sendRafDetailsSms'
export { default as getShippingDetails } from './getShippingDetails'
export { default as toggleWantsToReceiveIce } from './toggleWantsToReceiveIce'
export { getContactPreferences, updateContactPreferences } from './contact_preferences_thunks'
export { restartSubscriptionWithDate } from './restart_subscription_on_date_thunk'
export { getMarketingPreferenceData, updateMarketingPreferences } from './marketingPreferencesThunks'
