import { useQuery } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import { format } from 'date-fns'
import upperFirst from 'lodash/upperFirst'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { possessive } from '@/utils/StringHelper'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import Calendar from 'assets/images/icons/calendar--no-bg.svg'
import HorizontalSectionDivider from 'assets/images/illustrations/separator--horizontal-solid.svg'
import VerticalSectionDivider from 'assets/images/illustrations/separator--vertical-solid.svg'
import Stethoscope from 'assets/images/illustrations/stethoscope.svg'

import { CardSkeleton } from '@/components/elements/atoms/Card/CardSkeleton'
import Text from '@/components/elements/atoms/Text'
import ActionCard from '@/components/elements/molecules/ActionCard/ActionCard'

import STYLES from './AppointmentCard.module.sass'

import { APPOINTMENT_QUERY } from './queries/appointmentQuery'

import {
  AppointmentQuery,
  AppointmentQueryVariables
} from './queries/__generated__/AppointmentQuery'

type Props = {
  appointmentId: string
  calendlyUrl?: string
}

const AppointmentCard = ({
  appointmentId,
  calendlyUrl
}: Props): JSX.Element => {
  const { windowWidth } = useWindowSize()
  const { t } = useTranslation('account')
  const { data, loading, error } = useQuery<
    AppointmentQuery,
    AppointmentQueryVariables
  >(APPOINTMENT_QUERY, {
    variables: {
      appointmentId: appointmentId
    }
  })

  const onClick = useCallback(() => {
    if (calendlyUrl) {
      window.open(calendlyUrl, '_blank', 'noopener,noreferrer')
    }
  }, [calendlyUrl])

  if (error) {
    Sentry.captureException(`Error in APPOINTMENT_QUERY`, {
      extra: {
        error
      }
    })
  }

  if (loading || !data) {
    return <CardSkeleton height={'20rem'} />
  }

  const appointment = data.user.appointment
  const petCareProfessional = appointment.petCareProfessional
  const dog = appointment.dog
  const formattedDate = format(
    new Date(appointment.time),
    // eslint-disable-next-line i18next/no-literal-string
    `E do LLL 'at' h:mmbbb`
  )
  const appointmentType = appointment.appointmentProduct.appointmentType

  return (
    <ActionCard
      button={{
        onClick: onClick,
        text: {
          text: 'appointment_card.view_button',
          namespace: 'account',
          translate: true
        }
      }}
      shadow
      padding={windowWidth > BREAKPOINTS.md ? 32 : 16}
    >
      <div className={STYLES.container}>
        <div className={STYLES.header}>
          <img
            src={petCareProfessional.profilePicture}
            alt={petCareProfessional.name}
          />
          <Text
            text={'appointment_card.header_text'}
            namespace={'account'}
            variables={{
              dogName: possessive(upperFirst(dog?.name) ?? 'loading'),
              proName: petCareProfessional?.name
            }}
            variant={'display16'}
            margin={false}
          />
        </div>
        <div className={STYLES.divider}>
          {windowWidth <= BREAKPOINTS.sm ? (
            <img
              src={HorizontalSectionDivider}
              alt={t('appointment_card.section_divider_alt')}
            />
          ) : (
            <img
              src={VerticalSectionDivider}
              alt={t('appointment_card.section_divider_alt')}
            />
          )}
        </div>
        <div className={STYLES.infoSection}>
          <div className={STYLES.info}>
            <img
              src={Calendar}
              alt={t('appointment_card.calendar_alt')}
              width={21}
              height={28}
            />
            <Text text={formattedDate} translate={false} margin={false} />
          </div>
          <div className={STYLES.info}>
            <img
              src={Stethoscope}
              alt={t('appointment_card.stethoscope_alt')}
              width={21}
              height={28}
            />
            <Text
              text={`appointment_card.${appointmentType}`}
              namespace={'account'}
              margin={false}
            />
          </div>
        </div>
      </div>
    </ActionCard>
  )
}

export { AppointmentCard }
