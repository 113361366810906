// @flow

import * as React from 'react'

import AwaitingDonation from './charity_donation/AwaitingDonation'
import DonationComplete from './charity_donation/DonationComplete'

import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'
import type { Language } from '@/packs/localisation'

type Props = {|
  donate: (number, () => void) => void,
  remainingBalance: string,
  shippingCountryCode: CountryCode,
  preferredLanguage: Language
|}

type DonationState =
  | 'AWAITING_DONATION'
  | 'DONATION_COMPLETE'

type State = {|
  donationState: DonationState,
  lastDonation: number
|}

class CharityDonation extends React.Component<Props, State> {
  constructor (props: Props): void {
    super(props)
    this.state = {
      donationState: 'AWAITING_DONATION',
      lastDonation: 0
    }
  }

  donate = (donation: number): void => {
    this.props.donate(donation, (): void => {
      const newState = {
        donationState: 'DONATION_COMPLETE',
        lastDonation: donation
      }
      this.setState(newState)
    })
  }

  dismissDonationComplete = (): void => {
    this.setState({
      donationState: 'AWAITING_DONATION',
      lastDonation: 0
    })
  }

  render (): React.Node {
    const {donationState} = this.state
    switch (donationState) {
      case 'AWAITING_DONATION':
        return <AwaitingDonation donate={this.donate} shippingCountryCode={this.props.shippingCountryCode}/>

      case 'DONATION_COMPLETE':
        return (
          <DonationComplete
            shippingCountryCode={this.props.shippingCountryCode}
            preferredLanguage={this.props.preferredLanguage}
            donation={this.state.lastDonation}
            remainingBalance={this.props.remainingBalance}
            dismiss={this.dismissDonationComplete}
          />
        )

      default:
        (donationState: empty) // eslint-disable-line no-unused-expressions
        return null
    }
  }
}

export default CharityDonation
