// @noflow
import { ACCOUNT_ROUTES } from '@/routes'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { isToday } from 'date-fns'
import i18next from 'i18next'
import camelCase from 'lodash/camelCase'
import isNil from 'lodash/isNil'
import isNull from 'lodash/isNull'
import React, { useCallback, useState } from 'react'
// Routing
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

import { possessivePronoun, pronounContext } from '@/utils/StringHelper'

import useSubscriptionResume from '@/hooks/subscriptionResume/useSubscriptionResume'

import AlertCard from '@/components/elements/atoms/Alert/AlertCard'
import Card from '@/components/elements/atoms/Card/Card'
import Separator from '@/components/elements/atoms/Separator/Separator'
import Tabs, { Tab } from '@/components/elements/atoms/Tabs/Tabs'
import Text from '@/components/elements/atoms/Text/Text'
import BoxBreakdown from '@/components/elements/molecules/BoxBreakdown/BoxBreakdown'
import FeedCard from '@/components/elements/molecules/FeedCard/FeedCard'
import NotificationContainer from '@/components/elements/molecules/NotificationContainer/NotificationContainer'
import NotificationContent from '@/components/elements/molecules/NotificationContent/NotificationContent'
import { BoxPriceBreakdown } from '@/components/elements/organisms/BoxPriceBreakdown/BoxPriceBreakdown'
import HeroText from '@/components/elements/organisms/HeroText/HeroText'
import RecipeSelectionToggle from '@/components/elements/organisms/RecipeSelectionToggle/RecipeSelectionToggle'
import StickyNavigation from '@/components/elements/organisms/StickyNavigation/StickyNavigation'
import { plansPageState } from '@/components/pages/PlansPage/PlansPage'
import type {
  ReviewQuery_currentUser_User_planRecommendation_individual_dog as Dog,
  ReviewQuery_currentUser_User_subscription_meal as Meal,
  ReviewQuery_pendingSubscription_pendingSubscriptionFlavours as PendingSubscriptionFlavours,
  ReviewQuery,
  ReviewQuery_currentUser_User_subscription
} from '@/components/pages/PlansPage/__generated__/ReviewQuery'
import CancelPlanUpdateWarningModal from '@/components/pages/PlansPage/components/CancelPlanUpdateWarningModal/CancelPlanUpdateWarningModal'
import FAQSection from '@/components/pages/PlansPage/components/FAQSection/FAQSection'
import TopNavigation from '@/components/pages/PlansPage/components/TopNavigation/TopNavigation'
import { currentUserToLocaleData } from '@/components/pages/PlansPage/helpers/currentUserToLocaleData'
import { handleFetchError } from '@/components/pages/PlansPage/helpers/handleErrors'
import isAllOrMix from '@/components/pages/PlansPage/helpers/isAllOrMix'
import PlansRoutes from '@/components/pages/PlansPage/types/routes'
// eslint-disable-next-line no-restricted-imports
import { PendingSubscription } from '@/components/types'

import STYLES from './ReviewSharedPlan.module.sass'

import { SubscriptionStatus } from '@/types'

import {
  trackSharedReviewTabToggled,
  trackUpdatePlanClicked
} from '../../helpers/analytics'
import { GET_REVIEW_QUERY, UPDATE_PLAN } from '../../queries'
import { Route as PlanRoute } from '../../types/routes'

type Props = {
  variant?: keyof typeof STYLES
  namespace: string
  maxFlavours: number
}

type Recipe = {
  themeColour: string
  name: string
  numberOfPouches: number
  pouchSize: number
  thumbnail?: string
}

const getRecipesFromPayload = (
  recipes: PendingSubscriptionFlavours[] | Meal[],
  pouchSize: number
): Array<Recipe> =>
  recipes.map((recipe) => ({
    name: recipe.flavour.name,
    themeColour: recipe.flavour.themeColour,
    numberOfPouches: 'servings' in recipe ? recipe.servings : recipe.quantity,
    thumbnail: recipe.flavour.thumbnail.src,
    pouchSize
  }))

const ReviewSharedPlan = ({
  variant,
  namespace,
  maxFlavours
}: Props): JSX.Element | null => {
  const plansState: PendingSubscription = useReactiveVar(plansPageState)
  const [navigatingAway, setNavigatingAway] = React.useState<boolean>(false)
  const { plan } = plansState
  const location = useLocation().pathname as PlanRoute
  const [currentUserSubscription, setCurrentUserSubscription] =
    useState<null | ReviewQuery_currentUser_User_subscription>(null)

  const { handleResumeSubscription } = useSubscriptionResume({
    subscriptionId: currentUserSubscription?.id || ''
  })

  const copyContext = 'plan_steps.review'

  const onChangeHandler = useCallback(
    (id: string) => trackSharedReviewTabToggled(id),
    []
  )

  const manualButtonClick = useCallback(() => setNavigatingAway(true), [])

  // adjust the cache policy otherwise recipe changes will not be reflected until reload
  const { loading, data, error } = useQuery<ReviewQuery>(GET_REVIEW_QUERY(), {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'cache-first',
    onCompleted: ({ currentUser }) =>
      currentUser?.__typename === 'User' &&
      setCurrentUserSubscription(currentUser?.subscription)
  })

  const [updatePlan, { loading: loadingUpdatePlan }] = useMutation(UPDATE_PLAN)

  if (
    loading ||
    !data ||
    !data.pendingSubscription ||
    !data.pendingSubscription.pendingSubscriptionFlavours ||
    !data.pendingSubscription.standardOrderPricing ||
    !data.currentUser ||
    !data.standardDeliveryFee ||
    data.currentUser.__typename === 'PreWizardGuest' ||
    !data.currentUser.planRecommendation ||
    !data.currentUser.planRecommendation.individual ||
    !(data.currentUser.planRecommendation.individual.length > 0)
  )
    return null

  if (error) handleFetchError(error)

  const userMealBreakdown =
    data.pendingSubscription.pendingSubscriptionFlavours.reduce(
      (
        breakdown: { [key: string]: number },
        sf: PendingSubscriptionFlavours
      ) => {
        breakdown[camelCase(sf.flavour.slug)] = sf.sevenDayServings
        return breakdown
      },
      {}
    )

  const {
    pendingSubscriptionFlavours,
    plan: subscriptionPlan,
    standardOrderPricing
  } = data.pendingSubscription

  const { pouchesPerDay } = subscriptionPlan

  const { currentUser, standardDeliveryFee } = data

  const {
    planRecommendation: { individual }
  } = data.currentUser

  const { countryCode, preferredLanguage } =
    currentUserToLocaleData(currentUser)

  const userIsSubscriber = currentUser.__typename === 'User'

  const pausedUser =
    userIsSubscriber &&
    currentUser.subscription.status === SubscriptionStatus.paused

  const updatePlanAndNavigateToMyPlan = () =>
    updatePlan({
      variables: {
        userId:
          data.currentUser?.__typename !== 'PreWizardGuest'
            ? data?.currentUser?.id
            : '',
        meal: userMealBreakdown,
        planId: data.pendingSubscription.plan.id
      }
    })
      .then(async (data) => {
        if (pausedUser) await handleResumeSubscription()

        trackUpdatePlanClicked(location)

        if (data) {
          window.location.href = ACCOUNT_ROUTES.base
        }
      })
      .catch((errors) => {
        const text = Object.values(errors).includes(
          'User_exceeded_max_quantity'
        )
          ? i18next.t('wizard_flow:error_message.full_box_error')
          : i18next.t('wizard_flow: error_message.generic')
        toast.error(
          <NotificationContent
            copy={{
              translate: false,
              text: text
            }}
          />,
          { toastId: 'review-shared-plan-error-message' }
        )
      })

  const oldSubscription = userIsSubscriber ? currentUser.subscription : null
  if (isNull(oldSubscription)) return null

  const dogGenders: Dog['gender'][] = individual.map(({ dog }) => dog.gender)
  const dogPronoun = pronounContext(dogGenders, i18next.language)

  const subscriptionSurchargeTotal =
    oldSubscription.recurringOrder.surchargeTotal

  if (isNil(plan)) {
    window.location.href = '/plans/recipes'
    return null
  }

  const dailyServing = plan.pouchSize * pouchesPerDay

  const lastBoxMeals = oldSubscription.meal

  const oldPlanMeals = lastBoxMeals
    ?.map((meal) => ({
      ...meal,
      servings: meal.quantity
    }))
    .filter((item) => item.quantity > 0) // TODO: add type safety

  let oldTotalPrice = oldSubscription.planPriceTotal
  if (subscriptionSurchargeTotal > 0)
    oldTotalPrice += subscriptionSurchargeTotal

  return (
    <div className={`${STYLES.container} ${variant ? STYLES[variant] : ''}`}>
      <TopNavigation backUrl={PlansRoutes.Plan} />
      <HeroText
        variant="plans"
        namespace={namespace}
        title={`plan_steps.stage`}
        variables={{ stepCount: 3, totalSteps: 3 }}
        subtitle={`plan_steps.review.header.title_add`}
        subtitleVariables={{ context: dogPronoun }}
      />
      <div className={STYLES.wrapper}>
        <Tabs
          initial="recommended"
          // eslint-disable-next-line react/jsx-no-bind
          onChange={(tab: string) => onChangeHandler(tab)}
        >
          <Tab
            label={{
              text: `${copyContext}.plan_toggle.old_plan`,
              namespace
            }}
            id="oldPlan"
          >
            <Card shadow={false} squared>
              <div className={STYLES.cardContent}>
                <Text
                  text={`${copyContext}.plan_card.old_plan.title`}
                  namespace={namespace}
                  variant="display16"
                  colour="brandBlue500"
                />
                <BoxBreakdown
                  pouchSize={oldSubscription.plan.pouchSize}
                  pouches={oldSubscription.plan.numberOfPouches}
                  cadence={oldSubscription.plan.durationInDays / 7}
                />
                <AlertCard
                  message={{
                    text: `${copyContext}.plan_card.recommended_plan.feeds_${isAllOrMix(
                      oldSubscription.plan.typeOfPlanForCustomer
                    )}`,
                    namespace: namespace,
                    element: 'span'
                  }}
                  variant="pointerInfo"
                  pointerPosition="center"
                />
                {oldPlanMeals && (
                  <RecipeSelectionToggle
                    recipes={getRecipesFromPayload(
                      oldPlanMeals,
                      oldSubscription.plan.pouchSize
                    )}
                    dogPronoun={dogPronoun}
                    namespace={namespace}
                  />
                )}
                <Separator handdrawn />
                <section className={STYLES.priceBreakdown}>
                  <BoxPriceBreakdown
                    total={oldTotalPrice}
                    meals={[
                      {
                        total: oldSubscription.planPriceTotal,
                        perDay: oldSubscription.planPricePerDay
                      }
                    ]}
                    deliveryFee={standardDeliveryFee}
                    surchargeTotal={subscriptionSurchargeTotal}
                    shippingCountryCode={countryCode}
                    preferredLanguage={preferredLanguage}
                  />
                </section>
              </div>
            </Card>
          </Tab>
          <Tab
            label={{
              text: `${copyContext}.plan_toggle.recommended_plan`,
              namespace
            }}
            id="recommended"
          >
            <Card shadow={false} squared>
              <div className={STYLES.cardContent}>
                <Text
                  text={`${copyContext}.plan_card.recommended_plan.title`}
                  namespace={namespace}
                  variant="display16"
                  colour="brandBlue500"
                />
                <Text
                  text={`${copyContext}.plan_card.recommended_plan.subtitle`}
                  namespace={namespace}
                  variant="textRegular16"
                  colour="brandBlue500"
                />
                <section className={STYLES.breakdown}>
                  <BoxBreakdown
                    pouchSize={plan.pouchSize}
                    pouches={subscriptionPlan.numberOfPouches}
                    cadence={subscriptionPlan.durationInDays / 7}
                  />
                  <AlertCard
                    message={{
                      text: `${copyContext}.plan_card.recommended_plan.feeds_${isAllOrMix(
                        subscriptionPlan.typeOfPlanForCustomer
                      )}`,
                      namespace: namespace,
                      variables: {
                        possessivePronoun: possessivePronoun(
                          dogGenders,
                          preferredLanguage
                        )
                      },
                      element: 'span'
                    }}
                    variant="pointerInfo"
                    pointerPosition="center"
                  />
                </section>
                <section className={STYLES.dogs}>
                  {individual.map(({ dog }) => (
                    <FeedCard
                      key={dog.id}
                      dogName={dog.name}
                      dailyGrams={dog.dailyGrams}
                      dailyGramsHasChanged={!isToday(new Date(dog.createdAt))}
                      avatar={
                        dog.dogProfile && !isNull(dog.dogProfile.avatarUrl)
                          ? dog.dogProfile.avatarUrl
                          : undefined
                      }
                    />
                  ))}
                </section>
                <RecipeSelectionToggle
                  recipes={getRecipesFromPayload(
                    pendingSubscriptionFlavours,
                    dailyServing
                  )}
                  dogPronoun={dogPronoun}
                  namespace={namespace}
                />
                <section className={STYLES.alert}>
                  <AlertCard
                    message={{
                      variant: 'textRegular14',
                      text: `${copyContext}.plan_card.recommended_plan.alert`,
                      namespace: namespace,
                      margin: false,
                      element: 'span',
                      align: 'left'
                    }}
                    variant="info"
                  />
                </section>
                <Separator handdrawn />
                <section className={STYLES.priceBreakdown}>
                  <BoxPriceBreakdown
                    total={standardOrderPricing.netTotalPrice}
                    meals={[
                      {
                        total: standardOrderPricing.grossCoreFoodPrice,
                        perDay: standardOrderPricing.grossCoreFoodPricePerDay
                      }
                    ]}
                    deliveryFee={standardOrderPricing.netDeliverySurchargePrice}
                    surchargeTotal={
                      standardOrderPricing.netRecipeSurchargePrice
                    }
                    shippingCountryCode={countryCode}
                    preferredLanguage={preferredLanguage}
                  />
                </section>
              </div>
            </Card>
          </Tab>
        </Tabs>
      </div>
      <FAQSection
        namespace={namespace}
        numberOfDogs={individual.length}
        maxRecipes={maxFlavours}
        shippingCountryCode={countryCode}
      />
      <StickyNavigation
        variant={'twoButtons'}
        disabled={loadingUpdatePlan}
        buttonOne={{
          variant: 'secondary',
          text: 'plan_steps.navigation.manage_plan',
          icon: null,
          iconColour: 'brandRed400',
          namespace,
          onClick: manualButtonClick
        }}
        buttonTwo={{
          variant: 'primary',
          text: pausedUser
            ? 'plan_steps.navigation.update_and_reactive_plan'
            : 'plan_steps.navigation.update_plan',
          iconColour: 'brandWhite',
          onClick: updatePlanAndNavigateToMyPlan,
          namespace
        }}
      />
      <NotificationContainer autoClose={5000} />
      <CancelPlanUpdateWarningModal
        open={navigatingAway}
        toggle={setNavigatingAway}
      />
    </div>
  )
}

export { Props }
export default ReviewSharedPlan
