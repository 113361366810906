// @noflow
import * as Sentry from '@/utils/sentry'

import type { SectionName } from '../types'

// TODO: Placeholder for now
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AppState = any

const sectionList = (state: AppState): Array<SectionName> => {
  if (state.paymentSectionReducer.requiresPayment) {
    return ['account', 'delivery-address', 'delivery-date', 'payment']
  }
  return ['account', 'delivery-address', 'delivery-date']
}

const sectionIndex = (
  sectionName: SectionName,
  sections: Array<SectionName>
): number => {
  const index = sections.indexOf(sectionName)
  if (index === -1) {
    Sentry.captureException(`Unknown section name`, {
      extra: {
        sectionName
      },
      tags: {
        product: Sentry.Product.Checkout
      }
    })
  }
  return index
}

const getSection = (
  state: AppState,
  sectionName: SectionName
): { validity: boolean; visible: boolean } => {
  switch (sectionName) {
    case 'account': {
      return state.accountSectionReducer
    }
    case 'delivery-date': {
      return state.deliveryDateSectionReducer
    }
    case 'delivery-address': {
      return state.deliveryAddressSectionReducer
    }
    case 'payment': {
      return state.paymentSectionReducer
    }
  }
}

const sectionClassName = (sectionName: SectionName): string => {
  switch (sectionName) {
    case 'account': {
      return 'account-section'
    }
    case 'delivery-date': {
      return 'delivery-date-section'
    }
    case 'delivery-address': {
      return 'delivery-address-section'
    }
    case 'payment': {
      return 'payment-section'
    }
  }
}

const nextSection = (state: AppState): SectionName | 'buy-subscription' =>
  sectionList(state).find(
    (sectionName: SectionName): boolean =>
      !getSection(state, sectionName).visible
  ) || 'buy-subscription'

const currentSection = (state: AppState): SectionName => {
  const next = nextSection(state)
  const sections = sectionList(state)
  if (next === 'buy-subscription') {
    return sections[sections.length - 1]
  }
  return sections[sectionIndex(next, sections) - 1]
}

const currentSectionIndex = (state: AppState): number => {
  return sectionIndex(currentSection(state), sectionList(state))
}

const firstSectionWithErrors = (state: AppState): SectionName | null =>
  sectionList(state).find(
    (sectionName: SectionName): boolean =>
      !getSection(state, sectionName).validity
  ) || null

export {
  nextSection,
  currentSection,
  firstSectionWithErrors,
  sectionIndex,
  sectionClassName,
  sectionList,
  currentSectionIndex
}

export type { SectionName }
