// @noflow
import React from 'react'
import type { ReactElement } from 'react'

import ProductCard, { CloudinaryAsset } from './ProductCard'
import ProductModal from './ProductModal'
import type { CloudinaryOtherAsset } from './ProductModal'

type Flavour = string

type Sticker = {
  image: {
    src: string | null
  }
}

type Benefit = {
  name: string
  url: string | null
}

type Constituent = {
  name: string
  percentage: string
}

type ProductProps = {
  className: string
  primaryImage: CloudinaryAsset
  otherImages: Array<CloudinaryOtherAsset>
  title: string
  shortDescription: string
  description: string
  sticker?: Sticker
  benefits: Array<Benefit>
  composition?: string
  constituents?: Array<Constituent>
  additives?: string
  storage?: string
  ingredients?: {
    composition: string
    analyticalConstituents: Array<string>
  }
  tastingNotes?: string
  name?: Flavour
  displayCtaButton?: boolean
  labels?: Array<{ name: string; colour: string }>
  shopProductName?: string
}

type PresentationalProps = {
  signedIn: boolean
  recipe?: string | null
  isExtras?: boolean
}

type Props = ProductProps & PresentationalProps

const ProductCardAndModal = ({
  shopProductName,
  className,
  primaryImage,
  otherImages,
  title,
  description,
  sticker,
  benefits,
  composition,
  constituents,
  additives,
  storage,
  ingredients,
  shortDescription,
  tastingNotes,
  signedIn,
  recipe,
  name,
  displayCtaButton,
  labels,
  isExtras
}: Props): ReactElement => {
  const shouldOpenRecipeModal =
    recipe !== undefined && name !== undefined && recipe === name
  const [isModalOpen, setModalOpen] = React.useState(shouldOpenRecipeModal)
  const [isModalMounted, setModalMounted] = React.useState(
    shouldOpenRecipeModal
  )
  const onclick = React.useCallback((): void => {
    setModalMounted(true)
    setModalOpen(true)
  }, [])
  const closeAndUnmountModal = React.useCallback((): void => {
    setModalOpen(false)
    setTimeout((): void => {
      setModalMounted(false)
    }, 300)
  }, [])

  return (
    <>
      <ProductCard
        className={className}
        sticker={sticker}
        image={primaryImage}
        title={title}
        description={shortDescription}
        benefits={benefits}
        onClick={onclick}
        labels={labels}
      />
      {isModalMounted && (
        <ProductModal
          shopProductName={shopProductName}
          className={className}
          images={otherImages}
          title={title}
          description={description}
          benefits={benefits}
          composition={composition}
          constituents={constituents}
          ingredients={ingredients}
          additives={additives}
          storage={storage}
          isOpen={isModalOpen}
          closeAndUnmount={closeAndUnmountModal}
          tastingNotes={tastingNotes}
          signedIn={signedIn}
          displayCtaButton={displayCtaButton}
          isExtras={isExtras}
        />
      )}
    </>
  )
}

export default ProductCardAndModal
export type { ProductProps, Constituent, Flavour }
