import { Language } from '@/packs/localisation'
import { useReactiveVar } from '@apollo/client'
import React, { useEffect, useMemo, useState } from 'react'
import { Navigate } from 'react-router'

import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { formatCurrencyWithDecimal } from '@/utils/currency'

import DogCelebrating from 'assets/images/illustrations/dogs/dog-celebrating.svg'

import Text from '@/components/elements/atoms/Text/Text'

import STYLES from '../ResolutionConfirmation.module.sass'

import { Code } from '@/types'

import { customerIssueManagementState } from '../../../CustomerIssueManagementPage'
import useCustomerIssueManagementTracking from '../../../analytics'
import CustomerIssueManagementRoutes from '../../../types/routes'
import ResolutionConfirmationBox from './ResolutionConfirmationBox'

type Props = {
  discountAmount: number
  shippingCountryCode: Code
  preferredLanguage: Language
}

const ApplyDiscount = ({
  discountAmount,
  shippingCountryCode,
  preferredLanguage
}: Props): JSX.Element => {
  const customerIssueManagementData = useReactiveVar(
    customerIssueManagementState
  )
  const customerIssueManagementTracking = useCustomerIssueManagementTracking()
  const [initialised, setInitialised] = useState(false)

  useEffect(() => {
    if (!initialised) {
      customerIssueManagementTracking.stepLoaded(
        'Discount confirmation',
        'Resolution confirmation'
      )
      setInitialised(true)
    }
  }, [
    initialised,
    customerIssueManagementData,
    customerIssueManagementTracking
  ])

  const discountValue = useMemo(() => {
    const { locale, currency } = countryCodeToLocaleCurrency(
      shippingCountryCode,
      preferredLanguage
    )
    return formatCurrencyWithDecimal(discountAmount, { locale, currency })
  }, [discountAmount, shippingCountryCode, preferredLanguage])

  if (!discountAmount) {
    return <Navigate to={CustomerIssueManagementRoutes.ContactCustomerLove} />
  }
  return (
    <>
      <div className={STYLES.imgContainer}>
        <img alt="" src={DogCelebrating} />
      </div>
      <div className={STYLES.discount}>
        <Text
          namespace={customerIssueManagementData.namespace}
          text={'resolutionConfirmation.discount.text1'}
          variant={'textRegular16'}
          colour={'brandBlue500'}
          margin={false}
        />
        {!!discountValue && (
          <ResolutionConfirmationBox
            asset={'boxClosed'}
            assetSize={20}
            textProps={{
              namespace: customerIssueManagementData.namespace,
              text: discountValue,
              colour: 'brandBlue500',
              translate: false,
              variant: 'textRegular18',
              margin: false
            }}
          />
        )}
        <Text
          namespace={customerIssueManagementData.namespace}
          text={'resolutionConfirmation.discount.text2'}
          variant={'textRegular16'}
          colour={'brandBlue500'}
          margin={false}
        />
      </div>
    </>
  )
}

export { Props }
export default ApplyDiscount
