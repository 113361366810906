// @noflow
import React, { useCallback } from 'react'
import type { ReactElement } from 'react'

import type { Locale } from '@/utils/countryCodeHelper'
import { formatCurrencyWithDecimal } from '@/utils/currency'

import ProductQuantityLarge from '@/components/elements/molecules/ProductQuantityLarge/ProductQuantityLarge'

import type { BasketPageQuery_user_basket_basketProducts as BasketProduct } from '../../queries/__generated__/BasketPageQuery'
import type { Currency } from '@/shared_types/rails_models/shipping_countries'

type Props = {
  basketProduct: BasketProduct
  locale: Locale
  currency: Currency
  basketProductsUpdateLoading: boolean
  handleRemoveBasketProduct: (
    productId: BasketProduct['productVariant']['id']
  ) => Promise<void>
}

const BasketProductCard = ({
  basketProduct,
  locale,
  currency,
  basketProductsUpdateLoading,
  handleRemoveBasketProduct
}: Props): ReactElement | null => {
  const {
    discountedPrice,
    grossPrice,
    quantity,
    productVariant: {
      id: productVariantId,
      name: sizeName,
      productCollection: {
        name: productName,
        thumbnail: { src }
      }
    }
  } = basketProduct

  const productDiscountedPrice = formatCurrencyWithDecimal(
    discountedPrice * quantity,
    {
      locale,
      currency
    }
  )

  const productGrossPrice = formatCurrencyWithDecimal(grossPrice * quantity, {
    locale,
    currency
  })

  const amountDiscounted = grossPrice - discountedPrice
  const discountedPriceToShow =
    amountDiscounted > 0
      ? formatCurrencyWithDecimal(amountDiscounted, {
          locale,
          currency
        })
      : null

  // Handlers
  const removeBasketProduct = useCallback(() => {
    handleRemoveBasketProduct(productVariantId)
  }, [handleRemoveBasketProduct, productVariantId])

  return (
    <ProductQuantityLarge
      image={src}
      alt={productName}
      quantity={quantity}
      price={productDiscountedPrice}
      grossPrice={productGrossPrice}
      discount={discountedPriceToShow}
      title={{
        text: productName,
        translate: false
      }}
      size={{
        text: sizeName,
        translate: false
      }}
      onProductRemove={removeBasketProduct}
      onProductRemoveDisabled={basketProductsUpdateLoading}
    />
  )
}

export default BasketProductCard
