// @flow

import { connect } from 'react-redux'
import { getDogsDetails } from '../../thunks'
import { toggleDogDetails, toggleDogOverview } from '../../actions'

import type { State } from '../../index'
import type { DogsDetails } from '../../message_types'
import type { Dispatch } from 'redux'

import DogsDetailsComponent from '../../components/user_details/DogsDetailsComponent'

type PresentationalProps = $ReadOnly<{|
    dogsDetails: DogsDetails,
    isFetching: boolean,
    userId: number
|}>

type ActionProps = $ReadOnly<{|
  dispatchGetDogsDetails: ((number) => void),
  dispatchToggleDogDetails: ((number) => void),
  dispatchToggleDogOverview: ((number, 'overview' | 'description') => void)
|}>

export type ComponentProps =
  & PresentationalProps
  & ActionProps

function mapStateToProps (state: State): PresentationalProps {
  const { dogsDetails, fetchingStatuses, selectedUserIds } = state
  const { userId } = selectedUserIds
  return { dogsDetails, isFetching: fetchingStatuses.dogsDetails, userId }
}

function mapDispatchToProps (dispatch: Dispatch): ActionProps {
  const dispatchGetDogsDetails = (userId: number): void => {
    dispatch(getDogsDetails(userId))
  }
  const dispatchToggleDogDetails = (dogIndex: number): void => {
    dispatch(toggleDogDetails(dogIndex))
  }
  const dispatchToggleDogOverview = (dogIndex: number, panelInView: 'overview' | 'description'): void => {
    dispatch(toggleDogOverview(dogIndex, panelInView))
  }
  return { dispatchGetDogsDetails, dispatchToggleDogDetails, dispatchToggleDogOverview }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DogsDetailsComponent)
