import classnames from 'classnames'
import isUndefined from 'lodash/isUndefined'
import React, { useEffect, useState } from 'react'

// Assets
import SuccessIcon from 'assets/images/icons/checkmarks/green-checkmark.svg'

import Image, {
  Props as ImageProps
} from '@/components/elements/atoms/Image/Image'
import Text, { Props as TextProps } from '@/components/elements/atoms/Text/Text'

// Styles
import STYLES from './successoverlay.module.sass'

type Props = {
  typography?: TextProps
  illustration?: ImageProps
  footnote?: TextProps
  sprite?: string
  imageAlt?: string
  children?: JSX.Element
  handleClose?: () => void
  dataTestId?: string
}

const SuccessOverlay = ({
  typography,
  illustration,
  footnote,
  sprite,
  imageAlt = '',
  children,
  handleClose,
  dataTestId
}: Props): JSX.Element => {
  const [fade, setFade] = useState(false)

  useEffect(() => {
    const fadeOutOverlay = setTimeout(() => {
      handleClose && handleClose()
      setFade(true)
    }, 3500)

    return () => {
      clearTimeout(fadeOutOverlay)
    }
  }, [])

  const overlayClassName = classnames(STYLES.overlayWrapper, {
    [STYLES.hidden]: fade
  })
  return (
    <div className={overlayClassName} data-testid={dataTestId}>
      <div className={STYLES.successCheckmark}>
        <img src={SuccessIcon} alt="Green checkmark icon" />
      </div>
      {children}
      {!isUndefined(illustration) && <Image {...illustration} />}
      {!isUndefined(sprite) && !isUndefined(imageAlt) && (
        <div className={STYLES.spriteContainer}>
          <img alt={imageAlt} src={sprite} />
        </div>
      )}
      {typography && (
        <Text
          {...typography}
          text={typography.text}
          variant={'display28'}
          align="center"
          margin={false}
        />
      )}
      {footnote && (
        <Text
          {...footnote}
          text={footnote.text}
          variant={'textRegular14'}
          align="center"
          margin={false}
        />
      )}
    </div>
  )
}

export { Props }
export default SuccessOverlay
