import {
  IdealBankElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js'
import i18next from 'i18next'
import React from 'react'

import * as Sentry from '@/utils/sentry'
import { isInAppBrowser } from '@/utils/userAgent'

import { IntentType } from '@/types'

import * as ANALYTICS from '../../../Analytics/CheckoutAnalytics'
import { checkoutPageState } from '../../../CheckoutPage'
import { ActivePaymentView } from '../../../types'
import { bannerMessageState } from '../Banner'
import { setSubmissionState } from '../PaymentSection'
import buySubscriptionWithSepaDirectDebit from '../helpers/buySubscriptionWithSepaDirectDebit'
import getIdealPaymentAuthorisation from '../helpers/getIdealPaymentAuthorisation'

type UseBuySubscriptionWithIdeal = {
  iDealSubscriptionCallback: () => void
  idealButtonClicked: () => void
  idealAuthReceived: boolean
}

const useBuySubscriptionWithIdeal = ({
  csrfToken,
  setActivePaymentView
}: {
  csrfToken: string
  setActivePaymentView: (view: ActivePaymentView) => void
}): UseBuySubscriptionWithIdeal => {
  const elements = useElements()
  const stripe = useStripe()
  const { sections } = checkoutPageState()
  const { paymentDetails } = sections
  const { form } = paymentDetails
  const { selectedPaymentMethod } = form

  const copyContext = 'checkout:payment_section.ideal_error'

  const [idealAuthReceived, setIdealAuthReceived] = React.useState(false)

  const iDealSubscriptionCallback = React.useCallback(() => {
    const idealBank = elements?.getElement(IdealBankElement)
    const name = sections.accountDetails.form.name.value

    if (!stripe)
      throw new Error(
        'Cannot find stripe when buySubscriptionWithSepaDirectDebit is called'
      )
    if (!idealBank) {
      Sentry.captureException('Could not find ideal card element at checkout', {
        tags: {
          product: Sentry.Product.Checkout
        }
      })
      return
    }

    setSubmissionState({ type: 'loading' })

    // Reset the error banner if it's showing
    bannerMessageState({ message: null, type: 'error' })

    // If a mobile iDEAL user, show an alert asking them to return to the site after authorisation
    return getIdealPaymentAuthorisation({ stripe, name, idealBank, csrfToken })
  }, [csrfToken, elements, sections.accountDetails.form.name.value, stripe])

  const idealButtonClicked = React.useCallback(() => {
    ANALYTICS.idealButtonClicked(isInAppBrowser(navigator.userAgent))
    setActivePaymentView('iDeal')

    selectedPaymentMethod.type = 'iDeal'

    return checkoutPageState({
      ...checkoutPageState(),
      sections
    })
  }, [sections, selectedPaymentMethod, setActivePaymentView])

  React.useEffect(() => {
    if (
      window.location.search.includes('redirect_status') &&
      stripe &&
      selectedPaymentMethod.type === 'iDeal'
    ) {
      if (window.location.search.includes('redirect_status=succeeded')) {
        const urlParams = new URLSearchParams(window.location.search)
        const paymentIntentSecret = urlParams.get(
          'payment_intent_client_secret'
        )
        const setupIntentSecret = urlParams.get('setup_intent_client_secret')

        const intentSecret = paymentIntentSecret || setupIntentSecret
        const intentType = paymentIntentSecret
          ? IntentType.payment_intent
          : IntentType.setup_intent

        if (intentSecret) {
          setIdealAuthReceived(true)
          buySubscriptionWithSepaDirectDebit({
            intentSecret: intentSecret,
            csrfToken,
            paymentMethod: 'iDeal',
            intentType: intentType
          })
        } else {
          Sentry.captureException(
            'Ideal payment failed, intentSecret is undefined',
            {
              tags: {
                product: Sentry.Product.Checkout
              }
            }
          )
          setSubmissionState({
            type: 'error',
            error: i18next.t(copyContext)
          })
        }
      } else {
        const urlParams = new URLSearchParams(window.location.search)
        const status = urlParams.get('redirect_status')
        Sentry.captureException(`Ideal Auth Redirect Status`, {
          extra: {
            status
          },
          tags: {
            product: Sentry.Product.Checkout
          }
        })
        setIdealAuthReceived(false)

        // Remove URL params after failed authentication
        window.history.replaceState(
          {},
          document.title,
          location.href.split('?')[0]
        )

        bannerMessageState({
          message: i18next.t(copyContext),
          type: 'error'
        })

        setSubmissionState({
          type: 'error',
          error: i18next.t(copyContext)
        })
      }
    }
  }, [csrfToken, sections, selectedPaymentMethod.type, stripe])

  return {
    iDealSubscriptionCallback,
    idealButtonClicked,
    idealAuthReceived
  }
}

export default useBuySubscriptionWithIdeal
