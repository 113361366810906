// @noflow
import React, { useCallback } from 'react'
import type { ComponentProps } from 'react'

import Modal from '@/components/elements/atoms/Modal/Modal'
import Stories from '@/components/elements/organisms/Stories'

type StoriesProps = ComponentProps<typeof Stories>

type Props = {
  isModalOpen: boolean
  storiesProps: StoriesProps
  setIsModalOpen: (isModalOpen: Props['isModalOpen']) => void
}

const StoriesModal = ({
  isModalOpen,
  setIsModalOpen,
  storiesProps
}: Props): JSX.Element => {
  const { onAllStoriesEnd } = storiesProps

  // Handlers
  const handleToggleIsModalOpen = useCallback(() => {
    setIsModalOpen(!isModalOpen)
  }, [isModalOpen, setIsModalOpen])

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
  }, [setIsModalOpen])

  const handleOnAllStoriesEnd = useCallback(() => {
    if (onAllStoriesEnd) {
      onAllStoriesEnd()
    }

    handleCloseModal()
  }, [onAllStoriesEnd, handleCloseModal])

  return (
    <Modal
      isModalOpen={isModalOpen}
      setOpenModal={handleToggleIsModalOpen}
      width={450}
      showCloseButton={false}
      padding={false}
      fullHeight
      fadeAnimation
    >
      <Stories
        {...storiesProps}
        setIsModalOpen={setIsModalOpen}
        onAllStoriesEnd={handleOnAllStoriesEnd}
      />
    </Modal>
  )
}

export type { Props }

export default StoriesModal
