import { gql } from '@apollo/client'

const PRICE_FRAGMENT = gql`
  fragment PriceFragment on ProductVariant {
    id
    grossPrice
    productVariantDeliveryTypes {
      deliveryType
      adjustedGrossPrice
      netPrice
      boostedNetPrice
    }
  }
`

export { PRICE_FRAGMENT }
