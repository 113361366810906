// @flow

import * as React from 'react'

import HappyGreenDog from '../../../../../../assets/images/illustrations/dogs/happy-green-dog.svg'
import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { formatCurrencyWithDecimal } from '@/utils/currency'
import i18next from 'i18next'

import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'
import type { Language } from '@/packs/localisation'

type Props = {|
  donation: number,
  remainingBalance: string,
  dismiss: () => void,
  shippingCountryCode: CountryCode,
  preferredLanguage: Language
|}

class DonationComplete extends React.Component<Props> {
  namespace = 'ambassadors:dashboard.balance_tab'

  render (): React.Node {
    const { locale, currency } = countryCodeToLocaleCurrency(this.props.shippingCountryCode, this.props.preferredLanguage)
    return (
      <div className='ambassador-balance__card'>
        <div className='ambassador-balance__card__header'>
          <h2>{ i18next.t(`${this.namespace}.thanks_for_donation`) }</h2>
          <img
            src={HappyGreenDog}
            alt='An illustration of a happy dog'
          />
          <p>{ i18next.t(`${this.namespace}.thanks_for_donation`, { amount: formatCurrencyWithDecimal(this.props.donation, { locale: locale, currency: currency }) } ) }</p>

          <p className='border--green'>{ i18next.t(`${this.namespace}.remaining_balance`, { remainingBalance: this.props.remainingBalance }) }</p>

          <button
            className='btn btn-updated--blue'
            onClick={this.props.dismiss}
          >
            { i18next.t(`${this.namespace}.amazing`) }
          </button>
        </div>
      </div>
    )
  }
}

export default DonationComplete
