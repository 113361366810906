import React, { useEffect, useState } from 'react'

import {
  CustomerIssueReportCreate_response_resolutionOffering_details_CustomerIssueManagementResolutionDetailsHelpCentreArticle as HelpCentreArticleDetails,
  CustomerIssueReportCreate_response_resolutionOffering as ResolutionOffering,
  CustomerIssueReportCreate_response_resolutionOffering_details_CustomerIssueManagementResolutionDetailsTrackOrder as TrackOrderDetails
} from '../../../mutations/__generated__/CustomerIssueReportCreate'

import ContactCustomerLoveDetails from '../../ContactCustomerLove/ContactCustomerLoveDetails'
import HelpCentreArticle from './HelpCentreArticle'
import TrackOrder from './TrackOrder'

type Props = {
  resolutionOffering: ResolutionOffering
}

const QuickHelpTypeSwitch = ({ resolutionOffering }: Props): JSX.Element => {
  const [initialised, setInitialised] = useState(false)

  useEffect(() => {
    if (!initialised) {
      setInitialised(true)
    }
  }, [initialised])

  switch (resolutionOffering.resolutionType) {
    case 'track_order':
      return (
        <TrackOrder details={resolutionOffering.details as TrackOrderDetails} />
      )
    case 'help_centre_article':
      return (
        <HelpCentreArticle
          details={resolutionOffering.details as HelpCentreArticleDetails}
        />
      )
    default:
      return <ContactCustomerLoveDetails />
  }
}

export { Props }
export default QuickHelpTypeSwitch
