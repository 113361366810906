import { ACCOUNT_ROUTES } from '@/routes'
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar
} from '@apollo/client'
import Grid from '@material-ui/core/Grid'
import { addDays, subDays } from 'date-fns'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { featureFlagsDataVar, userDataVar } from '@/services/apollo'

import {
  capitaliseFirstLetter,
  possessive,
  toLocalisedSentence
} from '@/utils/StringHelper'
import cookies from '@/utils/cookies'
import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { formatCurrencyWithDecimal } from '@/utils/currency'
import { getTimezoneOffset } from '@/utils/getTimezoneOffset'
import { percentageValue } from '@/utils/percentage'
import * as Sentry from '@/utils/sentry'

import useLocalStorage from '@/hooks/useLocalStorage'
import useWindowSize from '@/hooks/useWindowSize'

import segmentTrack from '@/components/analytics/Analytics'
import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'
import FlatButton from '@/components/elements/atoms/FlatButton/FlatButton'
import Image from '@/components/elements/atoms/Image/Image'
import Link from '@/components/elements/atoms/Link/Link'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import SkeletonImage from '@/components/elements/atoms/SkeletonImage/SkeletonImage'
import Text from '@/components/elements/atoms/Text/Text'
import BoxBreakdown from '@/components/elements/molecules/BoxBreakdown/BoxBreakdown'
import Countdown from '@/components/elements/molecules/Countdown/Countdown'
import NotificationContainer from '@/components/elements/molecules/NotificationContainer/NotificationContainer'
import NotificationContent from '@/components/elements/molecules/NotificationContent/NotificationContent'
import ProductThumb from '@/components/elements/molecules/ProductThumb/ProductThumb'
import SwiperSlider from '@/components/elements/molecules/SwiperSlider/SwiperSlider'
import { ErrorState } from '@/components/elements/organisms/ErrorState'
import LoadingScreen from '@/components/elements/organisms/LoadingScreen/LoadingScreen'
import { BOX_DELIVERY_DATE_UPDATE } from '@/components/pages/OrderPage/queries'
import {
  SUBSCRIPTION_RESUME_MUTATION,
  TREATMENTS_PAUSED_PAGE_FUTURE_BOXES_QUERY,
  TREATMENTS_PAUSED_PAGE_QUERY
} from '@/components/pages/TreatmentsPausedPage/queries/queries'
import { BREAKPOINTS } from '@/components/templates/Base'

import STYLES from './DogsHero.module.sass'

import {
  TreatmentsPausedFutureBoxesQuery_user_subscription_previewUncreatedFutureBox as FutureBox,
  TreatmentsPausedFutureBoxesQuery
} from '../../queries/__generated__/TreatmentsPausedFutureBoxesQuery'
import {
  TreatmentsPausedPageQuery_user_dogs as Dog,
  TreatmentsPausedPageQuery_user_subscription_meal as Meal,
  TreatmentsPausedPageQuery_user
} from '../../queries/__generated__/TreatmentsPausedPageQuery'

import { PausedDatePicker } from '../PausedDatePicker/PausedDatePicker'

type PauseDate = {
  user: TreatmentsPausedPageQuery_user
  pausedDeliveryDate?: Date | null
  setPausedDeliveryDate: (selectedDate: Date) => void
  loading: boolean
}

const hasReturnedFromBrigthback = (referrer: string): boolean => {
  if (referrer === '') return false
  return referrer.includes('pause.butternutbox')
}

const NOW = new Date()
const namespace = 'treatments_paused_page'

const PausedDate = ({
  loading,
  pausedDeliveryDate,
  setPausedDeliveryDate,
  user
}: PauseDate): JSX.Element => {
  if (loading || !pausedDeliveryDate) {
    return <SkeletonImage width="100%" height={100} />
  }

  return (
    <PausedDatePicker
      user={user}
      pausedDeliveryDate={pausedDeliveryDate}
      setPausedDeliveryDate={setPausedDeliveryDate}
    />
  )
}

const DogsHero = (): JSX.Element => {
  const { windowWidth } = useWindowSize()
  const copyContext = 'home.box_details'
  const copyContextCountdown = 'next_day_delivery_countdown'
  const { t } = useTranslation(namespace)
  const featureFlagsData = useReactiveVar(featureFlagsDataVar)
  const userData = useReactiveVar(userDataVar)

  const [resumeLoading, setResumeLoading] = useState(false)
  const [pausedDeliveryDate, setPausedDeliveryDate] = useState<Date>()
  const [restartBoxDate] = useLocalStorage<string, Date | undefined>(
    cookies.restartBoxSelectedDate,
    pausedDeliveryDate
  )
  const [uncreatedBox, setUncreatedBox] = useState<FutureBox | null>(null)

  const { data, error, loading } = useQuery(TREATMENTS_PAUSED_PAGE_QUERY, {
    variables: {
      from: subDays(NOW, 3),
      to: addDays(NOW, 60)
    },
    onError: (error) =>
      Sentry.captureException(
        'Error occurred in TREATMENTS_PAUSED_PAGE_QUERY',
        {
          extra: { error },
          tags: {
            product: Sentry.Product.Account,
            team: Sentry.Team.Retention
          }
        }
      )
  })

  const [getFutureBoxes] = useLazyQuery<TreatmentsPausedFutureBoxesQuery>(
    TREATMENTS_PAUSED_PAGE_FUTURE_BOXES_QUERY,
    {
      onCompleted(futureBoxes) {
        setUncreatedBox(futureBoxes.user.subscription.previewUncreatedFutureBox)
      }
    }
  )

  useEffect((): void => {
    // Only retrieve `previewUncreatedFutureBox` if the user has a deliverable address
    if (userData?.address?.deliverable) getFutureBoxes()
  }, [getFutureBoxes, loading, userData])

  const [subscriptionResume] = useMutation(SUBSCRIPTION_RESUME_MUTATION, {
    variables: {
      numOfBoxes: 3
    }
  })

  const [boxDeliveryDateUpdate] = useMutation(BOX_DELIVERY_DATE_UPDATE)

  const allowEditAndReviewPlanBeforeReactivation =
    featureFlagsData?.allowEditAndReviewPlanBeforeReactivation === 'variant'

  const navigate = useNavigate()
  const gotToPlanManagement = useCallback(
    () => navigate(ACCOUNT_ROUTES.planManagement),
    [navigate]
  )

  const redeemCampaignDiscount = useCallback((): void => {
    // eslint-disable-next-line i18next/no-literal-string
    window.location.href = `/reactivation_discount_redemptions/${data?.user?.subscription.defaultReactivationCampaign?.name}`
  }, [data?.user?.subscription.defaultReactivationCampaign?.name])

  const editRecipes = useCallback(
    () => navigate(ACCOUNT_ROUTES.editRecipes),
    [navigate]
  )

  // Force reload when redirected directly from Brightback
  useEffect(() => {
    if (hasReturnedFromBrigthback(document.referrer)) window.location.reload()
  }, [])

  const triggerConfirmation = useCallback(() => {
    setTimeout((): void => {
      window.location.href = '/account/restart-plan-success'
    }, 500)
  }, [])

  const triggerError = useCallback((error) => {
    Sentry.captureException(
      'Could not resume subscription from SUBSCRIPTION_RESUME_MUTATION',
      {
        extra: { error },
        tags: {
          product: Sentry.Product.Account,
          team: Sentry.Team.Retention
        }
      }
    )
    toast.error(
      <NotificationContent
        copy={{
          text: 'notification.resume_failed',
          namespace: 'limited_offer_paused_page'
        }}
      />,
      { toastId: 'resume-error-message' }
    )
  }, [])

  useEffect((): void => {
    if (!data) return

    const box = uncreatedBox
    const date = !isUndefined(restartBoxDate)
      ? new Date(restartBoxDate)
      : box?.isoDeliveryDate
      ? new Date(box?.isoDeliveryDate)
      : NOW

    setPausedDeliveryDate(date)
  }, [data, restartBoxDate, uncreatedBox])

  const resumePlanClicked = useCallback(async (): Promise<void> => {
    segmentTrack('Treatments Paused Dashboard - Resume Plan CTA clicked')
    setResumeLoading(true)
    try {
      const { data: resumedData } = await subscriptionResume()

      const { subscription } = resumedData.subscriptionResume

      const [nextBox] = subscription.nextNBoxes

      if (!pausedDeliveryDate) {
        Sentry.captureException(
          `Treatments Paused Date | unable to set date after subscription resumed`,
          {
            tags: {
              product: Sentry.Product.Account,
              team: Sentry.Team.Retention
            }
          }
        )
      } else {
        await boxDeliveryDateUpdate({
          variables: {
            userId: data.user.id,
            boxId: nextBox.id,
            selectedDeliveryDate: getTimezoneOffset(pausedDeliveryDate),
            adjustFutureBoxDates: true,
            triggerEvents: true
          }
        })
      }

      triggerConfirmation()
    } catch (error) {
      triggerError(error)
    } finally {
      setResumeLoading(false)
    }
  }, [
    subscriptionResume,
    triggerConfirmation,
    boxDeliveryDateUpdate,
    data?.user.id,
    pausedDeliveryDate,
    triggerError
  ])

  const handleOpenDixa = useCallback(() => {
    if (window && window._dixa_) {
      window._dixa_.invoke('setWidgetOpen', true)
    } else {
      window.location.href = '/contact'
    }
  }, [])

  if (error) {
    return (
      <ErrorState
        error={{
          name: 'Error returning paused dashboard',
          message: error.message,
          apollo: error
        }}
      />
    )
  }

  if (loading || !data) {
    return (
      <LoadingScreen
        isOpen
        title={{
          text: 'loading_screen.title',
          namespace: 'shared'
        }}
      />
    )
  }

  const {
    user: {
      preferredLanguage,
      shippingCountryCode,
      dogs,
      subscription: {
        plan: { pouchSize, numberOfPouches, durationInDays },
        meal
      }
    }
  } = data

  const { order } = uncreatedBox || {}
  const { planTotal = 0, discountTotal = 0 } = order || {}
  const _discountTotal = discountTotal || 0

  const { locale, currency } = countryCodeToLocaleCurrency(
    shippingCountryCode,
    preferredLanguage
  )

  const planCadence = durationInDays / 7
  const dogNames = toLocalisedSentence({
    arr: dogs.map((dog: Dog) => capitaliseFirstLetter(dog.name)),
    lng: preferredLanguage
  })
  const possessiveDogNames = possessive(dogNames, preferredLanguage)

  const discountedPricePerDay = formatCurrencyWithDecimal(
    (planTotal - _discountTotal) / durationInDays,
    {
      locale,
      currency
    }
  )
  const pricePerDay = formatCurrencyWithDecimal(planTotal / durationInDays, {
    locale,
    currency
  })

  const { alreadyRedeemedReactivationCampaign, defaultReactivationCampaign } =
    data?.user?.subscription || {}

  const boxMeals = meal.filter((mealItem: Meal) => mealItem.quantity > 0)

  const discountAmount = defaultReactivationCampaign
    ? defaultReactivationCampaign?.discountCode.discountCodeParts[0].value || 0
    : alreadyRedeemedReactivationCampaign?.discountCode.discountCodeParts[0]
        .value || 0

  const discountPercentage = percentageValue(discountAmount)

  const tieredRectivationDiscountParts = defaultReactivationCampaign
    ? isNull(defaultReactivationCampaign?.discountCode.discountCodeParts[0].n)
      ? 1
      : defaultReactivationCampaign?.discountCode.discountCodeParts[0].n // Assumes single part if null, admin form ensures this is actually not null for reactivation campaigns
    : alreadyRedeemedReactivationCampaign
    ? isNull(
        alreadyRedeemedReactivationCampaign?.discountCode.discountCodeParts[0].n
      )
      ? 1
      : alreadyRedeemedReactivationCampaign?.discountCode.discountCodeParts[0].n
    : 1

  return (
    <Grid container className={STYLES.heroContainer} justifyContent="center">
      <div className={STYLES.heroContainerWrapper}>
        {windowWidth >= BREAKPOINTS.md && (
          <Grid item sm={12} md={6}>
            <Image
              className={STYLES.heroImage}
              slug={'dog-sniffing-butternut-box-pouches'}
              image={{
                resizeMode: 'resize_to_fill',
                retina: true,
                height:
                  allowEditAndReviewPlanBeforeReactivation &&
                  !defaultReactivationCampaign
                    ? 618
                    : 370,
                width:
                  allowEditAndReviewPlanBeforeReactivation &&
                  !defaultReactivationCampaign
                    ? 450
                    : 465
              }}
              alt={t('resume_plan_card.alt')}
            />
          </Grid>
        )}
        <Grid item sm={12} md={6} className={STYLES.planCard}>
          {defaultReactivationCampaign &&
          !alreadyRedeemedReactivationCampaign ? (
            <Card
              shadow
              border="none"
              padding={0}
              fill={windowWidth >= BREAKPOINTS.md}
            >
              <div className={STYLES.cardContent}>
                <div className={STYLES.campaignCopy}>
                  <Text
                    namespace={namespace}
                    text={`default_campaign_card.discount_off`}
                    variant="display36"
                    variables={{
                      discountPercentage
                    }}
                    margin={false}
                    align="center"
                    colour="brandRed400"
                  />
                  <Text
                    namespace={namespace}
                    text={'default_campaign_card.next_box'}
                    variables={{
                      discountParts: tieredRectivationDiscountParts,
                      count: tieredRectivationDiscountParts
                    }}
                    variant="display20"
                    margin={false}
                    align="center"
                  />
                  <div className={STYLES.campaignCountdown}>
                    <Countdown
                      labels={{
                        daysLabel: `${copyContextCountdown}.day`,
                        hoursLabel: `${copyContextCountdown}.hour`,
                        minutesLabel: `${copyContextCountdown}.minute`,
                        secondsLabel: `${copyContextCountdown}.second`
                      }}
                      namespace="checkout"
                      targetDate={defaultReactivationCampaign.expiryDate}
                      numberVariant="display24"
                      labelVariant="textRegular16"
                      countdownType="dailyCountdown"
                      textColour="brandBlue500"
                      variant="default"
                    />
                  </div>
                  <Text
                    namespace={namespace}
                    text={
                      isNull(defaultReactivationCampaign.description)
                        ? `default_campaign_card.body`
                        : defaultReactivationCampaign.description
                    }
                    variant="textRegular16"
                    margin={false}
                    variables={{
                      discountPercentage,
                      count: tieredRectivationDiscountParts
                    }}
                  />
                  <Button
                    onClick={redeemCampaignDiscount}
                    typography={{
                      namespace,
                      text: `default_campaign_card.claim_discount_cta`
                    }}
                    fullWidth
                    identifier="paused_page.campaign_card.claim_discount_cta"
                  />
                  <Text
                    namespace={namespace}
                    text={`default_campaign_card.account_paused`}
                    variant="textRegular14"
                    margin={false}
                    align="center"
                    colour="brandBlue400"
                  />
                </div>
              </div>
            </Card>
          ) : (
            <Card
              shadow
              border="none"
              padding={0}
              fill={windowWidth >= BREAKPOINTS.md}
            >
              <div className={STYLES.cardContent}>
                <div className={STYLES.cardCopy}>
                  {!userData?.address?.deliverable ? (
                    <Fragment>
                      <Text
                        namespace={namespace}
                        variant="display24"
                        text="resume_plan_card.cannot_ship_to_postcode_title"
                      />

                      <Text
                        namespace={namespace}
                        variant="display16"
                        text="resume_plan_card.cannot_ship_to_postcode_body"
                        variables={{ postcode: userData?.address?.postcode }}
                      />

                      <Button
                        fullWidth
                        identifier="paused_page.undeliverable_postcode.button"
                        onClick={handleOpenDixa}
                        typography={{
                          namespace,
                          text: 'resume_plan_card.cannot_ship_to_postcode_cta'
                        }}
                      />
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Text
                        namespace={namespace}
                        text={`resume_plan_card.title`}
                        variant="display20"
                        variables={{
                          possessiveDogNames
                        }}
                        margin={false}
                      />
                      <Text
                        namespace={namespace}
                        text={
                          allowEditAndReviewPlanBeforeReactivation
                            ? `resume_plan_card.review_and_edit_variant.body`
                            : `resume_plan_card.body`
                        }
                        colour="brandBlue400"
                        margin={false}
                      />
                      <PausedDate
                        user={data.user}
                        pausedDeliveryDate={pausedDeliveryDate}
                        setPausedDeliveryDate={setPausedDeliveryDate}
                        loading={loading}
                      />
                      {!allowEditAndReviewPlanBeforeReactivation && (
                        <div className={STYLES.boxBreakdown}>
                          <BoxBreakdown
                            cadence={planCadence}
                            pouchSize={pouchSize}
                            pouches={numberOfPouches}
                            alignment="left"
                          />
                        </div>
                      )}
                      {allowEditAndReviewPlanBeforeReactivation &&
                        !isUndefined(meal) && (
                          <SectionWrapper
                            margin={{
                              top: 24,
                              bottom: 16
                            }}
                            headerTypography={{
                              variant: 'display16',
                              element: 'div',
                              namespace: 'account',
                              text: `${copyContext}.fresh_meals`,
                              translate: true
                            }}
                            headerRightAdornment={{
                              variant: 'custom',
                              content: (
                                <FlatButton
                                  text={{
                                    text: `${copyContext}.edit`,
                                    namespace: 'account',
                                    translate: true
                                  }}
                                  onClick={editRecipes}
                                  identifier="box_details.edit_meals"
                                />
                              )
                            }}
                          >
                            <SwiperSlider
                              arrows={false}
                              variant="brandBlue100"
                              paddingOverride={
                                windowWidth > BREAKPOINTS.md ? 32 : 16
                              }
                            >
                              {boxMeals.map((mealItem: Meal) => (
                                <ProductThumb
                                  image={mealItem.flavour.thumbnail.src}
                                  alt={mealItem.flavour.name}
                                  quantity={mealItem.quantity}
                                  key={mealItem.flavour.name}
                                />
                              ))}
                            </SwiperSlider>
                          </SectionWrapper>
                        )}
                      <Text
                        namespace={namespace}
                        text={
                          _discountTotal > 0
                            ? `resume_plan_card.box_price_discount`
                            : `resume_plan_card.box_price`
                        }
                        variables={{
                          discountedPricePerDay,
                          pricePerDay
                        }}
                        variant="display20"
                        shouldScale={false}
                      />
                      <div className={STYLES.cardActions}>
                        <Button
                          dataTestId="resume-plan-cta"
                          onClick={resumePlanClicked}
                          typography={{
                            namespace,
                            text: resumeLoading
                              ? `resume_plan_card.cta_loading`
                              : alreadyRedeemedReactivationCampaign
                              ? `resume_plan_card.cta_discounted`
                              : `resume_plan_card.cta`
                          }}
                          disableAnalytics
                          fullWidth
                          disabled={resumeLoading}
                        />
                        {allowEditAndReviewPlanBeforeReactivation && (
                          <Link
                            onClick={gotToPlanManagement}
                            suffix={null}
                            text={
                              'resume_plan_card.review_and_edit_variant.review_and_edit_plan'
                            }
                            namespace={namespace}
                            identifier="resume_plan_card.review_and_edit_variant.review_and_edit_plan"
                          />
                        )}
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
              {alreadyRedeemedReactivationCampaign &&
                alreadyRedeemedReactivationCampaign.expiryDate && (
                  // It's possible that the expiry might be in the past (if they claim before the deadline
                  // but don't resume instantly), so we should check if it's in the future, if not just set
                  // the count down to 0h0m0s or hide the count down
                  <div className={STYLES.claimedCampaignCountdown}>
                    <Text
                      namespace={namespace}
                      text={`resume_plan_card.discount_expires_in`}
                      variant="textRegular14"
                      margin={false}
                      bold
                    />
                    <Countdown
                      labels={{
                        daysLabel: `${copyContextCountdown}.day`,
                        hoursLabel: `${copyContextCountdown}.hour`,
                        minutesLabel: `${copyContextCountdown}.minute`,
                        secondsLabel: `${copyContextCountdown}.second`
                      }}
                      namespace="checkout"
                      targetDate={
                        alreadyRedeemedReactivationCampaign.expiryDate
                      }
                      numberVariant="display16"
                      labelVariant="textRegular14"
                      countdownType="dailyCountdown"
                      textColour="brandBlue500"
                      variant="default"
                      backgroundColour="brandYellow100"
                    />
                  </div>
                )}
            </Card>
          )}
          {windowWidth < BREAKPOINTS.md && (
            <Grid item sm={12} md={6}>
              <Image
                className={STYLES.heroImage}
                slug={'dog-sniffing-butternut-box-pouches'}
                image={{
                  resizeMode: 'resize_to_fill',
                  height: 175,
                  width: 357,
                  retina: true
                }}
                alt={t('resume_plan_card.alt')}
              />
            </Grid>
          )}
        </Grid>
      </div>
      <NotificationContainer autoClose={5000} />
    </Grid>
  )
}

export default DogsHero
