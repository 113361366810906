// @noflow
import lazyLoad from '@/modules/lazyLoad'
import React from 'react'
import type { ReactElement } from 'react'

import Arrow from 'assets/images/icons/arrows/arrow--blue-600.svg'

import ProductLabel from '@/components/shared/elements/ProductLabel'

import CloudinaryImage from './elements/CloudinaryImage/CloudinaryImage'

type Sticker = {
  image: {
    src: string | null
  }
}

type Benefit = {
  name: string
  url: string | null
}

type Props = {
  className?: string
  sticker?: Sticker
  image: CloudinaryAsset
  title: string
  description: string
  benefits: Array<Benefit>
  onClick?: () => void
  labels?: Array<{ name: string; colour: string }>
}

type CloudinaryAsset = {
  path: string
  alt: string
}

const ProductCard = ({
  className = '',
  sticker,
  image,
  title,
  description,
  benefits,
  onClick,
  labels
}: Props): ReactElement => {
  React.useEffect((): void => {
    lazyLoad.update()
  }, [])

  return (
    <button
      className={`product-card ${className}`}
      type="button"
      onClick={onClick}
    >
      <CloudinaryImage
        className="product-card__primary-asset lazy"
        image={{
          path: image.path,
          width: 350,
          crop: 'fill',
          quality: 'auto:best',
          dpr: 2
        }}
        alt={image.alt}
      />
      <p className="product-card__title">{title}</p>
      {labels && (
        <div className="product-card__labels">
          {labels.map((label) => (
            <ProductLabel
              key={`${label.name}-product-card-label-key`}
              name={label.name}
              themeColour={label.colour}
            />
          ))}
        </div>
      )}
      <div
        className="product-card__description"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: description
        }}
      />

      {benefits.length > 0 && (
        <div className="product-card__icons">
          <div className="product-card__icons__benefits">
            {benefits
              .filter((benefit) => benefit.url !== null)
              .map(({ name, url }) => (
                <span key={name} data-tooltip={name}>
                  {url && (
                    <CloudinaryImage
                      alt={name}
                      image={{
                        path: url,
                        format: 'svg'
                      }}
                    />
                  )}
                </span>
              ))}
          </div>
          <img
            src={Arrow}
            alt="an arrow indicating you can click to read more"
            className="product-card__icons__arrow-button"
          />
        </div>
      )}
      {sticker && sticker.image.src && (
        <CloudinaryImage
          className="product-card__new-tag"
          image={{ path: sticker.image.src }}
          alt={sticker.image.src}
        />
      )}
    </button>
  )
}

export type { Props, Benefit, CloudinaryAsset }
export default ProductCard
