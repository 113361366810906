// @noflow
import classnames from 'classnames'
import React from 'react'

import Spinner from '@/components/elements/atoms/Spinner/Spinner'

import STYLES from './LoadingOverlay.module.sass'

type Props = {
  show: boolean
}

const LoadingOverlay = ({ show }: Props): JSX.Element => {
  const classname = classnames(STYLES.loading, {
    [STYLES.show]: show
  })

  return (
    <div className={classname}>
      <Spinner variant="brandYellow500" />
    </div>
  )
}

export default LoadingOverlay
