import { gql } from '@apollo/client'

const MEDICAL_INFO_QUERY = gql`
  query MedicalInfoQuery {
    user {
      id
      subscription {
        id
        idealPlan {
          pricingCurve {
            pricingCohort
          }
        }
        plan {
          pricingCurve {
            pricingCohort
          }
        }
      }
      dogs {
        id
        name
        gender
        possessivePronoun
        healthIssues {
          id
          name
          warning
          information
        }
        allergens {
          id
          name
        }
      }
    }
    healthIssues {
      id
      name
      warning
      information
    }
    allergens {
      id
      name
    }
  }
`

export { MEDICAL_INFO_QUERY }
