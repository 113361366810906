import { gql } from '@apollo/client'

import {
  BOX_ORDER_FRAGMENT,
  NON_CORE_ORDER_FRAGMENT
} from '@/components/pages/Dashboard/components/upcomingBoxes/queries/fragments'

const SUBSCRIPTION_RESUME_MUTATION = gql`
  mutation SubscriptionResume($numOfBoxes: Int!) {
    subscriptionResume {
      id
      subscription {
        id
        status
        nextNBoxes(num: $numOfBoxes) {
          id
          cutOffDate
        }
      }
    }
    subscriptionResumedEventCreate(userId: null, properties: null) {
      id
    }
    subscriptionResumedWorkflowCreate(userId: null, properties: null) {
      id
    }
  }
`

const TREATMENTS_PAUSED_PAGE_FUTURE_BOXES_QUERY = gql`
  query TreatmentsPausedFutureBoxesQuery {
    user {
      id
      subscription {
        id
        previewUncreatedFutureBox {
          isoDeliveryDate
          order {
            id
            totalPrice
            surchargeTotal
            planTotal
            deliveryFee
            subtotal
            discountTotal
            discountedSubtotalPricePerDay
            subtotalPricePerDay
          }
        }
      }
    }
  }
`

const TREATMENTS_PAUSED_PAGE_QUERY = gql`
  ${BOX_ORDER_FRAGMENT}
  ${NON_CORE_ORDER_FRAGMENT}
  query TreatmentsPausedPageQuery($from: ISO8601Date!, $to: ISO8601Date!) {
    user {
      id
      preferredLanguage
      shippingCountryCode
      dogs {
        id
        name
        gender
      }
      ordersByDate(from: $from, to: $to) {
        ...upcomingBoxOrderFragment
        ...nonCoreUpcomingOrderFragment
      }
      subscription {
        id
        previewUncreatedFutureBox {
          isoDeliveryDate
          order {
            id
            totalPrice
            surchargeTotal
            planTotal
            deliveryFee
            subtotal
          }
        }
        meal {
          quantity
          flavour {
            slug
            thumbnail {
              src
            }
            themeColour
            name
            recipeSurcharge
            productLabels {
              name
              themeColour
            }
          }
        }
        plan {
          id
          pouchSize
          numberOfPouches
          durationInDays
          swappablePlan {
            id
            pouchSize
            price
          }
          downsizeablePlan {
            id
            price
          }
        }
        currentPause {
          id
          treatments
        }
        defaultReactivationCampaign {
          name
          expiryDate
          description
          discountCode {
            id
            discountCodeParts {
              value
              discountType
              n
            }
          }
        }
        alreadyRedeemedReactivationCampaign {
          name
          expiryDate
          description
          discountCode {
            id
            discountCodeParts {
              value
              discountType
              n
            }
          }
        }
      }
    }
  }
`

export {
  TREATMENTS_PAUSED_PAGE_QUERY,
  SUBSCRIPTION_RESUME_MUTATION,
  TREATMENTS_PAUSED_PAGE_FUTURE_BOXES_QUERY
}
