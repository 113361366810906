import { useCsrfToken } from '@/context/injectedValues/injectedValues'
import React, { useCallback, useState } from 'react'

import useWindowSize from '@/hooks/useWindowSize'

import segmentTrack from '@/components/analytics/Analytics'
import { Button } from '@/components/elements/atoms/Button'
import Modal from '@/components/elements/atoms/Modal/Modal'
import Text from '@/components/elements/atoms/Text/Text'
import DownloadInvoiceButton from '@/components/elements/molecules/DownloadInvoiceButton/DownloadInvoiceButton'
import { BREAKPOINTS } from '@/components/templates/Base'

import STYLES from './BoxActionButtons.module.sass'

import { Code as CountryCode, Language } from '@/types'

import InvoiceCard, { Invoice } from './InvoiceCard/InvoiceCard'

type Props = {
  namespace: string
  copyContext: string
  showHelpWithOrderButton: boolean
  showDownloadReceiptButton: boolean
  invoiceIssued?: boolean
  shippingCountryCode: CountryCode
  invoiceId?: string
  showInvoiceDownloadDirections?: boolean
  preferredLanguage?: Language
  invoices?: Array<Invoice>
}

const BoxActionButtons = ({
  namespace,
  copyContext,
  showHelpWithOrderButton,
  showDownloadReceiptButton,
  invoiceIssued,
  shippingCountryCode,
  invoiceId,
  showInvoiceDownloadDirections,
  preferredLanguage,
  invoices
}: Props): JSX.Element => {
  const [invoicesModalIsOpen, setInvoicesModalIsOpen] = useState(false)
  const { windowWidth } = useWindowSize()

  const csrfToken = useCsrfToken().csrfToken

  const toggleInvoicesModalOpen = useCallback(() => {
    setInvoicesModalIsOpen(!invoicesModalIsOpen)
  }, [invoicesModalIsOpen])

  const helpWithBoxOnClick = useCallback(() => {
    segmentTrack('Order Details Page - Help with this order Button Clicked')
    // eslint-disable-next-line i18next/no-literal-string
    window.location.href = '/contact'
  }, [])

  return (
    <div className={STYLES.wrapper}>
      {showHelpWithOrderButton && (
        <Button
          typography={{
            text: `${copyContext}.help_with_this_order`,
            variant: 'textRegular16',
            namespace
          }}
          identifier="help_with_this_order"
          screenIdentifier="box_details"
          fullWidth
          onClick={helpWithBoxOnClick}
        />
      )}
      {showDownloadReceiptButton && invoiceId && csrfToken && (
        <DownloadInvoiceButton
          invoiceIssued={invoiceIssued}
          showInvoiceDownloadDirections={showInvoiceDownloadDirections}
          csrfToken={csrfToken}
          invoiceId={invoiceId}
          shippingCountryCode={shippingCountryCode}
          buttonText={{
            namespace,
            text: `${copyContext}.download_receipt`
          }}
          fullWidth
          toggleInvoicesModalOpen={toggleInvoicesModalOpen}
        />
      )}
      {invoicesModalIsOpen && (
        <Modal
          isModalOpen={invoicesModalIsOpen}
          setOpenModal={toggleInvoicesModalOpen}
          width={600}
          textAlign="center"
          bottomSticky={windowWidth < BREAKPOINTS.md}
        >
          <>
            <Text
              namespace={namespace}
              text={`${copyContext}.your_invoices`}
              variant="display28"
            />
            {preferredLanguage &&
              invoices &&
              invoices.map((invoice) => {
                return (
                  <InvoiceCard
                    key={invoice.id}
                    invoice={invoice}
                    shippingCountryCode={shippingCountryCode}
                    preferredLanguage={preferredLanguage}
                  />
                )
              })}

            <Button
              typography={{
                namespace,
                text: `${copyContext}.done`
              }}
              onClick={toggleInvoicesModalOpen}
              fullWidth
              disableAnalytics
            />
          </>
        </Modal>
      )}
    </div>
  )
}

export default BoxActionButtons
