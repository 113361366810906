/* eslint-disable i18next/no-literal-string */
import { gql } from '@apollo/client'

import { ORDER_SUMMARY_MODAL_FRAGMENT } from '@/components/modals/OrderSummaryModal/fragments'
import {
  BOX_ORDER_FRAGMENT_V3,
  NON_CORE_ORDER_FRAGMENT_V3
} from '@/components/pages/DashboardV3/components/UpcomingBoxesV3/queries/fragments'

const UPCOMING_BOX_QUERY_V3 = gql`
  ${BOX_ORDER_FRAGMENT_V3}
  ${NON_CORE_ORDER_FRAGMENT_V3}
  ${ORDER_SUMMARY_MODAL_FRAGMENT}
  query upcomingBoxQueryV3($offset: Int!, $limit: Int!, $order: String) {
    shouldSeeDeliveryTracking: featureFlag(
      flag: "should_see_delivery_tracking_component"
      fallback: "false"
    )
    settings(names: ["carrier_preferences_enabled"]) {
      name
      value
    }
    user {
      id
      currentDeliveryAreaPreference {
        id
        name
        preferredCarrierService {
          id
          carrier {
            name
          }
        }
      }
      ordersByDate(offset: $offset, limit: $limit, order: $order) {
        ...upcomingBoxOrderFragmentV3
        ...nonCoreUpcomingOrderFragmentV3
        ...OrderSummaryModalFragment
      }
      preferredLanguage
      shippingCountryCode
      shippingCountry {
        id
        showInvoiceDownloadDirections
      }
      subscription {
        id
        firstBox {
          id
        }
        plan {
          id
          pricePerDay
          numberOfPouches
          durationInWeeks
          nextUpcomingPrice {
            value
            effectiveFrom
          }
        }
      }
    }
  }
`

export { UPCOMING_BOX_QUERY_V3 }
