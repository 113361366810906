import { gql } from '@apollo/client'

const DASHBOARD_FOOTER_QUERY = gql`
  query DashboardFooterQuery {
    user {
      id
      shippingCountryCode
    }
  }
`

export { DASHBOARD_FOOTER_QUERY }
