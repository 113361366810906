import { gql } from '@apollo/client'

import {
  BOX_ORDER_FRAGMENT,
  NON_CORE_ORDER_FRAGMENT
} from '@/components/pages/Dashboard/components/upcomingBoxes/queries/fragments'

const ORDERS_PRODUCT_CREATE = gql`
  ${BOX_ORDER_FRAGMENT}
  ${NON_CORE_ORDER_FRAGMENT}

  mutation orderProductsCreate(
    $userId: ID!
    $productVariantInput: ProductVariantInput!
    $offset: Int!
    $limit: Int!
    $order: String
  ) {
    orderProductsCreate(
      userId: $userId
      productVariantInput: $productVariantInput
    ) {
      user {
        ordersByDate(offset: $offset, limit: $limit, order: $order) {
          ...upcomingBoxOrderFragment
          ...nonCoreUpcomingOrderFragment
        }
        basket {
          id
          basketProducts {
            id
            quantity
          }
        }
      }
    }
  }
`
export { ORDERS_PRODUCT_CREATE }
