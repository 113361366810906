// @noflow
import React from 'react'

import { ICON_COLOURS, IconProps } from '..'

type Props = {
  showShadow?: boolean
} & IconProps

const Cog = ({
  height,
  accentColour,
  width,
  showShadow
}: Props): JSX.Element => {
  return (
    <svg
      width={width ?? 30}
      height={height ?? 30}
      viewBox="0 0 30 30"
      fill={showShadow ? '#FFE180' : '#fff'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.625 5.722c0 .4-1.292.778-2.125.778C9.333 5.667 7.83 2.668 7 3 4.5 4 4 6.722 3.625 7.722c-.314.838 2.5 1 4.5 1.5-.167 1-.5 2.1-.5 2.5 0 .5-5-.5-6-.5s-.5 4.5-.5 5 5-.5 5.5-.5 1 1 1 2.5S4.5 20.5 3.5 21.5 6 26 7 26s2.625-3.778 3.125-3.778 1.5 0 2 .5-.5 4.5 0 5.5 4.5 0 5.5 0-1.5-4.5-1-5 2-.5 2.5-.5 3 3 3.5 3.5 4-4.5 3.5-5-3.5-1-3.5-1.5.5-2.5 1-3c.4-.4 2.833.833 4.5 1 .167-1.333 1.3-5.1.5-5.5-1-.5-4.5.5-5 .5s-1.5-2-1.5-2.5 4-3 3.5-3.5-2.5-3.5-3-3.5c-.4 0-2.667 2.167-3.5 3-.333 0-1.1-.1-1.5-.5-.5-.5 0-4-.5-4.5s-4.5 0-5 0 .5 4 .5 4.5Z"
        stroke={ICON_COLOURS[accentColour]}
        strokeWidth="1.3"
      />
      <circle
        cx="15"
        cy="14"
        r="3"
        stroke={ICON_COLOURS[accentColour]}
        strokeWidth="1.3"
      />
    </svg>
  )
}

export default Cog
