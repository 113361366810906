import { gql } from '@apollo/client'

const ADD_DISCOUNTED_EXTRAS_COUNTDOWN_CARD_QUERY = gql`
  query addDiscountedExtrasCountdownCardQuery {
    user {
      id
      subscription {
        id
        nextEditableBox {
          isoDeliveryDate
          hoursUntilCutOff
          order {
            discountTotalPercentage
          }
        }
      }
    }
  }
`

export { ADD_DISCOUNTED_EXTRAS_COUNTDOWN_CARD_QUERY }
