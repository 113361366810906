import { gql } from '@apollo/client'

const NO_PAYMENT_METHOD_PAGE_DATA = gql`
  query NoPaymentMethodPageData {
    user {
      id
      preferredLanguage
      shippingCountryCode
      email
      token
      shouldSupportSca
      dogs {
        name
      }
      subscription {
        plan {
          durationInDays
        }
        firstBox {
          price
          cutOffDate
        }
      }
    }
  }
`

export { NO_PAYMENT_METHOD_PAGE_DATA }
