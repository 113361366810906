// @noflow
import React from 'react'

import MultipleDogs from 'assets/images/illustrations/dogs/dog-profile-avatars/multiple-dogs.svg'

import DogAvatar from '@/components/elements/molecules/DogAvatar/DogAvatar'
// eslint-disable-next-line no-restricted-imports
import type { Dog } from '@/components/types'

import STYLES from './DogAvatarGroup.module.sass'

type Props = {
  dogs: Array<Dog>
}

const DogAvatarGroup = ({ dogs }: Props): JSX.Element => {
  return (
    <div className={STYLES.container}>
      {dogs.length <= 3 ? (
        dogs.map(
          (dog: Dog): React.ReactElement => (
            <DogAvatar
              key={`dog-avatar-${dog.id}`}
              avatarUrl={dog.dogProfile?.avatarUrl}
              sprinkles={dogs.length === 1}
              breed={dog.breed?.name}
              ageInMonths={dog.ageInMonths}
            />
          )
        )
      ) : (
        <img alt="" src={MultipleDogs} className={STYLES.multipleDogs} />
      )}
    </div>
  )
}

export { Props }
export default DogAvatarGroup
