// @noflow
import { userHasActivePaymentMethod } from '@/context/accessManagement/accessManagement'
import { useReactiveVar } from '@apollo/client'
import isUndefined from 'lodash/isUndefined'
import React from 'react'

import {
  featureFlagsDataVar,
  subscriptionDataVar,
  userDataVar
} from '@/services/apollo'

import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import MerchandisingCarousel from '@/components/elements/organisms/MerchandisingCarousel/MerchandisingCarousel'
import FeedingTipsCard from '@/components/pages/Dashboard/components/FeedingTipsCard/FeedingTipsCard'
import HomeTourV2 from '@/components/pages/Dashboard/components/HomeTourV2/HomeTourV2'
import NoPaymentMethodSection from '@/components/pages/Dashboard/components/NoPaymentMethodSection/NoPaymentMethodSection'
import SuspendedSection from '@/components/pages/Dashboard/components/SuspendedSection/SuspendedSection'

import { SubscriptionStatus } from '@/types'

const PrimaryActions = (): JSX.Element => {
  const subscriptionData = useReactiveVar(subscriptionDataVar)
  const featureFlagsData = useReactiveVar(featureFlagsDataVar)
  const userData = useReactiveVar(userDataVar)

  const { status, deliveriesReceived, paymentMethods } = subscriptionData ?? {}

  const { shouldSeeHomeOnboardingTourV2, shouldSeePlanManagementPhase3 } =
    featureFlagsData ?? {}

  /**
   * Recommended actions in order of priority:
   * 1. Suspended
   * 2. No active payment method
   * 3. Post Sign Up Hero
   * 4. Guide card
   * 5. Onboarding quiz
   * 6. Merchandising carousel
   */
  const suspended = status === SubscriptionStatus.suspended

  // if the user is an affiliate, they are gifted free boxes. In this situation,
  // we should not show the add payment method card as it is expected that they may
  // not have a payment method associated
  const affiliateTrialAccount = userData?.affiliateType !== 'not_affiliate'
  const noActivePaymentMethod =
    !userHasActivePaymentMethod(paymentMethods) && !affiliateTrialAccount

  const showGuideCard =
    !isUndefined(deliveriesReceived) &&
    deliveriesReceived < 2 &&
    userHasActivePaymentMethod(paymentMethods)

  const isOnOldDashboard = shouldSeePlanManagementPhase3 !== 'Variant'

  const showHomeOnboardingTourV2 =
    shouldSeeHomeOnboardingTourV2 === 'variant' && isOnOldDashboard

  switch (true) {
    case suspended: {
      return <SuspendedSection />
    }
    case noActivePaymentMethod: {
      return <NoPaymentMethodSection />
    }
    case showHomeOnboardingTourV2: {
      return <HomeTourV2 />
    }
    case showGuideCard: {
      return (
        <SectionWrapper
          margin={{
            top: 0,
            bottom: 0
          }}
        >
          <FeedingTipsCard />
        </SectionWrapper>
      )
    }
    default: {
      return (
        <SectionWrapper
          margin={{
            top: 0,
            bottom: 0
          }}
        >
          <MerchandisingCarousel />
        </SectionWrapper>
      )
    }
  }
}

export { PrimaryActions }
