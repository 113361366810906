/* eslint-disable i18next/no-literal-string */
import { gql } from '@apollo/client'

const SUBSCRIPTION_DETAILS_CARD_QUERY = gql`
  query SubscriptionDetailsCardQuery {
    user {
      id
      dogs {
        id
        name
        dogProfile {
          id
          avatarUrl
        }
        breed {
          id
          name
        }
        ageInMonths
      }
      subscription {
        id
        plan {
          numberOfPouches
          durationInDays
          gramsPerDayPerDogs {
            gramsPerDay
            dogId
            name
          }
        }
        status
      }
    }
  }
`

export { SUBSCRIPTION_DETAILS_CARD_QUERY }
