/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable react/jsx-no-useless-fragment */
import React, { createContext, useCallback, useMemo, useState } from 'react'

import ProductsUpsellModal, {
  Props as ProductsUpsellModalProps
} from '@/components/elements/organisms/ProductsUpsellModal/ProductsUpsellModal'

import { VariantDelivery } from '@/types'

type ProviderProps = {
  children: JSX.Element | JSX.Element[]
}

type HeaderText = ProductsUpsellModalProps['headerText']

type AnalyticsType = ProductsUpsellModalProps['analyticsType']

type OpenProductsUpsellModalProps = Pick<
  ProductsUpsellModalProps,
  'headerText' | 'analyticsType'
>

type Context = {
  productsUpsellModalIsOpen: boolean
  openProductsUpsellModal: (props: OpenProductsUpsellModalProps) => void
  closeProductsUpsellModal: () => void
}
const ProductUpsellContext = createContext<Context>({
  productsUpsellModalIsOpen: false,
  openProductsUpsellModal: () => {},
  closeProductsUpsellModal: () => {}
})

const ProductUpsellProvider = ({ children }: ProviderProps): JSX.Element => {
  const [productsUpsellModalIsOpen, setProductsUpsellModalIsOpen] =
    useState(false)
  const [headerText, setHeaderText] = useState<HeaderText>([])
  const [analyticsType, setAnalyticsType] = useState<AnalyticsType>(null)

  const openProductsUpsellModal = useCallback(
    ({ headerText, analyticsType }: OpenProductsUpsellModalProps) => {
      setHeaderText(headerText)
      setAnalyticsType(analyticsType)
      setProductsUpsellModalIsOpen(true)
    },
    []
  )

  const closeProductsUpsellModal = useCallback(() => {
    setProductsUpsellModalIsOpen(false)
  }, [])

  const context = useMemo(
    () => ({
      productsUpsellModalIsOpen,
      openProductsUpsellModal,
      closeProductsUpsellModal
    }),
    [
      closeProductsUpsellModal,
      openProductsUpsellModal,
      productsUpsellModalIsOpen
    ]
  )

  return (
    <ProductUpsellContext.Provider value={context}>
      {children}
      <ProductsUpsellModal
        onClose={context.closeProductsUpsellModal}
        isModalOpen={context.productsUpsellModalIsOpen}
        analyticsType={analyticsType}
        headerText={headerText}
        deliveryType={VariantDelivery.one_off}
      />
    </ProductUpsellContext.Provider>
  )
}

export { ProductUpsellContext, ProductUpsellProvider }
