import React, { useState } from 'react'

import {
  pageHeaderShowBackButtonState,
  pageTitleState
} from '../../CustomerIssueManagementPage'
import ContactCustomerLoveDetails from './ContactCustomerLoveDetails'

const ContactCustomerLove = (): JSX.Element => {
  const [initialised, setInitialised] = useState(false)

  React.useEffect(() => {
    if (!initialised) {
      pageTitleState('contact_customer_love.header')
      pageHeaderShowBackButtonState(false)
      setInitialised(true)
    }
  }, [initialised])

  return <ContactCustomerLoveDetails />
}

export default ContactCustomerLove
