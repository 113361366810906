// @noflow
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { isDevelopment as isDevelopmentEnv } from '@/utils/isDevelopment'

import { Expand } from '@/components/elements/atoms/Animated/Animated'
import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'
import FlatButton from '@/components/elements/atoms/FlatButton/FlatButton'
import Text from '@/components/elements/atoms/Text/Text'
import { checkoutPageState } from '@/components/pages/CheckoutPage/CheckoutPage'
import * as inputHelpers from '@/components/pages/CheckoutPage/helpers/inputs'
import Tooltip from '@/components/shared/Tooltip'
import GeoIpWidget from '@/components/shared/geo_ip_widget/GeoIpWidget'

import type { CheckoutPage } from '../../queries/__generated__/CheckoutPage'
import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'
import { ensureNever } from '@/typescript/utils'

import * as ANALYTICS from '../../Analytics/CheckoutAnalytics'
import useDeliveryDatesQuery from '../../hooks/useDeliveryDatesQuery'
import useValidatePostcode from '../../hooks/useValidatePostcode'
import type { AddressDetailsFormKey } from '../../types'
import {
  AddressSearchInputField,
  StandardInputField
} from '../InputField/InputField'
import PostcodeInputField from '../InputField/PostcodeInputField'
import DeliveryAddressFinder from './DeliveryAddressFinder'
import FetchifyParams from './FetchifyParams'

type Props = {
  namespace: string
  activeShippingCountries: CheckoutPage['shippingCountries']
  shippingCountryCode: CheckoutPage['guest']['assumedShippingCountry']['code']
  useFetchify: boolean
}

type FetchifyResult = {
  line_1: string
  line_2?: string
  province_name: string
  locality: string
  postal_code: string
}

type SelectableAddress = {
  addressLine1: string
  addressLine2?: string
  city: string
  postcode: string
}

const mapAddressToFormFields = (address: FetchifyResult): SelectableAddress => {
  const { line_1, line_2, locality, postal_code } = address

  return {
    addressLine1: line_1,
    addressLine2: line_2,
    city: locality,
    postcode: postal_code
  }
}

const DeliveryAddressSection = ({
  namespace,
  activeShippingCountries,
  shippingCountryCode,
  useFetchify
}: Props): JSX.Element => {
  const { t } = useTranslation(namespace)
  const { fetchDeliveryDates } = useDeliveryDatesQuery()
  const { sections } = checkoutPageState()

  const { addressDetails } = sections
  const { visible, valid, form } = addressDetails
  const { addressLine1, addressLine2, city, postcode, searchTerm } = form

  const {
    postcodeIsValid,
    errorMessage: postcodeErrorMessage,
    validatePostcode,
    loading: postcodeValidationLoading
  } = useValidatePostcode(shippingCountryCode)

  const copyContext = 'delivery_address_section'

  const isDevelopment = isDevelopmentEnv()

  const remoteAreaRegions: CountryCode = 'IE' || 'NL' || 'BE' || 'PL' || 'DE'

  const currentDomain = window.location.hostname
  const filteredActiveShippingCountriesByDomain = isDevelopment
    ? activeShippingCountries
    : activeShippingCountries.filter(({ domain }) => domain === currentDomain)

  const formHasInputtedData =
    addressLine1.value.length > 0 ||
    city.value.length > 0 ||
    postcode.value.length > 0

  const [showAddressDropdown, setShowAddressDropdown] = useState(false)
  const [showAddressFields, setShowAddressFields] = useState(
    formHasInputtedData || !useFetchify
  )

  const showDropdown = isDevelopment && searchTerm.value !== ''

  const updateInputField = (
    fieldName: AddressDetailsFormKey,
    value: string
  ) => {
    switch (fieldName) {
      case 'searchTerm':
      case 'addressLine1':
      case 'addressLine2':
      case 'city':
      case 'postcode':
      case 'eircode': {
        if (fieldName === 'searchTerm') setShowAddressDropdown(true)

        // Set the current value in the state
        sections.addressDetails.form[fieldName].value = value

        // Given the input is being typed into, update its inputInteractionState
        const newInteractionState = inputHelpers.interactingState(
          sections.addressDetails.form[fieldName].inputInteractionState
        )
        sections.addressDetails.form[fieldName].inputInteractionState =
          newInteractionState

        // Is the current value of the input valid?
        if (['postcode', 'eircode'].includes(fieldName)) {
          validatePostcode(value)
          sections.addressDetails.form[fieldName].errorMessage =
            postcodeErrorMessage
        } else {
          const errorMessage = inputHelpers.validate(
            value,
            fieldName,
            shippingCountryCode
          )

          sections.addressDetails.form[fieldName].errorMessage = errorMessage
        }

        return checkoutPageState({
          ...checkoutPageState(),
          sections
        })
      }
      default:
        ensureNever(fieldName)
    }
  }

  const validateInputField = (
    fieldName: AddressDetailsFormKey,
    value: string
  ) => {
    switch (fieldName) {
      case 'searchTerm':
      case 'addressLine1':
      case 'addressLine2':
      case 'city':
      case 'postcode':
      case 'eircode': {
        // Is the current value of the input valid?
        const errorMessage = !['searchTerm', 'postcode', 'eircode'].includes(
          fieldName
        )
          ? inputHelpers.validate(value, fieldName, shippingCountryCode)
          : null

        if (['postcode', 'eircode'].includes(fieldName)) {
          sections.addressDetails.form[fieldName].errorMessage = postcodeIsValid
            ? null
            : postcodeErrorMessage
        } else {
          // Append the relevant errorMessage to the state
          sections.addressDetails.form[fieldName].errorMessage = errorMessage
        }

        // Set the inputInteractionState directly
        sections.addressDetails.form[fieldName].inputInteractionState =
          'NotInteractingInteracted'

        ANALYTICS.blurField(fieldName, sections.addressDetails.form[fieldName])

        if (!errorMessage) {
          if (fieldName === 'postcode' && city.value) {
            fetchDeliveryDates({
              variables: {
                postcode: value,
                city: city.value
              }
            })

            sections.deliveryDetails.visible = true
          }

          if (fieldName === 'city' && postcode.value) {
            fetchDeliveryDates({
              variables: {
                postcode: postcode.value,
                city: value
              }
            })
          }
        }

        return checkoutPageState({
          ...checkoutPageState(),
          sections
        })
      }
      default:
        ensureNever(fieldName)
    }
  }

  const selectAddress = useCallback(
    (result: FetchifyResult, shippingCountryCode: CountryCode): void => {
      const addressOption = mapAddressToFormFields(result)
      const { addressLine1, addressLine2, city, postcode } = addressOption

      const addressOptionFields: Array<AddressDetailsFormKey> = [
        'addressLine1',
        'addressLine2',
        'city',
        'postcode'
      ]

      // Apply the selected addressOption to the state
      sections.addressDetails.form.addressLine1.value = addressLine1
      sections.addressDetails.form.addressLine2.value = addressLine2 || ''
      sections.addressDetails.form.city.value = city
      sections.addressDetails.form.postcode.value = postcode

      Object.values(addressOption).map((value, index): void => {
        let errorMessage = null
        //  validate all address fields
        if (['postcode', 'eircode'].includes(addressOptionFields[index])) {
          validatePostcode(value)
          errorMessage = postcodeErrorMessage
        } else {
          errorMessage = inputHelpers.validate(
            value ?? '',
            addressOptionFields[index],
            shippingCountryCode
          )
          sections.addressDetails.form[
            addressOptionFields[index]
          ].errorMessage = errorMessage
        }

        // Set the inputInteractionState directly
        sections.addressDetails.form[
          addressOptionFields[index]
        ].inputInteractionState = 'NotInteractingInteracted'

        if (addressOptionFields[index] === 'postcode' && !errorMessage) {
          fetchDeliveryDates({ variables: { postcode: value, city: city } })
        }

        checkoutPageState({
          ...checkoutPageState(),
          sections
        })

        return ANALYTICS.blurField(
          addressOptionFields[index],
          sections.addressDetails.form[addressOptionFields[index]]
        )
      })

      setShowAddressDropdown(false)
      setShowAddressFields(true)
    },
    // Disabled exhaustive dependencies as we don't want to re-run this effect
    // when the validatePostcode function changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchDeliveryDates, sections]
  )

  const selectAddressResult = useCallback(
    (result) => {
      if (isDevelopment) {
        selectAddress(result, shippingCountryCode)
      }
    },
    [isDevelopment, selectAddress, shippingCountryCode]
  )

  const continueButtonClick = useCallback(() => {
    sections.addressDetails.visible = false
    sections.deliveryDetails.visible = true

    checkoutPageState({
      ...checkoutPageState(),
      sections
    })
  }, [sections])

  const editSection = useCallback(
    (event) => {
      event.preventDefault()

      sections.addressDetails.visible = true

      checkoutPageState({
        ...checkoutPageState(),
        sections
      })
    },
    [sections]
  )

  const enterAddressManuallyCallback = useCallback(() => {
    setShowAddressFields(true)
    ANALYTICS.showAddressFields()
  }, [])

  const handleOnCountryChange = useCallback(() => {
    // We want to reset the user's address entry on country change to ensure
    // they have to enter the correct address which allows us to re-validate
    // their input

    const formFields = ['postcode', 'addressLine1', 'addressLine2', 'city']

    formFields.forEach((fieldName) => {
      sections.addressDetails.form[fieldName].value = ''
      sections.addressDetails.form[fieldName].errorMessage = null
      sections.addressDetails.form[fieldName].inputInteractionState =
        'NotInteractingNotInteracted'
    })

    // We want to set the selected selectedPaymentMethod.preselected to true
    // to ensure the first payment method for that country is selected on
    // page load and the state continues to update correctly
    sections.paymentDetails.form.selectedPaymentMethod.preselected = true

    checkoutPageState({
      ...checkoutPageState(),
      sections
    })
  }, [sections])

  useEffect(() => {
    const mappedShippingCountries = filteredActiveShippingCountriesByDomain.map(
      (country) => ({
        ...country,
        id: parseInt(country.id)
      })
    )
    // This check ensures we only use the Fetchify Address Auto-Complete in
    // Production and otherwise use the dummy address options in Development envs
    if (!isDevelopment && useFetchify) {
      // To use Fetchify's Address Auto-Complete component, as per the docs, we need to use the clickToAddress class
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line new-cap, no-new
      new clickToAddress(
        FetchifyParams({
          selectAddress,
          showAddressFields: () => setShowAddressFields(true),
          shippingCountryCode,
          activeShippingCountries: mappedShippingCountries
        })
      )
    }
    // Disabled exhaustive dependencies as we don't want to re-run this effect
    // more than once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDevelopment, shippingCountryCode, selectAddress, useFetchify])

  // Controls the validity of the entire section and
  // dictates whether the user can proceed to the next section
  // or pay for their order
  useEffect(() => {
    // Ensure there are no current error messages present
    const isDeliveryAddressSectionValid = () =>
      addressLine1.errorMessage === null &&
      addressLine2.errorMessage === null &&
      city.errorMessage === null &&
      postcodeIsValid

    // Ensure the form has values present
    // Values can be reset to '' after changing
    // Shipping Country. Look in handleOnCountryChange
    const formHasValuesPresent = () =>
      addressLine1.value.length > 0 &&
      city.value.length > 0 &&
      postcode.value.length > 0

    sections.addressDetails.valid =
      isDeliveryAddressSectionValid() && formHasValuesPresent()

    checkoutPageState({
      ...checkoutPageState(),
      sections
    })
  }, [
    addressLine1.errorMessage,
    addressLine1.value.length,
    addressLine2.errorMessage,
    city.errorMessage,
    city.value.length,
    formHasInputtedData,
    postcode.errorMessage,
    postcode.value,
    postcode.value.length,
    sections,
    postcodeIsValid
  ])

  return (
    <section className="delivery-address-section">
      <Card shadow>
        <div className="card-content">
          <div className="card-title">
            <Text
              namespace={namespace}
              text={`${copyContext}.title`}
              element="h2"
              variant="display20"
              align="left"
              margin={false}
            />
            {!visible && valid && (
              <FlatButton
                variant="yellow200"
                onClick={editSection}
                identifier="Delivery Address Edit"
                screenIdentifier="checkout_page"
                text={{
                  namespace: namespace,
                  text: 'edit_button'
                }}
              />
            )}
          </div>
          {!visible && valid && (
            <Text
              colour="brandBlue400"
              translate={false}
              align="left"
              text={[
                addressLine1.value,
                addressLine2.value,
                city.value,
                postcode.value.toUpperCase()
              ]
                .filter((value) => value.length > 0)
                .map((value) => value.charAt(0).toUpperCase() + value.slice(1))
                .join(', ')}
            />
          )}
          <Expand
            show={visible}
            margin={{ top: 1.6, right: 0, bottom: 0, left: 0 }}
            maxHeight={1200}
            origin="top"
          >
            <>
              <GeoIpWidget
                widgetLocation="checkout"
                onCountryChange={handleOnCountryChange}
                activeShippingCountries={filteredActiveShippingCountriesByDomain.map(
                  (country) => ({
                    ...country,
                    id: parseInt(country.id)
                  })
                )}
              />
              {useFetchify && (
                <>
                  <AddressSearchInputField
                    fieldName="searchTerm"
                    input={searchTerm}
                    // eslint-disable-next-line react/jsx-no-bind
                    onBlur={() =>
                      validateInputField('searchTerm', searchTerm.value)
                    }
                    // eslint-disable-next-line react/jsx-no-bind
                    onChange={(e) =>
                      updateInputField('searchTerm', e.currentTarget.value)
                    }
                    shippingCountryCode={shippingCountryCode}
                  />

                  {showDropdown && showAddressDropdown && (
                    <DeliveryAddressFinder
                      selectAddress={selectAddressResult}
                    />
                  )}
                </>
              )}
              {!showAddressFields && (
                <div>
                  <p className="address-info">
                    {t(`${copyContext}.type_address`)}
                  </p>
                  <button
                    data-testid="checkout-add-address-button"
                    className="btn btn-updated--text enter-address"
                    onClick={enterAddressManuallyCallback}
                    type="button"
                  >
                    <p>{t(`${copyContext}.enter_manually`)}</p>
                  </button>
                </div>
              )}
              {/* eslint-disable react/jsx-no-bind */}
              <div className={useFetchify ? '' : 'input-padding-top'}>
                <StandardInputField
                  dataTestId="checkout-address-line-1-input"
                  fieldName="addressLine1"
                  input={addressLine1}
                  maxCharacterLimit={inputHelpers.addressLineLimit(
                    shippingCountryCode
                  )}
                  onBlur={() =>
                    validateInputField('addressLine1', addressLine1.value)
                  }
                  onChange={(e) =>
                    updateInputField('addressLine1', e.currentTarget.value)
                  }
                  isHidden={!showAddressFields}
                />
              </div>
              <StandardInputField
                fieldName="addressLine2"
                input={addressLine2}
                onBlur={() =>
                  validateInputField('addressLine2', addressLine2.value)
                }
                onChange={(e) =>
                  updateInputField('addressLine2', e.currentTarget.value)
                }
                isHidden={!showAddressFields}
              />
              <StandardInputField
                fieldName="city"
                dataTestId="checkout-city-input"
                input={city}
                onBlur={() => validateInputField('city', city.value)}
                onChange={(e) =>
                  updateInputField('city', e.currentTarget.value)
                }
                isHidden={!showAddressFields}
              />
              <PostcodeInputField
                fieldName="postcode"
                dataTestId="checkout-postcode-input"
                value={postcode}
                onBlur={() => validateInputField('postcode', postcode.value)}
                onChange={(e) =>
                  updateInputField('postcode', e.currentTarget.value)
                }
                shippingCountryCode={shippingCountryCode}
                isHidden={!showAddressFields}
                errorMessage={postcodeErrorMessage}
                loading={postcodeValidationLoading}
                validatePostcode={validatePostcode}
                postcodeIsValid={postcodeIsValid}
              />
              {/* eslint-enable react/jsx-no-bind */}
              <div>
                {shippingCountryCode === remoteAreaRegions && (
                  <p className="address-info">
                    {t(`${copyContext}.remote_area_delivery`)}
                  </p>
                )}
                {/*
                  We need to temporarily hide this tooltip for Germany as our
                  DPD service there currently doesn't support SMS delivery notifications
                */}
                {shippingCountryCode !== 'DE' && (
                  <Tooltip
                    contentClass="checkout__tooltip__courier-instructions"
                    triggerText={t(`${copyContext}.courier_subtitle`)}
                  >
                    <p>{t(`${copyContext}.courier_text_1`)}</p>
                    <p>{t(`${copyContext}.courier_text_2`)}</p>
                  </Tooltip>
                )}
              </div>
              {visible && (
                <div className="checkout__continue-button">
                  <Button
                    typography={{
                      namespace,
                      text: 'continue_button.delivery'
                    }}
                    dataTestId="checkout-continue-to-date-button"
                    disabled={!valid}
                    onClick={continueButtonClick}
                    fullWidth
                    identifier="continue_to_delivery_date"
                    screenIdentifier="checkout_page"
                  />
                </div>
              )}
            </>
          </Expand>
        </div>
      </Card>
    </section>
  )
}

export default DeliveryAddressSection
