// @noflow
import isUndefined from 'lodash/isUndefined'
import React, { useCallback, useMemo } from 'react'

import { Button } from '@/components/elements/atoms/Button'
import { Option } from '@/components/elements/atoms/HybridSelect/HybridSelect'
import QuantitySelector from '@/components/elements/atoms/QuantitySelector/QuantitySelector'
import Text from '@/components/elements/atoms/Text/Text'
import LabelList from '@/components/elements/molecules/LabelList/LabelList'

import STYLES from './LineItem.module.sass'

import { ShopItemFragment } from '../../BoxItem/ShopItem/fragments/__generated__/ShopItemFragment'
import { NonPaymentReason } from '@/types'

import { editShopItemAnalytics } from '../../BoxItem/ShopItem/analytics'
import ProductThumb from '../../ProductThumb/ProductThumb'
import ItemLabels from './ItemLabels'
import ProductPrice from './ProductPrice'

type Props = {
  price: string
  productName: string
  quantity: number
  cloudinaryPath?: string
  discountedPrice: string | null
  isBoostedBox?: boolean
  free?: boolean
  recurring?: boolean
  sizeDescription: string | null
  subscribeSave?: boolean
  grossPrice?: string
}

type EditedProductDetails = {
  productCollectionSlug: string
  size: string
  productVariantId: string
  isRecurring?: boolean
}

type EditableShopItemProps = Props & {
  id: string
  productVariant: ShopItemFragment
  size: string
  sizes: Option[]
  maxQuantity?: number
  processing?: boolean
  requiresPayment?: boolean
  nonPaymentReason: NonPaymentReason | null
  onQuantityChange?: (
    id: string,
    quantity: number,
    editedProductDetails: EditedProductDetails
  ) => void
}

const LineItem = ({
  id,
  size,
  sizes,
  maxQuantity,
  onQuantityChange,
  processing,
  productVariant,
  quantity,
  recurring,
  productName,
  requiresPayment,
  nonPaymentReason,
  cloudinaryPath,
  subscribeSave,
  free,
  isBoostedBox,
  grossPrice,
  price,
  discountedPrice,
  sizeDescription
}: EditableShopItemProps): JSX.Element => {
  const [firstSize] = sizes
  const productSize = sizes.find((s) => s.value === size) || firstSize

  const editedProductDetails = useMemo(
    () => ({
      productCollectionSlug: productVariant.productCollection.slug,
      productVariantId: productVariant.id,
      size: productVariant.name,
      isRecurring: recurring
    }),
    [
      productVariant.productCollection.slug,
      productVariant.id,
      productVariant.name,
      recurring
    ]
  )

  const onQuantityChangeCallback = useCallback(
    (to: number) => {
      if (!isUndefined(onQuantityChange)) {
        onQuantityChange(id, Number(to), editedProductDetails)

        editShopItemAnalytics({
          product: {
            recurring,
            quantity,
            productVariant,
            nonPaymentReason,
            requiresPayment
          },
          finalQuantity: Number(to),
          finalSizeSlug: sizeDescription,
          binSelected: false
        })
      }
    },
    [
      onQuantityChange,
      id,
      editedProductDetails,
      recurring,
      quantity,
      productVariant,
      nonPaymentReason,
      requiresPayment,
      sizeDescription
    ]
  )

  const handleReAddExtra = useCallback(() => {
    onQuantityChangeCallback(1)
  }, [onQuantityChangeCallback])

  return (
    <div>
      <div className={STYLES.flexContainer}>
        <ProductThumb
          image={cloudinaryPath}
          alt={productName}
          size={productSize.text}
          imageSize={{ mobile: 100, desktop: 120 }}
        />

        <div className={STYLES.productDetails}>
          <div className={STYLES.productDetailsFlex}>
            <div
              style={{
                display: 'flex',
                alignContent: 'space-between',
                flexDirection: 'column'
              }}
            >
              <Text
                text={productName}
                margin={false}
                variant="textRegular16"
                translate={false}
                align="left"
              />

              <LabelList>
                <ItemLabels
                  subscribeSave={subscribeSave}
                  recurring={recurring}
                />
              </LabelList>
            </div>

            <ProductPrice
              free={free}
              isBoostedBox={isBoostedBox}
              grossPrice={grossPrice}
              price={price}
              discountedPrice={discountedPrice}
            />
          </div>

          <div className={STYLES.quantityWrapper}>
            {quantity === 0 ? (
              <Button
                typography={{
                  text: 'edit_extras.quantity_add',
                  namespace: 'account'
                }}
                variant="secondary"
                onClick={handleReAddExtra}
                identifier="edit_extras.re_add_extra"
                disabled={processing}
              />
            ) : (
              <QuantitySelector
                identifier="edit_extras.quantity"
                quantity={quantity}
                minValue={0}
                onChange={onQuantityChangeCallback}
                processing={processing}
                maxValue={maxQuantity}
                size={30}
                controlled
                debounced
                disabled={free || processing}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LineItem
