import { useReactiveVar } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router'

import IssueForm from './components/IssueForm'
import ItemSelector from './components/ItemSelector'

import {
  CustomerIssueCategorySubmission_response_fields as EntryField,
  CustomerIssueCategorySubmission_response_fields_CustomerIssueEntryFieldMultifield as Multifield,
  CustomerIssueCategorySubmission_response_fields_CustomerIssueEntryFieldMultifield_fields as MultifieldEntryFields
} from '../../mutations/__generated__/CustomerIssueCategorySubmission'
import { CustomerIssueReportEntriesSubmissionVariables } from '../../mutations/__generated__/CustomerIssueReportEntriesSubmission'
import { SelfResolutionCategories_user_subscription_box_potentialMissingItems as PotentialMissingItems } from '../../queries/__generated__/SelfResolutionCategories'

import {
  entryFieldsState,
  selfResolutionPageState
} from '../../SelfResolutionPage'
import getRouteWithBoxId from '../../helpers/getRouteWithBoxId'
import SelfResolutionRoutes from '../../types/routes'
import ContactCustomerLoveDetails from '../ContactCustomerLove/ContactCustomerLoveDetails'

type Props = {
  namespace: string
  returnPath: string
  reportIssue: (
    variables: CustomerIssueReportEntriesSubmissionVariables,
    affectedItems?: Array<PotentialMissingItems>
  ) => void
}

const ReportIssue = ({
  namespace,
  returnPath,
  reportIssue
}: Props): JSX.Element | null => {
  const [formType, setFormType] = useState<'issuesForm' | 'itemSelector'>(
    'issuesForm'
  )
  const [entryFields, setEntryFields] = useState<Array<EntryField>>([])
  const [multifields, setMultifields] = useState<Array<MultifieldEntryFields>>(
    []
  )
  const entryFieldsData = useReactiveVar(entryFieldsState)
  const selfResolutionState = useReactiveVar(selfResolutionPageState)
  const navigate = useNavigate()

  const setPageTitle = () => {
    selfResolutionPageState({
      ...selfResolutionState,
      pageTitle: null
    })
  }

  const sortEntryFields = () => {
    setEntryFields(
      entryFieldsData.entryFields.sort((a, b) => {
        if (a.presentationalOrder < b.presentationalOrder) {
          return -1
        } else {
          return 1
        }
      })
    )
  }

  const checkFormType = () => {
    const multifield = entryFieldsData.entryFields.find(
      (field) => field.dataType === 'multifield'
    ) as Multifield
    if (multifield) {
      setFormType('itemSelector')
      setMultifields(multifield.fields)
    }
  }

  useEffect(() => {
    setPageTitle()
    sortEntryFields()
    checkFormType()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!entryFields || entryFields.length === 0) {
    return (
      <ContactCustomerLoveDetails
        pageName={'Resolution offering'}
        namespace={namespace}
      />
    )
  }

  if (entryFieldsData.entryFields.length === 0) {
    return <Navigate to={getRouteWithBoxId(SelfResolutionRoutes.Categories)} />
  }

  if (!selfResolutionState.showBackButton) {
    navigate(returnPath, { replace: true })
  }

  if (formType === 'issuesForm') {
    return (
      <IssueForm
        namespace={namespace}
        entryFields={entryFields}
        userId={selfResolutionState.userId}
        reportId={selfResolutionState.reportId}
        reasons={entryFieldsData.reasons}
        reportIssue={reportIssue}
      />
    )
  } else {
    return (
      <ItemSelector
        userId={selfResolutionState.userId}
        reportId={selfResolutionState.reportId}
        fieldId={entryFields[0].id}
        namespace={namespace}
        potentialMissingItems={selfResolutionState.box.potentialMissingItems}
        entryFields={multifields}
        reportIssue={reportIssue}
      />
    )
  }
}

export { Props }
export default ReportIssue
