import { gql } from '@apollo/client'

import {
  BOX_ORDER_FRAGMENT,
  NON_CORE_ORDER_FRAGMENT
} from '@/components/pages/Dashboard/components/upcomingBoxes/queries/fragments'

const DELIVERY_DATE_UPDATE = gql`
  mutation DeliveryDateUpdate(
    $userId: ID!
    $boxId: ID!
    $selectedDeliveryDate: ISO8601Date!
    $adjustFutureBoxDates: Boolean!
    $triggerEvents: Boolean
  ) {
    boxDeliveryDateUpdate(
      userId: $userId
      boxId: $boxId
      date: $selectedDeliveryDate
      adjustFutureBoxDates: $adjustFutureBoxDates
      triggerEvents: $triggerEvents
    ) {
      subscription {
        id
        box(boxId: $boxId) {
          id
          isoDeliveryDate
          editable
        }
      }
    }
  }
`

const DELIVERY_DATE_QUERY = gql`
  ${BOX_ORDER_FRAGMENT}
  ${NON_CORE_ORDER_FRAGMENT}
  query DeliveryDateQuery(
    $boxId: ID!
    $from: ISO8601Date
    $to: ISO8601Date
    $nextNBoxes: Int!
  ) {
    user {
      id
      shippingCountryCode
      dogs {
        id
        name
      }
      shippingCountry {
        id
        showUpcomingOrderSeeMoreExtras
      }
      ordersByDate(from: $from, to: $to) {
        ...upcomingBoxOrderFragment
        ...nonCoreUpcomingOrderFragment
      }
      subscription {
        id
        nextNBoxes(num: $nextNBoxes) {
          id
          isNextEditableBox
          isoDeliveryDate
          durationInDays
          editable
        }
        box(boxId: $boxId) {
          id
          isoDeliveryDate
          address {
            id
            recipientName
            address1
            address2
            city
            postcode
          }
          editable
        }
      }
    }
  }
`

export { DELIVERY_DATE_QUERY, DELIVERY_DATE_UPDATE }
