// @noflow
import { intervalToDuration, isFuture } from 'date-fns'
import React, { Fragment, useEffect, useState } from 'react'

import * as Sentry from '@/utils/sentry'

import { BrandColours } from '@/constants/BrandColours'

import Text, { AllowedColours } from '@/components/elements/atoms/Text/Text'
import type { Props as TextProps } from '@/components/elements/atoms/Text/Text'

import STYLES from './Countdown.module.sass'

import CountdownOneLine from './CountdownOneLine/CountdownOneLine'

type BackgroundColour = keyof Pick<
  BrandColours,
  'brandYellow100' | 'brandYellow200'
>

type Props = {
  targetDate: string | Date | undefined
  labels: {
    daysLabel: string
    hoursLabel: string
    minutesLabel: string
    secondsLabel: string
  }
  countdownType?: 'dailyCountdown' | 'weeklyCountdown'
  labelVariant?: TextProps['variant']
  numberVariant?: TextProps['variant']
  shouldScale?: boolean
  variant?: 'small' | 'default' | 'oneLine'
  textColour?: AllowedColours
  namespace: string
  backgroundColour?: BackgroundColour
}

// Converts single digit numbers into to digits with a leading 0 ('01', '05', '10')
// And then turns each digit into an array ['0', '1']
const intervalToArray = (num: number | undefined): Array<string> => {
  if (num !== undefined) {
    return num.toString().padStart(2, '0').split('')
  } else {
    Sentry.captureException('num given to intervalToArray is undefined')
    return ['0', '0']
  }
}

const StandardCountdown = ({
  targetDate,
  labels,
  countdownType = 'dailyCountdown',
  labelVariant,
  numberVariant,
  shouldScale,
  variant,
  textColour,
  namespace,
  backgroundColour = 'brandYellow200'
}: Props): JSX.Element | null => {
  const [currentDate, setCurrentDate] = useState(new Date())

  const [countdownDate, setCountdownDate] = useState<Date>(new Date())

  useEffect(() => {
    if (isFuture(countdownDate)) {
      const tick = setInterval(() => {
        setCurrentDate(new Date())
      }, 1000)

      return () => clearInterval(tick)
    }
  })

  useEffect(() => {
    if (!targetDate) return
    const date = new Date(targetDate)
    setCountdownDate(date)
  }, [targetDate])

  if (!targetDate) return null

  const interval = intervalToDuration({
    start: currentDate,
    end: countdownDate
  })

  const zeroDays = interval.days === 0

  const dailyCountdownItems = [
    {
      label: labels.hoursLabel,
      value: intervalToArray(interval.hours),
      plural: interval.hours
    },
    {
      label: labels.minutesLabel,
      value: intervalToArray(interval.minutes),
      plural: interval.minutes
    },
    {
      label: labels.secondsLabel,
      value: intervalToArray(interval.seconds),
      plural: interval.seconds
    }
  ]
  const weeklyCountdownItems = [
    {
      label: labels.daysLabel,
      value: intervalToArray(interval.days),
      plural: interval.days
    },
    {
      label: labels.hoursLabel,
      value: intervalToArray(interval.hours),
      plural: interval.hours
    },
    {
      label: labels.minutesLabel,
      value: intervalToArray(interval.minutes),
      plural: interval.minutes
    }
  ]
  const countdownItems = zeroDays ? dailyCountdownItems : weeklyCountdownItems

  const isWeeklyCountdown = countdownType === 'weeklyCountdown'

  return (
    <div
      className={`
      ${STYLES.countdown}
      ${countdownType ? STYLES[countdownType] : ''}
      ${variant === 'small' ? STYLES.small : ''}
      ${variant === 'default' ? STYLES.default : ''}`}
    >
      {countdownItems.map((item, index) => (
        <Fragment key={item.label}>
          <div
            className={`
            ${STYLES.countdownItem}
            ${STYLES[backgroundColour]}`}
          >
            <div className={STYLES.countdownItemDigits}>
              {item.value.map((value, index) => (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${value}-${index}`}
                  className={STYLES.countdownItemDigit}
                >
                  <Text
                    text={value}
                    margin={false}
                    variant={
                      numberVariant ||
                      (isWeeklyCountdown ? 'display36' : 'textRegular14')
                    }
                    translate={false}
                    colour={
                      textColour ||
                      (isWeeklyCountdown ? 'brandBlue500' : 'brandWhite')
                    }
                    bold
                    shouldScale={shouldScale}
                  />
                </div>
              ))}
            </div>
            <div className={STYLES.countdownItemLabel}>
              <Text
                text={item.label}
                margin={false}
                variant={
                  labelVariant ||
                  (isWeeklyCountdown ? 'display16' : 'textRegular14')
                }
                colour={
                  textColour ||
                  (isWeeklyCountdown ? 'brandBlue500' : 'brandWhite')
                }
                shouldScale={shouldScale ?? isWeeklyCountdown}
                namespace={namespace}
                variables={{ count: item.plural === 0 ? 1 : item.plural }}
              />
            </div>
          </div>
          {index !== countdownItems.length - 1 &&
            !isWeeklyCountdown &&
            variant !== 'default' && (
              <div className={STYLES.countdownItem}>
                <Text
                  text=":"
                  margin={false}
                  variant={numberVariant || 'textRegular16'}
                  translate={false}
                  colour={textColour || 'brandWhite'}
                  bold
                  shouldScale={shouldScale}
                />
              </div>
            )}
        </Fragment>
      ))}
    </div>
  )
}

const Countdown = ({ variant, ...props }: Props): JSX.Element | null => {
  if (variant === 'oneLine') {
    return <CountdownOneLine {...props} />
  } else {
    return <StandardCountdown {...props} />
  }
}

export type { Props }
export default Countdown
export { intervalToArray }
