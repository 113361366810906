/* eslint-disable i18next/no-literal-string */
// @noflow
import { Factory } from 'fishery'

// eslint-disable-next-line no-restricted-imports
import type { Notification } from '@/components/types'

export default Factory.define<Notification>(({ sequence }) => ({
  id: sequence.toString(),
  link: 'http://www.google.com',
  image: {
    src: 'https://placedog.net/500'
  },
  order: sequence,
  text: `Card text ${sequence}`,
  altText: `Some alt text ${sequence}`
}))
