import { ACCOUNT_ROUTES } from '@/routes'
import { useMutation, useReactiveVar } from '@apollo/client'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { dogsDataVar, subscriptionDataVar } from '@/services/apollo'

import * as Sentry from '@/utils/sentry'

// Utils
import BREAKPOINTS from '@/constants/Breakpoints'

// Hooks
import useWindowSize from '@/hooks/useWindowSize'

// Assets
import BigOlChomper1 from 'assets/images/illustrations/munches/big-ol-chomper-1--yellow200.svg'
import BigOlChomper2 from 'assets/images/illustrations/munches/big-ol-chomper-2--yellow200.svg'
import LittleNibbler from 'assets/images/illustrations/munches/little-nibbler--yellow200.svg'

// Components
import AlertCard from '@/components/elements/atoms/Alert/AlertCard'
import BoostBackgroundStars from '@/components/elements/atoms/BoostBackgroundStars/BoostBackgroundStars'
import { Button } from '@/components/elements/atoms/Button'
import Image from '@/components/elements/atoms/Image/Image'
import Text from '@/components/elements/atoms/Text/Text'
import IconButton from '@/components/elements/molecules/IconButton/IconButton'
import SuccessOverlay from '@/components/elements/organisms/SuccessOverlay/SuccessOverlay'
import { initVariables } from '@/components/pages/App/App'
import { UPCOMING_BOX_QUERY_VARIABLES } from '@/components/pages/Dashboard/components/upcomingBoxes/UpcomingBoxes'

import STYLES from './BoostEnrollPage.module.sass'

import { ACTIVATE_BOOST_MEMBERSHIP } from './mutations/activateMembership'

import {
  ActivateMembership,
  ActivateMembershipVariables
} from './mutations/__generated__/ActivateMembership'
import { ArchivedType } from '@/types'

const namespace = 'organisms'
const copyContext = 'boost_splash_modal_V3'

type MunchIllustration = {
  image: string
  class: string
  alt: string
}

const BoostEnrollPage = (): JSX.Element => {
  const { t } = useTranslation('account')
  const { t: tOrganisms } = useTranslation('organisms')
  const { t: tPlans } = useTranslation('simplified_plans_flow')
  const { windowWidth } = useWindowSize()

  const navigate = useNavigate()
  const isMobile = windowWidth < BREAKPOINTS.md

  const subscriptionData = useReactiveVar(subscriptionDataVar)
  const { dogs = [] } = useReactiveVar(dogsDataVar) ?? {}

  const [dog] = dogs ?? []

  const [showSuccess, setShowSuccess] = useState(false)
  const [showDismiss, setShowDismiss] = useState(false)

  const munches: Array<MunchIllustration> = [
    {
      image: LittleNibbler,
      class: STYLES.littleNibbler,
      alt: 'little_nibble_alt'
    },
    {
      image: BigOlChomper1,
      class: STYLES.bigOlChomper1,
      alt: 'big_ol_chomper_alt'
    },
    {
      image: BigOlChomper2,
      class: STYLES.bigOlChomper2,
      alt: 'big_ol_chomper_alt'
    },
    {
      image: BigOlChomper1,
      class: STYLES.bigOlChomper3,
      alt: 'big_ol_chomper_alt'
    }
  ]

  const handleCompleted = useCallback(
    (callback) => {
      setTimeout(() => {
        setShowSuccess(true)
        callback()
      }, 300)
    },
    [setShowSuccess]
  )

  const goToShop = useCallback(() => {
    setTimeout(() => {
      window.location.href = ACCOUNT_ROUTES.extras
    }, 2000)
  }, [])

  const [activateBoostMembershipMutation, { loading: mutationLoading }] =
    useMutation<ActivateMembership, ActivateMembershipVariables>(
      ACTIVATE_BOOST_MEMBERSHIP,
      {
        variables: {
          ...UPCOMING_BOX_QUERY_VARIABLES,
          nextNBoxes: initVariables.nextNBoxes,
          archivedType: ArchivedType.all
        },
        onCompleted: (data) => {
          handleCompleted(goToShop)

          if (
            data.createMembership?.user.subscription.activeBoostedMembership
              ?.isActive
          ) {
            /**
             * Displaying the boost logo in the header relies on the subscriptionDataVar
             * Wait for this to update before redirecting
             */
            subscriptionDataVar({
              ...subscriptionData,
              // @ts-expect-error setting already defined properties of `activeBoostedMembership` an allowed nullable field
              activeBoostedMembership: {
                ...subscriptionData?.activeBoostedMembership,
                isActive: true
              }
            })
          }
        },
        onError: (error) => {
          Sentry.captureException(
            `Error encountered in ACTIVATE_BOOST_MEMBERSHIP`,
            {
              extra: { error },
              tags: {
                product: Sentry.Product.Account,
                team: Sentry.Team.Retention
              }
            }
          )

          setShowDismiss(true)
          setTimeout(() => navigate(ACCOUNT_ROUTES.base), 5000)
        }
      }
    )

  const handleButtonClick = useCallback(() => {
    activateBoostMembershipMutation()
  }, [activateBoostMembershipMutation])

  const handleCloseClick = useCallback(() => {
    navigate(ACCOUNT_ROUTES.base)
  }, [navigate])

  return (
    <div className={STYLES.container}>
      <div className={STYLES.upper}>
        <BoostBackgroundStars />
        <div className={STYLES.close}>
          <IconButton
            icon="close"
            iconColor="brandBlue400"
            shape="circle"
            size={48}
            variant="brandYellow100"
            iconSize={0.4}
            onClick={handleCloseClick}
            disabled={mutationLoading}
          />
        </div>
        <section className={STYLES.header}>
          <div className={STYLES.brandLogo}>
            <Image
              alt={t('navigation_logo_alt')}
              slug="account-logo"
              image={{
                height: 100,
                width: 124
              }}
            />
          </div>
          <Text
            margin={false}
            variant={isMobile ? 'display24' : 'display28'}
            shouldScale={false}
            variables={{ dogName: dog?.name }}
            namespace={namespace}
            text={`${copyContext}.header`}
          />
          <div className={STYLES.containerBottom}>
            {munches.map((munch, index) => (
              <img
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={munch.class}
                src={munch.image}
                alt={tPlans(`recipes.illustrations.${munch.alt}`)}
              />
            ))}
          </div>
        </section>
      </div>
      <div className={STYLES.contentWrapper}>
        <div className={STYLES.image}>
          <Image
            alt={tOrganisms(`${copyContext}.img_alt`)}
            slug="boosted-aps-only"
            image={{
              width: windowWidth >= BREAKPOINTS.md ? 520 : 270,
              height: windowWidth >= BREAKPOINTS.md ? 318 : 165
            }}
          />
        </div>
        <Text
          margin={false}
          variant={isMobile ? 'textRegular14' : 'textRegular18'}
          shouldScale={false}
          namespace={namespace}
          text={`${copyContext}.body`}
          variables={{ dogName: dog?.name }}
          align="center"
        />
        <Button
          disabled={mutationLoading}
          onClick={handleButtonClick}
          variant="primary"
          typography={{
            namespace,
            text: `${copyContext}.submit_button`
          }}
          screenIdentifier="Boost Onboarding Page v3"
          identifier="boost-my-box-v3"
          fullWidth
        />
        {showDismiss && (
          <div className={STYLES.alertCard}>
            <AlertCard
              variant="error"
              message={{ namespace, text: 'boost_splash_modal.submit_error' }}
            />
          </div>
        )}
      </div>
      {showSuccess && (
        <SuccessOverlay
          illustration={{
            slug: 'boosted-aps-only-success',
            alt: tOrganisms(`${copyContext}.img_alt_success`),
            image: {
              width: 239,
              height: 236
            }
          }}
          typography={{
            text: `${copyContext}.success_copy`,
            margin: false,
            namespace
          }}
        />
      )}
    </div>
  )
}

export default BoostEnrollPage
