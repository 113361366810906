import { ACCOUNT_ROUTES } from '@/routes'
import { useQuery } from '@apollo/client'
import Grid from '@mui/material/Grid'
import * as Sentry from '@sentry/browser'
import React, { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import useWindowSize from '@/hooks/useWindowSize'

import { BREAKPOINTS } from '@/components/pages/App/App'

import { PLAN_DETAILS_QUERY } from './queries/queries'

import {
  PlanDetailsQuery,
  PlanDetailsQueryVariables
} from './queries/__generated__/PlanDetailsQuery'
import { TypeOfPlan } from '@/types'

import { CardSkeleton } from '../../atoms/Card/CardSkeleton'
import FlatButton from '../../atoms/FlatButton/FlatButton'
import { SectionWrapper } from '../../atoms/SectionWrapper'
import IconCard from '../IconCard/IconCard'

const PlanDetails = (): JSX.Element => {
  const namespace = 'molecules'
  const { windowWidth } = useWindowSize()
  const isMobile = windowWidth < BREAKPOINTS.sm
  const navigate = useNavigate()
  const boxId = useParams().boxId || ''
  const { data, loading, error } = useQuery<
    PlanDetailsQuery,
    PlanDetailsQueryVariables
  >(PLAN_DETAILS_QUERY, {
    variables: {
      boxId
    }
  })

  const changePlan = useCallback(
    () => navigate(`${ACCOUNT_ROUTES.planManagement}`),
    [navigate]
  )

  if (error)
    Sentry.captureException('Error with PlanDetailsQuery', {
      extra: {
        error,
        message: error.message
      }
    })
  const {
    user: {
      subscription: { box },
      dogs
    }
  } = data || { user: { subscription: { box: null } } }

  const {
    editable,
    plan: { pouchSize, numberOfPouches, durationInDays, typeOfPlanForCustomer }
  } = box || {
    editable: false,
    plan: {
      numberOfPouches: 0,
      pouchSize: 0,
      durationInDays: 0
    }
  }

  const planType =
    typeOfPlanForCustomer &&
    [(TypeOfPlan.half, TypeOfPlan.some)].includes(typeOfPlanForCustomer)
      ? 'mixed'
      : 'all'

  const context = useMemo(() => {
    if (!dogs || dogs.length === 0) return undefined
    if (dogs.length === 1) return dogs[0].gender
    return 'plural'
  }, [dogs])

  const changePlanProps = useCallback(
    (cardName: string) => {
      return editable
        ? {
            onClick: changePlan,
            // eslint-disable-next-line i18next/no-literal-string
            identifier: `box_details.plan_details.${cardName}`
          }
        : {}
    },
    [editable, changePlan]
  )

  return (
    <div
      style={{ maxWidth: '85.5rem', marginLeft: 'auto', marginRight: 'auto' }}
    >
      <SectionWrapper
        margin={{
          top: 0,
          bottom: 0
        }}
        headerTypography={
          typeOfPlanForCustomer
            ? {
                variant: 'display16',
                element: 'div',
                namespace,
                text: 'plan_details.title.' + planType,
                variables: {
                  context
                },
                translate: true
              }
            : undefined
        }
        headerRightAdornment={
          editable
            ? {
                variant: 'custom',
                content: (
                  <FlatButton
                    text={{
                      text: 'plan_details.edit',
                      namespace,
                      translate: true
                    }}
                    onClick={changePlan}
                    identifier="box_details.edit_meals"
                  />
                )
              }
            : undefined
        }
      >
        <Grid
          container
          item
          alignItems="stretch"
          columnGap={{ xs: 1, md: 2 }}
          xs
        >
          {/* Pouch size */}
          <Grid item xs alignItems="stretch" key="pouch-size">
            {loading ? (
              <CardSkeleton height={131} />
            ) : (
              <IconCard
                padding={16}
                heading={{
                  text: 'plan_details.pouch_size.value',
                  namespace,
                  translate: true,
                  align: 'center',
                  variables: {
                    volume: pouchSize
                  },
                  colour: editable ? 'brandBlue500' : 'brandBlue400'
                }}
                subtext={{
                  text: 'plan_details.pouch_size.label',
                  align: 'center',
                  namespace,
                  translate: true,
                  colour: editable ? 'brandBlue500' : 'brandBlue400'
                }}
                icon={
                  isMobile
                    ? undefined
                    : {
                        asset: 'pouch',
                        backgroundColour: 'brandYellow200',
                        accentColour: editable ? 'brandBlue500' : 'brandBlue400'
                      }
                }
                direction="column"
                variant={
                  editable
                    ? {
                        border: 'transparent',
                        background: 'transparent',
                        selected: 'transparent'
                      }
                    : {
                        border: 'brandYellow400',
                        background: 'brandYellow200',
                        selected: 'transparent'
                      }
                }
                border={editable ? 'solid' : 'dashed'}
                showArrow={false}
                {...changePlanProps('pouch_size')}
              />
            )}
          </Grid>
          {/* Pouch count */}
          <Grid item xs alignItems="stretch" key="pouch-count">
            {loading ? (
              <CardSkeleton height={131} />
            ) : (
              <IconCard
                padding={16}
                heading={{
                  text: numberOfPouches.toString(),
                  align: 'center',
                  translate: false,
                  colour: editable ? 'brandBlue500' : 'brandBlue400'
                }}
                subtext={{
                  text: 'plan_details.pouch_count.label',
                  align: 'center',
                  namespace,
                  translate: true,
                  colour: editable ? 'brandBlue500' : 'brandBlue400'
                }}
                icon={
                  isMobile
                    ? undefined
                    : {
                        asset: 'boxOpen',
                        backgroundColour: 'brandYellow200',
                        accentColour: editable ? 'brandBlue500' : 'brandBlue400'
                      }
                }
                direction="column"
                variant={
                  editable
                    ? {
                        border: 'transparent',
                        background: 'transparent',
                        selected: 'transparent'
                      }
                    : {
                        border: 'brandYellow400',
                        background: 'brandYellow200',
                        selected: 'transparent'
                      }
                }
                border={editable ? 'solid' : 'dashed'}
                showArrow={false}
                {...changePlanProps('pouch_count')}
              />
            )}
          </Grid>
          {/* Cadence */}
          <Grid item xs alignItems="stretch" key="cadence">
            {loading ? (
              <CardSkeleton height={131} />
            ) : (
              <IconCard
                padding={16}
                heading={{
                  text: 'plan_details.cadence.value',
                  align: 'center',
                  translate: true,
                  namespace,
                  variables: {
                    days: durationInDays
                  },
                  colour: editable ? 'brandBlue500' : 'brandBlue400'
                }}
                subtext={{
                  text: 'plan_details.cadence.label',
                  align: 'center',
                  namespace,
                  translate: true,
                  colour: editable ? 'brandBlue500' : 'brandBlue400'
                }}
                icon={
                  isMobile
                    ? undefined
                    : {
                        asset: 'calendar',
                        backgroundColour: 'brandYellow200',
                        accentColour: editable ? 'brandBlue500' : 'brandBlue400'
                      }
                }
                direction="column"
                variant={
                  editable
                    ? {
                        border: 'transparent',
                        background: 'transparent',
                        selected: 'transparent'
                      }
                    : {
                        border: 'brandYellow400',
                        background: 'brandYellow200',
                        selected: 'transparent'
                      }
                }
                border={editable ? 'solid' : 'dashed'}
                showArrow={false}
                {...changePlanProps('cadence')}
              />
            )}
          </Grid>
        </Grid>
      </SectionWrapper>
    </div>
  )
}

export default PlanDetails
