import { gql } from '@apollo/client'

const PLAN_DETAILS_QUERY = gql`
  query PlanDetailsQuery($boxId: ID!) {
    user {
      id
      dogs {
        id
        gender
      }
      subscription {
        id
        box(boxId: $boxId) {
          id
          editable
          plan {
            id
            typeOfPlanForCustomer
            numberOfPouches
            pouchSize
            durationInDays
          }
        }
      }
    }
  }
`

export { PLAN_DETAILS_QUERY }
