/* eslint-disable jsx-a11y/label-has-for */
// @noflow
import { makeVar, useQuery } from '@apollo/client'
import i18next from 'i18next'
import { camelCase, isNil, isNull, isUndefined } from 'lodash'
import React, { useCallback, useEffect } from 'react'

import { pronounContext } from '@/utils/StringHelper'
import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { Options, formatCurrencyWithDecimal } from '@/utils/currency'
import { percentageValue } from '@/utils/percentage'

import Card from '@/components/elements/atoms/Card/Card'
import Label from '@/components/elements/atoms/Label/Label'
import Separator from '@/components/elements/atoms/Separator/Separator'
import SkeletonParagraph from '@/components/elements/atoms/SkeletonParagraph/SkeletonParagraph'
import SkeletonTitle from '@/components/elements/atoms/SkeletonTitle/SkeletonTitle'
import Text from '@/components/elements/atoms/Text/Text'
import BoxBreakdown from '@/components/elements/molecules/BoxBreakdown/BoxBreakdown'
import LabelList from '@/components/elements/molecules/LabelList/LabelList'
import TextSeparator from '@/components/elements/molecules/TextSeparator/TextSeparator'
import RecipeSelectionToggle from '@/components/elements/organisms/RecipeSelectionToggle/RecipeSelectionToggle'

import STYLES from './DsAppOrderSummary.module.sass'

import { DIRECT_SALES_PRICING_QUERY } from '../../queries/directSalesPaymentQuery'

import type {
  DirectSalesPaymentQuery_directSalesPendingSubscription_discountCode_discountCodeParts as Discount,
  DirectSalesPaymentQuery_directSalesPendingSubscription_pendingSubscription_productVariants_productVariant as ProductVariant,
  DirectSalesPaymentQuery_directSalesPendingSubscription_pendingSubscription_pendingSubscriptionFlavours as Recipes
} from '../../queries/__generated__/DirectSalesPaymentQuery'
import {
  DirectSalesPendingSubscriptionOrderPrices_pendingSubscriptionOrderPrices_additionalProductPrices as AdditionalProductPrices,
  DirectSalesPendingSubscriptionOrderPrices,
  DirectSalesPendingSubscriptionOrderPrices_pendingSubscriptionOrderPrices as FirstOrderPricing
} from '../../queries/__generated__/DirectSalesPendingSubscriptionOrderPrices'

import { State } from '../../PayOnOwnDevicePage'

type ExtraCardProps = {
  namespace: string
  productVariant: ProductVariant
  copyContext: string
  hasDiscount: boolean
  currencyOptions: Options
  prices: AdditionalProductPrices[]
}

type Recipe = {
  themeColour: string
  name: string
  numberOfPouches: number
  pouchSize: number
  thumbnail?: string
}

type BoxInView = 'introBox' | 'afterBox'

const getRecipesFromPayload = (
  recipes: Recipes[],
  pouchSize: number
): Array<Recipe> => {
  return recipes.map((recipe) => ({
    name: recipe.flavour.name,
    themeColour: recipe.flavour.themeColour,
    numberOfPouches: recipe.trialBoxServings,
    thumbnail: recipe.flavour?.thumbnail?.src,
    pouchSize
  }))
}

const ExtraCard = ({
  productVariant,
  namespace,
  currencyOptions,
  hasDiscount,
  copyContext,
  prices
}: ExtraCardProps): JSX.Element => {
  const currentPrices = prices.find((price) => price.id === productVariant.id)
  const oneOffPrice = currentPrices?.oneOffPrice || 0
  const discountedPrice = currentPrices?.discountedOneOffPrice || 0

  const extraName = productVariant.productCollection.name
  return (
    <div
      className={STYLES.orderSummaryItem}
      key={`dog-price-summary-${productVariant.productCollection.name}-key`}
    >
      <Text
        text={extraName}
        translate={false}
        namespace={namespace}
        variant="textRegular16"
        colour="brandBlue500"
        margin={false}
      />
      <Text
        text={
          !hasDiscount
            ? formatCurrencyWithDecimal(oneOffPrice, currencyOptions)
            : `${copyContext}.order_summary.extras.value_discounted`
        }
        namespace={namespace}
        variant="textRegular16"
        colour="brandBlue500"
        margin={false}
        variables={{
          price: formatCurrencyWithDecimal(oneOffPrice, currencyOptions),
          discountedPrice: formatCurrencyWithDecimal(
            discountedPrice,
            currencyOptions
          )
        }}
        translate={hasDiscount}
      />
    </div>
  )
}

const OrderSummarySkeletonLoader = (): JSX.Element => {
  return (
    <div className={STYLES.orderSummarySkeletonWrapper}>
      <div className={STYLES.orderSummarySkeleton}>
        <div className={STYLES.titleContainer}>
          <SkeletonTitle width={300} />
        </div>
        <div className={STYLES.titleContainer}>
          <SkeletonTitle width={125} align="center" margin="1.5rem" />
          <SkeletonTitle width={125} align="center" margin="1.5rem" />
        </div>
        <div className={STYLES.content}>
          <SkeletonParagraph width={300} count={5} height="2rem" />
        </div>
        <div className={STYLES.bottomRow}>
          <div className={STYLES.disclaimer}>
            <SkeletonParagraph
              width={300}
              count={1}
              height="2rem"
              shortLine={false}
              align="center"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

type Amount = {
  amount: number
  firstOrderPricing: FirstOrderPricing | null
}

const totalAmount = makeVar<Amount>({
  amount: 0,
  firstOrderPricing: null
})

const DsAppOrderSummary = ({
  namespace,
  paymentPageState
}: {
  namespace: string
  paymentPageState: State
}): JSX.Element | null => {
  const copyContext = 'plan_steps.review'

  const [loading, setLoading] = React.useState<boolean>(true)

  const [boxInView, setBoxInView] = React.useState<BoxInView>('introBox')

  const introBoxClick = useCallback(() => {
    setBoxInView('introBox')
  }, [])

  const afterBoxClick = useCallback(() => {
    setBoxInView('afterBox')
  }, [])

  const { data, language, targetFirstDeliveryDate } = paymentPageState
  const { directSalesPendingSubscription } = data
  const { pendingSubscription, address, discountCode } =
    directSalesPendingSubscription

  const {
    pendingSubscriptionFlavours,
    productVariants: extraProducts,
    plan: subscriptionPlan
  } = pendingSubscription

  const { pricesPerDayPerDogs, trialLengthInDays, pouchesPerDay } =
    subscriptionPlan

  const countryCode = address?.shippingCountry?.code
  const preferredLanguage = language

  const flavourQuantities = pendingSubscriptionFlavours.reduce(
    (breakdown: { [key: string]: number }, { flavour, trialBoxServings }) => {
      breakdown[camelCase(flavour.slug)] = trialBoxServings
      return breakdown
    },
    {}
  )

  const { data: prices } = useQuery<DirectSalesPendingSubscriptionOrderPrices>(
    DIRECT_SALES_PRICING_QUERY,
    {
      variables: {
        planId: subscriptionPlan.id,
        productVariantIds: extraProducts.map(
          (product) => product.productVariant.id
        ),
        discountCodeId: discountCode?.id || '',
        oneOffProductsPresent: true,
        flavourQuantities: flavourQuantities,
        mealSelectionType: 'default',
        expectedFirstShippingDate: targetFirstDeliveryDate
      }
    }
  )

  useEffect(() => {
    if (paymentPageState?.data && prices?.pendingSubscriptionOrderPrices) {
      setLoading(false)
    }
  }, [paymentPageState?.data, prices?.pendingSubscriptionOrderPrices])

  useEffect(() => {
    if (prices) {
      totalAmount({
        amount: prices.pendingSubscriptionOrderPrices[0].netTotalPrice,
        firstOrderPricing: prices.pendingSubscriptionOrderPrices[0]
      })
    }
  }, [prices])

  if (loading || paymentPageState === null || prices === undefined) {
    return <OrderSummarySkeletonLoader />
  }

  const dogGenders = pendingSubscription?.user?.dogs.map((dog) => dog.gender)
  const dogPronoun = pronounContext(dogGenders, i18next.language)
  const currencyOptions: Options = countryCodeToLocaleCurrency(
    countryCode,
    preferredLanguage
  )

  let discounts: undefined | Discount[]

  if (!isNull(discountCode)) discounts = discountCode?.discountCodeParts

  if (isNil(subscriptionPlan)) return null

  const cadenceInWeeks =
    boxInView === 'introBox'
      ? subscriptionPlan.trialLengthInDays / 7
      : subscriptionPlan.durationInDays / 7
  const singleWeek = cadenceInWeeks === 1
  const dailyServing = subscriptionPlan.pouchSize * pouchesPerDay
  const pouchesPerBox =
    boxInView === 'introBox'
      ? trialLengthInDays * pouchesPerDay
      : subscriptionPlan.numberOfPouches

  const weekPluralityString = singleWeek ? '' : '_plural'
  const hasDiscount = !isNull(discountCode)

  return (
    <div className={`${STYLES.wrapper} ${STYLES.planCardWrapper}`}>
      {/* Box Toggle */}
      <div className={STYLES.boxToggle}>
        <button
          type="button"
          onClick={introBoxClick}
          className={boxInView === 'introBox' ? STYLES.selected : ''}
        >
          <Text
            text={`${copyContext}.box_toggle.intro_box`}
            namespace={namespace}
            variant="display20"
            shouldScale
            margin={false}
            colour={boxInView === 'introBox' ? 'brandBlue500' : 'brandBlue400'}
          />
        </button>
        <button
          type="button"
          onClick={afterBoxClick}
          className={boxInView === 'afterBox' ? STYLES.selected : ''}
        >
          <Text
            text={`${copyContext}.box_toggle.after_box`}
            namespace={namespace}
            variant="display20"
            shouldScale
            margin={false}
            colour={boxInView === 'afterBox' ? 'brandBlue500' : 'brandBlue400'}
          />
        </button>
      </div>

      <div className={STYLES.cardOuter}>
        <Card squared>
          <div className={STYLES.orderDetails}>
            <div className={STYLES.orderTitleContainer}>
              <Text
                text={
                  boxInView === 'introBox'
                    ? `${copyContext}.box_card.title.intro_box`
                    : `${copyContext}.box_card.title.after_box_plural`
                }
                variables={{
                  cadenceInWeeks: cadenceInWeeks
                }}
                namespace={namespace}
                variant="display16"
                colour="brandBlue500"
                shouldScale
              />

              <Text
                text={
                  boxInView === 'introBox'
                    ? `${copyContext}.box_card.subtitle.intro_box`
                    : `${copyContext}.box_card.subtitle.after_box_plural`
                }
                variables={{
                  cadenceInWeeks: cadenceInWeeks,
                  context: dogPronoun
                }}
                namespace={namespace}
                variant="textRegular16"
                colour="brandBlue500"
                shouldScale
              />
            </div>

            <BoxBreakdown
              pouchSize={subscriptionPlan.pouchSize}
              pouches={pouchesPerBox}
              cadence={cadenceInWeeks}
            />

            {boxInView === 'afterBox' && (
              <div className={STYLES.orderTitleContainer}>
                <Text
                  text={`${copyContext}.box_card.box_after_information`}
                  variables={{ context: dogPronoun }}
                  namespace={namespace}
                  variant="textRegular16"
                  colour="brandBlue500"
                  shouldScale
                />
              </div>
            )}

            {boxInView === 'introBox' && (
              <RecipeSelectionToggle
                recipes={getRecipesFromPayload(
                  pendingSubscriptionFlavours,
                  dailyServing
                )}
                dogPronoun={dogPronoun}
                namespace={namespace}
              />
            )}
          </div>

          <TextSeparator
            text={`${copyContext}.box_card.divider`}
            variables={{ context: dogPronoun }}
            namespace={namespace}
            info
          />

          {boxInView === 'afterBox' && (
            <React.Fragment>
              <div className={STYLES.orderSummary}>
                <div
                  className={`${STYLES.orderSummaryItem} ${STYLES.orderSummaryTitle}`}
                >
                  <Text
                    text={`${copyContext}.second_box_summary.title`}
                    namespace={namespace}
                    variant="display16"
                    colour="brandBlue500"
                    shouldScale
                  />
                  {/* Handle displaying of discount */}
                  {!isUndefined(discounts) && (
                    <LabelList alignment="right">
                      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                      <Label
                        variant="discount"
                        text={{
                          text:
                            discounts[0].discountBasis === 'percentage'
                              ? `${copyContext}.order_summary.discount_label.percentage`
                              : `${copyContext}.order_summary.discount_label.value`,
                          variables: {
                            value:
                              discounts[0].discountBasis === 'percentage'
                                ? discounts[0].value
                                : formatCurrencyWithDecimal(
                                    discounts[0].value,
                                    currencyOptions
                                  )
                          },
                          namespace
                        }}
                      />
                    </LabelList>
                  )}
                </div>
                <div className={STYLES.orderSummaryItems}>
                  <div className={STYLES.orderSummaryItem}>
                    <Text
                      text={`${copyContext}.second_box_summary.plan_daily_cost.label`}
                      namespace={namespace}
                      variant="textRegular16"
                      colour="brandBlue500"
                      margin={false}
                      shouldScale
                    />
                    <Text
                      text={`${copyContext}.second_box_summary.plan_daily_cost.value_discounted`}
                      variables={{
                        pricePerDay: formatCurrencyWithDecimal(
                          prices.pendingSubscriptionOrderPrices[2]
                            .netCoreFoodPricePerDay,
                          currencyOptions
                        ),
                        discountedPricePerDay: formatCurrencyWithDecimal(
                          prices.pendingSubscriptionOrderPrices[1]
                            .netCoreFoodPricePerDay,
                          currencyOptions
                        )
                      }}
                      namespace={namespace}
                      variant="textRegular16"
                      colour="brandBlue500"
                      margin={false}
                      shouldScale
                    />
                  </div>
                  <div className={STYLES.orderSummaryItem}>
                    <Text
                      text={`${copyContext}.second_box_summary.plan_weekly_cost.label`}
                      namespace={namespace}
                      variant="textRegular16"
                      colour="brandBlue500"
                      margin={false}
                      shouldScale
                    />
                    <Text
                      text={`${copyContext}.second_box_summary.plan_weekly_cost.value_discounted`}
                      variables={{
                        pricePerWeek: formatCurrencyWithDecimal(
                          prices.pendingSubscriptionOrderPrices[2]
                            .grossCoreFoodPricePerDay * 7,
                          currencyOptions
                        ),
                        discountedPricePerWeek: formatCurrencyWithDecimal(
                          prices.pendingSubscriptionOrderPrices[1]
                            .netCoreFoodPricePerDay * 7,
                          currencyOptions
                        )
                      }}
                      namespace={namespace}
                      variant="textRegular16"
                      colour="brandBlue500"
                      margin={false}
                      shouldScale
                    />
                  </div>
                  <div className={STYLES.orderSummaryItem}>
                    <Text
                      text={`${copyContext}.second_box_summary.plan_total_cost.label`}
                      namespace={namespace}
                      variant="textRegular16"
                      colour="brandBlue500"
                      margin={false}
                      shouldScale
                    />
                    <Text
                      text={`${copyContext}.second_box_summary.plan_total_cost.value_discounted`}
                      variables={{
                        standardPrice: formatCurrencyWithDecimal(
                          prices.pendingSubscriptionOrderPrices[2]
                            .grossCoreFoodPrice,
                          currencyOptions
                        ),
                        discountedPrice: formatCurrencyWithDecimal(
                          prices.pendingSubscriptionOrderPrices[1]
                            .netCoreFoodPrice,
                          currencyOptions
                        )
                      }}
                      namespace={namespace}
                      variant="textRegular16"
                      colour="brandBlue500"
                      margin={false}
                      shouldScale
                    />
                  </div>
                  <Separator />
                  {prices.pendingSubscriptionOrderPrices[0]
                    .totalDiscountedAmount > 0 && (
                    <div className={`${STYLES.orderSummaryItem}`}>
                      <Text
                        text={`${copyContext}.order_summary.discount.label`}
                        namespace={namespace}
                        variant="textRegular16"
                        colour="brandBlue500"
                        margin={false}
                        shouldScale
                      />
                      <Text
                        text={`${copyContext}.order_summary.discount.value`}
                        variables={{
                          discountValue: formatCurrencyWithDecimal(
                            prices.pendingSubscriptionOrderPrices[1]
                              .totalDiscountedAmount,
                            currencyOptions
                          )
                        }}
                        namespace={namespace}
                        variant="textRegular16"
                        colour="brandRed500"
                        margin={false}
                        shouldScale
                      />
                    </div>
                  )}
                  {prices.pendingSubscriptionOrderPrices[2]
                    .grossRecipeSurchargePrice > 0 && (
                    <div className={STYLES.orderSummaryItem}>
                      <Text
                        text={`${copyContext}.order_summary.surcharge_amount.label`}
                        namespace={namespace}
                        variant="textRegular16"
                        colour="brandBlue500"
                        margin={false}
                        shouldScale
                      />
                      <Text
                        text={`${copyContext}.second_box_summary.surcharge_amount.price`}
                        variables={{
                          standardPrice: formatCurrencyWithDecimal(
                            prices.pendingSubscriptionOrderPrices[2]
                              .grossRecipeSurchargePrice,
                            currencyOptions
                          ),
                          discountedPrice: formatCurrencyWithDecimal(
                            prices.pendingSubscriptionOrderPrices[1]
                              .netRecipeSurchargePrice,
                            currencyOptions
                          )
                        }}
                        namespace={namespace}
                        variant="textRegular16"
                        colour="brandBlue500"
                        margin={false}
                        shouldScale
                      />
                    </div>
                  )}
                  <div className={STYLES.orderSummaryItem}>
                    <Text
                      text={`${copyContext}.second_box_summary.delivery.label`}
                      namespace={namespace}
                      variant="textRegular16"
                      colour="brandBlue500"
                      margin={false}
                      shouldScale
                    />
                    <Text
                      text={`${copyContext}.second_box_summary.delivery.value`}
                      variables={{
                        deliveryFee: formatCurrencyWithDecimal(
                          prices.pendingSubscriptionOrderPrices[1]
                            .netDeliverySurchargePrice,
                          currencyOptions
                        )
                      }}
                      namespace={namespace}
                      variant="textRegular16"
                      colour="brandBlue500"
                      margin={false}
                      shouldScale
                    />
                  </div>
                  <div className={STYLES.orderSummaryItem}>
                    <Text
                      text={`${copyContext}.order_summary.box_total.label`}
                      namespace={namespace}
                      variant="display16"
                      colour="brandBlue500"
                      margin={false}
                      shouldScale
                    />
                    <Text
                      text={`${copyContext}.order_summary.box_total.value`}
                      variables={{
                        totalPrice: formatCurrencyWithDecimal(
                          prices.pendingSubscriptionOrderPrices[1]
                            .netTotalPrice,
                          currencyOptions
                        )
                      }}
                      namespace={namespace}
                      variant="display16"
                      colour="brandBlue500"
                      margin={false}
                      shouldScale
                    />
                  </div>
                  {!isUndefined(discounts) && discounts.length >= 2 && (
                    // eslint-disable-next-line jsx-a11y/label-has-for
                    <Label
                      variant="secondBoxDiscount"
                      text={{
                        namespace,
                        text: `${copyContext}.order_summary.second_discount.label`,
                        variables: {
                          value:
                            discounts[1].discountBasis === 'percentage'
                              ? percentageValue(discounts[1].value)
                              : formatCurrencyWithDecimal(
                                  discounts[1].value,
                                  currencyOptions
                                )
                        }
                      }}
                    />
                  )}
                </div>
              </div>
              <div className={STYLES.orderTitleContainer}>
                <Text
                  text={`${copyContext}.second_box_summary.after_second_box.label`}
                  namespace={namespace}
                  variant="display16"
                  colour="brandBlue500"
                  shouldScale
                />
                <div className={STYLES.afterDetails}>
                  <Text
                    text={`${copyContext}.box_card.box_after_delivery_information${weekPluralityString}`}
                    variables={{
                      cadenceInWeeks: cadenceInWeeks,
                      dailyPrice: formatCurrencyWithDecimal(
                        prices.pendingSubscriptionOrderPrices[2]
                          .grossTotalPrice,
                        currencyOptions
                      ),
                      deliveryPrice: formatCurrencyWithDecimal(
                        prices.pendingSubscriptionOrderPrices[2]
                          .netDeliverySurchargePrice,
                        currencyOptions
                      )
                    }}
                    namespace={namespace}
                    variant="textRegular16"
                    colour="brandBlue500"
                    shouldScale
                  />
                </div>
              </div>
            </React.Fragment>
          )}
          {/* Order Summary */}
          {boxInView === 'introBox' &&
            !isUndefined(
              prices.pendingSubscriptionOrderPrices[0].netTotalPricePerDay
            ) && (
              <div className={STYLES.orderSummary}>
                <div
                  className={`${STYLES.orderSummaryItem} ${STYLES.orderSummaryTitle}`}
                >
                  <Text
                    text={`${copyContext}.order_summary.title`}
                    namespace={namespace}
                    variant="display16"
                    colour="brandBlue500"
                    shouldScale
                  />
                  {/* Handle displaying of discount */}
                  {!isUndefined(discounts) && (
                    <LabelList alignment="right">
                      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                      <Label
                        variant="discount"
                        text={{
                          text:
                            discounts[0].discountBasis === 'percentage'
                              ? `${copyContext}.order_summary.discount_label.percentage`
                              : `${copyContext}.order_summary.discount_label.value`,
                          variables: {
                            value:
                              discounts[0].discountBasis === 'percentage'
                                ? discounts[0].value
                                : formatCurrencyWithDecimal(
                                    discounts[0].value,
                                    currencyOptions
                                  )
                          },
                          namespace
                        }}
                      />
                    </LabelList>
                  )}
                </div>
                <div className={STYLES.orderSummaryItems}>
                  {pricesPerDayPerDogs.map((dog) => (
                    <div
                      className={STYLES.orderSummaryItem}
                      key={`dog-price-summary-${dog.name}-key`}
                    >
                      <Text
                        text={`${copyContext}.order_summary.intro_price.label`}
                        variables={{
                          dogName: dog.name
                        }}
                        namespace={namespace}
                        variant="textRegular16"
                        colour="brandBlue500"
                        margin={false}
                        shouldScale
                      />
                      <Text
                        text={
                          prices.pendingSubscriptionOrderPrices[0]
                            .totalDiscountedAmount > 0
                            ? `${copyContext}.order_summary.intro_price.value_discounted`
                            : `${copyContext}.order_summary.intro_price.value`
                        }
                        variables={{
                          pricePerDay: formatCurrencyWithDecimal(
                            dog.pricePerDayWithoutDiscount,
                            currencyOptions
                          ),
                          discountedPricePerDay: !isUndefined(
                            dog.pricePerDayWithDiscount
                          )
                            ? formatCurrencyWithDecimal(
                                dog.pricePerDayWithDiscount,
                                currencyOptions
                              )
                            : undefined
                        }}
                        namespace={namespace}
                        variant="textRegular16"
                        colour="brandBlue500"
                        margin={false}
                        shouldScale
                      />
                    </div>
                  ))}
                  <div className={STYLES.orderSummaryItem}>
                    <Text
                      text={`${copyContext}.order_summary.plan_total_cost.label`}
                      namespace={namespace}
                      variant="textRegular16"
                      colour="brandBlue500"
                      margin={false}
                      shouldScale
                    />
                    <Text
                      text={`${copyContext}.order_summary.plan_total_cost.value_discounted`}
                      variables={{
                        standardPrice: formatCurrencyWithDecimal(
                          prices.pendingSubscriptionOrderPrices[0]
                            .grossTotalPrice,
                          currencyOptions
                        ),
                        discountedPrice: formatCurrencyWithDecimal(
                          prices.pendingSubscriptionOrderPrices[0]
                            .netTotalPrice,
                          currencyOptions
                        )
                      }}
                      namespace={namespace}
                      variant="textRegular16"
                      colour="brandBlue500"
                      margin={false}
                      shouldScale
                    />
                  </div>
                  <Separator />
                  {prices.pendingSubscriptionOrderPrices[0]
                    .totalDiscountedAmount > 0 && (
                    <div className={`${STYLES.orderSummaryItem}`}>
                      <Text
                        text={`${copyContext}.order_summary.discount.label`}
                        namespace={namespace}
                        variant="textRegular16"
                        colour="brandBlue500"
                        margin={false}
                        shouldScale
                      />
                      <Text
                        text={`${copyContext}.order_summary.discount.value`}
                        variables={{
                          discountValue: formatCurrencyWithDecimal(
                            prices.pendingSubscriptionOrderPrices[0]
                              .totalDiscountedAmount,
                            currencyOptions
                          )
                        }}
                        namespace={namespace}
                        variant="textRegular16"
                        colour="brandRed500"
                        margin={false}
                        shouldScale
                      />
                    </div>
                  )}
                  {prices.pendingSubscriptionOrderPrices[2]
                    .grossRecipeSurchargePrice > 0 && (
                    <div className={STYLES.orderSummaryItem}>
                      <Text
                        text={`${copyContext}.order_summary.surcharge_amount.label`}
                        namespace={namespace}
                        variant="textRegular16"
                        colour="brandBlue500"
                        margin={false}
                        shouldScale
                      />
                      <Text
                        text={
                          prices.pendingSubscriptionOrderPrices[0]
                            .netRecipeSurchargePrice > 0
                            ? `${copyContext}.order_summary.surcharge_amount.price`
                            : `${copyContext}.order_summary.surcharge_amount.free`
                        }
                        variables={{
                          standardPrice: formatCurrencyWithDecimal(
                            prices.pendingSubscriptionOrderPrices[1]
                              .netRecipeSurchargePrice,
                            currencyOptions
                          )
                        }}
                        namespace={namespace}
                        variant="textRegular16"
                        colour="brandBlue500"
                        margin={false}
                        shouldScale
                      />
                    </div>
                  )}
                  {extraProducts.map((extraProduct) => {
                    return (
                      <ExtraCard
                        key={extraProduct.productVariant.id}
                        productVariant={extraProduct.productVariant}
                        namespace={namespace}
                        copyContext={copyContext}
                        hasDiscount={hasDiscount}
                        currencyOptions={currencyOptions}
                        prices={
                          prices.pendingSubscriptionOrderPrices[0]
                            .additionalProductPrices
                        }
                      />
                    )
                  })}
                  <div className={STYLES.orderSummaryItem}>
                    <Text
                      text={`${copyContext}.order_summary.delivery.label_offered`}
                      namespace={namespace}
                      variant="textRegular16"
                      colour="brandBlue500"
                      margin={false}
                      shouldScale
                    />
                    <Text
                      text={
                        prices.pendingSubscriptionOrderPrices[0]
                          .netDeliverySurchargePrice === 0
                          ? `${copyContext}.order_summary.delivery.value_free`
                          : `${copyContext}.order_summary.delivery.value_offered`
                      }
                      variables={{
                        deliveryFee: formatCurrencyWithDecimal(
                          prices.pendingSubscriptionOrderPrices[0]
                            .netDeliverySurchargePrice,
                          currencyOptions
                        )
                      }}
                      namespace={namespace}
                      variant="textRegular16"
                      colour="brandBlue500"
                      margin={false}
                      shouldScale
                    />
                  </div>
                  <div className={STYLES.orderSummaryItem}>
                    <Text
                      text={`${copyContext}.order_summary.box_total.label`}
                      namespace={namespace}
                      variant="display16"
                      colour="brandBlue500"
                      margin={false}
                      shouldScale
                    />
                    <Text
                      text={`${copyContext}.order_summary.box_total.value`}
                      variables={{
                        totalPrice: formatCurrencyWithDecimal(
                          prices.pendingSubscriptionOrderPrices[0]
                            .netTotalPrice,
                          currencyOptions
                        )
                      }}
                      namespace={namespace}
                      variant="display16"
                      colour="brandBlue500"
                      margin={false}
                      shouldScale
                    />
                  </div>
                  {!isUndefined(discounts) && discounts.length >= 2 && (
                    // eslint-disable-next-line jsx-a11y/label-has-for
                    <Label
                      variant="secondBoxDiscount"
                      text={{
                        namespace,
                        text: `${copyContext}.order_summary.second_discount.label`,
                        variables: {
                          value:
                            discounts[1].discountBasis === 'percentage'
                              ? percentageValue(discounts[1].value)
                              : formatCurrencyWithDecimal(
                                  discounts[1].value,
                                  currencyOptions
                                )
                        }
                      }}
                    />
                  )}
                </div>
              </div>
            )}
        </Card>
      </div>
    </div>
  )
}

export default DsAppOrderSummary
export { totalAmount }
