import { Language } from '@/packs/localisation'
import times from 'lodash/times'
import React, { useCallback } from 'react'

// Hooks
import useWindowSize from '@/hooks/useWindowSize'

// Components
import Card from '@/components/elements/atoms/Card/Card'
import Icon from '@/components/elements/atoms/Icon/Icon'
import Text from '@/components/elements/atoms/Text'
import RecipeCard, {
  Recipe,
  SkeletonRecipeCard
} from '@/components/elements/molecules/RecipeCard/RecipeCard'
import SwiperSlider from '@/components/elements/molecules/SwiperSlider/SwiperSlider'
import { BREAKPOINTS } from '@/components/templates/Base'

// Styles
import STYLES from './StarterBoxSection.module.sass'

// Types
import { Code } from '@/types'

import { trackCarouselInteraction } from '../../../../helpers/analytics'
import { Action, TooltipLocation } from '../../../../types/analyticsProperties'

type Props = {
  dogNames: Array<string>
  namespace: string
  copyContext: string
  loading: boolean
  shippingCountryCode: Code
  preferredLanguage: Language
  recipes: Array<Recipe>
  updateRecipes: (selected: boolean, recipe: Recipe) => void
  maxFlavoursReached: boolean
  noRecommendedRecipes: boolean
  handleTooltipOpened: (location: TooltipLocation) => void
  onReadMoreClick?: (slug: string) => void
}

const StarterBoxSection = ({
  dogNames,
  namespace,
  copyContext,
  loading,
  preferredLanguage,
  shippingCountryCode,
  updateRecipes,
  recipes,
  maxFlavoursReached,
  noRecommendedRecipes,
  handleTooltipOpened,
  onReadMoreClick
}: Props): JSX.Element | null => {
  const { windowWidth } = useWindowSize()
  const isDesktop = windowWidth >= BREAKPOINTS.lg

  const handleSliderFirstMove = useCallback(() => {
    trackCarouselInteraction({
      action: Action.onSwipe,
      section: 'starterBox'
    })
  }, [])

  const handleArrowClick = useCallback(() => {
    trackCarouselInteraction({
      action: Action.arrowClicked,
      section: 'starterBox'
    })
  }, [])

  const handleTooltipOnOpen = useCallback(() => {
    handleTooltipOpened(TooltipLocation.starterBox)
  }, [handleTooltipOpened])

  return (
    <div className={STYLES.starterBoxSection}>
      <div className={STYLES.title}>
        <Text
          namespace={namespace}
          text={`${copyContext}.primary_section.starter_box_header`}
          variables={{
            count: dogNames.length,
            dogName: dogNames
          }}
          variant="display20"
          element="h2"
          margin={false}
        />
      </div>
      {noRecommendedRecipes && (
        <div className={STYLES.alert}>
          <Card
            shadow={false}
            border="none"
            padding={0}
            variant="brandYellow100"
          >
            <div className={STYLES.alertContent}>
              <div className={STYLES.alertIconWrapper}>
                <Icon
                  asset="alert"
                  size={isDesktop ? 16 : 14}
                  width={isDesktop ? 16 : 14}
                  accentColour="supportBlue300"
                  backgroundColour="supportBlue100"
                />
              </div>
              <Text
                text={`${copyContext}.secondary_recipes.not_recommended_text`}
                namespace={namespace}
                variables={{
                  dogName: dogNames,
                  count: dogNames.length
                }}
                variant="textRegular14"
                margin={false}
              />
            </div>
          </Card>
        </div>
      )}
      {!noRecommendedRecipes && (
        <div className={STYLES.carouselWrapper}>
          <SwiperSlider
            arrows={windowWidth > BREAKPOINTS.md}
            onSliderFirstMove={handleSliderFirstMove}
            onArrowClick={handleArrowClick}
          >
            {loading
              ? times(5).map((n) => <SkeletonRecipeCard key={n} />)
              : recipes.map((recipe) => {
                  return (
                    <RecipeCard
                      dataTestId="starter-box-recipe-card"
                      key={recipe.name}
                      dogNames={dogNames}
                      loading={loading}
                      namespace={namespace}
                      preferredLanguage={preferredLanguage}
                      recipe={recipe}
                      shippingCountryCode={shippingCountryCode}
                      onSelect={updateRecipes}
                      disableSelection={maxFlavoursReached}
                      tooltipOnOpen={handleTooltipOnOpen}
                      onReadMoreClick={onReadMoreClick}
                    />
                  )
                })}
          </SwiperSlider>
        </div>
      )}
    </div>
  )
}

export default StarterBoxSection
