import { gql } from '@apollo/client'

const SAVE_FRESH_MEALS_UPDATE_QUERY = gql`
  query SaveFreshMealsUpdateQuery {
    user {
      id
      subscription {
        id
        nextNBoxes(num: 3) {
          id
          meals {
            id
            quantity
          }
        }
        meal {
          id
          quantity
        }
      }
    }
  }
`

export { SAVE_FRESH_MEALS_UPDATE_QUERY }
