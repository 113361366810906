// @noflow
import React, { useMemo } from 'react'

import Card from '@/components/elements/atoms/Card/Card'
import SkeletonButton from '@/components/elements/atoms/SkeletonButton/SkeletonButton'
import SkeletonParagraph from '@/components/elements/atoms/SkeletonParagraph/SkeletonParagraph'
import SkeletonTitle from '@/components/elements/atoms/SkeletonTitle/SkeletonTitle'

import STYLES from './DetailsCardSkeleton.module.sass'

type Props = {
  lines?: number
  shortLine?: boolean
  linkPosition?: 'top' | 'center'
}

const DetailsCardSkeleton = ({
  linkPosition = 'top',
  lines = 0,
  shortLine
}: Props): JSX.Element => {
  const linkClasses = useMemo(() => {
    return linkPosition === 'top' ? `${STYLES.link} ${STYLES.top}` : STYLES.link
  }, [linkPosition])
  const titleMargin = useMemo(() => {
    return lines > 0 ? '.8rem 0 1.2rem' : '0'
  }, [lines])
  return (
    <Card>
      <div className={STYLES.container}>
        <div className={STYLES.icon}>
          <SkeletonButton width={'3rem'} height={'3Rem'} />
        </div>
        <div className={STYLES.text}>
          <div className={STYLES.textWrapper}>
            <SkeletonTitle
              align={'left'}
              width={'40%'}
              height={'1.4rem'}
              margin={titleMargin}
            />
            {lines > 0 && (
              <SkeletonParagraph
                count={lines}
                height={'1.8rem'}
                shortLine={shortLine}
                margin={'0 0 .8rem'}
              />
            )}
          </div>
        </div>
        <div className={linkClasses}>
          <SkeletonButton width={'100%'} height={'1.6rem'} />
        </div>
      </div>
    </Card>
  )
}

export { Props }
export default DetailsCardSkeleton
