import { DocumentNode, gql } from '@apollo/client'

import { PENDING_SUBSCRIPTION_ORDER_PRICING_FRAGMENT } from '@/components/pages/CheckoutPage/queries/fragments/PendingSubscriptionOrderPricingFragment'

const CREATE_PENDING_SUBSCRIPTION = gql`
  mutation CreatePendingSubscription(
    $pendingSubscription: PendingSubscriptionInput!
  ) {
    createPendingSubscription(pendingSubscription: $pendingSubscription) {
      user {
        id
      }
      pendingSubscription {
        pendingSubscriptionFlavours {
          flavour {
            name
          }
          sevenDayServings
        }
      }
    }
  }
`

const UPDATE_PLAN = gql`
  mutation UpdatePlan($userId: ID!, $meal: MealInput!, $planId: ID!) {
    mealUpdate(userId: $userId, meal: $meal) {
      flavour {
        name
      }
    }
    subscriptionPlanUpdate(userId: $userId, planId: $planId) {
      id
      subscription {
        plan {
          id
        }
      }
    }
  }
`

// Queries
const GUEST_QUERY = gql`
  fragment recommendedExtraProductVariantsFields on ProductVariant {
    id
    name
    recurringDeliveryType {
      netPrice
    }
    productVariantDeliveryTypes {
      deliveryType
      netPrice
      priceAdjustment {
        value
      }
    }
    productCollection {
      slug
      name
      merchandisingImage {
        src
      }
      productLabel {
        name
        themeColour
      }
    }
    grossPrice
    discountedPrice
  }
  query GuestQuery {
    currentUser {
      __typename
      ... on Guest {
        id
        preferredLanguage
        assumedShippingCountry {
          id
          code
        }
        planRecommendation {
          recommendedExtraProductVariants {
            ...recommendedExtraProductVariantsFields
          }
        }
      }
      ... on User {
        id
        address {
          postcode
        }
        planRecommendation {
          recommendedExtraProductVariants {
            ...recommendedExtraProductVariantsFields
          }
        }
      }
    }
  }
`

const GET_REVIEW_QUERY = (): DocumentNode => {
  return gql`
    ${PENDING_SUBSCRIPTION_ORDER_PRICING_FRAGMENT}
    fragment pendingSubscriptionProductVariantFields on PendingSubscriptionsProductVariant {
      discountedPrice
      productVariant {
        id
        name
        grossPrice
        recurringDeliveryType {
          netPrice
        }
        productVariantDeliveryTypes {
          deliveryType
          netPrice
          priceAdjustment {
            value
          }
        }
        productCollection {
          name
          slug
          merchandisingImage {
            src
          }
        }
      }
    }

    query ReviewQuery {
      standardDeliveryFee
      currentUser {
        __typename
        ... on Guest {
          id
          assumedShippingCountry {
            id
            code
          }
          preferredLanguage
          discountCode {
            id
            discountCodeParts {
              n
              discountType
              discountBasis
              value
            }
          }
          planRecommendation {
            individual {
              dog {
                id
                name
                createdAt
                gender
                dailyGrams
                dogProfile {
                  avatarUrl
                }
              }
            }
          }
        }
        ... on User {
          id
          preferredLanguage
          shippingCountry {
            code
          }
          dogs {
            name
          }
          subscription {
            id
            status
            planPricePerDay
            planPriceTotal
            plan {
              pouchSize
              numberOfPouches
              durationInDays
              pouchesPerDay
              typeOfPlanForCustomer
            }
            meal {
              flavour {
                id
                slug
                name
                thumbnail {
                  src
                }
                themeColour
              }
              quantity
            }
            recurringOrder {
              surchargeTotal
            }
          }
          planRecommendation {
            individual {
              dog {
                id
                name
                createdAt
                gender
                dailyGrams
                dogProfile {
                  avatarUrl
                }
              }
            }
          }
        }
      }
      pendingSubscription {
        standardOrderPricing {
          ...pendingSubscriptionOrderPricingFragment
        }
        firstOrderPricing {
          ...pendingSubscriptionOrderPricingFragment
        }
        secondOrderPricing {
          ...pendingSubscriptionOrderPricingFragment
        }
        trialDiscountCodePart {
          value
        }
        productVariants {
          ...pendingSubscriptionProductVariantFields
        }
        plan {
          id
          numberOfPouches
          durationInDays
          trialLengthInDays
          pouchesPerDay
          pricingCurve {
            pricingCohort
          }
          pricesPerDayPerDogs {
            name
            pricePerDayWithDiscount
            pricePerDayWithoutDiscount
          }
          typeOfPlanForCustomer
        }
        pendingSubscriptionFlavours {
          flavour {
            name
            slug
            themeColour
            thumbnail {
              src
            }
          }
          servings
          trialBoxServings
          sevenDayServings
        }
      }
    }
  `
}

const GET_RECOMMENDATIONS_QUERY = gql`
  fragment planFields on Plan {
    id
    pouchSize
    durationInDays
    pricePerDay
    trialLengthInDays
    trialDiscountedPricePerDay
    numberOfPouches
    typeOfPlanForCustomer
    gramsPerDay
    pricingCurve {
      pricingCohort
    }
  }
  fragment flavourFields on NewFlavour {
    id
    name
    slug
    shortDescription
    longDescription
    tastingNotes
    fussyApproved
    primaryImage {
      src
    }
    productLabels {
      name
      themeColour
    }
    recipeSurcharges {
      planSize
      amount
    }
  }

  query RecommendationQuery {
    flavours {
      productBenefits {
        id
        name
        image {
          src
        }
      }
    }

    currentUser {
      __typename
      ... on Guest {
        id
        preferredLanguage
        assumedShippingCountry {
          id
          code
        }
        discountCode {
          id
          discountCodeParts {
            n
            discountType
            discountBasis
            value
          }
        }
        planRecommendation {
          idealGramsAmount
          combinedRecommendedFlavours {
            ...flavourFields
          }

          combinedOtherFlavours {
            ...flavourFields
          }

          combinedNotRecommendedFlavours {
            ...flavourFields
          }

          plans {
            ...planFields
          }

          recommendationReasons {
            id
            text
          }

          individual {
            dog {
              id
              name
              dailyCalories
              gender
              eaterType
            }
          }
        }
      }
      ... on User {
        id
        preferredLanguage
        shippingCountry {
          code
        }
        planRecommendation {
          idealGramsAmount
          combinedRecommendedFlavours {
            ...flavourFields
          }

          combinedOtherFlavours {
            ...flavourFields
          }

          combinedNotRecommendedFlavours {
            ...flavourFields
          }

          plans {
            ...planFields
          }

          recommendationReasons {
            id
            text
          }

          individual {
            dog {
              id
              name
              dailyCalories
              gender
              eaterType
            }
          }
        }
      }
    }
  }
`

export {
  CREATE_PENDING_SUBSCRIPTION,
  UPDATE_PLAN,
  GUEST_QUERY,
  GET_RECOMMENDATIONS_QUERY,
  GET_REVIEW_QUERY
}
