// @noflow
import React from 'react'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import IconCard, {
  Props
} from '@/components/elements/molecules/IconCard/IconCard'

import BoostBackgroundStars from '../../atoms/BoostBackgroundStars/BoostBackgroundStars'

/**
 * A card with styling for the boost membership which composes the IconCard component
 * @param children
 * @param iconCard : IconCard props (see IconCard for more details)
 * @constructor
 */
const BoostCard = ({ children, ...iconCard }: Props): JSX.Element => {
  const { windowWidth } = useWindowSize()

  return (
    <IconCard
      {...iconCard}
      variant="brandYellow200"
      border="none"
      background={<BoostBackgroundStars />}
      padding={windowWidth >= BREAKPOINTS.md ? 16 : 8}
    >
      {children}
    </IconCard>
  )
}

export { type Props }
export default BoostCard
