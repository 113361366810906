import { gql } from '@apollo/client'

const RECURRING_ORDER_PRODUCTS_UPDATE = gql`
  mutation RecurringOrderProductsUpdate(
    $userId: ID!
    $recurringOrderProducts: [ProductInput!]!
    $num: Int!
  ) {
    recurringOrderProductsUpdate(
      userId: $userId
      recurringOrderProducts: $recurringOrderProducts
    ) {
      id
      nextNBoxes(num: $num) {
        id
        physicalOrderProducts {
          id
          nonPaymentReason
          quantity
          productVariant {
            id
            name
            productCollection {
              id
              name
              thumbnail {
                src
              }
            }
          }
        }
      }
    }
  }
`
export { RECURRING_ORDER_PRODUCTS_UPDATE }
