import { useLanguage, useStripe } from '@/context/injectedValues/injectedValues'
import { Elements as StripeElements } from '@stripe/react-stripe-js'
import { Stripe, loadStripe } from '@stripe/stripe-js'
import React, { useEffect, useState } from 'react'

import { localeToStripeLocale } from '@/utils/localeToStripeLocale'

import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'

import PaymentMethods from './PaymentMethods'
import PaymentMethodsSkeleton from './PaymentMethodsSkeleton'

const PaymentMethodsPage = (): JSX.Element | null => {
  const [stripePromise, setStripePromise] = useState<
    Promise<Stripe | null> | undefined
  >()
  const [loading, setLoading] = useState<boolean>(true)
  const { key } = useStripe()
  const { userLanguage } = useLanguage()

  useEffect(() => {
    if (key) {
      setStripePromise(loadStripe(key))
    }
  }, [key])

  useEffect(() => {
    if (stripePromise) {
      stripePromise.then(() => {
        setLoading(false)
      })
    }
  }, [stripePromise])

  if (loading) {
    return <PaymentMethodsSkeleton />
  }

  if (!stripePromise) {
    return null
  }

  return (
    <SectionWrapper
      headerTypography={{
        text: 'titles.payment_methods',
        namespace: 'account'
      }}
      margin={{ top: 0, bottom: 0 }}
    >
      <StripeElements
        stripe={stripePromise}
        options={{
          locale: localeToStripeLocale(userLanguage || 'en')
        }}
      >
        <PaymentMethods />
      </StripeElements>
    </SectionWrapper>
  )
}

export default PaymentMethodsPage
