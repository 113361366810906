// @noflow
import type { Code as ShippingCountryCode } from '@/shared_types/rails_models/shipping_countries'

import { PaymentMethod } from '../../../types'

type PaymentMethodsAvaliable = {
  [key in ShippingCountryCode]: Array<PaymentMethod>
}

const paymentMethodsAvalible: PaymentMethodsAvaliable = {
  IE: ['creditCard', 'applePay', 'googlePay', 'payPal'],
  NI: ['creditCard', 'applePay', 'googlePay', 'payPal'],
  BE: ['bancontact', 'applePay', 'googlePay', 'creditCard', 'payPal'],
  NL: ['iDeal', 'applePay', 'googlePay', 'creditCard', 'payPal'],
  GB: ['creditCard', 'applePay', 'googlePay', 'payPal'],
  PL: ['creditCard', 'applePay', 'googlePay', 'payPal'],
  DE: ['payPal', 'applePay', 'googlePay', 'creditCard']
}

const findPaymentMethodsAvaliable = (
  countryCode: ShippingCountryCode
): Array<PaymentMethod> => {
  return paymentMethodsAvalible[countryCode]
}

export { findPaymentMethodsAvaliable }
