// @flow

import { formatCurrencyWithDecimal } from '@/utils/currency'
import type { Locale } from '@/utils/countryCodeHelper'
import type { Currency } from '@/shared_types/rails_models/shipping_countries'

type DiscountType =
  |'single_use'
  | 'n_boxes'
  | 'every_nth_box'
  | 'nth_box'

type DiscountBasis =
  |'percentage'
  | 'total'

type DiscountCodePart = {|
  discountBasis: DiscountBasis,
  discountType: DiscountType,
  n: number,
  value: number
|}

type DiscountCode = {|
  discountCodeParts: Array<DiscountCodePart>
|}

const partAppliesToBoxNumber = (part: DiscountCodePart, index: number): boolean => {
  switch (part.discountType) {
    case 'n_boxes': {
      return part.n >= index
    }
    case 'nth_box': {
      return part.n === index
    }
    case 'single_use': {
      return index === 1
    }
    case 'every_nth_box': {
      return index % part.n === 0
    }
    default: {
      (part.discountType: empty) // eslint-disable-line no-unused-expressions
      throw new Error(`Unsupported discount type: ${part.discountType}`)
    }
  }
}

const appliesToBoxNumber = (discountCode: DiscountCode, index: number): boolean => {
  return discountCode.discountCodeParts.some((part: DiscountCodePart): boolean =>
    partAppliesToBoxNumber(part, index)
  )
}

const discountedPriceWithPart = (part: DiscountCodePart, originalPrice: number): number => {
  switch (part.discountBasis) {
    case 'percentage':
      return Math.round(originalPrice * (1 - part.value / 100))
    case 'total':
      return Math.max(Math.floor(originalPrice - part.value), 0)
    default:
      (part.discountBasis: empty) // eslint-disable-line no-unused-expressions
      throw new Error(`Unknown discount basis: ${part.discountBasis}`)
  }
}

const formattedPartValue = (part: DiscountCodePart, locale: Locale, currency: Currency): string => {
  switch (part.discountBasis) {
    case 'total': {
      return formatCurrencyWithDecimal(part.value, { locale: locale, currency: currency })
    }
    case 'percentage': {
      if(locale === 'fr-BE') return`${part.value} %`
      return `${part.value}%`
    }
    default: {
      (part.discountBasis: empty) // eslint-disable-line no-unused-expressions
      throw new Error(`Unknown discount basis: ${part.discountBasis}`)
    }
  }
}

export {
  partAppliesToBoxNumber,
  appliesToBoxNumber,
  discountedPriceWithPart,
  formattedPartValue
}

export type {
  DiscountCode,
  DiscountCodePart,
  DiscountType,
  DiscountBasis
}
