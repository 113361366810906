// @noflow
import { ACCOUNT_ROUTES } from '@/routes'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { captureException } from '@sentry/browser'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { subscriptionDataVar } from '@/services/apollo'

import ArchivePaymentMethodModal from './components/ArchivePaymentMethodModal/ArchivePaymentMethodModal'
import NoPaymentMethod from './components/NoPaymentMethod/NoPaymentMethod'
import PaymentMethodCard from './components/PaymentMethodCard/PaymentMethodCard'
import UpdateDefaultPaymentMethodModal from './components/UpdateDefaultPaymentMethodModal/UpdateDefaultPaymentMethodModal'
import AlertCard from '@/components/elements/atoms/Alert/AlertCard'
import { Button } from '@/components/elements/atoms/Button'
import Spinner from '@/components/elements/atoms/Spinner/Spinner'
import { pageTitleState } from '@/components/pages/CustomerIssueManagementPage/CustomerIssueManagementPage'

import STYLES from './PaymentMethods.module.sass'

import { SET_PAYMENT_METHOD_TO_ACTIVE } from '../../mutations/mutations'
import { ARCHIVE_PAYMENT_METHOD_MUTATION } from './mutations/ArchivePaymentMethodMutation'
import { SUBSCRIPTION_UNSUSPENSION_ATTEMPT_MUTATION } from './mutations/SubscriptionUnsuspensionAttemptMutation'
import { PAYMENT_METHODS_QUERY } from './queries/PaymentMethodsQuery'

import { PaymentMethodSetActive } from '../../mutations/__generated__/PaymentMethodSetActive'
import { ArchivePaymentMethod } from './mutations/__generated__/ArchivePaymentMethod'
import {
  SubscriptionUnsuspensionAttemptMutation,
  SubscriptionUnsuspensionAttemptMutationVariables
} from './mutations/__generated__/SubscriptionUnsuspensionAttemptMutation'
import {
  PaymentMethodsQuery_user_subscription_paymentMethods as PaymentMethod,
  PaymentMethodsQuery,
  PaymentMethodsQueryVariables
} from './queries/__generated__/PaymentMethodsQuery'
import { ArchivedType } from '@/types'

import PaymentMethodsSkeleton from './PaymentMethodsSkeleton'

const PaymentMethods = (): JSX.Element | null => {
  const subscriptionData = useReactiveVar(subscriptionDataVar)
  const navigate = useNavigate()
  const [newSelectedPaymentMethod, setNewSelectedPaymentMethod] =
    useState<PaymentMethod>()
  const [paymentMethodToArchive, setPaymentMethodToArchive] =
    useState<PaymentMethod>()
  const [openChangePaymentMethod, setOpenChangePaymentMethod] = useState(false)
  const [openArchivePaymentMethod, setOpenArchivePaymentMethod] =
    useState(false)
  const [processingPaymentUpdate, setProcessingPaymentUpdate] = useState(false)
  const [allPaymentMethods, setAllPaymentMethods] = useState<PaymentMethod[]>(
    []
  )
  const [
    paymentMethodSuccessfullyUpdated,
    setPaymentMethodSuccessfullyUpdated
  ] = useState(false)
  const [
    paymentMethodSuccessfullyArchived,
    setPaymentMethodSuccessfullyArchived
  ] = useState(false)
  const [unsuspensionError, setUnsuspensionError] = useState(false)

  const {
    loading,
    data,
    error: paymentMethodsQueryError
  } = useQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>(
    PAYMENT_METHODS_QUERY,
    {
      variables: {
        archivedType: ArchivedType.non_archived,
        num: 1
      }
    }
  )

  useEffect(() => {
    if (data) {
      setAllPaymentMethods(data.user.subscription.paymentMethods)
    }
  }, [data])

  const onClickAddPaymentMethod = useCallback(() => {
    navigate(ACCOUNT_ROUTES.addPaymentMethod)
  }, [navigate])

  const changePaymentMethod = (paymentMethod: PaymentMethod) => {
    if (!paymentMethod.active && !processingPaymentUpdate) {
      setOpenChangePaymentMethod(true)
      setNewSelectedPaymentMethod(paymentMethod)
    }
  }

  const [
    setPaymentMethodToActiveMutation,
    {
      loading: setPaymentMethodToActiveLoading,
      error: setPaymentMethodToActiveError
    }
  ] = useMutation<PaymentMethodSetActive>(SET_PAYMENT_METHOD_TO_ACTIVE)

  const [
    archivePaymentMethodMutation,
    {
      loading: setArchivePaymentMethodLoading,
      error: setArchivePaymentMethodError
    }
  ] = useMutation<ArchivePaymentMethod>(ARCHIVE_PAYMENT_METHOD_MUTATION)

  const [
    subscriptionUnsuspensionAttemptMutation,
    {
      loading: subscriptionUnsuspensionAttemptMutationLoading,
      error: subscriptionUnsuspensionAttemptMutationError
    }
  ] = useMutation<
    SubscriptionUnsuspensionAttemptMutation,
    SubscriptionUnsuspensionAttemptMutationVariables
  >(SUBSCRIPTION_UNSUSPENSION_ATTEMPT_MUTATION, {
    variables: {
      offset: 0,
      limit: 6,
      nextNBoxes: 1
    },
    onCompleted: (data) => {
      const newStatus =
        data.subscriptionUnsuspensionAttempt?.subscription?.status
      if (
        newStatus &&
        subscriptionData &&
        newStatus !== subscriptionData?.status
      ) {
        subscriptionDataVar({
          ...subscriptionData,
          status: newStatus
        })
        setUnsuspensionError(false)
        navigate(ACCOUNT_ROUTES.base)
      }
    },
    onError: (error) => {
      captureException(error)
      setUnsuspensionError(true)
    }
  })

  const updateDefaultPaymentMethod = useCallback(async () => {
    setProcessingPaymentUpdate(true)
    const { data } = await setPaymentMethodToActiveMutation({
      variables: {
        paymentMethodId: newSelectedPaymentMethod?.id,
        archivedType: 'non_archived'
      }
    })
    if (data?.response?.subscription) {
      setAllPaymentMethods(data.response.subscription.paymentMethods)
      setOpenChangePaymentMethod(false)
      setProcessingPaymentUpdate(false)
      setPaymentMethodSuccessfullyUpdated(true)
    }
  }, [newSelectedPaymentMethod?.id, setPaymentMethodToActiveMutation])

  const archivePaymentMethod = useCallback(async () => {
    setProcessingPaymentUpdate(true)
    const { data } = await archivePaymentMethodMutation({
      variables: {
        paymentMethodId: paymentMethodToArchive?.id,
        archivedType: 'non_archived'
      }
    })
    if (data?.response?.user?.subscription) {
      setAllPaymentMethods(data.response.user.subscription.paymentMethods)
      setOpenArchivePaymentMethod(false)
      setProcessingPaymentUpdate(false)
      setPaymentMethodSuccessfullyArchived(true)
    }
  }, [archivePaymentMethodMutation, paymentMethodToArchive?.id])

  const onClickRetryPayment = useCallback(() => {
    subscriptionUnsuspensionAttemptMutation()
  }, [subscriptionUnsuspensionAttemptMutation])

  const archivePaymentMethodClick = useCallback(
    (paymentMethod: PaymentMethod) => {
      if (!paymentMethod.active) {
        setPaymentMethodToArchive(paymentMethod)
        setOpenArchivePaymentMethod(true)
      }
    },
    []
  )

  const sortPaymentMethods = (a: PaymentMethod, b: PaymentMethod) => {
    if (a.id < b.id) {
      return -1
    }
    if (a.id > b.id) {
      return 1
    }
    return 0
  }

  const toggleDefaultPaymentMethod = useCallback(() => {
    setOpenChangePaymentMethod(!openChangePaymentMethod)
    setPaymentMethodSuccessfullyUpdated(false)
  }, [openChangePaymentMethod])

  const toggleArchivePaymentMethod = useCallback(() => {
    setOpenArchivePaymentMethod(!openArchivePaymentMethod)
    setPaymentMethodSuccessfullyArchived(false)
  }, [openArchivePaymentMethod])

  useEffect(() => {
    pageTitleState('my_details.payment_methods.title')
  }, [])

  useEffect(() => {
    if (window.location.search.includes('success')) {
      setPaymentMethodSuccessfullyUpdated(true)
      window.history.replaceState(
        {},
        document.title,
        location.href.split('?')[0]
      )
    }
  }, [])

  if (loading || !data) {
    return <PaymentMethodsSkeleton />
  }

  const { user } = data
  const { subscription, dogs, shippingCountryCode } = user
  const { paymentMethods } = subscription
  const dogNames = dogs?.map((dog) => dog.name)
  const subscriptionSuspended = subscription.status === 'suspended'
  const freeBoxNotDelivered =
    !subscriptionSuspended &&
    user.subscription.nextNBoxes[0] &&
    user.subscription.nextNBoxes[0].isNextEditableBox &&
    user.subscription.nextNBoxes[0].isFreeBox &&
    user.subscription.deliveriesReceived === 0

  const noPaymentMethod = !paymentMethods?.length

  if (
    setPaymentMethodToActiveLoading ||
    setArchivePaymentMethodLoading ||
    processingPaymentUpdate
  ) {
    return (
      <div className={STYLES.loading}>
        <Spinner />
      </div>
    )
  }

  /* Error message - payment methods query */
  if (paymentMethodsQueryError) {
    return (
      <AlertCard
        message={{
          text: paymentMethodsQueryError.message,
          translate: false
        }}
        variant="error"
      />
    )
  } else {
    return (
      <div className={STYLES.paymentMethodsContainer}>
        {/* Error message - subscription unsuspension attempt mutation */}
        {(subscriptionUnsuspensionAttemptMutationError ||
          unsuspensionError) && (
          <div className={STYLES.wrapper}>
            <AlertCard
              message={{
                text: 'my_details.payment_methods.unsuspension_error',
                namespace: 'dashboard'
              }}
              variant="error"
            />
          </div>
        )}
        {/* Error message - set payment method to active mutation */}
        {setPaymentMethodToActiveError && (
          <div className={STYLES.wrapper}>
            <AlertCard
              message={{
                text: setPaymentMethodToActiveError?.message || '',
                translate: false
              }}
              variant="error"
            />
          </div>
        )}
        {/* Error message - archive payment method mutation */}
        {setArchivePaymentMethodError && (
          <div className={STYLES.wrapper}>
            <AlertCard
              message={{
                text: setArchivePaymentMethodError.message,
                translate: false
              }}
              variant="error"
            />
          </div>
        )}
        {/* Success message */}
        {paymentMethodSuccessfullyUpdated && (
          <div className={STYLES.wrapper}>
            <AlertCard
              message={{
                namespace: 'dashboard',
                text: 'my_details.payment_methods.payment_method_updated',
                margin: false,
                align: 'left'
              }}
              variant="success"
            />
          </div>
        )}
        {paymentMethodSuccessfullyArchived && (
          <div className={STYLES.wrapper}>
            <AlertCard
              message={{
                namespace: 'dashboard',
                text: 'my_details.payment_methods.payment_method_removed',
                margin: false,
                align: 'left'
              }}
              variant="success"
            />
          </div>
        )}
        {/* Add a payment method */}
        {noPaymentMethod && (
          <div className={STYLES.wrapper}>
            <NoPaymentMethod
              freeBoxNotDelivered={freeBoxNotDelivered}
              dogNames={dogNames || []}
              onClickAddPaymentMethod={onClickAddPaymentMethod}
            />
          </div>
        )}
        {/* Existing payment methods */}
        {allPaymentMethods.length
          ? [...allPaymentMethods]
              .sort(sortPaymentMethods)
              .map((paymentMethod): JSX.Element => {
                return (
                  <div className={STYLES.wrapper} key={paymentMethod.id}>
                    <PaymentMethodCard
                      paymentMethod={paymentMethod}
                      selected={paymentMethod.active}
                      // eslint-disable-next-line react/jsx-no-bind
                      onSelect={() => changePaymentMethod(paymentMethod)}
                      shouldOfferAttemptPaymentAgain={
                        paymentMethod.active && subscriptionSuspended
                      }
                      resumeSubscription={onClickRetryPayment}
                      disableRetryPaymentButton={
                        subscriptionUnsuspensionAttemptMutationLoading
                      }
                      onArchivePaymentMethodClick={archivePaymentMethodClick}
                      shippingCountryCode={shippingCountryCode}
                    />
                  </div>
                )
              })
          : ''}
        {/* Add a payment method button */}
        {/* Do not show button if there is no payment method as <NoPaymentMethod /> has one built it */}
        {freeBoxNotDelivered || noPaymentMethod ? (
          ''
        ) : (
          <div className={STYLES.buttonContainer}>
            <Button
              typography={{
                text: 'my_details.payment_methods.add_payment_method',
                namespace: 'dashboard'
              }}
              variant={'secondary'}
              disableAnalytics
              onClick={onClickAddPaymentMethod}
            />
          </div>
        )}
        {/* Confirm update default payment method */}
        <UpdateDefaultPaymentMethodModal
          suspended={subscriptionSuspended}
          isOpen={openChangePaymentMethod}
          toggle={toggleDefaultPaymentMethod}
          onSubmit={updateDefaultPaymentMethod}
        />
        {/* Confirm archive payment method */}
        <ArchivePaymentMethodModal
          isOpen={openArchivePaymentMethod}
          toggle={toggleArchivePaymentMethod}
          onSubmit={archivePaymentMethod}
        />
      </div>
    )
  }
}

export default PaymentMethods
