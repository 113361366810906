// @noflow
import React from 'react'

import {
  ICON_COLOURS,
  IconColours
} from '@/components/elements/atoms/Icon/Icon'

type Props = {
  size: number
  accentColour: IconColours
  width?: number | string
}

const Arrow = ({ size, accentColour, width }: Props): JSX.Element => {
  return (
    <svg
      fill="none"
      height={size}
      viewBox="0 0 30 30"
      width={width || '100%'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="m27.8571 11.7857c.5804 0 1.0826.2121 1.5067.6362s.6362.9263.6362 1.5067v2.1428c0 .5804-.2121 1.0826-.6362 1.5067s-.9263.6362-1.5067.6362h-9.6428v9.6428c0 .5804-.2121 1.0826-.6362 1.5067s-.9263.6362-1.5067.6362h-2.1428c-.5804 0-1.0826-.2121-1.5067-.6362s-.6362-.9263-.6362-1.5067v-9.6428h-9.64284c-.58036 0-1.08259-.2121-1.506699-.6362-.424107-.4241-.636161-.9263-.636161-1.5067v-2.1428c0-.5804.212054-1.0826.636161-1.5067.424109-.4241.926339-.6362 1.506699-.6362h9.64284v-9.64284c0-.58036.2121-1.08259.6362-1.506699.4241-.424107.9263-.636161 1.5067-.636161h2.1428c.5804 0 1.0826.212054 1.5067.636161.4241.424109.6362.926339.6362 1.506699v9.64284z"
        fill={ICON_COLOURS[accentColour]}
        fillRule="evenodd"
      />
    </svg>
  )
}

export default Arrow
