// @noflow
import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import type { Dispatch } from 'redux'

import Input from '@/components/shared/elements/Input'

import * as ACTIONS from '../../actions'
import type { State } from '../../reducers'
import { isValidEmail } from '../../reducers/formDataReducer'
import type { Form } from '../../reducers/formDataReducer'
import type { State as RequestsState } from '../../reducers/requestsReducer'

type PresentationalProps = {
  email: Form['email']
  submitContactForm: RequestsState['submitContactForm']
}

type ActionProps = {
  updateEmail: (e: React.ChangeEvent<HTMLInputElement>) => void
  validateEmail: (e: React.ChangeEvent<HTMLInputElement>) => void
}

type Props = PresentationalProps & ActionProps

const mapStateToProps = ({
  formData: { form },
  requests
}: State): PresentationalProps => {
  const { email } = form as Form
  const { submitContactForm } = requests

  return {
    email,
    submitContactForm
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => {
  const updateEmail = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.currentTarget.value
    dispatch(
      ACTIONS.updateField('email', {
        value,
        interaction: 'Interacting'
      })
    )
  }

  const validateEmail = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.currentTarget.value
    dispatch(
      ACTIONS.updateField('email', {
        value,
        interaction: isValidEmail(value)
          ? 'InteractedWithSuccess'
          : 'InteractedWithError'
      })
    )
  }

  return {
    updateEmail,
    validateEmail
  }
}

const EmailInput = ({
  email,
  submitContactForm,
  updateEmail,
  validateEmail
}: Props): React.ReactElement => {
  const { t } = useTranslation('contacts')
  const copyContext = 'email_input'
  return (
    <Input
      autoComplete="on"
      className="email"
      disabled={submitContactForm.status === 'In Flight'}
      inputId="email"
      invalid={email.interaction === 'InteractedWithError'}
      invalidityMessage={t(`${copyContext}.invalidity_message`)}
      label={t(`${copyContext}.label`)}
      name="email"
      onBlur={validateEmail}
      onChange={updateEmail}
      required
      type="text"
      value={email.value}
      placeholder={t(`${copyContext}.placeholder`)}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailInput)
