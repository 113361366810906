// @flow

/* eslint-disable react/require-optimization */
/* eslint-disable react/jsx-no-literals */
/* eslint-disable i18next/no-literal-string */
/* eslint-disable react/no-arrow-function-lifecycle */

import * as React from 'react'
import i18next from 'i18next'
import initI18n from '@/packs/localisation'

import AffiliateBoxSummaryComponent from '../affiliates/partials/box_summary_component'
import AffiliatesSummaryGridItemComponent from '../shared/AffiliatesSummaryGridItemComponent'
import ReferralComponent from '../affiliates/partials/referral_component'
import DalmatianRed from '../../../assets/images/illustrations/dogs/dalmatian-red.svg'

import type { Influencer } from './message_types'

type Props = {|
  influencer: Influencer,
  visible: boolean,
  setAlertMessage: string => void
|}

class SummaryTab extends React.Component<Props> {
  constructor (props: Props): void {
    super(props)
    if (!i18next.isInitialized) initI18n(this.props.influencer.preferred_language)
  }

  namespace = 'ambassadors:dashboard.summary_tab'

  referredPanelContent = (influencer: Influencer): React.Node => {
    return (
      <div>
        <div className='content-padding__top-bottom-mini'>
          <img
            alt='A drawing of a dalmatian'
            src={DalmatianRed}
          />
        </div>
        {
          influencer.referral_history.number_of_followers_referred > 0
            ? (
              <p>
                {influencer.referral_history.number_of_followers_referred}
              </p>
            ) : (
              <p>
                { i18next.t(`${this.namespace}.no_followers_referred`) }
              </p>
            )
        }
      </div>
    )
  }

  topBreedsPanelContent = (influencer: Influencer): React.Node => {
    const popular_breeds = influencer.referral_history.most_popular_breeds
    return (
      popular_breeds.length > 0
        ? (
          <div className='content-padding__top-bottom-mini'>
            {
              popular_breeds.map((breed: string, index: number): React.Node => (
                <p
                  className='list-item'
                  key={index}
                >
                  { `${index + 1}: ${breed}`}
                </p>
              ))
            }
            <p>
              (Let it breed, let it breed)
            </p>
          </div>
        ) : (
          <div>
            <div className='content-padding__top-bottom-mini'>
              <img
                alt='An illustration of a Dalmatian.'
                src={DalmatianRed}
              />
            </div>
            <p>
              { i18next.t(`${this.namespace}.no_followers_referred`) }
            </p>
          </div>
        )
    )
  }

  render = (): React.Node => (
    <div className={`visible-${this.props.visible.toString()} tab affiliate-summary`}>
      <div className='section affiliate-overview'>
        <div className='affiliate-summary__grid small-padding-top'>
          <AffiliatesSummaryGridItemComponent
            header='Followers Referred'
            content={this.referredPanelContent(this.props.influencer)}
          />
          <AffiliatesSummaryGridItemComponent
            header='Followers Referred'
            content={this.topBreedsPanelContent(this.props.influencer)}
          />
          {/* $FlowFixMe */}
          <AffiliateBoxSummaryComponent
            affiliate={this.props.influencer}
            affiliateType='influencer'
          />
        </div>
      </div>
      <div className='section affiliate-referral'>
        <ReferralComponent
          affiliateType='influencer'
          fullName={this.props.influencer.full_name}
          referralLink={this.props.influencer.referral_link}
          setAlertMessage={this.props.setAlertMessage}
          referralSignupCredit={0}
          includeTitle={false}
        />
      </div>
      <div className='section affiliate-tell-us-views content-padding'>
        <p className='white-text'>
          { i18next.t(`${this.namespace}.your_view`) }
        </p>
        <p className='red-text bold-font'>
          { i18next.t(`${this.namespace}.testimony`) }
        </p>
        <p className='red-text no-margin--bottom'>
          { i18next.t(`${this.namespace}.behaviourist`) }
        </p>
        <p className='red-text'>
          { i18next.t(`${this.namespace}.current_chair`) }
        </p>
        <div className='btn-with-shadow-container'>
          <a
            rel='noreferrer'
            className='btn btn-red'
            target='_blank'
            href='https://uk.trustpilot.com/review/butternutbox.com'
          >
            { i18next.t(`${this.namespace}.throw_words`) }
          </a>
        </div>
      </div>
    </div>
  )
}

export default SummaryTab
