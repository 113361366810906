import { gql } from '@apollo/client'

const HEALTH_ISSUES_QUERY = gql`
  query HealthIssues {
    healthIssues {
      id
      name
      information
      warning
    }
  }
`

export { HEALTH_ISSUES_QUERY }
