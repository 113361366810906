// @noflow
import type { Language } from '@/packs/localisation'
import { ACCOUNT_ROUTES } from '@/routes'
import { useQuery } from '@apollo/client'
import { Container, Grid } from '@mui/material'
import Cookies from 'js-cookie'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import cookies from '@/utils/cookies'
import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { formatCurrencyWithDecimal } from '@/utils/currency'
import * as Sentry from '@/utils/sentry'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import AlertCard from '@/components/elements/atoms/Alert/AlertCard'
import { Button } from '@/components/elements/atoms/Button'
import Image from '@/components/elements/atoms/Image/Image'
import Spinner from '@/components/elements/atoms/Spinner/Spinner'
import Text from '@/components/elements/atoms/Text/Text'
import { MFITB_PLAN_OFFER_QUERY } from '@/components/pages/MFITBPlanOffer/queries/MFITBPlanOfferQuery'
import { MFITBPlanOfferQuery } from '@/components/pages/MFITBPlanOffer/queries/__generated__/MFITBPlanOfferQuery'
import Base from '@/components/templates/Base'

import STYLES from './MFITBPlanOffer.module.sass'

type Props = {
  variant: Language
}

const MFITBPlanOffer = (): JSX.Element | null => {
  const namespace = 'account'
  const { t } = useTranslation(namespace)
  const { loading, data, error } = useQuery<MFITBPlanOfferQuery>(
    MFITB_PLAN_OFFER_QUERY
  )

  const { windowWidth } = useWindowSize()

  if (error) {
    Sentry.captureException(
      `Error occured in MFITB_PLAN_OFFER_QUERY on MFITBPlanOffer page`,
      {
        extra: { error },
        tags: {
          product: Sentry.Product.Account,
          team: Sentry.Team.Retention
        }
      }
    )
  }

  const swapToSavingsPlanClicked = useCallback(() => {
    Cookies.set(cookies.reviewingMFITBPlanOffer, 'true')
    window.location.href = `${ACCOUNT_ROUTES.reviewFrequency}/${data?.user?.subscription?.increasedDeliveryCadencePlan?.id}`
  }, [data?.user?.subscription?.increasedDeliveryCadencePlan?.id])

  const noThanksClicked = useCallback(() => {
    Cookies.set(cookies.swapToMFITBPlanOfferSeen, 'true')
    Cookies.set(cookies.reviewingMFITBPlanOffer, 'false')
    window.location.href = ACCOUNT_ROUTES.base
  }, [])

  if (loading) {
    return (
      <Base background="brandYellow100">
        <Container maxWidth="lg">
          <div className={STYLES.spinnerWrapper}>
            <Spinner variant="brandYellow500" />
          </div>
        </Container>
      </Base>
    )
  }

  if (!data || !data.user.subscription.plan || loading) {
    return null
  }

  const {
    user: {
      shippingCountryCode,
      preferredLanguage,
      subscription: { plan, increasedDeliveryCadencePlan }
    }
  } = data

  const { locale, currency, currencySymbol } = countryCodeToLocaleCurrency(
    shippingCountryCode,
    preferredLanguage
  )
  const savingsAmount = increasedDeliveryCadencePlan
    ? plan.pricePerWeek - increasedDeliveryCadencePlan.pricePerWeek
    : 0

  return (
    <Base background="brandYellow100">
      <Container maxWidth="lg">
        <div className={STYLES.wrapper}>
          <Grid container justifyContent="center">
            <Grid item sm={12} md={10} className={STYLES.titleWrapper}>
              <Text
                text={'mfitb_plan_offer.title'}
                margin={false}
                namespace={namespace}
                variant={
                  windowWidth < BREAKPOINTS.md ? 'display24' : 'display36'
                }
                align="center"
              />
            </Grid>
          </Grid>
          {increasedDeliveryCadencePlan && plan && (
            <Grid container justifyContent="center">
              <Text
                text={'mfitb_plan_offer.subtitle'}
                margin={false}
                namespace={namespace}
                variables={{
                  currentCadence: plan.durationInWeeks,
                  swapCadence: increasedDeliveryCadencePlan.durationInWeeks,
                  count: plan.durationInWeeks
                }}
                align="center"
              />
              <div className={STYLES.pouchesWrapper}>
                <div className={STYLES.pouchImage}>
                  <Image
                    alt={t('mfitb_plan_offer.image_alts.box')}
                    slug="open-box-of-butternut-meals-illustration"
                    image={{
                      width: windowWidth < BREAKPOINTS.md ? 150 : 190,
                      height: windowWidth < BREAKPOINTS.md ? 133 : 169
                    }}
                  />
                  <Text
                    text={'mfitb_plan_offer.current_cadence_week'}
                    variables={{
                      currentCadence: plan.durationInWeeks,
                      count: plan.durationInWeeks
                    }}
                    namespace={namespace}
                    margin={false}
                    align="center"
                    variant="display14"
                    bold
                  />
                </div>
                <div className={STYLES.pouchImage}>
                  <Image
                    alt={t('mfitb_plan_offer.image_alts.box')}
                    slug="open-box-of-butternut-meals-illustration"
                    image={{
                      width: windowWidth < BREAKPOINTS.md ? 183 : 235,
                      height: windowWidth < BREAKPOINTS.md ? 162 : 208
                    }}
                  />
                  <Text
                    text={'mfitb_plan_offer.new_cadence_week'}
                    variables={{
                      swapCadence: increasedDeliveryCadencePlan.durationInWeeks
                    }}
                    namespace={namespace}
                    margin={false}
                    align="center"
                    variant="display14"
                    bold
                  />
                </div>
              </div>
              <AlertCard
                discountIcon={currencySymbol}
                message={{
                  margin: false,
                  text: 'mfitb_plan_offer.alert',
                  align: 'left',
                  namespace,
                  variables: {
                    totalSavings: formatCurrencyWithDecimal(savingsAmount, {
                      locale: locale,
                      currency: currency
                    })
                  }
                }}
                variant="savings"
              />
            </Grid>
          )}
        </div>
      </Container>
      <div className={STYLES.stickyFooter}>
        <Container maxWidth="sm">
          <Grid
            container
            justifyContent="center"
            columnSpacing={windowWidth < BREAKPOINTS.md ? 0 : 2}
            rowSpacing={1}
            flexDirection={
              windowWidth < BREAKPOINTS.md ? 'column-reverse' : 'row'
            }
          >
            <Grid item xs={12} md={6}>
              <Button
                identifier="mfitb_plan_offer.cancel.button"
                // eslint-disable-next-line react/jsx-no-bind
                onClick={noThanksClicked}
                typography={{
                  namespace,
                  text: 'mfitb_plan_offer.buttons.cancel'
                }}
                variant="secondary"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                identifier="mfitb_plan_offer.review_changes.button"
                // eslint-disable-next-line react/jsx-no-bind
                onClick={swapToSavingsPlanClicked}
                typography={{
                  namespace,
                  text: 'mfitb_plan_offer.buttons.review_changes'
                }}
                variant="primary"
                fullWidth
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    </Base>
  )
}

export { Props }

export default MFITBPlanOffer
