import { gql } from '@apollo/client'

const SUBMIT_ANSWERS_MUTATION = gql`
  mutation SubmitSurveyAnswers($answers: [AnswerInput!]!) {
    submitSurveyAnswers(answers: $answers) {
      answers {
        id
        option {
          text
        }
        question {
          id
          text
        }
        value
      }
    }
  }
`
export { SUBMIT_ANSWERS_MUTATION }
