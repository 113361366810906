// @noflow
import { useMutation, useQuery } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import * as Cookies from 'js-cookie'
import { snakeCase } from 'lodash'
import React from 'react'

import segmentTrack from '@/components/analytics/Analytics'
import withApollo from '@/components/apollo/withApollo'
import Modal from '@/components/elements/atoms/Modal/Modal'
import Text from '@/components/elements/atoms/Text/Text'
// eslint-disable-next-line no-restricted-imports
import { Answer } from '@/components/types'

import STYLES from './SurveyCard.module.sass'

import { SUBMIT_ANSWERS_MUTATION } from './mutations/submitAnswersMutation'
import { SURVEY_CARD_QUERY } from './queries/surveyCardQuery'

import type { SubmitSurveyAnswers } from './mutations/__generated__/SubmitSurveyAnswers'
import type {
  SurveyCardQuery,
  SurveyCardQueryVariables,
  SurveyCardQuery_survey_questions as SurveyQuestionType
} from './queries/__generated__/SurveyCardQuery'

import ExternalLinkCard from './SurveyCardCards/ExternalLinkCard'
import FreeTextQuestion from './SurveyCardCards/FreeTextQuestion'
import IntroCard from './SurveyCardCards/IntroCard'
import MultipleChoiceQuestion from './SurveyCardCards/MultipleChoiceQuestion'
import RatingQuestion from './SurveyCardCards/RatingQuestion'

type BaseCardProps = {
  currentQuestion: SurveyQuestionType
  setCurrentQuestionIndex: React.Dispatch<React.SetStateAction<number>>
}

type AnswerableCardProps = {
  userId: string
  surveyTitle: string
  answers: Array<Answer>
  setAnswers: React.Dispatch<React.SetStateAction<Array<Answer>>>
}

type QuestionProps = BaseCardProps & {
  userId: string
  surveyTitle: string
  setModalDismissal: () => void
  answers: Array<Answer>
  setAnswers: React.Dispatch<React.SetStateAction<Array<Answer>>>
}

const sendAnswerAnalyticsEvent = ({
  userId,
  surveyTitle,
  question,
  answer
}: {
  userId: string
  surveyTitle: string
  question: string
  answer: number | string | undefined
}): void => {
  segmentTrack('Engagement Survey Answer Submitted', {
    userId,
    surveyTitle,
    question,
    answer
  })
}

const SurveyQuestion = ({
  currentQuestion,
  setModalDismissal,
  setCurrentQuestionIndex,
  userId,
  surveyTitle,
  setAnswers,
  answers
}: QuestionProps) => {
  const { questionType } = currentQuestion
  switch (questionType) {
    case 'rating': {
      return (
        <RatingQuestion
          currentQuestion={currentQuestion}
          setCurrentQuestionIndex={setCurrentQuestionIndex}
          userId={userId}
          surveyTitle={surveyTitle}
          setAnswers={setAnswers}
          answers={answers}
        />
      )
    }
    case 'intro_card': {
      return (
        <IntroCard
          currentQuestion={currentQuestion}
          setModalDismissal={setModalDismissal}
          setCurrentQuestionIndex={setCurrentQuestionIndex}
        />
      )
    }
    case 'free_text': {
      return (
        <FreeTextQuestion
          userId={userId}
          surveyTitle={surveyTitle}
          currentQuestion={currentQuestion}
          setCurrentQuestionIndex={setCurrentQuestionIndex}
          setAnswers={setAnswers}
          answers={answers}
        />
      )
    }
    case 'external_link': {
      return (
        <ExternalLinkCard
          currentQuestion={currentQuestion}
          setCurrentQuestionIndex={setCurrentQuestionIndex}
        />
      )
    }
    case 'multiple_choice': {
      return (
        <MultipleChoiceQuestion
          currentQuestion={currentQuestion}
          setCurrentQuestionIndex={setCurrentQuestionIndex}
          userId={userId}
          surveyTitle={surveyTitle}
          setAnswers={setAnswers}
          answers={answers}
          identifier={`${snakeCase(surveyTitle)}.question_${
            currentQuestion.order
          }`}
        />
      )
    }
    default: {
      Sentry.captureException(
        `questionType ${questionType} unsupported for SurveyCard`
      )
      return null
    }
  }
}

const FinishedMessageCard = ({
  finishedMessage
}: {
  finishedMessage: string
}) => (
  <div className={STYLES.surveyCard}>
    <Text
      text={finishedMessage}
      element={'h2'}
      variant="display20"
      colour={'brandBlue500'}
      margin
      align={'left'}
      translate={false}
    />
  </div>
)

const SurveyCard = (): JSX.Element | null => {
  const [shouldShowModal, setShouldShowModal] = React.useState(true)
  const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState(0)
  const [answers, setAnswers] = React.useState<Array<Answer>>([])
  const [submitSurveyAnswers] = useMutation<SubmitSurveyAnswers>(
    SUBMIT_ANSWERS_MUTATION
  )
  const [answersSubmitted, setAnswersSubmitted] = React.useState(false)

  React.useEffect((): void => {
    // if there are any other modals found in the DOM, do not render this one
    // eslint-disable-next-line i18next/no-literal-string
    const modals = document.querySelectorAll(`[class*='modal']`)
    if (modals.length > 0) {
      setShouldShowModal(false)
    }

    const modalStorageKeys = ['showCareSplashScreen', 'showExtrasWelcomeModal']
    modalStorageKeys.forEach((key) => {
      if (localStorage.getItem(key) === 'true') {
        setShouldShowModal(false)
      }
    })
  }, [])

  const setModalDismissal = React.useCallback((slug) => {
    setShouldShowModal(false)
    // eslint-disable-next-line i18next/no-literal-string
    Cookies.set(`has_dismissed_${slug}_survey`, 'true')
  }, [])

  const { loading, data, error, startPolling, stopPolling } = useQuery<
    SurveyCardQuery,
    SurveyCardQueryVariables
  >(SURVEY_CARD_QUERY, {
    variables: { startingUrl: window.location.pathname },
    notifyOnNetworkStatusChange: true
  })

  React.useEffect(() => {
    const surveyCookie = Cookies.get('should_show_survey')
    if (surveyCookie) {
      startPolling(2000)
    }
    if (data?.survey) {
      stopPolling()
      Cookies.remove('should_show_survey')
    }
  }, [data?.survey, stopPolling, startPolling])

  // If consent for analytics cookies is not given by user, do not proceed
  const cookieConsent = window.localStorage.getItem('cookieConsent')
  const cookieConsentObject = cookieConsent && JSON.parse(cookieConsent)
  if (
    !cookieConsentObject ||
    cookieConsentObject.state !== 'accepted' ||
    !cookieConsentObject.preferences.analytics
  ) {
    return null
  }

  if (!data || loading) return null
  if (!data?.survey) return null
  if (error) {
    Sentry.captureException(error.message)
    return null
  }

  const {
    survey: { name, slug, questions, finishedMessage },
    user
  } = data

  const currentQuestion = questions.find(
    (q: SurveyQuestionType) => q.order === currentQuestionIndex
  )

  if (questions.length < 1) {
    Sentry.captureException('Error getting survey questions')
    return null
  }

  if (
    !shouldShowModal ||
    Cookies.get(`has_dismissed_${slug}_survey`) === 'true'
  )
    return null

  if (currentQuestionIndex === questions.length && !answersSubmitted) {
    if (Cookies.get(`has_dismissed_${slug}_survey`) !== 'true') {
      submitSurveyAnswers({
        variables: { answers: answers.flat() }
      })
      setAnswersSubmitted(true)
    }
    setTimeout(() => {
      // eslint-disable-next-line i18next/no-literal-string
      Cookies.set(`has_dismissed_${slug}_survey`, 'true')
      setShouldShowModal(false)
    }, 4000)
  }

  const dixaWidget = document.getElementsByClassName(
    'dixa-messenger-wrapper'
  )[0] as HTMLElement

  if (dixaWidget) {
    dixaWidget.style.bottom = '27rem'
  }

  return (
    <Modal
      variant="survey"
      isModalOpen={shouldShowModal}
      // eslint-disable-next-line react/jsx-no-bind
      onCloseButtonClick={() => setModalDismissal(slug)}
      width={450}
      bottomSticky
      textAlign="center"
      closeButtonIcon="surveyClose"
      closeButtonBackground="brandBlue600"
      closeButtonIconSize={28}
    >
      {currentQuestion ? (
        <SurveyQuestion
          currentQuestion={currentQuestion}
          setCurrentQuestionIndex={setCurrentQuestionIndex}
          // eslint-disable-next-line react/jsx-no-bind
          setModalDismissal={() => setModalDismissal(slug)}
          userId={user.id}
          surveyTitle={name}
          setAnswers={setAnswers}
          answers={answers}
        />
      ) : (
        <FinishedMessageCard finishedMessage={finishedMessage} />
      )}
    </Modal>
  )
}

export { SURVEY_CARD_QUERY, SurveyCard, sendAnswerAnalyticsEvent }
export type { BaseCardProps, AnswerableCardProps }
export default withApollo(SurveyCard)
