// @noflow
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import React, { useCallback } from 'react'

import type { Locale } from '@/utils/countryCodeHelper'

import BREAKPOINTS from '@/constants/Breakpoints'

import useButternutBoost from '@/hooks/useButternutBoost'
import useWindowSize from '@/hooks/useWindowSize'

import { Button } from '@/components/elements/atoms/Button'
import StarRating from '@/components/elements/molecules/StarRating/StarRating'

import STYLES from './ProductCollectionTitle.module.sass'

import type {
  ProductCollectionQuery_productCollection_productVariants_productVariantDeliveryTypes as DeliveryType,
  ProductCollectionQuery_productCollection as ProductCollection,
  ProductCollectionQuery_productCollection_productVariants as ProductVariant
} from '../../../queries/__generated__/ProductCollectionQuery'
import type { Currency } from '@/shared_types/rails_models/shipping_countries'

import { getRoundedAverageRating } from '../../../utils/customerReviewsRating'
import SectionWrapper from '../../SectionWrapper'
import ProductPrice from '../../molecules/ProductPrice/ProductPrice'

type Props = {
  name: ProductCollection['name']
  slug: ProductCollection['slug']
  grossPrice: number | undefined
  averageCustomerRating: ProductCollection['averageCustomerRating']
  countOfCustomerRatings: ProductCollection['countOfCustomerRatings']
  publicCustomerReviews: ProductCollection['publicCustomerReviews']
  locale: Locale
  currency: Currency
  selectedProductVariantDeliveryType: DeliveryType | null
  customerReviewsSectionRef: React.RefObject<HTMLElement>
}

const ProductCollectionTitle = ({
  name,
  slug,
  grossPrice,
  averageCustomerRating,
  countOfCustomerRatings,
  publicCustomerReviews,
  locale,
  currency,
  selectedProductVariantDeliveryType,
  customerReviewsSectionRef
}: Props): JSX.Element => {
  const roundedAverageRating = getRoundedAverageRating(averageCustomerRating)
  const { boostedSubscription, boostedType } = useButternutBoost()
  const { windowWidth } = useWindowSize()

  const handleOnViewReviewsClick = useCallback(() => {
    window.analytics.track('Shop: Seen Reviews Section', {
      productCollectionSlug: slug
    })

    const customerReviewsSection = document.querySelector('#customer-reviews')

    if (
      customerReviewsSectionRef &&
      customerReviewsSectionRef.current &&
      windowWidth < BREAKPOINTS.md
    ) {
      customerReviewsSectionRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    if (customerReviewsSection && windowWidth > BREAKPOINTS.md) {
      const fixedHeaderHeight = 100
      const topOfTheCustomerReviewsSection =
        customerReviewsSection.getBoundingClientRect().top - fixedHeaderHeight

      window.scrollTo({
        top: topOfTheCustomerReviewsSection,
        behavior: 'smooth'
      })
    }
  }, [customerReviewsSectionRef, slug, windowWidth])

  return (
    <SectionWrapper
      title={{
        text: name,
        translate: false,
        variant: 'display24',
        element: 'h2',
        bold: false
      }}
      marginBottom
    >
      <div className={STYLES.container}>
        {countOfCustomerRatings > 0 && publicCustomerReviews.length > 0 && (
          <div className={STYLES.rating}>
            <StarRating
              rating={roundedAverageRating}
              responsive={false}
              iconSize={17}
              iconWidth={19}
              className={STYLES.ratingStars}
            />
            <Button
              variant="linkWhiteBG"
              typography={{
                namespace: 'dashboard',
                text: 'extras.product_collection.title.link_to_reviews'
              }}
              size="slim"
              onClick={handleOnViewReviewsClick}
              disableAnalytics
            />
          </div>
        )}
        {!isNull(selectedProductVariantDeliveryType) &&
          !isUndefined(grossPrice) && (
            <ProductPrice
              grossPrice={grossPrice}
              discountedPrice={selectedProductVariantDeliveryType.netPrice}
              boostedPrice={selectedProductVariantDeliveryType.boostedNetPrice}
              boostedSubscription={boostedSubscription}
              boostedType={boostedType}
              locale={locale}
              currency={currency}
            />
          )}
      </div>
    </SectionWrapper>
  )
}

export default ProductCollectionTitle
