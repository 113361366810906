// @noflow
import { ACCOUNT_ROUTES } from '@/routes'
import { useReactiveVar } from '@apollo/client'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { dogsDataVar } from '@/services/apollo'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'
import Text from '@/components/elements/atoms/Text/Text'
import { PawTip } from '@/components/pages/MealtimeChecklist/components/PawTip/PawTip'

import STYLES from './MealtimeChecklist.module.sass'

const namespace = 'account'

const DefaultChecklist = (): JSX.Element => {
  return (
    <>
      <div>
        <Text
          text="mealtime_checklist.default.tip1_title"
          margin={false}
          variant="textRegular14"
          namespace={namespace}
          bold
        />
        <Text
          text="mealtime_checklist.default.tip1_subtitle"
          margin={false}
          variant="textRegular14"
          namespace={namespace}
        />
        <div className={STYLES.list}>
          <PawTip
            typography={{
              text: 'mealtime_checklist.default.tip1_list.item1'
            }}
          />
          <PawTip
            typography={{
              text: 'mealtime_checklist.default.tip1_list.item2'
            }}
          />
          <PawTip
            typography={{
              text: 'mealtime_checklist.default.tip1_list.item3'
            }}
          />
        </div>
      </div>
      <div>
        <Text
          text="mealtime_checklist.default.tip2_title"
          margin={false}
          variant="textRegular14"
          namespace={namespace}
          bold
        />
        <Text
          text="mealtime_checklist.default.tip2_subtitle"
          margin={false}
          variant="textRegular14"
          namespace={namespace}
        />
      </div>
      <div>
        <Text
          text="mealtime_checklist.default.tip3_title"
          margin={false}
          variant="textRegular14"
          namespace={namespace}
          bold
        />
        <Text
          text="mealtime_checklist.default.tip3_subtitle"
          margin={false}
          variant="textRegular14"
          namespace={namespace}
        />
      </div>
      <div>
        <Text
          text="mealtime_checklist.default.tip4_title"
          margin={false}
          variant="textRegular14"
          namespace={namespace}
          bold
        />
        <Text
          text="mealtime_checklist.default.tip4_subtitle"
          margin={false}
          variant="textRegular14"
          namespace={namespace}
        />
      </div>
    </>
  )
}

const FussyChecklist = (): JSX.Element => {
  return (
    <>
      <div>
        <Text
          text="mealtime_checklist.fussy.surroundings"
          variant="display20"
          namespace={namespace}
          bold
          element="h3"
        />
        <Text
          text="mealtime_checklist.fussy.tip1_title"
          margin={false}
          variant="textRegular14"
          namespace={namespace}
          bold
        />
        <div className={STYLES.list}>
          <PawTip
            typography={{
              text: 'mealtime_checklist.fussy.tip1_list.item1'
            }}
          />
          <PawTip
            typography={{
              text: 'mealtime_checklist.fussy.tip1_list.item2'
            }}
          />
          <PawTip
            typography={{
              text: 'mealtime_checklist.fussy.tip1_list.item3'
            }}
          />
        </div>
      </div>
      <div>
        <Text
          text="mealtime_checklist.fussy.tip2_title"
          margin={false}
          variant="textRegular14"
          namespace={namespace}
          bold
        />
        <Text
          text="mealtime_checklist.fussy.tip2_subtitle"
          margin={false}
          variant="textRegular14"
          namespace={namespace}
        />
      </div>
      <div>
        <Text
          text="mealtime_checklist.fussy.tip3_title"
          margin={false}
          variant="textRegular14"
          namespace={namespace}
          bold
        />
        <Text
          text="mealtime_checklist.fussy.tip3_subtitle"
          margin={false}
          variant="textRegular14"
          namespace={namespace}
        />
      </div>
      <div>
        <Text
          text="mealtime_checklist.fussy.tip4_title"
          margin={false}
          variant="textRegular14"
          namespace={namespace}
          bold
        />
        <Text
          text="mealtime_checklist.fussy.tip4_subtitle"
          margin={false}
          variant="textRegular14"
          namespace={namespace}
        />
      </div>
      <div>
        <Text
          text="mealtime_checklist.fussy.habits"
          variant="display20"
          namespace={namespace}
          bold
          element="h3"
        />
        <Text
          text="mealtime_checklist.fussy.tip5_title"
          margin={false}
          variant="textRegular14"
          namespace={namespace}
          bold
        />
        <div className={STYLES.list}>
          <PawTip
            typography={{
              text: 'mealtime_checklist.fussy.tip5_list.item1'
            }}
          />
          <PawTip
            typography={{
              text: 'mealtime_checklist.fussy.tip5_list.item2'
            }}
          />
          <PawTip
            typography={{
              text: 'mealtime_checklist.fussy.tip5_list.item3'
            }}
            spaced
          />
        </div>
        <Text
          text="mealtime_checklist.fussy.tip5_subtitle"
          margin={false}
          variant="textRegular14"
          namespace={namespace}
        />
      </div>
      <div>
        <Text
          text="mealtime_checklist.fussy.tip6_title"
          margin={false}
          variant="textRegular14"
          namespace={namespace}
          bold
        />
        <Text
          text="mealtime_checklist.fussy.tip6_subtitle"
          margin={false}
          variant="textRegular14"
          namespace={namespace}
        />
      </div>
      <div>
        <Text
          text="mealtime_checklist.fussy.tip7_title"
          margin={false}
          variant="textRegular14"
          namespace={namespace}
          bold
        />
        <Text
          text="mealtime_checklist.fussy.tip7_subtitle"
          margin={false}
          variant="textRegular14"
          namespace={namespace}
        />
      </div>
    </>
  )
}

const MealtimeChecklist = (): JSX.Element => {
  const { windowWidth } = useWindowSize()
  const isMobile = windowWidth < BREAKPOINTS.md
  const navigate = useNavigate()
  const { has_fussy_eater } = useReactiveVar(dogsDataVar) || {}

  const gotToHome = useCallback(() => navigate(ACCOUNT_ROUTES.base), [navigate])

  return (
    <>
      <Card padding={isMobile ? 16 : 32} className={STYLES.card}>
        {has_fussy_eater ? <FussyChecklist /> : <DefaultChecklist />}
      </Card>
      <div className={STYLES.buttonWrapper}>
        <Button
          onClick={gotToHome}
          typography={{
            text: 'mealtime_checklist.got_it',
            namespace
          }}
          identifier="mealtime_checklist.got_it"
        />
      </div>
    </>
  )
}

export default MealtimeChecklist
