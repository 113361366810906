/* eslint-disable i18next/no-literal-string */
// @noflow
import { ACCOUNT_ROUTES } from '@/routes'
import { useMutation } from '@apollo/client'
import type { PaymentMethodable, SubscriptionStatus } from '@types'
import { formatISO } from 'date-fns'
import React, { useCallback, useContext, useEffect, useState } from 'react'

import { MINIMUM_ORDER_AMOUNT_NL } from '@/utils/butternutbox/constants/digital_product'
import type { Locale } from '@/utils/countryCodeHelper'
import {
  formatCurrencyWithDecimal,
  formatCurrencyWithoutDecimal
} from '@/utils/currency'

import OneTimePurchaseFooter from '@/components/elements/molecules/OneTimePurchaseFooter/OneTimePurchaseFooter'
import { modalData } from '@/components/elements/organisms/ConfirmationModal/ConfirmationModal'
import { NavigateContext } from '@/components/pages/App'
import Routes from '@/components/pages/ExtrasPage/routes'
import type { InitialValue } from '@/components/pages/ExtrasPage/screens/BasketPage/BasketPage'

// Mutations
import { CREATE_ORDER_MUTATION } from '../../mutations/createOrderMutation'

import type {
  nonCoreOrderCreate,
  nonCoreOrderCreateVariables
} from '../../mutations/__generated__/nonCoreOrderCreate'
import type {
  BasketPageQuery_user_subscription,
  BasketPageQuery_user_subscription_paymentMethods_CreditCardPaymentMethod as CreditCardPaymentMethod
} from '../../queries/__generated__/BasketPageQuery'
import type {
  Code as CountryCode,
  Currency
} from '@/shared_types/rails_models/shipping_countries'

export type Properties = {
  deliveriesReceived: number
  subscriptionStatus: SubscriptionStatus
  timestamp: Date
}

type Props = {
  value: InitialValue
  subscription: BasketPageQuery_user_subscription
  userId: string
  properties: Properties
  paymentDueDate?: Date | null
  shippingCountryCode: CountryCode
  totalPriceOfProducts: number
  locale: Locale
  currency: Currency
}

const BasketFooter = ({
  value,
  subscription,
  userId,
  properties,
  paymentDueDate,
  shippingCountryCode,
  totalPriceOfProducts,
  locale,
  currency
}: Props): JSX.Element => {
  const navigate = useContext(NavigateContext)
  const [isButtonClicked, setIsButtonClicked] = useState(false)
  const isNLMinimumAmountNotReached =
    shippingCountryCode === 'NL' &&
    totalPriceOfProducts < MINIMUM_ORDER_AMOUNT_NL

  const addressInput = {
    postcode: value.address.postcode,
    addressLineOne: value.address.address1,
    addressLineTwo: value.address.address2,
    recipientName: value.address.recipientName,
    city: value.address.city,
    deliveryNotes: value.address.deliveryNotes
  }

  const formattedDeliveryDate =
    value.deliveryDate &&
    formatISO(value.deliveryDate, { representation: 'date' })

  const [createOrderMutation, { loading, error, data }] = useMutation<
    nonCoreOrderCreate,
    nonCoreOrderCreateVariables
  >(CREATE_ORDER_MUTATION, {
    variables: {
      userId: userId,
      deliveryAddress: { ...addressInput },
      deliveryDate: formattedDeliveryDate
    }
  })

  const submitOrder = useCallback((): void => {
    createOrderMutation()
    window.analytics.track('Non Core Delivery Order Placed', properties)
  }, [createOrderMutation, properties])

  const lastFourDigits = () => {
    const activeCreditCardPaymentMethod = subscription.paymentMethods.find(
      (pm) => pm.__typename === 'CreditCardPaymentMethod' && pm.active
    ) as CreditCardPaymentMethod
    return activeCreditCardPaymentMethod?.lastFourDigits
  }

  useEffect(() => {
    if (isButtonClicked && isNLMinimumAmountNotReached) {
      window.analytics.track(
        'Disabled Pay Now button has been clicked (NL)',
        properties
      )
      setIsButtonClicked(false)
    }
  }, [isButtonClicked, isNLMinimumAmountNotReached, properties])

  if (data) {
    modalData({
      isOpen: true,
      namespace: 'thank_you',
      text: 'success_modal.order_placed',
      delay: 3000
    })
  }

  const errorCopy =
    error?.message ===
    `delivery_date: ${formattedDeliveryDate} is not available`
      ? 'date_error'
      : null
  const alert = !value.deliveryDate
    ? { namespace: 'one_time_purchase_footer', text: 'missing_date_error' }
    : null

  const infoText = isNLMinimumAmountNotReached && {
    namespace: 'dashboard',
    text: 'extras.basket.minimum_add_more_copy',
    variables: {
      price: formatCurrencyWithDecimal(MINIMUM_ORDER_AMOUNT_NL, {
        locale,
        currency
      }),
      remainingValue: formatCurrencyWithDecimal(
        MINIMUM_ORDER_AMOUNT_NL - totalPriceOfProducts,
        { locale, currency }
      )
    }
  }

  useEffect(() => {
    if (data) {
      navigate(
        `${ACCOUNT_ROUTES.extrasOnly}/${data.nonCoreOrderCreate?.order.id}?confirmation=true`,
        `${Routes.Confirmation}/?id=${data.nonCoreOrderCreate?.order.id}&confirmation=true`
      )
    }
  }, [data, navigate])

  return (
    <OneTimePurchaseFooter
      disabled={
        !value.deliveryDate ||
        isNLMinimumAmountNotReached ||
        subscription.status === 'suspended'
      }
      activePaymentMethodType={
        subscription.activePaymentMethodType as PaymentMethodable
      }
      lastFourDigits={lastFourDigits()}
      submit={submitOrder}
      error={!!error}
      errorCopy={errorCopy}
      loading={loading}
      paymentDueDate={paymentDueDate}
      setIsButtonClicked={setIsButtonClicked}
      infoText={infoText || null}
      shippingCountryCode={shippingCountryCode}
      alert={alert}
    />
  )
}

export { Props }

export default BasketFooter
