// @noflow
import { ensureNever } from '@/typescript/utils'

import * as ACTIONS from '../actions'
import fetchInitialDataQuery from '../graphql_queries/fetchInitialData'
import type { RequestResult } from '../graphql_queries/fetchInitialData'
import type { Dispatch, State, Thunk } from '../reducers'

const fetchInitialData = (): Thunk<Promise<void>> => {
  return (dispatch: Dispatch, getState: () => State): Promise<void> => {
    dispatch(ACTIONS.startRequest('fetchInitialData'))

    const { globalAttributes } = getState()
    const { token, userId } = globalAttributes
    return fetchInitialDataQuery({
      userId,
      token
    }).then((requestResult: RequestResult): void => {
      switch (requestResult.type) {
        case 'ServerError': {
          dispatch(ACTIONS.completeRequestWithError('fetchInitialData'))
          throw new Error(
            `Server Error: status=${requestResult.status}, message=${requestResult.message}`
          )
        }
        case 'GraphQLError': {
          dispatch(ACTIONS.completeRequestWithError('fetchInitialData'))
          // Join all error messages together into a single string:
          // ['A', 'B', 'C'] => 'A | B | C'
          const error = requestResult.errors.reduce(
            (acc: string, { message }: { message: string }): string =>
              `${acc} | ${message}`,
            ''
          )

          throw new Error(error)
        }
        case 'UnknownError': {
          dispatch(ACTIONS.completeRequestWithError('fetchInitialData'))
          throw new Error(requestResult.error)
        }
        case 'Success': {
          dispatch(ACTIONS.completeRequestWithSuccess('fetchInitialData'))
          const { data } = requestResult // eslint-disable-line no-unused-vars
          dispatch(ACTIONS.appDataReceieved(data.formData))
          dispatch(ACTIONS.globalDataReceieved(data.globalData))
          break
        }
        default: {
          ensureNever(requestResult)
        }
      }
    })
  }
}

export default fetchInitialData
