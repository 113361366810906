import React from 'react'

import { Locale } from '@/utils/countryCodeHelper'
import { Currency, formatCurrencyWithDecimal } from '@/utils/currency'

import Icon from '@/components/elements/atoms/Icon/Icon'
import Text, { TextProps } from '@/components/elements/atoms/Text'

import STYLES from './OrderSummaryRowItem.module.sass'

type Props = {
  label: TextProps
  price: number
  discountedPrice?: number | null
  locale: Locale
  currency: Currency
  isFree?: boolean
}

const OrderSummaryRowItem = ({
  label,
  price,
  discountedPrice,
  locale,
  currency,
  isFree = false
}: Props): JSX.Element => {
  const namespace = 'checkout'
  const copyContext = 'order_summary.summary'

  return (
    <div className={STYLES.summaryRowItem}>
      <Text
        text={label.text}
        namespace={label.namespace}
        translate={label.translate}
        variables={label.variables}
        variant="textRegular18"
      />
      <div className={STYLES.price}>
        {isFree && (
          <Icon asset="checkmark" size={15} accentColour="successGreen300" />
        )}
        {isFree ? (
          <Text
            text={`${copyContext}.free`}
            bold
            variant="textRegular18"
            namespace={namespace}
          />
        ) : discountedPrice ? (
          <Text
            text={formatCurrencyWithDecimal(discountedPrice, {
              locale,
              currency
            })}
            bold
            variant="textRegular18"
            translate={false}
          />
        ) : null}
        {discountedPrice || isFree ? (
          <Text
            text={`<accent type='strikeThrough'>${formatCurrencyWithDecimal(
              price,
              {
                locale,
                currency
              }
            )}</accent>`}
            variant="textRegular18"
            colour="brandBlue400"
            translate={false}
          />
        ) : (
          <Text
            text={`${formatCurrencyWithDecimal(price, {
              locale,
              currency
            })}`}
            variant="textRegular18"
            translate={false}
          />
        )}
      </div>
    </div>
  )
}

export default OrderSummaryRowItem
