import { gql } from '@apollo/client'

const RECOMMENDED_EXTRAS_FRAGMENT = gql`
  fragment recommendedExtrasFragment on ProductVariant {
    id
    name
    recurringDeliveryType {
      netPrice
    }
    productCollection {
      id
      name
      merchandisingImage {
        src
      }
      slug
    }
    grossPrice
  }
`

export { RECOMMENDED_EXTRAS_FRAGMENT }
