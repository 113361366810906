import { gql } from '@apollo/client'

const CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      __typename
      ... on User {
        marketingPreference
      }
      ... on PreWizardGuest {
        trackingId
      }
    }
    availableMarketingMethodPurposes {
      id
      marketingPurpose {
        id
        key
        name
        encouraged
      }
    }
  }
`
export { CURRENT_USER }
