import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import Image from '@/components/elements/atoms/Image'

import STYLES from './Pouch.module.sass'

type PouchProps = {
  double?: boolean
  children: ReactNode
}

const Pouch = ({ double = false, children }: PouchProps): JSX.Element => {
  const namespace = 'atoms'
  const { t } = useTranslation(namespace)

  return (
    <div className={STYLES.slide}>
      <div className={STYLES.pouch}>
        {double && (
          <Image
            className={STYLES.double}
            slug={'generic-pouch'}
            image={{ width: 200, height: 320 }}
            alt={t(`pouch.pouch_alt`)}
          />
        )}
        <Image
          slug={'generic-pouch'}
          image={{ width: 200, height: 320 }}
          alt={t(`pouch.pouch_alt`)}
        />
        <div className={STYLES.label}>
          <div className={STYLES.logo}>
            <Image
              slug="account-logo"
              alt={t('pouch.logo_alt')}
              image={{ width: 60, height: 48 }}
            />
          </div>
          <div className={STYLES.details}>{children}</div>
        </div>
      </div>
    </div>
  )
}

export default Pouch
