import { gql } from '@apollo/client'

const ARCHIVE_DOG_PROFILE_MUTATION = gql`
  mutation ArchiveDogProfileMutation($dogIds: [ID!]!, $archiveReason: String!) {
    archiveDogs(dogIds: $dogIds, archiveReason: $archiveReason) {
      user {
        id
        dogs {
          id
        }
      }
    }
  }
`

export { ARCHIVE_DOG_PROFILE_MUTATION }
