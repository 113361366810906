import { gql } from '@apollo/client'

const SAMPLE_PRODUCT_UPSELLABLE_QUERY = gql`
  query sampleProductUpsellableQuery {
    user {
      id
      shippingCountryCode
      preferredLanguage
      dogs {
        gender
        name
      }
      subscription {
        upsellableSampleProduct {
          id
          name
          grossPrice
          recurringDeliveryType {
            adjustedGrossPrice
          }
          productCollection {
            slug
            name
            thumbnail {
              src
            }
          }
        }
      }
    }
  }
`

export { SAMPLE_PRODUCT_UPSELLABLE_QUERY }
