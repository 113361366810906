// @noflow
import { Stripe, StripeCardElement } from '@stripe/stripe-js'
import i18next from 'i18next'

import * as Sentry from '@/utils/sentry'

import client from '@/components/apollo/client'
import type {
  AddDiscountToGuest,
  AddDiscountToGuestVariables
} from '@/components/pages/PlansPage/components/RedeemDiscount/queries/__generated__/AddDiscountToGuest'
// GraphQL
import { ADD_DISCOUNT_TO_GUEST } from '@/components/pages/PlansPage/components/RedeemDiscount/queries/redeemDiscount'

import { STRIPE_CHARGE_BOX_ONE_MUTATION } from '../mutations/stripeChargeBoxOneAndSetupPaymentMethod'
import { STRIPE_USER_PARAMS_SETUP_INTENT_CREATE_MUTATION } from '../mutations/stripeUserParamsSetupIntentCreate'
import { CHARGE_ID_FROM_PAYMENT_INTENT_ID } from '../queries/chargeIdFromPaymentIntentId'

import { StripeChargeBoxOneAndSetupPaymentMethod } from '../mutations/__generated__/StripeChargeBoxOneAndSetupPaymentMethod'
import { StripeUserParamsSetupIntentCreate } from '../mutations/__generated__/StripeUserParamsSetupIntentCreate'
import { ChargeIdFromPaymentIntentId } from '../queries/__generated__/ChargeIdFromPaymentIntentId'

import * as ANALYTICS from '../../../Analytics/CheckoutAnalytics'
import { bannerMessageState } from '../Banner'
import { setSubmissionState } from '../PaymentSection'
import { handleSubmitSubscription } from './buySubscriptionWithCard'
import { formatSubscriptionDataAddressForGraphql } from './formatSubscriptionDataAddressForGraphql'
import requestFromSCAController from './requestFromSCAController'
import { SubscriptionData } from './submitSubscription'

const createUserParams = async ({
  data,
  paymentIntentId
}: {
  data: SubscriptionData
  paymentIntentId: string | null
}) => {
  const subscriptionData = formatSubscriptionDataAddressForGraphql(data)

  await client.mutate<StripeUserParamsSetupIntentCreate>({
    mutation: STRIPE_USER_PARAMS_SETUP_INTENT_CREATE_MUTATION,
    variables: {
      authenticationType: 'credit_card',
      subscriptionData,
      paymentIntentId
    }
  })
}

const confirmPaymentRequestPayment = async ({
  stripe,
  clientSecret,
  paymentMethodId
}: {
  stripe: Stripe
  clientSecret: string
  paymentMethodId: string
}) => {
  // Confirm the PaymentIntent without handling potential next actions (yet).
  const { paymentIntent, error: confirmError } =
    await stripe.confirmCardPayment(
      clientSecret,
      {
        payment_method: paymentMethodId
      },
      { handleActions: false }
    )
  if (!confirmError && paymentIntent) return paymentIntent.id

  if (confirmError) throw new Error(confirmError.message)
  if (!paymentIntent) throw new Error('Error confirming the payment')

  // Check if the PaymentIntent requires any actions and,
  // if so, let Stripe.js handle the flow
  if (paymentIntent.status === 'requires_action') {
    const { paymentIntent: handledPaymentIntent, error } =
      await stripe.confirmCardPayment(clientSecret)
    if (error) throw new Error(error.message)
    if (!handledPaymentIntent) throw new Error('Error confirming the payment')

    return handledPaymentIntent.id
  }

  return undefined
}

const retrieveChargeId = async ({
  paymentIntentId
}: {
  paymentIntentId: string
}) => {
  const result = await client.query<ChargeIdFromPaymentIntentId>({
    query: CHARGE_ID_FROM_PAYMENT_INTENT_ID,
    variables: {
      paymentIntentId: paymentIntentId,
      paymentMethodType: 'credit_card'
    }
  })

  if (result.error) {
    throw new Error(result.error.message)
  }

  return result.data.chargeIdFromPaymentIntentId
}

const setupPaymentIntent = async () => {
  const response = await client.mutate<StripeChargeBoxOneAndSetupPaymentMethod>(
    {
      mutation: STRIPE_CHARGE_BOX_ONE_MUTATION,
      variables: {
        paymentMethodType: 'card'
      }
    }
  )

  if (response.errors) throw new Error(response.errors[0].message)

  return response?.data?.stripeChargeBoxOneAndSetupPaymentMethod
}

export const chargePaymentRequestBoxOne = async ({
  data,
  paymentMethodId,
  stripe
}: {
  data: SubscriptionData
  paymentMethodId: string
  stripe: Stripe
}): Promise<string | undefined> => {
  const paymentIntent = await setupPaymentIntent()
  if (!paymentIntent) return

  const {
    stripeSecretToken: clientSecret,
    boxCanBeCharged,
    paymentIntentId
  } = paymentIntent

  if (!boxCanBeCharged || !clientSecret || !paymentIntentId) return

  await createUserParams({ data, paymentIntentId })
  const PaymentRequestPaymentIntentId = await confirmPaymentRequestPayment({
    stripe,
    clientSecret,
    paymentMethodId
  })
  if (!PaymentRequestPaymentIntentId) return

  return await retrieveChargeId({
    paymentIntentId: PaymentRequestPaymentIntentId
  })
}

const updateSessionDiscountCode = () => {
  const sessionDiscountCode = window.sessionStorage.getItem('discount_code')
  // NOTE: resolves the promise to continue with the chained execution
  if (!sessionDiscountCode) return Promise.resolve()

  return client.mutate<AddDiscountToGuest, AddDiscountToGuestVariables>({
    mutation: ADD_DISCOUNT_TO_GUEST,
    variables: { discountCode: sessionDiscountCode }
  })
}

export const chargeCardBoxOne = ({
  stripe,
  cardElement,
  data,
  csrfToken,
  email
}: {
  stripe: Stripe
  cardElement: StripeCardElement
  data: SubscriptionData
  csrfToken: string
  email: string
}): void => {
  // NOTE: updates the discount if found and continues the execution
  updateSessionDiscountCode()
    .then(() => {
      client
        .mutate<StripeChargeBoxOneAndSetupPaymentMethod>({
          mutation: STRIPE_CHARGE_BOX_ONE_MUTATION,
          variables: {
            paymentMethodType: 'card'
          }
        })
        .then((res) => {
          if (res.errors) {
            bannerMessageState({
              message: i18next.t('checkout:errors.delivery_details_fetch'),
              type: 'error'
            })
          }

          if (res?.data?.stripeChargeBoxOneAndSetupPaymentMethod) {
            const {
              stripeSecretToken: clientSecret,
              boxCanBeCharged,
              paymentIntentId
            } = res.data.stripeChargeBoxOneAndSetupPaymentMethod

            if (boxCanBeCharged && clientSecret) {
              const formattedSubData =
                formatSubscriptionDataAddressForGraphql(data)

              client.mutate<StripeUserParamsSetupIntentCreate>({
                mutation: STRIPE_USER_PARAMS_SETUP_INTENT_CREATE_MUTATION,
                variables: {
                  authenticationType: 'credit_card',
                  subscriptionData: formattedSubData,
                  paymentIntentId: paymentIntentId
                }
              })

              stripe
                .confirmCardPayment(clientSecret, {
                  payment_method: {
                    card: cardElement
                  }
                })
                .then((res) => {
                  if (res.error) {
                    bannerMessageState({
                      message:
                        res.error?.message ||
                        i18next.t('checkout:errors.delivery_details_fetch'),
                      type: 'error'
                    })

                    setSubmissionState({
                      type: 'error',
                      error:
                        res.error?.message ||
                        i18next.t('checkout:errors.delivery_details_fetch')
                    })
                  }

                  if (res.paymentIntent?.payment_method) {
                    const paymentIntentId = res.paymentIntent.id
                    const paymentMethodId = res.paymentIntent.payment_method

                    client
                      .query<ChargeIdFromPaymentIntentId>({
                        query: CHARGE_ID_FROM_PAYMENT_INTENT_ID,
                        variables: {
                          paymentIntentId: paymentIntentId,
                          paymentMethodType: 'credit_card'
                        }
                      })
                      .then((result) => {
                        if (result.error) {
                          Sentry.captureException(
                            'Error fetching charge ID from Intent ID',
                            {
                              extra: {
                                message: result.error.message
                              },
                              tags: {
                                product: Sentry.Product.Checkout
                              }
                            }
                          )
                          bannerMessageState({
                            message: i18next.t(
                              'checkout:errors.delivery_details_fetch'
                            ),
                            type: 'error'
                          })
                        }
                        handleSubmitSubscription({
                          data,
                          csrfToken,
                          paymentMethodId: paymentMethodId,
                          paymentMethodType: 'creditCard',
                          boxOneChargeId:
                            result.data.chargeIdFromPaymentIntentId
                        })
                      })
                      .catch((err) => {
                        Sentry.captureException(
                          'Error fetching charge ID from Intent ID',
                          {
                            extra: {
                              message: err.message
                            },
                            tags: {
                              product: Sentry.Product.Checkout
                            }
                          }
                        )

                        bannerMessageState({
                          message: i18next.t(
                            'checkout:errors.delivery_details_fetch'
                          ),
                          type: 'error'
                        })
                      })
                  }
                })
            } else {
              requestFromSCAController({ data, email, csrfToken }).then(
                (res) => {
                  stripe
                    .confirmCardSetup(
                      res.card_authentication_setup.client_secret,
                      {
                        payment_method: { card: cardElement }
                      }
                    )
                    .then((result) => {
                      if (result?.setupIntent?.payment_method) {
                        handleSubmitSubscription({
                          data,
                          csrfToken,
                          paymentMethodId: result.setupIntent.payment_method,
                          paymentMethodType: 'creditCard'
                        })
                      } else {
                        bannerMessageState({
                          message:
                            result.error?.message ||
                            i18next.t('checkout:errors.delivery_details_fetch'),
                          type: 'error'
                        })

                        setSubmissionState({
                          type: 'error',
                          error:
                            result.error?.message ||
                            i18next.t('checkout:errors.delivery_details_fetch')
                        })

                        ANALYTICS.setPaymentError({
                          error:
                            result.error?.message ||
                            'Checkout Error in requestFromSCAController',
                          paymentMethod: 'creditCard'
                        })
                      }
                    })
                }
              )
            }
          }
        })
        .catch(() => {
          bannerMessageState({
            message: i18next.t('checkout:errors.delivery_details_fetch'),
            type: 'error'
          })
        })
    })
    .catch((error) => {
      bannerMessageState({
        message: error.message,
        type: 'error'
      })

      setSubmissionState({
        type: 'error',
        error: error.message
      })
    })
}
