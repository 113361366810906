import classnames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'

import STYLES from './Pill.module.sass'

type Icon = {
  src: string
  alt?: string
}

type Version = 'v2'

type Props = {
  variant?: keyof typeof STYLES
  namespace?: string
  text: string
  id: string
  dataTestId?: string
  name: string
  value: string | number
  icon?: Icon
  defaultChecked?: boolean
  checked?: boolean
  onChange?: (e?: React.FormEvent<HTMLDivElement>, index?: number) => void
  onClick?: (e?: React.MouseEvent, index?: number) => void
  warning?: boolean
  translate?: boolean
  colour?: 'white' | 'yellow300' | 'red400'
  showCrossOnChecked?: boolean
  version?: Version
}

const Pill = ({
  variant = 'radio',
  namespace = '',
  text,
  icon,
  id,
  dataTestId,
  name,
  value,
  defaultChecked,
  checked,
  onChange,
  onClick,
  warning = false,
  translate = true,
  colour,
  showCrossOnChecked = false,
  version
}: Props): JSX.Element => {
  const { t } = useTranslation(namespace)

  const className = classnames(STYLES.pill, {
    [STYLES[variant]]: variant,
    ...(colour && { [STYLES[colour]]: colour }),
    ...(version && { [STYLES[version]]: version })
  })

  const inputClassName = classnames({
    [STYLES.checked]: checked && !showCrossOnChecked,
    [STYLES.crossed]: checked && showCrossOnChecked
  })

  const labelClassName = classnames({ [STYLES.warning]: warning })

  return (
    <div className={className}>
      <input
        id={id}
        data-testid={dataTestId}
        name={name}
        type={variant}
        value={value}
        defaultChecked={defaultChecked}
        checked={checked}
        onChange={onChange}
        readOnly={variant && variant === 'deletable'}
        tabIndex={0}
        // NOTE: Checked class was added to fix a bug where in certain situations styling
        //       is not applied initially when using the :checked selector and moving between
        //       routes.
        className={inputClassName}
      />
      <label className={labelClassName} aria-label="Pill" htmlFor={id}>
        {icon && <img src={icon.src} alt={icon.alt} />}
        <span>{translate ? t(text) : text}</span>
        {variant && variant === 'deletable' && (
          <button type="button" onClick={onClick} />
        )}
      </label>
    </div>
  )
}

export { Props }
export default Pill
