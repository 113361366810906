import { gql } from '@apollo/client'

import { BOX_ORDER_FRAGMENT } from '@/components/pages/Dashboard/components/boxDetails/fragments/boxOrderFragment'

import { BOX_PHYSICAL_ORDER_PRODUCTS } from '../fragments/fragments'

const UPDATE_ORDER_PRODUCTS = gql`
  # eslint-disable-next-line
  ${BOX_PHYSICAL_ORDER_PRODUCTS}
  ${BOX_ORDER_FRAGMENT}
  mutation UpdateOrderProducts(
    $userId: ID!
    $boxId: ID!
    $orderProducts: [ProductInput!]!
  ) {
    orderProductsUpdate(
      userId: $userId
      boxId: $boxId
      orderProducts: $orderProducts
    ) {
      id
      box(boxId: $boxId) {
        ...BoxPhysicalOrderProducts
        ...BoxOrderFragment
      }
    }
  }
`

const UPDATE_RECURRING_ORDER_PRODUCTS = gql`
  # eslint-disable-next-line
  ${BOX_PHYSICAL_ORDER_PRODUCTS}
  ${BOX_ORDER_FRAGMENT}
  mutation UpdateRecurringOrderProducts(
    $userId: ID!
    $boxId: ID!
    $recurringOrderProducts: [ProductInput!]!
  ) {
    recurringOrderProductsUpdate(
      userId: $userId
      recurringOrderProducts: $recurringOrderProducts
    ) {
      id
      box(boxId: $boxId) {
        ...BoxPhysicalOrderProducts
        ...BoxOrderFragment
      }
    }
  }
`

export { UPDATE_ORDER_PRODUCTS, UPDATE_RECURRING_ORDER_PRODUCTS }
