// @flow

import * as React from 'react'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'

import { toValidCSRFToken } from '@/shared_types/csrfToken'
import { stepPersistance } from './middlewares'

import SingleCustomerViewIndexContainer from './containers/SingleCustomerViewIndexContainer'

import reducer, { init } from './reducers'

import type {
  SearchResult,
  AccountDetails,
  SubscriptionDetails,
  ApplicableDiscountPartsDetails,
  DeliveriesDetails,
  DogsDetails,
  CustomerHistory,
  ContactPreferences,
  MarketingPreferenceData,
  DeliveriesHistory,
  AccountStatusFilter,
  ShippingDetails
} from './message_types'

import type { SearchParams, FetchingStatuses } from './actions'
import type { CalendarDate, DeliveryWindow } from '../../shared/DatePicker'
import type { CSRFToken } from '@/shared_types/csrfToken'
import type { ShippingCountry } from '@/shared_types/rails_models/shipping_countries'
import type { Store } from 'redux'

export type State = $ReadOnly<{|
  csrfToken: CSRFToken,
  fetchingStatuses: FetchingStatuses,
  bannerMessage: $ReadOnly<{|
    showMessage: boolean,
    message: string
  |}>,
  searchParams: SearchParams,
  isSearching: boolean,
  viewingSearchResults: boolean,
  searchResults: Array<SearchResult>,
  selectedUserIds: $ReadOnly<{|
    userId: number,
    subscriptionId: number
  |}>,
  subscriptionDetails: SubscriptionDetails,
  deliveriesDetails: DeliveriesDetails,
  applicableDiscountsDetails: ApplicableDiscountPartsDetails,
  dogsDetails: DogsDetails,
  accountDetails: AccountDetails,
  customerHistory: CustomerHistory,
  contactPreferences: ContactPreferences,
  marketingPreferences: MarketingPreferenceData,
  deliveries_history: DeliveriesHistory,
  deliveryDateCalendar: $ReadOnly<{|
    isOpen: boolean,
    boxId: number,
    deliveryWindow: DeliveryWindow,
    selectedDate: ?CalendarDate,
    previousDeliveryDate: string,
    targetNumberOfCells: number,
    currentDeliveryDate: string,
    adjustFutureBoxes: boolean,
    disableAdjustFutureBoxes: boolean
  |}>,
  shippingDetails: ShippingDetails,
  shippingCountries: Array<ShippingCountry>
|}>

type Props = $ReadOnly<{|
  csrfToken: ?string,
  userId: ?number,
  subscriptionId: ?number,
  firstName: ?string,
  lastName: ?string,
  email: ?string,
  phone: ?string,
  postcode: ?string,
  dogsName: ?string,
  dogsBreed: ?string,
  rafCode: ?string,
  accountStatus: ?AccountStatusFilter,
  shippingCountryCode: ?string,
  shippingCountries: ?string
|}>

const app = (store: Store): React.Node => (
  <Provider store={store}>
    <div>
      <SingleCustomerViewIndexContainer />
    </div>
  </Provider>
)

const SingleCustomerView = (props: Props): React.Node => {
  // To use Redux extension in browser
  // https://github.com/zalmoxisus/redux-devtools-extension#usage
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  console.log('SOC is working')

  const {
    csrfToken,
    firstName,
    lastName,
    email,
    phone,
    postcode,
    dogsName,
    dogsBreed,
    rafCode,
    accountStatus,
    shippingCountryCode
  } = props

  if (!csrfToken) {
    throw new Error('SOC: Cannot load CSRF token')
  }
  // Assign default values to the initial search params
  const userId = props.userId || -1
  const subscriptionId = props.subscriptionId || -1
  const searchParams: SearchParams = {
    firstName: firstName || '',
    lastName: lastName || '',
    email: email || '',
    postcode: postcode || '',
    phone: phone || '',
    dogsName: dogsName || '',
    dogsBreed: dogsBreed || '',
    rafCode: rafCode || '',
    accountStatus: accountStatus || 'not-selected',
    shippingCountryCode: shippingCountryCode || 'not-selected'
  }
  const shippingCountries = props.shippingCountries ? JSON.parse(props.shippingCountries) : []

  const validCSRFToken = toValidCSRFToken(csrfToken)
  if (!validCSRFToken) {
    throw new Error(`CSRF Token is invalid ${csrfToken}`)
  }

  const middleware = [thunk, stepPersistance]
  const store = createStore(
    reducer,
    init(
      validCSRFToken,
      userId,
      subscriptionId,
      searchParams,
      shippingCountries
    ),
    composeEnhancers(
      applyMiddleware(...middleware)
    )
  )
  return app(store)
}

export default SingleCustomerView
