import React, { useCallback } from 'react'

import Card from '@/components/elements/atoms/Card/Card'
import SkeletonTitle from '@/components/elements/atoms/SkeletonTitle/SkeletonTitle'
import Text from '@/components/elements/atoms/Text/Text'
import WizardNavigation from '@/components/elements/organisms/WizardNavigation/WizardNavigation'
import {
  currentRouteToCurrentStep,
  currentRouteToPercentFilled,
  currentRouteToTotalStep
} from '@/components/pages/SimplifiedPlansPage/helpers/plansNavigation'
import { Routes as PlansRoute } from '@/components/pages/SimplifiedPlansPage/types/routes'

const SkeletonLoaderCard = ({ text }: { text: string }): JSX.Element => {
  const namespace = 'checkout'

  return (
    <div className="checkout-loading-card">
      <Card shadow>
        <div>
          <Text
            text={text}
            variant="display20"
            element="h2"
            namespace={namespace}
            margin={false}
            shouldScale={false}
            align="left"
          />
          <SkeletonTitle width="100%" height="1.5rem" margin={0} />
        </div>
      </Card>
    </div>
  )
}
const LoadingCheckoutPage = ({
  hasRecommendedExtras,
  shouldSeeCourierSelection,
  shouldSeePetParentOnPlans
}: {
  hasRecommendedExtras: boolean
  shouldSeeCourierSelection: boolean
  shouldSeePetParentOnPlans: boolean
}): JSX.Element => {
  const namespace = 'checkout'
  const handleBackButton = useCallback(() => {
    window.location.href = hasRecommendedExtras
      ? PlansRoute.Extras
      : PlansRoute.Plan
  }, [hasRecommendedExtras])
  const dateTitle = shouldSeeCourierSelection ? 'title_alternative' : 'title'

  return (
    <div className="checkout-wrapper">
      <div className="checkout__progress-bar-wrapper">
        <WizardNavigation
          variant="simplified"
          percentFilled={currentRouteToPercentFilled({
            route: PlansRoute.Checkout,
            hasRecommendedExtras,
            shouldSeePetParentOnPlans
          })}
          currentStep={currentRouteToCurrentStep({
            route: PlansRoute.Checkout,
            hasRecommendedExtras,
            shouldSeePetParentOnPlans
          })}
          totalSteps={currentRouteToTotalStep(
            hasRecommendedExtras,
            shouldSeePetParentOnPlans
          )}
          backOnClick={handleBackButton}
        />
      </div>
      <Text
        namespace={namespace}
        text="title"
        element="h1"
        variant="display28"
        margin={false}
      />
      <div className="content-wrapper">
        <div>
          <SkeletonLoaderCard text="account_section.title" />
          <SkeletonLoaderCard text="delivery_address_section.title" />
          <SkeletonLoaderCard text={`delivery_date_section.${dateTitle}`} />
          <SkeletonLoaderCard text="payment_section.title" />
        </div>
        <div className="order-summary-wrapper">
          <SkeletonLoaderCard text="order_summary.title" />
        </div>
      </div>
      <footer>
        <Text
          namespace={namespace}
          text="copyright"
          variant="textRegular18"
          variables={{
            fullDate: new Date().getFullYear()
          }}
        />
      </footer>
    </div>
  )
}

export default LoadingCheckoutPage
