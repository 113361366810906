// @noflow
import { ACCOUNT_ROUTES } from '@/routes'
import upperFirst from 'lodash/upperFirst'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import FlatButton from '@/components/elements/atoms/FlatButton/FlatButton'
import Image from '@/components/elements/atoms/Image/Image'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import Separator from '@/components/elements/atoms/Separator/Separator'
import Text from '@/components/elements/atoms/Text/Text'
import Tooltip from '@/components/elements/atoms/Tooltip/Tooltip'
import ActionCard from '@/components/elements/molecules/ActionCard/ActionCard'
import CalorieBar from '@/components/elements/molecules/CalorieBar/CalorieBar'
import ExpandCard from '@/components/elements/molecules/ExpandCard/ExpandCard'

import STYLES from './PersonalityDetails.module.sass'

import type { DogProfileQuery_user_dogs as Dog } from '../../../queries/__generated__/DogProfileQuery'

type Props = {
  dog?: Dog
  loading?: boolean
}

const PersonalityDetails = ({ dog, loading = false }: Props): JSX.Element => {
  const { windowWidth } = useWindowSize()
  const navigate = useNavigate()
  const { t } = useTranslation('account')

  const isMobile = windowWidth < BREAKPOINTS.md
  const copyContext = 'dog_profile.personality_details'

  const years = Math.floor((dog?.ageInMonths ?? 0) / 12)
  const months = (dog?.ageInMonths ?? 0) % 12
  const unknownBreed = dog?.breed.key === 'other'

  const onPersonalityDetailsEdit = useCallback((): void => {
    navigate(
      generatePath(ACCOUNT_ROUTES.dogAboutInfo, { dogId: dog?.id ?? '' })
    )
  }, [navigate, dog?.id])

  const onPlanEdit = useCallback((): void => {
    navigate(ACCOUNT_ROUTES.planManagement)
  }, [navigate])

  return (
    <div className={`${STYLES.wrapper} ${loading ? STYLES.loading : ''}`}>
      <ActionCard
        shadow
        button={{
          text: {
            text: 'dog_profile.edit_cta',
            namespace: 'account'
          },
          onClick: onPersonalityDetailsEdit
        }}
        padding={isMobile ? 24 : 32}
        skeleton={{
          isLoading: loading,
          height: '100%'
        }}
        height={isMobile ? 'auto' : '35.5rem'}
        fill={false}
      >
        <SectionWrapper
          headerTypography={{
            text: upperFirst(dog?.name),
            translate: false,
            variant: 'display28',
            margin: false,
            shouldScale: false
          }}
          margin={{
            top: 0,
            bottom: 0
          }}
        >
          <Text
            text={[
              unknownBreed
                ? t(`${copyContext}.breed_unknown`)
                : upperFirst(dog?.breed.name),
              t(`${copyContext}.neutered_${dog?.neutered ? 'yes' : 'no'}`, {
                context: dog?.gender ?? ''
              })
            ].join(' | ')}
            translate={false}
            margin={false}
          />
          <Text
            text={[
              years > 0 &&
                t(`${copyContext}.age_year`, {
                  count: years,
                  years
                }),
              months > 0 &&
                t(`${copyContext}.age_month`, {
                  count: months,
                  months
                })
            ]
              .filter(Boolean)
              .join(', ')}
            translate={false}
            margin={false}
          />
        </SectionWrapper>
        <Separator
          handdrawn
          margin={{ top: isMobile ? 16 : 24, bottom: isMobile ? 16 : 24 }}
        />
        <div className={STYLES.calorieGuidanceWrapper}>
          <SectionWrapper
            margin={{
              top: 0,
              bottom: 0
            }}
            headerTypography={{
              namespace: 'account',
              text: `${copyContext}.calorie_guidance.title`,
              variant: 'textRegular16',
              variables: {
                possessivePronoun: upperFirst(dog?.possessivePronoun ?? '')
              },
              margin: false
            }}
            headerRightAdornment={{
              variant: 'custom',
              content: (
                <FlatButton
                  text={{
                    text: 'dog_profile.edit_cta',
                    namespace: 'account'
                  }}
                  onClick={onPlanEdit}
                  identifier="dog_profile.personality_details.edit_plan"
                />
              )
            }}
          >
            <div className={STYLES.planPortionWrapper}>
              <Image
                alt={t(`${copyContext}.calorie_guidance.image_alt`)}
                slug="duck-and-chicken-bowl"
                image={{
                  width: 40,
                  height: 40
                }}
              />
              <Text
                text={`${copyContext}.calorie_guidance.plan_portion_in_grams`}
                namespace="account"
                variant="display24"
                variables={{
                  planPortionInGrams: dog?.planPortionInGrams ?? 0
                }}
                margin={false}
                shouldScale={false}
              />
            </div>
          </SectionWrapper>
          <SectionWrapper
            bgColour="brandYellow100"
            borderRadius={16}
            padding={24}
            margin={{
              top: 0,
              bottom: 0
            }}
          >
            <CalorieBar
              lowerBound={dog?.roundedDailyCaloriesLowerBoundary ?? 0}
              upperBound={dog?.roundedDailyCaloriesUpperBoundary ?? 2000}
              value={dog?.roundedPlanPortionInCalories ?? 0}
            />
            <div className={STYLES.calorieGuidanceInfo}>
              <div className={STYLES.calorieGuidanceLegendKey} />
              <Text
                text="="
                translate={false}
                margin={false}
                colour="brandYellow700"
              />
              <div className={STYLES.calorieGuidanceText}>
                <Text
                  element="span"
                  text={`${copyContext}.calorie_guidance.info`}
                  margin={false}
                  namespace="account"
                  colour="brandYellow700"
                  variables={{
                    dogName: upperFirst(dog?.name),
                    possessivePronoun: dog?.possessivePronoun,
                    min: dog?.roundedDailyCaloriesLowerBoundary,
                    max: dog?.roundedDailyCaloriesUpperBoundary
                  }}
                />
                <Tooltip
                  label={{
                    element: 'span',
                    text: `${copyContext}.calorie_guidance.tooltip.key`,
                    namespace: 'account',
                    colour: 'brandYellow700',
                    underline: true
                  }}
                  size={16}
                  displayIcon={false}
                  identifier="dog_profile.personality_details.calorie_guidance.tooltip"
                  variant="brandYellow200"
                >
                  <Text
                    element="span"
                    namespace="account"
                    text={`${copyContext}.calorie_guidance.tooltip.text`}
                    margin={false}
                  />
                </Tooltip>
              </div>
            </div>
          </SectionWrapper>
          <ExpandCard
            text={{
              text: `${copyContext}.calorie_guidance.mix_feeding.title`,
              namespace: 'account',
              margin: false,
              variant: 'textRegular16',
              bold: false
            }}
            variant="brandYellow100"
            size="small"
            border="none"
            shadow={false}
            closeOnOutsideClick
            identifier="dog_profile.personality_details.calorie_guidance.mix_feeding"
          >
            <Text
              text={`${copyContext}.calorie_guidance.mix_feeding.text`}
              namespace="account"
              variables={{
                possessivePronoun: dog?.possessivePronoun ?? '',
                halfCalories: dog?.roundedPlanPortionInCalories
                  ? dog?.roundedPlanPortionInCalories / 2
                  : 0
              }}
              margin={false}
            />
          </ExpandCard>
        </div>
      </ActionCard>
    </div>
  )
}

export { PersonalityDetails }
