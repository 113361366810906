import { isUndefined } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { animated, useSpring } from 'react-spring'

// Styles
import STYLES from './caloriebar.module.sass'

import Icon from '../../atoms/Icon/Icon'
import Text from '../../atoms/Text'

type Props = {
  scaleStart?: number
  scaleEnd?: number
  lowerBound?: number
  upperBound?: number
  value?: number
}

const CalorieBar = ({
  scaleStart = 0,
  scaleEnd = 2000,
  lowerBound,
  upperBound,
  value = 0
}: Props): JSX.Element => {
  const scale = scaleEnd - scaleStart
  const lowerBoundPercentage = useMemo(
    () => (!isUndefined(lowerBound) ? (lowerBound / scale) * 100 : 0),
    [lowerBound, scale]
  )
  const upperBoundPercentage = useMemo(
    () => (!isUndefined(upperBound) ? (upperBound / scale) * 100 : 0),
    [upperBound, scale]
  )
  const calorieRangePercentage = upperBoundPercentage - lowerBoundPercentage
  const [valuePercentage, setValuePercentage] = useState(0)

  const [valueLeft, api] = useSpring(() => ({
    config: { mass: 1, tension: 500, friction: 14 },
    left: `${valuePercentage}%`
  }))

  useEffect(() => {
    api.start({ left: `${valuePercentage}%` })
  }, [api, valuePercentage])

  useEffect(() => {
    const newValue = (value / scale) * 100
    setValuePercentage(newValue > 100 ? 100 : newValue)
  }, [scale, value])

  return (
    <div
      className={`${STYLES.container} ${
        valuePercentage >= 100 ? STYLES.short : ''
      }`}
    >
      <div className={STYLES.scale}>
        <Text
          colour="brandYellow700"
          text={`${scaleStart} kcals`}
          translate={false}
          margin={false}
        />
        <Text
          colour="brandYellow700"
          text={`${scaleEnd}${valuePercentage >= 100 ? '+' : ''} kcals`}
          translate={false}
          margin={false}
        />
      </div>
      <div className={STYLES.bar}>
        <div
          className={STYLES.fill}
          style={{
            width: `${calorieRangePercentage}%`,
            left: `${lowerBoundPercentage}%`
          }}
        />
        <div
          className={STYLES.lowerBound}
          style={{ left: `${lowerBoundPercentage}%` }}
        />
        <div
          className={STYLES.upperBound}
          style={{ left: `${upperBoundPercentage}%` }}
        />
        <animated.div className={STYLES.value} style={valueLeft}>
          <Icon asset="chevron" size={12} direction="up" />
          <Text
            colour="brandBlue500"
            text={`${value} kcal`}
            translate={false}
            margin={false}
            bold
          />
        </animated.div>
      </div>
    </div>
  )
}

export { Props }
export default CalorieBar
