// @noflow
import React, { useCallback, useState } from 'react'

import type { BrandColours } from '@/constants/BrandColours'

import Icon from '@/components/elements/atoms/Icon/Icon'
import type { StarTypes } from '@/components/elements/atoms/Icon/Icons/Star'

import STYLES from './StarRating.module.sass'

type AllowedRatings = 0 | 0.5 | 1 | 1.5 | 2 | 2.5 | 3 | 3.5 | 4 | 4.5 | 5

type Props = {
  rating?: AllowedRatings
  selectable?: boolean
  responsive?: boolean
  accentColour?: keyof BrandColours
  iconSize?: number
  iconWidth?: number
  className?: string
  onSelect?: (rating: AllowedRatings) => void
}

type InteractiveStarProps = {
  onClick: (rating: number) => void
  onHover: (rating: number) => void
  index: number
  type: StarTypes
  accentColour: Props['accentColour']
  responsive: Props['responsive']
  iconSize: number
  iconWidth: number
}

const InteractiveStar = ({
  onClick,
  onHover,
  index,
  type,
  accentColour,
  responsive,
  iconSize,
  iconWidth
}: InteractiveStarProps) => {
  const handleSelect = useCallback(() => {
    onClick(index + 1)
  }, [index, onClick])

  const handleHover = useCallback(() => {
    onHover(index + 1)
  }, [index, onHover])

  return (
    <span
      className={STYLES.interactiveStar}
      onClick={handleSelect}
      onKeyDown={handleSelect}
      onMouseOver={handleHover}
      onFocus={handleHover}
      tabIndex={index}
      role="button"
    >
      <Icon
        asset="star"
        accentColour={accentColour}
        size={iconSize}
        width={iconWidth}
        starType={type}
        responsive={responsive}
      />
    </span>
  )
}

const StarRating = ({
  rating = 0,
  selectable = false,
  onSelect,
  accentColour = 'brandYellow300',
  responsive = true,
  iconSize = 28,
  iconWidth = 30,
  className = ''
}: Props): JSX.Element => {
  const [hoverRating, setHoverRating] = useState<AllowedRatings>(0)
  const [hovering, setHovering] = useState<boolean>(false)
  const [selectedRating, setSelectedRating] = useState<AllowedRatings>(0)
  const handleSelect = useCallback(
    (rating) => {
      if (selectable && onSelect) {
        onSelect(rating)
        setSelectedRating(rating)
      }
    },
    [onSelect, selectable]
  )

  const handleHover = useCallback(
    (rating) => {
      if (selectable) {
        setHoverRating(rating)
        setHovering(true)
      }
    },
    [setHoverRating, selectable]
  )

  const handleMouseOut = useCallback(() => {
    if (selectable) {
      setHoverRating(0)
      setHovering(false)
    }
  }, [setHoverRating, selectable])

  let ratingToUse = rating
  if (selectable) {
    ratingToUse = hovering ? hoverRating : selectedRating
  }

  const stars = Array.from({ length: 5 }, (_, i) => {
    let type: StarTypes = 'filled'
    if (ratingToUse - i === 0.5) {
      type = 'half'
    } else if (ratingToUse <= i) {
      type = 'empty'
    }
    return (
      <InteractiveStar
        key={`interactive-star-${i}`}
        onClick={handleSelect}
        onHover={handleHover}
        type={type}
        index={i}
        accentColour={accentColour}
        responsive={responsive}
        iconSize={iconSize}
        iconWidth={iconWidth}
      />
    )
  })

  return (
    <div
      role="radio"
      aria-checked="false"
      onMouseOut={handleMouseOut}
      onBlur={handleMouseOut}
      tabIndex={0}
      className={`${STYLES.stars} ${className}`}
    >
      {stars}
    </div>
  )
}

export { Props }
export default StarRating
