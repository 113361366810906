import { gql } from '@apollo/client'

import { CALENDAR_DATES_V2_FRAGMENT } from '@/components/elements/molecules/CalendarV2/fragments/calendarDatesV2Fragment'

import { DELIVERY_DATE_USER_FRAGMENT } from '../fragments/DeliveryDateUserFragment'

// eslint-disable-next-line i18next/no-literal-string
const DELIVERY_DATE_V2_QUERY = gql`
  ${CALENDAR_DATES_V2_FRAGMENT}
  ${DELIVERY_DATE_USER_FRAGMENT}
  query DeliveryDateV2Query(
    $boxId: ID!
    $nextNBoxes: Int!
    $calendarInitDate: ISO8601Date!
    $nDays: Int
    $shouldAttemptToOfferNextDayDelivery: Boolean
    $userId: ID
  ) {
    user {
      id
      ...DeliveryDateUserFragment
    }
    calendarDates(
      startDate: $calendarInitDate
      nDays: $nDays
      shouldAttemptToOfferNextDayDelivery: $shouldAttemptToOfferNextDayDelivery
      userId: $userId
    ) {
      ...CalendarDatesV2Fragment
    }
  }
`

export { DELIVERY_DATE_V2_QUERY }
