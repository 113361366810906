// @noflow
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { MAX_PUPPY_AGE_IN_MONTHS } from '@/utils/butternutbox/constants/digital_product'

import Image from '@/components/elements/atoms/Image/Image'

import STYLES from './DynamicImage.module.sass'

import type { Dynamic } from '../../DogAvatar'

const DynamicImage = ({
  avatarUrl,
  ageInMonths,
  image
}: Dynamic): JSX.Element => {
  const { t } = useTranslation('molecules')

  const asset = useMemo(() => {
    switch (true) {
      case ageInMonths <= MAX_PUPPY_AGE_IN_MONTHS: {
        return {
          slug: 'avatar-puppy',
          alt: t('dog_avatar.alt_puppy')
        }
      }
      default: {
        return {
          slug: 'avatar-adult',
          alt: t('dog_avatar.alt_adult')
        }
      }
    }
  }, [ageInMonths, t])

  if (avatarUrl) {
    return (
      <img
        src={avatarUrl}
        alt={t('dog_avatar.alt')}
        width={image.width}
        height={image.height}
        className={STYLES.image}
      />
    )
  }

  return (
    <Image
      alt={asset.alt}
      slug={asset.slug}
      image={image}
      className={STYLES.image}
    />
  )
}

export { DynamicImage }
