// @noflow
import { useLazyQuery } from '@apollo/client'
import i18next from 'i18next'
import debounce from 'lodash/debounce'
import { useCallback, useMemo, useState } from 'react'

import * as Sentry from '@/utils/sentry'

import { POSTCODE_IS_DELIVERABLE_QUERY } from '../components/InputField/queries/PostcodeIsDeliveryableQuery'
import {
  PostcodeIsDeliverableQuery,
  PostcodeIsDeliverableQueryVariables
} from '../components/InputField/queries/__generated__/PostcodeIsDeliverableQuery'

import { Code as CountryCode } from '@/types'

type UseValidatePostcode = {
  validatePostcode: (value: string) => void
  postcodeIsValid: boolean
  loading: boolean
  errorMessage: string | null
}

const useValidatePostcode = (
  shippingCountryCode: CountryCode
): UseValidatePostcode => {
  const [postcodeIsValid, setPostcodeIsValid] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const copyContext = 'checkout:errors'

  const [validatePostcode, { loading }] = useLazyQuery<
    PostcodeIsDeliverableQuery,
    PostcodeIsDeliverableQueryVariables
  >(POSTCODE_IS_DELIVERABLE_QUERY, {
    onCompleted: ({ postcodeIsDeliverable }) => {
      setPostcodeIsValid(postcodeIsDeliverable)
      const countryCode = shippingCountryCode || 'GB'

      if (!postcodeIsDeliverable) {
        setErrorMessage(
          i18next.t(
            `${copyContext}.address_${
              countryCode === 'IE' ? 'eircode' : 'postcode'
            }`
          )
        )
      } else {
        setErrorMessage(null)
      }
    },
    onError: (error) => {
      Sentry.captureException(
        `Error with POSTCODE_IS_DELIVERABLE_QUERY on CheckoutPage`,
        {
          extra: {
            error: error.message
          },
          tags: {
            product: Sentry.Product.Checkout
          }
        }
      )
    }
  })

  const debouncedValidatePostcode = useMemo(
    () =>
      debounce((options) => {
        validatePostcode(options)
      }, 500),
    [validatePostcode]
  )

  const validate = useCallback(
    (value: string) => {
      if (value.trim().length >= 4) {
        debouncedValidatePostcode({ variables: { postcode: value.trim() } })
      } else {
        setPostcodeIsValid(false)
        setErrorMessage(
          i18next.t(
            `${copyContext}.address_${
              shippingCountryCode === 'IE' ? 'eircode' : 'postcode'
            }`
          )
        )
      }
    },
    [debouncedValidatePostcode, shippingCountryCode]
  )

  return {
    validatePostcode: validate,
    postcodeIsValid: postcodeIsValid && !loading,
    loading,
    errorMessage
  }
}

export default useValidatePostcode
