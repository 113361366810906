import { gql } from '@apollo/client'

const SUBMIT_CATEGORY = gql`
  fragment orderTrackingNumberFields on OrderTrackingNumber {
    boxId
    trackingId
    trackingUrl
    courierName
    address1
    address2
  }

  mutation CustomerIssueCategorySubmission(
    $userId: ID!
    $boxId: ID!
    $categoryId: ID!
  ) {
    response: customerIssueCategorySubmission(
      userId: $userId
      boxId: $boxId
      categoryId: $categoryId
    ) {
      resolutions {
        key
        requiresAction
        resolutionType
        details {
          __typename
          ... on OrderTrackingNumber {
            type
            ...orderTrackingNumberFields
          }
        }
      }
      fields {
        name
        dataType
        presentationalOrder
        selectionFormat
        __typename
        ... on CustomerIssueEntryFieldAssociation {
          allowedAssociationValues {
            __typename
            ... on CustomerIssueCategory {
              id
              description
              name
              key
              selfServable
              parentId
            }
            ... on Product {
              id
            }
          }
          associationType
          associationDisplayType
          id
        }
        ... on CustomerIssueEntryFieldString {
          allowedStringValues
          stringDisplayType
          id
        }
        ... on CustomerIssueEntryFieldInteger {
          allowedIntegerValues
          integerDisplayType
          id
        }
        ... on CustomerIssueEntryFieldBoolean {
          booleanDisplayType
          id
        }
        ... on CustomerIssueEntryFieldMultifield {
          multifieldDisplayType
          id
          fields {
            name
            dataType
            presentationalOrder
            selectionFormat
            __typename
            ... on CustomerIssueEntryFieldAssociation {
              allowedAssociationValues {
                __typename
                ... on CustomerIssueCategory {
                  id
                  description
                  name
                  key
                  selfServable
                  parentId
                }
                ... on Product {
                  id
                }
              }
              associationType
              associationDisplayType
              id
            }
            ... on CustomerIssueEntryFieldString {
              allowedStringValues
              stringDisplayType
              id
            }
            ... on CustomerIssueEntryFieldInteger {
              allowedIntegerValues
              integerDisplayType
              id
            }
            ... on CustomerIssueEntryFieldBoolean {
              booleanDisplayType
              id
            }
          }
        }
      }
      reasons {
        id
        name
        presentationalOrder
      }
      reportId
      entryId
    }
  }
`

export { SUBMIT_CATEGORY }
