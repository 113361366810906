// @flow

import * as React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { ILLUSTRATIONS } from '../../assets/'

import type { State } from '../../reducers'
import type { State as WizardStep } from '../../reducers/wizardStepReducer'

type PresentationalProps = {|
  wizardStep: WizardStep
|}

type ActionProps = {||}

type Props =
  & PresentationalProps
  & ActionProps

type Translate = (arg0: string) => string

const mapStateToProps = ({ wizardStep }: State): PresentationalProps => {
  return {
    wizardStep
  }
}

const stepProgressMessage = ({
  wizardStep,
  t
}: {|
  wizardStep: WizardStep,
  t: Translate
|}): string => {
  const copyContext = 'step_progress_message'
  switch (wizardStep) {
    case 'FavouriteGames': {
      return t(`${copyContext}.favourite_games`)
    }
    case 'FavouriteActivities': {
      return t(`${copyContext}.favourite_activities`)
    }
    case 'QuirksAndTraits': {
      return t(`${copyContext}.quirks_and_traits`)
    }
    case 'UploadPhoto': {
      return t(`${copyContext}.upload_photo`)
    }
    case 'DogDescription': {
      return t(`${copyContext}.dog_description`)
    }
    case 'PostWizard': {
      throw new Error(`${wizardStep} is not able to be set a stepProgressMessage`)
    }
    default: {
      (wizardStep: empty) // eslint-disable-line no-unused-expressions
      throw new Error(`Cannot generate stepProgressMessage for: ${wizardStep}`)
    }
  }
}

const wizardStepToPercentage = (wizardStep: WizardStep): string => {
  switch (wizardStep) {
    case 'FavouriteGames': {
      return '60%'
    }
    case 'FavouriteActivities': {
      return '70%'
    }
    case 'QuirksAndTraits': {
      return '80%'
    }
    case 'UploadPhoto': {
      return '90%'
    }
    case 'DogDescription': {
      return '98%'
    }
    case 'PostWizard': {
      throw new Error(`${wizardStep} is not able to be set a wizardStepToPercentage`)
    }
    default: {
      (wizardStep: empty) // eslint-disable-line no-unused-expressions
      throw new Error(`Cannot generate wizardStepToPercentage for: ${wizardStep}`)
    }
  }
}

const ProgressBar = ({
  wizardStep
}: Props): React.Element<'div'> => {
  const { t } = useTranslation('post_signup_wizard')
  return (
  <div className='progress-bar-wrapper'>
    <div className='progress-bar'>
      <div className='progress-bar__dog'>
        <div className='progress-bar__dog__speech-bubble'>
          <span>
            {
              stepProgressMessage({
                wizardStep,
                t
              })
            }
          </span>
        </div>
        <img
          alt=''
          loading='lazy'
          className='progress-bar__dog__illustration'
          src={ILLUSTRATIONS.ProgressDog}
        />
      </div>
      <div className='progress-bar__outer'>
        <div
          className='progress-bar__inner'
          style={{ width: `${wizardStepToPercentage(wizardStep)}` }}
        />
      </div>
    </div>
  </div>
  )
}

export default connect(
  mapStateToProps,
  null
)(ProgressBar)
