// @noflow
import locale from 'date-fns/esm/locale/en-GB'
import type { KeyboardEvent, SyntheticEvent } from 'react'
import * as React from 'react'

const WhiteVan =
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require('assets/images/illustrations/vans/white-van.svg') as string

// TODO: dates are strings for now because that's how they get serialized
export type CalendarDate = {
  date: string
  formatted_date: string
  day_name: string
  is_deliverable: boolean
  endOfLeadTime?: string
  full_date?: string
}

export type DeliveryWindow = {
  period_name: string
  month_and_year: string
  dates: Array<CalendarDate>
  days_to_pad_at_start: number
  days_to_pad_at_end: number
  today: string
}

type Props = {
  deliveryWindow: DeliveryWindow
  currentDeliveryDate?: string
  selectedDate: CalendarDate
  handleDateChange: (arg0: CalendarDate) => void
  boxDeliveryDates?: Array<string>
  setShowWarning?: (arg0: boolean) => void
}

const DatePickerComponent = (props: Props): React.ReactElement => {
  const dayHeaders = (): Array<string> => {
    if (locale && locale.localize) {
      return [
        locale.localize.day(1, { width: 'narrow' }),
        locale.localize.day(2, { width: 'narrow' }),
        locale.localize.day(3, { width: 'narrow' }),
        locale.localize.day(4, { width: 'narrow' }),
        locale.localize.day(5, { width: 'narrow' }),
        locale.localize.day(6, { width: 'narrow' }),
        locale.localize.day(0, { width: 'narrow' })
      ]
    } else {
      // fallback if locale is undefined
      return ['M', 'T', 'W', 'T', 'F', 'S', 'S']
    }
  }

  const renderHeader = (
    header: string,
    key: number
  ): React.ReactElement<'div'> => (
    <div className="date-picker__cell" key={key}>
      <div className="date-picker__cell__item date-picker__header__day">
        {header}
      </div>
    </div>
  )

  const renderDeliveryIcon = (
    isPreviousDeliveryDate: boolean,
    isSelected: boolean,
    nextBoxDeliveryDate?: boolean | null
  ): React.ReactNode => {
    if (isPreviousDeliveryDate || isSelected || nextBoxDeliveryDate) {
      return (
        <div className={`date-picker__cell__item__delivery-icon`}>
          <img src={WhiteVan} alt="selected delivery date icon" />
        </div>
      )
    } else {
      return null
    }
  }

  const showWarning = (isNextBoxDeliveryDate?: boolean | null): void => {
    const { setShowWarning } = props
    if (!setShowWarning) return

    isNextBoxDeliveryDate ? setShowWarning(true) : setShowWarning(false)
  }

  const handleSelectOnEnter = (
    e: KeyboardEvent<HTMLDivElement>,
    calendarDate: CalendarDate,
    isNextBoxDeliveryDate?: boolean | null
  ): void => {
    const { handleDateChange } = props
    e.currentTarget.blur()
    showWarning(isNextBoxDeliveryDate)
    handleDateChange(calendarDate)
  }

  const renderDeliverableDate = (
    calendarDate: CalendarDate,
    isSelected: boolean,
    isPreviousDeliveryDate: boolean,
    isNextBoxDeliveryDate?: boolean | null
  ): React.ReactElement<'div'> => {
    const { handleDateChange } = props

    const selectedSuffix =
      isSelected || isPreviousDeliveryDate ? '--selected' : ''
    const deliverySuffix =
      isPreviousDeliveryDate || isSelected ? '__delivery-day' : ''
    const futureDeliverySuffix =
      isNextBoxDeliveryDate && deliverySuffix === ''
        ? '__future-delivery-day'
        : ''

    return (
      <div className="date-picker__cell" key={calendarDate.date}>
        <div
          role="button"
          tabIndex={0}
          className={`date-picker__cell__item date-picker__body__date--deliverable${selectedSuffix}${futureDeliverySuffix}`}
          // eslint-disable-next-line react/jsx-no-bind
          onClick={(e: SyntheticEvent<HTMLDivElement>): void => {
            e.currentTarget.blur()
            showWarning(isNextBoxDeliveryDate)
            handleDateChange(calendarDate)
          }}
          // eslint-disable-next-line react/jsx-no-bind
          onKeyDown={(e: KeyboardEvent<HTMLDivElement>): void =>
            handleSelectOnEnter(e, calendarDate, isNextBoxDeliveryDate)
          }
        >
          <div className={`date-picker__cell__item__date${deliverySuffix}`}>
            {calendarDate.date}
          </div>
          {renderDeliveryIcon(
            isPreviousDeliveryDate,
            isSelected,
            isNextBoxDeliveryDate
          )}
        </div>
      </div>
    )
  }

  const renderUndeliverableDate = (
    calendarDate: CalendarDate,
    isPreviousDeliveryDate: boolean,
    todayDate: string
  ): React.ReactElement<'div'> => {
    const todaySuffix = calendarDate.full_date === todayDate ? '__today' : ''
    const deliverySuffix = isPreviousDeliveryDate ? '__delivery-day' : ''

    return (
      <div className={`date-picker__cell`} key={calendarDate.date}>
        <div
          className={`date-picker__cell__item date-picker__body__date--undeliverable`}
        >
          <div
            className={`date-picker__cell__item__date${todaySuffix}${deliverySuffix}`}
          >
            {calendarDate.date}
          </div>
          {renderDeliveryIcon(isPreviousDeliveryDate, false)}
        </div>
      </div>
    )
  }

  const renderEmptyCells = (
    numberOfCells: number,
    keyPrefix: string
  ): React.ReactNode => {
    if (numberOfCells <= 0) return
    return Array(numberOfCells)
      .fill(0)
      .map(
        (_: React.ReactNode, index: number): React.ReactElement<'div'> => (
          <div
            className="date-picker__cell"
            key={`empty-${keyPrefix}-${index + 1}`}
          >
            <div className="date-picker__cell__item" />
          </div>
        )
      )
  }

  const renderBody = (): React.ReactNode => {
    const {
      deliveryWindow,
      selectedDate,
      currentDeliveryDate,
      boxDeliveryDates
    } = props
    const todayDate = deliveryWindow.today
    return deliveryWindow.dates.map(
      (calendarDate: CalendarDate): React.ReactElement<'div'> => {
        const isNextBoxDeliveryDate = boxDeliveryDates
          ? !!boxDeliveryDates.find(
              (date: string): boolean => date === calendarDate.full_date
            )
          : null
        if (calendarDate.is_deliverable) {
          const isSelected = calendarDate.date === selectedDate.date
          const matchesAnExistingDelivery =
            calendarDate.full_date === currentDeliveryDate &&
            selectedDate.date === ''
          return renderDeliverableDate(
            calendarDate,
            isSelected,
            matchesAnExistingDelivery,
            isNextBoxDeliveryDate
          )
        } else {
          return renderUndeliverableDate(calendarDate, false, todayDate)
        }
      }
    )
  }
  const { deliveryWindow } = props
  return (
    <div className="date-picker">
      <div className="date-picker__header">
        {dayHeaders().map(renderHeader)}
      </div>
      <div className="date-picker__body">
        {renderEmptyCells(deliveryWindow.days_to_pad_at_start, 'before')}
        {renderBody()}
        {renderEmptyCells(deliveryWindow.days_to_pad_at_start, 'end')}
      </div>
    </div>
  )
}

export default DatePickerComponent
