import { gql } from '@apollo/client'

const INITIAL_DATA = gql`
  query CustomerIssueManagementInitialDataQuery(
    $reportableType: CustomerIssueManagementReportable!
    $reportableId: ID!
  ) {
    shouldSeeSelfResolutions: featureFlag(
      flag: "should_see_self_resolutions"
      fallback: "false"
    )
    user {
      id
      preferredLanguage
      reportable(reportableType: $reportableType, reportableId: $reportableId) {
        isoDeliveryDate
        price
        potentialMissingItems {
          name
          productVariant {
            id
          }
          category
          quantity
          price
          thumbnail
        }
      }
      dogs {
        name
      }
    }
    categories: eligibleCustomerIssueCategories(
      reportableType: $reportableType
      reportableId: $reportableId
    ) {
      description
      id
      key
      name
      parentId
      presentationalOrder(reportableType: $reportableType)
    }
  }
`

export { INITIAL_DATA }
