import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'
import React, { ReactElement, ReactNode } from 'react'

import Link, { Props as LinkProps } from '@/components/elements/atoms/Link/Link'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './TitleAndMore.module.sass'

type Props = {
  title: string
  more: string
  href?: LinkProps['href']
  onClick?: LinkProps['onClick']
  size?: 18
  namespace: string
  heading?: 2 | 3 | 4 | 5 | 6
  children?: ReactNode
  identifier: string
}

const TitleAndMore = ({
  title,
  more,
  href,
  onClick,
  namespace,
  children,
  size = 18,
  heading = 3,
  identifier
}: Props): ReactElement => {
  return (
    <div className={STYLES.container}>
      <Text
        element={`h${heading}`}
        text={title}
        variant={`display${size}`}
        shouldScale
        colour="brandBlue500"
        namespace={namespace}
      />
      {!isUndefined(children) && <div>{children}</div>}
      {!isUndefined(href) && !isEmpty(href) && (
        <Link
          text={more}
          href={href}
          onClick={onClick}
          namespace={namespace}
          size={(size - 4) as LinkProps['size']}
          identifier={identifier}
        />
      )}
    </div>
  )
}

export { Props }
export default TitleAndMore
