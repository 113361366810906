// @noflow
import React, { useCallback, useState } from 'react'

import Card from '@/components/elements/atoms/Card/Card'
import CheckBox from '@/components/elements/atoms/CheckBox/CheckBox'
import Text from '@/components/elements/atoms/Text/Text'
import StickyNavigation from '@/components/elements/organisms/StickyNavigation/StickyNavigation'
import CloudinaryImage from '@/components/shared/elements/CloudinaryImage/CloudinaryImage'
// eslint-disable-next-line no-restricted-imports
import type { Answer, Option, Question } from '@/components/types'

import STYLES from './MultipleChoiceQuestion.module.sass'

type Props = {
  question: Question
  onAnswer: (answers: Array<Answer>) => void
}

type OptionProps = {
  onSelect: (index: number, optionId: number, value: boolean) => void
  option: Option
  index: number
}

const OptionRow = ({ onSelect, option, index }: OptionProps) => {
  const [hover, setHover] = useState<boolean>(false)
  const [selected, setSelected] = useState<boolean>(false)

  const handleClick = useCallback(() => {
    setSelected(!selected)
    onSelect(index, option.id, !selected)
  }, [onSelect, setSelected, selected, option, index])

  const hoverOver = useCallback(() => {
    setHover(true)
  }, [setHover])

  const hoverOut = useCallback(() => {
    setHover(false)
  }, [setHover])

  let backgroundColor: 'blue100' | 'blue500' | undefined

  if (hover) {
    backgroundColor = 'blue100'
  }
  if (selected) {
    backgroundColor = 'blue500'
  }

  return (
    <button
      onClick={handleClick}
      onMouseOver={hoverOver}
      onMouseOut={hoverOut}
      onFocus={hoverOver}
      onBlur={hoverOut}
      className={`${STYLES.button} ${selected ? STYLES.selected : ''}`}
      type="button"
    >
      <Card background={backgroundColor}>
        <div className={STYLES.option}>
          <div>
            <CheckBox
              defaultValue={selected}
              id={0}
              tabIndex={0}
              onChange={handleClick}
            />
            <Text
              text={option.text}
              variant="display20"
              colour={selected ? 'brandWhite' : 'brandBlue500'}
              translate={false}
            />
          </div>
          {option.image && (
            <CloudinaryImage
              alt={option.text}
              image={{
                path: option.image.src,
                crop: 'fill',
                dpr: window.devicePixelRatio
              }}
            />
          )}
        </div>
      </Card>
    </button>
  )
}

const MultipleChoiceQuestion = ({ question, onAnswer }: Props): JSX.Element => {
  const [answers, setAnswers] = useState<Array<Answer>>([])
  const handleSelect = useCallback(
    (index, optionId, value) => {
      answers[index] = {
        optionId,
        questionId: question.id,
        value: value ? 1 : 0
      }
      setAnswers([...answers])
    },
    [setAnswers, answers, question]
  )

  const options = question.options.map((option: Option, index: number) => {
    return (
      <OptionRow
        key={option.id}
        option={option}
        onSelect={handleSelect}
        index={index}
      />
    )
  })

  const handleComplete = useCallback(
    (e) => {
      // we don't want the link to change the url
      e.preventDefault()
      onAnswer(answers)
    },
    [onAnswer, answers]
  )

  return (
    <>
      <div className={STYLES.options}>{options}</div>
      <StickyNavigation
        buttonOne={{
          url: '/',
          text: 'confirm',
          namespace: 'surveys',
          variant: 'primary',
          iconColour: 'brandWhite',
          onClick: handleComplete
        }}
      />
    </>
  )
}

export { Props }
export default MultipleChoiceQuestion
