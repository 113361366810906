// @flow

import * as React from 'react'
import type { ComponentProps } from './../../containers/user_details/MessageBannerContainer'

class MessageBannerComponent extends React.Component<ComponentProps> {
  render (): React.Node {
    if (this.props.showMessage) {
      return (
        <div className='alert alert--success text-center banner--top'>
          <p>{this.props.message}</p>
        </div>
      )
    } else return null
  }
}

export default MessageBannerComponent
