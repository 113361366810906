import { useStripe } from '@stripe/react-stripe-js'
import i18next from 'i18next'
import React from 'react'

import * as Sentry from '@/utils/sentry'
import { isInAppBrowser } from '@/utils/userAgent'

import { IntentType } from '@/types'

import * as ANALYTICS from '../../../Analytics/CheckoutAnalytics'
import { checkoutPageState } from '../../../CheckoutPage'
import { bannerMessageState } from '../Banner'
import { setSubmissionState } from '../PaymentSection'
import buySubscriptionWithSepaDirectDebit from '../helpers/buySubscriptionWithSepaDirectDebit'
import getBancontactPaymentAuthorisation from '../helpers/getBancontactPaymentAuthorisation'

type UseBuySubscriptionWithBancontact = {
  bancontactSubscriptionCallback: () => void
  bancontactAuthRecieved: boolean
}

const useBuySubscriptionWithBancontact = ({
  csrfToken
}: {
  csrfToken: string
}): UseBuySubscriptionWithBancontact => {
  const stripe = useStripe()
  const { sections } = checkoutPageState()
  const { paymentDetails } = sections
  const { form } = paymentDetails
  const { selectedPaymentMethod } = form

  const copyContext = 'checkout:payment_section.bancontact_error'

  const [bancontactAuthRecieved, setBancontactAuthRecieved] =
    React.useState(false)

  const bancontactSubscriptionCallback = React.useCallback(() => {
    if (!stripe)
      throw new Error(
        'Cannot find stripe when buySubscritionWithSepaDirectDebit is called'
      )

    const name = sections.accountDetails.form.name.value

    ANALYTICS.bancontactButtonClicked(isInAppBrowser(navigator.userAgent))
    ANALYTICS.submissionAttempt('bancontact')

    selectedPaymentMethod.type = 'bancontact'

    checkoutPageState({
      ...checkoutPageState(),
      sections
    })

    setSubmissionState({ type: 'loading' })

    // Reset the error banner if it's showing
    bannerMessageState({ message: null, type: 'error' })

    return getBancontactPaymentAuthorisation({ stripe, name, csrfToken })
  }, [csrfToken, sections, selectedPaymentMethod, stripe])

  // Upon being redirected back to Checkout after an authorisation attempt to
  // create the subscription or alert the customer that authentication has failed
  React.useEffect(() => {
    if (
      window.location.search.includes('redirect_status') &&
      stripe &&
      selectedPaymentMethod.type === 'bancontact'
    ) {
      if (window.location.search.includes('redirect_status=succeeded')) {
        const urlParams = new URLSearchParams(window.location.search)
        const paymentIntentSecret = urlParams.get(
          'payment_intent_client_secret'
        )
        const setupIntentSecret = urlParams.get('setup_intent_client_secret')

        const intentSecret = paymentIntentSecret || setupIntentSecret
        const intentType = paymentIntentSecret
          ? IntentType.payment_intent
          : IntentType.setup_intent

        if (intentSecret) {
          setBancontactAuthRecieved(true)
          buySubscriptionWithSepaDirectDebit({
            intentSecret: intentSecret,
            csrfToken,
            paymentMethod: 'bancontact',
            intentType: intentType
          })
        } else {
          Sentry.captureException(
            'Bancontact payment failed, intentSecret is undefined',
            {
              tags: {
                product: Sentry.Product.Checkout
              }
            }
          )
          setSubmissionState({
            type: 'error',
            error: i18next.t(copyContext)
          })
        }
      } else {
        const urlParams = new URLSearchParams(window.location.search)
        const status = urlParams.get('redirect_status')
        Sentry.captureException(`Bancontact Auth Redirect Status`, {
          extra: {
            status
          },
          tags: {
            product: Sentry.Product.Checkout
          }
        })
        setBancontactAuthRecieved(false)

        // Remove URL params after failed authentication
        window.history.replaceState(
          {},
          document.title,
          location.href.split('?')[0]
        )

        bannerMessageState({
          message: i18next.t(copyContext),
          type: 'error'
        })

        setSubmissionState({
          type: 'error',
          error: i18next.t(copyContext)
        })
      }
    }
  }, [csrfToken, sections, selectedPaymentMethod, stripe])

  return {
    bancontactSubscriptionCallback,
    bancontactAuthRecieved
  }
}

export default useBuySubscriptionWithBancontact
