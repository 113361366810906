// @noflow
import isNull from 'lodash/isNull'
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

type Props = {
  element: JSX.Element
  target: string
}

const Portal = ({ element, target }: Props): JSX.Element | null => {
  const [showPortal, setShowPortal] = useState(false)
  const targetElement = document.getElementById(target)

  // Show portal on mount and hide on unmount to force it to re-render on revisit
  useEffect(() => {
    setShowPortal(true)

    return () => {
      setShowPortal(false)
    }
  }, [])

  // If the target element doesn't exist, don't render the portal
  if (isNull(targetElement)) return null

  return showPortal ? createPortal(element, targetElement) : null
}

export { Portal }
