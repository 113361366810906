// @noflow
import {
  useLanguage,
  useShippingCountry
} from '@/context/injectedValues/injectedValues'
import { useLocalisation } from '@/context/localisation'
import { useReactiveVar } from '@apollo/client'
import { Grid } from '@mui/material'
import { isAfter, isEqual, parseISO } from 'date-fns'
import React from 'react'

import { featureFlagsDataVar } from '@/services/apollo'

import useButternutBoost from '@/hooks/useButternutBoost'
import useWindowSize from '@/hooks/useWindowSize'

import Receipt from 'assets/images/icons/receipt.svg'

import AlertCard from '@/components/elements/atoms/Alert/AlertCard'
import Icon from '@/components/elements/atoms/Icon/Icon'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import Text from '@/components/elements/atoms/Text/Text'
import ExpandCard from '@/components/elements/molecules/ExpandCard/ExpandCard'
import PriceBreakdown from '@/components/elements/molecules/PriceBreakdown/PriceBreakdown'
import { BoxPriceBreakdown } from '@/components/elements/organisms/BoxPriceBreakdown/BoxPriceBreakdown'
import { BREAKPOINTS } from '@/components/pages/App/App'

import STYLES from './BoxDetailsPriceBreakdown.module.sass'

import { MembershipTypes } from '@/types'

import type { Props as BoxDetailsProps } from '../boxDetails/BoxDetails'
import {
  getBoxPriceBreakdownTitleDetails,
  getNonCoreOrderPriceBreakdownRows,
  isBoxOrder,
  isNonCoreOrder
} from './utils'

type Props = Pick<
  BoxDetailsProps,
  | 'pricing'
  | 'type'
  | 'isBoostedBox'
  | 'isFreeBox'
  | 'isReplacementBox'
  | 'nextUpcomingPrice'
  | 'shippingDate'
  | 'planPricePerDay'
>

const BoxDetailsPriceBreakdown = ({
  pricing,
  type,
  isBoostedBox,
  isFreeBox,
  isReplacementBox,
  nextUpcomingPrice,
  shippingDate,
  planPricePerDay
}: Props): JSX.Element | null => {
  const { windowWidth } = useWindowSize()
  const { shippingCountry } = useShippingCountry()
  const { userLanguage } = useLanguage()
  const { boostedType } = useButternutBoost()
  const { format, formatCurrencyWithDecimal } = useLocalisation()
  const { shouldSeeUpcomingPriceRiseInfo } =
    useReactiveVar(featureFlagsDataVar) ?? {}

  const isBoxDateAfterPriceIncrease = (() => {
    if (!nextUpcomingPrice) {
      return false
    }

    const { effectiveFrom } = nextUpcomingPrice
    const parsedEffectiveFrom = parseISO(effectiveFrom)

    const compareDates = (callback: typeof isAfter | typeof isEqual) =>
      callback(shippingDate, parsedEffectiveFrom)

    return compareDates(isAfter) || compareDates(isEqual)
  })()

  const showUpcomingPriceRiseInfo =
    shouldSeeUpcomingPriceRiseInfo === 'true' &&
    nextUpcomingPrice &&
    isBoxDateAfterPriceIncrease &&
    planPricePerDay

  const isMobile = windowWidth < BREAKPOINTS.sm

  const boxPriceBreakdownTitleDetails = getBoxPriceBreakdownTitleDetails({
    type,
    pricing,
    isFreeBox,
    formatCurrencyWithDecimal
  })

  if (!pricing || !boxPriceBreakdownTitleDetails) {
    return null
  }

  const { isFree, isDiscounted, boxTotal, discountedBoxTotal } =
    boxPriceBreakdownTitleDetails

  return (
    <Grid item container rowSpacing={2}>
      <Grid item xs={12} lg={5}>
        <ExpandCard
          text={
            <div className={STYLES.titleWrapper}>
              <div className={STYLES.titleContent}>
                <Text
                  text="home.box_details.price_breakdown.title"
                  namespace="account"
                  variables={{
                    context: isFree ? 'free' : ''
                  }}
                  variant="display16"
                  margin={false}
                />
                {!isFree && (
                  <Text
                    text={isDiscounted ? boxTotal : discountedBoxTotal}
                    variant={isDiscounted ? 'textRegular16' : 'display16'}
                    strikeThrough={isDiscounted}
                    translate={false}
                    margin={false}
                  />
                )}
                {isDiscounted && !isFree && (
                  <Text
                    text={discountedBoxTotal}
                    variant="display16"
                    colour={
                      isBoostedBox && boostedType === MembershipTypes.boost
                        ? 'boostLight'
                        : 'brandRed500'
                    }
                    translate={false}
                    margin={false}
                  />
                )}
              </div>
              {showUpcomingPriceRiseInfo && (
                <Icon asset="question" size={26} width={26} />
              )}
            </div>
          }
          icon={Receipt}
          variant="brandBlue100"
          size={isMobile ? 'small' : 'medium'}
          border="none"
          shadow={false}
          closeOnOutsideClick
          divideContent
          identifier="box-details-price-breakdown"
        >
          {isBoxOrder(type, pricing) ? (
            <>
              <BoxPriceBreakdown
                shippingCountryCode={shippingCountry ?? 'GB'}
                preferredLanguage={userLanguage ?? 'en'}
                meals={[
                  {
                    total: pricing.planTotal || 0,
                    perDay: pricing.planTotalPerDay || 0
                  }
                ]}
                discount={pricing.discountTotal || undefined}
                deliveryFee={pricing.deliveryFee || 0}
                surchargeTotal={pricing.surchargeTotal}
                extras={pricing.physicalProductsTotal || undefined}
                boost={
                  (isBoostedBox && boostedType === MembershipTypes.boost) ??
                  undefined
                }
                isFreeBox={isFreeBox}
                isReplacementBox={isReplacementBox}
              />
              {showUpcomingPriceRiseInfo && (
                <SectionWrapper margin={{ top: 16, bottom: 0 }}>
                  <AlertCard
                    message={{
                      namespace: 'account',
                      text: 'home.box_details.price_breakdown.price_increase_alert',
                      margin: false,
                      align: 'left',
                      variables: {
                        currentPrice:
                          formatCurrencyWithDecimal(planPricePerDay),
                        nextUpcomingPrice: formatCurrencyWithDecimal(
                          pricing.planPricePerDay
                        ),
                        effectiveFrom:
                          format &&
                          format(
                            parseISO(nextUpcomingPrice.effectiveFrom),
                            'do MMMM yyyy'
                          )
                      }
                    }}
                    variant="info"
                  />
                </SectionWrapper>
              )}
            </>
          ) : isNonCoreOrder(type, pricing) ? (
            <PriceBreakdown
              title="order_summary"
              namespace="order_summary"
              rows={getNonCoreOrderPriceBreakdownRows({
                ...pricing,
                formatCurrencyWithDecimal
              })}
              isFree={isFree}
            />
          ) : (
            <div />
          )}
        </ExpandCard>
      </Grid>
    </Grid>
  )
}

export { BoxDetailsPriceBreakdown }
