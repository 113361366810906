import { gql } from '@apollo/client'

const ADD_DISCOUNT_TO_GUEST = gql`
  mutation AddDiscountToGuest($discountCode: String!) {
    addDiscountToGuest(code: $discountCode) {
      guest {
        discountCode {
          code
          id
        }
      }
    }
  }
`
export { ADD_DISCOUNT_TO_GUEST }
