// @flow

import { connect } from 'react-redux'
import type { State } from '../index'
import type { SearchParams } from '../actions'
import type { Dispatch } from 'redux'

import { respondToBrowserNavigation, clearSearchResults } from '../actions'
import { makeNewSearch } from '../thunks'
import SingleCustomerViewIndex from '../components/SingleCustomerViewIndex'

export type PresentationalProps = State

export type ActionProps = $ReadOnly<{|
  dispatchRespondToBrowserNavigation: ((State) => void),
  dispatchMakeNewSearch: ((SearchParams) => void),
  dispatchClearSearchResults: (() => void)
|}>

export type ComponentProps =
  & PresentationalProps
  & ActionProps

function mapStateToProps (state: State): PresentationalProps {
  return state
}

function mapDispatchToProps (dispatch: Dispatch): ActionProps {
  const dispatchRespondToBrowserNavigation = (state: State): void => {
    dispatch(respondToBrowserNavigation(state))
  }
  const dispatchMakeNewSearch = (searchParams: SearchParams): void => {
    dispatch(makeNewSearch(searchParams))
  }
  const dispatchClearSearchResults = (): void => {
    dispatch(clearSearchResults())
  }
  return { dispatchRespondToBrowserNavigation, dispatchMakeNewSearch, dispatchClearSearchResults }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleCustomerViewIndex)
