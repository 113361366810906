import { gql } from '@apollo/client'

const RAF_PASS_FRAGMENT = gql`
  fragment ShareRafPassFragment on User {
    id
    shippingCountryCode
    subscription {
      id
      referralLink {
        referralLinkTypeName
        referralDiscountsTotalValue
        url
        referralDiscount {
          id
          code
          discountCodeParts {
            value
            discountType
            discountBasis
            n
          }
        }
        referrerDiscount {
          applicableDiscountParts {
            value
            discountBasis
            n
            discountType
          }
        }
        referrals {
          refereeFirstName
          refereeDogNames
          refereeSignUpDate
        }
      }
    }
    dogs {
      id
      dogProfile {
        id
        avatarUrl
      }
    }
  }
`

export { RAF_PASS_FRAGMENT }
