import { useMutation, useReactiveVar } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import { useCallback, useEffect, useState } from 'react'

// Mutations
import { CUSTOMER_ISSUE_REPORT_CREATE } from '../mutations/CustomerIssueReportCreate'

import {
  CustomerIssueReportCreate,
  CustomerIssueReportCreate_response as Response
} from '../mutations/__generated__/CustomerIssueReportCreate'
import { CustomerIssueManagementInitialDataQuery_categories as EligibleCategory } from '../queries/__generated__/CustomerIssueManagementInitialDataQuery'
import {
  CustomerIssueManagementReportable,
  CustomerIssueManagementReporterable
} from '@/types'

import {
  customerIssueManagementState,
  customerIssueReportState,
  errorState,
  pageLoadingState,
  routeState
} from '../CustomerIssueManagementPage'
import CustomerIssueManagementRoutes from '../types/routes'

type CustomerIssueReportCreateMutationHook = {
  submit: (category: EligibleCategory) => void
}

const useCustomerIssueReportCreateMutation =
  (): CustomerIssueReportCreateMutationHook => {
    const customerIssueManagementData = useReactiveVar(
      customerIssueManagementState
    )
    const customerIssueReportData = useReactiveVar(customerIssueReportState)
    const [dataRetrieved, setDataRetrieved] = useState<Response | null>(null)
    const [
      customerIssueReportCreate,
      {
        loading: customerIssueReportCreateLoading,
        data: customerIssueReportCreateData,
        error: customerIssueReportCreateError
      }
    ] = useMutation<CustomerIssueReportCreate>(CUSTOMER_ISSUE_REPORT_CREATE)

    const submit = useCallback(
      (category: EligibleCategory) => {
        customerIssueManagementState({
          ...customerIssueManagementData,
          selectedCategory: category
        })
        customerIssueReportCreate({
          variables: {
            categoryId: category.id,
            reportableType: customerIssueManagementData.reportable
              .type as CustomerIssueManagementReportable,
            reportableId: customerIssueManagementData.reportable.id,
            reporterableType: customerIssueManagementData.reporterable
              .type as CustomerIssueManagementReporterable,
            reporterableId: customerIssueManagementData.reporterable.id
          }
        })
      },
      [customerIssueManagementData, customerIssueReportCreate]
    )

    useEffect(() => {
      pageLoadingState(customerIssueReportCreateLoading)
    }, [customerIssueReportCreateLoading])

    useEffect(() => {
      if (customerIssueReportCreateError) {
        const selectedCategoryId =
          customerIssueManagementData &&
          customerIssueManagementData.selectedCategory &&
          customerIssueManagementData.selectedCategory.id
        const reportable =
          customerIssueManagementData && customerIssueManagementData.reportable
        const reportableType = reportable && reportable.type
        const reportableId = reportable && reportable.id
        Sentry.captureException(
          `Error submitting customerIssueReportCreate with category ${selectedCategoryId} for ${reportableType} ${reportableId}`
        )
        errorState(!!customerIssueReportCreateError)
        routeState(CustomerIssueManagementRoutes.Error)
      }
    }, [customerIssueReportCreateError, customerIssueManagementData])

    useEffect(() => {
      if (
        customerIssueReportCreateData &&
        dataRetrieved !== customerIssueReportCreateData.response
      ) {
        setDataRetrieved(customerIssueReportCreateData.response)
        const { reportId, resolutionOffering, inputFields } =
          customerIssueReportCreateData.response as Response
        customerIssueReportState({
          ...customerIssueReportData,
          ...customerIssueReportCreateData.response
        })
        customerIssueManagementState({
          ...customerIssueManagementData,
          reportId
        })
        if (resolutionOffering) {
          routeState(CustomerIssueManagementRoutes.QuickHelp)
        } else if (inputFields && inputFields.length > 0) {
          routeState(CustomerIssueManagementRoutes.Inputs)
        } else {
          routeState(CustomerIssueManagementRoutes.ContactCustomerLove)
        }
      }
    }, [
      customerIssueReportCreateData,
      customerIssueManagementData,
      customerIssueReportData,
      dataRetrieved
    ])

    return {
      submit
    }
  }

export default useCustomerIssueReportCreateMutation
