import { gql } from '@apollo/client'

const DISCOUNT_COPY_QUERY = gql`
  query DiscountCopyQuery {
    guest {
      id
      discountCode {
        id
        discountCodeParts {
          discountBasis
          discountType
          n
          value
        }
      }
    }
  }
`

export { DISCOUNT_COPY_QUERY }
