import { useReactiveVar } from '@apollo/client'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import Icon from '@/components/elements/atoms/Icon/Icon'
import { customerIssueManagementState } from '@/components/pages/CustomerIssueManagementPage/CustomerIssueManagementPage'

import STYLES from '../../InputsScreen.module.sass'

import { CustomerIssueManagementInputInput } from '@/types'

type DropdownOption = {
  value: string
  text: string
  key: string
}

type DropdownProps = {
  options: Array<DropdownOption>
  setValue:
    | ((
        associationValue: string,
        input?: CustomerIssueManagementInputInput | null
      ) => void)
    | ((reason: string) => void)
  input: CustomerIssueManagementInputInput
}

const Dropdown = ({ options, setValue, input }: DropdownProps): JSX.Element => {
  const customerIssueManagementData = useReactiveVar(
    customerIssueManagementState
  )
  const { t } = useTranslation(customerIssueManagementData.namespace)
  const onChange = useCallback(
    (e) => {
      setValue(e.target.value)
    },
    [setValue]
  )

  const renderOptions = useCallback(() => {
    if (options) {
      return options.map(
        (option): JSX.Element => (
          <option key={option.key} value={option.value}>
            {option.text}
          </option>
        )
      )
    }
  }, [options])

  return (
    <div className={STYLES.dropdown}>
      <select
        required
        onChange={onChange}
        value={input.associationValue as string}
      >
        <option value="">{t('inputs.chooseTopic')}</option>
        {renderOptions()}
      </select>
      <div className={STYLES.dropdownArrow}>
        <Icon size={16} asset={'chevron'} direction={'down'} />
      </div>
    </div>
  )
}

export { DropdownOption }
export default Dropdown
