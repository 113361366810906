import { useReactiveVar } from '@apollo/client'
import React, { useCallback, useEffect, useState } from 'react'

import useWebviewHandler from '@/hooks/useWebviewHandler'

import SuccessIcon from 'assets/images/icons/checkmarks/green-checkmark.svg'

import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from '../QuickHelp.module.sass'

import { CustomerIssueReportCreate_response_resolutionOffering_details_CustomerIssueManagementResolutionDetailsTrackOrder as TrackOrderDetails } from '../../../mutations/__generated__/CustomerIssueReportCreate'

import { customerIssueManagementState } from '../../../CustomerIssueManagementPage'
import useCustomerIssueManagementTracking from '../../../analytics'
import ContactCustomerLoveDetails from '../../ContactCustomerLove/ContactCustomerLoveDetails'

type Props = {
  details: TrackOrderDetails
}

const TrackOrder = ({ details }: Props): JSX.Element => {
  const [isCopied, setIsCopied] = React.useState(false)
  const webviewHandler = useWebviewHandler()
  const customerIssueManagementData = useReactiveVar(
    customerIssueManagementState
  )
  const customerIssueManagementTracking = useCustomerIssueManagementTracking()
  const [initialised, setInitialised] = useState(false)

  useEffect(() => {
    if (!initialised) {
      customerIssueManagementTracking.stepLoaded(
        'Track order',
        'Quick Resolution'
      )
      setInitialised(true)
    }
  }, [
    initialised,
    customerIssueManagementData,
    customerIssueManagementTracking
  ])
  const copyTrackingNumber = useCallback(() => {
    if (!details.trackingId) return
    customerIssueManagementTracking.trackEvent('copied tracking code')
    if (webviewHandler.isWebview) {
      webviewHandler.postMessage('COPY', details.trackingId)
    } else {
      navigator.clipboard.writeText(details.trackingId)
    }
    setIsCopied(true)
  }, [details, setIsCopied, webviewHandler, customerIssueManagementTracking])

  const handleTrackOrder = useCallback(() => {
    customerIssueManagementTracking.trackEvent('Track Order clicked')
    if (webviewHandler.isWebview) {
      webviewHandler.postMessage(
        'TRACK-ORDER',
        'https://' + details.trackingUrl
      )
    } else {
      window.open('https://' + details.trackingUrl, '_blank')
    }
  }, [details, webviewHandler, customerIssueManagementTracking])

  if (!details.trackingId || !details.trackingUrl) {
    return <ContactCustomerLoveDetails />
  }

  return (
    <div className={STYLES.container}>
      <div className={'deliveryAddress'}>
        <Text
          margin={false}
          namespace={customerIssueManagementData.namespace}
          variant={'display16'}
          element={'h2'}
          colour={'brandBlue500'}
          text={'quick_help.address_header'}
        />
        <Text
          margin={false}
          translate={false}
          variant={'textRegular16'}
          colour={'brandBlue500'}
          text={details.address1}
        />
        <Text
          margin={false}
          translate={false}
          variant={'textRegular16'}
          colour={'brandBlue500'}
          text={details.address2}
        />
      </div>
      {details.trackingId && (
        <>
          <div className={STYLES.trackingDetails}>
            <Text
              margin={false}
              namespace={customerIssueManagementData.namespace}
              element={'h2'}
              variant="display16"
              colour={'brandBlue500'}
              text={'quick_help.tracking_header'}
            />
            <Text
              margin={false}
              namespace={customerIssueManagementData.namespace}
              variant={'textRegular16'}
              colour={'brandBlue500'}
              text={'quick_help.text'}
              variables={{ courier: details.courierName }}
            />
          </div>
          <Card border="dashed" squared>
            <div className={STYLES.orderTrackingNumber}>
              <Text
                margin={false}
                variant={'textRegular16'}
                colour={'brandBlue400'}
                translate={false}
                text={details.trackingId}
              />
              <Button
                typography={{
                  namespace: customerIssueManagementData.namespace,
                  text: 'quick_help.copy_button'
                }}
                variant={'secondary'}
                onClick={copyTrackingNumber}
                disableAnalytics
              />
            </div>
          </Card>
          {isCopied && (
            <div className={STYLES.copySuccess}>
              <img alt="" src={SuccessIcon} />
              <Text
                margin={false}
                namespace={customerIssueManagementData.namespace}
                text={'quick_help.copied'}
                variant={'textRegular14'}
                colour={'supportGreen300'}
              />
            </div>
          )}
          <div className={STYLES.trackOrderButton}>
            <Button
              typography={{
                namespace: customerIssueManagementData.namespace,
                text: 'quick_help.track_order'
              }}
              disableAnalytics
              onClick={handleTrackOrder}
            />
          </div>
        </>
      )}
    </div>
  )
}

export { Props }
export default TrackOrder
