// @noflow
import type { Language } from '@/packs/localisation'
import { Container, GridSize } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { differenceInCalendarDays, format, isToday, isTomorrow } from 'date-fns'
import i18next from 'i18next'
import upperFirst from 'lodash/upperFirst'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { pronounContext, toLocalisedSentence } from '@/utils/StringHelper'
import { localeToDateLocale } from '@/utils/countryCodeHelper'

import Text from '@/components/elements/atoms/Text/Text'

import STYLES from '../../../../../../assets/stylesheets/thank_you/next-steps.module.sass'

import type { Gender } from '@/shared_types/rails_models/dogs'
import type { Code } from '@/shared_types/rails_models/shipping_countries'
import type { DogInput as Dog } from '@/types'

type Props = {
  dogs: Pick<Dog, 'name' | 'gender'>[]
  editable: boolean
  cutOffDate: Date
  deliveryDate: Date
  shippingCountryCode: Code
}

const NextSteps = ({
  dogs,
  editable,
  cutOffDate,
  deliveryDate,
  shippingCountryCode
}: Props): React.ReactElement => {
  const dogNames = toLocalisedSentence({
    arr: dogs.map(({ name }): string => upperFirst(name)),
    lng: i18next.language
  })
  const { t } = useTranslation('thank_you')
  const copy_context = 'next_steps'
  const dogGenders = dogs.map(({ gender }): Gender => gender)
  const dateLocale = localeToDateLocale(
    shippingCountryCode,
    i18next.language as Language
  )

  const deliveryContext =
    differenceInCalendarDays(deliveryDate, cutOffDate) === 1
      ? 'delivery_next_day'
      : 'delivery_date'

  const dateFormatPattern = ((countryCode: Code): string => {
    switch (countryCode) {
      case 'NL':
      case 'BE':
        return 'd MMMM'
      case 'PL':
        return 'do MMMM (EEEE)'
      case 'DE':
        return 'EEEE, do MMMM'
      default:
        return 'MMMM, EEEE do'
    }
  })(shippingCountryCode)

  const steps: Array<React.ReactElement> = [
    editable ? (
      isToday(cutOffDate) ? (
        <p
          key={1}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: t(`${copy_context}.cutoff_today`, {
              context: deliveryContext,
              dogNames,
              deliveryDate: format(deliveryDate, dateFormatPattern, {
                locale: dateLocale
              })
            })
          }}
        />
      ) : (
        <p
          key={1}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: t(`${copy_context}.cutoff_date`, {
              context: deliveryContext,
              dogNames,
              deliveryDate: format(deliveryDate, dateFormatPattern, {
                locale: dateLocale
              }),
              cutoffDate: format(cutOffDate, dateFormatPattern, {
                locale: dateLocale
              })
            })
          }}
        />
      )
    ) : isTomorrow(deliveryDate) ? (
      <p
        key={1}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: t(`${copy_context}.delivery_cutoff_today_html`, { dogNames })
        }}
      />
    ) : (
      <p
        key={1}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: t(`${copy_context}.delivery_cutoff_date_html`, {
            dogNames,
            deliveryDate: format(deliveryDate, dateFormatPattern, {
              locale: dateLocale
            })
          })
        }}
      />
    ),
    <p
      key={2}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: t(`${copy_context}.check_in_html`, {
          context: pronounContext(dogGenders, i18next.language),
          dogNames,
          count: dogs.length
        })
      }}
    />,
    <p
      key={3}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: t(`${copy_context}.adjust_second_box_html`, {
          context: pronounContext(dogGenders, i18next.language)
        })
      }}
    />
  ]

  return (
    <section className={STYLES.section}>
      <Text
        text={`${copy_context}.next_steps_title`}
        namespace="thank_you"
        variant="display28"
        element="h2"
        align="center"
      />
      <Container maxWidth="xl">
        <Grid container alignItems="flex-start" spacing={3}>
          {steps.map(
            (step: React.ReactElement, index: number): React.ReactElement => (
              <Grid
                item
                md={(12 / steps.length) as GridSize}
                key={`step-${index + 1}`}
                className={STYLES.sectionStep}
              >
                <p className={STYLES.sectionStepNumber}>
                  {(index + 1).toString().padStart(2, '0')}
                </p>
                {step}
              </Grid>
            )
          )}
        </Grid>
      </Container>
    </section>
  )
}

export default NextSteps
