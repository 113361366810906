// @flow

import LazyLoad from 'vanilla-lazyload'

const lazyLoad = new LazyLoad({
  elements_selector: '.lazy',
  threshold: 500,
  cancel_on_exit: false
  // The cancel_on_exit setting triggers a feature to make our users save
  // bandwidth when they scroll fast over the images: if the images exit the
  // viewport and they haven't fully loaded yet, their downloads are canceled
  // to free some bandwidth for the newly entered images.
  // The feature was introduced in v.17 and seems to create a bug, when lazy
  // loading images within our Carousels, leaving us with a loop of canceled
  // network requests. Disabling the feature here fixes the bug.
})

export default lazyLoad
