/* eslint-disable i18next/no-literal-string */
// @noflow
import type { Language } from '@/packs/localisation'
import random from 'lodash/random'

import type {
  FormattedDog,
  FormattedUser
} from '@/components/pages/SignupWizardPage/helpers/submitFormData'
import {
  GoalSegment,
  User
} from '@/components/pages/SignupWizardPage/types/types'

import { Code as CountryCode, MarketingPreferences } from '@/types'

import { presetSubmissionData } from './dog'
import type { Preset } from './dog'

type UserSubmissionData = {
  email: string
  full_name: string
  dogs: Array<FormattedDog>
  marketing_consent?: MarketingPreferences
  supported_marketing_method_purpose_ids?: Array<string>
  country: CountryCode
  postcode: string | null
  preferred_language: Language
  goal_segment: GoalSegment | null
}

const firstNames = [
  'Adrian',
  'Alex',
  'Bernie',
  'Brett',
  'Carolina',
  'Caroliners',
  'Dan',
  'Daniel',
  'Despi',
  'Hannah',
  'James',
  'Lynsey',
  'Niall',
  'Ryan',
  'Tomás',
  'Ursula'
]
const lastNames = [
  'Lastnamov',
  'Lastnamich',
  'Lastnamsky',
  'Lastnamashvilli',
  'McLastname',
  `O'Lastname`,
  'van der Lastname',
  'Lastnampson',
  'Ben-Lastname',
  'Cohen'
]

const randomUser = (): User => {
  const firstName = firstNames[random(0, firstNames.length - 1)]
  const lastName = lastNames[random(0, lastNames.length - 1)]
  const name = `${firstName} ${lastName}`
  const email = `fake${random(100, 999)}@email.com`
  const postcode = `W${random(1, 12)} 7FP`
  const location = {
    countryCode: CountryCode.GB,
    postcode
  }

  return {
    email,
    name,
    location,
    marketingPreference: MarketingPreferences.full_consent,
    supportedMarketingMethodPurposeIds: [],
    goalSegment: GoalSegment.HealthConscious,
    trackingId: null
  }
}

const saveUserInLocalStorage = (user: User): void => {
  const partialState = localStorage.getItem('wizardState')
  if (partialState) {
    const state = { ...JSON.parse(partialState), user: user }
    localStorage.setItem(
      'wizardState',
      JSON.stringify({ user: state.user, dogs: state.dogs })
    )
  } else localStorage.setItem('wizardState', JSON.stringify({ user: user }))
}

const userSubmissionData = (
  user: User,
  presets: Array<Preset>
): UserSubmissionData => {
  const data: FormattedUser = {
    user: {
      email: user.email,
      full_name: user.name,
      dogs: presets.map(presetSubmissionData),
      marketing_consent:
        user.marketingPreference || MarketingPreferences.no_consent,
      supported_marketing_method_purpose_ids:
        user.supportedMarketingMethodPurposeIds || [],
      country: CountryCode.GB,
      postcode: user.location.postcode,
      preferred_language: 'en',
      goal_segment: user.goalSegment
    }
  }

  return data.user
}

export { randomUser, saveUserInLocalStorage, userSubmissionData }
export type { User }
