import { gql } from '@apollo/client'

const BANNER_QUERY = gql`
  query Banners {
    banners {
      id
      slug
      link
      order
      template
      text
      liveDate
      endDate
      showAgainAfterXDays
    }
    currentUser {
      __typename
    }
  }
`

export { BANNER_QUERY }
