import { useReactiveVar } from '@apollo/client'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import ResolutionSwitch from './components/ResolutionSwitch'

import STYLES from './Resolution.module.sass'

import { AddressInput } from '@/types'

import {
  customerIssueEntryInputsState,
  pageHeaderShowBackButtonState
} from '../../CustomerIssueManagementPage'
import { CustomerIssueResolutionOfferingMutationsHook } from '../../hooks/useCustomerIssueResolutionOfferingMutations'
import CustomerIssueManagementRoutes from '../../types/routes'
import ContactCustomerLoveDetails from '../ContactCustomerLove/ContactCustomerLoveDetails'

type Props = {
  customerIssueResolutionOfferingMutations: CustomerIssueResolutionOfferingMutationsHook
}

const Resolution = ({
  customerIssueResolutionOfferingMutations
}: Props): JSX.Element => {
  const [initialised, setInitialised] = useState(false)
  const customerIssueEntryInputsData = useReactiveVar(
    customerIssueEntryInputsState
  )
  const navigate = useNavigate()

  useEffect(() => {
    if (!initialised) {
      pageHeaderShowBackButtonState(false)
      setInitialised(true)
    }
  }, [initialised])

  const onAccept = useCallback(
    (deliveryDate?: string, deliveryAddress?: AddressInput) => {
      customerIssueResolutionOfferingMutations.accept(
        customerIssueEntryInputsData.resolutionOffering?.id as string,
        deliveryDate,
        deliveryAddress
      )
    },
    [
      customerIssueEntryInputsData.resolutionOffering?.id,
      customerIssueResolutionOfferingMutations
    ]
  )

  const contactUs = useCallback(() => {
    customerIssueResolutionOfferingMutations.reject(
      customerIssueEntryInputsData.resolutionOffering?.id as string
    )
    navigate(CustomerIssueManagementRoutes.ContactCustomerLove)
  }, [
    customerIssueEntryInputsData.resolutionOffering?.id,
    customerIssueResolutionOfferingMutations,
    navigate
  ])

  if (customerIssueEntryInputsData?.resolutionOffering) {
    return (
      <div className={STYLES.container}>
        <ResolutionSwitch
          resolutionOffering={customerIssueEntryInputsData.resolutionOffering}
          contactUs={contactUs}
          onAccept={onAccept}
        />
      </div>
    )
  } else {
    return <ContactCustomerLoveDetails />
  }
}

export default Resolution
