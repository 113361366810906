// @noflow
import { ensureNever } from '@/typescript/utils'

import type { RequestReducerAction } from '../actions'
import * as MESSAGES from '../actions/messages'

type RequestStatus =
  | 'Pending'
  | 'In Flight'
  | 'Complete With Success'
  | 'Complete With Error'

type State = {
  fetchInitialData: {
    status: RequestStatus
  }
  submitContactForm: {
    status: RequestStatus
  }
}

type Request = keyof State

// All requests should be pending/loading to begin with
const initialState: State = {
  fetchInitialData: {
    status: 'In Flight'
  },
  submitContactForm: {
    status: 'Pending'
  }
}

const reducer = (
  state: State = initialState,
  action: RequestReducerAction
): State => {
  switch (action.type) {
    case MESSAGES.START_REQUEST: {
      return {
        ...state,
        [action.name]: {
          ...state[action.name],
          status: 'In Flight'
        }
      }
    }
    case MESSAGES.COMPLETE_REQUEST_WITH_SUCCESS: {
      return {
        ...state,
        [action.name]: {
          ...state[action.name],
          status: 'Complete With Success'
        }
      }
    }
    case MESSAGES.COMPLETE_REQUEST_WITH_ERROR: {
      return {
        ...state,
        [action.name]: {
          ...state[action.name],
          status: 'Complete With Error'
        }
      }
    }
    default: {
      ensureNever(action)
      return state
    }
  }
}

export type { State, RequestStatus, Request }
export { initialState }

export default reducer
