import { useReactiveVar } from '@apollo/client'
import React, { useCallback } from 'react'

import STYLES from './ResolutionOffering.module.sass'

import {
  CustomerIssueReportEntriesSubmission_response_resolutions_details_ApplyDiscounts as DiscountDetails,
  CustomerIssueReportEntriesSubmission_response_resolutions_details_ReplacementBoxDetails as ReplacementBoxDetails
} from '../../mutations/__generated__/CustomerIssueReportEntriesSubmission'
import { CustomerIssueResolutionAcceptanceReplacementBoxSubmissionVariables } from '../../mutations/__generated__/CustomerIssueResolutionAcceptanceReplacementBoxSubmission'
import { SelfResolutionCategories_user_subscription_box_potentialMissingItems as PotentialMissingItems } from '../../queries/__generated__/SelfResolutionCategories'

import {
  resolutionOfferingState,
  selfResolutionPageState
} from '../../SelfResolutionPage'
import ContactCustomerLove from '../ContactCustomerLove/ContactCustomerLoveDetails'
import Discount from './ResolutionOfferingComponents/Discount'
import ReplacementBox from './ResolutionOfferingComponents/ReplacementBox'

type Props = {
  namespace: string
  orderReplacementBox: (
    variables: CustomerIssueResolutionAcceptanceReplacementBoxSubmissionVariables
  ) => void
  applyDiscount: () => void
}

const ResolutionOffering = ({
  namespace,
  orderReplacementBox,
  applyDiscount
}: Props): JSX.Element => {
  const selfResolutionState = useReactiveVar(selfResolutionPageState)
  const resolutionOfferingData = useReactiveVar(resolutionOfferingState)
  React.useEffect(() => {
    const pageTitle = 'resolutionOffering.header.default'

    selfResolutionPageState({
      ...selfResolutionState,
      showBackButton: false,
      pageTitle
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderResolutions = useCallback(() => {
    if (resolutionOfferingData.resolutions.length === 0) {
      return (
        <div className={STYLES.resolution}>
          <ContactCustomerLove
            pageName={'Resolution offering'}
            namespace={namespace}
          />
        </div>
      )
    }
    return resolutionOfferingData.resolutions.map((resolution) => {
      switch (resolution.key) {
        case 'replacement_box':
        case 'replace_box': {
          return (
            <ReplacementBox
              key={resolution.key}
              namespace={namespace}
              replacementBox={resolution.details as ReplacementBoxDetails}
              orderReplacementBox={orderReplacementBox}
            />
          )
        }
        case 'apply_discount': {
          return (
            <Discount
              key={resolution.key}
              namespace={namespace}
              discountDetails={resolution.details as DiscountDetails}
              affectedItems={
                resolutionOfferingData.affectedItems as Array<PotentialMissingItems>
              }
              applyDiscount={applyDiscount}
            />
          )
        }
        default:
          return (
            <div key={resolution.key} className={STYLES.resolution}>
              <ContactCustomerLove
                pageName={'Resolution offering'}
                namespace={namespace}
              />
            </div>
          )
      }
    })
  }, [namespace, orderReplacementBox, applyDiscount, resolutionOfferingData])

  return <div className={STYLES.container}>{renderResolutions()}</div>
}

export { Props }
export default ResolutionOffering
