// @noflow
import React, { useCallback } from 'react'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import Card from '@/components/elements/atoms/Card/Card'
import Image from '@/components/elements/atoms/Image/Image'

import STYLES from '../SelectableCardGroup.module.sass'

import type {
  OptionWithAnalytics,
  OptionWithoutAnalytics,
  Props as SelectableCardGroupProps
} from '../SelectableCardGtoupV2'

type CardItemWithAnalytics = OptionWithAnalytics &
  Pick<SelectableCardGroupProps, 'onSelect' | 'currentValue' | 'cardProps'>
type CardItemWithoutAnalytics = OptionWithoutAnalytics &
  Pick<SelectableCardGroupProps, 'cardProps' | 'onSelect' | 'currentValue'>

type Props = CardItemWithAnalytics | CardItemWithoutAnalytics

const CardItem = ({
  value,
  icon,
  currentValue,
  onSelect,
  cardProps,
  ...analyticProps
}: Props): JSX.Element => {
  const { windowWidth } = useWindowSize()
  const isMobile = windowWidth < BREAKPOINTS.md

  const handleOnSelect = useCallback(() => {
    onSelect(value)
  }, [onSelect, value])

  const isSelected = currentValue === value

  const {
    className = '',
    contentDirection = 'column',
    ...restCardProps
  } = cardProps || {}

  return (
    <Card
      className={`${STYLES.card} ${className} ${STYLES[contentDirection]}`}
      onClick={handleOnSelect}
      selected={isSelected}
      shadow
      border="solid"
      padding={isMobile ? 16 : 24}
      variant="brandYellow100"
      {...analyticProps}
      {...restCardProps}
    >
      <Image {...icon} />
    </Card>
  )
}

export { CardItem }
