// @noflow

type Basket = {
  __typename: 'Basket'
  id: string
  basketProducts: Array<{
    __typename: 'BasketProduct'
    quantity: number
  }>
}

/**
 * Returns the number of items in the basket.
 * @param {Basket | null} basket
 * @returns {number | null}
 *
 **/
const basketItemsCount = (basket: Basket | null): number => {
  if (!basket) return 0

  return basket.basketProducts.reduce((acc, { quantity }) => quantity + acc, 0)
}

export type { Basket }
export { basketItemsCount }
