// @flow

import * as React from 'react'
import { connect } from 'react-redux'

import formatter from '@/utils/formatter'
import { formatCurrencyWithDecimal } from '@/utils/currency'
import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import * as THUNKS from '../../thunks'
import * as ACTIONS from '../../actions'

import { subscriptionIcons } from '../../asset-imports/icons'
import PaypalIcon from 'assets/images/icons/payment_icons/paypal.svg'
import GoCardlessIcon from 'assets/images/icons/payment_icons/go_cardless.svg'
import Visa from 'assets/images/icons/payment_icons/visa.svg'
import Mastercard from 'assets/images/icons/payment_icons/mastercard.svg'
import SepaDirectDebitIcon from 'assets/images/icons/payment_icons/sepa_direct_debit.svg'
import CreditCard from 'assets/images/icons/payment_icons/credit_card.svg'
import Amex from 'assets/images/icons/payment_icons/amex.svg'

import type { State } from '../../index'
import type { Dispatch } from 'redux'
import type { Language } from '@/packs/localisation'
import type {
  SubscriptionDetails,
  SepaDirectDebit,
  Paypal,
  GoCardless,
  Card,
  AccountStatus,
  AllergyDetails,
  MealDetails,
  PaymentInformation,
  RecurringExtraProduct,
  RecipeSurcharge,
  DeliveryDetails
} from './../../message_types'

type PresentationalProps = {|
  isFetching: boolean,
  isTogglingWantsToReceiveIce: boolean,
  subscriptionDetails: SubscriptionDetails,
  subscriptionId: number,
  userId: number,
  deliveryDetails?: DeliveryDetails,
  preferredLanguage: Language
|}

type ActionProps = {|
  getSubscriptionDetails: ((number) => void),
  toggleChangePlanModal: (() => void),
  openRestartModal: (() => void),
  toggleAddCreditCardToSubscriptionModal: (() => void),
  toggleWantsToReceiveIce: (() => void)
|}

type Props =
  & PresentationalProps
  & ActionProps

type CardProps = {|
  paymentMethodDetails: Card
|}

type GoCardlessProps = {|
  paymentMethodDetails: GoCardless
|}

type SepaDirectDebitProps = {|
  paymentMethodDetails: SepaDirectDebit
|}

type PaypalProps = {|
  paymentMethodDetails: Paypal
|}

const mapStateToProps = (state: State): PresentationalProps => {
  const { deliveriesDetails, subscriptionDetails, fetchingStatuses, selectedUserIds, accountDetails } = state

  const isFetching = fetchingStatuses.subscriptionDetails
  const isTogglingWantsToReceiveIce = fetchingStatuses.wants_to_receive_ice

  const { userId, subscriptionId } = selectedUserIds

  const { deliveries } = deliveriesDetails

  const deliveryDetails = deliveries.find((delivery: DeliveryDetails): boolean => delivery.is_next_box && delivery.editable)

  const preferredLanguage = accountDetails.preferred_language

  return {
    subscriptionDetails,
    isFetching,
    isTogglingWantsToReceiveIce,
    userId,
    subscriptionId,
    deliveryDetails,
    preferredLanguage
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => {
  const getSubscriptionDetails = (userId: number): void =>
    dispatch(THUNKS.getSubscriptionDetails(userId))
  const toggleChangePlanModal = (): void => dispatch(ACTIONS.toggleChangePlanModal())
  const toggleAddCreditCardToSubscriptionModal = (): void => dispatch(ACTIONS.toggleAddCreditCardToSubscriptionModal())
  const openRestartModal = (): void => dispatch(ACTIONS.toggleRestartModal())
  const toggleWantsToReceiveIce = (): void => dispatch(THUNKS.toggleWantsToReceiveIce())

  return {
    getSubscriptionDetails,
    toggleChangePlanModal,
    toggleAddCreditCardToSubscriptionModal,
    openRestartModal,
    toggleWantsToReceiveIce
  }
}

const CardComponent = ({ paymentMethodDetails }: CardProps): React.Element<'div'> => {
  const cardIcon = {
    visa: Visa,
    mastercard: Mastercard,
    amex: Amex,
    credit_card: CreditCard
  }

  const { payment_method, last_four_digits, expiry_month, expiry_year } = paymentMethodDetails

  return (
    <div className='payment-method-container'>
      <div className='payment-method-margin'>
        <p className='card__row__title'>
          { 'Payment method' }
        </p>
        <div className='card__row__flex'>
          <img
            src={cardIcon[payment_method]}
            alt={payment_method}
            className={`card__row__payment-icon ${payment_method === 'visa' ? 'card__row__visa' : ''}`}
          />
          <p>
            { `**** ${last_four_digits}` }
          </p>
        </div>
      </div>
      <div>
        <p className='card__row__title'>
          { 'Expiry Date' }
        </p>
        <p>
          { `${expiry_month}/${expiry_year}` }
        </p>
      </div>
    </div>
  )
}

const GoCardlessComponent = ({ paymentMethodDetails }: GoCardlessProps): React.Element<'div'> => {
  const { payment_method, account_number_ending } = paymentMethodDetails

  return (
    <div className='payment-method-container'>
      <div className='payment-method-margin'>
        <p className='card__row__title'>
          { 'Payment method' }
        </p>
        <div className='card__row__flex'>
          <img
            src={GoCardlessIcon}
            alt={payment_method}
            className='card__row__payment-icon'
          />
          <p>
            { `**${account_number_ending}` }
          </p>
        </div>
      </div>
    </div>
  )
}
const PaypalComponent = ({ paymentMethodDetails }: PaypalProps): React.Element<'div'> => {
  const { payment_method, email } = paymentMethodDetails

  return (
    <div className='card__row'>
      <p className='card__row__title'>
        { 'Payment Method' }
      </p>
      <div className='card__row__flex'>
        <img
          src={PaypalIcon}
          alt={payment_method}
          className='card__row__paypal'
        />
        <p>
          { email }
        </p>
      </div>
    </div>
  )
}

const SepaDirectDebitComponent = ({ paymentMethodDetails }: SepaDirectDebitProps): React.Element<'div'> => {
  const { payment_method, last_four_digits } = paymentMethodDetails

  return (
    <div className='payment-method-container'>
      <div className='payment-method-margin'>
        <p className='card__row__title'>
          { 'Payment method' }
        </p>
        <div className='card__row__flex'>
          <img
            src={SepaDirectDebitIcon}
            alt={payment_method}
            className='card__row__payment-icon'
          />
          <p>
            { `**** ${last_four_digits}` }
          </p>
        </div>
      </div>
    </div>
  )
}

const SubscriptionDetailsComponent = ({
  subscriptionDetails,
  isFetching,
  isTogglingWantsToReceiveIce,
  userId,
  subscriptionId,
  deliveryDetails,
  getSubscriptionDetails,
  toggleChangePlanModal,
  toggleAddCreditCardToSubscriptionModal,
  toggleWantsToReceiveIce,
  openRestartModal,
  preferredLanguage
}: Props): React.Element<'div'> => {
  React.useEffect((): void => {
    if (subscriptionId > 0) {
      getSubscriptionDetails(userId)
    }
  }, [subscriptionId, getSubscriptionDetails, userId])

  const subscription = subscriptionDetails.plan_details
  const shippingCountry = subscription.shipping_country_code
  const accountType = subscriptionDetails.account_type

  const { locale, currency } = countryCodeToLocaleCurrency(shippingCountry, preferredLanguage)

  const mealsTable = (meal: MealDetails): Array<React.Element<'tr'>> | null => {
    return meal.map(({ flavour, quantity, recipe_surcharge, total_price }: $ElementType<MealDetails, 0>): React.Element<'tr'> => (
      <tr key={flavour}>
        <td className='meal-flavour'>
          {
            flavour === 'white_fish'
              ? 'Fish'
              : formatter.capitalizeFirstLetter(flavour)
          }
        </td>
        <td className="meal-count">
          { quantity }
        </td>
        <td className="meal-count">
          { recipe_surcharge ? formatCurrencyWithDecimal(recipe_surcharge, { locale: locale, currency: currency }) : 0 }
        </td>
        <td className="meal-count">
          { formatCurrencyWithDecimal(total_price, { locale: locale, currency: currency }) }
        </td>
      </tr>
    ))
  }

  const checkAllergies = (allergies: AllergyDetails): string => {
    const allergicMeals = []
    Object.keys(allergies).forEach((key: string): void => {
      if (allergies[key]) {
        allergicMeals.push(key)
      }
    })
    if (allergicMeals.length > 0) {
      return allergicMeals.join(', ')
    } else {
      return 'None'
    }
  }

  const recurringExtraProductsTable = (recurringExtraProducts: Array<RecurringExtraProduct>): Array<React.Element<'tr'>> => {
    return recurringExtraProducts.map(({ product_code, quantity, one_off_price, recurring_price }: RecurringExtraProduct): React.Element<'tr'> => {
      return (
        <tr key={product_code}>
          <td className="treat-body">
            { product_code }
          </td>
          <td className="treat-body">
            { quantity }
          </td>
          <td className="treat-body">
            { recurring_price ? formatCurrencyWithDecimal(recurring_price * quantity, { locale: locale, currency: currency }) : 'Price unavailable' }
          </td>
          <td className="treat-body">
            { one_off_price ? formatCurrencyWithDecimal(one_off_price * quantity, { locale: locale, currency: currency }) : 'Price unavailable' }
          </td>
        </tr>
      )
    })
  }

  const recipeSurchargesTable = (recipeSurcharges: Array<RecipeSurcharge>): Array<React.Element<'tr'>> => {
    return recipeSurcharges.map(({ flavour, plan_size, price }: RecipeSurcharge): React.Element<'tr'> => {
      return (
        <tr key={flavour}>
          <td className="treat-body">
            { flavour }
          </td>
          <td className="treat-body">
            { plan_size }
          </td>
          <td className="treat-body">
            { price ? formatCurrencyWithDecimal(price, { locale: locale, currency: currency }) : 'Price unavailable' }
          </td>

        </tr>
      )
    })
  }

  const generateAccountTypeLabel = (accountType: $PropertyType<SubscriptionDetails, 'account_type'>): React.Node => {
    const formattedAccountType = accountType.charAt(0).toUpperCase() + accountType.slice(1)
    switch (accountType) {
      case 'paid': {
        return (
          <p>
            <span className='label label--green'>
              { formattedAccountType }
            </span>
          </p>
        )
      }
      case 'trial':
      case 'free': {
        return (
          <p>
            <span className='label label--yellow'>
              { formattedAccountType }
            </span>
          </p>
        )
      }
      default:
        (accountType: empty)
        throw new Error(`Unhandled accountType given to generateAccountTypeLabel(). Was provided: ${accountType}`)
    }
  }

  const renderPaymentDetails = (paymentMethodDetails: PaymentInformation): React.Node | null => {
    switch (paymentMethodDetails.name) {
      case 'card': {
        return (<CardComponent paymentMethodDetails={paymentMethodDetails} />)
      } case 'go_cardless': {
        return (<GoCardlessComponent paymentMethodDetails={paymentMethodDetails} />)
      } case 'paypal': {
        return (<PaypalComponent paymentMethodDetails={paymentMethodDetails} />)
      } case 'sepa_direct_debit': {
        return (<SepaDirectDebitComponent paymentMethodDetails={paymentMethodDetails} />)
      } default: {
        return null
      }
    }
  }

  const generateRow = (subscriptionDetails: SubscriptionDetails): React.Node => {
    const meal = subscriptionDetails.meal_details
    const recurringExtraProducts = subscriptionDetails.recurring_extra_products
    const recipeSurcharges = subscriptionDetails.recipe_surcharges
    const totalPriceOfExtras: number = recurringExtraProducts.reduce((total: number, { quantity, price }: RecurringExtraProduct): number => {
      return total + (quantity * price)
    }, 0)
    const totalPriceOfRecipeSurcharges: number = recipeSurcharges.reduce((total: number, { price }: RecipeSurcharge): number => {
      return total + price
    }, 0)
    const totalPriceOfMeals = subscription.price_per_pouch * subscription.number_of_pouches

    return (
      <div>
        <div className='card__row'>
          <p className='card__row__title'>
            { 'Plan' }
          </p>
          <p>
            { subscription.box_kind === 'all_bb' ? 'All Butternut' : 'Mixed' }
            <span className='label label--blue'>
              { subscription.plan_group }
            </span>
            {
              subscription.pricing_curve_type === 'convenience_pricing' && (
                <span className='label label--yellow'>
                  { 'Convenience Pricing' }
                </span>
              )
            }
          </p>
        </div>
        <div className='card__row'>
          <p className='card__row__title'>
            { 'Account type' }
          </p>
          { generateAccountTypeLabel(accountType) }
        </div>
        <div className='card__row'>
          <p className='card__row__title'>
            { 'Calorie %' }
          </p>
          <p>
            { subscription.calorie_percentage_provided }
          </p>
        </div>
        <div className='card__row'>
          <p className='card__row__title'>
            { 'Delivery frequency' }
          </p>
          <p>
            { `${subscription.duration_in_days} days` }
          </p>
        </div>
        <div className='card__row'>
          <p className='card__row__title'>
            { 'Amount of food' }
          </p>
          <p>
            { `${subscription.number_of_pouches} x ${subscription.pouch_size_in_grams}g pouches` }
          </p>
        </div>
        <div className='card__row'>
          <div className='card__row__buttons'>
            <button
              className='card__button card__button--edit-plan button--blue'
              onClick={toggleChangePlanModal}
              type='button'
            >
              { `Edit plan` }
            </button>
          </div>
        </div>
        <div className='card__row'>
          <p className='card__row__title'>
            { 'Price' }
          </p>
          <p>
            { `Meals: ${formatCurrencyWithDecimal(totalPriceOfMeals / subscription.duration_in_days, { locale: locale, currency: currency })} per day, ${formatCurrencyWithDecimal(totalPriceOfMeals, { locale: locale, currency: currency })} per box` }
          </p>
          <p>
            { `Extras: ${formatCurrencyWithDecimal(totalPriceOfExtras / subscription.duration_in_days, { locale: locale, currency: currency })} per day, ${formatCurrencyWithDecimal(totalPriceOfExtras, { locale: locale, currency: currency })} per box` }
          </p>
          <p>
            { `Recipe Surcharges: ${formatCurrencyWithDecimal(totalPriceOfRecipeSurcharges / subscription.duration_in_days, { locale: locale, currency: currency })} per day, ${formatCurrencyWithDecimal(totalPriceOfRecipeSurcharges, { locale: locale, currency: currency })} per box` }
          </p>
          <p>
            { `Delivery fee: ${formatCurrencyWithDecimal(subscriptionDetails.delivery_fee, { locale: locale, currency: currency })} per box` }
          </p>
          <p>
            { `Box total: ${formatCurrencyWithDecimal((totalPriceOfMeals + totalPriceOfRecipeSurcharges + totalPriceOfExtras) / subscription.duration_in_days, { locale: locale, currency: currency })} per day, ${formatCurrencyWithDecimal(totalPriceOfMeals + totalPriceOfRecipeSurcharges + totalPriceOfExtras + subscriptionDetails.delivery_fee, { locale: locale, currency: currency })} per box` }
          </p>
        </div>
        <div className='card__row'>
          <p className='card__row__title'>
            { 'Meals' }
          </p>
          { meal &&
            <table
              style={{ width: '100%' }}
              className='card__row__table'
            >
              <tbody>
                <tr className='meal-header'>
                  <th>
                    { 'Recipe' }
                  </th>
                  <th>
                    { 'Pouch Quantity' }
                  </th>
                  <th>
                    { 'Surcharge' }
                    <br />
                    { 'per Serving' }
                  </th>
                  <th>
                    { 'Total Price' }
                  </th>
                </tr>
                { mealsTable(meal) }
              </tbody>
            </table> }
        </div>
        <div className='card__row'>
          <p className='card__row__title'>
            { 'Extras' }
          </p>
          {
            recurringExtraProducts.length > 0
              ? (
                <table className='card__row__table'>
                  <tbody>
                    <tr key='subject'>
                      <td className="treat-title">
                        { 'Product' }
                      </td>
                      <td className="treat-title">
                        { 'Quantity' }
                      </td>
                      <td className="treat-title active-price">
                        { 'Subscribe & Save Total Price' }
                      </td>
                      <td className="treat-title">
                        { 'One-off Total Price' }
                      </td>
                    </tr>
                    { recurringExtraProductsTable(recurringExtraProducts) }
                  </tbody>
                </table>
                )
              : (
                <p>
                  { 'No Recurring Extra Products' }
                </p>
                )
          }
        </div>
        <div className='card__row'>
          <p className='card__row__title'>
            { 'Recipe Surcharges' }
          </p>
          {
            recipeSurcharges.length > 0
              ? (
                <table className='card__row__table'>
                  <tbody>
                    <tr key='subject'>
                      <td className="treat-title">
                        { 'Flavour' }
                      </td>
                      <td className="treat-title">
                        { 'Plan Size' }
                      </td>
                      <td className="treat-title">
                        { 'Price' }
                      </td>
                    </tr>
                    { recipeSurchargesTable(recipeSurcharges) }
                  </tbody>
                </table>
                )
              : (
                <p>
                  { 'No Recurring Extra Products' }
                </p>
                )
          }
        </div>
        <div className='card__row'>
          {
            deliveryDetails &&
              <a
                href={`/admin/ap_orders/${deliveryDetails.order_id}/edit`}
                rel="noopener noreferrer"
                target='_blank'
              >
                <button
                  className='card__button button--blue'
                  type='button'
                >
                  { `Edit Treats` }
                </button>
              </a>
          }
        </div>
        <div className='card__row_flex'>
          <p className='card__row__title'>
            { 'Allergies' }
          </p>
          <p>
            { checkAllergies(subscriptionDetails.allergy_details) }
          </p>
        </div>
        <div className='card__row'>
          <p className='card__row__title'>
            { 'Wants to recieve ice' }
          </p>
          <p style={{ margin: '0 0 16px' }}>
            { subscriptionDetails.wants_to_receive_ice ? (
              <span className='label label--green'>
                { `Yes` }
              </span>
            ) : (
              <span className='label label--red'>
                { `No` }
              </span>
            )}
          </p>
          <div className='card__row__buttons'>
            <button
              onClick={toggleWantsToReceiveIce}
              type='button'
              className='card__button card__button--edit-plan button--blue'
              disabled={isTogglingWantsToReceiveIce}
            >
              { isTogglingWantsToReceiveIce ? 'Updating...' : 'Toggle wants to receive ice' }
            </button>
          </div>
        </div>
        <div className='card__row'>
          <p className='card__row__title'>
            { 'Current Payment Provider' }
          </p>
          <p>
            { subscriptionDetails.current_payment_provider }
          </p>
        </div>
        { subscriptionDetails.payment_method_details.name &&
          renderPaymentDetails(subscriptionDetails.payment_method_details) }
        { subscriptionDetails.stripe_publishable_key !== null && (
          <div className='card__row'>
            <div className='card__row__buttons'>
              <button
                onClick={toggleAddCreditCardToSubscriptionModal}
                type='button'
                className='card__button card__button--edit-plan button--blue'
              >
                { 'Add Credit Card' }
              </button>
            </div>
          </div>
        ) }
        { subscriptionDetails.addCreditCardToSubscriptionDetails.addCreditCardToSubscriptionSuccessMessage !== null && (
          <div className='card__row'>
            <p className='card__row__title'>
              { subscriptionDetails.addCreditCardToSubscriptionDetails.addCreditCardToSubscriptionSuccessMessage }
            </p>
          </div>
        ) }

      </div>
    )
  }

  const renderTransition = (isFetching: boolean): string => isFetching ? 'loading' : 'loaded'

  const renderLoadingIcon = (): ?React.Element<'div'> => {
    if (isFetching) {
      return (
        <div className="loading-ring">
          <span />
          <span />
          <span />
          <span />
        </div>
      )
    }
  }

  const renderPauseButton = (accountStatus: AccountStatus): ?React.Node => {
    const pause = subscriptionDetails.last_pause_details
    switch (accountStatus) {
      case 'active':
        return (
          <a
            className='card__button button--red'
            href={`/admin/pauses/new?subscription_id=${subscriptionId}`}
            rel="noopener noreferrer"
            target='_blank'
          >
            { 'Stop subscription' }
          </a>
        )
      case 'paused':
        return (
          <div>
            <button
              className='card__button button--green'
              onClick={openRestartModal}
              type='button'
            >
              { 'Restart subscription' }
            </button>
            {
              pause &&
              <p>
                { `Paused by ` }
                <strong>
                  { pause.paused_by }
                </strong>
                { ` on ` }
                <strong>
                  { pause.date }
                </strong>
                { ` because of ` }
                <strong>
                  { pause.reason }
                </strong>
              </p>
            }
            {
              pause.subreason &&
              <p>
                { `Subreason: ${pause.subreason}` }
              </p>
            }
            {
              pause.extra_information &&
              <p>
                { `Extra information: ${pause.extra_information}` }
              </p>
            }
          </div>
        )
      case 'suspended':
        return (
          <a
            className='card__button button--green'
            href={`/admin/subscriptions/${subscriptionId}`}
            rel="noopener noreferrer"
            target='_blank'
          >
            { 'Charge card in account' }
          </a>
        )
    }
  }

  const renderViewSubscriptionButton = (): React.Node => {
    if (subscriptionDetails.status === 'unknown' && !isFetching) {
      return null
    }
    return (
      <a
        className='card__button button--white'
        href={`/admin/subscriptions/${subscriptionId}`}
        rel="noopener noreferrer"
        target='_blank'
      >
        <img
          alt="Eye icon"
          className='icon'
          src={subscriptionIcons.eye}
        />
        { 'View full subscription' }
      </a>
    )
  }

  const renderViewSubscriptionCard = (): React.Node => {
    if (subscriptionDetails.status === 'unknown' && !isFetching) {
      return (
        <p className="no-results">
          { 'Guest User' }
        </p>
      )
    }
    return (
      <div className='card__body'>
        <a
          className='card__button button--grey button--no-shadow'
          href={`/admin/subscriptions/${subscriptionId}/edit`}
          rel="noopener noreferrer"
          target='_blank'
        >
          <img
            alt='Pencil icon'
            className='icon'
            src={subscriptionIcons.edit}
          />
        </a>
        { renderLoadingIcon() }
        { generateRow(subscriptionDetails) }
        { renderPauseButton(subscriptionDetails.status) }
      </div>
    )
  }
  return (
    <div className={`subscription card__container card__${renderTransition(isFetching)}`}>
      <div className='card__header__container'>
        <h3 className='card__header'>
          { 'Subscription' }
        </h3>
        { renderViewSubscriptionButton() }
      </div>
      { renderViewSubscriptionCard() }
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionDetailsComponent)
