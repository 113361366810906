// @noflow
import React, { useCallback, useEffect, useRef, useState } from 'react'
import type { KeyboardEvent, MouseEvent, SyntheticEvent } from 'react'

import BRAND_COLOURS from '@/constants/BrandColours'
import type { BrandColours } from '@/constants/BrandColours'
import BREAKPOINTS from '@/constants/Breakpoints'

import { useInterval } from '@/hooks/useInterval'
import useWindowSize from '@/hooks/useWindowSize'

import StoryContent from './components/molecules/StoryContent'
import StoryProgress from './components/molecules/StoryProgress'
import CloudinaryImage from '@/components/elements/atoms/CloudinaryImage/CloudinaryImage'
import Icon from '@/components/elements/atoms/Icon/Icon'
import Text from '@/components/elements/atoms/Text/Text'
import type { Props as TextProps } from '@/components/elements/atoms/Text/Text'

import STYLES from './Stories.module.sass'

type Description = {
  textProps: TextProps
  texts: Array<string>
}

type DefinedStoryType = {
  id: string
  layout: 'defined'
  title: TextProps
  subTitle?: TextProps
  description?: Description
  bgSrc?: string
  bgColour?: keyof BrandColours
  bottomBgColour?: keyof BrandColours
  content?: never
}

type CustomStoryType = {
  id: string
  layout: 'custom'
  bgSrc?: string
  bgColour?: keyof BrandColours
  content?: React.ComponentType
  title?: never
  subTitle?: never
  description?: never
  bottomBgColour?: never
}

type Story = DefinedStoryType | CustomStoryType

type Props = {
  stories: Array<Story>
  width?: number
  height?: number
  onAllStoriesEnd?: () => void
  setIsModalOpen?: (boolean: boolean) => void
}

type StoriesArgs = Pick<Props, 'stories' | 'onAllStoriesEnd'>
type Stories = {
  storyWrapperRef: React.Ref<HTMLDivElement>
  currentIndex: number
  isStoryPaused: boolean
  handleStoryClick: (e: SyntheticEvent) => void
}

const DEFAULT_STORY_DURATION = 5000

const useStories = ({ stories, onAllStoriesEnd }: StoriesArgs) => {
  const storyWrapperRef = useRef<HTMLDivElement>(null)

  const [currentIndex, setCurrentIndex] = useState(0)
  const [storyDuration] = useState(DEFAULT_STORY_DURATION)
  const [remainingTime, setRemainingTime] = useState(DEFAULT_STORY_DURATION)
  const [storyProgress, setStoryProgress] = useState(0)
  const [isStoryPaused, setIsStoryPaused] = useState(false)

  // Handlers
  const handleResetRemainingTime = useCallback(() => {
    setRemainingTime(storyDuration)
  }, [setRemainingTime, storyDuration])

  /**
   * Reset story state
   *
   * - Unpause the story
   * - Reset remaining story time
   * - Reset story progress
   */
  const handleResetStoryState = useCallback(() => {
    setIsStoryPaused(false)
    handleResetRemainingTime()
    setStoryProgress(0)
  }, [handleResetRemainingTime])

  /**
   * Decrease current story index
   *
   * - Decrease index
   * - Reset story state
   */
  const handleDecreaseCurrentIndex = useCallback(() => {
    setCurrentIndex((index) => index - 1)
    handleResetStoryState()
  }, [handleResetStoryState])

  /**
   * Increase current story index
   *
   * - Increase index
   * - Reset story state
   */
  const handleIncreaseCurrentIndex = useCallback(() => {
    setCurrentIndex((index) => index + 1)
    handleResetStoryState()
  }, [handleResetStoryState])

  /**
   * Handle story navigation and pause state
   *
   * - Navigate to the previous story
   * - Pause/Unpause
   * - Navigate to the next story
   */
  const handleStoryClick = useCallback(
    (e: SyntheticEvent) => {
      const storyWrapperDivWidth =
        storyWrapperRef?.current?.getBoundingClientRect().width

      if (storyWrapperDivWidth) {
        // 1/3 of the story wrapper div
        const oneThirdStoryWrapperDivWidth = storyWrapperDivWidth / 3
        // 2/3 of the story wrapper div
        const twoThirdsStoryWrapperDivWidth = storyWrapperDivWidth / 1.5
        // Mouse position
        const mouseXPos = (e as MouseEvent).nativeEvent.offsetX

        if (mouseXPos <= oneThirdStoryWrapperDivWidth) {
          // Click on the left side of the div
          // Navigate to the previous story if it's available
          if (currentIndex > 0) {
            handleDecreaseCurrentIndex()
          }
        } else if (mouseXPos >= twoThirdsStoryWrapperDivWidth) {
          // Click on the right side of the div
          // Navigate to the next story if it's available
          if (currentIndex < stories.length - 1) {
            handleIncreaseCurrentIndex()
          }
        } else {
          // Click in the middle of the div
          // Toggle pause state of the current story
          setIsStoryPaused(!isStoryPaused)
        }

        // Keyboard navigation if story area is focused
        if ((e as KeyboardEvent).key) {
          switch ((e as KeyboardEvent).key) {
            case 'ArrowLeft': {
              // <-
              // Navigate to the previous story if it's available
              if (currentIndex > 0) {
                handleDecreaseCurrentIndex()
              }
              break
            }
            case 'Enter': {
              // Toggle pause/unpause the story
              setIsStoryPaused(!isStoryPaused)
              break
            }
            case 'ArrowRight': {
              // ->
              // Navigate to the next story if it's available
              if (currentIndex < stories.length - 1) {
                handleIncreaseCurrentIndex()
              }
              break
            }
            default: {
              break
            }
          }
        }
      }
    },
    [
      currentIndex,
      handleDecreaseCurrentIndex,
      handleIncreaseCurrentIndex,
      stories,
      isStoryPaused
    ]
  )
  const handleSkipClick = useCallback(() => {
    if (currentIndex < stories.length - 1) {
      handleIncreaseCurrentIndex()
    }
  }, [currentIndex, handleIncreaseCurrentIndex, stories])

  const handleBackClick = useCallback(() => {
    if (currentIndex < stories.length - 1) {
      handleDecreaseCurrentIndex()
    }
  }, [currentIndex, handleDecreaseCurrentIndex, stories])

  // Effects
  useEffect(() => {
    if (isStoryPaused) {
      handleResetRemainingTime()
    } else {
      setRemainingTime((remainingDuration) => remainingDuration - storyProgress)
    }
    /**
     * Disable because we don't need to deduct `storyProgress` from `remainingDuration`
     * at every `storyProgress` change, but only if unpause event is executed.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleResetRemainingTime, isStoryPaused])

  // Track story progress
  useInterval(
    () => {
      setStoryProgress((value) => value + 10)
    },
    isStoryPaused ||
      storyProgress >=
        storyDuration - (currentIndex === stories.length - 1 ? -100 : 10)
      ? null
      : 10
  )

  // Change stories automatically
  useInterval(
    () => {
      handleIncreaseCurrentIndex()
    },
    storyProgress === 0 || currentIndex >= stories.length - 1 || isStoryPaused
      ? null
      : remainingTime
  )

  // Fire custom event when all stories end
  useEffect(() => {
    if (
      onAllStoriesEnd &&
      currentIndex === stories.length - 1 &&
      storyProgress > storyDuration + 50
    ) {
      onAllStoriesEnd()
    }
  }, [
    onAllStoriesEnd,
    currentIndex,
    stories.length,
    storyDuration,
    storyProgress
  ])

  return {
    storyWrapperRef,
    currentIndex,
    isStoryPaused,
    storyDuration,
    handleStoryClick,
    handleSkipClick,
    setIsStoryPaused,
    handleBackClick
  }
}

/**
 * # Stories component
 *
 * ## Story type
 * Story can be `defined` or `custom` layout type.
 *
 * ### Defined layout
 * Includes background colour or cloudinary image as a story background, title,
 * sub title and background at the bottom.
 * #### Props
 * @param {string} id id of the story
 * @param {'defined'} layout type of the layout
 * @param {TextProps} title title of the story
 * @param {TextProps} subTitle sub title of the story
 * @param {string} [bgSrc] background cloudinary path of the image
 * @param {keyof BrandColours} [bgColour] background brand color
 * @param {keyof BrandColours} [bottomBgColour='brandYellow200'] bottom background brand color
 *
 * ### Custom layout
 * Clean layout that can include background colour or cloudinary image as a
 * story background, and a custom content via provided React component.
 * #### Props
 * @param {string} id id of the story
 * @param {'custom'} layout type of the layout
 * @param {string} [bgSrc] background cloudinary path of the image
 * @param {keyof BrandColours} [bgColour] background brand color
 * @param {React.ComponentType} [content] custom React component as a content
 *
 * ## Story navigation
 * Navigation can be operated via mouse/taps or keyboard.
 *
 * ### Using mouse/taps
 * Story component is devided vertically by 3 equal sections.
 * Each section is responsible for different events.
 * - Clicking left section navigates to the previous story unless the current
 * story is the first one
 * - Clicking in the middle pauses/unpauses the current story
 * - Clicking right section navigates to the next story unless the current
 * story is the last one
 *
 * ### Using keyboard
 * To use keyboard navigation story has to be focused first.
 * - ArrowLeft (<-) navigates to the previous story or pauses/unpauses story
 * if the current story is the first one
 * - Enter pauses/unpauses the current story
 * - ArrowRight (->) navigates to the next story or pauses/unpauses story if
 * the current story is the last one
 *
 * @example
  ```tsx

  import Stories from '@/components/elements/organisms/Stories'


  import type { Story } from '@/components/elements/organisms/Stories'

  const stories: Array<Story> = [
    {
      id: 'story-1'
      layout: 'defined',
      bgSrc: '/Web/photos/dogs/Nala.jpg',
      // If you'd like to have white tape with blue shadow title please add
      // the title as following in the translation file:
      // title_key: "<accent type='whiteTapeWithBlueShadow'>Title</accent>"
      title: {
        namespace: 'stories',
        text: 'story_1.title'
      },
      subTitle: {
        namespace: 'stories',
        text: 'story_1.sub_title'
      }
    },
    {
      id: 'story-2'
      layout: 'defined',
      // Brand colour as a background
      bgColour: 'brandPink200',
      // Brand colour as a bottom background (default brandYellow200)
      bottomBgColour: 'brandWhite',
      // Title with a default styles
      title: {
        // Don't tranlate strings
        translate: false,
        text: 'Not translated title'
      },
      subTitle: {
        translate: false,
        text: 'Not translated sub title'
      }
    },
    {
      id: 'story-3'
      layout: 'defined',
      bgColour: 'brandBlue200',
      bottomBgColour: 'brandRed200',
      // Title with white tape and blue shadow styles
      title: {
        translate: false,
        text: '<accent type="whiteTapeWithBlueShadow">Styled title</accent>'
      },
      subTitle: {
        translate: false,
        text: 'Not translated sub title'
      }
    },
    {
      id: 'story-4'
      layout: 'custom',
      bgSrc: '/Web/photos/dogs/Pip-profile.jpg',
      content: () => <p>Custom content component</p>
    },
  ]

  <Stories
    stories={stories}
    height={62}
  />
  ```
 *
 * @param {Props} props stories, [width = 100%] in rem, [height = 100%] in rem,
 * onAllStoriesEnd
 */
const Stories = ({
  stories,
  width,
  height,
  onAllStoriesEnd,
  setIsModalOpen
}: Props): JSX.Element => {
  const {
    storyWrapperRef,
    currentIndex,
    isStoryPaused,
    storyDuration,
    handleStoryClick,
    handleSkipClick,
    setIsStoryPaused,
    handleBackClick
  } = useStories({ stories, onAllStoriesEnd: onAllStoriesEnd })

  const { bgColour, bgSrc } = stories[currentIndex]
  const { windowWidth } = useWindowSize()
  const closeModal = useCallback(() => {
    if (setIsModalOpen) {
      setIsModalOpen(false)
    }
  }, [setIsModalOpen])

  const togglePause = useCallback(() => {
    setIsStoryPaused(!isStoryPaused)
  }, [setIsStoryPaused, isStoryPaused])

  return (
    <div
      className={`${STYLES.container}`}
      style={{
        width: width ? `${width}rem` : '100%',
        height: height ? `${height}rem` : '100%'
      }}
    >
      <StoryProgress
        stories={stories}
        currentIndex={currentIndex}
        isStoryPaused={isStoryPaused}
        storyDuration={storyDuration}
      />
      <div
        ref={storyWrapperRef}
        role="button"
        tabIndex={0}
        className={STYLES.story}
        style={{
          ...(bgColour ? { background: BRAND_COLOURS[bgColour] } : {})
        }}
        onClick={handleStoryClick}
        onKeyDown={handleStoryClick}
      >
        {bgSrc && (
          <div className={STYLES.bgImageWrapper}>
            <CloudinaryImage
              alt=""
              image={{
                path: bgSrc,
                crop: 'fill',
                dpr: 2
              }}
            />
          </div>
        )}
        <StoryContent currentStory={stories[currentIndex]} />
      </div>
      {windowWidth >= BREAKPOINTS.md && (
        <div>
          {currentIndex !== 0 && (
            <div
              role="button"
              className={`${STYLES.arrow} ${STYLES.back}`}
              tabIndex={0}
              onClick={handleBackClick}
              onKeyDown={handleBackClick}
            >
              <Icon asset="arrow" size={18} accentColour="brandWhite" />
            </div>
          )}
          {currentIndex !== stories.length - 1 && (
            <div
              role="button"
              tabIndex={0}
              className={STYLES.arrow}
              onClick={handleSkipClick}
              onKeyDown={handleSkipClick}
            >
              <Icon asset="arrow" size={18} accentColour="brandWhite" />
            </div>
          )}
        </div>
      )}
      <div className={STYLES.controllers}>
        {windowWidth >= BREAKPOINTS.md && (
          <div
            role="button"
            tabIndex={0}
            onClick={togglePause}
            onKeyDown={togglePause}
          >
            <Icon
              asset={isStoryPaused ? 'play' : 'pause'}
              size={22}
              accentColour="brandWhite"
            />
          </div>
        )}
        {currentIndex === stories.length - 1 ? (
          <div
            role="button"
            tabIndex={0}
            className={STYLES.closeButton}
            onClick={closeModal}
            onKeyDown={closeModal}
          >
            <Icon asset="close" size={15} accentColour="brandBlue500" />
          </div>
        ) : (
          <div
            role="button"
            tabIndex={0}
            onClick={handleSkipClick}
            onKeyDown={handleSkipClick}
          >
            <Text
              variant="textRegular16"
              namespace="shared"
              text="modals.stories.skip"
              colour="shadowBlack"
              margin={false}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export type { Props, Story }

export default Stories
