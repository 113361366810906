import { gql } from '@apollo/client'

const INSTANT_CHARGE_SETUP_CREATE = gql`
  mutation instantChargeSetupCreate($redirectUrl: String) {
    instantChargeSetupCreate(redirectUrl: $redirectUrl) {
      instantChargeSetupToken
      user {
        id
        firstName
        lastName
        email
      }
    }
  }
`

export { INSTANT_CHARGE_SETUP_CREATE }
