// @noflow
import React, { CSSProperties } from 'react'
import type { ReactElement } from 'react'

import CloudinaryImage from '@/components/elements/atoms/CloudinaryImage/CloudinaryImage'

import STYLES from './flavourimage.module.sass'

type Props = {
  flavour: {
    name: string
    themeColour: string
    image: {
      src: string
    }
  }
  quantity?: number
  extraClasses?: string
  styleOverrides?: CSSProperties
}

const FlavourImage = ({
  flavour: { name, themeColour, image },
  quantity,
  extraClasses = '',
  styleOverrides
}: Props): ReactElement => (
  <div className={`${STYLES.flavourImage} ${extraClasses}`}>
    <div
      className={`${STYLES.flavourImageFlavourWrapper}`}
      style={{
        backgroundColor: themeColour,
        ...styleOverrides
      }}
    >
      <CloudinaryImage
        alt={`A Butternut ${name} meal pouch.`}
        image={{
          path: image.src,
          height: 70,
          width: 70
        }}
      />
    </div>
    {typeof quantity === 'number' && (
      <span className={STYLES.flavourImageQuantity}>{quantity}</span>
    )}
  </div>
)

export default FlavourImage
