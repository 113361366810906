import { useReactiveVar } from '@apollo/client'
import i18next from 'i18next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Navigate } from 'react-router'

import { toLocalisedSentence } from '@/utils/StringHelper'

import ActionButtons from '../../../components/ActionButtons/ActionButtons'

import {
  CustomerIssueResolutionOfferingAccept_response_applicationDetails as ApplicationDetails,
  CustomerIssueResolutionOfferingAccept_response_applicationDetails_CustomerIssueManagementResolutionApplicationDetailsApplyDiscount as ApplyDiscountDetails,
  CustomerIssueResolutionOfferingAccept_response_applicationDetails_CustomerIssueManagementResolutionApplicationDetailsReplaceBox as ReplaceBoxDetails,
  CustomerIssueResolutionOfferingAccept_response_user as UserDetails
} from '../../../mutations/__generated__/CustomerIssueResolutionOfferingAccept'
import { CustomerIssueManagementResolutionResolutionType } from '@/types'

import {
  customerIssueManagementState,
  pageTitleState
} from '../../../CustomerIssueManagementPage'
import useCustomerIssueManagementTracking from '../../../analytics'
import CustomerIssueManagementRoutes from '../../../types/routes'
import ApplyDiscount from './ApplyDiscount'
import Default from './Default'
import Replacement from './Replacement'

type Props = {
  applicationDetails: ApplicationDetails
  user: UserDetails
}

const ResolutionConfirmationSwitch = ({
  applicationDetails,
  user
}: Props): JSX.Element => {
  const customerIssueManagementData = useReactiveVar(
    customerIssueManagementState
  )
  const [resolutionType, setResolutionType] = useState(
    'Resolution confirmation'
  )
  const customerIssueManagementTracking = useCustomerIssueManagementTracking()
  const replaceBoxDetails: ReplaceBoxDetails =
    applicationDetails as ReplaceBoxDetails
  const applyDiscountDetails: ApplyDiscountDetails =
    applicationDetails as ApplyDiscountDetails
  const joinedDogNames = useMemo(() => {
    if (
      customerIssueManagementData.dogs &&
      customerIssueManagementData.dogs.length > 0
    ) {
      const dogNames = customerIssueManagementData.dogs?.map((dog) => dog.name)
      return toLocalisedSentence({
        arr: dogNames,
        lng: i18next.language
      })
    } else {
      return ''
    }
  }, [customerIssueManagementData.dogs])

  const defaultHeaderText = useMemo(() => {
    return [
      // eslint-disable-next-line i18next/no-literal-string
      `resolutionConfirmation.${applicationDetails.type}.${customerIssueManagementData.selectedCategory?.key}.header`,
      // eslint-disable-next-line i18next/no-literal-string
      `resolutionConfirmation.${applicationDetails.type}.header`,
      'resolutionConfirmation.default.header'
    ]
  }, [
    applicationDetails.type,
    customerIssueManagementData.selectedCategory?.key
  ])

  const defaultDoneButtonText = useMemo(() => {
    return [
      // eslint-disable-next-line i18next/no-literal-string
      `resolutionConfirmation.${applicationDetails.type}.${customerIssueManagementData.selectedCategory?.key}.done`,
      // eslint-disable-next-line i18next/no-literal-string
      `resolutionConfirmation.${applicationDetails.type}.done`,
      'resolutionConfirmation.default.done'
    ]
  }, [
    applicationDetails.type,
    customerIssueManagementData.selectedCategory?.key
  ])

  const goToMyAccount = useCallback(() => {
    customerIssueManagementTracking.dropOff(resolutionType, 'Dashboard')
    window.location.href = '../dashboard'
  }, [resolutionType, customerIssueManagementTracking])

  const goToDeliveries = useCallback(() => {
    customerIssueManagementTracking.dropOff(resolutionType, 'Update deliveries')
    window.location.href = '../dashboard/boxes'
  }, [resolutionType, customerIssueManagementTracking])

  useEffect(() => {
    switch (applicationDetails.type) {
      case CustomerIssueManagementResolutionResolutionType.replace_box:
        pageTitleState('resolutionConfirmation.replacementBox.header')
        setResolutionType('Replacement box confirmation')
        break
      case CustomerIssueManagementResolutionResolutionType.apply_discount:
        pageTitleState('resolutionConfirmation.discount.header')
        setResolutionType('Discount Confirmation')
        break
      case CustomerIssueManagementResolutionResolutionType.track_order:
      case CustomerIssueManagementResolutionResolutionType.help_centre_article:
        pageTitleState(defaultHeaderText)
        // eslint-disable-next-line i18next/no-literal-string
        setResolutionType(`Default - ${applicationDetails.type}`)
        break
    }
  }, [applicationDetails.type, defaultHeaderText])

  switch (applicationDetails.type) {
    case CustomerIssueManagementResolutionResolutionType.replace_box:
      return (
        <>
          <Replacement
            dogNames={joinedDogNames}
            isoDeliveryDate={
              replaceBoxDetails.resolutionRemnant.isoDeliveryDate
            }
          />
          <ActionButtons
            acceptButton={{
              action: goToMyAccount,
              text: 'resolutionConfirmation.discount.account_button'
            }}
            rejectButton={{
              action: goToDeliveries,
              text: 'resolutionConfirmation.discount.deliveries_button'
            }}
          />
        </>
      )
    case CustomerIssueManagementResolutionResolutionType.apply_discount:
      return (
        <>
          <ApplyDiscount
            discountAmount={
              applyDiscountDetails.resolutionRemnant.applicableDiscountParts[0]
                .value
            }
            shippingCountryCode={user.shippingCountryCode}
            preferredLanguage={user.preferredLanguage}
          />
          <ActionButtons
            acceptButton={{
              action: goToMyAccount,
              text: 'resolutionConfirmation.discount.account_button'
            }}
            rejectButton={{
              action: goToDeliveries,
              text: 'resolutionConfirmation.discount.deliveries_button'
            }}
          />
        </>
      )
    case CustomerIssueManagementResolutionResolutionType.track_order:
    case CustomerIssueManagementResolutionResolutionType.help_centre_article:
      return (
        <>
          <Default
            resolutionType={applicationDetails.type}
            dogNames={joinedDogNames}
          />
          <ActionButtons
            acceptButton={{
              action: goToMyAccount,
              text: defaultDoneButtonText
            }}
            variant="center"
          />
        </>
      )
    default:
      return <Navigate to={CustomerIssueManagementRoutes.ContactCustomerLove} />
  }
}

export { Props }
export default ResolutionConfirmationSwitch
