// @noflow
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { trackEvent } from '@/services/segment'

import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { formatCurrencyWithoutDecimal } from '@/utils/currency'
import { percentageValue } from '@/utils/percentage'

import RafPassImg from 'assets/images/illustrations/raf-share.svg'

import Card from '@/components/elements/atoms/Card/Card'
import { CardSkeleton } from '@/components/elements/atoms/Card/CardSkeleton'
import Text from '@/components/elements/atoms/Text/Text'
import { rafPassModalState } from '@/components/pages/ReferAFriendPage/components/GiveAndGetTab/RAFPassModal/RAFPassModal'

import STYLES from './ShareRafPass.module.sass'

import { ShareRafPassFragment_subscription_referralLink as ReferralLink } from './fragment/__generated__/ShareRafPassFragment'
import type { Code as CountryCode, Language } from '@/types/index'

type Props = {
  referralLink: ReferralLink
  shippingCountryCode: CountryCode
  preferredLanguage: Language
  hasFirstTimeProfilePic: boolean
}

const ShareRafPass = ({
  referralLink,
  shippingCountryCode,
  preferredLanguage,
  hasFirstTimeProfilePic
}: Props): JSX.Element | null => {
  const { t } = useTranslation('account')
  const { pathname } = useLocation()

  const isDataLoaded = referralLink && shippingCountryCode && preferredLanguage

  const handleShareRafPass = useCallback(() => {
    rafPassModalState(true)
    trackEvent('RAF Pass Modal opened', {
      component_identifier: 'Raf Pass Share Card',
      screen_identifier: pathname
    })
  }, [pathname])

  if (!isDataLoaded) return <CardSkeleton height="12rem" width="100%" />

  const { locale, currency } = countryCodeToLocaleCurrency(
    shippingCountryCode,
    preferredLanguage
  )

  const { referralDiscount, referrerDiscount } = referralLink
  const { discountCodeParts } = referralDiscount
  const {
    value: firstPartDiscountValue,
    discountBasis: firstPartDiscountBasis
  } = discountCodeParts[0]
  const showMultiDiscount = discountCodeParts.length > 1
  const firstDiscountValue =
    firstPartDiscountBasis === 'total'
      ? formatCurrencyWithoutDecimal(firstPartDiscountValue, {
          locale,
          currency
        })
      : percentageValue(firstPartDiscountValue)

  const { applicableDiscountParts } = referrerDiscount || {}
  const { value, discountBasis } = (applicableDiscountParts || [])[0]

  // This is to exclude charitable and alturistic referrals, so that we instead
  // show the donation copy
  const shouldShowDiscount = value > 0
  const discountValue =
    discountBasis === 'total'
      ? formatCurrencyWithoutDecimal(value, { locale, currency })
      : percentageValue(value)

  const RAFTitle = hasFirstTimeProfilePic
    ? 'dog_profile.share_raf_pass.title'
    : 'dog_profile.share_raf_pass.title_alternative'

  return (
    <Card
      className={STYLES.wrapper}
      variant="brandYellow200"
      fill={false}
      onClick={handleShareRafPass}
      identifier="dog_profile.share_raf_pass"
    >
      <div>
        <Text
          text={RAFTitle}
          namespace="account"
          align="left"
          colour="brandBlue500"
          variant="display20"
          margin={false}
        />
        <Text
          text={
            shouldShowDiscount
              ? showMultiDiscount
                ? 'dog_profile.share_raf_pass.description_tiered'
                : 'dog_profile.share_raf_pass.description'
              : 'dog_profile.share_raf_pass.description_donation'
          }
          namespace="account"
          variables={{
            referrerDiscount: discountValue,
            referralDiscount: firstDiscountValue
          }}
          align="left"
          colour="brandBlue400"
          variant="textRegular18"
          margin={false}
        />
      </div>
      <img
        className={STYLES.rafPassImg}
        src={RafPassImg}
        alt={t('dog_profile.share_raf_pass.alt')}
      />
    </Card>
  )
}

export { ShareRafPass }
