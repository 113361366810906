// @noflow
import { Language } from '@/packs/localisation'
import { makeVar, useReactiveVar } from '@apollo/client'
import React from 'react'

import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { formatCurrencyWithDecimal } from '@/utils/currency'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import Modal from '@/components/elements/atoms/Modal/Modal'
import Text from '@/components/elements/atoms/Text/Text'
import ShareModalButton from '@/components/elements/molecules/ShareModalButton/ShareModalButton'
import { ReferAFriendPageQuery } from '@/components/pages/ReferAFriendPage/queries/__generated__/ReferAFriendPageQuery'

import STYLES from './ReferralSavingsModal.module.sass'

type Props = {
  namespace: string
  referralLink: ReferAFriendPageQuery['user']['subscription']['referralLink']
  shippingCountryCode: ReferAFriendPageQuery['user']['shippingCountryCode']
  preferredLanguage: Language
}

const referralSavingsModalState = makeVar(false)

const ReferralSavingsModal = ({
  namespace,
  referralLink,
  shippingCountryCode,
  preferredLanguage
}: Props): JSX.Element => {
  const { referralDiscountsTotalValue } = referralLink
  const { locale, currency } = countryCodeToLocaleCurrency(
    shippingCountryCode,
    preferredLanguage
  )

  const copyContext = 'refer_a_friend.give_and_get_tab.referral_savings_modal'
  const isReferralSavingsModalOpen = useReactiveVar(referralSavingsModalState)
  const { windowWidth } = useWindowSize()

  const handleOnClick = React.useCallback(() => {
    referralSavingsModalState(false)
  }, [])

  return (
    <Modal
      isModalOpen={isReferralSavingsModalOpen}
      setOpenModal={referralSavingsModalState}
      width={600}
      showCloseButton
      bodyScroll
      bottomSticky={windowWidth < BREAKPOINTS.md}
    >
      <>
        <div className={STYLES.title}>
          <Text
            namespace={namespace}
            text={`${copyContext}.title`}
            variant="display20"
            align="center"
            margin={false}
          />
        </div>
        <div className={STYLES.savingsContainer}>
          <Text
            text={formatCurrencyWithDecimal(referralDiscountsTotalValue, {
              locale,
              currency
            })}
            variant="display20"
            margin={false}
            translate={false}
            align="center"
          />
        </div>
        <Text
          namespace={namespace}
          text={`${copyContext}.body`}
          variant="textRegular14"
          align="center"
        />
        <div className={STYLES.buttonWrapper}>
          <ShareModalButton
            namespace={namespace}
            buttonText={`${copyContext}.earn_more`}
            onClick={handleOnClick}
          />
        </div>
      </>
    </Modal>
  )
}

export { referralSavingsModalState }
export default ReferralSavingsModal
