// @flow

import * as React from 'react'
import { connect } from 'react-redux'

import {
  possessive,
  capitaliseFirstLetter
} from '@/utils/StringHelper'

import { ORNAMENTS } from '@/components/post_sign_up_wizard/assets'
import FinishingUp from '@/components/post_sign_up_wizard/components/FinishingUp'
import PostWizardReferral from '@/components/post_sign_up_wizard/components/base_components/PostWizardReferral'
import * as GlobalAttributes from '@/components/post_sign_up_wizard/reducers/globalAttributesReducer'
import { useTranslation } from 'react-i18next'

import type { State } from '@/components/post_sign_up_wizard/reducers'
import type { State as GlobalAttributesState } from '@/components/post_sign_up_wizard/reducers/globalAttributesReducer'
import type { Dog } from '@/components/post_sign_up_wizard/message_types'

type PresentationalProps = {|
  fullName: string,
  shippingCountryCode: $PropertyType<GlobalAttributesState, 'shippingCountryCode'>,
  preferredLanguage: $PropertyType<GlobalAttributesState, 'preferredLanguage'>,
  dogs: Array<Dog>,
  referralUrl: $PropertyType<GlobalAttributesState, 'referralUrl'>,
  referralDiscount: $PropertyType<GlobalAttributesState, 'referralDiscount'>,
  referrerDiscount: $PropertyType<GlobalAttributesState, 'referrerDiscount'>,
  csrfToken: string,
  userID: number,
  postSignupReferrerDiscount: string,
  postSignupReferralDiscount: string,
  whatsappSingleDog: string,
  whatsappMultipleDogs: string
|}

type Props =
  & PresentationalProps

const mapStateToProps = ({ globalAttributes }: State): PresentationalProps => {
  const {
    dogs,
    shippingCountryCode,
    preferredLanguage,
    referralUrl,
    referrerDiscount,
    referralDiscount,
    csrfToken,
    userID,
    postSignupReferrerDiscount,
    postSignupReferralDiscount,
    whatsappSingleDog,
    whatsappMultipleDogs
  } = globalAttributes

  const fullName = GlobalAttributes.selectFullName(globalAttributes)

  return {
    fullName,
    shippingCountryCode,
    preferredLanguage,
    dogs,
    referralUrl,
    referrerDiscount,
    referralDiscount,
    csrfToken,
    userID,
    postSignupReferrerDiscount,
    postSignupReferralDiscount,
    whatsappSingleDog,
    whatsappMultipleDogs
  }
}

const PostWizard = ({
  fullName,
  shippingCountryCode,
  preferredLanguage,
  referralUrl,
  dogs,
  referrerDiscount,
  referralDiscount,
  csrfToken,
  userID,
  postSignupReferrerDiscount,
  postSignupReferralDiscount,
  whatsappSingleDog,
  whatsappMultipleDogs
}: Props): React.Node => {
  const { t } = useTranslation('post_signup_wizard')
  const copyContext = 'finishing_up'
  window.sessionStorage.setItem('completedPostSignUpDogProfileWizard', 'true')
  // Set the profiles 'loaded' state initially to false
  const [finishUpScreenIsVisible, setFinishUpScreen] = React.useState(true)

  React.useEffect((): void => {
    // After 5 seconds - enable the button as 'loaded'
    setTimeout((): void => {
      const names = dogs.map(({ name }: Dog): string => capitaliseFirstLetter(possessive(name)))
      document.title = t(`${copyContext}.page_title`, { count: dogs.length, dogName: names[0] })
    }, 5000)

    // And after 3.5s unmount the <FinishingUp /> component from the DOM
    setTimeout((): void => {
      setFinishUpScreen(false)
    }, 3500)
  }, [dogs, t])

  return (
    <React.Fragment>
      {
        finishUpScreenIsVisible && (
          <FinishingUp />
        )
      }
      <section className='raf-step'>
        <div className='raf-step__header'>
          <h1>
            { t(`${copyContext}.getting_ready`) }
          </h1>
        </div>
        <PostWizardReferral
          dogs={dogs}
          referrerDiscount={referrerDiscount}
          referralDiscount={referralDiscount}
          referralLink={referralUrl}
          shippingCountryCode={shippingCountryCode}
          preferredLanguage={preferredLanguage}
          fullName={fullName}
          postSignupReferrerDiscount={postSignupReferrerDiscount}
          postSignupReferralDiscount={postSignupReferralDiscount}
          whatsappSingleDog={whatsappSingleDog}
          whatsappMultipleDogs={whatsappMultipleDogs}
          csrfToken={csrfToken}
          userID={userID}
        />
        <div className='raf-step__cta'>
          <img
            alt=''
            src={ORNAMENTS.StarStarburstFlashGroup}
            className='raf-step__sparkles'
          />
          <a
            className='btn-updated btn-updated--red'
            href={encodeURI('/dashboard/my-dog?analytics[link_clicked]=Go-to-dog-profile-from-wizard')}
          >
            { t(`${copyContext}.button_text_finish`) }
          </a>
          <img
            alt=''
            src={ORNAMENTS.StarStarburstFlashGroup}
            className='raf-step__sparkles'
          />
        </div>
      </section>
    </React.Fragment>
  )
}

export default connect(
  mapStateToProps,
  null,
)(PostWizard)
