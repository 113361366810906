import { gql } from '@apollo/client'

const CATEGORIES = gql`
  query SelfResolutionCategories($boxId: ID!) {
    user {
      id
      shippingCountryCode
      preferredLanguage
      subscription {
        box(boxId: $boxId) {
          hasPreviousOrderIssue
          potentialMissingItems {
            name
            productId
            productCode
            category
            thumbnail
            quantity
            price
          }
          isoDeliveryDate
          price
        }
      }
    }
    categories: customerIssueCategories(selfServable: true) {
      id
      name
      description
      key
      selfServable
      parentId
    }
  }
`

export { CATEGORIES }
