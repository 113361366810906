// @noflow
import React from 'react'

import Separator from '@/components/elements/atoms/Separator/Separator'
import Text from '@/components/elements/atoms/Text/Text'
import type { Props as TextProps } from '@/components/elements/atoms/Text/Text'

import STYLES from './Faq.module.sass'

type Props = {
  bold?: boolean
  question: TextProps
  answer: TextProps | Array<TextProps>
  onClick?: () => void
  solid?: boolean
}

const Faq = ({
  question,
  answer,
  onClick,
  bold = false,
  solid = false
}: Props): JSX.Element => {
  const [isFaqOpen, setIsFaqOpen] = React.useState<boolean>(false)
  const [answerHeight, setAnswerHeight] = React.useState<number>(0)
  const content = React.useRef<HTMLDivElement>(null)

  const triggerToggle = React.useCallback((): void => {
    setIsFaqOpen(!isFaqOpen)

    if (!isFaqOpen && content.current) {
      setAnswerHeight(content.current.scrollHeight)

      if (onClick) {
        onClick()
      }
    } else {
      setAnswerHeight(0)
    }
  }, [isFaqOpen, content, onClick])

  // users can pass an array or a single object. Ensure we have an array
  const answers = Array.isArray(answer) ? answer : [answer]

  const answersList = answers.map(
    (answer: TextProps, index: number): JSX.Element => (
      <Text
        key={`${answer.text}--${index + 1}`}
        namespace={answer.namespace}
        text={answer.text}
        margin={answer.margin}
        translate={answer.translate}
        variables={answer.variables}
        variant={answer.variant}
        colour={answer.colour}
        shouldScale={answer.shouldScale}
      />
    )
  )

  return (
    <div className={STYLES.faq}>
      <button type="button" className={STYLES.question} onClick={triggerToggle}>
        <Text
          namespace={question.namespace}
          text={question.text}
          margin={false}
          translate={question.translate}
          variables={question.variables}
          variant={question.variant}
          colour={question.colour}
          shouldScale={question.shouldScale}
        />
        <span
          className={`${STYLES.questionIcon} ${
            isFaqOpen ? STYLES.questionIconOpen : ''
          }`}
        />
      </button>
      <div
        ref={content}
        className={`${STYLES.answer} ${isFaqOpen ? STYLES.answerOpen : ''}`}
        style={{ maxHeight: `${answerHeight}px` }}
      >
        {answersList}
      </div>
      <Separator bold={bold} solid={solid} />
    </div>
  )
}

export { Props }
export default Faq
