// @noflow
import type { Language } from '@/packs/localisation'
import Cookies from 'js-cookie'
import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import BREAKPOINTS from '@/constants/Breakpoints'
import popularBreeds from '@/constants/PopularBreeds'

import useWindowSize from '@/hooks/useWindowSize'

import segmentTrack from '@/components/analytics/Analytics'
import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'
import Text from '@/components/elements/atoms/Text/Text'
import BreedAutocomplete from '@/components/elements/molecules/BreedAutocomplete/BreedAutocomplete'
import type { BreedSelectorQuery_breeds as Breed } from '@/components/elements/molecules/BreedAutocomplete/queries/__generated__/BreedSelectorQuery'

import STYLES from './BreedQuestionaireCard.module.sass'

import BreedCard, {
  NonPopularBreed,
  PopularBreed
} from '../../atoms/BreedCard/BreedCard'

type Props = {
  ctaDestination: string
  isPreWizardGuest: boolean
  scrollToOnFocus?:
    | {
        topOffset: number
      }
    | false
  shouldSeeNewBreedSelector?: boolean
  preferredLanguage: Language
}

const BreedQuestionaireCard = ({
  scrollToOnFocus,
  isPreWizardGuest,
  ctaDestination,
  shouldSeeNewBreedSelector = false,
  preferredLanguage
}: Props): JSX.Element => {
  const namespace = 'paid_traffic'
  const { t } = useTranslation(namespace)
  const { windowWidth } = useWindowSize()
  const [selectedBreed, setSelectedBreed] = useState<
    PopularBreed | NonPopularBreed | null
  >(null)

  const breedSelectorRef = useRef<HTMLDivElement>(null)

  // Callbacks
  const onOpenCallback = useCallback(() => setSelectedBreed(null), [])
  const linkCTADestination = useCallback(() => {
    const eventName = 'Breed selector breed entered'
    const properties = {
      path: window.location.pathname,
      breed: selectedBreed?.key
    }

    segmentTrack(eventName, properties)

    window.location.href = ctaDestination
  }, [ctaDestination, selectedBreed])

  const onFocusCallback = useCallback(() => {
    if (scrollToOnFocus && breedSelectorRef.current) {
      const { topOffset } = scrollToOnFocus
      const containerDistanceFromTop =
        breedSelectorRef.current.getBoundingClientRect().top

      const y = containerDistanceFromTop + window.pageYOffset - topOffset

      // On iOS, when a user focuses on an input, the window is automatically
      // scrolled so the input element is placed above the keyboard. By setting a
      // very small timeout, we can ensure that our custom scroll position does not
      // scroll too far which was observed previously.
      setTimeout(() => window.scrollTo({ top: y, behavior: 'smooth' }), 100)
    }
  }, [scrollToOnFocus])

  const onBreedSelection = useCallback((breed: Breed) => {
    const matchingPopularBreed = popularBreeds[breed.key]

    // Set Breed ID cookie to be accessed by the Wizard to allow
    // for pre-populated breed selection
    Cookies.set(
      'breedSelectorBreedId',
      JSON.stringify({
        id: parseInt(breed.id),
        key: breed.key,
        name: breed.name
      })
    )

    if (matchingPopularBreed) {
      return setSelectedBreed({
        ...breed,
        ...matchingPopularBreed,
        isPopular: true
      })
    } else {
      return setSelectedBreed({
        ...breed,
        isPopular: false
      })
    }
  }, [])

  return (
    <div
      id="breed-questionaire"
      ref={breedSelectorRef}
      className={`${STYLES.container}`}
    >
      {shouldSeeNewBreedSelector && preferredLanguage === 'en' ? (
        <BreedAutocomplete
          loadingPlaceholder={t(`breed_hero_section.field_placeholder_loading`)}
          placeholder={t(`breed_hero_section.placeholder_v2`)}
          variant="v2"
          embeddedCTA
          onClickCTA={linkCTADestination}
          events={{
            onBreedSelection,
            onListOpen: onOpenCallback,
            onFocus: onFocusCallback,
            onInputEmpty: () => null
          }}
        />
      ) : (
        <Card>
          <Text
            text={'breed_hero_section.text'}
            namespace={namespace}
            variant="textRegular18"
            colour="brandBlue500"
            margin={false}
          />
          <div className={STYLES.autoCompleteWrapper}>
            <BreedAutocomplete
              loadingPlaceholder={t(
                `breed_hero_section.field_placeholder_loading`
              )}
              placeholder={t(`breed_hero_section.field_placeholder`)}
              events={{
                onBreedSelection,
                onListOpen: onOpenCallback,
                onFocus: onFocusCallback,
                onInputEmpty: () => null
              }}
            />
            <Button
              typography={{
                namespace,
                text: isPreWizardGuest
                  ? `breed_autocomplete.feedback.cta_build_your_box`
                  : `breed_autocomplete.feedback.cta_see_your_plan`
              }}
              size={windowWidth > BREAKPOINTS.md ? 'large' : 'regular'}
              onClick={linkCTADestination}
              disableAnalytics
            />
          </div>
        </Card>
      )}
      {selectedBreed !== null && (
        <div>
          {shouldSeeNewBreedSelector ? (
            <div
              className={
                shouldSeeNewBreedSelector ? STYLES.breedCardSection : ''
              }
            >
              <div className={STYLES.breedCardWrapper}>
                <BreedCard
                  selectedBreed={selectedBreed}
                  isPreWizardGuest={isPreWizardGuest}
                  namespace={namespace}
                  variant={'v2'}
                />
              </div>
            </div>
          ) : (
            <BreedCard
              selectedBreed={selectedBreed}
              isPreWizardGuest={isPreWizardGuest}
              namespace={namespace}
              variant={'v1'}
            />
          )}
        </div>
      )}
    </div>
  )
}

export { Props }
export default BreedQuestionaireCard
