import { Locale, addWeeks, format, isSameDay } from 'date-fns'
import React, { useCallback } from 'react'

import useWindowSize from '@/hooks/useWindowSize'

import AlertCard from '@/components/elements/atoms/Alert/AlertCard'
import { Button, ButtonProps } from '@/components/elements/atoms/Button'
import Modal from '@/components/elements/atoms/Modal/Modal'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import Text from '@/components/elements/atoms/Text'
import { CalendarDatesV2Fragment } from '@/components/elements/molecules/CalendarV2/fragments/__generated__/calendarDatesV2Fragment'
import { BREAKPOINTS } from '@/components/templates/Base'

import STYLES from './PushBoxModal.module.sass'

import { CourierDeliveryDatesQuery_user_subscription_nextNBoxes as Box } from '../../queries/__generated__/CourierDeliveryDatesQuery'

type Props = {
  showConfirmPushModal: boolean
  toggleConfirmPush: () => void
  locale: Locale
  loading: boolean
  nextBoxDate: Date
  nextBoxOutForDelivery: false | Box
  onPushClick: (newDate: Date) => void
  calendarDates: CalendarDatesV2Fragment[]
}

const textContext = 'change_date.push_box'

const getCalendarObject = (
  nextBoxDate: Date,
  calendarDates: CalendarDatesV2Fragment[]
) => {
  const nextBoxCalendarObject = calendarDates.find((date) =>
    isSameDay(new Date(date.date), nextBoxDate)
  )

  return nextBoxCalendarObject || {}
}

const adjustedDate = (
  date: Date,
  comparisonDate: Partial<CalendarDatesV2Fragment>
) => (!comparisonDate?.deliverable ? comparisonDate?.replacementDate : date)

const PushBoxModal = ({
  showConfirmPushModal,
  toggleConfirmPush,
  locale,
  loading,
  nextBoxOutForDelivery,
  onPushClick,
  nextBoxDate,
  calendarDates
}: Props): JSX.Element | null => {
  const formattedNextBoxDate = nextBoxDate ? new Date(nextBoxDate) : new Date()

  const formatDate = (date?: Date) =>
    date
      ? format(new Date(date), 'do MMMM yyyy', {
          locale
        })
      : ''

  const formatDateDay = (date?: Date) =>
    date
      ? format(new Date(date), 'cccc', {
          locale
        })
      : ''

  const addOneWeek = addWeeks(formattedNextBoxDate, 1)
  const calendarObjectOneWeek = getCalendarObject(addOneWeek, calendarDates)
  const adjustedOneWeek = adjustedDate(addOneWeek, calendarObjectOneWeek)

  const formatAddOneWeek = formatDate(adjustedOneWeek)
  const formatAddOneWeekDay = formatDateDay(adjustedOneWeek)

  const addTwoWeeks = addWeeks(formattedNextBoxDate, 2)
  const calendarObjectTwoWeek = getCalendarObject(addTwoWeeks, calendarDates)
  const adjustedTwoWeek = adjustedDate(addTwoWeeks, calendarObjectTwoWeek)

  const formatAddTwoWeeks = formatDate(adjustedTwoWeek)
  const formatAddTwoWeeksDay = formatDateDay(adjustedTwoWeek)

  const unskippableFormattedDate = formatDate(
    nextBoxOutForDelivery && nextBoxOutForDelivery?.isoDeliveryDate
  )

  const hasDateAdjustments =
    addOneWeek !== adjustedOneWeek || addTwoWeeks !== adjustedTwoWeek

  const handleClick = useCallback(
    (newDate) => onPushClick(newDate),
    [onPushClick]
  )

  const handlePushOneWeek = useCallback(() => {
    handleClick(adjustedOneWeek)
  }, [adjustedOneWeek, handleClick])

  const handlePushTwoWeeks = useCallback(() => {
    handleClick(adjustedTwoWeek)
  }, [adjustedTwoWeek, handleClick])

  const { windowWidth } = useWindowSize()

  const options = [
    {
      title: { text: 'change_date.push_box.body', variables: { weeks: 1 } },
      dow: formatAddOneWeekDay,
      date: formatAddOneWeek,
      button: {
        text: { text: 'change_date.push_box.button', variables: { weeks: 1 } },
        onClick: handlePushOneWeek,
        identifier: 'push_box_1_week',
        variant: 'primary'
      }
    },
    {
      title: {
        text: 'change_date.push_box.body_plural',
        variables: { weeks: 2 }
      },
      dow: formatAddTwoWeeksDay,
      date: formatAddTwoWeeks,
      button: {
        text: {
          text: 'change_date.push_box.button_plural',
          variables: { weeks: 2 }
        },
        onClick: handlePushTwoWeeks,
        identifier: 'push_box_2_weeks',
        variant: 'secondary'
      }
    }
  ]

  return (
    <Modal
      {...(windowWidth > BREAKPOINTS.md
        ? { position: 'top' }
        : { bottomSticky: true })}
      isModalOpen={showConfirmPushModal}
      width={600}
      showCloseButton
      onCloseButtonClick={toggleConfirmPush}
    >
      <div className={STYLES.modalInner}>
        <Text
          namespace="account"
          text={`${textContext}.title`}
          variant="display24"
          margin={false}
        />

        {hasDateAdjustments && (
          <SectionWrapper margin={{ top: 24 }}>
            <AlertCard
              variant="info"
              message={{
                namespace: 'account',
                text: 'change_date.push_box.adjusted_date_info'
              }}
            />
          </SectionWrapper>
        )}

        {nextBoxOutForDelivery && (
          <SectionWrapper margin={{ top: 24 }}>
            <AlertCard
              variant="info"
              message={{
                namespace: 'account',
                text: `change_date.skip_box.unskippable_info`,
                variables: { date: unskippableFormattedDate }
              }}
            />
          </SectionWrapper>
        )}

        {options.map((option) => (
          <SectionWrapper margin={{ top: 24 }} key={option.title.text}>
            <Text {...option.title} namespace="account" />

            <div className={STYLES.highlightText}>
              <Text
                translate={false}
                variant="textRegular14"
                text={option.dow}
                margin={false}
              />
              <Text
                margin={false}
                translate={false}
                variant="display16"
                text={option.date}
              />
            </div>

            <Button
              disabled={loading}
              typography={{
                ...option.button.text,
                namespace: 'account'
              }}
              onClick={option.button.onClick}
              identifier={option.button.identifier}
              fullWidth
              variant={option.button.variant as ButtonProps['variant']}
            />
          </SectionWrapper>
        ))}
      </div>
    </Modal>
  )
}

export default PushBoxModal
