import { gql } from '@apollo/client'

const APPLY_DISCOUNT = gql`
  mutation CustomerIssueResolutionAcceptanceApplyDiscount(
    $userId: ID!
    $reportId: ID!
  ) {
    response: customerIssueResolutionAcceptanceApplyDiscount(
      userId: $userId
      reportId: $reportId
    ) {
      resolutionApplicationConfirmation {
        type
      }
    }
  }
`

export { APPLY_DISCOUNT }
