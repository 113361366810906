// @noflow
import segmentTrack, { Analytics } from '@/components/analytics/Analytics'

import { ensureNever } from '@/typescript/utils'

import type { Route } from '../types/routes'
import type {
  Dog,
  PageLoadProperties,
  PuppyNotBroughtHomeProperties,
  User,
  WizardHealthIssuesTag
} from '../types/types'
import { isPuppyFlow } from './dogAge'

const DOG_PERSISTENCE_KEY = 'new-wizard-dog-persistence-v1'

const getPersistedDogs = (): Array<Dog> | undefined => {
  try {
    const raw = window.localStorage.getItem(DOG_PERSISTENCE_KEY)
    if (raw) {
      return JSON.parse(raw)
    }
  } catch (_) {
    window.localStorage.removeItem(DOG_PERSISTENCE_KEY)
    return []
  }
}

const trackUserAddedDogAndClickedSeePlan = (): void => {
  Analytics.track('User Added Dog And Clicked On See Plan CTA', {})
}

const trackPageLoad = (properties: PageLoadProperties): void => {
  Analytics.track('Stepped Wizard Page Load', properties)
}

const trackPuppyNotBroughtHome = (
  properties: PuppyNotBroughtHomeProperties
): void => {
  Analytics.track('Wizard: Puppy Not Brought Home', properties)
}

const trackWeightEstimationShown = (): void => {
  Analytics.track('Wizard puppy weight estimation shown', {})
}

const trackWeightEstimationUsed = (): void => {
  Analytics.track('Wizard puppy weight estimation used', {})
}

const trackDogIsRescue = (hasRescue: boolean): void => {
  Analytics.track(
    'Stepped Wizard How Old Question Has Rescued Dogs Indicator',
    { hasRescue }
  )
}

const trackDogIsWorkingDog = (hasWorkingDog: boolean): void => {
  Analytics.track('Wizard: Has Working Dog', { hasWorkingDog })
}

const trackPostcodeValidationError = ([error, postcode]: [
  string,
  string
]): void => {
  Analytics.track('Postcode Validation Error in Wizard', {
    error,
    postcode: postcode.replace(/[a-zA-Z]/g, 'X').replace(/\d/g, 'D')
  })
}

const trackPostcodeSubmitted = (postcode: string): void => {
  const matches = postcode.match(
    /^([A-PR-UWYZ]([0-9]{1,2}|([A-HK-Y][0-9]|[A-HK-Y][0-9]([0-9]|[ABEHMNPRV-Y]))|[0-9][A-HJKS-UW]))/i
  )
  Analytics.track('Postcode Submitted in Wizard', {
    outcode: matches?.[0]
  })
}

const trackUserSelectedHealthIssues = (
  userHasSelectedHealthIssues: boolean
): void => {
  Analytics.track('User has selected health issues', {
    userHasSelectedHealthIssues
  })
}

const triggerAnalytics = (route: Route, dogs: Array<Dog>, user: User): void => {
  // The !! ensures a boolean is returned on finding a dog in the array which
  // fulfills the criteria, i.e. a puppy / an adult dog / a senior dog
  const hasPuppyFlow = isPuppyFlow(dogs)
  const hasMultipleDogs = dogs.length > 1
  const hasAdult = !!dogs.find((dog: Dog) => dog.ageStage === 'adult')
  const hasPuppy = !!dogs.find((dog: Dog) => dog.ageStage === 'puppy')
  const hasSenior = !!dogs.find((dog: Dog) => dog.ageStage === 'senior')
  const hasRescue = !!dogs.find((dog: Dog) => dog.isRescue === true)
  const hasWorkingDog = !!dogs.find((dog: Dog) => dog.isWorkingDog === true)
  const goalSegment = user?.goalSegment
  const snackQuantity = dogs[0].snackingHabits

  const pageLoadProperties = {
    dogCount: dogs.length,
    hasAdult: hasAdult,
    hasPuppy: hasPuppy,
    hasSenior: hasSenior,
    isEligibleForStrippedBackWizard: hasPuppyFlow,
    location: route,
    goalSegment,
    snackQuantity
  }

  switch (route) {
    case '/wizard/new': {
      trackPageLoad(pageLoadProperties)
      break
    }
    case '/wizard/gender': {
      trackPageLoad(pageLoadProperties)
      break
    }
    case '/wizard/age': {
      trackPageLoad(pageLoadProperties)
      break
    }
    case '/wizard/breed': {
      if (!hasMultipleDogs && hasPuppy) {
        const puppyProperties: PuppyNotBroughtHomeProperties = {
          activityLevel: dogs[0].activityLevel,
          age: {
            months: dogs[0].age && dogs[0].age.months,
            weeks: dogs[0].age && dogs[0].age.weeks
          },
          allergies: dogs[0].allergies ? dogs[0].allergies : null,
          bodyCondition: dogs[0].bodyCondition,
          breed: {
            id: dogs[0].breed && dogs[0].breed.id,
            name: dogs[0].breed && dogs[0].breed.key
          },
          broughtHome: dogs[0].broughtHome,
          foodCategoryIds: dogs[0].foodCategoryIds,
          deSexed: dogs[0].neutered,
          eaterType: dogs[0].eaterType,
          healthIssues: dogs[0].healthIssues
            ? {
                tag: (dogs[0].healthIssues.length === 0
                  ? 'None'
                  : 'Some') as WizardHealthIssuesTag,
                contents: dogs[0].healthIssues.map((issue) => issue.name)
              }
            : null,
          isRescue: dogs[0].isRescue,
          isWorkingDog: dogs[0].isWorkingDog,
          mass: dogs[0].weight,
          name: dogs[0].name,
          sex: dogs[0].gender,
          snackingHabits: dogs[0].snackingHabits
        }
        trackPuppyNotBroughtHome(puppyProperties)
      }
      if (hasRescue) {
        trackDogIsRescue(hasRescue)
      }
      trackPageLoad(pageLoadProperties)
      break
    }
    case '/wizard/food': {
      trackPageLoad(pageLoadProperties)
      break
    }
    case '/wizard/eater': {
      trackPageLoad(pageLoadProperties)
      break
    }
    case '/wizard/fussiness-help': {
      trackPageLoad(pageLoadProperties)
      break
    }
    case '/wizard/goals': {
      trackPageLoad(pageLoadProperties)
      break
    }
    case '/wizard/body': {
      trackPageLoad(pageLoadProperties)
      break
    }
    case '/wizard/weight': {
      trackPageLoad(pageLoadProperties)
      break
    }
    case '/wizard/activity': {
      trackPageLoad(pageLoadProperties)
      break
    }
    case '/wizard/allergies': {
      if (!hasPuppyFlow && hasWorkingDog) {
        trackDogIsWorkingDog(hasWorkingDog)
      }
      trackPageLoad(pageLoadProperties)
      break
    }
    case '/wizard/health': {
      trackPageLoad(pageLoadProperties)
      break
    }
    case '/wizard/snacks': {
      trackPageLoad(pageLoadProperties)
      break
    }
    case '/wizard/pet-parent': {
      if (user.location.postcode) {
        trackPostcodeSubmitted(user.location.postcode)
      }
      trackPageLoad(pageLoadProperties)
      break
    }
    default: {
      ensureNever(route)
      window.location.href = '/wizard/new'
    }
  }
}

const trackEmailAlreadyExists = (): void => {
  segmentTrack('Existing email address signup attempted')
}

export {
  getPersistedDogs,
  trackPageLoad,
  trackPuppyNotBroughtHome,
  trackWeightEstimationShown,
  trackWeightEstimationUsed,
  trackDogIsRescue,
  trackDogIsWorkingDog,
  trackPostcodeValidationError,
  trackPostcodeSubmitted,
  triggerAnalytics,
  trackUserAddedDogAndClickedSeePlan,
  trackUserSelectedHealthIssues,
  trackEmailAlreadyExists
}
