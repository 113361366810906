// @flow

import React from 'react'
import type { Node } from 'react'
import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import { Provider } from 'react-redux'
import AnalyticsMiddleware from 'redux-action-analytics-middleware'
import thunk from 'redux-thunk'
import { BrowserRouter as Router  } from 'react-router-dom'

import * as MESSAGES from './actions/messages'

import WizardSteps from './components/WizardSteps'
import { trackCallback, trackableActions } from './analytics'
import wizardStep from './reducers/wizardStepReducer'
import globalAttributes from './reducers/globalAttributesReducer'
import requests from './reducers/requestsReducer'
import dogProfiles, { init as initDogProfiles } from './reducers/dogProfilesReducer'

import { routeToWizardStep } from './postSignUpRoutes'
import scrollPage from '../../redux/middlewares/scrollPage'

import type { RailsModelID } from '../../shared_types/ids'
import type { CSRFToken, Token } from '../../shared_types/tokens'
import type {
  Dog,
  ApplicableDiscountPart
} from './message_types'
import type { State } from './reducers'
import type { State as GlobalAttributesState } from './reducers/globalAttributesReducer'
import type {
  ShouldScrollCallbackParameters
} from '../../redux/middlewares/scrollPage'

type Props = {|
  csrfToken: CSRFToken,
  token: Token,
  firstName: string,
  lastName: string,
  shippingCountryCode: $PropertyType<GlobalAttributesState, 'shippingCountryCode'>,
  preferredLanguage: $PropertyType<GlobalAttributesState, 'preferredLanguage'>,
  userID: RailsModelID,
  dogs: Array<Dog>,
  referralUrl: string,
  referrerDiscount: ?ApplicableDiscountPart,
  referralDiscount: ApplicableDiscountPart,
  deliveriesReceived: $PropertyType<GlobalAttributesState, 'deliveriesReceived'>,
  postSignupReferrerDiscount: string,
  postSignupReferralDiscount: string,
  whatsappSingleDog: string,
  whatsappMultipleDogs: string
|}

const PostSignUpWizard = ({
  csrfToken,
  token,
  firstName,
  lastName,
  shippingCountryCode,
  preferredLanguage,
  userID,
  dogs,
  referralUrl,
  referrerDiscount,
  referralDiscount,
  deliveriesReceived,
  postSignupReferrerDiscount,
  postSignupReferralDiscount,
  whatsappSingleDog,
  whatsappMultipleDogs
}: Props): Node => {
  const masterReducer = combineReducers({
    wizardStep,
    globalAttributes,
    dogProfiles,
    requests
  })
  const analyticsMiddleware = AnalyticsMiddleware({
    trackCallback,
    trackableActions
  })

  const scrollPageMiddleware = scrollPage({
    scrollProperties: {
      behavior: 'smooth',
      top: 0
    },
    historyProperties: {
      scrollRestoration: 'manual'
    },
    shouldScroll: ({
      action,
      preActionState,
      postActionState
    }: ShouldScrollCallbackParameters<State>): boolean => {
      const actionTypesToConsider = [
        MESSAGES.SET_WIZARD_STEP,
        MESSAGES.NEXT_WIZARD_STEP,
        MESSAGES.PREVIOUS_WIZARD_STEP
      ]
      if (!actionTypesToConsider.includes(action.type)) return false

      const previousWizardStep = preActionState.wizardStep
      const currentWizardStep = postActionState.wizardStep
      if (previousWizardStep !== currentWizardStep) {
        return true
      }
      return false
    }
  })

  const middleware = [
    thunk,
    scrollPageMiddleware,
    analyticsMiddleware
  ]

  // To use Redux extension in browser
  // https://github.com/zalmoxisus/redux-devtools-extension#usage
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const store = createStore(
    masterReducer,
    {
      wizardStep: routeToWizardStep(window.location.pathname),
      globalAttributes: {
        firstName,
        lastName,
        shippingCountryCode,
        preferredLanguage,
        csrfToken,
        token,
        userID,
        dogs,
        referralUrl,
        referrerDiscount,
        referralDiscount,
        deliveriesReceived,
        postSignupReferrerDiscount,
        postSignupReferralDiscount,
        whatsappSingleDog,
        whatsappMultipleDogs
      },
      dogProfiles: initDogProfiles(dogs)
    },
    composeEnhancers(
      applyMiddleware(...middleware)
    )
  )

  return (
    <Provider store={store}>
      <Router>
        <WizardSteps />
      </Router>
    </Provider>
  )
}

export default PostSignUpWizard
