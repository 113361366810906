// @noflow
import React from 'react'

type Props = {
  size: number
  width?: number | string
}

const Stethoscope = ({ size, width }: Props): JSX.Element => {
  return (
    <svg
      viewBox="0 0 23 32"
      xmlns="http://www.w3.org/2000/svg"
      width={width || '100%'}
      height={size}
      fill="none"
    >
      <path
        d="M2.899 22.464c0 .382-.144 1.12.039 1.45.077.138.162.318.274.43.184.185.222-.01.353-.113.346-.27.806-1.293.43-1.689-.158-.167-.916-.673-1.096-.313M13.443 2.188c0 .381-.144 1.12.039 1.45.077.138.162.318.274.43.184.184.222-.011.353-.113.346-.27.806-1.293.43-1.689-.158-.167-.916-.673-1.096-.313M11.092 1.796c-.162.593-1.219.86-1.254 1.489-.012.23-.212.688-.152.896.055.195.851-1.032.897-1.088.26-.318.286-.822.352-1.219"
        stroke="#fff"
      />
      <path
        d="M9.939 2.322c-.925 0-2.714-1.726-3.822-1.234-4.052 2.028-2.152 5.005-2.123 6.31.033 1.41 1.2 2.95 2.123 3.925.732.772 1.398 1.639 2.057 2.463.472.59.977 1.165 1.51 1.698.239.24.47.535.764.699.287.159.46.514.68.745.212.224.588.581.698.868.06.158.895 1.266.953 1.208.337-.337.45-1.062.684-1.477.182-.324.459-.59.637-.901.183-.32.493-.556.717-.845.221-.284.338-.673.6-.934.813-.813 1.424-1.85 2.25-2.675.354-.354.576-.756.891-1.141.366-.447.776-.866 1.19-1.279.609-.61 1.225-1.365 1.655-2.118.308-.538.414-1.299.552-1.91.205-.911.82-2.229.486-3.129-.106-.285-.475-.434-.745-.528-.544-.19-1.188-.186-1.694-.467-.287-.16-.998-.187-1.326-.208-.817-.05-1.69-.06-2.496.08-.547.096-1.05.494-1.523.765-.152.087-.217.78-.217.34"
        stroke="#174F78"
      />
      <path
        d="M14.44 2.916c0-.363-.342-.886-.68-1.038-.54-.243-.492-.053-.698.359-.284.567-.236 1.182-.236 1.826 0 .435 1.166.533 1.486.462.488-.109.195-1.371-.042-1.609M11.298 2.831c0-.371-.108-1.351-.34-1.061-.219.273-.395.62-.68.844-.423.333-1.263 1.328-.952 1.916.056.106.519.254.656.254.41 0 .325-.21.594-.424.097-.078.205-.547.212-.68.017-.293.265-.635.34-.934M12.487 7.625c0 .932-.19 2.094.066 2.991.078.275.188 1.198.188.406"
        stroke="#174F78"
      />
      <path
        d="M10.449 9.493h2.387c.507 0 1.241-.14 1.689.085M12.742 20.24v1.316c0 .486-.188.467-.642.467-.73 0-1.42-.023-2.123-.189-1.41-.331-3.18.315-3.18 2.01 0 .33-.046.442.255.576.274.121.632.066.934.066 1.348 0 2.685-.085 4.029-.085 1.227 0 2.229.102 3.378.528.64.237.992 1.032 1.274 1.595.605 1.21-.25 2.478-1.142 3.208-.434.355-1.027.489-1.562.575-.931.152-1.844.293-2.788.293-.574 0-1.15-.17-1.703-.17-.326 0-.61-.17-.939-.17-.278 0-.551-.16-.82-.17-.312-.012-.715-.061-1-.188-.273-.122-.574-.1-.855-.198-.596-.21-1.16-.417-1.693-.76-.834-.536-.51-2.326-.51-3.184"
        stroke="#174F78"
      />
      <path
        d="M3.57 21.174c-.681 0-2.1.301-2.288 1.146-.108.487.078.912.16 1.364.198 1.088.905 1.736 2 1.736 1.202 0 1.742-.821 1.742-1.991 0-.595.221-1.477-.236-1.934-.296-.296-.643-.236-1.038-.236"
        stroke="#174F78"
      />
      <path
        d="M7.737 13.59c.124.023.177.07.279.165.626.586 1.173 1.264 1.75 1.897.427.468.953.904 1.326 1.42.249.345.634.57.891.892.246.307.34.133.52-.095.303-.388.842-.71 1.056-1.137a.834.834 0 0 1 .293-.32c.219-.13.357-.549.49-.765.204-.33.514-.854.783-1.123.306-.306.473-1.17.85-1.358"
        stroke="#fff"
      />
      <path
        d="M6.555 13.141c-.057.066-.427.747-.44.836.116.058.657.607.749.705.102.11.215.227.302.35.18.258.466.45.624.73.124.218.307.403.47.591.12.137.286.236.395.378.095.123.235.228.346.339.118.118.233.242.356.356.135.126.228.287.383.395.108.074.247.258.383.274.082.009.233.253.29.311.174.179.374.334.57.486l.017.013c.123.096.272.163.383.274.11.11.236.212.366.296.222.142.457.321.709.393.082.024.16.078.24.122.046.026.21-.184.234-.204.125-.1.237-.21.369-.303.295-.209.513-.508.766-.763a6.53 6.53 0 0 0 .81-.954c.136-.204.32-.348.482-.527.358-.398.646-.85.986-1.264.223-.272.422-.557.624-.838.097-.133.178-.276.264-.416.085-.14.204-.263.295-.4.067-.1.158-.195.214-.301.067-.127.112-.136.017-.231-.052-.053-.1-.117-.156-.165-.042-.036-.06-.09-.097-.13-.045-.047-.107-.065-.153-.11-.226-.213-.488-.382-.706-.608-.202-.21-.435-.466-.673-.63-.042-.029-.196-.175-.253-.162-.07.015-.138.147-.187.196-.303.303-.559.65-.853.964-.118.126-.201.279-.308.416-.12.155-.237.325-.35.487-.164.233-.358.445-.537.664-.12.146-.248.289-.35.448-.059.09-.16.156-.213.241-.095.151-.357.252-.495.36-.072.057-.242.249-.349.217-.005-.002-.034-.08-.06-.095-.071-.041-.125-.147-.176-.21a1.312 1.312 0 0 0-.294-.262c-.306-.21-.555-.483-.812-.755-.518-.547-.991-1.136-1.512-1.687-.123-.13-.224-.284-.367-.394-.09-.07-.123-.187-.224-.252a1.452 1.452 0 0 1-.265-.242c-.042-.05-.107-.053-.152-.098a.462.462 0 0 0-.136-.104c-.118-.044-.138-.097-.236-.005-.05.047-.151.12-.21.153-.143.082-.274.243-.393.362-.136.137-.27.276-.405.415-.102.105-.187.226-.282.338Z"
        stroke="#174F78"
      />
    </svg>
  )
}

export default Stethoscope
