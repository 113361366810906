// @noflow
import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'

/**
 * A dog is a puppy from 0 months to the age of 11 months. Once a puppy reaches
 * 12 months old (1 year old) it is no longer considered a puppy from a digital
 * product perspective.
 *
 * This differs from the puppy feeding guidelines which continues up to and
 * including 18 months. These guidelines should be thought of as development
 * feeding guidelines instead of 'puppy' feeding guidelines.
 *
 * A dog is an adult from 12 to 144 months.
 *
 * A dog is a senior from 145 months.
 */
const MAX_PUPPY_AGE_IN_MONTHS = 11
const MIN_SENIOR_AGE_IN_MONTHS = 145

/**
 * This number is the total number of Extra products that is allowed in a
 * customers box. This number is set by current logistical restrictions and the
 * type of products/food we can actually fit in a single box.
 */

const MAXIMUM_NUMBER_OF_EXTRA_PRODUCTS = 40
const MAXIMUM_NUMBER_OF_SEPARATE_DELIVERY_EXTRA_PRODUCTS = 230

const MINIMUM_AMOUNT_ORDER_WITHOUT_EXTRA_FEE = (
  shippingCountryCode: CountryCode
): number => {
  switch (shippingCountryCode) {
    case 'NL':
    case 'BE': {
      return 2499
    }
    case 'NI':
    case 'IE':
    default: {
      return 800
    }
  }
}

const MINIMUM_ORDER_AMOUNT_NL = 2499

export {
  MAX_PUPPY_AGE_IN_MONTHS,
  MIN_SENIOR_AGE_IN_MONTHS,
  MAXIMUM_NUMBER_OF_EXTRA_PRODUCTS,
  MAXIMUM_NUMBER_OF_SEPARATE_DELIVERY_EXTRA_PRODUCTS,
  MINIMUM_AMOUNT_ORDER_WITHOUT_EXTRA_FEE,
  MINIMUM_ORDER_AMOUNT_NL
}
