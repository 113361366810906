// @noflow
import { makeVar, useQuery, useReactiveVar } from '@apollo/client'
import React from 'react'

import { percentageValue } from '@/utils/percentage'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import { Button } from '@/components/elements/atoms/Button'
import CloudinaryImage from '@/components/elements/atoms/CloudinaryImage/CloudinaryImage'
import Modal from '@/components/elements/atoms/Modal/Modal'
import Text, { Props as TextProps } from '@/components/elements/atoms/Text/Text'
import Faq from '@/components/elements/molecules/Faq/Faq'
import ProductBenefit from '@/components/elements/molecules/ProductBenefit/ProductBenefit'

import STYLES from './FlavourInformationModal.module.sass'

import { FLAVOUR_QUERY } from './queries/flavourQuery'

import type {
  FlavourQuery,
  FlavourQueryVariables
} from './queries/__generated__/FlavourQuery'

import SwiperSlider from '../../molecules/SwiperSlider/SwiperSlider'

type ModalData = {
  isOpen: boolean
  slug: string
}

const modalData = makeVar({
  isOpen: false
} as ModalData)

const FlavourInformationModal = (): JSX.Element | null => {
  const modalState = useReactiveVar(modalData)
  const { data, loading, error } = useQuery<
    FlavourQuery,
    FlavourQueryVariables
  >(FLAVOUR_QUERY, {
    variables: { slug: modalState.slug },
    skip: !modalState.slug
  })

  const { windowWidth } = useWindowSize()

  const setOpenModal = React.useCallback((): void => {
    modalData({ ...modalState, isOpen: !modalState.isOpen })
  }, [modalState])

  const closeModal = React.useCallback((): void => {
    setTimeout((): void => {
      modalData({ ...modalState, isOpen: false })
    }, 250)
  }, [modalState])

  if (error)
    throw new Error(
      `GraphQL occurred when executing FLAVOUR_QUERY: ${error.message} ${modalState.slug}`
    )
  if (loading || !data) {
    return (
      <Modal
        isModalOpen={modalState.isOpen}
        setOpenModal={setOpenModal}
        width={600}
      >
        <Text text={''} translate={false} />
      </Modal>
    )
  }

  const {
    flavour: {
      images,
      primaryImage,
      name,
      longDescription,
      tastingNotes,
      productBenefits,
      composition,
      flavourConstituents,
      additives,
      storage
    }
  } = data

  const additivesCopy: TextProps[] = [
    {
      namespace: 'shared',
      text: 'recipe_modals.nutritional_additives',
      variant: 'display16',
      colour: 'brandBlue500'
    },
    {
      text: additives,
      margin: false,
      translate: false
    }
  ]

  const constituentsCopy: TextProps[] = flavourConstituents.map(
    (flavourConstituent) => ({
      text: `${flavourConstituent.constituent.name} ${percentageValue(
        flavourConstituent.percentage
      )}`,
      colour: 'brandBlue500',
      translate: false
    })
  )

  const nutritionalInfoAnswer: TextProps[] = [
    {
      namespace: 'shared',
      text: 'recipe_modals.analytical_constituents',
      variant: 'display16',
      colour: 'brandBlue500'
    }
  ]

  const nutritionalInfoAnswerCopy = nutritionalInfoAnswer
    .concat(constituentsCopy)
    .concat(additivesCopy)

  return (
    <Modal
      isModalOpen={modalState.isOpen}
      setOpenModal={setOpenModal}
      width={600}
      padding={false}
      fullHeight={windowWidth < BREAKPOINTS.md}
    >
      <SwiperSlider
        slidesPerView={1}
        arrows
        arrowsInside
        variant="brandBlue100"
        bullets
      >
        <>
          <div className={STYLES.carouselImage}>
            <CloudinaryImage
              alt=""
              image={{
                path: primaryImage.src,
                width: 600,
                height: 400,
                crop: 'fill'
              }}
            />
          </div>
          {images.map(
            (image): JSX.Element => (
              <div key={image.src} className={STYLES.carouselImage}>
                <CloudinaryImage
                  alt=""
                  image={{
                    path: image.src,
                    width: 600,
                    height: 400,
                    crop: 'fill'
                  }}
                />
              </div>
            )
          )}
        </>
      </SwiperSlider>
      <div className={STYLES.information}>
        <Text
          text={name}
          variant="display24"
          colour="brandBlue500"
          translate={false}
        />
        <Text
          text={`${tastingNotes} ${longDescription}`}
          translate={false}
          variant="textRegular18"
          colour="brandBlue500"
        />
        <Text
          namespace="shared"
          text={'recipe_modals.key_benefits'}
          variant="display20"
          colour="brandBlue500"
        />
        <div className={STYLES.benefitList}>
          {productBenefits.map((benefit) => (
            <ProductBenefit
              key={benefit.name}
              benefit={{
                name: benefit.name,
                url: benefit.image?.src || null
              }}
            />
          ))}
        </div>
        <Faq
          question={{
            namespace: 'shared',
            text: 'recipe_modals.ingredients',
            variant: 'display20',
            colour: 'brandBlue500'
          }}
          answer={{
            text: composition,
            margin: false,
            translate: false
          }}
        />
        <Faq
          question={{
            namespace: 'shared',
            text: 'recipe_modals.nutritional_info',
            variant: 'display20',
            colour: 'brandBlue500'
          }}
          answer={nutritionalInfoAnswerCopy}
        />
        <Faq
          question={{
            namespace: 'shared',
            text: 'recipe_modals.storage_and_use',
            variant: 'display20',
            colour: 'brandBlue500'
          }}
          answer={{
            text: storage,
            margin: false,
            translate: false
          }}
        />
      </div>
      <div className={STYLES.stickyContainer}>
        <Button
          typography={{
            namespace: 'plans_flow',
            text: 'plan_steps.recipes.modal_ctas.back_to_recipes' // TODO: change to conditional CTA keys: add_recipe/back_to_recipes
          }}
          onClick={closeModal} // TODO: handle conditional events
          disableAnalytics
        />
      </div>
    </Modal>
  )
}

export { FLAVOUR_QUERY, modalData }

export default FlavourInformationModal
