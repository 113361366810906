// @noflow
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import type { Gender } from '@types'
import i18next from 'i18next'
import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
// Routing
import { useLocation, useNavigate } from 'react-router-dom'

import { possessive, pronounContext } from '@/utils/StringHelper'
import * as DiscountCodes from '@/utils/butternutbox/discountCodes'
import type { DiscountCodePart } from '@/utils/butternutbox/discountCodes'
import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { formatCurrencyWithDecimal } from '@/utils/currency'
import * as Sentry from '@/utils/sentry'

import CadenceSection from './components/CadenceSection/CadenceSection'
import AlertCard from '@/components/elements/atoms/Alert/AlertCard'
import Label from '@/components/elements/atoms/Label/Label'
import Text from '@/components/elements/atoms/Text/Text'
import SelectableCard from '@/components/elements/molecules/SelectableCard/SelectableCard'
import HeroText from '@/components/elements/organisms/HeroText/HeroText'
import StickyNavigation from '@/components/elements/organisms/StickyNavigation/StickyNavigation'
import {
  deliveryCadenceState,
  planRecommendationState,
  plansFlowTypeState,
  plansPageState,
  plansPageWizardState,
  plansSkipLoadingScreenState,
  recommendedExtraProductVariantsState
} from '@/components/pages/PlansPage/PlansPage'
import FAQSection from '@/components/pages/PlansPage/components/FAQSection/FAQSection'
import TopNavigation from '@/components/pages/PlansPage/components/TopNavigation/TopNavigation'
import { currentUserToLocaleData } from '@/components/pages/PlansPage/helpers/currentUserToLocaleData'
import {
  handleFetchError,
  handleWizardDataError,
  verifyWizardData
} from '@/components/pages/PlansPage/helpers/handleErrors'
import isAllOrMix from '@/components/pages/PlansPage/helpers/isAllOrMix'
import PlansRoutes from '@/components/pages/PlansPage/types/routes'
import type { InitialState as WizardState } from '@/components/pages/SignupWizardPage/SignupWizardPage'
// eslint-disable-next-line no-restricted-imports
import type { PendingSubscription, PlanType } from '@/components/types'
// eslint-disable-next-line no-restricted-imports
import { planTypes } from '@/components/types/PlanType'

import STYLES from './Plan.module.sass'

import type {
  RecommendationQuery_currentUser_User_planRecommendation_individual_dog as Dog,
  RecommendationQuery_currentUser_User_planRecommendation_plans as MealPlan,
  RecommendationQuery_currentUser_User_planRecommendation as PlanRecommendation,
  RecommendationQuery
} from '../../__generated__/RecommendationQuery'

import {
  trackBackButtonClicked,
  trackNextButtonClicked,
  trackPlanTypeSelected
} from '../../helpers/analytics'
import {
  CREATE_PENDING_SUBSCRIPTION,
  GET_RECOMMENDATIONS_QUERY
} from '../../queries'
import type { Route as PlanRoute } from '../../types/routes'
import type { FlowType } from '../../types/types'

type Props = {
  variant?: keyof typeof STYLES
  maxFlavours: number
  namespace: string
}

type PlanCardProps = {
  plans: Plans
  dogPronoun: string
  copyContext: string
  selectedPlan: MealPlan
  setSelectedPlan: (plan: MealPlan) => void
  t: TFunction<string>
  recommendedPlan?: MealPlan
  location: PlanRoute
  plansState: PendingSubscription
  wizardState: WizardState
  recommendationState: string
  cadenceState: PlanCadences
  flowTypeState: FlowType
}

type PlanCadences = 'long' | 'default' | 'short'
type Cadences = { [key: string]: MealPlan[] }
type Plans = { [key: string]: MealPlan }

const RecommendedLabel = (
  uid: string,
  copyContext: string,
  t: TFunction<string>
): JSX.Element => (
  // eslint-disable-next-line jsx-a11y/label-has-for
  <Label
    key={`${uid}-recommended-label-key`}
    variant="recommended"
    text={{
      text: t(`${copyContext}.plan_composition.recommended`),
      translate: false
    }}
  />
)

const PlanCards = ({
  plans,
  dogPronoun,
  copyContext,
  selectedPlan,
  recommendedPlan,
  setSelectedPlan,
  t,
  location,
  plansState,
  wizardState,
  recommendationState,
  cadenceState,
  flowTypeState
}: PlanCardProps): ReactElement => {
  const tooltips: {
    [key: string]:
      | {
          tooltipText: string
          tooltipContent: ReactElement
          tooltipIdentifier: string
        }
      | {
          tooltipText: undefined
          tooltipContent: undefined
          tooltipIdentifier: undefined
        }
  } = {
    all: {
      tooltipText: undefined,
      tooltipContent: undefined,
      tooltipIdentifier: undefined
    },
    mix: {
      tooltipText: t(`${copyContext}.plan_composition.tooltip_title`),
      tooltipContent: (
        <div className={STYLES.mixedPlanTooltipContent}>
          <Text
            text={t(
              `${copyContext}.plan_composition.tooltip_dogs_html_${dogPronoun}`
            )}
            variant="textRegular14"
            colour="brandBlue500"
            translate={false}
          />
        </div>
      ),
      tooltipIdentifier: 'plan_composition_tooltip'
    }
  }

  const cards = []
  let allOrMix, planTitle, planDescription, selected, single

  for (const [planType, plan] of Object.entries(plans)) {
    allOrMix = isAllOrMix(planType as PlanType)

    if (isUndefined(allOrMix)) {
      Sentry.captureException(
        `planType was undefined so a plan card was not rendered`,
        {
          extra: {
            planId: plan.id
          },
          tags: {
            product: Sentry.Product.PlansFlow
          }
        }
      )
      continue
    }

    planTitle = copyContext + '.plan_composition.title_' + planType
    planDescription =
      copyContext +
      '.plan_composition.title_' +
      allOrMix +
      '_plan_' +
      dogPronoun
    selected = selectedPlan.id === plan.id
    single = Object.entries(plans).length === 1

    const onSelectionChange = (selected: boolean, planId: string) => {
      if (selected && planId !== selectedPlan.id) {
        setSelectedPlan(plan)
        trackPlanTypeSelected(
          location,
          plansState,
          wizardState,
          recommendationState,
          cadenceState,
          planType as PlanType,
          flowTypeState
        )
      }
    }

    const recommendedLabel: ReactElement[] | undefined =
      !isUndefined(recommendedPlan) && recommendedPlan.id === plan.id
        ? [RecommendedLabel(plan.id, copyContext, t)]
        : undefined

    cards.push(
      <SelectableCard
        key={`plan-card-${plan.id}`}
        layoutVariant={single ? 'horizontal' : 'vertical'}
        title={t(planTitle)}
        subtitle={t(`${copyContext}.plan_composition.daily_grams_html`, {
          gramsPerDay: `${plan.gramsPerDay}g`
        })}
        cloudinaryPath={`Web/photos/meals/plan-size-as-portions/${planType}-butternut-with-pink-background.jpg`}
        description={t(planDescription)}
        labels={recommendedLabel}
        defaultSelected={selected}
        deselectable={false}
        // eslint-disable-next-line react/jsx-no-bind
        onSelectionChange={(selected: boolean) =>
          onSelectionChange(selected, plan.id)
        }
        {...tooltips[allOrMix]}
        ctaVariant={single ? 'none' : 'checkmark'}
      />
    )
  }

  return <>{cards.map((card) => card)}</>
}

const getNumberOfWeeksWorthOfFood = (durationInDays: number): number =>
  durationInDays / 7

// recommend 'all' plan if available
const getRecommendedPlan = (plans: Plans): MealPlan | undefined => {
  if (!isUndefined(plans.all)) return plans.all
  return Object.values(plans)[0]
}

// recommend middle cadence
const getRecommendedCadence = (availableCadences: MealPlan[]): MealPlan => {
  return availableCadences[Math.floor((availableCadences.length - 1) / 2)]
}

const filterDuplicateCadences = (cadences: MealPlan[]): MealPlan[] => {
  return cadences.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (t) =>
          t.typeOfPlanForCustomer === value.typeOfPlanForCustomer &&
          t.durationInDays === value.durationInDays
      )
  )
}

const getCadencesFromData = (data: PlanRecommendation): Cadences => {
  const cadences: Cadences = {}

  if (isUndefined(data?.plans)) {
    Sentry.captureException('data.plans in getCadencesFromData is undefined', {
      tags: {
        product: Sentry.Product.PlansFlow
      }
    })
    window.location.href = '/wizard/pet-parent'
  }
  const plans = data?.plans || []
  const filteredCadences = filterDuplicateCadences(plans)

  planTypes.forEach((planType: PlanType) => {
    cadences[planType] = filteredCadences.filter(
      (plan) => plan.typeOfPlanForCustomer === planType
    )
  })
  return cadences
}

const getPlanType = (plans: Cadences, planId: string): PlanType => {
  for (const planType of planTypes) {
    if (plans[planType].find((plan: MealPlan) => plan.id === planId))
      return planType
  }
  Sentry.captureException(`Plan type not found for plan id`, {
    extra: {
      planID: planId
    },
    tags: {
      product: Sentry.Product.PlansFlow
    }
  })
  return 'all'
}

const getPlans = (cadences: Cadences): Plans => {
  const selectablePlans: Plans = {}
  planTypes.forEach((planType: PlanType) => {
    if (!isUndefined(cadences[planType]) && cadences[planType].length > 0)
      selectablePlans[planType] = cadences[planType][0]
  })

  // only allow a maximum of 2 plan types to be returned
  return Object.fromEntries(
    Object.entries(selectablePlans).slice(0, 2)
  ) as Plans
}

const getPlanTitleKey = (
  plans: Plans,
  dogPronoun: string,
  copyContext: string
): string => {
  const highlightedPlanType: string | undefined = isAllOrMix(
    Object.keys(plans)[0] as PlanType
  )

  if (isUndefined(highlightedPlanType)) {
    Sentry.captureException(
      `planType was undefined so a plan title was not rendered`,
      {
        tags: {
          product: Sentry.Product.PlansFlow
        }
      }
    )
    return ''
  }

  return (
    copyContext +
    '.all_mix.' +
    highlightedPlanType +
    '_plan_title' +
    (highlightedPlanType === 'mix' ? '_' + dogPronoun : '')
  )
}

// order cadences by length (high to low)
const orderCadences = (cadences: MealPlan[]): MealPlan[] => {
  const cloneCadences: MealPlan[] = [...cadences]
  return cloneCadences.sort(
    (a: MealPlan, b: MealPlan) => b.durationInDays - a.durationInDays
  )
}

const Plan = ({
  variant,
  maxFlavours,
  namespace
}: Props): JSX.Element | null => {
  const navigate = useNavigate()
  const { t } = useTranslation(namespace)
  const location = useLocation().pathname as PlanRoute

  const plansState: PendingSubscription = useReactiveVar(plansPageState)
  const wizardState = useReactiveVar(plansPageWizardState)
  const cadenceState = useReactiveVar(deliveryCadenceState)
  const flowTypeState = useReactiveVar(plansFlowTypeState)
  const recommendationState = useReactiveVar(planRecommendationState)
  const recommendedExtraProductVariants = useReactiveVar(
    recommendedExtraProductVariantsState
  )

  if (!verifyWizardData(wizardState)) handleWizardDataError(wizardState)

  const { plan: selectedCadence } = plansState

  const [shouldStickyPriceBeVisible, setShouldStickyPriceBeVisible] =
    useState(false)
  const [cadences, setCadences] = useState<Cadences | undefined>()
  const [plans, setPlans] = useState<Plans | undefined>()
  const [recommendedPlan, setRecommendedPlan] = useState<MealPlan | undefined>()
  const [availableCadences, setAvailableCadences] = useState<
    MealPlan[] | undefined
  >()
  const [recommendedCadence, setRecommendedCadence] = useState<
    MealPlan | undefined
  >()
  const [selectedPlanType, setSelectedPlanType] = useState<
    PlanType | undefined
  >()
  const [selectedPlan, setSelectedPlan] = useState<MealPlan | undefined>()

  const { loading, data, error } = useQuery<RecommendationQuery>(
    GET_RECOMMENDATIONS_QUERY
  )

  const setSelectedCadence = useCallback(
    (plan: MealPlan) => {
      const updatedPendingSubscription: PendingSubscription = {
        ...plansState,
        plan: plan
      }

      if (!isEqual(updatedPendingSubscription, plansState))
        plansPageState(updatedPendingSubscription)
    },
    [plansState]
  )

  const backButtonClick = useCallback(() => {
    plansSkipLoadingScreenState(true)
    trackBackButtonClicked(
      location,
      plansState,
      wizardState,
      recommendationState,
      cadenceState,
      flowTypeState
    )
  }, [
    cadenceState,
    flowTypeState,
    recommendationState,
    wizardState,
    plansState,
    location
  ])

  const nextButtonClick = useCallback(() => {
    trackNextButtonClicked(
      location,
      plansState,
      wizardState,
      recommendationState,
      cadenceState,
      flowTypeState
    )

    navigate(PlansRoutes.Extras)
  }, [
    location,
    plansState,
    wizardState,
    recommendationState,
    cadenceState,
    flowTypeState,
    navigate
  ])

  const [saveSubscription, { loading: saveSubscriptionLoading }] = useMutation(
    CREATE_PENDING_SUBSCRIPTION,
    {
      variables: {
        pendingSubscription: {
          planId: !isNull(plansState.plan) ? plansState.plan.id : '',
          productVariantIds: !isNull(plansState.products)
            ? plansState.products.map(({ id }) => id)
            : undefined,
          flavourIds: !isNull(plansState.flavours)
            ? plansState.flavours.map((flavour) => flavour?.id || '')
            : undefined
        }
      },
      onCompleted: () => {
        trackNextButtonClicked(
          location,
          plansState,
          wizardState,
          recommendationState,
          cadenceState,
          flowTypeState
        )
        navigate(PlansRoutes.Review)
      }
    }
  )

  // store cadence data after fetch
  useEffect(() => {
    if (!isUndefined(cadences) && isUndefined(plans)) {
      setPlans(getPlans(cadences))
    }
  }, [cadences, plans])

  // initially set the recommended plan
  useEffect(() => {
    if (!isUndefined(plans) && isUndefined(recommendedPlan)) {
      setRecommendedPlan(getRecommendedPlan(plans))
    }
  }, [plans, recommendedPlan])

  // set initially selected plan from localStorage if available, otherwise to recommended if available, else set to first
  useEffect(() => {
    if (
      !isUndefined(plans) &&
      !isUndefined(cadences) &&
      Object.keys(plans).length > 0 &&
      isUndefined(selectedPlan)
    ) {
      if (!isNil(selectedCadence)) {
        // from local storage
        setSelectedPlan(plans[getPlanType(cadences, selectedCadence.id)])
      } else if (!isUndefined(recommendedPlan)) {
        // recommended
        setSelectedPlan(recommendedPlan)
      } else {
        // first
        setSelectedPlan(plans[Object.keys(plans)[0]])
      }
    }
  }, [
    plans,
    selectedPlan,
    cadences,
    recommendedPlan,
    selectedCadence,
    setSelectedCadence
  ])

  // set the type of plan selected (all/most/half/some)
  useEffect(() => {
    if (!isUndefined(cadences) && !isUndefined(selectedPlan)) {
      setSelectedPlanType(getPlanType(cadences, selectedPlan.id))
    }
  }, [selectedPlan, cadences])

  // update set of cadences to be displayed if plan type changes
  useEffect(() => {
    if (!isUndefined(cadences) && !isUndefined(selectedPlanType)) {
      setAvailableCadences(orderCadences(cadences[selectedPlanType]))
    }
  }, [selectedPlanType, cadences])

  // if cadences change then set the selected cadence to the recommended one
  useEffect(() => {
    if (!isUndefined(availableCadences))
      setRecommendedCadence(getRecommendedCadence(availableCadences))
  }, [availableCadences])

  // set selected cadence when selected plan is updated
  useEffect(() => {
    if (
      !isNull(selectedCadence) &&
      !isUndefined(availableCadences) &&
      availableCadences.find(
        (cadence) =>
          !isNull(selectedCadence) && cadence.id === selectedCadence.id
      )
    ) {
      // from local storage
      setSelectedCadence(selectedCadence)
    } else if (!isUndefined(recommendedCadence)) {
      // recommended
      setSelectedCadence(recommendedCadence)
    }
  }, [
    selectedCadence,
    recommendedCadence,
    availableCadences,
    setSelectedCadence
  ])

  if (
    !data ||
    !data.currentUser ||
    data.currentUser.__typename === 'PreWizardGuest' ||
    !data.currentUser.planRecommendation ||
    !data.currentUser.planRecommendation.individual ||
    !(data.currentUser.planRecommendation.individual.length > 0) ||
    loading
  )
    return null

  if (error) handleFetchError(error)

  const { individual } = data.currentUser.planRecommendation
  const dogs: Dog[] = individual.map(({ dog }) => dog)

  // store data after fetch
  if (isUndefined(cadences))
    setCadences(getCadencesFromData(data.currentUser.planRecommendation))

  const { name } = dogs[0]
  const dogGenders: Dog['gender'][] = dogs.map((dog: Dog) => dog.gender)

  const dogPronoun = pronounContext(dogGenders as Gender[], i18next.language)

  const copyContext = 'plan_steps.plan'

  const { currentUser } = data

  const { countryCode, preferredLanguage } =
    currentUserToLocaleData(currentUser)
  const { locale, currency } = countryCodeToLocaleCurrency(
    countryCode,
    preferredLanguage
  )
  const firstBoxDiscountPart =
    (currentUser.__typename === 'Guest' &&
      currentUser.discountCode &&
      currentUser.discountCode.discountCodeParts.find(
        (part: DiscountCodePart): boolean =>
          DiscountCodes.partAppliesToBoxNumber(part, 1)
      )) ??
    null

  if (isNull(selectedCadence)) return null

  return (
    <div className={`${STYLES.container} ${variant ? STYLES[variant] : ''}`}>
      {flowTypeState === 'new' ? (
        <HeroText
          variant="plans"
          namespace={namespace}
          title={`plan_steps.stage`}
          subtitle={`${copyContext}.header.title`}
          subtitleVariables={{
            count: individual.length,
            dogPossessiveName: possessive(name, i18next.language)
          }}
          variables={{
            stepCount: 2,
            totalSteps: recommendedExtraProductVariants.length > 0 ? 4 : 3
          }}
        />
      ) : (
        <React.Fragment>
          <TopNavigation backUrl={PlansRoutes.Recipes} />
          <HeroText
            variant="plans"
            namespace={namespace}
            title={`plan_steps.stage`}
            variables={{ stepCount: 2, totalSteps: 3 }}
            subtitle={`${copyContext}.header.title_add`}
          />
        </React.Fragment>
      )}
      {/* All/Mix Section */}
      <div className={STYLES.choosePlanType}>
        <div className={STYLES.titleWrapper}>
          <div className={STYLES.title}>
            {!isUndefined(plans) && Object.keys(plans).length > 0 && (
              <Text
                element="h3"
                text={getPlanTitleKey(plans, dogPronoun, copyContext)}
                namespace={namespace}
                variant="display24"
                colour="brandBlue500"
                margin={false}
                align="center"
                shouldScale
              />
            )}
          </div>
        </div>
        <div
          className={`${STYLES.planWrapper} ${
            !isUndefined(plans) && Object.keys(plans).length === 1
              ? STYLES.planTypeRowSingle
              : ''
          }`}
        >
          {!isUndefined(plans) && !isUndefined(selectedPlan) && (
            <PlanCards
              plans={plans}
              dogPronoun={dogPronoun}
              copyContext={copyContext}
              selectedPlan={selectedPlan}
              recommendedPlan={recommendedPlan}
              setSelectedPlan={setSelectedPlan}
              t={t}
              location={location}
              plansState={plansState}
              wizardState={wizardState}
              recommendationState={recommendationState}
              cadenceState={cadenceState}
              flowTypeState={flowTypeState}
            />
          )}
        </div>
        {flowTypeState === 'add' && (
          <div className={STYLES.alertWrapper}>
            <AlertCard
              message={{
                text: `${copyContext}.alert`,
                namespace: namespace,
                margin: false,
                align: 'left'
              }}
              variant="info"
            />
          </div>
        )}
      </div>

      {/* Cadence Selection */}
      <CadenceSection
        availableCadences={availableCadences}
        copyContext={copyContext}
        currency={currency}
        dogPronoun={dogPronoun}
        firstBoxDiscountPart={firstBoxDiscountPart}
        flowTypeState={flowTypeState}
        locale={locale}
        location={location}
        namespace={namespace}
        plansState={plansState}
        recommendationState={recommendationState}
        recommendedCadence={recommendedCadence}
        selectedCadence={selectedCadence}
        setSelectedCadence={setSelectedCadence}
        t={t}
        wizardState={wizardState}
        setShouldStickyPriceBeVisible={setShouldStickyPriceBeVisible}
      />
      <div className={STYLES.faq}>
        <FAQSection
          namespace={namespace}
          maxRecipes={maxFlavours}
          numberOfDogs={individual.length}
          shippingCountryCode={countryCode}
        />
      </div>
      {/* Price Banner */}
      <div
        className={`${STYLES.priceBanner} ${
          shouldStickyPriceBeVisible ? STYLES.priceBannerShow : ''
        }`}
      >
        <div className={`${STYLES.priceBannerContainer} ${STYLES.wrapper}`}>
          <div className={STYLES.priceBannerRow}>
            <Text
              text={
                currentUser.__typename === 'Guest'
                  ? `${copyContext}.pricing_banner.first_box`
                  : `${copyContext}.pricing_banner.plan_price`
              }
              namespace={namespace}
              variant="display16"
              colour="brandBlue500"
              margin={false}
              shouldScale
            />
            <Text
              text={
                currentUser.__typename === 'Guest' &&
                !isNull(currentUser.discountCode)
                  ? `${copyContext}.pricing_banner.price_per_day_discounted`
                  : `${copyContext}.pricing_banner.price_per_day`
              }
              variables={{
                price: formatCurrencyWithDecimal(selectedCadence.pricePerDay, {
                  locale,
                  currency
                }),
                discountedPricePerDay: !isUndefined(
                  selectedCadence.trialDiscountedPricePerDay
                )
                  ? formatCurrencyWithDecimal(
                      selectedCadence.trialDiscountedPricePerDay,
                      { locale, currency }
                    )
                  : undefined
              }}
              namespace={namespace}
              variant="textRegular16"
              colour="brandBlue500"
              margin={false}
              shouldScale
            />
          </div>
          {plansState.flavours &&
            plansState.flavours.filter(
              (flavour) =>
                flavour?.recipeSurcharges &&
                flavour?.recipeSurcharges.length > 0
            ).length > 0 && (
              <div className={STYLES.surchargeDisclaimer}>
                <Text
                  text={`${copyContext}.pricing_banner.surcharge_disclaimer`}
                  namespace={namespace}
                  variant="textRegular16"
                  colour="brandBlue500"
                  margin={false}
                  shouldScale
                />
              </div>
            )}
          {!isUndefined(selectedCadence.trialDiscountedPricePerDay) &&
            selectedCadence.trialDiscountedPricePerDay <
              selectedCadence.pricePerDay && (
              <div className={STYLES.priceBannerRow}>
                <Text
                  text={`${copyContext}.pricing_banner.ongoing_price`}
                  namespace={namespace}
                  variant="textRegular16"
                  colour="brandBlue500"
                  margin={false}
                  shouldScale
                />
                <Text
                  text={`${copyContext}.pricing_banner.price_per_day`}
                  namespace={namespace}
                  variables={{
                    price: !isNull(selectedCadence.pricePerDay)
                      ? formatCurrencyWithDecimal(selectedCadence.pricePerDay, {
                          locale,
                          currency
                        })
                      : undefined
                  }}
                  variant="textRegular16"
                  colour="brandBlue500"
                  margin={false}
                  shouldScale
                />
              </div>
            )}
        </div>
      </div>
      {flowTypeState === 'new' ? (
        <StickyNavigation
          variant="twoButtons"
          disabled={loading || saveSubscriptionLoading}
          buttonOne={{
            url: PlansRoutes.Recipes,
            variant: 'secondary',
            text: 'plan_steps.navigation.back',
            iconColour: 'brandRed400',
            namespace,
            onClick: backButtonClick
          }}
          buttonTwo={{
            dataTestId: 'next-button',
            variant: 'primary',
            text: 'plan_steps.navigation.continue',
            iconColour: 'brandWhite',
            namespace,
            onClick:
              recommendedExtraProductVariants.length > 0
                ? nextButtonClick
                : () => saveSubscription()
          }}
        />
      ) : (
        <StickyNavigation
          variant="oneButton"
          disabled={loading || saveSubscriptionLoading}
          buttonOne={{
            variant: 'primary',
            text: 'plan_steps.navigation.continue',
            iconColour: 'brandWhite',
            namespace,
            onClick: () => saveSubscription()
          }}
        />
      )}
    </div>
  )
}

export {
  Props,
  Cadences,
  PlanCadences,
  RecommendedLabel,
  getPlans,
  getCadencesFromData,
  getPlanType,
  isAllOrMix,
  orderCadences,
  filterDuplicateCadences,
  getNumberOfWeeksWorthOfFood
}
export default Plan
