import { gql } from '@apollo/client'

const CUSTOMER_ISSUE_RESOLUTION_OFFERING_ACCEPT = gql`
  mutation CustomerIssueResolutionOfferingAccept(
    $acceptanceDetails: CustomerIssueManagementResolutionOfferingAcceptanceDetailsInput!
  ) {
    response: customerIssueResolutionOfferingAccept(
      acceptanceDetails: $acceptanceDetails
    ) {
      applicationDetails {
        ... on CustomerIssueManagementResolutionApplicationDetailsReplaceBox {
          resolutionRemnant {
            id
            isoDeliveryDate
          }
        }
        ... on CustomerIssueManagementResolutionApplicationDetailsApplyDiscount {
          resolutionRemnant {
            applicableDiscountParts {
              value
            }
          }
        }
        type
      }
      user {
        id
        shippingCountryCode
        preferredLanguage
      }
    }
  }
`

export { CUSTOMER_ISSUE_RESOLUTION_OFFERING_ACCEPT }
