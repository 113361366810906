// @noflow
import React from 'react'

import HomeIcon from 'assets/images/favicons/favicon.svg'
import StoryIcon from 'assets/images/icons/navigation/icon-book.svg'
import LoginIcon from 'assets/images/icons/navigation/icon-log-in.svg'
import LogoutIcon from 'assets/images/icons/navigation/icon-log-out.svg'
import MealsIcon from 'assets/images/icons/navigation/icon-making-our-meals.svg'
import ProductsIcon from 'assets/images/icons/navigation/icon-pouch.svg'
import HelpIcon from 'assets/images/icons/navigation/icon-question-mark.svg'
import ReviewsIcon from 'assets/images/icons/speech-bubble-square.svg'

import BCorpLogo from '@/components/elements/atoms/BCorpLogo/BCorpLogo'
import NavLink from '@/components/elements/atoms/NavLink/NavLink'
import Separator from '@/components/elements/atoms/Separator/Separator'

import STYLES from './MobileNavMenu.module.sass'

// Enums
import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'

type Props = {
  variant?: keyof typeof STYLES
  backgroundClicked?: () => void
  open: boolean
  userLoggedIn: boolean
  shippingCountryCode: CountryCode
}

const MobileNavMenu = ({
  variant,
  backgroundClicked,
  open,
  userLoggedIn,
  shippingCountryCode
}: Props): JSX.Element => {
  return (
    <React.Fragment>
      <div
        className={`${STYLES.background} ${open ? STYLES.open : ''}`}
        onClick={backgroundClicked}
        onKeyDown={backgroundClicked}
        tabIndex={0}
        role="button"
      />
      <div
        className={`${STYLES.container} ${variant ? STYLES[variant] : ''} ${
          open ? STYLES.open : ''
        }`}
      >
        <div className={STYLES.section}>
          <div className={STYLES.row}>
            {userLoggedIn ? (
              <NavLink
                variant="mobile"
                text={{
                  text: 'log_out',
                  namespace: 'navigation'
                }}
                href="/users/sign_out"
                iconSrc={LogoutIcon}
              />
            ) : (
              <NavLink
                variant="mobile"
                text={{
                  text: 'log_in',
                  namespace: 'navigation'
                }}
                href="/users/sign_in"
                iconSrc={LoginIcon}
              />
            )}
          </div>
        </div>
        <Separator bold={false} />
        <div className={STYLES.section}>
          <div className={STYLES.row}>
            <NavLink
              variant="mobile"
              text={{
                text: 'home',
                namespace: 'navigation'
              }}
              href="/"
              iconSrc={HomeIcon}
            />
          </div>
          <div className={STYLES.row}>
            <NavLink
              variant="mobile"
              text={{
                text: 'our_products',
                namespace: 'navigation'
              }}
              href="/our_products"
              iconSrc={ProductsIcon}
            />
          </div>
          <div className={STYLES.row}>
            <NavLink
              variant="mobile"
              text={{
                text: 'making_our_meals',
                namespace: 'navigation'
              }}
              href="/making_our_meals"
              iconSrc={MealsIcon}
            />
          </div>
          <div className={STYLES.row}>
            <NavLink
              variant="mobile"
              text={{
                text: 'our_story',
                namespace: 'navigation'
              }}
              href="/our_story"
              iconSrc={StoryIcon}
            />
          </div>
        </div>
        <Separator bold={false} />
        <div className={STYLES.section}>
          <div className={STYLES.row}>
            <NavLink
              variant="mobile"
              text={{
                text: 'reviews',
                namespace: 'navigation'
              }}
              href="/reviews"
              iconSrc={ReviewsIcon}
            />
          </div>
          <div className={STYLES.row}>
            <NavLink
              variant="mobile"
              text={{
                text: 'help',
                namespace: 'navigation'
              }}
              href="/contact"
              iconSrc={HelpIcon}
            />
          </div>
        </div>
        <div className="mobile-sidebar-localisation-widget-wrapper" />
        {shippingCountryCode !== 'PL' && (
          <div className={STYLES.bcorp}>
            <div className={STYLES.bcorpLogo}>
              <BCorpLogo variant="cardboard" />
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export { Props }
export default MobileNavMenu
