// @noflow
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import { useCallback, useState } from 'react'

type UnsavedData = Record<string, string>

type UnsavedDataCheck = {
  canCloseModal: (valueToCheckAgainst: unknown, closeFn: () => void) => void
  closeModal: (() => void) | null
  closeWithUnsavedChanges: () => void
  resetUnsavedChanges: () => void
  valueChanged: (key: string, value: string) => void
}

const useUnsavedDataCheck = (openAreYouSure: () => void): UnsavedDataCheck => {
  const [closeModal, setCloseModal] = useState<null | (() => void)>(null)
  const [unsavedChanges, setUnsavedChanges] = useState<UnsavedData>({})

  const resetUnsavedChanges = useCallback(() => {
    setUnsavedChanges({})
  }, [])

  const closeWithUnsavedChanges = useCallback(() => {
    if (!isNull(closeModal)) {
      resetUnsavedChanges()
      closeModal()
      setCloseModal(null)
    }
  }, [closeModal, resetUnsavedChanges])

  const checkForUnsavedData = useCallback(
    (valueToCheckAgainst: UnsavedData) => {
      const hasUnsaved = Object.keys(unsavedChanges).find((key) => {
        return unsavedChanges[key] !== valueToCheckAgainst[key]
      })
      return isUndefined(hasUnsaved)
    },
    [unsavedChanges]
  )

  const canCloseModal = useCallback(
    (valueToCheckAgainst: unknown, closeFn: () => void): void => {
      if (checkForUnsavedData(valueToCheckAgainst as UnsavedData)) {
        closeFn()
      } else {
        setCloseModal(() => closeFn)
        openAreYouSure()
      }
    },
    [checkForUnsavedData, openAreYouSure]
  )

  const valueChanged = useCallback((key: string, val: string) => {
    setUnsavedChanges((prev) => {
      const newUnsavedChanges = { ...prev } as UnsavedData
      newUnsavedChanges[key] = val
      return newUnsavedChanges
    })
  }, [])

  return {
    canCloseModal,
    closeModal,
    closeWithUnsavedChanges,
    resetUnsavedChanges,
    valueChanged
  }
}

export default useUnsavedDataCheck
