// @noflow
import { combineReducers } from 'redux'
import type { ThunkDispatch } from 'redux-thunk'

import funnelReducer from './slices/funnelSlice'
import navigationReducer from './slices/navigationSlice'

const reducer = combineReducers({
  funnel: funnelReducer,
  navigation: navigationReducer
})

type State = ReturnType<typeof reducer>

type Dispatch = ThunkDispatch<State, unknown, Parameters<typeof reducer>[1]>

export default reducer
export type { State, Dispatch }
