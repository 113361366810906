import { useReactiveVar } from '@apollo/client'
import classNames from 'classnames'
import isArray from 'lodash/isArray'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { Autoplay, FreeMode, Navigation, Pagination, Thumbs } from 'swiper'
import {
  Swiper,
  SwiperClass,
  SwiperProps,
  SwiperRef,
  SwiperSlide,
  useSwiperSlide
} from 'swiper/react'
import 'swiper/swiper-bundle.min.css'
import { SwiperEvents, SwiperModule } from 'swiper/types'

import BREAKPOINTS from '@/constants/Breakpoints'

import { SliderHookVar } from '@/hooks/useSlider/useSlider'

import {
  CardSkeleton,
  Props as CardSkeletonProps
} from '@/components/elements/atoms/Card/CardSkeleton'
import IconButton from '@/components/elements/molecules/IconButton/IconButton'

import STYLES from './SwiperSlider.module.sass'

import { Expand } from '../../atoms/Animated/Animated'

type ArrowColours = 'brandBlue100' | 'brandYellow300'

type Props = Pick<
  SwiperProps,
  'onActiveIndexChange' | 'watchOverflow' | 'spaceBetween' | 'allowTouchMove'
> & {
  variant?: ArrowColours
  children: JSX.Element | null | Array<JSX.Element | null>
  slidesPerView?: number | 'auto'
  bullets?: boolean
  arrows?: boolean
  thumbs?: boolean
  autoPlay?: boolean
  arrowsInside?: boolean
  arrowPosition?: number // allows vertical adjustment of the prev/next buttons
  initialSlide?: number
  className?: string
  skeleton?: Pick<CardSkeletonProps, 'width' | 'height'>
  slider?: SwiperProps['onSwiper']
  autoHeight?: boolean // Set to `true` and slider wrapper will adapt its height to the height of the currently active slide
  paddingOverride?: 0 | 8 | 16 | 24 | 32
  id?: string
  loop?: boolean
  centeredSlides?: boolean
  onArrowClick?: () => void
  onLazyLoadNext?: () => void
  onLazyLoadPrev?: () => void
  onSlideChange?: SwiperEvents['slideChange']
  onSliderFirstMove?: SwiperEvents['sliderFirstMove']
  onThumbnailClick?: SwiperEvents['click']
}

type SliderSkeletonProps = Pick<CardSkeletonProps, 'width' | 'height'> & {
  show: boolean
  loading: {
    loadMore: () => void
    setLoading: (isLoading: boolean) => void
  }
}

const SliderSkeleton = ({
  width,
  height,
  show,
  loading
}: SliderSkeletonProps): JSX.Element => {
  const { isActive } = useSwiperSlide()

  useEffect(() => {
    if (show || !isActive) return
    loading.loadMore()
    loading.setLoading(true)
  }, [isActive, loading, show])
  return (
    <Expand origin="center" show={show || isActive}>
      <CardSkeleton width={width} height={height} />
    </Expand>
  )
}

const SwiperSlider = ({
  variant = 'brandYellow300',
  children,
  slidesPerView = 'auto',
  bullets = false,
  arrows,
  thumbs = false,
  autoPlay = false,
  arrowsInside = false,
  arrowPosition,
  spaceBetween = 16,
  initialSlide = 0,
  slider,
  skeleton,
  watchOverflow,
  className,
  autoHeight = false,
  paddingOverride = 0,
  id,
  allowTouchMove = true,
  loop = false,
  centeredSlides = false,
  onArrowClick,
  onLazyLoadNext,
  onLazyLoadPrev,
  onSlideChange,
  onSliderFirstMove,
  onThumbnailClick
}: Props): JSX.Element | null => {
  const sliderRef = useRef<SwiperRef | null>(null)
  const prevButton = useRef<HTMLDivElement>(null)
  const nextButton = useRef<HTMLDivElement>(null)
  const sliderIndexUpdate = useReactiveVar(SliderHookVar)
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass | null>(null)
  const [slides, setSlides] = useState<number>(0)
  const [initialised, setInitialised] = useState<boolean>(false)
  const [isLoadingPrev, setIsLoadingPrev] = useState<boolean>(false)
  const [isLoadingNext, setIsLoadingNext] = useState<boolean>(false)
  const childNodes = isArray(children)
    ? children
    : !isNull(children)
    ? [children]
    : []

  const isMobile = window.innerWidth < BREAKPOINTS.md
  const hasArrows = useMemo(
    () => arrows || (isUndefined(arrows) && !isMobile),
    [arrows, isMobile]
  )

  useEffect(() => {
    if (isNull(sliderIndexUpdate) || sliderIndexUpdate.id !== id) return
    switch (sliderIndexUpdate.slide) {
      case 'first':
        sliderRef.current?.swiper.slideTo(0)
        break
      case 'last':
        sliderRef.current?.swiper.slideTo(slides - 1)
        break
      case 'next':
        sliderRef.current?.swiper.slideNext()
        break
      case 'prev':
        sliderRef.current?.swiper.slidePrev()
        break
      default:
        sliderRef.current?.swiper.slideTo(sliderIndexUpdate.slide)
        break
    }
    SliderHookVar(null)
  }, [sliderIndexUpdate, id, slides])

  useEffect(() => {
    const swiper = sliderRef.current?.swiper
    if (!swiper) return
    const newSlidesAdded = swiper.slides.length - slides
    if (newSlidesAdded > 0) {
      if (isLoadingPrev && !isUndefined(onLazyLoadPrev)) {
        swiper.slideTo(1)
      }
      setIsLoadingNext(false)
      setIsLoadingPrev(false)
      setSlides(swiper.slides.length)
    }
  }, [
    initialSlide,
    initialised,
    isLoadingNext,
    isLoadingPrev,
    onLazyLoadPrev,
    slides
  ])

  useEffect(() => {
    const swiper = sliderRef.current?.swiper
    if (
      swiper &&
      !initialised &&
      swiper.slides.length > 0 &&
      swiper.slides.length >= slides
    ) {
      swiper.slideTo(initialSlide)
      setInitialised(true)
    }
  }, [initialSlide, initialised, slides])

  const generateModules = (
    arrows: boolean,
    bullets: boolean,
    thumbs: boolean,
    autoPlay: boolean
  ): SwiperModule[] => {
    const modules = []
    if (arrows) modules.push(Navigation)
    if (bullets) modules.push(Pagination)
    if (thumbs) modules.push(Thumbs)
    if (autoPlay) modules.push(Autoplay)
    return modules
  }

  const propModules = generateModules(hasArrows, bullets, thumbs, autoPlay)

  /**
   * Fires on an interaction which should trigger a lazy load of slides
   */
  const lazyLoadEvent = useCallback(
    (swiper: SwiperClass) => {
      if (
        !isUndefined(onLazyLoadPrev) &&
        swiper.isBeginning &&
        swiper.swipeDirection !== 'next'
      ) {
        setIsLoadingPrev(true)
        onLazyLoadPrev()
      }
      if (
        !isUndefined(onLazyLoadNext) &&
        swiper.isEnd &&
        swiper.swipeDirection !== 'prev'
      ) {
        setIsLoadingNext(true)
        onLazyLoadNext()
      }
    },
    [onLazyLoadPrev, onLazyLoadNext]
  )

  // Chick handler for the arrow buttons.
  // The navigation object handles the click events, so we don't need an onClick.
  // Adding this one here to take advantage of the IconButton atom,
  // which has an onClick as a required prop.
  const onClick = useCallback(
    (e) => {
      e.preventDefault()

      if (!isNull(sliderRef.current)) lazyLoadEvent(sliderRef.current.swiper)
      onArrowClick?.()
    },
    [lazyLoadEvent, onArrowClick]
  )

  const onSwiper = (swiper: SwiperClass): void => {
    if (slider) slider(swiper)

    // Delay execution for the refs to be defined
    const navigation = swiper.params.navigation
    if (
      typeof navigation === 'boolean' ||
      !hasArrows ||
      isUndefined(navigation)
    )
      return
    setTimeout(() => {
      // Override prevEl & nextEl now that refs are defined
      navigation.prevEl = prevButton.current
      navigation.nextEl = nextButton.current

      if (isUndefined(swiper?.navigation)) return

      // Re-init navigation
      swiper.navigation.destroy()
      swiper.navigation.init()
      swiper.navigation.update()
    })
  }

  const classes = classNames(STYLES.swiperSlider, {
    [STYLES.withThumbs]: thumbs,
    [STYLES.withBullets]: bullets,
    [STYLES.arrowsInside]: hasArrows && arrowsInside,
    [STYLES.withLazyLoadPrev]: onLazyLoadPrev,
    [STYLES.withLazyLoadNext]: onLazyLoadNext,
    [STYLES[`withPaddingOverride${paddingOverride}`]]: paddingOverride !== 0
  })

  const arrowStyles: CSSProperties | undefined = !isUndefined(arrowPosition)
    ? {
        // eslint-disable-next-line i18next/no-literal-string
        transform: `translate(0, ${arrowPosition}rem)`
      }
    : undefined

  return (
    <div className={classes} id={id}>
      <div className={STYLES.sliderWrapper}>
        <Swiper
          ref={sliderRef}
          spaceBetween={spaceBetween}
          slidesOffsetBefore={paddingOverride}
          slidesOffsetAfter={paddingOverride}
          slidesPerView={slidesPerView}
          modules={propModules}
          navigation={
            hasArrows
              ? {
                  nextEl: nextButton.current,
                  prevEl: prevButton.current
                }
              : hasArrows
          }
          pagination={bullets ? { clickable: true } : bullets}
          thumbs={{ swiper: thumbs ? thumbsSwiper : null }}
          autoplay={
            autoPlay ? { delay: 2500, disableOnInteraction: false } : autoPlay
          }
          initialSlide={initialSlide}
          className={className}
          observer
          // eslint-disable-next-line react/jsx-no-bind
          onSwiper={onSwiper}
          onSliderMove={lazyLoadEvent}
          watchOverflow={watchOverflow}
          autoHeight={autoHeight}
          effect="fade"
          centeredSlidesBounds
          onSliderFirstMove={onSliderFirstMove}
          allowTouchMove={allowTouchMove}
          centeredSlides={centeredSlides}
          loop={loop}
          onSlideChange={onSlideChange}
        >
          {skeleton && !isUndefined(onLazyLoadPrev) && initialised && (
            <SwiperSlide key={'loading-prev'}>
              <SliderSkeleton
                width={skeleton.width}
                height={skeleton.height}
                show={isLoadingPrev}
                loading={{
                  loadMore: onLazyLoadPrev,
                  setLoading: setIsLoadingPrev
                }}
              />
            </SwiperSlide>
          )}
          {childNodes.map((child) => {
            if (isNull(child)) return null
            return (
              // eslint-disable-next-line react/no-array-index-key
              <SwiperSlide key={child.key}>
                <div className={STYLES.slideContent}>{child}</div>
              </SwiperSlide>
            )
          })}
          {skeleton && !isUndefined(onLazyLoadNext) && initialised && (
            <SwiperSlide key={'loading-next'}>
              <SliderSkeleton
                width={skeleton.width}
                height={skeleton.height}
                show={isLoadingNext}
                loading={{
                  loadMore: onLazyLoadNext,
                  setLoading: setIsLoadingNext
                }}
              />
            </SwiperSlide>
          )}
        </Swiper>
        {hasArrows && (
          <>
            <div
              className={STYLES.arrowLeft}
              ref={prevButton}
              style={arrowStyles}
            >
              <IconButton
                icon="chevron"
                iconColor="brandBlue500"
                shape="circle"
                size={48}
                variant={variant}
                iconSize={0.4}
                iconDirection="left"
                onClick={onClick}
              />
            </div>
            <div
              className={STYLES.arrowRight}
              ref={nextButton}
              style={arrowStyles}
            >
              <IconButton
                icon="chevron"
                iconColor="brandBlue500"
                shape="circle"
                size={48}
                variant={variant}
                iconSize={0.4}
                onClick={onClick}
              />
            </div>
          </>
        )}
      </div>
      {thumbs && (
        <Swiper
          watchSlidesProgress
          onSwiper={setThumbsSwiper}
          slidesPerView={'auto'}
          spaceBetween={8}
          freeMode
          modules={[FreeMode, Navigation, Thumbs]}
          grabCursor
          onClick={onThumbnailClick}
        >
          {childNodes.map((child) => {
            if (isNull(child)) return null
            return (
              // eslint-disable-next-line react/no-array-index-key
              <SwiperSlide key={child.key}>
                <div className={STYLES.slideThumb}>{child}</div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      )}
    </div>
  )
}

export { Props, SliderHookVar }
export default SwiperSlider
