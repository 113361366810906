// @noflow
import { useQuery } from '@apollo/client'
import classnames from 'classnames'
import { useCombobox } from 'downshift'
import Cookies from 'js-cookie'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import * as Sentry from '@/utils/sentry'

import withApollo from '@/components/apollo/withApollo'

import STYLES from './BreedAutocomplete.module.sass'

import { BREED_SELECTOR_QUERY } from './queries/breedSelectorQuery'

import type {
  BreedSelectorQuery_breeds as Breed,
  BreedSelectorQuery
} from './queries/__generated__/BreedSelectorQuery'

import Icon from '../../atoms/Icon/Icon'
import ExpandableIconButton from '../ExpandableIconButton/ExpandableIconButton'

type Props = {
  loadingPlaceholder: string
  placeholder: string
  initialValue?: Breed
  customRef?: React.RefObject<HTMLInputElement>
  variant?: 'v2' | 'v1'
  embeddedCTA?: boolean
  onClickCTA?: () => void
  events: {
    onBreedSelection: (breed: Breed) => void
    onListOpen: () => void
    onFocus: () => void
    onInputEmpty: () => void
  }
}

const BreedAutomplete = ({
  loadingPlaceholder,
  placeholder,
  events,
  initialValue,
  customRef,
  variant = 'v1',
  onClickCTA,
  embeddedCTA = false
}: Props): JSX.Element | null => {
  const { onListOpen, onBreedSelection, onInputEmpty } = events

  const namespace = 'paid_traffic'
  const [breeds, setBreeds] = useState<Breed[]>([])
  const [toggleIconButton, setToggleIconButton] = useState(false)

  const { t } = useTranslation(namespace)
  const { loading, data, error } =
    useQuery<BreedSelectorQuery>(BREED_SELECTOR_QUERY)

  const shippingCountryCode = Cookies.get('user_country_code') || 'GB'
  const shouldSeeImprovedBreedSelectorLogic = !['NL', 'BE'].includes(
    shippingCountryCode
  )
  const mixedBreed: Breed = {
    __typename: 'Breed',
    id: '260',
    key: 'mixed_breed',
    name: t('breeds.mixed_breed.name', { ns: 'models' }),
    popular: false
  }

  const otherBreed: Breed = {
    ...mixedBreed,
    key: 'other',
    name: t('breeds.other.name', { ns: 'models' })
  }

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getItemProps,
    closeMenu,
    highlightedIndex
  } = useCombobox({
    initialSelectedItem: initialValue,
    items: breeds,
    itemToString: (breed) => (breed ? breed.name : ''),
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        onBreedSelection(selectedItem)
      }
    },
    onInputValueChange: ({ inputValue }) => {
      if (!data) {
        setToggleIconButton(false)
        return
      }
      if (!inputValue) {
        setToggleIconButton(false)
        closeMenu()
        onInputEmpty()
        return
      }
      setToggleIconButton(true)

      // Filter out any breed names from the list which don't match
      // the current input value
      const filteredBreeds = shouldSeeImprovedBreedSelectorLogic
        ? [...data.breeds]
            // Filter the array by matching the breed name with the current input value
            .filter(
              (item) =>
                item.name
                  .toLowerCase()
                  .includes(inputValue.trim().toLowerCase()) &&
                item.key !== 'other'
            )

            // Sort the filtered list by length to prioritise a core breed first
            // before a mixed breed.
            // Eg:
            // 'Poodle'
            // 'Toy Poodle'
            // 'Pugapoo - (Pug / Poodle mix)'
            .sort((breedA, breedB) => breedA.name.length - breedB.name.length)
        : data?.breeds.filter(
            (item) =>
              item.name.toLowerCase().startsWith(inputValue.toLowerCase()) &&
              item.key !== 'other'
          )

      // If we can't match a breed, ensure the user always sees a
      // 'Mixed breed' and 'Other' option
      if (filteredBreeds && filteredBreeds.length > 0) {
        setBreeds(filteredBreeds)
      } else {
        setBreeds([mixedBreed, otherBreed])
      }
    },
    stateReducer: (state, actionAndChanges) => {
      const { type: actionAndChangesType, changes } = actionAndChanges
      switch (actionAndChangesType) {
        case useCombobox.stateChangeTypes.InputBlur:
          setToggleIconButton(false)
          return {
            ...changes,
            selectedItem: null
          }
        default:
          return changes
      }
    }
  })

  const onInputFocus = useCallback(() => {
    events.onFocus && events.onFocus()
    setToggleIconButton(true)
  }, [events])

  React.useEffect(() => {
    if (!loading && data) {
      // We want to display the top 20 most popular breeds first when users
      // first open the list without typing anything
      const sortedBreeds = [...data.breeds]
        .sort((breedA, breedB) => breedA.name.localeCompare(breedB.name))
        .sort(
          (breedA, breedB) => Number(breedB.popular) - Number(breedA.popular)
        )
      setBreeds(sortedBreeds)
    }
  }, [data, loading])

  React.useEffect(() => {
    if (isOpen) {
      onListOpen()
    }
  }, [isOpen, onListOpen])

  if (error) {
    Sentry.captureException(
      `Error in BREED_SELECTOR_QUERY of: ${error.message}`
    )
    return null
  }

  const inputClassNames = classnames('breedautocomplete', STYLES.inputField)

  return (
    <div className={`${variant === 'v2' ? STYLES.v2 : ''}`}>
      <div
        className={`${STYLES.breedSelectorWrapper} ${
          isOpen ? STYLES.expanded : ''
        }`}
      >
        <input
          data-testid="breed-selector"
          className={inputClassNames}
          {...getInputProps({
            placeholder: loading ? loadingPlaceholder : placeholder,
            disabled: loading,
            onFocus: onInputFocus,
            ref: customRef
          })}
        />
        {variant === 'v2' && onClickCTA && embeddedCTA && (
          <div className={STYLES.buildBoxButtonWrapper}>
            <ExpandableIconButton
              toggleIconButton={toggleIconButton}
              text={'breed_autocomplete.feedback.cta_build_your_box_v2'}
              namespace={namespace}
              onClick={onClickCTA}
              variant="primary"
              icon={<Icon asset="arrow" size={30} accentColour="brandWhite" />}
            />
          </div>
        )}
      </div>
      <div
        className={`${STYLES.resultsListWrapper}  ${
          !isOpen ? STYLES.resultsListHidden : ''
        }`}
      >
        <ul
          className={`${STYLES.resultsList} ${
            !isOpen ? STYLES.resultsListHidden : ''
          }`}
          aria-label="Breed list"
          {...getMenuProps()}
        >
          {isOpen &&
            breeds.map((breed, index) => (
              <li
                data-testid="breed-selector-list-item"
                className={`${
                  highlightedIndex === index ? STYLES.highlighted : ''
                }`}
                key={breed.key}
                {...getItemProps({
                  item: breed,
                  index
                })}
              >
                {breed.name}
              </li>
            ))}
        </ul>
      </div>
    </div>
  )
}

export { Props }
export default withApollo(BreedAutomplete)
