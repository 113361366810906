// @noflow
import React, { Fragment, useCallback } from 'react'

import Card, {
  ClickableCardProps,
  NonClickableCardProps
} from '@/components/elements/atoms/Card/Card'
import FlatButton, {
  Props as FlatButtonProps
} from '@/components/elements/atoms/FlatButton/FlatButton'
import Text, { Props as TextProps } from '@/components/elements/atoms/Text/Text'

import STYLES from './ActionCard.module.sass'

import Label, { Props as LabelProps } from '../../atoms/Label/Label'
import LabelList from '../LabelList/LabelList'

type CardAction = Pick<
  FlatButtonProps,
  'onClick' | 'text' | 'variant' | 'identifier'
>

type ActionCardProps = {
  heading?: Omit<TextProps, 'variant'> & {
    labels?: Array<LabelProps>
  }
  button?: CardAction
  indent?: 0 | 8 | 16 | 24 | 32
}

type ClickableActionCardProps = ActionCardProps & ClickableCardProps
type NonClickableActionCardProps = ActionCardProps & NonClickableCardProps

type Props = ClickableActionCardProps | NonClickableActionCardProps

/**
 * A card component with an action button inside of it
 * @param title
 * @param button - props defining the flat button
 * @param children
 * @param card - the card properties inherited by this component
 * @constructor
 */
const ActionCard = ({
  button,
  heading,
  children,
  indent,
  ...card
}: Props): JSX.Element => {
  const buttonOnClick = useCallback(
    (event) => {
      event.stopPropagation()
      button?.onClick(event)
    },
    [button]
  )

  return (
    <Card {...card}>
      <div
        className={`
          ${STYLES.container}
          ${
            indent
              ? STYLES[`indent${indent}`]
              : card.padding
              ? STYLES[`indent${card.padding}`]
              : ''
          }
        `}
      >
        <div className={STYLES.content}>
          <div className={STYLES.heading}>
            {heading && (
              <>
                <Text element="div" {...heading} variant="display20" />
                {heading.labels && (
                  <LabelList alignment="left">
                    {heading.labels.map((label) => (
                      // eslint-disable-next-line jsx-a11y/label-has-for
                      <Label key={label.id} id={label.id} {...label} />
                    ))}
                  </LabelList>
                )}
              </>
            )}
          </div>

          {children}
        </div>
        {button?.text && (
          <FlatButton
            {...button}
            text={button.text}
            onClick={buttonOnClick}
            identifier="action_card"
          />
        )}
      </div>
    </Card>
  )
}

export {
  Props,
  ActionCardProps,
  ClickableActionCardProps,
  NonClickableActionCardProps
}
export default ActionCard
