// @flow

import * as MESSAGES from '../actions/messages'

import type { WizardStepReducerAction } from '../actions'

type State =
  | 'FavouriteGames'
  | 'FavouriteActivities'
  | 'QuirksAndTraits'
  | 'UploadPhoto'
  | 'DogDescription'
  | 'PostWizard'

type WizardStep = State

const initialState: State = 'FavouriteGames'

const nextWizardStep = (wizardStep: WizardStep): WizardStep => {
  switch (wizardStep) {
    case 'FavouriteGames': {
      return 'FavouriteActivities'
    }
    case 'FavouriteActivities': {
      return 'QuirksAndTraits'
    }
    case 'QuirksAndTraits': {
      return 'UploadPhoto'
    }
    case 'UploadPhoto': {
      return 'DogDescription'
    }
    case 'DogDescription': {
      return 'PostWizard'
    }
    case 'PostWizard': {
      return 'PostWizard'
    }
    default: {
      (wizardStep: empty) // eslint-disable-line no-unused-expressions
      throw new Error(`Cannot generate nextWizardStep for: ${wizardStep}`)
    }
  }
}

const previousWizardStep = (wizardStep: WizardStep): WizardStep => {
  switch (wizardStep) {
    case 'FavouriteGames': {
      return 'FavouriteGames'
    }
    case 'FavouriteActivities': {
      return 'FavouriteGames'
    }
    case 'QuirksAndTraits': {
      return 'FavouriteActivities'
    }
    case 'UploadPhoto': {
      return 'QuirksAndTraits'
    }
    case 'DogDescription': {
      return 'UploadPhoto'
    }
    case 'PostWizard': {
      return 'DogDescription'
    }
    default: {
      (wizardStep: empty) // eslint-disable-line no-unused-expressions
      throw new Error(`Cannot generate previousWizardStep for: ${wizardStep}`)
    }
  }
}

const reducer = (state: State = initialState, action: WizardStepReducerAction): State => {
  switch (action.type) {
    case MESSAGES.SET_WIZARD_STEP: {
      return action.wizardStep
    }
    case MESSAGES.NEXT_WIZARD_STEP: {
      return nextWizardStep(state)
    }
    case MESSAGES.PREVIOUS_WIZARD_STEP: {
      return previousWizardStep(state)
    }
    default: {
      (action.type: empty) // eslint-disable-line
      return state
    }
  }
}

export type { State }

export {
  nextWizardStep,
  previousWizardStep
}

export default reducer
