// @noflow
import React from 'react'

import { Locale } from '@/utils/currency'

import type { CheckoutPage } from '../../../queries/__generated__/CheckoutPage'

import type { ActivePaymentView, PaymentMethod } from '../../../types'
import { CreditCard } from '../CreditCardView'
import { UseBuySubscriptionWithPayPal } from '../hooks/useBuySubscriptionWithPayPal'
import { UseBuySubscriptionWithPaymentRequest } from '../hooks/useBuySubscriptionWithPaymentRequest'
import BancontactOption, { Bancontact } from './BancontactOption'
import CreditCardOption from './CreditCardOption'
import IdealOption, { Ideal } from './IdealOption'
import PayPalOption from './PayPalOption'
import PaymentRequestOption from './PaymentRequestOption'

type Props = {
  activePaymentView: ActivePaymentView
  namespace: string
  paymentMethod: PaymentMethod
  creditCard: CreditCard
  paymentRequest: UseBuySubscriptionWithPaymentRequest['paymentRequest']
  updatePaymentRequestPrices: UseBuySubscriptionWithPaymentRequest['updatePaymentRequestPrices']
  payPal: {
    payPalButtonClicked: UseBuySubscriptionWithPayPal['payPalButtonClicked']
    paypalClientId: string
    currency: CheckoutPage['guest']['assumedShippingCountry']['currency']
    payPalAuthorisationResponse: UseBuySubscriptionWithPayPal['payPalAuthorisationResponse']
    handlePayPalInContextSubmission: UseBuySubscriptionWithPayPal['handlePayPalInContextSubmission']
    disablePayPalButton: boolean
    locale: Locale
  }
  bancontact: Bancontact
  ideal: Ideal
  paymentSectionVisible: boolean
}
const PaymentOption = ({
  activePaymentView,
  namespace,
  paymentMethod,
  creditCard,
  paymentRequest,
  updatePaymentRequestPrices,
  payPal,
  bancontact,
  ideal,
  paymentSectionVisible
}: Props): JSX.Element => {
  return (
    <>
      {paymentMethod === 'creditCard' && (
        <CreditCardOption
          namespace={namespace}
          show={paymentSectionVisible && activePaymentView === 'creditCard'}
          creditCard={creditCard}
        />
      )}
      {(paymentMethod === 'applePay' || paymentMethod === 'googlePay') && (
        <PaymentRequestOption
          show={
            paymentSectionVisible &&
            (activePaymentView === 'applePay' ||
              activePaymentView === 'googlePay')
          }
          paymentRequest={paymentRequest}
          updatePaymentRequestPrices={updatePaymentRequestPrices}
        />
      )}
      {paymentMethod === 'bancontact' && (
        <BancontactOption
          bancontact={bancontact}
          namespace={namespace}
          show={paymentSectionVisible && activePaymentView === 'bancontact'}
        />
      )}
      {paymentMethod === 'iDeal' && (
        <IdealOption
          namespace={namespace}
          show={paymentSectionVisible && activePaymentView === 'iDeal'}
          ideal={ideal}
        />
      )}
      {paymentMethod === 'payPal' &&
        payPal.payPalAuthorisationResponse &&
        payPal.payPalAuthorisationResponse?.redirect_url !== null && (
          <PayPalOption
            show={paymentSectionVisible && activePaymentView === 'payPal'}
            payPal={payPal}
          />
        )}
    </>
  )
}

export default PaymentOption
