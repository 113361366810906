import { gql } from '@apollo/client'

const UPSELL_MODAL_FRAGMENT = gql`
  fragment UpsellModalFragment on User {
    id
    shippingCountryCode
    shippingCountry {
      id
      showUpcomingOrderSeeMoreExtras
    }
    dogs {
      id
      name
    }
  }
`

export { UPSELL_MODAL_FRAGMENT }
