// @noflow
import { useQuery, useReactiveVar } from '@apollo/client'
import i18next from 'i18next'
import React, { FormEvent, useCallback, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { possessive, pronounContext } from '@/utils/StringHelper'

import Text from '@/components/elements/atoms/Text'
import NotificationContent from '@/components/elements/molecules/NotificationContent/NotificationContent'
import type { Props as NotificationContentProps } from '@/components/elements/molecules/NotificationContent/NotificationContent'
import PillGroup from '@/components/elements/molecules/PillGroup/PillGroup'
import TextSeparator from '@/components/elements/molecules/TextSeparator/TextSeparator'
import StickyNavigation from '@/components/elements/organisms/StickyNavigation/StickyNavigation'
import {
  routeToNextStep,
  routeToPrevStep
} from '@/components/pages/SignupWizardPage/helpers/wizardRoutes'
import type {
  HealthIssues_healthIssues as HealthIssue,
  HealthIssues as HealthIssuesQuery
} from '@/components/pages/SignupWizardPage/screens/Health/queries/__generated__/HealthIssues'
import type { Route } from '@/components/pages/SignupWizardPage/types/routes'

import STYLES from './Health.module.sass'

import { HEALTH_ISSUES_QUERY } from './queries/healthIssuesQuery'

import { Gender } from '@/types'

import { wizardPageState } from '../../SignupWizardPage'
import { trackUserSelectedHealthIssues } from '../../helpers/analytics'
import useImplicitFormSubmission from '../../helpers/useImplicitFormSubmission'
import { Dog } from '../../types/types'
import HealthIssueAlertCard from './HealthIssueAlertCard'

type Props = {
  variant?: keyof typeof STYLES
  namespace: string
}

const Health = ({ variant, namespace }: Props): JSX.Element => {
  const copyContext = 'health_step'

  const wizardState = useReactiveVar(wizardPageState)
  const { dogs } = wizardState
  const route = useLocation().pathname as Route

  const { data } = useQuery<HealthIssuesQuery>(HEALTH_ISSUES_QUERY)

  const [showHealthIssues, setShowHealthIssues] = useState<Array<boolean>>(
    dogs.map(({ healthIssues }) => !!(healthIssues && healthIssues?.length > 0))
  )

  const [dogIndex, setDogIndex] = useState(0)

  const navigate = useNavigate()

  const navigateToNextStep = useCallback(() => {
    const userHasSelectedHealthIssues = dogs.some(
      ({ healthIssues }) => !!healthIssues?.length
    )
    trackUserSelectedHealthIssues(userHasSelectedHealthIssues)

    navigate(routeToNextStep({ route }))
  }, [dogs, navigate, route])

  useImplicitFormSubmission({
    formIsValid: true,
    onEnterPress: navigateToNextStep
  })

  const displayHealthIssueNotification = useCallback(
    (healthIssue: HealthIssue, dog: Dog) => {
      const copy: NotificationContentProps['copy'] = {
        namespace,
        variant: 'textRegular16',
        text: healthIssue.information,
        variables: {
          dogName: dog.name,
          possessiveDogName: possessive(dog.name, i18next.language),
          context: pronounContext([dog.gender as Gender], i18next.language)
        },
        translate: false
      }

      healthIssue.warning
        ? toast.error(<NotificationContent copy={copy} />)
        : toast.success(<NotificationContent copy={copy} />)
    },
    [namespace]
  )

  const handleChange = useCallback(
    (
      e: FormEvent<HTMLDivElement> | undefined,
      healthIssue: HealthIssue,
      index: number
    ) => {
      if (e) {
        setDogIndex(index)
        const clickedElement = e.target as HTMLInputElement

        if (clickedElement.checked) {
          if (!dogs[index].healthIssues) {
            dogs[index].healthIssues = []
          }
          dogs[index].healthIssues?.push(healthIssue)

          displayHealthIssueNotification(healthIssue, dogs[index])
        } else {
          dogs[index].healthIssues =
            dogs[index].healthIssues?.filter(
              (hi) => hi.id !== healthIssue.id
            ) || []
        }
      }
      wizardPageState({
        ...wizardState,
        dogs
      })
    },
    [displayHealthIssueNotification, dogs, wizardState]
  )

  const clearHealthIssues = useCallback(
    (index) => {
      dogs[index].healthIssues = []
      wizardPageState({
        ...wizardState,
        dogs
      })
    },
    [dogs, wizardState]
  )

  const toggleHealthIssues = useCallback(
    (index: number) => {
      clearHealthIssues(index)
      const newShow = [...showHealthIssues]
      newShow[index] = !newShow[index]
      setShowHealthIssues(newShow)
      setDogIndex(index)
    },
    [clearHealthIssues, showHealthIssues]
  )

  return (
    <>
      <div className={variant ? STYLES[variant] : ''}>
        <div className={STYLES.question} id={'anyHealthIssuesStep'}>
          {dogs.map((dog, index) => {
            return (
              <div className={STYLES.subQuestion} key={index.toString()}>
                <TextSeparator
                  text={`${copyContext}.text_separator`}
                  namespace={namespace}
                  variables={{ dogName: dog.name }}
                />
                {/* eslint-disable-next-line react/jsx-no-bind */}
                <div onChange={() => toggleHealthIssues(index)}>
                  <PillGroup
                    pillOptions={[
                      {
                        id: `${dog.name}-${index}-none`,
                        dataTestId: 'no-health-issues',
                        name: `${dog.name}-${index}-health`,
                        text: `${copyContext}.none`,
                        value: 'none',
                        defaultChecked: !showHealthIssues[index],
                        namespace
                      },
                      {
                        id: `${dog.name}-${index}-some`,
                        name: `${dog.name}-${index}-health`,
                        text: `${copyContext}.some`,
                        value: 'some',
                        defaultChecked: showHealthIssues[index],
                        namespace,
                        dataTestId: 'some-health-issues'
                      }
                    ]}
                  />
                </div>
                {data?.healthIssues && showHealthIssues[index] && (
                  <div className={STYLES.healthIssuesList}>
                    <Text
                      element="h3"
                      variant="textRegular16"
                      text="health_step.health_issues"
                      margin={false}
                      namespace="wizard_flow"
                    />

                    <PillGroup
                      pillOptions={data.healthIssues.map(
                        (healthIssue: HealthIssue, i) => ({
                          id: `${dog.name}-${i.toString()}-${healthIssue.id}`,
                          dataTestId: `health-issue-${healthIssue.id}`,
                          name: `${dog.name}-${i.toString()}-${
                            healthIssue.name
                          }`,
                          text: healthIssue.name,
                          value: healthIssue.id,
                          checked:
                            (dog.healthIssues &&
                              dog.healthIssues
                                .map(({ id }) => id)
                                .includes(healthIssue.id)) ||
                            false,
                          onChange: (e) =>
                            handleChange(e, healthIssue, dogIndex),
                          translate: false,
                          showCrossOnChecked: healthIssue.warning,
                          variant: 'checkbox',
                          colour: healthIssue.warning ? 'red400' : undefined
                        })
                      )}
                    />
                  </div>
                )}

                <div className={STYLES.alertGroup}>
                  {dog.healthIssues !== null &&
                    dog.healthIssues.length > 0 &&
                    dog.healthIssues.map((healthIssue, index) => (
                      <HealthIssueAlertCard
                        key={`${dog.name}-${
                          healthIssue.name
                        }-${index.toString()}`}
                        namespace={namespace}
                        dog={dog}
                        healthIssue={healthIssue}
                      />
                    ))}
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <StickyNavigation
        variant="twoButtons"
        buttonOne={{
          url: routeToPrevStep({ route }),
          text: 'wizard_flow:sticky_navigation.back',
          variant: 'secondary',
          iconColour: 'brandRed400'
        }}
        buttonTwo={{
          dataTestId: 'next-button',
          onClick: () => navigateToNextStep(),
          text: 'wizard_flow:sticky_navigation.continue',
          variant: 'primary',
          iconColour: 'brandWhite'
        }}
      />
    </>
  )
}

export { Props, Health }
export default Health
