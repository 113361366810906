/* eslint-disable i18next/no-literal-string */
import React, { useCallback, useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { AnalyticsProps, trackEvent } from '@/services/segment'

import STYLES from './QuantitySelector.module.sass'

type Props = {
  quantity: number
  onChange: (arg: number) => void
  minValue?: number
  maxValue?: number | null
  controlled?: boolean
  processing?: boolean
  debounced?: boolean
  size?: 30 | 40 | 'fullWidth'
  disabled?: boolean
  variant?: 'blue' | 'yellow'
} & AnalyticsProps

const QuantitySelector = ({
  quantity = 1,
  minValue = 1,
  maxValue,
  onChange,
  disabled = false,
  controlled = false,
  processing = false,
  debounced = false,
  size = 40,
  variant = 'blue',
  disableAnalytics = false,
  identifier,
  trackDisabledPresses = false,
  screenIdentifier = null
}: Props): JSX.Element => {
  const [selectedQuantity, setSelectedQuantity] = useState(quantity)

  const debouncedOnChange = useDebouncedCallback((to) => onChange(to), 1000)

  const handleTrackEvent = useCallback(
    (componentIdentifier: string): void => {
      trackEvent('Component Clicked', {
        component_identifier: componentIdentifier,
        ...(screenIdentifier ? { screen_identifier: screenIdentifier } : {})
      })
    },
    [screenIdentifier]
  )

  const handleDecrease = useCallback((): void => {
    if ((minValue !== null && selectedQuantity === minValue) || disabled) {
      /**
       * If the button is disabled, we still can track the event.
       * Analytics has to be enabled (defaults to `true`) and the `trackDisabledPresses` has
       * to be provided (defaults to `null`).
       */
      if (!disableAnalytics && trackDisabledPresses) {
        handleTrackEvent(`${identifier}.decrease.disabled`)
      }
    } else {
      // If the button and analytics are enabled, we track the event.
      if (!disableAnalytics && identifier) {
        handleTrackEvent(`${identifier}.decrease`)
      }

      if (selectedQuantity > minValue) {
        const to = selectedQuantity - 1
        setSelectedQuantity(to)
        if (debounced) debouncedOnChange(to)
        else onChange(to)
      }
    }
  }, [
    disabled,
    selectedQuantity,
    minValue,
    disableAnalytics,
    trackDisabledPresses,
    handleTrackEvent,
    identifier,
    debounced,
    debouncedOnChange,
    onChange
  ])

  const handleIncrease = useCallback((): void => {
    if ((maxValue !== null && selectedQuantity === maxValue) || disabled) {
      /**
       * If the button is disabled, we still can track the event.
       * Analytics has to be enabled (defaults to `true`) and the `trackDisabledPresses` has
       * to be provided (defaults to `null`).
       */
      if (!disableAnalytics && trackDisabledPresses) {
        // eslint-disable-next-line i18next/no-literal-string
        handleTrackEvent(`${identifier}.increase.disabled`)
      }
    } else {
      // If the button and analytics are enabled, we track the event.
      if (!disableAnalytics && identifier) {
        handleTrackEvent(`${identifier}.increase`)
      }

      const to = selectedQuantity + 1
      setSelectedQuantity(to)
      if (debounced) debouncedOnChange(to)
      else onChange(to)
    }
  }, [
    maxValue,
    selectedQuantity,
    disabled,
    debounced,
    debouncedOnChange,
    onChange,
    disableAnalytics,
    trackDisabledPresses,
    handleTrackEvent,
    identifier
  ])

  // set the selector to be controlled and it's source of truth to be the quantity prop vs it's state
  useEffect(() => {
    if (!processing && controlled && quantity !== selectedQuantity)
      setSelectedQuantity(quantity)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity, processing])

  return (
    <div
      className={`${STYLES.container} ${STYLES[`size${size}`]} ${
        STYLES[variant]
      }`}
    >
      <button
        className={`${STYLES.button} ${STYLES.decrease} ${
          (minValue !== null && selectedQuantity === minValue) || disabled
            ? STYLES.disabled
            : ''
        }`}
        type="button"
        onClick={handleDecrease}
      />
      <p className={STYLES.quantity}>{selectedQuantity}</p>
      <button
        className={`${STYLES.button} ${STYLES.increase} ${
          (maxValue !== null && selectedQuantity === maxValue) || disabled
            ? STYLES.disabled
            : ''
        }`}
        type="button"
        onClick={handleIncrease}
      />
    </div>
  )
}

export { Props }
export default QuantitySelector
