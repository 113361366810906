// @noflow
import React, { useCallback, useEffect, useState } from 'react'

import Icon from '@/components/elements/atoms/Icon/Icon'
import SkeletonButton from '@/components/elements/atoms/SkeletonButton/SkeletonButton'

import STYLES from './TextInput.module.sass'

type ValidationRule = {
  validate: (value: string) => boolean
  errorMessage: string
}

type Props = {
  validationRules?: Array<ValidationRule>
  initialValue?: string
  type?: 'text' | 'number' | 'password'
  placeholder?: string
  width?: string | number
  disabled?: boolean
  loading?: boolean
  onChange?: (value: string) => void
}

const TextInput = ({
  validationRules = [],
  initialValue = '',
  type = 'text',
  placeholder = '',
  width = '100%',
  disabled = false,
  loading = false,
  onChange
}: Props): JSX.Element => {
  const [value, setValue] = useState(initialValue)
  const [isValid, setIsValid] = useState(true)
  const [helperText, setHelperText] = useState('')

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value
      setValue(newValue)

      // Validate the input value against all provided validation rules
      let valid = true
      let errorMessage = ''

      for (const rule of validationRules) {
        if (!rule.validate(newValue)) {
          valid = false
          errorMessage = rule.errorMessage
          break
        }
      }

      setIsValid(valid)
      setHelperText(valid ? '' : errorMessage)

      if (onChange) {
        onChange(newValue)
      }
    },
    [validationRules, onChange]
  )

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  if (loading) {
    return <SkeletonButton width="100%" height={60} margin="0" />
  }

  return (
    <div className={STYLES.textInputContainer}>
      <input
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        disabled={disabled}
        className={`${STYLES.textInput} ${
          disabled ? STYLES.disabled : !isValid ? STYLES.error : STYLES.default
        }`}
        style={{ width }}
      />
      {helperText && (
        <div className={STYLES.helperTextContainer}>
          <Icon
            asset="errorCircle"
            size={16}
            width={16}
            accentColour="dangerRed300"
          />
          <span
            className={`${STYLES.helperText} ${
              !isValid ? STYLES.helperTextError : STYLES.helperTextDefault
            }`}
          >
            {helperText}
          </span>
        </div>
      )}
    </div>
  )
}

export default TextInput
