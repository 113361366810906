import React from 'react'

import { FaqType } from '../../types/types'
import ReplaceBoxFaq from './ReplaceBoxFaq'

type Props = {
  faqType: FaqType
}

const CustomerIssuesFAQSwitch = ({ faqType }: Props): JSX.Element | null => {
  switch (faqType) {
    case 'replaceBox':
      return (
        <div>
          <ReplaceBoxFaq />
        </div>
      )

    default:
      return null
  }
}

export { Props }
export default CustomerIssuesFAQSwitch
