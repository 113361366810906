// @flow

type DayOfWeek =
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday'
  | 'Sunday'

type LetterDayOfWeek =
  | 'M'
  | 'T'
  | 'W'
  | 'T'
  | 'F'
  | 'S'
  | 'S'

const daysOfTheWeek: Array<DayOfWeek> = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
]

const dayToLetterDayOfWeek = (d: DayOfWeek): LetterDayOfWeek => {
  switch (d) {
    case 'Monday': {
      return 'M'
    }
    case 'Tuesday': {
      return 'T'
    }
    case 'Wednesday': {
      return 'W'
    }
    case 'Thursday': {
      return 'T'
    }
    case 'Friday': {
      return 'F'
    }
    case 'Saturday':
    case 'Sunday': {
      return 'S'
    }
    default: {
      (d: empty) // eslint-disable-line no-unused-expressions
      throw new Error(`dayToLetterDayOfWeek not implemented for type: ${d}`)
    }
  }
}

export type {
  DayOfWeek,
  LetterDayOfWeek
}

export {
  daysOfTheWeek,
  dayToLetterDayOfWeek
}
