// @noflow
import type { Error } from '@/redux/graphql_queries/errorTypes'
import { ApolloQueryResult } from '@apollo/client'
import i18next from 'i18next'
import Cookies from 'js-cookie'

import { toLocalisedSentence } from '@/utils/StringHelper'

import client from '@/components/apollo/client'

import type {
  ContactFormInitialData,
  ContactFormInitialData_user
} from './__generated__/ContactFormInitialData'
import type { ContactFormInitialDataPreWizardGuest } from './__generated__/ContactFormInitialDataPreWizardGuest'
import type { RailsModelID } from '@/shared_types/ids'
import type { Token } from '@/shared_types/tokens'
import { Code, Language } from '@/types'

import type { LoadedAppData } from '../reducers/formDataReducer'
import type { GlobalData } from '../reducers/globalAttributesReducer'
import {
  INITIAL_DATA_PRE_WIZARD_GUEST_QUERY,
  INITIAL_DATA_QUERY
} from './queries'

type DecodedGraphQLResponse = {
  data?: ContactFormInitialData | ContactFormInitialDataPreWizardGuest
  errors?: Array<{ message: string }>
}

type Success = {
  type: 'Success'
  data: {
    formData: LoadedAppData
    globalData: GlobalData
  }
}

type RequestResult = Success | Error

const graphQLQuery = (userId: RailsModelID | null, token: Token | null) => {
  return userId && token
    ? INITIAL_DATA_QUERY
    : INITIAL_DATA_PRE_WIZARD_GUEST_QUERY
}

const fetchInitialData = ({
  userId,
  token
}: {
  userId: RailsModelID | null
  token: Token | null
}): Promise<RequestResult> => {
  const gqlQuery = graphQLQuery(userId, token)
  const shippingCountryCookie =
    (Cookies.get('user_country_code') as Code) || Code.GB

  return client
    .query({ query: gqlQuery })
    .then(
      (
        result: ApolloQueryResult<
          ContactFormInitialData | ContactFormInitialDataPreWizardGuest
        >
      ) => {
        const { errors, data } = result

        if (errors) {
          return {
            type: 'GraphQLError',
            errors: errors.map((error) => {
              return { message: error.message }
            })
          }
        }

        if (!errors && data) {
          let user: ContactFormInitialData_user | undefined
          if ('user' in data) {
            user = data.user
          }
          const { allergens, shippingCountries } = data

          const dogs = user?.dogs || []

          const dogNames = dogs.map(({ name }) => {
            return name
          })

          const email = user?.email || ''

          const shippingCountryCode = user?.shippingCountry.code || Code.GB

          const preferredLanguage = user?.preferredLanguage || Language.en

          const shippingCountryContactInformation = shippingCountries.find(
            (shippingCountry) => {
              if (user?.shippingCountry.code) {
                return shippingCountry.code === user.shippingCountry.code
              } else {
                return shippingCountry.code === shippingCountryCookie
              }
            }
          )?.contactInformation

          return {
            type: 'Success',
            data: {
              formData: {
                shippingCountryCode,
                preferredLanguage,
                name: {
                  value: user ? `${user.firstName} ${user.lastName}` : '',
                  interaction: 'NotInteracted'
                },
                email: {
                  value: email,
                  interaction: 'NotInteracted'
                },
                dogNames: {
                  value:
                    dogNames.length > 0
                      ? toLocalisedSentence({
                          arr: dogNames,
                          lng: i18next.language
                        })
                      : '',
                  interaction: 'NotInteracted'
                },
                message: {
                  value: '',
                  interaction: 'NotInteracted'
                },
                contactInformation: {
                  contactHours:
                    shippingCountryContactInformation?.contactHours ?? null,
                  contactNumber:
                    shippingCountryContactInformation?.contactNumber ?? null,
                  displayContactNumber:
                    shippingCountryContactInformation?.displayContactNumber ??
                    null,
                  displayWhatsappNumber:
                    shippingCountryContactInformation?.displayWhatsappNumber ??
                    null,
                  whatsappContactHours:
                    shippingCountryContactInformation?.whatsappContactHours ??
                    null,
                  whatsappNumber:
                    shippingCountryContactInformation?.whatsappNumber ?? null
                }
              },
              globalData: {
                allergens
              }
            }
          }
        }

        return {
          type: 'UnknownError',
          error: 'Unknown Error'
        }
      }
    )
}

export type { RequestResult, DecodedGraphQLResponse }

export default fetchInitialData
