// @noflow
import { Analytics } from '@/components/analytics/Analytics'

type NoPaymentMethodAnalyticsProperties = {
  successfulPaymentMethodSubmission: boolean
  haveReachedNoPaymentMethodPage: boolean
}

const trackNoPaymentMethodPage = (
  action: string,
  getAnalyticsProperties: () => NoPaymentMethodAnalyticsProperties,
  properties?: NoPaymentMethodAnalyticsProperties
): void => {
  if (properties) {
    // eslint-disable-next-line i18next/no-literal-string
    Analytics.track(`${action} On No Payment Method Page`, properties)
  } else {
    const props = getAnalyticsProperties()
    // eslint-disable-next-line i18next/no-literal-string
    Analytics.track(`${action} On No Payment Method Page`, props)
  }
}

const trackReachedNoPaymentMethodPage = (
  getAnalyticsProperties: () => NoPaymentMethodAnalyticsProperties
) => {
  const action = 'Reached No Payment Method Page'
  const props: NoPaymentMethodAnalyticsProperties = getAnalyticsProperties()
  props.haveReachedNoPaymentMethodPage = true
  trackNoPaymentMethodPage(action, getAnalyticsProperties, props)
}

const trackSuccessfulPaymentMethodSubmission = (
  getAnalyticsProperties: () => NoPaymentMethodAnalyticsProperties
) => {
  const action = 'Payment Method Submission Button Pressed'
  const props: NoPaymentMethodAnalyticsProperties = getAnalyticsProperties()
  props.successfulPaymentMethodSubmission = true
  trackNoPaymentMethodPage(action, getAnalyticsProperties, props)
}

const useGetAnalyticsProperties = () => {
  const getAnalyticsProperties = (
    successfulPaymentMethodSubmission = false,
    haveReachedNoPaymentMethodPage = false
  ): NoPaymentMethodAnalyticsProperties => {
    const properties: NoPaymentMethodAnalyticsProperties = {
      successfulPaymentMethodSubmission,
      haveReachedNoPaymentMethodPage
    }
    return properties
  }

  return getAnalyticsProperties
}

const useSuccessfulPaymentMethodSubmissionTracking = (): (() => void) => {
  const getAnalyticsProperties = useGetAnalyticsProperties()
  return () => trackSuccessfulPaymentMethodSubmission(getAnalyticsProperties)
}
const useReachedNoPaymentMethodPageTracking = (): (() => void) => {
  const getAnalyticsProperties = useGetAnalyticsProperties()
  return () => trackReachedNoPaymentMethodPage(getAnalyticsProperties)
}

export {
  useSuccessfulPaymentMethodSubmissionTracking,
  useReachedNoPaymentMethodPageTracking
}
