// @flow

import * as React from 'react'
import questionMark from '../../../assets/images/icons/icon-question-mark.svg'

type Props = {|
  children: React.Node,
  triggerText?: string,
  contentClass?: string,
  triggerAnalytics?: () => void
|}

const Tooltip = ({triggerText, contentClass, children, triggerAnalytics}: Props): React.Element<'div'> => {
  const [tooltipOpen, toggleTooltip] = React.useState(false)

  const onBlur = React.useCallback((): void => toggleTooltip(false))

  const onFocus = React.useCallback((e: SyntheticEvent<HTMLInputElement>): void => {
    e.persist()
    setTimeout((): void => {
      if (!tooltipOpen) {
        e.preventDefault()
        toggleTooltip(true)
      }
    }, 250)
  })

  const onClick = React.useCallback((e: SyntheticEvent<HTMLInputElement>): void => {
    e.preventDefault()
    e.stopPropagation()
    toggleTooltip(!tooltipOpen)
    if (!tooltipOpen && triggerAnalytics) {
      triggerAnalytics()
    }
  })

  return (
    <div
      className={`tooltip ${tooltipOpen ? 'tooltip--open' : 'tooltip--closed'}`}
      tabIndex="0"
      onFocus={onFocus}
      onBlur={onBlur}
    >
      <p
        className='micro tooltip__trigger-text'
        role="button"
        onClick={onClick}
      >
        { triggerText }
        <img
          className='tooltip__icon'
          src={questionMark}
          alt='A question mark'
          onClick={onClick}
        />
      </p>
      {
        tooltipOpen ? (
          <div
            role="status"
            className={`tooltip__content ${contentClass || ''}`}
          >
            { children }
          </div>
        ) : (<div role="status" />)
      }
    </div>
  )
}

export default Tooltip
