/* eslint-disable i18next/no-literal-string */
/* eslint-disable react/require-optimization */
/* eslint-disable react/no-arrow-function-lifecycle */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable flowtype/require-exact-type */
// @flow

import * as React from 'react'
import i18next from 'i18next'

import { tabs, shopTab } from './tabs'
import ContactFAQs from './partials/ContactFAQs'
import SelectBar from './partials/SelectBar'
import EmailIcon from 'assets/images/icons/envelope--white.svg'

import type { Ambassador } from './message_types'

type Props = {|
  ambassador: Ambassador,
  ambassadorShopPath: string
|}

const namespace = 'ambassadors:dashboard.contact'

const AmbassadorDashboardContact = ({ ambassador, ambassadorShopPath }: Props): React.Element<'div'> => (
  <div className='butternutbox-ambassador-dashboard'>
    <div className={`affiliate-dashboard__${tabs[0].hash}`}>
      <div className='affiliate-dashboard container'>
        <SelectBar
          tabs={tabs}
          shopTab={ ambassadorShopPath ? shopTab : []}
          ambassador={ambassador}
          ambassadorShopPath={ambassadorShopPath}
          selectedIndex={4}
        />
        <div className='contact-header'>
          <h1>
            { i18next.t(`${namespace}.here_to_help`) }
          </h1>
        </div>
        <div className='contact-options-wrapper'>
          <div className='contact-options'>
            <h2>
              { i18next.t(`${namespace}.who_to_contact`) }
            </h2>
            <div className='speech-bubble speech-bubble--left'>
              <p
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: i18next.t(`${namespace}.email_us_ambassador_questions`)
                }}
              />
            </div>
            <div className='contact-options__button-row'>
              <a
                href='mailto: ambassadors@butternutbox.com'
                target='_blank'
                rel='noopener noreferrer'
                className='btn btn-updated btn-updated--red'
              >
                <img
                  alt='Envelope icon to indicate email'
                  src={EmailIcon}
                />
                { i18next.t(`${namespace}.email_team`) }
              </a>
              <a
                href='https://butternutbox.com/contact'
                target='_blank'
                rel='noopener noreferrer'
                className='btn btn-updated btn-updated--secondary'
              >
                { i18next.t(`${namespace}.contact_cl`) }
              </a>
            </div>
            <div className='speech-bubble speech-bubble--right'>
              <p
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                    __html: i18next.t(`${namespace}.get_in_touch`)
                }}
              />
            </div>
          </div>
        </div>
        <ContactFAQs />
      </div>
    </div>
  </div>
)

export default AmbassadorDashboardContact
