/* eslint-disable i18next/no-literal-string */
const imgPathPrefix = 'Web/photos/ugc'
const PLReviews = [
  {
    path: `${imgPathPrefix}/filet_z_psa`,
    alt: 'instagram_carousel.pl.slide_1.image_alt',
    instagram_handle: 'filet_z_psa',
    text: 'Skrzydeł doda Ci... czekolada. Albo mocna kawa. 👼 A jeśli jesteś psem, sięgnij po @psibufet.'
  },
  {
    path: `${imgPathPrefix}/w_kokosowym_swiecie`,
    alt: 'instagram_carousel.pl.slide_2.image_alt',
    instagram_handle: 'w_kokosowym_swiecie',
    text: '@psibufet w nowej odsłonie 💛 Nowe opakowanie i ulepszony skład. 🔥 Póki co test smaku zdany pozytywnie 🥰'
  },
  {
    path: `${imgPathPrefix}/camber_pom`,
    alt: 'instagram_carousel.pl.slide_3.image_alt',
    instagram_handle: 'camber_pom',
    text: 'Psieposiłki psijechały! Kolejne miesiące na świeżym jedzonku od #psibufet. Nie drapiemy się, zacieki pod oczkami coraz mniejsze, a brzuszek szczęśliwy! 🐶'
  },
  {
    path: `${imgPathPrefix}/tojaelmo`,
    alt: 'instagram_carousel.pl.slide_4.image_alt',
    instagram_handle: 'tojaelmo',
    text: 'Ernie poluje już na kolejny posiłek 🐶'
  },
  {
    path: `${imgPathPrefix}/gymrussells`,
    alt: 'instagram_carousel.pl.slide_5.image_alt',
    instagram_handle: 'gymrussells',
    text: 'Nareszcie😋 Nareszcie przyjechała do nas nowa paczka od @psibufet 🐾♥️ ✅Nowy design ✅Nowe opakowania ✅Tak samo smaczne jedzenie👍'
  },
  {
    path: `${imgPathPrefix}/psimi_slowami`,
    alt: 'instagram_carousel.pl.slide_6.image_alt',
    instagram_handle: 'psimi_slowami',
    text: 'W końcu zdecydowałam się na zamówienie Toluszce jedzenia PsiBufet. Tola jest sporym niejadkiem i trochę się bałam, że nowe jedzenie jej nie podpasuje. Ale myliłam się. Tola pokochała @psibufet ❤️🐶'
  },
  {
    path: `${imgPathPrefix}/shiba_czaki`,
    alt: 'instagram_carousel.pl.slide_7.image_alt',
    instagram_handle: 'shiba_czaki',
    text: 'Ulubione jedzonko przyjechało od @psibufet ❤️'
  },
  {
    path: `${imgPathPrefix}/brandy_thedog_jrt`,
    alt: 'instagram_carousel.pl.slide_8.image_alt',
    instagram_handle: 'brandy_thedog_jrt',
    text: 'Halo, jedzenie, jesteś tam? 🧐🧐'
  }
]

export { PLReviews }
