import { gql } from '@apollo/client'

const BRIGHTBACK_INITIALISER_QUERY = gql`
  query BrightbackInitialiserQuery {
    user {
      id
      subscription {
        id
        deliveriesReceived
        pouchesConsumed
        hasAppliedPsfDiscount
        status
        pauseUrl
        activeBoostedMembership {
          id
          isActive
        }
        referralLink {
          referrerDiscount {
            applicableDiscountParts {
              value
              discountBasis
            }
          }
          referralDiscount {
            value
            discountBasis
          }
          referrals {
            refereeFirstName
          }
        }
        plan {
          id
        }
        nextNBoxes(num: 1) {
          id
          discountValue
          isNextEditableBox
          editable
        }
      }
    }
  }
`

export { BRIGHTBACK_INITIALISER_QUERY }
