import React, { useCallback } from 'react'

import Icon from '@/components/elements/atoms/Icon/Icon'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from '../Categories.module.sass'

import { SelfResolutionCategories_categories as CustomerIssueCategory } from '../../../queries/__generated__/SelfResolutionCategories'

type CategoryButtonProps = {
  category: CustomerIssueCategory
  selectCategory: (id: string) => void
}

const CategoryButton = ({
  category,
  selectCategory
}: CategoryButtonProps): JSX.Element => {
  const onClick = useCallback(
    () => selectCategory(category.id),
    [category, selectCategory]
  )

  return (
    <button type={'button'} className={STYLES.categoryButton} onClick={onClick}>
      <div className={STYLES.wrapper}>
        <div>
          <Text
            align={'left'}
            text={category.name}
            colour={'brandBlue500'}
            variant={'textRegular18'}
            translate={false}
          />
          {category.description !== 'Description not found' && (
            <Text
              align={'left'}
              text={category.description}
              translate={false}
              colour={'brandBlue400'}
              variant={'textRegular16'}
            />
          )}
        </div>
      </div>
      <div className={STYLES.icon}>
        <Icon asset={'chevron'} size={16} />
      </div>
    </button>
  )
}

export default CategoryButton
