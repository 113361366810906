// @noflow
import { Video } from 'cloudinary-react'
import * as React from 'react'

type CropType = 'scale' | 'fill' | 'fit' | 'limit' | 'pad' | 'lpad' | 'crop'

type CloudinaryParams = {
  path: string
  crop: CropType
  width?: number
  height?: number
  mode?: string
  alt?: string
  quality?: string
  format?: string
  aspectRatio?: string
  dpr?: number
  x?: number
  y?: number
  responsive?: boolean
  autoPlay?: HTMLVideoElement['autoplay']
  controls?: HTMLVideoElement['controls']
  muted?: HTMLVideoElement['muted']
  loop?: HTMLVideoElement['loop']
  playsInline?: boolean
}

type Props = {
  video: CloudinaryParams
  alt: string
}

const CloudName = 'ldhg5acsz'
// eslint-disable-next-line i18next/no-literal-string
const CloudPath = `https://res.cloudinary.com/${CloudName}/video/upload/`

const CloudinaryVideo = ({
  alt,
  video: {
    path,
    width,
    height,
    crop,
    dpr = window.devicePixelRatio,
    x,
    y,
    responsive,
    autoPlay,
    controls,
    muted,
    loop,
    playsInline = false
  }
}: Props): JSX.Element => (
  <Video
    cloudName={CloudName}
    publicId={path}
    width={width}
    height={height}
    alt={alt}
    dpr={dpr}
    x={x}
    y={y}
    responsive={responsive}
    autoPlay={autoPlay}
    controls={controls}
    muted={muted}
    loop={loop}
    controlsList="nodownload"
    disablepictureinpicture="true"
    crop={crop}
    playsInline={playsInline}
  />
)

export type { CloudinaryParams }

export { CloudPath, CloudName }

export default CloudinaryVideo
