// @noflow
import { useOccasion } from '@/context/festiveTheme/festiveTheme'
import { useCsrfToken } from '@/context/injectedValues/injectedValues'
import { ProductUpsellContext } from '@/context/productUpsellModal/productUpsellModal'
import type { Language } from '@/packs/localisation'
import { ACCOUNT_ROUTES } from '@/routes'
import {
  FetchResult,
  MutationFunctionOptions,
  useMutation,
  useQuery
} from '@apollo/client'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import * as Sentry from '@sentry/browser'
import { Code, Descriptor } from '@types'
import { format, format as formatDate, isEqual } from 'date-fns'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import kebabCase from 'lodash/kebabCase'
import React, {
  ElementType,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { pronounContext } from '@/utils/StringHelper'
import {
  countryCodeToLocaleCurrency,
  localeToDateLocale
} from '@/utils/countryCodeHelper'
import { Options } from '@/utils/currency'
import { getTimezoneOffset } from '@/utils/getTimezoneOffset'
import { isBoxDelivered } from '@/utils/orderHelper'
import scrollToElement from '@/utils/scrollToElement'

import BREAKPOINTS from '@/constants/Breakpoints'

import useButternutBoost from '@/hooks/useButternutBoost'
import useWindowSize from '@/hooks/useWindowSize'

import WhiteTick from 'assets/images/icons/checkmarks/white-tick-roundal.svg'

import AddMore from './components/AddMore/AddMore'
import AdditionalProductUpsellable from './components/AdditionalProductUpsellable'
import RecipeSelection from './components/RecipeSelection'
import segmentTrack from '@/components/analytics/Analytics'
import AlertCard from '@/components/elements/atoms/Alert/AlertCard'
import AnimatedBoostLogo from '@/components/elements/atoms/BoostLogo/AnimatedBoostLogo'
import { BoxDeliveryStatusText } from '@/components/elements/atoms/BoxDeliveryStatusText/BoxDeliveryStatusText'
import { Button } from '@/components/elements/atoms/Button'
import Icon from '@/components/elements/atoms/Icon/Icon'
import Modal from '@/components/elements/atoms/Modal/Modal'
import SkeletonLoading from '@/components/elements/atoms/SkeletonLoading/SkeletonLoading'
import Text from '@/components/elements/atoms/Text/Text'
import { SkeletonFreshMealItem } from '@/components/elements/molecules/BoxItem/FreshMealItem/FreshMealItem'
import ShopItem from '@/components/elements/molecules/BoxItem/ShopItem/ShopItem'
import Calendar from '@/components/elements/molecules/Calendar/Calendar'
import ChangeAddress from '@/components/elements/molecules/ChangeAddress/ChangeAddress'
import ExtrasModal from '@/components/elements/molecules/ExtrasModal/ExtrasModal'
import { transformExtra } from '@/components/elements/molecules/ExtrasModal/utils/transformExtras'
import IconCard from '@/components/elements/molecules/IconCard/IconCard'
import NotificationContainer from '@/components/elements/molecules/NotificationContainer/NotificationContainer'
import NotificationContent from '@/components/elements/molecules/NotificationContent/NotificationContent'
import TitleAndMore from '@/components/elements/molecules/TitleAndMore/TitleAndMore'
import BoostCard from '@/components/elements/organisms/BoostCard/BoostCard'
import ConfirmationModal, {
  modalData
} from '@/components/elements/organisms/ConfirmationModal/ConfirmationModal'
import FreshMealList, {
  Recipe
} from '@/components/elements/organisms/FreshMealList/FreshMealList'
import { ProductUpsellAnalyticTypes } from '@/components/elements/organisms/ProductsUpsellModal/ProductsUpsellModal'
import PriceBreakdownWithQuery from '@/components/pages/Dashboard/components/PriceBreakdownWithQuery/PriceBreakdownWithQuery'
import { BoxDeliveryAddressUpdate_boxAddressUpdate_subscription_box_address } from '@/components/pages/OrderPage/__generated__/BoxDeliveryAddressUpdate'
import { BoxDeliveryDateUpdate_boxDeliveryDateUpdate_subscription_box as BoxDeliveryInfo } from '@/components/pages/OrderPage/__generated__/BoxDeliveryDateUpdate'
import { boxQuery } from '@/components/pages/OrderPage/__generated__/boxQuery'
import {
  BOX_DELIVERY_ADDRESS_UPDATE,
  BOX_DELIVERY_DATE_UPDATE,
  BOX_QUERY,
  MEALS_QUERY
} from '@/components/pages/OrderPage/queries'
import { ReviewQuery_currentUser_User_planRecommendation_individual_dog as Dog } from '@/components/pages/PlansPage/__generated__/ReviewQuery'

import STYLES from './OrderPage.module.sass'

import type { boxQuery_user_subscription_box as Box } from './__generated__/boxQuery'
import {
  boxQuery_user_subscription_box_physicalOrderProducts,
  boxQuery_user_subscription_nextNBoxes
} from './__generated__/boxQuery'
import {
  mealsQuery_user_subscription_box_meals as Meal,
  mealsQuery
} from './__generated__/mealsQuery'
import { MembershipTypes, VariantDelivery } from '@/types'

import Base from '../../templates/Base'
import { NavigateContext } from '../App'
import OrderPageSkeleton from './OrderPageSkeleton'

type OrderHeaderProps = {
  boxId: number
  deliveryDate: Date
  cutOffDate: Date
  shippingCountryCode: Code
  preferredLanguage: Language
  shouldOfferSelfService: boolean
  isBoxDelivered: boolean
  amendable: boolean
  deliveryStatus: string | null
  descriptor: Descriptor
}

type Address =
  BoxDeliveryAddressUpdate_boxAddressUpdate_subscription_box_address
type UpdatedAddressFields = Omit<Partial<Address>, '__typename'>

type DeliveryDetailsProps = {
  shippingCountryCode: Code
  preferredLanguage: Language
  address: Address
  isoDeliveryDate: Date
  userId: string
  boxId: number
  isBoostedBox?: boolean
  amendable: boolean
  isNextEditableBox: boolean
  trackingUrl: string | null
  showUpcomingOrderSeeMoreExtras: boolean
  handleDeliveryDate: (
    userId: string,
    boxId: number,
    date: string,
    onSuccess?: (data: BoxDeliveryInfo) => void
  ) => void
  handleAddress: (
    userId: string,
    boxId: number,
    address: Address,
    onSuccess?: (data: Address) => void
  ) => void
} & Pick<Box, 'amendable' | 'isoDeliveryDate' | 'address'>

type RecipeProps = {
  pouchSize: number
  shippingCountryCode: Code
  preferredLanguage: Language
  isNextEditableBox: boolean
  isBoxDelivered: boolean
  namespace: string
  boxId: number
}

type MealsHeaderProps = {
  amendable: boolean
  onEditModalCloseGoTo?: string
  analyticsOnEditClick: () => void
}

type ShopSectionProps = {
  products: boxQuery_user_subscription_box_physicalOrderProducts[]
  amendable: boolean
  currencyOptions: Options
  possessivePronoun: string
  userId: string
  boxId: string
  isBoostedBox?: boolean
  namespace: string
  onExtrasModalClose: () => void
}

const getShopItemTotal = (
  physicalOrderProducts: boxQuery_user_subscription_box_physicalOrderProducts[]
): number =>
  physicalOrderProducts
    .map((physicalOrderProduct) => physicalOrderProduct.quantity)
    .reduce((previousValue, currentValue) => previousValue + currentValue, 0)

const OrderHeader = ({
  deliveryDate,
  cutOffDate,
  shippingCountryCode,
  preferredLanguage,
  shouldOfferSelfService,
  boxId,
  isBoxDelivered,
  amendable,
  deliveryStatus,
  descriptor
}: OrderHeaderProps) => {
  const helpWithOrderClicked = useCallback((): void => {
    segmentTrack('Order Details Page - Help with this order Button Clicked')
    // eslint-disable-next-line i18next/no-literal-string
    window.location.href = `/self-resolution?box_id=${boxId}`
  }, [boxId])

  const dateLocale = localeToDateLocale(shippingCountryCode, preferredLanguage)

  const showOrderHelpButton = isBoxDelivered && shouldOfferSelfService

  return (
    <header className={STYLES.header}>
      <Grid container alignItems="center" spacing={3}>
        <Grid item sm={12} md={showOrderHelpButton ? 8 : 12}>
          <Text
            element="h2"
            text={formatDate(new Date(deliveryDate), 'iiii dd MMMM', {
              locale: dateLocale
            })}
            variant="display18"
            colour="brandBlue500"
            margin={false}
            shouldScale
            translate={false}
          />
          <div>
            <BoxDeliveryStatusText
              amendable={amendable}
              cutOffDate={cutOffDate}
              deliveryDate={deliveryDate}
              status={deliveryStatus}
              type="box"
              descriptor={descriptor}
            />
          </div>
        </Grid>
        {showOrderHelpButton && (
          <Grid item sm={12} md={4}>
            <Grid
              container
              justifyContent="flex-end"
              className={STYLES.helpButton}
            >
              <Button
                typography={{
                  namespace: 'contacts',
                  text: 'last_box.getHelpButton'
                }}
                disableAnalytics
                onClick={helpWithOrderClicked}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </header>
  )
}

// Manually construct a new address object to pass to the backend
// The update address form only returns the updated form fields, however the mutation requires the entire address
const constructUpdatedAddressObject = (
  currentAddress: Address,
  updatedAddressFields: UpdatedAddressFields
): Address => {
  return { ...currentAddress, ...updatedAddressFields }
}

const DeliveryDetailsSection = ({
  shippingCountryCode,
  preferredLanguage,
  address,
  isoDeliveryDate,
  userId,
  boxId,
  isBoostedBox,
  amendable,
  isNextEditableBox,
  trackingUrl,
  showUpcomingOrderSeeMoreExtras,
  handleAddress,
  handleDeliveryDate
}: DeliveryDetailsProps): JSX.Element => {
  const dateLocale = localeToDateLocale(shippingCountryCode, preferredLanguage)
  const navigate = useContext(NavigateContext)

  const { windowWidth } = useWindowSize()
  const { boostedType } = useButternutBoost()

  const [addressModalIsOpen, setAddressModalIsOpen] = useState(false)
  const [deliveryDateModalIsOpen, setDeliveryDateModalIsOpen] = useState(false)
  const { openProductsUpsellModal } = useContext(ProductUpsellContext)

  const updateAddress = useCallback(
    (updatedFields: UpdatedAddressFields): void => {
      handleAddress(
        userId,
        boxId,
        constructUpdatedAddressObject(address, updatedFields),
        () => {
          modalData({
            isOpen: true,
            namespace: 'shared',
            text: 'delivery_details.address_updated',
            delay: 3000
          })
          segmentTrack('Order Details Page - Box Delivery Address Updated')
        }
      )
    },
    [address, boxId, handleAddress, userId]
  )

  const updateDeliveryDate = useCallback(
    (date: Date): void =>
      handleDeliveryDate(userId, boxId, getTimezoneOffset(date), () => {
        setDeliveryDateModalIsOpen(false)

        if (isNextEditableBox && showUpcomingOrderSeeMoreExtras) {
          openProductsUpsellModal({
            headerText: [
              {
                text: 'products_upsell_modal.delivery_date_changed_modal.title_information',
                variant: 'textRegular18',
                colour: 'brandBlue400',
                namespace: 'organisms',
                margin: false,
                variables: {
                  deliveryDate: format(
                    new Date(isoDeliveryDate),
                    'EEEE do MMMM',
                    { locale: dateLocale }
                  )
                }
              }
            ],
            analyticsType: ProductUpsellAnalyticTypes.deliveryDateChange
          })
        } else {
          // Pupdated modal
          modalData({
            isOpen: true,
            namespace: 'shared',
            text: 'delivery_details.date_updated',
            delay: 3000
          })
        }
      }),
    [
      boxId,
      dateLocale,
      handleDeliveryDate,
      isNextEditableBox,
      isoDeliveryDate,
      openProductsUpsellModal,
      showUpcomingOrderSeeMoreExtras,
      userId
    ]
  )

  const toggleAddressModal = useCallback((): void => {
    setAddressModalIsOpen(!addressModalIsOpen)
    if (!addressModalIsOpen)
      segmentTrack('Order Details Page - Edit Delivery Address Clicked')
  }, [addressModalIsOpen, setAddressModalIsOpen])

  const toggleDeliveryDatesModal = useCallback((): void => {
    navigate(`${ACCOUNT_ROUTES.changeDate}/${boxId}`, '#')
  }, [boxId, navigate])

  const trackOrderClicked = useCallback((): void => {
    if (isNull(trackingUrl)) {
      Sentry.captureException(
        'trackingUrl is null and cannot redirect the user'
      )
    }
    segmentTrack('Order Details Page - Track this order Button Clicked')
    window.open(trackingUrl as string)
  }, [trackingUrl])

  const today = new Date()
  const isOutForDelivery = isEqual(new Date(isoDeliveryDate), today)
  const recipientName = Object.keys(address)
    .filter((key: string) => key === 'recipientName')
    .map((key: string): string | null => {
      if (address[key as keyof Address] === '') return null
      return `${address[key as keyof Address]}`
    })
  const addressLines = Object.keys(address)
    .filter((key: string) => key === 'address1' || key === 'address2')
    .map((key: string): string | null => {
      if (address[key as keyof Address] === '') return null
      return `${address[key as keyof Address]}`
    })
    .join(', ')
  const cityAndPostcode = Object.keys(address)
    .filter((key: string) => key === 'city' || key === 'postcode')
    .map((key: string): string | null => {
      if (address[key as keyof Address] === '') return null
      return `${address[key as keyof Address]}`
    })
    .join(', ')
  // eslint-disable-next-line i18next/no-literal-string
  const addressCopy = `${recipientName}<br>${addressLines}<br>${cityAndPostcode}`

  const deliveryDetailsProps = useMemo(() => {
    return amendable
      ? {
          onClick: toggleAddressModal,
          identifier: 'delivery_details.address',
          button: {
            text: {
              text: 'delivery_details.edit',
              namespace: 'shared',
              translate: true
            },
            onClick: toggleAddressModal
          }
        }
      : {}
  }, [amendable, toggleAddressModal])

  const DeliveryCard: ElementType =
    isBoostedBox && boostedType === MembershipTypes.boost ? BoostCard : IconCard

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <DeliveryCard
          button={
            amendable
              ? {
                  text: {
                    text: 'delivery_details.edit',
                    namespace: 'shared',
                    translate: true
                  },
                  onClick: toggleDeliveryDatesModal
                }
              : undefined
          }
          icon={{
            asset: 'van',
            backgroundColour:
              isBoostedBox && boostedType === MembershipTypes.boost
                ? 'brandWhite'
                : 'brandYellow300'
          }}
          subtext={{
            text:
              isBoostedBox && boostedType === MembershipTypes.boost
                ? 'delivery_details.boost'
                : 'delivery_details.delivery_date',
            variant: isBoostedBox ? 'textRegular16' : undefined,
            bold: isBoostedBox,
            namespace: 'shared'
          }}
          subtextIcon={
            isBoostedBox && boostedType === MembershipTypes.boost
              ? WhiteTick
              : ''
          }
          onClick={amendable ? toggleDeliveryDatesModal : undefined}
          heading={{
            text: formatDate(new Date(isoDeliveryDate), 'iiii dd MMMM', {
              locale: dateLocale
            }),
            translate: false
          }}
          showArrow={false}
          shadow
        />
      </Grid>
      <Grid item xs={12}>
        <IconCard
          icon={{
            asset: 'house',
            accentColour: 'brandBlue500',
            backgroundColour: 'brandYellow300'
          }}
          subtext={{
            text: 'delivery_details.address',
            namespace: 'shared'
          }}
          heading={{
            text: `${addressCopy}`,
            translate: false
          }}
          showArrow={false}
          invert
          shadow
          {...deliveryDetailsProps}
        />
      </Grid>
      {isOutForDelivery && trackingUrl && (
        <Button
          typography={{
            namespace: 'contacts',
            text: 'last_box.trackOrder'
          }}
          disableAnalytics
          onClick={trackOrderClicked}
        />
      )}
      {addressModalIsOpen && (
        <Modal
          setOpenModal={toggleAddressModal}
          isModalOpen={addressModalIsOpen}
          width={600}
          fullHeight={windowWidth < BREAKPOINTS.md}
        >
          <ChangeAddress
            address={address}
            onChange={updateAddress}
            toggleModal={toggleAddressModal}
            shippingCountryCode={shippingCountryCode}
          />
        </Modal>
      )}
      {deliveryDateModalIsOpen && (
        <Modal
          setOpenModal={toggleDeliveryDatesModal}
          isModalOpen={deliveryDateModalIsOpen}
          width={600}
          bottomSticky={windowWidth < BREAKPOINTS.md}
        >
          <Calendar
            selectedDate={isoDeliveryDate}
            deliveryDate={isoDeliveryDate}
            shippingCountryCode={shippingCountryCode}
            setSelectedDate={updateDeliveryDate}
            shouldAttemptToOfferNextDayDelivery={false}
            userId={userId}
          />
          <div className={STYLES.alertCard}>
            <AlertCard
              message={{
                namespace: 'order',
                text: 'date_modal.info',
                margin: false
              }}
              variant="info"
            />
          </div>
        </Modal>
      )}
    </Grid>
  )
}

const MealsHeader = ({
  amendable,
  analyticsOnEditClick,
  onEditModalCloseGoTo
}: MealsHeaderProps): JSX.Element | null => {
  const recipeLinkParams = new URLSearchParams({
    scrollDestination: 'meal-selection',
    openModal: 'meal-selection'
  })
  const navigate = useContext(NavigateContext)

  const onEditRecipes = useCallback(
    (e): void => {
      e.preventDefault()
      analyticsOnEditClick()
      navigate(ACCOUNT_ROUTES.editRecipes, '#')
    },
    [analyticsOnEditClick, navigate]
  )

  if (onEditModalCloseGoTo)
    recipeLinkParams.append('onModalClose', onEditModalCloseGoTo)

  return (
    <div className={STYLES.sectionTitle}>
      <TitleAndMore
        title="recipes.title"
        more="recipes.more"
        href={amendable ? ACCOUNT_ROUTES.editRecipes : undefined}
        namespace="order"
        onClick={onEditRecipes}
        identifier="order_page.recipes.more"
      />
    </div>
  )
}

const Recipes = ({
  pouchSize,
  shippingCountryCode,
  preferredLanguage,
  isNextEditableBox,
  isBoxDelivered,
  namespace,
  boxId
}: RecipeProps): JSX.Element | null => {
  const { loading, data, error } = useQuery<mealsQuery>(MEALS_QUERY, {
    variables: {
      boxId: boxId || ''
    }
  })

  const boxExists = !!data?.user?.subscription?.box

  const recipesInTheBox = data?.user.subscription.box?.meals?.filter(
    ({ quantity }: Meal) => quantity > 0
  )
  const analyticsOnClick = useCallback((): void => {
    segmentTrack('Order Details Page - Edit meals clicked')
  }, [])

  if (error) return null

  return (
    <section className={STYLES.section}>
      {!isNextEditableBox && !isBoxDelivered && boxExists && (
        <div className={STYLES.editInfoAlert}>
          <AlertCard
            message={{
              text: !data.user.subscription.box?.editable
                ? 'recipes.alerts.no_longer_editable'
                : 'recipes.alerts.not_yet_editable',
              margin: false,
              namespace: namespace,
              align: 'left'
            }}
            variant="info"
          />
        </div>
      )}
      <MealsHeader
        amendable={isNextEditableBox}
        analyticsOnEditClick={analyticsOnClick}
      />
      <SkeletonLoading
        contentReady={!loading}
        template={
          <>
            <SkeletonFreshMealItem />
            <SkeletonFreshMealItem />
            <SkeletonFreshMealItem />
          </>
        }
      >
        <FreshMealList
          pouchSize={pouchSize}
          recipes={
            recipesInTheBox?.map((recipe): Recipe => {
              return {
                quantity: recipe.quantity,
                flavour: {
                  ...recipe.flavour,
                  thumbnail: recipe.flavour.thumbnail.src,
                  productLabels: recipe.flavour.productLabels || []
                }
              }
            }) || []
          }
          shippingCountryCode={shippingCountryCode}
          language={preferredLanguage}
          shouldSeeRecipeSurchargeTooltips={
            data?.shouldSeeRecipeSurchargeTooltips
              ? JSON.parse(data.shouldSeeRecipeSurchargeTooltips)
              : false
          }
          shouldSeeRecipeSurchargePriceIncreasePl={
            data?.shouldSeeRecipeSurchargePriceIncreasePl
              ? JSON.parse(data.shouldSeeRecipeSurchargePriceIncreasePl)
              : false
          }
        />
      </SkeletonLoading>
    </section>
  )
}

const Shop = ({
  products,
  amendable,
  currencyOptions,
  boxId,
  isBoostedBox,
  onExtrasModalClose
}: ShopSectionProps) => {
  const navigate = useContext(NavigateContext)
  const alertComponentRef = useRef<HTMLInputElement | undefined>()
  const extrasSectionRef = useRef<HTMLInputElement | undefined>()
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  // Fix bug where if user tries to add more than the allowed amount of extras
  // the shop UI would not update
  useEffect(() => {
    if (!modalOpen) onExtrasModalClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen])

  useEffect((): void => {
    /**
     * Listen out for URL parameters and scroll the customer to the corresponding
     * section once the React App loads
     */
    if (alertComponentRef.current) {
      const hash = window.location.hash
      const el = hash && document.getElementById(hash.slice(1))
      if (el) {
        const elementOffset = el.getBoundingClientRect().height
        const elementTop =
          el.getBoundingClientRect().top +
          window.pageYOffset -
          elementOffset * 2
        window.scrollTo({ top: elementTop, behavior: 'smooth' })
      }
    }
  }, [alertComponentRef])

  useEffect((): void => {
    if (extrasSectionRef.current) {
      const hash = window.location.hash
      const el = hash && document.getElementById(hash.slice(1))
      if (el) scrollToElement(el as HTMLElement)
    }
  }, [extrasSectionRef])

  const oneOffProductQuantity = localStorage.getItem('oneOffProductQuantity')
  const parseProductQuantity =
    oneOffProductQuantity && JSON.parse(oneOffProductQuantity)
  const [productQuantity, setProductQuantity] = useState<number>(
    parseInt(parseProductQuantity) || 0
  )

  useEffect((): void => {
    setTimeout((): void => {
      localStorage.setItem('oneOffProductQuantity', JSON.stringify(0))
      setProductQuantity(0)
    }, 4000)
  }, [])

  const openModal = useCallback(
    (event): void => {
      event.preventDefault()
      segmentTrack('Order Details Page - Edit shop items clicked')

      return navigate(ACCOUNT_ROUTES.editExtras + '/' + boxId, '#')
    },
    [boxId, navigate]
  )

  return (
    <>
      {products.length > 0 && (
        <section
          className={STYLES.section}
          id="extras"
          ref={extrasSectionRef as React.RefObject<HTMLDivElement>}
        >
          {productQuantity > 0 && (
            <div
              className={STYLES.alertCard}
              id="alert"
              ref={alertComponentRef as React.RefObject<HTMLDivElement>}
            >
              <AlertCard
                message={{
                  text: 'extras.basket.basket_card.title_one_off',
                  namespace: 'dashboard',
                  margin: false,
                  variables: { count: productQuantity }
                }}
                variant="success"
              />
            </div>
          )}
          <div className={STYLES.sectionTitle}>
            <TitleAndMore
              title="shop.title"
              more="shop.more"
              href={amendable ? '#' : undefined}
              namespace="order"
              onClick={amendable ? openModal : undefined}
              identifier="order_page.shop.more"
            />
          </div>
          {products.map((product) => {
            const transformedProduct = transformExtra(product, currencyOptions)

            return (
              <ShopItem
                key={`${kebabCase(transformedProduct.productName)}-${
                  transformedProduct.sizeDescription
                }`}
                {...transformedProduct}
                isBoostedBox={isBoostedBox}
              />
            )
          })}
        </section>
      )}
      <ExtrasModal
        boxId={boxId}
        setOpenModal={setModalOpen}
        isModalOpen={modalOpen}
      />
    </>
  )
}

const updateDeliveryAddress = (
  userId: string,
  boxId: number,
  address: Address,
  mutation: (
    options?: MutationFunctionOptions | undefined
  ) => Promise<FetchResult>,
  onSuccess?: (data: Address) => void
) => {
  mutation({
    variables: {
      userId: userId,
      boxId: boxId,
      address: {
        recipientName: address.recipientName,
        addressLineOne: address.address1,
        addressLineTwo: address.address2,
        city: address.city,
        postcode: address.postcode,
        deliveryNotes: address.deliveryNotes
      }
    }
  }).then((results) => {
    if (!isUndefined(onSuccess))
      onSuccess(results.data?.boxAddressUpdate.subscription.box.address)
  })
}

const updateDeliveryDates = (
  userId: string,
  boxId: number,
  date: string,
  mutation: (
    options?: MutationFunctionOptions | undefined
  ) => Promise<FetchResult>,
  onSuccess?: (data: BoxDeliveryInfo) => void
) => {
  mutation({
    variables: {
      userId: userId,
      boxId: boxId,
      selectedDeliveryDate: date,
      adjustFutureBoxDates: true,
      triggerEvents: true
    }
  }).then((results) => {
    if (!isUndefined(onSuccess))
      onSuccess(results.data?.boxDeliveryDateUpdate?.subscription.box)
  })
}

const OrderPage = (): JSX.Element | null => {
  const namespace = 'order'
  const { windowWidth } = useWindowSize()
  const { boostedType } = useButternutBoost()
  const { boxId: unparsedBoxId } = useParams()
  const boxId = unparsedBoxId ? parseInt(unparsedBoxId) : undefined
  const [deliveryDate, setDeliveryDate] = useState<Date>()
  const [cutoffDate, setCutoffDate] = useState<Date>()
  const [address, setAddress] = useState<Address>()
  const [boxDeliveryDateUpdate] = useMutation(BOX_DELIVERY_DATE_UPDATE)
  const [boxAddressUpdate] = useMutation(BOX_DELIVERY_ADDRESS_UPDATE)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const csrfTokenValue = useCsrfToken().csrfToken

  const search = window.location.search
  const productAddedConfirmation =
    new URLSearchParams(search).get('product_added_confirmation') === 'true'
  const errorMessage = new URLSearchParams(search).get('error')

  const { xmas } = useOccasion()

  React.useEffect(() => {
    if (productAddedConfirmation) {
      modalData({
        isOpen: true,
        namespace: 'order',
        text: 'confirmation_modal.add_product',
        delay: 3000
      })
    } else if (!productAddedConfirmation && errorMessage) {
      toast.error(
        <NotificationContent
          copy={{
            text: errorMessage,
            translate: false
          }}
        />,
        {
          icon: (
            <Icon size={20} asset="errorCircle" accentColour="dangerRed300" />
          )
        }
      )
    }
  }, [errorMessage, productAddedConfirmation])

  if (isUndefined(boxId)) {
    Sentry.captureException('Could not get boxId param from URL')
    throw new Error('Could not get boxId param from URL') // TEMP
  }

  const { loading, data, error, refetch } = useQuery<boxQuery>(BOX_QUERY, {
    variables: {
      boxId: boxId || ''
    }
  })

  useEffect(() => {
    if (data?.user?.subscription?.box) {
      const { isoDeliveryDate, cutOffDate, address } =
        data?.user?.subscription?.box

      setDeliveryDate(new Date(isoDeliveryDate))
      setCutoffDate(new Date(cutOffDate))
      setAddress(address)
    }
  }, [data])

  const handleDeliveryDate = useCallback(
    (
      userId: string,
      boxId: number,
      date: string,
      onSuccess?: (boxInfo: BoxDeliveryInfo) => void
    ) => {
      updateDeliveryDates(
        userId,
        boxId,
        date,
        boxDeliveryDateUpdate,
        (boxInfo: BoxDeliveryInfo) => {
          if (!isUndefined(onSuccess)) onSuccess(boxInfo)
          setDeliveryDate(new Date(boxInfo.isoDeliveryDate))
          setCutoffDate(new Date(boxInfo.cutOffDate))
        }
      )
    },
    [boxDeliveryDateUpdate]
  )

  const handleAddress = useCallback(
    (
      userId: string,
      boxId: number,
      address: Address,
      onSuccess?: (newAddress: Address) => void
    ) => {
      updateDeliveryAddress(
        userId,
        boxId,
        address,
        boxAddressUpdate,
        (newAddress: Address) => {
          if (!isUndefined(onSuccess)) onSuccess(newAddress)
          setAddress(newAddress)
        }
      )
    },
    [boxAddressUpdate]
  )

  if (error) {
    Sentry.captureException(error)
  }

  if (
    loading ||
    !data ||
    isNull(data.user.subscription.box) ||
    isUndefined(deliveryDate) ||
    isUndefined(address) ||
    isUndefined(cutoffDate)
  ) {
    return <OrderPageSkeleton />
  }

  const { user } = data

  const {
    shippingCountryCode,
    preferredLanguage,
    subscription,
    dogs,
    id,
    shippingCountry
  } = user

  const { upsellableSampleProduct, box } = subscription
  const { boostedBox } = box || {}
  const { showUpcomingOrderSeeMoreExtras, showExtras } = shippingCountry
  const isBoostedBox = !!boostedBox

  if (isNull(subscription) || isNull(subscription.box) || isNull(dogs))
    return null

  const dogGenders: Dog['gender'][] = dogs.map((dog) => dog.gender)
  const dogPronoun = pronounContext(dogGenders, preferredLanguage)

  const currencyOptions: Options = countryCodeToLocaleCurrency(
    shippingCountryCode,
    preferredLanguage
  )

  const { physicalOrderProducts, amendable, consignment, descriptor } =
    subscription.box

  const isNextEditableBox: boolean =
    subscription.nextNBoxes.find(
      ({ id }: boxQuery_user_subscription_nextNBoxes): boolean =>
        id === subscription.box?.id
    )?.isNextEditableBox || false

  return (
    <>
      <Base background={xmas ? 'transparent' : 'brandYellow100'}>
        <Container
          disableGutters
          className={isBoostedBox ? STYLES.boostHeader : undefined}
        >
          <Grid container spacing={3}>
            <Grid item xs={isBoostedBox ? 7 : 12}>
              <OrderHeader
                deliveryDate={deliveryDate}
                cutOffDate={cutoffDate}
                shippingCountryCode={shippingCountryCode}
                preferredLanguage={preferredLanguage}
                shouldOfferSelfService={subscription.box.shouldOfferSelfService}
                boxId={boxId}
                isBoxDelivered={isBoxDelivered(subscription.box as Box)}
                amendable={amendable}
                deliveryStatus={consignment?.status || ''}
                descriptor={descriptor}
              />
            </Grid>
            {isBoostedBox && boostedType === MembershipTypes.boost && (
              <Grid
                container
                item
                xs={5}
                justifyContent="flex-end"
                alignItems="flex-start"
              >
                <div className={STYLES.boostLogo}>
                  <AnimatedBoostLogo
                    showSign
                    width={windowWidth <= BREAKPOINTS.md ? 115 : 180}
                  />
                </div>
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <DeliveryDetailsSection
                    shippingCountryCode={shippingCountryCode}
                    preferredLanguage={preferredLanguage}
                    address={address}
                    isoDeliveryDate={deliveryDate}
                    userId={data.user.id}
                    boxId={boxId}
                    isBoostedBox={isBoostedBox}
                    amendable={subscription.box.amendable}
                    isNextEditableBox={isNextEditableBox}
                    handleDeliveryDate={handleDeliveryDate}
                    handleAddress={handleAddress}
                    showUpcomingOrderSeeMoreExtras={
                      showUpcomingOrderSeeMoreExtras
                    }
                    trackingUrl={
                      subscription.box.consignment
                        ? subscription.box.consignment.trackingUrl
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <RecipeSelection
                    box={subscription.box}
                    boxId={boxId}
                    shippingCountryCode={shippingCountryCode}
                    preferredLanguage={preferredLanguage}
                    isNextEditableBox={isNextEditableBox}
                  />
                  <Shop
                    products={physicalOrderProducts}
                    amendable={isNextEditableBox}
                    currencyOptions={currencyOptions}
                    possessivePronoun={dogPronoun}
                    userId={id}
                    boxId={boxId.toString()}
                    isBoostedBox={isBoostedBox}
                    namespace={namespace}
                    onExtrasModalClose={refetch}
                  />
                  {upsellableSampleProduct && showExtras && (
                    <div className={STYLES.section}>
                      <AdditionalProductUpsellable
                        product={upsellableSampleProduct}
                        shippingCountryCode={shippingCountryCode}
                        preferredLanguage={preferredLanguage}
                        dogs={dogs}
                        userId={id}
                        refetchQuery={refetch}
                        deliveryType={VariantDelivery.recurring}
                        boosted={isBoostedBox}
                      />
                    </div>
                  )}
                  {isNextEditableBox && showExtras && !isBoostedBox && (
                    <section className={STYLES.section}>
                      <AddMore link={ACCOUNT_ROUTES.extras} />
                    </section>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <section className={`${STYLES.section}`}>
                <PriceBreakdownWithQuery
                  boxId={boxId}
                  csrfToken={csrfTokenValue}
                />
              </section>
            </Grid>
          </Grid>
        </Container>
      </Base>
      <ConfirmationModal />
      <NotificationContainer autoClose={5000} />
    </>
  )
}

export {
  getShopItemTotal,
  constructUpdatedAddressObject,
  UpdatedAddressFields,
  Recipes,
  MealsHeader
}

export default OrderPage
