import isNull from 'lodash/isNull'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import STYLES from './PasswordField.module.sass'

import { Button } from '../../atoms/Button'
import Icon from '../../atoms/Icon/Icon'
import Text from '../../atoms/Text/Text'
import TextField, {
  Props as TextFieldProps
} from '../../atoms/TextField/TextField'

type Props = Pick<TextFieldProps, 'onChange' | 'onValidate'> & {
  variant?: 'password' | 'confirmPassword'
  password?: string | null
}

type PasswordDisplay = {
  state: 'show' | 'hide'
  type: 'password' | 'text'
  color: 'brandBlue500' | 'grey500'
  text: string
}

const PasswordField = ({
  variant = 'password',
  password = null,
  onChange,
  onValidate
}: Props): JSX.Element => {
  const [requiredLengthReached, setRequiredLengthReached] = useState(false)
  const [fieldValue, setFieldValue] = useState<string | null>(null)
  const [isValid, setIsValid] = useState<boolean>(true)
  const [passwordDisplay, setShowPassword] = useState<PasswordDisplay>({
    state: 'hide',
    type: 'password',
    color: 'grey500',
    text: 'show_password'
  })

  const togglePasswordVisibility = useCallback(() => {
    setShowPassword((prev) => {
      if (prev.type === 'password') {
        return {
          state: 'show',
          type: 'text',
          color: 'brandBlue500',
          text: 'hide_password'
        }
      } else {
        return {
          state: 'hide',
          type: 'password',
          color: 'grey500',
          text: 'show_password'
        }
      }
    })
  }, [])

  const minimumLengthValidation = useCallback((value: string): boolean => {
    return value.length >= 8
  }, [])

  const passwordMatchValidation = useCallback(
    (value: string): boolean => {
      return !isNull(password) && value === password
    },
    [password]
  )

  const checkForRequiredLength = useCallback(
    (value: string) => {
      if (variant === 'password') {
        setRequiredLengthReached(minimumLengthValidation(value))
      }
      setFieldValue(value)
      onChange && onChange(value)
    },
    [minimumLengthValidation, onChange, variant]
  )

  const additionalValidation = useCallback(
    (value: string): boolean => {
      if (variant === 'confirmPassword') {
        return passwordMatchValidation(value)
      } else {
        return minimumLengthValidation(value)
      }
    },
    [minimumLengthValidation, passwordMatchValidation, variant]
  )

  const errorMessage = useMemo(() => {
    return variant === 'confirmPassword' ? 'no_match' : 'required_length'
  }, [variant])

  useEffect(() => {
    if (
      variant === 'confirmPassword' &&
      !isNull(fieldValue) &&
      !isNull(password)
    ) {
      setIsValid(passwordMatchValidation(fieldValue))
    }
  }, [fieldValue, password, passwordMatchValidation, variant])

  return (
    <div className={STYLES.container}>
      <TextField
        label={variant}
        initialValue={''}
        type={passwordDisplay.type}
        isValid={isValid}
        onChange={checkForRequiredLength}
        onValidate={onValidate}
        additionalValidation={additionalValidation}
        errorMessage={errorMessage}
        successState={false}
      />
      <div
        className={`${STYLES.passwordDisplayToggle} ${
          STYLES[passwordDisplay.state]
        }`}
      >
        <Button
          typography={{
            namespace: 'text_field',
            text: passwordDisplay.text
          }}
          variant={'ghost'}
          onClick={togglePasswordVisibility}
          icon={{
            position: 'left',
            component: (
              <Icon
                asset="eye"
                size={15}
                width={23}
                accentColour={passwordDisplay.color}
              />
            )
          }}
          displayText="never"
          disableAnalytics
        />
      </div>
      {requiredLengthReached && (
        <div className={STYLES.validationMessage}>
          <div>
            <Icon
              asset={'checkmark'}
              size={12}
              width={12}
              accentColour={'successGreen300'}
            />
          </div>
          <Text
            variant={'textRegular12'}
            namespace={'text_field'}
            text={'required_length_reached'}
            colour={'brandBlue400'}
            margin={false}
          />
        </div>
      )}
    </div>
  )
}

export { Props }
export default PasswordField
