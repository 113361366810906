// @noflow
import {
  Stripe,
  StripeCardElement,
  StripeElements,
  PaymentMethod as StripePaymentMethod
} from '@stripe/stripe-js'
import i18next from 'i18next'

import * as Sentry from '@/utils/sentry'

import * as ANALYTICS from '../../../Analytics/CheckoutAnalytics'
import { checkoutPageState } from '../../../CheckoutPage'
import type { PaymentMethod } from '../../../types'
import { bannerMessageState } from '../Banner'
import { setSubmissionState } from '../PaymentSection'
import { chargeCardBoxOne } from './chargeBoxOne'
import { submitSubscription, subscriptionData } from './submitSubscription'
import type { SubscriptionData } from './submitSubscription'

const createPaymentMethod = async (
  stripe: Stripe,
  cardElement: StripeCardElement
) => {
  return await stripe.createPaymentMethod({
    type: 'card',
    card: cardElement
  })
}

const handleSubmitSubscription = ({
  data,
  csrfToken,
  paymentMethodId,
  paymentMethodType,
  boxOneChargeId
}: {
  data: SubscriptionData
  csrfToken: string
  paymentMethodId: string | StripePaymentMethod
  paymentMethodType: PaymentMethod
  boxOneChargeId?: string
}): void => {
  submitSubscription({ data, csrfToken, paymentMethodId, boxOneChargeId })
    .then((response) => {
      if (response.error) {
        bannerMessageState({
          message: response.error,
          type: 'error'
        })

        setSubmissionState({
          type: 'error',
          error: response.error
        })

        ANALYTICS.setPaymentError({
          error: response.error,
          paymentMethod: paymentMethodType
        })
      }
      return response
    })
    .then(({ redirect_to }) => {
      ANALYTICS.setPaymentComplete()
      if (redirect_to) {
        window.location.href = redirect_to
      }
    })
}

const buySubscriptionWithCard = ({
  stripe,
  elements,
  csrfToken
}: {
  stripe: Stripe
  elements: StripeElements
  csrfToken: string
}): void => {
  const checkoutData = checkoutPageState()
  const requiresPayment = checkoutData.user.requiresPaymentDetailsOnCheckout

  const cardElement = elements?.getElement('card')

  if (!cardElement && requiresPayment)
    Sentry.captureException('Could not find card element at checkout', {
      tags: {
        product: Sentry.Product.Checkout
      }
    })

  // Disable the Place my order button
  setSubmissionState({ type: 'loading' })

  // Reset the error banner if it's showing
  bannerMessageState({ message: null, type: 'error' })

  // Ambassador/Influencer flow
  if (!cardElement) {
    return handleSubmitSubscription({
      data: subscriptionData({ state: checkoutData }),
      csrfToken,
      paymentMethodId: '',
      paymentMethodType: 'creditCard'
    })
  }

  if (checkoutData.user.shouldSupportSca) {
    // SCA flow
    const data = subscriptionData({ state: checkoutData })
    const email = encodeURIComponent(
      checkoutData.sections.accountDetails.form.email.value
    )

    chargeCardBoxOne({ stripe, cardElement, email, data, csrfToken })
  } else {
    // Non SCA Flow
    createPaymentMethod(stripe, cardElement).then(
      ({ error, paymentMethod }) => {
        if (error) {
          bannerMessageState({
            message:
              error.message ||
              i18next.t('checkout:errors.delivery_details_fetch'),
            type: 'error'
          })

          setSubmissionState({
            type: 'error',
            error:
              error.message ||
              i18next.t('checkout:errors.delivery_details_fetch')
          })

          ANALYTICS.setPaymentError({
            error: error.message || 'Checkout Error in createPaymentMethod',
            paymentMethod: 'creditCard'
          })
        }

        if (paymentMethod) {
          const data = subscriptionData({ state: checkoutData })

          handleSubmitSubscription({
            data,
            csrfToken,
            paymentMethodId: paymentMethod.id,
            paymentMethodType: 'creditCard'
          })
        }
      }
    )
  }
}

export default buySubscriptionWithCard

export { handleSubmitSubscription }
