// @noflow
import * as Cookies from 'js-cookie'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from 'assets/images/icons/crosses/close--blue.svg'

import type { Status } from '@/shared_types/rails_models/subscription'

type SubscriberHasMultipleUnspentReactivationDiscounts = {
  reason: 'subscriber_has_multiple_unspent_reactivation_discounts'
  data: {
    applicable_discount_part_ids: Array<string>
  }
}

type ReactivationCampaignNotActive = {
  reason: 'reactivation_campaign_not_active'
  data: {
    is_active: boolean
    expiry_date: string
  }
}

type ReactivationCampaignNotLiveInCountry = {
  reason: 'reactivation_campaign_not_live_in_user_country'
  data: {
    user_shipping_country_code: string
    campaign_shipping_country_codes: Array<string>
  }
}

type PauseDurationRequirementsUnmet = {
  reason: 'pause_duration_requirements_unmet'
  data: {
    subscription_status: Status
    last_pause_date: string
    min_days_since_last_pause: number
  }
}

type RedeemedReactivationDiscountTooRecently = {
  reason: 'redeemed_reactivation_discount_too_recently'
  data: {
    most_recent_acceptable_last_redemption_date: string
    last_redemption_date: string
  }
}

type HasPreviouslyRedeemedDiscount = {
  reason: 'has_previously_redeemed_discount'
  data: {
    applicable_discount_part_id: string
  }
}

type Reason =
  | SubscriberHasMultipleUnspentReactivationDiscounts['reason']
  | ReactivationCampaignNotActive['reason']
  | ReactivationCampaignNotLiveInCountry['reason']
  | PauseDurationRequirementsUnmet['reason']
  | RedeemedReactivationDiscountTooRecently['reason']
  | HasPreviouslyRedeemedDiscount['reason']

type InvalidityReason =
  | SubscriberHasMultipleUnspentReactivationDiscounts
  | ReactivationCampaignNotActive
  | ReactivationCampaignNotLiveInCountry
  | PauseDurationRequirementsUnmet
  | RedeemedReactivationDiscountTooRecently
  | HasPreviouslyRedeemedDiscount

type ReactivationDiscountCookie = {
  success: boolean
  invalidity_reasons: Array<InvalidityReason>
}

type Props = {
  reason: Reason
  subscriptionStatus: Status
}

const ReactivationCampaignError = ({
  reason,
  subscriptionStatus
}: Props): React.ReactElement => {
  const { t } = useTranslation('dashboard')
  const copyContext = 'reactivation.reactivation_campaign_error'
  const [bannerShown, setBannerShown] = React.useState<boolean>(true)

  const closeBanner = React.useCallback((): void => {
    return setBannerShown(!bannerShown)
  }, [bannerShown])

  const reasonToHumanReadableReason = (reason: Reason): string => {
    switch (reason) {
      case 'reactivation_campaign_not_active':
      case 'pause_duration_requirements_unmet':
      case 'reactivation_campaign_not_live_in_user_country':
      case 'redeemed_reactivation_discount_too_recently': {
        return subscriptionStatus === 'active'
          ? t(`${copyContext}.active`)
          : t(`${copyContext}.inactive`)
      }
      default: {
        throw new Error(
          `Unhandled reason given to reasonToHumanReadableReason. Reason: ${reason}`
        )
      }
    }
  }

  React.useEffect((): void => {
    // Scroll the user to the top of the screen so they see the banner
    window.scrollTo({ top: 0, behavior: 'smooth' })

    // To prevent the banner from showing multiple times, we remove the cookie
    // from the browser once the component mounts
    Cookies.remove('reactivation_discount_redemption_attempted')
  }, [])

  return bannerShown ? (
    <div className="reactivation-campaign-error">
      <div className="reactivation-campaign-error__inner">
        <p>{reasonToHumanReadableReason(reason)}</p>
        <button onClick={closeBanner} type="button">
          <img alt="" src={CloseIcon} />
        </button>
      </div>
    </div>
  ) : (
    <div />
  )
}

export default ReactivationCampaignError

export type { ReactivationDiscountCookie, InvalidityReason, Reason }
