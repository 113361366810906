// @noflow
import {
  Stripe,
  StripeElements,
  PaymentMethod as StripePaymentMethod
} from '@stripe/stripe-js'
import i18next from 'i18next'

import * as Sentry from '@/utils/sentry'

import * as ANALYTICS from '../../../Analytics/CheckoutAnalytics'
import { checkoutPageState } from '../../../CheckoutPage'
import type { PaymentMethod } from '../../../types'
import { bannerMessageState } from '../Banner'
import { setSubmissionState } from '../PaymentSectionWithStripe'
import chargeStripeBoxOne from './chargeStripeBoxOne'
import { submitSubscription, subscriptionData } from './submitSubscription'
import type { SubscriptionData } from './submitSubscription'

const handleSubmitSubscription = async ({
  data,
  csrfToken,
  paymentMethodId,
  paymentMethodType,
  boxOneChargeId
}: {
  data: SubscriptionData
  csrfToken: string
  paymentMethodId: string | StripePaymentMethod
  paymentMethodType: PaymentMethod
  boxOneChargeId?: string
}): Promise<void> => {
  try {
    const response = await submitSubscription({
      data,
      csrfToken,
      paymentMethodId,
      boxOneChargeId
    })

    if (response.error) {
      bannerMessageState({
        message: response.error,
        type: 'error'
      })

      setSubmissionState({
        type: 'error',
        error: response.error
      })

      ANALYTICS.setPaymentError({
        error: response.error,
        paymentMethod: paymentMethodType
      })
    }

    ANALYTICS.setStripePaymentComplete(paymentMethodType)

    if (response.redirect_to) {
      window.location.href = response.redirect_to
    }
  } catch (error) {
    Sentry.captureException('Error occurred in submitSubscription', {
      extra: {
        message: error
      },
      tags: {
        product: Sentry.Product.Checkout
      }
    })
    bannerMessageState({
      message: i18next.t('checkout:errors.delivery_details_fetch'),
      type: 'error'
    })
  }
}

const buySubscriptionWithStripe = ({
  stripe,
  elements,
  csrfToken
}: {
  stripe: Stripe
  elements: StripeElements
  csrfToken: string
}): void => {
  const checkoutData = checkoutPageState()
  const requiresPayment = checkoutData.user.requiresPaymentDetailsOnCheckout
  const data = subscriptionData({ state: checkoutData })

  if (!elements && requiresPayment) {
    Sentry.captureException('Could not find PaymentElements at checkout', {
      tags: {
        product: Sentry.Product.Checkout
      }
    })
  }

  // Disable the Payment button
  setSubmissionState({ type: 'loading' })

  // Reset the error banner if it's showing
  bannerMessageState({ message: null, type: 'error' })

  // Ambassador/Influencer flow
  if (!elements) {
    handleSubmitSubscription({
      data,
      csrfToken,
      paymentMethodId: '',
      paymentMethodType: 'creditCard'
    })
  }

  if (checkoutData.user.shouldSupportSca) {
    const paymentMethod =
      checkoutData.sections.paymentDetails.form.selectedPaymentMethod.type

    chargeStripeBoxOne({ stripe, elements, paymentMethod, data, csrfToken })
  }
}

export default buySubscriptionWithStripe

export { handleSubmitSubscription }
