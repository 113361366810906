import { ApolloError } from '@apollo/client'
import { isObject, isString } from 'lodash'
import React from 'react'

import { ErrorState } from '@/components/elements/organisms/ErrorState'
import { Screen } from '@/components/templates/Screen'

type Props = {
  error?: ApolloError | string
}
const ErrorPage = ({ error }: Props): JSX.Element => {
  const errorDetails = isObject(error)
    ? {
        name: 'Error page displayed',
        message: error.message,
        apollo: error
      }
    : isString(error)
    ? {
        name: 'Error page displayed',
        message: error
      }
    : {
        name: 'Error page displayed',
        message: 'Unknown error'
      }

  return (
    <Screen
      header={{
        title: {
          text: 'error_state.error_other.title',
          namespace: 'account'
        }
      }}
      documentTitleKey="error_page"
      hideNavigationTabBar
    >
      <ErrorState error={errorDetails} />
    </Screen>
  )
}

export default ErrorPage
