// @noflow
import {
  useLanguage,
  useShippingCountry
} from '@/context/injectedValues/injectedValues'
import { useNotifications } from '@/context/notifications/notifications'
import { ACCOUNT_ROUTES } from '@/routes'
import { useReactiveVar } from '@apollo/client'
import isNil from 'lodash/isNil'
import isUndefined from 'lodash/isUndefined'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { SwiperClass } from 'swiper/react'

import { dogsDataVar } from '@/services/apollo'

import {
  UPDATED_PLAN_DURATION,
  UPDATED_PORTION_SIZE
} from '@/constants/RibbonEvents'

import { UsePlanQuery_user_subscription_planOptions } from '@/hooks/usePlan/__generated__/UsePlanQuery'
import usePlanOptions from '@/hooks/usePlan/usePlan'
import type { Plan, RecurringOrder } from '@/hooks/usePlan/usePlan'

import { Wiggle } from '@/components/elements/atoms/Animated/Animated'
import { Button } from '@/components/elements/atoms/Button'
import { CardSkeleton } from '@/components/elements/atoms/Card/CardSkeleton'
import { FixedBase } from '@/components/elements/atoms/FixedBase'
import Image from '@/components/elements/atoms/Image/Image'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import Separator from '@/components/elements/atoms/Separator/Separator'
import SpeechBubble from '@/components/elements/atoms/SpeechBubble/SpeechBubble'
import Text from '@/components/elements/atoms/Text/Text'
import BoxBreakdown from '@/components/elements/molecules/BoxBreakdown/BoxBreakdown'
import PillGroup from '@/components/elements/molecules/PillGroup/PillGroup'
import SwiperSlider from '@/components/elements/molecules/SwiperSlider/SwiperSlider'
import { BoxPriceBreakdown } from '@/components/elements/organisms/BoxPriceBreakdown/BoxPriceBreakdown'
import { howToFeedCopy } from '@/components/elements/organisms/HowToFeedCard/HowToFeedCard'
import { ClientInitQuery_user_dogs as Dog } from '@/components/pages/App/queries/__generated__/ClientInitQuery'
import { DogAvatarImage } from '@/components/pages/DogProfile/components/SelectableDogAvatar/DogAvatarImage'

import STYLES from './UpdatePlanPage.module.sass'

const namespace = 'account'

type PlanOverviewProp = {
  dogs: Dog[]
  pronoun: string
  plan: Plan
  recurringOrder: RecurringOrder
  savings?: number
}

/**
 * Note - copied across from old logic
 * @param plan
 */
const showHowToFeedSection = (plan: Plan) =>
  plan.servingSize !== 800 &&
  plan.servingSize !== 1000 &&
  plan.servingSize !== 1200 &&
  (plan.numberOfPouches / plan.durationInDays === 1 ||
    plan.numberOfPouches / plan.durationInDays === 2)

/**
 * Display information related to a plan. Show in the old and new tabs so users can compare what has changed
 * @param dogs
 * @param plan
 * @param recurringOrder
 * @param savings
 * @constructor
 */
const PlanOverview = ({
  dogs,
  pronoun,
  plan,
  recurringOrder,
  savings
}: PlanOverviewProp) => {
  const { shippingCountry } = useShippingCountry()
  const { userLanguage } = useLanguage()

  const {
    gramsPerDay,
    gramsPerDayPerDogs,
    typeOfPlanForCustomer,
    pricePerDay,
    price,
    pouchSize,
    numberOfPouches,
    durationInDays
  } = plan
  const { deliveryFee, physicalProductsTotal, surchargeTotal } = recurringOrder

  const dogsById: Record<string, Dog> = useMemo(
    () =>
      dogs.reduce(function (map: Record<string, Dog>, obj) {
        map[obj.id] = obj
        return map
      }, {}),
    [dogs]
  )

  return (
    <SectionWrapper
      padding={32}
      bgColour="brandWhite90"
      borderRadius={16}
      margin={0}
    >
      <div className={STYLES.inner}>
        <div className={STYLES.overview}>
          <div className={STYLES.recommendation}>
            <div>
              <div className={STYLES.grams}>
                <Text
                  text="plan_review.grams_per_day"
                  variables={{
                    grams: gramsPerDay
                  }}
                  variant="display24"
                  namespace={namespace}
                  element="div"
                />
              </div>
              <Text
                text={`shared.serving_type.${typeOfPlanForCustomer}.title`}
                variables={{
                  context: pronoun
                }}
                variant="textRegular18"
                namespace={namespace}
                element="div"
              />
            </div>
            <Image
              className={STYLES.portion}
              alt=""
              slug={`plan-size-as-portions-${typeOfPlanForCustomer}-butternut`}
              image={{
                height: 300,
                width: 300
              }}
            />
          </div>
          <div className={STYLES.coverage}>
            <SpeechBubble
              footPosition="topLeft"
              text={{
                text: `shared.serving_type.${typeOfPlanForCustomer}.description`,
                variables: { context: pronoun },
                namespace
              }}
            />
          </div>
        </div>
        {dogs.length > 1 && (
          <>
            <Separator handdrawn />
            <ul className={STYLES.dogs}>
              {gramsPerDayPerDogs.map((dog) => (
                <li key={dog.name} className={STYLES.dog}>
                  <div className={STYLES.avatar}>
                    <DogAvatarImage dog={dogsById[dog.dogId]} />
                  </div>
                  <Text
                    text={`plan_review.dog_grams_per_day`}
                    variant="textRegular16"
                    namespace={namespace}
                    element="div"
                    variables={{
                      name: dog.name,
                      grams: dog.gramsPerDay
                    }}
                  />
                </li>
              ))}
            </ul>
          </>
        )}
        {!showHowToFeedSection(plan) && (
          <div>
            <Separator handdrawn />
            <Text
              variant="display16"
              text="plan_review.how_to_feed"
              namespace={namespace}
              element="h3"
              align="left"
            />
            <Text
              text={howToFeedCopy(dogs.length, typeOfPlanForCustomer)}
              translate={false}
              element="div"
              align="left"
            />
          </div>
        )}
        <Separator handdrawn />
        <BoxBreakdown
          pouchSize={pouchSize}
          pouches={numberOfPouches}
          cadence={durationInDays / 7}
        />
        <Separator handdrawn />
        <BoxPriceBreakdown
          meals={[
            {
              perDay: pricePerDay,
              total: price
            }
          ]}
          surchargeTotal={surchargeTotal}
          extras={physicalProductsTotal}
          deliveryFee={deliveryFee}
          shippingCountryCode={shippingCountry}
          preferredLanguage={userLanguage}
          discount={savings}
        />
      </div>
    </SectionWrapper>
  )
}

const SkeletonLoading = () => (
  <div className={STYLES.skeleton}>
    <CardSkeleton height={200} />
    <CardSkeleton height={1000} />
    <CardSkeleton height={200} />
  </div>
)

const triggerPortionSizeSurvey = (
  newPortionSize?: number,
  oldPortionSize?: number
): void => {
  if (newPortionSize !== oldPortionSize)
    window.ribbon && window.ribbon('trigger', UPDATED_PORTION_SIZE)
}

const triggerDurationSurvey = (
  newDuration?: number,
  oldDuration?: number
): void => {
  if (newDuration !== oldDuration)
    window.ribbon && window.ribbon('trigger', UPDATED_PLAN_DURATION)
}

/**
 * Allows the user to compare their current plan against another plan and swap to the new plan
 * If no specific plan is passed via URL parameters then compare against the ideal plan for the user
 * @constructor
 */
const UpdatePlanPage = (): JSX.Element | null => {
  const container = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const { dogs, pronoun } = useReactiveVar(dogsDataVar) || {}
  const { planId } = useParams()
  const { setErrorNotification } = useNotifications()
  const [swiperIndex, setSwiperIndex] = useState<number>(1)
  const [newPlan, setNewPlan] = useState<
    UsePlanQuery_user_subscription_planOptions | undefined
  >()

  const {
    setPlan,
    getPlan,
    currentPlan,
    idealPlan,
    recurringOrder,
    canChangeDeliveryFrequency,
    updating
  } = usePlanOptions()
  const [swiper, setSwiper] = useState<SwiperClass>()

  const updatePlan = useCallback(() => {
    triggerPortionSizeSurvey(newPlan?.servingSize, currentPlan?.servingSize)
    triggerDurationSurvey(newPlan?.durationInDays, currentPlan?.durationInDays)
    if (
      !isUndefined(newPlan) &&
      !isUndefined(setPlan) &&
      !isUndefined(currentPlan)
    ) {
      const redirectURL = `${ACCOUNT_ROUTES.planConfirmation}/${currentPlan.id}`

      setPlan(newPlan.id)
        .then(() => {
          triggerPortionSizeSurvey(
            newPlan?.servingSize,
            currentPlan?.servingSize
          )
          triggerDurationSurvey(
            newPlan?.durationInDays,
            currentPlan?.durationInDays
          )
          navigate(redirectURL)
        })
        .catch(() => {
          setErrorNotification({
            text: 'serving_size.notifications.error',
            namespace
          })
        })
    }
  }, [newPlan, currentPlan, setPlan, navigate, setErrorNotification])

  useEffect(() => {
    if (isUndefined(currentPlan) || isUndefined(newPlan)) return
    if (currentPlan.id === newPlan.id) navigate(ACCOUNT_ROUTES.planConfirmation)
  }, [currentPlan, navigate, newPlan])

  const cancel = useCallback(
    () => navigate(ACCOUNT_ROUTES.planConfirmation),
    [navigate]
  )

  const changePouchSize = useCallback(() => {
    navigate(ACCOUNT_ROUTES.servingSize + `/${planId}`)
  }, [navigate, planId])

  const changeDeliveryFrequency = useCallback(
    () => navigate(ACCOUNT_ROUTES.deliveryFrequency + `/${planId}`),
    [navigate, planId]
  )

  useEffect(() => {
    if (!isUndefined(swiper)) {
      swiper.on('slideChange', (swiper) => {
        setSwiperIndex(swiper.activeIndex)
      })
    }
  }, [swiper])

  /**
   * Get the new plan from the planId URL parameter. If none is provided show the ideal plan
   */
  useEffect(() => {
    if (isUndefined(idealPlan)) return
    if (isUndefined(planId)) setNewPlan(idealPlan)
    else if (isUndefined(newPlan) && !isUndefined(getPlan)) {
      setNewPlan(getPlan(planId))
    }
  }, [idealPlan, getPlan, navigate, newPlan, planId])

  const switchTab = useCallback(
    (e) => swiper?.slideTo(e.target.value),
    [swiper]
  )

  if (
    isNil(dogs) ||
    isNil(pronoun) ||
    isUndefined(currentPlan) ||
    isUndefined(newPlan) ||
    isUndefined(recurringOrder)
  )
    return <div className={STYLES.container}>{SkeletonLoading()}</div>

  const savings = currentPlan.price - newPlan.price

  return (
    <div className={STYLES.container} ref={container}>
      <Wiggle repeat={1}>
        <SectionWrapper
          padding={32}
          bgColour="brandWhite90"
          borderRadius={16}
          margin={{ top: 0 }}
        >
          <>
            <ul className={STYLES.avatars}>
              {dogs.map((dog) => (
                <li key={dog.name}>
                  <div className={STYLES.avatar}>
                    <DogAvatarImage dog={dog} />
                  </div>
                </li>
              ))}
            </ul>
            <Text
              text="plan_review.recommendation"
              variables={{
                context: pronoun
              }}
              variant="textRegular18"
              namespace={namespace}
              element="div"
            />
            <Text
              text="plan_review.grams_per_day"
              variables={{
                grams: newPlan.gramsPerDay
              }}
              variant="display24"
              namespace={namespace}
              element="div"
            />
          </>
        </SectionWrapper>
      </Wiggle>
      <nav className={STYLES.tabs}>
        <PillGroup
          pillOptions={[
            {
              id: 'old',
              name: `plans`,
              text: 'plan_review.tabs.old',
              value: 0,
              checked: swiperIndex === 0,
              onChange: switchTab,
              namespace
            },
            {
              id: 'new',
              name: `plans`,
              text: 'plan_review.tabs.new',
              value: 1,
              defaultChecked: true,
              checked: swiperIndex === 1,
              onChange: switchTab,
              namespace
            }
          ]}
        />
      </nav>
      <SwiperSlider
        slider={setSwiper}
        arrows={false}
        slidesPerView={1}
        initialSlide={swiperIndex}
        autoHeight
      >
        <PlanOverview
          dogs={dogs}
          pronoun={pronoun}
          plan={currentPlan}
          recurringOrder={recurringOrder}
        />
        <PlanOverview
          dogs={dogs}
          pronoun={pronoun}
          plan={newPlan}
          recurringOrder={recurringOrder}
          savings={savings > 0 ? savings : undefined}
        />
      </SwiperSlider>
      <div className={STYLES.tweak}>
        <SectionWrapper
          padding={32}
          bgColour="brandWhite90"
          borderRadius={16}
          margin={{ top: 0 }}
        >
          <Text
            text="plan_review.tweak.title"
            variant="display20"
            namespace={namespace}
            element="h3"
            margin={false}
          />
          <Text
            text="plan_review.tweak.copy"
            namespace={namespace}
            variables={{ context: pronoun }}
          />
          <div className={STYLES.buttons}>
            <Button
              variant="secondary"
              onClick={changePouchSize}
              typography={{
                text: 'plan_review.tweak.buttons.pouch_size',
                namespace
              }}
              identifier="update_plan.change_pouch_size"
            />
            <Button
              variant="secondary"
              onClick={changeDeliveryFrequency}
              disabled={!canChangeDeliveryFrequency}
              typography={{
                text: 'plan_review.tweak.buttons.delivery_frequency',
                namespace
              }}
              identifier="update_plan.change_delivery_frequency"
            />
          </div>
        </SectionWrapper>
      </div>
      <FixedBase backgroundColor="brandWhite90" container={container}>
        <div className={STYLES.buttons}>
          <Button
            onClick={updatePlan}
            typography={{
              text: 'plan_review.buttons.submit',
              namespace
            }}
            identifier="update_plan.update"
            disabled={updating}
          />
          <Button
            variant="secondary"
            onClick={cancel}
            typography={{
              text: 'plan_review.buttons.cancel',
              namespace
            }}
            identifier="update_plan.dont_update"
            disabled={updating}
          />
        </div>
      </FixedBase>
    </div>
  )
}

export default UpdatePlanPage
