import { gql } from '@apollo/client'

import { CALENDAR_DATES_V2_FRAGMENT } from '@/components/elements/molecules/CalendarV2/fragments/calendarDatesV2Fragment'

const COURIER_FRAGMENT = gql`
  ${CALENDAR_DATES_V2_FRAGMENT}
  fragment CourierFragment on DeliveryArea {
    id
    regularDeliveryDaysOfWeek
    preferredCarrierService {
      id
      carrier {
        name
      }
      deliveryNotesLimit
    }
    calendarDates(
      startDate: $calendarInitDate
      nDays: $nDays
      shouldAttemptToOfferNextDayDelivery: $shouldAttemptToOfferNextDayDelivery
    ) {
      ...CalendarDatesV2Fragment
    }
  }
`

export { COURIER_FRAGMENT }
