// @noflow
import { isUndefined } from 'lodash'

import generateRecommendations, {
  Answers as AdaptedAnswers,
  Impression,
  MealRating,
  PooCondition,
  Recommendations,
  TransitionSpeed
} from '@/utils/onboardingQuiz/generateRecommendations'

// eslint-disable-next-line no-restricted-imports
import type { Answer, Question } from '@/components/types'

const onboardingQuizHelper = (
  answers: Array<Array<Answer>>,
  questions: Array<Question>
): Recommendations => {
  // if the order of the questions changes we'll need to update this
  const firstBoxImpressions = {
    [questions[0].options[2].id]: 'ReallyWell',
    [questions[0].options[1].id]: 'OkaySoFar',
    [questions[0].options[0].id]: 'NotEating'
  }

  const currentPooConditions = {
    [questions[2].options[2].id]: 'BetterThanBefore',
    [questions[2].options[1].id]: 'TheSame',
    [questions[2].options[0].id]: 'RunnierNow'
  }

  const transitioningSpeeds = {
    [questions[3].options[0].id]: 'AllIn',
    [questions[3].options[1].id]: 'Slowly'
  }

  const firstBoxImpression: Impression = firstBoxImpressions[
    answers[0][0].optionId
  ] as Impression
  const currentPooCondition: PooCondition = currentPooConditions[
    answers[2][0].optionId
  ] as PooCondition
  const transitioningSpeed: TransitionSpeed = transitioningSpeeds[
    answers[3][0].optionId
  ] as TransitionSpeed
  const ratedMeals: Array<Answer> = answers[1].filter(
    (answer: Answer) => !isUndefined(answer)
  )

  const mealRating: Array<MealRating> = ratedMeals.map((answer) => {
    return { rating: answer.value } as MealRating
  })

  const adaptedAnswers: AdaptedAnswers = {
    firstBoxImpression,
    currentPooCondition,
    mealRating,
    transitioningSpeed
  }
  const recommendations = generateRecommendations(adaptedAnswers)
  window.localStorage.setItem(
    'CMSonboardingRecommendations',
    JSON.stringify(recommendations)
  )
  return recommendations
}

export { onboardingQuizHelper }
