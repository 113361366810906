import { gql } from '@apollo/client'

const ORDER_REPLACEMENT_BOX = gql`
  mutation CustomerIssueResolutionAcceptanceReplacementBoxSubmission(
    $userId: ID!
    $reportId: ID!
    $replacementBoxDetails: CustomerIssueResolutionAcceptanceReplacementBoxDetailsInput!
  ) {
    response: customerIssueResolutionAcceptanceReplacementBoxSubmission(
      userId: $userId
      reportId: $reportId
      replacementBoxDetails: $replacementBoxDetails
    ) {
      resolutionApplicationConfirmation {
        type
      }
    }
  }
`

export { ORDER_REPLACEMENT_BOX }
