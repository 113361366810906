// @noflow
import initI18n from '@/packs/localisation'
import type { Language } from '@/packs/localisation'
import i18next from 'i18next'
import React, { useEffect } from 'react'

import Alert from '@/components/elements/atoms/Alert/AlertCard'
import { Props as TextProps } from '@/components/elements/atoms/Text/Text'

import STYLES from './AdminMessagePage.module.sass'

import Base from '../../templates/Base'

type Props = {
  language: Language
  message: TextProps
}

const AdminMessagePage = ({
  language = 'en',
  message
}: Props): JSX.Element | null => {
  if (!i18next.isInitialized) initI18n(language)

  useEffect(() => {
    const wizardStateKey = 'wizardState'
    const checkoutStateKey = 'checkout_state'

    window.localStorage.removeItem(checkoutStateKey)
    window.localStorage.removeItem(wizardStateKey)
  }, [])

  return (
    <Base background="brandYellow200">
      <main className={STYLES.main}>
        <Alert
          variant="info"
          message={{
            ...message,
            align: 'left'
          }}
        />
      </main>
    </Base>
  )
}

export default AdminMessagePage
