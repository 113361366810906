// @noflow
import { ACCOUNT_ROUTES } from '@/routes'

type TreatmentID =
  | 'care'
  | 'oob'
  | 'fussy_guarantee'
  | 'swap_and_save'
  | 'swap_surcharged_meals'
  | 'extras'
  | 'downsize_plan'

type Treatment = {
  title: string
  body: string
  footnote?: string
  footnoteDiscount?: string
  key: TreatmentID
  imgSlug: string
  destination: string
  spaDestination?: string
}

const recipeLinkParams = new URLSearchParams({
  scrollDestination: 'meal-selection',
  openModal: 'meal-selection'
})

const Care: Treatment = {
  title: 'treatments_section.treatment_cards.care.title',
  body: 'treatments_section.treatment_cards.care.body',
  key: 'care',
  imgSlug: 'care-treatment-card',
  destination: '#care'
}

// One off box
const OneOffBox: Treatment = {
  title: 'treatments_section.treatment_cards.oob.title',
  body: 'treatments_section.treatment_cards.oob.body',
  footnote: 'treatments_section.treatment_cards.oob.footnote',
  footnoteDiscount: 'treatments_section.treatment_cards.oob.footnote_discount',
  key: 'oob',
  imgSlug: 'oob-treatment-card',
  destination: '/dashboard/one-off-boxes',
  spaDestination: ACCOUNT_ROUTES.oneOffBox
}

const FussyGuarantee: Treatment = {
  title: 'treatments_section.treatment_cards.fussy_guarantee.title',
  body: 'treatments_section.treatment_cards.fussy_guarantee.body',
  key: 'fussy_guarantee',
  imgSlug: 'fussy-guarantee-treatment-card',
  destination: ''
}

const SwapAndSave: Treatment = {
  title: 'treatments_section.treatment_cards.swap_and_save.title',
  body: 'treatments_section.treatment_cards.swap_and_save.body',
  footnote: 'treatments_section.treatment_cards.swap_and_save.footnote',
  key: 'swap_and_save',
  imgSlug: 'swap-and-save-treatment-card',
  destination: ACCOUNT_ROUTES.swapAndSave
}

const SwapSurchargedMeals: Treatment = {
  title: 'treatments_section.treatment_cards.swap_surcharged_meals.title',
  body: 'treatments_section.treatment_cards.swap_surcharged_meals.body',
  footnote: 'treatments_section.treatment_cards.swap_surcharged_meals.footnote',
  key: 'swap_surcharged_meals',
  imgSlug: 'swap-recipes-treatment-card',
  // eslint-disable-next-line i18next/no-literal-string
  destination: `/dashboard/subscription?${recipeLinkParams}`,
  // eslint-disable-next-line i18next/no-literal-string
  spaDestination: ACCOUNT_ROUTES.switchAndSave
}

const Extras: Treatment = {
  title: 'treatments_section.treatment_cards.extras.title',
  body: 'treatments_section.treatment_cards.extras.body',
  key: 'extras',
  imgSlug: 'extras-treatment-card',
  destination: '/dashboard/extras',
  spaDestination: ACCOUNT_ROUTES.extras
}

const DownsizePlan: Treatment = {
  title: 'treatments_section.treatment_cards.downsize_plan.title',
  body: 'treatments_section.treatment_cards.downsize_plan.body',
  footnote: 'treatments_section.treatment_cards.downsize_plan.footnote',
  key: 'downsize_plan',
  imgSlug: 'downsize-treatment-card',
  destination: ACCOUNT_ROUTES.downsizeAndSave
}

const treatmentsList: { [key: string]: Treatment } = {
  Care,
  OneOffBox,
  FussyGuarantee,
  SwapAndSave,
  SwapSurchargedMeals,
  Extras,
  DownsizePlan
}

const generateTreatments = (treatments: [string]): Treatment[] => {
  const selectedTreatments: Treatment[] = []
  if (treatments) {
    treatments.forEach((name) => {
      selectedTreatments.push(treatmentsList[name])
    })
  }
  return selectedTreatments
}

export default generateTreatments
export type { Treatment, TreatmentID }
