/* eslint-disable react/require-optimization */
// @flow

import * as React from 'react'
import * as Sentry from '@sentry/browser'

// Utils
import * as DIGITAL_PRODUCT_CONSTANTS from '@/utils/butternutbox/constants/digital_product'

// Components
import ShippingDetails from './ShippingDetails'

// Assets
import { accountIcons } from '@/components/admin/single_customer_view/asset-imports/icons'

// Types
import type { ComponentProps } from '@/components/admin/single_customer_view/containers/user_details/AccountDetailsContainer'
import type {
  AccountDetails,
  DogDetails
} from '@/components/admin/single_customer_view/message_types'
import type { Dog } from '@/shared_types/rails_models/dogs'
import type { Language } from '@/packs/localisation'
import {
  genderToWizardSex,
  exerciseToWizardActivityLevel,
  currentFoodToWizardCurrentlyEating,
  bodyShapeToWizardBodyCondition,
  eaterTypeToWizardFussiness,
  snackingHabitsToWizardSnackingHabits
} from '@/shared_types/rails_models/dogs'

class AccountDetailsComponent extends React.Component<ComponentProps> {
  componentDidMount (): void {
    const { userId, dispatchGetAccountDetails, dispatchGetDogsDetails } = this.props
    dispatchGetAccountDetails(userId)
    dispatchGetDogsDetails(userId)
  }

  renderAccountDetails = (accountDetails: AccountDetails): React.Node => {
    const { flags = {} } = accountDetails
    const experimentFlags = flags && Object.keys(flags).map((key: string): React.Node =>
      (
        <div
          key={key}
          className='card__row'
        >
          <p className='card__row__title'>
            { key }
          </p>
          <span className={`label label--dark-blue`}>
            { flags[key].toString() }
          </span>
        </div>
      )
    )

    const languageCodeToLanguage = (languageCode: Language): string => {
      switch (languageCode) {
        case 'en': {
          return 'English'
        }
        case 'nl': {
          return 'Dutch'
        }
        case 'nl_BE': {
          return 'Flemish'
        }
        case 'pl_PL':
        case 'pl': {
          return 'Polish'
        }
        case 'fr': {
          return 'French'
        }
        case 'de_DE': {
          return 'German'
        }
        default: {
          Sentry.captureException(`languageCodeToLanguage is not implemented for ${languageCode}`)
          return 'English'
        }
      }
    }

    return (
      <div>
        <div className='card__row'>
          <p className='card__row__title'>
            { 'Full name' }
          </p>
          <p>
            { accountDetails.full_name }
          </p>
        </div>
        <div className='card__row'>
          <p className='card__row__title'>
            { 'Email' }
          </p>
          <p>
            { accountDetails.email }
          </p>
        </div>
        <div className='card__row'>
          <p className='card__row__title'>
            { 'Phone number' }
          </p>
          <p>
            { accountDetails.phone }
          </p>
        </div>
        <div className='card__row'>
          <p className='card__row__title'>
            { 'Address' }
          </p>
          <p>
            { accountDetails.full_address }
          </p>
        </div>
        <div className='card__row'>
          <p className='card__row__title'>
            { 'Preferred language' }
          </p>
          <p>
            { languageCodeToLanguage(accountDetails.preferred_language) }
          </p>
        </div>
        { accountDetails.assumed_country &&
          <div className='card__row'>
            <p className='card__row__title'>
              { 'Assumed country' }
            </p>
            <p>
              { accountDetails.assumed_country }
            </p>
          </div>
        }
        <div className='card__row'>
          <p className='card__row__title'>
            { 'RAF code' }
          </p>
          <p>
            {
              accountDetails.refer_a_friend_code
                ? 'www.butternutbox.com/' + accountDetails.refer_a_friend_code
                : 'No code available'
            }
          </p>
          {
            accountDetails.client_referral_link_id && (
              <a
                className='card__button button--blue'
                href={`/admin/client_referral_links/${accountDetails.client_referral_link_id}/edit`}
                target='_blank'
                rel='noopener noreferrer'
              >
                { 'Edit' }
              </a>
            )
          }
        </div>
        <div className='card__row'>
          <p className='card__row__title'>
            { 'RAF discounts' }
          </p>
          { this.renderRAFDiscounts(accountDetails) }
        </div>
        <div className='card__row'>
          <p className='card__row__title'>
            { 'Sign up discount' }
          </p>
          { this.renderSignUpDiscount(accountDetails) }
        </div>
        <div className='card__row'>
          <p className='card__row__title'>
            { 'Ambassador' }
          </p>
          {
            accountDetails.is_ambassador
              ? (
                <span className='label label--red'>
                  { 'Ambassador' }
                </span>
                )
              : (
                <span className='label label--grey'>
                  { 'No' }
                </span>
                )
          }
        </div>
        { accountDetails.is_ambassador && (
          <div className='card__row'>
            <a className='card__button button--blue' href={`/admin/ambassadors/${accountDetails.ambassador_id}`} target='_blank' rel='noopener noreferrer'>
              { 'View Ambassador' }
            </a>
          </div>
        )}
        <div className='card__row'>
          <p className='card__row__title'>
            { 'Influencer' }
          </p>
          {
            accountDetails.is_influencer
              ? (
                <span className='label label--red'>
                  { 'Influencer' }
                </span>
                )
              : (
                <span className='label label--grey'>
                  { 'No' }
                </span>
                )
          }
        </div>
        { accountDetails.is_influencer && (
          <div className='card__row'>
            <a className='card__button button--blue' href={`/admin/influencers/${accountDetails.influencer_id}`} target='_blank' rel='noopener noreferrer'>
              { 'View Influencer' }
            </a>
          </div>
        )}
        <div className='card__row'>
          <p className='card__row__title'>
            { 'Staff Discount Holder' }
          </p>
          {
            accountDetails.is_staff_discount_holder
              ? (
                <div>
                  <div className='card__row'>
                    <span className='label label--red'>
                      { 'Yes' }
                    </span>
                  </div>
                  <div className='card__row'>
                    <p className='card__row__title'>
                      { 'Collecting from work?' }
                    </p>
                    <span className='label label--red'>
                      { accountDetails.collected_from_work.toString() }
                    </span>
                  </div>
                  {
                    accountDetails.staff_discount_holder_id && (
                    <a
                      className='card__button button--blue'
                      href={`/admin/staff_discount_holders/${accountDetails.staff_discount_holder_id}/edit`}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      { 'Edit Staff Discount Holder' }
                    </a>
                    )
                  }
                </div>
                )
              : (
                <div>
                  <div className='card__row'>
                    <span className='label label--grey'>
                      { 'No' }
                    </span>
                  </div>
                  <div>
                    {
                      (
                        <a
                          className='card__button button--blue'
                          href={`/admin/staff_discount_holders/new`}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          { 'Create Staff Discount Holder' }
                        </a>
                      )
                    }
                  </div>
                </div>
                )
          }
        </div>
        <div className='card__row'>
          <details className='experimental-flags'>
            <summary className='card__button button--white'>
              <p className='card__row__title'>
                { 'Toggle Experimental Flags' }
              </p>
            </summary>
            { experimentFlags }
          </details>
        </div>
        <div className='card__row'>
          <p className='card__row__title'>
            { 'Admin notes' }
          </p>
          <p>
            { accountDetails.admin_notes ? accountDetails.admin_notes : 'No notes' }
          </p>
        </div>
      </div>
    )
  }

  renderRAFDiscounts = (accountDetails: AccountDetails): React.Node => {
    const { dispatchSendRafDetailsEmail } = this.props
    const { sendRafDetailsEmail } = accountDetails

    const disableRafDetailsEmailButton = (): boolean => (
      sendRafDetailsEmail.sendRafDetailsEmailInFlight ||
      !!sendRafDetailsEmail.sendRafDetailsEmailSuccessMessage
    )

    const { dispatchSendRafDetailsSms } = this.props
    const { sendRafDetailsSms } = accountDetails

    const disableRafDetailsSmsButton = (): boolean => (
      sendRafDetailsSms.sendRafDetailsSmsInFlight ||
        !!sendRafDetailsSms.sendRafDetailsSmsSuccess
    )
    if (accountDetails.referral_copy) {
      return (
        <>
          <p>
            { accountDetails.referral_copy }
          </p>
          <button
            className={disableRafDetailsEmailButton() ? 'card___button button--disabled' : 'card__button button--blue'}
            onClick={dispatchSendRafDetailsEmail}
            disabled={disableRafDetailsEmailButton()}
            type={'button'}
          >
            { 'Email RAF Details' }
          </button>
          <div className='card__row'>
            <p className='card__row__title'>
              { sendRafDetailsEmail.sendRafDetailsEmailSuccessMessage ||
                sendRafDetailsEmail.sendRafDetailsEmailErrorMessage }
            </p>
          </div>
          <div className='card__row'>
            <p className='card__row__title'>
              {
                accountDetails.phone && !accountDetails.sendRafDetailsSms.sendRafDetailsSmsSuccess && (
                  <button
                    className={disableRafDetailsSmsButton() ? 'card___button button--disabled' : 'card__button button--blue'}
                    onClick={dispatchSendRafDetailsSms}
                    disabled={disableRafDetailsSmsButton()}
                    type={'button'}
                  >
                    { 'Text RAF Details' }
                  </button>
                )
              }
              {
                  accountDetails.sendRafDetailsSms.sendRafDetailsSmsSuccess && (
                  <p>
                    { `SMS triggered Successfully` }
                  </p>
                  )
              }
            </p>
          </div>
        </>
      )
    } else {
      return (
        <p>
          { 'N/A' }
        </p>
      )
    }
  }

  renderSignUpDiscount = (accountDetails: AccountDetails): React.Node => {
    const active = accountDetails.signup_discount.active === true
      ? ''
      : '(expired)'
    if (accountDetails.signup_discount.discount_basis === 'total') {
      return (
        <p>
          { `${accountDetails.signup_discount.discount_amount}, ${accountDetails.signup_discount.discount_code} + ${accountDetails.signup_discount.channel} ${active}` }
        </p>
      )
    } else if (accountDetails.signup_discount.discount_basis === 'percentage') {
      return (
        <p>
          { `${accountDetails.signup_discount.discount_amount}%, ${accountDetails.signup_discount.discount_code} + ${accountDetails.signup_discount.channel} ${active}` }
        </p>
      )
    } else {
      return (
        <p>
          { `No discount` }
        </p>
      )
    }
  }

  transitionStatus = (): string => {
    const { isFetching } = this.props

    return isFetching ? 'loading' : 'loaded'
  }

  renderLoadingIcon = (): ?React.Element<'div'> => {
    const { isFetching } = this.props

    if (isFetching) {
      return (
        <div className="loading-ring">
          <span />
          <span />
          <span />
          <span />
        </div>
      )
    }
  }

  renderContinueSignupButton = (): React.Node => {
    return (
      <button
        type="button"
        className='card__button button--blue'
        onClick={ this.handleContinueSignupButtonClick }
      >
        <span>
          { 'Continue Signup' }
        </span>
      </button>
    )
  }

  handleContinueSignupButtonClick = (): void => {
    const { userId, accountDetails, dogsDetails } = this.props

    // Create a json object with all the necessary information
    const serializedDogsDetails = this.serializeDogsDetails(dogsDetails)
    // Populate the localStorage with information that is required to pre-fill
    // the wizard
    window.localStorage.setItem('new-wizard-dog-persistence-v1', serializedDogsDetails)
    window.localStorage.setItem('new-wizard-email-persistence-v1', accountDetails.email)
    window.localStorage.setItem('new-wizard-full-name-persistence-v1', accountDetails.full_name)

    // Login as user and redirect wizard
    window.open(`/admin/single_customer_view/continue_signup/for/${userId}`, '_blank')
  }

  serializeDogsDetails = (dogsDetails: Array<DogDetails>): string => (
    JSON.stringify(dogsDetails.map(({
      funnel_persistence_details_v1
    }: DogDetails): Dog => ({
      name: funnel_persistence_details_v1.name,
      sex: genderToWizardSex(funnel_persistence_details_v1.sex),
      deSexed: funnel_persistence_details_v1.desexed,
      age: funnel_persistence_details_v1.age_in_months <= DIGITAL_PRODUCT_CONSTANTS.MAX_PUPPY_AGE_IN_MONTHS
        ? {
            months: funnel_persistence_details_v1.age_in_months,
            weeks: 0
          }
        : {
            years: Math.floor(funnel_persistence_details_v1.age_in_months / 12),
            months: funnel_persistence_details_v1.age_in_months % 12
          },
      broughtHome: true, // We don't store this info in the database, so just set to true
      breed: {
        id: funnel_persistence_details_v1.breed_id,
        name: funnel_persistence_details_v1.breed
      },
      currentlyEating: funnel_persistence_details_v1.currently_eating.map(currentFoodToWizardCurrentlyEating),
      fussiness: eaterTypeToWizardFussiness(funnel_persistence_details_v1.fussiness),
      bodyCondition: bodyShapeToWizardBodyCondition(funnel_persistence_details_v1.body_condition),
      mass: funnel_persistence_details_v1.mass,
      activityLevel: exerciseToWizardActivityLevel(funnel_persistence_details_v1.activity_level),
      isWorkingDog: false, // We don't store this info in the database, so just set to false
      isRescue: false, // We don't store this info in the database, so just set to false
      allergies: funnel_persistence_details_v1.allergies.map(({ name }: $ElementType<$PropertyType<$PropertyType<DogDetails, 'funnel_persistence_details_v1'>, 'allergies'>, 0>): string => name),
      healthIssues: {
        tag: funnel_persistence_details_v1.health_issues.length === 0 ? 'None' : 'Some',
        contents: funnel_persistence_details_v1.health_issues.map(({ name }: $ElementType<$PropertyType<$PropertyType<DogDetails, 'funnel_persistence_details_v1'>, 'health_issues'>, 0>): string => name)
      },
      snackingHabits: snackingHabitsToWizardSnackingHabits(funnel_persistence_details_v1.snacking_habits)
    })))
  )

  render (): React.Node {
    const { userId, accountDetails, subscriptionId } = this.props
    return (
      <div>
        <div className={`account-details card__container card__${this.transitionStatus()}`}>
          <div className='card__header__container'>
            <h3 className='card__header'>
              { 'Account details' }
            </h3>
            <a
              className='card__button button--white'
              href={`/admin/users/${userId}`}
              rel="noopener noreferrer"
              target='_blank'
            >
              <img
                alt="Eye icon"
                className='icon'
                src={accountIcons.eye}
              />
              { 'View all details' }
            </a>
          </div>
          <div className='card__body'>
            <a
              className='card__button button--grey button--no-shadow'
              href={`/admin/users/${userId}/edit`}
              rel="noopener noreferrer"
              target='_blank'
            >
              <img
                alt='Pencil icon'
                className='icon'
                src={accountIcons.edit}
              />
            </a>
            { this.renderLoadingIcon() }
            { this.renderAccountDetails(accountDetails) }
            <a
              className='card__button button--blue'
              href={`/admin/applicable_discounts/new?subscription_id=${subscriptionId}`}
              rel="noopener noreferrer"
              target='_blank'
            >
              { 'Add a discount' }
            </a>
            {
              accountDetails.is_guest && (
                this.renderContinueSignupButton())
            }
          </div>
        </div>
        <ShippingDetails />
      </div>
    )
  }
}

export default AccountDetailsComponent
