// @noflow
import { Language } from '@/packs/localisation'
import { useReactiveVar } from '@apollo/client'
import { format, formatISO, parseISO } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { localeToDateLocale } from '@/utils/countryCodeHelper'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import AlertCard from '@/components/elements/atoms/Alert/AlertCard'
import Modal from '@/components/elements/atoms/Modal/Modal'
import Calendar from '@/components/elements/molecules/Calendar'

import type { CheckoutPage } from '../../queries/__generated__/CheckoutPage'
import type { DeliveryDateModalQuery } from './queries/__generated__/DeliveryDateModalQuery'
import { Code as ShippingCountryCode } from '@/types'

import * as ANALYTICS from '../../Analytics/CheckoutAnalytics'
import { checkoutPageState } from '../../CheckoutPage'
import { generateEndOfLeadTime } from '../../helpers/generateEndOfLeadTime'
import {
  checkoutPricingState,
  useCheckoutPricing
} from '../../hooks/useCheckoutPricing'

type Props = {
  namespace: string
  openDeliveryDateModal: React.Dispatch<React.SetStateAction<boolean>>
  isDeliveryDateModalOpen: boolean
  shippingCountryCode: CheckoutPage['guest']['assumedShippingCountry']['code']
  availableDeliveryDates: DeliveryDateModalQuery['calendarDates']
  preferredLanguage: Language
}

const DeliveryDateModal = ({
  namespace,
  openDeliveryDateModal,
  isDeliveryDateModalOpen,
  shippingCountryCode,
  availableDeliveryDates,
  preferredLanguage
}: Props): JSX.Element => {
  const { t } = useTranslation(namespace)

  const { windowWidth } = useWindowSize()

  const { priceIncrease } = useReactiveVar(checkoutPricingState)
  const { sections, plan } = checkoutPageState()
  const { addressDetails, deliveryDetails } = sections
  const { form: addressDetailsForm } = addressDetails
  const { postcode, city } = addressDetailsForm
  const { form: deliveryDetailsForm } = deliveryDetails
  const { selectedDeliveryDate } = deliveryDetailsForm

  const dateLocale = localeToDateLocale(shippingCountryCode, preferredLanguage)
  const { refetchCheckoutPricing } = useCheckoutPricing({
    endOfLeadTime: selectedDeliveryDate.endOfLeadTime,
    planId: plan.planId,
    skip: !isDeliveryDateModalOpen
  })

  const setSelectedDate = React.useCallback(
    (date: Date) => {
      ANALYTICS.deliveryDateUpdated({
        previousDate: sections.deliveryDetails.form.selectedDeliveryDate.value,
        newDate: formatISO(date, { representation: 'date' })
      })

      const matchedAvaliableDeliveryDate = availableDeliveryDates.find(
        (add) => add.date === formatISO(date, { representation: 'date' })
      )
      if (!matchedAvaliableDeliveryDate)
        throw new Error(
          `Could not find a matching availableDeliveryDate in DeliveryDateModal`
        )

      const endOfLeadTime = generateEndOfLeadTime(matchedAvaliableDeliveryDate)

      sections.deliveryDetails.form.selectedDeliveryDate.value =
        matchedAvaliableDeliveryDate.date
      sections.deliveryDetails.form.selectedDeliveryDate.endOfLeadTime =
        endOfLeadTime
      sections.deliveryDetails.valid = true

      checkoutPageState({
        ...checkoutPageState(),
        sections
      })

      refetchCheckoutPricing({
        expectedFirstShippingDate: endOfLeadTime,
        planId: plan.planId.toString()
      })
    },
    [sections, availableDeliveryDates, refetchCheckoutPricing, plan.planId]
  )

  const closeDeliveryDateModal = React.useCallback(
    () => openDeliveryDateModal(false),
    [openDeliveryDateModal]
  )

  const dateFormatPattern = ((countryCode: ShippingCountryCode): string => {
    switch (countryCode) {
      case 'NL':
      case 'BE':
        return 'd MMMM yyyy'
      default:
        return 'do MMMM yyyy'
    }
  })(shippingCountryCode)

  return (
    <Modal
      isModalOpen={isDeliveryDateModalOpen}
      setOpenModal={openDeliveryDateModal}
      width={450}
      bottomSticky={windowWidth < BREAKPOINTS.md}
    >
      <div className="modal-container">
        <Calendar
          city={city.value}
          postcode={postcode.value}
          selectedDate={parseISO(selectedDeliveryDate.value)}
          setSelectedDate={setSelectedDate}
          deliveryDate={parseISO(selectedDeliveryDate.value)}
          shippingCountryCode={shippingCountryCode}
          shouldAttemptToOfferNextDayDelivery
          availableDeliveryDates={availableDeliveryDates}
        />
        <p
          className="checkout__modals__delivery-date-modal__subtext"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: t(`delivery_date_modal.text_html`, {
              dayName: format(parseISO(selectedDeliveryDate.value), 'EEEE', {
                locale: dateLocale
              })
            })
          }}
        />
        {priceIncrease.showPriceIncreaseAlert &&
          priceIncrease.effectiveFrom && (
            <AlertCard
              variant="info"
              message={{
                text: 'order_summary.price_increase_info',
                namespace,
                align: 'left',
                variables: {
                  date: format(priceIncrease.effectiveFrom, dateFormatPattern, {
                    locale: dateLocale
                  })
                }
              }}
            />
          )}
        <button
          className="btn btn-secondary checkout__modals__delivery-date-modal__confirm-btn"
          onClick={closeDeliveryDateModal}
          type="button"
        >
          {t(`delivery_date_modal.button_text`)}
        </button>
      </div>
    </Modal>
  )
}

export default DeliveryDateModal
