import classnames from 'classnames'
import React, { MouseEvent, useCallback } from 'react'

import { AnalyticsProps, trackEvent } from '@/services/segment'

import BlueArrow from 'assets/images/icons/arrows/arrow-blue.svg'

import Text, { Props as TextProps } from '@/components/elements/atoms/Text/Text'

import STYLES from './Link.module.sass'

type Props = {
  variant?: keyof typeof STYLES
  text: string | Array<string>
  href?: string
  shouldScale?: boolean
  size?: 16 | 12 | 14 | 18
  flush?: 'left' // align the label text to the specified edge
  padding?: 'medium' | 'large'
  suffix?: JSX.Element | null
  opensInNewTab?: boolean
  variables?: TextProps['variables']
  translate?: TextProps['translate']
  colour?: TextProps['colour']
  nativeUnderline?: TextProps['underline']
  namespace?: string
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void
  reactRef?: React.RefObject<HTMLAnchorElement>
} & AnalyticsProps

const Link = ({
  variant,
  text,
  href,
  shouldScale = true,
  padding = 'medium',
  suffix = (
    <img
      className={`${STYLES.icon}`}
      src={BlueArrow}
      alt="Blue arrow pointing right"
    />
  ),
  opensInNewTab = false,
  variables,
  translate,
  colour,
  nativeUnderline = false,
  namespace,
  onClick,
  size = 16,
  flush,
  reactRef,
  identifier,
  screenIdentifier,
  disableAnalytics
}: Props): JSX.Element => {
  const className = classnames(
    STYLES.link,
    variant && STYLES[variant],
    flush && [STYLES[`${flush}Flush`]],
    {
      [STYLES[`size${size}`]]: size,
      [STYLES[`${padding}Padding`]]: padding
    }
  )

  const handleTrackEvent = useCallback((): void => {
    if (disableAnalytics || !identifier) return
    trackEvent('Component Clicked', {
      component_identifier: identifier,
      ...(screenIdentifier ? { screen_identifier: screenIdentifier } : {})
    })
  }, [disableAnalytics, identifier, screenIdentifier])

  const handeOnClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>): void => {
      handleTrackEvent()
      if (onClick) onClick(event)
    },
    [handleTrackEvent, onClick]
  )

  if (opensInNewTab) {
    return (
      <a
        href={href}
        className={className}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handeOnClick}
        ref={reactRef}
      >
        <Text
          namespace={namespace}
          text={text}
          variant={`textRegular${size}`}
          element="span"
          shouldScale={shouldScale}
          variables={variables}
          translate={translate}
          colour={colour}
          underline={nativeUnderline}
        />
        {suffix}
      </a>
    )
  } else {
    return (
      <a
        href={href}
        className={`
          ${STYLES.link}
          ${variant ? STYLES[variant] : ''}
          ${size ? STYLES[`size${size}`] : ''}
          ${padding ? STYLES[`${padding}Padding`] : ''}
          ${flush ? STYLES[`${flush}Flush`] : ''}
          ${nativeUnderline ? STYLES.nativeUnderline : ''}
        `}
        onClick={handeOnClick}
        ref={reactRef}
      >
        <Text
          namespace={namespace}
          text={text}
          variant={`textRegular${size}`}
          variables={variables}
          translate={translate}
          element="span"
          shouldScale={shouldScale}
          colour={colour}
          underline={nativeUnderline}
        />
        {suffix}
      </a>
    )
  }
}

export { Props }
export default Link
