// @noflow
import i18next from 'i18next'
import isUndefined from 'lodash/isUndefined'
import React, { RefObject, useEffect, useRef } from 'react'
import { TFunction } from 'react-i18next'

import { PronounContext } from '@/utils/StringHelper'
import * as DiscountCodes from '@/utils/butternutbox/discountCodes'
import { Currency, Locale } from '@/utils/currency'

import useIntersectionObserver from '@/hooks/useIntersectionObserver'

import Card from '@/components/elements/atoms/Card/Card'
import Label from '@/components/elements/atoms/Label/Label'
import SpeechBubble from '@/components/elements/atoms/SpeechBubble/SpeechBubble'
import Text from '@/components/elements/atoms/Text/Text'
import { RecommendationQuery_currentUser_Guest_discountCode_discountCodeParts } from '@/components/pages/PlansPage/__generated__/RecommendationQuery'
import { FlowType } from '@/components/pages/PlansPage/types/types'
import type { InitialState as WizardState } from '@/components/pages/SignupWizardPage/SignupWizardPage'
// eslint-disable-next-line no-restricted-imports
import type { PendingSubscription } from '@/components/types'

import STYLES from './CadenceSection.module.sass'

import type { RecommendationQuery_currentUser_User_planRecommendation_plans as MealPlan } from '../../../../__generated__/RecommendationQuery'

import type { Route as PlanRoute } from '../../../../types/routes'
import { getNumberOfWeeksWorthOfFood } from '../../Plan'
import OngoingPlanCards from '../OngoingPlanCards/OngoingPlanCards'

type Props = {
  namespace: string
  copyContext: string
  flowTypeState: FlowType
  firstBoxDiscountPart:
    | RecommendationQuery_currentUser_Guest_discountCode_discountCodeParts
    | false
    | null
  dogPronoun: PronounContext
  selectedCadence: MealPlan
  locale: Locale
  currency: Currency
  recommendedCadence: MealPlan | undefined
  availableCadences: MealPlan[] | undefined
  setSelectedCadence: (cadence: MealPlan) => void
  t: TFunction<string, undefined>
  location: PlanRoute
  plansState: PendingSubscription
  wizardState: WizardState
  recommendationState: string
  setShouldStickyPriceBeVisible: (visible: boolean) => void
}

const CadenceSection = ({
  namespace,
  copyContext,
  flowTypeState,
  firstBoxDiscountPart,
  dogPronoun,
  selectedCadence,
  locale,
  currency,
  recommendedCadence,
  availableCadences,
  setSelectedCadence,
  t,
  location,
  plansState,
  wizardState,
  recommendationState,
  setShouldStickyPriceBeVisible
}: Props): JSX.Element => {
  const firstBoxDiscountValue =
    firstBoxDiscountPart &&
    DiscountCodes.formattedPartValue(firstBoxDiscountPart, locale, currency)

  const stickyPriceTargetRef: RefObject<HTMLDivElement> = useRef(null)
  const shouldStickyPriceBeVisible = useIntersectionObserver(
    stickyPriceTargetRef,
    { threshold: [0.2] }
  )

  useEffect(() => {
    setShouldStickyPriceBeVisible(shouldStickyPriceBeVisible)
  }, [setShouldStickyPriceBeVisible, shouldStickyPriceBeVisible])

  return (
    <div ref={stickyPriceTargetRef}>
      <div className={STYLES.cpoSection}>
        <div className={`${STYLES.wrapper} ${STYLES.detailsWrapper}`}>
          <div className={STYLES.cadenceSelection}>
            <div className={flowTypeState === 'new' ? STYLES.title : ''}>
              <Text
                element="h3"
                text={`${copyContext}.cadence_selection.title`}
                namespace={namespace}
                variant="display24"
                colour="brandBlue500"
                margin={false}
                align="center"
                shouldScale
              />
            </div>
            {flowTypeState === 'new' && (
              <React.Fragment>
                <div className={STYLES.introBox}>
                  <Card variant="tapewhite" shadow={false}>
                    <div className={STYLES.introBoxContent}>
                      <div className={STYLES.introBoxDiscountHeader}>
                        <Text
                          text={`${copyContext}.cadence_selection.intro_box.title`}
                          variables={{ context: dogPronoun }}
                          namespace={namespace}
                          variant="display16"
                          colour="brandBlue500"
                          margin={false}
                          shouldScale
                        />
                        {firstBoxDiscountPart && (
                          /* eslint-disable-next-line jsx-a11y/label-has-for */
                          <Label
                            variant="discount"
                            text={{
                              text: `${copyContext}.cadence_selection.intro_box.discount`,
                              variables: { discount: firstBoxDiscountValue },
                              namespace: namespace
                            }}
                          />
                        )}
                      </div>
                      <Text
                        text={
                          ['pl', 'pl-PL'].includes(i18next.language) &&
                          selectedCadence.trialLengthInDays / 7 === 2
                            ? `${copyContext}.cadence_selection.intro_box.subtitle_two_weeks`
                            : `${copyContext}.cadence_selection.intro_box.subtitle`
                        }
                        variables={{
                          context: dogPronoun,
                          trialWeekNumber: selectedCadence.trialLengthInDays / 7
                        }}
                        namespace={namespace}
                        variant="textRegular16"
                        colour="brandBlue500"
                        shouldScale
                      />
                      <Text
                        text={`${copyContext}.cadence_selection.intro_box.pause_notice`}
                        namespace={namespace}
                        variant="textRegular16"
                        colour="brandBlue400"
                        margin={false}
                        shouldScale
                      />
                    </div>
                  </Card>
                </div>
                <div className={`${STYLES.title} ${STYLES.cpoTitle}`}>
                  <Text
                    element="h3"
                    text={`${copyContext}.cadence_selection.ongoing_plan.title`}
                    variables={{ context: dogPronoun }}
                    namespace={namespace}
                    variant="display20"
                    colour="brandBlue500"
                    margin={false}
                    align="center"
                    shouldScale
                  />
                </div>
              </React.Fragment>
            )}
            {recommendedCadence && (
              <SpeechBubble
                variant="square"
                footPosition="none"
                text={{
                  text: `${copyContext}.cadence_selection.ongoing_plan.speech_bubble`,
                  namespace,
                  variables: {
                    cadenceInWeeks: getNumberOfWeeksWorthOfFood(
                      recommendedCadence.durationInDays
                    )
                  },
                  margin: false
                }}
              />
            )}
          </div>
        </div>
      </div>

      <div className={STYLES.cpoSection}>
        <div className={STYLES.cpoWrapper}>
          {!isUndefined(availableCadences) &&
            !isUndefined(recommendedCadence) && (
              <OngoingPlanCards
                cadences={availableCadences}
                namespace={namespace}
                copyContext={copyContext}
                selectedCadence={selectedCadence}
                recommendedCadence={recommendedCadence}
                setSelectedCadence={setSelectedCadence}
                t={t}
                location={location}
                plansState={plansState}
                wizardState={wizardState}
                recommendationState={recommendationState}
                flowTypeState={flowTypeState}
              />
            )}
        </div>
      </div>
    </div>
  )
}

export default CadenceSection
