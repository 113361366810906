// @noflow
import { useLocalisation } from '@/context/localisation'
import camelCase from 'lodash/camelCase'
import isNull from 'lodash/isNull'
import React, { useCallback, useContext } from 'react'

// Constants
import BREAKPOINTS from '@/constants/Breakpoints'

// Hooks
import useWindowSize from '@/hooks/useWindowSize'

// Components
import { Fade } from '@/components/elements/atoms/Animated/Animated'
import QuantitySelector from '@/components/elements/atoms/QuantitySelector/QuantitySelector'
import Text from '@/components/elements/atoms/Text/Text'
import ProductBenefit from '@/components/elements/molecules/ProductBenefit/ProductBenefit'
import { CloudinaryImageCard } from '@/components/elements/organisms/ImageCard/ImageCard'
import { EditFreshMealsContext } from '@/components/pages/EditRecipes/EditRecipes'
import type { MealInputKey } from '@/components/pages/EditRecipes/EditRecipes'
import STYLES from '@/components/pages/EditRecipes/EditRecipes.module.sass'
import type {
  MealFlavoursQuery_user_subscription_meal_flavour_productBenefits as Benefit,
  MealFlavoursQuery_user_subscription_meal as Meal
} from '@/components/pages/EditRecipes/queries/__generated__/MealFlavoursQuery'

const RecipeCard = ({ freshMeal }: { freshMeal: Meal }): JSX.Element => {
  const { formatCurrencyWithDecimal } = useLocalisation()
  const { windowWidth } = useWindowSize()
  const isSmallScreen = windowWidth < BREAKPOINTS.sm

  const {
    mealQuantities,
    percentFilled,
    increaseMealQuantity,
    decreaseMealQuantity,
    pouchSize,
    pouchesPerServing
  } = useContext(EditFreshMealsContext)
  const {
    flavour: {
      slug,
      recipeSurcharge,
      name,
      thumbnail: { src },
      productBenefits
    }
  } = freshMeal
  const mealInputKey = camelCase(slug) as MealInputKey

  const formattedRecipeSurcharge = recipeSurcharge
    ? formatCurrencyWithDecimal(recipeSurcharge)
    : ''

  const flavourQuantity = mealQuantities[mealInputKey as MealInputKey] ?? 0

  const onQuantityChangeCallback = useCallback(
    (to: number) => {
      const incrementAmount = pouchesPerServing === 2 ? 2 : 1
      Number(to) > flavourQuantity
        ? increaseMealQuantity(mealInputKey, incrementAmount)
        : decreaseMealQuantity(mealInputKey, incrementAmount)
    },
    [
      flavourQuantity,
      increaseMealQuantity,
      mealInputKey,
      decreaseMealQuantity,
      pouchesPerServing
    ]
  )

  return (
    <CloudinaryImageCard
      alt={name}
      image={{
        path: src,
        crop: 'fill',
        height: 264,
        width: 310,
        dpr: window.devicePixelRatio
      }}
      padding={isSmallScreen ? 16 : 0}
      position="top"
      background={false}
    >
      <div className={STYLES.cardContent}>
        <div className={STYLES.bottomOfImage}>
          <Fade show={flavourQuantity > 0} duration={150}>
            <div className={STYLES.isInBox}>
              <Text
                text={'edit_recipes.in_box'}
                namespace="account"
                margin={false}
                variant="textRegular14"
                colour="brandWhite"
                bold
              />
            </div>
          </Fade>
        </div>
        <div className={STYLES.recipeDetails}>
          <div className={STYLES.heading}>
            <Text
              text={name}
              translate={false}
              margin={false}
              variant="display16"
            />
            {!isNull(recipeSurcharge) && recipeSurcharge > 0 && (
              <div>
                <Text
                  text={'edit_recipes.recipe_surcharge'}
                  namespace="account"
                  variables={{ price: formattedRecipeSurcharge }}
                  margin={false}
                />
              </div>
            )}
          </div>
          <div className={STYLES.benefitList}>
            {productBenefits.map((benefit: Benefit) => (
              <ProductBenefit
                key={benefit.name}
                benefit={{
                  name: benefit.name,
                  url: benefit.image?.src || null
                }}
                position="left"
              />
            ))}
          </div>
        </div>
        <Text
          text={'edit_recipes.plan_pouch_size'}
          namespace="account"
          variables={{ pouchSize: pouchSize }}
          margin={false}
          variant="display14"
        />
        <QuantitySelector
          controlled
          minValue={0}
          maxValue={percentFilled === 100 ? flavourQuantity : undefined}
          onChange={onQuantityChangeCallback}
          quantity={flavourQuantity}
          variant="yellow"
          identifier="recipe_card_quantity"
          trackDisabledPresses
        />
      </div>
    </CloudinaryImageCard>
  )
}

export { RecipeCard }
