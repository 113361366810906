// @flow

import * as MESSAGES from '../actions/messages'

import type { State } from '../index'
import type { Action, SearchParams } from '../actions'
import type { DeliveryDetails, PlanAttributesSelection, ProposedPlan, ExistingPreference, AvailablePreference, MarketingPreferenceData, SelectedMarketingMethodPurposes, SupportedMarketingMethodPurposes } from '../message_types'
import type { CSRFToken } from '@/shared_types/csrfToken'
import type { ShippingCountry } from '@/shared_types/rails_models/shipping_countries'

const initialProposedPlan: ProposedPlan = {
  plan: {
    id: -1,
    durationInDays: -1,
    numberOfPouches: -1,
    pricePerPouch: -1,
    pouchSizeInGrams: -1,
    deliveryFee: 0,
    pricingCurve: {
      pricingCurveType: 'default',
      pricingCohort: '125g'
    }
  },
  proposedRecipeSurcharges: [],
  shippingCountryCode: 'GB',
  meal: []
}

const initialPlanAttributesSelection: PlanAttributesSelection = {
  durationInDays: -1,
  numberOfPouches: -1,
  deliveryFee: 0,
  pricingCurve: {
    pricingCurveType: 'convenience_pricing',
    pricingCohort: '125g',
  }
}

const initialSubscriptionDetails = {
  id: -1,
  status: 'unknown',
  current_payment_provider: 'stripe',
  plan_details: {
    plan_id: -1,
    plan_group: 'unknown',
    box_kind: 'unknown',
    calorie_percentage_provided: -1,
    duration_in_days: -1,
    number_of_pouches: -1,
    pouch_size_in_grams: -1,
    price_per_pouch: 0,
    pricing_curve_type: 'default',
    pricing_cohort: '125g',
    shipping_country_code: 'GB',
    delivery_fee: 0
  },
  last_pause_details: {
    reason: '',
    subreason: '',
    extra_information: '',
    paused_by: '',
    date: ''
  },
  meal_details: [],
  allergy_details: {
    beef: false,
    chicken: false,
    turkey: false,
    lamb: false
  },
  changePlanDetails: {
    isModalOpen: false,
    planAttributesSelection: initialPlanAttributesSelection,
    proposedPlan: initialProposedPlan,
    changePlanRequestInFlight: false,
    updateProposedPlanRequestInFlight: false
  },
  restartSubscriptionDetails: {
    isModalOpen: false,
    displayInvalidRestartDateMessage: false,
    selectedDate: {
      day: new Date().getDate(),
      month: new Date().getMonth() + 1, // +1 since the month index is 0-based (0 = January) :)
      year: new Date().getFullYear(),
    },
  },
  switchToStripeDetails: {
    isModalOpen: false,
    stripeId: '',
    switchToStripeErrorMessage: null,
    switchToStripeSuccessMessage: null,
    switchToStripeInFlight: false
  },
  stripe_customer_id: '',
  payment_method_details: {
    name: 'card',
    payment_method: 'visa',
    last_four_digits: '1234',
    expiry_month: '1',
    expiry_year: '2024'
  },
  stripe_publishable_key: '',
  sca_enforced: false,
  email: '',
  addCreditCardToSubscriptionDetails: {
    isModalOpen: false,
    paymentMethodId: '',
    addCreditCardToSubscriptionErrorMessage: null,
    addCreditCardToSubscriptionSuccessMessage: null,
    addCreditCardToSubscriptionInFlight: false
  },
  recurring_extra_products: [],
  recipe_surcharges: [],
  delivery_fee: 0,
  account_type: 'paid',
  wants_to_receive_ice: false
}

const initialAccountDetails = {
  full_name: '',
  email: '',
  phone: '',
  full_address: '',
  preferred_language: 'en',
  assumed_country: 'Great Britain',
  refer_a_friend_code: '',
  is_staff_discount_holder: false,
  collected_from_work: false,
  referral_copy: '',
  discount_code_id: -1,
  admin_notes: '',
  two_day_postcode: false,
  signup_discount: {
    active: false,
    present: false,
    discount_code: '',
    discount_amount: '',
    discount_basis: '',
    channel: ''
  },
  is_ambassador: false,
  is_influencer: false,
  influencer_id: -1,
  ambassador_id: -1,
  is_guest: false,
  flags: null,
  sendRafDetailsEmail: {
    sendRafDetailsEmailErrorMessage: null,
    sendRafDetailsEmailSuccessMessage: null,
    sendRafDetailsEmailInFlight: false
  },
  client_referral_link_id: -1,
  staff_discount_holder_id: -1,
  sendRafDetailsSms: {
    sendRafDetailsSmsErrorMessage: null,
    sendRafDetailsSmsSuccess: false,
    sendRafDetailsSmsInFlight: false
  }
}

const initialDeliveriesDetails = {
  deliveryInTopOfViewIndex: 0,
  numberOfBoxIssues: -1,
  numberOfBoxesDelivered: -1,
  deliveries: []
}

const initialApplicableDiscountsDetails = []

const initialDogDetails = []

const initialCustomerHistory = {
  stripe_link: '',
  amplitude_link: '',
  gocardless_link: '',
  sentry_link: '',
  iterable_link: '',
  revolut_link: '',
  entries: []
}

const initialContactPreferences = {
  consented_to_marketing: false,
  contact_preferences_modal_is_open: false,
  wants_to_receive_treats_emails: false,
  current_wants_to_receive_treats_emails: false,
  opted_out_of_woolcool_return_scheme: false,
  current_opted_out_of_woolcool_return_scheme: false,
  wants_to_receive_ice: false,
  current_wants_to_receive_ice: false
}

const initialMarketingPreferences: MarketingPreferenceData = {
  user: {
    __typename: 'User',
    selectedMarketingMethodPurposes: [],
    availableMarketingMethodPurposes: []
  },
  errorMessage: null
}

const initialDeliveryDateCalendar = {
  isOpen: false,
  boxId: -1,
  deliveryWindow: {
    period_name: '',
    month_and_year: '',
    dates: [],
    days_to_pad_at_start: -1,
    days_to_pad_at_end: -1,
    today: ''
  },
  selectedDate: {
    date: '',
    formatted_date: '',
    full_date: '',
    day_name: '',
    is_deliverable: false
  },
  previousDeliveryDate: '',
  targetNumberOfCells: -1,
  currentDeliveryDate: '',
  adjustFutureBoxes: true,
  disableAdjustFutureBoxes: false
}

const initialShippingDetails = {
  noAddress: false,
  shippingCountries: [
    {
      name: 'Great Britain',
      code: 'GB',
      currency: 'GBP'
    },
    {
      name: 'Northern Ireland',
      code: 'NI',
      currency: 'GBP'
    },
    {
      name: 'Ireland',
      code: 'IE',
      currency: 'EUR'
    },
    {
      name: 'Netherlands',
      code: 'NL',
      currency: 'EUR'
    },
    {
      name: 'Belgium',
      code: 'BE',
      currency: 'EUR'
    },
    {
      name: 'Poland',
      code: 'PL',
      currency: 'PLN'
    },
    {
      name: 'Germany',
      code: 'DE',
      currency: 'EUR'
    }
  ],
  shippingCountryCode: 'IE',
  deliveryAreaName: '',
  regularLeadTimeInHours: -1,
  adHocLeadTimeInHours: -1,
  regularDeliverableDates: '',
  adHocDeliverableDates: '',
  isEligibleForCarrierPreference: false,
  carrierPreference: '',
  deliveryAreasUserId: -1,
  canManageDeliveryAreasUser: false
}

const initialFetchingStatuses = {
  accountDetails: false,
  appliedDiscountsDetails: false,
  customerHistoryEvents: false,
  deliveriesDetails: false,
  dogsDetails: false,
  subscriptionDetails: false,
  deliveryCalendar: false,
  deliveriesHistory: false,
  contactPreferencesStatus: false,
  shippingDetailsStatus: false,
  marketingPreferencesState: false,
  wants_to_receive_ice: false
}

const init = (
  csrfToken: CSRFToken,
  selectedUserId: number,
  selectedSubscriptionId: number,
  searchParams: SearchParams,
  shippingCountries: Array<ShippingCountry>
): State => {
  return {
    csrfToken: csrfToken,
    fetchingStatuses: initialFetchingStatuses,
    bannerMessage: {
      showMessage: false,
      message: ''
    },
    searchParams,
    isSearching: false,
    viewingSearchResults: false,
    searchResults: [],
    selectedUserIds: {
      userId: selectedUserId,
      subscriptionId: selectedSubscriptionId
    },
    accountDetails: initialAccountDetails,
    subscriptionDetails: initialSubscriptionDetails,
    deliveriesDetails: initialDeliveriesDetails,
    applicableDiscountsDetails: initialApplicableDiscountsDetails,
    dogsDetails: initialDogDetails,
    customerHistory: initialCustomerHistory,
    contactPreferences: initialContactPreferences,
    marketingPreferences: initialMarketingPreferences,
    deliveryDateCalendar: initialDeliveryDateCalendar,
    shippingDetails: initialShippingDetails,
    deliveries_history: [],
    shippingCountries
  }
}

const translatePlanDetailsToPlanAttributesSelection = ({ subscriptionDetails }: State): PlanAttributesSelection => {
  const { plan_details } = subscriptionDetails
  const { duration_in_days, number_of_pouches, pricing_curve_type, pricing_cohort, delivery_fee } = plan_details
  const pricingCurveType = pricing_curve_type === 'convenience_pricing' ? pricing_curve_type : 'default'
  return {
    durationInDays: duration_in_days,
    numberOfPouches: number_of_pouches,
    deliveryFee: delivery_fee,
    pricingCurve: {
      pricingCurveType: pricingCurveType,
      pricingCohort: pricing_cohort,
    }
  }
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case MESSAGES.MAKE_SEARCH_REQUEST:
      return Object.freeze({
        ...state,
        isSearching: true
      })
    case MESSAGES.RECEIVED_SEARCH_RESPONSE:
      return Object.freeze({
        ...state,
        isSearching: false,
        viewingSearchResults: true,
        searchResults: action.searchResults
      })
    case MESSAGES.TOGGLE_RESTART_MODAL: {
      return Object.freeze({
        ...state,
        subscriptionDetails: {
          ...state.subscriptionDetails,
          restartSubscriptionDetails: {
            ...state.subscriptionDetails.restartSubscriptionDetails,
            isModalOpen: !state.subscriptionDetails.restartSubscriptionDetails.isModalOpen,
          },
        },
      })
    }
    case MESSAGES.UPDATE_DAY: {
      return Object.freeze({
        ...state,
        subscriptionDetails: {
          ...state.subscriptionDetails,
          restartSubscriptionDetails: {
            ...state.subscriptionDetails.restartSubscriptionDetails,
            selectedDate: {
              ...state.subscriptionDetails.restartSubscriptionDetails.selectedDate,
              day: action.day,
            },
          },
        },
      })
    }
    case MESSAGES.UPDATE_MONTH: {
      return Object.freeze({
        ...state,
        subscriptionDetails: {
          ...state.subscriptionDetails,
          restartSubscriptionDetails: {
            ...state.subscriptionDetails.restartSubscriptionDetails,
            selectedDate: {
              ...state.subscriptionDetails.restartSubscriptionDetails.selectedDate,
              month: action.month,
            },
          },
        },
      })
    }
    case MESSAGES.UPDATE_YEAR: {
      return Object.freeze({
        ...state,
        subscriptionDetails: {
          ...state.subscriptionDetails,
          restartSubscriptionDetails: {
            ...state.subscriptionDetails.restartSubscriptionDetails,
            selectedDate: {
              ...state.subscriptionDetails.restartSubscriptionDetails.selectedDate,
              year: action.year,
            },
          },
        },
      })
    }
    case MESSAGES.MAKE_ACCOUNT_DETAILS_REQUEST:
      return Object.freeze({
        ...state,
        fetchingStatuses: {
          ...state.fetchingStatuses,
          accountDetails: true
        },
        accountDetails: initialAccountDetails
      })
    case MESSAGES.RECEIVED_ACCOUNT_DETAILS_RESPONSE:
      return Object.freeze({
        ...state,
        fetchingStatuses: {
          ...state.fetchingStatuses,
          accountDetails: false
        },
        accountDetails: {
          sendRafDetailsEmail: {
            ...state.accountDetails.sendRafDetailsEmail
          },
          sendRafDetailsSms: {
            ...state.accountDetails.sendRafDetailsSms
          },
          ...action.accountDetails
        }
      })
    case MESSAGES.UPDATE_SEARCH_STRING: {
      if (action.field === 'accountStatus') {
        // This check is here because the values that the accountStatus field
        // supports are only 'not-specified', 'active', 'paused', 'suspended'
        // and 'guest'. We need to cast action.value (which is a string) to one
        // of these five options in that case.
        //
        // Flow isn't smart enough to parse list membership so we need to
        // specify all options manually.
        const value = (action.value !== 'active' && action.value !== 'paused' && action.value !== 'suspended' && action.value !== 'guest' ? 'not-selected' : action.value)
        return Object.freeze({
          ...state,
          searchParams: {
            ...state.searchParams,
            accountStatus: value
          }
        })
      } else {
        return Object.freeze({
          ...state,
          searchParams: {
            ...state.searchParams,
            [action.field]: action.value
          }
        })
      }
    }
    case MESSAGES.UPDATE_SELECTED_USER_ID:
      return Object.freeze({
        ...state,
        viewingSearchResults: false,
        selectedUserIds: action.selectedUserIds,
        accountDetails: initialAccountDetails,
        subscriptionDetails: initialSubscriptionDetails,
        deliveriesDetails: initialDeliveriesDetails,
        applicableDiscountsDetails: initialApplicableDiscountsDetails,
        dogsDetails: initialDogDetails,
        customerHistory: initialCustomerHistory,
        contactPreferences: initialContactPreferences,
        deliveryDateCalendar: initialDeliveryDateCalendar
      })
    case MESSAGES.MAKE_SUBSCRIPTION_DETAILS_REQUEST:
      return Object.freeze({
        ...state,
        fetchingStatuses: {
          ...state.fetchingStatuses,
          subscriptionDetails: true
        },
        subscriptionDetails: initialSubscriptionDetails
      })
    case MESSAGES.RECEIVE_SUBSCRIPTION_DETAILS_RESPONSE:
      return Object.freeze({
        ...state,
        fetchingStatuses: {
          ...state.fetchingStatuses,
          subscriptionDetails: false,
        },
        subscriptionDetails: {
          changePlanDetails: {
            ...state.subscriptionDetails.changePlanDetails
          },
          restartSubscriptionDetails: {
            ...state.subscriptionDetails.restartSubscriptionDetails,
          },
          switchToStripeDetails: {
            ...state.subscriptionDetails.switchToStripeDetails,
          },
          addCreditCardToSubscriptionDetails: {
            ...state.subscriptionDetails.addCreditCardToSubscriptionDetails
          },
          ...action.subscriptionDetails,
        },
      })
    case MESSAGES.MAKE_DELIVERIES_DETAILS_REQUEST:
      return Object.freeze({
        ...state,
        fetchingStatuses: {
          ...state.fetchingStatuses,
          deliveriesDetails: true
        },
        deliveriesDetails: initialDeliveriesDetails
      })
    case MESSAGES.RECEIVE_DELIVERIES_DETAILS_RESPONSE:
      const nextBox = action.deliveries.find((delivery: DeliveryDetails): ?DeliveryDetails => {
        if (delivery.is_next_box) { return delivery }
      })
      const deliveryInTopOfViewIndex = nextBox ? Math.max(nextBox.box_number - 2, 0) : 0
      return Object.freeze({
        ...state,
        fetchingStatuses: {
          ...state.fetchingStatuses,
          deliveriesDetails: false
        },
        deliveriesDetails: {
          deliveryInTopOfViewIndex: deliveryInTopOfViewIndex,
          deliveries: action.deliveries || [],
          numberOfBoxIssues: action.numberOfBoxIssues || 0,
          numberOfBoxesDelivered: action.numberOfBoxesDelivered || 0
        }
      })
    case MESSAGES.UPDATE_TOP_DELIVERY_INDEX:
      return Object.freeze({
        ...state,
        deliveriesDetails: {
          ...state.deliveriesDetails,
          deliveryInTopOfViewIndex: action.newIndex
        }
      })
    case MESSAGES.MAKE_APPLICABLE_DISCOUNTS_DETAILS_REQUEST:
      return Object.freeze({
        ...state,
        fetchingStatuses: {
          ...state.fetchingStatuses,
          appliedDiscountsDetails: true
        },
        applicableDiscountsDetails: initialApplicableDiscountsDetails
      })
    case MESSAGES.RECEIVE_APPLICABLE_DISCOUNTS_DETAILS_RESPONSE:
      return Object.freeze({
        ...state,
        fetchingStatuses: {
          ...state.fetchingStatuses,
          appliedDiscountsDetails: false
        },
        applicableDiscountsDetails: action.applicableDiscountsDetails
      })
    case MESSAGES.MAKE_DOGS_DETAILS_REQUEST:
      return Object.freeze({
        ...state,
        fetchingStatuses: {
          ...state.fetchingStatuses,
          dogsDetails: true
        },
        dogsDetails: initialDogDetails
      })
    case MESSAGES.RECEIVE_DOGS_DETAILS_RESPONSE:
      return Object.freeze({
        ...state,
        fetchingStatuses: {
          ...state.fetchingStatuses,
          dogsDetails: false
        },
        dogsDetails: action.dogsDetails
      })
    case MESSAGES.MAKE_CUSTOMER_HISTORY_REQUEST:
      return Object.freeze({
        ...state,
        fetchingStatuses: {
          ...state.fetchingStatuses,
          customerHistoryEvents: true
        },
        customerHistory: initialCustomerHistory
      })
    case MESSAGES.RECEIVE_CUSTOMER_HISTORY_RESPONSE:
      return Object.freeze({
        ...state,
        fetchingStatuses: {
          ...state.fetchingStatuses,
          customerHistoryEvents: false
        },
        customerHistory: action.customerHistory
      })
    case MESSAGES.MAKE_CONTACT_PREFERENCES_REQUEST: {
      return Object.freeze({
        ...state,
        fetchingStatuses: {
          ...state.fetchingStatuses,
          contactPreferencesStatus: true,
          wants_to_receive_ice: true
        },
        contactPreferences: initialContactPreferences
      })
    }
    case MESSAGES.RECEIVE_CONTACT_PREFERENCES_RESPONSE: {
      return Object.freeze({
        ...state,
        fetchingStatuses: {
          ...state.fetchingStatuses,
          contactPreferencesStatus: false,
          wants_to_receive_ice: false
        },
        contactPreferences: {
          ...state.contactPreferences,
          ...action.contactPreferences
        },
        subscriptionDetails: {
          ...state.subscriptionDetails,
          wants_to_receive_ice: action.contactPreferences.wants_to_receive_ice
        }
      })
    }
    case MESSAGES.TOGGLE_DOG_DETAILS:
      const newDetails = state.dogsDetails.slice(0)
      newDetails[action.dogIndex] = {
        ...newDetails[action.dogIndex],
        detailsExpanded: !newDetails[action.dogIndex].detailsExpanded
      }
      return Object.freeze({
        ...state,
        dogsDetails: newDetails
      })
    case MESSAGES.TOGGLE_DOG_OVERVIEW:
      const newDetailsOverview = state.dogsDetails.slice(0)
      newDetailsOverview[action.dogIndex] = {
        ...newDetailsOverview[action.dogIndex],
        panelInView: action.panelInView
      }
      return Object.freeze({
        ...state,
        dogsDetails: newDetailsOverview
      })
    case MESSAGES.RECEIEVE_EMPTY_ACCOUNT_DETAILS_RESPONSE:
      return Object.freeze({
        ...state,
        fetchingStatuses: {
          ...state.fetchingStatuses,
          accountDetails: false
        }
      })
    case MESSAGES.RECEIEVE_EMPTY_SUBSCRIPTION_DETAILS_RESPONSE:
      return Object.freeze({
        ...state,
        fetchingStatuses: {
          ...state.fetchingStatuses,
          subscriptionDetails: false
        }
      })
    case MESSAGES.RECEIEVE_EMPTY_APPLICABLE_DISCOUNTS_DETAILS_RESPONSE:
      return Object.freeze({
        ...state,
        fetchingStatuses: {
          ...state.fetchingStatuses,
          appliedDiscountsDetails: false
        }
      })
    case MESSAGES.RECEIEVE_EMPTY_CUSTOMER_HISTORY_RESPONSE:
      return Object.freeze({
        ...state,
        fetchingStatuses: {
          ...state.fetchingStatuses,
          customerHistoryEvents: false
        }
      })
    case MESSAGES.RECEIEVE_EMPTY_CONTACT_PREFERENCES_RESPONSE: {
      return Object.freeze({
        ...state,
        fetchingStatuses: {
          ...state.fetchingStatuses,
          contactPreferencesStatus: false
        }
      })
    }
    case MESSAGES.RECEIEVE_EMPTY_DELIVERIES_DETAILS_RESPONSE:
      return Object.freeze({
        ...state,
        fetchingStatuses: {
          ...state.fetchingStatuses,
          deliveriesDetails: false
        }
      })
    case MESSAGES.RECEIEVE_EMPTY_DOGS_DETAILS_RESPONSE:
      return Object.freeze({
        ...state,
        fetchingStatuses: {
          ...state.fetchingStatuses,
          dogsDetails: false
        }
      })
    case MESSAGES.PUSH_BOX_N_WEEKS:
      return Object.freeze({
        ...state,
        fetchingStatuses: {
          ...state.fetchingStatuses,
          deliveriesDetails: true
        }
      })
    case MESSAGES.RESTART_SUBSCRIPTION:
      return Object.freeze({
        ...state,
        fetchingStatuses: {
          ...state.fetchingStatuses,
          subscriptionDetails: true,
          deliveriesDetails: true
        }
      })
    case MESSAGES.CLEAR_SEARCH_RESULTS:
      return Object.freeze({
        ...state,
        searchResults: []
      })
    case MESSAGES.CLEAR_SEARCH_PARAMS:
      return Object.freeze({
        ...state,
        searchParams: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          postcode: '',
          dogsName: '',
          dogsBreed: '',
          rafCode: '',
          accountStatus: 'not-selected',
          shippingCountryCode: 'not-selected'
        }
      })
    case MESSAGES.RESPOND_TO_BROWSER_NAVIGATION:
      return action.state

    case MESSAGES.DISPLAY_BANNER_MESSAGE:
      return Object.freeze({
        ...state,
        bannerMessage: {
          showMessage: true,
          message: action.message
        }
      })
    case MESSAGES.HIDE_BANNER_MESSAGE:
      return Object.freeze({
        ...state,
        bannerMessage: {
          showMessage: false,
          message: ''
        }
      })
    case MESSAGES.CHANGE_BOX_DELIVERY_DATE:
      return Object.freeze({
        ...state,
        fetchingStatuses: {
          ...state.fetchingStatuses,
          deliveriesDetails: true
        }
      })
    case MESSAGES.HANDLE_DATE_CHANGE:
      return Object.freeze({
        ...state,
        deliveryDateCalendar: {
          ...state.deliveryDateCalendar,
          selectedDate: action.calendarDate
        }
      })
    case MESSAGES.OPEN_DELIVERY_DATE_CALENDAR:
      return Object.freeze({
        ...state,
        deliveryDateCalendar: {
          ...state.deliveryDateCalendar,
          boxId: action.delivery.box_id,
          currentDeliveryDate: action.delivery.date_details.fetchable_delivery_date,
          isOpen: true,
          disableAdjustFutureBoxes: action.delivery.disable_adjust_future_boxes
        }
      })
    case MESSAGES.CLOSE_DELIVERY_DATE_CALENDAR:
      return Object.freeze({
        ...state,
        deliveryDateCalendar: {
          ...state.deliveryDateCalendar,
          isOpen: false
        }
      })
    case MESSAGES.TOGGLE_CONTACT_PREFERENCES_MODAL: {
      return Object.freeze({
        ...state,
        contactPreferences: {
          ...state.contactPreferences,
          contact_preferences_modal_is_open: !state.contactPreferences.contact_preferences_modal_is_open,
          current_wants_to_receive_treats_emails: state.contactPreferences.wants_to_receive_treats_emails,
          current_opted_out_of_woolcool_return_scheme: state.contactPreferences.opted_out_of_woolcool_return_scheme,
          current_wants_to_receive_ice: state.contactPreferences.wants_to_receive_ice
        }
      })
    }
    case MESSAGES.TOGGLE_CHANGE_PLAN_MODAL: {
      return Object.freeze({
        ...state,
        subscriptionDetails: {
          ...state.subscriptionDetails,
          changePlanDetails: {
            ...state.subscriptionDetails.changePlanDetails,
            proposedPlan: initialProposedPlan,
            planAttributesSelection: translatePlanDetailsToPlanAttributesSelection(state),
            isModalOpen: !state.subscriptionDetails.changePlanDetails.isModalOpen
          }
        }
      })
    }
    case MESSAGES.UPDATE_PRICING_COHORT_SELECTION: {
      return Object.freeze({
        ...state,
        subscriptionDetails: {
          ...state.subscriptionDetails,
          changePlanDetails: {
            ...state.subscriptionDetails.changePlanDetails,
            planAttributesSelection: {
              ...state.subscriptionDetails.changePlanDetails.planAttributesSelection,
              pricingCurve: {
                ...state.subscriptionDetails.changePlanDetails.planAttributesSelection.pricingCurve,
                pricingCohort: action.pricingCohort
              }
            }
          }
        }
      })
    }
    case MESSAGES.UPDATE_DURATION_IN_DAYS_SELECTION: {
      return Object.freeze({
        ...state,
        subscriptionDetails: {
          ...state.subscriptionDetails,
          changePlanDetails: {
            ...state.subscriptionDetails.changePlanDetails,
            planAttributesSelection: {
              ...state.subscriptionDetails.changePlanDetails.planAttributesSelection,
              durationInDays: action.durationInDays
            }
          }
        }
      })
    }
    case MESSAGES.UPDATE_NUMBER_OF_POUCHES_SELECTION: {
      return Object.freeze({
        ...state,
        subscriptionDetails: {
          ...state.subscriptionDetails,
          changePlanDetails: {
            ...state.subscriptionDetails.changePlanDetails,
            planAttributesSelection: {
              ...state.subscriptionDetails.changePlanDetails.planAttributesSelection,
              numberOfPouches: action.numberOfPouches
            }
          }
        }
      })
    }
    case MESSAGES.TOGGLE_CURRENT_TREATS_EMAIL_PREFERENCE: {
      return Object.freeze({
        ...state,
        contactPreferences: {
          ...state.contactPreferences,
          current_wants_to_receive_treats_emails: !state.contactPreferences.current_wants_to_receive_treats_emails
        }
      })
    }
    case MESSAGES.TOGGLE_CURRENT_WOOLCOOL_RETURN_SCHEME_PREFERENCE: {
      return Object.freeze({
        ...state,
        contactPreferences: {
          ...state.contactPreferences,
          current_opted_out_of_woolcool_return_scheme: !state.contactPreferences.current_opted_out_of_woolcool_return_scheme
        }
      })
    }
    case MESSAGES.TOGGLE_CURRENT_WANTS_TO_RECEIVE_ICE_PREFERENCE: {
      return Object.freeze({
        ...state,
        contactPreferences: {
          ...state.contactPreferences,
          current_wants_to_receive_ice: !state.contactPreferences.current_wants_to_receive_ice
        },
      })
    }
    case MESSAGES.TOGGLE_WANTS_TO_RECEIVE_ICE_REQUEST: {
      return Object.freeze({
        ...state,
        fetchingStatuses: {
          ...state.fetchingStatuses,
          wants_to_receive_ice: true
        }
      })
    }
    case MESSAGES.TOGGLE_WANTS_TO_RECEIVE_ICE_RESPONSE: {
      return Object.freeze({
        ...state,
        fetchingStatuses: {
          ...state.fetchingStatuses,
          wants_to_receive_ice: false
        },
        contactPreferences: {
          ...state.contactPreferences,
          wants_to_receive_ice: action.value
        },
        subscriptionDetails: {
          ...state.subscriptionDetails,
          wants_to_receive_ice: action.value
        }
      })
    }
    case MESSAGES.MAKE_CALENDAR_REQUEST:
      return Object.freeze({
        ...state,
        fetchingStatuses: {
          ...state.fetchingStatuses,
          deliveryCalendar: true
        }
      })
    case MESSAGES.RECEIVE_CALENDAR_RESPONSE:
      return Object.freeze({
        ...state,
        deliveryDateCalendar: {
          ...state.deliveryDateCalendar,
          deliveryWindow: action.deliveryWindow,
          previousDeliveryDate: action.previousDeliveryDate,
          selectedDate: action.selectedDate
        }
      })
    case MESSAGES.TOGGLE_ADJUST_FUTURE_BOXES:
      return Object.freeze({
        ...state,
        deliveryDateCalendar: {
          ...state.deliveryDateCalendar,
          adjustFutureBoxes: !state.deliveryDateCalendar.adjustFutureBoxes
        }
      })
    case MESSAGES.RECEIVE_DELIVERIES_HISTORY: {
      return Object.freeze({
        ...state,
        deliveries_history: action.deliveries_history,
        fetchingStatuses: {
          ...state.fetchingStatuses,
          deliveriesHistory: false
        }
      })
    }
    case MESSAGES.MAKE_DELIVERIES_HISTORY_REQUEST: {
      return Object.freeze({
        ...state,
        fetchingStatuses: {
          ...state.fetchingStatuses,
          deliveriesHistory: true
        }
      })
    }
    case MESSAGES.START_CHANGE_PLAN_REQUEST: {
      return Object.freeze({
        ...state,
        subscriptionDetails: {
          ...state.subscriptionDetails,
          changePlanDetails: {
            ...state.subscriptionDetails.changePlanDetails,
            changePlanRequestInFlight: true
          }
        }
      })
    }
    case MESSAGES.COMPLETE_CHANGE_PLAN_REQUEST: {
      return Object.freeze({
        ...state,
        subscriptionDetails: {
          ...state.subscriptionDetails,
          changePlanDetails: {
            ...state.subscriptionDetails.changePlanDetails,
            changePlanRequestInFlight: false
          }
        }
      })
    }
    case MESSAGES.START_UPDATE_PROPOSED_PLAN_REQUEST: {
      return Object.freeze({
        ...state,
        subscriptionDetails: {
          ...state.subscriptionDetails,
          changePlanDetails: {
            ...state.subscriptionDetails.changePlanDetails,
            updateProposedPlanRequestInFlight: true
          }
        }
      })
    }
    case MESSAGES.COMPLETE_UPDATE_PROPOSED_PLAN_REQUEST: {
      return Object.freeze({
        ...state,
        subscriptionDetails: {
          ...state.subscriptionDetails,
          changePlanDetails: {
            ...state.subscriptionDetails.changePlanDetails,
            updateProposedPlanRequestInFlight: false
          }
        }
      })
    }
    case MESSAGES.UPDATE_CHANGE_PLAN_MODAL_PROPOSED_PLAN: {
      return Object.freeze({
        ...state,
        subscriptionDetails: {
          ...state.subscriptionDetails,
          changePlanDetails: {
            ...state.subscriptionDetails.changePlanDetails,
            proposedPlan: action.proposedPlan
          }
        }
      })
    }
    case MESSAGES.DISPLAY_INVALID_RESTART_DATE_MESSAGE: {
      return Object.freeze({
        ...state,
        subscriptionDetails: {
          ...state.subscriptionDetails,
          restartSubscriptionDetails: {
            ...state.subscriptionDetails.restartSubscriptionDetails,
            displayInvalidRestartDateMessage: true
          }
        }
      })
    }
    case MESSAGES.TOGGLE_SWITCH_TO_STRIPE_MODAL: {
      return Object.freeze({
        ...state,
        subscriptionDetails: {
          ...state.subscriptionDetails,
          switchToStripeDetails: {
            ...state.subscriptionDetails.switchToStripeDetails,
            isModalOpen: !state.subscriptionDetails.switchToStripeDetails.isModalOpen
          }
        }
      })
    }
    case MESSAGES.TOGGLE_ADD_CREDIT_CARD_TO_SUBSCRIPTION_MODAL: {
      return Object.freeze({
        ...state,
        subscriptionDetails: {
          ...state.subscriptionDetails,
          addCreditCardToSubscriptionDetails: {
            ...state.subscriptionDetails.addCreditCardToSubscriptionDetails,
            isModalOpen: !state.subscriptionDetails.addCreditCardToSubscriptionDetails.isModalOpen
          }
        }
      })
    }
    case MESSAGES.ADD_CREDIT_CARD_TO_SUBSCRIPTION_SUCCESS: {
      return Object.freeze({
        ...state,
        subscriptionDetails: {
          ...state.subscriptionDetails,
          current_payment_provider: 'stripe',
          addCreditCardToSubscriptionDetails: {
            ...state.subscriptionDetails.addCreditCardToSubscriptionDetails,
            addCreditCardToSubscriptionSuccessMessage: action.message,
            addCreditCardToSubscriptionInFlight: false
          }
        }
      })
    }
    case MESSAGES.ADD_CREDIT_CARD_TO_SUBSCRIPTION_FAILURE: {
      return Object.freeze({
        ...state,
        subscriptionDetails: {
          ...state.subscriptionDetails,
          addCreditCardToSubscriptionDetails: {
            ...state.subscriptionDetails.addCreditCardToSubscriptionDetails,
            addCreditCardToSubscriptionErrorMessage: action.message,
            addCreditCardToSubscriptionInFlight: false
          }
        }
      })
    }
    case MESSAGES.START_ADD_CREDIT_CARD_TO_SUBSCRIPTION_REQUEST: {
      return Object.freeze({
        ...state,
        subscriptionDetails: {
          ...state.subscriptionDetails,
          addCreditCardToSubscriptionDetails: {
            ...state.subscriptionDetails.addCreditCardToSubscriptionDetails,
            addCreditCardToSubscriptionInFlight: true
          }
        }
      })
    }
    case MESSAGES.SEND_RAF_DETAILS_EMAIL_SUCCESS: {
      return Object.freeze({
        ...state,
        accountDetails: {
          ...state.accountDetails,
          sendRafDetailsEmail: {
            ...state.accountDetails.sendRafDetailsEmail,
            sendRafDetailsEmailSuccessMessage: action.message,
            sendRafDetailsEmailInFlight: false
          }
        }
      })
    }
    case MESSAGES.SEND_RAF_DETAILS_EMAIL_FAILURE: {
      return Object.freeze({
        ...state,
        accountDetails: {
          ...state.accountDetails,
          sendRafDetailsEmail: {
            ...state.accountDetails.sendRafDetailsEmail,
            sendRafDetailsEmailErrorMessage: action.message,
            sendRafDetailsEmailInFlight: false
          }
        }
      })
    }
    case MESSAGES.START_SEND_RAF_DETAILS_EMAIL_REQUEST: {
      return Object.freeze({
        ...state,
        accountDetails: {
          ...state.accountDetails,
          sendRafDetailsEmail: {
            ...state.accountDetails.sendRafDetailsEmail,
            sendRafDetailsEmailInFlight: true
          }
        }
      })
    }
    case MESSAGES.SEND_RAF_DETAILS_SMS_SUCCESS: {
      return Object.freeze({
        ...state,
        accountDetails: {
          ...state.accountDetails,
          sendRafDetailsSms: {
            ...state.accountDetails.sendRafDetailsSms,
            sendRafDetailsSmsSuccess: true,
            sendRafDetailsSmsInFlight: false
          }
        }
      })
    }
    case MESSAGES.SEND_RAF_DETAILS_SMS_FAILURE: {
      return Object.freeze({
        ...state,
        accountDetails: {
          ...state.accountDetails,
          sendRafDetailsSms: {
            ...state.accountDetails.sendRafDetailsSms,
            sendRafDetailsSmsErrorMessage: action.errorMessage,
            sendRafDetailsSmsInFlight: false,
            sendRafDetailsSmsSuccess: false
          }
        }
      })
    }
    case MESSAGES.START_SEND_RAF_DETAILS_SMS_REQUEST: {
      return Object.freeze({
        ...state,
        accountDetails: {
          ...state.accountDetails,
          sendRafDetailsSms: {
            ...state.accountDetails.sendRafDetailsSms,
            sendRafDetailsSmsInFlight: true
          }
        }
      })
    }
    case MESSAGES.UPDATE_STRIPE_ID: {
      return Object.freeze({
        ...state,
        subscriptionDetails: {
          ...state.subscriptionDetails,
          switchToStripeDetails: {
            ...state.subscriptionDetails.switchToStripeDetails,
            stripeId: action.stripeId
          }
        }
      })
    }
    case MESSAGES.START_SWITCH_TO_STRIPE_REQUEST: {
      return Object.freeze({
        ...state,
        subscriptionDetails: {
          ...state.subscriptionDetails,
          switchToStripeDetails: {
            ...state.subscriptionDetails.switchToStripeDetails,
            switchToStripeInFlight: true
          }
        }
      })
    }
    case MESSAGES.SWITCH_TO_STRIPE_SUCCESS: {
      return Object.freeze({
        ...state,
        subscriptionDetails: {
          ...state.subscriptionDetails,
          current_payment_provider: 'stripe',
          switchToStripeDetails: {
            ...state.subscriptionDetails.switchToStripeDetails,
            switchToStripeSuccessMessage: action.message,
            switchToStripeInFlight: false
          }
        }
      })
    }
    case MESSAGES.SWITCH_TO_STRIPE_FAILURE: {
      return Object.freeze({
        ...state,
        subscriptionDetails: {
          ...state.subscriptionDetails,
          switchToStripeDetails: {
            ...state.subscriptionDetails.switchToStripeDetails,
            switchToStripeErrorMessage: action.message,
            switchToStripeInFlight: false
          }
        }
      })
    }
    case MESSAGES.START_GET_SHIPPING_DETAILS_RESPONSE: {
      return Object.freeze({
        ...state,
        fetchingStatuses: {
          ...state.fetchingStatuses,
          shippingDetailsStatus: true
        }
      })
    }
    case MESSAGES.RECEIVE_SHIPPING_DETAILS_RESPONSE: {
      if (action.shippingDetails) {
        return Object.freeze({
          ...state,
          fetchingStatuses: {
            ...state.fetchingStatuses,
            shippingDetailsStatus: false
          },
          shippingDetails: action.shippingDetails
        })
      } else {
        return state
      }
    }
    case MESSAGES.ERROR_UPDATING_MARKETING_PREFERENCES: {
      return Object.freeze({
        ...state,
        fetchingStatuses: {
          ...state.fetchingStatuses,
          marketingPreferencesState: false
        },
        marketingPreferences: {
          ...state.marketingPreferences,
          errorMessage: 'There was an error updating your Marketing Preferences'
        }
      })
    }
    case MESSAGES.MAKE_MARKETING_PREFERENCES_REQUEST: {
      return Object.freeze({
        ...state,
        fetchingStatuses: {
          ...state.fetchingStatuses,
          marketingPreferencesState: true
        },
        marketingPreferences: {
          ...state.marketingPreferences,
          errorMessage: null
        }
      })
    }
    case MESSAGES.RECEIEVE_EMPTY_MARKETING_PREFERENCES_RESPONSE: {
      return Object.freeze({
        ...state,
        fetchingStatuses: {
          ...state.fetchingStatuses,
          marketingPreferencesState: false
        }
      })
    }
    case MESSAGES.RECEIVE_MARKETING_PREFERENCES_RESPONSE: {
      return Object.freeze({
        ...state,
        fetchingStatuses: {
          ...state.fetchingStatuses,
          marketingPreferencesState: false
        },
        marketingPreferences: {
          ...state.marketingPreferences,
          user: {
            ...state.marketingPreferences.user,
            selectedMarketingMethodPurposes: action.existingPreferences.map(
              (existingPreference: ExistingPreference): SelectedMarketingMethodPurposes => {
                return {
                  __typename: 'SelectedMarketingMethodPurpose',
                  id: existingPreference.id,
                  supportedMarketingMethodPurpose: {
                    __typename: 'SupportedMarketingMethodPurpose',
                    marketingMethod: {
                      __typename: 'MarketingMethod',
                      ...existingPreference.supportedMarketingMethodPurpose
                        .marketingMethod,
                      id: existingPreference.supportedMarketingMethodPurpose.marketingMethod.id.toString()
                    },
                    marketingPurpose: {
                      __typename: 'MarketingPurpose',
                      ...existingPreference.supportedMarketingMethodPurpose
                        .marketingPurpose,
                      id: existingPreference.supportedMarketingMethodPurpose.marketingPurpose.id.toString()
                    }
                  }
                }
              }
            ),
            availableMarketingMethodPurposes: action.availablePreferences.map(
              (availablePreference: AvailablePreference): SupportedMarketingMethodPurposes => {
                return {
                  __typename: 'SupportedMarketingMethodPurpose',
                  id: availablePreference.id,
                  marketingMethod: {
                    __typename: 'MarketingMethod',
                    ...availablePreference.marketingMethod,
                    id: availablePreference.marketingMethod.id.toString(),
                    name: availablePreference.marketingMethod.name
                  },
                  marketingPurpose: {
                    __typename: 'MarketingPurpose',
                    ...availablePreference.marketingPurpose,
                    id: availablePreference.marketingPurpose.id.toString(),
                    name: availablePreference.marketingPurpose.name,
                    description: availablePreference.marketingPurpose.description
                  },
                  preselect: availablePreference.preselect,
                  shippingCountry: {
                    __typename: 'ShippingCountry',
                    ...availablePreference.shippingCountry
                  }
                }
              }
            )
          },
          errorMessage: null
        }
      })
    }
    default:
      return state
  }
}

export {
  initialShippingDetails,
  init
}

export default reducer
