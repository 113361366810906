// @flow

import {Analytics} from '../../Analytics'

type SearchDetails = {|
  firstName: string,
  lastName: string,
  email: string,
  postcode: string,
  phone: string,
  dogsName: string,
  dogsBreed: string,
  rafCode: string,
  accountStatus: string,
  selectedIndex: number,
  selectedUserId: number
|}

class SingleCustomerViewAnalytics {
  static eventName = 'admin_single_customer_view_interactions'
  static context = 'Admin Single Customer View'

  static selectSearchResult (searchDetails: SearchDetails): void {
    const interactionEventProperties = {
      context: this.context,
      object: 'Search Result',
      action: 'Clicked'
    }
    Analytics.trackInteraction(
      this.eventName,
      interactionEventProperties,
      {searchDetails}
    )
  }
}

export default SingleCustomerViewAnalytics
