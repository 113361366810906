// @noflow
import React from 'react'

import { Locale } from '@/utils/currency'

import { Expand } from '@/components/elements/atoms/Animated/Animated'
import Card from '@/components/elements/atoms/Card/Card'

import type { CheckoutPage } from '../../../queries/__generated__/CheckoutPage'

import PayPalButton from '../PayPalButton'
import { UseBuySubscriptionWithPayPal } from '../hooks/useBuySubscriptionWithPayPal'

type Props = {
  show: boolean
  payPal: {
    payPalButtonClicked: UseBuySubscriptionWithPayPal['payPalButtonClicked']
    paypalClientId: string
    currency: CheckoutPage['guest']['assumedShippingCountry']['currency']
    payPalAuthorisationResponse: UseBuySubscriptionWithPayPal['payPalAuthorisationResponse']
    handlePayPalInContextSubmission: UseBuySubscriptionWithPayPal['handlePayPalInContextSubmission']
    disablePayPalButton: boolean
    locale: Locale
  }
}

const PayPalOption = ({ show, payPal }: Props): JSX.Element => {
  const {
    payPalButtonClicked,
    paypalClientId,
    currency,
    payPalAuthorisationResponse,
    handlePayPalInContextSubmission,
    disablePayPalButton,
    locale
  } = payPal

  return (
    <Expand show={show} margin={{ top: 1.6 }}>
      <Card variant="brandBlue100" squared={{ topLeft: true, topRight: true }}>
        <PayPalButton
          paypalClientId={paypalClientId}
          currency={currency}
          payPalButtonClicked={payPalButtonClicked}
          disablePayPalButton={disablePayPalButton}
          payPalAuthorisationResponse={payPalAuthorisationResponse}
          handlePayPalInContextSubmission={handlePayPalInContextSubmission}
          locale={locale}
        />
      </Card>
    </Expand>
  )
}

export default PayPalOption
