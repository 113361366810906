// @flow

import * as ACTIONS from '../actions'

import type { Dispatch, Thunk } from 'redux'
import type { RailsModelID as ID } from '../../../shared_types/ids'
import type { State } from '../reducers'

type Props = {|
  image: File,
  dogId: ID,
  onSuccess: (string) => void,
  onError: () => void
|}

const { fetch, FormData } = global

/**
 * Upload the dog image
 *
 * Body: {
 *   avatar: The image file to upload
 * }
 *
 * Response: {
 *   avatar_url: the url to the uploaded image
 * }
 */
const imageUpload = ({
  image,
  dogId,
  onSuccess,
  onError
}: Props): Thunk => {
  return (dispatch: Dispatch, getState: () => State): Promise<void> => {
    dispatch(ACTIONS.startRequest('photoUpdate', dogId))
    const { globalAttributes } = getState()
    const { token, csrfToken } = globalAttributes

    const endpoint = `/api/clients/v1/my-dog/${dogId}/avatar?token=${token}`
    const headers = {
      'X-CSRF-Token': csrfToken
    }
    const body = new FormData()
    body.append('avatar', image)
    const method = 'POST'
    const credentials = 'same-origin'

    return new Promise((): void => {
      fetch(endpoint, { headers, method, credentials, body })
        .then((response: Response): ?Promise<{avatar_url: string}> => {
          if (!response.ok) {
            dispatch(ACTIONS.completeRequest(
              'photoUpdate',
              {
                error: 'Error uploading the photo'
              }
            ))
            onError()
          }

          return response.json()
        }).then(({ avatar_url: avatarUrl }: { avatar_url: string }): void => {
          if (!avatarUrl) throw new Error('Missing avatarUrl')

          dispatch(ACTIONS.completeRequest(
            'photoUpdate',
            {}
          ))

          onSuccess(avatarUrl)
        })
    })
  }
}

export default imageUpload
