// @noflow
import { isUndefined } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { chooseCarrierDeliveryNoteLimit } from '@/utils/deliveryCarrier'

import BREAKPOINTS from '@/constants/Breakpoints'
import { UPDATED_DELIVERY_DETAILS } from '@/constants/RibbonEvents'

import useWindowSize from '@/hooks/useWindowSize'

import AccountDetailsCard from '../../MyDetailsPage/components/AccountDetailsCard/AccountDetailsCard'
import { Button } from '@/components/elements/atoms/Button'
import Modal from '@/components/elements/atoms/Modal/Modal'
import MultiLineTextField from '@/components/elements/atoms/MultiLineTextField/MultiLineTextField'
import TextField from '@/components/elements/atoms/TextField/TextField'
import Form from '@/components/elements/molecules/Form/Form'

import { MyDetailsPersonalDetailsQuery_user_address as Address } from '../../MyDetailsPage/queries/__generated__/MyDetailsPersonalDetailsQuery'

import DeliveryNotes from './DeliveryNotes'

type Props = {
  address: Address | null
  loading: boolean
  updateDefaultAddress: (data: Partial<Address>) => void
  deliveryNotesLimit?: number
}

const DefaultAddress = ({
  address,
  loading,
  deliveryNotesLimit,
  updateDefaultAddress
}: Props): JSX.Element => {
  const { openModal } = useParams()
  const { windowWidth } = useWindowSize()
  const [initialised, setInitialised] = useState(false)
  const [defaultAddressModalIsOpen, setDefaultAddressModalIsOpen] =
    useState(false)

  const toggleDefaultAddressModal = useCallback(() => {
    setDefaultAddressModalIsOpen((prev) => !prev)
  }, [])

  const onSubmit = useCallback(
    (data: { [key: string]: string }) => {
      updateDefaultAddress(data)
      toggleDefaultAddressModal()
      window.ribbon && window.ribbon('trigger', UPDATED_DELIVERY_DETAILS)
    },
    [toggleDefaultAddressModal, updateDefaultAddress]
  )

  const defaultDeliveryNoteLimit = chooseCarrierDeliveryNoteLimit(address)

  useEffect(() => {
    if (initialised) return
    if (!address) return
    if (!isUndefined(openModal)) {
      toggleDefaultAddressModal()
    }
    setInitialised(true)
  }, [address, initialised, openModal, toggleDefaultAddressModal])

  return (
    <>
      <AccountDetailsCard
        loading={loading}
        lines={2}
        shortLine={false}
        label={{ text: 'my_details.address_book.default_address.label' }}
        text={{
          text: 'my_details.address_book.default_address.text',
          variables: {
            ...address
          }
        }}
        onClick={toggleDefaultAddressModal}
        prompt={'my_details.address_book.default_address.prompt'}
        icon={'house'}
        namespace={'dashboard'}
        linkPosition={'top'}
        bottomExtra={
          <DeliveryNotes deliveryNotes={address?.deliveryNotes || null} />
        }
        identifier="address_book.default_address"
      />
      <Modal
        isModalOpen={defaultAddressModalIsOpen}
        setOpenModal={toggleDefaultAddressModal}
        width={600}
        bottomSticky={windowWidth < BREAKPOINTS.md}
      >
        <Form onSubmit={onSubmit}>
          <TextField label="address1" initialValue={address?.address1} />
          <TextField
            label="address2"
            initialValue={address?.address2 as string}
            optional
          />
          <TextField label="city" initialValue={address?.city} />
          <TextField
            label="postcode"
            type="postcode"
            initialValue={address?.postcode}
            shippingCountryCode={address?.shippingCountry.code}
          />
          <MultiLineTextField
            label="deliveryNotes"
            alwaysShowLabel
            rows={4}
            initialValue={address?.deliveryNotes}
            maxLength={deliveryNotesLimit ?? defaultDeliveryNoteLimit}
            optional
          />
          <Button
            variant="primary"
            typography={{
              namespace: 'dashboard',
              text: 'my_details.address_book.default_address.save'
            }}
            // eslint-disable-next-line react/jsx-no-bind, @typescript-eslint/no-empty-function
            onClick={() => {}} // Form handles the submit so just need empty function here
            identifier="address_book.save_default_address"
          />
        </Form>
      </Modal>
    </>
  )
}

export default DefaultAddress
