// @noflow
import React, { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'

// Component
import segmentTrack from '@/components/analytics/Analytics'
import Card, { generateVariant } from '@/components/elements/atoms/Card/Card'
import Image from '@/components/elements/atoms/Image/Image'
import Text from '@/components/elements/atoms/Text/Text'
import { NavigateContext } from '@/components/pages/App'

import STYLES from './AddMore.module.sass'

type Props = {
  link: string
  dogName: string
  hasExtras?: boolean
}

const AddMore = ({
  link,
  dogName,
  hasExtras = false
}: Props): JSX.Element | null => {
  const navigate = useContext(NavigateContext)
  const { t } = useTranslation('shared')
  const copyContext = 'box_breakdown.add_more_card'

  const spaceForMoreClicked = useCallback((): void => {
    segmentTrack('Order Details Page - Space for more items area clicked', {
      link
    })
    navigate(link, link)
  }, [link, navigate])

  if (hasExtras) return null

  return (
    <Card
      border="dashed"
      variant={generateVariant(
        'brandYellow200',
        'brandYellow600',
        'brandYellow300'
      )}
      onClick={spaceForMoreClicked}
      identifier="box_breakdown.see_extras"
    >
      <div className={STYLES.content}>
        <div>
          <Image
            slug="add-more-dog"
            image={{ width: 78, height: 84 }}
            alt={t('box_breakdown.boost_upsell_image_alt')}
          />
        </div>

        <div>
          <Text
            variant="display16"
            colour="brandBlue500"
            namespace="shared"
            text={`${copyContext}.title`}
            margin={false}
          />
          <Text
            variant="textRegular16"
            colour="brandBlue500"
            namespace="shared"
            text={`${copyContext}.description`}
            variables={{ dogName }}
            margin={false}
          />
        </div>
      </div>
    </Card>
  )
}

export { Props }
export default AddMore
