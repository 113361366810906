import { useNotifications } from '@/context/notifications/notifications'
import { useEffect } from 'react'

const namespace = 'account'

const useReactivationNotification = (): void => {
  const search = window.location.search
  const errorMessage = new URLSearchParams(search).get('error')
  const successMessage = new URLSearchParams(search).get('success')
  const reactivationMessage =
    new URLSearchParams(search).get('show_reactivation_confirmation') === 'true'
  const pauseSaveConfirmation =
    new URLSearchParams(search).get('show_plan_confirmation') === 'true'

  const { setSuccessNotification, setErrorNotification } = useNotifications()

  useEffect((): void => {
    if (errorMessage) {
      setErrorNotification({
        text: errorMessage
      })
    } else if (successMessage) {
      setSuccessNotification({
        text: successMessage
      })
    } else if (reactivationMessage) {
      setSuccessNotification(
        {
          text: 'home.alerts.reactivation_message',
          namespace
        },
        true
      )
    } else if (pauseSaveConfirmation) {
      setSuccessNotification(
        {
          text: 'home.alerts.plan_updated_confirmation',
          namespace
        },
        true
      )
    }
  }, [
    errorMessage,
    pauseSaveConfirmation,
    reactivationMessage,
    setErrorNotification,
    setSuccessNotification,
    successMessage
  ])
}

export default useReactivationNotification
