import React from 'react'

import CloudinaryImage from '@/components/shared/elements/CloudinaryImage/CloudinaryImage'

type Props = {
  image: string
  alt: string
  size?: 'small' | 'xsmall' | 'medium'
}

const ProductThumb = ({ alt, image, size = 'small' }: Props): JSX.Element => {
  const sizes = {
    medium: 80,
    small: 70,
    xsmall: 60
  }
  return (
    <CloudinaryImage
      image={{
        path: image,
        width: sizes[size],
        height: sizes[size],
        crop: 'fill',
        dpr: window.devicePixelRatio
      }}
      alt={alt}
    />
  )
}

export { Props }
export default ProductThumb
