/* eslint-disable i18next/no-literal-string */
import { gql } from '@apollo/client'

const PLAN_MANAGEMENT_PAGE_V3_QUERY = gql`
  query PlanManagementPageV3Query {
    user {
      id
      dogs {
        id
        name
        gender
      }
    }
  }
`

export { PLAN_MANAGEMENT_PAGE_V3_QUERY }
