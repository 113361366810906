import { gql } from '@apollo/client'

const POST_SIGN_UP_HERO_SECTION_QUERY = gql`
  query PostSignUpHeroSectionQuery {
    user {
      id
      preferredLanguage
      dogs {
        id
        name
        dogProfile {
          id
          avatarUrl
        }
      }
    }
  }
`

export { POST_SIGN_UP_HERO_SECTION_QUERY }
