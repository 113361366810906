/* globals history */
// @flow

import type { Dispatch, Middleware, MiddlewareAPI } from './index'
import type { Action, SearchParams, SearchField } from '../actions'

const generateSearchUrl = (searchParams: SearchParams): string => {
  const param_mapping: { [SearchField]: string } = {
    firstName: 'first_name',
    lastName: 'last_name',
    email: 'email',
    phone: 'phone',
    postcode: 'postcode',
    dogsName: 'dogs_name',
    dogsBreed: 'dogs_breed',
    rafCode: 'raf_code',
    accountStatus: 'account_status',
    shippingCountryCode: 'shipping_country_code'
  }

  // The below Flow error is a known issue:
  // - https://github.com/facebook/flow/issues/2221
  //
  // The `Object#entries` method returns an `Array<mixed` regardless of what
  // the actual types are of the elements of the array. Even though a
  // `SearchParams` has values which are all of the type `string`, Flow
  // assumes that they are of a mixed type.
  //
  // It is in the pipeline that the `Object#entries` type signature will be
  // updated to actually look at the types of the object that is being acted
  // upon in a later release of Flow but for now we must ignore this error
  const params = Object.entries(searchParams)
    // if accountStatus is not selected, ignore that field
    .filter(([k, v]: [string, mixed]): boolean => !((k === 'accountStatus' || k === 'shippingCountryCode') && v === 'not-selected'))
    // $FlowFixMe
    .map(([k, v]: [SearchField, string]): string => v ? `${param_mapping[k]}=${v}` : '')
    .filter((el: string): string => el !== '' ? el : '')

  const endpoint = '/admin/single_customer_view'
  const queryParams = params.join('&')
  return `${endpoint}?${queryParams}`
}

export const stepPersistance: Middleware = ({ getState }: MiddlewareAPI): (Dispatch => Dispatch) => {
  return (next: Dispatch): Dispatch => {
    return (action: Action): Action => {
      const previousState = getState()
      const returnValue = next(action)
      const currentState = getState()
      const { type } = action
      if (type === 'MAKE_SEARCH_REQUEST') {
        try {
          // Record the search we just made
          history.replaceState(previousState, '', `${window.location.pathname}${window.location.search}`)
          history.pushState(currentState, '', generateSearchUrl(currentState.searchParams))
        } catch (err) {
          // Ignore write errors
          console.log(err)
        }
      } else if (type === 'UPDATE_SELECTED_USER_ID') {
        try {
          // Record the user we just selected
          if (currentState.selectedUserIds.userId !== -1) {
            history.replaceState(previousState, '', generateSearchUrl(previousState.searchParams))
            history.pushState(currentState, '', `/admin/single_customer_view/${currentState.selectedUserIds.userId}`)
          }
        } catch (err) {
          // Ignore write errors
          console.log(err)
        }
      }
      return returnValue
    }
  }
}
