// @noflow
import type { FormattedDog } from '@/components/pages/SignupWizardPage/helpers/submitFormData'
import {
  Dog,
  WizardActivityLevel,
  WizardAgeStage,
  WizardBodyCondition,
  WizardEaterType,
  WizardSnackingHabits
} from '@/components/pages/SignupWizardPage/types/types'

import { Eater, Gender } from '@/types'

type Preset = 'tiny-tim' | 'banannah' | 'andrew'

const allPresets: Array<Preset> = ['tiny-tim', 'banannah', 'andrew']

const presetTitle = (preset: Preset): string => {
  switch (preset) {
    case 'tiny-tim':
      return 'Tiny Tim'
    case 'banannah':
      return 'Banannah'
    case 'andrew':
      return 'Absolute Unit Andrew'
  }
}

const presetDescription = (preset: Preset): string => {
  switch (preset) {
    case 'tiny-tim':
      return '160g/day'
    case 'banannah':
      return '330g/day'
    case 'andrew':
      return '1580g/day'
  }
}

const presetSubmissionData = (preset: Preset): FormattedDog => {
  switch (preset) {
    case 'tiny-tim':
      return {
        name: 'Tiny Tim',
        gender: Gender.male,
        breed_id: 21,
        body_shape: WizardBodyCondition.Skinny,
        exercise: 3,
        eater_type: Eater.picky_eater,
        age_in_months: 63,
        age_classification_by_user_when_created: 1,
        brought_home: true,
        weight_in_grams: 4000,
        neutered: true,
        snacking_habits: 'eats_lots_of_snacks',
        allergies: [],
        health_issues: [],
        food_category_ids: ['1'],
        is_rescue: false
      }
    case 'banannah':
      return {
        name: 'Banannah',
        gender: 'female',
        breed_id: 46,
        body_shape: 'just_right',
        exercise: 3,
        eater_type: Eater.eats_anything,
        age_in_months: 84,
        age_classification_by_user_when_created: 1,
        brought_home: true,
        weight_in_grams: 12000,
        neutered: true,
        snacking_habits: 'eats_lots_of_snacks',
        allergies: [],
        health_issues: [],
        food_category_ids: ['2'],
        is_rescue: false
      }
    case 'andrew':
      return {
        name: 'Absolute Unit Andrew',
        gender: Gender.male,
        breed_id: 20,
        body_shape: WizardBodyCondition.Skinny,
        exercise: 2,
        eater_type: Eater.fussy_eater,
        age_in_months: 105,
        age_classification_by_user_when_created: 1,
        brought_home: true,
        weight_in_grams: 60000,
        neutered: true,
        snacking_habits: 'eats_no_snacks',
        allergies: [],
        health_issues: [],
        food_category_ids: ['3'],
        is_rescue: false
      }
  }
}

const presetLocalStorageData = (preset: Preset): Dog => {
  switch (preset) {
    case 'tiny-tim':
      return {
        name: 'Tiny Tim',
        gender: Gender.male,
        ageStage: WizardAgeStage.Adult,
        isRescue: false,
        neutered: true,
        age: { years: 5, months: 3, weeks: null },
        broughtHome: true,
        breed: { id: 21, key: 'breed_21', name: 'Breed 21' },
        foodCategoryIds: ['1'],
        eaterType: WizardEaterType.GoodEater,
        bodyCondition: WizardBodyCondition.Skinny,
        weight: 4000,
        activityLevel: WizardActivityLevel.HyperActive,
        isWorkingDog: false,
        allergies: [],
        healthIssues: [],
        snackingHabits: WizardSnackingHabits.LotsOfSnacks
      }
    case 'banannah':
      return {
        name: 'Banannah',
        gender: Gender.female,
        ageStage: WizardAgeStage.Adult,
        isRescue: false,
        neutered: true,
        age: { years: 7, months: 0, weeks: null },
        broughtHome: true,
        breed: { id: 46, key: 'breed_46', name: 'Breed 46' },
        foodCategoryIds: ['2'],
        eaterType: WizardEaterType.GoodEater,
        bodyCondition: WizardBodyCondition.JustRight,
        weight: 12000,
        activityLevel: WizardActivityLevel.HyperActive,
        isWorkingDog: false,
        allergies: [],
        healthIssues: [],
        snackingHabits: WizardSnackingHabits.LotsOfSnacks
      }
    case 'andrew':
      return {
        name: 'Absolute Unit Andrew',
        gender: Gender.male,
        ageStage: WizardAgeStage.Adult,
        isRescue: false,
        neutered: true,
        age: { years: 8, months: 9, weeks: null },
        broughtHome: true,
        breed: { id: 20, key: 'breed_20', name: 'Breed 20' },
        foodCategoryIds: ['3'],
        eaterType: WizardEaterType.GoodEater,
        bodyCondition: WizardBodyCondition.Skinny,
        weight: 60000,
        activityLevel: WizardActivityLevel.Leisurely,
        isWorkingDog: false,
        allergies: [],
        healthIssues: [],
        snackingHabits: WizardSnackingHabits.NoSnacks
      }
  }
}

const savePresetsInLocalStorage = (presets: Array<Preset>): void => {
  const partialState = localStorage.getItem('wizardState')
  if (partialState) {
    const state = {
      ...JSON.parse(partialState),
      dogs: presets.map(presetLocalStorageData)
    }
    localStorage.setItem(
      'wizardState',
      JSON.stringify({ user: state.user, dogs: state.dogs })
    )
  } else
    localStorage.setItem(
      'wizardState',
      JSON.stringify({ dogs: presets.map(presetLocalStorageData) })
    )
}

export {
  allPresets,
  presetTitle,
  presetDescription,
  presetSubmissionData,
  savePresetsInLocalStorage
}
export type { Preset }
