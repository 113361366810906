import { gql } from '@apollo/client'

const UPDATE_PLAN_AND_RESUME_MUTATION = gql`
  mutation UpdatePlanAndResume($userId: ID!, $planId: ID!) {
    subscriptionPlanUpdate(userId: $userId, planId: $planId) {
      id
      subscription {
        plan {
          id
        }
      }
    }
    subscriptionResume {
      id
      subscription {
        id
        status
      }
    }
    subscriptionResumedEventCreate(userId: null, properties: null) {
      id
    }
    subscriptionResumedWorkflowCreate(userId: null, properties: null) {
      id
    }
  }
`

export { UPDATE_PLAN_AND_RESUME_MUTATION }
