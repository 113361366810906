import classnames from 'classnames'
import React from 'react'

import { transformIndents } from '@/utils/transformIndents'

import STYLES from './Separator.module.sass'

type IndentValues = 0 | 8 | 16 | 24 | 32
type Indent = {
  top?: IndentValues
  right?: IndentValues
  bottom?: IndentValues
  left?: IndentValues
}

type Props = {
  bold?: boolean
  solid?: boolean
  handdrawn?: boolean
  margin?: Indent | IndentValues
  vertical?: boolean
}

const Separator = ({
  bold = true,
  solid = false,
  handdrawn = false,
  margin,
  vertical = false
}: Props): JSX.Element => {
  const { marginStyles } = transformIndents({ margin })
  const className = classnames(STYLES.container, {
    [STYLES.bold]: bold,
    [STYLES.solid]: solid,
    [STYLES.handdrawn]: handdrawn,
    [STYLES.vertical]: vertical
  })

  return <hr className={className} style={{ ...marginStyles }} />
}

export { Props }
export default Separator
