// @noflow
import * as Sentry from '@/utils/sentry'

import WizardRoutes from '@/components/pages/SignupWizardPage/types/routes'
// Types
import type { Route } from '@/components/pages/SignupWizardPage/types/routes'

type RouteProps = {
  route: Route
  isGuest?: boolean
  fussyDogsPresent?: boolean
}

const routeToPrevStep = ({ route, isGuest }: RouteProps): Route => {
  switch (route) {
    case WizardRoutes.Name:
    case WizardRoutes.Gender: {
      return WizardRoutes.Name
    }
    case WizardRoutes.Age: {
      return WizardRoutes.Gender
    }
    case WizardRoutes.Breed: {
      return WizardRoutes.Age
    }
    case WizardRoutes.Food: {
      return WizardRoutes.Breed
    }
    case WizardRoutes.Eater: {
      return WizardRoutes.Food
    }
    case WizardRoutes.FussinessHelp: {
      return WizardRoutes.Eater
    }
    case WizardRoutes.Goals: {
      return WizardRoutes.Eater
    }
    case WizardRoutes.BodyShape: {
      return isGuest ? WizardRoutes.Goals : WizardRoutes.Eater
    }
    case WizardRoutes.Weight: {
      return WizardRoutes.BodyShape
    }
    case WizardRoutes.Activity: {
      return WizardRoutes.Weight
    }
    case WizardRoutes.Allergies: {
      return WizardRoutes.Activity
    }
    case WizardRoutes.Health: {
      return WizardRoutes.Allergies
    }
    case WizardRoutes.Snacks: {
      return WizardRoutes.Health
    }
    case WizardRoutes.PetParent: {
      return WizardRoutes.Snacks
    }
    default: {
      Sentry.captureException(`Cannot generate routeToPrevStep`, {
        extra: {
          route
        },
        tags: {
          product: Sentry.Product.Wizard
        }
      })
      return WizardRoutes.Name
    }
  }
}

const routeToNextStep = ({
  route,
  fussyDogsPresent,
  isGuest
}: RouteProps): Route => {
  switch (route) {
    case WizardRoutes.Name: {
      return WizardRoutes.Gender
    }
    case WizardRoutes.Gender: {
      return WizardRoutes.Age
    }
    case WizardRoutes.Age: {
      return WizardRoutes.Breed
    }
    case WizardRoutes.Breed: {
      return WizardRoutes.Food
    }
    case WizardRoutes.Food: {
      return WizardRoutes.Eater
    }
    case WizardRoutes.Eater: {
      if (fussyDogsPresent) {
        return WizardRoutes.FussinessHelp
      }
      return isGuest ? WizardRoutes.Goals : WizardRoutes.BodyShape
    }
    case WizardRoutes.FussinessHelp: {
      return isGuest ? WizardRoutes.Goals : WizardRoutes.BodyShape
    }
    case WizardRoutes.Goals: {
      return WizardRoutes.BodyShape
    }
    case WizardRoutes.BodyShape: {
      return WizardRoutes.Weight
    }
    case WizardRoutes.Weight: {
      return WizardRoutes.Activity
    }
    case WizardRoutes.Activity: {
      return WizardRoutes.Allergies
    }
    case WizardRoutes.Allergies: {
      return WizardRoutes.Health
    }
    case WizardRoutes.Health: {
      return WizardRoutes.Snacks
    }
    case WizardRoutes.Snacks: {
      return WizardRoutes.PetParent
    }
    default: {
      Sentry.captureException(`Cannot generate routeToNextStep`, {
        extra: {
          route
        },
        tags: {
          product: Sentry.Product.Wizard
        }
      })
      return WizardRoutes.Name
    }
  }
}
export { routeToPrevStep, routeToNextStep }
