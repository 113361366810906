/* eslint-disable i18next/no-literal-string */
// @noflow
import { Factory } from 'fishery'

// eslint-disable-next-line no-restricted-imports
import type { HealthIssue } from '@/components/types'

export default Factory.define<HealthIssue>(({ sequence }) => ({
  id: sequence,
  name: `Health Issue ${sequence}`,
  warning: false,
  information: `Health Issue Information ${sequence}`
}))
