import { useReactiveVar } from '@apollo/client'
import React, { useEffect, useState } from 'react'

import BasicInfoDog from 'assets/images/illustrations/dogs/wizard-footer/basic-info-dog.svg'

import ContactCustomerLoveButton from '../../components/ContactCustomerLove/ContactCustomerLoveButton'
import Text from '@/components/elements/atoms/Text/Text'
import WizardFooter from '@/components/elements/organisms/WizardFooter/WizardFooter'

import STYLES from './Error.module.sass'

import {
  customerIssueManagementState,
  pageHeaderShowBackButtonState,
  pageTitleState
} from '../../CustomerIssueManagementPage'
import useCustomerIssueManagementTracking from '../../analytics'

type Props = {
  errorType?: string
}

const Error = ({ errorType = 'default' }: Props): JSX.Element => {
  const customerIssueManagementData = useReactiveVar(
    customerIssueManagementState
  )
  const customerIssueManagementTracking = useCustomerIssueManagementTracking()
  const [initialised, setInitialised] = useState(false)

  useEffect(() => {
    if (!initialised) {
      pageHeaderShowBackButtonState(false)
      pageTitleState('error.titles.' + errorType)
      customerIssueManagementTracking.trackEvent('error page loaded')
      setInitialised(true)
    }
  }, [initialised, errorType, customerIssueManagementTracking])

  return (
    <div className={STYLES.container}>
      <Text
        namespace={customerIssueManagementData.namespace}
        text={`error.text_1`}
      />
      <Text
        namespace={customerIssueManagementData.namespace}
        text={`error.text_2`}
      />
      <div className={STYLES.actions}>
        <ContactCustomerLoveButton
          namespace={customerIssueManagementData.namespace}
          tracking={'Error'}
        />
      </div>
      <WizardFooter
        variant="customerLove"
        namespace={'wizard_flow'}
        title={'footer.title'}
        subtitle={'footer.subtitle'}
        iconAlt={'footer.guarantee_badge_icon_alt'}
        illustration={{
          img: BasicInfoDog,
          imgAlt: 'footer.basic_info_dog_alt',
          imgPosition: 'right'
        }}
      />
    </div>
  )
}

export { Props }
export default Error
