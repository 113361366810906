// @noflow
import type { Language } from '@/packs/localisation'
import { useQuery } from '@apollo/client'
import { format as formatDate, getDaysInMonth, isEqual } from 'date-fns'
import i18next from 'i18next'
import isNil from 'lodash/isNil'
import isNull from 'lodash/isNull'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { localeToDateLocale } from '@/utils/countryCodeHelper'
import { hasBeenDelivered } from '@/utils/orderHelper'

import Modal from '@/components/elements/atoms/Modal/Modal'
import Calendar from '@/components/elements/molecules/Calendar/Calendar'
import ChangeAddress from '@/components/elements/molecules/ChangeAddress/ChangeAddress'
import DetailsCard from '@/components/elements/molecules/DetailsCard/DetailsCard'
// eslint-disable-next-line no-restricted-imports
import { DeliveryStatus } from '@/components/types/DeliveryStatus'

import STYLES from './DeliveryDetails.module.sass'

import { DELIVERABLE_DATES_DATA } from './queries/deliverableDatesQuery'

import type {
  DeliverableDatesData,
  DeliverableDatesDataVariables,
  DeliverableDatesData_calendarDates
} from './queries/__generated__/DeliverableDatesData'
import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'

type Address = {
  recipientName: string
  address1: string
  address2: string | null
  city: string
  postcode: string
  deliveryNotes: string | null
  deliveryCarrier: string | null
}

type Props = {
  address: Address
  status?: DeliveryStatus | null
  deliveryDate: Date | null
  paymentDueDate: Date | null | undefined
  onChangeAddress?: (data: Address) => void
  onChangeDeliveryDate?: (data: Date) => void
  shippingCountryCode: CountryCode
  setPaymentDueDate?: (data: Date) => void
  dateBottomExtra?: JSX.Element | null
  addressBottomExtra?: JSX.Element | null
  shipmentType?: 'box' | 'non_core'
  userId?: string
}

const DeliveryDetails = ({
  address,
  status,
  deliveryDate,
  paymentDueDate,
  onChangeAddress,
  onChangeDeliveryDate,
  shippingCountryCode,
  setPaymentDueDate,
  dateBottomExtra,
  addressBottomExtra,
  shipmentType,
  userId
}: Props): JSX.Element => {
  const [addressModalIsOpen, setAddressModalIsOpen] = useState(false)

  /**
   * As an `initialMonthInView` we use the first day of the month, we want to make
   * sure that we'll get the first day of the month no matter what timezone is
   * used. To do that we set hours manually to 10h to avoid the 00:00:00 time.
   */

  const today = useMemo(() => new Date(), [])
  const initialMonthInView = useMemo(
    () => new Date(today.getFullYear(), today.getMonth(), 1, 10),
    [today]
  )

  const numberOfDaysInCurrentMonth = useMemo(
    () => getDaysInMonth(initialMonthInView) - 1,
    [initialMonthInView]
  )
  const { data } = useQuery<
    DeliverableDatesData,
    DeliverableDatesDataVariables
  >(DELIVERABLE_DATES_DATA, {
    variables: {
      calendarInitDate: initialMonthInView,
      nDays: numberOfDaysInCurrentMonth,
      ...(userId
        ? { userId }
        : { postcode: address.postcode, city: address.city }),
      shouldAttemptToOfferNextDayDelivery: true,
      shipmentType: shipmentType
    }
  })

  useEffect(() => {
    if (data && onChangeDeliveryDate) {
      const item = data.calendarDates.find(
        (calendarDate: DeliverableDatesData_calendarDates) =>
          calendarDate.deliverable
      )
      if (item?.date) {
        // Date needs to be formatted to be render properly
        // new Date('2022-01-27').toLocaleString()
        // '26/01/2022, 21:00:00'
        // new Date('2022/01/27').toLocaleString()
        // '27/01/2022, 00:00:00'
        onChangeDeliveryDate(new Date(item.date.replace(/-/g, '/')))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (data && setPaymentDueDate) {
      const selecteditem = data.calendarDates.find(
        (calendarDate: DeliverableDatesData_calendarDates) => {
          return (
            deliveryDate &&
            calendarDate.date === formatDate(deliveryDate, 'yyyy-MM-dd')
          )
        }
      )
      // NOTE - comparison of paymentDueDate and endOfLeadTime added to stop infinite re-render
      //        caused by setPaymentDueDate.
      if (
        selecteditem?.endOfLeadTime &&
        (isNil(paymentDueDate) ||
          !isEqual(new Date(selecteditem?.endOfLeadTime), paymentDueDate))
      ) {
        setPaymentDueDate(new Date(selecteditem.endOfLeadTime))
      }
    }
  }, [data, setPaymentDueDate, deliveryDate, paymentDueDate])

  const dateLocale = localeToDateLocale(
    shippingCountryCode,
    i18next.language as Language
  )

  const toggleAddressModal = useCallback((): void => {
    setAddressModalIsOpen(!addressModalIsOpen)
  }, [addressModalIsOpen, setAddressModalIsOpen])

  const [deliveryDateModalIsOpen, setDeliveryDateModalIsOpen] = useState(false)

  const toggleDeliveryDatesModal = useCallback((): void => {
    setDeliveryDateModalIsOpen(!deliveryDateModalIsOpen)
  }, [deliveryDateModalIsOpen, setDeliveryDateModalIsOpen])

  const deliveryCopy =
    isNull(deliveryDate) || hasBeenDelivered(deliveryDate, status)
      ? 'delivered'
      : 'get_it_on'
  const recipientName = Object.keys(address)
    .filter((key: string) => key === 'recipientName')
    .map((key: string): string | null => {
      if (address[key as keyof Address] === '') return null
      return `${address[key as keyof Address]}`
    })
  const addressLines = Object.keys(address)
    .filter((key: string) => key === 'address1' || key === 'address2')
    .map((key: string): string | null => {
      if (address[key as keyof Address] === '') return null
      return `${address[key as keyof Address]}`
    })
    .join(', ')
  const cityAndPostcode = Object.keys(address)
    .filter((key: string) => key === 'city' || key === 'postcode')
    .map((key: string): string | null => {
      if (address[key as keyof Address] === '') return null
      return `${address[key as keyof Address]}`
    })
    .join(', ')
  // eslint-disable-next-line i18next/no-literal-string
  const addressCopy = `${recipientName}<br>${addressLines}<br>${cityAndPostcode}`

  const addressChangeClickProps = onChangeAddress
    ? {
        onClick: toggleAddressModal,
        prompt: 'shared:delivery_details.edit',
        identifier: 'delivery_details.change_address'
      }
    : {}

  const deliveryDateChangeClickProps = onChangeDeliveryDate
    ? {
        onClick: toggleDeliveryDatesModal,
        prompt: 'shared:delivery_details.edit',
        identifier: 'delivery_details.change_date'
      }
    : {}

  return (
    <>
      {onChangeAddress && (
        <Modal
          setOpenModal={toggleAddressModal}
          isModalOpen={addressModalIsOpen}
          width={600}
        >
          <ChangeAddress
            address={address}
            onChange={onChangeAddress}
            toggleModal={toggleAddressModal}
            shippingCountryCode={shippingCountryCode}
          />
        </Modal>
      )}
      {onChangeDeliveryDate && (
        <Modal
          setOpenModal={toggleDeliveryDatesModal}
          isModalOpen={deliveryDateModalIsOpen}
          width={600}
        >
          <Calendar
            selectedDate={deliveryDate}
            deliveryDate={deliveryDate}
            shippingCountryCode={shippingCountryCode}
            setSelectedDate={onChangeDeliveryDate}
            shouldAttemptToOfferNextDayDelivery
            shipmentType={shipmentType}
            {...(userId
              ? { userId }
              : { postcode: address.postcode, city: address.city })}
          />
        </Modal>
      )}
      <div className={STYLES.cards}>
        <DetailsCard
          icon="van"
          iconSize={19}
          label={{
            text: 'delivery_details.delivery_date'
          }}
          text={{
            text: deliveryDate
              ? `delivery_details.${deliveryCopy}`
              : `delivery_details.select_date`,
            variables: {
              date: deliveryDate
                ? `<accent type="bold">${formatDate(
                    deliveryDate,
                    'iiii dd MMMM',
                    { locale: dateLocale }
                  )}</accent>`
                : ''
            }
          }}
          namespace="shared"
          bottomExtra={dateBottomExtra}
          {...deliveryDateChangeClickProps}
        />
        <DetailsCard
          icon="house"
          iconSize={32}
          label={{
            text: 'delivery_details.address'
          }}
          text={{
            text: `${addressCopy}`,
            translate: false
          }}
          namespace="shared"
          bottomExtra={addressBottomExtra}
          {...addressChangeClickProps}
        />
      </div>
    </>
  )
}

export { Props }
export default DeliveryDetails
