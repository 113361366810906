// @noflow
import { useNotifications } from '@/context/notifications/notifications'
import React, { useCallback, useState } from 'react'

import Validator from '@/utils/validator'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import AccountDetailsCard from '../../MyDetailsPage/components/AccountDetailsCard/AccountDetailsCard'
import { Button } from '@/components/elements/atoms/Button'
import Modal from '@/components/elements/atoms/Modal/Modal'
import Text from '@/components/elements/atoms/Text/Text'
import TextField from '@/components/elements/atoms/TextField/TextField'
import Form from '@/components/elements/molecules/Form/Form'

import STYLES from './../PersonalDetails.module.sass'

import { MyDetailsPersonalDetailsQuery_user as User } from '../../MyDetailsPage/queries/__generated__/MyDetailsPersonalDetailsQuery'

type Props = {
  user: User | null
  loading: boolean
  updateYourDetails: (data: Partial<User>) => void
}

const YourDetails = ({
  user,
  loading,
  updateYourDetails
}: Props): JSX.Element => {
  const { windowWidth } = useWindowSize()
  const { setInfoNotification } = useNotifications()
  const [yourDetailsModalIsOpen, setYourDetailsModalIsOpen] = useState(false)

  const toggleYourDetailsModal = useCallback(() => {
    setYourDetailsModalIsOpen((prev) => !prev)
  }, [])

  const stripTranslationLabels = useCallback(
    (data: { [key: string]: string }): Partial<User> | null => {
      const keys = Object.keys(data)
      if (keys.length > 0) {
        keys.forEach((key) => {
          const valueToUpdate = key.split('.').at(-1)
          if (valueToUpdate === 'name') {
            const names = data[key].split(' ')
            data.firstName = names[0]
            data.lastName = names.slice(1).join(' ')
          } else {
            data[valueToUpdate as string] = data[key]
          }
          delete data[key]
        })
        return data
      }
      return null
    },
    []
  )

  const nameValidation = useCallback((value: string): boolean => {
    return Validator.isValidName(value)
  }, [])

  const phoneNumberValidation = useCallback((value: string): boolean => {
    return Validator.isValidPhoneNumber(value)
  }, [])

  const emailValidation = useCallback((value: string): boolean => {
    return Validator.isValidEmail(value)
  }, [])

  const onSubmit = useCallback(
    (data: { [key: string]: string }) => {
      const updatedValues = stripTranslationLabels(data)
      if (updatedValues) {
        updateYourDetails(updatedValues)
        toggleYourDetailsModal()
      } else {
        setInfoNotification({
          text: 'my_details.personal_details.notification_no_change',
          namespace: 'dashboard'
        })
      }
    },
    [
      stripTranslationLabels,
      toggleYourDetailsModal,
      updateYourDetails,
      setInfoNotification
    ]
  )

  return (
    <>
      <AccountDetailsCard
        loading={loading}
        lines={3}
        shortLine={false}
        label={{ text: 'my_details.personal_details.your_details.label' }}
        text={{
          text: 'my_details.personal_details.your_details.text',
          variables: {
            name: `${user?.firstName} ${user?.lastName}`,
            email: user?.email,
            phone: user?.phone
          }
        }}
        onClick={toggleYourDetailsModal}
        prompt={'my_details.personal_details.your_details.prompt'}
        icon={'customer'}
        namespace={'dashboard'}
        linkPosition={'top'}
        identifier="personal_details.change_details"
      />
      <Modal
        isModalOpen={yourDetailsModalIsOpen}
        setOpenModal={toggleYourDetailsModal}
        width={600}
        bottomSticky={windowWidth < BREAKPOINTS.md}
      >
        <div className={STYLES.titleWrapper}>
          <Text
            namespace={'dashboard'}
            text={
              'my_details.personal_details.your_details.change_your_details'
            }
            variant="textRegular20"
            margin={false}
            bold
            align="center"
          />
        </div>
        <Form onSubmit={onSubmit}>
          <TextField
            label={'my_details.personal_details.your_details.name'}
            namespace={'dashboard'}
            initialValue={`${user?.firstName} ${user?.lastName}`}
            optional={false}
            additionalValidation={nameValidation}
            errorMessage={
              'my_details.personal_details.your_details.nameValidation'
            }
          />
          <TextField
            label={'my_details.personal_details.your_details.email'}
            namespace={'dashboard'}
            initialValue={user?.email}
            optional={false}
            additionalValidation={emailValidation}
          />
          <TextField
            label={'my_details.personal_details.your_details.phone'}
            namespace={'dashboard'}
            initialValue={user?.phone || ''}
            optional={false}
            additionalValidation={phoneNumberValidation}
          />
          <Button
            disableAnalytics
            typography={{
              namespace: 'dashboard',
              text: 'my_details.personal_details.your_details.save'
            }}
          />
        </Form>
      </Modal>
    </>
  )
}

export default YourDetails
