import { gql } from '@apollo/client'

const GOCARDLESS_USER_PARAM_CREATE = gql`
  mutation gocardlessUserParamCreate(
    $planId: ID!
    $email: String!
    $userParams: String!
  ) {
    gocardlessUserParamCreate(
      planId: $planId
      email: $email
      userParams: $userParams
    ) {
      gocardlessUserParam {
        id
      }
    }
  }
`
export { GOCARDLESS_USER_PARAM_CREATE }
