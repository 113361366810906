// @flow

import * as React from 'react'
import type { BrandColours } from '@/constants/BrandColours'
import type { SupportColours } from '@/constants/SupportColours'

type Props = {|
  color: $Values<BrandColours> | $Values<SupportColours>,
  rotation: number,
  extraClasses?: string,
  onClick?: () => void
|}

const Chevron = ({ color, rotation, extraClasses, onClick }: Props): React.Element<'svg'> => (
  <svg
    viewBox="0 0 10 13"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: `rotate(${rotation}deg)` }}
    className={extraClasses}
    onClick={onClick}
  >
    <path
      d="M.314.842C2.403 1.754 4.42 2.798 6.28 4.13c.612.432 1.332.732 2.028 1.008 1.008.396 1.26 1.164.408 1.836-1.44 1.128-2.988 2.124-4.5 3.156-.936.636-1.92 1.212-2.88 1.824-.625.396-.949.216-.985-.528-.024-.552.108-1.02.648-1.308.757-.408 1.513-.816 2.233-1.284.912-.6 1.8-1.236 2.676-1.884.372-.276.312-.528-.012-.876C4.37 4.43 2.39 3.446.518 2.306-.058 1.958-.19 1.466.074.842h.24z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
)

export default Chevron
