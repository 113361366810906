// @noflow
import { ACCOUNT_ROUTES } from '@/routes'
import { useQuery } from '@apollo/client'
import { Container, Grid } from '@material-ui/core'
import { differenceInDays, parseISO } from 'date-fns'
import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import * as Sentry from '@/utils/sentry'

// Contants
import BREAKPOINTS from '@/constants/Breakpoints'

import useElementSize from '@/hooks/useElementSize'
import useWindowSize from '@/hooks/useWindowSize'

import DogWithBagSprite from 'assets/images/illustrations/dogs/dog-with-bag--sprite.svg'

import BasketFooter from './components/BasketFooter/BasketFooter'
import BasketProductsList from './components/BasketProductsList'
import EmptyBasketState from './components/EmptyBasketState/EmptyBasketState'
import Icon from '@/components/elements/atoms/Icon/Icon'
import Separator from '@/components/elements/atoms/Separator/Separator'
import Text from '@/components/elements/atoms/Text/Text'
import OrderSummary from '@/components/elements/molecules/OrderSummary/OrderSummary'
import DeliveryDetails from '@/components/elements/organisms/DeliveryDetails/DeliveryDetails'
import LoadingScreen from '@/components/elements/organisms/LoadingScreen/LoadingScreen'
import DeliveryNotes from '@/components/pages/AddressBookPage/components/DeliveryNotes'
import type {
  upcomingOrderQuery_user_ordersByDate_Box as NextBox,
  upcomingOrderQuery
} from '@/components/pages/OrdersPage/__generated__/upcomingOrderQuery'
import { UPCOMING_ORDERS } from '@/components/pages/OrdersPage/queries'

import STYLES from './BasketPage.module.sass'

import { BASKET_PAGE_QUERY } from './queries/basketPageQuery'

import type {
  BasketPageQuery_user_address as AddressInput,
  BasketPageQuery
} from './queries/__generated__/BasketPageQuery'

type AddressValue = {
  address1: string
  address2: string | null
  city: string
  postcode: string
  recipientName: string
  deliveryNotes: string | null
  deliveryCarrier: string | null
}

type InitialValue = {
  deliveryDate: Date | null
  address: AddressValue
}

const INITIAL_VALUE = {
  deliveryDate: null,
  address: {
    recipientName: '',
    address1: '',
    address2: '',
    city: '',
    postcode: '',
    deliveryNotes: '',
    deliveryCarrier: ''
  }
}

const BasketPage = (): JSX.Element | null => {
  const { windowWidth } = useWindowSize()
  const [value, setValue] = useState<InitialValue>(INITIAL_VALUE)
  const [paymentDueDate, setPaymentDueDate] = useState<Date | null>()

  const {
    setRef: setFooterRef,
    size: { height: footerHeight }
  } = useElementSize()

  const { loading, data, error } = useQuery<BasketPageQuery>(BASKET_PAGE_QUERY)

  const { data: upcomingOrderData } =
    useQuery<upcomingOrderQuery>(UPCOMING_ORDERS)

  const handleAddressChange = useCallback(
    (addressValue: AddressValue): void => {
      setValue({
        ...value,
        deliveryDate: null,
        address: { ...value.address, ...addressValue }
      })
    },
    [value]
  )

  const handleDeliveryDate = useCallback(
    (deliveryDate: Date | null): void => {
      setValue({ ...value, deliveryDate })
    },
    [value]
  )

  useEffect((): void => {
    if (data?.user?.address) {
      let newValue = value.address
      Object.keys(data.user.address).forEach((key: string): void => {
        newValue = {
          ...newValue,
          [key]: data.user.address[key as keyof AddressInput]
        }
      })
      setValue({ ...value, address: { ...newValue } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.user?.address])

  if (error) {
    Sentry.captureException(
      'Basket Page | Error occured in BASKET_PAGE_QUERY',
      {
        extra: { error },
        tags: {
          product: Sentry.Product.Account,
          team: Sentry.Team.Retention
        }
      }
    )
  }

  if (loading || !data) {
    return (
      <LoadingScreen
        isOpen
        title={{
          text: 'extras.basket.loading_screen.title',
          namespace: 'dashboard'
        }}
        variant="animated"
        sprite={DogWithBagSprite}
      />
    )
  }

  if (!data.user.basket || data?.user?.basket?.basketProducts?.length === 0) {
    return <EmptyBasketState />
  }

  const {
    user: {
      id,
      shippingCountryCode,
      preferredLanguage,
      subscription,
      nonCoreOrders,
      basket: { discountedPrice, grossPrice, smallOrderFee, basketProducts }
    },
    nonCoreOrderDeliveryFee
  } = data

  const { locale, currency } = countryCodeToLocaleCurrency(
    shippingCountryCode,
    preferredLanguage
  )

  const properties = {
    deliveriesReceived: subscription.deliveriesReceived,
    extrasBoxesReceived: nonCoreOrders.length,
    subscriptionStatus: subscription.status,
    timestamp: new Date()
  }

  const nextBoxDeliveryDate = (
    upcomingOrderData?.user.ordersByDate.find(
      (order) => order.__typename === 'Box'
    ) as NextBox
  )?.isoDeliveryDate
  const diffDays =
    nextBoxDeliveryDate &&
    value?.deliveryDate &&
    differenceInDays(parseISO(nextBoxDeliveryDate), value.deliveryDate)

  return (
    <div
      className={STYLES.container}
      {...(footerHeight &&
        windowWidth < BREAKPOINTS.md && {
          // eslint-disable-next-line i18next/no-literal-string
          style: { marginBottom: `${footerHeight + 48}px` }
        })}
    >
      <Container maxWidth="lg">
        <Grid container className={STYLES.main} spacing={2} wrap="nowrap">
          <Grid item xs={12} md={12} className={STYLES.wrapper}>
            <BasketProductsList
              userId={id}
              basketProducts={basketProducts}
              locale={locale}
              currency={currency}
            />
            <div className={STYLES.emptyCard}>
              <div>
                <Text
                  text="extras.basket.add_more"
                  namespace="dashboard"
                  variant="textRegular18"
                  bold
                  margin={false}
                  colour="brandBlue500"
                />
                <Link to={ACCOUNT_ROUTES.extras}>
                  <Text
                    text="extras.basket.check_out_our_shop"
                    namespace="dashboard"
                    variant="textRegular18"
                    bold
                    margin={false}
                    colour="brandBlue500"
                  />
                </Link>
              </div>
              <Link to={ACCOUNT_ROUTES.extras}>
                <div className={STYLES.plusIcon}>
                  <Icon asset="plus" accentColour="brandWhite" size={16} />
                </div>
              </Link>
            </div>
            {value.address.postcode && (
              <DeliveryDetails
                userId={id}
                address={value.address}
                deliveryDate={value.deliveryDate}
                onChangeAddress={handleAddressChange}
                onChangeDeliveryDate={handleDeliveryDate}
                shippingCountryCode={shippingCountryCode}
                paymentDueDate={paymentDueDate}
                setPaymentDueDate={setPaymentDueDate}
                dateBottomExtra={
                  diffDays ? (
                    <div className={STYLES.bottomExtra}>
                      <Text
                        text="extras.basket.date_subtext"
                        namespace="dashboard"
                        variant="textRegular16"
                        colour="brandBlue500"
                        variables={{ daysAmount: diffDays }}
                        margin={false}
                      />
                    </div>
                  ) : null
                }
                addressBottomExtra={
                  <div className={STYLES.bottomExtra}>
                    <DeliveryNotes
                      deliveryNotes={value.address.deliveryNotes || null}
                    />
                    <Text
                      text="extras.basket.send_to_a_friend.title"
                      namespace="dashboard"
                      variant="textRegular16"
                      colour="brandBlue500"
                      margin={false}
                    />
                    <Text
                      text="extras.basket.send_to_a_friend.subtitle"
                      namespace="dashboard"
                      variant="textRegular16"
                      colour="brandBlue500"
                      margin={false}
                      bold
                    />
                  </div>
                }
                shipmentType="non_core"
              />
            )}
          </Grid>
          {windowWidth < BREAKPOINTS.md && <Separator handdrawn />}
          <Grid item xs={12} md={12}>
            <div className={STYLES.footer}>
              <OrderSummary
                grossPrice={
                  discountedPrice +
                  nonCoreOrderDeliveryFee +
                  (smallOrderFee || 0)
                }
                price={
                  grossPrice + nonCoreOrderDeliveryFee + (smallOrderFee || 0)
                }
                deliveryPrice={nonCoreOrderDeliveryFee}
                smallOrderFee={smallOrderFee}
                locale={locale}
                currency={currency}
                shippingCountryCode={shippingCountryCode}
              />
              <div
                className={`${
                  windowWidth < BREAKPOINTS.md && STYLES.basketFooter
                }`}
                ref={setFooterRef}
              >
                <BasketFooter
                  value={value}
                  subscription={subscription}
                  userId={id}
                  properties={properties}
                  paymentDueDate={paymentDueDate}
                  locale={locale}
                  currency={currency}
                  shippingCountryCode={shippingCountryCode}
                  totalPriceOfProducts={discountedPrice}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export { InitialValue, BASKET_PAGE_QUERY, BasketPage }

export default BasketPage
