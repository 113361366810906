// @flow

import * as TYPES from './types'

// Wizard Step Reducer Messages
const SET_WIZARD_STEP: TYPES.SET_WIZARD_STEP = 'SET_WIZARD_STEP'
const NEXT_WIZARD_STEP: TYPES.NEXT_WIZARD_STEP = 'NEXT_WIZARD_STEP'
const PREVIOUS_WIZARD_STEP: TYPES.PREVIOUS_WIZARD_STEP = 'PREVIOUS_WIZARD_STEP'

/**
 * Request Reducer Messages
 */
const START_REQUEST: TYPES.START_REQUEST = 'START_REQUEST'
const COMPLETE_REQUEST: TYPES.COMPLETE_REQUEST = 'COMPLETE_REQUEST'

// Editable Elements Reducer Types
const SELECT_FAVOURITE_GAME: TYPES.SELECT_FAVOURITE_GAME = 'SELECT_FAVOURITE_GAME'
const TOGGLE_ACTIVITY: TYPES.TOGGLE_ACTIVITY = 'TOGGLE_ACTIVITY'
const TOGGLE_TRAIT: TYPES.TOGGLE_TRAIT = 'TOGGLE_TRAIT'
const UPDATE_DOG_DESCRIPTION: TYPES.UPDATE_DOG_DESCRIPTION = 'UPDATE_DOG_DESCRIPTION'
const SET_AVATAR_SRC: TYPES.SET_AVATAR_SRC = 'SET_AVATAR_SRC'

export {
  SET_WIZARD_STEP,
  NEXT_WIZARD_STEP,
  PREVIOUS_WIZARD_STEP,
  START_REQUEST,
  COMPLETE_REQUEST,
  SELECT_FAVOURITE_GAME,
  TOGGLE_ACTIVITY,
  TOGGLE_TRAIT,
  UPDATE_DOG_DESCRIPTION,
  SET_AVATAR_SRC
}
