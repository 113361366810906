// @flow

import * as React from 'react'

// Types
import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'
import type { Language } from '@/packs/localisation'

type Props = {|
  isError: boolean,
  message: ?string,
  // eslint-disable-next-line react/no-unused-prop-types
  shippingCountryCode?: CountryCode,
  // eslint-disable-next-line react/no-unused-prop-types
  preferredLanguage?: Language
|}

const renderMessage = (
  message: string
): React.Node => {
  return (
    <span className='white-font'>
      { message }
    </span>
  )
}

const DashboardAlert = ({
  isError,
  message,
}: Props): React.Node => {
  if (message) {
    return (
      <div className='row'>
        <div className='col-md-12'>
          <div className={`alert ${isError ? 'alert--danger' : 'alert--success'} text-center`}>
            { renderMessage(message) }
          </div>
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default DashboardAlert
