// @flow

import * as React from 'react'
// eslint-disable-next-line flowtype/no-flow-fix-me-comments
// $FlowFixMe
import i18next from 'i18next'

type AccordionItem = {|
  question: string,
  answer: React.Node
|}

type AccordionItems = Array<AccordionItem>

const namespace = 'ambassadors:dashboard.faqs'

// eslint-disable-next-line flowtype/no-weak-types
const generateAccordionItems = (): AccordionItems => {
  return [
    {
      question: i18next.t(`${namespace}.how_do_i_share`),
      answer: (
        <p
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: i18next.t(`${namespace}.visit_the_refer_a_client_page`)
          }}
        />
      )
    },
    {
      question: i18next.t(`${namespace}.how_do_i_merch`),
      answer: (
        <p
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: i18next.t(`${namespace}.visit_the_free_merch_page`)
          }}
        />
      )
    },
    {
      question: i18next.t(`${namespace}.how_do_i_order_box`),
      answer: (
        <p
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: i18next.t(`${namespace}.how_to_order_box`)
          }}
        />
      )
    },
    {
      question: i18next.t(`${namespace}.how_much_credit_earned`),
      answer: (
        <p
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: i18next.t(`${namespace}.credit_explanation`)
          }}
        />
      )
    },
    {
      question: i18next.t(`${namespace}.how_can_i_apply_credit`),
      answer: (
        <p
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: i18next.t(`${namespace}.apply_credit`)
          }}
        />
      )
    },
    {
      question: i18next.t(`${namespace}.when_will_i_be_eligible`),
      answer: (
        <p>
          { i18next.t(`${namespace}.made_six_referrals`) }
          <br />
          { i18next.t(`${namespace}.pop_us_email`) }
        </p>
      )
    }
  ]
}

// eslint-disable-next-line flowtype/no-weak-types
const ContactFAQs = (): React.Element<'section'> => (
  <section className='faq'>
    <div className="faq__wrapper">
      <div className="faq__question-container">
        <h2 className="faq__question-container__title">
          { i18next.t(`${namespace}.frequently_asked_questions`) }
        </h2>

        <div className="accordion">
          <h3 className="accordion__title">
            { i18next.t(`${namespace}.about_ambassador_program`) }
          </h3>

          {
            generateAccordionItems().map((accordionItem: AccordionItem, index: number): React.Element<'div'> => {
              return (
                <div
                  className="accordion__item"
                  key={accordionItem.question}
                >
                  <input
                    id={`question-${index}`}
                    type="checkbox"
                    role="button"
                    aria-label={accordionItem.question}
                  />
                  <div className="accordion__item__animated-marker" />
                  <label
                    htmlFor={`question-${index}`}
                    className="accordion__item__title"
                    aria-owns={`question-${index}-answer`}
                  >
                    { accordionItem.question }
                  </label>
                  <div
                    className="accordion__item__content"
                    id={`question-${index}-answer`}
                  >
                    { accordionItem.answer }
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
      <div className='faq__image-container' />
    </div>
  </section>
)

export default ContactFAQs
