import { Factory } from 'fishery'

import {
  EditableShopItemProps,
  Props as ShopItemProps
} from '@/components/elements/molecules/BoxItem/ShopItem/ShopItem'

const ShopItemFactory = Factory.define<ShopItemProps>(() => ({
  cloudinaryPath:
    'http://res.cloudinary.com/ldhg5acsz/image/upload/c_fill,dpr_2.0,f_auto,h_60,q_auto,w_60/v1/Web/photos/extras/chicken_bone_broth/1-chicken-bone-broth--with-bowl.jpg',
  productName: 'chicken_bone_broth',
  price: '£10',
  sizeDescription: 'large',
  quantity: 1,
  discountedPrice: null
}))

const EditableShopItemFactory = Factory.define<EditableShopItemProps>(
  ({ sequence }) => ({
    ...ShopItemFactory.build(),
    editable: true,
    nonPaymentReason: null,
    id: sequence.toString(),
    size: '1',
    sizes: [
      {
        value: '1',
        text: 'Small',
        translate: false
      },
      {
        value: '2',
        text: 'Medium',
        translate: false
      },
      {
        value: '3',
        text: 'Large',
        translate: false
      }
    ],
    quantities: [
      {
        value: '1',
        text: '1',
        translate: false
      },
      {
        value: '2',
        text: '2',
        translate: false
      },
      {
        value: '3',
        text: '3',
        translate: false
      }
    ],
    productVariant: {
      __typename: 'ProductVariant',
      id: '1',
      slug: 'product_variant_slug',
      name: 'Product Variant Name',
      discountedPrice: 1000,
      grossPrice: 1500,
      oneOffDeliveryType: null,
      recurringDeliveryType: null,
      subscribeAndSaveable: false,
      productCollection: {
        __typename: 'ProductCollection',
        id: '1',
        slug: 'product_collection_slug',
        name: 'Product collection name',
        thumbnail: {
          __typename: 'Image',
          src: ''
        },
        productVariants: []
      },
      product: {
        __typename: 'Product',
        id: '1'
      }
    }
  })
)

export { ShopItemFactory, EditableShopItemFactory }
