import { useReactiveVar } from '@apollo/client'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import Text from '@/components/elements/atoms/Text/Text'
import { customerIssueManagementState } from '@/components/pages/CustomerIssueManagementPage/CustomerIssueManagementPage'
import useCustomerIssueManagementTracking from '@/components/pages/CustomerIssueManagementPage/analytics'
import { CustomerIssueManagementInitialDataQuery_user_reportable_potentialMissingItems as PotentialMissingItem } from '@/components/pages/CustomerIssueManagementPage/queries/__generated__/CustomerIssueManagementInitialDataQuery'
import { MultiSelectItem } from '@/components/pages/CustomerIssueManagementPage/types/types'

import STYLES from '../../InputsScreen.module.sass'

import {
  CustomerIssueReportCreate_response_inputFields_CustomerIssueManagementInputFieldMultifield_fields_CustomerIssueManagementInputFieldAssociation as MultifieldAssociationField,
  CustomerIssueReportCreate_response_inputFields_CustomerIssueManagementInputFieldMultifield as MultifieldInputFields,
  CustomerIssueReportCreate_response_inputFields_CustomerIssueManagementInputFieldMultifield_fields_CustomerIssueManagementInputFieldInteger as MultifieldIntegerField
} from '../../../../mutations/__generated__/CustomerIssueReportCreate'
import { CustomerIssueManagementInputInput } from '@/types'

import Multiselect from './Multiselect'
import ProductStepper from './ProductStepper'

type MultiselectAndEnumerateProps = {
  inputField: MultifieldInputFields
  multifieldInputs: Array<CustomerIssueManagementInputInput>
  pageState: string
  addMultifieldInput: (newInputGroup: CustomerIssueManagementInputInput) => void
  removeMultifieldInput: (
    multifieldInputsToRemove: CustomerIssueManagementInputInput
  ) => void
}

const MultiselectAndEnumerate = ({
  inputField,
  multifieldInputs,
  pageState,
  addMultifieldInput,
  removeMultifieldInput
}: MultiselectAndEnumerateProps): JSX.Element | null => {
  const [initialised, setInitialised] = useState(false)
  const [selectedItems, setSelectedItems] = useState<
    Array<PotentialMissingItem>
  >([])
  const customerIssueManagementTracking = useCustomerIssueManagementTracking()
  const customerIssueManagementData = useReactiveVar(
    customerIssueManagementState
  )
  const products = customerIssueManagementData.reportable.reportableFields
    ?.potentialMissingItems as Array<PotentialMissingItem>
  const associationField =
    (inputField.fields.find(
      (field) => field.dataType === 'association'
    ) as MultifieldAssociationField) || null
  const stepperField =
    (inputField.fields.find(
      (field) => field.dataType === 'integer'
    ) as MultifieldIntegerField) || null

  const multiselectItems = useMemo<Array<Array<MultiSelectItem>>>(() => {
    const meals = []
    const extras = []
    for (const product of products) {
      const item = {
        value: product,
        label: product.name,
        thumbnailSrc: product.thumbnail
      }
      switch (product.category) {
        case 'meal':
          meals.push(item)
          break
        case 'extra':
          extras.push(item)
          break
      }
    }
    return [meals, extras]
  }, [products])

  const handleItemAdded = useCallback(
    (potentialMissingItems: Array<PotentialMissingItem>) => {
      const newItems = potentialMissingItems.filter(
        (item) => !selectedItems.includes(item)
      )
      setSelectedItems(potentialMissingItems)
      newItems.forEach((newItem: PotentialMissingItem): void => {
        customerIssueManagementTracking.trackEvent('item selected', {
          item: newItem.name,
          numberOfItems: potentialMissingItems.length
        })
        addMultifieldInput({
          fieldId: inputField.id,
          inputs: [
            {
              fieldId: associationField.id,
              associationValue: newItem.productVariant.id
            }
          ]
        })
      })
    },
    [
      inputField.id,
      associationField.id,
      selectedItems,
      customerIssueManagementTracking,
      addMultifieldInput
    ]
  )

  const handleItemRemoved = useCallback(
    (potentialMissingItems: Array<PotentialMissingItem>) => {
      const newItems = selectedItems.filter(
        (item) => !potentialMissingItems.includes(item)
      )
      setSelectedItems(potentialMissingItems)
      newItems.forEach((newItem: PotentialMissingItem): void => {
        removeMultifieldInput({
          fieldId: inputField.id,
          inputs: [
            {
              fieldId: associationField.id,
              associationValue: newItem.productVariant.id
            }
          ]
        })
      })
    },
    [inputField.id, associationField.id, selectedItems, removeMultifieldInput]
  )

  const onSelected = useCallback(
    (values: Array<unknown>) => {
      const potentialMissingItems = values as Array<PotentialMissingItem>
      if (potentialMissingItems.length > selectedItems.length) {
        handleItemAdded(potentialMissingItems)
      } else if (potentialMissingItems.length < selectedItems.length) {
        handleItemRemoved(potentialMissingItems)
      }
    },
    [selectedItems, handleItemAdded, handleItemRemoved]
  )

  const sortedMultifieldInputs = useMemo(() => {
    return multifieldInputs.sort((a, b) => {
      const aInputs = a.inputs as Array<CustomerIssueManagementInputInput>
      const bInputs = b.inputs as Array<CustomerIssueManagementInputInput>
      const aAssociation = aInputs.find(
        (input) => input.fieldId === associationField.id
      ) as CustomerIssueManagementInputInput
      const bAssociation = bInputs.find(
        (input) => input.fieldId === associationField.id
      ) as CustomerIssueManagementInputInput
      const aAssociationValue = aAssociation.associationValue as string
      const bAssociationValue = bAssociation.associationValue as string
      return aAssociationValue > bAssociationValue ? 1 : -1
    })
  }, [associationField, multifieldInputs])

  useEffect(() => {
    if (!initialised) {
      multifieldInputs.forEach((input) => {
        const inputs = input.inputs as Array<CustomerIssueManagementInputInput>
        const assosciationInput = inputs.find(
          (i) => i.fieldId === associationField.id
        ) as CustomerIssueManagementInputInput
        const selectedProduct = products.find(
          (product) =>
            product.productVariant.id === assosciationInput.associationValue
        ) as PotentialMissingItem
        selectedItems.push(selectedProduct)
      })
      setInitialised(true)
    }
  }, [
    associationField.id,
    multifieldInputs,
    products,
    selectedItems,
    initialised
  ])

  if (!products) return null
  if (pageState === 'selecting') {
    return (
      <>
        <div className={STYLES.subtitle}>
          <Text
            text={inputField.name as string}
            translate={false}
            margin={false}
          />
        </div>
        <Multiselect
          items={multiselectItems}
          selectedItems={selectedItems}
          titles={['inputs.pouches', 'inputs.extras']}
          onSelected={onSelected}
        />
      </>
    )
  } else if (pageState === 'amounts') {
    return (
      <div>
        {sortedMultifieldInputs &&
          sortedMultifieldInputs.map((multifieldInput): JSX.Element => {
            const multifieldInputInputs =
              multifieldInput.inputs as Array<CustomerIssueManagementInputInput>
            const associationInput = multifieldInputInputs.find(
              (input) => input.fieldId === associationField.id
            ) as CustomerIssueManagementInputInput
            const productId = associationInput.associationValue
            const potentialMissingItem = products.find(
              (product) => product.productVariant.id === productId
            ) as PotentialMissingItem
            return (
              <ProductStepper
                key={productId}
                multifieldInput={multifieldInput}
                field={stepperField}
                potentialMissingItem={potentialMissingItem}
                setValue={addMultifieldInput}
              />
            )
          })}
      </div>
    )
  } else {
    return null
  }
}

export default MultiselectAndEnumerate
