// @noflow
import { useCallback, useEffect, useState } from 'react'

type Size = {
  width: number
  height: number
}

type ElementSize<T> = {
  setRef: (node: T | null) => void
  size: Size
}

/**
 * # Element size custom hook
 *
 * This hook helps to get element height and width using built in
 * react functionality.
 *
 * @example
  ```tsx
  import useElementSize from '@/hooks/useElementSize'

  const {
    setRef,
    size: { height, width }
  } = useElementSize()

  console.log(height, 'height)
  console.log(width, 'width)

  <div ref={setRef}>Content</div>
  ```
 *
 * @returns An object with two properties:
 * - setRef: a function that sets the ref
 * - size: an object with two properties: width and height
 */
const useElementSize = <
  T extends HTMLElement = HTMLDivElement
>(): ElementSize<T> => {
  /**
   * Mutable values like 'ref.current' aren't valid dependencies
   * because mutating them doesn't re-render the component.
   * Instead, we use a state as a ref to be reactive.
   */
  const [ref, setRef] = useState<T | null>(null)
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0
  })

  // Prevent too many rendering using useCallback
  const handleSetSize = useCallback(() => {
    setSize({
      width: ref?.offsetWidth || 0,
      height: ref?.offsetHeight || 0
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.offsetHeight, ref?.offsetWidth])

  useEffect(() => {
    handleSetSize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.offsetHeight, ref?.offsetWidth])

  return {
    setRef,
    size
  }
}

export default useElementSize
