/* eslint-disable i18next/no-literal-string */
import { gql } from '@apollo/client'

const EDIT_BOX_DELIVERY_ADDRESS_QUERY = gql`
  query editBoxDeliveryAddressV3Query {
    user {
      id
      currentDeliveryAreaPreference {
        id
        preferredCarrierService {
          id
          deliveryNotesLimit
        }
      }
      subscription {
        id
        nextEditableBox {
          id
          address {
            id
            recipientName
            address1
            address2
            city
            postcode
            deliveryNotes
          }
        }
      }
      shippingCountry {
        id
        code
      }
    }
  }
`

export { EDIT_BOX_DELIVERY_ADDRESS_QUERY }
