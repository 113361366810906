import React from 'react'

import { useLocalisationHelper } from '@/hooks/useLocalisationHelper/useLocalisationHelper'

import Text from '@/components/elements/atoms/Text'

import STYLES from '../OrderSummaryModal.module.sass'

import { BoxOrderSummaryModalFragment as Box } from '../fragments/__generated__/BoxOrderSummaryModalFragment'

type Props = {
  box: Box
}

const BoxPriceBreakdown = ({ box }: Props): JSX.Element => {
  const { formatPrice } = useLocalisationHelper()
  const namespace = 'modals'
  const context = 'order_summary.content'
  const {
    order: {
      deliveryFee,
      discountTotal,
      physicalProductsTotal,
      planTotal,
      planTotalPerDay,
      surchargeTotal,
      totalPrice
    }
  } = box
  const isDeliveryFree = deliveryFee === 0
  const isBoxFree = totalPrice === 0

  return (
    <div>
      <div className={STYLES.row}>
        <div>
          <Text
            text={`${context}.labels.fresh_recipes`}
            namespace={namespace}
            margin={false}
          />
        </div>
        <div>
          <Text
            text={`${context}.plan_prices`}
            variables={{
              planTotal: formatPrice(planTotal),
              planTotalPerDay: formatPrice(planTotalPerDay)
            }}
            namespace={namespace}
            margin={false}
          />
        </div>
      </div>
      {surchargeTotal > 0 && (
        <div className={STYLES.row}>
          <div>
            <Text
              text={`${context}.labels.recipe_surcharge`}
              namespace={namespace}
              margin={false}
            />
          </div>
          <div>
            <Text
              text={`${formatPrice(surchargeTotal)}`}
              translate={false}
              margin={false}
            />
          </div>
        </div>
      )}
      {!!physicalProductsTotal && (
        <div className={STYLES.row}>
          <div>
            <Text
              text={`${context}.labels.extras`}
              namespace={namespace}
              margin={false}
            />
          </div>
          <div>
            <Text
              text={`${formatPrice(physicalProductsTotal)}`}
              translate={false}
              margin={false}
            />
          </div>
        </div>
      )}
      <div className={STYLES.row}>
        <div>
          <Text
            text={`${context}.labels.delivery`}
            namespace={namespace}
            margin={false}
          />
        </div>
        <div>
          <Text
            text={
              isDeliveryFree ? `${context}.free` : `${formatPrice(deliveryFee)}`
            }
            translate={isDeliveryFree}
            margin={false}
          />
        </div>
      </div>
      {!!discountTotal && (
        <div className={STYLES.row}>
          <div>
            <Text
              text={`${context}.labels.discount_total`}
              namespace={namespace}
              margin={false}
            />
          </div>
          <div>
            <Text
              text={`${formatPrice(discountTotal)}`}
              translate={false}
              margin={false}
            />
          </div>
        </div>
      )}
      <div className={STYLES.row}>
        <div>
          <Text
            text={`${context}.labels.box_total`}
            namespace={namespace}
            variant="display16"
            margin={false}
          />
        </div>
        <div>
          <Text
            text={isBoxFree ? `${context}.free` : `${formatPrice(totalPrice)}`}
            variant="display16"
            translate={isBoxFree}
            margin={false}
          />
        </div>
      </div>
    </div>
  )
}

export { BoxPriceBreakdown }
