// @noflow
import { useReactiveVar } from '@apollo/client'
import i18next from 'i18next'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { SwiperClass } from 'swiper/react'

import { dogsDataVar } from '@/services/apollo'

import usePlanOptions from '@/hooks/usePlan/usePlan'
import type { FrequencyOption, Plan } from '@/hooks/usePlan/usePlan'

import Card from '@/components/elements/atoms/Card/Card'
import { CardSkeleton } from '@/components/elements/atoms/Card/CardSkeleton'
import Text, { Props as TextProps } from '@/components/elements/atoms/Text/Text'
import SwiperSlider from '@/components/elements/molecules/SwiperSlider/SwiperSlider'
import {
  PlanEditPage,
  Pouch
} from '@/components/pages/ServingSizePage/ServingSizePage'

import STYLES from './DeliveryFrequencyPage.module.sass'

const NAMESPACE = 'account'

type DateCardType = {
  text: TextProps[]
}

const DateCard = ({ text }: DateCardType) => (
  <Card padding={0} shadow fill={false}>
    <div className={STYLES.date}>
      {text.map((text, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Text key={i} {...text} element="div" />
      ))}
    </div>
  </Card>
)

const DeliveryFrequencyPage = (): JSX.Element | null => {
  const { dogs } = useReactiveVar(dogsDataVar) || {}
  const [durationSwiper, setDurationSwiper] = useState<SwiperClass>()
  const [servingsSwiper, setServingsSwiper] = useState<SwiperClass>()
  const [loading, setLoading] = useState<boolean>(true)
  const [durationIndex, setDurationIndex] = useState<number>()
  const [servingsIndex, setServingsIndex] = useState<number>()
  const [selectedPlan, setSelectedPlan] = useState<Plan | undefined>()
  const { getPlan } = usePlanOptions()
  const { planId } = useParams()
  const isPolish = ['pl', 'pl-PL'].includes(i18next.language)

  useEffect(() => {
    if (!isUndefined(planId) && !isUndefined(getPlan)) {
      setSelectedPlan(getPlan(planId))
    }
  }, [getPlan, planId])

  useEffect(() => {
    if (!isUndefined(servingsSwiper)) {
      servingsSwiper.on('slideChange', (swiper) => {
        setServingsIndex(swiper.activeIndex)
      })
    }
  }, [servingsSwiper])

  useEffect(() => {
    if (!isUndefined(durationSwiper)) {
      durationSwiper.on('slideChange', (swiper) => {
        setDurationIndex(swiper.activeIndex)
      })
    }
  }, [durationSwiper])

  const { currentPlan, frequencyOptionsByNumberOfPouches } = usePlanOptions()

  /**
   * Get the currently selected plan based off the currently
   * selected servings and duration.
   */
  useEffect(() => {
    if (
      !isUndefined(servingsIndex) &&
      !isUndefined(durationIndex) &&
      !isUndefined(frequencyOptionsByNumberOfPouches)
    ) {
      setSelectedPlan(
        frequencyOptionsByNumberOfPouches[servingsIndex].options[durationIndex]
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [servingsIndex, durationIndex, frequencyOptionsByNumberOfPouches])

  /**
   * Set initial plan on load
   */
  useEffect(() => {
    if (
      !isUndefined(currentPlan) &&
      !isUndefined(servingsSwiper) &&
      !isUndefined(durationSwiper) &&
      !isUndefined(frequencyOptionsByNumberOfPouches) &&
      isUndefined(durationIndex) &&
      isUndefined(servingsIndex)
    ) {
      const id = selectedPlan?.id ?? currentPlan.id

      frequencyOptionsByNumberOfPouches.every(
        (frequencyOption: FrequencyOption, servingIndex) => {
          const durationIndex = frequencyOption.options.findIndex(
            (plan) => plan.id === id
          )
          if (durationIndex !== -1) {
            setServingsIndex(servingIndex)
            setDurationIndex(durationIndex)

            servingsSwiper.slideTo(servingIndex, 0)
            durationSwiper.slideTo(durationIndex, 0)

            return false
          } else {
            return true
          }
        }
      )
    }
  }, [
    durationSwiper,
    durationIndex,
    currentPlan,
    frequencyOptionsByNumberOfPouches,
    servingsSwiper,
    servingsIndex,
    selectedPlan?.id
  ])

  /**
   * Handle loading state
   */
  useEffect(() => {
    setLoading(
      isUndefined(currentPlan) ||
        isUndefined(frequencyOptionsByNumberOfPouches) ||
        isNull(dogs)
    )
  }, [currentPlan, dogs, frequencyOptionsByNumberOfPouches])

  if (loading || isUndefined(currentPlan))
    return (
      <div className={STYLES.container}>
        <CardSkeleton width={800} height={900} />
      </div>
    )

  return (
    <div className={STYLES.container}>
      <PlanEditPage
        title={{
          text: 'delivery_frequency.current',
          variables: {
            pouches: currentPlan.numberOfPouches,
            count: currentPlan.durationInDays / 7
          }
        }}
        selectedPlan={selectedPlan}
        loading={loading}
      >
        <Text
          text="delivery_frequency.number_of_pouches.title"
          namespace={NAMESPACE}
          variant="textRegular20"
          margin={false}
        />
        <div className={STYLES.slider}>
          <SwiperSlider
            slider={setServingsSwiper}
            variant="brandYellow300"
            slidesPerView={1}
            initialSlide={0}
            arrows
          >
            {!isUndefined(servingsIndex) &&
            !isUndefined(frequencyOptionsByNumberOfPouches)
              ? frequencyOptionsByNumberOfPouches.map((frequencyOption) => (
                  // eslint-disable-next-line react/jsx-indent
                  <Pouch
                    key={frequencyOption.numberOfPouches}
                    size={frequencyOption.pouchSize < 400 ? 'small' : 'regular'}
                  >
                    <Text
                      text={frequencyOption.numberOfPouches.toString()}
                      translate={false}
                      variant="display28"
                      element="div"
                    />
                    <Text
                      text="delivery_frequency.pouch.size"
                      variables={{
                        grams: frequencyOption.pouchSize
                      }}
                      namespace={NAMESPACE}
                      variant="textRegular14"
                      element="div"
                    />
                  </Pouch>
                ))
              : null}
          </SwiperSlider>
        </div>
        <Text
          text="delivery_frequency.duration_in_days.title"
          namespace={NAMESPACE}
          variant="textRegular20"
          margin={false}
        />
        <div className={STYLES.slider}>
          <SwiperSlider
            slider={setDurationSwiper}
            variant="brandYellow300"
            slidesPerView={1}
            arrows
          >
            {!isUndefined(servingsIndex) &&
            !isUndefined(frequencyOptionsByNumberOfPouches)
              ? frequencyOptionsByNumberOfPouches[servingsIndex].options.map(
                  (plan: Plan): JSX.Element => (
                    <div className={STYLES.dateContainer} key={plan.id}>
                      <DateCard
                        text={[
                          {
                            text: (plan.durationInDays / 7).toString(),
                            variant: 'display28',
                            translate: false
                          },
                          {
                            text:
                              isPolish && plan.durationInDays / 7 === 8
                                ? `delivery_frequency_selection.week_1`
                                : `delivery_frequency_selection.week`,
                            variant: 'textRegular18',
                            variables: {
                              count: plan.durationInDays / 7
                            },
                            namespace: 'plan_management'
                          }
                        ]}
                      />
                    </div>
                  )
                )
              : null}
          </SwiperSlider>
        </div>
      </PlanEditPage>
    </div>
  )
}

export default DeliveryFrequencyPage
