// @noflow
import { captureException } from '@sentry/browser'
import type {
  PaymentRequestPaymentMethodEvent,
  PaymentRequestWallet,
  Stripe
} from '@stripe/stripe-js'
import 'scroll-behavior-polyfill'
import 'whatwg-fetch'

import { PaymentMethodable, Provider } from '@/types'

import * as ANALYTICS from '../../../Analytics/Analytics'
import { State } from '../../../PayOnOwnDevicePage'
import formatSubscriptionData from '../../../helpers/buiildSubscriptionData'
import {
  LoginUrlProps,
  setSubmissionState
} from '../../../screens/PaymentScreen/PaymentScreen'
import { PaymentMethod } from '../../../types'
import { submitSubscription } from '../../submitSubscription'
import stripeMutationMethods from '../sharedStripeMutationMethods'
import { chargePaymentRequestBoxOne } from './chargeBoxOnePaymentRequest'
import { paymentMethodFromWalletName } from './paymentMethodFromWalletName'

const buySubscriptionWithPaymentRequest = async ({
  event,
  stripe,
  paymentPageState,
  paymentMethodType,
  amount,
  navigateToNextStep
}: {
  stripe: Stripe
  event: PaymentRequestPaymentMethodEvent
  paymentPageState: State | null
  paymentMethodType: PaymentMethod
  amount: number
  navigateToNextStep: (input: LoginUrlProps) => void
}): Promise<void> => {
  const paymentMethod = paymentMethodFromWalletName(
    event.walletName as PaymentRequestWallet
  )
  const { raiseBannerError } = stripeMutationMethods()

  setSubmissionState({ type: 'loading' })
  try {
    if (!paymentPageState) {
      throw new Error('Pending subscription data not found')
    }

    const { data } = paymentPageState
    const { directSalesPendingSubscription } = data
    const { pendingSubscription } = directSalesPendingSubscription

    if (!pendingSubscription.user.email) {
      throw new Error('Email not found')
    }

    const paymentIntentId = await chargePaymentRequestBoxOne({
      paymentPageState,
      paymentMethod: event.paymentMethod,
      stripe,
      requestType: paymentMethodType,
      email: pendingSubscription.user.email,
      amount
    })

    if (!paymentIntentId) {
      throw new Error('Error charging payment request')
    }

    const formattedSubData = formatSubscriptionData({
      targetFirstDeliveryDate: paymentPageState.targetFirstDeliveryDate,
      subscriptionData: paymentPageState.data,
      provider: Provider.stripe,
      paymentMethodType: PaymentMethodable.CreditCard,
      paymentMethod: event.paymentMethod,
      paymentIntentId: paymentIntentId,
      supportedMarketingMethodPurposes: paymentPageState.marketingPreferences
    })
    console.log('formatted data', formattedSubData)

    if (!formattedSubData) {
      throw new Error('Error fetching subscription data')
    }

    const response = await submitSubscription({
      data: formattedSubData,
      navigateToNextStep
    })

    if (response && response.errors) throw new Error(response.errors[0].message)

    event.complete('success')
  } catch (error) {
    event.complete('fail')
    raiseBannerError(error as Error)

    if (error instanceof Error) {
      captureException(
        'Received error in response in buySubscriptionWithPaymentRequest',
        { extra: { error } }
      )
      ANALYTICS.setPaymentError({
        error: error.message,
        paymentMethod: paymentMethod
      })
    }
  }
}

export { buySubscriptionWithPaymentRequest }
