import { useStripe } from '@stripe/react-stripe-js'
import React, { useEffect } from 'react'

import { addIdealPaymentMethod } from '../screens/PaymentMethods/helpers/addPaymentMethod'

type useAddIdeal = {
  addIdealCallback: () => void
  idealAuthReceived: boolean
}

const useAddIdeal = ({
  userEmail,
  userToken,
  fullName,
  redirectUrl,
  csrfToken,
  idealBank,
  onSuccess,
  onError,
  onCreate,
  setLoading
}: {
  userEmail: string
  userToken: string
  fullName: string
  redirectUrl: string
  csrfToken: string
  idealBank: string
  onSuccess: () => void
  onError: (message: string) => void
  onCreate: (setupIntentId: string) => void
  setLoading: (loading: boolean) => void
}): useAddIdeal => {
  const stripe = useStripe()

  const [idealAuthReceived, setIdealAuthReceived] = React.useState(false)

  const addIdealCallback = React.useCallback(() => {
    if (!stripe)
      throw new Error('Cannot find stripe when addIdealCallback is called')

    addIdealPaymentMethod(
      userEmail,
      csrfToken,
      userToken,
      stripe,
      idealBank,
      fullName,
      redirectUrl,
      onSuccess,
      onError
    ).catch((error) => {
      console.log('error', error)
      onError(error)
    })
  }, [
    csrfToken,
    fullName,
    idealBank,
    onError,
    onSuccess,
    redirectUrl,
    stripe,
    userEmail,
    userToken
  ])

  // Upon being redirected back to page after an authorisation attempt to
  // create the subscription or alert the customer that authentication has failed
  useEffect(() => {
    if (window.location.search.includes('redirect_status') && stripe) {
      if (
        window.location.search.includes('redirect_status=succeeded') &&
        window.location.search.includes('method_type=ideal')
      ) {
        const urlParams = new URLSearchParams(window.location.search)
        const intentSecret = urlParams.get('setup_intent_client_secret')
        if (intentSecret) {
          setIdealAuthReceived(true)
          setLoading(true)
          stripe.retrieveSetupIntent(intentSecret).then((result) => {
            if (result.error) {
              setLoading(false)
              onError(result.error.message || '')
            } else {
              onCreate(result.setupIntent.id)
            }
          })
        } else {
          setIdealAuthReceived(false)
          setLoading(false)
          onError('Ideal payment failed, intentSecret is undefined')
        }
      } else {
        setLoading(false)
        setIdealAuthReceived(false)
        const urlParams = new URLSearchParams(window.location.search)
        const status = urlParams.get('redirect_status')
        // eslint-disable-next-line i18next/no-literal-string
        onError(`Ideal Auth Redirect Status = ${status}`)
        // Remove URL params after failed authentication
        window.history.replaceState(
          {},
          document.title,
          location.href.split('?')[0]
        )
      }
    }
  }, [onCreate, onError, setLoading, stripe])

  return {
    addIdealCallback,
    idealAuthReceived
  }
}

export default useAddIdeal
