// @noflow
import {
  MutationFunctionOptions,
  useMutation,
  useReactiveVar
} from '@apollo/client'
import isNull from 'lodash/isNull'
import { useCallback } from 'react'

import * as Sentry from '@/utils/sentry'

import { CREATE_PENDING_SUBSCRIPTION } from '../mutations/CreatePendingSubscription'

import {
  SimplifiedCreatePendingSubscription_createPendingSubscription_pendingSubscription_pendingSubscriptionFlavours as PendingSubscriptionFlavours,
  SimplifiedCreatePendingSubscription,
  SimplifiedCreatePendingSubscriptionVariables
} from '../mutations/__generated__/SimplifiedCreatePendingSubscription'

import { plansPageState } from '../SimplifiedPlansPage'
import type { PlanState } from '../SimplifiedPlansPage'

type UseCreatePendingSubscription = {
  createPendingSubscription: (
    options?: MutationFunctionOptions<
      SimplifiedCreatePendingSubscription,
      SimplifiedCreatePendingSubscriptionVariables
    >
  ) => void
  createPendingSubscriptionLoading: boolean
}

const useCreatePendingSubscription = (): UseCreatePendingSubscription => {
  const plansState: PlanState = useReactiveVar(plansPageState)

  const selectedPlanId = plansState.planId

  const proceedToCheckout = useCallback(
    (mealBreakdown: Array<PendingSubscriptionFlavours>) => {
      const simplifiedSelectedPlanDetailsKey =
        'simplified_selected_plan_details'

      window.localStorage.setItem(
        simplifiedSelectedPlanDetailsKey,
        JSON.stringify({
          mealBreakdown: mealBreakdown.reduce(
            (
              breakdown: { [key: string]: number },
              sf: PendingSubscriptionFlavours
            ) => {
              breakdown[sf.flavour.slug] = sf.sevenDayServings
              return breakdown
            },
            {}
          ),
          selectedPlanId,
          selectedExtraProducts: plansState.productVariants
        })
      )
      // eslint-disable-next-line i18next/no-literal-string
      window.location.href = `/subscription/checkout`
    },
    [plansState.productVariants, selectedPlanId]
  )

  const [
    createPendingSubscription,
    { loading: createPendingSubscriptionLoading }
  ] = useMutation<
    SimplifiedCreatePendingSubscription,
    SimplifiedCreatePendingSubscriptionVariables
  >(CREATE_PENDING_SUBSCRIPTION, {
    variables: {
      pendingSubscription: {
        planId: !isNull(plansState.planId) ? plansState.planId : '',
        productVariantIds: !isNull(plansState.productVariants)
          ? plansState.productVariants.map(({ id }) => id)
          : undefined,
        flavourIds: !isNull(plansState.flavours)
          ? plansState.flavours.map(({ id }) => id)
          : undefined
      }
    },
    onError: (error) => {
      Sentry.captureException(
        `Error with CREATE_PENDING_SUBSCRIPTION mutation`,
        {
          extra: { error },
          tags: {
            product: Sentry.Product.PlansFlow
          }
        }
      )
    },
    onCompleted: (data) => {
      proceedToCheckout(
        data.createPendingSubscription?.pendingSubscription
          .pendingSubscriptionFlavours || []
      )
    }
  })

  return {
    createPendingSubscription,
    createPendingSubscriptionLoading
  }
}

export default useCreatePendingSubscription
