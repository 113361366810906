// @flow

import * as ACTIONS from '../actions'

import toggleWantsToReceiveIceQuery from '../rails_queries/toggleWantsToReceiveIceQuery'

// Types
import type { Dispatch, Thunk } from 'redux'
import type { RequestResult } from '../rails_queries/toggleWantsToReceiveIceQuery'
import type { State } from '../index'

const toggleWantsToReceiveIce = (): Thunk => {
  return (dispatch: Dispatch, getState: () => State): Promise<void> => {
    dispatch(ACTIONS.toggleWantsToReceiveIceRequest())
    const { subscriptionDetails, csrfToken } = getState()
    const { id, wants_to_receive_ice } = subscriptionDetails
    const subscriptionId = id
    return toggleWantsToReceiveIceQuery({
      subscriptionId, wants_to_receive_ice, csrfToken
    }).then((res: RequestResult): void => {
      const { wants_to_receive_ice } = res
      dispatch(ACTIONS.toggleWantsToReceiveIceResponse(wants_to_receive_ice))
    })
  }
}

export default toggleWantsToReceiveIce
