import { gql } from '@apollo/client'

const TOP_NAV_QUERY = gql`
  query TopNavQuery {
    user {
      id
      firstName
      affiliateType
      referralsSinceLastLoginCount
      dogs {
        id
      }
    }
  }
`

export { TOP_NAV_QUERY }
