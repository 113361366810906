// @noflow
import classNames from 'classnames'
import React from 'react'

import STYLES from './PillToggle.module.sass'

import Interactive from '../Interactive/Interactive'

type Color = 'brand-blue-500'

type Props = {
  disabled?: boolean
  primaryColor?: Color
  onChange?: () => void
  toggled?: boolean
}

const PillToggle = ({
  disabled,
  primaryColor = 'brand-blue-500',
  toggled,
  onChange = () => null
}: Props): JSX.Element => {
  const cn = classNames(STYLES.toggle, STYLES[primaryColor], {
    [STYLES.active]: toggled
  })

  return (
    <Interactive
      element="button"
      className={cn}
      onClick={onChange}
      disabled={disabled}
      aria-pressed={toggled}
    />
  )
}

export { PillToggle, Props }
