// @noflow
import React, { useCallback } from 'react'

import { getLocalisedPathnames } from '@/utils/urls'

import Plus from 'assets/images/icons/plus.svg'

import Interactive from '@/components/elements/atoms/Interactive/Interactive'

import STYLES from './AddDogAction.module.sass'

type BgColour = 'brandYellow200' | 'brandBlue100'

type Props = {
  bgColour?: BgColour
}

const AddDogAction = ({ bgColour = 'brandYellow200' }: Props): JSX.Element => {
  const pathnames = getLocalisedPathnames()

  // TODO: Use NavigateContext if wizard is part of the SPA
  const handleAddNewDogs = useCallback(() => {
    window.location.href = `/${pathnames.WIZARD}`
  }, [pathnames.WIZARD])

  return (
    <Interactive
      element="button"
      onClick={handleAddNewDogs}
      className={`${STYLES.wrapper} ${STYLES[bgColour]}`}
    >
      <img src={Plus} alt="Add a dog" />
    </Interactive>
  )
}

export { AddDogAction }
