import React from 'react'
import { useTranslation } from 'react-i18next'

import Image from '@/components/elements/atoms/Image/Image'

import STYLES from './NavLogo.module.sass'

type Props = {
  size?: keyof typeof STYLES
  upsideDown?: boolean
}

const NavLogo = ({
  size = 'default',
  upsideDown = false
}: Props): JSX.Element => {
  const { t } = useTranslation('shared')
  if (upsideDown) {
    return (
      <Image
        alt={t('navigation.logo_alt')}
        slug={'butternut-box-logo-upside-down'}
        image={{
          width: size === 'large' ? 86 : 64,
          height: size === 'large' ? 70 : 52,
          resizeMode: 'resize_to_fit',
          retina: true
        }}
      />
    )
  } else {
    return (
      <Image
        className={size && STYLES[size]}
        alt={t('navigation.logo_alt')}
        slug={'butternut-logo-top'}
        image={{
          width: size === 'large' ? 103 : 82,
          height: size === 'large' ? 57 : 45,
          resizeMode: 'resize_to_fit',
          retina: true
        }}
      />
    )
  }
}

export default NavLogo
