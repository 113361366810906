import { gql } from '@apollo/client'

const PLAN_FRAGMENT = gql`
  fragment PlanFragment on Plan {
    id
    gramsPerDay
    gramsPerDayPerDogs {
      dogId
      name
      gramsPerDay
    }
    roundedCaloriesPerDay
    servingType
    typeOfPlanForCustomer
    numberOfPouches
    durationInDays
    pouchSize
    pricePerDay
    pricePerWeek
    price
    pricePerPouch
    pouchesPerDay
    servingSize
    numberOfServings
  }
`

const DOG_PLAN_FRAGMENT = gql`
  fragment DogPlanFragment on Dog {
    id
    name
    roundedDailyCalories(multipleOf: $caloriesMultipleOf)
    roundedDailyCaloriesLowerBoundary: roundedPercentageOfDailyCalories(
      multipleOf: $caloriesMultipleOf
      percentage: $lowerPercentage
    )
    roundedDailyCaloriesUpperBoundary: roundedPercentageOfDailyCalories(
      multipleOf: $caloriesMultipleOf
      percentage: $upperPercentage
    )
  }
`

const USE_PLAN_QUERY = gql`
  ${PLAN_FRAGMENT}
  ${DOG_PLAN_FRAGMENT}
  query UsePlanQuery(
    $caloriesMultipleOf: Int!
    $lowerPercentage: Int!
    $upperPercentage: Int!
  ) {
    user {
      id
      subscription {
        id
        deliveriesReceived
        recurringOrder {
          deliveryFee
          physicalProductsTotal
          surchargeTotal
        }
        planOptions {
          ...PlanFragment
        }
        plan {
          ...PlanFragment
        }
        idealPlan {
          ...PlanFragment
        }
      }
      dogs {
        id
        ...DogPlanFragment
      }
    }
  }
`

const UPDATE_PLAN_MUTATION = gql`
  ${PLAN_FRAGMENT}
  mutation UpdatePlanMutation($userId: ID!, $planId: ID!, $numOfBoxes: Int!) {
    subscriptionPlanUpdate(userId: $userId, planId: $planId) {
      id
      subscription {
        id
        nextNBoxes(num: $numOfBoxes) {
          id
          numberOfPouches
          durationInDays
          plan {
            id
            pouchSize
          }
        }
        plan {
          ...PlanFragment
        }
      }
    }
  }
`

export { USE_PLAN_QUERY, UPDATE_PLAN_MUTATION }
