/* eslint-disable i18next/no-literal-string */
import { Grid } from '@mui/material'
import classNames from 'classnames'
import i18next from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { pronounContext } from '@/utils/StringHelper'

import useWindowSize from '@/hooks/useWindowSize'

import DashedLoopInverted from 'assets/images/illustrations/dashed-loop-inverted.svg'
import DashedLoop from 'assets/images/illustrations/dashed-loop.svg'

import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'
import Image from '@/components/elements/atoms/Image'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import Text from '@/components/elements/atoms/Text'
import SwiperSlider from '@/components/elements/molecules/SwiperSlider/SwiperSlider'
import { BREAKPOINTS } from '@/components/templates/Base'

import STYLES from './AccountOverview.module.sass'

import { thankYouQueryV2_user_dogs as Dog } from '../../__generated__/thankYouQueryV2'

type Props = { onGoToAccountClick: () => void; dogs: Dog[] }

const AccountOverview = ({ dogs, onGoToAccountClick }: Props): JSX.Element => {
  const { windowWidth } = useWindowSize()
  const isMobile = windowWidth < BREAKPOINTS.md

  const genders = dogs.map((dog) => dog.gender)

  const items = [
    {
      className: 'deliveryDate',
      title: 'change_delivery_date',
      invert: true,
      image: {
        src: 'slide-delivery-date'
      }
    },
    {
      className: 'recipes',
      title: `recipe_selection_${pronounContext(genders, i18next.language)}`,
      image: {
        src: 'slide-recipes'
      }
    },
    {
      className: 'dailyFood',
      title: `update_food_${pronounContext(genders, i18next.language)}`,
      invert: true,
      image: {
        src: 'slide-daily-food'
      }
    },
    {
      className: 'extras',
      title: 'extras',
      image: {
        src: 'slide-extras'
      }
    }
  ]

  const { t } = useTranslation('account')

  return (
    <SectionWrapper margin={{ bottom: 32, top: 16 }}>
      {/* padding top + bottom 24, left right 16 */}
      <Card padding={24}>
        <Text
          namespace="account"
          variant="display20"
          text="thank_you_page_v2.account_overview.title"
          margin={false}
        />
        <Text
          namespace="account"
          margin={false}
          text="thank_you_page_v2.account_overview.sub_title"
        />

        <SwiperSlider
          slidesPerView={1}
          arrows={false}
          bullets
          className={STYLES.slider}
        >
          {items.map((slide): React.ReactElement => {
            const slideClass = classNames(
              STYLES.slide,
              STYLES[slide.className as keyof typeof STYLES]
            )

            return (
              <div key={slide.title} className={slideClass}>
                <Grid container>
                  <Grid
                    item
                    xs={6}
                    order={slide.invert ? 2 : 1}
                    className={STYLES.slideText}
                  >
                    <Text
                      namespace="account"
                      variant="display16"
                      text={`thank_you_page_v2.account_overview.${slide.title}`}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    order={slide.invert ? 1 : 2}
                    alignContent="flex-end"
                  >
                    <Image
                      slug={slide.image.src}
                      alt={t(
                        `thank_you_page_v2.account_overview.${slide.title}`
                      )}
                      image={{
                        width: isMobile ? 154 : 202,
                        height: isMobile ? 133 : 174
                      }}
                    />
                  </Grid>
                </Grid>

                <img
                  height={isMobile ? 60 : 90}
                  width={isMobile ? 60 : 100}
                  src={slide.invert ? DashedLoopInverted : DashedLoop}
                  alt="Dashed loop"
                  className={STYLES.dashedLoop}
                />
              </div>
            )
          })}
        </SwiperSlider>

        <SectionWrapper margin={{ top: 16, bottom: 8 }}>
          <Button
            identifier="thank_you.account_overview.go_to_account"
            typography={{
              text: 'thank_you_page_v2.go_to_account',
              namespace: 'account'
            }}
            onClick={onGoToAccountClick}
            fullWidth
          />
        </SectionWrapper>
      </Card>
    </SectionWrapper>
  )
}

export default AccountOverview
