// @noflow
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import {
  MAXIMUM_NUMBER_OF_EXTRA_PRODUCTS,
  MAXIMUM_NUMBER_OF_SEPARATE_DELIVERY_EXTRA_PRODUCTS
} from '@/utils/butternutbox/constants/digital_product'

import usePreviousValue from '@/hooks/usePreviousValue'

import Select from '@/components/elements/atoms/Select/Select'
import type { Option } from '@/components/elements/atoms/Select/Select'

import STYLES from './QuantitySelection.module.sass'

import type {
  SubscriptionStatus,
  VariantDelivery
} from '../../../../../../../../types/index'
import SectionWrapper from '../../SectionWrapper'

type Props = {
  selectedProductVariantFrequency: VariantDelivery
  isUpdateProductInProgress: boolean
  isUpdateProductDisabled: boolean
  setSelectedProductQuantity: (quantity: number) => void
  status: SubscriptionStatus
}

const QuantitySelection = ({
  selectedProductVariantFrequency,
  isUpdateProductInProgress,
  isUpdateProductDisabled,
  setSelectedProductQuantity,
  status
}: Props): JSX.Element => {
  const prevSelectedProductVariantFrequency = usePreviousValue<VariantDelivery>(
    selectedProductVariantFrequency
  )

  const initialQuantity = useMemo(
    () => ({
      id: '1' as Option['id'],
      value: 1 as Option['value'],
      label: {
        translate: false,
        text: '1'
      }
    }),
    []
  )
  const [quantity, setQuantity] = useState(initialQuantity)

  const quantitySelectionLimit =
    selectedProductVariantFrequency === 'on_demand'
      ? MAXIMUM_NUMBER_OF_SEPARATE_DELIVERY_EXTRA_PRODUCTS + 1
      : MAXIMUM_NUMBER_OF_EXTRA_PRODUCTS + 1

  const optionQuantitySelectionLimit =
    selectedProductVariantFrequency === 'on_demand'
      ? MAXIMUM_NUMBER_OF_SEPARATE_DELIVERY_EXTRA_PRODUCTS
      : MAXIMUM_NUMBER_OF_EXTRA_PRODUCTS

  const options = [...Array(optionQuantitySelectionLimit + 1).keys()]
    .slice(1)
    .map((option) => ({
      id: option.toString(),
      value: option,
      label: {
        translate: false,
        text: option.toString()
      }
    }))

  const onSelectChange = useCallback(
    (option): void => {
      setQuantity(option)
      setSelectedProductQuantity(option.value)
    },
    [setQuantity, setSelectedProductQuantity]
  )

  const resetQuantity = useCallback(() => {
    setQuantity(initialQuantity)
  }, [setQuantity, initialQuantity])

  useEffect(() => {
    if (
      prevSelectedProductVariantFrequency === 'on_demand' &&
      prevSelectedProductVariantFrequency !== selectedProductVariantFrequency &&
      (typeof quantity.value === 'number'
        ? quantity.value
        : parseInt(quantity.value)) >= quantitySelectionLimit
    ) {
      resetQuantity()
    }
  }, [
    resetQuantity,
    prevSelectedProductVariantFrequency,
    selectedProductVariantFrequency,
    quantity,
    quantitySelectionLimit
  ])

  return (
    <SectionWrapper
      title={{
        text: 'extras.product_collection.details.quantity_selection.title',
        namespace: 'dashboard'
      }}
      marginBottom
    >
      <div>
        <div className={STYLES.container}>
          {/* TODO: Make sure we use correct select component */}
          <Select
            initialValue={quantity}
            onChange={onSelectChange}
            label={{
              text: '1',
              translate: false
            }}
            options={options}
            disabled={
              isUpdateProductInProgress ||
              isUpdateProductDisabled ||
              status === 'suspended'
            }
          />
        </div>
      </div>
    </SectionWrapper>
  )
}

export default QuantitySelection
