// @noflow
import type { RecommendationId } from '@/shared_types/recommendationId'
import { ensureNever } from '@/typescript/utils'

type Impression = 'ReallyWell' | 'OkaySoFar' | 'NotEating'

type MealRating = {
  meal: string
  rating: number
}

type PooCondition = 'BetterThanBefore' | 'TheSame' | 'RunnierNow'

type TransitionSpeed = 'AllIn' | 'Slowly'

type Answers = {
  firstBoxImpression: Impression
  currentPooCondition: PooCondition
  mealRating: Array<MealRating>
  transitioningSpeed: TransitionSpeed
}

type Recommendation = {
  id: RecommendationId
  destination: string
  completed: boolean
  image?: string
}

type Recommendations = Array<Recommendation>

// TODO: images are temp for the time being - need BE updates to get
// all data from a Recommendation object if possible

const RecipeChange: Recommendation = {
  id: 'RecipeChange',
  destination:
    'dashboard/subscription?scrollDestination=meal-selection&openModal=meal-selection',
  completed: false,
  image: '/Web/photos/dogs/dog-next-to-butternutbox'
}

const RecipeChangeSensitiveStomach: Recommendation = {
  id: 'RecipeChangeSensitiveStomach',
  destination:
    'dashboard/subscription?scrollDestination=meal-selection&openModal=meal-selection',
  completed: false,
  image: '/Web/photos/dogs/bella-waiting-for-her-turkey-meal'
}

const TransitioningTips: Recommendation = {
  id: 'TransitioningTips',
  destination:
    'https://help.butternutbox.com/en/articles/85-transitioning-to-butternut',
  completed: false,
  image: '/Web/photos/dogs/BUTTERNUTBOX_2022_09_28_LJ4818'
}

const AddBiscuits: Recommendation = {
  id: 'AddBiscuits',
  destination: 'dashboard/extras',
  completed: false,
  image: '/Web/photos/dogs/puppy-with-baked-biscuits--mobile'
}

const DelayBox: Recommendation = {
  id: 'DelayBox',
  destination: 'dashboard/boxes',
  completed: false,
  image: '/Web/photos/dogs/how-it-works-2'
}

const Raf: Recommendation = {
  id: 'Raf',
  destination: 'dashboard/refer-a-friend',
  completed: false,
  image: '/Web/photos/dogs/three-dogs-wearing-bandanas'
}

const Extras: Recommendation = {
  id: 'Extras',
  destination: 'dashboard/extras',
  completed: false,
  image: '/Web/photos/dogs/german-shepherd-with-butternut-box--mobile'
}

const validateFirstBoxImpression = (
  firstBoxImpression: Impression
): boolean => {
  switch (firstBoxImpression) {
    case 'ReallyWell':
    case 'OkaySoFar':
    case 'NotEating': {
      return true
    }
    default: {
      ensureNever(firstBoxImpression)
      throw new Error(`Invalid firstBoxImpression of: ${firstBoxImpression}`)
    }
  }
}

const validateCurrentPooCondition = (
  currentPooCondition: PooCondition
): boolean => {
  switch (currentPooCondition) {
    case 'BetterThanBefore':
    case 'TheSame':
    case 'RunnierNow': {
      return true
    }
    default: {
      ensureNever(currentPooCondition)
      throw new Error(`Invalid currentPooCondition of: ${currentPooCondition}`)
    }
  }
}

const validateTransitioningSpeed = (
  transitioningSpeed: TransitionSpeed
): boolean => {
  switch (transitioningSpeed) {
    case 'AllIn':
    case 'Slowly': {
      return true
    }
    default: {
      ensureNever(transitioningSpeed)
      throw new Error(`Invalid transitioningSpeed of: ${transitioningSpeed}`)
    }
  }
}

const validateAnswers = (answers: Answers): boolean =>
  validateFirstBoxImpression(answers.firstBoxImpression) &&
  validateCurrentPooCondition(answers.currentPooCondition) &&
  validateTransitioningSpeed(answers.transitioningSpeed)

const generateRecommendations = (answers: Answers): Recommendations => {
  const {
    firstBoxImpression,
    currentPooCondition,
    mealRating,
    transitioningSpeed
  } = answers

  if (!validateAnswers(answers)) {
    throw new Error(
      `Answers given are not valid and recommendations cannot be generated. Answers given: ${JSON.stringify(
        answers
      )}`
    )
  }

  const positiveFirstBoxImpression =
    firstBoxImpression === 'ReallyWell' || firstBoxImpression === 'OkaySoFar'

  const negativeFirstBoxImpression = firstBoxImpression === 'NotEating'

  const positivePooCondition =
    currentPooCondition === 'BetterThanBefore' ||
    currentPooCondition === 'TheSame'

  const allMealsFiveStars = mealRating.every(
    ({ rating }: MealRating): boolean => rating === 5
  )

  switch (true) {
    case positiveFirstBoxImpression && allMealsFiveStars: {
      if (positivePooCondition) {
        if (transitioningSpeed === 'Slowly') {
          return [DelayBox, Extras, Raf]
        } else {
          return [Extras, Raf]
        }
      } else {
        if (transitioningSpeed === 'Slowly') {
          return [RecipeChangeSensitiveStomach, DelayBox, Extras, Raf]
        } else {
          return [RecipeChangeSensitiveStomach, TransitioningTips, Raf]
        }
      }
    }

    case positiveFirstBoxImpression && !allMealsFiveStars: {
      if (positivePooCondition) {
        if (transitioningSpeed === 'Slowly') {
          return [RecipeChange, DelayBox, Extras, Raf]
        } else {
          return [RecipeChange, Extras, Raf]
        }
      } else {
        if (transitioningSpeed === 'Slowly') {
          return [RecipeChangeSensitiveStomach, DelayBox, Raf]
        } else {
          return [RecipeChangeSensitiveStomach, TransitioningTips, Raf]
        }
      }
    }

    case negativeFirstBoxImpression && allMealsFiveStars: {
      if (positivePooCondition) {
        if (transitioningSpeed === 'Slowly') {
          return [DelayBox, AddBiscuits]
        } else {
          return [AddBiscuits]
        }
      } else {
        if (transitioningSpeed === 'Slowly') {
          return [RecipeChangeSensitiveStomach, DelayBox, AddBiscuits]
        } else {
          return [AddBiscuits, TransitioningTips, RecipeChangeSensitiveStomach]
        }
      }
    }

    case negativeFirstBoxImpression && !allMealsFiveStars: {
      if (positivePooCondition) {
        if (transitioningSpeed === 'Slowly') {
          return [DelayBox, RecipeChangeSensitiveStomach, AddBiscuits]
        } else {
          return [RecipeChange, AddBiscuits]
        }
      } else {
        if (transitioningSpeed === 'Slowly') {
          return [DelayBox, RecipeChangeSensitiveStomach, AddBiscuits]
        } else {
          return [TransitioningTips, RecipeChangeSensitiveStomach, AddBiscuits]
        }
      }
    }
    default: {
      throw new Error(
        `Cannot generate recommendations for answers of: ${JSON.stringify(
          answers
        )}`
      )
    }
  }
}

export type {
  Recommendations,
  Recommendation,
  RecommendationId,
  Answers,
  MealRating,
  Impression,
  PooCondition,
  TransitionSpeed
}

export {
  validateFirstBoxImpression,
  validateCurrentPooCondition,
  validateTransitioningSpeed
}

export default generateRecommendations
