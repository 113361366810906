// @noflow
import { makeVar, useReactiveVar } from '@apollo/client'
import React from 'react'
import { useTranslation } from 'react-i18next'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import Modal from '@/components/elements/atoms/Modal/Modal'
import Text from '@/components/elements/atoms/Text/Text'
import Video from '@/components/elements/atoms/Video'

import STYLES from './RuffGuideModal.module.sass'

type Props = {
  namespace: string
}

const ruffGuideModalState = makeVar(false)

const RuffGuideModal = ({ namespace }: Props): JSX.Element => {
  const copyContext = 'refer_a_friend.give_and_get_tab.ruff_guide_modal'
  const RuffGuideModalOpen = useReactiveVar(ruffGuideModalState)
  const { windowWidth } = useWindowSize()
  const { t } = useTranslation(namespace)

  return (
    <Modal
      isModalOpen={RuffGuideModalOpen}
      setOpenModal={ruffGuideModalState}
      width={600}
      showCloseButton
      bodyScroll
      bottomSticky={windowWidth < BREAKPOINTS.md}
    >
      <div className={STYLES.title}>
        <Text
          namespace={namespace}
          text={`${copyContext}.title`}
          variant="display20"
          align="center"
          margin={false}
        />
      </div>
      <div className={STYLES.videoContainer}>
        <Video
          alt={t(`${copyContext}.video_alt`)}
          controls
          muted={false}
          loop={false}
          slug="why-should-your-friends-become-butternutters"
          video={{
            format: 'mp4'
          }}
        />
      </div>
    </Modal>
  )
}

export { ruffGuideModalState }
export default RuffGuideModal
