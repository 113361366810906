// @noflow
import { Language } from '@/packs/localisation'
import isUndefined from 'lodash/isUndefined'
import React, { ReactNode, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { formatCurrencyWithDecimal } from '@/utils/currency'

import Label from '@/components/elements/atoms/Label/Label'
import ProductThumb from '@/components/elements/atoms/ProductThumb/ProductThumb'
import QuantitySelector from '@/components/elements/atoms/QuantitySelector/QuantitySelector'
import SkeletonImage from '@/components/elements/atoms/SkeletonImage/SkeletonImage'
import SkeletonTitle from '@/components/elements/atoms/SkeletonTitle/SkeletonTitle'
import Text from '@/components/elements/atoms/Text/Text'
import LabelList from '@/components/elements/molecules/LabelList/LabelList'
import SurchargeTooltip from '@/components/elements/molecules/SurchargeTooltip'

import STYLES from './../boxitem.module.sass'

import { Code } from '@/types'

type ProductLabel = {
  name: string
  themeColour: string
}

type Config = {
  imageSize?: 'xsmall' | 'small' | 'medium'
  noBorder?: boolean
}

type SkeletonFreshMealItemProps = Pick<Props, 'config'>

type Props = {
  name: string
  slug: string
  pouchSize?: number
  numberOfPouches: number
  thumbnail: string
  productLabels: ProductLabel[] | null
  recipeSurcharge?: number | null
  shouldSeeRecipeSurchargeTooltips: boolean
  shouldSeeRecipeSurchargePriceIncreasePl: boolean
  shippingCountryCode: Code
  language: Language
  editable?: boolean
  children?: ReactNode
  price?: number
  discountedPrice?: number
  config?: Config
  perDogLabel?: boolean
}

type EditableFreshMealItemProps = Props & {
  id: string
  maxNumberOfPouches?: number
  processing?: boolean
  onQuantityChange?: (id: string, quantity: number) => void
}

const SkeletonFreshMealItem = ({
  config
}: SkeletonFreshMealItemProps): JSX.Element => {
  return (
    <div
      className={`${STYLES.boxItemRow} ${
        config?.noBorder ? STYLES.noBorder : ''
      }`}
    >
      <div className={STYLES.details}>
        <div className={STYLES.freshMealItemImage}>
          <SkeletonImage
            width={config?.imageSize === 'medium' ? '8rem' : '6rem'}
            height={config?.imageSize === 'medium' ? '8rem' : '6rem'}
          />
        </div>
        <div className={STYLES.main}>
          <div className={STYLES.nameAndPrice}>
            <div className={STYLES.name}>
              <SkeletonTitle align={'left'} width={'40%'} height={'1.6rem'} />
            </div>
          </div>
          <SkeletonTitle align={'left'} height={'1.4rem'} />
        </div>
      </div>
    </div>
  )
}

const FreshMealItem = ({
  name,
  slug,
  pouchSize,
  numberOfPouches,
  thumbnail,
  productLabels,
  recipeSurcharge,
  children,
  editable = false,
  shouldSeeRecipeSurchargeTooltips,
  shouldSeeRecipeSurchargePriceIncreasePl,
  shippingCountryCode,
  language,
  price,
  discountedPrice,
  config,
  perDogLabel = false
}: Props): JSX.Element => {
  const { locale, currency } = countryCodeToLocaleCurrency(
    shippingCountryCode,
    language
  )

  const namespace = 'shared'
  const copyContext = 'box_breakdown'

  const { t } = useTranslation(namespace)

  const hasDiscount = price && discountedPrice && discountedPrice < price

  const servingsText = perDogLabel
    ? t(`${copyContext}.recipe_servings_multidog`, {
        numberOfServings: numberOfPouches,
        count: numberOfPouches
      })
    : t(`${copyContext}.recipe_servings`, {
        numberOfServings: numberOfPouches,
        count: numberOfPouches
      })

  return (
    <div
      className={`${STYLES.boxItemRow} ${
        config?.noBorder ? STYLES.noBorder : ''
      }`}
    >
      <div className={STYLES.details}>
        <div className={STYLES.freshMealItemImage}>
          {!isUndefined(thumbnail) && (
            <ProductThumb
              image={thumbnail}
              alt=""
              size={config?.imageSize || 'xsmall'}
            />
          )}
        </div>
        <div className={STYLES.main}>
          <div className={STYLES.nameAndPrice}>
            <div className={STYLES.name}>
              <Text text={name} margin={false} translate={false} align="left" />
            </div>
            {price && (
              <div className={STYLES.discountedPrice}>
                {hasDiscount && (
                  <Text
                    text={formatCurrencyWithDecimal(discountedPrice, {
                      locale,
                      currency
                    })}
                    margin={false}
                    translate={false}
                    colour="brandRed500"
                    bold
                  />
                )}
                <Text
                  text={
                    hasDiscount
                      ? `<accent type='strikeThrough'>${formatCurrencyWithDecimal(
                          price,
                          {
                            locale,
                            currency
                          }
                        )}</accent>`
                      : formatCurrencyWithDecimal(price, {
                          locale,
                          currency
                        })
                  }
                  margin={false}
                  translate={false}
                  colour={hasDiscount ? 'brandBlue400' : 'brandBlue500'}
                  bold={!hasDiscount}
                />
              </div>
            )}
            <SurchargeTooltip
              name={name}
              slug={slug}
              shippingCountryCode={shippingCountryCode}
              language={language}
              recipeSurcharge={recipeSurcharge || 0}
              shouldSeeRecipeSurchargeTooltips={
                shouldSeeRecipeSurchargeTooltips
              }
              shouldSeeRecipeSurchargePriceIncreasePl={
                shouldSeeRecipeSurchargePriceIncreasePl
              }
            />
          </div>
          {!editable && (
            <div
              className={`${STYLES.boxItemDescription} ${
                pouchSize ? STYLES.pouchSize : ''
              }`}
            >
              <Text
                text={servingsText}
                namespace={namespace}
                variant="textRegular14"
                colour="brandBlue400"
                margin={false}
                element="span"
                translate={false}
              />
              {pouchSize && (
                <Text
                  text={`${copyContext}.pouch_size`}
                  variables={{ pouchSize }}
                  namespace={namespace}
                  variant="textRegular14"
                  colour="brandBlue400"
                  margin={false}
                  element="span"
                />
              )}
            </div>
          )}
          {productLabels && productLabels.length > 0 && (
            <LabelList>
              {productLabels.map((label) => (
                // eslint-disable-next-line jsx-a11y/label-has-for
                <Label
                  key={`label-${label.name}-key`}
                  text={{
                    text: label.name,
                    translate: false
                  }}
                  colour={label.themeColour}
                />
              ))}
            </LabelList>
          )}
        </div>
      </div>
      {children && <div className={STYLES.children}>{children}</div>}
    </div>
  )
}

const EditableFreshMealItem = ({
  id,
  onQuantityChange,
  processing,
  maxNumberOfPouches,
  ...rest
}: EditableFreshMealItemProps): JSX.Element => {
  const { numberOfPouches } = rest
  const onQuantityChangeCallback = useCallback(
    (to: number) => {
      if (!isUndefined(onQuantityChange)) {
        onQuantityChange(id, Number(to))
      }
    },
    [onQuantityChange, id]
  )
  return (
    <FreshMealItem {...rest} editable>
      <div className={`${STYLES.fields} ${STYLES.quantitySelectorWrapper}`}>
        <div className={STYLES.update}>
          <QuantitySelector
            quantity={numberOfPouches}
            onChange={onQuantityChangeCallback}
            processing={processing}
            maxValue={maxNumberOfPouches}
            size={30}
            controlled
            minValue={0}
            identifier="box_item.shop_item"
          />
        </div>
      </div>
    </FreshMealItem>
  )
}

export {
  Config,
  Props,
  ProductLabel,
  EditableFreshMealItem,
  EditableFreshMealItemProps,
  SkeletonFreshMealItem
}
export default FreshMealItem
