// @noflow
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import BRAND_COLOURS from '@/constants/BrandColours'

import { Button, ButtonProps } from '@/components/elements/atoms/Button'
import Icon from '@/components/elements/atoms/Icon/Icon'

import STYLES from './StickyNavigation.module.sass'

type Props = {
  variant?: keyof typeof STYLES
  buttonOne: {
    dataTestId?: string
    url?: string
    variant: ButtonProps['variant']
    text: string
    namespace?: string
    icon?: JSX.Element | null
    iconColour?: keyof typeof BRAND_COLOURS
    onClick?: (
      e:
        | React.MouseEvent<HTMLButtonElement>
        | React.KeyboardEvent<HTMLButtonElement>
    ) => void
    fullWidth?: boolean
  }
  buttonTwo?: {
    dataTestId?: string
    url?: string
    variant: ButtonProps['variant']
    text: string
    namespace?: string
    icon?: JSX.Element | null
    iconColour?: keyof typeof BRAND_COLOURS
    onClick?: (
      e:
        | React.MouseEvent<HTMLButtonElement>
        | React.KeyboardEvent<HTMLButtonElement>
    ) => void
  }
  disabled?: boolean
  hide?: boolean
  children?: ReactNode
  fullHeight?: boolean
  inlineChildren?: boolean
}

const StickyNavigationButtonIcon = (
  button: Props['buttonOne'] | Props['buttonTwo'],
  direction: 'left' | 'right' = 'right'
): JSX.Element | undefined => {
  if (isUndefined(button)) return undefined

  const { icon, iconColour } = button

  return !isNull(icon) ? (
    !isUndefined(icon) ? (
      icon
    ) : (
      <Icon
        asset="chevron"
        accentColour={iconColour}
        size={18}
        width={18}
        direction={direction}
      />
    )
  ) : undefined
}

const StickyNavigation = ({
  variant = 'oneButton',
  buttonOne,
  buttonTwo,
  children,
  disabled = false,
  hide = false,
  fullHeight = false,
  inlineChildren = false
}: Props): JSX.Element => {
  return (
    <div
      className={`${STYLES.container} ${hide ? STYLES.hide : ''} ${
        fullHeight ? STYLES.fullHeight : ''
      }`}
    >
      <div
        id={'stickyNavigation'}
        className={`${STYLES.wrapper} ${
          inlineChildren ? STYLES.inlineChildren : ''
        }`}
      >
        {children && <div className={STYLES.children}>{children}</div>}
        <Link
          to={buttonOne.url ? buttonOne.url : '#'}
          aria-disabled={disabled}
          className={`${
            variant === 'oneButton' && disabled ? STYLES.disabled : ''
          } ${buttonOne.fullWidth ? STYLES.fullWidth : ''}`}
        >
          <Button
            dataTestId={buttonOne.dataTestId}
            variant={buttonOne.variant}
            typography={{
              text: buttonOne.text,
              namespace: buttonOne.namespace
            }}
            icon={{
              position: variant === 'twoButtons' ? 'left' : 'right',
              component: StickyNavigationButtonIcon(
                buttonOne,
                variant === 'twoButtons' ? 'left' : 'right'
              )
            }}
            onClick={buttonOne.onClick}
            disabled={variant === 'oneButton' ? disabled : false}
            fullWidth={buttonOne.fullWidth}
            disableAnalytics
          />
        </Link>
        {variant === 'twoButtons' &&
          buttonTwo &&
          (buttonTwo.url ? (
            <Link
              to={buttonTwo.url}
              aria-disabled={disabled}
              className={
                variant === 'twoButtons' && disabled ? STYLES.disabled : ''
              }
            >
              <Button
                dataTestId={buttonTwo.dataTestId}
                variant={buttonTwo.variant}
                typography={{
                  text: buttonTwo.text,
                  namespace: buttonTwo.namespace
                }}
                icon={{
                  component: StickyNavigationButtonIcon(buttonTwo, 'right'),
                  position: 'right'
                }}
                onClick={buttonTwo.onClick}
                disabled={disabled}
                disableAnalytics
              />
            </Link>
          ) : (
            <Button
              dataTestId={buttonTwo.dataTestId}
              typography={{
                text: buttonTwo.text,
                namespace: buttonTwo.namespace
              }}
              variant={buttonTwo.variant}
              icon={{
                component: StickyNavigationButtonIcon(buttonTwo, 'right'),
                position: 'right'
              }}
              onClick={buttonTwo.onClick}
              disabled={disabled}
              disableAnalytics
            />
          ))}
      </div>
    </div>
  )
}

export { Props }
export default StickyNavigation
