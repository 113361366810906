// @noflow
import React from 'react'

type ItemType = 'regular' | 'primary' | 'highlighted'

type Props = {
  type: ItemType
  onClick: () => void
  className?: string
  children: React.ReactNode
}

const itemTypeClasses = (type: ItemType): string => {
  switch (type) {
    case 'regular': {
      return 'list-item'
    }
    case 'primary': {
      return 'list-item list-item--primary'
    }
    case 'highlighted': {
      return 'list-item list-item--highlighted'
    }
  }
}

const ListItem = ({
  type,
  onClick,
  className,
  children
}: Props): React.ReactElement => (
  <button
    onClick={onClick}
    type="button"
    className={`${itemTypeClasses(type)} ${className ?? ''}`}
  >
    {children}
  </button>
)

export default ListItem
