// @noflow
import type { Language } from '@/packs/localisation'
import { useQuery } from '@apollo/client'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React from 'react'

import { capitaliseFirstLetter } from '@/utils/StringHelper'
import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { formatCurrencyWithDecimal } from '@/utils/currency'
import { localeToStripeLocale } from '@/utils/localeToStripeLocale'

import PaymentDetailsSection from './components/PaymentDetailsSection'
import Text from '@/components/elements/atoms/Text/Text'
import DashboardNavigation from '@/components/elements/organisms/DashboardNavigation/DashboardNavigation'
import LoadingScreen from '@/components/elements/organisms/LoadingScreen/LoadingScreen'

import STYLES from './NoPaymentMethodPage.module.sass'

import { NO_PAYMENT_METHOD_PAGE_DATA } from './queries/noPaymentMethodPageQuery'

import { NoPaymentMethodPageData } from './queries/__generated__/NoPaymentMethodPageData'

import { useReachedNoPaymentMethodPageTracking } from './analytics/NoPaymentMethodAnalytics'

type Props = {
  language: Language
  csrfToken: string
  stripePublishableKey: string
}

const NoPaymentMethodPage = ({
  language,
  csrfToken,
  stripePublishableKey
}: Props): JSX.Element | null => {
  const namespace = 'dashboard'
  const { loading, data } = useQuery<NoPaymentMethodPageData>(
    NO_PAYMENT_METHOD_PAGE_DATA
  )
  const stripePromise = loadStripe(stripePublishableKey)
  useReachedNoPaymentMethodPageTracking()

  if (loading || !data) {
    return (
      <LoadingScreen
        isOpen={loading}
        variant={'static'}
        title={{
          namespace,
          text: 'no_payment_method.loading'
        }}
      />
    )
  }

  const dogNames = data.user.dogs?.map((dog) => dog.name) || []
  const subscription = data.user.subscription
  const { locale, currency } = countryCodeToLocaleCurrency(
    data.user.shippingCountryCode,
    data.user.preferredLanguage
  )
  const pricePerBox = formatCurrencyWithDecimal(subscription.firstBox.price, {
    locale: locale,
    currency: currency
  })

  return (
    <React.Fragment>
      <DashboardNavigation showSecondaryNav={false} />
      <div className={STYLES.container}>
        <div className={STYLES.content}>
          <Text
            variant={'display20'}
            namespace={namespace}
            colour={'brandBlue500'}
            align={'left'}
            text={'no_payment_method.add_payment_details'}
          />
          <div className={STYLES.textContainer}>
            <Text
              variant={'textRegular16'}
              align={'left'}
              namespace={namespace}
              variables={{
                count: dogNames.length,
                dogName: capitaliseFirstLetter(dogNames[0]),
                planDurationInDays: subscription.plan.durationInDays,
                formattedBoxPrice: pricePerBox
              }}
              text={'no_payment_method.text_one'}
              colour={'brandBlue500'}
            />
          </div>
          <Elements
            stripe={stripePromise}
            options={{
              locale: localeToStripeLocale(data.user.preferredLanguage)
            }}
          >
            <PaymentDetailsSection
              namespace={namespace}
              user={data.user}
              csrfToken={csrfToken}
            />
          </Elements>
        </div>
      </div>
    </React.Fragment>
  )
}

export { Props }

export default NoPaymentMethodPage
