import { useReactiveVar } from '@apollo/client'
import { Grid } from '@mui/material'
import classNames from 'classnames'
import i18next from 'i18next'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { userDataVar } from '@/services/apollo'

import { possessive, pronounContext } from '@/utils/StringHelper'

import { useDogProfileUpload } from '@/hooks/useDogProfilePictureUpload'

import PolaroidDecoration from 'assets/images/backgrounds/dog-profile-picture-decorations.svg'
import PolaroidFrame from 'assets/images/backgrounds/dog-profile-polaroid-frame-blank.svg'
import PolaroidFallback from 'assets/images/backgrounds/dog-profile-polaroid-no-image.svg'

import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import Text from '@/components/elements/atoms/Text'

import STYLES from './DogProfile.module.sass'

import { thankYouQueryV2_user_dogs as Dog } from '../../__generated__/thankYouQueryV2'

type DogProfileCard = {
  dog: Dog
  handleUpload: (path: string, dog: Dog) => void
}

type DogProfileProps = {
  dogs: Dog[]
  handleUpload: (path: string, dog: Dog) => void
}

const DogProfileCard = ({ dog, handleUpload }: DogProfileCard) => {
  const { t } = useTranslation('account')
  const user = useReactiveVar(userDataVar)

  const dogProfileUpload = {
    handleSuccessMessage: (file: string) => handleUpload(file, dog),
    userId: user?.id || '',
    dog
  }

  const {
    fileInputRef,
    handleElementClick,
    handleFileSelect,
    pictureUploading,
    profilePicture
  } = useDogProfileUpload(dogProfileUpload)

  const polaroidClasses = classNames(STYLES.polaroidPicture, {
    [STYLES.rotate]: !!profilePicture || dog.dogProfile?.avatarUrl
  })

  return (
    <Card
      className={STYLES.profileWrapper}
      padding={24}
      dataTestId={`dog-profile`}
    >
      <Grid container gap={{ xs: 2 }} wrap="nowrap">
        <Grid item xs={5}>
          <div className={STYLES.polaroid}>
            <img
              src={PolaroidFrame}
              alt={t('dog_profile.profile_picture.image_alt_text', {
                dogName: dog?.name
              })}
              className={STYLES.polaroidFrame}
            />

            <img
              src={
                profilePicture || dog.dogProfile?.avatarUrl || PolaroidFallback
              }
              alt={dog.dogProfile?.description || dog.name}
              className={polaroidClasses}
            />

            <img
              src={PolaroidDecoration}
              className={STYLES.polaroidPictureDecoration}
              alt={dog.name}
            />
          </div>
        </Grid>
        <Grid item xs={7}>
          <Text
            dataTestId="dog-profile-title"
            namespace="account"
            variant="display20"
            text="thank_you_page_v2.dog_profile.complete_profile"
            variables={{ dogName: possessive(dog.name) }}
          />

          <Text
            namespace="account"
            margin={false}
            text={`thank_you_page_v2.dog_profile.polaroid_${pronounContext(
              [dog.gender],
              i18next.language
            )}`}
            colour="brandBlue400"
          />
        </Grid>
      </Grid>

      <SectionWrapper margin={{ top: 16, bottom: 8 }}>
        <Button
          dataTestId="dog-profile-upload-button"
          identifier="thank_you.dog_profile_upload_button"
          typography={{
            text: 'thank_you_page_v2.dog_profile.upload_button',
            namespace: 'account'
          }}
          fullWidth
          onClick={handleElementClick}
          disabled={pictureUploading}
        />

        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileSelect}
          className={STYLES.input}
          data-testid="dog-profile-upload-input"
        />
      </SectionWrapper>
    </Card>
  )
}

const DogProfile = ({ dogs, handleUpload }: DogProfileProps): JSX.Element => (
  <Fragment>
    {dogs.map((dog) => (
      <DogProfileCard key={dog.id} dog={dog} handleUpload={handleUpload} />
    ))}
  </Fragment>
)

export default DogProfile
