import { gql } from '@apollo/client'

const SEND_EMAIL_MUTATION = gql`
  mutation ReferAFriendUpsert($userId: ID!, $emails: [String!]!) {
    referAFriendUpsert(userId: $userId, emails: $emails) {
      id
    }
  }
`

export { SEND_EMAIL_MUTATION }
