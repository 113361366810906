// @noflow
// Utils
import { addMinutes } from 'date-fns'
import Cookies from 'js-cookie'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import BREAKPOINTS from '@/constants/Breakpoints'

import useUserInactivity from '@/hooks/useUserInactivity'
import useWindowSize from '@/hooks/useWindowSize'

import DiscountModalForm from './components/DiscountModalForm'
import segmentTrack from '@/components/analytics/Analytics'
import Image from '@/components/shared/elements/Image'

import TransitionalModal from '../../TransitionalModal'

type Props = {
  discountCode: string
  discountCopy: string
  discountPercentage: string
  funnelCompleted: boolean
  shouldSeeBlogDiscountModal: boolean
}

const COUNTDOWN_DURATION_IN_SECONDS = 15
const namespace = 'shared'
const copyContext = 'blog_discount_modal'

const BlogExitDiscountModal = ({
  discountCode,
  discountCopy,
  discountPercentage,
  funnelCompleted,
  shouldSeeBlogDiscountModal
}: Props): ReactElement | null => {
  const [discountModalIsMounted, setDiscountModalIsMounted] = useState(false)
  const [discountModalIsOpen, setDiscountModalIsOpen] = useState(false)

  const { windowWidth } = useWindowSize()
  const isMobile = windowWidth < BREAKPOINTS.md

  const showModal = !Cookies.get('has_closed_blog_discount_modal')

  const { t } = useTranslation(namespace)

  const { hasAbandoned, resetCountDown } = useUserInactivity({
    countdownDuration: COUNTDOWN_DURATION_IN_SECONDS,
    stopCountdown: !showModal
  })

  const closeDiscountModal = useCallback((): void => {
    setDiscountModalIsOpen(false)
    // Unlocks background body-scroll
    document.body.classList.remove('modal--open')

    const now = new Date()
    const expiration = addMinutes(now, 30)
    Cookies.set('has_closed_blog_discount_modal', 'true', {
      expires: expiration,
      path: '/'
    })

    setTimeout((): void => {
      setDiscountModalIsMounted(false)
    }, 300)
  }, [])

  useEffect(() => {
    resetCountDown()
  }, [resetCountDown])

  useEffect(() => {
    if (showModal && hasAbandoned) {
      setDiscountModalIsMounted(true)

      // Locks background body-scroll
      document.body.classList.add('modal--open')

      setTimeout((): void => {
        setDiscountModalIsOpen(true)

        const eventName = 'Blog Exit Discount Modal Displayed'
        const properties = {
          screen_identifier: 'Blog',
          component_identifier: 'Blog Exit Discount Modal'
        }
        segmentTrack(eventName, properties)
      }, 1000)
    }
  }, [hasAbandoned, showModal])

  if (!showModal || !hasAbandoned || !discountModalIsMounted) return null

  return (
    <TransitionalModal
      closeModal={closeDiscountModal}
      extraClasses="discount-modal__modal"
      modalIsOpen={discountModalIsOpen}
      modalSize="medium"
      showCloseButton
      transitionTypes={{ desktop: 'scale', mobile: 'scale' }}
    >
      <div className="discount-modal__container">
        <div className="discount-modal__content">
          <h3 className="display-16">{t(`${copyContext}.before_you_go`)}</h3>
          <h2 className="display-28">
            {t(`${copyContext}.discount`, { discountCopy })}
          </h2>
          <DiscountModalForm
            shouldShowLeadCaptureForm={
              shouldSeeBlogDiscountModal && !funnelCompleted
            }
            discountCode={discountCode}
            discountPercentage={discountPercentage}
            funnelCompleted={funnelCompleted}
          />
        </div>
        <Image
          slug="blog-discount-modal-hero-desktop"
          alt={t(`${copyContext}.image_alt`)}
          image={{ height: isMobile ? 140 : 352, width: isMobile ? 343 : 300 }}
          className="discount-modal__image"
        />
      </div>
    </TransitionalModal>
  )
}

export default BlogExitDiscountModal
