/* eslint-disable i18next/no-literal-string */
import { gql } from '@apollo/client'

const BOX_ORDER_FRAGMENT_V3 = gql`
  fragment BoxOrderFragmentV3 on Box {
    order {
      id
      orderProducts {
        id
      }
    }
    descriptor
    amendable
  }
`

export { BOX_ORDER_FRAGMENT_V3 }
