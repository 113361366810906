// @noflow
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { MAX_PUPPY_AGE_IN_MONTHS } from '@/utils/butternutbox/constants/digital_product'

import AdultAvatar from 'assets/images/illustrations/dogs/dog-profile-avatars/single-adult-dog.svg'
import PuppyAvatar from 'assets/images/illustrations/dogs/dog-profile-avatars/single-puppy-dog.svg'

import STYLES from './StaticImage.module.sass'

import type { Static } from '../../DogAvatar'
import { useBreedImage } from '../../hooks/useBreedImage'
import { Sprinkles } from '../Sprinkles'

const StaticImage = ({
  border,
  avatarUrl,
  ageInMonths,
  sprinkles = false,
  breed
}: Static): JSX.Element => {
  const { t } = useTranslation('molecules')
  const breedImage = useBreedImage(breed)

  const image = useMemo((): string => {
    if (avatarUrl) {
      return avatarUrl
    }

    if (breedImage) {
      return breedImage
    }

    return ageInMonths <= MAX_PUPPY_AGE_IN_MONTHS ? PuppyAvatar : AdultAvatar
  }, [avatarUrl, breedImage, ageInMonths])

  return (
    <div
      className={`${STYLES.container}`}
      data-testid="dog-avatar-static-image-wrapper"
    >
      <img
        alt={t('dog_avatar.alt')}
        className={`${STYLES.image} ${border && STYLES.border}`}
        src={image}
      />
      <Sprinkles sprinkles={sprinkles} />
    </div>
  )
}

export { StaticImage }
