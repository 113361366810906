// @noflow
import { useReactiveVar } from '@apollo/client'
import {
  ExpressCheckoutElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js'
import {
  StripeExpressCheckoutElementClickEvent,
  StripeExpressCheckoutElementConfirmEvent,
  StripeExpressCheckoutElementReadyEvent
} from '@stripe/stripe-js'
import i18next from 'i18next'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Product, captureException } from '@/utils/sentry'

import Text from '@/components/elements/atoms/Text/Text'
import { checkoutPageState } from '@/components/pages/CheckoutPage/CheckoutPage'
import * as sectionsHelpers from '@/components/pages/CheckoutPage/helpers/sections'
import type { AccountDetailsFormKey } from '@/components/pages/CheckoutPage/types'

import type { CheckoutPage } from '../../queries/__generated__/CheckoutPage'

import * as ANALYTICS from '../../Analytics/CheckoutAnalytics'
import { checkoutPricingState } from '../../hooks/useCheckoutPricing'
import { bannerMessageState } from '../PaymentSection/Banner'
import buySubscriptionWithStripe from '../PaymentSection/helpers/buySubscriptionWithStripe'
import { stripePaymentMethodToPaymentMethod } from '../PaymentSection/helpers/formatPaymentMethod'

type ExpressCheckoutProps = {
  namespace: string
  shippingCountryCode: CheckoutPage['guest']['assumedShippingCountry']['code']
  csrfToken: string
  isSectionValid: () => boolean
  validateInputField: (fieldName: AccountDetailsFormKey, value: string) => void
}

const ExpressCheckout = ({
  namespace,
  shippingCountryCode,
  csrfToken,
  isSectionValid,
  validateInputField
}: ExpressCheckoutProps): JSX.Element => {
  const { sections, user } = checkoutPageState()
  const { accountDetails, addressDetails, deliveryDetails } = sections
  const { form } = accountDetails
  const { name, password } = form

  const { firstOrderPricing } = useReactiveVar(checkoutPricingState)

  const [expressCheckoutHasMounted, setExpressCheckoutHasMounted] =
    useState(false)

  const stripe = useStripe()
  const elements = useElements()

  const { t } = useTranslation(namespace)
  const copyContext = 'payment_section.payment_request_display_items'

  const hasDiscount = user?.discount?.code

  const handleOnReady = useCallback(
    (event: StripeExpressCheckoutElementReadyEvent) => {
      const { availablePaymentMethods } = event

      if (availablePaymentMethods) {
        setExpressCheckoutHasMounted(true)
      }
    },
    []
  )

  const handleOnClick = useCallback(
    (event: StripeExpressCheckoutElementClickEvent) => {
      const options = {
        phoneNumberRequired: true,
        shippingAddressRequired: true,
        // This is important because by default Stripe allows all countries to
        // be used as a shipping address, whereas we want to limit it to the
        // shipping country they've entered Checkout Page with already
        allowedShippingCountries: [shippingCountryCode],
        shippingRates: [
          {
            id: 'delivery',
            displayName:
              firstOrderPricing.netDeliverySurchargePrice === 0
                ? t(`${copyContext}.free_delivery_with_date`, {
                    date: deliveryDetails.form.selectedDeliveryDate.value
                  })
                : t(`${copyContext}.delivery_fee`),
            amount: firstOrderPricing.netDeliverySurchargePrice
          }
        ],
        lineItems: [
          {
            name: t(`${copyContext}.box_price`),
            amount: firstOrderPricing.netTotalPrice
          }
        ]
      }

      ANALYTICS.expressPayButtonClicked()

      if (!isSectionValid()) {
        // If a user hasn't filled in the required details, we don't want to let
        // them attempt to pay and instead will close the payment request window
        validateInputField('name', name.value)
        validateInputField('password', password.value)
      } else {
        if (hasDiscount && elements) {
          elements.update({ amount: firstOrderPricing.netTotalPrice })
        }
        event.resolve(options)
      }
    },
    [
      deliveryDetails.form.selectedDeliveryDate.value,
      elements,
      firstOrderPricing.netDeliverySurchargePrice,
      firstOrderPricing.netTotalPrice,
      hasDiscount,
      isSectionValid,
      name.value,
      password.value,
      shippingCountryCode,
      t,
      validateInputField
    ]
  )

  const handleOnConfirm = useCallback(
    (event: StripeExpressCheckoutElementConfirmEvent) => {
      if (!stripe || !elements) {
        bannerMessageState({
          message: i18next.t('checkout:errors.delivery_details_fetch'),
          type: 'error'
        })
        return captureException(
          `Express Checkout button enabled without Stripe or Stripe Elements initialised`,
          {
            tags: {
              product: Product.Checkout
            }
          }
        )
      }

      const paymentMethod = stripePaymentMethodToPaymentMethod(
        event.expressPaymentType
      )
      ANALYTICS.submissionAttempt(paymentMethod)

      if (event.billingDetails && event.billingDetails.phone) {
        deliveryDetails.form.mobileNumber.value = event.billingDetails.phone
      }

      if (event.shippingAddress) {
        addressDetails.form.addressLine1.value =
          event.shippingAddress.address.line1
        addressDetails.form.addressLine2.value =
          event.shippingAddress.address.line2 || ''
        addressDetails.form.city.value = event.shippingAddress.address.city
        addressDetails.form.postcode.value =
          event.shippingAddress.address.postal_code
      }

      buySubscriptionWithStripe({ stripe, elements, csrfToken })
    },
    [
      addressDetails.form,
      csrfToken,
      deliveryDetails.form.mobileNumber,
      elements,
      stripe
    ]
  )

  const handleOnCancel = useCallback(() => {
    ANALYTICS.expressPayCancelled()
  }, [])

  return (
    <div
      className={`${sectionsHelpers.sectionClassName(
        'account'
      )}__express-checkout`}
    >
      {expressCheckoutHasMounted && (
        <Text
          namespace={namespace}
          text="account_section.or"
          align="center"
          variant="display16"
        />
      )}
      <div
        className={`${sectionsHelpers.sectionClassName(
          'account'
        )}__express-checkout-button`}
      >
        <ExpressCheckoutElement
          onReady={handleOnReady}
          onClick={handleOnClick}
          onConfirm={handleOnConfirm}
          onCancel={handleOnCancel}
        />
      </div>
    </div>
  )
}

export default ExpressCheckout
