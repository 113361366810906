// @noflow
import * as Sentry from '@sentry/browser'

// Ensure additions/changes to these names are reflected
// in the appropriate .yml files for localisation
type LevelName =
  | 'never_referred'
  | 'new_recruit'
  | 'pawformer'
  | 'hot_dog'
  | 'furcules'

type ReferralLevel = {
  name: LevelName
  threshold: number
  nextLevel: LevelName
}

const ReferralLevels: Array<ReferralLevel> = [
  {
    name: 'never_referred',
    threshold: 0,
    nextLevel: 'new_recruit'
  },
  {
    name: 'new_recruit',
    threshold: 1,
    nextLevel: 'pawformer'
  },
  {
    name: 'pawformer',
    threshold: 3,
    nextLevel: 'hot_dog'
  },
  {
    name: 'hot_dog',
    threshold: 10,
    nextLevel: 'furcules'
  },
  {
    name: 'furcules',
    threshold: 30,
    nextLevel: 'furcules'
  }
]

const referralsToReferralLevel = (referrals: number): ReferralLevel => {
  // For customers who have never referred, ensure they are given the never_referred
  // tier and not the second tier
  if (referrals === 0) {
    return ReferralLevels[0]
  }

  // For customers who have referred more than our maximum level, return the last level
  if (referrals > ReferralLevels[ReferralLevels.length - 1].threshold) {
    return ReferralLevels[ReferralLevels.length - 1]
  }

  // Everyone else should then have an elgible ReferralLevel to hit next
  const currentLevel = ReferralLevels.find(
    (level) => level.threshold > referrals
  )

  if (!currentLevel) {
    Sentry.captureException(`Couldn't genereate RefferalLevel`, {
      extra: { referrals }
    })
    return ReferralLevels[0]
  }

  return currentLevel
}

export { ReferralLevels, referralsToReferralLevel }

export type { ReferralLevel }
