import React, { ReactElement, useCallback } from 'react'
import { useLocation } from 'react-router-dom'

import NavigateAwayModal from '@/components/elements/molecules/NavigateAwayModal/NavigateAwayModal'
import {
  trackReviewNewPlanClicked,
  trackStayOnOldPlanClicked
} from '@/components/pages/PlansPage/helpers/analytics'
import { Route as PlanRoute } from '@/components/pages/PlansPage/types/routes'

type Props = {
  open: boolean
  toggle: (open: boolean) => void
}

const CancelPlanUpdateWarningModal = ({
  open,
  toggle
}: Props): ReactElement => {
  const location = useLocation().pathname as PlanRoute

  const navigateAwayModalCancel = useCallback(() => {
    trackReviewNewPlanClicked(location)
    toggle(false)
  }, [location, toggle])

  const navigateAwayModalConfirm = useCallback(() => {
    trackStayOnOldPlanClicked(location)
    window.location.href = '/dashboard'
  }, [location])

  return (
    <NavigateAwayModal
      namespace="plans_flow"
      open={open}
      toggle={toggle}
      title={'plan_steps.navigation.navigating_away_modal.title'}
      description={{
        text: 'plan_steps.navigation.navigating_away_modal.title'
      }}
      cancelLabel={
        'plan_steps.navigation.navigating_away_modal.new_plan_button'
      }
      confirmLabel={
        'plan_steps.navigation.navigating_away_modal.old_plan_button'
      }
      onCancel={navigateAwayModalCancel}
      onConfirm={navigateAwayModalConfirm}
    />
  )
}

export default CancelPlanUpdateWarningModal
