import { Language } from '@/packs/localisation'
import { Grid } from '@material-ui/core'
import { format, parseISO } from 'date-fns'
import Handlebars from 'handlebars'
import i18next from 'i18next'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { localeToDateLocale } from '@/utils/countryCodeHelper'

import BRAND_COLOURS from '@/constants/BrandColours'

import useWindowSize from '@/hooks/useWindowSize'

import DarkPaws from 'assets/images/illustrations/paws/dark-paws.svg'
import LightPaws from 'assets/images/illustrations/paws/light-paws.svg'

import Card from '@/components/elements/atoms/Card/Card'
import Image from '@/components/elements/atoms/Image/Image'
import Text, { AllowedColours } from '@/components/elements/atoms/Text/Text'
import CopyInputButton from '@/components/elements/molecules/CopyInputButton/CopyInputButton'
import CountdownBanner from '@/components/elements/molecules/CountdownBanner/CountdownBanner'
import ConfirmationModal, {
  modalData
} from '@/components/elements/organisms/ConfirmationModal/ConfirmationModal'
import EmailShare from '@/components/elements/organisms/EmailShare/EmailShare'

import STYLES from './RafCampaign.module.sass'

import { Code as CountryCode } from '@/types'

import { BREAKPOINTS } from '../Base'

type Props = {
  heroImageSlug: string
  heroImageAlt: string
  heading: string
  description: string
  referralCode: string
  copyLinkMessage: string
  ctaText: string
  ctaVariant?: 'primary' | 'secondary'
  infoText: string
  termsAndConditionsUrl: string
  sticker?: string
  stickerAlt?: string
  backgroundColour?: keyof typeof STYLES
  expiryDate?: string
  countdownText?: string
  countdownBackgroundColour?: keyof typeof BRAND_COLOURS
  countdownTextColour?: AllowedColours
  namespace: string
  userId: string
  textColour?: AllowedColours
  maxEmailInputs?: number
  enableConfetti?: boolean
  bannerColour?: keyof typeof STYLES
  addEmailInputColour?: 'brandBlue500' | 'brandWhite'
  preferredLanguage: Language
  shippingCountryCode: CountryCode
  customerDiscountCopy: string[] | undefined
  referralDiscountCopy: string[]
}

const RafCampaign = ({
  heroImageSlug,
  heroImageAlt,
  heading,
  description,
  referralCode,
  copyLinkMessage,
  ctaText,
  ctaVariant = 'primary',
  infoText,
  termsAndConditionsUrl,
  sticker,
  stickerAlt = '',
  backgroundColour = 'brandWhite',
  textColour = 'brandBlue500',
  expiryDate,
  countdownText,
  countdownBackgroundColour,
  countdownTextColour,
  namespace,
  userId,
  maxEmailInputs = 5,
  enableConfetti = false,
  bannerColour = 'brandBlue400',
  addEmailInputColour = 'brandBlue500',
  preferredLanguage,
  shippingCountryCode,
  customerDiscountCopy,
  referralDiscountCopy
}: Props): JSX.Element => {
  const [, setThrowConfetti] = useState(false)

  const { t } = useTranslation(namespace)

  const dateLocale = localeToDateLocale(
    shippingCountryCode,
    i18next.language as Language
  )

  const { windowWidth } = useWindowSize()
  const isMobile = windowWidth < BREAKPOINTS.md

  const compiledDescription = Handlebars.compile(description)({
    // Keeping unused values for future use
    referrerDiscountFirstBox: customerDiscountCopy?.[0] ?? 'X%',
    referralDiscountFirstBox: referralDiscountCopy?.[0] ?? 'X%',
    referralDiscountSecondBox: referralDiscountCopy?.[1] ?? 'X%'
  })

  const compiledInfoText = Handlebars.compile(infoText)({
    termsUrl: termsAndConditionsUrl,
    expiryDate: expiryDate
      ? format(parseISO(expiryDate), 'dd/MM/yyyy', { locale: dateLocale })
      : ''
  })

  const getPaws = useCallback(() => {
    switch (bannerColour) {
      case 'brandYellow300':
      case 'brandBlue400':
        return DarkPaws
      default:
        return LightPaws
    }
  }, [bannerColour])

  const throwConfettiCallback = useCallback(
    (showModal: boolean): void => {
      setThrowConfetti(true)
      setTimeout((): void => {
        setThrowConfetti(false)
        showModal &&
          modalData({
            isOpen: true,
            namespace,
            text: 'refer_a_friend.campaign.notification',
            delay: 3000
          })
      }, 3000)
    },
    [namespace]
  )

  const onEmailSuccessHandler = useCallback(() => {
    if (enableConfetti) {
      throwConfettiCallback(true)
    } else {
      modalData({
        isOpen: true,
        namespace,
        text: 'refer_a_friend.campaign.notification',
        delay: 3000
      })
    }
  }, [enableConfetti, namespace, throwConfettiCallback])

  const onCopySuccessHandler = useCallback(() => {
    if (enableConfetti) {
      throwConfettiCallback(false)
    }
  }, [enableConfetti, throwConfettiCallback])

  return (
    <div>
      {expiryDate && countdownText && (
        <div className={STYLES.countdownContainer}>
          <Card
            mask
            padding={0}
            className={STYLES.countdownBanner}
            squared={isMobile}
          >
            <CountdownBanner
              bannerText={{
                text: countdownText,
                variant: 'display16',
                colour: countdownTextColour,
                align: 'left',
                margin: false
              }}
              endOfLeadTime={expiryDate}
              namespace={namespace}
              countdownType="weeklyCountdown"
              countdownTextColour={countdownTextColour}
              countdownBackgroundColour={countdownBackgroundColour}
              showEndDate
              preferredLanguage={preferredLanguage}
              shippingCountryCode={shippingCountryCode}
            />
          </Card>
        </div>
      )}
      <Grid
        container
        spacing={2}
        className={`
          ${STYLES.wrapper}
          ${expiryDate ? STYLES.bannerHeight : ''}
        `}
      >
        <Grid item xs={12} md={6} className={STYLES.heroContainer}>
          <Card
            mask
            padding={0}
            fill={false}
            className={`${STYLES.heroImageCard} ${STYLES[bannerColour]}`}
          >
            <div className={STYLES.imageContainer}>
              <Image
                slug={heroImageSlug}
                alt={heroImageAlt}
                image={{
                  width: 500,
                  height: 500
                }}
                className={STYLES.heroImage}
              />
            </div>
            {sticker && (
              <Image
                slug={sticker}
                alt={stickerAlt}
                image={{
                  width: 100,
                  height: 100
                }}
                className={STYLES.mobileSticker}
              />
            )}
            <div className={STYLES.imageBanner}>
              {sticker && (
                <Image
                  slug={sticker}
                  alt={stickerAlt}
                  image={{
                    width: 130,
                    height: 130
                  }}
                  className={STYLES.sticker}
                />
              )}
              <img
                className={STYLES.paws}
                src={getPaws()}
                alt={t(`refer_a_friend.campaign.paws`)}
              />
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} className={STYLES.information}>
          <Card
            fill={false}
            className={`${STYLES.informationCard} ${STYLES[backgroundColour]}`}
            padding={0}
            mask
          >
            <div className={STYLES.informationWrapper}>
              <Text
                element="h2"
                text={heading}
                variant="display28"
                colour={textColour}
                align="left"
                margin={false}
                translate={false}
              />
              <Text
                element="p"
                text={compiledDescription}
                variant="textRegular16"
                align="left"
                colour={textColour}
                margin={false}
                translate={false}
              />
              <div className={STYLES.ctaWrapper}>
                <CopyInputButton
                  namespace={namespace}
                  referralCode={referralCode}
                  copyLinkMessage={copyLinkMessage}
                  partialLocation="Raf Campaign"
                  onSuccess={onCopySuccessHandler}
                />
                <EmailShare
                  userID={userId}
                  partialLocation="Raf Campaign"
                  ctaText={ctaText}
                  variant={ctaVariant}
                  fullWidthCta
                  maxEmailInputs={maxEmailInputs}
                  translate={false}
                  textColour={textColour}
                  onSuccess={onEmailSuccessHandler}
                  addEmailInputColour={addEmailInputColour}
                />
              </div>
              <Text
                element="p"
                text={compiledInfoText}
                variant="textRegular16"
                align="left"
                colour={textColour}
                translate={false}
                margin={false}
              />
            </div>
          </Card>
        </Grid>
      </Grid>
      <ConfirmationModal />
    </div>
  )
}

export { Props }
export default RafCampaign
