import { useReactiveVar } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import { useStripe } from '@stripe/react-stripe-js'
import i18next from 'i18next'
import React from 'react'

import { bannerMessageState } from '../../../components/Banner/Banner'
import { totalAmount } from '../../../components/DsAppOrderSummary/DsAppOrderSummary'

import { State } from '../../../PayOnOwnDevicePage'
import {
  ActivePaymentViews,
  LoginUrlProps,
  setSubmissionState
} from '../../../screens/PaymentScreen/PaymentScreen'
import buySubscriptionWithSepaDirectDebit from './buySubscriptionWithSepaDirectDebit'
import chargeBoxOneBancontact from './chargeBoxOneBancontact'

type UseBuySubscriptionWithBancontact = {
  bancontactSubscriptionCallback: () => void
  bancontactAuthRecieved: boolean
}

const useBuySubscriptionWithBancontact = ({
  paymentPageState,
  setActivePaymentView,
  navigateToNextStep
}: {
  paymentPageState: State
  setActivePaymentView: (view: ActivePaymentViews) => void
  navigateToNextStep: (input: LoginUrlProps) => void
}): UseBuySubscriptionWithBancontact => {
  const stripe = useStripe()
  const { pendingSubscription } =
    paymentPageState.data.directSalesPendingSubscription
  const copyContext =
    'plan_steps.payment.payment_options.payment_request_display_items'

  const { amount } = useReactiveVar(totalAmount)
  const [bancontactAuthRecieved, setBancontactAuthRecieved] =
    React.useState(false)

  const bancontactSelected =
    window.localStorage.getItem('paymentMethod') === 'bancontact'

  const bancontactSubscriptionCallback = React.useCallback(async () => {
    if (!stripe)
      throw new Error(
        'Cannot find stripe when buySubscritionWithSepaDirectDebit is called'
      )

    setSubmissionState({
      type: 'loading'
    })

    await chargeBoxOneBancontact({
      paymentPageState,
      stripe,
      fullName: `${pendingSubscription?.user?.firstName} ${pendingSubscription?.user?.lastName}`,
      email: pendingSubscription?.user?.email || '',
      amount: amount,
      setActivePaymentView
    }).then(() => {
      window.localStorage.setItem('paymentMethod', 'bancontact')
    })
  }, [
    stripe,
    pendingSubscription,
    paymentPageState,
    setActivePaymentView,
    amount
  ])

  // Upon being redirected back to Checkout after an authorisation attempt to
  // create the subscription or alert the customer that authentication has failed
  React.useEffect(() => {
    if (
      window.location.search.includes('redirect_status') &&
      stripe &&
      bancontactSelected &&
      paymentPageState
    ) {
      if (window.location.search.includes('redirect_status=succeeded')) {
        const urlParams = new URLSearchParams(window.location.search)
        const paymentIntentSecret = urlParams.get(
          'payment_intent_client_secret'
        )
        const setupIntentSecret = urlParams.get('setup_intent_client_secret')

        const intentSecret = paymentIntentSecret || setupIntentSecret

        if (intentSecret) {
          setBancontactAuthRecieved(true)
          setSubmissionState({ type: 'loading' })
          buySubscriptionWithSepaDirectDebit({
            intentSecret: intentSecret,
            paymentPageState,
            navigateToNextStep
          })
        } else {
          Sentry.captureException(
            'Bancontact payment failed, intentSecret is undefined'
          )
          setSubmissionState({
            type: 'error',
            error: i18next.t(copyContext)
          })
          window.history.replaceState(
            {},
            document.title,
            location.href.split('?')[0]
          )
        }
      } else {
        const urlParams = new URLSearchParams(window.location.search)
        const status = urlParams.get('redirect_status')
        Sentry.captureException(`Bancontact Auth Redirect Status = ${status}`)
        setBancontactAuthRecieved(false)

        // Remove URL params after failed authentication
        window.history.replaceState(
          {},
          document.title,
          location.href.split('?')[0]
        )

        bannerMessageState({
          message: i18next.t(copyContext)
        })

        setSubmissionState({
          type: 'error',
          error: i18next.t(copyContext)
        })
      }
    }
  }, [bancontactSelected, navigateToNextStep, paymentPageState, stripe])

  return {
    bancontactSubscriptionCallback,
    bancontactAuthRecieved
  }
}

export default useBuySubscriptionWithBancontact
