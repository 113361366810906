import { gql } from '@apollo/client'

const SUBSCRIPTION_PLAN_UPDATE_MUTATION = gql`
  mutation SubscriptionPlanUpdate($userId: ID!, $planId: ID!) {
    subscriptionPlanUpdate(userId: $userId, planId: $planId) {
      id
      subscription {
        plan {
          id
        }
      }
    }
  }
`

export { SUBSCRIPTION_PLAN_UPDATE_MUTATION }
