import { gql } from '@apollo/client'

const SUBSCRIPTION_RESUME_MUTATION = gql`
  mutation SubscriptionResumeMutation {
    subscriptionResume {
      id
      subscription {
        id
        status
        increasedDeliveryCadencePlan {
          id
        }
      }
    }
    subscriptionResumedEventCreate(userId: null, properties: null) {
      id
    }
    subscriptionResumedWorkflowCreate(userId: null, properties: null) {
      id
    }
  }
`

export { SUBSCRIPTION_RESUME_MUTATION }
