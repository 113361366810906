import { ButternutBoostContext } from '@/context/butternutBoost/butternutBoost'
import { useContext } from 'react'

import { MembershipTypes } from '@/types'

type ButternutBoost = {
  boostedSubscription: boolean
  setBoostedSubscription: (data: boolean) => void
  boostedType: MembershipTypes | null
  setBoostedType: (data: MembershipTypes) => void
  // BOOST-TODO => add undeliveredMembershipBoxesRemaining, boxAllowance...
}

/**
 * This hook returns a boolean value to show/hide the Buttetnut Boost XP.
 */
function useButternutBoost(): ButternutBoost {
  const {
    boostedSubscription,
    setBoostedSubscription,
    boostedType,
    setBoostedType
  } = useContext(ButternutBoostContext)

  return {
    boostedSubscription,
    setBoostedSubscription,
    boostedType,
    setBoostedType
  }
}

export default useButternutBoost
