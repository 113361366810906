// @noflow

/* eslint-disable i18next/no-literal-string */
import { userHasActivePaymentMethod } from '@/context/accessManagement/accessManagement'
import i18n from 'i18next'
import isUndefined from 'lodash/isUndefined'
import React from 'react'
import { Params, RouteObject, redirect } from 'react-router-dom'

import { DogInfo } from '@/services/apollo'

import { getOrderDescriptor } from '@/utils/orderHelper'

import { AddDogAction } from '@/components/elements/atoms/AddDogAction'
import RuffActually from '@/components/elements/atoms/RuffActually/RuffActually'
import AccountNavigation from '@/components/elements/molecules/AccountNavigation/AccountNavigation'
import { HeaderProps } from '@/components/elements/molecules/Header'
import PlanDetails from '@/components/elements/molecules/PlanDetails/PlanDetails'
import { ClientInitQuery_user_subscription as Subscription } from '@/components/pages/App/queries/__generated__/ClientInitQuery'
import ChangeDatePage from '@/components/pages/ChangeDatePage/ChangeDatePage'
import ChangeDatePageV2 from '@/components/pages/ChangeDatePageV2/ChangeDatePageV2'
import ConfirmPlanPage from '@/components/pages/ConfirmPlanPage/ConfirmPlanPage'
import ContactPreferencesPage from '@/components/pages/ContactPreferencesPage/ContactPreferencesPage'
import DashboardPage from '@/components/pages/Dashboard/Dashboard'
import HeroSection from '@/components/pages/Dashboard/components/HeroSection/HeroSection'
import DashboardPageV3 from '@/components/pages/DashboardV3/DashboardV3'
import EditDeliveryAddress from '@/components/pages/DashboardV3/components/EditDeliveryAddress/EditDeliveryAddress'
import DeliveryFrequencyPage from '@/components/pages/DeliveryFrequencyPage/DeliveryFrequencyPage'
import DeliveryFrequencyPageV3 from '@/components/pages/DeliveryFrequencyPageV3/DeliveryFrequencyPageV3'
import { DogProfile } from '@/components/pages/DogProfile'
import { DogProfileV2 } from '@/components/pages/DogProfile/v2'
import DownsizeAndSavePage from '@/components/pages/DownsizeAndSavePage/DownsizeAndSavePage'
import EditDogDetailsPage from '@/components/pages/EditDogDetailsPage/EditDogDetailsPage'
import { EditExtrasPage } from '@/components/pages/EditExtrasPage'
import { EditRecipes } from '@/components/pages/EditRecipes'
import BasketButton from '@/components/pages/ExtrasPage/components/BasketButton/BasketButton'
import BasketPage from '@/components/pages/ExtrasPage/screens/BasketPage/BasketPage'
import ConfirmationPage from '@/components/pages/ExtrasPage/screens/ConfirmationPage/ConfirmationPage'
import ExtrasList from '@/components/pages/ExtrasPage/screens/ExtrasList/ExtrasList'
import ProductCollection from '@/components/pages/ExtrasPage/screens/ProductCollection/ProductCollection'
import MFITBPlanOffer from '@/components/pages/MFITBPlanOffer/MFITBPlanOffer'
import MealtimeChecklist from '@/components/pages/MealtimeChecklist/MealtimeChecklist'
import MyAccountPage from '@/components/pages/MyAccountPage/MyAccountPage'
import PaymentMethodsPage from '@/components/pages/MyDetailsPage/screens/PaymentMethods/PaymentMethodsPage'
import AddPaymentMethodPage from '@/components/pages/MyDetailsPage/screens/PaymentMethods/components/AddPaymentMethod/AddPaymentMethodPage'
import NoDogsHero from '@/components/pages/NoDogsPage/components/NoDogsHero/NoDogsHero'
import NoDogsStateContent from '@/components/pages/NoDogsPage/components/NoDogsStateContent/NoDogsStateContent'
import { ConfirmationPage as OneOffBoxConfirmationPage } from '@/components/pages/OneOffBoxPage/screens/ConfirmationPage/ConfirmationPage'
import OneOffBox from '@/components/pages/OneOffBoxPage/screens/OneOffBox/OneOffBox'
import OrderPage from '@/components/pages/OrderPage/OrderPage'
import OrdersPage from '@/components/pages/OrdersPage/OrdersPage'
import PersonalDetails from '@/components/pages/PersonalDetailsPage/PersonalDetails'
import PlanManagementPage from '@/components/pages/PlanManagementPage/PlanManagementPage'
import PlanManagementPageV3 from '@/components/pages/PlanManagementPageV3/PlanManagementPageV3'
import ReferAFriendPage from '@/components/pages/ReferAFriendPage/ReferAFriendPage'
import RestartPlanSuccess from '@/components/pages/RestartPlanSuccess/RestartPlanSuccess'
import ServingSizePage from '@/components/pages/ServingSizePage/ServingSizePage'
import ServingSizePageV3 from '@/components/pages/ServingSizePageV3/ServingSizePageV3'
import SwapAndSavePage from '@/components/pages/SwapAndSavePage/SwapAndSavePage'
import SwitchAndSavePage from '@/components/pages/SwitchAndSavePage/SwitchAndSavePage'
import TreatmentsPausedPage from '@/components/pages/TreatmentsPausedPage/TreatmentsPausedPage'
import DogsHero from '@/components/pages/TreatmentsPausedPage/components/DogsHero/DogsHero'
import { UpdateDogAboutInfo } from '@/components/pages/UpdateDogAboutInfo'
import { UpdateDogAboutInfoV2 } from '@/components/pages/UpdateDogAboutInfo/v2'
import { UpdateDogBasicInfo } from '@/components/pages/UpdateDogBasicInfo'
import { UpdateDogEatingHabits } from '@/components/pages/UpdateDogEatingHabits'
import { UpdateDogMedicalInfo } from '@/components/pages/UpdateDogMedicalInfo'
import { UpdateDogMedicalInfoV2 } from '@/components/pages/UpdateDogMedicalInfo/v2'
import { UpdateDogPersonality } from '@/components/pages/UpdateDogPersonality'
import { UpdateDogWeightAndAppetite } from '@/components/pages/UpdateDogWeightAndAppetite'
import UpdatePlanPage from '@/components/pages/UpdatePlanPage/UpdatePlanPage'
import UpdatePlanPageV3 from '@/components/pages/UpdatePlanPageV3/UpdatePlanPageV3'
import type { ScreenProps } from '@/components/templates/Screen'
import { ScreenBackgroundColor } from '@/components/templates/Screen/Screen'

import { Language, MembershipTypes } from '@/types'

import { ACCOUNT_ROUTES } from './routes'

type Handle = Omit<ScreenProps, 'children'> & {
  header: HeaderProps
}

type AccountRouteObject = RouteObject & {
  handle: undefined | Handle | ((params: Params<string>) => Handle)
}

const namespace = 'account'

const Secondary = ({ header, ...rest }: Handle): Handle => {
  const { title: handleTitle } = header

  return {
    hideNavigationTabBar: true,
    hideFooter: true,
    childrenMaxWidth: 'md',
    ...rest,
    header: {
      variant: 'secondary',
      rightAdornment: null,
      ...header,
      title: {
        namespace,
        translate: true,
        ...handleTitle,
        ...header.title
      }
    }
  }
}

const Tertiary = (handle: Handle): Handle => ({
  hideNavigationTabBar: true,
  hideFooter: true,
  backgroundColor: ScreenBackgroundColor.brandYellow300,
  ...handle,
  header: {
    variant: 'tertiary',
    // eslint-disable-next-line react/destructuring-assignment
    ...handle?.header,
    rightAdornment: null,
    // eslint-disable-next-line react/destructuring-assignment
    title: { namespace, translate: true, ...handle.header.title }
  }
})

const createAccountRouteObject = (
  dogData: DogInfo | null,
  subscriptionData: Subscription | null,
  featureFlagData: Record<string, string | null> | null,
  isAdmin: boolean,
  isAffiliateWithFreeBox: boolean
): Array<AccountRouteObject> => {
  const { dogs, pronoun, has_fussy_eater } = dogData || {}
  const dogsAmount = dogs?.length ?? 0
  const noDogs = dogsAmount === 0
  const subscription = subscriptionData
  const { activeBoostedMembership } = subscription || {}
  const boostMembershipType =
    activeBoostedMembership?.membershipType === MembershipTypes.boost
  const hasActivePaymentMethod = userHasActivePaymentMethod(
    subscription?.paymentMethods
  )

  const shouldSeePlanManagementPhase3 =
    featureFlagData?.shouldSeePlanManagementPhase3 === 'Variant'

  const shouldSeeDogProfileV2 =
    featureFlagData?.shouldSeeDogProfileV2 === 'true'

  const shouldSeeUpdatedDeliveryCalendarUI =
    featureFlagData?.shouldSeeUpdatedDeliveryCalendarUI === 'true'

  const shouldSeeXmas = true

  const dashboardPage = () => {
    switch (true) {
      case shouldSeePlanManagementPhase3: {
        return <DashboardPageV3 />
      }
      default: {
        return <DashboardPage />
      }
    }
  }

  return [
    {
      path: ACCOUNT_ROUTES.base,
      handle: {
        header: {
          rightAdornment: [<AccountNavigation key="account-nav" />]
        },
        // Hide nav as users without a payment method
        // only one see one link
        // the exception is where the user is an affiliate. They are gifted free boxes, so in this situation,
        // we should enable them to use navigation bar to edit dogs and extras (up to 4)
        hideNavigationTabBar: !hasActivePaymentMethod && !isAffiliateWithFreeBox
      },
      children: [
        {
          index: true,
          handle: {
            showBannerManager: true,
            header: {
              forceShowLogo: !!activeBoostedMembership && boostMembershipType,
              showBoostLogo: !!activeBoostedMembership && boostMembershipType,
              title:
                activeBoostedMembership && boostMembershipType
                  ? false
                  : { text: 'titles.home', namespace }
            },
            documentTitleKey: 'home',
            highlightedSection: {
              children: <HeroSection />
            },
            scrollMode: 'pathname',
            showDixa: true
          },
          loader: () => {
            if (subscription?.status === 'paused') {
              return redirect(ACCOUNT_ROUTES.paused)
            }

            // NOTE: Can be removed once the single page app is rolled out to 100%.
            //       Re-direct the user to the edit recipe section which was previously
            //       located on the account landing page.
            const queryParams = new URLSearchParams(window.location.search)

            if (queryParams.get('openModal') === 'meal-selection') {
              // strip the query params out of the history object, otherwise on navigating back the router
              // will perpetually loop the user back to the editRecipes page
              history.replaceState(null, '', window.location.pathname)

              return redirect(ACCOUNT_ROUTES.editRecipes)
            }
            return null
          },
          element: dashboardPage()
        },
        {
          path: ACCOUNT_ROUTES.paused,
          handle: {
            header: {
              title: { text: 'titles.paused', namespace }
            },
            documentTitleKey: 'paused',
            showDixa: true,
            highlightedSection: {
              children: <DogsHero />
            }
          },
          element: <TreatmentsPausedPage />
        },
        {
          path: ACCOUNT_ROUTES.noDogs,
          loader: () => {
            return redirect(ACCOUNT_ROUTES.profile)
          },
          handle: {
            showDixa: true
          }
        },
        {
          path: ACCOUNT_ROUTES.orders,
          handle: {
            header: {
              title: { text: 'titles.orders', namespace: 'account' }
            },
            documentTitleKey: 'orders'
          },
          element: <OrdersPage variant="en" />
        },
        {
          path: ACCOUNT_ROUTES.box,
          handle: (params: Params<string>) => {
            return {
              header: {
                title: {
                  text: !isUndefined(params?.boxId)
                    ? `titles.order.${getOrderDescriptor(
                        params?.boxId,
                        subscription?.nextNBoxes
                      )}`
                    : '',
                  namespace
                }
              },
              highlightedSection: {
                children: <PlanDetails />
              },
              hideFooter: true,
              documentTitleKey: 'order',
              childrenMaxWidth: 'md'
            }
          },
          element: <OrderPage />
        },
        {
          path: ACCOUNT_ROUTES.onDemandExtras,
          handle: {
            ...Secondary({
              header: {
                title: { text: 'titles.order.ode', namespace }
              },
              documentTitleKey: 'order'
            }),
            showDixa: true
          },
          element: <ConfirmationPage />
        },
        {
          /* eslint-disable-next-line i18next/no-literal-string */
          path: ACCOUNT_ROUTES.changeDate + `/:boxId`,
          handle: {
            ...Secondary({
              header: {
                title: { text: 'change_date.title', namespace }
              },
              documentTitleKey: 'change_date',
              hidePadding: true
            }),
            showDixa: true
          },
          element: shouldSeeUpdatedDeliveryCalendarUI ? (
            <ChangeDatePageV2 />
          ) : (
            <ChangeDatePage />
          )
        },
        {
          path: ACCOUNT_ROUTES.planManagement,
          handle: {
            ...Secondary({
              header: {
                title: {
                  text: shouldSeePlanManagementPhase3
                    ? 'plan_management.title_v2'
                    : 'plan_management.title',
                  namespace,
                  align: 'center'
                },
                subtitle: shouldSeePlanManagementPhase3
                  ? {
                      text: 'plan_management.description',
                      namespace,
                      variables: {
                        dogName: noDogs ? '' : dogData?.dogs[0].name,
                        count: dogData?.dogs?.length || 0
                      },
                      align: 'center'
                    }
                  : undefined
              },
              documentTitleKey: 'plan_management'
            })
          },
          element: shouldSeePlanManagementPhase3 ? (
            <PlanManagementPageV3 />
          ) : (
            <PlanManagementPage />
          )
        },
        {
          path: ACCOUNT_ROUTES.editDogDetails + '/:dogId?',
          handle: {
            ...Tertiary({
              header: {
                title: {
                  text: 'change_dog_details.title',
                  variables: {
                    context: pronoun
                  }
                },
                leftAdornment: {
                  defaultBackRoute: ACCOUNT_ROUTES.profile
                }
              },
              documentTitleKey: 'change_dog_details'
            }),
            showDixa: true
          },
          element: <EditDogDetailsPage />
        },
        {
          path: ACCOUNT_ROUTES.deliveryFrequency + '/:planId?',
          handle: shouldSeePlanManagementPhase3
            ? {
                ...Secondary({
                  header: {
                    title: { text: 'edit_delivery_frequency.title' },
                    leftAdornment: {
                      defaultBackRoute: ACCOUNT_ROUTES.planManagement
                    }
                  },
                  documentTitleKey: 'edit_delivery_frequency',
                  hidePadding: true
                })
              }
            : {
                ...Tertiary({
                  header: {
                    title: {
                      text: 'delivery_frequency.title',
                      variables: {
                        context: pronoun
                      }
                    }
                  },
                  documentTitleKey: 'change_dog_details'
                })
              },
          element: shouldSeePlanManagementPhase3 ? (
            <DeliveryFrequencyPageV3 />
          ) : (
            <DeliveryFrequencyPage />
          )
        },
        {
          path: ACCOUNT_ROUTES.servingSize + '/:planId?',
          handle: shouldSeePlanManagementPhase3
            ? {
                ...Secondary({
                  header: {
                    title: {
                      text: 'serving_size_v2.title'
                    },
                    leftAdornment: {
                      defaultBackRoute: ACCOUNT_ROUTES.planManagement
                    }
                  },
                  documentTitleKey: 'serving_size_v2',
                  hidePadding: true
                })
              }
            : {
                ...Tertiary({
                  header: {
                    title: {
                      text: 'serving_size.title'
                    }
                  },
                  documentTitleKey: 'serving_size'
                })
              },
          element: shouldSeePlanManagementPhase3 ? (
            <ServingSizePageV3 />
          ) : (
            <ServingSizePage />
          )
        },
        {
          path: ACCOUNT_ROUTES.updatePlan + '/:planId?',
          handle: shouldSeePlanManagementPhase3
            ? {
                ...Secondary({
                  header: {
                    title: {
                      text: 'plan_review_v2.title',
                      variables: {
                        context: pronoun
                      }
                    },
                    leftAdornment: {
                      defaultBackRoute: ACCOUNT_ROUTES.planManagement
                    }
                  },
                  documentTitleKey: 'plan_review'
                })
              }
            : {
                ...Tertiary({
                  header: {
                    title: {
                      text: 'plan_review.new.title',
                      variables: {
                        context: pronoun
                      }
                    }
                  },
                  documentTitleKey: 'plan_review'
                })
              },
          element: shouldSeePlanManagementPhase3 ? (
            <UpdatePlanPageV3 />
          ) : (
            <UpdatePlanPage />
          )
        },
        {
          path: ACCOUNT_ROUTES.updatePlan + '/:reviewType/:planId',
          handle: {
            ...Secondary({
              header: {
                title: {
                  text: 'plan_review_v2.title',
                  variables: {
                    context: pronoun
                  }
                },
                leftAdornment: {
                  defaultBackRoute: ACCOUNT_ROUTES.planManagement
                }
              },
              documentTitleKey: 'plan_review'
            })
          },
          element: <UpdatePlanPageV3 />
        },
        {
          path: ACCOUNT_ROUTES.mealtimeChecklist,
          handle: {
            ...Tertiary({
              header: {
                title: {
                  text: 'mealtime_checklist.title',
                  align: 'center',
                  variant: 'display28',
                  namespace
                },
                subtitle: {
                  text:
                    dogData && has_fussy_eater
                      ? 'mealtime_checklist.subtitle_fussy'
                      : 'mealtime_checklist.subtitle',
                  namespace,
                  variant: 'textRegular16',
                  align: 'center'
                }
              },
              documentTitleKey: 'mealtime_checklist'
            }),
            childrenMaxWidth: 'md'
          },
          element: <MealtimeChecklist />
        },
        {
          path: ACCOUNT_ROUTES.planConfirmation + '/:previousPlanId?',
          handle: {
            ...Tertiary({
              header: {
                title: {
                  text: '',
                  translate: false
                }
              },
              documentTitleKey: 'plan_confirmation'
            })
          },
          element: <ConfirmPlanPage />
        },
        {
          path: ACCOUNT_ROUTES.profile,
          handle: {
            header: {
              title: {
                text: noDogs
                  ? 'Paused'
                  : dogsAmount > 1
                  ? 'titles.my_dogs'
                  : 'titles.my_dog',
                namespace
              },
              rightAdornment:
                dogsAmount === 1 ? (
                  <AddDogAction
                    bgColour={shouldSeeXmas ? 'brandBlue100' : 'brandYellow200'}
                  />
                ) : undefined
            },
            showSubHeader: dogsAmount > 1,
            documentTitleKey: noDogs ? 'paused' : 'dog_profile',
            hideNavigationTabBar: noDogs,
            fullWidth: noDogs,
            scrollMode: 'pathname',
            highlightedSection: {
              children: noDogs ? <NoDogsHero /> : null
            },
            showDixa: true
          },
          loader: () => {
            if (!dogData) return null
            if (dogData && noDogs) return redirect(ACCOUNT_ROUTES.profile)
            return redirect(`${ACCOUNT_ROUTES.profile}/${dogs?.[0].id}`)
          },
          element: noDogs ? (
            <NoDogsStateContent />
          ) : shouldSeeDogProfileV2 ? (
            <DogProfileV2 />
          ) : (
            <DogProfile />
          )
        },
        {
          path: `${ACCOUNT_ROUTES.profile}/:dogId`,
          handle: {
            header: {
              title: {
                text: dogsAmount > 1 ? 'titles.my_dogs' : 'titles.my_dog',
                namespace
              },
              rightAdornment:
                dogsAmount === 1 ? (
                  <AddDogAction
                    bgColour={shouldSeeXmas ? 'brandBlue100' : 'brandYellow200'}
                  />
                ) : undefined
            },
            showSubHeader: dogsAmount > 1,
            documentTitleKey: 'dog_profile',
            scrollMode: 'pathname',
            showDixa: true
          },
          element: shouldSeeDogProfileV2 ? <DogProfileV2 /> : <DogProfile />
        },
        {
          path: `${ACCOUNT_ROUTES.profile}/:dogId/about`,
          handle: {
            ...(shouldSeeDogProfileV2 ? Secondary : Tertiary)({
              header: {
                title: {
                  text: shouldSeeDogProfileV2
                    ? 'titles.update_dog_basic_information'
                    : 'titles.update_dog_about_info'
                },
                leftAdornment: {
                  defaultBackRoute: ACCOUNT_ROUTES.profile
                }
              },
              documentTitleKey: 'update_dog_about_info'
            }),
            showDixa: true,
            fillScreen: shouldSeeDogProfileV2
          },
          element: shouldSeeDogProfileV2 ? (
            <UpdateDogAboutInfoV2 />
          ) : (
            <UpdateDogAboutInfo />
          )
        },
        {
          path: `${ACCOUNT_ROUTES.profile}/:dogId/personality`,
          handle: {
            ...Tertiary({
              header: {
                title: {
                  text: 'titles.update_dog_personality'
                },
                leftAdornment: {
                  defaultBackRoute: ACCOUNT_ROUTES.profile
                }
              },
              documentTitleKey: 'update_dog_personality'
            }),
            showDixa: true
          },
          element: <UpdateDogPersonality />
        },
        {
          path: `${ACCOUNT_ROUTES.profile}/:dogId/basic-info`,
          handle: {
            ...Tertiary({
              header: {
                title: {
                  text: 'titles.update_dog_basic_info'
                },
                leftAdornment: {
                  defaultBackRoute: ACCOUNT_ROUTES.profile
                }
              },
              documentTitleKey: 'update_dog_basic_info'
            }),
            showDixa: true
          },
          element: <UpdateDogBasicInfo />
        },
        ...(shouldSeeDogProfileV2
          ? [
              {
                path: `${ACCOUNT_ROUTES.profile}/:dogId/weight-and-appetite`,
                handle: {
                  ...Secondary({
                    header: {
                      title: {
                        text: 'titles.update_dog_weight_and_appetite'
                      },
                      leftAdornment: {
                        defaultBackRoute: ACCOUNT_ROUTES.profile
                      }
                    },
                    documentTitleKey: 'update_dog_weight_and_appetite'
                  }),
                  showDixa: true,
                  fillScreen: true
                },
                element: <UpdateDogWeightAndAppetite />
              }
            ]
          : []),
        {
          path: `${ACCOUNT_ROUTES.profile}/:dogId/medical-info`,
          handle: {
            ...(shouldSeeDogProfileV2 ? Secondary : Tertiary)({
              header: {
                title: {
                  text: shouldSeeDogProfileV2
                    ? 'titles.update_dog_medical_info_v2'
                    : 'titles.update_dog_medical_info'
                },
                leftAdornment: {
                  defaultBackRoute: ACCOUNT_ROUTES.profile
                }
              },
              documentTitleKey: 'update_dog_medical_info'
            }),
            showDixa: true,
            fillScreen: shouldSeeDogProfileV2
          },
          element: shouldSeeDogProfileV2 ? (
            <UpdateDogMedicalInfoV2 />
          ) : (
            <UpdateDogMedicalInfo />
          )
        },
        {
          path: `${ACCOUNT_ROUTES.profile}/:dogId/eating-habits`,
          handle: {
            ...Tertiary({
              header: {
                title: {
                  text: 'titles.update_dog_eating_habits'
                },
                leftAdornment: {
                  defaultBackRoute: ACCOUNT_ROUTES.profile
                }
              },
              documentTitleKey: 'update_dog_eating_habits'
            }),
            showDixa: true
          },
          element: <UpdateDogEatingHabits />
        },
        {
          path: ACCOUNT_ROUTES.extras,
          handle: {
            header: {
              title: { text: 'titles.extras', namespace },
              rightAdornment: <BasketButton />
            },
            documentTitleKey: 'extras',
            scrollMode: 'pathname'
          },
          children: [
            {
              index: true,
              path: ACCOUNT_ROUTES.extras,
              handle: {
                header: {
                  title: { text: 'titles.extras', namespace }
                },
                documentTitleKey: 'extras',
                showSubHeader: true,
                showDixa: true
              },
              element: <ExtrasList />
            },
            {
              path: ACCOUNT_ROUTES.extras + '/:product_collection',
              handle: {
                ...Secondary({
                  header: {
                    title: { text: 'titles.extras', namespace },
                    leftAdornment: {
                      defaultBackRoute: ACCOUNT_ROUTES.extras
                    },
                    rightAdornment: <BasketButton />
                  },
                  childrenMaxWidth: 'xl',
                  documentTitleKey: 'extras'
                }),
                showDixa: true
              },
              element: <ProductCollection />
            },
            {
              path: ACCOUNT_ROUTES.basket,
              handle: {
                ...Secondary({
                  header: {
                    title: { text: 'titles.basket', namespace },
                    leftAdornment: {
                      defaultBackRoute: ACCOUNT_ROUTES.extras
                    }
                  },
                  childrenMaxWidth: 'xl',
                  documentTitleKey: 'basket'
                })
              },
              element: <BasketPage />
            }
          ]
        },
        {
          path: ACCOUNT_ROUTES.referAFriend,
          handle: {
            header: {
              rightAdornment:
                i18n.language === Language.en
                  ? [<RuffActually key="ruff-actually" />]
                  : [],
              title: {
                text: 'titles.refer_a_friend',
                namespace
              }
            },
            hidePadding: false,
            documentTitleKey: 'refer_a_friend',
            scrollMode: 'pathname',
            showSubHeader: true,
            showDixa: true
          },
          element: <ReferAFriendPage />
        },
        {
          path: ACCOUNT_ROUTES.referAFriend,
          handle: { documentTitleKey: 'refer_a_friend' },
          children: [
            {
              path: ACCOUNT_ROUTES.referAFriend + '/:tab',
              handle: {
                header: {
                  title: {
                    text: 'titles.refer_a_friend',
                    namespace
                  }
                },
                documentTitleKey: 'refer_a_friend',
                hidePadding: false,
                scrollMode: 'pathname',
                showSubHeader: true
              },
              element: <ReferAFriendPage />
            }
          ]
        },
        {
          path: ACCOUNT_ROUTES.swapAndSave,
          handle: {
            ...Secondary({
              header: {
                title: { text: 'titles.swap_and_save', namespace }
              },
              documentTitleKey: 'swap_and_save'
            })
          },
          element: <SwapAndSavePage />
        },
        {
          path: ACCOUNT_ROUTES.switchAndSave,
          handle: {
            ...Secondary({
              header: {
                title: {
                  text: 'titles.switch_and_save',
                  namespace
                }
              },
              documentTitleKey: 'switch_and_save'
            }),
            fillScreen: true
          },
          element: <SwitchAndSavePage />
        },
        {
          path: ACCOUNT_ROUTES.mfitbPlanOffer,
          handle: {
            hideNavigationTabBar: true,
            hideFooter: true,
            header: null,
            hidePadding: true,
            fillScreen: true,
            documentTitleKey: 'savings_plan_offer'
          },
          element: <MFITBPlanOffer />
        },
        {
          path: ACCOUNT_ROUTES.restartPlanSuccess,
          handle: {
            hideNavigationTabBar: true,
            hideFooter: true,
            header: null,
            hidePadding: true,
            fillScreen: true,
            documentTitleKey: 'home'
          },
          element: <RestartPlanSuccess />
        },
        {
          path: ACCOUNT_ROUTES.downsizeAndSave,
          handle: {
            ...Secondary({
              header: {
                title: { text: 'titles.downsize_and_save', namespace }
              },
              documentTitleKey: 'downsize_and_save'
            })
          },
          element: <DownsizeAndSavePage variant="en" />
        },
        {
          path: ACCOUNT_ROUTES.myAccount,
          handle: {
            ...Secondary({
              header: {
                title: { text: 'titles.my_account', namespace }
              },
              documentTitleKey: 'my_account'
            })
          },
          element: <MyAccountPage />
        },
        {
          path: ACCOUNT_ROUTES.personalDetails,
          handle: {
            header: {
              title: {
                text: 'titles.my_account',
                namespace
              }
            },
            documentTitleKey: 'personal_details',
            hideFooter: true,
            showDixa: true
          },
          element: <PersonalDetails />
        },
        {
          path: ACCOUNT_ROUTES.contactPreferences,
          handle: {
            header: {
              title: {
                text: 'titles.my_account',
                namespace
              }
            },
            documentTitleKey: 'contact_preferences',
            hideFooter: true,
            showDixa: true
          },
          element: <ContactPreferencesPage />
        },
        {
          path: ACCOUNT_ROUTES.paymentMethods,
          handle: {
            header: {
              title: {
                text: 'titles.my_account',
                namespace
              }
            },
            documentTitleKey: 'payment_methods',
            hideFooter: true,
            showDixa: true
          },
          element: <PaymentMethodsPage />
        },
        {
          path: ACCOUNT_ROUTES.addPaymentMethod,
          handle: {
            header: {
              title: {
                text: 'titles.my_account',
                namespace
              }
            },
            documentTitleKey: 'payment_methods',
            hideFooter: true,
            showDixa: true
          },
          element: <AddPaymentMethodPage />
        },
        {
          path: ACCOUNT_ROUTES.editRecipes,
          handle: {
            ...Secondary({
              header: {
                title: { text: 'titles.edit_recipes', namespace }
              },
              documentTitleKey: 'edit_recipes',
              childrenMaxWidth: 'xl'
            }),
            showDixa: true
          },
          element: <EditRecipes />
        },
        {
          path: `${ACCOUNT_ROUTES.editExtras}/:boxId?`,
          handle: {
            ...Secondary({
              header: {
                title: { text: 'titles.edit_extras', namespace }
              },
              documentTitleKey: 'edit_extras'
            }),
            showDixa: true
          },
          element: <EditExtrasPage />
        },
        {
          path: `${ACCOUNT_ROUTES.editDeliveryAddress}`,
          handle: {
            ...Secondary({
              header: {
                title: { text: 'titles.edit_delivery_address', namespace }
              },
              documentTitleKey: 'edit_delivery_address'
            }),
            showDixa: true
          },
          element: <EditDeliveryAddress />
        },
        {
          path: ACCOUNT_ROUTES.oneOffBox,
          handle: {
            ...Secondary({
              header: {
                title: { text: 'titles.one_off_box', namespace }
              },
              documentTitleKey: 'one_off_box',
              childrenMaxWidth: 'xl'
            })
          },
          element: <OneOffBox />
        },
        {
          path: ACCOUNT_ROUTES.oneOffBoxConfirmation,
          handle: {
            ...Secondary({
              header: {
                title: { text: 'titles.one_off_box', namespace }
              },
              documentTitleKey: 'one_off_box',
              childrenMaxWidth: 'xl'
            })
          },
          element: <OneOffBoxConfirmationPage />
        }
      ]
    },
    // Deeplinking / Universal Link redirects
    {
      path: '/account/box/core/:id',
      loader: ({ params }) => redirect(`${ACCOUNT_ROUTES.orders}/${params.id}`),
      handle: undefined
    },
    {
      path: '/account/box/extras/:id',
      loader: ({ params }) =>
        redirect(`${ACCOUNT_ROUTES.extrasOnly}/${params.id}`),
      handle: undefined
    },
    {
      path: '/account/refer-a-friend/pass',
      loader: () => redirect(ACCOUNT_ROUTES.referAFriend),
      handle: undefined
    }
  ]
}

export { createAccountRouteObject, AccountRouteObject }
