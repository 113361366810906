import { useReactiveVar } from '@apollo/client'
import React, { useMemo } from 'react'

import { Button } from '@/components/elements/atoms/Button'

import STYLES from './ActionButtons.module.sass'

import { customerIssueManagementState } from '../../CustomerIssueManagementPage'

type ButtonProps = {
  variant?: 'primary' | 'secondary' | 'ghost'
  text: string | Array<string>
  disabled?: boolean
  action: () => void
}

type Props = {
  variant?: 'left' | 'center' | 'right'
  acceptButton: ButtonProps
  rejectButton?: ButtonProps
}

const ActionButtons = ({
  variant = 'left',
  acceptButton,
  rejectButton
}: Props): JSX.Element => {
  const customerIssueManagementData = useReactiveVar(
    customerIssueManagementState
  )
  const styles = useMemo(() => {
    return `${STYLES.buttonsContainer} ${STYLES[variant]}`
  }, [variant])

  return (
    <div className={styles}>
      <Button
        typography={{
          text: acceptButton.text,
          namespace: customerIssueManagementData.namespace
        }}
        variant={acceptButton.variant || 'primary'}
        disabled={acceptButton.disabled || false}
        onClick={acceptButton.action}
        disableAnalytics
      />
      {rejectButton && (
        <Button
          typography={{
            namespace: customerIssueManagementData.namespace,
            text: rejectButton.text
          }}
          disabled={rejectButton.disabled || false}
          variant={rejectButton.variant || 'secondary'}
          onClick={rejectButton.action}
          disableAnalytics
        />
      )}
    </div>
  )
}

export { Props }
export default ActionButtons
