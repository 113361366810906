import { useReactiveVar } from '@apollo/client'

import { featureFlagsDataVar } from '@/services/apollo'

type UseSkipPushFlag = {
  shouldSeeSkipV1: boolean
  shouldSeeSkipV2: boolean
  shouldSeeSkipEitherVersion: boolean
  shouldSeePush: boolean
}

const useSkipPushFlag = (): UseSkipPushFlag => {
  const featureFlagsData = useReactiveVar(featureFlagsDataVar)

  const shouldSeeSkipV1 =
    featureFlagsData?.shouldSeeSkipBoxFeature === 'variant'

  const shouldSeeSkipV2 =
    featureFlagsData?.skipVersusPushBoxExpirience === 'skip-box'

  const shouldSeeSkipEitherVersion = shouldSeeSkipV1 || shouldSeeSkipV2

  const shouldSeePush =
    featureFlagsData?.skipVersusPushBoxExpirience === 'push-box'

  return {
    shouldSeeSkipV1,
    shouldSeeSkipV2,
    shouldSeeSkipEitherVersion,
    shouldSeePush
  }
}

export default useSkipPushFlag
