import { gql } from '@apollo/client'

const BASKET_BUTTON_QUERY = gql`
  query BasketButtonQuery {
    user {
      id
      basket {
        id
        basketProducts {
          id
          quantity
        }
      }
    }
  }
`
export { BASKET_BUTTON_QUERY }
