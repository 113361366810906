import { gql } from '@apollo/client'

// eslint-disable-next-line i18next/no-literal-string
const CALENDAR_DATES_V2_FRAGMENT = gql`
  fragment CalendarDatesV2Fragment on CalendarDate {
    date
    deliverable
    replacementDate
    endOfLeadTime
  }
`

export { CALENDAR_DATES_V2_FRAGMENT }
