/* eslint-disable i18next/no-literal-string */
import { ACCOUNT_ROUTES } from '@/routes'
import { Grid } from '@mui/material'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import useSkipPushFlag from '@/hooks/useSkipPushFlag'

import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'
import Image from '@/components/elements/atoms/Image'
import Separator from '@/components/elements/atoms/Separator/Separator'
import Text from '@/components/elements/atoms/Text'

import STYLES from '../PersonalDetails.module.sass'

type Props = {
  boxId?: string
}

const skip = 'skip_box'
const push = 'push_box'

const SkipPushCard = ({ boxId }: Props): JSX.Element => {
  const navigate = useNavigate()
  const { t } = useTranslation('account')

  const { shouldSeeSkipV2 } = useSkipPushFlag()

  const namespace = shouldSeeSkipV2 ? skip : push

  const handleClick = useCallback(async () => {
    if (!boxId) return

    const attribute = shouldSeeSkipV2 ? 'openSkipModal' : 'openPushModal'

    navigate(`${ACCOUNT_ROUTES.changeDate}/${boxId}?${attribute}=1`)
  }, [boxId, navigate, shouldSeeSkipV2])

  return (
    <>
      <Separator bold={false} handdrawn margin={{ top: 24, bottom: 24 }} />

      <Text
        text="change_date.push_skip_box.title"
        namespace="account"
        variant="display24"
      />

      <Grid container gap={3} wrap="nowrap">
        <Grid item xs={6} className={STYLES.skipBoxImageGrid}>
          <Image
            className={STYLES.skipBoxImage}
            slug="dog-with-box-on-staircase"
            image={{
              width: 500,
              height: 350,
              resizeMode: 'resize_to_fill'
            }}
            alt={t('change_date.push_skip_box.image_alt')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className={STYLES.skipBoxCopyGrid}>
            <Text
              text="change_date.push_skip_box.delay_title"
              namespace="account"
              variant="display20"
              margin={false}
            />
            <Text
              text={`change_date.${namespace}.delay_body`}
              namespace="account"
            />

            <Button
              dataTestId={
                shouldSeeSkipV2
                  ? 'skip-box-account-button'
                  : 'push-box-account-button'
              }
              typography={{
                text: shouldSeeSkipV2
                  ? `change_date.skip_box.skip_next_box`
                  : `change_date.push_box.title`,
                namespace: 'account'
              }}
              onClick={handleClick}
              identifier={
                shouldSeeSkipV2
                  ? 'account_details.skip_box_button'
                  : 'account_details.push_box_button'
              }
            />
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

export default SkipPushCard
