// @noflow
import React from 'react'
import { connect } from 'react-redux'

import type { Tab } from '../models/tab'
import type { State } from '../reducer'
import FunnelTab from './FunnelTab'
import IdeasTab from './IdeasTab'
import LocalisationTab from './LocalisationTab'

type PresentationalProps = {
  selectedTab: Tab
}

type Props = PresentationalProps

const mapStateToProps = (state: State): PresentationalProps => ({
  selectedTab: state.navigation.selectedTab
})

const TabContent = ({ selectedTab }: Props): React.ReactElement => {
  switch (selectedTab) {
    case 'funnel': {
      return <FunnelTab />
    }
    case 'ideas': {
      return <IdeasTab />
    }
    case 'localisation': {
      return <LocalisationTab />
    }
  }
}

export default connect(mapStateToProps)(TabContent)
