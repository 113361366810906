/* eslint-disable react/jsx-props-no-spreading */

/**
 * Modal Management System
 *
 * This module provides context and utilities for managing modals in a React application.
 * It allows dynamic registration, opening, closing, and rendering of modals.
 */
import React, { ReactElement, createContext, useMemo, useState } from 'react'

/**
 * Props passed to modals. Can be undefined or an object with arbitrary key-value pairs.
 */
type Props = undefined | Record<string, unknown>

/**
 * Context definition for managing modals.
 */
type ModalContext = {
  /**
   * The current state of all registered modals.
   * Each modal is keyed by its ID and has associated properties.
   */
  modals: Record<string, Props>

  /**
   * Opens a modal by its ID with the provided props.
   * @param id - The unique identifier for the modal.
   * @param props - The properties to pass to the modal.
   */
  openModal: (id: string, props: Props) => void

  /**
   * Closes a modal by its ID.
   * @param id - The unique identifier for the modal.
   */
  closeModal: (id: string) => void

  /**
   * Closes all currently open modals.
   */
  closeAllModals: () => void
}

/**
 * Context definition for an individual modal instance.
 */
type CreateModalContext = {
  /**
   * The unique identifier for the modal instance.
   */
  id: string

  /**
   * Closes the modal with the given ID.
   * @param id - The unique identifier for the modal.
   */
  closeModal: (id: string) => void
}

/**
 * The record structure for managing modal states and their props.
 */
type ModalRecord = Record<string, Props>

/**
 * Registry of all modal components.
 */
type RegisterModals = Record<string, (props: Props) => JSX.Element>

/**
 * Context for managing all modals.
 * Provides functions and state for opening, closing, and rendering modals.
 */
const ModalManagerContext = createContext<ModalContext | null>(null)

/**
 * Context for an individual modal.
 * Provides modal-specific state and actions.
 */
const ModalContext = createContext<CreateModalContext>({
  closeModal: () => undefined,
  id: ''
})

/**
 * Internal registry for registered modals.
 * Maps modal IDs to their corresponding React components.
 */
const registeredModals: RegisterModals = {}

/**
 * Registers a new modal with the given ID and component.
 *
 * @param id - The unique identifier for the modal.
 * @param Component - The React component representing the modal.
 */
const registerModal = <T extends Props>(
  id: string,
  Component: (props: T) => JSX.Element
): void => {
  registeredModals[id] = Component as (props: Props) => JSX.Element
}

/**
 * ModalManagerProvider
 *
 * A provider component that manages the state and rendering of all modals.
 *
 * @param {Object} props - The component props.
 * @param {ReactElement} props.children - The children components rendered within the provider.
 * @returns {JSX.Element} The rendered ModalManagerProvider.
 */
const ModalManagerProvider = ({
  children
}: {
  children: ReactElement
}): JSX.Element => {
  /**
   * Initializes the state for all modals.
   * Modals are set to `isOpen: false` by default.
   */
  const initialModals = Object.keys(registeredModals).reduce((acc, curr) => {
    return { ...acc, [curr]: { isOpen: false } }
  }, {})

  const [modals, setModals] = useState<ModalRecord>(initialModals)

  /**
   * Opens a modal by its ID with the given props.
   * @param id - The unique identifier for the modal.
   * @param props - The properties to pass to the modal.
   */
  const openModal = (id: string, props: Props) => {
    setModals((prev) => ({ ...prev, [id]: { isOpen: true, ...props } }))
  }

  /**
   * Closes a modal by its ID.
   * @param id - The unique identifier for the modal.
   */
  const closeModal = (id: string) => {
    setModals((prev = {}) => ({
      ...prev,
      [id]: { ...(prev[id] || {}), isOpen: false }
    }))
  }

  /**
   * Closes all modals currently open.
   */
  const closeAllModals = () => {
    setModals((prev) =>
      Object.keys(prev).reduce((acc: ModalRecord, id) => {
        acc[id] = { ...prev[id], isOpen: false }
        return acc
      }, {})
    )
  }

  /**
   * Renders a modal based on its ID and props.
   * @param entry - The modal entry containing its ID and associated properties.
   * @returns {JSX.Element | null} The rendered modal or `null` if the modal is closed.
   */
  const renderModal = (entry: [string, Props]) => {
    const [id, rest] = entry

    const { isOpen } = rest || {}

    const props = { id, closeModal, openModal, closeAllModals, ...rest }

    const Content = registeredModals[id]

    if (!isOpen || !Content) return null

    return (
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      <ModalContext.Provider key={id} value={{ id, closeModal }}>
        <Content {...props} />
      </ModalContext.Provider>
    )
  }

  /**
   * Memoized value for the modal context, ensuring performance optimization.
   */
  const value = useMemo(
    () => ({
      modals,
      openModal,
      closeModal,
      closeAllModals
    }),
    [modals]
  )

  return (
    <ModalManagerContext.Provider value={value}>
      {children}
      {Object.entries(modals).map(renderModal)}
    </ModalManagerContext.Provider>
  )
}

export {
  ModalManagerProvider,
  ModalManagerContext,
  registerModal,
  ModalContext,
  ModalRecord
}
