import { gql } from '@apollo/client'

import { USER_ADDRESS_FRAGMENT } from '../../AddressBookPage/fragments/addressFragment'
import { PERSONAL_DETAILS_FRAGMENT } from '../../PersonalDetailsPage/fragments/personalDetailsFragment'

const USER_FRAGMENT = gql`
  ${PERSONAL_DETAILS_FRAGMENT}
  ${USER_ADDRESS_FRAGMENT}
  fragment userFragment on User {
    id
    ...personalDetailsFragment
    ...UserAddressFragment
  }
`

const PAYMENT_METHODS_FRAGMENT = gql`
  fragment paymentMethodsFragment on Subscription {
    paymentMethods(archivedType: $archivedType) {
      ... on BillingAgreement {
        __typename
        id
        type
        active
        paypalEmail
      }
      ... on CreditCardPaymentMethod {
        __typename
        id
        type
        active
        lastFourDigits
        expMonth
        expYear
      }
      ... on SepaDirectDebit {
        __typename
        id
        type
        active
        accountLastFour
      }
      ... on MerchantInitiatedPayment {
        __typename
        id
        type
        active
        merchantInitiatedType
      }
    }
  }
`

export { USER_FRAGMENT, PAYMENT_METHODS_FRAGMENT }
