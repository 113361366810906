// @noflow
import { ACCOUNT_ROUTES } from '@/routes'
import { useQuery, useReactiveVar } from '@apollo/client'
import { Grid } from '@mui/material'
import * as Sentry from '@sentry/browser'
import upperFirst from 'lodash/upperFirst'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { featureFlagsDataVar, shippingCountryDataVar } from '@/services/apollo'

import { possessive, toLocalisedSentence } from '@/utils/StringHelper'

import BRAND_COLOURS from '@/constants/BrandColours'
// Utils
import BREAKPOINTS from '@/constants/Breakpoints'
import SUPPORT_COLOURS from '@/constants/SupportColours'

import useLocalStorage from '@/hooks/useLocalStorage'
import useWindowSize from '@/hooks/useWindowSize'

import Dogs from 'assets/images/illustrations/dogs/raf-dogs-greeting.svg'
// Assets
import AppIcon from 'assets/images/illustrations/download-app-icon.svg'
import Polaroid from 'assets/images/illustrations/polaroid-profile.svg'
import Tail1 from 'assets/images/illustrations/tail-wag-white-1.svg'
import Tail2 from 'assets/images/illustrations/tail-wag-white-2.svg'

import Card from '@/components/elements/atoms/Card/Card'
import { CardSkeleton } from '@/components/elements/atoms/Card/CardSkeleton'
// Components
import Icon from '@/components/elements/atoms/Icon/Icon'
import ProgressBar from '@/components/elements/atoms/ProgressBar/ProgressBar'
import SkeletonTitle from '@/components/elements/atoms/SkeletonTitle/SkeletonTitle'
import Text from '@/components/elements/atoms/Text/Text'
import SwiperSlider from '@/components/elements/molecules/SwiperSlider/SwiperSlider'
import {
  PostSignUpHeroSectionQuery_user_dogs as Dog,
  PostSignUpHeroSectionQuery
} from '@/components/pages/Dashboard/components/PostSignUpHeroSection/__generated__/PostSignUpHeroSectionQuery'

import STYLES from './PostSignUpHeroSection.module.sass'

import { Code } from '@/types'

// Apollo
import { POST_SIGN_UP_HERO_SECTION_QUERY } from './queries'

const namespace = 'account'
const COMPLETION_PERCENTAGE = 100

type CardProps = {
  setCompletion?: (arg: boolean) => void
  completed: boolean
  destination: string
  dogs: Dog[]
  possessiveDogNames?: string
  icon: string
  iconWidth: number
  iconHeight: number
  iconAlt: string
  title: string
  actionType: string
  id: string
  identifier: string
}

const PostSignUpHeroSectionLoading = (): JSX.Element => {
  const { windowWidth } = useWindowSize()
  const featureFlagsData = useReactiveVar(featureFlagsDataVar)
  const { systemShouldShowAppContent } = featureFlagsData ?? {}
  const isMobile = windowWidth < BREAKPOINTS.md

  return (
    <div>
      <SkeletonTitle
        align={isMobile ? 'center' : 'left'}
        height={isMobile ? 24 : 28}
        width={isMobile ? '60%' : '40%'}
        margin={isMobile ? '2.4rem 0' : '.5rem 0'}
      />
      <div className={STYLES.progress}>
        <SkeletonTitle
          align="left"
          height={isMobile ? 14 : 16}
          width="30%"
          margin=".5rem 0"
        />
        <ProgressBar
          background="grey100"
          fillColour={BRAND_COLOURS.brandBlue500}
          percentFilled={0}
          slim
        />
      </div>
      <Grid
        container
        item
        alignItems="stretch"
        className={STYLES.actionCards}
        spacing={2}
      >
        {systemShouldShowAppContent === 'true' && (
          <Grid item xs={4}>
            <CardSkeleton height="16rem" />
          </Grid>
        )}
        <Grid item xs={systemShouldShowAppContent === 'true' ? 4 : 6}>
          <CardSkeleton height="16rem" />
        </Grid>
        <Grid item xs={systemShouldShowAppContent === 'true' ? 4 : 6}>
          <CardSkeleton height="16rem" />
        </Grid>
      </Grid>
    </div>
  )
}

const ActionCard = ({
  setCompletion,
  completed,
  destination,
  dogs,
  possessiveDogNames,
  icon,
  iconWidth,
  iconHeight,
  iconAlt,
  title,
  actionType,
  id,
  identifier
}: CardProps): JSX.Element => {
  const navigate = useNavigate()
  const actionCallback = useCallback(() => {
    id === 'app' ? window.open(destination, '_blank') : navigate(destination)
    setTimeout((): void => {
      setCompletion && setCompletion(true)
    }, 500)
  }, [navigate, setCompletion, destination, id])
  return (
    <Card shadow padding={0} onClick={actionCallback} identifier={identifier}>
      <div className={STYLES.cardWrapper}>
        <Icon
          accentColour={completed ? 'successGreen300' : 'grey200'}
          asset="checkmark"
          size={20}
          width={20}
        />
        <Text
          text={title}
          variables={{
            possessiveDogNames,
            count: dogs.length
          }}
          margin={false}
          namespace={namespace}
          align="center"
        />
        <Text
          text={actionType}
          margin={false}
          namespace={namespace}
          colour="brandBlue400"
          variant="textRegular14"
          align="center"
        />
        <div className={STYLES.iconWrapper}>
          <img height={iconHeight} width={iconWidth} src={icon} alt={iconAlt} />
        </div>
      </div>
    </Card>
  )
}

const PostSignUpHeroSection = (): JSX.Element | null => {
  const namespace = 'account'
  const copyContext = 'home.hero_section.post_sign_up_hero'
  const { windowWidth } = useWindowSize()
  const shippingCountryData = useReactiveVar(shippingCountryDataVar)
  const featureFlagsData = useReactiveVar(featureFlagsDataVar)
  const { systemShouldShowAppContent } = featureFlagsData ?? {}
  const { t } = useTranslation('account')
  const codePoland = shippingCountryData?.code === Code.PL

  // TODO
  const [clickedAppDownload, setClickedAppDownload] = useLocalStorage<
    string,
    boolean
  >('clicked-app-task-complete', false)

  const [seenSuccessOnce, setSeenSuccessOnce] = useLocalStorage<
    string,
    boolean
  >('has-seen-success-state-once', false)
  const [clickedRaf, setClickedRaf] = useLocalStorage<string, boolean>(
    'clicked-raf-task-complete',
    false
  )
  const [completion, setCompletion] = useState(
    seenSuccessOnce ? COMPLETION_PERCENTAGE : 0
  )
  const isMobile = windowWidth < BREAKPOINTS.md
  const completionWeight = systemShouldShowAppContent === 'true' ? 33.33333 : 50

  const { loading, data, error } = useQuery<PostSignUpHeroSectionQuery>(
    POST_SIGN_UP_HERO_SECTION_QUERY
  )

  useEffect(() => {
    if (data && data?.user.dogs) {
      const hasUploadedAllAvatars = data.user.dogs.every(
        (dog: Dog) => dog.dogProfile && dog.dogProfile.avatarUrl !== null
      )
      const completionTimer = setInterval(() => {
        let completionPercentage = 0
        if (hasUploadedAllAvatars) completionPercentage += completionWeight
        if (clickedAppDownload) completionPercentage += completionWeight
        if (clickedRaf) completionPercentage += completionWeight
        setCompletion(Math.round(completionPercentage))
      }, 800)

      return () => {
        clearInterval(completionTimer)
      }
    }
  }, [
    data,
    data?.user.dogs,
    completion,
    clickedAppDownload,
    clickedRaf,
    completionWeight
  ])

  useEffect(() => {
    const fadeInSuccess = setTimeout(() => {
      if (completion === COMPLETION_PERCENTAGE && !seenSuccessOnce) {
        setSeenSuccessOnce(true)
      }
    }, 2000)

    return () => {
      clearTimeout(fadeInSuccess)
    }
  }, [completion, seenSuccessOnce, setSeenSuccessOnce])

  if (error) {
    Sentry.captureException(`Error in POST_SIGN_UP_HERO_SECTION_QUERY`, {
      extra: {
        error
      }
    })
  }

  if (loading || !data) {
    return <PostSignUpHeroSectionLoading />
  }

  const {
    user: { dogs, preferredLanguage }
  } = data

  if (!dogs || !preferredLanguage) return null

  const hasUploadedAllAvatars = dogs.every(
    (dog: Dog) => dog.dogProfile && dog.dogProfile.avatarUrl !== null
  )

  const selectJoinedPossessiveDogsNames = (dogs: Dog[]): string => {
    if (dogs.length > 0) {
      return possessive(
        toLocalisedSentence({
          arr: dogs.map(({ name }: Dog): string => upperFirst(name)),
          lng: preferredLanguage
        }),
        preferredLanguage
      )
    } else {
      return ''
    }
  }

  const possessiveDogNames = selectJoinedPossessiveDogsNames(dogs)

  const cardData =
    systemShouldShowAppContent === 'false'
      ? [
          {
            setCompletion: setClickedRaf,
            completed: clickedRaf,
            destination: ACCOUNT_ROUTES.referAFriend,
            icon: Dogs,
            iconWidth: 54,
            iconHeight: 23,
            iconAlt: t(`${copyContext}.v2.dogs_alt`),
            // eslint-disable-next-line i18next/no-literal-string
            title: `${copyContext}.v2.actions.raf.title`,
            // eslint-disable-next-line i18next/no-literal-string
            actionType: `${copyContext}.v2.actions.activity_type`,
            id: 'raf'
          },
          {
            setCompletion: undefined,
            completed: hasUploadedAllAvatars,
            destination: ACCOUNT_ROUTES.profile,
            icon: Polaroid,
            iconWidth: 30,
            iconHeight: 35,
            iconAlt: t(`${copyContext}.polaroid_alt`),
            title:
              dogs.length > 3
                ? // eslint-disable-next-line i18next/no-literal-string
                  `${copyContext}.v2.actions.dog_profile.title_dogs`
                : // eslint-disable-next-line i18next/no-literal-string
                  `${copyContext}.v2.actions.dog_profile.title`,
            // eslint-disable-next-line i18next/no-literal-string
            actionType: `${copyContext}.v2.actions.activity_type`,
            id: 'profile'
          }
        ]
      : [
          {
            setCompletion: setClickedAppDownload,
            completed: clickedAppDownload,
            destination: codePoland
              ? 'https://psibufet.pl/app/download'
              : 'https://butternutbox.com/app/download',
            icon: AppIcon,
            iconWidth: 32,
            iconHeight: 35,
            iconAlt: t(`${copyContext}.v2.app_alt`),
            // eslint-disable-next-line i18next/no-literal-string
            title: `${copyContext}.v2.actions.app.title`,
            // eslint-disable-next-line i18next/no-literal-string
            actionType: `${copyContext}.v2.actions.activity_type`,
            id: 'app'
          },
          {
            setCompletion: setClickedRaf,
            completed: clickedRaf,
            destination: ACCOUNT_ROUTES.referAFriend,
            icon: Dogs,
            iconWidth: 54,
            iconHeight: 23,
            iconAlt: t(`${copyContext}.v2.dogs_alt`),
            // eslint-disable-next-line i18next/no-literal-string
            title: `${copyContext}.v2.actions.raf.title`,
            // eslint-disable-next-line i18next/no-literal-string
            actionType: `${copyContext}.v2.actions.activity_type`,
            id: 'raf'
          },
          {
            setCompletion: undefined,
            completed: hasUploadedAllAvatars,
            destination: ACCOUNT_ROUTES.profile,
            icon: Polaroid,
            iconWidth: 30,
            iconHeight: 35,
            iconAlt: t(`${copyContext}.polaroid_alt`),
            title:
              dogs.length > 3
                ? // eslint-disable-next-line i18next/no-literal-string
                  `${copyContext}.v2.actions.dog_profile.title_dogs`
                : // eslint-disable-next-line i18next/no-literal-string
                  `${copyContext}.v2.actions.dog_profile.title`,
            // eslint-disable-next-line i18next/no-literal-string
            actionType: `${copyContext}.v2.actions.activity_type`,
            id: 'profile'
          }
        ]

  // Sort cards so the we render the completed ones last
  const sortedCardData = cardData.sort((x) => (!x.completed ? -1 : 1))

  return (
    <div>
      <div className={STYLES.titleWrapper}>
        {isMobile && (
          <img
            height={70}
            width={57}
            src={Tail1}
            className={STYLES.tailWag1}
            alt={t(`${copyContext}.tail_alt`)}
          />
        )}
        <div className={STYLES.title}>
          <Text
            text={`${copyContext}.v2.title`}
            namespace={namespace}
            variant="display24"
            element="h2"
            align={isMobile ? 'center' : 'left'}
            margin={false}
          />
        </div>
        {isMobile && (
          <img
            height={70}
            width={60}
            src={Tail2}
            alt={t(`${copyContext}.tail_alt`)}
            className={STYLES.tailWag2}
          />
        )}
      </div>
      <div className={STYLES.progress}>
        {completion === COMPLETION_PERCENTAGE && !seenSuccessOnce && (
          <div className={STYLES.progressSuccess} />
        )}
        <Text
          text={`${copyContext}.v2.to_do`}
          margin={false}
          namespace={namespace}
          variant="textRegular14"
        />
        <ProgressBar
          background="grey100"
          fillColour={
            completion === COMPLETION_PERCENTAGE
              ? SUPPORT_COLOURS.successGreen200
              : BRAND_COLOURS.brandBlue500
          }
          percentFilled={completion}
          slim
        />
      </div>
      {isMobile ? (
        <SwiperSlider className={STYLES.carousel}>
          {sortedCardData.map((card) => {
            return (
              <ActionCard
                setCompletion={card.setCompletion}
                completed={card.completed}
                destination={card.destination}
                icon={card.icon}
                iconWidth={card.iconWidth}
                iconHeight={card.iconHeight}
                iconAlt={card.iconAlt}
                title={card.title}
                actionType={card.actionType}
                possessiveDogNames={possessiveDogNames}
                dogs={dogs}
                key={card.id}
                id={card.id}
                identifier={
                  'post_sign_up_hero_section.action_card.' + card.actionType
                }
              />
            )
          })}
        </SwiperSlider>
      ) : (
        <Grid
          container
          alignItems="stretch"
          className={STYLES.actionCards}
          spacing={2}
        >
          {sortedCardData.map((card) => {
            return (
              <Grid
                item
                xs={systemShouldShowAppContent === 'true' ? 4 : 6}
                key={card.id}
              >
                <ActionCard
                  setCompletion={card.setCompletion}
                  completed={card.completed}
                  destination={card.destination}
                  icon={card.icon}
                  iconWidth={card.iconWidth}
                  iconHeight={card.iconHeight}
                  iconAlt={card.iconAlt}
                  title={card.title}
                  actionType={card.actionType}
                  possessiveDogNames={possessiveDogNames}
                  dogs={dogs}
                  id={card.id}
                  identifier={
                    'post_sign_up_hero_section.action_card.' + card.actionType
                  }
                />
              </Grid>
            )
          })}
        </Grid>
      )}
    </div>
  )
}

export default PostSignUpHeroSection
