// @flow

import * as React from 'react'

type Props = {|
  displayText: string,
  selectItemCallback: () => void,
  selected: boolean
|}

// Due to the how the SelectableItemList has been put together, we must deal
// with the fact that we require an arrow function here. The selected option
// must bubble up to the parent component rather than be connected to the Redux
// store where the selected option can be set as part of the application state
/* eslint-disable react/jsx-no-bind */
const PillButton = ({
  displayText,
  selectItemCallback,
  selected
}: Props): React.Element<'button'> => (
  <button
    className={`pill-btn ${selected ? 'pill-btn--selected' : ''}`}
    onClick={(e: SyntheticEvent<HTMLButtonElement>): void => {
      e.currentTarget.blur()
      selectItemCallback()
    }}
    type='button'
  >
    { displayText }
  </button>
)

export default PillButton
