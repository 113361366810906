// @noflow
import { StripeError } from '@stripe/stripe-js'
import i18next from 'i18next'

const shouldDisableButton = (error: string | null): boolean => {
  const copy_context = 'checkout:errors'
  switch (error) {
    case i18next.t(`${copy_context}.address_invalid`): {
      return false
    }
    case null: {
      return false
    }
    default: {
      return true
    }
  }
}

const abstractStripeError = (stripeError: StripeError): string => {
  const copy_context = 'checkout:errors'
  switch (stripeError.type) {
    case 'validation_error': {
      switch (stripeError.code) {
        case 'incomplete_number': {
          return i18next.t(`${copy_context}.incomplete_card_number`)
        }
        case 'invalid_number': {
          return i18next.t(`${copy_context}.invalid_card_number`)
        }
        case 'invalid_expiry_year_past': {
          return i18next.t(`${copy_context}.past_card_expiry`)
        }
        case 'incomplete_expiry': {
          return i18next.t(`${copy_context}.incomplete_card_expiry`)
        }
        case 'incomplete_cvc': {
          return i18next.t(`${copy_context}.incomplete_card_cvc`)
        }
        case 'invalid_cvc': {
          return i18next.t(`${copy_context}.invalid_card_cvc`)
        }
        default: {
          return (
            stripeError.message ??
            i18next.t(`${copy_context}.delivery_details_fetch`)
          )
        }
      }
    }
    default: {
      return (
        stripeError.message ??
        i18next.t(`${copy_context}.delivery_details_fetch`)
      )
    }
  }
}

export { shouldDisableButton, abstractStripeError }
