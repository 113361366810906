// @noflow
import i18next from 'i18next'
import * as React from 'react'
import {
  ChangeEvent,
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useState
} from 'react'

import * as Validation from '@/components/pages/CheckoutPage/components/Validation/Validation'
import * as inputHelpers from '@/components/pages/CheckoutPage/helpers/inputs'

import { validationIndicator } from '../../helpers/inputs'
import { BaseInputField, InputWithString } from './InputField'

type Props = {
  value: InputWithString
  dataTestId?: string
  onBlur: (event: ChangeEvent<HTMLInputElement>) => void
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

enum RuleId {
  MinimumLength = 'minimumLength',
  OneUpperCaseLetter = 'oneUpperCaseLetter',
  OneSpecialCharacterOrNumber = 'oneSpecialCharacterOrNumber',
  OneLowercaseLetter = 'oneLowercaseLetter'
}

type PasswordRule = {
  id: RuleId
  valid: boolean | null
  ruleMessage: string
}

type PasswordRulesState = PasswordRule[]

// Password Rules
// Regular expression to check for at least one uppercase letter
// Example: "Hello" matches the condition as it has an uppercase "H"
const oneUpperCaseLetter = /[A-Z]/

// Regular expression to check for at least one lowercase letter
// Example: "Hello" matches the condition as it has lowercase letters "ello"
const oneLowercaseLetter = /[a-z]/

// Regular expression to check for a minimum length of 8 characters
// Example: "Hello123" matches the condition as it has 8 characters
const maximumLength = /^.{8,}$/

// Regular expression to check for at least one special character or number
// Example: "Hello@123" matches the condition as it has a special character "@" and numbers "123"
const oneSpecialCharacterOrNumber = /[\W\d]/

const ValidationMessage = ({ rule }: { rule: PasswordRule }) => {
  switch (rule.valid) {
    case null:
      return (
        <p className="checkout__password-helper checkout__password-helper--grey">
          <Validation.Checkmark color="grey" />
          {rule.ruleMessage}
        </p>
      )
    case true:
      return (
        <p className="checkout__password-helper checkout__password-helper--green">
          <Validation.Checkmark color="green" />
          {rule.ruleMessage}
        </p>
      )
    case false:
      return (
        <p className="checkout__password-helper checkout__password-helper--red">
          <Validation.Checkmark color="red" />
          {rule.ruleMessage}
        </p>
      )
  }
}

const invalidatePasswordField = (value: InputWithString): void => {
  // Clear the password field
  value.value = ''

  // Set the password field to NotInteractingInteracted
  value.inputInteractionState = 'NotInteractingInteracted'

  // Validate the password field
  const errorMessage = inputHelpers.validate(value.value, 'password')

  // Set the error message
  value.errorMessage = errorMessage
}

/**
 * `PasswordInputField` is a React component that renders an input field for password entry.
 * It also includes password validation rules and displays validation messages.
 *
 * @component
 * @param {object} props - The properties that define the component's behavior and display.
 * @param {string} props.value - The current value of the password input field.
 * @param {function} props.onBlur - The function to be called when the input field loses focus.
 * @param {function} props.onChange - The function to be called when the value of the input field changes.
 *
 * @returns {ReactElement} A React element representing the password input field.
 *
 * @example
 * // To use this component, import it and use it in your React component's render method:
 * import PasswordInputField from './PasswordInputField';
 *
 * function MyComponent() {
 *   const [password, setPassword] = useState('');
 *
 *   return (
 *     <PasswordInputField
 *       value={password}
 *       onBlur={() => console.log('Input field lost focus')}
 *       onChange={(e) => setPassword(e.target.value)}
 *     />
 *   );
 * }
 */
const PasswordInputField = ({
  value,
  dataTestId,
  onBlur,
  onChange
}: Props): ReactElement => {
  const copyContext = 'checkout:errors.password'
  const [passwordDisplayed, setPasswordDisplayed] = useState(false)
  const [passwordRules, setPassWordRules] = useState<PasswordRulesState>([
    {
      id: RuleId.MinimumLength,
      valid: null,
      ruleMessage: i18next.t(`${copyContext}.minimum_length`)
    },
    {
      id: RuleId.OneUpperCaseLetter,
      valid: null,
      ruleMessage: i18next.t(`${copyContext}.one_uppercase_letter`)
    },
    {
      id: RuleId.OneSpecialCharacterOrNumber,
      valid: null,
      ruleMessage: i18next.t(`${copyContext}.one_special_character_or_number`)
    },
    {
      id: RuleId.OneLowercaseLetter,
      valid: null,
      ruleMessage: i18next.t(`${copyContext}.one_lowercase_letter`)
    }
  ])

  const testValue = useCallback(
    (value: string, pattern: RegExp) => pattern.test(value),
    []
  )

  const indicator = validationIndicator(value)

  const validateValue = useCallback(
    (value: string) => {
      setPassWordRules((prevRules) =>
        prevRules.map((rule) => {
          switch (rule.id) {
            case 'minimumLength':
              return {
                ...rule,
                valid: testValue(value, maximumLength)
              }
            case 'oneUpperCaseLetter':
              return {
                ...rule,
                valid: testValue(value, oneUpperCaseLetter)
              }
            case 'oneSpecialCharacterOrNumber':
              return {
                ...rule,
                valid: testValue(value, oneSpecialCharacterOrNumber)
              }
            case 'oneLowercaseLetter':
              return {
                ...rule,
                valid: testValue(value, oneLowercaseLetter)
              }
            default:
              return rule
          }
        })
      )
    },
    [testValue]
  )

  const onChangeCallback = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event)
      validateValue(event.currentTarget.value)
    },
    [onChange, validateValue]
  )

  const togglePasswordVisibility = useCallback(() => {
    setPasswordDisplayed(!passwordDisplayed)
  }, [passwordDisplayed])

  // If a value is already present, validate it on
  // component load and set the state of the password
  // rules appropriately
  useEffect(() => {
    if (value.inputInteractionState === 'NotInteractingInteracted') {
      validateValue(value.value)
    }
  }, [value.value, validateValue, value.inputInteractionState])

  return (
    <Fragment>
      <BaseInputField
        fieldName={passwordDisplayed ? 'visible-password' : 'password'}
        isValid={indicator !== 'error'}
        dataTestId={dataTestId}
        onBlur={onBlur}
        onChange={onChangeCallback}
        value={value.value}
      >
        <span
          aria-label={
            passwordDisplayed
              ? i18next.t('checkout:input_placeholders.hide_password')
              : i18next.t('checkout:input_placeholders.show_password')
          }
          className={`password-display-toggle${
            passwordDisplayed ? ' visible' : ''
          }`}
          onClick={togglePasswordVisibility}
          onKeyPress={togglePasswordVisibility}
          role="button"
          tabIndex={0}
        />
      </BaseInputField>
      <div className="checkout__password-helper-container">
        {passwordRules.map((rule) => (
          <ValidationMessage key={rule.id} rule={rule} />
        ))}
      </div>
    </Fragment>
  )
}

export default PasswordInputField

export {
  oneUpperCaseLetter,
  oneLowercaseLetter,
  maximumLength,
  oneSpecialCharacterOrNumber,
  invalidatePasswordField
}
