// @flow

import paw from 'assets/images/icons/SOC/paw.svg'
import email from 'assets/images/icons/SOC/envelope.svg'
import address from 'assets/images/icons/map.svg'
import checkmark from 'assets/images/icons/checkmarks/checkmark.svg'
import checkCircle from 'assets/images/icons/checkmarks/check-circle.svg'
import stop from 'assets/images/icons/SOC/stop.svg'
import suspended from 'assets/images/icons/SOC/suspended.svg'
import arrow from 'assets/images/icons/arrows/arrow.svg'
import box from 'assets/images/icons/SOC/box.svg'
import eye from 'assets/images/icons/eye.svg'
import dog from 'assets/images/icons/SOC/dog.svg'
import gender from 'assets/images/icons/SOC/gender.svg'
import breed from 'assets/images/icons/SOC/DNA.svg'
import edit from 'assets/images/icons/SOC/edit.svg'
import dogAvatar1 from 'assets/images/illustrations/dogs/dogAvatar1.svg'
import dogAvatar2 from 'assets/images/illustrations/dogs/dogAvatar2.svg'
import dogAvatar3 from 'assets/images/illustrations/dogs/dogAvatar3.svg'
import cutOffDate from 'assets/images/icons/SOC/cut-off-date.svg'
import noResultsEmail from 'assets/images/illustrations/no-results-email.svg'
import deleteIcon from 'assets/images/icons/SOC/delete.svg'

import startSearch from 'assets/images/illustrations/start-search.svg'

const searchResultsIcons = {
  email,
  address,
  checkmark,
  stop,
  suspended,
  box,
  noResultsEmail,
  paw,
  breed,
  startSearch
}

const accountIcons = {
  edit,
  eye
}

const subscriptionIcons = {
  edit,
  eye
}

const deliveriesIcons = {
  cutOffDate,
  edit,
  eye,
  arrow,
  checkCircle
}

const dogDetailsIcons = {
  dog,
  arrow,
  gender,
  breed,
  edit,
  deleteIcon,
  dogAvatar1,
  dogAvatar2,
  dogAvatar3
}

export { searchResultsIcons, accountIcons, subscriptionIcons, deliveriesIcons, dogDetailsIcons }
