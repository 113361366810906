import classNames from 'classnames'
import React from 'react'

// Components
import Text, { Props as TextProps } from '@/components/elements/atoms/Text/Text'

// Styles
import STYLES from './PawTip.module.sass'

const namespace = 'account'

type Props = {
  typography: TextProps
  spaced?: boolean
}

const PawTip = ({ typography, spaced = false }: Props): JSX.Element => {
  const pawClasses = classNames(STYLES.paw, {
    [STYLES.pawSpaced]: spaced
  })

  return (
    <div className={pawClasses}>
      <Text
        text={typography.text}
        margin={false}
        variant="textRegular14"
        namespace={namespace}
        bold={typography.bold}
      />
    </div>
  )
}

export { PawTip }
