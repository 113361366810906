import { Locale, format } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Box from 'assets/images/icons/box.svg'

import { FixedBase } from '@/components/elements/atoms/FixedBase'
import FlatButton from '@/components/elements/atoms/FlatButton/FlatButton'
import Text from '@/components/elements/atoms/Text'

import STYLES from '../ChangeDatePageV2.module.sass'

type DefaultFooterProps = {
  currentDeliveryDate: Date
  locale: Locale
  courierName?: string | null
  onButtonClick: () => void
  buttonIdentifier?: string
  buttonText?: string
  showButton?: boolean
}

const DefaultFooter = ({
  currentDeliveryDate,
  locale,
  courierName,
  onButtonClick,
  showButton,
  buttonText,
  buttonIdentifier
}: DefaultFooterProps): JSX.Element => {
  const { t } = useTranslation('account')
  return (
    <FixedBase backgroundColor="cardboardBrown">
      <div className={STYLES.container}>
        <div className={STYLES.textWrapper}>
          <div>
            <Text
              text="change_date.next_delivery"
              namespace="account"
              colour={'brandWhite'}
              margin={false}
              variant={'textRegular18'}
            />
            <Text
              text={format(currentDeliveryDate, 'EEE d MMM', {
                locale
              })}
              colour={'brandWhite'}
              margin={false}
              bold
              variant="display24"
              translate={false}
            />
            {courierName && (
              <Text
                text={courierName}
                colour={'brandWhite'}
                margin={false}
                variant={'textRegular16'}
                translate={false}
              />
            )}
          </div>

          {showButton ? (
            <FlatButton
              data-testid="change-date-skip-box-button"
              variant="yellow100"
              text={{
                text: buttonText || '',
                namespace: 'account'
              }}
              onClick={onButtonClick}
              identifier={buttonIdentifier || ''}
            />
          ) : (
            <img alt={t('change_date.box_illustration_image_alt')} src={Box} />
          )}
        </div>
      </div>
    </FixedBase>
  )
}

export { DefaultFooter }
