// @noflow
import React, { useCallback, useContext, useMemo } from 'react'

import { formatCurrencyWithDecimal } from '@/utils/currency'

import useSubscriptionResume from '@/hooks/subscriptionResume/useSubscriptionResume'

import { Button } from '@/components/elements/atoms/Button'
import Icon from '@/components/elements/atoms/Icon/Icon'
import Label from '@/components/elements/atoms/Label/Label'
import Text from '@/components/elements/atoms/Text/Text'
import NotificationContainer from '@/components/elements/molecules/NotificationContainer/NotificationContainer'

import STYLES from './ProductVariantFrequency.module.sass'

import type {
  ProductCollectionQuery_productCollection as ProductCollection,
  ProductCollectionQuery,
  ProductCollectionQuery_productCollection_productVariants as ProductVariant
} from '../../../queries/__generated__/ProductCollectionQuery'

import type { VariantDelivery } from '../../../../../../../../types/index'
import { ProductCollectionContext } from '../../../ProductCollection'
import FrequencyVariantIcon from '../../atoms/FrequencyVariantIcon'

type Props = {
  nonCoreOrderDeliveryFee: ProductCollectionQuery['nonCoreOrderDeliveryFee']
  name: ProductCollection['name']
  deliveryType: VariantDelivery
  selectedProductVariantFrequency: VariantDelivery
  subscribeAndSaveable: ProductVariant['subscribeAndSaveable']
  setSelectedProductVariantFrequency: (variantDelivery: VariantDelivery) => void
  shouldSee2Variants?: boolean
}

const ProductVariantFrequency = ({
  nonCoreOrderDeliveryFee,
  name,
  deliveryType,
  selectedProductVariantFrequency,
  subscribeAndSaveable,
  setSelectedProductVariantFrequency,
  shouldSee2Variants = false
}: Props): JSX.Element | null => {
  const copyContext =
    'extras.product_collection.details.product_variant_frequencies.option'

  const {
    locale,
    currency,
    isUpdateProductInProgress,
    subscriptionId,
    status
  } = useContext(ProductCollectionContext)

  const { handleResumeSubscription, subscriptionResumeMutationLoading } =
    useSubscriptionResume({ subscriptionId })

  const formattedDeliveryFee = useMemo(
    () =>
      formatCurrencyWithDecimal(nonCoreOrderDeliveryFee, {
        locale: locale,
        currency: currency
      }),
    [nonCoreOrderDeliveryFee, locale, currency]
  )

  const isProductVariantFrequencySelected = useMemo(
    () => selectedProductVariantFrequency === deliveryType,
    [selectedProductVariantFrequency, deliveryType]
  )

  // Handlers
  const handleSetSelectedProductVariantFrequency = useCallback((): void => {
    setSelectedProductVariantFrequency(deliveryType)
  }, [setSelectedProductVariantFrequency, deliveryType])

  return (
    <div>
      <div
        className={`${STYLES.wrapper} ${
          isProductVariantFrequencySelected ? STYLES.selected : ''
        } ${isUpdateProductInProgress ? STYLES.disabled : ''} ${
          status === 'paused' &&
          deliveryType !== 'on_demand' &&
          isProductVariantFrequencySelected
            ? STYLES.paused
            : ''
        }`}
      >
        <button
          type="button"
          className={`${STYLES.container} ${
            deliveryType === 'recurring' && shouldSee2Variants
              ? STYLES.paddingLeft
              : ''
          }`}
          onClick={handleSetSelectedProductVariantFrequency}
          disabled={isUpdateProductInProgress}
        >
          {deliveryType === 'recurring' && subscribeAndSaveable && (
            <div className={STYLES.bestValueWrapper}>
              {/* eslint-disable-next-line jsx-a11y/label-has-for */}
              <Label
                variant="bestValue"
                size="regular"
                text={{
                  text: `${copyContext}.best_value`,
                  shouldScale: false,
                  namespace: 'dashboard'
                }}
              />
            </div>
          )}
          <div className={STYLES.leftContent}>
            {!shouldSee2Variants && (
              <div className={STYLES.iconWrapper}>
                {isProductVariantFrequencySelected && (
                  <Icon asset="checkmark" size={19} />
                )}
              </div>
            )}
            <div className={STYLES.textContent}>
              <Text
                element="p"
                namespace="dashboard"
                text={`${copyContext}.title`}
                variables={{ context: deliveryType }}
                variant="textRegular16"
                shouldScale={false}
                bold={shouldSee2Variants || isProductVariantFrequencySelected}
                margin={false}
              />
              <Text
                element="p"
                namespace="dashboard"
                text={`${copyContext}.description`}
                variables={{ context: deliveryType, productName: name }}
                variant="textRegular14"
                shouldScale={false}
                margin={false}
              />
              {deliveryType === 'recurring' && subscribeAndSaveable && (
                <div>
                  {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                  <Label
                    variant="discount"
                    text={{
                      text: `${copyContext}.label`,
                      namespace: 'dashboard',
                      shouldScale: false,
                      variables: { context: deliveryType }
                    }}
                  />
                </div>
              )}
              {deliveryType === 'on_demand' && nonCoreOrderDeliveryFee && (
                <div className={STYLES.deliveryFee}>
                  <Icon size={14} asset="vanFilled" />
                  <Text
                    text="extras.separate_delivery.get_it_sooner.delivery_fee"
                    namespace="dashboard"
                    variables={{
                      deliveryFee: formattedDeliveryFee
                    }}
                    colour="brandBlue500"
                    variant="textRegular14"
                    margin={false}
                    shouldScale={false}
                  />
                </div>
              )}
            </div>
          </div>
          <FrequencyVariantIcon deliveryType={deliveryType} />
        </button>
      </div>
      {status === 'paused' &&
        isProductVariantFrequencySelected &&
        deliveryType !== 'on_demand' && (
          <div className={STYLES.pausedCard}>
            <div>
              <Text
                element="p"
                namespace="dashboard"
                text={`${copyContext}.paused.title`}
                variant="textRegular16"
                colour="supportOrange400"
                bold
                shouldScale={false}
                margin={false}
              />
              <Text
                element="p"
                namespace="dashboard"
                text={`${copyContext}.paused.description`}
                variables={{ context: deliveryType }}
                variant="textRegular16"
                colour="supportOrange400"
                shouldScale={false}
                margin={false}
              />
            </div>
            <Button
              typography={{
                namespace: 'dashboard',
                text: `${copyContext}.paused.button`,
                variables: {
                  context: subscriptionResumeMutationLoading && 'loading'
                }
              }}
              onClick={handleResumeSubscription}
              disabled={subscriptionResumeMutationLoading}
              disableAnalytics
            />
          </div>
        )}
      <NotificationContainer autoClose={5000} />
    </div>
  )
}

export default ProductVariantFrequency
