// @noflow
import * as Sentry from '@sentry/browser'
import { Stripe, StripeElements } from '@stripe/stripe-js'

import { State } from '../../../PayOnOwnDevicePage'
import {
  LoginUrlProps,
  setSubmissionState
} from '../../../screens/PaymentScreen/PaymentScreen'
import { chargeCardBoxOne } from './chargeBoxOneStripe'

const buySubscriptionWithCard = ({
  stripe,
  elements,
  paymentPageState,
  amount,
  navigateToNextStep
}: {
  stripe: Stripe
  elements: StripeElements
  paymentPageState: State
  amount: number
  navigateToNextStep: (input: LoginUrlProps) => void
}): void => {
  const cardElement = elements?.getElement('card')
  const { data } = paymentPageState
  const { directSalesPendingSubscription } = data
  const { pendingSubscription } = directSalesPendingSubscription

  if (!cardElement) {
    Sentry.captureException('Could not find card element at checkout')
  } else {
    // Disable the Place my order button
    setSubmissionState({ type: 'loading' })
    const email = pendingSubscription?.user?.email || ''

    chargeCardBoxOne({
      stripe,
      cardElement,
      email,
      data: paymentPageState,
      amount,
      navigateToNextStep
    })
  }
}

export default buySubscriptionWithCard
