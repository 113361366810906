import { gql } from '@apollo/client'

export const SIGNUP_INFLUENCER_MUTATION = gql`
  mutation SignupInfluencerMutation($influencer: SignupInfluencerInput!) {
    signupInfluencer(influencerInput: $influencer) {
      influencer {
        token
      }
    }
  }
`
