import { useAccessManagement } from '@/context/accessManagement/accessManagement'
import { useOccasion } from '@/context/festiveTheme/festiveTheme'
import { ACCOUNT_ROUTES } from '@/routes'
import { useQuery } from '@apollo/client'
import isUndefined from 'lodash/isUndefined'
import React, { Fragment, useCallback, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { trackEvent } from '@/services/segment'

import useWindowSize from '@/hooks/useWindowSize'

import { XmasHat } from '@/components/elements/molecules/AccountNavigation/XmasHat'
import { BREAKPOINTS } from '@/components/pages/App/App'

import STYLES from './AccountNavigation.module.sass'

import { ACCOUNT_NAVIGATION_QUERY } from './queries/accountNavigationQuery'

import { accountNavigationQuery } from './queries/__generated__/accountNavigationQuery'

import { Expand } from '../../atoms/Animated/Animated'
import BoxOpen from '../../atoms/Icon/Icons/BoxOpen/BoxOpen'
import ButternutLogo from '../../atoms/Icon/Icons/ButternutLogo'
import House from '../../atoms/Icon/Icons/House/House'
import { Letter } from '../../atoms/Icon/Icons/Letter'
import { Logout } from '../../atoms/Icon/Icons/Logout'
import { PaymentMethods } from '../../atoms/Icon/Icons/PaymentMethods'
import Person from '../../atoms/Icon/Icons/Person/Person'
import Phone from '../../atoms/Icon/Icons/Phone/Phone'
import Image from '../../atoms/Image/Image'
import Interactive from '../../atoms/Interactive/Interactive'
import Text from '../../atoms/Text/Text'
import { Tab } from '../NavigationTabBar/Tab'

const AccountNavigation = (): JSX.Element => {
  const [showMenu, setShowMenu] = useState(false)
  const accessManagement = useAccessManagement()
  const menuRef = useRef<HTMLDivElement>(null)
  const iconRef = useRef<HTMLDivElement>(null)
  const { windowWidth } = useWindowSize()
  const navigate = useNavigate()
  const { xmas, festiveClass } = useOccasion()

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target as Node) &&
      iconRef.current &&
      !iconRef.current.contains(event.target as Node)
    ) {
      setShowMenu(false)
      window.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const close = useCallback(() => {
    setShowMenu(false)
    window.removeEventListener('mousedown', handleClickOutside)
  }, [handleClickOutside])

  const onClick = useCallback(() => {
    trackEvent('Account Navigation Opened', {
      component_identifier: 'account_navigation_button'
    })

    if (windowWidth < BREAKPOINTS.md) {
      navigate(ACCOUNT_ROUTES.myAccount)
    } else {
      setShowMenu(!showMenu)
      if (!showMenu) {
        window.addEventListener('mousedown', handleClickOutside)
      } else {
        window.removeEventListener('mousedown', handleClickOutside)
      }
    }
  }, [handleClickOutside, navigate, showMenu, windowWidth])

  const onPress = useCallback(
    (event) => {
      if (event.key === 'Enter' || event.key === ' ') {
        onClick()
      }
    },
    [onClick]
  )

  const onBlur = useCallback(
    (e) => {
      const currentTarget = e.currentTarget

      // Give browser time to focus the next element
      requestAnimationFrame(() => {
        // Check if the new focused element is a child of the original container
        if (!currentTarget.contains(document.activeElement)) close()
      })
    },
    [close]
  )

  const TABS = [
    {
      icon: ButternutLogo,
      tabKey: 'ambassador',
      pathname: '#',
      title: 'account_navigation.ambassador',
      onClick: () => (window.location.href = ACCOUNT_ROUTES.ambassador),
      hidden: !accessManagement?.canUserAccessRoute(ACCOUNT_ROUTES.ambassador)
    },
    {
      icon: ButternutLogo,
      tabKey: 'influencer',
      pathname: '#',
      title: 'account_navigation.influencer',
      onClick: () => (window.location.href = ACCOUNT_ROUTES.influencer),
      hidden: !accessManagement?.canUserAccessRoute(ACCOUNT_ROUTES.influencer)
    },
    {
      icon: Person,
      tabKey: 'personalDetails',
      pathname: ACCOUNT_ROUTES.personalDetails,
      title: 'account_navigation.account_details',
      hidden: !accessManagement?.canUserAccessRoute(
        ACCOUNT_ROUTES.personalDetails
      ),
      onClick: () => close()
    },
    {
      icon: PaymentMethods,
      tabKey: 'paymentMethods',
      pathname: ACCOUNT_ROUTES.paymentMethods,
      title: 'account_navigation.payment_methods',
      hidden: !accessManagement?.canUserAccessRoute(
        ACCOUNT_ROUTES.paymentMethods
      ),
      onClick: () => close()
    },
    {
      icon: BoxOpen,
      tabKey: 'orderhistory',
      pathname: ACCOUNT_ROUTES.orders,
      title: 'account_navigation.order_history',
      hidden: !accessManagement?.canUserAccessRoute(ACCOUNT_ROUTES.orders),
      onClick: () => close()
    },
    {
      icon: Phone,
      tabKey: 'help',
      pathname: '#',
      title: 'account_navigation.help',
      onClick: () => (window.location.href = '/contact')
    },
    {
      icon: Letter,
      tabKey: 'contactPreferences',
      pathname: ACCOUNT_ROUTES.contactPreferences,
      title: 'account_navigation.contact_preferences',
      hidden: !accessManagement?.canUserAccessRoute(
        ACCOUNT_ROUTES.contactPreferences
      ),
      onClick: () => close()
    },
    {
      icon: Logout,
      tabKey: 'logout',
      pathname: '#',
      title: 'account_navigation.logout',
      onClick: () => (window.location.href = '/users/sign_out')
    }
  ]

  const { data } = useQuery<accountNavigationQuery>(ACCOUNT_NAVIGATION_QUERY, {
    fetchPolicy: 'cache-first'
  })

  return (
    <Fragment>
      <div id="my-account" ref={iconRef}>
        <Interactive
          className={`${STYLES.container} ${
            !isUndefined(festiveClass) ? STYLES[festiveClass] : ''
          }`}
          role="button"
          onClick={onClick}
          onKeyUp={onPress}
          tabIndex={0}
          focusHighlight={false}
        >
          <div className={STYLES.iconWrapper}>
            {xmas && (
              <div className={STYLES.xmasHat}>
                <XmasHat />
              </div>
            )}
            <Image
              slug="account-icon"
              alt="Account"
              image={{
                height: 48,
                width: 48
              }}
              className={STYLES.icon}
            />
          </div>
          {data && (
            <Text
              text={data?.user.firstName}
              translate={false}
              margin={false}
            />
          )}
        </Interactive>
      </div>
      <div className={STYLES.menuPosition}>
        <Expand
          show={showMenu}
          origin="top"
          config={{ tension: 625, friction: 40 }}
        >
          <div
            className={`${STYLES.navigationList} ${
              showMenu ? STYLES.show : ''
            }`}
            role={'menu'}
            ref={menuRef}
            onBlur={onBlur}
          >
            {TABS.filter((tab) => !tab.hidden).map((tab) => (
              <Tab key={tab.tabKey} {...tab} />
            ))}
          </div>
        </Expand>
      </div>
    </Fragment>
  )
}

export default AccountNavigation
