// @noflow
import React from 'react'

import Text from '@/components/elements/atoms/Text/Text'

import STYLES from '../SurveyCard.module.sass'

import type { SurveyCardQuery_survey_questions_options as SurveyQuestionOption } from '../queries/__generated__/SurveyCardQuery'

import { sendAnswerAnalyticsEvent } from '../SurveyCard'
import type { AnswerableCardProps, BaseCardProps } from '../SurveyCard'

type RatingCardProps = BaseCardProps & AnswerableCardProps

const RatingQuestion = ({
  currentQuestion,
  setCurrentQuestionIndex,
  userId,
  surveyTitle,
  setAnswers,
  answers
}: RatingCardProps): JSX.Element => {
  const handleClick = React.useCallback(
    (e) => {
      setCurrentQuestionIndex(currentQuestion.order + 1)
      const choice = Number(e.currentTarget.getAttribute('data-rating'))
      const optionId = Number(e.currentTarget.getAttribute('data-option-id'))
      answers[currentQuestion.order] = {
        optionId,
        questionId: currentQuestion.id,
        value: choice
      }
      setAnswers([...answers])
      sendAnswerAnalyticsEvent({
        userId,
        surveyTitle,
        question: currentQuestion.text,
        answer: choice
      })
    },
    [
      setCurrentQuestionIndex,
      currentQuestion,
      userId,
      surveyTitle,
      setAnswers,
      answers
    ]
  )

  return (
    <div className={STYLES.surveyCard}>
      <Text
        text={currentQuestion.text}
        element={'h2'}
        variant="display20"
        colour={'brandBlue500'}
        margin
        align={'left'}
        translate={false}
      />
      <div className={STYLES.contentContainer}>
        <div className={STYLES.ratingsContainer}>
          <div>
            {currentQuestion.options.map((option: SurveyQuestionOption) => (
              <button
                className={STYLES.ratingButton}
                type="button"
                data-rating={option.text}
                data-option-id={option.id}
                key={option.text}
                onClick={handleClick}
              >
                <Text
                  text={option.text}
                  element={'p'}
                  variant="display24"
                  colour={'brandBlue500'}
                  translate={false}
                />
              </button>
            ))}
          </div>
          <div className={STYLES.optionsContainer}>
            <Text
              namespace="surveys"
              text="not_easy"
              element={'p'}
              variant="textRegular14"
              colour="brandWhite"
              margin
            />
            <Text
              namespace="surveys"
              text="very_easy"
              element={'p'}
              variant="textRegular14"
              colour="brandWhite"
              margin
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default RatingQuestion
