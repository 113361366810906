// @noflow
import { useReactiveVar } from '@apollo/client'
import { useCallback } from 'react'

import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'

import useWebviewHandler from '@/hooks/useWebviewHandler'

import { Analytics } from '@/components/analytics/Analytics'

import {
  entryFieldsState,
  resolutionConfirmationState,
  resolutionOfferingState,
  resolutionsState,
  selfResolutionPageState
} from '../SelfResolutionPage'
import type { Route as SelfResolutionRoute } from '../types/routes'

type SelfResolutionTracking = {
  trackEvent: (eventName: string, eventProperties?: eventProperties) => void
  categorySelected: (category: string, parentCategory?: string) => void
  stepLoaded: (stepName: string, stepType: string) => void
  dropOff: (pageDropped: string, buttonClicked: string) => void
}

type SelfResolutionsFlowAnalyticsProperties = {
  route: SelfResolutionRoute
  usingApp: boolean
  [key: string]: unknown
}

type eventProperties = {
  [key: string]: unknown
}

const useSelfResolutionTracking = (): SelfResolutionTracking => {
  const webviewHandler = useWebviewHandler()
  const selfResolutionPageStateData = useReactiveVar(selfResolutionPageState)
  const resolutionsData = useReactiveVar(resolutionsState)
  const entryFieldsData = useReactiveVar(entryFieldsState)
  const resolutionOfferingData = useReactiveVar(resolutionOfferingState)
  const resolutionConfirmationData = useReactiveVar(resolutionConfirmationState)

  const trackEvent = useCallback(
    (eventName: string, eventProperties?: eventProperties) => {
      const properties: SelfResolutionsFlowAnalyticsProperties = {
        route: window.location.pathname as SelfResolutionRoute,
        usingApp: webviewHandler.isWebview,
        ...eventProperties
      }
      if (selfResolutionPageStateData?.box?.price > -1) {
        const { currency } = countryCodeToLocaleCurrency(
          selfResolutionPageStateData.shippingCountryCode,
          selfResolutionPageStateData.preferredLanguage
        )
        properties.boxPrice = selfResolutionPageStateData.box.price / 100
        properties.currency = currency
      }
      if (selfResolutionPageStateData?.reportId) {
        properties.reportId = selfResolutionPageStateData?.reportId
      }
      if (selfResolutionPageStateData?.selectedCategory) {
        properties.selectedCategory =
          selfResolutionPageStateData?.selectedCategory
      }
      if (resolutionsData && resolutionsData.length > 0) {
        properties.quickHelpResolution = resolutionsData[0].key
      }
      if (entryFieldsData?.entryFields) {
        properties.entryFields = entryFieldsData.entryFields
      }
      if (
        resolutionOfferingData &&
        resolutionOfferingData.resolutions.length > 0
      ) {
        properties.resolutionOffering =
          resolutionOfferingData.resolutions[0].key
      }
      if (resolutionConfirmationData?.type) {
        properties.resolutionConfirmation = resolutionConfirmationData.type
      }

      Analytics.track('Self Resolutions ' + eventName, properties)
    },
    [
      selfResolutionPageStateData,
      resolutionsData,
      entryFieldsData?.entryFields,
      resolutionOfferingData,
      resolutionConfirmationData?.type,
      webviewHandler.isWebview
    ]
  )

  const categorySelected = useCallback(
    (category: string, parentCategory?: string) => {
      trackEvent('category selected', { category, parentCategory })
    },
    [trackEvent]
  )

  const stepLoaded = useCallback(
    (stepName: string, stepType: string) => {
      trackEvent('step loaded', { stepName, stepType })
    },
    [trackEvent]
  )

  const dropOff = useCallback(
    (pageDropped: string, buttonClicked: string) => {
      trackEvent('drop off', { pageDropped, buttonClicked })
    },
    [trackEvent]
  )

  return {
    trackEvent,
    categorySelected,
    stepLoaded,
    dropOff
  }
}

export default useSelfResolutionTracking
