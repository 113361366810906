// @flow

import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PostSignUpRoutes from '../postSignUpRoutes'
import Header from './../../shared/wizards/Header'
import * as ACTIONS from '../actions'
import * as THUNKS from '../thunks'
import DogNameDivider from './base_components/DogNameDivider'
import SelectablePillButton from '@/components/shared/elements/PillButton/SelectablePillButton'
import { traits } from '../../../shared_types/rails_models/dog_personality_trait'
import { traitsSelected } from '../reducers/dogProfilesReducer'
import Chevron from '../../shared/images/Chevron'
import BRAND_COLOURS from '../../../constants/BrandColours'
import { useTranslation } from 'react-i18next'

import type { Dispatch } from 'redux'
import type { State } from '../reducers'
import type { Trait } from '../../../shared_types/rails_models/dog_personality_trait'
import type { RailsModelID as ID } from '../../../shared_types/ids'
import type { State as DogProfileState } from '../reducers/dogProfilesReducer'
import type { Dog } from '../message_types'

type PresentationalProps = {|
  dogs: Array<Dog>,
  dogProfiles: DogProfileState,
  areTraitsSelected: boolean
|}

type ActionProps = {|
  traitsUpdate: () => void,
  toggleTrait: (ID, Trait) => void
|}

type Props =
  & PresentationalProps
  & ActionProps

const mapStateToProps = ({
  globalAttributes,
  dogProfiles
}: State): PresentationalProps => {
  const { dogs } = globalAttributes
  const areTraitsSelected = traitsSelected(dogProfiles)
  return {
    dogs,
    dogProfiles,
    areTraitsSelected
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => {
  const toggleTrait = (id: ID, trait: Trait): void => {
    dispatch(ACTIONS.toggleTrait(id, trait))
  }
  const traitsUpdate = (): void => {
    dispatch(ACTIONS.nextWizardStep())
    dispatch(THUNKS.traitsUpdate())
  }

  return { traitsUpdate, toggleTrait }
}

const QuirksAndTraits = ({
  dogs,
  dogProfiles,
  traitsUpdate,
  toggleTrait,
  areTraitsSelected
}: Props): React.Node => {
  const { t } = useTranslation('post_signup_wizard')
  const copyContext = 'quirks_and_traits'
  const toggleTraitMethods = dogs.reduce((acc: ({ [ID]: { [Trait]: () => void} }), dog: Dog): ({ [ID]: { [Trait]: () => void} }) => {
    acc[dog.id] = traits.reduce((traitsAcc: ({ [Trait]: () => void }), trait: Trait): ({ [Trait]: () => void }) => {
      traitsAcc[trait] = (): void => toggleTrait(dog.id, trait)
      return traitsAcc
    }, {})
    return acc
  }, {})
  return (
    <React.Fragment>
      <Header headerSubtitle={ t(`${copyContext}.subtitle`) }>
        <span
            // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: t(`${copyContext}.title`)
          }}
        />
      </Header>
      <div className='step-body'>
        <div className='step-body__content'>
          {
            dogs.map(({ name, id }: Dog): React.Node => (
              <React.Fragment key={name}>
                <DogNameDivider dividerText={ t(`${copyContext}.separator_text`, { dogName: name }) } />
                <div className='selectable-pill-wrapper'>
                  {
                    traits.map((trait: Trait): React.Node => (
                      <SelectablePillButton
                        action={toggleTraitMethods[id][trait]}
                        buttonText={ t(`${copyContext}.traits.${trait}`) }
                        key={trait}
                        selected={dogProfiles[id].traits[trait]}
                      />
                    ))
                  }
                </div>
              </React.Fragment>
            ))
          }
          <Link
            className={`next-button next-button${areTraitsSelected ? '' : '--disabled'}`}
            onClick={traitsUpdate}
            to={PostSignUpRoutes.UploadPhoto}
          >
            { t(`${copyContext}.button_text_next`) }
            <Chevron
              color={BRAND_COLOURS.brandWhite}
              rotation={0}
            />
          </Link>
        </div>
      </div>
    </React.Fragment>
  )
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuirksAndTraits)
