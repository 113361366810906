import { gql } from '@apollo/client'

import {
  BOX_ORDER_FRAGMENT,
  NON_CORE_ORDER_FRAGMENT
} from '@/components/pages/Dashboard/components/upcomingBoxes/queries/fragments'

const UPCOMING_BOX_QUERY = gql`
  ${BOX_ORDER_FRAGMENT}
  ${NON_CORE_ORDER_FRAGMENT}
  query upcomingBoxQuery($offset: Int!, $limit: Int!, $order: String) {
    shouldSeeDeliveryTracking: featureFlag(
      flag: "should_see_delivery_tracking_component"
      fallback: "false"
    )
    settings(names: ["carrier_preferences_enabled"]) {
      name
      value
    }
    user {
      id
      currentDeliveryAreaPreference {
        id
        name
        preferredCarrierService {
          id
          carrier {
            name
          }
        }
      }
      ordersByDate(offset: $offset, limit: $limit, order: $order) {
        ...upcomingBoxOrderFragment
        ...nonCoreUpcomingOrderFragment
      }
      subscription {
        id
        firstBox {
          id
        }
        plan {
          id
          pricePerDay
          nextUpcomingPrice {
            value
            effectiveFrom
          }
        }
      }
    }
  }
`

export { UPCOMING_BOX_QUERY }
