// @noflow
import {
  copyReferralLinkButtonWasClicked,
  shareOnFacebookButtonWasClicked,
  shareOnWhatsAppButtonWasClicked
} from '@/helpers/ReferralLinkShareHelper'
import type { PartialLocation } from '@/helpers/ReferralLinkShareHelper'
import type { Language } from '@/packs/localisation'
import Handlebars from 'handlebars'
import React, { useCallback } from 'react'

import { toLocalisedSentence } from '@/utils/StringHelper'

import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'
import Icon from '@/components/elements/atoms/Icon/Icon'
import Text from '@/components/elements/atoms/Text/Text'
import EmailShare from '@/components/elements/organisms/EmailShare/EmailShare'

import STYLES from './RafLinkAndShare.module.sass'

type Props = {
  referralLink: string
  copyReferralLinkCallback: (arg: string) => void
  partialLocation: PartialLocation
  dogNames: Array<string>
  preferredLanguage: Language
  whatsappMessageSingleDog: string
  whatsappMessageMultipleDogs: string
  userID: string
}

const RafLinkAndShare = ({
  referralLink,
  copyReferralLinkCallback,
  partialLocation,
  dogNames,
  preferredLanguage,
  whatsappMessageSingleDog,
  whatsappMessageMultipleDogs,
  userID
}: Props): JSX.Element => {
  const joinedDogNames = toLocalisedSentence({
    arr: dogNames,
    lng: preferredLanguage
  })

  const whatsappMessage = Handlebars.compile(
    dogNames.length === 1
      ? whatsappMessageSingleDog
      : whatsappMessageMultipleDogs
  )({
    dogNames: joinedDogNames,
    // eslint-disable-next-line i18next/no-literal-string
    referralLink: `${referralLink}?share_source%3Dwhatsapp`
  })
  const handleCopyButtonClick = useCallback(
    (
      e:
        | React.MouseEvent<HTMLButtonElement>
        | React.KeyboardEvent<HTMLButtonElement>
    ): void => {
      copyReferralLinkButtonWasClicked({
        e,
        referralLink,
        partialLocation,
        copyReferralLinkCallback
      })
    },
    [referralLink, partialLocation, copyReferralLinkCallback]
  )

  const handleWhatsAppButtonClick = useCallback((): void => {
    const newWindow = window.open(
      // eslint-disable-next-line i18next/no-literal-string
      `https://api.whatsapp.com/send?phone=&text=${whatsappMessage}`,
      '_blank',
      'noopener,noreferrer'
    )
    if (newWindow) newWindow.opener = null
    shareOnWhatsAppButtonWasClicked(partialLocation)
  }, [partialLocation, whatsappMessage])

  const handleFacebookButtonClick = useCallback((): void => {
    const newWindow = window.open(
      // eslint-disable-next-line i18next/no-literal-string
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
        // eslint-disable-next-line i18next/no-literal-string
        `${referralLink}?share_source=facebook`
      )}`,
      '_blank',
      'noopener,noreferrer'
    )
    if (newWindow) newWindow.opener = null
    shareOnFacebookButtonWasClicked(partialLocation)
  }, [partialLocation, referralLink])

  return (
    <div className={STYLES.container}>
      <Text
        text={'referrals.how_to_refer.share_link'}
        namespace="dashboard"
        variant="textRegular18"
        colour="brandBlue500"
        margin={false}
        align="left"
        bold
      />
      <Card border="dashed" squared shadow={false}>
        <div className={STYLES.rafLink}>
          <input
            className={`input-${referralLink} ${STYLES.textarea}`}
            value={`${referralLink}?share_source=copylink`}
            readOnly
            name="referral-link"
          />
          <Button
            typography={{
              text: 'raf.copy_btn',
              namespace: 'shared'
            }}
            onClick={handleCopyButtonClick}
            variant="secondary"
            disableAnalytics
          />
        </div>
      </Card>
      <div className={STYLES.buttonsContainer}>
        <Button
          typography={{
            namespace: 'shared',
            text: 'raf.facebook_btn'
          }}
          onClick={handleFacebookButtonClick}
          size="regular"
          icon={{
            position: 'left',
            component: (
              <Icon size={25} asset="facebook" accentColour="brandWhite" />
            )
          }}
          variant="facebook"
          disableAnalytics
        />
        <Button
          typography={{
            namespace: 'shared',
            text: 'raf.whatsapp_btn'
          }}
          onClick={handleWhatsAppButtonClick}
          size="regular"
          icon={{
            position: 'left',
            component: (
              <Icon size={25} asset="whatsApp" accentColour="brandWhite" />
            )
          }}
          variant="whatsApp"
          disableAnalytics
        />
      </div>
      <EmailShare userID={userID} partialLocation={partialLocation} />
    </div>
  )
}

export { Props }
export default RafLinkAndShare
