// @flow

import * as React from 'react'
import ErrorIcon from 'assets/images/icons/crosses/red-cross.svg'

/**
 * Docs for the HTMLInputElement:
 * github.com/facebook/flow/blob/422f13bee662472f9d44742edf1cee68b7caee15/lib/dom.js#L3239
 *
 * Docs for the HTMLLabelElement:
 * github.com/facebook/flow/blob/422f13bee662472f9d44742edf1cee68b7caee15/lib/dom.js#L3439
 */

type Props = {|
  autoComplete: $PropertyType<HTMLInputElement, 'autocomplete'>,
  className: $PropertyType<HTMLLabelElement, 'className'>,
  disabled: $PropertyType<HTMLInputElement, 'disabled'>,
  invalid: boolean,
  invalidityMessage: string,
  inputId: $PropertyType<HTMLInputElement, 'id'>,
  label: string,
  name: $PropertyType<HTMLInputElement, 'name'>,
  onBlur: (SyntheticEvent<HTMLInputElement>) => void,
  onChange: (SyntheticEvent<HTMLInputElement>) => void,
  required: $PropertyType<HTMLInputElement, 'required'>,
  type: $PropertyType<HTMLInputElement, 'type'>,
  value: $PropertyType<HTMLInputElement, 'value'>,
  placeholder: $PropertyType<HTMLInputElement, 'placeholder'>
|}

const Input = ({
  autoComplete,
  className,
  disabled,
  invalid,
  invalidityMessage,
  inputId,
  label,
  name,
  onBlur,
  onChange,
  required,
  type,
  value,
  placeholder
}: Props): React.Node => (
  <React.Fragment>
    <input
      aria-label={label}
      autoComplete={autoComplete}
      className={`${className} ${disabled ? 'disabled' : ''} ${invalid ? 'invalid' : ''}`}
      disabled={disabled}
      id={inputId}
      name={name}
      onBlur={disabled ? null : onBlur}
      onChange={disabled ? null : onChange}
      required={required}
      type={type}
      value={value}
      placeholder={placeholder}
    />
    {
      invalid && (
        <p>
          <img
            alt=''
            src={ErrorIcon}
          />
          { invalidityMessage }
        </p>
      )
    }
  </React.Fragment>
)

export default Input
