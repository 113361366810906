import { gql } from '@apollo/client'

const SHARE_MODAL_QUERY = gql`
  query ShareModalQuery {
    user {
      id
      preferredLanguage
      shippingCountryCode
      dogs {
        name
      }
      subscription {
        referralLink {
          referralLinkTypeName
          referralDiscountsTotalValue
          url
          referralDiscount {
            code
            discountCodeParts {
              value
              discountType
              discountBasis
              n
            }
          }
          referrerDiscount {
            applicableDiscountParts {
              value
              discountBasis
              n
              discountType
            }
          }
          referrals {
            refereeFirstName
            refereeDogNames
            refereeSignUpDate
          }
        }
      }
    }
    whatsAppSingleDog: copyEntry(slug: "whatsapp_single_dog") {
      text
    }
    whatsAppMultipleDogs: copyEntry(slug: "whatsapp_multiple_dogs") {
      text
    }
  }
`
export { SHARE_MODAL_QUERY }
