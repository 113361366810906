// @noflow
import { useMutation, useQuery } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import i18next from 'i18next'
import React, { useCallback, useEffect } from 'react'

import {
  capitaliseFirstLetter,
  pronounContext,
  toLocalisedSentence
} from '@/utils/StringHelper'

import BREAKPOINTS from '@/constants/Breakpoints'

import useLocalStorage from '@/hooks/useLocalStorage'
import useWindowSize from '@/hooks/useWindowSize'

// Component
import segmentTrack from '@/components/analytics/Analytics'
import Modal from '@/components/elements/atoms/Modal/Modal'
import Text from '@/components/elements/atoms/Text/Text'
import ProductUpsellableCard from '@/components/elements/molecules/ProductUpsellableCard'
import { UPCOMING_BOX_QUERY_VARIABLES } from '@/components/pages/Dashboard/components/upcomingBoxes/UpcomingBoxes'
import {
  orderProductsCreate,
  orderProductsCreateVariables
} from '@/components/pages/ExtrasPage/screens/ProductCollection/mutations/__generated__/orderProductsCreate'
import { ORDERS_PRODUCT_CREATE } from '@/components/pages/ExtrasPage/screens/ProductCollection/mutations/ordersProductCreate'

import STYLES from './SampleProductModalWithQuery.module.sass'

import {
  sampleProductUpsellableQuery_user_dogs as Dog,
  sampleProductUpsellableQuery_user_subscription_upsellableSampleProduct as UpsellableProduct,
  sampleProductUpsellableQuery as UpsellableQuery,
  sampleProductUpsellableQuery_user as User
} from './__generated__/sampleProductUpsellableQuery'
import { VariantDelivery } from '@/types'

import { SAMPLE_PRODUCT_UPSELLABLE_QUERY } from './queries'

type Props = {
  userId: User['id']
  shippingCountryCode: User['shippingCountryCode']
  preferredLanguage: User['preferredLanguage']
  dogs: User['dogs']
  upsellableSampleProduct: UpsellableProduct
}

const SampleProductModal = ({
  userId,
  shippingCountryCode,
  preferredLanguage,
  dogs,
  upsellableSampleProduct
}: Props): JSX.Element | null => {
  const [shouldShowModal, setShouldShowModal] = React.useState(true)
  const [hideModal, setHideModal] = useLocalStorage<string, boolean>(
    'sample-product-follow-up-modal-dismissed',
    false
  )
  const { windowWidth } = useWindowSize()

  const {
    id: productVariantId,
    name,
    grossPrice,
    recurringDeliveryType
  } = upsellableSampleProduct

  const [createOrderProduct, { loading }] = useMutation<
    orderProductsCreate,
    orderProductsCreateVariables
  >(ORDERS_PRODUCT_CREATE, {
    variables: {
      ...UPCOMING_BOX_QUERY_VARIABLES,
      userId,
      productVariantInput: {
        productVariantId,
        quantity: 1,
        deliveryType: VariantDelivery.recurring
      }
    },
    onCompleted: () => {
      segmentTrack('Shop: Additional Product Added', {
        type: 'Sample Upsell Modal',
        productCollectionSlug: upsellableSampleProduct.productCollection.slug,
        purchaseType: VariantDelivery.recurring,
        quantity: 1,
        sizeSlug: name
      })

      window.location.href = '/dashboard/subscription#extras'
    },
    onError: (error) => {
      Sentry.captureException(`Error adding product in SampleProductModal`, {
        extra: { error }
      })
    }
  })
  const urlParams = new URLSearchParams(window.location.search)
  const emailParamPresent = urlParams.get('sample-product-modal') === 'true'
  const displayModal =
    (shouldShowModal && !hideModal) || (emailParamPresent && shouldShowModal)

  const onClose = useCallback(() => {
    segmentTrack('Closed AP Upsell Card', { type: 'Sample Upsell Modal' })

    setShouldShowModal(false)
    setHideModal(true)
  }, [setHideModal, setShouldShowModal])

  useEffect(() => {
    if (displayModal) {
      segmentTrack('Seen AP Upsell Card ', {
        type: 'Sample Upsell Modal',
        productCollectionName: upsellableSampleProduct.productCollection.name
      })
    }
  }, [displayModal, upsellableSampleProduct.productCollection.name])

  if (!displayModal || !dogs) return null

  const joinedDogsNames = toLocalisedSentence({
    arr: dogs.map(({ name }) => capitaliseFirstLetter(name)),
    lng: 'en'
  })

  const dogGenders = dogs.map(({ gender }: Dog) => gender)
  const dogPronoun = pronounContext(dogGenders, i18next.language)

  const namespace = 'shared'
  const copyContext = 'box_breakdown.additional_product_upsell.modal'

  return (
    <Modal
      isModalOpen={displayModal}
      setOpenModal={onClose}
      width={600}
      padding={false}
      textAlign="left"
      bottomSticky={window.innerWidth < BREAKPOINTS.md}
    >
      <div className={STYLES.wrapper}>
        <Text
          element="h4"
          variant="brandBlue500"
          bold
          namespace={namespace}
          text={`${copyContext}.title`}
          variables={{
            dogsName: joinedDogsNames,
            context: dogPronoun,
            count: dogs.length
          }}
          align="left"
          shouldScale={false}
        />

        <Text
          variant="textRegular16"
          namespace={namespace}
          text={`${copyContext}.subtitle`}
          variables={{ context: dogPronoun }}
          margin={false}
          align="left"
          shouldScale={false}
        />

        <div className={STYLES.cardWrapper}>
          <ProductUpsellableCard
            orientation={
              windowWidth < BREAKPOINTS.md ? 'vertical' : 'horizontal'
            }
            variant="oneClick"
            name={name}
            productName={upsellableSampleProduct.productCollection.name}
            grossPrice={grossPrice}
            discountedPrice={recurringDeliveryType?.adjustedGrossPrice}
            src={upsellableSampleProduct.productCollection.thumbnail.src}
            onClick={createOrderProduct}
            disableButton={loading}
            deliveryType={VariantDelivery.recurring}
            shippingCountryCode={shippingCountryCode}
            preferredLanguage={preferredLanguage}
            shadow={false}
            showExploreExtrasButton
          />
        </div>
      </div>
    </Modal>
  )
}

const SampleProductModalWithQuery = (): JSX.Element | null => {
  const { data, error, loading } = useQuery<UpsellableQuery>(
    SAMPLE_PRODUCT_UPSELLABLE_QUERY
  )

  if (error) {
    Sentry.captureException(
      `Error fetching upsellable sample product in SampleProductModal`,
      { extra: { error } }
    )
    return null
  }

  if (loading || !data) {
    return null
  }

  const user = data.user

  const { id, shippingCountryCode, preferredLanguage, dogs, subscription } =
    user

  if (!subscription.upsellableSampleProduct) return null

  return (
    <SampleProductModal
      userId={id}
      shippingCountryCode={shippingCountryCode}
      preferredLanguage={preferredLanguage}
      dogs={dogs}
      upsellableSampleProduct={subscription.upsellableSampleProduct}
    />
  )
}

export default SampleProductModalWithQuery
