import Grid from '@material-ui/core/Grid'
import React, { Fragment, useCallback } from 'react'

import useWindowSize from '@/hooks/useWindowSize'

import DogsIllustration from 'assets/images/illustrations/dogs/puppy-and-great-dane-with-plus-sign.svg'

import { Button } from '@/components/elements/atoms/Button'
import Text from '@/components/elements/atoms/Text/Text'
import { BREAKPOINTS } from '@/components/templates/Base'

import STYLES from './AddDogSection.module.sass'

const Illustration = ({
  handleAddADog
}: {
  handleAddADog: () => void
}): JSX.Element => {
  return (
    <div
      onClick={handleAddADog}
      onKeyDown={handleAddADog}
      tabIndex={0}
      role="button"
    >
      <img alt="" src={DogsIllustration} className={STYLES.illustration} />
    </div>
  )
}

const AddDogSection = ({
  firstName,
  dogPassedReason
}: {
  firstName: string
  dogPassedReason: boolean
}): JSX.Element | null => {
  const { windowWidth } = useWindowSize()
  const namespace = 'dashboard'
  const handleAddADog = useCallback(() => {
    window.localStorage.removeItem('wizardState')
    window.localStorage.removeItem('pendingSubscription')
    window.location.href = encodeURI(
      '/wizard/new?analytics[link_clicked]=no-dog-page-add-a-dog-hero-CTA-wizard-flow'
    )
  }, [])

  return (
    <Fragment>
      <Grid item sm={12} md={7} className={STYLES.heroContent}>
        <Text
          namespace={namespace}
          text={dogPassedReason ? `no_dogs.title_passed_away` : `no_dogs.title`}
          variant="display24"
          margin={false}
          variables={{
            firstName
          }}
        />
        <Text
          namespace={namespace}
          text={
            dogPassedReason ? `no_dogs.subtitle_passed_away` : `no_dogs.body`
          }
          margin={false}
          bold={!dogPassedReason}
        />
        {!dogPassedReason && (
          <Text
            namespace={namespace}
            text={`no_dogs.subtitle_passed_away`}
            margin={false}
          />
        )}
        {windowWidth < BREAKPOINTS.md && (
          <Illustration handleAddADog={handleAddADog} />
        )}
        <Button
          typography={{
            namespace,
            text: 'no_dogs.cta'
          }}
          onClick={handleAddADog}
          fullWidth
          identifier="No Dogs Page - Clicked on Add Dog Profile CTA"
        />
      </Grid>
      {windowWidth >= BREAKPOINTS.md && (
        <Grid item sm={12} md={5}>
          <Illustration handleAddADog={handleAddADog} />
        </Grid>
      )}
    </Fragment>
  )
}

export default AddDogSection
