import { gql } from '@apollo/client'

const SEPA_PAYMENT_METHOD_ID_FROM_INTENT_SECRET = gql`
  query SepaPaymentMethodIdFromIntentSecret(
    $intentSecret: String!
    $intentType: IntentType!
  ) {
    sepaPaymentMethodIdFromIntentSecret(
      intentSecret: $intentSecret
      intentType: $intentType
    )
  }
`

export { SEPA_PAYMENT_METHOD_ID_FROM_INTENT_SECRET }
