// @noflow
import classNames from 'classnames'
import React, { KeyboardEvent, MouseEvent, ReactNode } from 'react'

import { AnalyticsProps } from '@/services/segment'

import Card, { CardProps } from '@/components/elements/atoms/Card/Card'
import FlatButton, {
  FlatButtonTypographyVariantProps
} from '@/components/elements/atoms/FlatButton/FlatButton'
import Icon, { Props as IconProps } from '@/components/elements/atoms/Icon/Icon'
import Text, { Props as TextProps } from '@/components/elements/atoms/Text/Text'

import STYLES from './IconCard.module.sass'

type Event =
  | MouseEvent<HTMLElement | MouseEvent>
  | KeyboardEvent<HTMLElement | KeyboardEvent>

type CardAction = Omit<FlatButtonTypographyVariantProps, 'disableAnalytics'>

type Subtext = Omit<TextProps, 'color'> & {
  icon?: string
}

type IconCardProps = {
  size?: 16 | 24 | 32 | number
  heading?: Omit<TextProps, 'color'>
  icon?: Omit<IconProps, 'size'> & { size?: number }
  subtext?: Subtext | Array<Subtext>
  button?: CardAction
  invert?: boolean
  showArrow?: boolean
  direction?: 'row' | 'column'
  rightImage?: JSX.Element | false | null
  children?: ReactNode
}

type ClickableIconCard = Omit<CardProps, 'children' | 'onClick'> &
  IconCardProps & {
    onClick: (event?: Event) => void
  } & AnalyticsProps
type NonClickableIconCard = Omit<CardProps, 'children' | 'onClick'> &
  IconCardProps & {
    onClick?: never
    identifier?: never
    screenIdentifier?: never
    disableAnalytics?: never
  }

type Props = ClickableIconCard | NonClickableIconCard

/**
 * A card with an icon to the left
 * @param size - the icon size
 * @param heading - the title copy
 * @param subtext - The line(s) of copy beneath the title
 * @param icon - the icon name
 * @param direction - stack the card content horizontally (default) or vertically
 * @param children
 * @param card - the card specific props
 * @param rightImage - React element for additional image
 * @constructor
 */
const IconCard = ({
  heading,
  subtext,
  icon,
  invert,
  children,
  showArrow = true,
  button,
  direction = 'row',
  rightImage = false,
  ...card
}: Props): JSX.Element => {
  const classes = classNames(STYLES.container, {
    [STYLES[direction]]: true,
    [STYLES.invert]: invert
  })

  return (
    <Card {...card}>
      <div className={classes}>
        {icon && (
          <div className={STYLES.icon}>
            <Icon size={icon?.size ?? 36} {...icon} />
          </div>
        )}
        <div
          className={`${STYLES.content} ${
            icon ? STYLES.alignLeft : STYLES.alignCenter
          }`}
        >
          {button && (
            <div className={STYLES.button}>
              <FlatButton
                text={button.text}
                onClick={button.onClick}
                identifier={button.identifier || 'icon_card'}
              />
            </div>
          )}
          {heading && (
            <div className={STYLES.heading}>
              <Text element="div" {...heading} variant="display16" />
            </div>
          )}
          {subtext && (
            <div className={STYLES.subtext}>
              {Array.isArray(subtext) ? (
                <>
                  {subtext.map((item, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className={STYLES.subtextItem} key={index}>
                      <Text
                        element="div"
                        variant="textRegular14"
                        colour="brandBlue400"
                        shouldScale={false}
                        {...item}
                      />
                      {item.icon && (
                        <img
                          src={item.icon}
                          alt=""
                          className={STYLES.subtextIcon}
                        />
                      )}
                    </div>
                  ))}
                </>
              ) : (
                <div className={STYLES.subtextItem}>
                  <Text
                    element="div"
                    variant="textRegular14"
                    colour="brandBlue400"
                    shouldScale={false}
                    {...subtext}
                  />
                  {subtext.icon && (
                    <img
                      src={subtext.icon}
                      alt=""
                      className={STYLES.subtextIcon}
                    />
                  )}
                </div>
              )}
            </div>
          )}

          <div>{children}</div>
        </div>

        {rightImage}

        {card.onClick && showArrow && (
          <div className={STYLES.arrow}>
            <Icon asset="chevron" size={16} accentColour="brandBlue500" />
          </div>
        )}
      </div>
    </Card>
  )
}

export { Props }
export default IconCard
