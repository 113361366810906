// @noflow
import { formatISO, isTomorrow, parseISO, subMinutes } from 'date-fns'

import type { DeliveryDates_defaultSelectedDate as GraphQLCalendarDate } from '../queries/__generated__/DeliveryDates'

type CalendarDate = Omit<GraphQLCalendarDate, '__typename'>

// For both next day and non next day deliveries we render
// the time in the front-end directly. The endOfLeadTime
// value we receive from the back end includes a 30 minuite
// buffer before the _actual_ cut off time where the order
// files are generated.
//
// If, for UX purposes we want to also include an additional
// buffer for users on Checkout - we can adjust the variables
// below.

const nextDayDeliveryBuffer = 0
const nonNextDayDeliveryBuffer = 0

const generateEndOfLeadTime = ({
  date,
  deliverable,
  endOfLeadTime
}: CalendarDate): string => {
  const isNextDayDelivery = isTomorrow(new Date(date)) && deliverable
  const deliveryTimeBuffer = isNextDayDelivery
    ? nextDayDeliveryBuffer
    : nonNextDayDeliveryBuffer

  return formatISO(subMinutes(parseISO(endOfLeadTime), deliveryTimeBuffer))
}

export { generateEndOfLeadTime }
