/* eslint-disable react/require-optimization */
/* eslint-disable react/no-arrow-function-lifecycle */
/* eslint-disable react/destructuring-assignment */
// @flow

import * as React from 'react'
import initI18n from '@/packs/localisation'
import i18next from 'i18next'

import { Analytics } from '@/components/analytics/Analytics'

import type { Ambassador } from '../message_types'

type Tab = {|
  title: string,
  tabTitle: string,
  hash: string,
  pathname: string,
  translation_key: string
|}

type Props = {|
  // eslint-disable-next-line react/no-unused-prop-types
  ambassador: Ambassador,
  ambassadorShopPath?: string,
  selectedIndex: number,
  tabs: Array<Tab>,
  shopTab?: Array<Tab>
|}

class SelectBar extends React.Component<Props> {
  buttonColor = (): string => {
    const colors = [
      'yellow-blue',
      'red-pink',
      'blue-white',
      'blue-white',
    ]

    return colors[this.props.selectedIndex] || colors[0]
  }

  handleTabClick = (tab: Tab ): void => {
    // eslint-disable-next-line i18next/no-literal-string
    Analytics.track(`Ambassador Dashboard ${tab.tabTitle === 'Shop' ? 'Shopify store' : tab.pathname } Tab Clicked`)
  }

  renderTab = (tab: Tab, index: number): React.Element<'div'> => {
    if (!i18next.isInitialized) initI18n(this.props.ambassador.preferred_language)

    const linkText = i18next.t(tab.translation_key)
    const classSuffix = index === this.props.selectedIndex ? 'hover' : 'color'
    // eslint-disable-next-line i18next/no-literal-string
    const buttonClass = `btn-${this.buttonColor()}-${classSuffix} ${tab.hash === 'shop' ? 'btn-shop' : ''}`
    const tabPathName = (tab.tabTitle === 'Shop') ? this.props.ambassadorShopPath : tab.pathname

    return (
      <div key={index}>
        <div className='mobile-display'>
          <div className='tab-item'>
            <a
              className={`btn ${buttonClass}`}
              href={tabPathName}
               // eslint-disable-next-line react/jsx-no-bind, flowtype/require-return-type
              onClick={() => { this.handleTabClick(tab) }}
            >
              { linkText }
            </a>
          </div>
        </div>
        <div className='desktop-and-tablet-display'>
          <div className='tab-item'>
            <a
              className={`btn ${buttonClass}`}
              href={tabPathName}
              // eslint-disable-next-line react/jsx-no-bind, flowtype/require-return-type
              onClick={() => { this.handleTabClick(tab) }}
            >
              { linkText }
            </a>
          </div>
        </div>
      </div>
    )
  }

  render = (): React.Element<'div'> => (
    <div className='selectbar'>
      {
        this.props.tabs.map(this.renderTab)
      }
      {
        this.props.shopTab && this.props.shopTab.map(this.renderTab)
      }
    </div>
  )
}

export type {
  Tab,
}

export default SelectBar
