// @noflow
import { useQuery } from '@apollo/client'
import React from 'react'

import PlanIcon from 'assets/images/icons/navigation/icon-box.svg'
import DogIcon from 'assets/images/icons/navigation/icon-dog-profile.svg'
import ExtrasIcon from 'assets/images/icons/navigation/icon-extras.svg'
import FreeFoodIcon from 'assets/images/icons/navigation/icon-heart.svg'
import HomeIcon from 'assets/images/icons/navigation/icon-house.svg'

import NavLink from '@/components/elements/atoms/NavLink/NavLink'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './BottomStickyMobileNav.module.sass'

import { BOTTOM_NAV_QUERY } from './queries/bottomNavQuery'

import type { BottomNavQuery } from './queries/__generated__/BottomNavQuery'

const BottomStickyMobileNav = (): JSX.Element | null => {
  const { loading, data, error } = useQuery<BottomNavQuery>(BOTTOM_NAV_QUERY)

  if (loading || !data) return null
  // eslint-disable-next-line i18next/no-literal-string
  if (error)
    throw new Error(`Error when BOTTOM_NAV_QUERY executes: ${error.message}`)

  const {
    user: { affiliateType, referralsSinceLastLoginCount, dogs }
  } = data

  const isAmbassador = affiliateType === 'ambassador'
  const isInfluencer = affiliateType === 'influencer'
  const numberOfDogs = dogs?.length
  const referralsCount = referralsSinceLastLoginCount || 0

  return (
    <div className={`${STYLES.stickyFooter}`} id="sticky-bottom-nav">
      <NavLink
        variant="stickyBottomNav"
        text={{
          text: 'dashboard',
          namespace: 'navigation'
        }}
        href="/dashboard"
        iconSrc={HomeIcon}
      />
      <NavLink
        variant="stickyBottomNav"
        text={{
          text: 'my_plan',
          namespace: 'navigation'
        }}
        href="/dashboard/subscription"
        iconSrc={PlanIcon}
      />
      <div className={STYLES.item}>
        <NavLink
          variant="stickyBottomNav"
          text={{
            text: 'extras',
            namespace: 'navigation'
          }}
          href="/dashboard/extras"
          iconSrc={ExtrasIcon}
        />
      </div>
      {isAmbassador || isInfluencer ? (
        <NavLink
          variant="stickyBottomNav"
          text={{
            text: numberOfDogs && numberOfDogs > 1 ? `my_dogs` : `my_dog`,
            namespace: 'navigation'
          }}
          href="/dashboard/my-dog"
          iconSrc={DogIcon}
        />
      ) : (
        <div className={STYLES.item}>
          {referralsCount > 0 ? (
            <div className={STYLES.numberNotification}>
              <Text
                text={`${referralsSinceLastLoginCount}`}
                translate={false}
                variant="textRegular16"
                colour="brandWhite"
                element="span"
                shouldScale={false}
              />
            </div>
          ) : (
            <div className={STYLES.genericNotification} />
          )}
          <NavLink
            variant="stickyBottomNav"
            text={{
              text: 'free_food',
              namespace: 'navigation'
            }}
            href="/dashboard/refer-a-friend"
            iconSrc={FreeFoodIcon}
          />
        </div>
      )}
    </div>
  )
}

export { BOTTOM_NAV_QUERY, BottomStickyMobileNav }
export default BottomStickyMobileNav
