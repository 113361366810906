// @noflow
import { makeVar, useReactiveVar } from '@apollo/client'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import useWebviewHanlder from '@/hooks/useWebviewHandler/useWebviewHandler'

import CustomerIssueManagementHeader from './components/CustomerIssueManagementHeader/CustomerIssueManagementHeader'
import CustomerIssueManagementLoading from './components/CustomerIssueManagementLoading/CustomerIssueManagementLoading'
import CustomerIssuesFAQ from './components/CustomerIssuesFAQ/CustomerIssuesFAQ'
import withApollo from '@/components/apollo/withApollo'

import STYLES from './CustomerIssueManagementPage.module.sass'

import { CustomerIssueEntryInputsCreate_response } from './mutations/__generated__/CustomerIssueEntryInputsCreate'
import { CustomerIssueReportCreate_response } from './mutations/__generated__/CustomerIssueReportCreate'
import { CustomerIssueManagementInitialDataQuery_user_reportable as Reportable } from './queries/__generated__/CustomerIssueManagementInitialDataQuery'
import { CustomerIssueManagementReportable, Language } from '@/types'

import CustomerIssueManagement from './CustomerIssueManagement'
import useCustomerIssueManagementTracking from './analytics'
import useCustomerIssueEntryInputsCreateMutation from './hooks/useCustomerIssueEntryInputsCreateMutation'
import useCustomerIssueInitialQuery from './hooks/useCustomerIssueInitialQuery'
import useCustomerIssueReportCreateMutation from './hooks/useCustomerIssueReportCreateMutation'
import useCustomerIssueResolutionOfferingMutations from './hooks/useCustomerIssueResolutionOfferingMutations'
import ContactCustomerLoveDetails from './screens/ContactCustomerLove/ContactCustomerLoveDetails'
import type { Route as CustomerIssueManagementRoute } from './types/routes'
import {
  CustomerIssueManagementState,
  CustomerIssueResolutionOfferingState,
  LoadingVariant,
  PageClasses,
  PageTitle
} from './types/types'

type Props = {
  language: Language
}

const queryParams = Object.fromEntries(
  new URLSearchParams(window.location.search)
)

const reportable = {
  id: queryParams.box_id || queryParams.reportable_id,
  type: queryParams.box_id
    ? CustomerIssueManagementReportable.Box
    : (queryParams.reportable_type as CustomerIssueManagementReportable),
  reportableFields: {
    __typename: 'Reportable' as Reportable['__typename'],
    potentialMissingItems: [],
    isoDeliveryDate: null,
    price: -1
  }
}

const pageTitleState = makeVar<PageTitle>('default')
const pageClassesState = makeVar<Array<PageClasses>>(['container'])
const pageHeaderShowBackButtonState = makeVar<boolean>(true)
const pageLoadingState = makeVar<boolean>(false)
const pageLoadingVariantState = makeVar<LoadingVariant>('default')
const routeState = makeVar<CustomerIssueManagementRoute | null>(null)
const errorState = makeVar<boolean>(false)
const errorTypeState = makeVar<string>('default')
const customerIssueManagementState = makeVar<CustomerIssueManagementState>({
  shouldSeeSelfResolutions: true,
  shippingCountryCode: 'GB',
  preferredLanguage: 'en',
  reportId: '',
  selectedCategory: null,
  eligibleCategories: [],
  reportable,
  reporterable: {
    id: '',
    type: null
  },
  inputValues: [],
  returnPath: '../dashboard',
  namespace: 'customer_issue_management_flow',
  // eslint-disable-next-line i18next/no-literal-string
  queryParams: `?reportable_id=${reportable.id}&reportable_type=${reportable.type}`,
  faqType: null,
  initialHistoryLength: 1,
  clDetailsLoaded: false,
  dogs: []
})
const customerIssueReportState = makeVar<CustomerIssueReportCreate_response>({
  __typename: 'CustomerIssueReportCreatePayload',
  categoryReasons: null,
  entryId: '',
  inputFields: null,
  reportId: '',
  resolutionOffering: null
})
const customerIssueEntryInputsState =
  makeVar<CustomerIssueEntryInputsCreate_response>({
    __typename: 'CustomerIssueEntryInputsCreatePayload',
    entryId: '',
    reportId: '',
    resolutionOffering: null
  })
const customerIssueResolutionOfferingState =
  makeVar<CustomerIssueResolutionOfferingState>({
    __typename: 'CustomerIssueResolutionOfferingAcceptPayload',
    applicationDetails: null,
    user: null
  })

const CustomerIssueManagementPage = (): JSX.Element => {
  const pageClassesData = useReactiveVar(pageClassesState)
  const customerIssueManagementData = useReactiveVar(
    customerIssueManagementState
  )
  const customerIssueManagementTracking = useCustomerIssueManagementTracking()
  const webviewHandler = useWebviewHanlder()
  const [initialised, setInitialised] = useState(false)
  const customerIssueReportCreateMutation =
    useCustomerIssueReportCreateMutation()
  const customerIssueEntryInputsCreateMutation =
    useCustomerIssueEntryInputsCreateMutation()
  const customerIssueResolutionOfferingMutations =
    useCustomerIssueResolutionOfferingMutations()

  const containerClassNames = useMemo(() => {
    return pageClassesData.map((pageClass) => STYLES[pageClass]).join(' ')
  }, [pageClassesData])

  const close = useCallback(() => {
    if (webviewHandler.isWebview) {
      webviewHandler.postMessage('GO-BACK')
    } else {
      window.location.href = customerIssueManagementData.returnPath
    }
  }, [customerIssueManagementData.returnPath, webviewHandler])

  useEffect(() => {
    if (initialised) {
      return
    }
    if (customerIssueManagementData.shouldSeeSelfResolutions) {
      window.location.href = '/contact'
    }
    if (document.referrer.indexOf('/self-resolution/') === -1) {
      const hostName = window.location.origin
      const referrer =
        document.referrer.indexOf(hostName) === 0
          ? new URL(document.referrer).pathname
          : document.referrer
      customerIssueManagementTracking.trackEvent('new session started', {
        entry: referrer
      })
      if (document.referrer.indexOf(hostName) === 0) {
        const url = new URL(document.referrer)
        customerIssueManagementState({
          ...customerIssueManagementData,
          returnPath: url.pathname
        })
      }
    }
    webviewHandler.pageLoaded()
    setInitialised(true)
  }, [
    initialised,
    customerIssueManagementTracking,
    customerIssueManagementData,
    webviewHandler
  ])

  useCustomerIssueInitialQuery(reportable)

  useEffect(() => {
    if (!customerIssueManagementData.shouldSeeSelfResolutions) {
      window.location.href = '/contact'
    }
  }, [customerIssueManagementData.shouldSeeSelfResolutions])

  return (
    <div className={containerClassNames}>
      <Router>
        <CustomerIssueManagementLoading>
          <div className={STYLES.contentWrapper}>
            <CustomerIssueManagementHeader close={close} />
            {reportable.id && reportable.type && (
              <CustomerIssueManagement
                submitCustomerIssueReportCreate={
                  customerIssueReportCreateMutation.submit
                }
                submitCustomerIssueEntryInputsCreate={
                  customerIssueEntryInputsCreateMutation.submit
                }
                customerIssueResolutionOfferingMutations={
                  customerIssueResolutionOfferingMutations
                }
              />
            )}
            {(!reportable.id || !reportable.type) && (
              <div className={STYLES.cardContent}>
                <ContactCustomerLoveDetails />
              </div>
            )}
            <CustomerIssuesFAQ />
          </div>
        </CustomerIssueManagementLoading>
      </Router>
    </div>
  )
}

export {
  CustomerIssueManagementPage,
  pageTitleState,
  pageClassesState,
  pageHeaderShowBackButtonState,
  pageLoadingState,
  pageLoadingVariantState,
  routeState,
  errorState,
  errorTypeState,
  customerIssueManagementState,
  customerIssueReportState,
  customerIssueEntryInputsState,
  customerIssueResolutionOfferingState,
  Props
}
export default withApollo(CustomerIssueManagementPage)
