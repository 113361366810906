// @noflow
import type { Dog } from '../types/types'

const ageToNearestWeeks = ({
  weeks,
  months
}: {
  weeks: number | null
  months: number | null
}): number => {
  const ageInWeeks = weeks === null ? 0 : weeks
  const ageInMonths = months === null ? 0 : months
  const monthsInWeeks = Math.round(ageInMonths * 4.345)
  const totalAgeInWeeks = monthsInWeeks + ageInWeeks
  return totalAgeInWeeks
}

const isPuppyFlow = (dogs: Array<Dog>): boolean => {
  const hasMultipleDogs = dogs.length > 1

  // The !! ensures a boolean is returned on finding a dog in the array which
  // fulfills the criteria, i.e. a puppy that is less than 13 weeks old
  const isPuppyAge = !!dogs.find(
    (dog: Dog) =>
      dog.ageStage === 'puppy' &&
      dog.age &&
      ageToNearestWeeks({ weeks: dog.age.weeks, months: dog.age.months }) < 13
  )

  if (!hasMultipleDogs && isPuppyAge) {
    return true
  } else return false
}

export { ageToNearestWeeks, isPuppyFlow }
