// @flow

import * as React from 'react'
import { subMinutes } from 'date-fns'
import { connect } from 'react-redux'
import times from 'lodash/times'
import * as Sentry from '@sentry/browser'

import * as THUNKS from '../../thunks'
import { getCountryFlag } from '@/components/shared/geo_ip_widget/localisationHelpers'

import type { ShippingCountry } from '@/shared_types/rails_models/shipping_countries'

import type { Dispatch } from 'redux'

import type { State } from '../../index'
import type { ShippingDetails as ShippingDetailsType } from './../../message_types'

type PresentationalProps = {|
  isFetching: boolean,
  userId: number,
  shippingCountries: $PropertyType<ShippingDetailsType, 'shippingCountries'>,
  shippingCountryCode: $PropertyType<ShippingDetailsType, 'shippingCountryCode'>,
  deliveryAreaName: $PropertyType<ShippingDetailsType, 'deliveryAreaName'>,
  regularLeadTimeInHours: $PropertyType<ShippingDetailsType, 'regularLeadTimeInHours'>,
  adHocLeadTimeInHours: $PropertyType<ShippingDetailsType, 'adHocLeadTimeInHours'>,
  regularDeliverableDates: $PropertyType<ShippingDetailsType, 'regularDeliverableDates'>,
  adHocDeliverableDates: $PropertyType<ShippingDetailsType, 'adHocDeliverableDates'>,
  noAddress: $PropertyType<ShippingDetailsType, 'noAddress'>,
  isEligibleForCarrierPreference: $PropertyType<ShippingDetailsType, 'isEligibleForCarrierPreference'>,
  carrierPreference: $PropertyType<ShippingDetailsType, 'carrierPreference'>,
  deliveryAreasUserId: $PropertyType<ShippingDetailsType, 'deliveryAreasUserId'>,
  canManageDeliveryAreasUser: $PropertyType<ShippingDetailsType, 'canManageDeliveryAreasUser'>,
|}

type ActionProps = {|
  getShippingDetails: ((number) => void)
|}

type Props =
  & PresentationalProps
  & ActionProps

const mapStateToProps = (state: State): PresentationalProps => {
  const { shippingDetails, selectedUserIds, fetchingStatuses } = state
  const {
    shippingCountries,
    shippingCountryCode,
    deliveryAreaName,
    regularLeadTimeInHours,
    adHocLeadTimeInHours,
    regularDeliverableDates,
    adHocDeliverableDates,
    noAddress,
    isEligibleForCarrierPreference,
    carrierPreference,
    deliveryAreasUserId,
    canManageDeliveryAreasUser
  } = shippingDetails
  const { userId } = selectedUserIds
  const { shippingDetailsStatus } = fetchingStatuses

  return {
    shippingCountries,
    shippingCountryCode,
    deliveryAreaName,
    regularLeadTimeInHours,
    adHocLeadTimeInHours,
    regularDeliverableDates,
    adHocDeliverableDates,
    noAddress,
    isEligibleForCarrierPreference,
    carrierPreference,
    deliveryAreasUserId,
    canManageDeliveryAreasUser,
    userId,
    isFetching: shippingDetailsStatus
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => {
  const getShippingDetails = (userId: number): void => {
    dispatch(THUNKS.getShippingDetails(userId))
  }
  return { getShippingDetails }
}

const timeZoneFor = (shippingCountryCode: $PropertyType<ShippingDetailsType, 'shippingCountryCode'>): string => {
  switch (shippingCountryCode) {
    case 'GB': {
      return 'Europe/London'
    }
    case 'IE': {
      return 'Europe/Dublin'
    }
    case 'NI': {
      return 'Europe/Belfast'
    }
    case 'NL': {
      return 'Europe/Amsterdam'
    }
    case 'BE': {
      return 'Europe/Brussels'
    }
    case 'DE': {
      return 'Europe/Berlin'
    }
    case 'PL': {
      return 'Europe/Warsaw'
    }
    default: {
      throw new Error(`${shippingCountryCode} is not a handled CountryCode`)
    }
  }
}

const shippingCountryNameFor = (
  shippingCountries: $PropertyType<ShippingDetailsType, 'shippingCountries'>,
  shippingCountryCode: $PropertyType<ShippingDetailsType, 'shippingCountryCode'>,
): string => {
  const shippingCountry = shippingCountries.find((shippingCountry: ShippingCountry): boolean => {
    return shippingCountry.code === shippingCountryCode
  })

  if (!shippingCountry) {
    Sentry.captureException(`ShippingCountryCode ${shippingCountryCode} not found`)
    return 'Unidentified Shipping Country'
  }

  return shippingCountry.name
}

const toLocaleTimeString = (date: Date, shippingCountryCode: $PropertyType<ShippingDetailsType, 'shippingCountryCode'>): string => {
  const timeZone = timeZoneFor(shippingCountryCode)
  return date.toLocaleTimeString(
    'en-GB',
    {
      timeZone: timeZone,
      timeZoneName: 'short',
      hour: 'numeric',
      minute: 'numeric'
    }
  )
}

const ShippingDetails = ({
  shippingCountries,
  shippingCountryCode,
  deliveryAreaName,
  regularLeadTimeInHours,
  adHocLeadTimeInHours,
  regularDeliverableDates,
  adHocDeliverableDates,
  isFetching,
  getShippingDetails,
  userId,
  noAddress,
  isEligibleForCarrierPreference,
  carrierPreference,
  deliveryAreasUserId,
  canManageDeliveryAreasUser
}: Props): React.Node => {
  React.useEffect((): void => {
    getShippingDetails(userId)
  }, [getShippingDetails, userId])
  const date = new Date()
  const startOfTodayInUTC = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0,
    0,
    0
  )

  const regularCutOffTime = toLocaleTimeString(
    subMinutes(
      startOfTodayInUTC,
      regularLeadTimeInHours * 60
    ),
    shippingCountryCode
  )

  const adHocCutOffTime = toLocaleTimeString(subMinutes(
    startOfTodayInUTC,
    adHocLeadTimeInHours * 60
  ), shippingCountryCode)

  const timeNowInTimeZone = toLocaleTimeString(
    date,
    shippingCountryCode
  )

  return (
    <div className={`contact-preferences card__container card__${isFetching ? 'loading' : 'loaded'}`}>
      <div className='card__header__container'>
        <h3 className='card__header'>
          { `Shipping Details` }
        </h3>
        { !isFetching && !noAddress && (
          <img
            src={getCountryFlag(shippingCountryCode)}
            alt='A flag indicating the country the customer is in'
          />
        ) }
      </div>
      <div className='card__body'>
        {
          isFetching && (
            <div className="loading-ring">
              {
                times(4).map((index: number): React.Element<'span'> => (
                  <span key={index} />
                ))
              }
            </div>
          )
        }
        {
          noAddress ? (
            <p className="no-results">
              { 'No address' }
            </p>
          ) : (
            <React.Fragment>
              <div className='card__row'>
                <p className='card__row__title'>
                  { 'Country' }
                </p>
                <p>
                  { shippingCountryNameFor(shippingCountries, shippingCountryCode) }
                </p>
              </div>
              <div className='card__row'>
                <p className='card__row__title'>
                  { 'Delivery Area Name' }
                </p>
                <p>
                  { deliveryAreaName }
                </p>
              </div>
              <div className='card__row'>
                <p className='card__row__title'>
                  { 'Regular lead time / Ad-hoc lead time' }
                </p>
                <p>
                  { `${regularLeadTimeInHours} hours / ${adHocLeadTimeInHours} hours` }
                </p>
              </div>
              <div className='card__row'>
                <p className='card__row__title'>
                  { 'Local regular cutoff time / Local ad-hoc cutoff time' }
                </p>
                <p>
                  { `${regularCutOffTime} / ${adHocCutOffTime}` }
                </p>
              </div>
              <div className='card__row'>
                <p className='card__row__title'>
                  { 'Customer Local Time (Refresh to see time now)' }
                </p>
                <p>
                  { `${timeNowInTimeZone}` }
                </p>
              </div>
              <div className='card__row'>
                <p className='card__row__title'>
                  { 'Regular Deliverable Dates' }
                </p>
                <p>
                  { regularDeliverableDates }
                </p>
              </div>
              <div className='card__row'>
                <p className='card__row__title'>
                  { 'Ad Hoc Deliverable Dates' }
                </p>
                <p>
                  { adHocDeliverableDates }
                </p>
              </div>
              {
                isEligibleForCarrierPreference ? (
                  <div className='card__row'>
                    <p className='card__row__title'>
                      { 'Preferred Carrier' }
                    </p>
                    {
                      carrierPreference
                        ? (
                          <div>
                            <div className='card__row'>
                              <span className='label label--red'>
                                { carrierPreference }
                              </span>
                            </div>
                            {
                              canManageDeliveryAreasUser && (
                                <a
                                  className='card__button button--blue'
                                  href={`/admin/delivery_areas_users/${deliveryAreasUserId}/edit?user_id=${userId}`}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                >
                                  { 'Edit carrier preference' }
                                </a>
                              )
                            }
                          </div>
                        )
                        : (
                          <div>
                            <div className='card__row'>
                              <span className='label label--grey'>
                                { 'No' }
                              </span>
                            </div>
                            { canManageDeliveryAreasUser && (
                              <a
                                className='card__button button--blue'
                                href={`/admin/delivery_areas_users/new?user_id=${userId}`}
                                target='_blank'
                                rel='noopener noreferrer'
                              >
                                { 'Create carrier preference' }
                              </a>
                            )}
                          </div>
                        )
                    }
                  </div>
                ) : (<div />)
              }
            </React.Fragment>
          )
        }
      </div>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShippingDetails)
