// @noflow
import React from 'react'

// Styles
import STYLES from './ExtrasShop.module.sass'

// Types
import { ICON_COLOURS, IconProps } from '../index'

type Props = {
  showShadow?: boolean
} & IconProps

const ExtrasShop = ({
  height,
  accentColour,
  width,
  showShadow
}: Props): JSX.Element => {
  return (
    <div className={STYLES.wrapper}>
      <svg
        width={width ?? 28}
        height={height ?? 25}
        viewBox="0 0 28 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={STYLES.icon}
      >
        <path
          d="M14.528 23.547c.28 0 9.094.122 9.339 0 .296-.148.307-3.896.332-4.181.055-.453-.007-.895-.007-1.347 0-.233-.28-6.31-.28-6.544v-.894c0-.17.059-.567-.093-.67M14.5 23.547c-.28 0-10.372.122-10.617 0-.296-.148-.307-3.896-.332-4.181-.056-.453.007-.895.007-1.347 0-.233.28-6.31.28-6.544v-.894c0-.17-.059-.567.094-.67M8.745 1.124H4.49c-.083.042-.117.202-.157.29a2.012 2.012 0 0 1-.195.352c-.494.714-.982 1.46-1.537 2.14-.392.48-.81.935-1.084 1.484-.072.145-.175.288-.258.432-.038.067-.185.176-.041.115.126-.053.433-.05.57-.05h.447c.38 0 .727-.04 1.097-.085.18-.023.332-.08.518-.08h.681c.625 0 1.27-.051 1.891.018 1.443.159 2.871.312 4.323.312.71 0 1.42-.04 2.13-.04.454 0 .9-.042 1.347-.042.113 0 .242-.07.35-.04.102.03.193.04.312.04 1.454 0 2.93.124 4.393.124h.618c.184 0 .355.083.541.083.264 0 .557.036.817-.01.15-.026.29-.032.442-.032.085 0 .033.065.087.08.306.088.74.003 1.063.003.841 0 1.707-.095 2.54-.207.263-.035.52-.041.785-.041.036 0 .186.023.209 0 .013-.013-.155-.248-.17-.272-.16-.25-.237-.575-.442-.796-.208-.224-.352-.508-.532-.755-.54-.743-1.055-1.52-1.654-2.194a2.285 2.285 0 0 1-.258-.373c-.04-.072-.192-.292-.262-.331-.213-.123-.458-.095-.731-.074-.946.073-1.905.032-2.853.032-.555 0-1.102-.124-1.652-.124h-1.252c-.989 0-1.98-.042-2.97-.042-.618 0-1.209.166-1.825.166-.386 0-.77-.033-1.15-.041-.629-.014-1.254-.042-1.884-.042ZM1.012 6.26c0 .414-.058.877.083 1.265.157.431.364.876.644 1.235.276.356 1.003.461 1.403.564.292.075.572.166.872.166.306 0 .602.071.912.002.256-.057.482-.194.724-.301.466-.207.831-.445 1.11-.916.077-.132.206-.283.257-.423.066-.18.28-.417.29-.599.01-.185.08-.707 0-.828M7.514 7.833c0 .898.69 1.512 1.504 1.675.501.1.975.272 1.487.272h.694c.239 0 .473-.094.704-.127.339-.048.617-.319.935-.41.15-.042.24-.195.33-.312a5.44 5.44 0 0 0 .424-.642c.32-.576.175-1.38.175-2.03"
          stroke={ICON_COLOURS[accentColour]}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M13.85 8.206c.019.152.385.414.488.506.154.138.416.381.63.405.271.03.54.214.805.28.483.121 1.042.217 1.535.217.39 0 .745.002 1.118-.08.335-.075.789-.233 1.038-.481.458-.459 1.136-.992 1.136-1.696 0-.359.07-.803-.041-1.139M20.6 8.496c.333.333.654.768 1.11.943.36.139.737.3 1.127.3.25 0 .517.04.787.04a2.83 2.83 0 0 0 1.118-.239c.326-.137.548-.309.828-.515.334-.246.447-.635.548-1.026.141-.55.115-1.124.115-1.698M10.145 23.228c0-2.41-.054-4.809-.054-7.218 0-.212-.079-.658.027-.848.143-.259.604-.107.896-.22.707-.276 1.488-.242 2.24-.242h2.486c.266 0 1.25-.148 1.25.196V22.805c0 .214.119.803-.164.803"
          stroke={ICON_COLOURS[accentColour]}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
      {showShadow && (
        <svg
          width="30"
          height="26"
          viewBox="0 0 30 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={STYLES.shadow}
        >
          <path
            d="M2.9999 5.49999C1.3999 7.49999 2.33323 8.99999 2.9999 9.49999C2.9999 10.3 4.66663 10.8333 5.5 11C5.16667 15.1667 4.7 23.8 5.5 25C6.5 26.5 25 25.5 26.5 25C28 24.5 25.5 10.5 26.5 11C27.5 11.5 29 9.49999 29.5 7.5C30 5.50001 26 2.49999 25.5 1.49999C25 0.499986 9 1.49999 7 1.49999C5 1.49999 4.9999 2.99999 2.9999 5.49999Z"
            fill="#FFE180"
          />
        </svg>
      )}
    </div>
  )
}

export type { Props }

export { ExtrasShop }
