// @noflow
import { Stripe, StripeCardElement } from '@stripe/stripe-js'

import { PaymentMethodType, PaymentMethodable, Provider } from '@/types'

import { State } from '../../../PayOnOwnDevicePage'
import formatSubscriptionData from '../../../helpers/buiildSubscriptionData'
import { validate } from '../../../helpers/inputs'
import { LoginUrlProps } from '../../../screens/PaymentScreen/PaymentScreen'
import { submitSubscription } from '../../submitSubscription'
import stripeMutationMethods from '../sharedStripeMutationMethods'

export const chargeCardBoxOne = ({
  stripe,
  cardElement,
  data,
  email,
  amount,
  navigateToNextStep
}: {
  stripe: Stripe
  cardElement: StripeCardElement
  data: State
  email: string
  amount: number
  navigateToNextStep: (input: LoginUrlProps) => void
}): void => {
  const { stripeCustomerCreate, stripeSetupIntentCreate, raiseBannerError } =
    stripeMutationMethods()
  // NOTE: updates the discount if found and continues the execution
  stripeCustomerCreate({
    paymentMethodType: 'card',
    email: email,
    amount: amount
  })
    .then((res) => {
      if (res?.errors) {
        raiseBannerError(res.errors[0])
      }
      if (res?.data?.directSalesStripeCustomerCreate) {
        const clientSecret =
          res.data.directSalesStripeCustomerCreate.paymentIntentId
        const paymentIntentId = clientSecret.split('_secret')[0]

        if (clientSecret) {
          const formattedSubData = formatSubscriptionData({
            targetFirstDeliveryDate: data.targetFirstDeliveryDate,
            subscriptionData: data.data,
            provider: Provider.stripe,
            paymentMethodType: PaymentMethodable.CreditCard,
            paymentIntentId: paymentIntentId,
            supportedMarketingMethodPurposes: data.marketingPreferences
          })

          const user =
            data.data.directSalesPendingSubscription.pendingSubscription.user

          stripeSetupIntentCreate({
            authenticationType: 'credit_card',
            formattedSubscriptionData: formattedSubData,
            paymentIntentId: paymentIntentId,
            firstName: user.firstName,
            lastName: user.lastName || '',
            paymentMethodType: PaymentMethodType.credit_card
          })
          stripe
            .confirmCardPayment(clientSecret, {
              payment_method: {
                card: cardElement
              }
            })
            .then((res) => {
              if (res.error) {
                raiseBannerError(res.error)
              }
              if (
                res.paymentIntent?.payment_method &&
                typeof res.paymentIntent?.payment_method === 'string'
              ) {
                const paymentMethodId = validate(
                  res.paymentIntent.payment_method,
                  'paymentMethodId',
                  data.data.directSalesPendingSubscription.address
                    .shippingCountry.code
                )
                submitSubscription({
                  data: formattedSubData,
                  paymentMethodId,
                  navigateToNextStep
                })
              }
            })
        }
      }
    })
    .catch((error) => {
      raiseBannerError(error)
    })
}
