// @noflow
import { useQuery } from '@apollo/client'
import type {
  DocumentNode,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode
} from '@apollo/client'
import { useCallback, useEffect } from 'react'

type Query<TData, TVariables> =
  | DocumentNode
  | TypedDocumentNode<TData, TVariables>
type Options<TData, TVariables> = QueryHookOptions<TData, TVariables>

type ExtraOptions = {
  disablePolling?: boolean
  stopPollingOnUnmount?: boolean
  pollIntervalType?: 'seconds' | 'minutes' | 'hours'
}

type QueryWithPolling<TData, TVariables> = {
  startPolling: () => void
  stopPolling: () => void
} & QueryResult<TData, TVariables>

const DEFAULT_POLL_INTERVAL = 10
const DEFAULT_POLL_INTERVAL_TYPE = 'minutes'

/**
 * useQueryWithPolling hook
 *
 * Use this hook to make a query with polling.
 *
 * Query - The query to be made as normal.
 *
 * Options - The options to be passed to the query as normal. Please note that the `pollInterval`
 * has to be provided if you'd like to override the default polling interval.
 * Defaults to 10 minutes if the `pollIntervalType` is not provided as well.
 *
 * ExtraOptions - Extra arguments to be passed to the hook
 *  - `disablePolling` - use this to disable polling, useful for when you want to start polling later
 *    (e.g. when a user clicks a button or on a specific date).
 *    Defaults to `false`.
 *
 *  - `stopPollingOnUnmount` - use this to stop polling when the component unmounts.
 *    Useful for when you want to stop polling when the user navigates away from the page.
 *    Defaults to `false`.
 *
 *  - `pollIntervalType` - use this to set the poll interval type (seconds, minutes, hours).
 *    Defaults to `minutes`.
 *
 * @param {Query<TData, TVariables>} query - The query to be made as normal
 * @param {Options<TData, TVariables>} [options] - The options to be passed to the query as normal
 * @param {ExtraOptions} [extraOptions] - Extra arguments
 * @returns QueryWithPolling - Object with startPolling, stopPolling, and rest query response
 */
const useQueryWithPolling = <TData, TVariables>(
  query: Query<TData, TVariables>,
  options?: Options<TData, TVariables>,
  extraOptions?: ExtraOptions
): QueryWithPolling<TData, TVariables> => {
  const {
    disablePolling = false,
    stopPollingOnUnmount = false,
    pollIntervalType = DEFAULT_POLL_INTERVAL_TYPE
  } = extraOptions ?? {}

  const { startPolling, stopPolling, ...restQueryResponse } = useQuery<
    TData,
    TVariables
  >(query, {
    ...options,
    pollInterval: disablePolling ? 0 : options?.pollInterval
  })

  const pollIntervalInMilliseconds = ((): number => {
    const interval = options?.pollInterval ?? DEFAULT_POLL_INTERVAL

    switch (pollIntervalType) {
      case 'seconds': {
        return interval * 1000
      }
      case 'minutes': {
        return interval * 60000
      }
      case 'hours': {
        return interval * 3600000
      }
    }
  })()

  const handleStartPolling = useCallback((): void => {
    startPolling(pollIntervalInMilliseconds)
  }, [startPolling, pollIntervalInMilliseconds])

  useEffect(() => {
    if (!disablePolling) {
      // Start polling when the component mounts unless conditionally disabled
      handleStartPolling()

      if (stopPollingOnUnmount) {
        // Stop polling when the component unmounts unless conditionally disabled
        return () => {
          stopPolling()
        }
      }
    }
  }, [
    handleStartPolling,
    stopPolling,
    pollIntervalInMilliseconds,
    disablePolling,
    stopPollingOnUnmount
  ])

  return { startPolling: handleStartPolling, stopPolling, ...restQueryResponse }
}

export { useQueryWithPolling }
