// @noflow
import type { TFunction } from 'react-i18next'

import popularBreeds from '@/constants/PopularBreeds'

// Data
import Beans from 'assets/images/illustrations/ingredients/beans--brown.svg'
import Broccoli from 'assets/images/illustrations/ingredients/broccoli--brown.svg'
import Carrots from 'assets/images/illustrations/ingredients/carrots--brown.svg'
import Fish from 'assets/images/illustrations/ingredients/fish--brown.svg'
import SweetPotato from 'assets/images/illustrations/ingredients/sweet-potato--brown.svg'
import Wheat from 'assets/images/illustrations/ingredients/wheat--brown.svg'

import type { Props as WizardFooterProps } from '@/components/elements/organisms/WizardFooter/WizardFooter'
import WizardRoutes from '@/components/pages/SignupWizardPage/types/routes'

import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'
import { ensureNever } from '@/typescript/utils'

import { InitialState as WizardState } from '../SignupWizardPage'
import type { Route as WizardRoute } from '../types/routes'

type RouteToFreshFactFooter = {
  route: WizardRoute
  t: TFunction
  wizardState: WizardState
  shippingCountryCode: CountryCode
}

const routeToFreshFactFooter = ({
  route,
  t,
  wizardState,
  shippingCountryCode
}: Omit<RouteToFreshFactFooter, 'version'>): WizardFooterProps => {
  const copyContext = 'fresh_fact_footer'

  const { dogs, user } = wizardState
  const { goalSegment } = user

  const hasPuppy = dogs.some((dog) => dog.ageStage === 'puppy')
  const hasSenior = dogs.some((dog) => dog.ageStage === 'senior')
  const hasFussyEater = dogs.some(
    (dog) => dog.eaterType === 'canBeFussy' || dog.eaterType === 'veryFussy'
  )

  const isIrish = shippingCountryCode === 'IE'

  switch (route) {
    case WizardRoutes.Name: {
      return {
        variant: 'freshFacts',
        title: t(`${copyContext}.title`),
        subtitle: t(`${copyContext}.name_step.sub_title`),
        illustration: {
          img: Carrots,
          imgAlt: t(`${copyContext}.name_step.img_alt`),
          imgPosition: 'right'
        }
      }
    }

    case WizardRoutes.Gender: {
      return {
        variant: 'freshFacts',
        title: t(`${copyContext}.title`),
        subtitle: t(
          `${copyContext}.gender_step.sub_title${isIrish ? '_ie' : ''}`
        ),
        illustration: {
          img: Fish,
          imgAlt: t(`${copyContext}.gender_step.img_alt`),
          imgPosition: 'right'
        }
      }
    }

    case WizardRoutes.Age: {
      const baseFact: WizardFooterProps = {
        variant: 'freshFacts',
        title: t(`${copyContext}.title`),
        subtitle: t(`${copyContext}.age_step.sub_title`),
        illustration: {
          img: Broccoli,
          imgAlt: t(`${copyContext}.age_step.img_alt`),
          imgPosition: 'right'
        }
      }

      if (hasPuppy) {
        return {
          ...baseFact,
          subtitle: t(`${copyContext}.age_step.puppy_sub_title`)
        }
      } else if (hasSenior) {
        return {
          ...baseFact,
          subtitle: t(`${copyContext}.age_step.senior_sub_title`)
        }
      } else {
        return baseFact
      }
    }

    case WizardRoutes.Breed: {
      const matchingBreeds = dogs.reduce((acc, dog) => {
        if (!dog.breed) return acc

        const matchingPopularBreed = popularBreeds[dog.breed.key]

        if (matchingPopularBreed) {
          acc.push({
            ...matchingPopularBreed,
            name: dog.breed.key
          })

          return acc
        }
        return acc
      }, [] as { name: string; number: number; slug: string }[])

      if (matchingBreeds.length > 0) {
        const largestBreed = matchingBreeds.reduce(
          (acc, breed) => {
            if (breed.number > acc.number) return breed

            return acc
          },
          { number: 0, name: '', slug: '' }
        )

        const localisedBreedName = t(
          `models:breeds.${largestBreed.name}.sanitised_name_plural`
        )

        return {
          variant: 'freshFacts',
          title: t(`${copyContext}.title`),
          subtitle: t(`${copyContext}.breed_step.popular_breed_sub_title`, {
            breed: localisedBreedName,
            number: largestBreed.number
          }),
          illustration: {
            img: Wheat,
            imgAlt: t(`${copyContext}.breed_step.img_alt`),
            imgPosition: 'right'
          }
        }
      } else {
        return {
          variant: 'freshFacts',
          title: t(`${copyContext}.title`),
          subtitle: t(`${copyContext}.breed_step.sub_title`),
          illustration: {
            img: Wheat,
            imgAlt: t(`${copyContext}.breed_step.img_alt`),
            imgPosition: 'right'
          }
        }
      }
    }

    case WizardRoutes.Food: {
      return {
        variant: 'freshFacts',
        title: t(`${copyContext}.title`),
        subtitle: t(`${copyContext}.food_step.sub_title`),
        illustration: {
          img: Broccoli,
          imgAlt: t(`${copyContext}.food_step.img_alt`),
          imgPosition: 'right'
        }
      }
    }

    case WizardRoutes.Eater:
    case WizardRoutes.FussinessHelp: {
      const baseFact: WizardFooterProps = {
        variant: 'freshFacts',
        title: t(`${copyContext}.title`),
        subtitle: t(`${copyContext}.fussiness_step.sub_title`),
        illustration: {
          img: Beans,
          imgAlt: t(`${copyContext}.fussiness_step.img_alt`),
          imgPosition: 'right'
        }
      }

      if (hasFussyEater) {
        return {
          ...baseFact,
          subtitle: t(`${copyContext}.fussiness_step.fussy_sub_title`)
        }
      }

      return baseFact
    }

    case WizardRoutes.Goals: {
      const baseFact: WizardFooterProps = {
        variant: 'freshFacts',
        title: t(`${copyContext}.title`),
        subtitle: t(`${copyContext}.goals_step.sub_title`),
        illustration: {
          img: SweetPotato,
          imgAlt: t(`${copyContext}.goals_step.img_alt`),
          imgPosition: 'right'
        }
      }

      switch (goalSegment) {
        case 'convenience': {
          return {
            ...baseFact,
            subtitle: t(`${copyContext}.goals_step.convenience_sub_title`)
          }
        }
        case 'health_conscious': {
          return {
            ...baseFact,
            subtitle: t(`${copyContext}.goals_step.health_concious_sub_title`)
          }
        }
        case 'premium_ingredients': {
          return {
            ...baseFact,
            subtitle: t(
              `${copyContext}.goals_step.premium_ingredients_sub_title`
            )
          }
        }
        case 'premium_taste': {
          return {
            ...baseFact,
            subtitle: t(`${copyContext}.goals_step.premium_taste_sub_title`)
          }
        }
        default: {
          return baseFact
        }
      }
    }

    case WizardRoutes.BodyShape: {
      return {
        variant: 'freshFacts',
        title: t(`${copyContext}.title`),
        subtitle: t(
          `${copyContext}.body_step.sub_title${isIrish ? '_ie' : ''}`
        ),
        illustration: {
          img: SweetPotato,
          imgAlt: t(`${copyContext}.body_step.img_alt`),
          imgPosition: 'right'
        }
      }
    }

    case WizardRoutes.Weight: {
      return {
        variant: 'freshFacts',
        title: t(`${copyContext}.title`),
        subtitle: t(`${copyContext}.weight_step.sub_title`),
        illustration: {
          img: Carrots,
          imgAlt: t(`${copyContext}.weight_step.img_alt`),
          imgPosition: 'right'
        }
      }
    }

    case WizardRoutes.Activity: {
      return {
        variant: 'freshFacts',
        title: t(`${copyContext}.title`),
        subtitle: t(`${copyContext}.activity_step.sub_title`),
        illustration: {
          img: Carrots,
          imgAlt: t(`${copyContext}.activity_step.img_alt`),
          imgPosition: 'right'
        }
      }
    }

    case WizardRoutes.Allergies: {
      return {
        variant: 'freshFacts',
        title: t(`${copyContext}.title`),
        subtitle: t(`${copyContext}.allergies_step.sub_title`),
        illustration: {
          img: Wheat,
          imgAlt: t(`${copyContext}.allergies_step.img_alt`),
          imgPosition: 'right'
        }
      }
    }

    case WizardRoutes.Health: {
      return {
        variant: 'freshFacts',
        title: t(`${copyContext}.title`),
        subtitle: t(`${copyContext}.health_step.sub_title`),
        illustration: {
          img: SweetPotato,
          imgAlt: t(`${copyContext}.health_step.img_alt`),
          imgPosition: 'right'
        }
      }
    }

    case WizardRoutes.Snacks: {
      return {
        variant: 'freshFacts',
        title: t(`${copyContext}.title`),
        subtitle: t(`${copyContext}.snacks_step.sub_title`),
        illustration: {
          img: Broccoli,
          imgAlt: t(`${copyContext}.snacks_step.img_alt`),
          imgPosition: 'right'
        }
      }
    }

    case WizardRoutes.PetParent: {
      return {
        variant: 'freshFacts',
        title: t(`${copyContext}.title`),
        subtitle: t(`${copyContext}.pet_parent_step.sub_title`),
        illustration: {
          img: Fish,
          imgAlt: t(`${copyContext}.pet_parent_step.img_alt`),
          imgPosition: 'right'
        }
      }
    }

    default:
      ensureNever(route)

      return {
        variant: 'freshFacts',
        title: t(`${copyContext}.title`),
        subtitle: t(`${copyContext}.name_step.sub_title`),
        illustration: {
          img: Broccoli,
          imgAlt: t(`${copyContext}.name_step.img_alt`),
          imgPosition: 'right'
        }
      }
  }
}

export default routeToFreshFactFooter
