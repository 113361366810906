// @noflow
import type { GraphQLErrorInstance } from '@/redux/graphql_queries/errorTypes'
import type { StripeCardElement, StripeElements } from '@stripe/stripe-js'
import { Stripe } from '@stripe/stripe-js'

import { CONVERT_TO_PAID_MUTATION } from '../mutations/addPaymentMethod'

type PaymentMethod = { lastFourDigits: string }

type CreditCardsCreateResponse = {
  data: {
    creditCardsCreate: {
      subscription: {
        paymentMethods: Array<PaymentMethod>
        activePaymentMethodType:
          | 'CreditCard'
          | 'BillingAgreement'
          | 'SepaDirectDebit'
      }
    }
  }
  errors?: Array<GraphQLErrorInstance>
}

type CardAuthenticationStatus =
  | 'requires_payment_method'
  | 'requires_confirmation'
  | 'requires_action'
  | 'processing'
  | 'canceled'
  | 'succeeded'

type SCAResponse = {
  card_authentication_setup: {
    client_secret: string
    customer_id: string
    id: string
    status: CardAuthenticationStatus
  }
}

const requestFromSCAController = (
  email: string,
  csrfToken: string,
  authenticationType: 'card',
  token: string
): Promise<SCAResponse> => {
  const endpoint =
    '/api/payment_methods/credit_cards/strong_customer_authentication/create'
  return fetch(`${endpoint}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': csrfToken
    },
    credentials: 'same-origin',
    body: JSON.stringify({
      email,
      csrf_token: csrfToken,
      authentication_type: authenticationType,
      token
    })
  })
    .then((response: Response): Promise<SCAResponse> => {
      return response.json()
    })
    .catch((err: Error) => {
      throw new Error(`Error requesting from SCA controller: ${err}`)
    })
}

const confirmCardSetupWithoutSCA = async (
  stripe: Stripe,
  card: StripeCardElement,
  userID: number,
  endpoint: string,
  headers: {
    Accept: string
    'Content-Type': string
    'X-CSRF-Token': string
  },
  method: string,
  graphQLQuery: string,
  credentials: 'same-origin' | 'omit',
  successHandler: () => void,
  errorHandler: (arg0: string) => void
) => {
  const { error, paymentMethod } = await stripe.createPaymentMethod({
    type: 'card',
    card: card
  })
  if (error?.message) {
    errorHandler(error.message)
  } else {
    if (paymentMethod) {
      const body = JSON.stringify({
        query: graphQLQuery,
        variables: {
          userId: userID,
          creditCard: {
            paymentMethodId: paymentMethod.id,
            active: true
          }
        }
      })
      fetch(endpoint, { headers, method, credentials, body }).then(
        (res: Response): void => {
          if (res && res.ok) {
            res.json().then(({ errors }: CreditCardsCreateResponse): void => {
              if (!errors) {
                successHandler()
              } else {
                errorHandler(errors[0].message)
              }
            })
          } else {
            res.json().then(({ errors }: CreditCardsCreateResponse): void => {
              if (errors) {
                errorHandler(errors[0].message)
              }
            })
          }
        }
      )
    } else
      throw new Error('Payment method could not be set through no payment page')
  }
}

const confirmCardSetupWithSCA = (
  stripe: Stripe,
  card: StripeCardElement,
  userID: number,
  endpoint: string,
  headers: {
    Accept: string
    'Content-Type': string
    'X-CSRF-Token': string
  },
  method: string,
  graphQLQuery: string,
  credentials: 'same-origin' | 'omit',
  successHandler: () => void,
  errorHandler: (arg: string) => void,
  email: string,
  csrfToken: string,
  authenticationType: 'card',
  token: string
): void => {
  requestFromSCAController(
    encodeURIComponent(email),
    csrfToken,
    authenticationType,
    token
  ).then((res): void => {
    stripe
      .confirmCardSetup(res.card_authentication_setup.client_secret, {
        payment_method: { card: card }
      })
      .then((result) => {
        if (result.setupIntent?.payment_method) {
          const body = JSON.stringify({
            query: graphQLQuery,
            variables: {
              userId: userID,
              creditCard: {
                paymentMethodId: result.setupIntent.payment_method,
                active: true
              }
            }
          })
          return fetch(endpoint, { headers, method, credentials, body })
        }
      })
      .then((res) => {
        if (res && res.ok) {
          res.json().then(({ errors }: CreditCardsCreateResponse): void => {
            if (!errors) {
              successHandler()
            } else {
              errorHandler(errors[0].message)
            }
          })
        } else {
          res?.json().then(({ errors }: CreditCardsCreateResponse): void => {
            if (errors) {
              errorHandler(errors[0].message)
            }
          })
        }
      })
  })
}

const updateCreditCard = (
  stripe: Stripe,
  elements: StripeElements,
  successHandler: () => void,
  errorHandler: (arg: string) => void,
  userID: number,
  csrfToken: string,
  shouldSupportSCA: boolean,
  email: string,
  authenticationType: 'card',
  token: string
): Promise<Response> => {
  // Ensure that the userID is a number
  if (typeof userID !== 'number') {
    throw new Error(`User ID (${userID}) should be of type number`)
  }
  const endpoint = '/graphql'

  const graphQLQuery = CONVERT_TO_PAID_MUTATION
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  }
  const method = 'POST'
  const credentials = 'same-origin'

  const card = elements && elements.getElement('card')
  if (!card)
    throw new Error('could not find card element at No Payment Method Page')

  return new Promise((): void => {
    if (email && shouldSupportSCA) {
      confirmCardSetupWithSCA(
        stripe,
        card,
        userID,
        endpoint,
        headers,
        method,
        graphQLQuery,
        credentials,
        successHandler,
        errorHandler,
        email,
        csrfToken,
        authenticationType,
        token
      )
    } else {
      confirmCardSetupWithoutSCA(
        stripe,
        card,
        userID,
        endpoint,
        headers,
        method,
        graphQLQuery,
        credentials,
        successHandler,
        errorHandler
      )
    }
  })
}

export { updateCreditCard }
