import { gql } from '@apollo/client'

const CUSTOMER_ISSUE_RESOLUTION_OFFERING_REJECT = gql`
  mutation CustomerIssueResolutionOfferingReject(
    $rejectionDetails: CustomerIssueManagementResolutionOfferingRejectionDetailsInput!
  ) {
    response: customerIssueResolutionOfferingReject(
      rejectionDetails: $rejectionDetails
    ) {
      resolutionOffering {
        id
      }
    }
  }
`

export { CUSTOMER_ISSUE_RESOLUTION_OFFERING_REJECT }
