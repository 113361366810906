// @noflow
import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import BRAND_COLOURS from '@/constants/BrandColours'
import SUPPORT_COLOURS from '@/constants/SupportColours'

type Props = {
  baseColor?: string
  highlightColor?: string
  width?: string | number
  height?: string | number
  count?: number
}

const PillSkeleton = ({
  baseColor = SUPPORT_COLOURS.grey200,
  highlightColor = BRAND_COLOURS.brandWhite,
  width = '15rem',
  height = '5.9rem',
  count = 1
}: Props): JSX.Element => {
  return (
    <SkeletonTheme baseColor={baseColor} highlightColor={highlightColor}>
      <Skeleton
        count={count}
        width={width}
        height={height}
        style={{
          borderRadius: '50rem'
        }}
      />
    </SkeletonTheme>
  )
}

export type { Props }

export { PillSkeleton }
