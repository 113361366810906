// @noflow
import { useReactiveVar } from '@apollo/client'
import { useCallback } from 'react'

import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'

import useWebviewHandler from '@/hooks/useWebviewHandler'

import { Analytics } from '@/components/analytics/Analytics'

import {
  customerIssueEntryInputsState,
  customerIssueManagementState,
  customerIssueReportState
} from '../CustomerIssueManagementPage'
import type { Route as CustomerIssueManagementRoute } from '../types/routes'

type CustomerIssueManagementTracking = {
  trackEvent: (eventName: string, eventProperties?: eventProperties) => void
  categorySelected: (category: string, parentCategory?: string) => void
  stepLoaded: (stepName: string, stepType: string) => void
  dropOff: (pageDropped: string, buttonClicked: string) => void
}

type CustomerIssueManagementFlowAnalyticsProperties = {
  route: CustomerIssueManagementRoute
  usingApp: boolean
  [key: string]: unknown
}

type eventProperties = {
  [key: string]: unknown
}

const useCustomerIssueManagementTracking =
  (): CustomerIssueManagementTracking => {
    const webviewHandler = useWebviewHandler()
    const customerIssueManagementData = useReactiveVar(
      customerIssueManagementState
    )
    const customerIssueReportData = useReactiveVar(customerIssueReportState)
    const customerIssueEntryInputsData = useReactiveVar(
      customerIssueEntryInputsState
    )

    const trackEvent = useCallback(
      (eventName: string, eventProperties?: eventProperties) => {
        const properties: CustomerIssueManagementFlowAnalyticsProperties = {
          route: window.location.pathname as CustomerIssueManagementRoute,
          usingApp: webviewHandler.isWebview,
          ...eventProperties,
          ...customerIssueManagementData,
          ...customerIssueReportData,
          ...customerIssueEntryInputsData
        }
        if (
          customerIssueManagementData.reportable.type === 'Box' &&
          customerIssueManagementData.reportable.price &&
          customerIssueManagementData.reportable.price > -1
        ) {
          const { currency } = countryCodeToLocaleCurrency(
            customerIssueManagementData.shippingCountryCode,
            customerIssueManagementData.preferredLanguage
          )
          properties.boxPrice =
            customerIssueManagementData.reportable.price / 100
          properties.currency = currency
        }
        Analytics.track('Self Resolutions ' + eventName, properties)
      },
      [
        webviewHandler,
        customerIssueManagementData,
        customerIssueReportData,
        customerIssueEntryInputsData
      ]
    )

    const categorySelected = useCallback(
      (category: string, parentCategory?: string) => {
        trackEvent('category selected', { category, parentCategory })
      },
      [trackEvent]
    )

    const stepLoaded = useCallback(
      (stepName: string, stepType: string) => {
        trackEvent('step loaded', { stepName, stepType })
      },
      [trackEvent]
    )

    const dropOff = useCallback(
      (pageDropped: string, buttonClicked: string) => {
        trackEvent('drop off', { pageDropped, buttonClicked })
      },
      [trackEvent]
    )

    return {
      trackEvent,
      categorySelected,
      stepLoaded,
      dropOff
    }
  }

export default useCustomerIssueManagementTracking
