// @flow

import * as TYPES from './types'
import * as MESSAGES from './messages'

import type { CalendarDate, DeliveryWindow } from '../../../shared/DatePicker.js.flow'
import type { State } from '../index'
import type { DateOfMonth, MonthOfYear } from '../../../../shared_types/date_time/month'
import type {
  PricingCohort
} from '@/shared_types/rails_models/pricingCurve'

import type {
  SearchResults,
  AccountDetails,
  SubscriptionDetails,
  ApplicableDiscountPartsDetails,
  DogsDetails,
  DeliveryDetails,
  AccountStatusFilter,
  CustomerHistory,
  CustomerHistoryEntry,
  DeliveriesHistory,
  Date,
  ProposedPlan,
  ShippingDetails,
  AvailablePreference,
  ExistingPreference
} from '../message_types'

export type SearchField =
  | 'email'
  | 'phone'
  | 'firstName'
  | 'lastName'
  | 'postcode'
  | 'dogsName'
  | 'dogsBreed'
  | 'rafCode'
  | 'accountStatus'
  | 'shippingCountryCode'

export type SearchCriteria =
  | 'contains'
  | 'equals'
  | 'starts_with'
  | 'ends_with'

export type SearchParams = $ReadOnly<{|
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  postcode: string,
  dogsName: string,
  dogsBreed: string,
  rafCode: string,
  accountStatus: AccountStatusFilter,
  shippingCountryCode: string
|}>

export type FetchingStatuses = $ReadOnly<{|
  accountDetails: boolean,
  appliedDiscountsDetails: boolean,
  contactPreferencesStatus: boolean,
  customerHistoryEvents: boolean,
  deliveriesDetails: boolean,
  dogsDetails: boolean,
  subscriptionDetails: boolean,
  deliveryCalendar: boolean,
  deliveriesHistory: boolean,
  shippingDetailsStatus: boolean,
  marketingPreferencesState: boolean,
  wants_to_receive_ice: boolean
|}>

export type SelectedUserIds = $ReadOnly<{|
  userId: number,
  subscriptionId: number
|}>

type SearchRequest = $ReadOnly<{|
  type: TYPES.MAKE_SEARCH_REQUEST,
  searchParams: SearchParams
|}>

type SearchResponse = $ReadOnly<{|
  type: TYPES.RECEIVED_SEARCH_RESPONSE,
  searchResults: SearchResults
|}>

type AccountDetailsRequest = $ReadOnly<{|
  type: TYPES.MAKE_ACCOUNT_DETAILS_REQUEST,
  userId: number
|}>

type AccountDetailsResponse = $ReadOnly<{|
  type: TYPES.RECEIVED_ACCOUNT_DETAILS_RESPONSE,
  accountDetails: AccountDetails
|}>

type UpdateSearchString = $ReadOnly<{|
  type: TYPES.UPDATE_SEARCH_STRING,
  field: SearchField,
  value: string
|}>

type UpdateSelectedUserIds = $ReadOnly<{|
  type: TYPES.UPDATE_SELECTED_USER_ID,
  selectedUserIds: SelectedUserIds
|}>

type MakeSubscriptionDetailsRequest = $ReadOnly<{|
  type: TYPES.MAKE_SUBSCRIPTION_DETAILS_REQUEST
|}>

type ReceiveSubscriptionDetailsResponse = $ReadOnly<{|
  type: TYPES.RECEIVE_SUBSCRIPTION_DETAILS_RESPONSE,
  subscriptionDetails: SubscriptionDetails
|}>

export type MakeDeliveriesDetailsRequest = $ReadOnly<{|
  type: TYPES.MAKE_DELIVERIES_DETAILS_REQUEST
|}>

export type ReceiveDeliveriesDetailsResponse = $ReadOnly<{|
  type: TYPES.RECEIVE_DELIVERIES_DETAILS_RESPONSE,
  deliveries: Array<DeliveryDetails>,
  numberOfBoxIssues: number,
  numberOfBoxesDelivered: number
|}>

export type UpdateTopDeliveryIndex = $ReadOnly<{|
  type: TYPES.UPDATE_TOP_DELIVERY_INDEX,
  newIndex: number
|}>

type MakeApplicableDiscountsDetailsRequest = $ReadOnly<{|
  type: TYPES.MAKE_APPLICABLE_DISCOUNTS_DETAILS_REQUEST
|}>

type ReceiveApplicableDiscountsDetailsResponse = $ReadOnly<{|
  type: TYPES.RECEIVE_APPLICABLE_DISCOUNTS_DETAILS_RESPONSE,
  applicableDiscountsDetails: ApplicableDiscountPartsDetails
|}>

type MakeDogsDetailsRequest = $ReadOnly<{|
  type: TYPES.MAKE_DOGS_DETAILS_REQUEST
|}>

type ReceiveDogsDetailsResponse = $ReadOnly<{|
  type: TYPES.RECEIVE_DOGS_DETAILS_RESPONSE,
  dogsDetails: DogsDetails
|}>

type ToggleDogDetails = $ReadOnly<{|
  type: TYPES.TOGGLE_DOG_DETAILS,
  dogIndex: number
|}>

type ToggleDogOverview = $ReadOnly<{|
  type: TYPES.TOGGLE_DOG_OVERVIEW,
  dogIndex: number,
  panelInView: 'overview' | 'description'
|}>

type CustomerHistoryRequest = $ReadOnly<{|
  type: TYPES.MAKE_CUSTOMER_HISTORY_REQUEST
|}>

type CustomerHistoryResponse = $ReadOnly<{|
  type: TYPES.RECEIVE_CUSTOMER_HISTORY_RESPONSE,
  customerHistory: CustomerHistory
|}>

type MakeContactPreferencesRequest = $ReadOnly<{|
  type: TYPES.MAKE_CONTACT_PREFERENCES_REQUEST
|}>

type ReceiveContactPreferencesResponse = {|
  type: TYPES.RECEIVE_CONTACT_PREFERENCES_RESPONSE,
  contactPreferences: {|
    consented_to_marketing: boolean,
    wants_to_receive_treats_emails: boolean,
    opted_out_of_woolcool_return_scheme: boolean,
    wants_to_receive_ice: boolean
  |}
|}

type EmptyAccountDetailsResponse = $ReadOnly<{|
  type: TYPES.RECEIEVE_EMPTY_ACCOUNT_DETAILS_RESPONSE
|}>

type EmptySubscriptionDetailsResponse = $ReadOnly<{|
  type: TYPES.RECEIEVE_EMPTY_SUBSCRIPTION_DETAILS_RESPONSE
|}>

type EmptyApplicableDiscountsDetailsResponse = $ReadOnly<{|
  type: TYPES.RECEIEVE_EMPTY_APPLICABLE_DISCOUNTS_DETAILS_RESPONSE
|}>

type EmptyDeliveriesDetailsResponse = $ReadOnly<{|
  type: TYPES.RECEIEVE_EMPTY_DELIVERIES_DETAILS_RESPONSE
|}>

type EmptyDogsDetailsResponse = $ReadOnly<{|
  type: TYPES.RECEIEVE_EMPTY_DOGS_DETAILS_RESPONSE
|}>

type EmptyCustomerHistortyDetailsResponse = $ReadOnly<{|
  type: TYPES.RECEIEVE_EMPTY_CUSTOMER_HISTORY_RESPONSE
|}>

type EmptyContactPreferencesResponse = $ReadOnly<{|
  type: TYPES.RECEIEVE_EMPTY_CONTACT_PREFERENCES_RESPONSE
|}>

type PushBoxNWeeks = $ReadOnly<{|
  type: TYPES.PUSH_BOX_N_WEEKS,
  boxId: number,
  nWeeks: number
|}>

type RestartSubscription = {|
  newDate: Date,
  subscriptionId: number,
  type: TYPES.RESTART_SUBSCRIPTION,
|}

type RespondToBrowserNavigation = $ReadOnly<{|
  type: TYPES.RESPOND_TO_BROWSER_NAVIGATION,
  state: State
|}>

type ClearSearchResults = $ReadOnly<{|
  type: TYPES.CLEAR_SEARCH_RESULTS
|}>

type ClearSearchParams = $ReadOnly<{|
  type: TYPES.CLEAR_SEARCH_PARAMS
|}>

type ChangeBoxDeliveryDate = $ReadOnly<{|
  type: TYPES.CHANGE_BOX_DELIVERY_DATE,
  boxId: number
|}>

type OpenDeliveryDateCalendar = $ReadOnly<{|
  type: TYPES.OPEN_DELIVERY_DATE_CALENDAR,
  delivery: DeliveryDetails
|}>

type ToggleChangePlanModal = $ReadOnly<{|
  type: TYPES.TOGGLE_CHANGE_PLAN_MODAL
|}>

type ToggleSwitchToStripeModal = $ReadOnly<{|
  type: TYPES.TOGGLE_SWITCH_TO_STRIPE_MODAL
|}>

type ToggleAddCreditCardToSubscriptionModal = $ReadOnly<{|
  type: TYPES.TOGGLE_ADD_CREDIT_CARD_TO_SUBSCRIPTION_MODAL
|}>

type StartSwitchToStripeRequest = {|
  type: TYPES.START_SWITCH_TO_STRIPE_REQUEST
|}

type UpdateStripeId = {|
  type: TYPES.UPDATE_STRIPE_ID,
  stripeId: string
|}

type SwitchToStripeSuccess = {|
  type: TYPES.SWITCH_TO_STRIPE_SUCCESS,
  message: string
|}

type SwitchToStripeFailure = {|
  type: TYPES.SWITCH_TO_STRIPE_FAILURE,
  message: string
|}

type CloseDeliveryDateCalendar = $ReadOnly<{|
  type: TYPES.CLOSE_DELIVERY_DATE_CALENDAR
|}>

type ToggleCurrentTreatsEmailPreference = {|
  type: TYPES.TOGGLE_CURRENT_TREATS_EMAIL_PREFERENCE
|}

type ToggleCurrentWoolcoolReturnSchemePreference = {|
  type: TYPES.TOGGLE_CURRENT_WOOLCOOL_RETURN_SCHEME_PREFERENCE
|}

type ToggleCurrentWantsToReceiveIcePreference = {|
  type: TYPES.TOGGLE_CURRENT_WANTS_TO_RECEIVE_ICE_PREFERENCE
|}

type ToggleWantsToReceiveIceRequest = {|
  type: TYPES.TOGGLE_WANTS_TO_RECEIVE_ICE_REQUEST
|}


type ToggleWantsToReceiveIceResponse = {|
  type: TYPES.TOGGLE_WANTS_TO_RECEIVE_ICE_RESPONSE,
  value: boolean
|}

type ToggleContactPreferencesModal = {|
  type: TYPES.TOGGLE_CONTACT_PREFERENCES_MODAL
|}

type HandleDateChange = $ReadOnly<{|
  type: TYPES.HANDLE_DATE_CHANGE,
  calendarDate: CalendarDate
|}>

type MakeCalendarRequest = $ReadOnly<{|
  type: TYPES.MAKE_CALENDAR_REQUEST
|}>

type ReceivedCalendarResponse = $ReadOnly<{|
  type: TYPES.RECEIVE_CALENDAR_RESPONSE,
  deliveryWindow: DeliveryWindow,
  previousDeliveryDate: string,
  selectedDate: ?CalendarDate
|}>

type DisplayBannerMessage = $ReadOnly<{|
  type: TYPES.DISPLAY_BANNER_MESSAGE,
  message: string
|}>

type HideBannerMessage = $ReadOnly<{|
  type: TYPES.HIDE_BANNER_MESSAGE
|}>

type ToggleAdjustFutureBoxes = $ReadOnly<{|
  type: TYPES.TOGGLE_ADJUST_FUTURE_BOXES
|}>

type ReceiveDeliveriesHistory = {|
  type: TYPES.RECEIVE_DELIVERIES_HISTORY,
  deliveries_history: DeliveriesHistory
|}

type MakeDeliveriesHistoryRequest = {|
  type: TYPES.MAKE_DELIVERIES_HISTORY_REQUEST
|}

type ToggleRestartModal = {|
  type: TYPES.TOGGLE_RESTART_MODAL,
|}

type UpdateDay = {|
  type: TYPES.UPDATE_DAY,
  day: DateOfMonth
|}

type UpdateMonth = {|
  type: TYPES.UPDATE_MONTH,
  month: MonthOfYear
|}

type UpdateYear = {|
  type: TYPES.UPDATE_YEAR,
  year: number
|}

type StartChangePlanRequest = {|
  type: TYPES.START_CHANGE_PLAN_REQUEST
|}

type CompleteChangePlanRequest = {|
  type: TYPES.COMPLETE_CHANGE_PLAN_REQUEST
|}

type StartUpdateProposedPlanRequest = {|
  type: TYPES.START_UPDATE_PROPOSED_PLAN_REQUEST
|}

type CompleteUpdateProposedPlanRequest = {|
  type: TYPES.COMPLETE_UPDATE_PROPOSED_PLAN_REQUEST
|}

type UpdateChangePlanModalProposedPlan = {|
  type: TYPES.UPDATE_CHANGE_PLAN_MODAL_PROPOSED_PLAN,
  proposedPlan: ProposedPlan
|}

type UpdatePricingCohortSelection = {|
  type: TYPES.UPDATE_PRICING_COHORT_SELECTION,
  pricingCohort: PricingCohort
|}

type UpdateDurationInDaysSelection = {|
  type: TYPES.UPDATE_DURATION_IN_DAYS_SELECTION,
  durationInDays: number
|}

type UpdateNumberOfPouchesSelection = {|
  type: TYPES.UPDATE_NUMBER_OF_POUCHES_SELECTION,
  numberOfPouches: number
|}

type DisplayInvalidRestartDateMessage = {|
  type: TYPES.DISPLAY_INVALID_RESTART_DATE_MESSAGE
|}

type StartGetShippingDetailsRequest = {|
  type: TYPES.START_GET_SHIPPING_DETAILS_RESPONSE
|}

type ReceiveShippingDetailsResponse = {|
  type: TYPES.RECEIVE_SHIPPING_DETAILS_RESPONSE,
  shippingDetails: ?ShippingDetails
|}

type StartAddCreditCardToSubscriptionRequest = {|
  type: TYPES.START_ADD_CREDIT_CARD_TO_SUBSCRIPTION_REQUEST
|}

type AddCreditCardToSubscriptionSuccess = {|
  type: TYPES.ADD_CREDIT_CARD_TO_SUBSCRIPTION_SUCCESS,
  message: string
|}

type AddCreditCardToSubscriptionFailure = {|
  type: TYPES.ADD_CREDIT_CARD_TO_SUBSCRIPTION_FAILURE,
  message: string
|}

type StartSendRafDetailsEmailRequest = {|
  type: TYPES.START_SEND_RAF_DETAILS_EMAIL_REQUEST
|}

type SendRafDetailsEmailSuccess = {|
  type: TYPES.SEND_RAF_DETAILS_EMAIL_SUCCESS,
  message: string
|}

type SendRafDetailsEmailFailure = {|
  type: TYPES.SEND_RAF_DETAILS_EMAIL_FAILURE,
  message: string
|}

type StartSendRafDetailsSmsRequest = {|
  type: TYPES.START_SEND_RAF_DETAILS_SMS_REQUEST
|}

type SendRafDetailsSmsSuccess = {|
  type: TYPES.SEND_RAF_DETAILS_SMS_SUCCESS
|}

type SendRafDetailsSmsFailure = {|
  type: TYPES.SEND_RAF_DETAILS_SMS_FAILURE,
  errorMessage: string
|}

type ReceiveEmptyMarketingPreferencesResponse = {|
  type: TYPES.RECEIEVE_EMPTY_MARKETING_PREFERENCES_RESPONSE
|}

type MakeMarketingPreferencesRequest = {|
  type: TYPES.MAKE_MARKETING_PREFERENCES_REQUEST
|}

type ErrorUpdatingMarketingPreferences = $ReadOnly<{|
  type: TYPES.ERROR_UPDATING_MARKETING_PREFERENCES
|}>

type ReceiveMarketingPreferencesResponse = {|
  type: TYPES.RECEIVE_MARKETING_PREFERENCES_RESPONSE,
  availablePreferences: Array<AvailablePreference>,
  existingPreferences: Array<ExistingPreference>
|}

export type Action =
  | SearchRequest
  | SearchResponse
  | AccountDetailsRequest
  | AccountDetailsResponse
  | UpdateSearchString
  | UpdateSelectedUserIds
  | MakeSubscriptionDetailsRequest
  | ReceiveSubscriptionDetailsResponse
  | MakeDeliveriesDetailsRequest
  | ReceiveDeliveriesDetailsResponse
  | UpdateTopDeliveryIndex
  | MakeApplicableDiscountsDetailsRequest
  | ReceiveApplicableDiscountsDetailsResponse
  | MakeDogsDetailsRequest
  | ReceiveDogsDetailsResponse
  | ToggleDogDetails
  | ToggleDogOverview
  | CustomerHistoryRequest
  | CustomerHistoryResponse
  | MakeContactPreferencesRequest
  | ReceiveContactPreferencesResponse
  | EmptyAccountDetailsResponse
  | EmptySubscriptionDetailsResponse
  | EmptyApplicableDiscountsDetailsResponse
  | EmptyDeliveriesDetailsResponse
  | EmptyDogsDetailsResponse
  | EmptyCustomerHistortyDetailsResponse
  | EmptyContactPreferencesResponse
  | PushBoxNWeeks
  | RestartSubscription
  | RespondToBrowserNavigation
  | ClearSearchResults
  | ClearSearchParams
  | ChangeBoxDeliveryDate
  | OpenDeliveryDateCalendar
  | ToggleChangePlanModal
  | ToggleContactPreferencesModal
  | DisplayBannerMessage
  | HideBannerMessage
  | CloseDeliveryDateCalendar
  | HandleDateChange
  | MakeCalendarRequest
  | ReceivedCalendarResponse
  | ToggleAdjustFutureBoxes
  | ReceiveDeliveriesHistory
  | MakeDeliveriesHistoryRequest
  | ToggleRestartModal
  | UpdateDay
  | UpdateMonth
  | UpdateYear
  | ToggleCurrentTreatsEmailPreference
  | ToggleCurrentWoolcoolReturnSchemePreference
  | ToggleCurrentWantsToReceiveIcePreference
  | UpdatePricingCohortSelection
  | UpdateDurationInDaysSelection
  | UpdateNumberOfPouchesSelection
  | StartChangePlanRequest
  | CompleteChangePlanRequest
  | StartUpdateProposedPlanRequest
  | CompleteUpdateProposedPlanRequest
  | UpdateChangePlanModalProposedPlan
  | DisplayInvalidRestartDateMessage
  | ToggleSwitchToStripeModal
  | ToggleAddCreditCardToSubscriptionModal
  | StartAddCreditCardToSubscriptionRequest
  | AddCreditCardToSubscriptionSuccess
  | AddCreditCardToSubscriptionFailure
  | StartSwitchToStripeRequest
  | SwitchToStripeSuccess
  | SwitchToStripeFailure
  | UpdateStripeId
  | StartGetShippingDetailsRequest
  | ReceiveShippingDetailsResponse
  | StartSendRafDetailsEmailRequest
  | SendRafDetailsEmailSuccess
  | SendRafDetailsEmailFailure
  | StartSendRafDetailsSmsRequest
  | SendRafDetailsSmsSuccess
  | SendRafDetailsSmsFailure
  | ToggleWantsToReceiveIceRequest
  | ToggleWantsToReceiveIceResponse
  | ReceiveMarketingPreferencesResponse
  | ErrorUpdatingMarketingPreferences
  | MakeMarketingPreferencesRequest
  | ReceiveEmptyMarketingPreferencesResponse


export function makeSearchRequest (searchParams: SearchParams): Action {
  const type = MESSAGES.MAKE_SEARCH_REQUEST
  return { type, searchParams }
}

export function receiveSearchResponse (searchResults: SearchResults): Action {
  const type = MESSAGES.RECEIVED_SEARCH_RESPONSE
  return { type, searchResults }
}

export function makeAccountDetailsRequest (userId: number): Action {
  const type = MESSAGES.MAKE_ACCOUNT_DETAILS_REQUEST
  return { type, userId }
}

export function receiveAccountDetailsResponse (accountDetails: AccountDetails): Action {
  const type = MESSAGES.RECEIVED_ACCOUNT_DETAILS_RESPONSE
  return { type, accountDetails }
}

const updateSearchString = (field: SearchField, value: string): Action => {
  const type = MESSAGES.UPDATE_SEARCH_STRING
  return { type, field, value }
}

export function updateSelectedUserIds (selectedUserIds: SelectedUserIds): Action {
  const type = MESSAGES.UPDATE_SELECTED_USER_ID
  return { type, selectedUserIds }
}

export function makeSubscriptionDetailsRequest (): Action {
  const type: TYPES.MAKE_SUBSCRIPTION_DETAILS_REQUEST = MESSAGES.MAKE_SUBSCRIPTION_DETAILS_REQUEST
  return { type }
}

export function receiveSubscriptionDetailsResponse (subscriptionDetails: SubscriptionDetails): Action {
  const type = MESSAGES.RECEIVE_SUBSCRIPTION_DETAILS_RESPONSE
  return { type, subscriptionDetails }
}

export function makeDeliveriesDetailsRequest (): Action {
  const type: TYPES.MAKE_DELIVERIES_DETAILS_REQUEST = MESSAGES.MAKE_DELIVERIES_DETAILS_REQUEST
  return { type }
}

export function receiveDeliveriesDetailsResponse (deliveries: Array<DeliveryDetails>, numberOfBoxIssues: number, numberOfBoxesDelivered: number): Action {
  const type = MESSAGES.RECEIVE_DELIVERIES_DETAILS_RESPONSE
  return { type, deliveries, numberOfBoxIssues, numberOfBoxesDelivered }
}

export function updateTopIndex (newIndex: number): Action {
  const type = MESSAGES.UPDATE_TOP_DELIVERY_INDEX
  return { type, newIndex }
}

export function makeApplicableDiscountsDetailsRequest (): Action {
  const type: TYPES.MAKE_APPLICABLE_DISCOUNTS_DETAILS_REQUEST = MESSAGES.MAKE_APPLICABLE_DISCOUNTS_DETAILS_REQUEST
  return { type }
}

export function receiveApplicableDiscountsDetailsResponse (applicableDiscountsDetails: ApplicableDiscountPartsDetails): Action {
  const type = MESSAGES.RECEIVE_APPLICABLE_DISCOUNTS_DETAILS_RESPONSE
  return { type, applicableDiscountsDetails }
}

export function makeDogsDetailsRequest (): Action {
  const type: TYPES.MAKE_DOGS_DETAILS_REQUEST = MESSAGES.MAKE_DOGS_DETAILS_REQUEST
  return { type }
}

export function receiveDogsDetailsResponse (dogsDetails: DogsDetails): Action {
  const type = MESSAGES.RECEIVE_DOGS_DETAILS_RESPONSE
  return { type, dogsDetails }
}

export function toggleDogDetails (dogIndex: number): Action {
  const type: TYPES.TOGGLE_DOG_DETAILS = MESSAGES.TOGGLE_DOG_DETAILS
  return { type, dogIndex }
}

export function toggleDogOverview (dogIndex: number, panelInView: 'overview' | 'description'): Action {
  const type: TYPES.TOGGLE_DOG_OVERVIEW = MESSAGES.TOGGLE_DOG_OVERVIEW
  return { type, dogIndex, panelInView }
}

export function makeCustomerHistoryRequest (): Action {
  const type: TYPES.MAKE_CUSTOMER_HISTORY_REQUEST = MESSAGES.MAKE_CUSTOMER_HISTORY_REQUEST
  return { type }
}

const makeDeliveriesHistoryRequest = (): Action => {
  const type: TYPES.MAKE_DELIVERIES_HISTORY_REQUEST = MESSAGES.MAKE_DELIVERIES_HISTORY_REQUEST
  return { type }
}

export function receiveCustomerHistoryResponse (entries: Array<CustomerHistoryEntry>, amplitude_link: string, stripe_link: string, sentry_link: string, gocardless_link: string, iterable_link: string, revolut_link: string): Action {
  const type = MESSAGES.RECEIVE_CUSTOMER_HISTORY_RESPONSE
  const customerHistory: CustomerHistory = { entries, stripe_link, amplitude_link, sentry_link, gocardless_link, iterable_link, revolut_link }
  return { type, customerHistory }
}

const makeContactPreferencesRequest = (): Action => {
  const type: TYPES.MAKE_CONTACT_PREFERENCES_REQUEST = MESSAGES.MAKE_CONTACT_PREFERENCES_REQUEST
  return { type }
}

const receiveContactPreferencesResponse = (
  consented_to_marketing: boolean, wants_to_receive_treats_emails: boolean,
  opted_out_of_woolcool_return_scheme: boolean, wants_to_receive_ice: boolean): Action => {
  const type = MESSAGES.RECEIVE_CONTACT_PREFERENCES_RESPONSE
  const contactPreferences = {
    consented_to_marketing,
    wants_to_receive_treats_emails,
    opted_out_of_woolcool_return_scheme,
    wants_to_receive_ice
  }
  return { type, contactPreferences }
}

export function receiveEmptyAccountDetailsResponse (): Action {
  const type: TYPES.RECEIEVE_EMPTY_ACCOUNT_DETAILS_RESPONSE = MESSAGES.RECEIEVE_EMPTY_ACCOUNT_DETAILS_RESPONSE
  return { type }
}

export function receiveEmptySubscriptionDetailsResponse (): Action {
  const type: TYPES.RECEIEVE_EMPTY_SUBSCRIPTION_DETAILS_RESPONSE = MESSAGES.RECEIEVE_EMPTY_SUBSCRIPTION_DETAILS_RESPONSE
  return { type }
}

export function receiveEmptyApplicableDiscountsDetailsResponse (): Action {
  const type: TYPES.RECEIEVE_EMPTY_APPLICABLE_DISCOUNTS_DETAILS_RESPONSE = MESSAGES.RECEIEVE_EMPTY_APPLICABLE_DISCOUNTS_DETAILS_RESPONSE
  return { type }
}

export function receiveEmptyDeliveriesDetailsResponse (): Action {
  const type: TYPES.RECEIEVE_EMPTY_DELIVERIES_DETAILS_RESPONSE = MESSAGES.RECEIEVE_EMPTY_DELIVERIES_DETAILS_RESPONSE
  return { type }
}

export function receiveEmptyDogsDetailsResponse (): Action {
  const type: TYPES.RECEIEVE_EMPTY_DOGS_DETAILS_RESPONSE = MESSAGES.RECEIEVE_EMPTY_DOGS_DETAILS_RESPONSE
  return { type }
}

export function receiveEmptyCustomerHistoryResponse (): Action {
  const type: TYPES.RECEIEVE_EMPTY_CUSTOMER_HISTORY_RESPONSE = MESSAGES.RECEIEVE_EMPTY_CUSTOMER_HISTORY_RESPONSE
  return { type }
}

const receiveEmptyContactPreferencesResponse = (): Action => {
  const type: TYPES.RECEIEVE_EMPTY_CONTACT_PREFERENCES_RESPONSE = MESSAGES.RECEIEVE_EMPTY_CONTACT_PREFERENCES_RESPONSE
  return { type }
}

export function signalPushBoxNWeeks (boxId: number, nWeeks: number): Action {
  const type: TYPES.PUSH_BOX_N_WEEKS = MESSAGES.PUSH_BOX_N_WEEKS
  return { type, boxId, nWeeks }
}

const signalRestartSubscription = (subscriptionId: number, newDate: Date): Action => {
  const type: TYPES.RESTART_SUBSCRIPTION = MESSAGES.RESTART_SUBSCRIPTION
  return { type, subscriptionId, newDate }
}

export function respondToBrowserNavigation (state: State): Action {
  const type = MESSAGES.RESPOND_TO_BROWSER_NAVIGATION
  return { type, state }
}

export function clearSearchResults (): Action {
  const type: TYPES.CLEAR_SEARCH_RESULTS = MESSAGES.CLEAR_SEARCH_RESULTS
  return { type }
}

const clearSearchParams = (): ClearSearchParams => {
  const type: TYPES.CLEAR_SEARCH_PARAMS = MESSAGES.CLEAR_SEARCH_PARAMS
  return { type }
}

export function signalChangeBoxDeliveryDate (boxId: number): Action {
  const type: TYPES.CHANGE_BOX_DELIVERY_DATE = MESSAGES.CHANGE_BOX_DELIVERY_DATE
  return { type, boxId }
}

export function openDeliveryDateCalendar (delivery: DeliveryDetails): Action {
  const type: TYPES.OPEN_DELIVERY_DATE_CALENDAR = MESSAGES.OPEN_DELIVERY_DATE_CALENDAR
  return { type, delivery }
}

const toggleContactPreferencesModal = (): Action => {
  const type: TYPES.TOGGLE_CONTACT_PREFERENCES_MODAL = MESSAGES.TOGGLE_CONTACT_PREFERENCES_MODAL
  return { type }
}

const toggleChangePlanModal = (): Action => {
  const type: TYPES.TOGGLE_CHANGE_PLAN_MODAL = MESSAGES.TOGGLE_CHANGE_PLAN_MODAL
  return { type }
}

const toggleSwitchToStripeModal = (): ToggleSwitchToStripeModal => {
  const type: TYPES.TOGGLE_SWITCH_TO_STRIPE_MODAL = MESSAGES.TOGGLE_SWITCH_TO_STRIPE_MODAL
  return { type }
}

const toggleAddCreditCardToSubscriptionModal = (): ToggleAddCreditCardToSubscriptionModal => {
  const type: TYPES.TOGGLE_ADD_CREDIT_CARD_TO_SUBSCRIPTION_MODAL = MESSAGES.TOGGLE_ADD_CREDIT_CARD_TO_SUBSCRIPTION_MODAL
  return { type }
}

const startSwitchToStripeRequest = (): Action => {
  const type: TYPES.START_SWITCH_TO_STRIPE_REQUEST = MESSAGES.START_SWITCH_TO_STRIPE_REQUEST
  return { type }
}

const startAddCreditCardToSubscriptionRequest = (): Action => {
  const type: TYPES.START_ADD_CREDIT_CARD_TO_SUBSCRIPTION_REQUEST = MESSAGES.START_ADD_CREDIT_CARD_TO_SUBSCRIPTION_REQUEST
  return { type }
}

const updateStripeId = (stripeId: string): Action => {
  const type: TYPES.UPDATE_STRIPE_ID = MESSAGES.UPDATE_STRIPE_ID
  return { type, stripeId }
}

const switchToStripeFailure = (message: string): Action => {
  const type: TYPES.SWITCH_TO_STRIPE_FAILURE = MESSAGES.SWITCH_TO_STRIPE_FAILURE
  return { type, message }
}

const switchToStripeSuccess = (message: string): Action => {
  const type: TYPES.SWITCH_TO_STRIPE_SUCCESS = MESSAGES.SWITCH_TO_STRIPE_SUCCESS
  return { type, message }
}

const addCreditCardToSubscriptionFailure = (message: string): Action => {
  const type: TYPES.ADD_CREDIT_CARD_TO_SUBSCRIPTION_FAILURE = MESSAGES.ADD_CREDIT_CARD_TO_SUBSCRIPTION_FAILURE
  return { type, message }
}

const addCreditCardToSubscriptionSuccess = (message: string): Action => {
  const type: TYPES.ADD_CREDIT_CARD_TO_SUBSCRIPTION_SUCCESS = MESSAGES.ADD_CREDIT_CARD_TO_SUBSCRIPTION_SUCCESS
  return { type, message }
}

const startSendRafDetailsEmailRequest = (): Action => {
  const type: TYPES.START_SEND_RAF_DETAILS_EMAIL_REQUEST = MESSAGES.START_SEND_RAF_DETAILS_EMAIL_REQUEST
  return { type }
}

const sendRafDetailsEmailSuccess = (message: string): Action => {
  const type: TYPES.SEND_RAF_DETAILS_EMAIL_SUCCESS = MESSAGES.SEND_RAF_DETAILS_EMAIL_SUCCESS
  return { type, message }
}

const sendRafDetailsEmailFailure = (message: string): Action => {
  const type: TYPES.SEND_RAF_DETAILS_EMAIL_FAILURE = MESSAGES.SEND_RAF_DETAILS_EMAIL_FAILURE
  return { type, message }
}

const startSendRafDetailsSmsRequest = (): Action => {
  const type: TYPES.START_SEND_RAF_DETAILS_SMS_REQUEST = MESSAGES.START_SEND_RAF_DETAILS_SMS_REQUEST
  return { type }
}

const sendRafDetailsSmsSuccess = (): Action => {
  const type: TYPES.SEND_RAF_DETAILS_SMS_SUCCESS = MESSAGES.SEND_RAF_DETAILS_SMS_SUCCESS
  return { type }
}

const sendRafDetailsSmsFailure = (errorMessage: string): Action => {
  const type: TYPES.SEND_RAF_DETAILS_SMS_FAILURE = MESSAGES.SEND_RAF_DETAILS_SMS_FAILURE
  return { type, errorMessage }
}

const toggleCurrentTreatsEmailPreference = (): Action => {
  const type: TYPES.TOGGLE_CURRENT_TREATS_EMAIL_PREFERENCE = MESSAGES.TOGGLE_CURRENT_TREATS_EMAIL_PREFERENCE
  return { type }
}

const toggleCurrentWoolcoolReturnSchemePreference = (): Action => {
  const type: TYPES.TOGGLE_CURRENT_WOOLCOOL_RETURN_SCHEME_PREFERENCE = MESSAGES.TOGGLE_CURRENT_WOOLCOOL_RETURN_SCHEME_PREFERENCE
  return { type }
}

const toggleCurrentWantsToReceiveIcePreference = (): Action => {
  const type: TYPES.TOGGLE_CURRENT_WANTS_TO_RECEIVE_ICE_PREFERENCE = MESSAGES.TOGGLE_CURRENT_WANTS_TO_RECEIVE_ICE_PREFERENCE
  return { type }
}

const toggleWantsToReceiveIceRequest = (): Action => {
  const type: TYPES.TOGGLE_WANTS_TO_RECEIVE_ICE_REQUEST = MESSAGES.TOGGLE_WANTS_TO_RECEIVE_ICE_REQUEST
  return { type }
}

const toggleWantsToReceiveIceResponse = (value: boolean): Action => {
  const type: TYPES.TOGGLE_WANTS_TO_RECEIVE_ICE_RESPONSE = MESSAGES.TOGGLE_WANTS_TO_RECEIVE_ICE_RESPONSE
  return { type, value }
}

export function closeDeliveryDateCalendar (): Action {
  const type: TYPES.CLOSE_DELIVERY_DATE_CALENDAR = MESSAGES.CLOSE_DELIVERY_DATE_CALENDAR
  return { type }
}

export function handleDateChange (calendarDate: CalendarDate): Action {
  const type: TYPES.HANDLE_DATE_CHANGE = MESSAGES.HANDLE_DATE_CHANGE
  return { type, calendarDate }
}

export function makeCalendarRequest (): Action {
  const type: TYPES.MAKE_CALENDAR_REQUEST = MESSAGES.MAKE_CALENDAR_REQUEST
  return { type }
}

export function displayBannerMessage (message: string): Action {
  const type: TYPES.DISPLAY_BANNER_MESSAGE = MESSAGES.DISPLAY_BANNER_MESSAGE
  return { type, message }
}

export function hideBannerMessage (): Action {
  const type: TYPES.HIDE_BANNER_MESSAGE = MESSAGES.HIDE_BANNER_MESSAGE
  return { type }
}

export function receivedCalendarResponse (deliveryWindow: DeliveryWindow, previousDeliveryDate: string, selectedDate: ?CalendarDate): Action {
  const type: TYPES.RECEIVE_CALENDAR_RESPONSE = MESSAGES.RECEIVE_CALENDAR_RESPONSE
  return { type, deliveryWindow, previousDeliveryDate, selectedDate }
}

export function toggleAdjustFutureBoxes (): Action {
  const type: TYPES.TOGGLE_ADJUST_FUTURE_BOXES = MESSAGES.TOGGLE_ADJUST_FUTURE_BOXES
  return { type }
}

export function makeMarketingPreferencesRequest(): Action {
  const type: TYPES.MAKE_MARKETING_PREFERENCES_REQUEST = MESSAGES.MAKE_MARKETING_PREFERENCES_REQUEST
  return { type }
}

export function receiveEmptyMarketingPreferencesResponse(): Action {
  const type: TYPES.RECEIEVE_EMPTY_MARKETING_PREFERENCES_RESPONSE = MESSAGES.RECEIEVE_EMPTY_MARKETING_PREFERENCES_RESPONSE
  return { type }
}

export function errorUpdatingMarketingPreferences(): Action {
  const type: TYPES.ERROR_UPDATING_MARKETING_PREFERENCES = MESSAGES.ERROR_UPDATING_MARKETING_PREFERENCES
  return { type }
}

export function receiveMarketingPreferencesResponse(availablePreferences: Array<AvailablePreference>, existingPreferences: Array<ExistingPreference>): Action {
  const type: TYPES.RECEIVE_MARKETING_PREFERENCES_RESPONSE = MESSAGES.RECEIVE_MARKETING_PREFERENCES_RESPONSE
  return { type, availablePreferences, existingPreferences }
}

const receiveDeliveriesHistoryResponse = (deliveries_history: DeliveriesHistory): Action => {
  const type: TYPES.RECEIVE_DELIVERIES_HISTORY = MESSAGES.RECEIVE_DELIVERIES_HISTORY
  return { type, deliveries_history }
}

const toggleRestartModal = (): Action => {
  const type: TYPES.TOGGLE_RESTART_MODAL = MESSAGES.TOGGLE_RESTART_MODAL
  return { type }
}

const updateDay = (day: DateOfMonth): Action => {
  const type: TYPES.UPDATE_DAY = MESSAGES.UPDATE_DAY
  return { type, day }
}

const updateMonth = (month: MonthOfYear): Action => {
  const type: TYPES.UPDATE_MONTH = MESSAGES.UPDATE_MONTH
  return { type, month }
}

const updateYear = (year: number): Action => {
  const type: TYPES.UPDATE_YEAR = MESSAGES.UPDATE_YEAR
  return { type, year }
}

const startChangePlanRequest = (): StartChangePlanRequest => {
  const type: TYPES.START_CHANGE_PLAN_REQUEST = MESSAGES.START_CHANGE_PLAN_REQUEST
  return { type }
}

const completeChangePlanRequest = (): CompleteChangePlanRequest => {
  const type: TYPES.COMPLETE_CHANGE_PLAN_REQUEST = MESSAGES.COMPLETE_CHANGE_PLAN_REQUEST
  return { type }
}

const startUpdateProposedPlanRequest = (): StartUpdateProposedPlanRequest => {
  const type: TYPES.START_UPDATE_PROPOSED_PLAN_REQUEST = MESSAGES.START_UPDATE_PROPOSED_PLAN_REQUEST
  return { type }
}

const completeUpdateProposedPlanRequest = (): CompleteUpdateProposedPlanRequest => {
  const type: TYPES.COMPLETE_UPDATE_PROPOSED_PLAN_REQUEST = MESSAGES.COMPLETE_UPDATE_PROPOSED_PLAN_REQUEST
  return { type }
}

const updateChangePlanModalProposedPlan = (proposedPlan: ProposedPlan): UpdateChangePlanModalProposedPlan => {
  const type: TYPES.UPDATE_CHANGE_PLAN_MODAL_PROPOSED_PLAN = MESSAGES.UPDATE_CHANGE_PLAN_MODAL_PROPOSED_PLAN
  return { type, proposedPlan }
}

const updatePricingCohortSelection = (pricingCohort: PricingCohort): UpdatePricingCohortSelection => {
  const type: TYPES.UPDATE_PRICING_COHORT_SELECTION = MESSAGES.UPDATE_PRICING_COHORT_SELECTION
  return { type, pricingCohort }
}

const updateDurationInDaysSelection = (durationInDays: number): UpdateDurationInDaysSelection => {
  const type: TYPES.UPDATE_DURATION_IN_DAYS_SELECTION = MESSAGES.UPDATE_DURATION_IN_DAYS_SELECTION
  return { type, durationInDays }
}

const updateNumberOfPouchesSelection = (numberOfPouches: number): UpdateNumberOfPouchesSelection => {
  const type: TYPES.UPDATE_NUMBER_OF_POUCHES_SELECTION = MESSAGES.UPDATE_NUMBER_OF_POUCHES_SELECTION
  return { type, numberOfPouches }
}

const displayInvalidRestartDateMessage = (): DisplayInvalidRestartDateMessage => {
  const type: TYPES.DISPLAY_INVALID_RESTART_DATE_MESSAGE = MESSAGES.DISPLAY_INVALID_RESTART_DATE_MESSAGE
  return { type }
}

const startGetShippingDetailsRequest = (): StartGetShippingDetailsRequest => {
  const type: TYPES.START_GET_SHIPPING_DETAILS_RESPONSE = MESSAGES.START_GET_SHIPPING_DETAILS_RESPONSE
  return { type }
}

const receiveShippingDetailsResponse = (shippingDetails: ?ShippingDetails): ReceiveShippingDetailsResponse => {
  const type: TYPES.RECEIVE_SHIPPING_DETAILS_RESPONSE = MESSAGES.RECEIVE_SHIPPING_DETAILS_RESPONSE
  return { type, shippingDetails }
}

export {
  updateSearchString,
  receiveDeliveriesHistoryResponse,
  makeDeliveriesHistoryRequest,
  receiveContactPreferencesResponse,
  makeContactPreferencesRequest,
  receiveEmptyContactPreferencesResponse,
  toggleContactPreferencesModal,
  toggleChangePlanModal,
  toggleSwitchToStripeModal,
  toggleAddCreditCardToSubscriptionModal,
  startAddCreditCardToSubscriptionRequest,
  addCreditCardToSubscriptionSuccess,
  addCreditCardToSubscriptionFailure,
  startSwitchToStripeRequest,
  updateStripeId,
  switchToStripeSuccess,
  switchToStripeFailure,
  signalRestartSubscription,
  toggleRestartModal,
  updateDay,
  updateMonth,
  updateYear,
  toggleCurrentTreatsEmailPreference,
  toggleCurrentWoolcoolReturnSchemePreference,
  toggleCurrentWantsToReceiveIcePreference,
  toggleWantsToReceiveIceRequest,
  toggleWantsToReceiveIceResponse,
  clearSearchParams,
  updatePricingCohortSelection,
  updateDurationInDaysSelection,
  updateNumberOfPouchesSelection,
  startChangePlanRequest,
  completeChangePlanRequest,
  startUpdateProposedPlanRequest,
  completeUpdateProposedPlanRequest,
  updateChangePlanModalProposedPlan,
  displayInvalidRestartDateMessage,
  startGetShippingDetailsRequest,
  receiveShippingDetailsResponse,
  sendRafDetailsEmailSuccess,
  sendRafDetailsEmailFailure,
  startSendRafDetailsEmailRequest,
  sendRafDetailsSmsSuccess,
  sendRafDetailsSmsFailure,
  startSendRafDetailsSmsRequest

}
