// @noflow
import initI18n from '@/packs/localisation'
import type { Language } from '@/packs/localisation'
import isNull from 'lodash/isNull'
import React, { useCallback, useState } from 'react'

import AlertCard from '@/components/elements/atoms/Alert/AlertCard'
import { MarketingPreferenceQuery } from '@/components/elements/organisms/MarketingPreferences/queries/__generated__/MarketingPreferenceQuery'
import type {
  MarketingPreferenceQuery_user_selectedMarketingMethodPurposes as SelectedMarketingMethodPurpose,
  MarketingPreferenceQuery_user_availableMarketingMethodPurposes as SupportedMarketingMethodPurpose,
  MarketingPreferenceQuery_user as User
} from '@/components/elements/organisms/MarketingPreferences/queries/__generated__/MarketingPreferenceQuery'

import STYLES from './GuestContactPreferencesPage.module.sass'

import MarketingPreferences from '../elements/organisms/MarketingPreferences/MarketingPreferences'

type Props = {
  language: Language
  user: User
  existingPreferences: Array<SelectedMarketingMethodPurpose>
  availablePreferences: Array<SupportedMarketingMethodPurpose>
  csrfToken: string
}

const GuestContactPreferencesPage = ({
  language,
  user,
  existingPreferences,
  availablePreferences,
  csrfToken
}: Props): JSX.Element => {
  initI18n(language)

  const guestMarketingPreferenceData: MarketingPreferenceQuery = {
    user: {
      __typename: 'User',
      id: user.id,
      selectedMarketingMethodPurposes: existingPreferences.map(
        (preference) => ({
          __typename: 'SelectedMarketingMethodPurpose',
          id: preference.id.toString(),
          supportedMarketingMethodPurpose: {
            __typename: 'SupportedMarketingMethodPurpose',
            marketingMethod: {
              __typename: 'MarketingMethod',
              id: preference.supportedMarketingMethodPurpose.marketingMethod.id.toString(),
              name: preference.supportedMarketingMethodPurpose.marketingMethod
                .name
            },
            marketingPurpose: {
              __typename: 'MarketingPurpose',
              id: preference.supportedMarketingMethodPurpose.marketingPurpose.id.toString(),
              name: preference.supportedMarketingMethodPurpose.marketingPurpose
                .name,
              description:
                preference.supportedMarketingMethodPurpose.marketingPurpose
                  .description
            }
          }
        })
      ),
      availableMarketingMethodPurposes: availablePreferences.map(
        (preference) => ({
          __typename: 'SupportedMarketingMethodPurpose',
          id: preference.id.toString(),
          preselect: preference.preselect,
          shippingCountry: {
            __typename: 'ShippingCountry',
            id: preference.shippingCountry.id.toString()
          },
          marketingMethod: {
            __typename: 'MarketingMethod',
            id: preference.marketingMethod.id.toString(),
            name: preference.marketingMethod.name
          },
          marketingPurpose: {
            __typename: 'MarketingPurpose',
            id: preference.marketingPurpose.id.toString(),
            name: preference.marketingPurpose.name,
            description: preference.marketingPurpose.description
          }
        })
      )
    }
  }

  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const handleDisplayError = useCallback((errorText: string) => {
    setErrorMessage(errorText)
  }, [])

  const id = new URLSearchParams(window.location.search).get('id')
  const signature = new URLSearchParams(window.location.search).get('signature')

  const handleSubmitPreferences = useCallback(
    (preferences: string[]): void => {
      setErrorMessage(null)
      const endpoint = '/contact-preferences'
      // eslint-disable-next-line i18next/no-literal-string
      const queryParams = `id=${id}&signature=${signature}`
      const body = JSON.stringify({
        preferences: { purpose_ids: preferences }
      })
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRF-Token': csrfToken
      }
      const method = 'PATCH'
      const credentials = 'same-origin'

      fetch(`${endpoint}?${queryParams}`, {
        headers,
        method,
        credentials,
        body
      }).then((res: Response): Promise<void> | void => {
        if (!res.ok) {
          setErrorMessage('contact_preferences.preference_update_error')
          setShowSuccessMessage(false)
        } else {
          setErrorMessage(null)
          setShowSuccessMessage(true)
        }
      })
    },
    [csrfToken, id, signature]
  )

  return (
    <div className={STYLES.background}>
      <div className={STYLES.guestStyleWrapper}>
        {showSuccessMessage && (
          <div className={STYLES.alertWrapper}>
            <AlertCard
              message={{
                namespace: 'account',
                text: 'contact_preferences.preferences_updated',
                margin: false
              }}
              variant="success"
            />
          </div>
        )}
        {!isNull(errorMessage) && (
          <div className={STYLES.alertWrapper}>
            <AlertCard
              message={{
                namespace: 'account',
                text: errorMessage,
                margin: false
              }}
              variant="error"
            />
          </div>
        )}
        <MarketingPreferences
          data={guestMarketingPreferenceData}
          handleSubmitPreferences={handleSubmitPreferences}
          handleDisplayError={handleDisplayError}
        />
      </div>
    </div>
  )
}

export default GuestContactPreferencesPage
