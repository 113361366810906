// @noflow
import React from 'react'

import { Button } from '@/components/elements/atoms/Button'
import MultiLineTextField from '@/components/elements/atoms/MultiLineTextField/MultiLineTextField'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from '../SurveyCard.module.sass'

import { sendAnswerAnalyticsEvent } from '../SurveyCard'
import type { AnswerableCardProps, BaseCardProps } from '../SurveyCard'

type FreeTextQuestionProps = BaseCardProps & AnswerableCardProps

const FreeTextQuestion = ({
  currentQuestion,
  setCurrentQuestionIndex,
  userId,
  surveyTitle,
  setAnswers,
  answers
}: FreeTextQuestionProps): JSX.Element => {
  const textFieldRef = React.useRef<HTMLTextAreaElement>(null)
  const handleClick = React.useCallback(() => {
    setCurrentQuestionIndex(currentQuestion.order + 1)
    const answer = 1
    answers[currentQuestion.order] = {
      optionId: currentQuestion.options[0].id, // free text questions don't use options
      questionId: currentQuestion.id,
      value: answer // currently, values must be an integer so we do not store the free text answer
    }
    setAnswers([...answers])
    sendAnswerAnalyticsEvent({
      userId,
      surveyTitle,
      question: currentQuestion.text,
      answer: textFieldRef.current?.value
    })
  }, [
    setCurrentQuestionIndex,
    currentQuestion,
    userId,
    surveyTitle,
    setAnswers,
    answers,
    textFieldRef
  ])

  return (
    <div className={`${STYLES.freeTextQuestion} ${STYLES.surveyCard}`}>
      <Text
        text={currentQuestion.text}
        element={'h2'}
        variant="display20"
        colour={'brandBlue500'}
        margin
        align={'left'}
        translate={false}
      />
      <div className={STYLES.contentContainer}>
        <MultiLineTextField
          ref={textFieldRef}
          label={'textfield_label'}
          namespace="surveys"
        />
        <Button
          typography={{
            namespace: 'surveys',
            text: 'submit'
          }}
          variant="secondary"
          onClick={handleClick}
          disableAnalytics
        />
      </div>
    </div>
  )
}

export default FreeTextQuestion
