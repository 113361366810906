// @noflow
import React, { useCallback, useState } from 'react'
import Truncate from 'react-truncate'

import segmentTrack from '@/components/analytics/Analytics'
import { Button } from '@/components/elements/atoms/Button'

import STYLES from './Description.module.sass'

import type { ProductCollectionQuery_productCollection as ProductCollection } from '../../../queries/__generated__/ProductCollectionQuery'

type Props = {
  description: ProductCollection['description']
  descriptionRef: React.MutableRefObject<null>
  productCollectionSlug: ProductCollection['slug']
}

const Description = ({
  description,
  descriptionRef,
  productCollectionSlug
}: Props): JSX.Element => {
  const [isReadMoreExpanded, setIsReadMoreExpanded] = useState(false)

  const copyContext = 'extras.product_collection.details.information'

  const handleExpandReadMore = useCallback(() => {
    segmentTrack(
      // eslint-disable-next-line i18next/no-literal-string
      `Interacted with ${
        isReadMoreExpanded ? 'read less' : 'read more'
      } on AP description`,
      {
        productCollectionSlug
      }
    )

    setIsReadMoreExpanded(!isReadMoreExpanded)
  }, [isReadMoreExpanded, productCollectionSlug])

  return (
    <div
      className={STYLES.descriptionWrapper}
      ref={descriptionRef}
      id="description"
    >
      <div className={STYLES.markdownWrapper}>
        <Truncate trimWhitespace lines={isReadMoreExpanded ? false : 2}>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </Truncate>
      </div>
      <div className={STYLES.readMoreButton}>
        <Button
          typography={{
            namespace: 'dashboard',
            text: isReadMoreExpanded
              ? `${copyContext}.read_less`
              : `${copyContext}.read_more`
          }}
          variant="linkWhiteBG"
          size="slim"
          onClick={handleExpandReadMore}
          disableAnalytics
        />
      </div>
    </div>
  )
}

export default Description
