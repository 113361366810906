// @noflow
import lazyLoad from '@/modules/lazyLoad'
// Static content
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import React, { useCallback, useEffect } from 'react'
// Routing
import { useLocation, useNavigate } from 'react-router-dom'

import {
  capitaliseFirstLetter,
  toLocalisedSentence
} from '@/utils/StringHelper'
import * as DiscountCodes from '@/utils/butternutbox/discountCodes'
import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { formatCurrencyWithDecimal } from '@/utils/currency'
import * as Sentry from '@/utils/sentry'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'
import CloudinaryImage from '@/components/elements/atoms/CloudinaryImage/CloudinaryImage'
import Label from '@/components/elements/atoms/Label/Label'
import Link from '@/components/elements/atoms/Link/Link'
import Ribbon from '@/components/elements/atoms/Ribbon/Ribbon'
import SpeechBubble from '@/components/elements/atoms/SpeechBubble/SpeechBubble'
import Text from '@/components/elements/atoms/Text/Text'
import SwiperSlider from '@/components/elements/molecules/SwiperSlider/SwiperSlider'
import AdditionalProductInformationModal, {
  modalData as additionalProductModalData
} from '@/components/elements/organisms/AdditionalProductModal/AdditionalProductModal'
import HeroText from '@/components/elements/organisms/HeroText/HeroText'
import StickyNavigation from '@/components/elements/organisms/StickyNavigation/StickyNavigation'
import {
  deliveryCadenceState,
  planRecommendationState,
  plansFlowTypeState,
  plansPageState,
  plansPageWizardState
} from '@/components/pages/PlansPage/PlansPage'
import { currentUserToLocaleData } from '@/components/pages/PlansPage/helpers/currentUserToLocaleData'
import {
  handleFetchError,
  handleWizardDataError,
  verifyWizardData
} from '@/components/pages/PlansPage/helpers/handleErrors'
import PlansRoutes from '@/components/pages/PlansPage/types/routes'
import type { FlowType } from '@/components/pages/PlansPage/types/types'
// eslint-disable-next-line no-restricted-imports
import type { PendingSubscription } from '@/components/types'

import STYLES from './Extras.module.sass'

import type { GuestQuery_currentUser_User_planRecommendation_recommendedExtraProductVariants as ExtraProduct } from '../../__generated__/GuestQuery'
import type {
  RecommendationQuery_currentUser_Guest as Guest,
  RecommendationQuery_currentUser_Guest_discountCode_discountCodeParts as GuestDiscountCodePart,
  RecommendationQuery,
  RecommendationQuery_currentUser_User as User
} from '../../__generated__/RecommendationQuery'

import {
  trackAdditionalProductMoreInfoClicked,
  trackBackButtonClicked,
  trackExtrasProductAdded,
  trackExtrasProductRemoved,
  trackExtrasSeen,
  trackNextButtonClicked
} from '../../helpers/analytics'
import {
  CREATE_PENDING_SUBSCRIPTION,
  GET_RECOMMENDATIONS_QUERY
} from '../../queries'
import type { Route as PlanRoute } from '../../types/routes'

type Props = {
  variant?: keyof typeof STYLES
  namespace: string
  recommendedExtraProductVariants: ExtraProduct[]
}

type ExtraCardProps = {
  firstBoxDiscountPart: GuestDiscountCodePart | null
  extraProduct: ExtraProduct
  setSelectedExtras: (extraProduct: ExtraProduct) => void
  removeSelectedExtras: (extraProduct: ExtraProduct) => void
  currentUser: Guest | User
  productVariants: ExtraProduct[]
  copyContext: string
  namespace: string
  readMoreOnClick?: () => void
}

const ExtraCard = ({
  firstBoxDiscountPart,
  extraProduct,
  setSelectedExtras,
  removeSelectedExtras,
  currentUser,
  productVariants,
  copyContext,
  namespace,
  readMoreOnClick
}: ExtraCardProps): JSX.Element => {
  if (isUndefined(extraProduct.recurringDeliveryType))
    throw new Error(`${extraProduct.name} price not defined`)
  const { countryCode, preferredLanguage } =
    currentUserToLocaleData(currentUser)
  const { locale, currency } = countryCodeToLocaleCurrency(
    countryCode,
    preferredLanguage
  )

  const { windowWidth } = useWindowSize()
  const cardRef = React.useRef<HTMLDivElement>(null)

  const { recurringDeliveryType, productCollection, name, id } = extraProduct
  const adjustedPrice = recurringDeliveryType
    ? recurringDeliveryType.netPrice
    : extraProduct.grossPrice
  const formattedFullPrice = formatCurrencyWithDecimal(adjustedPrice, {
    locale: locale,
    currency: currency
  })
  const discountedPrice = firstBoxDiscountPart
    ? DiscountCodes.discountedPriceWithPart(firstBoxDiscountPart, adjustedPrice)
    : 0
  const formattedDiscountedPrice =
    firstBoxDiscountPart &&
    formatCurrencyWithDecimal(discountedPrice, {
      locale: locale,
      currency: currency
    })
  const firstBoxDiscountCopy =
    firstBoxDiscountPart &&
    DiscountCodes.formattedPartValue(firstBoxDiscountPart, locale, currency)
  const selected = productVariants?.find((productVariant) => {
    return productVariant.id === id
  })

  return (
    <div className={STYLES.contentWrapper} key={name} ref={cardRef}>
      <Card
        background={selected && 'blue500'}
        padding={windowWidth < BREAKPOINTS.lg ? 8 : 16}
        shadow
      >
        <div className={STYLES.cardContent}>
          <div className={STYLES.cardImage}>
            <CloudinaryImage
              alt=""
              lazyLoad
              image={{
                path: productCollection?.merchandisingImage?.src || '',
                crop: 'fill',
                width:
                  cardRef && cardRef.current
                    ? cardRef.current.clientWidth
                    : 500,
                dpr: 2
              }}
            />
            {firstBoxDiscountPart && (
              <div className={STYLES.discountRibbon}>
                <Ribbon
                  size="small"
                  direction="right"
                  textAlign="left"
                  message={{
                    text: `${copyContext}.discount_ribbon`,
                    namespace: namespace,
                    variant: 'display18',
                    colour: 'brandRed500',
                    variables: { firstBoxDiscount: firstBoxDiscountCopy }
                  }}
                />
              </div>
            )}
          </div>
          <div className={STYLES.cardContentWrapper}>
            <div className={STYLES.cardBody}>
              <div className={STYLES.cardHeader}>
                <Text
                  text={productCollection.name}
                  margin={false}
                  translate={false}
                  variant="display20"
                  bold
                  colour={selected ? 'brandWhite' : 'brandBlue500'}
                />
              </div>
              <Text
                text={name}
                margin={false}
                translate={false}
                variant="display16"
                shouldScale={false}
                colour={selected ? 'brandWhite' : 'brandBlue500'}
              />
              {productCollection.productLabel ? (
                <div className={STYLES.label}>
                  {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                  <Label
                    key={`${productCollection.name}-label-key`}
                    text={{
                      text: productCollection.productLabel.name,
                      translate: false
                    }}
                    variant="recommendedExtra"
                    size="regular"
                  />
                </div>
              ) : (
                <div className={STYLES.noLabel} />
              )}
              <div className={STYLES.productDetails}>
                {firstBoxDiscountPart ? (
                  <span className={STYLES.pricing}>
                    <Text
                      text={formattedDiscountedPrice || ''}
                      margin={false}
                      translate={false}
                      variant="textRegular16"
                      colour={'brandRed500'}
                      bold
                    />
                    <Text
                      text={`<accent type="discountStrikeThrough">${formattedFullPrice}</accent>`}
                      margin={false}
                      translate={false}
                      variant="textRegular14"
                      colour={'brandBlue500'}
                      element="span"
                    />
                  </span>
                ) : (
                  <Text
                    text={formattedFullPrice}
                    margin={false}
                    translate={false}
                    variant="textRegular16"
                    colour={selected ? 'brandWhite' : 'brandBlue500'}
                    element="span"
                    bold
                  />
                )}
              </div>
              {readMoreOnClick && (
                <div
                  className={`${STYLES.readMore} ${
                    selected ? STYLES.invertedLink : ''
                  }`}
                >
                  <Link
                    text={`${copyContext}.read_more`}
                    onClick={readMoreOnClick}
                    namespace={namespace}
                    suffix={null}
                    identifier="plan_steps.extras.read_more"
                  />
                </div>
              )}
            </div>
            <Button
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() =>
                selected
                  ? removeSelectedExtras(extraProduct)
                  : setSelectedExtras(extraProduct)
              }
              disableAnalytics
              typography={{
                namespace,
                text: selected
                  ? `${copyContext}.remove_cta`
                  : `${copyContext}.add_cta`
              }}
              variant={selected ? 'secondary' : 'primary'}
            />
          </div>
        </div>
      </Card>
    </div>
  )
}

const Extras = ({
  variant,
  namespace,
  recommendedExtraProductVariants
}: Props): JSX.Element | null => {
  const navigate = useNavigate()
  const location = useLocation().pathname as PlanRoute
  const {
    loading,
    data,
    error: reccommendationQueryError
  } = useQuery<RecommendationQuery>(GET_RECOMMENDATIONS_QUERY)
  const { windowWidth } = useWindowSize()

  const copyContext = 'plan_steps.extras'

  const pendingSubscription: PendingSubscription =
    useReactiveVar(plansPageState)
  const wizardState = useReactiveVar(plansPageWizardState)
  const recommendationState = useReactiveVar(planRecommendationState)
  const cadenceState = useReactiveVar(deliveryCadenceState)
  const flowTypeState: FlowType = useReactiveVar(plansFlowTypeState)

  const { productVariants, plan, flavours } = pendingSubscription

  if (!verifyWizardData(wizardState)) handleWizardDataError(wizardState)

  // save pending subscription
  const [
    saveSubscription,
    { loading: saveSubscriptionLoading, error: createPendingSubscriptionError }
  ] = useMutation(CREATE_PENDING_SUBSCRIPTION, {
    variables: {
      pendingSubscription: {
        planId: !isNull(plan) ? plan.id : undefined,
        productVariantIds: !isUndefined(productVariants)
          ? productVariants.map((productVariant) => productVariant.id)
          : [],
        flavourIds: !isNull(flavours)
          ? flavours.map((flavour) => flavour?.id)
          : undefined
      }
    },
    onCompleted: () => {
      if (pendingSubscription.productVariants.length > 0) {
        trackExtrasProductAdded(
          pendingSubscription.productVariants.map(
            ({ productCollection }) => productCollection.name
          ),
          pendingSubscription.productVariants.length,
          pendingSubscription.productVariants.map(
            ({ productCollection }) => productCollection.slug
          ),
          recommendedExtraProductVariants.map(({ id }) => id)
        )
      }

      trackNextButtonClicked(
        location,
        pendingSubscription,
        wizardState,
        recommendationState,
        cadenceState,
        flowTypeState
      )

      navigate(PlansRoutes.Review)
    }
  })

  const setSelectedExtras = useCallback(
    (extraProduct: ExtraProduct) => {
      productVariants.push(extraProduct)
      const updatedPendingSubscription: PendingSubscription = {
        ...pendingSubscription,
        productVariants: productVariants
      }
      plansPageState(updatedPendingSubscription)
    },
    [pendingSubscription, productVariants]
  )

  const removeSelectedExtras = useCallback(
    (extraProduct: ExtraProduct) => {
      trackExtrasProductRemoved(
        productVariants.map(({ productCollection }) => productCollection.name),
        productVariants.length,
        recommendedExtraProductVariants.map(
          ({ productCollection }) => productCollection.slug
        ),
        recommendedExtraProductVariants.map(({ id }) => id)
      )
      const filteredProducts = productVariants?.filter(
        (product) => extraProduct.id !== product.id
      )
      const updatedPendingSubscription: PendingSubscription = {
        ...pendingSubscription,
        productVariants: filteredProducts
      }
      plansPageState(updatedPendingSubscription)
    },
    [pendingSubscription, productVariants, recommendedExtraProductVariants]
  )

  const backButtonClick = useCallback(
    () =>
      trackBackButtonClicked(
        location,
        pendingSubscription,
        wizardState,
        recommendationState,
        cadenceState,
        flowTypeState
      ),
    [
      location,
      pendingSubscription,
      wizardState,
      recommendationState,
      cadenceState,
      flowTypeState
    ]
  )

  useEffect(() => {
    lazyLoad.update()
    trackExtrasSeen(
      recommendedExtraProductVariants.length,
      recommendedExtraProductVariants.map(
        ({ productCollection }) => productCollection.name
      ),
      recommendedExtraProductVariants.map(({ id }) => id)
    )
  }, [recommendedExtraProductVariants])

  const showProductModal = useCallback(
    (slug: string) => {
      additionalProductModalData({ isOpen: true, slug: slug })
      trackAdditionalProductMoreInfoClicked(
        location,
        pendingSubscription,
        wizardState,
        recommendationState,
        cadenceState,
        slug,
        flowTypeState
      )
    },
    [
      cadenceState,
      flowTypeState,
      location,
      pendingSubscription,
      recommendationState,
      wizardState
    ]
  )

  if (
    loading ||
    !data ||
    !data.currentUser ||
    data.currentUser.__typename === 'PreWizardGuest' ||
    !data.currentUser.planRecommendation
  ) {
    return <div />
  }

  const apolloError =
    reccommendationQueryError || createPendingSubscriptionError
  if (apolloError) handleFetchError(apolloError)

  const { currentUser } = data
  const { planRecommendation } = currentUser

  if (!planRecommendation) {
    Sentry.captureException('data.plans in getCadencesFromData is undefined', {
      tags: {
        product: Sentry.Product.PlansFlow
      }
    })
    window.location.href = '/wizard/pet-parent'
    return null
  }

  const { individual } = planRecommendation

  const { countryCode, preferredLanguage } =
    currentUserToLocaleData(currentUser)

  const joinedDogsNames = toLocalisedSentence({
    arr: individual.map((recommendation): string =>
      capitaliseFirstLetter(recommendation.dog.name)
    ),
    lng: preferredLanguage
  })

  const firstBoxDiscountPart = (() => {
    if (currentUser.__typename === 'Guest' && currentUser.discountCode) {
      const {
        discountCode: { discountCodeParts }
      } = currentUser

      return (
        discountCodeParts.find((part) =>
          DiscountCodes.partAppliesToBoxNumber(part, 1)
        ) ?? null
      )
    } else {
      return null
    }
  })()

  const { locale, currency } = countryCodeToLocaleCurrency(
    countryCode,
    preferredLanguage
  )
  const firstBoxDiscount =
    firstBoxDiscountPart &&
    DiscountCodes.formattedPartValue(firstBoxDiscountPart, locale, currency)

  return (
    <div className={`${STYLES.container} ${variant ? STYLES[variant] : ''}`}>
      <HeroText
        variant="plans"
        namespace={namespace}
        title={`plan_steps.stage`}
        variables={{ stepCount: 3, totalSteps: 4 }}
        subtitle={`${copyContext}.header.title`}
        subtitleVariables={{
          context: joinedDogsNames,
          count: recommendedExtraProductVariants.length
        }}
      />
      <div className={STYLES.content}>
        <SwiperSlider
          slidesPerView={1}
          arrows={
            recommendedExtraProductVariants.length > 1 &&
            windowWidth >= BREAKPOINTS.md
          }
          bullets={recommendedExtraProductVariants.length > 1}
        >
          {recommendedExtraProductVariants.map((extraProduct) => (
            <div className={STYLES.cardContainer} key={extraProduct.id}>
              <ExtraCard
                firstBoxDiscountPart={firstBoxDiscountPart}
                extraProduct={extraProduct}
                setSelectedExtras={setSelectedExtras}
                removeSelectedExtras={removeSelectedExtras}
                currentUser={currentUser}
                productVariants={productVariants}
                namespace={namespace}
                copyContext={copyContext}
                // eslint-disable-next-line react/jsx-no-bind
                readMoreOnClick={() =>
                  showProductModal(extraProduct.productCollection.slug)
                }
              />
            </div>
          ))}
        </SwiperSlider>
        <div
          className={`${STYLES.description} ${
            recommendedExtraProductVariants.length === 1
              ? STYLES.descriptionTopMargin
              : ''
          }`}
        >
          {firstBoxDiscountPart && (
            <div className={STYLES.discountSpeechBubble}>
              <SpeechBubble
                footPosition="topRight"
                bubblePosition="right"
                text={{
                  namespace,
                  text: `${copyContext}.speech_bubble`,
                  variables: {
                    firstBoxDiscount
                  },
                  margin: false
                }}
              />
            </div>
          )}
          <Text
            namespace={namespace}
            text={`${copyContext}.footnote`}
            margin={false}
            align="center"
            variant="textRegular16"
            colour="brandBlue400"
          />
        </div>
      </div>
      <StickyNavigation
        disabled={saveSubscriptionLoading}
        variant="twoButtons"
        buttonOne={{
          url: PlansRoutes.Plan,
          variant: 'secondary',
          text: 'plan_steps.navigation.back',
          iconColour: 'brandRed400',
          namespace,
          onClick: backButtonClick
        }}
        buttonTwo={
          !isUndefined(productVariants) && productVariants.length > 0
            ? {
                dataTestId: 'next-button',
                onClick: () => saveSubscription(),
                variant: 'primary',
                text: 'plan_steps.navigation.continue',
                iconColour: 'brandWhite',
                namespace
              }
            : {
                dataTestId: 'next-button',
                onClick: () => saveSubscription(),
                variant: 'secondary',
                text: 'plan_steps.navigation.extras',
                iconColour: 'brandRed400',
                namespace
              }
        }
      />
      <AdditionalProductInformationModal />
    </div>
  )
}

export { Props }
export default Extras
