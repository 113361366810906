// @noflow
import { Language } from '@/packs/localisation'
import type { PaymentMethodable } from '@types'
import { format } from 'date-fns'
import i18next from 'i18next'
import React, { useCallback } from 'react'

import { localeToDateLocale } from '@/utils/countryCodeHelper'

import AlertCard from '@/components/elements/atoms/Alert/AlertCard'
import { Button } from '@/components/elements/atoms/Button'
import Card, { generateVariant } from '@/components/elements/atoms/Card/Card'
import Text, { Props as TextProps } from '@/components/elements/atoms/Text/Text'

import STYLES from './OneTimePurchaseFooter.module.sass'

import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'

type Props = {
  variant?: keyof typeof STYLES
  activePaymentMethodType: PaymentMethodable
  lastFourDigits?: string
  error?: boolean
  errorCopy?: string | null
  loading?: boolean
  submit: () => void
  disabled?: boolean
  paymentDueDate?: Date | null
  setIsButtonClicked?: (x: boolean) => void
  infoText?: TextProps | null
  shippingCountryCode: CountryCode
  alert?: TextProps | null
}

type PaymentMethodProps = {
  paymentDueDate: Date
  activePaymentMethodType: string
  lastFourDigits?: string
  shippingCountryCode: CountryCode
}

type ContactProps = {
  error?: boolean
}

const ContactCustomerLove = ({ error }: ContactProps): JSX.Element => {
  const handleClick = useCallback((): void => {
    if (window && window._dixa_) {
      window._dixa_.invoke('setWidgetOpen', true)
    } else {
      window.location.href = '/contact'
    }
  }, [])

  const linkVariant = error ? 'red' : 'blue'

  return (
    <button
      className={`${STYLES.link} ${STYLES[linkVariant]}`}
      onClick={handleClick}
      type="button"
    >
      <Text
        namespace="one_time_purchase_footer"
        text={'contact_customer_love'}
        variant="textRegular16"
        margin={false}
        colour={error ? 'brandRed600' : 'brandBlue500'}
      />
    </button>
  )
}

const PaymentMethodSubtitle = ({
  paymentDueDate,
  activePaymentMethodType,
  lastFourDigits,
  shippingCountryCode
}: PaymentMethodProps): JSX.Element => {
  const dateLocale = localeToDateLocale(
    shippingCountryCode,
    i18next.language as Language
  )
  // get the date of today without setting a time
  const today = new Date(new Date().toDateString())
  const paymentText =
    today <= paymentDueDate
      ? 'payment_will_be_taken_on_date'
      : 'payment_taken_on_date'
  const date = format(paymentDueDate, 'EEEE, dd MMMM', { locale: dateLocale })

  switch (activePaymentMethodType) {
    case 'CreditCard': {
      return (
        <div className={`${STYLES.cardInfo} ${STYLES.textWrapper}`}>
          <Card
            variant={generateVariant('brandBlue100')}
            shadow={false}
            padding={8}
          >
            <Text
              namespace="one_time_purchase_footer"
              text={`${paymentText}_with_card`}
              variables={{ date, lastFourDigits: lastFourDigits }}
              variant="textRegular16"
              colour="brandBlue500"
              margin={false}
            />
          </Card>
        </div>
      )
    }
    case 'BillingAgreement': {
      return (
        <div className={STYLES.cardInfo}>
          <Card
            variant={generateVariant('brandBlue100')}
            shadow={false}
            padding={8}
          >
            <div className={STYLES.textWrapper}>
              <Text
                namespace="one_time_purchase_footer"
                text={`${paymentText}_with_paypal`}
                variables={{ date }}
                variant="textRegular16"
                margin={false}
                colour="brandBlue500"
                element="span"
              />
              <div className={STYLES.textWrapper}>
                <Text
                  namespace="one_time_purchase_footer"
                  text="to_change_payment_method"
                  variant="textRegular16"
                  margin={false}
                  colour="brandBlue500"
                />
                <ContactCustomerLove />
              </div>
            </div>
          </Card>
        </div>
      )
    }
    case 'SepaDirectDebit': {
      return (
        <div className={STYLES.cardInfo}>
          <Card
            variant={generateVariant('brandBlue100')}
            shadow={false}
            padding={8}
          >
            <div className={STYLES.textWrapper}>
              <Text
                namespace="one_time_purchase_footer"
                text={`${paymentText}_with_direct_debit`}
                variables={{ date }}
                variant="textRegular16"
                margin={false}
                colour="brandBlue500"
                element="span"
              />
              <div className={STYLES.textWrapper}>
                <Text
                  namespace="one_time_purchase_footer"
                  text="to_change_payment_method"
                  variant="textRegular16"
                  margin={false}
                  colour="brandBlue500"
                />
                <ContactCustomerLove />
              </div>
            </div>
          </Card>
        </div>
      )
    }
    case 'DirectDebit': {
      return (
        <div className={STYLES.cardInfo}>
          <Card
            variant={generateVariant('brandBlue100')}
            shadow={false}
            padding={8}
          >
            <div className={STYLES.textWrapper}>
              <Text
                namespace="one_time_purchase_footer"
                text={`${paymentText}_with_direct_debit`}
                variables={{ date }}
                variant="textRegular16"
                margin={false}
                colour="brandBlue500"
                element="span"
              />
              <div className={STYLES.textWrapper}>
                <Text
                  namespace="one_time_purchase_footer"
                  text="to_change_payment_method"
                  variant="textRegular16"
                  margin={false}
                  colour="brandBlue500"
                />
                <ContactCustomerLove />
              </div>
            </div>
          </Card>
        </div>
      )
    }
    default: {
      throw new Error('Missing payment type for one timer purchase footer')
    }
  }
}

const OneTimePurchaseFooter = ({
  variant,
  activePaymentMethodType,
  lastFourDigits = 'xxxx',
  error = false,
  errorCopy,
  loading = false,
  submit,
  disabled = false,
  paymentDueDate,
  setIsButtonClicked,
  infoText,
  shippingCountryCode,
  alert
}: Props): JSX.Element => {
  const paymentMethodText = (
    activePaymentMethodType: PaymentMethodable
  ): string => {
    switch (activePaymentMethodType) {
      case 'CreditCard': {
        return 'pay_with_card'
      }
      case 'BillingAgreement': {
        return 'pay_with_paypal'
      }
      case 'SepaDirectDebit': {
        return 'pay_with_direct_debit'
      }
      case 'DirectDebit': {
        return 'pay_with_gocardless_direct_debit'
      }
      default: {
        throw new Error('Missing payment type for one timer purchase footer')
      }
    }
  }

  const handleClick = useCallback(() => {
    if (setIsButtonClicked) {
      setIsButtonClicked(true)
    }
  }, [setIsButtonClicked])

  const errorText = errorCopy || 'error'

  return (
    <>
      {error && (
        <div className={STYLES.error}>
          <Card
            variant={generateVariant('brandPink200')}
            border="solid"
            shadow={false}
          >
            <div className={STYLES.textWrapper}>
              <Text
                text={errorText}
                variant="textRegular16"
                margin={false}
                colour="brandRed600"
                namespace="one_time_purchase_footer"
              />
              {!errorCopy && <ContactCustomerLove error />}
            </div>
          </Card>
        </div>
      )}
      {alert && (
        <div className={STYLES.alertCard}>
          <AlertCard
            message={{
              namespace: alert.namespace,
              text: alert.text,
              margin: false
            }}
            variant="warning"
          />
        </div>
      )}
      {activePaymentMethodType && (
        <>
          {infoText ? (
            <div className={`${STYLES.cardInfo} ${STYLES.textWrapper}`}>
              <Card
                variant={generateVariant('brandBlue100')}
                shadow={false}
                padding={8}
              >
                <Text
                  namespace={infoText.namespace}
                  text={infoText.text}
                  variables={infoText.variables}
                  variant="textRegular16"
                  colour="brandBlue500"
                  margin={false}
                />
              </Card>
            </div>
          ) : (
            paymentDueDate && (
              <PaymentMethodSubtitle
                paymentDueDate={paymentDueDate}
                activePaymentMethodType={activePaymentMethodType}
                lastFourDigits={lastFourDigits}
                shippingCountryCode={shippingCountryCode}
              />
            )
          )}
          <div
            className={`${STYLES.container} ${variant && STYLES[variant]}`}
            onClick={handleClick}
            onKeyUp={handleClick}
            role="button"
            tabIndex={0}
          >
            <Button
              disableAnalytics
              onClick={submit}
              typography={{
                namespace: 'one_time_purchase_footer',
                text: paymentMethodText(activePaymentMethodType)
              }}
              disabled={loading || disabled}
            />
          </div>
        </>
      )}
    </>
  )
}

export type { Props }

export { PaymentMethodSubtitle }

export default OneTimePurchaseFooter
