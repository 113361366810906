// @noflow

/* eslint-disable i18next/no-literal-string */
import { useNotifications } from '@/context/notifications/notifications'
import { ACCOUNT_ROUTES } from '@/routes'
import { useMutation } from '@apollo/client'
import i18next from 'i18next'
import upperFirst from 'lodash/upperFirst'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { possessive } from '@/utils/StringHelper'

import BREAKPOINTS from '@/constants/Breakpoints'

import useBoolean from '@/hooks/useBoolean'
import useWindowSize from '@/hooks/useWindowSize'

import CheckmarkArea from 'assets/images/icons/checkmarks/checkmark--green-area.svg'
import Female from 'assets/images/illustrations/dogs/dog-about/female.svg'
import Male from 'assets/images/illustrations/dogs/dog-about/male.svg'

import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'
import CheckBox from '@/components/elements/atoms/CheckBox/CheckBox'
import { FixedBase } from '@/components/elements/atoms/FixedBase'
import Image from '@/components/elements/atoms/Image/Image'
import Modal from '@/components/elements/atoms/Modal/Modal'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import SkeletonButton from '@/components/elements/atoms/SkeletonButton/SkeletonButton'
import Text from '@/components/elements/atoms/Text/Text'
import {
  Item,
  MultipleComboBox
} from '@/components/elements/molecules/MultipleComboBox'
import { SelectableCardGroup } from '@/components/elements/molecules/SelectableCardGroup'

import STYLES from './UpdateDogAboutInfo.module.sass'

import { UPDATE_DOG_ABOUT_INFO_MUTATION } from './mutations/updateDogAboutInfoMutation'

import type {
  UpdateDogAboutInfoMutation,
  UpdateDogAboutInfoMutationVariables
} from './mutations/__generated__/UpdateDogAboutInfoMutation'
import type {
  DogAboutInfoQuery_user_dogs as Dog,
  DogAboutInfoQuery
} from './queries/__generated__/DogAboutInfoQuery'
import { Gender, Key } from '@/types'

type Props = Partial<DogAboutInfoQuery> & {
  selectedDog?: Dog
  loading: boolean
}

const commonIconStyles = {
  width: '100%',
  height: 100
}

const GENDERS = [
  {
    value: Gender.female,
    icon: {
      src: Female,
      alt: 'update_dog_profile_about_info.gender.options.female',
      ...commonIconStyles
    },
    typography: {
      text: 'update_dog_profile_about_info.gender.options.female'
    },
    identifier: 'update_dog_profile_about_info.gender.female'
  },
  {
    value: Gender.male,
    icon: {
      src: Male,
      alt: 'update_dog_profile_about_info.gender.options.male',
      ...commonIconStyles
    },
    typography: {
      text: 'update_dog_profile_about_info.gender.options.male'
    },
    identifier: 'update_dog_profile_about_info.gender.male'
  }
]

const UpdateDogAboutInfoContent = ({
  selectedDog,
  loading,
  ...restProps
}: Props): JSX.Element => {
  const container = useRef<HTMLDivElement>(null)
  const { t } = useTranslation('account')
  const { setErrorNotification } = useNotifications()
  const navigate = useNavigate()
  const { windowWidth } = useWindowSize()

  const isMobile = windowWidth < BREAKPOINTS.md

  const loadedData = (() => {
    if (loading) {
      return null
    }

    const { user, breeds } = restProps
    const { id: userId, dogs } = user ?? {}

    return {
      userId,
      dogs,
      breeds
    }
  })()

  const [shouldSetInitialData, setShouldSetInitialData] = useState(true)
  const [aboutInfo, setAboutInfo] = useState({
    name: '',
    gender: Gender.female,
    breed: '',
    unknownBreed: true
  })

  const {
    value: isConfirmationModalOpen,
    setTrue: openConfirmationModal,
    setFalse: closeConfirmationModal,
    toggle: toggleConfirmationModal
  } = useBoolean(false)

  const genderContext = selectedDog?.gender ?? ''
  const possessiveDogName =
    selectedDog && possessive(selectedDog.name, i18next.language)

  const noChangeToAboutInfo = (() => {
    if (selectedDog) {
      const { name, gender, breed } = selectedDog

      return (
        name === aboutInfo.name &&
        gender === aboutInfo.gender &&
        breed.key === aboutInfo.breed
      )
    }
  })()

  // TODO: Add form validation when atomic Input implemented
  const formErrorsDisabled = aboutInfo.name === ''

  const handleOnGenderSelect = useCallback(
    (value) => {
      setAboutInfo({
        ...aboutInfo,
        gender: value
      })
    },
    [aboutInfo]
  )

  const onNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target

      setAboutInfo({
        ...aboutInfo,
        name: value
      })
    },
    [aboutInfo]
  )

  /**
   * Update dog about info mutation
   *
   * On error, show error message.
   * On success, show success message and navigate back to the previous screen.
   */
  const [updateDogAboutInfoMutation, { loading: updateDogAboutInfoLoading }] =
    useMutation<
      UpdateDogAboutInfoMutation,
      UpdateDogAboutInfoMutationVariables
    >(UPDATE_DOG_ABOUT_INFO_MUTATION, {
      onError: () => {
        setErrorNotification({
          text: 'update_dog_profile_about_info.notifications.error',
          namespace: 'account'
        })
      },
      onCompleted: () => {
        closeConfirmationModal()

        navigate(`${ACCOUNT_ROUTES.profile}/${selectedDog?.id}`)
      }
    })

  /**
   * Hander to update dog about info
   */
  const updateDogAboutInfoDetails = useCallback(async () => {
    try {
      const { name, gender, breed, unknownBreed } = aboutInfo

      await updateDogAboutInfoMutation({
        variables: {
          userId: loadedData?.userId ?? '',
          attributes: [
            {
              id: selectedDog?.id ?? '',
              name,
              gender,
              breedKey: (unknownBreed ? 'other' : breed) as Key
            }
          ]
        }
      })
    } catch (e) {
      setErrorNotification({
        text: 'update_dog_profile_about_info.notifications.error',
        namespace: 'account'
      })
    }
  }, [
    selectedDog?.id,
    loadedData?.userId,
    updateDogAboutInfoMutation,
    aboutInfo,
    setErrorNotification
  ])

  useEffect(() => {
    if (shouldSetInitialData && loadedData && selectedDog) {
      setAboutInfo({
        ...aboutInfo,
        name: selectedDog.name,
        gender: selectedDog.gender,
        breed: selectedDog.breed.key,
        unknownBreed: selectedDog.breed.key === 'other'
      })

      // Prevent the effect from running again
      setShouldSetInitialData(false)
    }
  }, [shouldSetInitialData, loadedData, aboutInfo, selectedDog])

  useEffect(() => {
    if (updateDogAboutInfoLoading) {
      openConfirmationModal()
    }
  }, [updateDogAboutInfoLoading, openConfirmationModal])

  const handleSelectBreed = useCallback(
    (selectedBreeds: Array<Item>) => {
      const [breed] = selectedBreeds

      // TODO: Handle multiple breeds when available on the back-end
      setAboutInfo({
        ...aboutInfo,
        breed: selectedBreeds.length === 1 ? breed.id : 'other'
      })
    },
    [aboutInfo]
  )

  const handleOnUnknownBreedSelect = useCallback(() => {
    setAboutInfo({
      ...aboutInfo,
      unknownBreed: !aboutInfo.unknownBreed
    })
  }, [setAboutInfo, aboutInfo])

  const breedOptions =
    loadedData?.breeds?.map(({ key, name }) => ({
      id: key,
      typography: {
        text: name,
        translate: false
      }
    })) ?? []

  const initialSelectedBreed = [
    {
      id: selectedDog?.breed.key ?? '',
      typography: {
        text: selectedDog?.breed.name ?? '',
        translate: false
      }
    }
  ]

  const getSectionWrapperProps = (type: string) =>
    ({
      headerTypography: {
        text: `update_dog_profile_about_info.${type}.title`,
        namespace: 'account',
        align: 'center',
        variables: {
          context: genderContext,
          possessiveDogName: upperFirst(possessiveDogName)
        }
      },
      bgColour: 'brandWhite90',
      borderRadius: 16,
      padding: { top: 16, right: 16, bottom: 16, left: 16 }
    } as const)

  return (
    <div ref={container}>
      <SectionWrapper {...getSectionWrapperProps('name')} margin={{ top: 0 }}>
        <div className={STYLES.sectionContent}>
          <Image
            alt={t('update_dog_profile_about_info.name.img_alt')}
            slug="dogs-name-conversation"
            image={{
              height: 270,
              width: 300
            }}
            className={STYLES.illustration}
          />
          {/* TODO: Replace by atomic Input component when available */}
          {loading ? (
            <SkeletonButton width="100%" height={50} />
          ) : (
            <input
              className={STYLES.input}
              value={aboutInfo.name}
              placeholder={t(
                'update_dog_profile_about_info.name.input_placeholder'
              )}
              onChange={onNameChange}
            />
          )}
        </div>
      </SectionWrapper>
      <SectionWrapper {...getSectionWrapperProps('gender')}>
        <div className={STYLES.sectionContent}>
          <SelectableCardGroup
            options={GENDERS}
            onSelect={handleOnGenderSelect}
            currentValue={aboutInfo.gender}
            cardProps={{
              skeleton: {
                isLoading: loading,
                height: isMobile ? '8rem' : '15rem'
              }
            }}
          />
        </div>
      </SectionWrapper>
      <SectionWrapper {...getSectionWrapperProps('breed')}>
        <div className={STYLES.sectionContent}>
          <Image
            alt={t('update_dog_profile_about_info.breed.merdog_alt')}
            slug="all-breeds"
            image={{
              height: 170,
              width: 300
            }}
            className={STYLES.illustration}
          />
          {selectedDog && (
            <MultipleComboBox
              items={breedOptions}
              initialSelectedItems={initialSelectedBreed}
              placeholder={{
                text: 'update_dog_profile_about_info.breed.input_placeholder',
                translate: true,
                namespace: 'account'
              }}
              disabled={aboutInfo.unknownBreed}
              onStateChange={handleSelectBreed}
            />
          )}
          <Card
            border="dashed"
            background={false}
            variant={{
              background: 'transparent',
              border: 'brandYellow400',
              selected: 'brandYellow200'
            }}
            className={STYLES.unknownBreedCard}
            onClick={handleOnUnknownBreedSelect}
            selected={aboutInfo.unknownBreed}
            skeleton={{
              isLoading: loading,
              height: 130
            }}
            identifier="update_dog_profile_about_info.breed.unknown"
          >
            <div className={STYLES.unknownBreedContent}>
              <Image
                alt={t('update_dog_profile_about_info.breed.merdog_alt')}
                slug="all-breeds"
                image={{
                  height: 79,
                  width: 79
                }}
                className={STYLES.unknownBreedIcon}
              />
              <Text
                namespace="account"
                text="update_dog_profile_about_info.breed.unknown"
                variables={{
                  context: genderContext
                }}
                margin={false}
                variant="display16"
                align="center"
              />
            </div>
            {/* TODO: Update checkbox component or create a new one */}
            <CheckBox
              defaultValue={aboutInfo.unknownBreed}
              id={0}
              tabIndex={0}
              onChange={handleOnUnknownBreedSelect}
            />
          </Card>
        </div>
      </SectionWrapper>
      <FixedBase backgroundColor="brandWhite90" container={container}>
        <div className={STYLES.fixedBaseContent}>
          <Button
            identifier="dog_about_info.update"
            onClick={updateDogAboutInfoDetails}
            typography={{
              text: 'update_dog_profile_about_info.button',
              namespace: 'account'
            }}
            skeleton={{
              isLoading: loading
            }}
            disabled={
              loading ||
              updateDogAboutInfoLoading ||
              noChangeToAboutInfo ||
              formErrorsDisabled
            }
          />
        </div>
      </FixedBase>
      <Modal
        isModalOpen={isConfirmationModalOpen}
        setOpenModal={toggleConfirmationModal}
        width={600}
        textAlign="center"
        fullHeight={isMobile}
        showCloseButton={false}
        backgroundColour="brandYellow100"
      >
        <div className={STYLES.confirmationModalContent}>
          <img
            src={CheckmarkArea}
            alt={t('update_dog_profile_about_info.notifications.confirm.alt')}
          />
          <Text
            namespace="account"
            text="update_dog_profile_about_info.notifications.confirm.text"
            variables={{
              possessiveDogName
            }}
            align="center"
            variant="display28"
          />
        </div>
      </Modal>
    </div>
  )
}

export { UpdateDogAboutInfoContent }
