// @noflow
// Utilities
import isUndefined from 'lodash/isUndefined'
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

import { Code, Language, Language as Languages } from '@/types'

type UserLanguageContextProps = {
  userLanguage: Language
  setUserLanguage: Dispatch<SetStateAction<Language>>
}

type ShippingCountryContextProps = {
  shippingCountry: Code
  setShippingCountry: Dispatch<SetStateAction<Code>>
}

type StripeContextProps = {
  key: string | undefined
  setStripeKey: Dispatch<SetStateAction<string | undefined>>
}

type CsrfTokenContextProps = {
  csrfToken: string | undefined
  setCsrfToken: Dispatch<SetStateAction<string | undefined>>
}

type TokenContextProps = {
  token: string | undefined
  setToken: Dispatch<SetStateAction<string | undefined>>
}

const UserLanguageContext = React.createContext<UserLanguageContextProps>({
  userLanguage: Languages.en,
  setUserLanguage: (prevState: SetStateAction<Language>) => prevState
})

const ShippingCountryContext = React.createContext<ShippingCountryContextProps>(
  {
    shippingCountry: Code.GB,
    setShippingCountry: (prevState: SetStateAction<Code>) => prevState
  }
)

const StripeContext = React.createContext<StripeContextProps>({
  key: undefined,
  setStripeKey: (prevState: SetStateAction<string | undefined>) => prevState
})

const CsrfTokenContext = React.createContext<CsrfTokenContextProps>({
  csrfToken: undefined,
  setCsrfToken: (prevState: SetStateAction<string | undefined>) => prevState
})

const TokenContext = React.createContext<TokenContextProps>({
  token: undefined,
  setToken: (prevState: SetStateAction<string | undefined>) => prevState
})

type InjectedValues = {
  stripeKey: string
  csrfToken: string
  token: string
  country: Code
  language: Language
}

type InjectedValuesProviderProps = InjectedValues & {
  children: JSX.Element
}

/**
 * Use react context to pass in values from the ERB file to React
 * Note - this file should be used sparingly, unless there is a good reason most values
 *        should come from graphQL. Only values which are needed to initialise the app
 *        (we don't want a graphQL request to block the first render) or can't be passed
 *        via graphQL should be passed in this way.
 */
const WithInjectedValues = ({
  language,
  country,
  stripeKey,
  csrfToken,
  token,
  children
}: InjectedValuesProviderProps): JSX.Element | null => {
  const [userLanguage, setUserLanguage] = useState<Language>(Language.en)
  const [shippingCountry, setShippingCountry] = useState<Code>(Code.GB)
  const [userStripeKey, setUserStripeKey] = useState<string | undefined>(
    undefined
  )
  const [userCsrftoken, setUserCsrftoken] = useState<string | undefined>(
    undefined
  )
  const [userToken, setUserToken] = useState<string | undefined>(undefined)

  const shippingCountryProviderValues = useMemo(
    () => ({ shippingCountry, setShippingCountry }),
    [shippingCountry, setShippingCountry]
  )
  const userLanguageProviderValues = useMemo(
    () => ({ userLanguage, setUserLanguage }),
    [userLanguage, setUserLanguage]
  )
  const stripeProviderValues = useMemo(
    () => ({ key: userStripeKey, setStripeKey: setUserStripeKey }),
    [userStripeKey]
  )
  const csrfTokenProviderValues = useMemo(
    () => ({ csrfToken: userCsrftoken, setCsrfToken: setUserCsrftoken }),
    [userCsrftoken]
  )
  const tokenProviderValues = useMemo(
    () => ({ token: userToken, setToken: setUserToken }),
    [userToken]
  )

  useEffect(() => setUserLanguage(language), [language])
  useEffect(() => setShippingCountry(country), [country])
  useEffect(() => {
    setUserStripeKey(stripeKey)
  }, [stripeKey])
  useEffect(() => {
    setUserCsrftoken(csrfToken)
  }, [csrfToken])
  useEffect(() => {
    setUserToken(token)
  }, [token])

  if (
    isUndefined(userLanguage) ||
    isUndefined(shippingCountry) ||
    isUndefined(userStripeKey)
  )
    return null

  return (
    <UserLanguageContext.Provider value={userLanguageProviderValues}>
      <StripeContext.Provider value={stripeProviderValues}>
        <CsrfTokenContext.Provider value={csrfTokenProviderValues}>
          <TokenContext.Provider value={tokenProviderValues}>
            <ShippingCountryContext.Provider
              value={shippingCountryProviderValues}
            >
              {children}
            </ShippingCountryContext.Provider>
          </TokenContext.Provider>
        </CsrfTokenContext.Provider>
      </StripeContext.Provider>
    </UserLanguageContext.Provider>
  )
}

/**
 * Get the users selected language
 */
const useLanguage = (): UserLanguageContextProps =>
  useContext(UserLanguageContext)

/**
 * Get the users shipping country code
 */
const useShippingCountry = (): ShippingCountryContextProps =>
  useContext(ShippingCountryContext)

/**
 * Get the users stripe key
 */
const useStripe = (): StripeContextProps => useContext(StripeContext)

/**
 * Get the users csrf token
 */
const useCsrfToken = (): CsrfTokenContextProps => useContext(CsrfTokenContext)

/**
 * Get the users api token
 */
const useApiToken = (): TokenContextProps => useContext(TokenContext)

export {
  WithInjectedValues,
  useLanguage,
  useShippingCountry,
  useStripe,
  useCsrfToken,
  useApiToken,
  InjectedValues,
  CsrfTokenContext,
  TokenContext
}
