// @flow

import * as React from 'react'
import { connect } from 'react-redux'

// Utils
import capitalize from 'lodash/capitalize'

// Redux
import * as ACTIONS from '../actions'
import * as THUNKS from '../thunks'

// Assets
import search from 'assets/images/icons/search.svg'
import LoadingDog from 'assets/images/illustrations/dogs/loading-dog.svg'

// Types
import type { Dispatch } from 'redux'
import type { State } from '../index'
import type {
  SearchField,
  SearchParams
} from '../actions'
import type { AccountStatusFilter } from '../message_types'
import type { ShippingCountry } from '@/shared_types/rails_models/shipping_countries'

type HTMLInputField = {|
  fieldHTMLFor: string,
  fieldLabel: string,
  fieldStateKey: SearchField,
  placeholder: string,
  inputName: string,
  id: string,
  value: string
|}

type PresentationalProps = {|
  searchParams: SearchParams,
  isSearching: boolean,
  ableToSearch: boolean,
  shippingCountries: Array<ShippingCountry>
|}

type ActionProps = {|
  makeNewSearch: (SearchParams) => void,
  updateSearchString: (SearchField, string) => void,
  clearSearchParams: () => void
|}

type Props =
  & PresentationalProps
  & ActionProps

const mapStateToProps = ({
  searchParams,
  isSearching,
  shippingCountries
}: State): PresentationalProps => {
  // eslint-disable-next-line flowtype/no-flow-fix-me-comments
  // $FlowFixMe
  const entries: Array<[SearchField, string | AccountStatusFilter]> = Object.entries(searchParams)

  // If all of the search fields are empty and the account status dropdown is
  // at 'not-selected' then we are unable to search.
  const ableToSearch = entries.some(([k, v]: [SearchField, string | AccountStatusFilter]): boolean => {
    switch (k) {
      case 'accountStatus': { return v !== 'not-selected' }
      default: { return v !== '' }
    }
  })

  return {
    searchParams,
    isSearching,
    ableToSearch,
    shippingCountries
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => {
  const makeNewSearch = (searchParams: SearchParams): void =>
    dispatch(THUNKS.makeNewSearch(searchParams))
  const updateSearchString = (searchField: SearchField, value: string): void =>
    dispatch(ACTIONS.updateSearchString(searchField, value))
  const clearSearchParams = (): void =>
    dispatch(ACTIONS.clearSearchParams())

  return { makeNewSearch, updateSearchString, clearSearchParams }
}

const SearchButton = ({
  ableToSearch,
  searchParams,
  makeNewSearch
}: {|
  ableToSearch: $PropertyType<Props, 'ableToSearch'>,
  searchParams: $PropertyType<Props, 'searchParams'>,
  makeNewSearch: $PropertyType<Props, 'makeNewSearch'>
|}): React.Element<'button'> => (
  <button
    className={ableToSearch ? 'button--active' : 'button--inactive'}
    type='submit'
    disabled={!ableToSearch}
    onClick={React.useCallback((): void => {
      makeNewSearch(searchParams)
    }, [makeNewSearch, searchParams])}
  >
    <img
      className='icon'
      src={search}
      alt='Search Icon'
    />
    <span>
      { 'Search Butternutters' }
    </span>
  </button>
)

const ClearSearchButton = ({
  ableToSearch,
  clearSearchParams
}: {|
  ableToSearch: $PropertyType<Props, 'ableToSearch'>,
  clearSearchParams: $PropertyType<Props, 'clearSearchParams'>
|}): React.Element<'button'> => (
  <button
    className={ableToSearch ? 'clear button--active' : 'clear button--inactive'}
    onClick={clearSearchParams}
    disabled={!ableToSearch}
    type='reset'
  >
    <span>
      { 'Clear' }
    </span>
  </button>
)

const SearchInputField = ({
  field: {
    fieldHTMLFor,
    fieldLabel,
    placeholder,
    inputName,
    id,
    value
  },
  onKeyUp,
  onChange
}: {|
  field: HTMLInputField,
  onKeyUp: (KeyboardEvent) => void,
  onChange: (SyntheticEvent<HTMLInputElement>) => void
|}): React.Element<'div'> => (
  <div className='search-view__bar__inner__field'>
    <label htmlFor={fieldHTMLFor}>
      { fieldLabel }
    </label>
    <input
      onKeyUp={onKeyUp}
      onChange={onChange}
      placeholder={placeholder}
      name={inputName}
      type='text'
      id={id}
      value={value}
    />
  </div>
)

const SearchBar = ({
  isSearching,
  searchParams,
  makeNewSearch,
  ableToSearch,
  clearSearchParams,
  updateSearchString,
  shippingCountries
}: Props): React.Element<'div'> => {
  if (isSearching) {
    return (
      <div className='search-view__bar--loading'>
        <img
          src={LoadingDog}
          alt='Loading spinner'
        />
      </div>
    )
  }

  const petParentDetailsFields: Array<HTMLInputField> = [
    {
      fieldHTMLFor: 'first-name',
      fieldLabel: 'First name',
      fieldStateKey: 'firstName',
      placeholder: 'e.g Lynsey',
      inputName: 'First name',
      id: 'first-name',
      value: searchParams.firstName
    },
    {
      fieldHTMLFor: 'last-name',
      fieldLabel: 'Last name',
      fieldStateKey: 'lastName',
      placeholder: 'e.g Bovis',
      inputName: 'Last name',
      id: 'last-name',
      value: searchParams.lastName
    },
    {
      fieldHTMLFor: 'email-address',
      fieldLabel: 'Email',
      fieldStateKey: 'email',
      placeholder: 'e.g tomas@butternutbox.com',
      inputName: 'Email address',
      id: 'email-address',
      value: searchParams.email
    },
    {
      fieldHTMLFor: 'phone',
      fieldLabel: 'Phone number',
      fieldStateKey: 'phone',
      placeholder: 'e.g 075551234',
      inputName: 'Phone number',
      id: 'phone',
      value: searchParams.phone
    },
    {
      fieldHTMLFor: 'postcode',
      fieldLabel: 'Postcode',
      fieldStateKey: 'postcode',
      placeholder: 'e.g W12 7FP',
      inputName: 'Postcode',
      id: 'postcode',
      value: searchParams.postcode
    },
    {
      fieldHTMLFor: 'raf-code',
      fieldLabel: 'RAF code',
      fieldStateKey: 'rafCode',
      placeholder: 'e.g Jennis0',
      inputName: 'RAF code',
      id: 'raf-code',
      value: searchParams.rafCode
    }
  ]

  const petDetailsFields: Array<HTMLInputField> = [
    {
      fieldHTMLFor: 'dog-name',
      fieldLabel: 'Dog\'s name (separate multiple names with a comma)',
      fieldStateKey: 'dogsName',
      placeholder: 'e.g Chambó, Buzz, Bella',
      inputName: 'Dog name',
      id: 'dog-name',
      value: searchParams.dogsName
    },
    {
      fieldHTMLFor: 'dog-breed',
      fieldLabel: "Dog's breed",
      fieldStateKey: 'dogsBreed',
      placeholder: 'e.g Polish Hunting Dog',
      inputName: 'Dog breed',
      id: 'dog-breed',
      value: searchParams.dogsBreed
    }
  ]

  return (
    <div className='search-view__bar'>
      <p className='search-view__bar__title'>
        { 'Search Butternutters' }
      </p>
      <p className='search-view__bar__subtitle'>
        { `Pet parent details` }
      </p>
      <div className='search-view__bar__inner'>
        {
          petParentDetailsFields.map((field: HTMLInputField): React.Element<typeof SearchInputField> => (
            <SearchInputField
              key={field.id}
              field={field}
              // If the key pressed was enter, perform makeNewSearch with
              // searchParams otherwise do nothing on key up
              // eslint-disable-next-line react/jsx-no-bind
              onKeyUp={(e: KeyboardEvent): void => e.keyCode === 13 ? makeNewSearch(searchParams) : undefined}
              // eslint-disable-next-line react/jsx-no-bind
              onChange={(e: SyntheticEvent<HTMLInputElement>): void => updateSearchString(field.fieldStateKey, e.currentTarget.value)}
            />
          ))
        }
        <div className='search-view__bar__inner__field'>
          <label htmlFor='account-status'>
            { `Account Status` }
          </label>
          <select
            name="Account Status"
            id="account-status"
            // eslint-disable-next-line react/jsx-no-bind
            onBlur={(e: SyntheticEvent<HTMLInputElement>): void => updateSearchString('accountStatus', e.currentTarget.value)}
            // eslint-disable-next-line react/jsx-no-bind
            onChange={(e: SyntheticEvent<HTMLInputElement>): void => updateSearchString('accountStatus', e.currentTarget.value)}
            value={searchParams.accountStatus}
          >
            <option value="not-selected">
              { 'Not selected' }
            </option>
            {
              ['active', 'paused', 'suspended', 'guest']
                .map((status: AccountStatusFilter): React.Element<'option'> => (
                  <option
                    key={status}
                    value={status}
                  >
                    { capitalize(status) }
                  </option>
                ))
            }
          </select>
        </div>
        <div className='search-view__bar__inner__field'>
          <label htmlFor='shiping-country'>
            { `Shipping Country` }
          </label>
          <select
            name="Shipping Country"
            id="shiping-country"
            // eslint-disable-next-line react/jsx-no-bind
            onBlur={(e: SyntheticEvent<HTMLInputElement>): void => updateSearchString('shippingCountryCode', e.currentTarget.value)}
            // eslint-disable-next-line react/jsx-no-bind
            onChange={(e: SyntheticEvent<HTMLInputElement>): void => updateSearchString('shippingCountryCode', e.currentTarget.value)}
            value={searchParams.shippingCountryCode}
          >
            <option value="not-selected">
              { 'Not selected' }
            </option>
            {
              shippingCountries &&
              shippingCountries.length > 0 &&
              shippingCountries
                .sort((a: ShippingCountry, b: ShippingCountry): number => a.name < b.name ? -1 : 1)
                .map((shippingCountry: ShippingCountry): React.Element<'option'> => {
                  return (
                    <option
                      key={shippingCountry.code}
                      value={shippingCountry.code}
                    >
                      { shippingCountry.name }
                    </option>
                  )
                })
            }
          </select>
        </div>
      </div>
      <p className='search-view__bar__subtitle'>
        { `Pet details` }
      </p>
      <div className='search-view__bar__inner pet-details'>
        {
          petDetailsFields.map((field: HTMLInputField): React.Element<typeof SearchInputField> => (
            <SearchInputField
              key={field.id}
              field={field}
              // If the key pressed was enter, perform makeNewSearch with
              // searchParams otherwise do nothing on key up
              // eslint-disable-next-line react/jsx-no-bind
              onKeyUp={(e: KeyboardEvent): void => e.keyCode === 13 ? makeNewSearch(searchParams) : undefined}
              // eslint-disable-next-line react/jsx-no-bind
              onChange={(e: SyntheticEvent<HTMLInputElement>): void => updateSearchString(field.fieldStateKey, e.currentTarget.value)}
            />
          ))
        }
      </div>
      <div className='button--wrapper'>
        <ClearSearchButton
          ableToSearch={ableToSearch}
          clearSearchParams={clearSearchParams}
        />
        <SearchButton
          ableToSearch={ableToSearch}
          searchParams={searchParams}
          makeNewSearch={makeNewSearch}
        />
      </div>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchBar)
