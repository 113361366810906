// @noflow
import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import SUPPORT_COLOURS from '@/constants/SupportColours'

import STYLES from './SkeletonImage.module.sass'

type Props = {
  baseColor?: string
  highlightColor?: string
  width?: string | number
  height?: string | number
  count?: number
  justify?: 'start' | 'center' | 'end'
  direction?: 'vertical' | 'horizontal'
  margin?: string | number
  borderRadius?: string
}

const SkeletonImage = ({
  baseColor = SUPPORT_COLOURS.grey200,
  highlightColor = 'white',
  width = '100%',
  height = '100%',
  count = 1,
  justify = 'center',
  direction = 'vertical',
  margin,
  borderRadius = '1.6rem'
}: Props): JSX.Element => {
  return (
    <div
      className={`${STYLES.container} ${STYLES[justify]} ${STYLES[direction]}`}
    >
      <SkeletonTheme baseColor={baseColor} highlightColor={highlightColor}>
        <Skeleton
          count={count}
          width={width}
          height={height}
          style={{
            margin,
            borderRadius
          }}
        />
      </SkeletonTheme>
    </div>
  )
}

export { Props }
export default SkeletonImage
