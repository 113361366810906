// @noflow
import { Factory } from 'fishery'

// Factories
import { DogFactory, SubscriptionFactory } from '@/components/factories'
// eslint-disable-next-line no-restricted-imports
import type { User } from '@/components/types'

export default Factory.define<User>(({ sequence }) => ({
  acquisitionChannel: 'Google',
  email: 'user@butternutbox.com',
  firstName: 'Test',
  friendsReferred: 0,
  id: sequence,
  lastName: 'User',
  phone: '07732132132',
  referralsSinceLastLoginCount: 0,
  shippingCountryCode: 'GB',
  preferredLanguage: 'en',
  shouldSupportSca: true,
  token: 'TEST_TOKEN',
  dogs: DogFactory.buildList(1),
  subscription: SubscriptionFactory.build()
}))
