// @noflow
import Grid from '@material-ui/core/Grid'
import classNames from 'classnames'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import React, { useCallback, useContext } from 'react'

import type { PronounContext } from '@/utils/StringHelper'

import segmentTrack from '@/components/analytics/Analytics'
import Card from '@/components/elements/atoms/Card/Card'
import Image from '@/components/elements/atoms/Image/Image'
import Text from '@/components/elements/atoms/Text/Text'
import { NavigateContext } from '@/components/pages/App'
import type { Treatment } from '@/components/pages/TreatmentsPausedPage/helpers/helpers'

import STYLES from './TreatmentSection.module.sass'

type Props = {
  treatments: Treatment[]
  pouchSize: number
  swappablePlanPouchSize: number
  surchargeTotal: string
  savingsAmount: string
  boxPrice: string
  discountedBoxPrice: string
  toggleCareModal: () => void
  namespace: string
  dogNames: string
  context: PronounContext
  downsizeSavingsAmount: string
}

type TreatmentProps = Omit<Props, 'treatments'> & {
  treatment: Treatment
}

const TreatmentCard = ({
  treatment,
  namespace,
  pouchSize,
  swappablePlanPouchSize,
  surchargeTotal,
  savingsAmount,
  boxPrice,
  discountedBoxPrice,
  toggleCareModal,
  dogNames,
  context,
  downsizeSavingsAmount
}: TreatmentProps): JSX.Element => {
  const navigate = useContext(NavigateContext)
  const treatmentCardClicked = useCallback(() => {
    segmentTrack('Treatments Paused Dashboard - Treatment Card Clicked', {
      reactivation_treatment_chosen: treatment.key
    })
    treatment.key === 'care'
      ? toggleCareModal()
      : navigate(
          treatment.spaDestination ?? treatment.destination,
          treatment.destination
        )
  }, [
    treatment.key,
    treatment.spaDestination,
    treatment.destination,
    toggleCareModal,
    navigate
  ])

  const footnoteCopy = treatment.footnote ? treatment.footnote : null

  const clickableCardProps =
    treatment.key === 'fussy_guarantee'
      ? {}
      : {
          onClick: treatmentCardClicked,
          identifier: treatment.key
        }

  return (
    <Card
      shadow={false}
      padding={0}
      squared
      background={false}
      {...clickableCardProps}
    >
      <div className={STYLES.cardContent}>
        <Image
          slug={treatment.imgSlug}
          image={{
            resizeMode: 'resize_to_fill',
            height: 660,
            width: 580
          }}
          alt={treatment.key}
        />
        <div className={STYLES.cardCopy}>
          <Text
            namespace={namespace}
            text={treatment.title}
            variant="display16"
            margin={false}
          />
          <Text
            namespace={namespace}
            text={treatment.body}
            margin={false}
            variant="textRegular14"
            variables={{
              pouchSize,
              swappablePlanPouchSize,
              dogName: dogNames,
              context: context
            }}
          />
          {!isNull(footnoteCopy) && !isUndefined(footnoteCopy) && (
            <Text
              namespace={namespace}
              text={footnoteCopy}
              margin={false}
              variant="display16"
              variables={{
                surchargeTotal,
                savingsAmount,
                boxPrice,
                discountedBoxPrice,
                downsizeSavingsAmount
              }}
            />
          )}
        </div>
      </div>
    </Card>
  )
}

const TreatmentSection = ({
  treatments,
  pouchSize,
  swappablePlanPouchSize,
  surchargeTotal,
  savingsAmount,
  boxPrice,
  discountedBoxPrice,
  toggleCareModal,
  namespace,
  dogNames,
  context,
  downsizeSavingsAmount
}: Props): JSX.Element => {
  return (
    <Grid
      container
      justifyContent="center"
      className={STYLES.treatmentsContainer}
    >
      <Grid item sm={12}>
        <Text
          namespace={namespace}
          text={'treatments_section.title'}
          variant="display24"
          margin={false}
        />
        <Text
          namespace={namespace}
          text={'treatments_section.body'}
          margin={false}
          colour="brandBlue400"
        />
      </Grid>
      <div className={STYLES.cardWrapper} data-testid="paused-treatments">
        {treatments.map((treatment: Treatment): JSX.Element => {
          return (
            <Grid
              data-testid={`paused-treatment-${treatment.key}`}
              item
              sm={12}
              md={4}
              className={STYLES.treatmentCard}
              key={treatment.key}
            >
              <TreatmentCard
                treatment={treatment}
                namespace={namespace}
                pouchSize={pouchSize}
                swappablePlanPouchSize={swappablePlanPouchSize}
                surchargeTotal={surchargeTotal}
                savingsAmount={savingsAmount}
                boxPrice={boxPrice}
                discountedBoxPrice={discountedBoxPrice}
                toggleCareModal={toggleCareModal}
                dogNames={dogNames}
                context={context}
                downsizeSavingsAmount={downsizeSavingsAmount}
              />
            </Grid>
          )
        })}
      </div>
    </Grid>
  )
}

export default TreatmentSection
