import classnames from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'

import STYLES from './CheckBox.module.sass'

import CheckMark from './icon-checkmark.svg'

type MarginRightValue = 0 | 8 | 16 | 24 | 32

type Props = {
  defaultValue: boolean
  id: number
  tabIndex: number
  marginRight?: MarginRightValue
  onChange: (id: number, checked: boolean) => void
}

const CheckBox = ({
  defaultValue,
  id,
  tabIndex,
  marginRight = 16,
  onChange
}: Props): JSX.Element => {
  const [checked, setChecked] = useState(defaultValue)
  const handleChange = useCallback(() => {
    setChecked(!checked)
    onChange(id, !checked)
  }, [checked, onChange, setChecked, id])

  useEffect(() => {
    setChecked(defaultValue)
  }, [defaultValue])

  const className = classnames({
    [STYLES[`marginRight${marginRight}`]]: marginRight
  })

  return (
    <div className={className}>
      <input
        className={STYLES.checkbox}
        type="checkbox"
        checked={checked}
        onChange={handleChange}
      />
      <span
        className={`${STYLES.fakebox} ${checked ? STYLES.checked : ''}`}
        onClick={handleChange}
        onKeyPress={handleChange}
        role="checkbox"
        aria-checked={checked}
        tabIndex={tabIndex}
      >
        <img src={CheckMark} alt="checkmark" />
      </span>
    </div>
  )
}

export { Props }
export default CheckBox
