import { ACCOUNT_ROUTES } from '@/routes'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import OpenBoxWithPouches from 'assets/images/illustrations/boxes/open-box-with-pouches.svg'

import DetailsCard from '@/components/elements/molecules/DetailsCard/DetailsCard'

import { PersonalDetailsQuery_user_subscription_nextEditableBox as Box } from '../../queries/__generated__/PersonalDetailsQuery'

import { Buttons } from './Buttons'

type Props = {
  nextBox: Box | null
  handlePauseSubscription: () => void
  handleModalClose: () => void
}

const DelayBox = ({
  nextBox,
  handlePauseSubscription,
  handleModalClose
}: Props): JSX.Element => {
  const navigate = useNavigate()
  const { id } = nextBox || {}

  const editNextBoxDeliveryDate = useCallback(() => {
    id
      ? navigate(`${ACCOUNT_ROUTES.changeDate}/${id}`)
      : navigate(ACCOUNT_ROUTES.base)
  }, [id, navigate])

  return (
    <>
      <DetailsCard
        onClick={editNextBoxDeliveryDate}
        invert
        label={{
          text: 'subscription_settings.delay_delivery.body'
        }}
        text={{
          text: 'subscription_settings.delay_delivery.title'
        }}
        leftImage={<img height="35px" src={OpenBoxWithPouches} alt={'alt'} />}
        namespace="account"
        variant="brandYellow200"
        identifier="subscription_settings.delay_delivery"
        dataTestId="delay-delivery-card"
      />

      <Buttons
        handleModalClose={handleModalClose}
        keepSubscriptionText="subscription_settings.keep_subscription_cta"
        handlePauseSubscription={handlePauseSubscription}
      />
    </>
  )
}

export { DelayBox }
