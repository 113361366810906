import { gql } from '@apollo/client'

const UPDATE_DOG_PROFILE_PICTURE_MUTATION = gql`
  mutation UpdateDogProfilePictureMutation(
    $userId: ID!
    $avatarsInput: [AvatarInput!]!
  ) {
    avatarsUpdate(userId: $userId, avatarsInput: $avatarsInput) {
      id
      dogs {
        id
        dogProfile {
          id
          avatarUrl
        }
      }
    }
  }
`

export { UPDATE_DOG_PROFILE_PICTURE_MUTATION }
