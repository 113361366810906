// @noflow
import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import ErrorIcon from 'assets/images/icons/crosses/red-cross.svg'

import { Button } from '@/components/shared/elements/Button/Button'

import * as ACTIONS from '../../actions'
import type { Dispatch, State } from '../../reducers'
import {
  selectIsFormValid,
  selectRequiredFormInputs
} from '../../reducers/formDataReducer'
import type { FormInput, Input } from '../../reducers/formDataReducer'
import type { State as RequestsState } from '../../reducers/requestsReducer'
import * as THUNKS from '../../thunks'

type PresentationalProps = {
  formIsInvalid: boolean
  formHasBeenInteractedWithAndHasAnError: boolean
  submitContactFormRequest: RequestsState['submitContactForm']
}

type ActionProps = {
  submitContactForm: () => void
  submitIncompleteContactFormCallback: () => void
}

type Props = PresentationalProps & ActionProps

const mapStateToProps = ({
  formData,
  requests
}: State): PresentationalProps => {
  const formIsInvalid = !selectIsFormValid(formData)

  const formHasBeenInteractedWithAndHasAnError = selectRequiredFormInputs(
    formData
  ).some((formInput: FormInput): boolean => {
    if (formInput instanceof Set) {
      const inputList = formInput as Set<Input>
      return Array.from(inputList).reduce(
        (acc: boolean, input: Input): boolean =>
          acc || input.interaction === 'InteractedWithError',
        false
      )
    } else {
      const input = formInput as Input
      return input.interaction === 'InteractedWithError'
    }
  })

  const { submitContactForm } = requests

  return {
    formIsInvalid,
    formHasBeenInteractedWithAndHasAnError,
    submitContactFormRequest: submitContactForm
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => {
  const submitContactForm = (): void => {
    dispatch(THUNKS.submitContactForm())
  }
  const submitIncompleteContactFormCallback = (): void => {
    dispatch(ACTIONS.probeRequiredFields())
  }

  return {
    submitContactForm,
    submitIncompleteContactFormCallback
  }
}

const SubmitButton = ({
  formIsInvalid,
  formHasBeenInteractedWithAndHasAnError,
  submitContactFormRequest,
  submitContactForm,
  submitIncompleteContactFormCallback
}: Props): React.ReactElement => {
  const namespace = 'contacts'
  const copyContext = 'submit_button'
  const { t } = useTranslation(namespace)
  return (
    <>
      <Button
        disabled={
          formIsInvalid || submitContactFormRequest.status === 'In Flight'
        }
        onClick={submitContactForm}
        typography={{
          text:
            submitContactFormRequest.status === 'In Flight'
              ? `${copyContext}.sending_button`
              : `${copyContext}.send_button`,
          namespace
        }}
        disabledOnClick={submitIncompleteContactFormCallback}
        disableAnalytics
      />
      {formHasBeenInteractedWithAndHasAnError && (
        <p className="invalid-message">
          <img alt="" src={ErrorIcon} />
          {t(`${copyContext}.invalidity_message`)}
        </p>
      )}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SubmitButton)
