import { ACCOUNT_ROUTES } from '@/routes'
import { useReactiveVar } from '@apollo/client'
import Grid from '@material-ui/core/Grid'
import { isUndefined } from 'lodash'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { dogsDataVar } from '@/services/apollo'

import DogsIllustration from 'assets/images/illustrations/dogs/dogs-with-credit-card.svg'

import { Button } from '@/components/elements/atoms/Button'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './NoPaymentMethodSection.module.sass'

const Illustration = () => (
  <img alt="" src={DogsIllustration} className={STYLES.illustration} />
)

const NoPaymentMethodSection = (): JSX.Element | null => {
  const navigate = useNavigate()
  const { possessive, dogs } = useReactiveVar(dogsDataVar) || {}
  const namespace = 'account'
  const handleAddPaymentMethod = useCallback(
    () => navigate(ACCOUNT_ROUTES.addPaymentMethod),
    [navigate]
  )

  return (
    <div className={STYLES.container}>
      <Grid item sm={12} md={6} className={STYLES.content}>
        <Text
          namespace={namespace}
          text={`home.hero_section.no_payment_method.title`}
          variant="display24"
          margin={false}
        />
        <Text
          namespace={namespace}
          text={`home.hero_section.no_payment_method.body`}
          margin={false}
          variables={{
            name:
              !isUndefined(dogs) && dogs.length > 0 && !isUndefined(possessive)
                ? possessive[dogs[0].id]
                : undefined,
            plural: !isUndefined(dogs) && dogs.length > 1
          }}
        />
        <Illustration />
        <Button
          onClick={handleAddPaymentMethod}
          identifier="no_payment_method_section.button"
          typography={{
            namespace,
            text: 'home.hero_section.no_payment_method.cta'
          }}
          variant="primary"
          fullWidth
        />
      </Grid>
    </div>
  )
}

export default NoPaymentMethodSection
