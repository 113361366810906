// @noflow
import React from 'react'

import {
  ICON_COLOURS,
  IconColours
} from '@/components/elements/atoms/Icon/Icon'

type Props = {
  size: number
  accentColour: IconColours
  backgroundColour?: IconColours
  width?: number | string
}

const PaymentMethod = ({
  size,
  accentColour,
  backgroundColour,
  width
}: Props): JSX.Element => {
  return (
    <svg
      width={width}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.12 23.615V12.287c0-.596-.07-1.338 1.072-1.287.469 0 .992-.066 1.448.054 1.366.36 3.029-.032 4.437.185 3.714.571 7.379.62 11.137.62.9 0 2.369-.343 2.865.595.275.519.081 1.043.167 1.602.086.558.101 1.087.101 1.662v7.778c0 .517-.073 1.243-.238 1.74-.193.578-.37.713-.995.767-.957.084-1.883-.1-2.84-.1H12.09c-.808 0-1.768-.085-2.55.13-1.103.305-2.34.19-3.478.19h-1.56c-.316 0-.864.106-1.15-.053-.549-.305-1.233.031-1.233-.857v-1.698Z"
        fill={backgroundColour ? ICON_COLOURS[backgroundColour] : '#fff'}
      />
      <path
        d="M1 22.496V11.168c0-.596-.069-1.339 1.073-1.287.468 0 .992-.066 1.447.054 1.366.36 3.03-.032 4.437.184 3.715.572 7.38.62 11.138.62.899 0 2.368-.343 2.865.595.274.52.08 1.044.166 1.603.086.558.102 1.087.102 1.661v7.779c0 .517-.073 1.242-.239 1.739-.192.578-.37.714-.994.768-.957.083-1.883-.101-2.841-.101H10.97c-.808 0-1.767-.085-2.55.13-1.103.305-2.34.191-3.477.191h-1.56c-.317 0-.864.106-1.15-.053-.55-.305-1.233.03-1.233-.858v-1.697ZM4.488 14.877H8.2"
        stroke={ICON_COLOURS[accentColour]}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M5.369 9.781c.019-.962.403-2.22.914-3.031.247-.392.35-.838.504-1.249.19-.508.527-1.025.643-1.55.074-.329.316-.592.394-.926.067-.29.308-.972.555-1.169.2-.16.161-.643.419-.779.23-.122.618-.082.855 0 .614.213 1.207.513 1.829.72.507.17 1.011.352 1.507.554.336.138.67.28 1.007.42.327.135.81.271 1.084.495.251.205.565.264.864.36.77.247 1.534.56 2.302.821 1.973.67 3.987 1.24 5.978 1.858.917.285 1.81.681 2.747.89.88.195 1.029.936 1.029 1.748 0 .69-.21 1.164-.423 1.824-.127.39-.4 1.033-.66 1.36-.348.433-.477 1.212-.632 1.769-.252.909-.668 1.787-.872 2.705-.052.235-.08.492-.173.724-.118.294-.121.797-.305 1.05-.157.215-.28.697-.364.969-.092.3-.233.534-.3.838-.072.323-1.279.038-1.529.038"
        stroke={ICON_COLOURS[accentColour]}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M7.96 4.904c1.124 0 2.114.437 3.14.78 1.247.415 2.503.838 3.734 1.295.768.284 1.51.637 2.286.914.705.251 1.47.434 2.15.745.375.17.808.219 1.186.398.231.11.658.26.915.288.555.061 1.193.452 1.735.626.504.162 1.022.25 1.524.424.293.1.545.38.855.398.473.026 1.184.325 1.6.533"
        stroke={ICON_COLOURS[accentColour]}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default PaymentMethod
