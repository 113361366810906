import { gql } from '@apollo/client'

const CALORIE_DETAILS_FRAGMENT = gql`
  fragment CalorieDetailsFragment on Dog {
    id
    name
    gender
    possessivePronoun
    planPortionInGrams
    roundedDailyCaloriesUnfulfilledByPlanPortionLowerBoundary: roundedDailyCaloriesPercentageUnfulfilledByPlanPortion(
      multipleOf: $caloriesMultipleOf
      percentage: $lowerPercentage
    )
    roundedDailyCaloriesUnfulfilledByPlanPortionUpperBoundary: roundedDailyCaloriesPercentageUnfulfilledByPlanPortion(
      multipleOf: $caloriesMultipleOf
      percentage: $upperPercentage
    )
    roundedDailyCaloriesLowerBoundary: roundedPercentageOfDailyCalories(
      multipleOf: $caloriesMultipleOf
      percentage: $lowerPercentage
    )
    roundedDailyCaloriesUpperBoundary: roundedPercentageOfDailyCalories(
      multipleOf: $caloriesMultipleOf
      percentage: $upperPercentage
    )
    roundedDailyCaloriesExceededByPlanPortion(multipleOf: $caloriesMultipleOf)
    roundedDailyCaloriesExceededByPlanPortionInPercentage(
      multipleOf: $percentageMultipleOf
    )
    roundedPlanPortionInCalories(multipleOf: $caloriesMultipleOf)
    roundedPlanPortionInPercentage(multipleOf: $percentageMultipleOf)
    roundedUncappedPlanPortionInPercentage(multipleOf: $percentageMultipleOf)
    snackingHabits
  }
`

export { CALORIE_DETAILS_FRAGMENT }
