// @noflow

/* eslint-disable no-restricted-imports */

/* eslint-disable i18next/no-literal-string */
import { ACCOUNT_ROUTES } from '@/routes'
import { isBefore, isToday, parse } from 'date-fns'

import segmentTrack from '@/components/analytics/Analytics'
import type {
  upcomingBoxQuery_user_ordersByDate as Order,
  upcomingBoxQuery_user_ordersByDate_Box_physicalOrderProducts as PhysicalOrderProducts
} from '@/components/pages/Dashboard/components/upcomingBoxes/queries/__generated__/upcomingBoxQuery'
import type { pastOrderQuery_user_ordersByDate as PastOrder } from '@/components/pages/OrdersPage/__generated__/pastOrderQuery'
import { DeliveryStatus } from '@/components/types/DeliveryStatus'

import { NonPaymentReason, Status } from '@/types'

import times from './times'

type OrderDescriptor = 'past' | 'next' | 'future'

/**
 * Get the order chronology from the box ID. Relies on all the upcoming orders
 * being passed via nextOrders.
 * @param id
 * @param nextOrders
 */
const getOrderDescriptor = (
  id: string,
  nextOrders?: {
    id: string
    isoDeliveryDate: string
    descriptor: string
  }[]
): OrderDescriptor => {
  if (Array.isArray(nextOrders) && nextOrders.length > 0) {
    const order = nextOrders.find((order) => order.id === id)
    if (order) {
      return order.descriptor as OrderDescriptor
    }
  }
  return 'past'
}

const isFreshMeal = (order: Order | PastOrder): boolean =>
  order.__typename === 'Box'

const analyticsOnClick = (title: string, link: string): void => {
  segmentTrack(title, { link })
}

const extrasCount = (order: Order | PastOrder): number => {
  if (order.__typename === 'Box') {
    const extrasQuantities = order.physicalOrderProducts.map(
      (product) => product.quantity
    )
    return extrasQuantities.reduce(
      (previousQuantities, currentQuantities) =>
        previousQuantities + currentQuantities,
      0
    )
  }

  const extrasQuantities = order.orderParts[0].orderProducts.map(
    (product) => product.quantity
  )
  return extrasQuantities.reduce(
    (previousQuantities, currentQuantities) =>
      previousQuantities + currentQuantities,
    0
  )
}

const isReplacementBox = (order: Order | PastOrder): boolean | undefined => {
  if (order.__typename === 'Box') return order.isReplacementBox
}

const isFree = (order: Order | PastOrder): boolean | undefined => {
  if (order.__typename === 'Box') {
    return order.isFreeBox
  }
}

const discountPercentage = (order: Order | PastOrder): number | undefined => {
  if (order.__typename === 'Box' && !isFree(order)) {
    return order.order.discountTotalPercentage || undefined
  }
  return undefined
}

const isAmendable = (order: Order | PastOrder): boolean => {
  if (order.__typename === 'NonCoreOrder') {
    return false
  }

  return order.amendable
}

const deliveryDate = (order: Order | PastOrder): Date => {
  if (order.__typename === 'Box') {
    return parse(order.isoDeliveryDate, 'yyyy-MM-dd', new Date())
  }
  return parse(order.orderParts[0].deliveryDate, 'yyyy-MM-dd', new Date())
}

const hasBeenDelivered = (
  deliveryDate: Date,
  deliveryStatus?: DeliveryStatus | null
): boolean => {
  if (deliveryStatus != null && deliveryStatus !== 'unknown')
    return deliveryStatus === 'delivered'
  return isBefore(deliveryDate, Date.now())
}

const isBoxDelivered = (
  order:
    | {
        __typename: 'Box'
        isoDeliveryDate: string
        consignment?: { status: Status | null } | null
        cutOffDate: string
      }
    | {
        __typename: 'NonCoreOrder'
        orderParts: { deliveryDate: string }[]
      }
): boolean => {
  if (order.__typename === 'Box') {
    const deliveryDate = parse(order.isoDeliveryDate, 'yyyy-MM-dd', new Date())
    return hasBeenDelivered(
      deliveryDate,
      order.consignment?.status as DeliveryStatus
    )
  }
  const deliveryDate = parse(
    order.orderParts[0].deliveryDate,
    'yyyy-MM-dd',
    new Date()
  )
  return hasBeenDelivered(deliveryDate)
}

const cutOffDate = (order: Order | PastOrder): Date | undefined => {
  if (order.__typename === 'Box') {
    return parse(order.cutOffDate, 'yyyy-MM-dd', new Date())
  }
}

const deliveryCopy = (
  order: Order | PastOrder,
  boxHasBeenDelivered: boolean
): string => {
  const copyContext = 'dashboard:upcoming_box_card'

  if (order.__typename === 'NonCoreOrder') {
    const deliveryDate = parse(
      order.orderParts[0].deliveryDate,
      'yyyy-MM-dd',
      new Date()
    )

    if (boxHasBeenDelivered) {
      return `${copyContext}.delivery_status.past_delivery`
    }
    if (isToday(deliveryDate)) {
      return `${copyContext}.delivery_status.day_of_delivery`
    }
    return `${copyContext}.delivery_status.post_cut_off`
  }

  const deliveryDate = parse(order.isoDeliveryDate, 'yyyy-MM-dd', new Date())
  const cutOffDate = parse(order.cutOffDate, 'yyyy-MM-dd', new Date())

  if (boxHasBeenDelivered) {
    return `${copyContext}.delivery_status.past_delivery`
  }
  if (isToday(deliveryDate)) {
    return `${copyContext}.delivery_status.day_of_delivery`
  }
  if (isBefore(cutOffDate, Date.now())) {
    return `${copyContext}.delivery_status.post_cut_off`
  }
  return `${copyContext}.delivery_status.pre_cut_off`
}

const link = (order: Order | PastOrder): string => {
  if (order.__typename === 'NonCoreOrder') {
    return `${ACCOUNT_ROUTES.extrasOnly}/${order.id}`
  }
  return `${ACCOUNT_ROUTES.orders}/${order.id}`
}

const getExtrasImages = ({
  order,
  hideSampleProducts
}: {
  order: Order | PastOrder
  hideSampleProducts?: boolean
}): (string | undefined)[] => {
  switch (order.__typename) {
    case 'Box': {
      const { physicalOrderProducts } = order

      return physicalOrderProducts
        .filter((op) =>
          hideSampleProducts
            ? op.nonPaymentReason !== NonPaymentReason.free_sample
            : true
        )
        .map((op) =>
          times(op.quantity).map(
            () => op.productVariant?.productCollection?.thumbnail?.src
          )
        )
        .flat()
    }
    case 'NonCoreOrder': {
      const { orderParts } = order

      return orderParts[0].orderProducts
        .map((op) =>
          times(op.quantity).map(
            () => op.productVariant?.productCollection?.thumbnail?.src
          )
        )
        .flat()
    }
  }
}

const isNextEditableBox = (order: Order | PastOrder): boolean => {
  if (order.__typename === 'Box') return order.isNextEditableBox
  return false
}

const includesSurpriseGift = (order: Order | PastOrder): boolean => {
  if (order.__typename === 'Box') {
    return !!order.order.orderProducts?.find(
      (op) => op.nonPaymentReason && op.nonPaymentReason === 'surprise_gift'
    )
  }
  return false
}

const sampleOrderProducts = (
  order: Order | PastOrder
): Array<PhysicalOrderProducts> => {
  switch (order.__typename) {
    case 'Box': {
      return order.physicalOrderProducts.filter(
        ({ nonPaymentReason }) =>
          nonPaymentReason === NonPaymentReason.free_sample
      )
    }
    case 'NonCoreOrder':
    default: {
      return []
    }
  }
}

export {
  getOrderDescriptor,
  isFree,
  isFreshMeal,
  extrasCount,
  isReplacementBox,
  discountPercentage,
  isAmendable,
  deliveryDate,
  cutOffDate,
  deliveryCopy,
  link,
  analyticsOnClick,
  isBoxDelivered,
  getExtrasImages,
  isNextEditableBox,
  hasBeenDelivered,
  includesSurpriseGift,
  sampleOrderProducts
}
