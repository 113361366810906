import { useNotifications } from '@/context/notifications/notifications'
import { ACCOUNT_ROUTES } from '@/routes'
import { useMutation, useReactiveVar } from '@apollo/client'
import { captureException } from '@sentry/browser'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { userDataVar } from '@/services/apollo'
import { trackEvent } from '@/services/segment'

import dogStanding from 'assets/images/illustrations/dogs/dog-standing.svg'

import AlertCard from '@/components/elements/atoms/Alert/AlertCard'
import LoadingScreen from '@/components/elements/organisms/LoadingScreen/LoadingScreen'
import { NavigateContext } from '@/components/pages/App'
import { findPaymentMethodsAvaliable } from '@/components/pages/CheckoutPage/components/PaymentSection/helpers/paymentMethodsAvaliable'
import { PayPalOption } from '@/components/pages/CheckoutPage/types'
import { pageTitleState } from '@/components/pages/CustomerIssueManagementPage/CustomerIssueManagementPage'
import useAddIdeal from '@/components/pages/MyDetailsPage/hooks/useAddIdeal'
import useAddPaypal from '@/components/pages/MyDetailsPage/hooks/useAddPaypal'
import { useSuccessfulPaymentMethodSubmissionTracking } from '@/components/pages/NoPaymentMethodPage/analytics/NoPaymentMethodAnalytics'

import STYLES from './AddPaymentMethod.module.sass'

import { CREATE_PAYMENT_METHOD_MUTATION } from '../../mutations/CreatePaymentMethod'

import { CreatePaymentMethodMutation } from '../../mutations/__generated__/CreatePaymentMethodMutation'
import { AddPaymentMethodQuery_user as User } from './queries/__generated__/AddPaymentMethodQuery'
import { AffiliateType } from '@/shared_types/graphql/types/enums/user/affiliateType'
import { Affiliate, Code, InputPaymentMethod, Provider } from '@/types'

import useAddBancontact from '../../../../hooks/useAddBancontact'
import { updateCreditCard } from '../../helpers/addPaymentMethod'
import BancontactCard from '../PaymentMethodCards/BancontactCard'
import CreditCardCard from '../PaymentMethodCards/CreditCardCard'
import IdealCard from '../PaymentMethodCards/IdealCard'
import PaypalCard from '../PaymentMethodCards/PaypalCard'

type Props = {
  user: User
  affiliateType: Affiliate
  shippingCountryCode: Code
  csrfToken: string
}

type PaypalProps = {
  csrfToken: string
  token: string
  currentPayPalOption: PayPalOption
  onCreatePaypalBillingAgreement: (token: string) => void
  setLoadingPaypal: (loading: boolean) => void
  onPaymentMethodsError: (errorMessage: string) => void
  loadingPaypal: boolean
  setPaypalAuthorisationLoading: (loading: boolean) => void
  paypalAuthorisationLoading: boolean
}

type IdealProps = {
  csrfToken: string
  fullName: string
  idealBank: string | undefined
  email: string
  token: string
  onCreateSepaDirectDebit: (setupIntentId: string) => void
  onPaymentMethodsError: (errorMessage: string) => void
  onSuccess: () => void
  setLoadingIdeal: (loading: boolean) => void
  inlineErrorMessage: string | null
  disableButton: boolean
  setDisableButton: (disable: boolean) => void
  loadingIdeal: boolean
  setIdealBank: (bank: string) => void
}

type BancontactProps = {
  csrfToken: string
  fullName: string
  userEmail: string
  userToken: string
  redirectUrl: string
  onCreate: (setupIntentId: string) => void
  onError: (errorMessage: string) => void
  onSuccess: () => void
  setLoading: (loading: boolean) => void
  loadingBancontact: boolean
}

type LoadingProps = {
  isOpen: boolean
  variant: 'display28' | 'display20'
}

const payPalOption = (
  affiliateType: AffiliateType,
  shippingCountryCode: Code
): PayPalOption => {
  const avaliablePaymentMethods =
    findPaymentMethodsAvaliable(shippingCountryCode)

  if (affiliateType === 'not_affiliate') {
    return avaliablePaymentMethods.includes('payPal')
      ? 'with_paypal'
      : 'without_paypal'
  } else {
    return 'without_paypal'
  }
}

const LoadingComponent = ({ isOpen, variant }: LoadingProps): JSX.Element => {
  return (
    <div className={STYLES.fullScreenMessage}>
      <LoadingScreen
        isOpen={isOpen}
        title={{
          namespace: 'dashboard',
          text: 'my_details.payment_methods.loading.text',
          variant: variant
        }}
        variant={'static'}
        image={dogStanding}
      />
    </div>
  )
}

const Paypal = ({
  csrfToken,
  token,
  currentPayPalOption,
  onCreatePaypalBillingAgreement,
  setLoadingPaypal,
  onPaymentMethodsError,
  loadingPaypal,
  setPaypalAuthorisationLoading,
  paypalAuthorisationLoading
}: PaypalProps): JSX.Element => {
  const {
    payPalButtonClicked,
    payPalAuthorisationResponse,
    payPalPaymentSuccess
  } = useAddPaypal({
    csrfToken,
    currentPayPalOption,
    responseLoading: loadingPaypal,
    userToken: token,
    redirectUrl: window.location.href,
    onCreate: onCreatePaypalBillingAgreement,
    onError: onPaymentMethodsError,
    setResponseLoading: setLoadingPaypal,
    setAuthorisationLoading: setPaypalAuthorisationLoading
  })

  if (loadingPaypal || !payPalAuthorisationResponse?.redirect_url) {
    return (
      <LoadingComponent
        isOpen={payPalPaymentSuccess || loadingPaypal}
        variant={'display28'}
      />
    )
  }

  return (
    <div className={STYLES.wrapper}>
      <PaypalCard
        paypalAuthorisationLoading={paypalAuthorisationLoading}
        onSubmit={payPalButtonClicked}
      />
    </div>
  )
}

const Ideal = ({
  csrfToken,
  fullName,
  idealBank,
  email,
  token,
  onCreateSepaDirectDebit,
  onPaymentMethodsError,
  onSuccess,
  setLoadingIdeal,
  inlineErrorMessage,
  disableButton,
  setDisableButton,
  loadingIdeal,
  setIdealBank
}: IdealProps): JSX.Element => {
  const { addIdealCallback, idealAuthReceived } = useAddIdeal({
    csrfToken,
    fullName,
    idealBank: idealBank || '',
    userEmail: email,
    userToken: token,
    // eslint-disable-next-line i18next/no-literal-string
    redirectUrl: `${window.location.href}?method_type=ideal`,
    onCreate: onCreateSepaDirectDebit,
    onError: onPaymentMethodsError,
    onSuccess,
    setLoading: setLoadingIdeal
  })

  if (loadingIdeal) {
    return (
      <LoadingComponent
        isOpen={idealAuthReceived || loadingIdeal}
        variant={idealAuthReceived ? 'display28' : 'display20'}
      />
    )
  }

  return (
    <div className={STYLES.wrapper}>
      <IdealCard
        inlineErrorMessage={inlineErrorMessage}
        disableButton={disableButton}
        setDisableButton={setDisableButton}
        onSubmit={addIdealCallback}
        setIdealBank={setIdealBank}
      />
    </div>
  )
}

const Bancontact = ({
  csrfToken,
  fullName,
  userEmail,
  userToken,
  redirectUrl,
  onCreate,
  onError,
  onSuccess,
  setLoading,
  loadingBancontact
}: BancontactProps): JSX.Element => {
  const { addBancontactCallback, bancontactAuthRecieved } = useAddBancontact({
    csrfToken,
    fullName,
    userEmail,
    userToken,
    redirectUrl,
    onCreate,
    onError,
    onSuccess,
    setLoading
  })

  if (loadingBancontact) {
    return (
      <LoadingComponent
        isOpen={bancontactAuthRecieved || loadingBancontact}
        variant={bancontactAuthRecieved ? 'display28' : 'display20'}
      />
    )
  }

  return (
    <div className={STYLES.wrapper}>
      <BancontactCard onSubmit={addBancontactCallback} />
    </div>
  )
}

const AddPaymentMethod = ({
  user,
  affiliateType,
  shippingCountryCode,
  csrfToken
}: Props): JSX.Element => {
  const navigate = useContext(NavigateContext)
  const userData = useReactiveVar(userDataVar)
  const { setSuccessNotification } = useNotifications()
  const [loadingPaypal, setLoadingPaypal] = useState(false)
  const [loadingIdeal, setLoadingIdeal] = useState(false)
  const [loadingBancontact, setLoadingBancontact] = useState(false)
  const [isDefaultPaymentMethod, setIsDefaultPaymentMethod] = useState(true)
  const [idealBank, setIdealBank] = useState<string>()
  const avaliablePaymentMethods =
    findPaymentMethodsAvaliable(shippingCountryCode)
  const [errorMessage, setErrorMessage] = useState<string | null>()
  const [inlineErrorMessage, setInlineErrorMessage] = React.useState<
    string | null
  >(null)
  const [disableButton, setDisableButton] = React.useState<boolean>(true)
  const [paypalAuthorisationLoading, setPaypalAuthorisationLoading] =
    useState(false)
  const fullName = user.firstName + ' ' + user.lastName
  const elements = useElements()
  const stripe = useStripe()
  const currentPayPalOption = payPalOption(affiliateType, shippingCountryCode)
  const { t } = useTranslation('dashboard')
  const trackPaymentMethodPageSubmission =
    useSuccessfulPaymentMethodSubmissionTracking()

  const [createPaymentMethod] = useMutation<CreatePaymentMethodMutation>(
    CREATE_PAYMENT_METHOD_MUTATION,
    {
      onError: (error) => {
        captureException('CreatePaymentMethodMutation failed', {
          extra: {
            error
          },
          tags: {
            component: 'AddPaymentMethodPage'
          }
        })
      },
      onCompleted: () => {
        trackEvent('Successfully added new payment method', {
          component_identifier:
            'AddPaymentMethodPage_CreatePaymentMethod_success'
        })
      }
    }
  )

  const onSuccess = useCallback(() => {
    trackPaymentMethodPageSubmission()
  }, [trackPaymentMethodPageSubmission])

  const onPaymentMethodsError = useCallback(
    (errorMessage: string): void => {
      if (errorMessage === 'Client attempted to refer themselves') {
        setErrorMessage(t('no_payment_method.self_referral_error_message'))
      } else {
        setErrorMessage(t('no_payment_method.error_message'))
      }
      captureException(errorMessage)
    },
    [t]
  )

  const onCreateSepaDirectDebit = useCallback(
    (setupIntentId) => {
      trackEvent('Creating new Payment method', {
        component_identifier:
          'AddPaymentMethodPage_CreatePaymentMethod_SepaDirectDebit_create'
      })
      createPaymentMethod({
        variables: {
          userId: user?.id,
          paymentMethods: [
            {
              paymentMethodId: setupIntentId,
              active: true,
              paymentMethodType: InputPaymentMethod.sepa_direct_debit,
              paymentProvider: Provider.stripe
            }
          ]
        }
      }).then(() => {
        navigate(
          // eslint-disable-next-line i18next/no-literal-string
          `${ACCOUNT_ROUTES.paymentMethods}?update=success`,
          '/dashboard/my-details/payment-methods?update=success'
        )
      })
    },
    [createPaymentMethod, navigate, user?.id]
  )

  const onCreatePaypalBillingAgreement = useCallback(
    (token) => {
      trackEvent('Creating new Payment method', {
        component_identifier:
          'AddPaymentMethodPage_CreatePaymentMethod_PayPal_create'
      })
      createPaymentMethod({
        variables: {
          userId: user?.id,
          paymentMethods: [
            {
              paymentMethodId: token,
              active: true,
              paymentMethodType: InputPaymentMethod.billing_agreement,
              paymentProvider: Provider.paypal
            }
          ]
        }
      }).then(() => {
        navigate(
          // eslint-disable-next-line i18next/no-literal-string
          `${ACCOUNT_ROUTES.paymentMethods}?update=success`,
          '/dashboard/my-details/payment-methods?update=success'
        )
      })
    },
    [createPaymentMethod, navigate, user?.id]
  )

  const onSubmitCreditCardPaymentMethod = useCallback(() => {
    setDisableButton(true)

    if (!stripe || !elements) {
      return captureException(
        `Add credit card payment method button is enabled without Stripe or Stripe Elements initialised`
      )
    }

    const onSuccess = () => {
      trackPaymentMethodPageSubmission()

      setSuccessNotification({
        namespace: 'dashboard',
        text: 'my_details.payment_methods.payment_method_updated'
      })

      navigate(
        // eslint-disable-next-line i18next/no-literal-string
        `${ACCOUNT_ROUTES.paymentMethods}?update=success`,
        '/dashboard/my-details/payment-methods?update=success'
      )
    }

    const onError = (errorMessage: string): void => {
      if (errorMessage === 'Client attempted to refer themselves') {
        setInlineErrorMessage(
          t('no_payment_method.self_referral_error_message')
        )
      } else {
        setInlineErrorMessage(t('no_payment_method.error_message'))
      }
      setDisableButton(true)
      captureException(errorMessage)
    }

    updateCreditCard(
      stripe,
      elements,
      isDefaultPaymentMethod,
      onSuccess,
      onError,
      parseInt(user.id),
      csrfToken,
      user.email,
      'card',
      user.token
    )
      .then(() => {
        setIsDefaultPaymentMethod(false)
      })
      .catch((error) => {
        onError(error)
      })
  }, [
    csrfToken,
    elements,
    isDefaultPaymentMethod,
    navigate,
    setSuccessNotification,
    stripe,
    t,
    trackPaymentMethodPageSubmission,
    user.email,
    user.id,
    user.token
  ])

  useEffect(() => {
    pageTitleState('my_details.payment_methods.add_payment_method')
  }, [])

  return (
    <>
      <div className={STYLES.wrapper}>
        {userData?.acquisitionChannel === 'direct_sales' &&
          userData.subscription.deliveriesReceived === 0 && (
            <div className={STYLES.wrapper}>
              <AlertCard
                message={{
                  namespace: 'dashboard',
                  text: t('my_details.payment_methods.telesales_charge'),
                  margin: true,
                  align: 'left'
                }}
              />
            </div>
          )}
        {/* Error message */}
        {errorMessage && (
          <div className={STYLES.wrapper}>
            <AlertCard
              message={{
                namespace: 'dashboard',
                text: errorMessage,
                margin: false,
                align: 'left'
              }}
              variant="error"
            />
          </div>
        )}
        <CreditCardCard
          disableButton={disableButton}
          inlineErrorMessage={inlineErrorMessage}
          onSubmit={onSubmitCreditCardPaymentMethod}
          setActivePayment={setIsDefaultPaymentMethod}
          setDisableButton={setDisableButton}
          setInlineErrorMessage={setInlineErrorMessage}
        />
      </div>
      {avaliablePaymentMethods.includes('bancontact') && (
        <Bancontact
          csrfToken={csrfToken}
          fullName={fullName}
          userEmail={user?.email}
          userToken={user?.token}
          redirectUrl={`${window.location.href}?method_type=bancontact`}
          onCreate={onCreateSepaDirectDebit}
          onError={onPaymentMethodsError}
          onSuccess={onSuccess}
          setLoading={setLoadingBancontact}
          loadingBancontact={loadingBancontact}
        />
      )}
      {avaliablePaymentMethods.includes('iDeal') && (
        <Ideal
          csrfToken={csrfToken}
          fullName={fullName}
          idealBank={idealBank}
          email={user?.email}
          token={user?.token}
          onCreateSepaDirectDebit={onCreateSepaDirectDebit}
          onPaymentMethodsError={onPaymentMethodsError}
          onSuccess={onSuccess}
          setLoadingIdeal={setLoadingIdeal}
          inlineErrorMessage={inlineErrorMessage}
          disableButton={disableButton}
          setDisableButton={setDisableButton}
          loadingIdeal={loadingIdeal}
          setIdealBank={setIdealBank}
        />
      )}
      {avaliablePaymentMethods.includes('payPal') && (
        <Paypal
          csrfToken={csrfToken}
          token={user?.token}
          currentPayPalOption={currentPayPalOption}
          onCreatePaypalBillingAgreement={onCreatePaypalBillingAgreement}
          setLoadingPaypal={setLoadingPaypal}
          onPaymentMethodsError={onPaymentMethodsError}
          loadingPaypal={loadingPaypal}
          setPaypalAuthorisationLoading={setPaypalAuthorisationLoading}
          paypalAuthorisationLoading={paypalAuthorisationLoading}
        />
      )}
    </>
  )
}

export { Props }
export default AddPaymentMethod
