import { gql } from '@apollo/client'

const GUEST_UPDATE_DETAILS = gql`
  mutation GuestUserUpdate(
    $userId: ID!
    $guestUserDetails: GuestUserDetailsInput!
  ) {
    guestUserUpdate(userId: $userId, guestUserDetails: $guestUserDetails) {
      id
    }
  }
`

export { GUEST_UPDATE_DETAILS }
