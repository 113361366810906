// @noflow
import React from 'react'

import Separator from '@/components/elements/atoms/Separator/Separator'
import Text from '@/components/elements/atoms/Text/Text'
import type { Props as TextProps } from '@/components/elements/atoms/Text/Text'

import STYLES from './SectionWrapper.module.sass'

type Props = {
  children: JSX.Element
  title: TextProps
  titleHelper?: JSX.Element
  className?: string
  showTopSectionDivider?: boolean
  showBottomSectionDivider?: boolean
  marginTop?: boolean
  marginBottom?: boolean
}

const SectionWrapper = ({
  children,
  title: {
    bold = true,
    variant = 'textRegular16',
    element = 'h3',
    shouldScale = false,
    margin = false,
    ...restTextProps
  },
  titleHelper,
  className = '',
  showTopSectionDivider = false,
  showBottomSectionDivider = false,
  marginTop = false,
  marginBottom = false
}: Props): JSX.Element => {
  return (
    <div
      className={`${STYLES.container} ${marginTop ? STYLES.marginTop : ''} ${
        marginBottom ? STYLES.marginBottom : ''
      } ${className || ''}`}
    >
      {showTopSectionDivider && <Separator bold={false} handdrawn />}
      <div className={STYLES.header}>
        <Text
          element={element}
          variant={variant}
          bold={bold}
          shouldScale={shouldScale}
          margin={margin}
          {...restTextProps}
        />
        {titleHelper && titleHelper}
      </div>
      <div>{children}</div>
      {showBottomSectionDivider && <Separator bold={false} handdrawn />}
    </div>
  )
}

export default SectionWrapper
