import { gql } from '@apollo/client'

const GUEST_UPDATE_MARKETING_PREFERENCES = gql`
  mutation GuestMarketingMethodPurposeUpdate(
    $userId: ID
    $authenticationStrategy: AuthenticationStrategy!
    $trackingId: String
    $token: String
    $attribute: String
    $shaType: String
    $encryptionKey: String
    $purposeIds: [ID!]!
  ) {
    guestMarketingMethodPurposeUpdate(
      userId: $userId
      authenticationStrategy: $authenticationStrategy
      trackingId: $trackingId
      token: $token
      attribute: $attribute
      shaType: $shaType
      encryptionKey: $encryptionKey
      purposeIds: $purposeIds
    ) {
      id
    }
  }
`

export { GUEST_UPDATE_MARKETING_PREFERENCES }
