// @noflow
import Cookies from 'js-cookie'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import React, { useCallback, useEffect } from 'react'

import BasicInfoDog from 'assets/images/illustrations/dogs/wizard-footer/basic-info-dog.svg'

import { Button } from '@/components/elements/atoms/Button'
import SpeechBubble from '@/components/elements/atoms/SpeechBubble/SpeechBubble'
import Text from '@/components/elements/atoms/Text/Text'
import ExpertCard from '@/components/elements/molecules/ExpertCard/ExpertCard'
import WizardFooter from '@/components/elements/organisms/WizardFooter/WizardFooter'
import Base from '@/components/templates/Base'

import STYLES from './ConfirmationPage.module.sass'

const ConfirmationPage = (): JSX.Element => {
  const backToAccount = useCallback(() => {
    window.location.href = '/dashboard'
  }, [])

  useEffect(() => {
    Cookies.remove('one_off_box_started')
  }, [])

  const oobDateKey = 'delivery_date_oob'
  const oobDate: string | null = localStorage.getItem(oobDateKey)

  return (
    <Base background="brandYellow100">
      <div className={STYLES.content}>
        {!isNull(oobDate) && !isUndefined(oobDate) && (
          <Text
            align="center"
            namespace="one_off_box_page"
            text={'order_confirmation.delivery_date'}
            variables={{
              date: oobDate
            }}
          />
        )}
        <Text
          align="center"
          namespace="one_off_box_page"
          text={'order_confirmation.box_changes'}
        />
      </div>
      <div className={STYLES.bubble}>
        <Text
          namespace="one_off_box_page"
          text={'order_confirmation.one_last_thing'}
          variant="display20"
          margin={false}
          align="center"
        />
        <SpeechBubble
          footPosition="bottomLeft"
          text={{
            namespace: 'one_off_box_page',
            text: 'order_confirmation.speech_bubble'
          }}
        />
        <ExpertCard
          namespace="expert"
          expert={{
            name: 'Keisha and Gizmo',
            description: 'keisha_gizmo.profession',
            imgSrc: 'Web/photos/people/keisha--square.jpg',
            imgAlt: 'keisha_gizmo.name'
          }}
        />
      </div>
      <div className={STYLES.button}>
        <Button
          typography={{
            namespace: 'one_off_box_page',
            text: 'order_confirmation.cta'
          }}
          disableAnalytics
          onClick={backToAccount}
        />
      </div>
      <WizardFooter
        namespace="wizard_flow"
        variant="customerLove"
        title={'footer.title'}
        subtitle={'footer.subtitle'}
        iconAlt={'footer.guarantee_badge_icon_alt'}
        illustration={{
          img: BasicInfoDog,
          imgAlt: 'footer.basic_info_dog_alt',
          imgPosition: 'left'
        }}
      />
    </Base>
  )
}

export { ConfirmationPage }

export default ConfirmationPage
