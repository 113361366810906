// @noflow
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js'
import React, { useCallback } from 'react'

import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { Locale } from '@/utils/currency'
import * as Sentry from '@/utils/sentry'

import { OnApproveData } from '@paypal/paypal-js/types/components/buttons'

import STYLES from '../PaymentOptions/PaymentOptions.module.sass'

import { State } from '../../PayOnOwnDevicePage'
import useBuySubscriptionWithPayPal, {
  PayPalInContextAction,
  UseBuySubscriptionWithPayPal
} from '../../hooks/paypal/useBuySubscriptionWithPayPal'
import {
  ActivePaymentViews,
  LoginUrlProps
} from '../../screens/PaymentScreen/PaymentScreen'
import { SubmissionState } from '../../types'

type Props = {
  paypalClientId: string
  setActivePaymentView: (view: ActivePaymentViews) => void
  csrfToken: string
  activePaymentView: ActivePaymentViews
  paymentPageState: State
  submissionState: SubmissionState
  navigateToNextStep: (input: LoginUrlProps) => void
}

const PayPal = ({
  paypalClientId,
  csrfToken,
  activePaymentView,
  paymentPageState,
  submissionState,
  navigateToNextStep,
  setActivePaymentView
}: Props): JSX.Element | null => {
  const {
    payPalAuthorisationResponse,
    payPalBillingAgreementTokenReceived,
    payPalButtonClicked,
    handlePayPalInContextSubmission
  } = useBuySubscriptionWithPayPal({
    csrfToken,
    activePaymentView,
    setActivePaymentView,
    paymentPageState,
    navigateToNextStep
  })

  const { locale, currency } = countryCodeToLocaleCurrency(
    paymentPageState.data.directSalesPendingSubscription.address.shippingCountry
      .code,
    paymentPageState.language
  )

  const disablePayPalButton =
    submissionState.type === 'loading' && payPalBillingAgreementTokenReceived

  const handleCreateBillingAgreement = async ({
    payPalAuthorisationResponse
  }: {
    payPalAuthorisationResponse: UseBuySubscriptionWithPayPal['payPalAuthorisationResponse']
  }): Promise<string> => {
    payPalButtonClicked()
    if (
      payPalAuthorisationResponse &&
      payPalAuthorisationResponse?.token !== null
    ) {
      return payPalAuthorisationResponse.token
    } else {
      setActivePaymentView('none')
      Sentry.captureException('Invalid PayPal Authorisation Response', {
        extra: {
          payPalAuthorisationResponse
        },
        tags: {
          product: Sentry.Product.PayOnOwnDevice
        }
      })
      return ''
    }
  }

  const handleOnApprove = useCallback(
    async (data: OnApproveData): Promise<void> => {
      if (data.orderID) {
        handlePayPalInContextSubmission({
          action: PayPalInContextAction.Approve,
          token: data.orderID
        })
      } else {
        Sentry.captureException(
          'Invalid PayPal data.orderID returned to handleOnApprove',
          {
            extra: {
              orderID: data.orderID
            },
            tags: {
              product: Sentry.Product.PayOnOwnDevice
            }
          }
        )
      }
    },
    [handlePayPalInContextSubmission]
  )

  const handleOnCancel = useCallback(
    (data: Record<string, unknown>): void => {
      if (data.orderID) {
        handlePayPalInContextSubmission({
          action: PayPalInContextAction.Cancel,
          token: data.orderID as string
        })
        setActivePaymentView('none')
      } else {
        setActivePaymentView('none')
        Sentry.captureException(
          'Invalid PayPal data.orderID returned to handleOnCancel',
          {
            extra: {
              orderID: data.orderID
            },
            tags: {
              product: Sentry.Product.PayOnOwnDevice
            }
          }
        )
      }
    },
    [handlePayPalInContextSubmission, setActivePaymentView]
  )

  const handleOnError = useCallback(
    (data: Record<string, unknown>): void => {
      if (data.orderID) {
        handlePayPalInContextSubmission({
          action: PayPalInContextAction.Error,
          token: data.orderID as string
        })
        setActivePaymentView('none')
      } else {
        Sentry.captureException(
          'Invalid PayPal data.orderID returned to handleOnError',
          {
            extra: {
              orderID: data.orderID
            },
            tags: {
              product: Sentry.Product.PayOnOwnDevice
            }
          }
        )
        setActivePaymentView('none')
      }
    },
    [handlePayPalInContextSubmission, setActivePaymentView]
  )

  const localeToPaypalLocale = useCallback((locale: Locale) => {
    const localeMap = {
      'en-GB': 'en_GB',
      'en-NI': 'en_GB',
      'en-IE': 'en_GB',
      'en-NL': 'en_GB',
      'nl-NL': 'nl_NL',
      'nl-GB': 'nl_NL',
      'nl-IE': 'nl_NL',
      'nl-NI': 'nl_NL',
      'nl-BE': 'nl_NL',
      'pl-PL': 'pl_PL',
      'fr-BE': 'fr_FR',
      'de-DE': 'de_DE'
    }

    return localeMap[locale] || 'en_GB' // default to 'en_GB' if the locale is not in the map
  }, [])

  if (!paypalClientId || !payPalAuthorisationResponse) {
    return null
  }

  return (
    <PayPalScriptProvider
      options={{
        clientId: paypalClientId,
        currency,
        intent: 'tokenize',
        vault: true,
        locale: localeToPaypalLocale(locale)
      }}
    >
      <div className={STYLES.paypalButton}>
        <PayPalButtons
          disabled={disablePayPalButton}
          style={{ layout: 'horizontal', tagline: false, label: 'paypal' }}
          // eslint-disable-next-line react/jsx-no-bind
          createBillingAgreement={() =>
            handleCreateBillingAgreement({ payPalAuthorisationResponse })
          }
          onApprove={handleOnApprove}
          onCancel={handleOnCancel}
          onError={handleOnError}
        />
      </div>
    </PayPalScriptProvider>
  )
}

export default PayPal
