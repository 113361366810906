import { useMutation, useQuery } from '@apollo/client'
import React, { useCallback } from 'react'
import { toast } from 'react-toastify'

import Icon from '@/components/elements/atoms/Icon/Icon'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import NotificationContent from '@/components/elements/molecules/NotificationContent/NotificationContent'
import {
  MarketingMethodPurposeUpdate,
  MarketingMethodPurposeUpdateVariables
} from '@/components/elements/organisms/MarketingPreferences/mutations/__generated__/MarketingMethodPurposeUpdate'
import { UPDATE_MARKETING_PREFERENCES } from '@/components/elements/organisms/MarketingPreferences/mutations/updateMarketingPreferences'
import { MARKETING_PREFERENCE } from '@/components/elements/organisms/MarketingPreferences/queries/marketingPreferenceQuery'

import { AuthenticationStrategy } from '../../../types/index'
import MarketingPreferences from '../../elements/organisms/MarketingPreferences/MarketingPreferences'

const ContactPreferencesPage = (): JSX.Element => {
  const { data: marketingPreferenceData } = useQuery(MARKETING_PREFERENCE)
  const [updateMarketingPreferences] = useMutation<
    MarketingMethodPurposeUpdate,
    MarketingMethodPurposeUpdateVariables
  >(UPDATE_MARKETING_PREFERENCES)

  const handleDisplayError = useCallback((errorText: string) => {
    toast.error(
      <NotificationContent
        copy={{
          text: errorText,
          namespace: 'account',
          translate: true
        }}
      />,
      {
        icon: <Icon size={20} asset="errorCircle" accentColour="dangerRed300" />
      }
    )
  }, [])

  const handleSubmitPreferences = useCallback(
    (preferenceIds: string[]) => {
      updateMarketingPreferences({
        variables: {
          userId: marketingPreferenceData?.user.id,
          authenticationStrategy: AuthenticationStrategy.user,
          purposeIds: preferenceIds,
          trackingId: null,
          shaType: null,
          encryptionKey: null,
          token: null,
          attribute: null
        },
        onCompleted: () => {
          toast.success(
            <NotificationContent
              copy={{
                text: 'contact_preferences.preferences_updated',
                namespace: 'account',
                translate: true
              }}
            />,
            { toastId: 'alert-success-message' }
          )
        },
        onError: () => {
          toast.error(
            <NotificationContent
              copy={{
                text: 'contact_preferences.preference_update_error',
                namespace: 'account',
                translate: true
              }}
            />,
            {
              icon: (
                <Icon
                  size={20}
                  asset="errorCircle"
                  accentColour="dangerRed300"
                />
              )
            }
          )
        }
      })
    },
    [marketingPreferenceData?.user.id, updateMarketingPreferences]
  )

  return (
    <SectionWrapper
      headerTypography={{
        text: 'titles.contact_preferences',
        namespace: 'account'
      }}
      margin={{ top: 0, bottom: 48 }}
    >
      <MarketingPreferences
        data={marketingPreferenceData}
        handleSubmitPreferences={handleSubmitPreferences}
        handleDisplayError={handleDisplayError}
      />
    </SectionWrapper>
  )
}

export default ContactPreferencesPage
