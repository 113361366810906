import { gql } from '@apollo/client'

const CURRENT_DISCOUNT_CODE = gql`
  query CurrentDiscountCode($discountCodeId: ID!) {
    discountCode(discountCodeId: $discountCodeId) {
      id
      code
    }
  }
`
export { CURRENT_DISCOUNT_CODE }
