import { useReactiveVar } from '@apollo/client'
import React, { useCallback, useEffect, useState } from 'react'

import InputSwitch from './components/InputSwitch'

import { CustomerIssueReportCreate_response_inputFields_CustomerIssueManagementInputFieldMultifield as CustomerIssueManagementInputFieldMultifield } from '../../mutations/__generated__/CustomerIssueReportCreate'
import { CustomerIssueManagementInputInput } from '@/types'

import {
  customerIssueReportState,
  pageHeaderShowBackButtonState,
  pageTitleState
} from '../../CustomerIssueManagementPage'
import useCustomerIssueManagementTracking from '../../analytics'
import { InputField } from '../../types/types'
import ContactCustomerLove from '../ContactCustomerLove/ContactCustomerLove'

type AnalyticItem = {
  name: string
  value: string | boolean | number
}

type Props = {
  submitCustomerIssueEntryInputsCreate: (
    inputs: Array<CustomerIssueManagementInputInput>,
    reasonId?: string
  ) => void
}

const Inputs = ({
  submitCustomerIssueEntryInputsCreate
}: Props): JSX.Element | null => {
  const pageHeaderShowBackButtonData = useReactiveVar(
    pageHeaderShowBackButtonState
  )
  const customerIssueManagementTracking = useCustomerIssueManagementTracking()
  const [initialised, setInitialised] = useState(false)
  const [inputs, setInputs] = useState<
    Array<CustomerIssueManagementInputInput>
  >([])
  const [reason, setReason] = useState('')
  const { inputFields, categoryReasons } = useReactiveVar(
    customerIssueReportState
  )

  const updateInputs = useCallback(
    (
      fieldId: string,
      newInputs: Array<CustomerIssueManagementInputInput>
    ): void => {
      const inputsWithoutOldInputs = inputs.filter(
        (input) => input.fieldId !== fieldId
      )
      const updatedInputs = inputsWithoutOldInputs.concat(newInputs)
      setInputs(updatedInputs)
    },
    [inputs]
  )

  const updateReason = useCallback(
    (newReason: string) => {
      customerIssueManagementTracking.trackEvent('reason changed', {
        reason: newReason
      })
      setReason(newReason)
    },
    [customerIssueManagementTracking]
  )

  const getReadableSubmittedValues = useCallback(
    (
      fields: Array<InputField>,
      inputValues: Array<CustomerIssueManagementInputInput>
    ): Array<AnalyticItem> => {
      return fields.flatMap((inputField) => {
        const input = inputValues.find(
          (x) => x.fieldId === inputField.id
        ) as CustomerIssueManagementInputInput
        const multifieldInputs =
          input.inputs as Array<CustomerIssueManagementInputInput>
        const multifieldField =
          inputField as CustomerIssueManagementInputFieldMultifield

        switch (inputField.dataType) {
          case 'association':
            return {
              name: inputField.name,
              value: input.associationValue as string
            }
          case 'string':
            return {
              name: inputField.name,
              value: input.stringValue as string
            }
          case 'boolean':
            return {
              name: inputField.name,
              value: input.booleanValue as boolean
            }
          case 'integer':
            return {
              name: inputField.name,
              value: input.integerValue as number
            }
          case 'multifield':
            return getReadableSubmittedValues(
              multifieldField.fields as Array<InputField>,
              multifieldInputs
            )
          default:
            return []
        }
      })
    },
    []
  )

  const submit = useCallback(() => {
    const trackingValues = getReadableSubmittedValues(
      inputFields as Array<InputField>,
      inputs
    )
    customerIssueManagementTracking.trackEvent('submit issue', {
      values: trackingValues
    })
    submitCustomerIssueEntryInputsCreate(inputs, reason)
  }, [
    submitCustomerIssueEntryInputsCreate,
    getReadableSubmittedValues,
    customerIssueManagementTracking,
    inputFields,
    inputs,
    reason
  ])

  useEffect(() => {
    if (!initialised) {
      pageTitleState('inputs.title')
      setInitialised(true)
    }
  }, [initialised, customerIssueManagementTracking])

  if (!initialised) return null
  if (!inputFields || inputFields.length === 0 || !pageHeaderShowBackButtonData)
    return <ContactCustomerLove />

  return (
    <div className="inputs">
      <InputSwitch
        inputFields={inputFields}
        categoryReasons={categoryReasons}
        updateInputs={updateInputs}
        setReason={updateReason}
        submit={submit}
        inputs={inputs}
        reason={reason}
      />
    </div>
  )
}

export default Inputs
