import React from 'react'
import { useLocation } from 'react-router-dom'

function useQueryParam(param: string): string | null {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { search } = useLocation()

  return React.useMemo(
    () => new URLSearchParams(search).get(param),
    [param, search]
  )
}

export default useQueryParam
