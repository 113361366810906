// @noflow

type Route =
  | '/plans/recipes'
  | '/plans/plan'
  | '/plans/extras'
  | '/plans/review'

const Recipes: Route = '/plans/recipes'
const Plan: Route = '/plans/plan'
const Extras: Route = '/plans/extras'
const Review: Route = '/plans/review'

const PlansRoutes = {
  Recipes,
  Plan,
  Extras,
  Review
}

export type { Route, PlansRoutes }

export default PlansRoutes
