// @noflow

/* eslint-disable react/jsx-no-useless-fragment */
import type { Language } from '@/packs/localisation'
import { ApolloError, useQuery } from '@apollo/client'
import { formatISO } from 'date-fns'
import isUndefined from 'lodash/isUndefined'
import React, { Fragment, useCallback } from 'react'
import { InView } from 'react-intersection-observer'

import {
  analyticsOnClick,
  cutOffDate,
  deliveryCopy,
  deliveryDate,
  discountPercentage,
  extrasCount,
  getExtrasImages,
  isAmendable,
  isBoxDelivered,
  isFree,
  isFreshMeal,
  isNextEditableBox,
  isReplacementBox,
  link,
  sampleOrderProducts
} from '@/utils/orderHelper'

import DogInBox from 'assets/images/illustrations/dogs/dachshund-in-box-blue-300.svg'

import Spinner from '@/components/elements/atoms/Spinner/Spinner'
import Text from '@/components/elements/atoms/Text/Text'
import OrderCard, {
  MAX_PRODUCT_THUMBNAILS_TO_SHOW
} from '@/components/elements/molecules/OrderCard/OrderCard'
import { ErrorState } from '@/components/elements/organisms/ErrorState'
import type {
  pastOrderQuery,
  pastOrderQuery_user_ordersByDate
} from '@/components/pages/OrdersPage/__generated__/pastOrderQuery'
import type { upcomingOrderQuery_user_ordersByDate } from '@/components/pages/OrdersPage/__generated__/upcomingOrderQuery'
import { PAST_ORDERS } from '@/components/pages/OrdersPage/queries'

import STYLES from './OrdersPage.module.sass'

import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'

type Props = {
  variant: Language
}

type OrderListProps = {
  orders:
    | pastOrderQuery_user_ordersByDate[]
    | upcomingOrderQuery_user_ordersByDate[]
  shippingCountryCode: CountryCode
  showExtrasInShippingCountry: boolean
}

const handleFetchError = (
  variant: Language,
  error: ApolloError
): JSX.Element => {
  return (
    <ErrorState
      error={{
        name: 'Error',
        message: error.message,
        apollo: error
      }}
      first_line={{
        namespace: 'care',
        text: 'error_page.text_1'
      }}
      second_line={{
        namespace: 'care',
        text: 'error_page.text_2'
      }}
    />
  )
}

const Card = ({
  order,
  shippingCountryCode,
  showExtrasInShippingCountry
}: {
  order: pastOrderQuery_user_ordersByDate | upcomingOrderQuery_user_ordersByDate
  shippingCountryCode: CountryCode
  showExtrasInShippingCountry: boolean
}) => {
  const { id } = order

  return (
    <div className={STYLES.singleCard}>
      <OrderCard
        isFreshMeal={isFreshMeal(order)}
        extrasCount={extrasCount(order)}
        isReplacementBox={isReplacementBox(order)}
        isFree={isFree(order)}
        deliveryDate={deliveryDate(order)}
        cutOffDate={cutOffDate(order)}
        isAmendable={isAmendable(order)}
        discountPercentage={discountPercentage(order)}
        link={link(order)}
        onClickAnalytics={analyticsOnClick}
        deliveryCopy={deliveryCopy(order, isBoxDelivered(order))}
        shippingCountryCode={shippingCountryCode}
        key={id}
        isBoxDelivered={isBoxDelivered(order)}
        isNextEditableBox={isNextEditableBox(order)}
        sampleOrderProducts={sampleOrderProducts(order)}
        extrasImages={getExtrasImages({
          order,
          hideSampleProducts: sampleOrderProducts.length > 0
        }).slice(0, MAX_PRODUCT_THUMBNAILS_TO_SHOW)}
        showExtrasInShippingCountry={showExtrasInShippingCountry}
      />
    </div>
  )
}

const EmptyState = ({
  namespace,
  pastBox
}: {
  namespace: string
  pastBox: boolean
}) => (
  <div className={STYLES.emptyState}>
    <img alt="Dog in box" src={DogInBox} />
    <Text
      namespace={namespace}
      text={pastBox ? 'tabs.no_past_orders' : 'tabs.no_upcoming_orders'}
      align="center"
      colour="brandBlue400"
      variant="display16"
    />
    <Text
      namespace={namespace}
      text={
        pastBox
          ? 'tabs.no_past_orders_copy_text'
          : 'tabs.no_upcoming_orders_copy_text'
      }
      align="center"
      colour="brandBlue400"
      margin={false}
    />
  </div>
)

const OrderListWithYears = ({
  orders,
  shippingCountryCode
}: Omit<OrderListProps, 'showExtrasInShippingCountry'>) => {
  // orders segregated by year
  const ordersByYear = orders.reduce((acc, order) => {
    const year =
      order.__typename === 'Box'
        ? new Date(order.isoDeliveryDate).getFullYear()
        : new Date(order.orderParts[0].deliveryDate).getFullYear()
    if (acc[year]) {
      acc[year].push(order)
    } else {
      acc[year] = [order]
    }
    return acc
  }, {} as Record<string, Array<pastOrderQuery_user_ordersByDate>>)

  return (
    <div className={STYLES.tabContent}>
      {Object.keys(ordersByYear)
        .reverse()
        .map((year) => {
          return (
            <Fragment key={year}>
              <Text text={year} variant="display18" translate={false} />
              {ordersByYear[year].map((order) => (
                <Card
                  key={order.id}
                  order={order}
                  shippingCountryCode={shippingCountryCode}
                  showExtrasInShippingCountry={false}
                />
              ))}
            </Fragment>
          )
        })}
    </div>
  )
}

const OrdersPage = ({ variant }: Props): JSX.Element => {
  const namespace = 'orders'
  const {
    error: pastOrderError,
    data: pastOrderData,
    fetchMore: pastOrderFetchMore,
    loading: pastOrderLoading
  } = useQuery<pastOrderQuery>(PAST_ORDERS, {
    variables: {
      to: formatISO(new Date().setHours(0, 0, 0, 0)),
      offset: 0,
      limit: 5,
      order: 'desc'
    },
    fetchPolicy: 'cache-and-network'
  })

  const onLoadMore = useCallback((): void => {
    pastOrderFetchMore({
      variables: { offset: pastOrderData?.user?.ordersByDate?.length }
    })
  }, [pastOrderFetchMore, pastOrderData?.user?.ordersByDate?.length])

  const handleInView = useCallback(
    (inView): void => {
      if (inView) {
        onLoadMore()
      }
    },
    [onLoadMore]
  )

  if (pastOrderLoading) {
    return (
      <div className={STYLES.loading}>
        <Spinner variant="brandYellow500" />
      </div>
    )
  }

  if (pastOrderError) return handleFetchError(variant, pastOrderError)

  if (
    isUndefined(pastOrderData) ||
    pastOrderData.user.ordersByDate.length === 0
  ) {
    return <EmptyState namespace={namespace} pastBox />
  }

  return (
    <>
      <OrderListWithYears
        orders={pastOrderData.user.ordersByDate}
        shippingCountryCode={pastOrderData.user.shippingCountryCode}
      />
      <InView onChange={handleInView} rootMargin={'0px 0px 200px 0px'} />
    </>
  )
}

export default OrdersPage
