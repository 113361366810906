import { gql } from '@apollo/client'

const SUSPENDED_SECTION_QUERY = gql`
  query SuspendedSectionQuery {
    user {
      id
      subscription {
        id
        status
        suspendedForInsufficientFunds
        activePaymentMethodType
        increasedDeliveryCadencePlan {
          id
        }
      }
    }
  }
`

export { SUSPENDED_SECTION_QUERY }
