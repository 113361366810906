// @noflow
import { Occasions, useOccasion } from '@/context/festiveTheme/festiveTheme'
import { useQuery } from '@apollo/client'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import i18next from 'i18next'
import isUndefined from 'lodash/isUndefined'
import React from 'react'

import { getLocalisedPathnames } from '@/utils/urls'

// Utils
import { SOCIAL_MEDIA_BASE_URLS } from '@/constants/urls'

import { triggerCookieConsentModal } from '@/components/cookie_consent'
// Assets
import BCorpLogo from '@/components/elements/atoms/BCorpLogo/BCorpLogo'
import NavLink, {
  Props as NavLinkProps
} from '@/components/elements/atoms/NavLink/NavLink'
import SocialLink from '@/components/elements/atoms/SocialLink/SocialLink'
// Components
import Text from '@/components/elements/atoms/Text'
import { XmasDogs } from '@/components/elements/molecules/Footer/XmasDogs'

// Styles
import STYLES from './Footer.module.sass'

import { FOOTER_QUERY } from './queries/footerQuery'

import {
  FooterQuery,
  FooterQuery_user_shippingCountry_footerLinks as Link
} from './queries/__generated__/FooterQuery'
import { Code, FooterColumn, Language, SubscriptionStatus } from '@/types'

import { ErrorState } from '../../organisms/ErrorState'
import { FooterSkeleton } from './FooterSkeleton'

const getHoverColor = (occasion?: Occasions): NavLinkProps['hoverColour'] => {
  if (occasion === Occasions.xmas) return 'brandBlue200'
  return 'brandYellow500'
}

const filterByColumn = (links: Link[], columnName: FooterColumn) =>
  links.filter(({ footerColumn }) => footerColumn === columnName)

const filterSubscriptionStatus = (
  footerLinks: Link[],
  userStatus: SubscriptionStatus | ''
): { [key: string]: Link[] } => {
  const links = footerLinks.filter((link: Link) => {
    if (link.availableForStatuses.length <= 0) return true

    if (!userStatus) return false

    return link.availableForStatuses.includes(userStatus)
  })

  return {
    all: links,
    column1: filterByColumn(links, FooterColumn.column_1),
    column2: filterByColumn(links, FooterColumn.column_2),
    column3: filterByColumn(links, FooterColumn.column_3)
  }
}

/**
 * Footer component
 *
 * Use this component to render a footer with links.
 *
 * @example
  ```
  <Footer />
  ```
 * @returns {JSX.Element} - Footer component
 * @category Components
 * @subcategory Molecules
 * @component Footer
 */
const Footer = (): JSX.Element => {
  const { loading, data, error } = useQuery<FooterQuery>(FOOTER_QUERY)
  const { xmas, festiveClass, occasion, optedIn } = useOccasion()

  if (loading) return <FooterSkeleton />

  if (error)
    return (
      <ErrorState
        error={{
          name: 'Error',
          message: error.message,
          apollo: error
        }}
      />
    )

  const { user } = data || {
    user: {
      subscription: { status: '' },
      shippingCountry: {
        code: '',
        footerLinks: []
      }
    }
  }

  const {
    shippingCountry: { code: shippingCountryCode, footerLinks }
  } = user

  const hideBCorpLogo = shippingCountryCode === Code.PL

  const pathnames = shippingCountryCode
    ? getLocalisedPathnames(shippingCountryCode)
    : {}

  const { column1, column2, column3 } = filterSubscriptionStatus(
    footerLinks,
    user?.subscription.status
  )

  const columns = [
    {
      title: 'footer.about_us.title',
      links: column1
    },
    {
      title: 'footer.digging_deeper.title',
      links: column2
    },
    {
      title: 'footer.help.title',
      links: column3
    }
  ]
  const subFooterLinks = [
    { text: 'extra_links.use_terms', href: `/${pathnames.TERMS_OF_USE}` },
    { text: 'extra_links.privacy', href: `/${pathnames.PRIVACY_POLICY}` },
    {
      text: 'extra_links.terms_conditions',
      href: `/${pathnames.TERMS_AND_CONDITIONS}`
    },
    {
      text: 'extra_links.cookies',
      onClick: () => {
        triggerCookieConsentModal()
      }
    }
  ]

  const socialMediaLinks = [
    {
      icon: 'tiktok-icon',
      href: pathnames.TIK_TOK,
      title: 'TikTok',
      key: 'TIK_TOK'
    },
    {
      icon: 'twitter-icon',
      href: pathnames.TWITTER,
      title: 'Twitter',
      key: 'TWITTER'
    },
    {
      icon: 'instagram-icon',
      href: pathnames.INSTAGRAM,
      title: 'Instagram',
      key: 'INSTAGRAM'
    },
    {
      icon: 'facebook-icon',
      href: pathnames.FACEBOOK,
      title: 'Facebook',
      key: 'FACEBOOK'
    },
    {
      icon: 'youtube-icon',
      href: pathnames.YOUTUBE,
      title: 'Youtube',
      key: 'YOUTUBE'
    }
  ]

  const hoverColor = getHoverColor(optedIn ? occasion : undefined)

  const { language } = i18next

  return (
    <footer
      className={`${STYLES.wrapper} ${
        !isUndefined(festiveClass) ? STYLES[festiveClass] : ''
      }`}
    >
      {xmas && language === Language.en && (
        <div className={STYLES.xmasDogs}>
          <XmasDogs />
        </div>
      )}
      <div className={`${STYLES.footerWrapper}`}>
        <Container maxWidth="lg">
          <Grid container className={STYLES.footerGridContainer}>
            <Grid item md={2} />
            {columns.map(({ title, links }) => (
              <Grid
                key={title}
                item
                xs={6}
                md={2}
                className={STYLES.footerLinksContent}
              >
                <Text
                  text={title}
                  namespace="molecules"
                  variant="display20"
                  margin={false}
                />
                <div className={STYLES.footerLinks}>
                  {links.map(({ title, url }) => (
                    <NavLink
                      key={title}
                      text={{
                        text: title,
                        translate: false
                      }}
                      href={url}
                      hoverColour={hoverColor}
                    />
                  ))}
                </div>
              </Grid>
            ))}
            <Grid item xs={6} md={2} container className={STYLES.footerLogo}>
              {!hideBCorpLogo && <BCorpLogo />}
            </Grid>
            <Grid item md={2} />
          </Grid>
        </Container>
      </div>
      <div className={STYLES.subFooterWrapper}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item md={1} />
            <Grid item xs={12} md={10} className={STYLES.subFooterContent}>
              <div className={STYLES.subFooterLinks}>
                {subFooterLinks.map(({ text, href = '', onClick }) => (
                  <NavLink
                    key={text}
                    text={{
                      text,
                      namespace: 'footer'
                    }}
                    href={href}
                    {...(onClick && { onClick })}
                    hoverColour={hoverColor}
                  />
                ))}
              </div>
              <div className={STYLES.socialLinks}>
                {socialMediaLinks.map(({ title, icon, href, key }) => {
                  if (href === '') return null
                  return (
                    <SocialLink
                      key={title}
                      href={`${
                        SOCIAL_MEDIA_BASE_URLS[
                          key as keyof typeof SOCIAL_MEDIA_BASE_URLS
                        ]
                      }/${href}`}
                      iconSrc={icon}
                      alt={title}
                      iconWidth={36}
                      iconHeight={36}
                    />
                  )
                })}
              </div>
            </Grid>
            <Grid item md={1} />
          </Grid>
        </Container>
      </div>
    </footer>
  )
}

export { Footer }
