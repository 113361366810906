import React from 'react'

function useQueryParam(param: string, search: string): string | null {
  return React.useMemo(
    () => new URLSearchParams(search).get(param),
    [param, search]
  )
}

export default useQueryParam
