import Grid from '@material-ui/core/Grid'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import DogWithHearts from 'assets/images/illustrations/dogs/dog-with-hearts.svg'

import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'
import CheckBox from '@/components/elements/atoms/CheckBox/CheckBox'
import Text from '@/components/elements/atoms/Text'
import STYLES from '@/components/elements/organisms/MarketingPreferences/MarketingPreferences.module.sass'
import {
  MarketingPreferenceQuery_user_availableMarketingMethodPurposes_marketingMethod as MarketingMethod,
  MarketingPreferenceQuery,
  MarketingPreferenceQuery_user_availableMarketingMethodPurposes_marketingPurpose as MarketingPurpose
} from '@/components/elements/organisms/MarketingPreferences/queries/__generated__/MarketingPreferenceQuery'

type Props = {
  data?: MarketingPreferenceQuery | null
  handleSubmitPreferences: (preferenceIds: string[]) => void
  handleDisplayError: (errorText: string) => void
}

type MarketingPreferencesState = {
  isUnsubscribeChecked: boolean
  isInteracted: boolean
  selectedMarketingMethodIds: Array<string>
  selectedMarketingPurposeIds: Array<string>
}

const MarketingPreferences = ({
  handleSubmitPreferences,
  data,
  handleDisplayError
}: Props): JSX.Element => {
  const [initialised, setInitialised] = useState(false)
  const [marketingPreferences, setMarketingPreferences] =
    useState<MarketingPreferencesState>({
      isUnsubscribeChecked: false,
      isInteracted: false,
      selectedMarketingMethodIds: [],
      selectedMarketingPurposeIds: []
    })

  const {
    availableMethods,
    availablePurposes,
    existingPreferences,
    preselectedPurposeIds,
    preselectedMethodIds
  } = useMemo(() => {
    if (!data) {
      return {
        availableMethods: [],
        availablePurposes: [],
        existingPreferences: [],
        preselectedPurposeIds: [],
        preselectedMethodIds: []
      }
    }

    const {
      availableMarketingMethodPurposes: availablePreferences,
      selectedMarketingMethodPurposes: existingPreferences
    } = data.user

    const availableMethods = availablePreferences.reduce(
      (acc, { marketingMethod }) => {
        if (!acc.find((method) => method.id === marketingMethod.id)) {
          acc.push(marketingMethod)
        }
        return acc
      },
      [] as MarketingMethod[]
    )

    const availablePurposes = availablePreferences.reduce(
      (acc, { marketingPurpose }) => {
        if (!acc.find((purpose) => purpose.id === marketingPurpose.id)) {
          acc.push(marketingPurpose)
        }
        return acc
      },
      [] as MarketingPurpose[]
    )

    const preselectedPreferences = availablePreferences.filter(
      ({ preselect }) => preselect
    )

    const preselectedPurposeIds = preselectedPreferences.map(
      ({ marketingPurpose: { id } }) => id
    )

    const preselectedMethodIds = preselectedPreferences.map(
      ({ marketingMethod: { id } }) => id
    )

    return {
      availablePreferences,
      availableMethods,
      availablePurposes,
      existingPreferences,
      preselectedPurposeIds,
      preselectedMethodIds
    }
  }, [data])

  const hasMethodSelected = useMemo(
    () => marketingPreferences.selectedMarketingMethodIds.length > 0,
    [marketingPreferences.selectedMarketingMethodIds]
  )
  const hasPurposeSelected = useMemo(
    () => marketingPreferences.selectedMarketingPurposeIds.length > 0,
    [marketingPreferences.selectedMarketingPurposeIds]
  )

  const handleMarketingPurposeChange = useCallback(
    (id: number, checked: boolean) => {
      const idAsString = `${id}`
      setMarketingPreferences((prevState) => ({
        ...prevState,
        isInteracted: true,
        isUnsubscribeChecked: false,
        selectedMarketingPurposeIds: checked
          ? [...prevState.selectedMarketingPurposeIds, idAsString]
          : prevState.selectedMarketingPurposeIds.filter(
              (item) => item !== idAsString
            )
      }))
    },
    []
  )

  const handleMarketingMethodChange = useCallback(
    (id: number, checked: boolean) => {
      const idAsString = `${id}`
      setMarketingPreferences((prevState) => ({
        ...prevState,
        isInteracted: true,
        isUnsubscribeChecked: false,
        selectedMarketingMethodIds: checked
          ? [...prevState.selectedMarketingMethodIds, idAsString]
          : prevState.selectedMarketingMethodIds.filter(
              (item) => item !== idAsString
            )
      }))
    },
    []
  )

  const handleClearPreferences = useCallback(
    (id: number, checked: boolean) => {
      if (id !== -1) return
      if (checked) {
        setMarketingPreferences((prevState) => ({
          ...prevState,
          selectedMarketingMethodIds: [],
          selectedMarketingPurposeIds: [],
          isInteracted: true,
          isUnsubscribeChecked: checked
        }))
      } else {
        setMarketingPreferences((prevState) => ({
          ...prevState,
          selectedMarketingMethodIds: preselectedMethodIds,
          selectedMarketingPurposeIds: preselectedPurposeIds,
          isInteracted: true,
          isUnsubscribeChecked: checked
        }))
      }
    },
    [preselectedMethodIds, preselectedPurposeIds]
  )

  const handleSubmit = useCallback(() => {
    const availableMarketingMethodPurposes = [
      ...(data?.user.availableMarketingMethodPurposes ?? [])
    ]

    const selectedPreferences = availableMarketingMethodPurposes.filter(
      (preference) =>
        marketingPreferences.selectedMarketingMethodIds.includes(
          preference.marketingMethod.id
        ) &&
        marketingPreferences.selectedMarketingPurposeIds.includes(
          preference.marketingPurpose.id
        )
    )

    const selectedPreferenceIds =
      selectedPreferences?.map((preference) => preference.id) || []

    if (!hasMethodSelected && hasPurposeSelected) {
      handleDisplayError('contact_preferences.update_error_missing_method')
      return
    } else if (hasMethodSelected && !hasPurposeSelected) {
      handleDisplayError('contact_preferences.update_error_missing_purpose')
      return
    }
    handleSubmitPreferences(selectedPreferenceIds)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [
    data?.user.availableMarketingMethodPurposes,
    handleSubmitPreferences,
    hasMethodSelected,
    hasPurposeSelected,
    marketingPreferences.selectedMarketingMethodIds,
    marketingPreferences.selectedMarketingPurposeIds,
    handleDisplayError
  ])

  useEffect(() => {
    if (!data || initialised) return
    if (!existingPreferences.length) {
      setMarketingPreferences((prevState) => ({
        ...prevState,
        isUnsubscribeChecked: true
      }))
      setInitialised(true)
      return
    }

    setMarketingPreferences((prevState) => ({
      ...prevState,
      selectedMarketingPurposeIds: existingPreferences.map(
        ({
          supportedMarketingMethodPurpose: {
            marketingPurpose: { id }
          }
        }) => id
      ),
      selectedMarketingMethodIds: existingPreferences.map(
        ({
          supportedMarketingMethodPurpose: {
            marketingMethod: { id }
          }
        }) => id
      )
    }))
    setInitialised(true)
  }, [data, existingPreferences, initialised])

  useEffect(() => {
    if (data && initialised && !hasMethodSelected && !hasPurposeSelected) {
      setMarketingPreferences((prevState) => {
        return {
          ...prevState,
          isUnsubscribeChecked: true,
          selectedMarketingMethodIds: [],
          selectedMarketingPurposeIds: []
        }
      })
    }
  }, [data, hasMethodSelected, hasPurposeSelected, initialised])

  return (
    <Card padding={32}>
      <div className={STYLES.cardWrapper}>
        <Grid item sm={12} md={6} className={STYLES.formWrapper}>
          {(() => {
            return (
              <>
                <div className={STYLES.sectionWrapper}>
                  <Text
                    text="contact_preferences.content_types"
                    namespace="account"
                    variant="display16"
                    colour="brandBlue500"
                    margin={false}
                  />
                  <div className={STYLES.checkboxList}>
                    {availablePurposes.map((purpose) => {
                      return (
                        <>
                          <div
                            key={`purpose-${purpose.id}`}
                            className={STYLES.checkboxWrapper}
                          >
                            <CheckBox
                              defaultValue={marketingPreferences.selectedMarketingPurposeIds.includes(
                                purpose.id
                              )}
                              id={parseInt(purpose.id, 10)}
                              tabIndex={0}
                              onChange={handleMarketingPurposeChange}
                              marginRight={16}
                            />
                            <Text
                              translate={false}
                              text={purpose.name}
                              colour={'brandBlue500'}
                              margin={false}
                              bold
                            />
                          </div>
                          <div className={STYLES.explainerText}>
                            <Text
                              text={purpose.description}
                              translate={false}
                              colour={'brandBlue400'}
                              margin={false}
                            />
                          </div>
                        </>
                      )
                    })}
                  </div>
                </div>
                <div className={STYLES.sectionWrapper}>
                  <Text
                    text="contact_preferences.contact_methods.title"
                    namespace="account"
                    variant="display16"
                    colour="brandBlue500"
                    margin={false}
                  />
                  <div
                    className={`${STYLES.horizontal} ${STYLES.checkboxList}`}
                  >
                    {availableMethods.map((method) => {
                      return (
                        <div
                          key={`method-${method.id}`}
                          className={STYLES.checkboxWrapper}
                        >
                          <CheckBox
                            defaultValue={marketingPreferences.selectedMarketingMethodIds.includes(
                              method.id
                            )}
                            id={parseInt(method.id, 10)}
                            tabIndex={0}
                            onChange={handleMarketingMethodChange}
                            marginRight={16}
                          />
                          <Text
                            translate={false}
                            text={method.name}
                            colour={'brandBlue500'}
                            margin={false}
                            bold
                          />
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className={STYLES.sectionWrapper}>
                  <div className={STYLES.checkboxWrapper}>
                    <CheckBox
                      defaultValue={marketingPreferences.isUnsubscribeChecked}
                      id={-1}
                      tabIndex={0}
                      onChange={handleClearPreferences}
                      marginRight={16}
                    />
                    <Text
                      namespace="account"
                      text="contact_preferences.unsubscribe"
                      colour={'brandBlue500'}
                      margin={false}
                    />
                  </div>
                </div>
                <div className={STYLES.footerSection}>
                  <Button
                    variant="primary"
                    fullWidth
                    typography={{
                      text: 'contact_preferences.save',
                      namespace: 'account'
                    }}
                    onClick={handleSubmit}
                    identifier="Update contact preferences clicked from in-account"
                    disabled={!marketingPreferences.isInteracted}
                  />
                  <Text
                    namespace="account"
                    text="contact_preferences.save_note"
                    colour={'brandBlue400'}
                    margin
                  />
                </div>
              </>
            )
          })()}
        </Grid>
        <div className={STYLES.imageWrapper}>
          <img alt="A illustrated dog with two hearts." src={DogWithHearts} />
        </div>
      </div>
    </Card>
  )
}

export default MarketingPreferences
