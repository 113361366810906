// @noflow
import type { WizardActivityLevel } from '@/components/pages/SignupWizardPage/types/types'

import type { Eater } from '@/types/index'

import { Routes } from './routes'

enum Action {
  onSwipe = 'Swipe',
  arrowClicked = 'Arrow Clicked'
}

enum FlowType {
  add = 'add',
  new = 'new'
}

enum SelectionStatus {
  selected = 'selected',
  deselected = 'deselected'
}

enum TooltipLocation {
  starterBox = 'starterBox',
  otherRecipes = 'otherRecipes'
}

type PlansAnalyticState = {
  numberOfRecommendedRecipes: number | null
  numberOfNonRecommendedRecipes: number | null
  addedExtraProducts: {
    names: string[] | null
    slugs: string[] | null
  } | null
  selectedDailyGrams: string | null
  idealGramsAmount: number | null
}

type ExtrasAnalyticProperties = {
  productType: string[]
  quantity: number
  productCollectionSlugs: string[]
  productVariantIds: string[] | null
}

type PageLoadProperties = {
  numberOfDogs: number
  numberOfPuppies: number
  ageInMonths: (number | null)[]
  hasAnAdult: boolean
  hasAPuppy: boolean
  hasASenior: boolean
  weightInGrams: (number | null)[]
  hasAnOverweightDog: boolean
  hasARightSizedDog: boolean
  hasAnUnderweightDog: boolean
  allergies: (string | null)[]
  hasADogWithAnAllergy: boolean
  healthIssues: (string | null)[]
  hasADogWithHealthIssue: boolean
  foodCategories: Array<string>
  eaterType: Eater[]
  hasAFussyEater: boolean
  activityLevel: WizardActivityLevel[]
  breed: (string | null)[]
  deliveryCadence: 'default' | null
  step: Routes
  numberOfSelectedRecipes: number | null
  numberOfRecommendedRecipes: number | null
  numberOfNonRecommendedRecipes: number | null
  addedExtraProducts: string[] | null
  flowType: FlowType
  selectedDailyGrams: string | null
  idealGramsAmount: number | null
}

export {
  Action,
  ExtrasAnalyticProperties,
  FlowType,
  SelectionStatus,
  PlansAnalyticState,
  PageLoadProperties,
  TooltipLocation
}
