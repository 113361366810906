import { gql } from '@apollo/client'

const THANK_YOU_QUERY = gql`
  query thankYouQuery(
    $headerSlug: String!
    $whatsappSingleDogSlug: String!
    $whatsappMultipleDogsSlug: String!
  ) {
    systemShouldShowAppContent: featureFlag(
      flag: "system_should_show_app_content"
      fallback: "not_in_cohort"
    )
    user {
      id
      shippingCountryCode
      preferredLanguage
      dogs {
        gender
        name
        id
        ageInMonths
        breed {
          id
          key
          name
        }
        dogProfile {
          avatarUrl
          description
        }
        dailyCalories
        dailyGrams
        eaterType
        neutered
        possessivePronoun
        pouchSizeInGrams
        weightInGrams
      }
      subscription {
        referralLink {
          url
        }
        nextNBoxes(num: 1) {
          id
          cutOffDate
          isoDeliveryDate
        }
      }
    }
    header: copyEntry(slug: $headerSlug) {
      text
    }
    whatsAppSingleDog: copyEntry(slug: $whatsappSingleDogSlug) {
      text
    }
    whatsAppMultipleDogs: copyEntry(slug: $whatsappMultipleDogsSlug) {
      text
    }
  }
`

export { THANK_YOU_QUERY }
