import React from 'react'

import Icon from '@/components/elements/atoms/Icon/Icon'
import { BRAND_ASSETS } from '@/components/elements/atoms/Icon/brandAssets'
import Text, { Props as TextProps } from '@/components/elements/atoms/Text/Text'

import STYLES from '../ResolutionConfirmation.module.sass'

type ResolutionConfirmationBoxProps = {
  textProps: TextProps
  asset?: keyof typeof BRAND_ASSETS
  assetSize: number
}

const ResolutionConfirmationBox = ({
  textProps,
  asset,
  assetSize = 14
}: ResolutionConfirmationBoxProps): JSX.Element => {
  return (
    <div className={STYLES.resolutionConfirmationBox}>
      {asset && (
        <div className={STYLES.iconContainer}>
          <Icon size={assetSize || 14} asset={asset} />
        </div>
      )}
      <Text
        namespace={textProps.namespace}
        text={textProps.text}
        margin={textProps.margin}
        translate={textProps.translate}
        variables={textProps.variables}
        variant={textProps.variant}
        colour={textProps.colour}
      />
    </div>
  )
}

export default ResolutionConfirmationBox
