// @noflow
import React from 'react'

import STYLES from './About.module.sass'

import type { ProductCollectionQuery_productCollection as ProductCollection } from '../../../queries/__generated__/ProductCollectionQuery'

import SectionWrapper from '../../SectionWrapper'

type Props = {
  about: ProductCollection['about']
  aboutRef: React.MutableRefObject<null>
}

const Information = ({ about, aboutRef }: Props): JSX.Element => {
  return (
    <div ref={aboutRef} id="about">
      <SectionWrapper
        title={{
          text: 'extras.product_collection.details.information.about_title',
          namespace: 'dashboard'
        }}
        showTopSectionDivider
      >
        {/* Content that we render is handled in CMS markdown */}
        <div
          className={STYLES.markdownWrapper}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: about
          }}
        />
      </SectionWrapper>
    </div>
  )
}

export default Information
