import { format } from 'date-fns'
import React from 'react'

import {
  countryCodeToLocaleCurrency,
  localeToDateLocale
} from '@/utils/countryCodeHelper'
import { Options, formatCurrencyWithDecimal } from '@/utils/currency'

import Card from '@/components/elements/atoms/Card/Card'
import Icon from '@/components/elements/atoms/Icon/Icon'
import Label from '@/components/elements/atoms/Label/Label'
import Link from '@/components/elements/atoms/Link/Link'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './InvoiceCard.module.sass'

import { Code, Language } from '@/types'

type Invoice = {
  id: string
  issueDate: string
  corrective: boolean
  grossTotal: number
}

type Props = {
  invoice: Invoice
  shippingCountryCode: Code
  preferredLanguage: Language
}

const InvoiceCard = ({
  invoice: { id, issueDate, corrective, grossTotal },
  shippingCountryCode,
  preferredLanguage
}: Props): JSX.Element => {
  const dateLocale = localeToDateLocale(shippingCountryCode, preferredLanguage)
  const date = format(new Date(issueDate), 'EEEE do MMMM', {
    locale: dateLocale
  })
  const currencyOptions: Options = countryCodeToLocaleCurrency(
    shippingCountryCode,
    preferredLanguage
  )
  const grossTotalFormatted = formatCurrencyWithDecimal(
    Math.abs(grossTotal),
    currencyOptions
  )

  return (
    <div className={STYLES.wrapper}>
      <Card variant={'brandBlue100'}>
        <div className={STYLES.container}>
          {corrective && (
            <div className={STYLES.label}>
              {/* eslint-disable-next-line jsx-a11y/label-has-for */}
              <Label
                text={{
                  text: 'correction',
                  namespace: 'invoices',
                  colour: 'brandWhite',
                  margin: false
                }}
                colour="brandRed400"
              />
            </div>
          )}
          <div className={STYLES.icon}>
            <Icon asset="receipt" size={50} width="4rem" />
          </div>
          <div>
            <Text margin={false} variant="display20" text={date} align="left" />
            <Text
              margin={false}
              variant="textRegular18"
              namespace="dashboard"
              text={
                corrective
                  ? 'box_breakdown.refunded'
                  : 'box_breakdown.invoice_total'
              }
              variables={{ total: grossTotalFormatted }}
              align="left"
            />
            <div className={STYLES.link}>
              <Link
                href={`/invoices/${id}.pdf`}
                opensInNewTab
                text="box_breakdown.download_invoice"
                suffix={null}
                namespace="dashboard"
                variant="link"
                identifier="box_breakdown.download_invoice"
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default InvoiceCard
export { Invoice }
