// @noflow
import classNames from 'classnames'
import React, { ElementType, forwardRef, useCallback, useEffect } from 'react'
import { animated, easings, useSpring } from 'react-spring'

// Styles
import STYLES from './Interactive.module.sass'

type Props = React.HTMLAttributes<HTMLElement> & {
  element?: 'button' | 'div' | 'time'
  disabled?: boolean
  selected?: boolean
  focusHighlight?: boolean
}

/**
 * A base element for any interactive component.
 * Adds the required aria attributes as well as a standard click animation and focus styling
 * @param element - the html element the component should render as
 * @param disabled
 * @param selected
 * @param focusHighlight - toggles off the focused visual state. DO NOT DISABLE THIS unless you have an alternative
 *                         focus state built into the component which composes this component
 * @param className
 * @param rest - any native attributes which should be rendered on the element
 * @constructor
 * @abstract
 */
const Interactive = (
  props: Props,
  ref: React.Ref<HTMLElement>
): JSX.Element => {
  const {
    element = 'div',
    disabled,
    selected,
    focusHighlight = true,
    // eslint-disable-next-line react/prop-types
    className,
    ...rest
  } = props

  const [styles, api] = useSpring(() => ({
    from: { scale: 1 },
    loop: { reverse: true },
    config: { duration: 50, easing: easings.easeInQuad },
    onRest: () => {
      api.start({ scale: 1, config: { easing: easings.easeOutQuad } })
    }
  }))

  useEffect(() => {
    api.stop()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onInteraction = useCallback(
    (event) => {
      if (rest.onClick && !disabled) {
        api.stop()
        api.set({ scale: 1 })
        api.start({ scale: 0.95 })

        rest.onClick(event)
      }
    },
    [api, disabled, rest]
  )

  const onPress = useCallback(
    (event) => {
      if (event.key === 'Enter' || event.key === ' ') {
        onInteraction(event)
      }
    },
    [onInteraction]
  )

  const classes = classNames(className, {
    [STYLES.interactive]: true,
    [STYLES.disableSelectedState]: !focusHighlight
  })

  const Element: ElementType = animated[element]

  return (
    <Element
      ref={ref}
      tabIndex={0}
      {...rest}
      role={element !== 'button' ? 'button' : undefined}
      onClick={onInteraction}
      onKeyUp={onPress}
      style={styles}
      className={classes}
      aria-disabled={disabled}
      aria-pressed={selected}
    >
      {rest.children}
    </Element>
  )
}

export { Props }
export default forwardRef(Interactive)
