// @noflow
import isNil from 'lodash/isNil'
import isString from 'lodash/isString'
import isUndefined from 'lodash/isUndefined'
import React, { useCallback, useMemo } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'

import { AnalyticsProps } from '@/services/segment'

import Card, {
  Props as CardProps,
  ClickableCardProps,
  NonClickableCardProps
} from '@/components/elements/atoms/Card/Card'
import CloudinaryImage from '@/components/elements/atoms/CloudinaryImage'
import Icon, { Props as IconProps } from '@/components/elements/atoms/Icon/Icon'
import Separator from '@/components/elements/atoms/Separator/Separator'
import Text, { Props as TextProps } from '@/components/elements/atoms/Text/Text'

import STYLES from './DetailsCard.module.sass'

import FlatButton from '../../atoms/FlatButton/FlatButton'

type DetailCardProps = {
  label?: Pick<TextProps, 'text' | 'variables'>
  text: Pick<TextProps, 'text' | 'variables' | 'translate' | 'variant'>
  prompt?: string
  icon?: IconProps['asset'] | JSX.Element
  leftImage?: JSX.Element | null
  iconSize?: number
  thumb?: string | JSX.Element
  invert?: boolean
  namespace: string
  bottomExtra?: JSX.Element | null | false
  variant?: CardProps['variant']
  border?: CardProps['border']
  dataTestId?: string
}

type HrefCardProps = {
  href: string
  onClick?: never
}
type OnClickCardProps = {
  href?: never
}
type NoClickCardProps = {
  href?: never
  onClick?: never
  identifier?: never
  screenIdentifier?: never
  disableAnalytics?: never
}

type HrefDetailCardProps = HrefCardProps &
  DetailCardProps &
  Omit<ClickableCardProps, 'label' | 'children' | 'onClick'> &
  AnalyticsProps
type OnClickDetailCardProps = OnClickCardProps &
  DetailCardProps &
  Omit<ClickableCardProps, 'label' | 'children'> &
  AnalyticsProps
type NonClickableDetailCardProps = NoClickCardProps &
  DetailCardProps &
  Omit<NonClickableCardProps, 'label' | 'children'>

type Props =
  | HrefDetailCardProps
  | OnClickDetailCardProps
  | NonClickableDetailCardProps

const handleClick = (
  href: Props['href'],
  onClick: Props['onClick'],
  navigate: NavigateFunction
) => {
  if (!isUndefined(href)) navigate(href)
  if (!isUndefined(onClick)) onClick()
}

const DetailsCard = ({
  label,
  text,
  prompt,
  href,
  onClick,
  thumb,
  icon,
  leftImage,
  iconSize = 30,
  invert,
  namespace,
  bottomExtra,
  variant = undefined,
  border,
  identifier,
  disableAnalytics,
  dataTestId
}: Props): JSX.Element => {
  const navigate = useNavigate()
  const onCardClick = useCallback(
    () => handleClick(href, onClick, navigate),
    [href, onClick, navigate]
  )
  const interactable: boolean = !isUndefined(href) || !isUndefined(onClick)

  const interactableProps = useMemo(() => {
    if (!interactable) return {}
    if (!isUndefined(disableAnalytics))
      return { onClick: onCardClick, disableAnalytics }
    if (isUndefined(identifier)) return {}
    return {
      onClick: onCardClick,
      identifier
    }
  }, [interactable, onCardClick, identifier, disableAnalytics])

  return (
    <Card
      border={border}
      padding={0}
      shadow={interactable}
      variant={variant}
      {...interactableProps}
      dataTestId={dataTestId}
    >
      <div className={STYLES.inner}>
        {!isUndefined(thumb) && (
          <div className={STYLES.thumb}>
            {isString(thumb) ? (
              <CloudinaryImage
                alt=""
                image={{
                  path: thumb
                }}
              />
            ) : (
              thumb
            )}
          </div>
        )}
        {isUndefined(thumb) && !isUndefined(icon) && (
          <div className={STYLES.icon}>
            {isString(icon) ? (
              <Icon
                asset={icon}
                size={iconSize}
                backgroundColour="brandYellow300"
              />
            ) : (
              icon
            )}
          </div>
        )}
        {!isUndefined(leftImage) && (
          <div className={STYLES.icon}>{leftImage}</div>
        )}
        <div className={`${STYLES.details} ${invert ? STYLES.invert : ''}`}>
          {!isUndefined(label) && (
            <Text
              namespace={namespace}
              text={label.text}
              variables={label.variables}
              variant="textRegular14"
              colour={
                variant === 'brandYellow100' ? 'brandBlue500' : 'brandBlue400'
              }
              margin={false}
            />
          )}
          <Text
            namespace={namespace}
            text={text.text}
            variables={text.variables}
            translate={text.translate}
            variant={text.variant || 'display16'}
            margin={false}
            bold
          />
        </div>
        {interactable &&
          (!isUndefined(prompt) ? (
            <div className={STYLES.prompt}>
              <FlatButton
                text={{ text: prompt, namespace, translate: true }}
                // eslint-disable-next-line react/jsx-no-bind
                onClick={(event) => event?.preventDefault()}
                identifier="details_card"
              />
            </div>
          ) : (
            <div className={STYLES.arrow}>
              <Icon asset="arrow" size={8} />
            </div>
          ))}
      </div>
      {!isNil(bottomExtra) && bottomExtra && (
        <div className={STYLES.bottomExtra}>
          <Separator handdrawn />
          {bottomExtra}
        </div>
      )}
    </Card>
  )
}

export {
  Props,
  HrefDetailCardProps,
  OnClickDetailCardProps,
  NonClickableDetailCardProps
}
export default DetailsCard
