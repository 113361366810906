import { Language } from '@/packs/localisation'
import { format } from 'date-fns'
import i18next from 'i18next'
import React from 'react'

import { localeToDateLocale } from '@/utils/countryCodeHelper'
import { Currency, Locale, formatCurrencyWithDecimal } from '@/utils/currency'

import DiscountBadge from 'assets/images/icons/badges/discount-badge.svg'

import Text from '@/components/elements/atoms/Text'

import STYLES from '../../PersonalDetails.module.sass'

import { Code } from '@/types'

import { Buttons } from './Buttons'

type DiscountModalProps = {
  discount: number
  isoDeliveryDate: string
  locale: Locale
  currency: Currency
  handleModalClose: () => void
  handlePauseSubscription: () => void
  shippingCountryCode: Code
}

const DiscountModal = ({
  discount,
  isoDeliveryDate,
  locale,
  currency,
  handleModalClose,
  handlePauseSubscription,
  shippingCountryCode
}: DiscountModalProps): JSX.Element => {
  const dateLocale = localeToDateLocale(
    shippingCountryCode,
    i18next.language as Language
  )

  return (
    <div className={STYLES.accountPauseModalWrapper}>
      <div className={STYLES.discountRoundal}>
        <Text
          translate={false}
          text="%"
          variant="display45"
          colour="brandWhite"
        />
        <img alt="" src={DiscountBadge} />
      </div>

      <Text
        variant="display24"
        variables={{
          deliveryDate: format(new Date(isoDeliveryDate), 'EEEE do LLLL', {
            locale: dateLocale
          }),
          savingsAmount: formatCurrencyWithDecimal(discount, {
            locale,
            currency
          })
        }}
        namespace="account"
        text={'subscription_settings.discount_warning.message_alternative'}
      />

      <Buttons
        handleModalClose={handleModalClose}
        handlePauseSubscription={handlePauseSubscription}
      />
    </div>
  )
}

export { DiscountModal, DiscountModalProps }
