// @noflow
import isString from 'lodash/isString'
import isUndefined from 'lodash/isUndefined'
import React, { ReactElement } from 'react'

import BoostLogo, {
  BoostLogoSizes
} from '@/components/elements/atoms/BoostLogo/BoostLogo'
import Text, {
  AllowedColours,
  Props as TextProps
} from '@/components/elements/atoms/Text/Text'
import Tooltip from '@/components/elements/atoms/Tooltip/Tooltip'

import STYLES from './PriceBreakdown.module.sass'

import DownloadInvoiceButton from '../DownloadInvoiceButton/DownloadInvoiceButton'

type Row = {
  label: string
  value: TextProps | string
  discount?: boolean
  boost?: boolean
  translate?: { label?: boolean; value?: boolean }
  id?: string
  tooltip?: JSX.Element
  labelOverrides?: Omit<TextProps, 'text'>
}

type TotalRow = {
  label: string
  value: string
  translate?: boolean
}

type Event =
  | React.MouseEvent<HTMLButtonElement>
  | React.KeyboardEvent<HTMLButtonElement>

type Props = {
  rows: Row[]
  title?: string
  total?: TotalRow
  labels?: ReactElement[] | ReactElement
  namespace: string
  showDownloadInvoiceButton?: boolean
  shippingCountryCode?: string
  csrfToken?: string
  invoiceId?: string
  newInvoiceIds?: string[]
  invoiceIssued?: boolean
  showInvoiceDownloadDirections?: boolean
  isFree?: boolean
  toggleInvoicesModalOpen?: (event: Event) => void
}

const hasDiscount = (rows: Props['rows']): boolean =>
  rows.some((row) => row.discount)

const hasBoost = (rows: Props['rows']): boolean =>
  rows.filter((row: Row) => row.boost).length > 0

/**
 * Color text based off the row state
 * @param discount - does this row display a discount
 * @param boost - does this row display a boost item
 * @param boosted - is this box boosted? (if so discount styling is not shown even if a discount is present)
 */
const handleRowTextColor = (
  discount?: boolean,
  boost?: boolean,
  boosted?: boolean
): AllowedColours => {
  if (boost) return 'boostLight'
  if (!boosted && discount) return 'brandRed500'
  return 'brandBlue500'
}

const PriceBreakdown = ({
  title,
  labels,
  rows,
  total,
  namespace,
  showDownloadInvoiceButton = false,
  shippingCountryCode = '',
  csrfToken = '',
  invoiceId = '',
  invoiceIssued,
  showInvoiceDownloadDirections,
  isFree = false,
  toggleInvoicesModalOpen
}: Props): JSX.Element => {
  const discounted = hasDiscount(rows)
  const boosted = hasBoost(rows)

  return (
    <div>
      {title && (
        <div className={STYLES.title}>
          <Text
            text={title}
            margin={false}
            element="span"
            variant="display16"
            colour="brandBlue500"
            bold
            namespace={namespace}
          />
          {boosted && <BoostLogo size={BoostLogoSizes.large} />}
          {/* if boost logo is shown discount labels will always be hidden */}
          {!boosted && !isUndefined(labels) && (
            <div className={STYLES.labels}>{labels}</div>
          )}
        </div>
      )}
      <div className={STYLES.rows}>
        {rows.map(
          (row): ReactElement => (
            <div key={row.label} className={STYLES.row} data-testid={row.id}>
              <Text
                text={row.label}
                margin={false}
                element="span"
                variant="textRegular16"
                colour={handleRowTextColor(row.discount, row.boost, boosted)}
                namespace={namespace}
                translate={row.translate?.label}
                {...row.labelOverrides}
              />
              <div className={STYLES.price} data-testid="value">
                {row.tooltip && (
                  <div className={STYLES.tooltip}>
                    <Tooltip identifier="price_breakdown_tooltip">
                      {row.tooltip}
                    </Tooltip>
                  </div>
                )}
                {isString(row.value) ? (
                  <Text
                    text={row.value}
                    margin={false}
                    element="span"
                    variant="textRegular16"
                    colour={handleRowTextColor(
                      row.discount,
                      row.boost,
                      boosted
                    )}
                    namespace={namespace}
                    translate={row.translate?.value}
                  />
                ) : (
                  <Text
                    {...row.value}
                    margin={false}
                    element="span"
                    variant="textRegular16"
                    colour={handleRowTextColor(
                      row.discount,
                      row.boost,
                      boosted
                    )}
                    namespace={namespace}
                    translate={row.translate?.value}
                  />
                )}
              </div>
            </div>
          )
        )}
        {!isUndefined(total) && (
          <div className={STYLES.row} data-testid="total">
            <Text
              text={total.label}
              margin={false}
              element="span"
              variant="textRegular16"
              colour={handleRowTextColor(discounted || isFree, false, boosted)}
              bold
              namespace={namespace}
            />
            <Text
              text={total.value}
              margin={false}
              element="span"
              variant="textRegular16"
              colour={handleRowTextColor(discounted || isFree, false, boosted)}
              bold
              namespace={namespace}
              translate={total.translate || false}
            />
          </div>
        )}
        {showDownloadInvoiceButton && csrfToken && (
          <div className={STYLES.row}>
            <DownloadInvoiceButton
              invoiceIssued={invoiceIssued}
              showInvoiceDownloadDirections={showInvoiceDownloadDirections}
              csrfToken={csrfToken}
              invoiceId={invoiceId}
              shippingCountryCode={shippingCountryCode}
              toggleInvoicesModalOpen={toggleInvoicesModalOpen}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export { Props, Row, TotalRow }
export default PriceBreakdown
