// @flow

import * as ACTIONS from '../actions'

import type { Dispatch, Thunk } from 'redux'
import type { Trait } from '../../../shared_types/rails_models/dog_personality_trait'
import type { RailsModelID as ID } from '../../../shared_types/ids'
import type { State } from '../reducers'

type DogIdTraits = {|
  dogId: ID,
  traits: Array<Trait>
|}

type ResponseBody = {|
  data?: {
    id: ID
  },
  errors?: [string]
|}

const { fetch } = global

/**
 * Updates traits attributed to a users dog profiles
 *
 * Body: {
 *   token: Matches User#token in the Postgres schema
 *   query: GraphQL mutation to add dog personality traits to a dog profile
 * }
 *
 * Response: {
 *    data: { Present if the request succeeded
 *      id: User_id
 *    }
 *    error: Present if the request failed
 * }
 */
const traitsUpdate = (): Thunk => {
  return (dispatch: Dispatch, getState: () => State): Promise<void> => {
    dispatch(ACTIONS.startRequest('traitsUpdate'))
    const { globalAttributes, dogProfiles } = getState()
    const { token, csrfToken, userID } = globalAttributes

    // Ensure that the userID is a number
    if (typeof userID !== 'number') {
      throw new Error(`User ID (${userID}) should be of type number`)
    }

    // eslint-disable-next-line flowtype/no-flow-fix-me-comments
    // $FlowFixMe[cannot-infer-type]
    const traitsInput: Array<DogIdTraits> = Object.keys(dogProfiles)
      .map((dogId: ID): DogIdTraits => {
        return {
          dogId: dogId,
          // eslint-disable-next-line flowtype/no-flow-fix-me-comments
          // $FlowFixMe[cannot-infer-type]
          traits: Object.keys(dogProfiles[dogId].traits)
            .reduce((acc: Array<Trait>, trait: Trait): Array<Trait> => {
              if (dogProfiles[dogId].traits[trait]) {
                acc.push(trait)
              }
              return acc
            }, [])
        }
      })

    const endpoint = '/graphql'

    const query = `
      mutation TraitsUpdate(
        $userId: ID!,
        $traitsInput: [TraitsInput!]!,
      ) {
        traitsUpdate(
          userId: $userId,
          traitsInput: $traitsInput
        ) {
          id
        }
      }
    `
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    }
    const method = 'POST'
    const credentials = 'same-origin'
    const body = JSON.stringify({
      token,
      query,
      variables: {
        userId: userID,
        traitsInput: traitsInput
      }
    })

    return new Promise((resolve: () => void): void => {
      fetch(endpoint, { headers, method, credentials, body })
        .then((res: Response): void => {
          res.json().then(({ data, errors }: ResponseBody): void => {
            if (!errors) {
              if (!data) {
                throw new Error(`There was no error but data is empty`)
              }
              dispatch(ACTIONS.completeRequest(
                'traitsUpdate',
                {}
              ))
              resolve()
            } else {
              dispatch(ACTIONS.completeRequest(
                'traitsUpdate',
                {
                  error: `Error for user with id ${userID}: ${errors.join()}`
                }
              ))
              resolve()
            }
          })
        })
    })
  }
}

export default traitsUpdate
