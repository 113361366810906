// @noflow
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Image from '@/components/elements/atoms/Image/Image'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import Text from '@/components/elements/atoms/Text/Text'

import ModalLayout from '../../layout'

type Props = {
  data: {
    firstDogName: string
    dogsAmount: number
    pouchSize: number
    pouchesPerDay: number
  }
}

const HowToFeed = ({
  data: { firstDogName, dogsAmount, pouchSize, pouchesPerDay }
}: Props): JSX.Element => {
  const { t } = useTranslation('modals')

  const variant = useMemo(() => {
    switch (pouchesPerDay) {
      case 0.5: {
        return {
          context: 'half',
          slug: 'half-pouch',
          image: {
            width: 40,
            height: 43
          }
        }
      }
      case 1: {
        return {
          context: 'one',
          slug: 'one-pouch',
          image: {
            width: 42,
            height: 48
          }
        }
      }
      case 2: {
        return {
          context: 'two',
          slug: 'two-pouches',
          image: {
            width: 44,
            height: 43
          }
        }
      }
      default: {
        return {
          context: 'one',
          slug: 'one-pouch',
          image: {
            width: 42,
            height: 48
          }
        }
      }
    }
  }, [pouchesPerDay])

  const commonTextProps = {
    namespace: 'modals',
    margin: false
  }

  return (
    <ModalLayout title={{ text: 'how_to_feed.title', namespace: 'modals' }}>
      <Image
        alt={t('how_to_feed.img_alt')}
        image={{
          ...variant.image,
          resizeMode: 'resize_to_fill',
          retina: true
        }}
        slug={variant.slug}
      />
      <SectionWrapper margin={{ top: 8, bottom: 0 }}>
        <Text
          text={`how_to_feed.pouches_per_day_${variant.context}`}
          variant="display20"
          variables={{ pouchesPerDay, pouchSize }}
          {...commonTextProps}
        />
        <Text
          text={`how_to_feed.description_${
            dogsAmount > 1 ? 'multiple' : variant.context
          }`}
          variables={{
            dogName: firstDogName,
            pouchesPerDay,
            pouchSize
          }}
          {...commonTextProps}
        />
      </SectionWrapper>
    </ModalLayout>
  )
}

export type { Props }

export { HowToFeed }
