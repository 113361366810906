// @noflow
import times from 'lodash/times'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Text from '@/components/elements/atoms/Text/Text'
import Faq from '@/components/elements/molecules/Faq/Faq'

const FrequentlyAskedQuestions = (): React.ReactElement => {
  const { t } = useTranslation('contacts')
  const faqData = t('frequently_asked_questions.questions', {
    returnObjects: true
  })
  const questionNumber = Object.keys(faqData).length
  return (
    <div className="contacts__faq-section">
      <Text
        element="h2"
        namespace="contacts"
        text="frequently_asked_questions.title"
        variant="display28"
      />
      {times(questionNumber).map(
        (index): React.ReactElement => (
          <Faq
            key={index}
            question={{
              text: `frequently_asked_questions.questions.question${
                index + 1
              }.question`,
              namespace: 'contacts'
            }}
            answer={{
              text: `frequently_asked_questions.questions.question${
                index + 1
              }.answer_html`,
              namespace: 'contacts'
            }}
          />
        )
      )}
    </div>
  )
}

export default FrequentlyAskedQuestions
