import { gql } from '@apollo/client'

const REACTIVATION_CAMPAIGN_FRAGMENT = gql`
  fragment reactivationCampaignFragment on ReactivationCampaign {
    name
    isActive
    expiryDate
    description
    currentUserIsEligible
    image {
      src
    }
    discountCode {
      id
      code
      discountCodeParts {
        discountBasis
        discountType
        n
        value
      }
    }
  }
`

export { REACTIVATION_CAMPAIGN_FRAGMENT }
