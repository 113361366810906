import React from 'react'

import { Currency, Locale, formatCurrencyWithDecimal } from '@/utils/currency'

import AlertCard from '@/components/elements/atoms/Alert/AlertCard'

import { Buttons } from './Buttons'

type Props = {
  boxesRemaining: number
  boostedBoxesTotalSavings?: string
  discount: number
  locale: Locale
  currency: Currency
  handleModalClose: () => void
  handlePauseSubscription: () => void
  isAPBoosted: boolean
}

const BoostModal = ({
  discount,
  boxesRemaining,
  boostedBoxesTotalSavings,
  locale,
  currency,
  handleModalClose,
  handlePauseSubscription,
  isAPBoosted
}: Props): JSX.Element => {
  const boostText =
    discount > 0
      ? 'subscription_settings.boost_with_discount'
      : 'subscription_settings.boost'

  const APBoostText = 'subscription_settings.ap_boost_with_remaingin_boxes'

  return (
    <>
      <AlertCard
        message={{
          namespace: 'account',
          text: isAPBoosted ? APBoostText : boostText,
          variables: {
            apDiscountAmount: '50%',
            undeliveredMembershipBoxesRemaining: boxesRemaining,
            savingsAmount: boostedBoxesTotalSavings,
            discountTotal:
              discount > 0
                ? formatCurrencyWithDecimal(discount, {
                    locale,
                    currency
                  })
                : 0
          },
          margin: false,
          align: 'left'
        }}
        variant={isAPBoosted ? 'discount' : 'boost'}
      />
      <Buttons
        handleModalClose={handleModalClose}
        handlePauseSubscription={handlePauseSubscription}
      />
    </>
  )
}
export { BoostModal }
