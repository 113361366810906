// @noflow
import { useReactiveVar } from '@apollo/client'
import i18next from 'i18next'
import React, { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  capitaliseFirstLetter,
  pronounContext,
  toLocalisedSentence
} from '@/utils/StringHelper'

import RadioGroup, {
  RadioOption
} from '@/components/elements/molecules/RadioGroup/RadioGroup'
import StickyNavigation from '@/components/elements/organisms/StickyNavigation/StickyNavigation'
import {
  routeToNextStep,
  routeToPrevStep
} from '@/components/pages/SignupWizardPage/helpers/wizardRoutes'
import type { Route } from '@/components/pages/SignupWizardPage/types/routes'

import STYLES from './Goals.module.sass'

import { Gender } from '@/types'

import { wizardPageState } from '../../SignupWizardPage'
import useImplicitFormSubmission from '../../helpers/useImplicitFormSubmission'
import { Dog, GoalSegment } from '../../types/types'

type Props = {
  variant?: keyof typeof STYLES
  namespace: string
}

type GoalsOptions = Omit<RadioOption, 'value'> & { value: GoalSegment }

const GoalsOptions = ({
  dogs,
  namespace
}: {
  dogs: Array<Dog>
  namespace: Props['namespace']
}) => {
  const wizardState = useReactiveVar(wizardPageState)
  const { user } = wizardState
  const dogGenders = dogs.map((dog) => dog.gender as Gender)

  const localisedDogNameSentence = (dogs: Array<Dog>): string => {
    return toLocalisedSentence({
      arr: dogs.map(({ name }) => capitaliseFirstLetter(name)),
      lng: i18next.language
    })
  }

  const dogNames = localisedDogNameSentence(dogs)

  const GoalsOptions: GoalsOptions[] = useMemo(
    () => [
      {
        // eslint-disable-next-line i18next/no-literal-string
        id: 'convenience',
        dataTestId: 'goals-convenience',
        text: {
          text: 'goals_step.convenience',
          namespace
        },
        value: GoalSegment.Convenience,
        defaultChecked: user.goalSegment === GoalSegment.Convenience
      },
      {
        // eslint-disable-next-line i18next/no-literal-string
        id: 'health_conscious',
        dataTestId: 'goals-healthConscious',
        text: {
          text: 'goals_step.health_conscious',
          namespace,
          variables: { context: pronounContext(dogGenders, i18next.language) }
        },
        value: GoalSegment.HealthConscious,
        defaultChecked: user.goalSegment === GoalSegment.HealthConscious
      },
      {
        // eslint-disable-next-line i18next/no-literal-string
        id: 'premium_ingredients',
        dataTestId: 'goals-premiumIngredients',
        text: {
          text: 'goals_step.premium_ingredients',
          namespace,
          variables: { dogNames }
        },
        value: GoalSegment.PremiumIngredients,
        defaultChecked: user.goalSegment === GoalSegment.PremiumIngredients
      },
      {
        // eslint-disable-next-line i18next/no-literal-string
        id: 'premium_taste',
        dataTestId: 'goals-premiumTaste',
        text: {
          text: 'goals_step.premium_taste',
          namespace,
          variables: {
            dogNames,
            context: pronounContext(dogGenders, i18next.language)
          }
        },
        value: GoalSegment.PremiumTaste,
        defaultChecked: user.goalSegment === GoalSegment.PremiumTaste
      }
    ],
    [user.goalSegment, dogGenders, namespace, dogNames]
  )

  const handleChange = useCallback(
    (index: number) => {
      const RootOption = GoalsOptions[index]

      user.goalSegment = RootOption.value

      wizardPageState({
        ...wizardState,
        user
      })
    },
    [GoalsOptions, user, wizardState]
  )

  return (
    <RadioGroup
      onChange={handleChange}
      radioOptions={GoalsOptions}
      variant="fullWidth"
      identifier="sign_up.goals"
    />
  )
}

const Goals = ({ variant, namespace }: Props): JSX.Element => {
  const route = useLocation().pathname as Route
  const wizardState = useReactiveVar(wizardPageState)
  const { dogs, user } = wizardState

  const formIsValid = user.goalSegment !== null

  const navigate = useNavigate()

  const onEnterPress = useCallback(() => {
    navigate(routeToNextStep({ route }))
  }, [navigate, route])

  useImplicitFormSubmission({ formIsValid, onEnterPress })

  return (
    <>
      <div className={variant ? STYLES[variant] : ''}>
        <div id={'whatKindOfGoalsStep'} className={STYLES.question}>
          <div className={STYLES.subQuestion}>
            <GoalsOptions dogs={dogs} namespace={namespace} />
          </div>
        </div>
      </div>
      <StickyNavigation
        variant="twoButtons"
        buttonOne={{
          url: routeToPrevStep({ route }),
          text: 'wizard_flow:sticky_navigation.back',
          variant: 'secondary',
          iconColour: 'brandRed400'
        }}
        buttonTwo={{
          dataTestId: 'next-button',
          url: routeToNextStep({ route }),
          text: 'wizard_flow:sticky_navigation.continue',
          variant: 'primary',
          iconColour: 'brandWhite'
        }}
        disabled={!formIsValid}
      />
    </>
  )
}

export { Props }
export default Goals
