// @noflow
import { ACCOUNT_ROUTES } from '@/routes'
import upperFirst from 'lodash/upperFirst'
import React, { useCallback } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import Female from 'assets/images/icons/female.svg'
import Male from 'assets/images/icons/male.svg'

import SkeletonParagraph from '@/components/elements/atoms/SkeletonParagraph/SkeletonParagraph'
import SkeletonTitle from '@/components/elements/atoms/SkeletonTitle/SkeletonTitle'
import Text from '@/components/elements/atoms/Text/Text'
import ActionCard from '@/components/elements/molecules/ActionCard/ActionCard'

import STYLES from './PersonalityDetails.module.sass'

import type { DogProfileQuery_user_dogs as Dog } from '../../queries/__generated__/DogProfileQuery'
import { Gender } from '@/types'

import { PersonalityTrait } from '../PersonalityTrait'

type Props = {
  dog?: Dog
  loading?: boolean
}

const PersonalityDetails = ({ dog, loading = false }: Props): JSX.Element => {
  const { windowWidth } = useWindowSize()
  const navigate = useNavigate()

  const years = Math.floor((dog?.ageInMonths ?? 0) / 12)
  const months = (dog?.ageInMonths ?? 0) % 12
  const unknownBreed = dog?.breed.key === 'other'

  const personalityTrait =
    dog?.dogProfile?.dogPersonalityTraits?.[0]?.trait ?? null
  const favouriteGame = dog?.dogProfile?.favouriteGame ?? null
  const activity = dog?.dogProfile?.dogActivities?.[0]?.activity ?? null

  const personalityDetails = [
    { type: 'quirks', value: personalityTrait },
    { type: 'favouriteGame', value: favouriteGame },
    { type: 'activity', value: activity }
  ]

  const genderIcon = (() => {
    switch (dog?.gender) {
      case Gender.female: {
        return Female
      }
      case Gender.male: {
        return Male
      }
      default: {
        return null
      }
    }
  })()

  const onPersonalityDetailsEdit = useCallback((): void => {
    navigate(
      generatePath(ACCOUNT_ROUTES.dogAboutInfo, { dogId: dog?.id ?? '' })
    )
  }, [navigate, dog?.id])

  return (
    <div className={`${STYLES.wrapper} ${loading ? STYLES.loading : ''}`}>
      <ActionCard
        shadow
        className={STYLES.card}
        button={{
          text: {
            text: 'dog_profile.edit_cta',
            namespace: 'account'
          },
          onClick: onPersonalityDetailsEdit
        }}
        indent={windowWidth < BREAKPOINTS.md ? 16 : 24}
        skeleton={{
          isLoading: loading,
          height: '100%'
        }}
        height={windowWidth < BREAKPOINTS.md ? 'auto' : '35.5rem'}
        fill={false}
      >
        {loading ? (
          <>
            <SkeletonTitle width={'50%'} />
            <SkeletonParagraph
              width={'40%'}
              count={3}
              align="center"
              shortLine={false}
            />
          </>
        ) : (
          <>
            <div className={STYLES.name}>
              <Text
                text={upperFirst(dog?.name)}
                translate={false}
                variant="display24"
                align="center"
                margin={false}
              />
              {genderIcon && <img src={genderIcon} alt="Dogs gender" />}
            </div>
            <Text
              text={
                unknownBreed
                  ? 'dog_profile.personality_details.breed'
                  : upperFirst(dog?.breed.name)
              }
              namespace="account"
              translate={unknownBreed}
              variables={{ context: unknownBreed ? 'unknown' : '' }}
              align="center"
              margin={false}
              bold
            />
            <div className={STYLES.age}>
              {years > 0 && (
                <Text
                  text="dog_profile.personality_details.age"
                  namespace="account"
                  variables={{
                    context: months > 0 ? 'yearWithMonth' : 'year',
                    count: years,
                    years,
                    months
                  }}
                  align="center"
                  margin={false}
                  bold
                />
              )}
              {months > 0 && (
                <Text
                  text="dog_profile.personality_details.age"
                  namespace="account"
                  variables={{ context: 'month', count: months, months }}
                  align="center"
                  margin={false}
                  bold
                />
              )}
            </div>
          </>
        )}
        <div className={STYLES.personalityDetails}>
          {personalityDetails.map(({ type, value }) => (
            <PersonalityTrait
              key={type}
              type={type}
              value={value}
              dogId={dog?.id}
            />
          ))}
        </div>
      </ActionCard>
    </div>
  )
}

export { PersonalityDetails }
