// @noflow
import React, { ReactNode } from 'react'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import Modal, {
  Props as ModalProps
} from '@/components/elements/atoms/Modal/Modal'
import Text, { Props as TextProps } from '@/components/elements/atoms/Text/Text'

import STYLES from './ModalWithHeaderFooter.module.sass'

type Props = Pick<
  ModalProps,
  | 'width'
  | 'setOpenModal'
  | 'isModalOpen'
  | 'showCloseButton'
  | 'loading'
  | 'children'
  | 'onCloseButtonClick'
> & {
  title: TextProps
  footer: ReactNode
}

const ModalExtended = ({
  title,
  children,
  footer,
  width,
  loading,
  showCloseButton,
  isModalOpen,
  setOpenModal,
  onCloseButtonClick
}: Props): JSX.Element | null => {
  const { windowWidth } = useWindowSize()
  return (
    <div className={STYLES.outer}>
      <Modal
        width={width}
        isModalOpen={isModalOpen}
        setOpenModal={setOpenModal}
        padding={false}
        loading={loading}
        flex
        showCloseButton={showCloseButton}
        bodyScroll={false}
        fullHeight={windowWidth < BREAKPOINTS.md}
        onCloseButtonClick={onCloseButtonClick}
      >
        <div className={STYLES.container}>
          <div className={STYLES.scroll}>
            <header className={`${STYLES.header} ${STYLES.hasClose} `}>
              <Text
                text={title.text}
                variables={title.variables}
                variant="display24"
                element="h2"
                colour="brandBlue500"
                margin={false}
                align="center"
                namespace={title.namespace}
              />
            </header>
            {children}
          </div>
          <footer className={STYLES.footer}>{footer}</footer>
        </div>
      </Modal>
    </div>
  )
}

export { Props }
export default ModalExtended
