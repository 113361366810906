// @noflow
import React, { useCallback } from 'react'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import { Button } from '@/components/elements/atoms/Button'
import { cloudinaryPath } from '@/components/elements/atoms/CloudinaryImage/CloudinaryImage'
import Text from '@/components/elements/atoms/Text/Text'

import STYLES from './KeyBenefits.module.sass'

import type { ProductCollectionQuery_productCollection_productBenefits as ProductBenefit } from '../../../queries/__generated__/ProductCollectionQuery'

import SectionWrapper from '../../SectionWrapper'

type Props = {
  productBenefits: Array<ProductBenefit>
  slug: string
  aboutRef: React.RefObject<HTMLElement>
}

const KeyBenefits = ({
  productBenefits,
  slug,
  aboutRef
}: Props): JSX.Element | null => {
  const { windowWidth } = useWindowSize()

  const handleScrollToAboutSection = useCallback(() => {
    window.analytics.track('Shop: Seen Product Details Section', {
      productCollectionSlug: slug
    })

    if (aboutRef && aboutRef.current) {
      const fixedHeaderHeight = windowWidth < BREAKPOINTS.md ? 50 : 100
      const topOfTheInformationSection =
        aboutRef.current.getBoundingClientRect().top +
        window.scrollY -
        fixedHeaderHeight

      window.scrollTo({
        top: topOfTheInformationSection,
        behavior: 'smooth'
      })
    }
  }, [aboutRef, slug, windowWidth])

  if (productBenefits.length <= 0) return null

  return (
    <SectionWrapper
      title={{
        text: 'extras.product_collection.details.key_benefits_title',
        namespace: 'dashboard'
      }}
      titleHelper={
        <Button
          typography={{
            namespace: 'dashboard',
            text: 'extras.product_collection.details.see_details_link'
          }}
          variant="linkWhiteBG"
          size="slim"
          onClick={handleScrollToAboutSection}
          disableAnalytics
        />
      }
      showBottomSectionDivider
    >
      <div className={STYLES.benefits}>
        {productBenefits.map(
          (productBenefit) =>
            productBenefit.image && (
              <div key={productBenefit.id} className={STYLES.benefit}>
                <img
                  src={cloudinaryPath({
                    path: productBenefit.image?.src,
                    transformations: { format: 'svg', width: 30 }
                  })}
                  alt="Product benefit"
                />
                <Text
                  element="span"
                  translate={false}
                  text={productBenefit.name}
                  variant="textRegular14"
                  align="center"
                />
              </div>
            )
        )}
      </div>
    </SectionWrapper>
  )
}

export default KeyBenefits
