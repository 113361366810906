// @noflow
import React, { useMemo } from 'react'

import STYLES from './ProgressBar.module.sass'

type Props = {
  index: number
  currentIndex: number
  isStoryPaused: boolean
  storyDuration: number
}

const ProgressBar = ({
  index,
  currentIndex,
  isStoryPaused,
  storyDuration
}: Props): JSX.Element => {
  const progressStyles = useMemo(() => {
    if (index < currentIndex) {
      // Story is finished
      return STYLES.finished
    } else if (currentIndex === index) {
      // Story is active or paused
      return isStoryPaused ? `${STYLES.active} ${STYLES.paused}` : STYLES.active
    } else {
      return ''
    }
  }, [index, currentIndex, isStoryPaused])

  return (
    <div className={STYLES.wrapper}>
      <span
        className={`${STYLES.progress} ${progressStyles}`}
        style={{ animationDuration: `${storyDuration}ms` }}
      />
    </div>
  )
}

export default ProgressBar
