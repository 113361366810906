// @noflow
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'

import { savePresetsInLocalStorage } from '../models/dog'
import type { Preset } from '../models/dog'
import {
  randomUser,
  saveUserInLocalStorage,
  userSubmissionData
} from '../models/user'
import type { User } from '../models/user'

type Submission =
  | { type: 'notSubmittedYet' }
  | { type: 'submitting' }
  | { type: 'failed'; error: Error }
  | { type: 'submitted' }

type State = {
  presets: Record<Preset, boolean>
  user: User
  submission: Submission
}

const initialState: State = {
  presets: {
    'tiny-tim': false,
    banannah: false,
    andrew: false
  },
  user: randomUser(),
  submission: { type: 'notSubmittedYet' }
}

const goToPlansClicked = createAsyncThunk(
  'funnel/goToPlansClicked',
  async ({
    user,
    presets,
    csrfToken
  }: {
    user: User
    presets: Array<Preset>
    csrfToken: string
  }): Promise<void> => {
    saveUserInLocalStorage(user)
    savePresetsInLocalStorage(presets)
    const response = await fetch('/api/wizard/v1/complete', {
      method: 'POST',
      body: JSON.stringify({
        user: userSubmissionData(user, presets)
      }),
      headers: {
        'X-CSRF-Token': csrfToken,
        'Content-Type': 'application/json'
      }
    })
    return response.json()
  }
)

const clearLocalStorageButtonClicked = createAsyncThunk(
  'funnel/clearLocalStorageButtonClicked',
  (): void => {
    for (const cookie in Cookies.get()) {
      Cookies.remove(cookie)
    }
    localStorage.clear()
    sessionStorage.clear()
    window.location.href = '/'
  }
)

const { reducer, actions } = createSlice({
  name: 'funnel',
  initialState,
  reducers: {
    randomNameClicked(state: State) {
      state.user = randomUser()
    },
    wizardPresetToggled(state: State, { payload }: PayloadAction<Preset>) {
      state.presets[payload] = !state.presets[payload]
    }
  },
  extraReducers: (builder) => {
    builder.addCase(goToPlansClicked.pending, (state: State) => {
      state.submission = { type: 'submitting' }
    })
    builder.addCase(goToPlansClicked.fulfilled, (state: State) => {
      state.submission = { type: 'submitted' }
      window.location.href = '/plans/recipes'
    })
    builder.addCase(goToPlansClicked.rejected, (state: State, action) => {
      state.submission = {
        type: 'failed',
        error: new Error(action.error.message)
      }
    })
  }
})

const { randomNameClicked, wizardPresetToggled } = actions

export {
  randomNameClicked,
  wizardPresetToggled,
  goToPlansClicked,
  clearLocalStorageButtonClicked
}

export type { Submission }

export default reducer
