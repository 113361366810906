// @noflow
import { gql, useQuery } from '@apollo/client'
import i18n from 'i18next'
import React, { useCallback, useState } from 'react'

import { Button } from '@/components/elements/atoms/Button'
import NavLink from '@/components/elements/atoms/NavLink/NavLink'
import NavLogo from '@/components/elements/atoms/NavLogo/NavLogo'
import NavMenuIcon from '@/components/elements/atoms/NavMenuIcon/NavMenuIcon'
import BannerManagerWithQuery from '@/components/elements/molecules/BannerManager/BannerManager'
import MobileNavMenu from '@/components/elements/organisms/MobileNavMenu/MobileNavMenu'
import initGeoIpWidget from '@/components/shared/geo_ip_widget/initGeoIpWidget'

import STYLES from './Navigation.module.sass'

// Enums
import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'

type Props = {
  csrfToken: string
  discount?: React.ReactNode
  shippingCountryCode: CountryCode
}

const NAV_QUERY = gql`
  query NavQuery {
    currentUser {
      __typename
    }
  }
`

const Navigation = ({
  csrfToken,
  discount,
  shippingCountryCode
}: Props): JSX.Element => {
  const { loading, data, error } = useQuery(NAV_QUERY)
  const [menuOpen, setMenuOpen] = useState(false)

  const menuItemClicked = useCallback(() => {
    setMenuOpen(!menuOpen)
  }, [menuOpen, setMenuOpen])

  const buildBoxClicked = useCallback(() => {
    window.location.href = '/users/new'
  }, [])

  const seePlanClicked = useCallback(() => {
    window.location.href = '/plans/recipes'
  }, [])

  const myAccountClicked = useCallback(() => {
    window.location.href = '/dashboard'
  }, [])

  if (loading) {
    return (
      <header className={STYLES.container}>
        <nav>
          <div className={`${STYLES.column} ${STYLES.left}`}>
            <a href="/">
              <NavLogo />
            </a>
          </div>
        </nav>
      </header>
    )
  }
  // eslint-disable-next-line i18next/no-literal-string
  if (error) throw new Error(`Error when NAV_QUERY executes: ${error.message}`)

  if (!loading) {
    initGeoIpWidget({
      desktopNav: true,
      mobileSidebar: true,
      csrfToken
    })
  }

  return (
    <React.Fragment>
      <header className={STYLES.container}>
        {discount}
        <nav>
          <div className={`${STYLES.column} ${STYLES.left}`}>
            <a href="/">
              <NavLogo />
            </a>
            <NavLink
              text={{
                text: 'our_products',
                namespace: 'navigation'
              }}
              href="/our_products"
            />
            <NavLink
              text={{
                text: 'making_our_meals',
                namespace: 'navigation'
              }}
              href="/making_our_meals"
            />
            <NavLink
              text={{
                text: 'help',
                namespace: 'navigation'
              }}
              href="/contact"
            />
          </div>
          <div className={`${STYLES.column} ${STYLES.right}`}>
            <div className="desktop-nav-localisation-widget" />
            {data.currentUser.__typename === 'User' ? (
              <React.Fragment>
                <NavLink
                  text={{
                    text: 'log_out',
                    namespace: 'navigation'
                  }}
                  href="/users/sign_out"
                />
                <Button
                  typography={{
                    namespace: 'navigation',
                    text: 'my_account'
                  }}
                  onClick={myAccountClicked}
                  disableAnalytics
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <NavLink
                  text={{
                    text: 'log_in',
                    namespace: 'navigation'
                  }}
                  href="/users/sign_in"
                />
                {data.currentUser.__typename === 'Guest' ? (
                  <Button
                    typography={{
                      namespace: 'navigation',
                      text: 'see_your_plan'
                    }}
                    onClick={seePlanClicked}
                    smallPadding
                    disableAnalytics
                  />
                ) : (
                  <Button
                    typography={{
                      namespace: 'navigation',
                      text: 'build_your_box'
                    }}
                    onClick={buildBoxClicked}
                    smallPadding
                    disableAnalytics
                  />
                )}
              </React.Fragment>
            )}
          </div>
          <div className={`${STYLES.column} ${STYLES.mobile}`}>
            <NavMenuIcon onClick={menuItemClicked} />
            <a href="/">
              <NavLogo />
            </a>
            {data.currentUser.__typename === 'User' ? (
              <Button
                typography={{
                  namespace: 'navigation',
                  text: 'my_account'
                }}
                onClick={myAccountClicked}
                smallPadding
                disableAnalytics
              />
            ) : data.currentUser.__typename === 'Guest' ? (
              <Button
                typography={{
                  namespace: 'navigation',
                  text: 'see_plan'
                }}
                onClick={seePlanClicked}
                smallPadding
                disableAnalytics
              />
            ) : (
              <Button
                typography={{
                  namespace: 'navigation',
                  text: 'build_box'
                }}
                onClick={buildBoxClicked}
                variant="primary"
                smallPadding
                disableAnalytics
              />
            )}
          </div>
        </nav>
        <BannerManagerWithQuery i18n={i18n} />
      </header>
      <MobileNavMenu
        open={menuOpen}
        backgroundClicked={menuItemClicked}
        userLoggedIn={data.currentUser.__typename === 'User'}
        shippingCountryCode={shippingCountryCode}
      />
    </React.Fragment>
  )
}

export { NAV_QUERY }
export default Navigation
export type { Props }
