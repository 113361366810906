// @noflow
import type { FormatCurrency } from '@/context/localisation/localisation'
import isNil from 'lodash/isNil'

import type { Row } from '@/components/elements/molecules/PriceBreakdown/PriceBreakdown'
import type {
  upcomingBoxQuery_user_ordersByDate_Box_order as BoxOrder,
  upcomingBoxQuery_user_ordersByDate_NonCoreOrder as NonCoreOrder,
  upcomingBoxQuery_user_ordersByDate_NonCoreOrder_orderParts as NonCoreOrderOrderPart
} from '@/components/pages/Dashboard/components/upcomingBoxes/queries/__generated__/upcomingBoxQuery'

import { NonCoreInvoiceStatus } from '@/types'

import type { Props as BoxDetailsProps } from '../boxDetails/BoxDetails'

type NonCoreOrderPricing = {
  invoice: NonCoreOrder['invoice']
  deliverySurcharge: NonCoreOrderOrderPart['deliverySurcharge']
  smallOrderSurcharge: NonCoreOrderOrderPart['smallOrderSurcharge']
  orderProductsTotal: NonCoreOrderOrderPart['orderProductsTotal']
}

type BoxType = BoxDetailsProps['type']
type Pricing = BoxOrder | NonCoreOrderPricing

type NonCoreOrderPriceBreakdownRowsArgs = NonCoreOrderPricing & {
  formatCurrencyWithDecimal: FormatCurrency
}

type BoxTitleArgs = {
  pricing?: Pricing
  type: BoxType
  isFreeBox?: boolean
  formatCurrencyWithDecimal: FormatCurrency
}

type BoxTitle = {
  isFree: boolean
  isDiscounted: boolean
  boxTotal: string
  discountedBoxTotal: string
}

const isBoxOrder = (type: BoxType, pricing?: Pricing): pricing is BoxOrder =>
  !!(pricing && type === 'box')

const isNonCoreOrder = (
  type: BoxType,
  pricing?: Pricing
): pricing is NonCoreOrderPricing => !!(pricing && type === 'nonCoreOrder')

const getNonCoreOrderPriceBreakdownRows = ({
  invoice: { status, discountTotal },
  deliverySurcharge,
  smallOrderSurcharge,
  orderProductsTotal,
  formatCurrencyWithDecimal
}: NonCoreOrderPriceBreakdownRowsArgs): Array<Row> => {
  const isOrderFree = status === 'free'

  const ordersPrice = {
    label: 'price',
    value: {
      text: 'price_value',
      variables: {
        price: formatCurrencyWithDecimal(orderProductsTotal ?? 0),
        context: isOrderFree ? 'free' : ''
      }
    },
    translate: {
      label: true,
      value: true
    }
  }

  const delivery = {
    label: 'delivery',
    value: {
      text:
        deliverySurcharge > 0
          ? formatCurrencyWithDecimal(deliverySurcharge)
          : 'free'
    },
    translate: {
      label: true,
      value: deliverySurcharge === 0
    },
    discount: deliverySurcharge === 0
  }

  const smallOrderFee = {
    label: 'small_order_fee',
    value: {
      text: formatCurrencyWithDecimal(smallOrderSurcharge)
    },
    translate: {
      label: true,
      value: false
    }
  }

  const discount = {
    label: 'discount',
    value: {
      text: formatCurrencyWithDecimal(discountTotal ?? 0)
    },
    translate: {
      label: true,
      value: false
    },
    discount: true
  }

  const showSmallOrderFee = !!(smallOrderSurcharge && smallOrderSurcharge > 0)
  const showDiscount = !!(discountTotal && discountTotal > 0)

  const rows = [
    ordersPrice,
    delivery,
    ...(showSmallOrderFee ? [smallOrderFee] : []),
    ...(showDiscount ? [discount] : [])
  ]

  return rows
}

const getBoxPriceBreakdownTitleDetails = ({
  type,
  pricing,
  isFreeBox = false,
  formatCurrencyWithDecimal
}: BoxTitleArgs): BoxTitle | null => {
  switch (true) {
    case isBoxOrder(type, pricing): {
      if (!pricing) return null

      const { totalPrice, discountTotal } = pricing as BoxOrder

      const formattedBoxTotal = formatCurrencyWithDecimal(
        totalPrice + (discountTotal ?? 0) ?? 0
      )

      const formattedDiscountedBoxTotal = formatCurrencyWithDecimal(totalPrice)

      const isFree = totalPrice === 0 || isFreeBox
      const isDiscounted = !isNil(discountTotal) && discountTotal > 0

      return {
        isFree,
        isDiscounted,
        boxTotal: formattedBoxTotal,
        discountedBoxTotal: formattedDiscountedBoxTotal
      }
    }
    case isNonCoreOrder(type, pricing): {
      if (!pricing) return null

      const { invoice } = pricing as NonCoreOrderPricing

      const formattedBoxTotal = formatCurrencyWithDecimal(
        invoice.grossPrice ?? 0
      )

      const formattedDiscountedBoxTotal = formatCurrencyWithDecimal(
        invoice.discountedPrice ?? 0
      )

      const isFree = invoice.status === NonCoreInvoiceStatus.free
      const isDiscounted =
        !isNil(invoice.discountTotal) && invoice.discountTotal > 0

      return {
        isFree,
        isDiscounted,
        boxTotal: formattedBoxTotal,
        discountedBoxTotal: formattedDiscountedBoxTotal
      }
    }
    default: {
      return null
    }
  }
}

export {
  isBoxOrder,
  isNonCoreOrder,
  getNonCoreOrderPriceBreakdownRows,
  getBoxPriceBreakdownTitleDetails
}
