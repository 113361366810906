// @noflow
import {
  ComputePositionConfig,
  VirtualElement,
  autoUpdate,
  offset,
  shift,
  useFloating
} from '@floating-ui/react-dom'
import classnames from 'classnames'
import {
  UseSelectGetItemPropsOptions,
  UseSelectStateChange,
  useSelect
} from 'downshift'
import isNil from 'lodash/isNil'
import isUndefined from 'lodash/isUndefined'
import snakeCase from 'lodash/snakeCase'
import React, { ChangeEvent, useCallback } from 'react'

import { isTouchScreen } from '@/utils/isTouchScreen'

import Icon from '@/components/elements/atoms/Icon/Icon'
import Text, { Props as TextProps } from '@/components/elements/atoms/Text/Text'

import STYLES from './HybridSelect.module.sass'

type Variant = 'default' | 'inline'

type Size = 16 | 18

type Props = {
  id: string
  label?: TextProps
  variant?: Variant
  options: Option[]
  noSelectionLabel: TextProps
  size?: Size
  value?: string
  positionDropdown?: ComputePositionConfig['placement']
  disabled?: boolean
  onChange: (value: string) => void
}

type Option = TextProps & {
  text: string
  value: string
  disabled?: boolean
  hidden?: boolean
}

type NativeSelectProps = {
  id: string
  isTouchDevice: boolean
  selected: string | null
  options: Option[]
  disabled?: boolean
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void
}

const NativeSelect = ({
  id,
  isTouchDevice,
  selected,
  options,
  disabled,
  onChange
}: NativeSelectProps) => (
  <select
    aria-hidden={!isTouchDevice}
    aria-labelledby={id}
    className={STYLES.nativeSelect}
    value={selected || undefined}
    // sync the native select value to downshift
    onChange={onChange}
    disabled={disabled}
  >
    {options.map((option) => (
      <option
        key={option.text}
        value={option.value}
        disabled={option.disabled}
        hidden={option.hidden}
      >
        {option.text}
      </option>
    ))}
  </select>
)

const getSelectedText = (value: string, options: Array<Option>): TextProps => {
  const text: Option | undefined = options.find(
    (option: Option) => option.value === value
  )
  if (!isUndefined(text)) return text
  throw new Error('Label was not found')
}

type SelectLabelProps = {
  id: string
  label: TextProps
  disabled?: boolean
  size: Size
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  accessibilityAttributes: any // have to use any here due to the modules typing
}

const SelectLabel = ({
  id,
  label,
  disabled,
  size,
  accessibilityAttributes
}: SelectLabelProps) => (
  <button
    id={id}
    className={STYLES.label}
    type="button"
    disabled={disabled}
    {...accessibilityAttributes()}
  >
    <Text
      text={label.text}
      variant={`textRegular${size}`}
      colour="brandBlue500"
      variables={label.variables}
      element="span"
      translate={false}
    />
    <div className={STYLES.carat}>
      <Icon size={size} asset="chevron" accentColour="brandBlue500" />
    </div>
  </button>
)

const getItems = (options: Option[]): string[] =>
  options.map((option: Option) => option.value)

type DropdownProps = {
  selected?: string
  highlighted: number
  size: Size
  options: Option[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  optionsAccessibilityAttributes: () => any // have to use any here due to the modules typing
  optionAccessibilityAttributes: (
    options: UseSelectGetItemPropsOptions<string>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => any // have to use any here due to the modules typing
}

const Dropdown = ({
  highlighted,
  size,
  options,
  optionsAccessibilityAttributes,
  optionAccessibilityAttributes
}: DropdownProps) => (
  <ul {...optionsAccessibilityAttributes()} className={STYLES.options}>
    {options.map((option: Option, index: number) => {
      const isDisabled = option.disabled
      const isHighlighted = highlighted === index
      return (
        <li
          key={`hybrid-select-key-${snakeCase(option.value)}`}
          className={`
                  ${STYLES.option}
                  ${isHighlighted ? STYLES.highlighted : ''}
                  ${isDisabled ? STYLES.disabledOption : ''}
                  ${option.hidden ? STYLES.hiddenOption : ''}
                `}
          {...optionAccessibilityAttributes({
            item: option.value,
            index,
            key: `${option.value}${index}`
          })}
        >
          <Text
            text={option.text}
            namespace={option.namespace}
            variant={`textRegular${size}`}
            colour={isDisabled ? 'brandBlue400' : 'brandBlue500'}
            variables={option.variables}
            translate={option.translate}
            element="span"
          />
        </li>
      )
    })}
  </ul>
)

/**
 * A custom select component which defaults to a native select element on touch devices
 * Note - using this component as a child of an element that animates scale using CSS transform
 *        can cause issues with the dropdowns positioning.
 *        An example of this would be a HybridDropdown inside a modal which animates using
 *        transform: scale(xx)
 *        This can be fixed by adding 'will-change: transform' along side the animation in your CSS
 * @param id used by aria attributes to link the native select to a label
 * @param label
 * @param variant
 * @param options
 * @param noSelectionLabel label to show if no initial selection is available
 * @param size
 * @param positionDropdown
 * @param disabled
 * @param onChange side effect for when the select value changes
 * @param value used to make the component controlled
 */
const HybridSelect = ({
  id,
  variant = 'default',
  options,
  label,
  noSelectionLabel,
  size = 18,
  positionDropdown = 'bottom-start',
  disabled,
  onChange,
  value
}: Props): JSX.Element => {
  const {
    x,
    y,
    refs: { setReference, setFloating },
    strategy
  } = useFloating<VirtualElement>({
    placement: positionDropdown,
    strategy: 'fixed',
    whileElementsMounted: autoUpdate,
    middleware: [
      shift({
        crossAxis: true
      }),
      offset(variant === 'default' ? -2 : 5)
    ]
  })

  const {
    isOpen,
    getLabelProps,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps
  } = useSelect({
    selectedItem: value,
    items: getItems(options),
    // on bespoke select change
    onSelectedItemChange: (data: UseSelectStateChange<string>) => {
      const updatedValue: string | null | undefined = data.selectedItem
      if (!isNil(updatedValue)) onChange(updatedValue)
    }
  })

  // on native select change
  const onValueChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => onChange(event.target.value),
    [onChange]
  )

  const isTouchDevice = isTouchScreen()

  const className = classnames(
    STYLES.container,
    STYLES[`size${size}`],
    variant && [STYLES[variant]],
    {
      [STYLES.isTouchDevice]: isTouchDevice,
      [STYLES.disabled]: disabled,
      [STYLES.isOpen]: isOpen
    }
  )

  return (
    <div className={className}>
      <div className={STYLES.selects} ref={setReference}>
        {/* Native select element used by touch devices */}
        <NativeSelect
          id={id}
          isTouchDevice={isTouchDevice}
          selected={value || null}
          options={options}
          onChange={onValueChange}
        />
        {label && (
          // disable as these will be added by downshift
          // eslint-disable-next-line jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control
          <label className={STYLES.inputLabel} {...getLabelProps()}>
            <Text
              text={label.text}
              namespace={label.namespace}
              variant={`textRegular${size}`}
              colour={'brandBlue500'}
              variables={label.variables}
              translate={label.translate}
              element="span"
            />
          </label>
        )}
        <SelectLabel
          id={id}
          label={
            !isUndefined(value)
              ? getSelectedText(value, options)
              : noSelectionLabel
          }
          disabled={disabled}
          size={size}
          accessibilityAttributes={getToggleButtonProps}
        />
      </div>
      {/* Custom dropdown for non-touch devices */}
      <div
        ref={setFloating}
        style={{
          position: strategy,
          top: y ?? 0,
          left: x ?? 0,
          display: !disabled && isOpen && !isTouchDevice ? 'block' : 'none'
        }}
      >
        <Dropdown
          options={options}
          size={size}
          selected={value}
          highlighted={highlightedIndex}
          optionsAccessibilityAttributes={getMenuProps}
          optionAccessibilityAttributes={getItemProps}
        />
      </div>
    </div>
  )
}

export { Props, Option }
export default HybridSelect
