import { useReactiveVar } from '@apollo/client'
import React from 'react'

import { selfResolutionPageState } from '../../SelfResolutionPage'
import ContactCustomerLoveDetails from './ContactCustomerLoveDetails'

type Props = {
  namespace: string
}

const ContactCustomerLove = ({ namespace }: Props): JSX.Element => {
  const selfResolutionState = useReactiveVar(selfResolutionPageState)
  const pageTitle = 'contact_customer_love.header'

  React.useEffect(() => {
    selfResolutionPageState({
      ...selfResolutionState,
      showBackButton: false,
      pageTitle
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ContactCustomerLoveDetails
      pageName={'Self Resolution Contact Customer Love'}
      namespace={namespace}
    />
  )
}

export { Props }
export default ContactCustomerLove
