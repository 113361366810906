// @noflow
import { Locale, format } from 'date-fns'
import React, { useCallback } from 'react'

import useWindowSize from '@/hooks/useWindowSize'

import AlertCard from '@/components/elements/atoms/Alert/AlertCard'
import { Button } from '@/components/elements/atoms/Button'
import { FixedBase } from '@/components/elements/atoms/FixedBase'
import type { Props as FixedBaseProps } from '@/components/elements/atoms/FixedBase/FixedBase'
import Icon from '@/components/elements/atoms/Icon/Icon'
import Text from '@/components/elements/atoms/Text'
import { BREAKPOINTS } from '@/components/templates/Base'

import STYLES from '../ChangeDatePageV2.module.sass'

type FixedBaseStyles = Pick<
  FixedBaseProps,
  | 'radiusBottomLeft'
  | 'radiusBottomRight'
  | 'radiusTopLeft'
  | 'radiusTopRight'
  | 'dropShadow'
>

type DateConfirmationFooterProps = {
  selectedDeliveryDate: Date
  locale: Locale
  processing: boolean
  handleCloseFooter: () => void
  updateDeliveryDate: (newDate: Date) => void
  hideCloseButton?: boolean
}

const DateConfirmationFooter = ({
  selectedDeliveryDate,
  locale,
  processing,
  handleCloseFooter,
  updateDeliveryDate,
  hideCloseButton = false,
  ...props
}: DateConfirmationFooterProps & FixedBaseStyles): JSX.Element => {
  const handleOnClick = useCallback(() => {
    updateDeliveryDate(selectedDeliveryDate)
  }, [selectedDeliveryDate, updateDeliveryDate])

  const { windowWidth } = useWindowSize()

  return (
    <FixedBase {...props}>
      <div className={STYLES.container}>
        <div className={STYLES.confirmTextWrapper}>
          <div>
            <Text
              text="change_date.confirm_move"
              namespace="account"
              colour={'brandBlue500'}
              margin={false}
              variant={'textRegular18'}
            />
            <Text
              text={format(new Date(selectedDeliveryDate), 'EEE d MMM', {
                locale
              })}
              colour={'brandBlue500'}
              margin={false}
              bold
              variant="display24"
              translate={false}
              align={windowWidth < BREAKPOINTS.md ? 'center' : 'left'}
            />
          </div>
          {!hideCloseButton && (
            <button
              type="button"
              className={STYLES.closeButton}
              onClick={handleCloseFooter}
            >
              {/* We want to use FlatButton here ideally however it is not currently */}
              {/* set up to render images without using asset management */}
              {/* which we should not use for icons */}
              {/* TODO: update when we update <FlatButton component */}
              <Icon asset={'close'} size={16} />
            </button>
          )}
        </div>
        <div className={STYLES.alertWrapper}>
          <AlertCard
            message={{
              namespace: 'account',
              text: 'change_date.info_message',
              margin: false
            }}
            variant="info"
          />
        </div>
        <Button
          identifier="change_date.update_date"
          fullWidth
          disabled={processing || !selectedDeliveryDate}
          onClick={handleOnClick}
          typography={{
            text: 'change_date.v2_submit',
            namespace: 'account'
          }}
        />
      </div>
    </FixedBase>
  )
}

export { DateConfirmationFooter }
