import React from 'react'

const Warn = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
    <rect width="20" height="20" fill="#F6820E" rx="10" />
    <g fill="#fff" clipPath="url(#a)">
      <path d="M9 6.667a1 1 0 1 1 2 0V10.5a1 1 0 1 1-2 0V6.667ZM9 13.333a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M4 4h12v12H4z" />
      </clipPath>
    </defs>
  </svg>
)

export default Warn
