// @flow

import * as React from 'react'

import AwaitingCreditRedemption from './paid_account/AwaitingCreditRedemption'
import CreditRedemptionComplete from './paid_account/CreditRedemptionComplete'

import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'

type Props = {|
  redeemCredits: (number, () => void) => void,
  myBoxesUrl: string,
  shippingCountryCode: CountryCode
|}

type CreditRedemptionState =
  | 'AWAITING_CREDIT_REDEMPTION'
  | 'CREDIT_REDEMTION_COMPLETE'

type State = {|
  creditRedemptionState: CreditRedemptionState
|}

class PaidAccount extends React.Component<Props, State> {
  constructor (props: Props): void {
    super(props)
    this.state = {
      creditRedemptionState: 'AWAITING_CREDIT_REDEMPTION'
    }
  }

  render (): React.Node {
    const {creditRedemptionState} = this.state
    switch (creditRedemptionState) {
      case 'AWAITING_CREDIT_REDEMPTION':
        return (
          <AwaitingCreditRedemption
            shippingCountryCode={this.props.shippingCountryCode}
            redeemCredits={(amount: number): void => {
              this.props.redeemCredits(amount, (): void => {
                this.setState({creditRedemptionState: 'CREDIT_REDEMTION_COMPLETE'})
              })
            }}
          />
        )

      case 'CREDIT_REDEMTION_COMPLETE':
        return (
          <CreditRedemptionComplete
            myBoxesUrl={this.props.myBoxesUrl}
          />
        )

      default:
        (creditRedemptionState: empty) // eslint-disable-line no-unused-expressions
        return null
    }
  }
}

export default PaidAccount
