// @noflow
import { useReactiveVar } from '@apollo/client'
import i18next from 'i18next'
import React, { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  capitaliseFirstLetter,
  possessive,
  pronounContext,
  toLocalisedSentence
} from '@/utils/StringHelper'

import type { Props as ListItem } from '@/components/elements/atoms/ListItem/ListItem'
import SpeechBubble from '@/components/elements/atoms/SpeechBubble/SpeechBubble'
import Text from '@/components/elements/atoms/Text/Text'
import ExpertCard from '@/components/elements/molecules/ExpertCard/ExpertCard'
import ListGroup from '@/components/elements/molecules/ListGroup/ListGroup'
import StickyNavigation from '@/components/elements/organisms/StickyNavigation/StickyNavigation'
import {
  routeToNextStep,
  routeToPrevStep
} from '@/components/pages/SignupWizardPage/helpers/wizardRoutes'
import type { Route } from '@/components/pages/SignupWizardPage/types/routes'

import STYLES from './FussinessHelp.module.sass'

import { Gender } from '@/types'

import { wizardPageState } from '../../SignupWizardPage'
import useImplicitFormSubmission from '../../helpers/useImplicitFormSubmission'
import type { Dog } from '../../types/types'

type Props = {
  namespace: string
  isGuest: boolean
}

const FussinessHelp = ({ namespace, isGuest }: Props): JSX.Element => {
  const wizardState = useReactiveVar(wizardPageState)
  const dogs: Array<Dog> = wizardState.dogs
  const route = useLocation().pathname as Route

  const fussyDogs = dogs.filter(
    (dog: Dog) =>
      dog.eaterType === 'veryFussy' || dog.eaterType === 'canBeFussy'
  )
  const veryFussyDogs = fussyDogs.filter(
    (dog: Dog) => dog.eaterType === 'veryFussy'
  )

  const localisedDogNameSentence = (dogs: Array<Dog>): string => {
    return toLocalisedSentence({
      arr: dogs.map(({ name }: Dog): string => capitaliseFirstLetter(name)),
      lng: i18next.language
    })
  }

  const dogGenders = (dogs: Array<Dog>): Array<Gender> => {
    return dogs.map((dog: Dog) => dog.gender) as Array<Gender>
  }

  const possessiveDogNames = possessive(
    localisedDogNameSentence(dogs),
    i18next.language
  )

  const navigate = useNavigate()

  const onEnterPress = useCallback(() => {
    navigate(routeToNextStep({ route, isGuest }))
  }, [navigate, isGuest, route])

  useImplicitFormSubmission({ formIsValid: true, onEnterPress })

  const HelpReasons: Array<ListItem> = [
    {
      dataTestId: 'fussiness-help-list-item',
      bulletStyle: 'checkBox',
      text: {
        colour: 'brandBlue500',
        variant: 'textRegular16',
        shouldScale: true,
        namespace,
        text: 'fussiness_help.help_reasons.reason_1'
      }
    },
    {
      dataTestId: 'fussiness-help-list-item',
      bulletStyle: 'checkBox',
      text: {
        colour: 'brandBlue500',
        variant: 'textRegular16',
        shouldScale: true,
        namespace,
        text: 'fussiness_help.help_reasons.reason_2',
        variables: { dogName: possessiveDogNames }
      }
    },
    {
      dataTestId: 'fussiness-help-list-item',
      bulletStyle: 'checkBox',
      text: {
        colour: 'brandBlue500',
        variant: 'textRegular16',
        shouldScale: true,
        namespace,
        text: 'fussiness_help.help_reasons.reason_3',
        variables: { dogName: possessiveDogNames }
      }
    }
  ]

  return (
    <>
      <div className={`${STYLES.container}`}>
        <div className={STYLES.subTitle}>
          <Text
            namespace={namespace}
            text={`fussiness_help.subtitle`}
            variant="display16"
            colour="brandBlue500"
            shouldScale
          />
        </div>
        <ListGroup listItems={HelpReasons} />
        <div className={STYLES.speechBubble}>
          <SpeechBubble
            footPosition="bottomLeft"
            text={{
              namespace,
              variant: 'textRegular16',
              shouldScale: true,
              colour: 'brandBlue500',
              variables: {
                dogName: localisedDogNameSentence(
                  veryFussyDogs.length > 0 ? veryFussyDogs : dogs
                ),
                context: pronounContext(
                  dogGenders(veryFussyDogs.length > 0 ? veryFussyDogs : dogs),
                  i18next.language
                )
              },
              text:
                veryFussyDogs.length > 0
                  ? 'fussiness_help.speech_bubble_very_fussy'
                  : 'fussiness_help.speech_bubble'
            }}
          />
        </div>
        <ExpertCard
          namespace={'expert'}
          expert={{
            name: 'Sarah Barber',
            description: 'sarah_connell_sait.profession',
            imgSrc: 'Web/photos/people/sarah-connell-sait.png',
            imgAlt: 'sarah_connell_sait.image_alt'
          }}
        />
      </div>
      <StickyNavigation
        variant="twoButtons"
        buttonOne={{
          url: routeToPrevStep({ route }),
          text: 'wizard_flow:sticky_navigation.back',
          variant: 'secondary',
          iconColour: 'brandRed400'
        }}
        buttonTwo={{
          dataTestId: 'next-button',
          url: routeToNextStep({ route, isGuest }),
          text: 'wizard_flow:sticky_navigation.continue',
          variant: 'primary',
          iconColour: 'brandWhite'
        }}
      />
    </>
  )
}

export { Props }
export default FussinessHelp
