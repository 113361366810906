// @noflow
import React from 'react'

import {
  ICON_COLOURS,
  IconColours
} from '@/components/elements/atoms/Icon/Icon'

type Props = {
  size: number
  accentColour: IconColours
  width?: number | string
}

const Minus = ({ size, accentColour, width }: Props): JSX.Element => {
  return (
    <svg
      fill="none"
      height={size}
      viewBox="0 0 24 6"
      width={width || '100%'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M22.2857 0.714752H1.71429C0.767679 0.714752 0 1.48243 0 2.42904V4.14332C0 5.08993 0.767679 5.85761 1.71429 5.85761H22.2857C23.2323 5.85761 24 5.08993 24 4.14332V2.42904C24 1.48243 23.2323 0.714752 22.2857 0.714752Z"
        fill={ICON_COLOURS[accentColour]}
        fillRule="evenodd"
      />
    </svg>
  )
}

export default Minus
