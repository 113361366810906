// @noflow

/* eslint-disable i18next/no-literal-string */
import { useNotifications } from '@/context/notifications/notifications'
import { ACCOUNT_ROUTES } from '@/routes'
import { useMutation } from '@apollo/client'
import i18next from 'i18next'
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { possessive } from '@/utils/StringHelper'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import { Button } from '@/components/elements/atoms/Button'
import Card from '@/components/elements/atoms/Card/Card'
import CheckBox from '@/components/elements/atoms/CheckBox/CheckBox'
import { FixedBase } from '@/components/elements/atoms/FixedBase'
import Image from '@/components/elements/atoms/Image/Image'
import { SectionWrapper } from '@/components/elements/atoms/SectionWrapper'
import SkeletonButton from '@/components/elements/atoms/SkeletonButton/SkeletonButton'
import Text from '@/components/elements/atoms/Text/Text'
import { TextInput } from '@/components/elements/atoms/TextInput'
import {
  Item,
  MultipleComboBox
} from '@/components/elements/molecules/MultipleComboBox'
import RadioGroup, {
  RadioOption
} from '@/components/elements/molecules/RadioGroup/RadioGroup'

import STYLES from './UpdateDogAboutInfo.module.sass'

import { UPDATE_DOG_BASIC_INFORMATION_MUTATION } from './mutations/updateDogBasicInformationMutation'

import type {
  DogAboutInfoQuery_user_dogs as Dog,
  DogAboutInfoQuery
} from '../queries/__generated__/DogAboutInfoQuery'
import type {
  UpdateDogBasicInformationMutation,
  UpdateDogBasicInformationMutationVariables
} from './mutations/__generated__/UpdateDogBasicInformationMutation'
import { Gender, Key } from '@/types'

type Props = Partial<DogAboutInfoQuery> & {
  selectedDog?: Dog
  loading: boolean
}

enum NeuteringStatus {
  yes = 'yes',
  no = 'no'
}

const UpdateDogAboutInfoContent = ({
  selectedDog,
  loading,
  ...restProps
}: Props): JSX.Element => {
  const container = useRef<HTMLDivElement>(null)
  const { t } = useTranslation('account')
  const { setErrorNotification, setSuccessNotification } = useNotifications()
  const navigate = useNavigate()
  const { windowWidth } = useWindowSize()

  const copyContext = 'update_dog_profile_basic_information'
  const isMobile = windowWidth < BREAKPOINTS.md

  const loadedData = (() => {
    if (loading) {
      return null
    }

    const { user, breeds } = restProps
    const { id: userId, dogs } = user ?? {}

    return {
      userId,
      dogs,
      breeds
    }
  })()

  const years = Math.floor((selectedDog?.ageInMonths ?? 0) / 12)
  const months = (selectedDog?.ageInMonths ?? 0) % 12
  const genderContext = selectedDog?.gender ?? ''
  const possessiveDogName =
    selectedDog && possessive(selectedDog.name, i18next.language)

  const [shouldSetInitialData, setShouldSetInitialData] = useState(true)
  const [basicInformation, setBasicInformation] = useState({
    name: '',
    gender: Gender.female,
    breed: '',
    unknownBreed: true,
    neutered: NeuteringStatus.no,
    age: {
      years: 0,
      months: 0
    }
  })

  const breedOptions =
    loadedData?.breeds?.map(({ key, name }) => ({
      id: key,
      typography: {
        text: name,
        translate: false
      }
    })) ?? []

  const initialSelectedBreed = [
    {
      id: selectedDog?.breed.key ?? '',
      typography: {
        text: selectedDog?.breed.name ?? '',
        translate: false
      }
    }
  ]

  const genderOptions = Object.values(Gender).map(
    (gender: Gender): RadioOption => ({
      id: gender,
      text: {
        text: `${copyContext}.gender.options`,
        namespace: 'account',
        variables: { context: gender }
      },
      value: gender,
      defaultChecked: gender === basicInformation?.gender
    })
  )

  const neuteringStatusOptions = Object.values(NeuteringStatus).map(
    (neuteringStatus: NeuteringStatus): RadioOption => ({
      id: neuteringStatus,
      text: {
        text: `${copyContext}.neutering_status.options.status`,
        namespace: 'account',
        variables: {
          context: neuteringStatus
        }
      },
      value: neuteringStatus,
      defaultChecked: neuteringStatus === basicInformation?.neutered
    })
  )

  const onSelectOption = useCallback(
    (index: number, field: keyof typeof basicInformation) => {
      let selectedValue: Gender | NeuteringStatus
      switch (field) {
        case 'gender': {
          selectedValue = Object.values(Gender)[index]
          break
        }
        case 'neutered': {
          selectedValue = Object.values(NeuteringStatus)[index]
          break
        }
        default: {
          return
        }
      }

      setBasicInformation((prevState) => ({
        ...prevState,
        [field]: selectedValue
      }))
    },
    []
  )

  const onGenderSelect = useCallback(
    (index: number) => onSelectOption(index, 'gender'),
    [onSelectOption]
  )

  const onNeuteringStatusSelect = useCallback(
    (index: number) => onSelectOption(index, 'neutered'),
    [onSelectOption]
  )

  const onFieldChange = useCallback(
    (
      field: keyof typeof basicInformation,
      value:
        | string
        | number
        | Partial<(typeof basicInformation)[keyof typeof basicInformation]>
    ) => {
      setBasicInformation((prevState) => ({
        ...prevState,
        [field]:
          typeof prevState[field] === 'object' &&
          !Array.isArray(prevState[field]) &&
          typeof value === 'object' &&
          !Array.isArray(value)
            ? {
                ...(prevState[field] as Record<string, unknown>),
                ...value
              }
            : value
      }))
    },
    []
  )

  const onNameChange = useCallback(
    (value: string) => onFieldChange('name', value),
    [onFieldChange]
  )

  const onAgeChange = useCallback(
    (field: 'years' | 'months', value: string) =>
      onFieldChange('age', { [field]: value === '' ? 0 : parseInt(value) }),
    [onFieldChange]
  )

  const onYearsChange = useCallback(
    (value: string) => onAgeChange('years', value),
    [onAgeChange]
  )

  const onMonthsChange = useCallback(
    (value: string) => onAgeChange('months', value),
    [onAgeChange]
  )

  const selectBreed = useCallback(
    (selectedBreeds: Array<Item>) => {
      const [breed] = selectedBreeds

      // TODO: Handle multiple breeds when available on the back-end
      setBasicInformation({
        ...basicInformation,
        breed: selectedBreeds.length === 1 ? breed.id : 'other'
      })
    },
    [basicInformation]
  )

  const onUnknownBreedSelect = useCallback(() => {
    setBasicInformation({
      ...basicInformation,
      unknownBreed: !basicInformation.unknownBreed
    })
  }, [basicInformation])

  /**
   * Update dog about info mutation
   *
   * On error, show error message.
   * On success, show success message and navigate back to the previous screen.
   */
  const [
    updateDogBasicInformationMutation,
    { loading: updateDogBasicInformationLoading }
  ] = useMutation<
    UpdateDogBasicInformationMutation,
    UpdateDogBasicInformationMutationVariables
  >(UPDATE_DOG_BASIC_INFORMATION_MUTATION, {
    onError: () => {
      setErrorNotification({
        text: `${copyContext}.notifications.error`,
        namespace: 'account'
      })
    },
    onCompleted: () => {
      setSuccessNotification({
        text: `${copyContext}.notifications.success`,
        namespace: 'account',
        variables: {
          possessiveDogName
        }
      })

      navigate(`${ACCOUNT_ROUTES.profile}/${selectedDog?.id}`)
    }
  })

  /**
   * Hander to update dog basic information
   */
  const updateDogBasicInformation = useCallback(async () => {
    try {
      const {
        name,
        gender,
        breed,
        unknownBreed,
        age,
        neutered: neuteredStatus
      } = basicInformation

      const ageInMonths = age.years * 12 + age.months
      const neutered = neuteredStatus === NeuteringStatus.yes

      await updateDogBasicInformationMutation({
        variables: {
          userId: loadedData?.userId ?? '',
          attributes: [
            {
              id: selectedDog?.id ?? '',
              name,
              gender,
              breedKey: (unknownBreed ? 'other' : breed) as Key,
              neutered,
              ageInMonthsWhenLastUpdated: ageInMonths
            }
          ]
        }
      })
    } catch (e) {
      setErrorNotification({
        text: `${copyContext}.notifications.error`,
        namespace: 'account'
      })
    }
  }, [
    selectedDog?.id,
    loadedData?.userId,
    updateDogBasicInformationMutation,
    basicInformation,
    setErrorNotification
  ])

  useEffect(() => {
    if (shouldSetInitialData && loadedData && selectedDog) {
      setBasicInformation({
        ...basicInformation,
        name: selectedDog.name,
        gender: selectedDog.gender,
        breed: selectedDog.breed.key,
        unknownBreed: selectedDog.breed.key === 'other',
        neutered: selectedDog.neutered
          ? NeuteringStatus.yes
          : NeuteringStatus.no,
        age: {
          years,
          months
        }
      })

      // Prevent the effect from running again
      setShouldSetInitialData(false)
    }
  }, [
    shouldSetInitialData,
    loadedData,
    basicInformation,
    selectedDog,
    years,
    months
  ])

  const getSectionWrapperProps = (type: string) =>
    ({
      headerTypography: {
        text: `${copyContext}.${type}.title`,
        namespace: 'account',
        variant: 'display16',
        shouldScale: false,
        margin: false,
        variables: {
          context: genderContext
        }
      },
      margin: {
        top: 0,
        bottom: 0
      }
    } as const)

  const formErrorsDisabled = basicInformation.name === ''
  const noChangeToBasicInformation = useMemo(() => {
    if (!selectedDog) return true

    const { name, gender, breed, neutered, ageInMonths } = selectedDog
    const {
      name: newName,
      gender: newGender,
      breed: newBreed,
      neutered: newNeuteredStatus,
      age: { years: newYears, months: newMonths }
    } = basicInformation
    const newNeutered = newNeuteredStatus === NeuteringStatus.yes
    const newAgeInMonths = newYears * 12 + newMonths

    return (
      name === newName &&
      gender === newGender &&
      breed.key === newBreed &&
      neutered === newNeutered &&
      ageInMonths === newAgeInMonths
    )
  }, [selectedDog, basicInformation])
  const isUpdateDisabled =
    loading ||
    updateDogBasicInformationLoading ||
    noChangeToBasicInformation ||
    formErrorsDisabled

  return (
    <div ref={container} className={STYLES.container}>
      <Card shadow padding={isMobile ? 24 : 32} width={520}>
        <div className={STYLES.card}>
          <SectionWrapper {...getSectionWrapperProps('name')}>
            <div className={STYLES.sectionContent}>
              <TextInput
                initialValue={selectedDog?.name}
                onChange={onNameChange}
                validationRules={[
                  {
                    validate: (value) => value.length > 0,
                    errorMessage: t(`${copyContext}.name.error_message`)
                  }
                ]}
                loading={loading}
              />
            </div>
          </SectionWrapper>
          <SectionWrapper {...getSectionWrapperProps('breed')}>
            <div className={STYLES.sectionContent}>
              <div className={STYLES.breedWrapper}>
                {loading ? (
                  <SkeletonButton width="100%" height={60} margin="0" />
                ) : selectedDog ? (
                  <Fragment>
                    <MultipleComboBox
                      items={breedOptions}
                      initialSelectedItems={initialSelectedBreed}
                      placeholder={{
                        text: `${copyContext}.breed.input_placeholder`,
                        namespace: 'account',
                        translate: true
                      }}
                      disabled={basicInformation.unknownBreed}
                      onStateChange={selectBreed}
                      borderRadius={8}
                      padding={16}
                    />
                    <div className={STYLES.unknownBreed}>
                      <CheckBox
                        defaultValue={basicInformation.unknownBreed}
                        id={0}
                        tabIndex={0}
                        onChange={onUnknownBreedSelect}
                        marginRight={0}
                      />
                      <Text
                        namespace="account"
                        text={`${copyContext}.breed.unknown`}
                        variables={{
                          context: genderContext
                        }}
                        margin={false}
                        shouldScale={false}
                        variant="textRegular18"
                      />
                      <Image
                        alt={t(`${copyContext}.breed.unknown_img_alt`)}
                        slug="merdog"
                        image={{
                          width: 100,
                          height: 62
                        }}
                      />
                    </div>
                  </Fragment>
                ) : null}
              </div>
            </div>
          </SectionWrapper>
          <SectionWrapper {...getSectionWrapperProps('age')}>
            <div className={STYLES.sectionContent}>
              <div className={STYLES.rowGridWrapper}>
                <div className={STYLES.ageInputWrapper}>
                  {loading ? (
                    <SkeletonButton width={100} height={60} margin="0" />
                  ) : (
                    <Fragment>
                      <TextInput
                        initialValue={years.toString()}
                        type="number"
                        onChange={onYearsChange}
                        loading={loading}
                        width={60}
                        placeholder="0"
                      />
                      <Text
                        namespace="account"
                        text={`${copyContext}.age.years`}
                        variables={{ count: basicInformation.age.years }}
                        margin={false}
                        shouldScale={false}
                        variant="textRegular18"
                      />
                    </Fragment>
                  )}
                </div>
                <div className={STYLES.ageInputWrapper}>
                  {loading ? (
                    <SkeletonButton width={100} height={60} margin="0" />
                  ) : (
                    <Fragment>
                      <TextInput
                        initialValue={months.toString()}
                        type="number"
                        onChange={onMonthsChange}
                        loading={loading}
                        width={60}
                        placeholder="0"
                      />
                      <Text
                        namespace="account"
                        text={`${copyContext}.age.months`}
                        variables={{ count: basicInformation.age.months }}
                        margin={false}
                        shouldScale={false}
                        variant="textRegular18"
                      />
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          </SectionWrapper>
          <SectionWrapper {...getSectionWrapperProps('gender')}>
            <div className={STYLES.sectionContent}>
              {loading ? (
                <div className={STYLES.rowGridWrapper}>
                  <SkeletonButton width="100%" height={60} margin="0" />
                  <SkeletonButton width="100%" height={60} margin="0" />
                </div>
              ) : (
                <RadioGroup
                  onChange={onGenderSelect}
                  radioOptions={genderOptions}
                  variant="horizontal"
                  identifier={`${copyContext}.gender`}
                  version="v2"
                />
              )}
            </div>
          </SectionWrapper>
          <SectionWrapper {...getSectionWrapperProps('neutering_status')}>
            <div className={STYLES.sectionContent}>
              {loading ? (
                <div className={STYLES.rowGridWrapper}>
                  <SkeletonButton width="100%" height={60} margin="0" />
                  <SkeletonButton width="100%" height={60} margin="0" />
                </div>
              ) : (
                <RadioGroup
                  onChange={onNeuteringStatusSelect}
                  radioOptions={neuteringStatusOptions}
                  variant="horizontal"
                  identifier={`${copyContext}.neutering_status`}
                  version="v2"
                />
              )}
            </div>
          </SectionWrapper>
        </div>
      </Card>
      <div className={STYLES.bottomImageWrapper}>
        <Image
          alt={t(`${copyContext}.img_alt`)}
          slug="dogs-name-conversation"
          image={{
            height: 270,
            width: 300
          }}
        />
      </div>
      <FixedBase backgroundColor="brandWhite90" container={container}>
        <div className={STYLES.footerContent}>
          <Button
            identifier="dog_profile_basic_information.update"
            onClick={updateDogBasicInformation}
            typography={{
              text: `${copyContext}.button`,
              namespace: 'account'
            }}
            skeleton={{
              isLoading: loading
            }}
            disabled={isUpdateDisabled}
          />
        </div>
      </FixedBase>
    </div>
  )
}

export { UpdateDogAboutInfoContent }
