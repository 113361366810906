// @noflow
import React from 'react'

import { AddDogAction } from '@/components/elements/atoms/AddDogAction'

import STYLES from './DogSelection.module.sass'

import type { DogProfileQuery_user_dogs as Dog } from '../../queries/__generated__/DogProfileQuery'

import { SelectableDogAvatar } from '../SelectableDogAvatar'

type Props = {
  dogs: Array<Dog>
  selectedDogId?: string
}

const DogSelection = ({ dogs, selectedDogId }: Props): JSX.Element => {
  return (
    <div className={STYLES.wrapper}>
      {dogs.map((dog) => (
        <SelectableDogAvatar
          key={dog.id}
          dog={dog}
          selectedDogId={selectedDogId}
        />
      ))}
      <AddDogAction bgColour="brandBlue100" />
    </div>
  )
}

export { DogSelection }
