// @noflow
import { useQuery } from '@apollo/client'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import withApollo from '@/components/apollo/withApollo'
import { Button } from '@/components/elements/atoms/Button'
import Icon from '@/components/elements/atoms/Icon/Icon'
import Image from '@/components/elements/atoms/Image/Image'
import SkeletonLoading from '@/components/elements/atoms/SkeletonLoading/SkeletonLoading'
import SkeletonParagraph from '@/components/elements/atoms/SkeletonParagraph/SkeletonParagraph'
import SkeletonTitle from '@/components/elements/atoms/SkeletonTitle/SkeletonTitle'
import Text from '@/components/elements/atoms/Text/Text'
import useSelfResolutionTracking from '@/components/pages/SelfResolutionPage/analytics'
import {
  SelfResolutionCategories_categories as CustomerIssueCategory,
  SelfResolutionCategories
} from '@/components/pages/SelfResolutionPage/queries/__generated__/SelfResolutionCategories'
import { CATEGORIES } from '@/components/pages/SelfResolutionPage/queries/categoriesQuery'
import CategoryButtons from '@/components/pages/SelfResolutionPage/screens/Categories/components/CategoryButtons'

type Props = {
  lastBoxId: string
  deliveryStatus: string
  deliveryDate: Date
}

const SkeletonContent = () => {
  return (
    <div className="contacts__last-box__skeleton">
      <SkeletonTitle align={'left'} height={'2rem'} />
      <SkeletonParagraph align={'left'} count={1} shortLine={false} />
    </div>
  )
}
const LastBox = ({
  lastBoxId,
  deliveryStatus,
  deliveryDate
}: Props): React.ReactElement => {
  const selfResolutionTracking = useSelfResolutionTracking()
  const [categories, setCategories] = useState<Array<CustomerIssueCategory>>([])
  const {
    loading: categoriesLoading,
    data: categoriesData,
    error: categoriesError
  } = useQuery<SelfResolutionCategories>(CATEGORIES, {
    variables: { boxId: lastBoxId }
  })
  const status = useMemo(() => {
    switch (deliveryStatus) {
      case 'unknown':
      case 'manifested':
      case 'in':
        return 'preparing'
      case 'out':
      case 'attempted_delivery':
        return 'delivering'
      default:
        return deliveryStatus
    }
  }, [deliveryStatus])

  useEffect(() => {
    if (categoriesData) {
      const allowedCategoryIds: Array<string> = []
      const deliveryIssueId = '148'
      const contentsIssueId = '149'
      const deliveryDateFourDaysLater = new Date(deliveryDate)
      deliveryDateFourDaysLater.setDate(deliveryDate.getDate() + 4)

      // If not delivered only show deliveryIssue category
      // If delivered only show deliveryIssue category if less than 4 days after delivery
      // Only show contentsIssue category when delivered
      if (status === 'delivered') {
        allowedCategoryIds.push(contentsIssueId)
        if (new Date() <= deliveryDateFourDaysLater) {
          allowedCategoryIds.push(deliveryIssueId)
        }
      } else {
        allowedCategoryIds.push(deliveryIssueId)
      }
      const requiredParentCategories = categoriesData.categories.filter(
        (cat) => {
          return allowedCategoryIds.indexOf(cat.id) > -1
        }
      )
      setCategories(requiredParentCategories)
    }
  }, [categoriesData, status, deliveryDate])

  const selectCategory = useCallback(
    (categoryId: string) => {
      const category = categoriesData?.categories.find(
        (cat) => cat.id === categoryId
      )
      if (category) {
        selfResolutionTracking.trackEvent('category selected', {
          category: category?.name
        })
        const selfResolutionUrl =
          '/self-resolution/categories?reportable_type=Box&reportable_id=' +
          lastBoxId +
          '&category_id=' +
          categoryId
        window.location.href = selfResolutionUrl
      }
    },
    [lastBoxId, categoriesData, selfResolutionTracking]
  )

  const defaultSelfResolution = useCallback(() => {
    const selfResolutionUrl = '/self-resolution?box_id=' + lastBoxId
    window.location.href = selfResolutionUrl
  }, [lastBoxId])
  const { t } = useTranslation('contacts')

  return (
    <div className="contacts__last-box">
      <Text
        element="h2"
        namespace="contacts"
        text="last_box.title"
        variant="display28"
        align={'left'}
      />
      <div className="contacts__last-box__component">
        <Image
          alt={t('last_box.image_alt')}
          slug="pooch-enjoying-their-latest-box-cover"
          image={{
            height: 236,
            width: 600
          }}
        />
        <div className="contacts__last-box__details">
          <Text
            namespace={'contacts'}
            text={'last_box.' + status + '.title'}
            variant={'display20'}
            variables={{ date: deliveryDate }}
            align={'left'}
          />
          {deliveryStatus !== 'delivered' && (
            <Text
              namespace={'contacts'}
              text={'last_box.' + status + '.text'}
              variant={'textRegular16'}
              variables={{ date: deliveryDate }}
              align={'left'}
            />
          )}
          {deliveryStatus === 'delivered' && (
            <div className="contacts__last-box__delivered">
              <Icon asset={'checkmark'} size={20} />
              <Text
                namespace={'contacts'}
                text="last_box.delivered.text"
                variant={'textRegular16'}
                variables={{ date: deliveryDate }}
                align={'left'}
              />
            </div>
          )}
        </div>
        <div className="contacts__last-box__categories">
          {!categoriesError && (
            <SkeletonLoading
              contentReady={!categoriesLoading && categories.length > 0}
              template={<SkeletonContent />}
            >
              <CategoryButtons
                categories={categories}
                selectCategory={selectCategory}
              />
            </SkeletonLoading>
          )}
          {!categoriesLoading && categoriesError && (
            <Button
              disableAnalytics
              variant={'primary'}
              typography={{
                namespace: 'contacts',
                text: 'last_box.getHelpButton'
              }}
              onClick={defaultSelfResolution}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default withApollo(LastBox)
