// @noflow
import isNull from 'lodash/isNull'
import React, { ReactNode, useCallback, useEffect, useRef } from 'react'
// Utilities
import { SpringConfig, animated, useSpring } from 'react-spring'

// Styles
import STYLES from './Animated.module.sass'

type Indent = {
  top?: number
  right?: number
  bottom?: number
  left?: number
}

type ExpandProps = {
  show: boolean
  origin?: 'center' | 'top' | 'bottom'
  children: ReactNode
  margin?: Indent
  config?: SpringConfig
  fullWidth?: boolean
  maxHeight?: number
}

type ExpandWithPeekContentProps = {
  show: boolean
  origin?: 'center' | 'top' | 'bottom'
  children: ReactNode
  margin?: Indent
  config?: SpringConfig
  colourGradient?: keyof Pick<typeof STYLES, 'brandYellow300' | 'transparent'>
  peekHeight?: number
}

/**
 * Used to show/hide an element on the page with an opening and closing animation
 */
const Expand = ({
  show = false,
  origin = 'center',
  config,
  children,
  fullWidth,
  margin: { top, right, bottom, left } = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  maxHeight
}: ExpandProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null)

  const getHeight = () => {
    if (maxHeight) {
      return (show ? maxHeight : 0) + 'px'
    }
    return (show && !isNull(ref.current) ? 1000 : 0) + 'px'
  }

  const getStyles = () => ({
    maxHeight: getHeight(),
    width: fullWidth ? '100%' : 'auto',
    opacity: show ? 1 : 0,
    // eslint-disable-next-line i18next/no-literal-string
    transform: `scaleY(${show ? 1 : 0})`,
    // eslint-disable-next-line i18next/no-literal-string
    transformOrigin: `${origin} center`,
    marginTop: (show && top ? top : 0) + 'rem',
    marginRight: (show && right ? right : 0) + 'rem',
    marginBottom: (show && bottom ? bottom : 0) + 'rem',
    marginLeft: (show && left ? left : 0) + 'rem',
    pointerEvents: show
      ? ('auto' as React.CSSProperties['pointerEvents'])
      : ('none' as React.CSSProperties['pointerEvents'])
  })

  const [styles, api] = useSpring(() => ({
    config: { mass: 1, tension: 225, friction: 20, ...config },
    ...getStyles()
  }))

  useEffect(() => {
    api.start(getStyles())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, show])

  return (
    <animated.div style={styles}>
      <div ref={ref}>{children}</div>
    </animated.div>
  )
}

const ExpandWithContentPeek = ({
  show = false,
  origin = 'center',
  config,
  children,
  margin: { top, right, bottom, left } = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  colourGradient = 'transparent',
  peekHeight = 100
}: ExpandWithPeekContentProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null)

  const getHeight = useCallback(() => {
    return (
      (show && !isNull(ref.current) ? ref.current.offsetHeight : peekHeight) +
      'px'
    )
  }, [peekHeight, show])

  const getStyles = useCallback(() => {
    return {
      height: getHeight(),
      // eslint-disable-next-line i18next/no-literal-string
      transformOrigin: `${origin} center`,
      marginTop: (show ? top : 0) + 'rem',
      marginRight: (show ? right : 0) + 'rem',
      marginBottom: (show ? bottom : 0) + 'rem',
      marginLeft: (show ? left : 0) + 'rem',
      overflow: 'hidden',
      position: 'relative'
    } as React.CSSProperties
  }, [bottom, getHeight, left, origin, right, show, top])

  const [styles, api] = useSpring(() => ({
    config: { mass: 1, tension: 225, friction: 20, ...config },
    ...getStyles()
  }))

  useEffect(() => {
    api.start(getStyles())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, show])

  return (
    <animated.div style={styles}>
      <div ref={ref}>
        {children}
        <div
          className={`${STYLES.overlay} ${STYLES[colourGradient]} ${
            !show ? STYLES.showOverlay : ''
          }`}
        />
      </div>
    </animated.div>
  )
}

type WiggleProps = {
  repeat?: number
  children: ReactNode
}

/**
 * Used to draw attention to an element on the page.
 * Only one wiggle animation should be visible at once.
 */
const Wiggle = ({ repeat = 3, children }: WiggleProps): JSX.Element => {
  const n = useRef(0)

  const { tween } = useSpring({
    from: { tween: 0 },
    tween: 1,
    config: { duration: 1250 },
    // loop 3 times
    loop: () => n.current < repeat,
    delay: 1000,
    onStart: () => n.current++
  })

  return (
    <animated.div
      style={{
        scale: tween
          .to({
            range: [0, 0.1, 0.2, 0.9, 1],
            output: [1, 0.9, 1.02, 1.02, 1]
          })
          .to((scale: number) => scale),
        rotate: tween
          .to({
            range: [0, 0.4, 0.5, 0.6, 0.7, 0.8, 1],
            output: [0, 0, -2, 2, -1, 1, 0]
          })
          .to((rotation: number) => rotation)
      }}
    >
      {children}
    </animated.div>
  )
}

type FadeProps = {
  show: boolean
  children: ReactNode
  opacity?: number // Must be number between 0 - 1. Use 0.5 for example to partially fade out,
  duration?: number // Duration of fade animation in milliseconds
}

/**
 * Used to show/hide an element on the page with a fade animation
 */
const Fade = ({
  show = false,
  children,
  opacity = 0,
  duration = 500
}: FadeProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null)

  const getStyles = () => ({
    opacity: show ? 1 : opacity
  })

  const [styles, api] = useSpring(() => ({
    config: { duration },
    ...getStyles()
  }))

  useEffect(() => {
    api.start(getStyles())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, show])

  return (
    <animated.div style={styles}>
      <div ref={ref}>{children}</div>
    </animated.div>
  )
}

type BounceProps = {
  show: boolean
  children: ReactNode
}

/**
 * Used to show/hide an element on the page with a bounce animation
 */
const Bounce = ({ show = false, children }: BounceProps): JSX.Element => {
  const getStyles = () => ({
    opacity: show ? 1 : 0,
    // eslint-disable-next-line i18next/no-literal-string
    transform: `scale(${show ? 1 : 0.5})`
  })

  const [styles, api] = useSpring(() => ({
    config: { mass: 1, tension: 500, friction: 14 },
    ...getStyles()
  }))

  useEffect(() => {
    api.start(getStyles())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  return <animated.div style={styles}>{children}</animated.div>
}

export {
  Expand,
  ExpandProps,
  ExpandWithContentPeek,
  Wiggle,
  WiggleProps,
  Fade,
  FadeProps,
  Bounce
}
