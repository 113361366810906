import { gql } from '@apollo/client'

// eslint-disable-next-line i18next/no-literal-string
const DELIVERY_DATE_V2_UPDATE = gql`
  mutation DeliveryDateV2Update(
    $userId: ID!
    $boxId: ID!
    $selectedDeliveryDate: ISO8601Date!
    $deliveryAreaId: ID
    $adjustFutureBoxDates: Boolean!
    $triggerEvents: Boolean
    $nextNBoxes: Int!
  ) {
    boxDeliveryDateUpdate(
      userId: $userId
      boxId: $boxId
      date: $selectedDeliveryDate
      deliveryAreaId: $deliveryAreaId
      adjustFutureBoxDates: $adjustFutureBoxDates
      triggerEvents: $triggerEvents
    ) {
      id
      currentDeliveryAreaPreference {
        id
        preferredCarrierService {
          id
          carrier {
            name
          }
        }
      }
      subscription {
        id
        nextNBoxes(num: $nextNBoxes) {
          id
          isoDeliveryDate
          editable
        }
        box(boxId: $boxId) {
          id
          isoDeliveryDate
        }
      }
    }
  }
`

export { DELIVERY_DATE_V2_UPDATE }
