// @noflow
import type { Dispatch, Thunk } from 'redux'

import * as ACTIONS from '../actions'
import { MarketingPreferenceData } from '../message_types'

const { fetch } = global

const getMarketingPreferenceData = (
  userId: number
): Thunk => {
  return (dispatch: Dispatch, getState): void => {
    const { csrfToken } = getState()
    dispatch(ACTIONS.makeMarketingPreferencesRequest())
    const endpoint = '/admin/single_customer_view/marketing_preferences'
    const queryParams = `?user_id=${userId}`
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-token': csrfToken
    }
    const method = 'GET'
    const credentials = 'same-origin'

    fetch(endpoint + queryParams, {
      headers,
      method,
      credentials
    }).then((res: Response): void => {
      if (!res.ok) {
        res.json().then((): void => {
          dispatch(ACTIONS.receiveEmptyMarketingPreferencesResponse())
        })
      } else {
        res.json().then((res: MarketingPreferenceData): void => {
          const action = ACTIONS.receiveMarketingPreferencesResponse(
            res.user.available_preferences,
            res.user.existing_preferences
          )
          dispatch(action)
        })
      }
    })
  }
}

const updateMarketingPreferences = (
  userId: number,
  purpose_ids: Array<number>
): Thunk => {
  return (dispatch: Dispatch, getState): void => {
    const { csrfToken } = getState()
    dispatch(ACTIONS.makeMarketingPreferencesRequest())
    const endpoint = '/admin/single_customer_view/marketing_preferences'
    // eslint-disable-next-line i18next/no-literal-string
    const queryParams = `user_id=${userId}`
    const body = JSON.stringify({
      preferences: { purpose_ids }
    })
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-CSRF-token': csrfToken
    }
    const method = 'POST'
    const credentials = 'same-origin'

    fetch(`${endpoint}?${queryParams}`, {
      headers,
      method,
      credentials,
      body
    }).then((res: Response): Promise<void> | void => {
      if (!res.ok) {
        dispatch(ACTIONS.errorUpdatingMarketingPreferences())
      } else {
        res.json().then((res: MarketingPreferenceData) => {
          const action = ACTIONS.receiveMarketingPreferencesResponse(
            res.user.available_preferences,
            res.user.existing_preferences
          )
          dispatch(action)
        })
      }
    })
  }
}

export { getMarketingPreferenceData, updateMarketingPreferences }
