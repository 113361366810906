// @noflow
import type { Code as CountryCode } from '@/types/index'

const dummyPostcodeForShippingCountry = (
  shippingCountryCode: CountryCode
): string => {
  switch (shippingCountryCode) {
    case 'IE': {
      return 'D01 V2N0'
    }
    case 'GB': {
      return 'W12 8AA'
    }
    case 'NI': {
      return 'BT15 2AJ'
    }
    case 'NL': {
      return '1054 HC'
    }
    case 'BE': {
      return '1000'
    }
    case 'PL': {
      return '62-262'
    }
    case 'DE': {
      return '10115'
    }
    default: {
      return 'W12 8AA'
    }
  }
}

const dummyCityForShippingCountry = (
  shippingCountryCode: CountryCode
): string => {
  switch (shippingCountryCode) {
    case 'IE': {
      return 'Dublin'
    }
    case 'GB': {
      return 'London'
    }
    case 'NI': {
      return 'Belfast'
    }
    case 'NL': {
      return 'Amsterdam'
    }
    case 'BE': {
      return 'Brussels'
    }
    case 'PL': {
      return 'Warsaw'
    }
    case 'DE': {
      return 'Berlin'
    }
    default: {
      return 'London'
    }
  }
}

export { dummyPostcodeForShippingCountry, dummyCityForShippingCountry }
