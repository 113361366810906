import * as Sentry from '@/utils/sentry'

import { ensureNever } from '@/typescript/utils'

import {
  AuthenticationType,
  PaymentIdentifier,
  PaymentMethod,
  PaymentProvider,
  PaymentType
} from '../../../types'

const paymentMethodIdentifierType = (
  paymentMethod: PaymentMethod
): PaymentIdentifier => {
  switch (paymentMethod) {
    case 'creditCard':
    case 'applePay':
    case 'iDeal':
    case 'bancontact':
    case 'googlePay':
    case 'goCardless':
    case 'none': {
      return 'id'
    }
    case 'payPal': {
      return 'token'
    }
    default: {
      ensureNever(paymentMethod)

      Sentry.captureException(`Unknown paymentMethodIdentifierType`, {
        extra: paymentMethod,
        tags: {
          product: Sentry.Product.Checkout
        }
      })

      return 'id'
    }
  }
}

const paymentMethodToPaymentMethodType = (
  paymentMethod: PaymentMethod
): PaymentType => {
  switch (paymentMethod) {
    case 'creditCard':
    case 'applePay':
    case 'googlePay':
    case 'none': {
      return 'credit_card'
    }
    case 'iDeal':
    case 'bancontact': {
      return 'sepa_direct_debit'
    }
    case 'payPal': {
      return 'billing_agreement'
    }
    case 'goCardless': {
      return 'direct_debit'
    }
    default: {
      ensureNever(paymentMethod)

      Sentry.captureException(`Unknown paymentMethodToPaymentMethodType`, {
        extra: paymentMethod,
        tags: {
          product: Sentry.Product.Checkout
        }
      })

      return 'credit_card'
    }
  }
}

const paymentMethodToProvider = (
  paymentMethod: PaymentMethod
): PaymentProvider => {
  switch (paymentMethod) {
    case 'creditCard':
    case 'applePay':
    case 'iDeal':
    case 'bancontact':
    case 'googlePay':
    case 'goCardless':
    case 'none': {
      return 'stripe'
    }
    case 'payPal': {
      return 'paypal'
    }
    default: {
      ensureNever(paymentMethod)

      Sentry.captureException(`Unknown paymentMethodToProvider`, {
        extra: paymentMethod,
        tags: {
          product: Sentry.Product.Checkout
        }
      })

      return 'stripe'
    }
  }
}

const stripePaymentMethodToPaymentMethod = (
  stripePaymentMethod: string
): PaymentMethod => {
  switch (stripePaymentMethod) {
    case 'card':
      return 'creditCard'
    case 'ideal':
      return 'iDeal'
    case 'bancontact':
      return 'bancontact'
    case 'google_pay':
      return 'googlePay'
    case 'apple_pay':
      return 'applePay'
    default:
      Sentry.captureException(`Unknown stripePaymentMethodToPaymentMethod`, {
        extra: stripePaymentMethod,
        tags: {
          product: Sentry.Product.Checkout
        }
      })
      return 'creditCard'
  }
}

const paymentMethodToAuthenticationType = (
  paymentMethod: PaymentMethod
): AuthenticationType => {
  switch (paymentMethod) {
    case 'creditCard':
    case 'applePay':
    case 'googlePay': {
      return 'credit_card'
    }
    case 'iDeal':
    case 'bancontact': {
      return paymentMethod
    }
    case 'payPal':
    case 'goCardless':
    case 'none': {
      Sentry.captureException(`Unexpected paymentMethodToAuthenticationType`, {
        extra: paymentMethod,
        tags: {
          product: Sentry.Product.Checkout
        }
      })

      return 'credit_card'
    }
    default: {
      ensureNever(paymentMethod)

      Sentry.captureException(`Unknown paymentMethodToAuthenticationType`, {
        extra: paymentMethod,
        tags: {
          product: Sentry.Product.Checkout
        }
      })

      return 'credit_card'
    }
  }
}

export {
  paymentMethodIdentifierType,
  paymentMethodToPaymentMethodType,
  paymentMethodToProvider,
  stripePaymentMethodToPaymentMethod,
  paymentMethodToAuthenticationType
}
