/* eslint-disable i18next/no-literal-string */
import { ACCOUNT_ROUTES } from '@/routes'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { trackEvent } from '@/services/segment'

import DogDiggingTailWag from 'assets/images/illustrations/dogs/dog-digging-tail-wag.svg'
import DogDigging from 'assets/images/illustrations/dogs/dog-digging.svg'

import { Button } from '@/components/elements/atoms/Button'
import Image from '@/components/elements/atoms/Image/Image'
import Text from '@/components/elements/atoms/Text'

import STYLES from './NotFoundErrorPage.module.sass'

import { NavigateContext } from '../App'

type Props = {
  data: string
}

const NotFoundErrorPage = ({ data }: Props): JSX.Element => {
  const navigate = useContext(NavigateContext)
  const [tailWag, setTailWag] = useState(false)

  const backToHome = useCallback((): void => {
    navigate(ACCOUNT_ROUTES.base, ACCOUNT_ROUTES.base)
  }, [navigate])

  useEffect(() => {
    trackEvent(`Route Not Found - ${data}`, {
      component_identifier: 'error_page'
    })
  }, [data])

  useEffect(() => {
    const prevTitle = document.title
    document.title = '404 Error'
    return () => {
      document.title = prevTitle
    }
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      setTailWag((prev) => !prev)
    }, 300)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className={STYLES.container}>
      <div className={STYLES.logoWrapper}>
        <Link to={ACCOUNT_ROUTES.base}>
          <Image
            className={STYLES.logo}
            alt={''}
            slug={'account-logo'}
            image={{
              height: 157,
              width: 194
            }}
          />
        </Link>
      </div>
      <div className={STYLES.footer}>
        <img
          alt={''}
          className={STYLES.image}
          src={tailWag ? DogDiggingTailWag : DogDigging}
        />
        <div className={STYLES.footerWrapper}>
          <div className={STYLES.footerContent}>
            <Text
              variant={'display24'}
              translate={false}
              text={'404'}
              colour={'brandBlue400'}
              margin={false}
            />
            <Text
              variant={'display36'}
              namespace={'account'}
              text={'error_not_found.title'}
              margin={false}
            />
            <Text
              variant={'textRegular20'}
              namespace={'account'}
              text={'error_not_found.message'}
              colour={'brandBlue400'}
            />
            <Button
              typography={{
                namespace: 'account',
                text: 'error_not_found.button'
              }}
              onClick={backToHome}
              identifier={'404-error-back-to-home'}
              fullWidth
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotFoundErrorPage
