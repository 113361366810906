// @noflow
import React from 'react'

type Props = {
  size: number
  width?: number | string
}

const WhatsApp = ({ size, width }: Props): JSX.Element => {
  return (
    <svg
      width={width || '100%'}
      height={size}
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24.5" cy="24.5" r="24.5" fill="#25D366" />
      <path
        d="M24.765 38.3698C22.7373 38.3766 20.7318 37.9484 18.8837 37.1141C18.5652 36.9584 18.2181 36.8699 17.864 36.854C17.5099 36.8381 17.1563 36.895 16.8251 37.0214C14.9457 37.7316 13.0209 38.3492 11.1003 38.9668C10.8078 39.0352 10.5006 38.9972 10.2336 38.8597C9.82191 38.6539 10.0566 38.2998 10.1698 37.991C10.9006 35.9839 11.6108 33.9686 12.3622 31.9677C12.4936 31.6383 12.555 31.2852 12.5426 30.9308C12.5302 30.5764 12.4442 30.2285 12.2901 29.9091C10.5815 25.9155 10.2316 21.8375 12.0843 17.813C13.764 14.1632 16.6131 11.695 20.5429 10.7521C22.7167 10.2293 24.9894 9.7064 27.2517 10.1943C34.1191 11.6723 38.3206 16.6108 39.0926 22.4859C40.1815 30.8478 33.2545 38.4789 24.765 38.3698ZM13.305 35.4878C14.1058 35.811 14.7316 35.4734 15.3018 35.212C16.4216 34.7014 17.453 34.8373 18.5214 35.3314C19.2501 35.669 20.0056 36.1054 20.7734 36.1692C22.3316 36.2824 23.8958 36.2865 25.4546 36.1815C29.0983 35.9757 32.2067 34.58 34.6667 31.8194C39.0308 26.9221 38.6809 19.8489 33.8 15.4745C31.7847 13.6691 29.3391 12.5925 26.8133 11.732C26.2263 11.5388 25.5993 11.5012 24.9935 11.6229C21.7657 12.3146 18.7272 13.3912 16.1087 15.5609C13.1362 18.0312 12.1048 21.1623 12.6092 24.8245C12.8295 26.4487 13.445 28.0276 13.939 29.6086C14.5648 31.6342 14.6636 33.5836 13.2988 35.4857L13.305 35.4878Z"
        fill="white"
      />
      <path
        d="M28.2402 30.9879C27.808 30.8396 27.3242 30.6585 26.8322 30.5103C22.7892 29.2937 20.3436 26.2449 18.2212 22.8936C17.2558 21.362 17.7045 19.3282 19.0447 18.1589C19.9937 17.317 20.9756 17.4878 21.5561 18.6118C21.797 19.075 22.0028 19.5546 22.2272 20.0281C22.6657 20.9524 22.6389 21.8129 21.904 22.5931C21.4408 23.0809 21.6343 23.6223 21.9822 24.0135C22.9231 25.0784 23.9282 26.0849 24.9918 27.0272C26.1467 28.0132 27.0072 27.8341 27.9438 26.6278C28.3041 26.1626 29.2942 26.0103 29.8933 26.3232C30.2926 26.529 30.6941 26.7349 31.1037 26.9181C32.1021 27.3648 32.3759 28.0441 31.9271 29.0487C31.5278 29.9483 30.7682 30.4115 29.8686 30.6605C29.3663 30.7902 28.8475 30.8643 28.2402 30.9879Z"
        fill="white"
      />
    </svg>
  )
}

export default WhatsApp
