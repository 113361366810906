// Test is a color is dark or light
// Taken from https://awik.io/determine-color-bright-dark-using-javascript/
const isDark = (hexColor: string): boolean => {
  const color = +('0x' + hexColor.slice(1).replace(hexColor, '$&$&'))

  const r = color >> 16
  const g = (color >> 8) & 255
  const b = color & 255

  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

  return hsp < 127.5
}

export { isDark }
