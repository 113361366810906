// @noflow
import classnames from 'classnames'
import Cookies from 'js-cookie'
import * as React from 'react'
import { useCallback } from 'react'

import { trackEvent } from '@/services/segment'

import STYLES from './Video.module.sass'

type VideoParams = {
  quality?: string
  format: 'mp4' | 'webm'
}

type Props = {
  slug: string
  video: VideoParams
  alt: string
  className?: string
  lazyLoad?: boolean
  poster?: string
  loop?: boolean
  playing?: boolean
  muted?: boolean
  playsInLine?: boolean
  controls?: boolean
  onStart?: () => void
  onEnded?: () => void
  screenIdentifier?: string
  componentIdentifier?: string
  playerHeight?: number | string
  playerWidth?: number | string
  controlsList?: string | undefined
}

const urlBuilder = (
  slug: Props['slug'],
  videoParams?: Props['video']
): string => {
  const locale = Cookies.get('user_language') || 'en'
  const hostName = window.location.origin

  // eslint-disable-next-line i18next/no-literal-string
  const url = new URL(`${hostName}/asset_management/assets/${slug}/${locale}?`)
  if (!videoParams) return url.pathname + url.search

  for (const [key, value] of Object.entries(videoParams)) {
    url.searchParams.set(key, value)
  }

  return url.pathname + url.search
}

/**
 * A video element that uses the butternut asset management system and React package to dynamically
 * transform our uploaded videos
 * Set 'playing' to true to autoplay the video.
 * All props other than slug are optional.
 *
 * Example usage
 * <Video
 *   alt='A video of butternut box'
 *   slug='video-slug'
 *   video={{ format: 'webm' }}
 * />
 */

const Video = ({
  slug,
  alt,
  video,
  className,
  poster,
  lazyLoad = false,
  loop = false,
  playing = false,
  muted = false,
  playsInLine = false,
  controls = true,
  onStart,
  onEnded,
  screenIdentifier,
  componentIdentifier,
  playerHeight = '100%',
  playerWidth = '100%',
  controlsList
}: Props): JSX.Element => {
  const src = urlBuilder(slug, video)

  const onVideoStart = useCallback(() => {
    if (screenIdentifier && componentIdentifier) {
      trackEvent('Video started', {
        component_identifier: componentIdentifier,
        ...(screenIdentifier ? { screen_identifier: screenIdentifier } : {})
      })
      onStart && onStart()
    }
  }, [componentIdentifier, onStart, screenIdentifier])

  const onVideoEnded = useCallback(() => {
    if (screenIdentifier && componentIdentifier) {
      trackEvent('Video ended', {
        component_identifier: componentIdentifier,
        ...(screenIdentifier ? { screen_identifier: screenIdentifier } : {})
      })
      onEnded && onEnded()
    }
  }, [componentIdentifier, onEnded, screenIdentifier])

  const classname = classnames(STYLES.video, className, {
    lazy: lazyLoad
  })

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video
      controlsList={`nodownload ${controlsList}`}
      aria-label={alt}
      src={src}
      preload="auto"
      className={classname}
      width={playerHeight}
      height={playerWidth}
      poster={poster}
      autoPlay={playing}
      loop={loop}
      muted={muted}
      playsInline={playsInLine}
      controls={controls}
      onPlay={onVideoStart}
      onEnded={onVideoEnded}
      disablePictureInPicture
      disableRemotePlayback
    >
      <source src={src} type={`video/${video?.format}`} />
    </video>
  )
}

export type { Props }

export default Video
