// @noflow
import type { Language } from '@/packs/localisation'
import flattenDeep from 'lodash/flattenDeep'
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

// Utils
import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { formatCurrencyWithDecimal } from '@/utils/currency'

// Constants
import BREAKPOINTS from '@/constants/Breakpoints'

import useButternutBoost from '@/hooks/useButternutBoost'
import useWindowSize from '@/hooks/useWindowSize'

// Assets
import DiscountRibbon from 'assets/images/icons/badges/50-discount-ribbon.svg'

import BoostLogo from '@/components/elements/atoms/BoostLogo/BoostLogo'
import { cloudinaryPath } from '@/components/elements/atoms/CloudinaryImage/CloudinaryImage'
import Label from '@/components/elements/atoms/Label/Label'
import Text from '@/components/elements/atoms/Text/Text'
import type {
  ExtrasListQuery_productGroups_productCollections as Product,
  ExtrasListQuery_productGroups_productCollections_productVariants as ProductVariant
} from '@/components/pages/ExtrasPage/screens/ExtrasList/queries/__generated__/ExtrasListQuery'
import CloudinaryImage from '@/components/shared/elements/CloudinaryImage/CloudinaryImage'

// Styles
import STYLES from './ProductCard.module.sass'

// Types
import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'
import { MembershipTypes, UnavailableObjectReason } from '@/types'

import ProductCardSkeleton from './ProductCardSkeleton'

type Props = {
  product: Product | null
  shippingCountryCode: CountryCode | undefined
  preferredLanguage: Language | undefined
  selected?: boolean
  url: string
  linkType?: ProductCardWrapperProps['linkType']
  treatmentStyling?: boolean
}

type ProductCardWrapperProps = {
  linkType: 'link' | 'a'
  url: Props['url']
  children: JSX.Element
}

const ProductCardWrapper = ({
  linkType,
  url,
  children
}: ProductCardWrapperProps): JSX.Element | null => {
  switch (linkType) {
    case 'a': {
      return (
        <a className={STYLES.link} href={url}>
          {children}
        </a>
      )
    }
    case 'link': {
      return (
        <Link className={STYLES.link} to={url}>
          {children}
        </Link>
      )
    }
    default: {
      return null
    }
  }
}

const ProductCard = ({
  product,
  shippingCountryCode,
  preferredLanguage,
  selected = false,
  url,
  linkType = 'a',
  treatmentStyling = false
}: Props): JSX.Element => {
  const { windowWidth } = useWindowSize()
  const { boostedSubscription, boostedType } = useButternutBoost()

  if (!product || !shippingCountryCode || !preferredLanguage) {
    return <ProductCardSkeleton />
  }

  const { locale, currency } = countryCodeToLocaleCurrency(
    shippingCountryCode,
    preferredLanguage
  )

  const grossPrices = product.productVariants.map(
    ({ grossPrice }: ProductVariant): number => grossPrice
  )
  const netPrices = flattenDeep(
    Object.values(
      product.productVariants.map(
        ({ productVariantDeliveryTypes }: ProductVariant): Array<number> => {
          return productVariantDeliveryTypes.map((types) => types.netPrice)
        }
      )
    )
  )
  const minNetPrice = Math.min(...netPrices)

  const boostedNetPrices = flattenDeep(
    Object.values(
      product.productVariants.map(
        ({ productVariantDeliveryTypes }: ProductVariant): Array<number> => {
          return productVariantDeliveryTypes.map(
            (types) => types.boostedNetPrice
          )
        }
      )
    )
  )
  const minBoostPrice = Math.min(...boostedNetPrices)
  const boostedPrice = formatCurrencyWithDecimal(minBoostPrice, {
    locale: locale,
    currency: currency
  })

  const adjustedGrossPrice = product.productVariants.map(
    ({ productVariantDeliveryTypes }: ProductVariant): number => {
      const productVariantDeliveryType = productVariantDeliveryTypes.filter(
        (productVariantType) => productVariantType.deliveryType === 'recurring'
      )
      return productVariantDeliveryType[0]
        ? productVariantDeliveryType[0].adjustedGrossPrice
        : 0
    }
  )

  const discountedAmount =
    Math.min(...adjustedGrossPrice) === 0
      ? Math.min(...grossPrices) - minNetPrice
      : Math.min(...adjustedGrossPrice) - minNetPrice
  const minGrossPrice =
    Math.min(...adjustedGrossPrice) !== 0
      ? Math.min(...adjustedGrossPrice)
      : Math.min(...grossPrices)

  const discountedPrice =
    discountedAmount !== 0
      ? // eslint-disable-next-line i18next/no-literal-string
        `<accent type="strikeTrough">${formatCurrencyWithDecimal(
          Math.min(...grossPrices),
          { locale: locale, currency: currency }
        )}</accent>`
      : // eslint-disable-next-line i18next/no-literal-string
        `<b>${formatCurrencyWithDecimal(minGrossPrice, {
          locale: locale,
          currency: currency
        })}</b>`

  const showComingSoon =
    product.unavailableObject?.reason === UnavailableObjectReason.coming_soon

  return (
    <ProductCardWrapper linkType={linkType} url={url}>
      <div
        className={`${STYLES.container} ${selected && STYLES.selected} ${
          treatmentStyling ? STYLES.treatment : ''
        }`}
      >
        {product.productSticker?.image?.src &&
          !product.unavailableObject?.reason &&
          boostedType !== MembershipTypes.boost_ap_only && (
            <div className={STYLES.tag}>
              <img
                src={cloudinaryPath({
                  path: product.productSticker.image.src,
                  transformations: { format: 'svg', width: 65 }
                })}
                alt={product.name}
              />
            </div>
          )}
        {boostedType === MembershipTypes.boost_ap_only && (
          <div className={STYLES.tag}>
            <img src={DiscountRibbon} alt={product.name} />
          </div>
        )}
        {product.primaryImage?.src && (
          <div className={STYLES.image}>
            {product.unavailableObject?.reason && (
              <div
                className={`${STYLES.outOfStock} ${
                  showComingSoon && STYLES.comingSoon
                }`}
              >
                <Text
                  text={product.unavailableObject?.reasonCopy}
                  translate={false}
                  margin={false}
                  variant="display16"
                  colour="brandWhite"
                  align="center"
                  bold
                />
              </div>
            )}

            <CloudinaryImage
              className={treatmentStyling ? STYLES.treatment : ''}
              image={{
                path: product.primaryImage.src,
                width: windowWidth > BREAKPOINTS.md ? 265 : 147,
                height: windowWidth > BREAKPOINTS.md ? 240 : 140,
                crop: 'fill',
                quality: 'auto:best',
                dpr: 2
              }}
              alt={product.name}
            />
          </div>
        )}
        <div className={STYLES.content}>
          <Text
            text={product.name}
            translate={false}
            margin={false}
            variant="textRegular16"
            colour={selected ? 'brandWhite' : 'brandBlue500'}
            element="h2"
            bold
          />
          <div>
            {!treatmentStyling && (
              <Fragment>
                <Text
                  namespace="dashboard"
                  text="extras.extras_list.size"
                  margin={false}
                  variables={{ count: product.productVariants.length }}
                  variant="textRegular16"
                  colour={selected ? 'brandWhite' : 'brandBlue400'}
                />
                {boostedSubscription ? (
                  <div className={STYLES.boostedPricesWrapper}>
                    {boostedType === MembershipTypes.boost && <BoostLogo />}
                    <div className={STYLES.boostedPrices}>
                      <Text
                        namespace="dashboard"
                        text={
                          boostedType === MembershipTypes.boost
                            ? boostedPrice
                            : 'extras.extras_list.from'
                        }
                        variables={{
                          price: formatCurrencyWithDecimal(minBoostPrice, {
                            locale: locale,
                            currency: currency
                          })
                        }}
                        variant="display14"
                        colour={
                          boostedType === MembershipTypes.boost
                            ? 'boostLight'
                            : 'brandRed500'
                        }
                        margin={false}
                        translate={boostedType !== MembershipTypes.boost}
                      />
                      <Text
                        namespace="dashboard"
                        text={`<accent type="strikeTrough">${formatCurrencyWithDecimal(
                          Math.min(...grossPrices),
                          {
                            locale: locale,
                            currency: currency
                          }
                        )}</accent>`}
                        variant="textRegular12"
                        colour="brandBlue400"
                        margin={false}
                        translate={false}
                      />
                    </div>
                  </div>
                ) : (
                  <div className={STYLES.prices}>
                    {discountedAmount !== 0 && (
                      <Text
                        namespace="dashboard"
                        text={'extras.extras_list.from'}
                        variables={{
                          price: formatCurrencyWithDecimal(minNetPrice, {
                            locale: locale,
                            currency: currency
                          })
                        }}
                        variant="textRegular16"
                        colour="brandRed500"
                        margin={false}
                        bold
                      />
                    )}
                    <Text
                      namespace="dashboard"
                      text={
                        discountedAmount !== 0
                          ? discountedPrice
                          : 'extras.extras_list.from'
                      }
                      variables={{
                        price: formatCurrencyWithDecimal(minGrossPrice, {
                          locale: locale,
                          currency: currency
                        })
                      }}
                      variant={
                        discountedAmount !== 0
                          ? 'textRegular14'
                          : 'textRegular16'
                      }
                      margin={false}
                      bold={discountedAmount === 0}
                      translate={discountedAmount === 0}
                    />
                  </div>
                )}
              </Fragment>
            )}
            {product.productLabel?.name && !boostedSubscription && (
              <div className={STYLES.label}>
                {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                <Label
                  variant="product"
                  text={{
                    text: product.productLabel.name,
                    variant: 'textRegular14',
                    margin: false,
                    translate: false
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </ProductCardWrapper>
  )
}

export type { Props }
export default ProductCard
